import React from 'react';

import { useMachine } from '../../MachineRow/contexts/MachineContext';
import DeleteTaskType from './DeleteTaskType';

const UnmapMachineTask = ({ task }) => {
  const { unmapMachineTask } = useMachine();
  const handleSubmit = () => unmapMachineTask(task.machineTaskTypeId);

  return <DeleteTaskType task={task} handleSubmit={handleSubmit} />;
};

export default UnmapMachineTask;
