/* eslint-disable import/no-cycle */
import { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import { getQtyFieldName } from '../components/QtyField/helpers/qtyFieldHelpers';
import { replaceNegativeAmount } from '../components/QuantityField/helpers/quantityFieldHelpers';
import { ADD_REMAINING_QUANTITIES_FIELD, PBS_FIELD } from '../constants';
import { useItemsIntegrationTableContext } from '../context/ItemsIntegrationTableContext';

const useSetValuesBeforeAutoFill = () => {
  const { setValuesBeforeAutofill, valuesBeforeAutofill, quantityFields } = useItemsIntegrationTableContext();
  const { watch, getValues, setValue } = useFormContext();
  const autoFill = watch(ADD_REMAINING_QUANTITIES_FIELD);

  useEffect(() => {
    if (Object.keys(valuesBeforeAutofill).length) return;
    setValuesBeforeAutofill('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuesBeforeAutofill]);
  useEffect(() => {
    if (!autoFill && valuesBeforeAutofill) {
      quantityFields.forEach((child: any) => {
        const childName = getQtyFieldName({
          locationId: child.isWorkPhase ? child.locationId : child.id,
          lineItemId: child.lineItemId,
          workPhaseId: child.isWorkPhase ? child.id : undefined,
        });
        setValue(childName, valuesBeforeAutofill[childName]);
      });
      setValuesBeforeAutofill('');
      return;
    }
    if (valuesBeforeAutofill || !autoFill) return;
    const values = getValues();
    Object.keys(values).forEach((field) => {
      if (values[field] === undefined) {
        values[field] = '';
      }
    });
    delete values[ADD_REMAINING_QUANTITIES_FIELD];
    delete values[PBS_FIELD];
    setValuesBeforeAutofill(values);
    const children = quantityFields.filter((field) => (field.nestLevel as number) > 0);
    children.forEach((child) => {
      const childName = getQtyFieldName({
        locationId: (child.isWorkPhase ? child.locationId : child.id) as string,
        lineItemId: child.lineItemId as string,
        workPhaseId: child.isWorkPhase ? child.id : undefined,
      });
      setValue(
        childName,
        replaceNegativeAmount((child.nestLevel === (0 as number) ? child.qtyAggregate : child.qtyRemaining) as number),
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoFill, valuesBeforeAutofill]);
};

export default useSetValuesBeforeAutoFill;
