import { useContext } from 'react';

import { BOMbyIdContext } from 'modules/Materials/BillOfMaterialsById/BOMbyIdContext';
import { TakeOffContext } from 'modules/Materials/TakeOff/context/TakeOffContext';

const useGetFilters = () => {
  const BOMbyIdFilterContext = useContext(BOMbyIdContext);
  let filters = null;

  if (BOMbyIdFilterContext) {
    const { BOMFilters } = BOMbyIdFilterContext;
    filters = BOMFilters;
  }

  const TakeOffFiltersContext = useContext(TakeOffContext);

  if (TakeOffFiltersContext) {
    const { parametersQuery } = TakeOffFiltersContext;
    filters = parametersQuery;
  }

  return { filters };
};

export default useGetFilters;
