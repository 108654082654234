import { useMemo, useCallback } from 'react';

import { useItemsDataGridPro } from './ItemsDataGridProProvider';

const SORT_KEY = 'SORT';
const SIZING_KEY = 'SIZING';
const VERSION = 'V.0.1';

const setNumberByFieldCache = (contextId, field, value, prefix) => {
  const base = `${contextId}-${VERSION}-${prefix}`;
  const prevValues = localStorage.getItem(base) ?? '{}';
  const map = JSON.parse(prevValues);
  const [newValue, oldIndex] = Array.isArray(value) ? value : [value, 'no-value'];
  let newStatus = { ...map, [field]: newValue };

  // sizing
  if (oldIndex === 'no-value') {
    localStorage.setItem(base, JSON.stringify(newStatus));
    return;
  }
  // sorting
  const reverseIndex = Object.entries(map).reduce(
    (pev, [key, value]) => ({ ...pev, [value]: key }),
    {},
  );
  const oldFieldId = reverseIndex[oldIndex];
  const newFieldId = reverseIndex[newValue];
  const override = { [newFieldId]: oldIndex, [oldFieldId]: newValue };

  newStatus = { ...newStatus, ...override };
  localStorage.setItem(base, JSON.stringify(newStatus));
};

const getNumberByFieldCache = (contextId, field, defaultNumber, prefix) => {
  const base = `${contextId}-${VERSION}-${prefix}`;
  const map = JSON.parse(localStorage.getItem(base) ?? '{}');
  const currentValue = map[field] ?? defaultNumber;
  return currentValue;
};

const mapFirstTime = (contextId, field, defaultNumber, prefix) => {
  const base = `${contextId}-${VERSION}-${prefix}`;
  const map = JSON.parse(localStorage.getItem(base) ?? '{}');
  if (!map[field]) {
    const newCache = { ...map, [field]: defaultNumber };
    localStorage.setItem(base, JSON.stringify(newCache));
  }
};

export const useColumns = ({ prevColumns, hasActiveForm } = {}) => {
  const { contextId, onUpdateColumns, columnsFilter } = useItemsDataGridPro({ prevColumns });
  const columns = useMemo(() => {
    const columnsOverride = prevColumns.map((column, index) => {
      const { field = false } = column;
      mapFirstTime(contextId, field, index, SORT_KEY);
      const targetIndex = getNumberByFieldCache(contextId, field, index, SORT_KEY);
      const width = getNumberByFieldCache(contextId, field, 0, SIZING_KEY);
      const override = {
        targetIndex,
        nameField: field,
        minWidth: column.minWidth || 50,
        maxWidth: column.maxWidth || 1030,
      };
      if (width !== 0) {
        override.width = width;
        delete column.flex;
      }
      return { ...column, ...override };
    });

    const result = columnsOverride.sort((a, b) => a.targetIndex - b.targetIndex);
    onUpdateColumns(result);
    const columnsFiltered = result.filter(({ field, useForm = false }) => {
      if (useForm && hasActiveForm) return true;
      return columnsFilter[field] ?? true;
    });
    return columnsFiltered;
  }, [prevColumns, contextId, onUpdateColumns, columnsFilter, hasActiveForm]);

  const onColumnOrderChange = useCallback(
    ({ field, targetIndex, oldIndex }) =>
      setNumberByFieldCache(contextId, field, [targetIndex, oldIndex], SORT_KEY),
    [contextId],
  );

  const onColumnWidthChange = useCallback(
    ({ width, colDef: { field } = {} } = {}) =>
      setNumberByFieldCache(contextId, field, width, SIZING_KEY),
    [contextId],
  );

  const hasResizableItems = useMemo(() => {
    const result = prevColumns.find((item) => item?.resizable);
    return Boolean(result);
  }, [prevColumns]);

  const hasColumnSeparator = useMemo(() => {
    const result = prevColumns.find((item) => item?.columnSeparator);
    return Boolean(result);
  }, [prevColumns]);

  return {
    columns,
    onColumnOrderChange,
    onColumnWidthChange,
    metadata: {
      hasResizableItems,
      hasColumnSeparator,
    },
  };
};
