import React from 'react';

import { Stack } from '@mui/material';

import StatusBadge from 'components/StatusBadges/StatusBadge';

export const CustomAssemblyStatus = ({ params }) =>
  params.row.type === 'Custom Assembly' && (
    <Stack direction="row" alignItems="center">
      <StatusBadge variant="draft" label={params.row.children.customAssemblyStatus} />
    </Stack>
  );
