import React from 'react';

import { ButtonProps } from '@mui/material';

import { CreateButton } from 'components';

const NextButton = ({ onClick = () => {}, disabled }: ButtonProps) => (
  <CreateButton buttonText="NEXT" color="secondary" disabled={disabled} onClick={(e) => onClick(e)} />
);

export default NextButton;
