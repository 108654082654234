import React, { FC } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import MuiDialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

import { Custom } from 'components/Icons';

import { IModal } from './@types/modal';

const DraggablePaper = (props: any) => (
  <Draggable handle="#MuiDailog-Drag-Handle">
    <Paper {...props} />
  </Draggable>
);

const Modal: FC<IModal> = ({
  title,
  subtitle,
  subtitleColor,
  onClose = () => {},
  footer,
  children,
  reducePadding,
  sx,
  paperSx,
  disableModalOutsideClick,
  disableTitleClose,
  disableCloseButton = false,
  titleStyles,
  contentStyles,
  allowOverflow,
  sizeIconClose = 'large',
  titleContainerStyles,
  floatingCloseButton = false,
  closeButtonStyles,
  closeButtonColor,
  draggable = false,
  maxWidth,
  style,
  hideBackdrop = false,
  ...props
}: IModal) => {
  const onCloseBackdropCloseDisabled = (e: any, reason: string) => {
    if (reason !== 'backdropClick') {
      onClose();
    }
  };

  const stopPropagationForTab = (event: any) => {
    if (event.key === 'Tab') {
      event.stopPropagation();
    }
  };

  return (
    <MuiDialog
      maxWidth={maxWidth}
      onKeyDown={stopPropagationForTab}
      onClose={disableModalOutsideClick ? onCloseBackdropCloseDisabled : onClose}
      onBlur={() => {}}
      sx={{
        '.MuiPaper-root': {
          overflowY: 'visible',
          ...paperSx,
        },
        ...sx,
      }}
      PaperComponent={draggable ? DraggablePaper : Paper}
      {...props}
      aria-labelledby="MuiDailog-Drag-Handle"
      style={style}
      hideBackdrop={hideBackdrop}
    >
      <Title sx={titleContainerStyles}>
        <Stack sx={{ width: '100%' }}>
          <Typography variant="h6" sx={titleStyles}>
            {title}
          </Typography>
          {Boolean(subtitle) && (
            <Typography color={subtitleColor} variant="body2">
              {subtitle}
            </Typography>
          )}
        </Stack>

        {!disableTitleClose && (
          <Box sx={floatingCloseButton ? { position: 'absolute', right: 0 } : {}}>
            <IconButton
              aria-label="delete"
              onClick={onClose}
              size={sizeIconClose}
              sx={closeButtonStyles}
              disabled={disableCloseButton}
            >
              <Custom.Close fill={closeButtonColor} />
            </IconButton>
          </Box>
        )}
      </Title>

      <DialogContent
        data-cy="modal-container"
        sx={{
          padding: '24px',
          paddingBottom: reducePadding ? '12px' : '24px',
          overflowY: 'auto',
          overflow: allowOverflow ? 'visible' : 'auto',
          ...contentStyles,
        }}
        dividers
      >
        {children}
      </DialogContent>
      {footer && <DialogActions sx={{ height: 52 }}>{footer}</DialogActions>}
    </MuiDialog>
  );
};

export default Modal;

const Title = ({ sx, children }: any) => (
  <DialogTitle
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: 64,
      position: 'relative',
      ...sx,
    }}
  >
    {children}
  </DialogTitle>
);
