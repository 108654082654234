import React, { useEffect, useState } from 'react';

import { Box, Stack, Typography } from '@mui/material';

import OverFlowTooltip from 'components/Tooltip/OverFlowTooltip';
import { getParsedDate, formateTime } from 'helpers/dateFunctions';

import useUserById from './hooks/useUserById';

interface GetDateAndCreatedByProps {
  createdOn: string;
  createdBy: string;
}

interface UserCache {
  [key: string]: string;
}

const userCache: UserCache = {};

const GetDateAndCreatedBy = ({ createdOn, createdBy: userId }: GetDateAndCreatedByProps) => {
  const getTime = formateTime(createdOn);
  const parseDate = getParsedDate(createdOn);

  const { getUserById } = useUserById();

  const [userName, setUserName] = useState('');

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (userCache[userId]) {
        setUserName(userCache[userId]);
        return;
      }

      const { completeName } = await getUserById(userId);

      userCache[userId] = completeName;
      setUserName(completeName);
    };

    fetchUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <Stack ml="auto" mr={2}>
      <OverFlowTooltip showAlways title={`Added at ${getTime} by ${userName}`}>
        <Typography component={Box} variant="body2" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {parseDate || ''}
        </Typography>
      </OverFlowTooltip>
    </Stack>
  );
};

export default GetDateAndCreatedBy;
