import React from 'react';

import StackRow from 'components/StackRow';
import useWorkRequestHelpers from 'modules/Field/WorkRequests/hooks/useWorkRequestHelpers';
import StatusPoint from 'modules/Field/WorkRequests/WorkRequest/components/StatusPoint';

import { useTableRowContext } from '../context/TableRowContext';
import { useWorkOrderItemsContext } from '../context/WorkOrderItemsContext';
import HideWhenEditingWrapper from './HideWhenEditingWrapper';

const StatusPointCell = ({ value, row }) => {
  const { workOrder } = useWorkOrderItemsContext();
  const { WOIsReleased } = useWorkRequestHelpers();
  const { isAddItemRow } = useTableRowContext();

  return (
    <HideWhenEditingWrapper>
      {!isAddItemRow && (
        <StackRow sx={{ width: '100%', justifyContent: 'center', height: '100%' }}>
          {WOIsReleased(workOrder) && row.hasShopTask && <StatusPoint statusId={value} />}
        </StackRow>
      )}
    </HideWhenEditingWrapper>
  );
};

export default StatusPointCell;
