import React, { useState } from 'react';

import CompanyInviteModal from './CompanyInviteModal/CompanyInviteModal';
import { CompanyMembersProvider } from './CompanyMembersContext';
import CompanyMembersHeader from './CompanyMembersHeader';
import CompanyMembersTable from './CompanyMembersTable/CompanyMembersTable';

const headerOptions = [
  { title: 'User', sortValue: 'name' },
  { title: 'Email', sortValue: 'email' },
  { title: 'Office Phone' },
  { title: 'Mobile Phone' },
  { title: 'Invite Status', sortValue: 'status' },
  { title: 'EMPTY' },
];

const CompanyMembers = () => {
  const [inviteModalOpen, setInviteModalOpen] = useState(false);

  const closeModal = () => setInviteModalOpen(false);
  const openModal = () => setInviteModalOpen(true);

  return (
    <CompanyMembersProvider>
      <CompanyMembersHeader openInviteModal={openModal} />
      <CompanyMembersTable headerOptions={headerOptions} />
      <CompanyInviteModal openStatus={inviteModalOpen} onClose={closeModal} />
    </CompanyMembersProvider>
  );
};

export default CompanyMembers;
