import React, { useEffect, useState } from 'react';

import { ROW_TYPES } from '../constants/constants';

const ActiveRowContext = React.createContext();

const ActiveRowProvider = ({ children }) => {
  const [activeRows, setActiveRows] = useState([]);

  const toggleSelection = (row) => {
    const wasSelected = activeRows.includes(row);
    const newRows = (() => {
      if (!wasSelected || activeRows.length > 1) return [row];
      return [];
    })();
    setActiveRows(newRows);
  };

  const toggleSelectionInGroup = (row) => {
    const index = activeRows.indexOf(row);
    const unselectDifferentNodes = activeRows.filter((item) => item.type === ROW_TYPES.WORK_PHASE);
    if (index === -1) return setActiveRows([...unselectDifferentNodes, row]);
    return setActiveRows(unselectDifferentNodes.filter((item) => item !== row));
  };

  const multiSelectTo = (row) => toggleSelection(row);

  const unselectAll = () => setActiveRows([]);

  const onWindowKeyDown = (event) => {
    if (event.defaultPrevented) return;
    if (event.key === 'Escape') unselectAll();
  };

  const handleUnselectAll = (event) => {
    if (event.defaultPrevented) return;
    unselectAll();
  };

  useEffect(() => {
    window.addEventListener('keydown', onWindowKeyDown);
    window.addEventListener('touchend', handleUnselectAll);

    return () => {
      window.removeEventListener('keydown', onWindowKeyDown);
      window.removeEventListener('touchend', handleUnselectAll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const valueObj = {
    activeRows,
    setActiveRows,
    unselectAll,
    toggleSelection,
    toggleSelectionInGroup,
    multiSelectTo,
  };
  return <ActiveRowContext.Provider value={valueObj}>{children}</ActiveRowContext.Provider>;
};

const useActiveRow = () => {
  const context = React.useContext(ActiveRowContext);
  if (context === undefined) {
    throw new Error('useActiveRow must be used within a ActiveRowContext');
  }
  return context;
};

export { ActiveRowContext, ActiveRowProvider, useActiveRow };
