import React from 'react';

import BOMItemsGroup from 'components/BOMItemsGroup';

import { ItemProvider } from './ItemProvider';
import BillOfProcessRow from './WorkRequestItemSublist/BillOfProcessRow';

const WorkRequestLists = ({ row, isResponsiveWR = false, parent }) => {
  if (!parent) return null;

  return (
    <ItemProvider item={row} isResponsiveWR={isResponsiveWR} parent={parent}>
      <BOMItemsGroup workRequestItemId={row.workRequestItemId} />
      <BillOfProcessRow />
    </ItemProvider>
  );
};

export default WorkRequestLists;
