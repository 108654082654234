import React, { useMemo, useState } from 'react';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { Custom } from 'components/Icons';
import { otherColors } from 'styles/theme/colors';

const MessageOptions = ({
  isArchive = false,
  message = {},
  onArchive = () => {},
  onDelete = () => {},
}) => {
  const [anchorEl, setAnchorEl] = useState();

  const hasMenuOpened = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleArchive = () => {
    onArchive(message);
    handleCloseMenu();
  };

  const handleDelete = () => {
    onDelete(message);
    handleCloseMenu();
  };

  return (
    <>
      <IconButton
        aria-label="message-options"
        id="message-options-btn"
        aria-controls={hasMenuOpened ? 'message-options-menu' : undefined}
        aria-expanded={hasMenuOpened ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleOpenMenu}
      >
        <Custom.MoreVert fill={otherColors.black} />
      </IconButton>
      <Menu
        id="message-options-menu"
        anchorEl={anchorEl}
        open={hasMenuOpened}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            width: '140px',
          },
        }}
        onClose={handleCloseMenu}
      >
        {!isArchive && <MenuItem onClick={handleArchive}>Archive</MenuItem>}
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
    </>
  );
};

export default MessageOptions;
