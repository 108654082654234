import React from 'react';

import { Chip } from '@mui/material';

import { useFilterOptions } from '../../features/filters/options';
import { useRulesFilter } from '../../features/filters/rules';
import { useSourceDataOption } from '../../features/filters/source-data';
import { useChipsFilterHelpers } from './useChipsFilterHelpers';

export const BreakdownChips = ({ sx, contextHook }) => {
  const { chipList, updateChipList } = contextHook();
  const { onDeleteChip } = useChipsFilterHelpers(contextHook);

  const handleDelete = (id) => {
    const auxList = [...chipList];
    const position = auxList.findIndex((e) => e.id === id);
    auxList.splice(position, 1);
    updateChipList([...auxList]);
    onDeleteChip(id);
  };

  return (
    <>
      {chipList?.map((item) => (
        <Chip
          key={item.id}
          label={item.chipName}
          icon={item.icon}
          onDelete={() => handleDelete(item.id)}
          size="small"
          sx={{ margin: '8px 8px 0 0', maxWidth: 'fit-content', ...sx }}
        />
      ))}
    </>
  );
};

export const OptionsChips = ({ sx }) => {
  const { onUpdateQuery: onUpdateOptionsQuery, onlyActive: onlyFiltersActive } = useFilterOptions();

  return (
    <>
      {onlyFiltersActive.map((option) => (
        <Chip
          key={option.key}
          label={option.label}
          onDelete={() => onUpdateOptionsQuery({ [option.key]: false })}
          size="small"
          sx={{ margin: '8px 8px 0 0', maxWidth: 'fit-content', ...sx }}
        />
      ))}
    </>
  );
};

export const RulesChips = ({ sx }) => {
  const { onlyActive: onlyRulesActive, onRemoveRule } = useRulesFilter();

  return (
    <>
      {onlyRulesActive.map((option, index) => {
        if (option)
          return (
            <Chip
              key={JSON.stringify({ option, index })}
              label={`Rule: ${index + 1} ${option.condition.toUpperCase()} ${option.propertyName} ${option.operator}
               ${option.values[0]}`}
              onDelete={() => onRemoveRule(`${index}`)}
              size="small"
              sx={{ margin: '8px 8px 0 0', maxWidth: 'fit-content', ...sx }}
            />
          );
        return <></>;
      })}
    </>
  );
};

export const SourceDataChips = ({ sx }) => {
  const { onClearQuery: onClearSourceDataQuery, query: querySourceDataQuery } = useSourceDataOption();
  return (
    <>
      {querySourceDataQuery.map((option) => (
        <Chip
          key={option}
          label={option}
          onDelete={() => onClearSourceDataQuery(option)}
          size="small"
          sx={{ margin: '8px 8px 0 0', maxWidth: 'fit-content', ...sx }}
        />
      ))}
    </>
  );
};
