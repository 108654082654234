/* eslint-disable import/no-cycle */
import { useMemo, useState } from 'react';

import useFetchWorkRequests from 'hooks-api/useFetchWorkRequests';
import { getFilters } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestListElements';
import { useCurrentProjectContext } from 'modules/Materials/components/ItemsIntegrationModal/context/CurrentProjectContext';

import { STATUS_LIST } from '../constants';
import useModalEnableDone from './useModalEnableDone';
import useModalOperations from './useModalOperations';

const useItemsIntegrationModalContextEffectsHandler = ({
  setAddNewWRForm,
  setActiveStep,
  setCompleted,
  setIsLoading,
  setSelectionModel,
  setOpen,
  addNewWR,
  setRows,
  isLoading,
  setEnableDone,
  enableDone,
  qtyStepMethods,
  completed,
}: any) => {
  const [openConfirmLeaveModal, setOpenConfirmLeaveModal] = useState(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { currentProject } = useCurrentProjectContext();
  const getWorkRequestsQuery = useMemo(
    () =>
      currentProject
        ? {
            skip: 0,
            statusName: STATUS_LIST.join(','),
            ...getFilters(currentProject, []),
            orderBy: 'needBy:asc',
          }
        : {},
    [currentProject],
  );
  const { getWorkRequests, workRequests } = useFetchWorkRequests();
  const selectedProject = useMemo(
    () =>
      currentProject?.type === 'PROJECT'
        ? {
            locationId: currentProject.id,
            locationName: currentProject.label,
            locationIdentifier: currentProject.identifier,
          }
        : null,
    [currentProject],
  );
  useModalEnableDone({
    setAddNewWRForm,
    setActiveStep,
    setRows,
    isLoading,
    setEnableDone,
    enableDone,
    qtyStepMethods,
    completed,
    workRequests,
    getWorkRequests,
    getWorkRequestsQuery,
  });

  const { handleOnScrollEnd, handleClose, handleNext, handleConfirmClose, radioCheckColumn } = useModalOperations({
    getWorkRequests,
    getWorkRequestsQuery,
    workRequests,
    setAddNewWRForm,
    setActiveStep,
    setCompleted,
    setIsLoading,
    setSelectionModel,
    setOpen,
    addNewWR,
    setOpenConfirmLeaveModal,
    showDialog,
    setShowDialog,
  });

  return {
    handleOnScrollEnd,
    handleClose,
    handleNext,
    handleConfirmClose,
    radioCheckColumn,
    selectedProject,
    openConfirmLeaveModal,
    setOpenConfirmLeaveModal,
    showDialog,
    setShowDialog,
  };
};

export default useItemsIntegrationModalContextEffectsHandler;
