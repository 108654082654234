/* eslint-disable import/no-cycle */
import React, { FC, useRef, useState } from 'react';

import useGeneralContext from 'helpers/useGeneralContext';

import useFieldValuesWatched from '../hooks/useFieldValuesWatched';
import useGetFieldMemoValues from '../hooks/useGetFieldMemoValues';
import useGetLBSElements from '../hooks/useGetLBSElements';
import { IFieldProvider, UseFieldContextType } from './@types/fieldContext';

const FieldContext = React.createContext<any>(null);

const FieldProvider: FC<IFieldProvider> = ({
  name,
  rowIdentifier,
  qtyAggregate,
  qtyRemaining,
  children,
  isWorkPhase = false,
  disabled = false,
}: IFieldProvider) => {
  const [showArrows, setShowArrows] = useState<boolean>(false);
  const [deleteBtnClicked, setDeleteBtnClicked] = useState<boolean>(false);
  const qtyFieldRef = useRef<HTMLInputElement | null>(null);
  const [showDeleteButton, setShowDeleteButton] = useState<boolean>(false);
  const {
    fieldId,
    lineItemId,
    lineItemMaxNestLevel,
    locationItemsByLineItem,
    unassignedRowFieldName,
    isBomLineItem,
    quantityField,
    currentNestLevel,
    fieldName,
    isReadOnly,
    rangeErrorMessage,
    hasChildren,
    color,
    inputStyles,
    parentFieldName,
  } = useGetFieldMemoValues({ name, isWorkPhase, qtyRemaining, qtyAggregate, showArrows });

  const {
    childrenFields,
    childrenFieldNames,
    lineItemFieldName,
    lineItemValueWatched,
    allChildrenBellowCurrentLocation,
    hasChildrenBelowAmount,
    quantityFieldChildren,
  } = useGetLBSElements({
    isBomLineItem,
    locationItemsByLineItem,
    fieldId,
    lineItemId,
    currentNestLevel,
    quantityField,
  });

  const { fieldWatched, parentWatched, childrenWatched, childrenValuesWatched } = useFieldValuesWatched({
    fieldName,
    parentFieldName,
    childrenFieldNames,
  });

  const valueObj: UseFieldContextType = {
    parentFieldName,
    fieldName,
    inputStyles,
    color,
    rowIdentifier,
    qtyAggregate,
    qtyRemaining,
    isReadOnly,
    rangeErrorMessage,
    currentNestLevel,
    lineItemId,
    isBomLineItem,
    disabled,
    showDeleteButton,
    fieldWatched,
    showArrows,
    qtyFieldRef,
    lineItemMaxNestLevel,
    fieldId,
    hasChildren,
    deleteBtnClicked,
    quantityFieldChildren,
    lineItemValueWatched,
    lineItemFieldName,
    childrenValuesWatched,
    childrenFields,
    hasChildrenBelowAmount,
    unassignedRowFieldName,
    quantityField,
    allChildrenBellowCurrentLocation,
    parentWatched,
    locationItemsByLineItem,
    childrenWatched,
    setShowArrows,
    setDeleteBtnClicked,
    setShowDeleteButton,
  };
  return <FieldContext.Provider value={valueObj}>{children}</FieldContext.Provider>;
};
const useFieldContext = (): UseFieldContextType => useGeneralContext(FieldContext, 'FieldContext');

export { FieldContext, FieldProvider, useFieldContext };
