/* eslint-disable max-lines-per-function */
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import { Droppable } from 'react-beautiful-dnd';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { useUser } from 'app/UserContext';
import ItemsDataGridPro from 'components/ItemsDatagridPro';
import { useManufacturerAPI } from 'hooks-api/useManufacturerAPI';
import { useMaterialsConfig } from 'hooks-api/useMaterialsConfig';
import { useComponentVisible } from 'hooks/useComponentVisible';
import { useEqualValueChecker } from 'hooks/useEqualValueChecker';
import useWindowSize from 'hooks/useWindowSize';

import NewItemRowComponent from '../commons/NewItemRowComponent';
import { useAssignationMode } from '../features/assignation-mode';
import { useTakeOff } from './context/TakeOffContext';
import NoRowsOverlay from './NoRowsOverlay';
import TakeOffCustomRow from './TakeOffCustomRow';
import EditQuantityModal from './TakeOffPadTab/EditQuantityModal/EditQuantityModal';
import { FIELDS_SORT } from './TakeOffPadTab/hooks/TableHeaders/constants';
import useTableHeaders from './TakeOffPadTab/hooks/TableHeaders/useTableHeaders';
import { getFilterHeight, partToTakeOffPadItemMapFn, takeOffPadItemMapFn } from './takOffHelpers';
import useCreateTakeoffPadItem from './useCreateTakeoffPadItem';
import useTakeOffPadTableRowErrors from './useTakeOffPadTableRowErrors';

export const TYPES_TO_CREATE_NEW_ITEM = {
  NEW: 'new',
  COPY: 'copy',
};
const ORDER_KEY = 'TakeOffPadTable-ColumnOrder';

const initialRows = [{ lineItemId: -1, lineItemName: '+ Add New Item' }];

const TakeOffPadTable = forwardRef(
  (
    { isSourcePad = false, text, data = [], onFetchMore = () => {}, onAddPad = () => {}, loading = false, methods },
    ref,
  ) => {
    const firTickRef = useRef(0);
    const selectedPartRef = useRef();
    const [rows, setRows] = useState([]);
    const [isAddingRow, setIsAddingRow] = useState(false);
    const [type, setType] = useState(null);
    const [partName, setPartName] = useState(null);
    const clearItems = useRef(false);
    const [containerFilterHeight, setContainerFilterHeight] = useState(0);
    const [newRow] = useState(initialRows);
    const [editingQuantityRowData, setEditingQuantityRowData] = useState(null);
    const { selectedItem } = useFacilitiesProjects();
    const {
      control,
      watch,
      setValue: setFormControlValue,
      reset,
      formState,
      getValues,
      clearErrors,
      handleSubmit,
    } = methods;

    const selectedManufacturer = watch('manufacturer');

    const onChangePartName = (value) => setPartName(value);

    const { user } = useUser();
    const { unitOfMeasure: uomOptions, manufactured: manufacturers, lineItemTypesMap } = useMaterialsConfig();
    const {
      pads,
      padIndex,
      selectedProject,
      pagination,
      updateItemCount,
      onAddTakeOffPadItem,
      loadingAddTakeOffPadItem,
      projectId,
      padSelected,
      apiRef,
      isRevitSourcePad,
    } = useTakeOff();

    const {
      callbacks: { onAddNewManufacturer },
    } = useManufacturerAPI();

    const {
      data: { assignMetadata },
    } = useAssignationMode(useTakeOff);

    const hook = useComponentVisible(false, ['AddItemAction']);
    const { setIsComponentVisible: changePartAutocompleteVisibility } = hook;

    useEffect(() => updateItemCount(rows.length), [rows, updateItemCount]);

    useEffect(() => (clearItems.current = !clearItems.current), [selectedProject, padSelected, projectId]);

    useEffect(() => {
      const dataMapped = data.map((item) => takeOffPadItemMapFn(item, padSelected));
      setRows(dataMapped);
    }, [data, padSelected]);

    const partLineItemType = useMemo(() => {
      if (type === TYPES_TO_CREATE_NEW_ITEM.COPY) return lineItemTypesMap?.part;
      return lineItemTypesMap?.writein;
    }, [lineItemTypesMap, type]);

    const hasUserSelectedManufacturer = useMemo(() => {
      if (!selectedPartRef.current || !selectedManufacturer) return false;

      if (typeof selectedManufacturer === 'object') {
        return selectedManufacturer?.id !== selectedPartRef.current?.manufacturer?.manufacturerId;
      }

      return selectedManufacturer !== selectedPartRef.current?.manufacturer?.manufacturerName;
    }, [selectedManufacturer]);

    const handleCreateTakeOffPadItem = useCallback(
      (part, type) => {
        setPartName(null);
        setType(type);
        changePartAutocompleteVisibility(false);
        setIsAddingRow(true);

        selectedPartRef.current = { ...part };

        setFormControlValue('uom', {
          value: part.unitOfMeasure.unitOfMeasureId,
          label: part.unitOfMeasure.unitOfMeasureName,
          code: part.unitOfMeasure.unitOfMeasureCode,
        });
        setFormControlValue('manufacturer', {
          id: part.manufacturer.manufacturerId,
          label: part.manufacturer.manufacturerName,
        });

        const newRow = partToTakeOffPadItemMapFn(part, padSelected);
        setRows((prevRows) => [{ lineItemId: 0, ...newRow }, ...prevRows]);
      },
      [setFormControlValue, changePartAutocompleteVisibility, padSelected],
    );

    const handleCreateNewTakeOffPadItem = useCallback(
      (part, type) => {
        setPartName(part?.partName);
        setType(type);
        changePartAutocompleteVisibility(false);
        setIsAddingRow(true);

        selectedPartRef.current = { ...part };

        const newRow = partToTakeOffPadItemMapFn(undefined, padSelected);
        setRows((prevRows) => [{ lineItemId: 0, ...newRow }, ...prevRows]);
      },
      [changePartAutocompleteVisibility, padSelected],
    );

    const resetForm = useCallback(() => {
      firTickRef.current = 0;
      setIsAddingRow(false);
      reset();
      changePartAutocompleteVisibility(false);
      setType(null);
      setPartName(null);
      const dataMapped = data.map((item) => takeOffPadItemMapFn(item, padSelected));
      setRows(dataMapped);
    }, [data, padSelected, changePartAutocompleteVisibility, reset]);

    useEffect(() => {
      resetForm();
    }, [padIndex, resetForm, projectId]);

    const onCreateTakeOffPadItem = useCreateTakeoffPadItem({
      pads,
      padIndex,
      manufacturers,
      onAddNewManufacturer,
      user,
      onAddTakeOffPadItem,
      loadingAddTakeOffPadItem,
      partLineItemType,
      selectedPartRef,
      type,
      resetForm,
      partName,
      getValues,
      assignMetadata,
      formState,
    });

    const handleSubmitForm = useCallback(
      (itemNameError) => () => {
        if (!itemNameError) {
          handleSubmit(onCreateTakeOffPadItem)();
        }
      },
      [handleSubmit, onCreateTakeOffPadItem],
    );

    const itemNameRules = useEqualValueChecker({
      currentName: partName ?? '',
      customMessage: 'Name already exists',
      rules: [
        {
          check: (value = '') => value.length > 128,
          message: (value = '') => `${value.length}/128`,
        },
      ],
      required: type === 'new',
    });

    useTakeOffPadTableRowErrors(formState, itemNameRules.error);

    const onKeyDownHandler = useCallback(
      (event) => {
        if (event.keyCode === 27) {
          resetForm();
        }
      },
      [resetForm],
    );

    const handleClickEditQuantity = (lineItemId) => {
      if (editingQuantityRowData || isRevitSourcePad) return;
      setEditingQuantityRowData(rows.find((data) => data.lineItemId === lineItemId));
    };

    const handleCloseQuantityDialog = () => setEditingQuantityRowData(null);

    const headers = useTableHeaders({
      isAddingRow,
      control,
      handleSubmitForm,
      uomOptions,
      manufacturers,
      hasUserSelectedManufacturer,
      partName,
      type,
      onChangePartName,
      padSelected,
      selectedManufacturer,
      resetForm,
      itemNameRules,
      onKeyDownHandler,
      editingQuantityRowData,
      handleClickEditQuantity,
      isRevitSourcePad,
      setFormControlValue,
      clearErrors,
      loadingAddTakeOffPadItem,
    });

    const onCreateNewItem = useCallback(
      (type) => (part, partName) => {
        if (type === TYPES_TO_CREATE_NEW_ITEM.COPY) {
          handleCreateTakeOffPadItem(part, type);
        }

        if (type === TYPES_TO_CREATE_NEW_ITEM.NEW) {
          handleCreateNewTakeOffPadItem({ partName }, type);
        }
      },
      [handleCreateNewTakeOffPadItem, handleCreateTakeOffPadItem],
    );

    const RowComponent = useMemo(() => {
      const component = !isSourcePad
        ? () => (
            <NewItemRowComponent
              actionDefaultLabel="Add item"
              hook={hook}
              onAddPartItem={onCreateNewItem(TYPES_TO_CREATE_NEW_ITEM.COPY)}
              onAddWriteInItem={onCreateNewItem(TYPES_TO_CREATE_NEW_ITEM.NEW)}
              disabled={!projectId || !padSelected || isAddingRow}
            />
          )
        : null;
      return component;
    }, [hook, isSourcePad, onCreateNewItem, projectId, padSelected, isAddingRow]);

    useEffect(() => {
      if (type === TYPES_TO_CREATE_NEW_ITEM.NEW) {
        const defaultValue = manufacturers.find(({ label }) => label.toLowerCase() === 'generic');
        setFormControlValue('manufacturer', defaultValue);
      }
    }, [manufacturers, type, setFormControlValue]);

    const handleDropItem = (item) => {
      const unitOfMeasure = uomOptions.find(({ code }) => code === item.unitOfMeasureCode);
      setFormControlValue('manufacturer', item.manufacturerName);
      setFormControlValue('uom', unitOfMeasure);
      setFormControlValue('catalogNumber', item.catalogNumber);
      setFormControlValue('quantity', 0);
      setFormControlValue('unitQuantity', 0);
      setType(TYPES_TO_CREATE_NEW_ITEM.COPY);
      selectedPartRef.current = {
        ...item,
        partId: item.partHistoryRefId,
      };
      handleSubmitForm()();
    };

    useImperativeHandle(ref, () => ({
      handleDropItem,
    }));

    const { height, width } = useWindowSize();
    const containerFilter = document.getElementById('location-chip-area');
    useEffect(() => {
      setContainerFilterHeight(containerFilter ? getFilterHeight(containerFilter) : 0);
    }, [containerFilter, width]);

    useEffect(() => {
      const isOpen = Boolean(editingQuantityRowData);
      if (isOpen) {
        const editingRowData = rows?.find((lineItem) => lineItem?.lineItemId === editingQuantityRowData?.lineItemId);

        if (editingRowData) setFormControlValue('quantity', editingRowData.quantity);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editingQuantityRowData, rows]);

    const handleOnClickAway = useCallback(() => {
      handleSubmitForm(itemNameRules.error)();
    }, [handleSubmitForm, itemNameRules.error]);

    const CustomRow = useCallback(
      (params) => (
        <TakeOffCustomRow params={params} isAddingRow={isAddingRow} onClickAwayListener={handleOnClickAway} />
      ),
      [isAddingRow, handleOnClickAway],
    );

    return (
      <>
        <Droppable droppableId="takeoff">
          {(provided) => (
            <Box ref={provided.innerRef} {...provided.droppableProps}>
              <Box
                sx={{ height: `${height - 235 - containerFilterHeight}px`, transition: 'all 200ms ease' }}
                data-cy="take-off-table"
              >
                <ItemsDataGridPro
                  apiRef={apiRef}
                  loading={loading}
                  hasActiveForm={isAddingRow}
                  onSelected={(items) => onAddPad(items)}
                  onSelectedId="lineItemId"
                  contextId={ORDER_KEY}
                  sx={{ borderTopLeftRadius: 0 }}
                  overrideSortModel={FIELDS_SORT}
                  disableColumnMenu
                  asyncApi={{ ...pagination, onScroll: onFetchMore }}
                  editMode="row"
                  rows={rows}
                  columns={headers(RowComponent)}
                  getRowId={(row) => row?.lineItemId}
                  pinnedRows={RowComponent ? { top: newRow } : undefined}
                  experimentalFeatures={{ rowPinning: true, newEditingApi: true }}
                  components={{
                    NoRowsOverlay: () => <NoRowsOverlay text={text} />,
                    Row: CustomRow,
                  }}
                  isRowSelectable={() => !isAddingRow}
                  onCellKeyDown={(params, event) => {
                    event.defaultMuiPrevented = true;
                  }}
                />
              </Box>
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
        {editingQuantityRowData && (
          <EditQuantityModal
            data={editingQuantityRowData}
            onClose={handleCloseQuantityDialog}
            ernPrefix="moab:takeoffpaditem"
            activeProject={selectedItem}
          />
        )}
      </>
    );
  },
);

export default React.memo(TakeOffPadTable);
