import React from 'react';

import { InputLabel } from '@mui/material';

export const CatalogSearchDropdownLabel = ({ sx, children }) => (
  <InputLabel
    id="catalog-dropdown"
    sx={{
      color: '#FFF',
      '&.Mui-focused': {
        color: '#FFF',
      },
      ...sx,
    }}
  >
    {children}
  </InputLabel>
);
