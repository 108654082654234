import React, { useState, useEffect, useCallback, useContext } from 'react';

import { Box, Divider } from '@mui/material';

import { EditQuantityModalContext } from 'modules/Materials/TakeOff/TakeOffPadTab/EditQuantityModal/contexts/EditQuantityModalContext';

import { usePopover } from '../../PopoverProvider';
import { checkType, ShowCheckBox, PickIcon, PickText, ArrowIndicator, getElementId } from '../HelperElements';
import updateWorkPhases from './updateWorkPhases';

const WorkPhasesItem = ({ item, depth = 0, typeStub = '', renderProp, listSetter, list }) => {
  const editQuantityModalContext = useContext(EditQuantityModalContext);
  const { chipList } = usePopover();
  const id = getElementId(item);
  const [isOpen, setIsOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(
    // eslint-disable-next-line
    chipList.findIndex((e) => e.id === id) === -1 ? false : true
  );

  const updateIsOpen = useCallback((value) => setIsOpen(value), [setIsOpen]);
  const updateIsChecked = useCallback(
    (value) => {
      setIsChecked(value);
      if (editQuantityModalContext) {
        const { addOrRemoveCheckedBoxParentIds } = editQuantityModalContext;
        addOrRemoveCheckedBoxParentIds([id], value);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setIsChecked],
  );

  const type = checkType(item) + typeStub;
  const render = isOpen && item.hasWorkPhases;

  const handleClick = () => updateIsOpen(!isOpen);

  useEffect(() => {
    if (Array.isArray(chipList)) {
      const position = chipList.findIndex((e) => e.id === id);
      if (position > -1) updateIsChecked(true);
    }
  }, [chipList, id, updateIsChecked]);

  useEffect(() => {
    if (type === 'Scope Package') updateWorkPhases(id, isChecked, list, listSetter);
    // eslint-disable-next-line
  }, [isChecked]);

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <ShowCheckBox item={item} isChecked={isChecked} setIsChecked={updateIsChecked} />
        <Box sx={{ width: `${depth * 24}px` }} />
        <ArrowIndicator item={item} isOpen={isOpen} handleClick={handleClick} />
        <PickIcon type={type} margin={type === 'Work Phase' ? '0 15px 0 8px}' : undefined} />
        <PickText type={type} item={item} />
      </Box>
      <Divider sx={{ width: '100%' }} />
      {render && renderProp()}
    </>
  );
};

export default WorkPhasesItem;
