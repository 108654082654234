import { useContext } from 'react';

import { WorkOrderItemsContext } from 'modules/Shop/WorkOrders/WorkOrder/context/WorkOrderItemsContext';
import { useAddWorkOrderItemFromCatalogDataLayer } from 'modules/Shop/WorkOrders/WorkOrder/hooks/dataLayer/useAddWorkOrderItemFromCatalogDataLayer';

const addWorkOrderItems = async (items, handleAddWorkOrderItemFromCatalog) => {
  const promises = items.map((item) => {
    if (!item?.partId) throw new Error('Item is undefined');

    const itemObj = { partId: item.partId, quantity: item.qty };

    return handleAddWorkOrderItemFromCatalog(itemObj);
  });

  return Promise.all(promises);
};

const useCatalogPopupWorkOrder = () => {
  const workOrderItemsContext = useContext(WorkOrderItemsContext);

  const { workOrder } = workOrderItemsContext || {};
  const { handleAddWorkOrderItemFromCatalog } = useAddWorkOrderItemFromCatalogDataLayer(workOrder);

  const handleAddItems = async (items) => {
    const responses = await addWorkOrderItems(items, handleAddWorkOrderItemFromCatalog);
    const responsesContainsError = responses.some((response) => response.errors?.length);
    if (responsesContainsError) return false;

    const insertedItems = responses?.reduce((accumulatedQuantity, response) => {
      const currentQuantity = response?.data?.addWorkOrderItemFromCatalog?.quantity ?? 0;
      return accumulatedQuantity + currentQuantity;
    }, 0);
    return insertedItems;
  };

  return { handleAddItems };
};

export default useCatalogPopupWorkOrder;
