import React, { useState } from 'react';

const CustomersContext = React.createContext();

const CustomersProvider = ({ children }) => {
  const [selected, setSelected] = useState([]);

  const selectedStateObj = React.useMemo(
    () => ({
      selected,
      setSelected,
    }),
    [selected, setSelected],
  );

  return <CustomersContext.Provider value={selectedStateObj}>{children}</CustomersContext.Provider>;
};

const useCustomersContext = () => {
  const context = React.useContext(CustomersContext);
  if (context === undefined) {
    throw new Error('useCustomersContext must be used within a CustomersContext');
  }
  return context;
};

export { CustomersContext, CustomersProvider, useCustomersContext };
