import usePartCategory from 'hooks-api/usePartCategory';
import usePartsCloudSearchAPIByPartId from 'hooks-api/usePartsCloudSearchAPIByPartId';

import { usePartCategory as usePartCategoryContext } from '../../PartCategory/PartCategoryContext';

const useOnSelectPart = ({ setSearchValue, setOptions, resetSearch, onSelectPartFromSearch }) => {
  const { fetchPartCategoryById } = usePartCategory();
  const { onSelectPartCategoryFromSearch } = usePartCategoryContext();
  const { getPartById } = usePartsCloudSearchAPIByPartId();

  const onSelectPartCategory = async (categoryId) => {
    const selectedPartCategory = await fetchPartCategoryById(categoryId);
    if (selectedPartCategory) {
      onSelectPartCategoryFromSearch(selectedPartCategory);

      setSearchValue('');
      setOptions([]);
      resetSearch();
    }
  };

  const onSelectPart = async (partId) => {
    const selectedPart = await getPartById(partId);
    if (selectedPart) {
      onSelectPartFromSearch(selectedPart);
    }
  };

  return {
    onSelectPartCategory,
    onSelectPart,
  };
};

export default useOnSelectPart;
