/* eslint-disable import/no-cycle */
import { useEffect } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';

import useGetNumberValues from 'hooks/useGetNumberValues';

import { ADD_REMAINING_QUANTITIES_FIELD } from '../../../constants';
import { useItemsIntegrationTableContext } from '../../../context/ItemsIntegrationTableContext';
import { addAutoFilledField, removeAutoFilledField } from '../../QtyField/helpers/qtyFieldHelpers';
import { useFieldContext } from '../context/FieldContext';
import { replaceNegativeAmount } from '../helpers/quantityFieldHelpers';

const useAutofillLineItemClicked = () => {
  const { control, setValue, getValues, trigger } = useFormContext();
  const {
    parentWatched,
    currentNestLevel,
    lineItemId,
    lineItemFieldName,
    fieldName,
    qtyRemaining,
    lineItemMaxNestLevel,
    fieldWatched,
  } = useFieldContext();
  const { autoFilledFields, setAutoFilledFields, setMaxNestLevel } = useItemsIntegrationTableContext();
  const autoFill = useWatch({ control, name: ADD_REMAINING_QUANTITIES_FIELD });
  const { getNumberValues } = useGetNumberValues();
  useEffect(() => {
    // * autopop if line item was clicked
    const parentWatchedHasNoValue = () => {
      if (autoFilledFields.includes(fieldName)) removeAutoFilledField(fieldName, setAutoFilledFields);
      if (getValues(fieldName) !== '') setValue(fieldName, '');
    };
    const isLineItemNotAutofilled = autoFill || currentNestLevel !== 1 || autoFilledFields?.includes(lineItemFieldName);
    if (isLineItemNotAutofilled) return;
    if (parentWatched === '') {
      parentWatchedHasNoValue();
      return;
    }
    if (!autoFilledFields.includes(fieldName)) addAutoFilledField(fieldName, { autoFilledFields, setAutoFilledFields });
    if (getNumberValues(fieldName) !== qtyRemaining || getValues(fieldName) === '')
      setValue(fieldName, replaceNegativeAmount(qtyRemaining));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentNestLevel, lineItemId, fieldName, qtyRemaining, autoFill, parentWatched, lineItemFieldName]);
  useEffect(
    // * handle unmount
    () => () => {
      const rowHasNoValue = fieldWatched || currentNestLevel !== lineItemMaxNestLevel;
      if (rowHasNoValue) return;
      trigger();
      setMaxNestLevel((current: any) => ({
        ...current,
        [lineItemId]: current[lineItemId] - 1,
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
};

export default useAutofillLineItemClicked;
