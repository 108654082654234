import React, { forwardRef, useImperativeHandle, useMemo, useState } from 'react';

import Stack from '@mui/material/Stack';

import CatalogSearchResultPartItem from '../CatalogSearchResultPartItem/CatalogSearchResultPartItem';
import { validateQty } from '../CatalogSearchResultPartItem/helpers/validate';
import { usePartCategory } from '../PartCategory/PartCategoryContext';
import { usePartCategorySearch } from '../PartCategory/PartCategorySearchContext';
import AddParts from './AddParts';
import AddPartsCategories from './AddPartsCategories';
import CloudSearchGrid from './CloudSearchGrid';
import useAddParts from './hooks/useAddParts';
import PartsGridNotFound from './PartsGridNotFound';

const PartsGrid = forwardRef(({ onAddParts = () => {}, addItemsLoading = false }, ref) => {
  const { parts, selectedPartCategories, popupColor, noAssemblies, disablePartId } = usePartCategory();

  const [partsQty, setPartsQty] = useState({});
  const buttonSx = popupColor
    ? { width: '80px', ml: 'auto', color: popupColor, fontWeight: '500', fontSize: '15px' }
    : { width: '80px', ml: 'auto', fontWeight: '500', fontSize: '15px' };

  const {
    parts: cloudSearchParts,
    isSearching,
    searchKey,
    partsCount: cloudSearchPartsCount,
  } = usePartCategorySearch();

  const isMainCategoryLevel = useMemo(() => selectedPartCategories?.length === 1, [selectedPartCategories]);

  const handleChangePartQty = (part, qty) => {
    setPartsQty((prev) => ({ ...prev, [part.partId]: { qty } }));
  };

  const { addPart: handleAddPart, addParts: handleAddParts } = useAddParts({
    partsQty,
    onAddParts,
    setPartsQty,
    isSearching,
    cloudSearchParts,
    parts,
  });

  const addAllButtonEnabled = useMemo(
    () =>
      Boolean(
        Object.keys(partsQty).length &&
          Object.values(partsQty).filter((item) => item?.qty).length >= 2 &&
          Object.values(partsQty).filter((item) => !validateQty(item?.qty || '')).length === 0 &&
          !addItemsLoading,
      ),
    [partsQty, addItemsLoading],
  );

  useImperativeHandle(ref, () => ({
    parts: cloudSearchParts,
  }));

  return (
    <>
      {isSearching && (
        <Stack display="flex" flexDirection="column">
          <AddParts handleAddParts={handleAddParts} addAllButtonEnabled={addAllButtonEnabled} buttonSx={buttonSx} />
          <CloudSearchGrid
            partsQty={partsQty}
            handleChangePartQty={handleChangePartQty}
            handleAddPart={handleAddPart}
          />
          <PartsGridNotFound searchKey={searchKey} cloudSearchPartsCount={cloudSearchPartsCount} />
        </Stack>
      )}
      {!isSearching && !!selectedPartCategories.length && !isMainCategoryLevel && (
        <Stack display="flex" flexDirection="column">
          <AddPartsCategories
            handleAddParts={handleAddParts}
            addAllButtonEnabled={addAllButtonEnabled}
            buttonSx={buttonSx}
          />
          {!!parts?.length && (
            <Stack>
              {parts.map((part) => (
                <CatalogSearchResultPartItem
                  key={part.partId}
                  disabled={(noAssemblies && part.hasAssembly === '1') || part.partHistoryRefId === disablePartId}
                  disabledButton={addItemsLoading}
                  part={part}
                  partsQty={partsQty}
                  onChangeQty={handleChangePartQty}
                  onAddItem={handleAddPart}
                />
              ))}
            </Stack>
          )}
        </Stack>
      )}
    </>
  );
});

export default PartsGrid;
