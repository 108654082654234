/* eslint-disable max-lines-per-function */
import { useCallback, useMemo, useState } from 'react';

import { gql, useLazyQuery, useMutation } from '@apollo/client';

import { useUser } from 'app/UserContext';
import { GENERIC_MUTATION_ERROR } from 'constants/globalConstants';
import {
  addFolder as ADD_FOLDER,
  deleteFolder as DELETE_FOLDER,
  updateFolder as UPDATE_FOLDER,
} from 'graphql/mutations';
import { folders as FOLDERS } from 'graphql/queries';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

const ParentFolderQuery = (companyId, parentFolderId) => ({
  query: gql(FOLDERS),
  variables: {
    query: {
      companyId,
      parentFolderId,
    },
  },
});

const useFolderAPI = () => {
  const [folders, setFolders] = useState([]);
  const { handleResponse } = useGraphqlResponseHandler();
  const { user } = useUser();
  const companyId = useMemo(() => user?.companyId, [user]);

  const [fetchFoldersAPI, { data: foldersCache, loading: fetchFoldersLoading }] = useLazyQuery(gql(FOLDERS), {
    fetchPolicy: 'cache-and-network',
  });

  const [createFolderAPI, { loading: createFolderLoading }] = useMutation(gql(ADD_FOLDER));
  const [updateFolderAPI, { loading: updateFolderLoading }] = useMutation(gql(UPDATE_FOLDER));
  const [deleteFolderAPI, { loading: deleteFolderLoading }] = useMutation(gql(DELETE_FOLDER), {
    refetchQueries: ['Folders'],
  });

  const deleteFolder = ({ folderId }) =>
    handleResponse(
      deleteFolderAPI,
      {
        variables: { params: { folderId } },
      },
      {
        successMessage: 'Folder successfully deleted',
        errorMessage: GENERIC_MUTATION_ERROR,
      },
    );

  const updateFolder = useCallback(
    async ({ folderId, folderName, storagePath, folderDescription, parentFolderId }, options) => {
      await handleResponse(
        updateFolderAPI,
        {
          variables: {
            params: {
              folderId,
            },
            body: { companyId, folderName, storagePath, folderDescription, parentFolderId },
            awaitRefetchQueries: true,
          },
          refetchQueries: [ParentFolderQuery(companyId, parentFolderId)],
        },
        {
          successMessage: options?.successMessage ?? 'Folder successfully updated',
          errorMessage: options?.errorMessage ?? GENERIC_MUTATION_ERROR,
        },
      );
    },
    [handleResponse, companyId, updateFolderAPI],
  );
  // parentFolderRootId is a id of parent of parent to current folder
  const createFolder = useCallback(
    async ({ folderName, storagePath, folderDescription, parentFolderId }, parentFolderRootId, callback) => {
      const { errors, data } = await handleResponse(
        createFolderAPI,
        {
          variables: {
            body: { companyId, folderName, storagePath, folderDescription, parentFolderId },
          },
          refetchQueries: [
            ...(parentFolderRootId ? [ParentFolderQuery(companyId, parentFolderRootId)] : []),
            ParentFolderQuery(companyId, parentFolderId),
          ],
        },
        {
          successMessage: '',
          errorMessage: '',
        },
      );
      if (!errors) {
        const { folderId, storagePath } = data?.addFolder ?? {};
        if (folderId) {
          // Update folder path with the folder id
          await updateFolder(
            {
              folderId,
              folderName,
              storagePath: `${storagePath}/${folderId}`,
              folderDescription,
              parentFolderId,
            },
            {
              successMessage: 'Folder successfully created',
              errorMessage: GENERIC_MUTATION_ERROR,
            },
          );
          callback();
        }
      }
    },
    [handleResponse, companyId, createFolderAPI, updateFolder],
  );

  const fetchFoldersByParentFolder = useCallback(
    async (folderId) => {
      if (!folderId) return [];

      setFolders([]);

      const { data, error } = await fetchFoldersAPI({
        variables: {
          query: {
            companyId,
            parentFolderId: folderId,
          },
        },
      });
      if (error) {
        return [];
      }

      setFolders(data?.folders ?? []);
      return data?.folders;
    },
    [fetchFoldersAPI, companyId],
  );

  return {
    createFolder,
    createFolderLoading,
    folders,
    fetchFoldersByParentFolder,
    foldersCache: foldersCache?.folders ?? [],
    setFolders,
    deleteFolderAPI,
    deleteFolderLoading,
    deleteFolder,
    updateFolder,
    updateFolderLoading,
    fetchFoldersAPI,
    fetchFoldersLoading,
  };
};

export default useFolderAPI;
