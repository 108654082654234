import { MutationHookOptions, gql, useMutation } from '@apollo/client';

import { deleteWorkOrderItem as DELETE_WORK_ORDER_ITEM } from 'graphql/mutations';
import { DeleteWorkOrderItemMutation, DeleteWorkOrderItemMutationVariables } from 'graphql/types';

const useDeleteWorkOrderItem = (
  options: MutationHookOptions<DeleteWorkOrderItemMutation, DeleteWorkOrderItemMutationVariables>,
) =>
  useMutation<DeleteWorkOrderItemMutation, DeleteWorkOrderItemMutationVariables>(gql(DELETE_WORK_ORDER_ITEM), options);

export default useDeleteWorkOrderItem;
