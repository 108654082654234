import React, { useCallback } from 'react';

import { GRID_CHECKBOX_SELECTION_COL_DEF, GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro';
import { Waypoint } from 'react-waypoint';

import { Custom } from 'components/Icons';
import NoRowsOverlay from 'components/ItemsDatagridPro/NoRowsOverlay';
import MiniSpinner from 'components/MiniSpinner';
import useLazyPaginatedWorkOrderItems from 'hooks-api/queries/workOrderItems/useLazyPaginatedWorkOrderItems';

import QuantityCell from '../components/QuantityCell';
import StatusPointCell from '../components/StatusPointCell';
import ToggleOpenCell from '../components/ToggleOpenCell';
import UnitOfMeasureCell from '../components/UnitOfMeasureCell';
import UnitQuantityCell from '../components/UnitQuantityCell';
import WOItemMoreMenu from '../components/WOItemMoreMenu';
import WorkOrderItemIcon from '../components/WorkOrderItemIcon';
import WorkOrderItemNameCell from '../components/WorkOrderItemNameCell';
import { TableRowProvider } from '../context/TableRowContext';
import { useWorkOrderItemsContext } from '../context/WorkOrderItemsContext';
import RowFormWrapper from '../WorkOrderItemsTable/form/RowFormWrapper';

const LastRowComponent = ({ rowId }) => {
  const { workOrder } = useWorkOrderItemsContext();
  const { workOrderItems, loadingWorkOrderItems, fetchMore } = useLazyPaginatedWorkOrderItems(workOrder);

  const lastRow = workOrderItems.slice(-1);

  if (lastRow.length && rowId !== lastRow[0].workOrderItemId) return <></>;

  return (
    <>
      {loadingWorkOrderItems && <MiniSpinner showBorderBottom sx={{ borderBottomColor: 'rgba(224, 224, 224, 1)' }} />}
      {workOrderItems.length > 0 ? (
        <Waypoint key={workOrderItems.length} bottomOffset="-20%" onEnter={() => fetchMore(workOrderItems?.length)} />
      ) : null}
    </>
  );
};

// eslint-disable-next-line max-lines-per-function
export const useWorkOrderItemTableHelpers = (
  workOrder,
  handleOpenModalToCreateCatalogPart,
  handleOpenModalToDelete,
  setOpenModalUOM,
) => {
  const CustomRow = useCallback(
    (params) => (
      <TableRowProvider item={params?.row} renderLastRow={() => <LastRowComponent rowId={params.rowId} />}>
        <RowFormWrapper {...params} />
      </TableRowProvider>
    ),
    [],
  );

    const CustomNoRowsOverlay = useCallback(
        () => (
            <NoRowsOverlay>
                <NoRowsOverlay.Icon icon={<Custom.WorkOrders />} />
                <NoRowsOverlay.Text>Add a item to begin.</NoRowsOverlay.Text>
            </NoRowsOverlay>
        ),
        [],
    );

  const Components = {
    NoRowsOverlay: CustomNoRowsOverlay,
    Row: CustomRow,
  };

  const tableProps = {
    disableColumnMenu: true,
    disableSelectionOnClick: true,
    getRowId: (row) => row?.workOrderItemId,
    rowHeight: 32,
    getDetailPanelHeight: () => 'auto',
    components: Components,
    getRowClassName: (params) => (params.row.workOrderItemId === 0 ? 'MuiDataGrid-row-fixed' : ''),
    sortingMode: 'client',
    experimentalFeatures: { rowPinning: true },
  };

  const columns = [
    {
      field: 'workOrderItemStatusTypeId',
      headerName: ' ',
      minWidth: 40,
      width: 40,
      padding: 0,
      sortable: false,
      renderCell: ({ value, row }) => <StatusPointCell value={value} row={row} />,
    },
    { ...GRID_CHECKBOX_SELECTION_COL_DEF, type: '' },
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      minWidth: 32,
      maxWidth: 32,
      type: '',
      renderCell: ({ value, id }) => <ToggleOpenCell id={id} value={value} disabled={Boolean(!workOrder)} />,
    },
    {
      field: 'workOrderItemTypeId',
      headerName: '',
      minWidth: 32,
      maxWidth: 32,
      sortable: false,
        renderCell: ({ row }) => <WorkOrderItemIcon workOrder={row} />,
    },
    {
      field: 'workOrderItemName',
      headerName: 'Item',
      flex: 4.5,
      renderCell: () => <WorkOrderItemNameCell />,
    },
    {
      field: 'quantity',
      headerName: 'Qty',
      flex: 1.1,
      maxWidth: 97,
      renderCell: () => <QuantityCell />,
    },
    {
      field: 'unityQuantity',
      headerName: 'Unit Qty',
      flex: 1.4,
      maxWidth: 130,
      renderCell: () => <UnitQuantityCell />,
    },
    {
      field: 'unitOfMeasureId',
      headerName: 'UOM',
      flex: 3,
      renderCell: () => <UnitOfMeasureCell setOpenModalUOM={setOpenModalUOM} />,
    },
    {
      field: 'moreMenu',
      headerName: '',
      minWidth: 48,
      width: 48,
      sortable: false,
      renderCell: () => (
        <WOItemMoreMenu
          handleOpenModalToCreateCatalogPart={handleOpenModalToCreateCatalogPart}
          handleOpenModalToDelete={handleOpenModalToDelete}
        />
      ),
    },
  ];

  return { columns, tableProps };
};