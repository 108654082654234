import React, { useCallback } from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Custom } from 'components/Icons';
import { rightDrawerOverlayColors } from 'styles/theme/colors';

import { usePartCategory } from '../PartCategoryContext';
import { usePartCategorySearch } from '../PartCategorySearchContext';

const PartCategoriesBreadcrumbs = () => {
  const { selectedPartCategories, onSelectPartCategoryBreadcrumb } = usePartCategory();
  const { isSearching, searchBreadcrumbs, reset } = usePartCategorySearch();

  const handleSelectCategory = useCallback(
    (categoryIndex) => {
      if (categoryIndex === selectedPartCategories.length - 1) return;

      onSelectPartCategoryBreadcrumb(categoryIndex);
    },
    [selectedPartCategories, onSelectPartCategoryBreadcrumb],
  );

  const handleSearchBreadCrumbClick = useCallback(
    (index) => {
      if (index === 0) reset();
    },
    [reset],
  );

  return (
    <Stack flexDirection="row" alignItems="center" sx={{ my: 1 }}>
      {isSearching ? (
        <>
          {searchBreadcrumbs.map(({ index, searchKey }) => (
            <React.Fragment key={`${index}-${searchKey}`}>
              <BreadcrumbLabel
                isActive={Boolean(index)}
                variant="body1"
                onClick={() => handleSearchBreadCrumbClick(index)}
              >
                {searchKey}
              </BreadcrumbLabel>
              {!index && <BreadcrumbSeparator />}
            </React.Fragment>
          ))}
        </>
      ) : (
        <>
          {selectedPartCategories.map((category, index) => {
            const isLast = index === selectedPartCategories.length - 1;
            return (
              <React.Fragment key={category?.partCategoryId}>
                <BreadcrumbLabel isActive={isLast} variant="body1" onClick={() => handleSelectCategory(index)}>
                  {category?.partCategoryName}
                </BreadcrumbLabel>
                {!isLast && <BreadcrumbSeparator />}
              </React.Fragment>
            );
          })}
        </>
      )}
    </Stack>
  );
};

export default PartCategoriesBreadcrumbs;

const BreadcrumbLabel = ({ children, disabled = false, isActive = false, onClick = () => {} }) => {
  const { popupColor } = usePartCategory();
  const hoverStyles = popupColor
    ? { color: popupColor, textDecorationLine: 'underline' }
    : { color: 'secondary.main', textDecorationLine: 'underline' };
  return (
    <Typography
      variant="body1"
      color={isActive ? 'surface.darkSurface.primary' : 'surface.darkSurface.secondary'}
      sx={{
        '&:hover': {
          cursor: 'pointer',
          ...(!disabled && hoverStyles),
        },
      }}
      onClick={onClick}
    >
      {children}
    </Typography>
  );
};

const BreadcrumbSeparator = () => (
  <Custom.KeyboardArrowRightIcon
    style={{
      width: '20px',
      height: '20px',
      marginLeft: '8px',
      marginRight: '8px',
      fill: rightDrawerOverlayColors.action,
    }}
  />
);
