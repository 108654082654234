import { useCallback } from 'react';

import { gql, useLazyQuery, useMutation } from '@apollo/client';

import { FETCH_POLICIES, GENERIC_MUTATION_ERROR } from 'constants/globalConstants';
import { deleteWorkCellTaskType as DELETE_WORK_CELL_TASK } from 'graphql/mutations';
import { workCellTaskType as WORK_CELL_TASKS } from 'graphql/queries';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

import { useDataContext } from '../../../contexts/DataContext';
import useCachedData from '../../../hooks/useCachedData';

export default function useWorkCellTasksDataLayer(workCell) {
  const { activeRow, setActiveRow } = useDataContext();
  const { handleResponse } = useGraphqlResponseHandler();
  const { disableWorkCellTasksContainerCache } = useCachedData();
  const [getWorkCellTasks, { data, loading: loadingWorkCellTasks }] = useLazyQuery(
    gql(WORK_CELL_TASKS),
    FETCH_POLICIES.CACHE_AND_NETWORK,
  );

  const [unmapWorkCellTaskMutation] = useMutation(gql(DELETE_WORK_CELL_TASK), {
    refetchQueries: ['WorkCellTaskType', 'MachineTaskType'],
  });

  const fetchWorkCellTasks = useCallback(async () => {
    const response = await getWorkCellTasks({
      variables: { query: { workCellId: workCell.workCellId } },
    });

    return response?.data?.workCellTaskType;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const unmapWorkCellTask = async (task) =>
    handleResponse(
      unmapWorkCellTaskMutation,
      {
        variables: {
          params: {
            workCellTaskTypeId: task.workCellTaskTypeId,
          },
        },
      },
      {
        successMessage: 'Task successfully unmapped',
        errorMessage: GENERIC_MUTATION_ERROR,
      },
      (result) => {
        if (result.deleteWorkCellTaskType) {
          if (task.workCellTaskTypeId === activeRow.id) setActiveRow({});
          disableWorkCellTasksContainerCache(workCell.workCellId, task.workCellTaskTypeId);
        }
      },
    );

  return {
    workCellTasks: data?.workCellTaskType || [],
    fetchWorkCellTasks,
    loadingWorkCellTasks,
    unmapWorkCellTask,
  };
}
