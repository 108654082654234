import React, { useCallback, useEffect, useState } from 'react';

import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { Stack, useTheme, Typography, Box } from '@mui/material';

import { useUser } from 'app/UserContext';
import { ReactComponent as ShopTasksThumbnailIcon } from 'assets/images/module-icons/thumbnails/task-type-thumbnail-icons/shop-task-type.svg';
import { getDocumentPresignedURL as GET_DOCUMENT_PRESIGNED_URL } from 'graphql/mutations';
import { documentById as DOCUMENT_BY_ID } from 'graphql/queries';
import { EModuleType } from 'types/module';

import { getThemeByModule } from '../styles/theme/theme';
import MoreMenu from './MoreMenu';
import OverFlowTooltip from './Tooltip/OverFlowTooltip';

const moduleThumbnailIcons = {
  shop: <ShopTasksThumbnailIcon />,
  field: <ShopTasksThumbnailIcon />,
  materials: <ShopTasksThumbnailIcon />,
  procurement: <ShopTasksThumbnailIcon />,
};

const TaskTypeCard = ({ taskTypeImageId, taskTypeName, taskTypeDescription, taskTypeClass, options, sx }) => {
  const [taskTypeClassTheme, setTaskTypeClassTheme] = useState(useTheme());
  const [showThreeDotsMenu, setShowThreeDotsMenu] = useState(false);
  const [image, setImage] = useState(null);

  const { user } = useUser();
  const [fetchDocument] = useLazyQuery(gql(DOCUMENT_BY_ID));
  const [getPresignedURL] = useMutation(gql(GET_DOCUMENT_PRESIGNED_URL));

  const fetchTaskTypeImage = useCallback(async () => {
    if (!taskTypeImageId) {
      setImage(null);
      return;
    }

    const document = await fetchDocument({
      variables: { params: { documentId: taskTypeImageId } },
    });

    if (!document?.data?.documentById) return;

    const presignedURLResponse = await getPresignedURL({
      variables: {
        body: {
          expirationHours: 24,
          objectKey: `${document?.data?.documentById.storagePath}/${document.data.documentById.documentName}`,
          requestedBy: user.userId,
          verb: 'GET',
        },
      },
    });

    const presignedURL = presignedURLResponse.data?.getDocumentPresignedURL?.preSignedURL;

    if (presignedURL) setImage(presignedURL);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskTypeClass, taskTypeImageId]);

  useEffect(() => fetchTaskTypeImage(), [fetchTaskTypeImage]);

  useEffect(() => {
    const theme = getThemeByModule(EModuleType[taskTypeClass.toUpperCase()]);
    setTaskTypeClassTheme(theme);
  }, [taskTypeClass]);

  const handleOnMouseOver = () => setShowThreeDotsMenu(true);

  const handleOnMouseLeave = () => setShowThreeDotsMenu(false);

  return (
    <StyledTaskTypeCard
      taskTypeClassTheme={taskTypeClassTheme}
      onMouseOver={() => handleOnMouseOver()}
      onMouseLeave={() => handleOnMouseLeave()}
      sx={sx}
    >
      <StyledTaskNodeThumbnailContainer noPadding={Boolean(image)}>
        {image ? (
          <img
            src={image}
            alt={taskTypeName}
            width="38px"
            height="46px"
            style={{ borderRadius: '3px 0 0 3px', textIndent: '-9999px' }}
          />
        ) : (
          moduleThumbnailIcons[taskTypeClass.toLowerCase()]
        )}
      </StyledTaskNodeThumbnailContainer>
      <Stack sx={{ flex: 1, width: 'calc(100% - 54px)' }}>
        <Box sx={{ height: '23px', display: 'flex', alignItems: 'center' }}>
          <OverFlowTooltip title={taskTypeName}>
            <Typography
              data-testid="taskNodeName"
              variant="caption"
              sx={{ color: taskTypeClassTheme.palette?.secondary?.main, fontWeight: 400 }}
            >
              {taskTypeName}
            </Typography>
          </OverFlowTooltip>
        </Box>

        <TaskTypeDescription>{taskTypeDescription}</TaskTypeDescription>
        {options && showThreeDotsMenu && (
          <MoreMenu
            style={{
              position: 'absolute',
              top: '5px',
              right: '-8px',
              height: '13px',
            }}
            iconProps={{ disableRipple: true }}
            options={options}
          />
        )}
      </Stack>
    </StyledTaskTypeCard>
  );
};

export default TaskTypeCard;

const TaskTypeDescription = ({ children }) => (
  <Typography
    variant="body2"
    sx={{
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxHeight: '1.5em',
      whiteSpace: 'nowrap',
      fontWeight: 400,
    }}
  >
    {children}
  </Typography>
);

const StyledTaskTypeCard = ({ sx, taskTypeClassTheme, ...rest }) => (
  <Stack
    sx={{
      height: 48,
      width: 232,
      border: '1px solid',
      borderRadius: '4px',
      borderColor: taskTypeClassTheme?.palette?.secondary?.main,
      backgroundColor: taskTypeClassTheme?.palette?.secondary?.light,
      padding: 0,
      flexDirection: 'row',
      gap: '16px',
      position: 'relative',
      overflow: 'hidden',
      ...sx,
    }}
    {...rest}
  />
);

const StyledTaskNodeThumbnailContainer = ({ noPadding, ...rest }) => (
  <Box
    sx={{
      width: 38,
      height: 46,
      backgroundColor: '#FFF', // TODO some cards may not have white background
      paddingLeft: noPadding ? 0 : '10px',
      paddingTop: noPadding ? 0 : '10px',
      borderTopLeftRadius: '4px',
      borderBottomLeftRadius: '4px',
      '& img[alt]': {
        color: 'transparent',
      },
    }}
    {...rest}
  />
);
