import React from 'react';

import { Stack } from '@mui/material';

import PartItemDetailCategory from './PartItemDetailCategory';
import PartItemDetailName from './PartItemDetailName';

const PartItemDetail = ({ children, sx }) => (
  <Stack sx={{ padding: '8px 16px', gap: '16px', ...sx }}>{children}</Stack>
);

PartItemDetail.PartItemDetailCategory = PartItemDetailCategory;
PartItemDetail.PartItemDetailName = PartItemDetailName;

export default PartItemDetail;
