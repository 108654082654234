import React from 'react';

import { Stack } from '@mui/material';

const RowComponent = ({ children }: { children: any }) => (
  <Stack direction="row" alignItems="center" sx={{ pr: 1.5, position: 'relative' }}>
    {children}
  </Stack>
);

export default RowComponent;
