export const getParamsObj = (query) => {
  const paramObj = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const value of query.keys()) {
    paramObj[value] = query.get(value);
  }
  return paramObj;
};

export const getParamsWihNewValue = (query, newValues) => {
  const auxQuery = { ...getParamsObj(query), ...newValues };
  return `?${new URLSearchParams(auxQuery).toString()}`;
};
