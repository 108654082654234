import React from 'react';

import { Button } from '@mui/material';

import { DeleteButtonProps } from './@types/buttons';

const DeleteButton = ({
  disabled,
  sx,
  onClick,
  buttonText = 'DELETE',
  ...props
}: DeleteButtonProps) => (
  <Button
    variant="contained"
    color="error"
    disabled={disabled}
    sx={{ marginLeft: '8px', fontWeight: 500, ...sx }}
    onClick={onClick}
    data-cy="delete-button"
    data-testid="delete-button"
    {...props}
  >
    {buttonText}
  </Button>
);

export default DeleteButton;
