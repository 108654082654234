import React from 'react';

import { POSITION_TITLE } from 'constants/globalConstants';

import PositionTitle from '../components/PositionTitle';
import TaskWrWoAttachments from '../components/TaskWrWoAttachments';
import { WorkRequestSectionTypes } from './@types/workRequestsTypes';

const WorkRequestSection = ({ documents, positionTitle, section }: WorkRequestSectionTypes) => (
  <>
    {positionTitle === POSITION_TITLE.workRequests && <PositionTitle />}

    <TaskWrWoAttachments documents={documents} section={section} />
  </>
);

export default WorkRequestSection;
