import React from 'react';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import { grey } from '@mui/material/colors';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';

import useSignOut from 'modules/Authentication/auth/useSignOut';

import DesktopProfileDropdownContent from './DesktopProfileDropdownContent';
import LicenseCheckDropdownContent from './LicenseCheckDropdownContent/LicenseCheckDropdownContent';

const DesktopProfileDropdown = ({
  closeModals,
  openModal,
  activeModal,
  dropdownIsOpen,
  licenseCheckIsOpen,
  goBackToDropdown,
  anchorRef,
}) => {
  const signOut = useSignOut();

  const handleSignOut = async () => {
    await signOut();
  };

  return (
    <DesktopProfileDropdownWrapper
      open={dropdownIsOpen || licenseCheckIsOpen}
      onClose={closeModals}
      anchorEl={anchorRef}
    >
      {dropdownIsOpen && (
        <DesktopProfileDropdownContent
          onResetPasswordClicked={() => openModal(activeModal.RESET_PASSWORD)}
          onTwoFactorAuthClicked={() => openModal(activeModal.AUTHENTICATION)}
          onLicenseCheckInOutClicked={() => openModal(activeModal.LICENSE_CHECK)}
          onEditProfileClicked={() => openModal(activeModal.EDIT_PROFILE)}
          signOut={handleSignOut}
        />
      )}

      {licenseCheckIsOpen && <LicenseCheckDropdownContent onBack={goBackToDropdown} />}
    </DesktopProfileDropdownWrapper>
  );
};

export default DesktopProfileDropdown;

const DesktopProfileDropdownWrapper = ({ open, anchorEl, children, onClose }) => (
  <ClickAwayListener onClickAway={onClose}>
    <Popper open={open} anchorEl={anchorEl} role={undefined} transition placement="bottom-end">
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} style={{ transformOrigin: 'top right' }}>
          <Paper
            sx={{
              marginTop: '18px',
              backgroundColor: grey[800],
              color: grey[500],
            }}
          >
            {children}
          </Paper>
        </Grow>
      )}
    </Popper>
  </ClickAwayListener>
);
