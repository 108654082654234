import React from 'react';

import { StatusBadge } from '@evolvemep/foresite-components-ui-react';
import dayjs, { Dayjs } from 'dayjs';

type Props = {
  label:
    | 'Draft'
    | 'Transferred'
    | 'Completed'
    | 'Rejected'
    | 'Submitted'
    | 'Pending'
    | 'Approved'
    | 'In Progress'
    | 'Canceled';
  variant: string;
  needBy: Dayjs;
};

const WorkCellStatus = ({ label, variant, needBy }: Props) => {
  const daysRemaining = needBy.diff(dayjs(), 'day');
  const newVariant = daysRemaining < 0 && label !== 'Completed' ? 'pastDaysRemaining' : variant;
  return <StatusBadge variant={newVariant} label={label} />;
};

export default WorkCellStatus;
