import React from 'react';

import { Typography, Stack } from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import ApproveAction from './ApproveAction/ApproveAction';
import SubmitAction from './SubmitAction';

export const columnWorkRequest = (
  CellComponentLink,
  CellComponentStatus,
  isCompleted,
  CellComponentDays,
  CellWorkRequestItemAttachments,
  CellComponentMoreMenu,
  selectedItem,
  CellComponentWRName,
) => [
  {
    field: 'workRequestIdentifier',
    headerName: 'Work Request Id',
    renderCell: CellComponentLink,
    width: '180',
    sortable: true,
  },
  {
    field: 'workRequestName',
    headerName: 'Work Request Name',
    flex: 4,
    sortable: true,
    renderCell: CellComponentWRName,
  },
  {
    field: selectedItem.type === 'PROJECT' ? 'facilityName' : 'projectName',
    headerName: selectedItem.type === 'PROJECT' ? 'Facility Name' : 'Project Name',
    flex: 4,
    sortable: true,
    renderCell: CellComponentWRName,
  },
  {
    field: 'workRequestStatusName',
    headerName: 'Status',
    renderCell: CellComponentStatus,
    flex: 4,
    sortable: true,
    minWidth: 94,
  },
  {
    field: 'needBy',
    headerName: 'Need By',
    flex: 3,
    sortable: true,
    valueFormatter: (params) => {
      dayjs.extend(utc);
      if (!params.value) return null;
      return dayjs(`${params.value}Z`).local().format('MM/DD/YYYY');
    },
  },
  {
    field: 'daysRemaining',
    renderHeader: () =>
      isCompleted ? (
        <Stack direction="row">
          <Typography variant="tableCellBold"> Days Early/</Typography>
          <Typography variant="tableCellBold" sx={{ color: 'error.main' }}>
            Late
          </Typography>
        </Stack>
      ) : (
        <Typography variant="tableCellBold">Days Remaining</Typography>
      ),
    renderCell: CellComponentDays,
    flex: 4,
    sortable: false,
  },
  {
    field: 'workRequestId',
    headerName: '',
    renderCell: CellWorkRequestItemAttachments,
    flex: 4,
    sortable: true,
  },
  {
    field: 'actions',
    type: 'actions',
    sortable: false,
    resizable: false,
    disableReorder: true,
    getActions: ({ row }) => [
      <SubmitAction workRequest={row} />,
      <ApproveAction workRequest={row} />,
      <CellComponentMoreMenu sx={{
        width: '24px!important',
      }} row={row} />,
    ],
  },
];
