import { v4 as uuidv4 } from 'uuid';

export const createLocation = (type) => ({
  locationName: '',
  locationId: uuidv4(),
  locationIdentifier: '',
  locationTypeName: type,
  isEditing: true,
  isNew: true,
});
