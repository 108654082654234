import { useCallback, useMemo } from 'react';

import { gql, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { GENERIC_MUTATION_ERROR } from 'constants/globalConstants';
import { VARIANT_ERROR } from 'constants/snackbarConstants';
import { updateDocument as UPDATE_DOCUMENT } from 'graphql/mutations';
import { documents as DOCUMENTS } from 'graphql/queries';
import useDocumentTypes from 'modules/Shop/Fabrication/TaskViewer/PlansModelsContent/hooks/useDocumentTypes';

import { useDrawingRegisterContext } from '../DrawingRegisterContext';
import { useNotificationMsg } from './snackBarMessages';

const useFolderDocuments = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { getMoveToFolderMsg } = useNotificationMsg();
  const { sortModel, selectedFolder, navigateToFolder } = useDrawingRegisterContext();
  const { drawingModelCNCIds } = useDocumentTypes();
  const parentFolderDocumentsQuery = useMemo(
    () => ({
      query: gql(DOCUMENTS),
      variables: {
        query: {
          folderIds: selectedFolder?.folderId,
          orderBy: sortModel,
          documentTypeIds: drawingModelCNCIds,
        },
      },
    }),
    [selectedFolder, sortModel, drawingModelCNCIds],
  );

  const [updateDocument] = useMutation(gql(UPDATE_DOCUMENT), {
    refetchQueries: [parentFolderDocumentsQuery],
    awaitRefetchQueries: true,
  });

  const onGoToFolder = useCallback(
    (folderId) => {
      navigateToFolder(folderId);
    },
    [navigateToFolder],
  );

  const moveDocuments = useCallback(
    async (documentIds, folderId) => {
      try {
        const responses = await Promise.all(
          documentIds.map(async (documentId) => {
            const res = await updateDocument({
              variables: {
                params: { documentId },
                body: {
                  folderId,
                  moveToFolder: true,
                },
              },
              onError: () => {
                enqueueSnackbar(GENERIC_MUTATION_ERROR, {
                  preventDuplicate: true,
                  ...VARIANT_ERROR,
                });
              },
            });
            return res;
          }),
        );
        const folderName = responses[0].data?.updateDocument.folderDocumentResponse[0].folderName ?? '';
        getMoveToFolderMsg(documentIds, folderName, () => onGoToFolder(folderId));
      } catch {
        enqueueSnackbar(GENERIC_MUTATION_ERROR, {
          preventDuplicate: true,
          ...VARIANT_ERROR,
        });
      }
    },
    [getMoveToFolderMsg, updateDocument, enqueueSnackbar, onGoToFolder],
  );

  return { moveDocuments };
};

export default useFolderDocuments;
