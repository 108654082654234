import React, { createContext, useState, useContext } from 'react';

const AssignationModeContext = createContext();

export const AssignationModeProvider = ({ children }) => {
  const chipSelectedState = useState();
  const assignationModeState = useState(false);
  const [chipSelected] = chipSelectedState;

  const getLocationId = () => assignationModeState && chipSelected?.locationId;
  const getWorkphaseId = () => assignationModeState && chipSelected?.workPhaseId;

  const states = {
    chipSelectedState,
    assignationModeState,
  };

  return (
    <AssignationModeContext.Provider value={{ states, getLocationId, getWorkphaseId }}>
      {children}
    </AssignationModeContext.Provider>
  );
};

export const useAssignationModeContext = () => {
  const context = useContext(AssignationModeContext);
  if (context === undefined) {
    throw new Error('useAssignationModeContext must be used within a AssignationModeContext');
  }
  return context;
};
