import useLBSAPI from 'hooks-api/useLBSAPI';

import { useModal } from '../contexts/ModalContext';
import useRefetchBOMItemsChildren from './useRefetchBOMItemsChildren';

const useLineItemQuantity = () => {
  const { ern, project, lineItem } = useModal();

  const { handleFetchPatchBulk2LBS, loadingPatchBulk2LBS } = useLBSAPI(ern);
  const { getBOMItemsChildrenIds } = useRefetchBOMItemsChildren();

  const updateLBSBulk = async (amounts) => {
    if (!amounts?.length) return true;

    const childrenIds = await getBOMItemsChildrenIds(lineItem?.lineItemId);

    const response = await handleFetchPatchBulk2LBS(amounts, {
      refetchQueries: ['BillOfMaterialItem'],
      awaitRefetchQueries: true,
      onQueryUpdated(observableQuery) {
        const observableQueryItem = observableQuery.getLastResult()?.data?.billOfMaterialItem[0];
        const isASubChild = childrenIds.includes(observableQueryItem?.lineItemId);

        const query = observableQuery?.last?.variables?.query;
        const isMainBomItemRow = query?.parentLineItemIds === undefined && query?.projectId === project.projectId;

        return isASubChild || isMainBomItemRow;
      },
    });

    const successUpdate = Boolean(response?.data?.patchBulk2BillOfMaterialLineItemByLBS);

    return successUpdate;
  };

  return { loadingPatchBulk2LBS, updateLBSBulk };
};

export default useLineItemQuantity;
