import React, { FC, useEffect } from 'react';

import { useForm } from 'react-hook-form';

import { useUser } from 'app/UserContext';
import useGeneralContext from 'helpers/useGeneralContext';
import useFetchFacilities from 'hooks-api/useFetchFacilities';
import useFetchProjectById from 'hooks-api/useFetchProjectById';
import { useWorkRequestModalCreate } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestModalCreate/useWorkRequestModalCreate';

import { INewWRFormProvider, NewWRFormContextType } from '../../../@types/newWRForm';
import { useCurrentProjectContext } from '../../../context/CurrentProjectContext';
import { useItemsIntegrationModalContext } from '../../../context/ItemsIntegrationModalContext';

const initialFormValues = {
  ProjectId: '',
  FacilityId: { value: '', label: '' },
  NeedBy: null,
  WorkRequestName: '',
  WorkRequestDescription: '',
};
const NewWRFormContext = React.createContext<NewWRFormContextType | undefined>(undefined);

const NewWRFormProvider: FC<INewWRFormProvider> = ({ children }: INewWRFormProvider) => {
  const methods = useForm({
    mode: 'all',
    defaultValues: initialFormValues,
  });
  const { currentProject } = useCurrentProjectContext();
  const { user } = useUser();
  const { project } = useFetchProjectById(currentProject?.id);
  const { setCompleted, setSelectionModel } = useItemsIntegrationModalContext();
  const { facilities } = useFetchFacilities({
    companyId: user.companyId,
    hasShopDepartment: true,
    showAvailableFacilities: true,
  });

  const {
    submit,
    loading: loadingAddWR,
    defaultFacility,
    onCloseModal,
    addWorkRequestData,
  } = useWorkRequestModalCreate({
    project,
    initialFormValues,
    reset: methods.reset,
    onClose: () => {},
    redirect: false,
  });

  useEffect(() => {
    if (!defaultFacility) return;
    methods.setValue('FacilityId', { value: defaultFacility.facilityId, label: defaultFacility.facilityName });
  }, [defaultFacility, methods]);

  useEffect(() => {
    if (!addWorkRequestData) return;
    setSelectionModel([addWorkRequestData?.addWorkRequest?.workRequestId]);
    setCompleted({ 0: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addWorkRequestData]);

  const valueObj: NewWRFormContextType = {
    methods,
    loadingAddWR,
    submit,
    onCloseModal,
    defaultFacilityId: defaultFacility?.FacilityId,
    project,
    facilities,
    addWorkRequestData,
  };
  return <NewWRFormContext.Provider value={valueObj}>{children}</NewWRFormContext.Provider>;
};

const useNewWRFormContext = (): NewWRFormContextType => useGeneralContext(NewWRFormContext, 'NewWRFormContext');

export { NewWRFormContext, NewWRFormProvider, useNewWRFormContext };
