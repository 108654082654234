import { v4 as uuidv4 } from 'uuid';

import { EIconType } from 'helpers/MenuIcons';

export const MAX_CHIP_DEPTH = 7;

export const ROW_TYPES = {
  LOCATION: 'Location',
  PROJECT: 'Project',
  SCOPE_PACKAGE: 'Scope Package',
  WORK_PHASE: 'Work Phase',
  ZONE: 'Zone',
  FLOOR: 'Floor',
  LOCATION_SYSTEM: 'Location System',
};

export const PREFIX_ICONS = {
  [ROW_TYPES.LOCATION]: EIconType.LOCATION,
  [ROW_TYPES.PROJECT]: EIconType.FOLDER,
  [ROW_TYPES.SCOPE_PACKAGE]: EIconType.TARGET,
  [ROW_TYPES.WORK_PHASE]: EIconType.SAND_WATCH,
  [ROW_TYPES.ZONE]: EIconType.ZONE,
  [ROW_TYPES.FLOOR]: EIconType.FLOOR,
  [ROW_TYPES.LOCATION_SYSTEM]: EIconType.LOCATION_SYSTEM,
};

const WORK_PHASE_MOCK = {
  locationTypeName: ROW_TYPES.WORK_PHASE,
  scopePackage: '',
  workPhase: [],
  isDraft: true,
  depth: 1,
};

const LOCATION_MOCK = {
  locationTypeName: '',
  locationFormatId: 'Alpha',
  identifierPrefix: '',
  identifierDelimiter: '',
  startOn: '',
  namePrefix: '',
  nameDelimiter: '',
  numberOfNodes: '',
  isDraft: true,
  depth: 1,
};

const formTemplate = (locationTypeName) => ({
  locationTypeName,
  locationFormatId: [ROW_TYPES.FLOOR, ROW_TYPES.ZONE].includes(locationTypeName) ? 'Alpha' : '',
  nameDelimiter: '-',
});

const RESET_FORM_KEYS = [
  'numberOfNodes',
  'identifierPrefix',
  'identifierDelimiter',
  'startOn',
  'nameDelimiter',
  'scopePackage',
  'workPhase',
  'locationFormatId',
];

export const buildChipTemplate = (isWorkPhase) => ({
  ...(isWorkPhase ? WORK_PHASE_MOCK : LOCATION_MOCK),
  id: uuidv4(),
});

export const buildFormTemplate = (chipType) => formTemplate(chipType);

export const cleanExtraFormFields = (setValue) => RESET_FORM_KEYS.forEach((key) => setValue(key, ''));
