import React, { useContext } from 'react';

import { Box, Stack, Typography } from '@mui/material';

import { CenteredLoadSpinner } from 'components';
import NavigationHeader from 'modules/Shop/Fabrication/TaskViewer/components/NavigationHeader';
import { TaskViewerContext } from 'modules/Shop/Fabrication/TaskViewer/TaskViewerContext';

const LoadingDocument = () => {
  const taskViewerContext = useContext(TaskViewerContext);
  const { activeDocument } = taskViewerContext || {};

  return (
    <Stack height="100%">
      {activeDocument && <NavigationHeader />}
      <Stack
        sx={{
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          paddingBottom: '20%',
          '@media screen and ( max-height: 700px )': {
            paddingBottom: 'unset !important',
          },
        }}
      >
        <Box sx={{ textAlign: 'center', maxWidth: '402px' }}>
          <Box>
            <CenteredLoadSpinner theme="secondary" />
          </Box>
          <Typography variant="h6" sx={{ color: 'surface.lightSurface.secondary', letterSpacing: '0.15px' }}>
            Loading...please wait.
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );
};

export default LoadingDocument;
