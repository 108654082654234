import { useEffect } from 'react';

import { mapInformation } from './mapInformation';

export function useEffectThatUpdatesWorkRequestList(
  workRequests,
  setWorkRequestsList,
  setDisabledNew,
  setDisabledFilters,
  filters,
  selectedItem,
) {
  useEffect(() => {
    if (!workRequests?.length) {
      setWorkRequestsList([]);
    }
    if (workRequests?.length > 0) {
      setDisabledNew(false);
      setDisabledFilters(false);
      const filteredWorkRequests = workRequests.filter((workRequest) =>
        filters.includes(workRequest.workRequestStatusName),
      );
      setWorkRequestsList(filteredWorkRequests.map(mapInformation));
    }
  }, [
    workRequests,
    setDisabledFilters,
    setDisabledNew,
    filters,
    setWorkRequestsList,
    selectedItem,
  ]);
}
