/* eslint-disable import/no-cycle */
import React, { useCallback, useEffect, useMemo } from 'react';

import { CircularProgress } from '@mui/material';

import { BillOfMaterialItem } from 'graphql/types';

import { useBOMbyIdContext } from '../BOMbyIdContext';
import BOMCatalogTable from './BOMCatalogTable';
import useGetFetchItemsFunction from './hooks/useGetFetchItemsFunction';

enum addedStatus {
  adding = 'adding',
  added = 'added',
  cancelled = 'cancelled'
}

type BOMItemsTableProps = {
  parentLineItemId: string;
  parentIsSelected: boolean;
  pl: number;
  addSubItem: boolean;
  onSubItemAdded?: (lineItemId: string, addedStatus: addedStatus) => void;
  setAddSubItem: (addSubItem: boolean) => void;
};

const getLineItemId = (billOfMaterialItem: BillOfMaterialItem) => billOfMaterialItem.lineItemId;

const BOMItemsTable = ({
  parentLineItemId = '',
  parentIsSelected: parentIsSelectedOld,
  pl = 0,
  addSubItem,
  onSubItemAdded,
}: BOMItemsTableProps) => {
  const { pagination, result, setSelectedPadItems, onChangeSortKey, bomLoading, refetchItems } =
    useGetFetchItemsFunction({ parentLineItemId });

  const { selectedPadItems } = useBOMbyIdContext();

  const parentIsSelected = useMemo(
    () =>
      parentIsSelectedOld ||
      selectedPadItems?.some(
        (billOfMaterialItem: BillOfMaterialItem) => billOfMaterialItem.lineItemId === parentLineItemId,
      ),
    [parentIsSelectedOld, parentLineItemId, selectedPadItems],
  );

  const getTableProps = useCallback(
    (isSelected: boolean) => {
      if (!isSelected) return { selectionModel: selectedPadItems?.map(getLineItemId) };
      return {
        selectionModel: (result ?? []).map(getLineItemId),
        onSelected: () => null,
      };
    },
    [result, selectedPadItems],
  );

  useEffect(() => {
    if (parentIsSelected) return;
    setSelectedPadItems([]);
  }, [parentIsSelected, setSelectedPadItems]);

  const originalRows = useMemo(() => result ?? [], [result]);
  const loading = useMemo(() => (parentLineItemId ? false : bomLoading), [bomLoading, parentLineItemId]);
  const subItemsPl = useMemo(() => pl + 2, [pl]);

  const props = useMemo(
    () => ({
      onChangeSortKey,
      refetchItems,
      pagination,
    }),
    [onChangeSortKey, pagination, refetchItems],
  );

  const handleSubItemAdded = useCallback(
    (added: boolean) =>
      onSubItemAdded && onSubItemAdded(parentLineItemId, added ? addedStatus.added : addedStatus.cancelled),
    [onSubItemAdded, parentLineItemId],
  );

  const subItemHasNoRows = !bomLoading && !originalRows?.length && !addSubItem && parentLineItemId;
  const subItemLoadingRows = bomLoading && !originalRows?.length && !addSubItem && parentLineItemId;
  if (subItemLoadingRows) return <CircularProgress color="secondary" />;
  if (subItemHasNoRows) return <></>;

  return (
    <BOMCatalogTable
      {...props}
      pl={subItemsPl}
      loading={loading}
      rows={originalRows}
      tableProps={getTableProps(parentIsSelected)}
      parentIsSelected={parentIsSelected}
      setSelectedPadItems={setSelectedPadItems}
      addWriteInRow={addSubItem}
      onSubItemAdded={handleSubItemAdded}
      parentLineItemId={parentLineItemId}
    />
  );
};

export default React.memo(BOMItemsTable);
