import { WorkOrder } from 'graphql/types';

import getNewIndexForWorkOrder from '../getNewIndexForWorkOrder';

export const getNewWorkOrdersSortOrder = (
  sourceList: Array<WorkOrder>,
  sourceIndex: number,
  destinationIndex: number,
) => {
  if (!sourceList?.length) return [];
  const sourceArray = [...sourceList];
  const newIndex = getNewIndexForWorkOrder(sourceArray, destinationIndex);
  const [removed] = sourceArray.splice(sourceIndex, 1);
  sourceArray.splice(destinationIndex, 0, removed);

  return changeWorkOrderSortOrder(sourceArray, {
    ...removed,
    sortOrder: newIndex,
  });
};

const changeWorkOrderSortOrder = (workOrders: Array<WorkOrder>, workOrderUpdated: WorkOrder): Array<WorkOrder> => {
  const workOrderMoved = workOrders.map((workOrder) => {
    if (workOrder.workOrderId === workOrderUpdated?.workOrderId) workOrder = { ...workOrderUpdated };
    return workOrder;
  });

  const newWorkOrderSO = workOrderMoved.reduce<Array<WorkOrder>>((workOrders, currentWorkOrder) => {
    const workOrderHaveNonValidSorOrderValues =
      currentWorkOrder?.sortOrder == null || workOrderUpdated?.sortOrder == null;
    if (workOrderHaveNonValidSorOrderValues) return [...workOrders, currentWorkOrder];

    const shouldIncreaseSortOrder =
      (currentWorkOrder?.sortOrder === workOrderUpdated?.sortOrder &&
        currentWorkOrder.workOrderId !== workOrderUpdated?.workOrderId) ||
      workOrders.find((workOrder) => workOrder?.sortOrder === currentWorkOrder?.sortOrder);

    if (currentWorkOrder?.sortOrder != null && shouldIncreaseSortOrder)
      return [...workOrders, { ...currentWorkOrder, sortOrder: currentWorkOrder?.sortOrder + 1 }];

    return [...workOrders, currentWorkOrder];
  }, []);

  return newWorkOrderSO;
};
