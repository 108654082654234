import React from 'react';

import { Stack, Modal } from '@mui/material';

import { useSendToBomModal } from '../SendToBomModalContext';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';

const ModalForItemQty = () => {
  const { isModalOpen, onCloseModal, bom } = useSendToBomModal();

  const styles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 688,
    height: 520,
    borderRadius: '5px',
    backgroundColor: 'white',
  };

  const stopPropagationForTab = (event) => {
    if (event.key === 'Tab') event.stopPropagation();
  };

  if (!bom) return <></>;
  return (
    <Modal open={isModalOpen} onClose={onCloseModal} onKeyDown={stopPropagationForTab}>
      <Stack sx={styles}>
        <ModalHeader />
        <ModalBody />
        <ModalFooter />
      </Stack>
    </Modal>
  );
};

export default ModalForItemQty;
