import React from 'react';

import { useGridApiContext } from '@mui/x-data-grid-pro';
import { useFormContext } from 'react-hook-form';

import { useBOMbyIdContext } from 'modules/Materials/BillOfMaterialsById/BOMbyIdContext';
import { QuantityInput } from 'modules/Materials/TakeOff/Form';
import QuantityCell from 'modules/Materials/TakeOff/TakeOffPadTab/EditQuantityModal/components/QuantityCell';

import { QTYNumberInput } from '../../Form';
import { BOMCatalogTableFields, BOMCatalogTableHeaders, getItemHasParent, isItemAPart } from './BOMTableColumnsHelpers';

export const getQuantityColumn = (editingQuantityRowData, handleClickEditQuantity) => ({
  useForm: true,
  field: BOMCatalogTableFields.QUANTITY,
  headerName: BOMCatalogTableHeaders[BOMCatalogTableFields.QUANTITY],
  flex: 1,
  editable: true,
  resizable: true,
  renderEditCell: () => <QuantityEditCell />,
  renderCell: ({ row, value, id }) => (
    <QuantityDisplayCell
      value={value}
      id={id}
      row={row}
      editingQuantityRowData={editingQuantityRowData}
      handleClickEditQuantity={handleClickEditQuantity}
    />
  ),
});

const QuantityEditCell = () => {
  const { control, getValues, formState } = useFormContext();

  const itemHasParent = getItemHasParent(getValues('parentLineItemId'));
  const isLineItemPartItem = isItemAPart(getValues('lineItemTypeId'));

  if (itemHasParent) return <></>;

  return (
    <QTYNumberInput
      name="quantity"
      placeholder="Qty required"
      autoFocus={isLineItemPartItem}
      control={control}
      disabled={formState.isSubmitting}
    />
  );
};

const QuantityDisplayCell = ({ row, value, id }) => {
  const { editingQuantityRowData, setEditingQuantityRowData } = useBOMbyIdContext();

  const { control } = useFormContext();
  const apiRef = useGridApiContext();
  const isEditing = editingQuantityRowData?.lineItemId === id;

  const itemHasParent = getItemHasParent(row?.parentLineItem);

  const handleClickEditQuantity = (lineItemId) => {
    if (editingQuantityRowData) return;
    if (itemHasParent) return;
    const rows = Array.from(apiRef?.current?.getRowModels().values());
    setEditingQuantityRowData(rows.find((data) => data.lineItemId === lineItemId));
  };

  if (isEditing) return <QuantityInput control={control} autoFocus defaultValue={value} isEditingQuantity />;

  return (
    <QuantityCell
      onClick={(event) => {
        handleClickEditQuantity(id);
        event.stopPropagation();
      }}
      value={value}
      isEditable={!itemHasParent}
    />
  );
};
