/* eslint-disable no-console */
import React, { forwardRef } from 'react';

import { RightSideFlyout } from 'components';
import { Custom } from 'components/Icons';
import { RIGHT_SIDE_FLYOUT_SECTIONS, SESSION_STORAGE_CONSTANTS } from 'constants/globalConstants';
import { useMaterialsConfig } from 'hooks-api/useMaterialsConfig';
import CatalogPopup from 'modules/Materials/components/CatalogPopup/CatalogPopup';

import { getManufacturerIdByName } from '../commons/helpers';
import { useAssignationModeContext } from '../features/assignation-mode';
import { useBOMbyIdContext } from './BOMbyIdContext';
import { filterLineItemsWithDecisions } from './BOMCatalogTable/Decisions/helpers';

const { CURRENT_BOM } = SESSION_STORAGE_CONSTANTS;

const BillOfMaterialsFlyoutMenu = forwardRef((props, ref) => {
  const { manufactured, unitOfMeasure } = useMaterialsConfig();
  const {
    onAddWriteInItems,
    decisionsStatesAndCallbacks: { updateLineItemsAdded, lineItemDecision, setCatalogItemsPendingToAdd },
    addWriteInItemLoading,
    assignModeOn,
    chipList,
  } = useBOMbyIdContext();
  const { getLocationId, getWorkphaseId } = useAssignationModeContext();

  const BOMStatus = JSON.parse(sessionStorage.getItem(CURRENT_BOM))?.billOfMaterialsStatusName === 'Closed';

  const handleAddItems = async (items, hasAlreadyDecisions) => {
    const bomItems = items.map((item) => {
      const unitOfMeasureId = unitOfMeasure.find(({ code }) => code === item.unitOfMeasureCode)?.value;
      return {
        lineItemTypeId: item.lineItemTypeId,
        manufacturerId: getManufacturerIdByName(item.manufacturerName, manufactured),
        unitOfMeasureId,
        quantity: Number(item.qty),
        partId: item.partId,
        manufacturerCatalogNumber: item.catalogNumber ?? '',
        locationId: getLocationId(),
        workPhaseId: getWorkphaseId(),
        lineItemName: item.partName,
      };
    });

    if (hasAlreadyDecisions) {
      setCatalogItemsPendingToAdd(bomItems);
      return;
    }

    const newCatalogItems = await onAddWriteInItems(bomItems);

    if (chipList.length !== 0 && !assignModeOn) return;

    filterLineItemsWithDecisions(newCatalogItems?.data?.addBillOfMaterialsWriteInItem, updateLineItemsAdded);
  };

  const flyoutItems = {
    staticList: [
      {
        isDisabled: BOMStatus,
        section: RIGHT_SIDE_FLYOUT_SECTIONS.CATALOG,
        icon: Custom.MenuBook,
        children: ({ onPanelClose, onDock, hasAlreadyDecisions, addItemsLoading }) => (
          <CatalogPopup
            ref={ref}
            onClose={onPanelClose}
            onDock={onDock}
            onAddItems={handleAddItems}
            addItemsLoading={addItemsLoading}
            hasAlreadyDecisions={hasAlreadyDecisions}
          />
        ),
      },
    ],
  };

  const onDock = () => console.log('docking');

  return (
    <RightSideFlyout
      flyoutItems={flyoutItems}
      onDock={onDock}
      hasAlreadyDecisions={!!lineItemDecision}
      addItemsLoading={!!addWriteInItemLoading}
    />
  );
});

export default BillOfMaterialsFlyoutMenu;
