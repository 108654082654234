import { useCallback, useEffect, useState } from 'react';

import { RECORDING_STATES } from 'constants/globalConstants';
import { Task, Time } from 'graphql/types';
import useHandleUpdateTask from 'hooks/task/useHandleUpdateTask/useHandleUpdateTask';

type TimeData = {
  time: string | null;
  events: any[];
};

export default function useProductionTime(taskId: string, task: Task) {
  const [timeData, setTimeData] = useState<TimeData>({ time: null, events: [] });
  const [taskStatus, setTaskStatus] = useState<string>();

  const { handleUpdateTaskStatus: updateTaskStatus, loading: loadingUpdateTask } = useHandleUpdateTask();

  const handleUpdateStatusBadge = (status: string) => {
    setTaskStatus(status === RECORDING_STATES.Completed ? RECORDING_STATES.Completed : RECORDING_STATES.In_progress);
  };

  const handleUpdateRecordEvents = (newTime: Time) => {
    setTimeData({ events: newTime?.events || [], time: newTime?.totalTime as string });
  };

  const handleUpdateVisualState = useCallback((firstEvent: string) => setTaskStatus(firstEvent), []);

  const handleUpdateTaskStatus = async (newStatus: string) => {
    const { data, errors } = (await updateTaskStatus(taskId, newStatus)) as any;

    if (!data?.updateTask) return {};

    handleUpdateStatusBadge(newStatus);
    handleUpdateRecordEvents(data?.updateTask?.time);

    return { data, errors };
  };

  useEffect(() => {
    let isMounted = true;
    if (timeData?.events?.length > 0 && isMounted) {
      const firstEvent = timeData?.events[0];
      handleUpdateVisualState(firstEvent?.description);
    }

    return () => {
      isMounted = false;
    };
  }, [handleUpdateVisualState, timeData?.events]);

  useEffect(() => {
    let isMounted = true;
    if (task && isMounted) {
      if (task.taskStatusTypeName) handleUpdateVisualState(task.taskStatusTypeName);
      if (task?.time?.totalTime) handleUpdateRecordEvents(task?.time);
    }

    return () => {
      isMounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);

  return {
    timeData,
    taskStatus,
    loadingUpdateTask,
    handleUpdateTaskStatus,
  };
}
