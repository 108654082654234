import { useCallback } from 'react';

import { gql, useMutation } from '@apollo/client';

import { addDocument as ADD_DOCUMENT } from 'graphql/mutations';
import { documents as DOCUMENTS } from 'graphql/queries';
import useLazyPaginatedQuery from 'hooks/useLazyPaginatedQuery';

export const TAKE_DOCUMENT_NUMBER = 10;

const useDocumentAPI = () => {
  const [{ lazyLoad, paginationHandler, refetch, refetchExistingPages }, { data, loading }] = useLazyPaginatedQuery(
    gql(DOCUMENTS),
    'cache-and-network',
    TAKE_DOCUMENT_NUMBER,
    false,
  );

  const fetchDocumentsByFolder = useCallback(
    async (query) => {
      await lazyLoad(query);
    },
    [lazyLoad],
  );

  const [addDocument, { loading: addDocumentLoading }] = useMutation(gql(ADD_DOCUMENT));

  const addDocumentToFolder = useCallback(
    async ({ filename, storagePath, folder, documentType }) => {
      const [name, extension] = filename.split(/\.(?=[^\\.]+$)/);
      return addDocument({
        variables: {
          body: {
            folderId: folder?.folderId,
            documentTypeId: documentType.documentTypeId,
            documentIdentifier: '',
            documentName: name,
            extension: `.${extension}`,
            storagePath,
          },
        },
      });
    },
    [addDocument],
  );

  return {
    documents: data?.documents ?? [],
    fetchDocumentsByFolder,
    addDocumentToFolder,
    paginationHandler,
    refetch,
    refetchExistingPages,
    loading,
    addDocumentLoading,
  };
};

export default useDocumentAPI;
