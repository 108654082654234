import React, { useEffect, useState } from 'react';

import { CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';

import { Custom } from 'components/Icons';
import useFetchIsTheLastVersion from 'hooks-api/useFetchIsTheLastVersionDocument';
import { errorColors, successColors } from 'styles/theme/colors';

import DrawingStatus from './DrawingStatus';

const getDocumentId = (id) =>
  id.replace(/^ern:docmgt:version:/, '').replace(/\?view=checkForLatest$/, '');

const DrawingStatusController = () => {
  const [loading, setLoading] = useState(true);
  const [isUpToDate, setIsUpToDate] = useState(false);
  const { id } = useParams();
  const { getDocumentIsLastVersion } = useFetchIsTheLastVersion(getDocumentId(id));

  useEffect(() => {
    const getData = async () => {
      const data = await getDocumentIsLastVersion(getDocumentId(id));
      setLoading(false);
      if (!data) {
        setIsUpToDate(false);
        return;
      }

      setIsUpToDate(data?.isLatestVersion || false);
    };
    getData();
  }, [getDocumentIsLastVersion, id]);

  if (loading) return <CircularProgress />;

  const message = isUpToDate ? 'The drawing is up to date' : 'The drawing is out of date';

  return (
    <DrawingStatus
      Icon={<DrawingStatusIcon isUpToDate={isUpToDate} />}
      messageUpToDate={message}
      link="/auth/login"
    />
  );
};

export default DrawingStatusController;

const DrawingStatusIcon = ({ isUpToDate }) => {
  if (isUpToDate)
    return (
      <Custom.CheckCircleOutline
        style={{ fill: successColors.main, width: '40px', height: '40px' }}
      />
    );

  return <Custom.Info style={{ fill: errorColors.main, width: '40px', height: '40px' }} />;
};
