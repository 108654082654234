import React from 'react';

import { IconButton } from '@mui/material';
import {
  gridDetailPanelExpandedRowsContentCacheSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';

import { Custom } from 'components/Icons';

const CustomDetailPanelToggle = ({ id, value: isExpanded, isDisabled = false, sx = {} }) => {
  const apiRef = useGridApiContext();

  const contentCache = useGridSelector(apiRef, gridDetailPanelExpandedRowsContentCacheSelector);

  const hasDetail = React.isValidElement(contentCache[id]);

  if (!hasDetail) return null;

  return (
    <IconButton
      size="medium"
      tabIndex={-1}
      aria-label={isExpanded ? 'Close' : 'Open'}
      sx={{ pointerEvents: isDisabled ? 'none' : '', padding: '4px', ...sx }}
    >
      <Custom.ArrowDropDownIcon
        sx={{
          transform: `rotateZ(${isExpanded ? 0 : -90}deg)`,
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shortest,
            }),
        }}
        color={isExpanded ? 'primary' : 'palette.surface.lightSurface.secondary'}
        fontSize="inherit"
      />
    </IconButton>
  );
};

export default CustomDetailPanelToggle;
