import React, { useEffect } from 'react';

import { CircularProgress, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';

import { useCategoriesContext } from '../CategoriesContext';
import CategoryName from './CategoryName';

const CategoryForm = ({ parentCategory, isEditing, onClose }) => {
  const {
    handleSubmit,
    control,
    reset,
    setFocus,
    setError,
    formState: { isValid, isSubmitting },
  } = useForm({
    mode: 'all',
    defaultValues: {
      parentPartCategoryId: parentCategory?.partCategoryId,
      categoryName: isEditing ? parentCategory?.partCategoryName : '',
      isEditing,
    },
  });
  const { onSubmit } = useCategoriesContext();

  useEffect(() => {
    setFocus('categoryName');
  }, [setFocus]);

  const submitValues = async (e) => {
    if (!isValid) return;
    if (!e.target.value) {
      onClose();
      return;
    }
    await handleSubmit(onSubmit)();
    reset();
    onClose();
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <Stack direction="row" sx={{ alignItems: 'center', width: '100%', gap: 2 }}>
      {isSubmitting && <CircularProgress color="secondary" size={25} />}
      <CategoryName
        control={control}
        name="categoryName"
        handleBlur={submitValues}
        defaultValue={isEditing ? parentCategory?.partCategoryName : ''}
        setError={setError}
        parentPartCategoryId={
          isEditing
            ? parentCategory?.parentPartCategory?.partCategoryId
            : parentCategory?.partCategoryId
        }
        onClose={handleClose}
      />
    </Stack>
  );
};

export default CategoryForm;
