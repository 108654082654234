import { useCallback } from 'react';

import { gql, useLazyQuery, useMutation } from '@apollo/client';

import { FETCH_POLICIES } from 'constants/globalConstants';
import { addMachine as ADD_MACHINE } from 'graphql/mutations';
import { workCellMachine as WORK_CELL_MACHINE, machines as MACHINES } from 'graphql/queries';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

import { useDataContext } from '../../../contexts/DataContext';
import { buildMachineBody, getFacilityId } from '../../../helpers/helpers';
import useCachedData from '../../../hooks/useCachedData';

export default function useWorkCellMachineDataLayer(workCell) {
  const { handleResponse } = useGraphqlResponseHandler();
  const { selectedItem, setActiveRow } = useDataContext();
  const { enableWorkCellMachinesContainerCache } = useCachedData();

  const [getWorkCellMachinesQuery, { data, loading: loadingWorkCellMachines }] = useLazyQuery(
    gql(WORK_CELL_MACHINE),
    FETCH_POLICIES.CACHE_AND_NETWORK,
  );

  const [getMachinesQuery, { loading: loadingMachines }] = useLazyQuery(
    gql(MACHINES),
    FETCH_POLICIES.CACHE_AND_NETWORK,
  );

  const [addMachineMutation, { loading: loadingAddMachine }] = useMutation(gql(ADD_MACHINE), {
    refetchQueries: ['WorkCellMachine', 'Machine'],
    update(_, { data: result }) {
      if (result.addMachine) {
        enableWorkCellMachinesContainerCache(workCell.workCellId);
        setActiveRow({ isMachineCreated: true, ...result.addMachine });
      }
    },
  });

  const fetchWorkCellMachines = useCallback(async () => {
    const response = await getWorkCellMachinesQuery({
      variables: {
        query: {
          workCellId: workCell.workCellId,
          facilityId: getFacilityId(selectedItem),
          orderBy: 'machineIdentifier:asc',
        },
      },
    });

    return response?.data?.workCellMachine;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchMachines = useCallback(async () => {
    const response = await getMachinesQuery({
      variables: {
        query: {
          facilityId: getFacilityId(selectedItem),
        },
      },
    });

    return response?.data?.machines;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addMachine = async (machineIdentifier) => {
    const machine = {
      machineIdentifier,
      workCellId: workCell.workCellId,
    };

    return handleResponse(
      addMachineMutation,
      { variables: buildMachineBody(machine, selectedItem) },
      { successMessage: 'Machine successfully added' },
    );
  };

  return {
    workCellMachines: data?.workCellMachine || [],
    fetchWorkCellMachines,
    loadingWorkCellMachines,
    loadingAddMachine,
    loadingMachines,
    fetchMachines,
    addMachine,
  };
}
