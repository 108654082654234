import React from 'react';

import { Box, Stack, Tabs } from '@mui/material';

import { Custom } from 'components/Icons';

import { PAD_ID, PAD_LABEL_NAME, useTakeOff } from '../../context/TakeOffContext';
import AddIconButton from '../AddIconButton';
import TabArrowButton from '../TabArrowButton';
import TabItem from '../TabItem';
import ControlledTabNameInput from '../TabNameInput/ControlledTabNameInput';
import { useTabPadsContext } from './TabPadsContext';
import useTabPadsActions from './useTabPadsActions';

const TabsComponent = ({ separator, keyPress, control }) => {
  const { sourcePadsQty, projectId, pads, padIndex } = useTakeOff();
  const { handleTabChange, saveName, cancelEdit, handleTextChange, resetForm, nameRule } = useTabPadsActions();
  const { addNewPad, setAddNewPad, savingNewPad, autoFocus, currentPadSelected, nameIsEditable, options } =
    useTabPadsContext();

  return (
    <Box sx={{ maxWidth: 'calc(100vw - 620px)' }}>
      <Tabs
        variant="scrollable"
        selectionFollowsFocus
        value={padIndex}
        onChange={handleTabChange}
        scrollButtons
        allowScrollButtonsMobile
        TabIndicatorProps={{
          style: { display: 'none' },
        }}
        sx={{
          '.MuiTabs-flexContainer': {
            height: '30px',
          },
          minHeight: '30px !important',
          height: '30px',
          padding: 0,
          margin: 0,
        }}
        ScrollButtonComponent={(props) => <ScrollButtons props={props} />}
      >
        {pads.map((pad, index) => {
          if (pad?.isSourcePad) {
            return (
              <TabItem
                key={pad?.[PAD_ID]}
                labelName={pad?.[PAD_LABEL_NAME]}
                item={pad}
                isSelected={index === padIndex}
                currentIndex={index}
                handleChange={handleTabChange}
                showSeparator={separator && index === sourcePadsQty - 1}
              />
            );
          }

          return (
            <TabItem
              items={pads.filter((item) => currentPadSelected?.takeoffPadId !== item?.takeoffPadId)}
              options={options}
              labelName={pad?.[PAD_LABEL_NAME]}
              item={pad}
              isSelected={index === padIndex}
              key={pad?.[PAD_ID]}
              currentIndex={index}
              handleChange={handleTabChange}
              itemSelected={currentPadSelected?.takeoffPadId}
              id={pad?.takeoffPadId}
              nameIsEditable={nameIsEditable}
              saveName={saveName}
              labelKey={PAD_LABEL_NAME}
              cancelEdit={cancelEdit}
            />
          );
        })}
        {addNewPad && (
          <ControlledTabNameInput
            disabled={!projectId}
            resetForm={resetForm}
            control={control}
            handleKeyPressNewPad={keyPress}
            handleTextChange={handleTextChange}
            hideAddNewPad={() => setAddNewPad(false)}
            editable={!savingNewPad}
            hasError={nameRule.error}
            skipError={nameRule.skip}
            autoFocus={autoFocus}
          />
        )}
      </Tabs>
    </Box>
  );
};

export default TabsComponent;

const ScrollButtons = ({ props }) => {
  const { showAddNewPad } = useTabPadsActions();
  const { addNewPad } = useTabPadsContext();
  const { projectId } = useTakeOff();

  return (
    <>
      {props.direction === 'left' && (
        <TabArrowButton {...props} left>
          <Custom.KeyboardArrowLeft />
        </TabArrowButton>
      )}
      {props.direction === 'right' && (
        <Stack direction="row">
          <AddIconButton
            scroll={props}
            isInputAvailable={addNewPad}
            showAddNewPad={showAddNewPad}
            disabled={!projectId}
          />
          <TabArrowButton {...props} right>
            <Custom.KeyboardArrowRight1 />
          </TabArrowButton>
        </Stack>
      )}
    </>
  );
};
