import React, { forwardRef } from 'react';

import { RightSideFlyout, RightSideFlyoutPaper } from 'components';
import { Custom } from 'components/Icons';

import { useWorkRequestsContext } from '../../WorkRequestsContext';
import { WorkRequestPropertiesAttachmentsProvider } from '../../WorRequestsProperties/WorkRequestPropertiesAttachments/WorkRequestPropertiesAttachmentsContext';
import { NoWorkRequestOverlay } from './NoWorkRequestOverlay';
import { PropertiesPopupHeader } from './PropertiesPopupHeader';
import { FlyoutWorkRequestDetails } from './WorkRequestDetails';

interface propTypes {
  onPanelClose: () => void;
  onDock: () => void;
}

const PopupElement = ({ onPanelClose, onDock }: propTypes) => (
  <PropertiesPopup onPanelClose={onPanelClose} onDock={onDock} />
);

const WorkRequestListFlyoutMenu = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ isHideOptions = false }: { isHideOptions: boolean }, ref) => {
    const flyoutItems = {
      staticList: isHideOptions
        ? []
        : [
            {
              section: 'Properties',
              icon: Custom.Settings,
              children: PopupElement,
            },
          ],
    };
    return <RightSideFlyout flyoutItems={flyoutItems} fixed />;
  },
);

export default WorkRequestListFlyoutMenu;

const PropertiesPopup = ({ onPanelClose, onDock }: propTypes) => {
  const { workRequestSelected, isWorkOrder } = useWorkRequestsContext();
  return (
    <WorkRequestPropertiesAttachmentsProvider>
      <RightSideFlyoutPaper
        leading={<PropertiesPopupHeader />}
        onClose={onPanelClose}
        onDock={onDock}
        width={500}
        sx={{ padding: 0 }}
        divider
        useXButton
        topRightActionsSx={{}}
      >
        {workRequestSelected?.workRequestName ? (
          <FlyoutWorkRequestDetails />
        ) : (
          <NoWorkRequestOverlay itemName={isWorkOrder ? 'work order' : undefined} />
        )}
      </RightSideFlyoutPaper>
    </WorkRequestPropertiesAttachmentsProvider>
  );
};
