import React from 'react';

export const QTY_FIELD_CONTEXT_DEFAULT_VALUES = {
  setShowDeleteButton: () => {},
  isReadOnly: false,
  qtyFieldRef: null,
  control: null,
  fieldName: '',
  showArrows: false,
  fieldValue: '',
  isBomLineItem: false,
  currentNestLevel: 0,
  isParentEditable: false,
  setShowArrows: () => {},
  setDeleteBtnClicked: () => {},
  setValue: () => {},
  fieldId: '',
  lineItemId: '',
  projectBreakdownStructure: '',
  showDeleteButton: false,
  deleteBtnClicked: false,
  qtyAggregate: 0,
  isScopePackage: false,
  isWorkPhase: false,
  qtyRemaining: 0,
  getValues: () => {},
  rangeErrorMessage: '',
  locationId: '',
  name: '',
  rowIdentifier: '',
  quantityField: null,
  quantityFieldChildren: [],
  quantityFieldChildrenByParentId: [],
};

export const ERROR_ID_MAX_LENGTH = 10;

export const NOT_VALUES_SET = 'NOT_VALUES_SET';

export const INPUT_MEMOIZED_COMPONENT_STATIC_PROPS: any = {
  shouldUnregister: false,
  showHelperText: false,
  placeholder: 'Qty',
  size: 'small',
  inputMode: 'numeric',
  onKeyDown: (event: React.MouseEvent<HTMLElement>) => event.stopPropagation(),
  width: '74px',
  autoComplete: 'off',
};

export const QTY_FIELD_INPUT_PROPS_STYLE = {
  height: '24px',
  padding: '0 4px 0 12px',
  fontSize: '14px',
  margin: 0,
};

export const QTY_LIMIT_EXCEEDED_ERROR_MESSAGE = 'Quantity limit exceeded';
