import React, { createContext, useContext } from 'react';

import { IWorkflowEditorContext } from './@types/WorkfowsEditorTypes';

const WorkflowEditorContext = createContext<IWorkflowEditorContext>({ loading: false });

const WorkflowEditorProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <WorkflowEditorContext.Provider value={{ loading: false }}>
    {children}
  </WorkflowEditorContext.Provider>
);

const useWorkflowEditor = () => {
  const context = useContext(WorkflowEditorContext);
  if (context === undefined) {
    throw new Error('useWorkflowEditor must be used within a WorkflowEditorContext');
  }
  return context;
};

export { WorkflowEditorContext, WorkflowEditorProvider, useWorkflowEditor };
