import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import PasswordConfirmationForm from 'app/Layout/HeaderBar/ProfileDropdown/ResetPasswordModal/PasswordConfirmationForm';

const SetPasswordForm = ({ onSubmit, buttonLabel, loading }) => {
  const {
    watch,
    handleSubmit,
    control,
    trigger,
    formState: { errors },
  } = useForm({ mode: 'all' });

  return (
    <Box sx={{ width: '326px' }}>
      <form data-testid="password-set-form" onSubmit={handleSubmit(onSubmit)}>
        <PasswordConfirmationForm
          control={control}
          watch={watch}
          errors={errors}
          trigger={trigger}
        />
        <Buttons loading={loading} buttonLabel={buttonLabel} />
      </form>
    </Box>
  );
};

export default SetPasswordForm;

const Buttons = ({ loading, buttonLabel }) => (
  <>
    <Box mt="45px" mb="25px">
      <Button
        sx={{
          '&:hover': {
            background: '#434A90',
          },
        }}
        disabled={loading}
        fullWidth
        type="submit"
        variant="contained"
        color="secondary"
        password-reset
      >
        {buttonLabel}
      </Button>
    </Box>
    <Button component={Link} to="/auth/login" fullWidth>
      RETURN TO LOGIN
    </Button>
  </>
);
