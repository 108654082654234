import React, { forwardRef, useCallback, useMemo } from 'react';

import { Stack, Typography, Snackbar, IconButton } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { Custom } from 'components/Icons';

import { useTakeOff } from './context/TakeOffContext';
import TakeOffPadTable from './TakeOffTable';

const TakeOffPadPage = forwardRef((props, ref) => {
  const {
    projectId,
    pads,
    padIndex,
    padItems,
    onAddPad,
    bomName,
    openSnackbar,
    selectedPadItems,
    updateOpenSnackbar,
    onFetchMoreSourcePadItems,
    onFetchMoreTakeOffPadItems,
    isPadItemsLoading,
  } = useTakeOff();

  const methods = useForm({ mode: 'all' });

  const activePad = useMemo(() => pads?.[padIndex], [padIndex, pads]);

  const handleClose = (e, reason) => {
    if (reason === 'clickaway') updateOpenSnackbar(true);
    else if (reason === 'escapeKeyDown') updateOpenSnackbar(true);
    else updateOpenSnackbar(false);
  };

  const skip = padItems?.length ?? 0;
  const handleFetchMore = useCallback(() => {
    if (!activePad) return;
    const fetchMoreItems = activePad.isSourcePad ? onFetchMoreSourcePadItems : onFetchMoreTakeOffPadItems;
    fetchMoreItems(skip);
  }, [onFetchMoreSourcePadItems, onFetchMoreTakeOffPadItems, skip, activePad]);

  const overLayMessage = useMemo(() => {
    if (!projectId) {
      return 'Start by opening a project';
    }
    if (!pads?.length) {
      return 'Start by naming your takeoff pad and adding items';
    }
    return 'No Data';
  }, [projectId, pads]);

  return (
    <>
      <TakeOffPadTable
        loading={isPadItemsLoading}
        ref={ref}
        isSourcePad={activePad?.isSourcePad}
        text={overLayMessage}
        data={padItems}
        methods={methods}
        onFetchMore={handleFetchMore}
        onAddPad={onAddPad}
      />
      <Snackbar
        open={openSnackbar}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={5000}
      >
        <Stack justifyContent="center" alignItems="center" sx={{ backgroundColor: '#45a543', borderRadius: '5px' }}>
          <Custom.CheckCircleOutline style={{ fill: 'white', margin: '16px 8px 16px 16px' }} />
          <Typography sx={{ color: 'white', marginRight: '16px' }}>
            {`${selectedPadItems.length} items successfully sent to BOM`}
          </Typography>
          <Link
            component={Link}
            to={`/materials/bill-of-materials/${bomName.current.id}`}
            style={{ textDecoration: 'none' }}
            onClick={() => localStorage.setItem('currentBOMName', bomName.current.name)}
          >
            <Typography sx={{ color: 'white', fontWeight: 500 }}>
              {`GO TO ${bomName.current.name.toUpperCase()} BOM`}
            </Typography>
          </Link>
          <IconButton onClick={() => updateOpenSnackbar(false)} sx={{ padding: 0 }}>
            <Custom.Close style={{ fill: 'white', margin: '16px' }} />
          </IconButton>
        </Stack>
      </Snackbar>
    </>
  );
});

export default React.memo(TakeOffPadPage);
