import React from 'react';

import { DragDropContext } from 'react-beautiful-dnd';

import ComponentPaneLayout from 'app/Layout/ReactGridLayout/ComponentPaneLayout';

import { useDataContext } from './WorkCellsAndMachines/contexts/DataContext';
import useDragEvents from './WorkCellsAndMachines/hooks/useDragEvents';
import WorkCellsAndMachines from './WorkCellsAndMachines/WorkCellsAndMachines';
import WorkCellsAndMachinesFlyoutMenu from './WorkCellsAndMachinesFlyoutMenu';
import WorkCellsAndMachinesProperties from './WorkCellsAndMachinesProperties/WorkCellsAndMachinesProperties';

const WorkCellsAndMachinesLayout = () => {
  const { setIsDragging } = useDataContext();
  const { onDragEnd, onDragStart } = useDragEvents(setIsDragging);

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <ComponentPaneLayout sizes={[1.32, 1]} flyoutMenu={<WorkCellsAndMachinesFlyoutMenu />}>
        <WorkCellsAndMachines />
        <WorkCellsAndMachinesProperties />
      </ComponentPaneLayout>
    </DragDropContext>
  );
};

export default WorkCellsAndMachinesLayout;
