import React, { useState, useEffect } from 'react';

import { gql } from '@apollo/client';

import { useUser } from 'app/UserContext';
import { INCLUDE_DELETED_OPTIONS } from 'constants/globalConstants';
import { users as USERS } from 'graphql/queries';
import useLazySearchableQuery from 'hooks/useLazySearchableQuery';

const AdminContext = React.createContext();

const AdminProvider = ({ children }) => {
  const { user } = useUser();

  const [selected, setSelected] = useState([]);
  const [showDeletedUsers, setShowDeletedUsers] = useState(false);
  const [inviteModalShown, setInviteModalShown] = useState(false);
  const [companyId, setCompanyId] = useState();

  const openInviteModal = () => setInviteModalShown(true);
  const closeInviteModal = () => setInviteModalShown(false);

  const [{ lazyLoad, searchHandler, sortHandler, paginationHandler }, { data, loading }] =
    useLazySearchableQuery(gql(USERS), {
      extraVars: {
        companyId: companyId || user.companyId,
        includeDeleted: showDeletedUsers ? INCLUDE_DELETED_OPTIONS.YES : null,
        isAdmin: true,
      },
      fetchPolicy: 'cache-and-network',
    });

  useEffect(() => {
    lazyLoad(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (companyId || user.companyId) lazyLoad(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDeletedUsers, companyId]);

  const selectedStateObj = React.useMemo(
    () => ({
      selected,
      setSelected,
      showDeletedUsers,
      setShowDeletedUsers,
      lazyLoad,
      paginationHandler,
      searchHandler,
      sortHandler,
      loading,
      data,
      inviteModalShown,
      openInviteModal,
      closeInviteModal,
      companyId,
      setCompanyId,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      selected,
      setSelected,
      showDeletedUsers,
      setShowDeletedUsers,
      loading,
      data,
      inviteModalShown,
      companyId,
      setCompanyId,
    ],
  );

  return <AdminContext.Provider value={selectedStateObj}>{children}</AdminContext.Provider>;
};

const useAdminContext = () => {
  const context = React.useContext(AdminContext);
  if (context === undefined) {
    throw new Error('useAdminContext must be used within an AdminContext');
  }
  return context;
};

export { AdminContext, AdminProvider, useAdminContext };
