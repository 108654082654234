import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import Modal from 'components/Modal';
import { useEntitlementCreate } from 'hooks-api/useEntitlementCreate';

import { useCompanyMembersContext } from '../CompanyMembersContext';
import AssignEntitlementsForm from './AssignEntitlementsForm';

const AddEntitlementsModal = ({ selectedUsers, open, onClose }) => {
  const { doEntitlementCreate, loading } = useEntitlementCreate();
  const { setSelected } = useCompanyMembersContext();
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({ mode: 'all' });

  const submit = ({ entitlements }) => {
    const userIds = selectedUsers?.map((user) => user.userId);
    const productPoolIds = entitlements?.map((value) => value.productPoolId);

    doEntitlementCreate({
      userIds,
      productPoolIds,
    })
      .then(() => {
        enqueueSnackbar('Successfully added!', { variant: 'success' });
        setSelected([]);
        onClose();
      })
      .catch((errors) => {
        enqueueSnackbar(errors, { variant: 'error' });
      });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Add Licenses"
      reducePadding
      fullWidth
      maxWidth="xs"
      footer={
        <>
          {loading && <CircularProgress />}
          <Button color="primary" onClick={onClose}>
            CANCEL
          </Button>
          <Button
            data-cy="assign-button"
            color="secondary"
            variant="contained"
            sx={{ marginLeft: '8px' }}
            onClick={handleSubmit(submit)}
            disabled={!isValid || loading}
          >
            ASSIGN
          </Button>
        </>
      }
    >
      <AssignEntitlementsForm control={control} />
    </Modal>
  );
};

export default AddEntitlementsModal;
