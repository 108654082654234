export const getManufacturerIdByName = (name = 'Generic', manufacturers = []) => {
  const manufacturer = manufacturers.find((item) => item?.label === name);
  return manufacturer?.id;
};

export const getUnitOfMeasureIdByCode = (code = '', unitOfMeasures = []) => {
  const unitOfMeasure = unitOfMeasures.find((item) => item.code === code);
  return unitOfMeasure?.value;
};

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);
