import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';

import { PasswordCheck } from 'components';
import PasswordControlField from 'components/FormComponents/PasswordControlField';
import {
  confirmPasswordRules,
  CURRENT_PASSWORD_RULES,
  PASSWORD_RULES,
} from 'constants/inputFieldRules';

const PasswordConfirmationForm = ({
  watch,
  validateCurrentPassword,
  showRulesOnInit,
  errors,
  trigger,
  ...props
}) => {
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [passwordIsDirty, setPasswordIsDirty] = useState(false);
  const password = watch('password');
  const confirmPassword = watch('confirm');

  const handleShowRules = () => password || showRulesOnInit;
  const handleShowErrors = () => passwordFocus || (showRulesOnInit && !passwordIsDirty);

  const revalidateConfirmationIsTheSame = async () => {
    await trigger('confirm');
  };

  useEffect(() => {
    if (password && confirmPassword) revalidateConfirmationIsTheSame();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password]);

  return (
    <>
      {validateCurrentPassword && <CurrentPasswordInput {...props} />}
      <NewPasswordInput
        password={password}
        isFocused={passwordFocus}
        hasError={errors.password?.message}
        onFocus={() => setPasswordFocus(true)}
        onBlur={() => {
          setPasswordFocus(false);
          setPasswordIsDirty(true);
        }}
        {...props}
      />
      <ConfirmNewPasswordInput newPassword={password} {...props} />
      {handleShowRules() && <RuleList password={password} focused={handleShowErrors()} />}
    </>
  );
};

export default PasswordConfirmationForm;

const CurrentPasswordInput = ({ ...props }) => (
  <PasswordControlField
    name="current"
    label="Current Password"
    rules={CURRENT_PASSWORD_RULES}
    {...props}
  />
);

const NewPasswordInput = ({ password, isFocused, hasError, ...props }) => {
  const PasswordLetterCounter = () => (
    <Box textAlign="right" mr="10px" mt={hasError ? '-29px' : '-6px'}>
      {password?.length || 0}/20
    </Box>
  );

  return (
    <>
      <PasswordControlField
        name="password"
        label="New Password"
        rules={PASSWORD_RULES}
        {...props}
      />
      {isFocused && <PasswordLetterCounter />}
    </>
  );
};

const ConfirmNewPasswordInput = ({ newPassword, ...props }) => (
  <PasswordControlField
    name="confirm"
    label="Confirm Password"
    {...props}
    rules={confirmPasswordRules(newPassword)}
  />
);

const RuleList = ({ password, focused }) => (
  <Box style={{ marginLeft: '-24px' }}>
    <PasswordCheck password={password || ''} focused={focused} />
  </Box>
);
