/* eslint-disable */
import { Auth } from 'aws-amplify';

import blobDownloader from 'helpers/blobDownloader';
import { getBaseApiUrl } from 'hooks-api/useEvolveApi';

export const fetchLatestEula = async () => {
  const baseUrl = await getBaseApiUrl();

  const res = await fetch(`${baseUrl}/admin/eula/latest`);
  const data = await res.json();

  return data;
};

export const userAcceptEULA = async (userName) => {
  const baseUrl = await getBaseApiUrl();

  const response = await fetch(`${baseUrl}/admin/user/username/${userName}/acceptEula`, {
    method: 'PATCH',
    mode: 'cors',
  });
  const data = await response.json();

  return data;
};

export const fetchUserStatus = async (userId) => {
  const baseUrl = await getBaseApiUrl();

  const res = await fetch(`${baseUrl}/admin/user/${userId}/providerStatus`);
  const data = await res.json();

  return data;
};

export const fetchProviderStatus = async (email) => {
  const baseUrl = await getBaseApiUrl();

  const res = await fetch(`${baseUrl}/admin/user/${email}/providerStatus`);
  const data = await res.json();

  return data;
};

export const getUserByUserName = async (userName) => {
  const baseUrl = await getBaseApiUrl();

  const res = await fetch(`${baseUrl}/admin/user/username/${userName}`);
  const data = await res.json();

  return data;
};

export const confirmUserByType = async (id, body, resource) => {
  const baseUrl = await getBaseApiUrl();

  const res = await fetch(`${baseUrl}/admin/${resource}/${id}/confirmation`, {
    method: 'PATCH',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'PATCH',
    },
    body: JSON.stringify(body),
  });

  if (res.ok) {
    const data = await res.json();
    return data;
  }

  throw new Error('UserNotFoundException');
};

export const requestTakeoffPadCSV = async (body) => {
  const amplifyRefreshedUser = await Auth.currentAuthenticatedUser();
  const idToken = `Bearer ${amplifyRefreshedUser.signInUserSession.idToken.jwtToken}`;
  const headers = {
    Authorization: idToken,
    'Content-type': 'application/json',
  };
  let fileNameHeader;
  try {
    const baseUrl = await getBaseApiUrl();

    const blobValue = await fetch(`${baseUrl}/moab/takeoffpaditem/csv`, {
      headers,
      method: 'POST',
      body: JSON.stringify(body),
    }).then((response) => {
      fileNameHeader = response.headers.get('filename');
      return response.blob();
    });
    blobDownloader(blobValue, fileNameHeader);
  } catch (error) {
    console.log(error);
  }
  return '';
};
export const requestSourcePadCSV = async (body) => {
  const amplifyRefreshedUser = await Auth.currentAuthenticatedUser();
  const idToken = `Bearer ${amplifyRefreshedUser.signInUserSession.idToken.jwtToken}`;
  const headers = {
    Authorization: idToken,
    'Content-type': 'application/json',
  };
  let fileNameHeader;
  try {
    const baseUrl = await getBaseApiUrl();

    const blobValue = await fetch(`${baseUrl}/moab/sourcepaditem/csv`, {
      headers,
      method: 'POST',
      body: JSON.stringify(body),
    }).then((response) => {
      fileNameHeader = response.headers.get('filename');
      return response.blob();
    });
    blobDownloader(blobValue, fileNameHeader);
  } catch (error) {
    console.log(error);
  }
  return '';
};

export const confirm = (id, type, body) => {
  if (type === 'project') {
    return confirmUserByType(id, body, 'projectuser');
  }

  if (type === 'department') {
    return confirmUserByType(id, body, 'departmentuser');
  }

  return confirmUserByType(id, body, 'user');
};

export const getViewerToken = async () => {
  const client_id = process.env.REACT_APP_FORGE_CLIENT_ID;
  const client_secret = process.env.REACT_APP_FORGE_CLIENT_SECRET;
  const scopes = 'code:all data:write data:read bucket:create bucket:delete bucket:read';
  const headers = {
    'Access-Control-Allow-Origin': 'http://evolvemep:3000',
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  try {
    const authToken = await fetch(`https://developer.api.autodesk.com/authentication/v1/authenticate`, {
      method: 'POST',
      body: JSON.stringify({
        client_id: client_id,
        client_secret: client_secret,
        grant_type: 'client_credentials',
        scope: scopes,
      }),
      mode: 'cors',
      headers,
    }).then((res) => {
      console.log('then', res);
      return res;
    });
  } catch (err) {
    console.error(err);
    return '';
  }
};
