import { useCallback } from 'react';

import { gql, useLazyQuery, useMutation } from '@apollo/client';

import { FETCH_POLICIES, GENERIC_MUTATION_ERROR } from 'constants/globalConstants';
import { deleteMachineTaskType as DELETE_MACHINE_TASK } from 'graphql/mutations';
import { machineTaskType as MACHINE_TASKS } from 'graphql/queries';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

import useCachedData from '../../../hooks/useCachedData';
import { useWorkCell } from '../../WorkCellRow/contexts/WorkCellContext';

export default function useMachineTasksDataLayer(machine) {
  const { handleResponse } = useGraphqlResponseHandler();
  const { workCell } = useWorkCell();
  const { cleanWorkCellTasksCache, cleanMachineTasksCache } = useCachedData();

  const [machineTasksQuery, { data, loading: loadingMachineTasks }] = useLazyQuery(
    gql(MACHINE_TASKS),
    FETCH_POLICIES.CACHE_AND_NETWORK,
  );

  const [unmapMachineTaskMutation] = useMutation(gql(DELETE_MACHINE_TASK));

  const fetchMachineTasks = useCallback(async () => {
    machineTasksQuery({
      variables: { query: { machineId: machine.machineId, workCellId: workCell.workCellId } },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const unmapMachineTask = async (machineTaskTypeId) => {
    await handleResponse(
      unmapMachineTaskMutation,
      {
        variables: {
          params: {
            machineTaskTypeId,
          },
        },
      },
      {
        successMessage: 'Task successfully unmapped',
        errorMessage: GENERIC_MUTATION_ERROR,
      },
      () => {
        cleanMachineTasksCache(workCell, machine);
        cleanWorkCellTasksCache(workCell);
      },
    );
  };

  return {
    machineTasks: data?.machineTaskType || [],
    fetchMachineTasks,
    loadingMachineTasks,
    unmapMachineTask,
  };
}
