import React from 'react';

import CustomDetailPanelToggle from 'modules/Field/WorkRequests/WorkRequest/components/CustomDetailPanelToggle';

import HideWhenEditingWrapper from './HideWhenEditingWrapper';

const ToggleOpenCell = ({ value, id, disabled = false }) => (
  <HideWhenEditingWrapper>
    <CustomDetailPanelToggle id={id} value={value} isDisabled={disabled} />
  </HideWhenEditingWrapper>
);

export default ToggleOpenCell;
