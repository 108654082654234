import React from 'react';

import { ForesiteTextField } from '@evolvemep/foresite-components-ui-react';
import { useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { useForm, Controller } from 'react-hook-form';

import { useUser } from 'app/UserContext';
import { Modal } from 'components';
import { VARIANT_SUCCESS } from 'constants/snackbarConstants';

import { useItemsContext } from '../ItemsContext';

const DeleteItemsConfirmDialog = ({ open = false, onClose = () => {} }) => {
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();

  const { user } = useUser();

  const { isRemovingPartsFromCategory, selectedItems, onDeleteItems } = useItemsContext();

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { isValid },
  } = useForm({ mode: 'all' });
  const confirmationEmail = watch('confirmationEmail');
  const isEmailsSame = confirmationEmail === user?.userEmail;

  const handleClose = () => {
    onClose();
    reset();
  };

  const handleConfirm = async () => {
    await onDeleteItems();

    const message =
      selectedItems.length === 1
        ? `${selectedItems.length} item successfully deleted.`
        : `${selectedItems.length} items successfully deleted.`;
    enqueueSnackbar(message, VARIANT_SUCCESS);
    handleClose();
  };

  return (
    <Modal
      open={open}
      title="Confirm delete"
      onClose={handleClose}
      closeButtonColor={theme.palette.action.lightSurface.active}
      closeButtonStyles={{ padding: 0, marginRight: '-8px' }}
      footer={
        <>
          {isRemovingPartsFromCategory && <CircularProgress size={24} color="secondary" />}
          <Button
            variant="text"
            sx={{ color: 'primary.main', fontWeight: 500 }}
            disabled={isRemovingPartsFromCategory}
            data-testid="delete-items-confrim-dialog-cancel-btn"
            onClick={handleClose}
          >
            CANCEL
          </Button>
          <Button
            color="error"
            variant="contained"
            disabled={!isValid || isRemovingPartsFromCategory || !isEmailsSame}
            data-testid="delete-items-confrim-dialog-submit-btn"
            onClick={handleSubmit(handleConfirm)}
          >
            DELETE
          </Button>
        </>
      }
    >
      <Stack display="flex" direction="column">
        <Typography variant="body" color="surface.lightSurface.primary">
          Are you sure you want to delete the <strong>{selectedItems.length}</strong> selected items? Please type your
          email to confirm deletion.
        </Typography>
        <form>
          <Controller
            name="confirmationEmail"
            control={control}
            defaultValue=""
            rules={{
              required: 'Email doesn’t match login',
              validate: (value) => value === user?.userEmail || 'Email doesn’t match login',
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <ForesiteTextField
                type="email"
                label="Email"
                inputProps={{
                  'data-testid': 'delete-items-confrim-dialog-email-input',
                }}
                disabled={isRemovingPartsFromCategory}
                value={value}
                onChange={onChange}
                error={Boolean(error)}
                helperText={error ? error.message : user?.userEmail}
                sx={{ my: 1, width: '320px' }}
              />
            )}
          />
        </form>
      </Stack>
    </Modal>
  );
};

export default DeleteItemsConfirmDialog;
