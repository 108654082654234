import React from 'react';

import { Stack } from '@mui/material';

import { useAccordionContext } from './PartItemDetailAccordionContext';

const PartItemDetailAccordionHeader = ({ children, sx, ...restProps }) => {
  const { setOpened } = useAccordionContext();

  return (
    <Stack
      direction="row"
      sx={{
        height: '76px',
        alignItems: 'center',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '0px 36px',
        ...sx,
      }}
      onClick={() => setOpened((prev) => !prev)}
      {...restProps}
    >
      {children}
    </Stack>
  );
};

export default PartItemDetailAccordionHeader;
