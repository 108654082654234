export const inputStyles = {
  '& .MuiFilledInput-root': {
    backgroundColor: 'transparent',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    '&:hover, &:active, &:focus': {
      backgroundColor: 'transparent',
    },
    '& > input': {
      padding: '0px !important',
      margin: 0,
      fontSize: '16px',
      lineHeight: '32px',
      fontWeight: 'normal',
    },
  },
};
