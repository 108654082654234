/* eslint-disable react/destructuring-assignment, no-console */
import React, { Component } from 'react';

import { AwsRum } from 'aws-rum-web';

import getEnvVars from '../envVars';
import ErrorPage from './ErrorPage';

const isLocalHost = String(window.location.href).includes('localhost');

const modifyError = (error) => {
  if (error.event_details) {
    const errorMessage = `- FATAL ERROR - ${error.event_details.message}`;
    return { ...error, message: errorMessage };
  }

  if (error.message) {
    const errorMessage = `- FATAL ERROR - ${error.message}`;
    return { ...error, message: errorMessage };
  }

  const errorMessage = `- FATAL ERROR - ${JSON.stringify(error)}`;
  return { ...error, message: errorMessage };
};

let awsRum = null;
const ensureAwsRum = async () => {
  // This allows us to memoize awsRum, only configure it once
  if (!isLocalHost && !awsRum) {
    try {
      const env = await getEnvVars();

      const config = {
        sessionSampleRate: 1,
        guestRoleArn: `arn:aws:iam::${env.REACT_APP_RUM_ACCOUNT_ID}:role/RUM-Monitor-us-east-1-${env.REACT_APP_RUM_ACCOUNT_ID}-${env.REACT_APP_RUM_UNIQUE_ID}-Unauth`,
        identityPoolId: `us-east-1:${env.REACT_APP_RUM_IDENTITY_POOL_ID}`,
        endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
        telemetries: ['performance', ['errors', { stackTraceLength: 2000 }], 'http'],
        allowCookies: true,
        enableXRay: false,
      };

      const APPLICATION_VERSION = '1.0.0';
      const APPLICATION_REGION = 'us-east-1';

      awsRum = new AwsRum(env.REACT_APP_RUM_APP_ID, APPLICATION_VERSION, APPLICATION_REGION, config);
    } catch (error) {
      console.log('awsRum initialization failed', error);
      // Ignore errors thrown during CloudWatch RUM web client initialization
    }
  }
}

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error, errorInfo) {
    return { hasError: true, error, errorInfo };
  }

  async componentDidCatch(error) {
    await ensureAwsRum();

    const errorWithErrorInfo = { ...error, ...this.state.errorInfo };
    if (!isLocalHost && this.state.hasError) {
      const modifiedError = modifyError(errorWithErrorInfo);
      awsRum.recordError(modifiedError);
      return;
    }
    if (!isLocalHost) {
      awsRum.recordError(errorWithErrorInfo);
    }
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
