import React from 'react';

import { useTheme } from '@mui/material';

import { ControlledDatePicker } from 'components';
import { Custom } from 'components/Icons';

const DatePicker = ({
  name = '',
  label = '',
  id = '',
  control,
  onDateChange = () => {},
  onDateBlur = () => {},
  setError = () => {},
  ...rest
}) => {
  const theme = useTheme();

  return (
    <ControlledDatePicker
      name={name}
      hideErrorMessage
      autoComplete="off"
      id={id}
      pickerIcon={Custom.CalendarTodayOutlined}
      control={control}
      label={label}
      size="small"
      fullWidth
      color="secondary"
      onDateChange={onDateChange}
      onDateBlur={onDateBlur}
      PaperProps={{
        sx: {
          '& .MuiPickersDay-today': {
            borderColor: `${theme.palette.secondary.main} !important`,
          },
          '& .Mui-selected': {
            backgroundColor: `${theme.palette.secondary.main} !important`,
          },
        },
      }}
      setError={setError}
      {...rest}
    />
  );
};

export default DatePicker;
