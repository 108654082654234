import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { ThemedCheckBox } from 'components';
import ActionsDropdown from 'components/TableComponents/ActionsDropdown/ActionsDropdown';
import Search from 'components/TableComponents/Search';

import EvolveMembersActions from './EvolveMembersActions';
import { useEvolveMembersContext } from './EvolveMembersContext';

const EvolveMembersHeader = ({ handleSearch, openModal }) => {
  const { selected } = useEvolveMembersContext();

  return (
    <HeaderWrapper>
      <Typography variant="h5" gutterBottom>
        eVolve Members
      </Typography>
      <Grid container justifyContent="space-between" wrap="nowrap">
        <Grid container item alignItems="center" wrap="nowrap">
          <ItemsSelected>{selected.length} Items Selected</ItemsSelected>
          <ActionsDropdown menuItemsRenderer={() => <EvolveMembersActions />} />
        </Grid>
        <Grid container item alignItems="center" wrap="nowrap" justifyContent="flex-end">
          <ThemedCheckBox />
          <Typography component="p" noWrap>
            Show Deleted Users
          </Typography>
          <Search handleSearch={handleSearch} placeholder="Search…" />
          <InviteButton onClick={openModal}>+ Invite Users</InviteButton>
        </Grid>
      </Grid>
    </HeaderWrapper>
  );
};

export default EvolveMembersHeader;

const HeaderWrapper = (props) => <Box mb="20px" {...props} />;

const ItemsSelected = (props) => <Typography sx={{ color: 'rgba(0, 0, 0, 0.5)' }} {...props} />;

const InviteButton = (props) => (
  <Button
    variant="contained"
    color="secondary"
    sx={{
      minWidth: 150,
      maxHeight: 32,
      ml: '16px',
      '&:hover': {
        backgroundColor: 'secondary.buttonHover',
      },
    }}
    {...props}
  />
);
