import React from 'react';

import { ForesiteTextField } from '@evolvemep/foresite-components-ui-react';
import { useFormContext, Controller } from 'react-hook-form';

import { useCatalogModal } from './CatalogModalContext';

const MAX_CHAR_COUNT = 256;
const fieldRules = {
  maxLength: {
    value: MAX_CHAR_COUNT,
  },
};

const DescriptionField = ({ control }) => {
  const { isLoading } = useFormContext();
  const { setDescriptionText } = useCatalogModal();

  const onValueChange = (event) => {
    setDescriptionText(event.target.value);
  };

  return (
    <Controller
      name="Description"
      control={control}
      rules={fieldRules}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <ForesiteTextField
          multiline
          fullWidth
          disabled={isLoading}
          maxRows={3}
          onChange={(event, child) => {
            onChange(event, child);
            onValueChange(event, child);
          }}
          size="small"
          error={Boolean(error)}
          name="Description"
          id="DescriptionField"
          label="Description"
          maxLength={256}
        />
      )}
    />
  );
};

export default DescriptionField;
