import React, { useEffect, useState } from 'react';

import { useWorkCellsAndMachinesContext } from '../../../contexts/WorkCellsAndMachinesContext';
import useWorkCellMachineDataLayer from '../hooks/useWorkCellMachineDataLayer';
import useWorkCellsDataLayer from '../hooks/useWorkCellsDataLayer';
import useWorkCellTasksDataLayer from '../hooks/useWorkCellTasksDataLayer';

const WorkCellContext = React.createContext();

const WorkCellProvider = ({ children, workCell, disabled = false }) => {
  const { workCellTasks, loadingWorkCellTasks, fetchWorkCellTasks, unmapWorkCellTask } =
    useWorkCellTasksDataLayer(workCell);

  const {
    workCellMachines,
    loadingWorkCellMachines,
    fetchWorkCellMachines,
    fetchMachines,
    addMachine,
    loadingAddMachine,
    loadingMachines,
  } = useWorkCellMachineDataLayer(workCell);

  const { deleteWorkCell, loadingDeleteWorkCell, saveWorkCell } = useWorkCellsDataLayer(workCell);

  const { handleSaveWorkCellForm } = useWorkCellsAndMachinesContext();

  const [openMachinesContainer, setOpenMachinesContainer] = useState(false);

  useEffect(() => {
    if (!handleSaveWorkCellForm) return;

    if (handleSaveWorkCellForm.workCell.workCellId === workCell.workCellId)
      saveWorkCell(handleSaveWorkCellForm.formValues);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSaveWorkCellForm]);

  const handleAddNewMachine = async () => {
    setOpenMachinesContainer(Math.random());
    const facilityMachines = await fetchMachines();
    const names = facilityMachines?.map((machine) => machine.machineIdentifier);

    let number = 0;
    let stillLoop = true;
    let availableName;

    while (stillLoop) {
      const curName = number === 0 ? 'Machine' : `Machine ${number}`;
      if (!names.includes(curName)) {
        availableName = curName;
        stillLoop = false;
      } else {
        number += 1;
      }
    }
    await addMachine(availableName);
    setOpenMachinesContainer(false);
  };

  const handleFetchWorkCellMachines = () => fetchWorkCellMachines();
  const handleFetchWorkCellTasks = () => fetchWorkCellTasks();

  const selectedStateObj = {
    workCell,
    disabled,
    workCellTasks,
    loadingWorkCellTasks,
    handleFetchWorkCellTasks,
    unmapWorkCellTask,
    workCellMachines,
    loadingWorkCellMachines,
    handleFetchWorkCellMachines,
    deleteWorkCell,
    loadingDeleteWorkCell,
    handleAddNewMachine,
    loadingAddMachine,
    loadingMachines,
    openMachinesContainer,
    setOpenMachinesContainer,
  };

  return <WorkCellContext.Provider value={selectedStateObj}>{children}</WorkCellContext.Provider>;
};

const useWorkCell = () => {
  const context = React.useContext(WorkCellContext);
  if (context === undefined) {
    throw new Error('useWorkCell must be used within a WorkCellContext');
  }
  return context;
};

export { WorkCellContext, WorkCellProvider, useWorkCell };
