import React, { useMemo, useContext, useState, useEffect } from 'react';

import { gql, useLazyQuery } from '@apollo/client';

import { useUser } from 'app/UserContext';
import { partCatalogs as PART_CATALOGS } from 'graphql/queries';

export const CatalogSetupContext = React.createContext();

export const CatalogSetupProvider = ({ children }) => {
  const [selectedCatalog, setSelectedCatalog] = useState(null);
  const { user } = useUser();

  const [
    fetchCatalogs,
    { loading: loadingCatalogs, data: dataCatalogs, refetch: refetchCatalogs },
  ] = useLazyQuery(gql(PART_CATALOGS), {
    variables: { query: { companyId: user.companyId, orderBy: 'name', take: 9999 } },
  });

  useEffect(() => fetchCatalogs(), [fetchCatalogs]);

  const valuesObj = useMemo(
    () => ({
      loadingCatalogs,
      dataCatalogs,
      selectedCatalog,
      setSelectedCatalog,
      refetchCatalogs,
    }),
    [loadingCatalogs, dataCatalogs, selectedCatalog, setSelectedCatalog, refetchCatalogs],
  );

  return <CatalogSetupContext.Provider value={valuesObj}>{children}</CatalogSetupContext.Provider>;
};

export const useCatalogSetupContext = () => {
  const context = useContext(CatalogSetupContext);
  if (context === undefined) {
    throw new Error('useCatalogContext must be used within a CatalogSetupProvider');
  }
  return context;
};
