import { useState, useCallback } from 'react';

import { BADGE_MAPPING_STATUS } from 'constants/badgeMappingStatus';
import { reorderItemInList } from 'helpers/arrayFunctions';

import useItemsBackup from './useItemsBackup';
import usePlaceholder from './usePlaceholder';
import { useWorkCellsKanban } from './WorkCellsContext';

const getStatusName = (taskStatusTypeName) => BADGE_MAPPING_STATUS[taskStatusTypeName] ?? null;

const getSortOrder = (currentStatus, prevTask, nextTask, prevStatus) => {
  if (prevStatus && currentStatus?.variant === prevStatus?.variant) return prevTask.sortOrder + 1;
  return nextTask.sortOrder - 1 < 0 ? 0 : nextTask.sortOrder - 1;
};

const getNewTasksOrder = (prevTasks, currentTask, newSortOrder) =>
  prevTasks.map((task) =>
    currentTask.workCellTaskId === task.workCellTaskId
      ? {
          ...task,
          sortOrder: newSortOrder,
        }
      : task,
  );

export default function useDragAndDrop(setTasks, tasks, updateTask) {
  const [filteredTasks, setFilteredTasks] = useState([]);
  const { placeholderProps, showPlaceholder, hidePlaceholder } = usePlaceholder();
  const { setBackup, rollback } = useItemsBackup(setTasks);
  const { setColumnActive } = useWorkCellsKanban();

  const getCurrentTaskContext = useCallback(
    ({ destination, source }) => {
      const sourceIndex = source?.index;
      const filteredTasks = [...tasks].filter((_, index) => index !== sourceIndex);
      const currentTask = [...tasks].find((_, index) => index === sourceIndex);

      const prevTask = filteredTasks[destination.index - 1];
      const nextTask = filteredTasks[destination.index];
      const prevStatus = getStatusName(prevTask?.taskStatusTypeName);
      const nextStatus = getStatusName(nextTask?.taskStatusTypeName);
      const currentStatus = getStatusName(currentTask?.taskStatusTypeName);

      return {
        currentTask,
        prevTask,
        nextTask,
        prevStatus,
        nextStatus,
        currentStatus,
      };
    },
    [tasks],
  );

  const isTaskDroppable = useCallback(
    (event) => {
      if (!event.destination) return false;
      const { prevStatus, nextStatus, currentStatus, prevTask } = getCurrentTaskContext(event);
      if (prevTask && !prevTask?.isEnabled) return false;

      return currentStatus?.variant === prevStatus?.variant || currentStatus?.variant === nextStatus?.variant;
    },
    [getCurrentTaskContext],
  );

  const onDragStart = ({ source }) => {
    setColumnActive(source?.droppableId);
    const sourceIndex = source?.index;
    setFilteredTasks([...tasks].filter((_, index) => index !== sourceIndex));
    setBackup(tasks);
  };

  const onDragUpdate = (event) => {
    const { draggableId, destination } = event;
    if (!destination) return;
    const destinationIndex = destination?.index;
    const isDrop = isTaskDroppable(event);
    if (isDrop) {
      showPlaceholder(draggableId, destinationIndex);
    } else hidePlaceholder();
  };

  const onDragEnd = async (event) => {
    setColumnActive(null);
    if (!event.destination) return;
    const {
      source: { index: initialIndex },
      destination: { index: destinationIndex },
    } = event;
    if (initialIndex === destinationIndex) return;

    const isDrop = isTaskDroppable(event);
    if (!isDrop) return;

    const { currentTask, prevTask, nextTask, prevStatus, currentStatus } = getCurrentTaskContext(event);
    if (!currentTask) return;

    const updatedOrder = reorderItemInList([...tasks], initialIndex, destinationIndex);
    setTasks(updatedOrder);
    const newSortOrder = getSortOrder(currentStatus, prevTask, nextTask, prevStatus);

    await updateTask(
      currentTask.workCellTaskId,
      newSortOrder,
      () => {
        setTasks((prev) => getNewTasksOrder(prev, currentTask, newSortOrder));
      },
      () => rollback(),
    );

    hidePlaceholder();
  };

  return {
    isTaskDroppable,
    filteredTasks,
    setFilteredTasks,
    placeholderProps,
    showPlaceholder,
    hidePlaceholder,
    getCurrentTaskContext,
    onDragStart,
    onDragUpdate,
    onDragEnd,
  };
}
