import React, { useRef, useEffect } from 'react';

import { Controller } from 'react-hook-form';

import { useCancelOperation } from 'hooks/useCancelOperation';

import { useTakeOff } from '../../context/TakeOffContext';
import TabNameInput from './TabNameInput';

const ControlledTabNameInput = ({
  control,
  handleKeyPressNewPad,
  handleTextChange,
  hideAddNewPad,
  editable,
  hasError,
  resetForm = () => {},
  autoFocus,
  disabled,
  controllerProps,
  inputProps,
  skipError = false,
}) => {
  const { ref } = useCancelOperation(() => resetForm());
  const { projectId } = useTakeOff();
  const formReference = useRef();

  useEffect(() => {
    if (projectId) formReference.current?.firstChild.firstChild.firstChild.focus();
  }, [projectId]);

  return (
    <div ref={formReference}>
      <Controller
        control={control}
        name="takeoffPadName"
        rules={{
          required: {
            value: true,
            message: 'Takeoff Pad name is required',
          },
          maxLength: {
            value: 32,
            message: 'Name should be less than 32 characters',
          },
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TabNameInput
            ref={ref}
            error={hasError}
            skipError={skipError}
            editable={editable}
            onKeyPress={async (e) => handleKeyPressNewPad(e.key, error)}
            onChange={(e) => {
              handleTextChange(e);
              onChange(e.target.value);
            }}
            onBlur={() => {
              if (!value?.length) hideAddNewPad();
              handleKeyPressNewPad('Enter', error);
            }}
            value={value}
            autoFocus={autoFocus}
            disabled={disabled}
            {...inputProps}
          />
        )}
        {...controllerProps}
      />
    </div>
  );
};

export default ControlledTabNameInput;
