import React, { useEffect, useState } from 'react';

import InputSelectMultiple from './InputSelectMultiple';

const mapLabels = (array) => (Array.isArray(array) && array.map(({ label }) => label)) || [];

const InputSelectMultipleField = ({ name, setValue, values, options, ...rest }) => {
  const [defaultValue, setDefaultValue] = useState(mapLabels(values));

  const getSelectedOptions = (newSelected) =>
    options.filter((option) => newSelected.some((newItem) => newItem === option.label));

  const onSelectChange = (newValue) => {
    setValue(name, getSelectedOptions(newValue));
    setDefaultValue(newValue);
  };

  useEffect(() => {
    setDefaultValue(mapLabels(values));
  }, [values]);

  const mappedOptions = mapLabels(options);

  return (
    <InputSelectMultiple
      defaultValue={defaultValue}
      options={mappedOptions}
      onSelectChange={onSelectChange}
      {...rest}
    />
  );
};
export default InputSelectMultipleField;
