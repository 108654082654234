/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';

import { useLazyQuery, gql } from '@apollo/client';
import { useFormContext } from 'react-hook-form';

import { SimpleControlledAutoComplete } from 'components/FormComponents';
import { requiredRule } from 'constants/inputFieldRules';
import {
  partAttributes as ATTRIBUTES,
  partAttributePredefinedNames as PREDEFINED_ATTRIBUTES,
} from 'graphql/queries';

import { CategoriesContext } from '../Categories/CategoriesContext';

const getOptionsFromPredefinedNamesAndExistingNames = (
  predefinedNamesArray,
  existingAttributeNames,
) =>
  predefinedNamesArray?.reduce((previous, current) => {
    if (existingAttributeNames?.includes(current?.predefinedAttributeName)) return previous;
    const { partAttributePredefinedNameId, predefinedAttributeName } = current;
    const newObject = { id: partAttributePredefinedNameId, label: predefinedAttributeName };
    return [...previous, newObject];
  }, []);

const AttributeNameSearchInput = ({ ...props }) => {
  const [options, setOptions] = useState([]);
  const { control, watch, setError } = useFormContext();
  const { getFieldState } = control;
  const [debouncedValue, setDebouncedValue] = useState();

  const { selectedCategory } = useContext(CategoriesContext);

  const [, { refetch: fetchPartAttributes }] = useLazyQuery(gql(ATTRIBUTES), {
    fetchPolicy: 'no-cache',
  });
  const [, { refetch: fetchPredefinedNames }] = useLazyQuery(gql(PREDEFINED_ATTRIBUTES), {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    const getOptions = async () => {
      const existingAttributes = await fetchPartAttributes({
        query: { partCatalogId: selectedCategory?.partCatalog?.partCatalogId, take: 999 },
      });

      const existingAttributeNames = existingAttributes?.data?.partAttributes?.map(
        (partAttribute) => partAttribute?.partAttributeName,
      );

      const predefinedNames = await fetchPredefinedNames();

      setOptions(
        getOptionsFromPredefinedNamesAndExistingNames(
          predefinedNames?.data?.partAttributePredefinedNames,
          existingAttributeNames,
        ),
      );
    };

    getOptions();
  }, []);

  const handleChange = async (value) => {
    const partCatalogId = selectedCategory?.partCatalog?.partCatalogId;
    const currentName =
      typeof value === 'object' && value !== null
        ? value?.label?.trim()
        : value?.trim().toLowerCase();

    const nameExists = await fetchPartAttributes({
      query: { partCatalogId, name: `eq:${currentName}` },
    });

    if (nameExists?.data?.partAttributes?.length > 0)
      setError('AttributeNameField', { type: 'custom', message: 'Attribute name already exists' });
  };

  const attributeNameValue = watch('AttributeNameField');

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(attributeNameValue), 300);
    return () => clearTimeout(timer);
  }, [attributeNameValue]);

  useEffect(() => handleChange(debouncedValue), [debouncedValue]);

  const rules = { ...requiredRule('Attribute name') };
  const error = getFieldState('AttributeNameField')?.error;

  return (
    <SimpleControlledAutoComplete
      limitOptions={6}
      control={control}
      size="small"
      name="AttributeNameField"
      label="Attribute Name"
      rules={rules}
      helperText={error?.message}
      options={options}
      {...props}
    />
  );
};

export default AttributeNameSearchInput;
