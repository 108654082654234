import { useCallback } from 'react';

import { useSnackbar } from 'notistack';

import { HandleItemsCreatedType } from '../@types/ItemsIntegrationModal';
import { useNewWRFormContext } from '../components/NewWRForm/context/NewWRFormContext';
import { useItemsIntegrationTableContext } from '../components/QuantityStep/components/ItemsIntegrationTable/context/ItemsIntegrationTableContext';
import { useItemsIntegrationModalContext } from '../context/ItemsIntegrationModalContext';
import { handleCloseModal, handleSubtitle } from '../helpers/itemsIntegrationModalHelpers';

const useModalHandlers = () => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    handleConfirmClose,
    handleClose,
    selectionModel,
    addNewWRForm,
    isLoading,
    setIsLoading,
    rows: workRequestRows,
  } = useItemsIntegrationModalContext();

  const {
    methods: { reset: resetQtyValues },
    onItemsCreated,
    isBOMtoWR,
    rows,
    resetAllRows,
  } = useItemsIntegrationTableContext();
  const {
    methods: {
      formState: { isDirty },
      reset,
    },
  } = useNewWRFormContext();
  const handleSubtitleCallback = useCallback(
    () => handleSubtitle({ addNewWRForm, isDirty, selectionModel, rows: workRequestRows, isBOMtoWR }),
    [selectionModel, workRequestRows, isDirty, addNewWRForm, isBOMtoWR],
  );
  const handleReset = () => {
    resetAllRows();
    reset();
    resetQtyValues();
  };

  const handleFinish = () => {
    handleCloseModal({
      reset: () => handleReset(),
      setIsLoading,
      handleClose,
    });
  };

  const handleCancel = () => {
    if (isLoading) return;
    handleConfirmClose(() => handleReset());
  };

  const handleItemsCreated = async ({
    currentWorkRequestIdentifier,
    isNewWR,
    integrationIdResp,
  }: HandleItemsCreatedType) => {
    handleFinish();
    onItemsCreated({
      integrationId: integrationIdResp,
      currentWorkRequestIdentifier,
      totalItemsCreated: rows?.length,
      isNewWR,
    });
  };

  const handleError = () => {
    enqueueSnackbar('An error ocurred', { variant: 'error' });
    handleFinish();
  };

  return {
    handleReset,
    handleFinish,
    handleCancel,
    handleItemsCreated,
    handleError,
    handleSubtitleCallback,
  };
};

export default useModalHandlers;
