import React from 'react';

import { Divider } from '@mui/material';

import ChipsArea from './ChipsArea';
import FooterWithButtons from './FooterWithButtons';
import { PopoverProvider } from './PopoverProvider';
import SourceTabs from './SourceTabs';

const PopoverContent = ({ contextHook, padSelected }) => {
  const { chipList, updateChipList } = contextHook();
  return (
    <PopoverProvider chipList={chipList} updateChipList={updateChipList}>
      <ChipsArea />
      <SourceTabs padSelected={padSelected} />
      <Divider variant="fullWidth" />
      <FooterWithButtons />
    </PopoverProvider>
  );
};

export default PopoverContent;
