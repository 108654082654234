import React from 'react';

import { useFormContext } from 'react-hook-form';

import CenteredLoadSpinner from 'components/CenteredLoadSpinner';
import InputSelectField from 'components/FormComponents/InputSelectField';

import useGetProjectTypeOptions from './useGetProjectTypeOptions';

const ProjectTypeSelect = () => {
  const { control } = useFormContext();
  const { projectTypeOptions, loading } = useGetProjectTypeOptions();

  if (loading || !projectTypeOptions) return <CenteredLoadSpinner />;

  return (
    <DropDown
      control={control}
      name="projectTypeId"
      label="Project Type"
      options={projectTypeOptions}
    />
  );
};

export default ProjectTypeSelect;

const DropDown = ({ label, options, ...rest }) => (
  <InputSelectField
    color="secondary"
    size="small"
    label={label}
    fullWidth
    options={options}
    {...rest}
  />
);
