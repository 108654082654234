import React from 'react';

import { Stack, Typography } from '@mui/material';

import { useTakeOff } from '../context/TakeOffContext';

const TabCounterItems = ({ isSelected = false, sx }) => {
  const { selectedPadItems } = useTakeOff();
  return (
    <Stack justifyContent="center">
      <Typography
        color="surface.lightSurface.secondary"
        variant="body2"
        sx={{ ml: 2, mr: 1, minWidth: '50px', overflow: 'hidden', whiteSpace: 'nowrap', ...sx }}
      >
        {`${selectedPadItems.length} item${selectedPadItems.length === 1 ? '' : 's'}`}{' '}
        {isSelected ? 'selected' : ''}
      </Typography>
    </Stack>
  );
};

export default TabCounterItems;
