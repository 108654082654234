import { gql, useMutation } from '@apollo/client';

import { useUser } from 'app/UserContext';
import { updateProject as UPDATE_PROJECT } from 'graphql/mutations';
import { projectById as PROJECT_BY_ID } from 'graphql/queries';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import useUploadImage from 'hooks/useUploadImage';

const removeNullOrUndefined = (obj) =>
  Object.fromEntries(Object.entries(obj).filter(([, v]) => v != null));

const useUpdateProjectMutation = ({ selectedProjectId }) => {
  const { user } = useUser();
  const { uploadImage } = useUploadImage();
  const { handleResponse } = useGraphqlResponseHandler();
  const [updateProjectMutation, { loading }] = useMutation(gql(UPDATE_PROJECT), {
    refetchQueries: [
      {
        query: gql(PROJECT_BY_ID),
        variables: {
          params: {
            projectId: selectedProjectId,
          },
        },
      },
    ],
  });

  const handleImage = async (imageFile) => {
    const imageStoragePath = `all/company/${user.companyId}/project/${selectedProjectId}/images`;
    const imageProjectId = `${selectedProjectId}/images`;
    return uploadImage(imageFile, imageStoragePath, imageProjectId);
  };

  const updateProject = async (values) => {
    const entries = removeNullOrUndefined(values);

    const { assignDesignDepartment, assignProcurementDepartment, assignShopDepartment, image } =
      entries;

    if (image) {
      const documentId = await handleImage(image);
      entries.projectImageId = documentId;
      delete entries.image;
    }

    // there is a cleaner way to do this... if no departmentType <type> dont add it to the body.
    const departmentIds = [];

    if (assignDesignDepartment) {
      departmentIds.push(assignDesignDepartment);
    }
    if (assignProcurementDepartment) {
      departmentIds.push(assignProcurementDepartment);
    }
    if (assignShopDepartment) {
      departmentIds.push(assignShopDepartment);
    }

    delete entries.assignDesignDepartment;
    delete entries.assignProcurementDepartment;
    delete entries.assignShopDepartment;

    if (departmentIds.length) entries.departmentIds = departmentIds;

    const body = {
      ...entries,
    };

    handleResponse(updateProjectMutation, {
      variables: {
        params: { projectId: selectedProjectId },
        body,
      },
    });
  };

  return { loading, updateProject };
};

export default useUpdateProjectMutation;
