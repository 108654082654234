import React, { useEffect, useState } from 'react';

import { Stack, Box, Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import BreadcrumbLabel from 'components/Breadcrumb/BreadcrumbLabel';
import { FilterButtonWithPopover } from 'components/Buttons';
import { Columns } from 'components/ItemsDatagridPro';
import { Breadcrumbs } from 'components/NavigationComponents';
import { BreadcrumbNextIcon } from 'components/NavigationComponents/Breadcrumbs';
import useUpdateProjectSubscription from 'hooks-api/subscriptions/useUpdatedProjectSubscription/useUpdatedProjectSubscription';
import { NewWRFormProvider } from 'modules/Materials/components/ItemsIntegrationModal/components/NewWRForm/context/NewWRFormContext';
import { ItemsIntegrationProvider } from 'modules/Materials/components/ItemsIntegrationModal/context/ItemsIntegrationModalContext';
import { useAssignationMode } from 'modules/Materials/features/assignation-mode';
import { ESearchType } from 'types/module';

import PopoverContent from '../commons/FilterPopoverContent/PopoverContent';
import HideChipsButton from '../commons/HideChipsButton';
import LocationChipsArea from '../commons/LocationsChipsArea';
import { ItemsIntegrationModal } from '../components/ItemsIntegrationModal';
import { ItemsIntegrationTableProvider } from '../components/ItemsIntegrationModal/components/QuantityStep/components/ItemsIntegrationTable/context/ItemsIntegrationTableContext';
import { CurrentProjectProvider } from '../components/ItemsIntegrationModal/context/CurrentProjectContext';
import { EditQuantityModalProvider } from '../TakeOff/TakeOffPadTab/EditQuantityModal/contexts/EditQuantityModalContext';
import EditQuantityModal from '../TakeOff/TakeOffPadTab/EditQuantityModal/EditQuantityModal';
import { useBOMbyIdContext } from './BOMbyIdContext';
import BOMItemsTable from './BOMCatalogTable';
import useGetProjectById from './BOMCatalogTable/hooks/useGetProjectById';
import BOMSearch from './BOMsearch';
import { DropdownActions } from './components/DropdownActions';
import useBomToWRIntegration from './hooks/useBomToWRIntegration';
import usePrevious from './hooks/usePrevious';
import { useRefetchBOMItems } from './hooks/useRefetchBOMItems';

const MAIN_PATH = `../bill-of-materials`;
const BillOfMaterialsById = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { selectedItem } = useFacilitiesProjects();
  const previousSelectedItem = usePrevious(selectedItem);
  const { chipList, selectedPadItems, editingQuantityRowData, setEditingQuantityRowData, setAssignModeOn } =
    useBOMbyIdContext();
  const [currentProject, setCurrentProject] = useState();
  const [projectName, setProjectName] = useState('BOM');
  const { project } = useUpdateProjectSubscription();
  const { getProject } = useGetProjectById();
  const { AssignationModeComponents } = useAssignationMode(useBOMbyIdContext, setAssignModeOn);

  useEffect(() => {
    const getProjectAsync = async () => {
      try {
        const project = await getProject(id);
        if (!project) {
          navigate(MAIN_PATH);
          return;
        }
        setProjectName(project?.projectName ?? '');
        setCurrentProject(getCurrentProject(project));
      } catch {
        navigate(MAIN_PATH);
      }
    };
    getProjectAsync();
  }, [getProject, id, navigate]);

  const handleCloseQuantityDialog = () => setEditingQuantityRowData(null);

  useEffect(() => {
    const updatedProjectIdEqualsId = project?.updatedProject?.projectId === id;
    if (updatedProjectIdEqualsId) {
      setCurrentProject(getCurrentProject(project?.updatedProject));
      setProjectName(project?.updatedProject?.projectName);
    }
  }, [id, project, setProjectName]);

  useEffect(() => {
    if (selectedItem && previousSelectedItem && selectedItem?.id !== previousSelectedItem?.id) navigate(MAIN_PATH);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  return (
    <CurrentProjectProvider currentProject={currentProject}>
      <ItemsIntegrationProvider
        selectedItemIdKey="lineItemId"
        locationsTableDescription="Specify the quantity of items you want to send to the Work Request"
        selectedItems={selectedPadItems}
      >
        <EditQuantityModalProvider chipList={chipList}>
          <BOMItemsToWRModal />
          <HeaderWrapper>
            <BillOfMaterialsByIdTitle
              isFacility={selectedItem?.type === ESearchType.FACILITY}
              projectName={selectedItem?.type === ESearchType.FACILITY ? projectName : selectedItem?.label}
            />
            <BOMSearch />
          </HeaderWrapper>
          <Stack direction="row" sx={{ alignItems: 'space-between', width: '100%' }}>
            <Grid container item alignItems="center" wrap="nowrap">
              <Box sx={{ display: 'flex', height: 45, alignItems: 'center', justifyContent: 'center' }}>
                <DropdownActions />
                {AssignationModeComponents.ChipSelected}
              </Box>
            </Grid>
            <Stack direction="row" sx={{ alignItems: 'center' }}>
              <HideChipsButton contextHook={useBOMbyIdContext} />
              <FilterButtonWithPopover>
                <PopoverContent contextHook={useBOMbyIdContext} />
              </FilterButtonWithPopover>
              {AssignationModeComponents.Button}
              <Columns />
            </Stack>
          </Stack>
          <LocationChipsArea contextHook={useBOMbyIdContext} />
          <BOMItemsTable />
          {editingQuantityRowData && (
            <EditQuantityModal
              data={editingQuantityRowData}
              onClose={handleCloseQuantityDialog}
              ernPrefix="moab:bomitem"
              activeProject={currentProject}
            />
          )}
        </EditQuantityModalProvider>
      </ItemsIntegrationProvider>
    </CurrentProjectProvider>
  );
};

export default BillOfMaterialsById;

const BOMItemsToWRModal = () => {
  const { refetchBOMItems } = useRefetchBOMItems();
  const { handleAddItems, handleItemsCreated } = useBomToWRIntegration();
  return (
    <NewWRFormProvider>
      <ItemsIntegrationTableProvider onItemsCreated={handleItemsCreated} isBOMtoWR baseERN="ern:moab:bomitem">
        <ItemsIntegrationModal
          modalTitle="Add items to work request"
          integratedItemsERN="ern:shop:workrequestitem"
          addItems={handleAddItems}
          refetchQueries={refetchBOMItems}
        />
      </ItemsIntegrationTableProvider>
    </NewWRFormProvider>
  );
};

const BillOfMaterialsByIdTitle = ({ isFacility, projectName }) => {
  if (!isFacility) return <BreadcrumbLabel sx={{ fontSize: '16px' }} label={`${projectName} BOM`} />;

  return (
    <Breadcrumbs
      breadcrumbs={[{ label: 'BOMs', url: '../bill-of-materials' }, { label: projectName }]}
      separator={<BreadcrumbNextIcon />}
    />
  );
};

const HeaderWrapper = ({ children }) => (
  <Stack
    direction="row"
    mb={2}
    sx={{
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '8px',
    }}
  >
    {children}
  </Stack>
);

const getCurrentProject = (project) => ({
  id: project.projectId,
  label: project.projectName,
  type: ESearchType.PROJECT,
  identifier: project.projectIdentifier,
});
