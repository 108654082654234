import React from 'react';

import { Stack, Box } from '@mui/material';

import EntitlementPoolTable from '../EntitlementPoolTable/EntitlementPoolTable';

const LicensePoolViewer = () => (
  <Box sx={{ height: 'inherit' }}>
    <Stack direction="row" height="calc(100% - 72px)">
      <EntitlementPoolTable />
    </Stack>
  </Box>
);

export default LicensePoolViewer;
