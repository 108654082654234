import React, { useRef, useEffect } from 'react';

import { InputForesiteField } from '@evolvemep/foresite-components-ui-react';
import { CircularProgress, Box, useTheme } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import { useForm } from 'react-hook-form';

import { Custom } from 'components/Icons';
import StackRow from 'components/StackRow';
import createCustomEvent from 'helpers/createCustomEvent';
import useKeyPressEffect from 'hooks/useKeyPressEffect';

import { BOMCatalogTableFields } from '../hooks/useBOMTableColumns/BOMTableColumnsHelpers';
import useUpdateWriteInName from '../hooks/useBOMTableColumns/LineItemName/useUpdateWriteInName';

interface RenameWriteInInputProps {
  id: string;
  defaultValue?: string;
  autoFocus?: boolean;
}

interface CustomEvent extends Event {
  detail: any;
}

type FormValues = {
  lineItemName: string;
};

const RenameWriteInInput: React.FC<RenameWriteInInputProps> = ({
  id,
  defaultValue = '',
  autoFocus = false,
  ...props
}) => {
  const apiRef = useGridApiContext();
  const rows = Array.from(apiRef?.current?.getRowModels().values());
  const selectedRow = rows.find((data) => data.lineItemId === id) || {};
  const allowSubmitRef = useRef({ allow: true, id: selectedRow });
  const { palette } = useTheme();
  const { control, formState, watch, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      lineItemName: defaultValue,
    },
  });

  const closeForm = () => {
    const allowSubmit = createCustomEvent('custom:submitEvent', { allow: true, id: selectedRow.lineItemId });
    const cellMode = apiRef.current.getCellMode(selectedRow.lineItemId, BOMCatalogTableFields.LINE_ITEM_NAME);
    window.dispatchEvent(allowSubmit);

    if (selectedRow && 'lineItemId' in selectedRow && cellMode === 'edit') {
      apiRef.current.stopCellEditMode({
        id: selectedRow.lineItemId,
        field: BOMCatalogTableFields.LINE_ITEM_NAME,
        ignoreModifications: true,
      });
    }
  };

  const { updateName, loading } = useUpdateWriteInName();

  const submit = async () => {
    if (!allowSubmitRef.current) return;

    const currentName = watch('lineItemName');

    if (currentName.trim() === '' || currentName.trim() === defaultValue) {
      closeForm();
      return;
    }

    const disallowSubmit = createCustomEvent('custom:submitEvent', { allow: false, id: selectedRow.lineItemId });
    window.dispatchEvent(disallowSubmit);
    await updateName(id, currentName.trim());

    closeForm();
  };

  useKeyPressEffect('Escape', () => closeForm());

  useKeyPressEffect(
    'Enter',
    handleSubmit(async () => submit()),
  );

  useEffect(() => {
    const handleCustomEvent = (event: Event) => {
      const customEvent = event as CustomEvent;
      if (selectedRow === customEvent.detail.id) {
        allowSubmitRef.current.allow = true;
      } else {
        allowSubmitRef.current.allow = customEvent.detail.allow;
      }
    };

    window.addEventListener('custom:submitEvent', handleCustomEvent);

    return () => {
      window.removeEventListener('custom:submitEvent', handleCustomEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StackRow sx={{ width: 'calc(100% - 12px)', pr: 1 }}>
      {formState.isSubmitting || loading ? (
        <CircularProgress color="secondary" size={17} sx={{ mr: '10px' }} />
      ) : (
        <Box width="18px" height="17px" mx="10px" sx={{ path: { fill: (palette.action as any).lightSurface.active } }}>
          <Custom.WriteInItemIcon />
        </Box>
      )}
      <InputForesiteField
        control={control as any}
        defaultValue={defaultValue}
        name="lineItemName"
        id="testing-a"
        autoFocus={autoFocus}
        fullWidth
        variant="outlined"
        autoComplete="none"
        onBlur={handleSubmit(async () => submit())}
        size="extra-small"
        maxLength={128}
        disabled={formState.isSubmitting || loading}
        {...props}
      />
    </StackRow>
  );
};

export default RenameWriteInInput;
