export const tooltipNames = {
  catalog: 'Catalog',
  'procurement-tasks': 'Procurement tasks',
  'field-tasks': 'Field tasks',
  'shop-tasks': 'Shop tasks',
  decisions: 'Decisions',
  snippets: 'Snippets',
  settings: 'Properties',
  history: 'Version History',
  'connector-tasks': 'Connectors',
  'action-tasks': 'Actions',
  properties: 'Properties',
};
