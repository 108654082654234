import React from 'react';

import Section from '../../../../components/Section';
import useBreadcrumbBuilder from '../ItemsSection/ItemsListSection/useBreadcrumbBuilder';
import { CategoriesHeader } from './CategoriesHeader';
import CategoriesList from './CategoriesList';

const Categories = () => {
  const { categoriesHiearachy } = useBreadcrumbBuilder();

  return (
    <Section mr="5px" ml="1px">
      <Section.Title>
        <CategoriesHeader addNewCategoryButton />
      </Section.Title>
      <Section.Body>
        <CategoriesList hiearachy={categoriesHiearachy} showMenu />
      </Section.Body>
    </Section>
  );
};

export default Categories;
