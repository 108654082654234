import React from 'react';

import Box from '@mui/material/Box';

import Header from './Header';

const CardItem = ({ children, containerSx, ...rest }) => (
  <CardContainer sx={containerSx}>
    <Header variantTitle="h6" {...rest} />
    {children}
  </CardContainer>
);

const CardContainer = ({ sx, children }) => (
  <Box
    sx={{
      border: 1,
      borderRadius: '4px',
      borderColor: 'other.border',
      padding: '12px 24px',
      width: '100%',
      height: 'calc(100vh - 180px)',
      overflowY: 'auto',
      ...sx,
    }}
  >
    {children}
  </Box>
);

export default CardItem;
