import React from 'react';

import { IconButton, InputAdornment, useTheme } from '@mui/material';

import { NumberForesiteField } from 'components/FormComponents/NumberForesiteField';
import { Custom } from 'components/Icons';

import { buildQuantityInputStyles } from '../helpers/styleHelpers';
import { validateMaxLengthRule } from './validateRule';

const noNegativeNumbersValidator = (value) => {
  if (value?.toString()?.includes('.')) return 'Invalid';
  if (parseInt(value, 10) < 0) return 'Invalid';
  return validateMaxLengthRule(value, 6);
};

export const QuantityInput = ({
  control,
  sx = {},
  disabled,
  rules = {},
  isEditingQuantity = false,
  handleResetValue = () => {},
  displayClearButton = false,
  error = undefined,
  ...rest
}) => {
  const { palette } = useTheme();

  return (
    <NumberForesiteField
      id="quantity-text-field"
      name="quantity"
      placeholder="Qty"
      size="small"
      color="secondary"
      control={control}
      rules={{
        validate: (value) => noNegativeNumbersValidator(value),
        ...rules,
      }}
      maxLength={6}
      sx={{
        ...buildQuantityInputStyles(isEditingQuantity, disabled, error),
        ...sx,
      }}
      InputProps={{
        style: { fontSize: 14 },
        inputProps: {
          min: 0,
          max: 999999,
        },
        ...(displayClearButton
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    size="small"
                    sx={{ width: '24px', height: '24px', padding: '3px' }}
                    onClick={handleResetValue}
                  >
                    <Custom.Close fill={palette.action.active} data-testid="close-button" />
                  </IconButton>
                </InputAdornment>
              ),
            }
          : {}),
      }}
      data-cy="qty-input"
      onClick={(event) => event.stopPropagation()}
      disabled={disabled}
      {...rest}
    />
  );
};
