import React from 'react';

import IconButton from '@mui/material/IconButton';

import { Custom } from 'components/Icons';

const SortArrow = ({ direction, isVisible, isHovering }) => (
  <IconButton
    sx={{
      opacity: () => {
        if (isVisible) return 1;
        return !isVisible && isHovering ? 0.3 : 0;
      },
    }}
  >
    {direction === 'desc' ? (
      <Custom.ArrowDown />
    ) : (
      <Custom.ArrowDown style={{ transform: 'rotate(180deg)' }} />
    )}
  </IconButton>
);

export default SortArrow;
