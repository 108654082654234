import React from 'react';

import { InputForesiteField } from '@evolvemep/foresite-components-ui-react';

import { ProfilePhoto } from 'components';
import PhoneControlField from 'components/FormComponents/PhoneControlField';
import { textInputPattern } from 'constants/inputFieldRules';

const EditProfileForm = ({ watch, setValue, trigger, profilePicture, ...props }) => (
  <>
    <ProfilePhoto src={profilePicture} name="profilePicture" setValue={setValue} isEditable isShowCaption />
    <ControlInput name="firstName" label="First Name" rules={textInputPattern('First name')} {...props} />
    <ControlInput name="lastName" label="Last Name" rules={textInputPattern('Last name')} {...props} />
    <PhoneInput
      name="workPhoneNumber"
      label="Work Number"
      rulesText="Work number"
      setValue={setValue}
      trigger={trigger}
      {...props}
    />
    <PhoneInput
      name="personalPhoneNumber"
      label="Personal Number"
      rulesText="Personal number"
      setValue={setValue}
      trigger={trigger}
      watch={watch}
      {...props}
    />
  </>
);

export default EditProfileForm;

const ControlInput = ({ ...props }) => <InputForesiteField size="small" sx={{ mt: '20px' }} {...props} />;

const PhoneInput = ({ ...props }) => <PhoneControlField size="small" sx={{ mt: '20px' }} {...props} />;
