/* eslint-disable max-lines-per-function */
import React, { createContext, useState, useCallback, useMemo, useContext, useEffect } from 'react';

import { gql } from '@apollo/client';

import { partsCloudSearch as PARTS_CLOUD_SEARCH } from 'graphql/queries';
import { DEFAULT_QUERY_PARAMS_BY_PART_NAME } from 'helpers/cloudSearchParams';
import { stringifyCloudQuery } from 'helpers/stringFunctions';
import usePartsCloudSearchAPI from 'hooks-api/usePartsCloudSearchAPI';
import useLazyPaginatedQuery from 'hooks/useLazyPaginatedQuery';

const DEFAULT_CONTEXT_VALUE = {
  isSearching: false,
  searchValue: '',
  setSearchValue: () => {},
  onSearchKeyChange: () => {},
  searchBreadcrumbs: [],
  reset: () => {},
  parts: [],
  partsCount: 0,
  onSearchParts: () => {},
  onFetchMoreParts: () => {},
  searchKey: '',
  onSelectPartFromSearch: () => {},
};

const PartCategorySearchContext = createContext(DEFAULT_CONTEXT_VALUE);

const DEFAULT_SEARCH_BREADCRUMBS = [
  {
    index: 0,
    searchKey: 'Search',
  },
  {
    index: 1,
    searchKey: '',
  },
];

const breadcrumbsMapCb = (searchKey) => (el) => !el.index ? el : { ...el, searchKey };

const PartCategorySearchProvider = ({
  children,
  catalogId = '',
  noAssemblies = false,
  disablePartId = null,
}) => {
  const [isSearching, setIsSearching] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchBreadcrumbs, setSearchBreadcrumbs] = useState(DEFAULT_SEARCH_BREADCRUMBS);
  const [parts, setParts] = useState([]);
  const [partsCount, setPartsCount] = useState(0);

  const { getPartsCount } = usePartsCloudSearchAPI(catalogId);
  const [{ lazyLoad: fetchParts, paginationHandler: fetchMoreParts }, { data: partsResponse }] =
    useLazyPaginatedQuery(gql(PARTS_CLOUD_SEARCH), 'cache-and-network');

  const onSearchKeyChange = useCallback((searchKey) => {
    setSearchBreadcrumbs((prevState) => prevState.map(breadcrumbsMapCb(searchKey)));

    if (!searchKey || searchKey.length < 3) {
      setIsSearching(false);
      return;
    }

    setIsSearching(true);
  }, []);

  const reset = useCallback(() => {
    setIsSearching(false);
    setSearchBreadcrumbs(DEFAULT_SEARCH_BREADCRUMBS);
    setSearchValue('');
  }, []);

  const onSearchParts = useCallback(
    async (searchKey) => {
      setIsSearching(true);
      setSearchBreadcrumbs((prevState) => prevState.map(breadcrumbsMapCb(searchKey)));

      const partsCountRes = await getPartsCount(searchKey);
      setPartsCount(partsCountRes);

      fetchParts({
        query: stringifyCloudQuery(searchKey, catalogId),
        ...DEFAULT_QUERY_PARAMS_BY_PART_NAME,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getPartsCount, catalogId, noAssemblies],
  );

  const onFetchMoreParts = useCallback(
    (skip) => {
      const [, { searchKey }] = searchBreadcrumbs;
      fetchMoreParts(skip, {
        query: stringifyCloudQuery(searchKey, catalogId),
        ...DEFAULT_QUERY_PARAMS_BY_PART_NAME,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchBreadcrumbs, catalogId, noAssemblies],
  );

  useEffect(() => {
    setParts(partsResponse?.partsCloudSearch ?? []);
  }, [partsResponse]);

  const onSelectPartFromSearch = useCallback((part) => {
    setSearchBreadcrumbs((prevState) => prevState.map(breadcrumbsMapCb(part.partName)));
    setPartsCount(1);
    setParts([{ ...part }]);
  }, []);

  const searchKey = useMemo(() => {
    const [, { searchKey: currentSearchKey }] = searchBreadcrumbs;
    return currentSearchKey;
  }, [searchBreadcrumbs]);

  const contextValue = useMemo(
    () => ({
      isSearching,
      searchValue,
      setSearchValue,
      searchBreadcrumbs,
      onSearchKeyChange,
      reset,
      parts,
      partsCount,
      onSearchParts,
      onFetchMoreParts,
      searchKey,
      onSelectPartFromSearch,
      noAssemblies,
      disablePartId,
    }),
    [
      isSearching,
      searchValue,
      setSearchValue,
      onSearchKeyChange,
      searchBreadcrumbs,
      reset,
      onSearchParts,
      partsCount,
      onFetchMoreParts,
      parts,
      searchKey,
      onSelectPartFromSearch,
      noAssemblies,
      disablePartId,
    ],
  );

  return (
    <PartCategorySearchContext.Provider value={contextValue}>
      {children}
    </PartCategorySearchContext.Provider>
  );
};

const usePartCategorySearch = () => {
  const context = useContext(PartCategorySearchContext);
  if (context === undefined) {
    throw new Error('usePartCategorySearch must be used within a PartCategorySearchContext');
  }
  return context;
};

export { PartCategorySearchContext, PartCategorySearchProvider, usePartCategorySearch };
