import React from 'react';

import Button from '@mui/material/Button';

import { Custom } from 'components/Icons';

const AddFilterButton = ({ onClick, disabled = false }) => (
  <Button
    color="secondary"
    onClick={onClick}
    disabled={disabled}
    data-testid="add-filter-button"
    sx={{
      fontWeight: '500',
      letterSpacing: '0.46px',
      color: 'secondary.dark',
      fontSize: '13px',
      height: '24px',
    }}
    startIcon={<Custom.AddIcon />}
  >
    ADD FILTER
  </Button>
);

export default AddFilterButton;
