const taskTypesPopupScrollBarStyle = {
  mb: 1,
  mt: 2,
  mr: 0.5,
  overflowX: 'hidden',

  /* Track */
  '::-webkit-scrollbar-track': {
    background: 'rgba(255, 255, 255, 0.68)',
  },

  /* Handle */
  '::-webkit-scrollbar-thumb': {
    background: 'white',
  },

  '& div[data-rbd-droppable-id="Task Types"] > div:first-of-type': {
    mt: 0,
  },

  '& div[data-rbd-droppable-id="Machines"] > div:first-of-type': {
    mt: 0,
  },
};

export { taskTypesPopupScrollBarStyle };
