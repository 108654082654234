export const WORK_ORDERS_COLORS = {
  0: '#5D74EE',
  1: '#FD825B',
  2: '#9B56D1',
  3: '#04AF72',
  4: '#BF8C0A',
  5: '#1993BA',
  6: '#FF7A00',
  7: '#D316C0',
  8: '#00CC2D',
  9: '#A4674E',
  10: '#044189',
  11: '#94550C',
  12: '#87277D',
  13: '#055C4D',
  14: '#28178F',
  15: '#A47090',
  16: '#DC6E88',
  17: '#374F2F',
  18: '#DB621E',
  19: '#8AA317',
};

export const FOREGROUND_COLORS = {
  blue: '#000698',
  white: '#FFFFFF',
  red: '#F44336',
  purple: '#7701D3',
  yellow: '#926901',
  green: '#04AF72',
  orange: '#FF4D00',
  brown: '#6C464F',
  forest: '#374F2F',
  gray: '#000000DE',
  cyan: '#00C2FF',
};
export const BACKGROUND_COLORS = {
  blue: '#E3E3FF',
  white: '#FFFFFF',
  red: '#FFEEEE',
  purple: '#FAF3FD',
  yellow: '#FFF7E2',
  green: '#EDFFF9',
  orange: '#FEF1E8',
  brown: '#FAF9F9',
  forest: '#F2FAF8',
  gray: '#E9E9E9',
  cyan: '#F2FCFF',
};
