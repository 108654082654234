import React from 'react';

import { CircularProgress, Stack, Typography, Box } from '@mui/material';

import { ProgressWrapper } from 'components/FormComponents/InputImage/components/InputImageStyles';

const PartAdditionalImageLoader = ({ uploadingProgress, uploadingPartAdditionalImages }) => (
  <>
    {uploadingPartAdditionalImages && (
      <Stack direction="row" mt={2}>
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress
            color="secondary"
            variant={uploadingProgress === null ? 'indeterminate' : 'determinate'}
            value={uploadingProgress}
          />
          {uploadingProgress !== null && (
            <ProgressWrapper>
              <Typography variant="caption" component="div">
                {`${Math.round(uploadingProgress)}%`}
              </Typography>
            </ProgressWrapper>
          )}
        </Box>
      </Stack>
    )}
  </>
);

export default PartAdditionalImageLoader;
