import React from 'react';

import { Box } from '@mui/material';

import MoreOptionsDropdown from 'components/TableComponents/MoreOptionsDropdown/MoreOptionsDropdown';

import MoreTableRowOptions from '../../Components/MoreTableRowOptions';

export const MoreMenuCell =
  (
    hoveredRequestId,
    setWorkRequest,
    setWorkRequestDeleteModalOpen,
    setDeletingRequestId,
    setDeletingRequestIdentifier,
  ) =>
  ({ row }) =>
    row?.workRequestId === hoveredRequestId && ['Draft', 'Canceled', 'Rejected'].includes(row.workRequestStatusName) ? (
      <MoreOptionsDropdown
        optionsRenderer={(onClose) => (
          <MoreTableRowOptions
            setRenameWorkRequest={() => {
              setWorkRequest(row);
              onClose();
            }}
            setWorkRequestModalDeleteOpen={() => {
              setWorkRequestDeleteModalOpen(true);
              setDeletingRequestId(row?.workRequestId);
              setDeletingRequestIdentifier(row?.workRequestIdentifier);
              onClose();
            }}
          />
        )}
      />
    ) : (
      <Box sx={{ width: '40px' }} />
    );
