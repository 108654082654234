import React from 'react';

import { gql, useMutation } from '@apollo/client';
import { Typography, Stack } from '@mui/material';

import { Modal } from 'components';
import { deleteAssemblyCustomCategory as DELETE_CUSTOM_ASSEMBLY_TYPES } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

import { useCustomAssemblyTypesContext } from '../../CustomAssemblyTypesContext';
import DeleteModalFooter from './DeleteModalFooter';

const DeleteConfirmationModal = ({ open, onClose }) => {
  const { currentRow, setCurrentRow } = useCustomAssemblyTypesContext();
  const { handleResponse } = useGraphqlResponseHandler();
  const [deleteCustomAssembly, { loading }] = useMutation(gql(DELETE_CUSTOM_ASSEMBLY_TYPES), {
    refetchQueries: ['AssemblyCustomCategories'],
  });

  const onConfirm = async () => {
    await handleResponse(
      deleteCustomAssembly,
      {
        variables: {
          params: {
            assemblyCustomCategoryId: currentRow.id,
          },
        },
      },
      { successMessage: 'Assembly type successfully deleted' },
      () => setCurrentRow(null),
    );
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Confirm delete"
      paperSx={{ width: '505px' }}
      footer={<DeleteModalFooter onClose={onClose} onConfirm={onConfirm} loading={loading} />}
      data-cy="DeleteConfirmationModal"
    >
      <Stack direction="row" marginBottom="16px">
        <Typography fontSize="16px">{'Are you sure you want to delete\u00A0'}</Typography>
        <Typography fontSize="16px" fontWeight="600">
          {currentRow?.identifier}
        </Typography>
        <Typography fontSize="16px">{'\u00A0?'}</Typography>
      </Stack>
    </Modal>
  );
};

export default DeleteConfirmationModal;
