import { gql, useLazyQuery } from '@apollo/client';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import {
  shopProperties as GET_SHOP_PROPERTIES,
  workCells as GET_WORK_CELLS,
} from 'graphql/queries';
import useGetShopConfigurations from 'modules/Shop/ShopProperties/InfoTab/useGetShopConfigurations';

const useShopFacility = () => {
  const { shopDepartmentId } = useFacilitiesProjects();

  const getFacilityId = (selectedItem) =>
    selectedItem?.type === 'FACILITY' ? selectedItem?.id : null;
  const { selectedItem: selectedFacility } = useFacilitiesProjects();
  const { activeShopConfiguration, loadingShopConfiguration } = useGetShopConfigurations();

  const [getShopProperty, { loading: loadingShop, data: shopData }] = useLazyQuery(
    gql(GET_SHOP_PROPERTIES),
    {
      variables: { query: { departmentId: shopDepartmentId } },
      fetchPolicy: 'cache-and-network',
    },
  );
  const [getWorkCells, { loading: loadingWorkCells, data: workCellsRed }] = useLazyQuery(
    gql(GET_WORK_CELLS),
    {
      variables: { query: { shopConfigurationId: activeShopConfiguration?.value } },
      fetchPolicy: 'cache-and-network',
    },
  );

  const refetchData = () => {
    getShopProperty({ variables: { query: { departmentId: shopDepartmentId } } });
    getWorkCells({
      variables: { query: { shopConfigurationId: activeShopConfiguration?.value } },
    });
  };

  const fetchInitialData = () => {
    getShopProperty();
    getWorkCells();
  };

  const loading = loadingShop || loadingWorkCells || loadingShopConfiguration;

  return {
    getFacilityId,
    selectedFacility,
    activeShopConfiguration,
    getShopProperty,
    shopData,
    workCellsData: workCellsRed,
    loading,
    getWorkCells,
    refetchData,
    fetchInitialData,
  };
};

export default useShopFacility;
