import React from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { ThemedCheckBox } from 'components';

type Props = {
  onCheck: React.ComponentProps<typeof ThemedCheckBox>['onChange'];
};

const DeletedUserFilter = ({ onCheck }: Props) => (
  <Stack direction="row" alignItems="center">
    <ThemedCheckBox onChange={onCheck} />
    <Typography component="p" noWrap>
      Show Only Deleted Members
    </Typography>
  </Stack>
);

export default DeletedUserFilter;
