import React, { useEffect, useRef } from 'react';

import { Box } from '@mui/material';

import PopperWithArrow from 'components/PopperWithArrow/PopperWithArrow';

import DecisionsData from './DecisionsData';
import { filterLineItemsWithDecisions } from './helpers';
import { useDecisionsApi } from './hooks/useDecisionsApi';
import useGetLineItemsAdded from './hooks/useGetLineItemsAdded';
import { useHandlePopper } from './hooks/useHandlePopper';
import useMapDecisionsData from './hooks/useMapDecisionsData';

const PopperModifiers = [
  {
    name: 'offset',
    options: {
      offset: [0, 30],
    },
  },
  {
    name: 'preventOverflow',
    options: {
      padding: 30,
    },
  },
  {
    name: 'flip',
    options: {
      padding: 30,
    },
  },
];

const DecisionAdded = ({ row, isTakeoff = false }) => {
  const { open, anchorEl, handleOpen, handleClose } = useHandlePopper();
  const {
    lineItemDecision,
    lineItemsAdded,
    updateLineItemsAdded,
    catalogItemsPendingToAdd,
    setCatalogItemsPendingToAdd,
    onAddWriteInItems,
    setAllDecisionsMade,
    allDecisionsMade,
    refetchCatalogItems,
    chipList,
    assignModeOn,
  } = useGetLineItemsAdded();
  const { getDecisions, decisions, loadingDecisions, refetchDecisions } = useDecisionsApi();
  const { mapDecisionsMade } = useMapDecisionsData();

  useEffect(() => {
    if (row?.lineItemId === lineItemDecision?.lineItemId) {
      getDecisions(row?.lineItemId);
      handleOpen({ currentTarget: anchorRef?.current });
      return;
    }

    if (open) handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineItemDecision]);

  const anchorRef = useRef(null);

  const handleNextButton = () => {
    const currentPosition = lineItemsAdded.indexOf(lineItemDecision);
    handleClose();
    updateLineItemsAdded(lineItemsAdded[currentPosition + 1]);
  };

  const onClose = () => {
    handleClose();
    updateLineItemsAdded(null);
    setAllDecisionsMade([]);
    setCatalogItemsPendingToAdd(null);
    refetchCatalogItems(true);
  };

  const isThelastDecision = () => {
    const currentPosition = lineItemsAdded.indexOf(lineItemDecision);

    return currentPosition === lineItemsAdded.length - 1;
  };

  const onAddCatalogItems = async () => {
    const newCatalogItems = await onAddWriteInItems(catalogItemsPendingToAdd);

    if (chipList?.length !== 0 && !assignModeOn) return;

    await filterLineItemsWithDecisions(
      newCatalogItems?.data?.addBillOfMaterialsWriteInItem || newCatalogItems?.data?.addTakeOffPadItem,
      updateLineItemsAdded,
    );
  };

  const addDecisionMadeToQueue = (decisionsMade) => {
    if (!decisionsMade) {
      setAllDecisionsMade([]);
      return;
    }

    const decisionsMadeMapped = mapDecisionsMade(decisionsMade, row?.lineItemId, row?.quantity);

    setAllDecisionsMade((prev) => [...prev, decisionsMadeMapped]);
  };

  return (
    <>
      <Box ref={anchorRef} sx={{ position: 'absolute', left: '220px' }} />
      <PopperWithArrow open={open} anchorEl={anchorEl} modifiers={PopperModifiers}>
        <DecisionsData
          decisions={decisions || []}
          onClose={onClose}
          onNext={!isThelastDecision() ? handleNextButton : undefined}
          row={row}
          loadingOptions={loadingDecisions}
          isTakeoff={isTakeoff}
          refetchDecisions={refetchDecisions}
          catalogItemsPendingToAdd={catalogItemsPendingToAdd}
          onAddCatalogItems={onAddCatalogItems}
          addDecisionMadeToQueue={addDecisionMadeToQueue}
          allDecisionsMade={allDecisionsMade}
        />
      </PopperWithArrow>
    </>
  );
};

export default DecisionAdded;
