import React from 'react';

import { Button } from '@mui/material';

const FilterFooterButton = ({ enableButtons = false, onCancel, onClear, onSubmit }) => (
  <>
    {enableButtons && (
      <>
        <Button
          variant="text"
          sx={{
            fontWeight: '500',
            color: 'secondary.dark',
          }}
          onClick={onCancel}
          data-testid="cancel-filters"
        >
          CANCEL
        </Button>
        <Button
          variant="outlined"
          sx={{
            fontWeight: '500',
            color: 'secondary.dark',
          }}
          onClick={onClear}
          data-testid="clear-all-filters"
        >
          CLEAR FILTERS
        </Button>
      </>
    )}
    <Button
      variant="contained"
      color="secondary"
      sx={{
        fontWeight: '500',
      }}
      onClick={onSubmit}
      disabled={!enableButtons}
      data-testid="done-filters"
    >
      DONE
    </Button>
  </>
);

export default FilterFooterButton;
