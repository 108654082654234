import React, { useState, useContext, createContext, useCallback } from 'react';

import { useBillOfMaterialsItemAPI } from 'hooks-api/useBillOfMaterialsItemAPI';

export const BOMItemContext = createContext();

export const BOMItemProvider = ({ children }) => {
  const [selectedItem, setSelectedItem] = useState(null);

  const {
    callbacks: { fetch },
    data: itemDetail,
    metadata: { loading: loadingItemDetail },
  } = useBillOfMaterialsItemAPI();

  const fetchBOMItem = useCallback(() => {
    if (selectedItem) {
      fetch({
        variables: { params: { id: selectedItem.lineItemId } },
      });
    }
  }, [selectedItem, fetch]);

  return (
    <BOMItemContext.Provider
      value={{
        selectedItem,
        fetchBOMItem,
        setSelectedItem,
        itemDetail: itemDetail?.billOfMaterialItemById,
        loadingItemDetail,
      }}
    >
      {children}
    </BOMItemContext.Provider>
  );
};

export const useBOMItemContext = () => {
  const context = useContext(BOMItemContext);
  if (!context) throw new Error('useBOMItem context must be used within a BOMItem provider');
  return context;
};
