import React from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import Divider from 'components/Divider';

const HelpDeskSliderMenuContent = () => (
  <DropdownContentWrapper>
    <HelpDeskTitle />
    <Divider sx={{ marginBottom: '32px' }} />
    <MenuLink onClick={() => {}}>Contact Support</MenuLink>
    <MenuLink onClick={() => {}}>End-User License Agreement</MenuLink>
    <MenuLink onClick={() => {}}>Privacy Policy</MenuLink>
    <MenuLink onClick={() => {}}>Cookie Policy</MenuLink>
  </DropdownContentWrapper>
);

export default HelpDeskSliderMenuContent;

const DropdownContentWrapper = ({ children }) => <Box padding="18px 35px 0px 35px">{children}</Box>;

const HelpDeskTitle = () => (
  <Typography
    align="left"
    color="white"
    variant="h5"
    sx={{
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }}
  >
    Help Desk
  </Typography>
);

const MenuLink = ({ children, onClick }) => (
  <Box mt="40px">
    <Typography variant="h6">
      <Link onClick={onClick} sx={{ color: 'other.links', cursor: 'pointer' }}>
        {children}
      </Link>
    </Typography>
  </Box>
);
