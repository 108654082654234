import { Divider, IconButton, List, ListItem, Stack, Typography } from '@mui/material';

import { Modal } from 'components';
import { Custom } from 'components/Icons';
import { ICONS_MAP } from 'constants/globalConstants';
import { BillOfMaterialItem } from 'typings/bom-items';

export const ModalNotAllowDeletePadItem = ({
  padItemNotAllow,
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
  padItemNotAllow: BillOfMaterialItem[];
}) => (
  <Modal
    title={
      <Stack direction="row" alignItems="center" justifyContent="flex-start">
        <IconButton onClick={onClose} sx={{ padding: 0, margin: 0 }}>
          <Custom.KeyboardArrowLeftIcon style={{ height: 35, width: 35, padding: 0, margin: 0 }} />
        </IconButton>
        <Typography variant="body1">Back</Typography>
      </Stack>
    }
    paperSx={{ maxWidth: 505, height: 428, maxHeight: 428 }}
    open={isOpen}
    onClose={onClose}
    hideBackdrop
  >
    <Typography component="span">
      <text style={{ fontWeight: 'bold' }}>{padItemNotAllow.length} items</text> cannot be deleted because they have
      been previously sent to a work request or requisition:
    </Typography>
    <List dense style={{ maxHeight: 428 }}>
      {padItemNotAllow.map(({ lineItemName, lineItemId, lineItemTypeName }) => {
        const Icon = ICONS_MAP[lineItemTypeName] ?? Custom.AssemblyIcon;
        return (
          <>
            <ListItem key={lineItemId} sx={{ gap: '12px' }} style={{ marginLeft: 0, paddingLeft: 0 }}>
              <Icon width={20} height={20} fill="rgba(0, 0, 0, 0.5)" />
              <Typography variant="body2" sx={{ lineHeight: '20px' }}>
                {lineItemName}
              </Typography>
            </ListItem>
            <Divider key={`${lineItemId}-divider`} sx={{ marginLeft: 0 }} variant="inset" component="li" />
          </>
        );
      })}
    </List>
  </Modal>
);
