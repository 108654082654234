import React, { useEffect, useRef, useState } from 'react';

import { Box, Tooltip, Typography } from '@mui/material';

import { OverflowToolTipProps } from './@types/Tooltip';

const counterTracker = {
  scrollWidth: 0,
  clientWidth: 0,
};

const OverFlowTooltip = ({
  title,
  sx = {},
  showAlways = false,
  placement = 'top',
  arrow = true,
  lowerTooltip,
  children,
}: OverflowToolTipProps) => {
  const [isOverflowed, setIsOverflow] = useState<boolean>(false);
  const textElementRef = useRef(counterTracker);

  useEffect(() => {
    setIsOverflow(textElementRef.current?.scrollWidth > textElementRef.current?.clientWidth);
  }, []);

  const shouldDisplayTooltip: boolean = showAlways || isOverflowed;

  return (
    <Tooltip
      title={
        title && (
          <Typography fontWeight="500" fontSize={10} lineHeight={lowerTooltip ? '20px' : '16px'}>
            {title}
          </Typography>
        )
      }
      PopperProps={{
        modifiers: lowerTooltip ? [{ name: 'offset', options: { offset: [0, -5] } }] : [{}],
      }}
      disableHoverListener={!shouldDisplayTooltip}
      placement={placement}
      arrow={arrow}
    >
      <Box
        ref={textElementRef}
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: 'inline',
          ...sx,
        }}
      >
        {children}
      </Box>
    </Tooltip>
  );
};

export default OverFlowTooltip;
