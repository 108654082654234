import { useSnackbar } from 'notistack';

import { VARIANT_SUCCESS } from 'constants/snackbarConstants';
import { BulkAmount } from 'graphql/types';

import { INode } from '../@types/modalContextTypes';
import { useModal } from '../contexts/ModalContext';
import { useProjectRow } from '../contexts/ProjectRowContext';
import { FOOTER_MESSAGE } from '../LocationsTable/constants/constants';

function buildChildrenLBS(nodeTree: INode[]): BulkAmount[] {
  const result: BulkAmount[] = [];

  function getModifiedNodes(node: INode) {
    if (node.isModifiedDirectly === true) {
      const lbs: BulkAmount = {};

      if ((node.type as string) === 'Location') lbs.locationId = node.id;
      else if ((node.type as string) === 'WorkPhase') {
        lbs.workPhaseId = node.locationPhaseWorkPhaseId;
        if (node.locationPhaseLocationId) lbs.locationId = node.locationPhaseLocationId;
      }

      result.push({
        amount: node.amount,
        ...lbs,
      });
    }

    if (node.children && node.children.length > 0) {
      node.children.forEach((child) => getModifiedNodes(child));
    }
  }

  nodeTree.forEach((child) => getModifiedNodes(child));

  return result;
}

const useSubmitForm = () => {
  const { nodeTree, setFooterMessage } = useModal();
  const { updateLBSBulk, unassignedQty, unassignedIsDirty } = useProjectRow();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (onClose = () => {}) => {
    const LBSArray = buildChildrenLBS(nodeTree[0].children);
    if (unassignedIsDirty) LBSArray.push({ amount: Number(unassignedQty) });

    const successUpdate = await updateLBSBulk(LBSArray);

    if (successUpdate) {
      enqueueSnackbar(FOOTER_MESSAGE.EDITED_SUCCESS, VARIANT_SUCCESS as any);
      onClose();
    } else setFooterMessage(FOOTER_MESSAGE.ERROR_SAVING);
  };

  return {
    handleSubmit,
  };
};

export default useSubmitForm;
