import React, { useState } from 'react';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';

import { useFacilitiesProjects, formatProject } from 'app/FacilitiesProjectsContext';

import ProjectDeleteModal from './ProjectDeleteModal';

const MoreTableRowOptions = ({ project }) => {
  const { setSelectedItem } = useFacilitiesProjects();
  const [openDelete, setOpenDelete] = useState(false);
  const navigate = useNavigate();

  const onEditClick = () => {
    setSelectedItem(formatProject(project));
    navigate('/admin/properties/project-properties');
  };

  return (
    <Box>
      <MenuItem onClick={() => setOpenDelete(true)}>Delete</MenuItem>{' '}
      <MenuItem onClick={onEditClick}>Edit</MenuItem>{' '}
      <ProjectDeleteModal
        projectId={project.projectId}
        openStatus={openDelete}
        onClose={() => setOpenDelete(false)}
      />
    </Box>
  );
};

export default MoreTableRowOptions;
