import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { CenteredLoadSpinner } from 'components';
import { FILE_TYPE_FORMATS_DR } from 'constants/fileConstants';
import { FILE_FORMATS } from 'constants/globalConstants';
import { convertURL } from 'helpers/urlFunctions';
import useFileDownloader from 'hooks/useFileDownloader';
import { CombinedDocumentType } from 'hooks/usePaginatedDocuments';

import { ROUNDED_BORDER_STYLE } from '../constants/constants';
import { useTaskViewerContext } from '../TaskViewerContext';
import ActiveDocument, { TEXT_ELLIPSIS_STYLE } from './components/ActiveDocument';
import DocumentIcon from './components/DocumentIcon';
import DownloadButton from './components/DownloadButton';
import Link from './components/Link';
import { ContextProps } from './types/ContextType';

const PlansModelsContent = () => {
  const {
    drawingDocuments,
    activeDocument,
    selectedIndexTable,
    setActiveDocument,
    setSelectedIndexTable,
    getDocumentPreSignedUrl,
    taskName,
    getUrlLoading,
    setTypeFileFormat,
    drawingPaginationWaypoint,
  }: ContextProps = useTaskViewerContext();
  const { handleDownloadFile, handleDownloadZip } = useFileDownloader();

  const handleViewDocument = (document: CombinedDocumentType) => {
    if (document.extension === '.pdf') {
      setTypeFileFormat(FILE_FORMATS.PDF);
    }
    setSelectedIndexTable('drawings');
    setActiveDocument(document);
  };

  const handleDownloadSingleFile = async (document: CombinedDocumentType) => {
    if (document.externalUrl) {
      const url = convertURL(document.externalUrl);
      window.open(url, '_blank');
    } else {
      const documentUrl = await getDocumentPreSignedUrl(document.storagePath as string);
      handleDownloadFile(documentUrl, document.documentNameFormatted);
    }
  };

  const handleDownloadAll = async () => {
    const documentsUrls = await Promise.all(
      drawingDocuments.map(async (document) => {
        const documentUrl = await getDocumentPreSignedUrl(document.storagePath as string);
        return { fileName: document?.documentNameFormatted, fileUrl: documentUrl };
      }),
    );

    handleDownloadZip(taskName, documentsUrls);
  };

  if (!drawingDocuments?.length) return <></>;

  return (
    <Stack sx={{ ...ROUNDED_BORDER_STYLE, mt: 1, pl: 2, py: 1 }}>
      {Boolean(activeDocument) && selectedIndexTable === 'drawings' && (
        <ActiveDocument document={activeDocument} handleDownload={() => handleDownloadSingleFile(activeDocument)} />
      )}
      {Boolean(drawingDocuments.length) && <DownloadAllButton onClick={handleDownloadAll} loading={getUrlLoading} />}
      <Stack gap="12px" sx={{ mr: 0.5, mt: 1, flex: 1, overflowY: 'overlay' }}>
        {drawingDocuments
          ?.filter((document) => document !== activeDocument)
          ?.map((document) => (
            <TableRow key={document?.documentId}>
              <Stack direction="row" alignItems="center" gap="10px" sx={{ path: { fillOpacity: 0.54 } }}>
                <DocumentIcon extension={document?.extension} typeId={document?.documentTypeId} />
                <Link
                  disabled={!FILE_TYPE_FORMATS_DR.includes(`.${document.extension}`)}
                  onClick={() => {
                    handleViewDocument(document);
                  }}
                  sx={{
                    color: 'surface.lightSurface.secondary',
                    ...TEXT_ELLIPSIS_STYLE,
                  }}
                >
                  {document?.documentName}
                </Link>
              </Stack>
              <DownloadButton onClick={() => handleDownloadSingleFile(document)} />
            </TableRow>
          ))}
        {drawingPaginationWaypoint()}
      </Stack>
    </Stack>
  );
};

export default PlansModelsContent;

const TableRow = ({ children }: { children: any }) => (
  <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ pr: 1.5 }}>
    {children}
  </Stack>
);

const DownloadAllButton = ({ onClick, loading }: { onClick: () => void; loading: boolean }) => {
  if (loading)
    return (
      <CenteredLoadSpinner
        sx={{
          m: 0,
          position: 'absolute',
          top: -24,
          right: 29,
          width: '20px',
          '& .MuiCircularProgress-root': {
            width: '16px !important',
            height: '16px !important',
          },
        }}
      />
    );

  return (
    <Typography
      variant="caption"
      onClick={onClick}
      sx={{
        letterSpacing: '0.4px',
        position: 'absolute',
        top: -28,
        right: 4,
        cursor: 'pointer',

        '&:hover': {
          fontWeight: 500,
        },
      }}
    >
      Download all
    </Typography>
  );
};
