/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

import { Box, CircularProgress, Container, Link, Typography } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import useBreakpoint from 'hooks/useBreakpoint';

import { confirm } from '../auth/restCalls';
import ErrorMessage from '../components/ErrorMessages';
import JoinForm from './JoinForm';

const Join = ({ name, type, id, code }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (success) {
      navigate('/auth/login', { state: { inviteConfirm: true } });
    }

    return () => setSuccess(false);
  }, [success, setSuccess, navigate]);

  const handleSubmit = async ({ password }) => {
    setLoading(true);

    const body = {
      confirmationCode: code,
      password,
    };

    try {
      const res = await confirm(id, type, body);

      if (res) setSuccess(true);
    } catch ({ message }) {
      setError(message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      {error && <ErrorMessage code={error} onClose={() => setError('')} />}
      <Title inviteDescription={name} />
      <JoinForm onSubmit={handleSubmit} type={type} />
      <LoadingCircleSpacer isLoading={loading} />
      <DontHaveAccountLink />
    </Container>
  );
};

export default Join;

const Title = ({ inviteDescription }) => (
  <Typography sx={{ marginBottom: '24px' }} component="h1" variant="h4" color="#10143D">
    {`You have been invited to join ${inviteDescription}`}
  </Typography>
);

const LoadingCircleSpacer = ({ isLoading }) => (
  <Box height="80px" display="flex" justifyContent="center" alignItems="center">
    {isLoading && <CircularProgress color="secondary" />}
  </Box>
);

const DontHaveAccountLink = () => {
  const { isAboveDesktop } = useBreakpoint();

  return (
    <Box mb={isAboveDesktop ? '0px' : '80px'}>
      <Typography variant="body1" component="span">
        Already have an account?
      </Typography>{' '}
      <Link variant="body1" component={RouterLink} to="/auth/login" sx={{ color: '#4A93FF' }}>
        Login here.
      </Link>
    </Box>
  );
};
