import React, { FC, useEffect, useState } from 'react';

import { Checkbox, Stack, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { useItemsIntegrationModalContext } from '../../context/ItemsIntegrationModalContext';
import ProjectBreakdownStructureDropdown from './components/ItemsIntegrationTable/components/ProjectBreakdownStructureDropdown/ProjectBreakdownStructureDropdown';
import { ADD_REMAINING_QUANTITIES_FIELD } from './components/ItemsIntegrationTable/constants';
import { useItemsIntegrationTableContext } from './components/ItemsIntegrationTable/context/ItemsIntegrationTableContext';
import ItemsIntegrationTable from './components/ItemsIntegrationTable/ItemsIntegrationTable';

const QuantityStep = () => {
  const { locationsTableDescription } = useItemsIntegrationModalContext();
  return (
    <Stack gap="16px" pt="24px" pb="9px" height="100%">
      {locationsTableDescription && <StepDescription text={locationsTableDescription} />}
      <Stack gap="24px" flexDirection="row">
        <Stack width="312px">
          <ProjectBreakdownStructureDropdown />
        </Stack>
        <RemainingQuantitiesCheckbox />
      </Stack>
      <ItemsIntegrationTable />
    </Stack>
  );
};

export default QuantityStep;

const StepDescription: FC<{ text: string }> = ({ text }) => (
  <Typography variant="body2" fontWeight={500}>
    {text}
  </Typography>
);

const RemainingQuantitiesCheckbox: FC = () => {
  const { control, getValues } = useFormContext();
  const { isLoading } = useItemsIntegrationModalContext();
  const { isLoadingRows } = useItemsIntegrationTableContext();
  const [disableCheckbox, setDisableCheckbox] = useState<boolean>(true);
  useEffect(() => {
    if (!isLoading && !isLoadingRows) {
      setDisableCheckbox(false);
      return;
    }
    setDisableCheckbox(true);
  }, [isLoading, isLoadingRows]);
  return (
    <Stack flexDirection="row" alignItems="center" width="261" sx={{ opacity: disableCheckbox ? 0.5 : 1 }}>
      <Controller
        control={control}
        name={ADD_REMAINING_QUANTITIES_FIELD}
        render={({ field }) => (
          <Checkbox
            {...field}
            disabled={disableCheckbox}
            defaultChecked={getValues(ADD_REMAINING_QUANTITIES_FIELD)}
            color="secondary"
          />
        )}
      />
      <Typography variant="body2">Add all remaining quantities</Typography>
    </Stack>
  );
};
