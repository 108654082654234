import React, { useState } from 'react';

import { CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';

import { VARIANT_ERROR, VARIANT_SUCCESS } from 'constants/snackbarConstants';
import { WorkRequest } from 'graphql/types';
import useRefetchWorkRequestsByChildWorkRequest from 'modules/Field/WorkRequests/hooks/useRefetchWorkRequestsByChildWorkRequest';
import useUpdateWorkRequestStatus from 'modules/Field/WorkRequests/WorkRequest/hooks/useUpdateWorkRequestStatus';
import ModalNeedByDate from 'modules/Field/WorkRequests/WorkRequest/ModalNeedByDate';

import { StatusTypeName } from '../@types/StatusTypeName';
import TableButton from '../TableButton';

type SubmitActionProps = { workRequest: WorkRequest };

const SubmitAction = ({ workRequest }: SubmitActionProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const statusTypeName = (workRequest?.workRequestStatusName as StatusTypeName) ?? undefined;
  const { updateWorkRequestStatus, loading } = useUpdateWorkRequestStatus();
  const { refetchWorkRequestByChildWorkRequest } = useRefetchWorkRequestsByChildWorkRequest();

  const { enqueueSnackbar } = useSnackbar();

  const handleUpdateWorkRequestStatus = async (
    workRequestToUpdate: WorkRequest,
    label: string,
    description: string,
  ) => {
    try {
      setIsSubmitting(true);
      const result = await updateWorkRequestStatus(workRequestToUpdate, label, description);
      await refetchWorkRequestByChildWorkRequest(workRequestToUpdate);
      enqueueSnackbar(result, VARIANT_SUCCESS);
    } catch (err) {
      enqueueSnackbar('Could not save changes.', VARIANT_ERROR);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClick = async () => {
    if (workRequest?.needBy) {
      await handleUpdateWorkRequestStatus(workRequest, 'Submitted', '');
      return;
    }
    setModalOpened(true);
  };

  const shouldDisplayButton = statusTypeName && ['Draft', 'Rejected', 'Canceled'].includes(statusTypeName);

  if (!shouldDisplayButton) return <></>;

  return (
    <>
      {isSubmitting ? (
        <CircularProgress size={24} sx={{ color: 'secondary.main' }} />
      ) : (
        <>
          <TableButton onClick={handleClick} disabled={loading}>
            Submit
          </TableButton>
          <ModalNeedByDate
            openStatus={modalOpened}
            onClose={() => setModalOpened(false)}
            workRequest={workRequest}
            updateWorkRequestStatus={handleUpdateWorkRequestStatus}
            loadingWRStatus={false}
          />
        </>
      )}
    </>
  );
};

export default SubmitAction;
