import { QueryHookOptions, gql, useQuery } from '@apollo/client';

import { workOrderStatusesList as GET_WORK_ORDER_STATUSES_LIST } from 'graphql/queries';
import { WorkOrderStatusesList } from 'graphql/types';

type useQueryWorkOrderStatusesListProps = {
  options?: QueryHookOptions<{ workOrderStatusesList: Array<WorkOrderStatusesList> }>;
};

export const useQueryWorkOrderStatusesList = ({ options }: useQueryWorkOrderStatusesListProps) => {
  const result = useQuery<{
    workOrderStatusesList: Array<WorkOrderStatusesList>;
  }>(gql(GET_WORK_ORDER_STATUSES_LIST), options);

  return { ...result };
};
