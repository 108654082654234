import React, { useEffect, useState } from 'react';

import FullScreenModal from 'components/FullScreenModal';
import { clone, recursiveToggleKey, replaceItemsById } from 'helpers/arrayFunctions';
import OutlinedCard from 'modules/Field/components/OutlinedCard';
import OutlinedCardHeaderText from 'modules/Field/components/OutlinedCardHeaderText';

import { ROW_TYPES } from '../../constants/constants';
import { useLocationsAndWorkPhases } from '../../contexts/LocationsAndWorkPhaseContext';
import LocationsRow from '../components/LocationsRow';
import PreviewCheckbox from '../CreateLocationsModal/CreateChipForm/components/FormFields/PreviewCheckbox';
import { useChips } from '../CreateLocationsModal/CreateLocationsModalContext';

const mapChipsToLocations = (array) =>
  array.reduce((arr, item) => {
    if (item.isDraft) return arr;

    const mappedChildren = {};
    if (item.children) mappedChildren.children = mapChipsToLocations(item.children);
    const locationIs = (askedLocations) => askedLocations.includes(item.locationTypeName);

    const location = {
      ...mappedChildren,
      locationId: item.id,
      locationTypeName: item.locationTypeName,
      isOpen: true,
      locationIdentifier: locationIs([ROW_TYPES.LOCATION_SYSTEM, ROW_TYPES.WORK_PHASE])
        ? ''
        : `${item.identifierPrefix}${item.identifierDelimiter}${item.startOn}`,
    };

    if (locationIs([ROW_TYPES.WORK_PHASE])) {
      item.workPhase?.forEach((workPhase) =>
        arr.push({
          ...location,
          locationId: workPhase.workPhaseId || workPhase.label,
          locationName: workPhase.workPhaseName || workPhase.label,
        }),
      );
    } else {
      arr.push({
        ...location,
        locationName: locationIs([ROW_TYPES.LOCATION_SYSTEM])
          ? item.namePrefix
          : `${item.namePrefix}${item.nameDelimiter}${item.startOn}`,
      });
    }

    return arr;
  }, []);

const PreviewModal = ({ control, open, ...props }) => {
  const {
    locations: { locations, activeRow },
  } = useLocationsAndWorkPhases();

  const { chips } = useChips();
  const [previewLocations, setPreviewLocations] = useState([]);

  const getActiveRowChildren = () => activeRow.children || [];

  const buildNewChildren = () => ({
    ...activeRow,
    isOpen: true,
    children: [...getActiveRowChildren(), ...mapChipsToLocations(chips)],
  });

  const appendChipsToSelectedLocation = () =>
    setPreviewLocations(replaceItemsById(buildNewChildren(), clone(locations), 'locationId'));

  const handleToggleOpen = (node) =>
    setPreviewLocations(
      recursiveToggleKey(node.locationId, previewLocations, 'isOpen', 'locationId'),
    );

  useEffect(() => {
    if (open) appendChipsToSelectedLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <FullScreenModal
      open={open}
      titleContent={<PreviewCheckForm checked={open} control={control} />}
      {...props}
    >
      <LocationsTable>
        {previewLocations?.map((location) => (
          <LocationsRow
            key={location.locationId}
            node={location}
            isPreview
            handleToggleOpen={handleToggleOpen}
          />
        ))}
      </LocationsTable>
    </FullScreenModal>
  );
};

export default PreviewModal;

const PreviewCheckForm = ({ ...props }) => (
  <form>
    <PreviewCheckbox
      sx={{ color: 'surface.lightSurface.primary' }}
      checkboxProps={{ sx: { ml: 3 } }}
      {...props}
    />
  </form>
);

const LocationsTable = ({ children }) => (
  <OutlinedCard
    hideAddButton
    header={
      <>
        <OutlinedCardHeaderText paddingLeft={3}>Id</OutlinedCardHeaderText>
        <OutlinedCardHeaderText paddingLeft={15}>Location</OutlinedCardHeaderText>
      </>
    }
    sx={{ minWidth: 588, width: 'fit-content' }}
  >
    {children}
  </OutlinedCard>
);
