import { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import { objIsEmpty } from 'helpers/objectFunctions';
import { ASSEMBLY_ITEM_TYPE_ID, WRITE_IN_ASSEMBLY } from 'modules/Field/WorkRequests/WorkRequest/constants';
import { NEW_UOM_OPTION } from 'modules/Materials/BillOfMaterialsById/BOMCatalogTable/Form';

import { useTableRowContext } from '../context/TableRowContext';
import { useWorkOrderItemsContext } from '../context/WorkOrderItemsContext';
import { useAddWorkOrderItemDataLayer } from './dataLayer/useAddWorkOrderItemDataLayer';
import { useUpdateWorkOrderItemDataLayer } from './dataLayer/useUpdateWorkOrderItemDataLayer';

export const useWorkOrderItemFormEvents = () => {
  const { trigger, formState, reset, watch } = useFormContext();
  const { setNewUomId, workOrder } = useWorkOrderItemsContext();
  const { setShowForm, isAddItemRow, item, isWriteInItem } = useTableRowContext();
  const { handleAddWorkOrderItem } = useAddWorkOrderItemDataLayer(workOrder);
  const { handleUpdateWorkOrderItem } = useUpdateWorkOrderItemDataLayer(workOrder);
  const formValues = watch();
  const { isValid, isDirty, errors } = formState;

  const closeFields = () => {
    setShowForm(false);
    setNewUomId(null);
    reset();
  };

  const submitItem = async () => {
    if (!isDirty) {
      closeFields();
      return;
    }

    const formIsValid = isValid && formValues.unitOfMeasureId.label !== NEW_UOM_OPTION.label;

    if (!formIsValid) {
      trigger();
      return;
    }
    if (isAddItemRow) handleAddWorkOrderItem(formValues);
    else {
      const hasModifiedName = item.workOrderItemName !== formValues.workOrderItemName;
      handleUpdateWorkOrderItem({
        ...formValues,
        workOrderItemId: item.workOrderItemId,
        itemType: isWriteInItem || hasModifiedName ? WRITE_IN_ASSEMBLY : ASSEMBLY_ITEM_TYPE_ID,
      });
    }

    closeFields();
  };

  const onKeyDown = (event) => {
    if (event.key === 'Enter') submitItem();
    if (event.key === 'Escape') closeFields();
    event.stopPropagation();
  };

  const handleBlur = (event) => {
    if (Object.keys(errors).length) {
      closeFields();
    }
    event.stopPropagation();
  };

  const handlePinnedRowsChangeErrorSize = (hasError = false) => {
    const el = document.querySelector('.MuiDataGrid-pinnedRows');
    if (hasError) return el?.classList.add('error');
    return el?.classList.remove('error');
  };

  useEffect(() => {
    if (!isAddItemRow) return;

    const hasErrors = !objIsEmpty(formState.errors);
    handlePinnedRowsChangeErrorSize(hasErrors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState]);

  return {
    submitItem,
    onKeyDown,
    handleBlur,
    handlePinnedRowsChangeErrorSize,
  };
};
