import React from 'react';

import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';

const EntitlementPoolHeader = () => (
  <StyledCardHeader>
    <HeaderText sx={{ minWidth: '120px' }}>Entitlement Pool</HeaderText>
    <Stack flexDirection="row" gap="56px">
      <HeaderText>Quantity</HeaderText>
      <HeaderText>Assigned</HeaderText>
      <HeaderText>Available</HeaderText>
      <HeaderText>Start Date</HeaderText>
      <HeaderText>Renewal Date</HeaderText>
    </Stack>
  </StyledCardHeader>
);

export default EntitlementPoolHeader;

const StyledCardHeader = ({ children }) => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    sx={{
      padding: 3,
      paddingRight: '51px',
      height: 56,
      borderBottom: '1px solid',
      borderColor: 'other.border',
    }}
  >
    {children}
  </Stack>
);

const HeaderText = ({ children, sx }) => (
  <Typography noWrap sx={{ fontSize: 14, lineHeight: '24px', fontWeight: 900, ...sx }}>
    {children}
  </Typography>
);
