import { useEffect } from 'react';

const useKeyPressEffect = (keyName: string, callback: () => void) => {
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === keyName) callback();
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyName, callback]);
};

export default useKeyPressEffect;
