export const mapInformation = (workRequest) => {
  let modifiedWorkRequest;

  if (workRequest) {
    modifiedWorkRequest = {
      ...workRequest,
      facilityName: workRequest.facilityName,
      projectName: workRequest.projectName,
      workRequestName: workRequest.workRequestName,
    };
  }
  return modifiedWorkRequest;
};
