import React from 'react';

import { customMaxLengthRule } from 'constants/inputFieldRules';

import TextField from './TextField';

const IdentifierPrefixInput = ({ ...props }) => (
  <TextField
    name="identifierPrefix"
    label="Id Prefix"
    sx={{ flex: 2, marginTop: 0 }}
    placeholder="ex. Bldg"
    InputLabelProps={{ shrink: true }}
    rules={customMaxLengthRule(5, 'Id prefix limited to 5 characters')}
    {...props}
  />
);

export default IdentifierPrefixInput;
