import React, { useCallback, useEffect, useMemo } from 'react';

import { gql } from '@apollo/client';

import { messages as MESSAGES } from 'graphql/queries';
import useLazyPaginatedQuery from 'hooks/useLazyPaginatedQuery';

import { MessageStatus } from './MessagesContext';

const ARCHIVE_MESSAGES_QUERY_VARIABLE = {
  status: MessageStatus.ARCHIVED,
  read: 'all',
};

const ArchiveMessagesContext = React.createContext({
  messages: [],
  loading: false,
  onSearch: () => {},
  onFetchMore: () => {},
});

const ArchiveMessagesProvider = ({ children }) => {
  const [{ lazyLoad, searchHandler, paginationHandler }, { loading, data }] = useLazyPaginatedQuery(
    gql(MESSAGES),
  );

  useEffect(() => {
    lazyLoad(ARCHIVE_MESSAGES_QUERY_VARIABLE);
  }, [lazyLoad]);

  const onSearch = useCallback(
    (searchPhrase) => {
      searchHandler(searchPhrase, ARCHIVE_MESSAGES_QUERY_VARIABLE);
    },
    [searchHandler],
  );

  const onFetchMore = useCallback(
    (skip) => {
      paginationHandler(skip, ARCHIVE_MESSAGES_QUERY_VARIABLE);
    },
    [paginationHandler],
  );

  const contextValue = useMemo(
    () => ({ messages: data?.messages ?? [], loading, onSearch, onFetchMore }),
    [data, loading, onSearch, onFetchMore],
  );

  return (
    <ArchiveMessagesContext.Provider value={contextValue}>
      {children}
    </ArchiveMessagesContext.Provider>
  );
};

const useArchiveMessagesContext = () => {
  const context = React.useContext(ArchiveMessagesContext);
  if (context === undefined) {
    throw new Error('useArchiveMessagesContext must be used within a ArchiveMessagesProvider');
  }
  return context;
};

export { ArchiveMessagesContext, ArchiveMessagesProvider, useArchiveMessagesContext };
