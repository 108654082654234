import React from 'react';

import { GridRow } from '@mui/x-data-grid-pro';

import useUpdateWorkOrderSuscription from 'hooks-api/subscriptions/useUpdateWorkOrderSuscription/useUpdateWorkOrderSuscription';

const RowComponent = ({ params, refetchTasks }) => {
  const { row } = params;

  const handleSuscriptionData = (data) => {
    if (data) refetchTasks();
  };

  useUpdateWorkOrderSuscription({
    variables: { workOrderId: row.workOrderId },
    onData: ({ data }) => handleSuscriptionData(data),
  });

  return <GridRow {...params} />;
};

export default React.memo(RowComponent);
