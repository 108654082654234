import React from 'react';

import { Box } from '@mui/material';

import { IPartNodeIconContainer } from './@types/partTypeNodeTypes';

const PartNodeIconContainer: React.FC<IPartNodeIconContainer> = ({
  children,
}: IPartNodeIconContainer) => (
  <Box
    style={{
      width: 64,
      height: 78,
      backgroundColor: '#E0E0E0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    {children}
  </Box>
);

export default PartNodeIconContainer;
