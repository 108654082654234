import React from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Custom } from 'components/Icons';

const DrawingDocumentNoRowsOverlay = () => (
  <Stack
    width="100%"
    height="100%"
    display="flex"
    flexDirection="column"
    alignItems="center"
    pt={18}
  >
    <Custom.DrawingDocument />
    <Typography variant="h6" sx={{ color: 'surface.lightSurface.secondary' }}>
      Start by creating folders or uploading drawings.
    </Typography>
  </Stack>
);

export default DrawingDocumentNoRowsOverlay;
