import React from 'react';

import { Step, StepLabel, Stepper } from '@mui/material';

const Stapper = ({ activeStep, steps }) => (
  <Stepper sx={{ width: '298px' }} nonLinear activeStep={activeStep}>
    {steps.map((step, index) => (
      <Step
        key={step}
        completed={activeStep > index}
        sx={{
          '& .MuiStepIcon-root': {
            '&.Mui-active': {
              color: 'secondary.main',
            },
            '&.Mui-completed': {
              color: 'secondary.main',
            },
          },
        }}
      >
        <StepLabel>{step}</StepLabel>
      </Step>
    ))}
  </Stepper>
);
export default Stapper;
