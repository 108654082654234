import React, { useState } from 'react';

import useWorkRequestDocumentApi from 'hooks-api/useWorkRequestDocumentApi';

import useFetchWorkRequestDocuments from '../../../../../hooks/useWorkRequestDocuments';
import { useWorkRequestsContext } from '../../WorkRequestsContext';

const WorkRequestPropertiesAttachmentsContext = React.createContext();

const WorkRequestPropertiesAttachmentsProvider = ({ children, showForm = true }) => {
  const { workRequestSelected, workOrderSelected } = useWorkRequestsContext();
  const {
    addWorkRequestDocumentLoading,
    callbacks: { addDocumentsToWorkRequest },
  } = useWorkRequestDocumentApi();

  const { workRequestDocuments, loading, paginationWaypoint } = useFetchWorkRequestDocuments(
    workOrderSelected?.workRequestId || workRequestSelected?.workRequestId,
  );

  const [selectedWorkRequestDocument, setSelectedWorkRequestDocument] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [showUrlInput, setShowUrlInput] = useState(false);
  const selectedStateObj = {
    workRequestDocuments,
    fetchWorkRequestDocumentsLoading: loading,
    addDocumentsToWorkRequest,
    addWorkRequestDocumentLoading,
    setSelectedWorkRequestDocument,
    selectedWorkRequestDocument,
    setModalOpen,
    modalOpen,
    showForm,
    showUrlInput,
    setShowUrlInput,
    paginationWaypoint,
  };

  return (
    <WorkRequestPropertiesAttachmentsContext.Provider value={selectedStateObj}>
      {children}
    </WorkRequestPropertiesAttachmentsContext.Provider>
  );
};

const useWorkRequestPropertiesAttachmentsContext = () => {
  const context = React.useContext(WorkRequestPropertiesAttachmentsContext);

  if (context === undefined) {
    throw new Error(
      'useWorkRequestPropertiesAttachmentsContext must be used within a WorkRequestPropertiesAttachmentsContext',
    );
  }

  return context;
};

export {
  WorkRequestPropertiesAttachmentsContext,
  WorkRequestPropertiesAttachmentsProvider,
  useWorkRequestPropertiesAttachmentsContext,
};
