import React from 'react';

import { GridActionsCellItem } from '@mui/x-data-grid-pro';

import { Custom } from 'components/Icons';

import { useTasksTableApiContext, useTasksTableDataContext } from './TasksTableContext';

const DeleteCell = ({ id, canDelete, row }) => {
  const { hoveredRowId } = useTasksTableDataContext();
  const { setDeleteModalOpened, setSelectedItem } = useTasksTableApiContext();

  if (!canDelete) return null;

  const isHoveringRow = id?.toString() === hoveredRowId;
  if (!isHoveringRow) return null;

  return (
    <GridActionsCellItem
      icon={<Custom.Trash data-testid="deleteTaskIcon" />}
      label="Delete"
      onClick={() => {
        setDeleteModalOpened(true);
        setSelectedItem(row);
      }}
    />
  );
};

export default React.memo(DeleteCell);
