/* eslint-disable func-names */
import AutodeskEventBus from './AutodeskEventBus';

function PageNavExtension(viewer, options) {
  window.Autodesk.Viewing.Extension.call(this, viewer, options);
}
PageNavExtension.prototype = Object.create(window.Autodesk.Viewing.Extension.prototype);
PageNavExtension.prototype.constructor = PageNavExtension;

PageNavExtension.prototype.load = function () {
  return true;
};

PageNavExtension.prototype.onToolbarCreated = function (toolbar) {
  const prevButton = new window.Autodesk.Viewing.UI.Button('[PageNavExtension]_prev_btn');
  prevButton.onClick = function () {
    AutodeskEventBus.dispatch('page_nav', { direction: 'prev' });
  };
  prevButton.addClass('autodesk-prev-page-btn');
  prevButton.setIcon('adsk-icon-arrow');
  prevButton.setToolTip('Previous');

  const pageNumButton = new window.Autodesk.Viewing.UI.Button('[PageNavExtension]_page_num_btn');
  pageNumButton.addClass('autodesk-page_num_btn');

  const nextButton = new window.Autodesk.Viewing.UI.Button('[PageNavExtension]_next_btn');
  nextButton.onClick = function () {
    AutodeskEventBus.dispatch('page_nav', { direction: 'next' });
  };
  nextButton.addClass('autodesk-next-page-btn');
  nextButton.setIcon('adsk-icon-arrow');
  nextButton.setToolTip('Next');

  this.subToolbar = new window.Autodesk.Viewing.UI.ControlGroup('page-navigation-toolbar');
  this.subToolbar.addControl(prevButton);
  this.subToolbar.addControl(pageNumButton);
  this.subToolbar.addControl(nextButton);

  toolbar.addControl(this.subToolbar, { index: 0 });
};

PageNavExtension.prototype.unload = function () {
  if (this.subToolbar) {
    this.viewer.toolbar.removeControl(this.subToolbar);
    this.subToolbar = null;
  }
};

window.Autodesk.Viewing.theExtensionManager.registerExtension('PageNavExtension', PageNavExtension);
