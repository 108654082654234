import React, { FC, useMemo } from 'react';

import { Box, Typography, useTheme } from '@mui/material';

import StackRow from 'components/StackRow';
import { PickIcon } from 'modules/Materials/commons/FilterPopoverContent/Breakdown/HelperElements';

import { QtyRemainingCell } from '../LocationRow/constants';
import LBSRowInfo from '../LocationRow/LBSRowInfo';
import { QuantityFieldType } from '../QuantityField/hooks/@types/fieldHookTypes';
import QuantityField from '../QuantityField/QuantityField';
import RowWrapper from '../RowWrapper/RowWrapper';

interface IWorkPhaseRow {
  row: QuantityFieldType;
  nestLevel: number;
  isScopePackage?: boolean;
  lineItemId: string;
}

const WorkPhaseRow: FC<IWorkPhaseRow> = ({ row, nestLevel, isScopePackage = false, lineItemId }: IWorkPhaseRow) => {
  const { palette } = useTheme();
  const qtyFieldName = useMemo(
    () => `${row.locationId ? `${row.locationId}__` : ''}${lineItemId}__${row.workPhaseId}`,
    [row, lineItemId],
  );
  if (!isScopePackage && !row.qtyRemaining) return <></>;
  return (
    <RowWrapper qtyRemaining={row.qtyRemaining} qtyAggregate={row.qtyAggregate} qtyFieldName={qtyFieldName}>
      <StackRow sx={{ flex: 1, gap: '8px', width: `${418 - nestLevel * 24}px` }}>
        <Box sx={{ width: `${nestLevel * 24 + 8}px` }} />
        <StackRow>
          <Box width="32px" />
          <PickIcon
            iconSx={{ '& path': { fill: `${palette.secondary.main} !important`, fillOpacity: '1' } }}
            margin="0"
            type={isScopePackage ? 'Work Phase' : 'Work Phase'}
            chipWidth={undefined}
          />
        </StackRow>
        <LBSRowInfo rowInfo={{ identifier: row.workPhaseName }} />
      </StackRow>
      <StackRow marginRight="16px" gap="16px">
        <Box width="96px">
          <QtyRemainingCell
            name={row.name || ''}
            qtyAggregate={row.qtyAggregate ?? 0}
            qtyRemaining={row.qtyRemaining ?? 0}
          >
            <Typography marginRight="20px" textAlign="right" variant="body2">
              {row.qtyRemaining} {Boolean(row.qtyAggregate && !row.qtyRemaining) && ` | ${row.qtyAggregate}`}
            </Typography>
          </QtyRemainingCell>
        </Box>
        <QuantityField
          rowIdentifier={row.locationIdentifier || ''}
          qtyRemaining={row.qtyRemaining ?? 0}
          qtyAggregate={row.qtyAggregate ?? 0}
          name={qtyFieldName}
          isWorkPhase
        />
      </StackRow>
    </RowWrapper>
  );
};

export default WorkPhaseRow;
