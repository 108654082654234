import React from 'react';

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { SnackbarProvider } from 'notistack';

import { CustomThemeProvider } from '../styles';
import AppRoutes from './AppRoutes';
import { FacilitiesProjectsProvider } from './FacilitiesProjectsContext';

const Notistack = ({ children }) => (
  <SnackbarWrapper>
    <SnackbarProvider
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      maxSnack={3}
      autoHideDuration={3000}
    >
      {children}
    </SnackbarProvider>
  </SnackbarWrapper>
);

const App = () => (
  <FacilitiesProjectsProvider>
    <CustomThemeProvider>
      <CssBaseline />
      <AppWrapper>
        <Notistack>
          <AppRoutes />
        </Notistack>
      </AppWrapper>
    </CustomThemeProvider>
  </FacilitiesProjectsProvider>
);

export default App;

const AppWrapper = (props) => <Box sx={{ margin: '0 auto', minHeight: '100vh' }} {...props} />;

const SnackbarWrapper = (props) => (
  <Box sx={{ '& .SnackbarContent-root': { minWidth: 'unset !important' } }} {...props} />
);