import React from 'react';

import { Stack, Typography } from '@mui/material';

const WorkOrderInProgressInformation = () => (
  <Stack
    sx={{
      maxWidth: '547px',
      border: 1,
      borderRadius: '4px',
      borderColor: 'other.border',
      padding: '8px 16px',
    }}
  >
    <Typography variant="subtitle2">Save data</Typography>
    <Typography variant="caption">
      Will record and preserve production time data and allow you to continue where you left off
    </Typography>
    <Typography variant="subtitle2" mt="18px">
      Discard data
    </Typography>
    <Typography variant="caption">
      Will delete all production time data for the task and allow you to start over
    </Typography>
  </Stack>
);

export default WorkOrderInProgressInformation;
