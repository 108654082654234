export const addNewSubcategory = async (
  parentPartCategoryId,
  selectedCatalog,
  categoryName,
  addSubCategory,
) => {
  const body = {
    parentPartCategoryId,
    partCatalogId: selectedCatalog?.partCatalogId,
    partCategoryName: categoryName.trim(),
  };
  await addSubCategory({ variables: { body } });
};

export const updateSubcategory = async (parentPartCategoryId, categoryName, changeName) => {
  const variables = {
    params: {
      id: parentPartCategoryId,
    },
    body: {
      partCategoryName: categoryName.trim(),
    },
  };
  await changeName({ variables });
};
