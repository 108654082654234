import { useState, useCallback, useEffect } from 'react';

import { RECORDING_STATES } from 'constants/globalConstants';
import { useTaskViewerContext } from 'modules/Shop/Fabrication/TaskViewer/TaskViewerContext';

import { useHandleVisibilityState } from './useHandleVisibilityState';

export const useAudioRecording = () => {
  const {
    handleUpdateTaskStatus,
    unitTime: { timeToMilliseconds },
    taskStatus,
  } = useTaskViewerContext();

  const { handleVisibility } = useHandleVisibilityState();

  const [isActive, setIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [time, setTime] = useState(0);

  const isCompleted = taskStatus === RECORDING_STATES.Completed;
  const isStarted = taskStatus === RECORDING_STATES.Started;

  useEffect(() => {
    if (handleVisibility && !isCompleted) setTime(timeToMilliseconds || 0);
    if (!isActive && !isCompleted) setTime(timeToMilliseconds || 0);
    if (!isCompleted && timeToMilliseconds > 0 && isStarted) {
      setIsActive(true);
      setIsPaused(false);
    }
  }, [timeToMilliseconds, isStarted, isActive, isCompleted, handleVisibility]);

  useEffect(() => {
    let interval = null;

    if (isActive && isPaused === false) {
      interval = setInterval(() => {
        setTime((time) => time + 10);
      }, 10);
    } else clearInterval(interval);

    return () => clearInterval(interval);
  }, [isActive, isPaused]);

  const handleCancelModal = () => setOpenModal(false);

  const handleOpenModal = () => setOpenModal(true);

  const handleStart = useCallback(() => {
    handleUpdateTaskStatus(RECORDING_STATES.Started);
    setIsActive(true);
    setIsPaused(false);
  }, [handleUpdateTaskStatus]);

  const handlePauseResume = useCallback(() => {
    handleUpdateTaskStatus(RECORDING_STATES.Paused);
    setIsPaused(!isPaused);
  }, [isPaused, handleUpdateTaskStatus]);

  const handleComplete = useCallback(async () => {
    const { data, errors } = await handleUpdateTaskStatus(RECORDING_STATES.Completed);
    if (data && !errors?.length) {
      handleCancelModal();
      setIsPaused(true);
      setIsActive(false);
      setTime(0);
    }
  }, [handleUpdateTaskStatus]);

  const completedTime = (time) => (isCompleted || taskStatus === 'Paused' ? time : '00:00:00');

  return {
    handleStart,
    handlePauseResume,
    handleComplete,
    isPaused,
    isActive,
    time,
    handleCancelModal,
    handleOpenModal,
    openModal,
    completedTime,
    isCompleted,
  };
};
