import React from 'react';

import MUIDivider from '@mui/material/Divider';

const Divider = ({ sx, ...props }) => (
  <MUIDivider
    sx={{
      backgroundColor: 'other.divider.darkSurface',
      ...sx,
    }}
    {...props}
  />
);

export default Divider;
