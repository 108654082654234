import React from 'react';

import { CircularProgress, FormControl, MenuItem, Typography } from '@mui/material';

import { darkSurfaceScrollBarStyle } from 'styles/theme/scrollBarStyle';

import { CatalogSearchDropdownLabel } from './CatalogSearchDropdownLabel';
import { CatalogSearchDropdownSelect } from './CatalogSearchDropdownSelect';

const CatalogSearchDropDown = ({ value = '', onChange = () => {}, loading = false, catalogs }) => {
  if (loading) return <CircularProgress sx={{ color: 'secondary.mainDarkBackground' }} />;

  return (
    <FormControl data-testid="catalog-search-dropdown-form" size="small">
      <CatalogSearchDropdownLabel>Catalog</CatalogSearchDropdownLabel>
      <CatalogSearchDropdownSelect
        value={value}
        onChange={onChange}
        menuSx={{
          background: 'rgba(48, 48, 48, 0.95)',
          boxShadow: 1,
          borderRadius: '4px',
          ...darkSurfaceScrollBarStyle,
        }}
      >
        {catalogs?.map(({ partCatalogId, partCatalogName }) => (
          <MenuItem key={partCatalogId} value={partCatalogId}>
            <Typography variant="body2" sx={{ color: 'white' }}>
              {partCatalogName}
            </Typography>
          </MenuItem>
        ))}
      </CatalogSearchDropdownSelect>
    </FormControl>
  );
};

export default CatalogSearchDropDown;
