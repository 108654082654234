import React, { useState } from 'react';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';

import { useProjectMembersContext } from './ProjectMembersContext';
import ProjectMembersDeleteModal from './ProjectMembersTable/ProjectMembersDeleteModal';

const ProjectMembersActions = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { selected, setSelected } = useProjectMembersContext();

  const validSelectedMembersForDeletion = selected.some((member) => !member.isDeleted);
  const selectedMemberIds = selected.map((user) => user.projectUserId);

  return (
    <Box>
      <MenuItem
        disabled={!validSelectedMembersForDeletion}
        onClick={() => setShowDeleteModal(true)}
      >
        Delete
      </MenuItem>{' '}
      <ProjectMembersDeleteModal
        projectUserIds={selectedMemberIds}
        open={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
          setSelected([]);
        }}
      />
    </Box>
  );
};

export default ProjectMembersActions;
