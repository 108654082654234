import React from 'react';

import { useMutation, gql } from '@apollo/client';
import Box from '@mui/material/Box';

import MenuItemWithSpinner from 'components/TableComponents/MoreOptionsDropdown/MenuItemWithSpinner';
import { resendUserInvitation as RESEND_INVITE } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

const MoreTableRowOptions = ({ evolveMemberId, onClose }) => {
  const { handleResponse } = useGraphqlResponseHandler(onClose);

  const [resendInvitation, { loading }] = useMutation(gql(RESEND_INVITE));

  const resendUserInvite = () => {
    handleResponse(resendInvitation, {
      variables: {
        params: { userId: evolveMemberId },
      },
    });
  };

  return (
    <Box>
      <MenuItemWithSpinner loading={loading} onClick={() => resendUserInvite()}>
        Resend Invite
      </MenuItemWithSpinner>
    </Box>
  );
};

export default MoreTableRowOptions;
