import React from 'react';

import StatusBadge from 'components/StatusBadges/StatusBadge';
import StatusBadgeColor from 'components/StatusBadges/StatusBadgeColor';
import OverFlowTooltip from 'components/Tooltip/OverFlowTooltip';
import { BADGE_MAPPING_STATUS } from 'constants/badgeMappingStatus';

const WorkRequestStatus = ({ status, pastDaysRemaining = false, isResponsiveWR = false, sx = {} }) => {
  const newStatus = BADGE_MAPPING_STATUS[status];
  const variant = pastDaysRemaining ? 'pastDaysRemaining' : newStatus?.variant || status;

  if (isResponsiveWR) {
    return (
      <OverFlowTooltip showAlways title={newStatus?.label} placement="bottom">
        <StatusBadgeColor
          data-testid="status-badge-color"
          key={newStatus?.variant}
          label={newStatus?.label || status}
          variant={variant}
          sx={{ backgroundColor: newStatus?.color || null, ...sx }}
          small
        />
      </OverFlowTooltip>
    );
  }

  return (
    <StatusBadge
      key={newStatus?.variant}
      label={newStatus?.label || status}
      variant={variant}
      sx={{ backgroundColor: newStatus?.color || null, ...sx }}
    />
  );
};

export default WorkRequestStatus;
