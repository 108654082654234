import React, { useCallback, useState } from 'react';

import { useReactiveVar } from '@apollo/client';
import Box from '@mui/material/Box';
import { useGridApiRef } from '@mui/x-data-grid-pro';

import { selectedWorkCellIdOnQueueVar } from 'apollo/reactiveVars';
import { CenteredLoadSpinner } from 'components';
import DeleteModal from 'components/DeleteModal';
import ItemsDataGridPro, { ItemsDataGridProProvider } from 'components/ItemsDatagridPro';

import NoRowsOverlay from './components/NoRowsOverlay';
import RowComponent from './components/RowComponent';
import { TABLE_STYLES } from './constants/style';
import useRenderCell from './hooks/useRenderCell';
import { useWorkCellQueueContext } from './WorkCellQueueContext';
import WorkCellTasksTableColumns from './WorkCellTasksTableColumns/WorkCellTasksTableColumns';

const WorkCellTasksTable = () => {
  const [itemToBeDeleted, setItemToBeDeleted] = useState();
  const selectedWorkCellId = useReactiveVar(selectedWorkCellIdOnQueueVar);
  const {
    selectedFacilityId,
    shopDepartmentId,
    workCellsTasks,
    loadingWorkCellsTasks,
    loadingDeleteTask,
    paginationHandler,
    handleDeleteTask,
    refetchTasks,
  } = useWorkCellQueueContext();

  const apiRef = useGridApiRef();

  const fetchMoreTaskTypes = useCallback((skip) => paginationHandler(skip), [paginationHandler]);

  const handleFetchMore = useCallback(() => {
    if (!workCellsTasks || !selectedWorkCellId) return;
    fetchMoreTaskTypes(workCellsTasks?.length);
  }, [workCellsTasks, selectedWorkCellId, fetchMoreTaskTypes]);

  const handleOpenModalToDelete = useCallback((task) => setItemToBeDeleted(task), []);
  const handleHideModalToDelete = useCallback(() => setItemToBeDeleted(null), []);
  const handleSubmitDeleteModal = useCallback(async () => {
    const response = await handleDeleteTask(itemToBeDeleted.workCellTaskId);
    if (response?.data?.deleteWorkCellTask) handleHideModalToDelete();
    refetchTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemToBeDeleted]);

  const { handleCellComponent } = useRenderCell(handleFetchMore, workCellsTasks, handleOpenModalToDelete);

  const columns = WorkCellTasksTableColumns(handleCellComponent);

  const noRowsOverlayMessage = (() => {
    if (!selectedFacilityId) return 'Select a facility to view work cells';
    if (!shopDepartmentId) return 'This facility does not currently have a Shop. Add a Shop to continue.';
    if (!selectedWorkCellId) return 'Select a work cell from the dropdown list';
    return loadingWorkCellsTasks ? <CenteredLoadSpinner theme="secondary" /> : 'No data';
  })();

  const getWorkRequestLists = React.useCallback(({ row }) => <p>{row.projectName} details</p>, []);
  const getDetailPanelHeight = React.useCallback(() => 'auto', []);

  const CustomRow = useCallback(
    (params) => <RowComponent params={params} refetchTasks={refetchTasks} />,
    [refetchTasks],
  );

  return (
    <Box sx={{ mt: '14px', mb: '14px', flexGrow: 1, height: 'calc(100vh - 260px)' }}>
      <ItemsDataGridProProvider contextId="WorkCellTasksTable">
        <ItemsDataGridPro
          apiRef={apiRef}
          disableColumnMenu
          disableSelectionOnClick
          columns={columns}
          rows={selectedWorkCellId ? workCellsTasks : []}
          headerHeight={32}
          rowHeight={32}
          components={{
            NoRowsOverlay: () => <NoRowsOverlay message={noRowsOverlayMessage} loading={loadingWorkCellsTasks} />,
            Row: CustomRow,
          }}
          sx={TABLE_STYLES}
          getDetailPanelHeight={getDetailPanelHeight}
          getDetailPanelContent={getWorkRequestLists}
          getRowId={(row) => row.workCellTaskId}
          isRowSelectable={(params) => params.row.isEnabled}
          getRowClassName={(params) => (!params.row.isEnabled ? 'disabled' : '')}
          onRowsScrollEnd={() => handleFetchMore()}
        />
      </ItemsDataGridProProvider>
      {Boolean(itemToBeDeleted) && (
        <DeleteModal
          open={Boolean(itemToBeDeleted)}
          itemName={itemToBeDeleted.taskName}
          onClose={handleHideModalToDelete}
          onSubmit={handleSubmitDeleteModal}
          loading={loadingDeleteTask}
        />
      )}
    </Box>
  );
};

export default WorkCellTasksTable;
