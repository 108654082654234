import React, { useCallback, useEffect } from 'react';

import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';

import useUploadImage from 'hooks/useUploadImage';

import FormFields from './FormFields';

const formatDefaultValues = (company) => ({
  companyName: company?.companyName,
});

const InfoForm = ({ company }) => {
  const { getImage } = useUploadImage();
  const { control, reset, setValue } = useForm({
    mode: 'all',
    defaultValues: formatDefaultValues(company),
  });

  const setProjectImage = useCallback(async () => {
    const getPresignedURL = await getImage(company?.companyImageId);
    setValue('image', getPresignedURL);
  }, [getImage, company?.companyImageId, setValue]);

  useEffect(() => {
    if (company?.companyImageId) setProjectImage();
    reset(formatDefaultValues(company));
  }, [company, reset, setProjectImage]);

  return (
    <Stack mt={0} mb={3} direction="row" gap="56px">
      <FormFields control={control} downloadPaths={company?.companyDownloadPaths} />
    </Stack>
  );
};

export default InfoForm;
