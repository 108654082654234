import { MutationHookOptions, gql, useMutation } from '@apollo/client';

import { addManufacturer as ADD_MANUFACTURER } from 'graphql/mutations';
import { AddManufacturerMutationVariables, Manufactured } from 'graphql/types';

const useAddManuracturerMutation = (
  options?: MutationHookOptions<{ addManufacturer: Manufactured }, AddManufacturerMutationVariables> | undefined,
) => useMutation<{ addManufacturer: Manufactured }, AddManufacturerMutationVariables>(gql(ADD_MANUFACTURER), options);

export default useAddManuracturerMutation;
