import React from 'react';

import Box from '@mui/material/Box';

import AddAdminModal from './AddAdminModal';
import { AdminProvider } from './AdminContext';
import AdminHeaderBar from './AdminHeaderBar';
import AdminTable from './AdminTable/AdminTable';

const AdminTab = () => (
  <AdminProvider>
    <AdminWrapper>
      <AdminHeaderBar />
      <AdminTable />
      <AddAdminModal />
    </AdminWrapper>
  </AdminProvider>
);

export default AdminTab;

const AdminWrapper = ({ children }) => (
  <Box mb="20px" height={1}>
    {children}
  </Box>
);
