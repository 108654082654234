import React, { useState, useEffect, useContext, useCallback } from 'react';

import { Box, Divider } from '@mui/material';

import { EditQuantityModalContext } from 'modules/Materials/TakeOff/TakeOffPadTab/EditQuantityModal/contexts/EditQuantityModalContext';

import { usePopover } from '../../PopoverProvider';
import { checkType, ShowCheckBox, PickIcon, PickText, ArrowIndicator, getElementId } from '../HelperElements';

const LocationsItem = ({ item, depth = 0, isOpen, handleClick, typeStub = '', parentIds = [] }) => {
  const editQuantityModalContext = useContext(EditQuantityModalContext);
  const { chipList, menuValue } = usePopover();
  const type = checkType(item) + typeStub;
  const id = getElementId(item);
  const [isChecked, setIsChecked] = useState(
    // eslint-disable-next-line
    chipList.findIndex((e) => e.id === id) === -1 ? false : true
  );

  const handleCheck = useCallback((newState) => {
    setIsChecked(newState);

    if (editQuantityModalContext) {
      const { addOrRemoveCheckedBoxParentIds } = editQuantityModalContext;
      const parentWorkPhaseId = type === 'Work Phase-G' ? [item.workPhaseId] : [];
      addOrRemoveCheckedBoxParentIds([...parentWorkPhaseId, ...parentIds, id], newState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Array.isArray(chipList)) {
      const position = chipList.findIndex((e) => e.id === id);
      if (position > -1) setIsChecked(true);
    }
  }, [chipList, id, setIsChecked]);

  useEffect(() => {
    const position = chipList.findIndex((e) => e.id === id);
    if (position !== -1) setIsChecked(true);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <ShowCheckBox item={item} isChecked={isChecked} setIsChecked={handleCheck} />
        <Box sx={{ width: `${depth * 24}px` }} />
        <ArrowIndicator item={item} isOpen={isOpen} handleClick={handleClick} />
        <PickIcon type={type} />
        <PickText type={type} item={item} />
      </Box>
      <Divider sx={{ width: '100%' }} />
      {item.locationPhases?.length > 0 &&
        menuValue !== 'Location Breakdown Structure' &&
        item.locationPhases.map((e) => (
          <LocationsItem
            key={getElementId(e)}
            item={e}
            depth={depth + 1}
            isOpen={isOpen}
            handleClick={handleClick}
            typeStub="-G"
            parentIds={[...parentIds, id]}
          />
        ))}
    </>
  );
};

export default LocationsItem;
