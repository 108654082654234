import React, { useContext } from 'react';

import { Box } from '@mui/material';

import { maxLengthRule } from 'constants/inputFieldRules';
import { QuantityInput } from 'modules/Materials/TakeOff/Form';

import { useModal } from '../../contexts/ModalContext';
import { useRow } from '../../contexts/RowContext';
import { UnassignedRowContext, useUnassignedRow } from '../../contexts/UnassignedRowContext';
import { ERROR_ON_FOCUS_STYLES, QTY_INPUT_STYLES, QTY_INPUT_WRAPPER_STYLES } from '../constants/constants';

const QtyInput = () => {
  const { errorNodeIds } = useModal();

  const unassignedRowContext = useContext(UnassignedRowContext);

  const rowProvider = unassignedRowContext ? useUnassignedRow : useRow;

  const {
    itemId,
    requiredQty,
    control,
    handleFocus,
    handleBlur,
    validateEnterKey,
    handleChange,
    disableInput,
    handleResetValue,
  } = rowProvider();

  return (
    <Box sx={QTY_INPUT_WRAPPER_STYLES}>
      <QuantityInput
        control={control}
        name={itemId}
        sx={{ ...QTY_INPUT_STYLES, ...(errorNodeIds?.length ? ERROR_ON_FOCUS_STYLES : {}) }}
        defaultValue={requiredQty}
        disabled={disableInput}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={validateEnterKey}
        onChange={handleChange}
        rules={maxLengthRule('Quantity', 6)}
        handleResetValue={handleResetValue}
        displayClearButton
        error={errorNodeIds?.includes(itemId)}
        id={`quantity-input-${itemId}`}
      />
    </Box>
  );
};

export default QtyInput;
