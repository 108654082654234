import { Typography } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';

import { CatalogNumberInput } from '../../Form';
import { checkSelectedManufacturer } from '../../Form/rules';
import { useGetGenericManufacturer } from '../useGetGenericManufacturer';
import { BOMCatalogTableFields, BOMCatalogTableHeaders } from './BOMTableColumnsHelpers';

const parseManufacturerCatalogNumber = (value = '', lineItemId) => {
  if (lineItemId === 0) return '';
  if ((value?.length ?? 0) === 0 || value === 'Generic') return '---';
  return value;
};
const BaseLabel = ({ row }) => parseManufacturerCatalogNumber(row?.manufacturerCatalogNumber, row?.lineItemId);

export const getCatalogNumberColumn = () => ({
  useForm: true,
  field: BOMCatalogTableFields.MANUFACTURER_CATALOG_NUMBER,
  headerName: BOMCatalogTableHeaders[BOMCatalogTableFields.MANUFACTURER_CATALOG_NUMBER],
  flex: 1.5,
  editable: true,
  resizable: true,
  renderEditCell: () => <CatalogNumberEditCell />,
  renderCell: (props) => <BaseLabel {...props} />,
});

const CatalogNumberEditCell = () => {
  const { control, formState } = useFormContext();
  const { manufacturer } = useWatch({ control });

  const genericManufacturer = useGetGenericManufacturer();

  const isManufacturerEqualToGeneric = checkSelectedManufacturer(manufacturer, genericManufacturer);

  if (!isManufacturerEqualToGeneric)
    return (
      <Typography sx={{ ml: '9px' }} variant="body2">
        ---{' '}
      </Typography>
    );

  return <CatalogNumberInput control={control} disabled={!manufacturer || formState.isSubmitting} />;
};
