import { createTheme } from '@mui/material/styles';

import { EModuleType } from 'types/module';

import breakpoints from './breakpoints';
import {
  adminColors,
  dataAnalyticsColors,
  electricalColors,
  errorColors,
  fieldColors,
  mechanicalColors,
  materialsColors,
  primaryColors,
  shopColors,
  designColors,
  successColors,
  evolveBrandColors,
  surfaceColors,
  actionColors,
  statusColors,
  otherColors,
  managementColors,
  syncColors,
  kanbanColors,
  greyColors,
} from './colors';
import components from './components';
import typography from './typography';

const getTheme = (activeModuleColors) =>
  createTheme({
    palette: {
      // overriding material color objects
      primary: primaryColors,
      secondary: activeModuleColors,
      error: errorColors,
      success: successColors,
      background: {
        default: '#dbdbdb',
      },
      // adding custom color objects
      surface: surfaceColors,
      action: actionColors,
      status: statusColors,
      other: otherColors,
      myWhite: {
        main: 'white',
      },
      kanban: kanbanColors,
      greyColors,
    },
    breakpoints: {
      values: breakpoints,
    },
    typography,
    components: components(activeModuleColors),
  });

export const getThemeByModule = (module) => {
  switch (module) {
    case EModuleType.ADMIN:
      return getTheme(adminColors);
    case EModuleType.DATA_ANALYTICS:
      return getTheme(dataAnalyticsColors);
    case EModuleType.ELECTRICAL:
      return getTheme(electricalColors);
    case EModuleType.FIELD:
      return getTheme(fieldColors);
    case EModuleType.MATERIALS:
      return getTheme(materialsColors);
    case EModuleType.MECHANICAL:
      return getTheme(mechanicalColors);
    case EModuleType.SHOP:
      return getTheme(shopColors);
    case EModuleType.MANAGEMENT:
      return getTheme(managementColors);
    case EModuleType.DESIGN:
      return getTheme(designColors);
    case EModuleType.SYNC:
      return getTheme(syncColors);

    default:
      return getTheme(evolveBrandColors);
  }
};
