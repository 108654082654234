import { forwardRef } from 'react';

import { withOverflowTooltip } from '@evolvemep/foresite-components-ui-react';
import { Badge, Box, BoxProps, ListItem, ListItemText, Tooltip, Typography } from '@mui/material';

import { TODAY, getDaysBetween } from 'helpers/dateFunctions';
import WorkOrderSpinner from 'modules/Shop/WorkOrders/WorkOrderSpinner';
import { WorkOrder } from 'typings/work-order';

import { getVariantStatus } from '../../helpers/getVariantStatus';
import { CellComponentLink } from '../WorkOrderTableComponents';

const WorkRequestNameWithTooltip = ({ value }: { value: string }) => {
  const WorkRequestNameText = forwardRef<HTMLDivElement>((props: BoxProps, ref) => {
    const { sx: sxFromProps, ...restProps } = props;

    return (
      <Box {...restProps} ref={ref} sx={{ ...sxFromProps }}>
        <Typography marginLeft={1} color="black" variant="body2" component="span">
          &#124; {`${value}`}
        </Typography>
      </Box>
    );
  });
  const OverflowToolTip = withOverflowTooltip(WorkRequestNameText);
  return <OverflowToolTip OverflowTooltipProps={{ title: value }} />;
};

export const ContentListWorkOrders = ({ workOrders }: { workOrders: WorkOrder[] }) => (
  <>
    {workOrders.map((workOrder) => {
      const status = workOrder.workOrderStatusTypeName;
      const daysRemaining = getDaysBetween(
        new Date(workOrder.workRequest.needBy as string),
        new Date(status === 'Completed' && workOrder?.lastStatusUpdate ? workOrder.lastStatusUpdate : TODAY),
      );
      const { variant } = getVariantStatus(status, daysRemaining);

      return (
        <ListItem key={`${workOrder.workOrderId}-item-work-order`}>
          {workOrder?.isStepFunctionProcessing ? (
            <WorkOrderSpinner size="10px" />
          ) : (
            <Tooltip arrow placement="top" title={workOrder.workOrderStatusTypeName}>
              <Badge
                sx={{
                  '&.MuiBadge-root': { borderRadius: '100%', width: '8px', height: '8px', marginRight: 1 },
                }}
                variant={variant as any}
              />
            </Tooltip>
          )}
          <ListItemText
            sx={{ '&.MuiListItemText-root': { display: 'inline-flex' } }}
            secondaryTypographyProps={{
              sx: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', lineHeight: '30px' },
            }}
            secondary={<WorkRequestNameWithTooltip value={workOrder?.workRequest?.workRequestName} />}
          >
            <CellComponentLink
              value={workOrder.workOrderName}
              row={workOrder}
              sx={{ fontWeight: 500, maxWidth: '110px', lineHeight: '30px' }}
            />
          </ListItemText>
        </ListItem>
      );
    })}
  </>
);
