import React, { useEffect, useMemo, useState } from 'react';

import { Box, Divider } from '@mui/material';

import StackRow from 'components/StackRow';
import useCache from 'hooks/useCache';

import { useModal } from '../../contexts/ModalContext';
import { useProjectRow } from '../../contexts/ProjectRowContext';
import { RowProvider } from '../../contexts/RowContext';
import ArrowIndicator from './ArrowIndicator';
import Loader from './Loader';
import RowInfo from './RowInfo';
import WorkPhaseRow from './WorkPhaseRow';

const ScopePackageRow = ({ item }) => {
  const { parentFilteredIds, show0WorkPhases, nodeTree, calculationNodeIds, project, findItem } = useModal();
  const [isOpen, setIsOpen] = useState(Boolean(parentFilteredIds?.length));
  const { loadingWorkPhases, workPhases } = useProjectRow();
  const { deleteFromCacheByQuery } = useCache();

  const handleClick = () => setIsOpen(!isOpen);

  const thereIsAWPFiltered = workPhases?.some((workPhase) => parentFilteredIds?.includes(workPhase.workPhaseId));

  const scopeWorkPhases = workPhases?.filter((workPhase) => workPhase.scopePackageId === item?.scopePackageId);

  const childQtySum = useMemo(
    () =>
      scopeWorkPhases.reduce((acc, workPhase) => {
        const workPhaseFromNodeTree = findItem(workPhase.workPhaseId);
        const workPhaseAQty = workPhaseFromNodeTree?.aggregateVisual || 0;

        return (acc += workPhaseAQty);
      }, 0),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scopeWorkPhases, nodeTree],
  );

  const isEditingSomeChild = useMemo(
    () => scopeWorkPhases.some((workPhase) => calculationNodeIds?.includes(workPhase.workPhaseId)),
    [scopeWorkPhases, calculationNodeIds],
  );

  const hidePhasesHasQty = !show0WorkPhases && childQtySum === 0 && !isEditingSomeChild;

  useEffect(() => {
    if (isOpen) {
      deleteFromCacheByQuery('workPhases', { projectId: project?.projectId });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  if ((parentFilteredIds?.length && !thereIsAWPFiltered) || hidePhasesHasQty)
    return <WorkPhasesRows isOpen={isOpen} workPhases={scopeWorkPhases} />;

  return (
    <>
      <StackRow sx={{ justifyContent: 'space-between', minHeight: '32px', gap: '8px', pr: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
          <Box sx={{ width: '36px' }} />
          <ArrowIndicator item={item} isOpen={isOpen} handleClick={handleClick} />
          <RowInfo type="Scope Package" item={item} isOpen={isOpen} />
        </Box>
      </StackRow>
      <Divider sx={{ width: '100%' }} />
      <WorkPhasesRows isOpen={isOpen} workPhases={scopeWorkPhases} />
      {isOpen ? <>{loadingWorkPhases && <Loader />}</> : <></>}
    </>
  );
};

export default ScopePackageRow;

const WorkPhasesRows = ({ isOpen, workPhases }) => {
  const { item } = useProjectRow();

  return workPhases?.map((workPhase) => (
    <RowProvider key={workPhase.workPhaseId} item={workPhase} type="WorkPhase" parentId={item?.id}>
      {isOpen && <WorkPhaseRow depth={8.5} />}
    </RowProvider>
  ));
};
