import React, { useState, useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import { ControlledAutoComplete } from 'components';
import countries from 'helpers/countryName';
import stateCodes from 'helpers/stateCodes';

const CountryField = ({ countryValue }) => {
  const { control, watch, setValue } = useFormContext();
  const [disableCountry, setDisableCountry] = useState(false);

  useEffect(() => {
    const state = stateCodes.find((state) => state === watch('address.state'));
    if (state) {
      setDisableCountry(true);
      setValue('address.country', 'United States');
    } else if (!state && !watch('address.state')) {
      setDisableCountry(false);
      setValue('address.country', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('address.state')]);

  return (
    <ControlledAutoComplete
      disabled={disableCountry}
      limitOptions={6}
      name="address.country"
      label="Country"
      defaultValue={countryValue}
      control={control}
      options={countries}
      rules={{ required: true }}
      fullWidth
    />
  );
};

export default CountryField;
