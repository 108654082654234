import { useMemo } from 'react';

import { useUnitOfMeasureAPI } from 'hooks-api/useUnitOfMeasureAPI';
import { getUomOptionsToSelect } from 'modules/Materials/CatalogSetup/ItemsSection/Manufacturer/UnitOfMeasure/unitOfMeasureHelpers';

export const useUOMOptions = () => {
  const { uoms } = useUnitOfMeasureAPI('cache-first');
  const uomOptions = useMemo(() => getUomOptionsToSelect(uoms), [uoms]);

  return {
    uomOptions: uomOptions || [],
  };
};
