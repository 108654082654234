/* eslint-disable import/no-cycle */
import { useEffect } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';

import useGetNumberValues from 'hooks/useGetNumberValues';

import { ADD_REMAINING_QUANTITIES_FIELD } from '../../../constants';
import { useItemsIntegrationTableContext } from '../../../context/ItemsIntegrationTableContext';
import { addAutoFilledField } from '../../QtyField/helpers/qtyFieldHelpers';
import { useFieldContext } from '../context/FieldContext';
import { replaceNegativeAmount } from '../helpers/quantityFieldHelpers';
import { getChildQtyFieldName } from './useClearAutofill';

const useAutofillLBSItem = () => {
  const { control, setValue } = useFormContext();
  const {
    isBomLineItem,
    lineItemValueWatched,
    lineItemFieldName,
    fieldName,
    qtyRemaining,
    quantityField,
    allChildrenBellowCurrentLocation,
    fieldWatched,
    qtyAggregate,
    lineItemId,
    unassignedRowFieldName,
    currentNestLevel,
    childrenFields,
    quantityFieldChildren,
    qtyFieldRef,
  } = useFieldContext();
  const { autoFilledFields, setAutoFilledFields, valuesBeforeAutofill } = useItemsIntegrationTableContext();
  const autoFill = useWatch({ control, name: ADD_REMAINING_QUANTITIES_FIELD });
  const { getNumberValues } = useGetNumberValues();

  useEffect(() => {
    // * autofill field LBS ELEMENT if line item has value
    const lineItemHasValueNotAutofilled =
      autoFill ||
      isBomLineItem ||
      !lineItemValueWatched ||
      autoFilledFields.includes(lineItemFieldName) ||
      getNumberValues(fieldName) === qtyRemaining;
    if (lineItemHasValueNotAutofilled) return;

    addAutoFilledField(fieldName, { autoFilledFields, setAutoFilledFields });
    setValue(fieldName, replaceNegativeAmount(qtyRemaining));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoFilledFields, fieldName, isBomLineItem, lineItemFieldName, lineItemValueWatched, qtyRemaining, autoFill]);

  useEffect(() => {
    // * Autofill all children fields if lbs item qty equals qtyAggregate
    const rowHasChildrenBelow =
      autoFill ||
      !quantityField ||
      parseInt(`${fieldWatched}` || '0', 10) !== qtyAggregate ||
      autoFilledFields.includes(fieldName) ||
      !allChildrenBellowCurrentLocation?.length ||
      !allChildrenBellowCurrentLocation?.every((child) => child.nestLevel && child.qtyAggregate);
    if (rowHasChildrenBelow) return;
    const hasAllChildrenAutofilled = allChildrenBellowCurrentLocation.every((child) => {
      const childName = getChildQtyFieldName(child, lineItemId);
      return autoFilledFields.includes(childName) && getNumberValues(childName) === child.qtyRemaining;
    });
    if (hasAllChildrenAutofilled) return;
    allChildrenBellowCurrentLocation.forEach((child) => {
      const childFieldName = getChildQtyFieldName(child, lineItemId);
      setValue(childFieldName, replaceNegativeAmount(child.qtyRemaining as number));
      addAutoFilledField(childFieldName, { autoFilledFields, setAutoFilledFields });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    childrenFields,
    allChildrenBellowCurrentLocation,
    autoFill,
    autoFilledFields,
    fieldName,
    fieldWatched,
    lineItemId,
    qtyAggregate,
    quantityField,
    quantityFieldChildren,
    currentNestLevel,
    unassignedRowFieldName,
  ]);

  useEffect(() => {
    if (
      !qtyFieldRef?.current ||
      !autoFill ||
      !valuesBeforeAutofill ||
      qtyFieldRef.current.value !== '' ||
      parseInt(qtyFieldRef.current.value || '0', 10) === qtyRemaining
    )
      return;
    setValue(fieldName, replaceNegativeAmount(qtyRemaining));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoFill, fieldName, qtyFieldRef, qtyRemaining, valuesBeforeAutofill]);
};

export default useAutofillLBSItem;
