import { gql, useLazyQuery } from '@apollo/client';

import { scopePackage as SCOPE_PACKAGE } from 'graphql/queries';

const useScopePackagesAPI = (fetchPolicy = 'cache-and-network') => {
  const [scopePackageQuery, { data, called: wasCalled, loading }] = useLazyQuery(
    gql(SCOPE_PACKAGE),
    {
      fetchPolicy,
    },
  );

  const fetchScopePackages = (query = {}, options = {}) =>
    scopePackageQuery({
      variables: { query },
      ...options,
    });

  return { fetchScopePackages, wasCalled, loading, scopePackages: data?.scopePackage || [] };
};

export default useScopePackagesAPI;
