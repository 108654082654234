import { useState } from 'react';

export const useHandlePopper = () => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event?.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return {
    open,
    anchorEl,
    handleOpen,
    handleClose,
  };
};
