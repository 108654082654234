export const CONDITIONS = ['and'] as const;
export const OPERATORS = [
  'is equal to',
  'is not equal to',
  'contains',
  'does not contain',
  'starts with',
  'does not start with',
  'ends with',
  'does not end with',
  'is greater than',
  'is greater than or equal to',
  'is less than',
  'is less than or equal to',
  'is blank',
  'is not blank',
  'is one of',
  'is between',
  'is not between',
] as const;
export const RULE_DEFAULT: any = {
  condition: '',
  operator: '',
  propertyName: '',
  subRules: [],
  values: [],
};
