import React from 'react';

import { customMaxLengthRule, requiredRule } from 'constants/inputFieldRules';

import TextField from './TextField';

const NameTextField = ({ nodeType, rules, ...props }) => (
  <TextField
    name="label"
    placeholder={`Untitled ${nodeType}`}
    rules={{
      ...requiredRule('Name'),
      ...customMaxLengthRule(32, `${nodeType} name limited to 32 characters`),
      ...rules,
    }}
    sx={{
      '.MuiFormHelperText-root': {
        lineHeight: '16px',
      },
    }}
    {...props}
  />
);

export default NameTextField;
