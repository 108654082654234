import React, { memo } from 'react';

import CollapseChildrenIcon from 'modules/Field/LocationsAndWorkPhases/components/CollapseChildrenIcon';

import { useChips } from '../../../CreateLocationsModalContext';
import StyledChipInfo from './StyledChipInfo';
import StyledChipRow from './StyledChipRow';

const RowChip = memo(({ chip, depth = 0, ...props }) => {
  const { toggleOpenChip } = useChips();

  const ChildrenChips = () =>
    chip.children?.map((child) => (
      <RowChip key={child.id} chip={child} depth={depth + 1} {...props} />
    ));

  return (
    <>
      <StyledChipRow chip={chip} {...props}>
        <CollapseChildrenIcon
          sx={{ ml: 2.8 * depth }}
          onClick={() => toggleOpenChip(chip)}
          open={chip.isOpen}
          showIcon={chip.childNodes?.length > 0}
        />
        <StyledChipInfo chip={chip} />
      </StyledChipRow>
      {chip.isOpen && <ChildrenChips />}
    </>
  );
});

export default RowChip;
