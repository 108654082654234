/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';

import { useMutation, gql } from '@apollo/client';
import { InputForesiteField } from '@evolvemep/foresite-components-ui-react';
import { FormControlLabel, Checkbox, Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { useFormContext, Controller } from 'react-hook-form';

import AssignAdminControlledInput from 'components/FormComponents/AssignAdminControlledInput';
import { Custom } from 'components/Icons';
import { deleteDepartment as DELETE_DEPARTMENT } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

import { useFacilityContext } from '../FacilityContext';
import DepartmentDeleteModal from './DepartmentDeleteModal';

const DepartmentInfoCheckbox = ({ departments = [], label, departmentTypeId, index }) => {
  const { control, setValue } = useFormContext();
  const { handleResponse } = useGraphqlResponseHandler();
  const { selectedDepartments, setSelectedDepartments } = useFacilityContext();

  const [deleteDepartment] = useMutation(gql(DELETE_DEPARTMENT));

  const facilityContainsDepartmentType = departments
    .map((department) => department.departmentType.departmentTypeId)
    .includes(departmentTypeId);

  const facilityDepartment = departments.find(
    (department) => department.departmentType.departmentTypeId === departmentTypeId,
  );

  const [checked, setChecked] = useState(facilityContainsDepartmentType);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [addingDepartment, setAddingDepartment] = useState(false);

  const baseFormRegisterName = `departments.${index}`;
  const departmentIdName = `${baseFormRegisterName}.departmentId`;

  if (facilityDepartment) {
    setValue(departmentIdName, facilityDepartment.departmentId);
  }

  const removeDepartment = async () => {
    const selectedDepartment = departments.find(
      (department) => department.departmentType.departmentTypeId === departmentTypeId,
    );

    if (!selectedDepartment) {
      setChecked(false);
      setDeleteModalOpen(false);
      return;
    }

    const { departmentId } = selectedDepartment;

    await handleResponse(
      deleteDepartment,
      { variables: { params: { departmentId } } },
      {
        successMessage: 'Department removed successfully.',
        errorMessage: 'Cannot delete last remaining department. Facility must contain at least one department.',
      },
    );

    if (selectedDepartments.length > 1) {
      setValue(departmentIdName, '');
      setValue(`${baseFormRegisterName}.departmentName`, '');
      setChecked(false);
      setSelectedDepartments(departments.filter((department) => department.departmentId !== departmentId));
    }
    setDeleteModalOpen(false);
  };

  const openDeleteModal = () => setDeleteModalOpen(true);
  const closeDeleteModal = () => setDeleteModalOpen(false);

  return (
    <Box>
      <Controller
        name={checked ? departmentIdName : ''}
        control={control}
        defaultValue=""
        shouldUnregister={checked}
        render={() => (
          <FormControlLabel
            label={label}
            control={
              <Checkbox
                color="secondary"
                checked={checked}
                disabled={checked && !addingDepartment}
                onChange={(e, isChecked) => {
                  const dptId = facilityDepartment?.departmentId || `NEW::${departmentTypeId}`;
                  setChecked(isChecked);
                  setAddingDepartment(true);
                  setValue(departmentIdName, dptId);
                }}
              />
            }
          />
        )}
      />

      {checked && (
        <Stack direction="column" width={1}>
          <Stack direction="row" alignItems="center" justifyContent="center">
            <InputForesiteField
              name={`${baseFormRegisterName}.departmentName`}
              label="Department Name"
              size="small"
              defaultValue={facilityDepartment?.departmentName || ''}
              control={control}
              rules={{ required: true }}
            />
            <InputForesiteField
              name={`${baseFormRegisterName}.departmentTypeId`}
              defaultValue={departmentTypeId}
              sx={{ display: 'none' }}
              control={control}
            />
            {!addingDepartment && (
              <IconButton sx={{ ml: 1, mt: 1 }} size="large" onClick={openDeleteModal}>
                <Custom.Delete />
              </IconButton>
            )}
          </Stack>
          {addingDepartment && (
            <Stack>
              <AssignAdminControlledInput name={`${baseFormRegisterName}.user`} control={control} />
            </Stack>
          )}
        </Stack>
      )}

      <DepartmentDeleteModal open={deleteModalOpen} onClose={closeDeleteModal} onSubmit={removeDepartment} />
    </Box>
  );
};

export default DepartmentInfoCheckbox;
