/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import parse from 'html-react-parser';

import { Modal } from 'components';
import { Custom } from 'components/Icons';
import { fetchLatestEula } from 'modules/Authentication/auth/restCalls';

const EULAModal = ({ open, onAccept, onDecline }) => {
  const [content, setContent] = useState('');

  useEffect(() => {
    const asyncFetchLatestEula = async () => {
      try {
        const res = await fetchLatestEula();
        setContent(res.content);
      } catch (error) {
        console.log({ error });
      }
    };
    asyncFetchLatestEula();
  }, []);

  return (
    <Modal
      open={open}
      onClose={onDecline}
      disableModalOutsideClick
      disableTitleClose
      maxWidth="md"
      scroll="paper"
      sx={{
        '.MuiPaper-root': {
          maxHeight: '80vh',
        },
      }}
      titleStyles={{ fontWeight: 700 }}
      title={<EULAHeader title="End-User License Agreement" />}
      footer={
        <>
          <DeclineButton onClick={onDecline} />
          <AcceptButton disabled={false} onClick={onAccept} />
        </>
      }
    >
      {parse(content)}
    </Modal>
  );
};

export default EULAModal;

const DeclineButton = ({ onClick }) => (
  <Button
    variant="text"
    onClick={onClick}
    sx={{
      fontSize: '15px',
      lineHeight: '24px',
      letterSpacing: '0.46px',
      textAlign: 'right',
    }}
  >
    DECLINE
  </Button>
);

const AcceptButton = ({ onClick }) => (
  <Button
    variant="contained"
    onClick={onClick}
    sx={{
      backgroundColor: '#10143D',
      fontSize: '15px',
      lineHeight: '24px',
      letterSpacing: '0.46px',
      textAlign: 'right',
      '&:hover': {
        background: '#434A90',
      },
    }}
  >
    ACCEPT
  </Button>
);

const EULAHeader = ({ title }) => (
  <Box>
    {title}
    <IconButton sx={{ ml: 2 }} size="large">
      <Custom.Download />
    </IconButton>
  </Box>
);
