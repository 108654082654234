import React, { FC, MouseEvent, useRef } from 'react';

import { Button, IconButton, Stack, Typography } from '@mui/material';
import { SnackbarContent, useSnackbar } from 'notistack';

import { Custom } from 'components/Icons';

interface ConfirmationToastProps {
  id: any;
  text: string;
  action: {
    text: string;
    onClick: () => void;
  };
}

const ConfirmationToast = React.forwardRef<HTMLDivElement, ConfirmationToastProps>((props, ref: any) => {
  const { text, action, ...rest } = props;
  const { closeSnackbar } = useSnackbar();
  const snackbarRef = useRef<any>(ref) as any;
  const handleCloseSnackbar = () => closeSnackbar(props.id);
  return (
    <SnackbarContent ref={snackbarRef.current} {...rest}>
      <SkackbarContainer>
        <Custom.CheckCircleOutline style={{ fill: 'white', margin: '16px 8px 16px 16px' }} />
        <Typography sx={{ color: 'white', marginRight: '16px' }}>{text}</Typography>
        <Button sx={{ color: 'white', fontWeight: 500 }} size="small" onClick={action.onClick}>
          {action.text}
        </Button>
        <CloseButton
          onClick={() => {
            handleCloseSnackbar();
          }}
        />
      </SkackbarContainer>
    </SnackbarContent>
  );
});

export default ConfirmationToast;

const SkackbarContainer: FC<any> = ({ children }) => (
  <Stack
    flexDirection="row"
    justifyContent="center"
    alignItems="center"
    sx={{
      backgroundColor: '#43a047',
      borderRadius: '4px',
      boxShadow:
        '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
    }}
  >
    {children}
  </Stack>
);

interface ICloseButton {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
}

const CloseButton: FC<ICloseButton> = ({ onClick }: ICloseButton) => (
  <IconButton onClick={(e) => onClick(e)} sx={{ padding: 0 }}>
    <Custom.Close style={{ fill: 'white', margin: '16px' }} />
  </IconButton>
);
