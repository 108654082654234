import React from 'react';

import { Box, Tab, Tabs } from '@mui/material';

const MessageListTabs = ({ activeTab, onChange }) => {
  const a11yProps = (index) => ({
    id: `message-tab-${index}`,
    'aria-controls': `message-tabpanel-${index}`,
  });

  return (
    <MessageListTabsWrapper>
      <Tabs value={activeTab} onChange={onChange} aria-label="message-list-tab">
        <Tab
          label="MESSAGES"
          {...a11yProps(0)}
          sx={{ width: '50%', fontWeight: 600, textAlign: 'center', letterSpacing: '0.46px' }}
        />
        <Tab
          label="ARCHIVE"
          {...a11yProps(1)}
          sx={{ width: '50%', fontWeight: 600, textAlign: 'center', letterSpacing: '0.46px' }}
        />
      </Tabs>
    </MessageListTabsWrapper>
  );
};

export default MessageListTabs;

const MessageListTabsWrapper = ({ children }) => (
  <Box
    sx={{
      margin: '0 18px',
      height: '48px',
    }}
  >
    {children}
  </Box>
);
