/* eslint-disable max-lines-per-function */
import React, { useState, useCallback, useEffect } from 'react';

import { useController, useFormContext } from 'react-hook-form';

import { ForesiteAutocomplete } from 'components/FormComponents';

import { useUnitOfMeasureContext } from './UnitOfMeasureContext';

const UnitOfMeasureField = ({ name, ...restProps }) => {
  const { control, defaultValues, setValue, clearErrors, disabled } = useFormContext();
  const [opened, setOpened] = useState(false);
  const { setNewUnitOfMeasure, uomOptions } = useUnitOfMeasureContext();

  const {
    field: { onChange, onBlur, ref, value },
    fieldState: { error },
    formState: { isSubmitting },
  } = useController({
    name,
    control,
    rules: {
      required: {
        value: true,
        message: 'Unit is required',
      },
      maxLength: {
        value: 32,
        message: 'Unit must not exceed 32 characters',
      },
    },
  });

  const clearCodeAndType = useCallback(() => {
    setValue('unitOfMeasureCode', '');
    setValue('unitOfMeasureTypeId', '');
  }, [setValue]);

  const onSelectedValue = useCallback(
    (item) => {
      if (item == null || item === '') {
        clearCodeAndType();
        return;
      }

      const itemIsObject = typeof item === 'object' && item != null;
      if (!itemIsObject) {
        setNewUnitOfMeasure(true);
        clearCodeAndType();
        return;
      }

      setValue('unitOfMeasureId', item);
      setValue('unitOfMeasureCode', item.code);
      setValue('unitOfMeasureTypeId', item.type);
      clearErrors('unitOfMeasureCode');
      setNewUnitOfMeasure(false);
    },
    [setNewUnitOfMeasure, setValue, clearErrors, clearCodeAndType],
  );

  const handleSelectValue = useCallback(
    (value) => {
      if (value == null && value === '') return;

      if (typeof value === 'object') {
        onSelectedValue(value);
        return;
      }
      const selectedOption = !uomOptions
        ? []
        : uomOptions.find((uom) => uom.label.toLowerCase().trim() === value.toLowerCase().trim());

      if (selectedOption != null) {
        onChange(selectedOption);
        onSelectedValue(selectedOption);
        setOpened(false);
        return;
      }

      onSelectedValue(value);
      setOpened(false);
    },
    [onChange, onSelectedValue, uomOptions],
  );

  useEffect(() => {
    handleSelectValue(defaultValues.unitOfMeasureId);
  }, [defaultValues.unitOfMeasureId, handleSelectValue]);

  return (
    <ForesiteAutocomplete
      freeSolo
      data-testid="uom-autocomplete"
      open={opened}
      onOpen={() => setOpened(true)}
      onClose={() => setOpened(false)}
      options={uomOptions}
      sx={{ width: '100%' }}
      onChange={(e, item) => {
        onChange(item);
        handleSelectValue(item);
      }}
      onInputChange={(e, item) => {
        if (typeof value === 'string') {
          onChange(item);
        } else if (typeof value === 'object' && value?.label !== item) {
          onChange(item);
        }
      }}
      onBlur={(e) => {
        onBlur(e);
        handleSelectValue(e.target.value);
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') handleSelectValue(e.target.value);
      }}
      name={name}
      value={value}
      disabled={isSubmitting || disabled}
      textFieldProps={{
        inputRef: { ref },
        error: !!error,
        helperText: error?.message,
        size: 'small',
        label: 'Unit Of Measure',
        maxLength: 32,
      }}
      {...restProps}
    />
  );
};

export default UnitOfMeasureField;
