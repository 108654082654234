export const onUpdateQueryCallback = (key: string, query: string[]) => {
  let newQuery = [...query];
  const exist = newQuery.find((keySelected) => keySelected === key);
  if (exist) {
    const filter = newQuery.filter((keySelected) => keySelected !== key);
    newQuery = filter;
  } else {
    newQuery = [...newQuery, key];
  }
  return newQuery;
};
