import { gql, useLazyQuery, useMutation } from '@apollo/client';

import { useUser } from 'app/UserContext';
import { quantitiesCreate as QUANTITIES_CREATE, quantitiesPatch as QUANTITIES_PATCH } from 'graphql/mutations';
import { quantitiesRetrieveByIdentifier as QUANTITIES_RETRIEVE_BY_IDENTIFIER } from 'graphql/queries';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

const useQuantityAPI = (ern = '') => {
  const { handleResponse } = useGraphqlResponseHandler();
  const { user } = useUser();

  const [fetchRetrieveByIdentifierQuantities, { data, loading }] = useLazyQuery(
    gql(QUANTITIES_RETRIEVE_BY_IDENTIFIER),
    {
      fetchPolicy: 'cache-and-network',
      variables: { params: { identifier: ern } },
    },
  );

  const [fetchCreateQuantityMutation, { loading: loadingCreteQuantity }] = useMutation(gql(QUANTITIES_CREATE), {
    refetchQueries: [
      {
        query: gql(QUANTITIES_RETRIEVE_BY_IDENTIFIER),
        variables: { params: { identifier: ern } },
      },
    ],
  });

  const [fetchPatchQuantityMutation, { loading: loadingPatchQuantity }] = useMutation(gql(QUANTITIES_PATCH));

  const handleFetchCreateQuantity = (amount, projectId, onCompleted) =>
    fetchCreateQuantityMutation({
      variables: {
        body: {
          ern,
          amount,
          properties: {
            companyId: user.companyId,
            projectId,
          },
        },
      },
      onCompleted,
    });

  const handleFetchPatchQuantity = (amount, projectId, onCompleted) =>
    handleResponse(
      fetchPatchQuantityMutation,
      { variables: { body: { ern, amount, operation: 'auto' } } },
      { successMessage: '', errorMessage: '' },
      onCompleted,
      () => {
        if (amount > 0) handleFetchCreateQuantity(amount, projectId, onCompleted);
      },
    );

  return {
    fetchRetrieveByIdentifierQuantities,
    data: data?.quantitiesRetrieveByIdentifier || {},
    loading,
    handleFetchCreateQuantity,
    loadingCreteQuantity,
    handleFetchPatchQuantity,
    loadingPatchQuantity,
  };
};

export default useQuantityAPI;
