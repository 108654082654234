/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';

import { Decisions } from '@evolvemep/foresite-components-ui-react';

import { useCallbackPrompt } from 'hooks/useCallbackPrompt';
import { useAssignationModeContext } from 'modules/Materials/features/assignation-mode';

import { useDecisionsApi } from './hooks/useDecisionsApi';
import useGetFilters from './hooks/useGetFilters';
import useMapDecisionsData from './hooks/useMapDecisionsData';

const DecisionsData = ({
  decisions,
  onClose,
  onNext,
  row,
  loadingOptions,
  isTakeoff,
  refetchDecisions,
  catalogItemsPendingToAdd,
  onAddCatalogItems,
  open,
  addDecisionMadeToQueue = () => {},
  allDecisionsMade = [],
}) => {
  const [decisionsMade, setDecisionsMade] = useState(null);
  const [loadingSubmit, setloadingSubmit] = useState(false);
  const [decisionDeleted, setDecisionDeleted] = useState(false);
  const [sendMadeDecisions, redirectNavigation] = useCallbackPrompt(open && !!decisionsMade);
  const { getLocationId, getWorkphaseId } = useAssignationModeContext();

  useEffect(() => {
    if (sendMadeDecisions) onSubmit(decisionsMade);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendMadeDecisions, decisionsMade]);

  useEffect(() => {
    if (!catalogItemsPendingToAdd) return;
    if (!decisionsMade) {
      onClose();
      onAddCatalogItems();
      return;
    }

    handleSubmitWithPendingCatalogItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogItemsPendingToAdd]);

  const { mapDecisions, mapDecisionsMade, mapDecisionToDelete } = useMapDecisionsData();
  const { updateDecisionsBOM, updateDecisionsTakeoff, errorUpdateDecision, loadingUpdateDecision } = useDecisionsApi();
  const { filters } = useGetFilters();

  const decisionsMapped = mapDecisions(decisions);

  const updateDecisions = async (body) => {
    if (isTakeoff) {
      await updateDecisionsTakeoff(body);
      return;
    }

    await updateDecisionsBOM(body);
  };

  const onSubmit = async (decisionsUnmapped) => {
    const decisionsMadeMapped = mapDecisionsMade(decisionsUnmapped, row?.lineItemId, row?.quantity);

    const body = {
      lineItemDecisions: [decisionsMadeMapped, ...allDecisionsMade],
      locationIds: filters?.locationIds,
      workPhaseIds: filters?.workPhaseIds,
      scopePackageIds: filters?.scopePackageIds,
      locationsWithWorkPhases: filters?.locationsWithWorkPhases,
      assignedlocationId: getLocationId() || null,
      assignedworkPhaseId: getWorkphaseId() || null,
    };

    setloadingSubmit(true);
    await updateDecisions(body);
    setloadingSubmit(false);

    if (sendMadeDecisions) redirectNavigation();

    onClose();
    if (onNext) onNext();
  };

  const handleSubmitWithPendingCatalogItems = async () => {
    await onSubmit(decisionsMade);
    await onAddCatalogItems();
  };

  const onDelete = async (decisionToDelete) => {
    const decisionToDeleteMapped = mapDecisionToDelete(decisionToDelete, row?.lineItemId);

    await updateDecisions(decisionToDeleteMapped);
    if (!errorUpdateDecision) {
      refetchDecisions();
      setDecisionDeleted(true);
    }
  };

  const onChange = (decisionsUnmapped) => setDecisionsMade(decisionsUnmapped);

  const handleNextButton = async () => {
    if (!decisionsMade) onNext();

    await addDecisionMadeToQueue(decisionsMade);
    onNext();
  };

  return (
    <Decisions
      decisions={decisionsMapped || []}
      onClose={onClose}
      onNext={onNext && handleNextButton}
      onSubmit={onSubmit}
      loadingOptions={loadingOptions}
      loadingSubmit={loadingSubmit}
      onDelete={onDelete}
      decisionDeleted={decisionDeleted}
      loadingDelete={loadingUpdateDecision}
      onChange={onChange}
    />
  );
};

export default DecisionsData;
