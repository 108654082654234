/* eslint-disable max-lines-per-function */
import React, { useMemo, useEffect, useState } from 'react';

import { Stack, Typography, Box } from '@mui/material';

import { Custom } from 'components/Icons';
import { surfaceColors, successColors, greyColors } from 'styles/theme/colors';

import usePartImage from '../../CatalogSetup/ItemsSection/Manufacturer/PartImage/hooks/usePartImage';
import { usePartCategory } from '../PartCategory/PartCategoryContext';
import PartItem from '../PartItem';
import CatalogSearchResultPartItemTitle from './CatalogSearchResultPartItemTitle';
import { getArrayOfParts } from './helpers/highlightWordInString';
import { validateQty } from './helpers/validate';

const highlightKeywordInString = (string = '', keyword = '') => {
  if (!keyword) return <CatalogSearchResultPartItemTitle>{string}</CatalogSearchResultPartItemTitle>;

  return (
    <CatalogSearchResultPartItemTitle>
      {getArrayOfParts(string, keyword).map((part) => (
        <span key={part.id} style={{ ...(part.isHighlight && { fontWeight: 700 }) }}>
          {part.text}
        </span>
      ))}
    </CatalogSearchResultPartItemTitle>
  );
};
const getStyles = (prop) => {
  const inputSx = prop
    ? {
        width: '85px',
        '.Mui-focused': {
          '.MuiOutlinedInput-notchedOutline, .MuiInputLabel-animated': {
            borderColor: prop,
          },
        },
      }
    : { width: '80px' };

  const buttonSx = prop
    ? {
        width: '80px',
        height: '40px',
        ml: 0,
        color: 'black',
        backgroundColor: prop,
        fontWeight: '500',
        fontSize: '15px',
        '&.Mui-disabled': {
          color: 'rgba(255, 255, 255, 0.26) !important',
          background: 'rgba(255, 255, 255, 0.12) !important',
        },
      }
    : {
        width: '80px',
        height: '40px',
        ml: 0,
        fontWeight: '500',
        fontSize: '15px',
        '&.Mui-disabled': {
          color: 'rgba(255, 255, 255, 0.26)',
          background: 'rgba(255, 255, 255, 0.12)',
        },
      };

  return { inputSx, buttonSx };
};

const CatalogSearchResultPartItem = ({
  part,
  partsQty,
  onChangeQty,
  onAddItem,
  searchValue,
  disabled = false,
  disabledButton = false,
}) => {
  const [partImageUrl, setPartImageUrl] = useState(null);
  const valid = useMemo(() => validateQty(partsQty[part.partId]?.qty ?? ''), [partsQty, part]);
  const { popupColor } = usePartCategory();
  const { inputSx, buttonSx } = getStyles(popupColor);
  const handleChangeQty = (event) => {
    if (validateQty(event.target.value)) onChangeQty(part, event.target.value);
  };
  const { getPartImage } = usePartImage();

  const { partName, partDescription, catalogNumber, manufacturerName, partImageDocumentId, hasAssembly } = useMemo(
    () => ({
      partName: part.partName,
      hasAssembly: part.hasAssembly,
      partDescription: part.manufacturer === 'object' ? part.description : part.partDescription,
      catalogNumber: typeof part.manufacturer === 'object' ? part.manufacturerCatalogNumber : part.catalogNumber,
      manufacturerName:
        typeof part.manufacturer === 'object' ? part.manufacturer?.manufacturerName : part.manufacturerName,
      partImageDocumentId: part.partImages?.length
        ? part.partImages?.find((image) => image.isDefault)?.document?.documentId
        : part.partImageDocumentId,
    }),
    [part],
  );

  useEffect(() => {
    async function fetchPartImage() {
      if (partImageDocumentId) {
        const imageUrl = await getPartImage(partImageDocumentId);
        setPartImageUrl({ imageUrl });
      }

      if (!partImageDocumentId) {
        setPartImageUrl(null);
      }
    }

    fetchPartImage();

    // eslint-disable-next-line
  }, [partImageDocumentId]);

  const partIcon = useMemo(() => {
    if (partImageUrl) return null;
    return hasAssembly === '1' ? (
      <Custom.AssemblyIcon />
    ) : (
      <Custom.PartThumbnail width={38} height={38} fill={greyColors.grey400} />
    );
  }, [partImageUrl, hasAssembly]);

  return (
    <PartItem sx={{ minWidth: '752px', width: '752px' }} disabled={disabled}>
      <PartItem.Image image={partImageUrl} icon={partIcon} />
      <PartItem.Content sx={{ minWidth: '444px', width: '444px', height: '80px' }}>
        <Typography
          variant="span"
          sx={{
            color: surfaceColors.darkSurface.primary,
            marginBottom: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          {highlightKeywordInString(partName, searchValue)}
        </Typography>
        <PartItem.Header>
          <PartItem.HeaderElement sx={{ color: surfaceColors.darkSurface.primary }}>
            Catalog # {catalogNumber}
          </PartItem.HeaderElement>
          <PartItem.HeaderElement sx={{ color: surfaceColors.darkSurface.primary }}>
            Mfg: {manufacturerName}
          </PartItem.HeaderElement>
        </PartItem.Header>
        <PartItem.Description sx={{ color: surfaceColors.darkSurface.primaryOpacity60 }}>
          {partDescription}
        </PartItem.Description>
      </PartItem.Content>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'end',
          justifyContent: 'space-between',
          position: 'relative',
          width: '186px',
          height: '80px',
          gap: '16px',
        }}
      >
        <Box position="relative">
          <PartItem.Input
            value={partsQty[part.partId]?.qty ?? ''}
            error={!valid}
            helperText={!valid && 'Invalid'}
            onChange={handleChangeQty}
            sx={inputSx}
          />
          {partsQty[part.partId]?.qtySuccessMsg && (
            <Stack position="absolute" sx={{ bottom: '-28px', left: 0, width: 'max-content' }}>
              <Typography color={successColors.light} fontSize="12px">
                {partsQty[part.partId].qtySuccessMsg}
              </Typography>
            </Stack>
          )}
        </Box>
        <PartItem.Button
          color="secondary"
          variant="contained"
          buttonText="Add"
          disabled={disabledButton || !valid || !partsQty[part.partId]?.qty}
          sx={buttonSx}
          onClick={() => onAddItem(part)}
        />
      </Stack>
    </PartItem>
  );
};

export default CatalogSearchResultPartItem;
