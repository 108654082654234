import React from 'react';

import { Box, Stack, Typography } from '@mui/material';

import { CreateButton, Modal } from 'components';
import { Custom } from 'components/Icons';

const WorkRequestInfoModal = ({ openStatus, onClose, statusInfo, identifier }) => (
  <Modal
    disableModalOutsideClick
    open={openStatus}
    onClose={onClose}
    title="Accept work request rejection"
    reducePadding
    fullWidth
    maxWidth="sm"
    titleContainerStyles={{ pr: '2px' }}
    footer={
      <CreateButton
        onClick={() => onClose()}
        buttonText="ACCEPT"
        sx={{
          backgroundColor: 'secondary.main',
          '&:hover': {
            backgroundColor: 'secondary.containedHoverBackground',
          },
        }}
      />
    }
    contentStyles={{ p: '20px' }}
  >
    <Stack sx={{ height: '180px' }}>
      <Stack direction="row" sx={{ gap: '5px', alignItems: 'center', minHeight: '24px' }}>
        <Custom.Error />
        <Typography variant="subtitle2">
          {identifier} was rejected for the following reason
        </Typography>
      </Stack>
      <Box sx={{ mt: '9px', ml: '10px' }}>
        <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
          {statusInfo}
        </Typography>
      </Box>
    </Stack>
  </Modal>
);

export default WorkRequestInfoModal;
