import React, { ReactElement } from 'react';

import { SxProps, Typography } from '@mui/material';

type PartItemHeaderElementProps = {
  children: ReactElement | string[];
  sx?: SxProps;
};
const PartItemHeaderElement = ({ children, sx }: PartItemHeaderElementProps) => (
  <Typography sx={{ typography: 'body2', fontSize: '12px', fontWeight: 400, lineHeight: '12px', ...sx }}>
    {children}
  </Typography>
);

export default PartItemHeaderElement;
