import React from 'react';

import Avatar from '@mui/material/Avatar';

const StyledAvatar = ({ size, children }) => (
  <Avatar
    sx={{
      border: '1px solid white',
      width: size === 'large' ? '105px' : '80px',
      height: size === 'large' ? '105px' : '80px',
      '& svg': {
        width: '100%',
        height: '100%',
      },
      '& img': {
        width: '100%',
        height: '100%',
      },
    }}
  >
    {children}
  </Avatar>
);

export default StyledAvatar;
