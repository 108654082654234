import React from 'react';

import { Custom } from 'components/Icons';

import { useAccordionContext } from './PartItemDetailAccordionContext';

const PartItemDetailAccordionIcon = () => {
  const { opened } = useAccordionContext();

  return (
    <>
      {' '}
      {!opened && <Custom.KeyboardArrowDown />}
      {opened && <Custom.KeyboardArrowUp />}
    </>
  );
};

export default PartItemDetailAccordionIcon;
