import useGetBOMLineItemsAdded from './useGetBOMLineItemsAdded';
import useGetTakeoffLineItemsAdded from './useGetTakeoffLineItemsAdded';

const useGetLineItemsAdded = () => {
  const BOMlineItemsAdded = useGetBOMLineItemsAdded();
  const TakeOfflineItemsAdded = useGetTakeoffLineItemsAdded();

  return BOMlineItemsAdded || TakeOfflineItemsAdded;
};

export default useGetLineItemsAdded;
