import React from 'react';

import { Stack } from '@mui/material';

const CardItemContainer = ({ children }) => (
  <Stack
    justifyContent="space-between"
    alignItems="center"
    direction="row"
    sx={{
      padding: 1,
      paddingLeft: 3,
      borderBottom: '1px solid',
      borderColor: 'other.border',
      height: '60px',
    }}
  >
    {children}
  </Stack>
);

export default CardItemContainer;
