import React from 'react';

import { CircularProgress } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';

import { Modal, CancelButton, DeleteButton } from 'components';
import ValidateEmail from 'components/FormComponents/ValidateEmail';

import CategoryModalContent from './CategoryModalContent';

const CategoryDeleteModal = ({ open, onClose, onSubmit, loading = false, itemName }) => {
  const title = `Confirm delete ${itemName}`;

  const methods = useForm({
    mode: 'all',
  });

  const {
    formState: { isValid },
  } = methods;

  return (
    <Modal
      disableModalOutsideClick
      open={open}
      onClose={onClose}
      title={title}
      reducePadding
      closeButtonStyles={{
        padding: 0,
      }}
      sx={{
        '.MuiDialog-paper': {
          maxWidth: '505px',
        },
      }}
      footer={
        <>
          {loading && <CircularProgress size={20} data-testid="circular-progress" />}
          <CancelButton onClick={onClose} disabled={loading} />
          <DeleteButton disabled={loading || !isValid} onClick={onSubmit} />
        </>
      }
    >
      <CategoryModalContent />
      <FormProvider {...methods}>
        <ValidateEmail disabled={loading} showUserEmail autoFocus />
      </FormProvider>
    </Modal>
  );
};

export default CategoryDeleteModal;
