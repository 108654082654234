import React, { useEffect, useState } from 'react';

import { gql, useMutation } from '@apollo/client';

import { useUser } from 'app/UserContext';
import { updateFacility as UPDATE_FACILITY } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import { ESearchType } from 'types/module';

const formatFacility = ({ facilityId, facilityName, departments, address }) => ({
  id: facilityId,
  label: facilityName || 'empty',
  address,
  departments,
  type: ESearchType.FACILITY,
});

const FacilityContext = React.createContext();

const removeDepartment = (facility, setFacility, departmentId) => {
  const newFacility = { ...facility };
  newFacility.departments = newFacility.departments.filter(
    (department) => department.departmentId !== departmentId,
  );
  setFacility(newFacility);
};

const FacilityProvider = ({ value = {}, children }) => {
  const { user } = useUser();
  const [facility, setFacility] = useState(value);
  const [selectedDepartments, setSelectedDepartments] = useState(facility.departments);

  const { handleResponse } = useGraphqlResponseHandler();
  const [updateFacility, { loading }] = useMutation(gql(UPDATE_FACILITY));
  const [updatedFacility, setUpdatedFacility] = useState(null);

  const submit = async (values) => {
    const { departments } = values;

    const body = { ...values, companyId: user.companyId, facilityIdParam: facility.facilityId };

    const updatedDepartments = departments
      ?.map((dpt, index) => {
        if (!dpt || !dpt.departmentId) return null;

        if (dpt.departmentId.includes('NEW')) {
          return {
            departmentName: dpt.departmentName,
            departmentTypeId: dpt.departmentId.split('::')[1],
            userId: dpt.user?.id,
            userEmail: dpt.user?.label == null ? dpt.user : dpt.user?.label,
          };
        }

        return {
          departmentId: dpt.departmentId,
          departmentName: dpt.departmentName,
          departmentTypeId: dpt.departmentTypeId,
          userId: facility.departments[index]?.adminUser,
          userEmail: facility.departments[index]?.userEmail,
          userName: facility.departments[index]?.userName,
        };
      })
      .filter((dpt) => dpt);

    body.departments =
      updatedDepartments?.filter(
        (department) =>
          (department.departmentId != null && department.departmentName != null) ||
          (department.departmentName != null &&
            (department.userEmail != null || department.userId != null)),
      ) || [];

    await handleResponse(
      updateFacility,
      {
        variables: {
          body,
        },
      },
      {
        successMessage: 'Facility edited successfully!',
        errorMessage: 'There was a problem removing the department.',
      },
      (response) => {
        setUpdatedFacility(response?.updateFacility);
      },
    );
  };

  useEffect(() => {
    setFacility(value);
  }, [value]);

  const selectedStateObj = React.useMemo(
    () => ({
      facility,
      setFacility,
      loading,
      submit,
      removeDepartmentFromFacility: (departmentId) =>
        removeDepartment(facility, setFacility, departmentId),
      selectedDepartments,
      setSelectedDepartments,
      updatedFacility,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [facility, setFacility, loading, submit, updatedFacility],
  );

  return <FacilityContext.Provider value={selectedStateObj}>{children}</FacilityContext.Provider>;
};

const useFacilityContext = () => {
  const context = React.useContext(FacilityContext);
  if (context === undefined) {
    throw new Error('useFacilitiesProjects must be used within a FacilityContext');
  }
  return context;
};

export { FacilityContext, FacilityProvider, useFacilityContext, formatFacility };
