import { useCallback } from 'react';

import { gql, useLazyQuery } from '@apollo/client';

import { partCategoryById } from 'graphql/queries';

const usePartCategory = () => {
  const [fetchPartCategory, { data, loading }] = useLazyQuery(gql(partCategoryById));

  const fetchPartCategoryById = useCallback(
    async (categoryId) => {
      const {
        data: { partCategoryById },
      } = await fetchPartCategory({
        variables: {
          params: { id: categoryId },
        },
      });
      return partCategoryById;
    },
    [fetchPartCategory],
  );

  return { data: data?.partCategoryById, loading, fetchPartCategoryById };
};

export default usePartCategory;
