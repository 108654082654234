import React, { useState, useEffect } from 'react';

import { Typography, Box } from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import useKeyPressEffect from 'hooks/useKeyPressEffect';

import { useWorkRequestsContext } from '../../../WorkRequestsContext';
import { InformativeRow } from '../InformativeRow';
import DarkNeedByDate from './DarkNeedByDate';

export const DateField = () => {
  dayjs.extend(utc);
  const { workRequestSelected } = useWorkRequestsContext();
  const [hovered, setHovered] = useState(false);
  const [date, setDate] = useState(workRequestSelected.needBy);

  useKeyPressEffect('Escape', () => setHovered(false));

  useEffect(() => setDate(workRequestSelected.needBy), [workRequestSelected.needBy]);
  useEffect(() => setHovered(false), [workRequestSelected]);
  return (
    <InformativeRow>
      <Typography color="white" variant="body2">
        Need By Date
      </Typography>
      <Box onClick={() => setHovered(true)} onMouseEnter={() => setHovered(true)}>
        {hovered && workRequestSelected.workRequestStatusName !== 'Completed' ? (
          <DarkNeedByDate setHovered={setHovered} setDate={setDate} />
        ) : (
          <Typography color="white" variant="body2" minWidth="100px" textAlign="right">
            {dayjs(`${date}Z`).local().format('MM/DD/YYYY')}
          </Typography>
        )}
      </Box>
    </InformativeRow>
  );
};
