import React from 'react';

import Box from '@mui/material/Box';

const IconStyled = ({ icon, color, sx, ...rest }) => (
  <Box
    sx={{
      path: {
        fill: color,
      },
      ...sx,
    }}
    {...rest}
  >
    {icon}
  </Box>
);

export default IconStyled;
