/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';

import StyledGridLayout from 'app/Layout/ReactGridLayout/StyledGridLayout';
import { buildDefaultLayoutSettings } from 'constants/globalConstants';
import useBreakpoint from 'hooks/useBreakpoint';
import useWindowSize from 'hooks/useWindowSize';
import 'react-grid-layout/css/styles.css';

import MobileRestricted from './MobileRestricted';

const MyGridLayout = ({ sizes, children }) => {
  const { isMobile } = useBreakpoint();
  const { height } = useWindowSize();

  const layoutSettings = useMemo(
    () => buildDefaultLayoutSettings(children.length, height, sizes),
    [height, children],
  );

  if (isMobile) return <MobileRestricted />;
  return (
    <StyledGridLayout compactType="horizontal" {...layoutSettings}>
      {children.map((child, index) => React.cloneElement(child, { key: index }))}
    </StyledGridLayout>
  );
};

export default MyGridLayout;
