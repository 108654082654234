import React, { useState } from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';

import { Custom } from 'components/Icons';

const MoreOptionsDropdown = ({ optionsRenderer = () => {}, callback = () => {}, buttonProps }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event) => {
    event.stopPropagation();
    callback();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event?.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton
        aria-controls={open ? 'positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleOpen}
        {...buttonProps}
      >
        <Custom.MoreVerAlt />
      </IconButton>
      <Menu
        id="positioned-menu"
        aria-labelledby="positioned-button"
        data-testid="more-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{ sx: { minWidth: '140px' } }}
      >
        {optionsRenderer(handleClose)}
      </Menu>
    </Box>
  );
};

export default MoreOptionsDropdown;
