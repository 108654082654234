/* eslint-disable import/no-cycle */
import { useEffect } from 'react';

import { useItemsIntegrationTableContext } from '../context/ItemsIntegrationTableContext';

const useSetQtyFields = () => {
  const { rows, setQuantityFields, quantityFields } = useItemsIntegrationTableContext();
  useEffect(() => {
    if (!rows.length) return;
    if (!rows.length && quantityFields.length) setQuantityFields([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, quantityFields]);
};

export default useSetQtyFields;
