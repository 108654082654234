import React, { useEffect } from 'react';

import { useReactiveVar } from '@apollo/client';
import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';

import { selectedWorkCellIdOnQueueVar } from 'apollo/reactiveVars';
import { CenteredLoadSpinner } from 'components';
import InputSelectField from 'components/FormComponents/InputSelectField';

import { useWorkCellQueueContext } from '../WorkCellQueueContext';

const WORK_CELL_SELECT_NAME = 'workCellId';

const WorkCellSelect = () => {
  const cachedSelectedWorkCellIdQueue = useReactiveVar(selectedWorkCellIdOnQueueVar);
  const { selectedFacilityId, workCells, loadingWorkCells } = useWorkCellQueueContext();

  const { control, watch, setValue } = useForm({ mod: 'all' });

  const selectedWorkCellId = watch(WORK_CELL_SELECT_NAME);

  useEffect(() => {
    if (!selectedWorkCellId) return;

    setTimeout(() => {
      selectedWorkCellIdOnQueueVar(selectedWorkCellId);
    }, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWorkCellId]);

  useEffect(() => {
    if (cachedSelectedWorkCellIdQueue && !selectedWorkCellId) {
      setValue(WORK_CELL_SELECT_NAME, cachedSelectedWorkCellIdQueue);
      return;
    }

    if (!cachedSelectedWorkCellIdQueue && selectedWorkCellId) setValue(WORK_CELL_SELECT_NAME, null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cachedSelectedWorkCellIdQueue, selectedWorkCellId, workCells]);

  return (
    <Box sx={{ width: '210px', mr: 1 }}>
      {loadingWorkCells ? (
        <LoadingSpinner />
      ) : (
        <DropDown disabled={!selectedFacilityId} control={control} options={workCells} defaultValue="" />
      )}
    </Box>
  );
};

export default WorkCellSelect;

const DropDown = ({ ...rest }) => (
  <InputSelectField name={WORK_CELL_SELECT_NAME} color="secondary" size="small" label="Work Cell" fullWidth {...rest} />
);

const LoadingSpinner = () => (
  <Box sx={{ position: 'relative', height: 40 }}>
    <CenteredLoadSpinner sx={{ position: 'absolute', mt: 0 }} />
  </Box>
);
