import { UpdateTaskMutation } from 'graphql/types';
import useUpdateTaskMutation from 'hooks-api/mutations/useUpdateTaskMutation';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import useGetTaskStatusTypes from 'hooks/useTaskStatusTypes/useGetTaskStatusTypes';
import useHandleUpdateQueueTableRows from 'modules/Shop/Fabrication/TaskViewer/hooks/useHandleUpdateQueueTableRows';

export default function useHandleUpdateTask() {
  const { handleResponse } = useGraphqlResponseHandler();
  const { handleUpdateQueueTask } = useHandleUpdateQueueTableRows();
  const { taskStatusTypes } = useGetTaskStatusTypes();

  const [updateTaskMutation, { loading }] = useUpdateTaskMutation({
    refetchQueries: ['WorkCellTasksQueue', 'WorkCellTasksQueueCount'],
  });

  const handleUpdateTask = (taskId: string, newStatusId: string) =>
    handleResponse(
      updateTaskMutation,
      { variables: { params: { taskId }, body: { taskStatusTypeId: newStatusId } } },
      { successMessage: '', errorMessage: 'Error: Unable to save changes' },
      (response: UpdateTaskMutation) => handleUpdateQueueTask(taskId, newStatusId, response),
    );

  const getStatusId = (name: string) =>
    taskStatusTypes?.find(({ taskStatusTypeName }) => taskStatusTypeName === name)?.taskStatusTypeId;

  const handleUpdateTaskStatus = async (taskId: string, status: string) => {
    const newStatusId = getStatusId(status);
    if (!newStatusId || !taskId) return {};
    return handleUpdateTask(taskId, newStatusId);
  };

  return {
    loading,
    handleUpdateTaskStatus,
  };
}
