import { useMemo } from 'react';

import { getValueByPropertyPath } from 'helpers/getValueByPropertyPath';
import { FlattenObjectKeysExtractOnlyString } from 'typings/flatten-object';

export const useSearchRepeatObj = <I, K extends FlattenObjectKeysExtractOnlyString<I>, R>(
  list: I[],
  path: K,
  resolve: (item: I, indicator: number) => R,
  determineNumberFlag?: (item: I, list: I[], pathValue: string) => number,
) =>
  useMemo(() => {
    const repeats: Record<string, number> = {};
    const listTransform = [...list]?.map((item) => {
      const value = getValueByPropertyPath(item, path);
      repeats[value] = determineNumberFlag?.(item, list, value) ?? (repeats[value] ?? 0) + 1;
      return resolve(item, repeats[value]);
    });

    return listTransform;
  }, [determineNumberFlag, list, path, resolve]);
