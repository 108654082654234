import React, { useState } from 'react';

import { gql, useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useFacilitiesProjects, formatFacility } from 'app/FacilitiesProjectsContext';
import ComponentPaneLayout from 'app/Layout/ReactGridLayout/ComponentPaneLayout';
import { useUser } from 'app/UserContext';
import { CenteredLoadSpinner } from 'components';
import { facilities as FACILITIES } from 'graphql/queries';

import CreateFacilityCard from './CreateFacilityCard';
import CreateFacilityModal from './CreateFacilityModal/CreateFacilityModal';
import FacilityCard from './FacilityCard';
import { FacilityProvider } from './FacilityContext';

const Facilities = () => {
  const { user } = useUser();
  const { selectedItem, setSelectedItem } = useFacilitiesProjects();
  const [createFacilityModalOpen, setCreateFacilityModalOpen] = useState(false);

  const { data, loading, refetch } = useQuery(gql(FACILITIES), {
    variables: {
      query: { companyId: user.companyId },
    },
    fetchPolicy: 'cache-and-network',
  });

  const openCreateFacilityModal = () => setCreateFacilityModalOpen(true);
  const closeCreateFacilityModal = () => setCreateFacilityModalOpen(false);
  const selectFacility = (facility) => setSelectedItem(formatFacility(facility));

  const facilities = data?.facilities || [];

  const FacilityCardList = () => (
    <>
      {facilities.map((facility) => (
        <FacilityProvider key={facility.facilityId} value={facility}>
          <FacilityCard
            refetchFacilities={refetch}
            selected={selectedItem?.id === facility.facilityId}
            onClick={() => selectFacility(facility)}
          />
        </FacilityProvider>
      ))}
    </>
  );

  return (
    <>
      <FacilitiesHeader onCreateButtonClick={openCreateFacilityModal} />
      <FacilityCardGrid>
        {loading ? <CenteredLoadSpinner sx={{ height: '294px' }} /> : <FacilityCardList />}
        <CreateFacilityCard onClick={openCreateFacilityModal} />
      </FacilityCardGrid>
      <CreateFacilityModal open={createFacilityModalOpen} onClose={closeCreateFacilityModal} />
    </>
  );
};

export default Facilities;

export const FacilityInterfaceNavContent = () => (
  <ComponentPaneLayout>
    <Facilities />
  </ComponentPaneLayout>
);

const FacilitiesHeader = ({ onCreateButtonClick }) => (
  <Stack
    direction="row"
    alignItems="center"
    justifyContent="space-between"
    sx={{ margin: '4px 0 16px 0' }}
  >
    <Typography variant="h5">My Facilities</Typography>
    <Button
      onClick={onCreateButtonClick}
      variant="contained"
      sx={{
        minWidth: 176,
        maxHeight: 32,
        bgcolor: 'primary.main',
        fontSize: 14,
        borderRadius: 1,
        '&:hover': {
          bgcolor: 'main.containedHoverBackground',
        },
        color: 'secondary.contrastText',
      }}
    >
      + Create Facility
    </Button>
  </Stack>
);

const FacilityCardGrid = ({ children }) => (
  <Box
    sx={{
      marginTop: '24px',
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(275px, 2fr))',
      gap: '20px',
      padding: '0 20px 32px 0px',
      height: '80vh',
      overflowY: 'scroll',
    }}
  >
    {children}
  </Box>
);
