import React from 'react';

import { MoreMenu } from 'components';

import CollapseChildrenIcon from '../../components/CollapseChildrenIcon';
import { RowIcon } from '../../components/RowPrefix';
import RowTitle from '../../components/RowTitle';
import StyledRow from '../../components/StyledRow';
import { useActiveRow } from '../../contexts/ActiveRowContext';
import { useDeleteModal } from '../../contexts/DeleteModalContext';
import { useLocationsAndWorkPhases } from '../../contexts/LocationsAndWorkPhaseContext';

const LocationRowInfo = React.memo(({ node, depth = 0, sx, workPhaseParent }) => {
  const { openDeleteLocationModal } = useDeleteModal();
  const { activeRows, setActiveRows } = useActiveRow();
  const {
    workPhases: { isEditingARow },
  } = useLocationsAndWorkPhases();

  const MORE_MENU_OPTIONS = [
    {
      label: 'Unmap location',
      clickEvent: () => {
        const locationPhaseToBeDeleted = node.locationPhases.find(
          ({ workPhaseId }) => workPhaseId === workPhaseParent.workPhaseId,
        );
        if (locationPhaseToBeDeleted) openDeleteLocationModal(locationPhaseToBeDeleted, 'unmap');
      },
    },
  ];

  const isAWorkPhase = depth === 2;
  const handleActiveNode = () => (!isAWorkPhase ? setActiveRows([node]) : null);

  return (
    <StyledRow
      isActive={activeRows.some(({ locationId }) => locationId && locationId === node.locationId)}
      onClick={handleActiveNode}
      sx={sx}
    >
      <CollapseChildrenIcon sx={{ ml: 2.8 * depth }} showIcon={false} />
      <RowIcon type={node.locationTypeName} green />
      <RowTitle>{node.locationCompositeName}</RowTitle>
      <MoreMenu options={MORE_MENU_OPTIONS} disabled={isEditingARow} sx={{ fontSize: 21 }} />
    </StyledRow>
  );
});

export default LocationRowInfo;
