import React, { useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { useTableRowContext } from '../../context/TableRowContext';
import { useWorkOrderItemsContext } from '../../context/WorkOrderItemsContext';

const RowFormProvider = ({ children }) => {
  const { newUomId } = useWorkOrderItemsContext();
  const { item, isAddItemRow, uomOptions, uomDefaultValue } = useTableRowContext();

  const uomOption = uomOptions?.filter((uomOption) => uomOption.value === item?.unitOfMeasureId)[0];

  const defaultValues = {
    workOrderItemName: !isAddItemRow ? item?.workOrderItemName : '',
    quantity: item?.quantity,
    unitOfMeasureId: uomOption || uomDefaultValue,
  };

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const { setValue, clearErrors } = methods;

  useEffect(() => {
    if (newUomId) {
      setValue('unitOfMeasureId', newUomId);
      clearErrors(['unitOfMeasureId']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uomOptions]);

  return <FormProvider {...methods}>{children}</FormProvider>;
};

export default RowFormProvider;
