import React from 'react';

import { InputForesiteField } from '@evolvemep/foresite-components-ui-react';
import { Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { ControlledAutoComplete } from 'components';
import { requiredRule } from 'constants/inputFieldRules';
import stateCodes from 'helpers/stateCodes';

const FormFields = ({ disabled }) => (
  <>
    <ShopPropertyNameField disabled={disabled} />
    <AddressLine1Field disabled={disabled} />
    <AddressLine2Field disabled={disabled} />
    <CityField disabled={disabled} />
    <Stack mt={0} direction="row" gap="16px">
      <StateField disabled={disabled} />
      <ZipField disabled={disabled} />
    </Stack>
    <CountryField disabled={disabled} />
  </>
);

export default FormFields;

const FormTextField = ({ name, label, ...rest }) => {
  const { control } = useFormContext();

  return <InputForesiteField name={name} control={control} label={label} size="small" {...rest} />;
};

const AddressLine1Field = ({ disabled }) => (
  <FormTextField disabled={disabled} maxLength={64} name="shippingAddressLine1" label="Address Line 1" />
);

const AddressLine2Field = ({ disabled }) => (
  <FormTextField disabled={disabled} maxLength={64} name="shippingAddressLine2" label="Address Line 2" />
);

const CityField = ({ disabled }) => <FormTextField disabled={disabled} name="city" label="City" maxLength={32} />;

const CountryField = ({ disabled }) => (
  <FormTextField disabled={disabled} name="country" label="Country" maxLength={32} />
);

const ShopPropertyNameField = ({ disabled }) => (
  <FormTextField
    disabled={disabled}
    maxLength={64}
    name="shopPropertyName"
    label="Shop Name"
    rules={{ ...requiredRule('Shop Name') }}
  />
);

const StateField = ({ disabled }) => (
  <ControlledAutoComplete
    name="state"
    label="State/Province/Region"
    defaultValue=""
    disabled={disabled}
    options={stateCodes}
    maxLength={64}
    fullWidth
    freeSolo
  />
);

const ZipField = ({ disabled }) => (
  <FormTextField disabled={disabled} name="zip" label="Zip/Postal Code" maxLength={10} />
);
