import React from 'react';

import { useWorkCell } from '../../WorkCellRow/contexts/WorkCellContext';
import DeleteTaskType from './DeleteTaskType';

const UnmapTask = ({ task }) => {
  const { unmapWorkCellTask } = useWorkCell();
  const handleSubmit = () => unmapWorkCellTask(task);

  return <DeleteTaskType task={task} handleSubmit={handleSubmit} />;
};

export default UnmapTask;
