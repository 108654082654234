import React from 'react';

import { Chip } from '@mui/material';

import { CONFIG_COLUMNS } from '../helpers/constants';

const FilterChips = ({ filters = [], onDelete, sxChip = {} }) => {
  if (filters.length === 0) return null;

  return (
    <>
      {filters.map((displayFilter) => (
        <Chip
          key={displayFilter.id}
          label={displayFilter.label}
          icon={CONFIG_COLUMNS[displayFilter.column].icon ?? CONFIG_COLUMNS.default.icon}
          onDelete={() => onDelete(displayFilter)}
          size="small"
          sx={{ margin: '8px 0 0 0', maxWidth: 'fit-content', ...sxChip }}
          data-testid="filter-chip"
        />
      ))}
    </>
  );
};

export default FilterChips;
