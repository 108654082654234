import React from 'react';

import { gql, useQuery } from '@apollo/client';

import {
  locationTypes as LOCATION_TYPES,
  locationFormats as LOCATION_FORMATS,
} from 'graphql/queries';

const LocationTypeAndFormatContext = React.createContext();

const LocationTypeAndFormatProvider = ({ children }) => {
  const { data: locationFormats } = useQuery(gql(LOCATION_FORMATS));
  const { data: locationTypes } = useQuery(gql(LOCATION_TYPES));

  const valueObj = {
    locationFormats: locationFormats?.locationFormats || [],
    locationTypes: locationTypes?.locationTypes || [],
  };

  return (
    <LocationTypeAndFormatContext.Provider value={valueObj}>
      {children}
    </LocationTypeAndFormatContext.Provider>
  );
};

const useLocationTypeAndFormat = () => {
  const context = React.useContext(LocationTypeAndFormatContext);
  if (context === undefined) {
    throw new Error('useLocationTypeAndFormat must be used within a LocationTypeAndFormatContext');
  }
  return context;
};

export { LocationTypeAndFormatContext, LocationTypeAndFormatProvider, useLocationTypeAndFormat };
