import React from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import LicensesTable from './LicensesTable';

function Licenses() {
  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ margin: '4px 0 16px 0' }}>
        <Typography variant="h5" fontWeight="600">
          Licenses
        </Typography>
      </Stack>
      <LicensesTable />
    </>
  );
}

export default Licenses;
