import { gql, useLazyQuery } from '@apollo/client';

import { messageById as MESSAGE_BY_ID } from 'graphql/queries';

const useFectchMessageById = () => {
  const [fetchMessageById, { loading: loadingAlert, data: message }] = useLazyQuery(
    gql(MESSAGE_BY_ID),
  );

  const messageById = message?.messageById ?? {};
  return { loadingAlert, message: messageById, fetchMessageById };
};

export default useFectchMessageById;
