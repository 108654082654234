import React, { useEffect } from 'react';

import { Box, ClickAwayListener } from '@mui/material';
import { GridRow } from '@mui/x-data-grid-pro';
import { FieldValues, FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { NEW_ITEM_ROW_ID } from 'constants/globalConstants';
import { BillOfMaterialItem } from 'graphql/types';
import useKeyPressEffect from 'hooks/useKeyPressEffect';
import { useAssignationModeContext } from 'modules/Materials/features/assignation-mode';

import { NewItem } from '../../AddBOMItemStrategy/AddBOMItemStrategy';
import useAddStrategy from '../../AddBOMItemStrategy/useAddStrategy';

type EditRowProps = {
  params: any;
  onCancelEditRow: (id: string) => void;
  onSuccessEditRow: (id: string) => void;
};

const EditRow = ({ params, onCancelEditRow, onSuccessEditRow }: EditRowProps) => {
  const methods = useForm({ mode: 'onSubmit' });
  const {
    setValue,
    handleSubmit,
    unregister,
    formState: { isSubmitting },
  } = methods;
  const { onAddItem, onCreateBOMItem, refetchParentBOMItems, refetchCurrenBOMItemsLevel } = useAddStrategy({ methods });

  const { row } = params;

  useEffect(() => {
    if (!row) return;
    const { manufacturer, unitOfMeasure } = row;
    const item: NewItem = {
      lineItemId: NEW_ITEM_ROW_ID.toString(),
      lineItemName: row.lineItemName,
      lineItemTypeId: row.lineItemTypeId,
      parentLineItemId: row.parentLineItemId,
      manufacturerCatalogNumber: row.manufacturerCatalogNumber,
      partId: row.partId,
      manufacturer,
      unitOfMeasure,
    };
    onAddItem(item);
  }, [onAddItem, row]);

  const { getLocationId, getWorkphaseId } = useAssignationModeContext();

  useEffect(() => {
    if (row?.parentLineItemId) return;
    if (!getLocationId() && !getWorkphaseId()) {
      unregister('locationId');
      unregister('workPhaseId');
    }
    setValue('locationId', getLocationId());
    setValue('workPhaseId', getWorkphaseId());
  }, [getLocationId, getWorkphaseId, row?.parentLineItemId, setValue, unregister]);

  const saveChanges: SubmitHandler<Omit<BillOfMaterialItem, '__typename'>> = async (data: FieldValues) => {
    await onCreateBOMItem(data);
    const parentLineItemId = data?.parentLineItemId;
    if (!parentLineItemId) {
      await refetchCurrenBOMItemsLevel(data);
      onSuccessEditRow(NEW_ITEM_ROW_ID.toString());
      return;
    }
    await refetchCurrenBOMItemsLevel(data);
    onSuccessEditRow(NEW_ITEM_ROW_ID.toString());
    await refetchParentBOMItems(data);
  };

  const handleSubmitData = async () => {
    if (isSubmitting) return;
    handleSubmit(async (data) => saveChanges(data as NewItem))();
  };

  useKeyPressEffect('Enter', handleSubmitData);
  useKeyPressEffect('Escape', () => onCancelEditRow(NEW_ITEM_ROW_ID.toString()));

  return (
    <FormProvider {...methods}>
      <ClickAwayListener onClickAway={handleSubmitData}>
        <Box>
          <GridRow {...params} />
        </Box>
      </ClickAwayListener>
    </FormProvider>
  );
};

export default React.memo(EditRow);
