import React, { useCallback } from 'react';

import { ClickAwayListener } from '@mui/material';

import { useUpdateWorkRequestItem } from './hooks/useUpdateWorkRequestItem';
import { useWorkRequestItemsContext } from './WorkRequestItemsContext';

const ClickAwayRow = ({ children, rowData }) => {
  const { submitItem } = useUpdateWorkRequestItem(rowData?.row);
  const { visibleInputs, modalUom } = useWorkRequestItemsContext();

  const handleClickAway = useCallback(() => {
    submitItem();
  }, [submitItem]);

  if ((!visibleInputs && rowData?.row?.workRequestItemId === 0) || modalUom) {
    return <>{children}</>;
  }

  return <ClickAwayListener onClickAway={handleClickAway}>{children}</ClickAwayListener>;
};

export default ClickAwayRow;
