import { isNil } from 'helpers/isNotNil';
import { Company, GetCompanyParams } from 'types/types-api';

import { useWrappedGet } from './useWrappedApiCall';

export const useGetCompany = (inputQuery?: GetCompanyParams) => {
  const { apiCall, ...rest } = useWrappedGet<Company>(`admin/company/${inputQuery?.companyId}`, {
    lazy: isNil(inputQuery),
  });
  const doGetCompany = (query: GetCompanyParams) => apiCall({ url: `admin/company/${query.companyId}` });
  return {
    ...rest,
    doGetCompany,
  };
};
