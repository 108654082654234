import React, { useCallback } from 'react';

import { Button, Stack } from '@mui/material';
import { Waypoint } from 'react-waypoint';

import { useUser } from 'app/UserContext';
import { TaskTypeCard } from 'components';
import { Custom } from 'components/Icons';
import Section from 'components/Section';

import DeleteTaskTypeModal from './DeleteTaskTypeModal';
import EditTaskTypeModal from './EditTaskTypeModal';
import NewTaskTypeModal from './NewTaskTypeModal';
import { useShopTasksContext } from './ShopTasksContext';
import ShopTaskTypeMessage from './ShopTaskTypeMessage';

const ShopTasksBody = () => {
  const {
    taskTypes,
    taskTypesPaginationHandler,
    setSelectedTaskType,
    showEditTaskTypeModal,
    setShowEditTaskTypeModal,
    showNewTaskTypeModal,
    setShowNewTaskTypeModal,
    showDeleteTaskTypeModal,
    setShowDeleteTaskTypeModal,
  } = useShopTasksContext();

  const { user } = useUser();

  const fetchMoreTaskTypes = useCallback(
    (skip) => {
      taskTypesPaginationHandler(skip, {
        taskTypeClassId: '9e1a7bc6-5b0a-4aa7-bcb5-b354e3bc2cdb',
        companyId: user?.companyId,
        orderBy: 'tasktypename',
      });
    },
    [taskTypesPaginationHandler, user?.companyId],
  );

  const handleFetchMore = useCallback(() => {
    if (!taskTypes) return;
    fetchMoreTaskTypes(taskTypes?.length);
  }, [taskTypes, fetchMoreTaskTypes]);

  const showEditModal = (task) => {
    setSelectedTaskType(task);
    setShowEditTaskTypeModal(true);
  };

  const showDeleteModal = (task) => {
    setSelectedTaskType(task);
    setShowDeleteTaskTypeModal(true);
  };

  return (
    <>
      <Section>
        <Section.Title
          direction="row"
          sx={{ justifyContent: 'end', width: '100%', minHeight: '32px' }}
        >
          <Button
            color="secondary"
            sx={{ pl: 0, pt: '4px', pb: '4px', backgroundColor: 'transparent', fontWeight: 500 }}
            onClick={() => setShowNewTaskTypeModal(true)}
          >
            <Stack direction="row" sx={{ gap: 1, alignItems: 'center' }}>
              <Custom.Add fill="#613FC2" /> NEW TASK TYPE
            </Stack>
          </Button>
        </Section.Title>
        {taskTypes?.length > 0 ? (
          <Section.Body
            direction="row"
            sx={{ padding: 2, gap: 2, flexWrap: 'wrap', width: '100%' }}
          >
            {taskTypes?.map((task) => (
              <TaskTypeCard
                key={task.taskTypeId}
                taskTypeImageId={task.taskTypeImageId}
                taskTypeName={task.taskTypeName}
                taskTypeDescription={task.taskTypeDescription}
                taskTypeClass="shop"
                options={[
                  { label: 'Edit', clickEvent: () => showEditModal(task) },
                  { label: 'Delete', clickEvent: () => showDeleteModal(task) },
                ]}
              />
            ))}
            <Waypoint
              key={taskTypes?.length}
              bottomOffset="-20%"
              onEnter={() => handleFetchMore()}
            />
          </Section.Body>
        ) : (
          <Section.Body
            direction="row"
            sx={{ padding: 2, gap: 2, flexWrap: 'wrap', width: '100%' }}
          >
            <ShopTaskTypeMessage
              message="Start by creating a new Shop Task Type."
              icon={<Custom.ShopTasksNav />}
            />
          </Section.Body>
        )}
      </Section>
      {showEditTaskTypeModal && <EditTaskTypeModal open={showEditTaskTypeModal} />}
      {showNewTaskTypeModal && <NewTaskTypeModal open={showNewTaskTypeModal} />}
      {showDeleteTaskTypeModal && <DeleteTaskTypeModal open={showDeleteTaskTypeModal} />}
    </>
  );
};

export default ShopTasksBody;
