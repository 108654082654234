import React, { FC, ReactNode, useMemo } from 'react';

import { Box } from '@mui/material';

import { useItemsIntegrationModalContext } from '../../context/ItemsIntegrationModalContext';
import { useItemsIntegrationTableContext } from '../QuantityStep/components/ItemsIntegrationTable/context/ItemsIntegrationTableContext';

interface ITableRowsWrapper {
  children: ReactNode;
}

const TableRowsWrapper: FC<ITableRowsWrapper> = ({ children }: ITableRowsWrapper) => {
  const { showSteps, isLoading } = useItemsIntegrationModalContext();
  const { isLoadingRows } = useItemsIntegrationTableContext();
  const loading = useMemo(() => isLoading || isLoadingRows, [isLoading, isLoadingRows]);
  return (
    <Box
      sx={{
        overflowY: loading ? 'hidden' : 'auto',
        flex: 1,
        maxHeight: showSteps ? '340px' : '402px',
        height: '100%',
        position: 'relative',
      }}
    >
      {children}
    </Box>
  );
};

export default TableRowsWrapper;
