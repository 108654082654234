/* eslint-disable import/no-cycle */

import useEnableDone from './useEnableDone';
import useSetQtyFields from './useSetQtyFields';
import useSetValuesBeforeAutoFill from './useSetValuesBeforeAutoFill';

const useItemsIntegrationTableContextEffectsHandler = () => {
  useSetValuesBeforeAutoFill();
  useEnableDone();
  useSetQtyFields();
};

export default useItemsIntegrationTableContextEffectsHandler;
