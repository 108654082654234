/* eslint-disable max-lines-per-function */
import React, { useCallback, useEffect, useMemo } from 'react';

import { Box, Typography } from '@mui/material';
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF, useGridApiRef } from '@mui/x-data-grid-pro';
import { Droppable } from 'react-beautiful-dnd';
import { Waypoint } from 'react-waypoint';

import ComponentPaneContent from 'app/Layout/ReactGridLayout/ComponentPaneContent';
import ConfirmLeavingModal from 'components/ConfirmLeavingModal/ConfirmLeavingModal';
import ItemsDataGridPro, { ItemsDataGridProProvider } from 'components/ItemsDatagridPro';
import { DOCUMENT_STATUS_TYPE } from 'constants/globalConstants';
import { removeDuplicateItemsByKey } from 'helpers/arrayFunctions';
import { ROW_TYPES } from 'modules/Shop/ShopSetup/WorkCellSetup/WorkCellsAndMachines/constants/constants';

import { useDrawingRegisterContext } from '../DrawingRegisterContext';
import { useDrawingRegisterDragDrop } from '../DrawingRegisterDragDropProvider';
import {
  DrawingDocumentNoRowsOverlay,
  DrawingDocumentRow,
  DrawingDocumentToolbar,
  DroppableFolderRow,
  DrawingFolderRow,
} from './components';
import DrawingFolderList from './components/DrawingFolderList/DrawingFolderList';
import { useDrawingDocumentContext } from './DrawingDocumentContext';
import DrawingDocumentRowProvider from './DrawingDocumentRowProvider';
import { DrawingFolderProvider, useDrawingFolderContext } from './DrawingFolderContext';
import { DrawingFolderRowProvider } from './DrawingFolderRowProvider';
import { drawingDocumentMapCb, handleSort } from './helpers/drawingFolderRowsHelper';
import { DocumentListStyles, DrawingDocumentType } from './hooks/constants';
import useDrawingDocumentsTableColumns from './hooks/useDrawingDocumentsTableColumns';
import useGetDocumentAndWrite from './hooks/useGetDocumentAndWrite';

const TIME_TO_CHANGE_STATUS = 20000;

const DrawingDocumentsList = () => {
  const apiRef = useGridApiRef();
  const {
    uploadingDocuments,
    setSortModel,
    loadingRootFolder,
    selectedFolder,
    setUploadingDocuments,
    isTableEditMode,
  } = useDrawingRegisterContext();
  const { documents, fetchMoreDocuments, handleUpdateRow } = useDrawingDocumentContext();
  const { folders, closeNewFolderFormRow, handleGoToFolder, loadParentApiRef } = useDrawingFolderContext();
  const { selectedRows, setSelectedRows, isMappingDocuments, setCheckboxFolderId, checkboxFolderId, isDragging } =
    useDrawingRegisterDragDrop();
  const { columns } = useDrawingDocumentsTableColumns(apiRef, 0, setCheckboxFolderId);
  const { fetchConvertingDocuments } = useGetDocumentAndWrite();

  const showLeavingDialog = useMemo(() => Boolean(uploadingDocuments.length), [uploadingDocuments]);

  useEffect(() => {
    loadParentApiRef(apiRef);
  }, [loadParentApiRef, apiRef]);

  const rows = useMemo(
    () =>
      removeDuplicateItemsByKey(
        [...folders, ...(uploadingDocuments ?? []), ...documents].map(drawingDocumentMapCb),
        'id',
      ),
    [folders, documents, uploadingDocuments],
  );

  const isRowSelectable = useCallback(
    ({ row }) => {
      if (row.type === DrawingDocumentType.FOLDER) return !selectedRows.length;
      return true;
    },
    [selectedRows],
  );

  const onCellClick = (cell) => {
    const { row, field } = cell;
    const { documentStatusTypeId } = row;
    const isLoading = documentStatusTypeId === DOCUMENT_STATUS_TYPE.LOADING_DOCUMENT;

    if (
      field !== GRID_DETAIL_PANEL_TOGGLE_COL_DEF.field &&
      field !== 'encodedUrn' &&
      row.type === DrawingDocumentType.FOLDER
    ) {
      setCheckboxFolderId(null);
      handleGoToFolder(cell);
    }

    if (row.type === DrawingDocumentType.DOCUMENT && !isLoading) {
      handleUpdateRow(cell, cell.field, apiRef.current.updateRows);
    }
  };

  const onSelectDocumentRows = useCallback((rows) => setSelectedRows(rows), [setSelectedRows]);
  const onSelectRow = useCallback(
    (rows = []) => {
      const selectedRow = rows[rows.length - 1];
      if (!selectedRow || selectedRow?.type === DrawingDocumentType.DOCUMENT) onSelectDocumentRows(rows);
    },
    [onSelectDocumentRows],
  );

  const getDetailPanelHeight = useCallback(() => 'auto', []);
  const getDetailPanelContent = useCallback(
    ({ row }) => {
      if (row.type === DrawingDocumentType.FOLDER)
        return (
          <DrawingFolderProvider folderParent={row?.folder} parentApiRef={apiRef}>
            <DrawingFolderList row={row} depth={1} />
          </DrawingFolderProvider>
        );
      return '';
    },
    [apiRef],
  );

  const handleCheckboxChange = (event) => {
    if (event.target.checked) setCheckboxFolderId(selectedFolder?.folderId);
    else setCheckboxFolderId(null);
  };

  const handleSelectAllCheckbox = useCallback(() => {
    const rowsId = rows.map(({ id }) => id);

    const checkbox = document.querySelector('input[aria-label="Select all rows"]');
    checkbox?.addEventListener('change', handleCheckboxChange);

    if (checkboxFolderId) apiRef.current.selectRows(rowsId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiRef, rows]);

  useEffect(() => {
    handleSelectAllCheckbox();
  }, [handleSelectAllCheckbox]);

  useEffect(() => {
    let intervalId;

    const convertingDocuments = documents?.filter(
      ({ documentStatusTypeId }) => documentStatusTypeId === DOCUMENT_STATUS_TYPE.CONVERTING_DOCUMENT,
    );

    if (!isDragging && convertingDocuments.length && !isTableEditMode) {
      intervalId = setInterval(() => {
        fetchConvertingDocuments(convertingDocuments);
      }, TIME_TO_CHANGE_STATUS);
    }

    return () => clearInterval(intervalId);
  }, [documents, fetchConvertingDocuments, isDragging, isTableEditMode]);

  const TableRow = useCallback(
    (params) => {
      const { rowId, row } = params;
      if (row.type === DrawingDocumentType.FOLDER || row.type === DrawingDocumentType.NEW_FOLDER_FORM)
        return <FolderRowWrapper params={params} apiRef={apiRef} onCloseNewFolderForm={closeNewFolderFormRow} />;

      return (
        <DrawingDocumentRowProvider rowData={params} updateRows={apiRef.current.updateRows}>
          <DroppableWrapper>
            {(provided) => (
              <DrawingDocumentRow
                provided={provided}
                params={params}
                selectedRows={selectedRows}
                isMappingDocuments={isMappingDocuments}
                renderLastRow={() => (
                  <LastRowComponent rowId={rowId} rows={rows} fetchMoreDocuments={fetchMoreDocuments} />
                )}
              />
            )}
          </DroppableWrapper>
        </DrawingDocumentRowProvider>
      );
    },
    [apiRef, closeNewFolderFormRow, fetchMoreDocuments, isMappingDocuments, rows, selectedRows],
  );

  const Components = {
    NoRowsOverlay: DrawingDocumentNoRowsOverlay,
    Row: TableRow,
  };

  const getRowClassName = useCallback(
    ({ row }) => (row.type === DrawingDocumentType.FOLDER ? 'folder-row' : 'document-row'),
    [],
  );

  const onSortModelChange = useCallback(
    (sort) => {
      if (!uploadingDocuments.length) setSortModel(handleSort(sort));
    },
    [setSortModel, uploadingDocuments.length],
  );

  return (
    <>
      <DrawingDocumentToolbar />

      {showLeavingDialog && (
        <LeavingDialog
          showLeavingDialog={showLeavingDialog}
          onConfirmNavigation={() => {
            setTimeout(() => setUploadingDocuments([]), 0);
          }}
        />
      )}
      <ComponentPaneContent>
        <ItemsDataGridProProvider>
          <ItemsDataGridPro
            columnBuffer={columns.length}
            apiRef={apiRef}
            columns={columns}
            rows={rows}
            isRowSelectable={isRowSelectable}
            checkboxSelection
            disableSelectionOnClick
            loading={loadingRootFolder}
            getRowClassName={getRowClassName}
            components={Components}
            onSelected={onSelectRow}
            onSortModelChange={onSortModelChange}
            onCellClick={onCellClick}
            getDetailPanelContent={getDetailPanelContent}
            getDetailPanelHeight={getDetailPanelHeight}
            sx={DocumentListStyles}
            disableColumnMenu
            initialState={{
              sorting: {
                sortModel: [{ field: 'documentIdentifier', sort: 'asc' }],
              },
            }}
          />
        </ItemsDataGridProProvider>
      </ComponentPaneContent>
    </>
  );
};

const LeavingDialog = ({ showLeavingDialog, onConfirmNavigation }) => (
  <ConfirmLeavingModal
    showDialog={showLeavingDialog}
    title="Upload incomplete"
    titleStyles={{ fontWeight: 500 }}
    closeButtonColor="#0000008A"
    contentStyles={{ px: 3, py: 2 }}
    onConfirmNavigation={onConfirmNavigation}
  >
    <Box>
      <Typography variant="subtitle1" lineHeight="24px" fontWeight={400} pr={3}>
        Are you sure you want to navigate away from this page? <br />
        Doing so will stop any files that have not finished uploading.
      </Typography>
    </Box>
  </ConfirmLeavingModal>
);

const LastRowComponent = ({ rowId, rows, fetchMoreDocuments }) => {
  const lastRow = rows.slice(-1);
  if (lastRow.length && rowId !== lastRow[0].id) return <></>;
  return <Waypoint key={rows.length} bottomOffset="-20%" onEnter={fetchMoreDocuments} />;
};

const DroppableWrapper = ({ children }) => (
  <Droppable droppableId="drawing-documents" type={ROW_TYPES.TASK_TYPES}>
    {children}
  </Droppable>
);

const FolderRowWrapper = React.memo(({ params, apiRef, onCloseNewFolderForm }) => {
  const { row } = params;
  return (
    <DroppableFolderRow row={row}>
      {(provided, snapshot) => (
        <DrawingFolderRowProvider rowData={params} updateRows={apiRef.current.updateRows}>
          <DrawingFolderRow
            params={params}
            provided={provided}
            snapshot={snapshot}
            onCloseNewFolderForm={onCloseNewFolderForm}
          />
        </DrawingFolderRowProvider>
      )}
    </DroppableFolderRow>
  );
});

export default DrawingDocumentsList;
