/* eslint-disable no-console */
import React from 'react';

import { useMutation, gql } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormHelperText } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';

import { useUser } from 'app/UserContext';
import { Modal } from 'components';
import { addUser as ADD_USER } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

import { useCompanyMembersContext } from '../CompanyMembersContext';
import CompanyInviteForm from './CompanyInviteForm';

const schema = yup.object().shape({
  newCompanyMembers: yup.array().of(yup.string().email()).min(1).required(),
});

const CompanyInviteModal = ({ openStatus, onClose }) => {
  const { refetch } = useCompanyMembersContext();
  const { handleBatchResponse } = useGraphqlResponseHandler();
  const { user } = useUser();
  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const [addCompanyMemberMutation, { loading }] = useMutation(gql(ADD_USER), {
    refetchQueries: ['Users'],
  });

  const submit = async ({ newCompanyMembers }) => {
    try {
      const results = await Promise.all(
        newCompanyMembers.map(async (companyMember) => {
          const res = await addCompanyMemberMutation({
            variables: {
              body: {
                companyId: user.companyId,
                userEmail: companyMember,
                userStatusCode: 'UNCONFIRMED',
                userName: uuidv4(),
              },
            },
          });
          refetch();
          return res;
        }),
      );

      handleBatchResponse(results);
    } finally {
      onClose();
    }
  };

  return (
    <Modal
      open={openStatus}
      onClose={onClose}
      title="Invite users to company"
      fullWidth
      maxWidth="xs"
      disableModalOutsideClick
      footer={
        <>
          {loading && <CircularProgress />}
          <Button color="primary" onClick={onClose} disabled={loading}>
            CANCEL
          </Button>
          <InviteButton onClick={handleSubmit(submit)} disabled={!isValid || loading}>
            INVITE
          </InviteButton>
        </>
      }
    >
      <CompanyInviteForm control={control} loading={loading} />
      {errors.newCompanyMembers && <FormHelperText error>Must be a valid email</FormHelperText>}
    </Modal>
  );
};

export default CompanyInviteModal;

const InviteButton = ({ onClick, disabled, children }) => (
  <Button ml="8px" color="primary" variant="contained" onClick={onClick} disabled={disabled}>
    {children}
  </Button>
);
