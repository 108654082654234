import React from 'react';

import { Typography, Stack } from '@mui/material';

import DaysRemaining from './Columns/DaysRemaining';
import NeedByDate from './Columns/NeedByDate';
import { CellComponentFlag, CellComponentLink, CellComponentStatus } from './WorkCellsTableComponents';

const WorkCellsColumns = (state) => [
  {
    field: 'flag',
    headerName: '',
    type: 'number',
    renderCell: (params) => <CellComponentFlag {...params} />,
    width: '90',
  },
  {
    field: 'workOrderName',
    headerName: 'Work Order Id',
    flex: 3,
    // sortable: false
    renderCell: (params) => <CellComponentLink {...params} />,
  },
  {
    field: 'workRequestName',
    headerName: 'Work Order Name',
    flex: 3,
  },
  {
    field: 'taskName',
    headerName: 'Task Name',
    flex: 3,
  },
  {
    field: 'workCellName',
    headerName: 'Work Cell',
    flex: 2,
  },
  {
    field: 'taskStatusTypeName',
    headerName: 'Status',
    flex: 2,
    renderCell: (params) => <CellComponentStatus {...params} />,
  },
  {
    field: 'needBy',
    headerName: 'Need By',
    flex: 2,
    renderCell: ({ row }) => <NeedByDate date={row.needBy} />,
  },
  {
    field: 'daysRemaining',
    renderHeader: () =>
      state === 'closed' ? (
        <Stack direction="row">
          <Typography variant="tableCellBold"> Days Early/</Typography>
          <Typography variant="tableCellBold" sx={{ color: 'error.main' }}>
            Late
          </Typography>
        </Stack>
      ) : (
        <Typography variant="tableCellBold">Days Remaining</Typography>
      ),
    flex: 2,
    renderCell: ({ row }) => (
      <DaysRemaining needByDate={row.needBy} statusDate={row.taskStatusTypeUpdatedOn} status={row.taskStatusTypeName} />
    ),
  },
];

export default WorkCellsColumns;
