import React, { useState } from 'react';

import { Select } from '@mui/material';

const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 8;

export const CatalogSearchDropdownSelect = ({ children, sx, menuSx, value, onChange }) => {
  const [selectOpened, setSelectOpened] = useState(false);
  return (
    <Select
      variant="outlined"
      style={{ width: '224px', marginRight: 8 }}
      sx={{
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#FFF',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#FFF',
        },
        '.MuiSelect-select.MuiInputBase-input': {
          color: '#FFF',
        },
        '.MuiSelect-icon': {
          color: '#FFF',
        },
        '&:hover': {
          '&& fieldset': {
            border: '1px solid #FFF',
          },
        },
        ...sx,
      }}
      labelId="catalog-dropdown"
      data-testid="catalog-dropdown"
      label="Catalog"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onOpen={() => setSelectOpened(true)}
      onClose={() => setSelectOpened(false)}
      open={selectOpened}
      MenuProps={{
        PaperProps: {
          sx: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: '224px',
            ...menuSx,
          },
        },
      }}
    >
      {children}
    </Select>
  );
};
