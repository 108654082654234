import React, { useState, useEffect, useCallback } from 'react';

import { gql } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { VARIANT_ERROR } from 'constants/snackbarConstants';
import { workCellTasksQueue as WORK_CELL_TASKS_QUEUE } from 'graphql/queries';
import { useLazyTaskStatusTypes } from 'hooks-api/queries/taskStatusTypes/useLazyTaskStatusTypes';
import useCache from 'hooks/useCache';

import useDragAndDrop from '../../context/useDragAndDrop';
import useWorkCellTaskDataLayer from '../../context/useWorkCellTaskDataLayer';
import { useWorkCellsKanban } from '../../context/WorkCellsContext';
import { sortTasksByEnabled } from '../../helpers';

const nonCompleteTaskStatusList = (data) => {
  const { taskStatusTypes } = data;
  const filterByName = taskStatusTypes
    .filter((task) => task.taskStatusTypeName !== 'Completed')
    .map((task) => task.taskStatusTypeId);
  return filterByName.join(',');
};

const WorkCellTasksContext = React.createContext();

const WorkCellTasksProvider = ({ children, workCellId, onFinishedLoading }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [tasks, setTasks] = useState([]);
  const { setWorkOrderIds, workOrderColors } = useWorkCellsKanban();
  const [getTaskStatusTypes] = useLazyTaskStatusTypes({ fetchPolicy: 'cache-first' });
  const { readCache, deleteCache } = useCache();

  const {
    updateTask,
    getTasks: getWorkCellTasks,
    tasksData: workCellTasksData,
    loadingTasks: loadingWorkCellTasks,
  } = useWorkCellTaskDataLayer();

  const { placeholderProps, onDragUpdate, onDragEnd, onDragStart } = useDragAndDrop(setTasks, tasks, updateTask);

  useEffect(
    () => onFinishedLoading({ id: workCellId, isLoading: loadingWorkCellTasks }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingWorkCellTasks],
  );

  const getTaskStatus = useCallback(async () => {
    const { data } = await getTaskStatusTypes();
    if (!data) throw new Error('Could not get task status types.');
    return nonCompleteTaskStatusList(data);
  }, [getTaskStatusTypes]);

  const updateWorkOrderIds = useCallback(
    (workCellTask) => setWorkOrderIds((current) => [...new Set([...current, workCellTask.workOrderId])]),
    [setWorkOrderIds],
  );

  useEffect(() => {
    const getTasks = async () => {
      try {
        const taskStatusTypeIds = await getTaskStatus();
        const shouldFetchTasks = taskStatusTypeIds?.length && workCellId;
        if (!shouldFetchTasks) return;
        const query = { workCellId, taskStatusTypeIds, skip: 0, take: 50 };
        const result = readCache(gql(WORK_CELL_TASKS_QUEUE), { query });
        if (result?.workCellTasksQueue) deleteCache('workCellTasksQueue', { query });
        getWorkCellTasks({ variables: { query } });
      } catch (err) {
        enqueueSnackbar('There was an error getting tasks.', VARIANT_ERROR);
      }
    };
    getTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workCellId]);

  useEffect(() => {
    if (workCellTasksData?.workCellTasksQueue?.length) {
      setTasks(sortTasksByEnabled(workCellTasksData?.workCellTasksQueue));
      workCellTasksData?.workCellTasksQueue?.forEach((workCellTask) => updateWorkOrderIds(workCellTask));
    }
  }, [updateWorkOrderIds, workCellTasksData]);

  const contextObj = {
    workOrderColors,
    workCellTasks: workCellTasksData?.workCellTasksQueue || [],
    tasks,
    setTasks,
    loadingWorkCellTasks,
    placeholderProps,
    onDragUpdate,
    onDragEnd,
    onDragStart,
  };

  return <WorkCellTasksContext.Provider value={contextObj}>{children}</WorkCellTasksContext.Provider>;
};

const useWorkCellTasks = () => {
  const context = React.useContext(WorkCellTasksContext);
  if (context === undefined) throw new Error('useWorkCellTasks must be used within a WorkCellTasksContext');

  return context;
};

export { useWorkCellTasks, WorkCellTasksContext, WorkCellTasksProvider };
