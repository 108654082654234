import { gql, useLazyQuery } from '@apollo/client';

import { billOfMaterialItemById as BILL_OF_MATERIALS_ITEM_BY_ID } from 'graphql/queries';

export const useBillOfMaterialsItemAPI = () => {
  const [fetch, { refetch, data, loading }] = useLazyQuery(gql(BILL_OF_MATERIALS_ITEM_BY_ID));

  return {
    callbacks: { fetch, refetch },
    data: data ?? {},
    metadata: {
      loading,
    },
  };
};
