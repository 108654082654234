export const KEY_CODE_ENTER = 13;

export const mustDisappear = (isSelected, draggingId, rowId) =>
  isSelected && draggingId && rowId !== draggingId;

const getRowTable = (tables, row) => {
  const tableKeys = Object.keys(tables);
  const rowTable = tableKeys.find((tableKey) =>
    tables[tableKey].some((currentRow) => currentRow === row),
  );
  return tables[rowTable];
};

export const getSelectedRowsInfo = (tables, selectedRows, newRow) => {
  const columnOfNew = getRowTable(tables, newRow);
  const indexOfNew = columnOfNew?.indexOf(newRow);

  const lastSelected = selectedRows[selectedRows.length - 1];
  const columnOfLast = getRowTable(tables, lastSelected);
  const indexOfLast = columnOfLast?.indexOf(lastSelected);

  return {
    columnOfNew,
    columnOfLast,
    indexOfNew,
    indexOfLast,
  };
};

export const multiSelect = (tables, selectedRows, row) => {
  if (!selectedRows.length) return [row];

  const { columnOfNew, columnOfLast, indexOfNew, indexOfLast } = getSelectedRowsInfo(
    tables,
    selectedRows,
    row,
  );

  if (columnOfNew !== columnOfLast) return columnOfNew?.slice(0, indexOfNew + 1);
  if (indexOfNew === indexOfLast) return null;

  const isSelectingForwards = indexOfNew > indexOfLast;
  const start = isSelectingForwards ? indexOfLast : indexOfNew;
  const end = isSelectingForwards ? indexOfNew : indexOfLast;

  const inBetween = columnOfNew?.slice(start, end + 1);

  const toAdd = inBetween.filter((task) => !selectedRows.includes(task));
  const sorted = isSelectingForwards ? toAdd : [...toAdd].reverse();
  const combined = [...selectedRows, ...sorted];
  return combined;
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const reorderSingleDrag = (tables, selectedTasks, source, destination) => {
  if (source.droppableId === destination.droppableId) {
    if (source.index === destination.index) return null;

    const column = tables[source.droppableId];
    const reordered = reorder(column, source.index, destination.index);
    return { ...tables, [source.droppableId]: reordered };
  }

  const initialTable = tables[source.droppableId];
  const destinationTable = tables[destination.droppableId];

  const draggedRow = initialTable[source.index];

  const newInitialTableRows = [...initialTable];
  newInitialTableRows.splice(source.index, 1);

  const newDestinationTableRows = [...destinationTable];
  newDestinationTableRows.splice(destination.index, 0, draggedRow);

  return {
    ...tables,
    [source.droppableId]: newInitialTableRows,
    [destination.droppableId]: newDestinationTableRows,
  };
};

const reorderMultiDrag = (tables, selectedTasks, source, destination) => {
  const initialTable = tables[source.droppableId];
  const draggedRow = initialTable[source.index];

  const insertAtIndex = (() => {
    const destinationIndexOffset = selectedTasks.reduce((previous, current) => {
      if (current === draggedRow) return previous;

      const destinationTable = tables[destination.droppableId];
      const column = getRowTable(tables, current);
      if (column !== destinationTable) return previous;

      const index = column.indexOf(current);
      if (index >= destination.index) return previous;

      return previous + 1;
    }, 0);

    const result = destination.index - destinationIndexOffset;
    return result;
  })();

  const orderedSelectedTasks = [...selectedTasks];
  orderedSelectedTasks.sort((a, b) => {
    if (a === draggedRow) return -1;
    if (b === draggedRow) return 1;

    const columnForA = getRowTable(tables, a);
    const indexOfA = columnForA.indexOf(a);
    const columnForB = getRowTable(tables, b);
    const indexOfB = columnForB.indexOf(b);

    if (indexOfA !== indexOfB) return indexOfA - indexOfB;
    return -1;
  });

  const withRemovedTasks = initialTable.filter((row) => !selectedTasks.includes(row));

  let destinationTable = tables[destination.droppableId];

  if (initialTable === destinationTable) destinationTable = withRemovedTasks;

  const withInserted = (() => {
    const base = [...destinationTable];
    base.splice(insertAtIndex, 0, ...orderedSelectedTasks);
    return base;
  })();

  return {
    ...tables,
    [source.droppableId]: withRemovedTasks,
    [destination.droppableId]: withInserted,
  };
};

export const multiDragAwareReorder = (tables, selectedTasks, source, destination) => {
  if (selectedTasks.length > 1) return reorderMultiDrag(tables, selectedTasks, source, destination);

  return reorderSingleDrag(tables, selectedTasks, source, destination);
};
