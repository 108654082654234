import React, { useCallback, memo } from 'react';

import { DataGridPro } from '@mui/x-data-grid-pro';

import { useAsyncApi } from './useAsyncApi';
import { useColumns } from './useColumns';

const sxComponentPanel = {
  '& .MuiButton-root': {
    color: 'secondary.main',
    fontWeight: 500,
  },
  '& .MuiInput-root:after': {
    borderBottom: '2px solid #EE875C',
  },
  '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
    backgroundColor: 'secondary.mainDarkBackground',
  },
  '& .MuiSwitch-switchBase': {
    color: '#fafafa',
  },

  '& .MuiSwitch-switchBase.Mui-checked': {
    color: 'secondary.mainDarkBackground',
  },
  '& .MuiSwitch-track': {
    backgroundColor: '#000',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: 'secondary.main',
  },
  '& .MuiFormControlLabel-root.Mui-disabled': {
    display: 'none',
  },
};

const getSxForItems = (metadata) => ({
  '&.MuiDataGrid-root .MuiDataGrid-row': {
    '.MuiBox-trash': {
      opacity: 0,
    },
    '&:hover .MuiBox-trash': {
      opacity: 1,
    },
  },
  '&.MuiDataGrid-root .MuiDataGrid-row:last-child': {
    // NO REMOVE => Important to add the Bottom removed in &.MuiDataGrid-root .MuiDataGrid-cell
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },

  '&.MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell': {
    // NO REMOVE => Important to show the form errors
    overflow: 'visible !important',
  },
  '&.MuiDataGrid-root .MuiDataGrid-cell': {
    // NO REMOVE BUT REQUIRE IMPROVEMENT TO ADD borderBottom TO columns.length
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    borderBottom: 'none',
  },
  '& .MuiCheckbox-root[aria-disabled="true"]': {
    opacity: 0.5,
  },
  '& .MuiDataGrid-pinnedRows': {
    bgcolor: '#fff',
    boxShadow: 'none ',
    '& .MuiDataGrid-pinnedRows:hover': {
      cursor: 'default',
    },
  },
  '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within, .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 'bold',
  },
  '& .MuiCheckbox-root svg': {
    width: 16,
    height: 16,
    backgroundColor: 'transparent',
    border: `2px solid #0000008a`,
    borderRadius: '2px',
  },
  '& .MuiCheckbox-root.Mui-checked svg': {
    width: 20,
    height: 20,
    backgroundColor: 'transparent',
    border: `0px`,
  },
  '& .MuiCheckbox-root svg path': {
    display: 'none',
  },
  '& .MuiCheckbox-root.Mui-checked svg path': {
    display: 'block',
    color: 'secondary.main',
    border: `0px solid black`,
  },
  '& .MuiDataGrid-columnSeparator': {
    visibility: metadata.hasResizableItems ? 'visible' : 'hidden',
    marginRight: (metadata.hasColumnSeparator || !metadata.hasColumnSeparator) && '5px',
  },
  '& .MuiDataGrid-columnHeader:first-of-type .MuiDataGrid-columnSeparator': {
    visibility: (metadata.hasColumnSeparator || !metadata.hasColumnSeparator) && 'hidden',
  },
  '& .MuiDataGrid-columnHeader:nth-last-of-type(-n+2) .MuiDataGrid-columnSeparator': {
    visibility: metadata.hasColumnSeparator && 'hidden',
  },
  '& .MuiDataGrid-columnHeader:nth-last-of-type(-n+1) .MuiDataGrid-columnSeparator': {
    visibility: !metadata.hasColumnSeparator && 'hidden',
  },
  '& .MuiDataGrid-root.MuiDataGrid-panel': {
    margin: '50px !important',
  },
  '& .MuiDataGrid-main .MuiDataGrid-columnHeaders': {
    overflow: 'visible',
  },
});

const ItemsDataGridPro = ({
  columnsFilterEl = () => {},
  onSelected = () => {},
  asyncApi = {},
  overrideSortModel = null,
  sx = {},
  checkboxSelection = true,
  headerHeight = 35,
  rowHeight = 35,
  rows = [],
  columns = [],
  onSelectedId = 'id',
  hasActiveForm = false,
  sortingMode = 'server',
  ...restProps
}) => {
  const columnOrderRef = useColumns({ prevColumns: columns, hasActiveForm });
  const { metadata } = columnOrderRef;
  const useAsyncApiRef = useAsyncApi({ asyncApi, overrideSortModel, rows });

  const onSelectionModelChange = useCallback(
    (keys = []) => {
      const filter = rows.filter((item) => keys.includes(item[onSelectedId]));
      onSelected(filter);
    },
    [onSelected, rows, onSelectedId],
  );

  return (
    <DataGridPro
      onSelectionModelChange={(keys) => {
        if (restProps.onSelectionModelChange) {
          restProps.onSelectionModelChange(keys);
          return;
        }
        onSelectionModelChange(keys);
      }}
      onRowsel
      rows={rows}
      checkboxSelection={checkboxSelection}
      hideFooter
      headerHeight={headerHeight}
      rowHeight={rowHeight}
      sortingMode={sortingMode}
      componentsProps={{
        panel: {
          anchorEl: columnsFilterEl,
          sx: { ...sxComponentPanel },
        },
      }}
      sx={{ ...getSxForItems(metadata), ...sx }}
      {...columnOrderRef}
      {...useAsyncApiRef}
      {...restProps}
    />
  );
};

export default memo(ItemsDataGridPro);
