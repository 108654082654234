import React, { useState } from 'react';

import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { TabBar } from 'components';
import NoContentMessage from 'components/TableComponents/NoContentMessage';

import Admin from './AdminTab/AdminTab';
import InfoForm from './InfoTab/InfoForm';

const tabComponents = { 0: InfoForm, 1: Admin };

const getFacilityId = (selectedItem) =>
  selectedItem?.type === 'FACILITY' ? selectedItem?.id : null;

const ShopProperties = () => {
  const { selectedItem } = useFacilitiesProjects();
  const facilityId = getFacilityId(selectedItem);

  const [activeTabIndex, setTabIndex] = useState(0);

  const handleTabChange = (e, newIndex) => {
    setTabIndex(newIndex);
  };

  const ActiveTabComponent = tabComponents[activeTabIndex];

  return (
    <>
      <HeaderWrapperSpaceBetween>
        <Stack direction="row">
          <CatalogTitle />
          <TabBar value={activeTabIndex} onChange={handleTabChange}>
            <Tab label="Info" />
            <Tab label="Admin" />
          </TabBar>
        </Stack>
      </HeaderWrapperSpaceBetween>
      {facilityId ? (
        <ActiveTabComponent />
      ) : (
        <NoContentMessage text="Open a facility to view Shop Properties..." />
      )}
    </>
  );
};

export default ShopProperties;

const HeaderWrapperSpaceBetween = ({ children }) => (
  <Stack direction="row" justifyContent="space-between" mb={2}>
    {children}
  </Stack>
);

const CatalogTitle = () => (
  <Box marginTop="4px" mt={0.5}>
    <Typography variant="h5" component="h1">
      Shop Properties
    </Typography>
  </Box>
);
