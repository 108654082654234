import React, { Fragment } from 'react';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';

import InfiniteScroll from 'components/TableComponents/InfiniteScroll';
import NoContentMessage from 'components/TableComponents/NoContentMessage';
import TableContainer from 'components/TableComponents/TableContainer';
import TableHeader from 'components/TableComponents/TableHeaders';
import TableSkeleton from 'components/TableComponents/TableSkeleton';
import {
  handleTableRowClick,
  handleSelectAllClick,
  isSelected,
} from 'components/TableComponents/tableUtilities';

import { useProjectMembersContext } from '../ProjectMembersContext';
import ProjectTableRow from './ProjectTableRow ';

const ProjectMembersTable = ({ headerOptions }) => {
  const { selected, setSelected, data, loading, sortHandler, paginationHandler } =
    useProjectMembersContext();

  const projectUsers = data?.projectUsers || [];

  const TableBodyContent = () => (
    <TableBody>
      {projectUsers.map((member, index) => (
        <Fragment key={member.projectUserId}>
          <ProjectTableRow
            member={member}
            handleRowClick={() => handleTableRowClick(member, selected, setSelected)}
            isItemSelected={isSelected(member, selected)}
          />
          {index === projectUsers.length - 1 && (
            <InfiniteScroll data={projectUsers} fetchMore={paginationHandler} />
          )}
        </Fragment>
      ))}
    </TableBody>
  );

  return (
    <ProjectTableBorderBox>
      <TableContainer>
        <Table stickyHeader>
          <TableHeader
            headerOptions={headerOptions}
            onSelectAllClick={(e) => handleSelectAllClick(e, projectUsers, setSelected)}
            handleSortOrder={sortHandler}
            rowCount={projectUsers.length}
            numSelected={selected.length}
            selectable
          />
          {loading ? (
            <TableSkeleton skeletons={headerOptions.map(({ title }) => title)} />
          ) : (
            <TableBodyContent />
          )}
        </Table>
      </TableContainer>
      {!projectUsers.length && !loading && <NoContentMessage text="No results" />}
    </ProjectTableBorderBox>
  );
};

export default ProjectMembersTable;

const ProjectTableBorderBox = (props) => (
  <Box
    sx={{
      border: '1px solid',
      borderColor: 'other.divider.lightSurface',
      borderRadius: '5px',
      position: 'relative',
      padding: '5px 0 0 0',
      height: 'calc(100% - 135px)',
    }}
    {...props}
  />
);
