import React, { useCallback, useMemo } from 'react';

import { Box } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';

import { useFilterOptions } from './useFilterOptions';

interface OptionsFilterProps {
  padSelected: { takeoffPadName?: string };
}

const STRING_FILTER = 'Revit';

export const OptionsFilter = ({ padSelected }: OptionsFilterProps) => {
  const { onUpdateQuery, options, query } = useFilterOptions();

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => onUpdateQuery({ [event.target.name]: event.target.checked }),
    [onUpdateQuery],
  );

  const isRevitPad = useMemo(() => padSelected && padSelected.takeoffPadName?.includes(STRING_FILTER), [padSelected]);
  const filteredOptions = useMemo(
    () =>
      isRevitPad
        ? options.filter((item) => !item.label.includes(STRING_FILTER) && item.label !== 'Show write-in items')
        : options,
    [isRevitPad, options],
  );

  return (
    <Box sx={{ mx: '56px', my: '32px' }}>
      <FormGroup>
        {filteredOptions.map(({ key, label }) => (
          <FormControlLabel
            sx={{ mb: '8px' }}
            key={key}
            control={
              <Checkbox
                key={`${key}:${query[key] ?? false}`}
                size="small"
                color="secondary"
                checked={query[key] ?? false}
                onChange={onChange}
              />
            }
            name={key}
            label={label}
          />
        ))}
      </FormGroup>
    </Box>
  );
};
