import React, { useEffect, useState } from 'react';

import { CircularProgress, FormControl, InputLabel, MenuItem, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { LOCAL_STORAGE_CONSTANTS } from 'constants/globalConstants';
import { getParamsWihNewValue } from 'helpers/routeFunctions';
import { useLocalStorage } from 'hooks/useLocalStorage';
import useQuery from 'hooks/useQuery';
import useQueryParamMemoized from 'hooks/useQueryParamMemoized';

import { useCatalogSetupContext } from '../CatalogSetupContext';
import NewCatalogButton from '../NewCatalogButton';
import NewCatalogModal from '../NewCatalogModal/NewCatalogModal';
import RightAlignedSelect from '../RightAlignedSelect';

const { SELECTED_CATALOG_ID } = LOCAL_STORAGE_CONSTANTS;

const CatalogSelector = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectOpened, setSelectOpened] = useState(false);
  const { dataCatalogs, loadingCatalogs, selectedCatalog, setSelectedCatalog } =
    useCatalogSetupContext();
  const catalogIdInLocalStorage = localStorage?.getItem(SELECTED_CATALOG_ID)?.replace(/['"]+/g, '');

  const [navigateCatalogId, setNavigateCatalogId] = useState(catalogIdInLocalStorage || null);
  const [, setCurrentCatalogId] = useLocalStorage(SELECTED_CATALOG_ID, '');
  const navigate = useNavigate();
  const query = useQuery();
  const partCatalogIdParam = useQueryParamMemoized('partCatalogId');

  const findCatalogById = (catalogId) => {
    const catalog = (dataCatalogs?.partCatalogs || [])?.find(
      (partCatalog) => partCatalog.partCatalogId === catalogId,
    );

    return catalog;
  };

  const catalog = dataCatalogs?.partCatalogs?.find(
    (partCatalog) => partCatalog.partCatalogId === catalogIdInLocalStorage,
  );

  useEffect(() => {
    if (dataCatalogs && catalog) setSelectedCatalog(catalog);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCatalogs, catalog]);

  useEffect(() => {
    if (partCatalogIdParam === navigateCatalogId) {
      setSelectedCatalog(findCatalogById(navigateCatalogId));
      setCurrentCatalogId(navigateCatalogId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partCatalogIdParam, navigateCatalogId]);

  if (loadingCatalogs) return <CircularProgress color="secondary" />;

  const handleChange = (catalogId) => {
    const catalog = findCatalogById(catalogId);
    if (catalog != null) {
      const newURL = getParamsWihNewValue(query, { partCatalogId: catalogId });
      navigate(newURL);
      setNavigateCatalogId(catalogId);
    }
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectOpened(false);
  };

  const catalogNames =
    (dataCatalogs?.partCatalogs || [])?.map((partCatalog) => partCatalog.partCatalogName) || [];

  return (
    <>
      <FormControl variant="outlined" size="small">
        <InputLabel shrink={selectedCatalog != null} id="catalog-dropdown">
          Catalog
        </InputLabel>
        <RightAlignedSelect
          labelId="catalog-dropdown"
          label="Catalog"
          color="secondary"
          value={selectedCatalog?.partCatalogId || ''}
          onChange={(e) => handleChange(e.target.value)}
          strings={catalogNames}
          notched={selectedCatalog != null}
          onOpen={() => setSelectOpened(true)}
          onClose={() => setSelectOpened(false)}
          open={selectOpened}
          data-testid="catalog-dropdown"
        >
          <NewCatalogButton openModal={openModal} />
          {(dataCatalogs?.partCatalogs || [])?.map(({ partCatalogId, partCatalogName }) => (
            <MenuItem
              data-cy={`partCatalog-${partCatalogId}`}
              key={partCatalogId}
              value={partCatalogId}
            >
              <Typography
                variant="body2"
                sx={{
                  width: '100%',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {partCatalogName}
              </Typography>
            </MenuItem>
          ))}
        </RightAlignedSelect>
      </FormControl>
      <NewCatalogModal open={modalOpen} onClose={closeModal} />
    </>
  );
};

export default CatalogSelector;
