import React from 'react';

import { ROW_TYPES } from '../../constants/constants';
// eslint-disable-next-line import/no-cycle
import LocationDroppableContainer from './dragAndDropComponents/LocationDroppableContainer';
import LocationsRowInfo from './LocationsRowInfo';
import WorkPhaseRowInfo from './WorkPhaseRowInfo';

const TYPES_WITH_DRAGGABLE_AREAS = [ROW_TYPES.ZONE, ROW_TYPES.FLOOR];

const LocationsRow = ({
  node,
  depth = 0,
  isPreview,
  handleToggleOpen,
  parent,
  ascendantIsFloor = false,
}) => {
  const isZoneOrFloor = TYPES_WITH_DRAGGABLE_AREAS.includes(node.locationTypeName);
  const showChildren = isZoneOrFloor || node.isOpen;

  return (
    <>
      {node.workPhaseId ? (
        <WorkPhaseRowInfo node={node} depth={depth} isPreview={isPreview} parent={parent} />
      ) : (
        <LocationsRowInfo
          node={node}
          depth={depth}
          isPreview={isPreview}
          handleToggleOpen={handleToggleOpen}
          parent={parent}
          ascendantIsFloor={ascendantIsFloor}
        />
      )}
      {showChildren && (
        <ChildrenItems
          node={node}
          depth={depth}
          isPreview={isPreview}
          handleToggleOpen={handleToggleOpen}
          ascendantIsFloor={ascendantIsFloor || node.locationTypeName === ROW_TYPES.FLOOR}
        />
      )}
    </>
  );
};

export default LocationsRow;

const ChildrenItems = ({ node, depth, ...rest }) => {
  const getChildren = (key) => node[key] || [];
  const childrenAndWorkPhases = [...getChildren('locationPhases'), ...getChildren('children')];

  if (TYPES_WITH_DRAGGABLE_AREAS.includes(node.locationTypeName))
    return (
      <LocationDroppableContainer
        droppableId={`location_${node.locationId}`}
        childrenNodes={node.isOpen && childrenAndWorkPhases ? childrenAndWorkPhases : []}
        parent={node}
        depth={depth + 1}
        {...rest}
      />
    );

  const children = childrenAndWorkPhases?.map((child) => (
    <LocationsRow
      key={child.locationPhaseId || child.locationId}
      node={child}
      depth={depth + 1}
      parent={node}
      {...rest}
    />
  ));
  return children || [];
};
