import React, { useEffect } from 'react';

import { MenuItem, Stack, Typography } from '@mui/material';
import parse from 'html-react-parser';
import { useForm } from 'react-hook-form';

import CenteredLoadSpinner from 'components/CenteredLoadSpinner';
import InputSelectField from 'components/FormComponents/InputSelectField';

import { useDataContext } from '../../contexts/DataContext';
import CreateShopConfiguration from './CreateShopConfiguration';
import EditShopConfiguration from './EditShopConfiguration';
import { useShopConfigurationSelectContext } from './ShopConfigurationSelectContext';

const ShopConfigurationSelect = () => {
  const {
    setPrevShopConfigId,
    openCreateModal,
    setOpenCreateModal,
    openEditModal,
    setOpenEditModal,
    shopConfigurationSelected,
    setShopConfigurationSelected,
    shopConfigurations,
    loadingShopConfiguration: loading,
    shopConfigurationsUnformatted,
  } = useShopConfigurationSelectContext();
  const { shopConfiguration, setShopConfiguration, selectedItem, shopDepartmentId } = useDataContext();

  const { control, setValue, watch, getValues } = useForm({
    mod: 'all',
    defaultValues: {
      ShopConfigurationId: shopConfiguration?.shopConfigurationId,
    },
  });
  const shopConfigurationId = watch('ShopConfigurationId');

  useEffect(() => {
    if (!shopConfigurations) return;
    const activeShopConfiguration = shopConfigurations?.find(({ isActive }) => isActive);
    if (activeShopConfiguration) {
      setValue('ShopConfigurationId', activeShopConfiguration.value);
      return;
    }
    if (shopConfigurations && shopConfigurations.length > 0)
      setValue('ShopConfigurationId', shopConfigurations[0].value);
  }, [selectedItem, shopConfigurations, setValue]);

  useEffect(() => {
    setShopConfiguration(shopConfigurations?.find(({ value }) => value === shopConfigurationId));
  }, [shopConfigurations, shopConfigurationId, setShopConfiguration]);

  const handleSelectChange = (event) => {
    const selected = shopConfigurationsUnformatted.find((item) => item.shopConfigurationId === event.target.value);
    setPrevShopConfigId(getValues()?.ShopConfigurationId);
    if (selected) {
      setShopConfigurationSelected(selected);
    }
  };

  const handleAddNew = () => {
    setPrevShopConfigId(getValues()?.ShopConfigurationId);
    setOpenCreateModal(true);
  };

  if (loading || !shopConfigurations) return <CenteredLoadSpinner />;

  return (
    <>
      <Stack direction="row" gap="8px" marginTop="-8px">
        {shopConfiguration?.isActive && <ActiveTag />}
        <DropDown
          disabled={!selectedItem || !shopDepartmentId}
          control={control}
          options={shopConfigurations}
          handleAddNewOption={handleAddNew}
          onSelectChange={handleSelectChange}
          defaultValue={shopConfiguration}
        />
      </Stack>

      <EditShopConfiguration
        openEditModal={openEditModal}
        setOpenEditModal={setOpenEditModal}
        setValue={setValue}
        shopConfiguration={shopConfigurationSelected}
      />

      <CreateShopConfiguration
        openCreateModal={openCreateModal}
        setOpenCreateModal={setOpenCreateModal}
        setValue={setValue}
      />
    </>
  );
};

export default ShopConfigurationSelect;

const DropDown = ({ ...rest }) => (
  <InputSelectField
    name="ShopConfigurationId"
    label="Shop Configuration"
    addNewOptionText="New shop configuration"
    color="secondary"
    size="small"
    fullWidth
    style={{ marginTop: 2, marginBottom: 1 }}
    showActive
    dataTestId="shop-configuration-select"
    sx={{
      '.MuiSelect-select .MuiTypography-caption': {
        display: 'none',
      },
    }}
    renderMenuItem={(option) => (
      <MenuItem disabled={option.disabled} value={option.value} key={option.value} color="secondary">
        <Stack direction="row" alignItems="center" gap="8px">
          <Typography variant="body2">{parse(option.label || '')} </Typography>
          {option.isActive && (
            <Typography variant="caption" color="secondary">
              Active
            </Typography>
          )}
        </Stack>
      </MenuItem>
    )}
    {...rest}
  />
);

const ActiveTag = () => (
  <Stack position="relative">
    <Stack
      position="absolute"
      sx={{
        left: '-30px',
      }}
    >
      <Typography variant="caption" color="secondary" mt="-8px">
        Active
      </Typography>
    </Stack>
  </Stack>
);
