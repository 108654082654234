import React, { useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { useUser } from 'app/UserContext';

import { useAdminContext } from './AdminContext';
import AdminDeleteModal from './AdminDeleteModal';

const AdminActions = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { user } = useUser();
  const { isShopAdmin, isShopAdminInvitationCompleted } = useFacilitiesProjects();

  const { selected, setSelected } = useAdminContext();
  const selectedMemberIds = selected.map(({ departmentUserId }) => departmentUserId);

  const hasUserDeleteAdminPermission = useMemo(
    () => user?.isAdmin || (isShopAdmin && isShopAdminInvitationCompleted),
    [user, isShopAdmin, isShopAdminInvitationCompleted],
  );

  return (
    <Box>
      <MenuItem
        onClick={() => setShowDeleteModal(true)}
        disabled={!selected.length || !hasUserDeleteAdminPermission}
      >
        Delete
      </MenuItem>
      <AdminDeleteModal
        userIds={selectedMemberIds}
        openStatus={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
          setSelected([]);
        }}
      />
    </Box>
  );
};

export default AdminActions;
