/* eslint-disable max-lines-per-function */
import React, { useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import { draftPartReactiveVar } from 'apollo/reactiveVars';
import useAttributeAPI from 'hooks-api/useAttributeAPI';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import { UNPUBLISHED_PART, SELECTED_CATEGORY } from 'modules/Materials/AssemblyEditor/Utils/constants';

import { usePartImageContext } from '../ItemsSection/Manufacturer/PartImage/PartImageContext';
import { getUomOptions } from '../ItemsSection/Manufacturer/UnitOfMeasure/unitOfMeasureHelpers';
import {
  getAttributeVariants,
  getPartItemFormValue,
  handleAddPartResponse,
  PART_ITEM_FORM_DEFAULT_VALUE,
  updateSelectedPart,
} from '../ItemsSection/NewItemsSection/newItemSectionHelpers';
import { useCatalogSelectedPartContext } from './CatalogSelectedPartProvider';
import { useManufacturerAPIContext } from './ManufacturerAPIProvider';
import { usePartAPIContext } from './PartAPIProvider';
import { useUOMAPIContext } from './UOMAPIProvider';

const CatalogPartContext = React.createContext();

const CatalogPartProvider = ({ children }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showNewItem, setShowNewItem] = useState(false);
  const [loadingPublishAssembly, setLoadingPublishAssembly] = useState(false);
  const [attributes, setAttributes] = useState([]);
  const [recentlyAddedPart, setRecentlyAddedPart] = useState(null);
  const [error, setError] = useState(null);

  const { selectedPart, setSelectedPart } = useCatalogSelectedPartContext();

  const { manufactured, onGetManufactured, getManufacturerId } = useManufacturerAPIContext();
  const { uoms, getUnitOfMeasureId } = useUOMAPIContext();

  const { addPart, updatePart, loadParts } = usePartAPIContext();

  useEffect(() => {
    if (selectedCategory) localStorage.setItem(SELECTED_CATEGORY, JSON.stringify(selectedCategory));
  }, [selectedCategory]);

  useEffect(() => {
    setShowNewItem(false);
    setSelectedPart(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  const uomOptions = useMemo(() => getUomOptions(uoms), [uoms]);
  const defaultValues = useMemo(() => {
    const defaultValuesLocalStorage = JSON.parse(localStorage.getItem(UNPUBLISHED_PART));
    return (
      defaultValuesLocalStorage ||
      (!selectedPart
        ? PART_ITEM_FORM_DEFAULT_VALUE
        : getPartItemFormValue({ selectedPart, uoms: uomOptions, manufacturers: manufactured }))
    );
  }, [selectedPart, manufactured, uomOptions]);

  const methods = useForm({ mode: 'onChange', defaultValues });

  const { handleResponse, handleBatchResponse } = useGraphqlResponseHandler();

  const { uploadPartImages } = usePartImageContext();
  const { enqueueSnackbar } = useSnackbar();
  const {
    callbacks: { onAddNewAttributeVariant },
  } = useAttributeAPI(attributes);

  const handleUpdate = async () => {
    setRecentlyAddedPart(selectedPart?.partId);
    methods.reset();
    setShowNewItem(false);
    setSelectedPart(null);
    draftPartReactiveVar(null);
    await loadParts({
      partCategoryId: selectedCategory?.partCategoryId,
      orderBy: 'dateadded:desc',
    });
    await onGetManufactured();
  };

  const onSubmit = async (values, shouldReturnPartData = false, part = null, showSnackbar = true) => {
    const returnPartData = shouldReturnPartData === true; // this NEEDS to be explicitly 'true'
    setError(null);
    if (part || selectedPart) {
      return updateSelectedPart({
        values,
        getUnitOfMeasureId,
        getManufacturerId,
        selectedPart: part || selectedPart,
        onAddNewAttributeVariant,
        defaultValues,
        uploadPartImages,
        enqueueSnackbar,
        handleUpdate,
        handleResponse,
        updatePart,
        showSnackbar,
      });
    }
    const category = selectedCategory || JSON.parse(localStorage.getItem(SELECTED_CATEGORY));
    const variants = await getAttributeVariants(values?.partAttributeVariantData || [], onAddNewAttributeVariant, true);

    const body = {
      manufacturerId: await getManufacturerId(values),
      partName: values?.partName.trim(),
      description: values?.description.trim(),
      unitOfMeasureId: await getUnitOfMeasureId(values),
      partCategoryId: category?.partCategoryId,
    };

    if (variants.length) {
      body.partAttributeVariantData = variants;
    }
    const addPartResp = await addPart({ variables: { body } });
    return handleAddPartResponse({
      addPartResp,
      uploadPartImages,
      values,
      returnPartData,
      loadParts,
      category,
      onGetManufactured,
      setRecentlyAddedPart,
      setShowNewItem,
      methods,
      handleBatchResponse,
    });
  };

  const valueObj = {
    loadingPublishAssembly,
    setLoadingPublishAssembly,
    methods,
    defaultValues,
    error,
    setError,
    recentlyAddedPart,
    setRecentlyAddedPart,
    attributes,
    setAttributes,
    showNewItem,
    setShowNewItem,
    selectedCategory,
    setSelectedCategory,
    onSubmit,
  };
  return <CatalogPartContext.Provider value={valueObj}>{children}</CatalogPartContext.Provider>;
};

const useCatalogPartContext = () => {
  const context = React.useContext(CatalogPartContext);
  if (context === undefined) {
    throw new Error('useCatalogPartContext must be used within an CatalogPartContext');
  }
  return context;
};

export { CatalogPartProvider, CatalogPartContext, useCatalogPartContext };
