import React, { useMemo } from 'react';

import { useReactiveVar } from '@apollo/client';
import { Box, Stack, Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';
import parse from 'html-react-parser';

import { selectedMessageVar } from 'apollo/reactiveVars';
import useBreakpoint from 'hooks/useBreakpoint';

import { useMessagesContext } from './contexts/MessagesContext';
import MessageOptions from './MessageOptions';

const MessagePreview = () => {
  const { isTablet } = useBreakpoint();
  const selectedMessage = useReactiveVar(selectedMessageVar);
  const { onArchiveMessage, onDeleteMessage, selectedMessagesTab } = useMessagesContext();
  const selectedMessageBody = useMemo(() => selectedMessage?.body ?? '', [selectedMessage]);

  return (
    <MessagePreviewWrapper>
      <MessageHTMLWrapper isTablet={isTablet}>
        {selectedMessage && (
          <>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="caption"
                fontSize="34px"
                fontWeight={400}
                sx={{
                  marginBottom: '20px',
                  marginTop: '22px',
                  lineHeight: '48px',
                  marginRight: 'auto',
                }}
              >
                {selectedMessage.subject}
              </Typography>
              <MessageOptions
                isArchive={Boolean(selectedMessagesTab)}
                message={selectedMessage}
                onArchive={onArchiveMessage}
                onDelete={onDeleteMessage}
              />
            </Stack>
            <Typography
              variant="caption"
              fontSize="14px"
              fontWeight={700}
              lineHeight="20px"
              sx={{ color: 'rgba(0, 0, 0, 0.5)' }}
            >
              {selectedMessage?.createdOn &&
                format(parseISO(selectedMessage?.createdOn), 'MMMM dd, yyyy')}
            </Typography>
            <MessageHTMLBox>{parse(selectedMessageBody ?? '')}</MessageHTMLBox>
          </>
        )}
      </MessageHTMLWrapper>
    </MessagePreviewWrapper>
  );
};

export default MessagePreview;

const MessagePreviewWrapper = ({ children }) => (
  <Stack alignItems="center" justifyContent="center" width="100%">
    {children}
  </Stack>
);

const MessageHTMLWrapper = ({ isTablet, children }) => (
  <Stack
    sx={{
      minWidth: '656px',
      width: isTablet ? '100%' : '50vw',
      borderRadius: '4px',
      background: 'white',
      height: '100%',
      overflowY: 'scroll',
      padding: '22px 52px',
    }}
  >
    {children}
  </Stack>
);

const MessageHTMLBox = ({ children }) => <Box sx={{ maxWidth: '100%' }}>{children}</Box>;
