/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const readAllMessage = /* GraphQL */ `
  mutation ReadAllMessage {
    readAllMessage
  }
`;
export const addAssembly = /* GraphQL */ `
  mutation AddAssembly(
    $query: MutationAddAssemblyQueryInput
    $body: MutationAddAssemblyBodyInput
  ) {
    addAssembly(query: $query, body: $body) {
      part {
        partId
        partName
        description
        stateId
        isDefault
        manufacturerCatalogNumber
        unitOfMeasureCode
        unitOfMeasureName
        upcCode
        url
        catalogUrl
        partUrl
        internalPartNumber
        createdOn
        hasAssembly
        manufacturer {
          manufacturerId
          companyId
          manufacturerName
          homePageUrl
        }
        attriubuteVariantData {
          partAttributeVariantDataId
          partAttributeId
          partAttributeName
          rangeValue
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
          partAttribute {
            partAttributeId
            partAttributeName
            allowWriteInVariant
            rangeMinimumValue
            rangeMaximumValue
            selectVariants {
              partAttributeSelectVariantId
              textValue
              codeValue
              isDeleted
            }
            partAttributeType {
              partAttributeTypeId
              partAttributeTypeName
              partAttributeTypeDescription
              sortOrder
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          selectValues {
            partAttributeSelectVariantId
            textValue
            codeValue
            isDeleted
          }
        }
        partCategory {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        partImages {
          partImageId
          sortOrder
          isDefault
          document {
            documentId
            documentName
            documentIdentifier
            documentTypeId
            storagePath
            extension
            encodedUrn
            documentStatusTypeId
            isMappedFromShop
            folderDocumentResponse {
              folderDocumentId
              folderId
              folderName
              documentId
            }
            documentNameFormatted
            createdOn
            createdBy
          }
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
        }
        documentIds
        unitOfMeasure {
          unitOfMeasureId
          unitOfMeasureTypeId
          companyId
          unitOfMeasureName
          unitOfMeasureCode
          createdOn
          createdBy
          updatedOn
          modifiedBy
          modifiedOn
        }
      }
      assemblyId
      historyTypeId
      assemblyNodes {
        quantity
        part {
          partId
          partName
          description
          stateId
          isDefault
          manufacturerCatalogNumber
          unitOfMeasureCode
          unitOfMeasureName
          upcCode
          url
          catalogUrl
          partUrl
          internalPartNumber
          createdOn
          hasAssembly
          manufacturer {
            manufacturerId
            companyId
            manufacturerName
            homePageUrl
          }
          attriubuteVariantData {
            partAttributeVariantDataId
            partAttributeId
            partAttributeName
            rangeValue
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            partAttribute {
              partAttributeId
              partAttributeName
              allowWriteInVariant
              rangeMinimumValue
              rangeMaximumValue
              selectVariants {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
              partAttributeType {
                partAttributeTypeId
                partAttributeTypeName
                partAttributeTypeDescription
                sortOrder
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
            }
            selectValues {
              partAttributeSelectVariantId
              textValue
              codeValue
              isDeleted
            }
          }
          partCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          partImages {
            partImageId
            sortOrder
            isDefault
            document {
              documentId
              documentName
              documentIdentifier
              documentTypeId
              storagePath
              extension
              encodedUrn
              documentStatusTypeId
              isMappedFromShop
              folderDocumentResponse {
                folderDocumentId
                folderId
                folderName
                documentId
              }
              documentNameFormatted
              createdOn
              createdBy
            }
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
          }
          documentIds
          unitOfMeasure {
            unitOfMeasureId
            unitOfMeasureTypeId
            companyId
            unitOfMeasureName
            unitOfMeasureCode
            createdOn
            createdBy
            updatedOn
            modifiedBy
            modifiedOn
          }
        }
        shopTaskId
        assemblyNodeId
        historyTypeId
        assembly {
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
          assemblyId
          historyTypeId
          assemblyNodes {
            quantity
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            shopTaskId
            assemblyNodeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyNodeType {
              assemblyNodeTypeId
              assemblyNodeTypeName
            }
            assemblyNodeName
            assemblyNodeDescription
            positionX
            positionY
          }
          assemblyEdges {
            assemblyEdgeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyEdgeType {
              assemblyEdgeTypeId
              assemblyEdgeTypeName
            }
            beginHandlePosition {
              assemblyHandlePositionId
              assemblyHandlePositionName
            }
            endHandlePosition {
              assemblyHandlePositionId
              assemblyHandlePositionName
            }
            beginNode {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
            endNode {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
          }
        }
        assemblyNodeType {
          assemblyNodeTypeId
          assemblyNodeTypeName
        }
        assemblyNodeName
        assemblyNodeDescription
        positionX
        positionY
      }
      assemblyEdges {
        assemblyEdgeId
        historyTypeId
        assembly {
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
          assemblyId
          historyTypeId
          assemblyNodes {
            quantity
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            shopTaskId
            assemblyNodeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyNodeType {
              assemblyNodeTypeId
              assemblyNodeTypeName
            }
            assemblyNodeName
            assemblyNodeDescription
            positionX
            positionY
          }
          assemblyEdges {
            assemblyEdgeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyEdgeType {
              assemblyEdgeTypeId
              assemblyEdgeTypeName
            }
            beginHandlePosition {
              assemblyHandlePositionId
              assemblyHandlePositionName
            }
            endHandlePosition {
              assemblyHandlePositionId
              assemblyHandlePositionName
            }
            beginNode {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
            endNode {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
          }
        }
        assemblyEdgeType {
          assemblyEdgeTypeId
          assemblyEdgeTypeName
        }
        beginHandlePosition {
          assemblyHandlePositionId
          assemblyHandlePositionName
        }
        endHandlePosition {
          assemblyHandlePositionId
          assemblyHandlePositionName
        }
        beginNode {
          quantity
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
          shopTaskId
          assemblyNodeId
          historyTypeId
          assembly {
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            assemblyId
            historyTypeId
            assemblyNodes {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
            assemblyEdges {
              assemblyEdgeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyEdgeType {
                assemblyEdgeTypeId
                assemblyEdgeTypeName
              }
              beginHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              endHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              beginNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              endNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
            }
          }
          assemblyNodeType {
            assemblyNodeTypeId
            assemblyNodeTypeName
          }
          assemblyNodeName
          assemblyNodeDescription
          positionX
          positionY
        }
        endNode {
          quantity
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
          shopTaskId
          assemblyNodeId
          historyTypeId
          assembly {
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            assemblyId
            historyTypeId
            assemblyNodes {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
            assemblyEdges {
              assemblyEdgeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyEdgeType {
                assemblyEdgeTypeId
                assemblyEdgeTypeName
              }
              beginHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              endHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              beginNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              endNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
            }
          }
          assemblyNodeType {
            assemblyNodeTypeId
            assemblyNodeTypeName
          }
          assemblyNodeName
          assemblyNodeDescription
          positionX
          positionY
        }
      }
    }
  }
`;
export const updateAssembly = /* GraphQL */ `
  mutation UpdateAssembly(
    $query: MutationUpdateAssemblyQueryInput
    $body: MutationUpdateAssemblyBodyInput
  ) {
    updateAssembly(query: $query, body: $body) {
      part {
        partId
        partName
        description
        stateId
        isDefault
        manufacturerCatalogNumber
        unitOfMeasureCode
        unitOfMeasureName
        upcCode
        url
        catalogUrl
        partUrl
        internalPartNumber
        createdOn
        hasAssembly
        manufacturer {
          manufacturerId
          companyId
          manufacturerName
          homePageUrl
        }
        attriubuteVariantData {
          partAttributeVariantDataId
          partAttributeId
          partAttributeName
          rangeValue
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
          partAttribute {
            partAttributeId
            partAttributeName
            allowWriteInVariant
            rangeMinimumValue
            rangeMaximumValue
            selectVariants {
              partAttributeSelectVariantId
              textValue
              codeValue
              isDeleted
            }
            partAttributeType {
              partAttributeTypeId
              partAttributeTypeName
              partAttributeTypeDescription
              sortOrder
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          selectValues {
            partAttributeSelectVariantId
            textValue
            codeValue
            isDeleted
          }
        }
        partCategory {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        partImages {
          partImageId
          sortOrder
          isDefault
          document {
            documentId
            documentName
            documentIdentifier
            documentTypeId
            storagePath
            extension
            encodedUrn
            documentStatusTypeId
            isMappedFromShop
            folderDocumentResponse {
              folderDocumentId
              folderId
              folderName
              documentId
            }
            documentNameFormatted
            createdOn
            createdBy
          }
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
        }
        documentIds
        unitOfMeasure {
          unitOfMeasureId
          unitOfMeasureTypeId
          companyId
          unitOfMeasureName
          unitOfMeasureCode
          createdOn
          createdBy
          updatedOn
          modifiedBy
          modifiedOn
        }
      }
      assemblyId
      historyTypeId
      assemblyNodes {
        quantity
        part {
          partId
          partName
          description
          stateId
          isDefault
          manufacturerCatalogNumber
          unitOfMeasureCode
          unitOfMeasureName
          upcCode
          url
          catalogUrl
          partUrl
          internalPartNumber
          createdOn
          hasAssembly
          manufacturer {
            manufacturerId
            companyId
            manufacturerName
            homePageUrl
          }
          attriubuteVariantData {
            partAttributeVariantDataId
            partAttributeId
            partAttributeName
            rangeValue
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            partAttribute {
              partAttributeId
              partAttributeName
              allowWriteInVariant
              rangeMinimumValue
              rangeMaximumValue
              selectVariants {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
              partAttributeType {
                partAttributeTypeId
                partAttributeTypeName
                partAttributeTypeDescription
                sortOrder
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
            }
            selectValues {
              partAttributeSelectVariantId
              textValue
              codeValue
              isDeleted
            }
          }
          partCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          partImages {
            partImageId
            sortOrder
            isDefault
            document {
              documentId
              documentName
              documentIdentifier
              documentTypeId
              storagePath
              extension
              encodedUrn
              documentStatusTypeId
              isMappedFromShop
              folderDocumentResponse {
                folderDocumentId
                folderId
                folderName
                documentId
              }
              documentNameFormatted
              createdOn
              createdBy
            }
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
          }
          documentIds
          unitOfMeasure {
            unitOfMeasureId
            unitOfMeasureTypeId
            companyId
            unitOfMeasureName
            unitOfMeasureCode
            createdOn
            createdBy
            updatedOn
            modifiedBy
            modifiedOn
          }
        }
        shopTaskId
        assemblyNodeId
        historyTypeId
        assembly {
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
          assemblyId
          historyTypeId
          assemblyNodes {
            quantity
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            shopTaskId
            assemblyNodeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyNodeType {
              assemblyNodeTypeId
              assemblyNodeTypeName
            }
            assemblyNodeName
            assemblyNodeDescription
            positionX
            positionY
          }
          assemblyEdges {
            assemblyEdgeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyEdgeType {
              assemblyEdgeTypeId
              assemblyEdgeTypeName
            }
            beginHandlePosition {
              assemblyHandlePositionId
              assemblyHandlePositionName
            }
            endHandlePosition {
              assemblyHandlePositionId
              assemblyHandlePositionName
            }
            beginNode {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
            endNode {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
          }
        }
        assemblyNodeType {
          assemblyNodeTypeId
          assemblyNodeTypeName
        }
        assemblyNodeName
        assemblyNodeDescription
        positionX
        positionY
      }
      assemblyEdges {
        assemblyEdgeId
        historyTypeId
        assembly {
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
          assemblyId
          historyTypeId
          assemblyNodes {
            quantity
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            shopTaskId
            assemblyNodeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyNodeType {
              assemblyNodeTypeId
              assemblyNodeTypeName
            }
            assemblyNodeName
            assemblyNodeDescription
            positionX
            positionY
          }
          assemblyEdges {
            assemblyEdgeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyEdgeType {
              assemblyEdgeTypeId
              assemblyEdgeTypeName
            }
            beginHandlePosition {
              assemblyHandlePositionId
              assemblyHandlePositionName
            }
            endHandlePosition {
              assemblyHandlePositionId
              assemblyHandlePositionName
            }
            beginNode {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
            endNode {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
          }
        }
        assemblyEdgeType {
          assemblyEdgeTypeId
          assemblyEdgeTypeName
        }
        beginHandlePosition {
          assemblyHandlePositionId
          assemblyHandlePositionName
        }
        endHandlePosition {
          assemblyHandlePositionId
          assemblyHandlePositionName
        }
        beginNode {
          quantity
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
          shopTaskId
          assemblyNodeId
          historyTypeId
          assembly {
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            assemblyId
            historyTypeId
            assemblyNodes {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
            assemblyEdges {
              assemblyEdgeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyEdgeType {
                assemblyEdgeTypeId
                assemblyEdgeTypeName
              }
              beginHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              endHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              beginNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              endNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
            }
          }
          assemblyNodeType {
            assemblyNodeTypeId
            assemblyNodeTypeName
          }
          assemblyNodeName
          assemblyNodeDescription
          positionX
          positionY
        }
        endNode {
          quantity
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
          shopTaskId
          assemblyNodeId
          historyTypeId
          assembly {
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            assemblyId
            historyTypeId
            assemblyNodes {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
            assemblyEdges {
              assemblyEdgeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyEdgeType {
                assemblyEdgeTypeId
                assemblyEdgeTypeName
              }
              beginHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              endHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              beginNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              endNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
            }
          }
          assemblyNodeType {
            assemblyNodeTypeId
            assemblyNodeTypeName
          }
          assemblyNodeName
          assemblyNodeDescription
          positionX
          positionY
        }
      }
    }
  }
`;
export const deleteAssembly = /* GraphQL */ `
  mutation DeleteAssembly($params: MutationDeleteAssemblyParamsInput!) {
    deleteAssembly(params: $params)
  }
`;
export const addAssemblyNode = /* GraphQL */ `
  mutation AddAssemblyNode(
    $query: MutationAddAssemblyNodeQueryInput
    $body: MutationAddAssemblyNodeBodyInput
  ) {
    addAssemblyNode(query: $query, body: $body) {
      quantity
      part {
        partId
        partName
        description
        stateId
        isDefault
        manufacturerCatalogNumber
        unitOfMeasureCode
        unitOfMeasureName
        upcCode
        url
        catalogUrl
        partUrl
        internalPartNumber
        createdOn
        hasAssembly
        manufacturer {
          manufacturerId
          companyId
          manufacturerName
          homePageUrl
        }
        attriubuteVariantData {
          partAttributeVariantDataId
          partAttributeId
          partAttributeName
          rangeValue
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
          partAttribute {
            partAttributeId
            partAttributeName
            allowWriteInVariant
            rangeMinimumValue
            rangeMaximumValue
            selectVariants {
              partAttributeSelectVariantId
              textValue
              codeValue
              isDeleted
            }
            partAttributeType {
              partAttributeTypeId
              partAttributeTypeName
              partAttributeTypeDescription
              sortOrder
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          selectValues {
            partAttributeSelectVariantId
            textValue
            codeValue
            isDeleted
          }
        }
        partCategory {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        partImages {
          partImageId
          sortOrder
          isDefault
          document {
            documentId
            documentName
            documentIdentifier
            documentTypeId
            storagePath
            extension
            encodedUrn
            documentStatusTypeId
            isMappedFromShop
            folderDocumentResponse {
              folderDocumentId
              folderId
              folderName
              documentId
            }
            documentNameFormatted
            createdOn
            createdBy
          }
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
        }
        documentIds
        unitOfMeasure {
          unitOfMeasureId
          unitOfMeasureTypeId
          companyId
          unitOfMeasureName
          unitOfMeasureCode
          createdOn
          createdBy
          updatedOn
          modifiedBy
          modifiedOn
        }
      }
      shopTaskId
      assemblyNodeId
      historyTypeId
      assembly {
        part {
          partId
          partName
          description
          stateId
          isDefault
          manufacturerCatalogNumber
          unitOfMeasureCode
          unitOfMeasureName
          upcCode
          url
          catalogUrl
          partUrl
          internalPartNumber
          createdOn
          hasAssembly
          manufacturer {
            manufacturerId
            companyId
            manufacturerName
            homePageUrl
          }
          attriubuteVariantData {
            partAttributeVariantDataId
            partAttributeId
            partAttributeName
            rangeValue
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            partAttribute {
              partAttributeId
              partAttributeName
              allowWriteInVariant
              rangeMinimumValue
              rangeMaximumValue
              selectVariants {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
              partAttributeType {
                partAttributeTypeId
                partAttributeTypeName
                partAttributeTypeDescription
                sortOrder
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
            }
            selectValues {
              partAttributeSelectVariantId
              textValue
              codeValue
              isDeleted
            }
          }
          partCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          partImages {
            partImageId
            sortOrder
            isDefault
            document {
              documentId
              documentName
              documentIdentifier
              documentTypeId
              storagePath
              extension
              encodedUrn
              documentStatusTypeId
              isMappedFromShop
              folderDocumentResponse {
                folderDocumentId
                folderId
                folderName
                documentId
              }
              documentNameFormatted
              createdOn
              createdBy
            }
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
          }
          documentIds
          unitOfMeasure {
            unitOfMeasureId
            unitOfMeasureTypeId
            companyId
            unitOfMeasureName
            unitOfMeasureCode
            createdOn
            createdBy
            updatedOn
            modifiedBy
            modifiedOn
          }
        }
        assemblyId
        historyTypeId
        assemblyNodes {
          quantity
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
          shopTaskId
          assemblyNodeId
          historyTypeId
          assembly {
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            assemblyId
            historyTypeId
            assemblyNodes {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
            assemblyEdges {
              assemblyEdgeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyEdgeType {
                assemblyEdgeTypeId
                assemblyEdgeTypeName
              }
              beginHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              endHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              beginNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              endNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
            }
          }
          assemblyNodeType {
            assemblyNodeTypeId
            assemblyNodeTypeName
          }
          assemblyNodeName
          assemblyNodeDescription
          positionX
          positionY
        }
        assemblyEdges {
          assemblyEdgeId
          historyTypeId
          assembly {
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            assemblyId
            historyTypeId
            assemblyNodes {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
            assemblyEdges {
              assemblyEdgeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyEdgeType {
                assemblyEdgeTypeId
                assemblyEdgeTypeName
              }
              beginHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              endHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              beginNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              endNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
            }
          }
          assemblyEdgeType {
            assemblyEdgeTypeId
            assemblyEdgeTypeName
          }
          beginHandlePosition {
            assemblyHandlePositionId
            assemblyHandlePositionName
          }
          endHandlePosition {
            assemblyHandlePositionId
            assemblyHandlePositionName
          }
          beginNode {
            quantity
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            shopTaskId
            assemblyNodeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyNodeType {
              assemblyNodeTypeId
              assemblyNodeTypeName
            }
            assemblyNodeName
            assemblyNodeDescription
            positionX
            positionY
          }
          endNode {
            quantity
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            shopTaskId
            assemblyNodeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyNodeType {
              assemblyNodeTypeId
              assemblyNodeTypeName
            }
            assemblyNodeName
            assemblyNodeDescription
            positionX
            positionY
          }
        }
      }
      assemblyNodeType {
        assemblyNodeTypeId
        assemblyNodeTypeName
      }
      assemblyNodeName
      assemblyNodeDescription
      positionX
      positionY
    }
  }
`;
export const updateAssemblyNode = /* GraphQL */ `
  mutation UpdateAssemblyNode(
    $params: MutationUpdateAssemblyNodeParamsInput!
    $query: MutationUpdateAssemblyNodeQueryInput
    $body: MutationUpdateAssemblyNodeBodyInput
  ) {
    updateAssemblyNode(params: $params, query: $query, body: $body) {
      quantity
      part {
        partId
        partName
        description
        stateId
        isDefault
        manufacturerCatalogNumber
        unitOfMeasureCode
        unitOfMeasureName
        upcCode
        url
        catalogUrl
        partUrl
        internalPartNumber
        createdOn
        hasAssembly
        manufacturer {
          manufacturerId
          companyId
          manufacturerName
          homePageUrl
        }
        attriubuteVariantData {
          partAttributeVariantDataId
          partAttributeId
          partAttributeName
          rangeValue
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
          partAttribute {
            partAttributeId
            partAttributeName
            allowWriteInVariant
            rangeMinimumValue
            rangeMaximumValue
            selectVariants {
              partAttributeSelectVariantId
              textValue
              codeValue
              isDeleted
            }
            partAttributeType {
              partAttributeTypeId
              partAttributeTypeName
              partAttributeTypeDescription
              sortOrder
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          selectValues {
            partAttributeSelectVariantId
            textValue
            codeValue
            isDeleted
          }
        }
        partCategory {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        partImages {
          partImageId
          sortOrder
          isDefault
          document {
            documentId
            documentName
            documentIdentifier
            documentTypeId
            storagePath
            extension
            encodedUrn
            documentStatusTypeId
            isMappedFromShop
            folderDocumentResponse {
              folderDocumentId
              folderId
              folderName
              documentId
            }
            documentNameFormatted
            createdOn
            createdBy
          }
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
        }
        documentIds
        unitOfMeasure {
          unitOfMeasureId
          unitOfMeasureTypeId
          companyId
          unitOfMeasureName
          unitOfMeasureCode
          createdOn
          createdBy
          updatedOn
          modifiedBy
          modifiedOn
        }
      }
      shopTaskId
      assemblyNodeId
      historyTypeId
      assembly {
        part {
          partId
          partName
          description
          stateId
          isDefault
          manufacturerCatalogNumber
          unitOfMeasureCode
          unitOfMeasureName
          upcCode
          url
          catalogUrl
          partUrl
          internalPartNumber
          createdOn
          hasAssembly
          manufacturer {
            manufacturerId
            companyId
            manufacturerName
            homePageUrl
          }
          attriubuteVariantData {
            partAttributeVariantDataId
            partAttributeId
            partAttributeName
            rangeValue
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            partAttribute {
              partAttributeId
              partAttributeName
              allowWriteInVariant
              rangeMinimumValue
              rangeMaximumValue
              selectVariants {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
              partAttributeType {
                partAttributeTypeId
                partAttributeTypeName
                partAttributeTypeDescription
                sortOrder
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
            }
            selectValues {
              partAttributeSelectVariantId
              textValue
              codeValue
              isDeleted
            }
          }
          partCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          partImages {
            partImageId
            sortOrder
            isDefault
            document {
              documentId
              documentName
              documentIdentifier
              documentTypeId
              storagePath
              extension
              encodedUrn
              documentStatusTypeId
              isMappedFromShop
              folderDocumentResponse {
                folderDocumentId
                folderId
                folderName
                documentId
              }
              documentNameFormatted
              createdOn
              createdBy
            }
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
          }
          documentIds
          unitOfMeasure {
            unitOfMeasureId
            unitOfMeasureTypeId
            companyId
            unitOfMeasureName
            unitOfMeasureCode
            createdOn
            createdBy
            updatedOn
            modifiedBy
            modifiedOn
          }
        }
        assemblyId
        historyTypeId
        assemblyNodes {
          quantity
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
          shopTaskId
          assemblyNodeId
          historyTypeId
          assembly {
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            assemblyId
            historyTypeId
            assemblyNodes {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
            assemblyEdges {
              assemblyEdgeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyEdgeType {
                assemblyEdgeTypeId
                assemblyEdgeTypeName
              }
              beginHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              endHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              beginNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              endNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
            }
          }
          assemblyNodeType {
            assemblyNodeTypeId
            assemblyNodeTypeName
          }
          assemblyNodeName
          assemblyNodeDescription
          positionX
          positionY
        }
        assemblyEdges {
          assemblyEdgeId
          historyTypeId
          assembly {
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            assemblyId
            historyTypeId
            assemblyNodes {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
            assemblyEdges {
              assemblyEdgeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyEdgeType {
                assemblyEdgeTypeId
                assemblyEdgeTypeName
              }
              beginHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              endHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              beginNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              endNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
            }
          }
          assemblyEdgeType {
            assemblyEdgeTypeId
            assemblyEdgeTypeName
          }
          beginHandlePosition {
            assemblyHandlePositionId
            assemblyHandlePositionName
          }
          endHandlePosition {
            assemblyHandlePositionId
            assemblyHandlePositionName
          }
          beginNode {
            quantity
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            shopTaskId
            assemblyNodeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyNodeType {
              assemblyNodeTypeId
              assemblyNodeTypeName
            }
            assemblyNodeName
            assemblyNodeDescription
            positionX
            positionY
          }
          endNode {
            quantity
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            shopTaskId
            assemblyNodeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyNodeType {
              assemblyNodeTypeId
              assemblyNodeTypeName
            }
            assemblyNodeName
            assemblyNodeDescription
            positionX
            positionY
          }
        }
      }
      assemblyNodeType {
        assemblyNodeTypeId
        assemblyNodeTypeName
      }
      assemblyNodeName
      assemblyNodeDescription
      positionX
      positionY
    }
  }
`;
export const deleteAssemblyNode = /* GraphQL */ `
  mutation DeleteAssemblyNode($params: MutationDeleteAssemblyNodeParamsInput!) {
    deleteAssemblyNode(params: $params)
  }
`;
export const addAssemblyEdge = /* GraphQL */ `
  mutation AddAssemblyEdge(
    $query: MutationAddAssemblyEdgeQueryInput
    $body: MutationAddAssemblyEdgeBodyInput
  ) {
    addAssemblyEdge(query: $query, body: $body) {
      assemblyEdgeId
      historyTypeId
      assembly {
        part {
          partId
          partName
          description
          stateId
          isDefault
          manufacturerCatalogNumber
          unitOfMeasureCode
          unitOfMeasureName
          upcCode
          url
          catalogUrl
          partUrl
          internalPartNumber
          createdOn
          hasAssembly
          manufacturer {
            manufacturerId
            companyId
            manufacturerName
            homePageUrl
          }
          attriubuteVariantData {
            partAttributeVariantDataId
            partAttributeId
            partAttributeName
            rangeValue
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            partAttribute {
              partAttributeId
              partAttributeName
              allowWriteInVariant
              rangeMinimumValue
              rangeMaximumValue
              selectVariants {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
              partAttributeType {
                partAttributeTypeId
                partAttributeTypeName
                partAttributeTypeDescription
                sortOrder
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
            }
            selectValues {
              partAttributeSelectVariantId
              textValue
              codeValue
              isDeleted
            }
          }
          partCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          partImages {
            partImageId
            sortOrder
            isDefault
            document {
              documentId
              documentName
              documentIdentifier
              documentTypeId
              storagePath
              extension
              encodedUrn
              documentStatusTypeId
              isMappedFromShop
              folderDocumentResponse {
                folderDocumentId
                folderId
                folderName
                documentId
              }
              documentNameFormatted
              createdOn
              createdBy
            }
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
          }
          documentIds
          unitOfMeasure {
            unitOfMeasureId
            unitOfMeasureTypeId
            companyId
            unitOfMeasureName
            unitOfMeasureCode
            createdOn
            createdBy
            updatedOn
            modifiedBy
            modifiedOn
          }
        }
        assemblyId
        historyTypeId
        assemblyNodes {
          quantity
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
          shopTaskId
          assemblyNodeId
          historyTypeId
          assembly {
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            assemblyId
            historyTypeId
            assemblyNodes {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
            assemblyEdges {
              assemblyEdgeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyEdgeType {
                assemblyEdgeTypeId
                assemblyEdgeTypeName
              }
              beginHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              endHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              beginNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              endNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
            }
          }
          assemblyNodeType {
            assemblyNodeTypeId
            assemblyNodeTypeName
          }
          assemblyNodeName
          assemblyNodeDescription
          positionX
          positionY
        }
        assemblyEdges {
          assemblyEdgeId
          historyTypeId
          assembly {
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            assemblyId
            historyTypeId
            assemblyNodes {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
            assemblyEdges {
              assemblyEdgeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyEdgeType {
                assemblyEdgeTypeId
                assemblyEdgeTypeName
              }
              beginHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              endHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              beginNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              endNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
            }
          }
          assemblyEdgeType {
            assemblyEdgeTypeId
            assemblyEdgeTypeName
          }
          beginHandlePosition {
            assemblyHandlePositionId
            assemblyHandlePositionName
          }
          endHandlePosition {
            assemblyHandlePositionId
            assemblyHandlePositionName
          }
          beginNode {
            quantity
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            shopTaskId
            assemblyNodeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyNodeType {
              assemblyNodeTypeId
              assemblyNodeTypeName
            }
            assemblyNodeName
            assemblyNodeDescription
            positionX
            positionY
          }
          endNode {
            quantity
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            shopTaskId
            assemblyNodeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyNodeType {
              assemblyNodeTypeId
              assemblyNodeTypeName
            }
            assemblyNodeName
            assemblyNodeDescription
            positionX
            positionY
          }
        }
      }
      assemblyEdgeType {
        assemblyEdgeTypeId
        assemblyEdgeTypeName
      }
      beginHandlePosition {
        assemblyHandlePositionId
        assemblyHandlePositionName
      }
      endHandlePosition {
        assemblyHandlePositionId
        assemblyHandlePositionName
      }
      beginNode {
        quantity
        part {
          partId
          partName
          description
          stateId
          isDefault
          manufacturerCatalogNumber
          unitOfMeasureCode
          unitOfMeasureName
          upcCode
          url
          catalogUrl
          partUrl
          internalPartNumber
          createdOn
          hasAssembly
          manufacturer {
            manufacturerId
            companyId
            manufacturerName
            homePageUrl
          }
          attriubuteVariantData {
            partAttributeVariantDataId
            partAttributeId
            partAttributeName
            rangeValue
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            partAttribute {
              partAttributeId
              partAttributeName
              allowWriteInVariant
              rangeMinimumValue
              rangeMaximumValue
              selectVariants {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
              partAttributeType {
                partAttributeTypeId
                partAttributeTypeName
                partAttributeTypeDescription
                sortOrder
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
            }
            selectValues {
              partAttributeSelectVariantId
              textValue
              codeValue
              isDeleted
            }
          }
          partCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          partImages {
            partImageId
            sortOrder
            isDefault
            document {
              documentId
              documentName
              documentIdentifier
              documentTypeId
              storagePath
              extension
              encodedUrn
              documentStatusTypeId
              isMappedFromShop
              folderDocumentResponse {
                folderDocumentId
                folderId
                folderName
                documentId
              }
              documentNameFormatted
              createdOn
              createdBy
            }
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
          }
          documentIds
          unitOfMeasure {
            unitOfMeasureId
            unitOfMeasureTypeId
            companyId
            unitOfMeasureName
            unitOfMeasureCode
            createdOn
            createdBy
            updatedOn
            modifiedBy
            modifiedOn
          }
        }
        shopTaskId
        assemblyNodeId
        historyTypeId
        assembly {
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
          assemblyId
          historyTypeId
          assemblyNodes {
            quantity
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            shopTaskId
            assemblyNodeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyNodeType {
              assemblyNodeTypeId
              assemblyNodeTypeName
            }
            assemblyNodeName
            assemblyNodeDescription
            positionX
            positionY
          }
          assemblyEdges {
            assemblyEdgeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyEdgeType {
              assemblyEdgeTypeId
              assemblyEdgeTypeName
            }
            beginHandlePosition {
              assemblyHandlePositionId
              assemblyHandlePositionName
            }
            endHandlePosition {
              assemblyHandlePositionId
              assemblyHandlePositionName
            }
            beginNode {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
            endNode {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
          }
        }
        assemblyNodeType {
          assemblyNodeTypeId
          assemblyNodeTypeName
        }
        assemblyNodeName
        assemblyNodeDescription
        positionX
        positionY
      }
      endNode {
        quantity
        part {
          partId
          partName
          description
          stateId
          isDefault
          manufacturerCatalogNumber
          unitOfMeasureCode
          unitOfMeasureName
          upcCode
          url
          catalogUrl
          partUrl
          internalPartNumber
          createdOn
          hasAssembly
          manufacturer {
            manufacturerId
            companyId
            manufacturerName
            homePageUrl
          }
          attriubuteVariantData {
            partAttributeVariantDataId
            partAttributeId
            partAttributeName
            rangeValue
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            partAttribute {
              partAttributeId
              partAttributeName
              allowWriteInVariant
              rangeMinimumValue
              rangeMaximumValue
              selectVariants {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
              partAttributeType {
                partAttributeTypeId
                partAttributeTypeName
                partAttributeTypeDescription
                sortOrder
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
            }
            selectValues {
              partAttributeSelectVariantId
              textValue
              codeValue
              isDeleted
            }
          }
          partCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          partImages {
            partImageId
            sortOrder
            isDefault
            document {
              documentId
              documentName
              documentIdentifier
              documentTypeId
              storagePath
              extension
              encodedUrn
              documentStatusTypeId
              isMappedFromShop
              folderDocumentResponse {
                folderDocumentId
                folderId
                folderName
                documentId
              }
              documentNameFormatted
              createdOn
              createdBy
            }
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
          }
          documentIds
          unitOfMeasure {
            unitOfMeasureId
            unitOfMeasureTypeId
            companyId
            unitOfMeasureName
            unitOfMeasureCode
            createdOn
            createdBy
            updatedOn
            modifiedBy
            modifiedOn
          }
        }
        shopTaskId
        assemblyNodeId
        historyTypeId
        assembly {
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
          assemblyId
          historyTypeId
          assemblyNodes {
            quantity
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            shopTaskId
            assemblyNodeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyNodeType {
              assemblyNodeTypeId
              assemblyNodeTypeName
            }
            assemblyNodeName
            assemblyNodeDescription
            positionX
            positionY
          }
          assemblyEdges {
            assemblyEdgeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyEdgeType {
              assemblyEdgeTypeId
              assemblyEdgeTypeName
            }
            beginHandlePosition {
              assemblyHandlePositionId
              assemblyHandlePositionName
            }
            endHandlePosition {
              assemblyHandlePositionId
              assemblyHandlePositionName
            }
            beginNode {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
            endNode {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
          }
        }
        assemblyNodeType {
          assemblyNodeTypeId
          assemblyNodeTypeName
        }
        assemblyNodeName
        assemblyNodeDescription
        positionX
        positionY
      }
    }
  }
`;
export const updateAssemblyEdge = /* GraphQL */ `
  mutation UpdateAssemblyEdge(
    $params: MutationUpdateAssemblyEdgeParamsInput!
    $query: MutationUpdateAssemblyEdgeQueryInput
    $body: MutationUpdateAssemblyEdgeBodyInput
  ) {
    updateAssemblyEdge(params: $params, query: $query, body: $body) {
      assemblyEdgeId
      historyTypeId
      assembly {
        part {
          partId
          partName
          description
          stateId
          isDefault
          manufacturerCatalogNumber
          unitOfMeasureCode
          unitOfMeasureName
          upcCode
          url
          catalogUrl
          partUrl
          internalPartNumber
          createdOn
          hasAssembly
          manufacturer {
            manufacturerId
            companyId
            manufacturerName
            homePageUrl
          }
          attriubuteVariantData {
            partAttributeVariantDataId
            partAttributeId
            partAttributeName
            rangeValue
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            partAttribute {
              partAttributeId
              partAttributeName
              allowWriteInVariant
              rangeMinimumValue
              rangeMaximumValue
              selectVariants {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
              partAttributeType {
                partAttributeTypeId
                partAttributeTypeName
                partAttributeTypeDescription
                sortOrder
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
            }
            selectValues {
              partAttributeSelectVariantId
              textValue
              codeValue
              isDeleted
            }
          }
          partCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          partImages {
            partImageId
            sortOrder
            isDefault
            document {
              documentId
              documentName
              documentIdentifier
              documentTypeId
              storagePath
              extension
              encodedUrn
              documentStatusTypeId
              isMappedFromShop
              folderDocumentResponse {
                folderDocumentId
                folderId
                folderName
                documentId
              }
              documentNameFormatted
              createdOn
              createdBy
            }
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
          }
          documentIds
          unitOfMeasure {
            unitOfMeasureId
            unitOfMeasureTypeId
            companyId
            unitOfMeasureName
            unitOfMeasureCode
            createdOn
            createdBy
            updatedOn
            modifiedBy
            modifiedOn
          }
        }
        assemblyId
        historyTypeId
        assemblyNodes {
          quantity
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
          shopTaskId
          assemblyNodeId
          historyTypeId
          assembly {
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            assemblyId
            historyTypeId
            assemblyNodes {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
            assemblyEdges {
              assemblyEdgeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyEdgeType {
                assemblyEdgeTypeId
                assemblyEdgeTypeName
              }
              beginHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              endHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              beginNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              endNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
            }
          }
          assemblyNodeType {
            assemblyNodeTypeId
            assemblyNodeTypeName
          }
          assemblyNodeName
          assemblyNodeDescription
          positionX
          positionY
        }
        assemblyEdges {
          assemblyEdgeId
          historyTypeId
          assembly {
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            assemblyId
            historyTypeId
            assemblyNodes {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
            assemblyEdges {
              assemblyEdgeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyEdgeType {
                assemblyEdgeTypeId
                assemblyEdgeTypeName
              }
              beginHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              endHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              beginNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              endNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
            }
          }
          assemblyEdgeType {
            assemblyEdgeTypeId
            assemblyEdgeTypeName
          }
          beginHandlePosition {
            assemblyHandlePositionId
            assemblyHandlePositionName
          }
          endHandlePosition {
            assemblyHandlePositionId
            assemblyHandlePositionName
          }
          beginNode {
            quantity
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            shopTaskId
            assemblyNodeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyNodeType {
              assemblyNodeTypeId
              assemblyNodeTypeName
            }
            assemblyNodeName
            assemblyNodeDescription
            positionX
            positionY
          }
          endNode {
            quantity
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            shopTaskId
            assemblyNodeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyNodeType {
              assemblyNodeTypeId
              assemblyNodeTypeName
            }
            assemblyNodeName
            assemblyNodeDescription
            positionX
            positionY
          }
        }
      }
      assemblyEdgeType {
        assemblyEdgeTypeId
        assemblyEdgeTypeName
      }
      beginHandlePosition {
        assemblyHandlePositionId
        assemblyHandlePositionName
      }
      endHandlePosition {
        assemblyHandlePositionId
        assemblyHandlePositionName
      }
      beginNode {
        quantity
        part {
          partId
          partName
          description
          stateId
          isDefault
          manufacturerCatalogNumber
          unitOfMeasureCode
          unitOfMeasureName
          upcCode
          url
          catalogUrl
          partUrl
          internalPartNumber
          createdOn
          hasAssembly
          manufacturer {
            manufacturerId
            companyId
            manufacturerName
            homePageUrl
          }
          attriubuteVariantData {
            partAttributeVariantDataId
            partAttributeId
            partAttributeName
            rangeValue
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            partAttribute {
              partAttributeId
              partAttributeName
              allowWriteInVariant
              rangeMinimumValue
              rangeMaximumValue
              selectVariants {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
              partAttributeType {
                partAttributeTypeId
                partAttributeTypeName
                partAttributeTypeDescription
                sortOrder
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
            }
            selectValues {
              partAttributeSelectVariantId
              textValue
              codeValue
              isDeleted
            }
          }
          partCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          partImages {
            partImageId
            sortOrder
            isDefault
            document {
              documentId
              documentName
              documentIdentifier
              documentTypeId
              storagePath
              extension
              encodedUrn
              documentStatusTypeId
              isMappedFromShop
              folderDocumentResponse {
                folderDocumentId
                folderId
                folderName
                documentId
              }
              documentNameFormatted
              createdOn
              createdBy
            }
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
          }
          documentIds
          unitOfMeasure {
            unitOfMeasureId
            unitOfMeasureTypeId
            companyId
            unitOfMeasureName
            unitOfMeasureCode
            createdOn
            createdBy
            updatedOn
            modifiedBy
            modifiedOn
          }
        }
        shopTaskId
        assemblyNodeId
        historyTypeId
        assembly {
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
          assemblyId
          historyTypeId
          assemblyNodes {
            quantity
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            shopTaskId
            assemblyNodeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyNodeType {
              assemblyNodeTypeId
              assemblyNodeTypeName
            }
            assemblyNodeName
            assemblyNodeDescription
            positionX
            positionY
          }
          assemblyEdges {
            assemblyEdgeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyEdgeType {
              assemblyEdgeTypeId
              assemblyEdgeTypeName
            }
            beginHandlePosition {
              assemblyHandlePositionId
              assemblyHandlePositionName
            }
            endHandlePosition {
              assemblyHandlePositionId
              assemblyHandlePositionName
            }
            beginNode {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
            endNode {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
          }
        }
        assemblyNodeType {
          assemblyNodeTypeId
          assemblyNodeTypeName
        }
        assemblyNodeName
        assemblyNodeDescription
        positionX
        positionY
      }
      endNode {
        quantity
        part {
          partId
          partName
          description
          stateId
          isDefault
          manufacturerCatalogNumber
          unitOfMeasureCode
          unitOfMeasureName
          upcCode
          url
          catalogUrl
          partUrl
          internalPartNumber
          createdOn
          hasAssembly
          manufacturer {
            manufacturerId
            companyId
            manufacturerName
            homePageUrl
          }
          attriubuteVariantData {
            partAttributeVariantDataId
            partAttributeId
            partAttributeName
            rangeValue
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            partAttribute {
              partAttributeId
              partAttributeName
              allowWriteInVariant
              rangeMinimumValue
              rangeMaximumValue
              selectVariants {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
              partAttributeType {
                partAttributeTypeId
                partAttributeTypeName
                partAttributeTypeDescription
                sortOrder
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
            }
            selectValues {
              partAttributeSelectVariantId
              textValue
              codeValue
              isDeleted
            }
          }
          partCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          partImages {
            partImageId
            sortOrder
            isDefault
            document {
              documentId
              documentName
              documentIdentifier
              documentTypeId
              storagePath
              extension
              encodedUrn
              documentStatusTypeId
              isMappedFromShop
              folderDocumentResponse {
                folderDocumentId
                folderId
                folderName
                documentId
              }
              documentNameFormatted
              createdOn
              createdBy
            }
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
          }
          documentIds
          unitOfMeasure {
            unitOfMeasureId
            unitOfMeasureTypeId
            companyId
            unitOfMeasureName
            unitOfMeasureCode
            createdOn
            createdBy
            updatedOn
            modifiedBy
            modifiedOn
          }
        }
        shopTaskId
        assemblyNodeId
        historyTypeId
        assembly {
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
          assemblyId
          historyTypeId
          assemblyNodes {
            quantity
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            shopTaskId
            assemblyNodeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyNodeType {
              assemblyNodeTypeId
              assemblyNodeTypeName
            }
            assemblyNodeName
            assemblyNodeDescription
            positionX
            positionY
          }
          assemblyEdges {
            assemblyEdgeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyEdgeType {
              assemblyEdgeTypeId
              assemblyEdgeTypeName
            }
            beginHandlePosition {
              assemblyHandlePositionId
              assemblyHandlePositionName
            }
            endHandlePosition {
              assemblyHandlePositionId
              assemblyHandlePositionName
            }
            beginNode {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
            endNode {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
          }
        }
        assemblyNodeType {
          assemblyNodeTypeId
          assemblyNodeTypeName
        }
        assemblyNodeName
        assemblyNodeDescription
        positionX
        positionY
      }
    }
  }
`;
export const deleteAssemblyEdge = /* GraphQL */ `
  mutation DeleteAssemblyEdge($params: MutationDeleteAssemblyEdgeParamsInput!) {
    deleteAssemblyEdge(params: $params)
  }
`;
export const addLocation = /* GraphQL */ `
  mutation AddLocation(
    $query: MutationAddLocationQueryInput
    $body: MutationAddLocationBodyInput
  ) {
    addLocation(query: $query, body: $body) {
      locationId
      locationTypeId
      locationTypeName
      projectId
      locationIdentifier
      locationCompositeIdentifier
      sortOrder
      hasChildren
      parentLocationId
      locationName
      locationCompositeName
      locationPhases {
        locationPhaseId
        locationId
        workPhaseId
        workPhaseName
        scopePackageId
        scopePackageName
        sortOrder
      }
      childrenTypeName
      movedLocationPhases {
        workPhaseId
        locationPhaseId
      }
    }
  }
`;
export const addScopePackage = /* GraphQL */ `
  mutation AddScopePackage(
    $query: MutationAddScopePackageQueryInput
    $body: MutationAddScopePackageBodyInput
  ) {
    addScopePackage(query: $query, body: $body) {
      projectId
      scopePackageId
      scopePackageName
      scopePackageIdentifier
      sortOrder
      hasWorkPhases
    }
  }
`;
export const addWorkPhase = /* GraphQL */ `
  mutation AddWorkPhase(
    $query: MutationAddWorkPhaseQueryInput
    $body: MutationAddWorkPhaseBodyInput
  ) {
    addWorkPhase(query: $query, body: $body) {
      workPhaseId
      workPhaseIdentifier
      workPhaseName
      sortOrder
      scopePackageId
      scopePackageName
      projectId
      hasLocationPhases
      updatedWorkPhaseLocationPhases {
        locationId
        locationPhaseId
      }
    }
  }
`;
export const addPartAttribute = /* GraphQL */ `
  mutation AddPartAttribute(
    $query: MutationAddPartAttributeQueryInput
    $body: MutationAddPartAttributeBodyInput
  ) {
    addPartAttribute(query: $query, body: $body) {
      partAttributeId
      partAttributeName
      allowWriteInVariant
      rangeMinimumValue
      rangeMaximumValue
      selectVariants {
        partAttributeSelectVariantId
        textValue
        codeValue
        isDeleted
      }
      partAttributeType {
        partAttributeTypeId
        partAttributeTypeName
        partAttributeTypeDescription
        sortOrder
      }
      partCategory {
        partCategoryId
        partCategoryName
        partCatalog {
          partCatalogId
          partCatalogName
          companyId
          topLevelNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        parentPartCategory {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        subNodes {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
      }
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $params: MutationUpdateMessageParamsInput!
    $query: MutationUpdateMessageQueryInput
    $body: MutationUpdateMessageBodyInput
  ) {
    updateMessage(params: $params, query: $query, body: $body) {
      messageId
      companyId
      messagePriorityId
      fromName
      subject
      body
      messagePriorityCode
      statusId
      statusName
      accessedOn
      createdOn
    }
  }
`;
export const updatePartAttribute = /* GraphQL */ `
  mutation UpdatePartAttribute(
    $query: MutationUpdatePartAttributeQueryInput
    $body: MutationUpdatePartAttributeBodyInput
  ) {
    updatePartAttribute(query: $query, body: $body) {
      partAttributeId
      partAttributeName
      allowWriteInVariant
      rangeMinimumValue
      rangeMaximumValue
      selectVariants {
        partAttributeSelectVariantId
        textValue
        codeValue
        isDeleted
      }
      partAttributeType {
        partAttributeTypeId
        partAttributeTypeName
        partAttributeTypeDescription
        sortOrder
      }
      partCategory {
        partCategoryId
        partCategoryName
        partCatalog {
          partCatalogId
          partCatalogName
          companyId
          topLevelNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        parentPartCategory {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        subNodes {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
      }
    }
  }
`;
export const deletePartAttribute = /* GraphQL */ `
  mutation DeletePartAttribute(
    $params: MutationDeletePartAttributeParamsInput!
  ) {
    deletePartAttribute(params: $params)
  }
`;
export const addLocationWizard = /* GraphQL */ `
  mutation AddLocationWizard(
    $query: MutationAddLocationWizardQueryInput
    $body: MutationAddLocationWizardBodyInput
  ) {
    addLocationWizard(query: $query, body: $body) {
      locationId
      locationTypeId
      locationTypeName
      projectId
      locationIdentifier
      locationCompositeIdentifier
      sortOrder
      hasChildren
      parentLocationId
      locationName
      locationCompositeName
      locationPhases {
        locationPhaseId
        locationId
        workPhaseId
        workPhaseName
        scopePackageId
        scopePackageName
        sortOrder
      }
      childrenTypeName
      movedLocationPhases {
        workPhaseId
        locationPhaseId
      }
    }
  }
`;
export const addWorkRequest = /* GraphQL */ `
  mutation AddWorkRequest(
    $query: MutationAddWorkRequestQueryInput
    $body: MutationAddWorkRequestBodyInput
  ) {
    addWorkRequest(query: $query, body: $body) {
      workRequestId
      workRequestStatusId
      workRequestStatusName
      facilityId
      projectId
      projectIdentifier
      projectName
      workRequestIdentifier
      workRequestName
      needBy
      workRequestDescription
      facilityName
      workRequestStatusDescription
      lastStatusUpdate
      numberOfAttachments
    }
  }
`;
export const addWorkRequestItem = /* GraphQL */ `
  mutation AddWorkRequestItem(
    $query: MutationAddWorkRequestItemQueryInput
    $body: MutationAddWorkRequestItemBodyInput
  ) {
    addWorkRequestItem(query: $query, body: $body) {
      workRequestItemId
      workRequestId
      workRequestItemTypeId
      unitOfMeasureId
      billOfProcessId
      workRequestItemName
      workRequestItemStatusTypeId
      quantity
      unityQuantity
      assignedQuantity
      remainingQuantity
      hasShopTask
      createdOn
      createdBy
      lineItemId
    }
  }
`;
export const addWorkRequestItemFromCatalog = /* GraphQL */ `
  mutation AddWorkRequestItemFromCatalog(
    $query: MutationAddWorkRequestItemFromCatalogQueryInput
    $body: MutationAddWorkRequestItemFromCatalogBodyInput
  ) {
    addWorkRequestItemFromCatalog(query: $query, body: $body) {
      workRequestItemId
      workRequestId
      workRequestItemTypeId
      unitOfMeasureId
      billOfProcessId
      workRequestItemName
      workRequestItemStatusTypeId
      quantity
      unityQuantity
      assignedQuantity
      remainingQuantity
      hasShopTask
      createdOn
      createdBy
      lineItemId
    }
  }
`;
export const addWorkRequestItemFromLineItem = /* GraphQL */ `
  mutation AddWorkRequestItemFromLineItem(
    $query: MutationAddWorkRequestItemFromLineItemQueryInput
    $body: MutationAddWorkRequestItemFromLineItemBodyInput
  ) {
    addWorkRequestItemFromLineItem(query: $query, body: $body) {
      workRequestItemId
      workRequestId
      workRequestItemTypeId
      unitOfMeasureId
      billOfProcessId
      workRequestItemName
      workRequestItemStatusTypeId
      quantity
      unityQuantity
      assignedQuantity
      remainingQuantity
      hasShopTask
      createdOn
      createdBy
      lineItemId
    }
  }
`;
export const addMessage = /* GraphQL */ `
  mutation AddMessage(
    $query: MutationAddMessageQueryInput
    $body: MutationAddMessageBodyInput
  ) {
    addMessage(query: $query, body: $body) {
      messageId
      companyId
      messagePriorityId
      fromName
      subject
      body
      messagePriorityCode
      statusId
      statusName
      accessedOn
      createdOn
    }
  }
`;
export const addEntitlement = /* GraphQL */ `
  mutation AddEntitlement(
    $query: MutationAddEntitlementQueryInput
    $body: MutationAddEntitlementBodyInput
  ) {
    addEntitlement(query: $query, body: $body) {
      entitlementId
      userId
      isCheckedOut
      checkedOutEndDate
      secureData
      secureSignature
      licenseTypeCode
      productPool {
        productPoolId
        poolQuantity
        productId
        bundleId
        type
        label
        poolAvailableQuantity
        renewalDate
        customData
        termDate
        startDate
        trialRenewalDate
        company {
          companyId
          companyName
          renewalDate
          mfA_Required
          data_Analytics
          companyDownloadPaths {
            document
            updateBy
            updatedOn
          }
          companyImageId
          address {
            addressId
            addressLine1
            addressLine2
            city
            state
            country
            zip
          }
          productPool {
            productPoolId
            poolQuantity
            productId
            bundleId
            type
            label
            poolAvailableQuantity
            renewalDate
            customData
            termDate
            startDate
            trialRenewalDate
            company {
              companyId
              companyName
              renewalDate
              mfA_Required
              data_Analytics
              companyDownloadPaths {
                document
                updateBy
                updatedOn
              }
              companyImageId
              address {
                addressId
                addressLine1
                addressLine2
                city
                state
                country
                zip
              }
              productPool {
                productPoolId
                poolQuantity
                productId
                bundleId
                type
                label
                poolAvailableQuantity
                renewalDate
                customData
                termDate
                startDate
                trialRenewalDate
              }
            }
            product {
              productId
              productName
              baseProductName
              isForesiteModule
              licenseTypeId
              licenseTypeName
              licenseClassId
              licenseClassName
              productNameFormatted
              onlyVisibleToMembers
              supportsTrial
              productLicenseTypes {
                productLicenseTypeId
                productId
                licenseTypeId
              }
            }
          }
        }
        product {
          productId
          productName
          baseProductName
          isForesiteModule
          licenseTypeId
          licenseTypeName
          licenseClassId
          licenseClassName
          productNameFormatted
          onlyVisibleToMembers
          supportsTrial
          productLicenseTypes {
            productLicenseTypeId
            productId
            licenseTypeId
            licenseType {
              licenseTypeId
              licenseTypeName
              licenseTypeCode
              sortOrder
            }
          }
        }
      }
    }
  }
`;
export const addBundle = /* GraphQL */ `
  mutation AddBundle(
    $query: MutationAddBundleQueryInput
    $body: MutationAddBundleBodyInput
  ) {
    addBundle(query: $query, body: $body) {
      bundleId
      bundleName
      bundleProducts {
        bundleProductId
        productId
        product {
          productId
          productName
          baseProductName
          isForesiteModule
          licenseTypeId
          licenseTypeName
          licenseClassId
          licenseClassName
          productNameFormatted
          onlyVisibleToMembers
          supportsTrial
          productLicenseTypes {
            productLicenseTypeId
            productId
            licenseTypeId
            licenseType {
              licenseTypeId
              licenseTypeName
              licenseTypeCode
              sortOrder
            }
          }
        }
      }
    }
  }
`;
export const addCompany = /* GraphQL */ `
  mutation AddCompany(
    $query: MutationAddCompanyQueryInput
    $body: MutationAddCompanyBodyInput
  ) {
    addCompany(query: $query, body: $body) {
      companyId
      companyName
      renewalDate
      mfA_Required
      data_Analytics
      companyDownloadPaths {
        document
        updateBy
        updatedOn
      }
      companyImageId
      address {
        addressId
        addressLine1
        addressLine2
        city
        state
        country
        zip
      }
      productPool {
        productPoolId
        poolQuantity
        productId
        bundleId
        type
        label
        poolAvailableQuantity
        renewalDate
        customData
        termDate
        startDate
        trialRenewalDate
        company {
          companyId
          companyName
          renewalDate
          mfA_Required
          data_Analytics
          companyDownloadPaths {
            document
            updateBy
            updatedOn
          }
          companyImageId
          address {
            addressId
            addressLine1
            addressLine2
            city
            state
            country
            zip
          }
          productPool {
            productPoolId
            poolQuantity
            productId
            bundleId
            type
            label
            poolAvailableQuantity
            renewalDate
            customData
            termDate
            startDate
            trialRenewalDate
            company {
              companyId
              companyName
              renewalDate
              mfA_Required
              data_Analytics
              companyDownloadPaths {
                document
                updateBy
                updatedOn
              }
              companyImageId
              address {
                addressId
                addressLine1
                addressLine2
                city
                state
                country
                zip
              }
              productPool {
                productPoolId
                poolQuantity
                productId
                bundleId
                type
                label
                poolAvailableQuantity
                renewalDate
                customData
                termDate
                startDate
                trialRenewalDate
              }
            }
            product {
              productId
              productName
              baseProductName
              isForesiteModule
              licenseTypeId
              licenseTypeName
              licenseClassId
              licenseClassName
              productNameFormatted
              onlyVisibleToMembers
              supportsTrial
              productLicenseTypes {
                productLicenseTypeId
                productId
                licenseTypeId
              }
            }
          }
        }
        product {
          productId
          productName
          baseProductName
          isForesiteModule
          licenseTypeId
          licenseTypeName
          licenseClassId
          licenseClassName
          productNameFormatted
          onlyVisibleToMembers
          supportsTrial
          productLicenseTypes {
            productLicenseTypeId
            productId
            licenseTypeId
            licenseType {
              licenseTypeId
              licenseTypeName
              licenseTypeCode
              sortOrder
            }
          }
        }
      }
    }
  }
`;
export const addUser = /* GraphQL */ `
  mutation AddUser(
    $query: MutationAddUserQueryInput
    $body: MutationAddUserBodyInput
  ) {
    addUser(query: $query, body: $body) {
      userId
      userName
      userFirstName
      userLastName
      userIdentifier
      userEmail
      workPhoneNumber
      personalPhoneNumber
      userStatus
      userStatusId
      inviteStatus
      userTypes {
        userTypeId
        typeName
        typeId
      }
      acceptedLatestEula
      companyId
      isAdmin
      isDeleted
      userPhotoId
    }
  }
`;
export const addProjectUser = /* GraphQL */ `
  mutation AddProjectUser(
    $query: MutationAddProjectUserQueryInput
    $body: MutationAddProjectUserBodyInput
  ) {
    addProjectUser(query: $query, body: $body) {
      projectUserId
      projectId
      inviteStatus
      expiresAt
      isAdmin
      isDeleted
      user {
        userId
        userName
        userFirstName
        userLastName
        userIdentifier
        userEmail
        workPhoneNumber
        personalPhoneNumber
        userStatus
        userStatusId
        inviteStatus
        userTypes {
          userTypeId
          typeName
          typeId
        }
        acceptedLatestEula
        companyId
        isAdmin
        isDeleted
        userPhotoId
      }
    }
  }
`;
export const addProject = /* GraphQL */ `
  mutation AddProject(
    $query: MutationAddProjectQueryInput
    $body: MutationAddProjectBodyInput
  ) {
    addProject(query: $query, body: $body) {
      projectId
      projectName
      projectIdentifier
      dataAnalytics
      units
      companyId
      address {
        addressId
        addressLine1
        addressLine2
        city
        state
        country
        zip
      }
      projectImageId
      disallowAutomaticDistributionQty
      projectType {
        projectTypeId
        projectTypeName
        companyId
      }
    }
  }
`;
export const addProduct = /* GraphQL */ `
  mutation AddProduct(
    $query: MutationAddProductQueryInput
    $body: MutationAddProductBodyInput
  ) {
    addProduct(query: $query, body: $body) {
      productId
      productName
      baseProductName
      isForesiteModule
      licenseTypeId
      licenseTypeName
      licenseClassId
      licenseClassName
      productNameFormatted
      onlyVisibleToMembers
      supportsTrial
      productLicenseTypes {
        productLicenseTypeId
        productId
        licenseTypeId
        licenseType {
          licenseTypeId
          licenseTypeName
          licenseTypeCode
          sortOrder
        }
      }
    }
  }
`;
export const addProductPool = /* GraphQL */ `
  mutation AddProductPool(
    $query: MutationAddProductPoolQueryInput
    $body: MutationAddProductPoolBodyInput
  ) {
    addProductPool(query: $query, body: $body) {
      productPoolId
      poolQuantity
      productId
      bundleId
      type
      label
      poolAvailableQuantity
      renewalDate
      customData
      termDate
      startDate
      trialRenewalDate
      company {
        companyId
        companyName
        renewalDate
        mfA_Required
        data_Analytics
        companyDownloadPaths {
          document
          updateBy
          updatedOn
        }
        companyImageId
        address {
          addressId
          addressLine1
          addressLine2
          city
          state
          country
          zip
        }
        productPool {
          productPoolId
          poolQuantity
          productId
          bundleId
          type
          label
          poolAvailableQuantity
          renewalDate
          customData
          termDate
          startDate
          trialRenewalDate
          company {
            companyId
            companyName
            renewalDate
            mfA_Required
            data_Analytics
            companyDownloadPaths {
              document
              updateBy
              updatedOn
            }
            companyImageId
            address {
              addressId
              addressLine1
              addressLine2
              city
              state
              country
              zip
            }
            productPool {
              productPoolId
              poolQuantity
              productId
              bundleId
              type
              label
              poolAvailableQuantity
              renewalDate
              customData
              termDate
              startDate
              trialRenewalDate
              company {
                companyId
                companyName
                renewalDate
                mfA_Required
                data_Analytics
                companyImageId
              }
              product {
                productId
                productName
                baseProductName
                isForesiteModule
                licenseTypeId
                licenseTypeName
                licenseClassId
                licenseClassName
                productNameFormatted
                onlyVisibleToMembers
                supportsTrial
              }
            }
          }
          product {
            productId
            productName
            baseProductName
            isForesiteModule
            licenseTypeId
            licenseTypeName
            licenseClassId
            licenseClassName
            productNameFormatted
            onlyVisibleToMembers
            supportsTrial
            productLicenseTypes {
              productLicenseTypeId
              productId
              licenseTypeId
              licenseType {
                licenseTypeId
                licenseTypeName
                licenseTypeCode
                sortOrder
              }
            }
          }
        }
      }
      product {
        productId
        productName
        baseProductName
        isForesiteModule
        licenseTypeId
        licenseTypeName
        licenseClassId
        licenseClassName
        productNameFormatted
        onlyVisibleToMembers
        supportsTrial
        productLicenseTypes {
          productLicenseTypeId
          productId
          licenseTypeId
          licenseType {
            licenseTypeId
            licenseTypeName
            licenseTypeCode
            sortOrder
          }
        }
      }
    }
  }
`;
export const addPartCatalog = /* GraphQL */ `
  mutation AddPartCatalog(
    $query: MutationAddPartCatalogQueryInput
    $body: MutationAddPartCatalogBodyInput
  ) {
    addPartCatalog(query: $query, body: $body) {
      partCatalogId
      partCatalogName
      companyId
      topLevelNodes {
        partCategoryId
        partCategoryName
        partCatalog {
          partCatalogId
          partCatalogName
          companyId
          topLevelNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        parentPartCategory {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        subNodes {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
      }
    }
  }
`;
export const addTopLevelPartCategory = /* GraphQL */ `
  mutation AddTopLevelPartCategory(
    $query: MutationAddTopLevelPartCategoryQueryInput
    $body: MutationAddTopLevelPartCategoryBodyInput
  ) {
    addTopLevelPartCategory(query: $query, body: $body) {
      partCategoryId
      partCategoryName
      partCatalog {
        partCatalogId
        partCatalogName
        companyId
        topLevelNodes {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
      }
      parentPartCategory {
        partCategoryId
        partCategoryName
        partCatalog {
          partCatalogId
          partCatalogName
          companyId
          topLevelNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        parentPartCategory {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        subNodes {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
      }
      subNodes {
        partCategoryId
        partCategoryName
        partCatalog {
          partCatalogId
          partCatalogName
          companyId
          topLevelNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        parentPartCategory {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        subNodes {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
      }
    }
  }
`;
export const addSubLevelPartCategory = /* GraphQL */ `
  mutation AddSubLevelPartCategory(
    $query: MutationAddSubLevelPartCategoryQueryInput
    $body: MutationAddSubLevelPartCategoryBodyInput
  ) {
    addSubLevelPartCategory(query: $query, body: $body) {
      partCategoryId
      partCategoryName
      partCatalog {
        partCatalogId
        partCatalogName
        companyId
        topLevelNodes {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
      }
      parentPartCategory {
        partCategoryId
        partCategoryName
        partCatalog {
          partCatalogId
          partCatalogName
          companyId
          topLevelNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        parentPartCategory {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        subNodes {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
      }
      subNodes {
        partCategoryId
        partCategoryName
        partCatalog {
          partCatalogId
          partCatalogName
          companyId
          topLevelNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        parentPartCategory {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        subNodes {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
      }
    }
  }
`;
export const addTasktype = /* GraphQL */ `
  mutation AddTasktype(
    $query: MutationAddTasktypeQueryInput
    $body: MutationAddTasktypeBodyInput
  ) {
    addTasktype(query: $query, body: $body) {
      taskTypeId
      taskTypeImageId
      taskTypeName
      taskTypeDescription
      taskTypeClassId
      companyId
    }
  }
`;
export const updatePartCategory = /* GraphQL */ `
  mutation UpdatePartCategory(
    $params: MutationUpdatePartCategoryParamsInput!
    $query: MutationUpdatePartCategoryQueryInput
    $body: MutationUpdatePartCategoryBodyInput
  ) {
    updatePartCategory(params: $params, query: $query, body: $body) {
      partCategoryId
      partCategoryName
      partCatalog {
        partCatalogId
        partCatalogName
        companyId
        topLevelNodes {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
      }
      parentPartCategory {
        partCategoryId
        partCategoryName
        partCatalog {
          partCatalogId
          partCatalogName
          companyId
          topLevelNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        parentPartCategory {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        subNodes {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
      }
      subNodes {
        partCategoryId
        partCategoryName
        partCatalog {
          partCatalogId
          partCatalogName
          companyId
          topLevelNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        parentPartCategory {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        subNodes {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
      }
    }
  }
`;
export const deletePartCategory = /* GraphQL */ `
  mutation DeletePartCategory($params: MutationDeletePartCategoryParamsInput!) {
    deletePartCategory(params: $params)
  }
`;
export const addDepartmentUser = /* GraphQL */ `
  mutation AddDepartmentUser(
    $query: MutationAddDepartmentUserQueryInput
    $body: MutationAddDepartmentUserBodyInput
  ) {
    addDepartmentUser(query: $query, body: $body) {
      departmentUserId
      departmentId
      expiresAt
      inviteStatus
      isDeleted
      isAdmin
      user {
        userId
        userName
        userFirstName
        userLastName
        userIdentifier
        userEmail
        workPhoneNumber
        personalPhoneNumber
        userStatus
        userStatusId
        inviteStatus
        userTypes {
          userTypeId
          typeName
          typeId
        }
        acceptedLatestEula
        companyId
        isAdmin
        isDeleted
        userPhotoId
      }
    }
  }
`;
export const addFacility = /* GraphQL */ `
  mutation AddFacility(
    $query: MutationAddFacilityQueryInput
    $body: MutationAddFacilityBodyInput
  ) {
    addFacility(query: $query, body: $body) {
      facilityId
      facilityName
      companyId
      address {
        addressId
        addressLine1
        addressLine2
        city
        state
        country
        zip
      }
      departments {
        departmentId
        departmentName
        departmentTypeId
        facility {
          facilityId
          facilityName
        }
        departmentType {
          departmentTypeId
          departmentTypeName
        }
        adminUser {
          userId
          userName
          userFirstName
          userLastName
          userIdentifier
          userEmail
          workPhoneNumber
          personalPhoneNumber
          userStatus
          userStatusId
          inviteStatus
          userTypes {
            userTypeId
            typeName
            typeId
          }
          acceptedLatestEula
          companyId
          isAdmin
          isDeleted
          userPhotoId
        }
      }
    }
  }
`;
export const updateFacility = /* GraphQL */ `
  mutation UpdateFacility(
    $query: MutationUpdateFacilityQueryInput
    $body: MutationUpdateFacilityBodyInput
  ) {
    updateFacility(query: $query, body: $body) {
      facilityId
      facilityName
      companyId
      address {
        addressId
        addressLine1
        addressLine2
        city
        state
        country
        zip
      }
      departments {
        departmentId
        departmentName
        departmentTypeId
        facility {
          facilityId
          facilityName
        }
        departmentType {
          departmentTypeId
          departmentTypeName
        }
        adminUser {
          userId
          userName
          userFirstName
          userLastName
          userIdentifier
          userEmail
          workPhoneNumber
          personalPhoneNumber
          userStatus
          userStatusId
          inviteStatus
          userTypes {
            userTypeId
            typeName
            typeId
          }
          acceptedLatestEula
          companyId
          isAdmin
          isDeleted
          userPhotoId
        }
      }
    }
  }
`;
export const userAcceptLatestEula = /* GraphQL */ `
  mutation UserAcceptLatestEula(
    $params: MutationUserAcceptLatestEulaParamsInput!
  ) {
    userAcceptLatestEula(params: $params) {
      eulaId
      eulaVersion
      eulaTimeStamp
      content
    }
  }
`;
export const addCompanyRenewalDate = /* GraphQL */ `
  mutation AddCompanyRenewalDate(
    $params: MutationAddCompanyRenewalDateParamsInput!
    $query: MutationAddCompanyRenewalDateQueryInput
    $body: MutationAddCompanyRenewalDateBodyInput
  ) {
    addCompanyRenewalDate(params: $params, query: $query, body: $body) {
      companyId
      companyName
      renewalDate
      mfA_Required
      data_Analytics
      companyDownloadPaths {
        document
        updateBy
        updatedOn
      }
      companyImageId
      address {
        addressId
        addressLine1
        addressLine2
        city
        state
        country
        zip
      }
      productPool {
        productPoolId
        poolQuantity
        productId
        bundleId
        type
        label
        poolAvailableQuantity
        renewalDate
        customData
        termDate
        startDate
        trialRenewalDate
        company {
          companyId
          companyName
          renewalDate
          mfA_Required
          data_Analytics
          companyDownloadPaths {
            document
            updateBy
            updatedOn
          }
          companyImageId
          address {
            addressId
            addressLine1
            addressLine2
            city
            state
            country
            zip
          }
          productPool {
            productPoolId
            poolQuantity
            productId
            bundleId
            type
            label
            poolAvailableQuantity
            renewalDate
            customData
            termDate
            startDate
            trialRenewalDate
            company {
              companyId
              companyName
              renewalDate
              mfA_Required
              data_Analytics
              companyDownloadPaths {
                document
                updateBy
                updatedOn
              }
              companyImageId
              address {
                addressId
                addressLine1
                addressLine2
                city
                state
                country
                zip
              }
              productPool {
                productPoolId
                poolQuantity
                productId
                bundleId
                type
                label
                poolAvailableQuantity
                renewalDate
                customData
                termDate
                startDate
                trialRenewalDate
              }
            }
            product {
              productId
              productName
              baseProductName
              isForesiteModule
              licenseTypeId
              licenseTypeName
              licenseClassId
              licenseClassName
              productNameFormatted
              onlyVisibleToMembers
              supportsTrial
              productLicenseTypes {
                productLicenseTypeId
                productId
                licenseTypeId
              }
            }
          }
        }
        product {
          productId
          productName
          baseProductName
          isForesiteModule
          licenseTypeId
          licenseTypeName
          licenseClassId
          licenseClassName
          productNameFormatted
          onlyVisibleToMembers
          supportsTrial
          productLicenseTypes {
            productLicenseTypeId
            productId
            licenseTypeId
            licenseType {
              licenseTypeId
              licenseTypeName
              licenseTypeCode
              sortOrder
            }
          }
        }
      }
    }
  }
`;
export const updateEntitlement = /* GraphQL */ `
  mutation UpdateEntitlement(
    $params: MutationUpdateEntitlementParamsInput!
    $query: MutationUpdateEntitlementQueryInput
    $body: MutationUpdateEntitlementBodyInput
  ) {
    updateEntitlement(params: $params, query: $query, body: $body) {
      entitlementId
      userId
      isCheckedOut
      checkedOutEndDate
      secureData
      secureSignature
      licenseTypeCode
      productPool {
        productPoolId
        poolQuantity
        productId
        bundleId
        type
        label
        poolAvailableQuantity
        renewalDate
        customData
        termDate
        startDate
        trialRenewalDate
        company {
          companyId
          companyName
          renewalDate
          mfA_Required
          data_Analytics
          companyDownloadPaths {
            document
            updateBy
            updatedOn
          }
          companyImageId
          address {
            addressId
            addressLine1
            addressLine2
            city
            state
            country
            zip
          }
          productPool {
            productPoolId
            poolQuantity
            productId
            bundleId
            type
            label
            poolAvailableQuantity
            renewalDate
            customData
            termDate
            startDate
            trialRenewalDate
            company {
              companyId
              companyName
              renewalDate
              mfA_Required
              data_Analytics
              companyDownloadPaths {
                document
                updateBy
                updatedOn
              }
              companyImageId
              address {
                addressId
                addressLine1
                addressLine2
                city
                state
                country
                zip
              }
              productPool {
                productPoolId
                poolQuantity
                productId
                bundleId
                type
                label
                poolAvailableQuantity
                renewalDate
                customData
                termDate
                startDate
                trialRenewalDate
              }
            }
            product {
              productId
              productName
              baseProductName
              isForesiteModule
              licenseTypeId
              licenseTypeName
              licenseClassId
              licenseClassName
              productNameFormatted
              onlyVisibleToMembers
              supportsTrial
              productLicenseTypes {
                productLicenseTypeId
                productId
                licenseTypeId
              }
            }
          }
        }
        product {
          productId
          productName
          baseProductName
          isForesiteModule
          licenseTypeId
          licenseTypeName
          licenseClassId
          licenseClassName
          productNameFormatted
          onlyVisibleToMembers
          supportsTrial
          productLicenseTypes {
            productLicenseTypeId
            productId
            licenseTypeId
            licenseType {
              licenseTypeId
              licenseTypeName
              licenseTypeCode
              sortOrder
            }
          }
        }
      }
    }
  }
`;
export const updateEntitlements = /* GraphQL */ `
  mutation UpdateEntitlements(
    $query: MutationUpdateEntitlementsQueryInput
    $body: MutationUpdateEntitlementsBodyInput
  ) {
    updateEntitlements(query: $query, body: $body) {
      entitlementId
      userId
      isCheckedOut
      checkedOutEndDate
      secureData
      secureSignature
      licenseTypeCode
      productPool {
        productPoolId
        poolQuantity
        productId
        bundleId
        type
        label
        poolAvailableQuantity
        renewalDate
        customData
        termDate
        startDate
        trialRenewalDate
        company {
          companyId
          companyName
          renewalDate
          mfA_Required
          data_Analytics
          companyDownloadPaths {
            document
            updateBy
            updatedOn
          }
          companyImageId
          address {
            addressId
            addressLine1
            addressLine2
            city
            state
            country
            zip
          }
          productPool {
            productPoolId
            poolQuantity
            productId
            bundleId
            type
            label
            poolAvailableQuantity
            renewalDate
            customData
            termDate
            startDate
            trialRenewalDate
            company {
              companyId
              companyName
              renewalDate
              mfA_Required
              data_Analytics
              companyDownloadPaths {
                document
                updateBy
                updatedOn
              }
              companyImageId
              address {
                addressId
                addressLine1
                addressLine2
                city
                state
                country
                zip
              }
              productPool {
                productPoolId
                poolQuantity
                productId
                bundleId
                type
                label
                poolAvailableQuantity
                renewalDate
                customData
                termDate
                startDate
                trialRenewalDate
              }
            }
            product {
              productId
              productName
              baseProductName
              isForesiteModule
              licenseTypeId
              licenseTypeName
              licenseClassId
              licenseClassName
              productNameFormatted
              onlyVisibleToMembers
              supportsTrial
              productLicenseTypes {
                productLicenseTypeId
                productId
                licenseTypeId
              }
            }
          }
        }
        product {
          productId
          productName
          baseProductName
          isForesiteModule
          licenseTypeId
          licenseTypeName
          licenseClassId
          licenseClassName
          productNameFormatted
          onlyVisibleToMembers
          supportsTrial
          productLicenseTypes {
            productLicenseTypeId
            productId
            licenseTypeId
            licenseType {
              licenseTypeId
              licenseTypeName
              licenseTypeCode
              sortOrder
            }
          }
        }
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $params: MutationUpdateUserParamsInput!
    $query: MutationUpdateUserQueryInput
    $body: MutationUpdateUserBodyInput
  ) {
    updateUser(params: $params, query: $query, body: $body) {
      userId
      userName
      userFirstName
      userLastName
      userIdentifier
      userEmail
      workPhoneNumber
      personalPhoneNumber
      userStatus
      userStatusId
      inviteStatus
      userTypes {
        userTypeId
        typeName
        typeId
      }
      acceptedLatestEula
      companyId
      isAdmin
      isDeleted
      userPhotoId
    }
  }
`;
export const updateProductPoolRenewalDate = /* GraphQL */ `
  mutation UpdateProductPoolRenewalDate(
    $params: MutationUpdateProductPoolRenewalDateParamsInput!
    $query: MutationUpdateProductPoolRenewalDateQueryInput
    $body: MutationUpdateProductPoolRenewalDateBodyInput
  ) {
    updateProductPoolRenewalDate(params: $params, query: $query, body: $body) {
      productPoolId
      poolQuantity
      productId
      bundleId
      type
      label
      poolAvailableQuantity
      renewalDate
      customData
      termDate
      startDate
      trialRenewalDate
      company {
        companyId
        companyName
        renewalDate
        mfA_Required
        data_Analytics
        companyDownloadPaths {
          document
          updateBy
          updatedOn
        }
        companyImageId
        address {
          addressId
          addressLine1
          addressLine2
          city
          state
          country
          zip
        }
        productPool {
          productPoolId
          poolQuantity
          productId
          bundleId
          type
          label
          poolAvailableQuantity
          renewalDate
          customData
          termDate
          startDate
          trialRenewalDate
          company {
            companyId
            companyName
            renewalDate
            mfA_Required
            data_Analytics
            companyDownloadPaths {
              document
              updateBy
              updatedOn
            }
            companyImageId
            address {
              addressId
              addressLine1
              addressLine2
              city
              state
              country
              zip
            }
            productPool {
              productPoolId
              poolQuantity
              productId
              bundleId
              type
              label
              poolAvailableQuantity
              renewalDate
              customData
              termDate
              startDate
              trialRenewalDate
              company {
                companyId
                companyName
                renewalDate
                mfA_Required
                data_Analytics
                companyImageId
              }
              product {
                productId
                productName
                baseProductName
                isForesiteModule
                licenseTypeId
                licenseTypeName
                licenseClassId
                licenseClassName
                productNameFormatted
                onlyVisibleToMembers
                supportsTrial
              }
            }
          }
          product {
            productId
            productName
            baseProductName
            isForesiteModule
            licenseTypeId
            licenseTypeName
            licenseClassId
            licenseClassName
            productNameFormatted
            onlyVisibleToMembers
            supportsTrial
            productLicenseTypes {
              productLicenseTypeId
              productId
              licenseTypeId
              licenseType {
                licenseTypeId
                licenseTypeName
                licenseTypeCode
                sortOrder
              }
            }
          }
        }
      }
      product {
        productId
        productName
        baseProductName
        isForesiteModule
        licenseTypeId
        licenseTypeName
        licenseClassId
        licenseClassName
        productNameFormatted
        onlyVisibleToMembers
        supportsTrial
        productLicenseTypes {
          productLicenseTypeId
          productId
          licenseTypeId
          licenseType {
            licenseTypeId
            licenseTypeName
            licenseTypeCode
            sortOrder
          }
        }
      }
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $params: MutationUpdateProjectParamsInput!
    $query: MutationUpdateProjectQueryInput
    $body: MutationUpdateProjectBodyInput
  ) {
    updateProject(params: $params, query: $query, body: $body) {
      projectId
      projectName
      projectIdentifier
      dataAnalytics
      units
      companyId
      address {
        addressId
        addressLine1
        addressLine2
        city
        state
        country
        zip
      }
      projectImageId
      disallowAutomaticDistributionQty
      projectType {
        projectTypeId
        projectTypeName
        companyId
      }
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $params: MutationUpdateCompanyParamsInput!
    $query: MutationUpdateCompanyQueryInput
    $body: MutationUpdateCompanyBodyInput
  ) {
    updateCompany(params: $params, query: $query, body: $body) {
      companyId
      companyName
      renewalDate
      mfA_Required
      data_Analytics
      companyDownloadPaths {
        document
        updateBy
        updatedOn
      }
      companyImageId
      address {
        addressId
        addressLine1
        addressLine2
        city
        state
        country
        zip
      }
      productPool {
        productPoolId
        poolQuantity
        productId
        bundleId
        type
        label
        poolAvailableQuantity
        renewalDate
        customData
        termDate
        startDate
        trialRenewalDate
        company {
          companyId
          companyName
          renewalDate
          mfA_Required
          data_Analytics
          companyDownloadPaths {
            document
            updateBy
            updatedOn
          }
          companyImageId
          address {
            addressId
            addressLine1
            addressLine2
            city
            state
            country
            zip
          }
          productPool {
            productPoolId
            poolQuantity
            productId
            bundleId
            type
            label
            poolAvailableQuantity
            renewalDate
            customData
            termDate
            startDate
            trialRenewalDate
            company {
              companyId
              companyName
              renewalDate
              mfA_Required
              data_Analytics
              companyDownloadPaths {
                document
                updateBy
                updatedOn
              }
              companyImageId
              address {
                addressId
                addressLine1
                addressLine2
                city
                state
                country
                zip
              }
              productPool {
                productPoolId
                poolQuantity
                productId
                bundleId
                type
                label
                poolAvailableQuantity
                renewalDate
                customData
                termDate
                startDate
                trialRenewalDate
              }
            }
            product {
              productId
              productName
              baseProductName
              isForesiteModule
              licenseTypeId
              licenseTypeName
              licenseClassId
              licenseClassName
              productNameFormatted
              onlyVisibleToMembers
              supportsTrial
              productLicenseTypes {
                productLicenseTypeId
                productId
                licenseTypeId
              }
            }
          }
        }
        product {
          productId
          productName
          baseProductName
          isForesiteModule
          licenseTypeId
          licenseTypeName
          licenseClassId
          licenseClassName
          productNameFormatted
          onlyVisibleToMembers
          supportsTrial
          productLicenseTypes {
            productLicenseTypeId
            productId
            licenseTypeId
            licenseType {
              licenseTypeId
              licenseTypeName
              licenseTypeCode
              sortOrder
            }
          }
        }
      }
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation(
    $params: MutationUpdateLocationParamsInput!
    $query: MutationUpdateLocationQueryInput
    $body: MutationUpdateLocationBodyInput
  ) {
    updateLocation(params: $params, query: $query, body: $body) {
      explicitlyUpdatedLocation {
        locationId
        locationTypeId
        locationTypeName
        projectId
        locationIdentifier
        locationCompositeIdentifier
        sortOrder
        hasChildren
        parentLocationId
        locationName
        locationCompositeName
        locationPhases {
          locationPhaseId
          locationId
          workPhaseId
          workPhaseName
          scopePackageId
          scopePackageName
          sortOrder
        }
        childrenTypeName
        movedLocationPhases {
          workPhaseId
          locationPhaseId
        }
      }
      updatedLocationPhases {
        workPhaseId
        locationPhaseId
      }
    }
  }
`;
export const updateWorkRequest = /* GraphQL */ `
  mutation UpdateWorkRequest(
    $params: MutationUpdateWorkRequestParamsInput!
    $query: MutationUpdateWorkRequestQueryInput
    $body: MutationUpdateWorkRequestBodyInput
  ) {
    updateWorkRequest(params: $params, query: $query, body: $body) {
      workRequestId
      workRequestStatusId
      workRequestStatusName
      facilityId
      projectId
      projectIdentifier
      projectName
      workRequestIdentifier
      workRequestName
      needBy
      workRequestDescription
      facilityName
      workRequestStatusDescription
      lastStatusUpdate
      numberOfAttachments
    }
  }
`;
export const addWorkRequestDocument = /* GraphQL */ `
  mutation AddWorkRequestDocument(
    $query: MutationAddWorkRequestDocumentQueryInput
    $body: MutationAddWorkRequestDocumentBodyInput
  ) {
    addWorkRequestDocument(query: $query, body: $body) {
      workRequestDocumentId
      workRequestId
      documentId
      externalUrl
      createdOn
      updatedOn
      createdBy
    }
  }
`;
export const addWorkRequestItemDocument = /* GraphQL */ `
  mutation AddWorkRequestItemDocument(
    $query: MutationAddWorkRequestItemDocumentQueryInput
    $body: MutationAddWorkRequestItemDocumentBodyInput
  ) {
    addWorkRequestItemDocument(query: $query, body: $body)
  }
`;
export const deleteWorkRequestDocument = /* GraphQL */ `
  mutation DeleteWorkRequestDocument(
    $params: MutationDeleteWorkRequestDocumentParamsInput!
  ) {
    deleteWorkRequestDocument(params: $params)
  }
`;
export const addWorkOrderDocument = /* GraphQL */ `
  mutation AddWorkOrderDocument(
    $query: MutationAddWorkOrderDocumentQueryInput
    $body: MutationAddWorkOrderDocumentBodyInput
  ) {
    addWorkOrderDocument(query: $query, body: $body) {
      workOrderDocumentId
      workOrderId
      documentId
      externalUrl
      createdOn
      updatedOn
      createdBy
    }
  }
`;
export const deleteWorkOrderDocument = /* GraphQL */ `
  mutation DeleteWorkOrderDocument(
    $params: MutationDeleteWorkOrderDocumentParamsInput!
  ) {
    deleteWorkOrderDocument(params: $params)
  }
`;
export const updateWorkRequestItem = /* GraphQL */ `
  mutation UpdateWorkRequestItem(
    $params: MutationUpdateWorkRequestItemParamsInput!
    $query: MutationUpdateWorkRequestItemQueryInput
    $body: MutationUpdateWorkRequestItemBodyInput
  ) {
    updateWorkRequestItem(params: $params, query: $query, body: $body) {
      workRequestItemId
      workRequestId
      workRequestItemTypeId
      unitOfMeasureId
      billOfProcessId
      workRequestItemName
      workRequestItemStatusTypeId
      quantity
      unityQuantity
      assignedQuantity
      remainingQuantity
      hasShopTask
      createdOn
      createdBy
      lineItemId
    }
  }
`;
export const updateWorkRequestStatus = /* GraphQL */ `
  mutation UpdateWorkRequestStatus(
    $params: MutationUpdateWorkRequestStatusParamsInput!
    $query: MutationUpdateWorkRequestStatusQueryInput
    $body: MutationUpdateWorkRequestStatusBodyInput
  ) {
    updateWorkRequestStatus(params: $params, query: $query, body: $body) {
      workRequestStatusId
      workRequestStatusTypeId
      workRequestStatusTypeName
      workRequestStatusTypeDescription
    }
  }
`;
export const addTaskTypeToWorkRequest = /* GraphQL */ `
  mutation AddTaskTypeToWorkRequest(
    $params: MutationAddTaskTypeToWorkRequestParamsInput!
    $query: MutationAddTaskTypeToWorkRequestQueryInput
    $body: MutationAddTaskTypeToWorkRequestBodyInput
  ) {
    addTaskTypeToWorkRequest(params: $params, query: $query, body: $body)
  }
`;
export const updateScopePackage = /* GraphQL */ `
  mutation UpdateScopePackage(
    $params: MutationUpdateScopePackageParamsInput!
    $query: MutationUpdateScopePackageQueryInput
    $body: MutationUpdateScopePackageBodyInput
  ) {
    updateScopePackage(params: $params, query: $query, body: $body) {
      projectId
      scopePackageId
      scopePackageName
      scopePackageIdentifier
      sortOrder
      hasWorkPhases
    }
  }
`;
export const updateTaskType = /* GraphQL */ `
  mutation UpdateTaskType(
    $params: MutationUpdateTaskTypeParamsInput!
    $query: MutationUpdateTaskTypeQueryInput
    $body: MutationUpdateTaskTypeBodyInput
  ) {
    updateTaskType(params: $params, query: $query, body: $body) {
      taskTypeId
      taskTypeImageId
      taskTypeName
      taskTypeDescription
      taskTypeClassId
      companyId
    }
  }
`;
export const updateWorkPhase = /* GraphQL */ `
  mutation UpdateWorkPhase(
    $params: MutationUpdateWorkPhaseParamsInput!
    $query: MutationUpdateWorkPhaseQueryInput
    $body: MutationUpdateWorkPhaseBodyInput
  ) {
    updateWorkPhase(params: $params, query: $query, body: $body) {
      workPhaseId
      workPhaseIdentifier
      workPhaseName
      sortOrder
      scopePackageId
      scopePackageName
      projectId
      hasLocationPhases
      updatedWorkPhaseLocationPhases {
        locationId
        locationPhaseId
      }
    }
  }
`;
export const deleteProjectUsers = /* GraphQL */ `
  mutation DeleteProjectUsers(
    $query: MutationDeleteProjectUsersQueryInput
    $body: MutationDeleteProjectUsersBodyInput
  ) {
    deleteProjectUsers(query: $query, body: $body)
  }
`;
export const deleteDepartmentUsers = /* GraphQL */ `
  mutation DeleteDepartmentUsers(
    $query: MutationDeleteDepartmentUsersQueryInput
    $body: MutationDeleteDepartmentUsersBodyInput
  ) {
    deleteDepartmentUsers(query: $query, body: $body)
  }
`;
export const deleteUsers = /* GraphQL */ `
  mutation DeleteUsers(
    $query: MutationDeleteUsersQueryInput
    $body: MutationDeleteUsersBodyInput
  ) {
    deleteUsers(query: $query, body: $body)
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject($params: MutationDeleteProjectParamsInput!) {
    deleteProject(params: $params)
  }
`;
export const deleteFacility = /* GraphQL */ `
  mutation DeleteFacility($params: MutationDeleteFacilityParamsInput!) {
    deleteFacility(params: $params)
  }
`;
export const deleteTaskType = /* GraphQL */ `
  mutation DeleteTaskType($params: MutationDeleteTaskTypeParamsInput!) {
    deleteTaskType(params: $params)
  }
`;
export const trashMessage = /* GraphQL */ `
  mutation TrashMessage($params: MutationTrashMessageParamsInput!) {
    trashMessage(params: $params)
  }
`;
export const restoreMessage = /* GraphQL */ `
  mutation RestoreMessage($params: MutationRestoreMessageParamsInput!) {
    restoreMessage(params: $params) {
      messageId
      companyId
      messagePriorityId
      fromName
      subject
      body
      messagePriorityCode
      statusId
      statusName
      accessedOn
      createdOn
    }
  }
`;
export const markMessageAsRead = /* GraphQL */ `
  mutation MarkMessageAsRead(
    $params: MutationMarkMessageAsReadParamsInput!
    $query: MutationMarkMessageAsReadQueryInput
    $body: MutationMarkMessageAsReadBodyInput
  ) {
    markMessageAsRead(params: $params, query: $query, body: $body)
  }
`;
export const deleteDepartment = /* GraphQL */ `
  mutation DeleteDepartment($params: MutationDeleteDepartmentParamsInput!) {
    deleteDepartment(params: $params)
  }
`;
export const deleteEntitlement = /* GraphQL */ `
  mutation DeleteEntitlement($params: MutationDeleteEntitlementParamsInput!) {
    deleteEntitlement(params: $params) {
      entitlementId
      userId
      isCheckedOut
      checkedOutEndDate
      secureData
      secureSignature
      licenseTypeCode
      productPool {
        productPoolId
        poolQuantity
        productId
        bundleId
        type
        label
        poolAvailableQuantity
        renewalDate
        customData
        termDate
        startDate
        trialRenewalDate
        company {
          companyId
          companyName
          renewalDate
          mfA_Required
          data_Analytics
          companyDownloadPaths {
            document
            updateBy
            updatedOn
          }
          companyImageId
          address {
            addressId
            addressLine1
            addressLine2
            city
            state
            country
            zip
          }
          productPool {
            productPoolId
            poolQuantity
            productId
            bundleId
            type
            label
            poolAvailableQuantity
            renewalDate
            customData
            termDate
            startDate
            trialRenewalDate
            company {
              companyId
              companyName
              renewalDate
              mfA_Required
              data_Analytics
              companyDownloadPaths {
                document
                updateBy
                updatedOn
              }
              companyImageId
              address {
                addressId
                addressLine1
                addressLine2
                city
                state
                country
                zip
              }
              productPool {
                productPoolId
                poolQuantity
                productId
                bundleId
                type
                label
                poolAvailableQuantity
                renewalDate
                customData
                termDate
                startDate
                trialRenewalDate
              }
            }
            product {
              productId
              productName
              baseProductName
              isForesiteModule
              licenseTypeId
              licenseTypeName
              licenseClassId
              licenseClassName
              productNameFormatted
              onlyVisibleToMembers
              supportsTrial
              productLicenseTypes {
                productLicenseTypeId
                productId
                licenseTypeId
              }
            }
          }
        }
        product {
          productId
          productName
          baseProductName
          isForesiteModule
          licenseTypeId
          licenseTypeName
          licenseClassId
          licenseClassName
          productNameFormatted
          onlyVisibleToMembers
          supportsTrial
          productLicenseTypes {
            productLicenseTypeId
            productId
            licenseTypeId
            licenseType {
              licenseTypeId
              licenseTypeName
              licenseTypeCode
              sortOrder
            }
          }
        }
      }
    }
  }
`;
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation($params: MutationDeleteLocationParamsInput!) {
    deleteLocation(params: $params) {
      explicitlyDeletedLocation {
        locationId
        locationTypeId
        locationTypeName
        projectId
        locationIdentifier
        locationCompositeIdentifier
        sortOrder
        hasChildren
        parentLocationId
        locationName
        locationCompositeName
        locationPhases {
          locationPhaseId
          locationId
          workPhaseId
          workPhaseName
          scopePackageId
          scopePackageName
          sortOrder
        }
        childrenTypeName
        movedLocationPhases {
          workPhaseId
          locationPhaseId
        }
      }
      deletedLocationChildrenIds
      deletedLocationPhases {
        workPhaseId
        deletedLocationPhaseId
      }
    }
  }
`;
export const deleteScopePackage = /* GraphQL */ `
  mutation DeleteScopePackage($params: MutationDeleteScopePackageParamsInput!) {
    deleteScopePackage(params: $params) {
      deletedScopePackage {
        projectId
        scopePackageId
        scopePackageName
        scopePackageIdentifier
        sortOrder
        hasWorkPhases
      }
      deletedWorkPhasesIds
      deletedLocationPhases {
        locationId
        deletedLocationPhaseId
      }
    }
  }
`;
export const deleteWorkPhase = /* GraphQL */ `
  mutation DeleteWorkPhase($params: MutationDeleteWorkPhaseParamsInput!) {
    deleteWorkPhase(params: $params) {
      deletedWorkPhase {
        workPhaseId
        workPhaseIdentifier
        workPhaseName
        sortOrder
        scopePackageId
        scopePackageName
        projectId
        hasLocationPhases
        updatedWorkPhaseLocationPhases {
          locationId
          locationPhaseId
        }
      }
      deletedLocationPhases {
        locationId
        deletedLocationPhaseId
      }
    }
  }
`;
export const deleteWorkRequest = /* GraphQL */ `
  mutation DeleteWorkRequest($params: MutationDeleteWorkRequestParamsInput!) {
    deleteWorkRequest(params: $params)
  }
`;
export const deleteWorkRequestItem = /* GraphQL */ `
  mutation DeleteWorkRequestItem(
    $params: MutationDeleteWorkRequestItemParamsInput!
  ) {
    deleteWorkRequestItem(params: $params)
  }
`;
export const resendUserInvitation = /* GraphQL */ `
  mutation ResendUserInvitation(
    $params: MutationResendUserInvitationParamsInput!
  ) {
    resendUserInvitation(params: $params) {
      userId
      userName
      userFirstName
      userLastName
      userIdentifier
      userEmail
      workPhoneNumber
      personalPhoneNumber
      userStatus
      userStatusId
      inviteStatus
      userTypes {
        userTypeId
        typeName
        typeId
      }
      acceptedLatestEula
      companyId
      isAdmin
      isDeleted
      userPhotoId
    }
  }
`;
export const resendProjectUserInvitation = /* GraphQL */ `
  mutation ResendProjectUserInvitation(
    $params: MutationResendProjectUserInvitationParamsInput!
  ) {
    resendProjectUserInvitation(params: $params) {
      projectUserId
      projectId
      inviteStatus
      expiresAt
      isAdmin
      isDeleted
      user {
        userId
        userName
        userFirstName
        userLastName
        userIdentifier
        userEmail
        workPhoneNumber
        personalPhoneNumber
        userStatus
        userStatusId
        inviteStatus
        userTypes {
          userTypeId
          typeName
          typeId
        }
        acceptedLatestEula
        companyId
        isAdmin
        isDeleted
        userPhotoId
      }
    }
  }
`;
export const resendDepartmentUserInvitation = /* GraphQL */ `
  mutation ResendDepartmentUserInvitation(
    $params: MutationResendDepartmentUserInvitationParamsInput!
  ) {
    resendDepartmentUserInvitation(params: $params) {
      departmentUserId
      departmentId
      expiresAt
      inviteStatus
      isDeleted
      isAdmin
      user {
        userId
        userName
        userFirstName
        userLastName
        userIdentifier
        userEmail
        workPhoneNumber
        personalPhoneNumber
        userStatus
        userStatusId
        inviteStatus
        userTypes {
          userTypeId
          typeName
          typeId
        }
        acceptedLatestEula
        companyId
        isAdmin
        isDeleted
        userPhotoId
      }
    }
  }
`;
export const addBillOfMaterial = /* GraphQL */ `
  mutation AddBillOfMaterial(
    $query: MutationAddBillOfMaterialQueryInput
    $body: MutationAddBillOfMaterialBodyInput
  ) {
    addBillOfMaterial(query: $query, body: $body) {
      billOfMaterialsId
      billOfMaterialsStatusId
      billOfMaterialsStatusName
      projectId
      billOfMaterialsName
      createdOn
      createdBy
      modifiedOn
      modifiedBy
      createdByFirstName
      createdByLastName
      createdByEmail
      updatedOn
      updatedBy
      updatedByFirstName
      updatedByLastName
      updatedByEmail
    }
  }
`;
export const addBillOfMaterialsWriteInItem = /* GraphQL */ `
  mutation AddBillOfMaterialsWriteInItem(
    $query: MutationAddBillOfMaterialsWriteInItemQueryInput
    $body: MutationAddBillOfMaterialsWriteInItemBodyInput
  ) {
    addBillOfMaterialsWriteInItem(query: $query, body: $body) {
      lineItemId
      lineItemTypeId
      lineItemTypeName
      billOfMaterialsId
      unitOfMeasureId
      unitOfMeasureName
      unitOfMeasureCode
      manufacturerId
      manufacturerName
      partId
      locationId
      workPhaseId
      scopePackageId
      workPackageId
      kitPlanId
      spoolId
      lineItemName
      manufacturerCatalogNumber
      quantity
      remainingQuantity
      unitQuantity
      tradeSize
      createdOn
      createdBy
      createdByFirstName
      childLineItemContainerName
      createdByLastName
      createdByEmail
      updatedOn
      updatedBy
      updatedByFirstName
      updatedByLastName
      updatedByEmail
      numberOfOpenDecisions
      decisionsHaveVariantDeletedFromCurrentVersionOfPart
      ableToMakeDecisions
      numberOfDecisionsMade
      hasChildNodes
      parentLineItem
      projectId
      assemblyCustomCategoryId
      assemblyCustomCategoryName
      canChildLineItemBeAdded
    }
  }
`;
export const addBillOfMaterialsItem = /* GraphQL */ `
  mutation AddBillOfMaterialsItem(
    $query: MutationAddBillOfMaterialsItemQueryInput
    $body: MutationAddBillOfMaterialsItemBodyInput
  ) {
    addBillOfMaterialsItem(query: $query, body: $body) {
      lineItemId
      lineItemTypeId
      lineItemTypeName
      billOfMaterialsId
      unitOfMeasureId
      unitOfMeasureName
      unitOfMeasureCode
      manufacturerId
      manufacturerName
      partId
      locationId
      workPhaseId
      scopePackageId
      workPackageId
      kitPlanId
      spoolId
      lineItemName
      manufacturerCatalogNumber
      quantity
      remainingQuantity
      unitQuantity
      tradeSize
      createdOn
      createdBy
      createdByFirstName
      childLineItemContainerName
      createdByLastName
      createdByEmail
      updatedOn
      updatedBy
      updatedByFirstName
      updatedByLastName
      updatedByEmail
      numberOfOpenDecisions
      decisionsHaveVariantDeletedFromCurrentVersionOfPart
      ableToMakeDecisions
      numberOfDecisionsMade
      hasChildNodes
      parentLineItem
      projectId
      assemblyCustomCategoryId
      assemblyCustomCategoryName
      canChildLineItemBeAdded
    }
  }
`;
export const deleteBillOfMaterials = /* GraphQL */ `
  mutation DeleteBillOfMaterials(
    $params: MutationDeleteBillOfMaterialsParamsInput!
  ) {
    deleteBillOfMaterials(params: $params)
  }
`;
export const updateBillOfMaterialsItemById = /* GraphQL */ `
  mutation UpdateBillOfMaterialsItemById(
    $params: MutationUpdateBillOfMaterialsItemByIdParamsInput!
    $query: MutationUpdateBillOfMaterialsItemByIdQueryInput
    $body: MutationUpdateBillOfMaterialsItemByIdBodyInput
  ) {
    updateBillOfMaterialsItemById(params: $params, query: $query, body: $body) {
      lineItemId
      lineItemTypeId
      lineItemTypeName
      billOfMaterialsId
      unitOfMeasureId
      unitOfMeasureName
      unitOfMeasureCode
      manufacturerId
      manufacturerName
      partId
      locationId
      workPhaseId
      scopePackageId
      workPackageId
      kitPlanId
      spoolId
      lineItemName
      manufacturerCatalogNumber
      quantity
      remainingQuantity
      unitQuantity
      tradeSize
      createdOn
      createdBy
      createdByFirstName
      childLineItemContainerName
      createdByLastName
      createdByEmail
      updatedOn
      updatedBy
      updatedByFirstName
      updatedByLastName
      updatedByEmail
      numberOfOpenDecisions
      decisionsHaveVariantDeletedFromCurrentVersionOfPart
      ableToMakeDecisions
      numberOfDecisionsMade
      hasChildNodes
      parentLineItem
      projectId
      assemblyCustomCategoryId
      assemblyCustomCategoryName
      canChildLineItemBeAdded
    }
  }
`;
export const updateDecisions = /* GraphQL */ `
  mutation UpdateDecisions(
    $query: MutationUpdateDecisionsQueryInput
    $body: MutationUpdateDecisionsBodyInput
  ) {
    updateDecisions(query: $query, body: $body) {
      lineItemId
      lineItemTypeId
      lineItemTypeName
      billOfMaterialsId
      unitOfMeasureId
      unitOfMeasureName
      unitOfMeasureCode
      manufacturerId
      manufacturerName
      partId
      locationId
      workPhaseId
      scopePackageId
      workPackageId
      kitPlanId
      spoolId
      lineItemName
      manufacturerCatalogNumber
      quantity
      remainingQuantity
      unitQuantity
      tradeSize
      createdOn
      createdBy
      createdByFirstName
      childLineItemContainerName
      createdByLastName
      createdByEmail
      updatedOn
      updatedBy
      updatedByFirstName
      updatedByLastName
      updatedByEmail
      numberOfOpenDecisions
      decisionsHaveVariantDeletedFromCurrentVersionOfPart
      ableToMakeDecisions
      numberOfDecisionsMade
      hasChildNodes
      parentLineItem
      projectId
      assemblyCustomCategoryId
      assemblyCustomCategoryName
      canChildLineItemBeAdded
    }
  }
`;
export const updateBillOfMaterialItemAssemblyCategoryById = /* GraphQL */ `
  mutation UpdateBillOfMaterialItemAssemblyCategoryById(
    $params: MutationUpdateBillOfMaterialItemAssemblyCategoryByIdParamsInput!
    $query: MutationUpdateBillOfMaterialItemAssemblyCategoryByIdQueryInput
    $body: MutationUpdateBillOfMaterialItemAssemblyCategoryByIdBodyInput
  ) {
    updateBillOfMaterialItemAssemblyCategoryById(
      params: $params
      query: $query
      body: $body
    ) {
      lineItemId
      lineItemTypeId
      lineItemTypeName
      billOfMaterialsId
      unitOfMeasureId
      unitOfMeasureName
      unitOfMeasureCode
      manufacturerId
      manufacturerName
      partId
      locationId
      workPhaseId
      scopePackageId
      workPackageId
      kitPlanId
      spoolId
      lineItemName
      manufacturerCatalogNumber
      quantity
      remainingQuantity
      unitQuantity
      tradeSize
      createdOn
      createdBy
      createdByFirstName
      childLineItemContainerName
      createdByLastName
      createdByEmail
      updatedOn
      updatedBy
      updatedByFirstName
      updatedByLastName
      updatedByEmail
      numberOfOpenDecisions
      decisionsHaveVariantDeletedFromCurrentVersionOfPart
      ableToMakeDecisions
      numberOfDecisionsMade
      hasChildNodes
      parentLineItem
      projectId
      assemblyCustomCategoryId
      assemblyCustomCategoryName
      canChildLineItemBeAdded
    }
  }
`;
export const updateDecisionsTakeoff = /* GraphQL */ `
  mutation UpdateDecisionsTakeoff(
    $query: MutationUpdateDecisionsTakeoffQueryInput
    $body: MutationUpdateDecisionsTakeoffBodyInput
  ) {
    updateDecisionsTakeoff(query: $query, body: $body) {
      lineItemId
      lineItemTypeId
      lineItemTypeName
      takeoffPadId
      unitOfMeasureId
      unitOfMeasureName
      unitOfMeasureCode
      manufacturerId
      manufacturerName
      partId
      locationId
      workPhaseId
      scopePackageId
      workPackageId
      kitPlanId
      spoolId
      lineItemName
      manufacturerCatalogNumber
      quantity
      remainingQuantity
      unitQuantity
      tradeSize
      billOfMaterialsName
      createdOn
      createdBy
      createdByFirstName
      createdByLastName
      createdByEmail
      updatedOn
      updatedBy
      updatedByFirstName
      updatedByLastName
      updatedByEmail
      numberOfOpenDecisions
      decisionsHaveVariantDeletedFromCurrentVersionOfPart
      ableToMakeDecisions
      numberOfDecisionsMade
    }
  }
`;
export const renameBillOfMaterials = /* GraphQL */ `
  mutation RenameBillOfMaterials(
    $params: MutationRenameBillOfMaterialsParamsInput!
    $query: MutationRenameBillOfMaterialsQueryInput
    $body: MutationRenameBillOfMaterialsBodyInput
  ) {
    renameBillOfMaterials(params: $params, query: $query, body: $body) {
      billOfMaterialsId
      billOfMaterialsStatusId
      billOfMaterialsStatusName
      projectId
      billOfMaterialsName
      createdOn
      createdBy
      modifiedOn
      modifiedBy
      createdByFirstName
      createdByLastName
      createdByEmail
      updatedOn
      updatedBy
      updatedByFirstName
      updatedByLastName
      updatedByEmail
    }
  }
`;
export const closeBillOfMaterials = /* GraphQL */ `
  mutation CloseBillOfMaterials(
    $params: MutationCloseBillOfMaterialsParamsInput!
  ) {
    closeBillOfMaterials(params: $params)
  }
`;
export const openBillOfMaterials = /* GraphQL */ `
  mutation OpenBillOfMaterials(
    $params: MutationOpenBillOfMaterialsParamsInput!
  ) {
    openBillOfMaterials(params: $params)
  }
`;
export const addManufacturer = /* GraphQL */ `
  mutation AddManufacturer(
    $query: MutationAddManufacturerQueryInput
    $body: MutationAddManufacturerBodyInput
  ) {
    addManufacturer(query: $query, body: $body) {
      manufacturerId
      companyId
      manufacturerName
      homePageUrl
    }
  }
`;
export const addPart = /* GraphQL */ `
  mutation AddPart(
    $query: MutationAddPartQueryInput
    $body: MutationAddPartBodyInput
  ) {
    addPart(query: $query, body: $body) {
      partId
      partName
      description
      stateId
      isDefault
      manufacturerCatalogNumber
      unitOfMeasureCode
      unitOfMeasureName
      upcCode
      url
      catalogUrl
      partUrl
      internalPartNumber
      createdOn
      hasAssembly
      manufacturer {
        manufacturerId
        companyId
        manufacturerName
        homePageUrl
      }
      attriubuteVariantData {
        partAttributeVariantDataId
        partAttributeId
        partAttributeName
        rangeValue
        part {
          partId
          partName
          description
          stateId
          isDefault
          manufacturerCatalogNumber
          unitOfMeasureCode
          unitOfMeasureName
          upcCode
          url
          catalogUrl
          partUrl
          internalPartNumber
          createdOn
          hasAssembly
          manufacturer {
            manufacturerId
            companyId
            manufacturerName
            homePageUrl
          }
          attriubuteVariantData {
            partAttributeVariantDataId
            partAttributeId
            partAttributeName
            rangeValue
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            partAttribute {
              partAttributeId
              partAttributeName
              allowWriteInVariant
              rangeMinimumValue
              rangeMaximumValue
              selectVariants {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
              partAttributeType {
                partAttributeTypeId
                partAttributeTypeName
                partAttributeTypeDescription
                sortOrder
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
            }
            selectValues {
              partAttributeSelectVariantId
              textValue
              codeValue
              isDeleted
            }
          }
          partCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          partImages {
            partImageId
            sortOrder
            isDefault
            document {
              documentId
              documentName
              documentIdentifier
              documentTypeId
              storagePath
              extension
              encodedUrn
              documentStatusTypeId
              isMappedFromShop
              folderDocumentResponse {
                folderDocumentId
                folderId
                folderName
                documentId
              }
              documentNameFormatted
              createdOn
              createdBy
            }
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
          }
          documentIds
          unitOfMeasure {
            unitOfMeasureId
            unitOfMeasureTypeId
            companyId
            unitOfMeasureName
            unitOfMeasureCode
            createdOn
            createdBy
            updatedOn
            modifiedBy
            modifiedOn
          }
        }
        partAttribute {
          partAttributeId
          partAttributeName
          allowWriteInVariant
          rangeMinimumValue
          rangeMaximumValue
          selectVariants {
            partAttributeSelectVariantId
            textValue
            codeValue
            isDeleted
          }
          partAttributeType {
            partAttributeTypeId
            partAttributeTypeName
            partAttributeTypeDescription
            sortOrder
          }
          partCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        selectValues {
          partAttributeSelectVariantId
          textValue
          codeValue
          isDeleted
        }
      }
      partCategory {
        partCategoryId
        partCategoryName
        partCatalog {
          partCatalogId
          partCatalogName
          companyId
          topLevelNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        parentPartCategory {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        subNodes {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
      }
      partImages {
        partImageId
        sortOrder
        isDefault
        document {
          documentId
          documentName
          documentIdentifier
          documentTypeId
          storagePath
          extension
          encodedUrn
          documentStatusTypeId
          isMappedFromShop
          folderDocumentResponse {
            folderDocumentId
            folderId
            folderName
            documentId
          }
          documentNameFormatted
          createdOn
          createdBy
        }
        part {
          partId
          partName
          description
          stateId
          isDefault
          manufacturerCatalogNumber
          unitOfMeasureCode
          unitOfMeasureName
          upcCode
          url
          catalogUrl
          partUrl
          internalPartNumber
          createdOn
          hasAssembly
          manufacturer {
            manufacturerId
            companyId
            manufacturerName
            homePageUrl
          }
          attriubuteVariantData {
            partAttributeVariantDataId
            partAttributeId
            partAttributeName
            rangeValue
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            partAttribute {
              partAttributeId
              partAttributeName
              allowWriteInVariant
              rangeMinimumValue
              rangeMaximumValue
              selectVariants {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
              partAttributeType {
                partAttributeTypeId
                partAttributeTypeName
                partAttributeTypeDescription
                sortOrder
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
            }
            selectValues {
              partAttributeSelectVariantId
              textValue
              codeValue
              isDeleted
            }
          }
          partCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          partImages {
            partImageId
            sortOrder
            isDefault
            document {
              documentId
              documentName
              documentIdentifier
              documentTypeId
              storagePath
              extension
              encodedUrn
              documentStatusTypeId
              isMappedFromShop
              folderDocumentResponse {
                folderDocumentId
                folderId
                folderName
                documentId
              }
              documentNameFormatted
              createdOn
              createdBy
            }
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
          }
          documentIds
          unitOfMeasure {
            unitOfMeasureId
            unitOfMeasureTypeId
            companyId
            unitOfMeasureName
            unitOfMeasureCode
            createdOn
            createdBy
            updatedOn
            modifiedBy
            modifiedOn
          }
        }
      }
      documentIds
      unitOfMeasure {
        unitOfMeasureId
        unitOfMeasureTypeId
        companyId
        unitOfMeasureName
        unitOfMeasureCode
        createdOn
        createdBy
        updatedOn
        modifiedBy
        modifiedOn
      }
    }
  }
`;
export const updatePart = /* GraphQL */ `
  mutation UpdatePart(
    $query: MutationUpdatePartQueryInput
    $body: MutationUpdatePartBodyInput
  ) {
    updatePart(query: $query, body: $body) {
      partId
      partName
      description
      stateId
      isDefault
      manufacturerCatalogNumber
      unitOfMeasureCode
      unitOfMeasureName
      upcCode
      url
      catalogUrl
      partUrl
      internalPartNumber
      createdOn
      hasAssembly
      manufacturer {
        manufacturerId
        companyId
        manufacturerName
        homePageUrl
      }
      attriubuteVariantData {
        partAttributeVariantDataId
        partAttributeId
        partAttributeName
        rangeValue
        part {
          partId
          partName
          description
          stateId
          isDefault
          manufacturerCatalogNumber
          unitOfMeasureCode
          unitOfMeasureName
          upcCode
          url
          catalogUrl
          partUrl
          internalPartNumber
          createdOn
          hasAssembly
          manufacturer {
            manufacturerId
            companyId
            manufacturerName
            homePageUrl
          }
          attriubuteVariantData {
            partAttributeVariantDataId
            partAttributeId
            partAttributeName
            rangeValue
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            partAttribute {
              partAttributeId
              partAttributeName
              allowWriteInVariant
              rangeMinimumValue
              rangeMaximumValue
              selectVariants {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
              partAttributeType {
                partAttributeTypeId
                partAttributeTypeName
                partAttributeTypeDescription
                sortOrder
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
            }
            selectValues {
              partAttributeSelectVariantId
              textValue
              codeValue
              isDeleted
            }
          }
          partCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          partImages {
            partImageId
            sortOrder
            isDefault
            document {
              documentId
              documentName
              documentIdentifier
              documentTypeId
              storagePath
              extension
              encodedUrn
              documentStatusTypeId
              isMappedFromShop
              folderDocumentResponse {
                folderDocumentId
                folderId
                folderName
                documentId
              }
              documentNameFormatted
              createdOn
              createdBy
            }
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
          }
          documentIds
          unitOfMeasure {
            unitOfMeasureId
            unitOfMeasureTypeId
            companyId
            unitOfMeasureName
            unitOfMeasureCode
            createdOn
            createdBy
            updatedOn
            modifiedBy
            modifiedOn
          }
        }
        partAttribute {
          partAttributeId
          partAttributeName
          allowWriteInVariant
          rangeMinimumValue
          rangeMaximumValue
          selectVariants {
            partAttributeSelectVariantId
            textValue
            codeValue
            isDeleted
          }
          partAttributeType {
            partAttributeTypeId
            partAttributeTypeName
            partAttributeTypeDescription
            sortOrder
          }
          partCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        selectValues {
          partAttributeSelectVariantId
          textValue
          codeValue
          isDeleted
        }
      }
      partCategory {
        partCategoryId
        partCategoryName
        partCatalog {
          partCatalogId
          partCatalogName
          companyId
          topLevelNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        parentPartCategory {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        subNodes {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
      }
      partImages {
        partImageId
        sortOrder
        isDefault
        document {
          documentId
          documentName
          documentIdentifier
          documentTypeId
          storagePath
          extension
          encodedUrn
          documentStatusTypeId
          isMappedFromShop
          folderDocumentResponse {
            folderDocumentId
            folderId
            folderName
            documentId
          }
          documentNameFormatted
          createdOn
          createdBy
        }
        part {
          partId
          partName
          description
          stateId
          isDefault
          manufacturerCatalogNumber
          unitOfMeasureCode
          unitOfMeasureName
          upcCode
          url
          catalogUrl
          partUrl
          internalPartNumber
          createdOn
          hasAssembly
          manufacturer {
            manufacturerId
            companyId
            manufacturerName
            homePageUrl
          }
          attriubuteVariantData {
            partAttributeVariantDataId
            partAttributeId
            partAttributeName
            rangeValue
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            partAttribute {
              partAttributeId
              partAttributeName
              allowWriteInVariant
              rangeMinimumValue
              rangeMaximumValue
              selectVariants {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
              partAttributeType {
                partAttributeTypeId
                partAttributeTypeName
                partAttributeTypeDescription
                sortOrder
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
            }
            selectValues {
              partAttributeSelectVariantId
              textValue
              codeValue
              isDeleted
            }
          }
          partCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          partImages {
            partImageId
            sortOrder
            isDefault
            document {
              documentId
              documentName
              documentIdentifier
              documentTypeId
              storagePath
              extension
              encodedUrn
              documentStatusTypeId
              isMappedFromShop
              folderDocumentResponse {
                folderDocumentId
                folderId
                folderName
                documentId
              }
              documentNameFormatted
              createdOn
              createdBy
            }
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
          }
          documentIds
          unitOfMeasure {
            unitOfMeasureId
            unitOfMeasureTypeId
            companyId
            unitOfMeasureName
            unitOfMeasureCode
            createdOn
            createdBy
            updatedOn
            modifiedBy
            modifiedOn
          }
        }
      }
      documentIds
      unitOfMeasure {
        unitOfMeasureId
        unitOfMeasureTypeId
        companyId
        unitOfMeasureName
        unitOfMeasureCode
        createdOn
        createdBy
        updatedOn
        modifiedBy
        modifiedOn
      }
    }
  }
`;
export const deletePart = /* GraphQL */ `
  mutation DeletePart($params: MutationDeletePartParamsInput!) {
    deletePart(params: $params)
  }
`;
export const removePartsFromCategory = /* GraphQL */ `
  mutation RemovePartsFromCategory(
    $params: MutationRemovePartsFromCategoryParamsInput!
    $query: MutationRemovePartsFromCategoryQueryInput
    $body: MutationRemovePartsFromCategoryBodyInput
  ) {
    removePartsFromCategory(params: $params, query: $query, body: $body) {
      partCategoryId
      partCategoryName
      partCatalog {
        partCatalogId
        partCatalogName
        companyId
        topLevelNodes {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
      }
      parentPartCategory {
        partCategoryId
        partCategoryName
        partCatalog {
          partCatalogId
          partCatalogName
          companyId
          topLevelNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        parentPartCategory {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        subNodes {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
      }
      subNodes {
        partCategoryId
        partCategoryName
        partCatalog {
          partCatalogId
          partCatalogName
          companyId
          topLevelNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        parentPartCategory {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        subNodes {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
      }
    }
  }
`;
export const removePartAttributesFromCategory = /* GraphQL */ `
  mutation RemovePartAttributesFromCategory(
    $params: MutationRemovePartAttributesFromCategoryParamsInput!
    $query: MutationRemovePartAttributesFromCategoryQueryInput
    $body: MutationRemovePartAttributesFromCategoryBodyInput
  ) {
    removePartAttributesFromCategory(
      params: $params
      query: $query
      body: $body
    ) {
      partCategoryId
      partCategoryName
      partCatalog {
        partCatalogId
        partCatalogName
        companyId
        topLevelNodes {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
      }
      parentPartCategory {
        partCategoryId
        partCategoryName
        partCatalog {
          partCatalogId
          partCatalogName
          companyId
          topLevelNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        parentPartCategory {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        subNodes {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
      }
      subNodes {
        partCategoryId
        partCategoryName
        partCatalog {
          partCatalogId
          partCatalogName
          companyId
          topLevelNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        parentPartCategory {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        subNodes {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
      }
    }
  }
`;
export const addPartImageWithDocumentId = /* GraphQL */ `
  mutation AddPartImageWithDocumentId(
    $query: MutationAddPartImageWithDocumentIdQueryInput
    $body: MutationAddPartImageWithDocumentIdBodyInput
  ) {
    addPartImageWithDocumentId(query: $query, body: $body) {
      partImageId
      sortOrder
      isDefault
      document {
        documentId
        documentName
        documentIdentifier
        documentTypeId
        storagePath
        extension
        encodedUrn
        documentStatusTypeId
        isMappedFromShop
        folderDocumentResponse {
          folderDocumentId
          folderId
          folderName
          documentId
        }
        documentNameFormatted
        createdOn
        createdBy
      }
      part {
        partId
        partName
        description
        stateId
        isDefault
        manufacturerCatalogNumber
        unitOfMeasureCode
        unitOfMeasureName
        upcCode
        url
        catalogUrl
        partUrl
        internalPartNumber
        createdOn
        hasAssembly
        manufacturer {
          manufacturerId
          companyId
          manufacturerName
          homePageUrl
        }
        attriubuteVariantData {
          partAttributeVariantDataId
          partAttributeId
          partAttributeName
          rangeValue
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
          partAttribute {
            partAttributeId
            partAttributeName
            allowWriteInVariant
            rangeMinimumValue
            rangeMaximumValue
            selectVariants {
              partAttributeSelectVariantId
              textValue
              codeValue
              isDeleted
            }
            partAttributeType {
              partAttributeTypeId
              partAttributeTypeName
              partAttributeTypeDescription
              sortOrder
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          selectValues {
            partAttributeSelectVariantId
            textValue
            codeValue
            isDeleted
          }
        }
        partCategory {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        partImages {
          partImageId
          sortOrder
          isDefault
          document {
            documentId
            documentName
            documentIdentifier
            documentTypeId
            storagePath
            extension
            encodedUrn
            documentStatusTypeId
            isMappedFromShop
            folderDocumentResponse {
              folderDocumentId
              folderId
              folderName
              documentId
            }
            documentNameFormatted
            createdOn
            createdBy
          }
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
        }
        documentIds
        unitOfMeasure {
          unitOfMeasureId
          unitOfMeasureTypeId
          companyId
          unitOfMeasureName
          unitOfMeasureCode
          createdOn
          createdBy
          updatedOn
          modifiedBy
          modifiedOn
        }
      }
    }
  }
`;
export const updatePartImageWithDocumentId = /* GraphQL */ `
  mutation UpdatePartImageWithDocumentId(
    $params: MutationUpdatePartImageWithDocumentIdParamsInput!
    $query: MutationUpdatePartImageWithDocumentIdQueryInput
    $body: MutationUpdatePartImageWithDocumentIdBodyInput
  ) {
    updatePartImageWithDocumentId(params: $params, query: $query, body: $body) {
      partImageId
      sortOrder
      isDefault
      document {
        documentId
        documentName
        documentIdentifier
        documentTypeId
        storagePath
        extension
        encodedUrn
        documentStatusTypeId
        isMappedFromShop
        folderDocumentResponse {
          folderDocumentId
          folderId
          folderName
          documentId
        }
        documentNameFormatted
        createdOn
        createdBy
      }
      part {
        partId
        partName
        description
        stateId
        isDefault
        manufacturerCatalogNumber
        unitOfMeasureCode
        unitOfMeasureName
        upcCode
        url
        catalogUrl
        partUrl
        internalPartNumber
        createdOn
        hasAssembly
        manufacturer {
          manufacturerId
          companyId
          manufacturerName
          homePageUrl
        }
        attriubuteVariantData {
          partAttributeVariantDataId
          partAttributeId
          partAttributeName
          rangeValue
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
          partAttribute {
            partAttributeId
            partAttributeName
            allowWriteInVariant
            rangeMinimumValue
            rangeMaximumValue
            selectVariants {
              partAttributeSelectVariantId
              textValue
              codeValue
              isDeleted
            }
            partAttributeType {
              partAttributeTypeId
              partAttributeTypeName
              partAttributeTypeDescription
              sortOrder
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          selectValues {
            partAttributeSelectVariantId
            textValue
            codeValue
            isDeleted
          }
        }
        partCategory {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        partImages {
          partImageId
          sortOrder
          isDefault
          document {
            documentId
            documentName
            documentIdentifier
            documentTypeId
            storagePath
            extension
            encodedUrn
            documentStatusTypeId
            isMappedFromShop
            folderDocumentResponse {
              folderDocumentId
              folderId
              folderName
              documentId
            }
            documentNameFormatted
            createdOn
            createdBy
          }
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
        }
        documentIds
        unitOfMeasure {
          unitOfMeasureId
          unitOfMeasureTypeId
          companyId
          unitOfMeasureName
          unitOfMeasureCode
          createdOn
          createdBy
          updatedOn
          modifiedBy
          modifiedOn
        }
      }
    }
  }
`;
export const deletePartImage = /* GraphQL */ `
  mutation DeletePartImage($params: MutationDeletePartImageParamsInput!) {
    deletePartImage(params: $params)
  }
`;
export const addUnitOfMeasure = /* GraphQL */ `
  mutation AddUnitOfMeasure(
    $query: MutationAddUnitOfMeasureQueryInput
    $body: MutationAddUnitOfMeasureBodyInput
  ) {
    addUnitOfMeasure(query: $query, body: $body) {
      unitOfMeasureId
      unitOfMeasureTypeId
      companyId
      unitOfMeasureName
      unitOfMeasureCode
      createdOn
      createdBy
      updatedOn
      modifiedBy
      modifiedOn
    }
  }
`;
export const addTakeoffPad = /* GraphQL */ `
  mutation AddTakeoffPad(
    $query: MutationAddTakeoffPadQueryInput
    $body: MutationAddTakeoffPadBodyInput
  ) {
    addTakeoffPad(query: $query, body: $body) {
      takeoffPadId
      takeoffPadName
      lineItemContainerId
      lineItemContainerStatusId
      lineItemContainerStatusName
      projectId
      lineItemContainerName
      hasMappedItems
      hasItemsMappedFromSourcePad
      createdOn
      createdBy
      createdByFirstName
      createdByLastName
      createdByEmail
      updatedOn
      updatedBy
      updatedByFirstName
      updatedByLastName
      updatedByEmail
    }
  }
`;
export const moveTakeoffPadItems = /* GraphQL */ `
  mutation MoveTakeoffPadItems(
    $query: MutationMoveTakeoffPadItemsQueryInput
    $body: MutationMoveTakeoffPadItemsBodyInput
  ) {
    moveTakeoffPadItems(query: $query, body: $body) {
      previousLineItemId
      lineItemId
      lineItemTypeId
      lineItemTypeName
      sourcePadId
      unitOfMeasureId
      unitOfMeasureName
      unitOfMeasureCode
      manufacturerId
      manufacturerName
      partId
      locationId
      workPhaseId
      scopePackageId
      workPackageId
      kitPlanId
      spoolId
      lineItemName
      manufacturerCatalogNumber
      quantity
      actualQuantity
      remainingQuantity
      actualRemainingQuantity
      unitQuantity
      tradeSize
      takeoffPadName
      createdOn
      createdBy
      createdByFirstName
      createdByLastName
      createdByEmail
      updatedOn
      updatedBy
      updatedByFirstName
      updatedByLastName
      updatedByEmail
    }
  }
`;
export const deleteTakeoffPad = /* GraphQL */ `
  mutation DeleteTakeoffPad($params: MutationDeleteTakeoffPadParamsInput!) {
    deleteTakeoffPad(params: $params)
  }
`;
export const renameTakeOffPad = /* GraphQL */ `
  mutation RenameTakeOffPad(
    $params: MutationRenameTakeOffPadParamsInput!
    $query: MutationRenameTakeOffPadQueryInput
    $body: MutationRenameTakeOffPadBodyInput
  ) {
    renameTakeOffPad(params: $params, query: $query, body: $body) {
      takeoffPadId
      takeoffPadName
      lineItemContainerId
      lineItemContainerStatusId
      lineItemContainerStatusName
      projectId
      lineItemContainerName
      hasMappedItems
      hasItemsMappedFromSourcePad
      createdOn
      createdBy
      createdByFirstName
      createdByLastName
      createdByEmail
      updatedOn
      updatedBy
      updatedByFirstName
      updatedByLastName
      updatedByEmail
    }
  }
`;
export const addTakeOffPadItem = /* GraphQL */ `
  mutation AddTakeOffPadItem(
    $query: MutationAddTakeOffPadItemQueryInput
    $body: MutationAddTakeOffPadItemBodyInput
  ) {
    addTakeOffPadItem(query: $query, body: $body) {
      lineItemId
      lineItemTypeId
      lineItemTypeName
      takeoffPadId
      unitOfMeasureId
      unitOfMeasureName
      unitOfMeasureCode
      manufacturerId
      manufacturerName
      partId
      locationId
      workPhaseId
      scopePackageId
      workPackageId
      kitPlanId
      spoolId
      lineItemName
      manufacturerCatalogNumber
      quantity
      remainingQuantity
      unitQuantity
      tradeSize
      billOfMaterialsName
      createdOn
      createdBy
      createdByFirstName
      createdByLastName
      createdByEmail
      updatedOn
      updatedBy
      updatedByFirstName
      updatedByLastName
      updatedByEmail
      numberOfOpenDecisions
      decisionsHaveVariantDeletedFromCurrentVersionOfPart
      ableToMakeDecisions
      numberOfDecisionsMade
    }
  }
`;
export const deleteTakeOffPadItem = /* GraphQL */ `
  mutation DeleteTakeOffPadItem(
    $query: MutationDeleteTakeOffPadItemQueryInput
    $body: MutationDeleteTakeOffPadItemBodyInput
  ) {
    deleteTakeOffPadItem(query: $query, body: $body)
  }
`;
export const addShopConfiguration = /* GraphQL */ `
  mutation AddShopConfiguration(
    $query: MutationAddShopConfigurationQueryInput
    $body: MutationAddShopConfigurationBodyInput
  ) {
    addShopConfiguration(query: $query, body: $body) {
      shopConfigurationId
      shopConfigurationName
      shopConfigurationDescription
      departmentId
      facilityId
      isActive
    }
  }
`;
export const updateShopConfiguration = /* GraphQL */ `
  mutation UpdateShopConfiguration(
    $params: MutationUpdateShopConfigurationParamsInput!
    $query: MutationUpdateShopConfigurationQueryInput
    $body: MutationUpdateShopConfigurationBodyInput
  ) {
    updateShopConfiguration(params: $params, query: $query, body: $body) {
      shopConfigurationId
      shopConfigurationName
      shopConfigurationDescription
      departmentId
      facilityId
      isActive
    }
  }
`;
export const deleteShopConfiguration = /* GraphQL */ `
  mutation DeleteShopConfiguration(
    $params: MutationDeleteShopConfigurationParamsInput!
  ) {
    deleteShopConfiguration(params: $params)
  }
`;
export const addPartAttributeSelectVariant = /* GraphQL */ `
  mutation AddPartAttributeSelectVariant(
    $query: MutationAddPartAttributeSelectVariantQueryInput
    $body: MutationAddPartAttributeSelectVariantBodyInput
  ) {
    addPartAttributeSelectVariant(query: $query, body: $body) {
      partAttributeSelectVariantId
      textValue
      codeValue
      isDeleted
    }
  }
`;
export const addShopProperty = /* GraphQL */ `
  mutation AddShopProperty(
    $query: MutationAddShopPropertyQueryInput
    $body: MutationAddShopPropertyBodyInput
  ) {
    addShopProperty(query: $query, body: $body) {
      shopPropertyId
      shopPropertyName
      shopPropertyDescription
      shippingAddressLine1
      shippingAddressLine2
      city
      state
      zip
      country
      userId
      shopConfigurationId
      facilityId
    }
  }
`;
export const updateShopProperty = /* GraphQL */ `
  mutation UpdateShopProperty(
    $params: MutationUpdateShopPropertyParamsInput!
    $query: MutationUpdateShopPropertyQueryInput
    $body: MutationUpdateShopPropertyBodyInput
  ) {
    updateShopProperty(params: $params, query: $query, body: $body) {
      shopPropertyId
      shopPropertyName
      shopPropertyDescription
      shippingAddressLine1
      shippingAddressLine2
      city
      state
      zip
      country
      userId
      shopConfigurationId
      facilityId
    }
  }
`;
export const addWorkCell = /* GraphQL */ `
  mutation AddWorkCell(
    $query: MutationAddWorkCellQueryInput
    $body: MutationAddWorkCellBodyInput
  ) {
    addWorkCell(query: $query, body: $body) {
      workCellId
      workCellName
      workCellDescription
      facilityId
      shopConfigurationId
      hasTaskTypes
      hasMachines
    }
  }
`;
export const updateWorkCell = /* GraphQL */ `
  mutation UpdateWorkCell(
    $params: MutationUpdateWorkCellParamsInput!
    $query: MutationUpdateWorkCellQueryInput
    $body: MutationUpdateWorkCellBodyInput
  ) {
    updateWorkCell(params: $params, query: $query, body: $body) {
      workCellId
      workCellName
      workCellDescription
      facilityId
      shopConfigurationId
      hasTaskTypes
      hasMachines
    }
  }
`;
export const updateWorkCellTask = /* GraphQL */ `
  mutation UpdateWorkCellTask(
    $params: MutationUpdateWorkCellTaskParamsInput!
    $query: MutationUpdateWorkCellTaskQueryInput
    $body: MutationUpdateWorkCellTaskBodyInput
  ) {
    updateWorkCellTask(params: $params, query: $query, body: $body) {
      workCellTaskId
      workCellId
      taskId
      workOrderId
      sortOrder
    }
  }
`;
export const deleteWorkCellTask = /* GraphQL */ `
  mutation DeleteWorkCellTask($params: MutationDeleteWorkCellTaskParamsInput!) {
    deleteWorkCellTask(params: $params)
  }
`;
export const deleteWorkCell = /* GraphQL */ `
  mutation DeleteWorkCell($params: MutationDeleteWorkCellParamsInput!) {
    deleteWorkCell(params: $params)
  }
`;
export const deleteWorkCellTaskType = /* GraphQL */ `
  mutation DeleteWorkCellTaskType(
    $params: MutationDeleteWorkCellTaskTypeParamsInput!
  ) {
    deleteWorkCellTaskType(params: $params)
  }
`;
export const deleteMachineTaskType = /* GraphQL */ `
  mutation DeleteMachineTaskType(
    $params: MutationDeleteMachineTaskTypeParamsInput!
  ) {
    deleteMachineTaskType(params: $params)
  }
`;
export const mapSourcePadItem = /* GraphQL */ `
  mutation MapSourcePadItem(
    $query: MutationMapSourcePadItemQueryInput
    $body: MutationMapSourcePadItemBodyInput
  ) {
    mapSourcePadItem(query: $query, body: $body)
  }
`;
export const mapTaskTypeToWorkCell = /* GraphQL */ `
  mutation MapTaskTypeToWorkCell(
    $query: MutationMapTaskTypeToWorkCellQueryInput
    $body: MutationMapTaskTypeToWorkCellBodyInput
  ) {
    mapTaskTypeToWorkCell(query: $query, body: $body) {
      workCellTaskTypeId
      taskTypeId
      workCellId
    }
  }
`;
export const mapTaskTypeToMachine = /* GraphQL */ `
  mutation MapTaskTypeToMachine(
    $query: MutationMapTaskTypeToMachineQueryInput
    $body: MutationMapTaskTypeToMachineBodyInput
  ) {
    mapTaskTypeToMachine(query: $query, body: $body) {
      machineTaskTypeId
      taskTypeId
      machineId
    }
  }
`;
export const getDocumentPresignedURL = /* GraphQL */ `
  mutation GetDocumentPresignedURL(
    $query: MutationGetDocumentPresignedURLQueryInput
    $body: MutationGetDocumentPresignedURLBodyInput
  ) {
    getDocumentPresignedURL(query: $query, body: $body) {
      objectKey
      expirationHours
      preSignedURL
      contentType
    }
  }
`;
export const addFolder = /* GraphQL */ `
  mutation AddFolder(
    $query: MutationAddFolderQueryInput
    $body: MutationAddFolderBodyInput
  ) {
    addFolder(query: $query, body: $body) {
      folderId
      parentFolderId
      companyId
      folderName
      storagePath
      folderDescription
      hasSubFolder
      isHidden
      projectId
    }
  }
`;
export const updateFolder = /* GraphQL */ `
  mutation UpdateFolder(
    $params: MutationUpdateFolderParamsInput!
    $query: MutationUpdateFolderQueryInput
    $body: MutationUpdateFolderBodyInput
  ) {
    updateFolder(params: $params, query: $query, body: $body) {
      folderId
      parentFolderId
      companyId
      folderName
      storagePath
      folderDescription
      hasSubFolder
      isHidden
      projectId
    }
  }
`;
export const deleteFolder = /* GraphQL */ `
  mutation DeleteFolder($params: MutationDeleteFolderParamsInput!) {
    deleteFolder(params: $params)
  }
`;
export const addDocument = /* GraphQL */ `
  mutation AddDocument(
    $query: MutationAddDocumentQueryInput
    $body: MutationAddDocumentBodyInput
  ) {
    addDocument(query: $query, body: $body) {
      documentId
      documentName
      documentIdentifier
      documentTypeId
      storagePath
      extension
      encodedUrn
      documentStatusTypeId
      isMappedFromShop
      folderDocumentResponse {
        folderDocumentId
        folderId
        folderName
        documentId
      }
      documentNameFormatted
      createdOn
      createdBy
    }
  }
`;
export const updateDocument = /* GraphQL */ `
  mutation UpdateDocument(
    $params: MutationUpdateDocumentParamsInput!
    $query: MutationUpdateDocumentQueryInput
    $body: MutationUpdateDocumentBodyInput
  ) {
    updateDocument(params: $params, query: $query, body: $body) {
      documentId
      documentName
      documentIdentifier
      documentTypeId
      storagePath
      extension
      encodedUrn
      documentStatusTypeId
      isMappedFromShop
      folderDocumentResponse {
        folderDocumentId
        folderId
        folderName
        documentId
      }
      documentNameFormatted
      createdOn
      createdBy
    }
  }
`;
export const mapWorkPhaseToLocation = /* GraphQL */ `
  mutation MapWorkPhaseToLocation(
    $query: MutationMapWorkPhaseToLocationQueryInput
    $body: MutationMapWorkPhaseToLocationBodyInput
  ) {
    mapWorkPhaseToLocation(query: $query, body: $body) {
      locationId
      location {
        locationId
        locationTypeId
        locationTypeName
        projectId
        locationIdentifier
        locationCompositeIdentifier
        sortOrder
        hasChildren
        parentLocationId
        locationName
        locationCompositeName
        locationPhases {
          locationPhaseId
          locationId
          workPhaseId
          workPhaseName
          scopePackageId
          scopePackageName
          sortOrder
        }
        childrenTypeName
        movedLocationPhases {
          workPhaseId
          locationPhaseId
        }
      }
      workPhases {
        workPhaseId
        workPhaseIdentifier
        workPhaseName
        sortOrder
        scopePackageId
        scopePackageName
        projectId
        hasLocationPhases
        updatedWorkPhaseLocationPhases {
          locationId
          locationPhaseId
        }
      }
    }
  }
`;
export const unmapWorkPhaseFromLocation = /* GraphQL */ `
  mutation UnmapWorkPhaseFromLocation(
    $params: MutationUnmapWorkPhaseFromLocationParamsInput!
  ) {
    unmapWorkPhaseFromLocation(params: $params)
  }
`;
export const addMachine = /* GraphQL */ `
  mutation AddMachine(
    $query: MutationAddMachineQueryInput
    $body: MutationAddMachineBodyInput
  ) {
    addMachine(query: $query, body: $body) {
      machineId
      manufacturer
      model
      serialNumber
      machineImageId
      facilityId
      machineIdentifier
      workCells {
        workCellId
        shopConfigurationId
      }
    }
  }
`;
export const updateMachine = /* GraphQL */ `
  mutation UpdateMachine(
    $params: MutationUpdateMachineParamsInput!
    $query: MutationUpdateMachineQueryInput
    $body: MutationUpdateMachineBodyInput
  ) {
    updateMachine(params: $params, query: $query, body: $body) {
      machineId
      manufacturer
      model
      serialNumber
      machineImageId
      facilityId
      machineIdentifier
      workCells {
        workCellId
        shopConfigurationId
      }
    }
  }
`;
export const deleteWorkCellMachine = /* GraphQL */ `
  mutation DeleteWorkCellMachine(
    $params: MutationDeleteWorkCellMachineParamsInput!
  ) {
    deleteWorkCellMachine(params: $params)
  }
`;
export const addWorkCellMachine = /* GraphQL */ `
  mutation AddWorkCellMachine(
    $query: MutationAddWorkCellMachineQueryInput
    $body: MutationAddWorkCellMachineBodyInput
  ) {
    addWorkCellMachine(query: $query, body: $body) {
      workCellMachineId
      facilityId
      shopConfigurationId
      workCellId
      machineId
      machineManufacturer
      machineIdentifier
    }
  }
`;
export const syncDeleteConnection = /* GraphQL */ `
  mutation SyncDeleteConnection(
    $query: MutationSyncDeleteConnectionQueryInput
    $body: MutationSyncDeleteConnectionBodyInput
  ) {
    syncDeleteConnection(query: $query, body: $body) {
      connectionsInUsed {
        id
        name
      }
    }
  }
`;
export const syncCreateConnection = /* GraphQL */ `
  mutation SyncCreateConnection(
    $params: MutationSyncCreateConnectionParamsInput!
    $query: MutationSyncCreateConnectionQueryInput
    $body: MutationSyncCreateConnectionBodyInput
  ) {
    syncCreateConnection(params: $params, query: $query, body: $body)
  }
`;
export const syncUpdateConnection = /* GraphQL */ `
  mutation SyncUpdateConnection(
    $params: MutationSyncUpdateConnectionParamsInput!
    $query: MutationSyncUpdateConnectionQueryInput
    $body: MutationSyncUpdateConnectionBodyInput
  ) {
    syncUpdateConnection(params: $params, query: $query, body: $body)
  }
`;
export const updateWorkOrder = /* GraphQL */ `
  mutation UpdateWorkOrder(
    $params: MutationUpdateWorkOrderParamsInput!
    $query: MutationUpdateWorkOrderQueryInput
    $body: MutationUpdateWorkOrderBodyInput
  ) {
    updateWorkOrder(params: $params, query: $query, body: $body) {
      workOrderId
      workRequestId
      workOrderStatusTypeId
      workOrderStatusTypeName
      workOrderName
      workOrderDescription
      workOrderNumber
      workRequest {
        workRequestId
        workRequestStatusId
        workRequestStatusName
        facilityId
        projectId
        projectIdentifier
        projectName
        workRequestIdentifier
        workRequestName
        needBy
        workRequestDescription
        facilityName
        workRequestStatusDescription
        lastStatusUpdate
        numberOfAttachments
      }
      sortOrder
      createdBy
      lastStatusUpdate
      createdOn
      isStepFunctionProcessing
      stepFunctionProcessingMessage
      numberOfAttachments
      hasCustomWorkOrderName
    }
  }
`;
export const deleteWorkOrder = /* GraphQL */ `
  mutation DeleteWorkOrder($params: MutationDeleteWorkOrderParamsInput!) {
    deleteWorkOrder(params: $params)
  }
`;
export const syncCreateWorkflow = /* GraphQL */ `
  mutation SyncCreateWorkflow(
    $query: MutationSyncCreateWorkflowQueryInput
    $body: MutationSyncCreateWorkflowBodyInput
  ) {
    syncCreateWorkflow(query: $query, body: $body) {
      workflowId
      workflowName
      workflowMapping {
        workflowMappingId
        workflowMappingName
        isSyncRunning
        lastSyncAt
        lastSavedAt
        solitaryFlows {
          solitaryFlowId
          action
          actionNodeId
          actionAlias
          source {
            syncDetailsId
            nodeId
            connection {
              id
              name
              type
              configuration
              isConnectionUsed
              lastModified
              desktopConnectionId
            }
            hub {
              id
              name
            }
            projects {
              id
              name
              folders {
                id
                name
              }
            }
            additionalData
            settings {
              isSyncEnabled
              includeExtensions
              secondaryFilesSetting
              isSyncAllRevisions
              syncType
              scheduleSyncConfig
            }
          }
          destinations {
            syncDetailsId
            nodeId
            connection {
              id
              name
              type
              configuration
              isConnectionUsed
              lastModified
              desktopConnectionId
            }
            hub {
              id
              name
            }
            project {
              id
              name
              folder {
                id
                name
              }
            }
            additionalData
          }
          syncStatus
        }
      }
    }
  }
`;
export const syncRenameWorkflow = /* GraphQL */ `
  mutation SyncRenameWorkflow(
    $query: MutationSyncRenameWorkflowQueryInput
    $body: MutationSyncRenameWorkflowBodyInput
  ) {
    syncRenameWorkflow(query: $query, body: $body)
  }
`;
export const syncDuplicateWorkflow = /* GraphQL */ `
  mutation SyncDuplicateWorkflow(
    $query: MutationSyncDuplicateWorkflowQueryInput
    $body: MutationSyncDuplicateWorkflowBodyInput
  ) {
    syncDuplicateWorkflow(query: $query, body: $body)
  }
`;
export const syncCreateMapping = /* GraphQL */ `
  mutation SyncCreateMapping(
    $query: MutationSyncCreateMappingQueryInput
    $body: MutationSyncCreateMappingBodyInput
  ) {
    syncCreateMapping(query: $query, body: $body) {
      workflowId
      workflowName
      workflowMapping {
        workflowMappingId
        workflowMappingName
        isSyncRunning
        lastSyncAt
        lastSavedAt
        solitaryFlows {
          solitaryFlowId
          action
          actionNodeId
          actionAlias
          source {
            syncDetailsId
            nodeId
            connection {
              id
              name
              type
              configuration
              isConnectionUsed
              lastModified
              desktopConnectionId
            }
            hub {
              id
              name
            }
            projects {
              id
              name
              folders {
                id
                name
              }
            }
            additionalData
            settings {
              isSyncEnabled
              includeExtensions
              secondaryFilesSetting
              isSyncAllRevisions
              syncType
              scheduleSyncConfig
            }
          }
          destinations {
            syncDetailsId
            nodeId
            connection {
              id
              name
              type
              configuration
              isConnectionUsed
              lastModified
              desktopConnectionId
            }
            hub {
              id
              name
            }
            project {
              id
              name
              folder {
                id
                name
              }
            }
            additionalData
          }
          syncStatus
        }
      }
    }
  }
`;
export const syncRenameMapping = /* GraphQL */ `
  mutation SyncRenameMapping(
    $query: MutationSyncRenameMappingQueryInput
    $body: MutationSyncRenameMappingBodyInput
  ) {
    syncRenameMapping(query: $query, body: $body)
  }
`;
export const syncDuplicateMapping = /* GraphQL */ `
  mutation SyncDuplicateMapping(
    $query: MutationSyncDuplicateMappingQueryInput
    $body: MutationSyncDuplicateMappingBodyInput
  ) {
    syncDuplicateMapping(query: $query, body: $body)
  }
`;
export const syncDeleteWorkflow = /* GraphQL */ `
  mutation SyncDeleteWorkflow(
    $query: MutationSyncDeleteWorkflowQueryInput
    $body: MutationSyncDeleteWorkflowBodyInput
  ) {
    syncDeleteWorkflow(query: $query, body: $body)
  }
`;
export const syncArchiveWorkflow = /* GraphQL */ `
  mutation SyncArchiveWorkflow(
    $query: MutationSyncArchiveWorkflowQueryInput
    $body: MutationSyncArchiveWorkflowBodyInput
  ) {
    syncArchiveWorkflow(query: $query, body: $body)
  }
`;
export const syncRestoreWorkflow = /* GraphQL */ `
  mutation SyncRestoreWorkflow(
    $query: MutationSyncRestoreWorkflowQueryInput
    $body: MutationSyncRestoreWorkflowBodyInput
  ) {
    syncRestoreWorkflow(query: $query, body: $body)
  }
`;
export const syncDeactivateWorkflow = /* GraphQL */ `
  mutation SyncDeactivateWorkflow(
    $query: MutationSyncDeactivateWorkflowQueryInput
    $body: MutationSyncDeactivateWorkflowBodyInput
  ) {
    syncDeactivateWorkflow(query: $query, body: $body)
  }
`;
export const syncActivateWorkflow = /* GraphQL */ `
  mutation SyncActivateWorkflow(
    $query: MutationSyncActivateWorkflowQueryInput
    $body: MutationSyncActivateWorkflowBodyInput
  ) {
    syncActivateWorkflow(query: $query, body: $body)
  }
`;
export const syncDeleteWebhook = /* GraphQL */ `
  mutation SyncDeleteWebhook(
    $query: MutationSyncDeleteWebhookQueryInput
    $body: MutationSyncDeleteWebhookBodyInput
  ) {
    syncDeleteWebhook(query: $query, body: $body)
  }
`;
export const syncDeactivateWebhook = /* GraphQL */ `
  mutation SyncDeactivateWebhook(
    $query: MutationSyncDeactivateWebhookQueryInput
    $body: MutationSyncDeactivateWebhookBodyInput
  ) {
    syncDeactivateWebhook(query: $query, body: $body)
  }
`;
export const syncActivateWebhook = /* GraphQL */ `
  mutation SyncActivateWebhook(
    $query: MutationSyncActivateWebhookQueryInput
    $body: MutationSyncActivateWebhookBodyInput
  ) {
    syncActivateWebhook(query: $query, body: $body)
  }
`;
export const syncDeleteTemplate = /* GraphQL */ `
  mutation SyncDeleteTemplate(
    $query: MutationSyncDeleteTemplateQueryInput
    $body: MutationSyncDeleteTemplateBodyInput
  ) {
    syncDeleteTemplate(query: $query, body: $body)
  }
`;
export const syncRenameTemplate = /* GraphQL */ `
  mutation SyncRenameTemplate(
    $query: MutationSyncRenameTemplateQueryInput
    $body: MutationSyncRenameTemplateBodyInput
  ) {
    syncRenameTemplate(query: $query, body: $body)
  }
`;
export const syncDuplicateTemplate = /* GraphQL */ `
  mutation SyncDuplicateTemplate(
    $query: MutationSyncDuplicateTemplateQueryInput
    $body: MutationSyncDuplicateTemplateBodyInput
  ) {
    syncDuplicateTemplate(query: $query, body: $body)
  }
`;
export const addTask = /* GraphQL */ `
  mutation AddTask(
    $query: MutationAddTaskQueryInput
    $body: MutationAddTaskBodyInput
  ) {
    addTask(query: $query, body: $body) {
      taskId
      taskTypeId
      billOfProcessId
      workRequestItemId
      taskStatusTypeId
      taskPredecessorIds {
        taskId
        taskName
        taskStatusTypeId
      }
      taskPredecessorDeletedIds
      taskTypeIds
      taskName
      taskDescription
      taskStatusTypeName
      sortOrder
      isEnabled
      createdOn
      time {
        totalTime
        events {
          taskStatusTypeId
          description
          timesStamp
          userId
        }
      }
      hasDocuments
      hasDrawingRegisterDocuments
      hasCommonDocuments
      hasExternalDocuments
      quantity
      needBy
    }
  }
`;
export const updateTask = /* GraphQL */ `
  mutation UpdateTask(
    $params: MutationUpdateTaskParamsInput!
    $query: MutationUpdateTaskQueryInput
    $body: MutationUpdateTaskBodyInput
  ) {
    updateTask(params: $params, query: $query, body: $body) {
      taskId
      taskTypeId
      billOfProcessId
      workRequestItemId
      taskStatusTypeId
      taskPredecessorIds {
        taskId
        taskName
        taskStatusTypeId
      }
      taskPredecessorDeletedIds
      taskTypeIds
      taskName
      taskDescription
      taskStatusTypeName
      sortOrder
      isEnabled
      createdOn
      time {
        totalTime
        events {
          taskStatusTypeId
          description
          timesStamp
          userId
        }
      }
      hasDocuments
      hasDrawingRegisterDocuments
      hasCommonDocuments
      hasExternalDocuments
      quantity
      needBy
    }
  }
`;
export const updateTaskMultiple = /* GraphQL */ `
  mutation UpdateTaskMultiple(
    $query: MutationUpdateTaskMultipleQueryInput
    $body: MutationUpdateTaskMultipleBodyInput
  ) {
    updateTaskMultiple(query: $query, body: $body)
  }
`;
export const deleteTask = /* GraphQL */ `
  mutation DeleteTask($params: MutationDeleteTaskParamsInput!) {
    deleteTask(params: $params)
  }
`;
export const deleteSourcePad = /* GraphQL */ `
  mutation DeleteSourcePad($params: MutationDeleteSourcePadParamsInput!) {
    deleteSourcePad(params: $params)
  }
`;
export const createAutodeskFieldFolder = /* GraphQL */ `
  mutation CreateAutodeskFieldFolder(
    $query: MutationCreateAutodeskFieldFolderQueryInput
    $body: MutationCreateAutodeskFieldFolderBodyInput
  ) {
    createAutodeskFieldFolder(query: $query, body: $body) {
      success
    }
  }
`;
export const createAutodeskForgeFolder = /* GraphQL */ `
  mutation CreateAutodeskForgeFolder(
    $query: MutationCreateAutodeskForgeFolderQueryInput
    $body: MutationCreateAutodeskForgeFolderBodyInput
  ) {
    createAutodeskForgeFolder(query: $query, body: $body) {
      success
      name
    }
  }
`;
export const createProcoreFolder = /* GraphQL */ `
  mutation CreateProcoreFolder(
    $params: MutationCreateProcoreFolderParamsInput!
    $query: MutationCreateProcoreFolderQueryInput
    $body: MutationCreateProcoreFolderBodyInput
  ) {
    createProcoreFolder(params: $params, query: $query, body: $body) {
      success
    }
  }
`;
export const createSharepointFolder = /* GraphQL */ `
  mutation CreateSharepointFolder(
    $query: MutationCreateSharepointFolderQueryInput
    $body: MutationCreateSharepointFolderBodyInput
  ) {
    createSharepointFolder(query: $query, body: $body)
  }
`;
export const createNewformaFolder = /* GraphQL */ `
  mutation CreateNewformaFolder(
    $query: MutationCreateNewformaFolderQueryInput
    $body: MutationCreateNewformaFolderBodyInput
  ) {
    createNewformaFolder(query: $query, body: $body)
  }
`;
export const syncUpdateWorkflowCanvas = /* GraphQL */ `
  mutation SyncUpdateWorkflowCanvas(
    $query: MutationSyncUpdateWorkflowCanvasQueryInput
    $body: MutationSyncUpdateWorkflowCanvasBodyInput
  ) {
    syncUpdateWorkflowCanvas(query: $query, body: $body)
  }
`;
export const syncUpdateMappingConfigurations = /* GraphQL */ `
  mutation SyncUpdateMappingConfigurations(
    $query: MutationSyncUpdateMappingConfigurationsQueryInput
    $body: MutationSyncUpdateMappingConfigurationsBodyInput
  ) {
    syncUpdateMappingConfigurations(query: $query, body: $body)
  }
`;
export const syncUpdatePublishActionConfigurations = /* GraphQL */ `
  mutation SyncUpdatePublishActionConfigurations(
    $query: MutationSyncUpdatePublishActionConfigurationsQueryInput
    $body: MutationSyncUpdatePublishActionConfigurationsBodyInput
  ) {
    syncUpdatePublishActionConfigurations(query: $query, body: $body)
  }
`;
export const syncCreateMappingTemplate = /* GraphQL */ `
  mutation SyncCreateMappingTemplate(
    $query: MutationSyncCreateMappingTemplateQueryInput
    $body: MutationSyncCreateMappingTemplateBodyInput
  ) {
    syncCreateMappingTemplate(query: $query, body: $body) {
      templateId
      templateName
    }
  }
`;
export const syncWorkflowsImport = /* GraphQL */ `
  mutation SyncWorkflowsImport(
    $query: MutationSyncWorkflowsImportQueryInput
    $body: MutationSyncWorkflowsImportBodyInput
  ) {
    syncWorkflowsImport(query: $query, body: $body) {
      workflows {
        workflowName
        workflowId
        workflowError
        mappings {
          mappingName
          mappingId
          canvasNode
          mappingError
          solitaryFlowConfigurations {
            solitaryFlowId
            action
            actionNodeId
            actionAlias
            source {
              syncDetailsId
              nodeId
              connectionId
              hub {
                id
                name
              }
              projects {
                id
                name
              }
              additionalData
              settings {
                isSyncEnabled
                includeExtensions
                secondaryFilesSetting
                isSyncAllRevisions
                syncType
                scheduleSyncConfig
              }
              connectionName
            }
            destinations {
              syncDetailsId
              nodeId
              connectionId
              hub {
                id
                name
              }
              project {
                id
                name
              }
              additionalData
              connectionName
            }
            solitaryFlowError
          }
          shouldOverwrite
        }
      }
    }
  }
`;
export const syncMultipleWorkflows = /* GraphQL */ `
  mutation SyncMultipleWorkflows(
    $query: MutationSyncMultipleWorkflowsQueryInput
    $body: MutationSyncMultipleWorkflowsBodyInput
  ) {
    syncMultipleWorkflows(query: $query, body: $body) {
      workflows {
        workflowId
        mappings {
          mappingId
          solitaryFlowSettings {
            solitaryFlowId
            isSyncEnabled
            syncType
            scheduleSyncConfig
            syncStatus
          }
        }
      }
    }
  }
`;
export const syncMigratedWorkflows = /* GraphQL */ `
  mutation SyncMigratedWorkflows(
    $query: MutationSyncMigratedWorkflowsQueryInput
    $body: MutationSyncMigratedWorkflowsBodyInput
  ) {
    syncMigratedWorkflows(query: $query, body: $body)
  }
`;
export const syncDeleteMappingData = /* GraphQL */ `
  mutation SyncDeleteMappingData(
    $query: MutationSyncDeleteMappingDataQueryInput
    $body: MutationSyncDeleteMappingDataBodyInput
  ) {
    syncDeleteMappingData(query: $query, body: $body)
  }
`;
export const addDrawingTypeCategory = /* GraphQL */ `
  mutation AddDrawingTypeCategory(
    $query: MutationAddDrawingTypeCategoryQueryInput
    $body: MutationAddDrawingTypeCategoryBodyInput
  ) {
    addDrawingTypeCategory(query: $query, body: $body) {
      companyId
      userDefinedId
      name
      hasDrawingTypes
      id
      createdBy
      createdOnUtc
      modifiedBy
      modifiedOnUtc
    }
  }
`;
export const addTaskDocument = /* GraphQL */ `
  mutation AddTaskDocument(
    $query: MutationAddTaskDocumentQueryInput
    $body: MutationAddTaskDocumentBodyInput
  ) {
    addTaskDocument(query: $query, body: $body) {
      taskDocumentId
      taskId
      documentId
      externalUrl
      createdBy
      createdOn
      documentTypeId
    }
  }
`;
export const deleteTaskDocument = /* GraphQL */ `
  mutation DeleteTaskDocument($params: MutationDeleteTaskDocumentParamsInput!) {
    deleteTaskDocument(params: $params)
  }
`;
export const syncCreateTemplateFromMapping = /* GraphQL */ `
  mutation SyncCreateTemplateFromMapping(
    $query: MutationSyncCreateTemplateFromMappingQueryInput
    $body: MutationSyncCreateTemplateFromMappingBodyInput
  ) {
    syncCreateTemplateFromMapping(query: $query, body: $body)
  }
`;
export const syncDeleteMappingFlow = /* GraphQL */ `
  mutation SyncDeleteMappingFlow(
    $query: MutationSyncDeleteMappingFlowQueryInput
    $body: MutationSyncDeleteMappingFlowBodyInput
  ) {
    syncDeleteMappingFlow(query: $query, body: $body)
  }
`;
export const syncFlowNow = /* GraphQL */ `
  mutation SyncFlowNow(
    $query: MutationSyncFlowNowQueryInput
    $body: MutationSyncFlowNowBodyInput
  ) {
    syncFlowNow(query: $query, body: $body)
  }
`;
export const syncMultipleFlowNow = /* GraphQL */ `
  mutation SyncMultipleFlowNow(
    $query: MutationSyncMultipleFlowNowQueryInput
    $body: MutationSyncMultipleFlowNowBodyInput
  ) {
    syncMultipleFlowNow(query: $query, body: $body)
  }
`;
export const syncStopAll = /* GraphQL */ `
  mutation SyncStopAll(
    $query: MutationSyncStopAllQueryInput
    $body: MutationSyncStopAllBodyInput
  ) {
    syncStopAll(query: $query, body: $body)
  }
`;
export const syncSaveAll = /* GraphQL */ `
  mutation SyncSaveAll(
    $query: MutationSyncSaveAllQueryInput
    $body: MutationSyncSaveAllBodyInput
  ) {
    syncSaveAll(query: $query, body: $body)
  }
`;
export const addBillOfMaterialLineItemByLBS = /* GraphQL */ `
  mutation AddBillOfMaterialLineItemByLBS(
    $query: MutationAddBillOfMaterialLineItemByLBSQueryInput
    $body: MutationAddBillOfMaterialLineItemByLBSBodyInput
  ) {
    addBillOfMaterialLineItemByLBS(query: $query, body: $body) {
      ern
      locationId
      workPhaseId
      amount {
        assigned {
          individual
          aggregate
        }
        available {
          individual
          aggregate
        }
      }
      parentLocationId
      parentWorkPhases
      children {
        locationId
        workPhaseId
        amount {
          assigned {
            individual
            aggregate
          }
          available {
            individual
            aggregate
          }
        }
      }
      unassignedAmount
      unassignedAmountRemaining
      totalAmount
      totalAmountRemaining
    }
  }
`;
export const addBillOfMaterialLineItemByLBSBulk = /* GraphQL */ `
  mutation AddBillOfMaterialLineItemByLBSBulk(
    $query: MutationAddBillOfMaterialLineItemByLBSBulkQueryInput
    $body: MutationAddBillOfMaterialLineItemByLBSBulkBodyInput
  ) {
    addBillOfMaterialLineItemByLBSBulk(query: $query, body: $body)
  }
`;
export const patchBillOfMaterialLineItemByLBS = /* GraphQL */ `
  mutation PatchBillOfMaterialLineItemByLBS(
    $query: MutationPatchBillOfMaterialLineItemByLBSQueryInput
    $body: MutationPatchBillOfMaterialLineItemByLBSBodyInput
  ) {
    patchBillOfMaterialLineItemByLBS(query: $query, body: $body) {
      ern
      locationId
      workPhaseId
      amount {
        assigned {
          individual
          aggregate
        }
        available {
          individual
          aggregate
        }
      }
      parentLocationId
      parentWorkPhases
      children {
        locationId
        workPhaseId
        amount {
          assigned {
            individual
            aggregate
          }
          available {
            individual
            aggregate
          }
        }
      }
      unassignedAmount
      unassignedAmountRemaining
      totalAmount
      totalAmountRemaining
    }
  }
`;
export const patchBulkBillOfMaterialLineItemByLBS = /* GraphQL */ `
  mutation PatchBulkBillOfMaterialLineItemByLBS(
    $query: MutationPatchBulkBillOfMaterialLineItemByLBSQueryInput
    $body: MutationPatchBulkBillOfMaterialLineItemByLBSBodyInput
  ) {
    patchBulkBillOfMaterialLineItemByLBS(query: $query, body: $body) {
      key
      value
    }
  }
`;
export const patchBulk2BillOfMaterialLineItemByLBS = /* GraphQL */ `
  mutation PatchBulk2BillOfMaterialLineItemByLBS(
    $query: MutationPatchBulk2BillOfMaterialLineItemByLBSQueryInput
    $body: MutationPatchBulk2BillOfMaterialLineItemByLBSBodyInput
  ) {
    patchBulk2BillOfMaterialLineItemByLBS(query: $query, body: $body) {
      key
      value
    }
  }
`;
export const quantitiesCreate = /* GraphQL */ `
  mutation QuantitiesCreate(
    $query: MutationQuantitiesCreateQueryInput
    $body: MutationQuantitiesCreateBodyInput
  ) {
    quantitiesCreate(query: $query, body: $body) {
      ern
      resourceId
      properties {
        companyId
        projectId
      }
      amount
      amountBreakdown {
        amountId
        amount
        children {
          childAmountId
          childResourceERN
        }
      }
      constraints
    }
  }
`;
export const quantitiesPatch = /* GraphQL */ `
  mutation QuantitiesPatch(
    $query: MutationQuantitiesPatchQueryInput
    $body: MutationQuantitiesPatchBodyInput
  ) {
    quantitiesPatch(query: $query, body: $body) {
      ern
      resourceId
      properties {
        companyId
        projectId
      }
      amount
      amountBreakdown {
        amountId
        amount
        children {
          childAmountId
          childResourceERN
        }
      }
      constraints
    }
  }
`;
export const addAssemblyCustomCategory = /* GraphQL */ `
  mutation AddAssemblyCustomCategory(
    $query: MutationAddAssemblyCustomCategoryQueryInput
    $body: MutationAddAssemblyCustomCategoryBodyInput
  ) {
    addAssemblyCustomCategory(query: $query, body: $body) {
      assemblyCustomCategoryId
      workFlowId
      companyId
      assemblyCustomCategoryName
    }
  }
`;
export const updateAssemblyCustomCategory = /* GraphQL */ `
  mutation UpdateAssemblyCustomCategory(
    $params: MutationUpdateAssemblyCustomCategoryParamsInput!
    $query: MutationUpdateAssemblyCustomCategoryQueryInput
    $body: MutationUpdateAssemblyCustomCategoryBodyInput
  ) {
    updateAssemblyCustomCategory(params: $params, query: $query, body: $body) {
      assemblyCustomCategoryId
      workFlowId
      companyId
      assemblyCustomCategoryName
    }
  }
`;
export const deleteAssemblyCustomCategory = /* GraphQL */ `
  mutation DeleteAssemblyCustomCategory(
    $params: MutationDeleteAssemblyCustomCategoryParamsInput!
  ) {
    deleteAssemblyCustomCategory(params: $params)
  }
`;
export const syncMakeForesiteFolder = /* GraphQL */ `
  mutation SyncMakeForesiteFolder(
    $query: MutationSyncMakeForesiteFolderQueryInput
    $body: MutationSyncMakeForesiteFolderBodyInput
  ) {
    syncMakeForesiteFolder(query: $query, body: $body) {
      success
    }
  }
`;
export const addBOMItemCustomAssembly = /* GraphQL */ `
  mutation AddBOMItemCustomAssembly(
    $query: MutationAddBOMItemCustomAssemblyQueryInput
    $body: MutationAddBOMItemCustomAssemblyBodyInput
  ) {
    addBOMItemCustomAssembly(query: $query, body: $body) {
      lineItemId
      lineItemTypeId
      lineItemTypeName
      projectId
      customAssemblyName
      customAssemblyIdentifier
      assemblyCustomCategoryId
      assemblyCustomCategoryName
      customAssemblyStatus
      assignedto
      assignedtoFirstName
      assignedtoLastName
      assignedtoEmail
      quantity
      createdOn
      createdBy
      createdByFirstName
      createdByLastName
      createdByEmail
      hasChildren
    }
  }
`;
export const deleteBulkBOMItemCustomAssembly = /* GraphQL */ `
  mutation DeleteBulkBOMItemCustomAssembly(
    $query: MutationDeleteBulkBOMItemCustomAssemblyQueryInput
    $body: MutationDeleteBulkBOMItemCustomAssemblyBodyInput
  ) {
    deleteBulkBOMItemCustomAssembly(query: $query, body: $body)
  }
`;
export const deleteBOMItemCustomAssembly = /* GraphQL */ `
  mutation DeleteBOMItemCustomAssembly(
    $params: MutationDeleteBOMItemCustomAssemblyParamsInput!
  ) {
    deleteBOMItemCustomAssembly(params: $params)
  }
`;
export const syncCreateWindowsFolder = /* GraphQL */ `
  mutation SyncCreateWindowsFolder(
    $query: MutationSyncCreateWindowsFolderQueryInput
    $body: MutationSyncCreateWindowsFolderBodyInput
  ) {
    syncCreateWindowsFolder(query: $query, body: $body) {
      success
    }
  }
`;
export const addWorkOrderItem = /* GraphQL */ `
  mutation AddWorkOrderItem(
    $query: MutationAddWorkOrderItemQueryInput
    $body: MutationAddWorkOrderItemBodyInput
  ) {
    addWorkOrderItem(query: $query, body: $body) {
      workRequestItemId
      hasShopTask
      workOrderItemId
      workOrderId
      workOrderItemTypeId
      unitOfMeasureId
      billOfProcessId
      workOrderItemName
      WorkOrderItemIdentification
      quantity
      unityQuantity
      assignedQuantity
      remainingQuantity
      createdOn
      createdBy
      workOrderItemStatusTypeId
    }
  }
`;
export const addWorkOrderItemFromCatalog = /* GraphQL */ `
  mutation AddWorkOrderItemFromCatalog(
    $query: MutationAddWorkOrderItemFromCatalogQueryInput
    $body: MutationAddWorkOrderItemFromCatalogBodyInput
  ) {
    addWorkOrderItemFromCatalog(query: $query, body: $body) {
      workRequestItemId
      hasShopTask
      workOrderItemId
      workOrderId
      workOrderItemTypeId
      unitOfMeasureId
      billOfProcessId
      workOrderItemName
      WorkOrderItemIdentification
      quantity
      unityQuantity
      assignedQuantity
      remainingQuantity
      createdOn
      createdBy
      workOrderItemStatusTypeId
    }
  }
`;
export const updateWorkOrderItem = /* GraphQL */ `
  mutation UpdateWorkOrderItem(
    $params: MutationUpdateWorkOrderItemParamsInput!
    $query: MutationUpdateWorkOrderItemQueryInput
    $body: MutationUpdateWorkOrderItemBodyInput
  ) {
    updateWorkOrderItem(params: $params, query: $query, body: $body) {
      workRequestItemId
      hasShopTask
      workOrderItemId
      workOrderId
      workOrderItemTypeId
      unitOfMeasureId
      billOfProcessId
      workOrderItemName
      WorkOrderItemIdentification
      quantity
      unityQuantity
      assignedQuantity
      remainingQuantity
      createdOn
      createdBy
      workOrderItemStatusTypeId
    }
  }
`;
export const deleteWorkOrderItem = /* GraphQL */ `
  mutation DeleteWorkOrderItem(
    $params: MutationDeleteWorkOrderItemParamsInput!
  ) {
    deleteWorkOrderItem(params: $params)
  }
`;
export const syncRenameAction = /* GraphQL */ `
  mutation SyncRenameAction(
    $query: MutationSyncRenameActionQueryInput
    $body: MutationSyncRenameActionBodyInput
  ) {
    syncRenameAction(query: $query, body: $body)
  }
`;
