import React from 'react';

import MenuItem from '@mui/material/MenuItem';

import { CenteredLoadSpinner } from 'components';

const MenuItemWithSpinner = ({ disabled, loading, onClick, children }) => (
  <MenuItem disabled={loading || disabled} onClick={onClick}>
    {children}
    {loading && (
      <CenteredLoadSpinner
        sx={{
          mt: 0,
          ml: 1,
          '& .MuiCircularProgress-root': {
            height: '15px !important',
            width: '15px !important',
          },
        }}
      />
    )}
  </MenuItem>
);

export default MenuItemWithSpinner;
