import { useMemo } from 'react';

import { DeviceSizes } from 'constants/globalConstants';

import useBreakPoint from './useBreakpoint';

export default function useDeviceSize() {
  const { isMobile, isAboveTablet, isDesktop } = useBreakPoint();

  const deviceSize = useMemo(() => {
    if (isAboveTablet && !isDesktop) return DeviceSizes.tablet;
    if (isMobile) return DeviceSizes.mobile;
    return DeviceSizes.desktop;
  }, [isMobile, isAboveTablet, isDesktop]);

  return { deviceSize };
}
