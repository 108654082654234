import React, { forwardRef } from 'react';

import { Avatar } from '@mui/material';

import { Custom } from 'components/Icons';
import RoundedIconButton from 'components/RoundedIconButton';
import { otherColors } from 'styles/theme/colors';

import { useProfilePictureContext } from '../ProfilePictureContext';

const ProfileButtonWithArrow = forwardRef(({ open, onClick }, ref) => {
  const { profilePicture } = useProfilePictureContext();

  return (
    <RoundedIconButton
      ref={ref}
      aria-controls={open ? 'menu-list-grow' : undefined}
      aria-haspopup="true"
      onClick={onClick}
      color="inherit"
      size="small"
    >
      <Custom.ArrowDropDown fill={otherColors.white} />
      {!profilePicture ? (
        <Custom.AccountCircle />
      ) : (
        <Avatar alt="profile" src={profilePicture} sx={{ width: 24, height: 24 }} />
      )}
    </RoundedIconButton>
  );
});

export default ProfileButtonWithArrow;
