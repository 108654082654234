import React from 'react';

import { Drawer, IconButton, Box } from '@mui/material';

import { Custom } from 'components/Icons';
import { otherColors } from 'styles/theme/colors';

import MobileProfileDropdownContent from './HelpDeskSliderMenuContent';

const HelpDeskSliderMenu = ({ open, onClose }) => (
  <SliderMenuContainer open={open} onClose={onClose}>
    <CloseButton onClose={onClose} />
    <MobileProfileDropdownContent />
  </SliderMenuContainer>
);

export default HelpDeskSliderMenu;

const CloseButton = ({ onClose }) => (
  <Box textAlign="right">
    <IconButton color="inherit" onClick={onClose} size="large">
      <Custom.Close fill={otherColors.white} />
    </IconButton>
  </Box>
);

const SliderMenuContainer = ({ open, onClose, children }) => (
  <Drawer
    anchor="bottom"
    open={open}
    onClose={onClose}
    sx={{
      zIndex: 9001,
      '& .MuiPaper-root': {
        height: 'calc(100% - 16px)',
        backgroundColor: (theme) => theme.palette.grey[800],
        borderRadius: '25px 25px 0px 0px',
        color: '#ffffff',
      },
      '& .content': {
        padding: '18px 35px 0px 35px',
        '& .user': {
          display: 'flex',
          marginBottom: (theme) => theme.spacing(5),
          '& .user-info': {
            marginLeft: '16px',
          },
        },
      },
    }}
  >
    {children}
  </Drawer>
);
