import React from 'react';

import { ForesiteTextField } from '@evolvemep/foresite-components-ui-react';
import { Box, ClickAwayListener, Divider, Grid, IconButton, Stack } from '@mui/material';
import { Control, Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { Custom } from 'components/Icons';
import { POSITION_TITLE } from 'constants/globalConstants';
import { actionColors } from 'styles/theme/colors';

import { useTaskViewerContext } from '../../TaskViewerContext';
import { Document, TaskWrWoAttachmentsDocuments } from '../types/Document';
import FileRowComponent from './FileRowComponent/FileRowComponent';

type IFormInputs = {
  externalUrl: string;
};

type AddLinkProps = {
  onClickAway: () => void;
  onSubmit: SubmitHandler<IFormInputs>;
  control: Control<IFormInputs>;
  setIsAddLink: (isAddLink: boolean) => void;
  handleSubmit: (values: any) => any;
  darkTheme?: boolean;
  containerProps?: any;
  inputSx?: any;
};

const TaskWrWoAttachments = ({
  documents,
  section,
  setIsAddLink = () => {},
  isAddLink,
}: TaskWrWoAttachmentsDocuments) => {
  const { control, handleSubmit, reset } = useForm<IFormInputs>();

  const { addTaskByDocument } = useTaskViewerContext();
  const { id: taskId } = useParams();

  const onSubmit: SubmitHandler<IFormInputs> = ({ externalUrl }, event) => {
    setIsAddLink(false);
    addTaskByDocument({ taskId, externalUrl });
    reset();
    event?.preventDefault();
  };

  const handleClickAway = () => {
    handleSubmit(onSubmit)();
  };

  return (
    <Stack sx={{ MaxHeight: 461 }}>
      <Box sx={{ mr: 0.5, mt: 1, overflowY: 'overlay' }}>
        {documents.map((document: Document) => (
          <Stack key={uuidv4()}>
            <FileRowComponent document={document} section={section} />
          </Stack>
        ))}
      </Box>
      {section === POSITION_TITLE.tasks && isAddLink && (
        <>
          <AddLinkComponent
            onClickAway={handleClickAway}
            onSubmit={onSubmit}
            control={control}
            setIsAddLink={setIsAddLink}
            handleSubmit={handleSubmit}
          />
          <Divider light sx={{ mr: 2, my: 1 }} />
        </>
      )}
    </Stack>
  );
};

export const AddLinkComponent = ({
  onClickAway,
  onSubmit,
  control,
  setIsAddLink,
  handleSubmit,
  darkTheme,
  containerProps,
  inputSx,
}: AddLinkProps) => (
  <ClickAwayListener onClickAway={onClickAway}>
    <Grid container alignItems="center" {...containerProps}>
      <Grid item>
        <IconButton color="inherit" size="small" onClick={() => setIsAddLink(false)}>
          <Custom.Close fill={actionColors.lightSurface.active} />
        </IconButton>
      </Grid>
      <Grid item xs>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="externalUrl"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <ForesiteTextField
                defaultValue=""
                maxLength={null}
                helperText=""
                error={false}
                size="small"
                label=""
                disabled={false}
                margin="none"
                fullWidth={false}
                sx={{
                  width: '93%',
                  '& .MuiInputBase-input': {
                    fontSize: '12px',
                    padding: '6px 8px',
                    overflow: 'hidden',
                  },
                  '& .MuiInputLabel-root': {
                    fontSize: '12px',
                  },
                  backgroundColor: darkTheme ? 'white' : 'transparent',
                  ...inputSx,
                }}
                autoFocus
                {...field}
              />
            )}
          />
        </form>
      </Grid>
    </Grid>
  </ClickAwayListener>
);

export default TaskWrWoAttachments;
