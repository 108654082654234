import React, { useRef, useState } from 'react';

import { Box, Fade, Popper, styled } from '@mui/material';

const styles = {
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
};

const StyledPopper = styled(Popper)(() => ({
  position: 'relative',
  zIndex: 500,
  maxWidth: '375px',
  width: '100%',
  '&[data-popper-placement*="bottom"] .arrow': {
    top: 0,
    left: 'calc(50% - 1.5em)',
    marginTop: '-1em',
    width: '3em',
    height: '1em',
    '&::before': {
      borderWidth: '0 1em 1em 1em',
      borderColor: `transparent transparent #303030F2 transparent`,
    },
  },
  '&[data-popper-placement*="top"] .arrow': {
    bottom: 0,
    left: 'calc(50% - 1.5em)',
    marginBottom: '-1em',
    width: '3em',
    height: '1em',
    '&::before': {
      borderWidth: '1em 1em 0 1em',
      borderColor: `#303030F2 transparent transparent transparent`,
    },
  },
}));

const PopperWithArrow = ({ open, anchorEl, children, ...props }) => {
  const [arrowRef, setArrowRef] = useState(null);

  const boxRef = useRef(null);

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? 'transition-popper' : undefined;

  return (
    <StyledPopper
      id={id}
      open={open}
      anchorEl={anchorEl}
      transition
      modifiers={[
        {
          name: 'arrow',
          enabled: true,
          options: { element: arrowRef },
        },
      ]}
      {...props}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Box ref={boxRef}>
            <Box component="span" className="arrow" ref={setArrowRef} sx={styles.arrow} />
            {children}
          </Box>
        </Fade>
      )}
    </StyledPopper>
  );
};

export default PopperWithArrow;
