import React from 'react';

import Stack from '@mui/material/Stack';

import BackBar from './BackBar';
import { ActiveMessagesProvider, ArchiveMessagesProvider, MessagesProvider } from './contexts';
import MessagePreview from './MessagePreview';
import MessagesListBar from './MessagesList/MessagesListBar';

const MessageCenter = () => (
  <MessagesProvider>
    <ActiveMessagesProvider>
      <ArchiveMessagesProvider>
        <Stack width="100%">
          <BackBar />
          <MainContentWrapper>
            <MessagesListBar />
            <MessagePreview />
          </MainContentWrapper>
        </Stack>
      </ArchiveMessagesProvider>
    </ActiveMessagesProvider>
  </MessagesProvider>
);

export default MessageCenter;

const MainContentWrapper = ({ children }) => (
  <Stack
    sx={{
      flex: 1,
      overflowY: 'hidden',
      height: 'calc(100% - 40px)',
    }}
    direction="row"
  >
    {children}
  </Stack>
);
