import React, { useEffect, useState } from 'react';

import { EIconType } from 'helpers/MenuIcons';
import CollapseChildrenIcon from 'modules/Field/LocationsAndWorkPhases/components/CollapseChildrenIcon';

import MiniSpinner from '../../../../../../../components/MiniSpinner';
import { ROW_TYPES } from '../../constants/constants';
import { useDataContext } from '../../contexts/DataContext';
import { fillBorderTopStyles } from '../../helpers/helpers';
import RowTitle from '../RowTitle';
import StyledIcon from '../StyledIcon';
import StyledRow from '../StyledRow';
import { useWorkCell } from '../WorkCellRow/contexts/WorkCellContext';
import TaskRow from './TaskRow';

const TasksContainer = () => {
  const { workCell, disabled, handleFetchWorkCellTasks, workCellTasks, loadingWorkCellTasks } = useWorkCell();
  const { activeRow, setActiveRow, setCloseFlyoutMenu, isDragging } = useDataContext();
  const [isOpen, setIsOpen] = useState(false);

  const mappedContainer = {
    id: `${ROW_TYPES.TASK_TYPES}_${workCell.workCellId}`,
  };

  const handleSelectRow = () => {
    setActiveRow(mappedContainer);
    setCloseFlyoutMenu(true);
  };

  const fillOpacity = (() => {
    if (disabled || !workCell.hasTaskTypes) return 0.25;
    return isOpen ? 0.87 : 0.54;
  })();

  useEffect(() => {
    if (isOpen) handleFetchWorkCellTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <>
      <StyledRow
        isActive={mappedContainer.id === activeRow.id}
        onClick={() => {
          if (!disabled) handleSelectRow();
        }}
        sx={{
          position: 'relative',
          pl: 7,
          ...fillBorderTopStyles(mappedContainer.id === activeRow.id, isDragging),
        }}
        testId="tasks-container-row"
      >
        <CollapseChildrenIcon
          onClick={() => setIsOpen(!isOpen)}
          open={isOpen}
          showIcon
          filled
          sx={{ path: { color: 'black', fillOpacity } }}
          disabled={disabled || !workCell.hasTaskTypes}
        />

        <StyledIcon iconName={EIconType.SHOP_TASKS_NAV} highlight={isOpen} />
        <RowTitle>Tasks</RowTitle>
      </StyledRow>

      {isOpen && (
        <>
          {workCellTasks.length ? (
            workCellTasks?.map((task) => <TaskRow key={task.workCellTaskTypeId} task={task} />)
          ) : (
            <>{loadingWorkCellTasks && <MiniSpinner showBorderBottom />}</>
          )}
        </>
      )}
    </>
  );
};

export default TasksContainer;
