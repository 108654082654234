import React from 'react';

import KanbanColumn from './KanbanColumn';
import KanbanGrid from './KanbanGrid';

const KanbanBoard = ({ children, ...rest }) => <KanbanGrid {...rest}>{children}</KanbanGrid>;

KanbanBoard.Column = KanbanColumn;

export default KanbanBoard;
