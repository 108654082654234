/* eslint-disable max-lines-per-function */
import React, { useRef, useEffect } from 'react';

import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Modal, CancelButton, ControlledDatePicker } from 'components';
import { StatusButton } from 'components/Buttons';
import { Custom } from 'components/Icons';
import { UTCFORMATDAYJS } from 'constants/globalConstants';
import { VARIANT_ERROR } from 'constants/snackbarConstants';
import { isPastDate, TODAY } from 'helpers/dateFunctions';
import useSetupModule from 'hooks/useSetupModule';

import { useUpdateWorkRequest } from '../../WorRequestsProperties/WorkRequestPropertiesAttachments/hooks/useUpdateWorkRequest';
import { SUBMIT_STYLES } from '../constants';

dayjs.extend(utc);

const ModalNeedByDate = ({ openStatus, onClose, workRequest, updateWorkRequestStatus, loadingWRStatus }) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    setValue,
    reset,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    mode: 'all',
    defaultValues: { needBy: null },
  });
  const { updateWorkRequest, loading } = useUpdateWorkRequest();
  const date = useRef();
  const navigate = useNavigate();

  const { currentModule } = useSetupModule();
  const module = currentModule() ?? 'field';

  const handleCancel = () => {
    reset();
    onClose();
  };

  const submit = async (data) => {
    try {
      if (!isValid || isSubmitting) return;
      const { needBy } = data;
      const body = { needBy: needBy.local().endOf('day').utc().format(UTCFORMATDAYJS) };
      await updateWorkRequest(workRequest.workRequestId, body);
      await updateWorkRequestStatus(workRequest, 'Submitted', workRequest.workRequestDescription, () =>
        navigate(`/${module}/work-requests`),
      );
    } catch (error) {
      enqueueSnackbar('Could not save changes.', VARIANT_ERROR);
    } finally {
      handleCancel();
    }
  };

  const validate = (newDate) => {
    if (typeof newDate?.isValid === 'function' && !newDate.isValid()) return 'Invalid date';
    if (isPastDate(newDate) && newDate !== null) return 'Invalid date';
    date.current = newDate;
    return true;
  };

  useEffect(() => {
    if (workRequest) {
      reset();
      setValue('needBy', dayjs(`${workRequest.needBy}Z`));
    }
  }, [workRequest, workRequest?.needBy, setValue, reset]);

  return (
    <Modal
      open={openStatus}
      onClose={handleCancel}
      title="Add need by date"
      reducePadding
      fullWidth
      maxWidth="xs"
      closeButtonColor="#6a6a6a"
      titleStyles={{ fontWeight: 500 }}
      closeButtonStyles={{
        padding: 0,
        marginRight: '-4px',
      }}
      footer={
        <>
          <CancelButton onClick={handleCancel} color="secondary.dark" disabled={loading || !!loadingWRStatus} />
          <StatusButton
            buttonText="Done"
            sx={SUBMIT_STYLES}
            disabled={!!errors?.needBy || loading || !!loadingWRStatus || !isValid}
            onClick={async () => handleSubmit(async (data) => submit(data))()}
          />
        </>
      }
    >
      <Typography variant="subtitle2" sx={{ marginBottom: '8px' }}>
        Need by date is required to submit a work request
      </Typography>
      <ControlledDatePicker
        name="needBy"
        autoComplete="off"
        disabled={loading || !!loadingWRStatus || isSubmitting}
        pickerIcon={Custom.CalendarTodayOutlined}
        control={control}
        hideErrorMessage={false}
        minDate={TODAY}
        label="Need By Date*"
        size="small"
        fullWidth
        color="secondary"
        PaperProps={{
          sx: {
            '& .MuiPickersDay-today': { borderColor: 'green !important' },
            '& .Mui-selected': { backgroundColor: 'green !important' },
          },
        }}
        rules={{ validate, required: '*Required' }}
      />
    </Modal>
  );
};

export default ModalNeedByDate;
