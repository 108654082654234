import React, { useState } from 'react';

import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useUser } from 'app/UserContext';
import { TabBar, CenteredLoadSpinner } from 'components';
import { useGetCompany } from 'hooks-api/useGetCompany';

import Admin from './AdminTab/AdminTab';
import InfoForm from './InfoTab/InfoForm';

const CompanyProperties = () => {
  const [activeTabIndex, setTabIndex] = useState(0);
  const { user } = useUser();
  const { data, loading } = useGetCompany({ companyId: user?.companyId });

  const handleTabChange = (e, newIndex) => {
    setTabIndex(newIndex);
  };

  if (loading || !data) return <CenteredLoadSpinner sx={{ marginTop: '250px' }} />;

  const company = data;

  return (
    <>
      <HeaderWrapperSpaceBetween>
        <Stack direction="row">
          <CatalogTitle />
          <TabBar value={activeTabIndex} onChange={handleTabChange} />
        </Stack>
      </HeaderWrapperSpaceBetween>
      <>
        <InfoForm company={company} />
        <Admin canAddAdmin title="Company Administrators" />
      </>
    </>
  );
};

export default CompanyProperties;

const HeaderWrapperSpaceBetween = ({ children }) => (
  <Stack direction="row" justifyContent="space-between" mb={0}>
    {children}
  </Stack>
);

const CatalogTitle = () => (
  <Box marginTop="4px" mt={0.5}>
    <Typography variant="h5" component="h1">
      Company Properties
    </Typography>
  </Box>
);
