import React from 'react';

import { Stack, Button, CircularProgress, useTheme } from '@mui/material';

const DeleteAttachmentModalFooter = ({ handleClose, handleDelete, loading }) => {
  const CancelButton = () => (
    <Button type="button" sx={{ mr: 1, fontWeight: '500' }} onClick={handleClose}>
      CANCEL
    </Button>
  );

  const ConfirmButton = () => {
    const { palette } = useTheme();
    return (
      <Button
        type="submit"
        variant="contained"
        color="error"
        onClick={handleDelete}
        data-testid="confirm-button"
        sx={{ '&:hover': { backgroundColor: palette.error.containedHoverBackground } }}
      >
        CONFIRM
      </Button>
    );
  };

  return (
    <Stack direction="row">
      <CancelButton />
      {loading ? <Progress /> : <ConfirmButton />}
    </Stack>
  );
};

export default DeleteAttachmentModalFooter;

const Progress = () => (
  <Stack direction="row" width="93px" justifyContent="center">
    <CircularProgress />
  </Stack>
);
