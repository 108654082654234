import React, { useState } from 'react';

const AdminContext = React.createContext();

const AdminProvider = ({ children }) => {
  const [selected, setSelected] = useState([]);

  const selectedStateObj = React.useMemo(
    () => ({
      selected,
      setSelected,
    }),
    [selected, setSelected],
  );

  return <AdminContext.Provider value={selectedStateObj}>{children}</AdminContext.Provider>;
};

const useAdminContext = () => {
  const context = React.useContext(AdminContext);
  if (context === undefined) {
    throw new Error('useAdminContext must be used within an AdminContext');
  }
  return context;
};

export { AdminContext, AdminProvider, useAdminContext };
