import { useEffect, useMemo } from 'react';

import { FILE_TYPE_FORMATS_DR } from 'constants/fileConstants';
import { FILE_FORMATS } from 'constants/globalConstants';
import useDocumentForgeViewer from 'hooks-api/useDocumentForgeViewer';
import useDocumentSignedURLAPI from 'hooks-api/useDocumentSignedURLAPI';

import { useTaskViewerContext } from '../TaskViewerContext';
import useStatusGraphic from './useStatusGraphic';

const PDF_EXTENSIONS = ['.pdf'];

const useGetGraphicType = () => {
  const { signedURL, getSignedURL } = useDocumentSignedURLAPI();

  const { getForgeViewer, forgeViewer } = useDocumentForgeViewer();

  const { loading, error } = useStatusGraphic(forgeViewer);

  const {
    setTypeFileFormat,
    typeFileFormat,
    activeDocument,
    isDocumentsEmpty,
    loadingDrawingDocuments,
    loadingCommonWRWOFiles,
  } = useTaskViewerContext();

  useEffect(() => {
    if (!activeDocument?.documentId) return;
    if (PDF_EXTENSIONS.includes(activeDocument?.extension?.toLowerCase())) {
      setTypeFileFormat(FILE_FORMATS.PDF);
      getSignedURL(activeDocument.documentId);
      return;
    }
    setTypeFileFormat(FILE_FORMATS.ANOTHER_FILE);
    getForgeViewer(activeDocument.documentId);
  }, [activeDocument, getForgeViewer, getSignedURL, setTypeFileFormat]);

  const dynamicProps = useMemo(() => {
    if (typeFileFormat === FILE_FORMATS.PDF)
      return {
        shouldInitializeAuth: false,
        env: 'Local',
        urn: signedURL,
        extensions: ['Autodesk.PDF'],
        accessToken: '',
        getAccessToken: () => null,
        useADP: false,
      };

    if (forgeViewer)
      return {
        env: 'AutodeskProduction',
        api: 'derivativeV2',
        urn: forgeViewer?.encodedUrn,
        accessToken: forgeViewer?.token,
        getAccessToken: (onTokenReady) => {
          onTokenReady(forgeViewer?.token, forgeViewer?.timeInSeconds);
        },
      };
    return null;
  }, [forgeViewer, typeFileFormat, signedURL]);

  const isSupported = useMemo(() => {
    if (!activeDocument) return true;
    return FILE_TYPE_FORMATS_DR.some((formatFile) => `.${formatFile}` === activeDocument?.extension?.toLowerCase());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDocument?.extension]);

  return {
    dynamicProps,
    loading,
    error,
    isSupported,
    isDocumentsEmpty,
    loadingDrawingDocuments,
    loadingCommonWRWOFiles,
  };
};

export default useGetGraphicType;
