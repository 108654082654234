import React from 'react';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import StyledAvatar from 'app/Layout/HeaderBar/components/StyledAvatar';
import { useUser } from 'app/UserContext';
import Divider from 'components/Divider';
import { Custom } from 'components/Icons';

import { useProfilePictureContext } from '../ProfilePictureContext';

const DesktopProfileDropdownContent = ({
  onResetPasswordClicked,
  onLicenseCheckInOutClicked,
  onEditProfileClicked,
  signOut,
}) => {
  const { user, loading } = useUser();
  const { profilePicture } = useProfilePictureContext();

  const { userFirstName, userLastName, personalPhoneNumber, userEmail } = user ?? {};

  const getProfileName = () => {
    if (userFirstName && userLastName) {
      return `${userFirstName} ${userLastName}`;
    }

    return userEmail;
  };

  return (
    <Box textAlign="center" width="240px" padding="16px">
      {loading ? (
        <CircularProgress />
      ) : (
        <ProfileInformation
          name={getProfileName()}
          phoneNumber={personalPhoneNumber || ''}
          profilePicture={profilePicture}
        />
      )}
      <Divider sx={{ margin: 1, height: 2 }} />
      <MenuLink onClick={onEditProfileClicked}>Edit Profile</MenuLink>
      <MenuLink onClick={onResetPasswordClicked}>Reset Password</MenuLink>
      {/* <MenuLink onClick={onTwoFactorAuthClicked}>2-Factor Authentication</MenuLink> */}
      <MenuLink onClick={onLicenseCheckInOutClicked}>License Check-in/Check-out</MenuLink>
      <MenuLink onClick={signOut}>Sign out</MenuLink>
    </Box>
  );
};

export default DesktopProfileDropdownContent;

const ProfileInformation = ({ name, phoneNumber, profilePicture }) => (
  <>
    <Box display="flex" justifyContent="center" marginBottom={1}>
      <StyledAvatar>
        {!profilePicture ? (
          <Custom.AccountCircle />
        ) : (
          <Avatar alt="profile" src={profilePicture} sx={{ width: 80, height: 80 }} />
        )}
      </StyledAvatar>
    </Box>
    <Typography
      align="center"
      color="white"
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {name}
    </Typography>
    <Typography variant="caption" display="block" align="center">
      {phoneNumber}
    </Typography>
    {/* <Typography variant="caption" display="block" align="center">
      {office}
    </Typography> */}
  </>
);

const MenuLink = ({ children, onClick }) => (
  <Box my="20px">
    <Typography variant="body2">
      <Link onClick={onClick} sx={{ color: 'other.links', cursor: 'pointer' }}>
        {children}
      </Link>
    </Typography>
  </Box>
);
