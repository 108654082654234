import { ROW_TYPES } from '../../../constants/constants';
import { useLocationsAndWorkPhases } from '../../../contexts/LocationsAndWorkPhaseContext';
import { childrenIncludeType, createOption } from './moreMenuHelpers';
import { useGeneralOptions } from './useGeneralOptions';

const getZoneOptionsByChildren = (node, addLocation, ascendantIsFloor) => {
  if (childrenIncludeType(node, ROW_TYPES.FLOOR))
    return [
      createOption('New floor', () => addLocation(node, ROW_TYPES.FLOOR)),
      createOption('New location system', () => addLocation(node, ROW_TYPES.LOCATION_SYSTEM)),
    ];

  if (childrenIncludeType(node, ROW_TYPES.ZONE))
    return [
      createOption('New subzone', () => addLocation(node, ROW_TYPES.ZONE)),
      createOption('New location system', () => addLocation(node, ROW_TYPES.LOCATION_SYSTEM)),
    ];

  if (childrenIncludeType(node, ROW_TYPES.LOCATION_SYSTEM))
    return [
      createOption('New location system', () => addLocation(node, ROW_TYPES.LOCATION_SYSTEM)),
    ];

  return [
    createOption('New subzone', () => addLocation(node, ROW_TYPES.ZONE)),
    !ascendantIsFloor && createOption('New floor', () => addLocation(node, ROW_TYPES.FLOOR)),
    createOption('New location system', () => addLocation(node, ROW_TYPES.LOCATION_SYSTEM)),
  ].filter((row) => !!row);
};

export const useZoneOptions = (node, parent, ascendantIsFloor) => {
  const {
    locations: { handleAddLocation },
  } = useLocationsAndWorkPhases();
  const { getGeneralOptions } = useGeneralOptions(node, parent);

  const getZoneOptions = () => [
    ...getZoneOptionsByChildren(node, handleAddLocation, ascendantIsFloor),
    createOption('New zone above', () => handleAddLocation(node, ROW_TYPES.ZONE, 'above')),
    createOption('New zone below', () => handleAddLocation(node, ROW_TYPES.ZONE, 'below')),
    ...getGeneralOptions(),
  ];

  return { getZoneOptions };
};
