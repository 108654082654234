import { useMemo, useCallback } from 'react';

export const useAsyncApi = ({ asyncApi, rows, overrideSortModel } = {}) => {
  const ref = useMemo(() => asyncApi?.ref, [asyncApi]);

  const onRowsScrollEnd = useCallback(() => {
    if (rows?.length && asyncApi?.onScroll) {
      asyncApi?.onScroll();
    }
  }, [asyncApi, rows]);

  const onSortModelChange = useCallback(
    (model) => {
      const params = model[0] ?? {};
      const { field, sort = 'asc' } = params;
      if (asyncApi?.onOrderby && field) {
        const key = overrideSortModel ? overrideSortModel[field] ?? field : field;
        asyncApi?.onOrderby(key, sort);
      }
    },
    [asyncApi, overrideSortModel],
  );

  return { ref, onRowsScrollEnd, onSortModelChange };
};
