import React from 'react';

import { ClickAwayListener } from '@mui/base';
import { Box } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import { useDrawingDocumentContext } from './DrawingDocumentContext';

const DrawingDocumentRowProvider = ({ updateRows, rowData, children }) => {
  const methods = useForm({
    mode: 'all',
    defaultValues: {
      documentIdentifier: rowData.row.documentIdentifier,
      documentName: rowData.row.documentName,
    },
  });

  const {
    handleSubmit,
    formState: { isDirty, isValid, errors },
    watch,
  } = methods;
  const { idDocumentEdited, closeFields, updateDrawing } = useDrawingDocumentContext();

  const updateDrawingInvoke = (data) => {
    updateDrawing(data, updateRows);
  };

  const handleClickAway = (e) => {
    if (rowData.row.isValidating) {
      return;
    }
    if (!isDirty) {
      closeFields(e, idDocumentEdited, updateRows);
      return;
    }
    if (isValid && !errors?.documentIdentifier) {
      updateRows([
        {
          id: idDocumentEdited,
          documentIdentifier: watch('documentIdentifier'),
          documentName: watch('documentName'),
          isProcessing: true,
        },
      ]);
      handleSubmit(updateDrawingInvoke)();
    }

    closeFields(e, idDocumentEdited, updateRows);
  };

  if (!rowData.row.isEditing) {
    return <>{children}</>;
  }

  return (
    <FormProvider {...methods}>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={handleClickAway}
      >
        <Box
          sx={{
            '& .MuiDataGrid-cell': {
              maxHeight: 'none !important',
              minHeight: 'auto !important',
              paddingTop: '3.5px !important',
              paddingBottom: '3.5px !important',
              alignItems: 'flex-start',
              background: 'rgba(0, 0, 0, 0.06)',
            },
            '& .MuiDataGrid-row': {
              maxHeight: 'none !important',
              minHeight: 'auto !important',
            },
            '& .MuiCheckbox-root': {
              display: 'none',
            },
            '& .MuiInputBase-input': {
              fontSize: '14px',
              lineHeight: '24px',
              fontWeight: '400',
              height: '20px',
            },
            '& .MuiSelect-select': {
              minHeight: '20px !important',
            },
            '& .MuiFormLabel-root': {
              fontSize: '14px',
              lineHeight: '20px',
            },
            '& .MuiSelect-select span': {
              lineHeight: '20px',
            },
          }}
        >
          {children}
        </Box>
      </ClickAwayListener>
    </FormProvider>
  );
};

export default DrawingDocumentRowProvider;
