import React from 'react';

import { Skeleton, Stack } from '@mui/material';

export const WaitingElement = () => {
  const array = [1, 2, 3];
  return array.map((e) => (
    <Stack key={e} sx={{ alignItems: 'center', justifyContent: 'center' }}>
      <Skeleton
        variant="rectangular"
        animation="wave"
        sx={{ width: '90%', height: '24px', marginTop: '8px', borderRadius: '4px' }}
      />
    </Stack>
  ));
};
