import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';

import { useUser } from 'app/UserContext';
import Divider from 'components/Divider';
import { Custom } from 'components/Icons';
import StackRow from 'components/StackRow';
import { otherColors } from 'styles/theme/colors';

import StyledAvatar from '../../components/StyledAvatar';

const generateNavItem = (text, path) => ({ text, path });

const navItems = [
  generateNavItem('Messages', '/messages'),
  generateNavItem('Edit Profile', '/edit-profile'),
  generateNavItem('Reset Password', '/reset-password'),
  generateNavItem('2-Factor Authentication', '/2fac-auth'),
  generateNavItem('Support', '/support'),
];

const MobileProfileDropdownContent = () => {
  const { user } = useUser();
  const { firstName, lastName, attributes } = user;

  const getProfileName = () => {
    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    }

    return attributes?.email;
  };

  return (
    <DropdownContentWrapper>
      <ProfileInformation name={getProfileName()} phoneNumber="555-555-5555" />
      {navItems.map(({ text, path }) => (
        <NavigationLink key={text} text={text} path={path} />
      ))}
      <Divider sx={{ marginBottom: '32px' }} />
      <LogoutButton onClick={() => Auth.signOut()} />
    </DropdownContentWrapper>
  );
};

const StyledLink = (props) => (
  <StackRow
    sx={{
      justifyContent: 'space-between',
      mb: 4,
      '& a': {
        color: 'white',
        textDecoration: 'none',
      },
    }}
    {...props}
  />
);

const DarkTypography = (props) => (
  <Typography sx={{ color: 'surface.darkSurface.secondary' }} {...props} />
);

export default MobileProfileDropdownContent;

const DropdownContentWrapper = ({ children }) => <Box padding="18px 35px 0px 35px">{children}</Box>;

const ProfileInformation = ({ name, phoneNumber }) => (
  <Box display="flex" mb={8}>
    <StyledAvatar size="large">
      <Custom.AccountCircle />
    </StyledAvatar>
    <Box ml={2}>
      <Typography style={{ marginBottom: '8px' }}>{name}</Typography>
      <DarkTypography variant="body2" display="block">
        {phoneNumber}
      </DarkTypography>
      {/* <DarkTypography variant="body2" display="block">
        {office}
      </DarkTypography> */}
    </Box>
  </Box>
);

const NavigationLink = ({ text, path }) => (
  <StyledLink>
    <Typography variant="h6" display="block" component={Link} to={path} color="initial">
      {text}
    </Typography>
    <Custom.KeyboardArrowRight fill={otherColors.white} />
  </StyledLink>
);

const LogoutButton = ({ onClick }) => (
  <StyledLink style={{ color: 'rgba(255, 255, 255, 0.6)', cursor: 'pointer' }} onClick={onClick}>
    <Typography
      variant="h6"
      style={{ color: 'rgba(255, 255, 255, 0.6)', cursor: 'pointer' }}
      display="block"
      color="secondary"
    >
      Log Out
    </Typography>
    <Custom.KeyboardArrowRight fill={otherColors.white} />
  </StyledLink>
);
