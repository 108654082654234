import { useMemo } from 'react';

import { convertSecondsToHHMMSS } from 'helpers/dateFunctions';

export default function useCalculateTime(timeData, unityQuantity) {
  const unitTime = useMemo(() => {
    const totalTime = timeData?.time?.split(':');

    if (!totalTime || !timeData.time) return { totalUnitTime: '00:00:00', timeToMilliseconds: 0 };

    const hoursToSeconds = Number(totalTime[0]) * 3600;
    const minutesToSeconds = Number(totalTime[1]) * 60;
    const seconds = Number(totalTime[2]);

    const timeToSeconds = hoursToSeconds + minutesToSeconds + seconds;

    const divisor = !unityQuantity ? 1 : unityQuantity;
    const secondsUnityQuantity = timeToSeconds / divisor;

    const totalUnitTime = convertSecondsToHHMMSS(secondsUnityQuantity);

    return { totalUnitTime, timeToMilliseconds: timeToSeconds * 1000 };
  }, [timeData?.time, unityQuantity]);

  return unitTime;
}
