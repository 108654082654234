/* eslint-disable max-lines-per-function */
import { useCallback, useState } from 'react';

import { gql, useLazyQuery, useMutation } from '@apollo/client';

import { useUser } from 'app/UserContext';
import {
  getDocumentPresignedURL as GET_DOCUMENT_PRESIGNED_URL,
  addFolder as ADD_FOLDER,
  addDocument as ADD_DOCUMENT,
} from 'graphql/mutations';
import { folders as FOLDERS, documentById as DOCUMENT_BY_ID } from 'graphql/queries';

const useUploadImage = () => {
  const { user } = useUser();
  const [getPresignedURL] = useMutation(gql(GET_DOCUMENT_PRESIGNED_URL));
  const [fetchFolders] = useLazyQuery(gql(FOLDERS));
  const [addFolder] = useMutation(gql(ADD_FOLDER));
  const [addDocument] = useMutation(gql(ADD_DOCUMENT));
  const [, { refetch: fetchDocument }] = useLazyQuery(gql(DOCUMENT_BY_ID));
  const [uploadingProgress, setUploadingProgress] = useState(0);

  const uploadImage = useCallback(
    async (imgFile, storagePath, documentId) => {
      try {
        const fileName = imgFile.name;
        const presignedURLResponse = await getPresignedURL({
          variables: {
            body: {
              expirationHours: 24,
              objectKey: `${storagePath}/${fileName}`,
              requestedBy: user.userId,
              verb: 'PUT',
            },
          },
        });
        setUploadingProgress(20);

        const presignedURL = presignedURLResponse.data?.getDocumentPresignedURL?.preSignedURL;
        const contentType = presignedURLResponse.data?.getDocumentPresignedURL?.contentType;

        await fetch(presignedURL, {
          method: 'PUT',
          mode: 'cors',
          body: imgFile,
          headers: {
            'Content-Type': contentType,
          },
        });

        setUploadingProgress(40);

        const {
          data: { folders },
        } = await fetchFolders({
          variables: {
            query: {
              companyId: user.companyId,
              searchPhrase: documentId,
            },
          },
        });
        setUploadingProgress(60);

        let folderId;
        if (folders?.length > 0) {
          folderId = folders[0].folderId;
        } else {
          const newFolder = await addFolder({
            variables: {
              body: {
                companyId: user.companyId,
                folderName: documentId,
                storagePath,
                requestedBy: user.userId,
              },
            },
          });
          folderId = newFolder.data?.addFolder?.folderId;
        }
        setUploadingProgress(100);

        const newDocument = await addDocument({
          variables: {
            body: {
              folderId,
              documentName: fileName,
              storagePath: `${storagePath}/${fileName}`,
              requestedBy: user.userId,
            },
          },
        });

        return newDocument.data?.addDocument?.documentId;
      } catch (error) {
        return null;
      } finally {
        setUploadingProgress(0);
      }
    },
    [addDocument, addFolder, fetchFolders, getPresignedURL, user?.companyId, user?.userId],
  );

  const getImage = useCallback(
    async (imageId) => {
      if (!imageId) return null;
      const FILE_EXTENSIONS = /\.(jpg|jpeg|png|gif|heic|pdf)$/;

      const document = await fetchDocument({
        params: {
          documentId: imageId,
        },
      });
      if (!document.data?.documentById) return null;

      const {
        data: {
          documentById: { storagePath, documentName },
        },
      } = document;

      let filePath;
      if (!storagePath?.match(FILE_EXTENSIONS)) {
        filePath = `${storagePath}/${documentName}`;
      } else {
        filePath = storagePath;
      }

      const presignedURLResponse = await getPresignedURL({
        variables: {
          body: {
            expirationHours: 24,
            objectKey: filePath,
            requestedBy: user.userId,
            verb: 'GET',
          },
        },
      });

      return presignedURLResponse.data?.getDocumentPresignedURL?.preSignedURL;
    },
    [fetchDocument, getPresignedURL, user?.userId],
  );

  return { uploadImage, getImage, uploadingProgress };
};

export default useUploadImage;
