import React from 'react';

import { Box } from '@mui/material';

import { Modal, CancelButton, DeleteButton } from 'components';

const WorkRequestCancelModal = ({ openStatus, onClose, updateStatusToCanceled, identifier }) => {
  const submit = () => {
    updateStatusToCanceled();
    onClose();
  };

  return (
    <Modal
      disableModalOutsideClick
      open={openStatus}
      onClose={onClose}
      title="Confirm Cancel"
      titleStyles={{
        fontWeight: 500,
      }}
      closeButtonStyles={{
        padding: 0,
        marginRight: '-4px',
      }}
      reducePadding
      fullWidth
      maxWidth="xs"
      footer={
        <>
          <CancelButton onClick={onClose} buttonText="No" />
          <DeleteButton onClick={submit} buttonText="Yes" />
        </>
      }
    >
      <Box sx={{ fontSize: '16px' }}>
        <Box sx={{ display: 'inline' }}>
          Are you sure you want to cancel{' '}
          <Box fontWeight="bold" display="inline">
            {identifier}
          </Box>
          ?
        </Box>
      </Box>
    </Modal>
  );
};

export default WorkRequestCancelModal;
