import React from 'react';

import PartItemDetailAccordion from 'modules/Materials/components/PartItemDetailAccordion';
import { AccordionProvider } from 'modules/Materials/components/PartItemDetailAccordion/PartItemDetailAccordionContext';

import Manufacturer from '../Manufacturer/Manufacturer';

const Manufacturers = ({ ...restProps }) => (
  <AccordionProvider>
    <PartItemDetailAccordion>
      <PartItemDetailAccordion.Header>
        <PartItemDetailAccordion.Title>Manufacturer</PartItemDetailAccordion.Title>
        <PartItemDetailAccordion.Subtitle>Add manufacturers</PartItemDetailAccordion.Subtitle>
        <PartItemDetailAccordion.Icon />
      </PartItemDetailAccordion.Header>
      <PartItemDetailAccordion.Content>
        <Manufacturer {...restProps} />
      </PartItemDetailAccordion.Content>
    </PartItemDetailAccordion>
  </AccordionProvider>
);

export default Manufacturers;
