import React, { useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';

import { CenteredLoadSpinner } from 'components';
import KanbanBoard from 'components/KanbanBoard';
import useShopFacility from 'hooks/useShopFacility';
import { DEPARTMENT_TYPE } from 'types/DepartmentTypes';

import NoContentIconMessage from './components/NoContentIconMessage';
import NoRowsOverlay from './components/NoRowsOverlay';
import { useWorkCellsKanban } from './context/WorkCellsContext';
import { useWorkCellsList } from './context/WorkCellsListContext';
import useLoadingTasks from './hooks/useLoadingTasks';
import WorkCellsColumnWithContext from './WorkCellsColumn';

const WorkCellsKanban = ({ isDisabledDragAndDrop }) => {
  const { columnActive } = useWorkCellsKanban();
  const [hasTasks, setHasTasks] = useState(false);
  const { workCells } = useWorkCellsList();
  const { selectedFacility, refetchData, shopData, loading, workCellsData, activeShopConfiguration, fetchInitialData } =
    useShopFacility();
  const { isLoading: isLoadingTasks, onUpdateTasksLoading } = useLoadingTasks(workCellsData);

  useEffect(() => {
    if (activeShopConfiguration?.value) {
      fetchInitialData();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (activeShopConfiguration?.value && selectedFacility) {
      refetchData();
    }
    // eslint-disable-next-line
  }, [activeShopConfiguration, selectedFacility]);

  useEffect(() => {
    if (workCellsData?.workCells && !workCellsData.workCells.length) setHasTasks(false);
  }, [workCellsData?.workCells]);

  const hasShopDepartment = useMemo(
    () =>
      selectedFacility?.departments?.find(
        (department) => department.departmentType.departmentTypeId === DEPARTMENT_TYPE.SHOP,
      ),
    [selectedFacility],
  );

  const noRowsOverlayMessage = useMemo(() => {
    if (workCells?.length === 1) return 'Navigate to the Work Cell Setup to create a shop configuration.';
    if (!activeShopConfiguration) return 'Navigate to the Work Cell Setup to create a shop configuration.';
    return 'This shop configuration has no tasks.';
  }, [activeShopConfiguration, workCells]);

  if (!selectedFacility) return <NoContentIconMessage message="Select a facility to view work cells" />;
  if (loading) return <CenteredLoadSpinner theme="secondary" />;
  if (!hasShopDepartment && !shopData?.shopProperties?.length)
    return (
      <NoContentMessage>
        This facility does not currently have a Shop. <br />
        Add a Shop to continue.
      </NoContentMessage>
    );

  return (
    <Box sx={{ height: hasTasks ? '100%' : null }}>
      <KanbanBoard containerSx={{ paddingBottom: '90px', height: hasTasks ? '100%' : null }}>
        {workCellsData?.workCells?.map((workCell) => {
          const { workCellId } = workCell;
          return (
            <WorkCellsColumnWithContext
              key={workCellId}
              workCell={workCell}
              disabled={columnActive !== null && columnActive !== workCellId}
              setHasTasks={setHasTasks}
              isDisabledDragAndDrop={isDisabledDragAndDrop}
              onUpdateTasksLoading={onUpdateTasksLoading}
            />
          );
        })}
      </KanbanBoard>
      {isLoadingTasks ? (
        <LoadingTaskMessage />
      ) : (
        !hasTasks && (
          <NoRowsOverlay
            message={noRowsOverlayMessage}
            sx={{ width: noRowsOverlayMessage?.length > 60 ? '308px' : '100%' }}
          />
        )
      )}
    </Box>
  );
};

const NoContentMessage = ({ children, text }) => (
  <NoContentIconMessage
    message={
      <Box
        sx={{
          textAlign: 'center',
        }}
      >
        {children ?? text}
      </Box>
    }
  />
);

const LoadingTaskMessage = () => (
  <NoRowsOverlay
    icon={<CenteredLoadSpinner theme="secondary" />}
    iconFixed
    message="Loading tasks..."
    sx={{ width: '100%' }}
  />
);

export default WorkCellsKanban;
