import { useApolloClient } from '@apollo/client';
import { DocumentNode } from 'graphql';

import { arePropertiesInObject, objIsEmpty } from 'helpers/objectFunctions';
import { UnknownObject } from 'types/generalTypes';

type Query = {
  query: UnknownObject;
  value: unknown;
};

const useCache = () => {
  const client = useApolloClient();

  const readCache = <T>(query: any, variables: any) => {
    const data = client.cache.readQuery<T>({ query, variables });
    return data;
  };

  const writeCache = <T>(query: any, variables: any, data: T) => {
    client.cache.writeQuery<T>({
      query,
      variables,
      data,
    });
  };

  const updateCache = (query: any, variables: any, callback: (data: any) => void) => {
    client.cache.updateQuery(
      {
        query,
        variables,
      },
      (data) => callback(data),
    );
  };

  const deleteCache = (fieldName: string, args: any) => {
    client.cache.evict({
      id: 'ROOT_QUERY',
      fieldName,
      args,
    });
  };

  const deleteFromCacheByQuery = async (fieldName: string, queryToDelete: UnknownObject) => {
    try {
      const cachedQueries = client.cache.extract() as any;

      const cachedQueriesByFieldName = Object.keys(cachedQueries.ROOT_QUERY).filter(
        (key) => key.includes(`${fieldName}:`) || key.includes(`${fieldName}(`),
      );

      cachedQueriesByFieldName.forEach((cachedQuery) => {
        if (cachedQuery === fieldName) return;

        const parsedCachedQuery = JSON.parse(
          cachedQuery.replace(`${fieldName}:`, '').replace(`${fieldName}(`, '').replace(')', ''),
        );
        const mustDeleteQuery = arePropertiesInObject(parsedCachedQuery.query, queryToDelete);

        if (mustDeleteQuery) deleteCache(fieldName, { query: parsedCachedQuery.query });
      });

      // eslint-disable-next-line no-empty
    } catch {}
  };

  const getAllQueriesByKey = (field: string, queryToFindValue: DocumentNode, variables?: UnknownObject) => {
    try {
      const cachedQueries = (client.cache.extract() as UnknownObject).ROOT_QUERY as UnknownObject;

      return Object.keys(cachedQueries).reduce<Query[]>((queries, currentKey) => {
        if (!currentKey) return queries;

        const keyString = String(currentKey);
        if (!(keyString.includes(`${field}:`) || keyString.includes(`${field}(`))) return queries;

        const parsedQuery = JSON.parse(
          keyString.replace(`${field}:`, '')?.replace(`${field}(`, '')?.replace(')', ''),
        ).query;

        if (!objIsEmpty(variables) && !arePropertiesInObject(parsedQuery, variables || {})) return queries;

        const cacheValue = readCache(queryToFindValue, { query: parsedQuery }) as UnknownObject;
        return [...queries, { query: parsedQuery, value: cacheValue[field] }];
      }, []);
    } catch {
      return [];
    }
  };

  return { readCache, writeCache, updateCache, deleteCache, deleteFromCacheByQuery, getAllQueriesByKey };
};

export default useCache;
