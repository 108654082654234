import React, { ReactNode, useMemo, useState } from 'react';

import { layoutOptions } from '@evolvemep/foresite-components-ui-react';

type CalendarSetupMemoType = { day: Date; layout: layoutOptions };

type ShopContextType = {
  calendarSetupMemo: null | CalendarSetupMemoType;
  setCalendarSetupMemo: React.Dispatch<React.SetStateAction<CalendarSetupMemoType | null>>;
};

const ShopConfigContext = React.createContext<ShopContextType>({
  setCalendarSetupMemo: () => {},
  calendarSetupMemo: null,
});

type ShopConfigProviderProps = {
  children: ReactNode;
};

export const ShopConfigProvider = ({ children }: ShopConfigProviderProps) => {
  const [calendarSetupMemo, setCalendarSetupMemo] = useState<CalendarSetupMemoType | null>(null);

  const contextValue = useMemo(
    () => ({
      calendarSetupMemo,
      setCalendarSetupMemo,
    }),
    [calendarSetupMemo],
  );

  return <ShopConfigContext.Provider value={contextValue}>{children}</ShopConfigContext.Provider>;
};

export const useShopConfig = () => {
  const context = React.useContext(ShopConfigContext);
  if (context === undefined) {
    throw new Error('useShopConfig must be used within a ShopConfigProvider');
  }
  return context;
};
