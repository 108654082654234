import React, { FC, ReactElement, ReactNode, useMemo } from 'react';

import { Box, ButtonProps } from '@mui/material';

import { GeneralDictionary } from 'types/generalTypes';

import { useItemsIntegrationModalContext } from '../../context/ItemsIntegrationModalContext';
import { useItemsIntegrationTableContext } from '../QuantityStep/components/ItemsIntegrationTable/context/ItemsIntegrationTableContext';

export interface IFooter {
  handleCloseModal: () => void;
  cancelModalButtonDisabled?: boolean;
  nextButtonDisabled?: boolean;
  previousButtonDisabled?: boolean;
  handlePreviousClick: () => void;
  handleSubmitItems: () => void;
  NextButton: FC<ButtonProps>;
  PreviousButton: FC<ButtonProps>;
  DoneButton: FC<ButtonProps>;
  ErrorMessage: FC;
  CancelButton: FC<ButtonProps>;
}

const Footer: FC<IFooter> = ({
  handleCloseModal,
  cancelModalButtonDisabled,
  nextButtonDisabled,
  previousButtonDisabled,
  handlePreviousClick,
  handleSubmitItems,
  NextButton,
  PreviousButton,
  DoneButton,
  ErrorMessage,
  CancelButton,
}: IFooter) => {
  const { activeStep, handleNext, enableDone, showOnlyQtyStep } = useItemsIntegrationModalContext();
  const { isLoadingRows, isCalculatingFields } = useItemsIntegrationTableContext();
  const disableDone = useMemo(
    () => !enableDone || isLoadingRows || isCalculatingFields,
    [enableDone, isLoadingRows, isCalculatingFields],
  );
  const disablePrevious = useMemo(
    () => previousButtonDisabled || isLoadingRows || isCalculatingFields,
    [previousButtonDisabled, isLoadingRows, isCalculatingFields],
  );
  const stepButtons: GeneralDictionary<ReactElement> = !showOnlyQtyStep
    ? {
        0: <NextButton disabled={nextButtonDisabled} onClick={() => handleNext()} />,
        1: (
          <>
            <PreviousButton onClick={() => handlePreviousClick()} disabled={disablePrevious} />
            <DoneButton disabled={disableDone} onClick={() => handleSubmitItems()} />
          </>
        ),
      }
    : {
        0: <DoneButton disabled={disableDone} onClick={() => handleSubmitItems()} />,
      };
  return (
    <Wrapper>
      <ErrorMessage />
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <CancelButton onClick={() => handleCloseModal()} disabled={cancelModalButtonDisabled} />
        {stepButtons[activeStep]}
      </Box>
    </Wrapper>
  );
};

interface IWrapper {
  children: ReactNode;
}

const Wrapper: FC<IWrapper> = ({ children }: IWrapper) => (
  <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>{children}</Box>
);

export default Footer;
