import { WORK_ORDERS_COLORS } from 'components/KanbanBoard/constants';

export const assignWorkOrderColors = (workOrdersIds = [], workOrderColor = []) => {
  const colorsWorkOder = workOrdersIds.map((workOrder, index) => ({
    workOrderId: workOrder,
    color: WORK_ORDERS_COLORS[index] ?? WORK_ORDERS_COLORS[0],
  }));

  return [...(workOrderColor ?? []), ...colorsWorkOder].filter(
    (value, index, self) => index === self.findIndex((t) => t.workOrderId === value.workOrderId),
  );
};
