import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import AccordionTabs from './components/AccordionTabs';
import AudioRecording from './components/AudioRecording';
import Header from './components/Header';
import { ROUNDED_BORDER_STYLE } from './constants/constants';
import GraphicSection from './GraphicSection/GraphicSection';
import { TaskViewerProvider } from './TaskViewerContext';

const TaskViewer = () => (
  <TaskViewerProvider>
    <Stack>
      <Header />
      <InfoSections />
    </Stack>
  </TaskViewerProvider>
);
export default TaskViewer;

const InfoSections = () => (
  <Stack direction="row" gap="8px" sx={{ height: 'calc(100vh - 154px)', mt: 2 }}>
    <Stack sx={{ flex: 3, ...ROUNDED_BORDER_STYLE }}>
      <AudioRecording />
      <AccordionTabs />
    </Stack>
    <Box sx={{ flex: 7, ...ROUNDED_BORDER_STYLE }}>
      <GraphicSection />
    </Box>
  </Stack>
);
