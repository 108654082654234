import React, { useState } from 'react';

import { useMutation, gql } from '@apollo/client';
import Box from '@mui/material/Box';
import { useForm } from 'react-hook-form';

import { useUser } from 'app/UserContext';
import { Modal, CancelButton } from 'components';
import { LOCAL_STORAGE_CONSTANTS } from 'constants/globalConstants';
import { addPartCatalog as ADD_CATALOG } from 'graphql/mutations';
import { useLocalStorage } from 'hooks/useLocalStorage';

import { useCatalogSetupContext } from '../CatalogSetupContext';
import { AddButton } from './AddButton';
import { CatalogNameInput } from './CatalogNameInput';
import { CategoryTypeSelect } from './CategoryTypeSelect';

const { SELECTED_CATALOG_ID } = LOCAL_STORAGE_CONSTANTS;

const CatalogTypeOptions = [
  { value: 0, label: 'Everyone can view' },
  /* { value: 1, label: 'Everyone can edit' },
  { value: 2, label: 'Invite only (Admins choose who has access' } */
];

const NewCatalogModal = ({ open, onClose }) => {
  const { user } = useUser();
  const { setSelectedCatalog } = useCatalogSetupContext();
  const maxCharacterCount = 50;
  const [error, setError] = useState(null);
  const [, setCurrentCatalogId] = useLocalStorage(SELECTED_CATALOG_ID, '');
  const [addNewCatalog] = useMutation(gql(ADD_CATALOG), {
    refetchQueries: ['PartCatalogs'],
    awaitRefetchQueries: true,
  });

  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid },
    watch,
  } = useForm({
    mode: 'all',
  });

  const onSubmit = async (values) => {
    const partCatalog = await addNewCatalog({
      variables: {
        body: {
          partCatalogName: values.name?.trim(),
          companyId: user.companyId,
        },
      },
    });
    setSelectedCatalog(partCatalog?.data?.addPartCatalog);
    setCurrentCatalogId(partCatalog?.data?.addPartCatalog.partCatalogId);
    onClose();
  };

  const MaxCharacterCounter = () => (
    <Box textAlign="right" mr="10px" mt="4px" color="rgba(0,0,0,0.5)" fontSize="12px" lineHeight="24px">
      {watch('name')?.length || 0}/{maxCharacterCount}
    </Box>
  );

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      title="New Catalog"
      titleStyles={{ fontWeight: 500 }}
      fullWidth
      maxWidth="sm"
      sx={{ width: '505px', margin: '0 auto' }}
      footer={
        <Box>
          <CancelButton color="secondary" onClick={() => onClose()} />
          <AddButton disabled={!isDirty || !isValid} onClick={async () => handleSubmit(onSubmit)()} />
        </Box>
      }
    >
      <form>
        <CatalogNameInput control={control} setError={setError} />
        {!error && <MaxCharacterCounter />}
        <CategoryTypeSelect control={control} options={CatalogTypeOptions} />
      </form>{' '}
    </Modal>
  );
};

export default NewCatalogModal;
