const notEditStyles = {
  '& .MuiCheckbox-root': {
    display: 'none',
  },
};

export const buildTasksTableStyles = (canEdit = false) => ({
  border: 0,
  borderRadius: 0,
  overflow: 'hidden',

  '& div[role="columnheader"]:nth-of-type(3), & div[role="columnheader"]:nth-of-type(4)': {
    display: 'block',
  },
  'div[role="columnheader"]:nth-of-type(4)': {
    marginLeft: 'unset',
    marginRight: 'unset',
  },
  '& div[role="columnheader"]:nth-of-type(5)': {
    marginRight: 'unset',
  },
  '& div[role="columnheader"]:nth-of-type(6)': {
    marginLeft: '8px',
    marginRight: '-8px',
  },
  '& .MuiDataGrid-row': {
    width: '100% !important',
  },
  '& .MuiDataGrid-row:hover': {
    backgroundColor: '#f5f5f5',
  },
  '& .MuiDataGrid-row--editing': {
    boxShadow: 'none !important',
  },
  '& .MuiDataGrid-cell': {
    borderTop: 'none !important',
    borderBottom: '1px solid rgba(224,224,224,1) !important',
  },
  '& .MuiDataGrid-pinnedColumns': {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    boxShadow: 'none',
  },
  '& .MuiDataGrid-pinnedColumnHeaders': {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    boxShadow: 'none',
  },
  '& .MuiDataGrid-row.Mui-hovered': {
    backgroundColor: '#f5f5f5',
  },
  '& .MuiTextField-root': {
    margin: 0,
  },
  ...(canEdit ? {} : notEditStyles),
});
