/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useMemo, useState } from 'react';

import { ForesiteTextField, ControlledSelectField } from '@evolvemep/foresite-components-ui-react';
import { BackdropProps, FormHelperText, ListItemIcon, MenuItem, SelectProps, Stack, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import parse from 'html-react-parser';
import { Control, Controller } from 'react-hook-form';
import { FieldError, RegisterOptions } from 'react-hook-form/dist/types';

import { getNavIcon } from 'helpers/MenuIcons';

import { AddOptionButton } from './AddOptionButton';

type OptionType = {
  value: any;
  disabled?: boolean;
  icon?: any;
  label?: string;
  isActive?: boolean;
};

interface IInputSelectField {
  disabled?: boolean;
  name: string;
  label?: string;
  control: Control;
  defaultValue?: string | undefined;
  noOptionsText?: string;
  rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
  selectProps?: any;
  sx?: any;
  formControlSx?: any;
  handleAddNewOption?: (e: React.MouseEvent<HTMLElement>) => void;
  addNewOptionText?: string;
  options: OptionType[];
  onSelectChange: (e: React.ChangeEvent<HTMLInputElement>, child: any) => void;
  menuItemSx?: any;
  placeholder?: string;
  showActive?: boolean;
  renderMenuItem?: (option: OptionType) => any;
  dataTestId?: string;
  variant?: any;
  fullWidth?: boolean;
  SelectProps?: SelectProps;
  typeToSelect?: boolean;
}

interface IOptionItemContent {
  option: OptionType;
  menuItemSx?: any;
  showActive?: boolean;
}

type RenderMenuItemComponentType = {
  disabled?: boolean;
  value?: any;
  option: OptionType;
  menuItemSx?: any;
  showActive?: boolean;
  props?: any;
};

const formControlStyles = {
  // Styles for the placeholder
  '.MuiInputLabel-root:not(.MuiFormLabel-filled) + div > .MuiSelect-select.MuiInputBase-input': {
    color: 'surface.lightSurface.secondary',
  },
  '& .MuiSelect-select.MuiInputBase-input .MuiTypography-root': {
    fontSize: '16px',
    fontWeight: 'normal',
    lineHeight: 'normal',
  },
};

const textFieldSx = {
  '& .MuiInputBase-root': {
    maxHeight: '40px',
  },
  '.MuiSelect-select > .MuiListItemIcon-root, .MuiSelect-select .hideOnSelected': {
    display: 'none',
  },
};
const OptionItemContent: FC<IOptionItemContent> = ({ option, menuItemSx, showActive }: IOptionItemContent) => (
  <>
    {option.icon && <ListItemIcon sx={{ justifyContent: 'center', mr: 1 }}>{getNavIcon(option?.icon)}</ListItemIcon>}
    <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
      <Typography sx={menuItemSx} variant="body1">
        {parse(option?.label || '')}
      </Typography>
      {option?.isActive && showActive && (
        <Typography variant="caption" color="secondary">
          Active
        </Typography>
      )}
    </Stack>
  </>
);

const renderMenuItemComponent = ({
  disabled,
  value,
  option,
  menuItemSx,
  showActive,
  props,
}: RenderMenuItemComponentType) => (
  <MenuItem disabled={disabled} value={value} key={value} color="secondary" {...props}>
    <OptionItemContent option={option} menuItemSx={menuItemSx} showActive={showActive} />
  </MenuItem>
);

// eslint-disable-next-line max-lines-per-function
const InputSelectField: FC<IInputSelectField> = ({
  disabled,
  name,
  label,
  control,
  defaultValue,
  noOptionsText = 'No Options Available',
  rules,
  selectProps,
  sx,
  formControlSx,
  handleAddNewOption,
  addNewOptionText = 'New option',
  options = [],
  onSelectChange = () => {},
  menuItemSx,
  placeholder,
  showActive = false,
  renderMenuItem = undefined,
  dataTestId,
  variant,
  SelectProps,
  typeToSelect,
  ...props
}: IInputSelectField) => {
  const [error, setError] = useState<FieldError | undefined | null>(null);
  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => () => setIsMounted(false), []);

  return (
    <FormControl error={!!error} disabled={disabled} {...props} sx={{ ...formControlStyles, ...formControlSx }}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          setTimeout(() => isMounted && setError(error), 0);
          const controlledSelectFieldProps = {
            options,
            renderMenuItemComponent,
            menuItemSx,
            showActive,
            placeholder,
            disabled,
            defaultValue,
            label,
            onSelectChange,
            selectProps,
            textFieldSx,
            sx,
          };
          return (
            <>
              <ForesiteTextField
                disabled={disabled}
                placeholder={placeholder}
                label={label}
                size="small"
                variant={variant}
                name={name}
                id={name}
                select
                defaultValue={defaultValue}
                error={!!error}
                onChange={(event: React.ChangeEvent<HTMLInputElement>, child: any) => {
                  if (![child?.props?.value, event?.target?.value].includes('not-selectable')) {
                    onSelectChange(event, child);
                    onChange(event, child);
                  }
                }}
                value={value ?? defaultValue}
                data-testid={dataTestId}
                {...selectProps}
                sx={{ ...textFieldSx, ...sx }}
                SelectProps={SelectProps}
              >
                {options.length === 0 && (
                  <MenuItem disabled value="not-selectable">
                    {noOptionsText}
                  </MenuItem>
                )}
                {handleAddNewOption && (
                  <MenuItem
                    value="not-selectable"
                    sx={{ color: 'secondary.dark', padding: 0 }}
                    onClick={handleAddNewOption}
                  >
                    <AddOptionButton>{addNewOptionText}</AddOptionButton>
                  </MenuItem>
                )}
                {renderMenuItem && options.map(renderMenuItem)}
                {!renderMenuItem &&
                  options?.map((option) =>
                    renderMenuItemComponent({
                      disabled: option.disabled,
                      value: option.value,
                      option,
                      menuItemSx,
                      showActive,
                    }),
                  )}
              </ForesiteTextField>
              {error && <FormHelperText error>{error.message}</FormHelperText>}
            </>
          );
        }}
      />
    </FormControl>
  );
};

export default InputSelectField;
