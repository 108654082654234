import React from 'react';

import { useTheme } from '@mui/material';

import { WorkOrderStatusesList } from 'graphql/types';

import WorkOrdersKanbanColumn from './WorkOrdersKanbanColumn';
import { getColumns } from './WorkOrdersKanbanHelpers';

type WorkOrderKanbanColumnsProps = {
  data: Array<WorkOrderStatusesList>;
  disabled: string | null;
  disabledDrag: boolean;
};

const WorkOrderKanbanColumns = ({ data, disabled, disabledDrag }: WorkOrderKanbanColumnsProps) => {
  const { palette } = useTheme();

  return (
    <>
      {Object.values(getColumns(data, palette)).map(
        ({ title, headerColor, workOrderStatusTypeId }) => (
          <WorkOrdersKanbanColumn
            key={title}
            name={title}
            headerColor={headerColor}
            status={workOrderStatusTypeId ?? ''}
            disabled={disabled}
            disabledDrag={disabledDrag}
          />
        ),
      )}
    </>
  );
};

export default WorkOrderKanbanColumns;
