import React, { Fragment } from 'react';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';

import InfiniteScroll from 'components/TableComponents/InfiniteScroll';
import NoContentMessage from 'components/TableComponents/NoContentMessage';
import TableContainer from 'components/TableComponents/TableContainer';
import TableHeader from 'components/TableComponents/TableHeaders';
import TableSkeleton from 'components/TableComponents/TableSkeleton';

import CustomersTableRow from './CustomersTableRow';

const CustomersTable = ({ loading, customers, handleSortOrder, fetchNextPage, headerOptions }) => {
  const TableBodyContent = () => (
    <TableBody>
      {customers.map((company, index) => (
        <Fragment key={company.companyId}>
          <CustomersTableRow company={company} />

          {index === customers.length - 1 && <InfiniteScroll data={customers} fetchMore={fetchNextPage} />}
        </Fragment>
      ))}
    </TableBody>
  );

  return (
    <CustomersTableBorderBox>
      <TableContainer>
        <Table stickyHeader>
          <TableHeader headerOptions={headerOptions} handleSortOrder={handleSortOrder} rowCount={customers.length} />
          <TableBodyContent />
          {loading && <TableSkeleton skeletons={headerOptions.map(({ title }) => title)} />}
        </Table>
      </TableContainer>
      {!customers.length && !loading && <NoContentMessage text="No Customers..." />}
    </CustomersTableBorderBox>
  );
};

export default CustomersTable;

const CustomersTableBorderBox = (props) => (
  <Box
    sx={{
      border: '1px solid',
      borderColor: 'other.divider.lightSurface',
      borderRadius: '5px',
      position: 'relative',
      padding: '5px 0 0 0',
      height: 'calc(100% - 135px)',
    }}
    {...props}
  />
);
