import React, { Fragment, useMemo } from 'react';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { useUser } from 'app/UserContext';
import InfiniteScroll from 'components/TableComponents/InfiniteScroll';
import NoContentMessage from 'components/TableComponents/NoContentMessage';
import TableContainer from 'components/TableComponents/TableContainer';
import TableHeader from 'components/TableComponents/TableHeaders';
import TableSkeleton from 'components/TableComponents/TableSkeleton';
import {
  handleTableRowClick,
  handleSelectAllClick,
  isSelected,
} from 'components/TableComponents/tableUtilities';

import NoShopDepartmentMessage from '../../NoShopDepartmentMessage';
import { useAdminContext } from '../AdminContext';
import AdminTableRow from './AdminTableRow';

const headerOptions = [
  { title: 'ID', sortValue: 'userIdentifier' },
  { title: 'User', sortValue: 'userName' },
  { title: 'Email', sortValue: 'email' },
  { title: 'Role' },
  { title: 'Office Phone', sortValue: 'office' },
  { title: 'Mobile Phone', sortValue: 'mobilephone' },
  { title: 'Status', sortValue: 'status' },
  { title: 'EMPTY' },
];

const AdminTable = () => {
  const { user } = useUser();
  const { selected, setSelected, loading, data, sortHandler, paginationHandler } =
    useAdminContext();
  const { selectedItem, shopDepartmentId, isShopAdmin, isShopAdminInvitationCompleted } =
    useFacilitiesProjects();

  const users = data?.departmentUsers || [];

  const hasUserDeleteAdminPermission = useMemo(
    () => user?.isAdmin || (isShopAdmin && isShopAdminInvitationCompleted),
    [user, isShopAdmin, isShopAdminInvitationCompleted],
  );

  const NoOverlayMessage = () => {
    if (selectedItem?.type === 'FACILITY' && !shopDepartmentId)
      return <NoShopDepartmentMessage selectedItem={selectedItem} />;

    const emptyTableMessage =
      selectedItem?.type === 'FACILITY' ? 'No results' : 'Open a facility to view members...';

    return <NoContentMessage text={emptyTableMessage} />;
  };

  const TableBodyContent = () => (
    <TableBody>
      {users.map((member, index) => (
        <Fragment key={member.user.userId}>
          <AdminTableRow
            member={member}
            handleRowClick={() => handleTableRowClick(member, selected, setSelected)}
            isItemSelected={isSelected(member, selected)}
            hasDeleteOptionDisabled={!hasUserDeleteAdminPermission}
          />
          {index === users.length - 1 && (
            <InfiniteScroll data={users} fetchMore={paginationHandler} />
          )}
        </Fragment>
      ))}
    </TableBody>
  );

  return (
    <AdminTableBorderBox>
      <TableContainer>
        <Table stickyHeader>
          <TableHeader
            headerOptions={headerOptions}
            onSelectAllClick={(e) => handleSelectAllClick(e, users, setSelected)}
            handleSortOrder={sortHandler}
            rowCount={users.length}
            numSelected={selected.length}
            selectable
          />
          {loading ? (
            <TableSkeleton skeletons={headerOptions.map(({ title }) => title)} />
          ) : (
            <TableBodyContent />
          )}
        </Table>
      </TableContainer>
      {!users.length && !loading && <NoOverlayMessage />}
    </AdminTableBorderBox>
  );
};

export default AdminTable;

const AdminTableBorderBox = ({ children }) => (
  <Box
    mt="20px"
    sx={{
      border: `1px solid #0000001F`,
      borderRadius: 1,
      position: 'relative',
      padding: '5px 0 0 0',
      height: 'calc(100% - 135px)',
    }}
  >
    {children}
  </Box>
);
