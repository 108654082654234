import React, { useMemo } from 'react';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';

import { Custom } from 'components/Icons';
import { getNavIcon } from 'helpers/MenuIcons';
import { replaceSpaceToDash } from 'helpers/stringFunctions';
import { uuIDValidator } from 'helpers/uuIDValidator';
import useSetupModule from 'hooks/useSetupModule';
import useUrlParams from 'hooks/useUrlParams';

import { validateNavigationSource } from './HelperFunctions';

const NavItem = ({ item, isNestedList, isExpanded, onClick }) => {
  const { module } = useSetupModule();
  const location = useLocation();
  const {
    params: { nav },
  } = useUrlParams();

  const routeIsActive = (item) => {
    const pathNames = location.pathname.split('/').filter((x) => x);
    const pathNameWithoutUUID = pathNames.filter((x) => !uuIDValidator(x)).join('/');

    if (nav) {
      return nav === item.routePath;
    }
    return pathNameWithoutUUID === `${module.toLowerCase()}/${item.routePath}`;
  };

  const isChildItemActive = routeIsActive(item);

  const handleClick = () => {
    validateNavigationSource(item);
    onClick();
  };

  const dataTestIdOfItem = useMemo(() => {
    if (item.isMenu) return `nav-menu-${replaceSpaceToDash(item.navigationSetName)}`;
    return `nav-item-${replaceSpaceToDash(item.navigationItemName)}`;
  }, [item]);

  return (
    <StyledListItem
      selected={routeIsActive(item)}
      onClick={handleClick}
      isNestedList={isNestedList}
      isChildrenItemActive={isChildItemActive}
      isExpanded={isExpanded}
      data-testid={dataTestIdOfItem}
    >
      <ListItemIcon sx={{ minWidth: '38px' }}>{getNavIcon(item.icon)}</ListItemIcon>
      <ListItemText>
        <Typography variant="body2" component="div">
          {item.navigationItemName || item.navigationSetName}
        </Typography>
      </ListItemText>
      <ListItemIcon
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        {isNestedList && <ExpandIcon isOpen={isExpanded} />}
      </ListItemIcon>
    </StyledListItem>
  );
};

export default NavItem;

const StyledListItem = ({
  children,
  selected,
  onClick,
  isNestedList,
  isChildrenItemActive,
  isExpanded,
  ...props
}) => (
  <ListItem
    selected={selected}
    onClick={onClick}
    className={`${isNestedList ? 'NavList-nestedListItem' : ''}`}
    sx={{
      height: 42,
      cursor: 'pointer',
      color: isChildrenItemActive || isExpanded ? 'white' : '',
      m: '8px 0',
      '&:first-of-type': {
        mt: 0,
      },
      '&:last-of-type': {
        mb: 0,
      },
      path: {
        fill: isChildrenItemActive || isExpanded ? 'white' : '',
      },
      '&.Mui-selected, &:hover': {
        borderRadius: '5px',
        backgroundColor: isNestedList ? 'primary.outlinedHoverBackground' : 'secondary.main',
        color: 'primary.contrastText',
        path: {
          fill: 'white',
        },
      },
      '&::before': {
        content: 'none',
      },
    }}
    {...props}
  >
    {children}
  </ListItem>
);

const ExpandIcon = ({ isOpen }) =>
  isOpen ? <Custom.KeyboardArrowUp /> : <Custom.KeyboardArrowDownAlt />;
