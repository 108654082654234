import React from 'react';

import { minRule, customMaxLengthRule, maxRule } from 'constants/inputFieldRules';

import TextField from './TextField';

const NumberOfNodesInput = React.memo(({ ...rest }) => (
  <TextField
    name="numberOfNodes"
    label="# of Nodes"
    placeholder="ex. 1"
    type="number"
    InputLabelProps={{ shrink: true }}
    sx={{ flex: 1, margin: 0 }}
    rules={{
      ...minRule('# of Nodes', 1),
      ...maxRule('# of Nodes', 2000, '2000 node limit'),
      ...customMaxLengthRule(4, '# of Nodes limited to 4 characters'),
    }}
    InputProps={{ inputProps: { min: 1, max: 2000 } }}
    {...rest}
  />
));

export default NumberOfNodesInput;
