import { useEffect, useState } from 'react';

import { gql, useMutation } from '@apollo/client';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { updateShopConfiguration as UPDATE_SHOP_CONFIGURATION } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

export const formatShopConfiguration = (shopConfiguration) => {
  if (shopConfiguration?.value) {
    return {
      shopConfigurationId: shopConfiguration.value,
      shopConfigurationName: shopConfiguration.label,
      shopConfigurationDescription: shopConfiguration.description,
      isActive: shopConfiguration.isActive,
    };
  }

  return shopConfiguration;
};

const getFacilityId = (selectedItem) => (selectedItem?.type === 'FACILITY' ? selectedItem?.id : null);

const useUpdateShopConfiguration = (shopConfig) => {
  const [facilityId, setFacilityId] = useState(null);
  const [shopConfiguration, setShopConfiguration] = useState(null);
  const { handleResponse } = useGraphqlResponseHandler();
  const [updateShopConfiguration, { loading }] = useMutation(gql(UPDATE_SHOP_CONFIGURATION), {
    refetchQueries: ['shopConfigurations'],
  });
  const { selectedItem, shopDepartmentId } = useFacilitiesProjects();

  useEffect(() => {
    setFacilityId(getFacilityId(selectedItem));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  useEffect(() => {
    setShopConfiguration(formatShopConfiguration(shopConfig));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopConfig]);

  const editShopConfiguration = async (body, successCallback) => {
    const payload = {
      FacilityId: facilityId,
      DepartmentId: shopDepartmentId,
      ...body,
    };
    await handleResponse(
      updateShopConfiguration,
      {
        variables: {
          params: { shopConfigurationId: shopConfiguration.shopConfigurationId },
          body: payload,
        },
      },
      {},
      successCallback,
    );
  };

  return { loading, updateShopConfiguration, editShopConfiguration };
};

export default useUpdateShopConfiguration;
