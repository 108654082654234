import React from 'react';

import { Typography } from '@mui/material';

import Section from 'components/Section';
import { useProject } from 'modules/Field/LocationsAndWorkPhases/contexts/ProjectContext';

import NoRowsOverlay from '../../NoRowsOverlay';
import { usePopover } from '../PopoverProvider';
import { getElementId } from './HelperElements';
import LocationsItem from './LocationsOption/LocationsItem';
import LocationsList from './LocationsOption/LocationsList';
import WorkPhasesList from './WorkPhasesOption/WorkPhasesList';

export const Content = () => {
  const { selectedProject } = useProject();
  const { menuValue } = usePopover();
  const menuComponents = {
    'Location Breakdown Structure': <LocationsList />,
    'Locations with Work Phases': <LocationsList />,
    'Work Phases': <WorkPhasesList />,
  };
  return (
    <Section m="16px 24px">
      <Section.Title sx={{ minHeight: '32px' }}>
        <Typography variant="tableHeader" sx={{ margin: '0 92px 0 80px' }}>
          Id
        </Typography>
        <Typography variant="tableHeader">Location</Typography>
      </Section.Title>
      <Section.Body sx={{ height: '288px' }}>
        {selectedProject ? (
          <LocationsItem key={getElementId(selectedProject)} item={selectedProject} isOpen />
        ) : (
          <NoRowsOverlay text="Start by opening a project" />
        )}
        {menuComponents[menuValue]}
      </Section.Body>
    </Section>
  );
};
