import React, { useContext, useMemo } from 'react';

import { gql, useMutation } from '@apollo/client';
import { Box, CircularProgress, Typography } from '@mui/material';
import Button from '@mui/material/Button';

import { DeleteButton, Modal } from 'components';
import { deleteWorkRequestItem as DELETE_WORK_REQUEST_ITEM } from 'graphql/mutations';
import useWorkRequestStatusMutation, { WorkRequestByIdQuery } from 'hooks-api/useWorkRequestStatusMutation';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import { WorkOrderItemsContext } from 'modules/Shop/WorkOrders/WorkOrder/context/WorkOrderItemsContext';
import { useDeleteWorkOrderItemDataLayer } from 'modules/Shop/WorkOrders/WorkOrder/hooks/dataLayer/useDeleteWorkOrderItemDataLayer';

const DeleteWorkRequestItemModal = ({
  open,
  workRequestItemName,
  workRequestItemId,
  setShowModal,
  workRequest,
  workOrder,
}) => {
  const { handleResponse } = useGraphqlResponseHandler();
  const workOrderItemsContext = useContext(WorkOrderItemsContext);
  const { handleDeleteWorkOrderItem, deleteWorkOrderItemLoading } = useDeleteWorkOrderItemDataLayer(
    workOrderItemsContext?.workOrder,
  );

  const [removeWorkRequestItem, { loading }] = useMutation(gql(DELETE_WORK_REQUEST_ITEM), {
    refetchQueries: ['WorkRequestsById', 'WorkOrderById', 'WorkOrderItems', 'WorkRequestItems'],
    awaitRefetchQueries: true,
    update(cache) {
      cache.evict({ workRequestItemId });
      cache.gc();
    },
  });
  const { updateWorkRequestStatusMutation } = useWorkRequestStatusMutation();

  const onClose = () => setShowModal(false);

  const moveWorkRequestToDraft = () => {
    if (workRequest?.workRequestStatusName === 'Canceled' || workOrder) {
      updateWorkRequestStatusMutation({
        variables: {
          params: {
            workRequestId: workRequest?.workRequestId || workOrder?.workRequestId,
            workRequestStatusName: 'Draft',
          },
          body: {
            workRequestStatusDescription: '',
          },
        },
        refetchQueries: [WorkRequestByIdQuery(workRequest.workRequestId)],
        awaitRefetchQueries: true,
      });
    }
    onClose();
  };

  const deleteWorkRequestItem = async () => {
    await handleResponse(
      removeWorkRequestItem,
      {
        variables: {
          params: {
            workRequestItemId,
          },
        },
      },
      { successMessage: `Item successfully deleted` },
      moveWorkRequestToDraft,
    );
  };

  const deleteWorkOrderItem = async () => {
    await handleDeleteWorkOrderItem(workRequestItemId);
    onClose();
  };

  const loadingDelete = useMemo(() => loading || deleteWorkOrderItemLoading, [loading, deleteWorkOrderItemLoading]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      disableModalOutsideClick
      disableCloseButton={loadingDelete}
      fullWidth
      scroll="paper"
      sx={{
        '.MuiPaper-root': {
          maxHeight: '80vh',
        },
      }}
      titleStyles={{ fontWeight: 500 }}
      title={<ModalHeader title="Confirm Delete" />}
      footer={
        <>
          {loadingDelete && <CircularProgress size={35} />}
          <Button color="primary" onClick={onClose} disabled={loadingDelete}>
            NO
          </Button>
          <DeleteButton
            onClick={workOrderItemsContext ? deleteWorkOrderItem : deleteWorkRequestItem}
            disabled={loadingDelete}
          >
            CONFIRM
          </DeleteButton>
        </>
      }
    >
      <Typography>
        Are you sure you want to delete <b>{workRequestItemName}?</b>
      </Typography>
    </Modal>
  );
};

export default DeleteWorkRequestItemModal;

const ModalHeader = ({ title }) => <Box sx={{ width: 'md' }}>{title}</Box>;
