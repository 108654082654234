import React from 'react';

import { Typography } from '@mui/material';

export const ItemTypography = ({ children }) => (
  <Typography
    variant="body2"
    sx={{
      width: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }}
  >
    {children}
  </Typography>
);

export const selectMenuProps = (paperHeight = '414px') => ({
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  PaperProps: {
    style: {
      maxHeight: paperHeight,
      width: '286px',
    },
  },
});
