import React from 'react';

import { usePartCategorySearch } from '../../PartCategory/PartCategorySearchContext';
import { OptionType } from '../CatalogSearchConstans';
import CatalogSearchHistoryItem from '../CatalogSearchHistoryItem/CatalogSearchHistoryItem';
import CatalogSearchPartCategoryItem from '../CatalogSearchPartCategoryItem/CatalogSearchPartCategoryItem';
import CatalogSearchPartItem from '../CatalogSearchPartItem/CatalogSearchPartItem';

const CatalogSearchOptions = ({ option, setIsOpened, searchValue, onSelectPartCategory, onSelectPart, ...props }) => {
  const { type, value, label } = option;
  const { setSearchValue, onSearchParts } = usePartCategorySearch();

  const handleSelectItem = (item) => {
    setIsOpened(false);
    setSearchValue(typeof item === 'object' ? item.label : item);

    if (typeof item !== 'object') {
      onSearchParts(item);
      return;
    }

    const { type, value } = item;
    if (type === OptionType.PART_CATEGORY) onSelectPartCategory(value);
    if (type === OptionType.PART) onSelectPart(value);
  };

  if (type === OptionType.HISTORY) {
    return <CatalogSearchHistoryItem key={`${type}-${label}`} onSelect={handleSelectItem} label={label} {...props} />;
  }

  if (type === OptionType.PART_CATEGORY) {
    return <CatalogSearchPartCategoryItem key={`${type}-${value}`} onSelect={handleSelectItem} item={option} />;
  }

  return (
    <CatalogSearchPartItem
      key={`${type}-${value}`}
      onSelect={handleSelectItem}
      searchValue={searchValue}
      item={option}
      {...props}
    />
  );
};

export default CatalogSearchOptions;
