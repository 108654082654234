import { useEffect, useState, useMemo } from 'react';

const useLoadingTasks = (workCellsData) => {
  const [workcellsLoading, setWorkcellsLoading] = useState([]);

  useEffect(() => {
    if (workCellsData?.workCells)
      setWorkcellsLoading(
        workCellsData?.workCells.map((workCell) => ({ id: workCell.workCellId, isLoading: true })),
      );
  }, [workCellsData?.workCells]);

  const tasksIsLoading = useMemo(
    () => workcellsLoading.some((item) => item.isLoading),
    [workcellsLoading],
  );

  const onUpdateTasksLoading = (workcellLoading) => {
    const updatedItems = workcellsLoading.map((item) => {
      if (item.id === workcellLoading.id) {
        return { ...item, isLoading: workcellLoading.isLoading };
      }
      return item;
    });
    setWorkcellsLoading(updatedItems);
  };

  return { isLoading: tasksIsLoading, onUpdateTasksLoading };
};

export default useLoadingTasks;
