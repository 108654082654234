import React from 'react';

import { gql, useQuery } from '@apollo/client';
import Badge from '@mui/material/Badge';
import { useNavigate } from 'react-router-dom';

import { Custom } from 'components/Icons';
import RoundedIconButton from 'components/RoundedIconButton';
import { messagesCount as MESSAGES_COUNT } from 'graphql/queries';

const MailButton = () => {
  const navigate = useNavigate();
  const goToMessageCenter = () => navigate('/message');

  const { data: messagesCount } = useQuery(gql(MESSAGES_COUNT));

  return (
    <RoundedIconButton
      size="small"
      style={{ marginRight: '16px', width: '32px', height: '32px' }}
      onClick={goToMessageCenter}
    >
      <Badge badgeContent={messagesCount?.messagesCount ?? 0} color="error">
        <Custom.Mail />
      </Badge>
    </RoundedIconButton>
  );
};

export default MailButton;
