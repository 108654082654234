import React from 'react';

import { ForesiteTextField } from '@evolvemep/foresite-components-ui-react';
import { useController, useFormContext } from 'react-hook-form';

import { useUOMAPIContext } from 'modules/Materials/CatalogSetup/Providers/UOMAPIProvider';

import { useUnitOfMeasureContext } from './UnitOfMeasureContext';
import { getUomCodes } from './unitOfMeasureHelpers';

const UnitOfMeasureCodeField = ({ name, sx, ...restProps }) => {
  const { control, formState } = useFormContext();
  const { uoms } = useUOMAPIContext();
  const { newUnitOfMeasure } = useUnitOfMeasureContext();

  const {
    field: { onChange, onBlur, ref, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: {
      required: {
        value: true,
        message: null,
      },
      validate: (value) => {
        if (!newUnitOfMeasure) return true;
        if (getUomCodes(uoms).includes(value.trim())) return 'Code already exists';
        return true;
      },
    },
  });

  const disabled = !newUnitOfMeasure || formState.isSubmitting;
  return (
    <ForesiteTextField
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      name={name}
      inputRef={ref}
      sx={{
        width: '108px',
        '&  .MuiFormHelperText-root.Mui-error': {
          color: error?.type === 'required' ? 'rgba(0, 0, 0, 0.5)' : 'red',
        },
        ...sx,
      }}
      label="Code"
      error={!!error}
      helperText={
        (error == null && !disabled) || error?.type === 'required' ? '*required' : error?.message
      }
      maxLength="32"
      disabled={disabled}
      inputProps={{ 'data-testid': 'uom-code-input' }}
      {...restProps}
    />
  );
};

export default UnitOfMeasureCodeField;
