import { gql } from '@apollo/client';

import { shopConfigurations } from 'graphql/queries';
import { ShopConfiguration } from 'graphql/types';
import useCache from 'hooks/useCache';

const useAddShopConfigurationToCache = () => {
  const { readCache, writeCache } = useCache();

  const handleAddShopConfigurationToCache = (shopConfiguration: ShopConfiguration, facilityId: string) => {
    const variables = { params: { facilityId } };
    const cacheData = readCache<{ shopConfigurations: Array<ShopConfiguration> }>(gql(shopConfigurations), variables);
    writeCache(gql(shopConfigurations), variables, getNewShopConfigurations(cacheData, shopConfiguration));
  };

  return { handleAddShopConfigurationToCache };
};

export default useAddShopConfigurationToCache;

const getNewShopConfigurations = (
  cacheData: { shopConfigurations: Array<ShopConfiguration> } | null,
  shopConfiguration: ShopConfiguration,
) => {
  if (!cacheData) return { shopConfigurations: [shopConfiguration] };
  return { shopConfigurations: [...cacheData.shopConfigurations, shopConfiguration] };
};
