import React from 'react';

import { useReactiveVar } from '@apollo/client';
import { Button, Stack, Tooltip } from '@mui/material';

// import FilterButton from 'components/Buttons/FilterButton';
import { workCellsSelectedToggleOptionVar } from 'apollo/reactiveVars';
import { Custom } from 'components/Icons';
// import SearchBar from 'components/SearchBar/SearchBar';
import { shopColors } from 'styles/theme/colors';

export const WorkCellsListOptions = () => (
  <Stack direction="row" alignItems="center" sx={{ mt: 0 }}>
    <Stack direction="column">
      <Stack direction="row" alignItems="center">
        {/* <SearchBar placeholder="Search work cells…" /> */}
        <ViewColumns />
      </Stack>
      {/* <Stack direction="row" mt={2} sx={{ justifyContent: 'flex-end' }}>
        <FilterButton disabled={filterDisabled} />
        <ColumnsButton />
      </Stack> */}
    </Stack>
  </Stack>
);

// const ColumnsButton = () => (
//   <Stack direction="row" sx={{ alignItems: 'center', ml: 3.5 }}>
//     <Custom.ViewColumn
//       style={{
//         fill: actionColors.disabled,
//         margin: '0px 5px 0px 0px'
//       }}
//     />
//     <Typography
//       sx={{
//         fontSize: '14px',
//         color: actionColors.disabled,
//         lineHeight: '24px',
//         fontWeight: '600'
//       }}
//     >
//       COLUMN
//     </Typography>
//   </Stack>
// );

const ViewColumns = () => {
  const selectedTab = useReactiveVar(workCellsSelectedToggleOptionVar);
  const isListView = selectedTab === 'list';

  return (
    <Stack direction="row" ml={2}>
      <Tooltip title="List View" arrow>
        <Button
          sx={{
            minWidth: '48px',
            border: '1px solid #C4C4C4',
            borderRight: 'none',
            borderRadius: '4px 0 0 4px',
            bgcolor: isListView ? 'action.lightSurface.selected' : 'other.main',
          }}
          onClick={() => workCellsSelectedToggleOptionVar('list')}
        >
          <Custom.TableChart
            style={{
              fill: isListView ? shopColors.main : '#000',
              opacity: isListView ? 1 : 0.5,
            }}
          />
        </Button>
      </Tooltip>
      <Tooltip title="Kanban Board" arrow>
        <Button
          sx={{
            minWidth: '48px',
            border: '1px solid #C4C4C4',
            borderRadius: '0 4px 4px 0',
            bgcolor: !isListView ? 'action.lightSurface.selected' : 'other.main',
          }}
          onClick={() => workCellsSelectedToggleOptionVar('kanban')}
          data-cy="kanban-board-button"
        >
          <Custom.DeveloperBoard
            style={{
              fill: !isListView ? shopColors.main : '#000',
              opacity: !isListView ? 1 : 0.5,
            }}
          />
        </Button>
      </Tooltip>
    </Stack>
  );
};
