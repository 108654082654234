import React from 'react';

import Box from '@mui/material/Box';

import { MoreMenu } from 'components';
import useAttachments from 'components/AttachmentsTable/hooks/useAttachments';

const renameMenuOption = (onRenameClick) => ({
  label: 'Rename',
  clickEvent: onRenameClick,
});

const downloadFileOption = (onDownloadAction) => ({
  label: 'Download',
  clickEvent: onDownloadAction,
});

const menuStyles = {
  '.MuiDataGrid-row & > svg': {
    display: 'none',
  },
  '.MuiDataGrid-row:hover & > svg': {
    display: 'block',
  },
};

const DrawingDocumentMoreOptions = ({ onRenameClick, document }) => {
  const { handleDownloadSingleFile } = useAttachments();

  const MORE_MENU_OPTIONS = [
    downloadFileOption(() => handleDownloadSingleFile(document, 0)),
    renameMenuOption(onRenameClick),
  ];

  return (
    <Box>
      <MoreMenu
        options={MORE_MENU_OPTIONS}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        sxMenu={{ left: '30px' }}
        sxIconButton={menuStyles}
      />
    </Box>
  );
};

export default DrawingDocumentMoreOptions;
