import React, { useState } from 'react';

import { useMutation, gql } from '@apollo/client';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';

import { addPartAttributeSelectVariant as ADD_VARIENTS } from 'graphql/mutations';
import { partAttributes as ATTRIBUTES } from 'graphql/queries';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import useKeyPress from 'hooks/useKeyPress';

import { useCategoriesContext } from '../../Categories/CategoriesContext';
import { useAttributeContext } from '../AttributesContext';
import VariantCodeInput from './VariantCodeNameInput';
import VariantNameInput from './VariantNameInput';

const variantRules = {
  required: '*Required',
  maxLength: {
    value: 32,
    message: 'Name limited to 32 characters',
  },
};
const codeRules = {
  maxLength: {
    value: 6,
    message: '6/6',
  },
};
const VariantForm = ({
  defaultValues,
  onKeyDown,
  selectedAttribute,
  handleClickAttribute,
  variants,
  selectedItem,
}) => {
  const { selectedCategory } = useCategoriesContext();
  const [parentNameHasError, setParentNameHasError] = useState(false);
  const [parentCodeHasError, setParentCodeHasError] = useState(false);

  const { handleResponse } = useGraphqlResponseHandler();

  const [addVarients, { loading }] = useMutation(gql(ADD_VARIENTS));
  const form = useForm({
    mode: 'all',
    defaultValues: defaultValues || {
      name: '',
      code: '',
    },
  });
  const onSubmit = async (values) => {
    const LIMIT = 10;

    if (values.name && selectedAttribute?.partAttributeId) {
      await handleResponse(
        addVarients,
        {
          variables: {
            body: {
              partAttributeId: selectedAttribute?.partAttributeId,
              textValue: values.name,
              codeValue: values.code,
            },
          },
          update(cache, { data: attributeData }) {
            const variables = {
              query: {
                partCategoryId: selectedCategory?.partCategoryId,
                take: LIMIT,
              },
            };
            const data = cache.readQuery({ query: gql(ATTRIBUTES), variables });
            if (attributeData?.addPartAttributeSelectVariant && data) {
              const deepCopy = [...data.partAttributes];
              const updatedData = deepCopy.map((item) => {
                const itemCopy = { ...item };
                if (itemCopy?.partAttributeId === selectedAttribute?.partAttributeId) {
                  itemCopy.selectVariants = [
                    ...itemCopy.selectVariants,
                    attributeData.addPartAttributeSelectVariant,
                  ];
                }
                return itemCopy;
              });
              cache.writeQuery({
                query: gql(ATTRIBUTES),
                data: { partAttributes: updatedData },
                variables,
              });
              const updatedSelectedAttribute = updatedData.find(
                (item) => item.partAttributeId === selectedAttribute.partAttributeId,
              );
              if (updatedSelectedAttribute) {
                onKeyDown();
                handleClickAttribute(updatedSelectedAttribute);
              }
            }
          },
        },
        {
          successMessage: 'Variant successfully added',
        },
      );
    }
  };

  const onErrorParentName = (state) => setParentNameHasError(state?.name ?? false);
  const onErrorParentCode = (state) => setParentCodeHasError(state?.code ?? false);

  return (
    <CardItemContainer>
      <FormVariantsParameters
        parentNameHasError={parentNameHasError}
        parentCodeHasError={parentCodeHasError}
        loading={loading}
        selectedItem={selectedItem}
        onSubmit={onSubmit}
        variants={variants}
        defaultValues={defaultValues}
        form={form}
        onErrorParentName={onErrorParentName}
        onErrorParentCode={onErrorParentCode}
        onKeyDown={onKeyDown}
      />
    </CardItemContainer>
  );
};

export default VariantForm;

const FormVariantsParameters = ({
  loading,
  selectedItem,
  onSubmit,
  variants,
  defaultValues,
  form,
  onErrorParentName,
  onErrorParentCode,
  parentNameHasError,
  parentCodeHasError,
  onKeyDown,
}) => (
  <>
    <VariantNameInput
      parentError={parentCodeHasError}
      loading={loading}
      selectedItem={selectedItem}
      onSubmit={onSubmit}
      variants={variants}
      defaultValues={defaultValues}
      useForm={form}
      rules={variantRules}
      onError={onErrorParentName}
      onKeyDown={onKeyDown}
    />
    <VariantCodeInput
      parentError={parentNameHasError}
      loading={loading}
      selectedItem={selectedItem}
      onSubmit={onSubmit}
      variants={variants}
      defaultValues={defaultValues}
      useForm={form}
      rules={codeRules}
      onError={onErrorParentCode}
      onKeyDown={onKeyDown}
    />
  </>
);

const CardItemContainer = ({ children }) => {
  const { setVariantToAdd } = useAttributeContext();
  const onEsc = (e) => {
    if (e.keyCode === 27) {
      setVariantToAdd(null);
    }
  };

  useKeyPress(27, setVariantToAdd, null);

  return (
    <Grid
      container
      onKeyDown={onEsc}
      sx={{
        alignItems: 'center',
        height: '80px',
        borderBottom: '1px solid #cccccc',
        backgroundColor: 'rgba(0, 0, 0, 0.06)',
        pl: 2,
        pr: 1,
      }}
    >
      {children}
    </Grid>
  );
};
