import React from 'react';

import { Stack } from '@mui/material';

import PartItemButton from './PartItemButton';
import PartItemContent from './PartItemContent';
import PartItemDescription from './PartItemDescription';
import PartItemHeader from './PartItemHeader';
import PartItemHeaderElement from './PartItemHeaderElement';
import PartItemImage from './PartItemImage';
import PartItemInput from './PartItemInput';
import PartItemTitle from './PartItemTitle';

const PartItem = ({ children, sx = {}, id = '', disabled = false }) => (
  <Stack
    id={id}
    direction="row"
    data-cy={`partItem${disabled ? '-disabled' : ''}`}
    sx={{
      width: '100%',
      gap: 2,
      marginBottom: 4,
      alignItems: 'start',
      padding: 1,
      borderRadius: '5px',
      ...(disabled ? { opacity: '50%', pointerEvents: 'none' } : {}),
      ...sx,
    }}
  >
    {children}
  </Stack>
);

PartItem.Content = PartItemContent;
PartItem.Description = PartItemDescription;
PartItem.Header = PartItemHeader;
PartItem.HeaderElement = PartItemHeaderElement;
PartItem.Title = PartItemTitle;
PartItem.Image = PartItemImage;
PartItem.Input = PartItemInput;
PartItem.Button = PartItemButton;

export { PartItem };
