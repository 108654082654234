export const getFacilityId = (selectedItem) =>
  selectedItem?.type === 'FACILITY' ? selectedItem?.id : null;

export const buildVariables = (selectedItem, shopConfiguration = {}) => ({
  variables: {
    query: {
      facilityId: getFacilityId(selectedItem),
      shopConfigurationId: shopConfiguration.value,
    },
  },
});

export const buildMachineBody = (machine, selectedItem, isNew = true) => ({
  body: {
    facilityId: getFacilityId(selectedItem),
    manufacturer: machine.manufacturer,
    model: machine.model,
    serialNumber: machine.serialNumber,
    machineIdentifier: machine.machineIdentifier,
    machineImageId: machine.machineImageId,
    ...(isNew && { workCellId: machine.workCellId }),
  },
});

export const formatFlyoutMachines = (machines, shopConfigurationId) => {
  if (!Array.isArray(machines)) return [];

  return machines.map((machine) => {
    const hasWorkCells = machine.workCells.some(
      (item) => item.shopConfigurationId === shopConfigurationId,
    );
    return { ...machine, disabled: hasWorkCells };
  });
};

const rowHoverStyle = (isDragging) => ({
  backgroundColor: !isDragging && 'action.lightSurface.selected',
  '&::before': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: 2,
    top: -2,
    left: 0,
    backgroundColor: 'action.lightSurface.selected',
  },
});

export const fillBorderTopStyles = (isActive, isDragging) => ({
  position: 'relative',
  '&:hover': rowHoverStyle(isDragging),
  ...(isActive ? rowHoverStyle(isDragging) : {}),
});
