import React, { useState, useRef } from 'react';

import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import { useUser } from 'app/UserContext';
import useBreakpoint from 'hooks/useBreakpoint';

import ProfileButton from '../components/ProfileButton';
import DesktopProfileDropdown from './Desktop/DesktopProfileDropdown';
import ProfileButtonWithArrow from './Desktop/ProfileButtonWithArrow';
import EditProfileModal from './EditProfileModal/EditProfileModal';
import MobileProfileDropdown from './Mobile/MobileProfileDropdown';
import ResetPasswordModal from './ResetPasswordModal/ResetPasswordModal';

const activeModal = {
  AUTHENTICATION: 'authentication',
  RESET_PASSWORD: 'reset-password',
  LICENSE_CHECK: 'license-check',
  EDIT_PROFILE: 'edit-profile',
};

const ProfileDropdown = () => {
  const anchorRef = useRef(null);
  const { user } = useUser();
  const { isMobile } = useBreakpoint();
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const [activeModalType, setActiveModalType] = useState('');

  const openDropdown = () => setDropdownIsOpen(true);
  const closeDropdown = () => setDropdownIsOpen(false);
  const modalIsOpen = (modalType) => modalType === activeModalType;
  const closeModals = () => setActiveModalType('');

  const openModal = (modalType) => {
    closeDropdown();
    setActiveModalType(modalType);
  };

  const goBackToDropdown = () => {
    closeModals();
    openDropdown();
  };

  return (
    <ClickAwayListener onClickAway={closeDropdown}>
      <Box data-testid="desktop-profile-dropdown">
        {isMobile ? (
          <>
            <ProfileButton onClick={dropdownIsOpen ? closeDropdown : openDropdown} />
            <MobileProfileDropdown open={dropdownIsOpen} onClose={closeDropdown} />
          </>
        ) : (
          <>
            <ProfileButtonWithArrow
              ref={anchorRef}
              onClick={dropdownIsOpen ? closeDropdown : openDropdown}
            />
            <DesktopProfileDropdown
              dropdownIsOpen={dropdownIsOpen}
              licenseCheckIsOpen={modalIsOpen(activeModal.LICENSE_CHECK)}
              anchorRef={anchorRef.current}
              goBackToDropdown={goBackToDropdown}
              closeModals={closeModals}
              openModal={openModal}
              activeModal={activeModal}
            />
          </>
        )}

        <ResetPasswordModal open={modalIsOpen(activeModal.RESET_PASSWORD)} onClose={closeModals} />
        {Boolean(user) && (
          <EditProfileModal open={modalIsOpen(activeModal.EDIT_PROFILE)} onClose={closeModals} />
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default ProfileDropdown;
