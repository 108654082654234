import React from 'react';

import { Box, Typography } from '@mui/material';

import { Modal } from 'components';
import { actionColors } from 'styles/theme/colors';

import DeleteAttachmentModalFooter from './DeleteAttachmentModalFooter';

const getAttachmentName = (document) => {
  if (document?.externalUrl) return 'this URL link';
  if (!document?.externalUrl && document?.documentName) return document?.documentName;
  return '';
};

function DeleteAttachmentModal({
  modalOpen,
  selectedWorkRequestDocument,
  handleModalClose,
  handleDelete,
  loadingDelete,
}) {
  return (
    <Modal
      title="Confirm delete"
      open={modalOpen}
      onClose={handleModalClose}
      fullWidth
      titleContainerStyles={{ padding: '16px 0px 16px 16px' }}
      paperSx={{ maxWidth: 505 }}
      footer={
        <DeleteAttachmentModalFooter
          handleDelete={handleDelete}
          handleClose={handleModalClose}
          loading={loadingDelete}
        />
      }
      closeButtonColor={actionColors.lightSurface.active}
    >
      <Box sx={{ fontSize: '16px' }}>
        <Typography lineHeight="24px">
          {`Are you sure you want to delete ${getAttachmentName(selectedWorkRequestDocument)}`}?
        </Typography>
      </Box>
    </Modal>
  );
}

export default DeleteAttachmentModal;
