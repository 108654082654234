import React, { forwardRef, useContext } from 'react';

import { RightSideFlyout } from 'components';
import { Custom } from 'components/Icons';
import useBreakpoint from 'hooks/useBreakpoint';
import { TaskTypesPopup } from 'modules/Shop/ShopSetup/WorkCellSetup/WorkCellsAndMachines/FlyoutPopus';
import { WorkOrderItemsContext } from 'modules/Shop/WorkOrders/WorkOrder/context/WorkOrderItemsContext';

import { WorkRequestItemsContext } from '../WorkRequestItemsContext';
import CatalogPopupWorkRequest from './CatalogPopupWorkRequest';

const WorkRequestFlyoutMenu = forwardRef(({ isHideOptions = false }, ref) => {
  const { isTablet, isDesktop } = useBreakpoint();

  const workRequestItemsContext = useContext(WorkRequestItemsContext);
  const workOrderItemsContext = useContext(WorkOrderItemsContext);
  const { isReadOnly, workRequestStatus, WROrder } = workRequestItemsContext || {};
  const { inForgeView } = workRequestItemsContext || workOrderItemsContext;

  const staticList = [];
  const showFlyoutItems = isTablet || isDesktop;
  const wrIsPending = workRequestStatus === 'Pending';
  const WROrderIsDraft = WROrder?.workOrderStatusTypeName === 'Draft';
  const noTaskWRStatuses = ['Completed', 'In Progress'];
  const doNotShowShopTasks = noTaskWRStatuses.includes(workRequestStatus);

  const canMapTaskToWorkOrder =
    workOrderItemsContext?.canAddShopTaskToWriteInItem || workOrderItemsContext?.canAddShopTaskToCatalogItem;

  const catalogSection = {
    section: 'Catalog',
    icon: Custom.MenuBook,
    custom: false,
    children: ({ onPanelClose, onDock }) =>
      !isReadOnly && <CatalogPopupWorkRequest onDock={onDock} onPanelClose={onPanelClose} />,
  };

  const shopTasksSection = {
    section: 'Shop Tasks',
    icon: <Custom.WorkRequestFill />,
    custom: true,
    children: ({ onPanelClose }) => <TaskTypesPopup onClose={onPanelClose} />,
  };

  if (showFlyoutItems) {
    const canMapCatalogItemToWorkOrder = !workOrderItemsContext || workOrderItemsContext?.canAddCatalogItems;

    if (!isReadOnly && canMapCatalogItemToWorkOrder) staticList.push(catalogSection);

    if (!isHideOptions) {
      const canAddShopTasks = wrIsPending || WROrderIsDraft || canMapTaskToWorkOrder;
      if (!doNotShowShopTasks && canAddShopTasks) staticList.push(shopTasksSection);
    }
  }

  const flyoutItems = { staticList };

  if (inForgeView) return null;

  return <RightSideFlyout flyoutItems={flyoutItems} ref={ref} />;
});

export default WorkRequestFlyoutMenu;
