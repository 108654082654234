import React from 'react';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { useUser } from 'app/UserContext';
import { ThemedCheckBox } from 'components';
import ActionsDropdown from 'components/TableComponents/ActionsDropdown/ActionsDropdown';
import Search from 'components/TableComponents/Search';

import AdminActions from './AdminActions';
import { useAdminContext } from './AdminContext';

const AdminHeaderBar = () => {
  const { user } = useUser();
  const { selected } = useAdminContext();
  const { searchHandler, setShowDeletedUsers, openInviteModal } = useAdminContext();
  const { shopDepartmentId, isShopAdmin, isShopAdminInvitationCompleted } = useFacilitiesProjects();

  return (
    <Grid container justifyContent="space-between" wrap="nowrap">
      <Grid container item alignItems="center" wrap="nowrap">
        <ItemsSelected component="p">{selected.length} Items Selected</ItemsSelected>
        <ActionsDropdown menuItemsRenderer={() => <AdminActions />} />
      </Grid>
      <Grid container item alignItems="center" wrap="nowrap" justifyContent="flex-end">
        <ThemedCheckBox onChange={(e) => setShowDeletedUsers(e.target.checked)} />
        <Typography component="p" noWrap>
          Show Deleted Users
        </Typography>
        <Search handleSearch={searchHandler} placeholder="Search…" />
        {(user.isAdmin || (isShopAdmin && isShopAdminInvitationCompleted)) && (
          <InviteButton disabled={!shopDepartmentId} onClick={openInviteModal}>
            + Add Admin
          </InviteButton>
        )}
      </Grid>
    </Grid>
  );
};

export default AdminHeaderBar;

const ItemsSelected = ({ component, children }) => (
  <Typography component={component} sx={{ color: 'rgba(0, 0, 0, 0.5)' }}>
    {children}
  </Typography>
);

const InviteButton = ({ disabled, onClick, children }) => (
  <Button
    onClick={onClick}
    variant="contained"
    color="secondary"
    disabled={disabled}
    sx={{
      minWidth: 150,
      maxHeight: 32,
      ml: 2,
    }}
    data-testid="invite-shop-admin-btn"
  >
    {children}
  </Button>
);
