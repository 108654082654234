import React from 'react';

import { UPLOAD_FILE_EXTENSIONS } from 'constants/globalConstants';

import { LoadFileArgs } from '../WRflyoutTypes';

export const LoadFile =
  ({
    setUploading,
    setShowDialog,
    enqueueSnackbar,
    uploadFileToS3,
    addDocument,
    setUpdateWRList,
    updateWRList,
    addDocumentsToWorkRequestOrWorkOrder,
    workRequestSelected,
    workOrderSelected,
    cancelNavigation,
    shouldBlockNavigation,
    setReloadWorkOrderAttachments = () => {},
  }: LoadFileArgs) =>
  async (event: React.ChangeEvent<HTMLInputElement>) => {
    setUploading(true);
    setShowDialog(true);
    shouldBlockNavigation(true);
    try {
      const filesUploadPromises = [];
      const { files } = event.target;

      if (files) {
        const filesArray = Array.from(files ?? []);
        // eslint-disable-next-line no-restricted-syntax
        for (const file of filesArray) {
          if (!file?.name.match(UPLOAD_FILE_EXTENSIONS)) {
            enqueueSnackbar('Error: File type not supported', { variant: 'error' });
            setUploading(false);
            shouldBlockNavigation(false);
            return;
          }

          filesUploadPromises.push(
            uploadFileToS3({
              file,
            }),
          );
        }
      }
      const uploadedFiles = await Promise.all(filesUploadPromises);

      const addDocumentToFolderPromises = uploadedFiles.map(({ filename, documentStoragePath }) =>
        addDocument({
          filename,
          documentStoragePath,
        }),
      );
      const documentIds = await Promise.all(addDocumentToFolderPromises);

      await addDocumentsToWorkRequestOrWorkOrder({
        workRequestId: workRequestSelected?.workRequestId,
        workOrderId: workOrderSelected?.workOrderId,
        documentIds: [...documentIds],
      });
      setUpdateWRList(!updateWRList);
    } catch (error) {
      enqueueSnackbar(`Error uploading file`, { variant: 'error' });
      setUploading(false);
      shouldBlockNavigation(false);
      setShowDialog(false);
      if (typeof cancelNavigation === 'function') cancelNavigation();
    }
    if (event.target.files)
      enqueueSnackbar(`${event.target.files.length > 1 ? 'Files' : 'File'} successfully uploaded`, {
        variant: 'success',
      });
    setUploading(false);
    setShowDialog(false);
    shouldBlockNavigation(false);
    setReloadWorkOrderAttachments((current: boolean) => !current);
    if (typeof cancelNavigation === 'function') cancelNavigation();
  };
