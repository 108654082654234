import { useEffect, useMemo, useState } from 'react';

import { useBillOfMaterialsByIdAPI } from 'hooks-api/useBillOfMaterialsByIdAPI';

import { useBOMbyIdContext } from '../../BOMbyIdContext';
import useGetLineItemsAdded from '../Decisions/hooks/useGetLineItemsAdded';

const useGetFetchItemsFunction = ({ parentLineItemId }: { parentLineItemId: string | null }) => {
  const [selectedPadItems, setSelectedPadItems] = useState<Array<unknown>>([]);

  const values = useBOMbyIdContext();

  const { parameters } = values;
  const subItemParameters = useMemo(
    () => ({ ...parameters, parentLineItemIds: parentLineItemId }),
    [parameters, parentLineItemId],
  );

  const {
    data,
    metadata: { pagination, loading },
    callbacks: { onAddWriteInItems, onAddBOMItem, refetchItems },
    lazyLoad,
  } = useBillOfMaterialsByIdAPI({ ...subItemParameters });

  const { lineItemDecision } = useGetLineItemsAdded();

  useEffect(() => {
    if (lineItemDecision || !lazyLoad) return;
    lazyLoad(subItemParameters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lazyLoad, subItemParameters]);

  const result = useMemo(() => data || [], [data]);

  if (!parentLineItemId) return values;

  return {
    pagination,
    onAddWriteInItems,
    onAddBOMItem,
    refetchItems,
    result,
    setSelectedPadItems,
    selectedPadItems,
    bomLoading: loading,
  };
};

export default useGetFetchItemsFunction;
