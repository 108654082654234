import React from 'react';

import { useUser } from 'app/UserContext';
import { useManufacturerAPI } from 'hooks-api/useManufacturerAPI';
import { useMaterialsConfig } from 'hooks-api/useMaterialsConfig';

const ManufacturerAPIContext = React.createContext();

const ManufacturerAPIProvider = ({ children }) => {
  const { user } = useUser();
  const {
    manufactured,
    callbacks: { onGetManufactured },
  } = useMaterialsConfig();
  const {
    callbacks: { onAddNewManufacturer },
  } = useManufacturerAPI();
  const getManufacturerId = async (values) => {
    const { manufacturerId } = values;

    const newManufacturer = typeof manufacturerId === 'string' ? manufacturerId : manufacturerId.id;
    const foundManufacturer = manufactured.find(
      (item) =>
        item.id === newManufacturer || item.label.toLowerCase() === newManufacturer.toLowerCase(),
    );
    if (foundManufacturer) return foundManufacturer.id;
    const result = await onAddNewManufacturer({
      companyId: user?.companyId,
      manufacturerName: manufacturerId,
    });
    return result?.data?.addManufacturer?.manufacturerId;
  };
  const valueObj = { getManufacturerId, manufactured, onGetManufactured };
  return (
    <ManufacturerAPIContext.Provider value={valueObj}>{children}</ManufacturerAPIContext.Provider>
  );
};

const useManufacturerAPIContext = () => {
  const context = React.useContext(ManufacturerAPIContext);
  if (context === undefined)
    throw new Error('useManufacturerAPIContext must be within a ManufacturerAPIContext');
  return context;
};

export { ManufacturerAPIProvider, ManufacturerAPIContext, useManufacturerAPIContext };
