import React, { useEffect, useState } from 'react';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import CenteredLoadSpinner from 'components/CenteredLoadSpinner';
import { getStatusFilters } from 'helpers/arrayFunctions';
import useFetchWorkRequestsStatusType from 'hooks-api/useFetchWorkOrderStatusType';

import { useWorkOrdersContext } from '../WorkOrdersContext';

const STATUSBYGROUP = {
  closed: ['Draft', 'Not Started', 'In Progress', 'Blocked'],
  open: ['Completed'],
};

const WorkOrdersTabs = () => {
  const [value, setValue] = useState('open');
  const { workOrderStatusType, loading } = useFetchWorkRequestsStatusType();
  const { setFilters, setStatusToFilter } = useWorkOrdersContext();

  const handleChange = (event, newValue) => {
    event?.stopPropagation();
    setValue(newValue);
    setFilters({
      workOrderStatusIds: getStatusFilters(workOrderStatusType, STATUSBYGROUP[newValue]),
    });
    setStatusToFilter(newValue);
  };

  useEffect(() => {
    if (!loading) {
      handleChange(null, 'open');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <>
      {loading && <CenteredLoadSpinner />}
      {!loading && workOrderStatusType && (
        <Tabs value={value} onChange={handleChange} textColor="secondary" indicatorColor="secondary" sx={{ ml: 10 }}>
          <Tab
            label="Open"
            sx={{
              width: '170px',
              fontWeight: 500,
            }}
            value="open"
          />
          <Tab label="Closed" sx={{ width: '170px', fontWeight: 500 }} value="closed" />
        </Tabs>
      )}
    </>
  );
};

export default WorkOrdersTabs;
