import React, { useMemo } from 'react';

import { ISelectedItem } from 'app/FacilitiesProjectsContext';
import useGeneralContext from 'helpers/useGeneralContext';

type CurrentProjectContextType = { currentProject?: ISelectedItem } | null | undefined;

const CurrentProjectContext = React.createContext<CurrentProjectContextType>(undefined);

type CurrentProjectProviderType = {
  currentProject: ISelectedItem;
  children: React.ReactNode;
};

const CurrentProjectProvider = ({ currentProject, children }: CurrentProjectProviderType) => {
  const valueObj = useMemo(() => ({ currentProject }), [currentProject]);
  return <CurrentProjectContext.Provider value={valueObj}>{children}</CurrentProjectContext.Provider>;
};

const useCurrentProjectContext = () => useGeneralContext(CurrentProjectContext, 'CurrentProjectContext');

export { CurrentProjectContext, CurrentProjectProvider, useCurrentProjectContext };
