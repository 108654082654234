/* eslint-disable max-lines-per-function */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';

import { useLazyQuery, gql } from '@apollo/client';
import { Box } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import { Modal } from 'components';
import { partAttributeTypes as ATTRIBUTES_TYPES } from 'graphql/queries';

import { useAttributeContext } from '../AttributesSection/AttributesContext';
import AttributeForm from './AttributeForm';
import AttributeModalFooter from './AttributeModalFooter';
import SecondaryStepper from './SecondaryStepper';
import VariantCodesForm from './VariantCodesForm';

const ATTRIBUTE_STEPS = ['Attribute', 'Variant Codes'];

function getStepContent(step, isEdit, attributeTypes, addAttributeLoading) {
  return (
    <>
      <Box display={step === 1 ? 'none' : 'block'}>
        <AttributeForm
          attributeTypes={attributeTypes?.partAttributeTypes || []}
          loading={addAttributeLoading}
        />
      </Box>
      <Box display={step === 0 ? 'none' : 'block'}>
        <VariantCodesForm isEdit={isEdit} loading={addAttributeLoading} />
      </Box>
    </>
  );
}

const defaultFormValue = {
  allowWriteIn: false,
  attributeName: '',
  attributeType: '',
  variantCodes: [],
  variants: [],
};

const AttributeModal = ({ open, onClose, attributeToEdit }) => {
  const { createAttributes, addAttributeLoading } = useAttributeContext();
  const [fetchAttributeTypes, { data: attributeTypes }] = useLazyQuery(gql(ATTRIBUTES_TYPES));
  const [activeStepIndex, setActiveStepIndex] = useState(0);

  const methods = useForm({
    mode: 'all',
    defaultValues: defaultFormValue,
  });
  const {
    handleSubmit,
    setValue,
    reset,
    formState: { isValid, errors },
    watch,
  } = methods;
  const handleClose = () => {
    onClose();
    setActiveStepIndex(0);
    reset();
  };
  useEffect(() => {
    if (attributeToEdit) {
      setValue('allowWriteIn', attributeToEdit?.allowWriteInVariant);
      setValue('attributeName', attributeToEdit.partAttributeName);
      setValue('attributeType', attributeToEdit?.partAttributeType?.partAttributeTypeId);
    }

    if (attributeToEdit?.selectVariants) {
      setValue(
        'variants',
        attributeToEdit?.selectVariants.map((variant) => variant.textValue),
      );
      setValue(
        'variantCodes',
        attributeToEdit.selectVariants.map((variant) => ({
          label: variant.textValue,
          value: variant.codeValue,
        })),
      );
    }
  }, [attributeToEdit]);

  useEffect(() => {
    fetchAttributeTypes();
    setActiveStepIndex(0);
  }, []);

  const handleNext = () => {
    setActiveStepIndex(activeStepIndex + 1);
  };

  const handleBack = () => {
    setActiveStepIndex(activeStepIndex - 1);
  };

  const onSubmit = async (values) => {
    const successCallback = () => {
      handleClose();
      setActiveStepIndex(0);
      reset();
    };
    await createAttributes({ values, successCallback });
  };
  const onEnter = async (e) => {
    const codes = watch('variantCodes')
      .filter((item) => item.value.length)
      .map((item) => item.value.trim().toLowerCase());
    if (
      e.keyCode === 13 &&
      activeStepIndex === 1 &&
      isValid &&
      codes.length === watch('variantCodes').length
    ) {
      await handleSubmit(onSubmit)();
      setActiveStepIndex(0);
    }
  };
  const hasNoError = errors && Object.keys(errors).length === 0;

  return (
    <Modal
      onKeyUp={onEnter}
      title={attributeToEdit ? 'Edit Attribute' : 'Add Attribute'}
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      sx={{ maxHeight: '650px' }}
      footer={
        <AttributeModalFooter
          onNextStep={handleNext}
          onPreviousStep={handleBack}
          disableNext={!hasNoError || !isValid}
          isLastStep={activeStepIndex === ATTRIBUTE_STEPS.length - 1}
          onClickDone={handleSubmit(onSubmit)}
          disabledSubmit={!isValid || addAttributeLoading}
          loading={addAttributeLoading}
        />
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <SecondaryStepper activeStep={activeStepIndex} steps={ATTRIBUTE_STEPS} />
        <FormProvider {...methods}>
          {getStepContent(activeStepIndex, !!attributeToEdit, attributeTypes, addAttributeLoading)}
        </FormProvider>
      </form>
    </Modal>
  );
};
export default AttributeModal;
