import React, { useEffect, useState } from 'react';

import { Stack, useTheme } from '@mui/material';

import { getThemeByModule } from 'styles/theme/theme';
import { EModuleType } from 'types/module';

import TaskTypeCardInformation from './TaskTypeCardInformation';

const TaskTypeCard = ({
  taskTypeClass,
  taskTypeName,
  taskTypeDescription,
  image,
  options = undefined,
  icon,
  sx,
  disabled = false,
  children,
  ...props
}) => {
  const [taskTypeClassTheme, setTaskTypeClassTheme] = useState(useTheme());
  const [showThreeDotsMenu, setShowThreeDotsMenu] = useState(false);

  useEffect(() => {
    const theme = getThemeByModule(EModuleType[taskTypeClass.toUpperCase()]);
    setTaskTypeClassTheme(theme);
  }, [taskTypeClass]);

  const handleOnMouseOver = () => {
    if (options) setShowThreeDotsMenu(true);
  };

  const handleOnMouseLeave = () => {
    if (options) setShowThreeDotsMenu(false);
  };

  return (
    <Stack onMouseOver={() => handleOnMouseOver()} onMouseLeave={() => handleOnMouseLeave()}>
      <TaskTypeCardInformation
        showThreeDotsMenu={showThreeDotsMenu}
        taskTypeName={taskTypeName}
        taskTypeClassTheme={taskTypeClassTheme}
        taskTypeDescription={taskTypeDescription}
        image={image}
        options={options}
        icon={icon}
        sx={sx}
        disabled={disabled}
        data-testid={props['data-testid']}
      >
        {children}
      </TaskTypeCardInformation>
    </Stack>
  );
};

export default TaskTypeCard;
