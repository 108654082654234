import React, { Suspense } from 'react';

import { CircularProgress } from '@mui/material';

import ComponentPaneLayout from 'app/Layout/ReactGridLayout/ComponentPaneLayout';
import { FacilityAndProjectWrapper } from 'components';

import CompanyMembers from './CompanyMembers/CompanyMembers';
import CompanyProperties from './CompanyProperties/CompanyProperties';
import Facilities from './Facilities/Facilities';
import { Licenses } from './Licenses';
import ProjectMembers from './ProjectMembers/ProjectMembers';
import ProjectProperties from './ProjectProperties/ProjectProperties';
import Projects from './Projects/Projects';

export const Dashboard = () => (
  <ComponentPaneLayout>
    <div>Admin dashboard</div>
  </ComponentPaneLayout>
);

export const ProjectInterfaceNavContent = () => (
  <ComponentPaneLayout>
    <Suspense fallback={<CircularProgress />}>
      <Projects />
    </Suspense>
  </ComponentPaneLayout>
);

export const FacilitiesInterfaceNavContent = () => (
  <ComponentPaneLayout>
    <Suspense fallback={<CircularProgress />}>
      <Facilities />
    </Suspense>
  </ComponentPaneLayout>
);

export const MaterialMembersInterfaceNavContent = () => (
  <ComponentPaneLayout>
    <div>Material members dashboard</div>
  </ComponentPaneLayout>
);

export const CompanyMembersInterfaceNavContent = () => (
  <ComponentPaneLayout>
    <Suspense fallback={<CircularProgress />}>
      <CompanyMembers />
    </Suspense>
  </ComponentPaneLayout>
);

export const ProjectMembersInterfaceNavContent = () => (
  <FacilityAndProjectWrapper isProjectRequired>
    <ComponentPaneLayout>
      <Suspense fallback={<CircularProgress />}>
        <ProjectMembers />
      </Suspense>
    </ComponentPaneLayout>
  </FacilityAndProjectWrapper>
);

export const CompanyPropertiesInterfaceNavContent = () => (
  <ComponentPaneLayout>
    <Suspense fallback={<CircularProgress />}>
      <CompanyProperties />
    </Suspense>
  </ComponentPaneLayout>
);

export const ProjectPropertiesInterfaceNavContent = () => (
  <FacilityAndProjectWrapper isProjectRequired>
    <ComponentPaneLayout>
      <Suspense fallback={<CircularProgress />}>
        <ProjectProperties />
      </Suspense>
    </ComponentPaneLayout>
  </FacilityAndProjectWrapper>
);

export const LicensesInterfaceNavContent = () => (
  <ComponentPaneLayout>
    <Licenses />
  </ComponentPaneLayout>
);
