/* eslint-disable consistent-return */
/* eslint-disable camelcase */
import * as React from 'react';

import { History } from 'history';
import { Navigator } from 'react-router';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

type ExtendNavigator = Navigator & Pick<History, 'block'>;

type NavigationContextObject = {
  basename: string;
  navigator: ExtendNavigator;
  static: boolean;
};

export function useBlocker(blocker: any, when = true) {
  const { navigator }: NavigationContextObject = React.useContext(
    NavigationContext,
  ) as NavigationContextObject;

  React.useEffect(() => {
    if (!when) return;

    const unblock = navigator.block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry: () => {
          unblock();
          tx.retry();
        },
      };
      blocker(autoUnblockingTx);
    });

    return unblock;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigator, blocker, when]);
}
