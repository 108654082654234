import React from 'react';

import Stack from '@mui/material/Stack';

import { getNavIcon } from 'helpers/MenuIcons';

const StyledIcon = ({ iconName, highlight, disabled, sx }) => {
  const fillOpacity = (() => {
    if (disabled) return 0.25;
    return highlight ? 0.87 : 0.54;
  })();

  return (
    <Stack
      direction="row"
      sx={{
        width: 24,
        path: {
          fill: 'black',
          fillOpacity,
        },
        ...sx,
      }}
    >
      {getNavIcon(iconName)}
    </Stack>
  );
};

export default StyledIcon;
