import React from 'react';

import { Box, Stack, Typography } from '@mui/material';

import NoContentMessage from 'components/TableComponents/NoContentMessage';
import OutlinedCard from 'modules/Field/components/OutlinedCard';
import OutlinedCardHeaderText from 'modules/Field/components/OutlinedCardHeaderText';

import MiniSpinner from '../../../../../components/MiniSpinner';
import ShopConfigurationRow from './components/ShopConfigurationRow/ShopConfigurationRow';
import { useDataContext } from './contexts/DataContext';
import { useWorkCellsAndMachinesContext } from './contexts/WorkCellsAndMachinesContext';
import ShopConfigurationSelect from './ShopConfiguration/ShopConfigurationSelect/ShopConfigurationSelect';

const WorkCellsAndMachines = () => {
  const { handleAddNewWorkCell, loadingAddingWorkCell } = useWorkCellsAndMachinesContext();
  const { shopConfiguration, selectedItem, shopDepartmentId } = useDataContext();

  return (
    <>
      <Header />
      {shopDepartmentId ? (
        <WorkCellTable
          onAddButtonClick={handleAddNewWorkCell}
          disabled={!shopConfiguration || loadingAddingWorkCell}
        >
          {shopConfiguration ? <ShopConfigurationRow /> : <MiniSpinner showBorderBottom />}
        </WorkCellTable>
      ) : (
        <NoContentMessage
          text={`You must create a Shop department within Facility: ${selectedItem?.label} to view Work Cells + Machines.`}
          sx={{ margin: '270px auto' }}
        />
      )}
    </>
  );
};

export default WorkCellsAndMachines;

const Header = () => (
  <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
    <CatalogTitle />
    <Box sx={{ width: '300px' }}>
      <ShopConfigurationSelect />
    </Box>
  </Stack>
);

const CatalogTitle = () => (
  <Box marginTop="4px" mt={0.5}>
    <Typography variant="h5" component="h1">
      Work Cell Setup
    </Typography>
  </Box>
);

const WorkCellTable = ({ onAddButtonClick, disabled, children }) => (
  <OutlinedCard
    header={<OutlinedCardHeaderText paddingLeft={3}>Work Cells</OutlinedCardHeaderText>}
    addButtonLabel="New Work Cell"
    addButtonId="newWorkCellButton"
    onAddButtonClick={onAddButtonClick}
    disabled={disabled}
  >
    {children}
  </OutlinedCard>
);
