import React from 'react';

import Stack from '@mui/material/Stack';

import { IPropertiesOverlayDetailsProps } from './@types/PropertiesOverlayTypes';
import PropertiesOverlayQtyInput from './components/PropertiesOverlayQtyInput';
import PropertiesOverlayDetailItem from './PropertiesOverlayDetailItem';

const PropertiesOverlayDetails: React.FC<IPropertiesOverlayDetailsProps> = ({
  onSubmit,
  unitQty,
  uom,
  description,
  control,
}) => (
  <Stack direction="column" sx={{ paddingRight: '8px' }}>
    <PropertiesOverlayQtyInput
      title="Unit Qty"
      defaultValue={unitQty ?? 0}
      value={unitQty ?? 0}
      control={control}
      onSubmit={onSubmit}
    />
    <PropertiesOverlayDetailItem title="UOM" value={uom} />
    <PropertiesOverlayDetailItem title="Description" value={description ?? '--'} />
  </Stack>
);

export default PropertiesOverlayDetails;
