import React from 'react';

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { getNavIcon } from 'helpers/MenuIcons';
import { PREFIX_ICONS, ROW_TYPES } from 'modules/Field/LocationsAndWorkPhases/constants/constants';

import { useChips } from '../../../CreateLocationsModalContext';

const getChipNodes = (chip, isWorkPhase) => {
  if (chip.locationTypeName === ROW_TYPES.LOCATION_SYSTEM) return '';

  let nodes = 0;
  if (isWorkPhase) nodes = chip.workPhase?.length;
  else nodes = parseInt(chip.numberOfNodes, 10);
  if (nodes === 0 || !nodes) return isWorkPhase ? '# of Work Phases,' : '# of Nodes,';
  return `${nodes} ${isWorkPhase ? 'Work phase' : 'Node'}${nodes > 1 ? 's' : ''},`;
};

const useScopePackageName = () => {
  const { scopePackages } = useChips();

  const getScopePackageName = (scopePackageId) =>
    scopePackages?.find(({ id }) => id === scopePackageId)?.label || 'Scope Package';

  return { getScopePackageName };
};

const useChipName = ({ namePrefix, nameDelimiter, startOn, scopePackage }, isWorkPhase) => {
  const { getScopePackageName } = useScopePackageName();
  return isWorkPhase
    ? getScopePackageName(scopePackage)
    : `${namePrefix}${nameDelimiter !== 'none' ? nameDelimiter : ''}${startOn || ''}` || 'Location Name';
};

const StyledChipInfo = ({ chip }) => {
  const { deleteChip } = useChips();
  const { locationTypeName, isDraft } = chip;
  const isWorkPhase = locationTypeName === ROW_TYPES.WORK_PHASE;

  const chipNodes = getChipNodes(chip, isWorkPhase);
  const chipName = useChipName(chip, isWorkPhase);
  const chipLabel = `${chipNodes} ${chipName}`;

  const color = (() => {
    if (isDraft) return 'action.lightSurface.disabled';
    return 'action.lightSurface.primary';
  })();

  return (
    <Chip
      icon={<NodeIcon type={locationTypeName || ROW_TYPES.LOCATION} isDraft={isDraft} />}
      label={<Typography sx={{ fontSize: 13, marginLeft: -1, color }}>{chipLabel}</Typography>}
      onDelete={() => deleteChip(chip)}
      sx={{
        height: 24,
        '& .MuiChip-deleteIcon': {
          height: 16,
          '&:not(:hover)': {
            opacity: isDraft ? 0.26 : 0.54,
          },
        },
      }}
      clickable
    />
  );
};

export default StyledChipInfo;

const NodeIcon = ({ type, isDraft }) => (
  <Stack sx={{ marginLeft: 1, svg: { height: '14px', opacity: isDraft ? 0.26 : 0.54 } }}>
    {getNavIcon(PREFIX_ICONS[type])}
  </Stack>
);
