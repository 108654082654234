import React, { useEffect, useRef, useState } from 'react';

import { useQuery, gql } from '@apollo/client';
import { Waypoint } from 'react-waypoint';

import { workPhasesCount as COUNT } from 'graphql/queries';
import { useProject } from 'modules/Field/LocationsAndWorkPhases/contexts/ProjectContext';

import { getElementId } from '../HelperElements';
import { WaitingElement } from '../WaitingElement';
import CreateScopePackageArray from './CreateScopePackageArray';
import useScopePackageQuery from './useScopePackageQuery';
import useWorkPhaseQuery from './useWorkPhaseQuery';
import WorkPhasesItem from './WorkPhasesItem';

const WorkPhasesList = ({ item, depth = 1 }) => {
  const skip = useRef(0);
  const workPhaseSkip = useRef(0);
  const { itemSelectedId } = useProject();
  const [scopeList, setScopeList] = useState([]);
  const { fetchScopePackage, scopePackageData, scopePackageLoading, wasCalled } =
    useScopePackageQuery(50, skip.current);
  const { fetchWorkPhases, workPhasesData, workPhasesLoading } = useWorkPhaseQuery(
    50,
    workPhaseSkip.current,
  );

  const { loading, data } = useQuery(gql(COUNT), {
    variables: { query: { projectId: itemSelectedId } },
  });

  const handleScroll = () => {
    skip.current += 50;
    fetchScopePackage();
  };

  const callAllWorkPhases = async () => {
    const recalls = Math.ceil(data?.workPhasesCount / 50);
    for (let i = 0; i < recalls; i += 1) {
      workPhaseSkip.current = 50 * i;
      // eslint-disable-next-line
      await fetchWorkPhases();
    }
  };
  useEffect(() => {
    callAllWorkPhases();
    // eslint-disable-next-line
  }, [loading]);

  useEffect(() => {
    if (workPhasesData?.workPhases && scopePackageData?.scopePackage) {
      const scopePackages = CreateScopePackageArray(
        workPhasesData.workPhases,
        scopePackageData.scopePackage,
      );
      setScopeList(scopePackages);
    }
    // eslint-disable-next-line
  }, [scopePackageLoading, workPhasesLoading]);

  if (!wasCalled) {
    fetchScopePackage();
  }

  return (
    <>
      {item ? (
        <>
          {item.workPhases.map((workPhase) => (
            <WorkPhasesItem key={getElementId(workPhase)} item={workPhase} depth={depth + 1} />
          ))}
        </>
      ) : (
        scopeList?.length !== 0 &&
        scopeList?.map((scopePackage) => (
          <WorkPhasesItem
            listSetter={setScopeList}
            list={scopeList}
            key={getElementId(scopePackage)}
            item={scopePackage}
            depth={depth}
            renderProp={() => <WorkPhasesList item={scopePackage} depth={depth + 1} />}
          />
        ))
      )}

      {scopePackageData?.scopePackage?.length > 7 && (
        <Waypoint bottomOffset="-10%" onEnter={handleScroll} />
      )}
      {scopePackageLoading && <WaitingElement />}
    </>
  );
};

export default WorkPhasesList;
