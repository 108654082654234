import React, { useEffect } from 'react';

import { Box } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { ControlledInputCheckbox } from 'components';
import ControlledChipsAutoComplete from 'components/FormComponents/ControlledChipsAutoComplete';
import InputSelectField from 'components/FormComponents/InputSelectField';

import AttributeNameSearchInput from './AttributeNameSearchInput';

const generateAttributeTypeOption = (label, value, disabled) => ({
  label,
  value,
  disabled,
});

const AttributeForm = ({ loading, attributeTypes = [] }) => {
  const { control, watch, setValue } = useFormContext();
  const selectedAttributeType = watch('attributeType');
  useEffect(() => {
    setValue('variantCodes', []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAttributeType]);

  return (
    <Box pt={3}>
      <AttributeNameSearchInput autoFocus disabled={loading} />
      <AttributeTypeInput control={control} options={attributeTypes} disabled={loading} />
      {selectedAttributeType && <VariantInputs control={control} disabled={loading} />}
      <Box mt="26px">
        <AllowWriteInCheckbox control={control} />
      </Box>
    </Box>
  );
};

export default AttributeForm;

const AttributeTypeInput = ({ control, options, disabled }) => {
  let types = [];
  if (options && options.length > 0) {
    types = options
      .filter(({ partAttributeTypeName }) => partAttributeTypeName !== 'Range')
      .map((item) =>
        generateAttributeTypeOption(item?.partAttributeTypeName, item?.partAttributeTypeId, false),
      );
  }
  return (
    <InputSelectField
      margin="dense"
      variant="outlined"
      color="secondary"
      size="small"
      label="Attribute Type"
      name="attributeType"
      disabled={disabled}
      fullWidth
      control={control}
      options={types || []}
      sx={{ mt: 3 }}
      menuItemSx={{ fontSize: '16px' }}
      rules={{
        required: 'Attribute type is required',
      }}
    />
  );
};

const AllowWriteInCheckbox = ({ control }) => (
  <ControlledInputCheckbox
    color="secondary"
    name="allowWriteIn"
    control={control}
    label="Allow write-in attribute variants"
  />
);

const VariantInputs = ({ control, disabled }) => (
  <ControlledChipsAutoComplete
    options={[]}
    name="variants"
    disabled={disabled}
    color="secondary"
    control={control}
    label="Attribute Variants"
    placeholder="Variants"
    defaultValue={[]}
    sx={{ mt: 3 }}
    chipSx={{ margin: 4, padding: '3px 4px' }}
    inputProps={{ maxLength: 32 }}
    ignoreCaseAndTrailingSpaceOnSelect
    onKeyDown={(event) => {
      if (event.key === 'Backspace') {
        event.stopPropagation();
      }
    }}
    rules={{
      validate: (value) => {
        const variants = value
          .filter((item) => item.length)
          .map((item) => item.trim().toLowerCase() || item.trim().length < 0);
        if (!value.length) {
          return 'At least one attribute variant is required';
        }
        if (new Set(variants).size !== value.length) {
          return 'Value is duplicated.';
        }

        return true;
      },
    }}
  />
);
