/* eslint-disable max-lines-per-function */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { gql, useLazyQuery } from '@apollo/client';
import { Box, CircularProgress } from '@mui/material';

import { useUser } from 'app/UserContext';
import { partAttributeVariantData as PART_ATTRIBUTES_VARIANT_DATA } from 'appsync/queries';
import { ForesiteAutocomplete } from 'components/FormComponents';
import { partById as PART_BY_ID } from 'graphql/queries';
import { debounce } from 'helpers/common';
import usePartsCloudSearchAPI from 'hooks-api/usePartsCloudSearchAPI';

import PartOption from './PartOption';

const NoItemOption = { key: 'no_item_option', label: 'No items match your search...' };

const AddNewItemOption = { key: 'add_new_item_option', label: '+ Add a write-in item' };

function PartsAutocomplete({
  onCreate = () => {},
  onSelect,
  onChange = () => {},
  onInputChange = () => {},
  onBlur = () => {},
  ...restProps
}) {
  const searchKeyRef = useRef('');
  const [parts, setParts] = useState([]);
  const [open, setOpen] = useState(false);
  const { user } = useUser();
  const [value, setValue] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');

  const [fetchPart, { loading: loadingFetchPart }] = useLazyQuery(gql(PART_BY_ID), {
    fetchPolicy: 'cache-and-network',
  });

  const [fetchPartAttributeVariantData, { loading: loadingetchPartAttributeVariantData }] = useLazyQuery(
    gql(PART_ATTRIBUTES_VARIANT_DATA),
    { fetchPolicy: 'cache-and-network' },
  );

  const { data: partsResponse, loading: loadingParts, searchParts } = usePartsCloudSearchAPI(user?.companyId, 4);

  const loading = useMemo(
    () => loadingFetchPart || loadingetchPartAttributeVariantData || loadingParts,
    [loadingFetchPart, loadingetchPartAttributeVariantData, loadingParts],
  );

  useEffect(() => setParts(partsResponse ?? []), [partsResponse]);

  const fetchParts = useCallback(
    async (searchKey) => {
      setParts([]);
      if (searchKey.length < 3) return;
      searchKeyRef.current = searchKey;
      searchParts(searchKey);
    },
    [searchParts],
  );

  const handleInputChange = debounce((_event, value) => setValue(value));

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), 300);
    return () => clearTimeout(timer);
  }, [value]);

  useEffect(() => fetchParts(debouncedValue), [fetchParts, debouncedValue]);

  const handleSelectItem = useCallback(
    async (item) => {
      setOpen(false);
      const partId = item.partHistoryRefId;
      const {
        data: { partById: selectedPart },
      } = await fetchPart({ variables: { params: { id: partId } } });

      const {
        data: { partAttributeVariantData: existingVariants },
      } = await fetchPartAttributeVariantData({
        variables: { query: { partId } },
      });

      const tradeSizeValue = existingVariants?.reduce((existing, current) => {
        if (current?.partAttribute?.partAttributeName?.trim() === 'Trade Size')
          return current?.selectValues?.length ? current?.selectValues[0].textValue : '';

        return existing;
      }, null);

      if (selectedPart) onSelect({ ...selectedPart, tradeSize: tradeSizeValue });
    },
    [fetchPart, onSelect, fetchPartAttributeVariantData],
  );

  const filterOptions = (options, { inputValue }) => {
    if (!inputValue || loadingParts) return options;
    if (!options.length) return [NoItemOption, AddNewItemOption];
    return [...options, AddNewItemOption];
  };

  const renderOption = (props, option) => (
    <PartOption
      {...props}
      option={option}
      searchKey={searchKeyRef.current}
      onSelect={handleSelectItem}
      onCreate={onCreate}
    />
  );

  return (
    <Box sx={{ position: 'relative' }}>
      {loading && <CircularProgress sx={{ position: 'absolute', left: -46 }} size={24} color="secondary" />}
      <ForesiteAutocomplete
        onBlur={onBlur}
        disabled={loadingetchPartAttributeVariantData || loadingFetchPart}
        onChange={onChange}
        id="parts-autocomplete"
        sx={{ width: 470 }}
        textFieldProps={getTextFieldProps(onInputChange, handleKeyDown)}
        freeSolo
        disablePortal
        openOnFocus
        options={parts}
        filterOptions={filterOptions}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        onInputChange={handleInputChange}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        size="extra-small"
        {...restProps}
      />
    </Box>
  );
}

export default PartsAutocomplete;

const autocompleteTextFieldStyles = {
  '.MuiOutlinedInput-root': { padding: '0px !important' },
  '.MuiOutlinedInput-input': { padding: '2px 12px !important' },
};

const getTextFieldProps = (onInputChange, handleKeyDown) => ({
  onChange: onInputChange,
  autoFocus: true,
  placeholder: 'Search catalog items...',
  onKeyDown: handleKeyDown,
  sx: autocompleteTextFieldStyles,
  size: 'extra-small',
});

const getOptionLabel = (option) => {
  if (option.key) return option.label;
  return option.partName;
};

const handleKeyDown = (event) => {
  if (event.key === 'Enter') event.stopPropagation();
};
