import { ROW_TYPES } from '../../../constants/constants';
import { useLocationsAndWorkPhases } from '../../../contexts/LocationsAndWorkPhaseContext';
import { childrenIncludeType, createOption } from './moreMenuHelpers';
import { useGeneralOptions } from './useGeneralOptions';

const getFloorOptionsByChildren = (node, addLocation) => {
  if (childrenIncludeType(node, ROW_TYPES.FLOOR))
    return [
      createOption('New location system', () => addLocation(node, ROW_TYPES.LOCATION_SYSTEM)),
    ];

  if (childrenIncludeType(node, ROW_TYPES.ZONE))
    return [
      createOption('New zone', () => addLocation(node, ROW_TYPES.ZONE)),
      createOption('New location system', () => addLocation(node, ROW_TYPES.LOCATION_SYSTEM)),
    ];

  if (childrenIncludeType(node, ROW_TYPES.LOCATION_SYSTEM))
    return [
      createOption('New location system', () => addLocation(node, ROW_TYPES.LOCATION_SYSTEM)),
    ];

  return [
    createOption('New zone', () => addLocation(node, ROW_TYPES.ZONE)),
    createOption('New location system', () => addLocation(node, ROW_TYPES.LOCATION_SYSTEM)),
  ];
};

export const useFloorOptions = (node, parent) => {
  const {
    locations: { handleAddLocation },
  } = useLocationsAndWorkPhases();
  const { getGeneralOptions } = useGeneralOptions(node, parent);

  const getFloorOptions = () => [
    ...getFloorOptionsByChildren(node, handleAddLocation),
    createOption('New floor above', () => handleAddLocation(node, ROW_TYPES.FLOOR, 'above')),
    createOption('New floor below', () => handleAddLocation(node, ROW_TYPES.FLOOR, 'below')),
    ...getGeneralOptions(),
  ];

  return { getFloorOptions };
};
