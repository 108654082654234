import React from 'react';

import { InputForesiteField } from '@evolvemep/foresite-components-ui-react';
import { Stack, Box, Divider, Typography } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';

import { InputImage, ControlledAutoComplete } from 'components';
import InputSelectField from 'components/FormComponents/InputSelectField';
import { addressInput, cityInput, countryInputRules, maxLengthRule } from 'constants/inputFieldRules';
import stateCodes from 'helpers/stateCodes';

import DesignDepartmentInput from './DesignDepartmentInput';
import ProcurementDepartmentInput from './ProcurementDepartmentInput';
import ProjectTypeSelect from './ProjectTypeSelect';
import ShopDepartmentInput from './ShopDepartmentInput';

const unitOptions = [
  { value: 'METRIC', label: 'Metric' },
  { value: 'IMPERIAL', label: 'Imperial' },
];

const FormFields = ({ actionRenderer, idRequired = false }) => {
  const { control } = useFormContext();
  return (
    <>
      <Box width="50%" maxWidth="505px">
        <ControlInput name="projectName" label="Project Title" rules={maxLengthRule('Project Title')} />
        <ControlInput
          name="projectIdentifier"
          label="Project Identifier"
          rules={{
            ...maxLengthRule('Project Title'),
            required: idRequired ? 'This field is required' : null,
          }}
        />
        <ProjectTypeSelect />
        <ProcurementDepartmentInput control={control} />
        <DesignDepartmentInput control={control} />
        <ShopDepartmentInput />
        <DropDown name="units" label="Units" options={unitOptions} />
        <Divider sx={{ margin: '16px 0 8px' }} />
        <ControlInput name="addressLine1" label="Address Line 1" rules={addressInput('Address Line 1')} />
        <ControlInput name="addressLine2" label="Address Line 2" rules={addressInput('Address Line 2')} />
        <ControlInput control={control} name="city" label="City" rules={cityInput('City')} />
        <Stack mt={0} direction="row" gap="16px">
          <ControlledAutoComplete
            name="state"
            label="State/Province/Region"
            control={control}
            options={stateCodes}
            fullWidth
          />
          <ControlInput control={control} name="zip" label="Zip/Postal Code" />
        </Stack>
        <ControlInput name="country" label="Country" rules={countryInputRules('Country')} />
        {actionRenderer()}
      </Box>
      <ImageField name="image" />
    </>
  );
};

export default FormFields;

const ImageField = ({ name }) => {
  const { control } = useFormContext();
  const {
    field: { value },
  } = useController({
    name,
    control,
  });

  return (
    <Box mt={2}>
      <InputImage
        sx={{ width: 306, height: 198, cursor: 'pointer' }}
        margin="none"
        name={name}
        label="Image"
        control={control}
        defaultValue={value}
        accept="image/png, image/jpg, image/jpeg, image/heic, image/gif"
      />
      <Typography align="left" mt={2} sx={{ fontSize: '12px', lineHeight: '24px', opacity: 0.5, maxWidth: 306 }}>
        Select the image you wish to upload. Images should not be more than 500KB in size. Only GIF, PNG, JPEG, HEIC
        files are accepted.
      </Typography>
    </Box>
  );
};

const ControlInput = ({ ...props }) => {
  const { control } = useFormContext();
  return <InputForesiteField control={control} size="small" color="secondary" {...props} />;
};

const DropDown = ({ label, options, ...rest }) => {
  const { control } = useFormContext();
  return (
    <InputSelectField
      control={control}
      color="secondary"
      size="small"
      label={label}
      fullWidth
      options={options}
      {...rest}
    />
  );
};
