import React from 'react';

import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';

const StyledLink = ({ label, url, linkState, companyId, onClick }) => {
  const navigate = useNavigate();

  return (
    <Link
      onClick={onClick || (() => navigate(url, { state: { linkState, companyId } }))}
      sx={{
        color: 'secondary.main',
        cursor: 'pointer',
        textDecorationColor: 'transparent',
      }}
    >
      {label}
    </Link>
  );
};

export default StyledLink;
