import {
  addressRegEx,
  nameRegEx,
  passwordRegEx,
  phoneRegEx,
  cityRegEx,
  countryRegEx,
  positiveAndIntegerNumber,
} from './regEx';

export const requiredRule = (fieldName, message = `${fieldName} is required.`) => ({
  required: message,
});

export const patternRule = (regexp, fieldName, message = `${fieldName} is not valid.`) => ({
  pattern: { value: regexp, message },
});

export const minRule = (fieldName, minValue = 1) => ({
  min: { value: minValue, message: `${fieldName} must be more than ${minValue}.` },
});

export const maxRule = (fieldName, maxValue = 1, message) => ({
  max: { value: maxValue, message: message || `${fieldName} limited to ${maxValue}.` },
});

export const minLengthRule = (fieldName, minLength, hyphensAmount = 0, equalsMaxLength) => {
  const message = equalsMaxLength
    ? `${fieldName} must have ${minLength} characters.`
    : `${fieldName} should be more than ${minLength} characters.`;
  return {
    minLength: { value: minLength + hyphensAmount, message },
  };
};

export const maxLengthRule = (fieldName, maxLength = 30, hyphensAmount = 0, equalsMinLength) => {
  const message = equalsMinLength
    ? `${fieldName} must have ${maxLength} characters.`
    : `${fieldName} should be less than ${maxLength} characters.`;
  return {
    maxLength: { value: maxLength + hyphensAmount, message },
  };
};

export const customMaxLengthRule = (maxLength = 30, message) => ({
  maxLength: { value: maxLength, message },
});

export const CURRENT_PASSWORD_RULES = {
  ...requiredRule('Password'),
  ...minLengthRule('Password', 8),
  ...maxLengthRule('Password', 20),
};

export const PASSWORD_RULES = {
  ...CURRENT_PASSWORD_RULES,
  ...patternRule(passwordRegEx, '', 'Password is not strong.'),
};

export const confirmPasswordRules = (pw) => ({
  ...requiredRule('Confirm password'),
  validate: (value) => value === pw || 'Passwords do not match.',
});

export const textInputPattern = (fieldName, maxLength = 30) => ({
  ...patternRule(nameRegEx, fieldName),
  ...maxLengthRule(fieldName, maxLength),
});

export const textInputRules = (fieldName, maxLength = 30) => ({
  ...requiredRule(fieldName),
  ...patternRule(nameRegEx, fieldName),
  ...maxLengthRule(fieldName, maxLength),
});

export const addressInput = (fieldName, maxLength = 40) => ({
  ...patternRule(addressRegEx, '', `${fieldName} is not a valid address.`),
  ...maxLengthRule(fieldName, maxLength),
});

export const addressInputRules = (fieldName, maxLength = 40) => ({
  ...requiredRule(fieldName),
  ...patternRule(addressRegEx, '', `${fieldName} is not a valid address.`),
  ...maxLengthRule(fieldName, maxLength),
});

export const cityInput = (fieldName, maxLength = 30) => ({
  ...patternRule(cityRegEx, fieldName),
  ...maxLengthRule(fieldName, maxLength),
});

export const cityInputRules = (fieldName, maxLength = 30) => ({
  ...requiredRule(fieldName),
  ...patternRule(cityRegEx, fieldName),
  ...maxLengthRule(fieldName, maxLength),
});

export const countryInputRules = (fieldName, maxLength = 30) => ({
  ...cityInput(fieldName, maxLength),
  ...patternRule(countryRegEx, fieldName),
});

const getAmountOfAllowedHyphens = (hyphensPositions, allowedIndex) =>
  hyphensPositions.filter((hyphensPosition) => hyphensPosition < allowedIndex).length;

export const phoneInputRules = (fieldName, hyphensPositions, minLength = 10, maxLength = 10) => ({
  ...patternRule(phoneRegEx, '', 'Is not a valid phone.'),
  ...minLengthRule(
    fieldName,
    minLength,
    getAmountOfAllowedHyphens(hyphensPositions, minLength),
    minLength === maxLength,
  ),
  ...maxLengthRule(
    fieldName,
    maxLength,
    getAmountOfAllowedHyphens(hyphensPositions, maxLength),
    minLength === maxLength,
  ),
});

function isRenewalDateValid(dateStr) {
  const regex = /^\d{2}\/\d{2}$/;
  if (dateStr.match(regex) === null) return false;
  const [month, day] = dateStr.split('/');
  const isoFormattedStr = `1977-${month}-${day}`;
  const date = new Date(isoFormattedStr);
  const timestamp = date.getTime();
  if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) return false;
  return date.toISOString().startsWith(isoFormattedStr);
}

export const renewalDateRules = (fieldName) => ({
  ...requiredRule(fieldName),
  validate: (date) => isRenewalDateValid(date) || 'Invalid date',
});

export const positiveNumberRules = (fieldName, minValue = 1, message = `${fieldName} is required.`) => ({
  ...requiredRule(fieldName, message),
  ...minRule(fieldName, minValue),
});

export const positiveTrialDurationNumberRules = (fieldName, minValue = 1, maxValue = 90) => ({
  ...requiredRule(fieldName),
  ...minRule(fieldName, minValue),
  ...maxRule(fieldName, maxValue),
});

export const positiveAndIntegerNumberRules = (fieldName) => ({
  ...patternRule(positiveAndIntegerNumber, fieldName),
});

export const alreadyExistsRule = (array = [], errorMessage = 'Id already exists', strictEqual = false) => ({
  validate: (value) =>
    !array.some((id) => {
      if (strictEqual) return id === value;
      return id?.trim().toLowerCase() === value?.trim().toLowerCase();
    }) || errorMessage,
});

export const multiSearchRule = (array = [], errorMessage = 'Name already exists') => ({
  validate: () => {
    if (array?.length) return errorMessage;
    return true;
  },
});
