export const getLocationString = (chipList) => {
  const locations = chipList.reduce((acc, item) => {
    if (acc === '' && item.filterType === 'Location') return `${item.locationId}`;
    if (item.filterType === 'Location') return `${acc},${item.locationId}`;
    return acc;
  }, '');
  return locations;
};

export const getLocationPhasesString = (chipList) => {
  const locationPhases = chipList.reduce((acc, item) => {
    if (acc === '' && item.filterType === 'LocationPhase')
      return `${item.locationId}:${item.workPhaseId}`;
    if (item.filterType === 'LocationPhase') return `${acc},${item.locationId}:${item.workPhaseId}`;
    return acc;
  }, '');
  return locationPhases;
};

export const getWorkPhasesString = (chipList) => {
  const phases = chipList.reduce((acc, item) => {
    if (acc === '' && item.filterType === 'WorkPhase') return `${item.workPhaseId}`;
    if (item.filterType === 'WorkPhase') return `${acc},${item.workPhaseId}`;
    return acc;
  }, '');
  return phases;
};

export const getScopePackagesString = (chipList) => {
  const phases = chipList.reduce((acc, item) => {
    if (acc === '' && item.filterType === 'ScopePackage') return `${item.id}`;
    if (item.filterType === 'ScopePackage') return `${acc},${item.id}`;
    return acc;
  }, '');
  return phases;
};
