import React, { useState } from 'react';

import useFacilities from './hooks/useFacilities';
import useTasksDataLayer from './hooks/useTasksDataLayer';
import useWorkCellsDataLayer from './hooks/useWorkCellsDataLayer';

const WorkCellQueueContext = React.createContext();

const WorkCellQueueContextProvider = ({ children }) => {
  const [activeTabIndex, setTabIndex] = useState(0);

  const { selectedFacilityId, shopDepartmentId } = useFacilities();
  const { workCells, loadingWorkCells } = useWorkCellsDataLayer(selectedFacilityId);

  const {
    workCellsTasks,
    loadingWorkCellsTasks,
    tasksCounter,
    loadingDeleteTask,
    paginationHandler,
    handleDeleteTask,
    refetchTasks,
  } = useTasksDataLayer(activeTabIndex);

  const value = {
    activeTabIndex,
    setTabIndex,
    workCells,
    loadingWorkCells,
    selectedFacilityId,
    shopDepartmentId,
    workCellsTasks,
    loadingWorkCellsTasks,
    tasksCounter,
    loadingDeleteTask,
    paginationHandler,
    handleDeleteTask,
    refetchTasks,
  };

  return <WorkCellQueueContext.Provider value={value}>{children}</WorkCellQueueContext.Provider>;
};

const useWorkCellQueueContext = () => {
  const context = React.useContext(WorkCellQueueContext);
  if (context === undefined) throw new Error('useWorkCellQueueContext must be used within a WorkCellQueueContext');
  return context;
};

export { WorkCellQueueContext, WorkCellQueueContextProvider, useWorkCellQueueContext };
