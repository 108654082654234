import React, { useEffect, useState } from 'react';

import { taskDocument as TASK_DOCUMENTS } from 'graphql/queries';

import usePaginatedDocuments, { CombinedDocumentType } from './usePaginatedDocuments';

type UseTaskDocumentsType = {
  tasksDocuments: CombinedDocumentType[];
  loading: boolean;
  paginationWaypoint: () => React.ReactNode;
};

const useTaskDocuments = (
  taskIds: string[],
  documentTypeIds: string | null,
  includeExternalUrl = false,
  take = 50,
): UseTaskDocumentsType => {
  const [taskIdsCalled, setTaskIdsCalled] = useState<string | null>(null);
  const { documents, loading, handleFetchDocuments, paginationWaypoint } = usePaginatedDocuments(
    TASK_DOCUMENTS,
    'taskDocument',
    take,
  );

  useEffect(() => {
    const taskIdsComma = taskIds.sort().join(',');
    const selectAnotherWorkRequest = taskIdsComma !== taskIdsCalled || !taskIdsCalled;
    const isAvailableToFetch =
      taskIds.some((taskId) => Boolean(taskId)) && documentTypeIds?.length && selectAnotherWorkRequest;
    if (!isAvailableToFetch) return;

    setTaskIdsCalled(taskIdsComma);
    handleFetchDocuments({ taskIds: taskIds?.join(','), documentTypeIds, includeExternalUrl });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentTypeIds, taskIds]);

  return {
    tasksDocuments: documents,
    loading,
    paginationWaypoint,
  };
};

export default useTaskDocuments;
