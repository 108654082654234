/* eslint-disable import/no-cycle */
import React from 'react';

import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro';

import { Custom } from 'components/Icons';
import CustomDetailPanelToggle from 'modules/Field/WorkRequests/WorkRequest/components/CustomDetailPanelToggle';
import {
  LINE_ITEM_TYPE_ASSEMBLY_ID,
  LINE_ITEM_TYPE_PART_ID,
  LINE_ITEM_TYPE_WRITE_IN_ID,
} from 'modules/Materials/Constants/constants';

import { NOT_VALUES_SET } from './components/QtyField/constants';

export const MIN_WIDTH = 30;
export const BOM_TABLE_PROPS = {
  disableSelectionOnClick: true,
  disableColumnMenu: true,
  disableColumnReorder: true,
  checkboxSelection: false,
  rowHeight: 32,
  headerHeight: 32,
  columnTypes: {
    string: GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  },
  sx: {
    '& .MuiDataGrid-main': {
      flex: 1,
    },
    '& .MuiDataGrid-detailPanel': {
      backgroundColor: 'transparent',
    },
    '&.MuiDataGrid-root .MuiDataGrid-cell': {
      borderTop: 'none',
    },
    '& .MuiDataGrid-pinnedColumnHeaders, & .MuiDataGrid-pinnedColumns': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
    '& .Mui-hovered, .MuiDataGrid-row:hover': {
      backgroundColor: 'transparent !important',
    },
    '& .MuiDataGrid-pinnedColumns--left, & .MuiDataGrid-pinnedColumns--left > .MuiDataGrid-row, & .MuiDataGrid-pinnedColumns--left > .MuiDataGrid-row > .MuiDataGrid-cell':
      {
        minWidth: '10px !important',
        width: '10px !important',
        backgroundColor: 'red',
      },
    '& .MuiDataGrid-cell--textRightNoPadding': {
      paddingRight: '0 !important',
      textAlignt: 'right !important',
      display: 'flex !important',
      justifyContent: 'end !important',
    },
  },
  initialState: {
    pinnedColumns: {
      right: ['qtyRemaining', 'qtyToAdd'],
    },
  },
};
export const toggleDetailPanelBtn = {
  ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  renderCell: ({ id, value }: { id: string; value: boolean }) => <CustomDetailPanelToggle id={id} value={value} />,
};

export const ADD_REMAINING_QUANTITIES_FIELD = 'addRemainingQuantities';

export const ITEM_ICON_DICT: any = {
  [LINE_ITEM_TYPE_ASSEMBLY_ID]: <Custom.AssemblyIcon width="24px" />,
  [LINE_ITEM_TYPE_PART_ID]: <Custom.PartThumbnail width="20px" />,
  [LINE_ITEM_TYPE_WRITE_IN_ID]: <Custom.WriteInItemIcon width="20px" />,
};

export const ITEMS_INTEGRATION_TABLE_CONTEXT = {
  rows: [],
  setRows: () => {},
  columns: [],
  methods: undefined,
  quantityFields: [],
  setQuantityFields: () => {},
  maxNestLevel: {},
  setMaxNestLevel: () => {},
  autoFilledFields: [],
  setAutoFilledFields: () => {},
  loadingBOMRows: false,
  setLoadingBOMRows: () => {},
  hoveredRow: '',
  setHoveredRow: () => {},
  baseERN: '',
  valuesBeforeAutofill: NOT_VALUES_SET,
  setValuesBeforeAutofill: () => {},
  onItemsCreated: () => {},
  isBOMtoWR: false,
  quantityFieldsByLineItem: null,
  fieldErrors: [],
  setFieldErrors: () => {},
  resetAllRows: () => {},
};

export const PBS_FIELD = 'projectBreakdownStructure';

export const UNASSIGNED = 'UNASSIGNED';
