import React from 'react';

import { CenteredLoadSpinner } from 'components';

const MiniSpinner = ({ height = '32px', size = '20px', showBorderBottom = false, theme, sx = {} }) => (
  <CenteredLoadSpinner
    sx={{
      mt: 0,
      alignItems: 'center',
      height,
      '& span': {
        width: `${size} !important`,
        height: `${size} !important`,
      },
      ...(showBorderBottom ? { borderBottom: '1px solid', borderBottomColor: 'other.border' } : {}),
      ...sx,
    }}
    theme={theme}
  />
);

export default MiniSpinner;
