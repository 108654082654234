import React, { Suspense } from 'react';

import { CircularProgress } from '@mui/material';
import Stack from '@mui/material/Stack';
import { Route, Routes, Navigate } from 'react-router-dom';

import AcceptInvitation from './AcceptInvitation';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import Login from './Login/Login';
import ResetPassword from './ResetPassword';
import VerifyEmailPrompt from './VerifyEmailPrompt';

const AuthBackground = React.lazy(() => import('./AuthBackground'));

const AuthenticationRoutes = () => (
  <Suspense fallback={<CircularProgress />}>
    <AuthenticationLayout>
      <AuthBackground />
      <AuthPageWrapper>
        <Routes>
          {/* <Route path="register" element={<Registration />} /> */}
          <Route path="login" element={<Login />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="password-reset" element={<ResetPassword />} />
          <Route path="email-confirmation" element={<VerifyEmailPrompt />} />
          <Route path="accept" element={<AcceptInvitation />} />
          <Route path="*" element={<Navigate to="login" />} />
        </Routes>
      </AuthPageWrapper>
    </AuthenticationLayout>
  </Suspense>
);

export default AuthenticationRoutes;

const AuthPageWrapper = ({ children }) => (
  <Stack
    direction="row"
    alignItems="center"
    justifyContent="center"
    sx={({ breakpoints }) => ({
      width: '100%',
      maxWidth: '700px',
      minHeight: 'calc(100vh - 120px)',
      [breakpoints.up('md')]: {
        width: '40%',
        minHeight: 'calc(100vh - 425px)',
      },
    })}
  >
    {children}
  </Stack>
);

const AuthenticationLayout = ({ children }) => (
  <Stack
    sx={({ breakpoints }) => ({
      backgroundColor: '#fff',
      [breakpoints.up('md')]: {
        flexDirection: 'row-reverse',
      },
    })}
  >
    {children}
  </Stack>
);
