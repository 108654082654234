import React, { FC, useCallback, useMemo, useState } from 'react';

import { Box, useTheme } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';

import StackRow from 'components/StackRow';
import { BillOfMaterialLineItemByLBS } from 'graphql/types';
import { PickIcon } from 'modules/Materials/commons/FilterPopoverContent/Breakdown/HelperElements';
import { BOMDataType } from 'modules/Materials/components/ItemsIntegrationModal/@types/ItemsIntegrationModal';

import { ItemsIntegrationItemType } from '../../../@types/itemsIntegrationTableContext';
import { PBS_FIELD } from '../../constants';
import ArrowDetailIcon from '../ArrowDetailIcon/ArrowDetailIcon';
import LBSRowInfo from '../LocationRow/LBSRowInfo';
import LBSHeaders from '../LocationsTable/components/LBSHeaders';
import RowsNested, { IRowsNested } from '../RowsNested/RowsNested';
import RowWrapper from '../RowWrapper/RowWrapper';
import UnassignedRow from '../UnassignedRow/UnassignedRow';
import WorkPhasesScopePackageTable from '../WorkPhasesScopePackageTable/WorkPhasesScopePackageTable';

export type ProjectInfoType = {
  id?: string;
  identifier?: string;
  locationName?: string;
  children?: BillOfMaterialLineItemByLBS[];
};

interface IProjectRow {
  projectInfo: ProjectInfoType;
  lineItemId: string;
  workPhases: BillOfMaterialLineItemByLBS[];
  lineItem: ItemsIntegrationItemType;
  show?: boolean;
}

const ProjectRow: FC<IProjectRow> = ({ projectInfo, lineItemId, workPhases = [], lineItem, show }: IProjectRow) => {
  const { palette }: any = useTheme();
  const [showRows, setShowRows] = useState<boolean>(true);
  const handleShowRows = useCallback(() => setShowRows(!showRows), [showRows]);
  const { control } = useFormContext();
  const projectBreakdownStructure = useWatch({ control, name: PBS_FIELD });
  const BOMData = useMemo(() => ({ id: lineItemId }), [lineItemId]);
  if (!show) return null;
  return (
    <>
      <LBSHeaders />
      <RowWrapper>
        <StackRow sx={{ flex: 1, gap: '8px', width: '418px' }}>
          <Box sx={{ width: '8px' }} />
          <StackRow>
            <ArrowDetailIcon initialValue onToggle={() => handleShowRows()} show fillBox={false} />
            <PickIcon
              iconSx={{
                '& svg, path': {
                  fill: `${
                    showRows ? palette.surface.lightSurface.primary : palette.surface.lightSurface.secondary
                  } !important`,
                  fillOpacity: '1 !important',
                },
              }}
              margin="0"
              type="Project"
              chipWidth={undefined}
            />
          </StackRow>
          <LBSRowInfo rowInfo={projectInfo} />
        </StackRow>
      </RowWrapper>
      <UnassignedRow lineItem={lineItem} />
      <RowsNestedWrapper
        projectBreakdownStructure={projectBreakdownStructure}
        showRows={showRows}
        BOMData={BOMData}
        workPhases={workPhases}
        show={showRows}
        rows={projectInfo.children}
        lineItemId={lineItemId}
      />
    </>
  );
};

export default ProjectRow;

interface IRowsNestedWrapper extends IRowsNested {
  projectBreakdownStructure: string;
  showRows: boolean;
  BOMData: BOMDataType;
}

const RowsNestedWrapper: FC<IRowsNestedWrapper> = ({
  projectBreakdownStructure,
  showRows,
  BOMData,
  ...rest
}: IRowsNestedWrapper) => {
  if (!showRows) return null;
  if (projectBreakdownStructure === 'Work Phase') return <WorkPhasesScopePackageTable BOMData={BOMData} />;
  return <RowsNested {...rest} />;
};
