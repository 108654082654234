/* eslint-disable no-unused-vars */
import React from 'react';

import { Checkbox, FormControlLabel, FormLabel, Stack } from '@mui/material';

const PrivacyForm = ({ watch, setValue, onCheckChanged }) => (
  <>
    <CheckboxWrapper label="Privacy">
      <CheckBoxField
        setValue={setValue}
        watch={watch}
        name="dataAnalytics"
        label="Publish to Data Analytics"
        onChange={onCheckChanged}
      />
    </CheckboxWrapper>
  </>
);

export default PrivacyForm;

const CheckboxWrapper = ({ label, children }) => (
  <>
    <FormMainLabel>{label}</FormMainLabel>
    <Stack sx={{ pl: 2 }}>{children}</Stack>
  </>
);

const FormMainLabel = ({ children }) => (
  <FormLabel
    sx={{
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.15px',
      color: 'surface.lightSurface.primary',
      mt: 3,
    }}
  >
    {children}
  </FormLabel>
);

const CheckBoxField = ({ label, name, setValue, watch, onChange }) => (
  <FormControlLabel
    label={label}
    control={
      <Checkbox
        color="secondary"
        checked={watch(name)}
        name={name}
        onChange={(_, isChecked) => {
          setValue(name, isChecked);
          onChange();
        }}
      />
    }
  />
);
