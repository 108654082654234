import React from 'react';

import { Button, ButtonGroup } from '@mui/material';

import { Custom } from 'components/Icons';

const SelectWorkRequest = ({ disabled }) => (
  <ButtonGroup disabled={disabled} size="small" color="secondary">
    <Button sx={{ color: 'secondary.dark' }}>Actions</Button>
    <Button size="small" sx={{ backgroundColor: disabled ? 'rgba(166, 166, 166, 0.08)' : 'rgba(55, 165, 79, 0.08)' }}>
      <Custom.ArrowDropDown fill="#9E9E9E" />
    </Button>
  </ButtonGroup>
);

export default SelectWorkRequest;
