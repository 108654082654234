import React, { useState, useEffect, ReactNode } from 'react';

import { useUser } from 'app/UserContext';
import { isNotNil } from 'helpers/isNotNil';
import { useLicensesGet } from 'hooks-api/useLicensesGet';
import { useWrappedPaginatedGet } from 'hooks-api/useWrappedApiCall';
import { IncludeDeletedOptions, ProductPool, User } from 'types/types-api';

type CompanyMembersContextType = {
  selected: User[];
  setSelected: (selected: User[]) => void;
  showDeletedUsers: boolean;
  setShowDeletedUsers: (showDeletedUsers: boolean) => void;
  refetch: () => Promise<void>;
  paginationHandler: () => Promise<void>;
  searchHandler: (searchString: string) => void;
  sortHandler: (sortBy: string) => void;
  loading: boolean;
  data: User[] | undefined | null;
  selectedLicense: LicenseDropdown;
  setSelectedLicense: (license: LicenseDropdown) => void;
  dataProductPools: ProductPool[];
  loadingProductPools: boolean;
};

const CompanyMembersContext = React.createContext<CompanyMembersContextType | undefined>(undefined);

type LicenseDropdown = 'all-licenses' | 'no-license' | string;

const getExtraData = (selectedLicense: LicenseDropdown) => {
  if (selectedLicense === 'all-licenses') return {};
  if (selectedLicense === 'no-license') return { hasLicense: false };
  return { productPoolIds: selectedLicense };
};

const CompanyMembersProvider = ({ children }: { children: ReactNode }) => {
  const [showDeletedUsers, setShowDeletedUsers] = useState(false);
  const { user } = useUser();
  const { data: dataProductPools, loading: loadingProductPools } = useLicensesGet({
    CompanyId: user?.companyId,
    Take: 9999,
  });
  const [selected, setSelected] = useState<User[]>([]);
  const [selectedLicense, setSelectedLicense] = useState<LicenseDropdown>('all-licenses');

  const { data, loading, refetch, fetchNextPage, searchHandler, sortHandler, setDefaultOpts } =
    useWrappedPaginatedGet<User>('admin/user', {
      lazy: true,
    });

  useEffect(() => {
    if (isNotNil(user?.companyId)) {
      setDefaultOpts({
        lazy: false,
        defaultConfig: {
          params: {
            companyId: user.companyId,
            includeDeleted: showDeletedUsers ? IncludeDeletedOptions.ONLY_DELETED : null,
            ...getExtraData(selectedLicense),
          },
        },
      });
    }
  }, [selectedLicense, setDefaultOpts, showDeletedUsers, user?.companyId]);

  const selectedStateObj = React.useMemo(
    () => ({
      selected,
      setSelected,
      showDeletedUsers,
      setShowDeletedUsers,
      refetch,
      paginationHandler: fetchNextPage,
      searchHandler,
      sortHandler,
      loading,
      data,
      selectedLicense,
      setSelectedLicense,
      dataProductPools: dataProductPools?.data?.filter((p) => p.status !== 'Expired') ?? [],
      loadingProductPools,
    }),
    [
      selected,
      showDeletedUsers,
      refetch,
      fetchNextPage,
      searchHandler,
      sortHandler,
      loading,
      data,
      selectedLicense,
      dataProductPools?.data,
      loadingProductPools,
    ],
  );

  return <CompanyMembersContext.Provider value={selectedStateObj}>{children}</CompanyMembersContext.Provider>;
};

const useCompanyMembersContext = () => {
  const context = React.useContext(CompanyMembersContext);
  if (context === undefined) {
    throw new Error('useCompanyMembersContext must be used within a CompanyMembersContext');
  }
  return context;
};

export { CompanyMembersContext, CompanyMembersProvider, useCompanyMembersContext };
