import React, { Fragment, useState, useEffect } from 'react';

import { gql, useLazyQuery, useReactiveVar } from '@apollo/client';
import { Divider, Typography, Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate } from 'react-router-dom';

import { shouldBlockNavigation } from 'apollo/reactiveVars';
import {
  useFacilitiesProjects,
  formatFacility,
  formatProject,
} from 'app/FacilitiesProjectsContext';
import { useUser } from 'app/UserContext';
import { Custom } from 'components/Icons';
import SearchTextInput from 'components/SearchTextInput';
import SelectProjectConfirmModal from 'components/SelectProjectConfirmModal';
import { projects as PROJECTS, facilities as FACILITIES } from 'graphql/queries';
import { otherColors } from 'styles/theme/colors';
import { darkScrollBarStyle } from 'styles/theme/scrollBarStyle';

import {
  StyledDropdownPaper,
  StyledPopper,
  GroupTitle,
  GroupHeaderWrapper,
} from './HeaderSearchAutocomplete.styles';

const renderGroup = (params) => {
  const readableGroupType = params.group === 'FACILITY' ? 'Facilities' : 'Projects';

  const NoGroupTypeAvailable = () => (
    <Typography variant="body2" sx={{ marginLeft: 2, height: '40px' }}>
      No {readableGroupType} Available
    </Typography>
  );

  return [
    <Fragment key={params.key}>
      {+params.key !== 0 && <Divider sx={{ backgroundColor: 'other.divider.darkSurface' }} />}
      <GroupHeaderWrapper>
        <GroupTitle>{readableGroupType}</GroupTitle>
        <ViewAllLink type={params.group} />
      </GroupHeaderWrapper>
      <GroupChildrenWrapper small={params.group === 'FACILITY'}>
        {params.children[0].key === 'empty' ? <NoGroupTypeAvailable /> : params.children}
      </GroupChildrenWrapper>
    </Fragment>,
  ];
};

const renderInput = (params) => {
  const {
    InputProps: { ref, ...otherProps },
    ...rest
  } = params;

  delete rest.InputLabelProps;

  return (
    <SearchTextInput
      placeholder="Open Facilities or Projects..."
      xRef={ref}
      {...otherProps}
      {...rest}
    />
  );
};

// eslint-disable-next-line
const HeaderSearchAutocomplete = () => {
  const { setSelectedItem } = useFacilitiesProjects();
  const { user } = useUser();
  const [search, setSearch] = useState('');
  const [searchDebounced, setSearchDebounced] = useState(null);
  const [switchingItem, setSwitchingItem] = useState(null);
  const [isSelectItemConfirmModalOpen, setIsSelectItemConfirmModalOpen] = useState(false);
  const shouldBlock = useReactiveVar(shouldBlockNavigation);

  const [searchPartProject, { data: projectData, loading: loadingProject }] = useLazyQuery(
    gql(PROJECTS),
    { fetchPolicy: 'no-cache' },
  );

  const [searchPartFacilities, { data: facilityData, loading: loadingFacilities }] = useLazyQuery(
    gql(FACILITIES),
    { fetchPolicy: 'no-cache' },
  );

  useEffect(() => {
    const timer = setTimeout(() => setSearch(searchDebounced), 300);
    return () => clearTimeout(timer);
  }, [searchDebounced]);

  useEffect(() => {
    searchPartProject({
      variables: {
        query: {
          companyId: user.companyId,
          skip: 0,
          take: 50,
          searchPhrase: search,
        },
      },
    });
  }, [user.companyId, search, searchPartProject]);

  useEffect(() => {
    searchPartFacilities({
      variables: {
        query: {
          companyId: user.companyId,
          skip: 0,
          take: 50,
          searchPhrase: search,
        },
      },
    });
  }, [user.companyId, search, searchPartFacilities]);

  const emptyOptionsForGroupFormatting = [[{}]];

  const projects =
    projectData?.projects?.length > 0 ? projectData.projects : emptyOptionsForGroupFormatting;

  const facilities =
    facilityData?.facilities?.length > 0 ? facilityData.facilities : emptyOptionsForGroupFormatting;

  const options = [...facilities.map(formatFacility), ...projects.map(formatProject)];

  const handleInputChange = (event) => {
    if (event && event?.target?.value) setSearchDebounced(event?.target?.value);
    else setSearchDebounced(null);
  };

  const handleChange = (e, value) => {
    if (shouldBlock) {
      setIsSelectItemConfirmModalOpen(true);
      setSwitchingItem(value);
    } else {
      setSelectedItem(value);
    }
  };

  return (
    <>
      <Autocomplete
        data-cy="header-search"
        id="grouped-demo"
        options={options}
        groupBy={(option) => option.type}
        PaperComponent={StyledDropdownPaper}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderGroup={renderGroup}
        renderInput={renderInput}
        forcePopupIcon={false}
        loading={loadingProject && loadingFacilities}
        value={searchDebounced}
        onInputChange={handleInputChange}
        onChange={handleChange}
        clearIcon={<Custom.Close fill={otherColors.white} />}
        ListboxProps={{
          style: {
            maxHeight: '660px',
          },
        }}
        PopperComponent={StyledPopper}
      />
      <SelectProjectConfirmModal
        open={isSelectItemConfirmModalOpen}
        onClose={() => setIsSelectItemConfirmModalOpen(false)}
        submit={() => {
          setSelectedItem(switchingItem);
          setIsSelectItemConfirmModalOpen(false);
          shouldBlockNavigation(false);
        }}
      />
    </>
  );
};

export default HeaderSearchAutocomplete;

const ViewAllLink = ({ type }) => {
  const navigate = useNavigate();

  const url = type === 'PROJECT' ? '/admin/projects' : '/admin/facilities';

  return (
    <Typography
      variant="body2"
      sx={{
        color: 'other.links',
        cursor: 'pointer',
      }}
      onClick={() => navigate(url)}
    >
      View All
    </Typography>
  );
};

const GroupChildrenWrapper = ({ children, small }) => (
  <Box sx={{ maxHeight: small ? 130 : 400, overflowY: 'scroll', ...darkScrollBarStyle }}>
    {children}
  </Box>
);
