import React, { useEffect, useRef } from 'react';

import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import ForgeViewerDocument from 'components/Autodesk/ForgeViewerDocument';
import useSetupModule from 'hooks/useSetupModule';

import { getModuleRouteWorkOrder } from '../helpers';
import { useWorkOrderItemsContext } from './context/WorkOrderItemsContext';
import WorkOrderHeader from './WorkOrderHeader';
import WorkOrderItemsTable from './WorkOrderItemsTable/WorkOrderItemsTable';

const WorkOrderItems = () => {
  const { selectedItem } = useFacilitiesProjects();

  const facilityProjectRef = useRef(selectedItem);

  const { inForgeView, activeDocument } = useWorkOrderItemsContext();

  const { currentModule } = useSetupModule();
  const navigate = useNavigate();
  const moduleName = currentModule();
  const moduleRoute = getModuleRouteWorkOrder(moduleName);

  useEffect(() => {
    if (selectedItem?.id !== facilityProjectRef?.current?.id) {
      navigate(`/${moduleRoute}/work-orders`);
    }
  }, [navigate, selectedItem?.id, moduleRoute]);

  return (
    <Stack sx={{ flexGrow: 1 }}>
      <WorkOrderHeader />
      {inForgeView ? <ForgeViewerDocument document={activeDocument} /> : <WorkOrderItemsTable />}
    </Stack>
  );
};

export default WorkOrderItems;
