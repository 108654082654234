/* eslint-disable max-lines-per-function */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';

import { gql, useMutation } from '@apollo/client';

import {
  addBillOfMaterialsWriteInItem as ADD_WRITE_IN_ITEM,
  addBillOfMaterialsItem as ADD_BOM_ITEM,
} from 'graphql/mutations';
import { billOfMaterialItem as BILL_OF_MATERIAL_ITEM } from 'graphql/queries';
import useLazyPaginatedQuery from 'hooks/useLazyPaginatedQuery';
import {
  addTemporalIdToDuplicateItems,
  checkItemsToMakeDecisions,
  getLineItemsWithCorrectQty,
} from 'modules/Materials/BillOfMaterialsById/BOMCatalogTable/Decisions/helpers';

const NUMBER_BILL_OF_MATERIAL_ITEM_PER_PAGE = 50;

export const useBillOfMaterialsByIdAPI = (parameters, forceBomItemsRefetch) => {
  const [filters, setFilters] = React.useState({});

  const { projectId } = parameters;

  const [{ lazyLoad, paginationHandler, onOrderby: onOrderbyCallback, refetch }, { data, loading, sortingKeyword }] =
    useLazyPaginatedQuery(gql(BILL_OF_MATERIAL_ITEM), 'cache-and-network', NUMBER_BILL_OF_MATERIAL_ITEM_PER_PAGE);

  const onOrderby = React.useCallback(
    (columnName) => onOrderbyCallback({ columnName, variables: filters }),
    [filters, onOrderbyCallback],
  );

  const onScroll = React.useCallback(() => {
    const skip = data?.billOfMaterialItem?.length ?? 0;
    paginationHandler(skip, filters);
  }, [paginationHandler, data, filters]);

  const pagination = React.useMemo(() => ({ onScroll, onOrderby }), [onScroll, onOrderby]);

  React.useEffect(() => {
    sortingKeyword.current = '';
  }, [sortingKeyword, projectId]);

  React.useEffect(() => {
    if (projectId) lazyLoad(parameters);
  }, [projectId]);

  React.useEffect(() => {
    if (!projectId) return;
    setFilters((prev) => {
      if (JSON.stringify(prev) === JSON.stringify(parameters)) return prev;
      lazyLoad(parameters);

      return parameters;
    });
  }, [parameters, projectId]);

  const [addWriteInItem, { loading: addWriteInItemLoading }] = useMutation(gql(ADD_WRITE_IN_ITEM), {
    refetchQueries: ['QuantitiesRetrieveByIdentifier'],
    update: (cache, result) => {
      if (result?.errors?.length) return;
      cache.modify({
        id: 'ROOT_QUERY',
        fields: {
          quantitiesRetrieveByIdentifier(_, { DELETE }) {
            return DELETE;
          },
        },
      });
      cache.gc();
    },
  });

  const [addBillOfMaterialsItem] = useMutation(gql(ADD_BOM_ITEM), {
    refetchQueries: ['BillOfMaterialItem', 'QuantitiesRetrieveByIdentifier'],
  });

  const updateBomItemsCache = React.useCallback(
    (cache, data, partsAdded) => {
      const itemsToMakeDecisions = checkItemsToMakeDecisions(data?.addBillOfMaterialsWriteInItem);
      if (itemsToMakeDecisions?.length === 0 || forceBomItemsRefetch) {
        refetch(filters);
        return;
      }

      const lineItemsWithCorrectQty = getLineItemsWithCorrectQty(itemsToMakeDecisions, partsAdded);

      const queryObject = {
        query: gql(BILL_OF_MATERIAL_ITEM),
        variables: { query: { ...parameters, take: NUMBER_BILL_OF_MATERIAL_ITEM_PER_PAGE } },
      };

      const originalData = cache.readQuery(queryObject);

      const filteredData = addTemporalIdToDuplicateItems(originalData?.billOfMaterialItem, itemsToMakeDecisions);

      const updatedData = {
        billOfMaterialItem: [...lineItemsWithCorrectQty, ...filteredData],
      };

      cache.writeQuery({ ...queryObject, data: updatedData });
    },
    [forceBomItemsRefetch],
  );

  const onAddWriteInItems = React.useCallback(
    async (bomItems) => {
      const body = { projectId, items: bomItems };
      const addBomItemData = await addWriteInItem({
        variables: { body },
        update(cache, { data }) {
          updateBomItemsCache(cache, data, bomItems);
        },
      });
      return addBomItemData;
    },
    [addWriteInItem, projectId, refetch, filters, updateBomItemsCache],
  );

  const onAddBOMItem = React.useCallback(
    async (bomItems) => {
      const body = { projectId, items: bomItems };

      await addBillOfMaterialsItem({ variables: { body } });
      await refetch(filters);
    },
    [addBillOfMaterialsItem, projectId, refetch, filters],
  );

  const refetchItems = React.useCallback(async () => refetch(filters));

  return {
    callbacks: { onAddWriteInItems, onAddBOMItem, refetchItems },
    data: data?.billOfMaterialItem ?? [],
    metadata: { pagination, loading, addWriteInItemLoading },
    BOMFilters: filters,
    lazyLoad,
  };
};
