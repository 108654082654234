import React from 'react';

import { ThemeInheritor as ThemeLibs } from '@evolvemep/foresite-components-ui-react';
import { ThemeProvider as MaterialThemeProvider } from '@mui/material/styles';

import { useUser } from 'app/UserContext';
import useSetupModule from 'hooks/useSetupModule';

import { getThemeByModule } from './theme/theme';

const CustomThemeProvider = ({ children }) => {
  const { currentModule } = useSetupModule();
  const { user } = useUser();
  const theme = user ? getThemeByModule(currentModule()) : getThemeByModule();

  return (
    <MaterialThemeProvider theme={theme}>
      <ThemeLibs theme={theme}>{children}</ThemeLibs>
    </MaterialThemeProvider>
  );
};

export default CustomThemeProvider;
