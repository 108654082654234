import React, { useCallback, useContext, useEffect, useState } from 'react';

import { SESSION_STORAGE_CONSTANTS } from 'constants/globalConstants';
import usePartsCloudSearchCache from 'hooks/usePartsCloudSearchCache';

import { useCategoriesContext } from '../Categories/CategoriesContext';
import { useCatalogPartContext } from '../Providers/CatalogPartProvider';
import { useCatalogSelectedPartContext } from '../Providers/CatalogSelectedPartProvider';
import { usePartAPIContext } from '../Providers/PartAPIProvider';
import { useUOMAPIContext } from '../Providers/UOMAPIProvider';

const { PART_ADDED_FROM_WR, REACHED_CATALOG_SETUP_THROUGH_LINK } = SESSION_STORAGE_CONSTANTS;

const ItemsContext = React.createContext();

const ItemsProvider = ({ children }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [isDeleteItems, setIsDeleteItems] = useState(false);

  const { selectedCategory } = useCategoriesContext();
  const { deletePartsCloudSearchCache } = usePartsCloudSearchCache();

  const { setShowNewItem } = useCatalogPartContext();
  const {
    loadParts,
    loadingAddPart,
    loadingUpdatePart,
    loadPartsCount,
    removePartsFromCategory,
    isRemovingPartsFromCategory,
  } = usePartAPIContext();
  const { setSelectedPart } = useCatalogSelectedPartContext();
  const stringItem = sessionStorage.getItem(PART_ADDED_FROM_WR);
  const itemFromWR = JSON.parse(stringItem);
  sessionStorage.removeItem(PART_ADDED_FROM_WR);

  const navigationSource = sessionStorage.getItem(REACHED_CATALOG_SETUP_THROUGH_LINK);
  const navigationFromWR = navigationSource?.toLowerCase() === 'true';

  const { fetchUoms, loadingAddUom, fetchUomTypes } = useUOMAPIContext();

  useEffect(() => {
    fetchUomTypes();
    fetchUoms();

    if (itemFromWR && navigationFromWR) setTimeout(() => setSelectedPart(itemFromWR), 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShowNewItem(false);
    setSelectedItems([]);
    setIsDeleteItems(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  useEffect(() => {
    if (!selectedCategory?.partCategoryId) return;
    loadParts({
      partCategoryId: selectedCategory?.partCategoryId,
      orderBy: 'dateadded:desc',
    });
  }, [selectedCategory, loadParts]);

  useEffect(
    () => loadPartsCount({ variables: { query: { partCategoryId: selectedCategory?.partCategoryId ?? '' } } }),
    [selectedCategory, loadPartsCount],
  );

  const disablePublishAssembly = loadingAddUom || loadingUpdatePart || loadingAddPart;

  const onStartDeleteItems = useCallback(() => setIsDeleteItems(true), []);

  const onCancelDeleteItems = useCallback(() => {
    setSelectedItems([]);
    setIsDeleteItems(false);
  }, []);

  const onDeleteItems = useCallback(async () => {
    const selectedItemsIds = selectedItems.map(({ partId }) => partId);
    await removePartsFromCategory({
      variables: {
        params: { partCategoryId: selectedCategory.partCategoryId },
        body: { partIds: selectedItemsIds },
      },
    });

    setSelectedItems([]);
    setIsDeleteItems(false);

    // we have to clean partsCloudSearch cache up once we remove items from category
    await deletePartsCloudSearchCache(selectedCategory);
  }, [selectedItems, removePartsFromCategory, selectedCategory, deletePartsCloudSearchCache]);

  const onChangeItems = useCallback(
    (item, checked) => {
      if (!isDeleteItems) return;
      if (checked) setSelectedItems((prev) => [...prev, item]);
      else setSelectedItems((prev) => [...prev].filter((el) => el.partId !== item.partId));
    },
    [isDeleteItems],
  );

  return (
    <ItemsContext.Provider
      value={{
        disablePublishAssembly,
        selectedItems,
        onChangeItems,
        isDeleteItems,
        onStartDeleteItems,
        onCancelDeleteItems,
        onDeleteItems,
        isRemovingPartsFromCategory,
      }}
    >
      {children}
    </ItemsContext.Provider>
  );
};
const useItemsContext = () => {
  const context = useContext(ItemsContext);
  if (context === undefined) {
    throw new Error('useItemsContext must be used within a ItemsProvider');
  }
  return context;
};

export { ItemsContext, ItemsProvider, useItemsContext };
