/* eslint-disable react/no-array-index-key */
import React from 'react';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Waypoint } from 'react-waypoint';

const InfiniteScroll = ({ data, fetchMore }) => {
  if (!data.length) return null;
  return (
    <TableRow>
      <TableCell
        sx={{
          borderBottom: 'none',
        }}
      >
        <Waypoint
          key={data[0].userId ?? data.length}
          bottomOffset="-20%"
          onEnter={data.length === 1 ? undefined : () => fetchMore()}
        />
      </TableCell>
    </TableRow>
  );
};

export default InfiniteScroll;
