import { useMutation, gql, useLazyQuery } from '@apollo/client';

import { addAssembly as ADD_ASSEMBLY, updateAssembly as UPDATE_ASSEMBLY } from 'graphql/mutations';
import { assemblies as ASSEMBLIES } from 'graphql/queries';

export default () => {
  const [addAssembly, { loading: loadingAddAssembly }] = useMutation(gql(ADD_ASSEMBLY), {
    // ! THIS FUNCTION COULD BE USEFUL LATER ON
    // update(
    //   cache,
    //   {
    //     data: {
    //       addAssembly: { part }
    //     }
    //   }
    // ) {
    //   if (
    //     !Object.keys(cache.data.data.ROOT_QUERY).some((key) => /(partsCloudSearch:)/gm.test(key))
    //   ) {
    //     clearPartsCloudSearchCache(cache);
    //     return;
    //   }
    //   deleteCacheItemField(cache, {
    //     __typename: 'PartCloudSearchResult',
    //     partHistoryRefId: part.partId
    //   });
    // }
  });
  const [updateAssembly, { loading: loadingUpdateAssembly }] = useMutation(gql(UPDATE_ASSEMBLY));
  const [getAssemblies, { data: assembliesData, loading: loadingAssemblies }] = useLazyQuery(
    gql(ASSEMBLIES),
    { fetchPolicy: 'no-cache' },
  );
  return {
    addAssembly,
    loadingAddAssembly,
    updateAssembly,
    loadingUpdateAssembly,
    getAssemblies,
    assembliesData,
    loadingAssemblies,
  };
};
