import { useContext, useEffect } from 'react';

import { WorkRequestItemsContext } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestItemsContext';
import { WorkOrderItemsContext } from 'modules/Shop/WorkOrders/WorkOrder/context/WorkOrderItemsContext';

const SECONDS_TO_UPDATE_STATE = 3000;

export const useEffectThatCancelDraggingWRItem = () => {
  const workRequestItemsContext = useContext(WorkRequestItemsContext);
  const workOrderItemsContext = useContext(WorkOrderItemsContext);
  const { setIsDrawingWRItem } = workRequestItemsContext || workOrderItemsContext;

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsDrawingWRItem(false);
    }, SECONDS_TO_UPDATE_STATE);
    return () => clearTimeout(timer);
  }, [setIsDrawingWRItem]);
};
