import React from 'react';

import { Box } from '@mui/material';

import { MoreMenu } from 'components';

const renameMenuOption = (onRename) => ({
  label: 'Rename',
  clickEvent: onRename,
});

const deleteMenuOption = (onDelete) => ({
  label: 'Delete',
  clickEvent: onDelete,
  color: 'error.main',
});

const menuStyles = {
  '.MuiDataGrid-row & > svg': {
    display: 'none',
  },
  '.MuiDataGrid-row:hover & > svg': {
    display: 'block',
  },
};

const DrawingFolderRowMoreOptions = ({ onRename, onDelete, dataTestId }) => {
  const MORE_MENU_OPTIONS = [renameMenuOption(onRename), deleteMenuOption(onDelete)];

  return (
    <Box>
      <MoreMenu
        options={MORE_MENU_OPTIONS}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        sxMenu={{ left: '30px' }}
        sxIconButton={menuStyles}
        iconProps={{
          'data-testid': dataTestId,
        }}
      />
    </Box>
  );
};

export default DrawingFolderRowMoreOptions;
