import React from 'react';

import { Stack } from '@mui/material';

import { Custom } from 'components/Icons';

import { useDataContext } from '../contexts/DataContext';

const StyledRow = ({ isActive, dragging, onClick, sx, children, testId }) => {
  const { isDragging } = useDataContext();

  return (
    <Stack
      onClick={onClick}
      data-testid={testId}
      direction="row"
      width="100%"
      alignItems="center"
      minHeight="32px"
      sx={{
        transform: 'none !important',
        backgroundColor: isActive && !dragging ? 'action.lightSurface.selected' : 'white',
        borderBottom: '1px solid',
        borderColor: 'other.border',
        border: '',
        cursor: isDragging ? 'grabbing' : 'pointer',
        paddingLeft: dragging ? 0 : 2,
        paddingRight: 2,
        borderLeft: dragging ? '3px solid #613FC2' : 'none',
        '&:hover': {
          backgroundColor: !isDragging && 'action.lightSurface.selected',
        },
        '& > .MuiIconButton-root': {
          opacity: 0,
        },
        '&:hover > .MuiIconButton-root': {
          opacity: 1,
        },
        ...sx,
      }}
    >
      {dragging && <Custom.DragIndicator />}
      {children}
    </Stack>
  );
};

export default StyledRow;
