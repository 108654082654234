export const buildQuantityInputStyles = (isEditingQuantity, disabled, error) => ({
  margin: 0,
  height: 24,
  '& .MuiInputBase-root': { height: 24 },
  '& .MuiInputAdornment-root': {
    display: 'none',
  },
  input: {
    textAlign: 'right',
  },
  'input:not(:focus)::-webkit-outer-spin-button, input:not(:focus)::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
  },
  'input:not(:focus)': {
    MozAppearance: 'textfield',
  },
  'input:focus': {
    '& + .MuiInputAdornment-root, & .MuiInputAdornment-root': {
      display: 'flex',
      marginLeft: '0px',
      marginRight: '-10px',
    },
  },
  '& .MuiInputAdornment-root:hover': {
    display: 'flex',
    marginLeft: '0px',
    marginRight: '-10px',
  },
  ...(isEditingQuantity
    ? {
        '& fieldset': {
          border: `2px solid`,
          borderColor: 'secondary.main',
        },
      }
    : {}),
  ...(disabled
    ? {
        pointerEvents: 'none',
        '& fieldset': {
          borderColor: 'rgba(0, 0, 0, 0.1) !important',
        },
      }
    : {}),
  ...(error
    ? {
        '& input': {
          color: 'error.dark',
        },
        '& fieldset': {
          borderWidth: '2px',
          borderColor: 'error.dark',
        },
      }
    : {}),
});
