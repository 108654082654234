import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';

import { Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { useItemsIntegrationModalContext } from 'modules/Materials/components/ItemsIntegrationModal/context/ItemsIntegrationModalContext';

import { useItemsIntegrationTableContext } from '../../../context/ItemsIntegrationTableContext';
import CloseButton from '../../QtyField/components/CloseButton';
import useValidateNumberRange from '../../QtyField/hooks/useValidateNumberRange';
import { useFieldContext } from '../context/FieldContext';
import useFieldEffects from '../hooks/useFieldEffects';
import useFieldHandlers from '../hooks/useFieldHandlers';
import FieldMemoized from './FieldMemoized';

const Field: FC = () => {
  const { control, trigger, setValue, formState } = useFormContext();
  const {
    fieldName,
    color,
    inputStyles,
    qtyAggregate,
    isReadOnly,
    setShowDeleteButton,
    qtyFieldRef,
    disabled,
    fieldId,
    fieldWatched,
    isBomLineItem,
  } = useFieldContext();
  const [inputValue, setInputValue] = useState<string | number | undefined>(fieldWatched);
  const { handleFocus, handleBlur, handleClick } = useFieldHandlers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleMouseOver = useCallback(() => setShowDeleteButton(true), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleMouseLeave = useCallback(() => setShowDeleteButton(false), []);
  const { validateNumberRange } = useValidateNumberRange();
  const { isLoading } = useItemsIntegrationModalContext();
  const { isReadyForTriggerValidation, setFieldsCalculating, fieldsCalculating } = useItemsIntegrationTableContext();
  const isDisabled = useMemo(() => disabled || isLoading, [disabled, isLoading]);
  const { errors, isDirty } = formState;
  const error = useMemo(() => {
    if ((inputValue === '' || inputValue === undefined) && isBomLineItem) return false;
    if (inputValue === '0' && isBomLineItem) return true;
    const isValidateError = errors?.[fieldName]?.type === 'validate';
    const isQtyLimitExceedError = errors?.[fieldName]?.type === 'quantityLimitExceeded';
    const isRangeError = errors?.[fieldName]?.type === 'rangeError';
    if ((!isValidateError && !isQtyLimitExceedError && !isRangeError) || inputValue === '') return false;
    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, formState, fieldName, isBomLineItem]);
  const validate = useCallback(
    (value: string) => {
      if (!isReadyForTriggerValidation) return;
      validateNumberRange(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isReadyForTriggerValidation],
  );

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value), []);

  useEffect(() => {
    if (inputValue === undefined || !isDirty) return () => {};
    if (!fieldsCalculating[fieldName]) setFieldsCalculating((current) => ({ ...current, [fieldName]: true }));
    const debouncedValidation = setTimeout(async () => {
      setValue(fieldName, inputValue, { shouldDirty: true });
      await trigger(fieldName);
      setFieldsCalculating((current) => ({ ...current, [fieldName]: false }));
    }, 100);
    return () => clearTimeout(debouncedValidation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, formState.isDirty]);

  useEffect(() => {
    if (fieldWatched === inputValue) return;
    setInputValue(fieldWatched);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldWatched]);

  useFieldEffects();

  return (
    <Stack
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      sx={{
        position: 'relative',
        pointerEvents: isReadOnly || isDisabled ? 'none' : 'all',
        ...(isDisabled ? { opacity: '0.5' } : {}),
      }}
      alignItems="center"
    >
      <FieldMemoized
        validate={validate}
        key={fieldId}
        value={inputValue}
        control={control}
        qtyAggregate={qtyAggregate}
        name={fieldName}
        inputStyles={inputStyles}
        color={color}
        readOnly={isReadOnly}
        handleBlur={handleBlur}
        handleFocus={handleFocus}
        handleClick={handleClick}
        handleChange={handleChange}
        qtyFieldRef={qtyFieldRef}
        error={error}
      />
      <CloseButton />
    </Stack>
  );
};

export default Field;
