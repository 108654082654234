import { useFormContext } from 'react-hook-form';

import useWorkRequestStatusMutation, {
  WorkRequestByIdQuery,
} from 'hooks-api/useWorkRequestStatusMutation';

import { useWorkRequestItemsContext } from '../WorkRequestItemsContext';

export const useUpdateWorkRequestItem = (row) => {
  const {
    handleSubmit,
    trigger,
    formState: { isValid, isDirty },
    reset,
  } = useFormContext();

  const { updateWorkRequestStatusMutation } = useWorkRequestStatusMutation();

  const {
    addWorkRequestItem,
    updateWorkRequestItem,
    workRequest,
    setVisibleInputs,
    setIsEditingItem,
    setNewUomId,
  } = useWorkRequestItemsContext();

  const closeFields = () => {
    setIsEditingItem(false);
    setVisibleInputs(false);
    setNewUomId(null);
    reset();
  };

  const submitItem = async () => {
    if (!isDirty) {
      closeFields();
      return;
    }

    if (isValid) {
      const callbackWorkRequestItem = row?.isEditing
        ? (inputValues) => updateWorkRequestItem(inputValues, row.workRequestItemId, workRequest)
        : addWorkRequestItem;
      await handleSubmit(callbackWorkRequestItem)();
      if (['Canceled', 'Rejected'].includes(workRequest?.workRequestStatusName)) {
        updateWorkRequestStatusMutation({
          variables: {
            params: { workRequestId: workRequest.workRequestId, workRequestStatusName: 'Draft' },
            body: {
              workRequestStatusDescription: '',
            },
          },
          refetchQueries: [WorkRequestByIdQuery(workRequest.workRequestId)],
          awaitRefetchQueries: true,
        });
      }
      closeFields();
    } else {
      trigger();
    }
  };

  return {
    submitItem,
    closeFields,
  };
};
