import React, { FC, useEffect, useMemo } from 'react';

import { useItemsIntegrationTableContext } from '../../context/ItemsIntegrationTableContext';
import WorkPhaseRow from '../WorkPhaseRow/WorkPhaseRow';

interface IWorkPhasesTable {
  locationId?: string | null;
  workPhases: any[];
  nestLevel?: number;
  lineItemId: string;
  LBSWorkPhases: any[];
  isScopePackage?: boolean;
}

const WorkPhasesTable: FC<IWorkPhasesTable> = ({
  locationId = '',
  workPhases = [],
  nestLevel = 1,
  lineItemId = '',
  LBSWorkPhases = [],
  isScopePackage = false,
}: IWorkPhasesTable) => {
  const { maxNestLevel, setMaxNestLevel } = useItemsIntegrationTableContext();

  const rows = useMemo(
    () =>
      workPhases.reduce((resultWorkPhases: any[], workPhase: any) => {
        const LBSWorkPhase = LBSWorkPhases.find(({ workPhaseId }: any) => workPhaseId === workPhase.workPhaseId);
        if (!LBSWorkPhase) return resultWorkPhases;
        return [
          ...resultWorkPhases,
          {
            ...workPhase,
            locationId,
            qtyAggregate: Math.round(LBSWorkPhase.qtyAggregate || LBSWorkPhase.amount?.available?.aggregate) || 0,
            qtyRemaining: Math.round(LBSWorkPhase.qtyRemaining || LBSWorkPhase.amount?.available?.individual) || 0,
            lineItemId,
            isScopePackage,
          },
        ];
      }, []),
    [workPhases, LBSWorkPhases, locationId, lineItemId, isScopePackage],
  );
  useEffect(() => {
    if (nestLevel > maxNestLevel[lineItemId])
      setMaxNestLevel((current: any) => ({ ...current, [lineItemId]: nestLevel }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nestLevel, maxNestLevel]);
  if (!rows.length) return <></>;
  return (
    <>
      {rows.map((row: any) => (
        <WorkPhaseRow
          key={`${row.locationId}__${row.lineItemId}__${row.workPhaseId}`}
          row={row}
          nestLevel={nestLevel}
          lineItemId={lineItemId}
          isScopePackage={isScopePackage}
        />
      ))}
    </>
  );
};

export default WorkPhasesTable;
