import React, { useState } from 'react';

import { Stack, Typography, Divider, Box, useTheme, Button } from '@mui/material';

import { RightSideFlyoutPaper } from 'components';
import { Custom } from 'components/Icons';
import { useTakeOff } from 'modules/Materials/TakeOff/context/TakeOffContext';
import { darkSurfaceScrollBarStyle } from 'styles/theme/scrollBarStyle';

import { RevitModelProvider, RevitPropertiesGrid } from '../RevitModels';
import ConfirmModal from './ClearDataConfirmModal';

const PropertiesPopup = ({ onClose = () => {}, sourcePad = {} }) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isCleared, setIsCleared] = useState(false);

  const theme = useTheme();
  const { onDeleteSourcePad, padItems } = useTakeOff();

  const openConfirmModal = () => setIsConfirmModalOpen(true);

  const handleConfirm = () => {
    onDeleteSourcePad({ sourcePadId: sourcePad.sourcePadId });
    setIsCleared(true);
    setIsConfirmModalOpen(false);
  };

  const closeModal = () => setIsConfirmModalOpen(false);

  return (
    <RightSideFlyoutPaper
      leading={
        <Stack display="flex" flexDirection="row" width="100%">
          <Typography variant="h6" sx={{ color: 'white' }}>
            Properties
          </Typography>
          <Box
            sx={{
              '& svg': {
                position: 'absolute',
                top: '23px',
                right: '60px',
                height: '22px',
                width: '12px',
              },
            }}
          >
            <Custom.Vector1 />
          </Box>
        </Stack>
      }
      onClose={onClose}
      onDock={false}
      sx={{ width: '500px', paddingRight: '10px', paddingLeft: '25px' }}
    >
      <RevitModelProvider>
        <Divider
          sx={{
            borderColor: theme.palette.other.border,
            marginTop: '10px',
            marginRight: '10px',
          }}
        />
        <Stack direction="row" marginBottom="16px" marginTop="10px" color="white">
          <Typography variant="body3">Linked Revit Models</Typography>
          <Button
            variant="contained"
            color="secondary"
            sx={{ ml: 'auto', left: '-10px' }}
            disabled={sourcePad.hasMappedItems || !padItems?.length || isCleared}
            onClick={openConfirmModal}
          >
            CLEAR ALL DATA
          </Button>
        </Stack>
        <Stack overflow="auto" sx={{ maxHeight: '100%', ...darkSurfaceScrollBarStyle }}>
          <RevitPropertiesGrid />
        </Stack>
        <ConfirmModal open={isConfirmModalOpen} onClose={closeModal} onConfirm={handleConfirm} />
      </RevitModelProvider>
    </RightSideFlyoutPaper>
  );
};

export default PropertiesPopup;
