import React from 'react';

import Typography from '@mui/material/Typography';

const BasicCellComponent = ({ children, sx = {}, onClick }) => (
  <Typography
    onClick={onClick}
    variant="body2"
    sx={{
      fontWeight: 400,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      width: '100%',
      ...sx,
    }}
  >
    {children}
  </Typography>
);

export default BasicCellComponent;
