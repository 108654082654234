import React, { useEffect, useState } from 'react';

import { workOrderDocuments as WORK_ORDER_DOCUMENTS } from 'graphql/queries';
import usePaginatedDocuments, { CombinedDocumentType } from 'hooks/usePaginatedDocuments';

export type WorkOrderDocumentsType = {
  workOrderDocuments: CombinedDocumentType[];
  loading: boolean;
  paginationWaypoint: () => React.ReactNode;
};

const useWorkOrdersDocuments = (workOrderId: string, take = 50): WorkOrderDocumentsType => {
  const [workOrderCalled, setWorkOrderCalled] = useState<string | null>(null);
  const { documents, loading, handleFetchDocuments, paginationWaypoint } = usePaginatedDocuments(
    WORK_ORDER_DOCUMENTS,
    'workOrderDocuments',
    take,
  );

  useEffect(() => {
    const selectAnotherWorkRequest = workOrderId !== workOrderCalled || !workOrderCalled;
    const isAvailableToFetch = workOrderId?.length && selectAnotherWorkRequest;
    if (!isAvailableToFetch) return;

    setWorkOrderCalled(workOrderId);
    handleFetchDocuments({ workOrderId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workOrderId]);

  return {
    workOrderDocuments: documents,
    loading,
    paginationWaypoint,
  };
};

export default useWorkOrdersDocuments;
