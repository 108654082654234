import React from 'react';

import { Stack, SxProps } from '@mui/material';

interface Props {
  children: React.ReactNode;
  sx?: SxProps;
  justifyContent?: string;
  minHeight?: string;
  padding?: string;
}

const SectionTitle = ({
  children,
  sx,
  justifyContent,
  minHeight = '56px',
  padding = '0px 16px',
}: Props) => (
  <Stack
    direction="row"
    justifyContent={justifyContent}
    sx={{
      minHeight,
      borderBottom: '1px solid rgba(204, 204, 204, 1)',
      padding,
      alignItems: 'center',
      gap: '16px',
      ...sx,
    }}
  >
    {children}
  </Stack>
);

export default SectionTitle;
