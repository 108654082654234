import React from 'react';

import FilterPopover from 'components/Popover/FilterPopover';
import { usePopoverFilterContext } from 'components/Popover/FilterPopover/context/PopoverFilterContext';

const WorkOrdersFilterPopover = ({ open, onClose, anchorEl, id }) => {
  const {
    displayFilters,
    removeFilterByDisplay,
    clearFilters,
    filters,
    removeFilterByColumn,
    appendFilters,
    columns,
  } = usePopoverFilterContext();

  const handleCancel = () => {
    onClose();
    clearFilters();
  };
  const handleClearFilters = () => clearFilters();

  const handleFormSubmit = () => onClose();

  return (
    <FilterPopover id={id} open={open} onClose={onClose} anchorEl={anchorEl}>
      <FilterPopover.Header>
        <FilterPopover.FilterChips filters={displayFilters} onDelete={removeFilterByDisplay} />
      </FilterPopover.Header>

      <FilterPopover.Content>
        <FilterPopover.FilterForm
          filtersData={filters}
          onAppendFilters={appendFilters}
          onRemoveFilterByColumn={removeFilterByColumn}
          columns={columns}
        />
      </FilterPopover.Content>

      <FilterPopover.Footer
        enableButtons={filters.length > 0}
        render={(enableButtons) => (
          <FilterPopover.FilterFooterButton
            enableButtons={enableButtons}
            onCancel={handleCancel}
            onClear={handleClearFilters}
            onSubmit={handleFormSubmit}
          />
        )}
      />
    </FilterPopover>
  );
};

export default WorkOrdersFilterPopover;
