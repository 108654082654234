/* eslint-disable max-lines-per-function */
import React, { useState, useRef } from 'react';

import { useSnackbar } from 'notistack';
import { addEdge, removeElements } from 'react-flow-renderer';
import { v4 as uuidv4 } from 'uuid';

import { VARIANT_WARNING } from 'constants/snackbarConstants';

import { isCreatingInfiniteLoop, isUniqueEdge } from '../Utils/assemblyEditorHelpers';
import { ADD_EDGE_PARAMS } from '../Utils/constants';

const AssemblyEditorContext = React.createContext();

const AssemblyEditorProvider = ({
  elements = [],
  setElements = () => {},
  mainPartItem = {},
  setMainPartItem = () => {},
  onDeleteNext = () => {},
  onDuplicateNext = () => {},
  onDropNext = () => {},
  onConnectNext = () => {},
  onEditNext = () => {},
  assemblyId = '',
  setAssemblyId = () => {},
  children,
  updateElements = [],
  setUpdateElements = () => {},
  isUpdateAssembly = false,
  handlePublish = () => {},
  loadingAddAssembly = false,
  loadingPublishAssembly = false,
  loadingUpdateAssembly = false,
  loadingAssemblies = false,
  onCancel = () => {},
}) => {
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [idPropertyOpen, setIdPropertyOpen] = useState(false);
  const mainProviderWrapper = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const onLoad = (_reactFlowInstance) => setReactFlowInstance(_reactFlowInstance);

  const onDelete = (taskTypeId) => {
    const deleted = elements;
    let toBeDeleted = {};
    for (let i = 0; i < deleted.length; i += 1) {
      if (deleted[i].id === taskTypeId) {
        toBeDeleted = deleted[i];
      }
    }
    onDeleteNext({ ...toBeDeleted });
    setElements((els) => removeElements([toBeDeleted], els));
  };

  const onDuplicate = (taskTypeId) => {
    let nodeToBeDuplicated = null;
    let duplicateNode = {};
    const nodes = elements;
    for (let i = 0; i < nodes.length; i += 1) {
      if (!nodeToBeDuplicated) {
        if (taskTypeId === nodes[i].id && !nodes[i].data.isDeleted) {
          nodeToBeDuplicated = nodes[i];
        }
      }
    }

    const newGuid = uuidv4();
    duplicateNode = {
      ...nodeToBeDuplicated,
      id: newGuid,
      data: {
        ...nodeToBeDuplicated.data,
        id: newGuid,
      },
      position: {
        x: nodeToBeDuplicated.position.x + 50,
        y: nodeToBeDuplicated.position.y + 50,
      },
    };
    nodes.push(duplicateNode);
    onDuplicateNext(nodes, duplicateNode);
    setElements([...nodes]);
  };

  const onElementsRemove = (elementsToRemove) => setElements((els) => removeElements(elementsToRemove, els));

  const onDrop = (event) => {
    event.preventDefault();
    const nodeData = JSON.parse(event.dataTransfer.getData('application/reactflow'));
    const { taskTypeId, taskTypeDescription, taskTypeImageId, taskTypeName, image } = nodeData;
    const reactFlowBounds = mainProviderWrapper?.current?.getBoundingClientRect();
    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });
    const newGuid = uuidv4();
    const newNode = {
      setElements,
      id: newGuid,
      key: newGuid,
      type: 'taskType',
      position,
      data: {
        id: `${taskTypeId}`,
        key: newGuid,
        taskTypeId,
        taskTypeDescription,
        taskTypeImageId,
        taskTypeName,
        image,
      },
    };
    onDropNext(newNode);
    setElements((es) => es.concat(newNode));
  };

  const warningSnackbar = (message) => enqueueSnackbar(message, VARIANT_WARNING);

  const onConnect = (params) => {
    setElements((els) => {
      if (params.source === params.target) {
        warningSnackbar('Invalid connection');
        return els;
      }
      const sourceHandles = ['d', 'b'];
      const targetHandles = ['a', 'c'];
      if (sourceHandles.includes(params.sourceHandle) && sourceHandles.includes(params.targetHandle)) {
        warningSnackbar('Can not connect a finish handle to a finish handle');
        return els;
      }
      if (targetHandles.includes(params.sourceHandle) && targetHandles.includes(params.targetHandle)) {
        warningSnackbar('Can not connect a start handle to a start handle');
        return els;
      }
      if (!isUniqueEdge(els, params)) {
        warningSnackbar('Connection already made');
        return els;
      }
      if (isCreatingInfiniteLoop(els, params)) {
        warningSnackbar('Can not create infinite loops');
        return els;
      }
      const newElements = addEdge(
        {
          ...params,
          ...ADD_EDGE_PARAMS,
        },
        els,
      );
      const lastElement = newElements[newElements.length - 1];
      onConnectNext(lastElement);
      return newElements;
    });
  };

  const onEdit = (id, body) => {
    const index = elements.findIndex((el) => el.id === id);
    if (index !== -1) {
      const updatedItem = {
        ...elements[index],
        data: {
          ...elements[index].data,
          qty: body.quantity,
        },
      };
      onEditNext(updatedItem);
      setElements([...elements.slice(0, index), updatedItem, ...elements.slice(index + 1)]);
    }
  };

  const flowStateObj = {
    elements,
    setElements,
    onDelete,
    onDuplicate,
    onDrop,
    onLoad,
    mainProviderWrapper,
    onConnect,
    onElementsRemove,
    isUpdateAssembly,
    assemblyId,
    setAssemblyId,
    mainPartItem,
    setMainPartItem,
    updateElements,
    setUpdateElements,
    handlePublish,
    loadingAddAssembly,
    loadingPublishAssembly,
    loadingUpdateAssembly,
    loadingAssemblies,
    onCancel,
    onEdit,
    idPropertyOpen,
    setIdPropertyOpen,
  };
  return <AssemblyEditorContext.Provider value={flowStateObj}>{children}</AssemblyEditorContext.Provider>;
};

const useAssemblyEditorContext = () => {
  const context = React.useContext(AssemblyEditorContext);
  if (context === undefined) {
    throw new Error('useAssemblyEditorContext must be used within an AssemblyEditorContext');
  }
  return context;
};

export { AssemblyEditorContext, AssemblyEditorProvider, useAssemblyEditorContext };
