import React, { useEffect } from 'react';

import { Stack, useTheme } from '@mui/material';
import { GridRow, useGridApiContext } from '@mui/x-data-grid-pro';
import { Droppable } from 'react-beautiful-dnd';

import { useDrawingRegisterDragDrop } from 'modules/Design/DrawingRegister/DrawingRegisterDragDropProvider';
import { ROW_TYPES } from 'modules/Shop/ShopSetup/WorkCellSetup/WorkCellsAndMachines/constants/constants';

import { DROPPABLE_ROW_IDS } from '../../FieldWorkRequestConstants';
import { useWorkRequestItemsContext } from '../WorkRequestItemsContext';

const GridDroppable = ({ rowData }) => {
  const apiRef = useGridApiContext();

  const { setIsDrawingWRItem, workRequestStatus } = useWorkRequestItemsContext();

  const { finishedDraggingWRItem, setFinishedDraggingWRItem } = useDrawingRegisterDragDrop();

  useEffect(() => {
    const destinationRowId = finishedDraggingWRItem?.destination?.droppableId?.split('/')[1];
    if (rowData?.rowId === destinationRowId) {
      setIsDrawingWRItem(true);
      setFinishedDraggingWRItem(null);
      if (!apiRef.current.getExpandedDetailPanels().includes(destinationRowId))
        apiRef.current.toggleDetailPanel(rowData.rowId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishedDraggingWRItem]);

  const { palette } = useTheme();
  return (
    <Stack position="relative">
      <Stack direction="row">
        <GridRow {...rowData} />
      </Stack>
      <Droppable
        droppableId={`${DROPPABLE_ROW_IDS.ITEM}/${rowData.rowId}`}
        type={ROW_TYPES.TASK_TYPES}
        isDropDisabled={workRequestStatus === 'Completed'}
        direction="horizontal"
      >
        {(provided, snapshot) => (
          <Stack
            ref={provided.innerRef}
            {...provided.droppableProps}
            sx={{
              width: '100%',
              zIndex: snapshot.isDraggingOver ? 2 : -2,
              height: '32px',
              position: 'absolute',
              '&:hover': {
                borderTop: snapshot.isDraggingOver ? `1px solid ${palette?.secondary?.main}` : 'none',
                borderBottom: snapshot.isDraggingOver ? `1px solid ${palette?.secondary?.main} !important` : 'none',
                background: palette?.action?.hover,
              },
            }}
          >
            {provided.placeholder}
          </Stack>
        )}
      </Droppable>
    </Stack>
  );
};

export default GridDroppable;
