/* eslint-disable */
import * as React from 'react';

import { InputForesiteField } from '@evolvemep/foresite-components-ui-react';
import { UseControllerProps } from 'react-hook-form';

type ControlInput = {
  control: UseControllerProps['control'];
};

type CallbacksInput = {
  onKeyPress: React.KeyboardEventHandler<HTMLDivElement>;
  onKeyDown: React.KeyboardEventHandler<HTMLDivElement>;
};

// Components
type CatalogNumberInputProps = ControlInput & CallbacksInput & { disabled: boolean };
export const CatalogNumberInput = ({ control, onKeyPress, onKeyDown, disabled }: CatalogNumberInputProps) => (
  <InputForesiteField
    name="catalogNumber"
    placeholder="Catalog Number"
    size="small"
    color="secondary"
    sx={{
      margin: 0,
      height: 24,
      '& .MuiInputBase-root': { height: 24 }
    }}
    maxLength={64}
    control={control}
    onKeyDown={onKeyDown}
    disabled={disabled}
  />
);

type TakeOffPadNameProps = ControlInput;
export const TakeOffPadName = ({ control }: TakeOffPadNameProps) => (
  <InputForesiteField
    name="takeoffPadName"
    defaultValue=""
    label="Takeoff Pad Name"
    size="small"
    maxLength={32}
    sx={{ marginTop: 0 }}
    control={control}
  />
);

type TradeSizeInputProps = ControlInput & CallbacksInput & { defaultValue: string; disabled: boolean };
export const TradeSizeInput = ({ control, onKeyDown, onKeyPress, defaultValue, disabled }: TradeSizeInputProps) => (
  <InputForesiteField
    name="tradeSize"
    placeholder="Trade Size"
    size="small"
    color="secondary"
    sx={{
      margin: 0,
      height: 24,
      '& .MuiInputBase-root': { height: 24 }
    }}
    maxLength={32}
    control={control}
    onKeyDown={onKeyDown}
    defaultValue={defaultValue}
    disabled={disabled}
    data-cy="trade-size-input"
  />
);
