/* eslint-disable import/no-cycle */
import { useMemo } from 'react';

import { useTheme } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';

import { ADD_REMAINING_QUANTITIES_FIELD, UNASSIGNED } from '../../../constants';
import { useItemsIntegrationTableContext } from '../../../context/ItemsIntegrationTableContext';
import {
  getErrorMessage,
  getInputStyles,
  getQtyFieldName,
  getQuantityFieldLocation,
} from '../../QtyField/helpers/qtyFieldHelpers';
import { IUseGetFieldMemoValuesProps, QuantityFieldType, UseGetFieldMemoValuesType } from './@types/fieldHookTypes';

const useGetFieldMemoValues = ({
  name,
  isWorkPhase,
  qtyRemaining,
  qtyAggregate,
  showArrows,
}: IUseGetFieldMemoValuesProps): UseGetFieldMemoValuesType => {
  const { control, formState } = useFormContext();
  const { palette } = useTheme();

  const { errors } = formState;
  const autoFill: boolean = useWatch({ control, name: ADD_REMAINING_QUANTITIES_FIELD });

  const { quantityFieldsByLineItem, maxNestLevel, autoFilledFields } = useItemsIntegrationTableContext();
  const nameKeys: string[] = useMemo(() => name.split('__'), [name]);

  const fieldId: string = useMemo(() => {
    if (isWorkPhase) return nameKeys[2];
    return nameKeys[0] === 'bomItem' ? nameKeys[1] : nameKeys[0];
  }, [nameKeys, isWorkPhase]);
  const lineItemId: string = useMemo(() => (nameKeys[0] === 'bomItem' ? fieldId : nameKeys[1]), [nameKeys, fieldId]);
  const lineItemMaxNestLevel: number = useMemo(() => maxNestLevel?.[lineItemId], [maxNestLevel, lineItemId]);

  const locationItemsByLineItem: QuantityFieldType[] = useMemo(
    () => quantityFieldsByLineItem?.[lineItemId] || [],
    [quantityFieldsByLineItem, lineItemId],
  );

  const locationId: string | null = useMemo(() => (isWorkPhase ? nameKeys[0] : null), [isWorkPhase, nameKeys]);

  const unassignedRowFieldName: string = useMemo(() => `qtyToAdd__${UNASSIGNED}__${lineItemId}`, [lineItemId]);

  const isBomLineItem: boolean = useMemo(() => new RegExp('^bomItem__').test(name), [name]);
  const quantityField = useMemo(
    () =>
      getQuantityFieldLocation({
        currentFields: locationItemsByLineItem,
        itemLocationId: fieldId,
        lineItemId,
        locationId,
        isBomLineItem,
      }) as QuantityFieldType,
    [fieldId, isBomLineItem, lineItemId, locationId, locationItemsByLineItem],
  );
  const currentNestLevel = useMemo(() => quantityField?.nestLevel, [quantityField]) as number;
  const fieldName: string = useMemo(() => `qtyToAdd__${name}`, [name]);
  const isReadOnly: boolean = useMemo(
    () => (!errors[fieldName] ? autoFill || autoFilledFields.includes(fieldName) : false),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [autoFill, autoFilledFields, fieldName, formState],
  );
  const rangeErrorMessage: string = useMemo(
    () =>
      getErrorMessage({
        qtyRemaining,
        qtyAggregate,
        isBomLineItem,
      }),
    [qtyRemaining, qtyAggregate, isBomLineItem],
  );

  const hasChildren: boolean = useMemo(() => Boolean(quantityField?.children?.length), [quantityField]);

  const color: string = useMemo(
    () => (isReadOnly ? palette.secondary.main : 'black'),
    [isReadOnly, palette.secondary.main],
  );
  const inputStyles = useMemo(
    () => ({
      height: '24px',
      margin: 0,
      ...getInputStyles({ showArrows, isReadOnly }),
    }),
    [isReadOnly, showArrows],
  );

  const parentFieldName: string = useMemo(
    () => getQtyFieldName({ lineItemId, locationId: currentNestLevel > 1 ? quantityField?.parentId : '' }),
    [currentNestLevel, quantityField, lineItemId],
  );

  return {
    fieldId,
    lineItemId,
    lineItemMaxNestLevel,
    locationItemsByLineItem,
    unassignedRowFieldName,
    isBomLineItem,
    quantityField,
    currentNestLevel,
    fieldName,
    isReadOnly,
    rangeErrorMessage,
    hasChildren,
    color,
    inputStyles,
    parentFieldName,
  } as UseGetFieldMemoValuesType;
};

export default useGetFieldMemoValues;
