import React from 'react';

import { Stack, Box, Button, CircularProgress } from '@mui/material';

const AttributeModalFooter = ({
  onNextStep,
  onPreviousStep,
  isLastStep,
  onClickDone,
  disableNext,
  disabledSubmit,
  loading,
}) => {
  const NavigateStepsButton = () => {
    const ButtonType = isLastStep ? 'Previous' : 'Next';
    const onClick = isLastStep ? onPreviousStep : onNextStep;

    return (
      <Button
        type="button"
        sx={{ mr: 1 }}
        color="secondary"
        onClick={onClick}
        disabled={disableNext}
      >
        {ButtonType}
      </Button>
    );
  };

  const DoneButton = () => (
    <Button
      type="submit"
      disabled={disabledSubmit}
      variant="contained"
      color="secondary"
      onClick={onClickDone}
    >
      Done
    </Button>
  );

  return (
    <Stack direction="row">
      <Box display="flex">
        {loading && (
          <CircularProgress
            color="secondary"
            size={25}
            sx={{ marginRight: '10px', marginTop: '5px' }}
          />
        )}
        <NavigateStepsButton />
        <DoneButton />
      </Box>
    </Stack>
  );
};

export default AttributeModalFooter;
