import React from 'react';

import { Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';

type BreadcrumbLinkProps = {
  url: string;
  label: string;
  darkMode: boolean;
};

const BreadcrumbLink = ({ url, label, darkMode }: BreadcrumbLinkProps) => {
  const navigate = useNavigate();

  return (
    <Link
      onClick={() => navigate(url)}
      sx={{
        color: darkMode ? 'surface.darkSurface.secondary' : 'surface.lightSurface.secondary',
        cursor: 'pointer',
        textDecorationColor: 'transparent',
      }}
    >
      {label}
    </Link>
  );
};

export default BreadcrumbLink;
