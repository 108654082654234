/* eslint-disable max-lines-per-function */
import React, { useEffect, useMemo, useState } from 'react';

import { Button, CircularProgress, FormGroup, Stack, Typography } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { draftPartReactiveVar } from 'apollo/reactiveVars';
import ConfirmLeavingModal from 'components/ConfirmLeavingModal/ConfirmLeavingModal';
import { Custom } from 'components/Icons';
import Section from 'components/Section';
import { SESSION_STORAGE_CONSTANTS } from 'constants/globalConstants';
import { useCurrentModuleFromURL } from 'hooks/useCurrentModuleFromURL';
import {
  NEW_PART_ITEM_ID,
  UNPUBLISHED_PART,
} from 'modules/Materials/AssemblyEditor/Utils/constants';
import { greyColors } from 'styles/theme/colors';
import { EModuleType, MODULE_URL_MAPPING } from 'types/module';

import PartItemDetail from '../../../components/PartItemDetail';
import { useCategoriesContext } from '../../Categories/CategoriesContext';
import { useCatalogPartContext } from '../../Providers/CatalogPartProvider';
import { useCatalogSelectedPartContext } from '../../Providers/CatalogSelectedPartProvider';
import { useManufacturerAPIContext } from '../../Providers/ManufacturerAPIProvider';
import { usePartAPIContext } from '../../Providers/PartAPIProvider';
import { usePartImageContext } from '../Manufacturer/PartImage/PartImageContext';
import Manufacturers from '../Manufacturers';
import PartNameTextField from './Fields/PartNameTextField';
import { PART_ITEM_FORM_DEFAULT_VALUE } from './newItemSectionHelpers';

const { PART_CATEGORY_FROM_WR, REACHED_CATALOG_SETUP_THROUGH_LINK } = SESSION_STORAGE_CONSTANTS;

const NewItemsSection = () => {
  const { manufactured } = useManufacturerAPIContext();
  const { selectedCategory } = useCategoriesContext();
  const navigate = useNavigate();
  const { methods, defaultValues, onSubmit, setShowNewItem, setRecentlyAddedPart } =
    useCatalogPartContext();
  const { selectedPart, setSelectedPart } = useCatalogSelectedPartContext();
  const { searchCurrentModule } = useCurrentModuleFromURL(
    [MODULE_URL_MAPPING[EModuleType.SHOP]],
    MODULE_URL_MAPPING[EModuleType.MATERIALS],
  );

  const { loadingUpdatePart } = usePartAPIContext();
  const { imageUploading } = usePartImageContext();
  const [showDialog, setShowDialog] = useState(false);
  const location = useLocation();
  const { formState, getValues, reset } = methods;

  const { isDirty, isSubmitting, dirtyFields, errors, isValid } = formState;

  const isFormModified = useMemo(
    () => isDirty && Boolean(Object.keys(dirtyFields).length),
    [isDirty, dirtyFields],
  );

  useEffect(() => {
    reset({ ...(selectedPart ? defaultValues : PART_ITEM_FORM_DEFAULT_VALUE) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues, selectedPart]);

  useEffect(() => {
    if (isFormModified) setShowDialog(true);
  }, [isFormModified]);

  const goToAssemblyEditor = () => {
    const draftPart = {
      ...getValues(),
      partId: selectedPart?.partId || NEW_PART_ITEM_ID,
      isFormModified,
      canContinue: getValues().partName === defaultValues.partName,
      previousLocation: location,
      hasAssembly: selectedPart?.hasAssembly,
      isMainPartItem: true,
    };
    setSelectedPart(null);
    localStorage.setItem(UNPUBLISHED_PART, JSON.stringify({ ...draftPart }));
    sessionStorage.setItem(PART_CATEGORY_FROM_WR, JSON.stringify(selectedCategory));
    sessionStorage.setItem(REACHED_CATALOG_SETUP_THROUGH_LINK, true);
    draftPartReactiveVar(draftPart);
    navigate(`/${searchCurrentModule()}/assembly-editor`);
  };

  const handleCancelNewItem = () => {
    setShowNewItem(false);
    setRecentlyAddedPart(null);
    setSelectedPart(null);
    draftPartReactiveVar(null);
  };

  const disabledForm = isSubmitting || loadingUpdatePart || imageUploading;

  const hasErrors = Boolean(Object.keys(errors).length);
  const isPublishButtonDisabled = () => {
    if (selectedPart?.partId === NEW_PART_ITEM_ID) {
      return isSubmitting;
    }
    if (hasErrors || !isValid) return hasErrors;
    return hasErrors || !isValid || isSubmitting || !isDirty || loadingUpdatePart;
  };

  const isEditorButtonDisabled = hasErrors || !isValid || isSubmitting || loadingUpdatePart;

  return (
    <Section>
      <ConfirmLeavingModal
        allowedLeavingLocations={[`/${searchCurrentModule()}/assembly-editor`]}
        showDialog={showDialog}
      />
      <FormProvider {...methods} defaultValues={defaultValues} disabled={disabledForm}>
        <FormGroup>
          <Section.Title>
            <Stack direction="row" sx={{ flex: 1, alignItems: 'center', gap: 1 }}>
              <Typography variant="tableHeader">
                {selectedPart && selectedPart?.partId !== NEW_PART_ITEM_ID
                  ? 'Edit item'
                  : 'New Item'}
              </Typography>
              {(isSubmitting || disabledForm) && <CircularProgress color="secondary" size={22} />}
            </Stack>
            <Button
              color="secondary"
              disabled={isSubmitting || disabledForm}
              onClick={handleCancelNewItem}
            >
              CANCEL
            </Button>
            <Button
              sx={{ width: '97px', paddingRight: '8px' }}
              variant="contained"
              onClick={goToAssemblyEditor}
              disabled={isEditorButtonDisabled}
              data-cy="editorBtn"
            >
              EDITOR
              <Custom.KeyboardArrowRight style={{ fill: greyColors.grey500 }} />
            </Button>
            <Button
              data-testid="publish-part-item-btn"
              color="secondary"
              variant="contained"
              onClick={methods.handleSubmit(onSubmit)}
              disabled={isPublishButtonDisabled()}
            >
              PUBLISH
            </Button>
          </Section.Title>
          <Section.Body sx={{ height: 'calc(100vh - 237px)' }}>
            <PartItemDetail>
              <PartItemDetail.PartItemDetailCategory
                isExistingItem={selectedPart && selectedPart?.partId !== NEW_PART_ITEM_ID}
              >
                {selectedCategory?.partCategoryName || ''}
              </PartItemDetail.PartItemDetailCategory>
              <PartNameTextField name="partName" />
              <Manufacturers manufacturers={manufactured} selectedPart={selectedPart} />
            </PartItemDetail>
          </Section.Body>
        </FormGroup>
      </FormProvider>
    </Section>
  );
};
export default NewItemsSection;
