import { useCallback } from 'react';

import { gql, useLazyQuery } from '@apollo/client';

import { MessageStatus } from 'app/MessageCenter/contexts';
import { messages as MESSAGES } from 'graphql/queries';

import useFetchMessagePriorities from './useFetchMessagePriorities';

const PAGINATION_LIMIT = 1;

const useFetchMessages = () => {
  const { getHighPriorityType } = useFetchMessagePriorities();

  const [, { loading: loadingAlerts, data: alerts, refetch: fetchAlerts }] = useLazyQuery(
    gql(MESSAGES),
    { fetchPolicy: 'cache-and-network' },
  );

  const getMessages = useCallback(async () => {
    const highPriorityType = await getHighPriorityType();
    await fetchAlerts({
      query: {
        messagePriorityId: highPriorityType?.messagePriorityId,
        read: 'false',
        skip: 0,
        take: PAGINATION_LIMIT,
        status: MessageStatus.ACTIVE,
      },
    });
  }, [fetchAlerts, getHighPriorityType]);

  return { loadingAlerts, alerts, getMessages };
};

export default useFetchMessages;
