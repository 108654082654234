import React, { useEffect, useState } from 'react';

import { workRequestDocuments as WORK_REQUEST_DOCUMENTS } from 'graphql/queries';
import usePaginatedDocuments, { CombinedDocumentType, VariableType } from 'hooks/usePaginatedDocuments';

export type WorkRequestDocumentsType = {
  workRequestDocuments: CombinedDocumentType[];
  loading: boolean;
  paginationWaypoint: () => React.ReactNode;
  handleFetchDocuments: (variables: VariableType) => void;
};

const useWorkRequestDocuments = (workRequestId: string, take = 50, canFetch = true): WorkRequestDocumentsType => {
  const [workRequestCalled, setWorkRequestCalled] = useState<string | null>(null);
  const { documents, loading, handleFetchDocuments, paginationWaypoint } = usePaginatedDocuments(
    WORK_REQUEST_DOCUMENTS,
    'workRequestDocuments',
    take,
  );

  useEffect(() => {
    const selectAnotherWorkRequest = workRequestId !== workRequestCalled || !workRequestCalled;
    const isAvailableToFetch = workRequestId?.length && selectAnotherWorkRequest && canFetch;
    if (!isAvailableToFetch) return;

    setWorkRequestCalled(workRequestId);
    handleFetchDocuments({ workRequestId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workRequestId]);

  return {
    workRequestDocuments: documents?.sort(
      (a, b) => new Date(a.createdOn as string).getTime() - new Date(b.createdOn as string).getTime(),
    ),
    loading,
    handleFetchDocuments,
    paginationWaypoint,
  };
};

export default useWorkRequestDocuments;
