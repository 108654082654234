import { useEffect, useRef } from 'react';

import { gql, useQuery } from '@apollo/client';

import { useUser } from 'app/UserContext';
import { LOCAL_STORAGE_CONSTANTS } from 'constants/globalConstants';
import { partCatalogs as PART_CATALOGS } from 'graphql/queries';
import { useLocalStorage } from 'hooks/useLocalStorage';

const { PREV_CATALOG_ID_KEY } = LOCAL_STORAGE_CONSTANTS;

const useCatalogSearchDropDownHelpers = ({ onChange = () => {} }) => {
  const defaultPartCatalogRef = useRef();
  const [defaultPartCatalog] = useLocalStorage(PREV_CATALOG_ID_KEY, '');

  const { user } = useUser();
  const { data, loading } = useQuery(gql(PART_CATALOGS), {
    variables: { query: { companyId: user?.companyId, orderBy: 'name:asc', take: 99999 } },
  });

  useEffect(() => {
    if (
      data?.partCatalogs &&
      defaultPartCatalog &&
      defaultPartCatalogRef.current !== defaultPartCatalog
    ) {
      defaultPartCatalogRef.current = defaultPartCatalog;
      onChange(defaultPartCatalog);
    }
  }, [defaultPartCatalog, data?.partCatalogs, onChange]);

  return { catalogs: data?.partCatalogs, loading };
};

export default useCatalogSearchDropDownHelpers;
