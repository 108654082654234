import React from 'react';

import { gql, useQuery } from '@apollo/client';

import { useUser } from 'app/UserContext';
import { departments as DEPARTMENTS } from 'graphql/queries';
import { DEPARTMENT_TYPE } from 'types/DepartmentTypes';

import DropDown from './DropDown';

export const mapDepartment = (department) => ({
  label: `<span>${department.facility?.facilityName}</span> / <span style="font-weight: 700">${department.departmentName}</span>`,
  value: department.departmentId,
});

const ProcurementDepartmentInput = ({ control }) => {
  const { user } = useUser();

  const { data: departments } = useQuery(gql(DEPARTMENTS), {
    variables: {
      query: { companyId: user.companyId, departmentTypeId: DEPARTMENT_TYPE.PROCUREMENT },
    },
    fetchPolicy: 'cache-and-network',
  });

  const departmentsOptions = React.useMemo(
    () => departments?.departments?.map(mapDepartment) ?? [],
    [departments],
  );

  return (
    <DropDown
      control={control}
      name="assignProcurementDepartment"
      label="Assign Procurement Department"
      noOptionsText="No Procurement Departments Available"
      options={departmentsOptions}
    />
  );
};

export default React.memo(ProcurementDepartmentInput);
