/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo } from 'react';

import { CalendarFully, useCalendarDispatch, useCalendarState } from '@evolvemep/foresite-components-ui-react';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { usePopoverFilterContext } from 'components/Popover/FilterPopover/context/PopoverFilterContext';
import { formatLocalTimeToUTC } from 'helpers/dateFunctions';
import { useShopConfig } from 'modules/Shop/providers/ShopConfigProvider';
import { WorkOrder } from 'typings/work-order';

import { convertToLocalTime } from '../helpers';
import { useWorkOrdersContext } from '../WorkOrdersContext';
import { SlotDayWorkOrder } from './Components/SlotDayWorkOrder';
import { SlotMonthWorkOrder } from './Components/SlotMonthWorkOrder';
import { SlotWeekWorkOrder } from './Components/SlotWeekWorkOrder';
import { SlotYearWorkOrder } from './Components/SlotYearWorkOrder';

const WorkOrderCalendar = () => {
  const location = useLocation();

  const { setCalendarSetupMemo, calendarSetupMemo } = useShopConfig();
  const { workOrders, projectsColors, setFilters } = useWorkOrdersContext();
  const { selectedDate, layout } = useCalendarState();
  const { setStateCalendar } = useCalendarDispatch();

  const { selectedItem } = useFacilitiesProjects();
  const { appendFilters, clearFilters } = usePopoverFilterContext();

  const year = useMemo(() => selectedDate.getFullYear(), [selectedDate]);

  const intervalDate = useMemo(
    () => ({
      start: formatLocalTimeToUTC(dayjs(selectedDate).startOf('year')),
      end: formatLocalTimeToUTC(dayjs(selectedDate).endOf('year')),
    }),
    [year],
  );

  const handleChangeLocation = useCallback(() => {
    setCalendarSetupMemo({ layout, day: selectedDate });
  }, [layout, selectedDate]);

  const handleLoadMemoCalendarSetup = useCallback(() => {
    if (!calendarSetupMemo) return;
    setStateCalendar((prev) => ({
      ...prev,
      layout: calendarSetupMemo.layout,
      selectedDate: calendarSetupMemo.day,
    }));
  }, [calendarSetupMemo]);

  useEffect(handleChangeLocation, [location, handleChangeLocation]);
  useEffect(handleLoadMemoCalendarSetup, []);

  useEffect(() => {
    clearFilters();
    appendFilters([
      { column: 'dateFrom', values: intervalDate.start },
      { column: 'dateTo', values: intervalDate.end },
      { column: 'take', values: '300' },
    ]);
    setFilters([]);
    return clearFilters;
  }, [selectedItem, intervalDate]);

  const workOrderDto = useMemo(
    () =>
      (workOrders as WorkOrder[])?.flatMap((workOrder) => {
        const dateStr = workOrder.workRequest.needBy;
        if (dateStr) {
          return { date: convertToLocalTime(dateStr).toDate(), ...workOrder };
        }
        return [];
      }),
    [workOrders],
  );

  return (
    <CalendarFully
      sx={{
        root: { height: '90%' },
        view: { overflowX: 'hidden' },
        control: { gap: 1.5 },
      }}
      slot={{
        year: SlotYearWorkOrder,
        week: SlotWeekWorkOrder,
        day: SlotDayWorkOrder,
        month: SlotMonthWorkOrder,
      }}
      slotProps={{
        resourceList: [...workOrderDto],
        projectsColors,
      }}
    />
  );
};

export default WorkOrderCalendar;
