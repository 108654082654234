import React, { useState } from 'react';

import { Stack } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { StatusCell, ThemedCheckBox } from 'components';
import { Custom } from 'components/Icons';
import MoreOptionsDropdown from 'components/TableComponents/MoreOptionsDropdown/MoreOptionsDropdown';
import { useEntitlementGet } from 'hooks-api/useEntitlementGet';

import CompanyMembersCollapsedContent from './CompanyMembersCollapsedContent';
import MoreTableRowOptions from './MoreTableRowOptions';

const CompanyMembersTableRow = ({ member, handleRowClick = () => {}, isItemSelected }) => {
  const { entitlementGetList, ...rest } = useEntitlementGet({ userId: member.userId });
  const [open, setOpen] = useState(false);

  const openCollapse = () => setOpen(true);
  const closeCollapse = () => setOpen(false);

  const memberName = [member.userFirstName, member.userLastName].join(' ').trim();

  return (
    <>
      <TableRow
        key={member.userId}
        role="checkbox"
        hover
        tabIndex={-1}
        aria-checked={isItemSelected}
        selected={isItemSelected}
      >
        <TableCell padding="checkbox">
          <ThemedCheckBox
            data-cy={member.userEmail}
            data-testid={member.userEmail}
            checked={isItemSelected}
            inputProps={{ 'aria-labelledby': member.userId }}
            onClick={(e) => handleRowClick(e, member)}
          />
        </TableCell>
        <TableCell align="left">
          <Stack direction="row" alignItems="center">
            <CollapseToggleIcon open={open} onClick={open ? closeCollapse : openCollapse} />
            {memberName || '--'}
          </Stack>
        </TableCell>
        <TableCell align="left">{member.userEmail}</TableCell>
        <TableCell align="left">{member.workPhoneNumber || '--'}</TableCell>
        <TableCell align="left">{member.personalPhoneNumber || '--'}</TableCell>
        <StatusCell status={member.isDeleted ? 'Deleted' : member.inviteStatus} />
        <TableCell>
          <MoreOptionsDropdown
            buttonProps={{
              'data-cy': `more-menu-${member.userEmail}`,
            }}
            optionsRenderer={(onClose) => (
              <MoreTableRowOptions
                entitlementGetList={entitlementGetList}
                companyMember={member}
                memberStatus={member.inviteStatus}
                onClose={onClose}
              />
            )}
          />
        </TableCell>
      </TableRow>
      {open && (
        <CompanyMembersCollapsedContent
          entitlementGetList={entitlementGetList}
          {...rest}
          open={open}
          userId={member.userId}
          selected={isItemSelected}
        />
      )}
    </>
  );
};

export default React.memo(CompanyMembersTableRow);

const CollapseToggleIcon = ({ open, onClick }) => {
  const handleClick = (event) => {
    event.stopPropagation();
    onClick();
  };

  return open ? (
    <Custom.ArrowDropDownIcon color="primary" onClick={handleClick} sx={{ cursor: 'pointer', opacity: 0.6 }} />
  ) : (
    <Custom.ArrowRightIcon onClick={handleClick} sx={{ cursor: 'pointer', opacity: 0.6 }} />
  );
};
