import React from 'react';

import { Stack, SxProps } from '@mui/material';

interface Props {
  children: React.ReactNode;
  sx?: SxProps;
}

const SectionBody = ({ children, sx, ...restProps }: Props) => (
  <Stack sx={{ overflowY: 'auto', ...sx }} {...restProps}>
    {children}
  </Stack>
);

export default SectionBody;
