import React from 'react';

import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { DrawingDocumentDraggableRow } from '../DrawingDocumentDraggableRow';

const DrawingDocumentDragContainer = ({ width = 0, height = 0, rows = [], sx }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: 'absolute',
        width,
        height: rows.length * height,
        boxShadow:
          '0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12)',
        background: 'white',
        ...sx,
      }}
    >
      <Box
        sx={{
          height: '100%',
          width: '3px',
          position: 'absolute',
          backgroundColor: theme.palette.secondary.main,
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
        }}
      />
      {rows.map((row) => (
        <DrawingDocumentDraggableRow key={row.id} height={height} {...row} />
      ))}
    </Box>
  );
};
export default DrawingDocumentDragContainer;
