import React from 'react';

import { CircularProgress, Stack, Tab, Typography } from '@mui/material';

import { TabBar } from 'components';
import Header from 'components/Header';

import WorkCellSelect from './components/WorkCellSelect';
import { useWorkCellQueueContext } from './WorkCellQueueContext';
import WorkCellTasksTable from './WorkCellTasksTable';

const WorkCellQueue = () => {
  const { activeTabIndex, setTabIndex, tasksCounter, loadingWorkCellsTasks } = useWorkCellQueueContext();

  const tasksCounterMessage = (() => {
    if (tasksCounter === 1) return '1 task';
    return `${tasksCounter} tasks`;
  })();

  return (
    <Stack>
      <Header title={<TitleComponent value={activeTabIndex} onChange={(e, newIndex) => setTabIndex(newIndex)} />} />
      <Stack direction="row" sx={{ alignItems: 'space-between', width: '100%' }}>
        <Stack sx={{ flex: 1 }}>
          <Stack direction="row" sx={{ alignItems: 'center' }}>
            <WorkCellSelect />
            {loadingWorkCellsTasks ? (
              <CircularProgress size={30} color="secondary" />
            ) : (
              <Typography mr={1} variant="body">
                {tasksCounterMessage}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Stack>
      <WorkCellTasksTable />
    </Stack>
  );
};

export default WorkCellQueue;

const TitleComponent = ({ ...rest }) => (
  <Stack direction="row">
    <Typography variant="h5" component="h1" mt={0.5}>
      Work Cell Queue
    </Typography>
    <Tabs {...rest} />
  </Stack>
);

const Tabs = ({ value, onChange }) => (
  <TabBar value={value} onChange={onChange} sx={{ ml: '60px' }}>
    <Tab label="Open" />
    <Tab label="Closed" />
  </TabBar>
);
