import React from 'react';

import { Stack, Typography } from '@mui/material';

import PropertyNote from './RevitModelNote';

const RevitModelNoteList = ({ notes }) => (
  <Stack>
    <Typography variant="body2" sx={{ mt: '10px' }}>
      Notes
    </Typography>

    {notes.map((note) => (
      <PropertyNote key={note.sourcePadNoteId} note={note} />
    ))}
  </Stack>
);

export default RevitModelNoteList;
