import React, { useEffect, useState } from 'react';

import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { useForm } from 'react-hook-form';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { useBillOfMaterialsAPI } from 'hooks-api/useBillOfMaterialsAPI';

import { ItemsIntegrationModal } from '../components/ItemsIntegrationModal';
import { NewWRFormProvider } from '../components/ItemsIntegrationModal/components/NewWRForm/context/NewWRFormContext';
import { ItemsIntegrationTableProvider } from '../components/ItemsIntegrationModal/components/QuantityStep/components/ItemsIntegrationTable/context/ItemsIntegrationTableContext';
import { CurrentProjectProvider } from '../components/ItemsIntegrationModal/context/CurrentProjectContext';
import { ItemsIntegrationProvider } from '../components/ItemsIntegrationModal/context/ItemsIntegrationModalContext';
import { useTakeOff } from './context/TakeOffContext';
import AddNewTakeOffPadNameModal from './Form/AddNewTakeOffPadNameModal';
import TakeOffHeader from './header/TakeOffHeader';
import useTakeoffToBomIntegration from './hooks/useTakeoffToBomIntegration';
import TakeOffPadPage from './TakeOffPadPage';
import { EditQuantityModalProvider } from './TakeOffPadTab/EditQuantityModal/contexts/EditQuantityModalContext';
import TabPads from './TakeOffPadTab/TabPads/TabPads';
import { TabPadsProvider } from './TakeOffPadTab/TabPads/TabPadsContext';

const TakeOff = React.forwardRef((props, ref) => {
  const {
    control,
    formState: { errors },
  } = useForm({ mode: 'all' });

  const [isShowNewTakeOffModal, setIsShowNewTakeOffModal] = React.useState(false);
  const hideTakeOffModal = () => setIsShowNewTakeOffModal(false);
  const { chipList, selectedPadItems } = useTakeOff();
  const [bomId, setBomId] = useState(null);
  const { data: BOMdata } = useBillOfMaterialsAPI();
  const { selectedItem } = useFacilitiesProjects();

  useEffect(() => {
    if (BOMdata && BOMdata.length && selectedItem) {
      setBomId(BOMdata[0].billOfMaterialsId ?? null);
    }
  }, [BOMdata, selectedItem]);

  const { handleAddItems, handleItemsCreated } = useTakeoffToBomIntegration();

  return (
    <CurrentProjectProvider currentProject={selectedItem}>
      <ItemsIntegrationProvider
        locationsTableDescription="Specify the quantity of items you want to send to the BOM"
        showSteps={false}
        showOnlyQtyStep
        selectedItems={selectedPadItems}
        selectedItemIdKey="lineItemId"
      >
        <EditQuantityModalProvider chipList={chipList}>
          <NewWRFormProvider>
            <ItemsIntegrationTableProvider
              onItemsCreated={handleItemsCreated}
              addColumnHeader="Send"
              baseERN="ern:moab:takeoffpaditem"
            >
              <ItemsIntegrationModal
                modalTitle="Send to BOM"
                integrationId={bomId}
                integratedItemsERN="ern:moab:bomitem"
                addItems={handleAddItems}
              />
            </ItemsIntegrationTableProvider>
          </NewWRFormProvider>
          <AddNewTakeOffPadNameModal
            control={control}
            open={isShowNewTakeOffModal}
            onCancel={hideTakeOffModal}
            onSubmit={() => {}}
            isValid={Object.keys(errors).length > 0}
          />
          <Stack direction="row" flexWrap="wrap" sx={{ position: 'relative' }}>
            <TakeOffHeader />
            <PadsWrapper>
              <TabPadsProvider>
                <TabPads />
              </TabPadsProvider>
            </PadsWrapper>
          </Stack>
          <TakeOffPadPage ref={ref} />
        </EditQuantityModalProvider>
      </ItemsIntegrationProvider>
    </CurrentProjectProvider>
  );
});

export default TakeOff;

const PadsWrapper = ({ children }) => (
  <Box
    sx={{
      display: 'flex',
      width: '100%',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
    }}
  >
    {children}
  </Box>
);
