import React from 'react';

import { useTheme, Button, Stack, Typography } from '@mui/material';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { Custom } from 'components/Icons';

const NewWorkRequestButton = ({ disabled = false, checkProjectIdentifier }) => {
  const { selectedItem } = useFacilitiesProjects();
  const theme = useTheme();

  if (selectedItem.type === 'FACILITY') return <></>;

  return (
    <Button
      onClick={checkProjectIdentifier}
      sx={{
        padding: '4px 12px',
        height: 'fit-content',
        marginLeft: '16px',
        '&.MuiButton-outlined': { borderColor: 'secondary.outlinedRestingBorder' },
        '&:hover': { backgroundColor: 'secondary.outlinedHoverBackground' },
      }}
      variant="outlined"
      disabled={disabled}
    >
      <Stack direction="row" sx={{ alignItems: 'center' }}>
        <Custom.Add
          style={{
            fill: disabled ? theme?.palette?.action?.disabled : theme?.palette?.secondary?.dark,
            margin: '0px 8px 0px 0px',
          }}
        />
        <Typography
          sx={{
            fontSize: '14px',
            color: disabled ? 'secondary.disabled' : 'secondary.dark',
            lineHeight: '22px',
            fontWeight: '500',
          }}
        >
          NEW WORK REQUEST
        </Typography>
      </Stack>
    </Button>
  );
};

export default NewWorkRequestButton;
