import React, { useState } from 'react';

import { InputLabel, FormControl, Select, MenuItem } from '@mui/material';

import { usePopover } from '../PopoverProvider';

const Menu = () => {
  const { menuValue, updateMenuValue } = usePopover();
  const handleChange = (event) => updateMenuValue(event.target.value);
  const [isFocused, setIsFocused] = useState(false);

  return (
    <FormControl
      sx={{
        width: '312px',
        margin: '16px 0 0 24px',
      }}
      size="small"
    >
      <InputLabel id="inputId"> Breakdown Structure </InputLabel>
      <Select
        labelId="inputIdLabel"
        id="selectId"
        value={menuValue}
        label="Breakdown Structure"
        onChange={handleChange}
        onOpen={() => setIsFocused(true)}
        onClose={() => setIsFocused(false)}
        sx={{
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: ` ${isFocused ? '#DB6939' : '#dddddd'} !important`,
          },
        }}
      >
        <MenuItem value="Location Breakdown Structure">Location Breakdown Structure</MenuItem>
        <MenuItem value="Locations with Work Phases">Locations with Work Phases</MenuItem>
        <MenuItem value="Work Phases">Work Phases</MenuItem>
      </Select>
    </FormControl>
  );
};

export default Menu;
