import React from 'react';

import { Stack, Typography } from '@mui/material';

import { Custom } from 'components/Icons';

import Attributes from './Attributes/Attributes';
import DescriptionField from './Fields/DescriptionField';
import ManufacturerAutoField from './Fields/ManufacturerAutoField';
import PartNameField from './Fields/PartNameField';
import PartImage from './PartImage/PartImage';
import UnitOfMeasure from './UnitOfMeasure';

const Manufacturer = ({ control, manufacturers, selectedPart = {}, ...restProps }) => (
  <Stack direction="row" position="relative">
    <PartImage />
    <Stack
      sx={{
        margin: '16px 24px',
        backgroundColor: '#F5F5F5',
        padding: 2,
        gap: 2,
        width: '100%',
      }}
    >
      <Stack
        direction="row"
        sx={{
          alignItems: 'center',
          gap: '16px',
        }}
      >
        <ManufacturerAutoField name="manufacturerId" control={control} options={manufacturers} />
        <Custom.RadioButtonChecked />
        <Typography variant="body2" sx={{ color: 'rgba(0, 0, 0, 0.26)' }}>
          Default Manufacturer
        </Typography>
      </Stack>
      <PartNameField name="partName" size="small" />
      <DescriptionField name="description" defaultValue={selectedPart?.description} />
      <UnitOfMeasure {...restProps} />
      <Attributes />
    </Stack>
  </Stack>
);

export default Manufacturer;
