import React from 'react';

import { Typography } from '@mui/material';

import { usePartCategory } from '../PartCategory/PartCategoryContext';

const PartItemTitle = ({ children, onClick, sx, showOutlineOnHover = true, ...rest }) => {
  const { popupColor } = usePartCategory();
  const hoverSx = popupColor
    ? {
        color: popupColor,
        textDecorationLine: 'underline',
      }
    : {
        color: 'secondary.main',
        textDecorationLine: 'underline',
      };

  return (
    <Typography
      component="span"
      sx={{
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '14px',
        marginBottom: 1,
        width: 'fit-content',
        '&:hover': {
          cursor: 'pointer',
          ...(showOutlineOnHover && hoverSx),
        },
        ...sx,
      }}
      onClick={onClick}
      {...rest}
    >
      {children}
    </Typography>
  );
};
export default PartItemTitle;
