import axios from 'axios';
import { saveAs } from 'file-saver';
import fileDownload from 'js-file-download';
import JSZip from 'jszip';
import { useSnackbar } from 'notistack';

import { VARIANT_ERROR } from 'constants/snackbarConstants';

export default function useFileDownloader() {
  const { enqueueSnackbar } = useSnackbar();

  const handleDownloadFile = (url, filename) => {
    if (!url) return;

    if (!filename.includes('.')) filename += '.pdf';

    axios
      .get(url, { responseType: 'blob' })
      .then((res) => {
        fileDownload(res.data, filename);
      })
      .catch(() => {
        enqueueSnackbar('Error downloading this file', VARIANT_ERROR);
      });
  };

  /* Structure of files object must be
  [
    {
      fileName: string;
      fileUrl: string
    }
  ] 
  */
  const handleDownloadZip = async (zipName, files) => {
    let finalFiles = await Promise.all(
      files.map(async (file) => {
        try {
          const resp = await axios.get(file.fileUrl, { responseType: 'blob' });

          return { ...file, data: resp.data };
        } catch {
          return null;
        }
      }),
    );

    finalFiles = finalFiles.filter((file) => Boolean(file));

    if (!finalFiles.length) return enqueueSnackbar('Error downloading all files', VARIANT_ERROR);

    if (finalFiles.length !== files.length) enqueueSnackbar('Error downloading some files', VARIANT_ERROR);

    const zip = new JSZip();
    const folder = zip.folder(zipName);

    finalFiles.forEach((file) => folder.file(file.fileName, file.data, { blob: true }));

    return zip.generateAsync({ type: 'blob' }).then((blob) => {
      saveAs(blob, `${zipName}.zip`);
    });
  };

  return { handleDownloadFile, handleDownloadZip };
}
