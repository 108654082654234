import React from 'react';

import { Stack, IconButton } from '@mui/material';

import { Custom } from 'components/Icons';
import { surfaceColors } from 'styles/theme/colors';

const DrawerContainerItem = ({
  sx,
  children,
  onDragStart,
  onDrag = () => {},
  onDragEnd = () => {},
}) => (
  <StyledItem sx={sx}>
    <DraggableIcon />
    <Stack
      data-cy="drawerDraggableItem"
      draggable
      onDragStart={(event) => onDragStart(event, 'input')}
      onDrag={onDrag}
      onDragEnd={onDragEnd}
      sx={{ margin: 'auto' }}
    >
      {children}
    </Stack>
  </StyledItem>
);

const StyledItem = React.forwardRef(({ sx, children, ...props }, ref) => (
  <Stack ref={ref} sx={{ mt: '16px', width: 'auto', ...sx }} direction="row" {...props}>
    {children}
  </Stack>
));

const DraggableIcon = ({ showDrag }) => (
  <IconButton
    style={{
      cursor: 'move',
      padding: 0,
      margin: 0,
    }}
  >
    {showDrag && <Custom.DragIndicator style={{ fill: surfaceColors.darkSurface.primary }} />}
  </IconButton>
);

export default DrawerContainerItem;
