import React from 'react';

import { useFormContext } from 'react-hook-form';

import { useDrawingDocumentContext } from '../../DrawingDocumentContext';
import DrawingControlDocumentField from './DrawingControlDocumentField';

const DrawingDocumentField = ({ name, row, autoFocus, updateRows, ...restProps }) => {
  const { closeFields, updateDrawing } = useDrawingDocumentContext();

  const {
    control,
    formState: { isDirty, errors, isValid },
    handleSubmit,
    watch,
  } = useFormContext();

  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (!isDirty) {
        return;
      }
      if (isValid) {
        updateRows([
          {
            id: row.id,
            isProcessing: true,
            documentIdentifier: watch('documentIdentifier'),
            documentName: watch('documentName'),
          },
        ]);

        handleSubmit((data) => updateDrawing(data, updateRows))();
      }
    }
    if (event.key === 'Escape') closeFields(event, row.id, updateRows);
    if (event.key === 'Tab') {
      event.preventDefault();
      updateRows([
        {
          id: row.id,
          field: row.field === 'documentIdentifier' ? 'documentName' : 'documentIdentifier',
        },
      ]);
    }
    event.stopPropagation();
  };

  return (
    <DrawingControlDocumentField
      helperText={errors[name]?.message}
      name={name}
      control={control}
      autoFocus={autoFocus}
      error={!!errors?.[name]}
      onKeyDown={onKeyDown}
      {...restProps}
      disabled={row?.isProcessing}
    />
  );
};

export default DrawingDocumentField;
