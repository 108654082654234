import { useEffect, useState } from 'react';

export default function useWorkCellFormValidation({
  watchWorkCellName,
  watchWorkCellDescription,
  setValue,
  clearErrors,
}) {
  const [workCellNameWarning, setWorkCellNameWarning] = useState(false);
  const [workCellDescriptionWarning, setWorkCellDescriptionWarning] = useState(false);

  useEffect(() => {
    if (watchWorkCellName?.length === 33) {
      setWorkCellNameWarning(true);
      setValue('workCellName', watchWorkCellName?.slice(0, -1));
      clearErrors('workCellName');
    } else if (watchWorkCellName?.length < 32) {
      setWorkCellNameWarning(false);
    }
  }, [watchWorkCellName, setValue, clearErrors]);

  useEffect(() => {
    if (watchWorkCellDescription?.length === 256) {
      setWorkCellDescriptionWarning(true);
      setValue('workCellDescription', watchWorkCellDescription?.slice(0, -1));
      clearErrors('workCellDescription');
    } else if (watchWorkCellDescription?.length < 255) {
      setWorkCellDescriptionWarning(false);
    }
  }, [watchWorkCellDescription, setValue, clearErrors]);

  return { workCellNameWarning, workCellDescriptionWarning };
}
