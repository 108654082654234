import React from 'react';

import { Custom } from 'components/Icons';
import { actionColors } from 'styles/theme/colors';

export type EIconType = typeof EIconType[keyof typeof EIconType];
// eslint-disable-next-line no-redeclare
export const EIconType = {
  SHOPS: 'SHOPS',
  DASHBOARD: 'DASHBOARD',
  PROJECTS: 'PROJECTS',
  PROPERTIES: 'PROPERTIES',
  COMPANY: 'COMPANY',
  COMPANY_MEMBERS: 'COMPANY_MEMBERS',
  PROJECT_PROPERTIES: 'PROJECT_PROPERTIES',
  PROJECT_MEMBERS: 'PROJECT_MEMBERS',
  COMPANY_PROPERTIES: 'COMPANY_PROPERTIES',
  SHOP_MEMBERS: 'SHOP_MEMBERS',
  SHOP_PROPERTIES: 'SHOP_PROPERTIES',
  SHOP_TASKS_NAV: 'SHOP_TASKS_NAV',
  TASK_TYPE_MAPPING: 'TASK_TYPE_MAPPING',
  SHOP_TASKS_THUMBNAIL: 'SHOP_TASKS_THUMBNAIL',
  WORK_PLANNING: 'WORK_PLANNING',
  FABRICATION: 'FABRICATION',
  CATALOG_SETUP: 'CATALOG_SETUP',
  SUPPLIERS: 'SUPPLIERS',
  BOM: 'BOM',
  BILL_OF_MATERIALS: 'BILL_OF_MATERIALS',
  SPOOLS: 'SPOOLS',
  MATERIAL_MEMBERS: 'MATERIAL_MEMBERS',
  NAV_ITEM_1: 'NAV_ITEM_1',
  NAV_ITEM_2: 'NAV_ITEM_2',
  NAV_ITEM_3: 'NAV_ITEM_3',
  NAV_ITEM_4: 'NAV_ITEM_4',
  NAV_ITEM_5: 'NAV_ITEM_5',
  WORKFLOWS: 'WORKFLOWS',
  CONNECTIONS: 'CONNECTIONS',
  LOGS: 'LOGS',
  EVOLVE_MEMBERS: 'EVOLVEMEMBERS',
  PRODUCTS_AND_BUNDLES: 'PRODUCTSANDBUNDLES',
  CUSTOMERS: 'CUSTOMERS',
  CREATE: 'CREATE',
  GIFT_CARD: 'GIFT_CARD',
  GROUP_WORK: 'GROUP_WORK',
  LOCATION: 'LOCATION',
  LOCATION_TIME: 'LOCATION_TIME',
  WORK_BREAKDOWN_STRUCTURE: 'WORK_BREAKDOWN_STRUCTURE',
  WORK_REQUESTS: 'WORK_REQUESTS',
  BILL_OF_MATERIALS_WORK_REQUEST: 'BILL_OF_MATERIALS_WORK_REQUEST',
  BILL_OF_PROCESS_WORK_REQUEST: 'BILL_OF_PROCESS_WORK_REQUEST',
  BILL_OF_PROCESS: 'BILL_OF_PROCESS',
  MOAB_WORK_REQUEST: 'MOAB_WORK_REQUEST',
  CHILD_WORK_REQUERST: 'CHILD_WORK_REQUERST',
  REQUISITIONS_WORK_REQUEST: 'REQUISITIONS_WORK_REQUEST',
  TAKE_OFF_PAGE: 'TAKE_OFF_PAGE',
  WORK_CELLS_MACHINES: 'WORK_CELLS_MACHINES',
  WORK_CELLS: 'WORK_CELLS',
  MEMBERS: 'MEMBERS',
  LICENSES: 'LICENSES',
  FIELD_MEMBERS: 'FIELD_MEMBERS',
  DESIGN_MEMBERS: 'DESIGN_MEMBERS',
  DESIGN_PROPERTIES: 'DESIGN_PROPERTIES',
  PROJECT_MATERIALS: 'PROJECT_MATERIALS',
  WORK_ORDERS: 'WORK_ORDERS',
  WORK_REQUESTS_PROPERTIES: 'WORK_REQUESTS_PROPERTIES',
  WORK_CELL_QUEUE: 'WORK_CELL_QUEUE',
  WORKCELL: 'WORKCELL',
  WORKCELL_INACTIVE: 'WORKCELL_INACTIVE',
  MACHINE: 'MACHINE',
  MACHINE_INACTIVE: 'MACHINE_INACTIVE',
  ASSEMBLY: 'ASSEMBLY',
  FLOOR: 'FLOOR',
  LOCATION_SYSTEM: 'LOCATION_SYSTEM',
  ZONE: 'ZONE',
  FOLDER: 'FOLDER',
  TARGET: 'TARGET',
  SAND_WATCH: 'SAND_WATCH',
  CUSTOM_ASSEMBLY_TYPE: 'CUSTOM_ASSEMBLY_TYPE',
  DRAWING_TYPE: 'DRAWING_TYPE',
  WORKFLOW_EDITOR_BG: 'WORKFLOW_EDITOR_BG',
  DRAWING_REGISTER: 'DRAWING_REGISTER',
  SYNC: 'SYNC',
  REQUISITIONS: 'REQUISITIONS',
  REPORTING: 'REPORTING',
  REPORT_VIEWER: 'REPORT_VIEWER',
  REPORT_DESIGNER: 'REPORT_DESIGNER',
  PARTS_CATALOG: 'PARTS_CATALOG',
  DATA_IMPORT: 'DATA_IMPORT',
  CUSTOM_ASSEMBLIES: 'CUSTOM_ASSEMBLIES',
  SHOP_CONFIGURATION: 'SHOP_CONFIGURATION',
  LOCATION_MACHINE: 'LOCATION_MACHINE',
} as const;
// NOTE: for icon to use the color make sure the fill property has the value 'current' in the svg file (svg and paths)
export const getNavIcon = (icon: EIconType, color = actionColors.disabled) =>
  ({
    [EIconType.SHOPS]: <Custom.Shops fill={color} />,
    [EIconType.PROJECTS]: <Custom.Projects fill={color} />,
    [EIconType.COMPANY]: <Custom.CompanyProperties fill={color} />,
    [EIconType.COMPANY_MEMBERS]: <Custom.ShopMembers fill={color} />,
    [EIconType.COMPANY_PROPERTIES]: <Custom.Settings fill={color} />,
    [EIconType.PROJECT_MEMBERS]: <Custom.ShopMembers fill={color} />,
    [EIconType.PROJECT_PROPERTIES]: <Custom.Settings fill={color} />,
    [EIconType.SHOP_MEMBERS]: <Custom.ShopMembers fill={color} />,
    [EIconType.SHOP_PROPERTIES]: <Custom.ShopProperties fill={color} />,
    [EIconType.SHOP_TASKS_NAV]: <Custom.ShopTasksNav fill={color} />,
    [EIconType.TASK_TYPE_MAPPING]: <Custom.TaskTypeMapping fill={color} />,
    [EIconType.WORK_PLANNING]: <Custom.WorkPlanning fill={color} />,
    [EIconType.CUSTOM_ASSEMBLY_TYPE]: <Custom.AssemblyType fill={color} />,
    [EIconType.FABRICATION]: <Custom.Fabrication fill={color} />,
    [EIconType.SHOP_TASKS_THUMBNAIL]: <Custom.ShopTasksThumbnail />,
    [EIconType.CATALOG_SETUP]: <Custom.CatalogSetup fill={color} />,
    [EIconType.SUPPLIERS]: <Custom.Suppliers fill={color} />,
    [EIconType.BOM]: <Custom.BOM fill={color} />,
    [EIconType.BILL_OF_MATERIALS]: <Custom.BillOfMaterials fill={color} />,
    [EIconType.BILL_OF_PROCESS]: <Custom.WorkRequestFill fill={color} />,
    [EIconType.SPOOLS]: <Custom.Spools fill={color} />,
    [EIconType.MATERIAL_MEMBERS]: <Custom.ShopMembers fill={color} />,
    [EIconType.NAV_ITEM_1]: <Custom.EvolveMembers fill={color} />,
    [EIconType.NAV_ITEM_2]: <Custom.BundlesAndProducts fill={color} />,
    [EIconType.NAV_ITEM_3]: <Custom.NavItem3 fill={color} />,
    [EIconType.NAV_ITEM_4]: <Custom.NavItem4 fill={color} />,
    [EIconType.NAV_ITEM_5]: <Custom.NavItem5 fill={color} />,
    [EIconType.CONNECTIONS]: <Custom.CONNECTIONS fill={color} />,
    [EIconType.LOGS]: <Custom.LOGS fill={color} />,
    [EIconType.DASHBOARD]: <Custom.DASHBOARD fill={color} />,
    [EIconType.EVOLVE_MEMBERS]: <Custom.EvolveMembers fill={color} />,
    [EIconType.PRODUCTS_AND_BUNDLES]: <Custom.BundlesAndProducts fill={color} />,
    [EIconType.CUSTOMERS]: <Custom.Customers fill={color} />,
    [EIconType.CREATE]: <Custom.ProjectSetup fill={color} />,
    [EIconType.GIFT_CARD]: <Custom.GiftCard fill={color} />,
    [EIconType.GROUP_WORK]: <Custom.GroupWork fill={color} />,
    [EIconType.LOCATION]: <Custom.Location fill={color} />,
    [EIconType.LOCATION_TIME]: <Custom.LocationTime fill={color} />,
    [EIconType.FLOOR]: <Custom.Floor fill={color} />,
    [EIconType.LOCATION_SYSTEM]: <Custom.LocationSystem fill={color} />,
    [EIconType.ZONE]: <Custom.Zone fill={color} />,
    [EIconType.FOLDER]: <Custom.Folder fill={color} />,
    [EIconType.TARGET]: <Custom.Target fill={color} />,
    [EIconType.SAND_WATCH]: <Custom.SandWatch fill={color} />,
    [EIconType.WORK_BREAKDOWN_STRUCTURE]: <Custom.WorkBreakdownStructure fill={color} />,
    [EIconType.PROPERTIES]: <Custom.ShopSetup fill={color} />,
    [EIconType.MEMBERS]: <Custom.Members fill={color} />,
    [EIconType.WORK_REQUESTS]: <Custom.WorkRequests fill={color} />,
    [EIconType.BILL_OF_MATERIALS_WORK_REQUEST]: <Custom.BillOfMaterialsWorkRequest fill={color} />,
    [EIconType.BILL_OF_PROCESS_WORK_REQUEST]: <Custom.BillOfProcessWorkRequest fill={color} />,
    [EIconType.MOAB_WORK_REQUEST]: <Custom.MoabWorkRequest fill={color} />,
    [EIconType.REQUISITIONS_WORK_REQUEST]: <Custom.RequesitionWorkRequest fill={color} />,
    [EIconType.CHILD_WORK_REQUERST]: <Custom.ChildWorkRequest fill={color} />,
    [EIconType.TAKE_OFF_PAGE]: <Custom.TakeOff fill={color} />,
    [EIconType.WORK_CELLS_MACHINES]: <Custom.WorkCellsAndMachines fill={color} />,
    [EIconType.WORK_CELLS]: <Custom.WorkCells fill={color} />,
    [EIconType.LICENSES]: <Custom.DoubleCheck fill={color} />,
    [EIconType.FIELD_MEMBERS]: <Custom.ShopMembers fill={color} />,
    [EIconType.DESIGN_MEMBERS]: <Custom.ShopMembers fill={color} />,
    [EIconType.DESIGN_PROPERTIES]: <Custom.Settings fill={color} />,
    [EIconType.PROJECT_MATERIALS]: <Custom.ProjectMaterials fill={color} />,
    [EIconType.LOCATION_MACHINE]: <Custom.LocationMachine />,
    [EIconType.WORK_ORDERS]: <Custom.WorkOrders fill={color} />,
    [EIconType.WORK_REQUESTS_PROPERTIES]: <Custom.WorkRequestsProperties fill={color} />,
    [EIconType.WORK_CELL_QUEUE]: <Custom.ShopWorkCellQueue fill={color} />,
    [EIconType.WORKCELL]: <Custom.NavItem2 fill={color} />,
    [EIconType.WORKCELL_INACTIVE]: <Custom.WorkcellInactive fill={color} />,
    [EIconType.MACHINE]: <Custom.Machine fill="black" />,
    [EIconType.MACHINE_INACTIVE]: <Custom.MachineInactive fill={color} />,
    [EIconType.ASSEMBLY]: <Custom.Assembly fill={color} />,
    [EIconType.DRAWING_TYPE]: <Custom.DrawingType fill={color} />,
    [EIconType.WORKFLOW_EDITOR_BG]: <Custom.WorkflowEditorBg fill={color} />,
    [EIconType.WORKFLOWS]: <Custom.Workflow fill={color} />,
    [EIconType.DRAWING_REGISTER]: <Custom.DrawingRegister fill={color} />,
    [EIconType.SYNC]: <Custom.SYNC fill={color} />,
    [EIconType.REQUISITIONS]: <Custom.Requisitions fill={color} />,
    [EIconType.REPORTING]: <Custom.Reporting fill={color} />,
    [EIconType.REPORT_VIEWER]: <Custom.ReportViewer fill={color} />,
    [EIconType.REPORT_DESIGNER]: <Custom.ReportDesigner fill={color} />,
    [EIconType.PARTS_CATALOG]: <Custom.PartsCatalog fill={color} />,
    [EIconType.DATA_IMPORT]: <Custom.DataImport fill={color} />,
    [EIconType.CUSTOM_ASSEMBLIES]: <Custom.CustomAssemblies fill={color} />,
    [EIconType.SHOP_CONFIGURATION]: <Custom.ShopConfiguration fill={color} />,
  }[icon]);
