import React, { useState, useRef, useMemo, useEffect, ReactNode } from 'react';

import { useGridApiRef } from '@mui/x-data-grid-pro';

import getClient from 'apollo/client';
import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { usePopoverFilterContext } from 'components/Popover/FilterPopover/context/PopoverFilterContext';
import { LOCAL_STORAGE_CONSTANTS } from 'constants/globalConstants';
import type { WorkOrder } from 'graphql/types';
import { isNotNil } from 'helpers/isNotNil';
import useFetchWorkOrders from 'hooks-api/useFetchWorkOrders';
import { getLocalStorage, setLocalStorage } from 'hooks/useLocalStorage';
import { useWorkRequestsContext } from 'modules/Field/WorkRequests/WorkRequestsContext';

import useDocumentTypes from '../Fabrication/TaskViewer/PlansModelsContent/hooks/useDocumentTypes';
import { assignedColorToProject } from './WorkOrdersList/helpers/assignedProjectColor';
import { formatFiltersToQueryParams } from './WorkOrdersList/helpers/filterHelpers';

const WorkOrdersContext = React.createContext({});

const WorkOrdersProvider = ({ children }: { children: ReactNode }) => {
  const [filters, setFilters] = useState([]);
  const [statusToFilter, setStatusToFilter] = useState('');
  const [selectedWorkOrder, setSelectedWorkOrder] = useState(null);
  const apiRef = useGridApiRef();
  const { updateWRList } = useWorkRequestsContext();
  const { commonTypeId } = useDocumentTypes();
  const { loading, workOrders, getWorkOrders, paginationHandler, startPolling, stopPolling } =
    useFetchWorkOrders('cache-and-network');

  const { selectedItem, shopDepartmentId } = useFacilitiesProjects();
  const { filters: filtersPopover } = usePopoverFilterContext();

  const project = useRef(selectedItem?.type === 'PROJECT');
  const facility = useRef(selectedItem?.type === 'FACILITY');

  const formattedFilters = useMemo(() => formatFiltersToQueryParams(filtersPopover), [filtersPopover]);

  const projectsColors = useMemo(() => {
    const getProjectId = (workOrder: WorkOrder) => workOrder?.workRequest?.projectId;
    const projectColors = assignedColorToProject([...new Set(workOrders?.map(getProjectId).filter(isNotNil) ?? [])]);
    const projectColorMemory = getLocalStorage(LOCAL_STORAGE_CONSTANTS.PROJECT_COLORS, {});
    const newProjectColors = {
      ...Object.fromEntries([...projectColors]),
      ...projectColorMemory,
    };
    setLocalStorage(LOCAL_STORAGE_CONSTANTS.PROJECT_COLORS, newProjectColors);
    return new Map([...projectColors].map(([key, value]) => [key, newProjectColors?.[key] ?? value]));
  }, [workOrders]);

  useEffect(() => {
    if (!commonTypeId) return;

    const fetchWorkOrders = async (key: 'projectId' | 'facilityId', id: string | null | undefined) => {
      const client = await getClient();
      client.cache.evict({
        id: 'ROOT_QUERY',
        fieldName: 'workOrders',
      });

      const variables = {
        query: {
          take: 50,
          [key]: id,
          ...{ ...filters, ...formattedFilters },
          orderBy: 'needBy:asc',
          documentTypeIds: commonTypeId,
        },
      };

      await getWorkOrders({ variables });
    };

    if (project && project.current && filters) fetchWorkOrders('projectId', selectedItem?.id);
    if (facility && facility.current && filters) fetchWorkOrders('facilityId', selectedItem?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, formattedFilters, commonTypeId, updateWRList]);

  useEffect(() => {
    const isProcessing = workOrders?.some((workOrder) => workOrder?.isStepFunctionProcessing);

    if (isProcessing) startPolling(5000);

    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling, workOrders]);

  const selectedStateObj = {
    filters,
    setFilters,
    statusToFilter,
    setStatusToFilter,
    loading,
    workOrders,
    shopDepartmentId,
    selectedItem,
    project,
    facility,
    paginationHandler,
    projectsColors,
    selectedWorkOrder,
    setSelectedWorkOrder,
    apiRef,
  };

  return <WorkOrdersContext.Provider value={selectedStateObj}>{children}</WorkOrdersContext.Provider>;
};

const useWorkOrdersContext = () => {
  const context = React.useContext(WorkOrdersContext);
  if (context === undefined) {
    throw new Error('useWorkOrdersContext must be used within a WorkOrdersContext');
  }
  return context;
};

export { WorkOrdersContext, WorkOrdersProvider, useWorkOrdersContext };
