import { FC, ReactElement } from 'react';

import { ButtonProps } from '@mui/material';

import NextButton from './NextButton';
import PreviousButton from './PreviousButton';

interface IWizardButton extends ButtonProps {
  children: ReactElement;
}

interface IWizardButtonComponent extends FC<IWizardButton> {
  Previous: FC<ButtonProps>;
  Next: FC<ButtonProps>;
}

const WizardButton: IWizardButtonComponent = ({ children }: IWizardButton) => children;

WizardButton.Previous = PreviousButton;
WizardButton.Next = NextButton;

export default WizardButton;
