import React from 'react';

import { Button, ButtonProps } from '@mui/material';

const PreviousButton = ({ onClick = () => {}, disabled }: ButtonProps) => (
  <Button
    color="secondary"
    sx={{ color: 'secondary.main', letterSpacing: '0.4px', fontWeight: 600 }}
    variant="outlined"
    onClick={(e) => onClick(e)}
    disabled={disabled}
  >
    PREVIOUS
  </Button>
);

export default PreviousButton;
