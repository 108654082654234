import React from 'react';

import Link from '@mui/material/Link';

import { Custom } from 'components/Icons';

const EvolveLogoLink = () => (
  <Link href="https://www.evolvemep.com/" target="_blank">
    <img data-testid="evolve-logo-mobile" src={Custom.EvolveLogo} alt="mobile-logo" />
  </Link>
);

export default EvolveLogoLink;
