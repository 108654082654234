import { useCallback, useEffect } from 'react';

import { gql, useQuery } from '@apollo/client';

import {
  workRequestItems as WORK_REQUESTS_ITEMS,
  workRequestItemType as WORK_REQUEST_ITEM_TYPE,
} from 'graphql/queries';
import useCache from 'hooks/useCache';
import useLazyPaginatedQuery from 'hooks/useLazyPaginatedQuery';

const useWorkRequestItemsAPI = (workRequestId) => {
  const { deleteFromCacheByQuery } = useCache();

  const [{ lazyLoad, paginationHandler }, { data }] = useLazyPaginatedQuery(
    gql(WORK_REQUESTS_ITEMS),
    'cache-and-network',
    30,
  );

  useEffect(() => {
    if (workRequestId) {
      deleteFromCacheByQuery('workRequestItems', { workRequestId });
      lazyLoad({ workRequestId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workRequestId]);

  const { data: workRequestItemType, loading: loadingWRItemType } = useQuery(gql(WORK_REQUEST_ITEM_TYPE), {
    fetchPolicy: 'network-only',
  });

  const fetchMore = useCallback(
    (skip) => {
      if (workRequestId) paginationHandler(skip, { workRequestId });
    },
    [paginationHandler, workRequestId],
  );

  return {
    workRequestItems: data?.workRequestItems,
    workRequestItemType: workRequestItemType?.workRequestItemType,
    loadingWRItemType,
    fetchMore,
  };
};

export default useWorkRequestItemsAPI;
