import React from 'react';

import { Box } from '@mui/material';

import AttributeVariantMenu from './AttributeVariantMenu';
import VariantForm from './VariantForm';

const AddNewVariantForm = ({
  handleKeyDown,
  selectedAttribute,
  handleClickAttribute,
  variants,
}) => (
  <Box>
    <VariantForm
      variants={variants}
      onKeyDown={handleKeyDown}
      actionComponent={<AttributeVariantMenu />}
      selectedAttribute={selectedAttribute}
      handleClickAttribute={handleClickAttribute}
    />
  </Box>
);

export default AddNewVariantForm;
