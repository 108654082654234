import { useCallback } from 'react';

import { LicensesGetParams, ProductPool } from 'types/types-api';

import { useWrappedGet } from './useWrappedApiCall';

export const useLicensesGet = (inputQuery: LicensesGetParams) => {
  const { apiCall, ...rest } = useWrappedGet<{ data: ProductPool[] }>('admin/productPool', {
    defaultConfig: {
      params: inputQuery,
    },
  });
  const doLicensesGet = useCallback(async (params: LicensesGetParams) => apiCall({ params }), [apiCall]);
  return { doLicensesGet, ...rest };
};
