/* eslint-disable max-lines-per-function */
import React from 'react';

import IconButton from '@mui/material/IconButton';
import { useFormContext } from 'react-hook-form';

import { Custom } from 'components/Icons';
import { customMaxLengthRule, positiveAndIntegerNumberRules, requiredRule } from 'constants/inputFieldRules';
import { UOMAutocomplete } from 'modules/Materials/BillOfMaterialsById/BOMCatalogTable/Form';

import { useUpdateWorkRequestItem } from './hooks/useUpdateWorkRequestItem';
import WorkRequestControlInput from './WorkRequestControlInput';
import { useWorkRequestItemsContext } from './WorkRequestItemsContext';

const WorkRequestTableFields = ({ field, row, openModalUom }) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();
  const { workRequestItems, uomOptions, uomDefaultValue, isReadOnly, inWorkOrders } = useWorkRequestItemsContext();

  const { submitItem, closeFields } = useUpdateWorkRequestItem(row);

  const workRequestItemLength = watch('workRequestItemName')?.length;
  const workRequestItemNameValidation = workRequestItemLength === 128;

  const quantityLength = watch('quantity')?.length;
  const quantityValidation = quantityLength > 6;

  const onKeyDown = (event) => {
    if (event.key === 'Enter' && !Object.keys(errors).length) submitItem();

    if (event.key === 'Escape') closeFields();
    event.stopPropagation();
  };

  const handleBlur = (event) => {
    event.stopPropagation();
  };

  const noDuplicateItemName = (value) => {
    const itemNamesDuplicated = workRequestItems?.some(
      (item) =>
        item?.workRequestItemName.trim().toLowerCase() === value && item?.workRequestItemId !== row?.workRequestItemId,
    );

    return !itemNamesDuplicated || 'Item already exists';
  };

  const UOMIsEditing = uomOptions?.filter((option) => option.value === row.uom)[0];

  // Disable inputs to Work Request
  const disableQuantityToWR = Object.hasOwn(row, 'workRequestItemTypeId') && isReadOnly;

  // Disable inputs to Work Orders
  const disableQuantityToWO = false;
  const disableUOMToWO = inWorkOrders;

  if (field === 'workRequestItemName')
    return (
      <>
        {!row.isEditing && (
          <IconButton onClick={() => closeFields()} sx={{ p: 0, ml: '-35px', mr: '9px' }}>
            <Custom.Close />
          </IconButton>
        )}
        <WorkRequestControlInput
          control={control}
          name={field}
          placeholder="Item"
          rules={{
            ...requiredRule('Part name', '*Required'),
            validate: {
              noDuplicate: (value) => noDuplicateItemName(value.trim().toLowerCase()),
            },
          }}
          autoFocus
          maxLength={128}
          helperText={workRequestItemNameValidation ? '128/128' : null}
          error={!!errors?.workRequestItemName || workRequestItemNameValidation}
          defaultValue={row?.isEditing ? row.workRequestItemName : null}
          onKeyDown={onKeyDown}
          onBlur={handleBlur}
          errorAlign={workRequestItemNameValidation}
          inputProps={{
            maxLength: 128,
            'data-cy': 'work-request-item-name',
            'data-testid': 'work-request-item-name',
          }}
        />
      </>
    );

  if (field === 'quantity')
    return (
      <>
        <WorkRequestControlInput
          control={control}
          name={field}
          placeholder="Qty"
          rules={{
            ...requiredRule('Qty', '*Required'),
            ...customMaxLengthRule(6, '6/6'),
            ...positiveAndIntegerNumberRules('Qty'),
          }}
          defaultValue={row?.isEditing ? row.quantity : null}
          onKeyDown={onKeyDown}
          type="number"
          errorAlign={quantityValidation}
          inputProps={{
            'data-cy': 'work-request-item-quantity',
          }}
          disabled={inWorkOrders ? disableQuantityToWO : disableQuantityToWR}
        />
      </>
    );

  if (field === 'unitOfMeasureId') {
    return (
      <UOMAutocomplete
        disableClearable
        control={control}
        options={uomOptions}
        onCreate={openModalUom}
        name={field}
        onKeyDown={onKeyDown}
        defaultValue={row?.isEditing ? UOMIsEditing : uomDefaultValue}
        filterMethod="startsWith"
        sxRenderInput={{
          height: 'auto',
          width: '88px',
        }}
        disabled={inWorkOrders ? disableUOMToWO : disableQuantityToWR}
      />
    );
  }

  return null;
};

export default WorkRequestTableFields;
