import React from 'react';

import { Stack } from '@mui/material';

import CategoryItemContent from './CategoryItemContent';
import CategoryItemIcon from './CategoryItemIcon';
import CategoryItemMoreMenu from './CategoryItemMoreMenu';
import CategoryItemText from './CategoryItemText';

const CategoryItem = ({ children, sx, selected, onClick, ...restProps }) => (
  <Stack
    direction="row"
    onClick={onClick}
    sx={{
      backgroundColor: selected ? 'rgba(0, 0, 0, 0.06)' : 'rgba(255, 255, 255, 1)',
      boxShadow: 'inset 0px -1px 0px #CCCCCC;',
      padding: '18px 16px',
      alignItems: 'center',
      cursor: 'default',
      ...sx,
    }}
    {...restProps}
  >
    {children}
  </Stack>
);

CategoryItem.Icon = CategoryItemIcon;
CategoryItem.Text = CategoryItemText;
CategoryItem.Content = CategoryItemContent;
CategoryItem.Menu = CategoryItemMoreMenu;

export default CategoryItem;
