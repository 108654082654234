import { clone } from 'helpers/arrayFunctions';

export const sortTasksByEnabled = (workCellTasks) =>
  clone(workCellTasks || [])?.sort((a, b) => {
    if (a.isEnabled === b.isEnabled) return 0;
    if (a.isEnabled) return -1;
    return 1;
  }) || [];

const getWorkCellName = (workCells, workCellTask) => {
  const workCellName = workCells?.filter((workCell) => workCell.workCellId === workCellTask.workCellId)[0]
    ?.workCellName;

  return workCellName;
};

export const mapWorkCellTasksDataToTableColumns = (workCellTasks, workCells) =>
  workCellTasks?.map((workCellTask) => ({
    ...workCellTask,
    workCellName: getWorkCellName(workCells, workCellTask),
  }));
