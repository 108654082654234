import { gql, useMutation } from '@apollo/client';

import { addWorkRequest as ADD_WORK_REQUEST } from 'graphql/mutations';

const useAddWorkRequestMutation = () => {
  const [addWorkRequest, { loading, data: addWorkRequestData }] = useMutation(
    gql(ADD_WORK_REQUEST),
    {
      refetchQueries: ['WorkRequests'],
    },
  );

  return { loading, addWorkRequest, addWorkRequestData };
};

export default useAddWorkRequestMutation;
