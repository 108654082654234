import React from 'react';

import { Box, Button, MenuItem, Typography } from '@mui/material';

import { PartThumbnailOutlined } from 'assets/images';
import { getArrayOfParts } from 'modules/Materials/components/CatalogSearchResultPartItem/helpers/highlightWordInString';

const highlightKeywordInString = (string = '', keyword = '') => {
  if (!keyword)
    return (
      <Typography
        variant="body2"
        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {string}
      </Typography>
    );
  return (
    <Typography
      variant="body2"
      sx={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {getArrayOfParts(string, keyword).map((part) => (
        <span key={part.id} style={{ ...(part.isHighlight && { fontWeight: 700 }) }}>
          {part.text}
        </span>
      ))}
    </Typography>
  );
};

const PartOption = ({ option = {}, searchKey = '', onSelect = () => {}, onCreate = () => {} }) => {
  if (option.key === 'add_new_item_option') {
    return (
      <Button
        fullWidth
        variant="text"
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          color: 'secondary.main',
          textTransform: 'none',
        }}
        onClick={onCreate}
      >
        {option.label}
      </Button>
    );
  }

  if (option.key === 'no_item_option') {
    return (
      <MenuItem>
        <Typography variant="body2">{option.label}</Typography>
      </MenuItem>
    );
  }

  return (
    <MenuItem role="button" sx={{ width: '100%' }} onClick={() => onSelect(option)}>
      <Box display="flex" alignItems="center" sx={{ marginRight: '0.875em' }}>
        <PartThumbnailOutlined />
      </Box>
      {highlightKeywordInString(option.partName, searchKey)}
    </MenuItem>
  );
};

export default PartOption;
