/* eslint-disable no-restricted-globals, react/no-unescaped-entities */
import React from 'react';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { DeviceSizes } from 'constants/globalConstants';
import useDeviceSize from 'hooks/useDeviceSize';

import MobileErrorBackground from '../assets/images/backgrounds/ErrorPage-bg-mobile.png';
import TabletErrorBackground from '../assets/images/backgrounds/ErrorPage-bg-tablet.png';
import DesktopErrorBackground from '../assets/images/backgrounds/ErrorPage-bg.png';

const ErrorPage = () => {
  const { deviceSize } = useDeviceSize();
  const refreshPage = () => location.reload();
  // eslint-disable-next-line no-console
  const contactSupport = () => console.log('contacting support');

  return (
    <ErrorPageWrapper deviceSize={deviceSize}>
      <ErrorPageBackground deviceSize={deviceSize} />
      <ErrorPageContent deviceSize={deviceSize}>
        <ErrorTitle deviceSize={deviceSize} />
        <ErrorPageText deviceSize={deviceSize} onClick={contactSupport} />
        <RefreshButton onClick={refreshPage} />
      </ErrorPageContent>
    </ErrorPageWrapper>
  );
};

export default ErrorPage;

const ErrorPageWrapper = ({ deviceSize, children }) => (
  <Stack
    sx={{
      height: '100vh',
      width: 1,
      position:
        deviceSize === DeviceSizes.tablet || deviceSize === DeviceSizes.mobile
          ? 'fixed'
          : 'inherit',
      left: 0,
    }}
  >
    {children}
  </Stack>
);

const ErrorPageContent = ({ deviceSize, children }) => {
  const contentStyles = {
    position: 'absolute',
  };

  if (deviceSize === DeviceSizes.desktop) {
    contentStyles.top = '20%';
    contentStyles.right = '18%';
  }

  return (
    <Stack alignItems="center" width={1} height={0.6} justifyContent="center" sx={contentStyles}>
      {children}
    </Stack>
  );
};

const ErrorTitle = ({ deviceSize }) => {
  const getFontSize = () => {
    if (deviceSize === DeviceSizes.desktop) return '100px';
    if (deviceSize === DeviceSizes.tablet) return '80px';
    return '150px';
  };

  return (
    <Typography variant="h1" sx={{ fontSize: getFontSize(), fontWeight: 700, color: '#10143D' }}>
      Uh-oh
    </Typography>
  );
};

const ErrorPageText = ({ deviceSize, onClick }) => (
  <>
    <Typography
      variant="body2"
      align="center"
      sx={{ width: deviceSize === DeviceSizes.desktop ? '590px' : 3 / 5 }}
    >
      Information on this error has been captured and sent to Evolve so that it can be fixed.
    </Typography>
    <Typography
      variant="body2"
      align="center"
      sx={{ width: deviceSize === DeviceSizes.desktop ? '590px' : 3 / 5 }}
    >
      Refreshing the page typically resolves the issue. If the issue persists, please{' '}
      <Typography
        variant="body2"
        onClick={onClick}
        sx={{ color: '#589CE4', display: 'inline', cursor: 'pointer' }}
      >
        contact support.
      </Typography>
    </Typography>
  </>
);

const RefreshButton = ({ onClick }) => (
  <Button
    variant="contained"
    onClick={onClick}
    sx={{ width: '250px', height: '40px', backgroundColor: '#10143D', mt: '55px' }}
  >
    REFRESH PAGE
  </Button>
);

const ErrorPageBackground = ({ deviceSize }) => {
  if (deviceSize === DeviceSizes.desktop)
    return <img src={DesktopErrorBackground} alt="Error page" height="100%" />;

  if (deviceSize === DeviceSizes.tablet)
    return <img src={TabletErrorBackground} alt="Error page" height="100%" width="100%" />;

  if (deviceSize === DeviceSizes.mobile)
    return <img src={MobileErrorBackground} alt="Error page" height="100%" />;

  return <p>Loading...</p>;
};
