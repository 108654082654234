import React from 'react';

import { ForesiteTextField } from '@evolvemep/foresite-components-ui-react';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { Controller, useForm } from 'react-hook-form';

import { ThemedCheckBox } from 'components';

const CreatePresetForm = () => {
  const { control } = useForm({
    mode: 'all',
  });

  return (
    <Stack flex="1" direction="row" alignItems="center">
      <ThemedCheckBox />
      <Typography component="p" noWrap sx={{ marginRight: '16px' }}>
        Save as filter preset
      </Typography>

      <Controller
        name="Description"
        control={control}
        render={({ fieldState: { error } }) => (
          <ForesiteTextField
            size="small"
            error={Boolean(error)}
            name="presetName"
            id="presetName"
            label="Filter preset name"
          />
        )}
      />
    </Stack>
  );
};

export default CreatePresetForm;
