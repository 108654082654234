import React, { useCallback, useState } from 'react';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Custom } from 'components/Icons';

import { usePartAPIContext } from '../../Providers/PartAPIProvider';
import { DeleteItemsConfirmDialog } from '../DeleteItemsConfirmDialog';
import { useItemsContext } from '../ItemsContext';

const ItemsListToolbar = () => {
  const { partsCount, loadingPartsCount } = usePartAPIContext();
  const { isDeleteItems, onStartDeleteItems, onCancelDeleteItems, selectedItems } =
    useItemsContext();

  const [isOpenDeleteConfirmDialog, setIsOpenDeleteConfirmDialg] = useState(false);

  const handleDeleteItems = () => {
    setIsOpenDeleteConfirmDialg(true);
  };

  const handleCloseDeleteConfirmDialog = useCallback(() => {
    setIsOpenDeleteConfirmDialg(false);
  }, []);

  return (
    <>
      <Stack display="flex" flexDirection="row" alignItems="center" sx={{ mb: 1 }}>
        {loadingPartsCount ? (
          <CircularProgress size={16} />
        ) : (
          <Typography
            variant="body2"
            color="surface.lightSurface.secondary"
            data-testid="part-items-count-txt"
          >
            {partsCount} items
          </Typography>
        )}
        {Boolean(partsCount) && !isDeleteItems && (
          <Button
            size="small"
            color="secondary"
            sx={{ ml: 2 }}
            data-testid="edit-part-items-btn"
            onClick={onStartDeleteItems}
          >
            EDIT
          </Button>
        )}
        {isDeleteItems && (
          <>
            <Button
              size="small"
              color="secondary"
              sx={{ ml: 2 }}
              data-testid="cancel-edit-part-items-btn"
              onClick={onCancelDeleteItems}
            >
              CANCEL
            </Button>
            <IconButton
              sx={{ ml: 2 }}
              disabled={!selectedItems?.length}
              data-testid="delete-part-items-btn"
              onClick={handleDeleteItems}
            >
              {selectedItems?.length ? (
                <Custom.DeleteOutlineActive />
              ) : (
                <Custom.DeleteOutlineDisabled />
              )}
            </IconButton>
          </>
        )}
      </Stack>
      <DeleteItemsConfirmDialog
        open={isOpenDeleteConfirmDialog}
        onClose={handleCloseDeleteConfirmDialog}
      />
    </>
  );
};

export default ItemsListToolbar;
