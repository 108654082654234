import React from 'react';

import { Stack } from '@mui/material';

import {
  Floor,
  Folder,
  LocationSystem,
  LocationWorkPhase,
  SandWatch,
  SandWatchGreen,
  Target,
  Zone,
} from 'assets/images';

const PickIcon = ({ type, chipWidth, margin = '0 10px 0 8px', iconSx = {} }) => {
  let width;
  if (chipWidth) width = `${chipWidth}px`;

  const ICONS = {
    Project: <Folder width={width} />,
    Floor: <Floor width={width} />,
    Zone: <Zone width={width} />,
    'Location System': <LocationSystem width={width} />,
    'Work Phase-G': <SandWatchGreen width={width} />,
    'location with work phase': <LocationWorkPhase width={width} />,
    'Work Phase': <SandWatch width={width} />,
    'Scope Package': <Target width={width} />,
  };
  return (
    <Stack margin={margin} sx={iconSx}>
      {ICONS[type]}
    </Stack>
  );
};

export default PickIcon;
