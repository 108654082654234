import React from 'react';

import { Search } from 'components';

import { useBOMbyIdContext } from './BOMbyIdContext';

const BOMSearch = () => {
  const { findByKeyword } = useBOMbyIdContext();
  if (!(findByKeyword instanceof Function)) return <></>;
  return <Search handleSearch={findByKeyword} placeholder="Search BOM..." sx={{ width: '488px' }} />;
};

export default BOMSearch;
