import React from 'react';

import { Custom } from 'components/Icons';
import { actionColors } from 'styles/theme/colors';

type ConfigColumns = { [key: string]: { icon: any; chipType: string | null } };

export const GROUPED_VALUES = 'grouped_values';
export const SEPARATED_VALUES = 'separated_values';

export const CONFIG_COLUMNS: ConfigColumns = {
  default: {
    icon: <Custom.FilterList width={16} />,
    chipType: null,
  },
  date: {
    icon: <Custom.CalendarTodayOutlined width={16} />,
    chipType: GROUPED_VALUES,
  },
  projectIdentifier: {
    icon: <Custom.Projects width={15} />,
    chipType: SEPARATED_VALUES,
  },
  projectName: {
    icon: <Custom.Projects width={16} />,
    chipType: SEPARATED_VALUES,
  },
  workOrderStatusTypeId: {
    icon: <Custom.StatusSmall width={16} />,
    chipType: SEPARATED_VALUES,
  },
  workOrderName: {
    icon: <Custom.WorkOrders width={15} fill={actionColors.disabled} />,
    chipType: SEPARATED_VALUES,
  },
  workOrderId: {
    icon: <Custom.WorkOrders width={15} fill={actionColors.disabled} />,
    chipType: SEPARATED_VALUES,
  },
  workRequestIdentifier: {
    icon: <Custom.WorkRequests width={15} fill={actionColors.disabled} />,
    chipType: SEPARATED_VALUES,
  },
  workRequestName: {
    icon: <Custom.WorkRequests width={15} fill={actionColors.disabled} />,
    chipType: SEPARATED_VALUES,
  },
  workRequestStatusId: {
    icon: <Custom.StatusSmall width={16} />,
    chipType: SEPARATED_VALUES,
  },
  facilityName: {
    icon: <Custom.Projects width={16} />,
    chipType: SEPARATED_VALUES,
  },
};
