import React from 'react';

import { InputForesiteField } from '@evolvemep/foresite-components-ui-react';

import { requiredRule } from 'constants/inputFieldRules';

const TextField = ({ label, sx, rules, ...rest }) => (
  <InputForesiteField
    label={label}
    size="small"
    color="secondary"
    rules={{ ...requiredRule(label), ...rules }}
    sx={{ mt: '0', marginBottom: 0, ...sx }}
    autoComplete="off"
    {...rest}
  />
);

export default TextField;
