/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';

import { gql, useMutation, useLazyQuery } from '@apollo/client';

import { useUser } from 'app/UserContext';
import {
  getDocumentPresignedURL as GET_DOCUMENT_PRESIGNED_URL,
  addFolder as ADD_FOLDER,
  addDocument as ADD_DOCUMENT,
  updateTaskType as UPDATE_TASK_TYPE,
} from 'graphql/mutations';
import { folders as FOLDERS, taskTypes as TASK_TYPES, documentById as DOCUMENT_BY_ID } from 'graphql/queries';
import useLazyPaginatedQuery from 'hooks/useLazyPaginatedQuery';

const ShopTasksContext = React.createContext();

const ShopTasksProvider = ({ children }) => {
  const { user } = useUser();
  const [selectedTaskType, setSelectedTaskType] = useState(null);
  const [showEditTaskTypeModal, setShowEditTaskTypeModal] = useState(false);
  const [showNewTaskTypeModal, setShowNewTaskTypeModal] = useState(false);
  const [showDeleteTaskTypeModal, setShowDeleteTaskTypeModal] = useState(false);

  const [
    {
      lazyLoad: lazyLoadTaskTypes,
      paginationHandler: taskTypesPaginationHandler,
      refetchExistingPages: refetchTaskTypes,
    },
    { loading: loadingTaskTypes, data: taskTypesResponse },
  ] = useLazyPaginatedQuery(gql(TASK_TYPES), 'cache-and-network', 50);
  const [getPresignedURL] = useMutation(gql(GET_DOCUMENT_PRESIGNED_URL));
  const [fetchFolders] = useLazyQuery(gql(FOLDERS));
  const [addFolder] = useMutation(gql(ADD_FOLDER));
  const [addDocument] = useMutation(gql(ADD_DOCUMENT));
  const [fetchDocument] = useLazyQuery(gql(DOCUMENT_BY_ID));

  useEffect(
    () =>
      lazyLoadTaskTypes({
        taskTypeClassId: '9e1a7bc6-5b0a-4aa7-bcb5-b354e3bc2cdb',
        companyId: user?.companyId,
        orderBy: 'tasktypename',
      }),
    [lazyLoadTaskTypes, user?.companyId],
  );

  const uploadTaskTypeImage = async (taskTypeId, imgFile) => {
    try {
      const storagePath = `all/company/${user.companyId}/taskType/${taskTypeId}/images`;
      const fileName = imgFile.name;

      const presignedURLResponse = await getPresignedURL({
        variables: {
          body: {
            expirationHours: 24,
            objectKey: `${storagePath}/${fileName}`,
            requestedBy: user.userId,
            verb: 'PUT',
            ContentType: imgFile.type,
          },
        },
      });

      const presignedURL = presignedURLResponse.data?.getDocumentPresignedURL?.preSignedURL;
      await fetch(presignedURL, {
        method: 'PUT',
        mode: 'cors',
        body: imgFile,
      });

      const {
        data: { folders },
      } = await fetchFolders({
        variables: {
          query: {
            searchPhrase: taskTypeId,
            companyId: user.companyId,
          },
        },
      });
      let folderId;
      if (folders?.length > 0) {
        folderId = folders[0].folderId;
      } else {
        const newFolder = await addFolder({
          variables: {
            body: {
              companyId: user.companyId,
              folderName: taskTypeId,
              storagePath,
              requestedBy: user.userId,
            },
          },
        });
        folderId = newFolder.data?.addFolder?.folderId;
      }

      const newDocument = await addDocument({
        variables: {
          body: {
            folderId,
            documentName: fileName,
            storagePath,
            requestedBy: user.userId,
          },
        },
      });
      return newDocument.data?.addDocument?.documentId;
    } catch (error) {
      return null;
    }
  };

  const getTaskTypeImage = async (taskTypeImageId) => {
    const document = await fetchDocument({
      variables: {
        params: {
          documentId: taskTypeImageId,
        },
      },
    });
    if (!document.data) return null;
    const filePath = `${document.data.documentById.storagePath}/${document.data.documentById.documentName}`;

    const presignedURLResponse = await getPresignedURL({
      variables: {
        body: {
          expirationHours: 24,
          objectKey: filePath,
          requestedBy: user.userId,
          verb: 'GET',
        },
      },
    });

    return presignedURLResponse.data?.getDocumentPresignedURL?.preSignedURL;
  };

  const [updateTaskType] = useMutation(gql(UPDATE_TASK_TYPE), {
    update(cache, { data: taskTypeData }) {
      const data = cache.readQuery({
        query: gql(TASK_TYPES),
        variables: { query: { take: 10 } },
      });

      if (data?.taskTypes && taskTypeData?.updateTaskType) {
        const updatedData = [...data.taskTypes].map((taskType) => {
          const itemCopy = { ...taskType };
          if (itemCopy?.taskTypeId === taskTypeData?.updateTaskType?.taskTypeId) {
            itemCopy.taskTypeName = taskTypeData?.updateTaskType.taskTypeName;
            itemCopy.taskTypeDescription = taskTypeData?.updateTaskType.taskTypeDescription;
          }
          return itemCopy;
        });
        cache.writeQuery({
          query: gql(TASK_TYPES),
          variables: { query: { companyId: user?.companyId } },
          data: { taskTypes: updatedData },
        });
      }
    },
  });

  const { taskTypes } = taskTypesResponse || {};

  return (
    <ShopTasksContext.Provider
      value={{
        lazyLoadTaskTypes,
        refetchTaskTypes,
        taskTypes,
        loadingTaskTypes,
        taskTypesPaginationHandler,
        selectedTaskType,
        setSelectedTaskType,
        showEditTaskTypeModal,
        setShowEditTaskTypeModal,
        showNewTaskTypeModal,
        setShowNewTaskTypeModal,
        showDeleteTaskTypeModal,
        setShowDeleteTaskTypeModal,
        uploadTaskTypeImage,
        getTaskTypeImage,
        updateTaskType,
      }}
    >
      {children}
    </ShopTasksContext.Provider>
  );
};

const useShopTasksContext = () => {
  const context = React.useContext(ShopTasksContext);
  if (context === undefined) {
    throw new Error('useShopTasksContext must be used within a ShopTasksContext');
  }
  return context;
};

export { ShopTasksContext, ShopTasksProvider, useShopTasksContext };
