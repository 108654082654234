import React, { useEffect } from 'react';

import { CircularProgress, Stack } from '@mui/material';

import { useWorkOrderCancelModalContext } from './context/WorkOrderCancelModalContext';
import { useWorkOrderItemsContext } from './context/WorkOrderItemsContext';
import WorkOrderInProgressInformation from './WorkOrderInProgressInformation';
import WorkOrderTasksTable from './WorkOrderTasksTable/WorkOrderTasksTable';

const WorkOrderInProgressContent = () => {
  const { inProgressTasks, refetchTaskData, loadingTasksData } = useWorkOrderItemsContext();

  useEffect(() => {
    refetchTaskData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { setTasksToBeSavedOnCancel, setIsTableFilled, showCancelTable } = useWorkOrderCancelModalContext();

  if (!showCancelTable) return null;

  if (loadingTasksData)
    return (
      <Stack direction="row" justifyContent="center" alignItems="center" mt="8px">
        <CircularProgress size={22} />
      </Stack>
    );

  return (
    <>
      <WorkOrderTasksTable
        tasksData={inProgressTasks}
        setIsTableFilled={setIsTableFilled}
        setSaveTaskData={setTasksToBeSavedOnCancel}
      />
      <WorkOrderInProgressInformation />
    </>
  );
};

export default WorkOrderInProgressContent;
