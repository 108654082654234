import React, { useContext, useState } from 'react';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { DrawingRegisterContext } from 'modules/Design/DrawingRegister';
import WorkRequestModalCreate from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestModalCreate/WorkRequestModalCreate';
import ProjectIdentifierModal from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsModalIdentifier/ProjectIdentifierModal';

import { useWorkRequestsContext } from '../../WorkRequestsContext';
import NewRequestBtnCellResponsive from './NewRequestBtnCellResponsive';
import NewWorkRequestButton from './NewWorkRequestButton';

const NewRequestBtn = ({ isResponsive }) => {
  const drawingRegisterContext = useContext(DrawingRegisterContext);
  const { selectedItem } = useFacilitiesProjects();
  const { disabledNew, activeTabIndex } = useWorkRequestsContext();

  const [projectIdentifierModalOpen, setProjectIdentifierModalOpen] = useState(false);
  const [workRequestModalCreateOpen, setWorkRequestModalCreateOpen] = useState(false);
  const realSelectedItem = drawingRegisterContext?.activeItem ?? selectedItem;

  const checkProjectIdentifier = () => {
    if (realSelectedItem?.identifier) setWorkRequestModalCreateOpen(true);
    else setProjectIdentifierModalOpen(true);
  };

  const closeWorkRequestModalCreate = () => setWorkRequestModalCreateOpen(false);

  if (activeTabIndex) return null;

  return (
    <>
      {isResponsive ? (
        <NewRequestBtnCellResponsive checkProjectIdentifier={checkProjectIdentifier} disabled={disabledNew} />
      ) : (
        <NewWorkRequestButton checkProjectIdentifier={checkProjectIdentifier} disabled={disabledNew} />
      )}
      <WorkRequestModalCreate
        open={workRequestModalCreateOpen}
        onClose={closeWorkRequestModalCreate}
        selectedItem={realSelectedItem}
      />
      <ProjectIdentifierModal
        open={projectIdentifierModalOpen}
        onClose={() => setProjectIdentifierModalOpen(false)}
        openWorkRequestModal={() => setWorkRequestModalCreateOpen(true)}
      />
    </>
  );
};

export default NewRequestBtn;
