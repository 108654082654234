import React from 'react';

import { Link, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';

type BreadcrumbLinkProps = {
  url: string;
  label: string;
  darkMode: boolean;
  isMenuItem?: boolean;
  onClick?: () => void;
};

const BreadcrumbLink = ({
  url,
  label,
  darkMode,
  isMenuItem = false,
  onClick,
}: BreadcrumbLinkProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      navigate(url);
    }
  };

  if (isMenuItem) {
    return <MenuItem onClick={handleClick}>{label}</MenuItem>;
  }
  return (
    <Link
      onClick={handleClick}
      sx={{
        color: darkMode ? 'surface.darkSurface.secondary' : 'surface.lightSurface.secondary',
        cursor: 'pointer',
        textDecorationColor: 'transparent',
      }}
    >
      {label}
    </Link>
  );
};

export default BreadcrumbLink;
