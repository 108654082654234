import React, { useState, useCallback, createContext, useContext, useEffect, useMemo } from 'react';

import { useTakeOff } from '../../context/TakeOffContext';

const SendToBomContext = createContext();

const SendToBomProvider = ({ children }) => {
  const [messageIndex, setMessageIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemsToMove, setItemsToMove] = useState([]);
  const [selectAll, setSelectAll] = useState({ active: false, modifiedBy: 'default' });
  const [bom, setBom] = useState();
  const [mutationIdentifiers, setMutationIdentifiers] = useState({
    TakeoffPadId: '',
    BillOfMaterialsId: '',
    SendSpecificQuanties: true,
  });
  // These callbacks were created to avoid re-renders when a single state changes
  const updateMutationIdentifiers = useCallback((element) => setMutationIdentifiers(element), []);
  const updateMessageIndex = useCallback((element) => setMessageIndex(element), []);
  const updateItemsToMove = useCallback((element) => setItemsToMove(element), []);
  const updateIsModalOpen = useCallback((element) => setIsModalOpen(element), []);
  const updateSelectAll = useCallback((element) => setSelectAll(element), []);
  const updateBom = useCallback((element) => setBom(element), []);

  const { padSelected, selectedPadItems } = useTakeOff();

  const onCloseModal = useCallback(() => {
    const itemList = selectedPadItems.map((e) => ({
      LineItemId: e.lineItemId,
      Quantity: undefined,
      name: e.lineItemName,
      remaining: e.remainingQuantity,
      errorCode: 0,
    }));
    updateIsModalOpen(false);
    updateSelectAll({ active: false, modifiedBy: 'default' });
    updateItemsToMove(itemList);
    // eslint-disable-next-line
  }, [JSON.stringify(selectedPadItems)]);

  const onOpenModal = useCallback(
    (selectedBom) => {
      updateBom({ ...selectedBom });
      updateIsModalOpen(true);
    },
    [updateBom, updateIsModalOpen],
  );

  useEffect(() => {
    if (padSelected && bom) {
      updateMutationIdentifiers((prev) => ({
        ...prev,
        TakeoffPadId: padSelected.takeoffPadId,
        BillOfMaterialsId: bom.billOfMaterialsId,
      }));
    }
  }, [bom, padSelected, updateMutationIdentifiers]);

  useEffect(() => {
    if (selectedPadItems) {
      const itemList = selectedPadItems.map((e) => ({
        LineItemId: e.lineItemId,
        Quantity: undefined,
        name: e.lineItemName,
        remaining: e.remainingQuantity,
        errorCode: 0,
      }));
      updateItemsToMove(itemList);
    }
  }, [selectedPadItems, updateItemsToMove]);

  const memoizedValue = useMemo(
    () => ({
      mutationIdentifiers,
      messageIndex,
      isModalOpen,
      itemsToMove,
      selectAll,
      bom,
      updateMutationIdentifiers,
      updateMessageIndex,
      updateItemsToMove,
      updateSelectAll,
      onCloseModal,
      onOpenModal,
    }),
    [
      mutationIdentifiers,
      messageIndex,
      isModalOpen,
      itemsToMove,
      selectAll,
      bom,
      updateMutationIdentifiers,
      updateMessageIndex,
      updateItemsToMove,
      updateSelectAll,
      onCloseModal,
      onOpenModal,
    ],
  );

  return <SendToBomContext.Provider value={memoizedValue}>{children}</SendToBomContext.Provider>;
};

const useSendToBomModal = () => {
  const context = useContext(SendToBomContext);
  if (context === undefined) throw new Error('useSendToBomModal must be within a SendToBomContext');
  return context;
};

export { useSendToBomModal, SendToBomProvider };
