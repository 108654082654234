import React from 'react';

import { customMaxLengthRule } from 'constants/inputFieldRules';

import TextField from './TextField';

const NamePrefixInput = ({ label = 'Location Prefix', ...props }) => (
  <TextField
    name="namePrefix"
    label={label}
    sx={{ width: '73%' }}
    placeholder="ex. Building"
    InputLabelProps={{ shrink: true }}
    rules={customMaxLengthRule(32, 'Location Prefix limited to 32 characters')}
    {...props}
  />
);

export default NamePrefixInput;
