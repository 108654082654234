import React from 'react';

import { Box, CircularProgress, Typography } from '@mui/material';

import DecisionAdded from './Decisions/DecisionAdded';
import { getNumberOfOpenDecisionString } from './Decisions/helpers';

const RowItemName = ({ value, row, isLoading = false }) => (
  <>
    <Typography
      variant="body2"
      color="primary"
      pl="8px"
      sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
    >
      {isLoading ? (
        <CircularProgress size="24px" color="secondary" />
      ) : (
        <>
          {value}
          <Typography component="span" sx={{ color: '#00000040' }}>
            {getNumberOfOpenDecisionString(row?.numberOfOpenDecisions)}
          </Typography>
        </>
      )}
    </Typography>
    <Box onClick={(event) => event.stopPropagation()}>
      <DecisionAdded row={row} />
    </Box>
  </>
);

export default RowItemName;
