import React, { useState } from 'react';

import { useMutation, gql } from '@apollo/client';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';

import MenuItemWithSpinner from 'components/TableComponents/MoreOptionsDropdown/MenuItemWithSpinner';
import { resendUserInvitation as RESEND_INVITE } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

import AssignEntitlementsModal from '../AssignEntitlementsModal/AssignEntitlementsModal';
import CompanyMembersDeleteModal from './CompanyMembersDeleteModal';
import { ResetPasswordOption } from './components/ResetPasswordOption';

const MoreTableRowOptions = ({ entitlementGetList, companyMember, memberStatus, onClose }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const email = companyMember?.userEmail;
  const memberIsPending = memberStatus === 'Pending';

  const { handleResponse } = useGraphqlResponseHandler(onClose);
  const [resendInvitation, { loading }] = useMutation(gql(RESEND_INVITE));

  const resendUserInvite = () => {
    handleResponse(resendInvitation, {
      variables: {
        params: { userId: companyMember.userId },
      },
    });
  };

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => {
    setModalIsOpen(false);
    entitlementGetList?.({ userId: companyMember.userId });
  };

  return (
    <Box>
      <MenuItem onClick={openModal}>Assign Licenses</MenuItem>
      <ResetPasswordOption emails={[email]} onPasswordReset={() => onClose()} />
      {memberIsPending && (
        <MenuItemWithSpinner loading={loading} onClick={() => resendUserInvite()}>
          Resend Invite
        </MenuItemWithSpinner>
      )}
      <MenuItem onClick={() => setShowDeleteModal(true)}>Delete Member</MenuItem>{' '}
      <CompanyMembersDeleteModal
        companyMemberIds={[companyMember.userId]}
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
      />
      <AssignEntitlementsModal selectedUsers={[companyMember]} open={modalIsOpen} onClose={closeModal} />
    </Box>
  );
};

export default MoreTableRowOptions;
