import React from 'react';

import { useQuery, gql } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation } from 'react-router-dom';

import { useUser } from 'app/UserContext';
import SimpleControlledAutoComplete from 'components/FormComponents/SimpleControlledAutoComplete';
import { INCLUDE_DELETED_OPTIONS } from 'constants/globalConstants';
import { users as USERS } from 'graphql/queries';

const AssignAdminControlledInput = ({
  control,
  disabled,
  defaultValue = '',
  name = 'adminUserEmail',
  label = 'Assign Admin',
}) => {
  const { user } = useUser();
  const { state } = useLocation();
  const { data, loading, error } = useQuery(gql(USERS), {
    variables: {
      query: {
        companyId: state?.companyId || user.companyId,
        includeDeleted: INCLUDE_DELETED_OPTIONS.NO,
      },
    },
    fetchPolicy: 'no-cache',
  });

  if (loading || !data) return <CircularProgress />;
  if (error) return <p>{error.message}</p>;

  const users = data?.users || [];

  const formattedOptions = users.map(({ userEmail, userId }) => ({ label: userEmail, id: userId }));

  return (
    <SimpleControlledAutoComplete
      limitOptions={6}
      options={formattedOptions}
      control={control}
      disabled={disabled}
      defaultValue={defaultValue}
      size="small"
      name={name}
      rules={{ required: true }}
      label={label}
      helperText="Invite by email if user has not already joined the company"
      data-testid="assign-admin-input"
    />
  );
};

export default AssignAdminControlledInput;
