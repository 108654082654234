import React, { ReactElement } from 'react';

import { DataGridPro } from '@mui/x-data-grid-pro';

import { Custom } from 'components/Icons';

import { ProjectTableProps } from './@types/projectsTable';
import NoRowsOverlay from './components/NoRowsOverlay';
import { mapProjects, tableStyles } from './helpers/constants';
import useFacilityProjectsQuery from './hooks/useFacilityProjectsQuery';
import useBOMListTableHelpers from './hooks/useProjectsTableHelpers';

export const ProjectsTable = ({
  icon = <Custom.AccessTime />,
  onProjectClick = () => {},
  headerNames = [],
}: ProjectTableProps) => {
  const { projects, loadingProjects } = useFacilityProjectsQuery();
  const { columns } = useBOMListTableHelpers(onProjectClick, headerNames);

  return (
    <DataGridPro
      rowHeight={52}
      headerHeight={52}
      getRowId={(row) => row.id}
      hideFooter
      checkboxSelection={false}
      disableSelectionOnClick
      disableColumnMenu
      columns={columns}
      rows={projects?.map(mapProjects) || []}
      loading={loadingProjects}
      components={{
        NoRowsOverlay: () => (
          <NoRowsOverlay
            message="No projects are assigned to this facility."
            icon={icon as ReactElement}
            sx={{ paddingBottom: '16%' }}
          />
        ),
      }}
      sx={{
        '.MuiDataGrid-row:hover': {
          background: 'white',
        },
        '.MuiDataGrid-row:first-of-type [role="cell"]': {
          borderTop: 'none',
        },
        '.MuiDataGrid-row:last-of-type, .MuiDataGrid-columnHeaders': {
          borderBottom: '1px solid rgba(224, 224, 224, 1)',
        },
        ...tableStyles,
      }}
    />
  );
};
