/* eslint-disable max-lines-per-function */
import React, { useState, useContext, useEffect, useCallback } from 'react';

import { gql, useMutation } from '@apollo/client';

import { addPartAttribute as ADD_ATTRIBUTE } from 'graphql/mutations';
import { partAttributes as ATTRIBUTES } from 'graphql/queries';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import useLazyPaginatedQuery from 'hooks/useLazyPaginatedQuery';

import { useCatalogSetupContext } from '../CatalogSetupHeader/CatalogSetupContext';
import { useCategoriesContext } from '../Categories/CategoriesContext';
import { useCatalogPartContext } from '../Providers/CatalogPartProvider';

export const AttributesContext = React.createContext();

export const AttributesProvider = ({ children }) => {
  const { selectedCategory, setSelectedCategory } = useCategoriesContext();
  const { attributes, setAttributes } = useCatalogPartContext();
  const { selectedCatalog } = useCatalogSetupContext();

  const [attributesModalOpen, setAttributesModalOpen] = useState(false);
  const [variantFormOpen, setVariantFormOpen] = useState(false);
  const [selectedAttribute, setSelectedAttribute] = useState(null);
  const [variantToAdd, setVariantToAdd] = useState(null);
  const { handleResponse } = useGraphqlResponseHandler();
  const handleAddNewVariant = () => {
    setVariantToAdd(selectedAttribute);
    setVariantFormOpen(true);
  };
  const [
    { lazyLoad, paginationHandler: getAttributesPaginationHandler },
    { data: getAttributes, loading: getAttributesLoading },
  ] = useLazyPaginatedQuery(gql(ATTRIBUTES), 'cache-and-network', 10, false);
  const [addAttributeMutation, { loading: addAttributeLoading }] = useMutation(gql(ADD_ATTRIBUTE));

  useEffect(() => {
    lazyLoad({
      partCategoryId: selectedCategory?.partCategoryId ?? '',
      orderBy: 'name:asc',
    });
  }, [selectedCategory, lazyLoad, selectedCatalog, setAttributes]);

  useEffect(() => {
    setAttributes([]);
    if (getAttributes?.partAttributes && !getAttributesLoading) {
      setAttributes(getAttributes.partAttributes);
    }
  }, [getAttributes, getAttributesLoading, setAttributes]);

  const resetAttributesState = useCallback(() => {
    setSelectedAttribute(null);
    setAttributes([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAttributes, setSelectedAttribute]);

  useEffect(() => {
    resetAttributesState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCatalog, resetAttributesState]);

  useEffect(() => {
    setSelectedCategory(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCatalog?.partCatalogId]);

  useEffect(() => {
    if (!selectedCategory) {
      setAttributes([]);
    }
    setVariantFormOpen(false);
  }, [selectedCatalog, selectedCategory, setAttributes]);

  useEffect(() => {
    setAttributes([]);
    setSelectedAttribute(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  const createAttributes = async ({ values = [], successCallback = () => {} }) => {
    const LIMIT = 10;
    if (selectedCategory?.partCatalog?.partCatalogId && selectedCategory?.partCategoryId) {
      const variants = values?.variantCodes?.map((item) => ({
        textValue: item.label,
        codeValue: item.value,
      }));

      const partAttributeName =
        typeof values?.AttributeNameField === 'object' && values?.AttributeNameField != null
          ? values?.AttributeNameField.label
          : values.AttributeNameField;

      await handleResponse(
        addAttributeMutation,
        {
          variables: {
            body: {
              partCatalogId: selectedCategory?.partCatalog?.partCatalogId,
              partCategoryId: selectedCategory?.partCategoryId,
              partAttributeTypeId: values?.attributeType,
              partAttributeName,
              allowWriteInVariant: values?.allowWriteIn,
              addPartAttributeSelectVariantRequests: variants,
            },
          },

          update(cache, { data: attributeData }) {
            const data = cache.readQuery({
              query: gql(ATTRIBUTES),
              variables: {
                query: {
                  partCategoryId: selectedCategory?.partCategoryId,
                  take: LIMIT,
                },
              },
            });
            if (data?.partAttributes && attributeData?.addPartAttribute) {
              const updatedData = {
                partAttributes: [...data.partAttributes, attributeData.addPartAttribute],
              };
              cache.writeQuery({
                query: gql(ATTRIBUTES),
                data: updatedData,
                variables: {
                  query: {
                    partCategoryId: selectedCategory?.partCategoryId,
                    take: LIMIT,
                  },
                },
              });
            }
            if (attributeData?.addPartAttribute) {
              setSelectedAttribute(attributeData.addPartAttribute);
              successCallback();
            }
          },
        },
        {
          successMessage: 'Attribute successfully added',
        },
      );
    }
  };

  useEffect(() => {
    if (!selectedCategory) return;
    if (Array.isArray(attributes) && attributes.length > 0 && !selectedAttribute) {
      setSelectedAttribute((prev) => {
        if (!prev) return attributes?.[0];
        return prev;
      });
    }
  }, [attributes, selectedAttribute, selectedCategory]);

  return (
    <AttributesContext.Provider
      value={{
        attributesModalOpen,
        setAttributesModalOpen,
        attributes,
        getAttributesPaginationHandler,
        selectedAttribute,
        setSelectedAttribute,
        handleAddNewVariant,
        variantToAdd,
        setVariantToAdd,
        createAttributes,
        addAttributeLoading,
        getAttributesLoading,
        resetAttributesState,
        variantFormOpen,
        setVariantFormOpen,
      }}
    >
      {children}
    </AttributesContext.Provider>
  );
};

export const useAttributeContext = () => {
  const context = useContext(AttributesContext);
  if (!context) {
    throw new Error('useAttributeContext must be used within a AttributesProvider');
  }
  return context;
};
