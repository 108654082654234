import { useCallback } from 'react';

export const useConfigurationNameInputRules = (multiSearch, setError, clearErrors, validateTypingText) => {
  const VALIDATE_NAME = 'Name already exists';

  const configurationNameInputRules = {
    maxLength: {
      value: 32,
      message: 'Name limited to 32 characters',
    },
    validate: () => {
      if (multiSearch?.shopConfigurationsMultiSearch?.length) return VALIDATE_NAME;
      return true;
    },
  };

  const shopConfigurationsMultiSearch = useCallback(
    (watchShopConfigurationName) => {
      validateTypingText(watchShopConfigurationName);
      if (multiSearch?.shopConfigurationsMultiSearch?.length)
        setError('ShopConfigurationName', {
          type: 'custom',
          message: VALIDATE_NAME,
        });
      else clearErrors();
    },
    [clearErrors, multiSearch, setError, validateTypingText],
  );

  return { configurationNameInputRules, shopConfigurationsMultiSearch };
};
