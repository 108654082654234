import React, { ReactNode } from 'react';

import { Link, SxProps, Theme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

import StatusBadge from 'components/StatusBadges/StatusBadge';
import { BADGE_MAPPING_STATUS, StatusKey } from 'constants/badgeMappingStatus';

import { WORK_REQUEST_ITEMS_VIEWS } from '../WorkOrders/WorkOrder/constants/constants';
import { useWorkOrderItemsContext } from '../WorkOrders/WorkOrder/context/WorkOrderItemsContext';

type Props = {
  workItemTitle: string;
  title: string;
  status: StatusKey | undefined;
  isLate: boolean;
}

const WorkItemTitle = ({ workItemTitle, title, status, isLate }: Props) => {
  const navigate = useNavigate();
  const { inForgeView, setCurrentView } = useWorkOrderItemsContext();
  const newStatus = status ? BADGE_MAPPING_STATUS[status] : undefined;

  return (
    <Stack direction="row" alignItems="center">
      <LinkBreadcrumb onClick={() => navigate(-1)}>
        {workItemTitle}
      </LinkBreadcrumb>
      <Typography variant="subtitle1" sx={{ color: 'surface.lightSurface.secondary', margin: '0 7px' }}>
        /
      </Typography>
      <LinkBreadcrumb
        sx={{
          color: inForgeView ? 'surface.lightSurface.secondary' : 'surface.lightSurface.primary',
        }}
        onClick={() => setCurrentView(WORK_REQUEST_ITEMS_VIEWS.TABLE_VIEW)}
      >
        {title}
      </LinkBreadcrumb>
      {inForgeView && <ForgeViewBreadcrumb />}

      <StatusBadge
        variant={newStatus?.variant || status?.toLowerCase()}
        label={newStatus?.label}
        sx={{ height: '20px', ml: 2 }}
        isLate={isLate}
      />
    </Stack>
  );
};

const ForgeViewBreadcrumb = () => (
  <>
    <Typography variant="subtitle1" sx={{ color: 'surface.lightSurface.secondary', margin: '0 7px' }}>
      /
    </Typography>
    <Typography variant="subtitle1">Viewer</Typography>
  </>
);

const LinkBreadcrumb = ({ children, onClick, sx }: {
  children: ReactNode;
  onClick: () => void;
  sx?: SxProps<Theme> | undefined;
}) => (
  <Link
    onClick={onClick}
    variant="subtitle1"
    sx={{
      color: 'surface.lightSurface.secondary',
      ...sx,
    }}
    underline="none"
    component="button"
  >
    {children}
  </Link>
);

export default WorkItemTitle;
