import React from 'react';

import { Stack, Typography } from '@mui/material';

export const CustomAssemblyAssignee = ({ params }) =>
  params.row.type === 'Custom Assembly' && (
    <Stack
      direction="row"
      alignItems="center"
      style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
    >
      <Typography
        variant="body2"
        sx={{
          flex: 1,
          letterSpacing: '0.1px',
          marginLeft: 1,
          userSelect: 'none',
        }}
      >
        {params.row.children.createdByEmail}
      </Typography>
    </Stack>
  );
