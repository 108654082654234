import { v4 as uuidv4 } from 'uuid';

export const DrawingDocumentType = {
  FOLDER: 'folder',
  DOCUMENT: 'document',
  NEW_FOLDER_FORM: 'new_folder',
};

export const loadingNewFiles = (file) => {
  const [name, extension] = file.name.split(/\.(?=[^\\.]+$)/);
  return {
    __typename: 'Document',
    documentId: uuidv4(),
    documentName: name,
    documentIdentifier: '',
    documentTypeId: 'b5900a89-bf20-440c-b010-0cb982e57f78',
    storagePath: '',
    extension,
    encodedUrn: null,
    documentStatusTypeId: '72a1547b-a553-6893-8afe-t52f104i5g96',
    folderDocumentResponse: [
      {
        __typename: 'FolderDocument',
        folderId: '73af3c26-86b2-4fe0-b423-149871b5eab2',
        documentId: uuidv4(),
        folderDocumentId: '08db1436-cf3b-4a40-8e9e-cafb3d1a2164',
      },
    ],
  };
};
// TODO: add to this function the parents path
export const getStoragePathFolder = (path = '/', companyId, projectId) =>
  `all/company/${companyId}/project/${projectId}/drawingregister${path}`;

export const getPathFolder = (hierarchy = [], companyId, projectId) => {
  const path = hierarchy.join('/');
  return `all/company/${companyId}/project/${projectId}/drawingregister/${path}`;
};

export const NEW_FOLDER = {
  folderId: 0,
  hasSubFolder: false,
  __typename: 'NewFolder',
};

export const DROPPABLE_FOLDER_ID = 'drawing-folder';

export const DocumentListStyles = {
  '& .MuiDataGrid-detailPanel': {
    backgroundColor: '#FFFFFF',
    height: 'auto',
  },
  '& .MuiDataGrid-cell': {
    borderTop: 'none !important',
  },
  '& .MuiDataGrid-root': {
    border: 'none !important',
  },
  ' .MuiDataGrid-row, .MuiDataGrid-virtualScrollerRenderZone': {
    width: '100% !important',
    overflow: 'hidden',
  },
  '.MuiDataGrid-cell[data-field="__detail_panel_toggle__"]': {
    padding: '0 !important',
  },
};
