import React from 'react';

import { Stack, Typography } from '@mui/material';

import { getNavIcon } from 'helpers/MenuIcons';

import { PREFIX_ICONS, ROW_TYPES } from '../constants/constants';

export const RowPrefix = ({ children }) =>
  children ? (
    <Typography
      variant="body2"
      sx={{
        minWidth: 100,
        letterSpacing: '0.15px',
        userSelect: 'none',
      }}
    >
      {children}
    </Typography>
  ) : (
    <></>
  );

const buildColor = (type, green = false) => {
  let stroke = '';
  let fill = '';
  if (!green) return { stroke, fill };

  stroke = '#37A54F';
  fill = '#37A54F';

  if (type === ROW_TYPES.FLOOR) fill = '';
  if (type === ROW_TYPES.WORK_PHASE) stroke = '';

  return { stroke, fill };
};

export const RowIcon = ({ type, green }) => {
  const { stroke, fill } = buildColor(type, green);
  return (
    <Stack
      sx={{
        marginRight: 1,
        width: 18,
        path: {
          fill,
          stroke,
        },
        rect: {
          stroke,
        },
      }}
    >
      {getNavIcon(PREFIX_ICONS[type])}
    </Stack>
  );
};
