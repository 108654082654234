import React from 'react';

import { Content } from './Content';
import Menu from './Menu';

const BreakDown = ({ children }) => <>{children}</>;

BreakDown.Menu = Menu;
BreakDown.Content = Content;

export default BreakDown;
