import React, { useMemo, useState } from 'react';

import Stack from '@mui/material/Stack';

import { MoreMenu } from 'components';

import { WRITE_IN_ASSEMBLY, SPOOL_UOM } from './constants';

const addItemToCatalogMenuOption = (openSendItemToPCModal) => ({
  label: 'Add item to catalog',
  clickEvent: openSendItemToPCModal,
});

const renameMenuOption = (onRenameClick) => ({
  label: 'Rename',
  clickEvent: onRenameClick,
});

const deleteMenuOption = (openModalToDelete) => ({
  label: 'Delete',
  clickEvent: openModalToDelete,
  color: 'error.main',
});

const menuStyles = {
  '.MuiDataGrid-row & > svg': {
    display: 'none',
  },
  '.MuiDataGrid-row:hover & > svg': {
    display: 'block',
  },
};

const WorkRequestMoreOptions = ({
  value,
  workRequestItemTypeId,
  openModalToDelete,
  onRenameClick,
  openSendItemToPCModal,
  isReadOnly = false,
  uomOptions,
  row,
}) => {
  const [visible, setVisible] = useState(false);
  const getUOMbyId = useMemo(() => uomOptions?.filter((uom) => uom.value === value), [uomOptions, value]);

  const unitOfMeasure = uomOptions ? getUOMbyId[0] : null;

  const isWriteInAssembly = WRITE_IN_ASSEMBLY === workRequestItemTypeId;

  const isSpool = SPOOL_UOM === row?.unitOfMeasureId;

  const MORE_MENU_OPTIONS = [];

  if (isWriteInAssembly && !isSpool) {
    MORE_MENU_OPTIONS.push(addItemToCatalogMenuOption(openSendItemToPCModal));
  }

  if (!isReadOnly) {
    MORE_MENU_OPTIONS.push(renameMenuOption(onRenameClick));
  }

  if (!isReadOnly) {
    MORE_MENU_OPTIONS.push(deleteMenuOption(openModalToDelete));
  }

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
      {unitOfMeasure?.label || <div />}
      {MORE_MENU_OPTIONS && MORE_MENU_OPTIONS.length > 0 && (
        <MoreMenu
          options={MORE_MENU_OPTIONS}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          sxMenu={{ left: '30px' }}
          handleOpen={() => setVisible(true)}
          handleExtClose={() => setVisible(false)}
          sxIconButton={visible ? {} : menuStyles}
        />
      )}
    </Stack>
  );
};

export default WorkRequestMoreOptions;
