import React from 'react';

import { Box } from '@mui/system';
import { Droppable } from 'react-beautiful-dnd';

type DroppableContainerProps = {
  droppableId: string;
  disabled: string | null;
  setHighlightColumn: (isDraggingOver: boolean) => void;
  children: React.ReactNode;
};

const DroppableContainer = ({
  droppableId = 'droppableId',
  disabled = null,
  children,
  setHighlightColumn = () => {},
}: DroppableContainerProps) => {
  const disabledColumn = disabled !== droppableId;

  return (
    <Droppable droppableId={droppableId} type={droppableId}>
      {(provided, snapshot) => {
        setHighlightColumn(snapshot.isDraggingOver);

        return (
          <Box
            sx={{
              width: '180px',
              minHeight: '30px',
              opacity: disabled && disabledColumn ? 0.2 : 1,
            }}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {children}
            {provided.placeholder}
          </Box>
        );
      }}
    </Droppable>
  );
};

export default DroppableContainer;
