import React from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Custom } from 'components/Icons';

const NoRowsOverlay = ({ text, sx }) => (
  <Stack sx={{ background: 'white', alignItems: 'center', marginTop: '128px' }}>
    <Custom.Takeoff />
    <Typography
      variant="h5"
      sx={{
        color: 'surface.lightSurface.secondary',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '24px',
        ...sx,
      }}
    >
      {text}
    </Typography>
  </Stack>
);

export default NoRowsOverlay;
