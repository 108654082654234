import React from 'react';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import Modal from 'components/Modal';

import CreateShopConfigurationForm from './CreateShopConfigurationForm';

const ShopConfigurationModal = ({
  title,
  open,
  control,
  loading = false,
  isValid = false,
  onClose,
  onSubmit,
  configurationNameInputRules,
}) => (
  <Modal
    open={open}
    onClose={onClose}
    title={title}
    titleStyles={{ fontWeight: 'bold' }}
    maxWidth="sm"
    footer={
      <>
        {loading && <CircularProgress size={20} />}

        <Button color="secondary" onClick={onClose}>
          CANCEL
        </Button>

        <Button
          color="secondary"
          variant="contained"
          disabled={!isValid || loading}
          onClick={onSubmit}
          sx={{ marginLeft: '8px' }}
        >
          SAVE
        </Button>
      </>
    }
  >
    <CreateShopConfigurationForm
      control={control}
      configurationNameInputRules={configurationNameInputRules}
    />
  </Modal>
);

export default ShopConfigurationModal;
