import React, { FC, ReactNode, memo, useCallback } from 'react';

import StackRow from 'components/StackRow';
import { useItemsIntegrationModalContext } from 'modules/Materials/components/ItemsIntegrationModal/context/ItemsIntegrationModalContext';

import { useItemsIntegrationTableContext } from '../../context/ItemsIntegrationTableContext';
import { getErrorMessage } from '../QtyField/helpers/qtyFieldHelpers';

interface IRowWrapper {
  children: ReactNode;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  qtyFieldName?: string;
  qtyRemaining?: number;
  qtyAggregate?: number;
  isBomLineItem?: boolean;
  disabled?: boolean;
}

const RowWrapper: FC<IRowWrapper> = ({
  children,
  qtyFieldName = '',
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  qtyRemaining = 0,
  qtyAggregate = 0,
  isBomLineItem = false,
  disabled = false,
}: IRowWrapper) => {
  const { setHoveredQtyField, hoveredQtyField, focusedQtyField } = useItemsIntegrationModalContext();
  const { isLoadingRows } = useItemsIntegrationTableContext();
  const handleMouseEnter = useCallback(() => {
    if (isLoadingRows) {
      setHoveredQtyField(null);
      return;
    }
    if (disabled) return;
    if (!qtyAggregate && !qtyRemaining && !qtyFieldName) return;
    if (!focusedQtyField) setHoveredQtyField(() => getErrorMessage({ qtyRemaining, qtyAggregate, isBomLineItem }));
    onMouseEnter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingRows, qtyFieldName, onMouseEnter, qtyRemaining, qtyAggregate, isBomLineItem, disabled, focusedQtyField]);
  const handleMouseLeave = useCallback(() => {
    if (isLoadingRows) {
      setHoveredQtyField(null);
      return;
    }
    if (disabled) return;
    if (!hoveredQtyField) return;
    if (!focusedQtyField) setHoveredQtyField(null);
    onMouseLeave();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingRows, onMouseLeave, disabled, focusedQtyField]);
  return (
    <StackRow
      sx={{
        justifyContent: 'space-between',
        minHeight: '32px !important',
        gap: '8px',
        borderBottom: '1px solid #A9A9A980',
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
    </StackRow>
  );
};

export default memo(RowWrapper);
