import { gql, useApolloClient } from '@apollo/client';

import { workRequests } from 'graphql/queries';
import { WorkRequest } from 'graphql/types';

const useRefetchWorkRequestsByChildWorkRequest = () => {
  const client = useApolloClient();

  const refetchWorkRequestByChildWorkRequest = async (workRequest: WorkRequest) =>
    client.refetchQueries({
      include: [gql(workRequests)],
      onQueryUpdated(observableQuery) {
        const childWorkRequests = observableQuery.getLastResult()?.data?.workRequests;
        if (!childWorkRequests) return false;

        const isWorkRequestIncluded = childWorkRequests.some(
          (childWorkRequest: WorkRequest) => childWorkRequest.workRequestId === workRequest.workRequestId,
        );
        return isWorkRequestIncluded;
      },
    });

  return { refetchWorkRequestByChildWorkRequest };
};

export default useRefetchWorkRequestsByChildWorkRequest;
