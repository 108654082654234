import React from 'react';

import { Typography } from '@mui/material';
import { Handle } from 'react-flow-renderer';

import { MoreMenu } from 'components';

const DecisionNode = ({ data /* ...props */ }) => {
  const { isHovered, onDelete, onDuplicate, id } = data;

  const deleteNode = () => {
    onDelete(id);
  };

  const duplicateNode = () => {
    onDuplicate(id);
  };

  const options = [
    { label: 'Delete', clickEvent: deleteNode },
    { label: 'Duplicate', clickEvent: duplicateNode },
  ];

  return (
    <div
      style={{
        height: '66px',
        width: '87px',
        backgroundColor: 'white',
        borderRadius: '4px',
        border: '1px solid #CDAA2F',
        position: 'relative',
        boxShadow:
          '0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12)',
      }}
    >
      <div
        style={{
          backgroundColor: '#CDAA2F',
          width: '22px',
          height: '22px',
          transform: 'rotate(45deg)',
          position: 'absolute',
          top: 12,
          left: 31,
        }}
      />
      <Typography sx={{ textAlign: 'center', fontSize: '14px', pt: '36px' }}>Decision</Typography>
      {isHovered && (
        <MoreMenu style={{ position: 'absolute', top: 0, right: 0 }} options={options} />
      )}
      <Handle
        onConnect={(event) => {
          // eslint-disable-next-line
          console.log('event', event);
        }}
        type="source"
        position="left"
        id="a"
        style={{
          top: '50%',
          borderRadius: 2,
          borderColor: data.borderColor,
          backgroundColor: '#FFF',
          visibility: isHovered ? 'visible' : 'hidden',
        }}
      />
      <Handle
        type="source"
        position="right"
        id="b"
        style={{
          top: '50%',
          borderRadius: 2,
          borderColor: data.borderColor,
          backgroundColor: '#FFF',
          visibility: isHovered ? 'visible' : 'hidden',
        }}
      />
      <Handle
        type="source"
        position="top"
        id="c"
        style={{
          left: '50%',
          borderRadius: 2,
          borderColor: data.borderColor,
          backgroundColor: '#FFF',
          visibility: isHovered ? 'visible' : 'hidden',
        }}
      />
      <Handle
        type="source"
        position="bottom"
        id="d"
        style={{
          left: '50%',
          borderRadius: 2,
          borderColor: data.borderColor,
          backgroundColor: '#FFF',
          visibility: isHovered ? 'visible' : 'hidden',
        }}
      />
    </div>
  );
};

export default DecisionNode;
