import React from 'react';

import { Typography } from '@mui/material';

const RowTitle = ({ sx, children }) => (
  <Typography
    variant="subtitle2"
    sx={{
      flex: 1,
      fontWeight: 600,
      letterSpacing: '0.1px',
      marginLeft: 1,
      userSelect: 'none',
      ...sx,
    }}
  >
    {children}
  </Typography>
);

export default RowTitle;
