export const partAttributeVariantData = /* GraphQL */ `
  query PartAttributeVariantData($query: QueryPartAttributeVariantDataQueryInput) {
    partAttributeVariantData(query: $query) {
      partAttributeVariantDataId
      partAttributeId
      partAttribute {
        partAttributeId
        partAttributeName
      }
      selectValues {
        partAttributeSelectVariantId
        textValue
        codeValue
      }
    }
  }
`;
export const syncGetWorkflowMappingConfiguration = /* GraphQL */ `
  query SyncGetWorkflowMappingConfiguration($params: QuerySyncGetWorkflowMappingConfigurationParamsInput!) {
    syncGetWorkflowMappingConfiguration(params: $params) {
      workflowId
      workflowName
      mapping {
        mappingId
        mappingName
        canvasNode
        isSyncRunning
        lastSyncAt
        lastSavedAt
        isArchived
        isDeactivated
        solitaryFlowConfiguration {
          solitaryFlowId
          action
          actionNodeId
          actionAlias
          isDeactivated
          isArchived
          source {
            syncDetailsId
            nodeId
            connection {
              id
              name
              type
              configuration
              isConnectionUsed
              lastModified
              desktopConnectionId
            }
            hub {
              id
              name
            }
            projects {
              id
              name
              folders {
                id
                name
                items {
                  id
                  name
                }
              }
            }
            additionalData
            settings {
              isSyncEnabled
              includeExtensions
              secondaryFilesSetting
              isSyncAllRevisions
              syncType
              scheduleSyncConfig
            }
          }
          destinations {
            syncDetailsId
            nodeId
            connection {
              id
              name
              type
              configuration
              isConnectionUsed
              lastModified
              desktopConnectionId
            }
            hub {
              id
              name
            }
            project {
              id
              name
              folder {
                id
                name
              }
            }
            additionalData
          }
        }
        solitaryFlowPublishModelConfiguration {
          solitaryFlowId
          action
          actionNodeId
          actionAlias
          syncStatus
          lastSyncAt
          isDeactivated
          isArchived
          source {
            syncDetailsId
            nodeId
            connection {
              id
              name
              type
              configuration
              isConnectionUsed
              lastModified
              desktopConnectionId
            }
            hub {
              id
              name
            }
            projects {
              id
              name
              folders {
                id
                name
                items {
                  id
                  name
                }
              }
            }
            additionalData
            settings {
              isSyncEnabled
              includeExtensions
              secondaryFilesSetting
              isSyncAllRevisions
              syncType
              scheduleSyncConfig
            }
          }
        }
      }
    }
  }
`;
