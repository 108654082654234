import React from 'react';

import { Box, Divider } from '@mui/material';

import StackRow from 'components/StackRow';
import { objIsEmpty } from 'helpers/objectFunctions';

import { useModal } from '../../contexts/ModalContext';
import { useRow } from '../../contexts/RowContext';
import { GREEN_ICON_COLOR } from '../constants/constants';
import AggregatedAmountText from './AggregatedAmountText';
import Loader from './Loader';
import QtyInput from './QuantityInput';
import RowInfo from './RowInfo';

const WorkPhaseRow = ({ depth = 0, showAggregatedAmount = true }) => {
  const { show0WorkPhases, calculationNodeIds, errorNodeIds, parentFilteredIds } = useModal();
  const { itemId, item, loadingAmount, requiredQty, called, nodeTreeItem } = useRow();

  const isEditing = calculationNodeIds?.includes(itemId);

  if ((loadingAmount && !called) || objIsEmpty(item)) return <Loader />;

  if (!show0WorkPhases && requiredQty === 0 && nodeTreeItem?.aggregateVisual === 0 && !isEditing) return <></>;

  if (parentFilteredIds?.length && !parentFilteredIds?.includes(itemId)) return <></>;

  return (
    <>
      <StackRow sx={{ justifyContent: 'space-between', minHeight: '32px', gap: '8px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
          <Box sx={{ width: `${depth * 24 + 12}px` }} />
          <RowInfo type="Work Phase" item={item} iconSx={GREEN_ICON_COLOR} />
        </Box>
        <StackRow gap="16px">
          {showAggregatedAmount && (
            <AggregatedAmountText isEditing={nodeTreeItem?.isEdited} isError={errorNodeIds?.includes(itemId)}>
              {nodeTreeItem?.aggregateVisual}
            </AggregatedAmountText>
          )}
          <QtyInput />
        </StackRow>
      </StackRow>
      <Divider sx={{ width: '100%' }} />
    </>
  );
};

export default WorkPhaseRow;
