import React from 'react';

import { Button, useTheme } from '@mui/material';

import { Custom } from 'components/Icons';

import { useWorkRequestPropertiesAttachmentsContext } from '../../../WorRequestsProperties/WorkRequestPropertiesAttachments/WorkRequestPropertiesAttachmentsContext';

export const LinkButton = () => {
  const { setShowUrlInput, showUrlInput } = useWorkRequestPropertiesAttachmentsContext();
  const { palette } = useTheme();
  return (
    <Button
      startIcon={
        <Custom.Link
          style={{
            fill: showUrlInput ? palette.primary.containedHoverBackground : palette.secondary.mainDarkBackground,
          }}
          fillOpacity="1"
          width="30"
          height="10"
          data-testid="custom-link-icon"
        />
      }
      onClick={() => setShowUrlInput(true)}
      sx={{ fontWeight: '400', color: palette.secondary.mainDarkBackground, textTransform: 'none' }}
      variant="text"
      disabled={showUrlInput}
    >
      Add Link
    </Button>
  );
};
