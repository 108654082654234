import React from 'react';

import { useMutation, gql } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import { useForm } from 'react-hook-form';

import { Modal, CancelButton, CreateButton } from 'components';
import { addDepartmentUser as ADD_DEPARTMENT_USER } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

import { useDesignMembersContext } from '../DesignMembersContext';
import DesignInviteForm from './DesignInviteForm';

const InviteToDesignModal = () => {
  const { departmentId, inviteModalOpen, setInviteModalOpen } = useDesignMembersContext();
  const { handleBatchResponse } = useGraphqlResponseHandler();

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: 'all',
  });

  const [addDepartmentUserMutation, { loading }] = useMutation(gql(ADD_DEPARTMENT_USER), {
    refetchQueries: ['DepartmentUsers'],
  });

  const submit = async ({ newDesignUsers }) => {
    try {
      const results = await Promise.all(
        newDesignUsers.map(async (designUser) => {
          const body = {
            departmentId,
          };

          if (typeof designUser === 'object') {
            body.userId = designUser.id;
          } else {
            body.userEmail = designUser;
          }

          const res = await addDepartmentUserMutation({
            variables: {
              body,
            },
          });

          return res;
        }),
      );

      handleBatchResponse(results);
    } finally {
      setInviteModalOpen(false);
    }
  };

  return (
    <Modal
      open={inviteModalOpen}
      onClose={() => setInviteModalOpen(false)}
      title="Invite users to design"
      reducePadding
      fullWidth
      allowOverflow
      maxWidth="xs"
      contentStyles={{ overflowY: 'visible' }}
      footer={
        <>
          <CancelButton color="primary" onClick={setInviteModalOpen} />
          <CreateButton
            color="secondary"
            onClick={handleSubmit(submit)}
            disabled={!isValid || loading}
          />
        </>
      }
    >
      {loading ? <CircularProgress /> : <DesignInviteForm control={control} />}
    </Modal>
  );
};

export default InviteToDesignModal;
