import React, { useMemo } from 'react';

import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { Custom } from 'components/Icons';
import StackRow from 'components/StackRow';
import OverFlowTooltip from 'components/Tooltip/OverFlowTooltip';
import { getParsedDate, formateTime } from 'helpers/dateFunctions';

const AttachmentsTableRow = ({
  item,
  handleDownload = () => {},
  handleDeleteModal = () => {},
  handleOpenFile = () => {},
  allowDelete = false,
  loading,
  darkMode = false,
}) => {
  const { palette } = useTheme();

  const attachmentDateColor = useMemo(() => {
    if (!darkMode) return palette.surface.lightSurface.primary;
    if (!allowDelete) return palette.grey[500];
    return palette.secondary.mainDarkBackground;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [darkMode, allowDelete]);
  const themeColor = useMemo(() => {
    if (darkMode) return `${palette.secondary.mainDarkBackground} !important`;
    return `${palette.secondary.main} !important`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [darkMode]);

  return (
    <TableRowContent>
      <TableCell>
        <TableCellContent
          sx={{
            justifyContent: 'start',
            gap: 2,
            color: themeColor,
            path: {
              fill: '#000000',
              fillOpacity: 0.54,
            },
            '&:hover': {
              '.MuiTypography-root': {
                color: themeColor,
                textDecoration: 'underline',
              },
              path: {
                fill: themeColor,
                fillOpacity: 1,
              },
            },
            cursor: 'pointer',
            maxWidth: 'calc(100% - 8px)',
          }}
        >
          <Stack sx={{ minWidth: '24px', alignItems: 'center' }} data-testid="attachment-icon">
            {loading ? (
              <Progress />
            ) : (
              <>
                {item?.externalUrl ? (
                  <Custom.Link onClick={handleOpenFile} />
                ) : (
                  <Custom.FileDownloadOutlined onClick={handleDownload} />
                )}
              </>
            )}
          </Stack>
          <FileName onClick={handleOpenFile} item={item} palette={palette} darkMode={darkMode} />
        </TableCellContent>
      </TableCell>
      <TableCell align="center">
        <TableCellContent sx={{ mr: '20px' }}>
          <OverFlowTooltip title={`Added at ${formateTime(item?.createdOn)} by ${item?.username}`} showAlways>
            <Typography variant="body2" color={attachmentDateColor} data-testid="attachment-date">
              {getParsedDate(item?.createdOn)}
            </Typography>
          </OverFlowTooltip>
        </TableCellContent>
      </TableCell>
      {allowDelete && (
        <TableCell align="center">
          <TableCellContent sx={{ ml: '20px' }}>
            <Stack sx={{ '&:hover path': { fillOpacity: '0.78' } }}>
              <Custom.Trash
                onClick={handleDeleteModal}
                style={{ cursor: 'pointer' }}
                className="delete-icon"
                data-testid="delete-attachment-icon"
              />
            </Stack>
          </TableCellContent>
        </TableCell>
      )}
    </TableRowContent>
  );
};

const TableCellContent = (props) => <StackRow {...props} />;

const TableRowContent = (props) => (
  <TableRow
    data-testid="attachment-table-row"
    sx={{
      cursor: 'default',
      height: '32px',
      '& td:nth-of-type(1)': {
        maxWidth: '1px',
      },
      '& td:nth-of-type(2)': {
        width: '11px',
      },
      '& td:nth-of-type(3)': {
        width: '54px',
      },
      '& .delete-icon': {
        opacity: 0,
      },
      '&:hover .delete-icon': {
        opacity: 1,
      },
    }}
    {...props}
  />
);

const Progress = ({ sx, ...props }) => (
  <Box sx={{ display: 'flex' }} {...props}>
    <CircularProgress sx={sx} size={20} />
  </Box>
);

const FileName = ({ onClick, item, palette, darkMode }) => {
  const attachmentNameColor = useMemo(() => {
    if (darkMode) {
      if (item?.externalUrl) return `${palette.secondary.mainDarkBackground} !important`;
      return `${palette.surface.darkSurface.primary} !important`;
    }
    return `${palette.surface.lightSurface.primary} !important`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [darkMode, item]);
  return (
    <OverFlowTooltip title={item?.externalUrl ?? item?.documentNameFormatted}>
      <Typography
        onClick={onClick}
        component="span"
        noWrap
        variant="body2"
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: '100%',
          color: attachmentNameColor,
        }}
        data-testid="attachment-name"
      >
        {item?.externalUrl || item?.documentNameFormatted}
      </Typography>
    </OverFlowTooltip>
  );
};

export default AttachmentsTableRow;
