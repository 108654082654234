import React from 'react';

import { Stack, Box } from '@mui/material';

import ForgeViewerDocument from 'components/Autodesk/ForgeViewerDocument';

import { useDrawingDocumentContext } from './DrawingDocumentContext';

const DrawingDocumentDetailView = () => {
  const { documents, activeDocumentId } = useDrawingDocumentContext();

  const activeDocument = documents.filter(
    (document) => document?.documentId === activeDocumentId,
  )[0];

  return (
    <Stack direction="column" width="100%" height="100%">
      <Box height="100%" mt="16px">
        {activeDocument && <ForgeViewerDocument document={activeDocument} />}
      </Box>
    </Stack>
  );
};

export default DrawingDocumentDetailView;
