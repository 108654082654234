import React, { useState, useEffect, useMemo, useContext } from 'react';

import { ForesiteTextField } from '@evolvemep/foresite-components-ui-react';
import { Box } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';

import { WorkOrderItemsContext } from 'modules/Shop/WorkOrders/WorkOrder/context/WorkOrderItemsContext';

import { useCatalogModalQuery, useCatalogModal } from './CatalogModalContext';

const MAX_CHAR_COUNT = 128;

const PartNameField = ({ control, selectedItem }) => {
  const workOrderItemsContext = useContext(WorkOrderItemsContext);
  const { searchPartName, foundPartName, foundPartLoading } = useCatalogModalQuery();
  const { selectedCatalog, selectedCategory } = useCatalogModal();
  const [debouncedName, setDebouncedName] = useState(null);
  const [nameToSearch, setNameToSearch] = useState(null);
  const [currentFound, setCurrentFound] = useState(null);
  const { trigger, isLoading } = useFormContext();

  const onValueChange = (event) => {
    setDebouncedName(event.target.value);
  };

  const itemName = workOrderItemsContext
    ? selectedItem.workOrderItemName
    : selectedItem.workRequestItemName;

  useEffect(() => {
    searchPartName({
      variables: {
        query: {
          name: `eq:${encodeURIComponent(itemName?.trim())}`,
          take: 1,
        },
      },
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTimeout(() => trigger('PartNameField'), 200);
  }, [foundPartLoading, trigger]);

  useEffect(() => {
    const timer = setTimeout(() => setNameToSearch(debouncedName), 300);
    return () => clearTimeout(timer);
  }, [debouncedName]);

  useEffect(() => {
    if (!nameToSearch) return;
    searchPartName({
      variables: {
        query: {
          name: `eq:${encodeURIComponent(nameToSearch.trim())}`,
          take: 1,
        },
      },
    });
  }, [nameToSearch, searchPartName, selectedCatalog, selectedCategory]);

  useEffect(() => {
    if (foundPartName?.parts?.length)
      setCurrentFound(foundPartName.parts[0].partName.trim().toLowerCase());
  }, [foundPartName]);

  const fieldRules = useMemo(
    () => ({
      required: 'Item name is required',
      maxLength: {
        value: MAX_CHAR_COUNT,
      },
      validate: (value) => {
        const validation = value.trim().toLowerCase() === currentFound;
        return !validation || 'Item name already exists.';
      },
    }),
    [currentFound],
  );

  return (
    <Box>
      <Controller
        defaultValue={itemName}
        name="PartNameField"
        control={control}
        rules={fieldRules}
        render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
          <ForesiteTextField
            {...rest}
            fullWidth
            required
            disabled={isLoading}
            name="PartNameField"
            size="small"
            label="Generic part Name"
            defaultValue={itemName}
            onChange={(event, child) => {
              onChange(event, child);
              onValueChange(event, child);
            }}
            error={Boolean(error)}
            maxLength={128}
            helperText={error?.message || ''}
          />
        )}
      />
    </Box>
  );
};

export default PartNameField;
