import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import {
  MAX_CHIP_DEPTH,
  ROW_TYPES,
} from 'modules/Field/LocationsAndWorkPhases/constants/constants';
import { useLocationsAndWorkPhases } from 'modules/Field/LocationsAndWorkPhases/contexts/LocationsAndWorkPhaseContext';

import { useChips } from '../CreateLocationsModalContext';
import { NewRowButton, AddToLocationsButton } from './components/SharedComponents';
import LocationForm from './LocationForm';
import WorkPhaseForm from './WorkPhaseForm';

const CreateChipForm = ({ form, activeStep }) => {
  const {
    formValues: { locationTypeName, isDraft },
  } = form;

  const {
    addSubLocation,
    formIsValid,
    hasNotWorkPhases,
    addToLocations,
    activeChip,
    activeChipInStep2,
    showForm,
  } = useChips();

  const activeForm = activeChipInStep2.id ? activeChipInStep2 : activeChip;

  const locationIs = (askedLocations) => askedLocations.includes(activeForm.locationTypeName);

  const disableNewRowButton =
    !formIsValid ||
    hasNotWorkPhases ||
    isDraft ||
    (activeForm.depth === MAX_CHIP_DEPTH && activeForm.locationTypeName !== ROW_TYPES.WORK_PHASE) ||
    (activeForm.locationTypeName === ROW_TYPES.LOCATION_SYSTEM && activeStep === 1);

  const disableAddToLocationButton =
    !formIsValid || hasNotWorkPhases || !isDraft || !activeForm.isDraft;

  const {
    locations: { activeRow },
  } = useLocationsAndWorkPhases();

  return (
    <form>
      {showForm ? (
        <>
          {activeStep === 0 || locationTypeName === ROW_TYPES.LOCATION_SYSTEM ? (
            <LocationForm form={form} />
          ) : (
            <WorkPhaseForm form={form} />
          )}
        </>
      ) : (
        <Skeleton activeForm={activeForm} />
      )}
      <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
        <NewRowButton
          onClick={() => addSubLocation(activeStep, locationIs([ROW_TYPES.WORK_PHASE]))}
          disabled={disableNewRowButton}
        />
        <AddToLocationsButton
          onClick={() => addToLocations(activeRow.ascendantIsFloor)}
          disabled={disableAddToLocationButton}
        />
      </Stack>
    </form>
  );
};

export default CreateChipForm;

const Skeleton = ({ activeForm }) => {
  const height = (() => {
    if (activeForm.locationTypeName === ROW_TYPES.LOCATION_SYSTEM) return '165px';
    if (activeForm.locationTypeName === ROW_TYPES.WORK_PHASE) return '112px';
    return '279px';
  })();
  return <Box sx={{ width: '100%', height }} />;
};
