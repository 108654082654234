import React, { useMemo } from 'react';

import Box from '@mui/material/Box';
import { GridRow } from '@mui/x-data-grid-pro';
import { Draggable } from 'react-beautiful-dnd';
import ReactDOM from 'react-dom';

import { Custom } from 'components/Icons';

import { DrawingDocumentType } from '../../hooks';
import { DrawingDocumentDragContainer } from '../DrawingDocumentDragContainer';

const dndPortal = document.getElementById('dnd-portal');

export default function DrawingDocumentRow({
  params,
  provided: providedProp,
  selectedRows = [],
  isMappingDocuments = false,
  renderLastRow = () => {},
}) {
  const checkRowSelected = (rowData) => selectedRows.some(({ id }) => id === rowData.rowId);

  const row = useMemo(() => {
    const { rowId, row: rowData, containerWidth, rowHeight } = params;
    return {
      rowId,
      draggableIndex: rowData.draggableIndex,
      containerWidth,
      rowHeight,
    };
  }, [params]);

  const isRowDraggable = useMemo(() => {
    if (isMappingDocuments) return false;

    const { row: rowData } = params;
    const isRowDraggableByDefault = rowData.type === DrawingDocumentType.DOCUMENT;
    if (rowData.isEditing) return false;
    if (selectedRows.length) {
      const isRowSelected = selectedRows.some(({ id }) => id === rowData.id);
      return isRowSelected && isRowDraggableByDefault;
    }

    return isRowDraggableByDefault;
  }, [params, selectedRows, isMappingDocuments]);

  const rows = useMemo(() => {
    const { row: rowData } = params;
    if (selectedRows.length) {
      return selectedRows;
    }

    return [rowData];
  }, [params, selectedRows]);

  const handleTransitionEnd = (provided) => {
    if (typeof provided.draggableProps.onTransitionEnd === 'function') {
      queueMicrotask(() =>
        provided.draggableProps.onTransitionEnd?.({
          propertyName: 'transform',
        }),
      );
    }
  };

  return (
    <div ref={providedProp.innerRef} {...providedProp.droppableProps}>
      <Draggable draggableId={row.rowId} index={row.draggableIndex} isDragDisabled={!isRowDraggable}>
        {(provided, snapshot) => {
          handleTransitionEnd(provided);

          const RowContent = (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={{ cursor: !isRowDraggable && 'wait' }}
            >
              <RowComponentWrapper isSelected={checkRowSelected(row)}>
                <DragButton {...provided.dragHandleProps} />
                <GridRow {...params} />
              </RowComponentWrapper>
            </div>
          );

          return snapshot.isDragging
            ? ReactDOM.createPortal(
                <DraggableContent
                  provided={provided}
                  dragHandleProps={provided.dragHandleProps}
                  row={row}
                  rows={rows}
                />,
                dndPortal,
              )
            : RowContent;
        }}
      </Draggable>
      {renderLastRow && renderLastRow()}
    </div>
  );
}

const DragButton = (props) => (
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <Box {...props} className="drag-row-icon" sx={{ position: 'absolute', left: 6, zIndex: 19999 }}>
      <Custom.Drag />
    </Box>
  </Box>
);

const RowComponentWrapper = ({ isSelected, children }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      '& .drag-row-icon': {
        display: isSelected ? 'block' : 'none',
      },
      '&:hover .drag-row-icon': {
        display: 'block',
      },
    }}
    data-testid="elbox"
  >
    {children}
  </Box>
);

const DraggableContent = ({ provided, dragHandleProps, row, rows }) => (
  <Box sx={{ display: 'flex', alignItems: 'center' }} {...provided.draggableProps} ref={provided.innerRef}>
    <Box sx={{ position: 'absolute', left: 6, zIndex: 19999 }} {...dragHandleProps}>
      <Custom.Drag />
    </Box>
    <DrawingDocumentDragContainer width={row.containerWidth} height={row.rowHeight} rows={rows} />
  </Box>
);
