import React, { FC } from 'react';

import { Typography } from '@mui/material';

import Section from 'components/Section/Section';
import StackRow from 'components/StackRow';

const LBSHeaders: FC = () => (
  <Section.Title sx={{ minHeight: '32px !important', p: 0, gap: 0 }} justifyContent="space-between">
    <StackRow gap="8px">
      <Typography variant="subtitle2" width="98px" sx={{ margin: '0 0 0 54px' }}>
        Id
      </Typography>
      <Typography variant="subtitle2">Location</Typography>
    </StackRow>
  </Section.Title>
);

export default LBSHeaders;
