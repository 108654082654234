import React from 'react';

import { MoreMenu } from 'components';

import CollapseChildrenIcon from '../../components/CollapseChildrenIcon';
import { RowIcon } from '../../components/RowPrefix';
import StyledRow from '../../components/StyledRow';
import { useLocationsAndWorkPhases } from '../../contexts/LocationsAndWorkPhaseContext';
import { useMoreMenu } from '../hooks/useMoreMenu/useMoreMenu';
import LocationEditableName from './LocationEditableName';

const LocationsRowInfo = React.memo(
  ({ node, depth = 0, isPreview, handleToggleOpen, parent, ascendantIsFloor }) => {
    const { buildOptions } = useMoreMenu(node, parent, ascendantIsFloor);

    const {
      locations: {
        toggleOpenNode,
        activeRow,
        setActiveRow,
        previewActiveRow,
        setPreviewActiveRow,
        isEditingALocation,
      },
    } = useLocationsAndWorkPhases();

    const getChildren = (key) => node[key] || [];
    const childrenItem = [...getChildren('locationPhases'), ...getChildren('children')];

    return (
      <StyledRow
        isActive={node?.locationId === (isPreview ? previewActiveRow : activeRow)?.locationId}
        onClick={() =>
          isPreview ? setPreviewActiveRow(node) : setActiveRow({ ...node, ascendantIsFloor })
        }
      >
        <CollapseChildrenIcon
          onClick={() => (isPreview ? handleToggleOpen(node) : toggleOpenNode(node))}
          open={node.isOpen}
          showIcon={childrenItem?.length > 0 || (!isPreview && node.childrenTypeName)}
          filled
          sx={{ ml: 2.8 * depth - 0.5 }}
        />
        <RowIcon type={node.locationTypeName} />
        <LocationEditableName location={node} />
        {!isPreview && (
          <MoreMenu options={buildOptions()} disabled={isEditingALocation} sx={{ fontSize: 21 }} />
        )}
      </StyledRow>
    );
  },
);

export default LocationsRowInfo;
