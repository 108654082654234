import React from 'react';

import { Chip, Stack, Typography, useTheme } from '@mui/material';

import CatalogSearchDropDown from './CatalogSearchDropDown';
import useCatalogSearchDropDownHelpers from './useCatalogSearchDropDownHelpers';

const CatalogSearchDropDownConnected = ({ value = '', onChange = () => {}, partName }) => {
  const { loading, catalogs } = useCatalogSearchDropDownHelpers(onChange);
  const theme = useTheme();
  return (
    <Stack direction="row" alignItems="center">
      <CatalogSearchDropDown value={value} onChange={onChange} loading={loading} catalogs={catalogs} />
      {partName && <ChipAddingTo partName={partName} color={theme.palette.secondary.mainDarkBackground} />}
    </Stack>
  );
};

export default CatalogSearchDropDownConnected;

const ChipAddingTo = ({ color, partName }) => (
  <Chip
    label={
      <Stack direction="row" alignItems="center">
        Adding to:
        <Typography fontSize="13px" sx={{ fontWeight: 'bold', marginLeft: '2px' }}>
          {partName}
        </Typography>
      </Stack>
    }
    size="small"
    sx={{
      backgroundColor: color,
    }}
  />
);
