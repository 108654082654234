/* eslint-disable import/no-cycle */

import { FocusEvent, useCallback } from 'react';

import { useFormContext } from 'react-hook-form';

import { useItemsIntegrationModalContext } from 'modules/Materials/components/ItemsIntegrationModal/context/ItemsIntegrationModalContext';

import { useItemsIntegrationTableContext } from '../../../context/ItemsIntegrationTableContext';
import { getErrorMessage } from '../../QtyField/helpers/qtyFieldHelpers';
import { useFieldContext } from '../context/FieldContext';
import { replaceNegativeAmount } from '../helpers/quantityFieldHelpers';

const useFieldHandlers = () => {
  const {
    fieldName,
    qtyRemaining,
    qtyAggregate,
    isBomLineItem,
    setShowArrows,
    deleteBtnClicked,
    setDeleteBtnClicked,
    currentNestLevel,
    quantityFieldChildren,
    qtyFieldRef,
  } = useFieldContext();
  const { setValue } = useFormContext();
  const { setFocusedQtyField, setHoveredQtyField } = useItemsIntegrationModalContext();
  const { isCalculatingFields } = useItemsIntegrationTableContext();

  const handleDeleteBtnClicked = useCallback(() => {
    setValue(fieldName, '');
  }, [fieldName, setValue]);

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
      e.preventDefault();
      e.stopPropagation();
      setFocusedQtyField(null);
      setHoveredQtyField(null);
      setShowArrows(false);
      if (deleteBtnClicked) {
        handleDeleteBtnClicked();
        setDeleteBtnClicked(false);
      }
    },
    [
      setFocusedQtyField,
      setShowArrows,
      deleteBtnClicked,
      handleDeleteBtnClicked,
      setDeleteBtnClicked,
      setHoveredQtyField,
    ],
  );

  const handleClick = useCallback(() => {
    if (isCalculatingFields) return;
    const isLineItemOrLocationWithQtyRemaining = qtyRemaining && currentNestLevel > 0;
    const isLineItemWithChildren =
      currentNestLevel === 0 &&
      !quantityFieldChildren?.filter((child) => !child.isWorkPhase && (child.locationId || child.nestLevel)).length;
    if (isLineItemOrLocationWithQtyRemaining || isLineItemWithChildren) return;
    if (!isBomLineItem && qtyRemaining) return;
    setValue(fieldName, replaceNegativeAmount(qtyAggregate));
    qtyFieldRef?.current?.blur();
  }, [
    qtyRemaining,
    currentNestLevel,
    quantityFieldChildren,
    isBomLineItem,
    setValue,
    fieldName,
    qtyAggregate,
    qtyFieldRef,
    isCalculatingFields,
  ]);

  const handleFocus = useCallback(() => {
    setHoveredQtyField(() => getErrorMessage({ qtyRemaining, qtyAggregate, isBomLineItem }));
    setFocusedQtyField(fieldName);
    setShowArrows(true);
  }, [fieldName, isBomLineItem, qtyAggregate, qtyRemaining, setFocusedQtyField, setHoveredQtyField, setShowArrows]);

  return {
    handleDeleteBtnClicked,
    handleFocus,
    handleBlur,
    handleClick,
  };
};

export default useFieldHandlers;
