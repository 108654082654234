import React, { FC } from 'react';

import { Stack } from '@mui/material';

import { ItemsDataGridProProvider } from 'components/ItemsDatagridPro';
import ItemsDataGridPro from 'components/ItemsDatagridPro/ItemsDatagridPro';

import { useItemsIntegrationModalContext } from '../../context/ItemsIntegrationModalContext';

const WorkRequestsTable: FC = () => {
  const { rows, columns, setSelectionModel, selectionModel, apiRef, handleOnScrollEnd, loadingWorkRequests, addNewWR } =
    useItemsIntegrationModalContext();

  return (
    <ItemsDataGridProProvider contextId="ItemsToWRTable">
      <ItemsDataGridPro
        loading={loadingWorkRequests}
        apiRef={apiRef}
        sx={{
          '&.MuiDataGrid-root .MuiDataGrid-cell': {
            borderTop: 'none',
            borderBottom: 'none',
          },
          '&.MuiDataGrid-root .MuiDataGrid-row:last-child': {
            borderBottom: 'none',
          },
          '& .MuiDataGrid-row:hover': {
            backgroundColor: 'transparent',
          },
          '& .MuiDataGrid-row.Mui-selected': {
            backgroundColor: 'transparent !important',
          },
          '& .MuiDataGrid-main': {
            flex: 1,
          },
          border: 'none',
        }}
        rows={rows}
        columns={columns}
        rowHeight={48}
        checkboxSelection={false}
        disableColumnMenu
        selectionModel={selectionModel}
        onSelectionModelChange={(newSelectionModel: any) => setSelectionModel(newSelectionModel)}
        pinnedRows={addNewWR ? { top: [{ workRequestId: -1 }] } : {}}
        experimentalFeatures={{ rowPinning: true }}
        onRowsScrollEnd={handleOnScrollEnd}
        getRowId={(row: any) => row.workRequestId}
        components={{
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No work requests found
            </Stack>
          ),
        }}
      />
    </ItemsDataGridProProvider>
  );
};

export default WorkRequestsTable;
