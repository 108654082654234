/* eslint-disable max-lines-per-function */
import React, { createContext, useCallback, useMemo, useState, useContext, useEffect, useRef } from 'react';

import { useGridApiRef } from '@mui/x-data-grid-pro';

import { useTakeOffAPI } from 'hooks-api/useTakeOffAPI';
import { useProject } from 'modules/Field/LocationsAndWorkPhases/contexts/ProjectContext';
import {
  getLocationString,
  getWorkPhasesString,
  getLocationPhasesString,
  getScopePackagesString,
} from 'modules/Materials/BillOfMaterialsById/getIdsForFilters';
import { useHandleDecisionAdded } from 'modules/Materials/BillOfMaterialsById/hooks/useHandleDecisionAdded';

import { useBOMItemsFilters } from '../../features/filters/useBOMItemsFilters';

export const PAD_ID = 'takeoffPadId';
export const PAD_LABEL_NAME = 'takeoffPadName';

export const TakeOffContext = createContext();

export const TakeOffProvider = ({ children }) => {
  const tableRef = useRef();
  const apiRef = useGridApiRef();
  const sourcePadsRef = useRef([]);
  const bomName = useRef({ name: ' ', id: ' ' });
  const { itemSelectedId: projectId } = useProject();

  const [disabledDropdown, setDisabledDropdown] = useState(false);
  const [isRevitSourcePad, setIsRevitSourcePad] = useState(false);
  const [selectedPadItems, setSelectedPadItems] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [sourcePadsQty, setSourcePadsQty] = useState(0);
  const [itemCount, setItemCount] = useState(0);
  const [chipList, setChipList] = useState([]);
  const [assignModeOn, setAssignModeOn] = useState(false);
  const [padIndex, setPadIndex] = useState(0);
  const [currentQuery, setCurrentQuery] = useState(null);
  const [parameters, setParameters] = useState({
    locationIds: '',
    workPhaseIds: '',
    locationsWithWorkPhases: '',
    scopePackageIds: '',
  });

  const decisionsStatesAndCallbacks = useHandleDecisionAdded();
  const { lineItemDecision } = decisionsStatesAndCallbacks;

  const forceTakeoffItemsRefetch = chipList.length > 0 && !assignModeOn;

  const {
    paginationHandlers: {
      sourcePadItemsPaginationFn,
      takeOffPadItemsPaginationFn,
      sourcePadItemsOrderByFn,
      takeOffPadItemsOrderByFn,
    },
    client,
    sourcePadItemsCountRes,
    sourcePadItemsRes,
    sourcePadSortingKeyword,
    sourcePads,
    sourcePadProperties,
    takeOffPadItemsCountRes,
    takeOffPadItemsRes,
    takeOffPadSortingKeyword,
    takeOffPads,
    loadingAddTakeOffPadItem,
    callbacks: {
      onAddTakeOffPad,
      onDeleteTakeoffPad,
      onDeleteSourcePad,
      onRenameTakeOffPad,
      onMapSourcePadBase,
      fetchTakeOffPads,
      fetchSourcePadItems,
      fetchSourcePadItemsCount,
      fetchSourcePads,
      fetchSourcePadProperties,
      fetchTakeOffPadItems,
      refetchTakeOffPadItems: refetchTakeOffPadItemsCallback,
      fetchTakeOffPadItemsCount,
      refetchTakeOffPadItemsCount,
      onAddTakeOffPadItem,
      onDeleteTakeOffPadItems,
      refetchSourcePadItems,
      onGetListOfTakeOffPadsByName,
    },
    metadata: { loadingTakeOffPads, loadingSourcePads, takeOffPadItemsLoading, loadingSourcePadItems },
  } = useTakeOffAPI(currentQuery, forceTakeoffItemsRefetch);

  const filters = useBOMItemsFilters();
  const updateChipList = useCallback((element) => setChipList(element), []);
  const updateItemCount = useCallback((element) => setItemCount(element), []);
  const updateOpenSnackbar = useCallback((element) => setOpenSnackbar(element), []);

  const onAddPad = useCallback(
    (pads) => {
      setSelectedPadItems(pads);
      if (takeOffPads.length >= 1) setDisabledDropdown(false);
      else setDisabledDropdown(true);
    },
    [takeOffPads.length],
  );

  useEffect(() => {
    setSelectedPadItems([]);
    sourcePadSortingKeyword.current = '';
    takeOffPadSortingKeyword.current = '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    setPadIndex(0);
    setChipList([]);
  }, [projectId]);

  useEffect(() => {
    const filterIds = {
      locationIds: getLocationString(chipList),
      workPhaseIds: getWorkPhasesString(chipList),
      locationsWithWorkPhases: getLocationPhasesString(chipList),
      scopePackageIds: getScopePackagesString(chipList),
    };
    setParameters((prev) => ({ ...prev, ...filterIds }));
  }, [chipList]);

  useEffect(() => {
    if (projectId) {
      fetchSourcePads({
        variables: {
          query: { projectId },
        },
      });
    }
  }, [fetchSourcePads, projectId]);

  useEffect(() => {
    const auxList = selectedPadItems.map((item) => {
      const newItem = takeOffPadItemsRes?.takeoffPadItems?.find((value) => value.lineItemId === item.lineItemId);
      if (newItem) return { ...item, remainingQuantity: newItem?.remainingQuantity };
      return { ...item };
    });
    setSelectedPadItems(auxList);
    refetchTakeOffPadItemsCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [takeOffPadItemsRes]);

  useEffect(() => {
    if (projectId) {
      fetchTakeOffPads({
        variables: {
          query: { projectId },
        },
      });
    }
  }, [fetchTakeOffPads, projectId]);

  const pads = useMemo(() => {
    const formattedSourcePads = sourcePads.map((sourcePad) => ({
      isSourcePad: true,
      createdOn: sourcePad.createdOn,
      [PAD_ID]: sourcePad?.sourcePadId,
      [PAD_LABEL_NAME]: sourcePad?.sourcePadName,
    }));

    const mergePads = [...formattedSourcePads, ...takeOffPads].map((pad, index) => ({
      ...pad,
      index,
    }));
    return mergePads;
  }, [sourcePads, takeOffPads]);

  const padItems = useMemo(() => {
    const activePad = pads[padIndex];
    if (!activePad) return [];

    return activePad.isSourcePad ? sourcePadItemsRes?.sourcePadItems : takeOffPadItemsRes?.takeoffPadItems;
  }, [padIndex, pads, sourcePadItemsRes, takeOffPadItemsRes]);

  const isPadItemsLoading = useMemo(
    () => (padItems ?? [])?.length === 0 && (!!takeOffPadItemsLoading || !!loadingSourcePadItems),
    [padItems, takeOffPadItemsLoading, loadingSourcePadItems],
  );

  const padItemsCount = useMemo(() => {
    const activePad = pads[padIndex];
    if (!activePad) return 0;

    return activePad.isSourcePad
      ? sourcePadItemsCountRes?.sourcePadItemsCount
      : takeOffPadItemsCountRes?.takeoffPadItemsCount;
  }, [padIndex, pads, sourcePadItemsCountRes, takeOffPadItemsCountRes]);

  const parametersQuery = useMemo(() => {
    const orderByKey = 'orderBy';
    const parametersOverride = {
      [orderByKey]: 'name',
      ...parameters,
      ...filters,
    };
    return parametersOverride;
  }, [parameters, filters]);

  const fetchPadItems = useCallback(() => {
    if (lineItemDecision) return;
    const pad = pads[padIndex];
    if (!pad) return;

    const query = !pad.isSourcePad
      ? { takeOffPadId: pad.takeoffPadId, ...parametersQuery }
      : { projectId, sourcePadName: pad.takeoffPadName, ...parametersQuery };

    setCurrentQuery(query);

    if (!pad.isSourcePad) {
      fetchTakeOffPadItems(query);
      fetchTakeOffPadItemsCount({
        variables: {
          query,
        },
      });
      return;
    }
    if (pad.isSourcePad) {
      fetchSourcePadProperties({
        variables: {
          params: { sourcePadId: pad.takeoffPadId },
        },
      });
      delete query.externalSourceImportDescription;
      fetchSourcePadItems(query);
      fetchSourcePadItemsCount({
        variables: {
          query,
        },
      });
    }
  }, [
    lineItemDecision,
    pads,
    padIndex,
    parametersQuery,
    projectId,
    fetchTakeOffPadItems,
    fetchTakeOffPadItemsCount,
    fetchSourcePadProperties,
    fetchSourcePadItems,
    fetchSourcePadItemsCount,
  ]);

  useEffect(() => {
    fetchPadItems();
  }, [fetchPadItems, parametersQuery, projectId]);

  const onFetchMoreSourcePadItems = useCallback(
    (skip) => {
      const pad = pads[padIndex];
      if (pad?.isSourcePad && projectId && skip) {
        const queryParams = { ...parametersQuery };
        delete queryParams.externalSourceImportDescription;

        sourcePadItemsPaginationFn(skip, {
          projectId,
          sourcePadName: pad.takeoffPadName,
          ...queryParams,
        });
      }
    },
    [padIndex, pads, projectId, sourcePadItemsPaginationFn, parametersQuery],
  );

  const onFetchMoreTakeOffPadItems = useCallback(
    (skip) => {
      const pad = pads[padIndex];
      if (!pad?.isSourcePad && skip) {
        takeOffPadItemsPaginationFn(skip, { takeOffPadId: pad.takeoffPadId, ...parametersQuery });
      }
    },
    [padIndex, pads, takeOffPadItemsPaginationFn, parametersQuery],
  );

  useEffect(() => {
    if (!pads?.length) return;

    const isRevitPad = pads?.[padIndex]?.isSourcePad || false;
    if (isRevitPad !== isRevitSourcePad) setIsRevitSourcePad(isRevitPad);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pads, padIndex]);

  useEffect(() => {
    const currentSourcePads = sourcePads;
    const previousSourcePads = sourcePadsRef.current.slice(0);

    if (!currentSourcePads?.length) {
      return;
    }

    currentSourcePads.forEach((currentSourcePad) => {
      const prevSourcePad = previousSourcePads.find(
        (prevSourcePad) => prevSourcePad.sourcePadId === currentSourcePad.sourcePadId,
      );

      if (prevSourcePad && currentSourcePad.createdOn !== prevSourcePad.createdOn) {
        client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'sourcePadItems',
          args: {
            query: {
              projectId,
              sourcePadName: currentSourcePad.sourcePadName,
              ...parametersQuery,
            },
          },
          broadcast: false,
        });
        refetchSourcePadItems({
          projectId,
          sourcePadName: currentSourcePad.sourcePadName,
          ...parametersQuery,
        });
      }
    });
    sourcePadsRef.current = sourcePads;
  }, [sourcePads, parametersQuery, refetchSourcePadItems, client.cache, projectId]);

  const onSelectPad = useCallback((index) => {
    setPadIndex(index);
    tableRef?.current?.resetOnSelected();
  }, []);
  const onMapSourcePad = useCallback(
    async ({ takeoffPadId }) => {
      await onMapSourcePadBase({
        padIndex,
        pads,
        selectedPadItems,
        actionCallback: (padSelectedIndex) => {
          setPadIndex(padSelectedIndex);
        },
      })({ takeoffPadId });
      setSelectedPadItems([]);
      tableRef?.current?.resetOnSelected();
    },
    [onMapSourcePadBase, padIndex, pads, selectedPadItems],
  );

  useEffect(() => {
    setSourcePadsQty(sourcePads?.length);
  }, [sourcePads]);

  const onOrderby = useCallback(
    (columnName) => {
      const pad = pads[padIndex];
      if (pad?.isSourcePad && projectId) {
        sourcePadItemsOrderByFn({
          columnName,
          variables: { projectId, sourcePadName: pad?.takeoffPadName, ...parameters },
        });
      }

      if (!pad?.isSourcePad) {
        takeOffPadItemsOrderByFn({
          columnName,
          variables: { takeOffPadId: pad?.takeoffPadId, ...parameters },
        });
      }
    },
    [padIndex, pads, projectId, sourcePadItemsOrderByFn, takeOffPadItemsOrderByFn, parameters],
  );

  const refetchTakeOffPadItems = (...parameters) => {
    client.cache.evict({
      id: 'ROOT_QUERY',
      fieldName: 'takeoffPadItems',
    });
    refetchTakeOffPadItemsCallback(...parameters);
  };

  const pagination = useMemo(() => ({ onOrderby }), [onOrderby]);

  const contextValue = useMemo(
    () => ({
      projectId,
      pads,
      chipList,
      padIndex,
      padItems,
      padItemsCount,
      loadingSourcePads,
      loadingTakeOffPads,
      itemCount,
      selectedPadItems,
      openSnackbar,
      bomName,
      sourcePads,
      sourcePadProperties,
      sourcePadSortingKeyword,
      takeOffPadSortingKeyword,
      updateOpenSnackbar,
      updateItemCount,
      updateChipList,
      onSelectPad,
      padSelected: pads[padIndex],
      onFetchMoreSourcePadItems,
      onFetchMoreTakeOffPadItems,
      onAddTakeOffPad,
      onRenameTakeOffPad,
      onDeleteTakeoffPad,
      onDeleteSourcePad,
      onAddTakeOffPadItem,
      loadingAddTakeOffPadItem,
      onDeleteTakeOffPadItems,
      onAddPad,
      onMapSourcePad,
      onGetListOfTakeOffPadsByName,
      sourcePadsQty,
      pagination,
      tableRef,
      disabledDropdown,
      setDisabledDropdown,
      takeOffPads,
      isPadItemsLoading,
      parametersQuery,
      apiRef,
      activePad: pads[padIndex],
      decisionsStatesAndCallbacks,
      refetchTakeOffPadItems,
      assignModeOn,
      setAssignModeOn,
      isRevitSourcePad,
      parameters,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      chipList,
      loadingSourcePads,
      loadingTakeOffPads,
      itemCount,
      selectedPadItems,
      openSnackbar,
      bomName,
      sourcePads,
      sourcePadProperties,
      sourcePadSortingKeyword,
      takeOffPadSortingKeyword,
      updateOpenSnackbar,
      updateItemCount,
      onAddTakeOffPad,
      onDeleteTakeoffPad,
      onDeleteSourcePad,
      onFetchMoreSourcePadItems,
      onFetchMoreTakeOffPadItems,
      onMapSourcePad,
      onRenameTakeOffPad,
      onSelectPad,
      onGetListOfTakeOffPadsByName,
      padIndex,
      padItems,
      padItemsCount,
      pads,
      projectId,
      updateChipList,
      onAddTakeOffPadItem,
      loadingAddTakeOffPadItem,
      sourcePadsQty,
      pagination,
      tableRef,
      disabledDropdown,
      setDisabledDropdown,
      takeOffPads,
      onAddPad,
      isPadItemsLoading,
      parametersQuery,
      apiRef,
      isRevitSourcePad,
      parameters,
    ],
  );

  return <TakeOffContext.Provider value={contextValue}>{children}</TakeOffContext.Provider>;
};

export const useTakeOff = () => {
  const context = useContext(TakeOffContext);
  if (context === undefined) {
    throw new Error('useTakeOff must be used within a TakeOffProvider');
  }

  return context;
};

export default TakeOffContext;
