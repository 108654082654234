/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const departmentTypes = /* GraphQL */ `
  query DepartmentTypes {
    departmentTypes {
      departmentTypeId
      departmentTypeName
    }
  }
`;
export const navigationModules = /* GraphQL */ `
  query NavigationModules {
    navigationModules {
      navigationModuleId
      navigationModuleName
      navigationModuleCode
      sortOrder
      navigationModuleSets {
        navigationModuleSetId
        navigationModuleId
        navigationSetId
        navigationSet {
          navigationSetId
          navigationSetName
          isMenu
          icon
          sortOrder
          navigationSetItems {
            navigationSetItemId
            navigationSetId
            navigationItemId
            navigationItem {
              navigationItemId
              navigationItemName
              icon
              routePath
              iconPath
            }
            sortOrder
          }
        }
        sortOrder
        isLastInGroup
      }
    }
  }
`;
export const partAttributeTypes = /* GraphQL */ `
  query PartAttributeTypes {
    partAttributeTypes {
      partAttributeTypeId
      partAttributeTypeName
      partAttributeTypeDescription
      sortOrder
    }
  }
`;
export const partAttributePredefinedNames = /* GraphQL */ `
  query PartAttributePredefinedNames {
    partAttributePredefinedNames {
      partAttributePredefinedNameId
      predefinedAttributeName
    }
  }
`;
export const licenseTypes = /* GraphQL */ `
  query LicenseTypes {
    licenseTypes {
      licenseTypeId
      licenseTypeName
      licenseTypeCode
      sortOrder
    }
  }
`;
export const latestEula = /* GraphQL */ `
  query LatestEula {
    latestEula {
      eulaId
      eulaVersion
      eulaTimeStamp
      content
    }
  }
`;
export const workRequestsStatusType = /* GraphQL */ `
  query WorkRequestsStatusType {
    workRequestsStatusType {
      workRequestStatusTypeId
      workRequestStatusTypeName
    }
  }
`;
export const workRequestItemType = /* GraphQL */ `
  query WorkRequestItemType {
    workRequestItemType {
      workRequestItemTypeId
      workRequestItemTypeName
    }
  }
`;
export const locationTypes = /* GraphQL */ `
  query LocationTypes {
    locationTypes {
      locationTypeId
      locationTypeName
    }
  }
`;
export const locationFormats = /* GraphQL */ `
  query LocationFormats {
    locationFormats {
      locationFormatId
      locationFormatName
    }
  }
`;
export const lineItemTypes = /* GraphQL */ `
  query LineItemTypes {
    lineItemTypes {
      lineItemTypeId
      lineItemTypeName
    }
  }
`;
export const messageTypes = /* GraphQL */ `
  query MessageTypes {
    messageTypes {
      messageTypeId
      messageTypeName
    }
  }
`;
export const messagePriorities = /* GraphQL */ `
  query MessagePriorities {
    messagePriorities {
      messagePriorityId
      priorityName
      priorityCode
      sortOrder
      createdOn
      createdBy
    }
  }
`;
export const billOfMaterialsStatuses = /* GraphQL */ `
  query BillOfMaterialsStatuses {
    billOfMaterialsStatuses {
      billOfMaterialsStatusId
      billOfMaterialsStatusName
    }
  }
`;
export const tasktypeClasses = /* GraphQL */ `
  query TasktypeClasses {
    tasktypeClasses {
      taskTypeClassId
      taskTypeClassName
    }
  }
`;
export const unitOfMeasureTypes = /* GraphQL */ `
  query UnitOfMeasureTypes {
    unitOfMeasureTypes {
      unitOfMeasureTypeId
      unitOfMeasureTypeName
      unitOfMeasureCode
    }
  }
`;
export const licenseClass = /* GraphQL */ `
  query LicenseClass {
    licenseClass {
      licenseClassId
      licenseClassName
      sortOrder
    }
  }
`;
export const workOrderStatusesList = /* GraphQL */ `
  query WorkOrderStatusesList {
    workOrderStatusesList {
      workOrderStatusTypeId
      workOrderStatusTypeName
    }
  }
`;
export const syncConnectorsList = /* GraphQL */ `
  query SyncConnectorsList {
    syncConnectorsList {
      name
      url
      logoutUrl
      realTimeSync
      isLicensed
    }
  }
`;
export const getSupportedExtensions = /* GraphQL */ `
  query GetSupportedExtensions {
    getSupportedExtensions {
      type
      extensions
    }
  }
`;
export const taskStatusTypes = /* GraphQL */ `
  query TaskStatusTypes {
    taskStatusTypes {
      taskStatusTypeId
      taskStatusTypeName
      createdOn
      createdBy
      updatedOn
      updatedBy
      deletedOn
      deletedBy
    }
  }
`;
export const syncGetAllWorkflowMappingTemplates = /* GraphQL */ `
  query SyncGetAllWorkflowMappingTemplates {
    syncGetAllWorkflowMappingTemplates {
      templateId
      templateName
    }
  }
`;
export const drawingTypeCategories = /* GraphQL */ `
  query DrawingTypeCategories {
    drawingTypeCategories {
      companyId
      userDefinedId
      name
      hasDrawingTypes
      id
      createdBy
      createdOnUtc
      modifiedBy
      modifiedOnUtc
    }
  }
`;
export const documentTypes = /* GraphQL */ `
  query DocumentTypes {
    documentTypes {
      documentTypeId
      documentTypeName
    }
  }
`;
export const assemblyNodeType = /* GraphQL */ `
  query AssemblyNodeType {
    assemblyNodeType {
      assemblyNodeTypeId
      assemblyNodeTypeName
    }
  }
`;
export const assemblyEdgeType = /* GraphQL */ `
  query AssemblyEdgeType {
    assemblyEdgeType {
      assemblyEdgeTypeId
      assemblyEdgeTypeName
    }
  }
`;
export const syncLogsDataUsageReport = /* GraphQL */ `
  query SyncLogsDataUsageReport {
    syncLogsDataUsageReport {
      monthlyDataUsage
      yearlyDataUsage
    }
  }
`;
export const userStatus = /* GraphQL */ `
  query UserStatus($params: QueryUserStatusParamsInput!) {
    userStatus(params: $params) {
      userEmail
      userName
      userStatus
    }
  }
`;
export const userStatusById = /* GraphQL */ `
  query UserStatusById($params: QueryUserStatusByIdParamsInput!) {
    userStatusById(params: $params) {
      userEmail
      userName
      userStatus
    }
  }
`;
export const userByName = /* GraphQL */ `
  query UserByName($params: QueryUserByNameParamsInput!) {
    userByName(params: $params) {
      userId
      userName
      userFirstName
      userLastName
      userIdentifier
      userEmail
      workPhoneNumber
      personalPhoneNumber
      userStatus
      userStatusId
      inviteStatus
      userTypes {
        userTypeId
        typeName
        typeId
      }
      acceptedLatestEula
      companyId
      isAdmin
      isDeleted
      userPhotoId
    }
  }
`;
export const userById = /* GraphQL */ `
  query UserById($params: QueryUserByIdParamsInput!) {
    userById(params: $params) {
      userId
      userName
      userFirstName
      userLastName
      userIdentifier
      userEmail
      workPhoneNumber
      personalPhoneNumber
      userStatus
      userStatusId
      inviteStatus
      userTypes {
        userTypeId
        typeName
        typeId
      }
      acceptedLatestEula
      companyId
      isAdmin
      isDeleted
      userPhotoId
    }
  }
`;
export const users = /* GraphQL */ `
  query Users($query: QueryUsersQueryInput) {
    users(query: $query) {
      userId
      userName
      userFirstName
      userLastName
      userIdentifier
      userEmail
      workPhoneNumber
      personalPhoneNumber
      userStatus
      userStatusId
      inviteStatus
      userTypes {
        userTypeId
        typeName
        typeId
      }
      acceptedLatestEula
      companyId
      isAdmin
      isDeleted
      userPhotoId
    }
  }
`;
export const projectUsers = /* GraphQL */ `
  query ProjectUsers($query: QueryProjectUsersQueryInput!) {
    projectUsers(query: $query) {
      projectUserId
      projectId
      inviteStatus
      expiresAt
      isAdmin
      isDeleted
      user {
        userId
        userName
        userFirstName
        userLastName
        userIdentifier
        userEmail
        workPhoneNumber
        personalPhoneNumber
        userStatus
        userStatusId
        inviteStatus
        userTypes {
          userTypeId
          typeName
          typeId
        }
        acceptedLatestEula
        companyId
        isAdmin
        isDeleted
        userPhotoId
      }
    }
  }
`;
export const projectTypes = /* GraphQL */ `
  query ProjectTypes($query: QueryProjectTypesQueryInput!) {
    projectTypes(query: $query) {
      projectTypeId
      projectTypeName
      companyId
    }
  }
`;
export const projects = /* GraphQL */ `
  query Projects($query: QueryProjectsQueryInput!) {
    projects(query: $query) {
      projectId
      projectName
      projectIdentifier
      dataAnalytics
      units
      companyId
      address {
        addressId
        addressLine1
        addressLine2
        city
        state
        country
        zip
      }
      projectImageId
      disallowAutomaticDistributionQty
      projectType {
        projectTypeId
        projectTypeName
        companyId
      }
    }
  }
`;
export const projectsMultiSearch = /* GraphQL */ `
  query ProjectsMultiSearch($query: QueryProjectsMultiSearchQueryInput!) {
    projectsMultiSearch(query: $query) {
      projectId
      projectName
      projectIdentifier
      dataAnalytics
      units
      companyId
      address {
        addressId
        addressLine1
        addressLine2
        city
        state
        country
        zip
      }
      projectImageId
      disallowAutomaticDistributionQty
      projectType {
        projectTypeId
        projectTypeName
        companyId
      }
    }
  }
`;
export const projectsFromDepartmentUsers = /* GraphQL */ `
  query ProjectsFromDepartmentUsers(
    $query: QueryProjectsFromDepartmentUsersQueryInput!
  ) {
    projectsFromDepartmentUsers(query: $query) {
      projectId
      projectName
      projectIdentifier
      dataAnalytics
      units
      companyId
      address {
        addressId
        addressLine1
        addressLine2
        city
        state
        country
        zip
      }
      projectImageId
      disallowAutomaticDistributionQty
      projectType {
        projectTypeId
        projectTypeName
        companyId
      }
    }
  }
`;
export const projectById = /* GraphQL */ `
  query ProjectById($params: QueryProjectByIdParamsInput!) {
    projectById(params: $params) {
      projectId
      projectName
      projectIdentifier
      dataAnalytics
      units
      companyId
      address {
        addressId
        addressLine1
        addressLine2
        city
        state
        country
        zip
      }
      projectImageId
      departments {
        departmentId
        departmentName
        departmentTypeId
        facility {
          facilityId
          facilityName
        }
      }
      disallowAutomaticDistributionQty
      projectType {
        projectTypeId
        projectTypeName
        companyId
      }
    }
  }
`;
export const product = /* GraphQL */ `
  query Product($params: QueryProductParamsInput!) {
    product(params: $params) {
      productId
      productName
      baseProductName
      isForesiteModule
      licenseTypeId
      licenseTypeName
      licenseClassId
      licenseClassName
      productNameFormatted
      onlyVisibleToMembers
      supportsTrial
      productLicenseTypes {
        productLicenseTypeId
        productId
        licenseTypeId
        licenseType {
          licenseTypeId
          licenseTypeName
          licenseTypeCode
          sortOrder
        }
      }
    }
  }
`;
export const products = /* GraphQL */ `
  query Products($query: QueryProductsQueryInput) {
    products(query: $query) {
      productId
      productName
      baseProductName
      isForesiteModule
      licenseTypeId
      licenseTypeName
      licenseClassId
      licenseClassName
      productNameFormatted
      onlyVisibleToMembers
      supportsTrial
      productLicenseTypes {
        productLicenseTypeId
        productId
        licenseTypeId
        licenseType {
          licenseTypeId
          licenseTypeName
          licenseTypeCode
          sortOrder
        }
      }
    }
  }
`;
export const bundles = /* GraphQL */ `
  query Bundles($query: QueryBundlesQueryInput) {
    bundles(query: $query) {
      bundleId
      bundleName
      bundleProducts {
        bundleProductId
        productId
        product {
          productId
          productName
          baseProductName
          isForesiteModule
          licenseTypeId
          licenseTypeName
          licenseClassId
          licenseClassName
          productNameFormatted
          onlyVisibleToMembers
          supportsTrial
          productLicenseTypes {
            productLicenseTypeId
            productId
            licenseTypeId
            licenseType {
              licenseTypeId
              licenseTypeName
              licenseTypeCode
              sortOrder
            }
          }
        }
      }
    }
  }
`;
export const productPoolById = /* GraphQL */ `
  query ProductPoolById($params: QueryProductPoolByIdParamsInput!) {
    productPoolById(params: $params) {
      productPoolId
      poolQuantity
      productId
      bundleId
      type
      label
      poolAvailableQuantity
      renewalDate
      customData
      termDate
      startDate
      trialRenewalDate
      company {
        companyId
        companyName
        renewalDate
        mfA_Required
        data_Analytics
        companyDownloadPaths {
          document
          updateBy
          updatedOn
        }
        companyImageId
        address {
          addressId
          addressLine1
          addressLine2
          city
          state
          country
          zip
        }
        productPool {
          productPoolId
          poolQuantity
          productId
          bundleId
          type
          label
          poolAvailableQuantity
          renewalDate
          customData
          termDate
          startDate
          trialRenewalDate
          company {
            companyId
            companyName
            renewalDate
            mfA_Required
            data_Analytics
            companyDownloadPaths {
              document
              updateBy
              updatedOn
            }
            companyImageId
            address {
              addressId
              addressLine1
              addressLine2
              city
              state
              country
              zip
            }
            productPool {
              productPoolId
              poolQuantity
              productId
              bundleId
              type
              label
              poolAvailableQuantity
              renewalDate
              customData
              termDate
              startDate
              trialRenewalDate
              company {
                companyId
                companyName
                renewalDate
                mfA_Required
                data_Analytics
                companyImageId
              }
              product {
                productId
                productName
                baseProductName
                isForesiteModule
                licenseTypeId
                licenseTypeName
                licenseClassId
                licenseClassName
                productNameFormatted
                onlyVisibleToMembers
                supportsTrial
              }
            }
          }
          product {
            productId
            productName
            baseProductName
            isForesiteModule
            licenseTypeId
            licenseTypeName
            licenseClassId
            licenseClassName
            productNameFormatted
            onlyVisibleToMembers
            supportsTrial
            productLicenseTypes {
              productLicenseTypeId
              productId
              licenseTypeId
              licenseType {
                licenseTypeId
                licenseTypeName
                licenseTypeCode
                sortOrder
              }
            }
          }
        }
      }
      product {
        productId
        productName
        baseProductName
        isForesiteModule
        licenseTypeId
        licenseTypeName
        licenseClassId
        licenseClassName
        productNameFormatted
        onlyVisibleToMembers
        supportsTrial
        productLicenseTypes {
          productLicenseTypeId
          productId
          licenseTypeId
          licenseType {
            licenseTypeId
            licenseTypeName
            licenseTypeCode
            sortOrder
          }
        }
      }
    }
  }
`;
export const productPools = /* GraphQL */ `
  query ProductPools($query: QueryProductPoolsQueryInput!) {
    productPools(query: $query) {
      productPoolId
      poolQuantity
      productId
      bundleId
      type
      label
      poolAvailableQuantity
      renewalDate
      customData
      termDate
      startDate
      trialRenewalDate
      company {
        companyId
        companyName
        renewalDate
        mfA_Required
        data_Analytics
        companyDownloadPaths {
          document
          updateBy
          updatedOn
        }
        companyImageId
        address {
          addressId
          addressLine1
          addressLine2
          city
          state
          country
          zip
        }
        productPool {
          productPoolId
          poolQuantity
          productId
          bundleId
          type
          label
          poolAvailableQuantity
          renewalDate
          customData
          termDate
          startDate
          trialRenewalDate
          company {
            companyId
            companyName
            renewalDate
            mfA_Required
            data_Analytics
            companyDownloadPaths {
              document
              updateBy
              updatedOn
            }
            companyImageId
            address {
              addressId
              addressLine1
              addressLine2
              city
              state
              country
              zip
            }
            productPool {
              productPoolId
              poolQuantity
              productId
              bundleId
              type
              label
              poolAvailableQuantity
              renewalDate
              customData
              termDate
              startDate
              trialRenewalDate
              company {
                companyId
                companyName
                renewalDate
                mfA_Required
                data_Analytics
                companyImageId
              }
              product {
                productId
                productName
                baseProductName
                isForesiteModule
                licenseTypeId
                licenseTypeName
                licenseClassId
                licenseClassName
                productNameFormatted
                onlyVisibleToMembers
                supportsTrial
              }
            }
          }
          product {
            productId
            productName
            baseProductName
            isForesiteModule
            licenseTypeId
            licenseTypeName
            licenseClassId
            licenseClassName
            productNameFormatted
            onlyVisibleToMembers
            supportsTrial
            productLicenseTypes {
              productLicenseTypeId
              productId
              licenseTypeId
              licenseType {
                licenseTypeId
                licenseTypeName
                licenseTypeCode
                sortOrder
              }
            }
          }
        }
      }
      product {
        productId
        productName
        baseProductName
        isForesiteModule
        licenseTypeId
        licenseTypeName
        licenseClassId
        licenseClassName
        productNameFormatted
        onlyVisibleToMembers
        supportsTrial
        productLicenseTypes {
          productLicenseTypeId
          productId
          licenseTypeId
          licenseType {
            licenseTypeId
            licenseTypeName
            licenseTypeCode
            sortOrder
          }
        }
      }
    }
  }
`;
export const facilities = /* GraphQL */ `
  query Facilities($query: QueryFacilitiesQueryInput!) {
    facilities(query: $query) {
      facilityId
      facilityName
      companyId
      address {
        addressId
        addressLine1
        addressLine2
        city
        state
        country
        zip
      }
      departments {
        departmentId
        departmentName
        departmentTypeId
        facility {
          facilityId
          facilityName
        }
        departmentType {
          departmentTypeId
          departmentTypeName
        }
        adminUser {
          userId
          userName
          userFirstName
          userLastName
          userIdentifier
          userEmail
          workPhoneNumber
          personalPhoneNumber
          userStatus
          userStatusId
          inviteStatus
          userTypes {
            userTypeId
            typeName
            typeId
          }
          acceptedLatestEula
          companyId
          isAdmin
          isDeleted
          userPhotoId
        }
      }
    }
  }
`;
export const facilityById = /* GraphQL */ `
  query FacilityById($params: QueryFacilityByIdParamsInput!) {
    facilityById(params: $params) {
      facilityId
      facilityName
      companyId
      address {
        addressId
        addressLine1
        addressLine2
        city
        state
        country
        zip
      }
      departments {
        departmentId
        departmentName
        departmentTypeId
        facility {
          facilityId
          facilityName
        }
        departmentType {
          departmentTypeId
          departmentTypeName
        }
        adminUser {
          userId
          userName
          userFirstName
          userLastName
          userIdentifier
          userEmail
          workPhoneNumber
          personalPhoneNumber
          userStatus
          userStatusId
          inviteStatus
          userTypes {
            userTypeId
            typeName
            typeId
          }
          acceptedLatestEula
          companyId
          isAdmin
          isDeleted
          userPhotoId
        }
      }
    }
  }
`;
export const entitlements = /* GraphQL */ `
  query Entitlements($query: QueryEntitlementsQueryInput) {
    entitlements(query: $query) {
      entitlementId
      userId
      isCheckedOut
      checkedOutEndDate
      secureData
      secureSignature
      licenseTypeCode
      productPool {
        productPoolId
        poolQuantity
        productId
        bundleId
        type
        label
        poolAvailableQuantity
        renewalDate
        customData
        termDate
        startDate
        trialRenewalDate
        company {
          companyId
          companyName
          renewalDate
          mfA_Required
          data_Analytics
          companyDownloadPaths {
            document
            updateBy
            updatedOn
          }
          companyImageId
          address {
            addressId
            addressLine1
            addressLine2
            city
            state
            country
            zip
          }
          productPool {
            productPoolId
            poolQuantity
            productId
            bundleId
            type
            label
            poolAvailableQuantity
            renewalDate
            customData
            termDate
            startDate
            trialRenewalDate
            company {
              companyId
              companyName
              renewalDate
              mfA_Required
              data_Analytics
              companyDownloadPaths {
                document
                updateBy
                updatedOn
              }
              companyImageId
              address {
                addressId
                addressLine1
                addressLine2
                city
                state
                country
                zip
              }
              productPool {
                productPoolId
                poolQuantity
                productId
                bundleId
                type
                label
                poolAvailableQuantity
                renewalDate
                customData
                termDate
                startDate
                trialRenewalDate
              }
            }
            product {
              productId
              productName
              baseProductName
              isForesiteModule
              licenseTypeId
              licenseTypeName
              licenseClassId
              licenseClassName
              productNameFormatted
              onlyVisibleToMembers
              supportsTrial
              productLicenseTypes {
                productLicenseTypeId
                productId
                licenseTypeId
              }
            }
          }
        }
        product {
          productId
          productName
          baseProductName
          isForesiteModule
          licenseTypeId
          licenseTypeName
          licenseClassId
          licenseClassName
          productNameFormatted
          onlyVisibleToMembers
          supportsTrial
          productLicenseTypes {
            productLicenseTypeId
            productId
            licenseTypeId
            licenseType {
              licenseTypeId
              licenseTypeName
              licenseTypeCode
              sortOrder
            }
          }
        }
      }
    }
  }
`;
export const departments = /* GraphQL */ `
  query Departments($query: QueryDepartmentsQueryInput!) {
    departments(query: $query) {
      departmentId
      departmentName
      departmentTypeId
      facility {
        facilityId
        facilityName
      }
      departmentType {
        departmentTypeId
        departmentTypeName
      }
      adminUser {
        userId
        userName
        userFirstName
        userLastName
        userIdentifier
        userEmail
        workPhoneNumber
        personalPhoneNumber
        userStatus
        userStatusId
        inviteStatus
        userTypes {
          userTypeId
          typeName
          typeId
        }
        acceptedLatestEula
        companyId
        isAdmin
        isDeleted
        userPhotoId
      }
    }
  }
`;
export const departmentUsers = /* GraphQL */ `
  query DepartmentUsers($query: QueryDepartmentUsersQueryInput!) {
    departmentUsers(query: $query) {
      departmentUserId
      departmentId
      expiresAt
      inviteStatus
      isDeleted
      isAdmin
      user {
        userId
        userName
        userFirstName
        userLastName
        userIdentifier
        userEmail
        workPhoneNumber
        personalPhoneNumber
        userStatus
        userStatusId
        inviteStatus
        userTypes {
          userTypeId
          typeName
          typeId
        }
        acceptedLatestEula
        companyId
        isAdmin
        isDeleted
        userPhotoId
      }
    }
  }
`;
export const companies = /* GraphQL */ `
  query Companies($query: QueryCompaniesQueryInput) {
    companies(query: $query) {
      companyId
      companyName
      renewalDate
      mfA_Required
      data_Analytics
      companyDownloadPaths {
        document
        updateBy
        updatedOn
      }
      companyImageId
      address {
        addressId
        addressLine1
        addressLine2
        city
        state
        country
        zip
      }
      productPool {
        productPoolId
        poolQuantity
        productId
        bundleId
        type
        label
        poolAvailableQuantity
        renewalDate
        customData
        termDate
        startDate
        trialRenewalDate
        company {
          companyId
          companyName
          renewalDate
          mfA_Required
          data_Analytics
          companyDownloadPaths {
            document
            updateBy
            updatedOn
          }
          companyImageId
          address {
            addressId
            addressLine1
            addressLine2
            city
            state
            country
            zip
          }
          productPool {
            productPoolId
            poolQuantity
            productId
            bundleId
            type
            label
            poolAvailableQuantity
            renewalDate
            customData
            termDate
            startDate
            trialRenewalDate
            company {
              companyId
              companyName
              renewalDate
              mfA_Required
              data_Analytics
              companyDownloadPaths {
                document
                updateBy
                updatedOn
              }
              companyImageId
              address {
                addressId
                addressLine1
                addressLine2
                city
                state
                country
                zip
              }
              productPool {
                productPoolId
                poolQuantity
                productId
                bundleId
                type
                label
                poolAvailableQuantity
                renewalDate
                customData
                termDate
                startDate
                trialRenewalDate
              }
            }
            product {
              productId
              productName
              baseProductName
              isForesiteModule
              licenseTypeId
              licenseTypeName
              licenseClassId
              licenseClassName
              productNameFormatted
              onlyVisibleToMembers
              supportsTrial
              productLicenseTypes {
                productLicenseTypeId
                productId
                licenseTypeId
              }
            }
          }
        }
        product {
          productId
          productName
          baseProductName
          isForesiteModule
          licenseTypeId
          licenseTypeName
          licenseClassId
          licenseClassName
          productNameFormatted
          onlyVisibleToMembers
          supportsTrial
          productLicenseTypes {
            productLicenseTypeId
            productId
            licenseTypeId
            licenseType {
              licenseTypeId
              licenseTypeName
              licenseTypeCode
              sortOrder
            }
          }
        }
      }
    }
  }
`;
export const companyById = /* GraphQL */ `
  query CompanyById($params: QueryCompanyByIdParamsInput!) {
    companyById(params: $params) {
      companyId
      companyName
      renewalDate
      mfA_Required
      data_Analytics
      companyDownloadPaths {
        document
        updateBy
        updatedOn
      }
      companyImageId
      address {
        addressId
        addressLine1
        addressLine2
        city
        state
        country
        zip
      }
      productPool {
        productPoolId
        poolQuantity
        productId
        bundleId
        type
        label
        poolAvailableQuantity
        renewalDate
        customData
        termDate
        startDate
        trialRenewalDate
        company {
          companyId
          companyName
          renewalDate
          mfA_Required
          data_Analytics
          companyDownloadPaths {
            document
            updateBy
            updatedOn
          }
          companyImageId
          address {
            addressId
            addressLine1
            addressLine2
            city
            state
            country
            zip
          }
          productPool {
            productPoolId
            poolQuantity
            productId
            bundleId
            type
            label
            poolAvailableQuantity
            renewalDate
            customData
            termDate
            startDate
            trialRenewalDate
            company {
              companyId
              companyName
              renewalDate
              mfA_Required
              data_Analytics
              companyDownloadPaths {
                document
                updateBy
                updatedOn
              }
              companyImageId
              address {
                addressId
                addressLine1
                addressLine2
                city
                state
                country
                zip
              }
              productPool {
                productPoolId
                poolQuantity
                productId
                bundleId
                type
                label
                poolAvailableQuantity
                renewalDate
                customData
                termDate
                startDate
                trialRenewalDate
              }
            }
            product {
              productId
              productName
              baseProductName
              isForesiteModule
              licenseTypeId
              licenseTypeName
              licenseClassId
              licenseClassName
              productNameFormatted
              onlyVisibleToMembers
              supportsTrial
              productLicenseTypes {
                productLicenseTypeId
                productId
                licenseTypeId
              }
            }
          }
        }
        product {
          productId
          productName
          baseProductName
          isForesiteModule
          licenseTypeId
          licenseTypeName
          licenseClassId
          licenseClassName
          productNameFormatted
          onlyVisibleToMembers
          supportsTrial
          productLicenseTypes {
            productLicenseTypeId
            productId
            licenseTypeId
            licenseType {
              licenseTypeId
              licenseTypeName
              licenseTypeCode
              sortOrder
            }
          }
        }
      }
    }
  }
`;
export const companyProductPool = /* GraphQL */ `
  query CompanyProductPool(
    $params: QueryCompanyProductPoolParamsInput!
    $query: QueryCompanyProductPoolQueryInput
  ) {
    companyProductPool(params: $params, query: $query) {
      companyId
      address
      renewalDate
      productPool {
        productPoolId
        poolQuantity
        productId
        bundleId
        type
        label
        poolAvailableQuantity
        renewalDate
        customData
        termDate
        startDate
        trialRenewalDate
        company {
          companyId
          companyName
          renewalDate
          mfA_Required
          data_Analytics
          companyDownloadPaths {
            document
            updateBy
            updatedOn
          }
          companyImageId
          address {
            addressId
            addressLine1
            addressLine2
            city
            state
            country
            zip
          }
          productPool {
            productPoolId
            poolQuantity
            productId
            bundleId
            type
            label
            poolAvailableQuantity
            renewalDate
            customData
            termDate
            startDate
            trialRenewalDate
            company {
              companyId
              companyName
              renewalDate
              mfA_Required
              data_Analytics
              companyDownloadPaths {
                document
                updateBy
                updatedOn
              }
              companyImageId
              address {
                addressId
                addressLine1
                addressLine2
                city
                state
                country
                zip
              }
              productPool {
                productPoolId
                poolQuantity
                productId
                bundleId
                type
                label
                poolAvailableQuantity
                renewalDate
                customData
                termDate
                startDate
                trialRenewalDate
              }
            }
            product {
              productId
              productName
              baseProductName
              isForesiteModule
              licenseTypeId
              licenseTypeName
              licenseClassId
              licenseClassName
              productNameFormatted
              onlyVisibleToMembers
              supportsTrial
              productLicenseTypes {
                productLicenseTypeId
                productId
                licenseTypeId
              }
            }
          }
        }
        product {
          productId
          productName
          baseProductName
          isForesiteModule
          licenseTypeId
          licenseTypeName
          licenseClassId
          licenseClassName
          productNameFormatted
          onlyVisibleToMembers
          supportsTrial
          productLicenseTypes {
            productLicenseTypeId
            productId
            licenseTypeId
            licenseType {
              licenseTypeId
              licenseTypeName
              licenseTypeCode
              sortOrder
            }
          }
        }
      }
    }
  }
`;
export const navigationModuleById = /* GraphQL */ `
  query NavigationModuleById($params: QueryNavigationModuleByIdParamsInput!) {
    navigationModuleById(params: $params) {
      navigationModuleId
      navigationModuleName
      navigationModuleCode
      sortOrder
      navigationModuleSets {
        navigationModuleSetId
        navigationModuleId
        navigationSetId
        navigationSet {
          navigationSetId
          navigationSetName
          isMenu
          icon
          sortOrder
          navigationSetItems {
            navigationSetItemId
            navigationSetId
            navigationItemId
            navigationItem {
              navigationItemId
              navigationItemName
              icon
              routePath
              iconPath
            }
            sortOrder
          }
        }
        sortOrder
        isLastInGroup
      }
    }
  }
`;
export const partCatalogs = /* GraphQL */ `
  query PartCatalogs($query: QueryPartCatalogsQueryInput!) {
    partCatalogs(query: $query) {
      partCatalogId
      partCatalogName
      companyId
      topLevelNodes {
        partCategoryId
        partCategoryName
        partCatalog {
          partCatalogId
          partCatalogName
          companyId
          topLevelNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        parentPartCategory {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        subNodes {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
      }
    }
  }
`;
export const partCatalogById = /* GraphQL */ `
  query PartCatalogById($params: QueryPartCatalogByIdParamsInput!) {
    partCatalogById(params: $params) {
      partCatalogId
      partCatalogName
      companyId
      topLevelNodes {
        partCategoryId
        partCategoryName
        partCatalog {
          partCatalogId
          partCatalogName
          companyId
          topLevelNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        parentPartCategory {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        subNodes {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
      }
    }
  }
`;
export const partCategory = /* GraphQL */ `
  query PartCategory($query: QueryPartCategoryQueryInput!) {
    partCategory(query: $query) {
      partCategoryId
      partCategoryName
      partCatalog {
        partCatalogId
        partCatalogName
        companyId
        topLevelNodes {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
      }
      parentPartCategory {
        partCategoryId
        partCategoryName
        partCatalog {
          partCatalogId
          partCatalogName
          companyId
          topLevelNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        parentPartCategory {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        subNodes {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
      }
      subNodes {
        partCategoryId
        partCategoryName
        partCatalog {
          partCatalogId
          partCatalogName
          companyId
          topLevelNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        parentPartCategory {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        subNodes {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
      }
    }
  }
`;
export const partCategoryCloudSearch = /* GraphQL */ `
  query PartCategoryCloudSearch(
    $query: QueryPartCategoryCloudSearchQueryInput!
  ) {
    partCategoryCloudSearch(query: $query) {
      categoryDepth
      categoryId
      categoryName
      categoryPath
      catalogId
      companyId
    }
  }
`;
export const partCategoryById = /* GraphQL */ `
  query PartCategoryById($params: QueryPartCategoryByIdParamsInput!) {
    partCategoryById(params: $params) {
      partCategoryId
      partCategoryName
      partCatalog {
        partCatalogId
        partCatalogName
        companyId
        topLevelNodes {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
      }
      parentPartCategory {
        partCategoryId
        partCategoryName
        partCatalog {
          partCatalogId
          partCatalogName
          companyId
          topLevelNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        parentPartCategory {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        subNodes {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
      }
      subNodes {
        partCategoryId
        partCategoryName
        partCatalog {
          partCatalogId
          partCatalogName
          companyId
          topLevelNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        parentPartCategory {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        subNodes {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
      }
    }
  }
`;
export const partAttributeById = /* GraphQL */ `
  query PartAttributeById($params: QueryPartAttributeByIdParamsInput!) {
    partAttributeById(params: $params) {
      partAttributeId
      partAttributeName
      allowWriteInVariant
      rangeMinimumValue
      rangeMaximumValue
      selectVariants {
        partAttributeSelectVariantId
        textValue
        codeValue
        isDeleted
      }
      partAttributeType {
        partAttributeTypeId
        partAttributeTypeName
        partAttributeTypeDescription
        sortOrder
      }
      partCategory {
        partCategoryId
        partCategoryName
        partCatalog {
          partCatalogId
          partCatalogName
          companyId
          topLevelNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        parentPartCategory {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        subNodes {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
      }
    }
  }
`;
export const partAttributes = /* GraphQL */ `
  query PartAttributes($query: QueryPartAttributesQueryInput) {
    partAttributes(query: $query) {
      partAttributeId
      partAttributeName
      allowWriteInVariant
      rangeMinimumValue
      rangeMaximumValue
      selectVariants {
        partAttributeSelectVariantId
        textValue
        codeValue
        isDeleted
      }
      partAttributeType {
        partAttributeTypeId
        partAttributeTypeName
        partAttributeTypeDescription
        sortOrder
      }
      partCategory {
        partCategoryId
        partCategoryName
        partCatalog {
          partCatalogId
          partCatalogName
          companyId
          topLevelNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        parentPartCategory {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        subNodes {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
      }
    }
  }
`;
export const partAttributeVariantData = /* GraphQL */ `
  query PartAttributeVariantData(
    $query: QueryPartAttributeVariantDataQueryInput
  ) {
    partAttributeVariantData(query: $query) {
      partAttributeVariantDataId
      partAttributeId
      partAttributeName
      rangeValue
      part {
        partId
        partName
        description
        stateId
        isDefault
        manufacturerCatalogNumber
        unitOfMeasureCode
        unitOfMeasureName
        upcCode
        url
        catalogUrl
        partUrl
        internalPartNumber
        createdOn
        hasAssembly
        manufacturer {
          manufacturerId
          companyId
          manufacturerName
          homePageUrl
        }
        attriubuteVariantData {
          partAttributeVariantDataId
          partAttributeId
          partAttributeName
          rangeValue
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
          partAttribute {
            partAttributeId
            partAttributeName
            allowWriteInVariant
            rangeMinimumValue
            rangeMaximumValue
            selectVariants {
              partAttributeSelectVariantId
              textValue
              codeValue
              isDeleted
            }
            partAttributeType {
              partAttributeTypeId
              partAttributeTypeName
              partAttributeTypeDescription
              sortOrder
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          selectValues {
            partAttributeSelectVariantId
            textValue
            codeValue
            isDeleted
          }
        }
        partCategory {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        partImages {
          partImageId
          sortOrder
          isDefault
          document {
            documentId
            documentName
            documentIdentifier
            documentTypeId
            storagePath
            extension
            encodedUrn
            documentStatusTypeId
            isMappedFromShop
            folderDocumentResponse {
              folderDocumentId
              folderId
              folderName
              documentId
            }
            documentNameFormatted
            createdOn
            createdBy
          }
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
        }
        documentIds
        unitOfMeasure {
          unitOfMeasureId
          unitOfMeasureTypeId
          companyId
          unitOfMeasureName
          unitOfMeasureCode
          createdOn
          createdBy
          updatedOn
          modifiedBy
          modifiedOn
        }
      }
      partAttribute {
        partAttributeId
        partAttributeName
        allowWriteInVariant
        rangeMinimumValue
        rangeMaximumValue
        selectVariants {
          partAttributeSelectVariantId
          textValue
          codeValue
          isDeleted
        }
        partAttributeType {
          partAttributeTypeId
          partAttributeTypeName
          partAttributeTypeDescription
          sortOrder
        }
        partCategory {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
      }
      selectValues {
        partAttributeSelectVariantId
        textValue
        codeValue
        isDeleted
      }
    }
  }
`;
export const getAttributeNames = /* GraphQL */ `
  query GetAttributeNames($params: QueryGetAttributeNamesParamsInput!) {
    getAttributeNames(params: $params)
  }
`;
export const workRequests = /* GraphQL */ `
  query WorkRequests($query: QueryWorkRequestsQueryInput) {
    workRequests(query: $query) {
      workRequestId
      workRequestStatusId
      workRequestStatusName
      facilityId
      projectId
      projectIdentifier
      projectName
      workRequestIdentifier
      workRequestName
      needBy
      workRequestDescription
      facilityName
      workRequestStatusDescription
      lastStatusUpdate
      numberOfAttachments
    }
  }
`;
export const workRequestsById = /* GraphQL */ `
  query WorkRequestsById(
    $params: QueryWorkRequestsByIdParamsInput!
    $query: QueryWorkRequestsByIdQueryInput
  ) {
    workRequestsById(params: $params, query: $query) {
      workRequestId
      workRequestStatusId
      workRequestStatusName
      facilityId
      projectId
      projectIdentifier
      projectName
      workRequestIdentifier
      workRequestName
      needBy
      workRequestDescription
      facilityName
      workRequestStatusDescription
      lastStatusUpdate
      numberOfAttachments
    }
  }
`;
export const workRequestsItemById = /* GraphQL */ `
  query WorkRequestsItemById($params: QueryWorkRequestsItemByIdParamsInput!) {
    workRequestsItemById(params: $params) {
      workRequestItemId
      workRequestId
      workRequestItemTypeId
      unitOfMeasureId
      billOfProcessId
      workRequestItemName
      workRequestItemStatusTypeId
      quantity
      unityQuantity
      assignedQuantity
      remainingQuantity
      hasShopTask
      createdOn
      createdBy
      lineItemId
    }
  }
`;
export const workRequestItems = /* GraphQL */ `
  query WorkRequestItems($query: QueryWorkRequestItemsQueryInput) {
    workRequestItems(query: $query) {
      workRequestItemId
      workRequestId
      workRequestItemTypeId
      unitOfMeasureId
      billOfProcessId
      workRequestItemName
      workRequestItemStatusTypeId
      quantity
      unityQuantity
      assignedQuantity
      remainingQuantity
      hasShopTask
      createdOn
      createdBy
      lineItemId
    }
  }
`;
export const workRequestDocuments = /* GraphQL */ `
  query WorkRequestDocuments($query: QueryWorkRequestDocumentsQueryInput!) {
    workRequestDocuments(query: $query) {
      workRequestDocumentId
      workRequestId
      documentId
      externalUrl
      createdOn
      updatedOn
      createdBy
    }
  }
`;
export const workOrderDocuments = /* GraphQL */ `
  query WorkOrderDocuments($query: QueryWorkOrderDocumentsQueryInput) {
    workOrderDocuments(query: $query) {
      workOrderDocumentId
      workOrderId
      documentId
      externalUrl
      createdOn
      updatedOn
      createdBy
    }
  }
`;
export const workRequestProjects = /* GraphQL */ `
  query WorkRequestProjects(
    $params: QueryWorkRequestProjectsParamsInput!
    $query: QueryWorkRequestProjectsQueryInput
  ) {
    workRequestProjects(params: $params, query: $query) {
      projectId
      projectName
      projectIdentifier
    }
  }
`;
export const scopePackage = /* GraphQL */ `
  query ScopePackage($query: QueryScopePackageQueryInput!) {
    scopePackage(query: $query) {
      projectId
      scopePackageId
      scopePackageName
      scopePackageIdentifier
      sortOrder
      hasWorkPhases
    }
  }
`;
export const workPhaseById = /* GraphQL */ `
  query WorkPhaseById($params: QueryWorkPhaseByIdParamsInput!) {
    workPhaseById(params: $params) {
      workPhaseId
      workPhaseIdentifier
      workPhaseName
      sortOrder
      scopePackageId
      scopePackageName
      projectId
      hasLocationPhases
      updatedWorkPhaseLocationPhases {
        locationId
        locationPhaseId
      }
    }
  }
`;
export const workPhases = /* GraphQL */ `
  query WorkPhases($query: QueryWorkPhasesQueryInput!) {
    workPhases(query: $query) {
      workPhaseId
      workPhaseIdentifier
      workPhaseName
      sortOrder
      scopePackageId
      scopePackageName
      projectId
      hasLocationPhases
      updatedWorkPhaseLocationPhases {
        locationId
        locationPhaseId
      }
    }
  }
`;
export const workPhasesCount = /* GraphQL */ `
  query WorkPhasesCount($query: QueryWorkPhasesCountQueryInput!) {
    workPhasesCount(query: $query)
  }
`;
export const locations = /* GraphQL */ `
  query Locations($query: QueryLocationsQueryInput!) {
    locations(query: $query) {
      locationId
      locationTypeId
      locationTypeName
      projectId
      locationIdentifier
      locationCompositeIdentifier
      sortOrder
      hasChildren
      parentLocationId
      locationName
      locationCompositeName
      locationPhases {
        locationPhaseId
        locationId
        workPhaseId
        workPhaseName
        scopePackageId
        scopePackageName
        sortOrder
      }
      childrenTypeName
      movedLocationPhases {
        workPhaseId
        locationPhaseId
      }
    }
  }
`;
export const locationById = /* GraphQL */ `
  query LocationById($params: QueryLocationByIdParamsInput!) {
    locationById(params: $params) {
      locationId
      locationTypeId
      locationTypeName
      projectId
      locationIdentifier
      locationCompositeIdentifier
      sortOrder
      hasChildren
      parentLocationId
      locationName
      locationCompositeName
      locationPhases {
        locationPhaseId
        locationId
        workPhaseId
        workPhaseName
        scopePackageId
        scopePackageName
        sortOrder
      }
      childrenTypeName
      movedLocationPhases {
        workPhaseId
        locationPhaseId
      }
    }
  }
`;
export const externalParameters = /* GraphQL */ `
  query ExternalParameters($params: QueryExternalParametersParamsInput!) {
    externalParameters(params: $params) {
      parameters {
        name
        values
      }
      sourceData
    }
  }
`;
export const billOfMaterials = /* GraphQL */ `
  query BillOfMaterials($query: QueryBillOfMaterialsQueryInput) {
    billOfMaterials(query: $query) {
      billOfMaterialsId
      billOfMaterialsStatusId
      billOfMaterialsStatusName
      projectId
      billOfMaterialsName
      createdOn
      createdBy
      modifiedOn
      modifiedBy
      createdByFirstName
      createdByLastName
      createdByEmail
      updatedOn
      updatedBy
      updatedByFirstName
      updatedByLastName
      updatedByEmail
    }
  }
`;
export const billOfMaterialItem = /* GraphQL */ `
  query BillOfMaterialItem($query: QueryBillOfMaterialItemQueryInput!) {
    billOfMaterialItem(query: $query) {
      lineItemId
      lineItemTypeId
      lineItemTypeName
      billOfMaterialsId
      unitOfMeasureId
      unitOfMeasureName
      unitOfMeasureCode
      manufacturerId
      manufacturerName
      partId
      locationId
      workPhaseId
      scopePackageId
      workPackageId
      kitPlanId
      spoolId
      lineItemName
      manufacturerCatalogNumber
      quantity
      remainingQuantity
      unitQuantity
      tradeSize
      createdOn
      createdBy
      createdByFirstName
      childLineItemContainerName
      createdByLastName
      createdByEmail
      updatedOn
      updatedBy
      updatedByFirstName
      updatedByLastName
      updatedByEmail
      numberOfOpenDecisions
      decisionsHaveVariantDeletedFromCurrentVersionOfPart
      ableToMakeDecisions
      numberOfDecisionsMade
      hasChildNodes
      parentLineItem
      projectId
      assemblyCustomCategoryId
      assemblyCustomCategoryName
      canChildLineItemBeAdded
    }
  }
`;
export const billOfMaterialItemSearch = /* GraphQL */ `
  query BillOfMaterialItemSearch(
    $params: QueryBillOfMaterialItemSearchParamsInput!
    $query: QueryBillOfMaterialItemSearchQueryInput
  ) {
    billOfMaterialItemSearch(params: $params, query: $query) {
      lineItemId
      lineItemTypeId
      lineItemTypeName
      billOfMaterialsId
      unitOfMeasureId
      unitOfMeasureName
      unitOfMeasureCode
      manufacturerId
      manufacturerName
      partId
      locationId
      workPhaseId
      scopePackageId
      workPackageId
      kitPlanId
      spoolId
      lineItemName
      manufacturerCatalogNumber
      quantity
      remainingQuantity
      unitQuantity
      tradeSize
      createdOn
      createdBy
      createdByFirstName
      childLineItemContainerName
      createdByLastName
      createdByEmail
      updatedOn
      updatedBy
      updatedByFirstName
      updatedByLastName
      updatedByEmail
      numberOfOpenDecisions
      decisionsHaveVariantDeletedFromCurrentVersionOfPart
      ableToMakeDecisions
      numberOfDecisionsMade
      hasChildNodes
      parentLineItem
      projectId
      assemblyCustomCategoryId
      assemblyCustomCategoryName
      canChildLineItemBeAdded
    }
  }
`;
export const billOfMaterialItemById = /* GraphQL */ `
  query BillOfMaterialItemById(
    $params: QueryBillOfMaterialItemByIdParamsInput!
  ) {
    billOfMaterialItemById(params: $params) {
      lineItemId
      lineItemTypeId
      lineItemTypeName
      billOfMaterialsId
      unitOfMeasureId
      unitOfMeasureName
      unitOfMeasureCode
      manufacturerId
      manufacturerName
      partId
      locationId
      workPhaseId
      scopePackageId
      workPackageId
      kitPlanId
      spoolId
      lineItemName
      manufacturerCatalogNumber
      quantity
      remainingQuantity
      unitQuantity
      tradeSize
      createdOn
      createdBy
      createdByFirstName
      childLineItemContainerName
      createdByLastName
      createdByEmail
      updatedOn
      updatedBy
      updatedByFirstName
      updatedByLastName
      updatedByEmail
      numberOfOpenDecisions
      decisionsHaveVariantDeletedFromCurrentVersionOfPart
      ableToMakeDecisions
      numberOfDecisionsMade
      hasChildNodes
      parentLineItem
      projectId
      assemblyCustomCategoryId
      assemblyCustomCategoryName
      canChildLineItemBeAdded
    }
  }
`;
export const getDecisions = /* GraphQL */ `
  query GetDecisions(
    $params: QueryGetDecisionsParamsInput!
    $query: QueryGetDecisionsQueryInput
  ) {
    getDecisions(params: $params, query: $query) {
      partAttribute {
        partAttributeId
        partAttributeName
        allowWriteInVariant
        rangeMinimumValue
        rangeMaximumValue
        selectVariants {
          partAttributeSelectVariantId
          textValue
          codeValue
          isDeleted
        }
        partAttributeType {
          partAttributeTypeId
          partAttributeTypeName
          partAttributeTypeDescription
          sortOrder
        }
        partCategory {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
      }
      partAttributeVariantData {
        partAttributeVariantDataId
        partAttributeId
        partAttributeName
        rangeValue
        part {
          partId
          partName
          description
          stateId
          isDefault
          manufacturerCatalogNumber
          unitOfMeasureCode
          unitOfMeasureName
          upcCode
          url
          catalogUrl
          partUrl
          internalPartNumber
          createdOn
          hasAssembly
          manufacturer {
            manufacturerId
            companyId
            manufacturerName
            homePageUrl
          }
          attriubuteVariantData {
            partAttributeVariantDataId
            partAttributeId
            partAttributeName
            rangeValue
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            partAttribute {
              partAttributeId
              partAttributeName
              allowWriteInVariant
              rangeMinimumValue
              rangeMaximumValue
              selectVariants {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
              partAttributeType {
                partAttributeTypeId
                partAttributeTypeName
                partAttributeTypeDescription
                sortOrder
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
            }
            selectValues {
              partAttributeSelectVariantId
              textValue
              codeValue
              isDeleted
            }
          }
          partCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          partImages {
            partImageId
            sortOrder
            isDefault
            document {
              documentId
              documentName
              documentIdentifier
              documentTypeId
              storagePath
              extension
              encodedUrn
              documentStatusTypeId
              isMappedFromShop
              folderDocumentResponse {
                folderDocumentId
                folderId
                folderName
                documentId
              }
              documentNameFormatted
              createdOn
              createdBy
            }
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
          }
          documentIds
          unitOfMeasure {
            unitOfMeasureId
            unitOfMeasureTypeId
            companyId
            unitOfMeasureName
            unitOfMeasureCode
            createdOn
            createdBy
            updatedOn
            modifiedBy
            modifiedOn
          }
        }
        partAttribute {
          partAttributeId
          partAttributeName
          allowWriteInVariant
          rangeMinimumValue
          rangeMaximumValue
          selectVariants {
            partAttributeSelectVariantId
            textValue
            codeValue
            isDeleted
          }
          partAttributeType {
            partAttributeTypeId
            partAttributeTypeName
            partAttributeTypeDescription
            sortOrder
          }
          partCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        selectValues {
          partAttributeSelectVariantId
          textValue
          codeValue
          isDeleted
        }
      }
      lineItemDecision {
        lineItemDecisionId
        partAttribute {
          partAttributeId
          partAttributeName
        }
        partAttributeSelectVariant {
          partAttributeSelectVariantId
          textValue
          codeValue
          isDeleted
        }
        customValue
        isVariantDeletedFromCurrentVersionOfPart
        allVariantsDeletedFromCurrentVersionOfPart
      }
    }
  }
`;
export const billOfMaterialLineItemByLBS = /* GraphQL */ `
  query BillOfMaterialLineItemByLBS(
    $query: QueryBillOfMaterialLineItemByLBSQueryInput
  ) {
    billOfMaterialLineItemByLBS(query: $query) {
      ern
      locationId
      workPhaseId
      amount {
        assigned {
          individual
          aggregate
        }
        available {
          individual
          aggregate
        }
      }
      parentLocationId
      parentWorkPhases
      children {
        locationId
        workPhaseId
        amount {
          assigned {
            individual
            aggregate
          }
          available {
            individual
            aggregate
          }
        }
      }
      unassignedAmount
      unassignedAmountRemaining
      totalAmount
      totalAmountRemaining
    }
  }
`;
export const quantitiesRetrieve = /* GraphQL */ `
  query QuantitiesRetrieve($query: QueryQuantitiesRetrieveQueryInput) {
    quantitiesRetrieve(query: $query) {
      ern
      resourceId
      properties {
        companyId
        projectId
      }
      amount
      amountBreakdown {
        amountId
        amount
        children {
          childAmountId
          childResourceERN
        }
      }
    }
  }
`;
export const quantitiesRetrieveByIdentifier = /* GraphQL */ `
  query QuantitiesRetrieveByIdentifier(
    $params: QueryQuantitiesRetrieveByIdentifierParamsInput!
  ) {
    quantitiesRetrieveByIdentifier(params: $params) {
      ern
      resourceId
      properties {
        companyId
        projectId
        locationId
      }
      amount
      amountRemaining
      amountBreakdown {
        amountId
        amount
        children {
          childAmountId
          childResourceERN
        }
      }
      constraints
      failedConstraints {
        constraintId
        parentResourceERN
        description
      }
    }
  }
`;
export const unitOfMeasure = /* GraphQL */ `
  query UnitOfMeasure($query: QueryUnitOfMeasureQueryInput) {
    unitOfMeasure(query: $query) {
      unitOfMeasureId
      unitOfMeasureTypeId
      companyId
      unitOfMeasureName
      unitOfMeasureCode
      createdOn
      createdBy
      updatedOn
      modifiedBy
      modifiedOn
    }
  }
`;
export const unitOfMeasureById = /* GraphQL */ `
  query UnitOfMeasureById($params: QueryUnitOfMeasureByIdParamsInput!) {
    unitOfMeasureById(params: $params) {
      unitOfMeasureId
      unitOfMeasureTypeId
      companyId
      unitOfMeasureName
      unitOfMeasureCode
      createdOn
      createdBy
      updatedOn
      modifiedBy
      modifiedOn
    }
  }
`;
export const manufactured = /* GraphQL */ `
  query Manufactured($query: QueryManufacturedQueryInput!) {
    manufactured(query: $query) {
      manufacturerId
      companyId
      manufacturerName
      homePageUrl
    }
  }
`;
export const parts = /* GraphQL */ `
  query Parts($query: QueryPartsQueryInput) {
    parts(query: $query) {
      partId
      partName
      description
      stateId
      isDefault
      manufacturerCatalogNumber
      unitOfMeasureCode
      unitOfMeasureName
      upcCode
      url
      catalogUrl
      partUrl
      internalPartNumber
      createdOn
      hasAssembly
      manufacturer {
        manufacturerId
        companyId
        manufacturerName
        homePageUrl
      }
      attriubuteVariantData {
        partAttributeVariantDataId
        partAttributeId
        partAttributeName
        rangeValue
        part {
          partId
          partName
          description
          stateId
          isDefault
          manufacturerCatalogNumber
          unitOfMeasureCode
          unitOfMeasureName
          upcCode
          url
          catalogUrl
          partUrl
          internalPartNumber
          createdOn
          hasAssembly
          manufacturer {
            manufacturerId
            companyId
            manufacturerName
            homePageUrl
          }
          attriubuteVariantData {
            partAttributeVariantDataId
            partAttributeId
            partAttributeName
            rangeValue
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            partAttribute {
              partAttributeId
              partAttributeName
              allowWriteInVariant
              rangeMinimumValue
              rangeMaximumValue
              selectVariants {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
              partAttributeType {
                partAttributeTypeId
                partAttributeTypeName
                partAttributeTypeDescription
                sortOrder
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
            }
            selectValues {
              partAttributeSelectVariantId
              textValue
              codeValue
              isDeleted
            }
          }
          partCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          partImages {
            partImageId
            sortOrder
            isDefault
            document {
              documentId
              documentName
              documentIdentifier
              documentTypeId
              storagePath
              extension
              encodedUrn
              documentStatusTypeId
              isMappedFromShop
              folderDocumentResponse {
                folderDocumentId
                folderId
                folderName
                documentId
              }
              documentNameFormatted
              createdOn
              createdBy
            }
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
          }
          documentIds
          unitOfMeasure {
            unitOfMeasureId
            unitOfMeasureTypeId
            companyId
            unitOfMeasureName
            unitOfMeasureCode
            createdOn
            createdBy
            updatedOn
            modifiedBy
            modifiedOn
          }
        }
        partAttribute {
          partAttributeId
          partAttributeName
          allowWriteInVariant
          rangeMinimumValue
          rangeMaximumValue
          selectVariants {
            partAttributeSelectVariantId
            textValue
            codeValue
            isDeleted
          }
          partAttributeType {
            partAttributeTypeId
            partAttributeTypeName
            partAttributeTypeDescription
            sortOrder
          }
          partCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        selectValues {
          partAttributeSelectVariantId
          textValue
          codeValue
          isDeleted
        }
      }
      partCategory {
        partCategoryId
        partCategoryName
        partCatalog {
          partCatalogId
          partCatalogName
          companyId
          topLevelNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        parentPartCategory {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        subNodes {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
      }
      partImages {
        partImageId
        sortOrder
        isDefault
        document {
          documentId
          documentName
          documentIdentifier
          documentTypeId
          storagePath
          extension
          encodedUrn
          documentStatusTypeId
          isMappedFromShop
          folderDocumentResponse {
            folderDocumentId
            folderId
            folderName
            documentId
          }
          documentNameFormatted
          createdOn
          createdBy
        }
        part {
          partId
          partName
          description
          stateId
          isDefault
          manufacturerCatalogNumber
          unitOfMeasureCode
          unitOfMeasureName
          upcCode
          url
          catalogUrl
          partUrl
          internalPartNumber
          createdOn
          hasAssembly
          manufacturer {
            manufacturerId
            companyId
            manufacturerName
            homePageUrl
          }
          attriubuteVariantData {
            partAttributeVariantDataId
            partAttributeId
            partAttributeName
            rangeValue
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            partAttribute {
              partAttributeId
              partAttributeName
              allowWriteInVariant
              rangeMinimumValue
              rangeMaximumValue
              selectVariants {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
              partAttributeType {
                partAttributeTypeId
                partAttributeTypeName
                partAttributeTypeDescription
                sortOrder
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
            }
            selectValues {
              partAttributeSelectVariantId
              textValue
              codeValue
              isDeleted
            }
          }
          partCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          partImages {
            partImageId
            sortOrder
            isDefault
            document {
              documentId
              documentName
              documentIdentifier
              documentTypeId
              storagePath
              extension
              encodedUrn
              documentStatusTypeId
              isMappedFromShop
              folderDocumentResponse {
                folderDocumentId
                folderId
                folderName
                documentId
              }
              documentNameFormatted
              createdOn
              createdBy
            }
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
          }
          documentIds
          unitOfMeasure {
            unitOfMeasureId
            unitOfMeasureTypeId
            companyId
            unitOfMeasureName
            unitOfMeasureCode
            createdOn
            createdBy
            updatedOn
            modifiedBy
            modifiedOn
          }
        }
      }
      documentIds
      unitOfMeasure {
        unitOfMeasureId
        unitOfMeasureTypeId
        companyId
        unitOfMeasureName
        unitOfMeasureCode
        createdOn
        createdBy
        updatedOn
        modifiedBy
        modifiedOn
      }
    }
  }
`;
export const partsCount = /* GraphQL */ `
  query PartsCount($query: QueryPartsCountQueryInput) {
    partsCount(query: $query)
  }
`;
export const partsCloudSearch = /* GraphQL */ `
  query PartsCloudSearch($query: QueryPartsCloudSearchQueryInput) {
    partsCloudSearch(query: $query) {
      partDescription
      manufacturerName
      partCategoryPath
      partName
      unitOfMeasureCode
      partHistoryRefId
      version
      partId
      partImageDocumentId
      partHistoryId
      partCatalogId
      partCategories
      catalogNumber
      hasAssembly
    }
  }
`;
export const partsCloudSearchWithCount = /* GraphQL */ `
  query PartsCloudSearchWithCount(
    $query: QueryPartsCloudSearchWithCountQueryInput
  ) {
    partsCloudSearchWithCount(query: $query) {
      items {
        partDescription
        manufacturerName
        partCategoryPath
        partName
        unitOfMeasureCode
        partHistoryRefId
        version
        partId
        partImageDocumentId
        partHistoryId
        partCatalogId
        partCategories
        catalogNumber
        hasAssembly
      }
      totalCount
    }
  }
`;
export const partsCloudSearchCount = /* GraphQL */ `
  query PartsCloudSearchCount($query: QueryPartsCloudSearchCountQueryInput) {
    partsCloudSearchCount(query: $query)
  }
`;
export const partById = /* GraphQL */ `
  query PartById($params: QueryPartByIdParamsInput!) {
    partById(params: $params) {
      partId
      partName
      description
      stateId
      isDefault
      manufacturerCatalogNumber
      unitOfMeasureCode
      unitOfMeasureName
      upcCode
      url
      catalogUrl
      partUrl
      internalPartNumber
      createdOn
      hasAssembly
      manufacturer {
        manufacturerId
        companyId
        manufacturerName
        homePageUrl
      }
      attriubuteVariantData {
        partAttributeVariantDataId
        partAttributeId
        partAttributeName
        rangeValue
        part {
          partId
          partName
          description
          stateId
          isDefault
          manufacturerCatalogNumber
          unitOfMeasureCode
          unitOfMeasureName
          upcCode
          url
          catalogUrl
          partUrl
          internalPartNumber
          createdOn
          hasAssembly
          manufacturer {
            manufacturerId
            companyId
            manufacturerName
            homePageUrl
          }
          attriubuteVariantData {
            partAttributeVariantDataId
            partAttributeId
            partAttributeName
            rangeValue
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            partAttribute {
              partAttributeId
              partAttributeName
              allowWriteInVariant
              rangeMinimumValue
              rangeMaximumValue
              selectVariants {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
              partAttributeType {
                partAttributeTypeId
                partAttributeTypeName
                partAttributeTypeDescription
                sortOrder
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
            }
            selectValues {
              partAttributeSelectVariantId
              textValue
              codeValue
              isDeleted
            }
          }
          partCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          partImages {
            partImageId
            sortOrder
            isDefault
            document {
              documentId
              documentName
              documentIdentifier
              documentTypeId
              storagePath
              extension
              encodedUrn
              documentStatusTypeId
              isMappedFromShop
              folderDocumentResponse {
                folderDocumentId
                folderId
                folderName
                documentId
              }
              documentNameFormatted
              createdOn
              createdBy
            }
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
          }
          documentIds
          unitOfMeasure {
            unitOfMeasureId
            unitOfMeasureTypeId
            companyId
            unitOfMeasureName
            unitOfMeasureCode
            createdOn
            createdBy
            updatedOn
            modifiedBy
            modifiedOn
          }
        }
        partAttribute {
          partAttributeId
          partAttributeName
          allowWriteInVariant
          rangeMinimumValue
          rangeMaximumValue
          selectVariants {
            partAttributeSelectVariantId
            textValue
            codeValue
            isDeleted
          }
          partAttributeType {
            partAttributeTypeId
            partAttributeTypeName
            partAttributeTypeDescription
            sortOrder
          }
          partCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        selectValues {
          partAttributeSelectVariantId
          textValue
          codeValue
          isDeleted
        }
      }
      partCategory {
        partCategoryId
        partCategoryName
        partCatalog {
          partCatalogId
          partCatalogName
          companyId
          topLevelNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        parentPartCategory {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        subNodes {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
      }
      partImages {
        partImageId
        sortOrder
        isDefault
        document {
          documentId
          documentName
          documentIdentifier
          documentTypeId
          storagePath
          extension
          encodedUrn
          documentStatusTypeId
          isMappedFromShop
          folderDocumentResponse {
            folderDocumentId
            folderId
            folderName
            documentId
          }
          documentNameFormatted
          createdOn
          createdBy
        }
        part {
          partId
          partName
          description
          stateId
          isDefault
          manufacturerCatalogNumber
          unitOfMeasureCode
          unitOfMeasureName
          upcCode
          url
          catalogUrl
          partUrl
          internalPartNumber
          createdOn
          hasAssembly
          manufacturer {
            manufacturerId
            companyId
            manufacturerName
            homePageUrl
          }
          attriubuteVariantData {
            partAttributeVariantDataId
            partAttributeId
            partAttributeName
            rangeValue
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            partAttribute {
              partAttributeId
              partAttributeName
              allowWriteInVariant
              rangeMinimumValue
              rangeMaximumValue
              selectVariants {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
              partAttributeType {
                partAttributeTypeId
                partAttributeTypeName
                partAttributeTypeDescription
                sortOrder
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
            }
            selectValues {
              partAttributeSelectVariantId
              textValue
              codeValue
              isDeleted
            }
          }
          partCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          partImages {
            partImageId
            sortOrder
            isDefault
            document {
              documentId
              documentName
              documentIdentifier
              documentTypeId
              storagePath
              extension
              encodedUrn
              documentStatusTypeId
              isMappedFromShop
              folderDocumentResponse {
                folderDocumentId
                folderId
                folderName
                documentId
              }
              documentNameFormatted
              createdOn
              createdBy
            }
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
          }
          documentIds
          unitOfMeasure {
            unitOfMeasureId
            unitOfMeasureTypeId
            companyId
            unitOfMeasureName
            unitOfMeasureCode
            createdOn
            createdBy
            updatedOn
            modifiedBy
            modifiedOn
          }
        }
      }
      documentIds
      unitOfMeasure {
        unitOfMeasureId
        unitOfMeasureTypeId
        companyId
        unitOfMeasureName
        unitOfMeasureCode
        createdOn
        createdBy
        updatedOn
        modifiedBy
        modifiedOn
      }
    }
  }
`;
export const messages = /* GraphQL */ `
  query Messages($query: QueryMessagesQueryInput) {
    messages(query: $query) {
      messageId
      companyId
      messagePriorityId
      fromName
      subject
      body
      messagePriorityCode
      statusId
      statusName
      accessedOn
      createdOn
    }
  }
`;
export const messagesCount = /* GraphQL */ `
  query MessagesCount($query: QueryMessagesCountQueryInput) {
    messagesCount(query: $query)
  }
`;
export const messageById = /* GraphQL */ `
  query MessageById(
    $params: QueryMessageByIdParamsInput!
    $query: QueryMessageByIdQueryInput
  ) {
    messageById(params: $params, query: $query) {
      messageId
      companyId
      messagePriorityId
      fromName
      subject
      body
      messagePriorityCode
      statusId
      statusName
      accessedOn
      createdOn
    }
  }
`;
export const takeoffPads = /* GraphQL */ `
  query TakeoffPads($query: QueryTakeoffPadsQueryInput!) {
    takeoffPads(query: $query) {
      takeoffPadId
      takeoffPadName
      lineItemContainerId
      lineItemContainerStatusId
      lineItemContainerStatusName
      projectId
      lineItemContainerName
      hasMappedItems
      hasItemsMappedFromSourcePad
      createdOn
      createdBy
      createdByFirstName
      createdByLastName
      createdByEmail
      updatedOn
      updatedBy
      updatedByFirstName
      updatedByLastName
      updatedByEmail
    }
  }
`;
export const takeoffPadItems = /* GraphQL */ `
  query TakeoffPadItems($query: QueryTakeoffPadItemsQueryInput!) {
    takeoffPadItems(query: $query) {
      lineItemId
      lineItemTypeId
      lineItemTypeName
      takeoffPadId
      unitOfMeasureId
      unitOfMeasureName
      unitOfMeasureCode
      manufacturerId
      manufacturerName
      partId
      locationId
      workPhaseId
      scopePackageId
      workPackageId
      kitPlanId
      spoolId
      lineItemName
      manufacturerCatalogNumber
      quantity
      remainingQuantity
      unitQuantity
      tradeSize
      billOfMaterialsName
      createdOn
      createdBy
      createdByFirstName
      createdByLastName
      createdByEmail
      updatedOn
      updatedBy
      updatedByFirstName
      updatedByLastName
      updatedByEmail
      numberOfOpenDecisions
      decisionsHaveVariantDeletedFromCurrentVersionOfPart
      ableToMakeDecisions
      numberOfDecisionsMade
    }
  }
`;
export const takeoffPadItemsCount = /* GraphQL */ `
  query TakeoffPadItemsCount($query: QueryTakeoffPadItemsCountQueryInput!) {
    takeoffPadItemsCount(query: $query)
  }
`;
export const taskTypes = /* GraphQL */ `
  query TaskTypes($query: QueryTaskTypesQueryInput) {
    taskTypes(query: $query) {
      taskTypeId
      taskTypeImageId
      taskTypeName
      taskTypeDescription
      taskTypeClassId
      companyId
    }
  }
`;
export const taskTypeById = /* GraphQL */ `
  query TaskTypeById($params: QueryTaskTypeByIdParamsInput!) {
    taskTypeById(params: $params) {
      taskTypeId
      taskTypeImageId
      taskTypeName
      taskTypeDescription
      taskTypeClassId
      companyId
    }
  }
`;
export const shopConfigurations = /* GraphQL */ `
  query ShopConfigurations($params: QueryShopConfigurationsParamsInput!) {
    shopConfigurations(params: $params) {
      shopConfigurationId
      shopConfigurationName
      shopConfigurationDescription
      departmentId
      facilityId
      isActive
    }
  }
`;
export const shopConfigurationsMultiSearch = /* GraphQL */ `
  query ShopConfigurationsMultiSearch(
    $query: QueryShopConfigurationsMultiSearchQueryInput!
  ) {
    shopConfigurationsMultiSearch(query: $query) {
      shopConfigurationId
      shopConfigurationName
      shopConfigurationDescription
      departmentId
      facilityId
      isActive
    }
  }
`;
export const shopPropertyById = /* GraphQL */ `
  query ShopPropertyById($params: QueryShopPropertyByIdParamsInput!) {
    shopPropertyById(params: $params) {
      shopPropertyId
      shopPropertyName
      shopPropertyDescription
      shippingAddressLine1
      shippingAddressLine2
      city
      state
      zip
      country
      userId
      shopConfigurationId
      facilityId
    }
  }
`;
export const shopProperties = /* GraphQL */ `
  query ShopProperties($query: QueryShopPropertiesQueryInput) {
    shopProperties(query: $query) {
      shopPropertyId
      shopPropertyName
      shopPropertyDescription
      shippingAddressLine1
      shippingAddressLine2
      city
      state
      zip
      country
      userId
      shopConfigurationId
      facilityId
    }
  }
`;
export const workCell = /* GraphQL */ `
  query WorkCell($params: QueryWorkCellParamsInput!) {
    workCell(params: $params) {
      workCellId
      workCellName
      workCellDescription
      facilityId
      shopConfigurationId
      hasTaskTypes
      hasMachines
    }
  }
`;
export const workCells = /* GraphQL */ `
  query WorkCells($query: QueryWorkCellsQueryInput) {
    workCells(query: $query) {
      workCellId
      workCellName
      workCellDescription
      facilityId
      shopConfigurationId
      hasTaskTypes
      hasMachines
    }
  }
`;
export const workCellTaskType = /* GraphQL */ `
  query WorkCellTaskType($query: QueryWorkCellTaskTypeQueryInput) {
    workCellTaskType(query: $query) {
      workCellTaskTypeId
      workCellId
      taskTypeId
      taskTypeImageId
      taskTypeName
      taskTypeDescription
      taskTypeClassId
      companyId
      machines {
        machineId
        manufacturer
        model
        serialNumber
        machineImageId
        facilityId
        machineIdentifier
        workCells {
          workCellId
          shopConfigurationId
        }
      }
    }
  }
`;
export const sourcePadItems = /* GraphQL */ `
  query SourcePadItems($query: QuerySourcePadItemsQueryInput!) {
    sourcePadItems(query: $query) {
      previousLineItemId
      lineItemId
      lineItemTypeId
      lineItemTypeName
      sourcePadId
      unitOfMeasureId
      unitOfMeasureName
      unitOfMeasureCode
      manufacturerId
      manufacturerName
      partId
      locationId
      workPhaseId
      scopePackageId
      workPackageId
      kitPlanId
      spoolId
      lineItemName
      manufacturerCatalogNumber
      quantity
      actualQuantity
      remainingQuantity
      actualRemainingQuantity
      unitQuantity
      tradeSize
      takeoffPadName
      createdOn
      createdBy
      createdByFirstName
      createdByLastName
      createdByEmail
      updatedOn
      updatedBy
      updatedByFirstName
      updatedByLastName
      updatedByEmail
    }
  }
`;
export const sourcePadItemsCount = /* GraphQL */ `
  query SourcePadItemsCount($query: QuerySourcePadItemsCountQueryInput!) {
    sourcePadItemsCount(query: $query)
  }
`;
export const sourcePadNames = /* GraphQL */ `
  query SourcePadNames($query: QuerySourcePadNamesQueryInput!) {
    sourcePadNames(query: $query) {
      sourcePadId
      sourcePadName
      hasMappedItems
      createdOn
    }
  }
`;
export const sourcePadProperties = /* GraphQL */ `
  query SourcePadProperties($params: QuerySourcePadPropertiesParamsInput!) {
    sourcePadProperties(params: $params) {
      sourcePadId
      externalSourceImportDescription
      lastPublished
      publishedBy
      sourcePadNotes {
        sourcePadNoteId
        note
        date
        createdBy
      }
    }
  }
`;
export const folders = /* GraphQL */ `
  query Folders($query: QueryFoldersQueryInput) {
    folders(query: $query) {
      folderId
      parentFolderId
      companyId
      folderName
      storagePath
      folderDescription
      hasSubFolder
      isHidden
      projectId
    }
  }
`;
export const folderById = /* GraphQL */ `
  query FolderById($params: QueryFolderByIdParamsInput!) {
    folderById(params: $params) {
      folderId
      parentFolderId
      companyId
      folderName
      storagePath
      folderDescription
      hasSubFolder
      isHidden
      projectId
    }
  }
`;
export const documents = /* GraphQL */ `
  query Documents($query: QueryDocumentsQueryInput) {
    documents(query: $query) {
      documentId
      documentName
      documentIdentifier
      documentTypeId
      storagePath
      extension
      encodedUrn
      documentStatusTypeId
      isMappedFromShop
      folderDocumentResponse {
        folderDocumentId
        folderId
        folderName
        documentId
      }
      documentNameFormatted
      createdOn
      createdBy
    }
  }
`;
export const documentById = /* GraphQL */ `
  query DocumentById($params: QueryDocumentByIdParamsInput!) {
    documentById(params: $params) {
      documentId
      documentName
      documentIdentifier
      documentTypeId
      storagePath
      extension
      encodedUrn
      documentStatusTypeId
      isMappedFromShop
      folderDocumentResponse {
        folderDocumentId
        folderId
        folderName
        documentId
      }
      documentNameFormatted
      createdOn
      createdBy
    }
  }
`;
export const documentIsTheLastVersion = /* GraphQL */ `
  query DocumentIsTheLastVersion(
    $params: QueryDocumentIsTheLastVersionParamsInput!
  ) {
    documentIsTheLastVersion(params: $params) {
      isLatestVersion
    }
  }
`;
export const forgeDocument = /* GraphQL */ `
  query ForgeDocument($params: QueryForgeDocumentParamsInput!) {
    forgeDocument(params: $params) {
      token
      timeInSeconds
      encodedUrn
      documentStatusType {
        documentStatusTypeId
        documentStatusTypeName
      }
    }
  }
`;
export const licenses = /* GraphQL */ `
  query Licenses($query: QueryLicensesQueryInput) {
    licenses(query: $query) {
      productPoolId
      companyId
      productId
      product {
        productId
        productName
        licenseClassId
        licenseTypeId
      }
      bundleId
      bundle {
        bundleId
        bundleName
      }
      poolQuantity
      poolAvailableQuantity
      customData
      renewalDate
      trialRenewalDate
      startDate
      label
      type
    }
  }
`;
export const summarizeLocationPhases = /* GraphQL */ `
  query SummarizeLocationPhases(
    $query: QuerySummarizeLocationPhasesQueryInput!
  ) {
    summarizeLocationPhases(query: $query) {
      projectId
      hasLocationPhases
      numberOfLocationPhases
    }
  }
`;
export const locationListByWorkPhase = /* GraphQL */ `
  query LocationListByWorkPhase(
    $query: QueryLocationListByWorkPhaseQueryInput!
  ) {
    locationListByWorkPhase(query: $query) {
      locationId
      locationTypeId
      locationTypeName
      projectId
      locationIdentifier
      locationCompositeIdentifier
      sortOrder
      hasChildren
      parentLocationId
      locationName
      locationCompositeName
      locationPhases {
        locationPhaseId
        locationId
        workPhaseId
        workPhaseName
        scopePackageId
        scopePackageName
        sortOrder
      }
      childrenTypeName
      movedLocationPhases {
        workPhaseId
        locationPhaseId
      }
    }
  }
`;
export const machineById = /* GraphQL */ `
  query MachineById($params: QueryMachineByIdParamsInput!) {
    machineById(params: $params) {
      machineId
      manufacturer
      model
      serialNumber
      machineImageId
      facilityId
      machineIdentifier
      workCells {
        workCellId
        shopConfigurationId
      }
    }
  }
`;
export const machines = /* GraphQL */ `
  query Machines($query: QueryMachinesQueryInput) {
    machines(query: $query) {
      machineId
      manufacturer
      model
      serialNumber
      machineImageId
      facilityId
      machineIdentifier
      workCells {
        workCellId
        shopConfigurationId
      }
    }
  }
`;
export const machineTaskType = /* GraphQL */ `
  query MachineTaskType($query: QueryMachineTaskTypeQueryInput) {
    machineTaskType(query: $query) {
      machineTaskTypeId
      machineId
      taskTypeId
      taskTypeImageId
      taskTypeName
      taskTypeDescription
      taskTypeClassId
      companyId
    }
  }
`;
export const workOrders = /* GraphQL */ `
  query WorkOrders($query: QueryWorkOrdersQueryInput) {
    workOrders(query: $query) {
      workOrderId
      workRequestId
      workOrderStatusTypeId
      workOrderStatusTypeName
      workOrderName
      workOrderDescription
      workOrderNumber
      workRequest {
        workRequestId
        workRequestStatusId
        workRequestStatusName
        facilityId
        projectId
        projectIdentifier
        projectName
        workRequestIdentifier
        workRequestName
        needBy
        workRequestDescription
        facilityName
        workRequestStatusDescription
        lastStatusUpdate
        numberOfAttachments
      }
      sortOrder
      createdBy
      lastStatusUpdate
      createdOn
      isStepFunctionProcessing
      stepFunctionProcessingMessage
      numberOfAttachments
      hasCustomWorkOrderName
    }
  }
`;
export const workOrderItem = /* GraphQL */ `
  query WorkOrderItem($query: QueryWorkOrderItemQueryInput) {
    workOrderItem(query: $query) {
      workRequestItemId
      hasShopTask
      workOrderItemId
      workOrderId
      workOrderItemTypeId
      unitOfMeasureId
      billOfProcessId
      workOrderItemName
      WorkOrderItemIdentification
      quantity
      unityQuantity
      assignedQuantity
      remainingQuantity
      createdOn
      createdBy
      workOrderItemStatusTypeId
    }
  }
`;
export const workOrderItemById = /* GraphQL */ `
  query WorkOrderItemById($params: QueryWorkOrderItemByIdParamsInput!) {
    workOrderItemById(params: $params) {
      workRequestItemId
      hasShopTask
      workOrderItemId
      workOrderId
      workOrderItemTypeId
      unitOfMeasureId
      billOfProcessId
      workOrderItemName
      WorkOrderItemIdentification
      quantity
      unityQuantity
      assignedQuantity
      remainingQuantity
      createdOn
      createdBy
      workOrderItemStatusTypeId
    }
  }
`;
export const workOrdersCount = /* GraphQL */ `
  query WorkOrdersCount($query: QueryWorkOrdersCountQueryInput) {
    workOrdersCount(query: $query) {
      requestedSkip
      requestedTake
      entireCount
    }
  }
`;
export const syncConnections = /* GraphQL */ `
  query SyncConnections($query: QuerySyncConnectionsQueryInput) {
    syncConnections(query: $query) {
      id
      name
      type
      configuration
      isConnectionUsed
      lastModified
      desktopConnectionId
    }
  }
`;
export const workCellMachine = /* GraphQL */ `
  query WorkCellMachine($query: QueryWorkCellMachineQueryInput) {
    workCellMachine(query: $query) {
      workCellMachineId
      facilityId
      shopConfigurationId
      workCellId
      machineId
      machineManufacturer
      machineIdentifier
    }
  }
`;
export const workCellTasksMultiSearch = /* GraphQL */ `
  query WorkCellTasksMultiSearch(
    $query: QueryWorkCellTasksMultiSearchQueryInput
  ) {
    workCellTasksMultiSearch(query: $query) {
      workCellTaskId
      workCellId
      workCellName
      projectId
      workOrderId
      workRequestItemId
      workRequestId
      taskId
      taskTypeId
      taskStatusTypeId
      projectIdentifier
      projectName
      workOrderName
      workRequestItemName
      taskName
      taskTypeName
      taskStatusTypeName
      sortOrder
      isEnabled
      needBy
      hasTaskTypes
      taskStatusTypeUpdatedOn
      workRequestName
      workRequestItemTypeName
      workRequestItemTypeId
      workRequestIdentifier
    }
  }
`;
export const workCellTasksMultiSearchCount = /* GraphQL */ `
  query WorkCellTasksMultiSearchCount(
    $query: QueryWorkCellTasksMultiSearchCountQueryInput
  ) {
    workCellTasksMultiSearchCount(query: $query) {
      requestedSkip
      requestedTake
      entireCount
    }
  }
`;
export const workCellTasksQueue = /* GraphQL */ `
  query WorkCellTasksQueue($query: QueryWorkCellTasksQueueQueryInput) {
    workCellTasksQueue(query: $query) {
      workCellTaskId
      workCellId
      workCellName
      projectId
      workOrderId
      workRequestItemId
      workRequestId
      taskId
      taskTypeId
      taskStatusTypeId
      projectIdentifier
      projectName
      workOrderName
      workRequestItemName
      taskName
      taskTypeName
      taskStatusTypeName
      sortOrder
      isEnabled
      needBy
      hasTaskTypes
      taskStatusTypeUpdatedOn
      workRequestName
      workRequestItemTypeName
      workRequestItemTypeId
      workRequestIdentifier
    }
  }
`;
export const workCellTasksQueueCount = /* GraphQL */ `
  query WorkCellTasksQueueCount(
    $query: QueryWorkCellTasksQueueCountQueryInput
  ) {
    workCellTasksQueueCount(query: $query)
  }
`;
export const syncWorkflows = /* GraphQL */ `
  query SyncWorkflows($query: QuerySyncWorkflowsQueryInput) {
    syncWorkflows(query: $query) {
      workflowId
      workflowName
      workflowMapping {
        workflowMappingId
        workflowMappingName
        isSyncRunning
        lastSyncAt
        lastSavedAt
        isArchived
        isDeactivated
        solitaryFlows {
          solitaryFlowId
          action
          actionNodeId
          actionAlias
          isArchived
          isDeactivated
          source {
            syncDetailsId
            nodeId
            connection {
              id
              name
              type
              configuration
              isConnectionUsed
              lastModified
              desktopConnectionId
            }
            hub {
              id
              name
            }
            projects {
              id
              name
              folders {
                id
                name
              }
            }
            additionalData
            settings {
              isSyncEnabled
              includeExtensions
              secondaryFilesSetting
              isSyncAllRevisions
              syncType
              scheduleSyncConfig
            }
          }
          destinations {
            syncDetailsId
            nodeId
            connection {
              id
              name
              type
              configuration
              isConnectionUsed
              lastModified
              desktopConnectionId
            }
            hub {
              id
              name
            }
            project {
              id
              name
              folder {
                id
                name
              }
            }
            additionalData
          }
          syncStatus
          lastSyncAt
          actionType
        }
        publishModelSolitaryFlows {
          solitaryFlowId
          action
          actionNodeId
          actionAlias
          isArchived
          isDeactivated
          source {
            syncDetailsId
            nodeId
            connection {
              id
              name
              type
              configuration
              isConnectionUsed
              lastModified
              desktopConnectionId
            }
            hub {
              id
              name
            }
            projects {
              id
              name
              folders {
                id
                name
              }
            }
            additionalData
            settings {
              isSyncEnabled
              includeExtensions
              secondaryFilesSetting
              isSyncAllRevisions
              syncType
              scheduleSyncConfig
            }
          }
          destinations {
            syncDetailsId
            nodeId
            connection {
              id
              name
              type
              configuration
              isConnectionUsed
              lastModified
              desktopConnectionId
            }
            hub {
              id
              name
            }
            project {
              id
              name
              folder {
                id
                name
              }
            }
            additionalData
          }
          syncStatus
          lastSyncAt
          actionType
        }
      }
      isArchived
      isDeactivated
    }
  }
`;
export const syncArchivedWorkflows = /* GraphQL */ `
  query SyncArchivedWorkflows($query: QuerySyncArchivedWorkflowsQueryInput) {
    syncArchivedWorkflows(query: $query) {
      workflowId
      workflowName
      workflowMapping {
        workflowMappingId
        workflowMappingName
        isSyncRunning
        lastSyncAt
        lastSavedAt
        isArchived
        isDeactivated
        solitaryFlows {
          solitaryFlowId
          action
          actionNodeId
          actionAlias
          isArchived
          isDeactivated
          source {
            syncDetailsId
            nodeId
            connection {
              id
              name
              type
              configuration
              isConnectionUsed
              lastModified
              desktopConnectionId
            }
            hub {
              id
              name
            }
            projects {
              id
              name
              folders {
                id
                name
              }
            }
            additionalData
            settings {
              isSyncEnabled
              includeExtensions
              secondaryFilesSetting
              isSyncAllRevisions
              syncType
              scheduleSyncConfig
            }
          }
          destinations {
            syncDetailsId
            nodeId
            connection {
              id
              name
              type
              configuration
              isConnectionUsed
              lastModified
              desktopConnectionId
            }
            hub {
              id
              name
            }
            project {
              id
              name
              folder {
                id
                name
              }
            }
            additionalData
          }
          syncStatus
          lastSyncAt
          actionType
        }
        publishModelSolitaryFlows {
          solitaryFlowId
          action
          actionNodeId
          actionAlias
          isArchived
          isDeactivated
          source {
            syncDetailsId
            nodeId
            connection {
              id
              name
              type
              configuration
              isConnectionUsed
              lastModified
              desktopConnectionId
            }
            hub {
              id
              name
            }
            projects {
              id
              name
              folders {
                id
                name
              }
            }
            additionalData
            settings {
              isSyncEnabled
              includeExtensions
              secondaryFilesSetting
              isSyncAllRevisions
              syncType
              scheduleSyncConfig
            }
          }
          destinations {
            syncDetailsId
            nodeId
            connection {
              id
              name
              type
              configuration
              isConnectionUsed
              lastModified
              desktopConnectionId
            }
            hub {
              id
              name
            }
            project {
              id
              name
              folder {
                id
                name
              }
            }
            additionalData
          }
          syncStatus
          lastSyncAt
          actionType
        }
      }
      isArchived
      isDeactivated
    }
  }
`;
export const syncWebhooks = /* GraphQL */ `
  query SyncWebhooks($query: QuerySyncWebhooksQueryInput) {
    syncWebhooks(query: $query) {
      webhookId
      webhookDetails
      connectionType
      isActive
      lastSavedAt
      workflows {
        workflowId
        workflowName
        mappings {
          mappingId
          mappingName
          isSyncRunning
          syncStatus
          lastSyncTime
          solitaryFlows {
            solitaryFlowId
            actionAlias
            syncStatus
            syncType
            lastSyncTime
            webhookRegistryId
          }
        }
      }
    }
  }
`;
export const getWorkflowById = /* GraphQL */ `
  query GetWorkflowById($params: QueryGetWorkflowByIdParamsInput!) {
    getWorkflowById(params: $params) {
      workflowId
      workflowName
      workflowMapping {
        workflowMappingId
        workflowMappingName
        isSyncRunning
        lastSyncAt
        lastSavedAt
        isArchived
        isDeactivated
        solitaryFlows {
          solitaryFlowId
          action
          actionNodeId
          actionAlias
          isArchived
          isDeactivated
          source {
            syncDetailsId
            nodeId
            connection {
              id
              name
              type
              configuration
              isConnectionUsed
              lastModified
              desktopConnectionId
            }
            hub {
              id
              name
            }
            projects {
              id
              name
              folders {
                id
                name
              }
            }
            additionalData
            settings {
              isSyncEnabled
              includeExtensions
              secondaryFilesSetting
              isSyncAllRevisions
              syncType
              scheduleSyncConfig
            }
          }
          destinations {
            syncDetailsId
            nodeId
            connection {
              id
              name
              type
              configuration
              isConnectionUsed
              lastModified
              desktopConnectionId
            }
            hub {
              id
              name
            }
            project {
              id
              name
              folder {
                id
                name
              }
            }
            additionalData
          }
          syncStatus
          lastSyncAt
          actionType
        }
        publishModelSolitaryFlows {
          solitaryFlowId
          action
          actionNodeId
          actionAlias
          isArchived
          isDeactivated
          source {
            syncDetailsId
            nodeId
            connection {
              id
              name
              type
              configuration
              isConnectionUsed
              lastModified
              desktopConnectionId
            }
            hub {
              id
              name
            }
            projects {
              id
              name
              folders {
                id
                name
              }
            }
            additionalData
            settings {
              isSyncEnabled
              includeExtensions
              secondaryFilesSetting
              isSyncAllRevisions
              syncType
              scheduleSyncConfig
            }
          }
          destinations {
            syncDetailsId
            nodeId
            connection {
              id
              name
              type
              configuration
              isConnectionUsed
              lastModified
              desktopConnectionId
            }
            hub {
              id
              name
            }
            project {
              id
              name
              folder {
                id
                name
              }
            }
            additionalData
          }
          syncStatus
          lastSyncAt
          actionType
        }
      }
      isArchived
      isDeactivated
    }
  }
`;
export const getProcoreCompanies = /* GraphQL */ `
  query GetProcoreCompanies($query: QueryGetProcoreCompaniesQueryInput) {
    getProcoreCompanies(query: $query) {
      id
      text
    }
  }
`;
export const getProcoreProjects = /* GraphQL */ `
  query GetProcoreProjects($query: QueryGetProcoreProjectsQueryInput) {
    getProcoreProjects(query: $query) {
      id
      text
    }
  }
`;
export const getProcoreFolders = /* GraphQL */ `
  query GetProcoreFolders(
    $params: QueryGetProcoreFoldersParamsInput!
    $query: QueryGetProcoreFoldersQueryInput
  ) {
    getProcoreFolders(params: $params, query: $query) {
      id
      text
    }
  }
`;
export const getProcoreSelectedFolderIdStack = /* GraphQL */ `
  query GetProcoreSelectedFolderIdStack(
    $params: QueryGetProcoreSelectedFolderIdStackParamsInput!
    $query: QueryGetProcoreSelectedFolderIdStackQueryInput
  ) {
    getProcoreSelectedFolderIdStack(params: $params, query: $query)
  }
`;
export const getProcoreFolderName = /* GraphQL */ `
  query GetProcoreFolderName($query: QueryGetProcoreFolderNameQueryInput) {
    getProcoreFolderName(query: $query) {
      success
      name
    }
  }
`;
export const getAutodeskFieldProjects = /* GraphQL */ `
  query GetAutodeskFieldProjects(
    $query: QueryGetAutodeskFieldProjectsQueryInput
  ) {
    getAutodeskFieldProjects(query: $query) {
      id
      text
    }
  }
`;
export const getAutodeskFieldFolders = /* GraphQL */ `
  query GetAutodeskFieldFolders(
    $query: QueryGetAutodeskFieldFoldersQueryInput
  ) {
    getAutodeskFieldFolders(query: $query) {
      id
      text
    }
  }
`;
export const getAutodeskFieldFolderName = /* GraphQL */ `
  query GetAutodeskFieldFolderName(
    $query: QueryGetAutodeskFieldFolderNameQueryInput
  ) {
    getAutodeskFieldFolderName(query: $query) {
      success
      name
    }
  }
`;
export const getAutodeskForgeHubs = /* GraphQL */ `
  query GetAutodeskForgeHubs($query: QueryGetAutodeskForgeHubsQueryInput) {
    getAutodeskForgeHubs(query: $query) {
      id
      text
    }
  }
`;
export const getAutodeskForgeProjects = /* GraphQL */ `
  query GetAutodeskForgeProjects(
    $query: QueryGetAutodeskForgeProjectsQueryInput
  ) {
    getAutodeskForgeProjects(query: $query) {
      id
      text
    }
  }
`;
export const getAutodeskForgeSubItems = /* GraphQL */ `
  query GetAutodeskForgeSubItems(
    $query: QueryGetAutodeskForgeSubItemsQueryInput
  ) {
    getAutodeskForgeSubItems(query: $query) {
      id
      text
      isFolder
    }
  }
`;
export const getAutodeskForgeItemName = /* GraphQL */ `
  query GetAutodeskForgeItemName(
    $query: QueryGetAutodeskForgeItemNameQueryInput
  ) {
    getAutodeskForgeItemName(query: $query) {
      success
      name
    }
  }
`;
export const getAutodeskForgeSelectedSubItemIdStack = /* GraphQL */ `
  query GetAutodeskForgeSelectedSubItemIdStack(
    $query: QueryGetAutodeskForgeSelectedSubItemIdStackQueryInput
  ) {
    getAutodeskForgeSelectedSubItemIdStack(query: $query)
  }
`;
export const taskById = /* GraphQL */ `
  query TaskById($params: QueryTaskByIdParamsInput!) {
    taskById(params: $params) {
      taskId
      taskTypeId
      billOfProcessId
      workRequestItemId
      taskStatusTypeId
      taskPredecessorIds {
        taskId
        taskName
        taskStatusTypeId
      }
      taskPredecessorDeletedIds
      taskTypeIds
      taskName
      taskDescription
      taskStatusTypeName
      sortOrder
      isEnabled
      createdOn
      time {
        totalTime
        events {
          taskStatusTypeId
          description
          timesStamp
          userId
        }
      }
      hasDocuments
      hasDrawingRegisterDocuments
      hasCommonDocuments
      hasExternalDocuments
      quantity
      needBy
    }
  }
`;
export const taskMultiSearch = /* GraphQL */ `
  query TaskMultiSearch($query: QueryTaskMultiSearchQueryInput) {
    taskMultiSearch(query: $query) {
      taskId
      taskTypeId
      billOfProcessId
      workRequestItemId
      taskStatusTypeId
      taskPredecessorIds {
        taskId
        taskName
        taskStatusTypeId
      }
      taskPredecessorDeletedIds
      taskTypeIds
      taskName
      taskDescription
      taskStatusTypeName
      sortOrder
      isEnabled
      createdOn
      time {
        totalTime
        events {
          taskStatusTypeId
          description
          timesStamp
          userId
        }
      }
      hasDocuments
      hasDrawingRegisterDocuments
      hasCommonDocuments
      hasExternalDocuments
      quantity
      needBy
    }
  }
`;
export const taskDocument = /* GraphQL */ `
  query TaskDocument($query: QueryTaskDocumentQueryInput) {
    taskDocument(query: $query) {
      taskDocumentId
      taskId
      documentId
      externalUrl
      createdBy
      createdOn
      documentTypeId
    }
  }
`;
export const billOfProcessById = /* GraphQL */ `
  query BillOfProcessById($params: QueryBillOfProcessByIdParamsInput!) {
    billOfProcessById(params: $params) {
      billOfProcessId
      billOfMaterialName
      workRequestItemId
      createdOn
      createdBy
      updatedOn
      updatedBy
      deletedOn
      deletedBy
    }
  }
`;
export const workOrderById = /* GraphQL */ `
  query WorkOrderById($params: QueryWorkOrderByIdParamsInput!) {
    workOrderById(params: $params) {
      workOrderId
      workRequestId
      workOrderStatusTypeId
      workOrderStatusTypeName
      workOrderName
      workOrderDescription
      workOrderNumber
      workRequest {
        workRequestId
        workRequestStatusId
        workRequestStatusName
        facilityId
        projectId
        projectIdentifier
        projectName
        workRequestIdentifier
        workRequestName
        needBy
        workRequestDescription
        facilityName
        workRequestStatusDescription
        lastStatusUpdate
        numberOfAttachments
      }
      sortOrder
      createdBy
      lastStatusUpdate
      createdOn
      isStepFunctionProcessing
      stepFunctionProcessingMessage
      numberOfAttachments
      hasCustomWorkOrderName
    }
  }
`;
export const syncGetWorkflowMappingConfiguration = /* GraphQL */ `
  query SyncGetWorkflowMappingConfiguration(
    $params: QuerySyncGetWorkflowMappingConfigurationParamsInput!
  ) {
    syncGetWorkflowMappingConfiguration(params: $params) {
      workflowId
      workflowName
      mapping {
        mappingId
        mappingName
        canvasNode
        isSyncRunning
        lastSyncAt
        lastSavedAt
        isArchived
        isDeactivated
        solitaryFlowConfiguration {
          solitaryFlowId
          action
          actionNodeId
          actionAlias
          isDeactivated
          isArchived
          source {
            syncDetailsId
            nodeId
            connection {
              id
              name
              type
              configuration
              isConnectionUsed
              lastModified
              desktopConnectionId
            }
            hub {
              id
              name
            }
            projects {
              id
              name
              folders {
                id
                name
              }
            }
            additionalData
            settings {
              isSyncEnabled
              includeExtensions
              secondaryFilesSetting
              isSyncAllRevisions
              syncType
              scheduleSyncConfig
            }
          }
          destinations {
            syncDetailsId
            nodeId
            connection {
              id
              name
              type
              configuration
              isConnectionUsed
              lastModified
              desktopConnectionId
            }
            hub {
              id
              name
            }
            project {
              id
              name
              folder {
                id
                name
              }
            }
            additionalData
          }
        }
        solitaryFlowPublishModelConfiguration {
          solitaryFlowId
          action
          actionNodeId
          actionAlias
          syncStatus
          lastSyncAt
          isDeactivated
          isArchived
          source {
            syncDetailsId
            nodeId
            connection {
              id
              name
              type
              configuration
              isConnectionUsed
              lastModified
              desktopConnectionId
            }
            hub {
              id
              name
            }
            projects {
              id
              name
              folders {
                id
                name
              }
            }
            additionalData
            settings {
              isSyncEnabled
              includeExtensions
              secondaryFilesSetting
              isSyncAllRevisions
              syncType
              scheduleSyncConfig
            }
          }
        }
      }
    }
  }
`;
export const syncGetWorkflowMappingTemplatesList = /* GraphQL */ `
  query SyncGetWorkflowMappingTemplatesList(
    $query: QuerySyncGetWorkflowMappingTemplatesListQueryInput
  ) {
    syncGetWorkflowMappingTemplatesList(query: $query) {
      templateId
      templateName
    }
  }
`;
export const drawingTypeCategoryById = /* GraphQL */ `
  query DrawingTypeCategoryById(
    $params: QueryDrawingTypeCategoryByIdParamsInput!
  ) {
    drawingTypeCategoryById(params: $params) {
      companyId
      userDefinedId
      name
      hasDrawingTypes
      id
      createdBy
      createdOnUtc
      modifiedBy
      modifiedOnUtc
    }
  }
`;
export const syncGetConnectionById = /* GraphQL */ `
  query SyncGetConnectionById($params: QuerySyncGetConnectionByIdParamsInput!) {
    syncGetConnectionById(params: $params) {
      id
      name
      type
      configuration
      isConnectionUsed
      lastModified
      desktopConnectionId
    }
  }
`;
export const assemblies = /* GraphQL */ `
  query Assemblies($query: QueryAssembliesQueryInput) {
    assemblies(query: $query) {
      part {
        partId
        partName
        description
        stateId
        isDefault
        manufacturerCatalogNumber
        unitOfMeasureCode
        unitOfMeasureName
        upcCode
        url
        catalogUrl
        partUrl
        internalPartNumber
        createdOn
        hasAssembly
        manufacturer {
          manufacturerId
          companyId
          manufacturerName
          homePageUrl
        }
        attriubuteVariantData {
          partAttributeVariantDataId
          partAttributeId
          partAttributeName
          rangeValue
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
          partAttribute {
            partAttributeId
            partAttributeName
            allowWriteInVariant
            rangeMinimumValue
            rangeMaximumValue
            selectVariants {
              partAttributeSelectVariantId
              textValue
              codeValue
              isDeleted
            }
            partAttributeType {
              partAttributeTypeId
              partAttributeTypeName
              partAttributeTypeDescription
              sortOrder
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          selectValues {
            partAttributeSelectVariantId
            textValue
            codeValue
            isDeleted
          }
        }
        partCategory {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        partImages {
          partImageId
          sortOrder
          isDefault
          document {
            documentId
            documentName
            documentIdentifier
            documentTypeId
            storagePath
            extension
            encodedUrn
            documentStatusTypeId
            isMappedFromShop
            folderDocumentResponse {
              folderDocumentId
              folderId
              folderName
              documentId
            }
            documentNameFormatted
            createdOn
            createdBy
          }
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
        }
        documentIds
        unitOfMeasure {
          unitOfMeasureId
          unitOfMeasureTypeId
          companyId
          unitOfMeasureName
          unitOfMeasureCode
          createdOn
          createdBy
          updatedOn
          modifiedBy
          modifiedOn
        }
      }
      assemblyId
      historyTypeId
      assemblyNodes {
        quantity
        part {
          partId
          partName
          description
          stateId
          isDefault
          manufacturerCatalogNumber
          unitOfMeasureCode
          unitOfMeasureName
          upcCode
          url
          catalogUrl
          partUrl
          internalPartNumber
          createdOn
          hasAssembly
          manufacturer {
            manufacturerId
            companyId
            manufacturerName
            homePageUrl
          }
          attriubuteVariantData {
            partAttributeVariantDataId
            partAttributeId
            partAttributeName
            rangeValue
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            partAttribute {
              partAttributeId
              partAttributeName
              allowWriteInVariant
              rangeMinimumValue
              rangeMaximumValue
              selectVariants {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
              partAttributeType {
                partAttributeTypeId
                partAttributeTypeName
                partAttributeTypeDescription
                sortOrder
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
            }
            selectValues {
              partAttributeSelectVariantId
              textValue
              codeValue
              isDeleted
            }
          }
          partCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          partImages {
            partImageId
            sortOrder
            isDefault
            document {
              documentId
              documentName
              documentIdentifier
              documentTypeId
              storagePath
              extension
              encodedUrn
              documentStatusTypeId
              isMappedFromShop
              folderDocumentResponse {
                folderDocumentId
                folderId
                folderName
                documentId
              }
              documentNameFormatted
              createdOn
              createdBy
            }
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
          }
          documentIds
          unitOfMeasure {
            unitOfMeasureId
            unitOfMeasureTypeId
            companyId
            unitOfMeasureName
            unitOfMeasureCode
            createdOn
            createdBy
            updatedOn
            modifiedBy
            modifiedOn
          }
        }
        shopTaskId
        assemblyNodeId
        historyTypeId
        assembly {
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
          assemblyId
          historyTypeId
          assemblyNodes {
            quantity
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            shopTaskId
            assemblyNodeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyNodeType {
              assemblyNodeTypeId
              assemblyNodeTypeName
            }
            assemblyNodeName
            assemblyNodeDescription
            positionX
            positionY
          }
          assemblyEdges {
            assemblyEdgeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyEdgeType {
              assemblyEdgeTypeId
              assemblyEdgeTypeName
            }
            beginHandlePosition {
              assemblyHandlePositionId
              assemblyHandlePositionName
            }
            endHandlePosition {
              assemblyHandlePositionId
              assemblyHandlePositionName
            }
            beginNode {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
            endNode {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
          }
        }
        assemblyNodeType {
          assemblyNodeTypeId
          assemblyNodeTypeName
        }
        assemblyNodeName
        assemblyNodeDescription
        positionX
        positionY
      }
      assemblyEdges {
        assemblyEdgeId
        historyTypeId
        assembly {
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
          assemblyId
          historyTypeId
          assemblyNodes {
            quantity
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            shopTaskId
            assemblyNodeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyNodeType {
              assemblyNodeTypeId
              assemblyNodeTypeName
            }
            assemblyNodeName
            assemblyNodeDescription
            positionX
            positionY
          }
          assemblyEdges {
            assemblyEdgeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyEdgeType {
              assemblyEdgeTypeId
              assemblyEdgeTypeName
            }
            beginHandlePosition {
              assemblyHandlePositionId
              assemblyHandlePositionName
            }
            endHandlePosition {
              assemblyHandlePositionId
              assemblyHandlePositionName
            }
            beginNode {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
            endNode {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
          }
        }
        assemblyEdgeType {
          assemblyEdgeTypeId
          assemblyEdgeTypeName
        }
        beginHandlePosition {
          assemblyHandlePositionId
          assemblyHandlePositionName
        }
        endHandlePosition {
          assemblyHandlePositionId
          assemblyHandlePositionName
        }
        beginNode {
          quantity
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
          shopTaskId
          assemblyNodeId
          historyTypeId
          assembly {
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            assemblyId
            historyTypeId
            assemblyNodes {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
            assemblyEdges {
              assemblyEdgeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyEdgeType {
                assemblyEdgeTypeId
                assemblyEdgeTypeName
              }
              beginHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              endHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              beginNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              endNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
            }
          }
          assemblyNodeType {
            assemblyNodeTypeId
            assemblyNodeTypeName
          }
          assemblyNodeName
          assemblyNodeDescription
          positionX
          positionY
        }
        endNode {
          quantity
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
          shopTaskId
          assemblyNodeId
          historyTypeId
          assembly {
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            assemblyId
            historyTypeId
            assemblyNodes {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
            assemblyEdges {
              assemblyEdgeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyEdgeType {
                assemblyEdgeTypeId
                assemblyEdgeTypeName
              }
              beginHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              endHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              beginNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              endNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
            }
          }
          assemblyNodeType {
            assemblyNodeTypeId
            assemblyNodeTypeName
          }
          assemblyNodeName
          assemblyNodeDescription
          positionX
          positionY
        }
      }
    }
  }
`;
export const assemblyById = /* GraphQL */ `
  query AssemblyById($params: QueryAssemblyByIdParamsInput!) {
    assemblyById(params: $params) {
      part {
        partId
        partName
        description
        stateId
        isDefault
        manufacturerCatalogNumber
        unitOfMeasureCode
        unitOfMeasureName
        upcCode
        url
        catalogUrl
        partUrl
        internalPartNumber
        createdOn
        hasAssembly
        manufacturer {
          manufacturerId
          companyId
          manufacturerName
          homePageUrl
        }
        attriubuteVariantData {
          partAttributeVariantDataId
          partAttributeId
          partAttributeName
          rangeValue
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
          partAttribute {
            partAttributeId
            partAttributeName
            allowWriteInVariant
            rangeMinimumValue
            rangeMaximumValue
            selectVariants {
              partAttributeSelectVariantId
              textValue
              codeValue
              isDeleted
            }
            partAttributeType {
              partAttributeTypeId
              partAttributeTypeName
              partAttributeTypeDescription
              sortOrder
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          selectValues {
            partAttributeSelectVariantId
            textValue
            codeValue
            isDeleted
          }
        }
        partCategory {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        partImages {
          partImageId
          sortOrder
          isDefault
          document {
            documentId
            documentName
            documentIdentifier
            documentTypeId
            storagePath
            extension
            encodedUrn
            documentStatusTypeId
            isMappedFromShop
            folderDocumentResponse {
              folderDocumentId
              folderId
              folderName
              documentId
            }
            documentNameFormatted
            createdOn
            createdBy
          }
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
        }
        documentIds
        unitOfMeasure {
          unitOfMeasureId
          unitOfMeasureTypeId
          companyId
          unitOfMeasureName
          unitOfMeasureCode
          createdOn
          createdBy
          updatedOn
          modifiedBy
          modifiedOn
        }
      }
      assemblyId
      historyTypeId
      assemblyNodes {
        quantity
        part {
          partId
          partName
          description
          stateId
          isDefault
          manufacturerCatalogNumber
          unitOfMeasureCode
          unitOfMeasureName
          upcCode
          url
          catalogUrl
          partUrl
          internalPartNumber
          createdOn
          hasAssembly
          manufacturer {
            manufacturerId
            companyId
            manufacturerName
            homePageUrl
          }
          attriubuteVariantData {
            partAttributeVariantDataId
            partAttributeId
            partAttributeName
            rangeValue
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            partAttribute {
              partAttributeId
              partAttributeName
              allowWriteInVariant
              rangeMinimumValue
              rangeMaximumValue
              selectVariants {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
              partAttributeType {
                partAttributeTypeId
                partAttributeTypeName
                partAttributeTypeDescription
                sortOrder
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
            }
            selectValues {
              partAttributeSelectVariantId
              textValue
              codeValue
              isDeleted
            }
          }
          partCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          partImages {
            partImageId
            sortOrder
            isDefault
            document {
              documentId
              documentName
              documentIdentifier
              documentTypeId
              storagePath
              extension
              encodedUrn
              documentStatusTypeId
              isMappedFromShop
              folderDocumentResponse {
                folderDocumentId
                folderId
                folderName
                documentId
              }
              documentNameFormatted
              createdOn
              createdBy
            }
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
          }
          documentIds
          unitOfMeasure {
            unitOfMeasureId
            unitOfMeasureTypeId
            companyId
            unitOfMeasureName
            unitOfMeasureCode
            createdOn
            createdBy
            updatedOn
            modifiedBy
            modifiedOn
          }
        }
        shopTaskId
        assemblyNodeId
        historyTypeId
        assembly {
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
          assemblyId
          historyTypeId
          assemblyNodes {
            quantity
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            shopTaskId
            assemblyNodeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyNodeType {
              assemblyNodeTypeId
              assemblyNodeTypeName
            }
            assemblyNodeName
            assemblyNodeDescription
            positionX
            positionY
          }
          assemblyEdges {
            assemblyEdgeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyEdgeType {
              assemblyEdgeTypeId
              assemblyEdgeTypeName
            }
            beginHandlePosition {
              assemblyHandlePositionId
              assemblyHandlePositionName
            }
            endHandlePosition {
              assemblyHandlePositionId
              assemblyHandlePositionName
            }
            beginNode {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
            endNode {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
          }
        }
        assemblyNodeType {
          assemblyNodeTypeId
          assemblyNodeTypeName
        }
        assemblyNodeName
        assemblyNodeDescription
        positionX
        positionY
      }
      assemblyEdges {
        assemblyEdgeId
        historyTypeId
        assembly {
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
          assemblyId
          historyTypeId
          assemblyNodes {
            quantity
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            shopTaskId
            assemblyNodeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyNodeType {
              assemblyNodeTypeId
              assemblyNodeTypeName
            }
            assemblyNodeName
            assemblyNodeDescription
            positionX
            positionY
          }
          assemblyEdges {
            assemblyEdgeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyEdgeType {
              assemblyEdgeTypeId
              assemblyEdgeTypeName
            }
            beginHandlePosition {
              assemblyHandlePositionId
              assemblyHandlePositionName
            }
            endHandlePosition {
              assemblyHandlePositionId
              assemblyHandlePositionName
            }
            beginNode {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
            endNode {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
          }
        }
        assemblyEdgeType {
          assemblyEdgeTypeId
          assemblyEdgeTypeName
        }
        beginHandlePosition {
          assemblyHandlePositionId
          assemblyHandlePositionName
        }
        endHandlePosition {
          assemblyHandlePositionId
          assemblyHandlePositionName
        }
        beginNode {
          quantity
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
          shopTaskId
          assemblyNodeId
          historyTypeId
          assembly {
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            assemblyId
            historyTypeId
            assemblyNodes {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
            assemblyEdges {
              assemblyEdgeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyEdgeType {
                assemblyEdgeTypeId
                assemblyEdgeTypeName
              }
              beginHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              endHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              beginNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              endNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
            }
          }
          assemblyNodeType {
            assemblyNodeTypeId
            assemblyNodeTypeName
          }
          assemblyNodeName
          assemblyNodeDescription
          positionX
          positionY
        }
        endNode {
          quantity
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
          shopTaskId
          assemblyNodeId
          historyTypeId
          assembly {
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            assemblyId
            historyTypeId
            assemblyNodes {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
            assemblyEdges {
              assemblyEdgeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyEdgeType {
                assemblyEdgeTypeId
                assemblyEdgeTypeName
              }
              beginHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              endHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              beginNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              endNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
            }
          }
          assemblyNodeType {
            assemblyNodeTypeId
            assemblyNodeTypeName
          }
          assemblyNodeName
          assemblyNodeDescription
          positionX
          positionY
        }
      }
    }
  }
`;
export const assemblyNodes = /* GraphQL */ `
  query AssemblyNodes($query: QueryAssemblyNodesQueryInput) {
    assemblyNodes(query: $query) {
      quantity
      part {
        partId
        partName
        description
        stateId
        isDefault
        manufacturerCatalogNumber
        unitOfMeasureCode
        unitOfMeasureName
        upcCode
        url
        catalogUrl
        partUrl
        internalPartNumber
        createdOn
        hasAssembly
        manufacturer {
          manufacturerId
          companyId
          manufacturerName
          homePageUrl
        }
        attriubuteVariantData {
          partAttributeVariantDataId
          partAttributeId
          partAttributeName
          rangeValue
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
          partAttribute {
            partAttributeId
            partAttributeName
            allowWriteInVariant
            rangeMinimumValue
            rangeMaximumValue
            selectVariants {
              partAttributeSelectVariantId
              textValue
              codeValue
              isDeleted
            }
            partAttributeType {
              partAttributeTypeId
              partAttributeTypeName
              partAttributeTypeDescription
              sortOrder
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          selectValues {
            partAttributeSelectVariantId
            textValue
            codeValue
            isDeleted
          }
        }
        partCategory {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        partImages {
          partImageId
          sortOrder
          isDefault
          document {
            documentId
            documentName
            documentIdentifier
            documentTypeId
            storagePath
            extension
            encodedUrn
            documentStatusTypeId
            isMappedFromShop
            folderDocumentResponse {
              folderDocumentId
              folderId
              folderName
              documentId
            }
            documentNameFormatted
            createdOn
            createdBy
          }
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
        }
        documentIds
        unitOfMeasure {
          unitOfMeasureId
          unitOfMeasureTypeId
          companyId
          unitOfMeasureName
          unitOfMeasureCode
          createdOn
          createdBy
          updatedOn
          modifiedBy
          modifiedOn
        }
      }
      shopTaskId
      assemblyNodeId
      historyTypeId
      assembly {
        part {
          partId
          partName
          description
          stateId
          isDefault
          manufacturerCatalogNumber
          unitOfMeasureCode
          unitOfMeasureName
          upcCode
          url
          catalogUrl
          partUrl
          internalPartNumber
          createdOn
          hasAssembly
          manufacturer {
            manufacturerId
            companyId
            manufacturerName
            homePageUrl
          }
          attriubuteVariantData {
            partAttributeVariantDataId
            partAttributeId
            partAttributeName
            rangeValue
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            partAttribute {
              partAttributeId
              partAttributeName
              allowWriteInVariant
              rangeMinimumValue
              rangeMaximumValue
              selectVariants {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
              partAttributeType {
                partAttributeTypeId
                partAttributeTypeName
                partAttributeTypeDescription
                sortOrder
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
            }
            selectValues {
              partAttributeSelectVariantId
              textValue
              codeValue
              isDeleted
            }
          }
          partCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          partImages {
            partImageId
            sortOrder
            isDefault
            document {
              documentId
              documentName
              documentIdentifier
              documentTypeId
              storagePath
              extension
              encodedUrn
              documentStatusTypeId
              isMappedFromShop
              folderDocumentResponse {
                folderDocumentId
                folderId
                folderName
                documentId
              }
              documentNameFormatted
              createdOn
              createdBy
            }
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
          }
          documentIds
          unitOfMeasure {
            unitOfMeasureId
            unitOfMeasureTypeId
            companyId
            unitOfMeasureName
            unitOfMeasureCode
            createdOn
            createdBy
            updatedOn
            modifiedBy
            modifiedOn
          }
        }
        assemblyId
        historyTypeId
        assemblyNodes {
          quantity
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
          shopTaskId
          assemblyNodeId
          historyTypeId
          assembly {
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            assemblyId
            historyTypeId
            assemblyNodes {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
            assemblyEdges {
              assemblyEdgeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyEdgeType {
                assemblyEdgeTypeId
                assemblyEdgeTypeName
              }
              beginHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              endHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              beginNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              endNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
            }
          }
          assemblyNodeType {
            assemblyNodeTypeId
            assemblyNodeTypeName
          }
          assemblyNodeName
          assemblyNodeDescription
          positionX
          positionY
        }
        assemblyEdges {
          assemblyEdgeId
          historyTypeId
          assembly {
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            assemblyId
            historyTypeId
            assemblyNodes {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
            assemblyEdges {
              assemblyEdgeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyEdgeType {
                assemblyEdgeTypeId
                assemblyEdgeTypeName
              }
              beginHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              endHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              beginNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              endNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
            }
          }
          assemblyEdgeType {
            assemblyEdgeTypeId
            assemblyEdgeTypeName
          }
          beginHandlePosition {
            assemblyHandlePositionId
            assemblyHandlePositionName
          }
          endHandlePosition {
            assemblyHandlePositionId
            assemblyHandlePositionName
          }
          beginNode {
            quantity
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            shopTaskId
            assemblyNodeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyNodeType {
              assemblyNodeTypeId
              assemblyNodeTypeName
            }
            assemblyNodeName
            assemblyNodeDescription
            positionX
            positionY
          }
          endNode {
            quantity
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            shopTaskId
            assemblyNodeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyNodeType {
              assemblyNodeTypeId
              assemblyNodeTypeName
            }
            assemblyNodeName
            assemblyNodeDescription
            positionX
            positionY
          }
        }
      }
      assemblyNodeType {
        assemblyNodeTypeId
        assemblyNodeTypeName
      }
      assemblyNodeName
      assemblyNodeDescription
      positionX
      positionY
    }
  }
`;
export const assemblyNodeById = /* GraphQL */ `
  query AssemblyNodeById($params: QueryAssemblyNodeByIdParamsInput!) {
    assemblyNodeById(params: $params) {
      quantity
      part {
        partId
        partName
        description
        stateId
        isDefault
        manufacturerCatalogNumber
        unitOfMeasureCode
        unitOfMeasureName
        upcCode
        url
        catalogUrl
        partUrl
        internalPartNumber
        createdOn
        hasAssembly
        manufacturer {
          manufacturerId
          companyId
          manufacturerName
          homePageUrl
        }
        attriubuteVariantData {
          partAttributeVariantDataId
          partAttributeId
          partAttributeName
          rangeValue
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
          partAttribute {
            partAttributeId
            partAttributeName
            allowWriteInVariant
            rangeMinimumValue
            rangeMaximumValue
            selectVariants {
              partAttributeSelectVariantId
              textValue
              codeValue
              isDeleted
            }
            partAttributeType {
              partAttributeTypeId
              partAttributeTypeName
              partAttributeTypeDescription
              sortOrder
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          selectValues {
            partAttributeSelectVariantId
            textValue
            codeValue
            isDeleted
          }
        }
        partCategory {
          partCategoryId
          partCategoryName
          partCatalog {
            partCatalogId
            partCatalogName
            companyId
            topLevelNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          parentPartCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          subNodes {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
        }
        partImages {
          partImageId
          sortOrder
          isDefault
          document {
            documentId
            documentName
            documentIdentifier
            documentTypeId
            storagePath
            extension
            encodedUrn
            documentStatusTypeId
            isMappedFromShop
            folderDocumentResponse {
              folderDocumentId
              folderId
              folderName
              documentId
            }
            documentNameFormatted
            createdOn
            createdBy
          }
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
        }
        documentIds
        unitOfMeasure {
          unitOfMeasureId
          unitOfMeasureTypeId
          companyId
          unitOfMeasureName
          unitOfMeasureCode
          createdOn
          createdBy
          updatedOn
          modifiedBy
          modifiedOn
        }
      }
      shopTaskId
      assemblyNodeId
      historyTypeId
      assembly {
        part {
          partId
          partName
          description
          stateId
          isDefault
          manufacturerCatalogNumber
          unitOfMeasureCode
          unitOfMeasureName
          upcCode
          url
          catalogUrl
          partUrl
          internalPartNumber
          createdOn
          hasAssembly
          manufacturer {
            manufacturerId
            companyId
            manufacturerName
            homePageUrl
          }
          attriubuteVariantData {
            partAttributeVariantDataId
            partAttributeId
            partAttributeName
            rangeValue
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            partAttribute {
              partAttributeId
              partAttributeName
              allowWriteInVariant
              rangeMinimumValue
              rangeMaximumValue
              selectVariants {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
              partAttributeType {
                partAttributeTypeId
                partAttributeTypeName
                partAttributeTypeDescription
                sortOrder
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
            }
            selectValues {
              partAttributeSelectVariantId
              textValue
              codeValue
              isDeleted
            }
          }
          partCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          partImages {
            partImageId
            sortOrder
            isDefault
            document {
              documentId
              documentName
              documentIdentifier
              documentTypeId
              storagePath
              extension
              encodedUrn
              documentStatusTypeId
              isMappedFromShop
              folderDocumentResponse {
                folderDocumentId
                folderId
                folderName
                documentId
              }
              documentNameFormatted
              createdOn
              createdBy
            }
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
          }
          documentIds
          unitOfMeasure {
            unitOfMeasureId
            unitOfMeasureTypeId
            companyId
            unitOfMeasureName
            unitOfMeasureCode
            createdOn
            createdBy
            updatedOn
            modifiedBy
            modifiedOn
          }
        }
        assemblyId
        historyTypeId
        assemblyNodes {
          quantity
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
          shopTaskId
          assemblyNodeId
          historyTypeId
          assembly {
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            assemblyId
            historyTypeId
            assemblyNodes {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
            assemblyEdges {
              assemblyEdgeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyEdgeType {
                assemblyEdgeTypeId
                assemblyEdgeTypeName
              }
              beginHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              endHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              beginNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              endNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
            }
          }
          assemblyNodeType {
            assemblyNodeTypeId
            assemblyNodeTypeName
          }
          assemblyNodeName
          assemblyNodeDescription
          positionX
          positionY
        }
        assemblyEdges {
          assemblyEdgeId
          historyTypeId
          assembly {
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            assemblyId
            historyTypeId
            assemblyNodes {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
            assemblyEdges {
              assemblyEdgeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyEdgeType {
                assemblyEdgeTypeId
                assemblyEdgeTypeName
              }
              beginHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              endHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              beginNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              endNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
            }
          }
          assemblyEdgeType {
            assemblyEdgeTypeId
            assemblyEdgeTypeName
          }
          beginHandlePosition {
            assemblyHandlePositionId
            assemblyHandlePositionName
          }
          endHandlePosition {
            assemblyHandlePositionId
            assemblyHandlePositionName
          }
          beginNode {
            quantity
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            shopTaskId
            assemblyNodeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyNodeType {
              assemblyNodeTypeId
              assemblyNodeTypeName
            }
            assemblyNodeName
            assemblyNodeDescription
            positionX
            positionY
          }
          endNode {
            quantity
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            shopTaskId
            assemblyNodeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyNodeType {
              assemblyNodeTypeId
              assemblyNodeTypeName
            }
            assemblyNodeName
            assemblyNodeDescription
            positionX
            positionY
          }
        }
      }
      assemblyNodeType {
        assemblyNodeTypeId
        assemblyNodeTypeName
      }
      assemblyNodeName
      assemblyNodeDescription
      positionX
      positionY
    }
  }
`;
export const assemblyEdge = /* GraphQL */ `
  query AssemblyEdge($query: QueryAssemblyEdgeQueryInput) {
    assemblyEdge(query: $query) {
      assemblyEdgeId
      historyTypeId
      assembly {
        part {
          partId
          partName
          description
          stateId
          isDefault
          manufacturerCatalogNumber
          unitOfMeasureCode
          unitOfMeasureName
          upcCode
          url
          catalogUrl
          partUrl
          internalPartNumber
          createdOn
          hasAssembly
          manufacturer {
            manufacturerId
            companyId
            manufacturerName
            homePageUrl
          }
          attriubuteVariantData {
            partAttributeVariantDataId
            partAttributeId
            partAttributeName
            rangeValue
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            partAttribute {
              partAttributeId
              partAttributeName
              allowWriteInVariant
              rangeMinimumValue
              rangeMaximumValue
              selectVariants {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
              partAttributeType {
                partAttributeTypeId
                partAttributeTypeName
                partAttributeTypeDescription
                sortOrder
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
            }
            selectValues {
              partAttributeSelectVariantId
              textValue
              codeValue
              isDeleted
            }
          }
          partCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          partImages {
            partImageId
            sortOrder
            isDefault
            document {
              documentId
              documentName
              documentIdentifier
              documentTypeId
              storagePath
              extension
              encodedUrn
              documentStatusTypeId
              isMappedFromShop
              folderDocumentResponse {
                folderDocumentId
                folderId
                folderName
                documentId
              }
              documentNameFormatted
              createdOn
              createdBy
            }
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
          }
          documentIds
          unitOfMeasure {
            unitOfMeasureId
            unitOfMeasureTypeId
            companyId
            unitOfMeasureName
            unitOfMeasureCode
            createdOn
            createdBy
            updatedOn
            modifiedBy
            modifiedOn
          }
        }
        assemblyId
        historyTypeId
        assemblyNodes {
          quantity
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
          shopTaskId
          assemblyNodeId
          historyTypeId
          assembly {
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            assemblyId
            historyTypeId
            assemblyNodes {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
            assemblyEdges {
              assemblyEdgeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyEdgeType {
                assemblyEdgeTypeId
                assemblyEdgeTypeName
              }
              beginHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              endHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              beginNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              endNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
            }
          }
          assemblyNodeType {
            assemblyNodeTypeId
            assemblyNodeTypeName
          }
          assemblyNodeName
          assemblyNodeDescription
          positionX
          positionY
        }
        assemblyEdges {
          assemblyEdgeId
          historyTypeId
          assembly {
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            assemblyId
            historyTypeId
            assemblyNodes {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
            assemblyEdges {
              assemblyEdgeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyEdgeType {
                assemblyEdgeTypeId
                assemblyEdgeTypeName
              }
              beginHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              endHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              beginNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              endNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
            }
          }
          assemblyEdgeType {
            assemblyEdgeTypeId
            assemblyEdgeTypeName
          }
          beginHandlePosition {
            assemblyHandlePositionId
            assemblyHandlePositionName
          }
          endHandlePosition {
            assemblyHandlePositionId
            assemblyHandlePositionName
          }
          beginNode {
            quantity
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            shopTaskId
            assemblyNodeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyNodeType {
              assemblyNodeTypeId
              assemblyNodeTypeName
            }
            assemblyNodeName
            assemblyNodeDescription
            positionX
            positionY
          }
          endNode {
            quantity
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            shopTaskId
            assemblyNodeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyNodeType {
              assemblyNodeTypeId
              assemblyNodeTypeName
            }
            assemblyNodeName
            assemblyNodeDescription
            positionX
            positionY
          }
        }
      }
      assemblyEdgeType {
        assemblyEdgeTypeId
        assemblyEdgeTypeName
      }
      beginHandlePosition {
        assemblyHandlePositionId
        assemblyHandlePositionName
      }
      endHandlePosition {
        assemblyHandlePositionId
        assemblyHandlePositionName
      }
      beginNode {
        quantity
        part {
          partId
          partName
          description
          stateId
          isDefault
          manufacturerCatalogNumber
          unitOfMeasureCode
          unitOfMeasureName
          upcCode
          url
          catalogUrl
          partUrl
          internalPartNumber
          createdOn
          hasAssembly
          manufacturer {
            manufacturerId
            companyId
            manufacturerName
            homePageUrl
          }
          attriubuteVariantData {
            partAttributeVariantDataId
            partAttributeId
            partAttributeName
            rangeValue
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            partAttribute {
              partAttributeId
              partAttributeName
              allowWriteInVariant
              rangeMinimumValue
              rangeMaximumValue
              selectVariants {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
              partAttributeType {
                partAttributeTypeId
                partAttributeTypeName
                partAttributeTypeDescription
                sortOrder
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
            }
            selectValues {
              partAttributeSelectVariantId
              textValue
              codeValue
              isDeleted
            }
          }
          partCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          partImages {
            partImageId
            sortOrder
            isDefault
            document {
              documentId
              documentName
              documentIdentifier
              documentTypeId
              storagePath
              extension
              encodedUrn
              documentStatusTypeId
              isMappedFromShop
              folderDocumentResponse {
                folderDocumentId
                folderId
                folderName
                documentId
              }
              documentNameFormatted
              createdOn
              createdBy
            }
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
          }
          documentIds
          unitOfMeasure {
            unitOfMeasureId
            unitOfMeasureTypeId
            companyId
            unitOfMeasureName
            unitOfMeasureCode
            createdOn
            createdBy
            updatedOn
            modifiedBy
            modifiedOn
          }
        }
        shopTaskId
        assemblyNodeId
        historyTypeId
        assembly {
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
          assemblyId
          historyTypeId
          assemblyNodes {
            quantity
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            shopTaskId
            assemblyNodeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyNodeType {
              assemblyNodeTypeId
              assemblyNodeTypeName
            }
            assemblyNodeName
            assemblyNodeDescription
            positionX
            positionY
          }
          assemblyEdges {
            assemblyEdgeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyEdgeType {
              assemblyEdgeTypeId
              assemblyEdgeTypeName
            }
            beginHandlePosition {
              assemblyHandlePositionId
              assemblyHandlePositionName
            }
            endHandlePosition {
              assemblyHandlePositionId
              assemblyHandlePositionName
            }
            beginNode {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
            endNode {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
          }
        }
        assemblyNodeType {
          assemblyNodeTypeId
          assemblyNodeTypeName
        }
        assemblyNodeName
        assemblyNodeDescription
        positionX
        positionY
      }
      endNode {
        quantity
        part {
          partId
          partName
          description
          stateId
          isDefault
          manufacturerCatalogNumber
          unitOfMeasureCode
          unitOfMeasureName
          upcCode
          url
          catalogUrl
          partUrl
          internalPartNumber
          createdOn
          hasAssembly
          manufacturer {
            manufacturerId
            companyId
            manufacturerName
            homePageUrl
          }
          attriubuteVariantData {
            partAttributeVariantDataId
            partAttributeId
            partAttributeName
            rangeValue
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            partAttribute {
              partAttributeId
              partAttributeName
              allowWriteInVariant
              rangeMinimumValue
              rangeMaximumValue
              selectVariants {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
              partAttributeType {
                partAttributeTypeId
                partAttributeTypeName
                partAttributeTypeDescription
                sortOrder
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
            }
            selectValues {
              partAttributeSelectVariantId
              textValue
              codeValue
              isDeleted
            }
          }
          partCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          partImages {
            partImageId
            sortOrder
            isDefault
            document {
              documentId
              documentName
              documentIdentifier
              documentTypeId
              storagePath
              extension
              encodedUrn
              documentStatusTypeId
              isMappedFromShop
              folderDocumentResponse {
                folderDocumentId
                folderId
                folderName
                documentId
              }
              documentNameFormatted
              createdOn
              createdBy
            }
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
          }
          documentIds
          unitOfMeasure {
            unitOfMeasureId
            unitOfMeasureTypeId
            companyId
            unitOfMeasureName
            unitOfMeasureCode
            createdOn
            createdBy
            updatedOn
            modifiedBy
            modifiedOn
          }
        }
        shopTaskId
        assemblyNodeId
        historyTypeId
        assembly {
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
          assemblyId
          historyTypeId
          assemblyNodes {
            quantity
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            shopTaskId
            assemblyNodeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyNodeType {
              assemblyNodeTypeId
              assemblyNodeTypeName
            }
            assemblyNodeName
            assemblyNodeDescription
            positionX
            positionY
          }
          assemblyEdges {
            assemblyEdgeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyEdgeType {
              assemblyEdgeTypeId
              assemblyEdgeTypeName
            }
            beginHandlePosition {
              assemblyHandlePositionId
              assemblyHandlePositionName
            }
            endHandlePosition {
              assemblyHandlePositionId
              assemblyHandlePositionName
            }
            beginNode {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
            endNode {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
          }
        }
        assemblyNodeType {
          assemblyNodeTypeId
          assemblyNodeTypeName
        }
        assemblyNodeName
        assemblyNodeDescription
        positionX
        positionY
      }
    }
  }
`;
export const assemblyEdgeById = /* GraphQL */ `
  query AssemblyEdgeById($params: QueryAssemblyEdgeByIdParamsInput!) {
    assemblyEdgeById(params: $params) {
      assemblyEdgeId
      historyTypeId
      assembly {
        part {
          partId
          partName
          description
          stateId
          isDefault
          manufacturerCatalogNumber
          unitOfMeasureCode
          unitOfMeasureName
          upcCode
          url
          catalogUrl
          partUrl
          internalPartNumber
          createdOn
          hasAssembly
          manufacturer {
            manufacturerId
            companyId
            manufacturerName
            homePageUrl
          }
          attriubuteVariantData {
            partAttributeVariantDataId
            partAttributeId
            partAttributeName
            rangeValue
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            partAttribute {
              partAttributeId
              partAttributeName
              allowWriteInVariant
              rangeMinimumValue
              rangeMaximumValue
              selectVariants {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
              partAttributeType {
                partAttributeTypeId
                partAttributeTypeName
                partAttributeTypeDescription
                sortOrder
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
            }
            selectValues {
              partAttributeSelectVariantId
              textValue
              codeValue
              isDeleted
            }
          }
          partCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          partImages {
            partImageId
            sortOrder
            isDefault
            document {
              documentId
              documentName
              documentIdentifier
              documentTypeId
              storagePath
              extension
              encodedUrn
              documentStatusTypeId
              isMappedFromShop
              folderDocumentResponse {
                folderDocumentId
                folderId
                folderName
                documentId
              }
              documentNameFormatted
              createdOn
              createdBy
            }
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
          }
          documentIds
          unitOfMeasure {
            unitOfMeasureId
            unitOfMeasureTypeId
            companyId
            unitOfMeasureName
            unitOfMeasureCode
            createdOn
            createdBy
            updatedOn
            modifiedBy
            modifiedOn
          }
        }
        assemblyId
        historyTypeId
        assemblyNodes {
          quantity
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
          shopTaskId
          assemblyNodeId
          historyTypeId
          assembly {
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            assemblyId
            historyTypeId
            assemblyNodes {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
            assemblyEdges {
              assemblyEdgeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyEdgeType {
                assemblyEdgeTypeId
                assemblyEdgeTypeName
              }
              beginHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              endHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              beginNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              endNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
            }
          }
          assemblyNodeType {
            assemblyNodeTypeId
            assemblyNodeTypeName
          }
          assemblyNodeName
          assemblyNodeDescription
          positionX
          positionY
        }
        assemblyEdges {
          assemblyEdgeId
          historyTypeId
          assembly {
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            assemblyId
            historyTypeId
            assemblyNodes {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
            assemblyEdges {
              assemblyEdgeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyEdgeType {
                assemblyEdgeTypeId
                assemblyEdgeTypeName
              }
              beginHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              endHandlePosition {
                assemblyHandlePositionId
                assemblyHandlePositionName
              }
              beginNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              endNode {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
            }
          }
          assemblyEdgeType {
            assemblyEdgeTypeId
            assemblyEdgeTypeName
          }
          beginHandlePosition {
            assemblyHandlePositionId
            assemblyHandlePositionName
          }
          endHandlePosition {
            assemblyHandlePositionId
            assemblyHandlePositionName
          }
          beginNode {
            quantity
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            shopTaskId
            assemblyNodeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyNodeType {
              assemblyNodeTypeId
              assemblyNodeTypeName
            }
            assemblyNodeName
            assemblyNodeDescription
            positionX
            positionY
          }
          endNode {
            quantity
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            shopTaskId
            assemblyNodeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyNodeType {
              assemblyNodeTypeId
              assemblyNodeTypeName
            }
            assemblyNodeName
            assemblyNodeDescription
            positionX
            positionY
          }
        }
      }
      assemblyEdgeType {
        assemblyEdgeTypeId
        assemblyEdgeTypeName
      }
      beginHandlePosition {
        assemblyHandlePositionId
        assemblyHandlePositionName
      }
      endHandlePosition {
        assemblyHandlePositionId
        assemblyHandlePositionName
      }
      beginNode {
        quantity
        part {
          partId
          partName
          description
          stateId
          isDefault
          manufacturerCatalogNumber
          unitOfMeasureCode
          unitOfMeasureName
          upcCode
          url
          catalogUrl
          partUrl
          internalPartNumber
          createdOn
          hasAssembly
          manufacturer {
            manufacturerId
            companyId
            manufacturerName
            homePageUrl
          }
          attriubuteVariantData {
            partAttributeVariantDataId
            partAttributeId
            partAttributeName
            rangeValue
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            partAttribute {
              partAttributeId
              partAttributeName
              allowWriteInVariant
              rangeMinimumValue
              rangeMaximumValue
              selectVariants {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
              partAttributeType {
                partAttributeTypeId
                partAttributeTypeName
                partAttributeTypeDescription
                sortOrder
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
            }
            selectValues {
              partAttributeSelectVariantId
              textValue
              codeValue
              isDeleted
            }
          }
          partCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          partImages {
            partImageId
            sortOrder
            isDefault
            document {
              documentId
              documentName
              documentIdentifier
              documentTypeId
              storagePath
              extension
              encodedUrn
              documentStatusTypeId
              isMappedFromShop
              folderDocumentResponse {
                folderDocumentId
                folderId
                folderName
                documentId
              }
              documentNameFormatted
              createdOn
              createdBy
            }
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
          }
          documentIds
          unitOfMeasure {
            unitOfMeasureId
            unitOfMeasureTypeId
            companyId
            unitOfMeasureName
            unitOfMeasureCode
            createdOn
            createdBy
            updatedOn
            modifiedBy
            modifiedOn
          }
        }
        shopTaskId
        assemblyNodeId
        historyTypeId
        assembly {
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
          assemblyId
          historyTypeId
          assemblyNodes {
            quantity
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            shopTaskId
            assemblyNodeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyNodeType {
              assemblyNodeTypeId
              assemblyNodeTypeName
            }
            assemblyNodeName
            assemblyNodeDescription
            positionX
            positionY
          }
          assemblyEdges {
            assemblyEdgeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyEdgeType {
              assemblyEdgeTypeId
              assemblyEdgeTypeName
            }
            beginHandlePosition {
              assemblyHandlePositionId
              assemblyHandlePositionName
            }
            endHandlePosition {
              assemblyHandlePositionId
              assemblyHandlePositionName
            }
            beginNode {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
            endNode {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
          }
        }
        assemblyNodeType {
          assemblyNodeTypeId
          assemblyNodeTypeName
        }
        assemblyNodeName
        assemblyNodeDescription
        positionX
        positionY
      }
      endNode {
        quantity
        part {
          partId
          partName
          description
          stateId
          isDefault
          manufacturerCatalogNumber
          unitOfMeasureCode
          unitOfMeasureName
          upcCode
          url
          catalogUrl
          partUrl
          internalPartNumber
          createdOn
          hasAssembly
          manufacturer {
            manufacturerId
            companyId
            manufacturerName
            homePageUrl
          }
          attriubuteVariantData {
            partAttributeVariantDataId
            partAttributeId
            partAttributeName
            rangeValue
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            partAttribute {
              partAttributeId
              partAttributeName
              allowWriteInVariant
              rangeMinimumValue
              rangeMaximumValue
              selectVariants {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
              partAttributeType {
                partAttributeTypeId
                partAttributeTypeName
                partAttributeTypeDescription
                sortOrder
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
            }
            selectValues {
              partAttributeSelectVariantId
              textValue
              codeValue
              isDeleted
            }
          }
          partCategory {
            partCategoryId
            partCategoryName
            partCatalog {
              partCatalogId
              partCatalogName
              companyId
              topLevelNodes {
                partCategoryId
                partCategoryName
              }
            }
            parentPartCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            subNodes {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
          }
          partImages {
            partImageId
            sortOrder
            isDefault
            document {
              documentId
              documentName
              documentIdentifier
              documentTypeId
              storagePath
              extension
              encodedUrn
              documentStatusTypeId
              isMappedFromShop
              folderDocumentResponse {
                folderDocumentId
                folderId
                folderName
                documentId
              }
              documentNameFormatted
              createdOn
              createdBy
            }
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
          }
          documentIds
          unitOfMeasure {
            unitOfMeasureId
            unitOfMeasureTypeId
            companyId
            unitOfMeasureName
            unitOfMeasureCode
            createdOn
            createdBy
            updatedOn
            modifiedBy
            modifiedOn
          }
        }
        shopTaskId
        assemblyNodeId
        historyTypeId
        assembly {
          part {
            partId
            partName
            description
            stateId
            isDefault
            manufacturerCatalogNumber
            unitOfMeasureCode
            unitOfMeasureName
            upcCode
            url
            catalogUrl
            partUrl
            internalPartNumber
            createdOn
            hasAssembly
            manufacturer {
              manufacturerId
              companyId
              manufacturerName
              homePageUrl
            }
            attriubuteVariantData {
              partAttributeVariantDataId
              partAttributeId
              partAttributeName
              rangeValue
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              partAttribute {
                partAttributeId
                partAttributeName
                allowWriteInVariant
                rangeMinimumValue
                rangeMaximumValue
              }
              selectValues {
                partAttributeSelectVariantId
                textValue
                codeValue
                isDeleted
              }
            }
            partCategory {
              partCategoryId
              partCategoryName
              partCatalog {
                partCatalogId
                partCatalogName
                companyId
              }
              parentPartCategory {
                partCategoryId
                partCategoryName
              }
              subNodes {
                partCategoryId
                partCategoryName
              }
            }
            partImages {
              partImageId
              sortOrder
              isDefault
              document {
                documentId
                documentName
                documentIdentifier
                documentTypeId
                storagePath
                extension
                encodedUrn
                documentStatusTypeId
                isMappedFromShop
                documentNameFormatted
                createdOn
                createdBy
              }
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
            }
            documentIds
            unitOfMeasure {
              unitOfMeasureId
              unitOfMeasureTypeId
              companyId
              unitOfMeasureName
              unitOfMeasureCode
              createdOn
              createdBy
              updatedOn
              modifiedBy
              modifiedOn
            }
          }
          assemblyId
          historyTypeId
          assemblyNodes {
            quantity
            part {
              partId
              partName
              description
              stateId
              isDefault
              manufacturerCatalogNumber
              unitOfMeasureCode
              unitOfMeasureName
              upcCode
              url
              catalogUrl
              partUrl
              internalPartNumber
              createdOn
              hasAssembly
              manufacturer {
                manufacturerId
                companyId
                manufacturerName
                homePageUrl
              }
              attriubuteVariantData {
                partAttributeVariantDataId
                partAttributeId
                partAttributeName
                rangeValue
              }
              partCategory {
                partCategoryId
                partCategoryName
              }
              partImages {
                partImageId
                sortOrder
                isDefault
              }
              documentIds
              unitOfMeasure {
                unitOfMeasureId
                unitOfMeasureTypeId
                companyId
                unitOfMeasureName
                unitOfMeasureCode
                createdOn
                createdBy
                updatedOn
                modifiedBy
                modifiedOn
              }
            }
            shopTaskId
            assemblyNodeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyNodeType {
              assemblyNodeTypeId
              assemblyNodeTypeName
            }
            assemblyNodeName
            assemblyNodeDescription
            positionX
            positionY
          }
          assemblyEdges {
            assemblyEdgeId
            historyTypeId
            assembly {
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              assemblyId
              historyTypeId
              assemblyNodes {
                quantity
                shopTaskId
                assemblyNodeId
                historyTypeId
                assemblyNodeName
                assemblyNodeDescription
                positionX
                positionY
              }
              assemblyEdges {
                assemblyEdgeId
                historyTypeId
              }
            }
            assemblyEdgeType {
              assemblyEdgeTypeId
              assemblyEdgeTypeName
            }
            beginHandlePosition {
              assemblyHandlePositionId
              assemblyHandlePositionName
            }
            endHandlePosition {
              assemblyHandlePositionId
              assemblyHandlePositionName
            }
            beginNode {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
            endNode {
              quantity
              part {
                partId
                partName
                description
                stateId
                isDefault
                manufacturerCatalogNumber
                unitOfMeasureCode
                unitOfMeasureName
                upcCode
                url
                catalogUrl
                partUrl
                internalPartNumber
                createdOn
                hasAssembly
                documentIds
              }
              shopTaskId
              assemblyNodeId
              historyTypeId
              assembly {
                assemblyId
                historyTypeId
              }
              assemblyNodeType {
                assemblyNodeTypeId
                assemblyNodeTypeName
              }
              assemblyNodeName
              assemblyNodeDescription
              positionX
              positionY
            }
          }
        }
        assemblyNodeType {
          assemblyNodeTypeId
          assemblyNodeTypeName
        }
        assemblyNodeName
        assemblyNodeDescription
        positionX
        positionY
      }
    }
  }
`;
export const syncLogs = /* GraphQL */ `
  query SyncLogs($query: QuerySyncLogsQueryInput) {
    syncLogs(query: $query) {
      workflowName
      workflowId
      totalFiles
      successfulFilesCount
      mappings {
        mappingName
        mappingId
        totalFiles
        successfulFilesCount
        solitaryFlows {
          fileSyncLogId
          source {
            name
            type
          }
          syncStatus
          syncType
          totalFiles
          totalSize
          successfulFilesCount
          actionAlias
          destinations {
            name
            type
          }
          lastSyncTime
          isContactSupportTeam
        }
        publishModelSolitaryFlows {
          fileSyncLogId
          source {
            name
            type
          }
          syncStatus
          syncType
          totalFiles
          totalSize
          successfulFilesCount
          actionAlias
          destinations {
            name
            type
          }
          lastSyncTime
          isContactSupportTeam
        }
        lastSyncTime
      }
      lastSyncTime
    }
  }
`;
export const syncRealTimeLogs = /* GraphQL */ `
  query SyncRealTimeLogs($query: QuerySyncRealTimeLogsQueryInput) {
    syncRealTimeLogs(query: $query) {
      date
      files {
        fileName
        fileSyncTime
        solitaryFlows {
          workflowId
          workflowName
          mappingId
          mappingName
          actionAlias
          source
          sourcePath
          destinations {
            fileSyncLogId
            destination
            destinationPath
            syncStatus
            isContactSupportTeam
          }
        }
      }
    }
  }
`;
export const syncLogFileDetails = /* GraphQL */ `
  query SyncLogFileDetails($query: QuerySyncLogFileDetailsQueryInput) {
    syncLogFileDetails(query: $query)
  }
`;
export const syncLogActionDetails = /* GraphQL */ `
  query SyncLogActionDetails($query: QuerySyncLogActionDetailsQueryInput) {
    syncLogActionDetails(query: $query) {
      workflowId
      workflowName
      mappingId
      mappingName
      actionAlias
      syncStatus
      data {
        destinations {
          syncFlowMetricId
          name
          terminalStatus
          publishModelSuccess
          publishModelMessage
          issueManagementCount
          numFiles
          totalSize
        }
        isComplete
        isPublishModel
        isIssueManagement
      }
    }
  }
`;
export const syncLogActionFolderDetails = /* GraphQL */ `
  query SyncLogActionFolderDetails(
    $query: QuerySyncLogActionFolderDetailsQueryInput
  ) {
    syncLogActionFolderDetails(query: $query) {
      syncFolderMetricId
      name
      numFiles
      numSubFolders
      terminalStatus
      removeFilesNotInSourceFailed
      removeSubFoldersNotInSourceFailed
      skippedFilesDueToLimit
      skippedSubFoldersDueToDepth
      totalSize
    }
  }
`;
export const syncLogActionFileDetails = /* GraphQL */ `
  query SyncLogActionFileDetails(
    $query: QuerySyncLogActionFileDetailsQueryInput
  ) {
    syncLogActionFileDetails(query: $query) {
      name
      fileSyncDate
      terminalStatus
      totalSize
    }
  }
`;
export const getAutodeskGlueProjects = /* GraphQL */ `
  query GetAutodeskGlueProjects(
    $query: QueryGetAutodeskGlueProjectsQueryInput
  ) {
    getAutodeskGlueProjects(query: $query) {
      id
      text
    }
  }
`;
export const getAutodeskGlueFolders = /* GraphQL */ `
  query GetAutodeskGlueFolders($query: QueryGetAutodeskGlueFoldersQueryInput) {
    getAutodeskGlueFolders(query: $query) {
      id
      text
      isFolder
    }
  }
`;
export const getAutodeskGlueSelectedFolderIdStack = /* GraphQL */ `
  query GetAutodeskGlueSelectedFolderIdStack(
    $query: QueryGetAutodeskGlueSelectedFolderIdStackQueryInput
  ) {
    getAutodeskGlueSelectedFolderIdStack(query: $query)
  }
`;
export const getAutodeskGlueFolderName = /* GraphQL */ `
  query GetAutodeskGlueFolderName(
    $query: QueryGetAutodeskGlueFolderNameQueryInput
  ) {
    getAutodeskGlueFolderName(query: $query) {
      name
    }
  }
`;
export const getSharepointSites = /* GraphQL */ `
  query GetSharepointSites($query: QueryGetSharepointSitesQueryInput) {
    getSharepointSites(query: $query) {
      id
      text
    }
  }
`;
export const getSharepointFolders = /* GraphQL */ `
  query GetSharepointFolders($query: QueryGetSharepointFoldersQueryInput) {
    getSharepointFolders(query: $query) {
      id
      text
      isFolder
    }
  }
`;
export const getSharepointSelectedFolderIdStack = /* GraphQL */ `
  query GetSharepointSelectedFolderIdStack(
    $query: QueryGetSharepointSelectedFolderIdStackQueryInput
  ) {
    getSharepointSelectedFolderIdStack(query: $query)
  }
`;
export const getSharepointFolderName = /* GraphQL */ `
  query GetSharepointFolderName(
    $query: QueryGetSharepointFolderNameQueryInput
  ) {
    getSharepointFolderName(query: $query) {
      success
      name
    }
  }
`;
export const getNewformaProjects = /* GraphQL */ `
  query GetNewformaProjects($query: QueryGetNewformaProjectsQueryInput) {
    getNewformaProjects(query: $query) {
      id
      text
    }
  }
`;
export const getNewformaFolders = /* GraphQL */ `
  query GetNewformaFolders($query: QueryGetNewformaFoldersQueryInput) {
    getNewformaFolders(query: $query) {
      id
      text
    }
  }
`;
export const getNewformaSelectedFolderIdStack = /* GraphQL */ `
  query GetNewformaSelectedFolderIdStack(
    $query: QueryGetNewformaSelectedFolderIdStackQueryInput
  ) {
    getNewformaSelectedFolderIdStack(query: $query)
  }
`;
export const getNewformaFolderName = /* GraphQL */ `
  query GetNewformaFolderName($query: QueryGetNewformaFolderNameQueryInput) {
    getNewformaFolderName(query: $query) {
      success
      name
    }
  }
`;
export const getPlangridProjects = /* GraphQL */ `
  query GetPlangridProjects($query: QueryGetPlangridProjectsQueryInput) {
    getPlangridProjects(query: $query) {
      id
      text
    }
  }
`;
export const getPlangridFolders = /* GraphQL */ `
  query GetPlangridFolders($query: QueryGetPlangridFoldersQueryInput) {
    getPlangridFolders(query: $query) {
      id
      text
    }
  }
`;
export const getPlangridSelectedFolderIdStack = /* GraphQL */ `
  query GetPlangridSelectedFolderIdStack(
    $query: QueryGetPlangridSelectedFolderIdStackQueryInput
  ) {
    getPlangridSelectedFolderIdStack(query: $query)
  }
`;
export const getPlangridFolderName = /* GraphQL */ `
  query GetPlangridFolderName($query: QueryGetPlangridFolderNameQueryInput) {
    getPlangridFolderName(query: $query) {
      success
      name
    }
  }
`;
export const syncGetWorkflowMappingSyncStatus = /* GraphQL */ `
  query SyncGetWorkflowMappingSyncStatus(
    $query: QuerySyncGetWorkflowMappingSyncStatusQueryInput
  ) {
    syncGetWorkflowMappingSyncStatus(query: $query) {
      isSyncRunning
      lastSyncAt
      solitaryFlows {
        id
        actionNodeId
        syncStatus
      }
    }
  }
`;
export const assemblyCustomCategories = /* GraphQL */ `
  query AssemblyCustomCategories(
    $query: QueryAssemblyCustomCategoriesQueryInput
  ) {
    assemblyCustomCategories(query: $query) {
      assemblyCustomCategoryId
      workFlowId
      companyId
      assemblyCustomCategoryName
    }
  }
`;
export const assemblyCustomCategoryById = /* GraphQL */ `
  query AssemblyCustomCategoryById(
    $params: QueryAssemblyCustomCategoryByIdParamsInput!
  ) {
    assemblyCustomCategoryById(params: $params) {
      assemblyCustomCategoryId
      workFlowId
      companyId
      assemblyCustomCategoryName
    }
  }
`;
export const syncFetchForesiteCompanies = /* GraphQL */ `
  query SyncFetchForesiteCompanies(
    $query: QuerySyncFetchForesiteCompaniesQueryInput
  ) {
    syncFetchForesiteCompanies(query: $query) {
      id
      text
    }
  }
`;
export const syncFetchForesiteProjects = /* GraphQL */ `
  query SyncFetchForesiteProjects(
    $query: QuerySyncFetchForesiteProjectsQueryInput
  ) {
    syncFetchForesiteProjects(query: $query) {
      id
      text
    }
  }
`;
export const syncFetchForesiteFolders = /* GraphQL */ `
  query SyncFetchForesiteFolders(
    $query: QuerySyncFetchForesiteFoldersQueryInput
  ) {
    syncFetchForesiteFolders(query: $query) {
      id
      text
      isFolder
    }
  }
`;
export const syncFetchForesiteSelectedFolderIdStack = /* GraphQL */ `
  query SyncFetchForesiteSelectedFolderIdStack(
    $query: QuerySyncFetchForesiteSelectedFolderIdStackQueryInput
  ) {
    syncFetchForesiteSelectedFolderIdStack(query: $query)
  }
`;
export const checkCustomAssemblyNameUnique = /* GraphQL */ `
  query CheckCustomAssemblyNameUnique(
    $query: QueryCheckCustomAssemblyNameUniqueQueryInput
  ) {
    checkCustomAssemblyNameUnique(query: $query) {
      isUnique
    }
  }
`;
export const checkCustomAssemblyIdentifierUnique = /* GraphQL */ `
  query CheckCustomAssemblyIdentifierUnique(
    $query: QueryCheckCustomAssemblyIdentifierUniqueQueryInput
  ) {
    checkCustomAssemblyIdentifierUnique(query: $query) {
      isUnique
    }
  }
`;
export const customAssemblies = /* GraphQL */ `
  query CustomAssemblies($query: QueryCustomAssembliesQueryInput) {
    customAssemblies(query: $query) {
      lineItemId
      lineItemTypeId
      lineItemTypeName
      projectId
      customAssemblyName
      customAssemblyIdentifier
      assemblyCustomCategoryId
      assemblyCustomCategoryName
      customAssemblyStatus
      assignedto
      assignedtoFirstName
      assignedtoLastName
      assignedtoEmail
      quantity
      createdOn
      createdBy
      createdByFirstName
      createdByLastName
      createdByEmail
      hasChildren
    }
  }
`;
export const syncGetWindowsFolders = /* GraphQL */ `
  query SyncGetWindowsFolders($query: QuerySyncGetWindowsFoldersQueryInput) {
    syncGetWindowsFolders(query: $query) {
      id
      text
      isFolder
    }
  }
`;
export const syncValidateName = /* GraphQL */ `
  query SyncValidateName(
    $params: QuerySyncValidateNameParamsInput!
    $query: QuerySyncValidateNameQueryInput
  ) {
    syncValidateName(params: $params, query: $query)
  }
`;
export const platformSettings = /* GraphQL */ `
  query PlatformSettings($query: QueryPlatformSettingsQueryInput) {
    platformSettings(query: $query) {
      platformSettingId
      platformSettingName
      platformSettingValue
    }
  }
`;
export const syncRecommendedScheduleTimes = /* GraphQL */ `
  query SyncRecommendedScheduleTimes(
    $query: QuerySyncRecommendedScheduleTimesQueryInput
  ) {
    syncRecommendedScheduleTimes(query: $query)
  }
`;
