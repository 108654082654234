import React, { useCallback, useMemo, useState } from 'react';

import { onUpdateQueryCallback, onlyActiveCallback } from './callbacks';
import { OPTIONS } from './constants';
import { FilterOptionsProps, OptionsFilterQuerySchema } from './types';

export const FilterOptions = React.createContext<FilterOptionsProps>(null);

export const FilterOptionsProvider = ({ children }: { children: React.ReactNode }) => {
  const [showFilters, setShowFilters] = useState(true);
  const [query, setQuery] = useState<OptionsFilterQuerySchema>({});

  const onUpdateQuery = useCallback(
    (query: OptionsFilterQuerySchema) => setQuery((prev) => onUpdateQueryCallback(query, prev)),
    [],
  );

  const onClearQuery = useCallback(() => setQuery({}), []);
  const activeOptions = useMemo(() => OPTIONS.filter((option) => option.active), []);
  const onlyActive = useMemo(() => onlyActiveCallback(activeOptions, query), [activeOptions, query]);

  return (
    <FilterOptions.Provider
      value={{ options: activeOptions, query, onUpdateQuery, onClearQuery, onlyActive, showFilters, setShowFilters }}
    >
      {children}
    </FilterOptions.Provider>
  );
};

export const useFilterOptions = () => {
  const context = React.useContext(FilterOptions);
  if (!context) throw new Error('useFilterOptions require FilterOptionsProvider');
  return context;
};
