import { EntitlementCreateParams, EntitlementCreateResponse } from 'types/types-api';

import { useWrappedPost } from './useWrappedApiCall';

export const useEntitlementCreate = () => {
  const { apiCall: doEntitlementCreate, ...rest } = useWrappedPost<EntitlementCreateResponse, EntitlementCreateParams>(
    'admin/entitlement',
  );
  return {
    ...rest,
    doEntitlementCreate,
  };
};
