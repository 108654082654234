import { useEffect, useState } from 'react';

import { gql, useLazyQuery } from '@apollo/client';

import { departmentUsers as DEPARTMENT_USERS } from 'graphql/queries';

const ONLY_ADMIN_QUERY_STRING = 'only admin';
const InvitationStatus = {
  ACTIVE: 'Active',
  PENDING: 'Pending',
};

const useShopAdmin = (userId, departmentId) => {
  const [isShopAdmin, setIsShopAdmin] = useState(false);
  const [isInvitationCompleted, setIsInvitationCompleted] = useState(false);

  const [fetchDepartmentUsers] = useLazyQuery(gql(DEPARTMENT_USERS));

  useEffect(() => {
    if (!userId || !departmentId) return;

    const checkIsUserShopAdmin = async () => {
      const { data } = await fetchDepartmentUsers({
        variables: {
          query: {
            departmentId,
            searchPhrase: userId,
            includeAdmin: ONLY_ADMIN_QUERY_STRING,
            skip: 0,
            take: 1,
          },
        },
      });
      setIsShopAdmin(Boolean(data?.departmentUsers?.length));

      if (data?.departmentUsers?.length) {
        const [departmentUser] = data.departmentUsers;
        setIsInvitationCompleted(departmentUser?.inviteStatus === InvitationStatus.ACTIVE);
      }
    };
    checkIsUserShopAdmin();
  }, [userId, departmentId, fetchDepartmentUsers]);

  return { isShopAdmin, isInvitationCompleted };
};

export default useShopAdmin;
