import React from 'react';

import { Typography } from '@mui/material';

const CategoryItemText = ({ sx, children }) => (
  <Typography variant="body2" sx={{ color: 'rgba(0, 0, 0, 0.87)', ...sx, flex: 1 }} noWrap>
    {children}
  </Typography>
);

export default CategoryItemText;
