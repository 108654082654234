import { useMemo } from 'react';

import { useMaterialsConfig } from 'hooks-api/useMaterialsConfig';

export const useGetGenericManufacturer = () => {
  const { manufactured: manufacturers } = useMaterialsConfig();

  const genericManufacturer = useMemo(
    () => manufacturers?.find((item: { id: string; label: string }) => item?.label?.toLowerCase() === 'generic'),
    [manufacturers],
  );

  return genericManufacturer;
};
