import React from 'react';

import { useWorkOrderItemFormEvents } from '../hooks/useWorkOrderItemFormEvents';

const TableRowFormContext = React.createContext();

const TableRowFormProvider = ({ children }) => {
  const selectedStateObj = {
    ...useWorkOrderItemFormEvents(),
  };

  return (
    <TableRowFormContext.Provider value={selectedStateObj}>{children}</TableRowFormContext.Provider>
  );
};

const useTableRowFormContext = () => {
  const context = React.useContext(TableRowFormContext);

  if (context === undefined) {
    throw new Error('useTableRowFormContext must be used within a TableRowFormContext');
  }

  return context;
};

export { TableRowFormContext, TableRowFormProvider, useTableRowFormContext };
