import React, { FC, ReactNode } from 'react';

import { Stack } from '@mui/material';

import WarningTooltip from 'components/WarningTooltip';

interface ITooltipWrapper {
  showTooltip?: boolean;
  title?: string;
  children: ReactNode;
  isWarningTooltip?: boolean;
}

const TooltipWrapper: FC<ITooltipWrapper> = ({
  showTooltip = false,
  title = '',
  children,
  isWarningTooltip,
}: ITooltipWrapper) => {
  if (!showTooltip) return <>{children}</>;
  return (
    <WarningTooltip
      {...(isWarningTooltip ? {} : { componentsProps: { popper: { sx: {} } } })}
      placement="right"
      arrow
      title={title}
    >
      <Stack>{children}</Stack>
    </WarningTooltip>
  );
};

export default TooltipWrapper;
