import React, { useMemo, useContext, useEffect, useState, useCallback } from 'react';

import { gql, useLazyQuery, useMutation } from '@apollo/client';

import { SESSION_STORAGE_CONSTANTS } from 'constants/globalConstants';
import {
  addSubLevelPartCategory as ADD_SUB_CATEGORY,
  updatePartCategory as CHANGE_NAME,
} from 'graphql/mutations';
import { partCategory as CATEGORY_LIST } from 'graphql/queries';

import { useCatalogSetupContext } from '../CatalogSetupHeader/CatalogSetupContext';
import { useCatalogPartContext } from '../Providers/CatalogPartProvider';
import { addNewSubcategory, updateSubcategory } from './categoriesHelpers';

const { PART_CATEGORY_FROM_WR } = SESSION_STORAGE_CONSTANTS;

export const CategoriesContext = React.createContext();

export const CategoriesProvider = ({ children }) => {
  const { selectedCategory, setSelectedCategory } = useCatalogPartContext();
  const [activeInput, setActiveInput] = useState(false);
  const [lastAddedCategoryParentId, setLastAddedCategoryParentId] = useState(null);
  const { selectedCatalog } = useCatalogSetupContext();
  const [
    fetchCategories,
    {
      called: calledCategories,
      data: categoriesData,
      loading: loadingCategories,
      refetch: refetchCategories,
    },
  ] = useLazyQuery(gql(CATEGORY_LIST), {
    fetchPolicy: 'cache-and-network',
    variables: {
      query: {
        companyId: selectedCatalog?.companyId,
        partCatalogId: selectedCatalog?.partCatalogId,
        orderBy: 'name:asc',
        take: 99999,
        parentPartCategoryId: '',
      },
    },
  });

  const [addSubCategory] = useMutation(gql(ADD_SUB_CATEGORY));
  const [changeName] = useMutation(gql(CHANGE_NAME));

  useEffect(() => {
    const stringCategory = sessionStorage.getItem(PART_CATEGORY_FROM_WR);
    const categoryFromWR = JSON.parse(stringCategory);
    sessionStorage.removeItem(PART_CATEGORY_FROM_WR);
    if (categoryFromWR) return setSelectedCategory(categoryFromWR);

    const fetchCategoryAsync = async () => {
      const response = await fetchCategories();
      if (response?.data?.partCategory?.length > 0)
        setSelectedCategory(response?.data?.partCategory[0]);
    };
    if (selectedCatalog?.partCatalogId) return fetchCategoryAsync();
    return '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedCategory)
      refetchCategories({
        companyId: selectedCatalog?.companyId,
        parentPartCategoryId: selectedCategory?.partCategoryId,
        orderBy: 'name:asc',
        take: 99999,
      });
  }, [selectedCategory, refetchCategories, selectedCatalog?.companyId]);

  const onSubmit = useCallback(
    async (values) => {
      const { isEditing, parentPartCategoryId, categoryName } = values;

      if (!isEditing) {
        await addNewSubcategory(
          parentPartCategoryId,
          selectedCatalog,
          categoryName,
          addSubCategory,
        );
        setLastAddedCategoryParentId(parentPartCategoryId);
        return;
      }

      await updateSubcategory(parentPartCategoryId, categoryName, changeName);
    },
    [addSubCategory, changeName, selectedCatalog],
  );

  const valuesObj = useMemo(
    () => ({
      categoriesData,
      loadingCategories,
      calledCategories,
      refetchCategories,
      selectedCategory,
      setSelectedCategory,
      activeInput,
      setActiveInput,
      addSubCategory,
      onSubmit,
      lastAddedCategoryParentId,
    }),
    [
      categoriesData,
      loadingCategories,
      calledCategories,
      refetchCategories,
      selectedCategory,
      setSelectedCategory,
      activeInput,
      setActiveInput,
      addSubCategory,
      onSubmit,
      lastAddedCategoryParentId,
    ],
  );

  return <CategoriesContext.Provider value={valuesObj}>{children}</CategoriesContext.Provider>;
};

export const useCategoriesContext = () => {
  const context = useContext(CategoriesContext);
  if (context === undefined) {
    throw new Error('useCategoriesContext must be used within a CategoriesProvider');
  }
  return context;
};
