import { useCallback } from 'react';

import useFolderDocuments from './useFolderDocuments';

const useMapFolder = () => {
  const { moveDocuments } = useFolderDocuments();

  const handleMappedFolder = useCallback(
    async (params, selectedRows, setIsMapping) => {
      const { destination, draggableId } = params;
      const [, targetTaskId] = destination?.droppableId.split('/') ?? [];

      setIsMapping(true);
      const documentIds = selectedRows.length ? selectedRows.map((selectedRow) => selectedRow.id) : [draggableId];
      await moveDocuments(documentIds, targetTaskId);
      setIsMapping(false);
    },

    [moveDocuments],
  );

  return { handleMappedFolder };
};

export default useMapFolder;
