import React from 'react';

import { Radio } from '@mui/material';

import StatusBadge from 'components/StatusBadges/StatusBadge';
import { BADGE_MAPPING_STATUS } from 'constants/badgeMappingStatus';

export const CellComponentStatus = ({ value }) => {
  const newStatus = BADGE_MAPPING_STATUS[value];

  return (
    <StatusBadge key={newStatus?.variant} label={newStatus?.label || value} variant={newStatus?.variant || value} />
  );
};

export const RadioCell = ({ field, row, onChangeRadioButtons, ...props }) => (
  <Radio
    checked={!!props?.value}
    onChange={() => onChangeRadioButtons(row.taskId, field)}
    size="small"
    sx={{
      color: 'action.lightSurface.active',
      '&.Mui-checked': {
        color: 'secondary.main',
      },
    }}
  />
);
