import React from 'react';

import { Custom } from 'components/Icons';
import StackRow from 'components/StackRow';

export const StyledBox = (props) => (
  <StackRow
    sx={{
      position: 'absolute',
      justifyContent: 'center',
      opacity: 0,
      '&:hover': {
        cursor: 'pointer',
        opacity: 1,
        transition: 'all 0.5s ease-out',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
      },
    }}
    {...props}
  />
);

export const InputImageContainer = ({ sx, ...props }) => (
  <StackRow
    sx={{
      justifyContent: 'center',
      backgroundColor: '#EEEEEE',
      cursor: 'pointer',
      borderRadius: '4px',
      width: '100%',
      height: '200px',
      ...sx,
    }}
    {...props}
  />
);

export const ProgressWrapper = ({ sx, ...props }) => (
  <StackRow
    sx={{
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: 'absolute',
      justifyContent: 'center',
      ...sx,
    }}
    {...props}
  />
);

export const StyledEditInputImage = ({ sx, ...props }) => (
  <StackRow
    sx={{
      position: 'absolute',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      transition: 'all 0.5s ease-out',
      opacity: 1,
      '&:hover': {
        opacity: 1,
      },
      ...sx,
    }}
    {...props}
  />
);

export const StyledEditIcon = ({ sx, ...props }) => (
  <StackRow
    sx={{
      width: '32px',
      height: '32px',
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      justifyContent: 'center',
      cursor: 'pointer',
      '&:hover': {
        background: 'rgba(0, 0, 0, 0.5)',
      },
      borderBottomRightRadius: '4px',
      ...sx,
    }}
    {...props}
  />
);

export const CloudIcon = () => <Custom.CloudUpload style={{ fontSize: '72px', color: '#9e9e9e' }} />;

export const StyledClearInputImage = ({ sx, ...props }) => (
  <StackRow
    sx={{
      position: 'absolute',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      opacity: 1,
      ...sx,
    }}
    {...props}
  />
);
