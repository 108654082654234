import React, { useState, useCallback, useEffect, ReactNode } from 'react';

import { gql, useMutation, useReactiveVar } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { selectedMessageVar } from 'apollo/reactiveVars';
import useFectchMessageById from 'app/Layout/MessageAlertBanner/useFetchMessageById';
import { updateMessage as UPDATE_MESSAGE, trashMessage as DELETE_MESSAGE } from 'graphql/mutations';
import { messages as MESSAGES } from 'graphql/queries';
import { getParamsWihNewValue } from 'helpers/routeFunctions';
import useCache from 'hooks/useCache';
import useQuery from 'hooks/useQuery';
import useQueryParamMemoized from 'hooks/useQueryParamMemoized';
import { Message } from 'types/Message';

const MessageStatus = {
  READ: 'Read',
  UNREAD: 'Unread',
  ACTIVE: 'Active',
  ARCHIVE: 'Archive',
  ARCHIVED: 'Archived',
  DELETED: 'Deleted',
  DISMISSED: 'Dismissed',
};

type MessagesContextType = {
  onSelectMessage: (message: Message) => void;
  onArchiveMessage: (message: Message) => void;
  onDeleteMessage: (message: Message) => void;
  selectedMessagesTab: number;
  onChangeMessagesTab: (tabIndex: number) => void;
};

type MessagesProviderProps = {
  children: ReactNode;
};

const MessagesContext = React.createContext<MessagesContextType>({
  onSelectMessage: () => {},
  onArchiveMessage: () => {},
  onDeleteMessage: () => {},
  selectedMessagesTab: 0,
  onChangeMessagesTab: () => {},
});

const ActiveMessagesQuery = {
  query: gql(MESSAGES),
  variables: {
    query: {
      status: `${MessageStatus.ACTIVE},${MessageStatus.DISMISSED}`,
      read: 'all',
      skip: 0,
      take: 10,
    },
  },
};

const ArchiveMessagesQuery = {
  query: gql(MESSAGES),
  variables: { query: { status: MessageStatus.ARCHIVED, read: 'all', skip: 0, take: 10 } },
};

// eslint-disable-next-line max-lines-per-function
const MessagesProvider = ({ children }: MessagesProviderProps) => {
  const navigate = useNavigate();
  const query = useQuery();
  const messageIdParam = useQueryParamMemoized('messageId');
  const selectedMessage = useReactiveVar(selectedMessageVar);

  const { readCache, writeCache } = useCache();

  const [selectedMessagesTab, setSelectedMessagesTab] = useState(0);

  const onChangeMessagesTab = useCallback((tabIndex: number) => {
    setSelectedMessagesTab(tabIndex);
  }, []);

  const [updateMessage] = useMutation(gql(UPDATE_MESSAGE));
  const [deleteMessage] = useMutation(gql(DELETE_MESSAGE));

  const { fetchMessageById } = useFectchMessageById();

  const markMessageRead = useCallback(
    async ({ messageId }: Message) => {
      if (!messageId) return;

      await updateMessage({
        variables: {
          params: { messageId },
          body: { status: MessageStatus.READ },
        },
        update(_cache, { data: updatedMessageData }) {
          const { updateMessage: updatedMessage } = updatedMessageData;
          const activeMessagesQueryCache = readCache<{ messages: Message[] }>(
            ActiveMessagesQuery.query,
            ActiveMessagesQuery.variables,
          );

          if (activeMessagesQueryCache) {
            const activeMessagesQueryCacheData = activeMessagesQueryCache.messages ?? [];
            const updatedActiveMessagesQueryCacheData = activeMessagesQueryCacheData.map((el) =>
              el.messageId === updatedMessage.messageId ? { ...updatedMessage } : el,
            );
            writeCache(ActiveMessagesQuery.query, ActiveMessagesQuery.variables, {
              messages: updatedActiveMessagesQueryCacheData,
            });
          }

          selectedMessageVar(updatedMessage);
        },
        refetchQueries: ['MessagesCount'],
      });
    },
    [readCache, updateMessage, writeCache],
  );

  const onSelectMessage = useCallback(
    (message: Message) => {
      const newURL = getParamsWihNewValue(query, {
        messageId: message?.messageId || messageIdParam,
      });
      navigate(newURL);
      selectedMessageVar(message);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [messageIdParam],
  );

  useEffect(() => {
    const getMessage = async () => {
      if (!messageIdParam) {
        selectedMessageVar(null);
        return;
      }

      const { data } = await fetchMessageById({
        variables: { params: { id: messageIdParam } },
      });
      onSelectMessage(data?.messageById);
    };
    getMessage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchMessageById, messageIdParam]);

  useEffect(() => {
    if (selectedMessage == null || selectedMessage?.accessedOn) return;

    markMessageRead(selectedMessage);
  }, [markMessageRead, selectedMessage]);

  const onArchiveMessage = useCallback(
    async ({ messageId }: Message) => {
      await updateMessage({
        variables: {
          params: { messageId },
          body: { status: MessageStatus.ARCHIVE },
        },
        refetchQueries: [ActiveMessagesQuery, ArchiveMessagesQuery],
      });
    },
    [updateMessage],
  );

  const onDeleteMessage = useCallback(
    async ({ messageId }: Message) => {
      await deleteMessage({
        variables: {
          params: { messageId },
        },
        refetchQueries: [ActiveMessagesQuery, ArchiveMessagesQuery],
      });
    },
    [deleteMessage],
  );

  return (
    <MessagesContext.Provider
      value={{
        onSelectMessage,
        onArchiveMessage,
        onDeleteMessage,
        selectedMessagesTab,
        onChangeMessagesTab,
      }}
    >
      {children}
    </MessagesContext.Provider>
  );
};

const useMessagesContext = () => {
  const context = React.useContext(MessagesContext);
  if (context === undefined) {
    throw new Error('useMessagesContext must be used within a MessagesContext');
  }
  return context;
};

export { MessagesContext, MessagesProvider, useMessagesContext, MessageStatus };
