import { FieldValues, UseFormReturn } from 'react-hook-form';

import { BOM_ITEM_LINE_TYPES } from 'constants/globalConstants';
import { WriteInItemTypeInput } from 'graphql/types';

import { NEW_ITEM_ROW } from '../Form/rules';
import { AddBOMItemStrategy, Manufacturer, NewItem } from './AddBOMItemStrategy';

export class WriteInItemStrategy implements AddBOMItemStrategy {
  private formMethods: UseFormReturn<FieldValues, any>;

  public addingItem = false;

  constructor(formMethods: UseFormReturn<FieldValues, any>) {
    this.formMethods = formMethods;
  }

  // eslint-disable-next-line class-methods-use-this
  public createBOMItem(data: FieldValues, manufacturerId: string) {
    const { lineItemTypeId } = data;
    if (!lineItemTypeId) throw new Error('Error in the form, lineItemTypeId cannot be null.');
    const {
      uom,
      lineItemName,
      unitQuantity = 0,
      manufacturerCatalogNumber = null,
      quantity = 0,
      tradeSize,
      parentLineItemId,
      locationId,
      workPhaseId,
    } = data;

    const unitQuantityForm = Number.isNaN(Number(unitQuantity)) ? 0 : Number(unitQuantity);
    const quantityForm = Number.isNaN(Number(quantity)) ? 0 : Number(quantity);
    const overrideForRecursiveItems = parentLineItemId
      ? { parentLineItemId, unitQuantity: unitQuantityForm, tradeSize }
      : { quantity: quantityForm };

    const newItem: WriteInItemTypeInput = {
      locationId,
      ...overrideForRecursiveItems,
      lineItemTypeId,
      lineItemName,
      unitOfMeasureId: uom?.value,
      manufacturerId,
      manufacturerCatalogNumber,
      workPhaseId,
    };

    return [newItem];
  }

  public addItemRow(item: NewItem, genericManufacturer: Manufacturer) {
    const { reset, setValue } = this.formMethods;
    this.addingItem = true;
    reset();
    setValue('lineItemId', item.lineItemId);
    setValue('lineItemTypeId', BOM_ITEM_LINE_TYPES.write);
    setValue('lineItemName', item.lineItemName);
    setValue('manufacturer', genericManufacturer);
    if (item.parentLineItemId) setValue('parentLineItemId', item.parentLineItemId);
    return NEW_ITEM_ROW();
  }
}
