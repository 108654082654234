import { gql, useQuery } from '@apollo/client';

import { facilities as FACILITIES } from 'graphql/queries';

const useFetchFacilities = (query) => {
  const fetchFacilities = useQuery(gql(FACILITIES), {
    variables: {
      query,
    },
    fetchPolicy: 'no-cache',
  });

  const { loading } = fetchFacilities;

  const facilities = (fetchFacilities.data?.facilities || []).map((facility) => ({
    value: facility.facilityId,
    label: facility.facilityName,
    departments: facility.departments,
  }));

  return { loading, facilities };
};

export default useFetchFacilities;
