import React from 'react';

import { Custom } from 'components/Icons';
import RoundedIconButton from 'components/RoundedIconButton';

const ProfileButton = ({ onClick }) => (
  <RoundedIconButton onClick={onClick}>
    <Custom.AccountCircle />
  </RoundedIconButton>
);

export default ProfileButton;
