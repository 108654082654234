import { columnTaskTable } from './columns/columTaskTable';
import { columnTaskTableResponsive } from './columns/columTaskTableResponsive';

export const useTasksTableColumns = ({
  tasks,
  canEdit,
  canDelete,
  onDeleteTask,
  isResponsiveWR,
  isReleased,
  tasksPredecessorBlacklist,
  taskIdsRelateDuplicate,
}) => {
  if (isResponsiveWR) {
    return columnTaskTableResponsive();
  }

  return columnTaskTable(
    tasks,
    canEdit,
    canDelete,
    onDeleteTask,
    isReleased,
    tasksPredecessorBlacklist,
    taskIdsRelateDuplicate,
  );
};
