import { gql } from '@apollo/client';

import { workOrders as WORK_ORDERS } from 'graphql/queries';
import { QueryWorkOrdersQueryInput, WorkOrder } from 'graphql/types';
import useCache from 'hooks/useCache';

export const useGetWorkOrdersFromCache = () => {
  const { readCache } = useCache();
  const getWorkOrders = (variables: { query: QueryWorkOrdersQueryInput }) => {
    const sourceCacheObj = readCache<{ workOrders: Array<WorkOrder> }>(gql(WORK_ORDERS), variables);
    if (!sourceCacheObj) return null;

    return sourceCacheObj?.workOrders;
  };
  return { getWorkOrders };
};
