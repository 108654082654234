import React, { useState } from 'react';

import { TextField, InputAdornment, IconButton } from '@mui/material';
import { Controller } from 'react-hook-form';

import { Custom } from 'components/Icons';

const PasswordControlField = (props) => {
  const {
    name,
    defaultValue = '',
    control,
    label,
    onFocus,
    onBlur,
    rules,
    autoComplete,
    fullWidth = true,
    autoFocus = false,
    testid,
    hideViewPasswordIcon,
    margin = 'normal',
    ...rest
  } = props;
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      shouldUnregister
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <PasswordField
          data-testid={testid}
          variant="outlined"
          margin={margin}
          fullWidth={fullWidth}
          label={label}
          autoComplete={autoComplete || name}
          onChange={onChange}
          value={value}
          error={!!error}
          autoFocus={autoFocus}
          onFocus={onFocus}
          onBlur={onBlur}
          helperText={error ? error.message : null}
          hideViewPasswordIcon={hideViewPasswordIcon}
          {...rest}
        />
      )}
      rules={rules}
    />
  );
};

export default PasswordControlField;

const PasswordField = ({ hideViewPasswordIcon, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  return (
    <TextField
      {...props}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: hideViewPasswordIcon ? (
          <></>
        ) : (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              size="large"
            >
              {showPassword ? <Custom.Visibility /> : <Custom.VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
