import { gql, useQuery } from '@apollo/client';

import { workRequestsById as WORKREQUEST } from 'graphql/queries';

const useFetchWorkRequest = (workRequestId) => {
  const { data, loading, refetch } = useQuery(gql(WORKREQUEST), {
    variables: {
      params: { workRequestId },
    },
    fetchPolicy: 'cache-and-network',
  });

  return { loading, workRequest: data?.workRequestsById, refetch };
};

export default useFetchWorkRequest;
