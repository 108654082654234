import React, { useMemo } from 'react';

import { Typography, Breadcrumbs, Button, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useAssemblyEditorContext } from './ReactFlowProviders/AssemblyEditorContext';
import { UNPUBLISHED_PART } from './Utils/constants';
import FilterDropdown from './Utils/FilterDropdown';

const IS_SAVE_A_PART_AS_DRAFT_ENABLED = false;

const EditorHeader = () => {
  const navigate = useNavigate();

  const {
    isUpdateAssembly,
    updateElements,
    handlePublish,
    loadingAddAssembly,
    loadingPublishAssembly,
    loadingUpdateAssembly,
    onCancel,
    elements,
  } = useAssemblyEditorContext();

  const loading = loadingAddAssembly || loadingPublishAssembly || loadingUpdateAssembly;

  const newPartItemHasChanges = useMemo(() => {
    if (elements.length > 1) return true;
    if (elements[0]?.position?.x !== 550 || elements[0]?.position?.y !== 350) return true;

    return false;
  }, [elements]);

  const hasAssemblyChange = useMemo(() => {
    if (JSON.parse(localStorage.getItem(UNPUBLISHED_PART))?.isFormModified) return true;

    if (!isUpdateAssembly && newPartItemHasChanges) return true;

    const { addNodes, addEdges, updateNodes, updateEdges, deleteNodes, deleteEdges } =
      updateElements;

    return [addNodes, addEdges, updateNodes, updateEdges, deleteNodes, deleteEdges].some((el) =>
      Boolean(el.length),
    );
  }, [updateElements, isUpdateAssembly, newPartItemHasChanges]);

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: '#000',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs sx={{ ml: 3, mb: 2, mr: 4, mt: 2 }} aria-label="breadcrumb">
          <Typography
            sx={{ color: 'rgba(0, 0, 0, 0.5)', cursor: 'pointer' }}
            onClick={() => {
              const unpublishedPart = JSON.parse(
                localStorage.getItem(UNPUBLISHED_PART),
              )?.previousLocation;
              if (!unpublishedPart) {
                navigate('/materials/catalog-setup');
                return;
              }
              const { pathname, search, hash } = unpublishedPart;
              navigate(pathname + search + hash);
            }}
          >
            Catalog Setup
          </Typography>
          <Typography>Editor</Typography>\
        </Breadcrumbs>
        <FilterDropdown />
      </div>

      <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
        <Button style={{ marginRight: 24 }} variant="text" onClick={() => onCancel()}>
          Cancel
        </Button>
        {IS_SAVE_A_PART_AS_DRAFT_ENABLED && (
          <Button style={{ marginRight: 16 }} variant="outlined">
            Save as Draft
          </Button>
        )}
        <Button
          onClick={handlePublish}
          disabled={loading || !hasAssemblyChange}
          variant="contained"
          data-cy="assemblyEditorHeaderPublishBtn"
        >
          Publish
        </Button>
        {loading && (
          <CircularProgress
            sx={{ position: 'absolute', right: '-32px' }}
            color="secondary"
            size={24}
          />
        )}
      </div>
    </div>
  );
};

export default EditorHeader;
