import React from 'react';

import { Modal } from 'components';

const StyledModal = ({ title, children, contentStyles, ...props }) => (
  <Modal
    title={title}
    reducePadding
    fullWidth
    maxWidth="xs"
    sx={{
      '.MuiDialog-paper': {
        maxWidth: '505px',
      },
      '& .MuiFormHelperText-root': {
        mt: 0,
      },
      '& .MuiDialogTitle-root': {
        pr: 0,
      },
    }}
    titleStyles={{
      letterSpacing: 0.15,
      fontWeight: 600,
    }}
    contentStyles={{
      pt: 1,
      pb: 0,
      ...contentStyles,
    }}
    {...props}
  >
    {children}
  </Modal>
);

export default StyledModal;
