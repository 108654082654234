import React, { Fragment } from 'react';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';

import InfiniteScroll from 'components/TableComponents/InfiniteScroll';
import NoContentMessage from 'components/TableComponents/NoContentMessage';
import TableContainer from 'components/TableComponents/TableContainer';
import TableHeader from 'components/TableComponents/TableHeaders';
import TableSkeleton from 'components/TableComponents/TableSkeleton';
import {
  handleTableRowClick,
  handleSelectAllClick,
  isSelected,
} from 'components/TableComponents/tableUtilities';

import { useShopMembersContext } from '../ShopMembersContext';
import { TableRow } from './TableRow';

const ShopMembersTable = ({
  loading,
  shopMembers,
  handleSortOrder,
  paginationHandler,
  emptyTableMessage,
  headerOptions,
  hasShopAdminPermission = false,
}) => {
  const { selected, setSelected } = useShopMembersContext();

  const TableBodyContent = () => (
    <TableBody>
      {shopMembers.map((departmentUser, index) => (
        <Fragment key={departmentUser.departmentUserId}>
          <TableRow
            departmentUser={departmentUser}
            handleRowClick={() => handleTableRowClick(departmentUser, selected, setSelected)}
            isItemSelected={isSelected(departmentUser, selected)}
            hasShopAdminPermission={hasShopAdminPermission}
          />
          {index === shopMembers.length - 1 && (
            <InfiniteScroll data={shopMembers} fetchMore={paginationHandler} />
          )}
        </Fragment>
      ))}
    </TableBody>
  );

  return (
    <ShopsTableBorderBox>
      <TableContainer>
        <Table stickyHeader>
          <TableHeader
            headerOptions={headerOptions}
            onSelectAllClick={(e) => handleSelectAllClick(e, shopMembers, setSelected)}
            rowCount={shopMembers.length}
            numSelected={selected.length}
            handleSortOrder={handleSortOrder}
            selectable
          />
          {loading ? (
            <TableSkeleton skeletons={headerOptions.map(({ title }) => title)} />
          ) : (
            <TableBodyContent />
          )}
        </Table>
      </TableContainer>
      {!shopMembers.length && !loading && <NoContentMessage text={emptyTableMessage} />}
    </ShopsTableBorderBox>
  );
};

export default ShopMembersTable;

const ShopsTableBorderBox = (props) => (
  <Box
    sx={{
      border: '1px solid',
      borderColor: 'other.divider.lightSurface',
      borderRadius: '5px',
      position: 'relative',
      padding: '5px 0 0 0',
      height: 'calc(100% - 135px)',
    }}
    {...props}
  />
);
