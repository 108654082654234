import { Stack, useTheme } from '@mui/material';

import RowIcon from 'components/Table/RowIcon';

const SubItem = ({
  label,
  icon,
  opened,
  innerRef,
  onOpening = () => {},
  sx,
  className = '',
  isDraggingOver = false,
  disabled,
  ...restProps
}) => {
  const theme = useTheme();

  return (
    <Stack
      className={className}
      direction="row"
      sx={{
        pl: '118px',
        width: '100%',
        backgroundColor: 'surface.darkSurface.primary',
        borderBottom: '1px solid rgba(232, 232, 232, 1)',
        outline: isDraggingOver && `1px solid ${theme.palette?.secondary?.main}`,
        ...sx,
      }}
      ref={innerRef}
      {...restProps}
    >
      <RowIcon label={label} icon={icon} opened={opened} onOpening={onOpening} disabled={disabled} />
    </Stack>
  );
};

export default SubItem;
