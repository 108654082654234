import React from 'react';

import { Box } from '@mui/material';

import { Custom } from 'components/Icons';
import { surfaceColors } from 'styles/theme/colors';

import checkType from './checkType';

const ArrowIndicator = ({ item = {}, isOpen, handleClick, disabled, enableAction = false }) => {
  const type = checkType(item);
  const { hasChildren, hasWorkPhases } = item;
  const styles = {
    height: '24px',
    width: '24px',
    color: 'rgba(0, 0, 0, 0.5)',
    transform: isOpen ? 'rotate(90deg)' : '',
    fill: surfaceColors.lightSurface.secondary,
    cursor: 'pointer',
    ...(disabled
      ? {
          pointerEvents: 'none',
          fill: 'rgba(0, 0, 0, 0.1)',
        }
      : {}),
  };

  if (enableAction) return <Custom.ArrowRight onClick={handleClick} style={styles} />;
  if (type === 'Project') return <Custom.ArrowRight style={styles} />;
  if (hasChildren || hasWorkPhases)
    return <Custom.ArrowRight onClick={handleClick} style={styles} />;
  return <Box sx={{ width: '24px' }} />;
};

export default ArrowIndicator;
