import * as React from 'react';

import { gql, useMutation } from '@apollo/client';

import { addManufacturer as ADD_MANUFACTURER } from 'graphql/mutations';

export const useManufacturerAPI = () => {
  const [addNewManufacturer] = useMutation(gql(ADD_MANUFACTURER), {
    refetchQueries: ['Manufactured'],
    variables: {},
  });

  const onAddNewManufacturer = React.useCallback(
    async ({ companyId, manufacturerName }) => {
      const body = { companyId, manufacturerName };
      const data = await addNewManufacturer({ variables: { body } });
      return data;
    },
    [addNewManufacturer],
  );

  return {
    callbacks: { onAddNewManufacturer },
  };
};
