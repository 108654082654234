import React, { useState } from 'react';

import useGetShopConfigurations from 'modules/Shop/ShopProperties/InfoTab/useGetShopConfigurations';

const ShopConfigurationSelectContext = React.createContext({});

const ShopConfigurationSelectProvider = ({ children }) => {
  const [prevShopConfigId, setPrevShopConfigId] = useState(null);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [shopConfigurationSelected, setShopConfigurationSelected] = useState(null);
  const {
    shopConfigurations,
    loading: loadingShopConfigurations,
    setShopConfigurations,
    refetchShopConfigurations,
    shopConfigurationsUnformatted,
  } = useGetShopConfigurations();

  return (
    <ShopConfigurationSelectContext.Provider
      value={{
        prevShopConfigId,
        setPrevShopConfigId,
        openCreateModal,
        setOpenCreateModal,
        openEditModal,
        setOpenEditModal,
        shopConfigurationSelected,
        setShopConfigurationSelected,
        shopConfigurations,
        loadingShopConfigurations,
        setShopConfigurations,
        refetchShopConfigurations,
        shopConfigurationsUnformatted,
      }}
    >
      {children}
    </ShopConfigurationSelectContext.Provider>
  );
};

const useShopConfigurationSelectContext = () => {
  const context = React.useContext(ShopConfigurationSelectContext);
  if (context === undefined) {
    throw new Error('useFacilitiesProjects must be used within a ShopConfigurationSelectContext');
  }
  return context;
};

export { ShopConfigurationSelectContext, ShopConfigurationSelectProvider, useShopConfigurationSelectContext };
