import React, { useEffect } from 'react';

import { Box, useTheme, TextField, ClickAwayListener, CircularProgress } from '@mui/material';
import { useController } from 'react-hook-form';

import useKeyPressEffect from 'hooks/useKeyPressEffect';
import { useUpdateWorkRequest } from 'modules/Field/WorkRequests/WorRequestsProperties/WorkRequestPropertiesAttachments/hooks/useUpdateWorkRequest';

import { useWorkRequestsContext } from '../../../WorkRequestsContext';

// eslint-disable-next-line max-lines-per-function
const DescriptionForm = ({ setHovered, control, reset, handleSubmit, isSubmitting }) => {
  const { updateWorkRequest, loading, data } = useUpdateWorkRequest();
  const { workRequestSelected, setWorkRequest } = useWorkRequestsContext();
  const name = 'workRequestDescription';
  const { palette } = useTheme();

  const rules = {
    maxLength: {
      value: 999,
      message: '1000/1000',
    },
  };

  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({ name, control, rules });

  const submit = async () => {
    if (value !== workRequestSelected?.workRequestDescription && !error) {
      const body = { workRequestDescription: value };
      await updateWorkRequest(workRequestSelected.workRequestId, body);
      reset();
    } else {
      setHovered(false);
      reset();
    }
  };

  useEffect(() => {
    if (data && !loading) {
      setWorkRequest(data.updateWorkRequest);
      setHovered(false);
    }
  }, [data, loading, setWorkRequest, setHovered]);

  const submitCheck = () => {
    if (isSubmitting) return;
    handleSubmit(submit)();
  };

  useKeyPressEffect('Enter', submitCheck);

  return (
    <ClickAwayListener onClickAway={submitCheck}>
      <Box width="205px" sx={{ textAlign: 'right' }}>
        {isSubmitting && <CircularProgress sx={{ color: 'secondary.mainDarkBackground' }} size="22px" />}
        {!isSubmitting && (
          <TextField
            id="description"
            multiline
            maxRows={5}
            disabled={loading}
            error={error}
            size="small"
            inputProps={{ maxLength: 1000 }}
            helperText={error?.message || ''}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            sx={{
              width: '100%',
              '& .MuiInputBase-root.Mui-disabled': {
                color: palette.surface.darkSurface.disabled,
                '& textarea': {
                  WebkitTextFillColor: `${palette.surface.darkSurface.disabled} !important`,
                },
              },
              '& .Mui-error': {
                color: palette.error.light,
                lineHeight: '20px',
              },
              '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: `${palette.error.light} !important`,
              },
              '& .MuiFormHelperText-root': {
                textAlign: 'right',
              },
              '& .MuiInputBase-root': {
                color: palette.surface.darkSurface.secondary,
                padding: '4px 0 4px 12px',
                fontSize: '14px',
                lineHeight: '20px',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: `${palette.surface.darkSurface.secondary} !important`,
              },
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: error ? '' : `${palette.secondary.mainDarkBackground} !important`,
              },
            }}
          />
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default DescriptionForm;
