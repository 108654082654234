import React, { useMemo } from 'react';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';

import { Custom } from '../Icons';
import { NoContentFallback } from '../NoContentFallback';

const FacilityAndProjectWrapper = ({
  children,
  isFacilityRequired = false,
  isProjectRequired = false,
}) => {
  const { selectedItem } = useFacilitiesProjects();
  const messageSelectedItem = (() => {
    if (isFacilityRequired && isProjectRequired) return 'project or facility';
    return isFacilityRequired ? 'facility' : 'project';
  })();

  const isRequiredEntitySelected = useMemo(() => {
    if (!selectedItem) return false;

    const isFacilitySelected = selectedItem.type === 'FACILITY';
    const isProjectSelected = selectedItem.type === 'PROJECT';

    if (isFacilityRequired && isProjectRequired) {
      return isFacilitySelected || isProjectSelected;
    }

    if (isFacilityRequired) {
      return isFacilitySelected;
    }

    if (isProjectRequired) {
      return isProjectSelected;
    }

    return true;
  }, [selectedItem, isFacilityRequired, isProjectRequired]);

  if (!isRequiredEntitySelected) {
    return (
      <NoContentFallback
        icon={Custom.Info}
        message={`Start by selecting a ${messageSelectedItem}.`}
      />
    );
  }

  return children;
};

export default FacilityAndProjectWrapper;
