import React, { useState, useCallback } from 'react';

import { DragDropContext } from 'react-beautiful-dnd';

import { DROPPABLE_ROW_IDS } from 'modules/Field/WorkRequests/FieldWorkRequestConstants';

import useMapDocuments from './DrawingRegisterUtils/useMapDocuments';
import useMapFolder from './DrawingRegisterUtils/useMapFolder';
import useMapWorkRequestItem from './DrawingRegisterUtils/useMapWorkRequestItem';

const DEFAULT_CONTEXT_VALUE = {
  onDragStart: () => {},
  onDragEnd: () => {},
  isDragging: false,
  finishedDragging: {},
  setFinishedDragging: () => {},
  isTasksReadOnly: false,
  isMappingDocuments: false,
  setIsMappingDocuments: () => {},
};
const DrawingRegisterDragDropContext = React.createContext(DEFAULT_CONTEXT_VALUE);

const useHandleDraggableItem = (selectedRows, checkboxFolderId, setIsMapping) => {
  const { handleMappedDocument } = useMapDocuments();
  const { handleMappedFolder } = useMapFolder();
  const { handleMappedWorkRequestItem } = useMapWorkRequestItem();

  const handleDraggableItem = (params) => {
    const [workRequestDroppable] = params.destination.droppableId.split('/');

    if (workRequestDroppable === DROPPABLE_ROW_IDS.BOP)
      handleMappedDocument(params, selectedRows, checkboxFolderId, setIsMapping);

    if (workRequestDroppable === DROPPABLE_ROW_IDS.ITEM)
      handleMappedWorkRequestItem(params, selectedRows, checkboxFolderId, setIsMapping);

    if (workRequestDroppable.toUpperCase() === DROPPABLE_ROW_IDS.FOLDER)
      handleMappedFolder(params, selectedRows, setIsMapping);
  };

  return { handleDraggableItem };
};

const DrawingRegisterDragDropProvider = ({ children }) => {
  // State for maintaining selected rows
  const [selectedRows, setSelectedRows] = useState([]);

  // State for maintaining the ID of the folder selected through checkbox
  const [checkboxFolderId, setCheckboxFolderId] = useState(null);

  // States related to drag and drop
  const [finishedDraggingWRItem, setFinishedDraggingWRItem] = useState(null);
  const [finishedDragging, setFinishedDragging] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  // State to indicate if mapping is in progress
  const [isMapping, setIsMapping] = useState(false);

  // Custom hook
  const { handleDraggableItem } = useHandleDraggableItem(selectedRows, checkboxFolderId, setIsMapping);

  const onDragStart = useCallback(() => {
    setIsDragging(true);
  }, []);

  const onDragEnd = useCallback(
    (params) => {
      setIsDragging(false);
      if (!params?.destination || params?.destination?.droppableId === 'drawing-documents') return;

      setFinishedDraggingWRItem(params);
      setFinishedDragging(params);

      handleDraggableItem(params);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRows, checkboxFolderId],
  );

  return (
    <DrawingRegisterDragDropContext.Provider
      value={{
        isDragging,
        onDragStart,
        onDragEnd,
        isTasksReadOnly: false,
        selectedRows,
        setSelectedRows,
        isMappingDocuments: isMapping,
        setIsMappingDocuments: setIsMapping,
        finishedDraggingWRItem,
        setFinishedDraggingWRItem,
        finishedDragging,
        setFinishedDragging,
        checkboxFolderId,
        setCheckboxFolderId,
        isDropDisabled: true,
      }}
    >
      <DragDropContext onDragEnd={onDragEnd} onBeforeCapture={onDragStart}>
        {children}
      </DragDropContext>
    </DrawingRegisterDragDropContext.Provider>
  );
};

const useDrawingRegisterDragDrop = () => {
  const context = React.useContext(DrawingRegisterDragDropContext);
  if (context === undefined) {
    throw new Error('useDrawingRegisterDragDrop must be used within a DrawingRegisterDragDropContext');
  }
  return context;
};

export { DrawingRegisterDragDropProvider, useDrawingRegisterDragDrop, DrawingRegisterDragDropContext };
