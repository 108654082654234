import React, { useState } from 'react';

import { InputForesiteField } from '@evolvemep/foresite-components-ui-react';
import { FormControlLabel, Checkbox, Box } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';

import AssignAdminControlledInput from 'components/FormComponents/AssignAdminControlledInput';

const DepartmentInfoCheckbox = ({ label, departmentTypeId, index }) => {
  const { control, unregister, setValue } = useFormContext();
  const [checked, setChecked] = useState(false);
  const baseFormRegisterName = `departments.${index}`;
  const departmentIdName = `${baseFormRegisterName}.departmentTypeId`;

  return (
    <Box>
      <Controller
        name={checked ? departmentIdName : ''}
        control={control}
        defaultValue=""
        shouldUnregister={checked}
        render={() => (
          <FormControlLabel
            label={label}
            control={
              <Checkbox
                color="secondary"
                checked={checked}
                onChange={(e, isChecked) => {
                  setChecked(isChecked);

                  if (!isChecked) {
                    unregister(departmentIdName, { keepValue: false });
                  } else {
                    setValue(departmentIdName, departmentTypeId);
                  }
                }}
              />
            }
          />
        )}
      />

      {checked && (
        <>
          <InputForesiteField
            name={`${baseFormRegisterName}.departmentName`}
            label="Department Name"
            size="small"
            control={control}
            rules={{ required: true }}
            data-cy="department-name"
          />
          <AssignAdminControlledInput name={`${baseFormRegisterName}.user`} control={control} />
        </>
      )}
    </Box>
  );
};

export default DepartmentInfoCheckbox;
