import React, { useState } from 'react';

import { MoreMenu } from 'components';
import { EIconType } from 'helpers/MenuIcons';

import MiniSpinner from '../../../../../../../components/MiniSpinner';
import { EMPTY_WORKCELL } from '../../constants/constants';
import { useDataContext } from '../../contexts/DataContext';
import { useWorkCellsAndMachinesContext } from '../../contexts/WorkCellsAndMachinesContext';
import RowTitle from '../RowTitle';
import StyledIcon from '../StyledIcon';
import StyledRow from '../StyledRow';
import { WorkCellProvider } from '../WorkCellRow/contexts/WorkCellContext';
import WorkCellRow from '../WorkCellRow/WorkCellRow';
import EditShopConfigurationModals from './components/EditShopConfigurationModals';

const ShopConfigurationRow = () => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteShopModal, setOpenDeleteShopModal] = useState(false);

  const { activeRow, setActiveRow, setCloseFlyoutMenu, shopConfiguration } = useDataContext();

  const handleSelectRow = () => {
    setActiveRow({ id: shopConfiguration.value });
    setCloseFlyoutMenu(true);
  };

  const moreMenuOptions = [
    { label: 'Edit', clickEvent: () => setOpenEditModal(true) },
    { label: 'Delete', clickEvent: () => setOpenDeleteShopModal(true) },
  ];

  return (
    <>
      <StyledRow
        isActive={shopConfiguration.value === activeRow?.id}
        onClick={handleSelectRow}
        sx={{ pl: 4.5 }}
        testId="shop-configuration-row"
      >
        <StyledIcon iconName={EIconType.SHOP_CONFIGURATION} />
        <RowTitle testId="shop-configuration-name">{shopConfiguration?.label}</RowTitle>
        <MoreMenu options={moreMenuOptions} sx={{ fontSize: 21 }} />
      </StyledRow>

      <WorkCellChildren />

      <EditShopConfigurationModals
        node={shopConfiguration}
        openDeleteShopModal={openDeleteShopModal}
        setOpenDeleteShopModal={setOpenDeleteShopModal}
        openEditModal={openEditModal}
        setOpenEditModal={setOpenEditModal}
        shopConfiguration={shopConfiguration}
      />
    </>
  );
};

export default ShopConfigurationRow;

const WorkCellChildren = () => {
  const { workCells, loadingWorkCells, loadingAddingWorkCell } = useWorkCellsAndMachinesContext();

  return (
    <>
      {workCells?.length ? (
        <>
          {workCells.map((workCell) => (
            <WorkCellProvider key={workCell.workCellId} workCell={workCell}>
              <WorkCellRow />
            </WorkCellProvider>
          ))}
        </>
      ) : (
        <>{loadingWorkCells && <MiniSpinner showBorderBottom />}</>
      )}

      {!workCells?.length && !loadingWorkCells && (
        <WorkCellProvider workCell={EMPTY_WORKCELL} disabled>
          <WorkCellRow />
        </WorkCellProvider>
      )}
      {loadingAddingWorkCell && <MiniSpinner showBorderBottom />}
    </>
  );
};
