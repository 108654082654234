import React, { useMemo } from 'react';

import { WRITE_IN_ASSEMBLY } from './constants';

const ItemContext = React.createContext();

const ItemProvider = ({ children, item, isResponsiveWR = false, parent }) => {
  const isWorkOrder = Boolean(item?.workOrderItemId);
  const parentStatus = isWorkOrder ? parent?.workOrderStatusName : parent?.workRequestStatusName;

  const isWriteInItem = useMemo(() => {
    if (isWorkOrder) return item.workOrderItemTypeId === WRITE_IN_ASSEMBLY;
    return item.workRequestItemTypeId === WRITE_IN_ASSEMBLY;
  }, [item, isWorkOrder]);

  const valueObj = {
    isWorkOrder,
    item,
    isResponsiveWR,
    parentStatus,
    parent,
    isWriteInItem,
  };

  return <ItemContext.Provider value={valueObj}>{children}</ItemContext.Provider>;
};

const useItem = () => {
  const context = React.useContext(ItemContext);
  if (context === undefined) {
    throw new Error('useItem must be used within a ItemContext');
  }
  return context;
};

export { ItemProvider, useItem };
