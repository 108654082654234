import React from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import OverFlowTooltip from './Tooltip/OverFlowTooltip';

const Header = ({ title, variantTitle = 'h5', option = false, sx, ...rest }) => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    gap="12px"
    width="100%"
    sx={{ ...sx }}
    {...rest}
  >
    {typeof title === 'string' ? (
      <OverFlowTooltip title={title}>
        <Typography variant={variantTitle} sx={{ display: 'inline' }}>
          {title}
        </Typography>
      </OverFlowTooltip>
    ) : (
      title
    )}
    {option}
  </Stack>
);

export default Header;
