import React from 'react';

import { Button } from '@mui/material';

export const AddButton = ({ disabled, onClick }) => (
  <Button
    style={{ marginLeft: '8px' }}
    color="secondary"
    variant="contained"
    onClick={onClick}
    disabled={disabled}
    data-testid="new-catalog-modal-submit-btn"
  >
    ADD
  </Button>
);
