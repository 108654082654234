import React, { useRef, useCallback } from 'react';

import { CircularProgress, Popover, Stack, Typography, Box } from '@mui/material';

import { surfaceColors } from 'styles/theme/colors';

const WorkRequestAttachmentsPopOver = ({ id, open, anchorEl, handleClose, loadingAttachments, children }) => {
  const flag = useRef();

  const callback = (entries) => {
    const [entry] = entries;
    if (!entry.isIntersecting) flag?.current?.updatePosition();
  };

  const paperRef = useCallback((node) => {
    if (!node) return;
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.999,
    };

    const observer = new IntersectionObserver(callback, options);
    observer.observe(node);
  }, []);

  if (!open) return <></>;
  return (
    <Popover
      action={flag}
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      sx={{
        '.MuiPopover-paper': { padding: '8px 4px 24px 16px', width: '464px', maxHeight: '575px', overflowY: 'hidden' },
      }}
      PaperProps={{ ref: paperRef }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Box sx={{ padding: '21px 16px' }}>
        <Stack direction="row" gap={1}>
          <Typography variant="subtitle2" sx={{ color: surfaceColors.lightSurface.secondary }}>
            Attachments
          </Typography>
          {loadingAttachments && <CircularProgress color="secondary" size={20} />}
        </Stack>
      </Box>

      <Box sx={{ overflowY: 'auto', maxHeight: '500px' }}>{!loadingAttachments && children}</Box>
    </Popover>
  );
};

export default WorkRequestAttachmentsPopOver;
