import { useEffect, useState } from 'react';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { getFacilityId } from 'modules/Shop/ShopSetup/WorkCellSetup/WorkCellsAndMachines/helpers/helpers';

export default function useFacilities() {
  const { selectedItem, shopDepartmentId } = useFacilitiesProjects();
  const [selectedFacilityId, setSelectedFacilityId] = useState(null);

  useEffect(() => {
    setSelectedFacilityId(getFacilityId(selectedItem));
  }, [selectedItem]);

  return {
    selectedFacilityId,
    shopDepartmentId,
  };
}
