import React, { forwardRef, useContext } from 'react';

import { useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';

import { VARIANT_ERROR, VARIANT_SUCCESS } from 'constants/snackbarConstants';
import CatalogPopup from 'modules/Materials/components/CatalogPopup/CatalogPopup';
import { WorkOrderItemsContext } from 'modules/Shop/WorkOrders/WorkOrder/context/WorkOrderItemsContext';

import useCatalogPopupWorkOrder from './useCatalogPopupWorkOrder';
import useCatalogPopupWorkRequest from './useCatalogPopupWorkRequest';

const CatalogPopupWorkRequest = forwardRef(({ onPanelClose, onDock }, ref) => {
  const { enqueueSnackbar } = useSnackbar();
  const workOrderItemsContext = useContext(WorkOrderItemsContext);

  const hook = workOrderItemsContext ? useCatalogPopupWorkOrder : useCatalogPopupWorkRequest;
  const { handleAddItems } = hook();
  const theme = useTheme();

  const addItems = async (payload) => {
    const result = await handleAddItems(payload);
    if (result === 1) {
      enqueueSnackbar('Item succesfully added', VARIANT_SUCCESS);
      return;
    }
    if (result > 1) {
      enqueueSnackbar('Items succesfully added', VARIANT_SUCCESS);
      return;
    }
    enqueueSnackbar('Error: Could not save changes', VARIANT_ERROR);
  };

  return (
    <CatalogPopup
      ref={ref}
      onClose={onPanelClose}
      onDock={onDock}
      onAddItems={(payload) => addItems(payload)}
      moduleButtonsColor={`${theme.palette.secondary.mainDarkBackground} !important`}
    />
  );
});

export default CatalogPopupWorkRequest;
