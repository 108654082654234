import { gql, useMutation } from '@apollo/client';

import {
  addWorkCell as ADD_WORK_CELL,
  updateWorkCell as UPDATE_WORK_CELL,
  deleteWorkCell as DELETE_WORK_CELL,
} from 'graphql/mutations';
import { workCells as WORK_CELLS } from 'graphql/queries';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

import { NEW_WORKCELL_ID, ROW_TYPES } from '../../../constants/constants';
import { useDataContext } from '../../../contexts/DataContext';
import { useWorkCellsAndMachinesContext } from '../../../contexts/WorkCellsAndMachinesContext';
import { buildVariables } from '../../../helpers/helpers';

export default function useWorkCellsDataLayer(workCell) {
  const { activeRow, setActiveRow, selectedItem, shopConfiguration } = useDataContext();
  const { saveWorkCellForm } = useWorkCellsAndMachinesContext();
  const { handleResponse } = useGraphqlResponseHandler();

  const [addWorkCellMutation] = useMutation(gql(ADD_WORK_CELL), {
    update(cache, { data: result }) {
      const cachedWorkCells = cache.readQuery({
        query: gql(WORK_CELLS),
        ...buildVariables(selectedItem, shopConfiguration),
      });
      if (result.addWorkCell) {
        cache.writeQuery({
          query: gql(WORK_CELLS),
          ...buildVariables(selectedItem, shopConfiguration),
          data: {
            workCells: [...(cachedWorkCells?.workCells || []), { ...result.addWorkCell }],
          },
        });
        setActiveRow({
          id: result.addWorkCell.workCellId,
          type: ROW_TYPES.WORK_CELL,
          ...result.addWorkCell,
        });
      }
    },
  });

  const [updateWorkCellMutation] = useMutation(gql(UPDATE_WORK_CELL), {
    update(cache, { data: result }) {
      const cachedWorkCell = cache.readQuery({
        query: gql(WORK_CELLS),
        ...buildVariables(selectedItem, shopConfiguration),
      });

      if (result.updateWorkCell) {
        const updatedWorkCells = [...(cachedWorkCell?.workCells || [])].map((workCell) => {
          if (workCell?.workCellId === result?.updateWorkCell?.workCellId)
            return result.updateWorkCell;
          return workCell;
        });
        cache.writeQuery({
          query: gql(WORK_CELLS),
          ...buildVariables(selectedItem, shopConfiguration),
          data: { workCells: updatedWorkCells },
        });

        setActiveRow({
          noFocus: true,
          id: result.updateWorkCell.workCellId,
          type: ROW_TYPES.WORK_CELL,
          ...result.updateWorkCell,
        });
      }
    },
  });

  const [deleteWorkCellMutation, { loading: loadingDeleteWorkCell }] = useMutation(
    gql(DELETE_WORK_CELL),
    { refetchQueries: ['WorkCells', 'Machines'] },
  );

  const addWorkCell = async (formValues) => {
    const body = {
      ...formValues,
      ...buildVariables(selectedItem, shopConfiguration).variables.query,
    };

    return handleResponse(
      addWorkCellMutation,
      { variables: { body } },
      { successMessage: 'Work cell successfully added' },
    );
  };

  const updateWorkCell = async (formValues) =>
    handleResponse(
      updateWorkCellMutation,
      { variables: { params: { workcellId: workCell.workCellId }, body: formValues } },
      { successMessage: 'Work cell successfully edited' },
    );

  const saveWorkCell = async (formValues) => {
    if (workCell.workCellId === NEW_WORKCELL_ID) return addWorkCell(formValues);
    return updateWorkCell(formValues);
  };

  const deleteWorkCell = async () =>
    handleResponse(
      deleteWorkCellMutation,
      { variables: { params: { workCellId: workCell.workCellId } } },
      { successMessage: 'Work cell successfully deleted' },
      (result) => {
        if (result.deleteWorkCell) {
          saveWorkCellForm({});
          if (workCell.workCellId === activeRow.workCellId) setActiveRow({});
        }
      },
    );

  return {
    saveWorkCell,
    deleteWorkCell,
    loadingDeleteWorkCell,
  };
}
