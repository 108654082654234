import React, { ReactNode } from 'react';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';

import InfiniteScroll from 'components/TableComponents/InfiniteScroll';
import NoContentMessage from 'components/TableComponents/NoContentMessage';
import TableContainer from 'components/TableComponents/TableContainer';
import TableHeader from 'components/TableComponents/TableHeaders';
import TableSkeleton from 'components/TableComponents/TableSkeleton';
import { handleTableRowClick, handleSelectAllClick, isSelected } from 'components/TableComponents/tableUtilities';
import { otherColors } from 'styles/theme/colors';

import { useCompanyMembersContext } from '../CompanyMembersContext';
import CompanyMembersTableRow from './CompanyMembersTableRow';

const CompanyMembersTable = ({ headerOptions }: { headerOptions: any }) => {
  const { selected, setSelected, data, loading, sortHandler } = useCompanyMembersContext();

  const companyMembers = data || [];

  return (
    <CompanyTableBorderBox>
      <TableContainer>
        <Table stickyHeader>
          <TableHeader
            headerOptions={headerOptions}
            onSelectAllClick={(e: any /* event */) => handleSelectAllClick(e, companyMembers, setSelected)}
            handleSortOrder={sortHandler}
            rowCount={companyMembers.length}
            numSelected={selected.length}
            selectable
          />
          {loading && <TableSkeleton skeletons={headerOptions.map(({ title }: { title: string }) => title)} />}
          {!loading && <TableBodyContent />}
        </Table>
      </TableContainer>
      {!companyMembers.length && !loading && <NoContentMessage text="No Company Members..." />}
    </CompanyTableBorderBox>
  );
};

export default CompanyMembersTable;

const CompanyTableBorderBox = ({ children }: { children: ReactNode }) => (
  <Box
    sx={{
      border: `1px solid ${otherColors.divider.lightSurface}`,
      borderRadius: 3,
      position: 'relative',
      padding: '5px 0 0 0',
      height: 'calc(100% - 135px)',
    }}
  >
    {children}
  </Box>
);

const TableBodyContent = () => {
  const { selected, setSelected, data, paginationHandler } = useCompanyMembersContext();

  const companyMembers = data || [];

  return (
    <TableBody>
      {companyMembers.map((member, index) => (
        <>
          <CompanyMembersTableRow
            key={member.userId}
            member={member}
            handleRowClick={() => handleTableRowClick(member, selected, setSelected)}
            isItemSelected={isSelected(member, selected)}
          />
          {index === companyMembers.length - 1 && (
            <InfiniteScroll data={companyMembers} fetchMore={paginationHandler} />
          )}
        </>
      ))}
    </TableBody>
  );
};
