import React from 'react';

import Paper from '@mui/material/Paper';

export const StyledDropdownPaper = (props) => (
  <Paper
    sx={{
      width: '468px',
      color: '#FFFFFF',
      backgroundColor: '#303030',
      borderRadius: '4px',
      boxShadow:
        '0px 5px 5px -3px rgba(0, 0, 0, 0.3), 0px 8px 10px 1px rgba(0, 0, 0, 0.24), 0px 3px 14px 2px rgba(0, 0, 0, 0.22)',
    }}
    {...props}
  />
);
