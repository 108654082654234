import React from 'react';

import { TaskTypeNode } from 'components/EditorComponents/NodeTypes';

import { useAssemblyEditorContext } from '../ReactFlowProviders/AssemblyEditorContext';

const TaskTypeAssemblyNode = (props) => {
  const { onDelete, onDuplicate } = useAssemblyEditorContext();
  return <TaskTypeNode {...{ onDelete, onDuplicate, ...props }} />;
};

export default TaskTypeAssemblyNode;
