import { gql, useLazyQuery } from '@apollo/client';

import { useUser } from 'app/UserContext';
import { partCategory as CATEGORY_LIST } from 'graphql/queries';

import { useCatalogSetupContext } from '../../CatalogSetupHeader/CatalogSetupContext';

export const useCategoryListQuery = (categoryId = null) => {
  const { selectedCatalog } = useCatalogSetupContext();
  const { user } = useUser();

  const [
    fetchCategories,
    {
      called: calledCategories,
      data: categoriesData,
      loading: loadingCategories,
      refetch: refetchCategories,
    },
  ] = useLazyQuery(gql(CATEGORY_LIST), {
    fetchPolicy: 'cache-and-network',
    variables: {
      query: {
        companyId: user?.companyId,
        partCatalogId: selectedCatalog?.partCatalogId,
        orderBy: 'name:asc',
        take: 99999,
        parentPartCategoryId: categoryId,
      },
    },
  });

  return {
    fetchCategories,
    calledCategories,
    categoriesData,
    loadingCategories,
    refetchCategories,
  };
};
