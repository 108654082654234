import React from 'react';

import { GridRow } from '@mui/x-data-grid-pro';

import { useTableRowContext } from '../../context/TableRowContext';
import EditOrCreateRow from './EditOrCreateRow';

const RowFormWrapper = (rowData) => {
  const { showForm } = useTableRowContext();

  if (showForm) return <EditOrCreateRow rowData={rowData} />;

  return <GridRow {...rowData} />;
};

export default RowFormWrapper;
