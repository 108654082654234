/* eslint-disable prefer-promise-reject-errors */
import Auth from '@aws-amplify/auth';

import { assignUserDetailsToAmplifyUser } from 'app/UserContext';
import { LOCAL_STORAGE_CONSTANTS } from 'constants/globalConstants';
import { useWrappedGet } from 'hooks-api/useWrappedApiCall';

import { fetchProviderStatus } from './restCalls';

const { STAY_SIGNED_IN } = LOCAL_STORAGE_CONSTANTS;

const useSignIn = () => {
  const { apiCall } = useWrappedGet(`admin/user/username/`, { lazy: true });

  const checkForLatestEulaAgreement = async (email) => {
    const { acceptedLatestEula, userName } = await fetchProviderStatus(email);
    return { acceptedLatestEula, userName };
  };

  const signIn = async ({ email, password, keepEnabled }) => {
    const provider = await fetchProviderStatus(email);
    const { userStatus } = provider;

    if (userStatus === 'UNCONFIRMED')
      return Promise.reject({
        code: 'UserNotVerifiedFoundException',
        message: 'The email address is not verified',
      });

    if (['EMAIL_NOT_FOUND', 'USER_NOT_FOUND'].includes(userStatus))
      return Promise.reject({
        code: 'UserNotFoundException',
        message: 'The email address does not exist',
      });

    if (userStatus === 'FORCE_CHANGE_PASSWORD')
      return Promise.reject({
        code: 'UserNotFoundException',
        message: 'Username/client id combination not found.',
      });

    // TODO: if user has to complete the invitation link first

    const amplifyResult = await Auth.signIn(email, password);

    const res = await apiCall({ url: `admin/user/username/${amplifyResult.username}` });

    assignUserDetailsToAmplifyUser(amplifyResult, res);

    localStorage.setItem(STAY_SIGNED_IN, keepEnabled);

    return amplifyResult;
  };

  return { signIn, checkForLatestEulaAgreement };
};

export default useSignIn;
