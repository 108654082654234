import { MutationHookOptions, gql, useMutation } from '@apollo/client';

import { addBillOfMaterialsWriteInItem as ADD_BOM_WRITEIN_ITEM } from 'graphql/mutations';
import { AddBillOfMaterialsWriteInItemMutation, AddBillOfMaterialsWriteInItemMutationVariables } from 'graphql/types';

const useBillOfMaterialsWriteInItem = (
  options: MutationHookOptions<AddBillOfMaterialsWriteInItemMutation, AddBillOfMaterialsWriteInItemMutationVariables>,
) =>
  useMutation<AddBillOfMaterialsWriteInItemMutation, AddBillOfMaterialsWriteInItemMutationVariables>(
    gql(ADD_BOM_WRITEIN_ITEM),
    options,
  );

export default useBillOfMaterialsWriteInItem;
