import React, { isValidElement } from 'react';

import { IconButton } from '@mui/material';
import {
  gridDetailPanelExpandedRowsContentCacheSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';

import { Custom } from 'components/Icons';

type Props = {
  id: string;
  value: boolean;
};

export default function DrawingFolderPanelToggle({ id, value: isExpanded }: Props) {
  const apiRef = useGridApiContext();
  const contentCache = useGridSelector(apiRef, gridDetailPanelExpandedRowsContentCacheSelector);
  const hasDetail = isValidElement(contentCache[id]);

  return (
    <IconButton
      size="small"
      tabIndex={-1}
      disabled={!hasDetail}
      aria-label={isExpanded ? 'Open' : 'Close'}
      data-testid={`open-folder-button-${id}`}
    >
      {!isExpanded ? <Custom.ArrowRightIcon /> : <Custom.ArrowDropDownIcon color="primary" />}
    </IconButton>
  );
}
