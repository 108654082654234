/* eslint-disable max-lines-per-function */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';

import { gql, useMutation, useLazyQuery } from '@apollo/client';

import {
  addBillOfMaterial as ADD_BILL_OF_MATERIAL,
  deleteBillOfMaterials as DELETE_BILL_OF_MATERIALS,
  renameBillOfMaterials as RENAME_BILL_OF_MATERIALS,
  closeBillOfMaterials as CLOSE_BILL_OF_MATERIALS,
  openBillOfMaterials as OPEN_BILL_OF_MATERIALS,
} from 'graphql/mutations';
import { billOfMaterials as BILL_OF_MATERIALS } from 'graphql/queries';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import useLazyPaginatedQuery from 'hooks/useLazyPaginatedQuery';
import { useProject } from 'modules/Field/LocationsAndWorkPhases/contexts/ProjectContext';

export const useBillOfMaterialsAPI = () => {
  const { itemSelectedId, isProject } = useProject();
  const [orderby, setOrderBy] = React.useState('name:asc');
  const facilityId = itemSelectedId;
  const projectId = itemSelectedId;
  const queryParams = {
    ...(isProject ? { projectId } : { facilityId }),
    orderby,
  };

  const [{ lazyLoad, paginationHandler, onOrderby: onOrderbyCallback }, { data }] =
    useLazyPaginatedQuery(gql(BILL_OF_MATERIALS), 'cache-and-network', 50);

  const { handleResponse } = useGraphqlResponseHandler(() => lazyLoad(queryParams));

  const onOrderby = React.useCallback(
    (columnName) => {
      onOrderbyCallback({
        columnName,
        variables: queryParams,
      });
    },
    [queryParams, onOrderbyCallback],
  );

  const onScroll = React.useCallback(() => {
    const skip = data?.billOfMaterials?.length ?? 0;
    paginationHandler(skip, queryParams);
  }, [paginationHandler, data, queryParams]);

  const pagination = React.useMemo(() => ({ onScroll, onOrderby }), [onScroll, onOrderby]);

  React.useEffect(() => {
    if (facilityId) lazyLoad(queryParams);
  }, [facilityId]);

  const [addBillOfMaterial] = useMutation(gql(ADD_BILL_OF_MATERIAL), {
    refetchQueries: ['BillOfMaterials'],
  });

  const onAddBOM = React.useCallback(
    async ({ form }) => {
      const body = { ...form, facilityId };
      await handleResponse(
        addBillOfMaterial,
        { variables: { body } },
        { successMessage: 'Bill Of Material added successfully' },
      );
    },
    [facilityId, addBillOfMaterial, lazyLoad],
  );

  const [deleteBillOfMaterials] = useMutation(gql(DELETE_BILL_OF_MATERIALS), {
    refetchQueries: ['BillOfMaterials'],
  });

  const onDeleteBOM = React.useCallback(
    async ({ billOfMaterialsId }) => {
      const params = { billOfMaterialsId };
      await handleResponse(
        deleteBillOfMaterials,
        { variables: { params } },
        { successMessage: 'Bill Of Material was deleted successfully' },
      );
    },
    [deleteBillOfMaterials],
  );

  const [renameBillOfMaterials, { loading: loadingRenameBill }] = useMutation(
    gql(RENAME_BILL_OF_MATERIALS),
    {
      refetchQueries: ['BillOfMaterials'],
    },
  );

  const onRenameBOM = React.useCallback(
    async ({ billOfMaterialsId, billOfMaterialsName }) => {
      const params = { billOfMaterialsId };
      const body = { billOfMaterialsName };
      await handleResponse(
        renameBillOfMaterials,
        { variables: { params, body } },
        { successMessage: 'Bill Of Material updated successfully' },
      );
    },
    [renameBillOfMaterials],
  );

  const [closeBillOfMaterials] = useMutation(gql(CLOSE_BILL_OF_MATERIALS), {
    refetchQueries: ['BillOfMaterials'],
  });

  const onCloseBOM = React.useCallback(
    async ({ billOfMaterialsId }) => {
      const params = { billOfMaterialsId };
      await handleResponse(
        closeBillOfMaterials,
        { variables: { params } },
        { successMessage: 'Bill Of Material was successfully moved to closed' },
      );
    },
    [closeBillOfMaterials],
  );

  const [openBillOfMaterials] = useMutation(gql(OPEN_BILL_OF_MATERIALS), {
    refetchQueries: ['BillOfMaterials'],
  });

  const onOpenBOM = React.useCallback(
    async ({ billOfMaterialsId }) => {
      const params = { billOfMaterialsId };
      await handleResponse(
        openBillOfMaterials,
        { variables: { params } },
        { successMessage: 'Bill Of Material was successfully moved to open' },
      );
    },
    [openBillOfMaterials],
  );

  const [getListOfMaterialsByName] = useLazyQuery(gql(BILL_OF_MATERIALS));

  const onGetListOfMaterialsByName = React.useCallback(
    async (billOfMaterialsName) => {
      if (!facilityId) return [];
      const result = await getListOfMaterialsByName({
        variables: { query: { billOfMaterialsName, take: 1, facilityId } },
        fetchPolicy: 'cache-and-network',
      });
      return result?.data?.billOfMaterials ?? [];
    },
    [facilityId, getListOfMaterialsByName],
  );

  return {
    callbacks: {
      onAddBOM,
      onDeleteBOM,
      onRenameBOM,
      onCloseBOM,
      onOpenBOM,
      onGetListOfMaterialsByName,
    },
    data: data?.billOfMaterials ?? [],
    setOrderBy,
    metadata: { facilityId, pagination },
    loadingRenameBill,
  };
};
