import React, { forwardRef, useCallback, useMemo } from 'react';

import { Box } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import { useUser } from 'app/UserContext';

import { useDrawingRegisterContext } from '../DrawingRegisterContext';
import { useDrawingFolderContext } from './DrawingFolderContext';
import { DrawingDocumentType, getStoragePathFolder } from './hooks/constants';

const DrawingFolderRowContext = React.createContext({});

const DrawingFolderRowProvider = ({ rowData, children }) => {
  const { row } = rowData;

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      folderName: row.documentName ?? '',
    },
  });

  const { user } = useUser();
  const companyId = useMemo(() => user?.companyId, [user]);

  const { rootFolder, activeItem } = useDrawingRegisterContext();
  const { folderParentRow, setFolderParentRow } = useDrawingFolderContext();
  const { id: projectId } = activeItem;

  const {
    closeNewFolderFormRow,
    handleCreateFolder,
    handleUpdateFolder,
    createFolderLoading,
    updateFolderLoading,
  } = useDrawingFolderContext();

  const {
    getValues,
    formState: { isDirty, isValid },
  } = methods;

  const onSaveFolder = useCallback(async () => {
    if (!isDirty) {
      closeNewFolderFormRow();
      return;
    }

    if (isValid) {
      if (activeItem.type !== 'PROJECT' || !projectId) return;
      const folderName = getValues('folderName');
      if (row.type !== DrawingDocumentType.NEW_FOLDER_FORM) {
        await handleUpdateFolder({
          folderId: row.documentIdentifier,
          folderName,
          folderDescription: folderName,
          parentFolderId: row.folder.parentFolderId,
        });
      } else {
        await handleCreateFolder({
          folderName,
          storagePath: folderParentRow
            ? folderParentRow.folder.storagePath
            : getStoragePathFolder('', companyId, projectId),
          folderDescription: folderName,
          parentFolderId: folderParentRow ? folderParentRow.id : rootFolder?.folderId,
          projectId,
        });
      }
      closeNewFolderFormRow();
      setFolderParentRow(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderParentRow, projectId, companyId, isValid, isDirty, rootFolder]);

  const memoizedValue = useMemo(
    () => ({
      onSaveFolder,
      saveFolderLoading: createFolderLoading || updateFolderLoading,
    }),
    [onSaveFolder, createFolderLoading, updateFolderLoading],
  );

  if (!row.isEditing) {
    return <>{children}</>;
  }

  return (
    <FormProvider {...methods}>
      <DrawingFolderRowContext.Provider value={memoizedValue}>
        <WrapperProvider>{children}</WrapperProvider>
      </DrawingFolderRowContext.Provider>
    </FormProvider>
  );
};

const useDrawingFolderRowContext = () => {
  const context = React.useContext(DrawingFolderRowContext);
  if (context === undefined) {
    throw new Error('useDrawingFolderRowContext must be used within a DrawingFolderRowContext');
  }
  return context;
};

export { DrawingFolderRowContext, DrawingFolderRowProvider, useDrawingFolderRowContext };

const WrapperProvider = forwardRef(({ children }, ref) => (
  <Box
    ref={ref}
    sx={{
      '& .MuiDataGrid-cell': {
        maxHeight: 'none !important',
        minHeight: 'auto !important',
        paddingTop: '3.5px !important',
        paddingBottom: '3.5px !important',
        alignItems: 'flex-start',
        background: 'rgba(0, 0, 0, 0.06)',
      },
      '& .MuiDataGrid-row': {
        maxHeight: 'none !important',
        minHeight: 'auto !important',
      },
      '& .MuiCheckbox-root': {
        display: 'none',
      },
      '& .MuiInputBase-input': {
        fontSize: '14px',
        lineHeight: '24px',
        fontWeight: '400',
        height: '20px',
      },
      '& .MuiSelect-select': {
        minHeight: '20px !important',
      },
      '& .MuiFormLabel-root': {
        fontSize: '14px',
        lineHeight: '20px',
      },
      '& .MuiSelect-select span': {
        lineHeight: '20px',
      },
    }}
  >
    {children}
  </Box>
));
