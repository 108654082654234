const NOT_RELEASED_STATUSES = ['Draft', 'Blocked'];
const RELEASED_STATUSES = ['Not Started', 'In Progress'];
const NOT_COMPLETED_STATUSES = ['Draft', 'Not Started', 'In Progress', 'Blocked'];
const NOT_DRAFT_STATUSES = ['Not Started', 'In Progress', 'Blocked', 'Completed'];

export const useWorkOrderPermissions = (workOrder) => {
  const WO_IS_NOT_RELEASED = NOT_RELEASED_STATUSES.includes(workOrder?.workOrderStatusTypeName);
  const WO_IS_RELEASED = RELEASED_STATUSES.includes(workOrder?.workOrderStatusTypeName);
  const WO_IS_NOT_COMPLETED = NOT_COMPLETED_STATUSES.includes(workOrder?.workOrderStatusTypeName);
  const WO_IS_NOT_DRAFT = NOT_DRAFT_STATUSES.includes(workOrder?.workOrderStatusTypeName);

  return {
    canAddWriteInItems: WO_IS_NOT_RELEASED,
    canAddCatalogItems: WO_IS_NOT_RELEASED,
    canEditQuantity: WO_IS_NOT_RELEASED,
    canEditItemName: WO_IS_NOT_RELEASED,
    canEditUOM: WO_IS_NOT_RELEASED,
    canDeleteItems: WO_IS_NOT_RELEASED,
    canReleaseWorkOrder: WO_IS_NOT_RELEASED,
    canDeleteWorkOrder: WO_IS_NOT_COMPLETED,
    canCancelWorkOrder: WO_IS_RELEASED,
    canAddShopTaskToWriteInItem: WO_IS_NOT_RELEASED,
    canAddShopTaskToCatalogItem: false,
    canDeleteShopTaskFromWriteInItem: WO_IS_NOT_RELEASED,
    canDeleteShopTaskFromCatalogItem: false,
    canSetPredecessors: WO_IS_NOT_RELEASED,
    canSeeStatusPoint: WO_IS_NOT_DRAFT,
    canAddWriteInItemToCatalog: true,
  };
};
