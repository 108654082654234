import React, { useMemo } from 'react';

import { Box } from '@mui/material';

import ItemsDataGridPro, { ItemsDataGridProProvider } from 'components/ItemsDatagridPro';

import { useWorkCellsList } from '../context/WorkCellsListContext';
import { mapWorkCellTasksDataToTableColumns } from '../helpers';
import NoRowsOverlay from './NoRowsOverlay';
import WorkCellsColumns from './WorkCellsTableColumns';

const WorkCellsTable = () => {
  const {
    workCells,
    workCellTasks,
    loading,
    statusToFilter,
    paginationHandlerWorkCellsTask,
    facilityId,
    filters,
    handleSortOrder,
  } = useWorkCellsList();
  const workCellsTasksColumns = mapWorkCellTasksDataToTableColumns(workCellTasks, workCells);

  const noRowsOverlayMessage = useMemo(() => {
    if (workCells?.length === 1) return 'Navigate to the Work Cell Setup to create a shop configuration.';
    return 'This shop configuration has no tasks.';
  }, [workCells]);

  return (
    <Box sx={{ mt: '14px', mb: '14px', flexGrow: 1 }}>
      <ItemsDataGridProProvider contextId="WorkCellsTable">
        <ItemsDataGridPro
          rowHeight={32}
          headerHeight={32}
          loading={loading}
          hideFooterPagination
          checkboxSelection={false}
          disableColumnMenu
          columns={WorkCellsColumns(statusToFilter)}
          getRowId={(row) => row?.workCellTaskId}
          rows={workCellsTasksColumns || []}
          onRowsScrollEnd={() => {
            if (workCellTasks) {
              paginationHandlerWorkCellsTask(workCellTasks.length, {
                take: 30,
                facilityIds: facilityId,
                ...filters,
              });
            }
          }}
          components={{
            NoRowsOverlay: () => (
              <NoRowsOverlay
                message={noRowsOverlayMessage}
                sx={{ width: noRowsOverlayMessage?.length > 60 ? '308px' : '100%' }}
              />
            ),
          }}
          onSortModelChange={handleSortOrder}
          sortingOrder={['desc', 'asc']}
        />
      </ItemsDataGridProProvider>
    </Box>
  );
};

export default WorkCellsTable;
