import React from 'react';

import { CircularProgress } from '@mui/material';

import { isNil } from 'helpers/isNotNil';

import { useCompanyMembersContext } from '../CompanyMembersContext';
import ControlledProductPoolAutocomplete from './ControlledProductPoolAutocomplete';

const AssignEntitlementsForm = ({ control }) => {
  const { dataProductPools, loadingProductPools } = useCompanyMembersContext();

  if (loadingProductPools) return <CircularProgress />;

  const productPools = dataProductPools.filter((d) => isNil(d.desktopLicenseId)) || [];

  return (
    <ControlledProductPoolAutocomplete
      name="entitlements"
      defaultValue={[]}
      options={productPools}
      label="Products"
      control={control}
      fullWidth
      data-cy="licenses-autocomplete"
    />
  );
};

export default AssignEntitlementsForm;
