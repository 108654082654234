import React, { forwardRef } from 'react';

import grey from '@mui/material/colors/grey';
import IconButton from '@mui/material/IconButton';

const RoundedIconButton = forwardRef(({ sx, ...props }, ref) => (
  <IconButton
    ref={ref}
    sx={{
      backgroundColor: grey[800],
      padding: '6px',
      borderRadius: '50px',
      '&:hover': {
        backgroundColor: grey[600],
        svg: {
          color: grey[500],
        },
      },
      ...sx,
    }}
    {...props}
  />
));

export default RoundedIconButton;
