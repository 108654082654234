import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import useQuery from 'hooks/useQuery';

import useResendSignup from './auth/useResendSignUp';
import ErrorMessage from './components/ErrorMessages';
import StyledAlert from './components/StyledAlert';

const VerifyEmailPrompt = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [verificationResent, setVerificationResent] = useState(false);
  const [errorCode, setErrorCode] = useState('');
  const query = useQuery();
  const resendSignUp = useResendSignup();
  const isUnverified = query.get('isUnverified');
  const email = query.get('email');

  useEffect(
    () => () => {
      setVerificationResent(false);
    },
    [setVerificationResent],
  );

  const handleResendEmail = async () => {
    if (email) {
      try {
        setIsLoading(true);
        const response = await resendSignUp({ email });
        if (response) {
          setVerificationResent(true);
        }
      } catch ({ code }) {
        setErrorCode(code);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const showEmailNotVerifiedError = isUnverified && !verificationResent;

  return (
    <Container maxWidth="xs">
      {showEmailNotVerifiedError ? (
        <EmailNotVerifiedAlert errorCode={errorCode} />
      ) : (
        <VerificationLinkSentAlert email={email} />
      )}
      <Title />
      <VerifyEmailMessage />
      <ResendEmailbutton disabled={isLoading} onClick={handleResendEmail} />
      <LoadingCircleSpacer loading={isLoading} />
      <EnteredWrongEmailPrompt />
    </Container>
  );
};

export default VerifyEmailPrompt;

const Title = () => (
  <Box mb="30px">
    <Typography component="h1" variant="h4" color="#10143D" align="center">
      Verify email address
    </Typography>
  </Box>
);

const VerifyEmailMessage = () => (
  <Box px="80px" mb="40px">
    <Typography variant="body2" component="p" align="center">
      You must verify your email address to login to your account
    </Typography>
  </Box>
);

const ResendEmailbutton = ({ disabled, onClick }) => (
  <Button
    variant="contained"
    disabled={disabled}
    color="secondary"
    fullWidth
    onClick={onClick}
    sx={{
      backgroundColor: '#10143D',
      '&:hover': {
        background: '#434A90',
      },
    }}
  >
    RESEND EMAIL
  </Button>
);

const LoadingCircleSpacer = ({ loading }) => (
  <Box height="120px" display="flex" justifyContent="center" alignItems="center">
    {loading && <CircularProgress color="secondary" />}
  </Box>
);

const EnteredWrongEmailPrompt = () => (
  <Box textAlign="center" px="40px">
    <Typography variant="body1">Entered the wrong email address?</Typography>
    <Link href="/auth/register" variant="body1" sx={{ color: '#4A93FF' }}>
      Register with a different email.
    </Link>
  </Box>
);

const VerificationLinkSentAlert = ({ email }) => (
  <Box mb="45px">
    <StyledAlert severity="success">{`An email verification link has been sent to ${email}!`}</StyledAlert>
  </Box>
);

const EmailNotVerifiedAlert = ({ errorCode }) => {
  if (!errorCode) return <ErrorMessage code="EmailNotVerified" />;
  return <ErrorMessage code={errorCode} />;
};
