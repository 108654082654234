import { useApolloClient } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import useSetupModule from 'hooks/useSetupModule';
import ConfirmationToast from 'modules/Materials/components/ItemsIntegrationModal/components/ConfirmationToast/ConfirmationToast';
import { QuantityFieldType } from 'modules/Materials/components/ItemsIntegrationModal/components/QuantityStep/components/ItemsIntegrationTable/components/QuantityField/hooks/@types/fieldHookTypes';
import { addWorkRequestItemsFromLineItem } from 'modules/Materials/components/ItemsIntegrationModal/helpers/itemsIntegrationModalHelpers';

import { useBOMbyIdContext } from '../BOMbyIdContext';

const useBomToWRIntegration = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { setSelectedPadItems } = useBOMbyIdContext();
  const client = useApolloClient();
  const { currentModule } = useSetupModule();

  const handleAddItems = async ({
    integrationId,
    itemFields,
    rows,
  }: {
    integrationId: string;
    itemFields: any[];
    rows: QuantityFieldType[];
  }) => {
    const workRequestIntegratedItems = await addWorkRequestItemsFromLineItem({
      client,
      currentWorkRequestId: integrationId,
      lineItemsFields: itemFields,
      lineItems: rows,
    });
    return workRequestIntegratedItems.map(
      ({ lineItemId, workRequestItemId }: { lineItemId: string; workRequestItemId: string }) => ({
        id: lineItemId,
        itemIntegratedId: workRequestItemId,
      }),
    );
  };

  const handleItemsCreated = ({
    integrationId,
    currentWorkRequestIdentifier,
    totalItemsCreated,
    isNewWR,
  }: {
    integrationId: string;
    currentWorkRequestIdentifier: string;
    totalItemsCreated: number;
    isNewWR: boolean;
  }) => {
    setSelectedPadItems([]);
    enqueueSnackbar('', {
      content: (key) => (
        <ConfirmationToast
          text={`${totalItemsCreated} item${
            totalItemsCreated > 1 ? 's' : ''
          } successfully added to ${currentWorkRequestIdentifier}`}
          action={{
            text: 'GO TO WORK REQUEST',
            onClick: () => navigate(`/${currentModule()?.toLowerCase()}/work-requests/${integrationId}`),
          }}
          id={key}
          key={key}
        />
      ),
    });
    if (isNewWR) enqueueSnackbar('Work Request successfully created', { variant: 'success' });
  };

  return { handleAddItems, handleItemsCreated };
};

export default useBomToWRIntegration;
