import React from 'react';

import useShopConfigurationDataLayer from '../../../hooks/useShopConfigurationDataLayer';
import EditShopConfiguration from '../../../ShopConfiguration/EditShopConfiguration';
import DeleteModal from '../../../ShopConfiguration/ShopConfigurationSelect/DeleteModal';
import { useShopConfigurationSelectContext } from '../../../ShopConfiguration/ShopConfigurationSelect/ShopConfigurationSelectContext';

const EditShopConfigurationModals = ({
  node,
  openDeleteShopModal = false,
  setOpenDeleteShopModal,
  openEditModal = false,
  setOpenEditModal,
  shopConfiguration,
}) => {
  const { removeShopConfiguration, loadingDeleteShopConfiguration } = useShopConfigurationDataLayer();
  const { refetchShopConfigurations } = useShopConfigurationSelectContext();

  const handleDeleteShopConfiguration = async (id) => {
    await removeShopConfiguration(id, () => {
      setOpenDeleteShopModal(false);
      refetchShopConfigurations();
    });
  };

  return (
    <>
      <DeleteModal
        open={openDeleteShopModal}
        onClose={() => setOpenDeleteShopModal(false)}
        onSubmit={() => handleDeleteShopConfiguration(node.value)}
        itemName={node.label}
        loading={loadingDeleteShopConfiguration}
      />

      <EditShopConfiguration
        openEditModal={openEditModal}
        setOpenEditModal={setOpenEditModal}
        shopConfiguration={shopConfiguration}
      />
    </>
  );
};

export default EditShopConfigurationModals;
