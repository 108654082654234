import { gql, useLazyQuery } from '@apollo/client';

import { FETCH_POLICIES } from 'constants/globalConstants';
import { machines as MACHINES, machineById as MACHINE_BY_ID } from 'graphql/queries';

import { useDataContext } from '../../WorkCellsAndMachines/contexts/DataContext';
import { useWorkCellsAndMachinesContext } from '../../WorkCellsAndMachines/contexts/WorkCellsAndMachinesContext';
import { getFacilityId } from '../../WorkCellsAndMachines/helpers/helpers';

export const useMachineEditFormHelpers = (
  getValues,
  currentMachine,
  setCurrentMachine,
  setError,
  trigger,
  clearErrors,
  setValue,
  activeNode,
  currentImageId,
) => {
  const [, { refetch: getMachines, loading: loadingMachines }] = useLazyQuery(
    gql(MACHINES),
    FETCH_POLICIES.NO_CACHE,
  );
  const [getMachine, { loading: loadingMachine }] = useLazyQuery(
    gql(MACHINE_BY_ID),
    FETCH_POLICIES.CACHE_AND_NETWORK,
  );
  const { getMachineImage, saveMachineForm } = useWorkCellsAndMachinesContext();
  const { selectedItem } = useDataContext();

  const setMachineData = async (machineId) => {
    if (activeNode.machineId || machineId) {
      const machineById = await getMachine({
        variables: {
          params: { machineId: machineId || activeNode.machineId },
        },
      });
      setCurrentMachine(machineById?.data?.machineById);
    }
  };

  const validateMachineIdentifier = async (machineIdentifier) => {
    const existingMachines = await getMachines({
      query: {
        machineIdentifier: `eq:${machineIdentifier.trim()}`,
        facilityId: getFacilityId(selectedItem),
      },
    });
    return existingMachines?.data?.machines?.some(
      (item) => currentMachine?.machineId !== item?.machineId,
    );
  };

  const submit = async () => {
    const body = getValues();
    if (body?.machineIdentifier) {
      const error = await validateMachineIdentifier(body?.machineIdentifier);
      if (error) {
        setError('machineIdentifier', { type: 'exist', message: 'Machine Id already exists' });
        return;
      }
    }

    const validate = await trigger();
    if (!validate) return;

    // const key = event?.target?.id || event.target?.name || child;
    // const value = key === 'machineImage' ? event?.target.files : event?.target?.value;
    // body[key] = value;
    clearErrors('machineIdentifier');

    await saveMachineForm({
      ...body,
      machineImageId: currentMachine?.machineImageId || currentImageId,
    });
  };

  const fetchMachineImage = (machineImageId) => {
    if (machineImageId) {
      getMachineImage(machineImageId).then((presignedURL) => {
        setValue('machineImage', presignedURL);
      });
      return;
    }
    setValue('machineImage', null);
  };

  return { submit, setMachineData, fetchMachineImage, loadingMachine, loadingMachines };
};
