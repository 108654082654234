/* eslint-disable max-lines-per-function */
import React, { useCallback, useEffect, useState } from 'react';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import useDocumentAPI, { TAKE_DOCUMENT_NUMBER } from 'hooks-api/useDocumentAPI';
import useDocumentMutation from 'hooks-api/useDocumentMutation';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import useDocumentTypes from 'modules/Shop/Fabrication/TaskViewer/PlansModelsContent/hooks/useDocumentTypes';

import { useDrawingRegisterContext } from '../DrawingRegisterContext';

const DEFAULT_VALUE = { documents: [], documentTypes: [] };

const DrawingDocumentContext = React.createContext(DEFAULT_VALUE);

const findDocumentById = (documents, id) => documents.find((document) => document.documentId === id);

export const DRAWING_DOCUMENT_VIEWS = {
  DOCUMENTS_TABLE: 'documentsTable',
  DOCUMENT_DETAIL: 'documentDetail',
};

const DrawingDocumentProvider = ({ children }) => {
  const { selectedItem } = useFacilitiesProjects();

  const { selectedFolder, sortModel, setIsTableEditMode } = useDrawingRegisterContext();
  const [idDocumentEdited, setIdDocumentEdited] = useState(null);
  const [showLeavingDialog, setShowLeavingDialog] = useState(false);
  const [currentView, setCurrentView] = useState(DRAWING_DOCUMENT_VIEWS.DOCUMENTS_TABLE);
  const [activeDocumentId, setActiveDocumentId] = useState(null);
  const { updateDocumentMutation } = useDocumentMutation();
  const { handleResponse } = useGraphqlResponseHandler();

  const { documentTypes, drawingModelCNCIds } = useDocumentTypes();

  const { documents, fetchDocumentsByFolder, paginationHandler, refetchExistingPages, uploadingFiles, loading } =
    useDocumentAPI();

  const fetchDocs = useCallback(
    async (folderIds) => {
      await fetchDocumentsByFolder({ folderIds, orderBy: sortModel, documentTypeIds: drawingModelCNCIds });
    },
    [fetchDocumentsByFolder, sortModel, drawingModelCNCIds],
  );

  const handleUpdateRow = useCallback(
    (row, field, updateRows) => {
      if (['documentIdentifier', 'documentName'].includes(field) && !loading && row.id !== idDocumentEdited) {
        if (idDocumentEdited) {
          updateRows([{ id: idDocumentEdited, isEditing: false }]);
        }
        setIdDocumentEdited(row.id);
        updateRows([{ id: row.id, isEditing: true, field }]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [idDocumentEdited],
  );

  const updateDrawing = async (document, updateRows) => {
    await handleResponse(
      updateDocumentMutation,
      {
        variables: {
          params: { documentId: idDocumentEdited },
          body: document,
        },
      },
      {
        successMessage: `Drawing successfully updated`,
      },
      () => {},
      () => {
        const document = findDocumentById(documents, idDocumentEdited);
        updateRows([{ id: idDocumentEdited, ...document }]);
      },
    );

    await refetchExistingPages({
      folderIds: selectedFolder?.folderId,
      orderBy: sortModel,
      documentTypeIds: drawingModelCNCIds,
    });

    updateRows([{ id: idDocumentEdited, isEditing: false, isProcessing: false }]);
    setIdDocumentEdited(null);
  };

  const fetchMoreDocuments = useCallback(async () => {
    if (selectedFolder && selectedFolder.folderId) {
      await paginationHandler(documents?.length, {
        folderIds: selectedFolder?.folderId,
        orderBy: sortModel,
        documentTypeIds: drawingModelCNCIds,
      });
    }
  }, [documents, paginationHandler, selectedFolder, sortModel, drawingModelCNCIds]);

  const closeFields = (event, id, updateRows) => {
    updateRows([{ id, isEditing: false, isProcessing: false }]);
    setIsTableEditMode(false);
    setIdDocumentEdited(null);
    event.stopPropagation();
  };

  useEffect(() => {
    if (selectedFolder && selectedFolder.folderId && drawingModelCNCIds) fetchDocs(selectedFolder.folderId);
  }, [selectedFolder, fetchDocs, sortModel, drawingModelCNCIds]);

  useEffect(() => {
    if (documents?.length && documents?.length < TAKE_DOCUMENT_NUMBER + 1) fetchMoreDocuments();
  }, [documents, fetchMoreDocuments]);

  useEffect(() => {
    setCurrentView(DRAWING_DOCUMENT_VIEWS.DOCUMENTS_TABLE);
  }, [selectedItem]);

  const inForgeView = currentView === DRAWING_DOCUMENT_VIEWS.DOCUMENT_DETAIL;

  return (
    <DrawingDocumentContext.Provider
      value={{
        documents,
        documentTypes,
        paginationHandler,
        idDocumentEdited,
        setIdDocumentEdited,
        showLeavingDialog,
        setShowLeavingDialog,
        handleUpdateRow,
        closeFields,
        updateDrawing,
        fetchMoreDocuments,
        refetchExistingPages,
        uploadingFiles,
        loading,
        inForgeView,
        setCurrentView,
        activeDocumentId,
        setActiveDocumentId,
      }}
    >
      {children}
    </DrawingDocumentContext.Provider>
  );
};

const useDrawingDocumentContext = () => {
  const context = React.useContext(DrawingDocumentContext);
  if (context === undefined) {
    throw new Error('useDrawingDocumentContext must be used within a DrawingDocumentContext');
  }
  return context;
};

export { DrawingDocumentContext, DrawingDocumentProvider, useDrawingDocumentContext };
