import React from 'react';

import { FilterOptionsProvider } from './features/filters/options';
import { RulesFilterProvider } from './features/filters/rules';
import { SourceDataOptionProvider } from './features/filters/source-data';

const FiltersProviderBase = ({ children }) => (
  <RulesFilterProvider>
    <SourceDataOptionProvider>
      <FilterOptionsProvider>{children}</FilterOptionsProvider>
    </SourceDataOptionProvider>
  </RulesFilterProvider>
);

export default FiltersProviderBase;
