const PREV_PAGE_BUTTON_ID = '[PageNavExtension]_prev_btn';
const NEXT_PAGE_BUTTON_ID = '[PageNavExtension]_next_btn';
const PAGE_NUM_BUTTON_ID = '[PageNavExtension]_page_num_btn';

const PAGE_NUM_TEXT_ELEMENT_CLASSNAME = 'adsk-page-num-text';
const PAGE_NUM_INPUT_ELEMENT_CLASSNAME = 'adsk-page-num-input';
const BUTTON_DISABLED_CLASSNAME = 'autodesk-btn-disabled';

const pageNumInputKeyDownListener = (gotoPageCb, currentPageNumber, totalPageNumber) => (event) => {
  const targetPageNumber = Number(event.target.value);
  const isPageOutOfRange = targetPageNumber <= 0 || targetPageNumber > totalPageNumber;

  if (event.key === 'Escape') {
    event.target.value = currentPageNumber;
  }

  if (event.key === 'Enter') {
    if (isPageOutOfRange) {
      event.target.value = currentPageNumber;
    } else {
      gotoPageCb(Number(targetPageNumber));
    }
  }
};

const pageNumInputFocusoutListener = (gotoPageCb, currentPageNumber, totalPageNumber) => (event) => {
  const targetPageNumber = Number(event.target.value);
  const isPageOutOfRange = targetPageNumber <= 0 || targetPageNumber > totalPageNumber;

  // focusout event gets triggered once user hit enter and input element gets lost the focus
  if (targetPageNumber !== currentPageNumber) {
    if (isPageOutOfRange) {
      event.target.value = currentPageNumber;
    } else {
      gotoPageCb(Number(targetPageNumber));
    }
  }
};

export const onLoadPageNavExtension =
  ({ currentPageNumber = 0, totalPageNumber = 0, gotoPageCb = () => {} }) =>
  (extension) => {
    const controlEl = document.getElementById(PAGE_NUM_BUTTON_ID);
    if (controlEl) {
      const prevInputElCollection = document.getElementsByClassName(PAGE_NUM_INPUT_ELEMENT_CLASSNAME);
      const prevInputEl = prevInputElCollection && prevInputElCollection[0];
      if (prevInputEl) {
        prevInputEl.remove();
      }
      const inputEl = document.createElement('input');
      inputEl.type = 'number';
      inputEl.classList.add(PAGE_NUM_INPUT_ELEMENT_CLASSNAME);
      inputEl.value = currentPageNumber;
      inputEl.addEventListener('keydown', pageNumInputKeyDownListener(gotoPageCb, currentPageNumber, totalPageNumber));
      inputEl.addEventListener(
        'focusout',
        pageNumInputFocusoutListener(gotoPageCb, currentPageNumber, totalPageNumber),
      );

      const prevTextEl = document.getElementsByClassName(PAGE_NUM_TEXT_ELEMENT_CLASSNAME);
      prevTextEl?.[0]?.remove();
      const textEl = document.createElement('p');
      textEl.classList.add(PAGE_NUM_TEXT_ELEMENT_CLASSNAME);
      textEl.innerText = `/ ${totalPageNumber}`;

      controlEl.appendChild(inputEl);
      controlEl.appendChild(textEl);
    }

    const isFirstPage = currentPageNumber === 1;
    const prevBtnControl = extension.subToolbar?.getControl(PREV_PAGE_BUTTON_ID);
    if (isFirstPage) {
      prevBtnControl?.addClass(BUTTON_DISABLED_CLASSNAME);
    } else {
      prevBtnControl?.removeClass(BUTTON_DISABLED_CLASSNAME);
    }

    const isLastPage = currentPageNumber === totalPageNumber;
    const nextBtnControl = extension.subToolbar?.getControl(NEXT_PAGE_BUTTON_ID);
    if (isLastPage) {
      nextBtnControl?.addClass(BUTTON_DISABLED_CLASSNAME);
    } else {
      nextBtnControl?.removeClass(BUTTON_DISABLED_CLASSNAME);
    }
  };
