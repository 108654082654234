import React from 'react';

import { Typography } from '@mui/material';

const CatalogSearchResultPartItemTitle = ({ children }) => (
  <Typography variant="body2" fontWeight="700">
    {children}
  </Typography>
);

export default CatalogSearchResultPartItemTitle;
