import { WatchQueryFetchPolicy } from '@apollo/client';

import { TaskStatusType } from 'graphql/types';
import { useTaskStatusTypes } from 'hooks-api/queries/taskStatusTypes/useTaskStatusTypes';

type UseTaskDocumentsType = {
  taskStatusTypes: TaskStatusType[];
  loading: boolean;
};

const useGetTaskStatusTypes = (fetchPolicy: WatchQueryFetchPolicy = 'cache-first'): UseTaskDocumentsType => {
  const { data, loading } = useTaskStatusTypes({ fetchPolicy });

  return {
    taskStatusTypes: data?.taskStatusTypes || [],
    loading,
  };
};

export default useGetTaskStatusTypes;
