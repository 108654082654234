const DEFAULT_SCHEMA = {
  lineItemName: null,
  tradeSize: null,
  unitQuantity: 0,
  uom: null,
  manufacturer: null,
  catalogNumber: null,
  quantity: 0,
  remainingQuantity: 0,
  actualRemainingQuantity: 0,
  sourcePadName: 'Unmapped',
  takeoffPadName: 'Unassigned',
};

export const partToTakeOffPadItemMapFn = (part = DEFAULT_SCHEMA, pad) => ({
  lineItemName: part.partName,
  tradeSize: part.tradeSize,
  unitQuantity: DEFAULT_SCHEMA.unitQuantity,
  uom: part.unitOfMeasure,
  manufacturer: part.manufacturer,
  catalogNumber: part.manufacturerCatalogNumber,
  quantity: DEFAULT_SCHEMA.quantity,
  remainingQuantity: DEFAULT_SCHEMA.remainingQuantity,
  padName: pad?.isSourcePad ? DEFAULT_SCHEMA.sourcePadName : DEFAULT_SCHEMA.takeoffPadName,
  actualRemainingQuantity: DEFAULT_SCHEMA.actualRemainingQuantity,
  isSourcePad: pad?.isSourcePad,
});

export const takeOffPadItemMapFn = (item, pad) => {
  const isSourcePad = pad?.isSourcePad;
  const {
    lineItemId,
    lineItemName,
    tradeSize,
    unitQuantity,
    unitOfMeasureId,
    unitOfMeasureCode,
    unitOfMeasureName,
    manufacturerId,
    manufacturerName,
    manufacturerCatalogNumber,
    quantity,
    remainingQuantity,
    billOfMaterialsName,
    takeoffPadName,
    actualRemainingQuantity,
    lineItemTypeId,
    partId,
    takeoffPadId,
    numberOfOpenDecisions,
    decisionsHaveVariantDeletedFromCurrentVersionOfPart,
    ableToMakeDecisions,
    lineItemDecisions,
  } = item;
  return {
    isSourcePad,
    lineItemId,
    lineItemName,
    tradeSize,
    unitQuantity,
    uom: { unitOfMeasureId, unitOfMeasureName, unitOfMeasureCode },
    manufacturer: { manufacturerId, manufacturerName },
    catalogNumber: manufacturerCatalogNumber,
    quantity,
    remainingQuantity,
    actualRemainingQuantity,
    padName: isSourcePad ? takeoffPadName : billOfMaterialsName,
    lineItemTypeId,
    partId,
    takeoffPadId,
    numberOfOpenDecisions,
    decisionsHaveVariantDeletedFromCurrentVersionOfPart,
    ableToMakeDecisions,
    lineItemDecisions,
  };
};

export const getFilterHeight = (containerFilter) => {
  const containerHeight = containerFilter?.offsetHeight ?? 0;
  const style = getComputedStyle(containerFilter);
  return containerHeight + parseInt(style?.marginTop, 10) + parseInt(style?.marginBottom, 10);
};
