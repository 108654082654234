import React from 'react';

import { Stack, Button, useTheme } from '@mui/material';

const DeleteModalFooter = ({ onClose, onConfirm, loading }) => {
  const { palette } = useTheme();
  return (
    <Stack direction="row">
      <Button
        onClick={onClose}
        disabled={loading}
        sx={{ fontWeight: '600' }}
        data-cy="CancelDeleteButton"
      >
        CANCEL
      </Button>
      <Button
        onClick={onConfirm}
        variant="contained"
        disabled={loading}
        data-cy="ConfirmDeleteButton"
        sx={{
          backgroundColor: palette.error.main,
          marginLeft: '8px',
          fontWeight: '500',
          '&:hover': {
            backgroundColor: palette.error.containedHoverBackground,
          },
        }}
      >
        CONFIRM
      </Button>
    </Stack>
  );
};

export default DeleteModalFooter;
