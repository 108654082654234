import React from 'react';

import { customMaxLengthRule } from 'constants/inputFieldRules';
import { onlyLettersRegEx, romanNumeralRegEx } from 'constants/regEx';

import TextField from './TextField';

const StartOnInput = ({ format, ...props }) => {
  const placeholder = (() => {
    if (format) {
      if (format === 'Numeric') return 'ex. 1';
      if (format === 'Alpha') return 'ex. A';
      if (format === 'Roman Numeral') return 'ex. I';
    }
    return 'ex. A';
  })();

  const inputProps = {};
  if (format === 'Numeric') inputProps.InputProps = { inputProps: { min: 1 } };

  return (
    <TextField
      name="startOn"
      label="Start On"
      sx={{ flex: 1 }}
      placeholder={placeholder}
      InputLabelProps={{ shrink: true }}
      rules={{
        ...customMaxLengthRule(4, 'Start On limited to 4 characters'),
        validate: (value) => {
          if (format) {
            if (format === 'Numeric' && value < 1) return 'Start On must be more than 1';
            if (format === 'Alpha' && !onlyLettersRegEx.test(value))
              return 'Only letters are allowed';
            if (format === 'Roman Numeral' && !romanNumeralRegEx.test(value))
              return 'Invalid roman numeral.';
          }
          return true;
        },
      }}
      type={format === 'Numeric' ? 'number' : 'text'}
      {...inputProps}
      {...props}
    />
  );
};

export default StartOnInput;
