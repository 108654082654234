import React, { FC, useMemo } from 'react';

import { Box, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { useItemsIntegrationModalContext } from '../../context/ItemsIntegrationModalContext';

const ErrorMessage: FC = () => {
  const { formState } = useFormContext();
  const { errors: errorsQuantityStep } = formState;
  const { focusedQtyField, hoveredQtyField } = useItemsIntegrationModalContext();
  const keyToSearch = useMemo(() => {
    if (focusedQtyField) return focusedQtyField;
    if (hoveredQtyField) return hoveredQtyField;
    return '';
  }, [focusedQtyField, hoveredQtyField]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const firstRangeError: any = useMemo(() => errorsQuantityStep[keyToSearch], [keyToSearch, formState]);
  const hintMessage = useMemo(() => {
    if (!hoveredQtyField || firstRangeError?.message) return '';
    return hoveredQtyField;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRangeError, hoveredQtyField]);
  return (
    <Box>
      {(firstRangeError?.message || hintMessage) && (
        <Typography variant="body1" color={hintMessage ? 'surface.lightSurface.secondary' : 'error'} pl={2}>
          {firstRangeError?.message || hintMessage}
        </Typography>
      )}
    </Box>
  );
};

export default ErrorMessage;
