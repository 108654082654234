import { gql, useLazyQuery } from '@apollo/client';

import { partCategory as CATEGORY_LIST } from 'graphql/queries';

export const useCategoryListSearcherQuery = () => {
  const [, { data: existingCategories, refetch: fetchSearchCategories }] = useLazyQuery(
    gql(CATEGORY_LIST),
    {
      fetchPolicy: 'no-cache',
    },
  );

  return { fetchSearchCategories, existingCategories };
};
