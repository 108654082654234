import { columnWorkRequest } from './Columns/columnWorkRequest';
import { columnWorkRequestResponsive } from './Columns/columnWorkRequestResponsive';

const WorkRequestsColumns = (
  CellComponentLink,
  CellComponentLinkResponsive,
  CellComponentStatus,
  CellComponentDays,
  CellComponentMoreMenu,
  CellWorkRequestItemAttachments,
  CellComponentWRName,
  isCompleted,
  selectedItem,
  isResponsive,
) => {
  if (isResponsive)
    return columnWorkRequestResponsive(CellComponentLinkResponsive, CellComponentWRName, CellComponentStatus);

  return columnWorkRequest(
    CellComponentLink,
    CellComponentStatus,
    isCompleted,
    CellComponentDays,
    CellWorkRequestItemAttachments,
    CellComponentMoreMenu,
    selectedItem,
    CellComponentWRName,
  );
};

export default WorkRequestsColumns;
