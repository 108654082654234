import React from 'react';

import { Stack, Typography } from '@mui/material';

import { Custom } from 'components/Icons';

export const NoCustomAssemblyTypeOverlay = () => (
  <Stack sx={{ background: 'white', alignItems: 'center', marginTop: '250px' }}>
    <Custom.AssemblyType data-testid="custom-assembly-type-icon" width="46" height="46" />
    <Typography
      variant="h5"
      sx={{
        color: 'surface.lightSurface.secondary',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '24px',
      }}
    >
      Start by creating a new assembly type.
    </Typography>
  </Stack>
);
