import React from 'react';

import { Stack } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { Custom } from 'components/Icons';
import OverFlowTooltip from 'components/Tooltip/OverFlowTooltip';

import { ColumnButtonProps } from './@types/buttons';

const ColumnButton = ({
  onClick,
  disabled = false,
  color = 'secondary.dark',
  isResponsive = false,
}: ColumnButtonProps) => (
  <OverFlowTooltip title="Columns" showAlways={isResponsive}>
    <Button onClick={onClick} sx={{ minWidth: 0, height: 'fit-content' }} disabled={disabled}>
      <Stack direction="row" sx={{ alignItems: 'center' }}>
        <Custom.ViewColumnIcon
          sx={{
            lineHeight: '24px',
            fontSize: !isResponsive ? '14px' : null,
            color: disabled ? 'secondary.disabled' : color,
            margin: '0px 5px 0px 0px',
          }}
        />
        {!isResponsive && (
          <Typography
            sx={{
              fontSize: '14px',
              color: disabled ? 'secondary.disabled' : color,
              lineHeight: '24px',
              fontWeight: '500',
            }}
          >
            COLUMNS
          </Typography>
        )}
      </Stack>
    </Button>
  </OverFlowTooltip>
);

export default ColumnButton;
