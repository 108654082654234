import React, { forwardRef } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import ReactDOM from 'react-dom';

import { Custom } from 'components/Icons';
import StackRow from 'components/StackRow';

import CatalogSearchResultPartItem from '../CatalogSearchResultPartItem/CatalogSearchResultPartItem';
import { usePartCategorySearch } from '../PartCategory/PartCategorySearchContext';

const dndPortal = document.getElementById('dnd-portal');

const CloudSearchGrid = ({ partsQty, handleChangePartQty, handleAddPart }) => {
  const { parts: cloudSearchParts, searchKey, noAssemblies, disablePartId } = usePartCategorySearch();

  if (cloudSearchParts?.length) {
    return (
      <Droppable droppableId="grid">
        {(droppableProvided) => (
          <Stack ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
            {cloudSearchParts.map((part, index) => (
              <Draggable key={part?.partId} draggableId={part?.partId} index={index}>
                {(draggableProvided, snapshot) => {
                  const Content = (
                    <Box
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                      key={part?.partId}
                    >
                      <CatalogSearchResultPartItem
                        disabled={(noAssemblies && part.hasAssembly === '1') || part.partHistoryRefId === disablePartId}
                        key={part.partId}
                        part={part}
                        partsQty={partsQty}
                        searchValue={searchKey}
                        onChangeQty={handleChangePartQty}
                        onAddItem={handleAddPart}
                      />
                    </Box>
                  );
                  const DragContent = (
                    <DragNode
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                      part={part}
                    />
                  );

                  return snapshot.isDragging ? ReactDOM.createPortal(DragContent, dndPortal) : Content;
                }}
              </Draggable>
            ))}
          </Stack>
        )}
      </Droppable>
    );
  }

  return null;
};

const DragNodeWrapper = (props) => (
  <StackRow
    sx={{
      minWidth: '400px',
      maxWidth: '400px',
      maxHeight: '32px',
      minHeight: '32px',
      borderLeft: '3px solid',
      borderLeftColor: 'secondary.main',
      backgroundColor: '  ',
      boxShadow:
        '0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12)',
    }}
    {...props}
  />
);

const DragNode = forwardRef(({ part, ...rest }, ref) => (
  <DragNodeWrapper ref={ref} {...rest}>
    <Custom.DragIndicatorIcon fontSize="small" sx={{ color: 'surface.darkSurface.primaryOpacity60' }} />
    <Custom.CheckBoxOutlineBlank sx={{ ml: 2, mr: 1, color: 'surface.darkSurface.primaryOpacity20' }} />
    <Custom.ArrowDropDownIcon sx={{ color: 'surface.darkSurface.primaryOpacity60' }} />
    <Typography variant="body2" color="white">
      {part.partName}
    </Typography>
  </DragNodeWrapper>
));

export default CloudSearchGrid;
