import React, { useState } from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { NoContentMessage, CenteredLoadSpinner } from 'components';
import { Custom } from 'components/Icons';
import OutlinedCard from 'modules/Field/components/OutlinedCard';
import OutlinedCardHeaderText from 'modules/Field/components/OutlinedCardHeaderText';

import ConfirmationModal from '../../../../components/ConfirmationModal';
import { useDeleteModal } from '../contexts/DeleteModalContext';
import { useLocationsAndWorkPhases } from '../contexts/LocationsAndWorkPhaseContext';
import LocationsRow from './components/LocationsRow';
import CreateLocationsModal from './CreateLocationsModal/CreateLocationsModal';
import { ChipsProvider } from './CreateLocationsModal/CreateLocationsModalContext';

const Locations = () => {
  const {
    locations: {
      activeRow,
      loading,
      locations,
      isEditingALocation,
      handleDeleteLocation,
      loadingLocations,
    },
    workPhases: { resume },
  } = useLocationsAndWorkPhases();

  const { deleteModalText, locationToBeDeleted, hideDeleteModal } = useDeleteModal();
  const [modalOpen, setModalOpen] = useState(false);
  const closeModal = () => setModalOpen(false);
  const openModal = () => setModalOpen(true);

  const titleSuffix = resume?.hasLocationPhases ? '+ Work Phases' : '';

  return (
    <Stack flex="1" sx={{ height: 'inherit' }}>
      <Typography variant="h5" sx={{ margin: '4px 0 16px 0' }}>
        Locations {titleSuffix}
      </Typography>
      <OutlinedCard
        header={
          <>
            <OutlinedCardHeaderText paddingLeft={3}>Id</OutlinedCardHeaderText>
            <OutlinedCardHeaderText paddingLeft={15}>Location</OutlinedCardHeaderText>
          </>
        }
        addButtonLabel="New Locations"
        onAddButtonClick={openModal}
        disabled={
          !activeRow?.locationId ||
          isEditingALocation ||
          Boolean(activeRow?.children?.length) ||
          Boolean(activeRow?.childrenTypeName) ||
          loadingLocations ||
          loading
        }
      >
        {!locations || loading ? (
          <>
            {loading ? (
              <CenteredLoadSpinner />
            ) : (
              <NoContentMessage text="Open a Project to add Locations">
                <Custom.LocationOn />
              </NoContentMessage>
            )}
          </>
        ) : (
          locations.map((location) => <LocationsRow key={location.locationId} node={location} />)
        )}
        {loadingLocations && <CenteredLoadSpinner />}
      </OutlinedCard>
      <ChipsProvider>
        <CreateLocationsModal open={modalOpen} onClose={closeModal} />
      </ChipsProvider>
      <ConfirmationModal
        open={Boolean(locationToBeDeleted)}
        onClose={hideDeleteModal}
        onConfirm={handleDeleteLocation}
        text={deleteModalText}
        cancelButtonText="CANCEL"
        confirmButtonText="CONFIRM"
        modalTitle="Confirm Delete"
      />
    </Stack>
  );
};

export default Locations;
