import React from 'react';

import ScopePackageDroppableContainer from './dragAndDropComponents/ScopePackageDroppableContainer';
import WorkPhaseRowInfo from './WorkPhaseRowInfo';

const WorkPhaseRow = ({ node, depth = 0 }) => (
  <>
    <WorkPhaseRowInfo node={node} depth={depth} />
    {node.isOpen && <ChildrenItems node={node} depth={depth} />}
  </>
);

export default WorkPhaseRow;

const ChildrenItems = ({ node, depth }) => {
  const children = node.children?.map((child, index) => (
    <WorkPhaseRow key={child.id} node={child} depth={depth + 1} index={index} />
  ));

  return depth === 1 ? (
    <ScopePackageDroppableContainer
      droppableId={`scopePackage_${node.id}`}
      workPhases={node.isOpen && node.children ? node.children : []}
    />
  ) : (
    children || []
  );
};
