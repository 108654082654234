import React from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import Divider from 'components/Divider';

const HelpDeskMenuContent = () => (
  <Box textAlign="center" width="240px" padding="16px">
    <HelpDeskTitle />
    <Divider sx={{ m: 1, height: 2 }} />
    <MenuLink
      onClick={() => {
        // eslint-disable-next-line no-underscore-dangle
        const hsp = window._hsp || [];
        hsp.push(['showBanner']);
      }}
    >
      Cookie Settings
    </MenuLink>
    <MenuLink href="https://www.evolvemep.com/privacy-policy/">Privacy Policy</MenuLink>
    <MenuLink href="https://www.evolvemep.com/contact-support/">Contact Support</MenuLink>
    <MenuLink href="https://www.evolvemep.com/wp-content/uploads/2022/01/eVolve-EULA-1-1-2022-Final-1-2.pdf ">
      End-User License Agreement
    </MenuLink>
    <MenuLink href="https://help-foresite.evolvemep.com/">Knowledge Base</MenuLink>
    <MenuLink href="https://evolveforesite.featureupvote.com/">Submit Idea</MenuLink>
    {/* <MenuLink>Privacy Policy</MenuLink>
    <MenuLink>Cookie Policy</MenuLink> */}
  </Box>
);

export default HelpDeskMenuContent;

const HelpDeskTitle = () => (
  <Typography
    align="center"
    color="white"
    variant="h6"
    sx={{
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }}
  >
    Help Desk
  </Typography>
);

const MenuLink = ({ children, onClick, href }) => (
  <Box my="20px">
    <Typography variant="body2">
      <Link
        rel="noopener noreferrer"
        target="_blank"
        href={href}
        onClick={onClick}
        sx={{ color: 'other.links', cursor: 'pointer' }}
      >
        {children}
      </Link>
    </Typography>
  </Box>
);
