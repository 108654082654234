import React, { useContext, useState, useEffect } from 'react';

import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

import { useUser } from 'app/UserContext';
import { VARIANT_ERROR } from 'constants/snackbarConstants';
import useDocumentAPI from 'hooks-api/useDocumentAPI';
import useGetTaskById from 'hooks/task/useTaskById/useGetTaskById';
import useDocumentsCache from 'hooks/useDocumentsCache';
import useUploadFile from 'hooks/useUploadFile';

import useCalculateTime from './hooks/useCalculateTime';
import useProductionTime from './hooks/useProductionTime';
import useActionsDocument from './PlansModelsContent/hooks/useActionsDocument';
import useGetFolderByProjectId from './PlansModelsContent/hooks/useGetFolderByProjectId';
import useTaskDocuments from './PlansModelsContent/hooks/useTaskDocuments';
import useTaskProperties from './PlansModelsContent/hooks/useTaskProperties';

const TaskViewerContext = React.createContext();

// eslint-disable-next-line max-lines-per-function
const TaskViewerProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [taskError, setTaskError] = useState(false);
  const [isAddingAnAttach, setIsAddingAnAttach] = useState(false);
  const { id: taskId } = useParams();
  const { user } = useUser();
  const { uploadFile, uploading } = useUploadFile(user);
  const { addDocumentToFolder, addDocumentLoading } = useDocumentAPI();
  const { deleteDocumentsCache } = useDocumentsCache();
  const { workRequestId, workOrderId, projectId } = useTaskProperties();
  const { task, loading, called } = useGetTaskById(taskId);

  const productionTime = useProductionTime(taskId, task);

  const [typeFileFormat, setTypeFileFormat] = useState('');

  const unityQuantity = task?.quantity;

  useEffect(() => {
    if (called && !loading && !task) {
      setTaskError(true);
      enqueueSnackbar('Error getting task info.', VARIANT_ERROR);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, called]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => deleteDocumentsCache('taskDocument', { taskIds: taskId }), []);

  const value = {
    taskId,
    unityQuantity,
    taskName: task?.taskName || '',
    loadingTask: loading,
    taskError,
    unitTime: useCalculateTime(productionTime.timeData, unityQuantity),
    ...useTaskDocuments(taskId, workRequestId, workOrderId),
    ...useGetFolderByProjectId(projectId),
    ...useActionsDocument(),
    ...useTaskProperties(),
    ...productionTime,
    typeFileFormat,
    setTypeFileFormat,
    addDocumentToFolder,
    addDocumentLoading,
    uploadFile,
    uploading,
    setIsAddingAnAttach,
    isAddingAnAttach,
    needBy: task?.needBy,
  };

  return <TaskViewerContext.Provider value={value}>{children}</TaskViewerContext.Provider>;
};

const useTaskViewerContext = () => {
  const context = useContext(TaskViewerContext);
  if (context === undefined) {
    throw new Error('useTaskViewerContext must be used within a TaskViewerContext');
  }
  return context;
};

export { TaskViewerContext, TaskViewerProvider, useTaskViewerContext };
