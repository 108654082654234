import React, { useState } from 'react';

import { CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { VARIANT_ERROR, VARIANT_SUCCESS } from 'constants/snackbarConstants';
import { WorkRequest } from 'graphql/types';
import useRefetchWorkRequestsByChildWorkRequest from 'modules/Field/WorkRequests/hooks/useRefetchWorkRequestsByChildWorkRequest';
import useUpdateWorkRequestStatus from 'modules/Field/WorkRequests/WorkRequest/hooks/useUpdateWorkRequestStatus';

import { StatusTypeName } from '../@types/StatusTypeName';
import TableButton from '../TableButton';

type ApproveActionProps = {
  workRequest: WorkRequest;
};

const ApproveAction = ({ workRequest }: ApproveActionProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const statusTypeName = (workRequest?.workRequestStatusName as StatusTypeName) ?? undefined;
  const { updateWorkRequestStatus, loading } = useUpdateWorkRequestStatus();
  const { enqueueSnackbar } = useSnackbar();
  const { refetchWorkRequestByChildWorkRequest } = useRefetchWorkRequestsByChildWorkRequest();
  const { selectedItem } = useFacilitiesProjects();

  const handleUpdateWorkRequestStatus = async (label: string, description?: string) => {
    try {
      setIsSubmitting(true);
      const result = await updateWorkRequestStatus(workRequest, label, description ?? '');
      await refetchWorkRequestByChildWorkRequest(workRequest);
      enqueueSnackbar(result, VARIANT_SUCCESS);
    } catch (err) {
      enqueueSnackbar('Could not save changes.', VARIANT_ERROR);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onClick = () => handleUpdateWorkRequestStatus('Approved');

  if (selectedItem?.type !== 'FACILITY' || statusTypeName !== 'Pending') return <></>;

  return (
    <>
      {isSubmitting && <CircularProgress size={24} sx={{ color: 'secondary.main' }} />}
      {!isSubmitting && (
        <TableButton onClick={onClick} disabled={loading}>
          Approve
        </TableButton>
      )}
    </>
  );
};

export default ApproveAction;
