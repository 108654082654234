import { useCallback } from 'react';

import { CognitoUser } from '@aws-amplify/auth';
import { Auth } from 'aws-amplify';

export interface UserFromCognito extends CognitoUser {
  username: string;
}

const useAuth = () => {
  const getCognitoUser = useCallback(async (): Promise<UserFromCognito> => {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    return cognitoUser;
  }, []);

  return { getCognitoUser };
};

export default useAuth;
