import React, { useCallback, useMemo, useState } from 'react';

import { useExternalParameters } from '../useExternalParameters';
import { onUpdateQueryCallback } from './callbacks';
import { SourceDataOptionProps } from './types';

export const SourceDataOption = React.createContext<SourceDataOptionProps>(null);

export const SourceDataOptionProvider = ({ children }: { children: React.ReactNode }) => {
  const { sourceData } = useExternalParameters();

  const [query, setQuery] = useState<string[]>([]);

  const onUpdateQuery = useCallback(
    (key: string) => {
      const result = onUpdateQueryCallback(key, query);
      setQuery(result);
    },
    [query],
  );

  const onClearQuery = useCallback(() => setQuery([]), []);

  const encodedQuery = useMemo(() => encodeURIComponent(JSON.stringify(query)), [query]);

  return (
    <SourceDataOption.Provider
      value={{ query, onUpdateQuery, onClearQuery, sourceData, encodedQuery }}
    >
      {children}
    </SourceDataOption.Provider>
  );
};

export const useSourceDataOption = () => {
  const context = React.useContext(SourceDataOption);
  if (!context) throw new Error('useSourceDataOption require SourceDataOptionProvider');
  return context;
};
