import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Search from 'components/TableComponents/Search';

const CustomersHeader = ({ handleSearch }) => (
  <HeaderWrapper>
    <Grid container justifyContent="space-between" wrap="nowrap">
      <Typography variant="h5" gutterBottom>
        Customers
      </Typography>
      <Search data-cy="search-company-input" handleSearch={handleSearch} placeholder="Search…" />
    </Grid>
  </HeaderWrapper>
);

export default CustomersHeader;

const HeaderWrapper = (props) => <Box sx={{ marginBottom: '20px' }} {...props} />;
