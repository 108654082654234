import React, { useState, useEffect, useMemo } from 'react';

import { Typography, Stack, IconButton, useTheme } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { Custom } from 'components/Icons';
import Section from 'components/Section';
import { SESSION_STORAGE_CONSTANTS } from 'constants/globalConstants';
import { actionColors } from 'styles/theme/colors';

import { useCatalogModal, useCatalogModalQuery } from './CatalogModalContext';

const { PART_CATEGORY_FROM_WR } = SESSION_STORAGE_CONSTANTS;

const CategoriesTree = () => (
  <Section mr="5px" maxWidth="320px">
    <Section.Title sx={{ minHeight: '32px' }}>
      <Typography variant="subtitle2">Categories</Typography>
    </Section.Title>
    <Section.Body height="360px" maxHeight="360px !important">
      <CategoryList />
    </Section.Body>
  </Section>
);

export default CategoriesTree;

const CategoryList = ({ depth = 0, categoryId = null }) => {
  const { selectedCatalog } = useCatalogModal();
  const { fetchCategories, calledCategories, categoriesData } = useCatalogModalQuery(categoryId);

  if (!calledCategories && selectedCatalog) fetchCategories();

  return (
    <>
      {categoriesData?.partCategory?.map((category) => (
        <CategoryItem
          key={category.partCategoryId}
          depth={depth}
          category={category}
          renderList={() => <CategoryList depth={depth + 1} categoryId={category.partCategoryId} />}
        />
      ))}
    </>
  );
};

const CategoryItem = ({ depth, category, renderList }) => {
  const { subNodes, partCategoryName } = category;
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (depth === 0) setIsOpen(true);
  }, [depth]);

  const visible = subNodes?.length > 0;
  const shouldRenderList = isOpen && visible;

  return (
    <>
      <CategoryContainer depth={depth} category={category}>
        <ArrowButton isOpen={isOpen}>
          {visible && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setIsOpen((prev) => !prev);
              }}
              style={{ paddingTop: '0px', paddingBottom: '0px' }}
            >
              <Custom.ArrowRight style={{ fill: actionColors.lightSurface.active }} />
            </IconButton>
          )}
        </ArrowButton>
        <Typography variant="body2"> {partCategoryName} </Typography>
      </CategoryContainer>
      {shouldRenderList && renderList()}
    </>
  );
};

const CategoryContainer = ({ depth, category, children }) => {
  const theme = useTheme();
  const { isLoading } = useFormContext();

  const { selectedCategory, setSelectedCategory } = useCatalogModal();
  const categoryItemBgColor = useMemo(
    () => (selectedCategory === category.partCategoryId ? theme.palette.greyColors.grey200 : ''),
    [category.partCategoryId, selectedCategory, theme.palette.greyColors.grey200],
  );
  const disabledStyles = isLoading ? { pointerEvents: 'none', opacity: 0.4 } : {};

  const handleClick = () => {
    if (category.partCategoryName !== 'Categories') {
      setSelectedCategory(category.partCategoryId);
      sessionStorage.setItem(PART_CATEGORY_FROM_WR, JSON.stringify(category));
    }
  };
  return (
    <Stack
      onClick={handleClick}
      direction="row"
      sx={{
        boxShadow: 'inset 0px -1px 0px #CCCCCC;',
        padding: `4px ${16 * depth + 16}px`,
        alignItems: 'center',
        cursor: 'default',
        backgroundColor: categoryItemBgColor,
        ...disabledStyles,
      }}
    >
      {children}
    </Stack>
  );
};

const ArrowButton = ({ isOpen, children }) => (
  <Stack
    sx={{
      minHeight: '24px',
      minWidth: '24px',
      alignItems: 'center',
      transform: isOpen ? `rotate(90deg)` : '',
    }}
  >
    {children}
  </Stack>
);
