import React, { ForwardRefExoticComponent, forwardRef } from 'react';

import { Box, SxProps } from '@mui/material';

import KanbanColumnContent from './KanbanColumnContent';
import KanbanColumnHeader from './KanbanColumnHeader';

interface KanbanColumnCompose {
  Content: typeof KanbanColumnContent;
  Header: typeof KanbanColumnHeader;
}

type KanbanColumnProps = {
  sx: SxProps;
  children: React.ReactNode;
};

const KanbanColumn = forwardRef<HTMLDivElement, KanbanColumnProps>(
  ({ sx, children, ...restProps }, ref) => (
    <Box ref={ref} sx={{ ...sx }} {...restProps}>
      {children}
    </Box>
  ),
);

const KanbanColumnComposed = KanbanColumn as ForwardRefExoticComponent<
  KanbanColumnProps & React.RefAttributes<HTMLDivElement>
> &
  KanbanColumnCompose;

KanbanColumnComposed.Header = KanbanColumnHeader;
KanbanColumnComposed.Content = KanbanColumnContent;

export default KanbanColumnComposed;
