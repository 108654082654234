import * as React from 'react';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { Custom } from '../Icons';

const SortableColumnHeader = ({ onClick, label, sort, style, sx }) => (
  <TableCell sx={{ fontWeight: 'bold', width: 55, ...sx, ...style }}>
    <Box sx={{ display: 'flex', width: 'min-content' }}>
      <Typography sx={{ overflow: 'hidden', whiteSpace: 'nowrap' }} variant="inherit">
        {label}
      </Typography>
      {sort && (
        <IconButton sx={{ ml: 2, width: 18, height: 18 }} onClick={onClick} size="large">
          <Custom.ArrowDownward sx={{ fontSize: 14 }} />
        </IconButton>
      )}
    </Box>
  </TableCell>
);

const TableHeaders = ({ headers = [], onSelected, hasAllSelected, onSort, rowSx }) => (
  <TableHead>
    <TableRow>
      {onSelected && <SelectCell onChange={onSelected} checked={Boolean(hasAllSelected)} />}
      {headers.map(({ label, headerEmpty, key, sort, sortServerKey, style }) => {
        if (headerEmpty) {
          return <TableCell key={key} sx={{ width: 55, ...rowSx, ...style }} />;
        }
        return (
          <SortableColumnHeader
            style={style}
            sort={sort}
            key={key}
            onClick={() => onSort(key, sortServerKey)}
            label={label}
            sx={rowSx}
          />
        );
      })}
    </TableRow>
  </TableHead>
);

const SelectCell = ({ onChange, checked }) => (
  <CheckAllCell padding="checkbox">
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Custom.FlagIcon sx={{ color: '#0000008A' }} fontSize="small" />
      <CheckAllBox onChange={onChange} checked={Boolean(checked)} />
    </Box>
  </CheckAllCell>
);

const CheckAllBox = ({ sx, ...props }) => (
  <Checkbox
    sx={{
      color: 'action.lightSurface.active',
      '&.Mui-checked': {
        color: 'secondary.main',
      },
      '&.MuiCheckbox-indeterminate': {
        color: 'secondary.main',
      },
      ...sx,
    }}
    {...props}
  />
);

const CheckAllCell = ({ sx, ...props }) => (
  <TableCell
    sx={{
      backgroundColor: 'white',
      width: 24,
      ...sx,
    }}
    {...props}
  />
);

export default TableHeaders;
