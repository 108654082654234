import { format } from 'date-fns';

export const getFileWithFormattedName = (originalFile) => {
  if (originalFile.name.length <= 64) {
    return originalFile;
  }

  const { name } = originalFile;
  const ext = name.split('.').pop();
  const dateFormat = 'MM dd, yyyy';
  const todayString = format(new Date(), dateFormat);
  const newName = `${name.slice(0, 64 - dateFormat.length - 1 - ext.length)}${todayString}.${ext}`;

  return new File([originalFile], newName, { ...originalFile });
};
