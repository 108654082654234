import { useForm } from 'react-hook-form';

const useCheckboxesForm = (filteredIds = []) => {
  const { control, watch } = useForm({
    mode: 'onChange',
    defaultValues: {
      show0WorkLocations: Boolean(filteredIds?.length),
      show0WorkPhases: Boolean(filteredIds?.length),
    },
  });

  return {
    control,
    show0Locations: watch('show0WorkLocations'),
    show0WorkPhases: watch('show0WorkPhases'),
  };
};

export default useCheckboxesForm;
