import { gql, useReactiveVar } from '@apollo/client';

import { selectedWorkCellIdOnQueueVar } from 'apollo/reactiveVars';
import { workCellTasksQueue as WORK_CELL_TASKS_QUEUE } from 'graphql/queries';
import { Task, UpdateTaskMutation } from 'graphql/types';
import useCache from 'hooks/useCache/useCache';

import useTaskStatusTypeIds from '../../../../../hooks/useTaskStatusTypes/useTaskStatusTypeIds';

const getUpdatedChildren = (taskId: string, updatedTask: Task, cachedQueue: Task[] = [], isCompleted: boolean) => {
  const updatedRows = cachedQueue
    .map((cachedWorkCellTask) => {
      if (cachedWorkCellTask.taskId !== taskId) return cachedWorkCellTask;

      if (isCompleted) return null;

      return {
        ...cachedWorkCellTask,
        taskStatusTypeId: updatedTask.taskStatusTypeId,
        taskStatusTypeName: updatedTask.taskStatusTypeName,
      };
    })
    .filter((item) => Boolean(item));
  return updatedRows;
};

export default function useHandleUpdateQueueTableRows() {
  const { open, closed } = useTaskStatusTypeIds();
  const workCellId = useReactiveVar(selectedWorkCellIdOnQueueVar);
  const { getAllQueriesByKey, deleteFromCacheByQuery, writeCache } = useCache();

  const handleUpdateQueueTask = (taskId: string, newStatusId: string, response: UpdateTaskMutation) => {
    if (!response.updateTask) return;

    const cachedQueueQueries = getAllQueriesByKey('workCellTasksQueue', gql(WORK_CELL_TASKS_QUEUE), {
      workCellId,
      taskStatusTypeIds: open,
    });

    cachedQueueQueries.forEach((cachedQuery) => {
      const isCompleted = newStatusId === closed;

      const updatedChildren = getUpdatedChildren(
        taskId,
        response.updateTask as Task,
        cachedQuery.value as Task[],
        isCompleted,
      );

      if (isCompleted) deleteFromCacheByQuery('workCellTasksQueue', cachedQuery.query);
      writeCache(gql(WORK_CELL_TASKS_QUEUE), { query: cachedQuery.query }, { workCellTasksQueue: updatedChildren });
    });
  };

  return {
    handleUpdateQueueTask,
  };
}
