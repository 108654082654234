export const WRITE_IN_ASSEMBLY = 'b9182791-3777-4946-bf4f-797cc0e7d5a4';
export const ASSEMBLY_ITEM_TYPE_ID = '9259a9fd-dcb4-4ab2-807f-2867d368bb7f';
export const SPOOL_UOM = '85908275-9d41-4607-9226-e217b48803b7';
export const SUBMIT_STYLES = {
  backgroundColor: 'secondary.main',
  color: '#FFFFFF',
  border: 'none',
  marginLeft: '16px',
  '&:hover': {
    backgroundColor: 'secondary.containedHoverBackground',
    border: 'none',
    boxShadow: 'none',
  },
};
export const DETAIL_PANEL_TOGGLE_COLUMN = {
  width: 24,
  minWidth: 24,
  maxWidth: 24,
  cellClassName: 'MuiDataGrid-cell--detail-panel-toggle',
};
