import React from 'react';

import ProjectCreateModal from './ProjectCreateModal/ProjectCreateModal';
import { ProjectsContextProvider } from './ProjectsContext';
import ProjectsHeader from './ProjectsHeader';
import ProjectsTable from './ProjectsTable';

const Projects = () => (
  <>
    <ProjectsContextProvider>
      <ProjectsHeader />
      <ProjectCreateModal />
      <ProjectsTable />
    </ProjectsContextProvider>
  </>
);

export default Projects;
