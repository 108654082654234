import React from 'react';

import Checkbox from '@mui/material/Checkbox';

const ThemedCheckBox = ({ sx = {}, ...props }: React.ComponentProps<typeof Checkbox>) => (
  <Checkbox
    sx={{
      '&.Mui-checked': {
        color: 'secondary.main',
      },
      '&.MuiCheckbox-indeterminate': {
        color: 'secondary.main',
      },
      ...sx,
    }}
    {...props}
  />
);

export default ThemedCheckBox;
