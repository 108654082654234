import { useEffect, useState, useCallback } from 'react';

import { gql, useLazyQuery } from '@apollo/client';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import {
  shopConfigurations as SHOP_CONFIGURATIONS,
  shopConfigurationsMultiSearch as SHOP_CONFIGURATIONS_MULTI_SEARCH,
} from 'graphql/queries';

const getFacilityId = (selectedItem) => (selectedItem?.type === 'FACILITY' ? selectedItem?.id : null);

const useGetShopConfigurations = () => {
  const { selectedItem } = useFacilitiesProjects();
  const facilityId = getFacilityId(selectedItem);

  const [shopConfigurations, setShopConfigurations] = useState([]);

  const [fetchShopConfigurations, { data, loading: loadingShopConfiguration, refetch }] = useLazyQuery(
    gql(SHOP_CONFIGURATIONS),
    { fetchPolicy: 'cache-and-network' },
  );

  const [fetchShopConfigurationSearch, { data: multiSearch }] = useLazyQuery(gql(SHOP_CONFIGURATIONS_MULTI_SEARCH), {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!facilityId) return;

    fetchShopConfigurations({
      variables: {
        params: { facilityId },
      },
    });
  }, [facilityId, fetchShopConfigurations]);

  useEffect(() => {
    if (!data?.shopConfigurations) return;

    const formattedOptions = data.shopConfigurations.map(
      ({ shopConfigurationId, shopConfigurationName, shopConfigurationDescription, isActive }) => ({
        value: shopConfigurationId,
        label: shopConfigurationName,
        description: shopConfigurationDescription,
        isActive,
      }),
    );
    setShopConfigurations(formattedOptions);
  }, [data]);

  const refetchShopConfigurations = () => {
    refetch();
  };

  const onGetShopConfigurationName = useCallback(
    async (nameToSearch) => {
      await fetchShopConfigurationSearch({
        variables: {
          query: {
            facilityId,
            shopConfigurationName: `eq:${encodeURIComponent(nameToSearch.trim())}`,
          },
        },
        fetchPolicy: 'cache-and-network',
      });
    },
    [facilityId, fetchShopConfigurationSearch],
  );

  const validateTypingText = useCallback(
    async (propName) => {
      onGetShopConfigurationName(propName);
    },
    [onGetShopConfigurationName],
  );

  return {
    shopConfigurations,
    loadingShopConfiguration,
    setShopConfigurations,
    refetchShopConfigurations,
    shopConfigurationsUnformatted: data?.shopConfigurations ?? [],
    activeShopConfiguration: shopConfigurations?.filter((config) => config.isActive)[0] || null,
    onGetShopConfigurationName,
    multiSearch,
    validateTypingText,
  };
};

export default useGetShopConfigurations;
