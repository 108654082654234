import React from 'react';

import { MenuItem, Stack, Typography, Box } from '@mui/material';

import { PartThumbnailOutlined } from 'assets/images';

import { getArrayOfParts } from '../../CatalogSearchResultPartItem/helpers/highlightWordInString';

const highlightKeywordInString = (string = '', keyword = '') => {
  if (!keyword) return <PartItemDescription>{string}</PartItemDescription>;
  return (
    <PartItemDescription>
      {getArrayOfParts(string, keyword).map((part) => (
        <span key={part.id} style={{ ...(part.isHighlight && { fontWeight: 700 }) }}>
          {part.text}
        </span>
      ))}
    </PartItemDescription>
  );
};

const CatalogSearchPartItem = ({ item = {}, searchValue, onSelect = () => {}, sx }) => (
  <MenuItem
    role="button"
    onClick={() => onSelect(item)}
    sx={{ ...sx, height: '50px', padding: '0px 16px' }}
  >
    <Box display="flex" alignItems="center">
      <PartThumbnailOutlined />
    </Box>
    <Stack
      direction="row"
      sx={{
        ml: 1,
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {highlightKeywordInString(item.label, searchValue)}
    </Stack>
  </MenuItem>
);

export default CatalogSearchPartItem;

const PartItemDescription = ({ children }) => (
  <Typography variant="body2" style={{ color: 'white' }}>
    {children}
  </Typography>
);
