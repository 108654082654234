/* eslint-disable max-lines-per-function */
import React, { useCallback, useState } from 'react';

import useFetchPartImages from './hooks/useFetchPartImages';
import usePartImage from './hooks/usePartImage';

const PartImageContext = React.createContext();

const PartImageProvider = ({ children }) => {
  const {
    addPartImage,
    updatePartImage,
    deletePartImage,
    uploadingPartAdditionalImages,
    uploadingPartDefaultImage,
    uploadingProgress,
  } = usePartImage();
  const [imageUploading, setImageUploading] = useState(false);
  const { partAdditionalImages, partDefaultImage } = useFetchPartImages();

  const deletePartImages = useCallback(
    async (partImages) => {
      // eslint-disable-next-line
      for (const item of partImages) {
        // eslint-disable-next-line
        await deletePartImage({ ...item });
      }
    },
    [deletePartImage],
  );

  const addUpdatePartImages = useCallback(
    async (partImages) => {
      // eslint-disable-next-line
      for (const item of partImages) {
        const { partImageId, partImage, partId, isDefault, isDeleted, sortOrder, documentId } =
          item;

        if (!isDeleted && partImageId) {
          // eslint-disable-next-line
          await updatePartImage({
            partId,
            partImageId,
            imgFile: partImage,
            isDefault,
            sortOrder,
          });
        } else {
          // eslint-disable-next-line
          await addPartImage({
            partId,
            imgFile: partImage,
            isDefault,
            documentId,
          });
        }
      }
    },
    [updatePartImage, addPartImage],
  );

  const uploadPartImages = useCallback(
    async (partId, values) => {
      setImageUploading(true);
      const defaultPartImage = values.partDefaultImage;
      const additionalImages = values.partAdditionalImages ?? [];
      const partImages = additionalImages.map((item) => ({
        partId,
        isDefault: false,
        documentId: null,
        partImageId: item?.partImageId,
        partImage: item?.partImage,
        isDeleted: item?.isDeleted,
        sortOrder: item?.sortOrder,
      }));
      partImages.unshift({
        partId,
        isDefault: true,
        sortOrder: 1,
        documentId: defaultPartImage?.documentId,
        partImageId: defaultPartImage?.partImageId,
        partImage: defaultPartImage?.partImage,
        isDeleted: defaultPartImage?.isDeleted,
      });

      const partImagesToRemoved = partImages.filter((item) => item.isDeleted && item.partImageId);
      const partImagesToAddUpdate = partImages.filter(
        (item) => (item.partImage && typeof item.partImage === 'object') || item.documentId,
      );

      await deletePartImages(partImagesToRemoved);

      await addUpdatePartImages(partImagesToAddUpdate);
      setImageUploading(false);
    },
    [deletePartImages, addUpdatePartImages],
  );

  return (
    <PartImageContext.Provider
      value={{
        uploadPartImages,
        partAdditionalImages,
        partDefaultImage,
        uploadingPartAdditionalImages,
        uploadingPartDefaultImage,
        uploadingProgress,
        imageUploading,
        setImageUploading,
      }}
    >
      {children}
    </PartImageContext.Provider>
  );
};

const usePartImageContext = () => {
  const context = React.useContext(PartImageContext);
  if (context === undefined) {
    throw new Error('usePartImageContext must be used within a PartImageContext');
  }
  return context;
};

export { PartImageContext, PartImageProvider, usePartImageContext };
