/* eslint-disable import/no-cycle */

import { useMemo } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';

import { useItemsIntegrationTableContext } from '../../../context/ItemsIntegrationTableContext';
import { IUseFieldValuesWatched, UseFieldValuesWatchedType } from './@types/fieldHookTypes';

const useFieldValuesWatched = ({
  fieldName,
  parentFieldName,
  childrenFieldNames,
}: IUseFieldValuesWatched): UseFieldValuesWatchedType => {
  const { control } = useFormContext();
  const { autoFilledFields } = useItemsIntegrationTableContext();
  const fieldWatched = useWatch({ control, name: fieldName });
  const parentWatched = useWatch({ control, name: parentFieldName });

  const childrenWatched = useWatch({ control, name: childrenFieldNames });

  const childrenValuesWatched = useMemo(
    () =>
      childrenWatched.reduce((acc: number, current: string, index: number) => {
        if (autoFilledFields.includes(childrenFieldNames[index])) return acc;
        return parseInt(current || '0', 10) + acc;
      }, 0),
    [childrenWatched, autoFilledFields, childrenFieldNames],
  );
  return { fieldWatched, parentWatched, childrenWatched, childrenValuesWatched };
};

export default useFieldValuesWatched;
