import React from 'react';

import { Button, Typography } from '@mui/material';

import { Modal } from 'components';

const WorkRequestUploadIncompleteModal = ({ openStatus, onClose, submit }) => (
  <Modal
    disableModalOutsideClick
    open={openStatus}
    onClose={onClose}
    title="Upload incomplete"
    titleContainerStyles={{ padding: '16px 0px 16px 16px' }}
    reducePadding
    fullWidth
    paperSx={{ width: '505px' }}
    footer={
      <>
        <NoButton onClose={onClose} />
        <YesButton onSubmit={submit} />
      </>
    }
  >
    <Typography sx={{ lineHeight: '24px', fontWeight: '400', fontSize: '14px' }}>
      Are you sure you want to navigate away from this page? <br /> Doing so will stop any files
      that have not finished uploading.
    </Typography>
  </Modal>
);

export default WorkRequestUploadIncompleteModal;

const NoButton = ({ onClose = () => {} }) => (
  <Button
    sx={(theme) => ({
      minWidth: 'auto',
      color: theme.palette.secondary.dark,
      fontWeight: '500',
    })}
    onClick={onClose}
  >
    No
  </Button>
);

const YesButton = ({ onSubmit = () => {} }) => (
  <Button
    size="small"
    variant="contained"
    sx={(theme) => ({
      background: theme.palette.secondary.main,
      color: theme.palette.common.white,
    })}
    onClick={onSubmit}
  >
    Yes
  </Button>
);
