import React, { useState, useEffect, useMemo } from 'react';

import { gql, useLazyQuery } from '@apollo/client';
import { Stack, Tab, Typography, Box } from '@mui/material';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
// import { useUser } from 'app/UserContext';
import { CenteredLoadSpinner, TabBar } from 'components';
import { projectById as PROJECT_BY_ID } from 'graphql/queries';

import AdminTab from './AdminTab/AdminTab';
// import useProjectUser from './hooks/useProjectUser';
import InfoForm from './InfoForm/InfoForm';
import Privacy from './PrivacyTab/PrivacyTab';

const getProjectId = (selectedItem) => (selectedItem?.type === 'PROJECT' ? selectedItem?.id : null);

const ProjectProperties = () => {
  // const { user } = useUser();
  const [activeTabIndex, setTabIndex] = useState(0);
  const { selectedItem } = useFacilitiesProjects();
  const projectId = getProjectId(selectedItem);
  // const userEmail = user.attributes.email;
  // const { isProjectAdministrator } = useProjectUser({ projectId, userEmail });
  const [fetchProject, { data, loading }] = useLazyQuery(gql(PROJECT_BY_ID), {
    fetchPolicy: 'cache-and-network',
  });

  const tabComponents = useMemo(
    () => [
      { label: 'Info', Component: InfoForm },
      { label: 'Admins', Component: AdminTab },
      { label: 'Privacy', Component: Privacy },
      // SHOP-2553 - hide preferences tab
      // ...(user.isAdmin || isProjectAdministrator ? [Preferences] : [])
    ],
    [],
  );

  useEffect(() => {
    if (!projectId) return;

    fetchProject({ variables: { params: { projectId } } });
  }, [fetchProject, projectId]);

  const project = data?.projectById;

  if (loading || !project) return <CenteredLoadSpinner sx={{ marginTop: '250px' }} />;

  return (
    <>
      <HeaderWrapperSpaceBetween>
        <Stack direction="row">
          <Title />
          <TabBar value={activeTabIndex} onChange={(e, newIndex) => setTabIndex(newIndex)}>
            {tabComponents.map(({ label }) => (
              <Tab label={label} key={label} />
            ))}
            {/* user.isAdmin || isProjectAdministrator ? <Tab label="Preferences" /> : null} AM-1440 */}
          </TabBar>
        </Stack>
      </HeaderWrapperSpaceBetween>
      <ActiveComponentWrapper>
        {tabComponents?.map(({ Component, label }, index) => (
          <Box sx={{ height: '100%' }} hidden={activeTabIndex !== index} key={label}>
            {project && <Component project={project} />}
          </Box>
        ))}
      </ActiveComponentWrapper>
    </>
  );
};

export default ProjectProperties;

const ActiveComponentWrapper = ({ children }) => <Box sx={{ height: 1 / 1, overflowY: 'auto' }}>{children}</Box>;

const HeaderWrapperSpaceBetween = ({ children }) => (
  <Stack direction="row" justifyContent="space-between" mb={2}>
    {children}
  </Stack>
);

const Title = () => (
  <Typography variant="h5" component="h1" mt={0.5}>
    Project Properties
  </Typography>
);
