import React from 'react';

import { Stack, Typography, Button } from '@mui/material';

import { Custom } from 'components/Icons';
import { shopColors, surfaceColors } from 'styles/theme/colors';

import { AddLinkButtonProps, AddLinkProps } from '../@types/tasksTypes';

const AddLink = ({ disabled = false, setIsAddLink, darkTheme }: AddLinkProps) => {
  const fillColor = () => {
    if (disabled) return surfaceColors[darkTheme ? 'darkSurface' : 'lightSurface'].disabled;
    if (darkTheme) return shopColors.mainDarkBackground;
    return shopColors.main;
  };

  return (
    <Stack direction="row" alignItems="center">
      <AddLinkButton
        onClick={() => setIsAddLink(true)}
        disabled={disabled}
        component="label"
        startIcon={<Custom.Link style={{ fill: fillColor() }} />}
      >
        <Typography ml={1} variant="body1" color={fillColor()}>
          Add Link
        </Typography>
      </AddLinkButton>
    </Stack>
  );
};

const AddLinkButton = (props: AddLinkButtonProps) => (
  <Button
    sx={{
      textTransform: 'none',
    }}
    {...props}
  />
);

export default AddLink;
