import React, { useState } from 'react';

import { useMutation, gql } from '@apollo/client';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';

import DepartmentUsersDeleteModal from 'components/DepartmentUsersDeleteModal';
import MenuItemWithSpinner from 'components/TableComponents/MoreOptionsDropdown/MenuItemWithSpinner';
import { resendDepartmentUserInvitation as RESEND_INVITE } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

const MoreTableRowOptions = ({
  departmentUserId,
  userStatus,
  onClose,
  hasShopAdminPermission = false,
}) => {
  const userIsPending = userStatus === 'Pending';
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { handleResponse } = useGraphqlResponseHandler(onClose);

  const [resendInvitation, { loading }] = useMutation(gql(RESEND_INVITE));

  const closeModal = () => {
    setShowDeleteModal(false);
    onClose();
  };

  const resendUserInvite = () => {
    handleResponse(resendInvitation, {
      variables: {
        params: { departmentUserId },
      },
    });
  };

  return (
    <Box>
      {userIsPending && (
        <MenuItemWithSpinner
          disabled={!hasShopAdminPermission}
          loading={loading}
          onClick={() => resendUserInvite()}
        >
          Resend Invite
        </MenuItemWithSpinner>
      )}
      <MenuItem disabled={!hasShopAdminPermission} onClick={() => setShowDeleteModal(true)}>
        Delete
      </MenuItem>{' '}
      <DepartmentUsersDeleteModal
        departmentUserIds={[departmentUserId]}
        open={showDeleteModal}
        onClose={closeModal}
      />
    </Box>
  );
};

export default MoreTableRowOptions;
