import React from 'react';

import { UseFormProps } from 'react-hook-form';

import CellComponent from './components/CellComponent/CellComponent';
import { PBS_FIELD } from './components/QuantityStep/components/ItemsIntegrationTable/constants';

export const STEPS = ['Work Request', 'Quantity'];

type WorkRequestRowType = {
  needBy: string;
  workRequestId: string | -1;
  workRequestIdentifier: string;
  workRequestName: string;
};

type WorkRequestCellType = {
  row: WorkRequestRowType;
};

export const COLUMNS = [
  {
    field: 'workRequestIdentifier',
    headerName: 'Id',
    flex: 1 / 4,
    renderCell: ({ row }: WorkRequestCellType) => (
      <CellComponent label={row.workRequestId === -1 ? '' : row.workRequestIdentifier} />
    ),
    sortable: false,
  },
  {
    field: 'workRequestName',
    headerName: 'Work Request Name',
    flex: 1 / 2,
    renderCell: ({ row: { workRequestName } }: WorkRequestCellType) => <CellComponent label={workRequestName} />,
    sortable: false,
  },
  {
    field: 'needBy',
    headerName: 'Need By',
    flex: 1 / 4,
    renderCell: ({ row: { needBy } }: WorkRequestCellType) => (
      <CellComponent label={needBy && new Date(needBy).toLocaleDateString('en-US')} />
    ),
    sortable: false,
  },
];

export const STATUS_LIST = ['Draft', 'Submitted', 'Pending', 'Approved', 'Rejected', 'Canceled'];

export const QTY_STEP_FORM_DEFAULT_VALUES = { [PBS_FIELD]: 'Both' };

export const USE_FORM_OPTS: UseFormProps<any, any> = {
  mode: 'all',
  defaultValues: QTY_STEP_FORM_DEFAULT_VALUES,
  shouldUnregister: false,
};
