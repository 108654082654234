import React from 'react';

import { Button, Stack } from '@mui/material';

import { Custom } from 'components/Icons';
import { RECORDING_STATES } from 'constants/globalConstants';
import { useAudioRecording } from 'hooks/AudioRecording/useAudioRecording';
import { errorColors, surfaceColors } from 'styles/theme/colors';

import { useTaskViewerContext } from '../TaskViewerContext';
import ConfirmCompleteModal from './ConfirmCompleteModal';
import PrimaryButton from './PrimaryButton';
import Timer from './Timer';

const AudioRecording = () => {
  const { taskName, taskStatus, loadingUpdateTask, loadingTask, taskError } = useTaskViewerContext();
  const {
    handleStart,
    handlePauseResume,
    handleComplete,
    isPaused,
    isActive,
    time,
    handleCancelModal,
    handleOpenModal,
    openModal,
    isCompleted,
  } = useAudioRecording();

  const isTaskOpen = ![RECORDING_STATES.Started, RECORDING_STATES.Paused].includes(taskStatus);

  const networkLoading = loadingUpdateTask || loadingTask;
  const disablePlayButton = isCompleted || !isPaused || networkLoading || taskError;
  const disablePauseButton = !isActive || isPaused || networkLoading || taskError;
  const disableCompleteButton = (!isActive && isTaskOpen) || isCompleted || networkLoading || taskError;

  return (
    <Stack direction="row" justifyContent="space-between" sx={{ padding: '16px 20px', height: 64 }}>
      <Stack direction="row" gap="8px">
        <PlayButton disabled={disablePlayButton} onClick={handleStart} green={isActive && isPaused} />
        <PauseButton disabled={disablePauseButton} onClick={handlePauseResume} />
        <Timer time={time} />
      </Stack>
      <CompleteButton disabled={disableCompleteButton} onClick={handleOpenModal} />
      <ConfirmCompleteModal
        open={openModal}
        confirm={handleComplete}
        handleCancel={handleCancelModal}
        taskName={taskName}
      />
    </Stack>
  );
};

export default AudioRecording;

const PlayButton = ({ green, ...props }) => (
  <PrimaryButton
    color="primary"
    sx={{ width: '40px' }}
    preventCustomHoverBg={green}
    data-testid="play-button"
    {...props}
  >
    <Custom.PlayArrow />
  </PrimaryButton>
);

const PauseButton = (props) => {
  const { disabled } = props;
  return (
    <Button
      color="error"
      variant="outlined"
      sx={{
        minWidth: 'unset',
        width: '40px',
        height: '32px',
        borderColor: disabled ? `${surfaceColors.lightSurface.disabled} !important` : '',
      }}
      data-testid="pause-button"
      {...props}
    >
      <Custom.PauseArrow
        style={{
          fill: disabled ? surfaceColors.lightSurface.disabled : errorColors.main,
          fillOpacity: 1,
        }}
      />
    </Button>
  );
};

const CompleteButton = (props) => (
  <PrimaryButton sx={{ width: '128px' }} data-testid="complete-button" {...props}>
    COMPLETE
  </PrimaryButton>
);
