import { SESSION_STORAGE_CONSTANTS } from 'constants/globalConstants';
import { VARIANT_SUCCESS } from 'constants/snackbarConstants';

import { ATTRIBUTE_TYPE } from '../Manufacturer/Attributes/AttributeContent/AttributeSelectHelper';

export const getSingleSelectAttributeVariants = async (variants, createAttributeVariant) => {
  const validateAttributeVariants = variants.filter(
    (item) => item.partAttributeTypeName === ATTRIBUTE_TYPE.singleSelect,
  );

  const result = await Promise.all(
    validateAttributeVariants.map(async (item) => {
      if (!item?.selectedVariants && !item.isNewVariant) {
        return {
          partAttributeId: item.partAttributeId,
          selectValueIds: [],
        };
      }
      let newVariant;
      if (item.isNewVariant) {
        const { text, code, partAttributeId } = item;
        const { data } = await createAttributeVariant({
          textValue: text,
          codeValue: code,
          partAttributeId,
        });
        newVariant = data?.addPartAttributeSelectVariant;
      }
      return {
        partAttributeId: item.partAttributeId,
        selectValueIds: [item?.selectedVariants?.id || newVariant?.partAttributeSelectVariantId].filter(Boolean),
      };
    }),
  );

  return result;
};

export const getMultiSelectAttributeVariants = async (variants, createAttributeVariant) => {
  const validMultiSelectAttributeVariants = variants.filter(
    (item) => item.partAttributeTypeName === ATTRIBUTE_TYPE.multiSelect,
  );

  const result = await Promise.all(
    validMultiSelectAttributeVariants.map(async (item) => {
      if (item?.selectedVariants.length === 0 && !item?.isNewVariant) {
        return {
          partAttributeId: item.partAttributeId,
          selectValueIds: [],
        };
      }
      let newVariant;
      if (item.isNewVariant) {
        const { text, code, partAttributeId } = item;
        const { data } = await createAttributeVariant({
          textValue: text,
          codeValue: code,
          partAttributeId,
        });
        newVariant = data?.addPartAttributeSelectVariant;
      }
      return {
        partAttributeId: item.partAttributeId,
        selectValueIds: [
          ...(item?.selectedVariants.map((variant) => variant.id) ?? []),
          newVariant?.partAttributeSelectVariantId,
        ].filter(Boolean),
      };
    }),
  );

  return result;
};

export const getAttributeVariants = async (variants, onAddNewAttributeVariant, isFiltered = false) => {
  const singleSelectAttributeVariants = await getSingleSelectAttributeVariants(variants, onAddNewAttributeVariant);

  const multiSelectAttributeVariants = await getMultiSelectAttributeVariants(variants, onAddNewAttributeVariant);

  const attributeVariants = [...singleSelectAttributeVariants, ...multiSelectAttributeVariants];

  if (isFiltered) {
    return attributeVariants.filter((item) => item.selectValueIds.length);
  }
  return attributeVariants;
};

export const getUpdatePartAttributeVariantData = async (variants, onAddNewAttributeVariant, selectedPart) => {
  const attributeVariants = await getAttributeVariants(variants, onAddNewAttributeVariant);

  const udpateAttributeVariantData = [];
  const addAttributeVariantData = [];
  const deleteAttributeVariantData = [];

  attributeVariants.forEach((attributeVariant) => {
    const { selectValueIds } = attributeVariant;

    const selectedPartAttributeVariant = selectedPart?.attriubuteVariantData?.find(
      (item) => item.partAttributeId === attributeVariant.partAttributeId,
    );

    if (!selectedPartAttributeVariant && selectValueIds.length) {
      addAttributeVariantData.push({
        partAttributeId: attributeVariant.partAttributeId,
        selectValueIds: attributeVariant.selectValueIds,
      });
    }

    if (selectedPartAttributeVariant && selectValueIds.length === 0) {
      deleteAttributeVariantData.push(selectedPartAttributeVariant?.partAttributeVariantDataId);
    }

    if (selectedPartAttributeVariant && selectValueIds.length) {
      const currentPartAttributeVariantSelectValueIds = selectedPartAttributeVariant?.selectValues
        ?.map((selectedPartVariant) => selectedPartVariant?.partAttributeSelectVariantId)
        .filter(Boolean);

      if (
        selectValueIds.length !== currentPartAttributeVariantSelectValueIds.length ||
        selectValueIds.some((id) => !currentPartAttributeVariantSelectValueIds.includes(id))
      ) {
        udpateAttributeVariantData.push({
          partAttributeVariantDataId: selectedPartAttributeVariant?.partAttributeVariantDataId,
          selectValueIds,
        });
      }
    }
  });

  return {
    udpateAttributeVariantData,
    addAttributeVariantData,
    deleteAttributeVariantData,
  };
};

const { PUBLISHED_PART_FROM_EDITOR } = SESSION_STORAGE_CONSTANTS;

export const handleAddPartResponse = async ({
  addPartResp,
  uploadPartImages,
  values,
  returnPartData,
  loadParts,
  category,
  onGetManufactured,
  setRecentlyAddedPart,
  setShowNewItem,
  methods,
  handleBatchResponse,
}) => {
  if (addPartResp?.data?.addPart) {
    const { data } = addPartResp;
    await uploadPartImages(data?.addPart?.partId, values);
    if (returnPartData) return data;
    await loadParts({
      partCategoryId: category?.partCategoryId,
      orderBy: 'dateadded:desc',
    });
    await onGetManufactured();
    sessionStorage.removeItem(PUBLISHED_PART_FROM_EDITOR);
    setRecentlyAddedPart(data?.addPart?.partId);
    setShowNewItem(false);
    methods.reset();
  }
  handleBatchResponse([addPartResp]);
  return null;
};

export const getBodyForUpdate = async (
  values,
  getUnitOfMeasureId,
  getManufacturerId,
  selectedPart,
  onAddNewAttributeVariant,
) => {
  const { udpateAttributeVariantData, addAttributeVariantData, deleteAttributeVariantData } =
    await getUpdatePartAttributeVariantData(
      values?.partAttributeVariantData || [],
      onAddNewAttributeVariant,
      selectedPart,
    );

  const body = {
    partId: selectedPart?.partId,
    partName: values?.partName?.trim(),
    description: values?.description?.trim(),
    unitOfMeasureId: await getUnitOfMeasureId(values),
    manufacturerId: await getManufacturerId(values),
  };

  if (udpateAttributeVariantData.length) body.updatePartAttributeVariantData = udpateAttributeVariantData;

  if (addAttributeVariantData.length) body.addPartAttributeVariantData = addAttributeVariantData;

  if (deleteAttributeVariantData.length) body.deletePartAttributeVariantData = deleteAttributeVariantData;

  return body;
};

export const updateSelectedPart = async ({
  values,
  getUnitOfMeasureId,
  getManufacturerId,
  selectedPart,
  onAddNewAttributeVariant,
  defaultValues,
  uploadPartImages,
  enqueueSnackbar,
  handleUpdate,
  handleResponse,
  updatePart,
  showSnackbar = true,
}) => {
  const body = await getBodyForUpdate(
    values,
    getUnitOfMeasureId,
    getManufacturerId,
    selectedPart,
    onAddNewAttributeVariant,
  );
  const formModified =
    selectedPart.isFormModified ||
    body?.partName?.trim() !== defaultValues?.partName?.trim() ||
    body?.description?.trim() !== defaultValues?.description?.trim() ||
    body.unitOfMeasureId !== defaultValues?.unitOfMeasureId.id ||
    body.manufacturerId !== defaultValues?.manufacturerId.id ||
    body.updatePartAttributeVariantData?.length > 0 ||
    body.addPartAttributeVariantData?.length > 0 ||
    body.deletePartAttributeVariantData?.length > 0;

  await uploadPartImages(defaultValues?.partId, values);
  if (!formModified) {
    if (showSnackbar) enqueueSnackbar('Part updated successfully', VARIANT_SUCCESS);
    await handleUpdate();
    return null;
  }
  await handleResponse(
    updatePart,
    {
      variables: {
        body,
      },
    },
    { successMessage: showSnackbar ? 'Part updated successfully' : '' },
    handleUpdate,
  );

  return null;
};

export const addNewPart = async (
  values,
  getUnitOfMeasureId,
  selectedCategory,
  addPart,
  setRecentlyAddedPart,
  reset,
  setShowNewItem,
  loadParts,
  onGetManufactured,
  getManufacturerId,
  onAddNewAttributeVariant,
  uploadPartImages,
  handleBatchResponse,
  returnPartData = false,
) => {
  const variants = await getAttributeVariants(values?.partAttributeVariantData || [], onAddNewAttributeVariant, true);

  const body = {
    manufacturerId: await getManufacturerId(values),
    partName: values?.partName?.trim(),
    description: values?.description?.trim(),
    unitOfMeasureId: await getUnitOfMeasureId(values),
    partCategoryId: selectedCategory?.partCategoryId,
  };

  if (variants.length) {
    body.partAttributeVariantData = variants;
  }
  const addPartResp = await addPart({ variables: { body } });

  if (addPartResp?.data?.addPart) {
    const { data } = addPartResp;
    await uploadPartImages(data?.addPart?.partId, values);
    if (returnPartData) return data;
    await loadParts({
      partCategoryId: selectedCategory?.partCategoryId,
      orderBy: 'dateadded:desc',
    });
    await onGetManufactured();
    sessionStorage.removeItem(PUBLISHED_PART_FROM_EDITOR);
    setRecentlyAddedPart(data?.addPart?.partId);
    setShowNewItem(false);
    reset();
  }

  handleBatchResponse([addPartResp]);
  return null;
};

export const addNewUom = async (addUom, user, values, fetchUoms) => {
  const unitOfMeasureId = (
    await addUom({
      variables: {
        body: {
          companyId: user?.companyId,
          unitOfMeasureCode: values?.unitOfMeasureCode?.trim(),
          unitOfMeasureName: values?.unitOfMeasureId?.trim(),
          unitOfMeasureTypeId: values?.unitOfMeasureTypeId,
        },
      },
    })
  ).data?.addUnitOfMeasure?.unitOfMeasureId;
  await fetchUoms();
  return unitOfMeasureId;
};

export const PART_ITEM_FORM_DEFAULT_VALUE = {
  partName: '',
  description: '',
  unitOfMeasureId: null,
  manufacturerId: null,
  unitOfMeasureCode: '',
  unitOfMeasureTypeId: '',
  partAttributeVariantData: [],
  partDefaultImage: null,
  partAdditionalImages: [],
};

export const getPartItemFormValue = ({ selectedPart = null, uoms = [], manufacturers = [] }) => {
  if (!selectedPart) {
    return PART_ITEM_FORM_DEFAULT_VALUE;
  }

  const { partName, description, unitOfMeasure, manufacturer, partId } = selectedPart;

  return {
    partId,
    partName,
    description,
    unitOfMeasureId: uoms?.find((uom) => uom.id === unitOfMeasure?.unitOfMeasureId),
    manufacturerId: manufacturers?.find((el) => el.id === manufacturer?.manufacturerId),
    unitOfMeasureCode: unitOfMeasure?.unitOfMeasureCode,
    unitOfMeasureTypeId: unitOfMeasure?.unitOfMeasureTypeId,
    partAttributeVariantData: [],
    partDefaultImage: null,
    partAdditionalImages: [],
  };
};
