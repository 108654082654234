import React, { memo } from 'react';

import { Avatar, Stack, Tooltip, Typography } from '@mui/material';

import { Custom } from 'components/Icons';
import { formateTime, getMonthDayYearFromUtcDate } from 'helpers/dateFunctions';

const TaskTypeEvent = memo(({ row, userData }) => (
  <tr data-testid="event-row">
    <td>
      <Stack sx={{ my: '5px' }}>
        <Tooltip
          title={userData.username}
          placement="top"
          arrow
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip': {
                padding: '0 5px',
              },
            },
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -5],
                },
              },
            ],
          }}
        >
          {userData.avatar ? (
            <Avatar
              alt="Profile"
              src={userData.avatar}
              sx={{
                justifyContent: 'start',
                width: '18px',
                height: '18px',
              }}
            />
          ) : (
            <Custom.AccountCircleIcon sx={{ color: 'greyColors.grey400', width: '18px', height: '18px' }} />
          )}
        </Tooltip>
      </Stack>
    </td>
    <TableData>{row.description}</TableData>
    <TableData>{getMonthDayYearFromUtcDate(row.timesStamp) || ''}</TableData>
    <TableData>{formateTime(row.timesStamp) || ''}</TableData>
  </tr>
));

export default TaskTypeEvent;

const TableData = ({ children }) => (
  <td>
    <Typography variant="body2">{children}</Typography>
  </td>
);
