import React from 'react';

import { Button, Stack } from '@mui/material';

import ComponentPaneChips from 'app/Layout/ReactGridLayout/ComponentPaneChips';

import { usePopoverFilterContext } from '../context/PopoverFilterContext';
import FilterChips from './FilterChips';

const CardFilterChipList = () => {
  const { displayFilters, removeFilterByDisplay, clearFilters } = usePopoverFilterContext();

  if (displayFilters.length === 0) return null;

  return (
    <ComponentPaneChips>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          backgroundColor: 'greyColors.grey100',
          p: 2,
          borderRadius: '5px',
          gap: '8px',
          flexWrap: 'wrap',
        }}
        data-cy="filter-card-chips"
      >
        <FilterChips filters={displayFilters} onDelete={removeFilterByDisplay} sxChip={{ marginTop: '0px' }} />

        <ClearAllButton onClick={clearFilters}>Clear all</ClearAllButton>
      </Stack>
    </ComponentPaneChips>
  );
};

export default CardFilterChipList;

const ClearAllButton = ({ children, onClick }) => (
  <Button
    variant="text"
    size="small"
    onClick={onClick}
    data-cy="clear-all-button"
    sx={{
      fontSize: '16px',
      lineHeight: '32px',
      letterSpacing: '0.15px',
      textAlign: 'right',
      color: 'secondary.main',
      textTransform: 'none',
    }}
  >
    {children}
  </Button>
);
