import React from 'react';

import { Stack, Typography } from '@mui/material';

import { Custom } from 'components/Icons';

const PartImageErrorMessage = ({ message = '' }) => (
  <Stack direction="row" alignItems="center" mt={2}>
    {message && <Custom.Error style={{ color: '#F44336' }} />}
    <Typography ml={1} variant="body2" sx={{ color: '#F44336' }}>
      {message}
    </Typography>
  </Stack>
);

export default PartImageErrorMessage;
