import React, { FC } from 'react';

import { ButtonProps } from '@mui/material';

import { CreateButton } from 'components';

const DoneButton: FC<ButtonProps> = ({ onClick = () => {}, disabled }: ButtonProps) => (
  <CreateButton buttonText="DONE" color="secondary" disabled={disabled} onClick={(e) => onClick(e)} />
);

export default DoneButton;
