import React, { useEffect } from 'react';

import { InputForesiteField } from '@evolvemep/foresite-components-ui-react';
import { useFormContext } from 'react-hook-form';

import useProjectIdentifier from './useProjectIdentifier';

const ProjectIdentifierForm = ({ disable }) => {
  const { control, watch, setError, clearErrors } = useFormContext();
  const { projectsByIdentifier, ruleSearchByIdentifier, projectsMultiSearch } = useProjectIdentifier(setError);

  const projectIdentifier = watch('projectIdentifier');

  useEffect(() => {
    if (!projectIdentifier) return;
    projectsByIdentifier(projectIdentifier);
  }, [projectsByIdentifier, projectIdentifier]);

  useEffect(() => {
    if (projectsMultiSearch?.length) {
      setError('projectIdentifier', { type: 'custom', message: 'Id already exists' });
      return;
    }
    clearErrors();
  }, [projectsMultiSearch?.length, setError, clearErrors]);

  return (
    <InputForesiteField
      name="projectIdentifier"
      label="Project Id*"
      control={control}
      color="secondary"
      size="small"
      sx={{
        marginBottom: '30px',
        width: '505px',
        '& .MuiFormHelperText-root': {
          position: 'absolute',
          bottom: '-23px',
          right: projectIdentifier?.length >= 30 ? '10px' : null,
        },
      }}
      rules={ruleSearchByIdentifier}
      disabled={disable}
    />
  );
};

export default ProjectIdentifierForm;
