import React, { useCallback, useMemo, useState } from 'react';

import { useExternalParameters } from '../useExternalParameters';
import {
  isValidRuleCallback,
  onAddNewSubRuleCallback,
  onChangeCallback,
  onRemoveRuleCallback,
} from './callbacks';
import { RULE_DEFAULT } from './constants';
import { Rule, RulesFilterProps } from './types';

export const RulesFilterContext = React.createContext<RulesFilterProps>(null);

export const RulesFilterProvider = ({ children }: { children: React.ReactNode }) => {
  const [query, setQuery] = useState<Rule[]>([RULE_DEFAULT]);
  const { parameters, parametersSchema } = useExternalParameters();

  const onAddNewRule = useCallback(() => setQuery((prev) => [...prev, RULE_DEFAULT]), []);

  const onAddNewSubRule = useCallback(
    (ruleIndex: number) => {
      const result = onAddNewSubRuleCallback(ruleIndex, query);
      setQuery(result);
    },
    [query],
  );

  const onRemoveRule = useCallback(
    (position: string) => {
      const result = onRemoveRuleCallback(position, query);
      setQuery(result);
    },
    [query],
  );

  const onClearQuery = useCallback(() => {
    setQuery([RULE_DEFAULT]);
  }, []);

  const onChange = useCallback(
    (position: string, name: string, value: string) => {
      const result = onChangeCallback(position, name, value, query);
      setQuery(result);
    },
    [query],
  );

  const isValidRule = useCallback((rule: Rule) => isValidRuleCallback(rule), []);

  const onlyActive = useMemo(
    () =>
      query.map((rule) => {
        const result = isValidRule(rule);
        if (result) return rule;
        return null;
      }),
    [query, isValidRule],
  );

  const encodedQuery = useMemo(() => {
    const result = query.filter(isValidRule);
    return encodeURIComponent(JSON.stringify(result));
  }, [query, isValidRule]);

  return (
    <RulesFilterContext.Provider
      value={{
        query,
        parameters,
        onAddNewRule,
        onRemoveRule,
        onAddNewSubRule,
        onClearQuery,
        onChange,
        encodedQuery,
        onlyActive,
        parametersSchema,
      }}
    >
      {children}
    </RulesFilterContext.Provider>
  );
};

export const useRulesFilter = () => {
  const context = React.useContext(RulesFilterContext);
  if (!context) throw new Error('useRulesFilter require RulesFilterProvider');
  return context;
};
