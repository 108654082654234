import { useCallback, useState } from 'react';

import { gql, useLazyQuery } from '@apollo/client';

import {
  partsCloudSearch as PARTS_COULD_SEARCH,
  partsCloudSearchCount as PARTS_CLOUD_SEARCH_COUNT,
} from 'graphql/queries';
import { DEFAULT_QUERY_PARAMS_BY_CATEGORY_PATH } from 'helpers/cloudSearchParams';
import { encodePartCategoryPathCloudSearchQuery } from 'helpers/stringFunctions';

const PARTS_CLOUD_SEARCH_LIMIT = 5;

const usePartsCloudSearchByCategoryAPI = (catalogId = '', limit = 0, noAssemblies = false) => {
  const [data, setData] = useState([]);

  const [fetchParts, { loading }] = useLazyQuery(gql(PARTS_COULD_SEARCH), {
    fetchPolicy: 'no-cache',
  });

  const [, { refetch }] = useLazyQuery(gql(PARTS_CLOUD_SEARCH_COUNT), {
    fetchPolicy: 'no-cache',
  });

  const searchParts = useCallback(
    async (categoryPath) => {
      const {
        data: { partsCloudSearch },
      } = await fetchParts({
        variables: {
          query: {
            query: encodePartCategoryPathCloudSearchQuery(categoryPath, catalogId),
            skip: 0,
            take: limit || PARTS_CLOUD_SEARCH_LIMIT,
            ...DEFAULT_QUERY_PARAMS_BY_CATEGORY_PATH,
          },
        },
      });
      setData(partsCloudSearch);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [catalogId, limit, noAssemblies],
  );

  const getPartsCount = useCallback(
    async (categoryPath) => {
      const {
        data: { partsCloudSearchCount },
      } = await refetch({
        query: {
          query: encodePartCategoryPathCloudSearchQuery(categoryPath, catalogId),
          ...DEFAULT_QUERY_PARAMS_BY_CATEGORY_PATH,
        },
      });

      const partItemsCount = partsCloudSearchCount ?? 0;
      return partItemsCount;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [catalogId, noAssemblies],
  );

  return { data, loading, searchParts, getPartsCount };
};

export default usePartsCloudSearchByCategoryAPI;
