import React from 'react';

import { Popover, PopoverProps } from '@mui/material';

import { ContentDetailProjects } from './ContentDetailProjects';
import { ContentDetailProjectsProps } from './types/ProjectDetailWorkOrderTypes';

export interface ProjectDetailPopupProps extends ContentDetailProjectsProps {
  anchorEl: HTMLDivElement | null;
  onClose: () => void;
  popoverProps?: PopoverProps['anchorOrigin'];
}

export const ProjectDetailPopup: React.FC<ProjectDetailPopupProps> = ({
  getProjectNameWithKey,
  anchorEl,
  onClose,
  projects,
  countWorkOrder,
  popoverProps,
  hiddenHeader,
  modeOnlyProjects,
  getProjectIdWithKey,
  projectsColors,
  day,
}) => {
  const open = Boolean(anchorEl) && !!Object.values(projects).length;

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
        ...popoverProps,
      }}
    >
      <ContentDetailProjects
        {...{
          countWorkOrder,
          day,
          projectsColors,
          getProjectIdWithKey,
          getProjectNameWithKey,
          projects,
          hiddenHeader,
          modeOnlyProjects,
        }}
      />
    </Popover>
  );
};
