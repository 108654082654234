import React from 'react';

import { Stack } from '@mui/material';
import { Draggable } from 'react-beautiful-dnd';
import ReactDOM from 'react-dom';

import { BACKGROUND_COLORS, FOREGROUND_COLORS } from 'components/KanbanBoard/constants';

import {
  KanbanCardContent,
  KanbanCardFooter,
  KanbanCardHeader,
  KanbanWorkOrderDate,
  KanbanWorkOrderId,
  KanbanWorkOrderName,
  KanbanWorkOrderRemaining,
} from './KanbanCardComponents';

const dndPortal = document.getElementById('dnd-portal');

const onDragStyles = {
  border: 'none',
  boxShadow: 8,
};

const disabledStyles = {
  opacity: 0.5,
};

const KanbanCard = ({
  color = 'white',
  border = 'gray',
  expired = false,
  children,
  disabled = false,
  draggableId,
  index,
  disableDragFunction = false,
  enablePortal = true,
}) => {
  const background = BACKGROUND_COLORS[color] ?? BACKGROUND_COLORS.blue;
  const foreground = FOREGROUND_COLORS[border] ?? FOREGROUND_COLORS.blue;

  return (
    <Draggable draggableId={draggableId} index={index} isDragDisabled={disableDragFunction}>
      {(provided, snapshot) => {
        const getStyles = () => {
          if (disabled) return disabledStyles;
          return snapshot.isDragging ? onDragStyles : {};
        };

        const MainContent = (
          <Stack
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            sx={{
              background,
              border: expired ? 'none' : '1px solid',
              borderColor: foreground,
              marginBottom: 1,
              borderRadius: '4px',
              opacity: disabled ? 0.5 : 1,
              overflow: 'hidden',
              ...getStyles(),
            }}
          >
            {children}
          </Stack>
        );

        if (snapshot.isDragging && enablePortal) {
          return ReactDOM.createPortal(MainContent, dndPortal);
        }

        return MainContent;
      }}
    </Draggable>
  );
};

KanbanCard.Header = KanbanCardHeader;
KanbanCard.Content = KanbanCardContent;
KanbanCard.Footer = KanbanCardFooter;
KanbanCard.OrderId = KanbanWorkOrderId;
KanbanCard.OrderName = KanbanWorkOrderName;
KanbanCard.OrderDate = KanbanWorkOrderDate;
KanbanCard.OrderRemaining = KanbanWorkOrderRemaining;

export default KanbanCard;
