import React from 'react';

import StackRow from 'components/StackRow';

import HeaderSearchAutocomplete from '../components/HeaderSearchAutocomplete/HeaderSearchAutocomplete';

const MobileSearch = ({ isOpen }) => (
  <SearchInputContainer style={{ display: isOpen ? 'block' : 'none' }}>
    <HeaderSearchAutocomplete />
  </SearchInputContainer>
);

export default MobileSearch;

const SearchInputContainer = (props) => (
  <StackRow
    sx={{
      backgroundColor: '#424242',
      justifyContent: 'end',
      transition: 'all 0.2s ease-in-out',
      overflow: 'hidden',
      py: 1.5,
      px: 2,
      alignItems: 'center',
      height: 64,
      position: 'absolute',
      top: 64,
      left: 0,
      width: '100%',
      zIndex: 10000,
      '& > div': {
        width: '100%',
        '& > div': {
          backgroundColor: '#303030',
          color: 'white',
        },
      },
    }}
    {...props}
  />
);
