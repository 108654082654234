import React, { useMemo } from 'react';

import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Popper from '@mui/material/Popper';
import Stack from '@mui/material/Stack';

import AssignModeSwitch from './AssignModeSwitch';
import BaseButton from './BaseButton';
import LocationWithAWorkPhaseChipsContainer from './LocationWithAWorkPhaseChipsContainer';

const LocationWithAWorkPhasePopper = ({
  open = false,
  anchorEl = null,
  onClickOption = () => {},
  onDone = () => {},
  onCancel = () => {},
  onSwitch = () => {},
  isAssignationModeDisabled,
  defaultChecked,
  data = [],
  currentItem,
}) => {
  const doneButtonProps = useMemo(() => {
    const disabled = !defaultChecked;
    if (disabled)
      return {
        disabled,
        sxButton: {
          width: 71,
          marginRight: 1,
          color: 'surface.lightSurface.disabled',
          backgroundColor: 'action.lightSurface.disabledBackground',
        },
      };
    return {
      disabled,
      sxTypography: {
        color: 'white',
        '&:hover': { color: 'secondary.main' },
      },
      sxButton: {
        width: 71,
        bgcolor: 'secondary.main',
        marginRight: 1,
        '&:hover': { bgcolor: 'secondary.light', color: 'secondary.main' },
      },
      enabledTypographyColor: 'secondary.contrastText',
    };
  }, [defaultChecked]);
  return (
    <Popper
      data-testid="LocationWithAWorkPhasePopper-data-testid"
      open={open}
      anchorEl={anchorEl}
      transition
      placement="bottom-end"
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps}>
          <Stack
            sx={{
              width: 688,
              height: 189,
              bgcolor: 'background.paper',
              boxShadow:
                '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
              borderRadius: 1,
            }}
          >
            <LocationWithAWorkPhaseChipsContainer
              currentItem={currentItem}
              data={data}
              onClickOption={onClickOption}
            />
            <Box
              sx={{
                height: 52,
                justifyContent: 'flex-end',
                display: 'flex',
                alignItems: 'center',
                borderTopWidth: 1,
                borderTopStyle: 'solid',
                borderTopColor: 'other.border',
              }}
            >
              <AssignModeSwitch
                defaultChecked={defaultChecked}
                onSwitch={onSwitch}
                isAssignationModeDisabled={isAssignationModeDisabled}
              />
              <BaseButton
                data-testid="LocationWithAWorkPhasePopper-Cancel-data-testid"
                onClick={onCancel}
                buttonText="CANCEL"
                sxButton={{ width: 71, marginRight: 1 }}
              />
              <BaseButton
                data-testid="LocationWithAWorkPhasePopper-Done-data-testid"
                onClick={onDone}
                buttonText="DONE"
                {...doneButtonProps}
              />
            </Box>
          </Stack>
        </Fade>
      )}
    </Popper>
  );
};

export default LocationWithAWorkPhasePopper;
