import React from 'react';

import { grey } from '@mui/material/colors';
import { Position } from 'react-flow-renderer';

import useCustomSmoothPath from 'hooks/useCustomSmoothPath/useCustomSmoothPath';

import { ILagTimesEdge } from './@types/lagTimesEdgeTypes';

export const TARGET_OFFSET_FIX = 25;

const LagTimesEdge: React.FC<ILagTimesEdge> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
}: ILagTimesEdge) => {
  const edgePath = useCustomSmoothPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    borderRadius: 10,
  });

  const markerOrientation = {
    [Position.Left]: 'right',
    [Position.Top]: 'down',
    [Position.Right]: '',
    [Position.Bottom]: '',
  };

  return (
    <>
      <defs>
        <marker
          id="react-flow_custom_arrowclosed_right"
          viewBox="0 0 15 5"
          refX="0"
          refY="2.5"
          markerUnits="strokeWidth"
          markerWidth="15"
          markerHeight="5"
          orient="0deg"
        >
          <path d="M 0 2.5 L 5 2.5" style={{ strokeWidth: '1px', stroke: 'rgb(117, 117, 117)' }} />
          <path d="M 5 0 L 10 2.5 L 5 5" fill={grey[600]} />
        </marker>
        <marker
          id="react-flow_custom_arrowclosed_down"
          viewBox="0 0 15 5"
          refX="0"
          refY="2.5"
          markerUnits="strokeWidth"
          markerWidth="15"
          markerHeight="5"
          orient="90deg"
        >
          <path d="M 0 2.5 L 5 2.5" style={{ strokeWidth: '1px', stroke: 'rgb(117, 117, 117)' }} />
          <path d="M 5 0 L 10 2.5 L 5 5" fill={grey[600]} />
        </marker>
      </defs>
      <path
        data-testid={`assemblyEdge-d=${id}`}
        id={id}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={`url(#react-flow_custom_arrowclosed_${markerOrientation[targetPosition]})`}
        style={{ strokeWidth: 2, stroke: grey[600] }}
      />
    </>
  );
};

export default LagTimesEdge;
