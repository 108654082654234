import { ROW_TYPES } from 'modules/Field/LocationsAndWorkPhases/constants/constants';

import { useLocationsAndWorkPhases } from '../../../contexts/LocationsAndWorkPhaseContext';
import { createOption } from './moreMenuHelpers';

export const useProjectOptions = (node) => {
  const {
    locations: { handleAddLocation },
  } = useLocationsAndWorkPhases();

  const getProjectOptions = () =>
    node.children?.length === 0
      ? [
          createOption('New zone', () => handleAddLocation(node, ROW_TYPES.ZONE)),
          createOption('New floor', () => handleAddLocation(node, ROW_TYPES.FLOOR)),
          createOption('New sublocation system', () =>
            handleAddLocation(node, ROW_TYPES.LOCATION_SYSTEM),
          ),
        ]
      : [
          createOption('New sublocation system', () =>
            handleAddLocation(node, ROW_TYPES.LOCATION_SYSTEM),
          ),
        ];

  return { getProjectOptions };
};
