// Copyright ©️ 2022 eVolve MEP, LLC
import React from 'react';

import { Button } from '@mui/material';

import { CancelButtonProps } from './@types/buttons';

const CancelButton = ({
  color,
  sx,
  buttonText = 'CANCEL',
  onClick,
  theme = 'primary',
  disabled,
}: CancelButtonProps) => (
  <Button
    sx={{ color, fontWeight: 500, letterSpacing: '0.4px', ...sx }}
    onClick={onClick}
    disabled={disabled}
    data-testid="cancel-button"
    color={theme}
  >
    {buttonText}
  </Button>
);

export default CancelButton;
