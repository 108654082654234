import React, { useState } from 'react';

import { useWorkOrderItemsContext } from './WorkOrderItemsContext';

const WorkOrderCancelModalContext = React.createContext();

const WorkOrderCancelModalProvider = ({ children }) => {
  const [tasksToBeSavedOnCancel, setTasksToBeSavedOnCancel] = useState(null);
  const [isTableFilled, setIsTableFilled] = useState(true);

  const { workOrder, inProgressTasks } = useWorkOrderItemsContext();

  const showCancelTable =
    workOrder?.workOrderStatusTypeName === 'In Progress' && inProgressTasks?.length > 0;

  const selectedStateObj = {
    tasksToBeSavedOnCancel,
    setTasksToBeSavedOnCancel,
    isTableFilled,
    setIsTableFilled,
    showCancelTable,
  };

  return (
    <WorkOrderCancelModalContext.Provider value={selectedStateObj}>
      {children}
    </WorkOrderCancelModalContext.Provider>
  );
};

const useWorkOrderCancelModalContext = () => {
  const context = React.useContext(WorkOrderCancelModalContext);

  if (context === undefined) {
    throw new Error(
      'useWorkOrderCancelModalContext must be used within a WorkOrderCancelModalContext',
    );
  }

  return context;
};

export {
  WorkOrderCancelModalContext,
  WorkOrderCancelModalProvider,
  useWorkOrderCancelModalContext,
};
