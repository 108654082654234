import { gql, useMutation } from '@apollo/client';

import { GENERIC_MUTATION_ERROR } from 'constants/globalConstants';
import { deleteTask as DELETE_TASK } from 'graphql/mutations';
import { workRequestItems as WORK_REQUEST_ITEMS } from 'graphql/queries';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

export const useDeleteTask = (workRequestId) => {
  const [deleteTask, { loading }] = useMutation(gql(DELETE_TASK), {
    awaitRefetchQueries: true,
    refetchQueries: [
      'TaskMultiSearch',
      {
        query: gql(WORK_REQUEST_ITEMS),
        variables: {
          query: {
            workRequestId,
          },
        },
      },
    ],
  });

  const { handleResponse } = useGraphqlResponseHandler();

  const onDeleteTask = async (taskId) =>
    handleResponse(
      deleteTask,
      { variables: { params: { taskId } } },
      {
        successMessage: `Task successfully deleted`,
        errorMessage: GENERIC_MUTATION_ERROR,
      },
    );

  return {
    onDeleteTask,
    loading,
  };
};
