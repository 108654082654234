export const baseColors = [
  '#5D74EE',
  '#FD825B',
  '#9B56D1',
  '#04AF72',
  '#BF8C0A',
  '#1993BA',
  '#FF7A00',
  '#D316C0',
  '#7962BA',
  '#A4674E',
  '#044189',
  '#FF4D00',
  '#87277D',
  '#055C4D',
  '#28178F',
  '#A47090',
  '#DC6E88',
  '#46C6AF',
  '#DB621E',
  '#8AA317',
];

export const colorsOpacity = {
  '#D316C0': '#e05bd2',
  '#A4674E': '#b68571',
  '#044189': '#4e79ac',
  '#FF4D00': '#ff7032',
  '#87277D': '#aa67a3',
  '#055C4D': '#4f8c81',
  '#28178F': '#675bb0',
};

export const specialColors = { moreThanOne: '#F3BB4D' };

export const changeColorOpacity = (color: string) =>
  color in colorsOpacity ? colorsOpacity[color as keyof typeof colorsOpacity] : color;

export const assignedColorToProject = (projects: string[]) => {
  const keyColor = 'colors';
  const mapping = projects.reduce((map, project) => {
    const availableColors = map.get(keyColor) as string[];
    const palette = availableColors.length ? availableColors : baseColors;

    const randomIndex = Math.floor(Math.random() * palette.length);
    const color = palette[randomIndex];

    map.set(project, color);
    map.set(
      keyColor,
      palette.filter((colorInPalette) => colorInPalette !== color),
    );

    return map;
  }, new Map<string, string | string[]>([[keyColor, baseColors]]));
  mapping.delete(keyColor);
  return mapping;
};
