import React, { useState } from 'react';

import DeleteModal from 'components/DeleteModal';
import usePartsCloudSearchCache from 'hooks/usePartsCloudSearchCache';

import { useAttributeContext } from '../../AttributesSection/AttributesContext';
import { useCatalogSelectedPartContext } from '../../Providers/CatalogSelectedPartProvider';
import { usePartAPIContext } from '../../Providers/PartAPIProvider';
import CategoryDeleteModal from './CategoryModalDelete';
import { useDeletePartCategory } from './useDeletePartCategory';

const CategoryDelete = ({
  openModal,
  setOpenModal,
  partCategory,
  partCategoryId,
  partCategoryName,
  refetchCategories,
  refetchParentCategory,
  hasAttributesOrItems,
}) => {
  const [loading, setLoading] = useState(false);
  const { onDeletePartCategory } = useDeletePartCategory();
  const { getAttributesLoading, resetAttributesState } = useAttributeContext();
  const { loading: loadingPartItems } = usePartAPIContext();
  const { setSelectedPart } = useCatalogSelectedPartContext();

  const { deletePartsCloudSearchCache } = usePartsCloudSearchCache();

  const successPartCategoryDeleted = async () => {
    resetAttributesState();
    refetchParentCategory();
    refetchCategories();
    setSelectedPart(null);
  };

  const submit = async () => {
    setLoading(true);
    await onDeletePartCategory(partCategoryId, partCategoryName, successPartCategoryDeleted);
    setLoading(false);
    setOpenModal(false);

    // @see https://team-1620852251850.atlassian.net/browse/MAT2-1520
    // we have to remove partsCloudSearch cache here
    await deletePartsCloudSearchCache(partCategory);
  };

  const openModalDelete = hasAttributesOrItems
    ? openModal
    : openModal && !getAttributesLoading && !loadingPartItems;

  if (!hasAttributesOrItems) {
    return (
      <DeleteModal
        open={openModalDelete}
        onClose={() => setOpenModal(false)}
        onSubmit={submit}
        itemName={partCategoryName}
        loading={loading}
        deleteButtonText="Delete"
      />
    );
  }

  return (
    <CategoryDeleteModal
      open={openModalDelete}
      onClose={() => setOpenModal(false)}
      onSubmit={submit}
      itemName={partCategoryName}
      loading={loading}
    />
  );
};

export default CategoryDelete;
