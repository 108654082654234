/* eslint-disable */
const checkType = (item, menuValue) => {
  if (item?.__typename === 'LocationPhase' && menuValue === 'Locations with Work Phases')
    return 'location with work phase';
  if (item?.__typename === 'LocationPhase') return 'Work Phase';
  if (item?.__typename === 'WorkPhase') return 'Work Phase';
  if (item?.__typename === 'ScopePackage') return 'Scope Package';
  if (item?.type === 'PROJECT' || item?.locationTypeName === 'Project') return 'Project';
  if (item?.type === 'ScopePackage') return 'Scope Package';
  if (item?.type === 'WorkPhase') return 'Work Phase';
  if (item?.locationTypeName === 'Floor') return 'Floor';
  if (item?.locationTypeName === 'Zone') return 'Zone';
  if (item?.locationTypeName === 'Location System') return 'Location System';
  return '';
};

export default checkType;
