import { QueryHookOptions, gql, useQuery } from '@apollo/client';

import { manufactured as MANUFACTURERS } from 'graphql/queries';
import { Manufactured, ManufacturedQueryVariables } from 'graphql/types';

export const useQueryManufacturedList = (
  options: QueryHookOptions<{ manufactured: Array<Manufactured> }, ManufacturedQueryVariables>,
) => {
  const result = useQuery<{ manufactured: Array<Manufactured> }, ManufacturedQueryVariables>(
    gql(MANUFACTURERS),
    options,
  );

  return { ...result };
};
