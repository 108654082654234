import React, { ReactElement } from 'react';

import { Stack, SxProps } from '@mui/material';

import { Custom } from 'components/Icons';
import { greyColors } from 'styles/theme/colors';

type PartItemImageProps = {
  sx?: SxProps;
  image?: { imageUrl?: string };
  icon?: ReactElement;
};

const PartItemImage = ({ sx, image, icon }: PartItemImageProps) => {
  const renderImage = () => {
    if (icon) return icon;

    if (image && image?.imageUrl) {
      return (
        <img
          src={image?.imageUrl}
          width="80"
          height="80"
          alt="PartImage"
          style={{ borderRadius: '4px', objectFit: 'contain', padding: '1px' }}
        />
      );
    }

    return <Custom.PartThumbnail width={38} height={38} fill={greyColors.grey400} />;
  };

  return (
    <Stack
      sx={{
        height: 80,
        minWidth: 80,
        backgroundColor: '#EEEEEE',
        border: '1px solid #BDBDBD',
        borderRadius: '4px',
        justifyContent: 'center',
        alignItems: 'center',
        ...sx,
      }}
    >
      {renderImage()}
    </Stack>
  );
};

export default PartItemImage;
