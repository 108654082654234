import React from 'react';

import { GridRow } from '@mui/x-data-grid-pro';

import EditRow from './EditRow';

const TakeOffCustomRow = ({ params, isAddingRow, onClickAwayListener = () => {} }) => {
  if (isAddingRow && !params.rowId) return <EditRow params={params} onClickAwayListener={onClickAwayListener} />;

  return <GridRow {...params} />;
};

export default React.memo(TakeOffCustomRow);
