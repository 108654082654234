import React, { memo, useMemo, useState } from 'react';

import { WRITE_IN_ASSEMBLY } from 'modules/Field/WorkRequests/WorkRequest/constants';

import { useUOMOptions } from '../hooks/useUOMOptions';

const TableRowContext = React.createContext();

const TableRowProvider = memo(({ children, item, renderLastRow = () => {} }) => {
  const { uomOptions } = useUOMOptions();
  const uomDefaultValue = uomOptions?.filter((uom) => uom.label === 'asm')[0];

  const [showForm, setShowForm] = useState(false);
  const [fieldFocus, setFieldFocus] = useState(null);

  const isWriteInItem = WRITE_IN_ASSEMBLY === item?.workOrderItemTypeId;
  const isAddItemRow = item.workOrderItemId === 0;

  const memoizedValue = useMemo(
    () => ({
      item,
      isWriteInItem,
      showForm,
      setShowForm,
      isAddItemRow,
      fieldFocus,
      setFieldFocus,
      uomOptions,
      uomDefaultValue,
    }),
    [item, showForm, fieldFocus, isWriteInItem, isAddItemRow, uomOptions, uomDefaultValue],
  );

  return (
    <TableRowContext.Provider value={memoizedValue}>
      {children}
      {renderLastRow && renderLastRow()}
    </TableRowContext.Provider>
  );
});

const useTableRowContext = () => {
  const context = React.useContext(TableRowContext);

  if (context === undefined) {
    throw new Error('useTableRowContext must be used within a TableRowContext');
  }

  return context;
};

export { TableRowContext, TableRowProvider, useTableRowContext };
