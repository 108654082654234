import { Box } from '@mui/material';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';

import { useWorkRequestsContext } from '../WorkRequestsContext';
import SelectProjectMessage from './SelectProjectMessage';

const DefaultMessagesWorkRequestsList = () => {
  const { selectedItem } = useFacilitiesProjects();
  const { activeTabIndex } = useWorkRequestsContext();

  const tabStateMessage = activeTabIndex ? 'closed' : 'open';
  const selectedItemMessage = selectedItem?.type?.toLocaleLowerCase();
  const showDescription = !activeTabIndex && selectedItem?.type === 'PROJECT';

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        marginTop: '144px',
      }}
    >
      <SelectProjectMessage
        message={`This ${selectedItemMessage} has no ${tabStateMessage} work requests.`}
        description={showDescription && 'Start by creating a new work request.'}
      />
    </Box>
  );
};

export default DefaultMessagesWorkRequestsList;
