/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addedProject = /* GraphQL */ `
  subscription AddedProject {
    addedProject {
      projectId
      projectName
      projectIdentifier
      dataAnalytics
      units
      companyId
      address {
        addressId
        addressLine1
        addressLine2
        city
        state
        country
        zip
      }
      projectImageId
      disallowAutomaticDistributionQty
      projectType {
        projectTypeId
        projectTypeName
        companyId
      }
    }
  }
`;
export const addedTask = /* GraphQL */ `
  subscription AddedTask {
    addedTask {
      taskId
      taskTypeId
      billOfProcessId
      workRequestItemId
      taskStatusTypeId
      taskPredecessorIds {
        taskId
        taskName
        taskStatusTypeId
      }
      taskPredecessorDeletedIds
      taskTypeIds
      taskName
      taskDescription
      taskStatusTypeName
      sortOrder
      isEnabled
      createdOn
      time {
        totalTime
        events {
          taskStatusTypeId
          description
          timesStamp
          userId
        }
      }
      hasDocuments
      hasDrawingRegisterDocuments
      hasCommonDocuments
      hasExternalDocuments
      quantity
      needBy
    }
  }
`;
export const addedMessage = /* GraphQL */ `
  subscription AddedMessage {
    addedMessage {
      messageId
      companyId
      messagePriorityId
      fromName
      subject
      body
      messagePriorityCode
      statusId
      statusName
      accessedOn
      createdOn
    }
  }
`;
export const addedWorkRequest = /* GraphQL */ `
  subscription AddedWorkRequest {
    addedWorkRequest {
      workRequestId
      workRequestStatusId
      workRequestStatusName
      facilityId
      projectId
      projectIdentifier
      projectName
      workRequestIdentifier
      workRequestName
      needBy
      workRequestDescription
      facilityName
      workRequestStatusDescription
      lastStatusUpdate
      numberOfAttachments
    }
  }
`;
export const assignedEntitlement = /* GraphQL */ `
  subscription AssignedEntitlement {
    assignedEntitlement {
      entitlementId
      userId
      isCheckedOut
      checkedOutEndDate
      secureData
      secureSignature
      licenseTypeCode
      productPool {
        productPoolId
        poolQuantity
        productId
        bundleId
        type
        label
        poolAvailableQuantity
        renewalDate
        customData
        termDate
        startDate
        trialRenewalDate
        company {
          companyId
          companyName
          renewalDate
          mfA_Required
          data_Analytics
          companyDownloadPaths {
            document
            updateBy
            updatedOn
          }
          companyImageId
          address {
            addressId
            addressLine1
            addressLine2
            city
            state
            country
            zip
          }
          productPool {
            productPoolId
            poolQuantity
            productId
            bundleId
            type
            label
            poolAvailableQuantity
            renewalDate
            customData
            termDate
            startDate
            trialRenewalDate
            company {
              companyId
              companyName
              renewalDate
              mfA_Required
              data_Analytics
              companyDownloadPaths {
                document
                updateBy
                updatedOn
              }
              companyImageId
              address {
                addressId
                addressLine1
                addressLine2
                city
                state
                country
                zip
              }
              productPool {
                productPoolId
                poolQuantity
                productId
                bundleId
                type
                label
                poolAvailableQuantity
                renewalDate
                customData
                termDate
                startDate
                trialRenewalDate
              }
            }
            product {
              productId
              productName
              baseProductName
              isForesiteModule
              licenseTypeId
              licenseTypeName
              licenseClassId
              licenseClassName
              productNameFormatted
              onlyVisibleToMembers
              supportsTrial
              productLicenseTypes {
                productLicenseTypeId
                productId
                licenseTypeId
              }
            }
          }
        }
        product {
          productId
          productName
          baseProductName
          isForesiteModule
          licenseTypeId
          licenseTypeName
          licenseClassId
          licenseClassName
          productNameFormatted
          onlyVisibleToMembers
          supportsTrial
          productLicenseTypes {
            productLicenseTypeId
            productId
            licenseTypeId
            licenseType {
              licenseTypeId
              licenseTypeName
              licenseTypeCode
              sortOrder
            }
          }
        }
      }
    }
  }
`;
export const unassignedEntitlement = /* GraphQL */ `
  subscription UnassignedEntitlement {
    unassignedEntitlement {
      entitlementId
      userId
      isCheckedOut
      checkedOutEndDate
      secureData
      secureSignature
      licenseTypeCode
      productPool {
        productPoolId
        poolQuantity
        productId
        bundleId
        type
        label
        poolAvailableQuantity
        renewalDate
        customData
        termDate
        startDate
        trialRenewalDate
        company {
          companyId
          companyName
          renewalDate
          mfA_Required
          data_Analytics
          companyDownloadPaths {
            document
            updateBy
            updatedOn
          }
          companyImageId
          address {
            addressId
            addressLine1
            addressLine2
            city
            state
            country
            zip
          }
          productPool {
            productPoolId
            poolQuantity
            productId
            bundleId
            type
            label
            poolAvailableQuantity
            renewalDate
            customData
            termDate
            startDate
            trialRenewalDate
            company {
              companyId
              companyName
              renewalDate
              mfA_Required
              data_Analytics
              companyDownloadPaths {
                document
                updateBy
                updatedOn
              }
              companyImageId
              address {
                addressId
                addressLine1
                addressLine2
                city
                state
                country
                zip
              }
              productPool {
                productPoolId
                poolQuantity
                productId
                bundleId
                type
                label
                poolAvailableQuantity
                renewalDate
                customData
                termDate
                startDate
                trialRenewalDate
              }
            }
            product {
              productId
              productName
              baseProductName
              isForesiteModule
              licenseTypeId
              licenseTypeName
              licenseClassId
              licenseClassName
              productNameFormatted
              onlyVisibleToMembers
              supportsTrial
              productLicenseTypes {
                productLicenseTypeId
                productId
                licenseTypeId
              }
            }
          }
        }
        product {
          productId
          productName
          baseProductName
          isForesiteModule
          licenseTypeId
          licenseTypeName
          licenseClassId
          licenseClassName
          productNameFormatted
          onlyVisibleToMembers
          supportsTrial
          productLicenseTypes {
            productLicenseTypeId
            productId
            licenseTypeId
            licenseType {
              licenseTypeId
              licenseTypeName
              licenseTypeCode
              sortOrder
            }
          }
        }
      }
    }
  }
`;
export const updatedTask = /* GraphQL */ `
  subscription UpdatedTask {
    updatedTask {
      taskId
      taskTypeId
      billOfProcessId
      workRequestItemId
      taskStatusTypeId
      taskPredecessorIds {
        taskId
        taskName
        taskStatusTypeId
      }
      taskPredecessorDeletedIds
      taskTypeIds
      taskName
      taskDescription
      taskStatusTypeName
      sortOrder
      isEnabled
      createdOn
      time {
        totalTime
        events {
          taskStatusTypeId
          description
          timesStamp
          userId
        }
      }
      hasDocuments
      hasDrawingRegisterDocuments
      hasCommonDocuments
      hasExternalDocuments
      quantity
      needBy
    }
  }
`;
export const updatedWorkRequest = /* GraphQL */ `
  subscription UpdatedWorkRequest {
    updatedWorkRequest {
      workRequestId
      workRequestStatusId
      workRequestStatusName
      facilityId
      projectId
      projectIdentifier
      projectName
      workRequestIdentifier
      workRequestName
      needBy
      workRequestDescription
      facilityName
      workRequestStatusDescription
      lastStatusUpdate
      numberOfAttachments
    }
  }
`;
export const updatedProject = /* GraphQL */ `
  subscription UpdatedProject {
    updatedProject {
      projectId
      projectName
      projectIdentifier
      dataAnalytics
      units
      companyId
      address {
        addressId
        addressLine1
        addressLine2
        city
        state
        country
        zip
      }
      projectImageId
      disallowAutomaticDistributionQty
      projectType {
        projectTypeId
        projectTypeName
        companyId
      }
    }
  }
`;
export const updatedWorkRequestStatus = /* GraphQL */ `
  subscription UpdatedWorkRequestStatus {
    updatedWorkRequestStatus {
      workRequestStatusId
      workRequestStatusTypeId
      workRequestStatusTypeName
      workRequestStatusTypeDescription
    }
  }
`;
export const updatedWorkOrder = /* GraphQL */ `
  subscription UpdatedWorkOrder($workOrderId: ID!) {
    updatedWorkOrder(workOrderId: $workOrderId) {
      workOrderId
      workRequestId
      workOrderStatusTypeId
      workOrderStatusTypeName
      workOrderName
      workOrderDescription
      workOrderNumber
      workRequest {
        workRequestId
        workRequestStatusId
        workRequestStatusName
        facilityId
        projectId
        projectIdentifier
        projectName
        workRequestIdentifier
        workRequestName
        needBy
        workRequestDescription
        facilityName
        workRequestStatusDescription
        lastStatusUpdate
        numberOfAttachments
      }
      sortOrder
      createdBy
      lastStatusUpdate
      createdOn
      isStepFunctionProcessing
      stepFunctionProcessingMessage
      numberOfAttachments
      hasCustomWorkOrderName
    }
  }
`;
