import React, { ReactNode } from 'react';

import { CircularProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

import { CancelButton, CreateButton } from 'components';
import StackRow from 'components/StackRow';

import { useModal } from '../contexts/ModalContext';
import { useProjectRow } from '../contexts/ProjectRowContext';
import useCoordinates from '../hooks/useCoordinates';
import useSubmitForm from '../hooks/useSubmitForm';
import { getArrowStyles, getDialogStyles } from '../LocationsTable/constants/constants';

const QuantityDialog = ({ onClose, ...props }: { open: boolean; onClose: () => void; children: ReactNode }) => {
  const { lineItem } = useModal();
  const { coordinates, arrowPosition, arrowTop, arrowRight } = useCoordinates(lineItem);

  if (!coordinates) return null;

  const onCloseBackdropCloseDisabled = (e: any, reason: string) => {
    if (reason !== 'backdropClick') {
      onClose();
    }
  };

  const stopPropagationForTab = (event: any) => {
    if (event.key === 'Tab') event.stopPropagation();
  };

  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onKeyDown={stopPropagationForTab}
      disableEscapeKeyDown
      onClose={onCloseBackdropCloseDisabled}
      PaperProps={getDialogStyles(arrowPosition, arrowRight, coordinates)}
      sx={getArrowStyles(arrowPosition, arrowTop, arrowRight)}
      {...props}
    />
  );
};

export default QuantityDialog;

export const ModalFooter = ({ onClose }: { onClose: () => void }) => {
  const { formDirty, footerMessage, checkIfIsSuccessMessage } = useModal();
  const { loadingPatchBulk2LBS, projectVisualTotalQty } = useProjectRow();

  const { handleSubmit } = useSubmitForm();

  const isSuccessMessage = checkIfIsSuccessMessage();

  const onSubmit = () => handleSubmit(onClose);

  const updateLoading = loadingPatchBulk2LBS;
  const disableSubmitButton = !formDirty || updateLoading || projectVisualTotalQty > 999999;

  return (
    <DialogActions sx={{ justifyContent: 'space-between' }}>
      <Typography variant="body2" sx={{ color: isSuccessMessage ? 'success.dark' : 'error.main' }}>
        {footerMessage}
      </Typography>
      <StackRow>
        {updateLoading && <CircularProgress size={28} color="secondary" sx={{ mr: 2 }} />}
        <CancelButton onClick={onClose} theme="secondary" disabled={updateLoading} />
        <CreateButton onClick={onSubmit} buttonText="SAVE" disabled={disableSubmitButton} />
      </StackRow>
    </DialogActions>
  );
};

export const ModalTitle = ({ lineItemName }: { lineItemName: string }) => (
  <DialogTitle id="alert-dialog-title" sx={{ p: 2, pb: 1 }}>
    <Typography variant="body1" sx={{ fontWeight: 700 }}>
      Edit Quantity
    </Typography>
    <Typography
      variant="body2"
      sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '100%' }}
    >
      {lineItemName}
    </Typography>
  </DialogTitle>
);
