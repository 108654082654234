import React, { memo } from 'react';

import { Box } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { customMaxLengthRule, positiveAndIntegerNumberRules, requiredRule } from 'constants/inputFieldRules';
import WorkRequestControlInput from 'modules/Field/WorkRequests/WorkRequest/WorkRequestControlInput';

import { useTableRowContext } from '../context/TableRowContext';
import { useTableRowFormContext } from '../context/TableRowFormContext';
import { useWorkOrderItemsContext } from '../context/WorkOrderItemsContext';

const QuantityCell = memo(() => {
  const { item, showForm, setShowForm, setFieldFocus, fieldFocus, isAddItemRow } = useTableRowContext();
  const { canEditQuantity } = useWorkOrderItemsContext();

  const handleItemQuantityClick = () => {
    if (canEditQuantity && !showForm) {
      setShowForm(true);
      setFieldFocus('quantity');
    }
  };

  return (
    <Box
      sx={{
        height: '100%',
        pt: showForm ? '4px' : '6px',
        width: '-webkit-fill-available',
        cursor: !showForm && canEditQuantity && 'pointer',
      }}
      onClick={handleItemQuantityClick}
      onKeyDown={handleItemQuantityClick}
    >
      {showForm ? (
        <QuantityInput autoFocus={!isAddItemRow && fieldFocus === 'quantity'} />
      ) : (
        String(item?.quantity) || ''
      )}
    </Box>
  );
});

export default QuantityCell;

const QuantityInput = ({ autoFocus }) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const { item, isAddItemRow } = useTableRowContext();
  const { onKeyDown } = useTableRowFormContext();
  const { canEditQuantity } = useWorkOrderItemsContext();

  const inputValue = watch('quantity') || '';
  const quantityValidation = inputValue?.length === 6;

  return (
    <WorkRequestControlInput
      control={control}
      name="quantity"
      placeholder="Qty"
      rules={{
        ...requiredRule('Qty', '*Required'),
        ...customMaxLengthRule(6, '6/6'),
        ...positiveAndIntegerNumberRules('Qty'),
      }}
      helperText={quantityValidation ? '6/6' : null}
      error={!!errors.quantity || quantityValidation}
      defaultValue={!isAddItemRow ? item.quantity : null}
      onKeyDown={onKeyDown}
      type="number"
      inputProps={{
        'data-cy': 'work-request-item-quantity',
      }}
      disabled={!canEditQuantity}
      autoFocus={autoFocus}
    />
  );
};
