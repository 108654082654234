import { gql, useMutation } from '@apollo/client';

import { getDocumentPresignedURL as GET_DOCUMENT_PRESIGNED_URL } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

const useDownloadPaths = () => {
  const { handleResponse } = useGraphqlResponseHandler();
  const [getDocumentPresignedURLMutation, { loading, data }] = useMutation(
    gql(GET_DOCUMENT_PRESIGNED_URL),
  );

  const downloadPath = async (url) => {
    await handleResponse(
      getDocumentPresignedURLMutation,
      {
        variables: {
          body: {
            expirationHours: 24,
            objectKey: url,
          },
        },
      },
      {
        successMessage: 'Download url successfully',
      },
      (response) => {
        // Create blob link to download
        const {
          getDocumentPresignedURL: { preSignedURL, objectKey },
        } = response;

        const link = document.createElement('a');
        link.href = preSignedURL;

        const nameFile = objectKey.split('/').find((el) => el.includes('.zip'));
        link.setAttribute('download', nameFile);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      },
    );
  };

  return { loading, downloadPath, data };
};

export default useDownloadPaths;
