import React from 'react';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { ThemedCheckBox } from 'components';
import ActionsDropdown from 'components/TableComponents/ActionsDropdown/ActionsDropdown';
import Search from 'components/TableComponents/Search';

import { useAdminContext } from './AdminContext';

const AdminHeaderBar = ({ handleSearch, openInviteModal, handleShowDeletedUsers }) => {
  const { selected } = useAdminContext();

  return (
    <Grid container justifyContent="space-between" wrap="nowrap" sx={{ padding: '0.5em' }}>
      <Grid container item alignItems="center" wrap="nowrap">
        <ItemsSelected component="p">{selected.length} Items Selected</ItemsSelected>
        <ActionsDropdown />
      </Grid>
      <Grid container item alignItems="center" wrap="nowrap" justifyContent="flex-end">
        <ThemedCheckBox onChange={(e) => handleShowDeletedUsers(e.target.checked)} />
        <Typography component="p" noWrap>
          Show Deleted Users
        </Typography>
        <Search handleSearch={handleSearch} placeholder="Search…" />
        <InviteButton onClick={openInviteModal}>+ Add Admin</InviteButton>
      </Grid>
    </Grid>
  );
};

export default AdminHeaderBar;

const ItemsSelected = ({ component, children }) => (
  <Typography component={component} sx={{ color: 'rgba(0, 0, 0, 0.5)' }}>
    {children}
  </Typography>
);

const InviteButton = ({ onClick, children }) => (
  <Button
    onClick={onClick}
    variant="contained"
    color="secondary"
    sx={{
      minWidth: 150,
      maxHeight: 32,
    }}
  >
    {children}
  </Button>
);
