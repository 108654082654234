import React, { useMemo } from 'react';

import { CircularProgress } from '@mui/material';

import { Breadcrumbs } from 'components/NavigationComponents';

const DrawingFolderBreadcrumb = ({
  folders = [],
  onSelect = () => {},
  loading,
  inForgeView,
  selectedItem,
  activeItem,
}) => {
  const parentFolder = useMemo(
    () =>
      selectedItem.type === 'FACILITY'
        ? {
            label: activeItem.name ?? activeItem.label,
            folderId: folders[0] ? folders[0].parentFolderId : null,
          }
        : null,
    [selectedItem, activeItem, folders],
  );

  if (loading) return <CircularProgress size={20} />;

  const breadcrumbs = [
    {
      label: 'Drawing Register',
      onClick: () => onSelect(null),
    },
    ...(parentFolder
      ? [
          {
            label: parentFolder.label,
            onClick: () => (parentFolder ? onSelect({ folderId: parentFolder.folderId }) : onSelect(null)),
          },
        ]
      : []),
    ...folders.map((folder) => ({
      label: folder.folderName,
      onClick: () => (folder.folderId ? onSelect(folder) : onSelect(null)),
    })),
    ...(inForgeView ? [{ label: 'Viewer' }] : []),
  ];

  return (
    <Breadcrumbs
      breadcrumbs={breadcrumbs}
      aria-label="drawing-folders-breadcrumb"
      maxItems={2}
      collapseMode="menu"
      lastLabelSx={{
        fontSize: '16px',
      }}
      itemsBeforeCollapse={selectedItem.type === 'FACILITY' ? 2 : 1}
    />
  );
};

export default DrawingFolderBreadcrumb;
