import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import { BADGE_MAPPING_STATUS } from 'constants/badgeMappingStatus';
import { isNil } from 'helpers/isNotNil';
import { EIconType, getNavIcon } from 'helpers/MenuIcons';
import CollapseChildrenIcon from 'modules/Field/LocationsAndWorkPhases/components/CollapseChildrenIcon';

import { getDaysBetween } from '../../../../helpers/dateFunctions';
import DaysRemaining from './Columns/DaysRemaining';
import WorkCellStatus from './Columns/WorkCellStatus';

export const getDaysRemainingWorkCell = (row) => {
  if (isNil(row)) return 0;
  const firstDate = new Date(`${row.needBy}Z`);
  const secondDate =
    row.taskStatusTypeName === 'Completed' && row.taskStatusTypeUpdatedOn
      ? new Date(`${row.taskStatusTypeUpdatedOn}Z`)
      : new Date();
  const daysRemaining = row.needBy && getDaysBetween(firstDate, secondDate);
  return daysRemaining;
};

export const CellComponentFlag = () => (
  <Stack direction="row">
    <CollapseChildrenIcon showIcon filled sx={{ minWidth: 14 }} onClick={() => null} />
    <Box sx={{ mt: '6px', width: '20px' }}>{getNavIcon(EIconType.WORK_ORDERS)}</Box>
  </Stack>
);

export const linkStyles = {
  '& .link': {
    textDecoration: 'none',
    color: 'surface.lightSurface.primary',
    '&:hover': {
      color: 'secondary.main',
      textDecoration: 'underline',
    },
  },
};

export const CellComponentLink = ({ value, row }) => (
  <Box sx={{ ...linkStyles, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
    <Link
      to={`/shop/work-orders/${row.workOrderId}`}
      className="link"
      state={{ workOrderId: row.workOrderId, from: 'Work Cells' }}
    >
      <Typography color="secondary.main" variant="tableCell">
        {value}
      </Typography>
    </Link>
  </Box>
);

export const CellComponentStatus = ({ value, row }) => {
  const newStatus = BADGE_MAPPING_STATUS[value];
  const daysRemaining = getDaysRemainingWorkCell(row);
  return (
    <WorkCellStatus
      label={newStatus?.label || value}
      variant={daysRemaining < 0 ? 'pastDaysRemaining' : newStatus?.variant || value}
      needBy={dayjs(`${row.needBy}Z`)}
    />
  );
};

export const CellComponentDays = ({ row }) => {
  <DaysRemaining
    needByDate={row.workRequest.needBy}
    statusDate={row.lastStatusUpdate}
    status={row.workOrderStatusTypeName}
  />;
};
