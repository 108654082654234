import React from 'react';

import { SxProps } from '@mui/material';
import Typography from '@mui/material/Typography';

type LinkProps = {
  disabled?: boolean;
  children: any;
  sx?: SxProps;
  onClick: () => void;
};

const Link = ({ disabled, sx, onClick, ...rest }: LinkProps) => (
  <Typography
    variant="body2"
    onClick={onClick}
    sx={{
      ...(!disabled
        ? {
            cursor: 'pointer',
            '&:hover': {
              color: 'secondary.main',
              textDecoration: 'underline',
            },
          }
        : {
            color: 'surface.lightSurface.disabled',
            pointerEvents: 'none',
          }),
      ...sx,
    }}
    {...rest}
  />
);

export default Link;
