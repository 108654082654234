import React, { useMemo } from 'react';

import ChipOption from './ChipOption';

const ChipSelected = ({ item }) => {
  const itemProps = useMemo(() => {
    if (item) {
      const { chipName } = item;
      return {
        ...item,
        label: `Assigning to: ${chipName}`,
      };
    }
    return {};
  }, [item]);
  const sxChipOption = useMemo(() => {
    if (item)
      return {
        fontWeight: 500,
        bgcolor: 'secondary.main',
        color: 'white',
        margin: 0,
        marginLeft: 1,
        '& .MuiChip-deleteIcon': {
          fill: 'white',
        },
        '&:hover': {
          bgcolor: 'secondary.main',
        },
      };
    return {};
  }, [item]);

  return (
    <>
      {item && (
        <ChipOption
          onDelete={null}
          data-testid="ChipSelected-data-testid"
          sx={sxChipOption}
          key={item?.id}
          {...itemProps}
          icon={null}
        />
      )}
    </>
  );
};

export default ChipSelected;
