import React, { FC, useCallback, useMemo, useState } from 'react';

import { Box } from '@mui/material';

import StackRow from 'components/StackRow';
import { PickIcon } from 'modules/Materials/commons/FilterPopoverContent/Breakdown/HelperElements';

import ArrowDetailIcon from '../ArrowDetailIcon/ArrowDetailIcon';
import LBSRowInfo from '../LocationRow/LBSRowInfo';
import RowWrapper from '../RowWrapper/RowWrapper';
import WorkPhasesTable from '../WorkPhasesTable/WorkPhasesTable';

interface IScopeRow {
  scopeInfo: any;
  workPhases: any[];
  workPhasesLBS: any[];
  lineItemId: string;
}

const ScopeRow: FC<IScopeRow> = ({ lineItemId, scopeInfo, workPhases = [], workPhasesLBS = [] }: IScopeRow) => {
  const [showRows, setShowRows] = useState<boolean>(true);
  const handleShowRows = useCallback(() => setShowRows(!showRows), [showRows]);
  const scopeWorkPhases: any[] = useMemo(
    () => workPhases.filter((workPhase: any) => workPhase?.scopePackageId === scopeInfo.scopePackageId),
    [scopeInfo, workPhases],
  );
  const scopeWorkPhasesLBS = useMemo(
    () => workPhasesLBS.filter((workPhase) => workPhase.scopePackageId === scopeInfo?.scopePackageId),
    [workPhasesLBS, scopeInfo],
  );
  if (!scopeWorkPhasesLBS.length) return <></>;
  return (
    <>
      <RowWrapper>
        <StackRow sx={{ flex: 1, gap: '8px', width: '418px' }}>
          <Box sx={{ width: '8px' }} />
          <StackRow>
            {!scopeWorkPhasesLBS?.length ? (
              <Box width="32px" />
            ) : (
              <ArrowDetailIcon initialValue onToggle={() => handleShowRows()} />
            )}
            <PickIcon margin="0" type="Scope Package" chipWidth={undefined} />
          </StackRow>
          <LBSRowInfo rowInfo={scopeInfo} />
        </StackRow>
      </RowWrapper>
      {showRows && (
        <WorkPhasesTable
          isScopePackage
          lineItemId={lineItemId}
          workPhases={scopeWorkPhases}
          LBSWorkPhases={scopeWorkPhasesLBS}
        />
      )}
    </>
  );
};

export default ScopeRow;
