import React, { FC } from 'react';

import { WorkRequestsProvider } from 'modules/Field/WorkRequests/WorkRequestsContext';

import { useWorkOrdersContext } from '../../WorkOrdersContext';

type Props = {
  children: React.ReactNode;
};

const WorkOrderWRProvider: FC<Props> = ({ children }: Props) => {
  const { selectedWorkOrder } = useWorkOrdersContext();

  return (
    <WorkRequestsProvider
      workOrderSelected={selectedWorkOrder}
      workRequest={{
        ...selectedWorkOrder?.workRequest,
        workRequestStatusId: selectedWorkOrder?.workOrderStatusTypeId,
        workRequestStatusName: selectedWorkOrder?.workOrderStatusTypeName,
        daysRemaining: selectedWorkOrder?.daysRemaining,
      }}
      isWorkOrder
    >
      {children}
    </WorkRequestsProvider>
  );
};

export default WorkOrderWRProvider;
