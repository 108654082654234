import * as React from 'react';

import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';

import BreadcrumbLabel from './BreadcrumbLabel';
import BreadcrumbLink from './BreadcrumbLink';
import BreadcrumbNextIcon from './BreadcrumbNextIcon';

/** @deprecated use components/NavigationComponents/Breadcrumbs instead */
const Breadcrumb = ({
  breadcrumbs = [],
  darkMode,
  onSwitchChildren,
  sx,
  labelSx,
  lastLabelSx,
  ...props
}) => {
  const [children, setChildren] = React.useState();

  React.useEffect(() => {
    const chils = breadcrumbs.filter(({ component }) => Boolean(component));
    if (chils.length > 0) {
      setChildren(chils[0].component());
    }
  }, [setChildren, breadcrumbs]);

  React.useEffect(() => {
    const onChange = () => {
      if (onSwitchChildren) {
        onSwitchChildren(children);
      }
    };

    onChange();
  }, [children, onSwitchChildren]);

  return (
    <Breadcrumbs
      separator={<BreadcrumbNextIcon darkMode={darkMode} />}
      aria-label="breadcrumb"
      sx={sx}
      {...props}
    >
      {breadcrumbs.map(({ label, url, component }, index) => {
        if (url) {
          return <BreadcrumbLink key={label} url={url} label={label} darkMode={darkMode} />;
        }

        if (component) {
          return (
            <Box sx={{ cursor: 'pointer' }} onClick={() => setChildren(component())} key={label}>
              {index >= 0 && (
                <BreadcrumbLabel sx={labelSx} fontWeight={400} label={label} darkMode={darkMode} />
              )}
            </Box>
          );
        }
        if (index === breadcrumbs.length - 1) {
          return (
            <BreadcrumbLabel
              sx={lastLabelSx}
              key={label}
              label={label}
              darkMode={darkMode}
              fontWeight={400}
            />
          );
        }

        return (
          <BreadcrumbLabel
            sx={labelSx}
            key={label}
            label={label}
            darkMode={darkMode}
            fontWeight={400}
          />
        );
      })}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
