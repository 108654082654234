import { Dispatch, SetStateAction } from 'react';

import { UNASSIGNED } from '../../../constants';
import { ElementType, QuantityFieldType } from '../../QuantityField/hooks/@types/fieldHookTypes';

export const removeAutoFilledField = (
  autoFilledFieldName: string,
  setAutoFilledFields: Dispatch<SetStateAction<string[]>>,
) => {
  setAutoFilledFields((current) => current.filter((autoFilledField) => autoFilledField !== autoFilledFieldName));
};

export const addAutoFilledField = (
  autoFilledFieldName: string,
  {
    autoFilledFields,
    setAutoFilledFields,
  }: { autoFilledFields: string[]; setAutoFilledFields: Dispatch<SetStateAction<string[]>> },
) => {
  if (autoFilledFields.includes(autoFilledFieldName)) return;
  setAutoFilledFields((current: string[]) => [...current, autoFilledFieldName]);
};

export const getAllChildrenBelowLocation = ({
  element,
  childrenAcc = [],
}: {
  element: ElementType;
  childrenAcc?: ElementType[];
}): QuantityFieldType[] => {
  if (!element) return childrenAcc as QuantityFieldType[];
  childrenAcc.push(element);
  if (element?.children?.length) {
    element.children.forEach((child) => {
      getAllChildrenBelowLocation({ element: child, childrenAcc });
    });
  }
  return childrenAcc as QuantityFieldType[];
};

export const getQuantityFieldLocation = ({
  currentFields,
  itemLocationId,
  lineItemId,
  locationId,
  isBomLineItem = false,
}: {
  currentFields: QuantityFieldType[];
  itemLocationId: string;
  lineItemId: string;
  locationId: string | null;
  isBomLineItem: boolean;
}): QuantityFieldType | null | undefined => {
  if (!currentFields?.length) return null;
  const findCondition = (field: QuantityFieldType) => {
    if (isBomLineItem) return field?.id === lineItemId;
    if (field.isWorkPhase) return field?.id === itemLocationId && field.locationId === locationId;
    return field?.id === itemLocationId;
  };
  return currentFields.find((field) => findCondition(field));
};

export const getQtyFieldName = ({
  locationId,
  lineItemId,
  workPhaseId,
}: {
  locationId?: string;
  lineItemId: string;
  workPhaseId?: string;
}): string => {
  const getFirstId = () => {
    if (locationId) return locationId;
    if (workPhaseId) return undefined;
    return 'bomItem';
  };
  return ['qtyToAdd', getFirstId(), locationId?.includes(UNASSIGNED) ? '' : lineItemId, workPhaseId]
    .filter((str) => Boolean(str))
    .join('__');
};

export const getErrorMessage = ({
  qtyRemaining,
  qtyAggregate,
  isBomLineItem,
}: {
  qtyRemaining: number;
  qtyAggregate: number;
  isBomLineItem: boolean;
}): string => {
  const handleAggregateValueMessage = (): string => {
    if (qtyAggregate !== qtyRemaining && !isBomLineItem) return `or ${qtyAggregate}`;
    return '';
  };
  const handleRangeValueMessage = (): string => {
    if ((qtyRemaining === 0 && qtyAggregate === 0) || qtyRemaining < 0) return '0';
    return `${`between 1-${qtyRemaining}`} ${handleAggregateValueMessage()}`;
  };
  if ((!qtyRemaining && qtyAggregate) || qtyAggregate === 1) return `Value must be ${qtyAggregate}`;
  return `Value must be ${handleRangeValueMessage()} `;
};

export const getInputStyles = ({ showArrows, isReadOnly }: { showArrows: boolean; isReadOnly: boolean }) =>
  !showArrows
    ? {
        '& .MuiInputBase-input::-webkit-outer-spin-button, & .MuiInputBase-input::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
        '& .MuiInputBase-input': {
          MozAppearance: 'textfield',
          border: 'none',
          outline: 'none',
          pointerEvents: 'none',
        },
        ...(isReadOnly
          ? {
              '& .MuiOutlinedInput-notchedOutline': {
                border: '1px dashed #CCCCCC !important',
                outline: 'none',
              },
            }
          : {}),
      }
    : {};
