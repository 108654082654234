import { useCallback, useState, useRef } from 'react';

import { gql, useLazyQuery } from '@apollo/client';

import { partCategory as PART_CATEGORIES } from 'graphql/queries';

const usePartCategoriesAPI = (companyId = '', catalogId = '', limit = 9999) => {
  const [partCategories, setPartCategories] = useState([]);

  const [fetchPartCategories, { loading, data, refetch }] = useLazyQuery(gql(PART_CATEGORIES));
  const dataRef = useRef();
  dataRef.current = data;

  const getPartCategories = useCallback(
    async (partCategoryId = '') => {
      setPartCategories([]);

      if (!catalogId || !companyId) return [];

      const variables = {
        query: {
          skip: 0,
          take: limit,
          orderBy: 'name:asc',
          companyId,
          partCatalogId: catalogId,
          parentPartCategoryId: partCategoryId,
        },
      };

      const {
        data: { partCategory: partCategoriesRes },
      } = dataRef.current
        ? await refetch(variables)
        : await fetchPartCategories({
            variables,
          });
      setPartCategories(partCategoriesRes);
      return partCategoriesRes;
    },
    [catalogId, companyId, limit, fetchPartCategories, refetch],
  );

  return { partCategories, getPartCategories, loading };
};

export default usePartCategoriesAPI;
