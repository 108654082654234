import { ROW_TYPES } from 'modules/Field/LocationsAndWorkPhases/constants/constants';
import { useDeleteModal } from 'modules/Field/LocationsAndWorkPhases/contexts/DeleteModalContext';

import { useLocationsAndWorkPhases } from '../../../contexts/LocationsAndWorkPhaseContext';
import { createOption, getFirstChildType } from './moreMenuHelpers';

const promoteOption = (node, parent, clickEvent) => {
  const { locationTypeName: nodeType } = node;
  const { locationTypeName: parentType } = parent;

  if (nodeType === parentType && nodeType === ROW_TYPES.ZONE)
    return [createOption('Promote ', clickEvent)];
  return [];
};

const demoteOption = (node, parent, demote) => {
  const { locationTypeName: nodeType } = node;

  const locIndex = parent.children.indexOf(node);

  if (locIndex > 0) {
    const aboveSibling = parent.children[locIndex - 1] || {};
    const aboveSiblingChildType = getFirstChildType(aboveSibling);

    if (
      (!aboveSiblingChildType || nodeType === aboveSiblingChildType) &&
      nodeType === ROW_TYPES.ZONE
    )
      return [createOption('Demote ', () => demote(node, parent, parent.children[locIndex - 1]))];
  }

  return [];
};

export const useGeneralOptions = (node, parent) => {
  const {
    locations: { toggleEditNode, promote, demote },
  } = useLocationsAndWorkPhases();
  const { openDeleteLocationModal } = useDeleteModal();

  const getGeneralOptions = () => [
    ...promoteOption(node, parent, () => promote(node, parent)),
    ...demoteOption(node, parent, demote),
    createOption('Rename', () => {
      toggleEditNode(node);
    }),
    createOption('Delete', () => openDeleteLocationModal(node), 'error.main'),
  ];

  return { getGeneralOptions };
};
