import { gql, useMutation } from '@apollo/client';

import { updateDocument as UPDATE_DOCUMENT } from 'graphql/mutations';

const useDocumentMutation = () => {
  const [updateDocumentMutation, { loading }] = useMutation(gql(UPDATE_DOCUMENT));

  return { loading, updateDocumentMutation };
};

export default useDocumentMutation;
