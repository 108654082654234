import React from 'react';

import { Badge } from '@mui/material';

const StatusBadgeColor = ({ variant, sx = {}, small = false, ...props }) => (
  <Badge
    variant={variant}
    sx={{ width: small ? 8 : 10, height: small ? 8 : 10, borderRadius: 100, ...sx }}
    {...props}
  />
);

export default StatusBadgeColor;
