import { useCallback } from 'react';

import { gql, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';

import { updateBillOfMaterialsItemById as UPDATE_BOM_ITEM } from 'graphql/mutations';
import { BillOfMaterialItem } from 'graphql/types';
import useKeyPressEffect from 'hooks/useKeyPressEffect';
import { useBOMbyIdContext } from 'modules/Materials/BillOfMaterialsById/BOMbyIdContext';
import useRefetchBOMItemsChildren from 'modules/Materials/TakeOff/TakeOffPadTab/EditQuantityModal/hooks/useRefetchBOMItemsChildren';

const useUnitQuantityInput = ({ value }: { value: number }) => {
  const { editingUnitQtyRowData, setEditingUnitQtyRowData, setLoadingUpdateIds } = useBOMbyIdContext();
  const { getBOMItemsChildrenIds } = useRefetchBOMItemsChildren();
  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm({
    defaultValues: { quantity: value },
    mode: 'all',
  });

  const [updateBOMItem, { loading: updateBOMItemLoading }] = useMutation(gql(UPDATE_BOM_ITEM));

  const onUpdateBOMItem = useCallback(
    async (lineItem: BillOfMaterialItem, { unitQuantity }: { unitQuantity: number }, childrenIds: string[] = []) => {
      await updateBOMItem({
        variables: { body: { unitQuantity }, params: { id: lineItem?.lineItemId } },
        refetchQueries: ['BillOfMaterialItem'],
        awaitRefetchQueries: true,
        onQueryUpdated(observableQuery) {
          const resultChildren = observableQuery.getLastResult()?.data?.billOfMaterialItem as BillOfMaterialItem[];
          const isASubChild = resultChildren.some((child) => childrenIds.includes(child.lineItemId));
          return isASubChild;
        },
      });
    },
    [updateBOMItem],
  );

  const onCloseInput = useCallback(() => {
    setEditingUnitQtyRowData(null);
  }, [setEditingUnitQtyRowData]);

  useKeyPressEffect('Escape', () => onCloseInput());

  const onSubmit = async (data: any) => {
    if (isDirty) {
      const childrenIds = await getBOMItemsChildrenIds(editingUnitQtyRowData?.lineItemId);
      setLoadingUpdateIds(childrenIds);
      await onUpdateBOMItem(editingUnitQtyRowData, { unitQuantity: parseInt(data.quantity, 10) }, childrenIds);
      setLoadingUpdateIds([]);
    }

    onCloseInput();
  };

  return { handleSubmit, control, onSubmit, loading: updateBOMItemLoading };
};

export default useUnitQuantityInput;
