import React from 'react';

import Stack from '@mui/material/Stack';

import { Custom } from 'components/Icons';

const LinkButton = ({ onClick }: { onClick: () => void }) => (
  <Stack
    onClick={onClick}
    sx={{
      cursor: 'pointer',
      '&:hover path': {
        fill: '#613FC2',
        fillOpacity: 1,
      },
    }}
  >
    <Custom.Link fontSize="inherit" />
  </Stack>
);

export default LinkButton;
