const buildMuiButtonVariants = (activeModuleColors) => ({
  containedSecondary: {
    '&:hover': {
      backgroundColor: activeModuleColors.light,
    },
  },
  contained: {
    '&:hover': {
      backgroundColor: activeModuleColors.containedHoverBackground,
    },
  },
});

const buildBadgeColor = (variant, backgroundColor) => ({
  props: { variant },
  style: {
    backgroundColor,
  },
});

export const muiBadgeVariants = (activeModuleColors) => ({
  variants: [
    buildBadgeColor('receivedComplete', '#18AE39'),
    buildBadgeColor('approved', activeModuleColors?.approved || '#18AE39'),
    buildBadgeColor('completed', '#18AE39'),
    buildBadgeColor('submittedForApproval', '#613FC2'),
    buildBadgeColor('inProgress', '#4188FF'),
    buildBadgeColor('purchasingComplete', '#3653BA'),
    buildBadgeColor('receivedPartial', '#368BBA'),
    buildBadgeColor('outForQuotation', '#C9C226'),
    buildBadgeColor('submittedToPurchasing', '#D89F30'),
    buildBadgeColor('purchasingReview', '#B0780B'),
    buildBadgeColor('rejected', '#ce2d2dc7'),
    buildBadgeColor('recalled', '#9E1A1A'),
    buildBadgeColor('canceled', '#CE2D2D'),
    buildBadgeColor('notReceived', '#EC1111'),
    buildBadgeColor('paused', '#F25837'),
    buildBadgeColor('onHold', '#EB7000'),
    buildBadgeColor('closed', '#4F4F4F'),
    buildBadgeColor('new', '#FFA800'),
    buildBadgeColor('draft', activeModuleColors?.draft || '#00000080'),
    buildBadgeColor('pending', '#F3BB4D'),
    buildBadgeColor('late', '#F44336'),
    buildBadgeColor('draftWorkOrder', '#19749B'),
    buildBadgeColor('pastDaysRemaining', 'rgba(236, 17, 17, 1)'),
    buildBadgeColor('blocked', '#ED5500'),
  ],
});

export default (activeModuleColors) => ({
  MuiCssBaseline: {
    styleOverrides: {
      '*::-webkit-scrollbar': {
        width: '6px',
        height: '6px',
        borderRadius: '4px',
      },

      '*::-webkit-scrollbar-track': {
        background: '#f1f1f1',
        borderRadius: '4px',
        padding: '8px',
        boxSizing: 'content-box',
      },

      '*::-webkit-scrollbar-thumb:hover': {
        background: ' rgba(0, 0, 0, 0.5)',
      },

      '*::-webkit-scrollbar-thumb': {
        background: ' rgba(0, 0, 0, 0.1)',
        padding: '8px',
        boxSizing: 'content-box',
        borderRadius: '4px',
      },
      body: {
        margin: 0,
        overflowX: 'hidden',
        fontFamily: `futura-medium -apple-system, BlinkMacSystemFont,"Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell","Fira Sans", "Droid Sans", "Helvetica Neue",sans- serif, "futura";`,

        WebKitFontSmoothing: `antialiased`,
        MozOsxFontSmoothing: `grayscale`,
      },
    },
    MuiButton: { ...buildMuiButtonVariants(activeModuleColors) },
    MuiLink: {
      root: {
        color: '#4a93ff',
        cursor: 'pointer',
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: '8px',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '24px !important',
      },
      dividers: {
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      },
    },
    MuiDivider: {
      light: {
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
      },
    },
    MuiChip: {
      label: {
        fontSize: '13px',
        lineHeight: '18px',
      },
    },
  },
  MuiBadge: { ...muiBadgeVariants(activeModuleColors) },
});
