import { encodeURIFn } from './stringFunctions';

export const DEFAULT_QUERY_PARAMS_BY_PART_NAME = {
  parser: 'structured',
  queryOptions: encodeURIComponent(
    JSON.stringify({
      fields: [
        'catalog_number',
        'manufacturer_name',
        'part_name_for_searching',
        'part_category_name_for_searching',
        'unit_of_measure_code',
        'part_description',
        'part_categories_path',
      ],
    }),
  ),
};

export const DEFAULT_QUERY_PARAMS_BY_CATEGORY_PATH = {
  parser: 'structured',
  queryOptions: encodeURIFn("{fields:['part_categories_path']}"),
};

export const DEFAULT_QUERY_PARAMS_BY_PART_ID = {
  parser: 'structured',
  queryOptions: encodeURIComponent(
    JSON.stringify({
      fields: ['part_name', 'part_description', 'part_history_ref_id'],
    }),
  ),
};
