import { gql, useMutation } from '@apollo/client';

import { deleteShopConfiguration as DELETE_SHOP_CONFIGURATION } from 'graphql/mutations';
import { shopConfigurations } from 'graphql/queries';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

export default function useShopConfigurationDataLayer() {
  const { handleResponse } = useGraphqlResponseHandler();

  const [deleteShopConfiguration, { loading: loadingDeleteShopConfiguration }] = useMutation(
    gql(DELETE_SHOP_CONFIGURATION),
    { refetchQueries: [{ query: gql(shopConfigurations) }] },
  );

  const removeShopConfiguration = async (shopConfigurationId, successCallback) => {
    await handleResponse(
      deleteShopConfiguration,
      { variables: { params: { id: shopConfigurationId } } },
      {},
      successCallback,
    );
  };

  return {
    removeShopConfiguration,
    loadingDeleteShopConfiguration,
  };
}
