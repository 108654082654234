import { useMemo } from 'react';

import { gql, useQuery } from '@apollo/client';

import { externalParameters as EXTERNAL_PARAMETERS } from 'graphql/queries';
import { useProject } from 'modules/Field/LocationsAndWorkPhases/contexts/ProjectContext';

export type Parameter = {
  name: string;
  values: string[];
};

export type ParameterSchema = {
  [key: string]: string[];
};

export type ExternalParameters = {
  parameters: Parameter[];
  sourceData: string[];
};

export const useExternalParameters = () => {
  const { itemSelectedId: projectId } = useProject();

  const { data, loading, error } = useQuery(gql(EXTERNAL_PARAMETERS), {
    variables: { params: { projectId } },
    fetchPolicy: 'cache-and-network',
  });

  const parameters = useMemo(() => {
    if (error || loading) return [];
    const result = (data?.externalParameters?.parameters ?? []) as Parameter[];
    return result.map(({ name }) => name);
  }, [error, data, loading]);

  const parametersSchema = useMemo<ParameterSchema>(() => {
    if (error || loading) return {};
    const result = (data?.externalParameters?.parameters ?? []) as Parameter[];
    return result.reduce((prev, current) => ({ ...prev, [current.name]: current.values }), {});
  }, [error, data, loading]);

  const sourceData = useMemo(() => {
    if (error || loading) return [];
    const result = (data?.externalParameters?.sourceData ?? []) as string[];
    return result.map((key) => ({
      key,
      label: key,
    }));
  }, [error, data, loading]);

  return {
    parameters,
    parametersSchema,
    sourceData,
  };
};
