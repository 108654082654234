import React from 'react';

import { MenuItem } from '@mui/material';

import { useTakeOff } from '../../context/TakeOffContext';

const MapToTakeOffOption = () => {
  const { pads, onMapSourcePad, padSelected } = useTakeOff();
  const padSelectedId = padSelected?.takeoffPadId;
  return (
    <>
      {pads
        ?.filter(({ takeoffPadId }) => takeoffPadId !== padSelectedId)
        .map(({ takeoffPadName, takeoffPadId }) => (
          <MenuItem key={takeoffPadId} onClick={() => onMapSourcePad({ takeoffPadId })}>
            {takeoffPadName}
          </MenuItem>
        ))}
    </>
  );
};

export default MapToTakeOffOption;
