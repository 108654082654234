import React from 'react';

import ReactFlow, { ReactFlowProvider } from 'react-flow-renderer';

import AssemblyNode from '../FlowchartNodes/AssemblyNode';

const elements = [
  {
    id: 'e898c975-a1d3-4f91-b7d0-1bc1896c2104',
    type: 'assemblyType',
    key: 'e898c975-a1d3-4f91-b7d0-1bc1896c2104',
    position: {
      x: 550,
      y: 50,
    },
    data: {},
  },
  {
    id: 'e898c975-a1d3-4f91-9011-1bc1896c2104',
    type: 'assemblyType',
    key: 'e898c975-a1d3-4f91-9011-1bc1896c2104',
    position: {
      x: 550,
      y: 600,
    },
    data: {},
  },
];

const MainProvider = () => (
  <ReactFlowProvider>
    <ReactFlow elements={elements} nodeTypes={{ assemblyType: AssemblyNode }} />
  </ReactFlowProvider>
);

export default MainProvider;
