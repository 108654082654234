import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { Stack } from '@mui/material';

import { Custom } from 'components/Icons';
import { PartImage } from 'graphql/types';

import PartItem from '../../../../components/PartItem';
import usePartImage from '../../Manufacturer/PartImage/hooks/usePartImage';

type PartType = {
  title: string;
  catalogNumber?: string;
  manufacturerName?: string;
  description?: string;
  partId: string;
  partImages?: Array<PartImage>;
};

type ItemProps = {
  part: PartType;
  id: string;
  handleClick: (partId: string) => void;
  recentlyAddedPart: string;
  checked: boolean;
  isSelectable: boolean;
};

const Item = ({ part, id = '', handleClick, recentlyAddedPart, checked = false, isSelectable = false }: ItemProps) => {
  const { partId, title, catalogNumber, manufacturerName, description, partImages } = part;
  const { getPartImage } = usePartImage();
  const [partDefaultImage, setPartDefaultImage] = useState({});
  const defaultImage = partImages?.find((item) => item?.isDefault === true);
  const documentId = defaultImage?.document?.documentId;

  const fetchImages = useCallback(async () => {
    if (documentId) {
      const imageUrl = await getPartImage(documentId);
      setPartDefaultImage({ imageUrl });
    }
  }, [documentId, getPartImage]);

  useEffect(() => {
    fetchImages();
  }, [fetchImages]);

  const backgroundColorSx = checked ? { backgroundColor: 'rgba(0, 0, 0, 0.06)' } : {};
  const hoverBackgroundColorSx = useMemo(() => {
    if (checked) return {};
    return isSelectable ? { backgroundColor: 'rgba(0, 0, 0, 0.03)' } : {};
  }, [checked, isSelectable]);

  return (
    <PartItem id={id} sx={{ ...backgroundColorSx, '&:hover': { ...hoverBackgroundColorSx } }}>
      <PartItem.Image image={documentId ? partDefaultImage : {}} />
      <PartItem.Content sx={{ flex: 1, paddingRight: 6 }}>
        <PartItem.Title
          sx={{ marginBotton: '8px' }}
          onClick={() => handleClick(partId)}
          data-cy={`catalogPartItem-${title}`}
        >
          {title}
        </PartItem.Title>
        <PartItem.Header>
          {catalogNumber && <PartItem.HeaderElement>Catalog # {catalogNumber}</PartItem.HeaderElement>}
          <PartItem.HeaderElement>Mfg: {manufacturerName ?? ''}</PartItem.HeaderElement>
        </PartItem.Header>
        <PartItem.Description>{description ?? ''}</PartItem.Description>
      </PartItem.Content>
      {recentlyAddedPart === partId && (
        <Stack
          data-cy={`recentlyAddedPart-${title}`}
          data-testid="checkCircle"
          sx={{ justifyContent: 'center', color: 'success.main', paddingRight: 3 }}
        >
          <Custom.CheckCircle />
        </Stack>
      )}
    </PartItem>
  );
};

export default Item;
