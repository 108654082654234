import { useCallback } from 'react';

import { UpdateDeleteEntitlementParams } from 'types/types-api';

import { useWrappedDelete } from './useWrappedApiCall';

export const useUnassignEntitlement = () => {
  const { apiCall, ...rest } = useWrappedDelete('admin/entitlement');
  const doUnassignEntitlement = useCallback(
    async (query: UpdateDeleteEntitlementParams) =>
      apiCall({
        url: `admin/entitlement/${query?.entitlementId}`,
      }),
    [apiCall],
  );
  return { doUnassignEntitlement, ...rest };
};
