import React from 'react';

import { MenuItem, Typography } from '@mui/material';

const CatalogSearchPartCategoryItem = ({ item = {}, onSelect = () => {}, sx = {} }) => (
  <MenuItem
    role="button"
    onClick={() => onSelect(item)}
    sx={{ ...sx, height: '40px', padding: '0px 16px', gap: '8px' }}
  >
    <Typography
      variant="body2"
      sx={{
        paddingLeft: '5px',
        fontSize: '14px',
        lineHeight: '20px',
      }}
    >
      {item.label}
    </Typography>
  </MenuItem>
);

export default CatalogSearchPartCategoryItem;
