import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import getModuleIcon from 'helpers/module';
import useSetupModule from 'hooks/useSetupModule';
import { MODULE_LABEL_MAPPING } from 'types/module';

import EvolveLogoLink from './components/EvolveLogoLink';
import Breadcrumb from './components/FacilitiesProjectsBreadcrumb';
import HeaderDivider from './components/HeaderDivider';
import HeaderSearchAutocomplete from './components/HeaderSearchAutocomplete/HeaderSearchAutocomplete';
import MailButton from './components/MailButton';
import ModuleSelect from './components/ModuleSelect';
import HelpDeskDropdown from './HelpDeskDropdown/HelpDeskDropdown';
import ProfileDropdown from './ProfileDropdown/ProfileDropdown';

const DesktopHeader = () => {
  const LeftAligned = () => (
    <Box display="flex" alignItems="center" flexGrow="1">
      <Stack style={{ marginLeft: 8 }}>
        <EvolveLogoLink />
      </Stack>
      <HeaderDivider orientation="vertical" flexItem style={{ margin: '0 24px', height: 40 }} />
      <ActiveModuleIconLabel />
      <HeaderDivider orientation="vertical" flexItem style={{ margin: '0 32px', height: 40 }} />
      <Breadcrumb data-testid="header-breadcrumbs" />
    </Box>
  );

  const RightAligned = () => (
    <Box display="flex" alignItems="center">
      <Box style={{ marginRight: '1rem', width: '21rem' }}>
        <HeaderSearchAutocomplete />
      </Box>
      <HelpDeskDropdown />
      <MailButton />
      <ProfileDropdown />
      <HeaderDivider style={{ margin: '0 16px' }} orientation="vertical" flexItem />
      <ModuleSelect />
    </Box>
  );

  return (
    <SpaceBetweenContainer>
      <LeftAligned />
      <RightAligned />
    </SpaceBetweenContainer>
  );
};

export default DesktopHeader;

const ActiveModuleIconLabel = () => {
  const { currentModule } = useSetupModule();

  return (
    <Stack direction="row" sx={{ height: '100%' }} alignItems="center">
      <img width="24" height="24" src={getModuleIcon(currentModule())} alt="module-icon" />
      <Typography
        sx={{
          marginLeft: '8px',
          marginRight: '4px',
        }}
      >
        {MODULE_LABEL_MAPPING[currentModule()]}
      </Typography>
    </Stack>
  );
};

const SpaceBetweenContainer = (props) => (
  <Stack
    sx={{
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    }}
    {...props}
  />
);
