import React from 'react';

import { Stack, Typography, Divider } from '@mui/material';

import { Custom } from 'components/Icons';

import { useTakeOff } from '../../../context/TakeOffContext';
import { useSendToBomModal } from '../SendToBomModalContext';

const ModalHeader = () => {
  const { selectedPadItems } = useTakeOff();
  const { onCloseModal, bom } = useSendToBomModal();
  const title = bom.billOfMaterialsName;
  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ padding: '16px 16px 16px 24px' }}>
        <Stack direction="row" alignItems="center">
          <Typography variant="h6">{`Send to ${title}`}</Typography>
          <Typography variant="body2" sx={{ color: '#999', marginLeft: '8px' }}>{`${selectedPadItems.length} Item${
            selectedPadItems.length === 1 ? '' : 's'
          }`}</Typography>
        </Stack>

        <Custom.Close onClick={onCloseModal} />
      </Stack>
      <Divider />
    </>
  );
};

export default ModalHeader;
