import React, { useState } from 'react';

import { DesignMembersProvider } from './DesignMembersContext';
import DesignMembersHeader from './DesignMembersHeader';
import DesignMembersTable from './DesignMembersTable/DesignMembersTable';
import InviteToDesignModal from './InviteUsersModal/InviteToDesignModal';

const DesignMembers = () => {
  const [inviteModalOpen, setInviteModalOpen] = useState(false);

  const toggleInviteModal = () => setInviteModalOpen(!inviteModalOpen);

  return (
    <DesignMembersProvider>
      <DesignMembersHeader />
      <DesignMembersTable />
      <InviteToDesignModal openStatus={inviteModalOpen} onClose={toggleInviteModal} />
    </DesignMembersProvider>
  );
};

export default DesignMembers;
