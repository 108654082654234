import React, { useCallback, useState } from 'react';

import { Box } from '@mui/material';

import { useUser } from 'app/UserContext';
import ItemsDataGridPro, { ItemsDataGridProProvider } from 'components/ItemsDatagridPro';
import useLazyPaginatedWorkOrderItems from 'hooks-api/queries/workOrderItems/useLazyPaginatedWorkOrderItems';
import DeleteWorkRequestItemModal from 'modules/Field/WorkRequests/WorkRequest/DeleteWorkRequestModal';
import { CatalogModalProvider } from 'modules/Field/WorkRequests/WorkRequest/ModalToCreateCatalogPart/CatalogModalContext';
import ModalToCreateCatalogPart from 'modules/Field/WorkRequests/WorkRequest/ModalToCreateCatalogPart/ModalToCreateCatalogPart';
import SendToCatalogToast from 'modules/Field/WorkRequests/WorkRequest/SendToCatalogToast';
import WorkRequestLists from 'modules/Field/WorkRequests/WorkRequest/WorkRequestLists';
import { CatalogSetupProvider } from 'modules/Materials/CatalogSetup/CatalogSetupHeader/CatalogSetupContext';
import { CreateUOMModal } from 'modules/Materials/components/UOM';

import { buildTableStyles } from '../constants/constants';
import { useWorkOrderItemsContext } from '../context/WorkOrderItemsContext';
import { useWorkOrderItemTableHelpers } from '../hooks/useWorkOrderItemTableHelpers';

const ADD_ITEM_BUTTON_ROW = {
  top: [
    {
      workOrderItemId: 0,
      quantity: '',
      unityQuantity: '',
      unitOfMeasureId: '',
    },
  ],
};

const WorkOrderItemsTable = () => {
  const { canAddWriteInItems, setNewUomId, workOrder } = useWorkOrderItemsContext();
  const { workOrderItems, loadingWorkOrderItems } = useLazyPaginatedWorkOrderItems(workOrder);

  const [selectedItem, setSelectedItem] = useState(null);
  const [openModalUOM, setOpenModalUOM] = useState(false);
  const [openModalToCreateCatalogPart, setOpenModalToCreateCatalogPart] = useState(false);
  const [openModalToDelete, setOpenModalToDelete] = useState(false);

  const handleOpenModalToCreateCatalogPart = useCallback((selectedRow) => {
    setSelectedItem(selectedRow);
    setOpenModalToCreateCatalogPart(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenModalToDelete = useCallback((selectedRow) => {
    setSelectedItem(selectedRow);
    setOpenModalToDelete(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { columns, tableProps } = useWorkOrderItemTableHelpers(
    workOrder,
    handleOpenModalToCreateCatalogPart,
    handleOpenModalToDelete,
    setOpenModalUOM,
  );
  const { user } = useUser();

  const getWorkOrderItemLists = React.useCallback(
    ({ row }) => <WorkRequestLists row={row} parent={workOrder} />,
    [workOrder],
  );

  const onSelectUOMCreated = (uom) => setNewUomId(uom);

  return (
    <Box sx={{ mt: '14px', mb: '0px', flexGrow: 1 }}>
      <CatalogSetupProvider>
        <CatalogModalProvider>
          <ItemsDataGridProProvider contextId="WorkOrderItemsTable">
            <ItemsDataGridPro
              {...tableProps}
              columns={columns}
              rows={workOrderItems}
              pinnedRows={canAddWriteInItems ? ADD_ITEM_BUTTON_ROW : {}}
              sx={buildTableStyles(loadingWorkOrderItems)}
              getDetailPanelContent={getWorkOrderItemLists}
            />
          </ItemsDataGridProProvider>
          <SendToCatalogToast />
          <CreateUOMModal
            open={openModalUOM}
            onClose={() => setOpenModalUOM(false)}
            companyId={user?.companyId}
            onCreate={() => setOpenModalUOM(false)}
            onSelectUOMCreated={onSelectUOMCreated}
          />
          {openModalToCreateCatalogPart && (
            <ModalToCreateCatalogPart
              open={openModalToCreateCatalogPart}
              setShowModal={setOpenModalToCreateCatalogPart}
              selectedItem={selectedItem}
            />
          )}
          {openModalToDelete && (
            <DeleteWorkRequestItemModal
              open={openModalToDelete}
              workRequestItemName={selectedItem?.workOrderItemName}
              workRequestItemId={selectedItem?.workOrderItemId}
              setShowModal={setOpenModalToDelete}
              workOrder={workOrder}
            />
          )}
        </CatalogModalProvider>
      </CatalogSetupProvider>
    </Box>
  );
};

export default WorkOrderItemsTable;
