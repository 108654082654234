import { useEffect } from 'react';

export const useEffectThatHandlesHoverOnWorkRequests = (setHoveredRequestId, apiRef) => {
  useEffect(() => {
    const handleRowMouseEnter = ({ row }) => {
      setHoveredRequestId(row.workRequestId);
    };

    const handleRowMouseLeave = () => {
      setHoveredRequestId(null);
    };

    apiRef.current.subscribeEvent('rowMouseEnter', handleRowMouseEnter);
    apiRef.current.subscribeEvent('rowMouseLeave', handleRowMouseLeave);
  }, [apiRef, setHoveredRequestId]);
};
