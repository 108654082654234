import React from 'react';

import Section from '../../../../components/Section';
import useBreadcrumbBuilder from '../ItemsSection/ItemsListSection/useBreadcrumbBuilder';
import { CategoriesHeader } from './CategoriesHeader';
import CategoriesList from './CategoriesList';

const CategoriesRevit = () => {
  const { categoriesHiearachy } = useBreadcrumbBuilder();

  return (
    <Section mr="63px" ml="1px" outline="unset" maxWidth="23%">
      <Section.Title>
        <CategoriesHeader />
      </Section.Title>
      <Section.Body>
        <CategoriesList hiearachy={categoriesHiearachy} sxItem={{ height: '32px' }} />
      </Section.Body>
    </Section>
  );
};

export default CategoriesRevit;
