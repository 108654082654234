import React from 'react';

import { Stack } from '@mui/material';

import ComponentPaneActions from 'app/Layout/ReactGridLayout/ComponentPaneActions';
import { FilterButtonWithPopover } from 'components/Buttons';
import { Custom } from 'components/Icons';
import SearchBar from 'components/SearchBar/SearchBar';

import NewRequestBtn from './WorkRequestListElements/NewRequestBtn';

const WorkRequestFilterColumns = ({ workRequestSelected, filterComponent, isResponsive }) => (
  <ComponentPaneActions sx={{ justifyContent: 'end' }}>
    <Stack direction="row" alignItems="center" justifyContent="end">
      {workRequestSelected && <ShowSearchBar />}
      {filterComponent || <FilterButtonWithPopover disabled color="secondary.dark" />}
      <NewRequestBtn isResponsive={isResponsive} />
    </Stack>
  </ComponentPaneActions>
);

const ShowSearchBar = () => {
  const [openSearchBar, setOpenSearchBar] = React.useState(false);

  return openSearchBar ? (
    <SearchBar
      placeholder="Search work requests..."
      disabled
      sx={{ minWidth: '488px' }}
      closeSearchBar={() => setOpenSearchBar(false)}
      workRequestSelected
    />
  ) : (
    <Custom.Search sx={{ color: '#a4a4a4' }} onClick={() => setOpenSearchBar(true)} />
  );
};

export default WorkRequestFilterColumns;
