import React from 'react';

import { Typography, Stack, Box } from '@mui/material';

import { Custom } from 'components/Icons';
import useIconColorForDataGridTable from 'components/ItemsDatagridPro/useIconColorForDataGridTable';
import OverflowTip from 'components/Tooltip/OverflowTip';

import { WRITE_IN_ASSEMBLY, ASSEMBLY_ITEM_TYPE_ID } from './constants';

type Props = {
  // TODO: type this better
  row: {
    workRequestItemName: string;
    workRequestItemId: string;
    workRequestItemTypeId: typeof WRITE_IN_ASSEMBLY | typeof ASSEMBLY_ITEM_TYPE_ID;
  };
};

const WorkRequestItemName = ({ row }: Props) => {
  const { workRequestItemName, workRequestItemTypeId } = row;
  const isWriteIn = workRequestItemTypeId === WRITE_IN_ASSEMBLY;
  const { iconColor } = useIconColorForDataGridTable({ id: row.workRequestItemId });

  return (
    <Stack direction="row" alignItems="center" overflow="hidden">
      <Box width="18px" height="17px" sx={{ path: { fill: iconColor } }}>
        {isWriteIn ? <Custom.WriteInItemIcon /> : <Custom.Repair />}
      </Box>
      <OverflowTip title={workRequestItemName}>
        <Typography variant="body2" ml="15px" overflow="hidden" textOverflow="ellipsis">
          {workRequestItemName}
        </Typography>
      </OverflowTip>
    </Stack>
  );
};

export default WorkRequestItemName;
