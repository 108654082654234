import React, { useMemo } from 'react';

import { Droppable } from 'react-beautiful-dnd';

import { useDrawingRegisterDragDrop } from 'modules/Design/DrawingRegister/DrawingRegisterDragDropProvider';
import { ROW_TYPES } from 'modules/Shop/ShopSetup/WorkCellSetup/WorkCellsAndMachines/constants/constants';

const DroppableFolderRow = React.memo(({ row, children }) => {
  const { isMappingDocuments } = useDrawingRegisterDragDrop();
  const isDropDisabled = useMemo(
    () =>
      isMappingDocuments || row.type === 'new_folder' || (row.isEditing && row.type === 'folder'),
    [isMappingDocuments, row],
  );

  return (
    <Droppable
      droppableId={`drawing-folder/${row.id}`}
      isDropDisabled={isDropDisabled}
      type={ROW_TYPES.TASK_TYPES}
    >
      {children}
    </Droppable>
  );
});

export default DroppableFolderRow;
