import { useEffect } from 'react';

const useHandleHover = (apiRef, setHoveredRow) => {
  useEffect(() => {
    const handleRowMouseEnter = ({ row }) => {
      setHoveredRow(row.id);
    };

    const handleRowMouseLeave = () => {
      setHoveredRow(null);
    };

    apiRef.current.subscribeEvent('rowMouseEnter', handleRowMouseEnter);
    apiRef.current.subscribeEvent('rowMouseLeave', handleRowMouseLeave);
  }, [apiRef, setHoveredRow]);
};

export default useHandleHover;
