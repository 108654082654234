import React, { useCallback, useEffect, useMemo } from 'react';

import {
  AssignButtonWithPopper,
  ChipSelected as ChipSelectedComponent,
} from 'modules/Materials/features/assignation-mode/components';

import { useAssignationModeContext } from './useAssignationModeContext';

export const useAssignationMode = (context = () => ({}), onSwitchCallback = () => {}) => {
  const { chipList = [] } = context();
  const {
    states: { chipSelectedState, assignationModeState },
  } = useAssignationModeContext();

  const [chipSelected, setChipSelected] = chipSelectedState;
  const [assignationMode, setAssignationMode] = assignationModeState;
  const isMainButtonDisabled = useMemo(() => chipList.length === 0, [chipList]);
  const onSwitch = useCallback(
    (event, defaultState = false) => {
      const state = event?.target?.checked ?? defaultState;
      setAssignationMode(state);
      onSwitchCallback(state);
    },
    [setAssignationMode, onSwitchCallback],
  );
  const onClickOption = useCallback(
    (itemId) => {
      const item = chipList.find(({ id }) => itemId === id);
      if (item?.id === chipSelected?.id) {
        setChipSelected();
        onSwitch();
        return;
      }
      setChipSelected(item);
      onSwitch({}, true);
    },
    [chipList, chipSelected, setChipSelected, onSwitch],
  );
  const onCancel = useCallback(() => {
    setChipSelected();
    onSwitch();
  }, [setChipSelected, onSwitch]);

  useEffect(() => {
    if (chipList.length === 0) {
      onCancel();
    }
  }, [chipList, onCancel]);

  const Button = useMemo(
    () => (
      <AssignButtonWithPopper
        disabled={isMainButtonDisabled}
        data={chipList}
        onClickOption={onClickOption}
        currentItem={chipSelected}
        onCancel={onCancel}
        onSwitch={onSwitch}
        isAssignationModeDisabled={!chipSelected}
        defaultChecked={assignationMode && Boolean(chipSelected)}
      />
    ),
    [isMainButtonDisabled, chipList, onClickOption, chipSelected, onCancel, assignationMode, onSwitch],
  );

  const ChipSelected = useMemo(
    () => assignationMode && <ChipSelectedComponent item={chipSelected} />,
    [chipSelected, assignationMode],
  );

  const AssignationModeComponents = useMemo(() => ({ Button, ChipSelected }), [Button, ChipSelected]);

  const assignMetadata = useMemo(() => {
    if (chipSelected) {
      const { locationId, workPhaseId } = chipSelected;
      return { locationId, workPhaseId };
    }
    return {};
  }, [chipSelected]);

  useEffect(() => {
    if (!chipSelected) onSwitch();
  }, [chipSelected, onSwitch]);

  return {
    AssignationModeComponents,
    data: { assignMetadata },
  };
};
