import React from 'react';

import { Box, Tooltip, useTheme } from '@mui/material';

import { BADGE_MAPPING_STATUS } from 'constants/badgeMappingStatus';
import { getObjectKeyByValue } from 'helpers/objectFunctions';
import { convertToCamelCase } from 'helpers/stringFunctions';

type StatusNames = 'Started' | 'Not Started' | 'Completed';

const statusIds: Record<StatusNames, string> = {
  Started: '237130d0-3dea-4353-89c4-e30d8b405107',
  'Not Started': 'c06f1253-fd88-48ac-b2ea-bb2fdb743741',
  Completed: '274c5ba4-c137-434e-8249-84c5d5f72faf',
};

type StatusPointType = {
  statusId?: string;
};

const StatusPoint = ({ statusId }: StatusPointType) => {
  const { palette }: { palette: any } = useTheme();

  const statusName = getObjectKeyByValue(statusIds, statusId);

  const mappedStatusName = (): string => {
    if (statusId) return convertToCamelCase(statusName);
    return '';
  };

  const statusNameToTooltip = BADGE_MAPPING_STATUS[statusName as StatusNames];

  return (
    <Tooltip title={statusNameToTooltip?.label} arrow placement="top">
      <Box
        sx={{
          borderRadius: '50%',
          width: '8px',
          height: '8px',
          // TODO: Type palette properly
          // eslint-disable-next-line no-unsafe-optional-chaining
          background: (palette?.kanban)[mappedStatusName()],
        }}
      >
        {' '}
      </Box>
    </Tooltip>
  );
};

export default StatusPoint;
