import React, { useEffect } from 'react';

import { useForm } from 'react-hook-form';

import { useConfigurationNameInputRules } from 'hooks/useConfigurationNameInputRules';

import ShopConfigurationModal from './ShopConfigurationModal';
import useGetShopConfigurations from './useGetShopConfigurations';
import useUpdateShopConfiguration from './useUpdateShopConfiguration';

const EditShopConfigurationModal = ({
  open,
  onClose,
  onSubmit,
  shopConfiguration,
  setValue,
  prevShopConfiguration,
}) => {
  const { editShopConfiguration, loading } = useUpdateShopConfiguration(shopConfiguration);
  const { multiSearch, validateTypingText } = useGetShopConfigurations();

  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
    watch,
    setError,
    clearErrors,
  } = useForm({
    mode: 'all',
    defaultValues: {
      ShopConfigurationName: shopConfiguration?.shopConfigurationName,
      ShopConfigurationDescription: shopConfiguration?.shopConfigurationDescription,
      IsActive: shopConfiguration?.isActive,
    },
  });

  const { configurationNameInputRules, shopConfigurationsMultiSearch } = useConfigurationNameInputRules(
    multiSearch,
    setError,
    clearErrors,
    validateTypingText,
  );
  const watchShopConfigurationName = watch('ShopConfigurationName');

  useEffect(() => {
    if (open) {
      reset({
        ShopConfigurationName: shopConfiguration?.shopConfigurationName,
        ShopConfigurationDescription: shopConfiguration?.shopConfigurationDescription,
        IsActive: shopConfiguration?.isActive,
      });
    }
  }, [reset, open, shopConfiguration]);

  useEffect(() => {
    if (!watchShopConfigurationName) return;
    const shopConfigurationNameTrim = shopConfiguration?.shopConfigurationName.trim().toLowerCase();
    if (shopConfigurationNameTrim === watchShopConfigurationName?.trim()?.toLowerCase()) {
      clearErrors();
      return;
    }
    shopConfigurationsMultiSearch(watchShopConfigurationName);
  }, [
    shopConfiguration?.shopConfigurationName,
    shopConfigurationsMultiSearch,
    watchShopConfigurationName,
    clearErrors,
  ]);

  const handleCancelChange = () => {
    if (prevShopConfiguration && setValue) setValue('ShopConfigurationId', prevShopConfiguration);
    onClose();
  };

  const handleEdit = async (values) => {
    const body = {
      ShopConfigurationName: values.ShopConfigurationName,
      ShopConfigurationDescription: values.ShopConfigurationDescription,
      IsActive: shopConfiguration?.isActive ?? false,
    };

    await editShopConfiguration(body, (data) => {
      onSubmit({ ...data.updateShopConfiguration, IsActive: values.IsActive });
    });
  };

  return (
    <ShopConfigurationModal
      title="Edit Shop Configuration"
      open={open}
      onClose={handleCancelChange}
      control={control}
      loading={loading}
      isValid={isValid}
      onSubmit={handleSubmit(handleEdit)}
      configurationNameInputRules={configurationNameInputRules}
    />
  );
};

export default EditShopConfigurationModal;
