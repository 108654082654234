/* eslint-disable max-lines-per-function */
import React, { useRef } from 'react';

import { CenteredLoadSpinner } from 'components';

import LagTimesEdge from '../../../components/EditorComponents/EdgeTypes/LagTimesEdge/LagTimesEdge';
import EditorHeader from './EditorHeader';
import AssemblyNode from './FlowchartNodes/AssemblyNode';
import CustomPartTypeNode from './FlowchartNodes/CustomPartTypeNode';
import DecisionNode from './FlowchartNodes/DecisionNode';
import TaskTypeAssemblyNode from './FlowchartNodes/TaskTypeAssemblyNode';
// import FooterProvider from './ReactFlowProviders/FooterProvider'; This is for later on
import { useAssemblyEditorContext } from './ReactFlowProviders/AssemblyEditorContext';
import MainProvider from './ReactFlowProviders/MainProvider';

const AssemblyEditor = () => {
  const reactFlowWrapper = useRef(null);
  const { loadingAssemblies } = useAssemblyEditorContext();

  const nodeTypes = {
    taskType: TaskTypeAssemblyNode,
    partType: CustomPartTypeNode,
    decisionType: DecisionNode,
    assemblyType: AssemblyNode,
  };

  const edgeTypes = {
    lagTimesEdge: LagTimesEdge,
  };

  return (
    <div
      style={{ height: '100%', width: '100%' }}
      className="reactflow-wrapper"
      ref={reactFlowWrapper}
    >
      {loadingAssemblies ? (
        <CenteredLoadSpinner sx={{ height: '100%' }} theme="secondary" />
      ) : (
        <>
          <EditorHeader style={{ width: '100%' }} />

          <MainProvider nodeTypes={nodeTypes} edgeTypes={edgeTypes} />

          {/*
          // This is for later on
          <FooterProvider
            numOfSelected={selectedElements.length}
            edgeTypes={{}}
            nodeTypes={nodeTypes}
            elements={selectedElements}
          /> */}
        </>
      )}
    </div>
  );
};

export default AssemblyEditor;
