import React from 'react';

import NewItemRowComponent from 'modules/Materials/commons/NewItemRowComponent';

const RowComponent = ({
  hook = {},
  onAddWriteInItem = () => {},
  addSelectedPart = () => {},
  disabled = false,
  isRecursive = false,
}) => (
  <NewItemRowComponent
    actionDefaultLabel="Add item"
    hook={hook}
    onAddPartItem={addSelectedPart}
    onAddWriteInItem={onAddWriteInItem}
    disabled={disabled}
    isRecursive={isRecursive}
  />
);

export default RowComponent;
