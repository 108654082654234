/* eslint-disable import/no-cycle */

import { ApolloQueryResult } from '@apollo/client';

import { BillOfMaterialLineItemByLBS, Children, TakeOffPadItem } from 'graphql/types';
import { isNil } from 'helpers/isNotNil';
import { SelectedIntegrationItem } from 'modules/Materials/components/ItemsIntegrationModal/@types/itemsIntegrationModalContext';

import { QuantityFieldType } from '../components/QuantityField/hooks/@types/fieldHookTypes';
import { filterWorkPhasesAvailable } from '../components/WorkPhasesScopePackageTable/helpers/workPhasesScopeTableHelpers';
import { HandleDeleteItemType } from './@types/dataGridHelpers';

type LBSDataType = { billOfMaterialLineItemByLBS: BillOfMaterialLineItemByLBS };

export const handleLBSChildren = (response: ApolloQueryResult<LBSDataType>): Children[] => {
  const { data, errors } = response;
  if (isNil(data) || isNil(data.billOfMaterialLineItemByLBS)) return [];

  if (errors?.length && errors?.some((error: any) => error.errorType === '400')) return [];
  const { children }: BillOfMaterialLineItemByLBS = data.billOfMaterialLineItemByLBS;
  return children as Children[];
};

export const handleDeleteItem = (
  id: string,
  { setQuantityFields, methods, setRows, setFieldErrors, setSelectedItems, selectedItemIdKey }: HandleDeleteItemType,
) => {
  setQuantityFields((current) => current.filter((field) => field.lineItemId !== id));
  const fieldsToUnregister = Object.keys(methods.getValues()).filter((field) => field.includes(id));
  methods.unregister(fieldsToUnregister);
  setFieldErrors((current) => {
    let newFieldErrors = [...current];
    fieldsToUnregister.forEach((fieldName) => {
      newFieldErrors = newFieldErrors.filter((fieldErrorName) => fieldErrorName !== fieldName);
    });
    return newFieldErrors;
  });
  setRows((current) => current.filter((row) => row.id !== id));
  setSelectedItems((current) => current.filter((item) => item[selectedItemIdKey] !== id));
};

export const buildQuantityObjectItem = (
  selectedItem: SelectedIntegrationItem,
  children: QuantityFieldType[],
  billOfMaterialLineItemByLBS: BillOfMaterialLineItemByLBS,
): QuantityFieldType => ({
  nestLevel: 0,
  itemName: selectedItem.lineItemName ?? '',
  id: selectedItem.lineItemId,
  qtyRemaining: Math.round(billOfMaterialLineItemByLBS?.totalAmountRemaining ?? 0),
  qtyAggregate: Math.round(billOfMaterialLineItemByLBS?.totalAmountRemaining ?? 0),
  children: children?.filter((child) => child.locationId),
  lineItemTypeId: selectedItem?.lineItemTypeId,
  partId: selectedItem?.partId ?? undefined,
  takeoffPadId: (selectedItem as TakeOffPadItem)?.takeoffPadId ?? '',
  unitOfMeasureId: selectedItem.unitOfMeasureId ?? '',
  workPhases: filterWorkPhasesAvailable(children?.filter((child) => child.workPhaseId)),
  totalQuantity: selectedItem?.quantity ?? 0,
  totalQuantityRemaining: billOfMaterialLineItemByLBS?.totalAmountRemaining ?? 0,
  unassignedAmount: billOfMaterialLineItemByLBS?.unassignedAmountRemaining ?? 0,
});
