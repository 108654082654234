import React from 'react';

import { useMutation, gql } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import { useForm } from 'react-hook-form';

import { Modal, CancelButton, CreateButton } from 'components';
import AssignAdminControlledInput from 'components/FormComponents/AssignAdminControlledInput';
import { addProjectUser as ADD_PROJECT_USER } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

const AddAdminModal = ({ openStatus, onClose, projectId }) => {
  const { handleResponse } = useGraphqlResponseHandler(onClose);
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: 'all',
  });

  const [addProjectAdminUserMutation, { loading }] = useMutation(gql(ADD_PROJECT_USER), {
    refetchQueries: ['ProjectUsers'],
  });

  const submit = async ({ adminUserEmail }) => {
    const body = {
      projectId,
      isAdmin: true,
    };

    if (typeof adminUserEmail === 'object') {
      body.userId = adminUserEmail.id;
    } else {
      body.userEmail = adminUserEmail;
    }

    handleResponse(
      addProjectAdminUserMutation,
      {
        variables: {
          body,
        },
      },
      { successMessage: 'Admin added successfully!' },
    );
  };

  return (
    <Modal
      open={openStatus}
      onClose={onClose}
      title="Add Admin"
      reducePadding
      fullWidth
      maxWidth="xs"
      footer={
        <>
          {loading && <CircularProgress />}
          <CancelButton disabled={loading} onClick={onClose} />
          <CreateButton
            color="primary"
            buttonText="Add"
            disabled={!isValid || loading}
            onClick={handleSubmit(submit)}
          />
        </>
      }
    >
      <AssignAdminControlledInput control={control} sx={{ margin: 0 }} disabled={loading} />
    </Modal>
  );
};

export default AddAdminModal;
