export const highlightStyles = {
  backgroundColor: 'white !important',
  borderLeft: '3px solid',
  borderLeftColor: 'secondary.main',
  boxShadow: 8,
  '& div': {
    backgroundColor: 'white !important',
  },
};

export const disabledStyles = {
  backgroundColor: 'action.darkSurface.disabled',
  pointerEvents: 'none',
  color: 'action.disabled',
  '& .MuiSvgIcon-root': {
    fill: '#9E9E9E',
  },
};
