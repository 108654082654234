import React, { useState } from 'react';

const ShopMembersContext = React.createContext();

const ShopMembersProvider = ({ children }) => {
  const [selected, setSelected] = useState([]);

  const selectedStateObj = React.useMemo(
    () => ({
      selected,
      setSelected,
    }),
    [selected, setSelected],
  );

  return (
    <ShopMembersContext.Provider value={selectedStateObj}>{children}</ShopMembersContext.Provider>
  );
};

const useShopMembersContext = () => {
  const context = React.useContext(ShopMembersContext);
  if (context === undefined) {
    throw new Error('useShopMembersContext must be used within a ShopMembersContext');
  }
  return context;
};

export { ShopMembersContext, ShopMembersProvider, useShopMembersContext };
