import React, { useEffect } from 'react';

import { Button, Menu } from '@mui/material';

import { useItemsIntegrationModalContext } from 'modules/Materials/components/ItemsIntegrationModal/context/ItemsIntegrationModalContext';

import { useHeader } from '../HeaderContext';
import ActionOptions from './ActionOptions';
import CustomIcon from './CustomIcon';
import MapToTakeOffOption from './MapToTakeOffOption';

const SelectAction = ({ disabled, isSourcePad, isTakeoff = false }) => {
  const { anchorElLvl2, updateAnchorElLvl2 } = useHeader();
  const open = Boolean(anchorElLvl2);
  const handleClick = (e) => {
    updateAnchorElLvl2(e.currentTarget);
  };
  const handleClose = () => updateAnchorElLvl2(null);
  const { open: openItemsToBOMModal } = useItemsIntegrationModalContext();

  useEffect(() => {
    if (openItemsToBOMModal) handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openItemsToBOMModal]);
  return (
    <>
      <Button
        disabled={disabled}
        variant="outlined"
        size="small"
        endIcon={<CustomIcon disabled={disabled} />}
        onClick={handleClick}
        color="secondary"
        sx={{
          padding: '0 8px 0 8px',
          height: '32px',
          border: `solid 1px ${!disabled ? 'secondary.outlinedRestingBorder' : '#cccccc'} !important`,
        }}
        data-cy="select-action-take-off"
      >
        {isSourcePad ? 'MAP TO PAD' : 'ACTION'}
      </Button>
      <Menu id="Actions for items" anchorEl={anchorElLvl2} open={open} onClose={handleClose}>
        {isTakeoff && isSourcePad ? <MapToTakeOffOption /> : <ActionOptions closeMenu={handleClose} />}
      </Menu>
    </>
  );
};

export default SelectAction;
