import { clone } from 'helpers/arrayFunctions';

export const appendNewChild = (parentData, newChildData = {}, childrenKey = 'children') => ({
  ...parentData,
  isOpen: true,
  [childrenKey]: [...(parentData[childrenKey] || []), newChildData],
});

export const appendNewChildren = (parentData, newChildren = [], childrenKey = 'children') => ({
  ...parentData,
  isOpen: true,
  childrenTypeName: newChildren.length > 0 ? parentData.childrenTypeName : null,
  [childrenKey]: [...(parentData[childrenKey] || []), ...newChildren],
});

export const appendNewChildBySortOrder = (
  parentData,
  newChildData = {},
  childrenKey = 'children',
) => {
  let index = 0;
  const parent = clone(parentData);

  parent[childrenKey]?.forEach((location, locationIndex) => {
    if (newChildData.sortOrder > location.sortOrder) {
      index = locationIndex + 1;
    }
  });

  if (!parent[childrenKey]) parent[childrenKey] = [newChildData];
  else parent[childrenKey].splice(index, 0, newChildData);

  return parent;
};
