export const WIDTHS = {
  Project: 14,
  Floor: 14,
  Zone: 13,
  'Location System': 14,
  'Work Phase-G': 10,
  'location with work phase': 14,
  'Work Phase': 10,
  OTHER: 10,
};
export const MARGINS = {
  Project: '0 0 3px 6px',
  Floor: '0 0 2px 6px',
  Zone: '0 0 2px 8px',
  'Location System': '0 0 3px 6px',
  'Work Phase-G': '0 0 3px 6px',
  'location with work phase': '0 0 0 6px',
  'Work Phase': '0 0 3px 7px',
  'Scope Package': '0 0 3px 7px',
  OTHER: '0 0 3px 6px',
};
