import React from 'react';

import { IconButton } from '@mui/material';
import { Stack } from '@mui/system';

import { Custom } from 'components/Icons';

import useWorkOrderDelete from '../Hooks/useWorkOrderDelete';
import WorkOrderModalDelete from './WorkOrderModalDelete';

export const IconDeleteWorkOrder = ({ row }) => {
  const { workOrderName, workOrderId, workOrderStatusTypeName } = row;

  const [workOrderModalDeleteOpen, setWorkORderModalDeleteOpen] = React.useState(false);

  const openWorkOrderModalDelete = () => setWorkORderModalDeleteOpen(true);
  const closeWorkOrderModalDelete = () => setWorkORderModalDeleteOpen(false);

  const { removeWorkOrder, loading } = useWorkOrderDelete();

  const submit = async () => {
    await removeWorkOrder(workOrderId);
    closeWorkOrderModalDelete();
  };

  return (
    <>
      <Stack direction="row" onClick={openWorkOrderModalDelete}>
        {workOrderStatusTypeName !== 'Completed' && (
          <IconButton className="MuiBox-trash" disableRipple>
            <Custom.Trash />
          </IconButton>
        )}
      </Stack>
      <WorkOrderModalDelete
        openStatus={workOrderModalDeleteOpen}
        onClose={closeWorkOrderModalDelete}
        identifier={workOrderName}
        submit={submit}
        loading={loading}
      />
    </>
  );
};
