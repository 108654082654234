import React, { useState, useEffect } from 'react';

import ChangeShopConfigurationModal from 'modules/Shop/ShopProperties/InfoTab/ChangeShopConfigurationModal';
import EditShopConfigurationModal from 'modules/Shop/ShopProperties/InfoTab/EditShopConfigurationModal';
import useUpdateShopConfiguration, {
  formatShopConfiguration,
} from 'modules/Shop/ShopProperties/InfoTab/useUpdateShopConfiguration';

import { useShopConfigurationSelectContext } from './ShopConfigurationSelect/ShopConfigurationSelectContext';

const EditShopConfiguration = ({ openEditModal, setOpenEditModal, shopConfiguration }) => {
  const [openChangeModal, setOpenChangeModal] = useState(false);
  const [currentShopConfiguration, setCurrentShopConfiguration] = useState(null);
  const { shopConfigurationsUnformatted, refetchShopConfigurations } =
    useShopConfigurationSelectContext();

  useEffect(() => {
    setCurrentShopConfiguration(formatShopConfiguration(shopConfiguration));
  }, [shopConfiguration]);

  const { editShopConfiguration, loadingUpdate } =
    useUpdateShopConfiguration(currentShopConfiguration);

  const handleCloseEditShopModal = () => {
    setOpenEditModal(false);
  };

  const handleSubmitEditShopModal = (data) => {
    if (data?.IsActive && !currentShopConfiguration.isActive) {
      setCurrentShopConfiguration(data);
      setOpenChangeModal(true);
    }
    refetchShopConfigurations();

    handleCloseEditShopModal();
  };

  const handleCancelChange = () => {
    refetchShopConfigurations();
    handleCloseEditShopModal();
    setOpenChangeModal(false);
  };

  const handleConfirmActive = async () => {
    const body = {
      IsActive: true,
    };

    await editShopConfiguration(body, () => {
      refetchShopConfigurations();
      setOpenChangeModal(false);
      handleCloseEditShopModal();
    });
  };

  return (
    <>
      <EditShopConfigurationModal
        open={openEditModal}
        shopConfiguration={currentShopConfiguration}
        shopConfigurations={shopConfigurationsUnformatted}
        onClose={handleCloseEditShopModal}
        onSubmit={handleSubmitEditShopModal}
      />

      <ChangeShopConfigurationModal
        open={openChangeModal}
        newConfigurationName={currentShopConfiguration?.shopConfigurationName}
        onClose={handleCancelChange}
        onSubmit={handleConfirmActive}
        onPrevious={() => {
          setOpenChangeModal(false);
          setOpenEditModal(true);
        }}
        loading={loadingUpdate}
      />
    </>
  );
};

export default EditShopConfiguration;
