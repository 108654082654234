import React from 'react';

import { Box, Stack } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Droppable } from 'react-beautiful-dnd';

import { ROW_TYPES } from '../constants/constants';

const draggingOverStyles = (hasChildren) => ({
  background: grey[100],
  '&::before': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: 34,
    top: hasChildren ? 0 : -35,
    left: 0,
    borderBottom: '2px solid',
    borderBottomColor: 'secondary.main',
    borderTop: '2px solid',
    borderTopColor: 'secondary.main',
  },
});

const DroppableContainer = ({
  droppableId,
  type = ROW_TYPES.TASK_TYPES,
  isDropDisabled,
  children,
}) => (
  <Box sx={{ position: 'relative' }}>
    <Droppable droppableId={droppableId} type={type} isDropDisabled={isDropDisabled}>
      {({ innerRef, droppableProps, placeholder }, { isDraggingOver }) => (
        <Box
          ref={innerRef}
          {...droppableProps}
          sx={isDraggingOver ? draggingOverStyles(Boolean(children)) : {}}
        >
          {children || <EmptyContent />}
          {placeholder}
        </Box>
      )}
    </Droppable>
  </Box>
);

export default DroppableContainer;

const EmptyContent = () => <Stack sx={{ height: '1px', overflow: 'hidden' }}>&nbsp;</Stack>;
