import { useEffect, useMemo, useState } from 'react';

import { FILE_TYPE_FORMATS_DR } from 'constants/fileConstants';
import { FILE_FORMATS } from 'constants/globalConstants';
import useDocumentForgeViewer from 'hooks-api/useDocumentForgeViewer';
import useDocumentSignedURLAPI from 'hooks-api/useDocumentSignedURLAPI';

import useStatusGraphic from './useStatusGraphic';

const PDF_EXTENSIONS = ['.pdf'];

const useGetGraphicType = (document) => {
  const [typeFileFormat, setTypeFileFormat] = useState(null);
  const { signedURL, getSignedURL } = useDocumentSignedURLAPI();

  const { getForgeViewer, forgeViewer } = useDocumentForgeViewer();

  const { loading, error } = useStatusGraphic(forgeViewer, document, typeFileFormat);

  useEffect(() => {
    if (document?.documentId) {
      if (PDF_EXTENSIONS.includes(document?.extension?.toLowerCase())) {
        setTypeFileFormat(FILE_FORMATS.PDF);
        getSignedURL(document.documentId);
        return;
      }
      setTypeFileFormat(FILE_FORMATS.ANOTHER_FILE);
      getForgeViewer(document.documentId);
    }
  }, [document, getForgeViewer, getSignedURL, setTypeFileFormat]);

  const dynamicProps = useMemo(() => {
    if (typeFileFormat === FILE_FORMATS.PDF)
      return {
        shouldInitializeAuth: false,
        env: 'Local',
        urn: signedURL,
        extensions: ['Autodesk.PDF'],
        accessToken: '',
        getAccessToken: () => null,
        useADP: false,
      };

    if (forgeViewer)
      return {
        env: 'AutodeskProduction',
        api: 'derivativeV2',
        urn: forgeViewer?.encodedUrn,
        accessToken: forgeViewer?.token,
        getAccessToken: (onTokenReady) => {
          onTokenReady(forgeViewer?.token, forgeViewer?.timeInSeconds);
        },
      };
    return null;
  }, [forgeViewer, typeFileFormat, signedURL]);

  const isSupported = useMemo(
    () => FILE_TYPE_FORMATS_DR.some((formatFile) => `.${formatFile}` === document?.extension?.toLowerCase()),
    [document?.extension],
  );

  return { dynamicProps, loading, error, isSupported };
};

export default useGetGraphicType;
