import { Custom } from 'components/Icons';

const singlePaneLayout = [{ x: 0, y: 0, w: 1, h: 1, i: '0' }] as const;

const doublePaneLayout = [
  { i: '0', y: 0, w: 1, h: 1, minW: 1, x: 0 },
  { i: '1', y: 0, w: 1, h: 1, minW: 1, x: 1 },
] as const;

const triplePaneLayout = [
  { i: '0', x: 0, y: 0, w: 1, h: 1, minW: 1 },
  { i: '1', x: 1, y: 0, w: 1, h: 2, minW: 1 },
  { i: '2', x: 0, y: 1, w: 1, h: 1, minW: 1 },
] as const;

const quadruplePaneLayout = [
  { i: '0', x: 0, y: 0, w: 1, h: 1, minW: 1 },
  { i: '1', x: 1, y: 0, w: 1, h: 1, minW: 1 },
  { i: '2', x: 0, y: 1, w: 1, h: 1, minW: 1 },
  { i: '3', x: 1, y: 1, w: 1, h: 1, minW: 1 },
] as const;

export const getPaneLayouts = (numberOfPanes: number, sizes: number[] = []) => {
  const getPanesByNumber = () => {
    if (numberOfPanes === 1) return singlePaneLayout;
    if (numberOfPanes === 2) return doublePaneLayout;
    if (numberOfPanes === 3) return triplePaneLayout;
    if (numberOfPanes === 4) return quadruplePaneLayout;
    return quadruplePaneLayout;
  };

  const simpleLayouts = getPanesByNumber();
  const mappedLayout = getPanesByNumber().map((pane, index) => {
    if (sizes.length > 2) return pane;
    let correspondingIndex = index;
    if (index === 2) correspondingIndex = 0;
    if (index === 3) correspondingIndex = 1;

    return { ...pane, w: sizes[correspondingIndex] || pane.w };
  });

  return {
    lg: mappedLayout,
    md: mappedLayout,
    sm: simpleLayouts,
    xs: simpleLayouts,
    xxs: simpleLayouts,
  };
};

export const HEADER_HEIGHT = 64;
export const PANES_PADDING = 10;

export const buildDefaultLayoutSettings = (numberOfPanes: number, height: number, sizes: number[] = []) => {
  const rows = numberOfPanes <= 2 ? 1 : 2;

  let numberOfCols = numberOfPanes === 1 ? 1 : 2;

  if (sizes.length && sizes.length <= 2) {
    const sum = sizes.reduce((partialSum, a) => partialSum + a, 0);
    numberOfCols = sum + (2 - sizes.length);
  }

  return {
    isDraggable: true,
    isResizable: true,
    rows,
    maxRows: rows,
    preventCollision: false,
    allowOverlap: false,
    draggableHandle: '.draggable-area',
    layouts: getPaneLayouts(numberOfPanes, sizes),
    cols: { lg: numberOfCols, md: numberOfCols, sm: 1, xs: 1, xxs: 1 },
    rowHeight: (height - HEADER_HEIGHT - (rows + 1) * PANES_PADDING) / rows,
  };
};

export const DeviceSizes = {
  desktop: 'DESKTOP',
  tablet: 'TABLET',
  mobile: 'MOBILE',
} as const;

export const PREV_CATALOG_ID_KEY = 'PREV_CATALOG_ID_KEY' as const;
export const SELECTED_CATALOG_ID = 'SELECTED_CATALOG_ID' as const;

export const FETCH_POLICIES = {
  CACHE_AND_NETWORK: { fetchPolicy: 'cache-and-network' },
  CACHE_FIRST: { fetchPolicy: 'cache-first' },
  CACHE_ONLY: { fetchPolicy: 'cache-only' },
  NETWORK_ONLY: { fetchPolicy: 'network-only' },
  NO_CACHE: { fetchPolicy: 'no-cache' },
  STANDBY: { fetchPolicy: 'standby' },
} as const;

export const ITEM_TYPES = {
  WRITE_IN_ASSEMBLY: 'Write-In Assembly',
  LINE_ITEM: 'Line Item',
  PART: 'Part',
  ASSEMBLY: 'Assembly',
} as const;

export const SESSION_STORAGE_CONSTANTS = {
  PART_ADDED_FROM_WR: 'partAddedFromWR',
  PUBLISHED_PART_FROM_EDITOR: 'publishedPartFromEditor',
  PART_CATEGORY_FROM_WR: 'partCategoryFromWR',
  REACHED_CATALOG_SETUP_THROUGH_LINK: 'reachedCatalogSetupThroughLink',
  CURRENT_BOM: 'currentBOM',
  CURRENT_CATALOG: 'currentCatalog',
  WR_PROJECT_SELECTED: 'wrProjectSelected',
} as const;

export const RECORDING_STATES = {
  Started: 'Started',
  Paused: 'Paused',
  Completed: 'Completed',
  In_progress: 'In Progress',
  Not_started: 'Not Started',
} as const;

export const POSITION_TITLE = {
  tasks: 'tasks',
  workOrders: 'workOrders',
  workRequests: 'workRequests',
} as const;

export const DEPARTMENT_TYPE_IDS = {
  DESIGN_TYPE_DEPARTMENT_ID: 'bb0c5132-a15a-49a7-ba0c-9493b80942c9',
  PROCUREMENT_TYPE_DEPARTMENT_ID: '776d41c5-965a-4620-b40d-c3c847ccf7a0',
  SHOP_TYPE_DEPARTMENT_ID: '6edd6cfb-08d0-4a19-8ed2-3ee64320ebf0',
} as const;

export const DOCUMENT_STATUS_TYPE = {
  CONVERTING_DOCUMENT_FAILS: '095595cf-db4e-4154-95cd-9fe29ac00921',
  DOCUMENT_READY: '5d9f0522-b92d-4ce2-b56d-a97f9753b7df',
  DOCUMENT_CREATED: '62474f8c-42f3-457e-9c3e-c42ea2cdad4d',
  DOCUMENT_UPLOADED: '88a1147c-c657-4813-8aeb-e42c104e1e74',
  CONVERTING_DOCUMENT: 'e24d61ea-5c61-4a62-918c-542e8e9ccf45',
  LOADING_DOCUMENT: '72a1547b-a553-6893-8afe-t52f104i5g96',
} as const;

export const PRODUCT_IDS = {
  MECHANICAL_LICENSE: '29329d32-1464-4c9b-a760-35a572f49773',
  ELECTRICAL_LICENSE: '35a5aca5-1115-4c3e-b678-ce9d6fff66c1',
} as const;

export const FILE_FORMATS = {
  PDF: 'PDF',
  ANOTHER_FILE: 'ANOTHER_FILE',
} as const;

export const INCLUDE_DELETED_OPTIONS = {
  YES: 'yes',
  NO: 'no',
  ONLY_DELETED: 'only deleted',
} as const;

export const SORTING_MODE = {
  CLIENT: 'client',
  SERVER: 'server',
} as const;

export const LOCAL_STORAGE_CONSTANTS = {
  STAY_SIGNED_IN: 'staySignedIn',
  SELECTED_ITEM: 'SELECTED_ITEM',
  PART_IMAGES_DELETE_ASK_CONSENT: 'PART_IMAGES_DELETE_ASK_CONSENT',
  SELECTED_CATALOG_ID: 'SELECTED_CATALOG_ID',
  PREV_CATALOG_ID_KEY: 'PREV_CATALOG_ID_KEY',
  CURRENT_MODULE: 'currentModule',
  CURRENT_PAGE: 'currentPage',
  WORK_CELL_COLORS_IDS: 'WORK_CELLS_KANBAN_COLORS_IDS',
  PROJECT_COLORS: 'PROJECT_COLORS',
} as const;

export const BOM_ITEM_TYPES = {
  assembly: 'Custom Assembly',
  write: 'Write-In',
  part: 'Part',
} as const;

export const BOM_ITEM_LINE_TYPES = {
  assembly: '08daeffe-aac0-497c-8b68-6b2ef5ad121a',
  write: '3844b775-ee61-4bc6-8a8f-fe471929a381',
  part: '56b9df5e-31d5-4f72-b294-b2b615293a58',
} as const;

export const NEW_ITEM_ROW_ID = 1;

export const GENERIC_MUTATION_ERROR = 'Error: Unable to save changes.' as const;

export const RIGHT_SIDE_FLYOUT_SECTIONS = {
  CATALOG: 'catalog',
  SETTINGS: 'settings',
} as const;

export const ICONS_MAP = {
  [BOM_ITEM_TYPES.write]: Custom.WriteInItemIcon,
  [BOM_ITEM_TYPES.assembly]: Custom.CustomAssembly,
  [BOM_ITEM_TYPES.part]: Custom.PCItem,
} as const;

export const UTCFORMATDAYJS = 'YYYY-MM-DDTHH:mm:ss[Z]' as const;
export const UTCFORMATBE = 'YYYY-MM-DDTHH:mm:ss' as const;

export const UPLOAD_FILE_EXTENSIONS =
  /\.(jpg|jpeg|png|gif|bmp|tiff|svg|txt|xls|xlsx|ppt|pptx|doc|docx|csv|pdf|dxf|al|psd|rvt|maj|caj|esj|dwg|dxf|cad|ipt|iam|skp)$/i;
