import { NewWRFormContextType } from '../@types/newWRForm';
import { useNewWRFormContext } from '../components/NewWRForm/context/NewWRFormContext';
import { useItemsIntegrationModalContext } from '../context/ItemsIntegrationModalContext';

const useModalHelpers = ({ integrationId }: any) => {
  const { selectionModel, rows } = useItemsIntegrationModalContext();
  const {
    methods: {
      formState: { isDirty },
      handleSubmit: handleSubmitNewWR,
    },
    submit,
  }: NewWRFormContextType = useNewWRFormContext();
  const getIntegrationId = async () => {
    if (integrationId) return { integrationId };
    let currentWorkRequestId;
    let currentWorkRequestIdentifier;
    if (isDirty) {
      await handleSubmitNewWR(async (values: any) => {
        const newWorkRequestData = await submit(values, true);
        currentWorkRequestId = newWorkRequestData?.data?.addWorkRequest.workRequestId;
        currentWorkRequestIdentifier = newWorkRequestData?.data?.addWorkRequest.workRequestIdentifier;
      })();
      return { integrationId: currentWorkRequestId, currentWorkRequestIdentifier };
    }
    // eslint-disable-next-line prefer-destructuring
    currentWorkRequestId = selectionModel[0];
    currentWorkRequestIdentifier = rows?.find(
      (row: any) => row.workRequestId === selectionModel[0],
    )?.workRequestIdentifier;
    return { integrationId: currentWorkRequestId, currentWorkRequestIdentifier };
  };
  return { getIntegrationId };
};

export default useModalHelpers;
