import { useCallback } from 'react';

import { BOMCatalogTableFields } from './useBOMTableColumns/BOMTableColumnsHelpers';

const BOMCatalogTableSortKeys = [
  { field: BOMCatalogTableFields.LINE_ITEM_NAME, sortKey: 'name' },
  { field: BOMCatalogTableFields.QUANTITY, sortKey: 'quantity' },
  { field: BOMCatalogTableFields.QUANTITY_REMAINING, sortKey: 'remainingquantity' },
  { field: BOMCatalogTableFields.UNIT_OF_MEASURE_CODE, sortKey: 'unitcode' },
  { field: BOMCatalogTableFields.MANUFACTURER_NAME, sortKey: 'manufacturer' },
  { field: BOMCatalogTableFields.MANUFACTURER_CATALOG_NUMBER, sortKey: 'catalognumber' },
  { field: BOMCatalogTableFields.DECISIONS, sortKey: 'numberofopendecisions' },
  { field: BOMCatalogTableFields.ASSEMBLY_TYPE, sortKey: 'assemblyCustomCategoryName' },
];

const findSortKeyByField = (field) =>
  BOMCatalogTableSortKeys.find(({ field: columField }) => columField === field)?.sortKey;

const useBOMCatalogTableSort = (sortCb = () => {}) => {
  const onSortModelChange = useCallback(
    (columns = []) => {
      const column = columns[0];
      if (!column) return;

      const { field, sort } = column;
      const sortKey = findSortKeyByField(field);
      if (!sortKey) return;

      sortCb(`${sortKey}:${sort}`);
    },
    [sortCb],
  );

  return { onSortModelChange };
};

export default useBOMCatalogTableSort;
