import React, { useEffect } from 'react';

import { EIconType } from 'helpers/MenuIcons';

import MiniSpinner from '../../../../../../../components/MiniSpinner';
import { CONTAINER_TYPES, ROW_TYPES } from '../../constants/constants';
import { useDataContext } from '../../contexts/DataContext';
import DroppableContainer from '../DroppableContainer';
import RowTitle from '../RowTitle';
import StyledIcon from '../StyledIcon';
import StyledRow from '../StyledRow';
import TaskRow from '../TaskRow/TaskRow';
import { useWorkCell } from '../WorkCellRow/contexts/WorkCellContext';
import UnmapMachine from './components/UnmapMachine';
import { useMachine } from './contexts/MachineContext';

const MachineRow = () => {
  const { machine, machineTasks, loadingMachineTasks, disabled } = useMachine();
  const { disabled: disabledByWorkCell } = useWorkCell();
  const { activeRow, setActiveRow, setCloseFlyoutMenu, draggingTaskId } = useDataContext();

  const handleSelectRow = () => {
    setActiveRow({ id: machine.workCellMachineId, type: ROW_TYPES.MACHINE, ...machine });
    setCloseFlyoutMenu(true);
  };

  const isDisabled = disabled || disabledByWorkCell;

  useEffect(() => {
    if (activeRow.isMachineCreated && activeRow.machineId === machine.machineId) handleSelectRow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRow, machine]);

  return (
    <>
      <DroppableContainer
        droppableId={`${CONTAINER_TYPES.MACHINES_TASKS}_${machine.machineId}_${machine.workCellId}`}
        isDropDisabled={disabled || machineTasks?.some((task) => task.taskTypeId === draggingTaskId)}
      >
        <StyledRow
          isActive={machine.workCellMachineId === activeRow.id}
          onClick={handleSelectRow}
          sx={{ pl: 14, cursor: 'grab' }}
          testId="machine-row"
        >
          <StyledIcon iconName={EIconType.MACHINE} disabled={isDisabled} />
          <RowTitle disabled={isDisabled}>{machine.machineIdentifier}</RowTitle>
          <UnmapMachine machine={machine} />
        </StyledRow>
      </DroppableContainer>

      <>
        {machineTasks.length ? (
          machineTasks?.map((task) => (
            <TaskRow task={task} key={task.machineTaskTypeId} sx={{ pl: 16.9 }} isMachineTask />
          ))
        ) : (
          <>{loadingMachineTasks && <MiniSpinner showBorderBottom />}</>
        )}
      </>
    </>
  );
};

export default MachineRow;
