import React from 'react';

import { InputForesiteField } from '@evolvemep/foresite-components-ui-react';

import { requiredRule } from 'constants/inputFieldRules';
import { getNumberOfOccurrences } from 'helpers/arrayFunctions';

import DropdownMultiple from './DropdownMultiple';

const WorkPhaseList = ({
  showCreateWorkPhaseInput,
  setValue,
  saveNewOption,
  workPhase,
  form,
  handleAddNewOption,
  ...rest
}) =>
  showCreateWorkPhaseInput ? (
    <WorkPhaseTextField saveNewOption={saveNewOption} form={form} {...rest} />
  ) : (
    <DropdownMultiple
      name="workPhase"
      label="Work Phase"
      addNewOptionText="New work phase"
      setValue={setValue}
      values={workPhase}
      handleAddNewOption={handleAddNewOption}
      {...rest}
    />
  );

export default WorkPhaseList;

const WorkPhaseTextField = ({ saveNewOption, options, form, ...rest }) => {
  const validateNameExists = (value) =>
    getNumberOfOccurrences('label', value, options) !== 0 ? 'Work Phase name already exists' : true;

  const handleBlurSaveNewOption = (e) => {
    const { value } = e?.target || {};
    if (value && form.formState.isValid) saveNewOption(value);
  };

  return (
    <InputForesiteField
      name="newOptionName"
      color="secondary"
      label="Work Phase"
      variant="outlined"
      size="small"
      onKeyPress={(e) => {
        const { value } = e.target;
        if (e.code === 'Enter' && value && form.formState.isValid) saveNewOption(value);
      }}
      rules={{ ...requiredRule('Name'), validate: validateNameExists }}
      sx={{ mt: '8px', flex: 1, '& p': { color: 'error.main' } }}
      autoComplete="off"
      autoFocus
      onBlur={handleBlurSaveNewOption}
      {...rest}
    />
  );
};
