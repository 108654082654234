import React from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Custom } from 'components/Icons';
import { materialsColors } from 'styles/theme/colors';

const PropertiesOverlayHeader = ({ onClose }: any) => (
  <Stack
    direction="row"
    alignItems="center"
    sx={{
      justifyContent: 'space-between',
      borderBottom: '1px solid rgba(204, 204, 204, 1)',
      marginBottom: 1,
    }}
  >
    <Stack direction="row" alignItems="center" gap="8px">
      <Custom.PartThumbnailIcon
        sx={{ fill: materialsColors.mainDarkBackground, width: '18px', height: '18px' }}
      />

      <Typography noWrap variant="h6" sx={{ color: '#fff', width: '260px' }}>
        Item
      </Typography>
    </Stack>
    <Custom.CloseIcon sx={{ color: '#ffffffb3', cursor: 'default' }} onClick={onClose} />
  </Stack>
);

export default PropertiesOverlayHeader;
