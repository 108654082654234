import { useCallback } from 'react';

export const useCurrentModuleFromURL = (modulesAllow: string[], defaultModule: string) => {
  const searchCurrentModule = useCallback(() => {
    const root = window.location.pathname.match(/\/([^/]+)/)?.[1]?.toLocaleLowerCase();
    if (root && modulesAllow.includes(root)) return root;
    return defaultModule;
  }, [defaultModule, modulesAllow]);

  return { searchCurrentModule };
};
