import React from 'react';

import { Box, Typography } from '@mui/material';

import DecisionAdded from '../BillOfMaterialsById/BOMCatalogTable/Decisions/DecisionAdded';
import { getNumberOfOpenDecisionString } from '../BillOfMaterialsById/BOMCatalogTable/Decisions/helpers';
import BasicCellComponent from './BasicCellComponent';

const LineItemNameCell = ({ value, row }) => (
  <>
    <BasicCellComponent>
      {value}
      <Typography component="span" sx={{ color: '#00000040' }}>
        {getNumberOfOpenDecisionString(row?.numberOfOpenDecisions)}
      </Typography>
    </BasicCellComponent>
    <Box onClick={(event) => event.stopPropagation()}>
      <DecisionAdded row={row} isTakeoff />
    </Box>
  </>
);

export default LineItemNameCell;
