import React from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Custom } from 'components/Icons';

const PartsGridNotFound = ({ searchKey, cloudSearchPartsCount }) => {
  if (!cloudSearchPartsCount)
    return (
      <Stack display="flex" flexDirection="column" alignItems="center" pt={8}>
        <Custom.NoSearchContent />
        <Typography variant="h6" sx={{ color: 'rgba(255, 255, 255, 0.6)' }}>
          No items match your search for {searchKey}
        </Typography>
      </Stack>
    );

  return null;
};

export default PartsGridNotFound;
