import React from 'react';

import { Custom } from 'components/Icons';

type BreadcrumbNextIconProps = {
  darkMode: boolean;
};

const BreadcrumbNextIcon = ({ darkMode }: BreadcrumbNextIconProps) => (
  <Custom.NavigateNextIcon
    sx={{
      color: darkMode ? 'surface.darkSurface.secondary' : 'surface.lightSurface.secondary',
      margin: '0 -6px',
    }}
    fontSize="small"
  />
);

export default BreadcrumbNextIcon;
