import { gql, useMutation } from '@apollo/client';

import { getDocumentPresignedURL } from 'graphql/mutations';

const useGetPreSignedUrl = () => {
  const [fetchDocumentPresigned, { loading: getUrlLoading }] = useMutation(gql(getDocumentPresignedURL));

  const getDocumentPreSignedUrl = async (storagePath: string) => {
    const variables = { body: { expirationHours: 1, objectKey: storagePath, verb: 'GET' } };
    const response = await fetchDocumentPresigned({ variables });

    return response?.data?.getDocumentPresignedURL?.preSignedURL || '';
  };

  return {
    getDocumentPreSignedUrl,
    getUrlLoading,
  };
};

export default useGetPreSignedUrl;
