import { gql, useLazyQuery } from '@apollo/client';

import { workPhases as WORK_PHASES } from 'graphql/queries';

const useWorkPhaseAPI = (fetchPolicy = 'cache-and-network') => {
  const [workPhasesQuery, { data, called: wasCalled, loading }] = useLazyQuery(gql(WORK_PHASES), {
    fetchPolicy,
  });

  const fetchWorkPhases = (query = {}, options = {}) =>
    workPhasesQuery({
      variables: { query },
      ...options,
    });

  return { fetchWorkPhases, wasCalled, loading, workPhases: data?.workPhases || [] };
};

export default useWorkPhaseAPI;
