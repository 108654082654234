import React, { useMemo } from 'react';

import { CircularProgress, Stack, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

import { Custom } from '../Icons';

type Props = {
  sortLoading: boolean;
};

const useBOMItemsGroupColumns = ({ sortLoading }: Props) => {
  const rowStyles = useMemo(
    () => ({
      backgroundColor: 'white',
      padding: '0px 16px 0px 100px',
    }),
    [],
  );

  const getIcon = (params: GridRenderCellParams) => {
    if (params.row.itemType === 'Part') return <Custom.PartThumbnailIcon style={{ fill: '#9E9E9E', height: '23px' }} />;
    if (params.row.itemType === 'PC Assembly')
      return <Custom.AssemblyIcon style={{ fill: '#9E9E9E', height: '23px' }} />;
    if (params.row.itemType === 'Write-In')
      return <Custom.WriteInItemIcon style={{ fill: '#9E9E9E', height: '23px' }} />;
    return <></>;
  };

  return [
    {
      field: 'whiteSpace',
      headerName: '',
      width: 168,
      sortable: false,
    },
    {
      field: 'item',
      headerName: 'Item',
      width: '50%',
      flex: 3,
      renderCell: (params: GridRenderCellParams) =>
        sortLoading ? (
          <Stack
            sx={{
              ...rowStyles,
              pl: 0,
              alignItems: 'center',
              height: '32px',
              borderBottom: '1px solid rgba(224,224,224,1)',
            }}
            direction="row"
          >
            <CircularProgress color="secondary" size={20} />
          </Stack>
        ) : (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
            <div style={{ height: 32, width: 32, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {getIcon(params)}
            </div>
            <Typography variant="body2" sx={{ ml: '5px' }}>
              {params.value}
            </Typography>
          </div>
        ),
    },
    {
      field: 'spoolQuantity',
      headerName: 'Spool Qty',
      minWidth: 50,
      flex: 1,
      renderCell: ({ value }: GridRenderCellParams) => (
        <Typography variant="body2" sx={{ color: '#999' }}>
          {value}
        </Typography>
      ),
    },
    {
      field: 'quantityRequired',
      headerName: 'Qty',
      minWidth: 50,
      flex: 1,
    },
    {
      field: 'unitOfMeasure',
      headerName: 'UOM',
      minWidth: 50,
      flex: 1,
    },
    {
      field: 'manufacturer',
      headerName: 'Manufacturer',
      minWidth: 50,
      flex: 1,
    },
    {
      field: 'catalogNumber',
      headerName: 'Catalog Number',
      minWidth: 50,
      flex: 1,
    },
  ];
};

export default useBOMItemsGroupColumns;
