import React, { useCallback, useEffect, useState } from 'react';

import { useUser } from 'app/UserContext';
import useUploadImage from 'hooks/useUploadImage';

const ProfilePictureContext = React.createContext();

const ProfilePictureProvider = ({ children }) => {
  const { user, setUpdateUserListener } = useUser();
  const { getImage, uploadImage } = useUploadImage();
  const [profilePicture, setProfilePicture] = useState(null);

  const getProfilePicture = useCallback(async () => {
    if (user) {
      const { userPhotoId } = user;
      const getPresignedURL = await getImage(userPhotoId);
      setProfilePicture(getPresignedURL);
    }
  }, [getImage, user]);

  useEffect(() => {
    if (user) getProfilePicture();
  }, [getProfilePicture, user]);

  const updateProfilePicture = async (userPhotoId) => {
    const getPresignedURL = await getImage(userPhotoId);
    if (getPresignedURL) {
      setProfilePicture(getPresignedURL);
      setUpdateUserListener({ user, getPresignedURL });
    }
  };

  const uploadProfilePicture = async (imgFile) => {
    if (user) {
      const { companyId, userId } = user;
      const imageStoragePath = `all/company/${companyId}/users/${userId}/images`;
      const documentId = await uploadImage(imgFile, imageStoragePath, user.userId);

      return documentId;
    }
    return null;
  };

  return (
    <ProfilePictureContext.Provider
      value={{
        profilePicture,
        uploadProfilePicture,
        updateProfilePicture,
      }}
    >
      {children}
    </ProfilePictureContext.Provider>
  );
};

const useProfilePictureContext = () => {
  const context = React.useContext(ProfilePictureContext);
  if (context === undefined) {
    throw new Error('useProfilePictureContext must be used within a ProfilePictureContext');
  }
  return context;
};

export { ProfilePictureContext, ProfilePictureProvider, useProfilePictureContext };
