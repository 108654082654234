import React from 'react';

import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';

import CreateButton from 'components/Buttons/CreateButton';
import { Custom } from 'components/Icons';
import Search from 'components/TableComponents/Search';

import { useProjectContext } from './ProjectsContext';

const ProjectsHeader = () => {
  const { searchHandler, openModal } = useProjectContext();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ margin: '4px 0 16px 0' }}
    >
      <Typography variant="h5">My Projects</Typography>
      <Stack direction="row">
        <Search handleSearch={searchHandler} placeholder="Search…" />
        <CreateProjectButton onClick={openModal} />
      </Stack>
    </Stack>
  );
};

export default ProjectsHeader;

const CreateProjectButton = ({ onClick }) => (
  <CreateButton
    onClick={onClick}
    color="primary"
    startIcon={<Custom.AddIcon />}
    sx={{
      minWidth: 176,
      maxHeight: 32,
      fontSize: 14,
      borderRadius: 1,
    }}
    buttonText="create project"
    data-cy="create-project"
    data-testid="create-project-button"
  />
);
