import React, { useEffect, useRef } from 'react';

import { InputForesiteField } from '@evolvemep/foresite-components-ui-react';
import { IconButton, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import { Custom } from 'components/Icons';
import MiniSpinner from 'components/MiniSpinner';
import StackRow from 'components/StackRow';
import { requiredRule } from 'constants/inputFieldRules';
import { VARIANT_ERROR, VARIANT_SUCCESS } from 'constants/snackbarConstants';
import useWorkOrderDocumentAPI from 'hooks-api/useWorkOrderDocumentAPI';
import { useWorkRequestsContext } from 'modules/Field/WorkRequests/WorkRequestsContext';
import { surfaceColors } from 'styles/theme/colors';

import { useWorkRequestPropertiesAttachmentsContext } from '../WorkRequestPropertiesAttachmentsContext';

const WorkRequestAttachUrlInput = ({ setReloadWorkOrderAttachments = () => {} }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { workRequestSelected, updateWRList, setUpdateWRList, workOrderSelected } = useWorkRequestsContext();
  const { addDocumentsToWorkRequest, setShowUrlInput, addWorkRequestDocumentLoading } =
    useWorkRequestPropertiesAttachmentsContext();
  const {
    addWorkOrderDocumentLoading,
    callbacks: { addDocumentsToWorkOrder },
  } = useWorkOrderDocumentAPI();

  const inputRef = useRef();

  const { control, watch, trigger } = useForm({ mode: 'all' });

  const { url } = watch();

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  const handleHideInput = () => setShowUrlInput(false);

  const handleAddDocument = async () => {
    if (workOrderSelected)
      return addDocumentsToWorkOrder({
        workOrderId: workOrderSelected.workOrderId,
        externalUrl: url,
      });
    return addDocumentsToWorkRequest({
      workRequestId: workRequestSelected?.workRequestId,
      externalUrl: url,
    });
  };

  const submit = async () => {
    try {
      const response = await handleAddDocument();
      if (!response?.errors) {
        enqueueSnackbar('URL successfully added', VARIANT_SUCCESS);
        handleHideInput();
      } else {
        enqueueSnackbar('Error adding URL', VARIANT_ERROR);
      }
    } catch (error) {
      enqueueSnackbar('Error adding URL', VARIANT_ERROR);
    }
    setReloadWorkOrderAttachments((current) => current);
    setUpdateWRList(!updateWRList);
  };

  const validateEnterKey = (event) => {
    if (event.key === 'Enter') {
      event.target.blur();
    }
  };

  const handleBlur = (event) => {
    const targetClasses = event?.relatedTarget?.classList?.value || '';
    const hasClickedOnCancelButton = targetClasses.includes('cancel-button');
    if (hasClickedOnCancelButton) handleHideInput();
    else if (!url?.length) trigger('url');
    else submit();
  };

  const isLoading = addWorkRequestDocumentLoading || addWorkOrderDocumentLoading;

  return (
    <StackRow alignItems="flex-start">
      {isLoading ? (
        <MiniSpinner height="24px" size="16px" sx={{ marginTop: '4px', width: '37px', marginLeft: '4px' }} />
      ) : (
        <CancelIcon onClick={handleHideInput} />
      )}

      <UrlTextField
        inputRef={inputRef}
        control={control}
        onKeyDown={validateEnterKey}
        onBlur={handleBlur}
        disabled={isLoading}
      />
    </StackRow>
  );
};

export default WorkRequestAttachUrlInput;

const UrlTextField = (props) => {
  const { palette } = useTheme();
  return (
    <InputForesiteField
      name="url"
      placeholder=""
      rules={requiredRule('Url', '*Required')}
      maxLength={2048}
      size="small"
      color="secondary"
      inputProps={{
        maxLength: 2048,
        style: {
          height: 24,
          padding: '0 12px',
          fontSize: '14px',
          lineHeight: '20px',
          letterSpacing: '0.15px',
          overflow: 'hidden',
        },
      }}
      autoComplete="off"
      sx={{
        marginTop: '4px !important',
        '& input': { background: 'white', overflow: 'hidden', borderRadius: '4px' },
        '& .Mui-error': {
          color: `${palette.error.light}`,
          lineHeight: '20px',
        },
        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
          borderColor: `${palette.error.light} !important`,
        },
        '& .MuiFormHelperText-root': {
          textAlign: 'left',
          color: `${palette.error.light}`,
          marginTop: 0,
          '&.Mui-error': {
            color: `${palette.error.light}`,
          },
        },
      }}
      {...props}
    />
  );
};

const CancelIcon = ({ onClick }) => (
  <IconButton color="inherit" onClick={onClick} size="small" sx={{ ml: '4px' }} className="cancel-button">
    <Custom.Close fill={surfaceColors.darkSurface.primaryOpacity60} />
  </IconButton>
);
