import React from 'react';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import MoreMenuButton from 'components/MoreMenuButton';
import StackRow from 'components/StackRow';

const TaskTypeCardInformation = ({
  taskTypeName,
  taskTypeClassTheme,
  taskTypeDescription,
  image,
  options,
  icon,
  showThreeDotsMenu,
  sx,
  disabled = false,
  children,
  ...props
}) => (
  <StyledTaskTypeCard tasktypeclasstheme={taskTypeClassTheme} sx={{ ...sx }} disabled={disabled}>
    <StyledTaskNodeThumbnailContainer>
      {image ? (
        <img
          src={image}
          alt={taskTypeName}
          width="38px"
          height="46px"
          style={{ borderRadius: '3px 0 0 3px' }}
          data-testid="taskTypeCardInformationImage"
        />
      ) : (
        icon
      )}
    </StyledTaskNodeThumbnailContainer>
    <Stack sx={{ flex: 1, width: 'calc(100% - 54px)' }}>
      <Typography
        data-testid={props['data-testid']}
        variant="caption"
        sx={{
          color: taskTypeClassTheme.palette?.secondary?.main,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontWeight: 400,
        }}
      >
        {taskTypeName || '--'}
      </Typography>
      <Typography
        data-testid="taskTypeDescription"
        variant="body2"
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxHeight: '1.5em',
          whiteSpace: 'nowrap',
          fontWeight: 400,
        }}
      >
        {taskTypeDescription || '--'}
      </Typography>
      {options && showThreeDotsMenu && (
        <MoreMenuButton
          style={{
            position: 'absolute',
            top: '5px',
            right: '-8px',
            height: '13px',
          }}
          sxIcon={{ disableRipple: true }}
        >
          {options.map((item) => (
            <MenuItem key={item.label} onClick={item.clickEvent}>
              {item.label}
            </MenuItem>
          ))}
        </MoreMenuButton>
      )}
      {children}
    </Stack>
  </StyledTaskTypeCard>
);

const StyledTaskTypeCard = ({ sx, tasktypeclasstheme, disabled, ...props }) => (
  <StackRow
    sx={{
      height: 48,
      width: 232,
      border: '1px solid',
      borderRadius: '4px',
      borderColor: tasktypeclasstheme?.palette?.secondary?.main || '#613FC2',
      backgroundColor: tasktypeclasstheme?.palette?.secondary?.light || '#E6DEFE',
      padding: 0,
      gap: '16px',
      position: 'relative',
      '& path': { fill: tasktypeclasstheme?.palette?.secondary?.main || '#613FC2' },
      opacity: disabled ? '0.5' : '1',
      ...sx,
    }}
    {...props}
  />
);

const StyledTaskNodeThumbnailContainer = (props) => (
  <Box
    sx={{
      width: 38,
      height: 46,
      backgroundColor: '#FFF', // TODO some cards may not have white background
      paddingLeft: 0,
      paddingTop: 0,
      borderTopLeftRadius: '4px',
      borderBottomLeftRadius: '4px',
    }}
    {...props}
  />
);

export default TaskTypeCardInformation;
