import React, { FC } from 'react';

import { Stack, Typography, Box, useTheme } from '@mui/material';

import { Custom } from 'components/Icons';

export const PropertiesPopupHeader: FC = () => {
  const { palette } = useTheme();
  return (
    <>
      <Stack direction="row" justifyContent="space-between" sx={{ width: '450px', height: '100%' }}>
        <Stack direction="row">
          <Typography variant="h6" paddingLeft="24px" sx={{ color: palette.secondary.contrastText, marginTop: '16px' }}>
            Properties
          </Typography>
        </Stack>
        <Box height="22px" sx={{ marginTop: '22px', marginX: '4px' }}>
          <Custom.Dock width={22} height={22} fill={palette.secondary.contrastText} data-testid="dock-icon" />
        </Box>
      </Stack>
    </>
  );
};
