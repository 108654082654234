import React, { useState, useEffect, useMemo } from 'react';

import { gql } from '@apollo/client';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { useUser } from 'app/UserContext';
import { departmentUsers as DEPARTMENT_USERS } from 'graphql/queries';
import { INCLUDE_DELETED_OPTIONS } from 'graphql/types';
import useLazySearchableQuery from 'hooks/useLazySearchableQuery';

import { DEPARTMENT_USER_INCLUDED_ADMIN_OPTIONS } from '../ShopProperties/AdminTab/constants/constants';
import InviteToShopModal from './InviteUsersModal/InviteToShopModal';
import { ShopMembersProvider } from './ShopMembersContext';
import ShopMembersHeader from './ShopMembersHeader';
import ShopMembersTable from './ShopMembersTable/ShopMembersTable';

const headerOptions = [
  { title: 'ID', sortValue: 'userIdentifier' },
  { title: 'User', sortValue: 'userName' },
  { title: 'Email', sortValue: 'email' },
  { title: 'Role' },
  { title: 'Office Phone', sortValue: 'office' },
  { title: 'Mobile Phone', sortValue: 'mobilephone' },
  { title: 'Status', sortValue: 'status' },
  { title: 'EMPTY' },
];

const ShopMembers = () => {
  const { user } = useUser();
  const {
    selectedItem,
    shopDepartmentId: departmentId,
    isShopAdmin,
    isShopAdminInvitationCompleted,
  } = useFacilitiesProjects();
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [showDeletedUsers, setShowDeletedUsers] = useState(false);

  const [{ lazyLoad, paginationHandler, searchHandler, sortHandler }, { loading, data }] = useLazySearchableQuery(
    gql(DEPARTMENT_USERS),
    {
      extraVars: {
        departmentId,
        includeDeleted: showDeletedUsers ? INCLUDE_DELETED_OPTIONS.YES : null,
        includeAdmin: DEPARTMENT_USER_INCLUDED_ADMIN_OPTIONS.YES,
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  useEffect(() => {
    lazyLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (departmentId) lazyLoad(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentId, showDeletedUsers]);

  const shopMembers = useMemo(() => {
    if (!departmentId) return [];

    return data?.departmentUsers || [];
  }, [departmentId, data]);

  const message = useMemo(() => {
    if (selectedItem?.type !== 'FACILITY') {
      return 'Open a facility to view members...';
    }

    if (!departmentId) {
      return `You must create a Shop department within Facility: ${selectedItem?.label} to view members.`;
    }

    return 'No results';
  }, [selectedItem, departmentId]);

  const toggleInviteModal = () => setInviteModalOpen(!inviteModalOpen);

  const isInviteDisabled = useMemo(
    () => !departmentId || (!user?.isAdmin && !(isShopAdmin && isShopAdminInvitationCompleted)),
    [departmentId, user, isShopAdmin, isShopAdminInvitationCompleted],
  );

  const hasShopAdminPermission = useMemo(
    () => user?.isAdmin || (isShopAdmin && isShopAdminInvitationCompleted),
    [user, isShopAdmin, isShopAdminInvitationCompleted],
  );

  return (
    <ShopMembersProvider>
      <ShopMembersHeader
        handleSearch={searchHandler}
        openInviteModal={toggleInviteModal}
        disableInvite={isInviteDisabled}
        handleShowDeletedUsers={setShowDeletedUsers}
      />
      <ShopMembersTable
        loading={loading}
        shopMembers={shopMembers}
        headerOptions={headerOptions}
        handleSortOrder={sortHandler}
        paginationHandler={paginationHandler}
        emptyTableMessage={message}
        hasShopAdminPermission={hasShopAdminPermission}
      />
      <InviteToShopModal openStatus={inviteModalOpen} onClose={toggleInviteModal} departmentId={departmentId} />
    </ShopMembersProvider>
  );
};

export default ShopMembers;
