import React from 'react';

import { Box, Stack, Typography } from '@mui/material';

import { Custom } from 'components/Icons';
import NavigationHeader from 'modules/Shop/Fabrication/TaskViewer/components/NavigationHeader';

const ErrorDocument = () => (
  <Stack height="100%">
    <NavigationHeader />
    <Stack
      sx={{
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: '20%',
        '@media screen and ( max-height: 700px )': {
          paddingBottom: 'unset !important',
        },
      }}
    >
      <Box sx={{ textAlign: 'center', maxWidth: '402px' }}>
        <Box
          sx={{
            '& svg': { height: '42px', width: '46px' },
          }}
        >
          <Custom.Error />
        </Box>
        <Typography variant="h6" sx={{ color: 'surface.lightSurface.secondary', letterSpacing: '0.15px' }}>
          File failed to convert. <br />
          <a
            target="_blank"
            href="https://www.evolvemep.com/contact-support"
            style={{ textDecoration: 'none', color: '#4A93FF' }}
            rel="noreferrer"
          >
            Click here
          </a>{' '}
          to contact support.
        </Typography>
      </Box>
    </Stack>
  </Stack>
);

export default ErrorDocument;
