/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

import { Typography, Chip, Autocomplete, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

import { OutlinedWrapper } from './OutlinedWrapper';

const AutoCompleteChip = ({ label, option, index, value, onChange }) => (
  <Chip
    key={`${index}-${label}`}
    style={{ margin: 8 }}
    label={option.label || option}
    onDelete={() => {
      const valueCopy = value;
      valueCopy.splice(index, 1);
      onChange(valueCopy);
    }}
  />
);

const RenderedComponent = ({
  label,
  sx,
  error,
  color,
  value,
  options,
  onChange,
  placeholder,
  inputValue,
  setInputValue,
  ...restProps
}) => (
  <OutlinedWrapper label={label} sx={sx} error={error} color={color}>
    <Autocomplete
      onChange={(e, newSelectedOptions) => {
        onChange(newSelectedOptions);
        setInputValue('');
      }}
      onBlur={() => setInputValue((prev) => (prev !== '' ? '' : prev))}
      renderTags={() => null}
      multiple
      disablePortal
      autoHighlight
      options={options}
      isOptionEqualToValue={(option, value) => {
        if (option.id) {
          return option.id === value.id;
        }

        return option === value;
      }}
      sx={{ paddingTop: 0.5 }}
      value={value}
      inputValue={inputValue}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          onChange={(e) => setInputValue(e.target.value)}
          fullWidth
          color={color}
          {...restProps}
          sx={{
            mb: 1,
          }}
          placeholder={placeholder}
        />
      )}
    />
    {value?.map((option, index) => (
      <AutoCompleteChip
        label={label}
        option={option}
        index={index}
        value={value}
        onChange={onChange}
      />
    ))}
    {error && (
      <div>
        <Typography variant="caption" sx={{ color: 'red' }}>
          One or more entries are invalid
        </Typography>
      </div>
    )}
  </OutlinedWrapper>
);

const ControlledChipsAutoCompleteApi = ({ control, name, rules, defaultValue, ...props }) => (
  <Controller
    control={control}
    name={name}
    rules={rules}
    defaultValue={defaultValue}
    render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
      <RenderedComponent
        formState={formState}
        error={error}
        onChange={onChange}
        value={value}
        {...props}
      />
    )}
  />
);
export default ControlledChipsAutoCompleteApi;
