import React, { useEffect, useRef, useState } from 'react';

import { Stack, Typography, Divider, Tooltip } from '@mui/material';

import { checkOverflow } from 'helpers/checkOverflow';

import ItemTextField from './ItemTextField';

const ItemsToMove = ({ item }) => {
  const [overflowActive, setOverflowActive] = useState(false);

  const overflowingText = useRef();

  useEffect(() => {
    if (checkOverflow(overflowingText.current)) {
      setOverflowActive(true);
      return;
    }

    setOverflowActive(false);
  }, [overflowActive]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        key={item.LineItemId}
        alignItems="center"
        sx={{ minHeight: '32px', paddingLeft: '36px' }}
      >
        <ToolTipItemName overflowActive={overflowActive} name={item.name}>
          <Typography
            ref={overflowingText}
            variant="body2"
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              width: '67%',
            }}
          >
            {item.name}
          </Typography>
        </ToolTipItemName>
        <Stack
          direction="row"
          alignItems="center"
          width="196px"
          justifyContent="space-between"
          sx={{ paddingRight: '16px' }}
        >
          <Typography variant="body2"> {item.remaining} </Typography>
          <ItemTextField item={item} />
        </Stack>
      </Stack>
      <Divider />
    </>
  );
};

const ToolTipItemName = ({ children, overflowActive, name }) => (
  <Tooltip
    arrow
    title={
      overflowActive && (
        <Typography fontWeight="500" fontSize={10} lineHeight="16px">
          {name}
        </Typography>
      )
    }
    placement="top"
    PopperProps={{
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 10],
          },
        },
      ],
    }}
  >
    {children}
  </Tooltip>
);

export default ItemsToMove;
