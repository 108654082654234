import { useCallback } from 'react';

import { gql, useLazyQuery } from '@apollo/client';

import { userById as USER_BY_ID } from 'graphql/queries';
import { validateUserNameOrEmail } from 'helpers/userHelpers';

const useUserById = () => {
  const [, { refetch: fetchUserById }] = useLazyQuery(gql(USER_BY_ID), {
    fetchPolicy: 'cache-first',
  });

  const getUserById = useCallback(
    async (userId: string) => {
      const { data } = await fetchUserById({ params: { id: userId } });

      if (!data?.userById) return { completeName: '' };

      return {
        completeName: validateUserNameOrEmail(data.userById),
      };
    },
    [fetchUserById],
  );

  return { getUserById };
};

export default useUserById;
