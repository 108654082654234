import React, { useState } from 'react';

import { Link, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Navigate } from 'react-router-dom';

import { DeviceSizes } from 'constants/globalConstants';
import useDeviceSize from 'hooks/useDeviceSize';

import ResetPasswordBackground from '../assets/images/backgrounds/reset-password-bg.png';
import NoLicensesPageHeader from './Layout/HeaderBar/components/NoLicenses/NoLicensesPageHeader';
import ResetPasswordModal from './Layout/HeaderBar/ProfileDropdown/ResetPasswordModal/ResetPasswordModal';
import { useUser } from './UserContext';

const ResetPasswordLandingPage = ({ noLicense }) => {
  const { user, loading } = useUser();
  const { deviceSize } = useDeviceSize();
  const [openModal, setOpenModal] = useState(false);

  if (!loading && !user) return <Navigate to="/auth/login" />;

  return (
    <PageWrapper deviceSize={deviceSize}>
      <NoLicensesPageHeader />
      <PageBackground />
      <PageContent>
        {noLicense ? (
          <>
            <Typography variant="h3" color="#10143D" gutterBottom sx={{ textAlign: 'center' }}>
              {`You don't have a license.`}
            </Typography>
            <Typography variant="body1" color="#10143D" gutterBottom sx={{ textAlign: 'center' }}>
              Hi there, it seems you do not have a license. Please request one from your company admin to gain access.
            </Typography>
          </>
        ) : (
          <Typography variant="h3" color="#10143D" gutterBottom sx={{ textAlign: 'center' }}>
            <Link underline="none" style={{ cursor: 'pointer', color: '#4A93FF' }} onClick={() => setOpenModal(true)}>
              Click here
            </Link>{' '}
            to reset your password
          </Typography>
        )}
      </PageContent>
      {openModal && <ResetPasswordModal open={openModal} onClose={() => setOpenModal(false)} />}
    </PageWrapper>
  );
};

export default ResetPasswordLandingPage;

const PageWrapper = ({ deviceSize, children }) => (
  <Stack
    sx={{
      height: '100vh',
      width: 1,
      position: [DeviceSizes.tablet, DeviceSizes.mobile].includes(deviceSize) ? 'fixed' : 'inherit',
      left: 0,
    }}
  >
    {children}
  </Stack>
);

const PageBackground = () => <img src={ResetPasswordBackground} alt="Password reset" height="100%" />;

const PageContent = ({ children }) => {
  const contentStyles = {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  };

  return (
    <Stack alignItems="center" width={1} sx={contentStyles}>
      {children}
    </Stack>
  );
};
