/* eslint-disable max-lines-per-function */
import React, { useEffect } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { Custom } from 'components/Icons';
import { ProjectsTable } from 'components/ProjectsTable/ProjectsTable';

const BillOfMaterials = () => {
  const { selectedItem } = useFacilitiesProjects();
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedItem?.type === 'PROJECT') navigate(selectedItem.id);
  }, [selectedItem, navigate]);

  return (
    <Stack sx={{ flex: 1, pb: 2 }}>
      <Box marginTop="4px" marginBottom="16px">
        <Typography variant="h5" component="h1">
          Bill of Materials
        </Typography>
      </Box>
      <Stack sx={{ flex: 1 }}>
        <ProjectsTable
          icon={<Custom.BillOfMaterials />}
          onProjectClick={(project) => {
            navigate(project.id);
          }}
        />
      </Stack>
    </Stack>
  );
};

export default BillOfMaterials;
