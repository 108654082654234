import React, { useState } from 'react';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';

import DepartmentUsersDeleteModal from 'components/DepartmentUsersDeleteModal';

import { useDesignMembersContext } from './DesignMembersContext';

const DesignMembersActions = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { selected, setSelected } = useDesignMembersContext();

  const selectedMemberIds = selected.map((user) => user.departmentUserId);

  return (
    <Box>
      <MenuItem onClick={() => setShowDeleteModal(true)} disabled={selected.length < 1}>
        Delete
      </MenuItem>
      <DepartmentUsersDeleteModal
        departmentUserIds={selectedMemberIds}
        open={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
          setSelected([]);
        }}
      />
    </Box>
  );
};

export default DesignMembersActions;
