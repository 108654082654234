import React, { ReactElement } from 'react';

import { SxProps, Typography } from '@mui/material';

type PartItemDescriptionProps = {
  children: ReactElement | string;
  sx?: SxProps;
};
const PartItemDescription = ({ children, sx }: PartItemDescriptionProps) => (
  <Typography
    sx={{
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '20px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      wordWrap: 'break-word',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
      display: '-webkit-box',
      typography: 'body2',
      color: 'gray',
      ...sx,
    }}
  >
    {children}
  </Typography>
);

export default PartItemDescription;
