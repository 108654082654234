/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { Box, Checkbox } from '@mui/material';

import CollapseChildrenIcon from 'modules/Field/LocationsAndWorkPhases/components/CollapseChildrenIcon';

import { useCustomAssembliesContext } from '../CustomAssembliesContext';

export const CustomAssemblyFlag = ({ params }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [, setIsLoading] = useState(false);
  const {
    getCustomAssemblies,
    loadingCustomAssemblies,
    setCustomAssemblyChildren,
    deleteCustomAssemblyChildren,
    setLoadingChildren,
  } = useCustomAssembliesContext();

  const handleClick = () => {
    if (loadingCustomAssemblies) return;
    if (isOpen) {
      deleteCustomAssemblyChildren(params.row.id);
      setIsOpen((prev) => !prev);
    } else {
      const assemblyCustomCategoryId = params.row.id !== 1 ? params.row.id : undefined;
      getCustomAssemblies(assemblyCustomCategoryId, setCustomAssemblyChildren);
      setIsLoading(true);
      setLoadingChildren(params.row.id);
    }
  };

  useEffect(() => {
    if (params.row?.children?.length) {
      setIsLoading(false);
      setIsOpen((prev) => !prev);
    }
  }, [params.row?.children]);

  return params.row.type !== 'Loading' ? (
    <>
      {params.row.type === 'CustomAssemblyType' ? (
        <Box sx={{ paddingLeft: '20px' }}>
          <CollapseChildrenIcon open={isOpen} showIcon filled sx={{ minWidth: 14 }} onClick={handleClick} />
        </Box>
      ) : (
        <Checkbox />
      )}
    </>
  ) : (
    <></>
  );
};
