import React from 'react';

import InputSelectField from 'components/FormComponents/InputSelectField';

const DropDown = ({ label, options, noOptionsText, ...rest }) => (
  <InputSelectField
    color="secondary"
    size="small"
    label={label}
    fullWidth
    noOptionsText={noOptionsText}
    options={options}
    {...rest}
  />
);

export default DropDown;
