/* eslint-disable import/no-cycle */
import { useEffect } from 'react';

import { useCurrentProjectContext } from '../context/CurrentProjectContext';

const useModalEnableDone = ({
  setAddNewWRForm,
  setActiveStep,
  setRows,
  isLoading,
  setEnableDone,
  enableDone,
  qtyStepMethods,
  completed,
  workRequests,
  getWorkRequests,
  getWorkRequestsQuery,
}: any) => {
  const { currentProject } = useCurrentProjectContext();
  const { isDirty } = qtyStepMethods;

  useEffect(() => {
    if (isLoading) setEnableDone(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (isDirty && isLoading && enableDone) setEnableDone(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableDone, isLoading, isDirty]);

  useEffect(() => {
    if (!currentProject) return;
    getWorkRequests({
      take: 50,
      ...getWorkRequestsQuery,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject]);

  useEffect(() => {
    if (!workRequests?.length) return;
    setRows(
      [...workRequests].map(({ workRequestId, workRequestIdentifier, workRequestName, needBy }) => ({
        workRequestName,
        needBy,
        workRequestIdentifier,
        workRequestId,
      })),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workRequests]);

  useEffect(() => {
    if (!completed[0]) return;
    setAddNewWRForm(false);
    setActiveStep(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completed]);
};

export default useModalEnableDone;
