import React from 'react';

import { useTheme, Link } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { Custom } from 'components/Icons';
import useIconColorForDataGridTable from 'components/ItemsDatagridPro/useIconColorForDataGridTable';
import OverflowTypography from 'components/Tooltip/OverflowTypography';

import { useWorkRequestsContext, WORK_REQUEST_TABLES } from '../../WorkRequestsContext';

export const CellComponentLinkResponsive = ({ value, row }) => {
  const { palette } = useTheme();
  const { iconColor } = useIconColorForDataGridTable({ id: row.workRequestId });

  const { setCurrentTable } = useWorkRequestsContext();

  const switchTable = () => {
    setCurrentTable(WORK_REQUEST_TABLES.WORK_REQUEST_ITEMS_LIST);
  };

  return (
    <Wrapper>
      <Link className="link" onClick={switchTable} sx={{ cursor: 'pointer' }}>
        <Stack direction="row">
          <Box sx={{ mr: '6px', mt: '6px', width: '20px' }}>
            <Custom.WorkRequests style={{ fill: iconColor }} />
          </Box>
          <OverflowTypography
            title={value}
            variant="body2"
            TypographyProps={{
              width: 'calc(100% - 20px)',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              variant: 'tableCell',
              color: palette.secondary.main,
            }}
          />
        </Stack>
      </Link>
    </Wrapper>
  );
};

const Wrapper = (props) => (
  <Box
    sx={{
      width: '100%',
      '& .link': {
        textDecoration: 'none',
        color: 'surface.lightSurface.primary',
        '&:hover': {
          color: 'secondary.main',
          textDecoration: 'underline',
        },
      },
    }}
    {...props}
  />
);
