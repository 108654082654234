import React, { useState, useEffect, useCallback } from 'react';

import { ForesiteTextField } from '@evolvemep/foresite-components-ui-react';
import { useController } from 'react-hook-form';

import { useUser } from 'app/UserContext';
import { requiredRule } from 'constants/inputFieldRules';

import { useCatalogSetupContext } from '../../CatalogSetupHeader/CatalogSetupContext';
import { useCategoryListSearcherQuery } from '../CategoriesList/useCategoryListSearcherQuery';

const CategoryName = ({
  control,
  name,
  handleBlur,
  parentPartCategoryId,
  defaultValue = '',
  setError,
  onClose,
  ...restProps
}) => {
  const [searchText, setSearchText] = useState(defaultValue);
  const [searchTextDebounced, setSearchTextDebounced] = useState(searchText);
  const {
    user: { companyId },
  } = useUser();
  const {
    selectedCatalog: { partCatalogId },
  } = useCatalogSetupContext();

  const { fetchSearchCategories, existingCategories } = useCategoryListSearcherQuery();

  useEffect(() => {
    const timer = setTimeout(() => setSearchText(searchTextDebounced), 100);
    return () => clearTimeout(timer);
  }, [searchTextDebounced]);

  useEffect(() => {
    fetchSearchCategories({
      query: {
        name: `eq:${searchText.trim()}`,
        companyId,
        partCatalogId,
        parentPartCategoryId,
        take: 1,
      },
    });
  }, [searchText, partCatalogId, parentPartCategoryId, companyId, fetchSearchCategories]);

  const {
    field: { onChange, ref, value },
    fieldState: { error },
    formState: { isSubmitting, errors },
  } = useController({
    name,
    control,
    rules: {
      ...requiredRule('Category name'),
    },
    defaultValue,
  });

  const validateCategoryExists = useCallback(() => {
    if (existingCategories?.partCategory?.length > 0)
      setError(name, { type: 'custom', message: 'Category name already exists.' });
  }, [existingCategories?.partCategory?.length, name, setError]);

  useEffect(() => {
    const isEqualToInitialValue =
      defaultValue && value.toLowerCase().trim() === defaultValue.toLowerCase().trim();

    if (isEqualToInitialValue) return;

    validateCategoryExists();
  }, [setError, existingCategories, name, defaultValue, value, validateCategoryExists]);

  return (
    <ForesiteTextField
      maxLength={32}
      onChange={(e) => {
        onChange(e);
        setSearchTextDebounced(e.target.value.trim());
      }}
      onFocus={validateCategoryExists}
      onKeyDown={(e) => {
        if (e.key === 'Enter') handleBlur(e);
        if (e.key === 'Escape') onClose();
      }}
      value={value}
      name={name}
      inputRef={ref}
      disabled={isSubmitting}
      sx={{
        width: '100%',
        fontSize: '12px',
        paddingRight: 2,
        '& .MuiOutlinedInput-input': { backgroundColor: 'white' },
      }}
      error={!!error}
      helperText={errors?.categoryName ? errors.categoryName.message : ''}
      size="small"
      InputProps={{ style: { fontSize: 14 }, 'data-testid': 'category-form-name-input' }}
      autoComplete="off"
      {...restProps}
    />
  );
};

export default CategoryName;
