import React, { useMemo } from 'react';

import Stack from '@mui/material/Stack';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import { CreateButton } from 'components/Buttons';
import { VARIANT_ERROR } from 'constants/snackbarConstants';

import WorkOrderSpinner from '../WorkOrderSpinner';
import { WorkOrderCancelModalProvider } from './context/WorkOrderCancelModalContext';
import { useWorkOrderItemsContext } from './context/WorkOrderItemsContext';
import WorkOrderCancel from './WorkOrderCancel';

const TIME_TO_NAVIGATE = 1000;

const WorkOrderTitleButtons = ({
  updateWorkOrder,
  workOrderStatusType,
  workOrderStatus,
  workOrderId,
  isProcessingWorkOrder,
  setIsLeavingDialog,
  isLeavingDialog,
}) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar() || {};
  const { inForgeView } = useWorkOrderItemsContext();

  const disabledReleaseButton = useMemo(
    () => ['Not Started', 'In Progress', 'Completed'].includes(workOrderStatus) || isProcessingWorkOrder,
    [isProcessingWorkOrder, workOrderStatus],
  );

  const disabledCancelButton = useMemo(
    () => !['Not Started', 'In Progress'].includes(workOrderStatus) || isProcessingWorkOrder,
    [isProcessingWorkOrder, workOrderStatus],
  );

  const successCallbackRelease = (data) => {
    setIsLeavingDialog(false);
    const stepFunctionProcessingMessage = data?.updateWorkOrder?.stepFunctionProcessingMessage;
    if (stepFunctionProcessingMessage) {
      enqueueSnackbar(stepFunctionProcessingMessage, { autoHideDuration: 2500, ...VARIANT_ERROR });
    }
    if (!isLeavingDialog) {
      setTimeout(() => {
        navigate('/shop/work-orders');
      }, TIME_TO_NAVIGATE);
    }
  };

  const releaseWorkOrder = async () => {
    setIsLeavingDialog(true);
    await updateWorkOrder({
      workOrderId,
      body: {
        workOrderStatusTypeId: workOrderStatusType.find((workOrder) => workOrder.label === 'Not Started').value,
      },
      successCallback: successCallbackRelease,
    });
  };

  if (inForgeView) return null;

  return (
    <>
      <Stack direction="row" sx={{ mt: '4px', height: '32px' }}>
        {isProcessingWorkOrder && <WorkOrderSpinner />}
        <WorkOrderCancelModalProvider>
          <WorkOrderCancel workOrderStatus={workOrderStatus} disabled={disabledCancelButton} />
        </WorkOrderCancelModalProvider>

        <CreateButton
          buttonText="Release"
          sx={{
            width: '87px',
            minHeight: '32px',
            fontWeight: 500,
            '&:hover': {
              backgroundColor: 'secondary.containedHoverBackground',
              color: 'surface.darkSurface.primary',
              border: 'none',
              boxShadow: 'none',
            },
          }}
          onClick={releaseWorkOrder}
          disabled={disabledReleaseButton}
          data-cy="release-button"
        />
      </Stack>
    </>
  );
};

export default WorkOrderTitleButtons;
