import React, { FC } from 'react';

import DropdownButton from './DropdownButton';
import ItemCounter from './ItemCounter';

const DropdownActions: FC = () => (
  <>
    <DropdownButton />
    <ItemCounter />
  </>
);

export default DropdownActions;
