import React from 'react';

import { Box, IconButton, Stack } from '@mui/material';
import { Draggable } from 'react-beautiful-dnd';
import ReactDOM from 'react-dom';

import { Custom } from 'components/Icons';
import { useActiveRow } from 'modules/Field/LocationsAndWorkPhases/contexts/ActiveRowContext';

import { disabledStyles, highlightStyles } from '../../../utils/styles';
import LocationRowInfo from '../LocationRowInfo';
import RowInfo from '../WorkPhaseRowInfo';

const dndPortal = document.getElementById('dnd-portal');

const WorkPhaseDraggableRow = ({
  index,
  disappearRow,
  draggableId,
  workPhase,
  disableDragFunction,
}) => {
  const { toggleSelection, toggleSelectionInGroup, multiSelectTo, activeRows } = useActiveRow();

  const wasClickedWithCtrl = (event) => {
    const isUsingWindows = navigator.platform.indexOf('Win') >= 0;
    return isUsingWindows ? event.ctrlKey : event.metaKey;
  };

  const wasClickedWithShift = (event) => event.shiftKey;

  const performAction = (event) => {
    if (wasClickedWithCtrl(event)) return toggleSelectionInGroup(workPhase);
    if (wasClickedWithShift(event)) return multiSelectTo(workPhase);
    return toggleSelection(workPhase);
  };

  const onClick = (event) => {
    if (event.defaultPrevented) return;
    if (event.button !== 0) return;

    event.preventDefault();
    performAction(event);
  };

  return (
    <Draggable draggableId={draggableId} index={index} isDragDisabled={disableDragFunction}>
      {(provided, snapshot) => {
        if (disappearRow && !snapshot.isDragging)
          return (
            <Stack
              ref={provided.innerRef}
              {...provided.dragHandleProps}
              sx={{ minHeight: '32px' }}
            />
          );

        const getStyles = (() => {
          if (disableDragFunction) return disabledStyles;
          if (snapshot.isDragging) return highlightStyles;
          return {};
        })();

        const MainContent = (
          <StyledItem
            {...provided.draggableProps}
            ref={provided.innerRef}
            onClick={onClick}
            sx={{ position: 'relative' }}
          >
            <DraggableIcon {...provided.dragHandleProps} />
            <RowInfo node={workPhase} depth={2} sx={getStyles} />
            {snapshot.isDragging && (
              <Box>
                {activeRows
                  ?.filter((row) => row.id !== workPhase.id && row.label)
                  .map((row) => (
                    <StyledItem key={row.id}>
                      <DraggableIcon />
                      <RowInfo node={row} depth={2} sx={highlightStyles} />
                    </StyledItem>
                  ))}
              </Box>
            )}
          </StyledItem>
        );

        const LocationsList = (
          <>
            {workPhase.isOpen &&
              workPhase.children?.map((location) => (
                <LocationRowInfo
                  key={`Location-list-${workPhase.id}-${location.locationId}`}
                  node={location}
                  depth={3}
                  sx={getStyles}
                  workPhaseParent={workPhase}
                />
              ))}
          </>
        );

        return snapshot.isDragging ? (
          ReactDOM.createPortal(MainContent, dndPortal)
        ) : (
          <>
            {MainContent}
            {LocationsList}
          </>
        );
      }}
    </Draggable>
  );
};

export default WorkPhaseDraggableRow;

const StyledItem = React.forwardRef(({ children, sx, ...props }, ref) => (
  <Stack
    ref={ref}
    sx={{
      backgroundColor: 'white',
      '& > .MuiIconButton-root': {
        opacity: 0,
      },
      '&:hover > .MuiIconButton-root': {
        opacity: 1,
      },
      ...sx,
    }}
    {...props}
  >
    {children}
  </Stack>
));

const DraggableIcon = ({ ...props }) => (
  <IconButton size="small" sx={{ cursor: 'move', position: 'absolute', mt: 0.4 }} {...props}>
    <Custom.Drag style={{ margin: '4px' }} />
  </IconButton>
);
