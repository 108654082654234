import { useContext } from 'react';

import { BOMbyIdContext } from 'modules/Materials/BillOfMaterialsById/BOMbyIdContext';

const useGetBOMLineItemsAdded = () => {
  const BOMbyIdLineItemsContext = useContext(BOMbyIdContext);
  if (!BOMbyIdLineItemsContext) return null;

  const { decisionsStatesAndCallbacks, onAddWriteInItems, refetchItems, chipList, assignModeOn } =
    BOMbyIdLineItemsContext;

  return {
    onAddWriteInItems,
    refetchCatalogItems: refetchItems,
    chipList,
    assignModeOn,
    ...decisionsStatesAndCallbacks,
  };
};

export default useGetBOMLineItemsAdded;
