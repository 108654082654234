import React, { useState } from 'react';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';

import AdminDeleteModal from '../AdminDeleteModal';

const MoreTableRowOptions = ({ user = {}, hasDeleteOptionDisabled = false }) => {
  const [openDelete, setOpenDelete] = useState(false);

  const handleOpen = (event) => {
    event.stopPropagation();
    setOpenDelete(true);
  };

  return (
    <Box>
      <MenuItem disabled={hasDeleteOptionDisabled} onClick={handleOpen}>
        Delete
      </MenuItem>
      {openDelete && (
        <AdminDeleteModal
          userIds={[user.userId]}
          openStatus={openDelete}
          onClose={() => setOpenDelete(false)}
        />
      )}
    </Box>
  );
};

export default MoreTableRowOptions;
