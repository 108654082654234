import React, { FC } from 'react';

import { Custom } from 'components/Icons';
import AttachmentType from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestListFlyoutMenu/WorkRequestAttachments/AttachmentType';

import { ICustomAttachmentType } from '../types/attachmentType';

const TasksAttachType: FC<ICustomAttachmentType> = (props: ICustomAttachmentType) => (
  <AttachmentType label="Tasks" icon={<Custom.TaskDetails />} {...props} />
);

export default TasksAttachType;
