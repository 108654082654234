import React, { useEffect, useState } from 'react';

import { ForesiteTextField } from '@evolvemep/foresite-components-ui-react';
import { useController, useFormContext } from 'react-hook-form';

import { useCatalogSelectedPartContext } from 'modules/Materials/CatalogSetup/Providers/CatalogSelectedPartProvider';
import { usePartAPIContext } from 'modules/Materials/CatalogSetup/Providers/PartAPIProvider';

const PartNameTextField = ({ name, sx, ...restProps }) => {
  const { control, setError, defaultValues, disabled, clearErrors } = useFormContext();
  const [searchPartName, setSearchPartName] = useState(null);
  const [defaultValue, setDefaultValue] = useState('');
  const [searchPartNameDebounced, setSearchPartNameDebounced] = useState(null);
  const { searchPart, foundPart } = usePartAPIContext();
  const { selectedPart } = useCatalogSelectedPartContext();

  useEffect(() => {
    const timer = setTimeout(() => setSearchPartName(searchPartNameDebounced), 300);
    return () => clearTimeout(timer);
  }, [searchPartNameDebounced]);

  useEffect(() => {
    if (!foundPart?.parts?.length) {
      clearErrors('partName');
      return;
    }

    const isPartNameDuplicated = Boolean(foundPart.parts.length);
    const isSelectedPart = foundPart.parts[0].partId === selectedPart?.partId;
    if (isPartNameDuplicated && !isSelectedPart) {
      setError(name, { message: 'Part Name already exists' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foundPart, setError, name, selectedPart]);

  useEffect(() => {
    if (!searchPartName) return;
    searchPart({
      variables: {
        query: {
          name: `eq:${encodeURIComponent(searchPartName.trim())}`,
          take: 1,
        },
      },
    });
  }, [searchPartName, searchPart]);

  const {
    field: { onChange, onBlur, ref, value },
    fieldState: { error },
    formState: { isSubmitting },
  } = useController({
    name,
    control,
    rules: {
      required: { value: true, message: 'Part Name is required' },
      maxLength: { value: 128, message: 'Part name must be 128 characters or less' },
      validate: (value) => {
        const isEqualToInitialValue =
          defaultValues?.partName &&
          value.toLowerCase().trim() === defaultValues?.partName.toLowerCase().trim();

        if (isEqualToInitialValue) return null;

        return true;
      },
    },
  });

  useEffect(() => {
    setDefaultValue(defaultValues?.partName || '');
  }, [defaultValues]);

  return (
    <ForesiteTextField
      onChange={(e) => {
        onChange(e);
        setSearchPartNameDebounced(e.target.value);
      }}
      onBlur={onBlur}
      defaultValue={defaultValue}
      value={value}
      disabled={isSubmitting || disabled}
      name={name}
      inputRef={ref}
      sx={{ width: '100%', ...sx }}
      error={!!error}
      helperText={error?.message}
      label="Generic Part Name"
      size="small"
      maxLength={128}
      inputProps={{ 'data-testid': 'part-name-input' }}
      {...restProps}
    />
  );
};

export default PartNameTextField;
