import React, { useState } from 'react';

import ProjectInviteModal from './ProjectInviteModal/ProjectInviteModal';
import { ProjectMembersProvider } from './ProjectMembersContext';
import ProjectMembersHeader from './ProjectMembersHeader';
import ProjectMembersTable from './ProjectMembersTable/ProjectMembersTable';

const headerOptions = [
  { title: 'ID', sortValue: 'userIdentifier' },
  { title: 'User', sortValue: 'name' },
  { title: 'Email', sortValue: 'email' },
  { title: 'Role' },
  { title: 'Office Phone' },
  { title: 'Mobile Phone' },
  { title: 'Status', sortValue: 'status' },
  { title: 'EMPTY' },
];

const ProjectMembers = () => {
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const toggleInviteModal = () => setInviteModalOpen(!inviteModalOpen);

  return (
    <ProjectMembersProvider>
      <ProjectMembersHeader openInviteModal={toggleInviteModal} />
      <ProjectMembersTable headerOptions={headerOptions} />
      <ProjectInviteModal openStatus={inviteModalOpen} onClose={toggleInviteModal} />
    </ProjectMembersProvider>
  );
};

export default ProjectMembers;
