import React from 'react';

import Stack from '@mui/material/Stack';

import { findNode } from 'helpers/arrayFunctions';
import { ROW_TYPES } from 'modules/Field/LocationsAndWorkPhases/constants/constants';
import { triggerFirstInvalidInput } from 'modules/Field/LocationsAndWorkPhases/Locations/helpers/formHelpers';

import { useChips } from '../../../CreateLocationsModalContext';

const StyledChipRow = ({ chip, children, trigger, formValues }) => {
  const {
    chips,
    activeChip,
    setActiveChip,
    formIsValid,
    hasNotWorkPhases,
    activeStep,
    activeChipInStep2,
    setActiveChipInStep2,
    goToStep,
  } = useChips();

  const activeId = activeChipInStep2.id || activeChip.id;
  const isActive = chip.id === activeId;

  const activeChipForm = findNode(activeId, chips);

  const backgroundColor = (() => {
    if (chip.isDraft && isActive) return 'action.lightSurface.hover';
    if (isActive) return 'action.lightSurface.selected';
    return 'white';
  })();

  return (
    <Stack
      direction="row"
      alignItems="center"
      onClick={() => {
        if (chip.id === activeChipForm.id) return null;

        if (!formIsValid || hasNotWorkPhases)
          return triggerFirstInvalidInput(activeChipForm.locationTypeName, formValues, trigger);

        if (activeStep === 1 && chip.locationTypeName !== ROW_TYPES.WORK_PHASE)
          return setActiveChipInStep2(chip);

        if (activeStep === 1) {
          setActiveChipInStep2({});
          return setActiveChip(chip);
        }

        setActiveChipInStep2({});
        setActiveChip(chip);

        if (chip.locationTypeName === ROW_TYPES.WORK_PHASE) goToStep(1);
        return null;
      }}
      sx={{
        minHeight: 32,
        cursor: 'pointer',
        backgroundColor,
        '&:hover': {
          backgroundColor: 'action.lightSurface.hover',
        },
      }}
    >
      {children}
    </Stack>
  );
};

export default StyledChipRow;
