import React from 'react';

import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';

import AddSourceForm from './AddSourceForm';
import MessageTypeForm from './MessageTypeForm';
import RecipientsForm from './RecipientsForm';

const MessageStepperForm = ({ activeStep, steps, body, control, recipientType }) => {
  const stepComponents = {
    0: <MessageTypeForm control={control} />,
    1: <AddSourceForm control={control} body={body} />,
    2: <RecipientsForm control={control} recipientType={recipientType} />,
  };

  return (
    <Stack>
      <StyledStepper steps={steps} activeStep={activeStep} />
      {stepComponents[activeStep]}
    </Stack>
  );
};

export default MessageStepperForm;

const StyledStepper = ({ steps, activeStep }) => (
  <Stepper activeStep={activeStep}>
    {steps.map((label) => (
      <Step
        key={label}
        sx={{
          '& .MuiStepIcon-root': {
            '&.Mui-active': {
              color: 'status.pending',
            },
            '&.Mui-completed': {
              color: 'status.active',
            },
          },
        }}
      >
        <StepLabel>{label}</StepLabel>
      </Step>
    ))}
  </Stepper>
);
