import useFetchTaskStatusTypes from 'hooks-api/useFetchTaskStatusTypes';
import { useWorkCellTaskMultiSearch } from 'hooks-api/useWorkCellTaskMultiSearch';

export const useWorkCells = (workRequestId) => {
  const { taskStatusTypes } = useFetchTaskStatusTypes();
  const inProgressStatusTypes = taskStatusTypes
    ?.filter((status) => ['Started', 'Paused'].includes(status.label))
    ?.map((status) => status.value)
    ?.join();

  const { tasksData, loadingTasksData, refetchTaskData } = useWorkCellTaskMultiSearch({
    workRequestId,
    taskStatusTypeIds: inProgressStatusTypes,
  });

  return {
    inProgressTasks: tasksData?.workCellTasksMultiSearch,
    loadingTasksData,
    refetchTaskData,
  };
};
