import { gql, useLazyQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { VARIANT_ERROR } from 'constants/snackbarConstants';
import { locations as LOCATIONS, summarizeLocationPhases as SUMMARIZE } from 'graphql/queries';
import { clone } from 'helpers/arrayFunctions';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

export default function useProjectDataLayer() {
  const { enqueueSnackbar } = useSnackbar();
  const { handleResponse } = useGraphqlResponseHandler();

  const [, { loading: loadingProjectLocations, refetch }] = useLazyQuery(gql(LOCATIONS), {
    fetchPolicy: 'cache-and-network',
  });

  const [summarizeQuery] = useLazyQuery(gql(SUMMARIZE), {
    fetchPolicy: 'cache-and-network',
  });

  const fetchProjectLocations = async (project, setLocations = () => {}) => {
    const { locationId: projectId } = project;

    const query = { projectId, orderBy: 'sortOrder:asce' };
    const response = await refetch({ query });

    const fetchedLocations = response?.data?.locations;
    if (fetchedLocations === null) {
      enqueueSnackbar('Error fetching locations', VARIANT_ERROR);
    } else {
      const projectWithChildren = { ...project, children: clone(fetchedLocations), isOpen: true };
      setLocations(projectWithChildren);
    }
  };

  const fetchSummarize = (project, setResume) => {
    const { locationId: projectId } = project;
    handleResponse(summarizeQuery, { variables: { query: { projectId } } }, { successMessage: '' }, (response) => {
      setResume(response?.summarizeLocationPhases);
    });
  };

  return { fetchProjectLocations, loadingProjectLocations, fetchSummarize };
}
