import React from 'react';

import ControlledChipsAutoComplete from 'components/FormComponents/ControlledChipsAutoComplete';

const CompanyInviteForm = ({ control, loading }) => (
  <ControlledChipsAutoComplete
    control={control}
    name="newCompanyMembers"
    rules={{ required: true }}
    label="Email"
    defaultValue={[]}
    placeholder="add email"
    isDisabled={loading}
  />
);

export default CompanyInviteForm;
