import React from 'react';

import { Box, Typography, useTheme } from '@mui/material';

import { Custom } from 'components/Icons';
import { actionColors } from 'styles/theme/colors';

const WorkRequestAttachmentsCount = ({ count, handleClick, disabled }) => {
  const theme = useTheme();
  return (
    <Box
      onClick={handleClick}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '43px',
        height: '26px',
        gap: '6px',
        borderRadius: '3px',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: actionColors.lightSurface.selected,
        },
        path: {
          fill: disabled ? theme.palette.action.lightSurface?.active : theme.palette.secondary.main,
        },
        pointerEvents: disabled ? 'none' : '',
      }}
    >
      <Custom.AttachFile />
      <Typography variant="body2">{count}</Typography>
    </Box>
  );
};

export default WorkRequestAttachmentsCount;
