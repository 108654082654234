import useLocationQuery from 'modules/Materials/commons/FilterPopoverContent/Breakdown/LocationsOption/useLocationQuery';

const useGetLocations = (locationId = null) => {
  const {
    fetchLocations,
    paginationHandler,
    locations,
    loading: locationLoading,
    called: wasCalled,
  } = useLocationQuery(locationId, 10);

  const handleScroll = () => paginationHandler(locations?.length);

  return {
    handleScroll,
    fetchLocations,
    locations,
    locationLoading,
    wasCalled,
  };
};

export default useGetLocations;
