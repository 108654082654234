import React from 'react';

import { Stack, Button, CircularProgress } from '@mui/material';

const ModalFooter = ({ onClose, onSubmit, isValid, isLoading }) => (
  <Stack
    spacing={2}
    direction="row"
    justifyContent="right"
    alignItems="center"
    sx={{ margin: '16px 8px 16px 8px ' }}
  >
    {isLoading && <CircularProgress size={25} color="secondary" />}
    <Button disabled={!isValid || isLoading} color="secondary" variant="outlined" onClick={onClose}>
      CANCEL
    </Button>
    <Button
      disabled={!isValid || isLoading}
      color="secondary"
      variant="contained"
      onClick={onSubmit}
    >
      PUBLISH
    </Button>
  </Stack>
);

export default ModalFooter;
