import dayjs from 'dayjs';

import { recursiveSort } from 'helpers/arrayFunctions';

import { CONFIG_COLUMNS, GROUPED_VALUES, SEPARATED_VALUES } from './constants';

// columns
type FilterValueType = {
  disabled: boolean;
  label: string;
  value: string;
};

export interface FilterType {
  column: string;
  values: Array<FilterValueType> | string;
}

export interface DisplayFilterType {
  id: string;
  column: string;
  value: string;
  label: string;
}

export interface FilterOptionType {
  disabled: boolean;
  label: string;
  value: string;
  options: Array<FilterValueType>;
}

const formatDate = (date: string): string => dayjs(date).format('MM/DD/YYYY');

export const formatDateFilter = (filters: Array<FilterType>): Array<DisplayFilterType> => {
  const datesValue = filters
    .filter((filter) => filter.column === 'dateFrom' || filter.column === 'dateTo')
    .map((filter) => formatDate(filter.values.toString()))
    .join(' - ');

  if (datesValue) {
    return [{ id: 'date', column: 'date', value: datesValue, label: datesValue }];
  }
  return [];
};

export const convertToDisplayFilter = (filter: FilterType): Array<DisplayFilterType> => {
  const columnConfig = CONFIG_COLUMNS[filter.column];
  if (!columnConfig) return [];

  if (columnConfig.chipType === GROUPED_VALUES) {
    return [
      {
        id: filter.column,
        column: filter.column,
        value: Array.isArray(filter.values) ? filter.values.map((val) => val.value).join(', ') : '',
        label: Array.isArray(filter.values) ? filter.values?.map((val) => val.label).join(', ') : '',
      },
    ];
  }

  if (columnConfig.chipType === SEPARATED_VALUES) {
    return Array.isArray(filter.values)
      ? filter.values.map((filterValue, index) => ({
          id: `${filter.column}-${index}`,
          column: filter.column,
          value: filterValue.value,
          label: filterValue.label,
        }))
      : [];
  }

  return [];
};

// Return the display filter array, it's used on chips
export const formatFiltersToDisplay = (filters: Array<FilterType>): Array<DisplayFilterType> => {
  const dateFilter = formatDateFilter(filters);

  const filterItems = filters.reduce<Array<DisplayFilterType>>((arr, filter) => {
    if (filter.column === 'dateFrom' || filter.column === 'dateTo') {
      return arr;
    }
    arr = [...arr, ...convertToDisplayFilter(filter)];
    return arr;
  }, []);

  return [...dateFilter, ...filterItems];
};

// Add value to the existing column on filters
function addValueToColumn(
  column: FilterOptionType,
  { optionValue, optionLabel }: { optionValue: string; optionLabel: string },
) {
  const newColumn = { ...column };
  const index = newColumn.options.findIndex((workOrder) => workOrder.value === optionValue);

  if (index === -1) {
    column.options.push({
      disabled: false,
      value: optionValue,
      label: optionLabel,
    });
  }
}

// Add new column that doesn't exist on filters array
function addColumn(
  filters: Array<FilterOptionType>,
  name: string,
  label: string,
  { optionValue, optionLabel }: { optionValue: string; optionLabel: string },
): Array<FilterOptionType> {
  return [
    ...filters,
    {
      disabled: false,
      value: name,
      label,
      options: [
        {
          disabled: false,
          value: optionValue,
          label: optionLabel,
        },
      ],
    },
  ];
}

export function addFilterOption(
  name: string,
  label: string,
  filters: Array<FilterOptionType>,
  { optionValue, optionLabel }: { optionValue: string; optionLabel: string },
): Array<FilterOptionType> {
  if (!optionLabel) return filters;
  let currentFilters = [...filters];

  const filterColumn = currentFilters.find((filter) => filter.value === name);
  if (filterColumn) {
    addValueToColumn(filterColumn, {
      optionValue,
      optionLabel,
    });
  } else {
    currentFilters = addColumn(filters, name, label, {
      optionValue,
      optionLabel,
    });
  }

  return currentFilters;
}

export const getFiltersWithoutDates = (filters: Array<FilterType>): Array<FilterType> =>
  filters.filter((filter) => filter.column !== 'dateFrom' && filter.column !== 'dateTo');

export const getFiltersWithoutOptions = (filters: Array<FilterType>): Array<FilterType> =>
  filters.filter((filter) => filter.values.length);

export const sortColumnsOptions = (columns: Array<FilterOptionType>): Array<FilterOptionType> =>
  columns.map((column) => ({
    ...column,
    options: recursiveSort(column.options, 'label'),
  }));

export function combineFilterColumns(arr1: Array<FilterOptionType>, arr2: Array<FilterOptionType>) {
  const hash: any = {};
  const newArray: Array<FilterOptionType> = [];

  arr1.forEach((obj) => {
    hash[obj.value] = obj;
    newArray.push(obj);
  });

  arr2.forEach((obj) => {
    if (hash[obj.value]) {
      Object.assign(hash[obj.value], obj);
    } else {
      hash[obj.value] = obj;
      newArray.push(obj);
    }
  });

  return newArray;
}
