import { useCallback } from 'react';

import { DEFAULT_QUERY_PARAMS_BY_CATEGORY_PATH } from 'helpers/cloudSearchParams';
import { encodePartCategoryPathCloudSearchQuery } from 'helpers/stringFunctions';
import usePartCategoryAPI from 'hooks-api/usePartCategory';

import useCache from './useCache';

export const getCategoryPathCb = (categories = []) =>
  categories.map(({ partCategoryName }) => partCategoryName).join('/');

export const getCategoryHierarchy = async (category, hierarchy = [], fetchFn) => {
  if (!category) return hierarchy;

  const { parentPartCategory, partCategoryId, partCategoryName } = category;
  const newHierarchy = [{ partCategoryId, partCategoryName }, ...hierarchy];

  if (!parentPartCategory) {
    return newHierarchy;
  }

  const { partCategoryId: parentPartCategoryId } = parentPartCategory;
  const parentPartCategoryResponse = await fetchFn(parentPartCategoryId);
  return getCategoryHierarchy(parentPartCategoryResponse, newHierarchy, fetchFn);
};

const usePartsCloudSearchCache = () => {
  const { deleteCache } = useCache();
  const { fetchPartCategoryById } = usePartCategoryAPI();

  const getPartsCloudSearchQuery = useCallback(
    async (catalog, category) => {
      const categoryHierarchy = await getCategoryHierarchy(category, [], fetchPartCategoryById);
      const categoryPath = getCategoryPathCb(categoryHierarchy);

      return {
        query: encodePartCategoryPathCloudSearchQuery(`/${categoryPath}`, catalog.partCatalogId),
        ...DEFAULT_QUERY_PARAMS_BY_CATEGORY_PATH,
      };
    },
    [fetchPartCategoryById],
  );

  const deletePartsCloudSearchCache = useCallback(
    async (category) => {
      const { partCatalog } = category;
      if (!partCatalog) return;

      const query = await getPartsCloudSearchQuery(partCatalog, category);
      deleteCache('partsCloudSearch', { query });
      deleteCache('partsCloudSearchCount', { query });
    },
    [getPartsCloudSearchQuery, deleteCache],
  );

  return { getPartsCloudSearchQuery, deletePartsCloudSearchCache };
};

export default usePartsCloudSearchCache;
