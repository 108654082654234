export const getTableStyles = (depth) => ({
  '& .MuiDataGrid-columnHeaders': { display: 'none' },
  '& .MuiDataGrid-virtualScroller': { marginTop: '0!important', overflow: 'hidden !important' },
  '& .MuiDataGrid-cell': {
    borderTop: 'none !important',
  },
  '& div[role="row"]': {
    borderBottom: '1px solid rgb(224, 224, 224) !important',
    width: '100% !important',
    position: 'relative',
  },
  '& div[role="cell"]:nth-of-type(1)': {
    ml: depth * 2.5,
  },
});
