import React from 'react';

import { List, ListItem, Typography } from '@mui/material';

const CategoryModalContent = () => (
  <>
    <Typography variant="body1" mb="8px">
      Deleting a category will delete:
    </Typography>
    <List dense sx={{ '& li': { paddingTop: 0, paddingBottom: 0 } }}>
      <ListItem dense>
        <Typography variant="body1">· All subcategories underneath it</Typography>
      </ListItem>
      <ListItem dense>
        <Typography variant="body1">· All attributes, attribute variants and codes</Typography>
      </ListItem>
      <ListItem dense>
        <Typography variant="body1">· All items</Typography>
      </ListItem>
    </List>
    <Typography variant="body1" mt="8px" mb="16px">
      Are you sure you want to delete the category? Please type your email to confirm deletion.
    </Typography>
  </>
);

export default CategoryModalContent;
