/* eslint-disable import/no-cycle */

import { useFormContext } from 'react-hook-form';

import { useItemsIntegrationTableContext } from '../../../context/ItemsIntegrationTableContext';
import { useFieldContext } from '../../QuantityField/context/FieldContext';
import { QTY_LIMIT_EXCEEDED_ERROR_MESSAGE } from '../constants';
import { useValidateNumberRangeCallbacks } from './useValidateNumberRangeCallbacks';

const useValidateNumberRange = () => {
  const { fieldName, qtyAggregate, qtyRemaining, isReadOnly, isBomLineItem, quantityFieldChildren } = useFieldContext();
  const { autoFilledFields } = useItemsIntegrationTableContext();
  const { formState } = useFormContext();
  const { isDirty } = formState;
  const { clearAllErrors, addValidateError, addRangeError, addFieldError, handleNegativeAmount, setQtyLimitError } =
    useValidateNumberRangeCallbacks();
  const validateNumberRange = (value: string) => {
    if (!isDirty) {
      clearAllErrors();
      return true;
    }
    if (value === '') {
      return addValidateError();
    }
    const numberValue = parseInt(value || '0', 10);
    const isCorrectValueRange = numberValue >= 0 && numberValue <= qtyRemaining;
    const equalsAggregateValue = numberValue === qtyAggregate;
    const isSumOfChildren = numberValue !== qtyAggregate && isReadOnly;
    const isValueBetweenCorrectRange = isCorrectValueRange || equalsAggregateValue || isSumOfChildren;
    if (isValueBetweenCorrectRange) {
      clearAllErrors(fieldName);
      return true;
    }
    if (!isValueBetweenCorrectRange) {
      return addRangeError();
    }
    const isNegativeAmount = qtyRemaining < 0 || qtyAggregate < 0;

    if (isNegativeAmount) return handleNegativeAmount(numberValue);
    if (isBomLineItem && !numberValue) return addRangeError();
    if (numberValue > qtyAggregate) {
      addFieldError();
      setQtyLimitError();
      return QTY_LIMIT_EXCEEDED_ERROR_MESSAGE;
    }

    const isLineItemWithValueAndNoChildren =
      isBomLineItem &&
      numberValue !== qtyRemaining &&
      Boolean(value) &&
      !quantityFieldChildren?.filter((child: any) => !child.isWorkPhase && (child.locationId || child.nestLevel))
        .length;
    if (isLineItemWithValueAndNoChildren) {
      clearAllErrors(fieldName);
      return true;
    }
    if (!value && !isBomLineItem) {
      clearAllErrors(fieldName);
      return true;
    }

    if (autoFilledFields.includes(fieldName)) {
      clearAllErrors(fieldName);
      return true;
    }

    return addRangeError();
  };

  return { validateNumberRange };
};

export default useValidateNumberRange;
