/* eslint-disable max-lines-per-function */
import { useCallback } from 'react';

import { gql, useMutation, useLazyQuery, useApolloClient } from '@apollo/client';

import {
  addTakeOffPadItem as ADD_TAKE_OFF_ITEM,
  mapSourcePadItem as MAP_SOURCE_PAD_ITEMS,
  deleteTakeOffPadItem as DELETE_TAKE_OFF_PAD_ITEMS,
} from 'graphql/mutations';
import {
  sourcePadNames as SOURCE_PADS,
  sourcePadProperties as SOURCE_PAD_PROPERTIES,
  sourcePadItems as SOURCE_PAD_ITEMS,
  sourcePadItemsCount as SOURCE_PAD_ITEMS_COUNT,
  takeoffPadItems as TAKE_OFF_PAD_ITEMS,
  takeoffPadItemsCount as TAKE_OFF_PAD_ITEMS_COUNT,
  takeoffPads as TAKE_OFF_PADS,
} from 'graphql/queries';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import useLazyPaginatedQuery from 'hooks/useLazyPaginatedQuery';
import { useProject } from 'modules/Field/LocationsAndWorkPhases/contexts/ProjectContext';
import {
  addTemporalIdToDuplicateItems,
  checkItemsToMakeDecisions,
  getLineItemsWithCorrectQty,
} from 'modules/Materials/BillOfMaterialsById/BOMCatalogTable/Decisions/helpers';

import {
  useAddPadMutation,
  useDeleteTakeOffPad,
  useRenameTakeOffPad,
  useMapPadItem,
  useDeleteSourcePad,
} from './useTakeOffMutations';

const TAKEOFF_PAD_ITEMS_LIMIT = 50;
const SOURCE_PADS_POLLING_INTERVAL = 5000;

export const useTakeOffAPI = (currentQuery, forceTakeoffItemsRefetch) => {
  const client = useApolloClient();
  const { itemSelectedId: projectId } = useProject();

  // Takeoff pads
  const [fetchTakeOffPads, { data: takeOffPads, refetch: refetchTakeOffPads, loading: loadingTakeOffPads }] =
    useLazyQuery(gql(TAKE_OFF_PADS));

  const { handleResponse } = useGraphqlResponseHandler(refetchTakeOffPads);

  // Source pads names
  const [fetchSourcePads, { data: sourcePads, loading: loadingSourcePads }] = useLazyQuery(gql(SOURCE_PADS), {
    pollInterval: SOURCE_PADS_POLLING_INTERVAL,
  });

  // Source Pad properties
  const [fetchSourcePadProperties, { data: sourcePadProperties, loading: loadingSourcePadProperties }] = useLazyQuery(
    gql(SOURCE_PAD_PROPERTIES),
    {
      pollInterval: SOURCE_PADS_POLLING_INTERVAL,
    },
  );

  // Source pads items
  const [
    {
      lazyLoad: fetchSourcePadItems,
      paginationHandler: sourcePadItemsPaginationFn,
      onOrderby: sourcePadItemsOrderByFn,
      refetch: refetchSourcePadItems,
    },
    { data: sourcePadItemsRes, sortingKeyword: sourcePadSortingKeyword, loading: loadingSourcePadItems },
  ] = useLazyPaginatedQuery(gql(SOURCE_PAD_ITEMS), 'cache-and-network', TAKEOFF_PAD_ITEMS_LIMIT);

  const [fetchSourcePadItemsCount, { data: sourcePadItemsCountRes }] = useLazyQuery(gql(SOURCE_PAD_ITEMS_COUNT), {
    fetchPolicy: 'cache-and-network',
  });

  // Takeoff pads items
  const [
    {
      lazyLoad: fetchTakeOffPadItems,
      paginationHandler: takeOffPadItemsPaginationFn,
      onOrderby: takeOffPadItemsOrderByFn,
      refetch: refetchTakeOffPadItems,
    },

    { data: takeOffPadItemsRes, sortingKeyword: takeOffPadSortingKeyword, loading: takeOffPadItemsLoading },
  ] = useLazyPaginatedQuery(gql(TAKE_OFF_PAD_ITEMS), 'cache-and-network', TAKEOFF_PAD_ITEMS_LIMIT);

  const [fetchTakeOffPadItemsCount, { data: takeOffPadItemsCountRes, refetch: refetchTakeOffPadItemsCount }] =
    useLazyQuery(gql(TAKE_OFF_PAD_ITEMS_COUNT));

  // Add
  const onAddTakeOffPad = useAddPadMutation(projectId, handleResponse);

  // Delete
  const onDeleteTakeoffPad = useDeleteTakeOffPad(handleResponse);

  // Rename
  const onRenameTakeOffPad = useRenameTakeOffPad(handleResponse);

  // Delete Source Pad
  const onDeleteSourcePad = useDeleteSourcePad(handleResponse);

  // Add TakeOff Pad Item
  const [addTakeOffPadItem, { loading: loadingAddTakeOffPadItem }] = useMutation(gql(ADD_TAKE_OFF_ITEM));

  const updateTakeoffItemsCache = useCallback(
    (cache, data, partsAdded) => {
      const itemsToMakeDecisions = checkItemsToMakeDecisions(data?.addTakeOffPadItem);

      if (itemsToMakeDecisions?.length === 0 || forceTakeoffItemsRefetch) {
        refetchTakeOffPadItems();
        return;
      }

      const lineItemsWithCorrectQty = getLineItemsWithCorrectQty(itemsToMakeDecisions, partsAdded);

      const queryObject = {
        query: gql(TAKE_OFF_PAD_ITEMS),
        variables: { query: currentQuery },
      };

      const originalData = cache.readQuery(queryObject);

      const filteredData = addTemporalIdToDuplicateItems(originalData?.takeoffPadItems, itemsToMakeDecisions);

      const updatedData = {
        takeoffPadItems: [...lineItemsWithCorrectQty, ...filteredData],
      };

      cache.writeQuery({ ...queryObject, data: updatedData });
    },
    [currentQuery, forceTakeoffItemsRefetch, refetchTakeOffPadItems],
  );

  const onAddTakeOffPadItem = useCallback(
    async ({ takeOffPadId, items }) => {
      const body = { takeoffpadId: takeOffPadId, items };

      const data = await handleResponse(
        addTakeOffPadItem,
        {
          variables: { body },
          update(cache, { data }) {
            updateTakeoffItemsCache(cache, data, items);
            cache.modify({
              id: 'ROOT_QUERY',
              fields: {
                quantitiesRetrieveByIdentifier(_, { DELETE }) {
                  return DELETE;
                },
                billOfMaterialLineItemByLBS(_, { DELETE }) {
                  return DELETE;
                },
              },
            });
            cache.gc();
          },
        },
        { successMessage: 'Take off item added' },
      );

      return data;
    },
    [addTakeOffPadItem, handleResponse, updateTakeoffItemsCache],
  );

  // Delete TakeOffPadItem

  const [deleteTakeOffPadItem] = useMutation(gql(DELETE_TAKE_OFF_PAD_ITEMS), {
    refetchQueries: ['TakeoffPadItems'],
    update(cache) {
      cache.evict({
        id: 'ROOT_QUERY',
        fieldName: 'takeoffPadItems',
      });
    },
  });

  const onDeleteTakeOffPadItems = useCallback(
    async (idItems) => {
      const body = { lineItemIds: idItems };

      const successMessage =
        idItems.length > 1 ? `${idItems.length} items successfully deleted` : 'Item successfully deleted';
      const errorMessage = idItems.length > 1 ? 'Error: Unable to delete items' : 'Error: Unable to delete item';

      await handleResponse(deleteTakeOffPadItem, { variables: { body } }, { successMessage, errorMessage });
    },
    [deleteTakeOffPadItem, handleResponse],
  );

  // Map SourcePad
  const [mapSourcePadItem] = useMutation(gql(MAP_SOURCE_PAD_ITEMS), {
    refetchQueries: ['TakeoffPadItems'],
  });
  const onMapSourcePadBase = useMapPadItem(handleResponse, mapSourcePadItem);

  const [getListOfTakeOffPadsByName] = useLazyQuery(gql(TAKE_OFF_PADS));
  const onGetListOfTakeOffPadsByName = useCallback(
    async (takeoffPadName) => {
      if (!projectId) return [];
      const result = await getListOfTakeOffPadsByName({
        variables: { query: { takeoffPadName, take: 1, projectId } },
      });
      return result?.data?.takeoffPads ?? [];
    },
    [getListOfTakeOffPadsByName, projectId],
  );

  return {
    client,
    sourcePads: sourcePads?.sourcePadNames ?? [],
    sourcePadProperties,
    sourcePadItemsCountRes,
    sourcePadItemsRes,
    sourcePadSortingKeyword,
    takeOffPadItemsCountRes,
    takeOffPadItemsRes,
    takeOffPadSortingKeyword,
    takeOffPads: takeOffPads?.takeoffPads ?? [],
    loadingAddTakeOffPadItem,
    callbacks: {
      fetchTakeOffPads,
      onRenameTakeOffPad,
      onDeleteTakeoffPad,
      onDeleteSourcePad,
      onAddTakeOffPad,
      onAddTakeOffPadItem,
      onDeleteTakeOffPadItems,
      onMapSourcePadBase,
      fetchSourcePads,
      fetchSourcePadProperties,
      fetchSourcePadItemsCount,
      fetchSourcePadItems,
      fetchTakeOffPadItemsCount,
      refetchTakeOffPadItemsCount,
      fetchTakeOffPadItems,
      refetchTakeOffPadItems,
      refetchSourcePadItems,
      onGetListOfTakeOffPadsByName,
    },
    paginationHandlers: {
      sourcePadItemsPaginationFn,
      takeOffPadItemsPaginationFn,
      sourcePadItemsOrderByFn,
      takeOffPadItemsOrderByFn,
    },
    metadata: {
      loadingTakeOffPads,
      loadingSourcePads,
      loadingSourcePadProperties,
      takeOffPadItemsLoading,
      loadingSourcePadItems,
    },
  };
};
