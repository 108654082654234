export const numCheck = (event) => {
  const { keyCode } = event;
  const topNumbers = keyCode >= 48 && keyCode <= 57;
  const rightNumbers = keyCode >= 96 && keyCode <= 105;

  const specialKeys = [8, 9, 13, 37, 38, 39, 40, 46].includes(keyCode);

  const valid = topNumbers || specialKeys || rightNumbers;
  return valid;
};
