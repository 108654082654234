/* eslint-disable no-console */
import React from 'react';

import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import StackRow from 'components/StackRow';

import ShopTasksBody from './ShopTasksBody';
import { ShopTasksProvider, useShopTasksContext } from './ShopTasksContext';

const ShopTasksTypes = () => (
  <ShopTasksProvider>
    <LeftAligned>
      <ShopTasksTitle />
      <LoadingTasksType />
    </LeftAligned>
    <StyledCard>
      <ShopTasksBody />
    </StyledCard>
  </ShopTasksProvider>
);

export default ShopTasksTypes;

const ShopTasksTitle = () => (
  <Typography
    mr="18px"
    variant="h5"
    component="h1"
    sx={{ textAlign: { sx: 'left', lg: 'center' } }}
  >
    Shop Task Types
  </Typography>
);

const LoadingTasksType = () => {
  const { loadingTaskTypes } = useShopTasksContext();
  return loadingTaskTypes && <CircularProgress size={30} color="secondary" />;
};

const LeftAligned = (props) => <StackRow mt="4px" mb="18px" {...props} />;

const StyledCard = (props) => (
  <Card
    sx={{
      border: '1px solid #cccccc',
      borderRadius: '4px',
      boxShadow: 'none',
      marginRight: '4px',
      flex: 1,
    }}
    {...props}
  />
);
