import { useMemo } from 'react';

import { useModal } from '../contexts/ModalContext';

const useChildrenSummation = () => {
  const { nodeTree, parentFilteredIds } = useModal();

  const childrenSummation = useMemo(() => {
    const firstLocationsLoaded = nodeTree?.length && nodeTree[0].children?.length;
    if (!firstLocationsLoaded) return 0;

    return nodeTree[0].children.reduce((acc, item) => {
      if (item.type === 'Location') acc += item.aggregate;
      else if (item.type === 'WorkPhase') acc += item.amount;
      return acc;
    }, 0);
  }, [nodeTree]);

  const getFilteredChildrenInputSummation = (node) => {
    if (!node) return 0;
    if (!node?.children?.length) return 0;

    return node.children.reduce((acc, item) => {
      if (parentFilteredIds.includes(item.id)) acc += item.amount;

      return acc + getFilteredChildrenInputSummation(item);
    }, 0);
  };

  const filteredChildrenInputSummation = useMemo(() => {
    if (parentFilteredIds?.length) return getFilteredChildrenInputSummation(nodeTree[0]);
    return 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeTree, parentFilteredIds]);

  return {
    childrenSummation,
    filteredChildrenInputSummation,
  };
};

export default useChildrenSummation;
