import React, { useCallback } from 'react';

import { gql, useMutation } from '@apollo/client';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';

import { useUser } from 'app/UserContext';
import { Modal, CancelButton } from 'components';
import { addMessage as ADD_MESSAGE } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

import {
  steps,
  lastStep,
  formatNewMessage,
  handleCancel,
  handleNextClick,
} from './helpersMessageCreateModal';
import MessageStepperForm from './MessageStepperForm/MessageStepperForm';

const MessageCreateModal = ({ open, onClose }) => {
  const { user } = useUser();
  const { handleResponse } = useGraphqlResponseHandler();

  const [addMessage, { loading: isAddingMessage }] = useMutation(gql(ADD_MESSAGE), {
    refetchQueries: ['Messages', 'MessagesCount'],
  });

  const [activeStep, setActiveStep] = React.useState(0);

  const { control, handleSubmit, watch, trigger, reset } = useForm({
    defaultValues: {
      title: '',
      messageType: '',
      messagePriority: 'normal',
      body: '',
      recipientType: 'all',
      recipients: [],
    },
  });

  const submit = async (values) => {
    if (isAddingMessage) return;

    const body = formatNewMessage(values, user);

    await handleResponse(
      addMessage,
      {
        variables: {
          body,
        },
      },
      { successMessage: `Message created successfully.` },
      () => {
        onClose();
        setActiveStep(0);
        reset();
      },
    );
  };

  const body = watch('body');
  const recipientType = watch('recipientType');

  const handleNext = useCallback(() => setActiveStep((prevActiveStep) => prevActiveStep + 1), []);
  const handleBack = useCallback(() => setActiveStep((prevActiveStep) => prevActiveStep - 1), []);

  return (
    <Modal
      open={open}
      onClose={() => handleCancel(onClose, setActiveStep, reset)}
      title="Create Message"
      reducePadding
      allowOverflow
      maxWidth="md"
      footer={
        <>
          <BackButton activeStep={activeStep} onClick={handleBack} />
          <Box sx={{ flex: '1 1 auto' }} />
          <CancelButton
            color="primary"
            onClick={() => handleCancel(onClose, setActiveStep, reset)}
          />
          <NextButton
            loading={isAddingMessage}
            activeStep={activeStep}
            onClick={() => handleNextClick(trigger, activeStep, handleSubmit, submit, handleNext)}
          />
        </>
      }
    >
      <MessageStepperForm
        activeStep={activeStep}
        steps={steps}
        control={control}
        body={body}
        recipientType={recipientType}
      />
    </Modal>
  );
};

export default MessageCreateModal;

const BackButton = ({ onClick, activeStep }) => (
  <Button color="inherit" disabled={activeStep === 0} onClick={onClick} sx={{ mr: 1 }}>
    Back
  </Button>
);

const NextButton = ({ onClick, activeStep, loading }) => (
  <Button color="primary" variant="contained" disabled={loading} onClick={onClick}>
    {loading && 'Loading...'}
    {!loading && (activeStep === lastStep ? 'Send Message' : 'Next')}
  </Button>
);
