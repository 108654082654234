import React from 'react';

import Typography from '@mui/material/Typography';

import { Modal, CancelButton, DeleteButton } from 'components';

const DepartmentDeleteModal = ({ open, onSubmit, onClose }) => (
  <Modal
    open={open}
    onClose={onClose}
    title="Confirm Delete"
    reducePadding
    fullWidth
    maxWidth="xs"
    footer={
      <>
        <CancelButton onClick={onClose} />
        <DeleteButton onClick={onSubmit} />
      </>
    }
  >
    <Typography variant="body1" mb="12px">
      Are you sure you want to delete this department?
    </Typography>
  </Modal>
);

export default DepartmentDeleteModal;
