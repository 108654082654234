import { useCallback } from 'react';

import { gql, useLazyQuery } from '@apollo/client';

import { MessageStatus } from 'app/MessageCenter/contexts';
import { messagesCount as MESSAGES_COUNT } from 'graphql/queries';

import useFetchMessagePriorities from './useFetchMessagePriorities';

const useFetchMessagesCount = () => {
  const { getHighPriorityType } = useFetchMessagePriorities();

  const [, { loading: loadingAlertCount, data: alertsCount, refetch: fetchAlertsCount }] =
    useLazyQuery(gql(MESSAGES_COUNT), {
      fetchPolicy: 'cache-and-network',
    });

  const getMessagesCount = useCallback(async () => {
    const highPriorityType = await getHighPriorityType();
    await fetchAlertsCount({
      query: {
        messagePriorityId: highPriorityType?.messagePriorityId,
        read: 'false',
        status: MessageStatus.ACTIVE,
      },
    });
  }, [fetchAlertsCount, getHighPriorityType]);

  return { loadingAlertCount, alertsCount, getMessagesCount };
};

export default useFetchMessagesCount;
