import React from 'react';

import { IconButton } from '@mui/material';
// import Fade from '@mui/material/Fade';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';

import { Custom } from 'components/Icons';
import { otherColors } from 'styles/theme/colors';

const AttributeVariantMenu = () => {
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);

  const handleClick = () => {
    // setAnchorEl(event.currentTarget);
  };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  return (
    <>
      <IconButton aria-controls="item-menu" aria-haspopup="true" onClick={handleClick} size="large">
        <Custom.MoreVert fill={otherColors.black} />
      </IconButton>
      {/* <Menu
        id="variant-item-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <MenuItem>
          <Typography component="span" variant="body2">
            test
          </Typography>
        </MenuItem>
      </Menu> */}
    </>
  );
};

export default AttributeVariantMenu;
