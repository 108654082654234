import React, { useState } from 'react';

import { Menu } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import { Custom } from 'components/Icons';

const MoreMenuButton = ({ disabled, sxIcon, style, children }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);

  const handleClick = (e) => {
    setOpenMenu(true);
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setOpenMenu(false);
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        disabled={disabled}
        size="small"
        name="open-more-menu"
        data-testid="more-menu-vertical-icon"
        onClick={handleClick}
        style={style}
        {...sxIcon}
      >
        <Custom.MoreVerAlt sx={{ ...sxIcon }} />
      </IconButton>
      <Menu open={openMenu} anchorEl={anchorEl} size="small" onClose={handleClose} id="more-menu">
        {children}
      </Menu>
    </>
  );
};

export default MoreMenuButton;
