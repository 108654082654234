import React, { useCallback, useEffect } from 'react';

import { CircularProgress, Box, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';

import { Modal } from 'components';
import { GENERIC_MUTATION_ERROR } from 'constants/globalConstants';
import { VARIANT_ERROR, VARIANT_SUCCESS } from 'constants/snackbarConstants';

import TaskTypeForm from './Form/TaskTypeForm';
import { useShopTasksContext } from './ShopTasksContext';

const AUTO_HIDE_DURATION_SUCCESS = 4000;
const AUTO_HIDE_DURATION_ERROR = 5000;

const EditTaskTypeModal = ({ open }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { setShowEditTaskTypeModal, selectedTaskType, uploadTaskTypeImage, getTaskTypeImage, updateTaskType } =
    useShopTasksContext();

  const onSubmit = async (values) => {
    const imgFile = values.taskTypeImage;

    const { taskTypeId } = selectedTaskType;
    const documentId = await uploadTaskTypeImage(taskTypeId, imgFile);

    const response = await updateTaskType({
      variables: {
        params: { taskTypeId },
        body: {
          taskTypeImageId: documentId,
          taskTypeName: values.taskTypeName,
          taskTypeDescription: values.taskTypeDescription,
        },
      },
    });

    if (response.errors) {
      enqueueSnackbar(GENERIC_MUTATION_ERROR, {
        autoHideDuration: AUTO_HIDE_DURATION_ERROR,
        ...VARIANT_ERROR,
      });
      return;
    }

    enqueueSnackbar('Shop task type successfully edited', {
      autoHideDuration: AUTO_HIDE_DURATION_SUCCESS,
      ...VARIANT_SUCCESS,
    });
  };

  const defaultValues = {
    taskTypeImage: '',
    taskTypeName: selectedTaskType?.taskTypeName,
    taskTypeDescription: selectedTaskType?.taskTypeDescription,
  };

  const methods = useForm({ mode: 'all', defaultValues });

  const fetchTaskTypeImage = useCallback(async () => {
    const taskTypeImageId = selectedTaskType?.taskTypeImageId;

    if (!taskTypeImageId) return;

    const presignedURL = await getTaskTypeImage(taskTypeImageId);

    if (presignedURL) {
      methods.setValue('taskTypeImage', presignedURL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTaskType]);

  useEffect(() => {
    fetchTaskTypeImage();
  }, [fetchTaskTypeImage]);

  return (
    <Modal
      open={open}
      onClose={() => {
        methods.reset();
        setShowEditTaskTypeModal(false);
      }}
      disableModalOutsideClick
      fullWidth
      scroll="paper"
      sx={{
        '.MuiPaper-root': {
          maxHeight: '80vh',
          maxWidth: '505px',
        },
      }}
      titleStyles={{ fontWeight: 700 }}
      title={<ModalHeader title="Edit shop task type" />}
      footer={<Footer methods={methods} setShowEditTaskTypeModal={setShowEditTaskTypeModal} onSubmit={onSubmit} />}
    >
      <FormProvider {...methods} defaultValues={defaultValues}>
        <TaskTypeForm />
      </FormProvider>
    </Modal>
  );
};

export default EditTaskTypeModal;

const Footer = ({ methods, setShowEditTaskTypeModal, onSubmit }) => (
  <>
    {methods.formState.isSubmitting && <CircularProgress size={25} color="secondary" />}
    <DeclineButton
      disabled={methods.formState.isSubmitting}
      onClick={() => {
        methods.reset();
        setShowEditTaskTypeModal(false);
      }}
    />
    <SaveButton
      disabled={!methods.formState.isValid || methods.formState.isSubmitting}
      onClick={async () => {
        await methods.handleSubmit(onSubmit)();
        methods.reset();
        setShowEditTaskTypeModal(false);
      }}
    />
  </>
);

const DeclineButton = ({ onClick, ...restProps }) => (
  <Button
    variant="text"
    onClick={onClick}
    sx={{
      fontSize: '15px',
      lineHeight: '24px',
      letterSpacing: '0.46px',
      textAlign: 'right',
      color: '#613FC2',
    }}
    {...restProps}
  >
    CANCEL
  </Button>
);

const SaveButton = ({ onClick, ...restProps }) => (
  <Button
    variant="contained"
    onClick={onClick}
    sx={{
      backgroundColor: '#613FC2',
      fontSize: '15px',
      lineHeight: '24px',
      letterSpacing: '0.46px',
      textAlign: 'right',
      '&:hover': {
        background: '#434A90',
      },
    }}
    {...restProps}
  >
    SAVE
  </Button>
);

const ModalHeader = ({ title }) => <Box sx={{ width: 'md' }}>{title}</Box>;
