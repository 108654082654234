import { removeUnmadeDecisions } from '../helpers';

const useMapDecisionsData = () => {
  const mapOptions = (variants) =>
    variants?.map((option) => ({
      label: option.textValue,
      value: option.partAttributeSelectVariantId,
    }));

  const getDefaultOption = (lineItemDecision) => {
    if (lineItemDecision?.customValue)
      return {
        label: lineItemDecision.customValue,
        value: 'CUSTOM_VALUE',
      };

    return {
      label: lineItemDecision?.partAttributeSelectVariant?.textValue,
      value: lineItemDecision?.partAttributeSelectVariant?.partAttributeSelectVariantId,
    };
  };

  const mapDecisions = (decisions) => {
    const decisionsMapped = decisions?.map((decision) => {
      const { partAttributeVariantData, lineItemDecision, partAttribute } = decision;
      const { partAttributeId, partAttributeName } = partAttribute;
      const variants = partAttributeVariantData?.selectValues;
      const defaultOption = getDefaultOption(lineItemDecision);

      return {
        id: partAttributeId,
        name: partAttributeId,
        placeholder: partAttributeName,
        defaultValue: defaultOption.label ? defaultOption : null,
        options: mapOptions(variants || []),
        canAddOption: partAttribute?.allowWriteInVariant,
        needDelete: lineItemDecision?.allVariantsDeletedFromCurrentVersionOfPart,
        needUpdate: lineItemDecision?.isVariantDeletedFromCurrentVersionOfPart,
        attributeVariantDeleted: lineItemDecision?.partAttributeSelectVariant?.textValue,
      };
    });

    return decisionsMapped;
  };

  const mapDecisionsMade = (madeDecisions, lineItemId, quantity) => {
    if (!madeDecisions || !lineItemId) return null;

    const filterMadeDecisions = removeUnmadeDecisions(madeDecisions);

    const decisionsMadeMapped = Object.keys(filterMadeDecisions).map((key) => {
      if (filterMadeDecisions[key]?.value === 'CUSTOM_VALUE') {
        return {
          partAttributeId: key,
          partAttributeSelectVariantId: '',
          customValue: filterMadeDecisions[key]?.label,
        };
      }

      return {
        partAttributeId: key,
        partAttributeSelectVariantId: filterMadeDecisions[key]?.value,
        customValue: '',
      };
    });

    return {
      lineItemId,
      quantity,
      decisions: decisionsMadeMapped,
    };
  };

  const mapDecisionToDelete = (decisionToDelete, lineItemId) => ({
    lineItemDecisions: [
      {
        lineItemId,
        decisions: [
          {
            partAttributeId: decisionToDelete?.id,
            partAttributeSelectVariantId: '',
            customValue: '',
            isSkipped: true,
          },
        ],
      },
    ],
  });

  return { mapDecisions, mapDecisionsMade, mapDecisionToDelete };
};

export default useMapDecisionsData;
