import React, { useEffect, createContext, useState, useCallback } from 'react';

import { gql } from '@apollo/client';

import { machines as MACHINES } from 'graphql/queries';
import useLazyPaginatedQuery from 'hooks/useLazyPaginatedQuery';

import { useDataContext } from '../contexts/DataContext';
import { formatFlyoutMachines, getFacilityId } from '../helpers/helpers';

const MachinesContext = createContext();

const MachinesProvider = ({ children }) => {
  const { selectedItem, shopConfiguration } = useDataContext();
  const [showMapped, setShowMapped] = useState(false);
  const [machinesData, setMachinesData] = useState([]);

  const [
    { lazyLoad: lazyLoadMachines, paginationHandler: machinesPaginationHandler, refetchExistingPages },
    { loading: loadingMachines, data: machinesResponse },
  ] = useLazyPaginatedQuery(gql(MACHINES), 'network-only', 50);

  useEffect(
    () =>
      lazyLoadMachines({
        facilityId: getFacilityId(selectedItem),
        orderBy: 'machineIdentifier:asc',
      }),
    [lazyLoadMachines, selectedItem],
  );

  useEffect(() => {
    const { machines } = machinesResponse || {};

    if (machines) {
      const formattedMachines = formatFlyoutMachines(machines, shopConfiguration?.value);
      setMachinesData(formattedMachines);
    }
  }, [machinesResponse, shopConfiguration]);

  const refetchMachines = useCallback(() => {
    refetchExistingPages({
      facilityId: getFacilityId(selectedItem),
      orderBy: 'machineIdentifier:asc',
    });
  }, [refetchExistingPages, selectedItem]);

  const { machines } = machinesResponse || {};

  return (
    <MachinesContext.Provider
      value={{
        machines,
        lazyLoadMachines,
        machinesPaginationHandler,
        refetchMachines,
        loadingMachines,
        machinesResponse,
        machinesData,
        showMapped,
        setShowMapped,
      }}
    >
      {children}
    </MachinesContext.Provider>
  );
};

const useMachinesContext = () => {
  const context = React.useContext(MachinesContext);
  if (context === undefined) {
    throw new Error('useMachinesContext must be used within a MachinesProvider');
  }
  return context;
};

export { MachinesContext, MachinesProvider, useMachinesContext };
