import React from 'react';

import { AccordionList } from '@evolvemep/foresite-components-ui-react';

import PlansModelsContent from '../PlansModelsContent/PlansModelsContent';
import { useTaskViewerContext } from '../TaskViewerContext';
import TaskWorkOrderWorkRequest from '../TaskWorkOrderWorkRequest/TaskWorkOrderWorkRequest';
import ProductionTimeContent from './ProductionTimeContent';
import TaskPropertiesContent from './TaskPropertiesContent';

const getItems = (drawingQty = 0, taskWRWOQty = 0) => [
  {
    title: 'Production time',
    defaultExpanded: true,
    content: <ProductionTimeContent />,
    key: 'production-time',
  },
  {
    title: `Mapped drawings (${drawingQty})`,
    content: <PlansModelsContent />,
    key: 'drawing-files',
  },
  {
    title: `Task/WO/WR attachments (${taskWRWOQty})`,
    content: <TaskWorkOrderWorkRequest />,
    key: 'task-files',
  },
  {
    title: 'Task properties',
    content: <TaskPropertiesContent />,
    key: 'task-properties',
  },
];

const AccordionTabs = () => {
  const { drawingDocuments, commonDocuments, workOrderDocuments, workRequestDocuments } = useTaskViewerContext();

  const summationOfCommon = commonDocuments?.length + workOrderDocuments?.length + workRequestDocuments?.length || 0;

  return (
    <AccordionList
      items={getItems(drawingDocuments?.length, summationOfCommon)}
      hideFirstBorder
      sx={{ overflow: 'auto', overflowY: 'overlay', position: 'relative' }}
    />
  );
};

export default AccordionTabs;
