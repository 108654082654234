import React from 'react';

import { Box, ClickAwayListener } from '@mui/material';
import { GridRow } from '@mui/x-data-grid-pro';

const EditRow = ({ params, onClickAwayListener }) => (
  <ClickAwayListener onClickAway={onClickAwayListener}>
    <Box>
      <GridRow {...params} />
    </Box>
  </ClickAwayListener>
);

export default EditRow;
