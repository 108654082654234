import React from 'react';

import ComponentPaneLayout from 'app/Layout/ReactGridLayout/ComponentPaneLayout';

import CompanyEntitlementPool from './CompanyEntitlementPool/CompanyEntitlementPool';
import Customers from './customers';
import EvolveMembers from './evolveMembers';

export const Dashboard = () => (
  <ComponentPaneLayout>
    <div>Management dashboard</div>
  </ComponentPaneLayout>
);

export const EvolveMembersInterfaceNavContent = () => (
  <ComponentPaneLayout>
    <EvolveMembers />
  </ComponentPaneLayout>
);

export const CustomersInterfaceNavContent = () => (
  <ComponentPaneLayout>
    <Customers />
  </ComponentPaneLayout>
);

export const CompanyEntitlementInterfaceNavContent = () => (
  <ComponentPaneLayout>
    <CompanyEntitlementPool />
  </ComponentPaneLayout>
);
