import React from 'react';

import { Stack, SxProps } from '@mui/material';

import SectionBody from './SectionBody';
import SectionTitle from './SectionTitle';

interface Props {
  children: React.ReactNode;
  sx?: SxProps;
}

const Section = ({ children, sx, ...restProps }: Props) => (
  <Stack
    sx={{
      outline: '1px solid rgba(204, 204, 204, 1)',
      borderRadius: '4px',
      height: '100%',
      flex: 1,
      ...sx,
      ...restProps,
    }}
  >
    {children}
  </Stack>
);

Section.Title = SectionTitle;
Section.Body = SectionBody;

export default Section;
