import { useState, useEffect } from 'react';

import { useQuery } from '@apollo/client';

const take = 10;

const useSearchableQuery = (QUERY, options = {}) => {
  const { extraVars, fetchPolicy } = options;
  const [hasMore, setHasMore] = useState(true);
  const [searchPhrase, setSearchPhrase] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const queryResults = useQuery(QUERY, {
    fetchPolicy,
    variables: {
      query: { skip: 0, take, searchPhrase, orderBy, ...extraVars },
    },
  });

  const { fetchMore, refetch } = queryResults;

  useEffect(() => {
    setHasMore(true);
    refetch({
      query: { skip: 0, take, searchPhrase, orderBy, ...extraVars },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPhrase, orderBy]);

  const searchHandler = (value) => {
    setSearchPhrase(value.trim());
  };

  const sortHandler = (value) => {
    setOrderBy(value);
  };

  const paginationHandler = async (skip) => {
    if (!hasMore) return;

    const res = await fetchMore({
      variables: {
        query: { skip, take, searchPhrase, orderBy, ...extraVars },
      },
    });

    if (!res) return;

    const paginatedResults = Object.values(res)[0];
    const paginatedResultsLength = Object.values(paginatedResults)[0]?.length;

    if (paginatedResultsLength === 0) setHasMore(false);
  };

  return [{ searchHandler, sortHandler, paginationHandler }, queryResults];
};

export default useSearchableQuery;
