import React, { FC, ReactElement, useCallback, useMemo, useState } from 'react';

import { Box, IconButton, useTheme } from '@mui/material';

import { Custom } from 'components/Icons';

interface IArrowDetailIcon {
  onToggle: () => void;
  initialValue?: boolean;
  show?: boolean;
  fillBox?: boolean;
}

const ArrowDetailIcon: FC<IArrowDetailIcon> = ({
  onToggle = () => {},
  initialValue = false,
  show,
  fillBox = true,
}: IArrowDetailIcon) => {
  const [open, setOpen] = useState<boolean>(initialValue);
  const { palette }: any = useTheme();
  const styles = useMemo(
    () => ({
      height: '24px',
      width: '24px',
      transform: open ? 'rotate(90deg)' : '',
      fill: open ? palette.surface.lightSurface.primary : palette.surface.lightSurface.secondary,
      cursor: 'pointer',
      transition: '150ms',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open],
  );
  const toggleShowDetails = useCallback(() => {
    setOpen(!open);
    onToggle();
  }, [open, onToggle]);

  if (!show)
    return (
      <RenderEmpty show={fillBox}>
        <Box width="32px" />
      </RenderEmpty>
    );
  return (
    <IconButton tabIndex={-1} onClick={() => toggleShowDetails()} sx={{ padding: '4px' }}>
      <Custom.ArrowRight style={styles} />
    </IconButton>
  );
};

export default ArrowDetailIcon;

interface IRenderEmpty {
  show?: boolean;
  children: ReactElement;
}

const RenderEmpty: FC<IRenderEmpty> = ({ show, children }: IRenderEmpty) => {
  if (!show) return null;
  return children;
};
