import React, { useMemo } from 'react';

import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';

import { NEW_ITEM_ROW_ID } from 'constants/globalConstants';

import DecisionRow from '../../Decisions/DecisionRow';
import { WrapperMoreOptionBomItem } from '../../WrapperMoreOptionBomItem';
import { BOMCatalogTableFields, BOMCatalogTableHeaders } from './BOMTableColumnsHelpers';
import { getCatalogNumberColumn } from './CatalogNumberCell';
import { getLineItemNameColumn } from './LineItemName/LineItemNameCell';
import { getManufacturerColumn } from './ManufacturerCell';
import { getQuantityColumn } from './QuantityCell';
import { getToggleColumn } from './ToggleCell';
import { getTradeSizeColumn } from './TradeSizeCell';
import { getUnitOfMeasureColumn } from './UnitOfMeasureCell';
import { getUnitQuantityColumn } from './UnitQuantityCell';

const useBOMTableColumns = ({
  partAutocompleteVisibilityHook,
  onAddSubItem,
  onAddPartItem,
  onAddWriteInItem,
  handleRowCancel,
}) => {
  const columns = useMemo(
    () => [
      getCheckboxGridColumn(),
      getToggleColumn(),
      getLineItemNameColumn(onAddPartItem, onAddWriteInItem, partAutocompleteVisibilityHook, handleRowCancel),
      getTradeSizeColumn(),
      getUnitQuantityColumn(),
      getQuantityColumn(),
      getQuantityRemainingColumn(),
      getUnitOfMeasureColumn(),
      getManufacturerColumn(),
      getCatalogNumberColumn(),
      getDecisionsColumn(),
      getActionsColumn(onAddSubItem),
    ],
    [handleRowCancel, onAddPartItem, onAddSubItem, onAddWriteInItem, partAutocompleteVisibilityHook],
  );

  return columns;
};

export default useBOMTableColumns;

const getCheckboxGridColumn = () => ({
  ...GRID_CHECKBOX_SELECTION_COL_DEF,
  type: '',
  hideable: false,
  disableReorder: true,
  renderCell: (cell) => {
    if (cell.id === NEW_ITEM_ROW_ID.toString()) return <></>;
    return GRID_CHECKBOX_SELECTION_COL_DEF.renderCell(cell);
  },
});

const getQuantityRemainingColumn = () => ({
  field: BOMCatalogTableFields.QUANTITY_REMAINING,
  headerName: BOMCatalogTableHeaders[BOMCatalogTableFields.QUANTITY_REMAINING],
  resizable: true,
  flex: 1,
  align: 'right',
});

const getDecisionsColumn = () => ({
  field: BOMCatalogTableFields.DECISIONS,
  headerName: BOMCatalogTableHeaders[BOMCatalogTableFields.DECISIONS],
  flex: 2,
  resizable: true,
  sortable: false,
  renderCell: (props) => {
    if (!props?.id) return <></>;
    return <DecisionRow {...props} />;
  },
});

const getActionsColumn = (onAddSubItem) => ({
  field: 'options',
  type: 'actions',
  flex: 0.5,
  renderCell: (props) => {
    if (!props?.id || props?.id === NEW_ITEM_ROW_ID.toString()) return <></>;

    return (
      <WrapperMoreOptionBomItem
        onAddSubItem={() => onAddSubItem(props?.id)}
        billOfMaterialItem={props.row}
        deleteDisable={props?.row?.childLineItemContainerName !== 'Unassigned'}
      />
    );
  },
});
