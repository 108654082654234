import React from 'react';

import { Stack } from '@mui/material';
import Button from '@mui/material/Button';
import { useLocation, useNavigate } from 'react-router-dom';

import { Custom } from 'components/Icons';
import { otherColors } from 'styles/theme/colors';

const BackBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const previousPath = location?.state?.prevPath;
  const goBackToPrevious = () => {
    if (previousPath && !previousPath.match('/message/')) {
      navigate(previousPath);
      return;
    }

    navigate(-1);
  };

  return (
    <BackBarWrapper>
      <Button sx={{ color: 'white' }} onClick={goBackToPrevious}>
        <Custom.ArrowBackAlt style={{ marginRight: '11px', fill: otherColors.white }} />
        Go Back
      </Button>
    </BackBarWrapper>
  );
};

export default BackBar;

const BackBarWrapper = ({ children }) => (
  <Stack
    direction="row"
    alignItems="center"
    justifyContent="flex-start"
    sx={{
      height: '40px',
      background: '#616161',
    }}
  >
    {children}
  </Stack>
);
