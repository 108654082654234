import React from 'react';

import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  RadioGroup,
} from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';

import { RadioOption } from 'components/FormComponents';
import { useUOMAPIContext } from 'modules/Materials/CatalogSetup/Providers/UOMAPIProvider';

import { useUnitOfMeasureContext } from './UnitOfMeasureContext';

const UnitOfMeasureTypeField = ({ name, sx, ...restProps }) => {
  const { control, formState } = useFormContext();
  const { unitOfMeasureTypes, loadingUnitOfMeasureTypes } = useUOMAPIContext();
  const { newUnitOfMeasure } = useUnitOfMeasureContext();

  const {
    field: { onChange, onBlur, ref, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required: { value: true, message: 'You must select a unit of measure' } },
  });

  if (loadingUnitOfMeasureTypes) return <CircularProgress />;

  const uomTypes = unitOfMeasureTypes?.unitOfMeasureTypes.map((uomType) => ({
    id: uomType.unitOfMeasureTypeId,
    label: uomType.unitOfMeasureTypeName,
  }));

  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        row
        control={control}
        name={name}
        onChange={(e) => onChange(e.target.value)}
        onBlur={onBlur}
        value={value}
        ref={ref}
        sx={{ ...sx }}
        {...restProps}
      >
        {uomTypes?.map(({ id, label }) => (
          <FormControlLabel
            key={id}
            value={id}
            control={<RadioOption inputProps={{ 'data-testid': `uom-type-radio-${label}` }} />}
            label={label}
            disabled={!newUnitOfMeasure || formState.isSubmitting}
          />
        ))}
      </RadioGroup>
      <FormHelperText sx={{ color: 'red' }}>{error?.message}</FormHelperText>
    </FormControl>
  );
};

export default UnitOfMeasureTypeField;
