import { useState } from 'react';

import { CombinedDocumentType } from 'hooks/usePaginatedDocuments';

const findNextDocIndex = (arr: CombinedDocumentType[], initialIndex: number, canRepeat = false): number => {
  for (let i = initialIndex; i < arr.length; i += 1) {
    if (!arr[i].externalUrl) return i;
  }

  for (let i = 0; i < arr.length; i += 1) {
    if (!arr[i].externalUrl && (i !== initialIndex - 1 || canRepeat)) return i;
  }

  return -1; // Return -1 if the search item is not found after the first n items
};

const findPrevDocIndex = (arr: CombinedDocumentType[], initialIndex: number): number => {
  for (let i = initialIndex; i >= 0; i -= 1) {
    if (!arr[i].externalUrl) return i;
  }

  for (let i = arr.length - 1; i >= 0; i -= 1) {
    if (!arr[i].externalUrl) return i;
  }

  return -1; // Return -1 if the search item is not found after the first n items
};

const useActiveDocument = (
  drawingDocuments: CombinedDocumentType[],
  taskWRWODocs: CombinedDocumentType[],
  activeDocument: CombinedDocumentType | undefined,
  setActiveDocument: (doc: CombinedDocumentType) => void,
) => {
  const [selectedIndexTable, setSelectedIndexTable] = useState<'drawings' | 'tasks' | 'workRequests' | 'workOrders'>(
    'drawings',
  );

  const findActiveDocIndex = () =>
    drawingDocuments.findIndex((doc) => doc.documentId === activeDocument?.documentId) || 0;

  const handleSelectNextFile = () => {
    if (selectedIndexTable === 'drawings') {
      const curIndex = findActiveDocIndex();
      const nextDoc = drawingDocuments[curIndex + 1] || drawingDocuments[0];
      setActiveDocument(nextDoc);
      return;
    }

    const taskWRWOIndex = taskWRWODocs.findIndex(({ documentId }) => documentId === activeDocument?.documentId);
    const nextDocIndex = findNextDocIndex(taskWRWODocs, taskWRWOIndex + 1, true);
    if (nextDocIndex !== -1) setActiveDocument(taskWRWODocs[nextDocIndex]);
  };

  const handleSelectPrevFile = () => {
    if (selectedIndexTable === 'drawings') {
      const curIndex = findActiveDocIndex();
      const prevDoc = drawingDocuments[curIndex - 1] || drawingDocuments[drawingDocuments.length - 1];
      setActiveDocument(prevDoc);
      return;
    }

    const taskWRWOIndex = taskWRWODocs.findIndex(({ documentId }) => documentId === activeDocument?.documentId);
    const prevDocIndex = findPrevDocIndex(taskWRWODocs, taskWRWOIndex - 1);
    if (prevDocIndex !== -1) setActiveDocument(taskWRWODocs[prevDocIndex]);
  };

  return {
    selectedIndexTable,
    handleSelectNextFile,
    handleSelectPrevFile,
    setSelectedIndexTable,
    findNextDocIndex,
  };
};

export default useActiveDocument;
