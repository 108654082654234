import useWorkRequestStatusMutation from 'hooks-api/useWorkRequestStatusMutation';

import { useWorkRequestItemsContext } from '../../WorkRequestItemsContext';

const addWorkRequestItems = async (items, addWorkRequestItemWithCacheUpdate) => {
  const promises = items.map((item) => {
    if (!item?.partId) throw new Error('Item is undefined');

    const itemObj = { partId: item.partId, quantity: item.qty };

    return addWorkRequestItemWithCacheUpdate(itemObj);
  });

  return Promise.all(promises);
};

const updateWorkRequestStatus = (workRequest, updateWorkRequestStatusMutation) => {
  if (!workRequest) return;

  const updateStatusNames = ['Canceled', 'Rejected'];
  const currentStatusIsCanceledOrRejected = updateStatusNames.some(
    (name) => name === workRequest?.workRequestStatusName,
  );

  if (!currentStatusIsCanceledOrRejected) return;

  const variables = {
    params: {
      workRequestId: workRequest?.workRequestId,
      workRequestStatusName: 'Draft',
    },
    body: { workRequestStatusDescription: '' },
  };
  updateWorkRequestStatusMutation({ variables });
};

const useCatalogPopupWorkRequest = () => {
  const { updateWorkRequestStatusMutation } = useWorkRequestStatusMutation();
  const { addWorkRequestItemWithCacheUpdate, workRequest } = useWorkRequestItemsContext();

  const handleAddItems = async (items) => {
    try {
      const responses = await addWorkRequestItems(items, addWorkRequestItemWithCacheUpdate);
      const responsesContainsError = responses.some((response) => response.errors?.length);
      if (responsesContainsError) return false;

      await updateWorkRequestStatus(workRequest, updateWorkRequestStatusMutation);

      const insertedItems = responses?.reduce((accumulatedQuantity, response) => {
        const currentQuantity = response?.data?.addWorkRequestItemFromCatalog?.quantity ?? 0;
        return accumulatedQuantity + currentQuantity;
      }, 0);

      return insertedItems;
    } catch {
      return 0;
    }
  };

  return { handleAddItems };
};

export default useCatalogPopupWorkRequest;
