import { OptionType } from './CatalogSearchConstans';

export const getLocalStorageKey = (id) => `${id}_catalogSearch`;

export const getRecentSearchesFromLocalStorage = (key, limit = 5) => {
  const recentSearchesFromLocalStorage = localStorage.getItem(key);
  const parsedSearches = recentSearchesFromLocalStorage
    ? JSON.parse(recentSearchesFromLocalStorage)
    : [];

  return parsedSearches.slice(0, limit);
};

export const addSearchToLocalStorage = (key, searchValue, item) => {
  const recentSearches = getRecentSearchesFromLocalStorage(key);

  const position = recentSearches.findIndex((item) => item.label === searchValue);
  if (position !== -1) {
    recentSearches.splice(position, 1);
  }

  recentSearches.unshift(item);
  localStorage.setItem(key, JSON.stringify(recentSearches));

  return recentSearches;
};

export const searchItemsFromLocalStorage = (sKey, value) =>
  getRecentSearchesFromLocalStorage(sKey).filter((term) => term?.label.includes(value));

export const filterSearchOptions = (options, userId, searchValue) => {
  const sKey = getLocalStorageKey(userId);
  const recentSearches = searchItemsFromLocalStorage(sKey, searchValue);
  recentSearches.forEach((item) => {
    if (!options.some((opt) => opt.label === item.label)) {
      options.unshift(item);
    }
  });

  return [...options];
};

export const optionMapFn = (option) => {
  const { __typename } = option;
  if (__typename === 'PartCloudSearchResult') {
    return { label: option.partName, value: option.partHistoryRefId, type: OptionType.PART };
  }
  return { label: option.categoryName, value: option.categoryId, type: OptionType.PART_CATEGORY };
};
