import React from 'react';

import { Box } from '@mui/material';

import { Custom } from 'components/Icons';
import { surfaceColors } from 'styles/theme/colors';

const ArrowIndicator = ({ item = {}, isOpen, handleClick, disabled, fixed = false, invisible = false }) => {
  const { hasChildren, locationPhases, hasWorkPhases } = item || {};

  const styles = {
    height: '24px',
    width: '24px',
    transform: isOpen ? 'rotate(90deg)' : '',
    fill: isOpen ? surfaceColors.lightSurface.primary : surfaceColors.lightSurface.secondary,
    cursor: 'pointer',
    ...(disabled
      ? {
          pointerEvents: 'none',
          fill: 'rgba(0, 0, 0, 0.1)',
        }
      : {}),
  };

  if (invisible) return <Box sx={{ width: '24px' }} />;

  if (hasChildren || hasWorkPhases || locationPhases?.length || fixed)
    return <Custom.ArrowRight onClick={handleClick} style={styles} />;

  return <Box sx={{ width: '24px' }} />;
};

export default ArrowIndicator;
