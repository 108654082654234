import { ApolloClient, from, HttpLink } from '@apollo/client';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

import { getJwtToken } from 'hooks-api/useEvolveApi';

import getEnvVars from '../envVars';
import cache from './cache/cache';

const getAuth = (env) => ({
  type: env.AWS_APPSYNC_AUTHENTICATION_TYPE,
  jwtToken: async () => {
    try {
      return getJwtToken();
    } catch {
      return '';
    }
  },
});

const getHttpLink = (env) =>
  new HttpLink({
    uri: env.AWS_APPSYNC_GRAPHQL_ENDPOINT,
    fetchOptions: {
      timeout: 900000, // TODO: TBD -> Timeout issue in production
    },
  });

const getLink = (env) => {
  const url = env.AWS_APPSYNC_GRAPHQL_ENDPOINT;
  const region = env.AWS_APPSYNC_REGION;
  const auth = getAuth(env);
  const httpLink = getHttpLink(env);
  return from([
    createAuthLink({ url, region, auth }),
    createSubscriptionHandshakeLink({ url, region, auth }, httpLink),
  ]);
};

const defaultOptions = {
  watchQuery: { errorPolicy: 'ignore' },
  query: { errorPolicy: 'all' },
  mutate: { errorPolicy: 'all' },
};

const getClient = async () => {
  const env = await getEnvVars();
  const link = getLink(env);
  return new ApolloClient({
    cache,
    link,
    defaultOptions,
    connectToDevTools: process.env.NODE_ENV !== 'production',
  });
};

export default getClient;
