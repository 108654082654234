/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import { InputForesiteField } from '@evolvemep/foresite-components-ui-react';
import { Grid, CircularProgress, Stack } from '@mui/material';

const VariantNameInput = ({
  defaultValues,
  variants,
  onSubmit,
  selectedItem,
  useForm,
  rules,
  loading,
  parentError,
  onError = () => {},
  onKeyDown = () => {},
}) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm;
  useEffect(() => {
    setValue('name', selectedItem?.text);
  }, [selectedItem]);

  return (
    <Grid item xs={8}>
      <Stack direction="row">
        {loading && <CircularProgress color="secondary" size={25} sx={{ marginRight: '5px' }} />}
        <VariantInputField
          error={errors?.name?.type === 'validation'}
          helperText={errors?.name?.type === 'validation' ? 'Variant name already exists' : ''}
          onError={onError}
          parentError={parentError}
          loading={loading}
          control={control}
          defaultValue={defaultValues?.name}
          rules={rules}
          variants={variants}
          onKeyDown={(e) => {
            if (e.code === 'Escape') {
              onKeyDown();
              return;
            }

            if (e.keyCode !== 13 || errors?.code?.type === 'validation') {
              return;
            }

            handleSubmit(onSubmit)();
          }}
        />
      </Stack>
    </Grid>
  );
};

export default VariantNameInput;

const getMargin = (parentCodeHasError) => {
  if (parentCodeHasError) return '24px';
  return '0px';
};

const VariantInputField = ({
  loading,
  control,
  defaultValue,
  rules,
  variants,
  onKeyDown = () => {},
  onError = () => {},
  parentError,
  error,
  helperText,
}) => (
  <InputForesiteField
    error={error}
    helperText={helperText}
    onError={onError}
    tabIndex={0}
    autoFocus
    maxLength={32}
    disabled={loading}
    control={control}
    defaultValue={defaultValue}
    name="name"
    size="small"
    onKeyDown={onKeyDown}
    rules={{
      ...rules,
      validate: {
        validation: (value) =>
          !variants.some((item) => item?.textValue?.trim().toLowerCase() === value.trim().toLowerCase()) || '',
      },
    }}
    sx={{
      margin: '0px',
      marginBottom: getMargin(parentError),
      pr: 1,
      height: '100%',
      '& .MuiInputBase-input': {
        padding: '8px 12px',
        fontSize: '14px',
        backgroundColor: 'white',
      },
      '& .MuiFormHelperText-contained': {
        marginTop: '0px',
      },
      '& .MuiFormHelperText-filled': {
        marginLeft: '0px !important',
      },
    }}
  />
);
