export type EModuleType = typeof EModuleType[keyof typeof EModuleType];
// eslint's built in "no-redeclare" is not valid for TypeScript, only for JavaScript
// TODO: We *should* disable the eslint rule (and let the TS rule take over)
// once the project is converted to TypeScript
// eslint-disable-next-line no-redeclare
export const EModuleType = {
  ADMIN: 'ADMIN',
  SHOP: 'SHOP',
  MATERIALS: 'MATERIALS',
  DATA_ANALYTICS: 'DATA_ANALYTICS',
  ELECTRICAL: 'ELECTRICAL',
  MECHANICAL: 'MECHANICAL',
  FIELD: 'FIELD',
  MANAGEMENT: 'MANAGEMENT',
  DESIGN: 'DESIGN',
  MESSAGE: 'MESSAGE',
  SYNC: 'SYNC',
} as const;

export const MODULE_URL_MAPPING: Record<EModuleType, string> = {
  [EModuleType.ADMIN]: 'admin',
  [EModuleType.SHOP]: 'shop',
  [EModuleType.MATERIALS]: 'materials',
  [EModuleType.DATA_ANALYTICS]: 'data_analytics',
  [EModuleType.ELECTRICAL]: 'electrical',
  [EModuleType.MECHANICAL]: 'mechanical',
  [EModuleType.FIELD]: 'field',
  [EModuleType.MANAGEMENT]: 'management',
  [EModuleType.DESIGN]: 'design',
  [EModuleType.MESSAGE]: 'message',
  [EModuleType.SYNC]: 'sync',
} as const;

// Only includes top-level Foresite modules,
// excludes sub-pages (like Message)
export const MODULE_LABEL_MAPPING: Partial<Record<EModuleType, string>> = {
  [EModuleType.ADMIN]: 'Admin',
  [EModuleType.DATA_ANALYTICS]: 'Data Analytics',
  [EModuleType.ELECTRICAL]: 'Electrical',
  [EModuleType.FIELD]: 'Field',
  [EModuleType.MATERIALS]: 'Materials',
  [EModuleType.MECHANICAL]: 'Mechanical',
  [EModuleType.SHOP]: 'Shop',
  [EModuleType.MANAGEMENT]: 'Management',
  [EModuleType.DESIGN]: 'Design',
  [EModuleType.SYNC]: 'Sync',
} as const;

export const ESearchType = {
  FACILITY: 'FACILITY',
  PROJECT: 'PROJECT',
} as const;
