import React, { useEffect, useRef } from 'react';

import { InputForesiteField } from '@evolvemep/foresite-components-ui-react';
import { Box } from '@mui/material';

const FolderControlInput = ({ autoFocus, ...restProps }) => {
  const textInput = useRef(null);

  useEffect(() => {
    if (autoFocus) {
      setTimeout(() => {
        textInput?.current?.focus();
      }, 0);
    }
  }, [autoFocus]);

  return (
    <Box sx={{ width: '100%', '.MuiFormControl-root': { width: '100%', margin: 0 } }}>
      <InputForesiteField
        inputRef={textInput}
        autoFocus
        size="small"
        color="secondary"
        sx={{
          '.MuiOutlinedInput-root': { padding: 0 },
          '.MuiOutlinedInput-input': { padding: '2px 12px !important' },
        }}
        autoComplete="off"
        {...restProps}
      />
    </Box>
  );
};

export default FolderControlInput;
