import { useMemo } from 'react';

import { useReactiveVar } from '@apollo/client';
import { Stack, Typography } from '@mui/material';

import { workOrdersSelectedToggleOptionVar } from 'apollo/reactiveVars';
import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import Header from 'components/Header';
import CardFilterChipList from 'components/Popover/FilterPopover/components/CardFilterChipList';

import NoFacilitySelected from '../NoFacilitySelectedPage';
import WorkOrdersKanban from '../WorkOrdersKanban';
import WorkOrderCalendar from './WorkOrderCalendar';
import { HeaderMenuComponent } from './WorkOrdersListOptions';
import WorkOrdersTable from './WorkOrdersTable';
import WorkOrdersTabs from './WorkOrdersTabs';

const WorkOrdersList = () => {
  const { selectedItem } = useFacilitiesProjects();
  const selectedTab = useReactiveVar(workOrdersSelectedToggleOptionVar);

  const isListView = useMemo(() => selectedTab === 'list', [selectedTab]);
  const isCalendarView = useMemo(() => selectedTab === 'calendar', [selectedTab]);
  const isKanbanView = useMemo(() => selectedTab === 'kanban', [selectedTab]);

  return (
    <>
      <Header
        title={
          <TitleComponent
            showOpenClosedTabs={isListView}
            title={isCalendarView ? 'Work Order Calendar' : 'Work Orders'}
          />
        }
        option={
          <HeaderMenuComponent
            {...((isCalendarView || isKanbanView) && {
              hiddenWorkOrdersFilter: true,
            })}
            hiddenSearchBar
            hiddenColumnsButton
            filterDisabled={!isListView}
          />
        }
      />
      {isListView && (
        <>
          <CardFilterChipList />
          <WorkOrdersTable />
        </>
      )}
      {isKanbanView && (selectedItem ? <WorkOrdersKanban /> : <NoFacilitySelected />)}
      {isCalendarView && (selectedItem ? <WorkOrderCalendar /> : <NoFacilitySelected />)}
    </>
  );
};

export default WorkOrdersList;

const TitleComponent = ({ showOpenClosedTabs, title }) => (
  <Stack direction="row">
    <Typography variant="h5">{title}</Typography>
    {showOpenClosedTabs && <WorkOrdersTabs />}
  </Stack>
);
