import { useCallback, useEffect } from 'react';

import { gql, useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { selectedWorkCellIdOnQueueVar } from 'apollo/reactiveVars';
import { VARIANT_ERROR } from 'constants/snackbarConstants';
import { deleteWorkCellTask as DELETE_WORK_CELL_TASK } from 'graphql/mutations';
import {
  workCellTasksQueue as WORK_CELLS_TASKS,
  workCellTasksQueueCount as WORK_CELL_TASKS_COUNT,
} from 'graphql/queries';
import useLazyPaginatedQuery from 'hooks/useLazyPaginatedQuery';

import useTaskStatusTypeIds from '../../../../../hooks/useTaskStatusTypes/useTaskStatusTypeIds';
import useFacilities from './useFacilities';

const take = 40;

export default function useTasksDataLayer(activeTabIndex) {
  const { enqueueSnackbar } = useSnackbar();
  const { open, closed } = useTaskStatusTypeIds();
  const { selectedFacilityId } = useFacilities();
  const workCellId = useReactiveVar(selectedWorkCellIdOnQueueVar);

  const [
    { lazyLoad: getWorkCellsTasks, paginationHandler: tasksPaginationHandler, refetchExistingPages },
    { loading, data },
  ] = useLazyPaginatedQuery(gql(WORK_CELLS_TASKS), 'network-only', take);

  const [workCellTasksQueueCount, { data: counterData }] = useLazyQuery(gql(WORK_CELL_TASKS_COUNT), {
    fetchPolicy: 'cache-and-network',
  });

  const [deleteWorkCellTaskMutation, { loading: loadingDeleteTask }] = useMutation(gql(DELETE_WORK_CELL_TASK), {
    refetchQueries: ['WorkCellTasksQueue', 'WorkCellTasksQueueCount'],
  });

  const getCurrentStatusTypeIds = () => (activeTabIndex === 0 ? open : closed);

  const buildVariables = (extraQuery = {}) => ({
    variables: {
      query: {
        taskStatusTypeIds: getCurrentStatusTypeIds(),
        workCellId,
        ...extraQuery,
      },
    },
  });

  const fetchWorkCellsTasks = useCallback(async () => {
    if (!workCellId) return;
    const variables = buildVariables({ take });
    if (!variables.variables.query.taskStatusTypeIds) return;

    getWorkCellsTasks(variables.variables.query);
    workCellTasksQueueCount(buildVariables({ skip: 0, take: 1 }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workCellId, open, closed, activeTabIndex, getWorkCellsTasks, workCellTasksQueueCount]);

  const handleDeleteTask = (workCellTaskId) =>
    deleteWorkCellTaskMutation({
      variables: { params: { workCellTaskId } },
      onCompleted: (response) => {
        if (!response?.deleteWorkCellTask) {
          enqueueSnackbar('Could not delete this task.', VARIANT_ERROR);
        }
      },
    });

  const paginationHandler = async (skip) => {
    const variables = buildVariables();
    tasksPaginationHandler(skip, variables.variables.query);
  };

  const refetchTasks = () => {
    const variables = buildVariables();
    refetchExistingPages(variables.variables.query);
  };

  useEffect(() => {
    const taskStatusTypeIdsAreDefined = Boolean(open && closed);
    if (taskStatusTypeIdsAreDefined) fetchWorkCellsTasks();
  }, [open, closed, selectedFacilityId, fetchWorkCellsTasks]);

  return {
    workCellsTasks: workCellId ? data?.workCellTasksQueue || [] : [],
    loadingWorkCellsTasks: loading,
    tasksCounter: workCellId ? counterData?.workCellTasksQueueCount || 0 : 0,
    loadingDeleteTask,
    paginationHandler,
    handleDeleteTask,
    refetchTasks,
  };
}
