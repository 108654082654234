import { gql, useMutation, useQuery } from '@apollo/client';

import { useUser } from 'app/UserContext';
import { addUnitOfMeasure as ADD_UNIT_OF_MEASURE } from 'graphql/mutations';
import { unitOfMeasure as UNITS_OF_MEASURE } from 'graphql/queries';

export const useUnitOfMeasureAPI = (fetchPolicy = 'cache-and-network') => {
  const { user } = useUser();
  const [createUOM, { loading }] = useMutation(gql(ADD_UNIT_OF_MEASURE), {
    refetchQueries: ['UnitOfMeasure'],
  });

  const {
    data: uoms,
    loading: loadingUoms,
    refetch: refetchUoms,
  } = useQuery(gql(UNITS_OF_MEASURE), {
    fetchPolicy,
    variables: {
      query: {
        companyId: user?.companyId,
      },
    },
  });

  return {
    loading,
    createUOM,
    uoms,
    loadingUoms,
    refetchUoms,
  };
};
