import React from 'react';

import { ForesiteTextField } from '@evolvemep/foresite-components-ui-react';
import { useController, useFormContext } from 'react-hook-form';

const TaskTypeDescriptionField = ({ name, ...restProps }) => {
  const { control } = useFormContext();
  const {
    field: { onChange, onBlur, ref, value },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <ForesiteTextField
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      name={name}
      inputRef={ref}
      sx={{ width: '100%' }}
      error={!!error}
      helperText={error?.message}
      multiline
      rows={7}
      label="Description"
      maxLength={342}
      placeholder="Add Description"
      {...restProps}
    />
  );
};

export default TaskTypeDescriptionField;
