/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { Box, Stack } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import { InputImage } from 'components';
import { clone } from 'helpers/arrayFunctions';
import { getFileWithFormattedName } from 'helpers/fileHelpers';
import { validateMaxLengthRule } from 'modules/Materials/TakeOff/Form/validateRule';

import { useDataContext } from '../../WorkCellsAndMachines/contexts/DataContext';
import { useWorkCellsAndMachinesContext } from '../../WorkCellsAndMachines/contexts/WorkCellsAndMachinesContext';
import MachineEditFormTextField from './MachineEditFormTextField';
import { useMachineEditFormHelpers } from './useMachineEditFormhelpers';

const updateField = (field, value, setValue, resetField) => {
  if (value) {
    setValue(field, value);
    return;
  }
  resetField(field);
};

const getDefaultValues = (currentMachine) => ({
  machineId: currentMachine?.machineId,
  machineIdentifier: currentMachine?.machineIdentifier,
  manufacturer: currentMachine?.manufacturer,
  model: currentMachine?.model,
  serialNumber: currentMachine?.serialNumber,
  machineImageId: currentMachine?.machineImageId,
});

const fetchInitialValues = (
  setValue,
  resetField,
  currentMachine,
  fetchMachineImage,
  comeFromNew,
  removeComeFromNew,
) => {
  const { machineIdentifier, manufacturer, model, serialNumber } = currentMachine;
  updateField('machineIdentifier', machineIdentifier, setValue, resetField);
  if (!comeFromNew) {
    updateField('manufacturer', manufacturer, setValue, resetField);
    updateField('model', model, setValue, resetField);
    updateField('serialNumber', serialNumber, setValue, resetField);
  } else {
    removeComeFromNew();
  }

  fetchMachineImage(currentMachine?.machineImageId);
};

// eslint-disable-next-line max-lines-per-function
const MachineEditForm = ({ machine }) => {
  const { activeRow } = useDataContext();
  const [currentImageId, setCurrentImageId] = useState(null);

  const [currentMachine, setCurrentMachine] = useState(null);

  const { uploadingMachineImage, uploadingProgress, uploadMachineImage, loadingGetImage } =
    useWorkCellsAndMachinesContext();

  const methods = useForm({
    mode: 'all',
    defaultValues: { ...getDefaultValues(currentMachine) },
  });

  const { watch, getValues, setValue, resetField, trigger, setError, clearErrors } = methods;

  const {
    formState: { isValid },
  } = methods;

  const formValues = watch();

  const { submit, setMachineData, fetchMachineImage, loadingMachine, loadingMachines } = useMachineEditFormHelpers(
    getValues,
    currentMachine,
    setCurrentMachine,
    setError,
    trigger,
    clearErrors,
    setValue,
    machine,
    currentImageId,
  );

  useEffect(() => {
    setCurrentImageId(null);
    setMachineData();

    if (!machine?.machineImageId) {
      setValue('machineImage', null);
      setValue('machineImageId', null);
    }
  }, [machine, activeRow.id]);

  const machineImage = watch('machineImage');

  useEffect(() => {
    if (currentMachine) {
      fetchInitialValues(setValue, resetField, currentMachine, fetchMachineImage, machine?.comeFromNew);
    }
  }, [currentMachine]);

  useEffect(() => {
    if (currentMachine?.machineImageId) fetchMachineImage(currentMachine?.machineImageId);
  }, [currentMachine?.machineImageId]);

  useEffect(() => {
    const uploadImage = async () => {
      const filename = getFileWithFormattedName(machineImage);
      const machineImageId = await uploadMachineImage(currentMachine?.machineId, filename);
      fetchMachineImage(machineImageId);
      setCurrentImageId(machineImageId);
    };
    if (machineImage && typeof machineImage === 'object') uploadImage();
  }, [machineImage]);

  const validateNextFocus = (event) => {
    const hasClickedInOtherField = ['machineIdentifier', 'manufacturer', 'serialNumber', 'model'].includes(
      event?.relatedTarget?.id,
    );

    if (!hasClickedInOtherField && isValid) {
      const formKeys = clone(formValues);
      delete formKeys.machineId;
      delete formKeys.machineImage;
      delete formKeys.machineImageId;

      const hasAnyDifference = !Object.keys(formKeys)
        .map((key) => formKeys[key]?.trim() === currentMachine[key]?.trim())
        .every((result) => result === true);
      if (hasAnyDifference) submit();
    }
  };

  const handleKeyUp = async (event) => {
    if (event.key === 'Enter') await submit(event);
  };

  const disabledFields = uploadingMachineImage || loadingMachine || loadingMachines;
  const isLoadingImage = (() => {
    if (machine?.machineImageId) return false;
    if (uploadingMachineImage || loadingGetImage || loadingMachine) return true;
    if (currentMachine?.machineImageId && !machineImage) return true;
    return false;
  })();

  return (
    <FormProvider {...methods}>
      <Stack spacing={2}>
        <ImageField
          uploadingMachineImage={isLoadingImage}
          uploadingProgress={uploadingProgress}
          machineImage={machineImage}
          submit={submit}
        />

        <MachineIdentifierField disabled={disabledFields} onBlur={validateNextFocus} onKeyUp={handleKeyUp} />
        <Box display="flex" justifyContent="space-between" sx={{ gap: 2, mt: '0 !important' }}>
          <ManufacturerField disabled={disabledFields} onBlur={validateNextFocus} onKeyUp={handleKeyUp} />
          <ModelField disabled={disabledFields} onBlur={validateNextFocus} onKeyUp={handleKeyUp} />
        </Box>
        <SerialNumberField disabled={disabledFields} onBlur={validateNextFocus} onKeyUp={handleKeyUp} />
      </Stack>
    </FormProvider>
  );
};

export default MachineEditForm;

const MachineIdentifierField = ({ ...restProps }) => (
  <MachineEditFormTextField
    name="machineIdentifier"
    id="machineIdentifier"
    label="Machine Id*"
    rules={{
      required: 'Machine Id is required',
      exist: 'Machine Id already exists',
      validate: (value) => validateMaxLengthRule(value, 64, true),
    }}
    inputProps={{ maxLength: 64 }}
    {...restProps}
  />
);

const ManufacturerField = ({ ...restProps }) => (
  <MachineEditFormTextField
    name="manufacturer"
    id="manufacturer"
    label="Manufacturer"
    rules={{ validate: (value) => validateMaxLengthRule(value, 64, true) }}
    inputProps={{ maxLength: 64 }}
    {...restProps}
  />
);

const ModelField = ({ ...restProps }) => (
  <MachineEditFormTextField
    name="model"
    id="model"
    label="Model"
    rules={{ validate: (value) => validateMaxLengthRule(value, 64, true) }}
    inputProps={{ maxLength: 64 }}
    {...restProps}
  />
);

const SerialNumberField = ({ ...restProps }) => (
  <MachineEditFormTextField
    name="serialNumber"
    id="serialNumber"
    label="Serial Number"
    rules={{ validate: (value) => validateMaxLengthRule(value, 64, true) }}
    inputProps={{ maxLength: 64 }}
    sx={{ mt: '8px !important' }}
    {...restProps}
  />
);

const ImageField = ({ uploadingMachineImage, uploadingProgress, machineImage, submit, ...restProps }) => (
  <InputImage
    isLoading={uploadingMachineImage}
    loadingValue={uploadingProgress}
    name="machineImage"
    accept="image/png, image/gif, image/jpeg"
    defaultValue={machineImage}
    onBlur={async (event) => submit(event)}
    {...restProps}
  />
);
