import React, { ChangeEvent, FC, FocusEvent, MutableRefObject, memo } from 'react';

import { Control } from 'react-hook-form';

import { NumberForesiteField } from 'components/FormComponents/NumberForesiteField';

import { INPUT_MEMOIZED_COMPONENT_STATIC_PROPS, QTY_FIELD_INPUT_PROPS_STYLE } from '../../QtyField/constants';
import { replaceNegativeAmount } from '../helpers/quantityFieldHelpers';

interface IFieldMemoized {
  name: string;
  inputStyles: any;
  color: string;
  qtyAggregate: number;
  control: Control;
  qtyFieldRef: MutableRefObject<HTMLInputElement | null>;
  readOnly?: boolean;
  value?: string | number;
  error?: boolean;
  handleFocus: () => void;
  handleBlur: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
  handleClick: () => void;
  handleValidate?: (value: string) => void;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  validate: (value: string) => void;
}

const FieldMemoized: FC<IFieldMemoized> = ({
  name,
  validate,
  inputStyles,
  color,
  qtyAggregate,
  control,
  qtyFieldRef,
  readOnly = false,
  value,
  error = false,
  handleFocus,
  handleBlur,
  handleClick,
  handleChange,
}: IFieldMemoized) => (
  <NumberForesiteField
    {...INPUT_MEMOIZED_COMPONENT_STATIC_PROPS}
    control={control}
    name={name}
    sx={inputStyles}
    inputProps={{
      style: {
        ...QTY_FIELD_INPUT_PROPS_STYLE,
        color,
      },
      readOnly,
      value,
      max: replaceNegativeAmount(qtyAggregate),
      min: 0,
      defaultValue: '',
      tabIndex: '-1' as string, // * this needs to be a string, not a number
    }}
    inputRef={qtyFieldRef}
    defaultValue=""
    rules={{ validate }}
    value={value}
    onFocus={() => handleFocus()}
    onBlur={(e) => handleBlur(e)}
    onClick={() => handleClick()}
    uncontrolledOnChange={handleChange}
    tabIndex={'-1' as string} // * this needs to be a string, not a number
    shouldUnregister={false}
    error={error}
  />
);

export default memo(FieldMemoized);
