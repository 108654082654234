import React, { useState } from 'react';

import useLocations from '../Locations/hooks/useLocations';
import useWorkPhases from '../WorkPhases/hooks/useWorkPhases';

const LocationsAndWorkPhaseContext = React.createContext();

const LocationsAndWorkPhaseProvider = ({ children }) => {
  const [locations, setLocations] = useState();
  const workPhases = useWorkPhases(locations, setLocations);

  const { updateSuffix, fetchLocationsByWorkPhase } = workPhases;
  const locationsFunctions = useLocations(
    locations,
    setLocations,
    updateSuffix,
    fetchLocationsByWorkPhase,
  );

  const valueObj = {
    locations: {
      locations,
      setLocations,
      ...locationsFunctions,
    },
    workPhases,
  };

  return (
    <LocationsAndWorkPhaseContext.Provider value={valueObj}>
      {children}
    </LocationsAndWorkPhaseContext.Provider>
  );
};

const useLocationsAndWorkPhases = () => {
  const context = React.useContext(LocationsAndWorkPhaseContext);
  if (context === undefined)
    throw new Error('useLocationsAndWorkPhases must be used within a LocationsAndWorkPhaseContext');
  return context;
};

export { LocationsAndWorkPhaseContext, LocationsAndWorkPhaseProvider, useLocationsAndWorkPhases };
