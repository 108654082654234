import React, { useState, useCallback, useEffect } from 'react';

import { useUser } from 'app/UserContext';
import { CatalogSetupProvider } from 'modules/Materials/CatalogSetup/CatalogSetupHeader/CatalogSetupContext';
import { CreateUOMModal } from 'modules/Materials/components/UOM';
import { useWorkOrder } from 'modules/Shop/WorkOrders/hooks/useWorkOrder';

import DeleteWorkRequestItemModal from './DeleteWorkRequestModal';
import { CatalogModalProvider } from './ModalToCreateCatalogPart/CatalogModalContext';
import ModalToCreateCatalogPart from './ModalToCreateCatalogPart/ModalToCreateCatalogPart';
import SendToCatalogToast from './SendToCatalogToast';
import { useWorkRequestItemsContext } from './WorkRequestItemsContext';
import WorkRequestTable from './WorkRequestTable';

const WorkRequestItems = ({ workRequestSelected, isResponsiveWR }) => {
  const { workRequestItems, setNewUomId, setSelectedWorkRequestId, modalUom, setModalUom } =
    useWorkRequestItemsContext();
  const [modalToCreateCatalogPart, setModalToCreateCatalogPart] = useState(false);
  const [modalToDelete, setModalToDelete] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const { workOrder } = useWorkOrder();

  const canEditRowToWO = ['Draft', 'Blocked'].includes(workOrder?.workOrderStatusTypeName);

  const openModalToDelete = useCallback((selectedRow) => {
    setModalToDelete(true);
    setSelectedItem(selectedRow);
  }, []);

  const openModalToCreateCatalogPart = useCallback((selectedRow) => {
    setModalToCreateCatalogPart(true);
    setSelectedItem(selectedRow);
  }, []);

  const { user } = useUser();

  const onSelectUOMCreated = (uom) => {
    setNewUomId(uom);
  };

  useEffect(() => {
    if (workRequestSelected) setSelectedWorkRequestId(workRequestSelected.workRequestId);
  }, [setSelectedWorkRequestId, workRequestSelected]);

  const openModalUom = useCallback(() => setModalUom(true), [setModalUom]);

  return (
    <CatalogSetupProvider>
      <CatalogModalProvider>
        <WorkRequestTable
          status={workRequestSelected?.workRequestStatusName}
          workRequest={workRequestSelected}
          workRequestItems={workRequestItems || []}
          modalToDelete={modalToDelete}
          openModalToDelete={openModalToDelete}
          openModalUom={openModalUom}
          openModalToCreateCatalogPart={openModalToCreateCatalogPart}
          isResponsiveWR={isResponsiveWR}
          canEditRowToWO={canEditRowToWO}
        />
        <SendToCatalogToast />
        <CreateUOMModal
          open={modalUom}
          onClose={() => setModalUom(false)}
          companyId={user?.companyId}
          onCreate={() => setModalUom(false)}
          onSelectUOMCreated={onSelectUOMCreated}
        />
        {modalToCreateCatalogPart && (
          <ModalToCreateCatalogPart
            open={modalToCreateCatalogPart}
            setShowModal={setModalToCreateCatalogPart}
            selectedItem={selectedItem}
          />
        )}
        {modalToDelete && (
          <DeleteWorkRequestItemModal
            open={modalToDelete}
            workRequestItemName={selectedItem?.workRequestItemName}
            workRequestItemId={selectedItem?.workRequestItemId}
            setShowModal={setModalToDelete}
            workRequest={workRequestSelected}
          />
        )}
      </CatalogModalProvider>
    </CatalogSetupProvider>
  );
};

export default WorkRequestItems;
