import React, { Fragment } from 'react';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';

import InfiniteScroll from 'components/TableComponents/InfiniteScroll';
import NoContentMessage from 'components/TableComponents/NoContentMessage';
import TableContainer from 'components/TableComponents/TableContainer';
import TableHeader from 'components/TableComponents/TableHeaders';
import TableSkeleton from 'components/TableComponents/TableSkeleton';
import {
  handleTableRowClick,
  handleSelectAllClick,
  isSelected,
} from 'components/TableComponents/tableUtilities';

import { useDesignMembersContext } from '../DesignMembersContext';
import { TableRow } from './TableRow';

const headerOptions = [
  { title: 'ID', sortValue: 'userIdentifier' },
  { title: 'User', sortValue: 'userName' },
  { title: 'Email', sortValue: 'email' },
  { title: 'Role' },
  { title: 'Office Phone' },
  { title: 'Mobile Phone' },
  { title: 'Status', sortValue: 'status' },
  { title: 'EMPTY' },
];

const DesignMembersTable = () => {
  const {
    departmentId,
    selected,
    setSelected,
    loading,
    sortHandler,
    paginationHandler,
    selectedItem,
    designMembers,
  } = useDesignMembersContext();

  let message =
    selectedItem?.type === 'FACILITY'
      ? `You must create a Design department within Facility: ${selectedItem?.label} to view members.`
      : 'Open a facility to view members...';

  if (selectedItem?.type === 'FACILITY' && departmentId) {
    message = 'No results';
  }

  const TableBodyContent = () => (
    <TableBody>
      {designMembers.map((departmentUser, index) => (
        <Fragment key={departmentUser.departmentUserId}>
          <TableRow
            departmentUser={departmentUser}
            handleRowClick={() => handleTableRowClick(departmentUser, selected, setSelected)}
            isItemSelected={isSelected(departmentUser, selected)}
          />
          {index === designMembers.length - 1 && (
            <InfiniteScroll data={designMembers} fetchMore={paginationHandler} />
          )}
        </Fragment>
      ))}
    </TableBody>
  );

  return (
    <DesignsTableBorderBox>
      <TableContainer>
        <Table stickyHeader>
          <TableHeader
            headerOptions={headerOptions}
            onSelectAllClick={(e) => handleSelectAllClick(e, designMembers, setSelected)}
            rowCount={designMembers.length}
            numSelected={selected.length}
            handleSortOrder={sortHandler}
            selectable
          />
          {loading ? (
            <TableSkeleton skeletons={headerOptions.map(({ title }) => title)} />
          ) : (
            <TableBodyContent />
          )}
        </Table>
      </TableContainer>
      {!designMembers.length && !loading && <NoContentMessage text={message} />}
    </DesignsTableBorderBox>
  );
};

export default DesignMembersTable;

const DesignsTableBorderBox = (props) => (
  <Box
    sx={{
      border: '1px solid',
      borderColor: 'other.divider.lightSurface',
      borderRadius: '5px',
      position: 'relative',
      padding: '5px 0 0 0',
      height: 'calc(100% - 135px)',
    }}
    {...props}
  />
);
