/* eslint-disable max-lines-per-function */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { createContext, useState, useContext, useEffect, useMemo, useCallback } from 'react';

import { gql } from '@apollo/client';

import { useUser } from 'app/UserContext';
import { partsCloudSearch as PARTS_CLOUD_SEARCH } from 'graphql/queries';
import { DEFAULT_QUERY_PARAMS_BY_CATEGORY_PATH } from 'helpers/cloudSearchParams';
import { encodePartCategoryPathCloudSearchQuery } from 'helpers/stringFunctions';
import usePartCategoriesAPI from 'hooks-api/usePartCategoriesAPI';
import usePartCategoryAPI from 'hooks-api/usePartCategory';
import usePartsCloudSearchByCategoryAPI from 'hooks-api/usePartsCloudSearchByCategoryAPI';
import useLazyPaginatedQuery from 'hooks/useLazyPaginatedQuery';
import { getCategoryHierarchy, getCategoryPathCb } from 'hooks/usePartsCloudSearchCache';

const PartCategoryContext = createContext({
  partCategories: [],
  selectedPartCategories: [],
  onSelectPartCategory: () => {},
  onSelectPartCategoryBreadcrumb: () => {},
  onSelectPartCategoryFromSearch: () => {},
  parts: [],
  onFetchMoreParts: () => {},
  partsCounts: {},
  selectedCategoryPartsCount: 0,
});

const PartCategoryProvider = ({
  children,
  catalogId,
  moduleButtonsColor,
  noAssemblies = false,
  disablePartId = null,
}) => {
  const [partCategories, setPartCategories] = useState([]);
  const [selectedPartCategories, setSelectedPartCategories] = useState([]);
  const [popupColor, setPopupColor] = useState();
  const [parts, setParts] = useState([]);
  const [partsCounts, setPartsCounts] = useState({});
  const [selectedCategoryPartsCount, setSelectedPartCategoryPartsCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const { user } = useUser();
  const { fetchPartCategoryById } = usePartCategoryAPI();
  const { getPartCategories } = usePartCategoriesAPI(user?.companyId, catalogId);
  const { getPartsCount } = usePartsCloudSearchByCategoryAPI(catalogId, 0);
  const [{ lazyLoad: fetchPartItems, paginationHandler: fetchMorePartItems }, { data: partsResponse }] =
    useLazyPaginatedQuery(gql(PARTS_CLOUD_SEARCH));

  const fetchCategoriesPartsCount = useCallback(async () => {
    if (selectedPartCategories.length <= 1) return;
    const categoryPath = getCategoryPathCb(selectedPartCategories);
    for (const { partCategoryId, partCategoryName } of partCategories) {
      const categoryPartsCount = await getPartsCount(`/${categoryPath}/${partCategoryName}`);
      setPartsCounts((prev) => ({ ...prev, [partCategoryId]: categoryPartsCount }));
    }
  }, [getPartsCount, partCategories, selectedPartCategories]);

  useEffect(() => setPopupColor(moduleButtonsColor), [moduleButtonsColor]);

  useEffect(() => fetchCategoriesPartsCount(), [fetchCategoriesPartsCount]);

  useEffect(() => setParts(partsResponse?.partsCloudSearch ?? []), [partsResponse]);

  const fetchRootPartCategory = useCallback(async () => {
    setLoading(true);
    const [rootPartCategory] = await getPartCategories();
    setSelectedPartCategories([rootPartCategory]);
    setLoading(false);
  }, [getPartCategories]);

  useEffect(() => {
    if (!catalogId) return;
    fetchRootPartCategory();
  }, [catalogId, fetchRootPartCategory]);

  const fetchPartCategories = useCallback(
    async (parentCategoryId) => {
      setLoading(true);
      setPartCategories([]);
      const categoriesResponse = await getPartCategories(parentCategoryId);
      setPartCategories(categoriesResponse);
      setLoading(false);
    },
    [getPartCategories],
  );

  useEffect(() => {
    if (!selectedPartCategories.length) return;
    const currentCategory = selectedPartCategories[selectedPartCategories.length - 1];
    if (currentCategory == null) return;
    fetchPartCategories(currentCategory.partCategoryId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPartCategories]);

  const fetchCurrentCategoryPartsCount = useCallback(async () => {
    const currentCategory = selectedPartCategories[selectedPartCategories.length - 1];
    if (!currentCategory) return;

    const categoryPath = getCategoryPathCb(selectedPartCategories);
    const partsCountResponse = await getPartsCount(`/${categoryPath}`);
    setSelectedPartCategoryPartsCount(partsCountResponse);
  }, [selectedPartCategories, getPartsCount]);

  useEffect(() => fetchCurrentCategoryPartsCount(), [fetchCurrentCategoryPartsCount]);

  useEffect(() => {
    const currentCategory = selectedPartCategories[selectedPartCategories.length - 1];
    if (!currentCategory) return;

    const categoryPath = getCategoryPathCb(selectedPartCategories);
    fetchPartItems({
      query: encodePartCategoryPathCloudSearchQuery(`/${categoryPath}`, catalogId),
      ...DEFAULT_QUERY_PARAMS_BY_CATEGORY_PATH,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPartCategories, catalogId, noAssemblies]);

  const onSelectPartCategoryBreadcrumb = useCallback(
    (breadcrumbIndex) =>
      setSelectedPartCategories((prev) => [...prev].filter((_el, index) => index <= breadcrumbIndex)),
    [],
  );

  const onSelectPartCategory = useCallback((category) => setSelectedPartCategories((prev) => [...prev, category]), []);

  const onSelectPartCategoryFromSearch = useCallback(
    async (category) => {
      const categoryHierarchy = await getCategoryHierarchy(category, [], fetchPartCategoryById);
      setSelectedPartCategories(categoryHierarchy);
    },
    [fetchPartCategoryById],
  );

  const onFetchMoreParts = useCallback(
    (skip) => {
      const currentCategory = selectedPartCategories[selectedPartCategories.length - 1];
      if (!skip || !currentCategory) return;
      const categoryPath = getCategoryPathCb(selectedPartCategories);
      fetchMorePartItems(skip, {
        query: encodePartCategoryPathCloudSearchQuery(`/${categoryPath}`, catalogId),
        ...DEFAULT_QUERY_PARAMS_BY_CATEGORY_PATH,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedPartCategories, catalogId, noAssemblies],
  );

  const contextValue = useMemo(
    () => ({
      partCategories,
      partsCounts,
      parts,
      popupColor,
      selectedPartCategories,
      selectedCategoryPartsCount,
      loading,
      onSelectPartCategory,
      onSelectPartCategoryBreadcrumb,
      onSelectPartCategoryFromSearch,
      onFetchMoreParts,
      disablePartId,
      noAssemblies,
    }),
    [
      onSelectPartCategory,
      onSelectPartCategoryBreadcrumb,
      onSelectPartCategoryFromSearch,
      partCategories,
      selectedPartCategories,
      partsCounts,
      popupColor,
      onFetchMoreParts,
      parts,
      selectedCategoryPartsCount,
      loading,
      disablePartId,
      noAssemblies,
    ],
  );

  return (
    <PartCategoryContext.Provider value={contextValue}>{Boolean(catalogId) && children}</PartCategoryContext.Provider>
  );
};

const usePartCategory = () => {
  const context = useContext(PartCategoryContext);
  if (context === undefined) {
    throw new Error('usePartCategory must be used within a PartCategoryContext');
  }
  return context;
};

export { PartCategoryContext, PartCategoryProvider, usePartCategory };
