/* eslint-disable no-unneeded-ternary */
import React from 'react';

import { Stack } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import { Modal } from 'components';

import { useCustomAssembliesContext } from '../../CustomAssembliesContext';
import { useCustomAssemblyTypesContext } from '../../CustomAssemblyTypesContext';
import CustomAssemblyAddForm from './CustomAssemblyAddForm';
import ModalFooter from './ModalFooter';

export const CustomAssemblyAddModal = ({ open, onClose = () => {} }) => {
  const methods = useForm({ mode: 'onChange' });
  const { createCustomAssembly } = useCustomAssembliesContext();
  const { defaultAssemblyType } = useCustomAssemblyTypesContext();
  const {
    handleSubmit,
    formState: { isValid, isSubmitting, errors },
    reset,
  } = methods;

  const handleClose = () => {
    onClose();
    reset();
  };

  const submit = ({ CustomAssemblyId, CustomAssemblyName, AssemblyType, Assignee }) => {
    createCustomAssembly(
      CustomAssemblyName,
      CustomAssemblyId,
      AssemblyType ? AssemblyType : defaultAssemblyType,
      Assignee,
    );
  };

  return (
    <Modal
      open={open}
      title="Create custom assembly"
      onClose={handleClose}
      footer={
        <ModalFooter
          onCancel={handleClose}
          disable={!isValid || isSubmitting || Boolean(Object.keys(errors).length)}
          onSubmit={handleSubmit(submit)}
        />
      }
      sx={{
        '.MuiDialog-paper': {
          width: '585px',
        },
      }}
    >
      <Stack>
        <FormProvider {...methods}>
          <CustomAssemblyAddForm />
        </FormProvider>
      </Stack>
    </Modal>
  );
};
