import React from 'react';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { ThemedCheckBox, StatusCell } from 'components';
import MoreOptionsDropdown from 'components/TableComponents/MoreOptionsDropdown/MoreOptionsDropdown';

import MoreTableRowOptions from './MoreTableRowOptions';

const EvolveMembersTableRow = ({ member, handleRowClick, isItemSelected }) => (
  <>
    <TableRow
      key={member.userId}
      onClick={(e) => handleRowClick(e, member)}
      role="checkbox"
      hover
      tabIndex={-1}
      aria-checked={isItemSelected}
      selected={isItemSelected}
    >
      <TableCell padding="checkbox">
        <ThemedCheckBox
          checked={isItemSelected}
          inputProps={{ 'aria-labelledby': member.userId }}
        />
      </TableCell>
      <TableCell align="left">{member.userIdentifier || '--'}</TableCell>
      <TableCell align="left">{`${member.userFirstName || '--'} ${
        member.userLastName || '--'
      }`}</TableCell>
      <TableCell align="left">{member.userEmail}</TableCell>
      <TableCell align="left">--</TableCell>
      <TableCell align="left">{member.workPhoneNumber || '--'}</TableCell>
      <TableCell align="left">{member.personalPhoneNumber || '--'}</TableCell>
      <StatusCell status={member.inviteStatus} />
      <TableCell>
        {' '}
        {member.inviteStatus === 'Pending' && (
          <MoreOptionsDropdown
            optionsRenderer={(onClose) => (
              <MoreTableRowOptions
                evolveMemberId={member.userId}
                memberStatus={member.inviteStatus}
                onClose={onClose}
              />
            )}
          />
        )}
      </TableCell>
    </TableRow>
  </>
);

export default EvolveMembersTableRow;
