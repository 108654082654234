import React from 'react';

import { Typography } from '@mui/material';

const PartItemDetailAccordionTitle = ({ children, ...restProps }) => (
  <Typography variant="body1" sx={{ width: '197px', ...restProps }}>
    {children}
  </Typography>
);

export default PartItemDetailAccordionTitle;
