import { gql, useLazyQuery, useMutation } from '@apollo/client';

import {
  updateDecisions as UPDATE_DECISIONS,
  updateDecisionsTakeoff as UPDATE_DECISIONS_TAKEOFF,
} from 'graphql/mutations';
import { getDecisions as GET_DECISIONS } from 'graphql/queries';

export const useDecisionsApi = () => {
  const [fetchDecisions, { loading: loadingDecisions, data: decisions, refetch: refetchDecisions }] = useLazyQuery(
    gql(GET_DECISIONS),
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const [updateDecisionsBOMMutation, { error: updateDecisionBOMError, loading: loadingUpdateDecisionBOM }] =
    useMutation(gql(UPDATE_DECISIONS), {
      refetchQueries: ['BillOfMaterialItem'],
      awaitRefetchQueries: true,
    });

  const updateDecisionsBOM = async (body) => {
    await updateDecisionsBOMMutation({
      variables: {
        body,
      },
    });
  };

  const [updateDecisionsTakeoffMutation, { error: updateDecisionTakeoffError, loading: loadingUpdateDecisionTakeoff }] =
    useMutation(gql(UPDATE_DECISIONS_TAKEOFF), {
      refetchQueries: ['TakeoffPadItems'],
    });

  const updateDecisionsTakeoff = async (body) => {
    await updateDecisionsTakeoffMutation({
      variables: {
        body,
      },
    });
  };

  const getDecisions = (id) => {
    fetchDecisions({
      variables: {
        params: {
          id,
        },
        query: {
          orderBy: 'name:asc',
        },
      },
    });
  };

  return {
    getDecisions,
    loadingDecisions,
    decisions: decisions?.getDecisions,
    updateDecisionsBOM,
    updateDecisionsTakeoff,
    refetchDecisions,
    errorUpdateDecision: !!updateDecisionBOMError || !!updateDecisionTakeoffError,
    loadingUpdateDecision: loadingUpdateDecisionBOM || loadingUpdateDecisionTakeoff,
  };
};
