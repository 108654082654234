import { gql, useLazyQuery, useMutation } from '@apollo/client';

import { updateWorkCellTask as UPDATE_WORK_CELL_TASK } from 'graphql/mutations';
import { workCellTasksQueue as GET_WORKCELL_TASKS_QUEUE } from 'graphql/queries';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

export default function useWorkCellTaskDataLayer() {
  const [updateWorkCellTask] = useMutation(gql(UPDATE_WORK_CELL_TASK), {
    refetchQueries: ['workCellTasksQueue'],
  });

  const [getTasks, { data: tasksData, loading: loadingTasks, called: calledTaskData }] = useLazyQuery(
    gql(GET_WORKCELL_TASKS_QUEUE),
    { fetchPolicy: 'cache-and-network' },
  );

  const { handleResponse } = useGraphqlResponseHandler();

  const updateTask = async (
    workCellTaskId: string,
    newSortOrder: number,
    successCallback: () => void,
    failCallback: () => void,
  ) => {
    await handleResponse(
      updateWorkCellTask,
      { variables: { params: { workCellTaskId }, body: { sortOrder: newSortOrder } } },
      { successMessage: '', errorMessage: 'Error reordering.' },
      () => successCallback(),
      () => failCallback(),
    );
  };

  return {
    updateTask,
    getTasks,
    tasksData,
    loadingTasks,
    calledTaskData,
  };
}
