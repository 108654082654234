import React, { FC, ReactNode } from 'react';

import { ButtonProps } from '@mui/material';

import { Modal } from 'components';
import { IModal } from 'components/Modal/@types/modal';

import CancelButton from './CancelButton';
import DoneButton from './DoneButton';
import ErrorMessage from './ErrorMessage';
import Footer, { IFooter } from './Footer';

interface IItemsModal extends IModal {
  title?: string;
  children: ReactNode;
  open: boolean;
}

interface ComponentInterface extends FC<IItemsModal> {
  CancelButton: FC<ButtonProps>;
  DoneButton: FC<ButtonProps>;
  ErrorMessage: FC;
  Footer: FC<IFooter>;
}

const ItemsModal: ComponentInterface = ({ children, open, ...props }: IItemsModal) => (
  <Modal
    title="Add items to work request"
    maxWidth="xl"
    titleContainerStyles={{ padding: '16px 0 16px 24px', height: 'auto' }}
    open={open}
    subtitleColor="surface.lightSurface.secondary"
    contentStyles={{
      paddingTop: 0,
      paddingBottom: 0,
      position: 'relative',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    }}
    paperSx={{ minHeight: '300px', maxHeight: '725px', height: '100%', flex: 1, maxWidth: '704px' }}
    closeButtonColor="#0000008A"
    {...props}
  >
    {children}
  </Modal>
);

ItemsModal.CancelButton = CancelButton;
ItemsModal.DoneButton = DoneButton;
ItemsModal.ErrorMessage = ErrorMessage;
ItemsModal.Footer = Footer;

export default ItemsModal;
