import React from 'react';

import { Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import TaskTypeDescriptionField from './TaskTypeDescriptionField';
import TaskTypeImageField from './TaskTypeImageField';
import TaskTypeNameField from './TaskTypeNameField';

const TaskTypeForm = () => {
  const {
    formState: { isSubmitting },
  } = useFormContext();
  return (
    <Stack sx={{ gap: 2 }}>
      <TaskTypeImageField name="taskTypeImage" disabled={isSubmitting} />
      <TaskTypeNameField
        name="taskTypeName"
        size="small"
        autoComplete="off"
        disabled={isSubmitting}
      />
      <TaskTypeDescriptionField name="taskTypeDescription" size="small" disabled={isSubmitting} />
    </Stack>
  );
};

export default TaskTypeForm;
