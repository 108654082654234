import React from 'react';

import { Stack } from '@mui/material';

import { OpenCollapseIconButton } from 'components';

const CollapseChildrenIcon = ({
  open,
  onClick,
  showIcon = true,
  filled = false,
  useMathSymbols = false,
  disabled = false,
  sx = {},
}) => (
  <Stack sx={{ minWidth: 31, ...sx }}>
    {showIcon && (
      <OpenCollapseIconButton
        disabled={disabled}
        useMathSymbols={useMathSymbols}
        filled={filled}
        open={open}
        onClick={onClick}
        sx={{ fontSize: 21 }}
      />
    )}
  </Stack>
);

export default CollapseChildrenIcon;
