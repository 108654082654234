import React, { useEffect } from 'react';

import { usePopover } from '../../PopoverProvider';
import { getElementId } from '../HelperElements';
import { WaitingElement } from '../WaitingElement';
import ListItem from './LocationsListItem';
import useLocationQuery from './useLocationQuery';

const LocationsList = ({ itemId, depth = 1, parentIds = [] }) => {
  const { locations, updateLocations } = usePopover();

  const {
    fetchLocations,
    locations: childrenLocation,
    loading: locationLoading,
    called: wasCalled,
  } = useLocationQuery(itemId, 50);

  useEffect(() => {
    if (childrenLocation) {
      const auxLocations = [...locations];
      auxLocations.push(...childrenLocation);
      const uniqueLocations = auxLocations.filter((item, index, array) => {
        const auxString = JSON.stringify(item);
        return index === array.findIndex((e) => JSON.stringify(e) === auxString);
      });
      updateLocations(uniqueLocations);
    }
    // eslint-disable-next-line
  }, [locationLoading]);

  if (!wasCalled) fetchLocations();

  return (
    <>
      {childrenLocation?.map((item) => (
        <ListItem
          key={getElementId(item)}
          item={item}
          depth={depth}
          fetchLocations={fetchLocations}
          parentIds={[...parentIds, itemId]}
          renderProps={() => (
            <LocationsList depth={depth + 1} itemId={getElementId(item)} parentIds={[...parentIds, itemId]} />
          )}
        />
      ))}
      {locationLoading && <WaitingElement />}
    </>
  );
};

export default LocationsList;
