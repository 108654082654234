import React, { useState, useEffect } from 'react';

import { LOCAL_STORAGE_CONSTANTS } from 'constants/globalConstants';
import { assignWorkOrderColors } from 'helpers/workCellCardColor';

const { WORK_CELL_COLORS_IDS } = LOCAL_STORAGE_CONSTANTS;

const WorkCellsKanbanContext = React.createContext();

const WorkCellsKanbanProvider = ({ children }) => {
  const [columnActive, setColumnActive] = useState(null);

  const [workOrderColors, setWorkOrderColors] = useState([]);
  const [workOrderIds, setWorkOrderIds] = useState([]);

  useEffect(() => {
    if (workOrderIds.length) {
      const localStorageColors = JSON.parse(localStorage.getItem(WORK_CELL_COLORS_IDS));
      setWorkOrderColors(assignWorkOrderColors(workOrderIds, localStorageColors));
    }
  }, [workOrderIds]);

  useEffect(() => {
    if (workOrderColors.length) localStorage.setItem(WORK_CELL_COLORS_IDS, JSON.stringify(workOrderColors));
  }, [workOrderColors]);

  const contextObj = {
    columnActive,
    setColumnActive,
    setWorkOrderIds,
    workOrderColors,
  };

  return <WorkCellsKanbanContext.Provider value={contextObj}>{children}</WorkCellsKanbanContext.Provider>;
};

const useWorkCellsKanban = () => {
  const context = React.useContext(WorkCellsKanbanContext);
  if (context === undefined) {
    throw new Error('useWorkCellsKanban must be used within a WorkCellsKanbanContext');
  }
  return context;
};

export { useWorkCellsKanban, WorkCellsKanbanContext, WorkCellsKanbanProvider };
