import { Position } from 'react-flow-renderer';

export enum OffsetDirection {
  Down = 'down',
  Up = 'up',
  Right = 'right',
  Left = 'left'
}

export interface IUseCustomSmoothPath {
  sourceX: number;
  sourceY: number;
  targetX: number;
  targetY: number;
  sourcePosition: Position;
  targetPosition: Position;
  borderRadius?: number;
}
