import * as React from 'react';

import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { InfiniteScroll } from 'components/TableComponents';

import Row from './Row';

const TableContent = ({
  data = [],
  headers = [],
  rowComponent: RowComponent,
  onSelected,
  itemsSelected = [],
  onSelectRow,
  onClickOutsideRow = () => {},
  rowSelected = null,
  id = 'id',
  components,
  componentsProps,
  componentsSx,
  rowSx,
  isDraggable = false,
  infiniteScroll = false,
  onFetchMore = () => {},
  selectCell = true,
}) => {
  const buildComponent = () => {
    if (!RowComponent) {
      return null;
    }

    if (typeof RowComponent === 'function') {
      return (
        <TableRow>
          {onSelected && <TableCell colSpan={1} sx={{ ...rowSx }} />}
          <TableCell
            sx={{ ...rowSx }}
            colSpan={headers.length + (onSelected ? 0 : 1)}
            {...componentsProps?.rowComponent}
          >
            <RowComponent />
          </TableCell>
        </TableRow>
      );
    }

    return (
      <TableRow>
        {onSelected && <TableCell colSpan={1} />}
        {Array.isArray(RowComponent) &&
          RowComponent.map((itemRowComponent) => (
            <TableCell
              key={JSON.stringify(itemRowComponent)}
              sx={{ ...rowSx }}
              colSpan={headers.length + (onSelected ? 0 : 1)}
              {...componentsProps?.rowComponent}
            >
              {itemRowComponent()}
            </TableCell>
          ))}
      </TableRow>
    );
  };
  return (
    <TableBody>
      {buildComponent()}
      <TableRow>
        {components?.NoRowsOverlay && data.length === 0 && (
          <TableCell
            colSpan={headers.length + (onSelected ? 1 : 0)}
            sx={{
              height: 'calc(100vh - 330px)',
              width: '100%',
              verticalAlign: 'top',
              ...componentsSx?.noRowsOverlay,
            }}
          >
            <components.NoRowsOverlay {...componentsProps?.noRowsOverlay} />
          </TableCell>
        )}
      </TableRow>
      {data.map((row, index) => (
        <Row
          id={id}
          key={row[id]}
          index={index}
          row={{ ...row, id: row[id] }}
          items={data}
          headers={headers}
          isDraggable={isDraggable}
          onSelected={onSelected}
          itemsSelected={itemsSelected}
          onSelectRow={onSelectRow}
          rowSelected={rowSelected}
          onClickOutsideRow={onClickOutsideRow}
          sx={rowSx}
          selectCell={selectCell}
        />
      ))}
      {infiniteScroll && <InfiniteScroll data={data} fetchMore={onFetchMore} />}
    </TableBody>
  );
};

export default TableContent;
