import React, { useEffect, useState } from 'react';

import { EIconType } from 'helpers/MenuIcons';

import { useChips } from '../CreateLocationsModalContext';
import ScopePackageList from './components/FormFields/ScopePackageList';
import WorkPhaseList from './components/FormFields/WorkPhaseList';
import { RowWithSpacer } from './components/SharedComponents';

const WorkPhaseForm = ({ form }) => {
  const [showCreateWorkPhaseInput, setShowCreateWorkPhaseInput] = useState(false);
  const { scopePackages, addManualWorkPhase } = useChips();

  const { formValues, setValue, control } = form;
  const { scopePackage, workPhase } = formValues;

  const handleAddNewOption = {};
  if (scopePackage) handleAddNewOption.handleAddNewOption = () => setShowCreateWorkPhaseInput(true);

  const buildScopePackageOptions = () =>
    scopePackages?.map((scope) => ({
      label: scope.label,
      value: scope.id,
      icon: EIconType.TARGET,
    }));

  const workPhaseOptions = React.useMemo(() => {
    if (!scopePackage || !scopePackages) return [];

    const workPhasesFromSelectedScopePackage = scopePackages.find(
      ({ id }) => id === scopePackage,
    )?.children;

    if (!workPhasesFromSelectedScopePackage) return [];

    return workPhasesFromSelectedScopePackage.map((workPhase) => ({
      ...workPhase,
      value: workPhase.id,
    }));
  }, [scopePackage, scopePackages]);

  const saveNewWorkPhaseOption = (newWorkPhaseText) => {
    setValue('newOptionName', '');
    setShowCreateWorkPhaseInput(false);
    addManualWorkPhase(newWorkPhaseText, scopePackage);
    setValue('workPhase', [...workPhase, { label: newWorkPhaseText, isNew: true }]);
  };

  useEffect(() => {
    setShowCreateWorkPhaseInput(false);
  }, [scopePackage]);

  return (
    <>
      <RowWithSpacer>
        <ScopePackageList
          onSelectChange={() => setValue('workPhase', undefined)}
          options={buildScopePackageOptions()}
          control={control}
        />
      </RowWithSpacer>
      <RowWithSpacer>
        <WorkPhaseList
          options={workPhaseOptions}
          showCreateWorkPhaseInput={showCreateWorkPhaseInput}
          saveNewOption={saveNewWorkPhaseOption}
          control={control}
          setValue={setValue}
          workPhase={workPhase}
          form={form}
          {...handleAddNewOption}
        />
      </RowWithSpacer>
    </>
  );
};

export default WorkPhaseForm;
