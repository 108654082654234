import React, { useMemo, useState } from 'react';

import { Checkbox, Stack, Typography } from '@mui/material';

import { ForesiteAutocomplete } from 'components/FormComponents';
import { Custom } from 'components/Icons';
import OverFlowTooltip from 'components/Tooltip/OverFlowTooltip';

import { useTasksTableApiContext, useTasksTableDataContext } from './TasksTableContext';

const icon = <Custom.CheckBoxOutlineBlank />;
const checkedIcon = <Custom.CheckBox />;

const autocompleteSx = {
  '& .MuiAutocomplete-inputRoot': { flexWrap: 'nowrap !important' },
  '& .MuiInputBase-root': { height: 24, width: '100%' },
  '& .MuiInputBase-input': { caretColor: 'transparent' },
  '& .MuiAutocomplete-input': { padding: 0 },
  width: '100%',
  margin: '0 auto',
};

const PredecessorAutoComplete = ({ tasks, row }) => {
  const { setIsEditingPredecessors, savePredecessors, setSelectedOptions } = useTasksTableApiContext();
  const { isEditingPredecessors, selectedOptions } = useTasksTableDataContext();

  const predecessorIds = useMemo(
    () =>
      selectedOptions != null ? selectedOptions : row?.taskPredecessorIds?.map((predecessor) => predecessor?.taskId),
    [row?.taskPredecessorIds, selectedOptions],
  );

  const [open, setOpen] = useState(isEditingPredecessors);

  const options = useMemo(
    () =>
      tasks?.map((task, index) => ({
        selectId: task?.taskId,
        label: task?.taskName,
        value: `${task?.taskId}-${index}`,
        selected: predecessorIds?.some((taskId) => `${taskId}-${index}` === `${task?.taskId}-${index}`),
      })) || [],
    [tasks, predecessorIds],
  );

  const handleOnBlur = () => savePredecessors(row);

  const handleStartEditing = () => {
    setOpen(true);
    setIsEditingPredecessors(true);
  };

  return (
    <ForesiteAutocomplete
      autoFocus
      inputValue=""
      className="Predecessors-Autocomplete"
      disableCloseOnSelect
      disableClearable
      getOptionLabel={(option) => option.value}
      noOptionsText="No options available"
      open={open}
      onOpen={handleStartEditing}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      onBlur={() => handleOnBlur()}
      onChange={(_, values) => setSelectedOptions(values.map((value) => value?.selectId))}
      renderOption={(props, option, state) =>
        option.selectId !== row.taskId && <Option key={option.selectId} option={option} props={props} state={state} />
      }
      options={options?.filter((option) => option.selectId !== row.taskId)}
      multiple
      size="small"
      textFieldProps={{ sx: autocompleteSx }}
      defaultValue={options?.filter((option) => option.selected) ?? []}
      renderTags={(tagValue) => <Tags tagValue={tagValue} onClick={handleStartEditing} />}
      data-testid="predecessor-autocomplete"
      sx={{ '&.MuiAutocomplete-root': { width: '100%' } }}
    />
  );
};

export default React.memo(PredecessorAutoComplete);

const Tags = ({ tagValue, onClick }) => (
  <Typography
    key={tagValue.value}
    variant="body2"
    component="span"
    sx={{ overflow: 'hidden', textOverflow: 'ellipsis', minWidth: '180px' }}
    onClick={onClick}
  >
    {tagValue
      ?.sort((a, b) => a.label.localeCompare(b.label))
      .map((tag) => tag.label)
      .join(', ')}
  </Typography>
);

const Option = ({ props, option, state }) => {
  const { label, selectId } = option;

  return (
    <Stack direction="row" component="li" sx={{ padding: '8px !important', gap: 1 }} {...props}>
      <Checkbox
        icon={icon}
        checkedIcon={checkedIcon}
        checked={state.selected}
        color="secondary"
        sx={{ padding: 0 }}
        data-testid={`checkbox_testid_${selectId}`}
      />
      <OverFlowTooltip title={label}>
        <Typography variant="body2" component="span">
          {label}
        </Typography>
      </OverFlowTooltip>
    </Stack>
  );
};
