import React, { useState, useEffect, useMemo } from 'react';

import { Stack } from '@mui/material';
import { useFieldArray, useForm } from 'react-hook-form';

import { getFiltersWithoutDates } from '../helpers/filterPopoverHelpers';
import AddFilterButton from './Buttons/AddFilterButton';
import FilterFormItem from './FilterFormItem';
import DateFilterInput from './Inputs/DateFilterInput';

const NEW_FILTER_VALUE = { column: '', values: [] };

const DEFAULT_VALUES = {
  filters: [NEW_FILTER_VALUE],
};

const FilterForm = ({
  onAppendFilters = () => {},
  filtersData = [],
  onRemoveFilterByColumn = () => {},
  columns = [],
}) => {
  const [isDisable, setIsDisable] = useState(true);
  const filtersWithoutDates = useMemo(() => getFiltersWithoutDates(filtersData), [filtersData]);
  const columnsWithOptions = useMemo(
    () => columns.filter((column) => column.options.length),
    [columns],
  );

  const { control, handleSubmit, setValue, reset } = useForm({
    mode: 'all',
    defaultValues: filtersWithoutDates?.length ? { filters: filtersWithoutDates } : DEFAULT_VALUES,
  });

  useEffect(() => {
    reset({
      filters: filtersWithoutDates.length === 0 ? [NEW_FILTER_VALUE] : filtersWithoutDates,
    });
  }, [reset, filtersWithoutDates]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'filters',
  });

  const handleSelectDates = ({ dateFrom, dateTo }) => {
    onAppendFilters([
      { column: 'dateFrom', values: dateFrom },
      { column: 'dateTo', values: dateTo },
    ]);
  };

  const onSubmit = (data) => {
    onAppendFilters(data.filters.filter((filter) => filter.values.length));
    setIsDisable(false);
  };

  const handleRemoveFilter = (index, field) => {
    const fieldToRemove = fields[index];
    remove(index);
    setIsDisable(false);
    if (fieldToRemove?.column) {
      onRemoveFilterByColumn(field.column);
      if (fields.length === 1) {
        setIsDisable(true);
      }
    }
  };

  const handleRemoveLastFilter = (columnToDelete, column) => {
    onRemoveFilterByColumn(columnToDelete);
    onAppendFilters([{ column, values: [] }]);
    handleSubmit(onSubmit)();
  };

  const handleAddRow = () => {
    append(NEW_FILTER_VALUE);
    setIsDisable(true);
  };

  return (
    <form>
      <DateFilterInput onChange={handleSelectDates} />

      <Stack direction="column" justifyContent="flex-start" sx={{ width: '100%' }}>
        {fields.map((field, index) => (
          <FilterFormItem
            key={field.id}
            control={control}
            prefixName={`filters.${index}`}
            onRemove={() => {
              handleRemoveFilter(index, field);
            }}
            onSubmit={handleSubmit(onSubmit)}
            onRemoveFilter={handleRemoveLastFilter}
            setValue={setValue}
            filters={filtersData}
            columns={columnsWithOptions}
          />
        ))}

        <Stack direction="row" sx={{ width: '100%', mt: 1 }} alignItems="center">
          <AddFilterButton disabled={isDisable} onClick={handleAddRow} />
        </Stack>
      </Stack>
    </form>
  );
};

export default FilterForm;
