import React from 'react';

import { /* Stack, */ Checkbox } from '@mui/material';

import { CustomAssemblyAction } from './CustomAssemblyAction';
import { CustomAssemblyAssignee } from './CustomAssemblyAssignee';
import { CustomAssemblyBopCalculator } from './CustomAssemblyBopCalculator';
import { CustomAssemblyFlag } from './CustomAssemblyFlag';
import { CustomAssemblyQtyRequired } from './CustomAssemblyQtyRequired';
import { CustomAssemblyStatus } from './CustomAssemblyStatus';
import { CustomAssemblyTypeRow } from './CustomAssemblyTypeRow';
import IdentifierHeader from './IdentifierHeader';
// import MoreMenu from './MoreMenu';
// import NewCustomAssemblyBtnCell from './NewCustomAssemblyBtnCell';

export const DEFAULT_ROW = {
  id: 1,
  identifier: 'Unassigned',
  assemblyName: '',
  workflow: '',
  status: '',
  type: 'CustomAssemblyType',
};

export const GetColumns = (/* gridApi */) => [
  {
    field: 'flag',
    sortable: false,
    resizable: false,
    flex: 0.5,
    renderHeader: () => <Checkbox />,
    renderCell: (params) => <CustomAssemblyFlag params={params} />,
  },
  {
    field: 'identifier',
    sortable: true,
    resizable: true,
    flex: 8,
    renderHeader: () => <IdentifierHeader />,
    renderCell: (params) => <CustomAssemblyTypeRow params={params} />,
  },
  {
    field: 'qtyRequired',
    headerName: 'Qty Required',
    sortable: true,
    resizable: true,
    flex: 3,
    renderCell: (params) => <CustomAssemblyQtyRequired params={params} />,
  },
  {
    field: 'workflow',
    headerName: 'Bop Calculator',
    sortable: true,
    resizable: true,
    flex: 4,
    renderCell: (params) => <CustomAssemblyBopCalculator params={params} />,
  },
  {
    field: 'action',
    headerName: 'Action',
    sortable: true,
    resizable: true,
    flex: 3,
    renderCell: (params) => <CustomAssemblyAction params={params} />,
  },
  {
    field: 'status',
    headerName: 'Status',
    sortable: true,
    resizable: true,
    flex: 2,
    renderCell: (params) => <CustomAssemblyStatus params={params} />,
  },
  {
    field: 'assignedTo',
    headerName: 'Assignee',
    sortable: true,
    resizable: true,
    flex: 3,
    renderCell: (params) => <CustomAssemblyAssignee params={params} />,
  },
  // {
  //   field: 'MoreMenu',
  //   headerName: '',
  //   headerAlign: 'right',
  //   sortable: false,
  //   flex: 1,
  //   renderCell: (params) =>
  //     params.row.type === 'CustomAssemblyType' && (
  //       <Stack direction="row" alignItems="center" columnGap="10px" sx={{ width: '90%' }}>
  //         <NewCustomAssemblyBtnCell params={params} />
  //         <MoreMenu params={params} gridApi={gridApi} />
  //       </Stack>
  //     )
  // }
];
