import React from 'react';

import Tabs from '@mui/material/Tabs';

const TabBar = ({ children, ...rest }) => (
  <SecondaryColorTabs {...rest}>{children}</SecondaryColorTabs>
);

export default TabBar;

const SecondaryColorTabs = ({ sx, ...props }) => (
  <Tabs
    textColor="secondary"
    indicatorColor="secondary"
    sx={{
      marginLeft: 4,
      '& .MuiTab-root': {
        padding: '6px 48px',
        fontWeight: 500,
      },
      justifyContent: { xs: 'flex-start', lg: 'center' },
      ...sx,
    }}
    {...props}
  />
);
