import { useCallback } from 'react';

import { gql, useLazyQuery, useMutation } from '@apollo/client';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { addWorkRequestDocument as ADD_WORK_REQUEST_DOCUMENT } from 'graphql/mutations';
import {
  workRequestDocuments as WORK_REQUEST_DOCUMENTS,
  documents as DOCUMENTS,
  workOrders as WORK_ORDERS,
} from 'graphql/queries';

const useWorkRequestDocumentAPI = () => {
  const { selectedItem } = useFacilitiesProjects();
  const [addWorkRequestDocuments, { loading: addWorkRequestDocumentLoading }] = useMutation(
    gql(ADD_WORK_REQUEST_DOCUMENT),
  );

  const [fetchWorkRequestDocumentsAPI, { data, loading: fetchWorkRequestDocumentsLoading }] = useLazyQuery(
    gql(WORK_REQUEST_DOCUMENTS),
    { fetchPolicy: 'cache-and-network' },
  );

  const [fetchDocuments] = useLazyQuery(gql(DOCUMENTS), { fetchPolicy: 'cache-and-network' });

  const fetchWorkRequestDocuments = useCallback(
    async (workRequestId) => {
      await fetchWorkRequestDocumentsAPI({
        variables: {
          query: {
            workRequestId,
          },
        },
      });
    },
    [fetchWorkRequestDocumentsAPI],
  );

  const addDocumentsToWorkRequest = useCallback(
    async ({ workRequestId, documentIds, externalUrl }) =>
      addWorkRequestDocuments({
        variables: {
          body: {
            workRequestId,
            documentIds,
            externalUrl,
          },
        },
        refetchQueries: [
          {
            query: gql(WORK_REQUEST_DOCUMENTS),
            variables: {
              query: { workRequestId },
            },
          },
          {
            query: gql(DOCUMENTS),
            variables: {
              query: { documentIds },
            },
          },
          {
            query: gql(WORK_ORDERS),
            variables: {
              query: { faciltyId: selectedItem?.id },
            },
          },
        ],
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addWorkRequestDocuments],
  );

  const fetchDocumentsByDocumentIds = useCallback(
    async (documentIds) => {
      const documents = await fetchDocuments({
        variables: {
          query: {
            documentIds,
          },
        },
      });
      return documents;
    },

    [fetchDocuments],
  );

  return {
    fetchWorkRequestDocumentsResp: data?.workRequestDocuments,
    fetchWorkRequestDocumentsLoading,
    addWorkRequestDocumentLoading,
    callbacks: {
      fetchWorkRequestDocuments,
      fetchDocumentsByDocumentIds,
      addDocumentsToWorkRequest,
    },
  };
};

export default useWorkRequestDocumentAPI;
