import { useEffect, useState } from 'react';

import { TaskStatusType } from 'graphql/types';

import useGetTaskStatusTypes from '../useGetTaskStatusTypes';

const getOpenStatusIds = (status: TaskStatusType[]) => {
  const openStatus = status.filter(({ taskStatusTypeName }) => taskStatusTypeName !== 'Completed');
  return openStatus?.map((status) => status.taskStatusTypeId).join(',') || null;
};

const getClosedStatusId = (status: TaskStatusType[]) =>
  status.find(({ taskStatusTypeName }) => taskStatusTypeName === 'Completed')?.taskStatusTypeId || null;

type taskStatusTabs = {
  open: string | null;
  closed: string | null;
};

const useTaskStatusTypeIds = () => {
  const [taskStatus, setTaskStatus] = useState<taskStatusTabs>({ open: null, closed: null });
  const { taskStatusTypes } = useGetTaskStatusTypes();

  useEffect(() => {
    if (taskStatusTypes.length)
      setTaskStatus({
        open: getOpenStatusIds(taskStatusTypes),
        closed: getClosedStatusId(taskStatusTypes) as string,
      });
  }, [taskStatusTypes]);

  return {
    open: taskStatus.open,
    closed: taskStatus.closed,
  };
};

export default useTaskStatusTypeIds;
