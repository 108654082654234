import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputBase from '@mui/material/InputBase';

const MessageListTrashToolbar = ({ onSearch = () => {} }) => {
  // Temporary until GQL implementation
  const [searchValue, setSearchValue] = React.useState('');
  const search = (event) => {
    setSearchValue(event.target.value);
    onSearch(event.target.value);
  };
  const clearAll = () => {};

  return (
    <MessageListToolbarWrapper>
      <SearchBar value={searchValue} onChange={search} />
      <ClearAllMessageButton onClick={clearAll} />
    </MessageListToolbarWrapper>
  );
};

export default MessageListTrashToolbar;

const MessageListToolbarWrapper = ({ children }) => (
  <Box
    sx={{
      display: 'block',
      padding: '25px 17px',
      height: '130px',
    }}
  >
    {children}
  </Box>
);

const ClearAllMessageButton = ({ onClick }) => (
  <Button
    color="primary"
    variant="outlined"
    onClick={onClick}
    fullWidth
    sx={{
      height: '40px',
      fontSize: '15px',
      lineHeight: '24px',
      letterSpacing: '0.46px',
      fontWeight: 550,
    }}
  >
    Clear All
  </Button>
);

const SearchBar = ({ value, onChange }) => (
  <Box>
    <InputBase
      onChange={onChange}
      value={value}
      placeholder="Search…"
      inputProps={{ 'aria-label': 'search' }}
      sx={{
        width: '100%',
        height: '32px',
        padding: '6px 16px',
        backgroundColor: 'other.selected',
        borderRadius: '5px',
        fontSize: '14px',
        lineHeight: '20px',
        marginBottom: '16px',
      }}
    />
  </Box>
);
