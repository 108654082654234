/* eslint-disable no-console */
import React, { forwardRef } from 'react';

import { RightSideFlyout } from 'components';
import { Custom } from 'components/Icons';
import { useMaterialsConfig } from 'hooks-api/useMaterialsConfig';
import CatalogPopup from 'modules/Materials/components/CatalogPopup/CatalogPopup';
import PropertiesPopup from 'modules/Materials/components/PropertiesPopup/PropertiesPopup';
import { useAssignationMode } from 'modules/Materials/features/assignation-mode/useAssignationMode';

import { filterLineItemsWithDecisions } from '../BillOfMaterialsById/BOMCatalogTable/Decisions/helpers';
import { getManufacturerIdByName, getUnitOfMeasureIdByCode } from '../commons/helpers';
import { useTakeOff } from './context/TakeOffContext';

const TakeOffFlyoutMenu = forwardRef((props, ref) => {
  const {
    pads,
    padIndex,
    onAddTakeOffPadItem,
    sourcePads,
    chipList,
    assignModeOn,
    decisionsStatesAndCallbacks: { updateLineItemsAdded, lineItemDecision, setCatalogItemsPendingToAdd },
  } = useTakeOff();
  const {
    data: { assignMetadata },
  } = useAssignationMode(useTakeOff);
  const { manufactured, unitOfMeasure } = useMaterialsConfig();
  const selectedPad = pads[padIndex];
  const sourcePad = sourcePads.find((pad) => pad.sourcePadId === selectedPad?.takeoffPadId);

  const handleAddItems = async (items, hasAlreadyDecisions) => {
    const takeoffItems = items.map((item) => ({
      lineItemTypeId: item.lineItemTypeId,
      manufacturerId: getManufacturerIdByName(item.manufacturerName, manufactured),
      unitOfMeasureId: getUnitOfMeasureIdByCode(item.unitOfMeasureCode, unitOfMeasure),
      quantity: Number(item.qty),
      partId: item.partId,
      manufacturerCatalogNumber: item.catalogNumber ?? '',
      lineItemName: item.partName,
      ...assignMetadata,
    }));
    const takeOffPadId = pads[padIndex]?.takeoffPadId;

    if (hasAlreadyDecisions) {
      setCatalogItemsPendingToAdd({ takeOffPadId, items: takeoffItems });
      return;
    }

    const newCatalogItems = await onAddTakeOffPadItem({
      takeOffPadId,
      items: takeoffItems,
    });

    if (chipList.length !== 0 && !assignModeOn) return;

    filterLineItemsWithDecisions(newCatalogItems?.data?.addTakeOffPadItem, updateLineItemsAdded);
  };

  const flyoutItems = {
    staticList: [
      ...(!selectedPad?.isSourcePad
        ? [
            {
              section: 'catalog',
              icon: Custom.MenuBook,
              children: ({ onPanelClose, onDock, hasAlreadyDecisions }) => (
                <CatalogPopup
                  ref={ref}
                  onClose={onPanelClose}
                  onDock={onDock}
                  onAddItems={handleAddItems}
                  hasAlreadyDecisions={hasAlreadyDecisions}
                />
              ),
            },
          ]
        : []),
      ...(selectedPad?.isSourcePad
        ? [
            {
              section: 'settings',
              icon: Custom.Settings,
              children: ({ onPanelClose, onDock }) => (
                <PropertiesPopup ref={ref} onClose={onPanelClose} onDock={onDock} sourcePad={sourcePad} />
              ),
            },
          ]
        : []),
    ],
  };

  const onDock = () => console.log('docking');

  return <RightSideFlyout flyoutItems={flyoutItems} onDock={onDock} hasAlreadyDecisions={!!lineItemDecision} />;
});

export default TakeOffFlyoutMenu;
