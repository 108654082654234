import React, { useCallback } from 'react';

import { useGridApiRef } from '@mui/x-data-grid-pro';

import { ItemsDataGridProProvider } from 'components/ItemsDatagridPro';
import ItemsDataGridPro from 'components/ItemsDatagridPro/ItemsDatagridPro';

import { useCustomAssemblyTypesContext } from '../CustomAssemblyTypesContext';
import { NoCustomAssemblyTypeOverlay } from './NoCustomAssemblyTypeOverlay';
import useHandleHover from './useHandleHover';
import { GetColumns, DEFAULT_ROW } from './Utilities';

export const CustomAssemblyTypesTable = () => {
  const apiRef = useGridApiRef();

  const { setLineItemIds, customAssemblyTypes, isCreatingCustomAssemblyType, setHoveredRow, errorInForm, renamingId } =
    useCustomAssemblyTypesContext();

  useHandleHover(apiRef, setHoveredRow);

  const handleSelectItems = useCallback(
    (items) => {
      const customAssemblyIds = items?.flatMap(({ id, type }) => (type === 'Custom Assembly' ? id : []));
      setLineItemIds((lineItemsIdsPrev) => {
        const excludedFromPrev = new Set(lineItemsIdsPrev);
        const excludedFromCurrent = new Set(customAssemblyIds);

        const currentIds = customAssemblyIds.filter((id) => !excludedFromPrev.has(id));
        const prevIds = lineItemsIdsPrev.filter((id) => !excludedFromCurrent.has(id));

        return [...prevIds, ...currentIds];
      });
    },
    [setLineItemIds],
  );

  return (
    <ItemsDataGridProProvider contextId="CustomAssemblyTypesTable">
      <ItemsDataGridPro
        apiRef={apiRef}
        rowHeight={32}
        headerHeight={32}
        hideFooterPagination
        checkboxSelection={false}
        disableColumnMenu
        columns={GetColumns(apiRef)}
        rows={customAssemblyTypes.length ? [DEFAULT_ROW, ...customAssemblyTypes] : [DEFAULT_ROW, { id: 'none' }]}
        loading={isCreatingCustomAssemblyType}
        components={{
          NoRowsOverlay: NoCustomAssemblyTypeOverlay,
        }}
        onSelected={handleSelectItems}
        getRowClassName={(params) => (params.id === renamingId && errorInForm ? 'edit-row-error' : '')}
        sx={{
          '& .MuiDataGrid-virtualScrollerRenderZone': {
            width: '100%',
          },
          '& .edit-row-error.edit-row-error': {
            alignItems: 'center',
            minHeight: '48px !important',
          },
          '& .edit-row-error .MuiDataGrid-cell': {
            minHeight: '48px !important',
          },
        }}
      />
    </ItemsDataGridProProvider>
  );
};
