import { useCallback, useEffect, useState } from 'react';

import { gql, useLazyQuery } from '@apollo/client';

import { workCells as WORK_CELLS } from 'graphql/queries';
import useGetShopConfigurations from 'modules/Shop/ShopProperties/InfoTab/useGetShopConfigurations';

const mapWorkCellToSelect = (workCells = []) =>
  workCells.map(({ workCellName: label, workCellId: value }) => ({ label, value }));

export default function useWorkCellsDataLayer(selectedFacilityId) {
  const { activeShopConfiguration: shopConfiguration } = useGetShopConfigurations();
  const [workCells, setWorkCells] = useState([]);

  const [getWorkCells, { loading }] = useLazyQuery(gql(WORK_CELLS));

  const fetchWorkCells = useCallback(async () => {
    setWorkCells([]);
    if (!selectedFacilityId || !shopConfiguration?.value) return;
    const { data } = await getWorkCells({
      variables: {
        query: {
          facilityId: selectedFacilityId,
          shopConfigurationId: shopConfiguration?.value,
          orderBy: 'workCellName',
        },
      },
    });

    const workCells = data?.workCells || [];
    setWorkCells(mapWorkCellToSelect(workCells));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFacilityId, getWorkCells, shopConfiguration]);

  useEffect(() => {
    fetchWorkCells();
  }, [fetchWorkCells]);

  return { workCells, loadingWorkCells: loading };
}
