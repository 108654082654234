import React from 'react';

import { Box, CircularProgress, Stack } from '@mui/material';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { CancelButton, CreateButton } from 'components';

import NoShopDepartmentMessage from '../NoShopDepartmentMessage';
import FormFields from './FormFields';
import useInfoForm, { getFormattedValues } from './useInfoForm';

const InfoForm = () => {
  const { selectedItem, shopDepartmentId } = useFacilitiesProjects();

  const methods = useForm({ mode: 'all', defaultValues: getFormattedValues() });
  const {
    reset,
    formState: { isDirty, isValid, isSubmitting },
  } = methods;

  const { onSubmit, creating, updating, loadingShop } = useInfoForm(reset);

  const submitDisabled = !isDirty || !isValid || isSubmitting;
  const showLoading = creating || updating || loadingShop;

  if (!shopDepartmentId) return <NoShopDepartmentMessage selectedItem={selectedItem} />;

  return (
    <Stack mt={0} direction="row" gap="56px">
      <FormProvider {...methods}>
        <Box width="100%" maxWidth="505px">
          <FormFields disabled={loadingShop} />
          <Footer
            loading={showLoading}
            reset={reset}
            onSubmit={onSubmit}
            submitDisabled={submitDisabled}
          />
        </Box>
      </FormProvider>
    </Stack>
  );
};

export default InfoForm;

const Footer = ({ loading, reset, onSubmit, submitDisabled }) => {
  const { handleSubmit } = useFormContext();

  return (
    <Stack mt={2} direction="row" alignItems="center" justifyContent="flex-end">
      {loading && <CircularProgress />}
      <CancelButton color="secondary" onClick={() => reset()} />
      <CreateButton
        color="secondary"
        disabled={submitDisabled}
        buttonText="save changes"
        onClick={handleSubmit(onSubmit)}
      />
    </Stack>
  );
};
