import { CONTAINER_TYPES, ROW_TYPES } from '../constants/constants';
import { useDataContext } from '../contexts/DataContext';
import useDataLayer from './useDataLayer';

export default function useDragEvents() {
  const {
    mapTaskTypeToWorkCell,
    mapTaskTypeToMachine,
    mapMachineToWorkCell,
    handleChangeWorkCellMachine,
  } = useDataLayer();

  const { setIsDragging, setDraggingTaskId } = useDataContext();

  const handleDragTaskFromFlyout = (destinationTableName, taskId, destinationId, workCellId) => {
    const hasDraggedToWorkCell = destinationTableName === CONTAINER_TYPES.WORK_CELL_TASKS;

    if (hasDraggedToWorkCell) return mapTaskTypeToWorkCell(taskId, destinationId);
    return mapTaskTypeToMachine(taskId, destinationId, workCellId);
  };

  const onDragEnd = ({ source, destination, draggableId }) => {
    setIsDragging(false);
    setDraggingTaskId(null);
    if (!destination) return;
    if (source.droppableId === destination.droppableId) return;

    const [destinationTableName, destinationId, workCellId] = destination.droppableId.split('_');
    const [sourceTableName, sourceTableId] = source.droppableId.split('_');
    const [, sourceId, workCellMachineId] = draggableId.split('_');

    if (sourceTableName === ROW_TYPES.MACHINE && workCellMachineId) {
      if (sourceTableId === destinationId) return;
      handleChangeWorkCellMachine(sourceId, workCellMachineId, destinationId, sourceTableId);
      return;
    }

    if (sourceTableName === ROW_TYPES.MACHINE && draggableId)
      mapMachineToWorkCell(draggableId, destinationId);

    if (sourceTableName === ROW_TYPES.TASK_TYPES)
      handleDragTaskFromFlyout(destinationTableName, draggableId, destinationId, workCellId);
  };

  const onDragStart = (result) => {
    setIsDragging(true);
    if (result.type === ROW_TYPES.TASK_TYPES) setDraggingTaskId(result.draggableId);
  };

  return { onDragEnd, onDragStart };
}
