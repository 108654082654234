import React from 'react';

import { useQuery, gql } from '@apollo/client';

import { useUser } from 'app/UserContext';
import ControlledChipsAutoComplete from 'components/FormComponents/ControlledChipsAutoComplete';
import { emailRegEx } from 'constants/regEx';
import { users as USERS } from 'graphql/queries';

const ProjectInviteForm = ({ control, loadingProp }) => {
  const { user } = useUser();
  const { data, loading, error } = useQuery(gql(USERS), {
    variables: {
      query: {
        companyId: user.companyId,
      },
    },
  });

  if (error) return <p>{error.message}</p>;

  const users = data?.users || [];

  const formattedOptions = users.map(({ userEmail, userId }) => ({
    label: userEmail,
    id: userId,
  }));

  return (
    <ControlledChipsAutoComplete
      options={formattedOptions}
      control={control}
      name="newProjectUsers"
      rules={{
        pattern: emailRegEx,
        message: 'Email is Invalid',
        required: true,
      }}
      placeholder="Search or add email"
      // eslint-disable-next-line react/jsx-no-duplicate-props
      label="Email or Name"
      defaultValue={[]}
      helperText="Invite by email if user has not already joined the company"
      isDisabled={loading || loadingProp}
    />
  );
};

export default ProjectInviteForm;
