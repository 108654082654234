import React from 'react';

import { Stack } from '@mui/material';

const CategoryItemContent = ({ sx, children }) => (
  <Stack direction="row" sx={{ ...sx, gap: 1, width: '100%', alignItems: 'center' }}>
    {children}
  </Stack>
);

export default CategoryItemContent;
