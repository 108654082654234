import { useEffect, useState } from 'react';

import { useUser } from 'app/UserContext';
import { Event } from 'graphql/types';
import { validateUserNameOrEmail } from 'helpers/userHelpers';

import { useFetchTaskTypeImage } from './useFetchTaskTypeImage';

type CachedUserDetails = {
  username: string;
  avatar: string;
};

type CachedUserObject = {
  [key: string]: CachedUserDetails;
};

const getUniqueUserIds = (events: Event[] = []): string[] => {
  const allUserIds = events.map((row) => row.userId as string);
  return [...new Set(allUserIds)];
};

export default function useCachedUserPhotos(events: Event[] = []) {
  const { updateUserListener } = useUser();
  const [cachedUserPhotos, setCachedUserPhotos] = useState<CachedUserObject>({});
  const { fetchTaskTypeUserData } = useFetchTaskTypeImage();

  const getTaskTypeUserData = async (userId: string) => {
    if (cachedUserPhotos[userId]) return cachedUserPhotos[userId];
    return fetchTaskTypeUserData(userId);
  };

  useEffect(() => {
    const controller = new AbortController();

    const getInitialProfilePhotos = async () => {
      if (!events.length) return;

      const uniqueUserIds = getUniqueUserIds(events);
      const nonCachedUserIds = uniqueUserIds.filter((userId) => Boolean(userId) && !cachedUserPhotos[userId]);
      const allUsersAreCached = !nonCachedUserIds.length;

      if (allUsersAreCached) return;

      const usersData = await Promise.all(
        nonCachedUserIds.map(async (userId) => {
          try {
            const userByIdResponse = await getTaskTypeUserData(userId);
            return { userId, data: userByIdResponse };
          } catch {
            return null;
          }
        }),
      );

      setCachedUserPhotos((prevCachedUserPhotos) => {
        const cachedData = { ...prevCachedUserPhotos };
        usersData.forEach((resp) => {
          if (resp) cachedData[resp.userId] = resp.data as CachedUserDetails;
        });
        return cachedData;
      });
    };

    getInitialProfilePhotos();

    return () => controller.abort();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events.length]);

  useEffect(() => {
    let isMounted = true;

    const handleChangeUserPhotoWhenUserIsEdited = () => {
      if (updateUserListener?.user?.userId && updateUserListener?.getPresignedURL && isMounted) {
        setCachedUserPhotos((prev) => ({
          ...prev,
          [updateUserListener?.user?.userId]: {
            username: validateUserNameOrEmail(updateUserListener?.user),
            avatar: updateUserListener.getPresignedURL,
          },
        }));
      }
    };

    handleChangeUserPhotoWhenUserIsEdited();

    return () => {
      isMounted = false;
    };
  }, [updateUserListener]);

  return {
    cachedUserPhotos,
  };
}
