import React from 'react';

import Box from '@mui/material/Box';
import { WidthProvider, Responsive } from 'react-grid-layout';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const StyledGridLayout = (props) => (
  <Box
    sx={{
      position: 'relative',
      transition: 'height 200ms ease',
      flexGrow: 1,
      maxWidth: 1940,
      '& .react-grid-item': {
        transition: 'all 200ms ease',
        '&.cssTransforms': {
          transitionProperty: 'transform',
        },
        '& > .react-resizable-handle': {
          position: 'absolute',
          right: '3px',
          bottom: '3px',
          width: '5px',
          height: '5px',
          borderRight: '2px solid rgba(0, 0, 0, 0.4)',
          borderBottom: '2px solid rgba(0, 0, 0, 0.4)',
          zIndex: 999,
        },
        '&.react-draggable-dragging': {
          transition: 'none',
        },
        '&.react-grid-placeholder': {
          background: '#575757',
          borderRadius: '4px',
        },
      },

      '& .draggable-area': {
        cursor: 'move',
      },
    }}
  >
    <ResponsiveReactGridLayout {...props} />
  </Box>
);

export default StyledGridLayout;
