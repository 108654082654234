import React, { useRef } from 'react';

import { CenteredLoadSpinner } from 'components';
import useUrlParams from 'hooks/useUrlParams';

import { MainProvider, WorkflowsEditorHeader } from './components';
import { useWorkflowEditor } from './WorkflowsEditorContext';

const WorkfowsEditor = () => {
  const reactFlowWrapper = useRef(null);
  const { params } = useUrlParams();
  const { is_new: isNew } = params;

  const { loading } = useWorkflowEditor();

  return (
    <div style={{ height: '100%' }} className="reactflow-wrapper" ref={reactFlowWrapper}>
      <WorkflowsEditorHeader openNameField={Boolean(isNew)} />
      {loading ? (
        <CenteredLoadSpinner sx={{ height: '100%', alignItems: 'center' }} theme="secondary" />
      ) : (
        <MainProvider />
      )}
    </div>
  );
};

export default WorkfowsEditor;
