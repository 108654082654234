import React, { useCallback } from 'react';

import { Button, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { Custom } from 'components/Icons';
import { Folder } from 'graphql/types';
import useDocumentTypes from 'modules/Shop/Fabrication/TaskViewer/PlansModelsContent/hooks/useDocumentTypes';
import { shopColors, surfaceColors } from 'styles/theme/colors';

import { useTaskViewerContext } from '../../../../TaskViewerContext';
import { AddLinkButtonProps } from '../../@types/tasksTypes';

const getDocumentStoragePath = ({ folder, file }: { folder: Folder; file: File }) => {
  const documentGUID = uuidv4();
  return `${folder?.storagePath}/${documentGUID}-${file.name}`;
};

interface AttachFilesProps {
  disabled: boolean;
  setIsLoader: (isLoader: boolean) => void;
}

const AttachFiles = ({ disabled, setIsLoader }: AttachFilesProps) => {
  const { folderApiData, addTaskByDocument, uploadFile, addDocumentToFolder, setIsAddingAnAttach } =
    useTaskViewerContext();
  const { commonTypeId } = useDocumentTypes();
  const { id: taskId } = useParams();

  const { enqueueSnackbar } = useSnackbar();

  const uploadFileToS3 = useCallback(
    async (file: File) => {
      const storagePath = getDocumentStoragePath({
        folder: folderApiData,
        file,
      });
      await uploadFile(file, storagePath);
      return { filename: file.name, storagePath };
    },
    [folderApiData, uploadFile],
  );

  const getPromiseAddDocument = async ({ filename, storagePath }: { filename: string; storagePath: string }) => {
    const { data } = await addDocumentToFolder({
      documentType: { documentTypeId: commonTypeId },
      filename,
      storagePath,
      folder: folderApiData,
    });

    if (!data) throw new Error('Could not add document to folder.');

    return data?.addDocument?.documentId;
  };

  const onUpload = async (event: any) => {
    try {
      if (!folderApiData?.storagePath) throw new Error('Could not get folder for project.');
      setIsAddingAnAttach(true);
      setIsLoader(true);
      const uploadedFiles = await Promise.all([...event.target.files].map((file) => uploadFileToS3(file)));
      const promisesAddDocumentsToFolder = uploadedFiles.map(getPromiseAddDocument);
      const results = await Promise.all(promisesAddDocumentsToFolder);
      setIsLoader(false);
      const documentIds = results.filter(Boolean);
      addTaskByDocument({ taskId, documentIds });
    } catch (error) {
      enqueueSnackbar('Error during file upload', { variant: 'error' });
    } finally {
      setIsLoader(false);
    }
    setIsAddingAnAttach(false);
    event.preventDefault();
  };

  const color = disabled ? surfaceColors.lightSurface.disabled : shopColors.main;

  return (
    <Stack direction="row" alignItems="center" ml={1.5}>
      <UploadButton disabled={disabled} component="label" startIcon={<Custom.AttachFile fill={color} />}>
        <Typography ml={1} variant="body1" color={color}>
          Attach Files
        </Typography>
        <input data-testid="uploadInput" hidden type="file" multiple onChange={onUpload} />
      </UploadButton>
    </Stack>
  );
};

const UploadButton = (props: AddLinkButtonProps) => <Button sx={{ textTransform: 'none' }} {...props} />;

export default AttachFiles;
