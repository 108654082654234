import useAddWorkOrderItem from 'hooks-api/mutations/useAddWorkOrderItem';
import useLazyPaginatedWorkOrderItems from 'hooks-api/queries/workOrderItems/useLazyPaginatedWorkOrderItems';
import useWorkRequestItemTypesIds from 'hooks-api/useWorkRequestItemTypeIds';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

import { getCachedItems } from '../helpers/cacheHelpers';

export const useAddWorkOrderItemDataLayer = (workOrder) => {
  const { writeInItemTypeId } = useWorkRequestItemTypesIds();
  const { handleResponse } = useGraphqlResponseHandler();

  const { workOrderItemsQuery } = useLazyPaginatedWorkOrderItems(workOrder);

  const [addWorkOrderItemMutation] = useAddWorkOrderItem({});

  const handleAddWorkOrderItem = ({ unitOfMeasureId, workOrderItemName, quantity, itemType = null }) => {
    const body = {
      workOrderItemTypeId: itemType ?? writeInItemTypeId,
      unitOfMeasureId: unitOfMeasureId?.value,
      workOrderId: workOrder?.workOrderId,
      workOrderItemName,
      quantity,
      workOrderItemIdentification: '',
    };

    handleResponse(
      addWorkOrderItemMutation,
      {
        variables: { body },
        update: (cache, result) => {
          const cachedItems = getCachedItems(cache, result, workOrderItemsQuery);
          const newItem = result?.data?.addWorkOrderItem;
          if (!(cachedItems && newItem)) return;

          cache.writeQuery({ ...workOrderItemsQuery, data: { workOrderItem: [...cachedItems, newItem] } });
        },
      },
      { successMessage: 'Item successfully created', errorMessage: 'Could not create item' },
    );
  };

  return {
    handleAddWorkOrderItem,
  };
};
