import React, { useState, useContext, createContext, useMemo, useCallback } from 'react';

const PopoverContext = createContext();

export const PopoverProvider = ({ children, chipList, updateChipList }) => {
  const [menuValue, setMenuValue] = useState('Location Breakdown Structure');
  const [parentId, setParentId] = useState('');
  const [locations, setLocations] = useState([]);

  const updateMenuValue = useCallback((value) => setMenuValue(value), []);
  const updateParentId = useCallback((value) => setParentId(value), []);
  const updateLocations = useCallback((value) => setLocations(value), []);

  const contextValue = useMemo(
    () => ({
      chipList,
      updateChipList,
      menuValue,
      parentId,
      locations,
      updateMenuValue,
      updateParentId,
      updateLocations,
    }),
    [
      chipList,
      updateChipList,
      menuValue,
      parentId,
      locations,
      updateMenuValue,
      updateParentId,
      updateLocations,
    ],
  );

  return <PopoverContext.Provider value={contextValue}>{children}</PopoverContext.Provider>;
};

export const usePopover = () => {
  const context = useContext(PopoverContext);
  if (!context) throw new Error('usePopover must be used within a PopoverProvider');
  return context;
};
