import React, { useCallback, useMemo } from 'react';

import { Custom } from 'components/Icons';

import PartItem from '../../PartItem';

const PartCategoryCard = ({
  isMainCategory = true,
  category = {},
  partsCount = 0,
  onSelect = () => {},
  selectedPartCategories = [],
}) => {
  const subNodes = useMemo(
    () => category?.subNodes?.map(({ partCategoryName }) => partCategoryName)?.join(', '),
    [category],
  );

  const handleSelectCategory = useCallback(
    () =>
      onSelect({
        ...category,
        parentPartCategory: selectedPartCategories[selectedPartCategories.length - 1],
      }),
    [category, onSelect, selectedPartCategories],
  );

  return (
    <PartItem sx={{ margin: 0, gap: 1, maxWidth: '232px' }}>
      <PartItem.Image icon={<Custom.Toolbox />} sx={{ height: 72, minWidth: 72 }} />
      <PartItem.Content sx={{ width: '152px' }}>
        <PartItem.Title
          sx={{
            lineHeight: '20px',
            color: 'white',
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          onClick={handleSelectCategory}
          data-cy="partCategory"
        >
          {category.partCategoryName}
        </PartItem.Title>
        {isMainCategory && <PartItem.Description sx={{ color: 'white' }}>{subNodes}</PartItem.Description>}
        {!isMainCategory && (
          <PartItem.Description sx={{ color: '#FFFFFF99' }}>
            ({partsCount.toLocaleString('en-US')})
          </PartItem.Description>
        )}
      </PartItem.Content>
    </PartItem>
  );
};

export default PartCategoryCard;
