import React, { createContext, useContext, useMemo } from 'react';

import { useTakeOff } from 'modules/Materials/TakeOff/context/TakeOffContext';

const RevitModelContext = createContext({
  revitModels: [],
});

const RevitModelProvider = ({ children }) => {
  const { sourcePadProperties } = useTakeOff();

  const contextValue = useMemo(
    () => ({ revitModels: sourcePadProperties?.sourcePadProperties }),
    [sourcePadProperties],
  );

  return <RevitModelContext.Provider value={contextValue}>{children}</RevitModelContext.Provider>;
};

const useRevitModel = () => {
  const context = useContext(RevitModelContext);
  if (context === undefined) {
    throw new Error('useRevitModel must be used within a RevitModelContext');
  }
  return context;
};

export { RevitModelContext, RevitModelProvider, useRevitModel };
