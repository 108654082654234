import { getElementId } from 'modules/Materials/commons/FilterPopoverContent/Breakdown/HelperElements';

import { INode } from '../@types/modalContextTypes';

export const mapNodeItem = (item: { [key: string]: any }, force0amount: boolean): INode => {
  let id = getElementId(item);
  let label = '';
  let identifier = '';
  let amount = 0;

  let locationPhaseLocationId = null;
  let locationPhaseWorkPhaseId = null;
  const assigned = item?.amount?.assigned || {};

  if (item?.type === 'PROJECT') {
    id = item.lineItemId;
    label = item?.projectName;
    identifier = item?.projectIdentifier;
    amount = item?.amount || 0;
  }

  if (item?.type === 'ScopePackage') {
    label = item?.scopePackageName;
    identifier = item?.scopePackageIdentifier;
  }

  if (item?.type === 'WorkPhase') {
    label = item?.workPhaseName;
    identifier = item?.workPhaseIdentifier;
    locationPhaseLocationId = item?.locationId;
    locationPhaseWorkPhaseId = item?.workPhaseId;
    amount = assigned.individual || 0;
  }

  if (item?.type === 'Location') {
    label = item?.locationName;
    identifier = item?.locationIdentifier;
    amount = assigned.individual || 0;
  }

  if (force0amount) amount = 0;

  return {
    id,
    amount: amount || 0,
    aggregate: assigned.aggregate || 0,
    aggregateVisual: assigned.aggregate || 0,
    children: [],
    type: item?.type,
    identifier,
    label,
    locationPhaseLocationId,
    locationPhaseWorkPhaseId,
    isEdited: false,
    isModifiedDirectly: false,
  };
};
