import React from 'react';

import { MenuItem, Typography } from '@mui/material';

import { Custom } from 'components/Icons';

const CatalogSearchHistoryItem = ({ label, onSelect = () => {}, sx }) => (
  <MenuItem
    role="button"
    onClick={() => onSelect(label)}
    sx={{ ...sx, height: '40px', padding: '0px 16px', gap: '8px' }}
  >
    <Custom.Restore />
    <Typography
      variant="body2"
      sx={{
        paddingLeft: '5px',
        fontSize: '14px',
        lineHeight: '20px',
      }}
    >
      {label}
    </Typography>
  </MenuItem>
);
export default CatalogSearchHistoryItem;
