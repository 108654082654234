import React, { ReactNode, useCallback, useMemo } from 'react';

import { useSnackbar } from 'notistack';

import { VARIANT_ERROR } from 'constants/snackbarConstants';
import { WorkRequest } from 'graphql/types';
import useGeneralContext from 'helpers/useGeneralContext';
import useWorkRequestStatusMutation, { WorkRequestByIdQuery } from 'hooks-api/useWorkRequestStatusMutation';

type UpdateWorkRequestStatusContextType = {
  updateWorkRequestStatus: (workRequest: WorkRequest, label: string, description: string) => Promise<string>;
  loading: boolean;
};
const UpdateWorkRequestStatusContext = React.createContext<UpdateWorkRequestStatusContextType | undefined>(undefined);

export const UpdateWorkRequestStatusProvider = ({ children }: { children: ReactNode }) => {
  const { updateWorkRequestStatusMutation, loading } = useWorkRequestStatusMutation();
  const { enqueueSnackbar } = useSnackbar();

  const updateWorkRequestStatus = useCallback(
    async (workRequest: WorkRequest, label: string, description: string) => {
      if (loading) {
        enqueueSnackbar('Please wait for the current action to complete.', VARIANT_ERROR);
      }
      const { data } = await updateWorkRequestStatusMutation({
        variables: {
          params: { workRequestId: workRequest.workRequestId, workRequestStatusName: label },
          body: { workRequestStatusDescription: description },
        },
        refetchQueries: [WorkRequestByIdQuery(workRequest.workRequestId)],
        awaitRefetchQueries: true,
      });

      if (!data?.updateWorkRequestStatus) throw new Error('Could not save changes.');
      return `${workRequest.workRequestIdentifier} status updated successfully.`;
    },
    [enqueueSnackbar, loading, updateWorkRequestStatusMutation],
  );

  const value = useMemo(() => ({ updateWorkRequestStatus, loading }), [loading, updateWorkRequestStatus]);
  return <UpdateWorkRequestStatusContext.Provider value={value}>{children}</UpdateWorkRequestStatusContext.Provider>;
};

export const useUpdateWorkRequestStatus = () =>
  useGeneralContext(UpdateWorkRequestStatusContext, 'UpdateWorkRequestStatus');
