/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';

import Auth from '@aws-amplify/auth';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

import { CenteredLoadSpinner, SetPasswordForm } from 'components';
import useQuery from 'hooks/useQuery';

import { fetchProviderStatus } from './auth/restCalls';
import ErrorMessages from './components/ErrorMessages';

const ResetPassword = () => {
  const [changeSuccess, setChangeSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorCode, setErrorCode] = useState('');
  const navigate = useNavigate();
  const queryParams = useQuery();

  useEffect(() => {
    if (changeSuccess) {
      navigate('/auth/login', { state: { ResetPassword: changeSuccess } });
    }

    return () => setChangeSuccess(false);
  }, [setChangeSuccess, navigate, changeSuccess]);

  const resetPasswordHandler = async ({ password }) => {
    setLoading(true);
    const email = queryParams.get('email');
    const code = queryParams.get('code');

    if (email && code) {
      try {
        const emailAllowChar = email.split(' ').join('+');
        const status = await fetchProviderStatus(emailAllowChar);

        const res = await Auth.forgotPasswordSubmit(status?.userName, code, password);

        if (res === 'SUCCESS') {
          setChangeSuccess(true);
        }
      } catch ({ code }) {
        setErrorCode(code);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Box
      display="flex"
      position="relative"
      flexDirection="column-reverse"
      justifyContent="center"
      height="100vh"
    >
      <Container component="main" maxWidth="xs">
        {errorCode && <ErrorMessages code={errorCode} onClose={() => setErrorCode('')} />}

        <Typography component="h1" variant="h4" color="secondary" gutterBottom>
          Create new password
        </Typography>

        <SetPasswordForm onSubmit={resetPasswordHandler} buttonLabel="SUBMIT" loading={loading} />

        {loading && <CenteredLoadSpinner />}
      </Container>
    </Box>
  );
};

export default ResetPassword;

// needs translation
