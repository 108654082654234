import { useState } from 'react';

import { Box, Stack, Button, useTheme, BoxProps } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';

import { CenteredLoadSpinner } from 'components';
import { Custom } from 'components/Icons';
import StackRow from 'components/StackRow';
import { VARIANT_ERROR } from 'constants/snackbarConstants';
import { useLazyPlatformSettings } from 'hooks-api/queries/platformSettings/useLazyPlatFormSettings';
import useFileDownloader from 'hooks/useFileDownloader';

import useGetPreSignedUrl from './useGetPresignedUrl';

const DataImport = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { handleDownloadFile } = useFileDownloader();
  const { getDocumentPreSignedUrl } = useGetPreSignedUrl();
  const [getPlatformSetting] = useLazyPlatformSettings();
  const [downloadInProgress, setDownloadInProgress] = useState<boolean>(false);

  const handleDownloadTemplate = async () => {
    try {
      setDownloadInProgress(true);
      const { data, error } = await getPlatformSetting({
        variables: { query: { platformSetting: 'PartImportTemplate' } },
      });
      if (!data?.platformSettings || error) throw new Error('Could not get the platform setting');
      const platformSettingValue = data?.platformSettings[0].platformSettingValue;
      if (platformSettingValue == null) throw new Error('Could not get the platform setting');
      const fileName = platformSettingValue.split('\\')?.pop()?.split('/').pop();
      const documentUrl = await getDocumentPreSignedUrl(platformSettingValue.toLowerCase());
      handleDownloadFile(documentUrl, fileName);
    } catch {
      enqueueSnackbar('Error downloading template', VARIANT_ERROR);
    } finally {
      setDownloadInProgress(false);
    }
  };

  return (
    <Box>
      <DataImportTitle />
      <Typography variant="subtitle2" sx={{ mt: '8px', mb: '8px' }}>
        Import your data into Foresite using our pre-formatted templates.
      </Typography>
      <StyledCard>
        <StackRow sx={{ gap: 1 }}>
          <Typography variant="body1" sx={{ flex: 1, lineHeight: '32px' }}>
            Add New Parts
          </Typography>
          <DownloadTemplateButton onClick={handleDownloadTemplate} loading={downloadInProgress} />
          <UploadDataButton />
        </StackRow>
        <Stack>
          <Typography variant="caption" sx={{ lineHeight: '16px', width: '100%', opacity: 0.5 }}>
            Use this template to import new parts into a catalog.
            <br />
            Download the template and add new parts, then upload it back to Foresite.
          </Typography>
        </Stack>
      </StyledCard>
    </Box>
  );
};

export default DataImport;

const DataImportTitle = () => (
  <Typography
    variant="h5"
    component="h1"
    display="block"
    sx={{
      mt: '4px',
      textAlign: { xs: 'left', lg: 'left' },
    }}
  >
    Data Import
  </Typography>
);

const StyledCard = (props: BoxProps) => (
  <Box
    sx={{
      border: '1px solid rgba(204, 204, 204, 1)',
      pl: 2,
      pt: '14px',
      pr: 2,
      pb: 3,
      borderRadius: '4px',
      boxShadow: 'none',
      width: '716px',
      justify: 'space-between',
    }}
    {...props}
  />
);

type DownloadTemplateButtonProps = {
  onClick: () => void;
  loading: boolean;
};

const DownloadTemplateButton = ({ onClick, loading }: DownloadTemplateButtonProps) => {
  const { palette } = useTheme();

  if (loading)
    return (
      <CenteredLoadSpinner
        sx={{
          m: 0,
          top: -24,
          right: 29,
          width: '20px',
          '& .MuiCircularProgress-root': {
            width: '16px !important',
            height: '16px !important',
          },
        }}
      />
    );

  return (
    <Button
      startIcon={<Custom.Download2 fill={palette.secondary.main} style={{ width: 20, height: 20 }} />}
      sx={{
        width: '188px',
        color: palette.secondary.main,
        fontWeight: 500,
        mr: 1,
        fontSize: '13px',
        lineHeight: '24px',
      }}
      onClick={onClick}
      variant="text"
    >
      DOWNLOAD TEMPLATE
      <input multiple hidden type="file" />
    </Button>
  );
};

const UploadDataButton = () => {
  const { palette } = useTheme();
  return (
    <Button
      startIcon={<Custom.Upload fill={palette.grey[400]} style={{ width: 20, height: 20 }} />}
      sx={{
        width: '131px',
        color: palette.secondary.main,
        fontWeight: 500,
        mr: 1,
        fontSize: '13px',
        lineHeight: '24px',
      }}
      variant="text"
      disabled
    >
      UPLOAD DATA
      <input multiple hidden type="file" />
    </Button>
  );
};
