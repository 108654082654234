import React from 'react';

import ControlledRadioGroup from 'components/FormComponents/ControlledRadioGroup';
import { useLocationTypeAndFormat } from 'modules/Field/LocationsAndWorkPhases/contexts/LocationTypeAndFormatContext';

const NomenclatureRadioList = ({ ...props }) => {
  const { locationFormats } = useLocationTypeAndFormat();

  const formattedOptions = () =>
    locationFormats?.map((option) => ({
      value: option.locationFormatName,
      label: option.locationFormatName,
    })) || [];

  return (
    <ControlledRadioGroup
      name="locationFormatId"
      radioOptions={formattedOptions()}
      activeColor="secondary"
      row
      sx={{
        m: 1,
        mt: 2,
        width: '101%',
        '& div': {
          flexDirection: 'row',
          justifyContent: 'space-between',
        },
        '& label': {
          mr: 4,
        },
      }}
      {...props}
    />
  );
};

export default NomenclatureRadioList;
