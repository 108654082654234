import React from 'react';

import Paper from '@mui/material/Paper';

import { rightDrawerOverlayColors } from 'styles/theme/colors';

import { IPropertiesOverlayContainerProps } from './@types/PropertiesOverlayTypes';

const PropertiesOverlayContainer: React.FC<IPropertiesOverlayContainerProps> = ({ children }) => (
  <Paper
    sx={{
      zIndex: 5,
      backgroundColor: rightDrawerOverlayColors.background,
      width: 376,
      height: 384,
      position: 'absolute',
      top: 88,
      left: 0,
      display: 'flex',
      flexDirection: 'column',
      py: 1,
      px: '24px',
    }}
  >
    {children}
  </Paper>
);

export default PropertiesOverlayContainer;
