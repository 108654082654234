import { Palette } from '@mui/material';

import { otherColors, surfaceColors } from 'styles/theme/colors';

const spinButtonStyles = {
  '-webkit-appearance': 'none',
  backgroundColor: 'blue',
  background: ` url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABhSURBVHgB7ZRBCkAhCEQd+JfshO3/BY3AhZtopCICHwgtdN4iUSRJPKpaekVmPrbRgou9BUBl5sA0+XBHZSRTwSCclmAhnJYkdzn+yXfXdCLZu552h36r0D2KSs6EJ+/SAEcuQMNwGZv0AAAAAElFTkSuQmCC') no-repeat center center`,
  width: '2em',
  opacity: 1,
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  overflow: 'hidden',
  borderTopRightRadius: '0.25rem',
  borderBottomRightRadius: '0.25rem',
};

export const getInputStyles = (isFocused: boolean, palette: Palette) => ({
  width: '80px',
  '&:hover': {
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: `${isFocused ? palette.secondary.mainDarkBackground : otherColors.outlineBorder} !important`,
    },
  },
  'input[type=number]::-webkit-outer-spin-button': {
    ...spinButtonStyles,
  },
  'input[type=number]::-webkit-inner-spin-button': {
    ...spinButtonStyles,
  },
  '& .MuiInputBase-root': {
    color: `${surfaceColors.darkSurface.secondary} !important`,
    textAlign: 'right',
  },
  '& .MuiInputBase-input': {
    textAlign: isFocused ? 'left !important' : 'right !important',
    paddingRight: `${isFocused ? '12px' : '0px'} !important`,
    '&:hover': {
      paddingRight: '12px !important',
    },
  },
  '& .MuiFormHelperText-root': {
    textAlign: 'right',
  },
  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    borderColor: `${isFocused ? palette.secondary.mainDarkBackground : 'transparent'} !important`,
  },
});
