import React, { useState, useEffect } from 'react';

import { Typography, Box } from '@mui/material';
import { useForm } from 'react-hook-form';

import OverFlowTooltip from 'components/Tooltip/OverFlowTooltip';
import useKeyPressEffect from 'hooks/useKeyPressEffect';
import { useWorkRequestsContext } from 'modules/Field/WorkRequests/WorkRequestsContext';

import { InformativeRow } from '../InformativeRow';
import NameForm from './NameForm';

const NameField = () => {
  const { workRequestSelected, workOrderSelected } = useWorkRequestsContext();
  const [hovered, setHovered] = useState(false);
  const editable =
    !workOrderSelected &&
    ['Draft', 'Canceled', 'Rejected', 'Submitted', 'Pending'].includes(workRequestSelected.workRequestStatusName);
  const {
    control,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    mode: 'all',
    defaultValues: { workRequestName: workRequestSelected.workRequestName },
  });

  useEffect(() => {
    reset({ workRequestName: workRequestSelected.workRequestName });
  }, [workRequestSelected.workRequestName, reset]);

  useKeyPressEffect('Escape', () => {
    setHovered(false);
    reset();
  });

  useEffect(() => setHovered(false), [workRequestSelected]);
  return (
    <InformativeRow>
      <Typography color="white" variant="body2">
        Work {!workOrderSelected ? 'Request' : 'Order'} Name{!workOrderSelected && '*'}
      </Typography>
      <Box onClick={() => setHovered(true)} maxWidth="210px" onMouseEnter={() => setHovered(true)}>
        {hovered && editable ? (
          <NameForm
            setHovered={setHovered}
            control={control}
            reset={reset}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
          />
        ) : (
          <OverFlowTooltip
            title={workRequestSelected.workRequestName}
            showAlways={workRequestSelected.workRequestName.length > 26}
          >
            <Typography
              color="white"
              variant="body2"
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '100%',
              }}
            >
              {workRequestSelected.workRequestName}
            </Typography>
          </OverFlowTooltip>
        )}
      </Box>
    </InformativeRow>
  );
};

export default NameField;
