import React, { useEffect, useRef, useState } from 'react';

import { Tooltip, Typography } from '@mui/material';

import { CounterTracker, OverflowTipProps } from './@types/Tooltip';

const counterTracker = {
  scrollWidth: 0,
  offsetWidth: 0,
};

const OverflowTypography = ({
  title = '',
  offset = { x: 0, y: 0 },
  placement = 'top',
  variant,
  sx = {},
  onClick = () => {},
  TypographyProps,
}: OverflowTipProps) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef<CounterTracker>(counterTracker);

  useEffect(() => setIsOverflow(textElementRef.current?.scrollWidth > textElementRef.current?.offsetWidth), []);

  return (
    <Tooltip
      arrow
      ref={textElementRef}
      title={
        title && (
          <Typography fontWeight="500" fontSize={10} lineHeight="16px">
            {title}
          </Typography>
        )
      }
      placement={placement}
      disableHoverListener={!isOverflowed}
      PopperProps={{ modifiers: [{ name: 'offset', options: { offset: [offset?.x, offset?.y] } }] }}
    >
      <Typography
        sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', ...sx }}
        variant={variant}
        onClick={onClick}
        {...TypographyProps}
      >
        {title}
      </Typography>
    </Tooltip>
  );
};

export default OverflowTypography;
