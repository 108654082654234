import React from 'react';

import { Stack } from '@mui/material';

const StyledRow = ({ isActive, onClick, sx, children }) => (
  <Stack
    onClick={onClick}
    direction="row"
    width="100%"
    alignItems="center"
    minHeight="32px"
    sx={{
      backgroundColor: isActive ? 'action.lightSurface.selected' : 'white',
      borderBottom: '1px solid',
      borderColor: 'other.border',
      cursor: 'pointer',
      paddingX: 2,
      '&:hover': {
        backgroundColor: 'action.lightSurface.hover',
      },
      '& > .MuiIconButton-root': {
        opacity: 0,
      },
      '&:hover > .MuiIconButton-root': {
        opacity: 1,
      },
      ...sx,
    }}
  >
    {children}
  </Stack>
);

export default StyledRow;
