import { RULE_DEFAULT } from './constants';
import { Rule } from './types';

export const onAddNewSubRuleCallback = (ruleIndex: number, query: Rule[]) =>
  query.map((rule, index) => {
    if (ruleIndex === index) {
      return {
        ...rule,
        subRules: [...(rule.subRules ?? []), RULE_DEFAULT],
      };
    }
    return rule;
  });

export const onRemoveRuleCallback = (position: string, query: Rule[]) => {
  const filters = [...query];
  const values = position.split(':');
  const isNode = values.length > 1;

  if (!isNode) {
    const parent = parseInt(values[0], 10);
    const newFilters = filters.filter((_, index) => index !== parent);
    return newFilters.length === 0 ? [RULE_DEFAULT] : newFilters;
  }
  const parent = parseInt(values[0], 10);
  const child = parseInt(values[1], 10);
  const newFilters = filters.map((rule, index) => {
    if (index === parent) {
      const subRules = [...(rule.subRules ?? [])];
      const newSubRules = subRules.filter((_, subIndex) => subIndex !== child);
      return { ...rule, subRules: newSubRules };
    }
    return rule;
  });
  return newFilters.length === 0 ? [RULE_DEFAULT] : newFilters;
};

export const onChangeCallback = (position: string, name: string, value: string, query: Rule[]) => {
  const values = position.split(':');
  const isNode = values.length > 1;

  const rulesUpdated = [...query];
  const parent = parseInt(values[0], 10);

  if (!isNode) {
    const rule = rulesUpdated[parent];
    const data = name === 'values' ? [value] : value;
    const ruleUpdated = { ...rule, [name]: data };
    rulesUpdated[parent] = ruleUpdated;
  } else {
    const child = parseInt(values[1], 10);
    const rule = rulesUpdated[parent].subRules[child];
    const data = name === 'values' ? [value] : value;
    const ruleUpdated = { ...rule, [name]: data };
    rulesUpdated[parent].subRules[child] = ruleUpdated;
  }

  return rulesUpdated;
};

export const isValidRuleCallback = (rule: Rule) => {
  if (rule?.condition && rule?.operator && rule?.propertyName && rule?.values.length > 0) {
    const subRulesValid = rule.subRules?.every(
      (subRule) =>
        subRule?.condition &&
        subRule?.operator &&
        subRule?.propertyName &&
        subRule?.values?.length > 0,
    );
    return subRulesValid !== false;
  }
  return false;
};
