/* eslint-disable max-lines-per-function */
import React, { useMemo, useState, useCallback, useEffect } from 'react';

import { useController, useFormContext } from 'react-hook-form';

import { ForesiteAutocomplete } from 'components/FormComponents';

import { getAttributeVariants } from './AttributeSelectHelper';

const AttributeSelectField = ({ setNewAttributeVariant, attribute, index, ...restProps }) => {
  const { control, setValue, setError, clearErrors, getValues, disabled } = useFormContext();
  const [opened, setOpened] = useState(false);

  const {
    field: { onChange, ref, onBlur, value },
    formState: { isSubmitting },
    fieldState: { error },
  } = useController({
    name: `partAttributeVariantData.${index}.selectedVariants`,
    control,
    rules: {
      maxLength: {
        value: 32,
        message: 'Variant must not exceed 32 characters',
      },
    },
  });

  const variants = useMemo(() => getAttributeVariants(attribute), [attribute]);

  const clearNewVariant = useCallback(() => {
    setNewAttributeVariant(false);
    setValue(`partAttributeVariantData.${index}.text`, '');
    setValue(`partAttributeVariantData.${index}.code`, '');
    setValue(`partAttributeVariantData.${index}.isNewVariant`, false);
    clearErrors(`partAttributeVariantData.${index}.code`);
  }, [index, setNewAttributeVariant, setValue, clearErrors]);

  const handleSelectValue = useCallback(
    (value) => {
      const currentValue = getValues(`partAttributeVariantData.${index}.selectedVariants`);

      if (!value || (currentValue !== null && typeof currentValue === 'object')) {
        return;
      }

      const selectedOption = variants.find(
        (variant) => variant.label.toLowerCase().trim() === value.toLowerCase().trim(),
      );

      if (!selectedOption) {
        setNewAttributeVariant(true);
        setValue(`partAttributeVariantData.${index}.isNewVariant`, true);
        setValue(`partAttributeVariantData.${index}.text`, value);
        setValue(`partAttributeVariantData.${index}.code`, '');
        onChange(null);
        setOpened(false);
        return;
      }

      setError(`partAttributeVariantData.${index}.selectedVariants`, {
        message: 'Variant name already exists',
      });
      clearNewVariant();
      setOpened(false);
    },
    [
      variants,
      setNewAttributeVariant,
      onChange,
      index,
      setValue,
      setError,
      getValues,
      clearNewVariant,
    ],
  );

  const handleKeyDown = useCallback(
    (event) => {
      if (!attribute.allowWriteInVariant) {
        return;
      }

      if (event.key === 'Enter') {
        handleSelectValue(event.target.value);
      }
    },
    [attribute.allowWriteInVariant, handleSelectValue],
  );

  const handleInputChange = (value, reason) => {
    if (reason === 'reset') {
      return;
    }

    onChange(value);

    if (!value) {
      clearErrors(`partAttributeVariantData`);
      clearNewVariant();
      return;
    }

    const selectedOption = variants.find(
      (variant) => variant.label.toLowerCase().trim() === value.toLowerCase().trim(),
    );

    if (selectedOption) {
      clearNewVariant();
      setOpened(false);
      return;
    }

    clearErrors(`partAttributeVariantData`);
  };

  const handleOnChange = useCallback(
    (e, item) => {
      if (item) {
        onChange(item);
        clearErrors(`partAttributeVariantData`);
        clearNewVariant();
      }
    },
    [onChange, clearErrors, clearNewVariant],
  );

  useEffect(() => {
    if (attribute?.isNewVariant) {
      onChange(attribute?.text);
      setValue(`partAttributeVariantData.${index}.code`, attribute.code);
      setNewAttributeVariant(true);
    }
  }, [attribute, index, onChange, setNewAttributeVariant, setValue]);

  return (
    <ForesiteAutocomplete
      freeSolo
      open={opened}
      onOpen={() => setOpened(true)}
      onClose={() => setOpened(false)}
      options={variants}
      sx={{ width: '100%' }}
      onChange={handleOnChange}
      onBlur={(e) => {
        onBlur(e);
        if (attribute.allowWriteInVariant) {
          handleSelectValue(e.target.value);
        }
      }}
      onInputChange={(e, item, reason) => handleInputChange(item, reason)}
      onKeyDown={handleKeyDown}
      name={`partAttributeVariantData.${index}.selectedVariants`}
      value={value}
      disabled={isSubmitting || disabled}
      textFieldProps={{
        inputRef: { ref },
        error: !!error,
        helperText: error?.message,
        size: 'small',
        label: attribute.partAttributeName,
        maxLength: 32,
      }}
      {...restProps}
    />
  );
};

export default AttributeSelectField;
