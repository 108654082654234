import React, { useCallback, useContext, useEffect, useMemo } from 'react';

import { Box, CircularProgress } from '@mui/material';

import TasksTable from 'modules/Field/components/TasksTable';
import { useDeleteTask } from 'modules/Field/components/TasksTable/useDeleteTask';
import { DROPPABLE_ROW_IDS } from 'modules/Field/WorkRequests/FieldWorkRequestConstants';
import { WorkOrderItemsContext } from 'modules/Shop/WorkOrders/WorkOrder/context/WorkOrderItemsContext';

import { useTaskRepeatAndPredecessors } from '../../hooks/useTaskRepeatAndPredecessors';
import { useItem } from '../../ItemProvider';
import { useWorkRequestDragDrop } from '../../WorkRequestDragDropProvider';
import { WorkRequestItemsContext } from '../../WorkRequestItemsContext';
import MappedDrawingDocs from '../MappedDrawingDocs/MappedDrawingDocs';
import BillOfProcessSubItem from './BillOfProcessSubItem';
import RowComponent from './RowComponent';
import RowComponentDroppable from './RowComponentDroppable';
import useBillOfProcessRowHelpers from './useBillOfProcessRowHelpers';
import { useEffectThatCancelDraggingWRItem } from './useEffectThatCancelDraggingWRItem';

const NON_EDITABLE_WR_STATUSES = ['Approved', 'Completed', 'In Progress', 'Rejected', 'Canceled'];

const BillOfProcessRow = () => {
  const { item, isResponsiveWR, parentStatus, isWorkOrder, parent, isWriteInItem } = useItem();

  const workRequestItemsContext = useContext(WorkRequestItemsContext);
  const workOrderItemsContext = useContext(WorkOrderItemsContext);

  const { isDrawingWRItem, WROrder } = workRequestItemsContext || {};

  const isTaskEditable = useMemo(() => {
    if (!isWriteInItem) return false;

    // From Work Order
    if (isWorkOrder) {
      return workOrderItemsContext.canSetPredecessors || workOrderItemsContext.canDeleteShopTaskFromWriteInItem;
    }

    // From Work Request
    if (NON_EDITABLE_WR_STATUSES.includes(parentStatus)) return false;

    const WROrderIsDraft = WROrder?.workOrderStatusTypeName === 'Draft';

    return ['Pending'].includes(parentStatus) || WROrderIsDraft;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWorkOrder, isWriteInItem, parentStatus]);

  const { finishedDragging, setFinishedDragging, needsDragDropContext } = useWorkRequestDragDrop();

  const { onDeleteTask } = useDeleteTask(parent?.workRequestId);

  const { opened, loading, tasks, updatingWorkRequestItemID, onOpeningBillOfProcess, onUpdateTask, setOpened } =
    useBillOfProcessRowHelpers(item, parent?.workRequestId, parent?.workOrderId);

  const { taskIdsRelateDuplicate, tasksAddDuplicateCount, tasksPredecessorBlacklist } =
    useTaskRepeatAndPredecessors(tasks);

  useEffect(() => {
    if (
      finishedDragging?.destination?.droppableId === `${DROPPABLE_ROW_IDS.BOP}/${item.workRequestItemId}` &&
      !opened
    ) {
      setOpened(true);
      setFinishedDragging((current) => ({ item, ...current }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishedDragging]);

  useEffect(() => isDrawingWRItem && setOpened(isDrawingWRItem), [isDrawingWRItem, setOpened]);

  useEffectThatCancelDraggingWRItem();

  const getDetailPanelHeight = useCallback(() => 'auto', []);

  const getDrawingDocs = useCallback((props) => <MappedDrawingDocs props={props} />, []);

  const rowComponent = (params) =>
    needsDragDropContext && parent?.workRequestStatusName !== 'Completed' ? (
      <RowComponent {...params} />
    ) : (
      <RowComponentDroppable {...params} />
    );

  return (
    <Box sx={{ backgroundColor: 'surface.darkSurface.primary' }}>
      <BillOfProcessSubItem
        row={item}
        opened={opened}
        onOpeningBillOfProcess={onOpeningBillOfProcess}
        isTaskEditable={isWriteInItem}
      />
      {opened && loading && updatingWorkRequestItemID === item.workRequestItemId && (
        <CircularProgress color="secondary" />
      )}
      {opened && tasks?.length > 0 && (
        <Box sx={{ backgroundColor: 'surface.darkSurface.primary' }}>
          <TasksTable
            canDelete={isTaskEditable}
            canEdit={isTaskEditable}
            tasks={tasksAddDuplicateCount}
            onSavePredecessors={onUpdateTask}
            onDeleteTask={onDeleteTask}
            rowComponent={rowComponent}
            getDrawingDocs={getDrawingDocs}
            detailPanelHeight={getDetailPanelHeight}
            isResponsiveWR={isResponsiveWR}
            taskIdsRelateDuplicate={taskIdsRelateDuplicate}
            tasksPredecessorBlacklist={tasksPredecessorBlacklist}
          />
        </Box>
      )}
    </Box>
  );
};

export default BillOfProcessRow;
