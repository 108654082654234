import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { VARIANT_ERROR } from 'constants/snackbarConstants';
import {
  addScopePackage as ADD_SCOPE_PACKAGE,
  updateScopePackage as UPDATE_SCOPE_PACKAGE,
  deleteScopePackage as DELETE_SCOPE_PACKAGE,
} from 'graphql/mutations';
import { scopePackage as SCOPE_PACKAGE } from 'graphql/queries';
import { recursiveUpdateNodeKeyValue, removeChildrenRecursively } from 'helpers/arrayFunctions';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

import { ROW_TYPES } from '../../constants/constants';
import useWorkPhasesByLocation from './useWorkPhasesByLocation';

export default function useScopePackageDataLayer(locations, setLocations, errorCallback = () => {}) {
  const { removeWorkPhasesFromLocationsTable } = useWorkPhasesByLocation(locations, setLocations);
  const { handleResponse } = useGraphqlResponseHandler();
  const { enqueueSnackbar } = useSnackbar();

  const [scopePackagesQuery, { loading: loadingScopePackages }] = useLazyQuery(gql(SCOPE_PACKAGE), {
    fetchPolicy: 'cache-and-network',
  });

  const [addScopePackageMutation] = useMutation(gql(ADD_SCOPE_PACKAGE));
  const [updateScopePackageMutation] = useMutation(gql(UPDATE_SCOPE_PACKAGE));
  const [deleteScopePackageMutation] = useMutation(gql(DELETE_SCOPE_PACKAGE));

  const fetchScopePackages = (projectId, successCallback = () => {}) => {
    scopePackagesQuery({
      variables: { query: { projectId, orderby: 'sortOrder' } },
      onCompleted: (response) => {
        const scopePackage = response?.scopePackage;
        if (!scopePackage) {
          enqueueSnackbar('Error fetching scope packages', VARIANT_ERROR);
        } else {
          successCallback(scopePackage);
        }
      },
    });
  };

  const addScopePackage = async (row, projectId, rows, setTableRows = () => {}) => {
    await handleResponse(
      addScopePackageMutation,
      {
        variables: {
          body: {
            projectId,
            scopePackageName: row.label,
            scopePackageIdentifier: row.prefix,
          },
        },
      },
      { successMessage: 'Scope package successfully created' },
      ({ addScopePackage: newScopePackage }) => {
        const { scopePackageId: id, scopePackageName: label, scopePackageIdentifier: prefix } = newScopePackage;
        const newScopeMapped = { id, label, prefix, type: ROW_TYPES.SCOPE_PACKAGE };
        setTableRows(recursiveUpdateNodeKeyValue(row.id, rows, newScopeMapped));
      },
      () => errorCallback(),
    );
  };

  const updateScopePackage = async ({ id, label: ScopePackageName, prefix: scopePackageIdentifier }) => {
    await handleResponse(
      updateScopePackageMutation,
      { variables: { params: { id }, body: { ScopePackageName, scopePackageIdentifier } } },
      { successMessage: 'Scope package successfully updated' },
      () => {},
      () => errorCallback(),
    );
  };

  const removeScopePackage = async (id, rows, setTableRows = () => {}, updateSuffix, successCallback = () => {}) => {
    await handleResponse(
      deleteScopePackageMutation,
      { variables: { params: { id } } },
      { successMessage: 'Scope package successfully deleted' },
      (response) => {
        if (response.deleteScopePackage) {
          setTableRows(removeChildrenRecursively(id, rows));
          removeWorkPhasesFromLocationsTable(response?.deleteScopePackage);
          updateSuffix();
          successCallback();
        }
      },
    );
  };

  return {
    fetchScopePackages,
    loadingScopePackages,
    addScopePackage,
    updateScopePackage,
    removeScopePackage,
  };
}
