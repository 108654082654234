import React, { useEffect, useState } from 'react';

import { gql, useLazyQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { projectById as PROJECT_BY_ID } from 'graphql/queries';
import { clone } from 'helpers/arrayFunctions';

import { ROW_TYPES } from '../constants/constants';
import { getIdFromURL } from './ProjectContextHelper';

interface ISelectedProject {
  locationId: string;
  locationIdentifier: string;
  locationName: string;
  locationTypeName: string;
  projectName: string;
}

interface IProjectContext {
  loading: boolean;
  selectedProject?: ISelectedProject;
  isProject?: boolean;
  isItemSelected?: boolean;
  itemSelectedId?: string;
}

const ProjectContext = React.createContext<IProjectContext>({ loading: false });

const ProjectProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const params = useParams();
  const urlProjectId = getIdFromURL(params['*']);
  const { selectedItem } = useFacilitiesProjects();
  const [selectedProject, setSelectedProject] = useState<ISelectedProject>();

  const [fetchProjectById, { loading }] = useLazyQuery(gql(PROJECT_BY_ID), {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ projectById: response }) => {
      const { projectName, projectId, projectIdentifier } = clone(response);
      const project: ISelectedProject = {
        projectName,
        locationName: projectName,
        locationId: projectId,
        locationIdentifier: projectIdentifier || '-',
        locationTypeName: ROW_TYPES.PROJECT,
      };
      return setSelectedProject(response ? project : undefined);
    },
  });

  const isProject = Boolean(selectedItem?.type === 'PROJECT');

  const itemSelectedId = (() => {
    if (urlProjectId) return urlProjectId;

    return isProject ? selectedItem?.id : '';
  })();

  const isItemSelected = Boolean(selectedItem);

  useEffect(() => {
    if (itemSelectedId !== '') {
      fetchProjectById({
        variables: {
          params: {
            projectId: itemSelectedId,
          },
        },
      });
    } else setSelectedProject(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemSelectedId]);

  const valueObj = { selectedProject, loading, isProject, isItemSelected, itemSelectedId };
  return <ProjectContext.Provider value={valueObj}>{children}</ProjectContext.Provider>;
};

const useProject = () => {
  const context = React.useContext(ProjectContext);
  if (context === undefined) {
    throw new Error('useProject must be used within a ProjectContext');
  }
  return context;
};

export { ProjectContext, ProjectProvider, useProject };
