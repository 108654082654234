import React from 'react';

import { Box } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Droppable } from 'react-beautiful-dnd';

import { EIconType } from 'helpers/MenuIcons';
import useSetupModule from 'hooks/useSetupModule';
import { useDrawingRegisterDragDrop } from 'modules/Design/DrawingRegister/DrawingRegisterDragDropProvider';
import { DROPPABLE_ROW_IDS } from 'modules/Field/WorkRequests/FieldWorkRequestConstants';
import { ROW_TYPES } from 'modules/Shop/ShopSetup/WorkCellSetup/WorkCellsAndMachines/constants/constants';

import SubItem from '../../components/SubItem';

const BillOfProcessSubItem = ({ opened, onOpeningBillOfProcess, row, isTaskEditable }) => {
  const { currentModule } = useSetupModule();

  const { isDropDisabled } = useDrawingRegisterDragDrop();

  if (currentModule() !== 'SHOP' || !isTaskEditable)
    return (
      <SubItem
        label="Bill of Process"
        icon={EIconType.BILL_OF_PROCESS_WORK_REQUEST}
        opened={opened}
        onOpening={onOpeningBillOfProcess}
        disabled={!row?.hasShopTask}
      />
    );

  return (
    <Droppable
      droppableId={`${DROPPABLE_ROW_IDS.BOP}/${row.workRequestItemId}`}
      type={ROW_TYPES.TASK_TYPES}
      isDropDisabled={isDropDisabled}
      direction="horizontal"
    >
      {(provided, snapshot) => (
        <Box sx={{ background: grey[100] }}>
          <SubItem
            label="Bill of Process"
            icon={EIconType.BILL_OF_PROCESS_WORK_REQUEST}
            opened={opened}
            onOpening={onOpeningBillOfProcess}
            innerRef={provided.innerRef}
            className="DroppableRow"
            isDraggingOver={snapshot.isDraggingOver}
            {...provided.droppableProps}
          />
        </Box>
      )}
    </Droppable>
  );
};

export default BillOfProcessSubItem;
