import { ROW_TYPES } from '../../../constants/constants';
import { useFloorOptions } from './useFloorOptions';
import { useLocationSystemOptions } from './useLocationSystemOptions';
import { useProjectOptions } from './useProjectOptions';
import { useWorkPhaseOptions } from './useWorkPhaseOptions';
import { useZoneOptions } from './useZoneOptions';

export const useMoreMenu = (node, parent, ascendantIsFloor) => {
  const { getLocationSystemOptions } = useLocationSystemOptions(node, parent, ascendantIsFloor);
  const { getZoneOptions } = useZoneOptions(node, parent, ascendantIsFloor);
  const { getFloorOptions } = useFloorOptions(node, parent);
  const { getWorkPhaseOptions } = useWorkPhaseOptions(node);
  const { getProjectOptions } = useProjectOptions(node);

  const buildOptions = () => {
    const { locationTypeName: locType } = node;
    if (locType === ROW_TYPES.LOCATION_SYSTEM) return getLocationSystemOptions();
    if (locType === ROW_TYPES.ZONE) return getZoneOptions();
    if (locType === ROW_TYPES.FLOOR) return getFloorOptions();
    if (locType === ROW_TYPES.PROJECT) return getProjectOptions();
    if (node.workPhaseId) return getWorkPhaseOptions();
    return [];
  };

  return { buildOptions };
};
