import React from 'react';

import { Stack, Typography, useTheme } from '@mui/material';
import { parseISO, format } from 'date-fns';

const PropertyNote = ({ note }) => {
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      alignItems="baseline"
      justifyContent="space-between"
      paddingY="10px"
      borderBottom="0.5px solid"
      borderColor={theme.palette.grey[800]}
    >
      <Stack display="flex" flexDirection="row" gap="10px">
        <Typography variant="body2">{note.createdBy}</Typography>
        <Typography variant="body2" color={theme.palette.grey[400]}>
          {format(parseISO(note.date), 'MM/dd/yy')}
        </Typography>
      </Stack>
      <Typography variant="body2" textAlign="right" maxWidth="250px">
        {note.note}
      </Typography>
    </Stack>
  );
};

export default PropertyNote;
