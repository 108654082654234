import React, { useState, useContext, useCallback, createContext } from 'react';

const HeaderContext = createContext();

const HeaderProvider = ({ children }) => {
  const [anchorElLvl1, setAnchorElLvl1] = useState(null);
  const [anchorElLvl2, setAnchorElLvl2] = useState(null);

  const updateAnchorElLvl1 = useCallback((element) => setAnchorElLvl1(element), []);
  const updateAnchorElLvl2 = useCallback((element) => setAnchorElLvl2(element), []);

  return (
    <HeaderContext.Provider
      value={{ anchorElLvl1, anchorElLvl2, updateAnchorElLvl1, updateAnchorElLvl2 }}
    >
      {children}
    </HeaderContext.Provider>
  );
};

const useHeader = () => {
  const context = useContext(HeaderContext);
  if (context === undefined) throw new Error('useHeader must be within a HeaderContext');
  return context;
};

export { useHeader, HeaderProvider };
