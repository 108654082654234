import React from 'react';

import { useCustomAssemblyTypesContext } from './CustomAssemblyTypesContext';
import { useCustomAssemblyTypes } from './hooks/useCustomAssemblyTypes';

const CustomAssembliesContext = React.createContext();

// eslint-disable-next-line max-lines-per-function
const CustomAssembliesProvider = ({ children }) => {
  const { customAssemblyTypes, setCustomAssemblyTypes } = useCustomAssemblyTypesContext();

  const {
    deleteBulkItemCustomAssembly,
    addBomItemCustomAssembly,
    getCustomAssemblies,
    isCreatingCustomAssembly,
    loadingCustomAssemblies,
  } = useCustomAssemblyTypes();

  const createCustomAssembly = (CustomAssemblyName, CustomAssemblyId, AssemblyType, Assignee) => {
    addBomItemCustomAssembly(
      CustomAssemblyName,
      CustomAssemblyId,
      AssemblyType,
      Assignee,
      customAssemblyTypes,
      setCustomAssemblyTypes,
    );
  };

  const setCustomAssemblyChildren = (assemblyCustomCategoryId, newCustomAssemblyData) => {
    const current = { index: -1 };
    customAssemblyTypes.map((value, index) => {
      if (value.id === assemblyCustomCategoryId) {
        current.index = index;
      }
      return -1;
    });

    const copy = [...customAssemblyTypes];
    if (copy.at(current.index).children.length === 0) {
      copy.at(current.index).children = newCustomAssemblyData;
      newCustomAssemblyData.map((value, index) => {
        const newItem = {
          id: value.lineItemId,
          type: value.lineItemTypeName,
          children: value,
        };
        return copy.splice(current.index + index + 1, 0, newItem);
      });
    }
    setCustomAssemblyTypes(copy);
  };

  const deleteCustomAssemblyChildren = (assemblyCustomCategoryId) => {
    const current = { index: -1 };

    customAssemblyTypes.map((value, index) => {
      if (value.id === assemblyCustomCategoryId) {
        current.index = index;
      }
      return -1;
    });
    const copy = [...customAssemblyTypes];
    const childrenLength = copy.at(current.index).children.length;
    copy.at(current.index).children = [];
    copy.splice(current.index + 1, childrenLength);
    setCustomAssemblyTypes(copy);
  };

  const setLoadingChildren = (assemblyCustomCategoryId) => {
    const current = { index: -1 };
    customAssemblyTypes.map((value, index) => {
      if (value.id === assemblyCustomCategoryId) {
        current.index = index;
      }
      return -1;
    });
    const copy = [...customAssemblyTypes];
    const newItem = {
      id: 'loading',
      type: 'Loading',
    };
    copy.splice(current.index + 1, 0, newItem);
    setCustomAssemblyTypes(copy);
  };

  const value = {
    isCreatingCustomAssembly,
    loadingCustomAssemblies,
    createCustomAssembly,
    getCustomAssemblies,
    setCustomAssemblyChildren,
    deleteCustomAssemblyChildren,
    deleteBulkItemCustomAssembly,
    setLoadingChildren,
  };

  return <CustomAssembliesContext.Provider value={value}>{children}</CustomAssembliesContext.Provider>;
};

const useCustomAssembliesContext = () => {
  const context = React.useContext(CustomAssembliesContext);
  if (context === undefined) {
    throw new Error('useCustomAssembliesContext must be used within a CustomAssembliesContext');
  }
  return context;
};

export { CustomAssembliesContext, CustomAssembliesProvider, useCustomAssembliesContext };
