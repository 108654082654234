/* eslint-disable prefer-spread */
/* eslint-disable no-plusplus */

const skipTakePagination = (keyArgs = false) => ({
  keyArgs,
  merge(existing = [], incoming, { args: { query = {} } }) {
    const { skip = 0 } = query;

    const merged = existing ? existing.slice(0) : [];
    const incomingLength = incoming?.length || 0;

    for (let i = 0; i < incomingLength; ++i) {
      merged[skip + i] = incoming[i];
    }

    return merged;
  },
});

export default skipTakePagination;
