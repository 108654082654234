import React, { FC } from 'react';

import { Stack, Typography } from '@mui/material';

import { useBOMbyIdContext } from '../../BOMbyIdContext';

const ItemCounter: FC = () => {
  const { selectedPadItems } = useBOMbyIdContext();
  return (
    <Stack justifyContent="center">
      <Typography
        color="surface.lightSurface.secondary"
        variant="body2"
        sx={{ ml: 2, mr: 1, minWidth: '50px', overflow: 'hidden', whiteSpace: 'nowrap' }}
      >
        {`${selectedPadItems.length} item${selectedPadItems.length === 1 ? '' : 's'} selected`}
      </Typography>
    </Stack>
  );
};

export default ItemCounter;
