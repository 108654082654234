import React, { useCallback } from 'react';

import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import { Droppable } from 'react-beautiful-dnd';
import { Waypoint } from 'react-waypoint';

import { useUser } from 'app/UserContext';
import { CenteredLoadSpinner } from 'components';
import { Custom } from 'components/Icons';
import ListFlyoutPaper from 'components/ListFlyoutPaper';
import TaskTypeCard from 'components/TaskTypeCard';

import { useShopTasksContext } from '../../../ShopTasks/ShopTasksContext';
import { ROW_TYPES } from '../constants/constants';
import { taskTypesPopupScrollBarStyle } from './scrollBarStyle';
import StyledPopup from './StyledPopup';

export const TaskTypesPopup = ({ onClose }) => {
  const { taskTypes, taskTypesPaginationHandler, loadingTaskTypes } = useShopTasksContext();

  const { user } = useUser();
  const fetchMoreTaskTypes = useCallback(
    (skip) => {
      taskTypesPaginationHandler(skip, {
        taskTypeClassId: '9e1a7bc6-5b0a-4aa7-bcb5-b354e3bc2cdb',
        companyId: user?.companyId,
        orderBy: 'taskTypeName',
      });
    },
    [taskTypesPaginationHandler, user?.companyId],
  );

  const handleFetchMore = useCallback(() => {
    if (!taskTypes) return;
    fetchMoreTaskTypes(taskTypes?.length);
  }, [taskTypes, fetchMoreTaskTypes]);

  return (
    <StyledPopup onClose={onClose}>
      <ListFlyoutPaper.Title textHelp="Drag task types to map">Shop Tasks</ListFlyoutPaper.Title>
      <ListFlyoutPaper.Items sx={taskTypesPopupScrollBarStyle}>
        {taskTypes?.length > 0 && (
          <DroppableContainer>
            {taskTypes?.map((taskType, index) => (
              <TaskType key={taskType.taskTypeId} taskType={taskType} index={index} />
            ))}
          </DroppableContainer>
        )}
        <Waypoint key={taskTypes?.length} bottomOffset="-20%" onEnter={() => handleFetchMore()} />
        {loadingTaskTypes && <CenteredLoadSpinner darkMode />}
      </ListFlyoutPaper.Items>
    </StyledPopup>
  );
};

const DroppableContainer = ({ children }) => (
  <Box>
    <Droppable droppableId={ROW_TYPES.TASK_TYPES} type={ROW_TYPES.TASK_TYPES}>
      {(droppableProvided) => (
        <Box {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
          {children}
          {droppableProvided.placeholder}
        </Box>
      )}
    </Droppable>
  </Box>
);

const TaskType = ({ taskType: { taskTypeId, taskTypeName, taskTypeImageId, taskTypeDescription }, index }) => (
  <ListFlyoutPaper.Item draggable index={index} draggableId={taskTypeId}>
    <TaskTypeCard
      taskTypeName={taskTypeName}
      taskTypeClass="shop"
      taskTypeImageId={taskTypeImageId}
      taskTypeDescription={taskTypeDescription}
      icon={
        <Stack sx={{ m: 0, pt: '12px', pl: '10px' }}>
          <Custom.WorkRequestFill />
        </Stack>
      }
      sx={{ width: '240px', cursor: 'grab', userSelect: 'none' }}
    />
  </ListFlyoutPaper.Item>
);
