import React, { useCallback, useMemo, useState } from 'react';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useForm } from 'react-hook-form';

import { Modal } from 'components';
import { useUnitOfMeasureAPI } from 'hooks-api/useUnitOfMeasureAPI';
import { actionColors } from 'styles/theme/colors';

import { UOMForm } from '../UOMForm';

function CreateUOMModal({
  open,
  companyId = '',
  onCreate = () => {},
  onClose = () => {},
  onSelectUOMCreated = () => {},
}) {
  const [uomDuplicationState, setUOMDuplicationState] = useState({
    uomName: false,
    uomCode: false,
  });

  const { control, handleSubmit, reset, formState, watch } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const { createUOM, loading: isCreatingUOM, refetchUoms } = useUnitOfMeasureAPI();

  const handleCreateUOM = async ({ uomCode, uomName, uomType }) => {
    const value = await createUOM({
      variables: {
        body: {
          companyId,
          unitOfMeasureTypeId: uomType,
          unitOfMeasureName: uomName,
          unitOfMeasureCode: uomCode,
        },
      },
    });
    refetchUoms();
    const newUOM = value?.data?.addUnitOfMeasure;
    onCreate();
    reset();
    onSelectUOMCreated({
      label: newUOM?.unitOfMeasureName,
      value: newUOM?.unitOfMeasureId,
      code: newUOM?.unitOfMeasureCode,
    });
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  const onUOMDuplicationStateChange = useCallback((data) => {
    setUOMDuplicationState((prev) => ({ ...prev, ...data }));
  }, []);

  const isUOMValid = useMemo(() => {
    const isDuplicated = Object.values(uomDuplicationState).some(Boolean);
    const hasError = Boolean(Object.keys(formState.errors).length);
    const uomNameValue = watch('uomName');
    const uomCodeValue = watch('uomCode');
    const uomTypeValue = watch('uomType');

    return !isDuplicated && !hasError && uomNameValue && uomCodeValue && uomTypeValue;
  }, [uomDuplicationState, formState, watch]);

  return (
    <Modal
      open={open}
      title="New UOM"
      titleContainerStyles={{ pr: 0 }}
      closeButtonColor={actionColors.lightSurface.active}
      onClose={onClose}
      footer={
        <>
          {isCreatingUOM && <CircularProgress size={24} />}
          <CancelButton disabled={isCreatingUOM} onClick={handleClose}>
            CANCEL
          </CancelButton>
          <Button
            color="secondary"
            variant="contained"
            disabled={isCreatingUOM || !isUOMValid}
            onClick={handleSubmit(handleCreateUOM)}
          >
            ADD
          </Button>
        </>
      }
    >
      <form onSubmit={handleSubmit(handleCreateUOM)}>
        <UOMForm
          companyId={companyId}
          control={control}
          errors={formState.errors}
          onDuplicationStateChange={onUOMDuplicationStateChange}
        />
      </form>
    </Modal>
  );
}

export default CreateUOMModal;

const CancelButton = (props) => <Button variant="text" sx={{ color: 'secondary.main', fontWeight: 500 }} {...props} />;
