/* eslint-disable import/no-cycle */
import { UNASSIGNED } from '../../../constants';
import { addAutoFilledField } from '../../QtyField/helpers/qtyFieldHelpers';
import { QuantityFieldType } from '../../QuantityField/hooks/@types/fieldHookTypes';

const getWorkPhasesFields = ({ locationData, workPhases, nestLevel, locationId, lineItemId }: any) =>
  locationData?.workPhases?.length
    ? workPhases?.map((workPhase: any) => ({
        id: workPhase.workPhaseId,
        qtyRemaining: Math.round(workPhase?.amount?.available?.individual) || 0,
        qtyAggregate: Math.round(workPhase?.amount?.available?.aggregate) || 0,
        nestLevel: nestLevel + 1,
        locationId,
        lineItemId,
        isWorkPhase: true,
        parentId: locationId,
      }))
    : [];

const getFirstLevelChild = ({ locationId, lineItemId, nestLevel, amount, LBSData, workPhasesFields }: any) => ({
  id: locationId,
  parentId: lineItemId,
  lineItemId,
  nestLevel,
  qtyRemaining: Math.round(amount?.available?.individual) || 0,
  qtyAggregate: Math.round(amount?.available?.aggregate) || 0,
  children: [
    ...(LBSData?.children?.map((child: any) => ({
      id: child.locationId,
      parentId: locationId,
      nestLevel: nestLevel + 1,
      lineItemId,
    })) ?? []),
    ...workPhasesFields,
  ],
  workPhasesFields,
});

const getChildBelow = ({
  locationId,
  lineItemId,
  auxCurrent,
  parentLocationIndex,
  amount,
  nestLevel,
  LBSData,
  workPhasesFields,
  dontSend,
}: any) => {
  const children =
    LBSData?.children?.map((child: any) => ({
      id: child.locationId,
      parentId: locationId,
      nestLevel: nestLevel + 1,
      lineItemId,
    })) ?? [];
  children.push(...workPhasesFields);
  const newChild = {
    id: locationId,
    lineItemId,
    parentId: auxCurrent[parentLocationIndex]?.id,
    nestLevel,
    qtyRemaining: Math.round(amount?.available?.individual) || 0,
    qtyAggregate: Math.round(amount?.available?.aggregate) || 0,
    children: [...children],
    dontSend,
    workPhasesFields,
  };
  return newChild;
};

export const getNewQtyFields = ({
  current,
  locationData,
  workPhases,
  nestLevel,
  locationId,
  lineItemId,
  amount,
  LBSData,
  autoFilledFields,
  setAutoFilledFields,
  setValue,
  getValues,
}: any): QuantityFieldType[] => {
  const auxCurrent = [...current];
  const workPhasesFields = getWorkPhasesFields({ locationData, workPhases, nestLevel, locationId, lineItemId });
  const childQtyFieldName = `qtyToAdd__${locationId}__${lineItemId}`;
  const unassignedRowFieldName = `qtyToAdd__${UNASSIGNED}__${lineItemId}`;
  const concatNewItems = (newChild: QuantityFieldType) => auxCurrent.push(...[newChild, ...workPhasesFields]);
  if (nestLevel === 1) {
    const parentLineItemIndex = auxCurrent.findIndex((item) => item.id === lineItemId);
    if (parentLineItemIndex === -1) return current;
    const lineItemQuantityField = auxCurrent[parentLineItemIndex];
    const parentQtyFieldName = `qtyToAdd__bomItem__${lineItemId}`;
    if (getValues(parentQtyFieldName)) {
      setValue(childQtyFieldName, amount?.available?.individual);
      setValue(unassignedRowFieldName, lineItemQuantityField?.unassignedAmount);
      addAutoFilledField(childQtyFieldName, {
        autoFilledFields,
        setAutoFilledFields,
      });
      addAutoFilledField(unassignedRowFieldName, {
        autoFilledFields,
        setAutoFilledFields,
      });
    }
    const child = getFirstLevelChild({
      locationId,
      lineItemId,
      nestLevel,
      amount,
      LBSData,
      workPhasesFields,
    });
    auxCurrent[parentLineItemIndex] = { ...auxCurrent[parentLineItemIndex], children: [child, ...workPhasesFields] };
    concatNewItems(child);
    return auxCurrent;
  }
  const parentLocationIndex = auxCurrent.findIndex(
    (item) => item.id === locationData.parentLocationId && item.lineItemId === lineItemId,
  );
  const parentQtyFieldName = `qtyToAdd__${auxCurrent[parentLocationIndex]?.id}__${lineItemId}`;
  let dontSend;
  const parentItem = auxCurrent[parentLocationIndex];
  if (
    autoFilledFields.find((field: string) => field === parentQtyFieldName) ||
    parseInt(getValues(parentQtyFieldName), 10) === parentItem?.qtyAggregate
  ) {
    dontSend = true;
    setValue(childQtyFieldName, amount?.available?.individual);
    addAutoFilledField(childQtyFieldName, {
      autoFilledFields,
      setAutoFilledFields,
    });
  }
  const child = getChildBelow({
    locationId,
    lineItemId,
    auxCurrent,
    parentLocationIndex,
    amount,
    nestLevel,
    LBSData,
    workPhasesFields,
    dontSend,
  });
  concatNewItems(child);
  if (!auxCurrent[parentLocationIndex]) return auxCurrent;
  if (
    auxCurrent[parentLocationIndex]?.children?.length &&
    !auxCurrent[parentLocationIndex]?.children?.find((child: any) => child.id === locationId)
  ) {
    auxCurrent[parentLocationIndex].children.push(child);
  } else {
    auxCurrent[parentLocationIndex].children = [child, ...auxCurrent[parentLocationIndex].workPhasesFields];
  }

  return auxCurrent;
};

export const fetchLocationData = async ({
  getLocationById,
  locationId,
  setLocationData,
  amount,
  lineItemId,
  LBSData,
  enqueueSnackbar,
}: any) => {
  try {
    const {
      data: {
        locationById: { locationIdentifier, locationName, locationTypeName, parentLocationId, locationPhases },
      },
    } = await getLocationById({ variables: { params: { locationId } } });
    setLocationData({
      type: locationTypeName,
      locationIdentifier,
      name: locationName,
      qtyRemaining: Math.round(amount?.available?.individual) || 0,
      locationId,
      lineItemId,
      qtyAggregate: Math.round(amount?.available?.aggregate) || 0,
      children: LBSData?.children,
      parentLocationId,
      workPhases: locationPhases,
    });
  } catch (error) {
    enqueueSnackbar('error', { variant: 'error' });
  }
};
