import { useCallback } from 'react';

import { gql, useLazyQuery } from '@apollo/client';

import { forgeDocument as FORGE_DOCUMENT } from 'graphql/queries';

const useDocumentForgeViewer = () => {
  const [fetchForgeViewer, { data }] = useLazyQuery(gql(FORGE_DOCUMENT), {
    fetchPolicy: 'network-only',
  });

  const getForgeViewer = useCallback(
    async (documentId) => {
      if (!documentId) return;

      await fetchForgeViewer({
        variables: { params: { documentId } },
      });
    },

    [fetchForgeViewer],
  );

  return {
    getForgeViewer,
    forgeViewer: data?.forgeDocument,
  };
};

export default useDocumentForgeViewer;
