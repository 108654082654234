import React from 'react';

import { useMutation, gql } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import { useUser } from 'app/UserContext';
import { Modal, CancelButton, CreateButton } from 'components';
import AssignAdminControlledInput from 'components/FormComponents/AssignAdminControlledInput';
import { updateUser as UPDATE_USER, addUser as ADD_USER } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

import { useAdminContext } from '../AdminContext';

const AddAdminModal = () => {
  const { companyId, inviteModalShown, closeInviteModal } = useAdminContext();
  const { user } = useUser();

  const { handleResponse } = useGraphqlResponseHandler(closeInviteModal);
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: 'all',
  });

  const [addCompanyAdminMutation, { loading: addNewLoading }] = useMutation(gql(ADD_USER), {
    refetchQueries: ['Users'],
  });

  const [updateUserToAdminMutation, { loading: updateUserLoading }] = useMutation(
    gql(UPDATE_USER),
    {
      refetchQueries: ['Users'],
    },
  );

  const loading = updateUserLoading || addNewLoading;

  const submit = async ({ adminUserEmail }) => {
    let mutation = () => {};
    const body = { isAdmin: true };
    const vars = {};

    if (typeof adminUserEmail === 'object') {
      // existing user
      mutation = updateUserToAdminMutation;
      vars.params = { userId: adminUserEmail.id };
      vars.body = body;
    } else {
      // new user
      mutation = addCompanyAdminMutation;
      body.userEmail = adminUserEmail;
      body.companyId = companyId || user?.companyId;
      body.userStatusCode = 'UNCONFIRMED';
      body.userName = uuidv4();
      vars.body = body;
    }

    await handleResponse(
      mutation,
      {
        variables: vars,
      },
      { successMessage: 'Admin added successfully!' },
    );
  };

  return (
    <Modal
      open={inviteModalShown}
      onClose={closeInviteModal}
      title="Add Admin"
      reducePadding
      fullWidth
      maxWidth="xs"
      footer={
        <>
          {loading && <CircularProgress />}
          <CancelButton onClick={closeInviteModal} />
          <CreateButton
            color="primary"
            disabled={!isValid || loading}
            onClick={handleSubmit(submit)}
          />
        </>
      }
    >
      <AssignAdminControlledInput control={control} sx={{ margin: 0 }} />
    </Modal>
  );
};

export default AddAdminModal;
