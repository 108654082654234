import React from 'react';

import Stack from '@mui/material/Stack';

import { useDrawingDocumentContext } from './DrawingDocumentContext';
import DrawingDocumentDetailView from './DrawingDocumentDetailView';
import DrawingDocumentsHeader from './DrawingDocumentsHeader';
import DrawingDocumentsList from './DrawingDocumentsList';

const DrawingDocument = () => {
  const { inForgeView } = useDrawingDocumentContext();

  return (
    <Stack flex="1" sx={{ height: 'inherit' }}>
      <DrawingDocumentsHeader />
      {inForgeView ? <DrawingDocumentDetailView /> : <DrawingDocumentsList />}
    </Stack>
  );
};

export default DrawingDocument;
