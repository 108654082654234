import React, { forwardRef } from 'react';

import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import { Custom } from 'components/Icons';

import DropDownMenuProperties from './DropDownMenuProperties';

const ComponentPane = ({ children, pb = 1.5, renderMenuItems, ...props }, ref) => (
  <StyledComponentPane ref={ref} {...props}>
    <IconButton style={{ width: '40px', height: '40px' }} className="draggable-area" size="large">
      <Custom.DragIndicatorIcon fontSize="inherit" />
    </IconButton>
    <Stack flex={1} pt={1.5} pb={pb} height="100%" overflow="hidden">
      {children}
    </Stack>
    <DropDownMenuProperties renderMenuItems={renderMenuItems} />
  </StyledComponentPane>
);

export default forwardRef(ComponentPane);

const StyledComponentPane = forwardRef((props, ref) => (
  <Paper
    ref={ref}
    sx={{
      height: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      overflowY: 'hidden',
    }}
    {...props}
  />
));
