/* eslint-disable max-lines-per-function */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';

import TableMUI from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';

import TableContent from './TableContent';
import TableHeaders from './TableHeaders';
import { useTableHelpers } from './useTableHelpers';

const getUniqueData = (list, id) => [
  ...list.reduce((map, value) => map.set(value[id], value), new Map()).values(),
];

const Table = (
  {
    pagination,
    data = [],
    headers = [],
    rowComponent,
    onSelected,
    onSelectRow,
    onClickOutsideRow,
    components,
    componentsProps,
    componentsSx,
    rowSx,
    size,
    id,
    tableSx,
    tableMUISx,
    isDraggable = false,
    infiniteScroll = false,
    onFetchMore = () => {},
    stickyHeader,
    clearItems,
    selectCell = true,
  },
  ref,
) => {
  const dataWithoutNulls = data.filter((item) => item !== null);
  const [rows, setRows] = React.useState(dataWithoutNulls);
  const [items, setItems] = React.useState([]);
  const [lastSelectedItem, setLastSelectedItem] = React.useState(null);
  const [selectedRowItem, setSelectedRowItem] = React.useState(null);
  const [sorterKeys, setSorterKeys] = React.useState({});
  const { getNewItems } = useTableHelpers({ data, id });

  React.useEffect(() => {
    const updated = getUniqueData(items, id);
    setItems((prevItems) => {
      if (JSON.stringify(prevItems) !== JSON.stringify(updated)) {
        return updated;
      }
      return prevItems;
    });
  }, [items]);

  const onSelectedCallback = (selected, e) => {
    const isShiftKeyPressed = e.nativeEvent.shiftKey;
    const itemExists = items.some((item) => item[id] === selected[id]);

    if (!isShiftKeyPressed) {
      const newItems = itemExists
        ? items.filter((item) => item[id] !== selected[id])
        : [...items, data.find((item) => item[id] === selected[id])];
      setItems(newItems);
      setLastSelectedItem(() => (itemExists ? null : selected));
      return;
    }

    const lastItemNotSelected = lastSelectedItem == null;
    if (lastItemNotSelected) {
      setItems([data.find((item) => item[id] === selected[id])]);
      setLastSelectedItem(selected);
      return;
    }

    setItems(getNewItems(items, selected, lastSelectedItem));
  };

  React.useImperativeHandle(ref, () => ({
    resetOnSelected: () => {
      setLastSelectedItem(null);
      setItems([]);
    },
  }));

  const onSelectedAllCallback = () => {
    if (items.length === rows.length) {
      setLastSelectedItem(null);
      setItems([]);
    } else {
      setItems(rows);
    }
  };

  React.useEffect(() => setItems([]), [clearItems]);

  const onSelectedRowCallback = (row) => {
    onSelectRow(row);
    setSelectedRowItem(row);
  };

  React.useEffect(() => {
    if (onSelected) {
      onSelected({ items });
    }
  }, [items]);

  React.useEffect(() => setRows(dataWithoutNulls), [data]);

  const onSort = (key, sortServerKey) => {
    if (!sortServerKey || !pagination?.onOrderby) {
      let value = false;
      const exist = sorterKeys[key];
      if (exist) {
        const sorted = [...rows].sort((after, before) => {
          const b = before[key]?.toString() ?? '';
          const a = after[key]?.toString() ?? '';
          return b.localeCompare(a);
        });
        setRows(sorted);
      } else {
        value = true;
        const sorted = [...rows].sort((after, before) => {
          const b = before[key]?.toString() ?? '';
          const a = after[key]?.toString() ?? '';
          return a.localeCompare(b);
        });
        setRows(sorted);
      }
      setSorterKeys({ ...sorterKeys, [key]: value });
    } else {
      pagination?.onOrderby(sortServerKey);
    }
  };

  return (
    <TableContainer
      ref={pagination?.ref}
      onScroll={() => {
        if (pagination?.onScroll) {
          pagination.onScroll();
        }
      }}
      sx={{
        border: 'black 1px solid',
        borderColor: 'other.border',
        borderRadius: 1,
        height: '100vh',
        ...tableSx,
      }}
    >
      <TableMUI sx={{ minWidth: '100%', ...tableMUISx }} size={size} stickyHeader={stickyHeader}>
        <TableHeaders
          rowSx={rowSx}
          onSort={onSort}
          headers={headers}
          onSelected={onSelected ? onSelectedAllCallback : null}
          hasAllSelected={items.length === rows.length && items.length + rows.length !== 0}
        />
        <TableContent
          pagination={pagination}
          id={id}
          data={rows}
          headers={headers}
          rowComponent={rowComponent}
          isDraggable={isDraggable}
          onSelected={onSelected ? onSelectedCallback : null}
          itemsSelected={items}
          onSelectRow={onSelectRow ? onSelectedRowCallback : null}
          rowSelected={selectedRowItem}
          components={components}
          componentsProps={componentsProps}
          componentsSx={componentsSx}
          rowSx={rowSx}
          infiniteScroll={infiniteScroll}
          onFetchMore={onFetchMore}
          onClickOutsideRow={onClickOutsideRow}
          selectCell={selectCell}
        />
      </TableMUI>
    </TableContainer>
  );
};

export default React.forwardRef(Table);
