/* eslint-disable no-console */
import React, { useEffect } from 'react';

import { CircularProgress } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import { Modal, CancelButton, CreateButton } from 'components';

import { useFacilityContext } from '../FacilityContext';
import FacilityInfoForm from '../FacilityInfoForm';
import DepartmentInfoForm from './DepartmentInfoForm';

const EditFacilityModal = ({ open, onClose }) => {
  const { facility, submit, loading, updatedFacility } = useFacilityContext();
  const methods = useForm({ mode: 'all' });

  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  useEffect(() => {
    if (updatedFacility) onClose(updatedFacility);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedFacility]);

  return (
    <Modal
      title="Edit facility"
      open={open}
      onClose={onClose}
      footer={
        <>
          {loading && <CircularProgress />}
          <CancelButton onClick={onClose} sx={{ marginRight: '8px' }} />
          <CreateButton
            buttonText="Save Changes"
            color="primary"
            onClick={async () => {
              await handleSubmit(submit)();
            }}
            disabled={!isValid || loading}
          />
        </>
      }
    >
      <FormProvider {...methods}>
        <FacilityInfoForm facilityName={facility.facilityName} address={facility.address} />
        <DepartmentInfoForm departments={facility.departments} />
      </FormProvider>
    </Modal>
  );
};

export default EditFacilityModal;
