import React, { useEffect, useState } from 'react';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import CenteredLoadSpinner from 'components/CenteredLoadSpinner';
import { getStatusFilters } from 'helpers/arrayFunctions';
import useFetchTaskStatusTypes from 'hooks-api/useFetchTaskStatusTypes';

import { useWorkCellsList } from '../context/WorkCellsListContext';

const STATUSBYGROUP = { closed: ['Started', 'Paused', 'Not Started'], open: ['Completed'] };

const WorkCellTabs = () => {
  const [value, setValue] = useState('open');
  const { taskStatusTypes, loading } = useFetchTaskStatusTypes();
  const { setFilters, setStatusToFilter } = useWorkCellsList();

  const handleChange = (_, newValue) => {
    setValue(newValue);
    setFilters({
      taskStatusTypeIds: getStatusFilters(taskStatusTypes, STATUSBYGROUP[newValue]),
    });
    setStatusToFilter(newValue);
  };

  useEffect(() => {
    if (!loading) {
      handleChange(null, 'open');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <>
      {loading && <CenteredLoadSpinner />}
      {!loading && taskStatusTypes && (
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          sx={{ ml: 10 }}
        >
          <Tab
            label="Open"
            sx={{
              width: '170px',
              fontWeight: 500,
            }}
            value="open"
          />
          <Tab label="Closed" sx={{ width: '170px', fontWeight: 500 }} value="closed" />
        </Tabs>
      )}
    </>
  );
};

export default WorkCellTabs;
