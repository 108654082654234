import { surfaceColors } from './colors';

const darkScrollBarStyle = {
  /* Track */
  '::-webkit-scrollbar-track': {
    background: '#616161',
  },

  /* Handle */
  '::-webkit-scrollbar-thumb': {
    background: 'rgba(0, 0, 0, 0.5)',
  },

  /* Handle on hover */
  '::-webkit-scrollbar-thumb:hover': {
    background: 'rgba(0, 0, 0, 0.7)',
  },
};

const darkSurfaceScrollBarStyle = {
  '::-webkit-scrollbar': {
    width: '6px',
    borderRadius: '4px',
  },
  /* Track */
  '::-webkit-scrollbar-track': {
    background: 'rgba(255, 255, 255, 0.1)',
  },

  /* Handle */
  '::-webkit-scrollbar-thumb': {
    background: 'rgba(255, 255, 255, 0.6)',
    borderRadius: '4px',
  },

  /* Handle on hover */
  '::-webkit-scrollbar-thumb:hover': {
    background: 'rgba(255, 255, 255, 0.6)',
  },
};

const lightScrollBarStyle = {
  /* Track */
  '::-webkit-scrollbar-track': {
    background: 'rgba(0, 0, 0, 0.1)',
  },

  /* Handle */
  '::-webkit-scrollbar-thumb': {
    background: surfaceColors.lightSurface.secondary,
  },
  // surfaceColors.lightSurface.secondary
  /* Handle on hover */
  '::-webkit-scrollbar-thumb:hover': {
    background: 'rgba(0, 0, 0, 0.7)',
  },
};

export { darkScrollBarStyle, lightScrollBarStyle, darkSurfaceScrollBarStyle };
