import React, { useState, useEffect } from 'react';

import { removeDuplicateItemsByKey } from 'helpers/arrayFunctions';

import { CONFIG_COLUMNS, GROUPED_VALUES, SEPARATED_VALUES } from '../helpers/constants';
import { formatFiltersToDisplay } from '../helpers/filterPopoverHelpers';

const PopoverFilterContext = React.createContext();

const PopoverFilterProvider = ({ children, columns: colOptions }) => {
  const [columns, setColumns] = useState(colOptions ?? []);
  const [columnsBackup, setColumnsBackup] = useState([]);
  const [filters, setFilters] = useState([]);
  const [displayFilters, setDisplayFilters] = useState([]);

  useEffect(() => {
    setDisplayFilters(formatFiltersToDisplay(filters));
  }, [filters]);

  const appendFilters = (filtersList) => {
    const filtersFlat = filtersList.map((filter) => filter.column);
    const columnsFlat = columnsBackup.map((column) => column.value);

    const columnsToBackup = columns.filter(
      (column) => !columnsFlat.includes(column.value) && filtersFlat.includes(column.value),
    );

    if (filtersFlat.includes('dateFrom') && filtersFlat.includes('dateTo')) {
      columnsToBackup.push({
        disabled: false,
        label: 'date',
        value: 'date',
        options: [],
      });
    }

    setColumnsBackup((prevState) => [...prevState, ...columnsToBackup]);
    setFilters((prevState) => removeDuplicateItemsByKey([...filtersList, ...prevState], 'column'));
  };

  const appendFilter = ({ column, values }) => {
    appendFilters([{ column, values }]);
  };

  const removeFilterByDisplay = (displayFilter) => {
    if (displayFilter.column === 'date') {
      setFilters((prevState) =>
        prevState.filter((fil) => fil.column !== 'dateFrom' && fil.column !== 'dateTo'),
      );
      return;
    }
    if (CONFIG_COLUMNS[displayFilter.column].chipType === GROUPED_VALUES) {
      setFilters((prevState) => prevState.filter((fil) => fil.column !== displayFilter.column));
    }
    if (CONFIG_COLUMNS[displayFilter.column].chipType === SEPARATED_VALUES) {
      const newFilters = [...filters];
      const columnIndex = newFilters.findIndex((obj) => obj.column === displayFilter.column);
      if (columnIndex !== -1) {
        const valueIndex = newFilters[columnIndex].values.findIndex(
          (val) => val.value === displayFilter.value,
        );

        if (valueIndex !== -1) {
          newFilters[columnIndex].values.splice(valueIndex, 1);
          if (newFilters[columnIndex].values.length === 0) {
            newFilters.splice(columnIndex, 1);
          }
          setFilters(newFilters);
        }
      }
    }
  };

  const removeFilterByColumn = (column) => {
    setFilters((prevState) => prevState.filter((filter) => filter.column !== column));
  };

  const clearFilters = () => {
    setFilters([]);
    setColumnsBackup([]);
  };

  const contextObj = {
    columns,
    setColumns,
    filters,
    appendFilter,
    appendFilters,
    clearFilters,
    displayFilters,
    removeFilterByDisplay,
    removeFilterByColumn,
    columnsBackup,
    setColumnsBackup,
  };

  return (
    <PopoverFilterContext.Provider value={contextObj}>{children}</PopoverFilterContext.Provider>
  );
};

const usePopoverFilterContext = () => {
  const context = React.useContext(PopoverFilterContext);
  if (context === undefined) {
    throw new Error('usePopoverFilterContext must be used within a PopoverFilterContext');
  }
  return context;
};

export { usePopoverFilterContext, PopoverFilterContext, PopoverFilterProvider };
