import React, { useCallback, useEffect, useMemo } from 'react';

import { ForesiteTextField } from '@evolvemep/foresite-components-ui-react';
import { Autocomplete } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';

import { useCatalogModalQuery, useCatalogModal } from './CatalogModalContext';

const UOMAutocomplete = ({ workRequestItem = {} }) => {
  const { isLoading, control, setValue: setFormValue, watch } = useFormContext();

  const { fetchUOMs, UOMsData } = useCatalogModalQuery();
  const { setUOM } = useCatalogModal();

  useEffect(() => {
    fetchUOMs();
  }, [fetchUOMs]);

  const uomList = useMemo(
    () =>
      (UOMsData?.unitOfMeasure ?? []).map(({ unitOfMeasureId, unitOfMeasureName }) => ({
        label: unitOfMeasureName,
        value: unitOfMeasureId,
      })),
    [UOMsData],
  );

  const uomValue = watch('uom');
  useEffect(() => {
    if (!uomValue) return;

    if (typeof uomValue === 'string') {
      setUOM(uomValue);
      return;
    }

    setUOM(uomValue.value);
  }, [uomValue, setUOM]);

  useEffect(() => {
    const defaultUOM = uomList.find(({ value }) => value === workRequestItem.unitOfMeasureId);
    if (defaultUOM) {
      setFormValue('uom', defaultUOM, { shouldDirty: true });
    }
  }, [uomList, workRequestItem, setFormValue]);

  const filterUOMs = useCallback((options, { inputValue }) => {
    if (!inputValue) return options;

    return options.filter(({ label }) => label.toLowerCase().startsWith(inputValue.toLowerCase()));
  }, []);

  return (
    <Controller
      control={control}
      name="uom"
      rules={{ required: true }}
      render={({ field: { onChange, ref, value }, fieldState: { error } }) => (
        <Autocomplete
          fullWidth
          disabled={isLoading}
          color="secondary"
          options={uomList}
          filterOptions={filterUOMs}
          getOptionLabel={(option) => option?.label || ''}
          value={value || {}}
          onChange={(event, newValue) => onChange(newValue)}
          renderInput={(params) => (
            <ForesiteTextField
              {...params}
              fullWidth
              inputRef={ref}
              size="small"
              variant="outlined"
              label="Unit of Measure *"
              error={Boolean(error)}
              helperText={error?.message}
            />
          )}
        />
      )}
    />
  );
};

export default UOMAutocomplete;
