import React from 'react';

import Typography from '@mui/material/Typography';

const RowTitle = ({ children, disabled, sx, testId }) => (
  <Typography
    variant="subtitle2"
    data-testid={testId}
    sx={{
      flex: 1,
      marginLeft: 1,
      userSelect: 'none',
      color: disabled ? 'surface.lightSurface.secondary' : 'inherit',
      ...sx,
    }}
  >
    {children}
  </Typography>
);

export default RowTitle;
