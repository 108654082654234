import React, { useState } from 'react';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';

import EditFacilityModal from './EditFacilityModal/EditFacilityModal';
import FacilityDeleteModal from './FacilityDeleteModal';

const MoreOptions = ({ refetchFacilities }) => {
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const onDeleteClick = () => setOpenDelete(true);
  const onEditClick = () => setOpenEdit(true);
  const closeEditModal = () => {
    setOpenEdit(false);
    refetchFacilities();
  };

  return (
    <Box>
      <MenuItem onClick={onEditClick}>Edit</MenuItem>{' '}
      <MenuItem onClick={onDeleteClick}>Delete</MenuItem>{' '}
      <EditFacilityModal open={openEdit} onClose={closeEditModal} />
      <FacilityDeleteModal open={openDelete} onClose={() => setOpenDelete(false)} />
    </Box>
  );
};

export default MoreOptions;
