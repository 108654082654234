import React from 'react';

import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';

import Modal from 'components/Modal';

const ChangeShopConfigurationModal = ({
  open,
  newConfigurationName,
  loading,
  onClose,
  onSubmit,
  onPrevious,
}) => {
  const warnings = [
    <span>
      By changing the shop configuration to <b>{newConfigurationName}</b>, all tasks &apos;in
      progress&apos; and &apos;not started&apos; will be remapped.
    </span>,
    'Work Cell Kanban will lose priorities and will need to be reset.',
    'Work Order Kanban priorities will remain the same.',
    'Once tasks are remapped, the order of the tasks will follow Work Order priorities.',
  ];
  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Change Shop Configuration"
      titleStyles={{ fontWeight: 'bold' }}
      maxWidth="sm"
      footer={
        <>
          {loading && <CircularProgress size={20} />}
          {onPrevious && (
            <Button disabled={loading} color="secondary" onClick={onPrevious}>
              PREVIOUS
            </Button>
          )}
          <Button disabled={loading} color="secondary" variant="outlined" onClick={onClose}>
            CANCEL
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={onSubmit}
            disabled={loading}
            sx={{ marginLeft: '8px' }}
          >
            SAVE
          </Button>
        </>
      }
    >
      <ul
        style={{
          paddingLeft: 24,
          listStyleType: 'none',
          position: 'relative',
        }}
      >
        {warnings.map((warning) => (
          <li key={warning}>
            <span style={{ left: '8px', position: 'absolute' }}>•</span>
            {warning}
          </li>
        ))}
      </ul>
    </Modal>
  );
};

export default ChangeShopConfigurationModal;
