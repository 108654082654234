/* eslint-disable max-lines-per-function */
import React from 'react';

import { Box, Button, Tooltip, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import MoreMenu from 'components/MoreMenu';
import { VARIANT_ERROR } from 'constants/snackbarConstants';
import { useCancelOperation } from 'hooks/useCancelOperation';
import { useEqualValueChecker } from 'hooks/useEqualValueChecker';

import TabNameInput from './TabNameInput/TabNameInput';

const getTabItemOptionCb = (pad) => (option) => {
  const isDeleteItemOption = option.key === 'Delete';
  return {
    ...option,
    ...(isDeleteItemOption && { disabled: Boolean(pad.hasMappedItems) }),
    clickEvent: () => option?.clickEvent(pad),
  };
};

const TabItem = ({
  labelName = '',
  isSelected = false,
  currentIndex,
  handleChange = () => {},
  item,
  options,
  nameIsEditable,
  itemSelected,
  id,
  saveName,
  cancelEdit = () => {},
  showSeparator,
}) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const handleClick = (event) => {
    handleChange(event, currentIndex, item);
  };
  const { enqueueSnackbar } = useSnackbar();

  const [currentName, setCurrentName] = React.useState(labelName);
  const onChange = (event) => setCurrentName(event.target.value);
  const nameRule = useEqualValueChecker({
    currentName,
    customMessage: 'Takeoff pad name already exists',
    rules: [
      {
        check: (value = '') => value.length > 32,
        message: 'Name should be less than 32 characters',
      },
      {
        check: (value = '') => value.length === 0,
        message: 'Takeoff Pad name is required',
      },
    ],
  });

  const onSave = (event) => {
    const { error } = nameRule;
    if (event.key === 'Enter') {
      if (currentName.length !== 0 && !error) {
        saveName({ name: currentName, id });
        setCurrentName(currentName);
      }
      if (error) {
        enqueueSnackbar(nameRule.message, VARIANT_ERROR);
      }
    }
    setIsVisible(false);
  };

  const { ref } = useCancelOperation(() => {
    cancelEdit();
    setCurrentName(labelName);
    setIsVisible(false);
  });

  return (
    <Box>
      {nameIsEditable && itemSelected === id ? (
        <form ref={ref} onSubmit={(event) => event.preventDefault()} autoComplete="off">
          <TabNameInput
            error={nameRule.error}
            value={currentName}
            onChange={onChange}
            onKeyPress={(event) => {
              onSave(event);
            }}
            helperText={nameRule.error ? nameRule.message : ''}
            editable
            onBlur={() => onSave({ key: 'Enter' })}
            autoFocus={nameIsEditable && itemSelected === id}
          />
        </form>
      ) : (
        <Tooltip title={labelName} placement="top" arrow>
          <Button
            id={id}
            onMouseEnter={() => setIsVisible(true)}
            onMouseLeave={() => setIsVisible(false)}
            onClick={handleClick}
            data-cy="button-to-delete-pad-item"
            sx={{
              borderBottomColor: isSelected ? 'secondary.main' : 'transparent',
              borderBottomWidth: isSelected ? '2px' : '1px',
              borderBottomStyle: 'solid',
              borderRight: '1px solid #CCCCCC',
              borderRightColor: showSeparator ? 'rgba(0, 0, 0, 0.54)' : '#CCCCCC',
              borderRightWidth: showSeparator ? '2px' : '1px',
              borderTop: '1px solid #CCCCCC',
              color: isSelected ? 'secondary.main' : 'surface.lightSurface.secondary',
              textTransform: 'capitalize',
              fontFamily: 'Roboto',
              fontWeight: '700',
              borderRadius: 0,
              minWidth: 98,
              height: '100%',
              mb: 2,
            }}
            size="medium"
          >
            <Box
              display="flex"
              sx={{
                maxWidth: '82px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="body2"
                fontWeight="bold"
                sx={{
                  marginRight: options ? 1 : 0,
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  fontWeight: 'bold',
                }}
                data-cy="pad-name"
              >
                {labelName}
              </Typography>
            </Box>
            {options && isVisible && (
              <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
                <MoreMenu
                  options={Array.isArray(options) ? options.map(getTabItemOptionCb(item)) : options}
                  sx={{ height: 24, width: 24 }}
                  sxIconButton={{ padding: 0 }}
                />
              </Box>
            )}
          </Button>
        </Tooltip>
      )}
    </Box>
  );
};

export default TabItem;
