import React, { FC } from 'react';

import { MenuItem, Stack, SxProps, Typography } from '@mui/material';

import { Custom } from 'components/Icons';

interface IMenuOption {
  text?: string;
  chevron?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  sx?: SxProps;
}

const MenuOption: FC<IMenuOption> = ({ text, chevron, onClick = () => {}, disabled = false, sx }: IMenuOption) => (
  <MenuItem disabled={disabled} onClick={onClick}>
    <Stack direction="row" sx={{ justifyContent: 'space-between', width: '100%' }}>
      <Typography sx={{ fontSize: 14, lineHeight: '20px', ...sx }}>{text}</Typography>
      {chevron && <Custom.KeyboardArrowRight style={{ marginLeft: '16px' }} />}
    </Stack>
  </MenuItem>
);

export default MenuOption;
