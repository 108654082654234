import { useCallback, useState } from 'react';

import { gql, useLazyQuery } from '@apollo/client';

import {
  partsCloudSearch as PARTS_COULD_SEARCH,
  partsCloudSearchCount as PARTS_CLOUD_SEARCH_COUNT,
} from 'graphql/queries';
import {
  DEFAULT_QUERY_PARAMS_BY_PART_NAME,
  DEFAULT_QUERY_PARAMS_BY_PART_ID,
} from 'helpers/cloudSearchParams';
import { stringifyCloudQuery } from 'helpers/stringFunctions';

const PARTS_CLOUD_SEARCH_LIMIT = 5;

const usePartsCloudSearchAPI = (catalogId = '', limit = 0, noAssemblies = false) => {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);

  const [fetchParts, { loading }] = useLazyQuery(gql(PARTS_COULD_SEARCH), {
    fetchPolicy: 'no-cache',
  });

  const [fetchPartsCount] = useLazyQuery(gql(PARTS_CLOUD_SEARCH_COUNT), {
    fetchPolicy: 'no-cache',
  });

  const searchParts = useCallback(
    async (queryString) => {
      const {
        data: { partsCloudSearch },
      } = await fetchParts({
        variables: {
          query: {
            query: stringifyCloudQuery(queryString, catalogId),
            skip: 0,
            take: limit || PARTS_CLOUD_SEARCH_LIMIT,
            ...DEFAULT_QUERY_PARAMS_BY_PART_NAME,
          },
        },
      });
      setData(partsCloudSearch);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [catalogId, limit, noAssemblies],
  );

  const getPartsCount = useCallback(
    async (queryString) => {
      const {
        data: { partsCloudSearchCount },
      } = await fetchPartsCount({
        variables: {
          query: {
            query: stringifyCloudQuery(queryString, catalogId),
            ...DEFAULT_QUERY_PARAMS_BY_PART_NAME,
          },
        },
      });

      const partItemsCount = partsCloudSearchCount ?? 0;
      setCount(partItemsCount);

      return partItemsCount;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [catalogId, noAssemblies],
  );

  const getPartById = useCallback(async (queryString, partId) => {
    const {
      data: { partsCloudSearch },
    } = await fetchParts({
      variables: {
        query: {
          query: stringifyCloudQuery(queryString, partId),
          ...DEFAULT_QUERY_PARAMS_BY_PART_ID,
        },
      },
    });
    setData(partsCloudSearch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { data, count, loading, searchParts, getPartsCount, getPartById };
};

export default usePartsCloudSearchAPI;
