import React from 'react';

import { Box, Typography } from '@mui/material';

import { IPartNodeHeader } from './@types/partTypeNodeTypes';

const PartNodeHeader: React.FC<IPartNodeHeader> = ({
  title = '',
  nodeName = '',
  isAssembly = false,
  id,
}: IPartNodeHeader) => (
  <Box
    style={{
      display: 'flex',
      flexDirection: 'column',
      height: 48,
      width: 174,
      marginBottom: 3,
      paddingLeft: '8px',
    }}
  >
    <Typography
      style={{
        height: 16,
        marginBottom: 4,
        fontSize: 12,
      }}
      color="#DB6939"
      data-testid="partNodeHeaderTitle"
    >
      {title}
    </Typography>
    <Typography
      data-testid={isAssembly ? `assemblyNode-${id}` : `partNode-${id}`}
      style={{
        height: 24,
        width: '100%',
        fontSize: 14,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      }}
      color={({ palette }: any) => palette.surface.lightSurface.primary}
    >
      {nodeName}
    </Typography>
  </Box>
);
export default PartNodeHeader;
