import React, { useRef } from 'react';

import { Select } from '@mui/material';

const RightAlignedSelect = (props) => {
  const { strings } = props;
  const numberOfLetters = useRef(0);
  if (strings[0]) {
    numberOfLetters.current = strings.reduce((a, b) => (a.length > b.length ? a : b)).length;
  }
  return (
    <Select
      style={{ width: '286px' }}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        PaperProps: {
          style: {
            maxHeight: '414px',
            width: '286px',
          },
        },
      }}
      sx={{ '.MuiTypography-root': { fontSize: '16px' } }}
      {...props}
    />
  );
};
export default RightAlignedSelect;
