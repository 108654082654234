import { useCallback } from 'react';

import { useEqualValueChecker } from 'hooks/useEqualValueChecker';

import { useTakeOff } from '../../context/TakeOffContext';
import { useTabPadsContext } from './TabPadsContext';

const useTabPadsActions = () => {
  const { onSelectPad, onRenameTakeOffPad, onDeleteTakeoffPad, takeOffPads, setDisabledDropdown } =
    useTakeOff();
  const {
    setAutoFocus,
    setAddNewPad,
    setCurrentPadSelected,
    currentPadSelected,
    currentName,
    hideModal,
    setCurrentName,
    setNameIsEditable,
  } = useTabPadsContext();

  const nameRule = useEqualValueChecker({
    currentName,
    customMessage: 'Takeoff pad name already exists',
    rules: [
      {
        check: (value = '') => value.length > 32,
        message: 'Name should be less than 32 characters',
      },
      {
        check: (value = '') => value.length === 0,
        message: 'Takeoff Pad name is required',
        skip: true,
      },
    ],
  });

  const onChangeNameToDelete = (padId) => setCurrentPadSelected(padId);

  const showAddNewPad = useCallback(() => {
    setAddNewPad(true);
    onSelectPad(null);
    setAutoFocus(true);
  }, [onSelectPad, setAddNewPad, setAutoFocus]);

  const onAccept = async () => {
    const takeoffPadId = currentPadSelected?.takeoffPadId;
    await onDeleteTakeoffPad({ takeoffPadId });
    hideModal();
    if (takeOffPads.length - 1 >= 1) setDisabledDropdown(false);
    else setDisabledDropdown(true);
  };

  const handleTabChange = (event, newTabIndex, pad) => {
    event.stopPropagation();
    onSelectPad(newTabIndex, pad);
  };

  const handleTextChange = (e) => {
    const { value } = e.target;
    setCurrentName(value);
  };

  const saveName = ({ name, id }) => {
    onRenameTakeOffPad({ takeoffPadId: id, takeoffPadName: name });
    setNameIsEditable(false);
  };

  const cancelEdit = () => {
    setCurrentPadSelected({});
    setNameIsEditable(false);
  };

  const resetForm = () => {
    setAddNewPad(false);
  };

  return {
    handleTextChange,
    onChangeNameToDelete,
    handleTabChange,
    showAddNewPad,
    saveName,
    cancelEdit,
    resetForm,
    nameRule,
    onAccept,
  };
};

export default useTabPadsActions;
