import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { EIconType, getNavIcon } from 'helpers/MenuIcons';
import CollapseChildrenIcon from 'modules/Field/LocationsAndWorkPhases/components/CollapseChildrenIcon';
import { actionColors, surfaceColors } from 'styles/theme/colors';

type Props = {
  icon: EIconType;
  label: string;
  opened: boolean;
  onOpening: () => void;
  disabled?: boolean;
};

const RowIcon = ({ icon, label, opened, onOpening, disabled = false }: Props) => (
  <>
    <CollapseChildrenIcon
      disabled={disabled}
      open={opened}
      showIcon
      filled
      sx={{ minWidth: 14 }}
      onClick={() => onOpening()}
    />
    <Box
      sx={{
        mt: '6px',
        width: '20px',
      }}
    >
      {getNavIcon(icon, opened ? surfaceColors.lightSurface.primary : actionColors.lightSurface.active) ?? icon}
    </Box>
    <Typography
      sx={{
        fontSize: '14px',
        display: 'inline',
        ml: '16px',
        color: 'surface.lightSurface.primary',
      }}
    >
      {label}
    </Typography>
  </>
);

export default RowIcon;
