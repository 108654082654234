import React, { useState } from 'react';

import DeleteModal from 'components/DeleteModal';

import { useTakeOff } from './context/TakeOffContext';

const DeletePadItem = ({ openModal, setOpenModal, id }) => {
  const { padItems, onDeleteTakeOffPadItems } = useTakeOff();
  const [loading, setLoading] = useState(false);

  const itemToDelete = padItems.filter((padItem) => padItem?.lineItemId === id)[0];

  const handleSubmit = async () => {
    setLoading(true);
    await onDeleteTakeOffPadItems([id]);
    setLoading(false);
    setOpenModal(false);
  };
  return (
    <DeleteModal
      open={openModal}
      onClose={() => setOpenModal(false)}
      deleteButtonText="Delete"
      itemName={itemToDelete?.lineItemName}
      confirmText="Are you sure you want to delete"
      onSubmit={handleSubmit}
      loading={loading}
    />
  );
};
export default DeletePadItem;
