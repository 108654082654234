import * as React from 'react';

import { Stack, Typography } from '@mui/material';

import { Custom } from 'components/Icons';

const NoRowsOverlay = ({ text }) => (
  <Stack sx={{ background: 'white', alignItems: 'center', marginTop: '128px' }}>
    <Custom.Empty />
    <Typography variant="h5" sx={{ mt: 1, color: 'rgba(0, 0, 0, 0.5)' }}>
      {text}
    </Typography>
  </Stack>
);

export default NoRowsOverlay;
