import React from 'react';

import { Box, IconButton } from '@mui/material';

import { Custom } from 'components/Icons';
import useIconColorForDataGridTable from 'components/ItemsDatagridPro/useIconColorForDataGridTable';

import { useTableRowContext } from '../context/TableRowContext';

const WorkOrderItemIcon = ({ workOrder }) => {
  const { isWriteInItem, isAddItemRow, showForm, setShowForm } = useTableRowContext();

  return (
    <>
      <Box sx={{ height: '100%', paddingTop: '8px' }}>
        {showForm ? (
          <CancelButton onClick={() => setShowForm(false)} />
        ) : (
          <>{!isAddItemRow && <RowIcon isWriteInItem={isWriteInItem} workOrder={workOrder} />}</>
        )}
      </Box>
    </>
  );
};

export default WorkOrderItemIcon;

const CancelButton = ({ onClick }) => (
  <Box
    width="32px"
    height="32px"
    onClick={onClick}
    sx={{ svg: { fill: 'rgba(0, 0, 0, 0.54)', fillOpacity: 1 }, cursor: 'pointer', ml: -1, mt: -1 }}
  >
    <IconButton size="large" sx={{ p: 0.6 }}>
      <Custom.Close />
    </IconButton>
  </Box>
);

const RowIcon = ({ isWriteInItem, workOrder }) => {
  const { iconColor } = useIconColorForDataGridTable({ id: workOrder?.workOrderItemId });

  return (
    <Box width="18px" height="17px" sx={{ path: { fill: iconColor } }}>
      {isWriteInItem ? <Custom.WriteInItemIcon /> : <Custom.Repair />}
    </Box>
  );
};
