import React, { useCallback, useMemo, useState } from 'react';

import { ForesiteTextField } from '@evolvemep/foresite-components-ui-react';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useController } from 'react-hook-form';

export const NEW_UOM_OPTION = {
  value: 'new-uom',
  label: '+ New UOM',
};

const UOM_FILTER_METHOD = {
  INCLUDES: 'includes',
  STARTS_WITH: 'startsWith',
};

const UOM_FILTER_CB = {
  [UOM_FILTER_METHOD.INCLUDES]: (filterValue) => (option) =>
    option.label.toLowerCase().includes(filterValue.toLowerCase()),
  [UOM_FILTER_METHOD.STARTS_WITH]: (filterValue) => (option) =>
    option.label.toLowerCase().startsWith(filterValue.toLowerCase()),
};

export const UOMAutocomplete = ({
  control,
  options = [],
  onCreate = () => {},
  name = 'uom',
  onKeyDown = () => {},
  onBlur = () => {},
  defaultValue = '',
  disableClearable = false,
  filterMethod = UOM_FILTER_METHOD.INCLUDES,
  disabled = false,
  sxRenderInput = {},
  inputProps = {},
}) => {
  const [highlightedOption, setHighligtedOption] = useState();
  const [open, setOpen] = useState(false);

  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required: { value: true, message: '*Required' } },
    defaultValue,
    shouldUnregister: true,
  });

  const optionsWithNewUOM = useMemo(() => [NEW_UOM_OPTION, ...options], [options]);

  const onValueChange = useCallback(
    (_event, newValue) => {
      if (newValue?.value === NEW_UOM_OPTION.value) {
        onCreate();
        onChange(null);
        return;
      }
      onChange(newValue);
    },
    [onChange, onCreate],
  );

  const filterOptions = useCallback(
    (_, inputState) => {
      const { inputValue = '' } = inputState;
      const filterdOptions = optionsWithNewUOM.filter(UOM_FILTER_CB[filterMethod](inputValue));
      return filterdOptions ?? [];
    },
    [filterMethod, optionsWithNewUOM],
  );

  const handleKeyDown = (e) => {
    if (e.key !== 'Enter' || !open) return;
    onChange(highlightedOption);
    setOpen(false);
    e.stopPropagation();

    setTimeout(() => {
      if (e.key === 'Enter' && e.target?.value === NEW_UOM_OPTION.label) onCreate();
    }, 0);
  };

  return (
    <Autocomplete
      freeSolo
      fullWidth
      disabled={disabled}
      placeholder="UOM"
      value={value ?? ''}
      onChange={onValueChange}
      options={optionsWithNewUOM}
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      componentsProps={{ paper: { sx: { minWidth: '126px' } } }}
      disableClearable={disableClearable || !value}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      filterOptions={filterOptions}
      filterSelectedOptions
      onHighlightChange={(_e, option) => setHighligtedOption(option)}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      renderInput={(params) => (
        <ForesiteTextField
          {...params}
          fullWidth
          margin="dense"
          variant="outlined"
          color="secondary"
          size="small"
          placeholder="UOM"
          error={!!error}
          helperText={error?.message?.replace("'", '').replace("'", '')}
          sx={{ ...getInputStyles(disabled), ...sxRenderInput }}
          onKeyDown={handleKeyDown}
          {...inputProps}
        />
      )}
      renderOption={UOMAutocompleteOption}
      data-cy="UOM-autocomplete"
    />
  );
};

const UOMAutocompleteOption = (props, option) => {
  const { value, label } = option;
  const color = value === NEW_UOM_OPTION.value ? 'secondary' : 'primary';
  return (
    <MenuItem {...props} tabIndex={0} data-cy="menu-option-UOM">
      <Typography color={color} variant="body2">
        {label}
      </Typography>
    </MenuItem>
  );
};

const getInputStyles = (disabled) => ({
  width: '100%',
  margin: 0,
  '& .MuiInputBase-root': { height: 24, fontSize: 14 },
  '& .MuiAutocomplete-input': { position: 'relative', top: -6 },
  '& .MuiFormHelperText-root.Mui-error': {
    textAlign: 'end',
    marginRight: 0,
    margin: 0,
    lineHeight: '16px',
    maxHeight: '14px',
  },
  ...(disabled ? { fieldset: { borderColor: '#eee !important' } } : {}),
});
