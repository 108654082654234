import React from 'react';

import { Box, CircularProgress } from '@mui/material';

import { CancelButton, DeleteButton, Modal } from 'components';

const WorkOrderModalDelete = ({ openStatus = true, onClose = () => {}, identifier, submit, loading = false }) => (
  <Modal
    disableModalOutsideClick
    disableCloseButton={loading}
    open={openStatus}
    onClose={onClose}
    title="Confirm delete"
    titleStyles={{
      fontWeight: 500,
    }}
    closeButtonStyles={{
      padding: 0,
      marginRight: '-4px',
    }}
    fullWidth
    maxWidth="xs"
    footer={
      <>
        {loading && <CircularProgress size={25} color="secondary" />}
        <CancelButton onClick={onClose} buttonText="Cancel" disabled={loading} />
        <DeleteButton onClick={submit} buttonText="Confirm" disabled={loading} />
      </>
    }
  >
    <Box sx={{ fontSize: '16px' }}>
      <Box sx={{ display: 'inline' }}>
        Are you sure you want to delete{' '}
        <Box fontWeight="bold" display="inline">
          {identifier}
        </Box>
        ?
      </Box>
    </Box>
  </Modal>
);

export default WorkOrderModalDelete;
