import React, { useCallback, useEffect, useMemo } from 'react';

import { CircularProgress, Stack } from '@mui/material';
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF, useGridApiRef } from '@mui/x-data-grid-pro';

import ItemsDataGridPro, { ItemsDataGridProProvider } from 'components/ItemsDatagridPro';

import { DrawingFolderProvider, useDrawingFolderContext } from '../../DrawingFolderContext';
import { DrawingFolderRowProvider } from '../../DrawingFolderRowProvider';
import { drawingFolderMap, drawingNewFolderMap } from '../../helpers/drawingFolderRowsHelper';
import useDrawingDocumentsTableColumns from '../../hooks/useDrawingDocumentsTableColumns';
import { DroppableFolderRow, DrawingFolderRow } from '../DrawingDocumentRow';
import { getTableStyles } from './DrawingFolderListConstants';

// row is passed via FolderProvider
const DrawingFolderList = ({ depth = 0 }) => {
  const apiRef = useGridApiRef();
  const {
    closeNewFolderFormRow,
    folders,
    fetchFoldersLoading,
    handleGoToFolder,
    loadParentApiRef,
  } = useDrawingFolderContext();

  useEffect(() => {
    loadParentApiRef(apiRef);
  }, [loadParentApiRef, apiRef]);

  const { columns } = useDrawingDocumentsTableColumns(apiRef, depth);

  const getDetailPanelContent = useCallback(
    ({ row }) => (
      <DrawingFolderProvider folderParent={row?.folder} parentApiRef={apiRef}>
        <DrawingFolderList row={row} depth={depth + 1} />
      </DrawingFolderProvider>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const rows = useMemo(
    () =>
      folders.map((dd, index) => {
        const { __typename: documentType } = dd;
        if (documentType === 'Folder') {
          return drawingFolderMap(dd, index);
        }
        return drawingNewFolderMap(dd, index);
      }),
    [folders],
  );

  const onCellClick = (cell) => {
    const { field } = cell;
    if (field !== GRID_DETAIL_PANEL_TOGGLE_COL_DEF.field && field !== 'encodedUrn') {
      handleGoToFolder(cell);
    }
  };

  return (
    <>
      <ItemsDataGridProProvider>
        <ItemsDataGridPro
          data-testid="subfolder-datagrid"
          autoHeight
          headerHeight={0}
          rowThreshold={0}
          apiRef={apiRef}
          rows={rows}
          getRowId={(row) => row?.id}
          getRowClassName={() => 'folder-row'}
          disableSelectionOnClick
          sx={getTableStyles(depth)}
          columns={columns}
          components={{
            NoRowsOverlay: () => <NoRowsOverlay loading={fetchFoldersLoading} />,
            Row: (params) => {
              const { row } = params;
              return (
                <DroppableFolderRow row={row}>
                  {(provided, snapshot) => (
                    <DrawingFolderRowProvider
                      rowData={params}
                      updateRows={apiRef.current.updateRows}
                    >
                      <DrawingFolderRow
                        provided={provided}
                        snapshot={snapshot}
                        params={params}
                        onCloseNewFolderForm={() => {
                          const { row } = params;
                          closeNewFolderFormRow(row?.id, apiRef.current.updateRows);
                        }}
                      />
                    </DrawingFolderRowProvider>
                  )}
                </DroppableFolderRow>
              );
            },
          }}
          onCellClick={onCellClick}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={() => 'auto'}
        />
      </ItemsDataGridProProvider>
    </>
  );
};

export default DrawingFolderList;

const NoRowsOverlay = ({ loading }) => (
  <Stack width="100%" height="32px" display="flex" flexDirection="row" alignItems="center">
    {loading ? <CircularProgress size={16} /> : ''}
  </Stack>
);
