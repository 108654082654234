import { GRID_CHECKBOX_SELECTION_COL_DEF, GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro';

import WorkRequestStatus from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestStatus';
import { getDaysRemainingWorkCell } from 'modules/Shop/WorkCells/WorkCellsList/WorkCellsTableComponents';

import WorkOrderNameCell from './WorkOrderNameCell/WorkOrderNameCell';

const WorkCellTasksTableColumns = (handleCellComponent) => [
  { ...GRID_CHECKBOX_SELECTION_COL_DEF, width: 30 },
  {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    renderCell: handleCellComponent,
    width: 30,
  },
  {
    field: 'workOrderName',
    headerName: 'Work Order Id',
    flex: 1,
    renderCell: WorkOrderNameCell,
    sortable: false,
    resizable: true,
  },
  {
    field: 'projectName',
    headerName: 'Project Name',
    flex: 1,
    renderCell: handleCellComponent,
    sortable: false,
    resizable: true,
  },
  {
    field: 'projectIdentifier',
    headerName: 'Project Id',
    flex: 1,
    renderCell: handleCellComponent,
    sortable: false,
    resizable: true,
  },
  {
    field: 'workRequestItemName',
    headerName: 'Item',
    flex: 1,
    renderCell: handleCellComponent,
    sortable: false,
    resizable: true,
  },
  {
    field: 'taskName',
    headerName: 'Shop Task Type',
    flex: 1,
    renderCell: handleCellComponent,
    sortable: false,
    resizable: true,
  },
  {
    field: 'taskStatusTypeName',
    headerName: 'Status',
    flex: 1,
    renderCell: ({ row, value }) => (
      <WorkRequestStatus status={value} pastDaysRemaining={getDaysRemainingWorkCell(row) < 0} />
    ),
    sortable: false,
    resizable: true,
  },
  {
    field: 'delete',
    headerName: '',
    renderCell: handleCellComponent,
    width: 40,
    resizable: false,
    sortable: false,
  },
];

export default WorkCellTasksTableColumns;
