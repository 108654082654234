import React, { useState } from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { ItemMenu } from 'components';

const AttributeItem = ({ name, onClick, isSelected, handleAddNewVariant = () => {}, ...rest }) => {
  const [isHover, setIsHover] = useState(false);

  const onMouseLeave = () => setIsHover(false);
  const onMouseEnter = () => setIsHover(true);

  const options = [
    {
      label: 'New attribute variant',
      onClick: handleAddNewVariant,
    },
  ];

  return (
    <AttributeItemContainer
      {...rest}
      onClick={onClick}
      isSelected={isSelected}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
    >
      <MoveIcon />
      <Typography variant="body2" sx={{ width: '100%' }}>
        {name}
      </Typography>
      {isHover && <ItemMenu sx={{ mr: 1.5 }} options={options} />}{' '}
    </AttributeItemContainer>
  );
};

export default AttributeItem;

const AttributeItemContainer = ({
  onClick,
  children,
  isSelected,
  onMouseEnter,
  onMouseLeave,
  ...rest
}) => (
  <Box
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    onClick={onClick}
    sx={{
      height: '60px',
      borderBottom: '1px solid',
      borderColor: 'other.border',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      cursor: 'pointer',
      backgroundColor: isSelected ? 'action.lightSurface.selected' : 'white',
    }}
    {...rest}
  >
    {children}
  </Box>
);

const MoveIcon = ({ mr }) => (
  <IconButton size="small" sx={{ cursor: 'move', marginRight: mr }}>
    {/* <MUI.DragIndicator fontSize="small" /> */}
  </IconButton>
);
