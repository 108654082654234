import React from 'react';

import Box from '@mui/material/Box';

import AddAdminModal from './AddAdminModal/AddAdminModal';
import { AdminProvider } from './AdminContext';
import AdminHeaderBar from './AdminHeaderBar';
import AdminTable from './AdminTable/AdminTable';

const AdminTab = ({ canAddAdmin, title }) => (
  <AdminProvider>
    <AdminWrapper>
      <AdminHeaderBar title={title} canAddAdmin={canAddAdmin} />
      <AdminTable />
      <AddAdminModal />
    </AdminWrapper>
  </AdminProvider>
);

export default AdminTab;

const AdminWrapper = ({ children }) => (
  <Box mb="0" height={1}>
    {children}
  </Box>
);
