import React, { useCallback, useEffect, useState } from 'react';

import { InterfaceSelector } from '@evolvemep/foresite-components-ui-react';
import { Box } from '@mui/material';

const InterfaceSelectorWorkRequest = ({ options = [], offset = { x: 0, y: 0 } }) => {
  const [position, setPosition] = useState(null);

  const getPosition = useCallback(() => {
    setTimeout(() => {
      const rect = document.getElementById('interface-selector-ref');
      setPosition({
        x: rect?.offsetLeft + offset?.x ?? 0,
        y: rect?.offsetTop + offset?.y ?? 0,
      });
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getPosition();
  }, [getPosition]);

  useEffect(() => {
    function handleResize() {
      setTimeout(() => {
        const rect = document.getElementById('interface-selector-ref');
        setPosition({
          x: rect?.offsetLeft + offset?.x ?? 0,
          y: rect?.offsetTop + offset?.y ?? 0,
        });
      }, 500);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return position ? (
    <InterfaceSelector startOffset={position} isDragDisabled items={options} openInHover />
  ) : null;
};

export const InterfaceSelectorWorkRequestRef = ({ sx = {}, id = 'interface-selector-ref' }) => (
  <Box
    id={id}
    sx={{
      position: 'absolute',
      right: 0,
      top: 0,
      width: '34px',
      height: '4px',
      ...sx,
    }}
  />
);

export default InterfaceSelectorWorkRequest;
