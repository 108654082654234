import { v4 as uuidv4 } from 'uuid';

const { ROW_TYPES } = require('../../constants/constants');

export const mapScopePackages = (scopePackages) =>
  scopePackages.map((scope) => ({
    type: ROW_TYPES.SCOPE_PACKAGE,
    label: scope.scopePackageName,
    prefix: scope.scopePackageIdentifier,
    id: scope.scopePackageId,
  }));

export const mapProject = (project) => ({
  type: project.locationTypeName,
  label: project.locationName,
  id: project.locationId,
  prefix: project.locationIdentifier,
});

export const createRow = (parentType, parentId) => ({
  id: uuidv4(),
  type: parentType === ROW_TYPES.PROJECT ? ROW_TYPES.SCOPE_PACKAGE : ROW_TYPES.WORK_PHASE,
  scopePackageId: parentId,
  isEditing: true,
  isNew: true,
});

export const appendWorkPhasesToScopePackages = (scopePackages, workPhases) =>
  scopePackages?.map((scope) => ({
    ...scope,
    children: workPhases.filter((workPhase) => workPhase.scopePackageId === scope.id),
  })) || [];

export const mapWorkPhases = (workPhases) =>
  workPhases?.map((wp) => ({
    ...wp,
    id: wp.workPhaseId,
    label: wp.workPhaseName,
    prefix: wp.workPhaseIdentifier,
    type: ROW_TYPES.WORK_PHASE,
  })) || [];
