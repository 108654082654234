import * as React from 'react';

import { UseControllerProps } from 'react-hook-form';

import { TakeOffPadName } from '.';

type AddNewTakeOffPadNameFormProps = {
  control: UseControllerProps['control'];
  onSubmit: React.FormEventHandler<HTMLFormElement>;
};

const AddNewTakeOffPadNameForm = ({ control, onSubmit }: AddNewTakeOffPadNameFormProps) => (
  <form onSubmit={onSubmit} autoComplete="off">
    <TakeOffPadName control={control} />
  </form>
);

export default AddNewTakeOffPadNameForm;
