import React, { useRef, useEffect } from 'react';

import { CircularProgress, Palette, Stack, useTheme, ClickAwayListener } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useForm } from 'react-hook-form';

import { ControlledDatePicker } from 'components';
import { formatLocalTimeToUTC, isPastDate, TODAY } from 'helpers/dateFunctions';
import useKeyPressEffect from 'hooks/useKeyPressEffect';

import { useWorkRequestsContext } from '../../../WorkRequestsContext';
import { useUpdateWorkRequest } from '../../../WorRequestsProperties/WorkRequestPropertiesAttachments/hooks/useUpdateWorkRequest';

type props = {
  setHovered: React.Dispatch<React.SetStateAction<boolean>>;
  setDate: React.Dispatch<any>;
};

const areValuesDifferent = (dateRef: any, WRNeedBy: any) => {
  if (WRNeedBy === '' && dateRef === null) return true;
  return false;
};

const DarkNeedByDate = ({ setHovered, setDate }: props) => {
  dayjs.extend(utc);
  const { palette } = useTheme();
  const date = useRef<Dayjs | null>();
  const { workRequestSelected, setWorkRequest, updateWRList, setUpdateWRList, workOrderSelected } =
    useWorkRequestsContext();
  const STATUSES_WITH_REQUIRED_NEED_BY = ['Submitted', 'Approved', 'Pending'];
  const isRequired =
    Boolean(workOrderSelected) || STATUSES_WITH_REQUIRED_NEED_BY.includes(workRequestSelected.workRequestStatusName);
  const {
    control,
    setValue,
    reset,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty, isValid },
  } = useForm({
    mode: 'all',
    defaultValues: { needBy: dayjs(`${workRequestSelected.needBy}Z`).local() },
  });
  const { updateWorkRequest, loading } = useUpdateWorkRequest();
  const closeNeedByDate = isValid && !isDirty;

  useEffect(() => {
    reset({ needBy: dayjs(`${workRequestSelected.needBy}Z`).local() });
  }, [workRequestSelected.needBy, setValue, reset]);

  useEffect(() => {
    date.current = dayjs(`${workRequestSelected.needBy}Z`).local();
    // eslint-disable-next-line
  }, []);

  const validate = (newDate: Dayjs) => {
    if (isRequired && newDate === null) return '*Required';
    if (typeof newDate.isValid === 'function' && !newDate.isValid()) return 'Invalid';
    if (isPastDate(newDate) && newDate !== null) return 'Invalid';

    date.current = newDate;
    return true;
  };

  const arePrevAndNewValuesFalsy = areValuesDifferent(date.current, workRequestSelected.needBy);
  const submit = async () => {
    const shouldUpdateWorkRequest =
      !arePrevAndNewValuesFalsy && date.current !== workRequestSelected.needBy && !errors?.needBy;

    if (!shouldUpdateWorkRequest) {
      reset();
      setHovered(false);
      return;
    }

    const body = { needBy: formatLocalTimeToUTC(date?.current) };
    const { data } = await updateWorkRequest(workRequestSelected.workRequestId, body);
    if (workOrderSelected) setUpdateWRList(!updateWRList);

    if (data) {
      setWorkRequest(data?.updateWorkRequest);
      setHovered(false);
    }

    if (!date.current) setDate(null);
  };

  const submitCheck = () => {
    if (isSubmitting || closeNeedByDate) {
      setHovered(false);
      return;
    }
    handleSubmit(submit)();
  };

  useKeyPressEffect('Enter', submitCheck);

  return (
    <ClickAwayListener onClickAway={submitCheck}>
      <Stack height={errors?.needBy ? undefined : '22px'} direction="row" sx={{ gap: 1 }}>
        {isSubmitting && <CircularProgress sx={{ color: 'secondary.mainDarkBackground' }} size="22px" />}
        {!isSubmitting && (
          <ControlledDatePicker
            onAccept={submitCheck}
            name="needBy"
            autoComplete="off"
            control={control}
            hideErrorMessage={false}
            minDate={TODAY}
            label={undefined}
            size="extra-small"
            fullWidth
            darkTheme
            color="secondary"
            rules={{ validate }}
            PaperProps={{ sx: getDarkNeedByDateStyles(palette) }}
            triggerChangeOnlyOnBlur
            disabled={loading}
            id={undefined}
            maxDate={undefined}
            helperText={undefined}
            pickerIcon={undefined}
          />
        )}
      </Stack>
    </ClickAwayListener>
  );
};

export default DarkNeedByDate;

export const getDarkNeedByDateStyles = (palette: Palette) => ({
  backgroundColor: '#303030',
  '& .MuiPickersCalendarHeader-label': {
    color: palette.secondary.contrastText,
  },
  '& .MuiPickersDay-today': {
    borderColor: `${palette.secondary.mainDarkBackground} !important`,
  },
  '& .Mui-selected': {
    backgroundColor: `${palette.secondary.mainDarkBackground} !important`,
  },
  '& .MuiTypography-caption': { color: '#acacac' },
  '& .MuiButtonBase-root': {
    backgroundColor: '#303030',
    color: palette.secondary.contrastText,
    '&.Mui-disabled': {
      color: '#636363',
    },
  },
  '& .PrivatePickersYear-yearButton': {
    color: 'white !important',
  },
});
