/* eslint-disable max-len */
import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Custom } from 'components/Icons';
import {
  lowercaseRegEx,
  numberRegEx,
  specialCharacterRegEx,
  uppercaseRegEx,
} from 'constants/regEx';

const regExCheck = (pattern, password, focused) => {
  if (focused && !pattern.test(password)) {
    return undefined;
  }
  if (!focused && !pattern.test(password)) {
    return false;
  }
  if (pattern.test(password)) {
    return true;
  }
  return undefined;
};

const lengthCheck = (password, focused) => {
  if (focused && password.length < 8) {
    return undefined;
  }
  if (!focused && password.length < 8) {
    return false;
  }
  if (password.length > 7) {
    return true;
  }
  return undefined;
};

const PasswordCheck = ({ password, focused }) => (
  <PasswordCheckWrapper>
    <Typography color="textSecondary" variant="overline" component="p">
      PASSWORD MUST
    </Typography>
    <Box>
      <MarkRequirementText hasPassed={lengthCheck(password, focused)}>
        Be a minimum of 8 characters
      </MarkRequirementText>
      <MarkRequirementText hasPassed={regExCheck(specialCharacterRegEx, password, focused)}>
        Include at least 1 special character
      </MarkRequirementText>
      <MarkRequirementText hasPassed={regExCheck(numberRegEx, password, focused)}>
        Include at least 1 number
      </MarkRequirementText>
      <MarkRequirementText hasPassed={regExCheck(uppercaseRegEx, password, focused)}>
        Include at least 1 uppercase letter
      </MarkRequirementText>
      <MarkRequirementText hasPassed={regExCheck(lowercaseRegEx, password, focused)}>
        Include at least 1 lowercase letter
      </MarkRequirementText>
    </Box>
  </PasswordCheckWrapper>
);

export default PasswordCheck;

const PasswordCheckWrapper = ({ children }) => (
  <Box marginLeft="24px" marginTop="8px">
    {children}
  </Box>
);

const MarkRequirementText = ({ hasPassed, children }) => (
  <Stack direction="row" alignItems="center" my="3px">
    {hasPassed === undefined ? (
      <Custom.FiberManualRecord color="disabled" fontSize="small" />
    ) : (
      <Custom.CheckCircleIcon fontSize="small" color={hasPassed ? 'success' : 'error'} />
    )}

    <Typography variant="body1" ml="5px" color="text.secondary">
      {children}
    </Typography>
  </Stack>
);
