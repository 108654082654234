import { FlattenObjectKeys } from 'typings/flatten-object';

export const getValueByPropertyPath = <T, K extends FlattenObjectKeys<T>>(obj: T, path: K) => {
  const keys = (path as string).split('.');
  return keys.reduce((value, key) => {
    if (value && typeof value === 'object' && key in value) {
      return value[key];
    }
    return undefined;
  }, obj as any);
};
