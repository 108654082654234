import React from 'react';

import { Stack } from '@mui/material';

import { Custom } from 'components/Icons';
import { DOCUMENT_STATUS_TYPE } from 'constants/globalConstants';

import { useDrawingFolderContext } from '../../DrawingFolderContext';
import { DrawingDocumentType } from '../../hooks/constants';
import { DrawingDocumentMoreOptions } from '../DrawingDocumentRowMoreOptions';
import NewFolderButton from '../DrawingDocumentToolbar/NewFolderButton';
import DrawingFolderRowMoreOptions from '../DrawingFolderRowMoreOptions/DrawingFolderRowMoreOptions';
import StatusType from './StatusType';

const DrawingRenderCellEncondedUrn = ({ onRenameClick, row, onNewFolder = () => {} }) => {
  const { documentStatusTypeId } = row;
  const { setFolderToDelete } = useDrawingFolderContext();

  const convertingDocument = documentStatusTypeId === DOCUMENT_STATUS_TYPE.CONVERTING_DOCUMENT;
  const errorConvertingDocument =
    documentStatusTypeId === DOCUMENT_STATUS_TYPE.CONVERTING_DOCUMENT_FAILS;

  if (
    documentStatusTypeId === DOCUMENT_STATUS_TYPE.LOADING_DOCUMENT ||
    row?.isProcessing ||
    row?.isValidating
  )
    return <StatusType loading onRenameClick={onRenameClick} row={row} />;

  if (convertingDocument)
    return (
      <StatusType
        title="Processing..."
        placement="top"
        icon={<Custom.Alert />}
        onRenameClick={onRenameClick}
        row={row}
      />
    );

  if (errorConvertingDocument)
    return (
      <StatusType
        title="Failed to convert"
        placement="top"
        icon={<Custom.Error />}
        onRenameClick={onRenameClick}
        row={row}
      />
    );

  return (
    <Wrapper>
      {!row?.isEditing && row.type === DrawingDocumentType.FOLDER ? (
        <>
          <NewFolderButton
            title="New subfolder"
            onClick={onNewFolder}
            dataTestId={`create-subfolder-${row.id}`}
          />
          <DrawingFolderRowMoreOptions
            onDelete={() => setFolderToDelete(row)}
            onRename={onRenameClick}
            dataTestId={`more-menu-folder-${row.id}`}
          />
        </>
      ) : null}
      {!row?.isEditing && row.type === DrawingDocumentType.DOCUMENT ? (
        <DrawingDocumentMoreOptions onRenameClick={onRenameClick} document={row} />
      ) : null}
    </Wrapper>
  );
};

export default React.memo(DrawingRenderCellEncondedUrn);

const Wrapper = ({ children }) => (
  <Stack
    flexDirection="row"
    justifyContent="flex-end"
    sx={{
      position: 'absolute',
      right: 0,
      left: 'auto',
      top: 0,
      '.MuiDataGrid-row & > span': {
        display: 'none',
      },
      '.MuiDataGrid-row:hover & > span': {
        display: 'block',
      },
    }}
  >
    {children}
  </Stack>
);
