import React, { useState } from 'react';

import { Stack, Typography, Button, useTheme } from '@mui/material';

import { Custom } from 'components/Icons';
import AttributeModal from 'modules/Materials/CatalogSetup/AttributeModal/AttributeModal';
import { useAttributeContext } from 'modules/Materials/CatalogSetup/AttributesSection/AttributesContext';
import { useCategoriesContext } from 'modules/Materials/CatalogSetup/Categories/CategoriesContext';

import AttributesContent from './AttributeContent/AttributesContent';

const Attributes = () => {
  const { selectedCategory } = useCategoriesContext();
  const { getAttributesPaginationHandler, attributes } = useAttributeContext();
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <Stack sx={{ gap: '2px' }}>
      <Typography variant="body1">Specifications</Typography>
      <Typography variant="body2" sx={{ fontWeight: 600 }}>
        Attributes
      </Typography>
      <Stack sx={{ gap: 2, marginTop: 2 }}>
        <AttributesContent
          selectedCategoryId={selectedCategory?.partCategoryId}
          attributes={attributes || []}
          paginationHandler={getAttributesPaginationHandler}
        />
      </Stack>
      <Stack direction="row">
        <AddAttributeButton onClick={openModal} />
      </Stack>
      <AttributeModal open={modalOpen} onClose={closeModal} />
    </Stack>
  );
};
export default Attributes;

const AddAttributeButton = ({ onClick }) => {
  const { palette } = useTheme();
  return (
    <Button onClick={onClick} color="secondary" startIcon={<Custom.Add fill={palette.secondary.main} />}>
      <Typography
        variant="button"
        sx={{
          letterSpacing: '0.46px',
        }}
      >
        ADD ATTRIBUTE
      </Typography>
    </Button>
  );
};
