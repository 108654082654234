import React from 'react';

import { Stack, Typography } from '@mui/material';

import { Custom } from 'components/Icons';
import { greyColors } from 'styles/theme/colors';

const IsDocumentsEmpty = () => (
  <Stack alignItems="center" justifyContent="center" sx={{ height: '70%' }}>
    <Custom.Plans fill={greyColors.grey400} style={{ width: 62, height: 62 }} />
    <TextTypography text="No documents are mapped to this task." />
  </Stack>
);

const TextTypography = ({ text }) => (
  <Typography variant="h6" sx={{ color: 'surface.lightSurface.secondary', marginBottom: '5px' }}>
    {text}
  </Typography>
);

export default IsDocumentsEmpty;
