import React from 'react';

import { gql, useLazyQuery } from '@apollo/client';
import { InputForesiteField } from '@evolvemep/foresite-components-ui-react';
import { useFormContext } from 'react-hook-form';

import { assemblyCustomCategories } from 'graphql/queries';

const CustomAssemblyTypeAddForm = () => {
  const { control, getValues, setError } = useFormContext();

  const [customAssemblyTypesQuery] = useLazyQuery(gql(assemblyCustomCategories));

  const checkExistence = async () => {
    const { AssemblyTypeName } = getValues();
    const {
      data: { assemblyCustomCategories },
    } = await customAssemblyTypesQuery({
      variables: {
        query: {
          name: `eq:${AssemblyTypeName}`,
          skip: 0,
          take: 1,
        },
      },
      fetchPolicy: 'no-cache',
    });

    if (assemblyCustomCategories.length) setError('AssemblyTypeName', { message: 'Name already exists' });
  };

  return (
    <InputForesiteField
      fullWidth
      size="small"
      color="secondary"
      autoComplete="off"
      control={control}
      onBlur={checkExistence}
      name="AssemblyTypeName"
      label="Assembly Type Name*"
      data-testid="AssemblyTypeName"
      data-cy="AssemblyTypeName"
      rules={{ required: '*Required' }}
      maxLength={64}
      inputProps={{ 'data-cy': 'newAssemblyTypeForm' }}
    />
  );
};

export default CustomAssemblyTypeAddForm;
