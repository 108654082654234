import React, { useState, useMemo, useEffect } from 'react';

import { gql, useMutation } from '@apollo/client';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputBase from '@mui/material/InputBase';
import Stack from '@mui/material/Stack';

import { useActiveMessagesContext } from 'app/MessageCenter/contexts';
import { useUser } from 'app/UserContext';
import { readAllMessage } from 'graphql/mutations';

import MessageCreateModal from './MessageCreateModal/MessageCreateModal';

const USER_TYPE_CONSTANT = 'de74a8fb-f778-4f25-99a5-4238de51538c';

const MessageListActiveToolbar = ({ onSearch = () => {} }) => {
  const { user } = useUser();

  const { messages, setActiveMessages } = useActiveMessagesContext();

  const [readAll, { loading }] = useMutation(gql(readAllMessage));

  const hasCreateMessagePermission = useMemo(
    () => user?.userTypes?.some(({ typeId }) => typeId === USER_TYPE_CONSTANT),
    [user],
  );

  const [isOpen, setIsOpen] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [searchValue, setSearchValue] = React.useState('');

  useEffect(() => {
    if (loading) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  }, [loading]);

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    onSearch(event.target.value);
  };

  const closeModal = () => setIsOpen(false);
  const openModal = () => setIsOpen(true);

  const handleClickMarkAll = async () => {
    await readAll();
    const markedMessages = messages.map((message) => {
      const newMessage = { ...message };
      if (!newMessage.accessedOn) {
        const newDate = new Date();
        newMessage.accessedOn = newDate.toISOString();
      }
      return newMessage;
    });
    setActiveMessages(markedMessages);
  };

  return (
    <MessageListToolbarWrapper sx={{ height: '124px' }}>
      <Stack direction="row" justifyContent="space-between" sx={{ marginBottom: '16px' }}>
        <MarkAllAsReadButton onClick={handleClickMarkAll} disabled={isDisable} />
        {hasCreateMessagePermission && <CreateMessageButton onClick={openModal} />}
      </Stack>
      <SearchBar value={searchValue} onChange={handleSearch} />
      <MessageCreateModal open={isOpen} onClose={closeModal} />
    </MessageListToolbarWrapper>
  );
};

export default MessageListActiveToolbar;

const MessageListToolbarWrapper = ({ children, sx }) => (
  <Box
    sx={{
      display: 'block',
      padding: '25px 17px',
      ...sx,
    }}
  >
    {children}
  </Box>
);

const MarkAllAsReadButton = ({ onClick, disabled }) => (
  <Button
    color="primary"
    variant="outlined"
    size="medium"
    onClick={onClick}
    disabled={disabled}
    sx={{
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.46px',
      width: 'auto',
      height: '32px',
    }}
  >
    Mark All as Read
  </Button>
);

const CreateMessageButton = ({ onClick }) => (
  <Button
    color="primary"
    variant="contained"
    onClick={onClick}
    fullWidth
    sx={{
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.46px',
      width: 'auto',
      height: '32px',
    }}
  >
    ╋ Create Message
  </Button>
);

const SearchBar = ({ value, onChange }) => (
  <Box>
    <InputBase
      onChange={onChange}
      value={value}
      placeholder="Search…"
      inputProps={{ 'aria-label': 'search' }}
      sx={{
        width: '100%',
        height: '32px',
        padding: '6px 16px',
        backgroundColor: 'other.selected',
        borderRadius: '5px',
        fontSize: '14px',
        lineHeight: '20px',
      }}
    />
  </Box>
);
