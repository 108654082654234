import React from 'react';

import { customMaxLengthRule, requiredRule } from 'constants/inputFieldRules';

import TextField from './TextField';

const IdentifierTextField = ({ rules, limitCharacters, ...props }) => (
  <TextField
    name="prefix"
    placeholder="Id"
    rules={{
      ...requiredRule('Identifier'),
      ...customMaxLengthRule(limitCharacters, `Id limited to ${limitCharacters} characters`),
      ...rules,
    }}
    sx={{
      '.MuiFormHelperText-root': {
        marginRight: 0,
        lineHeight: '16px',
      },
    }}
    {...props}
  />
);

export default IdentifierTextField;
