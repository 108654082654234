import useTaskDocuments from 'hooks/useTaskDocuments';

import useDocumentTypes from './useDocumentTypes';

export default function useDrawingDocuments(taskId: string) {
  const { drawingModelCNCIds } = useDocumentTypes();
  const {
    tasksDocuments: drawingDocuments,
    loading: loadingDrawingDocuments,
    paginationWaypoint,
  } = useTaskDocuments([taskId], drawingModelCNCIds, false);

  return {
    drawingDocuments,
    loadingDrawingDocuments,
    drawingPaginationWaypoint: paginationWaypoint,
  };
}
