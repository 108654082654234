import {
  Dispatch,
  ReactElement,
  SetStateAction,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';

import { Alert, Stack, SxProps, Typography } from '@mui/material';

import { Alert as AlertIcon } from 'assets/images';
import DeleteModal from 'components/DeleteModal';
import { useCustomAssemblyTypes } from 'modules/Shop/CustomAssemblies/hooks/useCustomAssemblyTypes';
import { BillOfMaterialItem } from 'typings/bom-items';

import { LINE_ITEM_TYPE_ASSEMBLY_ID } from '../Constants/constants';
import { ModalNotAllowDeletePadItem } from './ModalNotAllowDeletePadItem';

const deleteAssemblyText = ' Deleting assemblies/sub-assemblies will also delete all child items under them.';

export const ModalPadItemsDelete = forwardRef<
  { openModalConfirmDelete: () => void },
  { selectedPadItems: BillOfMaterialItem[] }
>(({ selectedPadItems }, ref) => {
  const { deleteBulkItemCustomAssembly, isDeletingBulkCustomAssembly } = useCustomAssemblyTypes();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openNotAllowDeleteModal, setOpenNotAllowDeleteModal] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      openModalConfirmDelete() {
        setOpenConfirmModal(true);
      },
    }),
    [],
  );

  const { padItemAllow, padItemNotAllow } = useMemo(
    () =>
      selectedPadItems.reduce(
        (padItemReduce, item) => {
          const { padItemAllow, padItemNotAllow } = padItemReduce;

          if (item?.childLineItemContainerName !== 'Unassigned')
            return {
              padItemAllow,
              padItemNotAllow: [...padItemNotAllow, item],
            };

          return {
            padItemAllow: [...padItemAllow, item],
            padItemNotAllow,
          };
        },
        {
          padItemAllow: [],
          padItemNotAllow: [],
        } as { padItemAllow: BillOfMaterialItem[]; padItemNotAllow: BillOfMaterialItem[] },
      ),
    [selectedPadItems],
  );

  const getContentDeleteModal = useCallback(() => {
    const hasAssembly = padItemAllow.some(({ lineItemTypeId }) => lineItemTypeId === LINE_ITEM_TYPE_ASSEMBLY_ID);

    const BaseTextComponent = (isAssembly = false) => (
      <Stack sx={{ gap: 2 }}>
        {padItemNotAllow.length > 0 && (
          <AlertNotAllowedItems
            setOpenNotAllowDeleteModal={setOpenNotAllowDeleteModal}
            padItemNotAllow={padItemNotAllow}
          />
        )}
        {padItemAllow.length > 0 && (
          <Typography variant="body1" mb="12px" sx={{ lineHeight: '24px' }}>
            Are you sure you want to delete{' '}
            <Typography component="span" variant="body1" sx={{ fontWeight: 700, wordBreak: 'break-word' }}>
              {getItemText(padItemAllow, 'selected item', true)}
            </Typography>
            ?{isAssembly && deleteAssemblyText}
          </Typography>
        )}
      </Stack>
    );
    if (hasAssembly) return BaseTextComponent(true);

    return BaseTextComponent();
  }, [padItemAllow, padItemNotAllow]);

  const handleDeleteBulk = () => () => {
    const itemsIds = padItemAllow.map((padItem) => padItem?.lineItemId);
    deleteBulkItemCustomAssembly(itemsIds, () => setOpenConfirmModal(false));
  };

  return (
    <>
      <DeleteModal
        open={openConfirmModal}
        onClose={() => setOpenConfirmModal(false)}
        onSubmit={handleDeleteBulk()}
        loading={isDeletingBulkCustomAssembly}
        disabledSubmit={padItemAllow.length <= 0}
        paperSx={{ maxWidth: 505 }}
      >
        {getContentDeleteModal()}
      </DeleteModal>
      <ModalNotAllowDeletePadItem
        padItemNotAllow={padItemNotAllow}
        isOpen={openNotAllowDeleteModal}
        onClose={() => setOpenNotAllowDeleteModal(false)}
      />
    </>
  );
});

const getItemText = (items: BillOfMaterialItem[], text: string, shouldBold?: boolean) => {
  const fontWeight = shouldBold ? 700 : 400;

  if (items.length > 1)
    return (
      <>
        <BoldItemText sx={{ fontWeight }}>{items.length.toString()}</BoldItemText>
        <Typography component="span" sx={{ fontWeight: 400 }}>{` ${text}s`}</Typography>
      </>
    );
  if (items.length === 1) return <BoldItemText sx={{ fontWeight }}>{items[0].lineItemName}</BoldItemText>;
  return '';
};

const BoldItemText = ({ children, sx }: { children: string | ReactElement; sx: SxProps }) => (
  <Typography component="span" sx={{ ...sx }}>
    {children}
  </Typography>
);

type AlertNotAllowedItemsProps = {
  setOpenNotAllowDeleteModal: Dispatch<SetStateAction<boolean>>;
  padItemNotAllow: BillOfMaterialItem[];
};

const AlertNotAllowedItems = ({ setOpenNotAllowDeleteModal, padItemNotAllow }: AlertNotAllowedItemsProps) => (
  <Alert
    sx={{
      fontSize: '16px',
      backgroundColor: 'transparent',
      color: 'common.black',
      '&.MuiPaper-root': { padding: 0 },
      '& .MuiAlert-icon': { margin: 'auto', marginRight: '10px' },
    }}
    severity="error"
    color="warning"
    iconMapping={{ error: <AlertIcon color="red" /> }}
  >
    <Typography
      component="span"
      onClick={() => setOpenNotAllowDeleteModal(true)}
      sx={{ color: 'warning.main', cursor: 'pointer', '& :hover': { textDecoration: 'underline' } }}
    >
      {getItemText(padItemNotAllow, 'item', true)}
    </Typography>{' '}
    cannot be deleted because they have been previously sent to a work request or requisition.
  </Alert>
);
