export const FIELDS = {
  lineItemName: 'lineItemName',
  tradeSize: 'tradeSize',
  unitQuantity: 'unitQuantity',
  uom: 'uom',
  manufacturer: 'manufacturer',
  catalogNumber: 'catalogNumber',
  quantity: 'quantity',
  remainingQuantity: 'remainingQuantity',
  padName: 'padName',
  decisions: 'decisions',
};

export const HEADERS_NAME = (isSourcePad = false) => ({
  [FIELDS.lineItemName]: 'Item',
  [FIELDS.tradeSize]: 'Trade Size',
  [FIELDS.unitQuantity]: 'Unit Qty',
  [FIELDS.uom]: 'UOM',
  [FIELDS.manufacturer]: 'Manufacturer',
  [FIELDS.catalogNumber]: 'Catalog Number',
  [FIELDS.quantity]: 'Qty',
  [FIELDS.remainingQuantity]: 'Qty Remaining',
  [FIELDS.padName]: isSourcePad ? 'PAD' : 'BOM',
  [FIELDS.decisions]: 'Decisions',
});

export const FIELDS_SORT = {
  [FIELDS.lineItemName]: 'name',
  [FIELDS.uom]: 'unitname',
  [FIELDS.manufacturer]: 'manufacturer',
  [FIELDS.quantity]: 'quantity',
  [FIELDS.tradeSize]: 'tradesize',
  [FIELDS.unitQuantity]: 'unitquantity',
  [FIELDS.catalogNumber]: 'catalognumber',
  [FIELDS.remainingQuantity]: 'remainingquantity',
};

export const FIELDS_COLUMNS_CSV = {
  ...FIELDS,
  [FIELDS.uom]: 'unitOfMeasureCode',
  [FIELDS.manufacturer]: 'manufacturerName',
  [FIELDS.catalogNumber]: 'manufacturerCatalogNumber',
  [FIELDS.padName]: 'childLineItemContainerName',
};
