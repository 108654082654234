import React, { useEffect, useMemo, useState } from 'react';

import { Box, Divider, Stack } from '@mui/material';
import { Waypoint } from 'react-waypoint';

import StackRow from 'components/StackRow';
import useCache from 'hooks/useCache';
import { checkType } from 'modules/Materials/commons/FilterPopoverContent/Breakdown/HelperElements';

import { useModal } from '../../contexts/ModalContext';
import { RowProvider, useRow } from '../../contexts/RowContext';
import AggregatedAmountText from './AggregatedAmountText';
import ArrowIndicator from './ArrowIndicator';
import Loader from './Loader';
import QtyInput from './QuantityInput';
import RowInfo from './RowInfo';
import WorkPhaseRow from './WorkPhaseRow';

const LocationRow = ({ depth = 0 }) => {
  const { show0Locations, calculationNodeIds, errorNodeIds, parentFilteredIds } = useModal();
  const { deleteFromCacheByQuery } = useCache();

  const {
    itemId,
    item,
    requiredQty,
    fetchLocations,
    locationLoading,
    parentIds,
    locations,
    nodeTreeItem,
    amountLoaded,
    disableInput,
  } = useRow();

  const [isOpen, setIsOpen] = useState(false);
  const type = checkType(item);

  const handleClick = () => setIsOpen(!isOpen);

  const showCollapseIcon = useMemo(() => {
    if (!locations) return false;

    if (parentFilteredIds?.length)
      return locations?.some((location) => parentFilteredIds?.includes(location.locationId));

    return true;
  }, [locations, parentFilteredIds]);

  const isNotFilteredOrParent = useMemo(
    () => parentFilteredIds?.length && !parentFilteredIds?.includes(item?.locationId),
    [parentFilteredIds, item],
  );

  const has0quantity = useMemo(() => {
    const isEditing = calculationNodeIds?.includes(itemId);
    return !show0Locations && requiredQty === 0 && nodeTreeItem?.aggregateVisual === 0 && !isEditing;
  }, [show0Locations, requiredQty, nodeTreeItem, calculationNodeIds, itemId]);

  useEffect(() => {
    if (parentFilteredIds?.length && itemId && amountLoaded) setIsOpen(parentFilteredIds?.includes(itemId));
  }, [itemId, parentFilteredIds, amountLoaded]);

  useEffect(() => {
    if (isOpen) {
      deleteFromCacheByQuery('locations', { parentLocationId: itemId });
      fetchLocations();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  if (has0quantity || isNotFilteredOrParent) return <></>;

  const displayLocationsLoading = locationLoading && !locations.length;

  return (
    <>
      <StackRow sx={{ justifyContent: 'space-between', minHeight: '32px', gap: '8px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
          <Box sx={{ width: `${depth * 24 + 12}px` }} />
          <ArrowIndicator
            item={item}
            isOpen={isOpen}
            handleClick={handleClick}
            invisible={!showCollapseIcon}
            disabled={disableInput}
          />
          <RowInfo type={type} item={item} isOpen={isOpen} />
        </Box>
        <StackRow gap="16px">
          <AggregatedAmountText isEditing={nodeTreeItem?.isEdited} isError={errorNodeIds?.includes(itemId)}>
            {nodeTreeItem?.aggregateVisual}
          </AggregatedAmountText>
          <QtyInput />
        </StackRow>
      </StackRow>
      <Divider sx={{ width: '100%' }} />
      {isOpen && (
        <>
          {item.locationPhases?.length > 0 &&
            item.locationPhases.map((workPhase) => (
              <RowProvider
                key={workPhase?.locationPhaseId}
                type="WorkPhase"
                item={workPhase}
                parentIds={[...parentIds, item?.locationId]}
                parentId={item?.locationId}
              >
                <WorkPhaseRow depth={depth + 2} showAggregatedAmount={false} />
              </RowProvider>
            ))}
          {displayLocationsLoading ? <Loader /> : <LocationRows depth={depth + 1} />}
        </>
      )}
    </>
  );
};

export default LocationRow;

const LocationRows = ({ depth }) => {
  const { locations, handleScroll, parentIds, item } = useRow();

  return (
    <>
      {locations?.map((location, index) => (
        <Stack key={`${depth}-${location?.locationId}`}>
          <RowProvider item={location} parentIds={[...parentIds, item?.locationId]} parentId={item?.locationId}>
            <LocationRow depth={depth} />
          </RowProvider>
          {index === locations.length - 1 && <Waypoint bottomOffset="-10%" onEnter={handleScroll} />}
        </Stack>
      ))}
    </>
  );
};
