import React, { useState, useMemo } from 'react';

import WorkRequestRejectModal from 'modules/Field/WorkRequests/Modals/WorkRequestRejectModal';
import WorkRequestTransferModal from 'modules/Field/WorkRequests/Modals/WorkRequestTransferModal';

import StatusButton from './StatusButton';

const FacilityButtons = ({ workRequest, updateWorkRequestStatus, updateWorkRequest }) => {
  const { workRequestStatusName } = workRequest;
  const [workRequestModalRejectOpen, setWorkRequestModalRejectOpen] = useState(false);

  const openWorkRequestModalReject = () => setWorkRequestModalRejectOpen(true);
  const closeWorkRequestModalReject = () => setWorkRequestModalRejectOpen(false);

  const [workRequestModalTransferOpen, setWorkRequestModalTransferOpen] = useState(false);

  const openWorkRequestModalTransfer = () => setWorkRequestModalTransferOpen(true);
  const closeWorkRequestModalTransfer = () => setWorkRequestModalTransferOpen(false);

  const disabledButtons = useMemo(
    () => ['Approved', 'Rejected', 'Canceled', 'Completed', 'In Progress'].includes(workRequestStatusName),
    [workRequestStatusName],
  );
  return (
    <>
      <StatusButton
        buttonText="Transfer"
        variant="text"
        onClick={() => openWorkRequestModalTransfer()}
        disabled={disabledButtons}
        data-cy="transfer-button"
      />
      <StatusButton
        buttonText="Reject"
        variant="outlined"
        onClick={() => openWorkRequestModalReject()}
        disabled={disabledButtons}
        data-cy="reject-button"
      />
      <StatusButton
        buttonText="Approve"
        onClick={() => updateWorkRequestStatus(workRequest, 'Approved')}
        variant="contained"
        disabled={disabledButtons}
        data-cy="approve-button"
      />
      <WorkRequestRejectModal
        openStatus={workRequestModalRejectOpen}
        onClose={closeWorkRequestModalReject}
        workRequest={workRequest}
        updateWorkRequestStatus={updateWorkRequestStatus}
      />
      <WorkRequestTransferModal
        openStatus={workRequestModalTransferOpen}
        onClose={closeWorkRequestModalTransfer}
        updateWorkRequest={updateWorkRequest}
        updateWorkRequestStatus={updateWorkRequestStatus}
        workRequest={workRequest}
      />
    </>
  );
};

export default FacilityButtons;
