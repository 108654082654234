import { useCallback } from 'react';

import type { Entitlement, EntitlementGetListParams } from 'types/types-api';

import { useWrappedGet, EvolveApiReturn } from './useWrappedApiCall';

export const useEntitlementGet = (params?: EntitlementGetListParams, lazy?: boolean) => {
  const { apiCall, ...rest } = useWrappedGet<EvolveApiReturn<Entitlement>>('admin/entitlement/multisearch', {
    defaultConfig: { params },
    lazy,
  });
  const entitlementGetList = useCallback(
    async (params2: EntitlementGetListParams) => apiCall({ params: params2 }),
    [apiCall],
  );
  return { entitlementGetList, ...rest };
};
