import useWorkRequestStatusMutation, {
  WorkRequestByIdQuery,
} from 'hooks-api/useWorkRequestStatusMutation';

const useWorkRequestHelpers = () => {
  const { updateWorkRequestStatusMutation } = useWorkRequestStatusMutation();

  const WRIsCanceledOrRejected = (workRequest) => {
    if (!workRequest) return false;

    const updateStatusNames = ['Canceled', 'Rejected'];
    return updateStatusNames.some((name) => name === workRequest?.workRequestStatusName);
  };

  const WOIsReleased = (workRequest) => {
    if (!workRequest) return false;

    const releasedStatusNames = ['Blocked', 'In Progress', 'Completed', 'Not Started'];
    return releasedStatusNames.some(
      (name) =>
        name === (workRequest?.workRequestStatusName || workRequest?.workOrderStatusTypeName),
    );
  };

  const moveWorkRequestToDraft = (workRequestId) => {
    if (!workRequestId) return;

    updateWorkRequestStatusMutation({
      variables: {
        params: {
          workRequestId,
          workRequestStatusName: 'Draft',
        },
        body: { workRequestStatusDescription: '' },
      },
      refetchQueries: [WorkRequestByIdQuery(workRequestId)],
      awaitRefetchQueries: true,
    });
  };

  return { moveWorkRequestToDraft, WRIsCanceledOrRejected, WOIsReleased };
};

export default useWorkRequestHelpers;
