import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { useUser } from 'app/UserContext';
import { getLocalStorage, setLocalStorage } from 'hooks/useLocalStorage';
import useSetupModule from 'hooks/useSetupModule';

import { getModuleRoute } from '../FieldWorkRequestHelpers';
import ModalNeedByDate from './ModalNeedByDate';
import StatusButton from './StatusButton';
import WorkRequestCancelModal from './WorkRequestCancelModal';
import WorkRequestInfoModal from './WorkRequestInfoModal';
import { useWorkRequestItemsContext } from './WorkRequestItemsContext';

const ProjectButtons = ({ updateWorkRequestStatus, workRequest, loadingWRStatus }) => {
  const [needByDateModalOpen, setNeedByDateModalOpen] = useState(false);
  const [workRequestModalCancelOpen, setWorkRequestModalCancelOpen] = useState(false);
  const [workRequestRejectInfoModalOpen, setWorkRequestRejectInfoModalOpen] = useState(false);

  const { currentModule } = useSetupModule();
  const moduleName = currentModule();
  const moduleRoute = getModuleRoute(moduleName);

  const navigate = useNavigate();
  const { user } = useUser();
  const {
    workRequestStatusName: status,
    workRequestIdentifier: identifier,
    workRequestDescription: description,
    workRequestStatusDescription: statusInfo,
    lastStatusUpdate,
  } = workRequest;
  const buttonSplitCancel = 'Cancel';
  const disabledSubmit = !['Canceled', 'Draft', 'Rejected'].includes(status);
  const disabledCancel = ['Canceled', 'Draft', 'Completed'].includes(status);
  const { visibleInputs } = useWorkRequestItemsContext();

  const openWorkRequestModalCancel = () => setWorkRequestModalCancelOpen(true);
  const closeWorkRequestModalCancel = () => setWorkRequestModalCancelOpen(false);

  const closeWorkRequestInfoModal = () => {
    setLocalStorage(`${user.userId}+${identifier}+wr-rejected`, lastStatusUpdate || true);
    setWorkRequestRejectInfoModalOpen(false);
  };

  const updateStatusToSubmitted = () => {
    if (workRequest.needBy === null) return setNeedByDateModalOpen(true);
    return updateWorkRequestStatus(workRequest, 'Submitted', description, () =>
      navigate(`/${moduleRoute}/work-requests`),
    );
  };

  const updateStatusToCanceled = () => {
    updateWorkRequestStatus(workRequest, 'Canceled', description, () => navigate(`/${moduleRoute}/work-requests`));
    closeWorkRequestModalCancel();
  };

  useEffect(() => {
    const statusRejectedDate = getLocalStorage(`${user.userId}+${identifier}+wr-rejected`);

    if (lastStatusUpdate)
      setWorkRequestRejectInfoModalOpen(lastStatusUpdate !== statusRejectedDate && status === 'Rejected');
    else setWorkRequestRejectInfoModalOpen(!statusRejectedDate && status === 'Rejected');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <StatusButton
        variant="outlined"
        buttonText={buttonSplitCancel}
        onClick={() => openWorkRequestModalCancel()}
        disabled={disabledCancel}
        data-cy="cancel-button"
      />
      <StatusButton
        buttonText="Submit"
        variant="contained"
        disabled={disabledSubmit || visibleInputs}
        onClick={updateStatusToSubmitted}
        data-cy="submit-button"
      />
      <ModalNeedByDate
        openStatus={needByDateModalOpen}
        onClose={() => setNeedByDateModalOpen(false)}
        workRequest={workRequest}
        loadingWRStatus={loadingWRStatus}
        updateWorkRequestStatus={updateWorkRequestStatus}
      />
      <WorkRequestCancelModal
        openStatus={workRequestModalCancelOpen}
        onClose={closeWorkRequestModalCancel}
        updateStatusToCanceled={updateStatusToCanceled}
        identifier={identifier}
      />
      <WorkRequestInfoModal
        openStatus={workRequestRejectInfoModalOpen}
        onClose={closeWorkRequestInfoModal}
        identifier={identifier}
        statusInfo={statusInfo}
      />
    </>
  );
};

export default ProjectButtons;
