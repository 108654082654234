import React from 'react';

import ConfirmationModal from 'components/ConfirmationModal';

const ConfirmationSubmitWithDraftModal = (props) => (
  <ConfirmationModal
    modalTitle="Unsaved Work"
    confirmButtonText="YES"
    confirmButtonColor="secondary"
    text="You have unsaved work. Do you want to proceed without saving?"
    sx={{
      '.MuiDialog-paper': {
        maxWidth: '516px',
      },
    }}
    contentStyles={{
      pt: 3,
      pb: 5,
    }}
    {...props}
  />
);

export default ConfirmationSubmitWithDraftModal;
