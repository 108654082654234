import { useCallback, useMemo } from 'react';

import { WorkOrder } from 'typings/work-order';

const GROUP_KEY = '#';

export const useGroupByProject = (workOrders: WorkOrder[]) => {
  const projects = useMemo(
    () =>
      workOrders.reduce((acc, obj) => {
        const { projectId } = obj.workRequest;
        const { projectName } = obj.workRequest;
        const key = `${projectId}${GROUP_KEY}${projectName}`;

        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push({ ...obj });

        return acc;
      }, {} as { [key: string]: WorkOrder[] }),
    [workOrders],
  );

  const getProjectNameWithKey = useCallback(
    (projectKey: string) => projectKey.split(GROUP_KEY)?.[1],
    [],
  );
  const getProjectIdWithKey = useCallback(
    (projectKey: string) => projectKey.split(GROUP_KEY)?.[0],
    [],
  );

  return { projects, getProjectNameWithKey, getProjectIdWithKey };
};
