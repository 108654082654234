import React, { useEffect, useRef } from 'react';

import { Box, Checkbox } from '@mui/material';

import { usePopover } from '../../PopoverProvider';
import checkType from './checkType';
import checkTypeForFilter from './checkTypeForFilter';
import getElementId from './getElementId';
import { WIDTHS, MARGINS } from './iconStyleConstant';
import PickIcon from './PickIcon';
import useGetChipName from './useGetChipName';

const transformIntoChip = (method, item, menuValue) => {
  const type = checkType(item, menuValue);
  const filterType = checkTypeForFilter(item);
  const id = getElementId(item);
  const { locationId } = item;
  const { workPhaseId } = item;
  const chipName = filterType === 'WorkPhase' ? item.workPhaseChipName : method(item, true);
  const icon = <PickIcon type={type} chipWidth={WIDTHS[type]} margin={MARGINS[type]} />;
  const chip = { id, chipName, filterType, icon, locationId, workPhaseId };
  return chip;
};

const getNameForWorkPhaseChip = (item) => {
  const type = checkTypeForFilter(item);
  if (type === 'WorkPhase') return item.workPhaseName;
  if (type === 'ScopePackage') return item.scopePackageName;
  return '';
};

const ShowCheckBox = ({ item, isChecked, setIsChecked }) => {
  const { chipList, updateChipList, menuValue } = usePopover();
  const { getChipName } = useGetChipName();
  const type = checkType(item, menuValue);
  const forbiddenNames = ['Project'];
  const id = getElementId(item);
  const method = menuValue === 'Work Phases' ? getNameForWorkPhaseChip : getChipName;
  const isDisabled = useRef(false);

  if (type === 'Work Phase') {
    isDisabled.current = chipList.findIndex((e) => e.id === item.scopePackageId) !== -1;
  }

  const handleCheck = (e) => {
    const auxList = [...chipList];
    if (e.target.checked) {
      setIsChecked(true);
      auxList.push(transformIntoChip(method, item, menuValue));
      updateChipList([...auxList]);
    }
    if (!e.target.checked) {
      setIsChecked(false);
      const position = auxList.findIndex((e) => e.id === id);
      auxList.splice(position, 1);
      updateChipList([...auxList]);
    }
  };

  useEffect(() => {
    const position = chipList.findIndex((e) => e.id === id);
    if (position === -1) setIsChecked(false);
  }, [chipList, id, setIsChecked]);

  if (forbiddenNames.includes(type)) return <Box sx={{ width: '62px', height: '32px' }} />;
  return (
    <Checkbox
      size="small"
      onChange={handleCheck}
      checked={isChecked || item.isGroupSelected || isDisabled.current}
      disabled={item.isGroupSelected || isDisabled.current}
      color="secondary"
      sx={{ margin: '0  24px 0 0', height: '32px' }}
    />
  );
};

export default ShowCheckBox;
