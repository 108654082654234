import React, { ReactNode, useCallback, useMemo } from 'react';

import { gql, useMutation } from '@apollo/client';

import { updateWorkOrder as UPDATE_WORK_ORDER } from 'graphql/mutations';
import type { WorkOrderId } from 'graphql/types';
import useGeneralContext from 'helpers/useGeneralContext';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

type UpdateWorkOrderParams = {
  workOrderId: WorkOrderId;
  body: any;
  successMessage?: string;
  successCallback?: () => void;
};

type WorkOrderUpdateContextType = {
  loading: boolean;
  updateWorkOrder: (params: UpdateWorkOrderParams) => Promise<{ data: any; errors: any }>;
};
const WorkOrderUpdateContext = React.createContext<WorkOrderUpdateContextType | undefined>(undefined);

export const WorkOrderUpdateProvider = ({ children }: { children: ReactNode }) => {
  const { handleResponse } = useGraphqlResponseHandler();
  const [updateWorkOrderMutation, { loading }] = useMutation(gql(UPDATE_WORK_ORDER), {
    refetchQueries: ['WorkOrders', 'TaskMultiSearch'],
  });

  const updateWorkOrder = useCallback(
    async ({
      workOrderId,
      body,
      successMessage = `Work Order successfully updated`,
      successCallback = () => {},
    }: UpdateWorkOrderParams) =>
      handleResponse(
        updateWorkOrderMutation,
        { variables: { params: { workOrderId }, body } },
        { successMessage },
        successCallback,
      ),
    [handleResponse, updateWorkOrderMutation],
  );

  const value = useMemo(
    () => ({ loading, updateWorkOrderMutation, updateWorkOrder }),
    [loading, updateWorkOrder, updateWorkOrderMutation],
  );
  return <WorkOrderUpdateContext.Provider value={value}>{children}</WorkOrderUpdateContext.Provider>;
};

export const useWorkOrderUpdate = () => useGeneralContext(WorkOrderUpdateContext, 'WorkOrderUpdate');
