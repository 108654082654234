import React from 'react';

import TasksTable from './TasksTable';
import { TasksTableProvider } from './TasksTableContext';

const TasksTableWithContext = ({
  canEdit,
  canDelete,
  onSavePredecessors,
  tasks,
  onDeleteTask,
  getDrawingDocs,
  rowComponent,
  detailPanelHeight,
  isResponsiveWR,
  taskIdsRelateDuplicate,
  tasksPredecessorBlacklist,
  ...restProps
}) => (
  <TasksTableProvider canEdit={canEdit} onSavePredecessors={onSavePredecessors} tasks={tasks}>
    <TasksTable
      {...restProps}
      canEdit={canEdit}
      canDelete={canDelete}
      tasks={tasks}
      onDeleteTask={onDeleteTask}
      rowComponent={rowComponent}
      getDrawingDocs={getDrawingDocs}
      detailPanelHeight={detailPanelHeight}
      isResponsiveWR={isResponsiveWR}
      taskIdsRelateDuplicate={taskIdsRelateDuplicate}
      tasksPredecessorBlacklist={tasksPredecessorBlacklist}
    />
  </TasksTableProvider>
);

export { TasksTableWithContext as default };
