import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';

import { PartTypeNode } from 'components/EditorComponents/NodeTypes';
import { PropertiesOverlay } from 'components/EditorComponents/PropertiesOverlay';

import { useAssemblyEditorContext } from '../ReactFlowProviders/AssemblyEditorContext';

const CustomPartTypeNode = (props) => {
  const { data, id } = props;
  const { onDelete, onDuplicate, onEdit, idPropertyOpen, setIdPropertyOpen } =
    useAssemblyEditorContext();
  const [openOverlay, setOpenOverlay] = useState(false);

  useEffect(() => {
    if (idPropertyOpen === id) {
      setOpenOverlay(true);
      return;
    }

    setOpenOverlay(false);
  }, [id, idPropertyOpen]);

  const { control, handleSubmit } = useForm({
    mode: 'all',
    defaultValues: {
      quantity: data.quantity,
    },
  });

  const submit = (data) => {
    onEdit(id, data);
  };

  return (
    <>
      <PartTypeNode
        onDelete={onDelete}
        onDuplicate={onDuplicate}
        {...props}
        selected={openOverlay}
      />
      {openOverlay && (
        <PropertiesOverlay
          data={data}
          control={control}
          onSubmit={() => handleSubmit(submit)()}
          onClose={() => {
            setIdPropertyOpen(null);
            setOpenOverlay(false);
          }}
        />
      )}
    </>
  );
};

export default CustomPartTypeNode;
