import { useCallback, useEffect, useMemo, useState } from 'react';

import { gql, useLazyQuery } from '@apollo/client';

import { useUser } from 'app/UserContext';
import { folders as FOLDERS } from 'graphql/queries';
import useUrlParams from 'hooks/useUrlParams';

import { getParentFolders } from '../DrawingDocument/helpers/drawingFolderBreadcrumbHelper';
import { useDrawingRegisterContext } from '../DrawingRegisterContext';

const useDrawingRegisterBreadcrumbs = () => {
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const { params: searchObj } = useUrlParams();
  const { user } = useUser();
  const companyId = useMemo(() => user?.companyId, [user]);

  const { activeItem } = useDrawingRegisterContext();

  const [fetchFoldersAPI, { loading: fetchFoldersLoading }] = useLazyQuery(gql(FOLDERS), {
    fetchPolicy: 'no-cache',
  });

  const projectId = useMemo(
    () => (activeItem?.type === 'PROJECT' ? activeItem.id : null),
    [activeItem],
  );

  const onFetchRootBreadcrumbs = useCallback(
    async (folderId) => {
      if (!projectId) return;
      if (!folderId) {
        setBreadcrumbs([]);
        return;
      }
      // Get all folders for breadcrumbs
      const { data, error } = await fetchFoldersAPI({
        variables: {
          query: {
            companyId,
            projectIds: projectId,
            orderBy: 'StoragePath',
          },
        },
      });
      if (!error && data?.folders?.length) {
        const folderParents = data.folders.slice(1);
        setBreadcrumbs(getParentFolders(folderId, folderParents));
      }
    },
    [fetchFoldersAPI, companyId, projectId],
  );

  useEffect(() => {
    onFetchRootBreadcrumbs(searchObj?.folder);
  }, [onFetchRootBreadcrumbs, searchObj.folder]);

  return {
    breadcrumbs,
    loading: fetchFoldersLoading,
  };
};

export default useDrawingRegisterBreadcrumbs;
