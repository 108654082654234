import { containsOperator, equalToOperator, operators } from 'components/FilterPopUp/constants';

export const workRequestFilters = (projects, facilities, wrStatus) => [
  { label: 'all', value: 'searchPhrase', operators: containsOperator, type: 'textfield' },
  {
    label: 'Project',
    value: 'projectId',
    operators: equalToOperator,
    type: 'dropdown',
    options: projects,
  },
  {
    label: 'Facility',
    value: 'facilityId',
    operators: equalToOperator,
    type: 'dropdown',
    options: facilities,
  },
  { label: 'Project Identifier', value: 'projectIdentifier', operators, type: 'textfield' },
  { label: 'Project Name', value: 'projectName', operators, type: 'textfield' },
  {
    label: 'Work Request Status',
    value: 'workRequestStatusId',
    operators: equalToOperator,
    type: 'dropdown',
    options: wrStatus,
  },
  { label: 'Facility Name', value: 'facilityName', operators, type: 'textfield' },
  {
    label: 'Work Request Identifier',
    value: 'workRequestIdentifier',
    operators,
    type: 'textfield',
  },
  { label: 'Work Request Name', value: 'workRequestName', operators, type: 'textfield' },
];

export const getModuleRoute = (module) => {
  const modules = {
    SHOP: 'shop',
    FIELD: 'field',
    DESIGN: 'design',
    MATERIALS: 'materials',
  };
  return modules[module];
};
