import { getBaseApiUrl } from './useEvolveApi';

const useFetchIsTheLastVersion = () => {
  const getDocumentIsLastVersion = async (documentId: string) => {
    const baseUrl = await getBaseApiUrl();
    const response = await fetch(`${baseUrl}/docmgt/version/${documentId}/islatest`, {
      method: 'GET',
      mode: 'cors',
    });

    if (!response || response.status !== 200) return null;

    const data = await response.json();

    return data;
  };

  return { getDocumentIsLastVersion };
};

export default useFetchIsTheLastVersion;
