import React, { useEffect, useMemo } from 'react';

import { useReactiveVar } from '@apollo/client';
import { Button, Stack, Tooltip, Typography } from '@mui/material';

import { workOrdersSelectedToggleOptionVar } from 'apollo/reactiveVars';
import { Custom } from 'components/Icons';
import SearchBar from 'components/SearchBar/SearchBar';
import { useShopConfig } from 'modules/Shop/providers/ShopConfigProvider';
import { shopColors, actionColors } from 'styles/theme/colors';

import WorkOrdersFilter from './WorkOrdersFilter';

export const HeaderMenuComponent = ({
  filterDisabled = false,
  hiddenSearchBar = false,
  hiddenWorkOrdersFilter = false,
  hiddenColumnsButton = false,
}) => {
  const selectedTab = useReactiveVar(workOrdersSelectedToggleOptionVar);
  const isListView = selectedTab === 'list';
  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ mt: 0 }}>
      <Stack direction={isListView ? 'column' : 'row'}>
        {!hiddenSearchBar && <SearchBar placeholder="Search work orders…" />}
        <Stack direction="row" ml={5} gap={1} mt={isListView ? 2 : 0} sx={{ justifyContent: 'flex-end' }}>
          {!hiddenWorkOrdersFilter && <WorkOrdersFilter disabled={filterDisabled} />}
          {!hiddenColumnsButton && <ColumnsButton />}
          <ViewColumns />
        </Stack>
      </Stack>
    </Stack>
  );
};

const ColumnsButton = () => (
  <Stack direction="row" sx={{ alignItems: 'center', mx: 3.5 }}>
    <Custom.ViewColumn
      style={{
        fill: actionColors.disabled,
        margin: '0px 5px 0px 0px',
      }}
    />
    <Typography
      sx={{
        fontSize: '14px',
        color: actionColors.disabled,
        lineHeight: '24px',
        fontWeight: '600',
      }}
    >
      COLUMN
    </Typography>
  </Stack>
);

const ViewColumns = () => {
  const selectedTab = useReactiveVar(workOrdersSelectedToggleOptionVar);
  const { setCalendarSetupMemo } = useShopConfig();

  const isListView = useMemo(() => selectedTab === 'list', [selectedTab]);
  const isCalendarView = useMemo(() => selectedTab === 'calendar', [selectedTab]);
  const isKanbanView = useMemo(() => selectedTab === 'kanban', [selectedTab]);

  useEffect(() => {
    if (isCalendarView) return;
    setCalendarSetupMemo(null);
  }, [isCalendarView, setCalendarSetupMemo]);

  return (
    <Stack direction="row">
      <Tooltip title="List View" arrow>
        <Button
          sx={{
            minWidth: '48px',
            border: '1px solid #C4C4C4',
            borderRight: 'none',
            borderRadius: '4px 0 0 4px',
            bgcolor: isListView ? 'action.lightSurface.selected' : 'other.main',
          }}
          onClick={() => workOrdersSelectedToggleOptionVar('list')}
        >
          <Custom.TableChart
            style={{
              fill: isListView ? shopColors.dark : '#000',
              opacity: isListView ? 1 : 0.5,
            }}
          />
        </Button>
      </Tooltip>
      <Tooltip title="Kanban Board" arrow>
        <Button
          sx={{
            minWidth: '48px',
            borderRadius: 0,
            border: '1px solid #C4C4C4',
            bgcolor: isKanbanView ? 'action.lightSurface.selected' : 'other.main',
          }}
          onClick={() => workOrdersSelectedToggleOptionVar('kanban')}
          data-cy="kanban-board-button"
        >
          <Custom.DeveloperBoard
            style={{
              fill: isKanbanView ? shopColors.dark : '#000',
              opacity: isKanbanView ? 1 : 0.5,
            }}
          />
        </Button>
      </Tooltip>
      <Tooltip title="Calendar" arrow>
        <Button
          sx={{
            minWidth: '48px',
            border: '1px solid #C4C4C4',
            borderRadius: '0 4px 4px 0',
            borderLeft: 'none',
            bgcolor: isCalendarView ? 'action.lightSurface.selected' : 'other.main',
          }}
          onClick={() => workOrdersSelectedToggleOptionVar('calendar')}
          data-cy="calendar-board-button"
        >
          <Custom.CalendarBoard
            style={{
              fill: isCalendarView ? shopColors.dark : '#000',
              opacity: isCalendarView ? 1 : 0.5,
            }}
          />
        </Button>
      </Tooltip>
    </Stack>
  );
};
