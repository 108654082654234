import { PropsBase, withCalendarSlotBase } from '@evolvemep/foresite-components-ui-react';

import { WorkOrder } from 'typings/work-order';

import { useGroupByProject } from '../../Hooks/useGroupByProject';
import { useSlotSubscribeClick } from '../../Hooks/useSlotSubscribeClick';
import { SlotBaseWeekAndMonth } from '../SlotBaseWeekAndMonth';

export const SlotMonthWorkOrder = withCalendarSlotBase<
  WorkOrder,
  PropsBase & { projectsColors: Map<string, string> }
>(({ resourceList, day, projectsColors }) => {
  const { anchorEl, listRef, setAnchorEl } = useSlotSubscribeClick();
  const { projects, getProjectNameWithKey, getProjectIdWithKey } = useGroupByProject(resourceList);

  const handleOnClose = () => setAnchorEl(null);

  return (
    <SlotBaseWeekAndMonth
      ref={listRef}
      {...{
        projectsColors,
        resourceList,
        day,
        projects,
        getProjectNameWithKey,
        getProjectIdWithKey,
        anchorEl,
        onClose: handleOnClose,
      }}
    />
  );
});
