import React from 'react';

import { Button, Typography } from '@mui/material';

import { draftPartReactiveVar } from 'apollo/reactiveVars';
import Breadcrumb from 'components/Breadcrumb';
import Section from 'components/Section';

import { useCategoriesContext } from '../../Categories/CategoriesContext';
import { useCatalogPartContext } from '../../Providers/CatalogPartProvider';
import { useCatalogSelectedPartContext } from '../../Providers/CatalogSelectedPartProvider';
import { PART_ITEM_FORM_DEFAULT_VALUE } from '../NewItemsSection/newItemSectionHelpers';
import ItemsList from './ItemList';
import ItemsListToolbar from './ItemsListToolbar';
import useBreadcrumbBuilder from './useBreadcrumbBuilder';

const ItemsListSection = () => {
  const { selectedCategory } = useCategoriesContext();
  const {
    setShowNewItem,
    methods: { reset },
  } = useCatalogPartContext();
  const { setSelectedPart } = useCatalogSelectedPartContext();
  const { categoriesBreadcrumbs } = useBreadcrumbBuilder();

  const disableAddItemButton = selectedCategory == null || selectedCategory?.parentPartCategory == null;

  const onAddItem = () => {
    reset({ ...PART_ITEM_FORM_DEFAULT_VALUE });
    setShowNewItem(true);
    setSelectedPart(null);
    draftPartReactiveVar(null);
  };

  return (
    <Section>
      <Section.Title>
        <Title />
        <AddItemButton disabled={disableAddItemButton} onClick={onAddItem} />
      </Section.Title>
      <Section.Body sx={{ padding: '8px 16px' }}>
        <BodyBreadcrumb breadcrumbs={categoriesBreadcrumbs} />
        <ItemsListToolbar />
        <ItemsList />
      </Section.Body>
    </Section>
  );
};

export default ItemsListSection;

const Title = () => (
  <Typography variant="body1" sx={{ flex: 1 }}>
    Items
  </Typography>
);

const AddItemButton = ({ disabled, onClick }) => (
  <Button
    data-testid="add-item-to-category-btn"
    color="secondary"
    disabled={disabled}
    variant="contained"
    onClick={onClick}
  >
    ADD ITEM
  </Button>
);

const BodyBreadcrumb = ({ breadcrumbs }) => (
  <Breadcrumb
    breadcrumbs={breadcrumbs}
    maxItems={3}
    labelSx={{ fontSize: '16px', color: 'rgba(0, 0, 0, 0.5)' }}
    lastLabelSx={{ fontSize: '16px', color: 'rgba(0, 0, 0, 0.87)' }}
    sx={{ mt: 1, mb: 2 }}
  />
);
