import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

export const columnWorkRequestResponsive = (CellComponentLinkResponsive, CellComponentWRName, CellComponentStatus) => [
  {
    field: 'workRequestIdentifier',
    headerName: 'WR Id',
    renderCell: CellComponentLinkResponsive,
    minWidth: 160,
    sortable: true,
  },
  {
    field: 'workRequestName',
    headerName: 'Work Request Name',
    renderCell: CellComponentWRName,
    minWidth: 160,
    flex: 1,
    sortable: true,
  },
  {
    field: 'workRequestStatusName',
    headerName: 'Status',
    minWidth: 120,
    renderCell: CellComponentStatus,
  },
  {
    field: 'needBy',
    headerName: 'Need By',
    flex: 1,
    sortable: true,
    valueFormatter: (params) => {
      dayjs.extend(utc);
      if (!params.value) return null;
      return dayjs(`${params.value}Z`).local().format('MM/DD/YYYY');
    },
  },
];
