import { useContext } from 'react';

import { WorkOrderItemsContext } from '../WorkOrder/context/WorkOrderItemsContext';

export const useWorkOrder = () => {
  const workOrderContext = useContext(WorkOrderItemsContext);
  let workOrderData = null;
  let loading = false;

  if (workOrderContext) {
    const { workOrder, loading: workOrderLoading } = workOrderContext;
    workOrderData = workOrder;
    loading = workOrderLoading;
  }

  return {
    workOrder: workOrderData,
    loading,
  };
};
