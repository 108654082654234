import React from 'react';

import { Typography, Stack } from '@mui/material';

import { WorkRequestAttachments } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestListElements';

import { convertToLocalTime } from '../helpers';
import {
  CellComponentWOName,
  CellComponentDays,
  CellComponentLink,
  CellComponentFlag,
  CellComponentStatus,
  CellComponentTrash,
} from './Components/WorkOrderTableComponents';
import ReleaseAction from './Table/Columns/ReleaseAction';

const WorkOrdersColumns = (state) => [
  {
    field: 'flag',
    headerName: '',
    sortable: false,
    type: 'number',
    width: '90',
    renderCell: (params) => <CellComponentFlag {...params} />,
  },
  {
    field: 'workOrderName',
    headerName: 'Work Order Id',
    flex: 3,
    sortable: false,
    renderCell: (params) => <CellComponentLink {...params} />,
  },
  {
    field: 'workRequestName',
    headerName: 'Work Order Name',
    sortable: false,
    flex: 3,
    renderCell: (params) => <CellComponentWOName {...params} />,
  },
  {
    field: 'projectName',
    headerName: 'Project Name',
    sortable: false,
    flex: 3,
    renderCell: CellComponentWOName,
  },
  {
    field: 'workOrderStatusTypeName',
    headerName: 'Status',
    sortable: false,
    flex: 2,
    renderCell: (params) => <CellComponentStatus {...params} />,
  },
  {
    field: 'needBy',
    headerName: 'Need By',
    sortable: false,
    flex: 2,
    valueFormatter: (params) => {
      if (!params.value) return null;
      return convertToLocalTime(params.value).format('MM/DD/YYYY');
    },
  },
  {
    field: 'daysRemaining',
    renderHeader: () =>
      state === 'closed' ? (
        <Stack direction="row">
          <Typography variant="tableCellBold"> Days Early/</Typography>
          <Typography variant="tableCellBold" sx={{ color: 'error.main' }}>
            Late
          </Typography>
        </Stack>
      ) : (
        <Typography variant="tableCellBold">Days Remaining</Typography>
      ),
    sortable: false,
    flex: 2,
    renderCell: (params) => <CellComponentDays {...params} />,
  },
  {
    field: 'attachments',
    headerName: '',
    sortable: false,
    flex: 2,
    renderCell: WorkRequestAttachments(),
  },
  {
    field: 'actions',
    type: 'actions',
    sortable: false,
    resizable: false,
    disableReorder: true,
    getActions: ({ row }) => [<ReleaseAction workOrder={row} />],
  },
  {
    field: 'trash',
    headerName: '',
    sortable: false,
    type: 'number',
    width: '50',
    renderCell: (params) => <CellComponentTrash {...params} />,
  },
];

export default WorkOrdersColumns;
