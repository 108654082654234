import React from 'react';

import Skeleton from '@mui/material/Skeleton';

const Loader = () => (
  <Skeleton
    variant="rectangular"
    animation="wave"
    sx={{ height: '24px', minHeight: '24px', margin: '4px', borderRadius: '4px' }}
  />
);

export default Loader;
