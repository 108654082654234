import React, { useMemo } from 'react';

import { gql, useQuery } from '@apollo/client';
import { useFormContext } from 'react-hook-form';

import { useUser } from 'app/UserContext';
import { departments as DEPARTMENTS } from 'graphql/queries';
import { DEPARTMENT_TYPE } from 'types/DepartmentTypes';

import DropDown from './DropDown';
import { mapDepartment } from './ProcurementDepartmentInput';

const ShopDepartmentInput = () => {
  const { control } = useFormContext();
  const { user } = useUser();

  const { data: departments } = useQuery(gql(DEPARTMENTS), {
    variables: {
      query: { companyId: user.companyId, departmentTypeId: DEPARTMENT_TYPE.SHOP },
    },
    fetchPolicy: 'cache-and-network',
  });

  const departmentsOptions = useMemo(
    () => departments?.departments?.map(mapDepartment) ?? [],
    [departments],
  );

  return (
    <DropDown
      control={control}
      name="assignShopDepartment"
      label="Assign Shop Department"
      noOptionsText="No Shop Departments Available"
      options={departmentsOptions}
    />
  );
};

export default ShopDepartmentInput;
