import { useCallback } from 'react';

import { gql, useLazyQuery } from '@apollo/client';

import { partsCloudSearch as PARTS_COULD_SEARCH } from 'graphql/queries';
import { DEFAULT_QUERY_PARAMS_BY_PART_ID } from 'helpers/cloudSearchParams';
import { stringifyCloudQueryPartId } from 'helpers/stringFunctions';

const usePartsCloudSearchAPIByPartId = () => {
  const [fetchParts] = useLazyQuery(gql(PARTS_COULD_SEARCH), {
    fetchPolicy: 'no-cache',
  });

  const getPartById = useCallback(async (partId) => {
    const {
      data: { partsCloudSearch: selectedPart },
    } = await fetchParts({
      variables: {
        query: {
          query: stringifyCloudQueryPartId(partId),
          take: 1,
          ...DEFAULT_QUERY_PARAMS_BY_PART_ID,
        },
      },
    });

    if (!selectedPart || selectedPart.length < 0) return null;
    return selectedPart[0];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { getPartById };
};

export default usePartsCloudSearchAPIByPartId;
