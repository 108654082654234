import React from 'react';

import { Breadcrumbs } from 'components/NavigationComponents';

import useBreadcrumbBuilder from '../ItemsSection/ItemsListSection/useBreadcrumbBuilder';
import { useCatalogPartContext } from '../Providers/CatalogPartProvider';

const BreadCrumbHeader = ({ showBreadcrumb, children }) => {
  const { showNewItem } = useCatalogPartContext();
  const { categoriesBreadcrumbs } = useBreadcrumbBuilder();
  const breadCrumbs = showNewItem
    ? [...categoriesBreadcrumbs, { label: 'New Item' }]
    : categoriesBreadcrumbs;

  return (
    <>
      {showBreadcrumb && (
        <Breadcrumbs
          maxItems={2}
          aria-label="breadcrumb"
          breadcrumbs={[{ label: 'Items' }, ...breadCrumbs]}
          labelSx={{ fontSize: '16px', color: 'rgba(0, 0, 0, 0.5)' }}
          lastLabelSx={{ fontSize: '16px', color: 'rgba(0, 0, 0, 0.87 )' }}
        />
      )}
      {children}
    </>
  );
};

export default BreadCrumbHeader;
