import React, { FC } from 'react';

import { CircularProgress, Stack, Typography } from '@mui/material';
import { FormProvider } from 'react-hook-form';

import { CancelButton } from 'components';
import { WorkRequestCreateForm } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestModalCreate/WorkRequestCreateForm';

import { useCurrentProjectContext } from '../../context/CurrentProjectContext';
import { useItemsIntegrationModalContext } from '../../context/ItemsIntegrationModalContext';
import { useNewWRFormContext } from './context/NewWRFormContext';

const NewWRForm: FC = () => {
  const { setAddNewWRForm } = useItemsIntegrationModalContext();
  const { methods, facilities, loadingAddWR } = useNewWRFormContext();
  const { currentProject } = useCurrentProjectContext();

  const handleCancel = () => {
    methods.reset();
    setAddNewWRForm(false);
  };
  return (
    <FormProvider {...methods}>
      <Stack
        position="relative"
        gap="24px"
        alignItems="flex-start"
        bgcolor="#F5F5F5"
        padding="16px 24px"
        maxWidth={450}
      >
        {loadingAddWR && (
          <Stack
            position="absolute"
            top={0}
            left={0}
            height="100%"
            width="100%"
            padding={0}
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress color="secondary" />
          </Stack>
        )}
        <Typography variant="body2" fontWeight={700}>
          Create new work request
        </Typography>
        <WorkRequestCreateForm
          shouldUnregisterFields={false}
          showProjectInfo={false}
          facilities={facilities}
          isAdding={loadingAddWR}
          formDescriptionSx={{ fontSize: '14px', fontWeight: 500 }}
          facilityIdSx={{ width: undefined }}
          selectedItem={currentProject}
        />
        <CancelButton
          onClick={() => {
            handleCancel();
          }}
          disabled={loadingAddWR}
          sx={{ color: 'secondary.main', letterSpacing: '0.4px', fontWeight: 600 }}
        />
      </Stack>
    </FormProvider>
  );
};

export default NewWRForm;
