import { ROW_TYPES } from '../../../constants/constants';
import { useLocationsAndWorkPhases } from '../../../contexts/LocationsAndWorkPhaseContext';
import { createOption, getFirstChildType } from './moreMenuHelpers';
import { useGeneralOptions } from './useGeneralOptions';

const getLocationSystemOptionsByChildren = (node, addLocation, ascendantIsFloor) => {
  if (getFirstChildType(node) === ROW_TYPES.FLOOR)
    return [createOption('New floor', () => addLocation(node, ROW_TYPES.FLOOR))];

  if (getFirstChildType(node) === ROW_TYPES.ZONE)
    return [createOption('New zone', () => addLocation(node, ROW_TYPES.ZONE))];

  return [
    createOption('New zone', () => addLocation(node, ROW_TYPES.ZONE)),
    !ascendantIsFloor && createOption('New floor', () => addLocation(node, ROW_TYPES.FLOOR)),
  ].filter((row) => row);
};

export const useLocationSystemOptions = (node, parent, ascendantIsFloor) => {
  const {
    locations: { handleAddLocation },
  } = useLocationsAndWorkPhases();
  const { getGeneralOptions } = useGeneralOptions(node, parent);

  const getLocationSystemOptions = () => [
    ...getLocationSystemOptionsByChildren(node, handleAddLocation, ascendantIsFloor),
    createOption('New location system above', () =>
      handleAddLocation(node, ROW_TYPES.LOCATION_SYSTEM, 'above'),
    ),
    createOption('New location system below', () =>
      handleAddLocation(node, ROW_TYPES.LOCATION_SYSTEM, 'below'),
    ),
    ...getGeneralOptions(),
  ];

  return { getLocationSystemOptions };
};
