import React, { useState, useContext, createContext, useMemo, useCallback } from 'react';

const TabPadsContext = createContext();

const TabPadsProvider = ({ children }) => {
  const [addNewPad, setAddNewPad] = useState(false);
  const [savingNewPad, setSavingNewPad] = useState(false);
  const [currentPadSelected, setCurrentPadSelected] = useState({});
  const [currentName, setCurrentName] = useState('');
  const [nameIsEditable, setNameIsEditable] = useState(false);
  const [autoFocus, setAutoFocus] = useState(true);
  const [modalIsActive, setModalIsOpen] = useState(false);

  const hideModal = () => setModalIsOpen(false);
  const showModal = () => setModalIsOpen(true);
  const onChangeNameToDelete = (padId) => setCurrentPadSelected(padId);

  const onDeletePad = useCallback((pad) => {
    if (!pad.hasMappedItems) {
      onChangeNameToDelete(pad);
      showModal();
    }
  }, []);

  const options = useMemo(
    () => [
      {
        key: 'Rename',
        label: 'Rename',
        clickEvent: (pad) => {
          onChangeNameToDelete(pad);
          setNameIsEditable(true);
        },
      },
      {
        key: 'Delete',
        label: 'Delete',
        clickEvent: onDeletePad,
      },
    ],
    [onDeletePad],
  );

  return (
    <TabPadsContext.Provider
      value={{
        modalIsActive,
        setModalIsOpen,
        addNewPad,
        setAddNewPad,
        savingNewPad,
        setSavingNewPad,
        autoFocus,
        setAutoFocus,
        currentName,
        setCurrentName,
        currentPadSelected,
        setCurrentPadSelected,
        nameIsEditable,
        setNameIsEditable,
        hideModal,
        options,
      }}
    >
      {children}
    </TabPadsContext.Provider>
  );
};

const useTabPadsContext = () => {
  const context = useContext(TabPadsContext);
  if (context === undefined) throw new Error('useTabPadsContext must be within a TabPadsContext');
  return context;
};

export { useTabPadsContext, TabPadsProvider };
