import { useMemo } from 'react';

import { gql, useQuery } from '@apollo/client';

import { documentTypes as DOCUMENT_TYPES } from 'graphql/queries';
import { findItemByProperties } from 'helpers/arrayFunctions';

import { DocumentType } from '../../types/DocumentType';

interface QueryData {
  documentTypes: DocumentType[];
}

const getTypeId = (documentTypes: DocumentType[], documentTypeName: string): string =>
  (findItemByProperties(documentTypes, { documentTypeName }) as unknown as DocumentType)?.documentTypeId;

export default function useDocumentTypes() {
  const { data } = useQuery<QueryData>(gql(DOCUMENT_TYPES));

  const documentTypeIds = useMemo((): {
    drawingModelCNCIds: string | null;
    commonTypeId: string | null;
  } => {
    if (!data?.documentTypes)
      return {
        drawingModelCNCIds: '',
        commonTypeId: null,
      };

    const commonTypeId = getTypeId(data?.documentTypes, 'Common');
    const drawingsTypeId = getTypeId(data?.documentTypes, 'Drawings');
    const modelsTypeId = getTypeId(data?.documentTypes, 'Models');
    const CNCTypeId = getTypeId(data?.documentTypes, 'CNC');

    const drawingModelCNCIds = `${drawingsTypeId},${modelsTypeId},${CNCTypeId}`;

    return { drawingModelCNCIds, commonTypeId };
  }, [data?.documentTypes]);

  const returnObj = { documentTypes: data?.documentTypes || [], ...documentTypeIds };

  return returnObj;
}
