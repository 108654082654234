import { useCallback, useEffect } from 'react';

import { gql, useLazyQuery, useMutation } from '@apollo/client';

import { FETCH_POLICIES } from 'constants/globalConstants';
import { addWorkCell as ADD_WORK_CELL } from 'graphql/mutations';
import { workCells as WORK_CELLS } from 'graphql/queries';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

import { ROW_TYPES } from '../constants/constants';
import { useDataContext } from '../contexts/DataContext';
import { buildVariables } from '../helpers/helpers';

export default function useShopConfigWorkCellsDataLayer() {
  const { selectedItem, shopConfiguration, setActiveRow } = useDataContext();
  const { handleResponse } = useGraphqlResponseHandler();

  const [workCellsQuery, { data, loading: loadingWorkCells }] = useLazyQuery(
    gql(WORK_CELLS),
    FETCH_POLICIES.CACHE_AND_NETWORK,
  );

  const [addWorkCellMutation, { loading: loadingAddingWorkCell }] = useMutation(
    gql(ADD_WORK_CELL),
    {
      update(cache, { data: result }) {
        const cachedWorkCells = cache.readQuery({
          query: gql(WORK_CELLS),
          ...buildVariables(selectedItem, shopConfiguration),
        });
        if (cachedWorkCells?.workCells && result.addWorkCell) {
          cache.writeQuery({
            query: gql(WORK_CELLS),
            ...buildVariables(selectedItem, shopConfiguration),
            data: {
              workCells: [...cachedWorkCells.workCells, { ...result.addWorkCell }],
            },
          });
          setActiveRow({
            id: result.addWorkCell.workCellId,
            isNew: true,
            type: ROW_TYPES.WORK_CELL,
            ...result.addWorkCell,
          });
        }
      },
    },
  );

  const addWorkCell = async (workCellName) => {
    const body = {
      workCellName,
      ...buildVariables(selectedItem, shopConfiguration).variables.query,
    };

    handleResponse(
      addWorkCellMutation,
      { variables: { body } },
      { successMessage: 'Work cell successfully added' },
    );
  };

  const fetchWorkCells = useCallback(async () => {
    if (!shopConfiguration?.value) return;
    workCellsQuery(buildVariables(selectedItem, shopConfiguration));
    setActiveRow({});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, shopConfiguration]);

  useEffect(() => {
    fetchWorkCells();
  }, [fetchWorkCells]);

  return {
    workCells: data?.workCells?.filter((workCell) => workCell.workCellName !== 'Unassigned') || [],
    loadingWorkCells,
    addWorkCell,
    loadingAddingWorkCell,
  };
}
