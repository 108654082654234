import skipTakePagination from './skipTakePagination';

const QueryPolicies = {
  fields: {
    companies: skipTakePagination(),
    users: skipTakePagination(['query', ['companyId', 'isAdmin']]),
    projects: skipTakePagination(['query', ['companyId']]),
    projectUsers: skipTakePagination(false),
    departmentUsers: skipTakePagination([
      'query',
      ['departmentId', 'includeAdmin', 'includeDeleted', 'take', 'orderBy', 'searchPhrase'],
    ]),
    projectsFromDepartmentUsers: skipTakePagination(),
    messages: skipTakePagination(['query', ['status', 'messagePriorityId', 'read']]),
    partAttributes: skipTakePagination(['query', ['partCategoryId', 'take']]),
    parts: skipTakePagination(['query', ['partCategoryId', 'take']]),
    sourcePadItems: skipTakePagination([
      'query',
      [
        'projectId',
        'sourcePadName',
        'locationIds',
        'locationsWithWorkPhases',
        'workPhaseIds',
        'orderBy',
        'rules',
        'hideLineItemsWithZeroRemainingQuantity',
        'hideMappedLineItems',
        'hideLineItemsWithZeroQuantity',
        'showUnassignedLineItemsOnly',
        'showWriteInLineItemsOnly',
        'showLineItemsWithZeroQuantity',
      ],
    ]),
    takeoffPadItems: skipTakePagination([
      'query',
      [
        'takeOffPadId',
        'locationIds',
        'locationsWithWorkPhases',
        'workPhaseIds',
        'orderBy',
        'rules',
        'externalSourceImportDescription',
        'showWriteInLineItemsOnly',
        'showUnassignedLineItemsOnly',
        'showLineItemsWithZeroQuantity',
        'showLineItemsMappedFromLineItems',
        'hideLineItemsWithZeroRemainingQuantity',
        'hideLineItemsWithZeroQuantity',
        'hideMappedLineItems',
      ],
    ]),
    products: skipTakePagination(),
    bundles: skipTakePagination(),
    locations: skipTakePagination(['query', ['projectId', 'parentLocationId', 'take']]),
    taskTypes: skipTakePagination(['query', ['companyId']]),
    machines: skipTakePagination(['query', ['facilityId', 'workCellId']]),
    partsCloudSearch: skipTakePagination(['query', ['query', 'size']]),
    licenses: skipTakePagination(),
    platformSettings: skipTakePagination('query', ['platformSettings']),
    partCategory: skipTakePagination(['query', ['companyId', 'partCatalogId', 'parentPartCategoryId']]),
    machineTaskType: skipTakePagination(['query', ['machineId', 'workCellId']]),
    workOrders: skipTakePagination([
      'query',
      [
        'take',
        'orderBy',
        'facilityId',
        'projectId',
        'workOrderStatusIds',
        'workRequestId',
        'projectIds',
        'workRequestIds',
        'workOrderIds',
      ],
    ]),
    workCellTasksQueue: skipTakePagination(['query', ['workCellTaskId', 'taskStatusTypeIds', 'workCellId']]),
    workRequests: skipTakePagination([
      'query',
      ['projectId', 'facilityId', 'statusName', 'workRequestStatusIds', 'take', 'orderBy', 'skip'],
    ]),
    syncConnections: skipTakePagination(),
    syncWorkflows: skipTakePagination(),
    syncArchivedWorkflows: skipTakePagination(),
    syncWebhooks: skipTakePagination(),
    getAutodeskForgeHubs: skipTakePagination(['query', ['connectionId']]),
    getAutodeskForgeProjects: skipTakePagination(['query', ['connectionId', 'hubId']]),
    getAutodeskForgeSubItems: skipTakePagination(['query', ['connectionId', 'hubId', 'projectId', 'parentFolderId']]),
    getAutodeskFieldProjects: skipTakePagination(['query', ['connectionId']]),
    getAutodeskFieldFolders: skipTakePagination(['query', ['connectionId', 'projectId', 'parentFolderId']]),
    getAutodeskGlueProjects: skipTakePagination(['query', ['connectionId']]),
    getAutodeskGlueFolders: skipTakePagination(['query', ['connectionId', 'projectId', 'parentFolderId']]),
    getNewformaProjects: skipTakePagination(['query', ['connectionId']]),
    getNewformaFolders: skipTakePagination(['query', ['connectionId', 'projectId', 'parentFolderId']]),
    getProcoreCompanies: skipTakePagination(['query', ['connectionId']]),
    getProcoreProjects: skipTakePagination(['query', ['connectionId', 'companyId']]),
    getProcoreFolders: skipTakePagination(['query', ['connectionId', 'companyId', 'projectId', 'parentFolderId']]),
    getPlangridProjects: skipTakePagination(['query', ['connectionId']]),
    getPlangridFolders: skipTakePagination(['query', ['connectionId', 'projectId', 'parentFolderId']]),
    getSharepointSites: skipTakePagination(['query', ['connectionId']]),
    getSharepointFolders: skipTakePagination(['query', ['connectionId', 'siteId', 'parentFolderId']]),
    syncFetchForesiteCompanies: skipTakePagination(['query', ['connectionId']]),
    syncFetchForesiteProjects: skipTakePagination(['query', ['connectionId', 'companyId']]),
    syncFetchForesiteFolders: skipTakePagination([
      'query',
      ['connectionId', 'companyId', 'projectId', 'parentFolderId'],
    ]),
    syncGetWindowsFolders: skipTakePagination(['query', ['connectionId', 'parentFolderId']]),
    syncGetWorkflowMappingTemplatesList: skipTakePagination(),
    syncLogs: skipTakePagination(),
    syncRealTimeLogs: skipTakePagination(),
    syncLogFileDetails: skipTakePagination(['query', ['fileSyncLogId', 'IsDebug', 'searchPhrase']]),
    taskMultiSearch: skipTakePagination(['query', ['workRequestItemId', 'workRequestId']]),
    folders: skipTakePagination(['query', ['searchPhrase', 'parentFolderId']]),
    documents: skipTakePagination([
      'query',
      ['folderIds', 'orderBy', 'documentIds', 'documentIdentifier', 'documentTypeIds'],
    ]),
    workRequestItems: skipTakePagination(['query', ['workRequestId']]),
    unitOfMeasure: skipTakePagination(['query', ['companyId']]),
    workCellTasksMultiSearch: skipTakePagination([
      'query',
      ['facilityIds', 'taskStatusTypesIds', 'workRequestId', 'orderBy'],
    ]),
    taskDocument: skipTakePagination(['query', ['taskId', 'taskIds', 'taskDocumentId', 'documentTypeIds']]),
    workOrderDocuments: skipTakePagination(['query', ['workOrderId', 'workOrderIds']]),
    workRequestDocuments: skipTakePagination(['query', ['workRequestId']]),
    workOrderItem: skipTakePagination(['query', ['workOrderId']]),
    billOfMaterialItem: skipTakePagination([
      'query',
      [
        'workPhaseIds',
        'take',
        'showWriteInLineItemsOnly',
        'hideLineItemsWithZeroQuantity',
        'externalSourceImportDescription',
        'hideLineItemsWithZeroRemainingQuantity',
        'hideMappedLineItems',
        'locationIds',
        'locationsWithWorkPhases',
        'orderby',
        'projectId',
        'rules',
        'scopePackageIds',
        'showUnassignedLineItemsOnly',
        'showLineItemsWithZeroQuantity',
        'showLineItemsMappedFromLineItems',
        'parentLineItemIds',
        'lineItemName',
      ],
    ]),
  },
};

export default QueryPolicies;
