import React, { Fragment } from 'react';

import { Box } from '@mui/material';
import { Waypoint } from 'react-waypoint';

import AttributeItem from './AttributeItem';

const AttributeContent = ({
  attributes,
  handleEditAttribute,
  handleClickAttribute,
  selectedAttribute,
  paginationHandler,
  selectedCategoryId,
  handleAddNewVariant,
}) => (
  <Box>
    {attributes.map((attribute) => {
      const isSelected = attribute?.partAttributeId === selectedAttribute?.partAttributeId;

      return (
        <Fragment key={attribute?.partAttributeId}>
          <AttributeItem
            data-testid={`attribute-${attribute?.partAttributeId}`}
            handleAddNewVariant={handleAddNewVariant}
            isSelected={isSelected}
            key={attribute?.partAttributeId}
            name={attribute?.partAttributeName}
            onClick={() => handleClickAttribute(attribute)}
            editAttribute={() => handleEditAttribute(attribute)}
          />
          <Waypoint
            bottomOffset="-20%"
            onEnter={
              attributes.length === 1
                ? undefined
                : () =>
                    paginationHandler(attributes.length, {
                      partCategoryId: selectedCategoryId,
                      orderBy: 'name:asc',
                    })
            }
          />
        </Fragment>
      );
    })}
  </Box>
);

export default AttributeContent;
