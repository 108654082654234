import { useState } from 'react';

export const useHandleDecisionAdded = () => {
  const [selectedRowDecision, setSelectedRowDecision] = useState([]);
  const [lineItemsAdded, setLineItemsAdded] = useState([]);
  const [lineItemDecision, setLineItemDecision] = useState(null);
  const [catalogItemsPendingToAdd, setCatalogItemsPendingToAdd] = useState(null);
  const [allDecisionsMade, setAllDecisionsMade] = useState([]);

  const updateLineItemsAdded = (itemToMakeDecision, itemsAdded = null) => {
    setLineItemDecision(itemToMakeDecision);
    if (itemsAdded) {
      setLineItemsAdded(itemsAdded);
    }
  };

  return {
    lineItemsAdded,
    lineItemDecision,
    updateLineItemsAdded,
    catalogItemsPendingToAdd,
    setCatalogItemsPendingToAdd,
    selectedRowDecision,
    setSelectedRowDecision,
    allDecisionsMade,
    setAllDecisionsMade,
  };
};
