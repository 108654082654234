import { SubscriptionHookOptions, gql, useSubscription } from '@apollo/client';

import { assignedEntitlement as ASSIGNED_ENTITLEMENT } from 'graphql/subscriptions';
import { Entitlement } from 'graphql/types';

const useAssignedEntitlementSubscription = (
  options: SubscriptionHookOptions<{ assignedEntitlement: Entitlement[] }, any>,
) => useSubscription<{ assignedEntitlement: Entitlement[] }>(gql(ASSIGNED_ENTITLEMENT), options);

export default useAssignedEntitlementSubscription;
