/* eslint-disable max-lines-per-function */
import React, { useCallback, useMemo } from 'react';

import { useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useSnackbar } from 'notistack';
import { v4 as uuidv4 } from 'uuid';

import ComponentPaneActions from 'app/Layout/ReactGridLayout/ComponentPaneActions';
import { useUser } from 'app/UserContext';
import { Custom } from 'components/Icons';
import { VARIANT_SUCCESS, VARIANT_ERROR } from 'constants/snackbarConstants';
import useDocumentAPI from 'hooks-api/useDocumentAPI';
import useUploadFile from 'hooks/useUploadFile';
import { useDrawingRegisterContext } from 'modules/Design/DrawingRegister/DrawingRegisterContext';
import { useDrawingRegisterDragDrop } from 'modules/Design/DrawingRegister/DrawingRegisterDragDropProvider';
import useDocumentTypes from 'modules/Shop/Fabrication/TaskViewer/PlansModelsContent/hooks/useDocumentTypes';

import { useDrawingDocumentContext } from '../../DrawingDocumentContext';
import { useDrawingFolderContext } from '../../DrawingFolderContext';
import { loadingNewFiles } from '../../hooks';
import NewFolderButton from './NewFolderButton';

const getDocumentStoragePath = ({ folder = {}, file }) => {
  const documentGUID = uuidv4();
  return `${folder?.storagePath}/${documentGUID}-${file.name}`;
};

const DrawingDocumentToolbar = () => {
  const theme = useTheme();
  const { user } = useUser();
  const { selectedFolder, setUploadingDocuments, sortModel } = useDrawingRegisterContext();
  const { documentTypes, setShowLeavingDialog } = useDrawingDocumentContext();
  const { folders, openNewFolderFormRow } = useDrawingFolderContext();
  const { setIsMappingDocuments } = useDrawingRegisterDragDrop();
  const { drawingModelCNCIds } = useDocumentTypes();

  const { enqueueSnackbar } = useSnackbar();

  const { uploadFile } = useUploadFile(user);
  const { addDocumentToFolder, refetchExistingPages } = useDocumentAPI();
  const drawingDocumentType = useMemo(
    () => documentTypes.find(({ documentTypeName }) => documentTypeName === 'Drawings'),
    [documentTypes],
  );

  const uploadFileToS3 = useCallback(
    async (file) => {
      const storagePath = getDocumentStoragePath({
        folder: selectedFolder,
        file,
      });
      await uploadFile(file, storagePath);
      return { filename: file.name, storagePath };
    },
    [selectedFolder, uploadFile],
  );

  const onUpload = async (event) => {
    setShowLeavingDialog(true);
    setIsMappingDocuments(true);
    // files is a FileList, which cannot be treated as an array,
    // but *can* be spread into an actual array
    const setCacheFiles = [...event.target.files].map(loadingNewFiles);

    setUploadingDocuments((previousDocuments) => [...(previousDocuments ?? []), ...setCacheFiles]);

    const filesUploadPromises = [...event.target.files].map((file) => uploadFileToS3(file));
    const uploadedFiles = await Promise.all(filesUploadPromises);

    const addDocumentToFolderPromises = uploadedFiles.map(async ({ filename, storagePath }) => {
      const { data, errors } = await addDocumentToFolder({
        documentType: drawingDocumentType,
        filename,
        storagePath,
        folder: selectedFolder,
      });
      const document = data?.addDocument;

      setUploadingDocuments((previousDocuments) => [
        ...previousDocuments.filter((uploadingDocument) => uploadingDocument.documentName !== document?.documentName),
      ]);

      return { errors };
    });

    const resultPromise = await Promise.all(addDocumentToFolderPromises);
    await refetchExistingPages(
      { folderIds: selectedFolder?.folderId, orderBy: sortModel, documentTypeIds: drawingModelCNCIds },
      false,
      resultPromise?.length,
    );

    setIsMappingDocuments(false);
    setShowLeavingDialog(false);

    if (resultPromise.some((promise) => promise.errors)) {
      const errorFiles = resultPromise.reduce((acc, { errors }, index) => {
        if (errors) acc.push(setCacheFiles[index]);
        return acc;
      }, []);

      setUploadingDocuments((previousDocuments) =>
        previousDocuments.filter((document) =>
          errorFiles.some((errorDocument) => document.documentName !== errorDocument.documentName),
        ),
      );

      enqueueSnackbar(
        `There was an error uploading ${addDocumentToFolderPromises.length} ${
          addDocumentToFolderPromises.length === 1 ? 'file' : 'files'
        }. Please try again.`,
        VARIANT_ERROR,
      );
      return;
    }

    enqueueSnackbar(
      `${addDocumentToFolderPromises.length} ${
        addDocumentToFolderPromises.length === 1 ? 'file' : 'files'
      } successfully uploaded`,
      VARIANT_SUCCESS,
    );
  };

  return (
    <ComponentPaneActions sx={{ justifyContent: 'flex-end' }}>
      <Stack flexDirection="row" justifyContent="flex-end" data-testid="drawing-document-toolbar">
        <UploadButton component="label" startIcon={<Custom.Upload fill={theme.palette.secondary.dark} />}>
          UPLOAD
          <input hidden type="file" multiple onChange={onUpload} />
        </UploadButton>

        <NewFolderButton
          onClick={() => {
            openNewFolderFormRow(null, folders);
          }}
          dataTestId="create-folder-toolbar"
        />
      </Stack>
    </ComponentPaneActions>
  );
};

export default DrawingDocumentToolbar;

const UploadButton = (props) => (
  <Button
    sx={{
      width: '98px',
      color: 'secondary.dark',
      fontWeight: 500,
      mr: 1,
      fontSize: '14px',
    }}
    {...props}
  />
);
