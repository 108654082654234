import React from 'react';

import { ForesiteTextField } from '@evolvemep/foresite-components-ui-react';
import { useController, useFormContext } from 'react-hook-form';

const PartNameField = ({ name, sx, ...restProps }) => {
  const { control } = useFormContext();
  const {
    field: { onChange, onBlur, ref, value },
  } = useController({
    name,
    control,
    rules: {
      required: { value: true, message: 'Part Name is required' },
      maxLength: { value: 128, message: 'Part name must be 64 characters or less' },
    },
  });

  return (
    <ForesiteTextField
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      name={name}
      inputRef={ref}
      disabled
      sx={{ width: '100%', ...sx }}
      label="Part Name"
      maxLength={128}
      {...restProps}
    />
  );
};

export default PartNameField;
