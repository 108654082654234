import React, { Fragment } from 'react';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';

import InfiniteScroll from 'components/TableComponents/InfiniteScroll';
import NoContentMessage from 'components/TableComponents/NoContentMessage';
import TableContainer from 'components/TableComponents/TableContainer';
import TableHeader from 'components/TableComponents/TableHeaders';
import TableSkeleton from 'components/TableComponents/TableSkeleton';
import {
  handleTableRowClick,
  handleSelectAllClick,
  isSelected,
} from 'components/TableComponents/tableUtilities';

import { useEvolveMembersContext } from '../EvolveMembersContext';
import EvolveMembersTableRow from './EvolveMembersTableRow';

const EvolveMembersTable = ({
  loading,
  evolveMembers,
  handleSortOrder,
  paginationHandler,
  headerOptions,
}) => {
  const { selected, setSelected } = useEvolveMembersContext();

  const TableBodyContent = () => (
    <TableBody>
      {evolveMembers.map((member, index) => (
        <Fragment key={member.userId}>
          <EvolveMembersTableRow
            member={member}
            handleRowClick={() => handleTableRowClick(member, selected, setSelected)}
            isItemSelected={isSelected(member, selected)}
          />
          {index === evolveMembers.length - 1 && (
            <InfiniteScroll data={evolveMembers} fetchMore={paginationHandler} />
          )}
        </Fragment>
      ))}
    </TableBody>
  );

  return (
    <EvolveMembersTableBorderBox>
      <TableContainer>
        <Table stickyHeader>
          <TableHeader
            headerOptions={headerOptions}
            onSelectAllClick={(e) => handleSelectAllClick(e, evolveMembers, setSelected)}
            handleSortOrder={handleSortOrder}
            rowCount={evolveMembers.length}
            numSelected={selected.length}
            selectable
          />
          {loading ? (
            <TableSkeleton skeletons={headerOptions.map(({ title }) => title)} />
          ) : (
            <TableBodyContent />
          )}
        </Table>
      </TableContainer>
      {!evolveMembers.length && !loading && <NoContentMessage text="No Evolve Members..." />}
    </EvolveMembersTableBorderBox>
  );
};

export default EvolveMembersTable;

const EvolveMembersTableBorderBox = (props) => (
  <Box
    sx={{
      border: '1px solid',
      borderColor: 'other.divider.lightSurface',
      borderRadius: '5px',
      position: 'relative',
      padding: '5px 0 0 0',
      height: 'calc(100% - 135px)',
    }}
    {...props}
  />
);
