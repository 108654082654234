import React from 'react';

import Skeleton from '@mui/material/Skeleton';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

const TableSkeleton = ({ skeletons }) => (
  <TableBody>
    {skeletons.map((skull) => (
      <TableRow key={skull}>
        {skeletons.map((brain) => (
          <Cell key={brain} />
        ))}
      </TableRow>
    ))}
  </TableBody>
);
export default TableSkeleton;

const Cell = () => (
  <TableCell
    sx={{
      borderBottom: 'none',
      padding: 0,
    }}
  >
    <Typography variant="h3">
      <Skeleton
        sx={{
          borderRadius: 0,
        }}
      />
    </Typography>
  </TableCell>
);
