import { useCallback, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import { useUser } from 'app/UserContext';
import { useMaterialsConfig } from 'hooks-api/useMaterialsConfig';
import { CreateUOMModal } from 'modules/Materials/components/UOM';

import { UOMAutocomplete } from '../../Form';
import { BOMCatalogTableFields, BOMCatalogTableHeaders, isItemAPart } from './BOMTableColumnsHelpers';

export const getUnitOfMeasureColumn = () => ({
  useForm: true,
  field: BOMCatalogTableFields.UNIT_OF_MEASURE_CODE,
  headerName: BOMCatalogTableHeaders[BOMCatalogTableFields.UNIT_OF_MEASURE_CODE],
  flex: 1,
  editable: true,
  resizable: true,
  renderEditCell: () => <UnitOfMeasureEditCell />,
});

const UnitOfMeasureEditCell = () => {
  const { control, setValue, clearErrors, getValues, formState } = useFormContext();
  const { unitOfMeasure: uomOptions } = useMaterialsConfig();
  const { user } = useUser();
  const [isOpenUOMModal, setIsOpenUOMModal] = useState(false);

  const onSelectUOM = useCallback(
    (newUOM) => {
      setValue('uom', newUOM);
      clearErrors('uom');
    },
    [setValue, clearErrors],
  );

  const disableAutocomplete = isItemAPart(getValues('lineItemTypeId')) || formState.isSubmitting;
  return (
    <>
      <UOMAutocomplete
        control={control}
        options={uomOptions}
        onCreate={() => setIsOpenUOMModal(true)}
        disabled={disableAutocomplete}
      />
      <CreateUOMModal
        open={isOpenUOMModal}
        companyId={user?.companyId}
        onCreate={() => setIsOpenUOMModal(false)}
        onClose={() => setIsOpenUOMModal(false)}
        onSelectUOMCreated={onSelectUOM}
      />
    </>
  );
};
