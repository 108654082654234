import React, { useMemo, useState } from 'react';

import { MenuItem, Typography } from '@mui/material';

import DeleteModal from 'components/DeleteModal';

import { useTakeOff } from '../../context/TakeOffContext';

const DeleteItemsOption = ({ closeMenu }) => {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { selectedPadItems, onDeleteTakeOffPadItems, pads, padIndex } = useTakeOff();

  const selectedIds = selectedPadItems.map((item) => item.lineItemId);
  const activePad = useMemo(() => pads?.[padIndex], [padIndex, pads]);
  const isAssignedToBOM = selectedPadItems.some((item) => item.padName !== 'Unassigned');

  const handleSubmit = async () => {
    setLoading(true);
    await onDeleteTakeOffPadItems(selectedIds);
    setLoading(false);
    setOpenModal(false);
    closeMenu();
  };

  if (activePad?.isSourcePad || isAssignedToBOM) return null;

  return (
    <>
      <MenuItem onClick={() => setOpenModal(true)} data-cy="delete-option">
        <Typography color="error" sx={{ fontSize: 14, lineHeight: '20px' }}>
          Delete
        </Typography>
      </MenuItem>
      <DeleteModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        title="Confirm delete"
        itemName=""
        confirmText={<DeleteConfirmationText selectedPadItems={selectedPadItems} />}
        onSubmit={handleSubmit}
        loading={loading}
        deleteButtonText="Delete"
      />
    </>
  );
};

const DeleteConfirmationText = ({ selectedPadItems }) => (
  <Typography variant="body1" component="span">
    Are you sure you want to delete <b>{selectedPadItems?.length}</b> selected items
  </Typography>
);

export default DeleteItemsOption;
