import React from 'react';

import { Stack, Typography } from '@mui/material';

import { Custom } from 'components/Icons';
import MiniSpinner from 'components/MiniSpinner';

export const CustomAssemblyTypeRowInfo = React.memo(({ node, /* depth = 0, */ sx }) => (
  // should implement tree structure logic here with depth!
  // custom assembly type can have assemblies as children
  <StyledRow sx={sx}>
    <RowTypeId node={node} sx={sx} />
  </StyledRow>
));

const StyledRow = ({ isActive, onClick, sx, children }) => (
  <Stack
    onClick={onClick}
    direction="row"
    width="100%"
    alignItems="center"
    minHeight="32px"
    sx={{
      backgroundColor: isActive && 'action.lightSurface.selected',
      borderColor: 'other.border',
      cursor: 'pointer',
      paddingX: 0,
      '& > .MuiIconButton-root': {
        opacity: 0,
      },
      ...sx,
    }}
  >
    {children}
  </Stack>
);

export const RowTypeId = ({ node, sx }) => (
  <Stack
    direction="row"
    sx={{ ...sx, width: '100%', paddingLeft: node.type === 'Custom Assembly' ? '40px' : '' }}
    alignItems="center"
  >
    {node.type === 'Loading' ? (
      <MiniSpinner />
    ) : (
      <>
        {node.type === 'CustomAssemblyType' ? <Custom.AssemblyType /> : <Custom.CustomAssembly />}
        {node.type === 'CustomAssemblyType' ? (
          <Typography
            variant="body2"
            sx={{
              flex: 1,
              letterSpacing: '0.1px',
              marginLeft: 1,
              userSelect: 'none',
            }}
          >
            {node.identifier}
          </Typography>
        ) : (
          <Stack direction="row" sx={{ width: '100%', paddingLeft: '10px' }} alignItems="center">
            <Typography variant="body2" sx={{ flexGrow: 1 }}>
              {node.children.customAssemblyIdentifier}
            </Typography>
            <Typography variant="body2" sx={{ flexGrow: 1 }}>
              {node.children.customAssemblyName}
            </Typography>
          </Stack>
        )}
      </>
    )}
  </Stack>
);
