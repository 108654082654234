import React from 'react';

import { InputForesiteField } from '@evolvemep/foresite-components-ui-react';
import { useFormContext } from 'react-hook-form';

import { ControlledAutoComplete } from 'components';
import StackRow from 'components/StackRow';
import stateCodes from 'helpers/stateCodes';

import CountryField from './CountryField';

const AddressForm = ({ address }) => {
  const { control } = useFormContext();

  return (
    <>
      <InputForesiteField
        name="address.addressLine1"
        label="Address Line 1"
        size="small"
        defaultValue={address?.addressLine1}
        control={control}
        rules={{ required: true }}
        data-cy="address-line-1"
      />
      <InputForesiteField
        name="address.addressLine2"
        label="Address Line 2"
        size="small"
        defaultValue={address?.addressLine2 || ''}
        control={control}
        data-cy="address-line-2"
      />
      <StackRow>
        <ControlledAutoComplete
          freeSolo
          name="address.state"
          label="State/Province/Region"
          defaultValue={address?.state}
          control={control}
          options={stateCodes}
          rules={{ required: true }}
          listBoxProps={{ style: { maxHeight: '17em' } }}
          fullWidth
          data-cy="address-state"
        />
        <InputForesiteField
          name="address.zip"
          size="small"
          label="Zip/Postal Code"
          defaultValue={address?.zip}
          control={control}
          rules={{ required: true }}
          sx={{ marginLeft: '24px' }}
          data-cy="address-zip"
        />
      </StackRow>
      <InputForesiteField
        name="address.city"
        label="City"
        size="small"
        defaultValue={address?.city}
        control={control}
        rules={{ required: true }}
        data-cy="address-city"
      />
      <CountryField countryValue={address?.country} />
    </>
  );
};

export default AddressForm;
