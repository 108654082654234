import { useCallback, useMemo } from 'react';

import { useSearchRepeatObj } from 'hooks/useSearchRepeatObj';
import { useTaskPredecessorBlacklist } from 'modules/Field/components/TasksTable/useTaskPredecessorBlacklist';
import { Task } from 'typings/task';

export const useTaskRepeatAndPredecessors = (tasks: Task[]) => {
  const transformTaskData = useCallback(
    (item: Task, flagCount: number) => ({
      ...item,
      taskName: flagCount > 0 ? `${item.taskName} (${flagCount})` : item.taskName,
      duplicateCount: flagCount,
    }),
    [],
  );

  const tasksAddDuplicateCount = useSearchRepeatObj(tasks, 'taskName', transformTaskData, () => 0);

  const taskIdsRelateDuplicate = useMemo(
    () => tasksAddDuplicateCount.reduce((p, c) => ({ ...p, [c.taskId]: c.duplicateCount }), {}),
    [tasksAddDuplicateCount],
  );

  const tasksPredecessorBlacklist = useTaskPredecessorBlacklist(
    tasks,
    [...tasks.map((task) => task.taskId)],
    true,
  );

  return { taskIdsRelateDuplicate, tasksPredecessorBlacklist, tasksAddDuplicateCount };
};
