import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import getModuleIcon from 'helpers/module';
import useSetupModule from 'hooks/useSetupModule';

import EvolveLogoLink from './components/EvolveLogoLink';
import Breadcrumb from './components/FacilitiesProjectsBreadcrumb';
import HeaderDivider from './components/HeaderDivider';
import HeaderSearchAutocomplete from './components/HeaderSearchAutocomplete/HeaderSearchAutocomplete';
import MailButton from './components/MailButton';
import ModuleSelect from './components/ModuleSelect';
import SearchButton from './components/SearchButton';
import HelpDeskDropdown from './HelpDeskDropdown/HelpDeskDropdown';
import ProfileDropdown from './ProfileDropdown/ProfileDropdown';

const TabletHeader = () => {
  const { currentModule } = useSetupModule();
  const [showSearch, setShowSearch] = useState(false);

  const ActiveModuleIcon = () => (
    <img width="24" height="24" src={getModuleIcon(currentModule())} alt="module-icon" />
  );

  const handleToggleSearch = () => {
    setShowSearch(!showSearch);
  };

  const LeftAligned = () => (
    <Box display="flex" alignItems="center">
      <ActiveModuleIcon />
      <Breadcrumb style={{ marginLeft: '16px' }} />
    </Box>
  );

  const RightAligned = () => (
    <Box display="flex" alignItems="center">
      <SearchButton onClick={handleToggleSearch} />
      <HelpDeskDropdown />
      <MailButton />
      <ProfileDropdown />
      <HeaderDivider style={{ margin: '0 16px' }} orientation="vertical" flexItem />
      <ModuleSelect />
    </Box>
  );

  return (
    <>
      <SpaceBetweenContainer>
        <LeftAligned />
        <EvolveLogoLink />
        <RightAligned />
      </SpaceBetweenContainer>
      <SearchInputContainer style={{ display: showSearch ? 'flex' : 'none' }}>
        <HeaderSearchAutocomplete />
      </SearchInputContainer>
    </>
  );
};

export default TabletHeader;

const SpaceBetweenContainer = (props) => (
  <Stack
    sx={{
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    }}
    {...props}
  />
);

const SearchInputContainer = (props) => (
  <Box
    sx={{
      backgroundColor: '#424242',
      justifyContent: 'end',
      transition: 'all 0.2s ease-in-out',
      overflow: 'hidden',
      py: 1.5,
      px: 2,
      alignItems: 'center',
      height: '64px',
      position: 'absolute',
      top: '64px',
      left: 0,
      width: '100%',
      zIndex: 10000,
      '& > div': {
        width: '394px',
        '& > div': {
          backgroundColor: '#303030',
          color: 'white',
        },
      },
    }}
    {...props}
  />
);
