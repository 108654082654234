import { OptionsItem } from './types';

export const OPTIONS: OptionsItem[] = [
  {
    key: 'hideLineItemsWithZeroRemainingQuantity',
    label: 'Hide items with 0 Qty Remaining',
    active: true,
  },
  { key: 'hideMappedLineItems', label: 'Hide mapped items', active: true },
  { key: 'showLineItemsWithZeroQuantity', label: 'Show items with 0 Qty', active: true },
  { key: 'showLineItemsMappedFromLineItems', label: 'Show items from Revit source pad', active: false },
  { key: 'showUnassignedLineItemsOnly', label: 'Show unassigned items', active: true },
  { key: 'showWriteInLineItemsOnly', label: 'Show write-in items', active: true },
];
