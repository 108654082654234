import React from 'react';

import { grey } from '@mui/material/colors';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { CombinedDocumentType } from 'hooks/usePaginatedDocuments';

import DocumentIcon from './DocumentIcon';
import DownloadButton from './DownloadButton';

export const TEXT_ELLIPSIS_STYLE = {
  maxWidth: '17vw',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',

  '@media screen and ( max-width: 1140px )': {
    maxWidth: '194px',
  },
};

type ActiveDocumentProps = {
  document: CombinedDocumentType;
  handleDownload: () => void;
};

const ActiveDocument = ({ document, handleDownload }: ActiveDocumentProps) => (
  <>
    <Stack
      direction="row"
      alignItems="center"
      gap="10px"
      justifyContent="space-between"
      sx={{ background: grey[100], mr: 2, height: 32 }}
    >
      <Stack direction="row" alignItems="center" gap="10px" sx={{ path: { fillOpacity: 0.54 } }}>
        <DocumentIcon
          typeId={document?.documentTypeId}
          extension={document?.externalUrl ? 'url' : document?.extension}
        />
        <Typography variant="body2" sx={{ fontWeight: 700, color: 'secondary.main', ...TEXT_ELLIPSIS_STYLE }}>
          {document?.documentName || document?.externalUrl}
        </Typography>
      </Stack>

      <DownloadButton onClick={handleDownload} />
    </Stack>
    <Divider light sx={{ mr: 2, my: 1.5 }} />
  </>
);

export default ActiveDocument;
