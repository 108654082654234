import { Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router';

import { Custom } from 'components/Icons';
import { WorkCellTask } from 'graphql/types';
import { actionColors } from 'styles/theme/colors';

type WorkOrderNameCellProps = {
  id: string;
  row: WorkCellTask;
  value: string;
};

const WorkOrderNameCell = ({ id, row, value }: WorkOrderNameCellProps) => {
  const navigate = useNavigate();

  const handleNavigateToRow = (row: WorkCellTask) => {
    if (!row.isEnabled) return;
    navigate(`/shop/task-viewer/${row.taskId}`, { state: { from: 'Work Cell Queue' } });
  };

  const isDisabled = !row.isEnabled;

  const opacity = isDisabled ? 0.5 : 1;

  return (
    <Stack
      direction="row"
      data-testid={`workOrderName-${id}`}
      sx={{ alignItems: 'center', opacity }}
      color="secondary.main"
      width="100%"
    >
      <Icon />
      <Link disabled={isDisabled} onClick={() => handleNavigateToRow(row)}>
        {value}
      </Link>
    </Stack>
  );
};

export default WorkOrderNameCell;

const Icon = () => (
  <Stack sx={{ mr: 1, flexDirection: 'row', alignItems: 'center' }}>
    <Custom.WorkOrders style={{ fill: actionColors.disabled }} />
  </Stack>
);

type LinkProps = {
  disabled: boolean;
  children: any;
  onClick: () => void;
};

const Link = ({ disabled, ...rest }: LinkProps) => (
  <Typography
    variant="body2"
    sx={{
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      cursor: 'pointer',
      '&:hover': {
        color: 'secondary.main',
        textDecoration: !disabled ? 'underline' : 'none',
      },
    }}
    {...rest}
  />
);
