import useTaskDocuments from 'hooks/useTaskDocuments';

import useDocumentTypes from './useDocumentTypes';

export default function useCommonDocuments(taskId: string) {
  const { commonTypeId } = useDocumentTypes();
  const {
    tasksDocuments: commonDocuments,
    loading: loadingCommonDocuments,
    paginationWaypoint,
  } = useTaskDocuments([taskId], commonTypeId, true);

  return {
    commonDocuments,
    loadingCommonDocuments,
    commonPaginationWaypoint: paginationWaypoint,
  };
}
