import React from 'react';

import { CellComponentStatus, RadioCell } from './WorkOrderTaskCells';

const WorkOrderTasksColumns = (onChangeRadioButtons) => [
  {
    field: 'taskName',
    headerName: 'Task Name',
    flex: 1.5,
    sortable: false,
  },
  {
    field: 'taskStatusTypeName',
    headerName: 'Status',
    flex: 1,
    sortable: false,
    renderCell: (params) => <CellComponentStatus {...params} />,
  },
  {
    field: 'workCellName',
    headerName: 'Work Cell',
    flex: 1,
    sortable: false,
  },
  {
    field: 'saveData',
    headerName: 'Save data',
    flex: 1,
    sortable: false,
    renderCell: (params) => <RadioCell {...params} onChangeRadioButtons={onChangeRadioButtons} />,
  },
  {
    field: 'discardData',
    headerName: 'Discard data',
    flex: 1,
    sortable: false,
    renderCell: (params) => <RadioCell {...params} onChangeRadioButtons={onChangeRadioButtons} />,
  },
];

export default WorkOrderTasksColumns;
