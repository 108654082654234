import React from 'react';

import Button from '@mui/material/Button';

const PartItemButton = ({ color, disabled, variant, sx, onClick, buttonText, ...props }) => (
  <Button
    variant={variant}
    color={color}
    disabled={disabled}
    sx={{ marginLeft: '8px', ...sx }}
    onClick={onClick}
    data-cy="partItemButton"
    {...props}
  >
    {buttonText}
  </Button>
);

export default PartItemButton;
