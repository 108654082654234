/* eslint-disable import/no-cycle */
import { useEffect } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';

import { ADD_REMAINING_QUANTITIES_FIELD } from '../../../constants';
import { useItemsIntegrationTableContext } from '../../../context/ItemsIntegrationTableContext';
import { addAutoFilledField, getQtyFieldName, removeAutoFilledField } from '../../QtyField/helpers/qtyFieldHelpers';
import { useFieldContext } from '../context/FieldContext';
import { QuantityFieldType } from './@types/fieldHookTypes';

export const getChildQtyFieldName = (child: QuantityFieldType, lineItemId: string) =>
  getQtyFieldName({
    locationId: (child.isWorkPhase ? child.locationId : child.id) as string,
    lineItemId,
    workPhaseId: child.isWorkPhase ? child.id : undefined,
  });

const useClearAutofill = () => {
  const { control, setValue, getValues } = useFormContext();
  const {
    fieldName,
    isBomLineItem,
    qtyRemaining,
    qtyAggregate,
    fieldWatched,
    hasChildrenBelowAmount,
    currentNestLevel,
    lineItemFieldName,
    lineItemValueWatched,
    quantityFieldChildren,
    quantityField,
    lineItemId,
    unassignedRowFieldName,
  } = useFieldContext();
  const { autoFilledFields, setAutoFilledFields } = useItemsIntegrationTableContext();
  const autoFill = useWatch({ control, name: ADD_REMAINING_QUANTITIES_FIELD });
  useEffect(() => {
    // * clear field if any child below has value and current field has no qtyRemaining
    const locationElementHasValueOrQtyRemaining = autoFill || isBomLineItem || qtyRemaining || fieldWatched !== 0;
    if (locationElementHasValueOrQtyRemaining) return;
    const handleChildrenBelowAmount = () => {
      if (!autoFilledFields.includes(fieldName))
        addAutoFilledField(fieldName, { autoFilledFields, setAutoFilledFields });
      if (!getValues(fieldName) && getValues(fieldName) !== '') setValue(fieldName, 0);
    };
    if (hasChildrenBelowAmount) {
      handleChildrenBelowAmount();
      return;
    }
    if (autoFilledFields.includes(fieldName)) removeAutoFilledField(fieldName, setAutoFilledFields);
    setValue(fieldName, '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentNestLevel, isBomLineItem, qtyRemaining, fieldWatched, hasChildrenBelowAmount, fieldName, autoFill]);
  useEffect(() => {
    // * clear field if line item has NO value
    const lineItemHasValueOrIsNotAutofilled =
      autoFill || isBomLineItem || !autoFilledFields.includes(lineItemFieldName) || lineItemValueWatched;
    if (lineItemHasValueOrIsNotAutofilled) return;
    removeAutoFilledField(fieldName, setAutoFilledFields);
    setValue(fieldName, '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentNestLevel, fieldName, isBomLineItem, lineItemFieldName, lineItemValueWatched, autoFill]);
  useEffect(() => {
    // * Clear children fields autopop if lbs item qty is different than qtyAggregate
    const locaitonItemValueIsNotQtyAggregate =
      autoFill ||
      !quantityField ||
      parseInt(`${fieldWatched}` || '0', 10) === qtyAggregate ||
      autoFilledFields.includes(fieldName) ||
      !quantityFieldChildren.length;
    if (locaitonItemValueIsNotQtyAggregate) return;

    if (!quantityFieldChildren.some((child) => autoFilledFields.includes(getChildQtyFieldName(child, lineItemId))))
      return;
    quantityFieldChildren.forEach((child) => {
      const childFieldName = getChildQtyFieldName(child, lineItemId);
      setValue(childFieldName, '');
      removeAutoFilledField(childFieldName, setAutoFilledFields);
    });
    if (isBomLineItem && autoFilledFields.includes(unassignedRowFieldName)) {
      setValue(unassignedRowFieldName, '');
      removeAutoFilledField(unassignedRowFieldName, setAutoFilledFields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fieldWatched,
    autoFill,
    quantityField,
    isBomLineItem,
    quantityFieldChildren,
    lineItemId,
    fieldName,
    qtyAggregate,
  ]);
};

export default useClearAutofill;
