import React from 'react';

import { useGridApiContext } from '@mui/x-data-grid-pro';
import { useFormContext } from 'react-hook-form';

import MiniSpinner from 'components/MiniSpinner';
import { useBOMbyIdContext } from 'modules/Materials/BillOfMaterialsById/BOMbyIdContext';
import QuantityCell from 'modules/Materials/TakeOff/TakeOffPadTab/EditQuantityModal/components/QuantityCell';

import { QTYNumberInput } from '../../Form';
import UnitQuantityInput from '../../Form/UnitQuantityInput';
import { BOMCatalogTableFields, BOMCatalogTableHeaders, getItemHasParent } from './BOMTableColumnsHelpers';

export const getUnitQuantityColumn = () => ({
  useForm: true,
  headerName: BOMCatalogTableHeaders[BOMCatalogTableFields.UNIT_QUANTITY],
  field: BOMCatalogTableFields.UNIT_QUANTITY,
  flex: 1.05,
  resizable: true,
  editable: true,
  align: 'right',
  renderCell: ({ row, id }) => <UnitQuantityDisplayCell row={row} id={id} />,
  renderEditCell: () => <UnitQuantityEditCell />,
});

const UnitQuantityEditCell = () => {
  const { control, getValues, formState } = useFormContext();

  const itemHasParent = getItemHasParent(getValues('parentLineItemId'));

  if (!itemHasParent) return <></>;
  return (
    <QTYNumberInput
      name="unitQuantity"
      placeholder="Unit Qty"
      control={control}
      autoFocus={false}
      disabled={formState.isSubmitting}
    />
  );
};

const UnitQuantityDisplayCell = ({ row, id }) => {
  const { editingUnitQtyRowData, setEditingUnitQtyRowData, loadingUpdateIds } = useBOMbyIdContext();
  const apiRef = useGridApiContext();

  const isEditing = editingUnitQtyRowData?.lineItemId === id;
  const itemHasParent = getItemHasParent(row?.parentLineItem);

  if (!row?.parentLineItem) return <></>;

  const handleClick = (lineItemId) => {
    if (editingUnitQtyRowData) return;
    if (!itemHasParent) return;

    const rows = Array.from(apiRef?.current?.getRowModels().values());
    setEditingUnitQtyRowData(rows.find((data) => data.lineItemId === lineItemId));
  };

  if (isEditing) return <UnitQuantityInput value={row?.unitQuantity} itemHasParent={itemHasParent} />;

  return (
    <>
      {loadingUpdateIds.includes(id) && <MiniSpinner size="16px" theme="secondary" />}
      <QuantityCell
        value={row?.unitQuantity}
        isEditable
        onClick={(event) => {
          handleClick(id);
          event.stopPropagation();
        }}
      />
    </>
  );
};
