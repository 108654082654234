import { useCallback, useEffect } from 'react';

import { WatchQueryFetchPolicy } from '@apollo/client';

import { Task } from 'graphql/types';
import { useLazyTaskById } from 'hooks-api/queries/taskById/useLazyTaskById';

type UseGetTaskByIdType = {
  loading: boolean;
  task: Task | null;
  called: boolean;
  fetchTaskById: () => void;
};

export default function useGetTaskById(
  taskId: string,
  fetchPolicy: WatchQueryFetchPolicy = 'network-only',
): UseGetTaskByIdType {
  const [getTaskById, { data, loading, called }] = useLazyTaskById({ fetchPolicy });

  const fetchTaskById = useCallback(() => {
    getTaskById({ variables: { params: { taskId } } });
  }, [getTaskById, taskId]);

  useEffect(() => {
    const abortController = new AbortController();
    if (taskId) fetchTaskById();
    return () => abortController.abort();
  }, [fetchTaskById, taskId]);

  return {
    task: data?.taskById || null,
    loading,
    called,
    fetchTaskById,
  };
}
