import React from 'react';

import { InputForesiteField } from '@evolvemep/foresite-components-ui-react';

import { useCatalogSetupContext } from '../CatalogSetupContext';

const maxCharacterCount = 50;

export const CatalogNameInput = ({ control, setError }) => {
  const { dataCatalogs } = useCatalogSetupContext();

  const catalogNames =
    dataCatalogs?.partCatalogs?.map((catalog) => catalog?.partCatalogName?.trim().toLowerCase()) || [];

  const catalogNameInputRules = {
    required: 'Catalog name is required',
    maxLength: {
      value: maxCharacterCount,
      message: `Catalog name must not exceed 50 characters`,
    },
    validate: {
      isDuplicated: (value) => !catalogNames.includes(value.trim().toLowerCase()) || 'Catalog name already exists.',
    },
  };

  return (
    <InputForesiteField
      control={control}
      name="name"
      label="Catalog Name"
      defaultValue=""
      size="small"
      margin="dense"
      color="secondary"
      rules={catalogNameInputRules}
      required
      onError={(fields) => setError(fields.name)}
      inputProps={{ 'data-testid': 'new-catalog-modal-name-input' }}
    />
  );
};
