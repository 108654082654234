import * as React from 'react';

import { useController, useFormContext } from 'react-hook-form';

import { ForesiteAutocomplete } from 'components/FormComponents';

const ManufacturerAutoField = ({ name, options, sx }) => {
  const { control, disabled } = useFormContext();
  const {
    field: { onChange, onBlur, ref, value },
    fieldState: { error },
    formState: { isSubmitting },
  } = useController({
    name,
    control,
    rules: {
      required: true,
      maxLength: { value: 64, message: 'Manufacturer name must be 64 characters or less' },
    },
  });
  return (
    <ForesiteAutocomplete
      freeSolo
      data-testid="manufacturer-autocomplete"
      options={options}
      sx={{ width: '100%', ...sx }}
      onChange={(e, item) => {
        onChange(item);
      }}
      onInputChange={(e, item) => {
        if (typeof value === 'string') {
          onChange(item);
        } else if (typeof value === 'object' && value?.label !== item) {
          onChange(item);
        }
      }}
      onBlur={onBlur}
      name={name}
      value={value}
      disabled={isSubmitting || disabled}
      textFieldProps={{
        inputRef: { ref },
        error: !!error,
        helperText: error?.message,
        size: 'small',
        label: 'Manufacturer',
        maxLength: 64,
      }}
    />
  );
};

export default ManufacturerAutoField;
