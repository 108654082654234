import React from 'react';

import { Typography } from '@mui/material';

const useProjectsTableHelpers = (onProjectClick, headerNames = []) => {
  const columns = [
    {
      field: 'identifier',
      headerName: headerNames[0] || 'Project Id',
      renderCell: ({ row }) => <Typography variant="body2">{row?.identifier ?? ''}</Typography>,
      width: 200,
      maxWidth: 200,
      minWidth: 200,
    },
    {
      field: 'name',
      headerName: headerNames[1] || 'Project/BOM Name',
      flex: 1,
      renderCell: ({ row }) => (
        <Typography
          variant="body2"
          color="secondary"
          className="project-title"
          sx={{
            '&:hover': {
              textDecoration: 'underline',
              cursor: 'pointer',
            },
          }}
          onClick={() => onProjectClick(row)}
        >
          {row?.name ?? ''}
        </Typography>
      ),
    },
  ];

  return { columns };
};

export default useProjectsTableHelpers;
