import React, { useState, useEffect, useCallback } from 'react';

import { useMutation, gql } from '@apollo/client';
import { Button, Stack, Typography, Divider, Box } from '@mui/material';

import { moveTakeoffPadItems as MOVE_ITEMS } from 'graphql/mutations';
import { useTakeOff } from 'modules/Materials/TakeOff/context/TakeOffContext';

import { useHeader } from '../../HeaderContext';
import { useSendToBomModal } from '../SendToBomModalContext';

const ERROR_MESSAGES = [' ', 'Qty exceeds remaining qty', 'Value must only contain whole numbers'];

const ModalFooter = () => {
  const { updateMessageIndex, onCloseModal, messageIndex, itemsToMove, mutationIdentifiers } =
    useSendToBomModal();
  const { updateAnchorElLvl1, updateAnchorElLvl2 } = useHeader();
  const [message, setMessage] = useState(ERROR_MESSAGES[0]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [moveItems] = useMutation(gql(MOVE_ITEMS), {
    refetchQueries: ['TakeoffPads', 'TakeoffPadItems'],
  });

  const updateIsDisabled = useCallback((element) => setIsDisabled(element), []);
  const updateMessage = useCallback((element) => setMessage(element), []);

  const { updateOpenSnackbar } = useTakeOff();

  const logicReducer = itemsToMove.reduce(
    (acc, e) => {
      const logicInput = e.Quantity === 0 ? 1 : e.Quantity;
      if (typeof logicInput === 'number') acc[0] += logicInput;
      acc[1] *= !!logicInput;
      return acc;
    },
    [0, true],
  );

  const validator = Boolean(logicReducer[0] * logicReducer[1]);

  const handleDone = async () => {
    const cleanedItems = itemsToMove.map((e) => ({
      LineItemId: e.LineItemId,
      Quantity: e.Quantity,
    }));
    await moveItems({
      variables: {
        body: { ...mutationIdentifiers, LineItems: cleanedItems },
      },
    });
    onCloseModal();
    updateAnchorElLvl1(null);
    updateAnchorElLvl2(null);
    updateOpenSnackbar(true);
  };

  useEffect(() => {
    updateMessage(ERROR_MESSAGES[messageIndex]);
  }, [messageIndex, updateMessage]);

  useEffect(() => {
    const sum = itemsToMove.reduce((s, e) => (s += Math.abs(e.Quantity)), 0);
    if (sum === 0) updateMessage(ERROR_MESSAGES[0]);

    const indexOfMessage = itemsToMove.reduce((a, e) => (a > e.errorCode ? a : e.errorCode), 0);
    updateMessageIndex(indexOfMessage);
    // eslint-disable-next-line
  }, [JSON.stringify(itemsToMove)]);

  useEffect(() => {
    if (!validator) updateIsDisabled(true);
    if (messageIndex) updateIsDisabled(true);
    if (validator && !messageIndex) updateIsDisabled(false);
  }, [messageIndex, itemsToMove, updateIsDisabled, validator]);

  return (
    <Box sx={{ position: 'absolute', bottom: 0, width: '100%' }}>
      <Divider />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ padding: '8px 8px 8px 24px' }}
      >
        <Typography color="red">{message}</Typography>
        <Stack direction="row">
          <Button color="secondary" variant="text" onClick={onCloseModal}>
            CANCEL
          </Button>
          <Button
            sx={{ marginLeft: '8px' }}
            color="secondary"
            variant="contained"
            disabled={isDisabled}
            onClick={handleDone}
          >
            DONE
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default ModalFooter;
