import { gql, useLazyQuery } from '@apollo/client';

import { workPhases as WORKPHASES } from 'graphql/queries';
import { useProject } from 'modules/Field/LocationsAndWorkPhases/contexts/ProjectContext';

const useWorkPhaseQuery = (take = 50, skip = 0) => {
  const { itemSelectedId } = useProject();
  const [
    fetchWorkPhases,
    { data: workPhasesData, called: workPhasesWereCalled, loading: workPhasesLoading },
  ] = useLazyQuery(gql(WORKPHASES), {
    fetchPolicy: 'cache-and-network',
    variables: {
      query: { projectId: itemSelectedId, take, skip },
    },
  });
  return { fetchWorkPhases, workPhasesData, workPhasesWereCalled, workPhasesLoading };
};

export default useWorkPhaseQuery;
