import React, { useMemo } from 'react';

import { useReactiveVar } from '@apollo/client';
import { Stack, Typography } from '@mui/material';

import { workCellsSelectedToggleOptionVar } from 'apollo/reactiveVars';
import Header from 'components/Header';
import CardFilterChipList from 'components/Popover/FilterPopover/components/CardFilterChipList';

import WorkCellsKanban from '../WorkCellsKanban';
import { WorkCellsListOptions } from './WorkCellsListOptions';
import WorkCellsTable from './WorkCellsTable';
import WorkCellTabs from './WorkCellsTabs';

const WorkCellsList = () => {
  const selectedTab = useReactiveVar(workCellsSelectedToggleOptionVar);

  const isListView = useMemo(() => selectedTab === 'list', [selectedTab]);

  return (
    <>
      <Header
        title={<TitleComponent showOpenClosedTabs={isListView} />}
        option={<WorkCellsListOptions />}
        sx={{ alignItems: 'flex-start' }}
      />
      {isListView && (
        <>
          <CardFilterChipList />
          <WorkCellsTable />
        </>
      )}
      {!isListView && <WorkCellsKanban isDisabledDragAndDrop />}
    </>
  );
};

export default WorkCellsList;

const TitleComponent = ({ showOpenClosedTabs }) => (
  <Stack direction="row" sx={{ mb: showOpenClosedTabs ? 5 : 0 }}>
    <Typography variant="h5">Work Cells</Typography>
    {showOpenClosedTabs && <WorkCellTabs />}
  </Stack>
);
