import React, { useState } from 'react';

import { DragDropContext } from 'react-beautiful-dnd';

import { useDragFunctions } from '../hooks/useDragFunctions';
import { useActiveRow } from './ActiveRowContext';

const DragAndDropContext = React.createContext();

const DragAndDropProvider = ({ children }) => {
  const { activeRows, setActiveRows } = useActiveRow();
  const [draggingWorkPhaseId, setDraggingWorkPhaseId] = useState(null);
  const { onDragEnd } = useDragFunctions();

  const handleDragEnd = (dragInfo) => {
    onDragEnd(dragInfo);
    setDraggingWorkPhaseId(null);
  };

  const onDragStart = (dragInfo) => {
    const { draggableId } = dragInfo;
    const isDraggingASelectedRow = activeRows.some(({ id }) => id === draggableId);

    if (!isDraggingASelectedRow) setActiveRows([{ id: draggableId }]);
    setDraggingWorkPhaseId(draggableId);
  };

  const valueObj = { draggingWorkPhaseId };
  return (
    <DragAndDropContext.Provider value={valueObj}>
      <DragDropContext onDragEnd={handleDragEnd} onDragStart={onDragStart}>
        {children}
      </DragDropContext>
    </DragAndDropContext.Provider>
  );
};

const useDragAndDrop = () => {
  const context = React.useContext(DragAndDropContext);
  if (context === undefined) {
    throw new Error('useDragAndDrop must be used within a DragAndDropContext');
  }
  return context;
};

export { DragAndDropContext, DragAndDropProvider, useDragAndDrop };
