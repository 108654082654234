/* eslint-disable max-lines-per-function */
import { useCallback, useEffect, useMemo, useState } from 'react';

import { gql, useLazyQuery, useQuery, useMutation, useApolloClient } from '@apollo/client';

import { addTask as ADD_TASK, updateTask as UPDATE_TASK } from 'graphql/mutations';
import {
  taskMultiSearch as GET_TASKS_MULTI_SEARCH,
  workCellTasksMultiSearch as WORK_CELL_TASKS_MULTI_SEARCH,
  workRequestItems as WR_ITEMS,
  workOrderItem as WO_ITEMS,
} from 'graphql/queries';
import { DROPPABLE_ROW_IDS } from 'modules/Field/WorkRequests/FieldWorkRequestConstants';
import { useShopTasksContext } from 'modules/Shop/ShopSetup/ShopTasks/ShopTasksContext';

import { useWorkRequestDragDrop } from '../../WorkRequestDragDropProvider';

const useBillOfProcessRowHelpers = (row, workRequestId, workOrderId) => {
  const client = useApolloClient();
  const isWorkOrder = Boolean(row?.workOrderItemId);
  const [opened, setOpened] = useState(false);
  const [updatingWorkRequestItemID, setUpdatingWorkRequestItemID] = useState(null);

  const [, { data: tasksData, loading: loadingTasksData, refetch: refetchTasks }] = useLazyQuery(
    gql(GET_TASKS_MULTI_SEARCH),
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        query: { workRequestItemId: row?.workRequestItemId, orderBy: 'createdOn:asc' },
      },
    },
  );

  const { data: workCellTasks } = useQuery(gql(WORK_CELL_TASKS_MULTI_SEARCH), {
    fetchPolicy: 'cache-and-network',
    variables: {
      query: { workRequestId, take: 999 },
    },
  });

  const [addTask, { loading: loadingAddTask }] = useMutation(gql(ADD_TASK));
  const [updateTask, { loading: loadingUpdateTask }] = useMutation(gql(UPDATE_TASK));

  useEffect(() => {
    if (!row?.workRequestItemId) return;
    refetchTasks();
  }, [refetchTasks, row?.workRequestItemId]);

  const onOpeningBillOfProcess = async () => {
    if (!opened) {
      refetchTasks();
      setOpened(true);
      return;
    }

    setOpened(false);
  };

  const onUpdateTask = async ({ task, values }) => {
    setUpdatingWorkRequestItemID(task.workRequestItemId);

    const queryObject = {
      query: gql(GET_TASKS_MULTI_SEARCH),
      variables: { query: { workRequestItemId: row?.workRequestItemId } },
    };

    if (values)
      await updateTask({
        variables: {
          params: { taskId: task?.taskId },
          body: { taskPredecessorIds: values },
        },
        update(cache, { data: { updateTask } }) {
          const originalData = cache.readQuery(queryObject);
          const isThereData = originalData?.taskMultiSearch && updateTask;

          if (!isThereData) return;

          const updatedData = {
            taskMultiSearch: originalData?.taskMultiSearch?.map((task) => {
              if (task?.taskId === updateTask?.taskId) return updateTask;
              return task;
            }),
          };

          cache.writeQuery({ ...queryObject, data: updatedData });
        },
      });
  };

  const { taskTypes } = useShopTasksContext();
  const { finishedDragging, setFinishedDragging } = useWorkRequestDragDrop();

  const updateCacheItem = useCallback(
    (itemId, hasShopTask) => {
      if (hasShopTask) return;

      const queryType = isWorkOrder ? 'workOrderItem' : 'workRequestItems';
      const idKey = isWorkOrder ? 'workOrderItemId' : 'workRequestItemId';
      const gqlQuery = isWorkOrder ? WO_ITEMS : WR_ITEMS;
      const queryVariablesKey = isWorkOrder ? 'workOrderId' : 'workRequestId';
      const parentId = isWorkOrder ? workOrderId : workRequestId;

      const query = {
        query: gql(gqlQuery),
        variables: { query: { [queryVariablesKey]: parentId } },
      };

      client.cache.updateQuery(query, (data) => ({
        [queryType]: data?.[queryType]?.map((item) => {
          if (item && item[idKey] !== itemId) return item;
          return { ...item, hasShopTask: true };
        }),
      }));
    },
    [client.cache, isWorkOrder, workOrderId, workRequestId],
  );

  useEffect(() => {
    const isDroppableIdSameAsWorkRequestItemId =
      finishedDragging?.destination?.droppableId === `${DROPPABLE_ROW_IDS.BOP}/${row.workRequestItemId}`;
    if (!isDroppableIdSameAsWorkRequestItemId) return;

    const addNewTask = async (task, billOfProcessId) => {
      await addTask({
        variables: {
          body: {
            taskTypeIds: [task.taskTypeId],
            billOfProcessId,
            taskName: task.taskTypeName,
            taskDescription: task.taskTypeDescription,
          },
        },
      });
      updateCacheItem(row?.workOrderItemId || row?.workRequestItemId, row?.hasShopTask);
      refetchTasks();
    };

    setFinishedDragging(null);
    const taskType = taskTypes[finishedDragging?.source?.index];

    if (!taskType) return;
    addNewTask(taskType, row?.billOfProcessId);
  }, [
    addTask,
    row.workRequestItemId,
    row?.billOfProcessId,
    row?.hasShopTask,
    row?.workOrderItemId,
    finishedDragging,
    refetchTasks,
    setFinishedDragging,
    taskTypes,
    updateCacheItem,
  ]);

  const tasks = useMemo(() => {
    if (!tasksData?.taskMultiSearch) return [];

    const getWorkCellTaskByTaskId = (taskId) =>
      workCellTasks?.workCellTasksMultiSearch?.find((WCTask) => WCTask.taskId === taskId);

    return tasksData?.taskMultiSearch.map((task) => ({
      ...task,
      workCellName: getWorkCellTaskByTaskId(task.taskId)?.workCellName,
    }));
  }, [workCellTasks?.workCellTasksMultiSearch, tasksData?.taskMultiSearch]);

  const loading = loadingTasksData || loadingAddTask || loadingUpdateTask;
  return {
    onOpeningBillOfProcess,
    tasks,
    loading,
    opened,
    updatingWorkRequestItemID,
    onUpdateTask,
    setOpened,
  };
};

export default useBillOfProcessRowHelpers;
