import React, { useState, useEffect } from 'react';

import { TextField } from '@mui/material';

import { useSendToBomModal } from '../SendToBomModalContext';
import sanitizeInput from './sanitizeInput';
import validateInput from './validateInput';

const ItemTextField = ({ item }) => {
  const { updateSelectAll, selectAll, itemsToMove, updateItemsToMove } = useSendToBomModal();
  const [value, setValue] = useState('');
  const qtyRem = item.remaining;
  const id = item.LineItemId;

  useEffect(() => {
    const auxList = [...itemsToMove];
    const newItem = auxList.find((e) => e.LineItemId === id);
    if (itemsToMove && selectAll.active) {
      newItem.Quantity = qtyRem;
      newItem.errorCode = 0;
      updateItemsToMove(auxList);
    }
    if (itemsToMove && !selectAll.active && selectAll.modifiedBy === 'checkbox') {
      newItem.Quantity = '';
      newItem.errorCode = 0;
      updateItemsToMove(auxList);
    }
    setValue(newItem.Quantity);
    // eslint-disable-next-line
  }, [JSON.stringify(selectAll)]);

  const handleChange = (e) => {
    const userInput = sanitizeInput(e.target.value);
    updateSelectAll({ active: false, modifiedBy: 'textField' });
    setValue(userInput);
    if (itemsToMove) {
      const auxList = [...itemsToMove];
      const renderedItem = auxList.find((e) => e.LineItemId === id);
      renderedItem.Quantity = userInput;
      renderedItem.errorCode = validateInput(userInput, qtyRem);
      updateItemsToMove(auxList);
    }
  };

  return (
    <TextField
      onChange={(e) => handleChange(e, item)}
      value={value}
      variant="outlined"
      placeholder="Qty"
      color="secondary"
      sx={{
        width: '72px',
        '& .MuiOutlinedInput-input': { height: '24px', padding: '0 12px' },
        '& .Mui-focused': { top: 0 },
      }}
    />
  );
};

export default ItemTextField;
