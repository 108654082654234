import { DOCUMENT_STATUS_TYPE, FILE_FORMATS } from 'constants/globalConstants';

const useStatusGraphic = (forgeViewer, activeDocument, typeFileFormat) => {
  // loading
  const convertingForgeViewer =
    (typeFileFormat === FILE_FORMATS.ANOTHER_FILE && !forgeViewer) ||
    forgeViewer?.documentStatusType?.documentStatusTypeId ===
      DOCUMENT_STATUS_TYPE.CONVERTING_DOCUMENT;

  const convertingDocument =
    (typeFileFormat === FILE_FORMATS.PDF && !activeDocument) ||
    activeDocument?.documentStatusTypeId === DOCUMENT_STATUS_TYPE.CONVERTING_DOCUMENT;

  // error
  const errorForgeViewer =
    typeFileFormat === FILE_FORMATS.ANOTHER_FILE &&
    forgeViewer?.documentStatusType?.documentStatusTypeId ===
      DOCUMENT_STATUS_TYPE.CONVERTING_DOCUMENT_FAILS;

  const errorDocument =
    typeFileFormat === FILE_FORMATS.PDF &&
    activeDocument?.documentStatusTypeId === DOCUMENT_STATUS_TYPE.CONVERTING_DOCUMENT_FAILS;

  return {
    loading:
      typeFileFormat === FILE_FORMATS.PDF ? false : convertingForgeViewer || convertingDocument,
    error: errorForgeViewer || errorDocument,
  };
};

export default useStatusGraphic;
