export const ROW_TYPES = {
  SHOP_CONFIGURATION: 'Shop Configuration',
  WORK_CELL: 'Work Cell',
  MACHINE: 'Machine',
  TASK_TYPES: 'Task Types',
};

export const CONTAINER_TYPES = {
  WORK_CELL_TASKS: `${ROW_TYPES.WORK_CELL}/${ROW_TYPES.TASK_TYPES}`,
  WORK_CELL_MACHINES: `${ROW_TYPES.WORK_CELL}/${ROW_TYPES.MACHINE}`,
  MACHINES_TASKS: `${ROW_TYPES.MACHINE}/${ROW_TYPES.TASK_TYPES}`,
  MACHINES: ROW_TYPES.MACHINE,
};

export const NEW_WORKCELL_ID = 'NEW_WORKCELL';

export const EMPTY_WORKCELL = {
  id: NEW_WORKCELL_ID,
  workCellId: NEW_WORKCELL_ID,
  workCellName: 'Work Cell',
  type: ROW_TYPES.WORK_CELL,
  hasTaskTypes: false,
  hasMachines: false,
};
