import { gql, useQuery } from '@apollo/client';

import { workRequestItemType as WORK_REQUEST_ITEM_TYPE } from 'graphql/queries';

const useWorkRequestItemTypesAPI = (fetchPolicy = 'cache-and-network') => {
  const { data: workRequestItemType, loading: loadingWorkRequestItemType } = useQuery(gql(WORK_REQUEST_ITEM_TYPE), {
    fetchPolicy,
  });

  return {
    workRequestItemType: workRequestItemType?.workRequestItemType,
    loadingWorkRequestItemType,
  };
};

export default useWorkRequestItemTypesAPI;
