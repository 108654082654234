import React, { useState, useMemo } from 'react';

import { Box } from '@mui/material';

import { Custom } from 'components/Icons';
import EditFacilityModal from 'modules/Admin/Facilities/EditFacilityModal/EditFacilityModal';
import { FacilityProvider } from 'modules/Admin/Facilities/FacilityContext';

import { formatFacility, useFacilitiesProjects } from '../../../app/FacilitiesProjectsContext';
import SelectFacilityMessage from '../WorkOrders/WorkOrdersList/SelectFacilityMessage';

const NoShopDepartmentMessage = ({ selectedItem }) => {
  const { setSelectedItem } = useFacilitiesProjects();
  const [openEdit, setOpenEdit] = useState(false);

  const openEditFacilityModal = () => setOpenEdit(true);
  const closeEditModal = (updatedFacility) => {
    setOpenEdit(false);
    setSelectedItem(formatFacility(updatedFacility));
  };

  const mappedFacility = useMemo(
    () => ({
      address: selectedItem.address,
      departments: selectedItem.departments,
      facilityId: selectedItem.id,
      facilityName: selectedItem.label,
    }),
    [selectedItem],
  );

  return (
    <>
      <SelectFacilityMessage
        message={
          <>
            This facility does not currently have a Shop.
            <Box
              onClick={openEditFacilityModal}
              sx={{
                color: 'other.links',
                display: 'inline-block',
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              Click here
            </Box>{' '}
            to add a Shop to this Facility.
          </>
        }
        icon={<Custom.ShopProperties />}
        sx={{ width: '400px' }}
      />
      <FacilityProvider value={mappedFacility}>
        <EditFacilityModal open={openEdit} onClose={closeEditModal} />
      </FacilityProvider>
    </>
  );
};

export default NoShopDepartmentMessage;
