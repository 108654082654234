/* eslint-disable max-lines-per-function */
import { useCallback, useEffect, useMemo } from 'react';

import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { parseISO, format } from 'date-fns';
import { useSnackbar } from 'notistack';

import { useUser } from 'app/UserContext';
import { Custom } from 'components/Icons';
import Table from 'components/Table/Table';
import { VARIANT_SUCCESS, VARIANT_ERROR } from 'constants/snackbarConstants';
import { isNotNil } from 'helpers/isNotNil';
import { useWrappedPaginatedGet } from 'hooks-api/useWrappedApiCall';
import type { ProductPool } from 'types/types-api';

const licensesMapper = (licenses: ProductPool[] = []) =>
  licenses.map(
    ({
      productId,
      product,
      poolQuantity,
      poolAvailableQuantity,
      renewalDate,
      startDate,
      desktopLicenseId,
      status,
    }) => ({
      id: productId,
      product: product.productName,
      total: poolQuantity,
      assigned: poolQuantity - poolAvailableQuantity,
      available: poolAvailableQuantity,
      expirationDate: renewalDate ? format(parseISO(renewalDate), 'MM/dd/yyyy') : '',
      startDate,
      status,
      desktopLicenseId,
    }),
  );
type LicenseType = ReturnType<typeof licensesMapper>[number];

const LICENSES_LIMIT = 20;

function LicensesTable() {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useUser();

  const { fetchNextPage, data: licensesRes } = useWrappedPaginatedGet<ProductPool>('admin/productpool', {
    lazy: true,
    perPage: LICENSES_LIMIT,
  });

  useEffect(() => {
    if (isNotNil(user)) {
      const { companyId } = user;
      fetchNextPage({ params: { companyId } });
    }
  }, [user, fetchNextPage]);

  const licenses = useMemo(() => licensesMapper(licensesRes), [licensesRes]);

  const handleCopyLicenseKey = useCallback(
    async (desktopLicenseId: string) => {
      if (!navigator.clipboard) return;

      try {
        await navigator.clipboard.writeText(desktopLicenseId);
        enqueueSnackbar('Copied!', VARIANT_SUCCESS);
      } catch (err) {
        enqueueSnackbar('Failed!', VARIANT_ERROR);
      }
    },
    [enqueueSnackbar],
  );

  // Not the best way to type this,
  // but <Table /> isn't typed so this is a quick solution
  type HeaderRender = {
    item: LicenseType;
  };

  const headers = useMemo(
    () => [
      {
        label: 'Products',
        key: 'product',
        style: { width: '20%' },
        render: ({ item }: HeaderRender) => <Typography variant="body2">{item.product}</Typography>,
      },
      {
        label: 'Total',
        key: 'total',
        style: { width: '12%' },
        render: ({ item }: HeaderRender) => <Typography variant="body2">{item.total}</Typography>,
      },
      {
        label: 'Assigned',
        key: 'assigned',
        style: { width: '12%' },
        render: ({ item }: HeaderRender) => <Typography variant="body2">{item.assigned}</Typography>,
      },
      {
        label: 'Available',
        key: 'available',
        style: { width: '12%' },
        render: ({ item }: HeaderRender) => <Typography variant="body2">{item.available}</Typography>,
      },
      {
        label: 'Expiration Date',
        key: 'expirationDate',
        style: { width: '12%' },
        render: ({ item }: HeaderRender) => <Typography variant="body2">{item.expirationDate}</Typography>,
      },
      {
        label: 'Status',
        key: 'status',
        style: { width: '8%' },
        render: ({ item }: HeaderRender) => (
          <Typography
            variant="body2"
            color={
              {
                Active: 'green',
                Expired: 'red',
                Pending: 'orange',
              }[item.status]
            }
          >
            {item.status}
          </Typography>
        ),
      },
      {
        label: 'Desktop License',
        key: 'desktopLicenseId',
        style: { width: '30%' },
        render: ({ item: { desktopLicenseId } }: HeaderRender) =>
          isNotNil(desktopLicenseId) ? (
            <Stack direction="row" alignItems="center">
              <IconButton aria-label="copy" size="small" onClick={() => handleCopyLicenseKey(desktopLicenseId)}>
                <Custom.ContentCopy fontSize="small" />
              </IconButton>
              <Typography variant="body2" sx={{ ml: 1 }}>
                {desktopLicenseId}
              </Typography>
            </Stack>
          ) : (
            <Typography variant="body2"> — </Typography>
          ),
      },
    ],
    [handleCopyLicenseKey],
  );

  const handleFetchMoreLicenses = useCallback(
    (skip?: number) => {
      if (isNotNil(user)) {
        const { companyId } = user;
        fetchNextPage({ params: { companyId } }, skip);
      }
    },
    [user, fetchNextPage],
  );

  return (
    // @ts-ignore Table has missing attributes
    <Table
      id="id"
      headers={headers}
      data={licenses}
      infiniteScroll
      onFetchMore={handleFetchMoreLicenses}
      selectCell={false}
    />
  );
}

export default LicensesTable;
