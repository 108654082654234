import React from 'react';

import { Box } from '@mui/material';
import { GridRow } from '@mui/x-data-grid-pro';

import ClickAwayRow from '../ClickAwayRow';
import RowFormProvider from '../RowFormProvider';

const EditOrCreateRow = ({ isEditingRow, rowData }) => (
  <RowFormProvider isEditingRow={isEditingRow} rowData={rowData}>
    <ClickAwayRow isEditingRow={isEditingRow} rowData={rowData}>
      <Box
        sx={{
          '& .MuiDataGrid-cell': {
            maxHeight: 'none !important',
            minHeight: 'auto !important',
            paddingTop: '3.5px !important',
            paddingBottom: '3.5px !important',
            alignItems: 'flex-start',
          },
          '& .MuiDataGrid-row': {
            maxHeight: 'none !important',
            minHeight: 'auto !important',
          },
          '& .MuiCheckbox-root': {
            display: 'none',
          },
          '& .MuiInputBase-input': {
            fontSize: '14px',
            lineHeight: '24px',
            fontWeight: '400',
            height: '20px',
          },
          '& .MuiSelect-select': {
            minHeight: '20px !important',
          },
          '& .MuiFormLabel-root': {
            fontSize: '14px',
            lineHeight: '20px',
          },
          '& .MuiSelect-select span': {
            lineHeight: '20px',
          },
        }}
      >
        <GridRow {...rowData} />
      </Box>
    </ClickAwayRow>
  </RowFormProvider>
);

export default EditOrCreateRow;
