import React from 'react';

import { useNavigate } from 'react-router-dom';

import { useModule } from 'app/Modules/ModuleContext';
import Divider from 'components/Divider';
import useSetupModule from 'hooks/useSetupModule';

import NavItem from './NavItem';
import NestedNavItem from './NestedNavItem';

const NavigationSet = ({ navigationSet, dividerAtTheBottom }) => {
  const { loading } = useModule();
  const { currentModule } = useSetupModule();
  const navigate = useNavigate();
  const navigateTo = (url) => navigate(`${currentModule().toLowerCase()}/${url}`);

  return (
    <>
      {navigationSet.isMenu ? (
        <NestedNavItem item={navigationSet} key={navigationSet.navigationSetId}>
          {!loading &&
            navigationSet?.navigationSetItems.map(({ navigationItem }) => {
              if (navigationItem.navigationItemName === 'BOP Calculator') return null;
              return (
                <NavItem
                  item={navigationItem}
                  key={navigationItem.navigationItemId}
                  onClick={() => navigateTo(navigationItem.routePath)}
                />
              );
            })}
        </NestedNavItem>
      ) : (
        navigationSet.navigationSetItems.map(({ navigationItem }) => (
          <NavItem
            item={navigationItem}
            key={navigationItem.navigationItemId}
            onClick={() => navigateTo(navigationItem.routePath)}
          />
        ))
      )}
      {dividerAtTheBottom && <Divider sx={{ margin: '8px' }} />}
    </>
  );
};

export default NavigationSet;
