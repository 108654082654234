import React, { useState } from 'react';

import { ForesiteTextField } from '@evolvemep/foresite-components-ui-react';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useController } from 'react-hook-form';

import { getInputStyles } from './PropertiesOverlayQtyInputStyles';

const INPUT_NAME = 'quantity';

const PropertiesOverlayQtyInput = ({ title, control, onSubmit, defaultValue }: any) => {
  const { palette } = useTheme();
  const [isFocused, setIsFocused] = useState(false);
  const {
    field: { onChange, onBlur, value: formValue },
    fieldState: { error },
  } = useController({ name: INPUT_NAME, control, defaultValue });

  const handleSubmit = () => {
    onBlur();
    onSubmit();
    setIsFocused(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const sanitizedValue = inputValue.replace(/[^0-9]/g, '');

    if (onChange) {
      onChange(sanitizedValue);
    }
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      gap="8px"
      sx={{
        py: '8px',
        borderBottom: '1px solid rgba(204, 204, 204, 1)',
        '&:last-child': {
          border: 'none',
        },
      }}
      onClick={() => setIsFocused(true)}
    >
      <Typography variant="body2" sx={{ color: '#fff' }}>
        {title}
      </Typography>

      <ForesiteTextField
        size="extra-small"
        value={formValue}
        defaultValue={defaultValue}
        name={INPUT_NAME}
        type={isFocused ? 'number' : 'text'}
        onBlur={handleSubmit}
        onChange={handleChange}
        onFocus={() => setIsFocused(true)}
        helperText={error?.message || ''}
        error={Boolean(error)}
        darkTheme
        inputProps={{
          min: 0,
        }}
        sx={getInputStyles(isFocused, palette)}
        maxLength={null}
        label=""
        disabled={false}
        margin={undefined}
        fullWidth={false}
      />
    </Stack>
  );
};

export default PropertiesOverlayQtyInput;
