import React, { useState, useCallback, useContext, createContext, useEffect, useMemo } from 'react';

import { gql, useLazyQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { projectById as PROJECT_BY_ID } from 'graphql/queries';
import { useBillOfMaterialsByIdAPI } from 'hooks-api/useBillOfMaterialsByIdAPI';
import useCache from 'hooks/useCache';
import { useProject } from 'modules/Field/LocationsAndWorkPhases/contexts/ProjectContext';

import { useBOMItemsFilters } from '../features/filters/useBOMItemsFilters';
import {
  getLocationString,
  getLocationPhasesString,
  getWorkPhasesString,
  getScopePackagesString,
} from './getIdsForFilters';
import { useHandleDecisionAdded } from './hooks/useHandleDecisionAdded';

export const BOMbyIdContext = createContext();

// eslint-disable-next-line max-lines-per-function
export const BOMbyIdProvider = ({ children }) => {
  const { id: urlId } = useParams();
  const { itemSelectedId: id } = useProject();
  const { selectedItem } = useFacilitiesProjects();
  const { deleteFromCacheByQuery } = useCache();
  const [editingQuantityRowData, setEditingQuantityRowData] = useState(null);
  const [editingUnitQtyRowData, setEditingUnitQtyRowData] = useState(null);

  const [selectedPadItems, setSelectedPadItems] = useState([]);
  const [chipList, setChipList] = useState([]);
  const [assignModeOn, setAssignModeOn] = useState(false);
  const [loadingUpdateIds, setLoadingUpdateIds] = useState([]);

  const filters = useBOMItemsFilters();

  const [parameters, setParameters] = useState({
    projectId: selectedItem?.type === 'FACILITY' ? urlId : id,
    orderby: 'name',
    locationIds: '',
    workPhaseIds: '',
    locationsWithWorkPhases: '',
    scopePackageIds: '',
    externalSourceImportDescription: '%5B%5D',
    rules: '%5B%5D',
  });

  const updateChipList = useCallback((element) => setChipList(element), []);
  const decisionsStatesAndCallbacks = useHandleDecisionAdded();
  const { lineItemDecision } = decisionsStatesAndCallbacks;

  const forceBomItemsRefetch = chipList.length > 0 && !assignModeOn;

  const {
    data,
    metadata: { pagination, loading, addWriteInItemLoading },
    callbacks: { onAddWriteInItems: onAddWriteInItemsCallback, onAddBOMItem, refetchItems },
    BOMFilters,
    lazyLoad,
  } = useBillOfMaterialsByIdAPI(
    {
      ...parameters,
      ...filters,
    },
    forceBomItemsRefetch,
  );

  const refetchBOMItems = (forceRefetch = false) => {
    if (lineItemDecision && !forceRefetch) return;

    refetchItems();
  };

  const onAddWriteInItems = async (...params) => {
    const response = await onAddWriteInItemsCallback(...params);
    const itemAdded = (response?.data?.addBillOfMaterialsWriteInItem ?? [])[0];

    if (itemAdded) {
      const ern = `ern:moab:bomitem:${itemAdded.lineItemId}`;
      deleteFromCacheByQuery('billOfMaterialLineItemByLBS', { ern });
    }

    return response;
  };

  const findByKeyword = (input = '') =>
    lazyLoad({
      ...parameters,
      ...filters,
      lineItemName: input ? `like:${input}` : undefined,
    });

  const result = useMemo(() => data || [], [data]);

  const onChangeSortKey = (sortKey) => setParameters((prevState) => ({ ...prevState, orderby: sortKey }));

  const [fetchProject, { data: project }] = useLazyQuery(gql(PROJECT_BY_ID), { fetchPolicy: 'cache-and-network' });

  const bomLoading = useMemo(() => (result ?? [])?.length === 0 && !!loading, [result, loading]);

  const parametersAndFilters = useMemo(() => ({ ...parameters, ...filters, take: 50 }), [parameters, filters]);

  useEffect(() => {
    const filterIds = {
      projectId: id,
      locationIds: getLocationString(chipList),
      workPhaseIds: getWorkPhasesString(chipList),
      locationsWithWorkPhases: getLocationPhasesString(chipList),
      scopePackageIds: getScopePackagesString(chipList),
    };
    setParameters((prev) => ({ ...prev, ...filterIds }));
  }, [chipList, id]);

  useEffect(() => {
    if (!id && !urlId) return;
    fetchProject({ variables: { params: { projectId: id || urlId } } });
  }, [fetchProject, id, urlId]);

  useEffect(() => {
    if (data.length) {
      const auxList = selectedPadItems.map((item) => {
        const newItem = data?.find((bomItem) => bomItem.lineItemId === item.lineItemId);
        if (newItem) return { ...item, remainingQuantity: newItem?.remainingQuantity, quantity: newItem?.quantity };
        return { ...item };
      });
      setSelectedPadItems(auxList);
    }
    // * selectedPadItems not needed as dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <BOMbyIdContext.Provider
      value={{
        selectedPadItems,
        setSelectedPadItems,
        chipList,
        updateChipList,
        pagination,
        onAddWriteInItems,
        addWriteInItemLoading,
        onAddBOMItem,
        refetchItems: refetchBOMItems,
        result,
        findByKeyword,
        onChangeSortKey,
        project,
        bomLoading,
        BOMFilters,
        decisionsStatesAndCallbacks,
        assignModeOn,
        setAssignModeOn,
        editingQuantityRowData,
        setEditingQuantityRowData,
        parameters: parametersAndFilters,
        editingUnitQtyRowData,
        setEditingUnitQtyRowData,
        loadingUpdateIds,
        setLoadingUpdateIds,
      }}
    >
      {children}
    </BOMbyIdContext.Provider>
  );
};

export const useBOMbyIdContext = () => {
  const context = useContext(BOMbyIdContext);
  if (!context) throw new Error('useBOMbyId context must be used within a BOMbyId provider');
  return context;
};
