import { makeVar } from '@apollo/client';

import { Message } from 'types/Message';

type ToggleButtonOptions = 'list' | 'kanban' | 'calendar';

export const draftPartReactiveVar = makeVar(null);
export const selectedMessageVar = makeVar<Message | null>(null);
export const shouldBlockNavigation = makeVar<boolean>(false);
export const selectedWorkCellIdOnQueueVar = makeVar<string | null>(null);
export const workOrdersSelectedToggleOptionVar = makeVar<ToggleButtonOptions>('list');
export const workCellsSelectedToggleOptionVar = makeVar<ToggleButtonOptions>('list');
