import React from 'react';

import { InputForesiteField } from '@evolvemep/foresite-components-ui-react';
import { useFormContext } from 'react-hook-form';

import AddressForm from './AddressForm';

const FacilityInfoForm = ({ facilityName, address }) => {
  const { control } = useFormContext();

  return (
    <>
      <InputForesiteField
        name="facilityName"
        label="Name of Facility"
        size="small"
        defaultValue={facilityName}
        control={control}
        rules={{ required: true }}
        data-cy="facility-name"
        data-testid="facility-name-input"
      />
      <AddressForm address={address} />
    </>
  );
};
export default FacilityInfoForm;
