import React from 'react';

import { Link } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useNavigate, useLocation } from 'react-router-dom';

import MiniSpinner from 'components/MiniSpinner';
import { isNotNil } from 'helpers/isNotNil';
import useSetupModule from 'hooks/useSetupModule';

import { useTaskViewerContext } from '../../TaskViewerContext';
import Status from './Status';

const getStatus = (curStatus) => {
  if (curStatus === 'Completed') return 'Completed';
  if (curStatus === 'Not Started') return 'Not Started';
  return 'In Progress';
};

const Header = () => {
  const { taskProperty, taskStatus, taskName, loadingTask, needBy } = useTaskViewerContext();
  const badgedLabel = getStatus(taskStatus);

  return (
    <Stack direction="row" sx={{ mt: 0.5 }}>
      <Breadcrumbs loadingTask={loadingTask} taskName={taskName} />
      {isNotNil(taskStatus) && <Status date={needBy} variant={badgedLabel} />}
      {loadingTask ? (
        <MiniSpinner sx={{ width: 50 }} size="16px" height="24px" />
      ) : (
        <Typography variant="subtitle1" style={{ marginLeft: 30 }}>
          {taskProperty?.workRequestItemName}
        </Typography>
      )}
    </Stack>
  );
};

export default Header;

const Breadcrumbs = ({ loadingTask, taskName }) => {
  const { currentModule } = useSetupModule();
  const navigate = useNavigate();
  const handleClick = () => navigate(`/${currentModule()}/work-cell-queue`);
  const location = useLocation();
  const title = location.state?.from || 'Work Cell Queue';
  return (
    <Stack direction="row" sx={{ gap: '7px' }}>
      <Link
        onClick={handleClick}
        variant="subtitle1"
        sx={{ color: 'surface.lightSurface.secondary' }}
        underline="none"
        component="button"
      >
        {title}
      </Link>
      {!loadingTask && taskName && (
        <Typography variant="subtitle1" sx={{ color: 'surface.lightSurface.secondary' }}>
          /
        </Typography>
      )}
      {!loadingTask && taskName && (
        <Typography variant="subtitle1" data-testid="task-name">
          {taskName}
        </Typography>
      )}
    </Stack>
  );
};
