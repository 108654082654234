import React from 'react';

import Button from '@mui/material/Button';

const BaseButton = ({
  color = 'secondary',
  onClick = () => {},
  buttonText = '',
  disabled,
  sxButton,
  sxTypography,
  children,

  ...others
}) => (
  <>
    <Button
      data-testid="BaseButton-data-testid"
      disabled={disabled}
      onClick={onClick}
      sx={{
        width: 113,
        height: 32,
        ...sxButton,
        fontWeight: 600,
        letterSpacing: '0.4px',
        ...sxTypography,
      }}
      color={color}
      {...others}
    >
      {buttonText}
    </Button>
    {children}
  </>
);

export default BaseButton;
