import React, { useContext, useEffect } from 'react';

import { Stack, useTheme } from '@mui/material';
import { GridRow, useGridApiContext } from '@mui/x-data-grid-pro';
import { Droppable } from 'react-beautiful-dnd';

import { useDrawingRegisterDragDrop } from 'modules/Design/DrawingRegister/DrawingRegisterDragDropProvider';
import { DROPPABLE_ROW_IDS } from 'modules/Field/WorkRequests/FieldWorkRequestConstants';
import {
  WorkRequestItemsContext,
  useWorkRequestItemsContext,
} from 'modules/Field/WorkRequests/WorkRequest/WorkRequestItemsContext';
import { ROW_TYPES } from 'modules/Shop/ShopSetup/WorkCellSetup/WorkCellsAndMachines/constants/constants';
import { WorkOrderItemsContext } from 'modules/Shop/WorkOrders/WorkOrder/context/WorkOrderItemsContext';

const RowComponentDroppable = ({ rowId, ...params }) => {
  const theme = useTheme();
  const apiRef = useGridApiContext();
  const { isMappingDocuments, finishedDragging, setFinishedDragging } = useDrawingRegisterDragDrop();
  const { workRequestStatus } = useWorkRequestItemsContext();

  const workRequestItemsContext = useContext(WorkRequestItemsContext);
  const workOrderItemsContext = useContext(WorkOrderItemsContext);

  const { isDrawingWRItem } = workRequestItemsContext || workOrderItemsContext;

  useEffect(() => {
    const destinationRowId = finishedDragging?.destination?.droppableId?.split('/')[1];
    if (apiRef.current.getExpandedDetailPanels().includes(rowId)) return;
    if (rowId === destinationRowId || isDrawingWRItem) {
      apiRef.current.toggleDetailPanel(rowId);
      setFinishedDragging(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishedDragging, isDrawingWRItem]);

  const isDroppableDisabled = isMappingDocuments || workRequestStatus === 'Completed';
  const droppableId = `${DROPPABLE_ROW_IDS.BOP}/${rowId}`;

  return (
    <Droppable
      droppableId={droppableId}
      type={ROW_TYPES.TASK_TYPES}
      isDropDisabled={isDroppableDisabled}
      direction="horizontal"
    >
      {(provided, snapshot) => (
        <Stack
          direction="row"
          key={rowId}
          ref={provided.innerRef}
          {...provided.droppableProps}
          sx={{
            ' .tasks-row': {
              boxSizing: 'border-box',
            },
            '& .tasks-row:hover': {
              borderTop: snapshot.isDraggingOver ? `1px solid ${theme.palette?.secondary?.main}` : 'none',
              borderBottom: snapshot.isDraggingOver ? `1px solid ${theme.palette?.secondary?.main} !important` : 'none',
              zIndex: snapshot.isDraggingOver ? 99999 : 0,
              background: theme.palette?.action?.hover,
            },
          }}
        >
          <GridRow rowId={rowId} {...params} data-testid={`task_row_testid${rowId}`} />
        </Stack>
      )}
    </Droppable>
  );
};

export default RowComponentDroppable;
