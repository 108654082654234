import React, { useCallback, useMemo, useState } from 'react';

import { GridRowModes, useGridApiRef } from '@mui/x-data-grid-pro';

const TasksTableDataContext = React.createContext();
const TasksTableApiContext = React.createContext();

const stopEditingOtherTasks = (tasks, apiRef, id) => {
  if (!tasks.length) return;
  tasks?.forEach(({ taskId }) => {
    if (taskId === id) return;
    const taskRowMode = apiRef.current.getRowMode(taskId);
    if (taskRowMode === GridRowModes.Edit) apiRef.current.stopRowEditMode({ id: taskId });
  });
};

const startEditingCurrentRow = (canEdit, isEditingPredecessors, tasks, apiRef, id) => {
  stopEditingOtherTasks(tasks, apiRef, id);
  if (!canEdit || isEditingPredecessors) return;
  const currentGridRowModeEqualsView = apiRef.current.getRowMode(id) === GridRowModes.View;
  if (currentGridRowModeEqualsView) apiRef.current.startRowEditMode({ id });
};

const stopEditingRowIfNotOpened = (
  hoveredRowId,
  apiRef,
  isEditingPredecessors,
  setIsEditingPredecessors,
) => {
  const currentGridRowModeEqualsEdit =
    hoveredRowId &&
    apiRef.current.getRowMode(hoveredRowId) === GridRowModes.Edit &&
    !isEditingPredecessors;
  if (currentGridRowModeEqualsEdit) {
    apiRef.current.stopRowEditMode({ id: hoveredRowId });
    setIsEditingPredecessors(false);
  }
};

const TasksTableProvider = ({ canEdit, tasks, onSavePredecessors = () => {}, children }) => {
  const [isEditingPredecessors, setIsEditingPredecessors] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [hoveredRowId, setHoveredRowId] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [deleteModalOpened, setDeleteModalOpened] = useState(false);
  const [deleteModalLoading, setDeleteModalLoading] = useState(false);

  const apiRef = useGridApiRef();

  const onRowMouseEnter = useCallback(
    (event) => {
      if (isEditingPredecessors) return;
      stopEditingRowIfNotOpened(
        hoveredRowId,
        apiRef,
        isEditingPredecessors,
        setIsEditingPredecessors,
      );
      const id = event.currentTarget?.getAttribute('data-id');
      setHoveredRowId(id);
      startEditingCurrentRow(canEdit, isEditingPredecessors, tasks, apiRef, id);
    },
    [apiRef, canEdit, hoveredRowId, isEditingPredecessors, tasks],
  );

  const onRowMouseLeave = useCallback(
    (event) => {
      const id = event.currentTarget?.getAttribute('data-id');
      setHoveredRowId(null);
      if (!isEditingPredecessors) stopEditingOtherTasks(tasks, apiRef, id);
    },
    [apiRef, isEditingPredecessors, tasks],
  );

  const savePredecessors = useCallback(
    (task) => {
      onSavePredecessors({ task, values: selectedOptions });
      setIsEditingPredecessors(false);
      setSelectedOptions(null);
    },
    [onSavePredecessors, selectedOptions],
  );

  const tasksTableDataContext = useMemo(
    () => ({
      isEditingPredecessors,
      hoveredRowId,
      selectedOptions,
      selectedItem,
      deleteModalOpened,
      deleteModalLoading,
    }),
    [
      hoveredRowId,
      isEditingPredecessors,
      selectedOptions,
      selectedItem,
      deleteModalOpened,
      deleteModalLoading,
    ],
  );

  const stopEditingAllTasks = useCallback(() => {
    stopEditingOtherTasks(tasks, apiRef, null);
  }, [apiRef, tasks]);

  const tasksTableApiContext = useMemo(
    () => ({
      setIsEditingPredecessors,
      setHoveredRowId,
      apiRef,
      onRowMouseEnter,
      onRowMouseLeave,
      setSelectedOptions,
      savePredecessors,
      stopEditingAllTasks,
      setSelectedItem,
      setDeleteModalOpened,
      setDeleteModalLoading,
    }),
    [apiRef, onRowMouseEnter, onRowMouseLeave, savePredecessors, stopEditingAllTasks],
  );

  return (
    <TasksTableDataContext.Provider value={tasksTableDataContext}>
      <TasksTableApiContext.Provider value={tasksTableApiContext}>
        {children}
      </TasksTableApiContext.Provider>
    </TasksTableDataContext.Provider>
  );
};

const useTasksTableDataContext = () => {
  const context = React.useContext(TasksTableDataContext);
  if (context === undefined) {
    throw new Error('useTasksTableDataContext must be used within a TasksTableDataContext');
  }
  return context;
};

const useTasksTableApiContext = () => {
  const context = React.useContext(TasksTableApiContext);
  if (context === undefined) {
    throw new Error('useTasksTableApiContext must be used within a TasksTableDataContext');
  }
  return context;
};

export {
  TasksTableDataContext,
  TasksTableApiContext,
  TasksTableProvider,
  useTasksTableDataContext,
  useTasksTableApiContext,
};
