import React from 'react';

import { useMutation, gql } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { Modal, CancelButton, CreateButton } from 'components';
import { deleteProjectUsers as DELETE_PROJECT_USERS } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

import { useProjectMembersContext } from '../ProjectMembersContext';

const ProjectMembersDeleteModal = ({ projectUserIds, open, onClose }) => {
  const { handleResponse } = useGraphqlResponseHandler();
  const [deleteProjectUsers, { loading }] = useMutation(gql(DELETE_PROJECT_USERS), {
    refetchQueries: ['ProjectUsers'],
  });
  const { lazyLoad } = useProjectMembersContext();

  const submit = async () => {
    handleResponse(deleteProjectUsers, {
      variables: {
        body: {
          projectUserIds,
        },
      },
    });
    lazyLoad();
    onClose();
  };

  return (
    <Modal
      disableModalOutsideClick
      disableCloseButton={loading}
      open={open}
      onClose={onClose}
      title="Confirm Delete"
      reducePadding
      maxWidth="xs"
      footer={
        <>
          {loading && <CircularProgress />}
          <CancelButton onClick={onClose} />
          <CreateButton color="error" buttonText="Delete" disabled={loading} onClick={submit} />
        </>
      }
    >
      <Typography variant="body1" mb="12px" maxWidth="300px">
        Are you sure you want to delete the selected members?
      </Typography>
    </Modal>
  );
};

export default ProjectMembersDeleteModal;
