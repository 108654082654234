import React from 'react';

import { Stack, Typography } from '@mui/material';

const TableForgeContainer = ({ document, children }) => (
  <Stack
    sx={{
      height: '100%',
      border: '1px solid #0000001A',
      borderRadius: '4px',
    }}
  >
    <Stack sx={{ height: 32, borderBottom: '1px solid #00000033' }}>
      {document && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          sx={{ height: 'inherit', ml: 1 }}
        >
          <Typography
            variant="body2"
            sx={{
              fontWeight: 500,
              color: 'surface.lightSurface.primary',
              letterSpacing: '0.15px',
              lineHeight: '24px',
            }}
          >
            {document?.documentName}
          </Typography>
        </Stack>
      )}
    </Stack>
    {children}
  </Stack>
);

export default TableForgeContainer;
