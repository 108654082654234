import React from 'react';

import {
  PickIcon,
  PickText,
} from 'modules/Materials/commons/FilterPopoverContent/Breakdown/HelperElements';
import { surfaceColors } from 'styles/theme/colors';

const RowInfo = ({ type, item, isOpen, iconSx }) => (
  <>
    <PickIcon
      type={type}
      isOpen={isOpen}
      iconSx={{
        ...(isOpen
          ? {
              path: {
                fill: type === 'Floor' ? 'none' : surfaceColors.lightSurface.primary,
                stroke: type === 'Floor' && surfaceColors.lightSurface.primary,
                fillOpacity: 1,
                strokeOpacity: 1,
              },
              rect: {
                stroke: surfaceColors.lightSurface.primary,
                strokeOpacity: 1,
              },
            }
          : {
              path: {
                fill: type === 'Floor' ? 'none' : surfaceColors.lightSurface.secondary,
                stroke: type === 'Floor' && surfaceColors.lightSurface.secondary,
                fillOpacity: 1,
                strokeOpacity: type === 'Floor' ? 0.8 : 1,
              },
              rect: {
                stroke: surfaceColors.lightSurface.secondary,
                strokeOpacity: 0.8,
              },
            }),
        ...iconSx,
      }}
    />
    <PickText
      type={type}
      item={item}
      identifierSx={{
        margin: 0,
        width: '86px',
        marginRight: '8px',
      }}
      nameSx={{
        width: '10px',
        flex: 1,
      }}
    />
  </>
);

export default RowInfo;
