import React from 'react';

import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';

import { AddOptionButton } from './AddOptionButton';

const InputSelectMultiple = ({
  noOptionsText = 'No Options Available',
  handleAddNewOption,
  addNewOptionText = 'New option',
  defaultValue = [],
  onSelectChange,
  options,
  label,
  color = 'secondary',
  fontSize = '14px',
  size,
  ...props
}) => {
  const labelId = `${label}-label`;

  const handleChange = (event) => {
    const { value } = event.target;
    onSelectChange(value);
  };

  const optionsHeight = size === 'small' ? '40px' : '54px';

  return (
    <FormControl {...props} color={color} size={size}>
      <InputLabel id={labelId} sx={{ backgroundColor: 'white', pr: 0.5 }}>
        {label}
      </InputLabel>
      <Select
        labelId={labelId}
        label={label}
        multiple
        value={defaultValue}
        onChange={handleChange}
        sx={{ fontSize }}
        renderValue={(selected) => selected.join(', ')}
      >
        {options.length === 0 && (
          <MenuItem disabled value="" sx={{ height: optionsHeight }}>
            {noOptionsText}
          </MenuItem>
        )}
        {handleAddNewOption && (
          <MenuItem
            value="not-selectable"
            sx={{ color: 'secondary.dark', p: 0, height: optionsHeight }}
            onClick={handleAddNewOption}
          >
            <AddOptionButton isMultiple>{addNewOptionText}</AddOptionButton>
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem key={option} value={option} sx={{ height: optionsHeight }}>
            <ListItemIcon>
              <Checkbox checked={defaultValue.indexOf(option) > -1} color={color} />
            </ListItemIcon>
            <Typography variant="body2" sx={{ fontSize }}>
              {option}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
export default InputSelectMultiple;
