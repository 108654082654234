const blobDownloader = (blob, fileName = 'export file') => {
  const a = document.createElement('a');
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);

  const clickEvent = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });

  a.dispatchEvent(clickEvent);
  a.remove();
};

export default blobDownloader;
