import React, { useState } from 'react';

import { useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import { Custom } from 'components/Icons';

import useDataLayer from '../../../hooks/useDataLayer';
import DeleteModal from '../../../ShopConfiguration/ShopConfigurationSelect/DeleteModal';

const UnmapMachine = ({ machine }) => {
  const [openModal, setOpenModal] = useState(false);
  const { unmapWorkCellMachine, loadingUnmapWorkCellMachine } = useDataLayer();
  const theme = useTheme();

  const handleDeleteWorkCellMachine = async () => {
    await unmapWorkCellMachine(
      machine.workCellMachineId,
      machine.workCellId,
      'Machine successfully unmapped',
      () => {
        setOpenModal(false);
      },
    );
  };

  return (
    <>
      <IconButton
        size="small"
        onClick={(e) => {
          setOpenModal(true);
          e.stopPropagation();
        }}
      >
        <Custom.MachineUnmap fill={theme.palette.action.lightSurface.active} />
      </IconButton>
      <DeleteModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        onSubmit={handleDeleteWorkCellMachine}
        itemName={machine.machineIdentifier}
        loading={loadingUnmapWorkCellMachine}
        title="Confirm unmap"
        confirmText="Are you sure you want to unmap"
        deleteButtonText="Confirm"
      />
    </>
  );
};

export default UnmapMachine;
