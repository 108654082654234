import React, { useEffect } from 'react';

import { gql, useMutation } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import { useForm } from 'react-hook-form';

import { useUser } from 'app/UserContext';
import { Modal, CancelButton, CreateButton, CenteredLoadSpinner } from 'components';
import { updateUser as UPDATE_USER } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

import { useProfilePictureContext } from '../ProfilePictureContext';
import EditProfileForm from './EditProfileForm';

const formDefaultValues = ({ userFirstName, userLastName, workPhoneNumber, personalPhoneNumber }) => ({
  firstName: userFirstName || '',
  lastName: userLastName || '',
  workPhoneNumber: workPhoneNumber || '',
  personalPhoneNumber: personalPhoneNumber || '',
  profilePicture: null,
});

const EditProfileModal = ({ open, onClose }) => {
  const { user = {}, loading, refreshUser } = useUser();
  const { userId } = user;

  const { uploadProfilePicture, profilePicture: profileImage, updateProfilePicture } = useProfilePictureContext();

  const [updateUserInfo] = useMutation(gql(UPDATE_USER), {
    refetchQueries: ['UserByName'],
  });
  const { handleResponse } = useGraphqlResponseHandler(onClose);
  const existingUserData = user;

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    trigger,
    formState: { isDirty, isValid, isSubmitting },
  } = useForm({
    mode: 'all',
    defaultValues: existingUserData && formDefaultValues(existingUserData),
  });

  const submitDisabled = !isDirty || !isValid || isSubmitting;

  const successEditProfile = (res) => {
    const { updateUser } = res;

    updateProfilePicture(updateUser.userPhotoId);
  };

  const editProfileHandler = async (form) => {
    const { firstName, lastName, workPhoneNumber, personalPhoneNumber, profilePicture } = form;
    let bodyProfilePicture = {};
    if (profilePicture) {
      const documentId = await uploadProfilePicture(profilePicture);
      bodyProfilePicture = {
        userPhotoId: documentId,
      };
    }
    const body = {
      firstName,
      lastName,
      workPhoneNumber,
      personalPhoneNumber,
      ...bodyProfilePicture,
    };

    await handleResponse(
      updateUserInfo,
      { variables: { params: { userId }, body } },
      { successMessage: 'Profile information changed successfully.' },
      successEditProfile,
    );
    await refreshUser();
  };

  useEffect(() => {
    if (open && existingUserData) {
      reset(formDefaultValues(existingUserData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <StyledModal
      open={open}
      onClose={onClose}
      title="Edit Profile"
      maxWidth="xs"
      footer={
        <>
          {isSubmitting && <CircularProgress size={25} color="secondary" />}
          <CancelButton color="primary" onClick={onClose} />
          <CreateButton
            color="primary"
            onClick={handleSubmit(editProfileHandler)}
            disabled={submitDisabled}
            buttonText="save changes"
          />
        </>
      }
    >
      <form onSubmit={handleSubmit(editProfileHandler)}>
        <EditProfileForm control={control} setValue={setValue} trigger={trigger} profilePicture={profileImage} />
      </form>
      {loading && <CenteredLoadSpinner />}
    </StyledModal>
  );
};

export default EditProfileModal;

const StyledModal = ({ children, ...props }) => (
  <Modal
    reducePadding
    fullWidth
    maxWidth="xs"
    sx={{
      '.MuiDialog-paper': {
        maxWidth: '355px',
      },
    }}
    titleStyles={{
      letterSpacing: 0.15,
    }}
    contentStyles={{
      py: 3,
    }}
    {...props}
  >
    {children}
  </Modal>
);
