const steps = ['Message Type', 'Add Source', 'Recipients'];
const lastStep = steps.length - 1;

const formatNewMessage = (values, user) => {
  const parsedValue = Buffer.from(`${values.body}`, 'utf-8').toString('base64');
  const message = {
    fromUserId: user.userId,
    fromName: `${user?.firstName == null ? '' : user.firstName} ${
      user?.lastName == null ? '' : user.lastName
    }`,
    companyId: user.companyId,
    subject: values.title,
    body: parsedValue,
    isEncrypted: true,
    messagePriority: values.messagePriority,
  };

  if (values.recipientType === 'all') {
    message.bulkRecipientCode = 'ALL';
  } else if (values.recipientType === 'admins') {
    message.bulkRecipientCode = 'ADMIN';
  } else {
    message.recipients = values.recipients.map((user) => user.id);
  }

  return message;
};

const handleCancel = (onClose, setActiveStep, reset) => {
  setActiveStep(0);
  reset();
  onClose();
};

const handleNextClick = async (trigger, activeStep, handleSubmit, submit, handleNext) => {
  const result = await trigger();
  if (!result) return;

  if (activeStep === lastStep) {
    handleSubmit(submit)();
    return;
  }
  handleNext();
};

export { steps, lastStep, formatNewMessage, handleCancel, handleNextClick };
