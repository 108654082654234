import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { ThemedCheckBox } from 'components';
import ActionsDropdown from 'components/TableComponents/ActionsDropdown/ActionsDropdown';
import Search from 'components/TableComponents/Search';

import DesignMembersActions from './DesignMembersActions';
import { useDesignMembersContext } from './DesignMembersContext';

const DesignMembersHeader = () => {
  const { selected, searchHandler, setShowDeletedUsers, designMembers, setInviteModalOpen } =
    useDesignMembersContext();

  return (
    <HeaderWrapper>
      <Typography variant="h5" gutterBottom>
        Design Members
      </Typography>

      <Grid container justifyContent="space-between" wrap="nowrap">
        <Grid container item alignItems="center" wrap="nowrap">
          <ItemsSelected>{selected.length} Items Selected</ItemsSelected>
          <ActionsDropdown menuItemsRenderer={() => <DesignMembersActions />} />{' '}
        </Grid>

        <Grid container item alignItems="center" wrap="nowrap" justifyContent="flex-end">
          <ThemedCheckBox onChange={(e) => setShowDeletedUsers(e.target.checked)} />
          <Typography component="p" noWrap>
            Show Deleted Users
          </Typography>
          <Search handleSearch={searchHandler} placeholder="Search…" />
          <InviteButton
            color="secondary"
            disabled={designMembers.length === 0}
            onClick={() => setInviteModalOpen(true)}
            variant="contained"
          >
            + Invite Users
          </InviteButton>
        </Grid>
      </Grid>
    </HeaderWrapper>
  );
};

export default DesignMembersHeader;

const HeaderWrapper = (props) => <Box sx={{ marginBottom: '20px' }} {...props} />;

const ItemsSelected = (props) => <Typography sx={{ color: 'rgba(0, 0, 0, 0.5)' }} {...props} />;

const InviteButton = (props) => (
  <Button
    sx={{
      marginLeft: '16px',
      minWidth: 150,
      maxHeight: 32,
      background: 'secondary.main',
      fontSize: 14,
      borderRadius: '4px',
      '&:hover': {
        background: 'secondary.containedHoverBackground',
      },
      color: 'secondary.contrastText',
    }}
    {...props}
  />
);
