export const TABLE_STYLES = {
  '& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator': {
    visibility: 'hidden',
  },
  '& .delete-button': {
    display: 'none',
  },
  '& .MuiDataGrid-row:hover .delete-button': {
    display: 'flex',
  },
};
