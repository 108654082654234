import React from 'react';

import { CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';

import { Modal, CancelButton, DeleteButton } from 'components';

const DeleteModal = ({
  open,
  onClose,
  onSubmit,
  loading,
  itemName,
  confirmText = 'Are you sure you want to delete the',
  deleteButtonText,
  title = 'Confirm Delete',
}) => {
  const submit = (e) => {
    onSubmit();
    e.stopPropagation();
  };

  return (
    <Modal
      disableModalOutsideClick
      disableCloseButton={loading}
      open={open}
      onClose={onClose}
      title={title}
      reducePadding
      fullWidth
      maxWidth="xs"
      closeButtonStyles={{
        padding: 0,
      }}
      footer={
        <>
          {loading && <CircularProgress size={20} />}
          <CancelButton disabled={loading} onClick={onClose} />
          <DeleteButton disabled={loading} onClick={submit} buttonText={deleteButtonText} />
        </>
      }
    >
      <Typography variant="body1" mb="12px">
        {confirmText} <b>{itemName}</b>?
      </Typography>
    </Modal>
  );
};

export default DeleteModal;
