import React from 'react';

import { Box, CircularProgress, ClickAwayListener } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import { useWorkOrderUpdate } from 'hooks-api/useWorkOrderUpdate';
import useKeyPressEffect from 'hooks/useKeyPressEffect';

import WorkOrderNameInputComponent from '../WorkOrderNameInput';
import { workOrder } from '../WRflyoutTypes';

const EditWorkOrderName = ({
  workOrderSelected,
  setHover,
  editWOName,
}: {
  workOrderSelected: workOrder;
  setHover: React.Dispatch<React.SetStateAction<boolean>>;
  editWOName: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const methods = useForm({
    mode: 'all',
    defaultValues: { workOrderName: workOrderSelected?.workOrderName },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const { updateWorkOrder } = useWorkOrderUpdate();

  const submit = ({ workOrderName }: { workOrderName: string }) =>
    updateWorkOrderName({ customWorkOrderName: workOrderName });

  const submitCheck = () => {
    if (isSubmitting) return;
    handleSubmit(submit)();
  };

  useKeyPressEffect('Enter', submitCheck);

  const updateWorkOrderName = async ({ customWorkOrderName }: { customWorkOrderName: string }) => {
    await updateWorkOrder({
      workOrderId: workOrderSelected.workOrderId,
      body: { workOrderName: customWorkOrderName },
    });
    setHover(false);
    editWOName(false);
  };

  return (
    <FormProvider {...methods}>
      <ClickAwayListener onClickAway={submitCheck}>
        <Box sx={{ display: 'inline' }}>
          {isSubmitting && (
            <CircularProgress
              sx={{ color: 'secondary.mainDarkBackground', marginLeft: '-22px', marginTop: '8px' }}
              size="22px"
            />
          )}
          <WorkOrderNameInputComponent />
        </Box>
      </ClickAwayListener>
    </FormProvider>
  );
};

export default EditWorkOrderName;
