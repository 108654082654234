import { gql, useLazyQuery } from '@apollo/client';

import { scopePackage as SCOPE_PACKAGE } from 'graphql/queries';
import { useProject } from 'modules/Field/LocationsAndWorkPhases/contexts/ProjectContext';

const useScopePackageQuery = (take = 50, skip = 0) => {
  const { itemSelectedId } = useProject();
  const [
    fetchScopePackage,
    { data: scopePackageData, called: wasCalled, loading: scopePackageLoading },
  ] = useLazyQuery(gql(SCOPE_PACKAGE), {
    fetchPolicy: 'cache-and-network',
    variables: {
      query: { projectId: itemSelectedId, take, skip },
    },
  });
  return { fetchScopePackage, scopePackageData, scopePackageLoading, wasCalled };
};

export default useScopePackageQuery;
