/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import { gql } from '@apollo/client';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { departmentUsers as DEPARTMENT_USERS } from 'graphql/queries';
import useLazySearchableQuery from 'hooks/useLazySearchableQuery';

const DesignMembersContext = React.createContext();

const DesignMembersProvider = ({ children }) => {
  const { selectedItem, shopDepartmentId: departmentId } = useFacilitiesProjects();
  const [selected, setSelected] = useState([]);
  const [showDeletedUsers, setShowDeletedUsers] = useState(false);
  const [inviteModalOpen, setInviteModalOpen] = useState(false);

  const [{ lazyLoad, paginationHandler, searchHandler, sortHandler }, { loading, data }] =
    useLazySearchableQuery(gql(DEPARTMENT_USERS), {
      extraVars: {
        departmentId,
        includeDeleted: showDeletedUsers ? 'YES' : null,
      },
    });

  useEffect(() => {
    if (departmentId) {
      lazyLoad();
    }
  }, [selectedItem, showDeletedUsers]);

  const selectedStateObj = React.useMemo(
    () => ({
      departmentId,
      selectedItem,
      selected,
      setSelected,
      showDeletedUsers,
      setShowDeletedUsers,
      inviteModalOpen,
      setInviteModalOpen,
      lazyLoad,
      paginationHandler,
      searchHandler,
      sortHandler,
      loading,
      designMembers: !departmentId ? [] : data?.departmentUsers || [],
    }),
    [
      departmentId,
      selectedItem,
      selected,
      setSelected,
      showDeletedUsers,
      setShowDeletedUsers,
      inviteModalOpen,
      setInviteModalOpen,
      loading,
      data,
    ],
  );

  return (
    <DesignMembersContext.Provider value={selectedStateObj}>
      {children}
    </DesignMembersContext.Provider>
  );
};

const useDesignMembersContext = () => {
  const context = React.useContext(DesignMembersContext);
  if (context === undefined) {
    throw new Error('useDesignMembersContext must be used within a DesignMembersContext');
  }
  return context;
};

export { DesignMembersContext, DesignMembersProvider, useDesignMembersContext };
