import React, { useState, useRef, useEffect } from 'react';

import { Button, Menu, MenuItem, useTheme } from '@mui/material';

import { Export } from 'assets/images';

import { ExportButtonProps } from './@types/buttons';

const ExportButton = ({ context, action }: ExportButtonProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const { selectedPadItems } = context();
  const isDisabled = !!selectedPadItems.length;
  const button = useRef<HTMLButtonElement>(null);
  const handleClick = () => setOpen(true);
  const handleDownload = () => {
    action();
    setOpen(false);
  };

  const {
    palette: { secondary },
  } = useTheme();

  useEffect(() => {
    const paths = button.current?.firstChild?.firstChild?.childNodes;
    if (paths) {
      Array.from(paths)
        .filter((node): node is Element => node instanceof Element)
        .forEach((node) => {
          node.setAttribute('fill', !isDisabled ? '#c8c8c8' : secondary.main);
        });
    }
  }, [isDisabled, secondary]);
  return (
    <>
      <Button
        variant="text"
        disabled={!isDisabled}
        startIcon={<Export />}
        ref={button}
        sx={{
          marginLeft: '4px',
          fontSize: '14px',
          lineHeight: '24px',
          fontWeight: '600',
          '&.MuiButton-text': {
            fontSize: '14px',
            color: !isDisabled ? '#c8c8c8' : 'secondary.main',
            lineHeight: '24px',
            fontWeight: '600',
          },
        }}
        onClick={handleClick}
      >
        EXPORT
      </Button>

      <Menu
        id="Export Menu"
        open={open}
        onClose={() => setOpen(false)}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 172, left: window.innerWidth - 205 }}
      >
        <MenuItem onClick={handleDownload}>Download as .csv</MenuItem>
      </Menu>
    </>
  );
};

export default ExportButton;
