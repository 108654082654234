import { ROW_TYPES } from '../../constants/constants';

const ZONE_AND_FLOOR_FIELDS = [
  'locationTypeName',
  'numberOfNodes',
  'locationFormatId',
  'identifierPrefix',
  'identifierDelimiter',
  'startOn',
  'namePrefix',
  'nameDelimiter',
];

const fieldsByType = {
  [ROW_TYPES.ZONE]: ZONE_AND_FLOOR_FIELDS,
  [ROW_TYPES.FLOOR]: ZONE_AND_FLOOR_FIELDS,
  [ROW_TYPES.LOCATION_SYSTEM]: ['locationTypeName', 'namePrefix'],
  [ROW_TYPES.WORK_PHASE]: ['scopePackage', 'workPhase'],
  '': ZONE_AND_FLOOR_FIELDS,
};

export const triggerFirstInvalidInput = (type, formValues, trigger) => {
  let hasMarkedFirstOne = false;
  fieldsByType[type].forEach((key) => {
    if (!formValues[key] && !hasMarkedFirstOne) {
      trigger(key, true);
      hasMarkedFirstOne = true;
    }
  });
};

const EMPTY_VALUES = {
  locationTypeName: '',
  numberOfNodes: '',
  identifierPrefix: '',
  identifierDelimiter: '',
  startOn: '',
  namePrefix: '',
  nameDelimiter: '',
};

export const validateFormIsEmpty = (formValues) =>
  Object.keys(EMPTY_VALUES).every(
    (key) => !(key in formValues) || formValues[key] === EMPTY_VALUES[key],
  );
