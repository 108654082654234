import React, { useState } from 'react';

import { CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';

import { VARIANT_ERROR } from 'constants/snackbarConstants';
import { WorkOrder } from 'graphql/types';
import { useQueryWorkOrderStatusesList } from 'hooks-api/queries/workOrderStatusesList/useQueryWorkOrderStatusesList';
import { useWorkOrderUpdate } from 'hooks-api/useWorkOrderUpdate';
import TableButton from 'modules/Field/WorkRequests/WorkRequestsList/Columns/TableButton';

import useClientRefetchWorkOrdersByChildWorkOrder from './useClientRefetchWorkOrdersByChildWorkOrder';

type ReleaseActionProps = { workOrder: WorkOrder };

const ReleaseAction = ({ workOrder }: ReleaseActionProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const status = workOrder?.workOrderStatusTypeName;
  const shouldDisplayButton = status && ['Draft', 'Blocked'].includes(status);
  const { enqueueSnackbar } = useSnackbar();
  const { updateWorkOrder, loading } = useWorkOrderUpdate();
  const { data: workOrdersStatuses } = useQueryWorkOrderStatusesList({});
  const { refetchWorkOrdersByChildWorkOrder } = useClientRefetchWorkOrdersByChildWorkOrder();

  const successCallbackRelease = (updateWorkOrder: WorkOrder) => {
    const stepFunctionProcessingMessage = updateWorkOrder?.stepFunctionProcessingMessage;
    if (stepFunctionProcessingMessage)
      enqueueSnackbar(stepFunctionProcessingMessage, { autoHideDuration: 2500, ...VARIANT_ERROR });
  };

  const updateWorkOrderStatus = async () => {
    const { workOrderId } = workOrder;
    return updateWorkOrder({
      workOrderId,
      body: {
        workOrderStatusTypeId: workOrdersStatuses?.workOrderStatusesList?.find(
          (workOrder) => workOrder.workOrderStatusTypeName === 'Not Started',
        )?.workOrderStatusTypeId,
      },
    });
  };

  const handleUpdateWorkOrder = async () => {
    try {
      setIsSubmitting(true);
      const result = await updateWorkOrderStatus();
      await refetchWorkOrdersByChildWorkOrder(workOrder);
      successCallbackRelease(result.data?.updateWorkOrder);
    } catch (err) {
      enqueueSnackbar('Could not save changes.', VARIANT_ERROR);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!shouldDisplayButton) return <></>;

  return (
    <>
      {isSubmitting && <CircularProgress size={24} sx={{ color: 'secondary.main' }} />}
      {!isSubmitting && (
        <TableButton disabled={loading} onClick={handleUpdateWorkOrder}>
          Release
        </TableButton>
      )}
    </>
  );
};

export default ReleaseAction;
