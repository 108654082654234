import React from 'react';

import { Button, Stack } from '@mui/material';

import { Custom } from 'components/Icons';

const NewAssemblyTypeButton = ({ newModalSetter }) => (
  <Stack display="block" textAlign="right">
    <Button
      variant="outlined"
      color="secondary"
      sx={{ fontWeight: 'bold', mb: 1 }}
      startIcon={<Custom.AddIcon />}
      onClick={() => newModalSetter(true)}
      data-testid="new-assembly-type-button"
      data-cy="new-assembly-type-button"
    >
      NEW ASSEMBLY TYPE
    </Button>
  </Stack>
);

export default NewAssemblyTypeButton;
