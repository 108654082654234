import React from 'react';

import { useTheme } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useLocation } from 'react-router-dom';

const CssTextField = ({ sx, ...props }) => (
  <TextField
    sx={{
      '& label.Mui-focused': {
        color: 'rgba(255, 255, 255, 0.6)',
      },
      '& label': {
        color: 'rgba(255, 255, 255, 0.6)',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'rgba(255, 255, 255, 0.6)',
      },
      '& .MuiOutlinedInput-root': {
        color: 'common.white',
        '& fieldset': {
          borderColor: 'other.border',
        },
        '&:hover fieldset': {
          borderColor: 'other.border',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'secondary.main',
        },
      },
      '& .MuiFormHelperText-root.Mui-error': {
        color: 'error.light',
        margin: '0px 0px 0px auto',
      },
      '& .Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: 'error.light !important',
      },
      '& .MuiFormLabel-root.Mui-error': {
        color: 'error.light',
      },
      ...sx,
    }}
    {...props}
  />
);

const PartItemInput = ({ sx, ...props }) => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const isAssemblyEditor = pathname?.includes('/assembly-editor');

  return (
    <CssTextField
      label={isAssemblyEditor ? 'Unit Qty' : 'Qty'}
      id="custom-css-outlined-input"
      sx={sx}
      size="small"
      theme={theme}
      data-cy="partItemInput"
      {...props}
    />
  );
};

export default PartItemInput;
