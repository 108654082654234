import { gql, useQuery } from '@apollo/client';

import { workOrderById as WORK_ORDER } from 'graphql/queries';
import type { WorkOrder, WorkOrderId } from 'graphql/types';

type Vars = {
  params: {
    workOrderId: WorkOrderId;
  };
};

type Result = {
  workOrderById: WorkOrder;
}

const useFetchWorkOrder = (workOrderId: WorkOrderId) => {
  const { data, loading, refetch, startPolling, stopPolling } = useQuery<Result, Vars>(gql(WORK_ORDER), {
    variables: { params: { workOrderId } },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: false,
  });

  return { loading, workOrder: data?.workOrderById, refetch, startPolling, stopPolling };
};

export default useFetchWorkOrder;
