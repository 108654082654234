import React, { useCallback } from 'react';

import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import { Waypoint } from 'react-waypoint';

import { useUser } from 'app/UserContext';
import { CenteredLoadSpinner } from 'components';
import { Custom } from 'components/Icons';
// import NewTaskTypeModal from 'modules/Shop/ShopSetup/ShopTasks/NewTaskTypeModal';
import TaskTypeCard from 'components/TaskTypeCard/index';
import useFetchPresignedDocument from 'hooks-api/useFetchPresignedDocument';
import { useShopTasksContext } from 'modules/Shop/ShopSetup/ShopTasks/ShopTasksContext';

import DrawerContainer from './TaskTypeDrawer/DrawerContainer';

const taskTypesPopupScrollBarStyle = {
  mb: 1,
  mt: 2,
  mr: 0.5,
  overflowX: 'hidden',

  /* Track */
  '::-webkit-scrollbar-track': {
    background: 'rgba(255, 255, 255, 0.68)',
  },

  /* Handle */
  '::-webkit-scrollbar-thumb': {
    background: 'white',
  },
};
export const TaskTypesPopup = () => {
  const { taskTypes, taskTypesPaginationHandler, loadingTaskTypes } = useShopTasksContext();
  const { user } = useUser();
  const fetchMoreTaskTypes = useCallback(
    (skip) => {
      taskTypesPaginationHandler(skip, {
        taskTypeClassId: '9e1a7bc6-5b0a-4aa7-bcb5-b354e3bc2cdb',
        companyId: user?.companyId,
        orderBy: 'taskTypeName',
      });
    },
    [taskTypesPaginationHandler, user?.companyId],
  );

  const handleFetchMore = useCallback(() => {
    if (!taskTypes) return;
    fetchMoreTaskTypes(taskTypes?.length);
  }, [taskTypes, fetchMoreTaskTypes]);

  return (
    <StyledPopup>
      <DrawerContainer.Title textHelp="Drag task types into workflow to create assembly">
        Shop Tasks
      </DrawerContainer.Title>
      <DrawerContainer.Search />
      <div
        style={{
          marginLeft: '25px',
          color: 'rgba(230, 222, 254, 1)',
        }}
      >
        + NEW TASK TYPE
      </div>
      {/* <NewTaskTypeModal open /> */}
      <DrawerContainer.Items sx={taskTypesPopupScrollBarStyle}>
        {taskTypes?.length > 0 && (
          <div>
            {taskTypes?.map((taskType, index) => (
              <TaskType key={taskType.taskTypeId} taskType={taskType} index={index} />
            ))}
          </div>
        )}
        <Waypoint key={taskTypes?.length} bottomOffset="-20%" onEnter={() => handleFetchMore()} />
        {loadingTaskTypes && <CenteredLoadSpinner darkMode />}
      </DrawerContainer.Items>
    </StyledPopup>
  );
};

const DRAGGED_COPY_ELEMENT_ID = '__dragged_copy_element__';

const handleCreateCustomDraggedElement = (event) => {
  const elementCopy = event.target.cloneNode(true);
  elementCopy.id = DRAGGED_COPY_ELEMENT_ID;
  elementCopy.style.pointerEvents = 'none';
  elementCopy.style.position = 'absolute';
  elementCopy.style.zIndex = 9999;
  const dragIcon = document.createElement('div');
  dragIcon.style.pointerEvents = 'none';
  dragIcon.style.position = 'absolute';
  dragIcon.style.zIndex = 9999;
  dragIcon.style.width = '10px';
  dragIcon.style.height = '10px';
  dragIcon.style.opacity = 0;
  document.getElementById('root').append(dragIcon); // this is an empty element to replace the original one with transparency
  document.getElementById('root').append(elementCopy);
  event.dataTransfer.setDragImage(dragIcon, 0, 0);
};

const TaskType = ({
  taskType: { taskTypeId, taskTypeName, taskTypeImageId, taskTypeDescription },
  index,
}) => {
  const { document: image } = useFetchPresignedDocument(taskTypeImageId);
  const onDragEnd = () => document.getElementById(DRAGGED_COPY_ELEMENT_ID).remove();

  const onDrag = (event) => {
    const elementCopy = document.getElementById(DRAGGED_COPY_ELEMENT_ID);
    elementCopy.style.top = `${event.clientY}px`;
    elementCopy.style.left = `${event.clientX}px`;
  };
  const onDragStart = (event) => {
    const nodeData = {
      taskTypeId,
      taskTypeName,
      taskTypeImageId,
      image,
      taskTypeDescription,
    };
    event.dataTransfer.setData('application/reactflow', JSON.stringify(nodeData));
    event.dataTransfer.effectAllowed = 'move';
    handleCreateCustomDraggedElement(event);
  };
  return (
    <DrawerContainer.Item
      onDrag={onDrag}
      onDragEnd={() => onDragEnd()}
      onDragStart={(event) => onDragStart(event)}
      index={index}
    >
      <TaskTypeCard
        taskTypeName={taskTypeName}
        taskTypeClass="shop"
        image={image}
        taskTypeDescription={taskTypeDescription}
        icon={
          <Stack sx={{ m: 0, pt: '12px', pl: '10px' }}>
            <Custom.WorkRequestFill />
          </Stack>
        }
        sx={{ width: '240px', cursor: 'grab', userSelect: 'none' }}
      />
    </DrawerContainer.Item>
  );
};

const StyledPopup = ({ children }) => (
  <Box
    sx={{
      position: 'absolute',
      top: '75px',
      right: '55px',
      overflowY: 'scroll',
      borderRadius: '4px',
      width: '290px',
      bottom: '8px',
      zIndex: 999,
      backgroundColor: 'rgba(48, 48, 48, 0.95)',
    }}
  >
    {children}
  </Box>
);
