import { useContext } from 'react';

import { TakeOffContext } from 'modules/Materials/TakeOff/context/TakeOffContext';

const useGetTakeoffLineItemsAdded = () => {
  const TakeOffLineItemsContext = useContext(TakeOffContext);

  if (!TakeOffLineItemsContext) return null;

  const { decisionsStatesAndCallbacks, onAddTakeOffPadItem, refetchTakeOffPadItems, chipList, assignModeOn } =
    TakeOffLineItemsContext;

  return {
    onAddWriteInItems: onAddTakeOffPadItem,
    refetchCatalogItems: refetchTakeOffPadItems,
    chipList,
    assignModeOn,
    ...decisionsStatesAndCallbacks,
  };
};

export default useGetTakeoffLineItemsAdded;
