import React from 'react';

import { gql, useQuery } from '@apollo/client';
import { InputForesiteField } from '@evolvemep/foresite-components-ui-react';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';

import CenteredLoadSpinner from 'components/CenteredLoadSpinner';
import ControlledRadioGroup from 'components/FormComponents/ControlledRadioGroup';
import { messageTypes as MESSAGE_TYPES, messagePriorities as MESSAGE_PRIORITIES } from 'graphql/queries';

const MessageTypeForm = ({ control }) => {
  const { data: typeData, loading: loadingTypes } = useQuery(gql(MESSAGE_TYPES));
  const { data: priorityData, loading: loadingPriorities } = useQuery(gql(MESSAGE_PRIORITIES));

  if (loadingTypes || loadingPriorities) return <CenteredLoadSpinner />;

  const messageTypeOptions = typeData.messageTypes.map(({ messageTypeId, messageTypeName }) => ({
    label: messageTypeName,
    value: messageTypeId,
  }));

  const priorityOptions = priorityData.messagePriorities.map(({ priorityName, priorityCode }) => ({
    label: priorityName,
    value: priorityCode,
  }));

  return (
    <>
      <InputForesiteField
        name="title"
        label="Title"
        size="small"
        control={control}
        shouldUnregister={false}
        sx={{ margin: '40px 0 20px 0' }}
        rules={{ required: 'Title is required' }}
      />

      <FormMainLabel>Select message type</FormMainLabel>
      <ControlledRadioGroup
        name="messageType"
        radioOptions={messageTypeOptions}
        control={control}
        row
        sx={{ margin: '30px 0px 50px 0px' }}
      />

      <FormMainLabel>Select Priority</FormMainLabel>
      <FormSubLabel>
        High-priority messages will display both in the ribbon and in the Message Center. Normal priority messages will
        only display in the Message Center.
      </FormSubLabel>
      <ControlledRadioGroup
        name="messagePriority"
        radioOptions={priorityOptions}
        control={control}
        row
        sx={{ margin: '30px 0px 50px 0px' }}
      />
    </>
  );
};

export default MessageTypeForm;

const FormMainLabel = ({ children }) => (
  <FormLabel
    sx={{
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.25px',
      color: 'rgba(0, 0, 0, 0.87)',
    }}
  >
    {children}
  </FormLabel>
);

const FormSubLabel = ({ children }) => (
  <Typography
    sx={{
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
      color: 'rgba(0, 0, 0, 0.5)',
      marginTop: '8px',
      width: '454px',
      overflowWrap: 'anywhere',
    }}
  >
    {children}
  </Typography>
);
