import React, { useCallback, useMemo, useState } from 'react';

import BaseButton from './BaseButton';
import LocationWithAWorkPhasePopper from './LocationWithAWorkPhasePopper';

const AssignButtonWithPopper = ({
  disabled = false,
  onClickOption = () => {},
  onCancel = () => {},
  onSwitch = () => {},
  isAssignationModeDisabled,
  defaultChecked,
  data = [],
  currentItem,
}) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const onClick = (event) => {
    if (!open) {
      setAnchorEl(event?.currentTarget);
      setOpen((previousOpen) => !previousOpen);
    }
  };

  const onResetValues = useCallback(() => {
    setAnchorEl(null);
    setOpen(false);
  }, []);

  const onCancelHandler = useCallback(() => {
    onResetValues();
    onCancel();
  }, [onCancel, onResetValues]);

  const onDoneHandler = useCallback(() => {
    onResetValues();
  }, [onResetValues]);

  const sxButtonActive = useMemo(() => {
    if (open)
      return {
        bgcolor: 'secondary.light',
        '&:hover': {
          bgcolor: 'secondary.light',
        },
      };
    return {};
  }, [open]);

  return (
    <BaseButton
      buttonText="ASSIGN MODE"
      disabled={disabled}
      onClick={onClick}
      sxButton={{ ...sxButtonActive }}
    >
      <LocationWithAWorkPhasePopper
        anchorEl={anchorEl}
        open={open}
        onClickOption={onClickOption}
        data={data}
        currentItem={currentItem}
        onCancel={onCancelHandler}
        onDone={onDoneHandler}
        onSwitch={onSwitch}
        isAssignationModeDisabled={isAssignationModeDisabled}
        defaultChecked={defaultChecked}
      />
    </BaseButton>
  );
};

export default AssignButtonWithPopper;
