import React, { useRef } from 'react';

import { Navigate } from 'react-router-dom';

import ComponentPaneLayout from 'app/Layout/ReactGridLayout/ComponentPaneLayout';
import { FacilityAndProjectWrapper } from 'components';
import { PopoverFilterProvider } from 'components/Popover/FilterPopover/context/PopoverFilterContext';
import { WorkOrderUpdateProvider } from 'hooks-api/useWorkOrderUpdate';
import { DrawingRegister, DrawingRegisterProvider } from 'modules/Design/DrawingRegister';
import { UpdateWorkRequestStatusProvider } from 'modules/Field/WorkRequests/WorkRequest/hooks/useUpdateWorkRequestStatus/useUpdateWorkRequestStatus';
import { WorkOrdersProvider } from 'modules/Shop/WorkOrders/WorkOrdersContext';

import { ShopTasksProvider } from '../Shop/ShopSetup/ShopTasks/ShopTasksContext';
import { WorkRequestsProvider as WorkRequestsShopProvider } from '../Shop/WorkRequests/WorkRequestsContext';
import { ActiveRowProvider } from './LocationsAndWorkPhases/contexts/ActiveRowContext';
import { DeleteModalProvider } from './LocationsAndWorkPhases/contexts/DeleteModalContext';
import { DragAndDropProvider } from './LocationsAndWorkPhases/contexts/DragAndDropContext';
import { LocationsAndWorkPhaseProvider } from './LocationsAndWorkPhases/contexts/LocationsAndWorkPhaseContext';
import { LocationTypeAndFormatProvider } from './LocationsAndWorkPhases/contexts/LocationTypeAndFormatContext';
import { ProjectProvider } from './LocationsAndWorkPhases/contexts/ProjectContext';
import { LocationsAndWorkPhaseContent } from './LocationsAndWorkPhases/LocationsAndWorkPhaseContent';
import WorkBreakdownStructure from './WorkBreakdownStructure/WorkBreakdownStructure';
import WorkPackages from './WorkPackages/WorkPackages';
import WorkRequest from './WorkRequests/WorkRequest/WorkRequest';
import { WorkRequestDragDropProvider } from './WorkRequests/WorkRequest/WorkRequestDragDropProvider';
import WorkRequestFlyoutMenu from './WorkRequests/WorkRequest/WorkRequestFlyoutMenu';
import { WorkRequestItemsProvider } from './WorkRequests/WorkRequest/WorkRequestItemsContext';
import WorkRequestsAndProperties from './WorkRequests/WorkRequestsAndProperties';
import { WorkRequestsProvider } from './WorkRequests/WorkRequestsContext';
import WorkRequestFilter from './WorkRequests/WorkRequestsList/WorkRequestFilter/WorkRequestFilter';
import WorkRequestListFlyoutMenu from './WorkRequests/WorkRequestsList/WorkRequestListFlyoutMenu/WorkRequestListFlyoutMenu';

export const Dashboard = () => (
  <ComponentPaneLayout>
    <Navigate to="work-requests" />
  </ComponentPaneLayout>
);

export const FieldMembersInterfaceNavContent = () => (
  <ComponentPaneLayout>
    <div>Field members dashboard</div>
  </ComponentPaneLayout>
);

export const WorkPackagesInterfaceNavContent = () => (
  <ComponentPaneLayout>
    <WorkPackages />
  </ComponentPaneLayout>
);

export const WorkBreakDownStructureInterfaceNavContent = () => (
  <ComponentPaneLayout>
    <WorkBreakdownStructure />
  </ComponentPaneLayout>
);

export const LocationsAndWorkPhasesInterfaceNavContent = () => (
  <DeleteModalProvider>
    <LocationTypeAndFormatProvider>
      <ProjectProvider>
        <ActiveRowProvider>
          <LocationsAndWorkPhaseProvider>
            <DragAndDropProvider>
              <LocationsAndWorkPhaseContent />
            </DragAndDropProvider>
          </LocationsAndWorkPhaseProvider>
        </ActiveRowProvider>
      </ProjectProvider>
    </LocationTypeAndFormatProvider>
  </DeleteModalProvider>
);

export const DrawingRegisterInterfaceNavContent = () => (
  <FacilityAndProjectWrapper isFacilityRequired isProjectRequired>
    <WorkOrderUpdateProvider>
      <UpdateWorkRequestStatusProvider>
        <DrawingRegisterProvider>
          <PopoverFilterProvider>
            <DrawingRegister />
          </PopoverFilterProvider>
        </DrawingRegisterProvider>
      </UpdateWorkRequestStatusProvider>
    </WorkOrderUpdateProvider>
  </FacilityAndProjectWrapper>
);

export const WorkRequestsNavContent = () => {
  const partDroppables = useRef();
  return (
    <FacilityAndProjectWrapper isProjectRequired>
      <WorkRequestsProvider>
        <PopoverFilterProvider>
          <WorkOrderUpdateProvider>
            <UpdateWorkRequestStatusProvider>
              <WorkOrdersProvider>
                <ComponentPaneLayout flyoutMenu={<WorkRequestListFlyoutMenu ref={partDroppables} />}>
                  <WorkRequestsAndProperties filterComponent={<WorkRequestFilter />} />
                </ComponentPaneLayout>
              </WorkOrdersProvider>
            </UpdateWorkRequestStatusProvider>
          </WorkOrderUpdateProvider>
        </PopoverFilterProvider>
      </WorkRequestsProvider>
    </FacilityAndProjectWrapper>
  );
};

export const WorkRequestNavContent = () => {
  const partDroppables = useRef();
  return (
    <WorkRequestsShopProvider>
      <ShopTasksProvider>
        <WorkOrderUpdateProvider>
          <UpdateWorkRequestStatusProvider>
            <WorkRequestsProvider>
              <WorkRequestDragDropProvider isTasksReadOnly>
                <WorkRequestItemsProvider isTasksReadOnly>
                  <ComponentPaneLayout flyoutMenu={<WorkRequestFlyoutMenu isHideOptions ref={partDroppables} />}>
                    <WorkRequest hideBulkActions />
                  </ComponentPaneLayout>
                </WorkRequestItemsProvider>
              </WorkRequestDragDropProvider>
            </WorkRequestsProvider>
          </UpdateWorkRequestStatusProvider>
        </WorkOrderUpdateProvider>
      </ShopTasksProvider>
    </WorkRequestsShopProvider>
  );
};
