import React, { FC } from 'react';

import { Button } from '@mui/material';

import { SESSION_STORAGE_CONSTANTS } from 'constants/globalConstants';
import CustomIcon from 'modules/Materials/TakeOff/header/SelectAction/CustomIcon';

import { useBOMbyIdContext } from '../../BOMbyIdContext';

const { CURRENT_BOM } = SESSION_STORAGE_CONSTANTS;

interface IActionsButton {
  handleClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const ActionsButton: FC<IActionsButton> = ({ handleClick }: IActionsButton) => {
  const { selectedPadItems } = useBOMbyIdContext();
  const BOMStatus = JSON.parse(sessionStorage.getItem(CURRENT_BOM) || '{}')?.billOfMaterialsStatusName === 'Closed';
  const inactive = BOMStatus || selectedPadItems.length === 0;
  return (
    <Button
      disabled={inactive}
      variant="outlined"
      size="small"
      endIcon={<CustomIcon disabled={inactive} />}
      onClick={handleClick}
      color="secondary"
      sx={{
        padding: '0 8px 0 8px',
        height: '32px',
        border: `solid 1px ${!inactive ? 'secondary.outlinedRestingBorder' : '#cccccc'} !important`,
      }}
    >
      ACTIONS
    </Button>
  );
};

export default ActionsButton;
