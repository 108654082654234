import React from 'react';

import { Stack, Typography } from '@mui/material';

export const CustomAssemblyBopCalculator = ({ params }) =>
  params.row.type === 'Custom Assembly' && (
    <Stack direction="row" alignItems="center">
      <Typography
        variant="body2"
        sx={{
          flex: 1,
          letterSpacing: '0.1px',
          marginLeft: 1,
          userSelect: 'none',
        }}
      >
        None
      </Typography>
    </Stack>
  );
