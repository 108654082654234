import React from 'react';

import { Typography, MenuItem } from '@mui/material';

const NewCatalogButton = ({ openModal }) => (
  <>
    <MenuItem data-testid="new-catalog-btn" color="secondary" onClick={openModal} value="">
      <Typography
        variant="button"
        align="left"
        color="secondary"
        style={{
          textTransform: 'capitalize',
          width: '100%',
        }}
      >
        + New Catalog
      </Typography>
    </MenuItem>
  </>
);

export default NewCatalogButton;
