import React from 'react';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';

import { Custom } from 'components/Icons';
import OverflowTypography from 'components/Tooltip/OverflowTypography';

const DrawingDocumentDraggableRow = ({ height = 0, documentIdentifier, documentName }) => (
  <Box display="flex" sx={{ borderBottom: '1px solid rgb(224, 224, 224)', height, boxSizing: 'border-box' }}>
    <Box display="flex" alignItems="center" width="72px">
      <Checkbox
        size="small"
        defaultChecked
        color="secondary"
        sx={{ ml: '14px' }}
        inputProps={{ 'aria-label': 'drawing-document-row-checbox' }}
      />
    </Box>
    <Stack flexDirection="row" width="calc(100% - 72px)">
      <Stack flexDirection="row" alignItems="center" width="25%">
        <Custom.SmallDrawingDocument />
        <OverflowTypography
          title={documentIdentifier}
          variant="body2"
          sx={{ color: 'surface.lightSurface.primary', ml: 1 }}
        />
      </Stack>
      <Stack flexDirection="row" alignItems="center" width="75%">
        <OverflowTypography
          title={documentName}
          variant="body2"
          sx={{ color: 'surface.lightSurface.primary', ml: 1 }}
        />
      </Stack>
    </Stack>
  </Box>
);

export default DrawingDocumentDraggableRow;
