import React from 'react';

import Stack from '@mui/material/Stack';

import { InterfaceSelectorWorkRequestRef } from 'components/InterfaceSelectorWorkRequest';

import FacilityButtons from './FacilityButtons';
import ProjectButtons from './ProjectButtons';
import { WR_INTERFACE_SELECTOR_REF_NAME } from './utils/constants';

const WorkRequestTitleButtons = ({
  updateWorkRequest,
  loadingWRStatus,
  updateWorkRequestStatus,
  workRequest,
  selectedItem,
  inForgeViewer,
}) => {
  const projectAlignment = selectedItem?.type === 'PROJECT' ? { justifyContent: 'right' } : {};

  if (inForgeViewer) return <InterfaceSelectorWorkRequestRef id={WR_INTERFACE_SELECTOR_REF_NAME} />;

  return (
    <Stack direction="row" sx={{ mt: '4px', width: '380px', ...projectAlignment }}>
      <InterfaceSelectorWorkRequestRef id={WR_INTERFACE_SELECTOR_REF_NAME} sx={{ position: 'static' }} />

      {selectedItem?.type === 'FACILITY' && (
        <FacilityButtons
          workRequest={workRequest}
          updateWorkRequestStatus={updateWorkRequestStatus}
          updateWorkRequest={updateWorkRequest}
        />
      )}
      {selectedItem?.type === 'PROJECT' && (
        <ProjectButtons
          workRequest={workRequest}
          updateWorkRequestStatus={updateWorkRequestStatus}
          loadingWRStatus={loadingWRStatus}
        />
      )}
    </Stack>
  );
};

export default WorkRequestTitleButtons;
