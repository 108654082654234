import { useState, useEffect, useCallback, useMemo } from 'react';

import usePartCategoryAPI from 'hooks-api/usePartCategory';
import { getCategoryHierarchy } from 'hooks/usePartsCloudSearchCache';

import { useCategoriesContext } from '../../Categories/CategoriesContext';

const useBreadcrumbBuilder = () => {
  const [categoriesHiearachy, setCategoriesHiearachy] = useState([]);

  const { selectedCategory } = useCategoriesContext();
  const { fetchPartCategoryById } = usePartCategoryAPI();

  const fetchCategoryHierarchy = useCallback(async () => {
    const hierarchy = await getCategoryHierarchy(
      selectedCategory ?? { parentPartCategory: { partCategoryId: '' } },
      [],
      fetchPartCategoryById,
    );
    setCategoriesHiearachy(hierarchy);
  }, [fetchPartCategoryById, selectedCategory]);

  useEffect(() => {
    fetchCategoryHierarchy();
  }, [fetchCategoryHierarchy]);

  const categoriesBreadcrumbs = useMemo(
    () =>
      (categoriesHiearachy ?? []).map(({ partCategoryName }) => ({
        label: partCategoryName || '',
      })),
    [categoriesHiearachy],
  );

  return {
    categoriesBreadcrumbs: categoriesBreadcrumbs.filter(({ label }) => label),
    categoriesHiearachy,
  };
};

export default useBreadcrumbBuilder;
