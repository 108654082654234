import { MutationHookOptions, gql, useMutation } from '@apollo/client';

import { addWorkOrderItem as ADD_WORK_ORDER_ITEM } from 'graphql/mutations';
import { AddWorkOrderItemMutation, AddWorkOrderItemMutationVariables } from 'graphql/types';

const useAddWorkOrderItem = (
  options: MutationHookOptions<AddWorkOrderItemMutation, AddWorkOrderItemMutationVariables>,
) => useMutation<AddWorkOrderItemMutation, AddWorkOrderItemMutationVariables>(gql(ADD_WORK_ORDER_ITEM), options);

export default useAddWorkOrderItem;
