import { useEffect } from 'react';

import { getStatusFilters } from 'helpers/arrayFunctions';
import useFetchWorkRequests from 'hooks-api/useFetchWorkRequests';

import { getFilters } from './getFilters';
import { getStatusesToFilterOut } from './useEffectThatFetchWorkRequests';

export function useFetchWorkRequestToFilters(selectedItem, filters, workRequestsStatus) {
  const { getWorkRequests, workRequests, loading } = useFetchWorkRequests();

  useEffect(() => {
    const statusesToFilterOut = getStatusesToFilterOut(selectedItem.type);
    const translateFilters = getFilters(selectedItem, filters);
    const workRequestStatusIds =
      selectedItem.type === 'FACILITY'
        ? getStatusFilters(workRequestsStatus, statusesToFilterOut)
        : '';

    if (translateFilters) {
      getWorkRequests({
        take: 1000,
        workRequestStatusIds,
        ...translateFilters,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, filters, workRequestsStatus]);

  return { workRequests, loading };
}
