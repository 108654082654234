import React from 'react';

import { useAttributeContext } from '../AttributesSection/AttributesContext';
import AttributeModal from './AttributeModal';

const AttributeModalSetup = () => {
  const { attributesModalOpen, setAttributesModalOpen } = useAttributeContext();

  return (
    <AttributeModal open={attributesModalOpen} onClose={() => setAttributesModalOpen(false)} />
  );
};

export default AttributeModalSetup;
