import React from 'react';

import { Box, Stack } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Droppable } from 'react-beautiful-dnd';

import { useActiveRow } from 'modules/Field/LocationsAndWorkPhases/contexts/ActiveRowContext';

import { useDragAndDrop } from '../../../contexts/DragAndDropContext';
import { mustDisappear } from '../../../utils/util';
import WorkPhaseDraggableRow from './WorkPhaseDraggableRow';

const ScopePackageDroppableContainer = ({ droppableId, workPhases = [] }) => {
  const { draggingWorkPhaseId } = useDragAndDrop();
  const { activeRows } = useActiveRow();
  return (
    <Box sx={{ height: 'auto' }}>
      <Droppable droppableId={droppableId} type="DROPPABLE-AREA">
        {(droppableProvided, snapshot) => (
          <Box
            isDraggingOver={snapshot.isDraggingOver}
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps}
            sx={{ backgroundColor: grey[100] }}
          >
            {!workPhases.length ? (
              <EmptyContent />
            ) : (
              <>
                {workPhases.map((workPhase, index) => {
                  const isSelected = activeRows.some((activeRow) => activeRow === workPhase);
                  return (
                    <WorkPhaseDraggableRow
                      key={workPhase.id}
                      index={index}
                      draggableId={workPhase.id}
                      disappearRow={mustDisappear(isSelected, draggingWorkPhaseId, workPhase.id)}
                      workPhase={workPhase}
                    />
                  );
                })}
              </>
            )}
            {droppableProvided.placeholder}
          </Box>
        )}
      </Droppable>
    </Box>
  );
};

export default ScopePackageDroppableContainer;

const EmptyContent = () => <Stack sx={{ height: '1px', overflow: 'hidden' }}>&nbsp;</Stack>;
