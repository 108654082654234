import React from 'react';

import { ForesiteTextField } from '@evolvemep/foresite-components-ui-react';
import { Autocomplete, createFilterOptions } from '@mui/material';
import { Controller } from 'react-hook-form';

const ControlledAutoComplete = ({
  options,
  control,
  limitOptions,
  name,
  label,
  rules,
  defaultValue = '',
  getOptionLabel = (option) => option,
  fullWidth,
  freeSolo,
  disabled,
  listBoxProps = {},
  ...props
}) => {
  const filterOptions = (options, state) =>
    createFilterOptions()(options, state).slice(0, limitOptions);
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Autocomplete
          disabled={disabled}
          freeSolo={freeSolo}
          filterOptions={filterOptions}
          ListboxProps={listBoxProps}
          onChange={(e, item) => onChange(item)}
          value={value}
          autoHighlight
          options={options}
          getOptionLabel={getOptionLabel}
          fullWidth={fullWidth}
          renderInput={(params) => (
            <ForesiteTextField
              onChange={freeSolo && ((event) => onChange(event.target.value))}
              {...params}
              margin="normal"
              size="small"
              variant="outlined"
              label={label}
              error={!!error}
              helperText={error?.message}
              fullWidth
              {...props}
            />
          )}
        />
      )}
    />
  );
};

export default ControlledAutoComplete;
