import React, { FC } from 'react';

import { Custom } from 'components/Icons';
import AttachmentType from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestListFlyoutMenu/WorkRequestAttachments/AttachmentType';
import { actionColors } from 'styles/theme/colors';

import { ICustomAttachmentType } from '../types/attachmentType';

const WorkOrdersAttachType: FC<ICustomAttachmentType> = (props: ICustomAttachmentType) => (
  <AttachmentType label="Work Orders" icon={<Custom.WorkOrders fill={actionColors.disabled} />} {...props} />
);

export default WorkOrdersAttachType;
