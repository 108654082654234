import React from 'react';

import { Box, MenuItem } from '@mui/material';

const MoreTableRowOptions = ({ setWorkRequestModalDeleteOpen }) => (
  <Box>
    <MenuItem
      sx={{ color: 'error.main' }}
      onClick={(e) => {
        setWorkRequestModalDeleteOpen(true);
        e.stopPropagation();
      }}
    >
      Delete
    </MenuItem>
  </Box>
);

export default MoreTableRowOptions;
