/* eslint-disable no-console */
import { gql, useMutation } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';

import { useUser } from 'app/UserContext';
import { addFacility as ADD_FACILITY_MUTATION } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

const formatToNewOrExistingUser = (user, isExistingKey, isNewKey) => {
  if (typeof user === 'object') {
    return { [isExistingKey]: user.id, isAdmin: true };
  }

  return { [isNewKey]: user, userName: uuidv4(), isAdmin: true };
};

const formatDepartments = (departments) => {
  const formattedDepartments = departments.map((department) => {
    const newDepartment = { ...department };
    delete newDepartment.user;

    return {
      ...formatToNewOrExistingUser(department.user, 'userId', 'userEmail'),
      ...newDepartment,
    };
  });

  const removedEmptyValues = formattedDepartments.filter(Boolean);

  return removedEmptyValues;
};

const useCreateFacility = ({ onCompleted }) => {
  const { handleResponse } = useGraphqlResponseHandler();
  const { user } = useUser();
  const [addFacility, { loading }] = useMutation(gql(ADD_FACILITY_MUTATION), {
    refetchQueries: ['Facilities'],
  });

  const submit = async (values) => {
    const { departments } = values;
    const body = { ...values, companyId: user.companyId };

    if (departments) {
      body.departments = formatDepartments(departments);
    }

    handleResponse(
      addFacility,
      {
        variables: {
          body,
        },
      },
      {
        successMessage: 'Facility created successfully',
      },
      () => onCompleted(),
    );
  };

  return { submit, loading };
};

export default useCreateFacility;
