import React from 'react';

import { Stack } from '@mui/material';

import { useChips } from '../../CreateLocationsModalContext';
import RowChip from './RowChip/RowChip';

const Chips = (props) => {
  const { chips } = useChips();

  return (
    <StylesBox>
      {chips.map((chip) => (
        <RowChip chip={chip} key={chip.id} {...props} />
      ))}
    </StylesBox>
  );
};

export default Chips;

const StylesBox = ({ children }) => (
  <Stack
    sx={{
      height: 96,
      mt: 2,
      borderRadius: 1,
      border: '1px solid',
      borderColor: 'other.border',
      marginBottom: 1,
      overflowY: 'auto',
    }}
  >
    {children}
  </Stack>
);
