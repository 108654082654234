import React, { useCallback, useState, forwardRef, useMemo } from 'react';

import Stack from '@mui/material/Stack';

import { RightSideFlyoutPaper } from 'components';
import { LOCAL_STORAGE_CONSTANTS } from 'constants/globalConstants';
import { useMaterialsConfig } from 'hooks-api/useMaterialsConfig';
import { useLocalStorage, getLocalStorage } from 'hooks/useLocalStorage';
import { darkSurfaceScrollBarStyle } from 'styles/theme/scrollBarStyle';

import CatalogSearch from '../CatalogSearch/CatalogSearch';
import CatalogSearchDropDown from '../CatalogSearchDropDown';
import PartCategoriesBreadcrumbs from '../PartCategory/PartCategoriesBreadcrumbs/PartCategoriesBreadcrumbs';
import PartCategoriesGrid from '../PartCategory/PartCategoriesGrid/PartCategoriesGrid';
import { PartCategoryProvider } from '../PartCategory/PartCategoryContext';
import { PartCategorySearchProvider } from '../PartCategory/PartCategorySearchContext';
import { PartsGrid } from '../PartsGrid';
import CatalogPopupInfiniteControl from './CatalogPopupInfiniteControl';

const LineItemType = {
  PART: 'Part',
};
const { PREV_CATALOG_ID_KEY } = LOCAL_STORAGE_CONSTANTS;

const CatalogPopup = forwardRef(
  (
    {
      onAddItems = () => {},
      onClose = () => {},
      moduleButtonsColor,
      noAssemblies = false,
      disablePartId = null,
      partName = null,
      hasAlreadyDecisions = false,
      addItemsLoading,
    },
    ref,
  ) => {
    const { lineItemTypes } = useMaterialsConfig();
    const partLineItemType = useMemo(
      () => lineItemTypes?.find(({ label }) => label === LineItemType.PART),
      [lineItemTypes],
    );

    const [prevCatalogId, setPrevCatalogId] = useLocalStorage(
      PREV_CATALOG_ID_KEY,
      getLocalStorage(PREV_CATALOG_ID_KEY),
    );
    const [catalogId, setCatalogId] = useState(prevCatalogId || '');

    const handleCatalogChange = useCallback(
      (value) => {
        setCatalogId(value);
        setPrevCatalogId(value);
      },
      [setPrevCatalogId],
    );

    const handleClose = () => {
      setPrevCatalogId(catalogId);
      onClose();
    };

    const handleAddParts = useCallback(
      (parts) => {
        const payload = parts.map(({ part, partQty }) => ({
          partId: part.partHistoryRefId,
          partName: part.partName ?? '',
          manufacturerName:
            typeof part.manufacturer === 'object' ? part.manufacturer?.manufacturerName ?? '' : part.manufacturerName,
          catalogNumber:
            typeof part.manufacturer === 'object' ? part.manufacturerCatalogNumber ?? '' : part.catalogNumber,
          unitOfMeasureCode:
            typeof part.unitOfMeasure === 'object'
              ? part.unitOfMeasure?.unitOfMeasureCode ?? ''
              : part.unitOfMeasureCode,
          qty: partQty,
          lineItemTypeId: partLineItemType?.value,
          partImageDocumentId: part.partImageDocumentId,
        }));
        onAddItems(payload, hasAlreadyDecisions);
      },
      [onAddItems, partLineItemType, hasAlreadyDecisions],
    );

    return (
      <RightSideFlyoutPaper
        leading={
          <CatalogSearchDropDown value={catalogId} darkTheme onChange={handleCatalogChange} partName={partName} />
        }
        onClose={handleClose}
        onDock={false}
        sx={{ top: 100 }}
      >
        <PartCategoryProvider
          catalogId={catalogId}
          moduleButtonsColor={moduleButtonsColor}
          noAssemblies={noAssemblies}
          disablePartId={disablePartId}
        >
          <PartCategorySearchProvider catalogId={catalogId} noAssemblies={noAssemblies} disablePartId={disablePartId}>
            <PartCategoriesBreadcrumbs />
            <CatalogSearchContainer sx={{ marginTop: '15px' }}>
              {catalogId && <CatalogSearch noAssemblies={noAssemblies} catalogId={catalogId} />}
            </CatalogSearchContainer>
            <Stack
              flexDirection="column"
              overflow="auto"
              sx={{ maxHeight: '100%', mt: 5, ...darkSurfaceScrollBarStyle }}
            >
              <PartCategoriesGrid />
              <PartsGrid ref={ref} onAddParts={handleAddParts} addItemsLoading={addItemsLoading} />
              <CatalogPopupInfiniteControl />
            </Stack>
          </PartCategorySearchProvider>
        </PartCategoryProvider>
      </RightSideFlyoutPaper>
    );
  },
);

export default CatalogPopup;

const CatalogSearchContainer = ({ children, sx }) => <Stack sx={sx}>{children}</Stack>;
