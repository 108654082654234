import React from 'react';

import { StyledLink } from '@evolvemep/foresite-components-ui-react';
import { Avatar, Stack, Typography } from '@mui/material';

import OverflowTip from 'components/Tooltip/OverflowTip';

export const KanbanCardHeader = ({ children }) => (
  <OverflowTip title={children} offset={{ x: 0, y: -15 }}>
    <Typography
      sx={{
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontSize: 12,
        color: '#000',
        lineHeight: '24px',
        padding: '10px 16px 0 16px',
      }}
    >
      {children}
    </Typography>
  </OverflowTip>
);

export const KanbanCardContent = ({ children }) => <Stack sx={{ padding: '3px 16px 4px 16px' }}>{children}</Stack>;

export const KanbanCardFooter = ({ img, detailColor }) => (
  <Stack
    direction="row"
    spacing={2}
    sx={{
      justifyContent: 'space-between',
      alignItems: 'center',
      borderTop: `1px solid ${detailColor}`,
      padding: '0 16px',
    }}
  >
    <Avatar alt="User avatar" src={img} sx={{ width: 18, height: 18, m: '4px 0' }} />
    <Stack direction="row" sx={{ alignItems: 'center' }}>
      {/* <Typography sx={{ fontSize: 12, color: '#000', opacity: 0.5 }}>{comments}</Typography>
      <MUI.ModeComment
        sx={{
          height: '18px',
          width: '18px',
          color: '#000',
          opacity: 0.5
        }}
      /> */}
    </Stack>
  </Stack>
);

export const KanbanWorkOrderId = ({ order, children, ...props }) => (
  <StyledLink to={order.workOrderId} state={{ workOrderId: order.workOrderId, from: 'Work Orders' }}>
    <OverflowTip title={children} offset={{ x: 0, y: -15 }}>
      <Typography
        sx={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          fontSize: 16,
          fontWeight: 700,
          lineHeight: '18px',
        }}
        {...props}
      >
        {children}
      </Typography>
    </OverflowTip>
  </StyledLink>
);

export const KanbanWorkOrderName = ({ children, ...props }) => (
  <OverflowTip title={children} offset={{ x: 0, y: -8 }}>
    <Typography
      sx={{
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontSize: 12,
        lineHeight: '24px',
        color: '#000',
      }}
      {...props}
      variant="subtitle1"
    >
      {children}
    </Typography>
  </OverflowTip>
);

export const KanbanWorkOrderDate = ({ children, expired = false, sx, ...props }) => (
  <Typography
    variant="body1"
    sx={{
      whiteSpace: 'nowrap',
      fontSize: 16,
      fontWeight: expired ? 900 : 400,
      color: expired ? '#F44336' : '#000',
      opacity: expired ? 1 : 0.5,
      lineHeight: '24px',
      mt: 1,
      ...sx,
    }}
    {...props}
  >
    Need By: {children}
  </Typography>
);

export const KanbanWorkOrderRemaining = ({ children, expired = false, sx, ...props }) => (
  <Typography
    sx={{
      fontSize: 12,
      fontWeight: expired ? 900 : 500,
      color: expired ? '#F44336' : '#000',
      opacity: expired ? '#F44336' : 0.5,
      lineHeight: '24px',
      ...sx,
    }}
    variant="subtitle1"
    {...props}
  >
    Days Remaining: {children}
  </Typography>
);
