import React, { useState, useEffect } from 'react';

import { useQuery, gql } from '@apollo/client';
import { useLocation } from 'react-router-dom';

import { useUser } from 'app/UserContext';
import { users as USERS } from 'graphql/queries';
import { INCLUDE_DELETED_OPTIONS } from 'graphql/types';

import { useCustomAssemblyTypesContext } from '../CustomAssemblyTypesContext';
import { CustomAssemblyAddModal } from './AddCustomAssemblyModal/CustomAssemblyAddModal';
import { CustomAssemblyTypeAddModal } from './AddCustomAssemblyTypeModal/CustomAssemblyTypeAddModal';
import { CustomAssemblyTypesTable } from './CustomAssemblyTypesTable';
import DeleteConfirmationModal from './DeleteConfirmationModal/DeleteConfirmationModal';
import NewAssemblyButton from './NewAssemblyTypeButton';
import { NoCustomAssemblyTypeOverlay } from './NoCustomAssemblyTypeOverlay';
import TypesListHeader from './TypesListHeader';

const transformUserData = (user) => ({
  userId: user.userId,
  userPhotoId: user.userPhotoId,
  userEmail: user.userEmail,
});

export const CustomAssemblyTypesList = () => {
  const [isCustomAssemblyTypeAddModalOpen, setIsCustomAssemblyTypeModalOpen] = useState(false);
  const {
    setCustomAssemblyTypes,
    fetchCustomAssemblyTypes,
    customAssemblyTypes,
    setDeleteModalOpen,
    setCreateModalOpen,
    deleteModalOpen,
    createModalOpen,
    setCurrentRow,
    setCompanyUsers,
  } = useCustomAssemblyTypesContext();
  const { user } = useUser();
  const { state } = useLocation();
  const { data } = useQuery(gql(USERS), {
    variables: {
      query: {
        companyId: state?.companyId || user.companyId,
        includeDeleted: INCLUDE_DELETED_OPTIONS.NO,
      },
    },
    fetchPolicy: 'no-cache',
  });
  const unassigned = {
    userId: 0,
    userEmail: 'Unassigned',
    userPhotoId: null,
  };

  useEffect(() => {
    const users =
      data?.users && data.users.length
        ? [
            {
              userId: user.userId,
              userEmail: `${user.attributes.email} (Assign to me)`,
              userPhotoId: user.userPhotoId,
            },
            unassigned,
            ...data.users.map(transformUserData).filter((item) => item.userId !== user.userId),
          ]
        : [unassigned];
    setCompanyUsers(users);
    // eslint-disable-next-line
  }, [data]);

  const closeDeleteModal = () => {
    setCurrentRow(null);
    setDeleteModalOpen(false);
  };

  useEffect(() => {
    fetchCustomAssemblyTypes(setCustomAssemblyTypes);
    // eslint-disable-next-line
  }, []);

  const { createCustomAssemblyType } = useCustomAssemblyTypesContext();

  return (
    <>
      <TypesListHeader />
      <NewAssemblyButton newModalSetter={setIsCustomAssemblyTypeModalOpen} />
      {customAssemblyTypes.length < 1 ? <NoCustomAssemblyTypeOverlay /> : <CustomAssemblyTypesTable />}
      <DeleteConfirmationModal open={deleteModalOpen} onClose={closeDeleteModal} />
      <CustomAssemblyTypeAddModal
        open={isCustomAssemblyTypeAddModalOpen}
        onClose={() => setIsCustomAssemblyTypeModalOpen(false)}
        createCustomAssemblyType={createCustomAssemblyType}
      />
      <CustomAssemblyAddModal
        open={createModalOpen}
        onClose={() => {
          setCreateModalOpen(false);
        }}
      />
    </>
  );
};
