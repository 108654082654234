import React, { memo, useCallback, useEffect, useMemo } from 'react';

import { CircularProgress, IconButton, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { Custom } from 'components/Icons';
import { requiredRule } from 'constants/inputFieldRules';
import useFolderAPI from 'hooks-api/useFolderAPI';

import { useDrawingFolderContext } from '../../DrawingFolderContext';
import FolderControlInput from './FolderControlInput';

const DrawingFolderFormRow = ({ onClose, onSave, loading = false }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { folderParentRow, folders: rootFolders, idEditedFolder } = useDrawingFolderContext();
  const { fetchFoldersByParentFolder, foldersCache: folders } = useFolderAPI();

  useEffect(() => {
    if (folderParentRow) {
      fetchFoldersByParentFolder(folderParentRow.id);
    }
  }, [folderParentRow, fetchFoldersByParentFolder]);

  const mainFolders = useMemo(
    () => (folderParentRow ? folders : rootFolders),
    [folderParentRow, folders, rootFolders],
  );

  const onKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === 'Tab') onSave();
    if (event.key === 'Escape') onClose();
    event.stopPropagation();
  };

  const noDuplicateItemName = useCallback(
    (value) => {
      const itemNamesDuplicated = mainFolders
        ?.filter((folder) => folder.folderId !== idEditedFolder)
        .some(({ folderName }) => folderName?.trim().toLowerCase() === value.trim().toLowerCase());

      return !itemNamesDuplicated || 'Name already exists';
    },
    [idEditedFolder, mainFolders],
  );

  const blockInvalidChars = (event) => {
    const regex = /[\\/[\]:¦<>+=;,*?"]/;
    const { key } = event;
    if (regex.test(key)) {
      event.preventDefault();
    }
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        paddingRight: '42px',
        paddingLeft: '4px',
        background: 'rgba(0, 0, 0, 0.04)',
        width: '100%',
      }}
      data-testid="new-folder-row-form"
    >
      <IconButton onClick={onClose}>
        <Custom.Close />
      </IconButton>

      {loading ? (
        <CircularProgress size={16} />
      ) : (
        <FolderControlInput
          control={control}
          name="folderName"
          rules={{
            ...requiredRule('Folder name', '*Required'),
            validate: {
              noDuplicate: (value) => noDuplicateItemName(value),
            },
          }}
          autoFocus
          maxLength={64}
          defaultValue={null}
          inputProps={{
            'data-cy': 'new-folder-input',
            'data-testid': 'new-folder-input',
          }}
          disabled={loading}
          error={!!errors?.folderName}
          onKeyDown={onKeyDown}
          onKeyPress={blockInvalidChars}
        />
      )}
    </Stack>
  );
};

export default memo(DrawingFolderFormRow);
