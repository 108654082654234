import React from 'react';

import { Box } from '@mui/material';

const KanbanGrid = ({ children, containerSx = {} }) => (
  <Box
    sx={{
      position: 'relative',
      height: '100%',
    }}
  >
    <Box
      sx={{
        display: 'grid',
        gridAutoFlow: 'column',
        gridAutoColumns: '196px',
        gridGap: '16px',
        width: '100%',
        overflow: 'auto',
        position: 'absolute',
        ...containerSx,
      }}
    >
      {children}
    </Box>
  </Box>
);

export default KanbanGrid;
