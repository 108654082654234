/* eslint-disable no-console */
import React from 'react';

import { CircularProgress } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import { Modal, CancelButton, CreateButton } from 'components';

import FacilityInfoForm from '../FacilityInfoForm';
import DepartmentInfoForm from './DepartmentInfoForm';
import useCreateFacility from './useCreateFacility';

const CreateFacilityModal = ({ open, onClose }) => {
  const methods = useForm({ mode: 'all' });
  const {
    watch,
    reset,
    formState: { isValid },
  } = methods;
  const { submit, loading } = useCreateFacility({
    onCompleted: () => {
      onClose();
      reset();
    },
  });

  const isDepartmentSelected = watch('departments');

  const handleClose = () => {
    reset();
    onClose();
  };

  const formValues = watch();

  return (
    <Modal
      title="Create new facility"
      open={open}
      onClose={handleClose}
      footer={
        <>
          {loading && <CircularProgress />}
          <CancelButton onClick={handleClose} sx={{ marginRight: '8px' }} />
          <CreateButton
            color="primary"
            onClick={() => submit(formValues)}
            disabled={!isValid || loading || !isDepartmentSelected}
          />
        </>
      }
    >
      <FormProvider {...methods}>
        <FacilityInfoForm />
        <DepartmentInfoForm />
      </FormProvider>
    </Modal>
  );
};

export default CreateFacilityModal;
