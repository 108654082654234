import React from 'react';

import { useMutation, gql } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { Modal, CancelButton, DeleteButton } from 'components';
import { deleteFacility as DELETE_FACILITY } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

import { useFacilityContext } from './FacilityContext';

const FacilityDeleteModal = ({ open, onClose }) => {
  const { handleResponse } = useGraphqlResponseHandler();
  const {
    facility: { facilityId },
  } = useFacilityContext();

  const [deleteFacility, { loading }] = useMutation(gql(DELETE_FACILITY), {
    refetchQueries: ['Facilities'],
  });

  const submit = async () => {
    handleResponse(deleteFacility, {
      variables: {
        params: {
          facilityId,
        },
      },
    });

    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      disableCloseButton={loading}
      title="Confirm Delete"
      reducePadding
      fullWidth
      maxWidth="xs"
      footer={
        <>
          {loading && <CircularProgress />}
          <CancelButton onClick={onClose} />
          <DeleteButton disabled={loading} onClick={submit} />
        </>
      }
    >
      <Typography variant="body1" mb="12px">
        Are you sure you want to delete this facility?
      </Typography>
    </Modal>
  );
};

export default FacilityDeleteModal;
