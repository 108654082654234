import React, { useState, useEffect } from 'react';

import { Checkbox, FormHelperText, Stack, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';

import { ForesiteAutocomplete } from 'components/FormComponents';
import { Custom } from 'components/Icons';

const TEXT_FIELD_STYLES = {
  variant: 'standard',
  color: 'secondary',
  fullWidth: true,
};

const AutocompleteControlled = ({ name, control, options, onClose, label, dataTestId, sx }) => {
  const [open, setOpen] = useState(false);
  const [noSelectedText, setNoSelectedText] = useState('');

  useEffect(() => {
    if (options.length) setNoSelectedText('Filter Value');
    return () => {
      setNoSelectedText('');
    };
  }, [options]);

  const handleOpen = () => {
    setNoSelectedText('');
    setOpen(true);
  };

  const handleClose = (e, values) => {
    if (values.length) onClose(e);
    setOpen(false);
  };

  const renderOption = (props, option, state) =>
    option ? <Option key={option.value} option={option} props={props} state={state} /> : null;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <ForesiteAutocomplete
            textFieldProps={{ ...TEXT_FIELD_STYLES, label, name, 'data-testid': dataTestId }}
            autoFocus
            label="Values"
            className="Item-Autocomplete"
            disableCloseOnSelect
            disableClearable
            getOptionLabel={(option) => option.label}
            open={open}
            onOpen={handleOpen}
            onClose={(e) => handleClose(e, value)}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            onChange={(_, values) => {
              if (values.length && noSelectedText !== '') setNoSelectedText('Filter Value');
              onChange(values);
            }}
            renderOption={renderOption}
            options={options ?? []}
            multiple
            size="small"
            value={value}
            renderTags={(tagValue) => <Tag tagValue={tagValue} />}
            sx={{ ...sx }}
          />
          {error && <FormHelperText error>{error.message}</FormHelperText>}
        </>
      )}
    />
  );
};

const ICON = <Custom.CheckBoxOutlineBlank />;
const CHECKED_ICON = <Custom.CheckBox />;

const Option = ({ props, option, state }) => {
  const { label, value } = option;

  return (
    <Stack direction="row" component="li" sx={{ padding: '8px !important', gap: 1 }} {...props}>
      <Checkbox
        size="small"
        icon={ICON}
        checkedIcon={CHECKED_ICON}
        checked={state.selected}
        color="secondary"
        sx={{ padding: 0 }}
        data-testid={`checkbox_testid_${value}`}
      />
      <Typography variant="body" component="span">
        {label}
      </Typography>
    </Stack>
  );
};

const Tag = ({ tagValue }) => (
  <Typography
    key={tagValue.value}
    variant="body1"
    sx={{ lineHeight: '16px', paddingBottom: '6px' }}
  >
    {tagValue.length < 4
      ? tagValue?.map((tag) => tag.label).join(', ')
      : `(${tagValue.length} selected)`}
  </Typography>
);

export default AutocompleteControlled;
