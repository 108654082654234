import * as React from 'react';

export const useComponentVisible = (initialIsVisible, prevId) => {
  const [isComponentVisible, setIsComponentVisible] = React.useState(initialIsVisible);

  React.useEffect(() => {
    setIsComponentVisible(initialIsVisible);
  }, [initialIsVisible]);

  const ref = React.useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      if (prevId && Array.isArray(prevId)) {
        if (!prevId.includes(event.target.id)) {
          setIsComponentVisible(false);
        }
      } else {
        setIsComponentVisible(false);
      }
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
};
