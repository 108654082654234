export const NODE_HANDLERS_TYPES = {
  SOURCE: 'source',
  TARGET: 'target',
};

export const NODE_HANDLERS = [
  {
    type: NODE_HANDLERS_TYPES.TARGET,
    id: 'a',
    position: 'left',
    style: { top: '8px', left: '-36px' },
    handleStyle: {
      top: '50%',
    },
  },
  {
    type: NODE_HANDLERS_TYPES.SOURCE,
    id: 'b',
    position: 'right',
    style: { top: '8px', right: '-40px' },
    handleStyle: {
      top: '50%',
    },
  },
  {
    type: NODE_HANDLERS_TYPES.TARGET,
    id: 'c',
    position: 'top',
    style: { top: '-30px', right: '44.5%' },
    handleStyle: {
      left: '50%',
    },
  },
  {
    type: NODE_HANDLERS_TYPES.SOURCE,
    id: 'd',
    position: 'bottom',
    style: { top: '42px', right: '43%' },
    handleStyle: {
      left: '50%',
    },
  },
];
