import React, { FC } from 'react';

import Field from './components/Field';
import { FieldProvider } from './context/FieldContext';

interface IQuantityField {
  name: string;
  rowIdentifier: string;
  qtyAggregate: number;
  qtyRemaining: number;
  isWorkPhase?: boolean;
  disabled?: boolean;
}

const QuantityField: FC<IQuantityField> = ({
  name = '',
  rowIdentifier = '',
  qtyAggregate = 0,
  qtyRemaining = 0,
  isWorkPhase = false,
  disabled = false,
}: IQuantityField) => (
  <FieldProvider
    name={name}
    qtyAggregate={qtyAggregate}
    rowIdentifier={rowIdentifier}
    qtyRemaining={qtyRemaining}
    isWorkPhase={isWorkPhase}
    disabled={disabled}
  >
    <Field />
  </FieldProvider>
);

export default QuantityField;
