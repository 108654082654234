import React from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Custom } from 'components/Icons';

const CreateFacilityCard = ({ onClick }) => (
  <StyledCard onClick={onClick}>
    <Custom.AddIcon data-testid="create-facility-add-icon" />
    <Title />
  </StyledCard>
);

export default CreateFacilityCard;

const StyledCard = ({ children, onClick }) => (
  <Stack
    direction="row"
    alignItems="center"
    justifyContent="center"
    sx={{
      width: '100%',
      height: '294px',
      marginBottom: '10px',
      cursor: 'pointer',
      letterSpacing: '0.15px',
      color: '#757575',
      backgroundColor: 'other.selected',

      '&:hover': {
        backgroundColor: '#bdbdbd',
      },
    }}
    onClick={onClick}
  >
    {children}
  </Stack>
);

const Title = () => (
  <Typography
    sx={{
      fontWeight: '500',
      fontSize: '20px',
      lineHeight: '32px',
      letterSpacing: '0.15px',
    }}
    component="h4"
  >
    Create Facility
  </Typography>
);
