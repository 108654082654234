import React from 'react';

import { IconButton } from '@mui/material';

import { Custom } from 'components/Icons';
import { otherColors } from 'styles/theme/colors';

const CategoryItemMoreMenu = ({ onClick, sx, ...restProps }) => (
  <IconButton
    data-testid="category-button-option"
    sx={{ padding: 0, ...sx }}
    size="small"
    onClick={onClick}
    {...restProps}
  >
    <Custom.MoreVert fill={otherColors.black} />
  </IconButton>
);

export default CategoryItemMoreMenu;
