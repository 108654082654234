import React, { useCallback, useRef, useEffect } from 'react';

import { DragDropContext } from 'react-beautiful-dnd';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import ComponentPaneLayout from 'app/Layout/ReactGridLayout/ComponentPaneLayout';
import { FacilityAndProjectWrapper } from 'components';
import { ItemsDataGridProProvider } from 'components/ItemsDatagridPro';
import { useProject } from 'modules/Field/LocationsAndWorkPhases/contexts/ProjectContext';
import { ShopTasksProvider } from 'modules/Shop/ShopSetup/ShopTasks/ShopTasksContext';

import { AssemblyEditorAPIProvider } from './AssemblyEditor/APIProviders/AssemblyEditorAPIProvider';
import AssemblyEditor from './AssemblyEditor/AssemblyEditor';
import AssemblyEditorFlyoutMenu from './AssemblyEditor/AssemblyEditorFlyoutMenu';
import BillOfMaterials from './BillOfMaterials/BillOfMaterials';
import BillOfMaterialsById from './BillOfMaterialsById/BillOfMaterialsById';
import BillOfMaterialsFlyoutMenu from './BillOfMaterialsById/BillOfMaterialyFlyoutMenu';
import { BOMbyIdProvider } from './BillOfMaterialsById/BOMbyIdContext';
import CatalogSetup from './CatalogSetup/CatalogSetup';
import { BOMItemProvider } from './components/BillOfMaterialsItemPopup/BOMItemContext';
import DataImport from './DataImport';
import { AssignationModeProvider } from './features/assignation-mode';
import FiltersProviderBase from './FiltersProviderBase';
import { TakeOffProvider } from './TakeOff/context/TakeOffContext';
import TakeOff from './TakeOff/TakeOff';
import TakeOffFlyoutMenu from './TakeOff/TakeOffFlyoutMenu';

export const Dashboard = () => (
  <ComponentPaneLayout>
    <Navigate to="bill-of-materials" />
  </ComponentPaneLayout>
);

export const AssemblyEditorNavContent = () => {
  const partDroppables = useRef();
  return (
    <ShopTasksProvider>
      <DragDropContext>
        <AssemblyEditorAPIProvider>
          <ComponentPaneLayout flyoutMenu={<AssemblyEditorFlyoutMenu />} ref={partDroppables}>
            <AssemblyEditor />
          </ComponentPaneLayout>
        </AssemblyEditorAPIProvider>
      </DragDropContext>
    </ShopTasksProvider>
  );
};

export const CatalogSetupInterfaceNavContent = () => (
  <ComponentPaneLayout>
    <CatalogSetup />
  </ComponentPaneLayout>
);

export const DataImportInterfaceNavContent = () => (
  <ComponentPaneLayout>
    <DataImport />
  </ComponentPaneLayout>
);

export const BillOfMaterialsInterfaceNavContent = () => {
  const { selectedItem } = useFacilitiesProjects();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (selectedItem === null && params.id) navigate('../bill-of-materials');
    // eslint-disable-next-line
  }, [selectedItem]);

  return (
    <FacilityAndProjectWrapper isFacilityRequired isProjectRequired>
      <ItemsDataGridProProvider contextId="BillOfMaterials">
        <ComponentPaneLayout>
          <BillOfMaterials />
        </ComponentPaneLayout>
      </ItemsDataGridProProvider>
    </FacilityAndProjectWrapper>
  );
};

export const BillOfMaterialsByIdInterfaceNavContent = () => {
  const { selectedItem } = useFacilitiesProjects();
  const navigate = useNavigate();
  const params = useParams();
  const BOMRef = useRef();
  const partDroppables = useRef();
  const onDropPartDroppable = useCallback(({ draggableId }) => {
    const partDroppable = partDroppables.current?.parts?.find(({ partId }) => partId === draggableId);
    BOMRef.current.handleDropItem(partDroppable);
  }, []);

  useEffect(() => {
    if (selectedItem === null && params.id) navigate('../bill-of-materials');
    // eslint-disable-next-line
  }, [selectedItem]);

  return (
    <FiltersProviderBase>
      <BOMbyIdProvider>
        <DragDropContext onDragEnd={onDropPartDroppable}>
          <AssignationModeProvider>
            <BOMItemProvider>
              <FacilityAndProjectWrapper isFacilityRequired isProjectRequired>
                <ComponentPaneLayout flyoutMenu={<BillOfMaterialsFlyoutMenu ref={partDroppables} />}>
                  <ItemsDataGridProProvider contextId="BillOfMaterialsById">
                    <BillOfMaterialsById ref={BOMRef} />
                  </ItemsDataGridProProvider>
                </ComponentPaneLayout>
              </FacilityAndProjectWrapper>
            </BOMItemProvider>
          </AssignationModeProvider>
        </DragDropContext>
      </BOMbyIdProvider>
    </FiltersProviderBase>
  );
};

const TakeOffContentProviders = () => {
  const takeOffPadPageRef = useRef();
  const partDroppables = useRef();

  const { selectedProject } = useProject();
  const isProjectSelected = Boolean(selectedProject?.locationId);

  const onDropPartDroppable = useCallback(({ draggableId }) => {
    const partDroppable = partDroppables.current?.parts?.find(({ partId }) => partId === draggableId);
    takeOffPadPageRef.current.handleDropItem(partDroppable);
  }, []);

  return (
    <TakeOffProvider>
      <AssignationModeProvider>
        <DragDropContext onDragEnd={onDropPartDroppable}>
          <ComponentPaneLayout flyoutMenu={isProjectSelected && <TakeOffFlyoutMenu ref={partDroppables} />}>
            <ItemsDataGridProProvider contextId="TakeOff">
              <TakeOff ref={takeOffPadPageRef} />
            </ItemsDataGridProProvider>
          </ComponentPaneLayout>
        </DragDropContext>
      </AssignationModeProvider>
    </TakeOffProvider>
  );
};

export const TakeOffContent = () => (
  <FiltersProviderBase>
    <TakeOffContentProviders />
  </FiltersProviderBase>
);
