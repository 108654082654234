import React, { useEffect } from 'react';

import ComponentPaneLayout from 'app/Layout/ReactGridLayout/ComponentPaneLayout';

import { useLocationsAndWorkPhases } from './contexts/LocationsAndWorkPhaseContext';
import Locations from './Locations/Locations';
import WorkPhases from './WorkPhases/WorkPhases';

export const LocationsAndWorkPhaseContent = () => {
  const {
    locations: { cancelCreation: cancelLocationForm },
    workPhases: { cancelCreation: cancelWorkPhaseForm },
  } = useLocationsAndWorkPhases();

  const validateEscapeKey = (event) => {
    if (event.key === 'Escape') {
      cancelWorkPhaseForm();
      cancelLocationForm();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      document.addEventListener('keydown', validateEscapeKey, false);
    }, 1000);

    return () => {
      document.removeEventListener('keydown', validateEscapeKey, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ComponentPaneLayout>
      <Locations />
      <WorkPhases />
    </ComponentPaneLayout>
  );
};
