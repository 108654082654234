import { useSearchParams } from 'react-router-dom';

const useUrlParams = () => {
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);

  return { params };
};

export default useUrlParams;
