import React, { useCallback } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Droppable } from 'react-beautiful-dnd';
import { Waypoint } from 'react-waypoint';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { CenteredLoadSpinner } from 'components';
import { Custom } from 'components/Icons';
import ListFlyoutPaper from 'components/ListFlyoutPaper';
import TaskTypeCard from 'components/TaskTypeCard/TaskTypeCard';

import { ROW_TYPES } from '../constants/constants';
import { getFacilityId } from '../helpers/helpers';
import { useMachinesContext } from './MachinesContext';
import { taskTypesPopupScrollBarStyle } from './scrollBarStyle';
import StyledPopup from './StyledPopup';

export const MachinesPopup = ({ onClose }) => {
  const { selectedItem } = useFacilitiesProjects();
  const { loadingMachines, machinesPaginationHandler, machinesData, showMapped, setShowMapped } =
    useMachinesContext();
  const toggleItemsVisualization = () => {
    setShowMapped(!showMapped);
  };

  const fetchMoreMachines = useCallback(
    (skip) => {
      machinesPaginationHandler(skip, {
        facilityId: getFacilityId(selectedItem),
        orderBy: 'machineIdentifier:asc',
      });
    },
    [machinesPaginationHandler, selectedItem],
  );

  const handleFetchMore = useCallback(() => {
    if (!machinesData) return;
    fetchMoreMachines(machinesData?.length);
  }, [machinesData, fetchMoreMachines]);

  return (
    <StyledPopup onClose={onClose}>
      <ListFlyoutPaper.Title textHelp="Drag machines to map">Machines</ListFlyoutPaper.Title>
      <ListFlyoutPaper.ActionButton sx={{ px: 2, my: 2, mr: 0.5 }}>
        <HideButton
          onClick={toggleItemsVisualization}
          label={showMapped ? 'SHOW MAPPED' : 'HIDE MAPPED'}
          open={showMapped}
        />
      </ListFlyoutPaper.ActionButton>
      <ListFlyoutPaper.Items sx={{ ...taskTypesPopupScrollBarStyle, mt: 0 }}>
        {machinesData?.length > 0 && (
          <DroppableContainer>
            {machinesData
              .filter((machine) => (!showMapped ? !machine.disabled : true))
              .map((machine, index) => (
                <MachineType machine={machine} index={index} key={machine.machineId} />
              ))}
          </DroppableContainer>
        )}
        <Waypoint
          key={machinesData?.length}
          bottomOffset="-20%"
          onEnter={() => handleFetchMore()}
        />
        {loadingMachines && <CenteredLoadSpinner darkMode />}
      </ListFlyoutPaper.Items>
    </StyledPopup>
  );
};

const DroppableContainer = ({ children }) => (
  <Droppable droppableId={ROW_TYPES.MACHINE} type={ROW_TYPES.MACHINE}>
    {(droppableProvided) => (
      <Box {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
        {children}
        {droppableProvided.placeholder}
      </Box>
    )}
  </Droppable>
);

const MachineType = ({
  machine: { machineId, manufacturer, machineIdentifier, disabled },
  index,
}) => (
  <ListFlyoutPaper.Item draggable={!disabled} index={index} draggableId={machineId}>
    <TaskTypeCard
      taskTypeName={manufacturer}
      taskTypeClass="shop"
      taskTypeDescription={machineIdentifier}
      icon={
        <Stack sx={{ m: 0, pt: '12px', pl: '10px' }}>
          <Custom.Machine />
        </Stack>
      }
      sx={{ width: '240px', cursor: 'grab', userSelect: 'none' }}
      disabled={disabled}
    />
  </ListFlyoutPaper.Item>
);

const HideButton = ({ label, disabled, onClick, open }) => (
  <Button
    color="secondary"
    onClick={onClick}
    disabled={disabled}
    sx={{
      fontWeight: 'bold',
      letterSpacing: '0.4px',
      color: 'secondary.light',
      fontSize: '13px',
      height: '24px',
      marginLeft: 'auto',
    }}
    startIcon={open ? <Custom.ExpandMoreIcon /> : <Custom.ExpandLessIcon />}
  >
    {label}
  </Button>
);
