import React from 'react';

import { CircularProgress, IconButton } from '@mui/material';

import { Custom } from 'components/Icons';
import StackRow from 'components/StackRow';

type Props = {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isSubmitting: boolean;
};

const CloseButton = ({ onClick, isSubmitting }: Props) => (
  <StackRow>
    {!isSubmitting && (
      <IconButton sx={{ pr: '4px' }} onClick={(event) => onClick(event)}>
        <Custom.CloseAlt height={13} width={14} fill="black" fillOpacity={0.54} />
      </IconButton>
    )}
    {isSubmitting && <CircularProgress color="secondary" size={22} sx={{ mr: '4px' }} />}
  </StackRow>
);

export default CloseButton;
