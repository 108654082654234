import React from 'react';

import { InputForesiteField } from '@evolvemep/foresite-components-ui-react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Controller } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';

import PasswordControlField from 'components/FormComponents/PasswordControlField';
import { emailRegEx } from 'constants/regEx';
import useBreakpoint from 'hooks/useBreakpoint';

const LoginForm = ({ onSubmit, isLoading = false, control }) => (
  <>
    <form onSubmit={onSubmit} autoComplete="off">
      <Title />
      <EmailInputField control={control} />
      <PasswordInputField control={control} />
      <SpaceBetweenFlexbox>
        <KeepMeSignedInCheckbox control={control} />
        <ForgotPasswordLink />
      </SpaceBetweenFlexbox>
      <LoginButton disabled={isLoading}>Login</LoginButton>
    </form>
  </>
);

export default LoginForm;

const Title = () => {
  const { isMobile } = useBreakpoint();

  return (
    <Typography component="h1" variant={isMobile ? 'h5' : 'h4'} color="#10143D">
      Login
    </Typography>
  );
};

const EmailInputField = ({ control }) => (
  <InputForesiteField
    name="email"
    defaultValue=""
    data-cy="email-input"
    control={control}
    label="Email"
    size="normal"
    rules={{
      required: 'Email is required',
      pattern: {
        value: emailRegEx,
        message: 'Email is invalid',
      },
    }}
  />
);

const PasswordInputField = ({ control }) => (
  <PasswordControlField
    data-cy="password-input"
    name="password"
    defaultValue=""
    testid="password-input"
    control={control}
    label="Password"
    rules={{
      required: 'Invalid password',
    }}
  />
);

const KeepMeSignedInCheckbox = ({ control }) => (
  <Controller
    name="keepEnabled"
    defaultValue={false}
    control={control}
    render={({ field: { onChange, value } }) => (
      <FormControlLabel
        label="Keep me signed in"
        control={
          <Checkbox
            onChange={onChange}
            value={value}
            color="secondary"
            sx={{
              '&.Mui-checked': {
                color: '#434A90',
              },
            }}
          />
        }
      />
    )}
  />
);

const ForgotPasswordLink = () => (
  <Link data-cy="forgot-password" component={RouterLink} to="/auth/forgot-password" variant="body2">
    <Typography variant="body1" component="span" sx={{ color: '#4A93FF' }}>
      Forgot Password?
    </Typography>
  </Link>
);

const SpaceBetweenFlexbox = ({ children }) => (
  <Box display="flex" alignItems="center" justifyContent="space-between" my="2.25rem">
    {' '}
    {children}
  </Box>
);

const LoginButton = ({ children, ...props }) => (
  <Button
    {...props}
    fullWidth
    type="submit"
    variant="contained"
    sx={{
      backgroundColor: '#10143D',
      fontSize: '15px',
      lineHeight: '24px',
      letterSpacing: '0.46px',
      textTransform: 'uppercase',
      '&:hover': {
        background: '#434A90',
      },
    }}
  >
    {children}
  </Button>
);
