export const WORK_REQUEST_ITEMS_VIEWS = {
  TABLE_VIEW: 'talbleView',
  FORGE_VIEW: 'forgeView',
};

export const buildTableStyles = (isLoading) => ({
  '& div[role="cell"]:nth-of-type(3)': {
    padding: 0,
  },
  '& .MuiDataGrid-cell': {
    maxHeight: 'none !important',
  },
  '& div.MuiDataGrid-pinnedRows': {
    zIndex: 9999,
  },
  '& div.MuiDataGrid-pinnedRows.error': {
    height: 'auto !important',
    minHeight: '56px',
  },
  '&.MuiDataGrid-root .MuiDataGrid-cell': {
    borderTop: 'none',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
  '& .MuiDataGrid-virtualScrollerContent--overflowed .MuiDataGrid-row--lastVisible .MuiDataGrid-cell': {
    borderBottomColor: 'rgba(224, 224, 224, 1)',
  },
  ...(isLoading
    ? {
        '&::before': {
          content: '""',
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.4)',
          zIndex: 999,
        },
      }
    : {}),
});

export const WORK_ORDER_STATUS_TYPES = {
  NotStarted: {
    name: 'Not Started',
    id: '7db39c5c-51ee-4dbe-9cfa-4c830669eb91',
  },
  Completed: {
    name: 'Completed',
    id: '6c0e7cbe-2348-4050-9ad5-57ea19b473d3',
  },
  InProgress: {
    name: 'In Progress',
    id: '38ecc785-8013-44b9-91c0-320892b9e941',
  },
  Blocked: {
    name: 'Blocked',
    id: '23fbdc04-7d47-42bd-a7f1-3af4a73460eb',
  },
  Hold: {
    name: 'Hold',
    id: '1a92be9c-563e-40c5-b63c-64a244c810ef',
  },
  Draft: {
    name: 'Draft',
    id: '19a67edd-4b5e-4b0b-a054-8c9681cfb448',
  },
};

const { Draft, NotStarted, InProgress, Blocked } = WORK_ORDER_STATUS_TYPES;

export const WO_STATUSES_ID_WITH_REQUIRED_NEED_BY = [Draft, NotStarted, InProgress, Blocked].map(({ id }) => id);
