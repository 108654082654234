import React from 'react';

import { Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Item from '@mui/material/ListItem';

import { CenteredLoadSpinner } from 'components';
import { Custom } from 'components/Icons';
import StackRow from 'components/StackRow';
import { getParsedDate } from 'helpers/dateFunctions';

import useDownloadPaths from './useDownloadPaths';

const DownloadZip = ({ zips }) => {
  const { downloadPath, loading } = useDownloadPaths();

  if (loading) return <CenteredLoadSpinner />;

  const nameUrl = (url) =>
    url
      .split('/')
      .find((el) => el.includes('.'))
      .replace(/\.zip/g, '')
      .split('-')
      .join(' ');

  return (
    <>
      <Box sx={{ minWidth: 275, backgroundColor: 'white' }}>
        <Typography
          sx={{
            fontSize: 13,
            textAlign: 'left',
            margin: '-5px 0 0px 13px',
            backgroundColor: 'white',
            position: 'absolute',
            lineHeight: 1,
          }}
          color="text.secondary"
          gutterBottom
        >
          Downloads
        </Typography>
        <Card
          variant="outlined"
          sx={{
            marginTop: '14px',
            border: '1px solid #bdbdbd',
          }}
        >
          <CardContent
            sx={{
              padding: '8px!important',
            }}
          >
            <Typography variant="body2">
              <Box>
                <Grid container spacing={0}>
                  {zips?.map((urlZip) => (
                    <Grid item xs={6} padding={0} margin={0}>
                      <Item sx={{ paddingTop: 0, paddingBottom: 0 }}>
                        <Stack key={urlZip.document} onClick={() => downloadPath(urlZip.document)}>
                          <StackRow
                            sx={{
                              cursor: 'pointer',
                              marginTop: '0',
                              '&:hover *': {
                                color: '#434A90 !important',
                                fill: '#434A90 !important',
                              },
                            }}
                          >
                            <Custom.DownloadIcon sx={{ width: '0.8em' }} style={{ color: '#4A93FF' }} />

                            <Typography
                              style={{
                                color: '#4A93FF',
                                textDecoration: 'none',
                                margin: '-10px 0 0 5px',
                                fontSize: '14px',
                                lineHeight: '1',
                              }}
                            >
                              {nameUrl(urlZip.document)}
                            </Typography>
                          </StackRow>
                          <Typography
                            sx={{
                              color: 'surface.lightSurface.secondary',
                              lineHeight: 1,
                              margin: '-7px 0 0 25px',
                              fontSize: '10.5px',
                            }}
                          >
                            Upload Date: {getParsedDate(urlZip.updatedOn)}
                          </Typography>
                        </Stack>
                      </Item>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default DownloadZip;
