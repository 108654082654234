import React, { ReactNode } from 'react';

import { Typography } from '@mui/material';

export interface IAggregatedAmountText {
  isEditing: boolean;
  isError: boolean;
  children: ReactNode;
}

const AggregatedAmountText = ({
  isEditing = false,
  isError = false,
  children,
}: IAggregatedAmountText) => {
  const color = ((): string => {
    if (isError) return 'error.dark';
    if (isEditing) return 'secondary.main';

    return 'surface.lightSurface.primary';
  })();

  return (
    <Typography variant="body2" sx={{ color }}>
      {children}
    </Typography>
  );
};

export default AggregatedAmountText;
