import React from 'react';

import { Stack, Typography } from '@mui/material';

import { IPropertiesOverlayProps } from './@types/PropertiesOverlayTypes';
import PropertiesOverlayBody from './PropertiesOverlayBody';
import PropertiesOverlayContainer from './PropertiesOverlayContainer';
import PropertiesOverlayDetails from './PropertiesOverlayDetails';
import PropertiesOverlayHeader from './PropertiesOverlayHeader';
import PropertiesOverlayImage from './PropertiesOverlayImage';

const PropertiesOverlay: React.FC<IPropertiesOverlayProps> = ({
  data: { partName, image, qty, quantity, unitOfMeasureCode, description },
  control,
  onSubmit,
  onClose,
}) => (
  <PropertiesOverlayContainer>
    <PropertiesOverlayHeader onClose={onClose} />
    <PropertiesOverlayBody>
      <Stack>
        <Typography
          noWrap
          variant="subtitle1"
          sx={{ color: 'surface.darkSurface.primary', width: '260px', height: 'auto' }}
        >
          {partName}
        </Typography>
      </Stack>

      <PropertiesOverlayImage image={image} partName={partName} />

      <PropertiesOverlayDetails
        control={control}
        onSubmit={onSubmit}
        unitQty={qty ?? quantity}
        uom={unitOfMeasureCode}
        description={description}
      />
    </PropertiesOverlayBody>
  </PropertiesOverlayContainer>
);

export default PropertiesOverlay;
