import React from 'react';

import { DateField } from './DateField';
import { DaysRemaining } from './DaysRemaining';
import { DescriptionField } from './DescriptionField';
import NameField from './NameField';
import { SelectField } from './SelectField';

export const WorkRequestInformation = () => (
  <>
    <NameField />
    <SelectField />
    <DateField />
    <DaysRemaining />
    <DescriptionField />
  </>
);
