import React, { useRef } from 'react';

import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import useOnScreen from 'hooks/useOnScreen';

import { usePartCategory } from '../PartCategory/PartCategoryContext';

const AddPartsCategories = ({ handleAddParts, addAllButtonEnabled, buttonSx }) => {
  const ref = useRef();
  const isIntersecting = useOnScreen(ref);
  const { selectedCategoryPartsCount } = usePartCategory();

  return (
    <>
      <Box ref={ref} />
      <Stack
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          width: '100%',
          maxWidth: '752px',
          position: 'sticky',
          top: 0,
          transition: 'background-color 0.5s ease',
          backgroundColor: isIntersecting ? 'transparent' : 'rgba(58, 58, 58, 0.9)',
          zIndex: 1,
        }}
      >
        <Typography variant="body2" sx={{ color: 'white' }}>
          {selectedCategoryPartsCount} results
        </Typography>
        <Button
          variant="text"
          disabled={!addAllButtonEnabled}
          color="secondary"
          sx={{ ...buttonSx, ...(!addAllButtonEnabled ? { opacity: '50%' } : {}) }}
          onClick={handleAddParts}
          data-cy="addAllPartCategoriesBtn"
        >
          ADD ALL
        </Button>
      </Stack>
    </>
  );
};

export default AddPartsCategories;
