import React from 'react';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';

const ItemCheckbox = ({ checked = false, sx = {}, onChange = () => {} }) => (
  <Box sx={{ mb: 4, display: 'flex' }}>
    <Checkbox
      sx={{ my: 'auto', padding: 0.25, ...sx }}
      color="secondary"
      checked={checked}
      onChange={(event) => onChange(event.target.checked)}
      inputProps={{ 'aria-label': 'controlled', 'data-testid': 'part-item-checkbox' }}
    />
  </Box>
);

export default ItemCheckbox;
