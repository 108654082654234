import React from 'react';

import MiniSpinner from 'components/MiniSpinner';
import StackRow from 'components/StackRow';
import { QuantityInput } from 'modules/Materials/TakeOff/Form';

import useUnitQuantityInput from './useUnitQuantityInput';

type Props = {
  value: number;
};

const UnitQuantityInput = ({ value }: Props) => {
  const { handleSubmit, control, onSubmit, loading: updateBOMItemLoading } = useUnitQuantityInput({ value });

  return (
    <StackRow sx={{ width: '100%' }}>
      {updateBOMItemLoading && <MiniSpinner size="16px" theme="secondary" />}
      <QuantityInput
        control={control}
        autoFocus
        isEditingQuantity
        disabled={updateBOMItemLoading}
        onKeyDown={async (event: React.KeyboardEvent<HTMLDivElement>) => {
          event.stopPropagation();
          if (event.code === 'Enter') await handleSubmit(onSubmit)();
        }}
        onBlur={() => handleSubmit(onSubmit)()}
      />
    </StackRow>
  );
};

export default UnitQuantityInput;
