import React from 'react';

import { IconButton, Tooltip, Stack, useTheme } from '@mui/material';

import { Custom } from 'components/Icons';

const OpenPlusModalButton = ({ onClick, disabled = false, title }) => {
  const { palette } = useTheme();

  return (
    <Tooltip title={title} placement="top" arrow disableHoverListener={disabled}>
      <Stack
        sx={{
          path: {
            fill: disabled
              ? palette.surface.lightSurface.disabled
              : 'surfaceColors.darkSurface.primary',
          },
        }}
      >
        <IconButton onClick={disabled ? undefined : onClick} size="large" disabled={disabled}>
          <Custom.AddBoxAlt />
        </IconButton>
      </Stack>
    </Tooltip>
  );
};

export default OpenPlusModalButton;
