import React, { useState, useEffect } from 'react';

import { InterfaceSelector } from '@evolvemep/foresite-components-ui-react';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { Custom } from 'components/Icons';
import useSetupModule from 'hooks/useSetupModule';
import { actionColors } from 'styles/theme/colors';

import { useWorkRequestsContext } from '../../WorkRequestsContext';
import { WR_INTERFACE_SELECTOR_REF_NAME } from '../utils/constants';

const InterfaceSelectorWorkRequest = ({ currentTable, forcedPosition }) => {
  const navigate = useNavigate();
  const [position, setPosition] = useState(null);
  const { currentModule } = useSetupModule();
  const { workRequestSelected } = useWorkRequestsContext();

  useEffect(() => {
    const interfaceSelectorRef = document.querySelector(`#${WR_INTERFACE_SELECTOR_REF_NAME}`);
    setTimeout(() => {
      setPosition(
        forcedPosition || {
          x: interfaceSelectorRef?.offsetLeft,
          y: interfaceSelectorRef?.offsetTop,
        },
      );
    }, 500);
  }, [forcedPosition]);

  useEffect(() => {
    function handleResize() {
      const interfaceSelectorRef = document.querySelector(`#${WR_INTERFACE_SELECTOR_REF_NAME}`);

      setPosition({
        x: interfaceSelectorRef?.offsetLeft,
        y: interfaceSelectorRef?.offsetTop,
      });
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = [
    {
      title: 'Work Requests',
      description: 'Single pane view',
      icon: <Custom.WorkRequests fill={actionColors.disabled} />,
      onClick: () => {
        if (currentTable === 'WorkRequestItemsList') {
          const withWorkRequest = workRequestSelected ? `/${workRequestSelected.workRequestId}` : '';
          navigate(`/${currentModule()}/work-requests${withWorkRequest}`);
        }
      },
    },
    {
      title: 'Work Requests + Drawing Register',
      description: 'Two pane view',
      icon: <Custom.DrawingRegister />,
      onClick: () => {
        const params = {
          workRequest: workRequestSelected?.workRequestId,
          nav: 'work-requests',
        };
        navigate({
          pathname: `/${currentModule()}/drawing-register`,
          search: `?${createSearchParams(params)}`,
        });
      },
    },
  ];

  return position ? (
    <InterfaceSelector
      startPosition={forcedPosition ? 'top right' : 'top left'}
      startOffset={forcedPosition || position}
      isDragDisabled
      openInHover
      items={currentTable === 'WorkRequestItemsList' ? options.reverse() : options}
    />
  ) : null;
};

export default InterfaceSelectorWorkRequest;
