/* eslint-disable import/no-cycle */
import React, { FC } from 'react';

import { BillOfMaterialLineItemByLBS } from 'graphql/types';

import LBSRow from '../LocationRow/LBSRow';
import { QuantityFieldType } from '../QuantityField/hooks/@types/fieldHookTypes';
import WorkPhasesTable from '../WorkPhasesTable/WorkPhasesTable';

export interface IRowsNested {
  show: boolean;
  rows?: BillOfMaterialLineItemByLBS[];
  nestLevel?: number;
  lineItemId: string;
  workPhases: BillOfMaterialLineItemByLBS[];
  locationData?: QuantityFieldType;
}
const RowsNested: FC<IRowsNested> = ({
  show = false,
  rows = [],
  nestLevel = 0,
  lineItemId,
  workPhases = [],
  locationData,
}: IRowsNested) => {
  if (!show) return <></>;
  return (
    <>
      {Boolean(locationData?.workPhases?.length) && (
        <WorkPhasesTable
          locationId={locationData?.locationId}
          lineItemId={lineItemId}
          nestLevel={nestLevel + 1}
          workPhases={locationData?.workPhases || []}
          LBSWorkPhases={workPhases}
        />
      )}
      {Boolean(rows?.length) &&
        rows.map((row: any) => (
          <LBSRow
            key={`${row.locationId}__${lineItemId}`}
            item={row}
            nestLevel={nestLevel + 1}
            lineItemId={lineItemId}
          />
        ))}
    </>
  );
};

export default RowsNested;
