import * as React from 'react';

import { gql, useMutation } from '@apollo/client';

import { addPartAttributeSelectVariant as ADD_VARIANTS } from 'graphql/mutations';

const useAttributeAPI = (attributes = []) => {
  const [addVariantMutation] = useMutation(gql(ADD_VARIANTS));

  const onAddNewAttributeVariant = React.useCallback(
    async ({ partAttributeId, textValue, codeValue }) => {
      const body = { partAttributeId, textValue, codeValue };
      const data = await addVariantMutation({
        variables: { body },
        update(cache, { data: attributeData }) {
          const attributeToUpdate = attributes.find(
            (item) => item?.partAttributeId === partAttributeId,
          );

          if (attributeData !== null && attributeToUpdate) {
            const identity = cache.identify(attributeToUpdate);
            cache.modify({
              id: identity,
              fields: {
                selectVariants(existingSelectVariants = []) {
                  return [...existingSelectVariants, attributeData?.addPartAttributeSelectVariant];
                },
              },
            });
          }
        },
      });
      return data;
    },
    [addVariantMutation, attributes],
  );

  return {
    callbacks: { onAddNewAttributeVariant },
  };
};

export default useAttributeAPI;
