import React, { useEffect, useMemo } from 'react';

import { Stack } from '@mui/material';

import useFetchWorkRequest from 'hooks-api/useFetchWorkRequest';
import useUrlParams from 'hooks/useUrlParams';
import WorkRequest from 'modules/Field/WorkRequests/WorkRequest/WorkRequest';
import { WORK_REQUEST_TABLES, useWorkRequestsContext } from 'modules/Field/WorkRequests/WorkRequestsContext';
import WorkRequestsListContainer from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsListContainer';

const DrawingRegisterWorkRequests = ({ filterComponent }) => {
  const { currentTable, setCurrentTable, setWorkRequest } = useWorkRequestsContext();
  const { params: searchObj } = useUrlParams();
  const workRequestId = searchObj?.workRequest;
  const { workRequest } = useFetchWorkRequest(workRequestId);

  useEffect(() => {
    if (workRequest) {
      setWorkRequest(workRequest);
      setCurrentTable(WORK_REQUEST_TABLES.WORK_REQUEST_ITEMS_LIST);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workRequest]);

  const isRenderWorkRequestTable = useMemo(
    () => currentTable === WORK_REQUEST_TABLES.WORK_REQUEST_LIST,
    [currentTable],
  );

  return (
    <Stack sx={{ flexGrow: 1 }}>
      {isRenderWorkRequestTable ? (
        <WorkRequestsListContainer filterComponent={filterComponent} isResponsive />
      ) : (
        <WorkRequest isResponsiveWR hideBulkActions />
      )}
    </Stack>
  );
};

export default DrawingRegisterWorkRequests;
