import React from 'react';

import ComponentPaneLayout from 'app/Layout/ReactGridLayout/ComponentPaneLayout';
import { FacilityAndProjectWrapper } from 'components';
import { PopoverFilterProvider } from 'components/Popover/FilterPopover/context/PopoverFilterContext';
import { WorkOrderUpdateProvider } from 'hooks-api/useWorkOrderUpdate';
import { UpdateWorkRequestStatusProvider } from 'modules/Field/WorkRequests/WorkRequest/hooks/useUpdateWorkRequestStatus/useUpdateWorkRequestStatus';

import DesignMembers from './DesignMembers/DesignMembers';
import { DrawingRegister, DrawingRegisterProvider } from './DrawingRegister';
import DrawingStatus from './DrawingStatus/DrawingStatus';

const withComponentPaneLayout = ({ WrappedComponent }) => (
  <ComponentPaneLayout>
    <WrappedComponent />
  </ComponentPaneLayout>
);

export const DesignMembersInterfaceNavContent = () => (
  <ComponentPaneLayout>
    <DesignMembers />
  </ComponentPaneLayout>
);

export const DrawingStatusInterfaceNavContent = () => (
  <ComponentPaneLayout>
    <DrawingStatus />
  </ComponentPaneLayout>
);

export const DrawingRegisterInterfaceNavContent = () => (
  <FacilityAndProjectWrapper isFacilityRequired isProjectRequired>
    <WorkOrderUpdateProvider>
      <UpdateWorkRequestStatusProvider>
        <DrawingRegisterProvider>
          <PopoverFilterProvider>
            <DrawingRegister />
          </PopoverFilterProvider>
        </DrawingRegisterProvider>
      </UpdateWorkRequestStatusProvider>
    </WorkOrderUpdateProvider>
  </FacilityAndProjectWrapper>
);

export const Dashboard = () => withComponentPaneLayout(<div>Design dashboard</div>);

export const DesignPropertiesInterfaceNavContent = () =>
  withComponentPaneLayout(<div>Design properties dashboard</div>);
