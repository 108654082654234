import React, { forwardRef } from 'react';

import { useTheme } from '@emotion/react';
import { v4 as uuid } from 'uuid';

import usePartImage from '../CatalogSetup/ItemsSection/usePartImage';
import CatalogPopup from '../components/CatalogPopup/CatalogPopup';
import { useAssemblyEditorContext } from './ReactFlowProviders/AssemblyEditorContext';
import { ASSEMBLY_PART_NODE_HEIGHT, DEFAULT_CATALOG_PART_POSITION } from './Utils/constants';

const partItemsToPartNodes = (partItems = [], yOffset = 0) =>
  partItems.map((partItem, index) => ({
    id: uuid(),
    sourcePosition: 'right',
    type: `partType`,
    targetPosition: 'left',
    data: partItem,
    position: {
      x: DEFAULT_CATALOG_PART_POSITION.positionX,
      y: DEFAULT_CATALOG_PART_POSITION.positionY + yOffset + (ASSEMBLY_PART_NODE_HEIGHT + 10) * index,
    },
  }));

const AssemblyEditorCatalogPopup = forwardRef(({ onPanelClose, onDock }, ref) => {
  const theme = useTheme();
  const { getPartImage } = usePartImage();

  const { setElements, isUpdateAssembly, setUpdateElements, mainPartItem, elements } = useAssemblyEditorContext();
  const addItems = async (payload) => {
    const firstColumnElementsY = elements.filter(({ position }) => position?.x < 20).map(({ position }) => position.y);
    const yOffset = firstColumnElementsY.length
      ? Math.max(...firstColumnElementsY) + (ASSEMBLY_PART_NODE_HEIGHT + 5)
      : 0;
    const partItemNodes = partItemsToPartNodes(payload, yOffset);
    const nodesWithImages = await Promise.all(
      [...partItemNodes].map(async (node) => {
        if (!node?.data?.partImageDocumentId) return node;
        const image = await getPartImage(node?.data?.partImageDocumentId);
        return { ...node, data: { ...node.data, image } };
      }),
    );
    setElements((current) => [...current, ...nodesWithImages]);
    if (isUpdateAssembly)
      setUpdateElements((current) => ({
        ...current,
        addNodes: [...current.addNodes, ...partItemNodes],
      }));
  };

  return (
    <CatalogPopup
      ref={ref}
      onClose={onPanelClose}
      onDock={onDock}
      onAddItems={(payload) => addItems(payload)}
      moduleButtonsColor={`${theme.palette.secondary.mainDarkBackground} !important`}
      noAssemblies
      disablePartId={mainPartItem?.partId}
      partName={mainPartItem?.partName ?? null}
    />
  );
});

export default AssemblyEditorCatalogPopup;
