import React from 'react';

import { MoreMenu } from 'components';

import CollapseChildrenIcon from '../../components/CollapseChildrenIcon';
import { RowIcon } from '../../components/RowPrefix';
import RowTitle from '../../components/RowTitle';
import StyledRow from '../../components/StyledRow';
import { ROW_TYPES } from '../../constants/constants';
import { useLocationsAndWorkPhases } from '../../contexts/LocationsAndWorkPhaseContext';
import { useMoreMenu } from '../hooks/useMoreMenu/useMoreMenu';

const WorkPhaseRowInfo = React.memo(({ node, depth = 0, isPreview, parent }) => {
  const { buildOptions } = useMoreMenu(node, parent);
  const {
    locations: { previewActiveRow, setPreviewActiveRow, isEditingALocation },
  } = useLocationsAndWorkPhases();

  return (
    <StyledRow
      isActive={node.workPhaseId === (isPreview ? previewActiveRow : {}).workPhaseId}
      onClick={() => (isPreview ? setPreviewActiveRow(node) : {})}
    >
      <CollapseChildrenIcon sx={{ ml: 2.8 * depth - 0.5 }} showIcon={false} />
      <RowIcon type={ROW_TYPES.WORK_PHASE} green />
      <RowTitle>{node.workPhaseName}</RowTitle>
      {!isPreview && (
        <MoreMenu options={buildOptions()} disabled={isEditingALocation} sx={{ fontSize: 21 }} />
      )}
    </StyledRow>
  );
});

export default WorkPhaseRowInfo;
