/* eslint-disable */

import React, { ChangeEvent, useEffect, useMemo } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { useController, UseControllerProps } from 'react-hook-form';

import { numCheck } from './utils';
import { ForesiteTextField } from '@evolvemep/foresite-components-ui-react';

type OnErrorOut = {
  [name: string]: boolean;
};

type NumberForesiteFieldOwnProps = {
  maxLength?: number | null;
  onError?: (state: OnErrorOut) => void;
  rules: any;
  onMouseOver?: () => void;
  showHelperText?: boolean;
  uncontrolledOnChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};

type MergeNumberForesiteFieldProps = UseControllerProps &
  Partial<Omit<TextFieldProps, 'rules'>> &
  NumberForesiteFieldOwnProps;

export type NumberForesiteFieldTypeProps = { type?: 'number' | 'phone' };

export type NumberForesiteFieldProps = Omit<MergeNumberForesiteFieldProps, 'type'> & NumberForesiteFieldTypeProps;

// This component was made to support only <input type='number' />
// If you want use other input types, please use ForesiteTextField as a base component
// and create a new Component with that specific type
export const NumberForesiteField = ({
  // set useController props
  type = 'number',
  name,
  rules = {},
  control,
  shouldUnregister = true,
  defaultValue = '',
  showHelperText = true,
  // main properties
  helperText,
  onChange,
  uncontrolledOnChange,
  onBlur,
  onKeyDown,
  maxLength = null,
  sx,
  onError,
  ...props
}: NumberForesiteFieldProps) => {
  const {
    field: { onBlur: onBlurField, onChange: onChangeField, value },
    fieldState: { error }
  } = useController({ name, control, defaultValue, rules, shouldUnregister });

  const onBlurCallback = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    onBlurField();
    if (onBlur) onBlur(event);
  };

  const onChangeCallback = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChangeField(event);
    if (onChange) onChange(event);
  };

  const onKeyDownCallback = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const isValid = numCheck(event);
    if (!isValid) {
      event.preventDefault();
      return;
    }
    if (onKeyDown) onKeyDown(event);
  };

  const propsComponent = useMemo(() => {
    const defaultProps: Partial<TextFieldProps> = {
      autoComplete: 'none',
      fullWidth: true,
      autoFocus: false,
      variant: 'outlined',
      margin: 'normal',
      size: 'medium',
      required: false
    };

    const isError = Boolean(error);
    const errorMessage = isError ? error?.message ?? helperText : helperText;
    const auxHelperText = showHelperText ? helperText ?? errorMessage : '';

    const mainProps: any = {
      error: isError,
      helperText: auxHelperText,
      value,
      onBlur: onBlurCallback,
      onChange: uncontrolledOnChange || onChangeCallback,
      onKeyDown: onKeyDownCallback,
      sx,
      maxLength: rules?.maxLength?.value ?? maxLength
    };

    return {
      ...defaultProps,
      ...mainProps
    };
  }, [error, value, onBlurCallback, uncontrolledOnChange, onChangeCallback, sx, maxLength, helperText, rules]);

  const onErrorHandler = (error: boolean) => {
    const payload = { [name]: error };
    if (onError) {
      onError(payload);
    }
  };

  return (
    <ForesiteTextField
      // override
      {...propsComponent}
      {...props}
      onError={onErrorHandler}
      type={type}
    />
  );
};
