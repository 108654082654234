/* eslint-disable camelcase */
export type GetCompanyProductPoolParams = {
  IncludeExpired?: boolean | null;
  LicenseTypeId?: string | null;
  LicenseClassId?: string | null;
};

export type GetCompanyProductPoolResponse = {
  companyId: string;
  productPool: ProductPool[];
};

export type EntitlementCreateParams = {
  UserIds: string[];
  ProductdPoolIds: string[];
};

export type EntitlementCreateResponse = {
  entitlementId: EntitlementId;
  userId: string;
  productPoolId: string;
  productPool: ProductPool;
  secureData: any;
  secureSignature: any;
};

export type EntitlementGetListParams = {
  userId?: string;
  productName?: string;
  ClientId?: string;
};

declare const entitlementIdSymbol: unique symbol;
export type EntitlementId = string & { [entitlementIdSymbol]: never };

export type UpdateDeleteEntitlementParams = {
  entitlementId: EntitlementId;
};

export type Entitlement = {
  entitlementId: EntitlementId;
  userId: string;
  productPoolId: string;
  isCheckedOut: boolean | BooleanStringOptions;
  checkedOutEndDate: string;
  productPool: ProductPool;
  secureData: any;
  secureSignature: any;
};

export interface AddProductPoolParams {
  companyId?: string | null;
  productId?: string | null;
  poolQuantity?: number | null;
  customData?: string | null;
  term?: number | null;
  startDate?: string | null;
  termDate?: string | null;
  trialTermDate?: string | null;
  trialDuration?: string | null;
}

export interface ProductPool {
  productPoolId: string;
  desktopLicenseId?: string;
  companyId: string;
  company: Company;
  productId: string;
  product: Product;
  bundle: any;
  poolQuantity: number;
  poolAvailableQuantity: number;
  customData: any;
  renewalDate: string;
  startDate: string;
  status: 'Expired' | 'Active' | 'Pending';
  label: string;
  type: string;
}

export type Product = {
  productId: string;
  productName: string;
};

export type GetCompanyParams = {
  companyId: string;
};

export interface Company {
  companyId: string;
  companyName: string;
  companyDownloadPaths: DownloadPath[];
  status: any;
  size: any;
  type: any;
  creationDate: string;
  address: {
    addressId: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zip: string;
    country: string;
  };
  hq: string;
  mfA_Required: boolean | BooleanStringOptions;
  data_Analytics: boolean | BooleanStringOptions;
  companyImageId: string;
  hubSpotId: string;
}

export type DownloadPath = {
  document: string;
  updatedBy: string;
  updatedOn: string;
};

export type LicensesGetParams = {
  CompanyId?: string;
  ProductOrBundleId?: string;
  ProductOrBundleName?: string;
  ExcludeExpired?: boolean;
  Skip?: number;
  Take?: number;
  OrderBy?: string;
};

export type UserGetMultisearchParams = {
  CompanyId: string;
  IncludeDeleted?: boolean | IncludeDeletedOptions;
};

export enum IncludeDeletedOptions {
  YES = 'yes',
  NO = 'no',
  ONLY_DELETED = 'only deleted',
}

export enum BooleanStringOptions {
  TRUE = 'true',
  FALSE = 'false',
}

export type UserGetMultisearchResponse = {
  data: User[];
};

export type User = {
  userId: string;
  userIdentifier: any;
  userName: string;
  userEmail: string;
  userFirstName: string | null;
  userLastName: string | null;
  workPhoneNumber: string | null;
  personalPhoneNumber: string | null;
  companyId: string;
  company: Company;
  userStatusId: string | null;
  userStatus: UserStatus;
  userPhotoId: string | null;
  userTypes: UserType[];
  expiresAt: string;
  confirmedAt: string | null;
  inviteStatus: string | null;
  acceptedLatestEula: boolean;
  isAdmin: boolean;
  isMarketingNotificationAllowed: boolean | null;
  isProductNotificationAllowed: boolean | null;
  isDeleted: boolean;
  hubSpotId: string | null;
};

export type UserStatus = {
  userStatusId: string | null;
  userStatusName: string | null;
  userStatusCode: string | null;
  userStatusDescription: string | null;
  sortOrder?: number;
};

export type UserType = {
  userTypeId: string;
  typeName: string;
  typeId: string;
};
