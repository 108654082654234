export const buildDarkStyles = (darkMode, palette) => ({
  '& * :not(.MuiCircularProgress-circle)': {
    color: darkMode && 'surface.darkSurface.primary',
    fill: darkMode && `${palette.surface.darkSurface.primary} !important`,
  },
  '& th span': {
    fontWeight: 600,
  },
  '& td, & th': {
    borderBottom: '1px solid rgba(224, 224, 224, .7)',
  },
  '.MuiCircularProgress-svg': {
    color: 'secondary.mainDarkBackground',
  },
});
