import { useState } from 'react';

import { gql, useQuery } from '@apollo/client';

import { useUser } from 'app/UserContext';
import { projectTypes as PROJECT_TYPES } from 'graphql/queries';

const useGetProjectTypeOptions = () => {
  const { user } = useUser();

  const [projectTypeOptions, setProjectTypeOptions] = useState([]);

  const { loading } = useQuery(gql(PROJECT_TYPES), {
    variables: {
      query: { companyId: user.companyId },
    },
    onCompleted: ({ projectTypes }) => {
      const formattedOptions = projectTypes.map(({ projectTypeId, projectTypeName }) => ({
        value: projectTypeId,
        label: projectTypeName,
      }));
      setProjectTypeOptions(formattedOptions);
    },
  });

  return { projectTypeOptions, loading };
};

export default useGetProjectTypeOptions;
