import { useCallback, useMemo, useRef } from 'react';

import { useApolloClient, useLazyQuery } from '@apollo/client';

const checkHasMore = (response, limit) => {
  if (!response) return false;
  const { data = {} } = response;

  const paginatedResult = Object.values(data)[0];
  return paginatedResult?.length === limit;
};

const OrderDirection = {
  ASCENDING: 'asc',
  DESCENDING: 'desc',
};

// eslint-disable-next-line max-lines-per-function
const useLazyPaginatedQuery = (
  QUERY,
  fetchPolicy = '',
  limit = 10,
  notifyOnNetworkStatusChange = true,
  errorPolicy = 'none',
) => {
  const sortingKeyword = useRef('');
  const hasMore = useRef(true);
  const orderColumnsRef = useRef(new Map());
  const client = useApolloClient();

  const [fetchData, { loading, data, fetchMore, refetch, startPolling, stopPolling, called }] = useLazyQuery(QUERY, {
    fetchPolicy,
    errorPolicy,
    notifyOnNetworkStatusChange,
  });

  const searchHandler = useCallback(
    async (searchPhrase, variables) => {
      hasMore.current = true;
      await refetch({ query: { skip: 0, take: limit, searchPhrase, ...variables } });
    },
    [refetch, limit],
  );

  const paginationHandler = useCallback(
    async (skip, variables) => {
      if (!hasMore.current) return;
      const orderColumns = [...orderColumnsRef.current.keys()];
      const lastOrderColumnName = orderColumns[orderColumns.length - 1];
      await fetchMore({
        variables: {
          query: {
            skip,
            take: limit,
            ...variables,
            ...(Boolean(lastOrderColumnName) && {
              orderBy: `${lastOrderColumnName}:${orderColumnsRef.current.get(lastOrderColumnName)}`,
            }),
          },
        },
      }).then((moreData) => {
        if (!checkHasMore(moreData, limit)) hasMore.current = false;
      });
    },
    [fetchMore, limit],
  );

  const lazyLoad = useCallback(
    async (variables, pollInterval = 0, queryParams = {}, queryConfig = {}) => {
      hasMore.current = true;
      return fetchData({
        variables: {
          ...queryParams,
          query: { skip: 0, take: limit, ...variables },
        },
        ...queryConfig,
        pollInterval,
      });
    },
    [fetchData, limit],
  );

  const paginatedLazyLoad = useCallback(
    async (params) =>
      lazyLoad(params).then((response) => {
        if (!checkHasMore(response, limit)) hasMore.current = false;
      }),
    [lazyLoad, limit],
  );

  const refetchExistingPages = useCallback(
    async (variables, resetHasMore = false, newItemsAdded = 0) => {
      const existingData = client.readQuery({
        query: QUERY,
        variables: { query: { ...variables } },
      });

      if (!existingData) return;

      const [firstKey] = Object.keys(existingData);
      const dataArray = existingData[firstKey];
      const dataLength = dataArray?.length + newItemsAdded;

      await fetchData({ variables: { query: { skip: 0, take: limit, ...variables } } });

      const refetchTasks = [...Array(Math.ceil(dataLength / limit)).keys()]
        .slice(1)
        .map((number) => fetchMore({ variables: { query: { skip: number * limit, take: limit, ...variables } } }));

      await Promise.all(refetchTasks);

      /* Reset hasMore in the scenario where after refetch the result data size may increase */
      if (resetHasMore) hasMore.current = true;
    },
    [QUERY, client, fetchData, fetchMore, limit],
  );

  const getNextOrderDirection = useCallback((columnName) => {
    const hasOrderDirection = orderColumnsRef.current.has(columnName);
    if (!hasOrderDirection) return OrderDirection.ASCENDING;

    return orderColumnsRef.current.get(columnName) === OrderDirection.ASCENDING
      ? OrderDirection.DESCENDING
      : OrderDirection.ASCENDING;
  }, []);

  const onOrderby = useCallback(
    async ({ variables = {}, columnName = '', orderByKey = 'orderBy' }) => {
      try {
        const orderDirection = getNextOrderDirection(columnName);
        sortingKeyword.current = columnName;
        await refetch({
          query: {
            ...variables,
            [orderByKey]: `${columnName}:${orderDirection}`,
            skip: 0,
            take: limit,
          },
        });

        orderColumnsRef.current.set(columnName, orderDirection);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error({ error });
      }
    },
    [getNextOrderDirection, limit, refetch],
  );

  const dataMemoized = useMemo(() => data, [data]);

  return [
    {
      lazyLoad,
      paginatedLazyLoad,
      paginationHandler,
      searchHandler,
      refetch,
      refetchExistingPages,
      onOrderby,
      startPolling,
      stopPolling,
    },
    { loading, data: dataMemoized, sortingKeyword, called },
  ];
};

export default useLazyPaginatedQuery;
