import React, { useContext, useEffect } from 'react';

import { Typography, Stack } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';

import { Modal } from 'components';
import { WORK_ORDER_STATUS_TYPES } from 'modules/Shop/WorkOrders/WorkOrder/constants/constants';
import { WorkOrderItemsContext } from 'modules/Shop/WorkOrders/WorkOrder/context/WorkOrderItemsContext';
import { useUpdateWorkOrderItemDataLayer } from 'modules/Shop/WorkOrders/WorkOrder/hooks/dataLayer/useUpdateWorkOrderItemDataLayer';

import { ASSEMBLY_ITEM_TYPE_ID } from '../constants';
import { WorkRequestItemsContext } from '../WorkRequestItemsContext';
import { useCatalogModal, useCatalogModalQuery } from './CatalogModalContext';
import CatalogSelector from './CatalogSelector';
import CategoriesTree from './CategoriesTree';
import ItemDetailsForm from './ItemDetailsForm';
import ModalFooter from './ModalFooter';

const useGetFormStatus = (formState, addAssemblyLoading) => {
  const { selectedCategory, manufacturer, UOM } = useCatalogModal();
  const isValid = formState.isValid && !!selectedCategory && !!manufacturer && !!UOM;
  const isLoading = addAssemblyLoading || formState.isSubmitting;
  return { isValid, isLoading };
};

// eslint-disable-next-line max-lines-per-function
const ModalToCreateCatalogPart = ({ open, setShowModal, selectedItem = {} }) => {
  const workRequestItemsContext = useContext(WorkRequestItemsContext);
  const workOrderItemsContext = useContext(WorkOrderItemsContext);
  const { handleUpdateWorkOrderItem } = useUpdateWorkOrderItemDataLayer(workOrderItemsContext?.workOrder);
  const { addPartToCatalog, fetchTasksInBOP, addAssembly, addAssemblyLoading } = useCatalogModalQuery(
    null,
    selectedItem.billOfProcessId,
  );
  const {
    selectedCategory,
    resetStates,
    manufacturer,
    UOM,
    nodesAndEdges,
    setOpenSnackBar,
    partCreated,
    setPartCreated,
  } = useCatalogModal();
  const methods = useForm({ mode: 'all' });
  const { handleSubmit } = methods;
  const { isValid, isLoading } = useGetFormStatus(methods.formState, addAssemblyLoading);
  const methodsAndFormStatus = { ...methods, isValid, isLoading };

  const onClose = () => {
    setShowModal(false);
    resetStates();
  };

  const onSubmit = handleSubmit(async (data) => {
    if (workRequestItemsContext) {
      await workRequestItemsContext.updateWorkRequestItemMutation({
        variables: {
          params: { workRequestItemId: selectedItem.workRequestItemId },
          body: {
            workRequestId: selectedItem.workRequestId,
            workRequestItemTypeId: ASSEMBLY_ITEM_TYPE_ID,
            unitOfMeasureId: UOM,
            workRequestItemName: data.PartNameField,
            quantity: selectedItem.quantity,
          },
        },
      });
    } else if (
      workOrderItemsContext &&
      workOrderItemsContext.workOrder.workOrderStatusTypeId !== WORK_ORDER_STATUS_TYPES.Completed.id
    ) {
      await handleUpdateWorkOrderItem({
        unitOfMeasureId: UOM,
        workOrderItemName: data.PartNameField,
        quantity: selectedItem.quantity,
        workOrderItemId: selectedItem.workOrderItemId,
        itemType: ASSEMBLY_ITEM_TYPE_ID,
      });
    }

    const res = await addPartToCatalog({
      variables: {
        body: {
          partName: data.PartNameField,
          description: data.Description,
          unitOfMeasureId: UOM,
          manufacturerId: manufacturer,
          partCategoryId: selectedCategory,
        },
      },
    });
    setPartCreated(res.data.addPart);
  });

  useEffect(() => {
    if (!partCreated || !selectedItem.billOfProcessId || !nodesAndEdges) return;
    const { nodes, edges } = nodesAndEdges;
    addAssembly({
      variables: { body: { partId: partCreated.partId, nodes, edges } },
    }).then(() => {
      setPartCreated(null);
      setOpenSnackBar(true);
      onClose();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodesAndEdges]);

  useEffect(() => {
    if (selectedItem.billOfProcessId) fetchTasksInBOP();
    // eslint-disable-next-line
  }, []);

  return (
    <FormProvider {...methodsAndFormStatus}>
      <Modal
        open={open}
        onClose={onClose}
        title="Add to Catalog"
        paperSx={{
          width: '815px',
          height: '666px',
          maxWidth: '815px !important',
        }}
        footer={<ModalFooter onClose={onClose} onSubmit={onSubmit} isValid={isValid} isLoading={isLoading} />}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack>
            <CatalogSelector />
            <InstructionPrompt />
            <Stack direction="row">
              <CategoriesTree />
              <ItemDetailsForm selectedItem={selectedItem} />
            </Stack>
          </Stack>
        </form>
      </Modal>
    </FormProvider>
  );
};

export default ModalToCreateCatalogPart;

const InstructionPrompt = () => {
  const { selectedCatalog } = useCatalogModal();
  return selectedCatalog?.current ? (
    <Typography variant="body1" margin="16px 0 16px 0">
      Start by selecting a catalog
    </Typography>
  ) : (
    <Typography variant="body1" margin="16px 0 16px 0">
      Select a category to add the item
    </Typography>
  );
};
