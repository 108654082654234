import React from 'react';

import { Typography, Stack } from '@mui/material';

import OverFlowTooltip from 'components/Tooltip/OverFlowTooltip';
import { truncateText } from 'helpers/stringFunctions';
import { shopColors, surfaceColors } from 'styles/theme/colors';

const textEllipsisStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  ml: 2,
  width: 160,
};

interface OverFlowComponentProps {
  selectDocument: () => void;
  externalUrl: string;
  documentName: string;
  isSelectedDocument: boolean;
}

const MAX_LENGTH_CHARACTERS = 21;

const OverFlowComponent = ({
  selectDocument,
  externalUrl,
  documentName,
  isSelectedDocument,
}: OverFlowComponentProps) => (
  <Stack onClick={selectDocument}>
    <OverFlowTooltip
      showAlways={externalUrl?.length > MAX_LENGTH_CHARACTERS || documentName?.length > MAX_LENGTH_CHARACTERS}
      title={externalUrl || documentName}
    >
      <Typography
        variant="body2"
        sx={{
          cursor: 'pointer',
          color: isSelectedDocument ? shopColors.main : surfaceColors.lightSurface.primary,
          fontWeight: isSelectedDocument ? '700' : 'normal',
          '&:hover': { color: 'secondary.main', textDecoration: 'underline' },
          ...textEllipsisStyle,
        }}
      >
        {externalUrl || (documentName ? truncateText(documentName, MAX_LENGTH_CHARACTERS) : '')}
      </Typography>
    </OverFlowTooltip>
  </Stack>
);

export default OverFlowComponent;
