import React from 'react';

import { useCatalogSetupContext } from '../../CatalogSetupHeader/CatalogSetupContext';
import CategoryListItem from './CategoryListItem';
import { useCategoryListQuery } from './useCategoryListQuery';

const CategoriesList = ({
  depth = 0,
  categoryId = null,
  hiearachy = [],
  refetchParentCategory = () => {},
  sxItem,
  showMenu,
}) => {
  const { selectedCatalog } = useCatalogSetupContext();
  const { fetchCategories, calledCategories, categoriesData, refetchCategories } = useCategoryListQuery(categoryId);

  if (!calledCategories && selectedCatalog) {
    fetchCategories();
  }

  return (
    <>
      {categoriesData?.partCategory?.map((category) => {
        const isCategoryInHiearachy = hiearachy.some(
          ({ partCategoryId }) => partCategoryId === category.partCategoryId,
        );

        return (
          <CategoryListItem
            data-testid={`category-${category.partCategoryId}`}
            key={category.partCategoryId}
            depth={depth}
            isInHiearachy={isCategoryInHiearachy}
            category={category}
            renderList={() => (
              <CategoriesList
                depth={depth + 1}
                categoryId={category.partCategoryId}
                hiearachy={hiearachy}
                refetchParentCategory={refetchCategories}
                sxItem={sxItem}
                showMenu={showMenu}
              />
            )}
            fetchCategories={fetchCategories}
            refetchCategories={refetchCategories}
            refetchParentCategory={refetchParentCategory}
            sx={{ ...sxItem }}
            showMenu={showMenu}
          />
        );
      })}
    </>
  );
};

export default CategoriesList;
