import React from 'react';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { StatusCell, ThemedCheckBox } from 'components';
import MoreOptionsDropdown from 'components/TableComponents/MoreOptionsDropdown/MoreOptionsDropdown';

import MoreTableRowOptions from './MoreTableRowOptions';

const AdminTableRow = ({
  member,
  handleRowClick,
  isItemSelected,
  hasDeleteOptionDisabled = false,
}) => (
  <TableRow
    key={member.userId}
    onClick={(e) => handleRowClick(e, member)}
    role="checkbox"
    hover
    tabIndex={-1}
    aria-checked={isItemSelected}
    selected={isItemSelected}
  >
    <TableCell padding="checkbox">
      <ThemedCheckBox
        checked={isItemSelected}
        inputProps={{ 'aria-labelledby': member.user.userId }}
      />
    </TableCell>
    <TableCell align="left">--</TableCell>
    <TableCell align="left">{`${member.user.userFirstName || '--'} ${
      member.user.userLastName || '--'
    }`}</TableCell>
    <TableCell align="left">{member.user.userEmail}</TableCell>
    <TableCell align="left">--</TableCell>
    <TableCell align="left">{member.user.workPhoneNumber || '--'}</TableCell>
    <TableCell align="left">{member.user.personalPhoneNumber || '--'}</TableCell>
    <StatusCell status={member.isDeleted ? 'Deleted' : member.inviteStatus} />
    <TableCell>
      <MoreOptionsDropdown
        optionsRenderer={() => (
          <MoreTableRowOptions
            user={member.user}
            hasDeleteOptionDisabled={hasDeleteOptionDisabled}
          />
        )}
      />
    </TableCell>
  </TableRow>
);
export default AdminTableRow;
