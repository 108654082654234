import { useActiveRow } from '../contexts/ActiveRowContext';
import useLocationsDataLayer from './useMapWorkPhasesToLocations';

export const useDragFunctions = () => {
  const { activeRows } = useActiveRow();
  const { mapWorkPhasesToLocation } = useLocationsDataLayer();

  const handleMapWorkPhasesToLocation = (locationId, activeRows) => {
    const workPhaseIds = activeRows.map(({ id }) => id);
    mapWorkPhasesToLocation(locationId, workPhaseIds);
  };

  const dragToOtherTable = (dragInfo) => {
    const { source, destination } = dragInfo;
    const [originTableName] = source.droppableId.split('_');
    const [destinationTableName, destinationTableId] = destination.droppableId.split('_');
    if (originTableName === 'scopePackage' && destinationTableName === 'location') {
      handleMapWorkPhasesToLocation(destinationTableId, activeRows);
    }
  };

  const onDragEnd = (dragInfo) => {
    const { source, destination } = dragInfo;
    if (!destination) return;
    if (source.index === destination.index && source.droppableId === destination.droppableId)
      return;
    if (source.droppableId === destination.droppableId) return;

    dragToOtherTable(dragInfo);
  };

  return { onDragEnd };
};
