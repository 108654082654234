const createDelimiterOption = (value, label, visibleValue) => ({
  value,
  label: `<span style="display: flex; align-items: center">${
    visibleValue || value
  }&nbsp;&nbsp;&nbsp;&nbsp;<span class="hideOnSelected">${label}</span></span>`,
});

export default function useDelimiterOptions() {
  const delimiterOptions = [
    createDelimiterOption('none', 'None', ' '),
    createDelimiterOption(',', 'Comma'),
    createDelimiterOption('-', 'Dash'),
    createDelimiterOption('.', 'Period'),
    createDelimiterOption('|', 'Pipe'),
    createDelimiterOption('“', 'Quote'),
    createDelimiterOption(';', 'Semicolon'),
    createDelimiterOption('/', 'Slash'),
    createDelimiterOption(
      ' ',
      'Space',
      `<span style="margin: 0px -4px 0 -2px; padding-right: 8px; transform: rotate(-90deg)">[</span>`,
    ),
    createDelimiterOption('_', 'Underscore'),
  ];

  return { delimiterOptions };
}
