import React from 'react';

import { Typography, Stack } from '@mui/material';

const IdentifierHeader = () => {
  const styles = { fontWeight: 700, fontSize: 14, lineHeight: 24, marginLeft: '20px' };
  return (
    <Stack direction="row">
      <Typography sx={styles}>Type / Id / Name</Typography>
    </Stack>
  );
};

export default IdentifierHeader;
