import React from 'react';

import { Button, IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';

import { Custom } from 'components/Icons';
import { VARIANT_ERROR, VARIANT_INFO, VARIANT_SUCCESS } from 'constants/snackbarConstants';

const textPlural = (list) => (list.length > 1 ? 's' : '');
const verbPlural = (list) => (list.length > 1 ? 'are' : 'is');

export const useNotificationMsg = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const getMsg = (notificationObj, singleDocExists) => {
    const { addList, alreadyMappedList } = notificationObj;

    const mapPlural = textPlural(addList);
    const alreadyMappedPlural = textPlural(alreadyMappedList);
    const verb = verbPlural(alreadyMappedList);

    if (addList.length && alreadyMappedList.length) {
      return enqueueSnackbar(
        `${addList?.length} drawing${mapPlural} successfully mapped \n${alreadyMappedList?.length} drawing${alreadyMappedPlural} was already mapped`,
        { style: { whiteSpace: 'pre-line' }, preventDuplicate: true, ...VARIANT_INFO },
      );
    }
    if (!addList.length && !alreadyMappedList.length && singleDocExists) {
      enqueueSnackbar(`Drawing${alreadyMappedPlural} is already mapped to task`, {
        preventDuplicate: true,
        ...VARIANT_ERROR,
      });
      return null;
    }
    if ((!addList.length && alreadyMappedList.length > 1) || (!addList.length && alreadyMappedList.length)) {
      enqueueSnackbar(`Drawing${alreadyMappedPlural} ${verb} already mapped to task`, {
        preventDuplicate: true,
        ...VARIANT_ERROR,
      });
      return null;
    }
    if (addList.length || (!addList.length && !singleDocExists)) {
      return enqueueSnackbar(`Drawing${mapPlural} successfully mapped`, {
        preventDuplicate: true,
        ...VARIANT_SUCCESS,
      });
    }

    return null;
  };

  const getMoveToFolderMsg = (documentIds, folderName, onClick = () => {}) =>
    enqueueSnackbar(`${documentIds.length} drawing${textPlural(documentIds)} successfully moved to ${folderName}`, {
      preventDuplicate: true,
      ...VARIANT_SUCCESS,
      action: (key) => (
        <>
          <Button sx={{ color: 'white' }} size="small" onClick={onClick}>
            Go to folder
          </Button>
          <IconButton onClick={() => closeSnackbar(key)} sx={{ ml: 2, color: 'white' }} size="small">
            <Custom.CloseIcon />
          </IconButton>
        </>
      ),
    });

  return { getMsg, getMoveToFolderMsg };
};

export default useNotificationMsg;
