import { gql, useApolloClient, ObservableQuery } from '@apollo/client';

import { billOfMaterialItem as BOM_ITEM } from 'graphql/queries';

export const useRefetchBOMItems = () => {
  const client = useApolloClient();
  const refetchBOMItems = async (projectId: string) =>
    client.refetchQueries({
      include: [gql(BOM_ITEM)],
      onQueryUpdated(observableQuery: ObservableQuery) {
        if (!projectId) throw new Error('There was an error getting items.');
        return getBOMItemsCurrentLevelQuery(observableQuery, projectId);
      },
    });
  const getBOMItemsCurrentLevelQuery = (observableQuery: ObservableQuery, projectId: string) => {
    const { projectId: queryProjectId } = observableQuery.variables?.query ?? {};

    const shouldFetchRootBOM = queryProjectId === projectId;
    if (shouldFetchRootBOM) return true;
    return false;
  };
  return { refetchBOMItems };
};
