import React, { useState, useEffect } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';

import { useCustomAssemblyTypes } from './hooks/useCustomAssemblyTypes';

const CustomAssemblyTypesContext = React.createContext();

// eslint-disable-next-line max-lines-per-function
const CustomAssemblyTypesProvider = ({ children }) => {
  const [customAssemblyTypes, setCustomAssemblyTypes] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [renamingId, setRenamingId] = useState(null);
  const [currentRow, setCurrentRow] = useState();
  const [hoveredRow, setHoveredRow] = useState();
  const [errorInForm, setErrorInForm] = useState(false);
  const [companyUsers, setCompanyUsers] = useState([]);
  const [lineItemIds, setLineItemIds] = useState([]);
  const [defaultAssemblyType, setDefaultAssemblyType] = useState('');
  const { selectedItem } = useFacilitiesProjects();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const {
    fetchCustomAssemblyTypes,
    addAssemblyCustomCategory,
    checkCustomAssemblyNameUnique,
    checkCustomAssemblyIdentifierUnique,
    loadingCustomAssemblyTypes,
    isCreatingCustomAssemblyType,
    checkingCustomAssemblyIdUnique,
    checkingCustomAssemblyNameUnique,
  } = useCustomAssemblyTypes();

  const createCustomAssemblyType = (assemblyType) => {
    addAssemblyCustomCategory(customAssemblyTypes, assemblyType, setCustomAssemblyTypes);
  };

  useEffect(() => {
    const selectedItemIsProject = Boolean(selectedItem && selectedItem?.type === 'PROJECT');
    /**
     * If we need remove isMaterials flag is necessary create a Context to get the data used on the follow module
     * modules/Materials/BillOfMaterialsById/BOMCatalogTable/BOMCatalogTable.jsx
     *  */
    const isMaterials = pathname.includes('materials');
    if (selectedItemIsProject && !isMaterials) navigate(`/shop/custom-assembly/${selectedItem?.id}`, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, pathname]);

  const value = {
    lineItemIds,
    currentRow,
    hoveredRow,
    renamingId,
    errorInForm,
    deleteModalOpen,
    createModalOpen,
    customAssemblyTypes,
    companyUsers,
    loadingCustomAssemblyTypes,
    isCreatingCustomAssemblyType,
    checkingCustomAssemblyIdUnique,
    checkingCustomAssemblyNameUnique,
    defaultAssemblyType,
    setLineItemIds,
    setCurrentRow,
    setHoveredRow,
    setRenamingId,
    setErrorInForm,
    setDeleteModalOpen,
    setCreateModalOpen,
    setCompanyUsers,
    setCustomAssemblyTypes,
    fetchCustomAssemblyTypes,
    createCustomAssemblyType,
    checkCustomAssemblyNameUnique,
    checkCustomAssemblyIdentifierUnique,
    setDefaultAssemblyType,
  };

  return <CustomAssemblyTypesContext.Provider value={value}>{children}</CustomAssemblyTypesContext.Provider>;
};

const useCustomAssemblyTypesContext = () => {
  const context = React.useContext(CustomAssemblyTypesContext);
  if (context === undefined) {
    throw new Error('useCustomAssemblyTypesContext must be used within a CustomAssemblyTypesContext');
  }
  return context;
};

export { CustomAssemblyTypesContext, CustomAssemblyTypesProvider, useCustomAssemblyTypesContext };
