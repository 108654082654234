import React from 'react';

import { gql, useMutation } from '@apollo/client';
import { CircularProgress, useTheme } from '@mui/material';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import AttachmentsTable from 'components/AttachmentsTable/AttachmentsTable';
import { deleteWorkOrderDocument as DELETE_DOCUMENT } from 'graphql/mutations';
import {
  documents as DOCUMENTS,
  taskMultiSearch as TASK_MULTI_SEARCH,
  workOrders as WORK_ORDERS,
  workOrderDocuments as WORK_ORDER_DOCUMENTS,
} from 'graphql/queries';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import useWorkOrdersDocuments from 'hooks/useWorkOrderDocuments';
import { useWorkRequestsContext } from 'modules/Field/WorkRequests/WorkRequestsContext';
import { handleSuccessMessage } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestListFlyoutMenu/WorkRequestAttachments/AttachmentList';
import {
  document,
  workOrder,
} from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestListFlyoutMenu/WRflyoutTypes';

import WorkOrdersAttachType from './WorkOrdersAttachType';

const getRefetchQueriesDeleteWorkOrderAttachment = ({ workOrderSelected, attachment, selectedItem }: any) => [
  {
    query: gql(TASK_MULTI_SEARCH),
    variables: {
      query: {
        workRequestId: workOrderSelected?.workRequestId,
      },
    },
  },
  {
    query: gql(DOCUMENTS),
    variables: {
      query: {
        documentIds: attachment?.documentId,
      },
    },
  },
  {
    query: gql(WORK_ORDERS),
    variables: {
      query: {
        facilityId: selectedItem?.id,
      },
    },
  },
  {
    query: gql(WORK_ORDER_DOCUMENTS),
    variables: {
      query: {
        workOrderSelected: workOrderSelected?.workOrderId,
      },
    },
  },
];

export const updateCacheDeletingWorkOrderOrTaskAttachment = (cache: any) => {
  cache.modify({
    id: 'ROOT_QUERY',
    fields: {
      workOrders(_: any, { DELETE }: any) {
        return DELETE;
      },
      taskDocument(_: any, { DELETE }: any) {
        return DELETE;
      },
      documents(_: any, { DELETE }: any) {
        return DELETE;
      },
      workOrderDocuments(_: any, { DELETE }: any) {
        return DELETE;
      },
      taskMultiSearch(_: any, { DELETE }: any) {
        return DELETE;
      },
    },
  });
  cache.gc();
};

const WorkOrderAttachmentList = ({
  showWorkOrdersHeader = true,
  workOrderRow,
  darkMode = true,
  canDelete = true,
}: {
  showWorkOrdersHeader?: boolean;
  workOrderRow?: workOrder;
  darkMode?: boolean;
  canDelete?: boolean;
}) => {
  const { selectedItem } = useFacilitiesProjects();
  const { updateWRList, setUpdateWRList, workOrderSelected } = useWorkRequestsContext();
  const workOrder = workOrderRow || workOrderSelected;
  const {
    workOrderDocuments,
    loading: loadingWorkOrderDocuments,
    paginationWaypoint,
  } = useWorkOrdersDocuments(workOrder?.workOrderId);
  const { handleResponse } = useGraphqlResponseHandler();
  const [deleteWorkOrderDocument, { loading }] = useMutation(gql(DELETE_DOCUMENT), {
    refetchQueries: ['WorkOrderDocuments', 'Documents', 'WorkOrders'],
  });

  const handleDelete = async (attachment: document) => {
    await handleResponse(
      deleteWorkOrderDocument,
      {
        variables: {
          params: {
            workOrderDocumentId: attachment?.workOrderDocumentId,
          },
        },
        update(cache: any) {
          updateCacheDeletingWorkOrderOrTaskAttachment(cache);
        },
        refetchQueries: getRefetchQueriesDeleteWorkOrderAttachment({
          workOrderSelected: workOrder,
          attachment,
          selectedItem,
        }),
      },
      { successMessage: handleSuccessMessage(attachment) },
    );
    setUpdateWRList(!updateWRList);
  };

  const initialLoading = loadingWorkOrderDocuments && !workOrderDocuments?.length;
  const paginationLoading = loadingWorkOrderDocuments && workOrderDocuments?.length;

  if (initialLoading) return <Loading darkMode={darkMode} />;

  return (
    <>
      {Boolean(showWorkOrdersHeader || workOrderDocuments?.length) && <WorkOrdersAttachType darkMode={darkMode} />}
      {Boolean(workOrderDocuments?.length) && (
        <AttachmentsTable
          attachments={workOrderDocuments}
          handleDelete={canDelete ? handleDelete : undefined}
          loadingDelete={loading}
          darkMode={darkMode}
          paginationWaypoint={paginationWaypoint}
        />
      )}
      {paginationLoading && <Loading darkMode={darkMode} />}
    </>
  );
};

export default WorkOrderAttachmentList;

const Loading = ({ darkMode }: { darkMode: boolean }) => {
  const { palette } = useTheme();

  return (
    <CircularProgress
      size={32}
      sx={{ color: darkMode ? palette.secondary.mainDarkBackground : palette.secondary.main }}
    />
  );
};
