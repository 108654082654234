import React, { useState } from 'react';

import { CircularProgress, Stack, Box, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

import ConfirmLeavingModal from 'components/ConfirmLeavingModal/ConfirmLeavingModal';
import Header from 'components/Header';
import { useWorkOrderUpdate } from 'hooks-api/useWorkOrderUpdate';
import WorkItemTitle from 'modules/Shop/components/WorkItemTitle';

import { useWorkOrderItemsContext } from './context/WorkOrderItemsContext';
import WorkOrderTitleButtons from './WorkOrderTitleButtons';

const WorkOrderHeader = () => {
  const { workOrder, loading, workOrderStatusType, loadingStatus, WOisLate } = useWorkOrderItemsContext();
  const { loading: loadingSubmit, updateWorkOrder } = useWorkOrderUpdate();

  const [isLeavingDialog, setIsLeavingDialog] = useState(false);

  const location = useLocation();
  const workItemTitle = location.state?.from || 'Work Orders';

  const statusButtons =
    !workOrder || loadingSubmit || loadingStatus ? (
      <CircularProgress />
    ) : (
      <WorkOrderTitleButtons
        updateWorkOrder={updateWorkOrder}
        workOrderStatusType={workOrderStatusType}
        workOrderStatus={workOrder?.workOrderStatusTypeName}
        workOrderName={workOrder?.workOrderName}
        workOrderId={workOrder?.workOrderId}
        isProcessingWorkOrder={workOrder?.isStepFunctionProcessing}
        setIsLeavingDialog={setIsLeavingDialog}
        isLeavingDialog={isLeavingDialog}
      />
    );

  return (
    <>
      <Stack>
        {loading ? (
          <CircularProgress />
        ) : (
          <Header
            title={
              <WorkItemTitle
                workItemTitle={workItemTitle}
                title={`${workOrder?.workOrderName}: ${workOrder?.workRequest?.workRequestName}`}
                status={workOrder?.workOrderStatusTypeName}
                link="/shop/work-orders"
                isLate={WOisLate}
              />
            }
            option={statusButtons}
            sx={{ height: '40px' }}
          />
        )}
      </Stack>

      <LeavingDialog
        showLeavingDialog={isLeavingDialog}
        onConfirmNavigation={() => {
          setTimeout(() => setIsLeavingDialog(false), 0);
        }}
      />
    </>
  );
};

const LeavingDialog = ({ showLeavingDialog, onConfirmNavigation }) => (
  <ConfirmLeavingModal
    showDialog={showLeavingDialog}
    title="Release Work Order"
    titleStyles={{ fontWeight: 500 }}
    closeButtonColor="#0000008A"
    contentStyles={{ px: 3, py: 2 }}
    onConfirmNavigation={onConfirmNavigation}
  >
    <Box>
      <Typography variant="subtitle1" lineHeight="24px" fontWeight={400} pr={3}>
        Are you sure you want to navigate away from this page? <br />
        Doing so will stop any work order that has not finished.
      </Typography>
    </Box>
  </ConfirmLeavingModal>
);

export default WorkOrderHeader;
