import { useCallback } from 'react';

import { Project } from 'graphql/types';
import { useLazyProjectById } from 'hooks-api/queries/projectById/useLazyProjectById';

const useGetProjectById = () => {
  const [getProjectById] = useLazyProjectById({ fetchPolicy: 'network-only' });

  const getProject = useCallback(
    async (id: string): Promise<Project | null> => {
      const { data, error } = await getProjectById({ variables: { params: { projectId: id } } });
      if (!data || error) return null;
      const { projectById } = data;
      return projectById;
    },
    [getProjectById],
  );

  return { getProject };
};

export default useGetProjectById;
