import React, { FC, ReactNode } from 'react';

import { Box, CircularProgress, Stack } from '@mui/material';

interface ITableWrapper {
  children: ReactNode;
  loading?: boolean;
}

const TableWrapper: FC<ITableWrapper> = ({ children, loading }: ITableWrapper) => (
  <Box sx={{ border: '1px solid #A9A9A980', borderRadius: '4px', flex: 1, overflow: 'hidden', position: 'relative' }}>
    <LoadingOverlay show={loading} />
    {children}
  </Box>
);

export default TableWrapper;

interface ILoadingOverlay {
  show?: boolean;
}

const LoadingOverlay: FC<ILoadingOverlay> = ({ show }: ILoadingOverlay) => {
  if (!show) return null;
  return (
    <Stack
      sx={{
        position: 'absolute',
        height: '100%',
        width: '100%',
        backgroundColor: 'rgba(255,255,255,0.7)',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: '2',
      }}
    >
      <CircularProgress color="secondary" />
    </Stack>
  );
};
