import { FC } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';

import { stylesBoxCircular } from './styles';

export const HeaderListDetailProjects: FC<{ day: Date | Dayjs; countWorkOrder: number }> = ({
  day,
  countWorkOrder,
}) => (
  <Stack
    sx={{ position: 'sticky', top: 0, zIndex: 1 }}
    position="relative"
    bgcolor="grey.50"
    display="flex"
  >
    <Box margin="auto">
      <Typography mx="6px" mt="3px" variant="h6">
        {dayjs(day).format(' dddd')}
      </Typography>
      <Typography>{dayjs(day).format(' MMMM DD, YYYY')}</Typography>
    </Box>
    <Box
      sx={{
        ...stylesBoxCircular,
      }}
    >
      {countWorkOrder}
    </Box>
  </Stack>
);
