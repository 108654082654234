import { v4 as uuidv4 } from 'uuid';

import {
  ASSEMBLY_NODE_SHOP_TASK_TYPE_ID,
  ASSEMBLY_NODE_PART_TYPE_ID,
  EDGE_TYPE_ID_DICT,
  DEFAULT_ASSEMBLY_NODE_POSITION,
  ASSEMBLY_EDGE_TYPE_ID,
} from 'modules/Materials/AssemblyEditor/Utils/constants';

const getParentTasks = (task = {}, taskList = []) =>
  taskList.filter((t) =>
    t.taskPredecessorIds.find((predecessor) => predecessor.taskId === task.taskId),
  );

const getNodesAndEdges = (taskList, catalogItem) => {
  const { a, b } = EDGE_TYPE_ID_DICT;
  const edges = [];
  const nodes = [];

  nodes.push({
    referenceId: uuidv4(),
    assemblyNodeTypeId: ASSEMBLY_NODE_PART_TYPE_ID,
    assemblyNodeName: catalogItem?.partName,
    assemblyNodeDescription: catalogItem?.description,
    partId: catalogItem?.partId,
    ...DEFAULT_ASSEMBLY_NODE_POSITION,
  });

  if (!taskList) return { nodes, edges };
  taskList.forEach((task) => {
    // first add just the nodes
    nodes.push({
      referenceId: uuidv4(),
      assemblyNodeTypeId: ASSEMBLY_NODE_SHOP_TASK_TYPE_ID,
      assemblyNodeName: task.taskName,
      assemblyNodeDescription: task.taskDescription,
      shopTaskId: task.taskTypeId,
    });
  });
  taskList.forEach((task) => {
    // now add the edges
    const beginNodeReferenceId = nodes.find(
      (node) => node.shopTaskId === task.taskTypeId,
    ).referenceId;

    const parentTasks = getParentTasks(task, taskList);
    if (!parentTasks.length) {
      edges.push({
        beginNodeReferenceId,
        endNodeReferenceId: nodes[0].referenceId,
        assemblyEdgeTypeId: ASSEMBLY_EDGE_TYPE_ID,
        beginHandlePositionId: b,
        endHandlePositionId: a,
      });
      return;
    }
    parentTasks.forEach((parent) => {
      const endNodeReferenceId = nodes.find(
        (node) => node.shopTaskId === parent.taskTypeId,
      )?.referenceId; // finds parent node
      if (endNodeReferenceId)
        // connects predecessor with parent
        edges.push({
          beginNodeReferenceId,
          endNodeReferenceId,
          assemblyEdgeTypeId: ASSEMBLY_EDGE_TYPE_ID,
          beginHandlePositionId: b,
          endHandlePositionId: a,
        });
    });
  });

  return { nodes, edges };
};

export default getNodesAndEdges;
