import { gql, useQuery } from '@apollo/client';

import { workCellTasksMultiSearch as GET_WORK_CELL_TASKS_MULTI_SEARCH } from 'graphql/queries';

export const useWorkCellTaskMultiSearch = (query) => {
  const {
    data: tasksData,
    loading: loadingTasksData,
    refetch: refetchTaskData,
  } = useQuery(gql(GET_WORK_CELL_TASKS_MULTI_SEARCH), {
    fetchPolicy: 'cache-and-network',
    variables: {
      query: { orderBy: 'createdOn:asc', ...query },
    },
  });

  return {
    tasksData,
    loadingTasksData,
    refetchTaskData,
  };
};
