import React, { useState } from 'react';

import { DragDropContext } from 'react-beautiful-dnd';

const WorkRequestDragDropContext = React.createContext();

const WorkRequestDragDropProvider = ({
  children,
  isTasksReadOnly = false,
  needsDragDropContext = true,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [finishedDragging, setFinishedDragging] = useState({});

  const onDragStart = () => {
    setIsDragging(true);
    if (finishedDragging) setFinishedDragging({});
  };

  const onDragEnd = (params) => {
    setIsDragging(false);
    setFinishedDragging((current) => ({ ...current, ...params }));
  };

  const valueObj = {
    onDragStart,
    onDragEnd,
    isDragging,
    finishedDragging,
    setFinishedDragging,
    isTasksReadOnly,
    needsDragDropContext,
  };

  return (
    <WorkRequestDragDropContext.Provider value={valueObj}>
      {needsDragDropContext ? (
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
          {children}
        </DragDropContext>
      ) : (
        children
      )}
    </WorkRequestDragDropContext.Provider>
  );
};

const useWorkRequestDragDrop = () => {
  const context = React.useContext(WorkRequestDragDropContext);
  if (context === undefined) {
    throw new Error('useWorkRequestDragDrop must be used within a WorkRequestDragDropContext');
  }
  return context;
};

export { WorkRequestDragDropProvider, useWorkRequestDragDrop };
