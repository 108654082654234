import React from 'react';

import { Box } from '@mui/material';

import { greyColors } from 'styles/theme/colors';

import { IPartTypeNodeContainer } from './@types/partTypeNodeTypes';

const PartTypeNodeContainer: React.FC<IPartTypeNodeContainer> = ({
  children,
  setIsHovered = () => null,
  isAssembly = false,
  selected = false,
}: IPartTypeNodeContainer) => (
  <Box
    onMouseEnter={() => setIsHovered(true)}
    onMouseLeave={() => setIsHovered(false)}
    borderRadius="4px"
    style={{
      position: 'relative',
      height: 80,
      width: 255,
      outlineWidth: selected ? '2px' : '1px',
      outlineStyle: 'solid',
      outlineColor: isAssembly ? '#DB6939' : greyColors.grey500,
      backgroundColor: 'white',
      padding: 0,
      cursor: 'grab',
      boxShadow:
        '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
    }}
  >
    <Box style={{ display: 'flex', flexDirection: 'row' }}>{children}</Box>
  </Box>
);

export default PartTypeNodeContainer;
