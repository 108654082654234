import React from 'react';

import { Button, Stack, Typography, useTheme } from '@mui/material';

import { Custom } from 'components/Icons';

import { useFilterOptions } from '../features/filters/options';
import { useChipsFilterHelpers } from './FilterPopoverContent/useChipsFilterHelpers';

const HideChipsButton = ({ contextHook }) => {
  const { hasFilters } = useChipsFilterHelpers(contextHook);
  const { showFilters, setShowFilters } = useFilterOptions();
  const { palette } = useTheme();

  const handleClick = () => {
    setShowFilters(!showFilters);
  };

  if (!hasFilters) return <></>;

  return (
    <Button
      onClick={handleClick}
      sx={{
        marginLeft: 1,
        height: 'fit-content',
      }}
    >
      <Stack
        direction="row"
        sx={{
          alignItems: 'center',
          minWidth: 'max-content',
        }}
      >
        <Custom.KeyboardArrowUpAlt
          style={{
            color: palette.secondary.main,
            margin: '0px 5px 0px 0px',
            transform: showFilters ? '' : 'rotate(180deg)',
          }}
        />
        <Typography
          sx={{
            fontSize: '14px',
            color: 'secondary.main',
            lineHeight: '24px',
            fontWeight: '600',
          }}
        >
          {`${showFilters ? 'HIDE' : 'SHOW'} FILTERS`}
        </Typography>
      </Stack>
    </Button>
  );
};

export default HideChipsButton;
