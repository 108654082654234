import React from 'react';

import { gql, useMutation } from '@apollo/client';
import { CircularProgress, useTheme } from '@mui/material';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import AttachmentsTable from 'components/AttachmentsTable/AttachmentsTable';
import { deleteWorkRequestDocument as DELETE_DOCUMENT } from 'graphql/mutations';
import { workOrders as WORK_ORDERS, workRequestDocuments as WORK_REQUEST_DOCUMENTS } from 'graphql/queries';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import { useWorkRequestsContext } from 'modules/Field/WorkRequests/WorkRequestsContext';

import { useWorkRequestPropertiesAttachmentsContext } from '../../../WorRequestsProperties/WorkRequestPropertiesAttachments/WorkRequestPropertiesAttachmentsContext';
import { document } from '../WRflyoutTypes';
import WorkRequestsAttachType from './WorkRequestsAttachType';

export const handleSuccessMessage = (attachment: document) => {
  if (attachment.externalUrl) return 'URL successfully deleted';
  return 'File successfully deleted';
};

const getRefetchQueries = ({ workRequestSelected, selectedItem }: any) => [
  {
    query: gql(WORK_REQUEST_DOCUMENTS),
    variables: {
      query: {
        workRequestId: workRequestSelected?.workRequestId,
      },
    },
  },
  {
    query: gql(WORK_ORDERS),
    variables: {
      query: {
        facilityId: selectedItem?.id,
        take: '50',
        orderBy: 'needBy:asc',
        taskStatusIds: '',
      },
    },
  },
];

const updateCache = (cache: any) => {
  cache.modify({
    id: 'ROOT_QUERY',
    fields: {
      workOrders(_: any, { DELETE }: any) {
        return DELETE;
      },
      taskDocument(_: any, { DELETE }: any) {
        return DELETE;
      },
      documents(_: any, { DELETE }: any) {
        return DELETE;
      },
    },
  });
  cache.gc();
};

export const AttachmentList = ({ setReloadWorkOrderAttachments = () => {} }: any) => {
  const { palette } = useTheme();
  const { workRequestSelected, updateWRList, setUpdateWRList, isWorkOrder } = useWorkRequestsContext();

  const { selectedItem } = useFacilitiesProjects();

  const { workRequestDocuments, paginationWaypoint, fetchWorkRequestDocumentsLoading } =
    useWorkRequestPropertiesAttachmentsContext();
  const { handleResponse } = useGraphqlResponseHandler();
  const [deleteWorkRequestDocument, { loading }] = useMutation(gql(DELETE_DOCUMENT), {
    refetchQueries: ['WorkRequestDocuments', 'WorkOrders'],
  });
  const handleDelete =
    workRequestSelected?.workRequestStatusName === 'Completed'
      ? null
      : async (attachment: document) => {
          await handleResponse(
            deleteWorkRequestDocument,
            {
              variables: {
                params: {
                  workRequestDocumentId: attachment?.workRequestDocumentId,
                },
              },
              refetchQueries: getRefetchQueries({ workRequestSelected, selectedItem }),
              update(cache: any) {
                updateCache(cache);
              },
            },
            { successMessage: handleSuccessMessage(attachment) },
          );
          setReloadWorkOrderAttachments((current: boolean) => !current);
          setUpdateWRList(!updateWRList);
        };

  const initialLoading = fetchWorkRequestDocumentsLoading && !workRequestDocuments?.length;
  const paginationLoading = fetchWorkRequestDocumentsLoading && workRequestDocuments?.length;

  if (initialLoading) return <CircularProgress sx={{ color: palette.secondary.mainDarkBackground }} />;
  if (!workRequestDocuments.length) return null;

  return (
    <>
      {isWorkOrder && <WorkRequestsAttachType darkMode />}
      <AttachmentsTable
        attachments={workRequestDocuments}
        handleDelete={handleDelete}
        loadingDelete={loading}
        darkMode
        paginationWaypoint={paginationWaypoint}
      />
      {paginationLoading && <CircularProgress sx={{ color: palette.secondary.mainDarkBackground }} />}
    </>
  );
};
