import { useApolloClient } from '@apollo/client';
import Auth from '@aws-amplify/auth';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { useUser } from 'app/UserContext';
import useSetupModule from 'hooks/useSetupModule';

const useSignOut = () => {
  const client = useApolloClient();
  const { setUser } = useUser();
  const { setSelectedItem } = useFacilitiesProjects();
  const { resetModule } = useSetupModule();

  const signOut = async () => {
    client.stop();
    await client?.clearStore();
    await Auth.signOut();
    removeCognitoTokens();
    resetModule();
    setSelectedItem(null);
    setUser(null);
  };

  return signOut;
};

export default useSignOut;

const removeCognitoTokens = () => {
  Object.entries(localStorage)
    .map((item) => item[0])
    .filter((item) => item.substring(0, 30) === 'CognitoIdentityServiceProvider')
    .map((item) => localStorage.removeItem(item));
};
