import React, { useCallback } from 'react';

import { InputForesiteField } from '@evolvemep/foresite-components-ui-react';
import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';

import { requiredRule, customMaxLengthRule } from 'constants/inputFieldRules';

import { ROW_TYPES } from '../../constants/constants';
import { useLocationsAndWorkPhases } from '../../contexts/LocationsAndWorkPhaseContext';

const LocationEditableInputs = ({ location }) => {
  const {
    locations: { handleUpdateLocation, setActiveRow },
  } = useLocationsAndWorkPhases();

  const { control, watch, handleSubmit } = useForm({
    mode: 'all',
    defaultValues: {
      locationIdentifier: location.locationIdentifier,
      locationName: location.locationName,
    },
  });

  const formValues = watch();

  const { locationIdentifier, locationName } = formValues;

  const validateEnterKey = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        handleSubmit(() => {
          handleUpdateLocation({ ...location, locationIdentifier, locationName });
          setActiveRow({});
        })();
      }
    },
    [location, locationIdentifier, locationName, handleSubmit, handleUpdateLocation, setActiveRow],
  );

  return (
    <Stack direction="row" alignItems="start" gap="24px" sx={{ flex: 1 }}>
      <Stack
        sx={{
          width: location.locationTypeName === ROW_TYPES.LOCATION_SYSTEM ? '100%' : 102,
          ml: 1,
        }}
      >
        <LocationIdentifierField control={control} onKeyDown={validateEnterKey} />
      </Stack>
      {location.locationTypeName !== ROW_TYPES.LOCATION_SYSTEM && (
        <Stack sx={{ flex: 1, pr: 4 }}>
          <LocationNameField control={control} onKeyDown={validateEnterKey} />
        </Stack>
      )}
    </Stack>
  );
};

export default LocationEditableInputs;

const LocationIdentifierField = ({ ...props }) => (
  <InputForesiteField
    name="locationIdentifier"
    size="small"
    color="secondary"
    placeholder="Untitled Identifier"
    rules={{
      ...requiredRule('Location ID', 'Enter ID'),
      ...customMaxLengthRule(32, 'Max length: 32'),
    }}
    sx={{ marginTop: '6px' }}
    inputProps={{
      style: {
        height: 36,
        padding: '0 14px',
        fontSize: 14,
      },
    }}
    {...props}
  />
);

const LocationNameField = ({ ...props }) => (
  <InputForesiteField
    name="locationName"
    size="small"
    color="secondary"
    placeholder="Untitled Location"
    rules={{
      ...requiredRule('Location name', 'Enter location name'),
      ...customMaxLengthRule(32, 'Max length: 32'),
    }}
    sx={{ marginTop: '6px' }}
    inputProps={{
      style: {
        height: 36,
        padding: '0 14px',
        fontSize: 14,
      },
    }}
    {...props}
  />
);
