import React, { useState } from 'react';

import Stack from '@mui/material/Stack';

import { MoreMenu } from 'components';
import { SPOOL_UOM } from 'modules/Field/WorkRequests/WorkRequest/constants';

import { useTableRowContext } from '../context/TableRowContext';
import { useWorkOrderItemsContext } from '../context/WorkOrderItemsContext';
import HideWhenEditingWrapper from './HideWhenEditingWrapper';

const addItemToCatalogMenuOption = (clickEvent) => ({ label: 'Add item to catalog', clickEvent });

const renameMenuOption = (clickEvent) => ({ label: 'Rename', clickEvent });

const deleteMenuOption = (clickEvent) => ({ label: 'Delete', clickEvent, color: 'error.main' });

const menuStyles = {
  '.MuiDataGrid-row & > svg': {
    display: 'none',
  },
  '.MuiDataGrid-row:hover & > svg': {
    display: 'block',
  },
};

const WOItemMoreMenu = ({ handleOpenModalToCreateCatalogPart, handleOpenModalToDelete }) => {
  const { canEditItemName, canDeleteItems } = useWorkOrderItemsContext();

  const { isAddItemRow, isWriteInItem, setShowForm, item } = useTableRowContext();
  const [visible, setVisible] = useState(false);

  const MORE_MENU_OPTIONS = [];

  const isSpool = SPOOL_UOM === item?.unitOfMeasureId;

  if (isWriteInItem && !isSpool) {
    MORE_MENU_OPTIONS.push(addItemToCatalogMenuOption(() => handleOpenModalToCreateCatalogPart(item)));
  }

  if (canEditItemName) {
    MORE_MENU_OPTIONS.push(renameMenuOption(() => setShowForm(true)));
  }

  if (canDeleteItems) {
    MORE_MENU_OPTIONS.push(deleteMenuOption(() => handleOpenModalToDelete(item)));
  }

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
      <HideWhenEditingWrapper>
        {!isAddItemRow && MORE_MENU_OPTIONS && MORE_MENU_OPTIONS.length > 0 && (
          <MoreMenu
            options={MORE_MENU_OPTIONS}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            sxMenu={{ left: '30px' }}
            handleOpen={() => setVisible(true)}
            handleExtClose={() => setVisible(false)}
            sxIconButton={visible ? {} : menuStyles}
          />
        )}
      </HideWhenEditingWrapper>
    </Stack>
  );
};

export default WOItemMoreMenu;
