import React from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { Custom } from 'components/Icons';
import MoreOptionsDropdown from 'components/TableComponents/MoreOptionsDropdown/MoreOptionsDropdown';

import { useFacilityContext } from './FacilityContext';
import MoreOptions from './MoreOptions';

const FacilityCard = ({ selected, onClick, refetchFacilities }) => {
  const { facility } = useFacilityContext();

  if (!facility) return <></>;

  const { facilityName, address, thumbnail } = facility;

  return (
    <StyledCard data-cy="facility-card">
      <CardContent sx={{ p: 0 }}>
        <Box onClick={onClick}>
          <CardImage name={facilityName} image={thumbnail || Custom.FacilityDefault} />
          <Box sx={{ ml: 1, mt: 1 }}>
            <Stack direction="row" alignItems="center">
              {selected && <FacilitySelectedIcon />}
              <Title>{facilityName}</Title>
            </Stack>
            <MetaText>{`${address?.addressLine1},`}</MetaText>
            <MetaText>{`${address?.city}, ${address?.state} ${address?.zip}`}</MetaText>
          </Box>
        </Box>

        <CardFooter refetchFacilities={refetchFacilities} />
      </CardContent>
    </StyledCard>
  );
};

export default FacilityCard;

const StyledCard = ({ onClick, children, ...props }) => (
  <Card
    sx={{
      height: '294px',
      marginBottom: '10px',
      cursor: 'pointer',
      '&:hover': { background: '#00000008' },
    }}
    onClick={onClick}
    {...props}
  >
    {children}
  </Card>
);

const CardImage = ({ image, name }) => (
  <CardMedia component="img" image={image} alt={`${name} Thumbnail`} sx={{ height: '142px' }} />
);

const FacilitySelectedIcon = () => (
  <Tooltip title="Facility Opened">
    <Custom.CheckCircleIcon color="success" sx={{ marginRight: 1 }} />
  </Tooltip>
);

const Title = ({ children }) => (
  <Typography
    component="h4"
    sx={{
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      fontWeight: '500',
      fontSize: '20px',
      lineHeight: '32px',
      letterSpacing: '0.15px',
      color: 'rgba(0, 0, 0, 0.87)',
    }}
  >
    {children}
  </Typography>
);

const MetaText = ({ children }) => (
  <Typography
    sx={{
      fontSize: '12px',
      lineHeight: '24px',
      letterSpacing: '0.4px',
      color: 'rgba(0, 0, 0, 0.5)',
    }}
  >
    {children}
  </Typography>
);

const CardFooter = ({ refetchFacilities }) => (
  <Stack direction="row" alignItems="center" justifyContent="flex-end" mt={3} ml={2}>
    {/* <Stack direction="row" alignItems="center">
      <Custom.AccessTime
        style={{ marginRight: '5px', color: 'rgba(0, 0, 0, 0.5)' }}
        fontSize="small"
      />
      <MetaText>Last Viewed 9 Days Ago</MetaText>
    </Stack> */}

    <MoreOptionsDropdown optionsRenderer={() => <MoreOptions refetchFacilities={refetchFacilities} />} />
  </Stack>
);
