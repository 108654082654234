import { BOMCatalogTableFields, BOMCatalogTableHeaders } from '../BOMTableColumnsHelpers';
import LineItemDisplayCell from './LineItemDisplayCell';
import LineItemNameEditCell from './LineItemNameEditCell';

// eslint-disable-next-line import/no-cycle
export const getLineItemNameColumn = (
  onSelectPart,
  onAddWriteInItem,
  partAutocompleteVisibilityHook,
  handleRowCancel,
) => ({
  minWidth: 262,
  useForm: true,
  field: BOMCatalogTableFields.LINE_ITEM_NAME,
  headerName: BOMCatalogTableHeaders[BOMCatalogTableFields.LINE_ITEM_NAME],
  editable: true,
  resizable: true,
  disableReorder: true,
  colSpan: (params) => (params.id === 0 ? 3 : 1),
  renderEditCell: ({ row, id }) => <LineItemNameEditCell row={row} id={id} handleRowCancel={handleRowCancel} />,
  renderCell: ({ id, value, row, api }) => (
    <LineItemDisplayCell
      id={id}
      value={value}
      row={row}
      onSelectPart={onSelectPart}
      onAddWriteInItem={onAddWriteInItem}
      partAutocompleteVisibilityHook={partAutocompleteVisibilityHook}
      handleRowCancel={handleRowCancel}
      api={api}
    />
  ),
});
