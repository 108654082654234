/* eslint-disable import/no-cycle */
/* eslint-disable max-lines-per-function */
import React, { useState, useCallback, useEffect, useMemo } from 'react';

import { Box } from '@mui/material';
import { GridRowModes, useGridApiRef } from '@mui/x-data-grid-pro';

import ItemsDataGridPro from 'components/ItemsDatagridPro';
import { BOM_ITEM_LINE_TYPES, NEW_ITEM_ROW_ID } from 'constants/globalConstants';
import { useComponentVisible } from 'hooks/useComponentVisible';
import { setLocalStorage } from 'hooks/useLocalStorage';
import useWindowSize from 'hooks/useWindowSize';
import NoRowsOverlay from 'modules/Materials/commons/NoRowsOverlay';

import BOMItemsTable from './BOMItemsTable';
import BOMItemGridRow from './Form/BOMItemGridRow/BOMItemGridRow';
import { currentOrderedToObject } from './helpers';
import { useBOMTableColumns } from './hooks';
import useBOMCatalogTableSort from './hooks/useBOMCatalogTableSort';
import { useRecursiveStyleItems } from './useRecursiveStyleItems';

const DEFAULT_ROW = [{ lineItemId: 0, lineItemName: '+ Add New Item' }];
const COLUMN_CHANGED_EVENT = 'BOMItemsTable:columnsorderchanged';

const BOMCatalogTable = ({
  pl,
  parentIsSelected,
  parentLineItemId,
  addWriteInRow,
  onSubItemAdded,
  setSelectedPadItems,
  ...props
}) => {
  const apiRef = useGridApiRef();
  const [addSubItems, setAddSubItems] = useState({});
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [currentExpandedRows, setCurrentExpandedRows] = useState([]);

  const { onSortModelChange } = useBOMCatalogTableSort(props.onChangeSortKey);

  const componentVisibilityHook = useComponentVisible(false, ['AddItemAction']);
  const { height } = useWindowSize();
  const { setIsComponentVisible: changePartAutocompleteVisibility } = componentVisibilityHook;

  const makeFirstRowEditable = useCallback(
    (newRow) => {
      if (!newRow) return;
      if (parentLineItemId && parentLineItemId !== newRow[0]?.parentLineItemId) return;
      setRows((prevRows) => [...newRow, ...prevRows]);
      setRowModesModel((prev) => ({ ...prev, [NEW_ITEM_ROW_ID]: { mode: GridRowModes.Edit } }));
    },
    [parentLineItemId],
  );

  const onAddItem = useCallback(
    (newRow) => {
      if (!newRow?.length) return;
      changePartAutocompleteVisibility(false);
      makeFirstRowEditable(newRow);
    },
    [changePartAutocompleteVisibility, makeFirstRowEditable],
  );

  const handleRowClose = useCallback(
    (idToDelete, itemAdded = false) => {
      setRows((prevRows) => prevRows.filter((row) => row.lineItemId !== idToDelete));
      setRowModesModel({});
      if (addWriteInRow) onSubItemAdded(idToDelete, itemAdded);
    },
    [addWriteInRow, onSubItemAdded],
  );

  const updateExpandedIds = useCallback(
    (rowIds) => {
      apiRef?.current?.updateRows(getRowsToUpdate(currentExpandedRows, rowIds).map((rowId) => ({ lineItemId: rowId })));
      setCurrentExpandedRows(rowIds);
    },
    [apiRef, currentExpandedRows],
  );

  const handleColumnChange = useCallback(
    (event) => {
      const { field, targetIndex } = event?.detail ?? {};
      const currentState = apiRef?.current?.exportState();
      const currentOrderedColums = currentState?.columns?.orderedFields;

      if (parentLineItemId && apiRef?.current) {
        apiRef.current.setColumnIndex(field, targetIndex);
        return;
      }

      const newColumsOrder = currentOrderedToObject(currentOrderedColums);
      if (newColumsOrder == null) return;

      setLocalStorage('BillOfMaterialsById-V.0.1-SORT', newColumsOrder);
    },
    [apiRef, parentLineItemId],
  );

  const onSelectedWriteInItem = useCallback(
    (lineItemName, parentLineItemId) => onAddItem(getBOMItemForWriteInItem(lineItemName, parentLineItemId)),
    [onAddItem],
  );

  useEffect(() => {
    document.addEventListener(COLUMN_CHANGED_EVENT, (event) => handleColumnChange(event));
    return document.removeEventListener(COLUMN_CHANGED_EVENT, (event) => handleColumnChange(event));
  }, [handleColumnChange]);

  useEffect(() => {
    setRows(props.rows);
    if (addWriteInRow) onAddItem(onSelectedWriteInItem('', parentLineItemId));
  }, [props.rows, addWriteInRow, onAddItem, onSelectedWriteInItem, parentLineItemId]);

  useEffect(() => {
    const expandedRowIds = apiRef?.current?.getExpandedDetailPanels();
    const cancelledRowsIds = Object.entries(addSubItems)
      .filter((subitem) => subitem[1] === 'cancelled')
      .map((row) => row[0]);
    if (!cancelledRowsIds.length) return;
    expandedRowIds.forEach((rowId) => {
      const row = rows.find((row) => row.lineItemId === rowId);
      const shouldClosePanel = cancelledRowsIds.includes(rowId) && !row?.hasChildNodes;
      if (shouldClosePanel) apiRef?.current?.toggleDetailPanel(rowId);
    });
  }, [addSubItems, apiRef, rows]);

  useEffect(() => {
    const expandedRowIds = apiRef?.current?.getExpandedDetailPanels();
    const addingRowsIds = Object.entries(addSubItems)
      .filter((subitem) => subitem[1] === 'adding' || subitem[1] === 'added')
      .map((row) => row[0]);
    expandedRowIds.forEach((rowId) => {
      const row = rows.find((row) => row.lineItemId === rowId);
      const shouldClosePanel = !row?.hasChildNodes && !addingRowsIds.includes(rowId);
      if (shouldClosePanel) apiRef?.current?.toggleDetailPanel(rowId);
    });
  }, [addSubItems, apiRef, rows]);

  const onSelectedPart = (part) => onAddItem(getBOMItemForPart(part));
  const onAddPartItem = (part) => onAddItem(onSelectedPart(part));

  const onAddWriteInItem = useCallback(
    (_ignore, lineItemName) => onAddItem(onSelectedWriteInItem(lineItemName, parentLineItemId)),
    [onAddItem, onSelectedWriteInItem, parentLineItemId],
  );

  const onAddSubItem = useCallback(
    (id) => {
      if (addSubItems[id] === 'adding') return;
      const rowIds = apiRef?.current?.getExpandedDetailPanels();
      const rowExpanded = rowIds?.find((rowId) => rowId === id);
      if (!rowExpanded) apiRef?.current?.toggleDetailPanel(id);
      setAddSubItems({ ...addSubItems, [id]: 'adding' });
    },
    [addSubItems, apiRef],
  );

  const columns = useBOMTableColumns({
    partAutocompleteVisibilityHook: componentVisibilityHook,
    onAddPartItem,
    onAddWriteInItem,
    onAddSubItem,
    handleRowCancel: handleRowClose,
  });

  const onCellKeyDown = useCallback(({ cellMode }, event) => {
    if (cellMode === GridRowModes.View) event.defaultMuiPrevented = true;
  }, []);

  const handleSelectCheckbox = useCallback(
    (keys) => setSelectedPadItems(rows.filter((row) => keys.includes(row.lineItemId))),
    [setSelectedPadItems, rows],
  );

  const addSubItem = useCallback(
    (lineItemId, added) => setAddSubItems((subitems) => ({ ...subitems, [lineItemId]: added })),
    [],
  );

  const getBomItemsRecursiveList = useCallback(
    ({ row }) => {
      const shouldAddSubItem = addSubItems[row.lineItemId] === 'adding';
      return (
        <BOMItemsTable
          pl={pl}
          parentIsSelected={parentIsSelected}
          addSubItem={shouldAddSubItem}
          onSubItemAdded={addSubItem}
          parentLineItemId={row.lineItemId}
        />
      );
    },
    [addSubItem, addSubItems, parentIsSelected, pl],
  );

  const getDetailPanelHeight = useCallback(() => 'auto', []);

  const headerStyleForRecursiveTables = useRecursiveStyleItems({
    isRecursive: Boolean(parentLineItemId),
    parentIsSelected,
    pl,
  });

  const getRowHeight = useCallback(({ id }) => {
    if (id === NEW_ITEM_ROW_ID.toString()) return 'auto';
    return 35;
  }, []);

  const CustomRow = useCallback(
    (params) => (
      <BOMItemGridRow
        params={params}
        onCancelEditRow={handleRowClose}
        onSuccessEditRow={(id) => handleRowClose(id, true)}
      />
    ),
    [handleRowClose],
  );

  const CustomNoRowsOverlay = useCallback(() => <NoRowsOverlay text="No Data" />, []);

  const Components = {
    NoRowsOverlay: CustomNoRowsOverlay,
    Row: CustomRow,
  };

  const hasActiveForm = useMemo(() => rows.some((row) => row.lineItemId === NEW_ITEM_ROW_ID.toString()), [rows]);

  const getIsRowSelectable = useCallback(
    ({ row }) => {
      if (parentLineItemId) return false;
      return !row?.lineItemId !== NEW_ITEM_ROW_ID.toString();
    },
    [parentLineItemId],
  );

  const getRowId = useCallback((row) => row?.lineItemId, []);

  const handleRowScrollEnd = useCallback(() => {
    const shouldExecuteScroll =
      rows?.length && props?.pagination?.onScroll && typeof props?.pagination?.onScroll === 'function';
    if (shouldExecuteScroll) props.pagination.onScroll();
  }, [props.pagination, rows?.length]);

  return (
    <Box sx={parentLineItemId ? { height: 'auto' } : { height: `${height - 235}px` }}>
      <ItemsDataGridPro
        autoHeight={Boolean(parentLineItemId)}
        sx={headerStyleForRecursiveTables}
        apiRef={apiRef}
        checkboxSelectionVisibleOnly
        getDetailPanelContent={getBomItemsRecursiveList}
        getDetailPanelHeight={getDetailPanelHeight}
        getRowHeight={getRowHeight}
        hasActiveForm={hasActiveForm}
        initialState={{ pinnedColumns: { right: ['actions'] } }}
        rows={rows}
        columns={columns}
        editMode="row"
        disableColumnMenu
        pagination={Boolean(props.pagination)}
        getRowId={getRowId}
        pinnedRows={{ top: DEFAULT_ROW }}
        experimentalFeatures={{ rowPinning: !parentLineItemId, newEditingApi: true }}
        onRowsScrollEnd={handleRowScrollEnd}
        components={Components}
        isRowSelectable={getIsRowSelectable}
        rowModesModel={rowModesModel}
        onSortModelChange={onSortModelChange}
        onSelectionModelChange={handleSelectCheckbox}
        onSelectedId="lineItemId"
        onCellKeyDown={onCellKeyDown}
        onDetailPanelExpandedRowIdsChange={updateExpandedIds}
        disableSelectionOnClick
        loading={props?.loading}
        onColumnOrderChange={(params) => dispatchColumnChangedCustemEvent(params, parentLineItemId)}
        {...props?.tableProps}
      />
    </Box>
  );
};

export default React.memo(BOMCatalogTable);

const getBOMItemForWriteInItem = (lineItemName, parentLineItemId) => [
  {
    lineItemTypeId: BOM_ITEM_LINE_TYPES.write,
    lineItemId: NEW_ITEM_ROW_ID.toString(),
    parentLineItemId,
    lineItemName,
  },
];

const getBOMItemForPart = (part) => {
  const { manufacturer, unitOfMeasure } = part;
  if (!manufacturer || (manufacturer && (!manufacturer?.manufacturerId || !manufacturer?.manufacturerName)))
    throw new Error("Manufacturer doesn't have a correct value");

  if (
    !unitOfMeasure ||
    (unitOfMeasure &&
      (!unitOfMeasure?.unitOfMeasureId || !unitOfMeasure?.unitOfMeasureCode || !unitOfMeasure?.unitOfMeasureName))
  )
    throw new Error("Unit of measure doesn't have a correct value");

  return [
    {
      lineItemTypeId: BOM_ITEM_LINE_TYPES.part,
      lineItemId: NEW_ITEM_ROW_ID.toString(),
      lineItemName: part?.partName,
      unitOfMeasure,
      manufacturerCatalogNumber: part?.manufacturerCatalogNumber,
      partId: part?.partId,
      manufacturer,
    },
  ];
};

const getRowsToUpdate = (rowsIdsToUpdate = [], rowIds = []) => {
  const removedIds = rowsIdsToUpdate.reduce((result, rowId) => {
    const foundInList = rowIds.find((id) => id === rowId);
    if (foundInList) return result;
    return [...result, rowId];
  }, []);
  return [...removedIds, ...rowIds];
};

const dispatchColumnChangedCustemEvent = (params, parentLineItemId) => {
  if (parentLineItemId) return;
  const { field, targetIndex } = params;
  const customEvent = new CustomEvent('BOMItemsTable:columnsorderchanged', {
    detail: { field, targetIndex },
  });
  document.dispatchEvent(customEvent);
};
