/* eslint-disable max-lines-per-function */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import useFolderAPI from 'hooks-api/useFolderAPI';

import { useDrawingRegisterContext } from '../DrawingRegisterContext';
import DrawingFolderDeleteModal from './components/DrawingFolderDeleteModal/DrawingFolderDeleteModal';
import { showNewFolderForm } from './helpers/drawingFolderRowsHelper';

const DEFAULT_VALUE = {
  folders: [],
  deleteFolderLoading: false,
  folderToDelete: null,
  idEditedFolder: null,
  folderParentRow: null,
};

const DrawingFolderContext = React.createContext(DEFAULT_VALUE);

const DrawingFolderProvider = ({ children, folderParent, parentApiRef }) => {
  const [idEditedFolder, setIdEditedFolder] = useState(null);
  const [folderToDelete, setFolderToDelete] = useState(null);
  const [folderParentRow, setFolderParentRow] = useState(null);
  const [folders, setFolders] = useState([]);
  const [currentApiRef, setCurrentApiRef] = useState(null);
  const { navigateToFolder, selectedFolder, setIsTableEditMode } = useDrawingRegisterContext();

  const {
    deleteFolder,
    deleteFolderLoading,
    createFolder,
    createFolderLoading,
    updateFolder,
    updateFolderLoading,
    fetchFoldersByParentFolder,
    foldersCache,
    fetchFoldersLoading,
  } = useFolderAPI();

  // Get folders by current selected folder
  useEffect(() => {
    const folderId = folderParent ? folderParent.folderId : selectedFolder?.folderId;
    fetchFoldersByParentFolder(folderId);
  }, [folderParent, selectedFolder, fetchFoldersByParentFolder]);

  useEffect(() => {
    setFolders(foldersCache);
    // eslint-disable-next-line
  }, [JSON.stringify(foldersCache)]);

  const loadParentApiRef = useCallback((apiRef = null) => {
    setCurrentApiRef(apiRef);
  }, []);

  const handleCreateFolder = useCallback(
    async (folderData) => {
      await createFolder(folderData, folderParentRow?.folder?.parentFolderId ?? null, () => {
        if (currentApiRef && folderParentRow)
          currentApiRef.current.setExpandedDetailPanels([folderParentRow.id]);
      });
    },
    [createFolder, currentApiRef, folderParentRow],
  );

  const handleUpdateFolder = useCallback(
    async (folderData) => {
      await updateFolder(folderData);
    },
    [updateFolder],
  );

  const handleDeleteFolder = useCallback(async () => {
    if (folderToDelete) {
      await deleteFolder({
        folderId: folderToDelete.id,
        parentFolderId: folderToDelete.folder.parentFolderId,
      });
      if (folders.length <= 1) {
        if (parentApiRef) parentApiRef.current.toggleDetailPanel(folderParent.folderId);
      }
      setFolderToDelete(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folders, deleteFolder, folderToDelete]);

  const handleUpdateFolderRow = (rowData, field, updateRows) => {
    if (rowData.id !== idEditedFolder) {
      if (idEditedFolder) {
        updateRows([{ id: idEditedFolder, isEditing: false }]);
      }
      setIdEditedFolder(rowData.id);
      updateRows([{ id: rowData.id, isEditing: true, field }]);
    }
  };

  const handleGoToFolder = (cell) => {
    const { row } = cell;
    navigateToFolder(row.id);
  };

  const closeFolderFields = (event, id, updateRows) => {
    updateRows([{ id, isEditing: false, isProcessing: false }]);
    setIdEditedFolder(null);
    setIsTableEditMode(false);
    event.stopPropagation();
  };

  const openNewFolderFormRow = useCallback((folderRow, folders) => {
    setFolderParentRow(folderRow || null);
    setIsTableEditMode(true);
    setFolders(showNewFolderForm(folders, folderRow?.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeNewFolderFormRow = () => {
    setIdEditedFolder(null);
    setIsTableEditMode(false);
    // eslint-disable-next-line no-underscore-dangle
    setFolders((prevState) => prevState.filter((row) => row.__typename !== 'NewFolder'));
  };

  const isOpenDeleteFolderModal = useMemo(() => Boolean(folderToDelete), [folderToDelete]);

  const closeDeleteFolderModal = () => {
    setFolderToDelete(null);
  };

  return (
    <DrawingFolderContext.Provider
      value={{
        folders,
        closeDeleteFolderModal,
        closeFolderFields,
        closeNewFolderFormRow,
        deleteFolderLoading,
        folderToDelete,
        handleCreateFolder,
        createFolderLoading,
        handleDeleteFolder,
        handleGoToFolder,
        handleUpdateFolder,
        updateFolderLoading,
        handleUpdateFolderRow,
        idEditedFolder,
        isOpenDeleteFolderModal,
        openNewFolderFormRow,
        setFolderToDelete,
        folderParentRow,
        setFolderParentRow,
        fetchFoldersLoading,
        loadParentApiRef,
      }}
    >
      <DrawingFolderContext.Consumer>
        {({
          isOpenDeleteFolderModal,
          closeDeleteFolderModal,
          handleDeleteFolder,
          folderToDelete,
          deleteFolderLoading,
        }) =>
          isOpenDeleteFolderModal ? (
            <DrawingFolderDeleteModal
              open={isOpenDeleteFolderModal}
              setOpen={closeDeleteFolderModal}
              onSubmit={handleDeleteFolder}
              folder={folderToDelete}
              loading={deleteFolderLoading}
            />
          ) : null
        }
      </DrawingFolderContext.Consumer>
      {children}
    </DrawingFolderContext.Provider>
  );
};

const useDrawingFolderContext = () => {
  const context = React.useContext(DrawingFolderContext);
  if (context === undefined) {
    throw new Error('useDrawingFolderContext must be used within a DrawingFolderContext');
  }
  return context;
};

export { DrawingFolderContext, DrawingFolderProvider, useDrawingFolderContext };
