import React from 'react';

import { Stack, Typography } from '@mui/material';

import { CenteredLoadSpinner } from 'components';
import ConfirmationModal from 'components/ConfirmationModal';
import { Custom } from 'components/Icons';
import NoContentMessage from 'components/TableComponents/NoContentMessage';
import OutlinedCardHeaderText from 'modules/Field/components/OutlinedCardHeaderText';

import OutlinedCard from '../../components/OutlinedCard';
import { ROW_TYPES } from '../constants/constants';
import { useActiveRow } from '../contexts/ActiveRowContext';
import { useDeleteModal } from '../contexts/DeleteModalContext';
import { useLocationsAndWorkPhases } from '../contexts/LocationsAndWorkPhaseContext';
import WorkPhaseRow from './components/WorkPhaseRow';

const WorkPhases = () => {
  const { deleteModalText, workPhaseToBeDeleted, hideDeleteModal } = useDeleteModal();
  const { activeRows } = useActiveRow();
  const {
    workPhases: {
      tableRows,
      handleAddRow,
      isEditingARow,
      handleDeleteRow,
      loadingWorkPhases,
      loadingLocationList,
      loading,
      resume,
    },
  } = useLocationsAndWorkPhases();

  const addButtonLabel =
    activeRows[0]?.type === ROW_TYPES.PROJECT ? 'New Scope Package' : 'New Work Phase';

  const titleSuffix = resume?.hasLocationPhases ? '+ Locations' : '';

  const activeRowIsWorkPhase = activeRows[0]?.type === ROW_TYPES.WORK_PHASE;
  const activeRowIsALocation = activeRows[0]?.locationId;

  const disableButton =
    !activeRows.length ||
    activeRows?.length > 1 ||
    isEditingARow ||
    loadingWorkPhases ||
    activeRowIsWorkPhase ||
    activeRowIsALocation;

  const addChildToSelectedRow = () => handleAddRow();

  return (
    <Stack flex="1" sx={{ height: '100%' }}>
      <Typography variant="h5" sx={{ margin: '4px 0 16px 0' }}>
        Work Phases {titleSuffix}
      </Typography>
      <OutlinedCard
        header={<OutlinedCardHeaderText paddingLeft={3}>Work Phases</OutlinedCardHeaderText>}
        addButtonLabel={addButtonLabel}
        onAddButtonClick={addChildToSelectedRow}
        disabled={Boolean(disableButton)}
      >
        {!tableRows || loading ? (
          <>
            {loading ? (
              <CenteredLoadSpinner />
            ) : (
              <NoContentMessage text="Open a Project to add Work Phases">
                <Custom.Hourglass />
              </NoContentMessage>
            )}
          </>
        ) : (
          tableRows.map((project) => <WorkPhaseRow key={project.id} node={project} />)
        )}
        {(loadingWorkPhases || loadingLocationList) && <CenteredLoadSpinner />}
      </OutlinedCard>
      <ConfirmationModal
        open={Boolean(workPhaseToBeDeleted)}
        onClose={hideDeleteModal}
        onConfirm={handleDeleteRow}
        text={deleteModalText}
        cancelButtonText="CANCEL"
        confirmButtonText="CONFIRM"
        modalTitle="Confirm Delete"
      />
    </Stack>
  );
};

export default WorkPhases;
