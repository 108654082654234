import React, { useCallback, useMemo, useContext, useRef, ReactNode, useEffect } from 'react';

import { gql, useMutation, useSubscription } from '@apollo/client';

import { MessageStatus } from 'app/MessageCenter/contexts';
import { updateMessage as UPDATE_MESSAGE } from 'graphql/mutations';
import { addedMessage as ADDED_MESSAGE } from 'graphql/subscriptions';
import useCache from 'hooks/useCache';
import { Message } from 'types/Message';

import useFetchMessages from './useFetchMessages';
import useFetchMessagesCount from './useFetchMessagesCount';

type MessageAlertBannerContextType = {
  alerts: Message[];
  alertsCount: number;
  dismissAlert: (messageId: string) => void;
};

const MessageAlertBannerContext = React.createContext<MessageAlertBannerContextType>({
  alerts: [],
  alertsCount: 0,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  dismissAlert: (messageId: string) => {},
});

type MessageAlertBannerProviderType = {
  children: ReactNode;
};

const MessageAlertBannerProvider = ({ children }: MessageAlertBannerProviderType) => {
  const hightPriorityMessageTypeId = useRef('');
  const { deleteCache } = useCache();

  const { alerts, getMessages } = useFetchMessages();
  const { alertsCount, getMessagesCount } = useFetchMessagesCount();
  const [updateMessage] = useMutation(gql(UPDATE_MESSAGE));
  const { data: message } = useSubscription(gql(ADDED_MESSAGE));

  useEffect(() => {
    getMessages();
    getMessagesCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!message || message?.addedMessage?.messagePriorityCode !== 'HIGH') return;
    deleteCache('messages', {
      query: {
        status: MessageStatus.ACTIVE,
        read: false,
        messagePriorityId: hightPriorityMessageTypeId.current,
      },
    });
    getMessages();
    getMessagesCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  const dismissAlert = useCallback(
    async (messageId: string) => {
      const variables = {
        params: { messageId },
        body: { status: 'Dismiss' },
      };

      await updateMessage({ variables });
      await getMessages();
      await getMessagesCount();
    },
    [updateMessage, getMessages, getMessagesCount],
  );

  const contextValue = useMemo(
    () => ({
      alerts: alerts ? alerts?.messages : [],
      alertsCount: alertsCount ? alertsCount?.messagesCount : 0,
      dismissAlert,
    }),
    [alerts, alertsCount, dismissAlert],
  );

  return <MessageAlertBannerContext.Provider value={contextValue}>{children}</MessageAlertBannerContext.Provider>;
};

const useMessageAlertBannerContext = () => {
  const context = useContext(MessageAlertBannerContext);
  if (context === undefined) {
    throw new Error('useMessageAlertBannerContext must be used within a MessageAlertBannerContext');
  }
  return context;
};

export { MessageAlertBannerContext, MessageAlertBannerProvider, useMessageAlertBannerContext };
