import { gql, useMutation } from '@apollo/client';

import { GENERIC_MUTATION_ERROR } from 'constants/globalConstants';
import { deletePartCategory as DELETE_PART_CATEGORY } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

export const useDeletePartCategory = () => {
  const [deletePartCategory] = useMutation(gql(DELETE_PART_CATEGORY), {
    refetchQueries: ['Parts'],
  });

  const { handleResponse } = useGraphqlResponseHandler();

  const onDeletePartCategory = async (id, partCategoryName, successCallback) => {
    await handleResponse(
      deletePartCategory,
      {
        variables: {
          params: { id },
        },
      },
      {
        successMessage: `${partCategoryName} category successfully deleted`,
        errorMessage: GENERIC_MUTATION_ERROR,
      },
      () => successCallback(),
    );
  };

  return {
    onDeletePartCategory,
  };
};
