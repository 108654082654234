import React, { useEffect } from 'react';

import { InputImage } from 'components';

const PartDefaultImage = ({
  partDefaultImage,
  uploadingProgress,
  uploadingPartDefaultImage,
  setError = () => {},
  control,
  setValue,
  isSubmitting,
}) => {
  useEffect(() => {
    if (partDefaultImage && partDefaultImage?.imageUrl) {
      setValue(`partDefaultImage`, {
        partImage: partDefaultImage.imageUrl,
        imageUrl: partDefaultImage.imageUrl,
        partImageId: partDefaultImage?.partImageId,
      });
    }
  }, [partDefaultImage, setValue]);

  const handleDeleteImage = (name) => setValue(name, true);

  const handleSelectImage = () => {
    setError(null);
  };

  const handleError = (error) => {
    setError(error);
  };

  return (
    <InputImage
      isLoading={uploadingPartDefaultImage}
      loadingValue={uploadingProgress}
      withHover
      withEdit
      control={control}
      defaultValue={partDefaultImage?.imageUrl}
      disabled={isSubmitting}
      name="partDefaultImage.partImage"
      accept="image/png, image/gif, image/jpeg, image/heic"
      sx={{ width: '184px', height: '184px' }}
      onDeleteImage={() => handleDeleteImage('partDefaultImage.isDeleted')}
      onSelectImage={handleSelectImage}
      onError={handleError}
    />
  );
};

export default PartDefaultImage;
