import { useCallback, useEffect } from 'react';

import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { VARIANT_SUCCESS } from 'constants/snackbarConstants';
import { deleteTaskDocument as DELETE_TASK_DOCUMENT } from 'graphql/mutations';
import { taskDocument as TASK_DOCUMENTS, documents as DOCUMENTS } from 'graphql/queries';
import useDocumentTypes from 'modules/Shop/Fabrication/TaskViewer/PlansModelsContent/hooks/useDocumentTypes';

const useDRWorkRequests = ({ props }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { drawingModelCNCIds } = useDocumentTypes();
  const [getTaskDocuments, { data: taskDocuments }] = useLazyQuery(gql(TASK_DOCUMENTS));
  const [deleteTaskDocument, { loading: deleting }] = useMutation(gql(DELETE_TASK_DOCUMENT));
  const [getDrawingDocument, { data: mappedDocs }] = useLazyQuery(gql(DOCUMENTS));

  const noDocuments = !taskDocuments?.taskDocument?.length;

  const handleDocsRequests = useCallback(async () => {
    await getTaskDocuments({
      variables: {
        query: {
          taskId: props?.row?.taskId,
          includeExternalUrl: false,
          documentTypeIds: drawingModelCNCIds,
        },
      },
    });

    const ids = taskDocuments?.taskDocument
      ?.filter((document) => document.documentId)
      .map(({ documentId }) => `${documentId}`)
      .toString();

    if (!ids) return;

    await getDrawingDocument({
      variables: {
        query: {
          documentIds: ids,
        },
      },
    });
  }, [getDrawingDocument, getTaskDocuments, props?.row?.taskId, taskDocuments?.taskDocument, drawingModelCNCIds]);

  const unmapDocument = (docId) => {
    deleteTaskDocument({
      variables: {
        params: {
          taskDocumentId: docId,
        },
      },
      onCompleted: () =>
        enqueueSnackbar('Drawing successfully unmapped', {
          preventDuplicate: true,
          ...VARIANT_SUCCESS,
        }),
      refetchQueries: () => {
        if (mappedDocs?.documents?.length === 1) return ['TaskMultiSearch', 'TaskDocument'];
        return ['TaskDocument'];
      },
    });
  };

  useEffect(() => {
    handleDocsRequests();
  }, [handleDocsRequests]);

  return {
    mappedDocs: mappedDocs?.documents || [],
    taskDocuments,
    noDocuments,
    unmapDocument,
    deleting,
  };
};

export default useDRWorkRequests;
