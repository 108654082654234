import React, { useState, useEffect } from 'react';

import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';

import EULAModal from 'app/EULAModal';
import { useUser } from 'app/UserContext';
import useQuery from 'hooks/useQuery';
import StyledAlert from 'modules/Authentication/components/StyledAlert';

import { userAcceptEULA } from '../auth/restCalls';
import useSignIn from '../auth/useSignIn';
import ErrorMessage from '../components/ErrorMessages';
import LoginForm from './LoginForm';
import { checkValidation } from './loginUtils';

const Login = () => {
  const { signIn, checkForLatestEulaAgreement } = useSignIn();
  const { setUser } = useUser();
  const [isLoading, setIsLoading] = React.useState(false);
  const [showEULA, setShowEULA] = React.useState(false);
  const [EulaUser, setEulaUser] = React.useState('');
  const [errorCode, setErrorCode] = useState('');
  const [isConfirmed, setIsConfirmed] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const query = useQuery();
  const queryErrorCode = query.get('errorCode');
  const username = query.get('username');
  const code = query.get('code');

  const { handleSubmit, control, getValues } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const changePasswordSuccess = state?.ResetPassword;
  const confirmationSuccess = state?.inviteConfirm;

  useEffect(() => setErrorCode(queryErrorCode), [queryErrorCode]);

  useEffect(() => {
    if (username && code && !isConfirmed) checkValidation({ username, code, setIsConfirmed });
  }, [code, isConfirmed, username]);

  const login = async (values) => {
    try {
      const amplifyResult = await signIn(values);
      setUser(amplifyResult);
      if (!values.keepEnabled) window.addEventListener('unload', () => localStorage.clear());
      setIsLoading(false);
      navigate('/');
    } catch ({ code }) {
      if (code === 'UserNotVerifiedFoundException') {
        setIsLoading(false);
        navigate(`/auth/email-confirmation/?isUnverified=true&email=${values.email}`);
      } else {
        setIsLoading(false);
        setErrorCode(code);
      }
    }
  };

  const handleSignIn = async (values) => {
    setIsLoading(true);
    const { userName, acceptedLatestEula } = await checkForLatestEulaAgreement(values.email);

    if (acceptedLatestEula) {
      await login(values);
    } else if (userName) {
      setEulaUser(userName);
      setShowEULA(true);
      setIsLoading(false);
    } else {
      setErrorCode('UserNotFoundException');
      setIsLoading(false);
    }
  };

  const onAcceptEULA = async () => {
    await userAcceptEULA(EulaUser);
    setShowEULA(false);
    await handleSignIn(getValues());
  };

  const onDeclineEULA = () => setShowEULA(false);

  return (
    <Container component="main" maxWidth="xs">
      {errorCode && <ErrorMessage code={errorCode} onClose={() => setErrorCode('')} />}
      {isConfirmed && <VerifiedEmailMessage />}
      {changePasswordSuccess && <PasswordChangeSuccessMessage />}
      {confirmationSuccess && <InviteConfirmSuccessMessage />}
      <LoginForm onSubmit={handleSubmit(handleSignIn)} isLoading={isLoading} control={control} />
      <LoadingCircleSpacer isLoading={isLoading} />
      {/* <DontHaveAccountLink /> */}
      <EULAModal open={showEULA} onAccept={onAcceptEULA} onDecline={onDeclineEULA} />
    </Container>
  );
};

export default Login;

const VerifiedEmailMessage = () => (
  <Box mb="45px">
    <StyledAlert severity="success">Your email address has been verified!</StyledAlert>
  </Box>
);

const PasswordChangeSuccessMessage = () => (
  <Box mb="45px">
    <StyledAlert severity="success">Success! Your new password has been set.</StyledAlert>
  </Box>
);

const InviteConfirmSuccessMessage = () => (
  <Box mb="45px">
    <StyledAlert severity="success">Success! You have successfully joined.</StyledAlert>
  </Box>
);

const LoadingCircleSpacer = ({ isLoading }) => (
  <Box height="80px" display="flex" justifyContent="center" alignItems="center">
    {isLoading && <CircularProgress />}
  </Box>
);
