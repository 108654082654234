import React from 'react';

import { Menu, MenuItem } from '@mui/material';

const CategoryListItemMenu = ({
  menuVisible,
  setMenuVisible,
  setAddingSubcategory,
  setOpened,
  anchorEl,
  setEditingSubcategory,
  setAttributesModalOpen,
  depth,
  setOpenModalDelete,
}) => (
  <Menu
    open={menuVisible}
    anchorEl={anchorEl}
    onBlur={(e) => {
      e.stopPropagation();
      setMenuVisible(false);
    }}
  >
    <MenuItem
      onClick={(e) => {
        e.stopPropagation();
        setMenuVisible(false);
        setAddingSubcategory(true);
        setOpened(true);
      }}
    >
      New Subcategory
    </MenuItem>
    <MenuItem onClick={() => setAttributesModalOpen(true)}>New Attribute</MenuItem>
    <MenuItem
      onClick={(e) => {
        e.stopPropagation();
        setMenuVisible(false);
        setEditingSubcategory(true);
      }}
    >
      Rename
    </MenuItem>
    {depth !== 0 && <MenuItem onClick={() => setOpenModalDelete(true)}>Delete</MenuItem>}
  </Menu>
);

export default CategoryListItemMenu;
