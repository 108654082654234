import { useEffect, useRef, useState } from 'react';

export const useSlotSubscribeClick = () => {
  const listRef = useRef<HTMLUListElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    const eventSubscribe = 'click';
    const parentBox = listRef.current?.parentNode?.parentNode as HTMLDivElement;
    const handler = () => setAnchorEl(parentBox);
    parentBox?.addEventListener(eventSubscribe, handler);
    return () => parentBox?.removeEventListener(eventSubscribe, handler);
  }, []);

  return { anchorEl, setAnchorEl, listRef };
};
