import React from 'react';

import UnitOfMeasure from './UnitOfMeasure';
import { UnitOfMeasureProvider } from './UnitOfMeasureContext';

const ConnectedUnitOfMeasure = () => (
  <UnitOfMeasureProvider>
    <UnitOfMeasure />
  </UnitOfMeasureProvider>
);

export { ConnectedUnitOfMeasure as default };
