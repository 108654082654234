/* eslint-disable max-lines-per-function */
import { useState } from 'react';

import { gql, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { useUser } from 'app/UserContext';
import { VARIANT_ERROR } from 'constants/snackbarConstants';
import {
  deletePartImage as DELETE_PART_IMAGE,
  updatePartImageWithDocumentId as UPDATE_PART_IMAGE_WITH_DOCUMENT_ID,
  addPartImageWithDocumentId as ADD_PART_IMAGE_WITH_DOCUMENT_ID,
} from 'graphql/mutations';
import useUploadImage from 'hooks/useUploadImage';

export default function usePartImage() {
  const { user } = useUser();
  const { getImage, uploadImage, uploadingProgress } = useUploadImage();
  const { enqueueSnackbar } = useSnackbar();
  const [uploadingPartAdditionalImages, setUploadingPartAdditionalImages] = useState(false);
  const [uploadingPartDefaultImage, setUploadingPartDefaultImage] = useState(false);
  const [addPartImageWithDocumentIdMutation] = useMutation(gql(ADD_PART_IMAGE_WITH_DOCUMENT_ID));
  const [updatePartImageWithDocumentIdMutation] = useMutation(gql(UPDATE_PART_IMAGE_WITH_DOCUMENT_ID));
  const [deletePartImageMutation] = useMutation(gql(DELETE_PART_IMAGE));

  const uploadPartImage = async ({ documentId: id, imgFile, isDefault }) => {
    if (isDefault) {
      setUploadingPartDefaultImage(true);
    } else {
      setUploadingPartAdditionalImages(true);
    }

    const fileName = imgFile.name;
    const storagePath = `all/company/${user.companyId}/parts/images/${fileName}`;

    const documentId = await uploadImage(imgFile, storagePath, id);

    setUploadingPartAdditionalImages(false);
    setUploadingPartDefaultImage(false);

    return documentId;
  };

  const addPartImage = async ({ partId, imgFile, isDefault, documentId }) => {
    try {
      let partImageDocumentId;
      if (!documentId) {
        partImageDocumentId = await uploadPartImage({ documentId: partId, imgFile, isDefault });
      } else {
        partImageDocumentId = documentId;
      }

      await addPartImageWithDocumentIdMutation({
        variables: {
          body: {
            partId,
            documentId: partImageDocumentId,
            isDefault,
          },
        },
      });
    } catch (error) {
      enqueueSnackbar('Uploading part image failed', VARIANT_ERROR);
    }
  };

  const updatePartImage = async ({ partId, partImageId, imgFile, isDefault, sortOrder, documentId }) => {
    try {
      let partImageDocumentId;
      if (!documentId) {
        partImageDocumentId = await uploadPartImage({ documentId: partId, imgFile, isDefault });
      } else {
        partImageDocumentId = documentId;
      }

      await updatePartImageWithDocumentIdMutation({
        variables: {
          body: {
            documentId: partImageDocumentId,
            sortOrder,
          },
          params: {
            partImageId,
          },
        },
      });
    } catch (error) {
      enqueueSnackbar('Uploading part image failed', VARIANT_ERROR);
    }
  };

  const deletePartImage = async ({ partImageId }) => {
    try {
      await deletePartImageMutation({
        variables: {
          params: {
            partImageId,
          },
        },
      });
    } catch (error) {
      enqueueSnackbar('Deleting part image failed', VARIANT_ERROR);
    }
  };

  const getPartImage = async (documentId) => {
    const getPresignedURL = await getImage(documentId);

    return getPresignedURL;
  };

  return {
    getPartImage,
    uploadingPartDefaultImage,
    uploadingPartAdditionalImages,
    uploadingProgress,
    updatePartImage,
    addPartImage,
    deletePartImage,
  };
}
