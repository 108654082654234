import React from 'react';

import { CircularProgress, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import { Modal, CancelButton, DeleteButton } from 'components';

const DeleteModal = ({
  open,
  onClose,
  onSubmit,
  loading = false,
  disabled = false,
  itemName = '',
  confirmText = 'Are you sure you want to delete the',
  deleteButtonText = 'Delete',
  title = 'Confirm delete',
  maxWidth = 'xs',
  children,
  paperSx = {},
  disabledSubmit = false,
}) => {
  const theme = useTheme();
  return (
    <Modal
      disableModalOutsideClick
      disableCloseButton={loading}
      open={open}
      onClose={(event) => {
        if (loading) return;
        onClose(event);
      }}
      title={title}
      reducePadding
      fullWidth
      maxWidth={maxWidth}
      paperSx={paperSx}
      closeButtonStyles={{
        padding: 0,
      }}
      closeButtonColor={theme.palette.action.lightSurface?.active}
      footer={
        <>
          {loading && <CircularProgress size={20} color="secondary" />}
          <CancelButton onClick={onClose} disabled={loading || disabled} />
          <DeleteButton
            disabled={loading || disabled || disabledSubmit}
            onClick={onSubmit}
            buttonText={deleteButtonText}
          />
        </>
      }
      data-cy="delete-modal"
    >
      {children || (
        <Typography variant="body1" mb="12px" data-testid="confirm-text">
          {confirmText}
          {itemName ? (
            <Typography
              component="span"
              variant="body1"
              sx={{ fontWeight: 700, wordBreak: 'break-word', lineHeight: '24px' }}
            >
              {' '}
              {itemName}
            </Typography>
          ) : (
            <></>
          )}
          ?
        </Typography>
      )}
    </Modal>
  );
};

DeleteModal.propTypes = {
  children: PropTypes.node,
};

export default DeleteModal;
