import React from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useFormContext } from 'react-hook-form';

import WorkRequestTableFields from './WorkRequestTableFields';

const WorkRequestTableRow = ({ visibleInputs, setVisibleInputs, field, row, openModalUom, workRequest }) => {
  const { reset } = useFormContext();

  const openNewItem = () => {
    setVisibleInputs(true);
    reset();
  };

  if (!visibleInputs && field === 'workRequestItemName') return <ButtonToAddItem onClick={openNewItem} />;

  if (visibleInputs) {
    return <WorkRequestTableFields field={field} row={row} openModalUom={openModalUom} workRequest={workRequest} />;
  }

  return null;
};

export const ButtonToAddItem = ({ onClick }) => (
  <Stack
    sx={{
      cursor: 'pointer',
      padding: '2px 0',
      color: 'secondary.dark',
      '&:hover': {
        textDecoration: 'underline',
        color: 'secondary.dark',
      },
    }}
    direction="row"
    alignItems="center"
    onClick={onClick}
    data-cy="add-work-request-item-button"
    data-testid="add-work-request-item-button"
  >
    <Typography variant="body2" sx={{ letterSpacing: '0.15px' }}>
      + Add item
    </Typography>
  </Stack>
);

export default WorkRequestTableRow;
