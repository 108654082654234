import React from 'react';

import { Button, Stack } from '@mui/material';

import { IWorkflowsEditorHeaderProps } from '../../@types/WorkfowsEditorTypes';
import WorkflowsEditorBreadcrumb from '../WorkflowsEditorBreadcrumb/WorkflowsEditorBreadcrumb';

const WorkflowsEditorHeader = ({
  onCancel,
  onPublish,
  openNameField,
}: IWorkflowsEditorHeaderProps) => (
  <Stack direction="row" alignItems="center" justifyContent="space-between">
    <WorkflowsEditorBreadcrumb openNameField={openNameField} />
    <Stack direction="row" alignItems="center" justifyContent="flex-end">
      <Button
        style={{ marginRight: 24, fontWeight: 500 }}
        variant="text"
        onClick={() => onCancel && onCancel()}
      >
        Cancel
      </Button>
      <Button onClick={onPublish} variant="contained" data-cy="assemblyEditorHeaderPublishBtn">
        Publish
      </Button>
    </Stack>
  </Stack>
);

export default WorkflowsEditorHeader;
