import React from 'react';

import { CircularProgress } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { useUser } from 'app/UserContext';
import SimpleControlledAutoComplete from 'components/FormComponents/SimpleControlledAutoComplete';
import { useQueryManufacturedList } from 'hooks-api/queries/manufactured/useQueryManufactured';

export const ManufacturersAutocomplete = ({ disabled = false, ...props }) => {
  const { control } = useFormContext();
  const { user } = useUser();

  const { data: manufactured, loading: loadingManufacturers } = useQueryManufacturedList({
    variables: { query: { companyId: user?.companyId } },
    fetchPolicy: 'cache-and-network',
  });

  const manufacturedOptions = getManufacturerOptions(manufactured);

  if (loadingManufacturers && manufacturedOptions?.length <= 0) return <CircularProgress color="secondary" size={25} />;

  return (
    <SimpleControlledAutoComplete
      freeSolo
      fullWidth
      disabled={disabled}
      margin="dense"
      variant="outlined"
      color="secondary"
      size="extra-small"
      placeholder="Manufacturer"
      name="manufacturer"
      rules={getRulesManufacturerAutocomplete()}
      control={control}
      options={manufacturedOptions ?? []}
      sx={stylesManufacturerAutocomplete}
      style={{ width: '100%' }}
      maxLength={64}
      {...props}
    />
  );
};

const stylesManufacturerAutocomplete = {
  margin: 0,
  '& .MuiInputBase-root': { height: 24, fontSize: 14 },
  '& .MuiFormHelperText-root.Mui-error': { textAlign: 'end', marginRight: 0 },
  '& .MuiAutocomplete-input': { position: 'relative', top: -8 },
};

const getRulesManufacturerAutocomplete = () => ({
  required: { value: true, message: 'Required' },
});

const getManufacturerOptions = (manufactured) => {
  const options = manufactured?.manufactured ?? [];
  return options.map(({ manufacturerId, manufacturerName }) => ({
    label: manufacturerName,
    id: manufacturerId,
  }));
};
