import React from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';

import StyledAlert from 'modules/Authentication/components/StyledAlert';

const ErrorMessage = ({ code, onClose }) => {
  const navigate = useNavigate();

  const Alert = ({ children }) => (
    <Box mb="45px">
      <StyledAlert severity="error" onClose={onClose}>
        {children}
      </StyledAlert>
    </Box>
  );

  switch (code) {
    case 'LimitExceededException':
      return <Alert>Too many attemps, try again later</Alert>;

    case 'NotAuthorizedException':
      return <Alert>Invalid email or password.</Alert>;

    case 'UserNotFoundException':
      return <Alert>Username/client id combination not found.</Alert>;

    case 'EmailNotVerified':
      return <Alert>Your email address has not been verified!</Alert>;

    case 'InvitationExpired':
      return <Alert>Sorry, this invitation has expired.</Alert>;

    case 'EmailAlreadyExists':
      return (
        <Alert>
          The email already exists, try a different email or
          <Link onClick={() => navigate('/auth/login')}>{' try to login to resend verification code'}</Link>
        </Alert>
      );

    default:
      return <Alert>Oops something went wrong!</Alert>;
  }
};

export default ErrorMessage;

// needs translations
