import React, { useState } from 'react';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';

import { useEvolveMembersContext } from './EvolveMembersContext';
import EvolveMembersDeleteModal from './EvolveMembersTable/EvolveMembersDeleteModal';

const EvolveMembersActions = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { selected, setSelected } = useEvolveMembersContext();

  const selectedMemberIds = selected.map((user) => user.userId);

  return (
    <Box>
      <MenuItem onClick={() => setShowDeleteModal(true)} disabled={selected.length < 1}>
        Delete
      </MenuItem>{' '}
      <EvolveMembersDeleteModal
        companyMemberIds={selectedMemberIds}
        open={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
          setSelected([]);
        }}
      />
    </Box>
  );
};

export default EvolveMembersActions;
