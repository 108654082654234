export const validateMaxLengthRule = (value = '', max = 32, showLabelAtLimit = false) => {
  const showLabel = showLabelAtLimit ? 1 : 0;
  const currentLength = value?.toString().length;
  if (currentLength > max - showLabel) return `${currentLength}/${max}`;
  return null;
};

export const validateMaxLengthLimitRule = (value = '', max = 32) => {
  const currentLength = value?.toString().length;
  if (currentLength >= max) return `${currentLength}/${max}`;
  return null;
};
