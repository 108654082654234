import { gql, useMutation } from '@apollo/client';

import { updateWorkRequest as UPDATE_WORK_REQUEST } from 'graphql/mutations';

const useWorkRequestMutation = () => {
  const [updateWorkRequestMutation, { loading }] = useMutation(gql(UPDATE_WORK_REQUEST), {
    update(cache) {
      cache.evict({
        id: 'ROOT_QUERY',
        fieldName: 'workRequests',
      });
    },
  });

  return { loading, updateWorkRequestMutation };
};

export default useWorkRequestMutation;
