// * this one is needed due to the amount of variables and functions provided
/* eslint-disable max-lines-per-function */
/* eslint-disable import/no-cycle */
import React, { FC, useEffect, useMemo, useState } from 'react';

import { useGridApiRef } from '@mui/x-data-grid-pro';
import { FormProvider, useForm } from 'react-hook-form';

import useGeneralContext from 'helpers/useGeneralContext';
import useFetchWorkRequests from 'hooks-api/useFetchWorkRequests';

import {
  IItemsIntegrationModalProvider,
  ItemToWRType,
  SelectedItemsType,
  UseItemsIntegrationModalContextType,
} from '../@types/itemsIntegrationModalContext';
import { COLUMNS, STEPS, USE_FORM_OPTS } from '../constants';
import useItemsIntegrationModalContextEffectsHandler from '../hooks/useItemsIntegrationModalContextEffectsHandler';

const ItemsIntegrationContext = React.createContext<UseItemsIntegrationModalContextType | undefined>(undefined);

const ItemsIntegrationProvider: FC<IItemsIntegrationModalProvider> = ({
  children,
  /** addNewWR = true for showing "+ NEW WORK REQUEST" button */
  addNewWR = true,
  selectedItems: selectedItemsProp = [],
  /** showOnlyQtyStep for only second step */
  showOnlyQtyStep = false,
  showSteps = true,
  locationsTableDescription = '',
  selectedItemIdKey,
}: IItemsIntegrationModalProvider) => {
  const [open, setOpen] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [completed, setCompleted] = useState<UseItemsIntegrationModalContextType['completed']>({});
  const [rows, setRows] = useState<ItemToWRType[]>([]);
  const [selectionModel, setSelectionModel] = useState<any[]>([]);
  const [addNewWRForm, setAddNewWRForm] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [enableDone, setEnableDone] = useState<boolean>(false);
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [focusedQtyField, setFocusedQtyField] = useState<string | null>(null);
  const [hoveredQtyField, setHoveredQtyField] = useState<string | null>(null);
  const [selectedItems, setSelectedItems] = useState<SelectedItemsType>([]);
  const { loading: loadingWorkRequests } = useFetchWorkRequests();
  const qtyStepMethods = useForm(USE_FORM_OPTS);
  const totalSelectedItems = useMemo(
    () => selectedItems.filter(({ remainingQuantity }) => (remainingQuantity as number) > 0).length,
    [selectedItems],
  );
  const steps = useMemo(() => (showOnlyQtyStep ? [STEPS[1]] : STEPS), [showOnlyQtyStep]);
  const apiRef = useGridApiRef();
  useEffect(() => {
    setSelectedItems([...selectedItemsProp.filter(({ remainingQuantity }) => (remainingQuantity as number) > 0)]);
  }, [selectedItemsProp]);

  const {
    handleOnScrollEnd,
    handleClose,
    handleNext,
    handleConfirmClose,
    radioCheckColumn,
    selectedProject,
    openConfirmLeaveModal,
    setOpenConfirmLeaveModal,
    showDialog,
    setShowDialog,
  } = useItemsIntegrationModalContextEffectsHandler({
    setAddNewWRForm,
    setActiveStep,
    setCompleted,
    setIsLoading,
    setSelectionModel,
    setOpen,
    addNewWR,
    setRows,
    isLoading,
    setEnableDone,
    enableDone,
    qtyStepMethods,
    completed,
  });

  const columns = useMemo(() => [radioCheckColumn, ...COLUMNS], [radioCheckColumn]);

  useEffect(() => {
    if (!open) qtyStepMethods.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const valueObj: UseItemsIntegrationModalContextType = {
    open,
    setOpen,
    handleClose,
    selectedItems,
    selectedItemsProp,
    setSelectedItems,
    totalSelectedItems,
    steps,
    activeStep,
    setActiveStep,
    completed,
    setCompleted,
    rows,
    setRows,
    columns,
    selectionModel,
    setSelectionModel,
    apiRef,
    handleOnScrollEnd,
    loadingWorkRequests,
    addNewWRForm,
    setAddNewWRForm,
    handleNext,
    isLoading,
    setIsLoading,
    enableDone,
    setEnableDone,
    openSnackBar,
    setOpenSnackBar,
    selectedProject,
    addNewWR,
    showOnlyQtyStep,
    openConfirmLeaveModal,
    setOpenConfirmLeaveModal,
    showDialog,
    setShowDialog,
    handleConfirmClose,
    showSteps,
    qtyStepMethods,
    locationsTableDescription,
    focusedQtyField,
    setFocusedQtyField,
    hoveredQtyField,
    setHoveredQtyField,
    selectedItemIdKey,
  };
  return (
    <FormProvider {...qtyStepMethods}>
      <ItemsIntegrationContext.Provider value={valueObj}>{children}</ItemsIntegrationContext.Provider>
    </FormProvider>
  );
};

const useItemsIntegrationModalContext: () => UseItemsIntegrationModalContextType = () =>
  useGeneralContext(ItemsIntegrationContext, 'ItemsIntegrationContext');

export { ItemsIntegrationContext, ItemsIntegrationProvider, useItemsIntegrationModalContext };
