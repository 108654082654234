import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import { Modal } from 'components';

const TakeOffHeaderModal = ({ title }) => (
  <Box>
    {title}
    <IconButton sx={{ ml: 2 }} size="large" />
  </Box>
);

const DeclineButton = ({ onClick }) => (
  <Button
    size="medium"
    variant="text"
    onClick={onClick}
    sx={{
      fontSize: '15px',
      lineHeight: '24px',
      letterSpacing: '0.46px',
      textAlign: 'right',
      fontWeight: 500,
    }}
  >
    CANCEL
  </Button>
);

const AcceptButton = ({ onClick }) => (
  <Button
    size="medium"
    variant="contained"
    onClick={onClick}
    data-cy="confirm-delete-pad-item"
    sx={{
      fontWeight: 500,
      backgroundColor: 'error.main',
      fontSize: '15px',
      lineHeight: '24px',
      letterSpacing: '0.46px',
      textAlign: 'right',
      '&:hover': {
        backgroundColor: 'error.containedHoverBackground',
      },
    }}
  >
    DELETE
  </Button>
);

export const TakeOffActionModal = ({ title, open, onAccept, onDecline, children }) => (
  <Modal
    open={open}
    onClose={onDecline}
    disableTitleClose={false}
    closeButtonColor="rgba(0, 0, 0, 0.54)"
    closeButtonStyles={{
      padding: 0,
      marginRight: '-8px',
    }}
    disableModalOutsideClick
    maxWidth="md"
    scroll="paper"
    sx={{
      '.MuiPaper-root': {
        maxHeight: '80vh',
      },
    }}
    titleStyles={{ fontWeight: 700 }}
    title={<TakeOffHeaderModal title={title} />}
    footer={
      <>
        <DeclineButton onClick={onDecline} />
        <AcceptButton disabled={false} onClick={onAccept} />
      </>
    }
  >
    {children}
  </Modal>
);
