import React from 'react';

import { Typography } from '@mui/material';

import Section from 'components/Section';
import StackRow from 'components/StackRow';
import NoRowsOverlay from 'modules/Materials/commons/NoRowsOverlay';

import { useModal } from '../contexts/ModalContext';
import ProjectRow from './components/ProjectRow';

const LocationsTable = () => {
  const { project } = useModal();

  return (
    <Section sx={{ mt: '1px' }}>
      <TableHeaderColumns />
      <Section.Body sx={{ height: '325px', overflow: 'auto', overflowY: 'overlay !important' }}>
        {project.type === 'PROJECT' ? <ProjectRow /> : <NoRowsOverlay text="Start by opening a project" />}
      </Section.Body>
    </Section>
  );
};

export default LocationsTable;

const TableHeaderColumns = () => (
  <Section.Title sx={{ minHeight: '32px', p: 0, gap: 0 }} justifyContent="space-between">
    <StackRow>
      <Typography variant="subtitle2" sx={{ margin: '0 104px 0 48px' }}>
        Id
      </Typography>
      <Typography variant="subtitle2" sx={{}}>
        Location/ Work Phase
      </Typography>
    </StackRow>
    <StackRow>
      <Typography variant="subtitle2" sx={{ mr: '27px' }}>
        Aggregated Qty
      </Typography>
      <Typography variant="subtitle2" sx={{ mr: '16px' }}>
        Qty Required
      </Typography>
    </StackRow>
  </Section.Title>
);
