import React, { useState } from 'react';

import { Stack, IconButton } from '@mui/material';
import { Draggable } from 'react-beautiful-dnd';
import ReactDOM from 'react-dom';

import { Custom } from 'components/Icons';

const dndPortal = document.getElementById('dnd-portal');

const ListFlyoutPaperItem = ({
  draggable,
  draggableId,
  index,
  disableDragFunction,
  sx,
  children,
}) => {
  const [showDrag, setShowDrag] = useState(false);

  return draggable ? (
    <Draggable draggableId={draggableId} index={index} isDragDisabled={disableDragFunction}>
      {(provided, snapshot) => {
        const MainContent = (
          <StyledItem
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            onMouseLeave={() => setShowDrag(false)}
            onMouseEnter={() => setShowDrag(true)}
            ref={provided.innerRef}
            sx={sx}
          >
            <DraggableIcon showDrag={showDrag} />
            <Stack sx={{ ml: showDrag ? 0 : '14px', width: '100%', pr: 2 }}>{children}</Stack>
          </StyledItem>
        );

        return snapshot.isDragging ? ReactDOM.createPortal(MainContent, dndPortal) : MainContent;
      }}
    </Draggable>
  ) : (
    <StyledItem sx={sx}>
      <Stack sx={{ ml: '14px', width: '100%', pr: 2 }}>{children}</Stack>
    </StyledItem>
  );
};

const StyledItem = React.forwardRef(({ sx, children, ...props }, ref) => (
  <Stack ref={ref} sx={{ mt: '16px', width: 'auto', ...sx }} direction="row" {...props}>
    {children}
  </Stack>
));

const DraggableIcon = ({ showDrag }) => (
  <IconButton
    style={{
      cursor: 'move',
      padding: 0,
      margin: 0,
    }}
  >
    {showDrag && <Custom.DragIndicatorAlt style={{ width: '14px', height: '14px' }} />}
  </IconButton>
);

export default ListFlyoutPaperItem;
