import { Typography } from '@mui/material';
import dayjs from 'dayjs';

import { getDaysBetween } from 'helpers/dateFunctions';

type Props = {
  needByDate: string;
  statusDate: string;
  status:
    | 'Draft'
    | 'Transferred'
    | 'Completed'
    | 'Rejected'
    | 'Submitted'
    | 'Pending'
    | 'Approved'
    | 'In Progress'
    | 'Canceled';
};

const DaysRemaining = ({ needByDate, statusDate, status }: Props) => {
  const firstDate = new Date(`${needByDate}Z`);

  const datesAreNotValid = !dayjs(`${firstDate}Z`).isValid() || (statusDate && !dayjs(`${statusDate}Z`).isValid());
  if (datesAreNotValid) return <></>;

  const secondDate = status === 'Completed' && statusDate ? new Date(`${statusDate}Z`) : new Date();
  const daysRemaining = needByDate && getDaysBetween(firstDate, secondDate);

  const color = Number(daysRemaining) < 0 ? 'error.main' : 'surface.lightSurface.primary';

  return (
    <Typography variant="body2" sx={{ color }}>
      {status === 'Completed' ? `${daysRemaining}`.replace('-', '') : daysRemaining}
    </Typography>
  );
};

export default DaysRemaining;
