import dagre from 'dagre';

import { ASSEMBLY_TASK_NODE_HEIGHT, ASSEMBLY_TASK_NODE_WIDTH } from './constants';

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

export const autoLayoutedNodes = (nodes = [], edges = []) => {
  dagreGraph.setGraph({ rankdir: 'LR' });
  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, {
      width: ASSEMBLY_TASK_NODE_WIDTH,
      height: ASSEMBLY_TASK_NODE_HEIGHT,
    });
  });
  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });
  dagre.layout(dagreGraph);
  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = 'right';
    node.sourcePosition = 'left';
    node.position = {
      x: nodeWithPosition.x - ASSEMBLY_TASK_NODE_WIDTH / 2,
      y: nodeWithPosition.y - ASSEMBLY_TASK_NODE_HEIGHT / 2,
    };

    return node;
  });
  return nodes;
};
