/* eslint-disable max-lines-per-function */
import * as React from 'react';

import Checkbox from '@mui/material/Checkbox';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Draggable } from 'react-beautiful-dnd';

import { Custom } from '../Icons';
import MoreMenu from '../MoreMenu';

const Row = ({
  headers,
  row,
  items,
  isDraggable,
  onSelected,
  itemsSelected = [],
  onSelectRow,
  rowSelected = null,
  onClickOutsideRow = () => {},
  id,
  sx,
  index = 0,
  selectCell = true,
}) => {
  const buildComponent = () => {
    const callback = headers[0]?.leadingComponent;
    if (!index && typeof callback === 'function')
      return (
        <IconButton
          sx={{ ml: 3, width: 18, height: 18, pl: '13px', pt: '12px' }}
          onClick={callback}
          size="large"
        >
          <Custom.CloseIcon sx={{ fontSize: 24 }} />
        </IconButton>
      );
    if (onSelected)
      return (
        <CheckAllBox
          onChange={(e) => onSelected(row, e)}
          sx={sx}
          checked={Boolean(itemsSelected.find((item) => item[id] === row[id]))}
        />
      );

    return null;
  };

  const RowContent = (
    <>
      {selectCell && <SelectCell sx={sx}>{buildComponent()}</SelectCell>}

      {headers.map(({ key, render, options, props, setValue }) => {
        const renderKey = `${key}.${row[id]}`;
        if (render) {
          return (
            <TableCell sx={sx} component="th" scope="row" key={renderKey}>
              {render({ item: row, items, index })}
            </TableCell>
          );
        }
        if (key === '*options') {
          const optionProps = props ? props({ item: row, items }) : {};
          return (
            <RowOption
              sx={sx}
              key={renderKey}
              options={options?.map((option) => ({
                ...option,
                clickEvent: () => option?.clickEvent({ item: row, items }),
              }))}
              {...optionProps}
            />
          );
        }

        return (
          <TableCell sx={sx} key={renderKey}>
            {setValue ? setValue(row[key], row) : row[key]}
          </TableCell>
        );
      })}
    </>
  );

  return (
    <ClickAwayListener
      disableReactTree
      mouseEvent="onMouseUp"
      onClickAway={(event) => {
        if (row?.isNewRow) {
          const className = event?.target?.className ?? '';
          const { id = '' } = event.target ?? {};
          const iconIgnored = typeof className?.baseVal === 'string';
          const functionValidation = iconIgnored ? 'ClickAwayListener-ItemIgnored' : className;

          const classNameValidation =
            functionValidation?.includes('MuiBackdrop-root') ||
            functionValidation?.includes('MuiBackdrop-invisible') ||
            functionValidation?.includes('PrivateSwitchBase-input') ||
            functionValidation?.includes('MuiTypography-root');

          const idValidation = id?.includes('ClickAwayListener-ItemIgnored');

          if (classNameValidation || idValidation) {
            event.preventDefault();
          } else {
            onClickOutsideRow(event);
          }
        }
      }}
    >
      {isDraggable ? (
        <Draggable draggableId={id} index={index} isDragDisabled>
          {(provided) => (
            <TableRow
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              id={`row-${index}`}
              onClick={() => onSelectRow && onSelectRow(row)}
              sx={sx}
              {...{ selected: rowSelected && rowSelected[id] === row[id] }}
            >
              {RowContent}
            </TableRow>
          )}
        </Draggable>
      ) : (
        <TableRow
          id={`row-${index}`}
          onClick={() => onSelectRow && onSelectRow(row)}
          sx={sx}
          {...{ selected: rowSelected && rowSelected[id] === row[id] }}
        >
          {RowContent}
        </TableRow>
      )}
    </ClickAwayListener>
  );
};

const RowOption = ({ options, disabled, sx, ...rest }) => (
  <TableCell sx={sx}>
    <MoreMenu
      disabled={disabled}
      options={options}
      sxIconButton={{ height: sx.height, width: sx.height }}
      {...rest}
    />
  </TableCell>
);

const SelectCell = ({ sx, children }) => (
  <CheckAllCell sx={sx} padding="checkbox">
    {children}
  </CheckAllCell>
);

const CheckAllBox = ({ sx, ...props }) => (
  <Checkbox
    sx={{
      marginLeft: '25px',
      width: 32,
      height: 32,
      '&.Mui-checked': {
        color: 'secondary.main',
      },
      '&.MuiCheckbox-indeterminate': {
        color: 'secondary.main',
      },
      ...sx,
    }}
    {...props}
  />
);

const CheckAllCell = ({ sx, ...props }) => (
  <TableCell
    sx={{
      backgroundColor: 'white',
      ...sx,
    }}
    {...props}
  />
);

export default Row;
