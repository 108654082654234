import React from 'react';

import useDelimiterOptions from '../../../hooks/useDelimiterOptions';
import DropDown from './Dropdown';

const NameDelimiterList = ({ sx, ...props }) => {
  const { delimiterOptions } = useDelimiterOptions();
  return (
    <DropDown
      name="nameDelimiter"
      label="Delimiter"
      options={delimiterOptions}
      sx={{ flex: 1, ...sx }}
      placeholder="ex. -"
      shrink
      {...props}
    />
  );
};

export default NameDelimiterList;
