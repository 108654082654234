import React, { useSendToBomModal } from '../SendToBomModalContext';
import ItemsToMove from './ItemsToMove';

const SelectedItems = () => {
  const { itemsToMove } = useSendToBomModal();

  if (!itemsToMove) return <></>;
  return itemsToMove.map((item) => <ItemsToMove item={item} key={item.LineItemId} />);
};

export default SelectedItems;
