import React from 'react';

import { FormLabel } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Controller } from 'react-hook-form';

const BuiltRadionGroup = ({
  sxLabel,
  label,
  field,
  fieldState,
  row,
  direction,
  sx,
  size,
  radioOptions,
  activeColor,
  onChange,
}) => (
  <>
    <FormLabel
      sx={{
        fontSize: '14px',
        lineHeight: '32px',
        letterSpacing: '0.15px',
        color: 'surface.lightSurface.secondary',
        ...sxLabel,
      }}
    >
      {label}
    </FormLabel>
    <RadioGroup {...field} row={row} sx={sx}>
      <Stack>
        <Stack direction={direction}>
          {radioOptions?.map(({ label, value, disabled = false, disabledRadioOnly = false }) => (
            <FormControlLabel
              key={value}
              value={value}
              control={<Radio size={size} color={activeColor} disabled={disabledRadioOnly} />}
              label={label}
              sx={{ marginRight: '16px', letterSpacing: '0.15px' }}
              onChange={(event) => {
                if (onChange) {
                  onChange(event);
                }
                return event.target.value;
              }}
              disabled={disabled}
            />
          ))}
        </Stack>
        {fieldState.invalid && (
          <Typography variant="h1" sx={{ color: '#F44336', fontSize: '12px' }}>
            You must select one option
          </Typography>
        )}
      </Stack>
    </RadioGroup>
  </>
);

const ControlledRadioGroup = ({
  control,
  name,
  row,
  direction,
  size,
  radioOptions,
  label,
  sxLabel,
  activeColor,
  sx,
  onChange,
  defaultValue = '',
}) => (
  <Controller
    rules={{ required: true }}
    control={control}
    name={name}
    defaultValue={defaultValue}
    render={({ field, fieldState }) => (
      <BuiltRadionGroup
        sxLabel={sxLabel}
        label={label}
        field={field}
        size={size}
        fieldState={fieldState}
        row={row}
        direction={direction}
        sx={sx}
        radioOptions={radioOptions}
        activeColor={activeColor}
        onChange={onChange}
      />
    )}
  />
);

export default ControlledRadioGroup;
