import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { gql } from '@apollo/client';

import { messages as MESSAGES } from 'graphql/queries';
import useLazyPaginatedQuery from 'hooks/useLazyPaginatedQuery';

import { MessageStatus } from './MessagesContext';

const ACTIVE_MESSAGES_QUERY_VARIABLE = {
  status: `${MessageStatus.ACTIVE},${MessageStatus.DISMISSED}`,
  read: 'all',
};

const ActiveMessagesContext = React.createContext({
  messages: [],
  loading: false,
  onSearch: () => {},
  onFetchMore: () => {},
});

const ActiveMessagesProvider = ({ children }) => {
  const [activeMessages, setActiveMessages] = useState([]);

  const [{ lazyLoad, searchHandler, paginationHandler }, { loading, data }] = useLazyPaginatedQuery(
    gql(MESSAGES),
  );

  useEffect(() => lazyLoad(ACTIVE_MESSAGES_QUERY_VARIABLE), [lazyLoad]);

  useEffect(() => {
    setActiveMessages(data?.messages);
  }, [data]);

  const onSearch = useCallback(
    (searchPhrase) => {
      searchHandler(searchPhrase, ACTIVE_MESSAGES_QUERY_VARIABLE);
    },
    [searchHandler],
  );

  const onFetchMore = useCallback(
    (skip) => {
      paginationHandler(skip, ACTIVE_MESSAGES_QUERY_VARIABLE);
    },
    [paginationHandler],
  );

  const contextValue = useMemo(
    () => ({ messages: activeMessages ?? [], loading, onSearch, onFetchMore, setActiveMessages }),
    [activeMessages, loading, onSearch, onFetchMore],
  );

  return (
    <ActiveMessagesContext.Provider value={contextValue}>{children}</ActiveMessagesContext.Provider>
  );
};

const useActiveMessagesContext = () => {
  const context = React.useContext(ActiveMessagesContext);
  if (context === undefined) {
    throw new Error('useActiveMessagesContext must be used within a ActiveMessagesContext');
  }
  return context;
};

export { ActiveMessagesContext, ActiveMessagesProvider, useActiveMessagesContext };
