/* eslint-disable max-lines-per-function */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { CancelButton, CreateButton } from 'components/Buttons';
import { getOccurrences } from 'helpers/arrayFunctions';

import ConfirmationModal from '../../../../../components/ConfirmationModal';
import { buildChipTemplate, ROW_TYPES } from '../../constants/constants';
import { useLocationsAndWorkPhases } from '../../contexts/LocationsAndWorkPhaseContext';
import { validateFormIsEmpty } from '../helpers/formHelpers';
import PreviewModal from '../PreviewModal/PreviewModal';
import ConfirmationSubmitWithDraftModal from './components/ConfirmationSubmitWithDraftModal';
import Loader from './components/Loader';
import StyledModal from './components/StyledModal';
import WizardStepper from './components/WizardStepper';
import Chips from './CreateChipForm/components/Chips';
import CreateChipForm from './CreateChipForm/CreateChipForm';
import { useChips } from './CreateLocationsModalContext';
import useFormValues from './hooks/useFormValues';

const steps = ['Locations', 'Work phases'];

const CreateLocationsModal = ({ open, onClose }) => {
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openConfirmationModalWithDraft, setOpenConfirmationModalWithDraft] = useState(false);
  const {
    chips,
    setChips,
    activeChip,
    setActiveChip,
    setFormIsValid,
    setHasNotWorkPhases,
    activeStep,
    completed,
    handleResetStepper,
    handleNext,
    handleChangeActiveStep,
    deleteNewWorkPhases,
  } = useChips();
  const form = useFormValues(activeChip, () => {
    deleteNewWorkPhases();
    onClose();
  });
  const {
    locations: { activeRow },
  } = useLocationsAndWorkPhases();

  const { reset: resetForm, formState, control, loading, formValues, trigger, submit } = form;
  const { locationTypeName, workPhase, preview, isDraft } = formValues;

  const isFinalStep = activeStep === steps.length - 1;

  const disableDoneButton = loading;
  const { isValid } = formState;
  const disableNextButton = isDraft || !isValid;

  const handleSubmit = () => {
    const draftItems = getOccurrences('isDraft', true, chips);
    const hasAnyInvalidDraft = draftItems.some((chip) => {
      if (chip.locationTypeName !== ROW_TYPES.WORK_PHASE) return true;
      if (chip.scopePackage) return true;
      return false;
    });
    if (hasAnyInvalidDraft) setOpenConfirmationModalWithDraft(true);
    else submit();
  };

  const handleClickCancel = () => {
    const firstChipsIsNotADraft = !chips[0].isDraft;
    const formIsEmpty = validateFormIsEmpty(formValues);
    if (firstChipsIsNotADraft || !formIsEmpty) setOpenConfirmationModal(true);
    else onClose();
  };

  useEffect(() => {
    if (open) {
      const initialChip = buildChipTemplate();
      resetForm(initialChip);
      setChips([initialChip]);
      setActiveChip(initialChip);
      handleResetStepper();
    }
  }, [open]);

  useEffect(() => {
    const isAWorkPhase = locationTypeName === ROW_TYPES.WORK_PHASE;
    const emptyWorkPhaseList = !workPhase?.length;
    setHasNotWorkPhases(isAWorkPhase && emptyWorkPhaseList);
  }, [locationTypeName, workPhase]);

  useEffect(() => {
    if (activeChip) {
      resetForm(activeChip);
    }
  }, [activeChip]);

  useEffect(() => {
    setFormIsValid(isValid);
  }, [isValid]);

  return (
    <>
      <StyledModal
        open={open}
        onClose={onClose}
        subtitle={activeRow?.locationName || activeRow?.locationIdentifier}
        opacity={openConfirmationModal || openConfirmationModalWithDraft ? 0 : 1}
        footer={
          <>
            {loading && <Loader />}
            <CancelButton
              onClick={handleClickCancel}
              sx={{ color: 'secondary.dark', letterSpacing: '0.4px', fontWeight: 600 }}
            />
            {isFinalStep && <DoneButton onClick={handleSubmit} disabled={disableDoneButton} />}
            {!isFinalStep && <NextButton onClick={handleNext} disabled={disableNextButton} />}
          </>
        }
      >
        <WizardStepper
          steps={steps}
          completed={completed}
          activeStep={activeStep}
          setActiveStep={handleChangeActiveStep}
        />
        <Chips trigger={trigger} formValues={formValues} />
        <CreateChipForm form={form} activeStep={activeStep} />
      </StyledModal>
      <PreviewModal open={Boolean(preview)} control={control} />
      <ConfirmationModal
        open={openConfirmationModal}
        text="Are you sure you want to cancel the creation of the New Locations modal?"
        onClose={() => setOpenConfirmationModal(false)}
        onConfirm={() => {
          setOpenConfirmationModal(false);
          deleteNewWorkPhases();
          onClose();
        }}
      />
      <ConfirmationSubmitWithDraftModal
        open={openConfirmationModalWithDraft}
        onClose={() => setOpenConfirmationModalWithDraft(false)}
        onConfirm={() => {
          setOpenConfirmationModalWithDraft(false);
          submit();
        }}
      />
    </>
  );
};

export default CreateLocationsModal;

const NextButton = (props) => <CreateButton buttonText="Next" color="secondary" {...props} />;
const DoneButton = (props) => <CreateButton buttonText="Done" color="secondary" {...props} />;
