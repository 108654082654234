import React from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

const ActionsDropdown = ({
  menuItemsRenderer = () => {},
  label = 'Action',
  selectStyle = {},
  labelStyle = {},
  ...rest
}) => (
  <ActionFormControl {...rest}>
    <InputLabel sx={labelStyle} id="actions-label" size="small">
      {label}
    </InputLabel>
    <Select
      labelId="actions-label"
      id="actions-select"
      variant="outlined"
      label="Actions"
      value=""
      size="small"
      sx={selectStyle}
    >
      {menuItemsRenderer()}
    </Select>
  </ActionFormControl>
);

export default ActionsDropdown;

const ActionFormControl = (props) => (
  <FormControl sx={{ width: 145, marginLeft: '24px' }} {...props} />
);
