import React from 'react';

import { Stack, SxProps } from '@mui/material';

type KanbanColumnContentProps = {
  children: React.ReactNode;
  highlightColumn: boolean;
  highlightColor: string;
  sx: SxProps;
};

const KanbanColumnContent = ({
  children,
  highlightColumn,
  highlightColor,
  sx,
  ...restProps
}: KanbanColumnContentProps) => (
  <Stack
    {...restProps}
    sx={{
      ...sx,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#F5F5F5',
      backgroundAttachment: 'fixed',
      padding: '8px 6px 0 6px',
      width: '196px',
      flexGrow: 1,
      border: highlightColumn ? `2px solid ${highlightColor}` : '2px solid transparent',
      borderTop: 'none',
    }}
  >
    {children}
  </Stack>
);

export default KanbanColumnContent;
