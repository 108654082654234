import React, { MouseEvent } from 'react';

import { InputForesiteField } from '@evolvemep/foresite-components-ui-react';
import { Control, useFormContext } from 'react-hook-form';

type CatalogNumberInputProps = {
  disabled: boolean;
  control: Control;
};

export const CatalogNumberInput = ({ disabled, control }: CatalogNumberInputProps) => (
  <InputForesiteField
    name="manufacturerCatalogNumber"
    placeholder="Catalog Number"
    size="extra-small"
    color="secondary"
    sx={{ margin: 0, height: 24, '& .MuiInputBase-root': { height: 24 }, fontSize: 14 }}
    control={control}
    disabled={disabled}
    maxLength={64}
  />
);

type TradeSizeInputProps = { defaultValue: string };

export const TradeSizeInput = ({ defaultValue, ...restProps }: TradeSizeInputProps) => {
  const { control, formState } = useFormContext();

  const handleMouseDown = (event: MouseEvent<HTMLInputElement>) => {
    setTimeout(() => (event.target as HTMLInputElement).focus(), 300);
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <InputForesiteField
      name="tradeSize"
      placeholder="Trade Size"
      size="small"
      color="secondary"
      sx={{
        margin: 0,
        height: 24,
        '& .MuiInputBase-root': { height: 24 },
        fontSize: 14,
      }}
      maxLength={32}
      control={control}
      onMouseDown={handleMouseDown}
      defaultValue={defaultValue}
      data-cy="trade-size-input"
      disabled={formState.isSubmitting}
      {...restProps}
    />
  );
};
