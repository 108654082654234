import React, { useEffect, useRef } from 'react';

import { InputForesiteField } from '@evolvemep/foresite-components-ui-react';
import Box from '@mui/material/Box';

const WorkRequestControlInput = ({ autoFocus, disabled, ...restProps }) => {
  const textInput = useRef(null);

  useEffect(() => {
    if (autoFocus) {
      textInput.current.focus();
    }
  }, [autoFocus]);

  return (
    <Box sx={{ width: '100%', '.MuiFormControl-root': { width: '100%', margin: 0 } }}>
      <InputForesiteField
        inputRef={textInput}
        size="small"
        color="secondary"
        sx={{
          '.MuiOutlinedInput-root': { padding: 0 },
          '.MuiOutlinedInput-input': { padding: '2px 12px !important' },
          ...(disabled
            ? {
                fieldset: {
                  borderColor: '#eee !important',
                },
              }
            : {}),
        }}
        autoComplete="off"
        disabled={disabled}
        {...restProps}
      />
    </Box>
  );
};

export default WorkRequestControlInput;
