import { OperationVariables } from '@apollo/client';
import { Palette } from '@mui/material/styles';
import { DraggableLocation, DropResult } from 'react-beautiful-dnd';

import { QueryWorkOrdersQueryInput, WorkOrder, WorkOrderStatusesList } from 'graphql/types';

import getNewIndexForWorkOrder from './helpers/getNewIndexForWorkOrder';

export const getWorkOrderStatusIdByName = (
  name: string,
  workOrderStatusesList: Array<WorkOrderStatusesList> | undefined,
): WorkOrderStatusesList | undefined =>
  workOrderStatusesList?.find(
    (workOrderStatus) => workOrderStatus?.workOrderStatusTypeName?.toLowerCase() === name?.toLowerCase(),
  );

type KanbanColumn = {
  name: string;
  headerColor: string;
  title: string;
};

export const getColumns = (
  workOrdersStatusesList: Array<WorkOrderStatusesList>,
  palette: Palette,
): Array<KanbanColumn & WorkOrderStatusesList> => {
  const columns = [
    { name: 'Draft', headerColor: palette?.kanban?.draft, title: 'Draft' },
    { name: 'Not Started', headerColor: palette?.kanban?.notStarted, title: 'Not started' },
    { name: 'In Progress', headerColor: palette?.kanban?.inProgress, title: 'In progress' },
    { name: 'Blocked', headerColor: palette?.kanban?.blocked, title: 'Blocked' },
  ];

  return columns.reduce<Array<KanbanColumn & WorkOrderStatusesList>>((newColumns, currentColumn) => {
    const workOrderStatus = getWorkOrderStatusIdByName(currentColumn?.name, workOrdersStatusesList);
    if (!workOrderStatus) return newColumns;
    return [...newColumns, { ...currentColumn, ...workOrderStatus }];
  }, []);
};

export const getStatusesIds = (data: { workOrderStatusesList: any[] }): string | null => {
  const columns = ['Draft', 'Not Started', 'In Progress', 'Blocked'];

  const result = columns.reduce<Array<string>>((columnIds, currentColumn) => {
    const workOrderStatus = getWorkOrderStatusIdByName(currentColumn, data?.workOrderStatusesList);
    if (!workOrderStatus || !workOrderStatus?.workOrderStatusTypeId) return columnIds;
    return [...columnIds, workOrderStatus?.workOrderStatusTypeId];
  }, []);

  return result && result.length ? result?.toString() : null;
};

export const buildWorkOrderReadCacheVariables = (
  facilityId: string,
  source: DraggableLocation,
  workOrderStatusesList: Array<WorkOrderStatusesList>,
): { query: QueryWorkOrdersQueryInput } | null => {
  const workOrderStatusTypeId = getWorkOrderStatusIdByName(source.droppableId, workOrderStatusesList);

  if (!workOrderStatusTypeId) return null;

  return {
    query: {
      take: 50,
      orderBy: 'sortOrder:asc',
      workOrderStatusIds: workOrderStatusTypeId?.workOrderStatusTypeId,
      facilityId,
    },
  };
};

export const buildVariablesUpdateWorkOrder = (
  result: DropResult,
  sourceList: Array<WorkOrder>,
  destinationIndex: number,
): OperationVariables => ({
  params: { workOrderId: result.draggableId },
  body: { sortOrder: getNewIndexForWorkOrder(sourceList, destinationIndex) },
});
