import { useEffect } from 'react';

import { mapInformation } from '.';

export const useEffectThatUpdatesSelectedWorkRequest = (
  workRequestSelected,
  workRequests,
  setWorkRequest,
) => {
  useEffect(() => {
    let updatedWorkRequest = {};
    if (workRequestSelected) {
      const matchedWorkRequest = workRequests.find(
        (workRequest) => workRequest.workRequestId === workRequestSelected.workRequestId,
      );
      updatedWorkRequest = mapInformation(matchedWorkRequest);
    }
    if (workRequestSelected && updatedWorkRequest?.needBy) setWorkRequest(updatedWorkRequest);
    // eslint-disable-next-line
  }, [workRequests]);
};
