import React, { useEffect, useState } from 'react';

import { ForesiteTextField } from '@evolvemep/foresite-components-ui-react';
import { Stack, Typography } from '@mui/material';
import { useController } from 'react-hook-form';

import { inputStyles } from './WorkflowNameFieldStyles';

const WorkflowNameField = ({ control, onSubmit, onCancel, openNameField = false }) => {
  const [isNameFocused, setIsNameFocused] = useState(false);
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    name: 'name',
    control,
    rules: { required: true },
  });

  useEffect(() => {
    if (openNameField) {
      setIsNameFocused(true);
    }
  }, [setIsNameFocused, openNameField]);

  const handleSubmit = () => {
    if (value) {
      setIsNameFocused(false);
      onSubmit();
    } else {
      handleCancel();
    }
  };

  const handleCancel = () => {
    setIsNameFocused(false);
    onCancel();
  };

  return (
    <NameWrapper>
      {isNameFocused ? (
        <ForesiteTextField
          name="Name"
          value={value}
          onChange={onChange}
          onBlur={(e) => {
            onBlur(e);
            handleSubmit();
          }}
          onFocus={(event) => {
            event.target.select();
          }}
          onKeyDown={({ key, target }) => {
            const { value } = target;
            if (key === 'Enter' && value) handleSubmit();
            if (key === 'Escape') handleCancel();
          }}
          variant="filled"
          size="small"
          autoFocus
          hiddenLabel
          sx={inputStyles}
          error={Boolean(error)}
          InputProps={{
            disableUnderline: true,
          }}
        />
      ) : (
        <Typography
          color="text.primary"
          onClick={() => {
            setIsNameFocused(true);
          }}
        >
          {value}
        </Typography>
      )}
    </NameWrapper>
  );
};

export default WorkflowNameField;

const NameWrapper = ({ children }) => (
  <Stack
    sx={{
      '& .MuiFormControl-root': {
        verticalAlign: 'middle !important',
        marginTop: '0px !important',
        marginBottom: '0px !important',
      },
    }}
  >
    {children}
  </Stack>
);
