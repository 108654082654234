import React, { useEffect, useState } from 'react';

import { useFieldArray } from 'react-hook-form';

import { InputAdditionalImage } from 'components/FormComponents';

const PartAdditionalImages = ({
  partAdditionalImages,
  watch,
  control,
  setValue,
  getValues,
  isSubmitting,
  setError = () => {},
}) => {
  const [disabled, setDisabled] = useState(false);

  const { fields } = useFieldArray({ name: 'partAdditionalImages', control });
  const watchPartDefaultImage = watch('partDefaultImage.partImage');

  useEffect(() => {
    if (partAdditionalImages && partAdditionalImages?.length) {
      const partAdditionalImagesValue = [...new Array(4)].map((_, i) => ({
        partImage: null,
        imageUrl: partAdditionalImages[i]?.imageUrl,
        partImageId: partAdditionalImages[i]?.partImageId,
        sortOrder: partAdditionalImages[i]?.sortOrder,
        documentId: partAdditionalImages[i]?.document?.documentId,
      }));

      setValue(`partAdditionalImages`, partAdditionalImagesValue);
      return;
    }
    setValue(`partAdditionalImages`, [...new Array(4)]);
  }, [partAdditionalImages, setValue, getValues]);

  useEffect(() => {
    if (watchPartDefaultImage) {
      setDisabled(false);
      return;
    }

    setDisabled(true);
  }, [watchPartDefaultImage]);

  const handleSelectImage = () => {
    setError(null);
  };

  const handleError = (error) => {
    setError(error);
  };

  const handleDeleteImage = (index) => {
    setValue(`partAdditionalImages.${index}.isDeleted`, true);
    setValue(`partAdditionalImages.${index}.imageUrl`, null);
  };

  return fields.map((field, index) => (
    <InputAdditionalImage
      key={field.id}
      defaultValue={field?.imageUrl}
      control={control}
      disabled={disabled || isSubmitting}
      name={`partAdditionalImages.${index}.partImage`}
      accept="image/png, image/gif, image/jpeg, image/heic"
      sx={{ width: '32px', height: '32px' }}
      onDeleteImage={() => handleDeleteImage(index)}
      onSelectImage={handleSelectImage}
      onError={handleError}
    />
  ));
};

export default PartAdditionalImages;
