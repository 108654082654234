import React, { useState } from 'react';

import { MoreMenu } from 'components';
import { EIconType } from 'helpers/MenuIcons';
import CollapseChildrenIcon from 'modules/Field/LocationsAndWorkPhases/components/CollapseChildrenIcon';

import { CONTAINER_TYPES, ROW_TYPES } from '../../constants/constants';
import { useDataContext } from '../../contexts/DataContext';
import { fillBorderTopStyles } from '../../helpers/helpers';
import DroppableContainer from '../DroppableContainer';
import MachinesContainer from '../MachineRow/MachinesContainer';
import RowTitle from '../RowTitle';
import StyledIcon from '../StyledIcon';
import StyledRow from '../StyledRow';
import TasksContainer from '../TaskRow/TasksContainer';
import DeleteWorkCellModal from './components/DeleteWorkCellModal';
import { useWorkCell } from './contexts/WorkCellContext';

const WorkCellRow = () => {
  const { workCell, disabled, handleAddNewMachine } = useWorkCell();
  const [isOpen, setIsOpen] = useState(false);
  const [openDeleteWorkCellModal, setOpenDeleteWorkCellModal] = useState(false);

  const { activeRow, setActiveRow, isDragging, setCloseFlyoutMenu } = useDataContext();

  const mappedWorkCell = {
    label: workCell.workCellName,
    id: workCell.workCellId,
    type: ROW_TYPES.WORK_CELL,
    ...workCell,
  };

  const handleSelectRow = () => {
    setActiveRow(mappedWorkCell);
    setCloseFlyoutMenu(true);
  };

  const moreMenuOptions = [
    { label: 'New machine', clickEvent: () => handleAddNewMachine() },
    { label: 'Rename', clickEvent: () => handleSelectRow() },
    {
      label: 'Delete',
      clickEvent: () => setOpenDeleteWorkCellModal(true),
      color: 'error.main',
    },
  ];

  const isDisabled = disabled || (activeRow.workCellId === workCell.workCellId && activeRow.isNew);

  return (
    <>
      <StyledRow
        isActive={workCell.workCellId === activeRow.id}
        onClick={handleSelectRow}
        sx={fillBorderTopStyles(workCell.workCellId === activeRow.id, isDragging)}
        testId="workCell-row"
      >
        <CollapseChildrenIcon onClick={() => setIsOpen(!isOpen)} open={isOpen} showIcon filled sx={{ ml: 1.8 }} />
        <StyledIcon iconName={EIconType.WORKCELL} disabled={isDisabled} highlight={isOpen} />
        <RowTitle testId="work-cell-name" disabled={isDisabled}>
          {workCell.workCellName}
        </RowTitle>
        {!isDisabled && <MoreMenu options={moreMenuOptions} sx={{ fontSize: 21 }} />}
      </StyledRow>

      {!isDisabled && (
        <>
          <DroppableContainer droppableId={`${CONTAINER_TYPES.WORK_CELL_TASKS}_${workCell.workCellId}`} />
          <DroppableContainer
            droppableId={`${CONTAINER_TYPES.WORK_CELL_MACHINES}_${workCell.workCellId}`}
            type={ROW_TYPES.MACHINE}
          />
        </>
      )}

      {(isOpen || isDisabled) && (
        <>
          <TasksContainer />
          <MachinesContainer />
        </>
      )}

      <DeleteWorkCellModal
        node={mappedWorkCell}
        openDeleteWorkCellModal={openDeleteWorkCellModal}
        setOpenDeleteWorkCellModal={setOpenDeleteWorkCellModal}
      />
    </>
  );
};

export default WorkCellRow;
