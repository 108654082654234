import React from 'react';

import { Divider, Stack, Typography, useTheme } from '@mui/material';
import { formatInTimeZone } from 'date-fns-tz';

import RevitModelNoteList from './RevitModelNoteList';

const RevitModel = ({ revitModel, isLastRevitModel }) => {
  const theme = useTheme();
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  return (
    <Stack fontSize="14px" color="white" marginRight="10px">
      <Typography variant="body3" sx={{ mb: '10px' }}>
        {revitModel.externalSourceImportDescription ?? ''}
      </Typography>
      {revitModel.lastPublished && (
        <Stack
          direction="row"
          justifyContent="space-between"
          paddingBottom="10px"
          borderBottom="1px solid"
          borderColor={theme.palette.grey[600]}
        >
          <Typography variant="body2">Last Published</Typography>
          <Stack direction="row" gap="16px">
            <Typography variant="body2">
              {formatInTimeZone(new Date(`${revitModel.lastPublished}Z`), timeZone, 'MM/dd/yy')}
            </Typography>
            <Typography variant="body2">
              {formatInTimeZone(new Date(`${revitModel.lastPublished}Z`), timeZone, 'h:mm a')}
            </Typography>
          </Stack>
        </Stack>
      )}
      <Stack
        direction="row"
        justifyContent="space-between"
        paddingY="10px"
        borderBottom="1px solid"
        borderColor={theme.palette.grey[600]}
      >
        <Typography variant="body2">Published By</Typography>
        <Typography variant="body2">
          {revitModel.publishedBy ? revitModel.publishedBy : 'N/A'}
        </Typography>
      </Stack>
      <RevitModelNoteList notes={revitModel.sourcePadNotes} />

      {!isLastRevitModel && (
        <Divider
          sx={{
            borderColor: theme.palette.other.border,
            marginBottom: 1,
            marginTop: 1,
          }}
        />
      )}
    </Stack>
  );
};

export default RevitModel;
