import React, { useMemo } from 'react';

import DialogContent from '@mui/material/DialogContent';

import { ControlledInputCheckbox } from 'components';
import StackRow from 'components/StackRow';

import { IEditQuantityModal, Project } from './@types/editQuantityModalTypes';
import QuantityDialog, { ModalFooter, ModalTitle } from './components/QuantityDialog';
import { useEditQuantityModal } from './contexts/EditQuantityModalContext';
import { ModalProvider, useModal } from './contexts/ModalContext';
import { ProjectRowProvider } from './contexts/ProjectRowContext';
import LocationsTable from './LocationsTable/LocationsTable';

const EditQuantityModal = ({ data, onClose, ernPrefix = 'moab:takeoffpaditem', activeProject }: IEditQuantityModal) => {
  const { uniqueFilteredIds, uniqueCheckedIds } = useEditQuantityModal();

  const project = useMemo(
    (): Project => ({
      projectId: activeProject.id || '',
      projectName: activeProject.label || '',
      projectIdentifier: activeProject.identifier || '',
      type: activeProject.type || 'PROJECT',
    }),
    [activeProject],
  );

  return (
    <ModalProvider
      lineItem={data}
      ernPrefix={ernPrefix}
      parentFilteredIds={uniqueFilteredIds}
      filteredCheckedIds={uniqueCheckedIds}
      project={project}
    >
      <ProjectRowProvider>
        <QuantityDialog open={Boolean(data)} onClose={onClose}>
          <ModalTitle lineItemName={data?.lineItemName} />
          <StackRow sx={{ px: 3, span: { fontSize: '14px !important' } }} gap="16px">
            <Checkboxes />
          </StackRow>
          <DialogContent sx={{ padding: '8px 16px', zIndex: 6, position: 'relative' }}>
            <LocationsTable />
          </DialogContent>
          <ModalFooter onClose={onClose} />
        </QuantityDialog>
      </ProjectRowProvider>
    </ModalProvider>
  );
};

export default EditQuantityModal;

const Checkboxes = () => {
  const { control } = useModal();

  return (
    <>
      <ControlledInputCheckbox
        color="secondary"
        name="show0WorkLocations"
        control={control}
        label="Show locations with 0 qty required"
        checkboxProps={{ size: 'small' }}
      />
      <ControlledInputCheckbox
        color="secondary"
        name="show0WorkPhases"
        control={control}
        label="Show work phases with 0 qty required"
        checkboxProps={{ size: 'small' }}
      />
    </>
  );
};
