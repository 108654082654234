import React, { useState } from 'react';

import { Box, Tab, Tabs } from '@mui/material';

import { OptionsFilter } from '../../features/filters/options';
import BreakDown from './Breakdown/Breakdown';

const SourceTabs = ({ padSelected }) => {
  const [value, setValue] = useState(0);
  const handleChange = (_, newValue) => setValue(newValue);

  const tabsValues = [
    {
      label: 'breakdowns',
      children: (
        <BreakDown>
          <BreakDown.Menu />
          <BreakDown.Content />
        </BreakDown>
      ),
    },
    {
      label: 'Options',
      children: <OptionsFilter padSelected={padSelected} />,
    },
    // Hidden by a request, it will be active later.
    // {
    //   label: 'Rules',
    //   children: <RulesFilter />
    // },
    // { label: 'Source Data', children: <SourceData /> }
  ];
  return (
    <Box>
      <Box sx={{ margin: '16px 0 0 24px' }}>
        <Tabs value={value} textColor="secondary" indicatorColor="secondary" onChange={handleChange}>
          {tabsValues.map(({ label }) => (
            <Tab label={label} key={label} />
          ))}
        </Tabs>
      </Box>
      {tabsValues.map(({ children, label }, index) => (
        <TabPanel key={label} value={value} index={index}>
          {children}
        </TabPanel>
      ))}
    </Box>
  );
};

export default SourceTabs;

const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && <Box>{children}</Box>}
  </div>
);
