import React from 'react';

import { Stack } from '@mui/material';

import { EIconType } from 'helpers/MenuIcons';

import { useDataContext } from '../../contexts/DataContext';
import RowTitle from '../RowTitle';
import StyledIcon from '../StyledIcon';
import StyledRow from '../StyledRow';
import { useWorkCell } from '../WorkCellRow/contexts/WorkCellContext';
import UnmapMachineTask from './components/UnmapMachineTask';
import UnmapTask from './components/UnmapTask';

const TaskRow = ({ task, isMachineTask = false, sx }) => {
  const { disabled } = useWorkCell();
  const { activeRow, setActiveRow, setCloseFlyoutMenu } = useDataContext();

  const handleSelectRow = (taskId) => {
    setActiveRow({ id: taskId });
    setCloseFlyoutMenu(true);
  };

  const tasksRows = (() => {
    const children = [];

    if (!task.machines?.length) children.push(task);
    else
      task.machines.forEach((machine) =>
        children.push({
          ...task,
          machineName: machine.machineIdentifier || machine.manufacturer,
        }),
      );
    return children;
  })();

  return tasksRows?.map((task) => {
    const taskId = isMachineTask ? task.machineTaskTypeId : `${task.workCellTaskTypeId}-${task.machineName}`;
    return (
      <StyledRow
        key={taskId}
        isActive={taskId === activeRow.id}
        onClick={() => handleSelectRow(taskId)}
        sx={{ pl: 14, ...sx }}
      >
        <StyledIcon iconName={EIconType.SHOP_TASKS_NAV} disabled={disabled} />
        <RowTitle disabled={disabled}>{task.taskTypeName}</RowTitle>
        <TaskMachineName task={task} machineName={task.machineName} />
        <DeleteTaskType task={task} />
      </StyledRow>
    );
  });
};

export default TaskRow;

const DeleteTaskType = ({ task }) => {
  if (task?.workCellTaskTypeId) return <UnmapTask task={task} />;
  if (task.machineTaskTypeId) return <UnmapMachineTask task={task} />;
  return null;
};

const TaskMachineName = ({ machineName }) => {
  if (!machineName) return null;

  return (
    <Stack direction="row" sx={{ flex: 3, marginLeft: 1, userSelect: 'none' }}>
      <StyledIcon iconName={EIconType.MACHINE} />
      <RowTitle
        sx={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          maxWidth: '350px',
        }}
      >
        {machineName}
      </RowTitle>
    </Stack>
  );
};
