import WorkOrdersKanbanColumn from './WorkOrdersKanbanColumn';
import { WorkOrdersKanbanColumnProvider } from './WorkOrdersKanbanColumnContext';

type WorkOrderKanbanColumnProps = {
  name: string;
  headerColor: string;
  status: string;
  disabled: string | null;
  disabledDrag: boolean;
};

const WorkOrderKanbanColumn = ({
  name,
  headerColor,
  status,
  disabled,
  disabledDrag,
}: WorkOrderKanbanColumnProps) => (
  <WorkOrdersKanbanColumnProvider status={status}>
    <WorkOrdersKanbanColumn
      name={name}
      headerColor={headerColor}
      disabled={disabled}
      disableDrag={disabledDrag}
    />
  </WorkOrdersKanbanColumnProvider>
);

export default WorkOrderKanbanColumn;
