import React from 'react';

import { Typography } from '@mui/material';

const PartItemDetailAccordionSubtitle = ({ children, ...restProps }) => (
  <Typography
    variant="body1"
    sx={{ flex: 1, ...restProps, color: (theme) => theme.palette.surface.lightSurface.secondary }}
  >
    {children}
  </Typography>
);

export default PartItemDetailAccordionSubtitle;
