import { useMemo } from 'react';

import { FETCH_POLICIES, ITEM_TYPES } from 'constants/globalConstants';
import { WorkRequestItemType } from 'graphql/types';

import useWorkRequestItemTypesAPI from './useWorkRequestItemTypesAPI';

type TypeIds = {
  writeInItemTypeId?: string;
  lineItemTypeId?: string;
  partItemTypeId?: string;
  assemblyItemTypeId?: string;
};

const useWorkRequestItemTypesIds = (fetchPolicy = FETCH_POLICIES.CACHE_FIRST.fetchPolicy) => {
  const { workRequestItemType } = useWorkRequestItemTypesAPI(fetchPolicy);

  const itemTypes = useMemo(() => {
    if (!workRequestItemType?.length) return null;

    const itemTypes: TypeIds = {};

    workRequestItemType.forEach((type: WorkRequestItemType) => {
      if (!type.workRequestItemTypeId) return;

      const typeName = type.workRequestItemTypeName;
      const typeId = type.workRequestItemTypeId;

      if (typeName === ITEM_TYPES.WRITE_IN_ASSEMBLY) itemTypes.writeInItemTypeId = typeId;
      else if (typeName === ITEM_TYPES.LINE_ITEM) itemTypes.lineItemTypeId = typeId;
      else if (typeName === ITEM_TYPES.PART) itemTypes.partItemTypeId = typeId;
      else if (typeName === ITEM_TYPES.ASSEMBLY) itemTypes.assemblyItemTypeId = typeId;
    });

    return itemTypes;
  }, [workRequestItemType]);

  return {
    ...itemTypes,
  };
};

export default useWorkRequestItemTypesIds;
