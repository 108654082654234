import { useEffect, useState } from 'react';

import { gql, useLazyQuery } from '@apollo/client';

import { billOfMaterialLineItemByLBS as GET_LBS } from 'graphql/queries';
import { BillOfMaterialLineItemByLBS } from 'graphql/types';

type FilterType = 'Work Phase' | 'Both' | 'Location' | '' | null;

export type LBSDataType =
  | (BillOfMaterialLineItemByLBS & {
      workPhases: BillOfMaterialLineItemByLBS[];
    })
  | null;

interface IUseItemByLBS {
  BOMData: {
    id: string;
  };
  filterType?: FilterType;
  parentLocationId?: string;
  baseERN: string;
}

type UseItemByLBSType = {
  LBSData: LBSDataType;
  loadingLineItem: boolean;
  filterType: FilterType;
};

export default ({ BOMData, filterType = '', parentLocationId, baseERN = '' }: IUseItemByLBS): UseItemByLBSType => {
  const [LBSData, setLBSData] = useState<LBSDataType>(null);
  const [getBOMLineItemByLbs, { loading: loadingLineItem }] = useLazyQuery(gql(GET_LBS), {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    const getData = async () => {
      setLBSData(null);
      const ern = `${baseERN}:${BOMData?.id}`;
      const {
        data: { billOfMaterialLineItemByLBS },
      } = await getBOMLineItemByLbs({
        variables: {
          query: {
            ern,
            filterType,
            locationId: parentLocationId,
          },
        },
      });
      const { amount, children }: BillOfMaterialLineItemByLBS = billOfMaterialLineItemByLBS;
      setLBSData({
        amount,
        children: children
          ?.filter((child) => child?.locationId)
          .map((child) => ({ ...child, lineItemId: BOMData?.id })),
        workPhases: children?.filter((child) => child?.workPhaseId),
      } as any);
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    LBSData,
    loadingLineItem,
    filterType,
  };
};
