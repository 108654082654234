import { useCallback } from 'react';

import { gql, useLazyQuery, useMutation } from '@apollo/client';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { addWorkOrderDocument as ADD_WORK_ORDER_DOCUMENT } from 'graphql/mutations';
import {
  workRequestDocuments as WORK_REQUEST_DOCUMENTS,
  documents as DOCUMENTS,
  workOrders as WORK_ORDERS,
  taskDocument as TASK_DOCUMENTS,
  workOrderDocuments as WORK_ORDER_DOCUMENTS,
} from 'graphql/queries';

const getRefetchQueries = ({ workOrderId, documentIds, selectedItem }: any) => [
  {
    query: gql(DOCUMENTS),
    variables: {
      query: {
        documentIds,
      },
    },
  },
  {
    query: gql(TASK_DOCUMENTS),
    variables: {
      query: {
        taskDocumentId: documentIds,
      },
    },
  },
  {
    query: gql(WORK_ORDERS),
    variables: {
      query: {
        facilityId: selectedItem?.id,
      },
    },
  },
  {
    query: gql(WORK_ORDER_DOCUMENTS),
    variables: {
      query: {
        workOrderId,
      },
    },
  },
];

const updateCache = (cache: any) => {
  cache.modify({
    id: 'ROOT_QUERY',
    fields: {
      workOrders(_: any, { DELETE }: any) {
        return DELETE;
      },
      taskDocument(_: any, { DELETE }: any) {
        return DELETE;
      },
      documents(_: any, { DELETE }: any) {
        return DELETE;
      },
      workOrderDocuments(_: any, { DELETE }: any) {
        return DELETE;
      },
    },
  });
  cache.gc();
};

const useWorkOrderDocumentAPI = () => {
  const { selectedItem } = useFacilitiesProjects();
  const [addWorkOrderDocuments, { loading: addWorkOrderDocumentLoading }] = useMutation(gql(ADD_WORK_ORDER_DOCUMENT));

  const [fetchWorkOrderDocumentsAPI, { data, loading: fetchWorkOrderDocumentsLoading }] = useLazyQuery(
    gql(WORK_REQUEST_DOCUMENTS),
    { fetchPolicy: 'cache-and-network' },
  );

  const [fetchDocuments] = useLazyQuery(gql(DOCUMENTS));

  const fetchWorkOrderDocuments = useCallback(
    async (workOrderId: string) => {
      await fetchWorkOrderDocumentsAPI({
        variables: {
          query: {
            workOrderId,
          },
        },
      });
    },
    [fetchWorkOrderDocumentsAPI],
  );

  const addDocumentsToWorkOrder = useCallback(
    async ({
      workOrderId,
      documentIds,
      externalUrl,
    }: {
      workOrderId: string;
      documentIds: string;
      externalUrl: string;
    }) =>
      addWorkOrderDocuments({
        variables: {
          body: {
            workOrderId,
            documentIds,
            externalUrl,
          },
        },
        refetchQueries: getRefetchQueries({ documentIds, selectedItem }),
        update(cache) {
          updateCache(cache);
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addWorkOrderDocuments],
  );

  const fetchDocumentsByDocumentIds = useCallback(
    async (documentIds: string) => {
      const documents = await fetchDocuments({
        variables: {
          query: {
            documentIds,
          },
        },
      });
      return documents;
    },

    [fetchDocuments],
  );

  return {
    fetchWorkOrderDocumentsResp: data?.workOrderDocuments,
    fetchWorkOrderDocumentsLoading,
    addWorkOrderDocumentLoading,
    callbacks: {
      fetchWorkOrderDocuments,
      fetchDocumentsByDocumentIds,
      addDocumentsToWorkOrder,
    },
  };
};

export default useWorkOrderDocumentAPI;
