import React, { ReactElement } from 'react';

import { Box, Stack, Typography } from '@mui/material';

import { surfaceColors } from 'styles/theme/colors';

type NoRowsOverlayProps = { children: ReactElement };
type NoRowsOverlayIconProps = { icon: ReactElement };
type NoRowsOverlayTextProps = { children: string };

const NoRowsOverlay = ({ children }: NoRowsOverlayProps) => (
  <Stack sx={{ width: '100%', height: '100%', marginTop: '25vh' }}>
    <Box sx={{ textAlign: 'center', width: '100%', height: '100%' }}>{children}</Box>
  </Stack>
);

const NoRowsOverlayIcon = ({ icon }: NoRowsOverlayIconProps) => (
  <Box sx={{ '& svg': { height: '46px', width: '46px', path: { fill: '#BDBDBD' } } }}>{icon}</Box>
);

const NoRowsOverlayText = ({ children }: NoRowsOverlayTextProps) => (
  <Typography variant="h6" color={surfaceColors.lightSurface.secondary}>
    {children}
  </Typography>
);

NoRowsOverlay.Icon = NoRowsOverlayIcon;
NoRowsOverlay.Text = NoRowsOverlayText;

export default NoRowsOverlay;
