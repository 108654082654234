import React, { useState } from 'react';

import { useMutation, gql } from '@apollo/client';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';

import MenuItemWithSpinner from 'components/TableComponents/MoreOptionsDropdown/MenuItemWithSpinner';
import { resendProjectUserInvitation as RESEND_PROJECT_INVITE } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

import ProjectMembersDeleteModal from './ProjectMembersDeleteModal';

const MoreTableRowOptions = ({ member, onClose }) => {
  const memberIsPending = !member.isDeleted && member.inviteStatus === 'Pending';
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { handleResponse } = useGraphqlResponseHandler(onClose);

  const [resendInvitation, { loading }] = useMutation(gql(RESEND_PROJECT_INVITE));

  const resendUserInvite = () => {
    handleResponse(resendInvitation, {
      variables: {
        params: { projectUserId: member.projectUserId },
      },
    });
  };

  return (
    <Box>
      {memberIsPending && (
        <MenuItemWithSpinner loading={loading} onClick={() => resendUserInvite()}>
          Resend Invite
        </MenuItemWithSpinner>
      )}
      <MenuItem disabled={member.isDeleted} onClick={() => setShowDeleteModal(true)}>
        Delete
      </MenuItem>{' '}
      <ProjectMembersDeleteModal
        projectUserIds={[member.projectUserId]}
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
      />
    </Box>
  );
};

export default MoreTableRowOptions;
