import React from 'react';

import { useTheme, Button } from '@mui/material';

import { Custom } from 'components/Icons';

type propTypes = {
  showUrlInput: boolean;
  handleClick: () => void;
  children: React.ReactNode;
};
export const AttachButtonWrap = ({ showUrlInput, handleClick, children }: propTypes) => {
  const { palette } = useTheme();
  return (
    <Button
      startIcon={
        <Custom.AttachFile
          style={{
            fill: showUrlInput ? palette.primary.containedHoverBackground : palette.secondary.mainDarkBackground,
          }}
          width="20"
          height="18"
        />
      }
      onClick={handleClick}
      sx={{ fontWeight: '400', color: palette.secondary.mainDarkBackground, textTransform: 'none' }}
      variant="text"
      disabled={showUrlInput}
    >
      {children}
    </Button>
  );
};
