import { useCallback, useState, useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';

const checkHasMore = (res) => {
  const paginatedResults = Object.values(res)[0];
  const resultsArray = Object.values(paginatedResults)[0];

  if (resultsArray) return 0;

  return Object.values(paginatedResults)[0]?.length;
};

const take = 10;

const useLazySearchableQuery = (QUERY, options = {}) => {
  const { extraVars, fetchPolicy, onCompleted } = options;
  const [hasMore, setHasMore] = useState(true);
  const [searchPhrase, setSearchPhrase] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [fetchData, queryResults] = useLazyQuery(QUERY, {
    fetchPolicy,
    onCompleted,
  });
  const { fetchMore, refetch } = queryResults;

  useEffect(() => {
    setHasMore(true);
    refetch({
      query: { skip: 0, take, searchPhrase, orderBy, ...extraVars },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPhrase, orderBy]);

  const searchHandler = useCallback((value) => {
    const encodedSearchPhrase = encodeURIComponent(value.trim());
    setSearchPhrase(encodedSearchPhrase);
  }, []);

  const sortHandler = useCallback((value) => {
    const valueWithoutSpaces = value.split(' ').join('');
    setOrderBy(valueWithoutSpaces.toLowerCase());
  }, []);

  const paginationHandler = useCallback(
    async (skip) => {
      if (!hasMore) return;
      const res = await fetchMore({
        variables: {
          query: { skip, take, searchPhrase, orderBy, ...extraVars },
        },
      });

      if (!res) return;

      if (checkHasMore(res)) setHasMore(false);
    },
    [extraVars, fetchMore, hasMore, orderBy, searchPhrase],
  );

  const lazyLoad = useCallback(
    async (useRefetch) => {
      setHasMore(true);
      if (useRefetch) await refetch({ query: { skip: 0, take, searchPhrase, orderBy, ...extraVars } });
      else
        await fetchData({
          variables: { query: { skip: 0, take, searchPhrase, orderBy, ...extraVars } },
        });
    },
    [extraVars, fetchData, orderBy, refetch, searchPhrase],
  );

  return [{ lazyLoad, paginationHandler, searchHandler, sortHandler }, queryResults];
};

export default useLazySearchableQuery;
