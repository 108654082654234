import React from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { IPropertiesOverlayDetailItemProps } from './@types/PropertiesOverlayTypes';

const PropertiesOverlayDetailItem: React.FC<IPropertiesOverlayDetailItemProps> = ({
  title,
  value,
}) => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="flex-start"
    gap="8px"
    sx={{
      py: '8px',
      borderBottom: '1px solid rgba(204, 204, 204, 1)',
      '&:last-child': {
        border: 'none',
      },
    }}
  >
    <Typography variant="body2" sx={{ color: '#fff' }}>
      {title}
    </Typography>

    <Typography variant="body2" sx={{ color: '#fff' }}>
      {value}
    </Typography>
  </Stack>
);

export default PropertiesOverlayDetailItem;
