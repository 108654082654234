import { forwardRef } from 'react';

import { Box, List, ListSubheader, Paper, Stack, SxProps, Typography } from '@mui/material';

import { squareStyles } from '../../helpers/squareStyles';
import { ContentListWorkOrders } from './ContentListWorkOrders';
import { HeaderListDetailProjects } from './HeaderListDetailProjects';
import { stylesBoxCircular } from './styles';
import { ContentDetailProjectsProps } from './types/ProjectDetailWorkOrderTypes';

export const ContentDetailProjects = forwardRef<HTMLDivElement, ContentDetailProjectsProps>(
  (
    {
      getProjectNameWithKey,
      getProjectIdWithKey,
      projects,
      projectsColors,
      countWorkOrder,
      hiddenHeader,
      modeOnlyProjects,
      day,
    },
    ref,
  ) => (
    <Paper ref={ref} sx={{ width: 300 }}>
      {!hiddenHeader && <HeaderListDetailProjects countWorkOrder={countWorkOrder} day={day} />}
      <Stack overflow="auto">
        {Object.entries(projects).map(([project, workOrders]) => (
          <List
            dense
            sx={modeOnlyProjects ? { paddingBottom: 0 } : {}}
            key={`${project}-list-project`}
            subheader={
              <ListSubheader
                disableSticky
                sx={
                  {
                    backgroundColor: 'grey.100',
                    width: '100%',
                    color: 'common.black',
                    position: 'relative',
                    '&.MuiListSubheader-root:before': {
                      ...squareStyles(projectsColors.get(getProjectIdWithKey(project))),
                    },
                    ...(modeOnlyProjects && { backgroundColor: 'none' }),
                  } as SxProps
                }
              >
                {getProjectNameWithKey(project)}
                <Box
                  sx={{
                    ...stylesBoxCircular,
                    color: 'grey.800',
                    bottom: '30%',
                    ...(modeOnlyProjects && { backgroundColor: 'grey.50' }),
                  }}
                >
                  {workOrders.length}
                </Box>
              </ListSubheader>
            }
          >
            {!modeOnlyProjects && (
              <>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography ml={1} fontWeight="600">
                    Work Orders:
                  </Typography>
                  <Box
                    sx={{
                      ...stylesBoxCircular,
                      backgroundColor: 'grey.50',
                      position: 'inherit',
                      color: 'grey.800',
                      marginRight: '10px',
                      bottom: '30%',
                    }}
                  >
                    {workOrders.length}
                  </Box>
                </Stack>

                <ContentListWorkOrders workOrders={workOrders} />
              </>
            )}
          </List>
        ))}
      </Stack>
    </Paper>
  ),
);
