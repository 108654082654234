import { useEffect, useState } from 'react';

import { gql, useQuery } from '@apollo/client';

import { workRequestsStatusType as WORK_REQUESTS_STATUS_TYPE } from 'graphql/queries';

const useFetchWorkRequestsStatusType = () => {
  const {
    data,
    loading,
    refetch: refetchWorkRequestStatus,
  } = useQuery(gql(WORK_REQUESTS_STATUS_TYPE));
  const [workRequestsStatus, setWorkRequestsStatus] = useState([]);

  useEffect(() => {
    if (data?.workRequestsStatusType)
      setWorkRequestsStatus(
        data.workRequestsStatusType.map((status) => ({
          value: status.workRequestStatusTypeId,
          label: status.workRequestStatusTypeName,
        })),
      );
  }, [data]);

  return { loading, workRequestsStatus, refetchWorkRequestStatus };
};

export default useFetchWorkRequestsStatusType;
