import React from 'react';

import { Divider, Stack } from '@mui/material';

const FilterPopoverFooter = ({ enableButtons = false, render = () => {} }) => (
  <Stack sx={{ margin: '16px -24px -16px -24px' }}>
    <Divider variant="fullWidth" />

    <Stack spacing={2} direction="row" justifyContent="right" sx={{ padding: '8px' }}>
      {render(enableButtons)}
    </Stack>
  </Stack>
);

export default FilterPopoverFooter;
