import { useCallback, useEffect, useMemo } from 'react';

import { gql, useLazyQuery } from '@apollo/client';

import { taskMultiSearch as TASKS_MULTI_SEARCH } from 'graphql/queries';
import useCache from 'hooks/useCache';

type EventType = {
  taskStatusTypeId?: string;
  description?: string;
  timesStamp?: string;
  userId?: string;
};

type TaskPredecessorType = {
  taskId?: string;
  taskName?: string;
  taskStatusTypeId?: string;
};

type TaskType = {
  taskId: string;
  taskTypeId?: string;
  billOfProcessId?: string;
  workRequestItemId?: string;
  taskStatusTypeId?: string;
  taskPredecessorIds?: TaskPredecessorType[];
  taskName?: string;
  taskDescription?: string;
  time?: {
    totalTime?: string;
    events?: EventType[];
  };
  taskStatusTypeName?: string;
  isEnabled?: boolean;
  hasDocuments?: boolean;
  createdOn?: string;
  isSaveData?: boolean;
  quantity?: number;
  needBy?: string;
};

type TasksMultiSearchParams = {
  workRequestItemId?: string;
  billOfProcessId?: string;
  orderBy?: string;
  taskStatusTypeIds?: string;
  workCellId?: string;
  shopConfigurationId?: string;
  workRequestId?: string;
  skip?: number;
  take?: number;
};

type UseTasksMultiSearchAPIType = {
  tasks: TaskType[];
  loadingTasks: boolean;
  refetchTasks: () => void;
};

const useTasksMultiSearchAPI = (query: TasksMultiSearchParams): UseTasksMultiSearchAPIType => {
  const { deleteCache } = useCache();

  const [fetchTaskMultiSearch, { data, loading: loadingTasks, refetch: refetchTasks }] = useLazyQuery(
    gql(TASKS_MULTI_SEARCH),
    { fetchPolicy: 'cache-and-network' },
  );

  const fetchTasks = useCallback(async () => {
    await deleteCache('taskMultiSearch', {
      query,
    });
    await fetchTaskMultiSearch({
      variables: { query },
    });
  }, [fetchTaskMultiSearch, query, deleteCache]);

  useEffect(() => {
    fetchTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const tasks = useMemo(() => data?.taskMultiSearch || [], [data]);

  return {
    tasks,
    loadingTasks,
    refetchTasks,
  };
};

export default useTasksMultiSearchAPI;
