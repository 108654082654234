import { InMemoryCache } from '@apollo/client';

import { TODAY, getDaysBetween, getParsedDate } from 'helpers/dateFunctions';

import QueryPolicies from './QueryPolicies';

const cache = new InMemoryCache({
  typePolicies: {
    Query: QueryPolicies,
    PartCatalog: {
      keyFields: ['partCatalogId'],
    },
    Address: {
      keyFields: ['addressId'],
    },
    PartCategory: {
      keyFields: ['partCategoryId', 'subNodes'],
    },
    PartAttribute: {
      keyFields: ['partAttributeId'],
    },
    PartAttributeType: {
      keyFields: ['partAttributeTypeId'],
    },
    NavigationModule: {
      keyFields: ['navigationModuleId'],
    },
    NavigationSet: {
      keyFields: ['navigationSetId'],
    },
    NavigationItem: {
      keyFields: ['navigationItemId'],
    },
    DepartmentType: {
      keyFields: ['departmentTypeId'],
    },
    Department: {
      keyFields: ['departmentId'],
    },
    Entitlement: {
      keyFields: ['entitlementId'],
    },
    Facility: {
      keyFields: ['facilityId'],
    },
    ProductPool: {
      keyFields: ['productPoolId'],
    },
    Product: {
      keyFields: ['productId'],
    },
    Project: {
      keyFields: ['projectId'],
    },
    ProjectType: {
      keyFields: ['projectTypeId'],
    },
    ProjectUser: {
      keyFields: ['projectUserId'],
    },
    TaskType: {
      keyFields: ['taskTypeId'],
    },
    User: {
      keyFields: ['userId'],
    },
    Company: {
      keyFields: ['companyId'],
    },
    Message: {
      keyFields: ['messageId', 'body'],
    },
    Location: {
      keyFields: ['locationId'],
    },
    PartCloudSearchResult: {
      keyFields: ['partHistoryRefId'],
    },
    TakeOffPad: {
      keyFields: ['takeoffPadId'],
    },
    SourcePad: {
      keyFields: ['lineItemId'],
    },
    TaskStatusTypes: {
      keyFields: ['taskStatusTypes'],
    },
    Task: {
      keyFields: ['taskId'],
    },
    Document: {
      keyFields: ['documentId'],
    },
    Folder: {
      keyFields: ['folderId'],
    },
    WorkOrder: {
      keyFields: ['workOrderId'],
    },
    WorkRequest: {
      keyFields: ['workRequestId'],
      fields: {
        needByDateFormatted: {
          read(_, { readField }) {
            const needBy = readField('needBy');
            if (!needBy) return '';
            return getParsedDate(needBy);
          },
        },
        daysRemaining: {
          read(_, { readField }) {
            const needBy = readField('needBy');
            if (!needBy) return '';
            return getDaysBetween(new Date(needBy), new Date(TODAY));
          },
        },
      },
    },
    WorkRequestItem: {
      keyFields: ['workRequestItemId'],
    },
    BillOfMaterialItem: {
      keyFields: ['lineItemId'],
    },
  },
});

export default cache;
