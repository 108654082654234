import React from 'react';

import Typography from '@mui/material/Typography';

const QuantityCell = ({
  value,
  onClick,
  isEditable = false,
}: {
  value: number;
  isEditable: boolean;
  onClick: () => void;
}) => (
  <Typography
    onClick={onClick}
    variant="body2"
    sx={{
      color: isEditable ? 'surface.lightSurface.primary' : 'surface.lightSurface.secondary',
      fontWeight: 400,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      width: '100%',
      cursor: 'pointer',
      textAlign: 'right',
      paddingRight: '12px',
      height: '24px',
      lineHeight: '26px',
      '&:hover': isEditable
        ? {
            borderRadius: '4px',
            WebkitBoxShadow: 'inset 0px 0px 2px 0px rgba(0,0,0,0.75)',
            MozBoxShadow: 'inset 0px 0px 2px 0px rgba(0,0,0,0.75)',
            boxShadow: 'inset 0px 0px 2px 0px rgba(0,0,0,0.75)',
          }
        : {},
    }}
  >
    {value}
  </Typography>
);

export default QuantityCell;
