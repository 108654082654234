import React, { useEffect } from 'react';

import { Box } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-pro';

import { ItemsDataGridProProvider } from 'components/ItemsDatagridPro';
import ItemsDataGridPro from 'components/ItemsDatagridPro/ItemsDatagridPro';

import WorkOrderTasksColumns from './WorkOrderTasksColumns';

const mapTasksDataToTableColumns = (task) => ({
  ...task,
  workCellName: task.workCellName,
  saveData: null,
  discardData: null,
});

const mapRowsToSaveDataTasks = (row) => ({
  taskId: row.taskId,
  isSaveData: row.saveData,
});

const WorkOrderTasksTable = ({ tasksData, setIsTableFilled, setSaveTaskData, loadingTasksData }) => {
  const apiRef = useGridApiRef();
  const rowTasks = tasksData?.map(mapTasksDataToTableColumns);

  useEffect(() => {
    setIsTableFilled(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllRows = () => {
    const rowIds = apiRef.current.getAllRowIds();

    return rowIds.map((id) => apiRef.current.getRow(id));
  };

  const isTableFilled = (allRows) => !allRows.some((row) => row.saveData === null || row.discardData === null);

  const onChangeRadioButtons = (id, field) => {
    apiRef.current.updateRows([{ taskId: id, saveData: field === 'saveData', discardData: field === 'discardData' }]);

    const allRows = getAllRows();
    const isFilled = isTableFilled(allRows);

    if (isFilled) {
      const saveTask = allRows?.map(mapRowsToSaveDataTasks);
      setIsTableFilled(true);
      setSaveTaskData(saveTask);
    }
  };

  const autoHeight = rowTasks.length < 4;

  return (
    <Box sx={{ mt: '14px', mb: '14px', height: autoHeight ? 'auto' : '161px' }}>
      <ItemsDataGridProProvider contextId="WorkOrderTasksTable">
        <ItemsDataGridPro
          rowHeight={32}
          headerHeight={32}
          apiRef={apiRef}
          loading={loadingTasksData}
          loadIcon
          hideFooterPagination
          checkboxSelection={false}
          disableColumnMenu
          columns={WorkOrderTasksColumns(onChangeRadioButtons)}
          getRowId={(row) => row?.taskId}
          rows={loadingTasksData ? [] : rowTasks}
          autoHeight={autoHeight}
          sx={{
            '& .MuiDataGrid-columnHeader:nth-of-type(1)': {
              paddingLeft: '16px',
            },
            '& .MuiDataGrid-cell:nth-of-type(1)': {
              paddingLeft: '16px',
            },
          }}
        />
      </ItemsDataGridProProvider>
    </Box>
  );
};
export default React.memo(WorkOrderTasksTable);
