import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { ThemedCheckBox } from 'components';
import ActionsDropdown from 'components/TableComponents/ActionsDropdown/ActionsDropdown';
import Search from 'components/TableComponents/Search';

import ProjectMembersActions from './ProjectMembersActions';
import { useProjectMembersContext } from './ProjectMembersContext';

const ProjectMembersHeader = ({ openInviteModal }) => {
  const { projectId, selected, searchHandler, setShowDeletedUsers } = useProjectMembersContext();

  return (
    <HeaderWrapper>
      <Typography variant="h5" gutterBottom>
        Project Members
      </Typography>

      <Grid container justifyContent="space-between" wrap="nowrap">
        <Grid container item alignItems="center" wrap="nowrap">
          <ItemsSelected component="p">{selected.length} Items Selected</ItemsSelected>
          <ActionsDropdown
            menuItemsRenderer={() => <ProjectMembersActions selectedMembers={{ selected }} />}
          />
        </Grid>

        <Grid container item alignItems="center" wrap="nowrap" justifyContent="flex-end">
          <ThemedCheckBox onChange={(e) => setShowDeletedUsers(e.target.checked)} />
          <Typography component="p" noWrap>
            Show Deleted Users
          </Typography>
          <Search handleSearch={searchHandler} placeholder="Search…" />
          <InviteButton onClick={openInviteModal} variant="contained" disabled={projectId == null}>
            + Invite Users
          </InviteButton>
        </Grid>
      </Grid>
    </HeaderWrapper>
  );
};

export default ProjectMembersHeader;

const HeaderWrapper = (props) => <Box sx={{ marginBottom: '20px' }} {...props} />;

const ItemsSelected = (props) => <Typography sx={{ color: 'rgba(0, 0, 0, 0.5)' }} {...props} />;

const InviteButton = (props) => (
  <Button
    sx={{
      minWidth: 150,
      maxHeight: 32,
      backgroundColor: 'secondary.main',
      fontSize: 14,
      borderRadius: '4px',
      color: 'secondary.contrastText',
      marginLeft: '16px',
      '&:hover': {
        backgroundColor: 'secondary.buttonHover',
      },
    }}
    {...props}
  />
);
