import { useRef, useState, useMemo, useCallback } from 'react';

import { withCalendarSlotBase, SlotBaseYear, PropsBase } from '@evolvemep/foresite-components-ui-react';
import { Popover, useTheme } from '@mui/material';

import type { WorkOrder } from 'typings/work-order';

import { changeColorOpacity, specialColors } from '../../helpers/assignedProjectColor';
import { useGroupByProject } from '../../Hooks/useGroupByProject';
import { ContentDetailProjects } from '../ProjectDetailWorkOrder';

const boxShadow =
  '0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12)';

export const SlotYearWorkOrder = withCalendarSlotBase<
  WorkOrder,
  PropsBase & { projectsColors: Map<string, string> }
>((props) => {
  const { day, resourceList, projectsColors, ...rest } = props;

  const theme = useTheme();

  const [autoClosePopper, setAutoClosePopper] = useState(true);
  const [forceClose, setForceClose] = useState<undefined | boolean>(undefined);

  const refPaper = useRef<HTMLDivElement>(null);
  const { projects, getProjectNameWithKey, getProjectIdWithKey } = useGroupByProject(resourceList);

  const handleClosePopover = () => {
    setAutoClosePopper(true);
    setForceClose(true);
    setTimeout(() => setForceClose(undefined), 0);
  };

  const projectsEntries = useMemo(() => Object.entries(projects), [projects]);

  const getColorCircle = useCallback(() => {
    const colorSelected = projectsColors.get(getProjectIdWithKey(projectsEntries?.[0]?.[0]));
    return props.isSlotSelected ? changeColorOpacity(colorSelected as string) : colorSelected;
  }, [getProjectIdWithKey, projectsColors, projectsEntries, props.isSlotSelected]);

  const colorCircleYear = projectsEntries.length === 1 && getColorCircle();
  const open = !autoClosePopper && Boolean(refPaper.current);

  return (
    <SlotBaseYear
      hiddenButtonClose
      forceClose={forceClose}
      autoClosePopper={autoClosePopper}
      onClickDay={() => setAutoClosePopper(false)}
      {...(projectsEntries.length && {
        backgroundColorHighlight: colorCircleYear || specialColors.moreThanOne,
        backgroundColorHighlightHover: colorCircleYear || specialColors.moreThanOne,
        colorHighlight: theme.palette.getContrastText(colorCircleYear || specialColors.moreThanOne),
      })}
      popperProps={{
        sx: {
          boxShadow,
        },
      }}
      day={day}
      resourceList={resourceList}
      {...rest}
    >
      <ContentDetailProjects
        modeOnlyProjects
        ref={refPaper}
        {...{
          day,
          getProjectNameWithKey,
          getProjectIdWithKey,
          projectsColors,
          projects,
          countWorkOrder: resourceList.length,
        }}
      />
      <Popover
        open={open}
        anchorEl={refPaper.current}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ContentDetailProjects
          {...{
            countWorkOrder: 3,
            day,
            getProjectNameWithKey,
            getProjectIdWithKey,
            projectsColors,
            projects,
            hiddenHeader: true,
          }}
        />
      </Popover>
    </SlotBaseYear>
  );
});
