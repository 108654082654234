import React, { forwardRef, useImperativeHandle, useRef } from 'react';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import Popper from '@mui/material/Popper';
import { usePopupState, bindToggle, bindPopper } from 'material-ui-popup-state/hooks';

import { useModule } from 'app/Modules/ModuleContext';
import { Custom } from 'components/Icons';

import ModuleSelectContent from './ModuleSelectDropdown';

const ModuleSelect = () => {
  const triggerRef = useRef(null);
  const { popperRef, rightSideFlyoutRef } = useModule();
  const popupState = usePopupState({
    variant: 'popper',
    popupId: 'demoPopper',
  });

  useImperativeHandle(
    popperRef,
    () => ({
      open: () => popupState.open(triggerRef.current),
      toggle: () => popupState.toggle(triggerRef.current),
      close: popupState.close,
      isOpen: popupState.isOpen,
    }),
    [popupState],
  );

  const handleClose = (event) => {
    if (triggerRef.current && triggerRef.current.contains(event.target)) {
      return;
    }

    popupState.close();
  };

  return (
    <>
      <SelectModulesButton
        {...bindToggle(popupState)}
        onClick={(e) => {
          rightSideFlyoutRef.current?.close();
          bindToggle(popupState).onClick(e);
        }}
        ref={triggerRef}
      />
      <ClickAwayListener onClickAway={handleClose}>
        <Popper {...bindPopper(popupState)} transition placement="bottom-end">
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} style={{ transformOrigin: 'top right' }}>
              <ModuleSelectContent />
            </Grow>
          )}
        </Popper>
      </ClickAwayListener>
    </>
  );
};

export default ModuleSelect;

const SelectModulesButton = forwardRef((props, ref) => (
  <IconButton
    {...props}
    data-cy="select-module-button"
    aria-haspopup="true"
    ref={ref}
    data-testid="module-select-button"
    sx={{
      cursor: 'pointer',

      '&:hover': {
        svg: {
          color: 'white',
        },
      },
    }}
  >
    <Custom.Apps />
  </IconButton>
));
