export const getArrayOfParts = (string = '', keyword = '') => {
  const queryResults = (string ?? '')
    .trim()
    .split(' ')
    .filter((word) => word !== '')
    .map((word, index) => ({
      text: `${word} `,
      id: word + index + (string ?? '') + keyword,
    }));
  const keywords = keyword
    .trim()
    .split(' ')
    .filter((word) => word !== '');
  // eslint-disable-next-line prefer-const
  let result = queryResults;
  keywords.forEach((word, index) => {
    for (let wordIndex = 0; wordIndex < result.length; wordIndex += 1) {
      const queryWord = queryResults[wordIndex];
      const { text } = queryWord;
      const firstIndex = text.toLowerCase().indexOf(word.toLowerCase());
      if (firstIndex !== -1) {
        const lastIndex = word.length + firstIndex;
        const firstPart = text.substring(0, firstIndex);
        const highlighted = text.substring(firstIndex, lastIndex);
        const rest = text.substring(lastIndex);

        result[wordIndex] = {
          text: firstPart,
          id: `${firstPart} ${word} ${index} ${wordIndex} ${string ?? ''} ${keyword} first part`,
        };
        result.splice(wordIndex + 1, 0, {
          text: highlighted,
          isHighlight: true,
          id: `${highlighted} ${word} ${index} ${wordIndex} ${string ?? ''} ${keyword} highlight`,
        });
        result.splice(wordIndex + 2, 0, {
          text: `${rest} `,
          id: `${rest} ${word} ${index} ${wordIndex} ${string ?? ''} ${keyword} rest`,
        });
        wordIndex += 2;
      }
    }
  });
  return result;
};
