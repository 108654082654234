import { gql, useMutation } from '@apollo/client';

import { addShopConfiguration as ADD_SHOP_CONFIGURATION } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

const useCreateShopConfiguration = () => {
  const { handleResponse } = useGraphqlResponseHandler();
  const [addShopConfiguration, { loading }] = useMutation(gql(ADD_SHOP_CONFIGURATION), {
    // refetchQueries: ['shopConfigurations']
  });

  const createShopConfiguration = async (body, successCallback) => {
    await handleResponse(addShopConfiguration, { variables: { body } }, {}, successCallback);
  };

  return { loading, addShopConfiguration, createShopConfiguration };
};

export default useCreateShopConfiguration;
