const CreateScopePackageArray = (workPhaseArray, scopePckArray) => {
  const scopePackages = scopePckArray.map((e) => ({ ...e, workPhases: [] }));
  workPhaseArray.forEach((workPhase) => {
    const auxWorkPhase = {
      ...workPhase,
      isGroupSelected: false,
      workPhaseChipName: `${workPhase.scopePackageName}/ ${workPhase.workPhaseName}`,
    };
    const scopePackageItem = scopePackages.find(
      (scopePck) => scopePck.scopePackageId === workPhase.scopePackageId,
    );
    if (scopePackageItem) scopePackageItem.workPhases.push(auxWorkPhase);
  });
  return scopePackages;
};

export default CreateScopePackageArray;
