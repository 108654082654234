import { useCallback } from 'react';

const useBOMTableAPI = ({ api }) => {
  const highlightRow = useCallback(
    (rowId = 1, hasError = false) => {
      if (!api) return;

      const rowEl = api.getRowElement(rowId);
      if (!rowEl) return;

      if (hasError) {
        rowEl.style.height = '64px';
        rowEl.style.maxHeight = 'none';
        rowEl.style.verticalAlign = 'text-top';
      } else {
        rowEl.style.height = '32px';
        rowEl.style.maxHeight = '35px';
        rowEl.style.verticalAlign = 'middle';
      }
    },
    [api],
  );

  const highlightCell = useCallback(
    ({ rowId, field, hasError }) => {
      if (!api) return;

      const cellEl = api.getCellElement(rowId, field);
      if (!cellEl) return;

      if (hasError) {
        cellEl.style.maxHeight = 'none';
      } else {
        cellEl.style.maxHeight = '35px';
      }
    },
    [api],
  );

  return { highlightRow, highlightCell };
};

export default useBOMTableAPI;
