import React, { useState, useRef, forwardRef } from 'react';

import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import { Custom } from 'components/Icons';
import RoundedIconButton from 'components/RoundedIconButton';
import useBreakpoint from 'hooks/useBreakpoint';

import HelpDeskDropdownMenu from './HelpDeskDropdownMenu';
import HelpDeskSliderMenu from './Mobile/HelpDeskSliderMenu';

const activeModal = {
  EULA: 'eula',
};

const HelpDeskDropdown = () => {
  const anchorRef = useRef(null);
  const { isMobile } = useBreakpoint();
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

  const openDropdown = () => setDropdownIsOpen(true);
  const closeDropdown = () => setDropdownIsOpen(false);

  const openModal = () => {
    closeDropdown();
  };

  const closeAll = () => {
    closeDropdown();
  };

  return (
    <HelpDeskWrapper onClickAway={closeDropdown}>
      <HelpDeskButton ref={anchorRef} onClick={dropdownIsOpen ? closeDropdown : openDropdown} />

      {isMobile ? (
        <HelpDeskSliderMenu open={dropdownIsOpen} onClose={closeDropdown} />
      ) : (
        <HelpDeskDropdownMenu
          open={dropdownIsOpen}
          onClose={closeAll}
          anchorRef={anchorRef.current}
          openModal={openModal}
          activeModal={activeModal}
        />
      )}
    </HelpDeskWrapper>
  );
};

export default HelpDeskDropdown;

const HelpDeskButton = forwardRef(({ open, onClick }, ref) => (
  <RoundedIconButton
    ref={ref}
    aria-controls={open ? 'menu-list-grow' : undefined}
    aria-haspopup="true"
    onClick={onClick}
    color="inherit"
    size="small"
    sx={{ mr: 2 }}
  >
    <Custom.Help />
  </RoundedIconButton>
));

const HelpDeskWrapper = ({ onClickAway, children }) => (
  <ClickAwayListener onClickAway={onClickAway}>
    <Box>{children}</Box>
  </ClickAwayListener>
);
