import React, { ReactNode } from 'react';

import { Stack, Divider, useTheme } from '@mui/material';

type Props = {
  children: ReactNode;
};
export const InformativeRow = ({ children }: Props) => {
  const { palette } = useTheme();
  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        {children}
      </Stack>
      <Divider
        variant="middle"
        sx={{ margin: '8px 0 8px 0', borderColor: palette.primary.light }}
      />
    </>
  );
};
