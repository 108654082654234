import React, { forwardRef } from 'react';

import { Typography, Stack, Box } from '@mui/material';

import OverFlowTooltip from 'components/Tooltip/OverFlowTooltip';

const KanbanColumn = (
  { children, title = 'Work Cell', disabled = false, active = false, numberItems = 0 },
  ref,
) => (
  <KanbanColumnContainer ref={ref}>
    <KanbanColumnDisabledBox disabled={disabled} />
    <KanbanColumnHeaderContainer>
      <KanbanColumnTitle title={title} />
      <KanbanColumnTasksCount numberItems={numberItems} />
    </KanbanColumnHeaderContainer>
    {numberItems > 0 && (
      <KanbanColumnTasksContainer active={active}>{children}</KanbanColumnTasksContainer>
    )}
  </KanbanColumnContainer>
);

const KanbanColumnTasksContainer = ({ children, active = false }) => (
  <Box
    sx={{
      backgroundColor: 'greyColors.grey100',
      borderRadius: '4px',
      display: 'flex',
      flexDirection: 'column',
      padding: '8px',
      paddingTop: '16px',
      width: '196px',
      height: '100%',
      ...(active && {
        borderColor: 'greyColors.grey600',
        borderSize: '2px',
        borderStyle: 'solid',
      }),
    }}
  >
    {children}
  </Box>
);

const KanbanColumnContainer = forwardRef(({ children }, ref) => (
  <Box sx={{ position: 'relative', height: '100%' }} ref={ref}>
    {children}
  </Box>
));

const KanbanColumnDisabledBox = ({ disabled }) =>
  disabled && (
    <Box
      data-testid="kanban-column-disabled-box"
      sx={{
        position: 'absolute',
        height: '100%',
        width: '100%',
        backgroundColor: 'rgba(255,255,255,0.8)',
        zIndex: 1,
      }}
    />
  );

const KanbanColumnHeaderContainer = ({ children }) => (
  <Stack
    alignItems="center"
    direction="row"
    justifyContent="space-between"
    sx={{
      position: 'sticky',
      width: 196,
      backgroundColor: 'white',
      top: 0,
      paddingBottom: '4px',
      zIndex: 2,
    }}
  >
    {children}
  </Stack>
);

const KanbanColumnTasksCount = ({ numberItems = 0 }) => (
  <Box
    data-testid="kanban-column-tasks-number"
    sx={{
      backgroundColor: 'greyColors.grey100',
      height: '28px',
      width: '28px',
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Typography variant="body2">{numberItems}</Typography>
  </Box>
);

const KanbanColumnTitle = ({ title }) => (
  <OverFlowTooltip title={title}>
    <Typography
      variant="h6"
      sx={{
        fontSize: 16,
        display: 'inline',
      }}
    >
      {title}
    </Typography>
  </OverFlowTooltip>
);

export default forwardRef(KanbanColumn);
