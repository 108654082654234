import React from 'react';

import { Box, IconButton } from '@mui/material';

import { Custom } from 'components/Icons';

const CustomTrashComponent = ({ isHovered, handleOpenModal, onMouseEnter, onMouseLeave }: any) => (
  <Box
    sx={{
      display: isHovered ? 'block' : 'none',
      position: 'absolute',
      right: '1px',
      cursor: 'pointer',
    }}
    onClick={handleOpenModal}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    <IconButton>
      <Custom.Trash />
    </IconButton>
  </Box>
);

export default CustomTrashComponent;
