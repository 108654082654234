import React from 'react';

import { CircularProgress, Divider, Stack, Typography, useTheme } from '@mui/material';

import { Custom } from 'components/Icons';
import { POSITION_TITLE } from 'constants/globalConstants';
import { actionColors } from 'styles/theme/colors';

import { ROUNDED_BORDER_STYLE } from '../constants/constants';
import { useTaskViewerContext } from '../TaskViewerContext';
import TasksSection from './tasks/tasks';
import WorkOrdersSection from './workOrders/workOrders';
import WorkRequestSection from './workRequests/workRequests';

const TaskWorkOrderWorkRequest = () => {
  const {
    commonDocuments,
    workOrderDocuments,
    workRequestDocuments,
    loadingCommonDocuments,
    WOPaginationWaypoint,
    WRPaginationWaypoint,
    commonPaginationWaypoint,
  } = useTaskViewerContext();
  const { palette } = useTheme() as any;

  const conditions = [
    { documents: commonDocuments, title: POSITION_TITLE.tasks },
    { documents: workOrderDocuments, title: POSITION_TITLE.workOrders },
    { documents: workRequestDocuments, title: POSITION_TITLE.workRequests },
  ];

  const conditionMatch = conditions?.find((condition) => condition.documents?.length > 0);

  const positionTitle = conditionMatch ? conditionMatch.title : '';

  return (
    <Stack sx={{ ...ROUNDED_BORDER_STYLE, mt: 1, pl: 2, py: 1 }}>
      <>
        <Stack direction="row" alignItems="center" justifyContent="center" mt={2}>
          <Stack sx={{ '& path': { fill: palette?.surface?.lightSurface?.primary } }}>
            <Custom.TaskDetails style={{ marginRight: 10 }} />
          </Stack>
          <Typography variant="subtitle2">Tasks</Typography>
        </Stack>
        <Divider light sx={{ mr: 2, my: 1 }} />
        {loadingCommonDocuments && !commonDocuments?.length ? (
          <CircularProgress color="secondary" />
        ) : (
          <>
            <TasksSection documents={commonDocuments} positionTitle={positionTitle} section={POSITION_TITLE.tasks} />
            {commonPaginationWaypoint()}
          </>
        )}
      </>
      {Boolean(workOrderDocuments?.length) && (
        <>
          <Stack direction="row" alignItems="center" justifyContent="center" mt={2}>
            <Stack sx={{ '& path': { fill: palette?.surface?.lightSurface?.primary } }}>
              <Custom.WorkOrders style={{ marginRight: 10 }} fill={actionColors.disabled} />
            </Stack>
            <Typography variant="subtitle2">Work Orders</Typography>
          </Stack>
          <Divider light sx={{ mr: 2, my: 1 }} />
          <WorkOrdersSection
            documents={workOrderDocuments}
            positionTitle={positionTitle}
            section={POSITION_TITLE.workOrders}
          />
          {WOPaginationWaypoint()}
        </>
      )}
      {Boolean(workRequestDocuments?.length) && (
        <>
          <Stack direction="row" alignItems="center" justifyContent="center" mt={2}>
            <Stack sx={{ '& path': { fill: palette?.surface?.lightSurface?.primary } }}>
              <Custom.WorkRequests style={{ marginRight: 10 }} fill={actionColors.disabled} />
            </Stack>
            <Typography variant="subtitle2">Work Requests</Typography>
          </Stack>
          <Divider light sx={{ mr: 2, my: 1 }} />
          <WorkRequestSection
            documents={workRequestDocuments}
            positionTitle={positionTitle}
            section={POSITION_TITLE.workRequests}
          />
          {WRPaginationWaypoint()}
        </>
      )}
    </Stack>
  );
};

export default TaskWorkOrderWorkRequest;
