import React from 'react';

import { Button, Stack, useTheme } from '@mui/material';

const ModalFooter = ({ onSubmit, onCancel, disable }) => {
  const { palette } = useTheme();
  return (
    <Stack direction="row">
      <Button
        sx={{ marginRight: '8px', fontWeight: 'bold' }}
        onClick={() => onCancel(false)}
        data-cy="CancelButton"
        disabled={disable}
      >
        Cancel
      </Button>
      <Button
        sx={{
          fontWeight: 'bold',
          backgroundColor: disable ? palette.action.disabled : palette.secondary.main,
        }}
        data-cy="CreateButton"
        variant="contained"
        color="secondary"
        onClick={onSubmit}
        disabled={disable}
      >
        Create
      </Button>
    </Stack>
  );
};

export default ModalFooter;
