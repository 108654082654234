import React from 'react';

import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';

import { TabBar } from 'components';
import StackRow from 'components/StackRow';
import { replaceSpaceToDash } from 'helpers/stringFunctions';

import CatalogSelector from './CatalogSelector';

const CatalogSetupHeader = ({ tabs, activeTab, onTabClick }) => {
  const renderedTabs = tabs.map(({ label }) => (
    <Tab data-testid={`catalog-setup-tab-${replaceSpaceToDash(label)}`} key={label} label={label} />
  ));

  return (
    <SpaceBetweenContainer>
      <LeftAligned>
        <CatalogTitle />
        <TabBar value={activeTab} onChange={onTabClick}>
          {renderedTabs}
        </TabBar>
      </LeftAligned>
      <RightAligned>
        <CatalogSelector />
      </RightAligned>
    </SpaceBetweenContainer>
  );
};

export default CatalogSetupHeader;

const CatalogTitle = () => (
  <Typography
    variant="h5"
    component="h1"
    sx={{
      marginTop: '4px',
      textAlign: { xs: 'left', lg: 'center ' },
    }}
  >
    Catalog Setup
  </Typography>
);

const LeftAligned = (props) => <StackRow {...props} />;

const SpaceBetweenContainer = (props) => (
  <Stack
    direction="row"
    sx={{
      justifyContent: 'space-between',
      marginBottom: '16px',
    }}
    {...props}
  />
);

const RightAligned = (props) => <StackRow {...props} />;
