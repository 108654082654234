import React, { useState } from 'react';

import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import HeaderCell from './HeaderCell';

const TableHeaders = ({
  numSelected,
  rowCount,
  onSelectAllClick,
  selectable,
  handleSortOrder,
  headerOptions,
  classes = {},
}) => {
  const [sortType, setSortType] = useState('ID');

  const onColumnSortClick = (type, direction) => {
    setSortType(type);
    handleSortOrder(`${type}:${direction}`);
  };

  const SelectAllHeaderCell = () => (
    <CheckAllCell padding="checkbox">
      <CheckAllBox
        indeterminate={numSelected > 0 && numSelected < rowCount}
        checked={rowCount > 0 && numSelected === rowCount}
        onChange={onSelectAllClick}
      />
    </CheckAllCell>
  );

  return (
    <TableHead>
      <TableRow>
        {selectable && <SelectAllHeaderCell />}
        {headerOptions.map(({ title, sortValue, styles, key, cellStyles }) => (
          <HeaderCell
            key={typeof title === 'string' ? title : key}
            header={title}
            sortValue={sortValue}
            isEmptyTab={title === 'EMPTY'}
            isSelected={sortType === title}
            onClick={onColumnSortClick}
            styles={styles}
            classes={classes}
            cellStyles={cellStyles}
          />
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeaders;

const CheckAllBox = ({ sx, ...props }) => (
  <Checkbox
    sx={{
      '&.Mui-checked': {
        color: 'secondary.main',
      },
      '&.MuiCheckbox-indeterminate': {
        color: 'secondary.main',
      },
      ...sx,
    }}
    {...props}
  />
);

const CheckAllCell = ({ sx, ...props }) => (
  <TableCell
    sx={{
      backgroundColor: 'white',
      ...sx,
    }}
    {...props}
  />
);
