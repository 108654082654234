import React from 'react';

import { InputForesiteField } from '@evolvemep/foresite-components-ui-react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import useBreakpoint from 'hooks/useBreakpoint';

const ForgotPasswordForm = ({ onSubmit, loading }) => {
  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm({ mode: 'all', defaultValues: { email: '' } });
  const { isMobile } = useBreakpoint();

  return (
    <>
      <Title isMobile={isMobile} />
      <EnterEmailPrompt isMobile={isMobile} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <EmailInputField control={control} />
        <SendVerificationLinkButton
          disabled={loading || !isValid}
          fullWidth
          type="submit"
          variant="contained"
          color="secondary"
        >
          SEND VERIFICATION LINK
        </SendVerificationLinkButton>
        <ReturnToLoginButton component={Link} to="/auth/login" fullWidth>
          RETURN TO LOGIN
        </ReturnToLoginButton>
      </form>
    </>
  );
};

export default ForgotPasswordForm;

// needs translations

const Title = ({ isMobile }) => (
  <Typography component="h1" variant={isMobile ? 'h5' : 'h4'} color="#10143D">
    Forgot Password
  </Typography>
);

const EnterEmailPrompt = ({ isMobile }) => (
  <Typography variant={isMobile ? 'body2' : 'body1'} component="p" color="#10143D">
    Please enter your email address to receive a reset password link.
  </Typography>
);

const EmailInputField = ({ control }) => (
  <InputForesiteField
    name="email"
    control={control}
    label="Email"
    sx={{ marginBottom: '24px' }}
    rules={{
      required: 'Invalid email',
      pattern: {
        value: /\S+@\S+\.\S+/,
        message: 'Invalid email',
      },
    }}
  />
);

const SendVerificationLinkButton = (props) => (
  <Button
    sx={{
      backgroundColor: '#10143D',
      fontSize: '15px',
      lineHeight: '24px',
      letterSpacing: '0.46px',
      textTransform: 'uppercase',
      '&:hover': {
        background: '#434A90',
      },
    }}
    {...props}
  />
);

const ReturnToLoginButton = (props) => <Button sx={{ marginTop: '8px' }} {...props} />;
