import { useCallback, useMemo } from 'react';

import { gql, useLazyQuery } from '@apollo/client';

import { DOCUMENT_STATUS_TYPE } from 'constants/globalConstants';
import { documentById as DOCUMENT_BY_ID, documents as DOCUMENTS } from 'graphql/queries';
import useCache from 'hooks/useCache';

import { useDrawingRegisterContext } from '../../DrawingRegisterContext';

const useGetDocumentAndWrite = () => {
  const [fetchDocument] = useLazyQuery(gql(DOCUMENT_BY_ID), {
    fetchPolicy: 'network-only',
  });

  const { writeCache, readCache } = useCache();
  const { selectedFolder, sortModel } = useDrawingRegisterContext();

  const queryDocumentById = useMemo(
    () => ({
      query: gql(DOCUMENTS),
      variables: {
        query: {
          folderIds: selectedFolder?.folderId,
          orderBy: sortModel,
        },
      },
    }),
    [selectedFolder?.folderId, sortModel],
  );

  const writeDocumentCache = useCallback(
    (document) => {
      const { query, variables } = queryDocumentById;

      const { documents: cacheDocuments } = readCache(query, variables);
      const itemToUpdate = cacheDocuments.find((item) => item.documentId === document.documentId);

      if (!itemToUpdate) return;

      const updatedItem = {
        ...itemToUpdate,
        documentStatusTypeId: document.documentStatusTypeId,
      };

      const updatedCacheDocuments = cacheDocuments.map((item) =>
        item.documentId === itemToUpdate.documentId ? updatedItem : item,
      );

      writeCache(query, variables, { documents: updatedCacheDocuments });
    },

    [queryDocumentById, readCache, writeCache],
  );

  const getDocumentById = useCallback(
    async (documentId) => {
      const { data } = await fetchDocument({
        variables: { params: { documentId } },
      });

      return data?.documentById || null;
    },
    [fetchDocument],
  );

  const fetchConvertingDocuments = async (convertingDocuments) => {
    const documentsById = convertingDocuments.map(({ documentId }) => getDocumentById(documentId));

    const results = await Promise.all(documentsById);

    if (!results.length) return;

    results.forEach((document) => {
      if (document?.documentStatusTypeId !== DOCUMENT_STATUS_TYPE.CONVERTING_DOCUMENT) writeDocumentCache(document);
    });
  };

  return {
    fetchConvertingDocuments,
  };
};

export default useGetDocumentAndWrite;
