export const filterLineItemsWithDecisions = (lineItems, updateLineItemsAdded) => {
  if (!lineItems) return;
  const itemsToMakeDecisions = checkItemsToMakeDecisions(lineItems);

  if (itemsToMakeDecisions?.length > 0) updateLineItemsAdded(itemsToMakeDecisions[0], itemsToMakeDecisions);
};

export const checkItemsToMakeDecisions = (lineItems) =>
  lineItems?.filter((lineItem) => lineItem?.ableToMakeDecisions && lineItem?.numberOfDecisionsMade === 0);

export const getNumberOfOpenDecisionString = (numberOfOpenDecisions) => {
  if (!numberOfOpenDecisions) return null;

  const numberOfDecisionsArray = Array(numberOfOpenDecisions).fill(' x');

  return numberOfDecisionsArray.join(',');
};

export const getLineItemsWithCorrectQty = (itemsToMakeDecisions, partsAdded) => {
  if (itemsToMakeDecisions == null) return [];
  return itemsToMakeDecisions.map((lineItem) => {
    const partWithCorrectQty = partsAdded.filter((part) => part.partId === lineItem.partId);

    if (partWithCorrectQty?.length === 0) return lineItem;

    return {
      ...lineItem,
      quantity: partWithCorrectQty[0].quantity,
    };
  });
};

export const addTemporalIdToDuplicateItems = (writeItems, itemsToMakeDecisions) => {
  if (itemsToMakeDecisions == null) return [];
  return writeItems?.map((lineItem) => {
    const itemAlreadyAdded = itemsToMakeDecisions.some(
      (itemToMakeDecision) => itemToMakeDecision.lineItemId === lineItem.lineItemId,
    );

    if (!itemAlreadyAdded) return lineItem;
    return {
      ...lineItem,
      lineItemId: `${lineItem.lineItemId}-temporalId`,
    };
  });
};

export const removeUnmadeDecisions = (decisions) => {
  if (!decisions) return {};
  // Get an array of key-value pairs from the object
  const entries = Object?.entries(decisions);

  // Filter the entries where there are not value
  const filteredEntries = entries.filter(([, value]) => value);

  // Convert the filtered array back into an object
  const filteredObject = Object.fromEntries(filteredEntries);

  return filteredObject;
};
