type UseRecursiveStyleItemsProps = {
  isRecursive: boolean;
  parentIsSelected: boolean;
  pl: number;
};

export const useRecursiveStyleItems = ({ isRecursive, parentIsSelected, pl }: UseRecursiveStyleItemsProps) => {
  const initialStyles = {
    '& .MuiDataGrid-detailPanel': {
      backgroundColor: 'white !important',
    },
    '& .MuiDataGrid-columnHeader:nth-of-type(2) .MuiDataGrid-columnSeparator': {
      visibility: 'hidden',
    },
    '& .MuiDataGrid-cell[data-field="lineItemName"]': {
      maxHeight: '50px !important',
    },
  };

  if (!isRecursive) return initialStyles;
  const recursiveTableStyles = {
    ...initialStyles,
    boxShadow: 0,
    border: 0,
    '& .MuiDataGrid-columnHeaders': {
      display: 'none',
    },
    '& .MuiDataGrid-virtualScroller': {
      marginTop: '0 !important',
      overflowX: 'hidden !important',
    },
    '& .MuiDataGrid-virtualScrollerContent': {
      backgroundColor: 'white !important',
    },
    '& .MuiDataGrid-detailPanel': {
      overflow: 'hidden !important',
      height: 'min-content !important',
    },
    '& .MuiDataGrid-row': {
      border: 'none !important',
      position: 'relative',
    },
    '& .MuiDataGrid-cell[data-field="__detail_panel_toggle__"]': {
      pl,
      pr: '22px',
    },
    '& .MuiDataGrid-cell[data-field="assemblyType"], .MuiDataGrid-cell[data-field="tradeSize"], .MuiDataGrid-cell[data-field="unitQuantity"], .MuiDataGrid-cell[data-field="quantity"], .MuiDataGrid-cell[data-field="remainingQuantity"], .MuiDataGrid-cell[data-field="unitOfMeasureCode"], .MuiDataGrid-cell[data-field="manufacturerName"], .MuiDataGrid-cell[data-field="manufacturerCatalogNumber"], .MuiDataGrid-cell[data-field="decisions"], .MuiDataGrid-cell[data-field="options"]':
      {
        position: 'relative',
        right: `${pl * 8 - 10}px`,
      },
    '& .MuiDataGrid-cell[data-field="lineItemName"]': {
      pr: `${pl * 8 - 10}px`,
    },
    '& .MuiCheckbox-root.Mui-checked svg': parentIsSelected && {
      opacity: 0.4,
    },
  };

  return recursiveTableStyles;
};
