/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react';

import { getSmoothStepPath, Position } from 'react-flow-renderer';

import { IUseCustomSmoothPath, OffsetDirection } from './@types/useCustomSmoothPathTypes';

export const TARGET_OFFSET_FIX = 25;
export const ARROW_OFFSET_FIX = 15;

export default ({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  borderRadius = 10,
}: IUseCustomSmoothPath) => {
  const fixedTargetPosition = useMemo<Position>(
    () => {
      if (targetPosition === Position.Left) {
        if (sourceX > targetX - TARGET_OFFSET_FIX) return Position.Top;
        return Position.Left;
      }
      if (sourceY <= targetY - TARGET_OFFSET_FIX) return Position.Top;
      return Position.Left;
    },
    targetPosition === Position.Left ? [sourceX, targetX] : [sourceY, targetY],
  );

  const fixedSourcePosition = useMemo<Position>(
    () => {
      if (sourcePosition === Position.Right) {
        if (sourceX > targetX - TARGET_OFFSET_FIX) return Position.Top;
        return Position.Right;
      }
      if (sourceY <= targetY - TARGET_OFFSET_FIX) return Position.Top;
      return Position.Right;
    },
    sourcePosition === Position.Right ? [sourceX, targetX] : [sourceY, targetY],
  );

  const changeOffsetY = useMemo<OffsetDirection>(
    () => (sourceY > targetY ? OffsetDirection.Down : OffsetDirection.Up),
    targetPosition === Position.Top ? [] : [sourceY, targetY],
  );

  const changeOffsetX = useMemo<OffsetDirection>(
    () => (sourceX > targetX ? OffsetDirection.Right : OffsetDirection.Left),
    targetPosition === Position.Left ? [] : [sourceX, targetX],
  );

  const offsetDict = {
    [OffsetDirection.Down]: borderRadius,
    [OffsetDirection.Up]: -borderRadius,
    [OffsetDirection.Right]: borderRadius,
    [OffsetDirection.Left]: -borderRadius,
  };

  const fixedTargetY = useMemo<number>(() => {
    if (targetPosition === Position.Left) {
      if (fixedTargetPosition === Position.Top) return targetY + offsetDict[changeOffsetY];
      return targetY;
    }
    if (fixedTargetPosition === Position.Top) return targetY - ARROW_OFFSET_FIX;
    return targetY - ARROW_OFFSET_FIX - borderRadius;
  }, [fixedTargetPosition, targetY, sourceY]);

  const fixedTargetX = useMemo<number>(() => {
    if (targetPosition === Position.Top) {
      if (fixedTargetPosition === Position.Top) return targetX;
      return targetX + offsetDict[changeOffsetX];
    }
    if (fixedTargetPosition === Position.Top) return targetX - ARROW_OFFSET_FIX - borderRadius;
    return targetX - ARROW_OFFSET_FIX;
  }, [fixedTargetPosition, targetX, sourceX]);

  const edgePath = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition: fixedSourcePosition,
    targetX: fixedTargetX,
    targetY: fixedTargetY,
    targetPosition: fixedTargetPosition,
    borderRadius,
  });

  const fixedEdgePath = useMemo<string>(() => {
    if (targetPosition === Position.Left) {
      if (sourceX + TARGET_OFFSET_FIX <= targetX) return edgePath;
      return `${edgePath}Q${fixedTargetX},${fixedTargetY - offsetDict[changeOffsetY]}  ${
        fixedTargetX + borderRadius
      },${fixedTargetY - offsetDict[changeOffsetY]}`;
    }
    if (sourceY + TARGET_OFFSET_FIX <= targetY) return edgePath;
    return `${edgePath}Q${fixedTargetX - offsetDict[changeOffsetX]},${fixedTargetY}  ${
      fixedTargetX - offsetDict[changeOffsetX]
    },${fixedTargetY + borderRadius}`;
  }, [edgePath, fixedTargetX, fixedTargetY]);

  return fixedEdgePath;
};
