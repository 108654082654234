import { gql } from '@apollo/client';

import getClient from 'apollo/client';
import { workCellTasksMultiSearch as WORK_CELL_TASKS_MULTI_SEARCH } from 'graphql/queries';
import useLazyPaginatedQuery from 'hooks/useLazyPaginatedQuery';

export const useWorkCellsTaskDataLayer = () => {
  const [
    { lazyLoad: getWorkCellTasks, paginationHandler },
    { data, loading: loadingWorkCellTasks },
  ] = useLazyPaginatedQuery(gql(WORK_CELL_TASKS_MULTI_SEARCH), 'cache-and-network', 30);

  const readWorkCellsTaskCache = async (query) => {
    const client = await getClient();
    client.readQuery({
      query: gql(WORK_CELL_TASKS_MULTI_SEARCH),
      variables: {
        query: {orderBy: 'createdOn:asc', ...query},
      },
    });
  }

  return {
    getWorkCellTasks,
    workCellTasksData: data?.workCellTasksMultiSearch,
    loadingWorkCellTasks,
    paginationHandler,
    readWorkCellsTaskCache,
  };
};
