import React, { FC } from 'react';

import { Box } from '@mui/material';

interface IRowPlaceholder {
  nestLevel?: number;
  rowsNumber?: number;
}

const RowPlaceholder: FC<IRowPlaceholder> = ({ nestLevel = 1, rowsNumber = 1 }: IRowPlaceholder) => (
  <Box
    sx={{
      height: `${rowsNumber * 32}px`,
      width: '100%',
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
      padding: `6px 6px 6px ${nestLevel * (nestLevel === 1 ? 48 : 32)}px`,
      animation: 'pulse 1s infinite ease',
      '@keyframes pulse': {
        '0%': {
          opacity: 0.5,
        },
        '50%': {
          opacity: 1,
        },
        '100%': {
          opacity: 0.5,
        },
      },
    }}
  >
    <Box
      sx={{
        height: '100%',
        width: '100%',
        borderRadius: '4px',
        backgroundColor: 'rgba(0,0,0,0.1)',
      }}
    />
  </Box>
);

export default RowPlaceholder;
