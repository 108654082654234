import React from 'react';

import { Typography } from '@mui/material';

import { Modal } from 'components';
import { CancelButton, CreateButton } from 'components/Buttons';

const ConfirmationModal = ({
  open,
  onClose,
  onConfirm,
  text,
  cancelButtonText = 'NO',
  confirmButtonText = 'CONFIRM',
  modalTitle = 'Confirm Cancel',
  confirmButtonColor = 'error',
  sx,
  contentStyles,
}) => (
  <StyledModal
    open={open}
    onClose={onClose}
    title={modalTitle}
    sx={sx}
    contentStyles={contentStyles}
    footer={
      <>
        <CancelButton
          buttonText={cancelButtonText}
          onClick={onClose}
          sx={{ letterSpacing: '0.4px', fontWeight: 600 }}
        />
        <CreateButton
          buttonText={confirmButtonText}
          color={confirmButtonColor}
          onClick={onConfirm}
        />
      </>
    }
  >
    <Typography sx={{ lineHeight: '24px', letterSpacing: '0.15px' }}>{text}</Typography>
  </StyledModal>
);

export default ConfirmationModal;

const StyledModal = ({ children, sx, contentStyles, ...props }) => (
  <Modal
    reducePadding
    fullWidth
    maxWidth="sm"
    sx={{
      '.MuiDialog-paper': {
        maxWidth: '505px',
      },
      ...sx,
    }}
    titleStyles={{
      letterSpacing: 0.15,
      fontWeight: 600,
    }}
    contentStyles={{
      py: 2,
      ...contentStyles,
    }}
    {...props}
  >
    {children}
  </Modal>
);
