import React, { Fragment } from 'react';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useNavigate } from 'react-router-dom';

import { useFacilitiesProjects, formatProject } from 'app/FacilitiesProjectsContext';
import StyledLink from 'components/StyledLink';
import InfiniteScroll from 'components/TableComponents/InfiniteScroll';
import MoreOptionsDropdown from 'components/TableComponents/MoreOptionsDropdown/MoreOptionsDropdown';
import NoContentMessage from 'components/TableComponents/NoContentMessage';
import TableContainer from 'components/TableComponents/TableContainer';
import TableHeader from 'components/TableComponents/TableHeaders';
import TableSkeleton from 'components/TableComponents/TableSkeleton';
import useSetupModule from 'hooks/useSetupModule';

import MoreTableRowOptions from './MoreTableRowOptions';
import { useProjectContext } from './ProjectsContext';

const headerOptions = [
  { title: 'ID', sortValue: 'projectIdentifier' },
  { title: 'Project Name', sortValue: 'projectName' },
  { title: 'Type', sortValue: 'type' },
  { title: 'Members' },
  { title: '' },
];

const ProjectsTable = () => {
  const navigate = useNavigate();
  const { setSelectedItem } = useFacilitiesProjects();
  const { loading, projects, paginationHandler, sortHandler } = useProjectContext();
  const { currentModule } = useSetupModule();

  const module = currentModule() ?? 'admin';

  const TableBodyContent = () => (
    <TableBody>
      {projects.map((project, index) => (
        <Fragment key={project.projectId}>
          <TableRow>
            <TableCell align="left">{project.projectIdentifier || '--'}</TableCell>
            <TableCell align="left">
              <StyledLink
                label={project.projectName}
                onClick={() => {
                  setSelectedItem(formatProject(project));
                  navigate(`/${module}/properties/project-properties`);
                }}
              />
            </TableCell>
            <TableCell align="left">--</TableCell>
            <TableCell align="left">--</TableCell>
            <TableCell align="right">
              <MoreOptionsDropdown
                optionsRenderer={() => <MoreTableRowOptions project={project} />}
              />
            </TableCell>
          </TableRow>
          {index === projects.length - 1 && (
            <InfiniteScroll data={projects} fetchMore={paginationHandler} />
          )}
        </Fragment>
      ))}
    </TableBody>
  );

  return (
    <ProjectsTableBorderBox>
      <TableContainer>
        <Table stickyHeader>
          <TableHeader headerOptions={headerOptions} handleSortOrder={sortHandler} />
          {loading ? (
            <TableSkeleton skeletons={headerOptions.map(({ title }) => title)} />
          ) : (
            <TableBodyContent />
          )}
        </Table>
      </TableContainer>
      {!projects.length && !loading && <NoContentMessage text="No Available Projects..." />}
    </ProjectsTableBorderBox>
  );
};

export default ProjectsTable;

const ProjectsTableBorderBox = (props) => (
  <Box
    sx={{
      border: '1px solid',
      borderColor: 'other.divider.lightSurface',
      borderRadius: '5px',
      position: 'relative',
      padding: '5px 0 0 0',
      height: 'calc(100% - 72px)',
    }}
    {...props}
  />
);
