import { getBaseApiUrl } from './useEvolveApi';

export const useGetLinkFromQrId = () => {
  const getLinkFromQrCode = async (id: string) => {
    const baseUrl = await getBaseApiUrl();
    const response = await fetch(`${baseUrl}/resource/qr/shortened/${id}`, {
      method: 'GET',
      mode: 'cors',
    });

    return response;
  };

  return { getLinkFromQrCode };
};
