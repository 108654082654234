import React from 'react';

import InputSelectMultipleField from 'components/FormComponents/InputSelectMultipleField';
import { requiredRule } from 'constants/inputFieldRules';

const DropdownMultiple = ({ label, options = [], ...props }) => (
  <InputSelectMultipleField
    size="small"
    label={label}
    fullWidth
    options={options}
    sx={{ marginTop: '8px', marginBottom: '8px' }}
    fontSize="16px"
    rules={requiredRule(label)}
    {...props}
  />
);

export default DropdownMultiple;
