import { ResponseKey, VariableType } from 'hooks/usePaginatedDocuments';

import useCache from './useCache';

export type DocumentsCacheType = {
  deleteDocumentsCache: (queryKey: ResponseKey, query: VariableType) => void;
};

const useDocumentsCache = (): DocumentsCacheType => {
  const { deleteFromCacheByQuery } = useCache();

  const deleteDocumentsCache = (queryKey: ResponseKey, query: VariableType) => {
    deleteFromCacheByQuery(queryKey, query);
  };

  return {
    deleteDocumentsCache,
  };
};

export default useDocumentsCache;
