import React from 'react';

import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';

import { Custom } from 'components/Icons';

const StyledAlert = ({ children, ...props }) => (
  <Alert
    {...props}
    sx={{
      boxShadow: `0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)`,
    }}
    iconMapping={{
      success: <Custom.CheckCircle />,
      error: <Custom.Error />,
    }}
  >
    <Typography variant="body2">{children}</Typography>
  </Alert>
);

export default StyledAlert;
