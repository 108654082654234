import React from 'react';

import CardFilterChipList from 'components/Popover/FilterPopover/components/CardFilterChipList';
import { WorkRequestsProvider } from 'modules/Shop/WorkRequests/WorkRequestsContext';

import WorkRequestFilterColumns from './WorkRequestFilterColumns';
import WorkRequestsHeader from './WorkRequestsHeader';
import WorkRequestsList from './WorkRequestsList';

const WorkRequestsListContainer = ({ workRequestSelected, filterComponent, isResponsive }) => (
  <WorkRequestsProvider>
    <WorkRequestsHeader
      title="Work Requests"
      filter
      workRequestSelected={workRequestSelected}
      isResponsive={isResponsive}
    />
    <WorkRequestFilterColumns
      workRequestSelected={workRequestSelected}
      filterComponent={filterComponent}
      isResponsive={isResponsive}
    />
    {filterComponent && <CardFilterChipList />}
    <WorkRequestsList workRequestSelected={workRequestSelected} isResponsive={isResponsive} />
  </WorkRequestsProvider>
);

export default WorkRequestsListContainer;
