/* eslint-disable max-lines-per-function */
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { VARIANT_ERROR } from 'constants/snackbarConstants';
import {
  addWorkPhase as ADD_WORK_PHASE,
  updateWorkPhase as UPDATE_WORK_PHASE,
  deleteWorkPhase as DELETE_WORK_PHASE,
} from 'graphql/mutations';
import { workPhases as WORK_PHASES } from 'graphql/queries';
import { findParent, recursiveUpdateNodeKeyValue, removeChildrenRecursively } from 'helpers/arrayFunctions';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

import { ROW_TYPES } from '../../constants/constants';
import { appendWorkPhasesToScopePackages, mapWorkPhases } from '../helpers/scopePackageHelper';
import useWorkPhasesByLocation from './useWorkPhasesByLocation';

export default function useWorkPhasesDataLayer(locations, setLocations, errorCallback = () => {}) {
  const { removeWorkPhasesFromLocationsTable, updateWorkPhasesNamesOnLocationsTable } = useWorkPhasesByLocation(
    locations,
    setLocations,
  );
  const { handleResponse } = useGraphqlResponseHandler();
  const { enqueueSnackbar } = useSnackbar();

  const [workPhasesQuery, { loading }] = useLazyQuery(gql(WORK_PHASES), {
    fetchPolicy: 'cache-and-network',
  });

  const [addWorkPhaseMutation] = useMutation(gql(ADD_WORK_PHASE));
  const [updateWorkPhaseMutation] = useMutation(gql(UPDATE_WORK_PHASE));
  const [deleteWorkPhaseMutation] = useMutation(gql(DELETE_WORK_PHASE));

  const fetchWorkPhases = async (projectId, setTableRows = () => {}, setActiveRows = () => {}) => {
    workPhasesQuery({
      variables: { query: { projectId, orderBy: 'sortOrder:asc' } },
      onCompleted: (response) => {
        const fetchedWorkPhases = response.workPhases;
        if (!fetchedWorkPhases) {
          enqueueSnackbar('Error fetching work phases', VARIANT_ERROR);
        } else {
          const appendWorkPhasesToItsRespectiveScopePackage = (tableRows) => {
            const mappedWorkPhases = mapWorkPhases(fetchedWorkPhases);
            const scopePackages = tableRows[0].children;
            const scopePackagesWithChildren = appendWorkPhasesToScopePackages(scopePackages, mappedWorkPhases);
            if (scopePackagesWithChildren[0])
              setTimeout(() => {
                setActiveRows([scopePackagesWithChildren[0]]);
              }, 0);

            return [{ ...tableRows[0], children: scopePackagesWithChildren }];
          };
          setTableRows((prev) => appendWorkPhasesToItsRespectiveScopePackage(prev));
        }
      },
    });
  };

  const addWorkPhase = async (row, projectId, rows, setTableRows = () => {}) => {
    const body = {
      projectId,
      workPhaseName: row.label,
      scopePackageId: findParent(row.id, rows)?.id,
      sortOrder: row.sortOrder,
      workPhaseIdentifier: row.prefix,
    };

    await handleResponse(
      addWorkPhaseMutation,
      { variables: { body } },
      { successMessage: 'Work Phase successfully created' },
      ({ addWorkPhase: newWorkPhase }) => {
        const {
          workPhaseId: id,
          workPhaseName: label,
          sortOrder,
          workPhaseIdentifier: prefix,
          scopePackageId,
        } = newWorkPhase;
        const newWorkPhaseMapped = {
          id,
          label,
          sortOrder,
          prefix,
          scopePackageId,
          type: ROW_TYPES.WORK_PHASE,
        };
        setTableRows(recursiveUpdateNodeKeyValue(row.id, rows, newWorkPhaseMapped));
      },
      () => errorCallback(),
    );
  };

  const updateWorkPhase = async (workPhase, rows) => {
    const { id, label: phaseName, sortOrder, prefix: workPhaseIdentifier } = workPhase;
    const scopePackageId = findParent(id, rows)?.id;
    await handleResponse(
      updateWorkPhaseMutation,
      {
        variables: {
          params: { id },
          body: { phaseName, sortOrder, scopePackageId, workPhaseIdentifier },
        },
      },
      { successMessage: 'Work phase successfully updated 2' },
      (response) => updateWorkPhasesNamesOnLocationsTable(response),
      () => errorCallback(),
    );
  };

  const removeWorkPhase = async (id, rows, setTableRows = () => {}, updateSuffix) => {
    await handleResponse(
      deleteWorkPhaseMutation,
      { variables: { params: { id } } },
      { successMessage: 'Work phase successfully deleted' },
      (response) => {
        if (response?.deleteWorkPhase) {
          setTableRows(removeChildrenRecursively(id, rows));
          removeWorkPhasesFromLocationsTable(response?.deleteWorkPhase);
          updateSuffix();
        }
      },
    );
  };

  return {
    fetchWorkPhases,
    addWorkPhase,
    updateWorkPhase,
    removeWorkPhase,
    loadingWorkPhases: loading,
  };
}
