import React from 'react';

import { Typography, useTheme } from '@mui/material';

import { Modal, CancelButton, ConfirmButton } from 'components';

const ConfirmModal = ({ open, onConfirm, onClose }) => {
  const theme = useTheme();

  return (
    <StyledModal
      open={open}
      onClose={onClose}
      title={<Typography variant="h6">Confirm Clear Data</Typography>}
      footer={
        <>
          <CancelButton color="primary" onClick={onClose} />
          <ConfirmButton
            color="white"
            sx={{
              background: theme.palette.error.main,
              '&:hover': {
                background: theme.palette.error.light,
              },
            }}
            onClick={onConfirm}
          />
        </>
      }
    >
      <Typography variant="body1">
        Are you sure you want to clear all data for the <b>Revit</b> source tab?
      </Typography>
    </StyledModal>
  );
};

export default ConfirmModal;

const StyledModal = ({ children, ...props }) => (
  <Modal
    reducePadding
    width="505px"
    titleStyles={{
      letterSpacing: 0.15,
    }}
    contentStyles={{
      padding: '13px 34px 0 24px',
    }}
    {...props}
  >
    {children}
  </Modal>
);
