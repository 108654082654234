import { SubscriptionHookOptions, gql, useSubscription } from '@apollo/client';

import { unassignedEntitlement as UNASSIGNED_ENTITLEMENT } from 'graphql/subscriptions';
import { Entitlement } from 'graphql/types';

const useUnassignedEntitlementSubscription = (
  options: SubscriptionHookOptions<{ unassignedEntitlement: Entitlement }, any>,
) => useSubscription<{ unassignedEntitlement: Entitlement }>(gql(UNASSIGNED_ENTITLEMENT), options);

export default useUnassignedEntitlementSubscription;
