import React, { useState } from 'react';

import { CreateButton } from 'components';
import useUpdateMultipleTasks from 'hooks-api/useUpdateMultipleTasks';
import { useWorkOrderUpdate } from 'hooks-api/useWorkOrderUpdate';

import { useWorkOrderCancelModalContext } from './context/WorkOrderCancelModalContext';
import { useWorkOrderItemsContext } from './context/WorkOrderItemsContext';
import WorkOrderModalCancel from './WorkOrderModalCancel';

const WorkOrderCancel = ({ workOrderStatus, disabled }) => {
  const [openModal, setOpenModal] = useState(false);
  const { workOrder, workOrderStatusType } = useWorkOrderItemsContext();

  const { tasksToBeSavedOnCancel, showCancelTable } = useWorkOrderCancelModalContext();
  const { updateWorkOrder, loading } = useWorkOrderUpdate();
  const { updateMultipleTasks, loadingMultipleTasks } = useUpdateMultipleTasks();

  const blockWorkOrder = async () => {
    await updateWorkOrder({
      workOrderId: workOrder?.workOrderId,
      body: {
        workOrderStatusTypeId: workOrderStatusType.find((workOrder) => workOrder.label === 'Blocked').value,
      },
      successCallback: () => {},
      successMessage: 'Work order successfully canceled',
    });
    setOpenModal(false);
  };

  const cancelMultipleTasks = async () => {
    await updateMultipleTasks({ body: { tasks: tasksToBeSavedOnCancel } });
  };

  const onSubmit = async () => {
    if (showCancelTable) await cancelMultipleTasks();
    blockWorkOrder();
  };

  return (
    <>
      <CreateButton
        variant="outlined"
        buttonText="Cancel"
        onClick={() => setOpenModal(true)}
        disabled={disabled}
        sx={{
          minHeight: '32px',
          fontWeight: 500,
          '&:hover': {
            backgroundColor: 'secondary.tableHover',
            color: '#31167E',
            boxShadow: 'none',
          },
        }}
        data-cy="cancel-button"
      />
      <WorkOrderModalCancel
        open={openModal}
        onClose={() => setOpenModal(false)}
        onSubmit={onSubmit}
        loading={loading || loadingMultipleTasks}
        workOrderStatus={workOrderStatus}
      />
    </>
  );
};

export default WorkOrderCancel;
