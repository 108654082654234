import { useCallback, useEffect, useMemo, useState } from 'react';

import type { AxiosRequestConfig } from 'axios';

import { extractErrorMessage } from 'helpers/extractError';
import { isNil, isNotNil } from 'helpers/isNotNil';

import useEvolveApi from './useEvolveApi';

type Opts<D> = {
  /**
   * The default config passed to each api call.
   * To make changes per-call, pass the new config params into the `apiCall`.
   */
  defaultConfig?: AxiosRequestConfig<D>;
};

// All other API calls happen as a result of an action.
// GET requests, specifically, *may* happen immediately upon page load,
// but may also either need to wait for something else, or be called again later.
type GetOpts<D> = Opts<D> & {
  /** If `true`, will not make a request call upon hook instantiation */
  lazy?: boolean;
};

const useWrappedApiCallNoBody = <ResponseType, DataType = any>(
  method: 'get' | 'apiDelete',
  url: string,
  defaultOpts?: GetOpts<DataType>,
) => {
  // Frequently, the passed in parameters are recalculated on a rerender
  // so we need to be very careful to not rerender/recalculate when they change.
  const [initialOpts, setDefaultOpts] = useState(defaultOpts);
  const [loading, setLoading] = useState(!initialOpts?.lazy && method === 'get');
  const [data, setData] = useState<ResponseType | undefined>(undefined);
  const [errors, setErrors] = useState<any>();
  const apiMethod = useEvolveApi()[method];

  const apiCall = useCallback(
    async (config?: AxiosRequestConfig<DataType>) => {
      setErrors(undefined);
      setLoading(true);
      return apiMethod<ResponseType>(config?.url ?? url, { ...initialOpts?.defaultConfig, ...config })
        .then((res) => {
          setData(res);
          return res;
        })
        .catch((err) => {
          const extractedError = extractErrorMessage(err);
          setErrors(extractedError);
          throw extractedError;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [apiMethod, initialOpts?.defaultConfig, url],
  );

  useEffect(() => {
    if (!initialOpts?.lazy && method !== 'apiDelete') {
      // For non-lazy GET requests, submit a request upon hook instantiation
      apiCall(initialOpts?.defaultConfig).catch(() => {});
    }
  }, [initialOpts, apiCall, method]);

  return {
    setDefaultOpts,
    apiCall,
    data,
    loading,
    errors,
  };
};

const useWrappedApiCallWithBody = <ResponseType, DataType>(
  method: 'post' | 'patch',
  url: string,
  defaultOpts?: Opts<DataType>,
) => {
  const [initialOpts, setDefaultOpts] = useState(defaultOpts);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ResponseType | undefined>(undefined);
  const [errors, setErrors] = useState<any>();
  const apiMethod = useEvolveApi()[method];

  const apiCall = useCallback(
    async (body: DataType, config?: AxiosRequestConfig<DataType>) => {
      setErrors(undefined);
      setLoading(true);
      return apiMethod<ResponseType>(config?.url ?? url, body, { ...initialOpts?.defaultConfig, ...config })
        .then((res) => {
          setData(res);
          return res;
        })
        .catch((err) => {
          const extractedError = extractErrorMessage(err);
          setErrors(extractedError);
          throw extractedError;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [apiMethod, initialOpts?.defaultConfig, url],
  );

  return { setDefaultOpts, apiCall, data, loading, errors };
};

/** Used for paginated responses */
export type EvolveApiReturn<DataType> = {
  data: DataType[];
  requestedSkip: number;
  requestedTake: number;
  entireCount: number;
};

/**
 * Wrapper for Evolve's pagination API, used with `Multisearch` endpoints.
 */
export const useWrappedPaginatedGet = <ResponseType, DataType = any>(
  /** Can be overridden per-call via `config.url` */
  url: string,
  /**
   * The default options to pass to the api calls.
   * Changing this input will not change the options - instead,
   * pass the new options into the `apiCall`.
   */
  defaultOpts?: GetOpts<DataType> & {
    /** How many results to return per call. */
    perPage?: number;
  },
) => {
  const [searchPhrase, setSearchPhrase] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [initialOpts, setDefaultOptsInner] = useState(defaultOpts);
  const [lazyCallMade, setLazyCallMade] = useState(initialOpts?.lazy ?? false);
  const {
    apiCall,
    data,
    loading,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setDefaultOpts: _unused,
    ...rest
  } = useWrappedApiCallNoBody<EvolveApiReturn<ResponseType>, DataType>('get', url, {
    // We will handle a non-lazy call ourselves below in useEffect
    lazy: true,
  });
  const setDefaultOpts = useCallback((...opts: Parameters<typeof setDefaultOptsInner>) => {
    setDefaultOptsInner(...opts);
    setLazyCallMade(false);
  }, []);
  // `data` above only has the most recent page,
  // so we keep track of all of the concatenated pages here
  const [fullData, setFullData] = useState<ResponseType[]>([]);
  const [totalRequested, hasMore] = useMemo(() => {
    if (isNil(data)) {
      return [0, true];
    }
    const total = Math.max(data.requestedTake, data.data.length) + data.requestedSkip;
    return [total, total < data.entireCount];
  }, [data]);
  // I don't like how many var changes will cause this function to be regenerated
  // but the `loading && hasMore` check *should* prevent extraneous calls.
  // This should definitely be revisited in the future.
  const fetchNextPage = useCallback(
    async (
      config?: AxiosRequestConfig<DataType>,
      perPage?: number,
      /**
       * If true, will call the API even if we've already pulled the last page.
       * Used when we need to reset to page one.
       */
      force?: boolean,
    ) => {
      // If we're actively loading,
      // or we've retrieved all available data,
      // don't bother sending another request.
      if (!force && (loading || !hasMore)) {
        return;
      }
      const newData = await apiCall({
        ...initialOpts?.defaultConfig,
        ...config,
        params: {
          ...initialOpts?.defaultConfig?.params,
          skip: totalRequested,
          take: initialOpts?.perPage ?? perPage,
          ...(orderBy ? { orderBy } : {}),
          ...(searchPhrase ? { searchPhrase } : {}),
          ...config?.params,
        },
      });
      if (isNotNil(newData)) {
        setFullData((alreadyFetched) => [...alreadyFetched, ...newData.data]);
      }
    },
    [loading, hasMore, apiCall, totalRequested, initialOpts, orderBy, searchPhrase],
  );

  // Effect is used to make any non-lazy call
  // ie. an initial call, when search params change, etc
  useEffect(() => {
    // I'm not convinced this is the cleanest way to do this
    if (!initialOpts?.lazy && !lazyCallMade && !loading) {
      setLazyCallMade(true);
      setFullData([]);
      fetchNextPage({ params: { skip: 0 } }, initialOpts?.perPage, true).catch(() => {});
    }
  }, [lazyCallMade, fetchNextPage, loading, initialOpts?.lazy, initialOpts?.perPage]);

  const refetch = useCallback(async () => {
    setFullData([]);
    await fetchNextPage(
      {
        params: {
          skip: 0,
        },
      },
      undefined,
      true,
    );
  }, [fetchNextPage]);

  const searchHandler = useCallback((value: string) => {
    setSearchPhrase(value.trim());
    setLazyCallMade(false);
  }, []);
  const sortHandler = useCallback((value: string) => {
    const valueWithoutSpaces = value.split(' ').join('');
    setOrderBy(valueWithoutSpaces.toLowerCase());
    setLazyCallMade(false);
  }, []);

  return {
    setDefaultOpts,
    fetchNextPage,
    refetch,
    data: fullData,
    hasMore,
    loading,
    searchHandler,
    sortHandler,
    ...rest,
  };
};

export const useWrappedGet = <ResponseType, DataType = any>(url: string, opts?: GetOpts<DataType>) =>
  useWrappedApiCallNoBody<ResponseType, DataType>(
    'get',
    /** Can be overridden for each `apiCall`, if needed, via `config.url` */
    url,
    opts,
  );

export const useWrappedPost = <ResponseType, DataType>(
  /** Can be overridden for each `apiCall`, if needed, via `config.url` */
  url: string,
  opts?: Opts<DataType>,
) => useWrappedApiCallWithBody<ResponseType, DataType>('post', url, opts);

export const useWrappedPatch = <ResponseType, DataType>(
  /** Can be overridden for each `apiCall`, if needed, via `config.url` */
  url: string,
  opts?: Opts<DataType>,
) => useWrappedApiCallWithBody<ResponseType, DataType>('patch', url, opts);

export const useWrappedDelete = <ResponseType, DataType = any>(
  /** Can be overridden for each `apiCall`, if needed, via `config.url` */
  url: string,
  opts?: Opts<DataType>,
) => useWrappedApiCallNoBody<ResponseType, DataType>('apiDelete', url, opts);
