import { gql, useMutation } from '@apollo/client';

import { GENERIC_MUTATION_ERROR } from 'constants/globalConstants';
import { deleteWorkOrder as DELETE_WORK_ORDER } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

export default function useWorkOrderDelete() {
  const { handleResponse } = useGraphqlResponseHandler();

  const [deleteWorkOrder, { loading }] = useMutation(gql(DELETE_WORK_ORDER), {
    refetchQueries: ['WorkOrders'],
  });

  const removeWorkOrder = (workOrderId) =>
    handleResponse(
      deleteWorkOrder,
      { variables: { params: { workOrderId } } },
      {
        successMessage: 'Work order successfully deleted',
        errorMessage: GENERIC_MUTATION_ERROR,
      },
    );

  return {
    removeWorkOrder,
    loading,
  };
}
