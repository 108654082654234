import { useMemo } from 'react';

import { gql, useQuery } from '@apollo/client';

import { useUser } from 'app/UserContext';
import { folders as FOLDERS } from 'graphql/queries';

const useGetFolderByProjectId = (projectId: string) => {
  const { user } = useUser();
  const companyId = useMemo(() => user?.companyId, [user]);

  const { data, loading: fetchFoldersLoading } = useQuery(gql(FOLDERS), {
    variables: {
      query: {
        companyId,
        projectIds: projectId,
        folderName: projectId,
      },
    },
    fetchPolicy: 'cache-and-network',
    skip: !projectId,
  });

  return {
    folderApiData: data?.folders[0] || null,
    fetchFoldersLoading,
  };
};

export default useGetFolderByProjectId;
