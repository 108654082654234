import React from 'react';

import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { format, parseISO } from 'date-fns';

const MessageListItem = ({
  message,
  isActive = false,
  isTrash = false,
  onSelect = () => {},
  onArchive = () => {},
  onDelete = () => {},
}) => {
  const handleSelect = () => {
    onSelect(message);
  };

  const handleArchive = (event) => {
    event.stopPropagation();
    onArchive(message);
  };

  const handleDelete = (event) => {
    event.stopPropagation();
    onDelete(message);
  };

  return (
    <ListItemButton
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '10px 26px',
        bgcolor: '#EEEEEE',
        border: '1px solid rgba(0, 0, 0, 0.25)',
        borderLeft: 'none',
        borderRight: 'none',
        ...(isActive && { bgcolor: '#FFFFFF' }),
      }}
      onClick={handleSelect}
    >
      <Stack width="100%" direction="row" alignItems="center" justifyContent="flex-start">
        {message && !message.accessedOn && (
          <Badge color="error" variant="dot" sx={{ marginRight: '0.5em' }} />
        )}
        <Typography variant="body1" sx={{ opacity: 0.87 }}>
          {message.subject}
        </Typography>
      </Stack>
      <Stack width="100%" direction="row" alignItems="center">
        <Typography variant="body2" sx={{ opacity: 0.5, fontWeight: '700' }}>
          {format(parseISO(message.createdOn), 'MMMM dd, yyyy')}
        </Typography>
        {isActive && (
          <>
            {isTrash ? (
              <MessageListItemAction action="Delete" onClick={handleDelete} />
            ) : (
              <>
                <MessageListItemAction action="Archive" onClick={handleArchive} />
                <MessageListItemAction
                  action="Delete"
                  sx={{ marginLeft: '1em' }}
                  onClick={handleDelete}
                />
              </>
            )}
          </>
        )}
      </Stack>
    </ListItemButton>
  );
};

export default MessageListItem;

const MessageListItemAction = ({ action, sx = {}, onClick = () => {} }) => (
  <Box component="span" sx={{ marginLeft: 'auto', cursor: 'pointer', ...sx }} onClick={onClick}>
    <Typography variant="body2" sx={{ opacity: 0.5 }}>
      {action}
    </Typography>
  </Box>
);
