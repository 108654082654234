import React from 'react';

import { Stack } from '@mui/material';

import { useWorkRequestsContext } from 'modules/Field/WorkRequests/WorkRequestsContext';

import WorkRequestAttachUrlInput from '../../../WorRequestsProperties/WorkRequestPropertiesAttachments/components/WorkRequestAttachUrlInput';
import { useWorkRequestPropertiesAttachmentsContext } from '../../../WorRequestsProperties/WorkRequestPropertiesAttachments/WorkRequestPropertiesAttachmentsContext';
import { AttachmentButtons } from './AttachmentButtons';
import { AttachmentList } from './AttachmentList';

export const WorkRequestAttachments = () => {
  const { showUrlInput } = useWorkRequestPropertiesAttachmentsContext();
  const { isWorkOrder } = useWorkRequestsContext();
  return (
    <Stack direction="column">
      <AttachmentList />
      {showUrlInput && <WorkRequestAttachUrlInput />}
      {!isWorkOrder && <AttachmentButtons />}
    </Stack>
  );
};
