import React from 'react';

import { useMutation, gql } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import { useForm } from 'react-hook-form';

import { useUser } from 'app/UserContext';
import { Modal, CancelButton, CreateButton } from 'components';
import { addProject as ADD_PROJECT } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

import { useProjectContext } from '../ProjectsContext';
import ProjectCreateForm from './ProjectCreateForm ';

const ProjectCreateModal = () => {
  const { modalOpenStatus, closeModal, lazyLoad } = useProjectContext();

  const { handleResponse } = useGraphqlResponseHandler(closeModal);
  const { user } = useUser();
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: 'all',
  });

  const [addProjectMutation, { loading }] = useMutation(gql(ADD_PROJECT));

  const submit = async ({ adminUserEmail, projectName }) => {
    const body = {
      requestedBy: user.userId,
      companyId: user.companyId,
      projectName,
      isAdmin: true,
    };

    if (typeof adminUserEmail === 'object') {
      body.userId = adminUserEmail.id;
    } else {
      body.userEmail = adminUserEmail;
    }

    handleResponse(
      addProjectMutation,
      {
        variables: {
          body,
        },
      },
      { successMessage: `${projectName} created successfully` },
      () => lazyLoad(),
    );
  };

  return (
    <Modal
      open={modalOpenStatus}
      onClose={closeModal}
      title="Create new project"
      reducePadding
      fullWidth
      maxWidth="xs"
      footer={
        <>
          {loading && <CircularProgress />}
          <CancelButton onClick={closeModal} disabled={loading} />
          <CreateButton
            color="primary"
            disabled={!isValid || loading}
            onClick={handleSubmit(submit)}
            data-cy="create-new-project"
          />
        </>
      }
    >
      <ProjectCreateForm control={control} disabled={loading} />
    </Modal>
  );
};

export default ProjectCreateModal;
