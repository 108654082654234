import React, { useState } from 'react';

const EvolveMembersContext = React.createContext();

const EvolveMembersProvider = ({ children }) => {
  const [selected, setSelected] = useState([]);

  const selectedStateObj = React.useMemo(
    () => ({
      selected,
      setSelected,
    }),
    [selected, setSelected],
  );

  return (
    <EvolveMembersContext.Provider value={selectedStateObj}>
      {children}
    </EvolveMembersContext.Provider>
  );
};

const useEvolveMembersContext = () => {
  const context = React.useContext(EvolveMembersContext);
  if (context === undefined) {
    throw new Error('useEvolveMembersContext must be used within a EvolveMembersContext');
  }
  return context;
};

export { EvolveMembersContext, EvolveMembersProvider, useEvolveMembersContext };
