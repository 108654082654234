import * as React from 'react';

import { NumberForesiteField } from 'components/FormComponents/NumberForesiteField';

export const QTYNumberInput = ({ name = 'unitQuantity', placeholder = 'Qty required', control, ...rest }) => {
  const handleMouseUp = (event) => {
    setTimeout(() => event.target.focus(), 300);
    event.stopPropagation();
  };

  return (
    <NumberForesiteField
      name={name}
      placeholder={placeholder}
      size="extra-small"
      color="secondary"
      control={control}
      sx={{ margin: 0 }}
      InputProps={{
        style: { fontSize: 14 },
        inputProps: {
          min: 0,
          max: 999999,
        },
      }}
      InputLabelProps={{ shrink: false }}
      maxLength={6}
      onMouseUp={handleMouseUp}
      {...rest}
    />
  );
};
