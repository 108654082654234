import React, { memo } from 'react';

import { Box } from '@mui/material';

import { useTableRowContext } from '../context/TableRowContext';

const UnitQuantityCell = memo(() => {
  const { item } = useTableRowContext();

  return <Box sx={{ height: '100%', pt: '6px' }}>{String(item?.unityQuantity || '')}</Box>;
});

export default UnitQuantityCell;
