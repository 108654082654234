import { WorkRequest } from './columns/WorkRequest';
import { WorkRequestResponsive } from './columns/WorkRequestResponsive';

const WorkRequestTableColumns = (handleCellComponent, isResponsiveWR) => {
  if (isResponsiveWR) {
    return WorkRequestResponsive(handleCellComponent);
  }

  return WorkRequest(handleCellComponent);
};

export default WorkRequestTableColumns;
