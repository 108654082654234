export const sortWorkRequestList =
  (setOrderBy, workRequestsList, setWorkRequestsList) => (sortModel) => {
    const attachmentSort = sortModel.find((model) => model.field === 'workRequestId');

    if (!attachmentSort && sortModel.length) {
      setOrderBy({
        field: sortModel[0].field,
        sort: sortModel[0].sort,
      });
    }
    if (attachmentSort?.sort === 'asc') {
      const listToSort = [...workRequestsList];
      listToSort.sort((firstItem, secondItem) => firstItem.attachment - secondItem.attachment);
      setWorkRequestsList(listToSort);
    }
    if (attachmentSort?.sort === 'desc') {
      const listToSort = [...workRequestsList];
      listToSort.sort((firstItem, secondItem) => secondItem.attachment - firstItem.attachment);
      setWorkRequestsList(listToSort);
    }
  };
