import React from 'react';

import { Stack } from '@mui/material';

import UnitOfMeasureCodeField from './UnitOfMeasureCodeField';
import { useUnitOfMeasureContext } from './UnitOfMeasureContext';
import UnitOfMeasureField from './UnitOfMeasureField';
import UnitOfMeasureTypeField from './UnitOfMeasureTypeField';

const UnitOfMeasure = () => {
  const { newUnitOfMeasure } = useUnitOfMeasureContext();

  return (
    <Stack direction="row" sx={{ gap: 2 }}>
      <Stack sx={{ gap: 2, flex: 1 }}>
        <UnitOfMeasureField name="unitOfMeasureId" />
        {newUnitOfMeasure && <UnitOfMeasureTypeField name="unitOfMeasureTypeId" />}
      </Stack>
      <UnitOfMeasureCodeField name="unitOfMeasureCode" size="small" />
    </Stack>
  );
};

export default UnitOfMeasure;
