import * as React from 'react';

import SimpleControlledAutoComplete from 'components/FormComponents/SimpleControlledAutoComplete';

import { validateMaxLengthRule } from './validateRule';

export const ManufacturersAutocomplete = ({ control, options = [], ...rest }) => (
  <SimpleControlledAutoComplete
    freeSolo
    fullWidth
    margin="dense"
    variant="outlined"
    color="secondary"
    size="small"
    placeholder="Manufacturer"
    name="manufacturer"
    rules={{
      required: '*Required',
      validate: (value) => validateMaxLengthRule(value, 64),
    }}
    control={control}
    options={options}
    sx={{
      margin: 0,
      height: 24,
      '& .MuiInputBase-root': {
        height: 24,
        fontSize: 14,
      },
      '& .MuiAutocomplete-input': {
        position: 'relative',
        top: -6,
        paddingRight: '24px !important',
      },
      '& .MuiFormHelperText-root.Mui-error': {
        textAlign: 'end',
      },
    }}
    style={{ width: '100%' }}
    {...rest}
  />
);
