import { NEW_ITEM_ROW_ID } from 'constants/globalConstants';

export const maxLengthValidator = (value, maxLength) => {
  const currentLength = value?.toString().length;
  if (currentLength >= maxLength) {
    return `${currentLength}/${maxLength}`;
  }

  return null;
};

export const validateMaxLengthRule = (value = '', max = 32, showLabelAtLimit = false) => {
  const showLabel = showLabelAtLimit ? 1 : 0;
  const currentLength = value?.toString().length;
  if (currentLength > max - showLabel) return `${currentLength}/${max}`;
  return null;
};

export const noNegativeNumbersValidator = (value, maxLength = 6) => {
  if (!value) return null;
  if (Number.isNaN(parseInt(value, 10))) return 'Invalid';
  if (parseInt(value, 10) < 0) return 'Invalid';
  return maxLengthValidator(value, maxLength);
};

export const checkSelectedManufacturer = (value, manufacturer) => {
  if (!value) return false;
  if (typeof value === 'object') return value?.id !== manufacturer?.id;

  return ![manufacturer?.id, manufacturer?.label].includes(value);
};

export const NEW_ITEM_ROW = (
  lineItemName = '',
  lineItemTypeId,
  parentLineItemId,
  manufacturerCatalogNumber = '---',
) => [{ lineItemId: NEW_ITEM_ROW_ID, lineItemName, manufacturerCatalogNumber, lineItemTypeId, parentLineItemId }];

export const parseCatalogNumber = (str = '') => (`${str ?? ''}`.toString().length === 0 ? '---' : str);
