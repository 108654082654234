import { useCallback, useEffect, useMemo, useState } from 'react';

import { CombinedDocumentType } from 'hooks/usePaginatedDocuments';
import useWorkOrdersDocuments from 'hooks/useWorkOrderDocuments';
import useWorkRequestDocuments from 'hooks/useWorkRequestDocuments';

import useActiveDocument from './taskDocuments/useActiveDocument';
import useGetPreSignedUrl from './taskDocuments/useGetPreSignedUrl';
import useCommonDocuments from './useCommonDocuments';
import useDocumentTypes from './useDocumentTypes';
import useDrawingDocuments from './useDrawingDocuments';

const getFirstDetailedDoc = (allDocs: CombinedDocumentType[]): CombinedDocumentType | undefined =>
  allDocs?.find((doc) => !doc.externalUrl);

export default function useTaskDocuments(taskId: string, workRequestId: string, workOrderId: string) {
  const [activeDocument, setActiveDocument] = useState<CombinedDocumentType>();
  const { drawingModelCNCIds } = useDocumentTypes();

  const { getDocumentPreSignedUrl, getUrlLoading } = useGetPreSignedUrl();

  const useWorkRequestDocumentsHook = useWorkRequestDocuments(workRequestId);
  const useWorkOrdersDocumentsHook = useWorkOrdersDocuments(workOrderId);

  const { drawingDocuments, loadingDrawingDocuments, drawingPaginationWaypoint } = useDrawingDocuments(taskId);
  const { commonDocuments, loadingCommonDocuments, commonPaginationWaypoint } = useCommonDocuments(taskId);

  const {
    workRequestDocuments,
    loading: loadingWRDocuments,
    paginationWaypoint: WRPaginationWaypoint,
  } = useWorkRequestDocumentsHook;

  const {
    workOrderDocuments,
    loading: loadingWODocuments,
    paginationWaypoint: WOPaginationWaypoint,
  } = useWorkOrdersDocumentsHook;

  const taskWRWODocs = useMemo(
    () => [...(commonDocuments || []), ...(workOrderDocuments || []), ...(workRequestDocuments || [])],
    [commonDocuments, workOrderDocuments, workRequestDocuments],
  );

  const allDocuments = useMemo(
    () => [...(drawingDocuments || []), ...(taskWRWODocs || [])],
    [drawingDocuments, taskWRWODocs],
  );

  const { selectedIndexTable, handleSelectNextFile, handleSelectPrevFile, setSelectedIndexTable, findNextDocIndex } =
    useActiveDocument(drawingDocuments, taskWRWODocs, activeDocument, setActiveDocument);

  const handleSelectNextDetailedDoc = useCallback(() => {
    const deletedIndex =
      taskWRWODocs.findIndex((doc) => {
        if (!activeDocument?.externalUrl) return doc.documentId === activeDocument?.documentId;
        return doc.externalUrl === activeDocument?.externalUrl && doc.createdOn === activeDocument?.createdOn;
      }) || 0;

    const nextDocIndex = findNextDocIndex(taskWRWODocs, deletedIndex + 1);

    if (nextDocIndex !== -1) return setActiveDocument(taskWRWODocs[nextDocIndex]);
    return setActiveDocument(undefined);
  }, [
    activeDocument?.createdOn,
    activeDocument?.documentId,
    activeDocument?.externalUrl,
    findNextDocIndex,
    taskWRWODocs,
  ]);

  const loadingDocs = useMemo(
    () => loadingDrawingDocuments || loadingWRDocuments || loadingWODocuments || loadingCommonDocuments,
    [loadingDrawingDocuments, loadingWRDocuments, loadingWODocuments, loadingCommonDocuments],
  );

  useEffect(() => {
    if (activeDocument || loadingDocs || !allDocuments.length) return;

    const firstDetailedDoc = getFirstDetailedDoc(allDocuments);

    if (firstDetailedDoc) {
      setActiveDocument(firstDetailedDoc);
      const selectedType: 'drawings' | 'tasks' = drawingModelCNCIds?.includes(firstDetailedDoc.documentTypeId as string)
        ? 'drawings'
        : 'tasks';

      setSelectedIndexTable(selectedType);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allDocuments, loadingDrawingDocuments]);

  return {
    handleSelectNextDetailedDoc,

    drawingDocuments,
    commonDocuments,
    workOrderDocuments,
    workRequestDocuments,

    activeDocument,
    selectedIndexTable,
    handleSelectNextFile,
    handleSelectPrevFile,
    setSelectedIndexTable,
    findNextDocIndex,
    setActiveDocument,

    getUrlLoading,
    getDocumentPreSignedUrl,

    drawingPaginationWaypoint,
    commonPaginationWaypoint,
    WRPaginationWaypoint,
    WOPaginationWaypoint,

    loadingDrawingDocuments,
    loadingCommonDocuments,
    loadingCommonWRWOFiles: loadingCommonDocuments || loadingWRDocuments || loadingWODocuments,
    isDocumentsEmpty: !loadingDocs && !allDocuments.length,
  };
}
