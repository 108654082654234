import React, { useState } from 'react';

const CatalogSelectedPartContext = React.createContext();

const CatalogSelectedPartProvider = ({ children }) => {
  const [selectedPart, setSelectedPart] = useState(null);

  const valueObj = { selectedPart, setSelectedPart };
  return (
    <CatalogSelectedPartContext.Provider value={valueObj}>
      {children}
    </CatalogSelectedPartContext.Provider>
  );
};

const useCatalogSelectedPartContext = () => {
  const context = React.useContext(CatalogSelectedPartContext);
  if (context === undefined) {
    throw new Error(
      'useCatalogSelectedPartContext must be used within an CatalogSelectedPartContext',
    );
  }
  return context;
};

export { CatalogSelectedPartContext, CatalogSelectedPartProvider, useCatalogSelectedPartContext };
