import React from 'react';

import { Stack, Typography } from '@mui/material';
import { Handle } from 'react-flow-renderer';

import { NODE_HANDLERS, NODE_HANDLERS_TYPES } from './constants';
import { INodeHandlers } from './NodeTypes/@types/nodeHandlersTypes';

const NodeHandlers: React.FC<INodeHandlers> = ({
  isHovered = false,
  showLabels = false,
}: INodeHandlers) => (
  <>
    {NODE_HANDLERS.map(({ type, id, position, handleStyle, style }: any) => (
      <Stack
        data-testid="nodeHandler"
        data-cy={`handle-${type}`}
        key={id}
        style={{ visibility: isHovered ? 'visible' : 'hidden' }}
      >
        {showLabels && (
          <Typography
            data-testid="nodeHandlerLabel"
            style={{ position: 'absolute', fontSize: '12px', ...style }}
          >
            {type === NODE_HANDLERS_TYPES.SOURCE ? 'Finish' : 'Start'}
          </Typography>
        )}
        <Handle
          {...{ type, id, position }}
          style={{
            ...handleStyle,
            borderRadius: 2,
            zIndex: 999,
            borderColor: 'rgba(49, 22, 126, 1)',
            backgroundColor: '#FFF',
          }}
        />
        <Handle
          {...{ type, id, position }}
          style={{
            zIndex: 998,
            // Make the handle invisible and increase the touch area
            background: 'transparent',
            border: 'none',
            width: '20px',
            height: '20px',
          }}
        />
      </Stack>
    ))}
  </>
);

export default NodeHandlers;
