import React, { useCallback, useEffect } from 'react';

import { useFieldArray, useFormContext } from 'react-hook-form';
import { Waypoint } from 'react-waypoint';

import { useCatalogSelectedPartContext } from 'modules/Materials/CatalogSetup/Providers/CatalogSelectedPartProvider';

import AttributeSelect from './AttributeSelect';
import { ATTRIBUTE_TYPE, getAttributePartFormDefaultValue } from './AttributeSelectHelper';

const cleanUpAttribute = (list = []) =>
  list.map((item) => {
    const clone = { ...item };
    delete clone.id;
    return clone;
  });

const Attributes = ({ attributes, paginationHandler, selectedCategoryId }) => {
  const { control, setValue, watch } = useFormContext();
  const { fields } = useFieldArray({ name: 'partAttributeVariantData', control });
  const { selectedPart } = useCatalogSelectedPartContext();
  const partAttributeVariantDataWatch = watch('partAttributeVariantData');

  const getSelectedPartAttributeVariants = useCallback(
    (attribute) => {
      const attributeType = attribute?.partAttributeType?.partAttributeTypeName;
      const attributeVariantData = partAttributeVariantDataWatch.find(
        (item) => item.partAttributeId === attribute?.partAttributeId,
      );

      if (attributeVariantData) {
        return attributeVariantData;
      }

      const attributeVariant = selectedPart?.attriubuteVariantData.find(
        (item) => item.partAttributeId === attribute?.partAttributeId,
      );

      if (attributeVariant) {
        const selectedVariants = attributeVariant.selectValues.map((attributeVariant) => ({
          id: attributeVariant.partAttributeSelectVariantId,
          label: attributeVariant?.textValue,
          code: attributeVariant?.codeValue,
        }));

        return {
          ...getAttributePartFormDefaultValue(attribute),
          selectedVariants:
            attributeType === ATTRIBUTE_TYPE.singleSelect ? selectedVariants?.[0] : selectedVariants,
        };
      }

      return getAttributePartFormDefaultValue(attribute);
    },
    [partAttributeVariantDataWatch, selectedPart],
  );
  const getSelectedAttributeVariants = useCallback(
    (attribute) => {
      const findAttribute = partAttributeVariantDataWatch.find(
        (item) => item.partAttributeId === attribute?.partAttributeId,
      );

      if (!findAttribute) {
        return getAttributePartFormDefaultValue(attribute);
      }

      return findAttribute;
    },
    [partAttributeVariantDataWatch],
  );

  useEffect(() => {
    if (attributes && attributes.length) {
      const partAttributeVariantData = attributes.map((attribute) =>
        selectedPart
          ? getSelectedPartAttributeVariants(attribute)
          : getSelectedAttributeVariants(attribute),
      );

      const hasSameVariants =
        JSON.stringify(cleanUpAttribute(partAttributeVariantData)) ===
        JSON.stringify(cleanUpAttribute(partAttributeVariantDataWatch));

      if (!hasSameVariants) setValue('partAttributeVariantData', partAttributeVariantData);
    }
  }, [
    attributes,
    selectedPart,
    getSelectedAttributeVariants,
    getSelectedPartAttributeVariants,
    setValue,
    partAttributeVariantDataWatch,
  ]);

  return (
    <>
      {fields.map((item, index) => (
        <AttributeSelect key={item.id} attribute={item} index={index} />
      ))}
      <Waypoint
        bottomOffset="-20%"
        onEnter={
          attributes.length === 1
            ? undefined
            : () =>
                paginationHandler(attributes.length, {
                  partCategoryId: selectedCategoryId,
                })
        }
      />
    </>
  );
};

export default Attributes;
