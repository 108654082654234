import React from 'react';

import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

type Props = { date: string };

const NeedByDate = ({ date }: Props) => {
  const convertedDate = dayjs(`${date}Z`);
  if (!convertedDate.isValid()) return <></>;
  return <Typography variant="body2">{convertedDate.local().format('MM/DD/YYYY')}</Typography>;
};

export default NeedByDate;
