import { gql, useQuery } from '@apollo/client';

import { locationListByWorkPhase as LOCATION_LIST } from 'graphql/queries';
import { clone, recursiveUpdateNode } from 'helpers/arrayFunctions';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

export default function useLocationsByWorkPhasesDataLayer(errorCallback = () => {}) {
  const { handleResponse } = useGraphqlResponseHandler();
  const { loading, refetch } = useQuery(gql(LOCATION_LIST), {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  const fetchLocationListByWorkPhase = async (
    projectId,
    workPhase = {},
    setTableRows = () => {},
  ) => {
    const { workPhaseId } = workPhase;
    handleResponse(
      refetch,
      { query: { projectId, workPhaseId } },
      { successMessage: '' },
      (response) => {
        if (response?.locationListByWorkPhase?.length) {
          workPhase.isOpen = true;
          workPhase.children = clone(response.locationListByWorkPhase);
          setTableRows((prev) => recursiveUpdateNode(workPhase, prev));
        }
      },
      () => errorCallback(),
    );
  };

  const fetchLocationList = async (projectId, workPhase = {}) => {
    const { workPhaseId, id } = workPhase;
    return refetch({ query: { projectId, workPhaseId: workPhaseId || id } });
  };

  return {
    fetchLocationList,
    fetchLocationListByWorkPhase,
    loadingLocationList: loading,
  };
}
