import React, { useState, useEffect } from 'react';

import { gql } from '@apollo/client';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { departmentUsers as DEPARTMENT_USERS } from 'graphql/queries';
import useLazySearchableQuery from 'hooks/useLazySearchableQuery';

import { DEPARTMENT_USER_INCLUDED_ADMIN_OPTIONS } from './constants/constants';

const AdminContext = React.createContext();

const AdminProvider = ({ children }) => {
  const { selectedItem, shopDepartmentId } = useFacilitiesProjects();

  const [selected, setSelected] = useState([]);
  const [showDeletedUsers, setShowDeletedUsers] = useState(false);
  const [inviteModalShown, setInviteModalShown] = useState(false);

  const openInviteModal = () => setInviteModalShown(true);
  const closeInviteModal = () => setInviteModalShown(false);

  const [{ lazyLoad, searchHandler, sortHandler, paginationHandler }, { data, loading, called }] =
    useLazySearchableQuery(gql(DEPARTMENT_USERS), {
      extraVars: {
        departmentId: shopDepartmentId,
        includeDeleted: showDeletedUsers ? 'YES' : 'NO',
        includeAdmin: DEPARTMENT_USER_INCLUDED_ADMIN_OPTIONS.ONLY_ADMIN,
      },
      fetchPolicy: 'cache-and-network',
    });

  useEffect(() => {
    if (!called) lazyLoad();
  }, [lazyLoad, called]);

  useEffect(() => {
    lazyLoad(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDeletedUsers, selectedItem]);

  return (
    <AdminContext.Provider
      value={{
        selected,
        setSelected,
        showDeletedUsers,
        setShowDeletedUsers,
        paginationHandler,
        searchHandler,
        sortHandler,
        loading,
        data,
        inviteModalShown,
        openInviteModal,
        closeInviteModal,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

const useAdminContext = () => {
  const context = React.useContext(AdminContext);
  if (context === undefined) {
    throw new Error('useAdminContext must be used within an AdminContext');
  }
  return context;
};

export { AdminContext, AdminProvider, useAdminContext };
