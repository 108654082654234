import { useCallback } from 'react';

import { useFormContext } from 'react-hook-form';

const useDynamicWatch = (dynamicFieldNames: string[] | string | any): any => {
  const { watch } = useFormContext();

  const watchedValues: any = useCallback(
    () => watch(dynamicFieldNames),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dynamicFieldNames],
  );

  return watchedValues();
};

export default useDynamicWatch;
