import React from 'react';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useLocation } from 'react-router-dom';

import StyledLink from 'components/StyledLink';

const CustomersTableRow = ({ company }) => {
  const location = useLocation();

  return (
    <TableRow key={company.companyId} role="checkbox" hover tabIndex={-1}>
      <TableCell align="left">
        <StyledLink
          label={company.companyName}
          linkState={company.companyName}
          companyId={company.companyId}
          url={`${location.pathname}/${company.companyId}`}
        />
      </TableCell>
      <TableCell align="left">{company.netsuiteId || '--'}</TableCell>
    </TableRow>
  );
};

export default CustomersTableRow;
