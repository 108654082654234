import React from 'react';

import { Stack } from '@mui/material';

import SelectProjectMessage from '../WorkRequestsList/SelectProjectMessage';

const NoRowsOverlay = ({ message }) => (
  <Stack
    sx={{
      width: '100%',
      height: '100%',
      marginTop: '25vh',
    }}
  >
    <SelectProjectMessage message={message} />
  </Stack>
);

export default NoRowsOverlay;
