import React from 'react';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { StatusCell, ThemedCheckBox } from 'components';
import MoreOptionsDropdown from 'components/TableComponents/MoreOptionsDropdown/MoreOptionsDropdown';

import MoreTableRowOptions from './MoreTableRowOptions';

const Row = ({ departmentUser, handleRowClick, isItemSelected }) => (
  <TableRow
    key={departmentUser.user.userId}
    role="checkbox"
    hover
    tabIndex={-1}
    aria-checked={isItemSelected}
    selected={isItemSelected}
  >
    <TableCell padding="checkbox">
      <ThemedCheckBox
        checked={isItemSelected}
        inputProps={{ 'aria-labelledby': departmentUser.user.userId }}
        onClick={(e) => handleRowClick(e, departmentUser.user)}
      />
    </TableCell>
    <TableCell align="left">{departmentUser.user.userIdentifier || '--'}</TableCell>
    <TableCell align="left">{`${departmentUser.user.userFirstName || '--'} ${
      departmentUser.user.userLastName || '--'
    }`}</TableCell>
    <TableCell align="left">{departmentUser.user.userEmail || '--'}</TableCell>
    <TableCell align="left">--</TableCell>
    <TableCell align="left">{departmentUser.user.workPhoneNumber || '--'}</TableCell>
    <TableCell align="left">{departmentUser.user.personalPhoneNumber || '--'}</TableCell>
    <StatusCell
      status={
        departmentUser.isDeleted || departmentUser.user.isDeleted
          ? 'Deleted'
          : departmentUser.inviteStatus
      }
    />
    <TableCell>
      <MoreOptionsDropdown
        optionsRenderer={(onClose) => (
          <MoreTableRowOptions
            departmentUserId={departmentUser.departmentUserId}
            userStatus={departmentUser.inviteStatus}
            onClose={onClose}
          />
        )}
      />
    </TableCell>
  </TableRow>
);

export { Row as TableRow };
