/* eslint-disable max-lines-per-function */
import React, { useCallback, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { getParamsWihNewValue } from 'helpers/routeFunctions';
import useQuery from 'hooks/useQuery';
import useQueryParamMemoized from 'hooks/useQueryParamMemoized';

import { useAttributeContext } from '../../AttributesSection/AttributesContext';
import { usePartAPIContext } from '../../Providers/PartAPIProvider';
import { useCategoriesContext } from '../CategoriesContext';
import CategoryDelete from '../CategoryDelete/CategoryDelete';
import CategoryForm from '../CategoryForm';
import CategoryItem from '../CategoryItem';
import CategoryListItemMenu from './CategoryListItemMenu';

const CategoryListItem = ({
  depth = 0,
  isInHiearachy = false,
  category,
  renderList,
  fetchCategories,
  refetchCategories,
  refetchParentCategory,
  showMenu,
  ...rest
}) => {
  const { partCategoryId, subNodes, partCategoryName } = category;
  const [menuVisible, setMenuVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [hovered, setHovered] = useState(false);
  const [addingSubcategory, setAddingSubcategory] = useState(false);
  const [editingSubcategory, setEditingSubcategory] = useState(false);
  const [opened, setOpened] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const {
    setSelectedCategory: selectCategoryInContext,
    selectedCategory: selectedCategoryInContext,
    activeInput,
    setActiveInput,
    lastAddedCategoryParentId,
  } = useCategoriesContext();
  const navigate = useNavigate();
  const query = useQuery();
  const partCategoryIdParam = useQueryParamMemoized('partCategoryId');
  const { setAttributesModalOpen, attributes } = useAttributeContext();

  const { data: partItems, loadParts } = usePartAPIContext();

  useEffect(() => {
    if (depth === 0 || isInHiearachy) {
      setOpened(true);
    }
  }, [depth, isInHiearachy]);

  const handleCloseSubcategory = useCallback(() => {
    setAddingSubcategory(false);
    setOpened(false);
    setOpened(true);
    if (subNodes?.length <= 0) fetchCategories();
  }, [setAddingSubcategory, setOpened, subNodes?.length, fetchCategories]);

  const handleCloseRename = useCallback(() => {
    setEditingSubcategory(false);
    fetchCategories();
  }, [setEditingSubcategory, fetchCategories]);

  const handleCloseNewCategory = useCallback(() => {
    setActiveInput(false);
    setOpened(false);
    setOpened(true);
    if (subNodes?.length <= 0) fetchCategories();
  }, [setActiveInput, subNodes?.length, fetchCategories]);

  useEffect(() => {
    if (lastAddedCategoryParentId === category?.partCategoryId) setOpened(true);
  }, [lastAddedCategoryParentId, category?.partCategoryId]);

  useEffect(() => {
    if (!selectCategoryInContext?.partCategoryId) return;
    loadParts({
      partCategoryId: selectCategoryInContext.partCategoryId,
      orderBy: 'dateadded:desc',
    });
  }, [selectCategoryInContext, loadParts]);

  const onMenuClick = (e) => {
    e.stopPropagation();
    setMenuVisible(true);
    setAnchorEl(e.currentTarget);
  };

  const shouldRenderList = (opened && subNodes?.length > 0) || (depth === 0 && activeInput);
  const isAddingSubcategory = addingSubcategory && opened;
  const isAddingSubcategoryToMainCategory = depth === 0 && activeInput;
  const isCategoryItemSelected = selectedCategoryInContext?.partCategoryId === partCategoryId;
  const hasAttributesOrItems =
    subNodes?.length > 0 || attributes?.length > 0 || partItems?.parts?.length > 0;
  const variantSelected = !showMenu && isCategoryItemSelected;

  useEffect(() => {
    if (partCategoryIdParam === category.partCategoryId) selectCategoryInContext(category);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partCategoryIdParam]);

  return (
    <>
      <CategoryItem
        {...rest}
        data-cy={`partCategory-${partCategoryId}`}
        key={partCategoryId}
        selected={isCategoryItemSelected && showMenu}
        onClick={() => {
          const newURL = getParamsWihNewValue(query, { partCategoryId: category.partCategoryId });
          navigate(newURL);
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <CategoryItem.Content sx={{ paddingLeft: `${depth * 32}px` }}>
          <CategoryItem.Icon
            onClick={(e) => {
              e.stopPropagation();
              setOpened((prev) => !prev);
            }}
            opened={opened}
            visible={subNodes?.length > 0}
          />
          {!editingSubcategory && (
            <CategoryItem.Text
              sx={{
                color: variantSelected ? 'secondary.main' : 'rgba(0, 0, 0, 0.87)',
                fontWeight: variantSelected ? 'bold' : 'normal',
              }}
            >
              {partCategoryName}
            </CategoryItem.Text>
          )}
          {editingSubcategory && (
            <CategoryForm
              isEditing
              depth={depth}
              parentCategory={category}
              onClose={handleCloseRename}
            />
          )}
          {hovered && showMenu && <CategoryItem.Menu onClick={onMenuClick} />}
          <CategoryListItemMenu
            depth={depth}
            anchorEl={anchorEl}
            menuVisible={menuVisible}
            setAddingSubcategory={setAddingSubcategory}
            setMenuVisible={setMenuVisible}
            setOpened={setOpened}
            setEditingSubcategory={setEditingSubcategory}
            setAttributesModalOpen={setAttributesModalOpen}
            setOpenModalDelete={setOpenModalDelete}
          />
        </CategoryItem.Content>
      </CategoryItem>
      {shouldRenderList && renderList()}
      {isAddingSubcategory && (
        <CategoryFormAdd depth={depth} category={category} onClose={handleCloseSubcategory} />
      )}
      {isAddingSubcategoryToMainCategory && (
        <CategoryFormAdd depth={depth} category={category} onClose={handleCloseNewCategory} />
      )}
      <CategoryDelete
        openModal={openModalDelete}
        setOpenModal={setOpenModalDelete}
        partCategory={category}
        partCategoryId={partCategoryId}
        partCategoryName={partCategoryName}
        refetchCategories={refetchCategories}
        refetchParentCategory={refetchParentCategory}
        hasAttributesOrItems={hasAttributesOrItems}
      />
    </>
  );
};

export default CategoryListItem;

const CategoryFormWrapper = ({ depth, children }) => (
  <CategoryItem sx={{ paddingTop: '10px', paddingBottom: '10px', backgroundColor: '#f0f0f0' }}>
    <CategoryItem.Content sx={{ ml: `${48 + depth * 32}px` }}>{children}</CategoryItem.Content>
  </CategoryItem>
);
const CategoryFormAdd = ({ depth, category, onClose }) => (
  <CategoryFormWrapper depth={depth}>
    <CategoryForm depth={depth} parentCategory={category} onClose={onClose} />
  </CategoryFormWrapper>
);
