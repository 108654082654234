/* eslint-disable max-lines-per-function */
import React, { useState, memo, useEffect, useCallback } from 'react';

import ComponentPaneContent from 'app/Layout/ReactGridLayout/ComponentPaneContent';
import ItemsDataGridPro, { ItemsDataGridProProvider } from 'components/ItemsDatagridPro';
import StackRow from 'components/StackRow';
import { useWorkOrder } from 'modules/Shop/WorkOrders/hooks/useWorkOrder';

import { getTableStyles } from '../FieldWorkRequestConstants';
import useWorkRequestHelpers from '../hooks/useWorkRequestHelpers';
import CustomDetailPanelToggle from './components/CustomDetailPanelToggle';
import StatusPoint from './components/StatusPoint';
import NoRowsOverlay from './NoRowsOverlay';
import { useWorkRequestDragDrop } from './WorkRequestDragDropProvider';
import WorkRequestItemName from './WorkRequestItemName';
import { useWorkRequestItemsContext } from './WorkRequestItemsContext';
import WorkRequestLists from './WorkRequestLists';
import WorkRequestMoreOptions from './WorkRequestMoreOptions';
import WorkRequestRowProvider from './WorkRequestRowProvider';
import WorkRequestTableColumns from './WorkRequestTableColumns';
import WorkRequestTableRow from './WorkRequestTableRow';

const tableProps = {
  disableColumnMenu: true,
  disableSelectionOnClick: true,
  getRowId: (row) => row?.workRequestItemId,
  rowHeight: 32,
  sx: getTableStyles(),
  rowThreshold: 0,
  components: {
    NoRowsOverlay: () => <NoRowsOverlay message="Add an item to begin." />,
    Row: (data) => <WorkRequestRowProvider rowData={data} />,
  },
};

const WorkRequestTable = ({
  workRequest,
  workRequestItems,
  modalToDelete,
  openModalToDelete,
  openModalToCreateCatalogPart,
  openModalUom,
  status,
  isResponsiveWR,
  canEditRowToWO = false,
}) => {
  const {
    setWorkRequestStatus,
    isReadOnly,
    inWorkOrders,
    setWorkRequest,
    fetchMore,
    uomOptions,
    visibleInputs,
    setVisibleInputs,
    isEditingItem,
    setIsEditingItem,
    apiRef,
    setWorkRequestItemsSelected,
  } = useWorkRequestItemsContext();
  const { workOrder } = useWorkOrder();

  const { isDragging } = useWorkRequestDragDrop();

  const { WOIsReleased } = useWorkRequestHelpers();
  const [idRowEditing, setIdRowEditing] = useState(0);

  useEffect(() => {
    if (status) setWorkRequestStatus(status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (workRequest) setWorkRequest(workRequest);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workRequest]);

  const closeUpdateItem = useCallback(() => {
    if (idRowEditing !== 0) apiRef.current.updateRows([{ workRequestItemId: idRowEditing, isEditing: false }]);
    setIsEditingItem(false);
  }, [apiRef, idRowEditing, setIsEditingItem]);

  useEffect(() => {
    if (!isEditingItem) closeUpdateItem();
  }, [isEditingItem, closeUpdateItem]);

  const handleUpdateRow = (row) => {
    if (row.workRequestItemId !== 0 && !visibleInputs) {
      apiRef.current.updateRows([{ ...row, isEditing: true }]);
      setIsEditingItem(true);
      setIdRowEditing(row.workRequestItemId);
    }
  };

  const handleCellComponent = ({ id, field, hasFocus, row, value }) => {
    if (id !== 0 && field === '__detail_panel_toggle__') {
      if (row.isEditing) return <></>;
      return <CustomDetailPanelToggle id={id} value={value} sx={{ padding: 0 }} />;
    }

    if (field === 'workRequestItemStatusTypeId')
      return (
        <StackRow sx={{ width: '100%', justifyContent: 'center', height: '100%' }}>
          {WOIsReleased(workRequest || workOrder) && row.hasShopTask && <StatusPoint statusId={value} />}
        </StackRow>
      );

    if (id === 0 || row.isEditing) {
      return (
        <WorkRequestTableRow
          visibleInputs={!row.isEditing ? visibleInputs : true}
          setVisibleInputs={setVisibleInputs}
          field={field}
          row={{ ...row, uom: value, hasFocus }}
          openModalUom={openModalUom}
          workRequest={workRequest}
        />
      );
    }

    if (field === 'unitOfMeasureId') {
      return (
        <WorkRequestMoreOptions
          uomOptions={uomOptions}
          isReadOnly={isReadOnly}
          value={value}
          row={row}
          workRequestItemTypeId={row?.workRequestItemTypeId}
          openSendItemToPCModal={() => openModalToCreateCatalogPart(row)}
          modalToDelete={modalToDelete}
          openModalToDelete={() => openModalToDelete(row)}
          onRenameClick={() => {
            handleUpdateRow(row);
          }}
        />
      );
    }

    if (field === 'workRequestItemName') {
      return <WorkRequestItemName row={row} />;
    }
    return null;
  };

  const columns = WorkRequestTableColumns(handleCellComponent, isResponsiveWR);

  const getWorkRequestLists = React.useCallback(
    ({ row }) => <WorkRequestLists row={row} isResponsiveWR={isResponsiveWR} parent={workRequest} />,
    [isResponsiveWR, workRequest],
  );

  const handleRowClick = React.useCallback(
    ({ row }, event) => {
      if (inWorkOrders && !canEditRowToWO) return;
      if (status && isReadOnly) {
        event.defaultMuiPrevented = true;
        return;
      }
      handleUpdateRow(row);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [status, isReadOnly, canEditRowToWO],
  );

  const getDetailPanelHeight = React.useCallback(() => 'auto', []);
  const styles = { ...getTableStyles(visibleInputs, isDragging) };
  const rowsPlusAddItemRow = (items) =>
    isReadOnly ? items : [{ workRequestItemId: 0, workRequestItemName: '' }, ...items];

  return (
    <ComponentPaneContent>
      <ItemsDataGridProProvider contextId="WorkRequestTable">
        <ItemsDataGridPro
          {...tableProps}
          sx={styles}
          apiRef={apiRef}
          onRowsScrollEnd={() => fetchMore(workRequestItems?.length)}
          columns={columns}
          rows={rowsPlusAddItemRow(workRequestItems)}
          getRowId={(row) => row?.workRequestItemId}
          experimentalFeatures={{ rowPinning: !inWorkOrders, newEditingApi: !inWorkOrders }}
          rowHeight={32}
          onRowClick={handleRowClick}
          getDetailPanelContent={getWorkRequestLists}
          getDetailPanelHeight={getDetailPanelHeight}
          onSelected={(itemsSelected) => setWorkRequestItemsSelected(itemsSelected)}
          onSelectedId="workRequestItemId"
        />
      </ItemsDataGridProProvider>
    </ComponentPaneContent>
  );
};

export default memo(WorkRequestTable);
