import React, { useState } from 'react';

import { MoreMenu } from '@evolvemep/foresite-components-ui-react';
import { Box } from '@mui/material';

import { AssemblyItemNodeIcon } from 'assets/images/nav-icons/material-icons/assembly-editor-icons';
import DeleteModal from 'components/DeleteModal';
import { Custom } from 'components/Icons';
import { errorColors, greyColors } from 'styles/theme/colors';

import NodeHandlers from '../../NodeHandlers';
import { IPartTypeNode } from './@types/partTypeNodeTypes';
import PartTypeNodeContainer from './PartTypeNodeContainer';
import PartTypeNodeFooter from './PartTypeNodeFooter';
import PartTypeNodeHeader from './PartTypeNodeHeader';
import PartTypeNodeIconContainer from './PartTypeNodeIconContainer';

const PartTypeNode: React.FC<IPartTypeNode> = ({
  id,
  data,
  showHandlers = true,
  showLabels = false,
  isAssembly = false,
  onDelete = () => null,
  showMoreMenu = true,
  moreMenuOpts = [],
  selected = false,
}: IPartTypeNode) => {
  const [isHovered, setIsHovered] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const handleModalDelete = () => {
    setIsHovered(false);
    setOpenModalDelete(true);
  };

  const options = moreMenuOpts.length
    ? moreMenuOpts
    : [{ label: 'Delete', clickEvent: handleModalDelete, color: errorColors.main }];

  const PartTypeNodeIcon = () =>
    isAssembly ? (
      <AssemblyItemNodeIcon data-testid="assemblyTypeNodeIcon" />
    ) : (
      <Custom.PartThumbnail data-testid="partTypeNodeIcon" width={24} fill={greyColors.grey400} />
    );

  return (
    <>
      <PartTypeNodeContainer
        isAssembly={isAssembly}
        setIsHovered={setIsHovered}
        selected={selected}
      >
        {isHovered && showMoreMenu && (
          <MoreMenu
            sxIconButton={{ position: 'absolute', top: 0, right: '-4px' }}
            options={options}
          />
        )}
        <PartTypeNodeIconContainer>
          {data.image ? (
            <img
              data-testid="partTypeNodeImage"
              src={data.image}
              alt={data.partName}
              width="100%"
            />
          ) : (
            <PartTypeNodeIcon />
          )}
        </PartTypeNodeIconContainer>
        <Box style={{ fontFamily: 'Roboto' }}>
          <PartTypeNodeHeader
            id={id}
            isAssembly={isAssembly}
            title={isAssembly ? 'Assembly' : 'Item'}
            nodeName={data.partName}
          />
          <PartTypeNodeFooter
            qty={data.qty || data.quantity}
            isMainPartItem={data?.isMainPartItem}
            unitOfMeasureCode={data.unitOfMeasureCode}
          />
        </Box>
        {showHandlers && <NodeHandlers showLabels={showLabels} isHovered={isHovered} />}
      </PartTypeNodeContainer>
      <DeleteModal
        open={openModalDelete}
        onClose={() => setOpenModalDelete(false)}
        onSubmit={() => onDelete(id)}
        itemName={data.partName}
        confirmText="Are you sure you want to delete"
      />
    </>
  );
};

export default PartTypeNode;
