import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import { Modal } from 'components';

import AddNewTakeOffPadNameForm from './AddNewTakeOffPadNameForm';

const Header = () => (
  <Box>
    New Takeoff Pad
    <IconButton sx={{ ml: 2 }} size="large" />
  </Box>
);

const CancelButton = ({ onClick }) => (
  <Button
    variant="text"
    onClick={onClick}
    sx={{
      fontSize: '15px',
      lineHeight: '24px',
      letterSpacing: '0.46px',
      marginTop: '30px',
      textAlign: 'right',
      color: 'secondary.main',
    }}
  >
    CANCEL
  </Button>
);

const CreateButton = ({ onClick, disabled }) => (
  <Button
    disabled={disabled}
    variant="contained"
    onClick={onClick}
    sx={{
      backgroundColor: 'secondary.main',
      fontSize: '15px',
      lineHeight: '24px',
      letterSpacing: '0.46px',
      marginTop: '30px',
      textAlign: 'right',
      '&:hover': {
        backgroundColor: 'secondary.containedHoverBackground',
      },
    }}
  >
    ADD
  </Button>
);

const AddNewTakeOffPadNameModal = ({ open, onSubmit, onCancel, control, isValid }) => (
  <Modal
    open={open}
    onClose={onCancel}
    disableModalOutsideClick
    disableTitleClose
    maxWidth="md"
    scroll="paper"
    sx={{
      '.MuiPaper-root': {
        maxHeight: '80vh',
        maxWidth: 500,
        width: 500,
      },
    }}
    titleStyles={{ fontWeight: 700 }}
    title={<Header />}
    footer={
      <>
        <CancelButton onClick={onCancel} />
        <CreateButton disabled={isValid} onClick={onSubmit} />
      </>
    }
  >
    <AddNewTakeOffPadNameForm control={control} onSubmit={onSubmit} />
  </Modal>
);

export default AddNewTakeOffPadNameModal;
