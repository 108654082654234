import React from 'react';

import WorkRequestItemAttachments from '../../Components/WorkRequestItemAttachments';

export const WorkRequestAttachments =
  () =>
  ({ value, row }) => {
    const { numberOfAttachments } = row;

    return <WorkRequestItemAttachments workRequestId={value} numberOfAttachments={numberOfAttachments} row={row} />;
  };
