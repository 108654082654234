import React, { ReactNode } from 'react';

import Typography from '@mui/material/Typography';

const NoContentMessage = ({
  text,
  variant = 'h5',
  children,
  sx,
}: Pick<React.ComponentProps<typeof Typography>, 'variant' | 'sx'> &
  // At least one of text or children must be provided
  (| {
        text?: string;
        children: ReactNode;
      }
    | {
        text: string;
        children?: ReactNode;
      }
  )) => (
  <Typography
    variant={variant}
    sx={{
      margin: '8% auto',
      color: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '24px',
      ...sx,
    }}
  >
    {text} {children}
  </Typography>
);

export default NoContentMessage;
