import { useState } from 'react';

import { gql, useMutation } from '@apollo/client';

import { getDocumentPresignedURL as DOCUMENT_PRESIGNED_URL } from 'graphql/mutations';

interface DocumentPresigned {
  getDocumentPresignedURL: {
    preSignedURL: string;
  };
}

interface UrlCache {
  [key: string]: string;
}

const useGetPreSignedUrl = () => {
  const [cachePresignedUrls, setCachePresignedUrls] = useState<UrlCache>({});

  const [fetchDocumentPresigned, { loading: getUrlLoading }] = useMutation<DocumentPresigned>(
    gql(DOCUMENT_PRESIGNED_URL),
  );

  const getDocumentPreSignedUrl = async (storagePath: string) => {
    if (cachePresignedUrls[storagePath]) return cachePresignedUrls[storagePath];
    const variables = { body: { expirationHours: 1, objectKey: storagePath, verb: 'GET' } };
    const response = await fetchDocumentPresigned({ variables });

    const presignedUrl = response?.data?.getDocumentPresignedURL?.preSignedURL || '';
    setCachePresignedUrls((prev) => ({ ...prev, [storagePath]: presignedUrl }));
    return presignedUrl;
  };

  return {
    getDocumentPreSignedUrl,
    getUrlLoading,
  };
};

export default useGetPreSignedUrl;
