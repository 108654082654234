import { gql, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { VARIANT_ERROR } from 'constants/snackbarConstants';
import {
  addWorkCellMachine as ADD_WORKCELL_MACHINE,
  mapTaskTypeToWorkCell as MAP_TASK_TYPE_TO_WORK_CELL,
  mapTaskTypeToMachine as MAP_TASK_TYPE_TO_MACHINE,
} from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

import useMachineDataLayer from '../components/MachineRow/hooks/useMachineDataLayer';
import useCachedData from './useCachedData';

export default function useDataLayer() {
  const { handleResponse } = useGraphqlResponseHandler();
  const { enqueueSnackbar } = useSnackbar();
  const { enableWorkCellTasksContainerCache, enableWorkCellMachinesContainerCache } = useCachedData();

  const { unmapWorkCellMachine, loadingUnmapWorkCellMachine } = useMachineDataLayer();

  const [mapTaskTypeToWorkCellMutation] = useMutation(gql(MAP_TASK_TYPE_TO_WORK_CELL), {
    refetchQueries: ['WorkCellTaskType'],
  });

  const [mapTaskTypeToMachineMutation] = useMutation(gql(MAP_TASK_TYPE_TO_MACHINE), {
    refetchQueries: ['MachineTaskType', 'WorkCellTaskType'],
  });

  const [mapMachineToWorkCellMutation] = useMutation(gql(ADD_WORKCELL_MACHINE), {
    refetchQueries: ['WorkCellMachine', 'Machines'],
  });

  const mapTaskTypeToWorkCell = (taskTypeId, workCellId) => {
    handleResponse(
      mapTaskTypeToWorkCellMutation,
      { variables: { body: { taskTypeId, workCellIds: [workCellId] } } },
      { successMessage: 'Task successfully mapped', errorMessage: '' },
      (result) => {
        if (result.mapTaskTypeToWorkCell) enableWorkCellTasksContainerCache(workCellId);
      },
      (error) => {
        if (error === 'This task has already been mapped to a work cell in this shop configuration')
          enqueueSnackbar(
            'Error: This task has already been mapped to a work cell in this shop configuration',
            VARIANT_ERROR,
          );
      },
    );
  };

  const mapTaskTypeToMachine = (taskTypeId, machineId, workCellId) => {
    handleResponse(
      mapTaskTypeToMachineMutation,
      { variables: { body: { taskTypeId, workCellId, machineIds: [machineId] } } },
      { successMessage: 'Task successfully mapped' },
      (result) => {
        if (result.mapTaskTypeToMachine) enableWorkCellTasksContainerCache(workCellId);
      },
    );
  };

  const mapMachineToWorkCell = async (machineId, workCellId) =>
    handleResponse(
      mapMachineToWorkCellMutation,
      { variables: { body: { workCellIds: [workCellId], machineId } } },
      { successMessage: 'Machine successfully mapped' },
      (result) => {
        if (result.addWorkCellMachine) enableWorkCellMachinesContainerCache(workCellId);
      },
    );

  const handleChangeWorkCellMachine = async (machineId, workCellMachineId, destinationWorkCellId, sourceWorkCellId) => {
    await mapMachineToWorkCell(machineId, destinationWorkCellId);
    await unmapWorkCellMachine(workCellMachineId, sourceWorkCellId, '');
  };

  return {
    mapTaskTypeToWorkCell,
    mapTaskTypeToMachine,
    mapMachineToWorkCell,
    unmapWorkCellMachine,
    handleChangeWorkCellMachine,
    loadingUnmapWorkCellMachine,
  };
}
