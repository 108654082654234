import React from 'react';

import List from '@mui/material/List';

import { useModule } from 'app/Modules/ModuleContext';
import useSetupModule from 'hooks/useSetupModule';

import NavigationSet from './NavigationSet';

const getNavigationSets = (module, data) => {
  if (module) {
    const [navigationModule] = data.filter((obj) => obj?.navigationModuleCode === module);

    return navigationModule?.navigationModuleSets;
  }

  return [];
};

const NavBarContent = () => {
  const { data, loading } = useModule();
  const { currentModule } = useSetupModule();

  const navigationSets = getNavigationSets(currentModule(), data);
  return (
    <List>
      {!loading &&
        navigationSets?.map(({ navigationModuleSetId, isLastInGroup, navigationSet }) => (
          <NavigationSet key={navigationModuleSetId} navigationSet={navigationSet} dividerAtTheBottom={isLastInGroup} />
        ))}
    </List>
  );
};

export default NavBarContent;
