import React from 'react';

import { Typography } from '@mui/material';

const PartItemDetailCategory = ({ isExistingItem, children, ...restProps }) => (
  <Typography
    sx={{
      lineHeight: '36px',
      backgroundColor: 'rgba(245, 245, 245, 1)',
      padding: '0px 8px',
      width: 'fit-content',
      ...restProps,
    }}
    variant="body2"
  >
    {isExistingItem ? 'Category' : 'Adding to'}: {children}
  </Typography>
);

export default PartItemDetailCategory;
