import React from 'react';

import { Breadcrumbs, Link } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';

import { IWorkflowsEditorBreadcrumbProps } from '../../@types/WorkfowsEditorTypes';
import WorkflowNameField from './WorkflowNameField';

const WorkflowsEditorBreadcrumb = ({ openNameField }: IWorkflowsEditorBreadcrumbProps) => {
  const { control, handleSubmit, reset } = useForm({
    mode: 'all',
    defaultValues: {
      name: 'Untitled Workflow',
    },
  });

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link underline="hover" color="inherit" component={RouterLink} to="/shop/workflows">
        BOP Calculator Workflows
      </Link>
      <WorkflowNameField
        openNameField={openNameField}
        control={control}
        onSubmit={handleSubmit(() => {})}
        onCancel={() => {
          reset({ name: 'Untitled Workflow' });
        }}
      />
    </Breadcrumbs>
  );
};

export default WorkflowsEditorBreadcrumb;
