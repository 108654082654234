import React from 'react';

import { useCustomAssemblyTypesContext } from '../CustomAssemblyTypesContext';
import { CustomAssemblyTypeRowInfo } from './CustomAssemblyTypeRowInfo';
import RenameCustomAssemblyForm from './RenameCustomAssemblyForm';

export const CustomAssemblyTypeRow = ({ params, depth = 0 }) => {
  const { renamingId } = useCustomAssemblyTypesContext();
  const node = params.row;
  return (
    <>
      {renamingId === params.row.id ? (
        <RenameCustomAssemblyForm />
      ) : (
        <CustomAssemblyTypeRowInfo node={node} depth={depth} />
      )}
    </>
  );
};
