import React from 'react';

import { Box } from '@mui/material';

import { ForgeViewer } from 'components/Autodesk';
import { TaskViewerProvider } from 'modules/Shop/Fabrication/TaskViewer/TaskViewerContext';

import ErrorDocument from './components/ErrorDocument';
import FileNotSupported from './components/FileNotSupported';
import LoadingDocument from './components/LoadingDocument';
import TableForgeContainer from './components/TableForgeContainer';
import useGetGraphicType from './hooks/useGetGraphicType';

const ForgeViewerDocument = ({ document }) => {
  const { dynamicProps, loading, error, isSupported } = useGetGraphicType(document);

  if (!isSupported)
    return (
      <TaskViewerProvider>
        <TableForgeContainer>
          <FileNotSupported />
        </TableForgeContainer>
      </TaskViewerProvider>
    );

  if (loading) return <LoadingDocument />;

  if (error) return <ErrorDocument />;

  return (
    <TableForgeContainer document={document}>
      {Boolean(dynamicProps && document) && (
        <Box
          sx={{
            position: 'relative',
            height: 'calc(100% - 32px)',
            overflow: 'hidden',
            zIndex: 0,
            '& .adsk-control .clickoff': { maxWidth: '100%' },
            '& .adsk-toolbar': { zIndex: 1 },
          }}
        >
          <ForgeViewer key={document?.documentId} {...dynamicProps} />
        </Box>
      )}
    </TableForgeContainer>
  );
};

export default ForgeViewerDocument;
