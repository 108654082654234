import React from 'react';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { StatusCell } from 'components';
// import MoreOptionsDropdown from 'components/TableComponents/MoreOptionsDropdown/MoreOptionsDropdown';
import { USER_TYPES } from 'types/userTypes';

// import MoreTableRowOptions from './MoreTableRowOptions';

const AdminTableRow = ({ member }) => (
  <TableRow key={member.userId} role="checkbox" hover tabIndex={-1}>
    <TableCell align="left">{`${member.userFirstName || '--'} ${member.userLastName || '--'}`}</TableCell>
    <TableCell align="left">{member.userEmail}</TableCell>
    <TableCell align="left">{USER_TYPES.COMPANY_ADMINISTRATOR.label}</TableCell>
    <TableCell align="left">{member.workPhoneNumber || '--'}</TableCell>
    <TableCell align="left">{member.personalPhoneNumber || '--'}</TableCell>
    <StatusCell status={member.isDeleted ? 'Deleted' : member.inviteStatus} />
    <TableCell>
      {/* <MoreOptionsDropdown
        optionsRenderer={() => <MoreTableRowOptions companyMemberIds={[member.userId]} />}
      /> */}
    </TableCell>
  </TableRow>
);
export default AdminTableRow;
