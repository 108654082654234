import React from 'react';

import { InputForesiteField } from '@evolvemep/foresite-components-ui-react';
import { Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { useUser } from 'app/UserContext';

const ValidateEmail = ({
  disabled = false,
  title = null,
  showUserEmail = false,
  label = 'Email',
  autoFocus = false,
}) => {
  const { user } = useUser();
  const {
    attributes: { email },
  } = user;
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const helperText =
    errors?.email?.type === 'validate' || errors?.email?.type === 'required' || !showUserEmail ? null : email;

  return (
    <>
      {title && <Typography variant="subtitle2">{title}</Typography>}
      <InputForesiteField
        name="email"
        control={control}
        label={label}
        rules={{
          required: 'Email doesn’t match login',
          validate: (value) => value === email || 'Email doesn’t match login',
        }}
        sx={{ marginBottom: '16px', marginTop: '8px' }}
        helperText={helperText}
        disabled={disabled}
        autoFocus={autoFocus}
        size="small"
      />
    </>
  );
};

export default ValidateEmail;
