import React from 'react';

import { CircularProgress } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import OverflowTip from 'components/Tooltip/OverflowTip';

import { ROUNDED_BORDER_STYLE } from '../constants/constants';
import { useTaskViewerContext } from '../TaskViewerContext';

const textEllipsisStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: 140,
  textAlign: 'end',
};

const TaskPropertiesContent = () => {
  const { taskProperty, loadingProperties } = useTaskViewerContext();

  if (!taskProperty || loadingProperties) return <CircularProgress color="secondary" />;

  return (
    <Stack gap="8px" sx={{ ...ROUNDED_BORDER_STYLE, mt: 1, px: 2, py: 1, minHeight: 269 }}>
      <TableResult label="Project Name" value={taskProperty.projectName} />
      <TableResult label="Work Request Id" value={taskProperty.workRequestIdentifier} />
      <TableResult label="Work Order Id" value={taskProperty.workOrderName} />
      <TableResult label="Work Order Name" value={taskProperty.workRequestName} />
      <TableResult label="Item" value={taskProperty.workRequestItemName} />
      <TableResult label="Task Name" value={taskProperty.taskName} />
    </Stack>
  );
};

export default TaskPropertiesContent;

const TableResult = ({ label, value }) => (
  <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 0.5 }}>
    <Typography variant="body2" sx={{ color: 'surface.lightSurface.secondary' }}>
      {label}
    </Typography>
    <OverflowTip title={value}>
      <Typography sx={{ ...textEllipsisStyle }} variant="body2">
        {value}
      </Typography>
    </OverflowTip>
  </Stack>
);
