import React from 'react';

import { Box } from '@mui/material';

import BOMItemsGroup from 'components/BOMItemsGroup';
import { Custom } from 'components/Icons';
import ItemsDataGridPro, { ItemsDataGridProProvider } from 'components/ItemsDatagridPro';
import { usePopoverFilterContext } from 'components/Popover/FilterPopover/context/PopoverFilterContext';
import { getDaysBetween } from 'helpers/dateFunctions';
import useDocumentTypes from 'modules/Shop/Fabrication/TaskViewer/PlansModelsContent/hooks/useDocumentTypes';
import { actionColors } from 'styles/theme/colors';

import { useWorkOrdersContext } from '../WorkOrdersContext';
import SelectFacilityMessage from './SelectFacilityMessage';
import WorkOrdersColumns from './WorkOrdersTableColumns';

const MESSAGES = {
  noFacility: 'Select a facility or project to view work orders.',
  noFacilityShop: 'This facility does not have a shop. Add a shop to continue.',
};

export const linkStyles = {
  '& .link': {
    textDecoration: 'none',
    color: 'surface.lightSurface.primary',
    '&:hover': {
      color: 'secondary.main',
      textDecoration: 'underline',
    },
  },
};

const getDaysRemaining = (row) => {
  const firstDate = new Date(`${row?.workRequest?.needBy}Z`);
  const secondDate =
    row?.workOrderStatusTypeName === 'Completed' && row?.lastStatusUpdate
      ? new Date(`${row.lastStatusUpdate}Z`)
      : new Date();
  const daysRemaining = row?.workRequest?.needBy && getDaysBetween(firstDate, secondDate);
  return daysRemaining;
};

export const mapWorkOrderDataToTableColumns = (workOrder) => {
  const daysRemaining = getDaysRemaining(workOrder);

  return {
    ...workOrder,
    needBy: workOrder.workRequest.needBy,
    daysRemaining,
    projectName: workOrder.workRequest.projectName,
    workRequestName: workOrder.workRequest.workRequestName,
  };
};

const WorkOrdersTable = () => {
  const {
    loading,
    workOrders,
    filters,
    shopDepartmentId,
    selectedItem,
    statusToFilter,
    paginationHandler,
    setSelectedWorkOrder,
    apiRef,
  } = useWorkOrdersContext();
  const { filters: filtersContext } = usePopoverFilterContext();
  const { commonTypeId } = useDocumentTypes();

  const facility = selectedItem?.type === 'FACILITY';

  const overlayMessage = (() => {
    if (!selectedItem) return MESSAGES.noFacility;
    if (facility && !shopDepartmentId) return MESSAGES.noFacilityShop;
    if (selectedItem && !workOrders?.length) {
      if (filtersContext.length) return `No Results`;
      return `${`This ${selectedItem?.type?.toLowerCase()} has no ${statusToFilter}`} work orders.`;
    }

    return null;
  })();

  const handleWorkOrderSelection = (rows) => {
    if (!rows.length) {
      setSelectedWorkOrder(null);
      return;
    }
    const selectedRow = workOrders.find(({ workOrderId }) => workOrderId === rows[0]);
    const daysRemaining = getDaysRemaining(selectedRow);

    setSelectedWorkOrder({
      ...selectedRow,
      workRequest: {
        ...selectedRow?.workRequest,
        needBy: selectedRow?.workRequest?.needBy,
      },
      daysRemaining,
    });
  };

  const getDetailPanelContent = (id) => <BOMItemsGroup workOrderId={id} />;
  const getDetailPanelHeight = () => 'auto';

  return (
    <Box sx={{ mt: '14px', mb: '0px', flexGrow: 1 }}>
      <ItemsDataGridProProvider contextId="WorkOrdersTable">
        <ItemsDataGridPro
          apiRef={apiRef}
          rowHeight={32}
          headerHeight={32}
          loading={loading}
          hideFooterPagination
          checkboxSelection={false}
          disableColumnMenu
          columns={WorkOrdersColumns(statusToFilter)}
          getRowId={(row) => row?.workOrderId}
          getDetailPanelContent={(row) => getDetailPanelContent(row?.id)}
          getDetailPanelHeight={getDetailPanelHeight}
          rows={workOrders.map(mapWorkOrderDataToTableColumns)}
          onRowsScrollEnd={() => {
            if (facility && selectedItem?.id && workOrders.length > 0) {
              paginationHandler(workOrders.length, {
                take: 50,
                facilityId: selectedItem?.id,
                ...filters,
                orderBy: 'needBy:asc',
                documentTypeIds: commonTypeId,
              });
            }
          }}
          sx={getStyles()}
          onSelectionModelChange={(rows) => handleWorkOrderSelection(rows)}
          components={{
            DetailPanelExpandIcon: () => <Custom.ArrowRightIcon />,
            DetailPanelCollapseIcon: () => <Custom.ArrowDropDownIcon color="primary" />,
            NoRowsOverlay: () => (
              <SelectFacilityMessage
                message={overlayMessage}
                icon={
                  overlayMessage === MESSAGES.noShop ? (
                    <Custom.ShopProperties />
                  ) : (
                    <Custom.WorkOrders fill={actionColors.disabled} />
                  )
                }
                sx={{
                  width: overlayMessage === MESSAGES.noShop ? 340 : 350,
                }}
              />
            ),
          }}
        />
      </ItemsDataGridProProvider>
    </Box>
  );
};

export default WorkOrdersTable;

const getStyles = () => ({
  '& .MuiDataGrid-row': {
    width: '100% !important',
  },
  '& .MuiDataGrid-row:hover': {
    backgroundColor: '#f5f5f5',
  },
  '& .MuiDataGrid-cell': {
    borderTop: 'none !important',
    borderBottom: '1px solid rgba(224,224,224,1) !important',
  },
  '& .MuiDataGrid-pinnedColumns': {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    boxShadow: 'none',
  },
  '& .MuiDataGrid-pinnedColumnHeaders': {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    boxShadow: 'none',
  },
  '& .MuiDataGrid-row.Mui-hovered': {
    backgroundColor: '#f5f5f5',
  },
  '& .MuiTextField-root': {
    margin: 0,
  },
});
