import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { useUser } from 'app/UserContext';
import {
  addAssemblyCustomCategory as ADD_CUSTOM_ASSEMBLY_TYPES,
  deleteBOMItemCustomAssembly as DELETE_BOM_ITEM_CUSTOM_ASSEMBLY,
  addBOMItemCustomAssembly as ADD_BOM_ITEM_CUSTOM_ASSEMBLY,
  deleteBulkBOMItemCustomAssembly as DELETE_BULK_BOM_ITEM_CUSTOM_ASSEMBLY,
} from 'graphql/mutations';
import {
  assemblyCustomCategories as CUSTOM_ASSEMBLY_TYPES,
  checkCustomAssemblyIdentifierUnique as CHECK_CUSTOM_ASSEMBLY_ID_UNIQUE,
  checkCustomAssemblyNameUnique as CHECK_CUSTOM_ASSEMBLY_NAME_UNIQUE,
  customAssemblies as GET_CUSTOM_ASSEMBLIES,
} from 'graphql/queries';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import { useProject } from 'modules/Field/LocationsAndWorkPhases/contexts/ProjectContext';

const mapCustomAssemblyTypeResponse = (customAssemblyType) => ({
  id: customAssemblyType.assemblyCustomCategoryId,
  type: 'CustomAssemblyType',
  identifier: customAssemblyType.assemblyCustomCategoryName,
  companyId: customAssemblyType.companyId,
  workFlowId: customAssemblyType.workFlowId,
  children: [],
});

// eslint-disable-next-line
export const useCustomAssemblyTypes = () => {
  const { handleResponse } = useGraphqlResponseHandler();
  const { user } = useUser();
  const { selectedProject } = useProject();
  const projectId = selectedProject?.locationId;
  const { enqueueSnackbar } = useSnackbar();

  const [customAssemblyTypesQuery, { data: customAssemblyTypesData, loading: loadingCustomAssemblyTypes }] =
    useLazyQuery(gql(CUSTOM_ASSEMBLY_TYPES), {
      fetchPolicy: 'no-cache',
    });

  const [addAssemblyCustomCategoryMutation, { loading: isCreatingCustomAssemblyType }] = useMutation(
    gql(ADD_CUSTOM_ASSEMBLY_TYPES),
  );

  const [deleteBulkBOMItemCustomAssemblyMutation, { loading: isDeletingBulkCustomAssembly }] = useMutation(
    gql(DELETE_BULK_BOM_ITEM_CUSTOM_ASSEMBLY),
  );

  const [deleteBOMItemCustomAssemblyMutation, { loading: isDeletingCustomAssembly }] = useMutation(
    gql(DELETE_BOM_ITEM_CUSTOM_ASSEMBLY),
  );

  const [addBOMItemCustomAssemblyMutation, { loading: isCreatingCustomAssembly }] = useMutation(
    gql(ADD_BOM_ITEM_CUSTOM_ASSEMBLY),
  );

  const [checkCustomAssemblyNameUniqueQuery, { loading: checkingCustomAssemblyNameUnique }] = useLazyQuery(
    gql(CHECK_CUSTOM_ASSEMBLY_NAME_UNIQUE),
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [checkCustomAssemblyIdentifierUniqueQuery, { loading: checkingCustomAssemblyIdUnique }] = useLazyQuery(
    gql(CHECK_CUSTOM_ASSEMBLY_ID_UNIQUE),
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [getCustomAssembliesQuery, { loading: loadingCustomAssemblies }] = useLazyQuery(gql(GET_CUSTOM_ASSEMBLIES), {
    fetchPolicy: 'cache-and-network',
  });

  const fetchCustomAssemblyTypes = (successCallback = () => {}) => {
    customAssemblyTypesQuery({
      variables: { query: { companyId: user.companyId } },
      notifyOnNetworkStatusChange: true,
      onCompleted: ({ assemblyCustomCategories }) => {
        if (!assemblyCustomCategories) {
          enqueueSnackbar('Error fetching custom assembly types', { variant: 'error' });
        } else {
          const customAssemblyTypes = assemblyCustomCategories.map(mapCustomAssemblyTypeResponse);
          successCallback(customAssemblyTypes);
        }
      },
    });
  };

  const getCustomAssemblies = async (assemblyCustomCategoryId, successCallback = () => {}) => {
    getCustomAssembliesQuery({
      variables: {
        query: { projectId, parentLineItemId: user.parentLineItemId, assemblyCustomCategoryId },
      },
      notifyOnNetworkStatusChange: true,
      onCompleted: ({ customAssemblies }) => {
        successCallback(assemblyCustomCategoryId, customAssemblies);
      },
    });
  };

  const addAssemblyCustomCategory = async (
    customAssemblyTypes,
    addingCustomAssemblyTypeName,
    setCustomAssemblyTypes,
  ) => {
    await handleResponse(
      addAssemblyCustomCategoryMutation,
      {
        variables: {
          body: {
            assemblyCustomCategoryName: addingCustomAssemblyTypeName,
            companyId: user.companyId,
          },
        },
      },
      { successMessage: 'Assembly type successfully created' },
      ({ addAssemblyCustomCategory: addedCustomAssemblyType }) => {
        const customAssemblyType = mapCustomAssemblyTypeResponse(addedCustomAssemblyType);
        setCustomAssemblyTypes([...customAssemblyTypes, customAssemblyType]);
      },
      () => {
        enqueueSnackbar('Error adding custom assembly types', { variant: 'error' });
      },
    );
  };

  const checkCustomAssemblyIdentifierUnique = async (customAssemblyIdentifier) => {
    const {
      data: {
        checkCustomAssemblyIdentifierUnique: { isUnique },
      },
    } = await checkCustomAssemblyIdentifierUniqueQuery({
      variables: { query: { projectId, customAssemblyIdentifier } },
      notifyOnNetworkStatusChange: true,
    });
    return isUnique;
  };

  const checkCustomAssemblyNameUnique = async (customAssemblyName) => {
    const {
      data: {
        checkCustomAssemblyNameUnique: { isUnique },
      },
    } = await checkCustomAssemblyNameUniqueQuery({
      variables: { query: { projectId, customAssemblyName } },
      notifyOnNetworkStatusChange: true,
    });
    return isUnique;
  };

  const addBomItemCustomAssembly = async (
    customAssemblyName,
    customAssemblyIdentifier,
    assemblyCustomCategoryId,
    assignedto,
    customAssemblyTypes,
    setCustomAssemblyTypes,
  ) => {
    await handleResponse(
      addBOMItemCustomAssemblyMutation,
      {
        variables: {
          body: {
            projectId,
            customAssemblyName,
            customAssemblyIdentifier,
            assemblyCustomCategoryId,
            assignedto,
          },
        },
      },
      { successMessage: 'Custom assembly successfully created' },
      ({ addBOMItemCustomAssembly }) => {
        // action to save custom assembly to context
        const current = { index: -1 };
        customAssemblyTypes.map((value, index) => {
          if (value.id === assemblyCustomCategoryId) {
            current.index = index;
          }
          return -1;
        });
        const copy = [...customAssemblyTypes];
        const newItem = {
          id: addBOMItemCustomAssembly.lineItemId,
          type: addBOMItemCustomAssembly.lineItemTypeName,
          children: addBOMItemCustomAssembly,
        };

        if (copy[current.index + 1] && copy[current.index + 1].type === 'Custom Assembly') {
          copy[current.index].children = [...copy[current.index].children, addBOMItemCustomAssembly];
          copy.splice(current.index + copy[current.index].children.length, 0, newItem);
        }

        setCustomAssemblyTypes(copy);
      },
      () => {
        enqueueSnackbar('Error adding custom assembly', { variant: 'error' });
      },
    );
  };

  const deleteBulkItemCustomAssembly = async (lineItemIds, deleteCompletedCallback) => {
    await handleResponse(
      deleteBulkBOMItemCustomAssemblyMutation,
      {
        variables: { body: { lineItemIds } },
        update: (cache, result) => {
          if (result?.errors?.length) return;
          lineItemIds.forEach((lineItemId) => {
            cache.evict({ id: cache.identify({ lineItemId, __typename: 'BillOfMaterialItem' }) });
            cache.gc();
          });
        },
      },
      { successMessage: `${lineItemIds.length} Items successfully deleted` },
      () => {
        deleteCompletedCallback?.(lineItemIds);
      },
      () => {
        deleteCompletedCallback?.(lineItemIds);
        enqueueSnackbar('Error deleting custom assembles', { variant: 'error' });
      },
    );
  };

  const deleteItemCustomAssembly = async (billOfMaterialItem, deleteCompletedCallback) => {
    await handleResponse(
      deleteBOMItemCustomAssemblyMutation,
      {
        variables: { params: { lineItemId: billOfMaterialItem.lineItemId } },
        update: (cache, result) => {
          if (result?.errors?.length) return;
          cache.modify({
            id: 'ROOT_QUERY',
            fields: {
              billOfMaterialItem: (value) =>
                value?.filter((bomItem) => bomItem?.lineItemId !== billOfMaterialItem.lineItemId),
            },
          });
        },
        refetchQueries: ['BillOfMaterialItem'],
        onQueryUpdated(observableQuery) {
          return getExecuteBOMItemsQuery(
            observableQuery,
            billOfMaterialItem.parentLineItem,
            billOfMaterialItem.projectId,
          );
        },
      },
      { successMessage: '1 Item successfully deleted' },
      () => {
        deleteCompletedCallback?.(billOfMaterialItem.lineItemId);
      },
      () => {
        enqueueSnackbar('Error deleting custom assembles', { variant: 'error' });
      },
    );
  };
  return {
    fetchCustomAssemblyTypes,
    deleteBulkItemCustomAssembly,
    deleteItemCustomAssembly,
    addAssemblyCustomCategory,
    addBomItemCustomAssembly,
    checkCustomAssemblyNameUnique,
    checkCustomAssemblyIdentifierUnique,
    deleteBOMItemCustomAssemblyMutation,
    deleteBulkBOMItemCustomAssemblyMutation,
    customAssemblyTypesQuery,
    getCustomAssemblies,
    customAssemblyTypesData,
    loadingCustomAssemblyTypes,
    loadingCustomAssemblies,
    isDeletingCustomAssembly,
    isCreatingCustomAssemblyType,
    isDeletingBulkCustomAssembly,
    isCreatingCustomAssembly,
    checkingCustomAssemblyIdUnique,
    checkingCustomAssemblyNameUnique,
  };
};

const getExecuteBOMItemsQuery = (observableQuery, parentLineItemId, currentProjectId) => {
  const { parentLineItemIds, projectId } = observableQuery.variables?.query ?? {};

  const billOfMaterialItems = observableQuery
    .getLastResult()
    ?.data?.billOfMaterialItem?.map((billOfMaterialItem) => billOfMaterialItem.lineItemId);
  const shouldFetchParentQuery = billOfMaterialItems.includes(parentLineItemId);
  const shouldFetchCurrentQueryIfChild = parentLineItemIds && parentLineItemIds === parentLineItemId;
  const shouldFetchRootBOM = !parentLineItemId && projectId === currentProjectId;
  if (shouldFetchParentQuery || shouldFetchCurrentQueryIfChild || shouldFetchRootBOM) return true;

  return false;
};
