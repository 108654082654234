import React, { useEffect, useMemo, useRef } from 'react';

import { Box, IconButton, Stack, useTheme } from '@mui/material';
import { useController } from 'react-hook-form';

import InputSelectField from 'components/FormComponents/InputSelectField';
import { Custom } from 'components/Icons';

import AutocompleteControlled from './Inputs/AutocompleteControlled';

const inputSx = (theme) => ({
  '& .MuiInput-underline::after': {
    borderBottomColor: `${theme.palette.secondary.main} !important`,
  },
  '& .MuiInput-root:hover::before': {
    borderBottomColor: `${theme.palette.secondary.main} !important`,
  },
});

const FilterFormItem = ({
  control,
  prefixName,
  onRemove,
  onSubmit,
  onRemoveFilter,
  filters,
  columns,
}) => {
  const currentValue = useRef(null);
  const theme = useTheme();
  const themeInputSx = inputSx(theme);

  const {
    field: { value: columnValue },
  } = useController({
    name: `${prefixName}.column`,
    control,
  });

  const filtersFlat = useMemo(() => filters.map((filter) => filter.column), [filters]);
  const columnsNotSelected = useMemo(
    () => columns.filter((col) => !filtersFlat.includes(col.value) || col.value === columnValue),
    [columns, filtersFlat, columnValue],
  );

  const {
    field: { value: valuesValue, onChange },
  } = useController({
    name: `${prefixName}.values`,
    control,
  });

  useEffect(() => {
    if (
      currentValue.current !== null &&
      columnValue !== '' &&
      currentValue.current !== columnValue
    ) {
      if (valuesValue.length) {
        onChange([]);
        onRemoveFilter(currentValue.current, columnValue);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnValue]);

  const options = useMemo(
    () => columns.find((col) => col.value === columnValue)?.options,
    [columns, columnValue],
  );

  const handleClose = (e) => {
    if (onSubmit) onSubmit(e);
  };

  if (!currentValue.current && columnValue) {
    currentValue.current = columnValue;
  }

  return (
    <Stack
      direction="row"
      sx={{ width: '100%' }}
      alignItems="center"
      data-testid="filter-form-item"
    >
      <IconButton aria-label="delete" onClick={onRemove} data-testid={`${prefixName}.removeButton`}>
        <Custom.CloseIcon sx={{ color: 'surface.lightSurface.active' }} />
      </IconButton>
      <Box sx={{ width: '210px' }}>
        <InputSelectField
          name={`${prefixName}.column`}
          label="Columns"
          color="secondary"
          size="small"
          fullWidth
          variant="standard"
          control={control}
          options={columnsNotSelected}
          dataTestId={`${prefixName}.column`}
          sx={themeInputSx}
        />
      </Box>
      <Box sx={{ width: '100%' }}>
        <AutocompleteControlled
          name={`${prefixName}.values`}
          control={control}
          options={options ?? []}
          onClose={handleClose}
          label="Value"
          dataTestId={`${prefixName}.values`}
          sx={themeInputSx}
        />
      </Box>
    </Stack>
  );
};

export default FilterFormItem;
