import React from 'react';

import DeleteItemsOption from './DeleteItemsOption';
import SendToBOMOption from './SendToBOMOption';

const ActionOptions = ({ closeMenu }) => (
  <>
    <SendToBOMOption />
    <DeleteItemsOption closeMenu={closeMenu} />
  </>
);

export default ActionOptions;
