import * as React from 'react';

import AddNewItem from './AddNewItem';

const NewItemRowComponent = ({
  actionDefaultLabel,
  hook,
  addNewItemSx,
  onAddPartItem,
  onAddWriteInItem,
  disabled,
  isRecursive,
}) => {
  const [name, setName] = React.useState('');
  const onChangeName = (event) => setName(event.target.value);

  const handleSelectOption = React.useCallback((option) => onAddPartItem(option), [onAddPartItem]);

  const showModalToAddNewItem = React.useCallback((item) => onAddWriteInItem(item, name), [name, onAddWriteInItem]);

  return (
    <AddNewItem
      actionDefaultLabel={actionDefaultLabel}
      hook={hook}
      onOptionSelected={handleSelectOption}
      addNewItemSx={addNewItemSx}
      onInputChange={onChangeName}
      showModal={showModalToAddNewItem}
      disabled={disabled}
      isRecursive={isRecursive}
    />
  );
};

export default NewItemRowComponent;
