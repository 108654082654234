import React from 'react';

import { useMutation, gql } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Modal } from 'components';
import { addProjectUser as ADD_PROJECT_USER } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

import { useProjectMembersContext } from '../ProjectMembersContext';
import ProjectInviteForm from './ProjectInviteForm';

const schema = yup.object().shape({
  newProjectUsers: yup
    .array()
    .of(
      yup.lazy((value) =>
        typeof value === 'object' ? yup.object().shape({ label: yup.string().email() }) : yup.string().email(),
      ),
    )
    .min(1)
    .required(),
});

const ProjectInviteModal = ({ openStatus, onClose }) => {
  const { projectId } = useProjectMembersContext();

  const { handleBatchResponse } = useGraphqlResponseHandler();
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: { newProjectUsers: [] },
  });

  const [addProjectUserMutation, { loading }] = useMutation(gql(ADD_PROJECT_USER), {
    refetchQueries: ['ProjectUsers'],
  });

  const submit = async ({ newProjectUsers }) => {
    try {
      const results = await Promise.all(
        newProjectUsers.map(async (projectUser) => {
          const body = {
            projectId,
          };

          if (typeof projectUser === 'object') {
            body.userId = projectUser.id;
          } else {
            body.userEmail = projectUser;
          }

          const res = await addProjectUserMutation({
            variables: {
              body,
            },
          });

          return res;
        }),
      );

      handleBatchResponse(results);
    } finally {
      onClose();
    }
  };

  return (
    <Modal
      open={openStatus}
      onClose={onClose}
      title="Invite users to project"
      fullWidth
      maxWidth="xs"
      disableModalOutsideClick
      allowOverflow
      footer={
        <>
          {loading && <CircularProgress />}
          <Button color="primary" onClick={onClose} disabled={loading}>
            CANCEL
          </Button>
          <InviteButton
            color="primary"
            variant="contained"
            onClick={handleSubmit(submit)}
            disabled={!isValid || loading}
          >
            INVITE
          </InviteButton>
        </>
      }
    >
      <ProjectInviteForm control={control} loadingProp={loading} />
    </Modal>
  );
};

export default ProjectInviteModal;

const InviteButton = (props) => (
  <Button
    sx={{
      marginLeft: '8px',
      '&:hover': {
        backgroundColor: 'primary.containedHoverBackground',
      },
    }}
    {...props}
  />
);
