import React from 'react';

import { gql, useLazyQuery, useMutation } from '@apollo/client';

import { useUser } from 'app/UserContext';
import { addUnitOfMeasure as ADD_UNIT_OF_MEASURE } from 'graphql/mutations';
import {
  unitOfMeasure as UNITS_OF_MEASURE,
  unitOfMeasureTypes as UNIT_OF_MEASURE_TYPES,
} from 'graphql/queries';

import { addNewUom } from '../ItemsSection/NewItemsSection/newItemSectionHelpers';

const UOMAPIContext = React.createContext();

const UOMAPIProvider = ({ children }) => {
  const { user } = useUser();
  const [addUom, { loading: loadingAddUom }] = useMutation(gql(ADD_UNIT_OF_MEASURE), {
    refetchQueries: ['unitOfMeasure'],
  });
  const [fetchUoms, { data: uoms, loading: loadingUoms }] = useLazyQuery(gql(UNITS_OF_MEASURE), {
    fetchPolicy: 'cache-and-network',
    variables: {
      query: {
        companyId: user?.companyId,
      },
    },
  });

  const [fetchUomTypes, { data: unitOfMeasureTypes, loading: loadingUnitOfMeasureTypes }] =
    useLazyQuery(gql(UNIT_OF_MEASURE_TYPES), { variables: { fetchPolicy: 'no-cache' } });
  const getUnitOfMeasureId = async (values = {}) => {
    if (typeof values?.unitOfMeasureId === 'object') return values?.unitOfMeasureId?.id;
    return addNewUom(addUom, user, values, fetchUoms);
  };
  const valueObj = {
    addUom,
    loadingAddUom,
    fetchUoms,
    uoms,
    loadingUoms,
    getUnitOfMeasureId,
    fetchUomTypes,
    unitOfMeasureTypes,
    loadingUnitOfMeasureTypes,
  };
  return <UOMAPIContext.Provider value={valueObj}>{children}</UOMAPIContext.Provider>;
};

const useUOMAPIContext = () => {
  const context = React.useContext(UOMAPIContext);
  if (context === undefined) throw new Error('useUOMAPIContext must be within a UOMAPIContext');
  return context;
};

export { UOMAPIProvider, UOMAPIContext, useUOMAPIContext };
