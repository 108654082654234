import React from 'react';

import { gql, useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { departmentTypes as DEPARTMENT_TYPES } from 'graphql/queries';

import DepartmentInfoCheckbox from './DepartmentInfoCheckbox';

const DepartmentInfoForm = ({ departments }) => {
  const { data, loading } = useQuery(gql(DEPARTMENT_TYPES));

  if (loading) return <CircularProgress />;

  const { departmentTypes } = data;

  return (
    <DepartmentFormWrapper>
      <Title variant="subtitle2">
        <strong>Department Type </strong>(You can select multiple)
      </Title>

      {departmentTypes.map(({ departmentTypeId, departmentTypeName }, index) => (
        <DepartmentInfoCheckbox
          index={index}
          key={departmentTypeId}
          departmentTypeId={departmentTypeId}
          label={departmentTypeName}
          value={departmentTypeName}
          departments={departments}
        />
      ))}
    </DepartmentFormWrapper>
  );
};

export default DepartmentInfoForm;

const Title = (props) => <Typography sx={{ marginBottom: '16px' }} {...props} />;

const DepartmentFormWrapper = (props) => (
  <Box
    sx={{
      marginTop: '16px',
      marginBottom: '1.5px',
    }}
    {...props}
  />
);
