import React, { Fragment, useEffect } from 'react';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { useLocation } from 'react-router-dom';

import InfiniteScroll from 'components/TableComponents/InfiniteScroll';
import NoContentMessage from 'components/TableComponents/NoContentMessage';
import TableContainer from 'components/TableComponents/TableContainer';
import TableHeader from 'components/TableComponents/TableHeaders';
import TableSkeleton from 'components/TableComponents/TableSkeleton';

import { useAdminContext } from '../AdminContext';
import AdminTableRow from './AdminTableRow';

const headerOptions = [
  { title: 'User', sortValue: 'name' },
  { title: 'Email', sortValue: 'email' },
  { title: 'Role' },
  { title: 'Office Phone' },
  { title: 'Mobile Phone' },
  { title: 'Status', sortValue: 'status' },
  { title: 'EMPTY' },
];

const AdminTable = () => {
  const { state } = useLocation();
  const { loading, data, sortHandler, paginationHandler, setCompanyId } = useAdminContext();

  useEffect(() => {
    if (state?.companyId) setCompanyId(state?.companyId);
  }, [state?.companyId, setCompanyId]);

  const users = data?.users || [];

  const TableBodyContent = () => (
    <TableBody>
      {users.map((member, index) => (
        <Fragment key={member.userId}>
          <AdminTableRow member={member} />
          {index === users.length - 1 && <InfiniteScroll data={users} fetchMore={paginationHandler} />}
        </Fragment>
      ))}
    </TableBody>
  );

  return (
    <AdminTableBorderBox>
      <TableContainer sx={{ height: 1 }}>
        <Table stickyHeader>
          <TableHeader headerOptions={headerOptions} handleSortOrder={sortHandler} rowCount={users.length} />
          {loading ? <TableSkeleton skeletons={headerOptions.map(({ title }) => title)} /> : <TableBodyContent />}
        </Table>
      </TableContainer>
      {!users.length && !loading && <NoContentMessage text="No Admins..." />}
    </AdminTableBorderBox>
  );
};

export default AdminTable;

const AdminTableBorderBox = ({ children }) => (
  <Box
    mt="20px"
    sx={{
      border: `1px solid #0000001F`,
      borderRadius: 1,
      position: 'relative',
      padding: '5px 0 0 0',
      height: 'calc(100% - 135px)',
    }}
  >
    {children}
  </Box>
);
