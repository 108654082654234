import { useMemo } from 'react';

import { GeneralDictionary } from 'types/generalTypes';

import { LoadingRowsType } from '../../@types/itemsIntegrationTableContext';
import { QuantityFieldType } from '../components/QuantityField/hooks/@types/fieldHookTypes';

interface IUseGetLoadingRows {
  fieldsCalculating: GeneralDictionary<boolean>;
  loadingBOMRows: LoadingRowsType;
  quantityFields: QuantityFieldType[];
}

export const useGetLoadingRows = ({ fieldsCalculating, loadingBOMRows, quantityFields }: IUseGetLoadingRows) => {
  const isCalculatingFields = useMemo<boolean>(() => {
    const calculatingFieldsArray = Object.values(fieldsCalculating);
    if (!calculatingFieldsArray.length) return false;
    return calculatingFieldsArray.some((field) => Boolean(field));
  }, [fieldsCalculating]);

  const isLoadingRows = useMemo<boolean>(() => {
    if (isCalculatingFields) return true;
    const loadingRowsArray = Object.values(loadingBOMRows ?? {});
    if (!loadingRowsArray.length) return false;
    return loadingRowsArray.some((loadingRow) => Boolean(loadingRow));
  }, [loadingBOMRows, isCalculatingFields]);

  const quantityFieldsByLineItem = useMemo<GeneralDictionary<QuantityFieldType[]>>(() => {
    if (!quantityFields.length) return {};
    const lineItems = quantityFields.filter(({ nestLevel }) => nestLevel === 0);
    if (!lineItems.length) return {};
    const newLineItemDict: GeneralDictionary<QuantityFieldType[]> = {};
    lineItems.forEach((lineItem) => {
      newLineItemDict[lineItem.id] = [
        lineItem,
        ...quantityFields.filter(({ lineItemId }) => lineItemId === lineItem.id),
      ];
    });
    return newLineItemDict;
  }, [quantityFields]);

  return { isLoadingRows, quantityFieldsByLineItem, isCalculatingFields };
};
