import React from 'react';

import Button from '@mui/material/Button';

import { CenteredLoadSpinner, Modal } from 'components';
import StackRow from 'components/StackRow';

import { useTaskViewerContext } from '../TaskViewerContext';

const ConfirmCompleteModal = ({ open, confirm, handleCancel, taskName }) => {
  const { loadingUpdateTask } = useTaskViewerContext();
  return (
    <Modal
      open={open}
      titleStyles={{ fontWeight: 700 }}
      title="Confirm completion"
      fullWidth
      onClose={handleCancel}
      maxWidth="sm"
      disableModalOutsideClick={loadingUpdateTask}
      disableTitleClose={loadingUpdateTask}
      footer={
        <StackRow>
          <CancelButton disabled={loadingUpdateTask} handleCancel={handleCancel} />
          <ConfirmButton disabled={loadingUpdateTask} confirm={confirm} />
        </StackRow>
      }
    >
      Are you sure you want to complete <b>{taskName}?</b>
    </Modal>
  );
};

export default ConfirmCompleteModal;

const CancelButton = ({ disabled, handleCancel }) => (
  <Button onClick={handleCancel} disabled={disabled} data-testid="cancel-button">
    Cancel
  </Button>
);

const ConfirmButton = ({ disabled, confirm }) => {
  if (disabled)
    return (
      <CenteredLoadSpinner
        width="101px"
        sx={{
          mt: 0,
          span: {
            width: '24px !important',
            height: '24px !important',
          },
        }}
      />
    );
  return (
    <Button
      style={{ marginLeft: '8px' }}
      color="secondary"
      variant="contained"
      onClick={confirm}
      disabled={disabled}
      data-testid="confirm-button"
    >
      Confirm
    </Button>
  );
};
