import { surfaceColors } from 'styles/theme/colors';

const useExpandedIconColor = () => {
  const getIconColor = (expanded: boolean) =>
    expanded ? surfaceColors.lightSurface.primary : surfaceColors.lightSurface.secondary;

  return getIconColor;
};

export default useExpandedIconColor;
