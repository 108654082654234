/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useContext, useEffect } from 'react';

import { gql } from '@apollo/client';

import { useUser } from 'app/UserContext';
import { projectsFromDepartmentUsers as PROJECTS } from 'graphql/queries';
import useLazySearchableQuery from 'hooks/useLazySearchableQuery';

const ProjectsContext = React.createContext();

const ProjectsContextProvider = ({ children }) => {
  const { user } = useUser();
  const [modalOpenStatus, setModalOpenStatus] = useState(false);

  const [{ lazyLoad, searchHandler, sortHandler, paginationHandler }, { data, loading }] =
    useLazySearchableQuery(gql(PROJECTS), {
      extraVars: {
        companyId: user.companyId,
      },
      fetchPolicy: 'cache-and-network',
    });

  useEffect(() => lazyLoad(), []);

  const closeModal = () => {
    setModalOpenStatus(false);
    lazyLoad();
  };
  const openModal = () => setModalOpenStatus(true);

  const selectedStateObj = useMemo(
    () => ({
      modalOpenStatus,
      closeModal,
      openModal,
      lazyLoad,
      searchHandler,
      sortHandler,
      paginationHandler,
      loading,
      projects: data?.projectsFromDepartmentUsers || [],
    }),
    [modalOpenStatus, loading, data],
  );

  return <ProjectsContext.Provider value={selectedStateObj}>{children}</ProjectsContext.Provider>;
};

const useProjectContext = () => {
  const context = useContext(ProjectsContext);
  if (context === undefined) {
    throw new Error('useProjectsContext must be used within a ProjectsContext');
  }
  return context;
};

export { ProjectsContext, ProjectsContextProvider, useProjectContext };
