/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { KeyboardEvent } from 'react';

import { GridApi, GridValidRowModel } from '@mui/x-data-grid-pro';

import { BOM_ITEM_LINE_TYPES } from 'constants/globalConstants';
import { BillOfMaterialItemInput } from 'graphql/types';

export const BOMCatalogTableFields = {
  LINE_ITEM_NAME: 'lineItemName',
  TRADE_SIZE: 'tradeSize',
  ASSEMBLY_TYPE: 'assemblyType',
  UNIT_QUANTITY: 'unitQuantity',
  QUANTITY: 'quantity',
  QUANTITY_REMAINING: 'remainingQuantity',
  UNIT_OF_MEASURE_CODE: 'unitOfMeasureCode',
  MANUFACTURER_NAME: 'manufacturerName',
  MANUFACTURER_CATALOG_NUMBER: 'manufacturerCatalogNumber',
  DECISIONS: 'decisions',
};

export const BOMCatalogTableHeaders = {
  [BOMCatalogTableFields.LINE_ITEM_NAME]: 'Item',
  [BOMCatalogTableFields.TRADE_SIZE]: 'Trade Size',
  [BOMCatalogTableFields.ASSEMBLY_TYPE]: 'Assembly Type',
  [BOMCatalogTableFields.UNIT_QUANTITY]: 'Unit Qty',
  [BOMCatalogTableFields.QUANTITY]: 'Qty Required',
  [BOMCatalogTableFields.QUANTITY_REMAINING]: 'Qty Remaining',
  [BOMCatalogTableFields.UNIT_OF_MEASURE_CODE]: 'UOM',
  [BOMCatalogTableFields.MANUFACTURER_NAME]: 'Manufacturer',
  [BOMCatalogTableFields.MANUFACTURER_CATALOG_NUMBER]: 'Catalog Number',
  [BOMCatalogTableFields.DECISIONS]: 'Decisions',
};

export const handleDeleteItem = (apiRef: React.MutableRefObject<GridApi>, id: string) => {
  // const rows = apiRef.current.getRowModels();
  // apiRef.current.setRows((rows: GridValidRowModel) => {
  //   return rows;
  // });
  // apiRef.current.stopRowEditMode({ id });
};

export const onEscapeKeyPressed = (
  key: KeyboardEvent<HTMLInputElement>,
  apiRef: React.MutableRefObject<GridApi>,
  id: string,
) => {
  // if (key === 'Escape') apiRef.current.setRows((rows) => rows.filter((row) => row.lineItemId !== id));
};

export const getItemHasParent = (parentLineItemId: string) => parentLineItemId != null;

export const isItemACustomAssembly = (lineItemTypeId: string) => lineItemTypeId === BOM_ITEM_LINE_TYPES.assembly;
export const isItemAPart = (lineItemTypeId: string) => lineItemTypeId === BOM_ITEM_LINE_TYPES.part;
export const isItemAWriteIn = (lineItemTypeId: string) => lineItemTypeId === BOM_ITEM_LINE_TYPES.write;
