/* eslint-disable max-lines-per-function */
import React, { useCallback } from 'react';

import { Box, IconButton } from '@mui/material';

import { Custom } from 'components/Icons';
import BasicCellComponent from 'modules/Materials/TakeOff/BasicCellComponent';
import InputField from 'modules/Materials/TakeOff/components/InputField';
import {
  CatalogNumberInput,
  ManufacturersAutocomplete,
  QTYNumberInput,
  QuantityInput,
  TradeSizeInput,
} from 'modules/Materials/TakeOff/Form';
import UOMCell from 'modules/Materials/TakeOff/Form/UOMCell';
import LineItemNameCell from 'modules/Materials/TakeOff/LineItemNameCell';
import MoreOptionsBOM from 'modules/Materials/TakeOff/MoreOptionsBOM';

import { FIELDS } from './constants';

const buildCssByColumn = (columnName, isRevitSourcePad = false) => {
  let sx = {};

  if ([FIELDS.quantity, FIELDS.remainingQuantity].includes(columnName)) {
    sx = { textAlign: 'right', paddingRight: '12px', height: '24px', lineHeight: '26px' };
  }

  if (columnName === FIELDS.quantity && !isRevitSourcePad) {
    sx = {
      ...sx,
      cursor: 'pointer',
      '&:hover': {
        borderRadius: '4px',
        WebkitBoxShadow: 'inset 0px 0px 2px 0px rgba(0,0,0,0.75)',
        MozBoxShadow: 'inset 0px 0px 2px 0px rgba(0,0,0,0.75)',
        boxShadow: 'inset 0px 0px 2px 0px rgba(0,0,0,0.75)',
      },
    };
  }
  return sx;
};

const useTableHeaderHelpers = ({
  control,
  uomOptions,
  manufacturers,
  partName,
  onChangePartName,
  resetForm,
  onKeyDownHandler,
  handleSubmitForm,
  itemNameRules,
  type,
  isRevitSourcePad,
  setFormControlValue,
  clearErrors,
  loadingAddTakeOffPadItem,
}) => {
  const onSave = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        handleSubmitForm(itemNameRules.error)();
      }
    },
    [handleSubmitForm, itemNameRules],
  );

  const handlerBasicComponent = useCallback(
    ({ id, field, value, onClick, row }) => {
      let children = value;
      if (FIELDS.manufacturer === field) children = value?.manufacturerName;
      if (FIELDS.uom === field) children = value?.unitOfMeasureCode;
      if (field === FIELDS.catalogNumber && !value) children = '---';
      if (field === FIELDS.decisions) return <MoreOptionsBOM value={value} id={id} row={row} />;
      if (field === FIELDS.lineItemName) return <LineItemNameCell value={value} row={row} />;

      return (
        <BasicCellComponent sx={buildCssByColumn(field, isRevitSourcePad)} onClick={onClick}>
          {children}
        </BasicCellComponent>
      );
    },
    [isRevitSourcePad],
  );

  const handlerFormComponent = useCallback(
    ({ field, value, isEditingQuantity }) => {
      switch (field) {
        case FIELDS.lineItemName:
          return (
            <NameInput
              itemNameRules={itemNameRules}
              resetForm={resetForm}
              onKeyDownHandler={onKeyDownHandler}
              partName={partName}
              onSave={onSave}
              onChangePartName={onChangePartName}
              disabled={loadingAddTakeOffPadItem}
            />
          );
        case FIELDS.tradeSize:
          return (
            <TradeSizeInput
              onKeyDown={onKeyDownHandler}
              control={control}
              onKeyPress={onSave}
              defaultValue={value}
              disabled={loadingAddTakeOffPadItem}
            />
          );

        case FIELDS.unitQuantity:
          return (
            <QTYNumberInput
              onKeyDown={onKeyDownHandler}
              control={control}
              onKeyPress={onSave}
              disabled={loadingAddTakeOffPadItem}
            />
          );

        case FIELDS.uom:
          return (
            <UOMCell
              control={control}
              setFormControlValue={setFormControlValue}
              clearErrors={clearErrors}
              uomOptions={uomOptions}
              onKeyDownHandler={onKeyDownHandler}
              disabled={loadingAddTakeOffPadItem}
            />
          );

        case FIELDS.manufacturer:
          return (
            <ManufacturersAutocomplete
              onKeyDown={onKeyDownHandler}
              control={control}
              options={manufacturers}
              disabled={type === 'copy' || loadingAddTakeOffPadItem}
            />
          );

        case FIELDS.catalogNumber:
          return (
            <CatalogNumberInput
              onKeyDown={onKeyDownHandler}
              control={control}
              onKeyPress={onSave}
              disabled={loadingAddTakeOffPadItem}
            />
          );

        case FIELDS.quantity:
          return (
            <QuantityInput
              onKeyDown={onKeyDownHandler}
              control={control}
              onKeyPress={onSave}
              autoFocus={type === 'copy' || isEditingQuantity}
              defaultValue={value}
              isEditingQuantity={isEditingQuantity}
              disabled={loadingAddTakeOffPadItem}
            />
          );

        default:
          return handlerBasicComponent({ field, value });
      }
    },
    [
      clearErrors,
      control,
      handlerBasicComponent,
      itemNameRules,
      manufacturers,
      onChangePartName,
      onKeyDownHandler,
      onSave,
      partName,
      resetForm,
      setFormControlValue,
      type,
      uomOptions,
      loadingAddTakeOffPadItem,
    ],
  );

  const renderCell = useCallback(
    ({ id, field, value, condition = false, RowComponent, onClick = () => {}, isEditingQuantity, row }) => {
      if (id === -1 && field === 'lineItemName' && RowComponent) return <RowComponent />;
      if (condition) return handlerFormComponent({ field, value, isEditingQuantity });
      return handlerBasicComponent({ id, field, value, onClick, row });
    },
    [handlerBasicComponent, handlerFormComponent],
  );

  return { renderCell, handlerBasicComponent, onSave };
};

export default useTableHeaderHelpers;

const NameInput = ({ itemNameRules, resetForm, onKeyDownHandler, partName, onSave, onChangePartName, disabled }) => (
  <Box sx={{ width: 'calc(100% - 30px)' }}>
    <IconButton sx={{ height: 18, mr: 1 }} onClick={resetForm} size="large">
      <Custom.CloseAlt />
    </IconButton>
    <InputField
      hasError={itemNameRules.error}
      onKeyDown={onKeyDownHandler}
      error={itemNameRules.error}
      value={partName}
      onChange={(e) => onChangePartName(e.target?.value)}
      onKeyPress={onSave}
      helperText={itemNameRules.error ? itemNameRules.message : ''}
      disabled={disabled}
    />
  </Box>
);
