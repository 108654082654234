import React, { useState } from 'react';

import { CircularProgress, Stack } from '@mui/material';

import { POSITION_TITLE } from 'constants/globalConstants';

import { useTaskViewerContext } from '../../TaskViewerContext';
import PositionTitle from '../components/PositionTitle';
import TaskWrWoAttachments from '../components/TaskWrWoAttachments';
import { TasksSectionTypes } from './@types/tasksTypes';
import AddLink from './components/AddLink';
import AttachFiles from './components/AttachFiles';

const TasksSection = ({ documents, positionTitle, section }: TasksSectionTypes) => {
  const [isAddLink, setIsAddLink] = useState<boolean>(false);
  const [isLoader, setIsLoader] = useState<boolean>(false);

  const {
    taskError,
    uploading,
    addDocumentLoading,
    addTaskDocumentLoading,
    removeTaskDocumentLoading,
    isAddingAnAttach,
  } = useTaskViewerContext();

  const disabled =
    isAddLink ||
    taskError ||
    uploading ||
    addDocumentLoading ||
    addTaskDocumentLoading ||
    removeTaskDocumentLoading ||
    isAddingAnAttach;

  return (
    <>
      {positionTitle === POSITION_TITLE.tasks && <PositionTitle />}
      {/* Tasks */}
      {(!!documents.length || isAddLink) && (
        <TaskWrWoAttachments
          documents={documents}
          section={section}
          setIsAddLink={setIsAddLink}
          isAddLink={isAddLink}
        />
      )}

      <Stack spacing={3.5} direction="row" alignItems="center">
        <AttachFiles disabled={disabled} setIsLoader={setIsLoader} />
        <AddLink disabled={disabled} setIsAddLink={setIsAddLink} />
        {isLoader && <CircularProgress color="secondary" size="25px" />}
      </Stack>
    </>
  );
};

export default TasksSection;
