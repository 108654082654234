import { gql, useQuery } from '@apollo/client';

import { taskStatusTypes as TASK_STATUS_TYPES } from 'graphql/queries';

const useFetchTaskStatusTypes = () => {
  const { data, loading } = useQuery(gql(TASK_STATUS_TYPES), {
    fetchPolicy: 'cache-first',
  });

  const taskStatusTypes = (data?.taskStatusTypes || []).map((status) => ({
    value: status.taskStatusTypeId,
    label: status.taskStatusTypeName,
  }));

  return { loading, taskStatusTypes };
};

export default useFetchTaskStatusTypes;
