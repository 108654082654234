import * as React from 'react';

export const useEqualValueChecker = ({
  currentName = '',
  customMessage = 'Error',
  rules = [],
  callback = null,
  required = true,
  triggered = false,
}) => {
  const resultInitialState = { error: false, message: customMessage, skip: false };
  const [result, setResult] = React.useState(resultInitialState);

  const checker = React.useCallback(
    async (name) => {
      let error = false;
      let skip = false;
      let message = customMessage;

      if (name.length === 0 && required && triggered) {
        error = true;
        message = '*Required';
      }

      rules.forEach((rule) => {
        if (rule?.check(name)) {
          error = true;
          skip = rule?.skip ?? false;
          const currentMessage =
            typeof rule?.message === 'string' ? rule?.message : rule?.message(name);
          message = currentMessage;
        }
      });

      if (error) {
        setResult({ error, message, skip });
        return;
      }
      if (callback) {
        const list = await callback(name);
        if (Array.isArray(list) && list.length > 0) {
          error = true;
          skip = false;
        }
      }
      setResult({ error, message, skip });
    },
    [rules, customMessage, callback, required, triggered],
  );

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      checker(currentName);
    }, 400);

    return () => clearTimeout(timeout);
    // Was added this line to avoid call multiple time
    // the checker function and just call this service when
    // currentName change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentName]);

  return result;
};
