import React, { useState } from 'react';

import Auth from '@aws-amplify/auth';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import { Modal, CancelButton, CreateButton, CenteredLoadSpinner } from 'components';
import { VARIANT_ERROR, VARIANT_SUCCESS } from 'constants/snackbarConstants';

import PasswordConfirmationForm from './PasswordConfirmationForm';

const ResetPasswordModal = ({ open, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const {
    watch,
    handleSubmit,
    control,
    trigger,
    formState: { isDirty, isValid, errors },
  } = useForm({ mode: 'all' });

  const submitDisabled = !isDirty || !isValid;

  const resetPasswordHandler = async ({ confirm, current }) => {
    setLoading(true);

    try {
      const amplifyUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(amplifyUser, current, confirm);

      enqueueSnackbar('Password successfully changed', VARIANT_SUCCESS);
    } catch (error) {
      enqueueSnackbar('There was an error attempting to change your password.', VARIANT_ERROR);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <Modal
      open={open}
      disableModalOutsideClick
      onClose={onClose}
      title="Reset Password"
      reducePadding
      fullWidth
      maxWidth="xs"
      titleStyles={{
        letterSpacing: 0.15,
        alignItems: 'center',
      }}
      contentStyles={{
        pt: 1,
        pb: 3,
      }}
      footer={
        <>
          <CancelButton color="primary" onClick={onClose} />
          <CreateButton
            color="primary"
            onClick={handleSubmit(resetPasswordHandler)}
            disabled={submitDisabled}
            buttonText="save changes"
          />
        </>
      }
    >
      <form onSubmit={handleSubmit(resetPasswordHandler)}>
        <PasswordConfirmationForm
          control={control}
          watch={watch}
          trigger={trigger}
          errors={errors}
          validateCurrentPassword
          showRulesOnInit
          hideViewPasswordIcon
        />
      </form>
      {loading && <CenteredLoadSpinner />}
    </Modal>
  );
};

export default ResetPasswordModal;
