import React, { useEffect } from 'react';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { WorkOrder } from 'graphql/types';
import { useLazyWorkOrdersCount } from 'hooks-api/queries/workOrdersCount/useLazyWorkOrdersCount';
import useFetchWorkOrders from 'hooks-api/useFetchWorkOrders';

type WorkOrdersKanbanColumnsContext = {
  workOrders: Array<WorkOrder> | null;
  workOrdersCount: number;
  loadingWorkOrderCount: boolean;
  fetchMore: (skip: number) => void;
};

type WorkOrdersKanbanColumnProviderProps = {
  status: string;
  children: React.ReactNode;
};
const WorkOrdersKanbanColumnContext = React.createContext<WorkOrdersKanbanColumnsContext>({
  workOrders: null,
  workOrdersCount: 0,
  loadingWorkOrderCount: false,
  fetchMore: () => {},
});

const WorkOrdersKanbanColumnProvider = ({ status, children }: WorkOrdersKanbanColumnProviderProps) => {
  const { getWorkOrders, workOrders, paginationHandler } = useFetchWorkOrders('cache-and-network');
  const {
    data: workOrderCount,
    loading: loadingWorkOrderCount,
    fetchWorkOrderCount,
  } = useLazyWorkOrdersCount({ options: { fetchPolicy: 'network-only' } });

  const { selectedItem } = useFacilitiesProjects();

  useEffect(() => {
    if (!status || !selectedItem) return;
    const fetchWorkOrders = async (key: string) => {
      const variables = {
        query: {
          orderBy: 'sortOrder:asc',
          [key]: selectedItem?.id,
          workOrderStatusIds: status,
          take: 50,
        },
      };
      await getWorkOrders({ variables });
      await fetchWorkOrderCount(variables);
    };
    if (selectedItem.type === 'FACILITY') fetchWorkOrders('facilityId');
    if (selectedItem.type === 'PROJECT') fetchWorkOrders('projectId');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, selectedItem]);

  const fetchMore = (skip: number) => {
    paginationHandler(skip, {
      orderBy: 'sortOrder:asc',
      workOrderStatusIds: status,
      facilityId: selectedItem?.id,
      take: 50,
    });
  };

  return (
    <WorkOrdersKanbanColumnContext.Provider
      value={{
        workOrders,
        workOrdersCount: workOrderCount?.workOrdersCount?.entireCount ? workOrderCount.workOrdersCount.entireCount : 0,
        loadingWorkOrderCount,
        fetchMore,
      }}
    >
      {children}
    </WorkOrdersKanbanColumnContext.Provider>
  );
};

const useWorkOrdersKanbanColumnsContext = () => {
  const context = React.useContext(WorkOrdersKanbanColumnContext);
  if (context === undefined) {
    throw new Error('WorkOrdersKanbanContext must be used within a WorkOrdersContext');
  }
  return context;
};

export { WorkOrdersKanbanColumnContext, WorkOrdersKanbanColumnProvider, useWorkOrdersKanbanColumnsContext };
