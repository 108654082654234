import React, { useEffect } from 'react';

import { useWorkCellsAndMachinesContext } from '../../../contexts/WorkCellsAndMachinesContext';
import useMachineDataLayer from '../hooks/useMachineDataLayer';
import useMachineTasksDataLayer from '../hooks/useMachineTasksDataLayer';

const MachineContext = React.createContext();

const MachineProvider = ({ machine, disabled = false, children }) => {
  const { machineTasks, loadingMachineTasks, fetchMachineTasks, unmapMachineTask } =
    useMachineTasksDataLayer(machine);
  const { updateMachine } = useMachineDataLayer(machine);
  const { handleSaveMachineForm } = useWorkCellsAndMachinesContext();

  useEffect(() => {
    fetchMachineTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!handleSaveMachineForm) return;

    if (handleSaveMachineForm.machine.machineId === machine.machineId)
      updateMachine(handleSaveMachineForm.formValues);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSaveMachineForm]);

  const selectedStateObj = {
    machine,
    disabled,
    machineTasks,
    loadingMachineTasks,
    unmapMachineTask,
  };

  return <MachineContext.Provider value={selectedStateObj}>{children}</MachineContext.Provider>;
};

const useMachine = () => {
  const context = React.useContext(MachineContext);
  if (context === undefined) {
    throw new Error('useMachine must be used within a MachineContext');
  }
  return context;
};

export { MachineContext, MachineProvider, useMachine };
