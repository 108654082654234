import React from 'react';

import {
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  useTheme,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { LOCAL_STORAGE_CONSTANTS, SESSION_STORAGE_CONSTANTS } from 'constants/globalConstants';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useCatalogSetupContext } from 'modules/Materials/CatalogSetup/CatalogSetupHeader/CatalogSetupContext';

import { useCatalogModal } from './CatalogModalContext';
import { selectMenuProps, ItemTypography } from './StylesObjects';

const { SELECTED_CATALOG_ID } = LOCAL_STORAGE_CONSTANTS;
const { CURRENT_CATALOG } = SESSION_STORAGE_CONSTANTS;

const CatalogSelector = () => {
  const { formState } = useFormContext();
  const { selectedCatalog, setSelectedCatalog, setCategoryId } = useCatalogModal();
  const { dataCatalogs, loadingCatalogs } = useCatalogSetupContext();
  const [, setCurrentCatalogId] = useLocalStorage(SELECTED_CATALOG_ID, '');
  const theme = useTheme();

  const handleChange = (e) => {
    const currentCatalog = dataCatalogs?.partCatalogs?.find(
      (item) => item.partCatalogId === e.target.value,
    );
    sessionStorage.setItem(CURRENT_CATALOG, JSON.stringify(currentCatalog));
    setCurrentCatalogId(currentCatalog.partCatalogId);
    setCategoryId(null);
    setSelectedCatalog(e.target.value);
  };

  if (loadingCatalogs) return <CircularProgress color="secondary" />;

  return (
    <FormControl size="small">
      <InputLabel id="Catalog-selector">Catalog</InputLabel>
      <Select
        disabled={formState.isSubmitting}
        onChange={(e) => handleChange(e)}
        value={selectedCatalog}
        color="secondary"
        label="Catalog"
        style={{ width: '320px' }}
        MenuProps={selectMenuProps()}
        sx={{
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: `${theme.palette.primary.light} !important`,
          },
        }}
      >
        {(dataCatalogs?.partCatalogs || [])?.map(({ partCatalogId, partCatalogName }) => (
          <MenuItem key={partCatalogId} value={partCatalogId}>
            <ItemTypography>{partCatalogName}</ItemTypography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CatalogSelector;
