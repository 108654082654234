import React, { useCallback, useEffect, useState } from 'react';

import { IUnassignedRowContext, IUnassignedRowProvider } from '../@types/modalContextTypes';
import { useModal } from './ModalContext';
import { useProjectRow } from './ProjectRowContext';

const UnassignedRowContext = React.createContext<IUnassignedRowContext | undefined>(undefined);

const UnassignedRowProvider = ({ formMethods = {}, children }: IUnassignedRowProvider) => {
  const { lineItem, updateIsLoading, setFormDirty, setCalculationNodeIds, handleLoadingUpdate } = useModal();
  const { unassignedQty, projectVisualTotalQty, initialUnassignedValue, loadingPatchBulk2LBS, setUnassignedIsDirty } =
    useProjectRow();
  const [disableInput, setDisableInput] = useState(false);

  const { control, trigger, setValue } = formMethods;

  const itemId = 'unassigned';

  const setLastValidQtyToInput = async () => {
    await setValue(itemId, unassignedQty);
    trigger();
  };

  const handleBlur = async (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    const inputValue = Number(event?.target?.value);
    setCalculationNodeIds([]);

    if (!event?.target?.value?.length || Number.isNaN(inputValue) || inputValue < 0) return setLastValidQtyToInput();

    return null;
  };

  const validateEnterKey = useCallback(
    (event: any) => {
      if ((!event?.target?.value?.length || event?.target?.value <= 0) && event.keyCode === 40) {
        event.preventDefault();
      }
      if (event.key === 'Enter') {
        if (event?.target?.value?.length && event?.target?.value >= 0) {
          handleBlur(event);
          setDisableInput(true);
          setTimeout(() => {
            setDisableInput(false);
          }, 0);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectVisualTotalQty],
  );

  const handleFocus = () => setCalculationNodeIds([itemId, lineItem?.lineItemId]);

  const handleResetValue = () => {
    const isPositiveNumber = initialUnassignedValue >= 0;
    setValue(itemId, isPositiveNumber ? initialUnassignedValue : 0);
  };

  const handleChange = () => {
    setUnassignedIsDirty(true);
    setFormDirty(true);
    trigger();
  };

  useEffect(() => {
    handleLoadingUpdate(loadingPatchBulk2LBS);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingPatchBulk2LBS]);

  const selectedStateObj: IUnassignedRowContext = {
    itemId,
    requiredQty: unassignedQty,
    handleBlur,
    validateEnterKey,
    handleFocus,
    handleChange,
    control,
    handleResetValue,
    disableInput: loadingPatchBulk2LBS || updateIsLoading || disableInput,
  };

  return <UnassignedRowContext.Provider value={selectedStateObj}>{children}</UnassignedRowContext.Provider>;
};

const useUnassignedRow = () => {
  const context = React.useContext(UnassignedRowContext);
  if (context === undefined) {
    throw new Error('useUnassignedRow must be used within a UnassignedRowContext');
  }
  return context;
};

export { UnassignedRowContext, UnassignedRowProvider, useUnassignedRow };
