import React from 'react';

import { CircularProgress } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';

import { Modal, CancelButton, DeleteButton } from 'components';
import ValidateEmail from 'components/FormComponents/ValidateEmail';

import { useWorkOrderCancelModalContext } from './context/WorkOrderCancelModalContext';
import WorkOrderModalContent from './WorkOrderModalContent';

const WorkOrderModalCancel = ({ open, onClose, onSubmit, loading = false, workOrderStatus }) => {
  const { isTableFilled, showCancelTable } = useWorkOrderCancelModalContext();
  const title = `Confirm '${workOrderStatus}' cancel`;

  const methods = useForm({
    mode: 'onBlur',
  });

  const {
    formState: { isValid },
  } = methods;

  return (
    <Modal
      disableModalOutsideClick
      open={open}
      onClose={onClose}
      title={title}
      reducePadding
      closeButtonStyles={{
        padding: 0,
      }}
      contentStyles={{ padding: '16px 24px 12px 24px' }}
      sx={{
        '.MuiDialog-paper': {
          maxWidth: showCancelTable ? '641px' : '505px',
        },
      }}
      footer={
        <>
          {loading && <CircularProgress size={20} data-testid="circular-progress" />}
          <CancelButton onClick={onClose} disabled={loading} buttonText="No" />
          <DeleteButton
            disabled={loading || !isValid || !isTableFilled}
            onClick={onSubmit}
            buttonText="Yes"
          />
        </>
      }
    >
      <FormProvider {...methods}>
        <WorkOrderModalContent />
        <ValidateEmail
          disabled={loading}
          title="Please type your email to confirm cancel:"
          label="Email*"
        />
      </FormProvider>
    </Modal>
  );
};

export default WorkOrderModalCancel;
