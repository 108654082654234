import React from 'react';

import RightSideFlyoutPaper from 'components/RightSideFlyoutPaper';

import DrawerContainerActionButton from './DrawerContainerActionButton';
import DrawerContainerItem from './DrawerContainerItem';
import DrawerContainerItems from './DrawerContainerItems';
import DrawerContainerSearch from './DrawerContainerSearch';
import DrawerContainerTitle from './DrawerContainerTitle';

const DrawerContainer = ({ topRightActionsSx = {}, width = '280px', onClose, sx, children }) => (
  <RightSideFlyoutPaper
    onClose={onClose}
    useXButton
    topRightActionsSx={topRightActionsSx}
    width={width}
    sx={{ bottom: '16', p: 0, ...sx }}
  >
    {children}
  </RightSideFlyoutPaper>
);

DrawerContainer.Title = DrawerContainerTitle;
DrawerContainer.Search = DrawerContainerSearch;
DrawerContainer.ActionButton = DrawerContainerActionButton;
DrawerContainer.Items = DrawerContainerItems;
DrawerContainer.Item = DrawerContainerItem;

export default DrawerContainer;
