import { useCallback, useEffect, useMemo } from 'react';

import { gql } from '@apollo/client';

import { workOrderItem as WORK_ORDER_ITEMS } from 'graphql/queries';
import { WorkOrder } from 'graphql/types';
import { isMultipleOf } from 'helpers/arrayFunctions';
import { WorkOrderItemsQueryObject } from 'hooks-api/types/workOrderItemsTypes';
import useLazyPaginatedQuery from 'hooks/useLazyPaginatedQuery';

const PAGINATE_BY = 50;

const useLazyPaginatedWorkOrderItems = (workOrder: WorkOrder, fetchWorkOrderItemsOnInit = true) => {
  const [{ lazyLoad, paginationHandler }, { data, loading: loadingWorkOrderItems }] = useLazyPaginatedQuery(
    gql(WORK_ORDER_ITEMS),
    'cache-and-network',
    PAGINATE_BY,
  );

  const workOrderItemsQuery = useMemo(
    (): WorkOrderItemsQueryObject => ({
      query: gql(WORK_ORDER_ITEMS),
      variables: {
        query: {
          workOrderId: workOrder?.workOrderId,
          take: data?.workOrderItem?.length + 1,
          skip: 0,
        },
      },
    }),
    [workOrder, data],
  );

  const fetchMore = useCallback(
    (skip: number) => {
      const canPaginate = workOrder?.workOrderId && isMultipleOf(PAGINATE_BY, data?.workOrderItem?.length);
      if (canPaginate && paginationHandler) paginationHandler(skip, { workOrderId: workOrder?.workOrderId });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [workOrder, data],
  );

  useEffect(() => {
    const componentIsMounted = workOrder?.workOrderId && fetchWorkOrderItemsOnInit && lazyLoad;
    if (componentIsMounted) lazyLoad({ workOrderId: workOrder?.workOrderId });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workOrder]);

  return {
    workOrderItems: data?.workOrderItem || [],
    loadingWorkOrderItems,
    fetchMore,
    workOrderItemsQuery,
  };
};

export default useLazyPaginatedWorkOrderItems;
