import React from 'react';

import { IconButton } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Custom } from 'components/Icons';

import { BORDER_COLOR } from '../constants/constants';
import { useTaskViewerContext } from '../TaskViewerContext';

const NavigationHeader = () => {
  const { handleSelectNextFile, handleSelectPrevFile, activeDocument } = useTaskViewerContext();

  return (
    <Stack sx={{ height: 32, borderBottom: BORDER_COLOR }}>
      {activeDocument && (
        <Stack direction="row" alignItems="center" justifyContent="flex-start" sx={{ height: 'inherit', ml: 1 }}>
          <IconButton onClick={handleSelectPrevFile}>
            <Custom.KeyboardArrowLeftIcon />
          </IconButton>
          <IconButton onClick={handleSelectNextFile}>
            <Custom.KeyboardArrowRightIcon />
          </IconButton>
          <Typography
            variant="body2"
            sx={{
              fontWeight: 700,
              color: 'surface.lightSurface.secondary',
              letterSpacing: '0.15px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {activeDocument?.documentName}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};
export default NavigationHeader;
