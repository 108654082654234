import { DrawingDocumentType, NEW_FOLDER } from '../hooks/constants';

export const drawingFolderMap = (folder) => ({
  id: folder.folderId,
  documentIdentifier: folder.folderId,
  documentName: folder.folderName,
  type: DrawingDocumentType.FOLDER,
  folder,
});

export const drawingNewFolderMap = (_, index) => ({
  id: index,
  drawingId: 0,
  drawingName: null,
  type: DrawingDocumentType.NEW_FOLDER_FORM,
  isEditing: true,
});

export const drawingDocumentMapCb = (document, index) => {
  const {
    __typename: documentType,
    documentId,
    documentIdentifier,
    documentName,
    encodedUrn,
    documentStatusTypeId,
    isMappedFromShop,
    storagePath,
    extension,
    documentNameFormatted,
  } = document;

  if (documentType === 'Document') {
    return {
      draggableIndex: index,
      id: documentId,
      documentIdentifier,
      documentName,
      type: DrawingDocumentType.DOCUMENT,
      encodedUrn,
      documentStatusTypeId,
      isMappedFromShop,
      storagePath,
      extension,
      documentNameFormatted,
    };
  }

  if (documentType === 'Folder') {
    return drawingFolderMap(document, index);
  }

  return drawingNewFolderMap(document, index);
};

export const handleSort = (sort) => {
  if (sort.length === 0) return 'documentIdentifier:asc,documentName:asc';
  const mainSortField = `${sort[0]?.field}:${sort[0]?.sort}`;
  return sort[0]?.field === 'documentIdentifier'
    ? `${mainSortField},documentName:asc`
    : mainSortField;
};

export function showNewFolderForm(rows, folderId) {
  // eslint-disable-next-line no-underscore-dangle
  if (rows.find((row) => row?.__typename === 'NewFolder')) {
    return rows;
  }

  if (folderId) {
    return addFolderFormById(folderId, NEW_FOLDER, rows);
  }

  return addFolderFormLastItem(NEW_FOLDER, rows);
}

function addFolderFormById(id, newFolder, folders) {
  const newFolders = [...folders];
  const index = newFolders.findIndex((folder) => folder.folderId === id);

  if (index === -1) {
    newFolders.push(NEW_FOLDER);
    return newFolders;
  }
  return [...newFolders.slice(0, index + 1), newFolder, ...newFolders.slice(index + 1)];
}

function addFolderFormLastItem(newFolder, folders) {
  const newFolders = [...folders];
  const lastFolderIndex = newFolders.length - 1;

  if (lastFolderIndex === -1) {
    newFolders.unshift(newFolder);
  } else {
    newFolders.splice(lastFolderIndex + 1, 0, newFolder);
  }

  return newFolders;
}
