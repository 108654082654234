import React, { useState, useEffect } from 'react';

import ChangeShopConfigurationModal from 'modules/Shop/ShopProperties/InfoTab/ChangeShopConfigurationModal';
import CreateShopConfigurationModal from 'modules/Shop/ShopProperties/InfoTab/CreateShopConfigurationModal';
import useCreateShopConfiguration from 'modules/Shop/ShopProperties/InfoTab/useCreateShopConfiguration';

import { useShopConfigurationSelectContext } from './ShopConfigurationSelectContext';

const CreateShopConfiguration = ({ openCreateModal, setOpenCreateModal, setValue }) => {
  const [openChangeModal, setOpenChangeModal] = useState(false);
  const [shopConfiguration, setShopConfiguration] = useState(null);
  const { createShopConfiguration, loading } = useCreateShopConfiguration();
  const { shopConfigurations, setShopConfigurations, refetchShopConfigurations } = useShopConfigurationSelectContext();

  useEffect(
    () => () => {
      setShopConfiguration(null);
    },
    [],
  );

  const handleCloseCreateModal = () => {
    setOpenCreateModal(false);
  };

  const handleCreateShopConfiguration = (values) => {
    setShopConfiguration(values);
    handleCloseCreateModal();
    if (!values.IsActive) {
      setValue('ShopConfigurationId', values.value);
    } else {
      setOpenChangeModal(true);
    }
  };

  const handleCancelChange = () => {
    handleCloseCreateModal();
    setOpenChangeModal(false);
    setShopConfiguration(null);
  };

  const handleConfirmActive = async () => {
    const body = {
      ...shopConfiguration,
    };

    await createShopConfiguration(body, async (data) => {
      await refetchShopConfigurations();
      setValue('ShopConfigurationId', data?.addShopConfiguration.shopConfigurationId);
      setOpenChangeModal(false);
    });
  };

  return (
    <>
      <CreateShopConfigurationModal
        open={openCreateModal}
        setShopConfigurations={setShopConfigurations}
        shopConfigurations={shopConfigurations}
        onClose={handleCancelChange}
        onSubmit={handleCreateShopConfiguration}
        saveWhenIsActive={false}
        shopConfiguration={shopConfiguration}
      />

      {shopConfiguration && (
        <ChangeShopConfigurationModal
          open={openChangeModal}
          newConfigurationName={shopConfiguration.ShopConfigurationName}
          onClose={handleCancelChange}
          onSubmit={handleConfirmActive}
          onPrevious={() => {
            setOpenChangeModal(false);
            setOpenCreateModal(true);
          }}
          loading={loading}
        />
      )}
    </>
  );
};

export default CreateShopConfiguration;
