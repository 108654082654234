/* eslint-disable import/no-cycle */
import React from 'react';

import { Button, Radio, Stack } from '@mui/material';

import { useCurrentProjectContext } from '../context/CurrentProjectContext';

const useModalOperations = ({
  getWorkRequests,
  getWorkRequestsQuery,
  workRequests,
  setAddNewWRForm,
  setActiveStep,
  setCompleted,
  setIsLoading,
  setSelectionModel,
  setOpen,
  addNewWR,
  setOpenConfirmLeaveModal,
  showDialog,
  setShowDialog,
}: any) => {
  const { currentProject } = useCurrentProjectContext();

  const handleOnScrollEnd = () => {
    if (currentProject)
      getWorkRequests({
        take: workRequests.length + 20,
        ...getWorkRequestsQuery,
      });
  };
  const handleClose = (onClose = () => {}) => {
    onClose();
    setShowDialog(false);
    setOpenConfirmLeaveModal(false);
    setAddNewWRForm(false);
    setActiveStep(0);
    setCompleted({});
    setSelectionModel([]);
    setIsLoading(false);
    setOpen(false);
  };
  const handleConfirmClose = (onClose = () => {}) => {
    if (showDialog) {
      setOpenConfirmLeaveModal(true);
      return;
    }
    handleClose(onClose);
  };
  const handleNext = () => {
    setCompleted({ 0: true });
    setActiveStep((current: number) => current + 1);
  };
  const radioCheckColumn = {
    field: 'radioBtn',
    headerName: '',
    width: 56,
    sortable: false,
    renderCell: ({ id, api }: any) => {
      const cell: any = Array.from(api.getSelectedRows())[0] || [null];
      if (addNewWR && id === -1)
        return (
          <NewWRBtn
            onClick={() => {
              setAddNewWRForm(true);
              setSelectionModel([]);
            }}
          />
        );
      return <Radio color="secondary" size="small" checked={cell[0] === id} value={id} />;
    },
  };
  return {
    handleOnScrollEnd,
    handleClose,
    handleNext,
    handleConfirmClose,
    radioCheckColumn,
  };
};

export default useModalOperations;

const NewWRBtn = ({ onClick }: { onClick: (e: any) => void }) => (
  <Stack sx={{ position: 'relative', height: '48px' }}>
    <Stack
      sx={{
        position: 'absolute',
        height: '48px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Button
        onClick={(e) => onClick(e)}
        sx={{
          color: 'secondary.main',
          letterSpacing: '0.46px',
          fontWeight: 500,
          fontSize: '13px',
          lineHeight: '24px',
        }}
      >
        + NEW WORK REQUEST
      </Button>
    </Stack>
  </Stack>
);
