import React, { useCallback, useState } from 'react';

import { TableHead, TableRow, TableCell, Typography, TableContainer, Table, TableBody, useTheme } from '@mui/material';

import useAttachments from 'components/AttachmentsTable/hooks/useAttachments';

import AttachmentsTableRow from './AttachmentsTableRow';
import DeleteAttachmentModal from './DeleteAttachmentModal/DeleteAttachmentModal';
import { buildDarkStyles } from './helpers';

const AttachmentsTable = ({
  attachments,
  handleDelete,
  loadingDelete = false,
  darkMode = false,
  paginationWaypoint = () => {},
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedWorkRequestDocument, setSelectedWorkRequestDocument] = useState({});
  const { palette } = useTheme();

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedWorkRequestDocument(null);
  };

  const {
    metadata: { preSignedUrlLoading, selectedIndex },
    handleDownloadSingleFile,
    handleOpenSingleFile,
  } = useAttachments();

  const handleDeleteModal = useCallback(
    (document) => {
      setModalOpen(true);
      setSelectedWorkRequestDocument(document);
    },
    [setModalOpen, setSelectedWorkRequestDocument],
  );

  const handleDeleteFunc = async () => {
    await handleDelete(selectedWorkRequestDocument);
    handleModalClose();
  };

  if (attachments.length <= 0) return <></>;

  return (
    <>
      <TableContainer>
        <Table padding="none" aria-label="attachments-table" sx={buildDarkStyles(darkMode, palette)}>
          <TableHeader allowDelete={Boolean(handleDelete)} />
          <TableBody>
            {attachments?.map((item, index) => (
              <AttachmentsTableRow
                darkMode={darkMode}
                key={
                  item?.workRequestDocumentId || item?.taskDocumentId || item?.workOrderDocumentId || item?.documentId
                }
                item={item}
                loading={preSignedUrlLoading && index === selectedIndex}
                handleDownload={(event) => {
                  event.stopPropagation();
                  handleDownloadSingleFile(item, index);
                }}
                handleOpenFile={(event) => {
                  event.stopPropagation();
                  handleOpenSingleFile(item, index);
                }}
                handleDeleteModal={(event) => {
                  event.stopPropagation();
                  handleDeleteModal(item);
                }}
                allowDelete={Boolean(handleDelete)}
              />
            ))}
            {paginationWaypoint()}
          </TableBody>
        </Table>
      </TableContainer>
      <DeleteAttachmentModal
        modalOpen={modalOpen}
        selectedWorkRequestDocument={selectedWorkRequestDocument}
        handleModalClose={handleModalClose}
        handleDelete={handleDeleteFunc}
        loadingDelete={loadingDelete}
      />
    </>
  );
};

export default AttachmentsTable;

const TableHeader = ({ allowDelete }) => (
  <TableHead>
    <TableRow sx={{ height: '32px' }}>
      <TableCell>
        <Typography sx={{ marginLeft: '40px' }} variant="tableHeader">
          File name
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="tableHeader">Date</Typography>
      </TableCell>
      {allowDelete && <TableCell />}
    </TableRow>
  </TableHead>
);
