import { gql, useQuery } from '@apollo/client';

import { workOrderStatusesList as WORKORDER_LIST } from 'graphql/queries';

const useFetchWorkOrderStatusType = () => {
  const { data, loading } = useQuery(gql(WORKORDER_LIST));

  const workOrderStatusType = (data?.workOrderStatusesList || []).map((status) => ({
    value: status.workOrderStatusTypeId,
    label: status.workOrderStatusTypeName,
  }));

  return { loading, workOrderStatusType };
};

export default useFetchWorkOrderStatusType;
