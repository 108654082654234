import * as React from 'react';

import { NumberForesiteField } from 'components/FormComponents/NumberForesiteField';

import { validateMaxLengthRule } from './validateRule';

const noNegativeNumbersValidator = (value = '') => {
  if (value.length === 0) return null;
  if (Number.isNaN(parseInt(value, 10))) return 'Invalid';
  if (value.toString().includes('.')) return 'Invalid';
  if (parseInt(value, 10) < 0) return 'Invalid';
  return validateMaxLengthRule(value, 6);
};

export const QTYNumberInput = ({ control, ...rest }) => (
  <NumberForesiteField
    name="unitQuantity"
    placeholder="Unit Qty"
    size="small"
    color="secondary"
    control={control}
    rules={{
      validate: (value) => noNegativeNumbersValidator(value),
    }}
    maxLength={6}
    sx={{ margin: 0, height: 24, '& .MuiInputBase-root': { height: 24 } }}
    InputProps={{ style: { fontSize: 14 } }}
    InputLabelProps={{ shrink: false }}
    data-cy="unit-quantity-input"
    {...rest}
  />
);
