import React, { useMemo, useEffect, useState, useCallback } from 'react';

import { createSearchParams, useNavigate } from 'react-router-dom';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { useUser } from 'app/UserContext';
import { SESSION_STORAGE_CONSTANTS } from 'constants/globalConstants';
import useFolderAPI from 'hooks-api/useFolderAPI';
import useSetupModule from 'hooks/useSetupModule';
import useUrlParams from 'hooks/useUrlParams';

const { WR_PROJECT_SELECTED } = SESSION_STORAGE_CONSTANTS;

const DrawingRegisterContext = React.createContext();

const DrawingRegisterProvider = ({ children }) => {
  const { selectedItem } = useFacilitiesProjects();
  const navigate = useNavigate();
  const { user } = useUser();
  const companyId = useMemo(() => user?.companyId, [user]);

  const [rootFolder, setRootFolder] = useState(null);
  const [sortModel, setSortModel] = useState('documentIdentifier:asc,documentName:asc');
  const [uploadingDocuments, setUploadingDocuments] = useState([]);
  const [activeItem, setActiveItem] = useState(null);
  const [isTableEditMode, setIsTableEditMode] = useState(false);
  const { currentModule, currentPage } = useSetupModule();
  const { params: searchObj } = useUrlParams();

  const { fetchFoldersAPI: fetchRootFolder, fetchFoldersLoading: loadingRootFolder } =
    useFolderAPI();

  const navigateToFolder = (folderId) => {
    if (!folderId) {
      delete searchObj.folder;
      navigate({
        pathname: `/${currentModule()?.toLowerCase()}/${currentPage()}`,
        search: `?${createSearchParams(searchObj)}`,
      });
      return;
    }

    const params = { ...searchObj, folder: folderId };
    navigate({
      pathname: `/${currentModule()?.toLowerCase()}/${currentPage()}`,
      search: `?${createSearchParams(params)}`,
    });
  };

  useEffect(() => {
    const wrProjectSelected = sessionStorage.getItem(WR_PROJECT_SELECTED);
    const wrProjectSelectedJson = JSON.parse(wrProjectSelected);

    if (wrProjectSelectedJson) {
      setActiveItem(wrProjectSelectedJson);
    } else {
      setActiveItem(selectedItem);
    }

    navigateToFolder();

    return () => {
      sessionStorage.removeItem(WR_PROJECT_SELECTED);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  const onFetchRootFolder = useCallback(async () => {
    if (activeItem) {
      if (activeItem.type !== 'PROJECT') return;

      const { id: projectId } = activeItem;

      // whenever project is selected, reset folders
      setRootFolder(null);
      let payload = { projectIds: projectId, folderName: projectId };
      if (searchObj?.folder) {
        payload = { folderIds: searchObj.folder };
      }
      const { data, error } = await fetchRootFolder({
        variables: {
          query: {
            companyId,
            ...payload,
            skip: 0,
            take: 1,
          },
        },
      });
      if (error) return;
      setRootFolder(data?.folders[0] ?? null);
    }
  }, [fetchRootFolder, companyId, activeItem, searchObj.folder]);

  useEffect(() => {
    onFetchRootFolder();
  }, [onFetchRootFolder]);

  return (
    <DrawingRegisterContext.Provider
      value={{
        activeItem,
        setActiveItem,
        rootFolder,
        selectedFolder: rootFolder,
        sortModel,
        setSortModel,
        uploadingDocuments,
        setUploadingDocuments,
        loadingRootFolder,
        navigateToFolder,
        isTableEditMode,
        setIsTableEditMode,
      }}
    >
      {children}
    </DrawingRegisterContext.Provider>
  );
};

const useDrawingRegisterContext = () => {
  const context = React.useContext(DrawingRegisterContext);
  if (context === undefined) {
    throw new Error('useDrawingRegisterContext must be used within a DrawingRegisterContext');
  }
  return context;
};

export { DrawingRegisterContext, DrawingRegisterProvider, useDrawingRegisterContext };
