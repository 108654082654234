import React, { useState, useEffect, useMemo } from 'react';

import { Typography, Box, CircularProgress, useTheme, Stack, Divider } from '@mui/material';
import { useForm } from 'react-hook-form';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { useUser } from 'app/UserContext';
import useFetchFacilities from 'hooks-api/useFetchFacilities';
import useKeyPressEffect from 'hooks/useKeyPressEffect';

import { useWorkRequestsContext } from '../../../WorkRequestsContext';
import CustomSelectForm from './CustomSelectForm';

export const SelectField = () => {
  const { workRequestSelected, workOrderSelected } = useWorkRequestsContext();
  const { selectedItem } = useFacilitiesProjects();
  const [open, setOpen] = useState(false);
  const { palette } = useTheme();
  const { user } = useUser();

  const editable =
    !workOrderSelected &&
    selectedItem?.type === 'PROJECT' &&
    ['Draft', 'Canceled', 'Rejected', 'Submitted'].includes(workRequestSelected.workRequestStatusName);

  const { control, reset } = useForm({ mode: 'all' });

  const { loading: loadingFacilities, facilities } = useFetchFacilities({
    companyId: user.companyId,
    hasShopDepartment: true,
    showAvailableFacilities: true,
  });

  useEffect(() => {
    reset({ facilityId: workRequestSelected.facilityId });
    setOpen(false);
  }, [workRequestSelected, reset]);

  useKeyPressEffect('Escape', () => setOpen(false));

  const label = useMemo(() => {
    if (!workOrderSelected) return `${selectedItem?.type === 'PROJECT' ? 'Facility' : 'Project'} Name*`;
    return 'Facility Name';
  }, [workOrderSelected, selectedItem]);

  const selectName = useMemo(() => {
    if (workOrderSelected) return workOrderSelected.workRequest.facilityName;
    if (selectedItem?.type === 'PROJECT') return workRequestSelected?.facilityName;
    return workRequestSelected?.projectName;
  }, [selectedItem, workOrderSelected, workRequestSelected]);

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center" height="20px">
        <Typography color="white" variant="body2">
          {label}
        </Typography>
        {loadingFacilities ? (
          <CircularProgress size="22px" sx={{ color: palette.secondary.mainDarkBackground }} />
        ) : (
          <Box
            onClick={() => setOpen(true)}
            onMouseEnter={() => setOpen(!workOrderSelected)}
            onMouseLeave={() => setOpen(false)}
            height={open && editable ? '34px' : '22px'}
          >
            {open && editable ? (
              <>
                <CustomSelectForm setOpen={setOpen} control={control} facilities={facilities} />
              </>
            ) : (
              <Typography color="white" variant="body2" sx={{ pointerEvents: 'none' }}>
                {selectName}
              </Typography>
            )}
          </Box>
        )}
      </Stack>
      <Divider variant="middle" sx={{ margin: '8px 0 8px 0', borderColor: palette.primary.light }} />
    </>
  );
};
