import { MutationHookOptions, gql, useMutation } from '@apollo/client';

import { addWorkOrderItemFromCatalog as ADD_WORK_ORDER_ITEM_FROM_CATALOG } from 'graphql/mutations';
import { AddWorkOrderItemFromCatalogMutation, AddWorkOrderItemFromCatalogMutationVariables } from 'graphql/types';

const useAddWorkOrderItemFromCatalog = (
  options: MutationHookOptions<AddWorkOrderItemFromCatalogMutation, AddWorkOrderItemFromCatalogMutationVariables>,
) =>
  useMutation<AddWorkOrderItemFromCatalogMutation, AddWorkOrderItemFromCatalogMutationVariables>(
    gql(ADD_WORK_ORDER_ITEM_FROM_CATALOG),
    options,
  );

export default useAddWorkOrderItemFromCatalog;
