import { useState } from 'react';

const QUERY_ATTR_ID = 'data-rbd-drag-handle-draggable-id';

export default function usePlaceholder() {
  const [placeholderProps, setPlaceholderProps] = useState({});

  const showPlaceholder = (draggableId, destinationIndex) => {
    const domQuery = `[${QUERY_ATTR_ID}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    if (!draggedDOM) return;

    const { clientHeight, clientWidth } = draggedDOM;

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY: clientHeight * destinationIndex + 4,
      clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft),
    });
  };

  const hidePlaceholder = () => {
    setPlaceholderProps({});
  };

  return { placeholderProps, showPlaceholder, hidePlaceholder };
}
