import React from 'react';

import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';

import NavItem from './NavItem';

const NestedList = ({ item, children }) => {
  const [isOpen, setOpen] = React.useState(false);
  const toggleOpenList = () => setOpen(!isOpen);

  return (
    <>
      <NavItem item={item} isNestedList isExpanded={isOpen} onClick={toggleOpenList} />
      <Collapse
        in={isOpen}
        timeout="auto"
        unmountOnExit
        sx={{ backgroundColor: 'primary.outlinedHoverBackground', borderRadius: '5px' }}
      >
        <List component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </>
  );
};

export default NestedList;
