import { MaterialsConfigContextProvider } from 'hooks-api/useMaterialsConfig';
import { ProjectProvider } from 'modules/Field/LocationsAndWorkPhases/contexts/ProjectContext';

import { PartImageProvider } from './CatalogSetup/ItemsSection/Manufacturer/PartImage/PartImageContext';
import { CatalogPartProvider } from './CatalogSetup/Providers/CatalogPartProvider';
import { CatalogSelectedPartProvider } from './CatalogSetup/Providers/CatalogSelectedPartProvider';
import { ManufacturerAPIProvider } from './CatalogSetup/Providers/ManufacturerAPIProvider';
import { PartAPIProvider } from './CatalogSetup/Providers/PartAPIProvider';
import { UOMAPIProvider } from './CatalogSetup/Providers/UOMAPIProvider';

export const ProviderMaterialBase = ({ children }) => (
  <ProjectProvider>
    <MaterialsConfigContextProvider>
      <CatalogSelectedPartProvider>
        <PartAPIProvider>
          <UOMAPIProvider>
            <ManufacturerAPIProvider>
              <PartImageProvider>
                <CatalogPartProvider>{children}</CatalogPartProvider>
              </PartImageProvider>
            </ManufacturerAPIProvider>
          </UOMAPIProvider>
        </PartAPIProvider>
      </CatalogSelectedPartProvider>
    </MaterialsConfigContextProvider>
  </ProjectProvider>
);

export default ProviderMaterialBase;
