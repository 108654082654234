import React from 'react';

import { Tooltip, IconButton } from '@mui/material';

import { Custom } from 'components/Icons';
import { otherColors } from 'styles/theme/colors';

const AddIconButton = ({ showTooltip, showAddNewPad, disabled, isInputAvailable, scroll }) => {
  const isScrollActive = scroll.disabled;
  if (showTooltip) {
    return (
      <Tooltip title="New Pad" placement="top" arrow>
        <IconButton
          disabled={disabled}
          onClick={showAddNewPad}
          sx={{
            height: '30px',
            borderTop: '1px solid #CCCCCC',
            borderRadius: 0,
            borderLeft: !isScrollActive || isInputAvailable ? '1px solid #CCCCCC' : 'none',
          }}
          aria-label="add"
          data-testid="new-pad-button"
        >
          <Custom.Add style={{ fill: otherColors.black }} />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <IconButton
      onClick={showAddNewPad}
      disabled={disabled}
      sx={{
        height: 30,
        borderTop: '1px solid #CCCCCC',
        borderRadius: '0px',
        borderLeft: !isScrollActive || isInputAvailable ? '1px solid #CCCCCC' : 'none',
      }}
      aria-label="add"
      data-testid="new-pad-button"
    >
      <Custom.Add style={{ fill: otherColors.black }} />
    </IconButton>
  );
};

export default AddIconButton;
