import React from 'react';

import Button from '@mui/material/Button';

const makeButtonSxProps = (withBorder = false) => ({
  backgroundColor: 'primary.contrastText',
  height: '32px',
  fontWeight: '500',
  boxShadow: 'none',
  color: 'secondary.main',
  borderColor: 'secondary.main',
  border: withBorder ? '1px solid' : 'none',
});

const StatusButton = ({ buttonText, color, disabled, onClick, sx, ...props }) => (
  <Button
    variant="contained"
    color={color}
    disabled={disabled}
    sx={{ height: '32px', marginLeft: '8px', ...makeButtonSxProps(true), ...sx }}
    onClick={onClick}
    {...props}
  >
    {buttonText}
  </Button>
);

export default StatusButton;
