import * as React from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';

import { Custom } from 'components/Icons';

const RightSideFlyoutPaper = ({
  children,
  leading,
  onClose,
  onDock,
  width = 800,
  sx,
  divider = false,
  useXButton,
  topRightActionsSx,
}) => (
  <Paper
    sx={{
      zIndex: 5,
      backgroundColor: 'rgba(48, 48, 48, 0.95)',
      width,
      position: 'absolute',
      top: 64,
      bottom: 4,
      right: 56,
      display: 'flex',
      flexDirection: 'column',
      padding: 2,
      ...sx,
    }}
  >
    <TopRightActions onClose={onClose} useXButton={useXButton} onDock={onDock} topRightActionsSx={topRightActionsSx} />
    <FlexContainer divider={divider}>
      {/* <BackArrow /> */}
      {leading}
    </FlexContainer>
    {divider && <Divider variant="middle" sx={{ borderColor: '#bbb', marginX: '24px' }} />}
    {children}
  </Paper>
);

const TopRightActions = ({ useXButton, onDock, onClose, topRightActionsSx }) => (
  <Box display="flex" sx={{ position: 'absolute', top: 12, right: 12, ...topRightActionsSx }}>
    {onDock && (
      <IconButton disabled={!onDock} onClick={onDock}>
        <Custom.Dock color="disabled" />
      </IconButton>
    )}
    <IconButton onClick={onClose}>
      {useXButton ? <Custom.CloseIcon sx={{ color: 'white' }} /> : <Custom.DisableByDefault color="disabled" />}
    </IconButton>
  </Box>
);

const FlexContainer = ({ divider, children }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      marginBottom: divider ? '8px' : '',
    }}
  >
    {children}
  </Box>
);

export default RightSideFlyoutPaper;
