import * as React from 'react';

import { gql, useQuery } from '@apollo/client';

import { useUser } from 'app/UserContext';
import {
  unitOfMeasure as UNIT_OF_MEASURE,
  manufactured as MANUFACTURED,
  lineItemTypes as LINE_ITEM_TYPES,
  billOfMaterialsStatuses as BILL_OF_MATERIALS_STATUSES,
} from 'graphql/queries';

const MaterialsConfigContext = React.createContext();

export const MaterialsConfigContextProvider = ({ children }) => {
  const { user } = useUser();
  const { companyId } = user;

  // unitOfMeasure
  const { data: unitOfMeasure } = useQuery(gql(UNIT_OF_MEASURE), {
    variables: { query: { companyId } },
  });

  const unitOfMeasureOptions = React.useMemo(() => {
    const options = unitOfMeasure?.unitOfMeasure ?? [];
    return options.map(({ unitOfMeasureId, unitOfMeasureName, unitOfMeasureCode }) => ({
      label: unitOfMeasureName,
      value: unitOfMeasureId,
      code: unitOfMeasureCode,
    }));
  }, [unitOfMeasure]);

  const {
    data: manufactured,
    refetch,
    loading: loadingManufacturers,
  } = useQuery(gql(MANUFACTURED), {
    variables: { query: { companyId } },
    fetchPolicy: 'cache-and-network',
  });

  const onGetManufactured = () => refetch();

  const manufacturedOptions = React.useMemo(() => {
    const options = manufactured?.manufactured ?? [];
    return options.map(({ manufacturerId, manufacturerName }) => ({
      label: manufacturerName,
      id: manufacturerId,
    }));
  }, [manufactured]);

  // lineItemTypes
  const { data: lineItemTypes } = useQuery(gql(LINE_ITEM_TYPES));

  const lineItemTypesOptions = React.useMemo(() => {
    const options = lineItemTypes?.lineItemTypes ?? [];
    return options.map(({ lineItemTypeId, lineItemTypeName }) => ({
      label: lineItemTypeName,
      value: lineItemTypeId,
    }));
  }, [lineItemTypes]);

  const lineItemTypesMap = React.useMemo(
    () =>
      lineItemTypesOptions.reduce(
        (prev, current) => ({
          ...prev,
          [current.label.toLowerCase().replace('-', '')]: current.value,
        }),
        {},
      ),
    [lineItemTypesOptions],
  );

  // billOfMaterialsStatuses
  const { data: billOfMaterialsStatuses } = useQuery(gql(BILL_OF_MATERIALS_STATUSES));

  const billOfMaterialsStatusesOptions = React.useMemo(() => {
    const options = billOfMaterialsStatuses?.billOfMaterialsStatuses ?? [];
    const result = options.map(({ billOfMaterialsStatusId, billOfMaterialsStatusName }, index) => ({
      label: billOfMaterialsStatusName,
      value: billOfMaterialsStatusId,
      index: 1 - index,
    }));
    return result.sort((a, b) => a.label.localeCompare(b.label)).reverse();
  }, [billOfMaterialsStatuses]);

  return (
    <MaterialsConfigContext.Provider
      value={{
        unitOfMeasure: unitOfMeasureOptions,
        manufactured: manufacturedOptions,
        loadingManufacturers,
        lineItemTypes: lineItemTypesOptions,
        lineItemTypesMap,
        billOfMaterialsStatuses: billOfMaterialsStatusesOptions,
        callbacks: {
          onGetManufactured,
        },
      }}
    >
      {children}
    </MaterialsConfigContext.Provider>
  );
};

export const useMaterialsConfig = () => {
  const context = React.useContext(MaterialsConfigContext);
  if (context === undefined) {
    throw new Error('useMaterialsConfig must be used within a MaterialsConfigContextProvider');
  }
  return context;
};
