import React from 'react';

import { grey } from '@mui/material/colors';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';

import HelpDeskMenuContent from './HelpDeskMenuContent';

const HelpDeskDropdownMenu = ({ open, onClose, openModal, activeModal, anchorRef }) => (
  <HelpDeskDropdownMenuWrapper open={open} onClose={onClose} anchorEl={anchorRef}>
    {open && <HelpDeskMenuContent openEULA={() => openModal(activeModal.EULA)} />}
  </HelpDeskDropdownMenuWrapper>
);

export default HelpDeskDropdownMenu;

const HelpDeskDropdownMenuWrapper = ({ open, anchorEl, children }) => (
  <Popper open={open} anchorEl={anchorEl} role={undefined} transition placement="bottom-end">
    {({ TransitionProps }) => (
      <Grow {...TransitionProps} style={{ transformOrigin: 'top right' }}>
        <Paper
          sx={{
            marginTop: '18px',
            backgroundColor: grey[800],
            color: grey[500],
          }}
        >
          {children}
        </Paper>
      </Grow>
    )}
  </Popper>
);
