import React from 'react';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { StatusCell, ThemedCheckBox } from 'components';
import MoreOptionsDropdown from 'components/TableComponents/MoreOptionsDropdown/MoreOptionsDropdown';

import MoreTableRowOptions from './MoreTableRowOptions';

const ProjectTableRow = ({ member, handleRowClick, isItemSelected }) => (
  <TableRow
    key={member.projectUserId}
    role="checkbox"
    hover
    tabIndex={-1}
    aria-checked={isItemSelected}
    selected={isItemSelected}
  >
    <TableCell padding="checkbox">
      <ThemedCheckBox
        checked={isItemSelected}
        inputProps={{ 'aria-labelledby': member.projectUserId }}
        onClick={(e) => handleRowClick(e, member)}
      />
    </TableCell>
    <TableCell align="left">{member.user.userIdentifier || '--'}</TableCell>
    <TableCell align="left">{`${member.user.userFirstName || '--'} ${
      member.user.userLastName || '--'
    }`}</TableCell>
    <TableCell align="left">{member.user.userEmail || '--'}</TableCell>
    <TableCell align="left">--</TableCell>
    <TableCell align="left">{member.user.workPhoneNumber || '--'}</TableCell>
    <TableCell align="left">{member.user.personalPhoneNumber || '--'}</TableCell>
    <StatusCell
      status={member.user.isDeleted || member.isDeleted ? 'Deleted' : member.inviteStatus}
    />
    <TableCell>
      <MoreOptionsDropdown
        optionsRenderer={(onClose) => <MoreTableRowOptions member={member} onClose={onClose} />}
      />
    </TableCell>
  </TableRow>
);

export default ProjectTableRow;
