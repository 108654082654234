import React from 'react';

import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Stepper from '@mui/material/Stepper';

const stepperStyles = {
  '& .MuiSvgIcon-root.Mui-completed': {
    color: 'secondary.main',
  },
  '& .MuiSvgIcon-root.Mui-active': {
    color: 'secondary.main',
  },
  '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
    fill: 'common.white',
  },
  '& .MuiStepLabel-root .Mui-disabled': {
    color: 'surface.lightSurface.secondary',
  },
  '& .MuiStepButton-root.MuiStepButton-horizontal': {
    padding: '16px',
    margin: '-16px',
  },
};

const stepperClickDisabledStyles = {
  '& .MuiButtonBase-root': {
    pointerEvents: 'none !important',
  },
};

export default function WizardStepper({
  steps,
  completed,
  activeStep,
  setActiveStep,
  canClickSteps = true,
  handleClickStep,
}) {
  const handleStep = (step) => () => {
    if (handleClickStep) {
      handleClickStep();
      return;
    }
    if (canClickSteps) setActiveStep(step);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ maxWidth: 305 }}>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step
              key={label}
              completed={completed[index]}
              sx={{ ...stepperStyles, ...(!canClickSteps ? stepperClickDisabledStyles : {}) }}
              disabled={index > activeStep && !completed[index] && !completed[index - 1]}
            >
              <StepButton color="secondary" onClick={handleStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Box>
  );
}
