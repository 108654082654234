import { useFormContext } from 'react-hook-form';

import { ManufacturersAutocomplete } from '../../Form';
import { BOMCatalogTableFields, BOMCatalogTableHeaders, isItemAPart } from './BOMTableColumnsHelpers';

export const getManufacturerColumn = () => ({
  useForm: true,
  field: BOMCatalogTableFields.MANUFACTURER_NAME,
  headerName: BOMCatalogTableHeaders[BOMCatalogTableFields.MANUFACTURER_NAME],
  flex: 1.2,
  editable: true,
  resizable: true,
  renderEditCell: ({ row }) => <ManufacturersEditCell row={row} />,
});

const ManufacturersEditCell = ({ row }) => {
  const { formState } = useFormContext();
  const disableManufacturerField = isItemAPart(row?.lineItemTyeId) || formState.isSubmitting;
  return <ManufacturersAutocomplete disabled={disableManufacturerField} />;
};
