import React from 'react';

import { FormGroup } from '@mui/material';
import { useForm } from 'react-hook-form';

import useUpdateProjectMutation from '../useUpdateProjectMutation';
import PrivacyForm from './PrivacyForm';

const PrivacyTab = ({ project }) => {
  const { updateProject } = useUpdateProjectMutation({ selectedProjectId: project?.projectId });
  const { handleSubmit, watch, control, setValue } = useForm({
    mode: 'all',
    defaultValues: {
      dataAnalytics: project?.dataAnalytics,
    },
  });

  const onCheckChanged = () => {
    handleSubmit(updateProject)();
  };

  return (
    <FormGroup sx={{ maxWidth: 560 }}>
      <PrivacyForm
        onCheckChanged={onCheckChanged}
        watch={watch}
        control={control}
        setValue={setValue}
      />
    </FormGroup>
  );
};

export default PrivacyTab;
