import { gql, useMutation } from '@apollo/client';

import { mapWorkPhaseToLocation as MAP_WORK_PHASE_TO_LOCATION } from 'graphql/mutations';
import { clone, findNode, recursiveUpdateNode } from 'helpers/arrayFunctions';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

import { useLocationsAndWorkPhases } from '../contexts/LocationsAndWorkPhaseContext';

export default function useLocationsDataLayer() {
  const { handleResponse } = useGraphqlResponseHandler();
  const {
    locations: { setLocations, locations, setBackupState, goToBackup, toggleOpenNode },
    workPhases: { updateSuffix, fetchLocationsByWorkPhase },
  } = useLocationsAndWorkPhases();

  const [mapWorkPhaseToLocation] = useMutation(gql(MAP_WORK_PHASE_TO_LOCATION));

  const mapWorkPhasesToLocation = (locationId, workphasesIds) => {
    setBackupState(clone(locations));
    handleResponse(
      mapWorkPhaseToLocation,
      { variables: { body: { locationId, workphasesIds } } },
      {
        successMessage: `Work Phase${workphasesIds.length > 1 ? 's' : ''} successfully mapped`,
      },
      ({
        mapWorkPhaseToLocation: {
          location: { locationPhases },
        },
      }) => {
        goToBackup();
        const updatedNode = clone(findNode(locationId, locations, 'locationId'));
        updatedNode.locationPhases = locationPhases;
        setLocations(recursiveUpdateNode(updatedNode, locations, 'locationId'));
        toggleOpenNode(updatedNode, null, true);
        updateSuffix();
        fetchLocationsByWorkPhase(workphasesIds);
      },
    );
  };

  return { mapWorkPhasesToLocation };
}
