import React from 'react';

import { Box, Typography } from '@mui/material';

import { Custom } from 'components/Icons';
import { surfaceColors } from 'styles/theme/colors';

const SelectProjectMessage = ({ message, description }) => (
  <Box sx={{ textAlign: 'center', width: '100%', height: '100%' }}>
    <Box sx={{ '& svg': { height: '46px', width: '46px', path: { fill: '#BDBDBD' } } }}>
      <Custom.WorkRequests data-testid="custom-svg" />
    </Box>
    <Typography variant="h6" color={surfaceColors.lightSurface.secondary}>
      {message}
    </Typography>
    <Typography variant="h6" color={surfaceColors.lightSurface.secondary}>
      {description}
    </Typography>
  </Box>
);

export default SelectProjectMessage;
