import React from 'react';

import { Box, CircularProgress } from '@mui/material';

import NavItemTooltip from 'components/Tooltip/NavItemTooltip';

import { DrawingDocumentMoreOptions } from '../DrawingDocumentRowMoreOptions';

const StatusType = ({ title, placement, icon, loading, onRenameClick, row }) => {
  const processingRow = !row?.isEditing || !row?.isProcessing;
  const showOptions = processingRow && !loading;

  return (
    <>
      {showOptions && <DrawingDocumentMoreOptions onRenameClick={onRenameClick} document={row} />}
      <Box sx={{ width: '100px', textAlign: 'right' }}>
        {loading ? (
          <CircularProgress size={20} sx={{ color: 'secondary.main' }} data-testid="loading" />
        ) : (
          <NavItemTooltip title={title} placement={placement}>
            {icon}
          </NavItemTooltip>
        )}
      </Box>
    </>
  );
};

export default StatusType;
