import React, { useEffect } from 'react';

import { useForm } from 'react-hook-form';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { debounce } from 'helpers/common';
import { useConfigurationNameInputRules } from 'hooks/useConfigurationNameInputRules';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

import ShopConfigurationModal from './ShopConfigurationModal';
import useAddShopConfigurationToCache from './useAddShopConfigurationToCache';
import useCreateShopConfiguration from './useCreateShopConfiguration';
import useGetShopConfigurations from './useGetShopConfigurations';

const CreateShopConfigurationModal = ({
  open,
  setShopConfigurations,
  shopConfiguration,
  onClose,
  onSubmit,
  saveWhenIsActive = true,
}) => {
  const { handleAddShopConfigurationToCache } = useAddShopConfigurationToCache();
  const { multiSearch, validateTypingText } = useGetShopConfigurations();
  const { selectedItem, shopDepartmentId } = useFacilitiesProjects();
  const { handleResponse } = useGraphqlResponseHandler(onClose);
  const { addShopConfiguration, loading } = useCreateShopConfiguration();

  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
    watch,
    setError,
    clearErrors,
    trigger,
  } = useForm({ mode: 'all' });

  const { configurationNameInputRules, shopConfigurationsMultiSearch } = useConfigurationNameInputRules(
    multiSearch,
    setError,
    clearErrors,
    validateTypingText,
  );

  const handleClose = () => {
    reset();
    onClose();
    shopConfigurationsMultiSearch('');
  };

  useEffect(() => {
    if (open) {
      reset({
        ShopConfigurationName: shopConfiguration?.ShopConfigurationName ?? '',
        ShopConfigurationDescription: shopConfiguration?.ShopConfigurationDescription ?? '',
        IsActive: shopConfiguration?.IsActive ?? false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, shopConfiguration]);

  useEffect(() => {
    const debouncedCb = debounce(({ ShopConfigurationName }) => {
      if (!ShopConfigurationName) return;
      shopConfigurationsMultiSearch(ShopConfigurationName);
      trigger('ShopConfigurationName');
    }, 300);

    const subscription = watch(debouncedCb);

    return () => subscription.unsubscribe();
  }, [trigger, shopConfigurationsMultiSearch, watch]);

  useEffect(() => configurationNameInputRules.validate(), [configurationNameInputRules]);

  const handleCreate = async (values) => {
    const facilityId = selectedItem?.type === 'FACILITY' ? selectedItem?.id : null;
    const body = buildBodyForAddShopConfiguration(values, facilityId, shopDepartmentId);

    if (!saveWhenIsActive && values.IsActive) {
      onSubmit(body);
      return;
    }

    const { data } = await handleResponse(addShopConfiguration, { variables: { body } });
    const createdShopConfiguration = buildNewShopConfiguration(data, values);
    setShopConfigurations((prevState) => [...prevState, createdShopConfiguration]);
    onSubmit(createdShopConfiguration);
    handleAddShopConfigurationToCache(data.addShopConfiguration, selectedItem?.id);
  };

  return (
    <ShopConfigurationModal
      title="Create Shop Configuration"
      open={open}
      onClose={handleClose}
      control={control}
      loading={loading}
      isValid={isValid}
      onSubmit={handleSubmit(handleCreate)}
      configurationNameInputRules={configurationNameInputRules}
    />
  );
};

export default CreateShopConfigurationModal;

const buildNewShopConfiguration = (data, values) => ({
  label: data.addShopConfiguration.shopConfigurationName,
  value: data.addShopConfiguration.shopConfigurationId,
  isActive: values.IsActive,
  description: values.ShopConfigurationDescription,
});

const buildBodyForAddShopConfiguration = (values, facilityId, shopDepartmentId) => ({
  ShopConfigurationName: values.ShopConfigurationName,
  ShopConfigurationDescription: values.ShopConfigurationDescription,
  FacilityId: facilityId,
  DepartmentId: shopDepartmentId,
  IsActive: values.IsActive,
});
