/* eslint-disable max-lines-per-function */
import React, { useMemo, useCallback } from 'react';

import { Box, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { GRID_CHECKBOX_SELECTION_COL_DEF, GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro';
import { useFormContext } from 'react-hook-form';

import { Custom } from 'components/Icons';
import OverflowTip from 'components/Tooltip/OverflowTip';
import { DOCUMENT_STATUS_TYPE } from 'constants/globalConstants';
import { debounce } from 'helpers/common';
import useFetchDocumentsByIdentifier from 'hooks-api/useFetchDocumentsByIdentifier';

import { useDrawingRegisterContext } from '../../DrawingRegisterContext';
import DrawingDocumentField from '../components/DrawingDocumentField/DrawingDocumentField';
import DrawingFolderPanelToggle from '../components/DrawingFolderPanelToggle/DrawingFolderPanelToggle';
import DrawingRenderCellEncondedUrn from '../components/DrawingRenderCellEncondedUrn/DrawingRenderCellEncondedUrn';
import { DRAWING_DOCUMENT_VIEWS, useDrawingDocumentContext } from '../DrawingDocumentContext';
import { useDrawingFolderContext } from '../DrawingFolderContext';
import { DrawingDocumentType } from './constants';

const noDuplicateItemIdentifier = (value, documentsById, id, folderId) => {
  let itemIdentifierDuplicated = false;

  if (value) {
    itemIdentifierDuplicated = (documentsById || []).some(
      (document) =>
        document.documentIdentifier.trim().toLowerCase() === value.trim().toLowerCase() &&
        id !== document.documentId &&
        document.folderDocumentResponse[0].folderId === folderId,
    );
  }

  return itemIdentifierDuplicated;
};

const DrawingDocumentIdentifierField = ({ row, updateRows, closeFields }) => {
  const { id } = row;
  const { selectedFolder } = useDrawingRegisterContext();

  const methods = useFormContext();
  const { setError, watch, clearErrors } = methods;

  const handleValidationDocumentIdentifier = (documentsById) => {
    if (noDuplicateItemIdentifier(watch('documentIdentifier'), documentsById, row.id, selectedFolder.folderId)) {
      setError('documentIdentifier', { type: 'manual', message: 'Id already exits' });
    } else {
      clearErrors('documentIdentifier');
    }
    updateRows([
      {
        id: row.id,
        isValidating: false,
      },
    ]);
  };

  const { getDocumentsByIdentifier } = useFetchDocumentsByIdentifier(handleValidationDocumentIdentifier);

  function getDocumentsByIdentifierValidation(event) {
    updateRows([
      {
        id: row.id,
        isValidating: true,
      },
    ]);
    if (event.target.value)
      getDocumentsByIdentifier({
        variables: {
          query: {
            documentIdentifier: event.target.value,
          },
        },
      });
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetDocumentsByIdentifierValidation = useCallback(
    debounce(getDocumentsByIdentifierValidation, 1000),
    [],
  );

  return (
    <Box display="flex" alignItems="center">
      <Custom.CloseIcon
        sx={{ fontSize: '24px', marginRight: '9px', color: 'action.lightSurface.active' }}
        onClick={(e) => closeFields(e, row.id, updateRows)}
      />
      <DrawingDocumentField
        name="documentIdentifier"
        row={row}
        updateRows={updateRows}
        maxLength={64}
        id={`drawingIdentifier${id}`}
        onChange={debouncedGetDocumentsByIdentifierValidation}
        autoFocus={row.field === 'documentIdentifier'}
      />
    </Box>
  );
};

const DrawingDocumentLink = ({ setCurrentView, setActiveDocumentId, value, row, field }) => {
  const { documentStatusTypeId } = row;
  const isSupportedExtension = ['.pdf', '.rvt', '.nwd'].includes(row.extension.toLowerCase());
  const isLoading = documentStatusTypeId === DOCUMENT_STATUS_TYPE.LOADING_DOCUMENT;
  const isConverting = documentStatusTypeId === DOCUMENT_STATUS_TYPE.CONVERTING_DOCUMENT;

  if (!isSupportedExtension || isLoading || isConverting)
    return <DrawingDocumentInformationCell value={value} field={field} cellSx={{ width: '400px' }} />;

  return (
    <DrawingDocumentInformationCell
      value={value}
      field={field}
      cellSx={{
        width: '400px',
        cursor: 'pointer',
        color: 'secondary.main',
        '&:hover': { textDecoration: 'underline' },
      }}
      onClick={(event) => {
        event.stopPropagation();
        setCurrentView(DRAWING_DOCUMENT_VIEWS.DOCUMENT_DETAIL);
        setActiveDocumentId(row.id);
      }}
    />
  );
};

const DrawingDocumentInformationCell = ({ value, sx, field, cellSx, onClick = () => {} }) => (
  <Box data-cell={field} sx={cellSx} onClick={onClick}>
    <OverflowTip title={value}>
      <Typography variant="body2" sx={{ ...sx }} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
        {value}
      </Typography>
    </OverflowTip>
  </Box>
);

const useDrawingDocumentsTableColumns = (apiRef, depth = 0, setCheckboxFolderId = () => {}) => {
  const { updateRows } = apiRef.current;
  const { closeFields, handleUpdateRow, setCurrentView, setActiveDocumentId } = useDrawingDocumentContext();
  const { folders, handleUpdateFolderRow, openNewFolderFormRow } = useDrawingFolderContext();
  const { setIsTableEditMode } = useDrawingRegisterContext();
  const theme = useTheme();

  const handleCheckboxClick = () => setCheckboxFolderId(null);

  const columns = useMemo(
    () => [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        type: '',
        hideable: false,
        disableReorder: true,
        flex: 0.1,
        renderCell: (params) => {
          const { field, row } = params;
          if (
            field === GRID_CHECKBOX_SELECTION_COL_DEF.field &&
            (row.type === DrawingDocumentType.FOLDER || row.type === DrawingDocumentType.NEW_FOLDER_FORM)
          ) {
            return '';
          }
          return <Box onClick={handleCheckboxClick}>{GRID_CHECKBOX_SELECTION_COL_DEF.renderCell(params)}</Box>;
        },
      },
      {
        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        minWidth: 20,
        maxWidth: 20,
        renderCell: (params) => {
          const { row } = params;
          return row.type === DrawingDocumentType.FOLDER && row.folder.hasSubFolder ? (
            <DrawingFolderPanelToggle id={params.id} value={params.value} />
          ) : (
            ''
          );
        },
      },
      {
        field: 'documentIdentifier',
        headerName: 'Drawing Id',
        flex: 1,
        renderCell: (params) => {
          const { row, value, field } = params;
          const iconColor = row?.isMappedFromShop
            ? theme.palette.secondary.main
            : theme.palette.action.lightSurface.active;
          if (row.type === DrawingDocumentType.DOCUMENT && row.isEditing)
            return <DrawingDocumentIdentifierField row={row} updateRows={updateRows} closeFields={closeFields} />;
          return row.type === DrawingDocumentType.DOCUMENT ? (
            <Box display="flex" alignItems="center">
              <Custom.SmallDrawingDocument onClick={(e) => e.preventDefault()} fill={iconColor} />
              <DrawingDocumentInformationCell
                sx={{ color: 'rgba(0, 0, 0, 0.87)', ml: 1, width: '60px' }}
                value={value}
                field={field}
              />
            </Box>
          ) : (
            <Box display="flex" alignItems="center">
              <Custom.FolderOpen fill={theme.palette.action.lightSurface.active} />
            </Box>
          );
        },
        sortingOrder: ['asc', 'desc'],
      },
      {
        field: 'documentName',
        headerName: 'Name',
        flex: 4,
        renderCell: (params) => {
          const { row, value, field } = params;
          if (row.isEditing) {
            return (
              <DrawingDocumentField
                name="documentName"
                id={`drawingName${row.id}`}
                row={row}
                updateRows={updateRows}
                maxLength={128}
                rules={{ required: '*Required' }}
                autoFocus={row.field === 'documentName'}
              />
            );
          }

          if (row.type === 'document' && row.extension === '.pdf') {
            return (
              <DrawingDocumentLink
                row={row}
                value={value}
                setCurrentView={setCurrentView}
                setActiveDocumentId={setActiveDocumentId}
                field={field}
              />
            );
          }
          return <DrawingDocumentInformationCell row={row} value={value} field={field} />;
        },
        sortingOrder: ['asc', 'desc'],
      },
      {
        field: 'encodedUrn',
        headerName: '',
        sortable: false,
        flex: 1,
        renderCell: (params) => (
          <>
            <DrawingRenderCellEncondedUrn
              value={params.value}
              row={params.row}
              depth={depth}
              onRenameClick={() => {
                const { row } = params;
                setIsTableEditMode(true);
                if (row.type === DrawingDocumentType.FOLDER) {
                  handleUpdateFolderRow(params.row, 'documentName', updateRows);
                  return;
                }
                handleUpdateRow(params.row, 'documentName', updateRows);
              }}
              onNewFolder={() => {
                openNewFolderFormRow(params.row, folders);
              }}
              documentId={params.id}
            />
          </>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [theme.palette, folders, updateRows, depth],
  );

  return { columns };
};

export default useDrawingDocumentsTableColumns;
