import React from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Controller } from 'react-hook-form';

import { Custom } from 'components/Icons';

const icon = <Custom.CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <Custom.CheckBox fontSize="small" />;

const ControlledProductPoolAutocomplete = ({
  options = [],
  control,
  name,
  label,
  rules,
  defaultValue = '',
  getOptionLabel = (option) => option.label,
  ...props
}) => (
  <Controller
    control={control}
    name={name}
    rules={rules}
    shouldUnregister
    defaultValue={defaultValue}
    render={({ field: { onChange, value, error } }) => (
      <Autocomplete
        multiple
        error={error}
        value={value}
        options={options}
        disableCloseOnSelect
        onChange={(e, newValue) => onChange(newValue)}
        getOptionLabel={getOptionLabel}
        renderOption={(props, option, { selected }) => (
          <CheckboxOption selected={selected} option={option} {...props} key={option.productPoolId} />
        )}
        forcePopupIcon={false}
        isOptionEqualToValue={(option, value) => option.productPoolId === value.productPoolId}
        renderInput={(params) => (
          <TextField {...params} sx={{ mb: 1 }} label={label} {...props} color="secondary" fullWidth />
        )}
      />
    )}
  />
);

export default ControlledProductPoolAutocomplete;

const CheckboxOption = ({ option, selected, ...props }) => {
  const key = option?.label?.split(' ')?.join('')?.toLowerCase();
  return (
    <li {...props}>
      <Checkbox
        data-cy={`option-autocomplete-${key}`}
        icon={icon}
        checkedIcon={checkedIcon}
        sx={{ marginRight: 1 }}
        checked={selected}
      />
      <Typography variant="subtitle1" sx={{ fontSize: '14px' }}>
        {`${option.label}  (${option.poolAvailableQuantity} available)`}
      </Typography>
    </li>
  );
};
