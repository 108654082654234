import React, { useEffect, useState } from 'react';

import { CircularProgress } from '@mui/material';
import { parseISO, isAfter } from 'date-fns';
import { Navigate } from 'react-router-dom';

import useQuery from 'hooks/useQuery';

import { confirm, fetchUserStatus } from './auth/restCalls';
import Join from './Join/Join';

const UserStatus = {
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  EMAIL_NOT_FOUND: 'EMAIL_NOT_FOUND',
  CONFIRMED: 'CONFIRMED',
};

const UserErrorCode = {
  USER_NOT_FOUND_EXCEPTION: 'UserNotFoundException',
  INVITATION_EXPIRED: 'InvitationExpired',
};

const ValidateType = {
  PROJECT: 'project',
  DEPARTMENT: 'department',
};

const AcceptInvitation = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [error, setError] = useState(false);
  const [errorCode, setErrorCode] = useState('');

  const queryParams = useQuery();
  const userId = queryParams.get('userId');
  const name = queryParams.get('name');
  const type = queryParams.get('type');
  const id = queryParams.get('id');
  const code = queryParams.get('code');
  const expirationDateStr = queryParams.get('expiration');

  useEffect(() => {
    const expirationDate = parseISO(expirationDateStr);
    const hasExpired = isAfter(new Date(), expirationDate);
    if (hasExpired) {
      setLoading(false);
      setError(true);
      setErrorCode(UserErrorCode.INVITATION_EXPIRED);
      return;
    }

    const checkUser = async () => {
      const userResponse = await fetchUserStatus(userId);

      const isUserNotFound = [UserStatus.USER_NOT_FOUND, UserStatus.EMAIL_NOT_FOUND].includes(
        userResponse.userStatus,
      );
      if (isUserNotFound) {
        setUser(null);
        setError(true);
        setErrorCode(UserErrorCode.USER_NOT_FOUND_EXCEPTION);
        setLoading(false);
        return;
      }
      setUser(userResponse);
      setLoading(false);
    };

    checkUser();
  }, [code, id, type, userId, expirationDateStr]);

  useEffect(() => {
    const checkValidations = async () => {
      const body = {
        confirmationCode: code,
      };

      const validateType = [ValidateType.PROJECT, ValidateType.DEPARTMENT].includes(type);

      if (validateType) {
        try {
          setLoading(true);
          await confirm(id, type, body);
        } finally {
          setLoading(false);
        }
      }
    };

    checkValidations();
  }, [code, id, type]);

  const LoadedPath = () => {
    if (error) {
      if (errorCode === UserErrorCode.INVITATION_EXPIRED) {
        return <Navigate to="/invitation-expired" />;
      }

      const navigatePath = errorCode ? `/auth/login?errorCode=${errorCode}` : '/auth/login';
      return <Navigate to={navigatePath} />;
    }

    return user.userStatus === 'CONFIRMED' ? (
      <Navigate to="/auth/login" />
    ) : (
      <Join name={name} type={type} id={id} code={code} />
    );
  };

  return loading ? <CircularProgress /> : <LoadedPath />;
};

export default AcceptInvitation;
