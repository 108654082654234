export function getParentFolders(folderId, folders) {
  const parentFolder = folders.find((folder) => folder.folderId === folderId);
  if (!parentFolder) return [];
  if (!folders.some((folder) => folder.folderId === parentFolder?.parentFolderId)) {
    return [parentFolder];
  }
  const parentFolders = getParentFolders(parentFolder.parentFolderId, folders);
  parentFolders.push(parentFolder);
  return parentFolders;
}
