export const createOption = (label, clickEvent, color, disabled) => ({
  label,
  clickEvent,
  color,
  disabled,
});

export const getFirstChildType = (node) => {
  if (node?.children) return node?.children[0]?.locationTypeName;
  if (node?.childrenTypeName) return node?.childrenTypeName;
  return null;
};

export const hasHiddenChildren = (node) => !node.children?.length && node.childrenTypeName;

export const childrenIncludeType = (node, type) =>
  node.children?.some((child) => child.locationTypeName === type) || node.childrenTypeName === type;
