import React from 'react';

import { Button } from '@mui/material';

import { ConfirmButtonProps } from './@types/buttons';

const ConfirmButton = ({ color, sx, buttonText = 'Confirm', onClick }: ConfirmButtonProps) => (
  <Button sx={{ color, ...sx }} onClick={onClick}>
    {buttonText}
  </Button>
);

export default ConfirmButton;
