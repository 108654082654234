import { useEffect } from 'react';

import { gql, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';

import { addLocationWizard as ADD_LOCATION_WIZARD } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import { useLocationsAndWorkPhases } from 'modules/Field/LocationsAndWorkPhases/contexts/LocationsAndWorkPhaseContext';
import { useLocationTypeAndFormat } from 'modules/Field/LocationsAndWorkPhases/contexts/LocationTypeAndFormatContext';

import { useChips } from '../CreateLocationsModalContext';
import { buildWizardBody } from '../helpers';

export default function useFormValues(defaultValues, onClose) {
  const { locationTypes: types, locationFormats: formats } = useLocationTypeAndFormat();
  const {
    locations: { activeRow, locations, addWizardLocations },
    workPhases: { getWorkPhases },
  } = useLocationsAndWorkPhases();
  const { handleResponse } = useGraphqlResponseHandler();
  const { updateChipWithFormValues, chips } = useChips();

  const { control, handleSubmit, reset, setValue, watch, trigger, formState } = useForm({
    mode: 'all',
    defaultValues,
  });

  const [addLocationMutation, { loading }] = useMutation(gql(ADD_LOCATION_WIZARD));

  const formValues = watch();

  const {
    locationTypeName,
    locationFormatId,
    identifierPrefix,
    identifierDelimiter,
    startOn,
    namePrefix,
    nameDelimiter,
    numberOfNodes,
    scopePackage,
    workPhase,
  } = formValues;

  const submit = async () => {
    const body = buildWizardBody(locations[0].locationId, activeRow, chips, types, formats)[0];
    await handleResponse(
      addLocationMutation,
      { variables: { body } },
      { successMessage: 'Locations successfully created' },
      ({ addLocationWizard: addedLocations }) => {
        addWizardLocations(addedLocations);
        getWorkPhases();
        onClose();
      },
    );
  };

  useEffect(() => {
    updateChipWithFormValues(formValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    locationTypeName,
    locationFormatId,
    identifierPrefix,
    identifierDelimiter,
    startOn,
    namePrefix,
    nameDelimiter,
    numberOfNodes,
    scopePackage,
    workPhase,
  ]);

  const formEvents = { control, handleSubmit, reset, setValue, trigger };
  const formStatus = { formValues, loading, submit, formState };

  return { ...formEvents, ...formStatus };
}
