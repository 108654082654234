import React, { ComponentProps, ReactNode, useMemo, useState } from 'react';

import { MoreMenu } from '@evolvemep/foresite-components-ui-react';
import { Stack, Typography, useTheme } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-pro';

import DeleteModal from 'components/DeleteModal';
import { BillOfMaterialItem } from 'graphql/types';
import { LINE_ITEM_TYPE_ASSEMBLY_ID } from 'modules/Materials/Constants/constants';
import { useCustomAssemblyTypes } from 'modules/Shop/CustomAssemblies/hooks/useCustomAssemblyTypes';

import { isItemAPart, isItemAWriteIn, BOMCatalogTableFields } from './hooks/useBOMTableColumns/BOMTableColumnsHelpers';

interface WrapperMoreOptionBomItemProps {
  children: ReactNode;
  billOfMaterialItem: BillOfMaterialItem;
  deleteDisable: boolean;
  onAddSubItem: () => void;
  id: string;
}

type MoreMenuProps = ComponentProps<typeof MoreMenu>;

const customContentModal = {
  [LINE_ITEM_TYPE_ASSEMBLY_ID]: (assembly: string) => (
    <Typography variant="body1" mb="12px">
      Are you sure you want to delete{' '}
      <Typography component="span" variant="body1" sx={{ wordBreak: 'break-word', fontWeight: 700 }}>
        {assembly}
      </Typography>
      ? Deleting assemblies/sub-assemblies will also delete all child items under them.
    </Typography>
  ),
};

const noVisibleStyles = {
  '.MuiDataGrid-row & > svg': {
    display: 'none',
  },
  '.MuiDataGrid-row:hover & > svg': {
    display: 'block',
  },
};

export const WrapperMoreOptionBomItem = ({
  billOfMaterialItem,
  deleteDisable,
  onAddSubItem = () => {},
}: WrapperMoreOptionBomItemProps) => {
  const [visible, setVisible] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const { deleteItemCustomAssembly, isDeletingCustomAssembly } = useCustomAssemblyTypes();
  const apiRef = useGridApiContext();
  const { palette } = useTheme();

  const { lineItemTypeId, lineItemId, lineItemName, canChildLineItemBeAdded } = billOfMaterialItem;

  const handleRenameClick = () => {
    const rows = Array.from(apiRef?.current?.getRowModels().values());
    const selectedRowId = rows.find((data) => data.lineItemId === billOfMaterialItem.lineItemId);
    if (selectedRowId) {
      apiRef.current.startCellEditMode({
        id: billOfMaterialItem.lineItemId,
        field: BOMCatalogTableFields.LINE_ITEM_NAME,
      });
    }
  };

  const OPTIONS_MENU: MoreMenuProps['options'] = useMemo(
    () => [
      {
        label: 'Add subitem',
        clickEvent: onAddSubItem,
        disabled: Boolean(lineItemTypeId && isItemAPart(lineItemTypeId)) || !canChildLineItemBeAdded,
        isInactive: false,
      },
      {
        label: 'Rename',
        clickEvent: () => handleRenameClick(),
        disabled: !(lineItemTypeId && isItemAWriteIn(lineItemTypeId)),
        isInactive: false,
      },
      {
        label: 'Delete',
        clickEvent: () => lineItemId && setOpenConfirmModal(true),
        disabled: false,
        isInactive: false,
        color: palette?.error?.main,
        ...(deleteDisable && {
          disabled: true,
          color: 'rgba(0,0,0,1)',
          tooltipProps: {
            title: 'Delete is disabled because the item has been previously sent to a work request.',
            placement: 'left',
            arrow: true,
            componentsProps: { tooltip: { sx: { lineHeight: '16px' } } },
          },
        }),
      },
    ],
    // eslint-disable-next-line
    [canChildLineItemBeAdded, deleteDisable, lineItemId, lineItemTypeId, onAddSubItem, palette?.error?.main]
  );

  if (!lineItemTypeId || !lineItemId) return <></>;

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
      <MoreMenu
        options={OPTIONS_MENU}
        sxMenu={{ left: '30px' }}
        handleOpen={() => setVisible(true)}
        handleExtClose={() => setVisible(false)}
        sxIconButton={visible ? {} : noVisibleStyles}
        {...{
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          transformOrigin: { vertical: 'top', horizontal: 'right' },
        }}
      />
      <DeleteModal
        open={openConfirmModal}
        onClose={() => setOpenConfirmModal(false)}
        onSubmit={() => deleteItemCustomAssembly(billOfMaterialItem, () => setOpenConfirmModal(false))}
        loading={isDeletingCustomAssembly}
        itemName={lineItemName ?? ''}
      >
        {customContentModal?.[lineItemTypeId as keyof typeof customContentModal]?.(lineItemName ?? '')}
      </DeleteModal>
    </Stack>
  );
};
