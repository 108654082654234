import React, { useEffect, useMemo, useState } from 'react';

import { FormControl, Select, InputLabel, MenuItem, useTheme } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';

import { useCatalogModal, useCatalogModalQuery } from './CatalogModalContext';
import stylesBuilder from './StylesBuilder';
import { selectMenuProps, ItemTypography } from './StylesObjects';

const ManufacturerSelect = () => {
  const [isFocused, setisFocused] = useState(false);

  const theme = useTheme();
  const { control, isLoading, setValue: setFormValue, watch } = useFormContext();

  const { setManufacturer } = useCatalogModal();
  const { fetchManufacturers, manufacturersData } = useCatalogModalQuery();

  useEffect(() => {
    fetchManufacturers();
  }, [fetchManufacturers]);

  const manufacturersList = useMemo(
    () => manufacturersData?.manufactured ?? [],
    [manufacturersData],
  );

  const newManufacturer = watch('manufacturer');
  useEffect(() => {
    setManufacturer(newManufacturer);
  }, [newManufacturer, setManufacturer]);

  useEffect(() => {
    const genericManufacturer = manufacturersList.find(
      ({ manufacturerName }) => manufacturerName === 'Generic',
    );
    if (genericManufacturer) {
      setFormValue('manufacturer', genericManufacturer.manufacturerId, { shouldDirty: true });
    }
  }, [manufacturersList, setFormValue]);

  return (
    <FormControl size="small">
      <InputLabel id="manufacturer-select-label">Manufacturer *</InputLabel>
      <Controller
        name="manufacturer"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Select
            labelId="manufacturer-select-label"
            required
            disabled={isLoading}
            value={value || ''}
            onChange={onChange}
            onBlur={() => setisFocused(false)}
            onFocus={() => setisFocused(true)}
            color="secondary"
            label="Manufacturer"
            MenuProps={selectMenuProps()}
            sx={stylesBuilder(false, isFocused, theme)}
          >
            {manufacturersList.map(({ manufacturerId, manufacturerName }) => (
              <MenuItem key={manufacturerId} value={manufacturerId}>
                <ItemTypography>{manufacturerName}</ItemTypography>
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormControl>
  );
};

export default ManufacturerSelect;
