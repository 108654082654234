import React from 'react';

import { Stack, SxProps } from '@mui/material';

type ComponentPaneContentProps = {
  children: React.ReactNode;
  sx?: SxProps;
};

const ComponentPaneContent = ({ children, sx, ...restProps }: ComponentPaneContentProps) => (
  <Stack sx={{ ...sx, flex: 1 }} {...restProps}>
    {children}
  </Stack>
);

export default ComponentPaneContent;
