import { useWrappedPaginatedGet } from 'hooks-api/useWrappedApiCall';

import { CustomersProvider } from './CustomersContext';
import CustomersHeader from './CustomersHeader';
import CustomersTable from './CustomersTable/CustomersTable';

const headerOptions = [{ title: 'Company Name', sortValue: 'companyName' }, { title: 'Netsuite ID' }];

const Customers = () => {
  const {
    searchHandler,
    data: customers,
    loading,
    fetchNextPage,
    sortHandler,
  } = useWrappedPaginatedGet('admin/company');

  return (
    <CustomersProvider>
      <CustomersHeader handleSearch={searchHandler} />
      <CustomersTable
        headerOptions={headerOptions}
        loading={loading}
        customers={customers}
        handleSortOrder={sortHandler}
        fetchNextPage={fetchNextPage}
      />
    </CustomersProvider>
  );
};

export default Customers;
