import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro';

import CustomDetailPanelToggle from 'modules/Field/WorkRequests/WorkRequest/components/CustomDetailPanelToggle';

export const getToggleColumn = () => ({
  ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  type: '',
  minWidth: 32,
  width: 32,
  renderCell: ({ row, id, value }) => <ToggleCell row={row} id={id} value={value} />,
});

const ToggleCell = ({ row, id, value }) => {
  if (!row?.hasChildNodes) return <></>;

  return <CustomDetailPanelToggle id={id} value={value} />;
};
