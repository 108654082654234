import React, { useContext } from 'react';

import DeleteModal from 'components/DeleteModal';
import { ItemsDataGridProProvider } from 'components/ItemsDatagridPro';
import ItemsDataGridPro from 'components/ItemsDatagridPro/ItemsDatagridPro';
import useWorkRequestHelpers from 'modules/Field/WorkRequests/hooks/useWorkRequestHelpers';
import { WorkRequestItemsContext } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestItemsContext';
import { useWorkOrder } from 'modules/Shop/WorkOrders/hooks/useWorkOrder';

import { buildTasksTableStyles } from './helpers/helpers';
import { useTasksTableApiContext, useTasksTableDataContext } from './TasksTableContext';
import { useTasksTableColumns } from './useTasksTableColumns';

const TasksTable = ({
  tasks,
  canEdit = false,
  canDelete = false,
  onDeleteTask,
  rowComponent,
  getDrawingDocs,
  detailPanelHeight,
  isResponsiveWR,
  sx = {},
  taskIdsRelateDuplicate,
  tasksPredecessorBlacklist,
  ...restProps
}) => {
  const workRequestContext = useContext(WorkRequestItemsContext);

  const { workOrder } = useWorkOrder();
  const { WOIsReleased } = useWorkRequestHelpers();
  const { deleteModalOpened, selectedItem, deleteModalLoading } = useTasksTableDataContext();
  const { stopEditingAllTasks, setDeleteModalOpened, setDeleteModalLoading } = useTasksTableApiContext();
  const { taskId, taskName } = selectedItem ?? {};

  const { apiRef, onRowMouseEnter, savePredecessors, onRowMouseLeave } = useTasksTableApiContext();

  const columns = useTasksTableColumns({
    tasks,
    canEdit,
    canDelete,
    onDeleteTask,
    isResponsiveWR,
    isReleased: WOIsReleased(workRequestContext?.workRequest || workOrder),
    tasksPredecessorBlacklist,
    taskIdsRelateDuplicate,
  });

  const onSubmit = async () => {
    if (!taskId) return;
    stopEditingAllTasks();
    setDeleteModalLoading(true);
    await onDeleteTask(taskId);
    setDeleteModalLoading(false);
    setDeleteModalOpened(false);
  };

  return (
    <>
      <ItemsDataGridProProvider>
        <ItemsDataGridPro
          experimentalFeatures={{ newEditingApi: true }}
          apiRef={apiRef}
          rows={tasks}
          getRowId={(row) => row.taskId}
          editMode={canEdit ? 'row' : null}
          rowHeight={32}
          headerHeight={32}
          columns={columns}
          autoHeight
          sx={{ ...buildTasksTableStyles(canEdit), ...sx }}
          getRowClassName={() => 'tasks-row'}
          initialState={{ pinnedColumns: { right: ['actions'] } }}
          onRowEditStop={({ row }) => savePredecessors(row)}
          components={{
            Row: rowComponent,
          }}
          getDetailPanelContent={getDrawingDocs}
          getDetailPanelHeight={detailPanelHeight}
          componentsProps={{
            row: {
              onMouseEnter: onRowMouseEnter,
              onMouseLeave: onRowMouseLeave,
              onClick: onRowMouseEnter,
            },
          }}
          disableColumnMenu
          disableSelectionOnClick
          disableVirtualization
          {...restProps}
        />
      </ItemsDataGridProProvider>
      <DeleteModal
        open={deleteModalOpened}
        onClose={() => setDeleteModalOpened(false)}
        onSubmit={onSubmit}
        itemName={taskName}
        confirmText="Are you sure you want to delete"
        loading={deleteModalLoading}
        deleteButtonText="Confirm"
      />
    </>
  );
};
export default React.memo(TasksTable);
