import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';

const WorkRequestsContext = React.createContext();

const WORK_REQUEST_TABS = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
};

const WORK_REQUEST_TABLES = {
  WORK_REQUEST_LIST: 'WorkRequestList',
  WORK_REQUEST_ITEMS_LIST: 'WorkRequestItemsList',
};

const WorkRequestsProvider = ({ children }) => {
  const selectedProjectIdRef = useRef(null);

  const [workRequestSelected, setWorkRequest] = useState();
  const [customDesignRows, setCustomDesignRows] = useState(false);
  const [currentTable, setCurrentTable] = useState(WORK_REQUEST_TABLES.WORK_REQUEST_LIST);
  const [filters, setFilters] = useState([]);
  const [activeTab, setActiveTab] = useState(WORK_REQUEST_TABS.OPEN);

  const { selectedItem } = useFacilitiesProjects();
  const selectedProjectId = useMemo(() => {
    const isProjectSelected = selectedItem?.type === 'PROJECT';
    return isProjectSelected ? selectedItem.id : null;
  }, [selectedItem]);

  useEffect(() => {
    if (!selectedProjectId || selectedProjectIdRef.current === selectedProjectId) return;

    if (currentTable === WORK_REQUEST_TABLES.WORK_REQUEST_ITEMS_LIST) {
      setCurrentTable(WORK_REQUEST_TABLES.WORK_REQUEST_LIST);
    }

    selectedProjectIdRef.current = selectedProjectId;
  }, [currentTable, selectedProjectId]);

  const selectedStateObj = {
    workRequestSelected,
    setWorkRequest,
    filters,
    setFilters,
    customDesignRows,
    setCustomDesignRows,
    currentTable,
    setCurrentTable,
    activeTab,
    setActiveTab,
  };

  return (
    <WorkRequestsContext.Provider value={selectedStateObj}>{children}</WorkRequestsContext.Provider>
  );
};

const useWorkRequestsContext = () => {
  const context = React.useContext(WorkRequestsContext);
  if (context === undefined) {
    throw new Error('useWorkRequestsContext must be used within a WorkRequestsContext');
  }
  return context;
};

export {
  WorkRequestsContext,
  WorkRequestsProvider,
  useWorkRequestsContext,
  WORK_REQUEST_TABS,
  WORK_REQUEST_TABLES,
};
