import { useCallback, useEffect, useState } from 'react';

import { gql, useLazyQuery } from '@apollo/client';

import { billOfMaterialLineItemByLBS as GET_ITEM_LBS } from 'graphql/queries';
import { SelectedIntegrationItem } from 'modules/Materials/components/ItemsIntegrationModal/@types/itemsIntegrationModalContext';

import { QuantityFieldType } from '../components/QuantityField/hooks/@types/fieldHookTypes';
import { useItemsIntegrationTableContext } from '../context/ItemsIntegrationTableContext';
import { buildQuantityObjectItem, handleLBSChildren } from '../helpers/dataGridHelpers';

interface IUseGetItemData {
  selectedItem: SelectedIntegrationItem;
}

const useGetItemData = ({ selectedItem }: IUseGetItemData) => {
  const [itemData, setItemData] = useState<QuantityFieldType | null>(null);
  const { baseERN, setQuantityFields, setLoadingBOMRows, setMaxNestLevel } = useItemsIntegrationTableContext();
  const [getItemLBS, { loading: loadingItemLBS }] = useLazyQuery(gql(GET_ITEM_LBS), {
    fetchPolicy: 'cache-and-network',
  });
  const getItemData = useCallback(async () => {
    setMaxNestLevel((current) => ({ ...current, [selectedItem.lineItemId]: 0 }));
    const ern = `${baseERN}:${selectedItem.lineItemId}`;

    const response = await getItemLBS({
      variables: { query: { ern, filterType: 'Both' } },
    });
    const children = handleLBSChildren(response);
    const { data } = response;
    const resultObject = buildQuantityObjectItem(
      selectedItem,
      children as QuantityFieldType[],
      data?.billOfMaterialLineItemByLBS,
    );
    setItemData(resultObject);
    if ((resultObject.qtyRemaining as number) <= 0) return null;
    setQuantityFields((currentFields) => {
      if (currentFields.find(({ id }) => id === resultObject.id)) return currentFields;
      return [...currentFields, resultObject];
    });
    return resultObject;
  }, [setMaxNestLevel, baseERN, selectedItem, getItemLBS, setQuantityFields]);

  useEffect(() => {
    if (!itemData || (itemData?.totalQuantityRemaining as number) > 0) return;
    setLoadingBOMRows((current) => {
      const auxCurrent = { ...current };
      delete auxCurrent[itemData?.id as string];
      return auxCurrent;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemData]);

  useEffect(() => {
    if ((selectedItem?.remainingQuantity as number) <= 0 || !selectedItem?.lineItemId) return;
    if (!loadingItemLBS) {
      setLoadingBOMRows((current) => ({
        ...current,
        [selectedItem.lineItemId as string]: false,
      }));
      return;
    }
    setLoadingBOMRows((current) => ({ ...current, [selectedItem.lineItemId as string]: loadingItemLBS }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, loadingItemLBS]);
  return { getItemData };
};

export default useGetItemData;
