import React from 'react';

import { useQuery, gql } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';

import { useUser } from 'app/UserContext';
import ControlledChipsAutoComplete from 'components/FormComponents/ControlledChipsAutoComplete';
import { users as USERS } from 'graphql/queries';

const DesignInviteForm = ({ control }) => {
  const { user } = useUser();
  const { data, loading, error } = useQuery(gql(USERS), {
    variables: {
      query: {
        companyId: user.companyId,
      },
    },
  });

  if (loading || !data) return <CircularProgress />;
  if (error) return <p>{error.message}</p>;

  const users = data?.users || [];

  const formattedOptions = users.map(({ userEmail, userId }) => ({ label: userEmail, id: userId }));

  return (
    <form>
      <ControlledChipsAutoComplete
        options={formattedOptions}
        control={control}
        name="newDesignUsers"
        rules={{ required: true }}
        label="Email or Name"
        defaultValue={[]}
        placeholder="Search or add email"
      />
    </form>
  );
};

export default DesignInviteForm;
