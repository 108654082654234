/* eslint-disable max-lines-per-function */
import { useCallback, useEffect, useMemo, useState } from 'react';

const biggerHeight = (prevValue = '0px', newValue = '0px') => {
  const prevNumber = parseInt(prevValue.replace('px', ''), 10);
  const newNumber = parseInt(newValue.replace('px', ''), 10);
  const finalNumber = prevNumber > newNumber ? prevNumber : newNumber;
  return `${finalNumber}px`;
};

const useTakeOffPadTableRowErrors = (formState, inputState) => {
  const defaultHeight = '32px';
  const requiredHeight = '64px';

  const requiredLabels = useMemo(() => ['Invalid', '*Required'], []);

  const [height, setHeight] = useState(defaultHeight);

  const InputNameError = (trowEl) => {
    const current = trowEl.style.height;
    const result = biggerHeight(current, requiredHeight);
    trowEl.style.height = result;
    trowEl.style.verticalAlign = 'text-top';
    setHeight(result);
  };

  const handleTradeSizeError = (trowEl) => {
    const current = trowEl.style.height;
    const result = biggerHeight(current, requiredHeight);
    trowEl.style.height = result;
    trowEl.style.verticalAlign = 'text-top';
    setHeight(result);
  };

  const handleUnitQuantityError = useCallback(
    (trowEl, hasUnitQuantityError) => {
      let height = requiredHeight;
      if (requiredLabels.includes(hasUnitQuantityError?.message)) height = requiredHeight;
      const current = trowEl.style.height;
      const result = biggerHeight(current, height);
      trowEl.style.height = result;
      trowEl.style.verticalAlign = 'text-top';
      setHeight(result);
    },
    [requiredLabels],
  );

  const handleUOMError = (trowEl) => {
    const current = trowEl.style.height;
    const result = biggerHeight(current, requiredHeight);
    trowEl.style.height = result;
    trowEl.style.verticalAlign = 'text-top';
    setHeight(result);
  };

  const handleManufacturerError = useCallback(
    (trowEl, hasManufacturerError) => {
      let height = requiredHeight;
      if (requiredLabels.includes(hasManufacturerError?.message)) height = requiredHeight;
      const current = trowEl.style.height;
      const result = biggerHeight(current, height);
      trowEl.style.height = result;
      trowEl.style.verticalAlign = 'text-top';
      setHeight(result);
    },
    [requiredLabels],
  );
  const handleCatalogNumber = (trowEl) => {
    const current = trowEl.style.height;
    const result = biggerHeight(current, requiredHeight);
    trowEl.style.height = result;
    trowEl.style.verticalAlign = 'text-top';
    setHeight(result);
  };

  const handleQuantityError = useCallback(
    (trowEl, hasQuantityError) => {
      let height = requiredHeight;
      if (requiredLabels.includes(hasQuantityError?.message)) height = requiredHeight;
      const current = trowEl.style.height;
      const result = biggerHeight(current, height);
      trowEl.style.height = result;
      trowEl.style.verticalAlign = 'text-top';
      setHeight(result);
    },
    [requiredLabels],
  );

  useEffect(() => {
    const trowEl = document.querySelector('[data-id="0"]');
    if (!trowEl) return;
    const hasFormError = Boolean(Object.keys(formState.errors).length);
    if (!hasFormError && !inputState) {
      trowEl.style.height = defaultHeight;
      trowEl.style.verticalAlign = 'middle';
      trowEl.style.maxHeight = '35px';
      setHeight(defaultHeight);
      return;
    }

    trowEl.style.maxHeight = 'none';

    const hasManufacturerError = formState.errors?.manufacturer;
    if (hasManufacturerError) {
      handleManufacturerError(trowEl, hasManufacturerError);
      return;
    }

    const hasUOMError = formState.errors?.uom;
    if (hasUOMError) {
      handleUOMError(trowEl);
      return;
    }

    const hasUnitQuantityError = formState.errors?.unitQuantity;
    if (hasUnitQuantityError) {
      handleUnitQuantityError(trowEl, hasUnitQuantityError);
      return;
    }

    const hasQuantityError = formState.errors?.quantity;
    if (hasQuantityError) {
      handleQuantityError(trowEl, hasQuantityError);
      return;
    }

    const hasCatalogNumberError = formState.errors?.catalogNumber;
    if (hasCatalogNumberError) {
      handleCatalogNumber(trowEl);
      return;
    }
    const hasTradeSizeError = formState.errors?.tradeSize;
    if (hasTradeSizeError) {
      handleTradeSizeError(trowEl);
      return;
    }

    if (inputState) {
      InputNameError(trowEl);
    }
  }, [
    formState,
    inputState,
    handleUnitQuantityError,
    handleManufacturerError,
    handleQuantityError,
  ]);

  return parseInt(height.replace('px', ''), 10);
};

export default useTakeOffPadTableRowErrors;
