import { gql } from '@apollo/client';

import {
  workCells as WORK_CELLS,
  workCellTaskType as WORK_CELL_TASKS,
  workCellMachine as WORK_CELL_MACHINES,
} from 'graphql/queries';
import useCache from 'hooks/useCache';

import { useDataContext } from '../contexts/DataContext';
import { buildVariables, getFacilityId } from '../helpers/helpers';

// eslint-disable-next-line max-lines-per-function
export default function useCachedData() {
  const { selectedItem, shopConfiguration } = useDataContext();
  const { deleteCache, readCache, writeCache } = useCache();

  const cleanWorkCellTasksCache = (workCell) =>
    deleteCache('workCellTaskType', { query: { workCellId: workCell.workCellId } });

  const cleanWorkCellMachinesCache = (workCell) =>
    deleteCache('workCellMachine', {
      query: {
        workCellId: workCell.workCellId,
        orderBy: 'machineIdentifier:asc',
        facilityId: getFacilityId(selectedItem),
      },
    });

  const cleanMachineTasksCache = (workCell, machine) =>
    deleteCache('machineTaskType', {
      query: { machineId: machine.machineId, workCellId: workCell.workCellId },
    });

  const enableWorkCellTasksContainerCache = (workCellId) => {
    const cachedWorkCells = readCache(gql(WORK_CELLS), buildVariables(selectedItem, shopConfiguration).variables);

    const updatedWorkCells = cachedWorkCells?.workCells.map((workCell) => {
      if (workCell.workCellId === workCellId) return { ...workCell, hasTaskTypes: true };
      return workCell;
    });

    writeCache(gql(WORK_CELLS), buildVariables(selectedItem, shopConfiguration).variables, {
      workCells: updatedWorkCells,
    });
  };

  const enableWorkCellMachinesContainerCache = (workCellId) => {
    const cachedWorkCells = readCache(gql(WORK_CELLS), buildVariables(selectedItem, shopConfiguration).variables);

    const updatedWorkCells = cachedWorkCells?.workCells.map((workCell) => {
      if (workCell.workCellId === workCellId) return { ...workCell, hasMachines: true };
      return workCell;
    });

    writeCache(gql(WORK_CELLS), buildVariables(selectedItem, shopConfiguration).variables, {
      workCells: updatedWorkCells,
    });
  };

  const disableWorkCellTasksContainerCache = (workCellId, unmappedWorkCellTaskId = null) => {
    const workCellTasksCached = readCache(gql(WORK_CELL_TASKS), {
      query: { workCellId },
    });

    const updatedTasks = workCellTasksCached.workCellTaskType.filter(
      (task) => task.workCellTaskTypeId !== unmappedWorkCellTaskId,
    );

    if (!updatedTasks.length) {
      const cachedWorkCells = readCache(gql(WORK_CELLS), buildVariables(selectedItem, shopConfiguration).variables);

      const updatedWorkCells = cachedWorkCells?.workCells.map((workCell) => {
        if (workCell.workCellId === workCellId) return { ...workCell, hasTaskTypes: false };
        return workCell;
      });

      writeCache(gql(WORK_CELLS), buildVariables(selectedItem, shopConfiguration).variables, {
        workCells: updatedWorkCells,
      });
    }
  };

  const disableWorkCellMachinesContainerCache = (workCellId, unmappedWorkCellMachineId = null) => {
    const workCellMachinesCached = readCache(gql(WORK_CELL_MACHINES), {
      query: {
        workCellId,
        orderBy: 'machineIdentifier:asc',
        facilityId: getFacilityId(selectedItem),
      },
    });

    if (workCellMachinesCached?.workCellMachine?.length) {
      const updatedMachines = workCellMachinesCached.workCellMachine.filter(
        (machine) => machine.workCellMachineId !== unmappedWorkCellMachineId,
      );

      if (!updatedMachines.length) {
        const cachedWorkCells = readCache(gql(WORK_CELLS), buildVariables(selectedItem, shopConfiguration).variables);

        const updatedWorkCells = cachedWorkCells?.workCells.map((workCell) => {
          if (workCell.workCellId === workCellId) return { ...workCell, hasMachines: false };

          return workCell;
        });

        writeCache(gql(WORK_CELLS), buildVariables(selectedItem, shopConfiguration).variables, {
          workCells: updatedWorkCells,
        });
      }
    }
  };

  return {
    cleanWorkCellTasksCache,
    cleanWorkCellMachinesCache,
    cleanMachineTasksCache,
    enableWorkCellTasksContainerCache,
    enableWorkCellMachinesContainerCache,
    disableWorkCellTasksContainerCache,
    disableWorkCellMachinesContainerCache,
  };
}
