import { useEffect } from 'react';

import { gql, useLazyQuery, useQuery } from '@apollo/client';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import {
  workRequestProjects as WORK_REQUEST_PROJECTS,
  departmentTypes as DEPARTMENT_TYPES,
} from 'graphql/queries';
import useSetupModule from 'hooks/useSetupModule';

const useFacilityProjectsQuery = () => {
  const { currentModule } = useSetupModule();
  const { data: departments } = useQuery(gql(DEPARTMENT_TYPES));
  const { selectedItem } = useFacilitiesProjects();

  const [getProjects, { data, loading: loadingProjects }] = useLazyQuery(
    gql(WORK_REQUEST_PROJECTS),
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const getDepartmentId = () => {
    const deparmentTypes = departments?.departmentTypes;

    const currentDepartmentType = deparmentTypes.filter(
      ({ departmentTypeName }) => departmentTypeName.toLowerCase() === currentModule().toLowerCase(),
    )[0];

    return currentDepartmentType?.departmentTypeId;
  };

  useEffect(() => {
    if (!selectedItem?.id || !departments?.departmentTypes) return;
    const departmentTypeIds = getDepartmentId();
    getProjects({
      variables: {
        params: { facilityId: selectedItem?.id },
        query: { departmentTypeIds },
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProjects, selectedItem?.id, departments?.departmentTypes]);

  return { loadingProjects, projects: data?.workRequestProjects || [] };
};

export default useFacilityProjectsQuery;
