/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import { InputForesiteField } from '@evolvemep/foresite-components-ui-react';
import { Grid } from '@mui/material';

const VariantCodeInput = ({
  defaultValues,
  variants,
  selectedItem,
  onSubmit,
  useForm,
  rules,
  loading,
  parentError,
  onError = () => {},
  onKeyDown = () => {},
}) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm;
  useEffect(() => {
    setValue('code', selectedItem?.code);
  }, [selectedItem]);
  return (
    <Grid item xs={4}>
      <VariantCodeInputField
        error={errors?.code?.type === 'validation'}
        helperText={errors?.code?.type === 'validation' ? 'code already exists' : ''}
        onError={onError}
        parentError={parentError}
        loading={loading}
        control={control}
        defaultValue={defaultValues?.code}
        rules={rules}
        variants={variants}
        onKeyDown={(e) => {
          if (e.code === 'Escape') {
            onKeyDown();
            return;
          }

          if (e.keyCode !== 13 || errors?.code?.type === 'validation') {
            return;
          }

          handleSubmit(onSubmit)();
        }}
      />
    </Grid>
  );
};

export default VariantCodeInput;

const getMargin = (parentNameHasError) => {
  if (parentNameHasError) return '24px';
  return '0px';
};

const VariantCodeInputField = ({
  loading,
  control,
  defaultValue,
  rules,
  variants = [],
  onKeyDown = () => {},
  onError = () => {},
  parentError,
  error,
  helperText,
}) => (
  <InputForesiteField
    maxLength={6}
    onError={onError}
    disabled={loading}
    onKeyDown={onKeyDown}
    control={control}
    defaultValue={defaultValue}
    error={error}
    helperText={helperText}
    name="code"
    size="small"
    rules={{
      ...rules,
      validate: {
        validation: (value) =>
          !variants.some(
            (item) => item?.codeValue?.trim().toLowerCase() === value?.trim().toLowerCase() && value?.trim(),
          ) || '',
      },
    }}
    sx={{
      margin: '0px',
      marginBottom: getMargin(parentError),
      height: '100%',
      '& .MuiInputBase-input': {
        padding: '8px 12px',
        fontSize: '14px',
        backgroundColor: 'white',
      },
      '& .MuiFormHelperText-contained': {
        marginTop: '0px',
      },
      '& .MuiFormHelperText-root': {
        margin: 0,
      },
      '& .MuiFormHelperText-filled': {
        textAlign: 'right',
      },
    }}
  />
);
