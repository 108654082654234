import { materialsColors, errorColors } from 'styles/theme/colors';

export const FOOTER_MESSAGE = {
  EDITED_SUCCESS: 'Qty successfully updated',
  QTY_LIMIT_EXCEEDED: 'Qty limit exceeded',
  ERROR_SAVING: 'Could not save the quantity',
  NO_NEGATIVE_NUMBERS: 'Cannot be a negative number',
};

export const QTY_INPUT_STYLES = {
  '& #quantity-text-field-helper-text': {
    display: 'none',
  },
  input: {
    py: 0,
    textAlign: 'right',
  },
  '& .MuiFormHelperText-root': {
    display: 'none !important',
  },
  '.MuiInputBase-root:not(.Mui-focused):hover fieldset': {
    borderColor: 'black',
  },
};

export const QTY_INPUT_WRAPPER_STYLES = {
  width: '94px',
  minWidth: '78px',
  mr: 2,
  alignItems: 'center',
  display: 'flex',
};

export const GREEN_ICON_COLOR = {
  path: {
    fill: materialsColors.main,
    fillOpacity: 1,
  },
};

export const ERROR_ON_FOCUS_STYLES = {
  '& .Mui-focused': {
    input: {
      WebkitTextFillColor: `${errorColors.dark} !important`,
    },
    fieldset: {
      borderColor: `${errorColors.dark} !important`,
    },
  },
};

export const getArrowStyles = (arrowPosition, arrowTop, arrowRight) => ({
  '& .MuiBackdrop-root': {
    background: 'none',
  },
  '& div[role="dialog"]': {
    boxShadow: 13,
  },
  '& div[role="dialog"]::before': {
    content: '""',
    zIndex: 0,
    position: 'absolute',
    width: '24px',
    height: '24px',
    WebkitTransform: 'rotate(45deg)',
    MozTransform: 'rotate(45deg)',
    MsTransform: 'rotate(45deg)',
    OTransform: 'rotate(45deg)',
    transform: 'rotate(45deg)',
    backgroundColor: 'white',
    left: 'auto',
    ...(arrowPosition === 'top'
      ? {
          right: `${arrowRight || 64}px`,
          top: '-12px',
          boxShadow: '-3px -3px 4px rgba(0,0,0,0.07)',
        }
      : {}),
    ...(arrowPosition === 'bottom'
      ? {
          right: `${arrowRight || 64}px`,
          bottom: '-12px',
          boxShadow: '3px 3px 4px rgba(0,0,0,0.12)',
        }
      : {}),
    ...(arrowPosition === 'right'
      ? {
          position: 'fixed',
          left: `${arrowRight}px`,
          top: `${arrowTop}px`,
          boxShadow: '3px -3px 4px rgba(0,0,0,0.12)',
        }
      : {}),
    ...(arrowPosition === 'left'
      ? {
          position: 'fixed',
          left: `${arrowRight}px`,
          top: `${arrowTop}px`,
          boxShadow: '-3px 3px 4px rgba(0,0,0,0.12)',
        }
      : {}),
  },
});

export const getDialogStyles = (arrowPosition, arrowRight, coordinates) => ({
  style: {
    minWidth: '672px',
    minHeight: '540px',
    maxHeight: '540px',
    top: coordinates?.top,
    right: coordinates?.right,
    left: coordinates?.left,
    position: coordinates?.position,
    overflow: 'inherit',
    ...(arrowPosition === 'right'
      ? {
          left: `${arrowRight - 692}px`,
        }
      : {}),
    ...(arrowPosition === 'left'
      ? {
          left: `${arrowRight - 20}px`,
        }
      : {}),
  },
});
