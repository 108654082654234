/* eslint-disable import/no-cycle */
import { useEffect } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';

import { ADD_REMAINING_QUANTITIES_FIELD } from '../../../constants';
import { useItemsIntegrationTableContext } from '../../../context/ItemsIntegrationTableContext';
import { removeAutoFilledField } from '../../QtyField/helpers/qtyFieldHelpers';
import { useFieldContext } from '../context/FieldContext';
import { replaceNegativeAmount } from '../helpers/quantityFieldHelpers';

const useValuesBeforeAutofill = () => {
  const { control, setValue, getValues } = useFormContext();
  const { isBomLineItem, fieldName, qtyRemaining, fieldWatched, qtyAggregate, unassignedRowFieldName, qtyFieldRef } =
    useFieldContext();
  const { autoFilledFields, setAutoFilledFields, valuesBeforeAutofill, setValuesBeforeAutofill } =
    useItemsIntegrationTableContext();
  const autoFill = useWatch({ control, name: ADD_REMAINING_QUANTITIES_FIELD });
  useEffect(() => {
    if (fieldName !== unassignedRowFieldName) return;
    if (!autoFilledFields.includes(fieldName)) return;
    if (parseInt(qtyFieldRef.current.value || '0', 10) === qtyRemaining) return;
    setValue(fieldName, replaceNegativeAmount(qtyRemaining));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoFilledFields, fieldName, unassignedRowFieldName, qtyFieldRef]);

  useEffect(() => {
    const rowIsNotNullOrAutofilled = !autoFilledFields.includes(fieldName) || getValues(fieldName) !== '';
    if (rowIsNotNullOrAutofilled) return;
    removeAutoFilledField(fieldName, setAutoFilledFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoFilledFields, fieldName]);

  useEffect(() => {
    const noFieldValuesBeforeAutofill = !valuesBeforeAutofill || valuesBeforeAutofill?.[fieldName] === undefined;
    if (noFieldValuesBeforeAutofill) return;
    if (autoFill) {
      setValue(fieldName, replaceNegativeAmount(isBomLineItem ? qtyAggregate : qtyRemaining));
      return;
    }
    setValue(fieldName, valuesBeforeAutofill[fieldName]);
    const auxCurrent = { ...valuesBeforeAutofill };
    delete auxCurrent[fieldName];
    setValuesBeforeAutofill(auxCurrent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoFill, valuesBeforeAutofill, fieldName, isBomLineItem, qtyAggregate, qtyRemaining, fieldWatched]);
};

export default useValuesBeforeAutofill;
