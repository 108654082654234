import React from 'react';

import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';

import { Custom } from 'components/Icons';

const DrawingStatus = ({ Icon, messageUpToDate, link }) => {
  const phoneSize = useMediaQuery('(min-width:480px)');

  return (
    <Stack>
      <Box display="flex" alignItems="center" flexGrow="1" sx={{ background: 'white' }}>
        <Stack style={{ paddingLeft: '16px', paddingTop: '12px' }}>
          <Link href="https://www.evolvemep.com/" target="_blank">
            <img
              data-testid="evolve-logo-mobile"
              src={Custom.EvolveLogoGeneric}
              alt="mobile-logo"
            />
          </Link>{' '}
        </Stack>
      </Box>
      <Box
        height="100vh"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{ background: 'white', width: 1 }}
      >
        {Icon}
        <Typography variant="h6" sx={{ mt: '12px' }}>
          {messageUpToDate}
        </Typography>
        {phoneSize && (
          <Stack direction="row" sx={{ mt: '8px' }}>
            <Link component={RouterLink} to={link}>
              <Typography sx={{ color: 'other.links' }}>Login </Typography>
            </Link>
            <Typography variant="body2" sx={{ fontSize: '16px', lineHeight: '32px', ml: '6px' }}>
              to view task details
            </Typography>
          </Stack>
        )}
      </Box>
    </Stack>
  );
};

export default DrawingStatus;
