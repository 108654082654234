/* eslint-disable import/no-cycle */
import { useEffect } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';

import { ADD_REMAINING_QUANTITIES_FIELD } from '../../../constants';
import { useItemsIntegrationTableContext } from '../../../context/ItemsIntegrationTableContext';
import { addAutoFilledField, removeAutoFilledField } from '../../QtyField/helpers/qtyFieldHelpers';
import { useFieldContext } from '../context/FieldContext';

const useAutofillLineItemChildren = () => {
  const { control, setValue, getValues } = useFormContext();
  const { fieldName, isBomLineItem, childrenValuesWatched, childrenFields, unassignedRowFieldName } = useFieldContext();
  const { autoFilledFields, setAutoFilledFields } = useItemsIntegrationTableContext();
  const autoFill = useWatch({ control, name: ADD_REMAINING_QUANTITIES_FIELD });
  useEffect(() => {
    // * autofill line item with children values (managing autofilled fields)
    const locationItemHasValue = autoFill || !isBomLineItem || getValues(fieldName);
    if (locationItemHasValue) return;
    if (childrenValuesWatched) {
      addAutoFilledField(fieldName, { autoFilledFields, setAutoFilledFields });
    } else {
      removeAutoFilledField(unassignedRowFieldName, setAutoFilledFields);
      removeAutoFilledField(fieldName, setAutoFilledFields);
    }
    setValue(fieldName, childrenValuesWatched || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBomLineItem, fieldName, childrenValuesWatched, autoFill]);

  useEffect(() => {
    // * autopop line item if it has children values
    const locationItemIsNotAutofilled = autoFill || !isBomLineItem || !autoFilledFields.includes(fieldName);
    if (locationItemIsNotAutofilled) return;
    if (childrenValuesWatched) {
      setValue(fieldName, childrenValuesWatched);
      return;
    }
    setValue(fieldName, '');
    removeAutoFilledField(unassignedRowFieldName, setAutoFilledFields);
    removeAutoFilledField(fieldName, setAutoFilledFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childrenValuesWatched, childrenFields, isBomLineItem, fieldName, autoFill]);
};

export default useAutofillLineItemChildren;
