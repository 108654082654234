import React from 'react';

import { AppBar, Toolbar } from '@mui/material';
import Box from '@mui/material/Box';

import HelpDeskDropdown from '../../HelpDeskDropdown/HelpDeskDropdown';
import ProfileDropdown from '../../ProfileDropdown/ProfileDropdown';
import { ProfilePictureProvider } from '../../ProfileDropdown/ProfilePictureContext';
import EvolveLogoLink from '../EvolveLogoLink';
import MailButton from '../MailButton';

const NoLicensesPageHeader = () => {
  const LeftAligned = () => (
    <Box display="flex" alignItems="center" flexGrow="1">
      <div style={{ marginLeft: 8, marginTop: 10 }}>
        <EvolveLogoLink />
      </div>
    </Box>
  );

  const RightAligned = () => (
    <ProfilePictureProvider>
      <Box display="flex" alignItems="center">
        <HelpDeskDropdown />
        <MailButton />
        <ProfileDropdown />
      </Box>
    </ProfilePictureProvider>
  );

  return (
    <HeaderBarContainer>
      <ToolBarContainer>
        <SpaceBetweenContainer>
          <LeftAligned />
          <RightAligned />
        </SpaceBetweenContainer>
      </ToolBarContainer>
    </HeaderBarContainer>
  );
};

export default NoLicensesPageHeader;

const SpaceBetweenContainer = ({ children }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    }}
  >
    {children}
  </Box>
);

const HeaderBarContainer = ({ children }) => (
  <AppBar
    sx={{
      backgroundColor: 'black',
      color: 'white',
      height: '64px',
    }}
  >
    {children}
  </AppBar>
);

const ToolBarContainer = ({ children }) => (
  <Toolbar sx={{ padding: '12px 16px' }}>{children}</Toolbar>
);
