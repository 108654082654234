import React from 'react';

import { InputForesiteField } from '@evolvemep/foresite-components-ui-react';
import { useFormContext } from 'react-hook-form';

const MachineEditFormTextField = ({ onBlur, ...rest }) => {
  const { control } = useFormContext();

  return (
    <InputForesiteField
      control={control}
      errorAlign="right"
      fullWidth
      onBlur={onBlur}
      size="small"
      color="secondary"
      {...rest}
    />
  );
};

export default MachineEditFormTextField;
