import { useCallback, useState } from 'react';

import { INode } from '../@types/modalContextTypes';

export type NotFoundNode = {
  parentId: string;
  node: INode;
};

const useNotFoundParentNodes = () => {
  const [notFoundParentNodes, setNotFoundParentNodes] = useState<NotFoundNode[]>([]);

  const findItemInNotFoundParent = useCallback(
    (searchedNode: INode): NotFoundNode =>
      notFoundParentNodes.find((curNode) => curNode.node === searchedNode) as NotFoundNode,
    [notFoundParentNodes],
  );

  const doesObjectExist = (newObject: INode, array: NotFoundNode[]) =>
    array.some((obj) => obj.node.id === newObject.id);

  const addNodeToNotFoundParentArray = (mappedNode: INode, parentId: string) =>
    setNotFoundParentNodes((prevNodes) =>
      doesObjectExist(mappedNode, prevNodes) ? prevNodes : [...prevNodes, { parentId, node: mappedNode }],
    );

  const removeNodeFromNotFoundParentArray = (node: NotFoundNode) =>
    setNotFoundParentNodes((prevNodes) =>
      prevNodes.filter((obj) => obj.parentId !== node.parentId || obj.node.id !== node.node.id),
    );

  return {
    notFoundParentNodes,
    findItemInNotFoundParent,
    addNodeToNotFoundParentArray,
    removeNodeFromNotFoundParentArray,
  };
};

export default useNotFoundParentNodes;
