import React from 'react';

import { RowPrefix } from '../../components/RowPrefix';
import RowTitle from '../../components/RowTitle';
import LocationEditableInputs from './LocationEditableInputs';

const LocationEditableName = React.memo(({ location }) => {
  if (location.isEditing) return <LocationEditableInputs location={location} />;

  return (
    <>
      <RowPrefix>{location.locationIdentifier}</RowPrefix>
      <RowTitle>{location.locationName}</RowTitle>
    </>
  );
});

export default LocationEditableName;
