import React from 'react';

import { useMutation, gql } from '@apollo/client';
import { Box, Button, CircularProgress } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import { useUser } from 'app/UserContext';
import { Modal } from 'components';
import { GENERIC_MUTATION_ERROR } from 'constants/globalConstants';
import { addTasktype as ADD_TASK_TYPE, updateTaskType as UPDATE_TASK_TYPE } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

import TaskTypeForm from './Form/TaskTypeForm';
import { useShopTasksContext } from './ShopTasksContext';

const NewTaskTypeModal = ({ open }) => {
  const { setShowNewTaskTypeModal, refetchTaskTypes, uploadTaskTypeImage } = useShopTasksContext();
  const { user } = useUser();
  const methods = useForm({ mode: 'all' });
  const {
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = methods;

  const [addTaskType, { loading }] = useMutation(gql(ADD_TASK_TYPE));
  const [updateTaskType] = useMutation(gql(UPDATE_TASK_TYPE));
  const { handleResponse } = useGraphqlResponseHandler();

  const submit = async (values) => {
    const imgFile = values.taskTypeImage;

    const newTaskType = await handleResponse(
      addTaskType,
      {
        variables: {
          body: {
            taskTypeName: values.taskTypeName,
            taskTypeDescription: values.taskTypeDescription,
            taskTypeClassId: '9e1a7bc6-5b0a-4aa7-bcb5-b354e3bc2cdb',
            companyId: user.companyId,
          },
        },
      },
      {
        successMessage: `Shop task type successfully created`,
        errorMessage: GENERIC_MUTATION_ERROR,
      },
    );

    const taskTypeId = newTaskType.data?.addTasktype?.taskTypeId;

    const documentId = await uploadTaskTypeImage(taskTypeId, imgFile);

    await updateTaskType({
      variables: {
        params: {
          taskTypeId,
        },
        body: {
          taskTypeImageId: documentId,
        },
      },
    });

    await refetchTaskTypes({
      taskTypeClassId: '9e1a7bc6-5b0a-4aa7-bcb5-b354e3bc2cdb',
      companyId: user?.companyId,
      orderBy: 'tasktypename',
    });

    setShowNewTaskTypeModal(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => setShowNewTaskTypeModal(false)}
      disableModalOutsideClick
      fullWidth
      scroll="paper"
      sx={{
        '.MuiPaper-root': {
          maxHeight: '80vh',
          maxWidth: '505px',
        },
      }}
      titleStyles={{ fontWeight: 700 }}
      title={<ModalHeader title="New shop task type" />}
      footer={
        <>
          {methods.formState.isSubmitting && <CircularProgress size={25} color="secondary" />}
          <Button color="primary" onClick={() => setShowNewTaskTypeModal(false)}>
            CANCEL
          </Button>
          <AddButton onClick={handleSubmit(submit)} disabled={!isValid || isSubmitting || loading}>
            CREATE
          </AddButton>
        </>
      }
    >
      <FormProvider {...methods}>
        <TaskTypeForm />
      </FormProvider>
    </Modal>
  );
};

export default NewTaskTypeModal;

const AddButton = ({ onClick, disabled, children }) => (
  <Button sx={{ marginLeft: '8px' }} color="secondary" variant="contained" onClick={onClick} disabled={disabled}>
    {children}
  </Button>
);

const ModalHeader = ({ title }) => <Box sx={{ width: 'md' }}>{title}</Box>;
