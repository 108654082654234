import { LazyQueryHookOptions, gql, useLazyQuery } from '@apollo/client';

import { workOrdersCount as GET_WORK_ORDERS_COUNT } from 'graphql/queries';
import { PaginationResponse, WorkOrdersCountQueryVariables } from 'graphql/types';

type useLazyWorkOrdersCountProps = {
  options?: LazyQueryHookOptions<
    { workOrdersCount: PaginationResponse },
    WorkOrdersCountQueryVariables
  >;
};

export const useLazyWorkOrdersCount = ({ options }: useLazyWorkOrdersCountProps) => {
  const [, { data, loading, called, refetch: fetchWorkOrderCount }] = useLazyQuery<
    { workOrdersCount: PaginationResponse },
    WorkOrdersCountQueryVariables
  >(gql(GET_WORK_ORDERS_COUNT), options);

  return {
    data,
    loading,
    called,
    fetchWorkOrderCount,
  };
};
