import React from 'react';

import ListFlyoutPaper from 'components/ListFlyoutPaper';

const StyledPopup = ({ onClose, children }) => (
  <ListFlyoutPaper
    onClose={onClose}
    sx={{
      bottom: '8px',
    }}
    useXButton
    topRightActionsSx={{ top: 8, right: 8 }}
  >
    {children}
  </ListFlyoutPaper>
);
export default StyledPopup;
