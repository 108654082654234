/* eslint-disable prefer-promise-reject-errors */
import Auth from '@aws-amplify/auth';

import { fetchProviderStatus } from './restCalls';

const useSignUp = () => {
  const resendSignUp = async ({ email }) => {
    const userInfo = await fetchProviderStatus(email);

    if (!userInfo) {
      return Promise.reject({
        code: 'UserNotFoundException',
        message: 'The email address does not exist',
      });
    }

    const result = await Auth.resendSignUp(userInfo.userName);

    return result;
  };

  return resendSignUp;
};

export default useSignUp;
