import { useCallback } from 'react';

import useDeleteWorkOrderItem from 'hooks-api/mutations/useDeleteWorkOrderItem';
import useLazyPaginatedWorkOrderItems from 'hooks-api/queries/workOrderItems/useLazyPaginatedWorkOrderItems';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

export const useDeleteWorkOrderItemDataLayer = (workOrder) => {
  const { handleResponse } = useGraphqlResponseHandler();

  const { workOrderItemsQuery } = useLazyPaginatedWorkOrderItems(workOrder);

  const [deleteWorkOrderItemMutation, { loading: deleteWorkOrderItemLoading }] = useDeleteWorkOrderItem({
    refetchQueries: [workOrderItemsQuery],
  });

  const handleDeleteWorkOrderItem = useCallback(async (workOrderItemId) => {
    await handleResponse(
      deleteWorkOrderItemMutation,
      { variables: { params: { workOrderItemId } } },
      { successMessage: 'Item deleted successfully', errorMessage: 'Could not delete item' },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    handleDeleteWorkOrderItem,
    deleteWorkOrderItemLoading,
  };
};
