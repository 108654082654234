import React from 'react';

import { Modal } from 'components';

const StyledModal = ({ children, opacity, ...props }) => (
  <Modal
    reducePadding
    fullWidth
    title="New Locations"
    maxWidth="sm"
    disableModalOutsideClick
    disableTitleClose
    disableEscapeKeyDown
    sx={{
      '.MuiDialog-paper': {
        maxWidth: '530px',
        maxHeight: '700px',
      },
      opacity,
    }}
    titleStyles={{
      letterSpacing: 0.15,
      fontWeight: 600,
    }}
    contentStyles={{
      pt: 1,
      pb: 2,
    }}
    {...props}
  >
    {children}
  </Modal>
);

export default StyledModal;
