import { gql } from '@apollo/client';

import { billOfMaterialItem as BILL_OF_MATERIAL_ITEM } from 'graphql/queries';
import useCache from 'hooks/useCache';

const useRefetchBOMItemsChildren = () => {
  const { getAllQueriesByKey } = useCache();

  const getBOMItemsChildrenIds = async (lineItemId) => {
    if (!lineItemId) return [];

    const recursiveChildrenIds = [];

    const getItemRecursively = (parentLineItemIds) => {
      recursiveChildrenIds.push(parentLineItemIds);
      const array = getAllQueriesByKey('billOfMaterialItem', gql(BILL_OF_MATERIAL_ITEM), { parentLineItemIds });

      array.forEach((cachedItem) => {
        cachedItem.value.forEach((child) => getItemRecursively(child.lineItemId));
      });
    };

    getItemRecursively(lineItemId);

    return recursiveChildrenIds;
  };

  return { getBOMItemsChildrenIds };
};
export default useRefetchBOMItemsChildren;
