import { useCallback } from 'react';

import { gql, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { GENERIC_MUTATION_ERROR } from 'constants/globalConstants';
import { addTaskDocument as ADD_TASK_DOCUMENT, deleteTaskDocument as DELETE_TASK_DOCUMENT } from 'graphql/mutations';

type addTaskByDocumentTypes = {
  taskId: string;
  documentIds: string[];
  externalUrl: string;
};

const useActionsDocument = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [addTaskDocument, { loading: addTaskDocumentLoading }] = useMutation(gql(ADD_TASK_DOCUMENT), {
    refetchQueries: ['TaskMultiSearch', 'TaskDocument'],
  });

  const [deleteTaskDocument, { loading: removeTaskDocumentLoading }] = useMutation(gql(DELETE_TASK_DOCUMENT), {
    refetchQueries: ['TaskMultiSearch', 'TaskDocument'],
  });

  const addTaskByDocument = useCallback(
    async ({ taskId, documentIds, externalUrl }: addTaskByDocumentTypes) => {
      const body = {
        taskId,
        documentIds,
        externalUrl,
      };

      const message = externalUrl ? 'URL successfully added' : 'File successfully added';

      await addTaskDocument({
        variables: {
          body,
        },
        awaitRefetchQueries: true,
        onCompleted: (response) => {
          if (!response?.addTaskDocument?.length) return;
          enqueueSnackbar(message, {
            preventDuplicate: true,
            variant: 'success',
          });
        },
        onError: () => {
          enqueueSnackbar(GENERIC_MUTATION_ERROR, {
            preventDuplicate: true,
            variant: 'error',
          });
        },
      });
    },
    [addTaskDocument, enqueueSnackbar],
  );

  const removeTaskDocument = useCallback(
    async (taskDocumentId: string) =>
      deleteTaskDocument({
        variables: {
          params: {
            taskDocumentId,
          },
        },
        awaitRefetchQueries: true,
        onCompleted: (response) => {
          if (!response.deleteTaskDocument) return;

          enqueueSnackbar('Attachment successfully deleted', {
            preventDuplicate: true,
            variant: 'success',
          });
        },
        onError: () => {
          enqueueSnackbar(GENERIC_MUTATION_ERROR, {
            preventDuplicate: true,
            variant: 'error',
          });
        },
      }),
    [deleteTaskDocument, enqueueSnackbar],
  );

  return { addTaskDocumentLoading, removeTaskDocumentLoading, addTaskByDocument, removeTaskDocument };
};

export default useActionsDocument;
