import React from 'react';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const BaseButton = ({
  color,
  onClick = () => {},
  buttonText = 'COLUMNS',
  icon = () => null,
  sx,
  ...others
}) => (
  <Button onClick={onClick} sx={{ marginRight: 2, ...sx }} color={color} {...others}>
    <Stack direction="row">
      {icon(others)}
      <Typography
        color="secondary.main"
        variant="button"
        sx={{ fontWeight: 600, letterSpacing: '0.4px' }}
      >
        {buttonText}
      </Typography>
    </Stack>
  </Button>
);

export default BaseButton;
