import { gql, useQuery } from '@apollo/client';

import { projectById as PROJECTBYID } from 'graphql/queries';

const useFetchProjectById = (projectId) => {
  const { data, loading } = useQuery(gql(PROJECTBYID), {
    variables: {
      params: { projectId },
    },
    fetchPolicy: 'cache-and-network',
  });
  return { loading, project: data?.projectById };
};

export default useFetchProjectById;
