import React, { FC } from 'react';

import LBSRowCell from './LBSRowCell';

interface ILBSRowInfo {
  rowInfo: {
    identifier?: string;
    locationName?: string;
  };
}

const LBSRowInfo: FC<ILBSRowInfo> = ({ rowInfo }) => (
  <>
    <LBSRowCell width={rowInfo.locationName ? '76px' : 'auto'} label={rowInfo.identifier} />
    <LBSRowCell variant="subtitle2" flex={1} label={rowInfo.locationName} />
  </>
);

export default LBSRowInfo;
