import React, { FC } from 'react';

import { useApolloClient } from '@apollo/client';
import { useWatch } from 'react-hook-form';

import { useCurrentProjectContext } from 'modules/Materials/components/ItemsIntegrationModal/context/CurrentProjectContext';

import { IItemsIntegrationModal } from './@types/ItemsIntegrationModal';
import { NewWRFormContextType } from './@types/newWRForm';
import ItemsModal from './components/ItemsModal/ItemsModal';
import { useNewWRFormContext } from './components/NewWRForm/context/NewWRFormContext';
import { QuantityFieldType } from './components/QuantityStep/components/ItemsIntegrationTable/components/QuantityField/hooks/@types/fieldHookTypes';
import { ADD_REMAINING_QUANTITIES_FIELD } from './components/QuantityStep/components/ItemsIntegrationTable/constants';
import { useItemsIntegrationTableContext } from './components/QuantityStep/components/ItemsIntegrationTable/context/ItemsIntegrationTableContext';
import WizardButton from './components/WizardButton/WizardButton';
import WizardContent from './components/WizardContent/WizardContent';
import { useItemsIntegrationModalContext } from './context/ItemsIntegrationModalContext';
import { getModalSubtitle, handlePreviousClick, handleSubmitLBS } from './helpers/itemsIntegrationModalHelpers';
import useModalHandlers from './hooks/useModalHandlers';
import useModalHelpers from './hooks/useModalHelpers';

const ItemsIntegrationModal: FC<IItemsIntegrationModal> = ({
  addItems = () => {},
  integratedItemsERN = '',
  integrationId = '',
  modalTitle = '',
  refetchQueries = () => {},
}: IItemsIntegrationModal) => {
  const {
    open,
    setActiveStep,
    selectionModel,
    addNewWRForm,
    setAddNewWRForm,
    isLoading,
    setIsLoading,
    totalSelectedItems,
  } = useItemsIntegrationModalContext();
  const {
    methods: {
      formState: { isValid, isDirty },
    },
  }: NewWRFormContextType = useNewWRFormContext();
  const {
    methods: { getValues: getBOMTableValues, control },
    rows: lineItems,
    quantityFields,
    baseERN,
    autoFilledFields,
  } = useItemsIntegrationTableContext();
  const isAddAllRemainingQuantities = useWatch({ control, name: ADD_REMAINING_QUANTITIES_FIELD });
  const { currentProject } = useCurrentProjectContext();

  const client = useApolloClient();

  const { getIntegrationId } = useModalHelpers({ integrationId });
  const { handleCancel, handleItemsCreated, handleError, handleSubtitleCallback } = useModalHandlers();

  const cancelModalButtonDisabled = isLoading || false;
  const nextButtonDisabled = isLoading || addNewWRForm ? !isValid : !selectionModel?.length;
  const previousButtonDisabled = isLoading || false;

  return (
    <ItemsModal
      title={modalTitle}
      open={open}
      onClose={() => handleCancel()}
      subtitle={getModalSubtitle({ totalSelectedItems, handleSubtitleCallback })}
      footer={
        <ItemsModal.Footer
          NextButton={WizardButton.Next}
          PreviousButton={WizardButton.Previous}
          DoneButton={ItemsModal.DoneButton}
          ErrorMessage={ItemsModal.ErrorMessage}
          CancelButton={ItemsModal.CancelButton}
          handlePreviousClick={() => handlePreviousClick({ setActiveStep, setAddNewWRForm, isDirty })}
          handleCloseModal={() => handleCancel()}
          handleSubmitItems={() => {
            handleSubmitLBS({
              setIsLoading,
              quantityFields: quantityFields.filter((field: QuantityFieldType) => field.id),
              getBOMTableValues,
              addItems,
              client,
              integratedItemsERN,
              onItemsCreated: handleItemsCreated,
              onError: handleError,
              getIntegrationId,
              rows: lineItems,
              isDirty,
              projectId: currentProject?.id,
              baseERN,
              isAddAllRemainingQuantities,
              autoFilledFields,
              refetchQueries,
            });
          }}
          {...{
            cancelModalButtonDisabled,
            nextButtonDisabled,
            previousButtonDisabled,
          }}
        />
      }
    >
      <WizardContent />
    </ItemsModal>
  );
};

export default ItemsIntegrationModal;
