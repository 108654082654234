import React, { FC } from 'react';

import { BOMDataType } from 'modules/Materials/components/ItemsIntegrationModal/@types/ItemsIntegrationModal';

import RowPlaceholder from '../RowPlaceholder/RowPlaceholder';
import ScopeRow from '../ScopeRow/ScopeRow';
import useWorkPhasesScopePackageTable from './hooks/useWorkPhasesScopePackageTable';

interface IWorkPhasesScopePackageTable {
  BOMData: BOMDataType;
}
const WorkPhasesScopePackageTable: FC<IWorkPhasesScopePackageTable> = ({ BOMData }: IWorkPhasesScopePackageTable) => {
  const { loadingScopePackages, loadingLBSData, scopePackages, workPhasesLBS, workPhases } =
    useWorkPhasesScopePackageTable({
      BOMData,
    });
  if (loadingScopePackages || loadingLBSData) return <RowPlaceholder />;
  if (!scopePackages?.length) return <></>;

  return (
    <>
      {scopePackages.map((row: any) => (
        <ScopeRow
          key={row.scopePackageId}
          lineItemId={BOMData.id}
          scopeInfo={{ ...row, locationName: row.scopePackageName, identifier: row.scopePackageIdentifier }}
          workPhasesLBS={workPhasesLBS}
          workPhases={workPhases}
        />
      ))}
    </>
  );
};

export default WorkPhasesScopePackageTable;
