import React from 'react';

import { useTheme } from '@emotion/react';
import { ClickAwayListener, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { GridRow } from '@mui/x-data-grid-pro';

import { greyColors } from 'styles/theme/colors';

import { useDrawingFolderRowContext } from '../../DrawingFolderRowProvider';
import DrawingFolderFormRow from '../DrawingFolderFormRow/DrawingFolderFormRow';

export default function DrawingFolderRow({
  provided,
  snapshot,
  params,
  onCloseNewFolderForm = () => {},
}) {
  const { row: rowData } = params;
  const { onSaveFolder, saveFolderLoading } = useDrawingFolderRowContext();
  const theme = useTheme();

  if (rowData.type === 'new_folder' || (rowData.isEditing && rowData.type === 'folder')) {
    return (
      <ClickAwayListener
        onClickAway={onSaveFolder}
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
      >
        <Stack ref={provided.innerRef} {...provided.droppableProps}>
          <DrawingFolderFormRow
            row={rowData}
            onClose={onCloseNewFolderForm}
            onSave={onSaveFolder}
            loading={saveFolderLoading}
          />
        </Stack>
      </ClickAwayListener>
    );
  }

  return (
    <Box
      ref={provided.innerRef}
      {...provided.droppableProps}
      sx={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        position: 'relative',
        '& > .folder-row': {
          borderTop: snapshot.isDraggingOver ? `1px solid ${theme.palette?.secondary?.main}` : '',
          borderBottom: snapshot.isDraggingOver
            ? `1px solid ${theme.palette?.secondary?.main} !important`
            : `1px solid ${greyColors.grey300}`,
        },
      }}
    >
      <GridRow {...params} />
    </Box>
  );
}
