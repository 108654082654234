import { useState } from 'react';

import { WatchQueryFetchPolicy, gql, useLazyQuery } from '@apollo/client';

import { workOrders as WORKORDERS } from 'graphql/queries';
import { WorkOrder } from 'graphql/types';

/**
 * Default number of rows to pull, can be overridden by caller
 */
const take = 10;

type Response = {
  workOrders: WorkOrder[];
};

const useFetchWorkOrders = (fetchPolicy: WatchQueryFetchPolicy = 'no-cache') => {
  const [hasMore, setHasMore] = useState(true);

  const [getWorkOrders, { data, loading, fetchMore, startPolling, stopPolling, refetch: refetchWorkOrders }] =
    useLazyQuery<Response>(gql(WORKORDERS), { fetchPolicy });

  const paginationHandler = async (skip: number, query: any) => {
    if (!hasMore) return;
    const variables = {
      query: { skip, take, ...query },
    };
    const res = await fetchMore({ variables });

    if (res && res.data.workOrders.length < variables.query.take) {
      setHasMore(false);
    }
  };

  return {
    getWorkOrders,
    workOrders: data?.workOrders || [],
    loading,
    paginationHandler,
    startPolling,
    stopPolling,
    refetchWorkOrders,
  };
};

export default useFetchWorkOrders;
