import { useMemo } from 'react';

import { BOMDataType } from 'modules/Materials/components/ItemsIntegrationModal/@types/ItemsIntegrationModal';

import useWorkPhasesScopePackageTableEffectsHandler from './useWorkPhasesScopePackageTableEffectsHandler';

interface IUseWorkPhasesScopePackageTable {
  BOMData: BOMDataType;
}

const useWorkPhasesScopePackageTable = ({ BOMData }: IUseWorkPhasesScopePackageTable) => {
  const { loadingLBSData, scopePackagesData, loadingScopePackages, workPhasesLBS, workPhases } =
    useWorkPhasesScopePackageTableEffectsHandler({ BOMData });
  const scopePackages = useMemo(() => scopePackagesData?.scopePackage, [scopePackagesData?.scopePackage]);

  return {
    loadingScopePackages,
    loadingLBSData,
    scopePackages,
    workPhasesLBS,
    workPhases,
  };
};

export default useWorkPhasesScopePackageTable;
