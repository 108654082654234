import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { SESSION_STORAGE_CONSTANTS } from 'constants/globalConstants';
import useFetchProjectById from 'hooks-api/useFetchProjectById';

const WorkRequestsContext = React.createContext();

export const WORK_REQUEST_TABLES = {
  WORK_REQUEST_LIST: 'WorkRequestList',
  WORK_REQUEST_ITEMS_LIST: 'WorkRequestItemsList',
};

const { WR_PROJECT_SELECTED } = SESSION_STORAGE_CONSTANTS;

const WorkRequestsProvider = ({ children, workRequest = null, workOrderSelected = null, isWorkOrder = false }) => {
  const [isDrawingOpen, setIsDrawingOpen] = useState(false);
  const [workRequestSelected, setWorkRequest] = useState();
  const [updateWRList, setUpdateWRList] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [disabledNew, setDisabledNew] = useState(true);
  const [disabledFilters, setDisabledFilters] = useState(true);
  const selectedProjectIdRef = useRef(null);
  const [currentTable, setCurrentTable] = useState(WORK_REQUEST_TABLES.WORK_REQUEST_LIST);
  const [filters, setFilters] = useState([]);
  const { selectedItem } = useFacilitiesProjects();
  const { project: projectById } = useFetchProjectById(workRequestSelected?.projectId);

  const selectedProjectId = useMemo(() => {
    const isProjectSelected = selectedItem?.type === 'PROJECT';
    return isProjectSelected ? selectedItem.id : null;
  }, [selectedItem]);

  useEffect(() => setIsDrawingOpen(false), [selectedItem]);
  useEffect(() => setWorkRequest(workRequest), [workRequest]);

  useEffect(() => {
    if (projectById) {
      const project = {
        id: projectById.projectId,
        identifier: projectById.projectIdentifier,
        name: projectById.projectName,
        label: projectById.projectIdentifier,
        type: 'PROJECT',
      };
      sessionStorage.setItem(WR_PROJECT_SELECTED, JSON.stringify(project));
    } else {
      sessionStorage.removeItem(WR_PROJECT_SELECTED);
    }
  }, [projectById]);

  useEffect(() => {
    if (!selectedProjectId || selectedProjectIdRef.current === selectedProjectId) return;

    if (currentTable === WORK_REQUEST_TABLES.WORK_REQUEST_ITEMS_LIST)
      setCurrentTable(WORK_REQUEST_TABLES.WORK_REQUEST_LIST);

    selectedProjectIdRef.current = selectedProjectId;
  }, [currentTable, selectedProjectId]);

  const selectedStateObj = {
    workRequestSelected,
    setWorkRequest,
    activeTabIndex,
    setActiveTabIndex,
    filters,
    setFilters,
    disabledNew,
    disabledFilters,
    setDisabledFilters,
    setDisabledNew,
    currentTable,
    setCurrentTable,
    updateWRList,
    setUpdateWRList,
    isDrawingOpen,
    setIsDrawingOpen,
    workOrderSelected,
    isWorkOrder,
  };

  return <WorkRequestsContext.Provider value={selectedStateObj}>{children}</WorkRequestsContext.Provider>;
};

const useWorkRequestsContext = () => {
  const context = React.useContext(WorkRequestsContext);
  if (context === undefined) throw new Error('useWorkRequestsContext must be used within a WorkRequestsContext');
  return context;
};

export { WorkRequestsContext, WorkRequestsProvider, useWorkRequestsContext };
