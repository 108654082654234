import { useEffect } from 'react';

const useKeyPress = (key, methodToExecute, methodParameters) => {
  useEffect(() => {
    const handleKeyUp = (e) => {
      if (e.keyCode === key) methodToExecute(methodParameters);
    };

    window.document.addEventListener('keyup', handleKeyUp);

    return () => {
      window.document.removeEventListener('keyup', handleKeyUp);
    };
    // eslint-disable-next-line
  }, []);
};

export default useKeyPress;
