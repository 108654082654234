import React from 'react';

import IconButton from '@mui/material/IconButton';

import { Custom } from 'components/Icons';
import { surfaceColors } from 'styles/theme/colors';

const OpenCollapseIconButton = ({ open, onClick, filled, useMathSymbols, disabled, sx }) => {
  const styledIcons = (() => {
    if (useMathSymbols) return <MathIcon open={open} sx={sx} />;
    return filled ? <FilledIcon open={open} sx={sx} /> : <OutlinedIcon open={open} sx={sx} />;
  })();

  return (
    <IconButton
      disabled={disabled}
      data-testid="open-collapse-button"
      aria-label={open ? 'collapse-row' : 'expand-row'}
      size="small"
      onClick={(e) => {
        e.stopPropagation();
        onClick(!open);
      }}
      sx={{
        color: open ? surfaceColors.lightSurface.primary : surfaceColors.lightSurface.secondary,
        '&.Mui-disabled': {
          color: '#00000029 !important',
        },
      }}
    >
      {styledIcons}
    </IconButton>
  );
};

export default OpenCollapseIconButton;

const animatedStyles = (isOpen) => ({
  transform: `rotateZ(${isOpen ? 0 : -90}deg)`,
  transition: (theme) =>
    theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
});

const FilledIcon = ({ open, sx }) => <Custom.ArrowDropDownIcon sx={{ ...animatedStyles(open), ...sx }} />;
const MathIcon = ({ open, sx }) => (open ? <Custom.Remove sx={sx} /> : <Custom.AddIcon sx={sx} />);
const OutlinedIcon = ({ open, sx }) => <Custom.KeyboardArrowDownIcon sx={{ ...animatedStyles(open), ...sx }} />;
