import React, { memo, useMemo } from 'react';

import { Box } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { UOMAutocomplete } from 'modules/Materials/BillOfMaterialsById/BOMCatalogTable/Form';

import { useTableRowContext } from '../context/TableRowContext';
import { useTableRowFormContext } from '../context/TableRowFormContext';
import { useWorkOrderItemsContext } from '../context/WorkOrderItemsContext';

const UnitOfMeasureCell = memo(({ setOpenModalUOM }) => {
  const { item, showForm, setShowForm, setFieldFocus, fieldFocus, isAddItemRow, isWriteInItem, uomOptions } =
    useTableRowContext();
  const { canEditUOM } = useWorkOrderItemsContext();

  const canEditWriteInItemUOM = canEditUOM && isWriteInItem;

  const unitOfMeasure = useMemo(
    () => uomOptions?.filter((uom) => uom.value === item?.unitOfMeasureId)?.at(0),
    [uomOptions, item?.unitOfMeasureId],
  );

  const handleItemUOMClick = () => {
    if (canEditWriteInItemUOM && !showForm) {
      setShowForm(true);
      setFieldFocus('unitOfMeasureId');
    }
  };

  return (
    <Box
      sx={{
        height: '100%',
        pt: showForm ? '4px' : '6px',
        width: '-webkit-fill-available',
        cursor: !showForm && canEditWriteInItemUOM && 'pointer',
      }}
      onClick={handleItemUOMClick}
      onKeyDown={handleItemUOMClick}
    >
      {showForm && (isWriteInItem || isAddItemRow) ? (
        <UnitOfMeasureSelect
          autoFocus={!isAddItemRow && fieldFocus === 'unitOfMeasureId'}
          setOpenModalUOM={setOpenModalUOM}
          options={uomOptions}
        />
      ) : (
        unitOfMeasure?.label || ''
      )}
    </Box>
  );
});

export default UnitOfMeasureCell;

const UnitOfMeasureSelect = ({ options, autoFocus, setOpenModalUOM }) => {
  const { control } = useFormContext();

  const { canEditUOM } = useWorkOrderItemsContext();

  const { onKeyDown } = useTableRowFormContext();

  return (
    <UOMAutocomplete
      disableClearable
      control={control}
      options={options}
      onCreate={() => setOpenModalUOM(true)}
      name="unitOfMeasureId"
      onKeyDown={onKeyDown}
      filterMethod="startsWith"
      sxRenderInput={{
        height: 'auto',
        width: '88px',
      }}
      disabled={!canEditUOM}
      inputProps={{
        autoFocus,
      }}
    />
  );
};
