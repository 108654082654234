import React, { useEffect, useRef } from 'react';

import { Box, useTheme, TextField, ClickAwayListener, CircularProgress } from '@mui/material';
import { useController } from 'react-hook-form';

import useKeyPressEffect from 'hooks/useKeyPressEffect';
import { useUpdateWorkRequest } from 'modules/Field/WorkRequests/WorRequestsProperties/WorkRequestPropertiesAttachments/hooks/useUpdateWorkRequest';

import { useWorkRequestsContext } from '../../../WorkRequestsContext';

const setUpdatedWorkRequest = (data, loading, setWorkRequest, setHovered) => {
  if (data && !loading) {
    setWorkRequest(data.updateWorkRequest);
    setHovered(false);
  }
};

const NameForm = ({ setHovered, control, reset, handleSubmit, isSubmitting }) => {
  const { updateWorkRequest, loading, data } = useUpdateWorkRequest();
  const { workRequestSelected, setWorkRequest } = useWorkRequestsContext();
  const name = 'workRequestName';
  const { palette } = useTheme();
  const updatedName = useRef();

  const connectValueToRef = (value) => {
    updatedName.current = value;
    return true;
  };

  const rules = {
    required: '*Required',
    maxLength: {
      value: 63,
      message: '64/64',
    },
    validate: connectValueToRef,
  };

  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({ name, control, rules });

  const validName = updatedName.current && updatedName.current !== workRequestSelected?.workRequestName && !error;

  const submit = async () => {
    if (!validName) {
      reset();
      setHovered(false);
    }
    if (validName) {
      const body = { workRequestName: updatedName.current };
      await updateWorkRequest(workRequestSelected.workRequestId, body);
      reset();
    }
  };

  useEffect(() => {
    setUpdatedWorkRequest(data, loading, setWorkRequest, setHovered);
  }, [data, loading, setWorkRequest, setHovered, updateWorkRequest]);

  const submitCheck = () => {
    if (isSubmitting) return;
    handleSubmit(submit)();
  };

  useKeyPressEffect('Enter', submitCheck);

  return (
    <ClickAwayListener onClickAway={submitCheck}>
      <Box width="205px" height={error ? undefined : '26px'} sx={{ textAlign: 'right' }}>
        {isSubmitting && <CircularProgress sx={{ color: 'secondary.mainDarkBackground' }} size="22px" />}
        {!isSubmitting && (
          <TextField
            id="name"
            multiline
            maxRows={1}
            disabled={loading}
            error={error}
            size="small"
            inputProps={{ maxLength: 64 }}
            helperText={error?.message || ''}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            sx={getFormStyles(palette, error)}
          />
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default NameForm;

const getFormStyles = (palette, error) => ({
  width: '100%',
  '& .MuiInputBase-root.Mui-disabled': {
    color: palette.surface.darkSurface.disabled,
    '& textarea': {
      WebkitTextFillColor: `${palette.surface.darkSurface.disabled} !important`,
    },
  },
  '& .Mui-error': {
    color: palette.error.light,
    lineHeight: '20px',
  },
  '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
    borderColor: `${palette.error.light} !important`,
  },
  '& .MuiFormHelperText-root': {
    textAlign: 'left',
    color: palette.error.light,
    marginTop: 0,
  },
  '& .MuiInputBase-root': {
    color: palette.surface.darkSurface.secondary,
    padding: '4px 0 4px 12px',
    fontSize: '14px',
    lineHeight: '20px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: `${palette.surface.darkSurface.secondary} !important`,
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: error ? '' : `${palette.secondary.mainDarkBackground} !important`,
  },
});
