import React from 'react';

import { GridRow } from '@mui/x-data-grid-pro';

import { NEW_ITEM_ROW_ID } from 'constants/globalConstants';

import EditRow from './EditRow';

type BOMItemGridRowProps = {
  params: any;
  onCancelEditRow: (id: string) => void;
  onSuccessEditRow: (id: string) => void;
};

const BOMItemGridRow = ({ params, onCancelEditRow, onSuccessEditRow }: BOMItemGridRowProps) => {
  const { row } = params;

  const isRowInEditMode = row?.lineItemId === NEW_ITEM_ROW_ID.toString();
  if (!isRowInEditMode) return <GridRow {...params} />;

  return <EditRow params={params} onCancelEditRow={onCancelEditRow} onSuccessEditRow={onSuccessEditRow} />;
};

export default React.memo(BOMItemGridRow);
