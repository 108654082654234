import { useEffect, useState } from 'react';

import { useFilterOptions } from './options';
import { useRulesFilter } from './rules';
import { useSourceDataOption } from './source-data';

export const useBOMItemsFilters = () => {
  const [filters, setFilters] = useState({});

  const { query: optionsQuery } = useFilterOptions();
  const { encodedQuery: rules } = useRulesFilter();
  const { encodedQuery: externalSourceImportDescription } = useSourceDataOption();

  useEffect(() => {
    const newFilter = {
      ...optionsQuery,
      rules,
      externalSourceImportDescription,
    };

    setFilters((prev) => {
      if (JSON.stringify(prev) === JSON.stringify(newFilter)) return prev;
      return newFilter;
    });
  }, [optionsQuery, rules, externalSourceImportDescription]);

  return filters;
};
