import React from 'react';

import { useMutation, gql } from '@apollo/client';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { Modal, CancelButton, DeleteButton } from 'components';
import { deleteWorkRequest as DELETE_WORK_REQUEST } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

const WorkRequestDeleteModal = ({ setWorkRequest, openStatus, onClose, id, identifier }) => {
  const { handleResponse } = useGraphqlResponseHandler();

  const [deleteWorkRequest, { loading }] = useMutation(gql(DELETE_WORK_REQUEST), {
    refetchQueries: ['WorkRequests'],
  });

  const submit = async () => {
    await handleResponse(
      deleteWorkRequest,
      {
        variables: {
          params: {
            workRequestId: id,
          },
        },
      },
      { successMessage: 'File successfully deleted' },
      () => {
        if (setWorkRequest) setWorkRequest(null);
      },
    );
    onClose();
  };

  return (
    <Modal
      disableModalOutsideClick
      disableCloseButton={loading}
      open={openStatus}
      onClose={onClose}
      title="Confirm Delete"
      reducePadding
      fullWidth
      maxWidth="xs"
      footer={
        <>
          {loading && <CircularProgress />}
          <CancelButton onClick={onClose} />
          <DeleteButton disabled={loading} onClick={submit} />
        </>
      }
    >
      <Box sx={{ fontSize: '16px' }}>
        Are you sure you want to delete this{' '}
        <Typography sx={{ fontWeight: 'bold', display: 'inline' }}>{identifier}</Typography>?
      </Box>
    </Modal>
  );
};

export default WorkRequestDeleteModal;
