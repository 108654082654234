import React, { useEffect, useRef } from 'react';

import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import ComponentPaneActions from 'app/Layout/ReactGridLayout/ComponentPaneActions';
import ForgeViewerDocument from 'components/Autodesk/ForgeViewerDocument';
import useSetupModule from 'hooks/useSetupModule';

import { getModuleRoute } from '../FieldWorkRequestHelpers';
import { useWorkRequestsContext } from '../WorkRequestsContext';
import SelectWorkRequest from './SelectWorkRequest';
import WorkRequestHeader from './WorkRequestHeader';
import { useWorkRequestItemsContext } from './WorkRequestItemsContext';
import WorkRequestItemsLayout from './WorkRequestItemsLayout';

const WorkRequest = ({ isResponsiveWR, hideBulkActions = false }) => {
  const { workRequestSelected } = useWorkRequestsContext();
  const { selectedItem } = useFacilitiesProjects();
  const { currentModule } = useSetupModule();
  const ref = useRef(selectedItem);
  const navigate = useNavigate();
  const moduleName = currentModule();
  const moduleRoute = getModuleRoute(moduleName);
  const { apiRef, workRequestItemsSelected, activeDocument, inForgeView } = useWorkRequestItemsContext();

  useEffect(() => {
    if (selectedItem?.id !== ref?.current?.id) navigate(`/${moduleRoute}/work-requests`);
  }, [navigate, selectedItem?.id, moduleRoute]);

  return (
    <Stack sx={{ height: '100%' }}>
      <WorkRequestHeader selectedItem={selectedItem} isResponsiveWR={isResponsiveWR} />
      {inForgeView ? (
        <ForgeViewerDocument document={activeDocument} />
      ) : (
        <>
          {!hideBulkActions ? (
            <SelectWorkRequest disabled apiRef={apiRef} workRequestItemsSelected={workRequestItemsSelected} />
          ) : (
            <ComponentPaneActions />
          )}
          <WorkRequestItemsLayout {...{ workRequestSelected }} isResponsiveWR={isResponsiveWR} />
        </>
      )}
    </Stack>
  );
};

export default WorkRequest;
