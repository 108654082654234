import React, { useCallback, useMemo } from 'react';

import { CircularProgress, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

import PartCategoryCard from '../PartCategoryCard/PartCategoryCard';
import { usePartCategory } from '../PartCategoryContext';
import { usePartCategorySearch } from '../PartCategorySearchContext';

const PartCategoriesGrid = () => {
  const { partCategories, partsCounts, selectedPartCategories, onSelectPartCategory, loading } = usePartCategory();
  const { isSearching } = usePartCategorySearch();

  const handleSelectCategory = useCallback((category) => onSelectPartCategory(category), [onSelectPartCategory]);
  const isMainCategoryLevel = useMemo(() => selectedPartCategories?.length === 1, [selectedPartCategories]);

  if (loading) return <CircularProgress sx={{ color: 'secondary.mainDarkBackground' }} />;
  if (!partCategories?.length)
    return (
      <Stack width="100%" height="100%" justifyContent="center" alignItems="center">
        <Typography color="surface.darkSurface.primary">No categories found</Typography>
      </Stack>
    );
  return (
    <>
      {!isSearching ? (
        <>
          <Stack
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))',
              rowGap: 5,
            }}
          >
            {partCategories.map((category) => (
              <PartCategoryCard
                key={category.partCategoryId}
                isMainCategory={isMainCategoryLevel}
                category={category}
                partsCount={partsCounts[category.partCategoryId] ?? 0}
                onSelect={handleSelectCategory}
                selectedPartCategories={selectedPartCategories}
              />
            ))}
          </Stack>

          {!isMainCategoryLevel && Boolean(partCategories.length) && (
            <Divider sx={{ height: '1px', border: '1px solid #FFFFFF1F', my: 2 }} />
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default PartCategoriesGrid;
