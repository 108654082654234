import React from 'react';

import { Box } from '@mui/material';

import { NoContentMessage } from 'components';
import { Custom } from 'components/Icons';

const NoContentIconMessage = ({ message }) => (
  <Box
    flex
    sx={{
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '75%',
    }}
  >
    <NoContentMessage
      sx={{ margin: 0 }}
      text={
        <>
          <Custom.WorkCells transform="scale(2.5)" />
          {message}
        </>
      }
    />
  </Box>
);

export default NoContentIconMessage;
