import React from 'react';

import { useTheme } from '@emotion/react';

import { Custom } from 'components/Icons';

const DecisionIcon = ({ hasOpenDecisions, decisionsContainsDeletedVariant }) => {
  const { palette } = useTheme();
  if (decisionsContainsDeletedVariant) return <Custom.Alert />;

  return <Custom.DecisionIcon fill={hasOpenDecisions ? `${palette.secondary.main} ` : '#0000008A'} />;
};

export default DecisionIcon;
