import React from 'react';

import { ForesiteTextField } from '@evolvemep/foresite-components-ui-react';
import { Box, Stack, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useUser } from 'app/UserContext';
import { CancelButton, CenteredLoadSpinner, CreateButton, Modal } from 'components';
import InputSelectField from 'components/FormComponents/InputSelectField';
import { requiredRule } from 'constants/inputFieldRules';
import useFetchFacilities from 'hooks-api/useFetchFacilities';

const WorkRequestTransferModal = ({ openStatus, onClose, updateWorkRequest, updateWorkRequestStatus, workRequest }) => {
  const methods = useForm({ mode: 'all', defaultValues: {} });
  const { handleSubmit, watch } = methods;

  const { user } = useUser();
  const navigate = useNavigate();

  const { loading: loadingFacilities, facilities } = useFetchFacilities({
    companyId: user.companyId,
    hasShopDepartment: true,
    showAvailableFacilities: true,
  });

  const updateWorkRequestStatusCallback = () => {
    updateWorkRequestStatus(workRequest, 'Transferred', watch('WorkRequestStatusDescription'), () =>
      navigate('/shop/work-requests'),
    );
  };

  const submit = (workRequesUpdate) => {
    updateWorkRequest({ facilityId: workRequesUpdate.facilityId }, updateWorkRequestStatusCallback);
    onClose();
  };
  return (
    <Modal
      disableModalOutsideClick
      open={openStatus}
      onClose={onClose}
      title="Transfer To"
      reducePadding
      fullWidth
      maxWidth="sm"
      titleContainerStyles={{ pr: '2px' }}
      footer={
        <>
          <CancelButton onClick={onClose} sx={{ color: '#442C88', fontWeight: 'bold' }} />
          <CreateButton
            onClick={handleSubmit(submit)}
            buttonText="TRANSFER"
            color="secondary"
            data-testid="transfer-button"
          />
        </>
      }
    >
      {loadingFacilities || !workRequest ? (
        <CenteredLoadSpinner sx={{ height: '294px' }} />
      ) : (
        <FormProvider {...methods}>
          <Stack>
            <InformationField>{workRequest?.projectName}</InformationField>
            <InformationField>{workRequest?.workRequestIdentifier}</InformationField>
            <InformationField>{workRequest?.facilityName}</InformationField>
            <Box sx={{ mt: '24px' }}>
              <Typography variant="formBoldLabel">Transfer To*</Typography>
              <InputSelectField
                name="facilityId"
                label="Facility"
                color="secondary"
                size="small"
                fullWidth
                defaultValue=""
                rules={requiredRule('Facility')}
                options={facilities?.filter(
                  ({ value, departments }) =>
                    workRequest?.facilityId !== value &&
                    departments.some(({ departmentType: { departmentTypeName } }) => departmentTypeName === 'Shop'),
                )}
                menuItemSx={{ fontSize: '16px' }}
                SelectProps={{
                  SelectDisplayProps: {
                    'data-testid': 'transfer-facility-select-button',
                  },
                }}
              />
              <ForesiteTextField
                name="WorkRequestStatusDescription"
                label="Notes"
                multiline
                rows={6}
                sx={{ width: '100%', mt: '15px' }}
                inputProps={{
                  maxLength: 1000,
                }}
              />
            </Box>
          </Stack>
        </FormProvider>
      )}
    </Modal>
  );
};

const InformationField = ({ children }) => (
  <Typography variant="informationText" sx={{ mt: '8px' }}>
    {children}
  </Typography>
);

export default WorkRequestTransferModal;
