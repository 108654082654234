import React from 'react';

import { POSITION_TITLE } from 'constants/globalConstants';

import PositionTitle from '../components/PositionTitle';
import TaskWrWoAttachments from '../components/TaskWrWoAttachments';
import { WorkOrdersSectionTypes } from './@types/workOrdersTypes';

const WorkOrdersSection = ({ documents, positionTitle, section }: WorkOrdersSectionTypes) => (
  <>
    {positionTitle === POSITION_TITLE.workOrders && <PositionTitle />}

    <TaskWrWoAttachments documents={documents} section={section} />
  </>
);

export default WorkOrdersSection;
