import React from 'react';

import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro';

import CustomDetailPanelToggle from 'modules/Field/WorkRequests/WorkRequest/components/CustomDetailPanelToggle';

export const columnTaskTableResponsive = () => [
  {
    field: 'whiteSpace',
    headerName: '',
    width: 92,
    sortable: false,
  },
  {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    headerClassName: 'toggle-column-header',
    renderCell: (params) => {
      if (!params.row.hasDrawingRegisterDocuments) return <></>;
      return <CustomDetailPanelToggle id={params?.id} value={params?.value} />;
    },
  },
  {
    field: 'taskName',
    headerName: 'Task Name',
    headerClassName: 'task-name',
    sortable: false,
    flex: 0.1,
  },
];
