import React from 'react';

import { Box, SxProps } from '@mui/material';

type WorkOrdersMessageProps = {
  message: string;
  icon: React.ReactNode;
  sx?: SxProps;
};

const WorkOrdersMessage = ({ message, icon, ...sx }: WorkOrdersMessageProps) => (
  <Box
    sx={{
      textAlign: 'center',
      width: '-webkit-fill-available',
      top: '246px',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      position: 'absolute',
    }}
  >
    <Box sx={{ '& svg': { height: '40px', width: '40px', path: { fill: '#BDBDBD' } } }}>{icon}</Box>
    <Box
      sx={{
        fontSize: '20px',
        lineHeight: '32px',
        fontWeight: 600,
        color: 'rgba(0,0,0,0.5)',
        width: 'auto',
        margin: 'auto',
        ...sx,
      }}
    >
      {message}
    </Box>
  </Box>
);

export default WorkOrdersMessage;
