import React from 'react';

import Stack from '@mui/material/Stack';

import { ForgeViewer } from 'components/Autodesk';
import ErrorDocument from 'components/Autodesk/components/ErrorDocument';
import FileNotSupported from 'components/Autodesk/components/FileNotSupported';
import IsDocumentsEmpty from 'components/Autodesk/components/IsDocumentsEmpty';
import LoadingDocument from 'components/Autodesk/components/LoadingDocument';
import { Custom } from 'components/Icons';

import NavigationHeader from '../components/NavigationHeader';
import NoRowsOverlay from '../components/NoRowsOverlay';
import { useTaskViewerContext } from '../TaskViewerContext';
import useGetGraphicType from './useGetGraphicType';

const GraphicSection = () => {
  const { activeDocument } = useTaskViewerContext();

  const {
    dynamicProps,
    loading,
    error,
    isSupported,
    isDocumentsEmpty,
    loadingDrawingDocuments,
    loadingCommonWRWOFiles,
  } = useGetGraphicType();

  if (error) return <ErrorDocument />;

  if (!isSupported && !isDocumentsEmpty) return <FileNotSupported />;

  if ((loading || loadingDrawingDocuments || loadingCommonWRWOFiles) && !activeDocument) return <LoadingDocument />;

  if (isDocumentsEmpty) return <IsDocumentsEmpty />;

  return (
    <Stack sx={{ height: '100%' }}>
      <NavigationHeader />
      {activeDocument ? (
        <>
          {Boolean(dynamicProps && activeDocument) && (
            <ForgeViewer key={activeDocument?.documentId} {...dynamicProps} />
          )}
        </>
      ) : (
        <NoRowsOverlay
          message="No documents are mapped to this task."
          icon={<Custom.Plans />}
          sx={{ paddingTop: '200px', height: 'unset' }}
        />
      )}
    </Stack>
  );
};

export default GraphicSection;
