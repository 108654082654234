export const getObjectKeyByValue = (obj, value) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, keyValue] of Object.entries(obj)) {
    if (value === keyValue) {
      return key;
    }
  }
  return '';
};

export const getCleanObj = (obj, clean = []) => {
  const cleanItem = obj;
  clean.forEach((e) => delete cleanItem[e]);
  return cleanItem;
};
export const objIsEmpty = (obj = {}) => Object.keys(obj).length === 0;

export const arePropertiesInObject = (obj1, obj2) =>
  Object.keys(obj2).every((key) => Object.prototype.hasOwnProperty.call(obj1, key) && obj1[key].includes(obj2[key]));

export const deepObjectsAreEqual = (obj1, obj2) => {
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    return obj1 === obj2;
  }

  const keys1 = Object.keys(obj1 || {});
  const keys2 = Object.keys(obj2 || {});

  if (keys1.length !== keys2.length) {
    return false;
  }

  return keys1.every((key) => deepObjectsAreEqual(obj1[key], obj2[key]));
};
