import React from 'react';

import { Custom } from 'components/Icons';

import useDocumentTypes from '../../hooks/useDocumentTypes';

export interface DocumentIconProps {
  typeId?: string | null;
  extension?: string | null;
}

const DocumentIcon = ({ typeId, extension }: DocumentIconProps) => {
  const { documentTypes } = useDocumentTypes();

  const documentTypeName = documentTypes?.find(({ documentTypeId }) => documentTypeId === typeId)?.documentTypeName;

  if (extension === '.maj') return <Custom.MajIcon />;
  if (extension === 'url') return <Custom.Link />;
  if (documentTypeName === 'Drawings') return <Custom.Plans />;
  if (documentTypeName === 'Models') return <Custom.Model />;
  if (documentTypeName === 'CNC') return <Custom.CNC />;

  return <Custom.Plans />;
};

export default DocumentIcon;
