import React from 'react';

import { useMutation, gql } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { Modal, CancelButton, DeleteButton } from 'components';
import { deleteProject as DELETE_PROJECT } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

import { useProjectContext } from './ProjectsContext';

const ProjectDeleteModal = ({ projectId, openStatus, onClose }) => {
  const { lazyLoad } = useProjectContext();
  const { handleResponse } = useGraphqlResponseHandler();

  const [deleteProject, { loading }] = useMutation(gql(DELETE_PROJECT), {
    refetchQueries: ['Projects'],
  });

  const submit = async () => {
    await handleResponse(deleteProject, {
      variables: {
        params: {
          projectId,
        },
      },
    });

    lazyLoad();
    onClose();
  };

  return (
    <Modal
      disableModalOutsideClick
      disableCloseButton={loading}
      open={openStatus}
      onClose={onClose}
      title="Confirm Delete"
      reducePadding
      fullWidth
      maxWidth="xs"
      footer={
        <>
          {loading && <CircularProgress />}
          <CancelButton onClick={onClose} />
          <DeleteButton disabled={loading} onClick={submit} />
        </>
      }
    >
      <Typography variant="body1" mb="12px">
        Are you sure you want to delete this project?
      </Typography>
    </Modal>
  );
};

export default ProjectDeleteModal;
