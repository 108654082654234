import React, { useEffect } from 'react';

import { Stack, Tab, Typography } from '@mui/material';

import ComponentPaneHeader from 'app/Layout/ReactGridLayout/ComponentPaneHeader';
import Header from 'components/Header';
import { InterfaceSelectorWorkRequestRef } from 'components/InterfaceSelectorWorkRequest';
import TabBar from 'components/TabBar';

import { useWorkRequestsContext } from '../WorkRequestsContext';

const STATUS_BY_GROUP = {
  closed: ['Draft', 'Submitted', 'Pending', 'Approved', 'Rejected', 'Canceled', 'In Progress'],
  open: ['Completed'],
};

const WorkRequestsHeader = ({ title, workRequestSelected }) => {
  const { activeTabIndex, setActiveTabIndex, setFilters, setWorkRequest } = useWorkRequestsContext();

  useEffect(() => {
    if (activeTabIndex) {
      setFilters(STATUS_BY_GROUP.open);
    } else {
      setFilters(STATUS_BY_GROUP.closed);
    }
  }, [activeTabIndex, setFilters]);

  const handleActiveTabIndex = (e, newIndex) => {
    setActiveTabIndex(newIndex);
    if (workRequestSelected) setWorkRequest({});
  };

  return (
    <ComponentPaneHeader>
      <Header
        id="workrequests-header"
        sx={{ margin: '4px 0 4px 0' }}
        title={<TitleComponent value={activeTabIndex} title={title} onChange={handleActiveTabIndex} />}
        option={
          <Stack direction="row" justifyContent="flex-end" alignItems="center" gap="24px">
            <InterfaceSelectorWorkRequestRef sx={{ position: 'static', height: '32px' }} />
          </Stack>
        }
      />
    </ComponentPaneHeader>
  );
};

export default WorkRequestsHeader;

const TitleComponent = ({ ...rest }) => (
  <Stack direction="row">
    <Typography variant="h5" component="h1" mt={0.5}>
      {rest.title}
    </Typography>
    <Tabs {...rest} />
  </Stack>
);

const Tabs = ({ value, onChange }) => (
  <TabBar value={value} onChange={onChange} sx={{ ml: '60px' }} data-testid="wr-header-tab">
    <Tab label="Open" />
    <Tab label="Closed" />
  </TabBar>
);
