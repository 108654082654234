import React from 'react';

import Auth from '@aws-amplify/auth';
import MenuItem from '@mui/material/MenuItem';
import { useSnackbar } from 'notistack';

type Props = {
  emails: Array<string>;
  onPasswordReset?: () => void;
};

const ResetPasswordOption = ({ emails, onPasswordReset }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const sendResetPasswordEmail = async () => {
    if (onPasswordReset) onPasswordReset();
    if (emails.length === 0) return;

    emails.map(async (email) => {
      try {
        const response = await Auth.forgotPassword(email);
        if (response) {
          enqueueSnackbar('Reset password email successfully sent', {
            variant: 'success',
            preventDuplicate: true,
          });
        }
      } catch (error) {
        if (error instanceof Error)
          enqueueSnackbar(`(${email}) - ${error.message}`, {
            variant: 'error',
          });
      }
    });
  };

  return (
    <MenuItem
      data-testid="reset-password-option"
      data-cy="reset-password-option"
      onClick={sendResetPasswordEmail}
    >
      Reset Password
    </MenuItem>
  );
};

export default ResetPasswordOption;
