import React, { FC, useEffect, useMemo, useRef, useState } from 'react';

import { Menu } from '@mui/material';

import ProjectIdentifierModal from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsModalIdentifier/ProjectIdentifierModal';
import { useItemsIntegrationModalContext } from 'modules/Materials/components/ItemsIntegrationModal/context/ItemsIntegrationModalContext';

import { useBOMbyIdContext } from '../../BOMbyIdContext';
import useDropdownMenuOptions from '../../hooks/useDropdownMenuOptions';
import { ModalPadItemsDelete } from '../../ModalPadItemsDelete';
import ActionsButton from './ActionsButton';
import MenuOption from './MenuOption';
import TooltipWrapper from './TooltipWrapper';

const DropdownButton: FC = () => {
  const [anchorElLvl1, setAnchorElLvl1] = useState<HTMLElement | null>(null);
  const { setOpen, open: openItemToWRModal } = useItemsIntegrationModalContext();
  const [openProjectIdentifierModal, setOpenProjectIdentifierModal] = useState(false);
  const open = useMemo(
    () => (openItemToWRModal || openProjectIdentifierModal ? false : Boolean(anchorElLvl1)),
    [openItemToWRModal, openProjectIdentifierModal, anchorElLvl1],
  );
  const modalCloseRef = useRef<{ openModalConfirmDelete: () => void }>(null);
  const openModalConfirmDelete = () => {
    handleClose();
    modalCloseRef.current?.openModalConfirmDelete();
  };

  const { menuOptions } = useDropdownMenuOptions({ setOpenProjectIdentifierModal, openModalConfirmDelete });
  const handleClose = () => setAnchorElLvl1(null);
  const handleOpenBOMToWRModal = () => setOpen(true);

  const { selectedPadItems } = useBOMbyIdContext();

  useEffect(() => {
    if (openItemToWRModal) handleClose();
  }, [openItemToWRModal]);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => setAnchorElLvl1(e.currentTarget);

  return (
    <>
      <ActionsButton handleClick={handleClick} />
      <Menu id="Actions for items" anchorEl={anchorElLvl1} open={open} onClose={handleClose}>
        {menuOptions.map(({ tooltip, ...rest }) => (
          <TooltipWrapper key={rest.text} {...tooltip}>
            <MenuOption {...rest} />
          </TooltipWrapper>
        ))}
      </Menu>
      <ProjectIdentifierModal
        open={openProjectIdentifierModal}
        onClose={() => setOpenProjectIdentifierModal(false)}
        openWorkRequestModal={() => handleOpenBOMToWRModal()}
      />
      {modalCloseRef && <ModalPadItemsDelete selectedPadItems={selectedPadItems} ref={modalCloseRef} />}
    </>
  );
};

export default DropdownButton;
