import { useEffect } from 'react';

import { formatWorkRequestsToColumns } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestFilter/filterHelpers';

export function useEffectThatUpdatesFilterData(
  workRequests,
  setColumns,
  selectedItem,
  workRequestTable,
  columnsBackup,
) {
  useEffect(() => {
    if (!workRequests?.length) {
      setColumns([]);
    }
    if (workRequests?.length > 0 && workRequestTable.length > 0) {
      setColumns(
        formatWorkRequestsToColumns(
          workRequests,
          selectedItem?.type,
          workRequestTable,
          columnsBackup,
        ),
      );
    }
  }, [workRequests, workRequestTable, setColumns, selectedItem, columnsBackup]);
}
