import React from 'react';

import { Typography } from '@mui/material';

const Timer = ({ time }) => {
  const seconds = `0${Math.floor((time / 1000) % 60)}`.slice(-2);
  const minutes = `0${Math.floor((time / 60000) % 60)}`.slice(-2);
  let hours = Math.floor(time / 3600000);

  if (hours < 100) hours = `0${hours}`.slice(-2);
  else hours = `${hours}`.slice(0, 3);

  return (
    <Typography>
      <span>{hours}:</span>
      <span>{minutes}:</span>
      <span>{seconds}</span>
    </Typography>
  );
};

export default Timer;
