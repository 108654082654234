import { WorkCell } from 'graphql/types';

import WorkCellsColumn from './WorkCellsColumn';
import { WorkCellTasksProvider } from './WorkCellTasksContext';

type WorkCellsColumnWithContextProps = {
  workCell: WorkCell;
  disabled?: boolean;
  isDisabledDragAndDrop?: boolean;
  setHasTasks: (hasTasks: boolean) => void;
  onUpdateTasksLoading: () => void;
};

const WorkCellsColumnWithContext = ({
  workCell,
  onUpdateTasksLoading,
  disabled,
  isDisabledDragAndDrop,
  setHasTasks,
}: WorkCellsColumnWithContextProps) => {
  const { workCellId, workCellName } = workCell;
  return (
    <WorkCellTasksProvider workCellId={workCellId} onFinishedLoading={onUpdateTasksLoading}>
      <WorkCellsColumn
        workCellName={workCellName}
        workCellId={workCellId}
        disabled={disabled}
        isDisabledDragAndDrop={isDisabledDragAndDrop}
        setHasTasks={setHasTasks}
      />
    </WorkCellTasksProvider>
  );
};

export default WorkCellsColumnWithContext;
