import { gql, useSubscription } from '@apollo/client';

// eslint-disable-next-line import/named
import { updatedProject as UPDATED_PROJECT } from 'graphql/subscriptions';

const useUpdateProjectSubscription = () => {
  const { data: project } = useSubscription(gql(UPDATED_PROJECT));

  return { project };
};

export default useUpdateProjectSubscription;
