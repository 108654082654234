/* eslint-disable import/no-cycle */
import React, { FC, useCallback, useMemo, useState } from 'react';

import { Box, Typography, useTheme } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';

import StackRow from 'components/StackRow';
import { BillOfMaterialLineItemByLBS } from 'graphql/types';
import useItemByLBS from 'hooks-api/useItemByLBS';
import { PickIcon } from 'modules/Materials/commons/FilterPopoverContent/Breakdown/HelperElements';

import { PBS_FIELD } from '../../constants';
import { useItemsIntegrationTableContext } from '../../context/ItemsIntegrationTableContext';
import ArrowDetailIcon from '../ArrowDetailIcon/ArrowDetailIcon';
import QuantityField from '../QuantityField/QuantityField';
import RowPlaceholder from '../RowPlaceholder/RowPlaceholder';
import RowsNested from '../RowsNested/RowsNested';
import RowWrapper from '../RowWrapper/RowWrapper';
import { QtyRemainingCell } from './constants';
import useLocationRowEffectsHandler from './hooks/useLocationRowEffectsHandler';
import LBSRowInfo from './LBSRowInfo';

const getIconStyle = (showRows: boolean, palette: any) => ({
  Floor: {
    '& svg, path': {
      strokeOpacity: `${showRows ? '1' : '0.5'} !important`,
    },
  },
  'Location System': {
    '& svg, path': {
      fill: `${showRows ? palette.surface.lightSurface.primary : palette.surface.lightSurface.secondary} !important`,
      fillOpacity: 1,
    },
  },
  Zone: {
    '& svg, rect': {
      stroke: `${showRows ? palette.surface.lightSurface.primary : palette.surface.lightSurface.secondary} !important`,
      strokeOpacity: '1 !important',
    },
  },
});

interface ILBSRow {
  nestLevel?: number;
  item: BillOfMaterialLineItemByLBS;
  lineItemId: string;
}
const LBSRow: FC<ILBSRow> = ({ nestLevel = 0, item, lineItemId }: ILBSRow) => {
  const { palette }: any = useTheme();
  const [showRows, setShowRows] = useState<boolean>(true);
  const handleShowRows = useCallback(() => setShowRows(!showRows), [showRows]);
  const { control } = useFormContext();
  const projectBreakdownStructure = useWatch({ control, name: PBS_FIELD });
  const { baseERN } = useItemsIntegrationTableContext();
  const { LBSData, loadingLineItem } = useItemByLBS({
    filterType: projectBreakdownStructure === 'Work Phase' ? 'Both' : projectBreakdownStructure,
    BOMData: { id: lineItemId },
    parentLocationId: item.locationId || '',
    baseERN,
  });

  const { loadingLocation, locationData } = useLocationRowEffectsHandler({
    nestLevel,
    lineItemId,
    locationId: item?.locationId || '',
    amount: item.amount,
    LBSData,
    loadingLineItem,
  } as any);

  const rows = useMemo(() => (LBSData?.children?.length ? LBSData.children : []), [LBSData]);
  const qtyFieldName = useMemo(
    () => (locationData ? `${locationData.locationId}__${lineItemId}` : ''),
    [locationData, lineItemId],
  );
  const ICON_STYLE: any = useMemo(
    () => getIconStyle(Boolean(showRows && (LBSData?.children?.length || LBSData?.workPhases?.length)), palette),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showRows, LBSData],
  );
  const showExpandRowsArrow = useMemo(
    () => Boolean(LBSData?.children?.length || LBSData?.workPhases?.length),
    [LBSData],
  );
  if (!locationData || loadingLineItem || loadingLocation || !LBSData) return <RowPlaceholder nestLevel={nestLevel} />;

  return (
    <>
      <RowWrapper
        qtyFieldName={qtyFieldName}
        qtyAggregate={locationData.qtyAggregate}
        qtyRemaining={locationData.qtyRemaining}
      >
        <StackRow sx={{ flex: 1, gap: '8px', width: `${418 - nestLevel * 24}px` }}>
          <Box sx={{ width: `${nestLevel * 24 + 8}px` }} />
          <StackRow>
            <ArrowDetailIcon show={showExpandRowsArrow} onToggle={() => handleShowRows()} initialValue />
            <PickIcon
              iconSx={ICON_STYLE[locationData?.type]}
              margin="0"
              type={locationData.type}
              chipWidth={undefined}
            />
          </StackRow>
          <LBSRowInfo rowInfo={{ identifier: locationData.locationIdentifier, locationName: locationData.name }} />
        </StackRow>
        <StackRow marginRight="16px" gap="16px">
          <Box width="96px">
            <QtyRemainingCell
              name={locationData.locationIdentifier}
              qtyAggregate={locationData.qtyAggregate}
              qtyRemaining={locationData.qtyRemaining}
            >
              <Typography marginRight="20px" textAlign="right" variant="body2">
                {locationData.qtyRemaining}{' '}
                {Boolean(locationData.qtyAggregate && (rows?.length || LBSData.workPhases?.length)) &&
                  ` | ${locationData.qtyAggregate}`}
              </Typography>
            </QtyRemainingCell>
          </Box>
          <QuantityField
            name={qtyFieldName}
            rowIdentifier={locationData.locationIdentifier}
            qtyRemaining={locationData.qtyRemaining}
            qtyAggregate={locationData.qtyAggregate}
          />
        </StackRow>
      </RowWrapper>
      <RowsNested
        locationData={locationData}
        workPhases={LBSData.workPhases}
        show={showRows}
        rows={rows as any[]}
        lineItemId={lineItemId}
        nestLevel={nestLevel}
      />
    </>
  );
};

export default LBSRow;
