import React, { useCallback, useEffect } from 'react';

import { Stack } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { CancelButton, CreateButton } from 'components';
import useUploadImage from 'hooks/useUploadImage';
import { DEPARTMENT_TYPE } from 'types/DepartmentTypes';

import useUpdateProjectMutation from '../useUpdateProjectMutation';
import FormFields from './FormFields/FormFields';

const getPersistedDepartment = (project, TYPE_ID) => {
  const department = project?.departments?.find(
    (department) => department?.departmentTypeId === TYPE_ID,
  );

  return department?.departmentId;
};

const formDefaultValues = (project) => ({
  projectName: project?.projectName || '',
  projectIdentifier: project?.projectIdentifier || '',
  projectTypeId: project?.projectType?.projectTypeId || null,
  assignProcurementDepartment: getPersistedDepartment(project, DEPARTMENT_TYPE.PROCUREMENT) || '',
  assignDesignDepartment: getPersistedDepartment(project, DEPARTMENT_TYPE.DESIGN) || '',
  assignShopDepartment: getPersistedDepartment(project, DEPARTMENT_TYPE.SHOP) || '',
  units: project?.units?.toUpperCase() || '',
  addressLine1: project?.address?.addressLine1 || '',
  addressLine2: project?.address?.addressLine2 || '',
  city: project?.address?.city || '',
  state: project?.address?.state || '',
  zip: project?.address?.zip || '',
  country: project?.address?.country || '',
});

const InfoForm = ({ project }) => {
  const { getImage } = useUploadImage();
  const { selectedItem, setSelectedItem } = useFacilitiesProjects();
  const { updateProject } = useUpdateProjectMutation({ selectedProjectId: project?.projectId });

  const methods = useForm({
    mode: 'all',
    defaultValues: { ...formDefaultValues(project) },
  });

  const {
    handleSubmit,
    reset,
    setValue,
    formState: { isDirty, isValid },
  } = methods;

  const submit = (form) => {
    if (selectedItem?.identifier && !form?.projectIdentifier) return;
    setSelectedItem({ ...selectedItem, identifier: form?.projectIdentifier });
    updateProject(form);
  };
  const setProjectImage = useCallback(async () => {
    const getPresignedURL = await getImage(project?.projectImageId);
    setValue('image', getPresignedURL);
  }, [project, getImage, setValue]);

  // if you remove this useEffect, the selected project will not update its existing info to form fields.
  useEffect(() => {
    if (project?.projectImageId) setProjectImage();
    reset(formDefaultValues(project));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  const resetToProjectDefault = () => {
    reset(formDefaultValues(project));
  };

  const submitDisabled = !isDirty || !isValid;

  return (
    <Stack mt={0} direction="row" gap="56px">
      <FormProvider {...methods}>
        <FormFields
          idRequired={!!selectedItem?.identifier}
          actionRenderer={() => (
            <ActionStack>
              <CancelButton onClick={resetToProjectDefault} />
              <CreateButton
                disabled={submitDisabled}
                buttonText="save changes"
                onClick={async () => {
                  await handleSubmit(submit)();
                }}
              />
            </ActionStack>
          )}
        />
      </FormProvider>
    </Stack>
  );
};

export default InfoForm;

const ActionStack = ({ children }) => (
  <Stack mt={2} direction="row" alignItems="center" justifyContent="flex-end">
    {children}
  </Stack>
);
