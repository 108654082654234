import React, { useState, useEffect } from 'react';

import { gql } from '@apollo/client';

import { projectUsers as PROJECT_USERS } from 'graphql/queries';
import useSearchableQuery from 'hooks/useSearchableQuery';

import AddAdminModal from './AddAdminModal/AddAdminModal';
import { AdminProvider } from './AdminContext';
import AdminHeaderBar from './AdminHeaderBar';
import AdminTable from './AdminTable/AdminTable';

const generateHeaderOption = (title, sortValue) => ({
  title,
  sortValue,
});

const headerOptions = [
  generateHeaderOption('ID', 'ID'),
  generateHeaderOption('User', 'User'),
  generateHeaderOption('Email', 'Email'),
  generateHeaderOption('Role'),
  generateHeaderOption('Office Phone'),
  generateHeaderOption('Mobile Phone'),
  generateHeaderOption('Status', 'Status'),
  generateHeaderOption('EMPTY'),
];

const AdminTab = ({ project }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [showDeletedUsers, setShowDeletedUsers] = useState(false);
  const projectId = project?.projectId;

  const [{ searchHandler, sortHandler, paginationHandler }, { data, loading, refetch }] =
    useSearchableQuery(gql(PROJECT_USERS), {
      extraVars: {
        projectId,
        includeDeleted: showDeletedUsers ? 'YES' : null,
      },
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => refetch(), [showDeletedUsers]);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const projectUsers = data?.projectUsers || [];
  const admins = projectUsers.filter(({ isAdmin }) => isAdmin);

  return (
    <AdminProvider>
      <AdminHeaderBar
        handleSearch={searchHandler}
        openInviteModal={openModal}
        handleShowDeletedUsers={setShowDeletedUsers}
      />
      <AdminTable
        loading={loading}
        headerOptions={headerOptions}
        admins={admins}
        handleSortOrder={sortHandler}
        paginationHandler={paginationHandler}
      />

      <AddAdminModal projectId={projectId} openStatus={modalOpen} onClose={closeModal} />
    </AdminProvider>
  );
};

export default AdminTab;
