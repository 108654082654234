import React, { useState } from 'react';

import { Stack, Table } from '@mui/material';

import AttachmentsTable from 'components/AttachmentsTable/AttachmentsTable';
import TaskAttachmentList from 'modules/Shop/WorkOrders/WorkOrdersList/Components/TaskAttachmentList';
import WorkOrderAttachmentList from 'modules/Shop/WorkOrders/WorkOrdersList/Components/WorkOrderAttachmentList';

import useFetchWorkRequestDocuments from '../../../../../hooks/useWorkRequestDocuments';
import WorkRequestsAttachType from '../../WorkRequestsList/WorkRequestListFlyoutMenu/WorkRequestAttachments/WorkRequestsAttachType';
import WorkRequestItemAttachmentsCount from './WorkRequestItemAttachmentsCount';
import WorkRequestItemAttachmentsPopOver from './WorkRequestItemAttachmentsPopOver';

const WorkRequestAttachments = ({ workRequestId, numberOfAttachments = 0, row }) => {
  const itemId = row?.workOrderId ? row.workRequest.workRequestId : workRequestId;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { workRequestDocuments, loading, handleFetchDocuments, paginationWaypoint } = useFetchWorkRequestDocuments(
    row?.workOrderId ? row.workRequestId : {},
    50,
    open,
  );

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleClick = async (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
    await handleFetchDocuments({ workRequestId: itemId });
  };

  if (!numberOfAttachments) {
    return <></>;
  }

  return (
    <Stack>
      <WorkRequestItemAttachmentsCount
        count={numberOfAttachments}
        handleClick={handleClick}
        disabled={Number(numberOfAttachments) === 0}
      />

      <WorkRequestItemAttachmentsPopOver
        open={open}
        id={itemId}
        anchorEl={anchorEl}
        handleClose={handleClose}
        loadingAttachments={loading && !workRequestDocuments?.length}
      >
        <AttachmentsContent
          workOrder={row?.workOrderId ? row : null}
          workRequestAttachments={workRequestDocuments}
          paginationWaypoint={paginationWaypoint}
        />
      </WorkRequestItemAttachmentsPopOver>
    </Stack>
  );
};

export default WorkRequestAttachments;

const AttachmentsContent = ({ workOrder, workRequestAttachments, paginationWaypoint }) => {
  if (!workOrder)
    return <AttachmentsTable attachments={workRequestAttachments} paginationWaypoint={paginationWaypoint} />;

  return (
    <Table>
      <TaskAttachmentList canDelete={false} darkMode={false} workOrderRow={workOrder} />
      <WorkOrderAttachmentList
        showWorkOrdersHeader={false}
        darkMode={false}
        canDelete={false}
        workOrderRow={workOrder}
      />
      {Boolean(workRequestAttachments?.length) && (
        <>
          <WorkRequestsAttachType />
          <AttachmentsTable attachments={workRequestAttachments} paginationWaypoint={paginationWaypoint} />
        </>
      )}
    </Table>
  );
};
