import React, { FC } from 'react';

import { Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';

import OverFlowTooltip from 'components/Tooltip/OverFlowTooltip';

interface ILBSRowCell {
  label?: string;
  width?: string;
  flex?: number;
  variant?: Variant;
}

const LBSRowCell: FC<ILBSRowCell> = ({ label = '', width, flex, variant = 'body2' }: ILBSRowCell) => (
  <OverFlowTooltip sx={{ width, flex }} title={label}>
    <Typography
      sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', display: 'inline' }}
      variant={variant}
    >
      {label}
    </Typography>
  </OverFlowTooltip>
);

export default LBSRowCell;
