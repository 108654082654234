import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import { Draggable } from 'react-beautiful-dnd';
import ReactDOM from 'react-dom';

import { EIconType } from 'helpers/MenuIcons';
import CollapseChildrenIcon from 'modules/Field/LocationsAndWorkPhases/components/CollapseChildrenIcon';
import { highlightStyles } from 'modules/Field/LocationsAndWorkPhases/utils/styles';

import MiniSpinner from '../../../../../../../components/MiniSpinner';
import { CONTAINER_TYPES, ROW_TYPES } from '../../constants/constants';
import { useDataContext } from '../../contexts/DataContext';
import DroppableContainer from '../DroppableContainer';
import RowTitle from '../RowTitle';
import StyledIcon from '../StyledIcon';
import StyledRow from '../StyledRow';
import { useWorkCell } from '../WorkCellRow/contexts/WorkCellContext';
import { MachineProvider } from './contexts/MachineContext';
import MachineRow from './MachineRow';

const dndPortal = document.getElementById('dnd-portal');

// eslint-disable-next-line max-lines-per-function
const MachinesContainer = () => {
  const {
    workCell,
    disabled,
    handleFetchWorkCellMachines,
    workCellMachines,
    loadingWorkCellMachines,
    loadingAddMachine,
    loadingMachines,
    openMachinesContainer,
  } = useWorkCell();
  const { activeRow, setActiveRow, setCloseFlyoutMenu } = useDataContext();
  const [isOpen, setIsOpen] = useState(false);
  const [isTemporaryEnabled, setIsTemporaryEnabled] = useState(false);

  const mappedContainer = {
    id: `${ROW_TYPES.MACHINE}_${workCell.workCellId}`,
  };

  const handleSelectRow = () => {
    setActiveRow(mappedContainer);
    setCloseFlyoutMenu(true);
  };

  const fillOpacity = (() => {
    if (!isTemporaryEnabled && (disabled || !workCell.hasMachines)) return 0.25;
    return isOpen ? 0.87 : 0.54;
  })();

  useEffect(() => {
    if (openMachinesContainer) {
      setIsTemporaryEnabled(true);
      setIsOpen(true);
    } else setIsTemporaryEnabled(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openMachinesContainer]);

  useEffect(() => {
    if (isOpen) handleFetchWorkCellMachines();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <>
      <StyledRow
        isActive={mappedContainer.id === activeRow.id}
        onClick={() => {
          if (!disabled) handleSelectRow();
        }}
        sx={{ pl: 7 }}
        testId="machines-container-row"
      >
        <CollapseChildrenIcon
          onClick={() => setIsOpen(!isOpen)}
          open={isOpen}
          showIcon
          filled
          sx={{ path: { color: 'black', fillOpacity } }}
          disabled={!isTemporaryEnabled && (disabled || !workCell.hasMachines)}
        />
        <StyledIcon iconName={EIconType.MACHINE} highlight={isOpen} />
        <RowTitle>Machines</RowTitle>
      </StyledRow>

      {isOpen && (
        <>
          {workCellMachines.length ? (
            <DroppableContainer
              droppableId={`${CONTAINER_TYPES.MACHINES}_${workCell.workCellId}`}
              type={ROW_TYPES.MACHINE}
              isDropDisabled
            >
              {workCellMachines?.map((machine, index) => (
                <MachineProvider machine={machine} key={machine.workCellMachineId}>
                  <Draggable
                    draggableId={`${ROW_TYPES.MACHINE}_${machine.machineId}_${machine.workCellMachineId}`}
                    index={index}
                  >
                    {(provided, snapshot) => {
                      const getStyles = (() => {
                        if (snapshot.isDragging) return highlightStyles;
                        return {};
                      })();

                      const Content = (
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          sx={getStyles}
                        >
                          <MachineRow />
                        </Box>
                      );
                      return snapshot.isDragging ? ReactDOM.createPortal(Content, dndPortal) : Content;
                    }}
                  </Draggable>
                </MachineProvider>
              ))}
            </DroppableContainer>
          ) : (
            <>
              {loadingWorkCellMachines && !(loadingAddMachine || loadingMachines) && <MiniSpinner showBorderBottom />}
            </>
          )}
          {(loadingAddMachine || loadingMachines) && <MiniSpinner showBorderBottom />}
        </>
      )}
    </>
  );
};

export default MachinesContainer;
