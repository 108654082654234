import { useCallback } from 'react';

import { gql, useMutation, useLazyQuery } from '@apollo/client';

import { useUser } from 'app/UserContext';
import { getDocumentPresignedURL as GET_DOCUMENT_PRESIGNED_URL } from 'graphql/mutations';
import { documentById as DOCUMENT_BY_ID } from 'graphql/queries';

const useDocumentSignedURLAPI = () => {
  const { user } = useUser();

  const [fetchDocument, { loading: loadingDocument, error: errorDocument }] = useLazyQuery(
    gql(DOCUMENT_BY_ID),
    {
      fetchPolicy: 'network-only',
    },
  );

  const [
    getDocumentSignedURL,
    { loading: loadingDocumentSignedURL, error: errorDocumentSignedURL, data: signedURLResponse },
  ] = useMutation(gql(GET_DOCUMENT_PRESIGNED_URL));

  const getSignedURL = useCallback(
    async (documentId) => {
      if (!documentId) return;

      const { data: documentResponse } = await fetchDocument({
        variables: { params: { documentId } },
      });
      if (!documentResponse?.documentById) return;

      const { documentById: documentData } = documentResponse;
      await getDocumentSignedURL({
        variables: {
          body: {
            expirationHours: 24,
            objectKey: `${documentData.storagePath}`,
            requestedBy: user.userId,
            verb: 'GET',
          },
        },
      });
    },
    [fetchDocument, getDocumentSignedURL, user],
  );

  return {
    signedURL: signedURLResponse?.getDocumentPresignedURL?.preSignedURL,
    getSignedURL,
    loading: loadingDocument || loadingDocumentSignedURL,
    error: errorDocument || errorDocumentSignedURL,
  };
};

export default useDocumentSignedURLAPI;
