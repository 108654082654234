import React from 'react';

import { Stack } from '@mui/material';

const DrawerContainerItems = ({ sx, children }) => (
  <Stack
    sx={{ width: 'auto', overflowY: 'auto', ...sx }}
    style={{ backgroundColor: 'transparent' }}
  >
    {children}
  </Stack>
);

export default DrawerContainerItems;
