import React, { useCallback } from 'react';

import { List, ListItemText, Typography } from '@mui/material';
import { Waypoint } from 'react-waypoint';

import CenteredLoadSpinner from 'components/CenteredLoadSpinner';

import { MessageListItem } from './MessageListItem';

const MessagesList = ({
  messages,
  selectedMessage,
  height,
  isTrash = false,
  loading = false,
  onSelectMessage = () => {},
  onArchiveMessage = () => {},
  onDeleteMessage = () => {},
  onFetchMore = () => {},
}) => {
  const handleFetchMore = useCallback(() => onFetchMore(messages?.length), [messages, onFetchMore]);

  if (loading) return <CenteredLoadSpinner />;

  if (!messages?.length) {
    return (
      <Typography variant="h5" align="center" sx={{ mt: 3 }}>
        No Messages
      </Typography>
    );
  }

  return (
    <MessagesListWrapper height={height}>
      {messages.map((message) => (
        <MessageListItem
          key={`${message.messageId}`}
          isTrash={isTrash}
          isActive={selectedMessage?.messageId === message.messageId}
          message={message}
          onSelect={onSelectMessage}
          onArchive={onArchiveMessage}
          onDelete={onDeleteMessage}
        />
      ))}
      <ListItemText>
        <Waypoint key={messages?.length} bottomOffset="-20%" onEnter={handleFetchMore} />
      </ListItemText>
    </MessagesListWrapper>
  );
};

export default MessagesList;

const MessagesListWrapper = ({ children, height }) => (
  <List component="nav" aria-label="main mailbox folders" sx={{ height, overflowY: 'scroll' }}>
    {children}
  </List>
);
