import React, { useRef, useState } from 'react';

import { Box, CircularProgress } from '@mui/material';
import { Waypoint } from 'react-waypoint';

import DroppableContainer from 'components/Kanban/commons/DroppableContainer';
import KanbanColumn from 'components/Kanban/KanbanColumn';

import WorkOrderKanbanItem from '../../WorkOrdersList/WorkOrdersKanbanItem';
import { useWorkOrdersKanbanColumnsContext } from './WorkOrdersKanbanColumnContext';

type WorkOrdersKanbanColumnProps = {
  name: string;
  headerColor: string;
  disabled: string | null;
  disableDrag: boolean;
};
const WorkOrdersKanbanColumn = ({ name, headerColor, disabled, disableDrag = false }: WorkOrdersKanbanColumnProps) => {
  const { workOrders, loadingWorkOrderCount, workOrdersCount, fetchMore } = useWorkOrdersKanbanColumnsContext();

  const [isDraggingOver, setIsDraggingOver] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);
  const height = ref?.current?.parentElement?.scrollHeight;

  return (
    <KanbanColumn key={name} sx={{ m: '0 6px' }} ref={ref} data-testid="workorder_kanban_column">
      <KanbanColumn.Header
        title={name}
        counter={workOrdersCount}
        detailColor={headerColor}
        sx={{ position: 'fixed', top: 62 }}
      />
      <KanbanColumn.Content highlightColumn={isDraggingOver} highlightColor={headerColor} sx={{ height }}>
        {loadingWorkOrderCount && <LoadingComponent />}
        <DroppableContainer droppableId={name} disabled={disabled} setHighlightColumn={setIsDraggingOver}>
          {workOrders?.map((item, index) => (
            <Box key={item.workOrderId}>
              <WorkOrderKanbanItem order={item} index={index} disableDrag={disableDrag} />

              {index === workOrders.length - 1 && (
                <Waypoint bottomOffset="-20%" onEnter={() => fetchMore(workOrders.length)} />
              )}
            </Box>
          ))}
        </DroppableContainer>
      </KanbanColumn.Content>
    </KanbanColumn>
  );
};

export default WorkOrdersKanbanColumn;

const LoadingComponent = () => (
  <Box sx={{ m: '20px auto', height: '40px' }}>
    <CircularProgress />
  </Box>
);
