import { gql, useApolloClient } from '@apollo/client';

import { workOrders } from 'graphql/queries';
import { WorkOrder } from 'graphql/types';

const useClientRefetchWorkOrdersByChildWorkOrder = () => {
  const client = useApolloClient();

  const refetchWorkOrdersByChildWorkOrder = async (workOrder: WorkOrder) =>
    client.refetchQueries({
      include: [gql(workOrders)],
      onQueryUpdated(observableQuery) {
        const childWorkOrders = observableQuery.getLastResult()?.data?.workOrders;
        if (!childWorkOrders) return false;

        const isWorkRequestIncluded = childWorkOrders.some(
          (childWorkOrder: WorkOrder) => childWorkOrder.workRequestId === workOrder.workOrderId,
        );
        return isWorkRequestIncluded;
      },
    });

  return { refetchWorkOrdersByChildWorkOrder };
};

export default useClientRefetchWorkOrdersByChildWorkOrder;
