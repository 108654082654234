import React, { useEffect } from 'react';

import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';

import { getNumberOfOccurrences } from 'helpers/arrayFunctions';

import { RowPrefix } from '../../components/RowPrefix';
import RowTitle from '../../components/RowTitle';
import { ROW_TYPES } from '../../constants/constants';
import { useActiveRow } from '../../contexts/ActiveRowContext';
import { useLocationsAndWorkPhases } from '../../contexts/LocationsAndWorkPhaseContext';
import IdentifierTextField from './IdentifierTextField';
import NameTextField from './NameTextField';

const getWorkPhaseSiblings = (array, workPhase) =>
  array
    ?.filter((item) => item !== workPhase && item.scopePackageId === workPhase.scopePackageId)
    ?.map(({ label, prefix }) => ({ label, prefix }));

const getScopePackageSiblings = (array, scopePackage) =>
  array?.filter((item) => item !== scopePackage)?.map(({ label, prefix }) => ({ label, prefix }));

const EditableRowName = React.memo(({ node, inputRef, focusNameInput }) => {
  const {
    workPhases: { handleUpdateRow, scopePackages, workPhases, cancelCreation },
  } = useLocationsAndWorkPhases();
  const { setActiveRows } = useActiveRow();
  const nodeValues = { label: node.label, prefix: node.prefix };
  const {
    control,
    watch,
    reset,
    trigger,
    formState: { isValid },
  } = useForm({
    mode: 'all',
    defaultValues: nodeValues,
  });

  const formValues = watch();

  const scopePackageSiblingValues = getScopePackageSiblings(scopePackages, node);
  const workPhaseSiblingValues = getWorkPhaseSiblings(workPhases, node);

  const { prefix, label } = formValues;

  const submit = () => {
    if (isValid) {
      handleUpdateRow({ ...node, label, prefix });
      setActiveRows([]);
    } else {
      trigger();
    }
  };

  const validateEnterKey = (event) => {
    if (event.key === 'Enter') {
      submit();
    }
  };

  const validateNameExists = (value) => {
    if (node.type === ROW_TYPES.SCOPE_PACKAGE) {
      if (getNumberOfOccurrences('label', value, scopePackageSiblingValues) !== 0)
        return 'Scope Package name already exists';
      return true;
    }
    if (getNumberOfOccurrences('label', value, workPhaseSiblingValues) !== 0)
      return 'Work Phase name already exists';
    return true;
  };

  const validateIdentifierExists = (value) => {
    if (node.type === ROW_TYPES.SCOPE_PACKAGE) {
      if (getNumberOfOccurrences('prefix', value, scopePackageSiblingValues) !== 0)
        return 'Id already exists';
      return true;
    }
    if (getNumberOfOccurrences('prefix', value, workPhaseSiblingValues) !== 0)
      return 'Id already exists';

    return true;
  };

  const validateFieldHasText = (event) => {
    const labelClone = label || '';
    const isNotClickedOnId = event?.relatedTarget?.value !== prefix;
    const isNotClickedOnLabel = event?.relatedTarget?.value !== labelClone;

    if (isNotClickedOnId && isNotClickedOnLabel) {
      if (label?.length) submit();
      else cancelCreation();
    }
  };

  useEffect(() => {
    if (node.isEditing) {
      reset(nodeValues);
      focusNameInput();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node.isEditing]);

  return node.isEditing ? (
    <Stack sx={{ width: 'inherit' }}>
      <Stack direction="row" alignItems="start" gap="16px">
        <Stack sx={{ width: 86 }}>
          <IdentifierTextField
            control={control}
            limitCharacters={node.type === ROW_TYPES.WORK_PHASE ? 8 : 5}
            onKeyDown={validateEnterKey}
            rules={{ validate: validateIdentifierExists }}
            onBlur={validateFieldHasText}
          />
        </Stack>
        <Stack sx={{ flex: 1 }}>
          <NameTextField
            inputRef={inputRef}
            control={control}
            nodeType={node.type}
            onKeyDown={validateEnterKey}
            rules={{ validate: validateNameExists }}
            onBlur={validateFieldHasText}
          />
        </Stack>
      </Stack>
    </Stack>
  ) : (
    <>
      <RowPrefix>{node.prefix || '-'}</RowPrefix>
      <RowTitle sx={{ ml: 0 }}>{node.label}</RowTitle>
    </>
  );
});

export default EditableRowName;
