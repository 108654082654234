import React from 'react';

import { Stack } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import { Modal } from 'components';

import CustomAssemblyTypeAddForm from './CustomAssemblyTypeAddForm';
import ModalFooter from './ModalFooter';

export const CustomAssemblyTypeAddModal = ({ open, onClose = () => {}, createCustomAssemblyType }) => {
  const methods = useForm({ mode: 'onChange' });

  const {
    handleSubmit,
    formState: { isValid, isSubmitting, errors },
    reset,
  } = methods;

  const handleClose = () => {
    onClose();
    reset();
  };

  const submit = async ({ AssemblyTypeName }) => {
    await createCustomAssemblyType(AssemblyTypeName);
    handleClose();
  };

  return (
    <Modal
      disableModalOutsideClick
      open={open}
      title="Create assembly type"
      onClose={handleClose}
      footer={
        <ModalFooter
          onCancel={handleClose}
          disable={!isValid || isSubmitting || Boolean(Object.keys(errors).length)}
          onSubmit={handleSubmit(submit)}
        />
      }
      sx={{ '.MuiDialog-paper': { width: '585px' } }}
    >
      <Stack>
        <FormProvider {...methods}>
          <CustomAssemblyTypeAddForm />
        </FormProvider>
      </Stack>
    </Modal>
  );
};
