import React, { ComponentProps, forwardRef } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useModule } from 'app/Modules/ModuleContext';
import Divider from 'components/Divider';
import getModuleIcon from 'helpers/module';
import useSetupModule from 'hooks/useSetupModule';
import { EModuleType } from 'types/module';

const ModuleSelectDropdown = (_: any, ref: React.ForwardedRef<unknown>) => {
  const { data, loading } = useModule();
  const { currentModule, setSelectedModule } = useSetupModule();

  const handleSelectModule = (moduleId: EModuleType) => {
    setSelectedModule(moduleId);
  };

  const ModuleItem = ({
    moduleCode,
    moduleName,
    ...props
  }: Omit<ComponentProps<typeof ModuleButtonWrapper>, 'selected' | 'onClick' | 'children'> & {
    moduleCode: EModuleType;
    moduleName: string;
  }) => (
    <Grid item key={moduleCode} md={4}>
      <ModuleButtonWrapper
        name={moduleName}
        selected={EModuleType[currentModule()] === moduleCode}
        onClick={() => handleSelectModule(moduleCode)}
        {...props}
      >
        <img width="56" height="56" src={getModuleIcon(EModuleType[moduleCode])} alt={`${moduleCode} Module`} />
        <Typography variant="caption" textAlign="center">
          {moduleName}
        </Typography>
      </ModuleButtonWrapper>
    </Grid>
  );

  return (
    <StyledDropdownPaper ref={ref}>
      <StyledContent>
        <Typography variant="body1" component="p">
          Select Module
        </Typography>
        <Divider sx={{ my: 1 }} />
        <Grid container columns={12}>
          {!loading &&
            data?.map(({ navigationModuleCode, navigationModuleName }) => (
              <ModuleItem
                key={navigationModuleCode}
                moduleCode={navigationModuleCode}
                moduleName={navigationModuleName}
                data-testid={`module-item-${navigationModuleName}`}
              />
            ))}
        </Grid>
      </StyledContent>
    </StyledDropdownPaper>
  );
};

export default forwardRef(ModuleSelectDropdown);

const ModuleButtonWrapper = ({
  onClick,
  children,
  selected,
  name = '',
  ...props
}: {
  name?: string;
  selected: boolean;
} & ComponentProps<typeof Stack>) => {
  const getName = () => {
    if (typeof name === 'string') {
      const parseName = name.split(' ').join('');
      return parseName.toLowerCase();
    }
    return '';
  };
  return (
    <Stack
      data-cy={`module-${getName()}`}
      alignItems="center"
      sx={{
        margin: 0.5,
        paddingTop: 1,
        color: 'white',
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          cursor: 'pointer',
        },
        backgroundColor: selected ? 'rgba(255, 255, 255, 0.1)' : 'other.navbarBackground',
        borderRadius: '6px',
      }}
      onClick={onClick}
      {...props}
    >
      {children}
    </Stack>
  );
};

const StyledDropdownPaper = forwardRef((props: ComponentProps<typeof Box>, ref) => (
  <Box
    ref={ref}
    sx={{
      backgroundColor: 'other.navbarBackground',
      color: 'common.white',
      borderRadius: { xs: '0px', md: '4px' },
      marginTop: { xs: '20px', md: '24px' },
      marginLeft: { xs: '-5px', md: 'unset' },
    }}
    {...props}
  />
));

const StyledContent = (props: ComponentProps<typeof Box>) => (
  <Box
    sx={{
      textAlign: 'center',
      width: { xs: '100%', md: '296px' },
      padding: '16px',
    }}
    {...props}
  />
);
