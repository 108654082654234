import React, { useEffect, useState } from 'react';

import { Box, Divider } from '@mui/material';
import { Waypoint } from 'react-waypoint';

import StackRow from 'components/StackRow';
import { checkType } from 'modules/Materials/commons/FilterPopoverContent/Breakdown/HelperElements';

import { useModal } from '../../contexts/ModalContext';
import { useProjectRow } from '../../contexts/ProjectRowContext';
import { RowProvider } from '../../contexts/RowContext';
import { UnassignedRowProvider } from '../../contexts/UnassignedRowContext';
import AggregatedAmountText from './AggregatedAmountText';
import ArrowIndicator from './ArrowIndicator';
import Loader from './Loader';
import LocationRow from './LocationRow';
import RowInfo from './RowInfo';
import ScopePackageRow from './ScopePackageRow';
import UnassignedRow from './UnassignedRow';

const ProjectRow = () => {
  const [isOpen, setIsOpen] = useState(true);

  const { errorNodeIds, formDirty } = useModal();
  const {
    item,
    loadingProjectData,
    loadingCreteQuantity,
    handleFetchScopePackages,
    handleFetchWorkPhases,
    loadingScopePackages,
    fetchLocations,
    handleScroll,
    locations,
    locationLoading,
    projectVisualTotalQty,
    formMethods,
  } = useProjectRow();

  const type = checkType(item);

  const handleClick = () => setIsOpen(!isOpen);

  useEffect(() => {
    if (isOpen && !loadingProjectData) {
      handleFetchScopePackages();
      handleFetchWorkPhases();
      fetchLocations();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, loadingProjectData]);

  if (loadingProjectData || loadingCreteQuantity) return <Loader />;

  const displayLocationsLoading = locationLoading && !locations?.length;

  return (
    <>
      <StackRow sx={{ justifyContent: 'space-between', minHeight: '32px', gap: '8px', pr: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
          <Box sx={{ width: '8px' }} />
          <ArrowIndicator item={item} isOpen={isOpen} handleClick={handleClick} fixed />
          <RowInfo type={type} item={item} isOpen={isOpen} />
        </Box>
        <AggregatedAmountText isEditing={formDirty} isError={errorNodeIds?.length}>
          {projectVisualTotalQty}
        </AggregatedAmountText>
      </StackRow>
      <Divider sx={{ width: '100%' }} />

      {isOpen && (
        <>
          <UnassignedRowProvider formMethods={formMethods}>
            <UnassignedRow />
          </UnassignedRowProvider>
          {loadingScopePackages ? <Loader /> : <ScopePackagesRows />}
          {displayLocationsLoading ? <Loader /> : <LocationRows />}
        </>
      )}

      {locations.length > 7 && <Waypoint bottomOffset="-10%" onEnter={handleScroll} />}
    </>
  );
};

export default ProjectRow;

const ScopePackagesRows = () => {
  const { scopePackages } = useProjectRow();

  return scopePackages
    ?.filter((scope) => scope.hasWorkPhases)
    .map((scopePackage) => <ScopePackageRow key={scopePackage.scopePackageId} item={scopePackage} />);
};

const LocationRows = () => {
  const { locations, item } = useProjectRow();

  return (
    <>
      {locations?.map((location) => (
        <RowProvider key={location?.locationId} item={location} parentId={item?.id}>
          <LocationRow depth={1} />
        </RowProvider>
      ))}
    </>
  );
};
