import { useEffect, useState } from 'react';

import useWindowSize from 'hooks/useWindowSize';

import { Coordinates, LineItemTypes } from '../@types/editQuantityModalTypes';

const useCoordinates = (open: LineItemTypes) => {
  const [coordinates, setCoordinates] = useState<Coordinates>();
  const [arrowPosition, setArrowPosition] = useState<'top' | 'bottom' | 'right' | 'left'>('top');
  const [arrowTop, setArrowTop] = useState<any>(0);
  const [arrowRight, setArrowRight] = useState<any>(0);
  const { height, width } = useWindowSize();

  useEffect(() => {
    const defaultPosition: Coordinates = {
      position: 'relative',
      top: 'unset',
      right: 'unset',
      left: 'unset',
    };

    if (!open) return;

    setTimeout(() => {
      const input = document.getElementById('quantity-text-field')?.getBoundingClientRect() || null;

      if (!input) return;

      defaultPosition.right = `calc(100% - ${input.x}px - 140px)`;
      defaultPosition.position = 'absolute';

      const belowPosition = input.y + 4;

      const fitInLeft = input.x - 672 > 0;

      if (window.innerHeight - belowPosition > 600) {
        defaultPosition.top = input.y + 24;
        setArrowPosition('top');
        if (!fitInLeft) {
          defaultPosition.left = `calc(${input.x}px - ${input.width}px)`;
          setArrowRight(590);
        }
      } else if (input.y - 4 > 590) {
        defaultPosition.top = input.y - 590;
        setArrowPosition('bottom');
        if (!fitInLeft) {
          defaultPosition.left = `calc(${input.x}px - ${input.width}px)`;
          setArrowRight(590);
        }
      } else if (!fitInLeft) {
        setArrowPosition('left');
        setArrowRight(input.x + input.width + 20);
        setArrowTop(input.y + 4);
      } else {
        setArrowPosition('right');
        setArrowTop(input.y + 4);
        setArrowRight(input.x - 40);
      }

      setCoordinates(defaultPosition);
    }, 0);
  }, [open, height, width]);

  return { coordinates, arrowPosition, arrowTop, arrowRight };
};

export default useCoordinates;
