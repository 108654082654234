import React from 'react';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { StatusCell, ThemedCheckBox } from 'components';
// import MoreOptionsDropdown from 'components/TableComponents/MoreOptionsDropdown/MoreOptionsDropdown';

// import MoreTableRowOptions from './MoreTableRowOptions';

const AdminTableRow = ({ projectUser, handleRowClick, isItemSelected }) => (
  <>
    <TableRow
      key={projectUser.user.userId}
      onClick={(e) => handleRowClick(e, projectUser)}
      role="checkbox"
      hover
      tabIndex={-1}
      aria-checked={isItemSelected}
      selected={isItemSelected}
    >
      <TableCell padding="checkbox">
        <ThemedCheckBox
          checked={isItemSelected}
          inputProps={{ 'aria-labelledby': projectUser.user.userId }}
        />
      </TableCell>
      <TableCell align="left">{projectUser.user.userIdentifier || '--'}</TableCell>
      <TableCell align="left">{`${projectUser.user.userFirstName || '--'} ${
        projectUser.user.userLastName || '--'
      }`}</TableCell>
      <TableCell align="left">{projectUser.user.userEmail}</TableCell>
      <TableCell align="left">Project Admin</TableCell>
      <TableCell align="left">{projectUser.user.workPhoneNumber || '--'}</TableCell>
      <TableCell align="left">{projectUser.user.personalPhoneNumber || '--'}</TableCell>
      <StatusCell
        status={
          projectUser.user.isDeleted || projectUser.isDeleted
            ? 'Deleted'
            : projectUser.user.inviteStatus
        }
      />
      <TableCell>
        {/* <MoreOptionsDropdown
          optionsRenderer={() => <MoreTableRowOptions adminIds={[user.userId]} />}
        /> */}
      </TableCell>
    </TableRow>
  </>
);
export default AdminTableRow;
