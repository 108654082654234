import React from 'react';

import { InputForesiteField } from '@evolvemep/foresite-components-ui-react';

const TextField = ({ sx, ...props }) => (
  <InputForesiteField
    size="small"
    color="secondary"
    sx={{ marginTop: '6px', ...sx }}
    inputProps={{
      style: {
        height: 36,
        padding: '0 14px',
        fontSize: 14,
      },
    }}
    autoComplete="off"
    {...props}
  />
);

export default TextField;
