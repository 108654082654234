import { grey } from '@mui/material/colors';

import { squareStyles } from '../../helpers/squareStyles';

export const listItemStyles = (color?: string) => ({
  '&.MuiListItem-root': {
    paddingTop: 0,
    paddingBottom: 0,
  },
  '&.MuiListItem-root:before': {
    ...squareStyles(color),
    top: 0,
  },
});

export const textSecondaryStyles = {
  backgroundColor: 'grey.50',
  width: 20,
  height: 20,
  marginLeft: 'auto',
  borderRadius: '100%',
  fontSize: '0.8rem',
  lineHeight: '20px',
  textAlign: 'center',
};

export const primaryTypographyStyles = {
  '&.MuiListItemText-primary': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};

export const listItemTextStyles = {
  '&.MuiListItemText-root': {
    display: 'flex',
  },
};

export const buttonViewAllStyles = {
  padding: 0,
  border: 'none',
  textTransform: 'none',
  borderTop: `1px solid ${grey[200]}`,
  width: '100%',
  margin: 'auto 0',
  borderRadius: 0,
  pointerEvents: 'none',
  position: 'absolute',
  left: 0,
  bottom: 0,
};
