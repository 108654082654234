import React from 'react';

import { AppBar, Dialog, DialogContent, Slide, Toolbar, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';

import { Custom } from 'components/Icons';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const FullScreenModal = ({
  title = 'Full screen default modal title',
  titleContent,
  onClose,
  children,
  sx,
  disableTitleClose,
  titleStyles,
  contentStyles,
  ...props
}) => (
  <Dialog fullScreen TransitionComponent={Transition} sx={sx} {...props}>
    <AppBar sx={{ position: 'relative', backgroundColor: grey[200] }}>
      <Toolbar sx={{ alignItems: 'center' }}>
        {titleContent || (
          <>
            {!disableTitleClose && (
              <IconButton edge="start" onClick={onClose} aria-label="close">
                <Custom.Close />
              </IconButton>
            )}
            <Typography
              sx={{ ml: 2, flex: 1, ...titleStyles }}
              color="primary"
              variant="h6"
              component="div"
            >
              {title}
            </Typography>
          </>
        )}
      </Toolbar>
    </AppBar>
    <DialogContent
      sx={{
        padding: '16px 40px',
        overflowY: 'auto',
        ...contentStyles,
      }}
      dividers
    >
      {children}
    </DialogContent>
  </Dialog>
);

export default FullScreenModal;
