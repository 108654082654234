import React from 'react';

import { Stack, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';

import { Custom } from 'components/Icons';
import { greyColors } from 'styles/theme/colors';

const OutlinedCard = ({
  header,
  addButtonLabel,
  addButtonId = '',
  addItemComponent,
  addButtonColorIcon,
  onAddButtonClick,
  disabled,
  hideAddButton,
  sx,
  children,
  noHeader = false,
}) => (
  <OutlinedWrapper sx={{ ...sx, maxHeight: '100%', overflowY: 'hidden' }}>
    {!noHeader && (
      <HeaderWrapper>
        {header}
        {!hideAddButton && (
          <AddItemButton
            component={addItemComponent}
            disabled={disabled}
            label={addButtonLabel}
            onClick={onAddButtonClick}
            colorIcon={addButtonColorIcon}
            id={addButtonId}
          />
        )}
      </HeaderWrapper>
    )}
    <Box sx={{ height: 'calc(100% - 32px)', overflowY: 'auto' }}>{children}</Box>
  </OutlinedWrapper>
);

export default OutlinedCard;

const OutlinedWrapper = ({ sx, children }) => (
  <Card
    sx={{
      border: '1px solid',
      borderColor: 'other.border',
      borderRadius: '4px',
      boxShadow: 'none',
      height: 'calc(100% - 72px)',
      ...sx,
    }}
  >
    {children}
  </Card>
);

const HeaderWrapper = (props) => (
  <Stack
    sx={{
      padding: '6px 21px',
      height: '32px',
      borderBottom: '1px solid',
      borderColor: 'other.border',
    }}
    direction="row"
    alignItems="center"
    {...props}
  />
);

const AddItemButton = ({ label, disabled, onClick, component, id }) => {
  const { palette } = useTheme();

  return (
    component || (
      <Button
        color="secondary"
        onClick={onClick}
        disabled={disabled}
        sx={{
          fontWeight: 'bold',
          letterSpacing: '1.46px',
          color: 'secondary.dark',
          fontSize: '13px',
          height: '24px',
          marginLeft: 'auto',
        }}
        startIcon={<Custom.Add fill={disabled ? greyColors.grey500 : palette.secondary.dark} />}
        id={id}
      >
        {label}
      </Button>
    )
  );
};
