import React from 'react';

import Stack from '@mui/material/Stack';

import { Custom } from 'components/Icons';
import { ProjectsTable } from 'components/ProjectsTable/ProjectsTable';

import { useDrawingRegisterContext } from '../DrawingRegisterContext';

export const FacilityProjectsTable = () => {
  const { setActiveItem } = useDrawingRegisterContext();

  return (
    <Stack sx={{ flex: 1 }}>
      <ProjectsTable
        icon={<Custom.DrawingRegister />}
        onProjectClick={(project) => {
          setActiveItem({ ...project, label: project.identifier, type: 'PROJECT' });
        }}
        headerNames={['Project Id', 'Project/Drawing Register Name']}
      />
    </Stack>
  );
};
