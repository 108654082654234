import useAutofillLBSItem from './useAutofillLBSItem';
import useAutofillLineItemChildren from './useAutofillLineItemChildren';
import useAutofillLineItemClicked from './useAutofillLineItemClicked';
import useClearAutofill from './useClearAutofill';
import useValuesBeforeAutofill from './useValuesBeforeAutofill';

const useFieldEffects = () => {
  useAutofillLBSItem();
  useAutofillLineItemClicked();
  useAutofillLineItemChildren();
  useClearAutofill();
  useValuesBeforeAutofill();
};

export default useFieldEffects;
