import React from 'react';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { WorkOrderAttachments } from 'modules/Shop/WorkOrders/WorkOrderAttachments';
import { useWorkOrdersContext } from 'modules/Shop/WorkOrders/WorkOrdersContext';

import { useWorkRequestsContext } from '../../WorkRequestsContext';
import { useWorkRequestPropertiesAttachmentsContext } from '../../WorRequestsProperties/WorkRequestPropertiesAttachments/WorkRequestPropertiesAttachmentsContext';
import { WorkOrdersDetails } from './WorkOrdersDetails';
import { WorkRequestAttachments } from './WorkRequestAttachments/WorkRequestAttachments';
import { WorkRequestInformation } from './WorkRequestInfo/WorkRequestInformation';
import { workOrder } from './WRflyoutTypes';

export const GetItemList = () => {
  const { workRequestDocuments } = useWorkRequestPropertiesAttachmentsContext();
  const { workRequestSelected, workOrderSelected } = useWorkRequestsContext();
  const { selectedItem } = useFacilitiesProjects();
  const { workOrders } = useWorkOrdersContext();
  const WorkRequestOrders = workOrders.filter(
    (workOrder: workOrder) => workOrder.workRequestId === workRequestSelected.workRequestId,
  );

  const infoItem = {
    title: 'Information',
    info: '',
    content: <WorkRequestInformation />,
    defaultExpanded: true,
  };

  const workRequestAttachments = {
    title: 'Attachments',
    info: workRequestDocuments.length,
    content: <WorkRequestAttachments />,
    defaultExpanded: false,
    key: 'WRattachments',
  };

  const workOrderDetails = {
    title: 'Work Orders',
    info: WorkRequestOrders.length,
    content: <WorkOrdersDetails WorkRequestOrders={WorkRequestOrders} />,
    defaultExpanded: false,
  };

  const workOrderAttachemnts = {
    title: 'Attachments',
    info: workOrderSelected?.numberOfAttachments,
    content: <WorkOrderAttachments />,
    defaultExpanded: false,
    key: 'WOattachments',
  };

  const items = [infoItem];

  if (selectedItem?.type === 'PROJECT') items.push(workRequestAttachments);

  if (selectedItem?.type !== 'PROJECT' && workOrderSelected) items.push(workOrderAttachemnts);

  if (selectedItem?.type !== 'PROJECT' && !workOrderSelected) items.push(workRequestAttachments, workOrderDetails);

  return items;
};
