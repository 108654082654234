const getRowIndexByKey = ({ elements = [], key = '', value = '' }) =>
  elements.map((el) => el[key]).indexOf(value);

export const useTableHelpers = ({ data, id }) => {
  const getCurrentIndex = (selected) =>
    getRowIndexByKey({ elements: data, key: id, value: selected[id] });
  const getLastSelectedIndex = (lastSelectedItem) =>
    getRowIndexByKey({ elements: data, key: id, value: lastSelectedItem[id] });

  const getSelectedIndexes = (selectedItems) =>
    data.reduce((array, current, index) => {
      const itemExists = getRowIndexByKey({ elements: selectedItems, key: id, value: current[id] });
      if (itemExists !== -1) array.push(index);
      return array;
    }, []);

  const getExtremeIndex = (selectedItems, currentIndex, lastSelectedIndex) => {
    const selectedIndexes = getSelectedIndexes(selectedItems);
    const isCurrentGreaterThanSelected = currentIndex > lastSelectedIndex;
    if (isCurrentGreaterThanSelected) {
      const minIndex = Math.min(...selectedIndexes);
      return minIndex < lastSelectedIndex ? minIndex : lastSelectedIndex;
    }
    const maxIndex = Math.max(...selectedIndexes);
    return maxIndex > lastSelectedIndex ? maxIndex : lastSelectedIndex;
  };

  const getFilterForNewItems = (selectedItems, index, currentIndex, lastSelectedIndex) => {
    const extremeIndex = getExtremeIndex(selectedItems, currentIndex, lastSelectedIndex);
    return currentIndex > lastSelectedIndex
      ? index >= extremeIndex && index <= currentIndex
      : index <= extremeIndex && index >= currentIndex;
  };

  const getNewItems = (selectedItems, selected, lastSelectedItem) => {
    const currentIndex = getCurrentIndex(selected);
    const lastSelectedIndex = getLastSelectedIndex(lastSelectedItem);

    return data.reduce((array, current, index) => {
      if (getFilterForNewItems(selectedItems, index, currentIndex, lastSelectedIndex))
        array.push(current);
      return array;
    }, []);
  };

  return { getNewItems };
};
