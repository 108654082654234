import { useCallback, useEffect, useState } from 'react';

import { addChildrenRecursively, clone, deepArraysAreEqual, findNode } from 'helpers/arrayFunctions';

import { INode } from '../@types/modalContextTypes';
import { mapNodeItem } from '../helpers/nodeItemHelpers';
import useNotFoundParentNodes, { NotFoundNode } from './useNotFoundParentNodes';

const changeValueRecursively = (
  calculationNodeIds: string[],
  nodeTree: INode[],
  editingRowId: string,
  inputValue: number,
  difference: number,
  isResetting: boolean,
) =>
  nodeTree.reduce((arr: INode[], item) => {
    if (item.children)
      item.children = changeValueRecursively(
        calculationNodeIds,
        item.children,
        editingRowId,
        inputValue,
        difference,
        isResetting,
      );

    if (calculationNodeIds.includes(item.id)) {
      item.aggregate += difference;
      item.aggregateVisual += difference;
      item.isEdited = true;
    }

    if (editingRowId === item.id) {
      item.amount = inputValue;
      item.isModifiedDirectly = !isResetting;
    }

    arr.push(item);
    return arr;
  }, []);

const useNodeTree = (calculationNodeIds: string[], filteredCheckedIds: string[]) => {
  const [formDirty, setFormDirty] = useState(false);
  const [nodeTree, setNodeTree] = useState<INode[]>([]);
  const [valueOnFocus, setValueOnFocus] = useState<number | null>();
  const [nodeTreeBackup, setNodeTreeBackup] = useState<INode[]>([]);
  const {
    notFoundParentNodes,
    addNodeToNotFoundParentArray,
    removeNodeFromNotFoundParentArray,
    findItemInNotFoundParent,
  } = useNotFoundParentNodes();

  const handleSetNodeTreeBackup = (updatedNodeTree?: INode[]) => setNodeTreeBackup(clone(updatedNodeTree || nodeTree));

  const updateParentsAggregatedQty = useCallback(
    (
      updateBackup = false,
      inputValue: number,
      isResetting?: boolean,
      manualDiff?: number,
      editingId?: string,
      manualCalculationIds?: string[],
    ) => {
      const difference = manualDiff || inputValue - (valueOnFocus || 0);
      const editingRowId = editingId || calculationNodeIds[calculationNodeIds.length - 1];

      const updatedNodeTree = changeValueRecursively(
        manualCalculationIds || calculationNodeIds,
        clone(nodeTreeBackup),
        editingRowId,
        inputValue,
        difference,
        isResetting || false,
      );

      setNodeTree(updatedNodeTree);
      if (updateBackup) handleSetNodeTreeBackup(updatedNodeTree);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nodeTreeBackup],
  );

  const findItem = useCallback((itemId: string): INode => findNode(itemId, nodeTree), [nodeTree]);

  const addNodeToTree = (item: { [key: string]: any }, parentId: string, force0amount: boolean) => {
    const mappedNode: INode = mapNodeItem(item, force0amount);
    if (findItem(mappedNode.id) || findItemInNotFoundParent(mappedNode)) return null;

    if (!parentId) return setNodeTree([mappedNode]);

    const foundTheParent = findItem(parentId);
    if (!foundTheParent) return addNodeToNotFoundParentArray(mappedNode, parentId);

    return setNodeTree((prevNodeTree) => addChildrenRecursively({ id: parentId }, prevNodeTree, mappedNode));
  };

  const calculateFilteredAggregatedQty = (node: INode): INode => {
    if (!node?.children?.length) return node;

    node.aggregateVisual =
      node.amount +
      node.children.reduce((acc, item) => {
        const childAggregate = calculateFilteredAggregatedQty(item).aggregateVisual;

        if (!filteredCheckedIds.includes(item.id)) return acc + childAggregate;

        const hasChildren = item.children?.length;
        const minValue = Math.min(item.amount, item.aggregateVisual);
        const isWorkPhase = item.type === 'WorkPhase';

        if (!hasChildren) item.aggregateVisual = isWorkPhase ? item.amount : minValue;

        return acc + item.aggregateVisual;
      }, 0);

    return node;
  };

  const updateFilteredVisualAggregatedQty = () => {
    const clonedNodeTree = clone(nodeTree);
    const response = calculateFilteredAggregatedQty(clonedNodeTree[0]);
    if (!deepArraysAreEqual([response], nodeTree)) setNodeTree([response]);
  };

  const validateParentExistAndAddItToNodeTree = (notFoundParentNode: NotFoundNode) => {
    const foundTheParent = findItem(notFoundParentNode.parentId);
    if (!foundTheParent) return;

    removeNodeFromNotFoundParentArray(notFoundParentNode);
    const parent = { id: notFoundParentNode.parentId };
    setNodeTree((prevNodeTree) => addChildrenRecursively(parent, prevNodeTree, notFoundParentNode.node));
  };

  const tryToAddNotLoadedParentsToNodeTree = () => {
    if (!notFoundParentNodes.length) return;
    notFoundParentNodes.forEach((node) => validateParentExistAndAddItToNodeTree(node));
  };

  useEffect(() => {
    tryToAddNotLoadedParentsToNodeTree();

    const hasNotFiltersOrFormIsDirty = !filteredCheckedIds?.length || !nodeTree?.length || formDirty;
    if (hasNotFiltersOrFormIsDirty) return;

    updateFilteredVisualAggregatedQty();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeTree]);

  useEffect(() => {
    handleSetNodeTreeBackup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueOnFocus]);

  return {
    nodeTree,
    formDirty,
    setFormDirty,
    updateParentsAggregatedQty,
    addNodeToTree,
    findItem,
    setValueOnFocus,
  };
};

export default useNodeTree;
