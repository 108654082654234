import React from 'react';

import DeleteModal from '../../../ShopConfiguration/ShopConfigurationSelect/DeleteModal';
import { useWorkCell } from '../contexts/WorkCellContext';

const DeleteWorkCellModal = ({
  node,
  openDeleteWorkCellModal = false,
  setOpenDeleteWorkCellModal,
}) => {
  const { deleteWorkCell, loadingDeleteWorkCell } = useWorkCell();
  const handleDeleteWorkCell = async (workCellId) => {
    await deleteWorkCell(workCellId, () => {
      setOpenDeleteWorkCellModal(false);
    });
  };

  return (
    <DeleteModal
      open={openDeleteWorkCellModal}
      onClose={() => setOpenDeleteWorkCellModal(false)}
      onSubmit={() => handleDeleteWorkCell(node.id)}
      itemName={node.label}
      loading={loadingDeleteWorkCell}
      confirmText="Are you sure you want to delete"
      deleteButtonText="CONFIRM"
    />
  );
};

export default DeleteWorkCellModal;
