/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useContext, useEffect } from 'react';

import { gql } from '@apollo/client';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { projectUsers as PROJECT_USERS } from 'graphql/queries';
import useLazySearchableQuery from 'hooks/useLazySearchableQuery';

const ProjectMembersContext = React.createContext();

const getProjectId = (selectedItem) => (selectedItem?.type === 'PROJECT' ? selectedItem?.id : null);

const ProjectMembersProvider = ({ children }) => {
  const { selectedItem } = useFacilitiesProjects();
  const [selected, setSelected] = useState([]);
  const [showDeletedUsers, setShowDeletedUsers] = useState(false);

  const projectId = getProjectId(selectedItem);

  const [{ lazyLoad, paginationHandler, searchHandler, sortHandler }, { loading, data, called }] =
    useLazySearchableQuery(gql(PROJECT_USERS), {
      extraVars: {
        projectId,
        includeDeleted: showDeletedUsers ? 'YES' : null,
      },
      fetchPolicy: 'cache-and-network',
    });

  useEffect(() => {
    if (projectId) {
      lazyLoad(called);
    }
  }, [projectId, showDeletedUsers, called]);

  const selectedStateObj = useMemo(
    () => ({
      projectId,
      selected,
      setSelected,
      showDeletedUsers,
      setShowDeletedUsers,
      lazyLoad,
      paginationHandler,
      searchHandler,
      sortHandler,
      loading,
      data,
    }),
    [
      projectId,
      selected,
      setSelected,
      showDeletedUsers,
      setShowDeletedUsers,
      paginationHandler,
      searchHandler,
      sortHandler,
      loading,
      data,
    ],
  );

  return (
    <ProjectMembersContext.Provider value={selectedStateObj}>
      {children}
    </ProjectMembersContext.Provider>
  );
};

const useProjectMembersContext = () => {
  const context = useContext(ProjectMembersContext);
  if (context === undefined) {
    throw new Error('useProjectMembersContext must be used within a ProjectMembersContext');
  }
  return context;
};

export { ProjectMembersContext, ProjectMembersProvider, useProjectMembersContext };
