import React from 'react';

import { Custom } from 'components/Icons';
import RoundedIconButton from 'components/RoundedIconButton';

const SearchButton = ({ onClick }) => (
  <RoundedIconButton style={{ marginRight: '16px' }} onClick={onClick}>
    <Custom.Search />
  </RoundedIconButton>
);

export default SearchButton;
