import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { workCellTasksMultiSearch as GET_WORK_CELL_TASKS_MULTI_SEARCH } from 'graphql/queries';

const useTaskProperties = () => {
  const { id: taskId } = useParams();

  const { data, loading } = useQuery(gql(GET_WORK_CELL_TASKS_MULTI_SEARCH), {
    fetchPolicy: 'cache-and-network',
    variables: {
      query: { orderBy: 'createdOn:asc', taskIds: taskId },
    },
  });

  const taskProperties = data?.workCellTasksMultiSearch[0];
  const workOrderId = taskProperties?.workOrderId;
  const workRequestId = taskProperties?.workRequestId;
  const projectId = taskProperties?.projectId;

  return {
    taskProperty: taskProperties,
    loadingProperties: loading,
    workOrderId: loading ? undefined : workOrderId,
    workRequestId: loading ? undefined : workRequestId,
    projectId: loading ? undefined : projectId,
  };
};

export default useTaskProperties;
