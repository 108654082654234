import React, { useCallback, useEffect, useRef } from 'react';

import { ForesiteTextField } from '@evolvemep/foresite-components-ui-react';
import { useTheme } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { useController } from 'react-hook-form';

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

// eslint-disable-next-line
const ControlledDatePicker = ({
  name,
  control,
  label,
  minDate,
  maxDate,
  fullWidth,
  size,
  rules,
  helperText,
  pickerIcon,
  darkTheme,
  onDateChange = () => {},
  onDateBlur = () => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onAccept = (_date) => {},
  autoComplete,
  triggerChangeOnlyOnBlur = false,
  onError = () => {},
  id,
  ...props
}) => {
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({ name, control, rules });

  const { palette } = useTheme();
  const localParams = useRef();
  const outputHelperText = useRef();
  const previousValue = usePrevious(value);
  const { setValue, disabled, onFocus } = props;

  useEffect(() => {
    if (value !== previousValue && !!value === false && setValue) {
      setValue(name, null);
    }
    // eslint-disable-next-line
  }, [value]);

  const handleChangeDatePicker = useCallback(
    (date, child) => {
      onChange(date, child);
      if (!triggerChangeOnlyOnBlur) onDateChange(date, child);
    },
    [onChange, onDateChange, triggerChangeOnlyOnBlur],
  );

  const handleAcceptDatePicker = useCallback(
    (date, child) => {
      onAccept(date);
      onChange(date, child);
      onDateChange(date, child);
      onBlur();
    },
    [onAccept, onBlur, onChange, onDateChange],
  );

  const handleBlurTextField = useCallback(
    (event) => {
      if (triggerChangeOnlyOnBlur) onDateBlur(event);
      onBlur();
    },
    [onBlur, onDateBlur, triggerChangeOnlyOnBlur],
  );

  const handleFocusTextField = useCallback(() => {
    if (onFocus) onFocus(value);
  }, [onFocus, value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        disabled={disabled}
        minDate={minDate}
        maxDate={maxDate}
        label={label}
        value={value}
        onChange={handleChangeDatePicker}
        onAccept={handleAcceptDatePicker}
        onError={onError}
        renderInput={(params) => {
          localParams.current = params;
          return (
            <ForesiteTextField
              id={id}
              autoComplete={autoComplete}
              size={size}
              fullWidth={fullWidth}
              value={value}
              onBlur={handleBlurTextField}
              onFocus={handleFocusTextField}
              helperText={outputHelperText.current || helperText || error?.message}
              data-cy="date-picker"
              {...params}
              error={error}
              darkTheme={darkTheme}
              sx={
                darkTheme
                  ? {
                      width: '100%',
                      '& .MuiSvgIcon-root': {
                        color: `${palette.surface.darkSurface.secondary} !important`,
                        fontSize: '18px',
                      },
                      '& .MuiInputBase-root.Mui-disabled': {
                        color: palette.surface.darkSurface.disabled,
                        '& textarea': {
                          WebkitTextFillColor: `${palette.surface.darkSurface.disabled} !important`,
                        },
                      },
                      '& .Mui-error': {
                        color: palette.error.light,
                        lineHeight: '20px',
                      },
                      '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
                        borderColor: `${palette.error.light} !important`,
                      },
                      '& .MuiFormHelperText-root': {
                        textAlign: 'left',
                        color: `${palette.error.light} !important`,
                      },
                      '& .MuiInputBase-root': {
                        color: palette.surface.darkSurface.secondary,
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: `${palette.surface.darkSurface.secondary} !important`,
                      },
                      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: error ? '' : `${palette.secondary.mainDarkBackground} !important`,
                      },
                      '.MuiInputLabel-root': {
                        color: palette.surface.darkSurface.secondary,
                      },
                      '.MuiInputLabel-root.Mui-focused': {
                        color: palette.surface.darkSurface.secondary,
                      },
                      flex: 1,
                    }
                  : { flex: 1 }
              }
            />
          );
        }}
        components={{ OpenPickerIcon: pickerIcon }}
        {...props}
      />
    </LocalizationProvider>
  );
};

export default ControlledDatePicker;
