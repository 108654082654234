import React from 'react';

import { Routes, Route, Navigate } from 'react-router-dom';

import PageNotFound from 'app/PageNotFound';
import { MaterialsConfigContextProvider } from 'hooks-api/useMaterialsConfig';
import {
  ProjectInterfaceNavContent,
  FacilitiesInterfaceNavContent,
  ProjectMembersInterfaceNavContent,
  ProjectPropertiesInterfaceNavContent,
} from 'modules/Admin/AdminNavInterfaces';
import {
  LocationsAndWorkPhasesInterfaceNavContent,
  WorkRequestNavContent,
  WorkRequestsNavContent,
} from 'modules/Field/FieldNavInterfaces';
import { ProjectProvider } from 'modules/Field/LocationsAndWorkPhases/contexts/ProjectContext';
import { PartImageProvider } from 'modules/Materials/CatalogSetup/ItemsSection/Manufacturer/PartImage/PartImageContext';
import { CatalogPartProvider } from 'modules/Materials/CatalogSetup/Providers/CatalogPartProvider';
import { CatalogSelectedPartProvider } from 'modules/Materials/CatalogSetup/Providers/CatalogSelectedPartProvider';
import { ManufacturerAPIProvider } from 'modules/Materials/CatalogSetup/Providers/ManufacturerAPIProvider';
import { PartAPIProvider } from 'modules/Materials/CatalogSetup/Providers/PartAPIProvider';
import { UOMAPIProvider } from 'modules/Materials/CatalogSetup/Providers/UOMAPIProvider';
import {
  BillOfMaterialsByIdInterfaceNavContent,
  BillOfMaterialsInterfaceNavContent,
  CatalogSetupInterfaceNavContent,
  TakeOffContent,
  DataImportInterfaceNavContent,
} from 'modules/Materials/MaterialNavInterfaces';

import {
  DesignMembersInterfaceNavContent,
  DesignPropertiesInterfaceNavContent,
  DrawingRegisterInterfaceNavContent,
} from './DesignNavInterfaces';

const ShopRoutes = () => (
  <ProjectProvider>
    <MaterialsConfigContextProvider>
      <CatalogSelectedPartProvider>
        <PartAPIProvider>
          <UOMAPIProvider>
            <ManufacturerAPIProvider>
              <PartImageProvider>
                <CatalogPartProvider>
                  <Routes>
                    <Route path="/" element={<Navigate to="drawing-register" />} />
                    <Route path="facilities" element={<FacilitiesInterfaceNavContent />} />
                    <Route path="design-members" element={<DesignMembersInterfaceNavContent />} />
                    <Route path="design-properties" element={<DesignPropertiesInterfaceNavContent />} />
                    <Route path="projects" element={<ProjectInterfaceNavContent />} />
                    <Route path="members/project-members" element={<ProjectMembersInterfaceNavContent />} />
                    <Route path="properties/project-properties" element={<ProjectPropertiesInterfaceNavContent />} />
                    <Route
                      path="project-setup/locations-and-work-phases"
                      element={<LocationsAndWorkPhasesInterfaceNavContent />}
                    />
                    <Route path="takeoff" element={<TakeOffContent />} />
                    <Route path="bill-of-materials" element={<BillOfMaterialsInterfaceNavContent />} />
                    <Route path="bill-of-materials/:id" element={<BillOfMaterialsByIdInterfaceNavContent />} />
                    <Route path="catalog-setup" element={<CatalogSetupInterfaceNavContent />} />
                    <Route path="data-import" element={<DataImportInterfaceNavContent />} />
                    <Route path="drawing-register" element={<DrawingRegisterInterfaceNavContent />} />
                    <Route path="work-requests" element={<WorkRequestsNavContent />} />
                    <Route path="work-requests/:id" element={<WorkRequestNavContent />} />
                    <Route path="*" element={<PageNotFound />} />
                  </Routes>
                </CatalogPartProvider>
              </PartImageProvider>
            </ManufacturerAPIProvider>
          </UOMAPIProvider>
        </PartAPIProvider>
      </CatalogSelectedPartProvider>
    </MaterialsConfigContextProvider>
  </ProjectProvider>
);

export default ShopRoutes;
