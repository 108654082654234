import { useEffect } from 'react';

import { getStatusFilters } from 'helpers/arrayFunctions';

import { useWorkRequestsContext } from '../../WorkRequestsContext';
import { getFilters } from './getFilters';

export const STATUS_THAT_SHOULDNT_APPEAR_ON_SHOP = ['Canceled', 'Rejected'];

export const getStatusesToFilterOut = (selectItemType) =>
  selectItemType === 'FACILITY' ? ['Canceled', 'Rejected', 'Draft'] : [];

export const useEffectThatFetchesWorkRequests = (
  selectedItem,
  filters,
  workRequestsStatus,
  getWorkRequests,
  orderBy,
  setDisabledNew,
  setDisabledFilters,
  filtersPopover,
) => {
  const { updateWRList } = useWorkRequestsContext();
  useEffect(() => {
    const statusesToFilterOut = getStatusesToFilterOut(selectedItem.type);
    const translateFilters = getFilters(selectedItem, filters);
    const workRequestStatusIds =
      selectedItem.type === 'FACILITY'
        ? getStatusFilters(workRequestsStatus, statusesToFilterOut)
        : '';

    if (translateFilters) {
      getWorkRequests({
        take: 1000,
        ...(orderBy ? { orderBy: `${orderBy.field}:${orderBy.sort}` } : {}),
        ...(workRequestStatusIds ? { workRequestStatusIds } : {}),
        ...{ ...translateFilters, ...filtersPopover },
      });
      setDisabledNew(false);
      return setDisabledFilters(true);
    }
    setDisabledFilters(true);
    setDisabledNew(true);

    return null;
  }, [
    filters,
    getWorkRequests,
    selectedItem,
    setDisabledFilters,
    setDisabledNew,
    orderBy,
    workRequestsStatus,
    filtersPopover,
    updateWRList,
  ]);
};
