/* eslint-disable max-lines-per-function */
import React, { useMemo, useCallback, useState, useEffect } from 'react';

import { useController, useFormContext } from 'react-hook-form';

import { ForesiteAutocomplete } from 'components/FormComponents';

import { getAttributeVariants } from './AttributeSelectHelper';

const AttributeMultiSelectField = ({ setNewAttributeVariant, attribute, index, ...restProps }) => {
  const { control, setValue, setError, clearErrors, disabled } = useFormContext();
  const [opened, setOpened] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const {
    field: { ref, value, onChange, onBlur },
    formState: { isSubmitting },
    fieldState: { error },
  } = useController({
    name: `partAttributeVariantData.${index}.selectedVariants`,
    control,
    rules: {
      maxLength: {
        value: 32,
        message: 'Variant must not exceed 32 characters',
      },
    },
  });
  const variants = useMemo(() => getAttributeVariants(attribute), [attribute]);

  const clearNewVariant = useCallback(() => {
    setNewAttributeVariant(false);
    setValue(`partAttributeVariantData.${index}.text`, '');
    setValue(`partAttributeVariantData.${index}.code`, '');
    setValue(`partAttributeVariantData.${index}.isNewVariant`, false);
    clearErrors(`partAttributeVariantData.${index}.code`);
  }, [index, setNewAttributeVariant, setValue, clearErrors]);

  const handleSelectValue = useCallback(
    (value) => {
      if (!value) {
        return;
      }

      const selectedOption = variants.find(
        (variant) => variant.label.toLowerCase().trim() === value.toLowerCase().trim(),
      );

      if (!selectedOption) {
        setNewAttributeVariant(true);
        setValue(`partAttributeVariantData.${index}.isNewVariant`, true);
        setValue(`partAttributeVariantData.${index}.text`, value);
        setValue(`partAttributeVariantData.${index}.code`, '');
        setOpened(false);
        return;
      }

      setError(`partAttributeVariantData.${index}.selectedVariants`, {
        message: 'Variant name already exists',
      });
      clearNewVariant();
      setOpened(false);
    },
    [variants, setNewAttributeVariant, index, setValue, clearNewVariant, setError],
  );

  const handleInputChange = (value, reason) => {
    if (reason === 'reset') {
      return;
    }

    setInputValue(value);

    if (!value) {
      clearErrors(`partAttributeVariantData`);
      clearNewVariant();
      return;
    }

    const selectedOption = variants.find(
      (variant) => variant.label.toLowerCase().trim() === value.toLowerCase().trim(),
    );

    if (selectedOption != null) {
      clearNewVariant();
      setOpened(true);
      return;
    }

    clearErrors(`partAttributeVariantData`);
  };

  const handleKeyDown = useCallback(
    (event) => {
      if (!attribute.allowWriteInVariant) {
        return;
      }

      if (event.key === 'Enter') {
        handleSelectValue(inputValue);
      }
    },
    [attribute.allowWriteInVariant, handleSelectValue, inputValue],
  );

  const handleOnChange = useCallback(
    (e, item) => {
      onChange(item);
      setInputValue('');
      clearErrors(`partAttributeVariantData`);

      clearNewVariant();
    },
    [onChange, clearErrors, clearNewVariant],
  );

  useEffect(() => {
    if (attribute?.isNewVariant) {
      setInputValue(attribute?.text);
      onChange(attribute?.selectedVariants || []);
      setValue(`partAttributeVariantData.${index}.code`, attribute.code);
      setNewAttributeVariant(true);
    }
  }, [attribute, index, onChange, setNewAttributeVariant, setValue]);

  return (
    <ForesiteAutocomplete
      filterSelectedOptions
      multiple
      open={opened}
      onOpen={() => setOpened(true)}
      onClose={() => setOpened(false)}
      options={variants}
      sx={{ width: '100%' }}
      onChange={handleOnChange}
      onBlur={(e) => {
        onBlur(e);
        if (attribute.allowWriteInVariant) {
          handleSelectValue(e.target.value);
        }
      }}
      onInputChange={(e, item, reason) => handleInputChange(item, reason)}
      onKeyDown={handleKeyDown}
      isOptionEqualToValue={(option, value) => {
        if (option.id) {
          return option.id === value.id;
        }

        return option === value;
      }}
      inputValue={inputValue}
      value={value}
      name={`partAttributeVariantData.${index}.selectedVariants`}
      disabled={isSubmitting || disabled}
      textFieldProps={{
        inputRef: { ref },
        error: !!error,
        helperText: error?.message,
        size: 'small',
        label: attribute?.partAttributeName,
        maxLength: 32,
      }}
      {...restProps}
    />
  );
};

export default AttributeMultiSelectField;
