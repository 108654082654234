import React from 'react';

import { useMutation, gql } from '@apollo/client';
import { Box, CircularProgress, Typography } from '@mui/material';
import Button from '@mui/material/Button';

import { Modal } from 'components';
import { GENERIC_MUTATION_ERROR } from 'constants/globalConstants';
import { deleteTaskType as DELETE_TASK_TYPE } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

import { useShopTasksContext } from './ShopTasksContext';

const DeleteTaskTypeModal = ({ open }) => {
  const { setShowDeleteTaskTypeModal, selectedTaskType } = useShopTasksContext();
  const { handleResponse } = useGraphqlResponseHandler();
  const [deleteTaskType, { loading }] = useMutation(gql(DELETE_TASK_TYPE), {
    update(cache) {
      cache.evict({ id: cache.identify(selectedTaskType) });
      cache.gc();
    },
  });

  const submit = async () => {
    await handleResponse(
      deleteTaskType,
      {
        variables: {
          params: {
            taskTypeId: selectedTaskType?.taskTypeId,
          },
        },
      },
      {
        successMessage: `Shop task type successfully deleted`,
        errorMessage: GENERIC_MUTATION_ERROR,
      },
    );
    setShowDeleteTaskTypeModal(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => setShowDeleteTaskTypeModal(false)}
      disableCloseButton={loading}
      disableModalOutsideClick
      fullWidth
      scroll="paper"
      sx={{
        '.MuiPaper-root': {
          maxHeight: '80vh',
        },
      }}
      titleStyles={{ fontWeight: 700 }}
      title={<ModalHeader title="Confirm Delete" />}
      footer={
        <>
          {loading && <CircularProgress size={25} color="secondary" />}
          <Button color="primary" disabled={loading} onClick={() => setShowDeleteTaskTypeModal(false)}>
            NO
          </Button>
          <DeleteButton disabled={loading} onClick={submit}>
            CONFIRM
          </DeleteButton>
        </>
      }
    >
      <Box>
        Are you sure you want to delete the Shop Task Type
        <Typography fontWeight="bold" display="inline" ml="4px">
          {selectedTaskType?.taskTypeName}
        </Typography>
        ?
      </Box>
    </Modal>
  );
};

export default DeleteTaskTypeModal;

const DeleteButton = ({ onClick, disabled, children }) => (
  <Button sx={{ marginLeft: '8px' }} color="error" variant="contained" onClick={onClick} disabled={disabled}>
    {children}
  </Button>
);

const ModalHeader = ({ title }) => <Box sx={{ width: 'md' }}>{title}</Box>;
