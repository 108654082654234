import React, { useState } from 'react';

import { Typography, Box, Stack } from '@mui/material';

import { useWorkRequestsContext } from '../../../WorkRequestsContext';
import { ShowMoreButton } from './ShowMoreButton';

type descTextProps = {
  setHovered: React.Dispatch<React.SetStateAction<boolean>>;
};
const PrepareDescription = (description: string) => {
  if (!description) return ' -\u00A0';
  if (description.length < 153) return description;
  return `${description.slice(0, 153)} ...`;
};
const DescriptionText = ({ setHovered }: descTextProps) => {
  const { workRequestSelected } = useWorkRequestsContext();
  const [isShort, setIsShort] = useState(true);

  const handleMouse = () => {
    if (isShort) return setHovered(true);
    return setHovered(false);
  };
  return (
    <Stack alignItems="flex-start">
      <Box onClick={() => setHovered(true)} onMouseEnter={handleMouse} width="205px">
        {isShort ? (
          <Typography
            color="white"
            variant="body2"
            textAlign="right"
            sx={{
              wordWrap: 'break-word',
              textAlign:
                workRequestSelected.workRequestDescription &&
                workRequestSelected.workRequestDescription.length > 33
                  ? 'justify'
                  : 'right',
            }}
          >
            {PrepareDescription(workRequestSelected.workRequestDescription)}
          </Typography>
        ) : (
          <Typography
            color="white"
            variant="body2"
            textAlign="right"
            sx={{ wordWrap: 'break-word', textAlign: 'justify' }}
          >
            {workRequestSelected.workRequestDescription}
          </Typography>
        )}
      </Box>
      {workRequestSelected.workRequestDescription &&
        workRequestSelected.workRequestDescription.length >= 153 && (
          <ShowMoreButton isShort={isShort} setIsShort={setIsShort} />
        )}
    </Stack>
  );
};

export default DescriptionText;
