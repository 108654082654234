/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';

import ReactFlow, { ReactFlowProvider } from 'react-flow-renderer';

import { useAssemblyEditorContext } from './AssemblyEditorContext';

const MainProvider = ({ nodeTypes, edgeTypes }) => {
  const [moreMenuOpen, setMoreMenuOpen] = useState(false);
  const {
    onDrop,
    elements,
    setElements,
    mainProviderWrapper,
    onLoad,
    onConnect,
    onElementsRemove,
    updateElements,
    setUpdateElements,
    setIdPropertyOpen,
  } = useAssemblyEditorContext();

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  };

  const onNodeDragStop = (_event, { id, position: { x, y } }) => {
    if (updateElements?.prevNodes?.find((prevNode) => prevNode.id === id))
      setUpdateElements((current) => ({
        ...current,
        updateNodes: [
          ...current.updateNodes.filter((updateNode) => updateNode.id !== id),
          {
            ...current.updateNodes.find((updateNode) => updateNode.id === id),
            id,
            position: { x, y },
            positionX: x,
            positionY: y,
          },
        ],
      }));

    if (updateElements?.addNodes?.find((addNode) => addNode.id === id))
      setUpdateElements((current) => ({
        ...current,
        addNodes: [
          ...current.addNodes.filter((updateNode) => updateNode.id !== id),
          {
            ...current.addNodes.find((updateNode) => updateNode.id === id),
            position: { x, y },
            positionX: x,
            positionY: y,
          },
        ],
      }));

    setElements((prev) => [...prev].map((el) => (el.id === id ? { ...el, position: { x, y } } : { ...el })));
  };

  const onSelectionChange = (element) => {
    if (element === null) {
      setIdPropertyOpen(null);
    }
  };

  const onElementClick = (event, element) => {
    const target = event?.target;
    const parentElement = target?.parentElement;
    const grandparentElement = parentElement?.parentElement;
    const isMenuOption = target.role === 'menuitem';

    if (isMenuOption) return;

    const isOMoreMenuClicked =
      target?.name === 'open-more-menu' ||
      parentElement?.name === 'open-more-menu' ||
      grandparentElement?.name === 'open-more-menu';

    if (isOMoreMenuClicked) {
      setMoreMenuOpen(true);
      return;
    }

    if (moreMenuOpen) {
      setMoreMenuOpen(false);
      return;
    }

    setIdPropertyOpen(element.id);
  };

  return (
    <div style={{ width: '100%', height: '100%' }} ref={mainProviderWrapper}>
      <ReactFlowProvider>
        <ReactFlow
          elements={elements}
          elementsSelectable
          connectionMode="loose"
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          onElementsRemove={onElementsRemove}
          onConnect={onConnect}
          onDrop={onDrop}
          onDragOver={onDragOver}
          onLoad={onLoad}
          selectNodesOnDrag={false}
          onNodeDragStop={onNodeDragStop}
          onElementClick={onElementClick}
          onSelectionChange={onSelectionChange}
          zoomOnScroll
        />
      </ReactFlowProvider>
    </div>
  );
};

export default MainProvider;
