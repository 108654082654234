import React from 'react';

import { StatusBadge } from '@evolvemep/foresite-components-ui-react';
import dayjs from 'dayjs';

import { BADGE_MAPPING_STATUS, StatusKey } from 'constants/badgeMappingStatus';
import { isDateBeforeToday } from 'helpers/dateFunctions';

type Props = {
  variant: StatusKey;
  date: string;
};

const Status = ({ date, variant }: Props) => {
  const newStatus = BADGE_MAPPING_STATUS[variant];
  const statusVariant = isDateBeforeToday(dayjs(`${date}Z`)) ? 'pastDaysRemaining' : newStatus?.variant;

  return (
    <>
      {newStatus && (
        <StatusBadge
          variant={statusVariant}
          label={newStatus?.label}
          sx={{ height: '20px', mt: '4px', ml: 4 }}
          data-testid="task-status"
        />
      )}
    </>
  );
};

export default Status;
