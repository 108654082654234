import React from 'react';

import { Popover, Stack } from '@mui/material';

import CreatePresetForm from './components/CreatePresetForm';
import FilterChips from './components/FilterChips';
import FilterFooterButton from './components/FilterFooterButton';
import FilterForm from './components/FilterForm';
import PresetsDropdown from './components/PresetsDropdown';
import FilterPopoverContent from './FilterPopoverContent';
import FilterPopoverFooter from './FilterPopoverFooter';
import FilterPopoverHeader from './FilterPopoverHeader';

const FilterPopover = ({ children, open, onClose, anchorEl, id, ...props }) => (
  <Popover
    data-testid="filter-popover"
    id={id}
    open={open}
    onClose={onClose}
    anchorEl={anchorEl}
    PaperProps={{
      elevation: 12,
    }}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  >
    <Stack
      sx={{
        flexDirection: 'column',
        gap: '16px',
        flexWrap: 'wrap',
        margin: '16px 24px',
        width: '688px',
      }}
    >
      {children}
    </Stack>
  </Popover>
);
// Popover layout components
FilterPopover.Header = FilterPopoverHeader;
FilterPopover.Content = FilterPopoverContent;
FilterPopover.Footer = FilterPopoverFooter;

// Filters components
FilterPopover.PresetsDropdown = PresetsDropdown;
FilterPopover.FilterChips = FilterChips;
FilterPopover.FilterForm = FilterForm;
FilterPopover.CreatePresetForm = CreatePresetForm;
FilterPopover.FilterFooterButton = FilterFooterButton;

export default FilterPopover;
