import React, { useState } from 'react';

import { usePopover } from '../../PopoverProvider';
import { getElementId } from '../HelperElements';
import Item from './LocationsItem';

const LocationsListItem = ({ depth, item, renderProps, fetchLocations, parentIds = [] }) => {
  const { updateParentId } = usePopover();
  const [isOpen, setIsOpen] = useState(false);
  const render = isOpen && item.hasChildren;
  const handleClick = () => {
    setIsOpen(() => !isOpen);
    updateParentId(() => getElementId(item));
    fetchLocations();
  };
  return (
    <>
      <Item
        depth={depth}
        item={item}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleClick={handleClick}
        parentIds={parentIds}
      />
      {render && renderProps()}
    </>
  );
};

export default LocationsListItem;
