import React from 'react';

import { useNavigate } from 'react-router-dom';

import { Custom } from 'components/Icons';
import { InterfaceSelectorWorkRequest } from 'components/InterfaceSelectorWorkRequest';
import useSetupModule from 'hooks/useSetupModule';
import { actionColors } from 'styles/theme/colors';

import { useWorkRequestsContext } from './WorkRequestsContext';
import WorkRequestsListContainer from './WorkRequestsList/WorkRequestsListContainer';

const WorkRequestsAndProperties = ({ filterComponent = null }) => {
  const { workRequestSelected } = useWorkRequestsContext();
  const navigate = useNavigate();
  const { currentModule } = useSetupModule();
  const options = [
    {
      title: 'Work Requests',
      description: 'Single pane view',
      icon: <Custom.WorkRequests fill={actionColors.disabled} />,
      onClick: () => {},
    },
    {
      title: 'Work Requests + Drawing Register',
      description: 'Two pane view',
      icon: <Custom.DrawingRegister />,
      onClick: () => {
        navigate({
          pathname: `/${currentModule()}/drawing-register`,
          search: '&nav=work-requests',
        });
      },
    },
  ];
  return (
    <>
      {!workRequestSelected && <InterfaceSelectorWorkRequest options={options} />}
      <WorkRequestsListContainer workRequestSelected={workRequestSelected} filterComponent={filterComponent} />
    </>
  );
};

export default WorkRequestsAndProperties;
