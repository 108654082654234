import React from 'react';

import Stack from '@mui/material/Stack';

import { Custom } from 'components/Icons';
import { greyColors } from 'styles/theme/colors';

import { IPropertiesOverlayImageProps } from './@types/PropertiesOverlayTypes';

const PropertiesOverlayImage: React.FC<IPropertiesOverlayImageProps> = ({ image, partName }) => (
  <Stack
    sx={{
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: greyColors.grey200,
      width: '164px',
      minHeight: '164px',
      borderRadius: '4px',
    }}
  >
    {image ? (
      <img src={image} alt={partName} width="100%" />
    ) : (
      <Custom.PartThumbnail width={80} fill={greyColors.grey400} />
    )}
  </Stack>
);

export default PropertiesOverlayImage;
