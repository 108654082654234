import React, { useMemo } from 'react';

import { gql, useMutation } from '@apollo/client';
import { CircularProgress, useTheme } from '@mui/material';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import AttachmentsTable from 'components/AttachmentsTable/AttachmentsTable';
import { deleteTaskDocument as DELETE_DOCUMENT } from 'graphql/mutations';
import {
  documents as DOCUMENTS,
  taskMultiSearch as TASK_MULTI_SEARCH,
  taskDocument as TASK_DOCUMENTS,
  workOrders as WORK_ORDERS,
} from 'graphql/queries';
import useTasksMultiSearchAPI from 'hooks-api/useTasksMultiSearchAPI';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import { useWorkRequestsContext } from 'modules/Field/WorkRequests/WorkRequestsContext';
import { handleSuccessMessage } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestListFlyoutMenu/WorkRequestAttachments/AttachmentList';
import {
  document,
  workOrder,
} from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestListFlyoutMenu/WRflyoutTypes';
import useDocumentTypes from 'modules/Shop/Fabrication/TaskViewer/PlansModelsContent/hooks/useDocumentTypes';

import useFetchTasksDocuments from '../../../../../hooks/useTaskDocuments';
import TasksAttachType from './TasksAttachType';
import { updateCacheDeletingWorkOrderOrTaskAttachment } from './WorkOrderAttachmentList';

const getRefetchQueries = ({ workOrderSelected, attachment, selectedItem }: any) => [
  {
    query: gql(TASK_MULTI_SEARCH),
    variables: {
      query: {
        workRequestId: workOrderSelected?.workRequestId,
      },
    },
  },
  {
    query: gql(DOCUMENTS),
    variables: {
      query: {
        documentIds: attachment?.documentId,
      },
    },
  },
  {
    query: gql(TASK_DOCUMENTS),
    variables: {
      query: {
        taskDocumentId: attachment?.taskDocumentId,
      },
    },
  },
  {
    query: gql(WORK_ORDERS),
    variables: {
      query: {
        facilityId: selectedItem?.id,
      },
    },
  },
];

const TaskAttachmentList = ({
  workOrderRow,
  darkMode = true,
  canDelete = true,
}: {
  workOrderRow?: workOrder;
  darkMode?: boolean;
  canDelete?: boolean;
}) => {
  const { commonTypeId } = useDocumentTypes();
  const { palette } = useTheme();
  const { updateWRList, setUpdateWRList, workOrderSelected } = useWorkRequestsContext();
  const workOrder = workOrderRow || workOrderSelected;
  const { handleResponse } = useGraphqlResponseHandler();
  const [deleteTaskDocument, { loading }] = useMutation(gql(DELETE_DOCUMENT));
  const { selectedItem } = useFacilitiesProjects();
  const tasksParams = useMemo(
    () => ({
      workRequestId: workOrder?.workRequestId,
    }),
    [workOrder?.workRequestId],
  );
  const { tasks, loadingTasks } = useTasksMultiSearchAPI(tasksParams);

  const taskIds = useMemo(() => (tasks?.length ? tasks.map((task) => task.taskId) : []), [tasks]);
  const {
    tasksDocuments,
    loading: loadingDocuments,
    paginationWaypoint,
  } = useFetchTasksDocuments(taskIds, commonTypeId, true);

  const handleDelete = async (attachment: document) => {
    await handleResponse(
      deleteTaskDocument,
      {
        variables: { params: { taskDocumentId: attachment?.taskDocumentId } },
        update(cache: any) {
          updateCacheDeletingWorkOrderOrTaskAttachment(cache);
        },
        refetchQueries: getRefetchQueries({ workOrderSelected, attachment, selectedItem }),
      },
      { successMessage: handleSuccessMessage(attachment) },
    );
    setUpdateWRList(!updateWRList);
  };

  if ((loadingDocuments || loadingTasks) && !tasksDocuments?.length)
    return (
      <CircularProgress
        size={32}
        sx={{ color: darkMode ? palette.secondary.mainDarkBackground : palette.secondary.main }}
      />
    );
  if (!tasksDocuments.length) return null;
  return (
    <>
      <TasksAttachType darkMode={darkMode} />
      <AttachmentsTable
        attachments={tasksDocuments}
        handleDelete={canDelete ? handleDelete : undefined}
        loadingDelete={loading}
        darkMode={darkMode}
        paginationWaypoint={paginationWaypoint}
      />
      {loadingDocuments && tasksDocuments?.length && (
        <CircularProgress
          size={32}
          sx={{ color: darkMode ? palette.secondary.mainDarkBackground : palette.secondary.main }}
        />
      )}
    </>
  );
};

export default TaskAttachmentList;
