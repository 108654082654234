import React, { useContext, useState } from 'react';

import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import { Modal } from 'components';
import { Custom } from 'components/Icons';
import OverflowTypography from 'components/Tooltip/OverflowTypography';
import { WorkOrderItemsContext } from 'modules/Shop/WorkOrders/WorkOrder/context/WorkOrderItemsContext';

import { WORK_REQUEST_ITEMS_VIEWS, WorkRequestItemsContext } from '../../WorkRequestItemsContext';
import useDRWorkRequests from './useDRWorkRequests';

const MappedDrawingDocs = ({ props }) => {
  const { mappedDocs, taskDocuments, noDocuments, unmapDocument, deleting } = useDRWorkRequests({
    props,
  });

  const workRequestItemsContext = useContext(WorkRequestItemsContext);
  const workOrderItemsContext = useContext(WorkOrderItemsContext);

  const { setCurrentView, setActiveDocument, workRequestStatus } = workRequestItemsContext || workOrderItemsContext;

  const showPinIcon = workRequestStatus !== 'Completed';

  if (noDocuments) return null;

  if (!mappedDocs) return <Progress />;

  const onClickDocument = (document) => {
    setCurrentView(WORK_REQUEST_ITEMS_VIEWS.FORGE_VIEW);
    setActiveDocument(document);
  };

  return (
    <>
      {mappedDocs?.map((document) => (
        <MappedDocumentsRow
          key={document?.documentId}
          doc={document}
          unmapDocument={unmapDocument}
          taskDocuments={taskDocuments}
          loading={deleting}
          onClickDocument={onClickDocument}
          showPinIcon={showPinIcon}
        />
      ))}
    </>
  );
};

export default MappedDrawingDocs;

const MappedDocumentsRow = ({ doc, unmapDocument, taskDocuments, loading, onClickDocument, showPinIcon }) => {
  const theme = useTheme();
  const [showPin, setShowPin] = useState(false);
  const [openUnmapModal, setOpenUnmapModal] = useState(false);
  const { documentId, documentIdentifier, documentName } = doc;
  const taskDoc = taskDocuments?.taskDocument.find((obj) => obj.documentId === documentId);
  return (
    <Box bgcolor="white" onMouseEnter={() => setShowPin(true)} onMouseLeave={() => setShowPin(false)}>
      <Stack
        direction="row"
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: '32px',
          paddingLeft: '200px',
          borderBottom: '1px solid rgba(232, 232, 232, 1)',
          ' :hover': {
            background: theme?.palette?.action?.hover,
          },
        }}
      >
        <Custom.SmallDrawingDocument fill={theme?.palette?.secondary?.main} width={25} />
        <OverflowTypography title={documentIdentifier || '---'} sx={{ width: 70, fontSize: 14 }} />
        <OverflowTypography
          title={documentName}
          onClick={() => onClickDocument(doc)}
          sx={{
            width: '100%',
            fontSize: 14,
            color: 'secondary.main',
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        />
        <Box
          sx={{
            display: showPin && showPinIcon ? 'block' : 'none',
            margin: '0 24px 0 auto',
            cursor: 'pointer',
          }}
          onClick={() => setOpenUnmapModal(true)}
        >
          <Custom.MachineUnmap />
        </Box>
      </Stack>
      <Modal
        title="Confirm unmap"
        open={openUnmapModal}
        onClose={() => setOpenUnmapModal(false)}
        footer={
          <ModalFooter action={unmapDocument} closeModal={setOpenUnmapModal} taskObj={taskDoc} loading={loading} />
        }
      >
        <>
          Are you sure you want to unmap{' '}
          <b>
            {documentIdentifier} {documentName}
          </b>
          ?
        </>
      </Modal>
    </Box>
  );
};

const ModalFooter = ({ action, closeModal, taskObj, loading }) => (
  <Stack direction="row">
    <Button sx={{ marginRight: '8px' }} onClick={() => closeModal(false)}>
      Cancel
    </Button>
    <Button variant="contained" color="error" onClick={() => action(taskObj?.taskDocumentId)} disabled={loading}>
      Confirm
    </Button>
  </Stack>
);

const Progress = () => (
  <Box bgcolor="white" width="100%">
    <CircularProgress size={25} sx={{ margin: '0 16px' }} />
  </Box>
);
