/* eslint-disable max-lines-per-function */
import React, { useState, useContext, useEffect } from 'react';

import { gql, useLazyQuery, useMutation } from '@apollo/client';

import { addPartAttribute as ADD_ATTRIBUTE } from 'graphql/mutations';
import {
  partCategoryById as PART_CATEGORY_BY_ID,
  partAttributes as ATTRIBUTES,
} from 'graphql/queries';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import useLazyPaginatedQuery from 'hooks/useLazyPaginatedQuery';

import { SELECTED_CATEGORY, UNPUBLISHED_PART } from '../AssemblyEditor/Utils/constants';

export const CatalogContext = React.createContext();

export const CatalogProvider = ({ children }) => {
  const [activeInput, setActiveInput] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [getCategory] = useLazyQuery(gql(PART_CATEGORY_BY_ID));

  const [partContext, setPartContext] = useState({
    catalogInfo: {
      partCatalogById: {
        companyId: '',
        partCatalogId: '',
        partCatalogName: '',
        topLevelNodes: [],
      },
    },
    renderList: [],
    activeCategories: {},
    parentCategoryId: [],
    onEdition: { element: {}, elementIndex: 0 },
    selectedCategoryId: null,
  });

  useEffect(() => {
    if (partContext?.selectedCategoryId == null) return;

    const fetchCategory = async () => {
      const category = await getCategory({
        variables: { params: { id: partContext?.selectedCategoryId } },
      });
      setSelectedCategory(category.data.partCategoryById);
    };

    fetchCategory();
  }, [partContext?.selectedCategoryId, getCategory]);

  const [modalActiveCategoryId, setActiveModalCategoryId] = useState(null);
  const [attributesModalOpen, setAttributesModalOpen] = useState(false);
  const [attributes, setAttributes] = useState([]);
  const [selectedAttribute, setSelectedAttribute] = useState(null);
  const [variantToAdd, setVariantToAdd] = useState(null);
  const { handleResponse } = useGraphqlResponseHandler();
  const handleAddNewVariant = () => {
    setVariantToAdd(selectedAttribute);
  };
  useEffect(() => {
    localStorage.removeItem(SELECTED_CATEGORY);
    localStorage.removeItem(UNPUBLISHED_PART);
  }, []);
  const [
    { lazyLoad, paginationHandler: getAttributesPaginationHandler },
    { data: getAttributes, loading: getAttributesLoading },
  ] = useLazyPaginatedQuery(gql(ATTRIBUTES), 'network-only');
  const [addAttributeMutation, { loading: addAttributeLoading }] = useMutation(gql(ADD_ATTRIBUTE));

  useEffect(() => {
    setSelectedAttribute(null);
    setAttributes([]);

    if (partContext?.selectedCategoryId == null) return;

    lazyLoad({
      partCategoryId: partContext.selectedCategoryId,
    });
  }, [partContext.selectedCategoryId, lazyLoad]);

  useEffect(() => {
    if (partContext?.selectedCategoryId == null) {
      setAttributes([]);
      return;
    }

    if (getAttributes?.partAttributes) {
      setAttributes(getAttributes.partAttributes);
    }
  }, [getAttributes, getAttributesLoading, partContext?.selectedCategoryId]);

  const createAttributes = async ({ values = [], successCallback = () => {} }) => {
    const LIMIT = 10;
    if (partContext?.catalogInfo?.partCatalogById?.partCatalogId && modalActiveCategoryId) {
      const variants = values?.variantCodes?.map((item) => ({
        textValue: item.label,
        codeValue: item.value,
      }));
      await handleResponse(
        addAttributeMutation,
        {
          variables: {
            body: {
              partCatalogId: partContext.catalogInfo.partCatalogById.partCatalogId,
              partCategoryId: modalActiveCategoryId,
              partAttributeTypeId: values?.attributeType,
              partAttributeName: values?.attributeName,
              allowWriteInVariant: values?.allowWriteIn,
              addPartAttributeSelectVariantRequests: variants,
            },
          },

          update(cache, { data: attributeData }) {
            const data = cache.readQuery({
              query: gql(ATTRIBUTES),
              variables: {
                query: {
                  partCategoryId: modalActiveCategoryId,
                  take: LIMIT,
                },
              },
            });
            if (data?.partAttributes && attributeData?.addPartAttribute) {
              const updatedData = {
                partAttributes: [...data.partAttributes, attributeData.addPartAttribute],
              };
              cache.writeQuery({
                query: gql(ATTRIBUTES),
                data: updatedData,
                variables: {
                  query: {
                    partCategoryId: modalActiveCategoryId,
                    take: LIMIT,
                  },
                },
              });
            }
            if (attributeData?.addPartAttribute) {
              setPartContext((prev) => ({
                ...prev,
                selectedCategoryId: modalActiveCategoryId,
              }));
              setSelectedAttribute(attributeData.addPartAttribute);

              successCallback();
            }
          },
        },
        {
          successMessage: 'Attribute successfully added',
        },
      );
    }
  };

  return (
    <CatalogContext.Provider
      value={{
        partContext,
        setPartContext,
        activeInput,
        setActiveInput,
        selectedCategory,
        attributesModalOpen,
        setAttributesModalOpen,
        modalActiveCategoryId,
        setActiveModalCategoryId,
        attributes,
        getAttributesPaginationHandler,
        selectedAttribute,
        setSelectedAttribute,
        handleAddNewVariant,
        variantToAdd,
        setVariantToAdd,
        createAttributes,
        addAttributeLoading,
      }}
    >
      {children}
    </CatalogContext.Provider>
  );
};

export const useCatalogContext = () => {
  const context = useContext(CatalogContext);
  if (context === undefined) {
    throw new Error('useCatalogContext must be used within a CatalogSetupProvider');
  }
  return context;
};
