import React from 'react';

import { Stack } from '@mui/material';

import PartItemDetailAccordionContent from './PartItemDetailAccordionContent';
import PartItemDetailAccordionHeader from './PartItemDetailAccordionHeader';
import PartItemDetailAccordionIcon from './PartItemDetailAccordionIcon';
import PartItemDetailAccordionSubtitle from './PartItemDetailAccordionSubtitle';
import PartItemDetailAccordionTitle from './PartItemDetailtAccordionTitle';

const PartItemDetailAccordion = ({ children, sx }) => (
  <Stack
    direction="column"
    sx={{
      ...sx,
    }}
  >
    {children}
  </Stack>
);

PartItemDetailAccordion.Content = PartItemDetailAccordionContent;
PartItemDetailAccordion.Header = PartItemDetailAccordionHeader;
PartItemDetailAccordion.Icon = PartItemDetailAccordionIcon;
PartItemDetailAccordion.Title = PartItemDetailAccordionTitle;
PartItemDetailAccordion.Subtitle = PartItemDetailAccordionSubtitle;

export default PartItemDetailAccordion;
