import { useEffect, useState } from 'react';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { useLazyWorkOrdersCount } from 'hooks-api/queries/workOrdersCount/useLazyWorkOrdersCount';
import { useQueryWorkOrderStatusesList } from 'hooks-api/queries/workOrderStatusesList/useQueryWorkOrderStatusesList';

import { getStatusesIds } from './WorkOrdersKanbanHelpers';

const useWorkOrdersKanban = () => {
  const { data } = useQueryWorkOrderStatusesList({
    options: { fetchPolicy: 'cache-first' },
  });
  const [hasWorkOrders, setHasWorkOrders] = useState<boolean>(false);

  const { fetchWorkOrderCount, loading, called } = useLazyWorkOrdersCount({
    options: { fetchPolicy: 'network-only' },
  });

  const { selectedItem, shopDepartmentId } = useFacilitiesProjects();

  useEffect(() => {
    if (!data || loading) return;
    const getData = async () => {
      const variables = {
        query: {
          facilityId: selectedItem?.id,
          workOrderStatusIds: getStatusesIds(data),
          take: 1,
          skip: 0,
        },
      };

      const workOrdersCount = await fetchWorkOrderCount(variables);
      const entireCount = workOrdersCount?.data?.workOrdersCount?.entireCount;
      setHasWorkOrders(entireCount ? entireCount > 0 : false);
    };
    getData();
  }, [hasWorkOrders, data, loading, fetchWorkOrderCount, selectedItem?.id]);

  const shouldDisplayErrorMessage = selectedItem?.type !== 'FACILITY' || !hasWorkOrders || !shopDepartmentId;

  return {
    hasWorkOrders,
    shouldDisplayErrorMessage,
    loadingCount: loading || !called,
  };
};

export default useWorkOrdersKanban;
