import React, { useState, useEffect, useMemo } from 'react';

import { Stack, CircularProgress } from '@mui/material';
import { GridSortModel } from '@mui/x-data-grid-pro';

import RowIcon from 'components/Table/RowIcon';
import { EIconType } from 'helpers/MenuIcons';
import useEvolveApi from 'hooks-api/useEvolveApi';
import type { EvolveApiReturn } from 'hooks-api/useWrappedApiCall';

import ItemsDataGridPro, { ItemsDataGridProProvider } from '../ItemsDatagridPro';
import useBOMItemsGroupColumns from './useBOMItemsGroupColumns';

type DetailsPanelProps = {
  workOrderId?: string;
  workRequestId?: string;
  workRequestItemId?: string;
  workOrderItemId?: string;
};
interface BOMItem {
  item: string;
  tradeSize: string;
  quantityRequired: number;
  displayQuantityRequired: number;
  unitOfMeasure: string;
  manufacturer: string;
  catalogNumber: string;
  itemType: string;
}

const BOMItemsGroup = ({ workOrderId, workRequestId, workRequestItemId, workOrderItemId }: DetailsPanelProps) => {
  const { get } = useEvolveApi();

  const [loading, setLoading] = useState(false);
  const [sortLoading, setSortLoading] = useState(false);
  const [openBOM, setOpenBOM] = useState(false);
  const [bomItems, setBomItems] = useState<BOMItem[]>([]);

  useEffect(() => {
    if (openBOM) {
      if (workOrderId) {
        setLoading(true);
        get<EvolveApiReturn<BOMItem>>('shop/workorder/GetBomItemsByWorkOrder', { params: { workOrderId } }).then(
          (res) => {
            if (res.data) setBomItems(res.data);
            setLoading(false);
          },
        );
      } else if (workRequestId) {
        setLoading(true);
        get<EvolveApiReturn<BOMItem>>('shop/workrequest/GetBomItemsByWorkRequest', { params: { workRequestId } }).then(
          (res) => {
            if (res.data) setBomItems(res.data);
            setLoading(false);
          },
        );
      } else if (workRequestItemId) {
        setLoading(true);
        get<EvolveApiReturn<BOMItem>>(`shop/WorkRequestItem/${workRequestItemId}/bom`).then((res) => {
          if (res.data) setBomItems(res.data);
          setLoading(false);
        });
      } else if (workOrderItemId) {
        setLoading(true);
        get<EvolveApiReturn<BOMItem>>(`shop/WorkOrderItem/${workOrderItemId}/bom`).then((res) => {
          if (res.data) setBomItems(res.data);
          setLoading(false);
        });
      }
    }
  }, [get, workOrderId, workRequestId, workOrderItemId, workRequestItemId, openBOM]);

  const onSortModelChange = (model: GridSortModel) => {
    if (!model.length) return;
    const orderBy = `${model[0].field}:${model[0].sort}`;
    if (workOrderId) {
      setSortLoading(true);
      get<EvolveApiReturn<BOMItem>>('shop/workorder/GetBomItemsByWorkOrder', {
        params: { workOrderId, orderBy },
      }).then((res) => {
        if (res.data) setBomItems(res.data);
        setSortLoading(false);
      });
    } else if (workRequestId) {
      setSortLoading(true);
      get<EvolveApiReturn<BOMItem>>('shop/workrequest/GetBomItemsByWorkRequest', {
        params: { workRequestId, orderBy },
      }).then((res) => {
        if (res.data) setBomItems(res.data);
        setSortLoading(false);
      });
    } else if (workRequestItemId) {
      get<EvolveApiReturn<BOMItem>>(`shop/WorkRequestItem/${workRequestItemId}/bom`, { params: { orderBy } }).then(
        (res) => {
          if (res.data) setBomItems(res.data);
          setSortLoading(false);
        },
      );
    } else if (workOrderItemId) {
      get<EvolveApiReturn<BOMItem>>(`shop/WorkOrderItem/${workOrderItemId}/bom`, { params: { orderBy } }).then(
        (res) => {
          if (res.data) setBomItems(res.data);
          setSortLoading(false);
        },
      );
    }
  };

  const rowStyles = useMemo(
    () => ({
      backgroundColor: 'white',
      padding: '0px 16px 0px 118px',
    }),
    [],
  );

  const columns = useBOMItemsGroupColumns({ sortLoading });

  return (
    <>
      {loading ? (
        <Stack
          sx={{
            ...rowStyles,
            alignItems: 'center',
            height: '31px',
            borderBottom: '1px solid rgba(224,224,224,1)',
          }}
          direction="row"
        >
          <CircularProgress color="secondary" size={20} />
        </Stack>
      ) : (
        <>
          <Stack
            onClick={() => setOpenBOM((prev) => !prev)}
            sx={{ ...rowStyles, borderBottom: '1px solid rgba(224,224,224,1)' }}
            direction="row"
          >
            <RowIcon
              label="Bill of Materials"
              icon={EIconType.BILL_OF_MATERIALS}
              opened={openBOM}
              onOpening={() => setOpenBOM((prev) => !prev)}
            />
          </Stack>
          {openBOM && bomItems.length > 0 && (
            <ItemsDataGridProProvider>
              <ItemsDataGridPro
                rows={sortLoading ? [{}] : bomItems}
                getRowId={(row: any) => JSON.stringify(row)}
                rowHeight={31}
                headerHeight={31}
                columns={columns}
                autoHeight
                sx={{
                  border: 0,
                  borderRadius: 0,
                  backgroundColor: 'white',
                }}
                checkboxSelection={false}
                hideFooterPagination
                disableColumnMenu
                onSortModelChange={onSortModelChange}
              />
            </ItemsDataGridProProvider>
          )}
        </>
      )}
    </>
  );
};

export default BOMItemsGroup;
