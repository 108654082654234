import Auth from '@aws-amplify/auth';

export const checkValidation = async ({ username, code, setIsConfirmed }) => {
  try {
    const response = await Auth.confirmSignUp(username, code);

    if (response === 'SUCCESS') {
      setIsConfirmed(true);
    }
  } catch (error) {
    setIsConfirmed(false);
  }
};
