import React from 'react';

import Stack from '@mui/material/Stack';

import { darkSurfaceScrollBarStyle } from 'styles/theme/scrollBarStyle';

import { IPropertiesOverlayBodyProps } from './@types/PropertiesOverlayTypes';

const PropertiesOverlayBody: React.FC<IPropertiesOverlayBodyProps> = ({ children }) => (
  <Stack
    direction="column"
    gap="24px"
    sx={{
      overflowY: 'scroll',
      flex: 1,
      ...darkSurfaceScrollBarStyle,
    }}
    className="nowheel"
  >
    {children}
  </Stack>
);

export default PropertiesOverlayBody;
