import React from 'react';

import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface LicenseFilterProps {
  disabled?: boolean;
  data: Array<{ value: string; title: string }>;
  onChange: (event: SelectChangeEvent<string>, child: React.ReactNode) => void;
  value: string | undefined;
}

const LicenseFilter: React.FC<LicenseFilterProps> = ({ disabled, data, onChange, value }) => (
  <FormControl size="small" sx={{ width: '130px' }}>
    <InputLabel id="license-filter-label" data-testid="license-filter-label">
      License
    </InputLabel>
    <Select
      label="License"
      labelId="license-filter-label"
      id="license-filter"
      value={value}
      onChange={onChange}
      disabled={disabled}
      inputProps={{ 'data-testid': 'license-filter' }}
      MenuProps={{
        id: 'license-filter-menu',
        // @ts-ignore
        'data-testid': 'license-filter-menu',
      }}
      sx={{ width: '130px' }}
    >
      <MenuItem value="all-licenses">All Licenses</MenuItem>
      <Divider />
      <MenuItem value="no-license">No License</MenuItem>

      {data.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.title}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default LicenseFilter;
