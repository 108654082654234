import {
  ADD_EDGE_PARAMS,
  ASSEMBLY_EDGE_TYPE_ID,
  ASSEMBLY_NODE_ASSEMBLY_TYPE_ID,
  ASSEMBLY_NODE_PART_TYPE_ID,
  ASSEMBLY_NODE_SHOP_TASK_TYPE_ID,
  EDGE_TYPE_ID_DICT,
  NEW_PART_ITEM_ID,
} from './constants';

export const createAssemblyBody = (publishedData = [], mainPartId) => {
  if (!publishedData?.length) return null;
  const body = {};
  const partData = (part) => {
    if (!body.nodes) body.nodes = [];
    body.nodes.push({
      // this is only for PARTS
      referenceId: part.id,
      assemblyNodeTypeId: ASSEMBLY_NODE_PART_TYPE_ID,
      assemblyNodeName: part.data.partName,
      assemblyNodeDescription: part.data.description,
      positionX: parseInt(part.position.x, 10),
      positionY: parseInt(part.position.y, 10),
      partId: part.data.partId,
      quantity: 1,
    });

    return (body.partId = part.data.partId !== NEW_PART_ITEM_ID ? part.data.partId : part.data);
  };
  const nodeData = ({ data, ...node }) => {
    if (!body.nodes) body.nodes = [];
    if (data.taskTypeName) {
      body.nodes.push({
        //! this is only for SHOP TASK NODES
        referenceId: node.id,
        assemblyNodeTypeId: ASSEMBLY_NODE_SHOP_TASK_TYPE_ID,
        assemblyNodeName: data.taskTypeName,
        assemblyNodeDescription: data.taskTypeDescription,
        positionX: parseInt(node.position.x, 10),
        positionY: parseInt(node.position.y, 10),
        shopTaskId: data.taskTypeId,
      });
      return;
    }
    body.nodes.push({
      //! this is only for PART NODES
      referenceId: node.id,
      assemblyNodeTypeId: ASSEMBLY_NODE_PART_TYPE_ID,
      assemblyNodeName: data.partName,
      positionX: parseInt(node.position.x, 10),
      positionY: parseInt(node.position.y, 10),
      partId: data.partId,
      quantity: data.qty,
    });
  };
  const edgeData = (edge) => {
    if (!body.edges) body.edges = [];
    body.edges.push({
      beginNodeReferenceId: edge.source,
      endNodeReferenceId: edge.target,
      assemblyEdgeTypeId: ASSEMBLY_EDGE_TYPE_ID,
      beginHandlePositionId: EDGE_TYPE_ID_DICT[edge.sourceHandle],
      endHandlePositionId: EDGE_TYPE_ID_DICT[edge.targetHandle],
    });
  };
  const bodyDict = {
    part: partData,
    node: nodeData,
    edge: edgeData,
  };
  const dictConditions = (item = {}) => {
    if (!item.data) return 'edge';
    if (item.data.partId === mainPartId) return 'part';
    return 'node';
  };
  publishedData.forEach((item) => {
    bodyDict[dictConditions(item)](item);
  });
  return body;
};

const getKeyByValue = (object, value) => Object.keys(object).find((key) => object[key] === value);

export const convertAssemblyElements = (nodes = [], edges = [], mainPartId) => {
  const { a, b } = EDGE_TYPE_ID_DICT;
  const nodeTypesDict = {};
  nodeTypesDict[ASSEMBLY_NODE_SHOP_TASK_TYPE_ID] = 'taskType';
  nodeTypesDict[ASSEMBLY_NODE_PART_TYPE_ID] = 'partType';
  nodeTypesDict[ASSEMBLY_NODE_ASSEMBLY_TYPE_ID] = 'assemblyType';
  const assemblyNodes = nodes.map((node) => ({
    id: node.assemblyNodeId,
    type:
      node.part?.partId === mainPartId
        ? 'assemblyType' // !THIS IS PROVISIONAL: While the Assembly node type is created in the BE
        : nodeTypesDict[node.assemblyNodeType.assemblyNodeTypeId],
    key: node.assemblyNodeId,
    position: {
      x: node.positionX,
      y: node.positionY,
    },
    data: node.part
      ? {
          ...node.part,
          partName: node.part.partName,
          description: node.assemblyNodeDescription,
          ...(node.quantity ? { quantity: node.quantity } : {}),
        }
      : {
          id: node.shopTaskId,
          key: node.assemblyNodeId,
          taskTypeId: node.shopTaskId,
          taskTypeDescription: node.assemblyNodeDescription,
          taskTypeName: node.assemblyNodeName,
        },
  }));
  const assemblyEdges = edges.map((edge) => ({
    id: edge.assemblyEdgeId,
    source: edge.beginNode.assemblyNodeId,
    sourceHandle: getKeyByValue(EDGE_TYPE_ID_DICT, edge.beginHandlePosition?.assemblyHandlePositionId || b),
    target: edge.endNode.assemblyNodeId,
    targetHandle: getKeyByValue(EDGE_TYPE_ID_DICT, edge.endHandlePosition?.assemblyHandlePositionId || a),
    ...ADD_EDGE_PARAMS,
  }));
  return { assemblyNodes, assemblyEdges };
};

export const isCreatingInfiniteLoop = (currentElements = [], edgeParams = {}) => {
  const initialPreviousEdgesConnected = [...currentElements].filter((el) => el.target === edgeParams.source);
  const allPreviousEdgesConnected = [];
  const getAllPreviousEdgesConnected = (connectedEdge) => {
    const allConnectedEdges = [connectedEdge];
    const newPreviousEdgesConnected = [...currentElements].filter((el) => el.target === connectedEdge.source);
    if (!newPreviousEdgesConnected.length) {
      return allConnectedEdges;
    }
    newPreviousEdgesConnected.forEach((prevEdge) => {
      allConnectedEdges.push(...getAllPreviousEdgesConnected(prevEdge));
    });
    return allConnectedEdges;
  };

  if (initialPreviousEdgesConnected.length) {
    initialPreviousEdgesConnected.forEach((edge) => {
      allPreviousEdgesConnected.push(...getAllPreviousEdgesConnected(edge));
    });
  }
  return Boolean(allPreviousEdgesConnected.filter((edge) => edge.source === edgeParams.target).length);
};

export const isUniqueEdge = (currentElements, edgeParams) =>
  Boolean(
    ![...currentElements].filter((el) => el.target === edgeParams.target && el.source === edgeParams.source)?.length,
  );

export const getNodesWithModifiedAssembly = (assemblyNodes, newPartElements, part) => {
  const nodesWithoutAssembly = assemblyNodes.filter((node) => node.data?.partId !== part.partId);
  const assemblyItemFromNodes = assemblyNodes.find((node) => node.data?.partId === part.partId);
  const newNodes = [
    { ...assemblyItemFromNodes, data: { ...assemblyItemFromNodes?.data, isMainPartItem: true } },
    ...nodesWithoutAssembly,
  ];
  if (!part.isFormModified) return { nodes: newNodes, updatedAssemblyItemNode: null };
  const updatedAssemblyItemNode = {
    ...assemblyItemFromNodes,
    ...newPartElements[0],
    position: assemblyItemFromNodes.position,
    id: assemblyItemFromNodes.id,
  };
  return { nodes: [...nodesWithoutAssembly, updatedAssemblyItemNode], updatedAssemblyItemNode };
};

export const getNewUpdateElementsWithModifiedNode = ({ updateElements, newUpdatedElement, current }) => {
  const isPrevNode = updateElements?.prevNodes?.find((el) => el.id === newUpdatedElement.id);
  if (isPrevNode)
    return {
      ...current,
      updateNodes: [...(current?.updateNodes?.filter((el) => el.id !== newUpdatedElement.id) ?? []), newUpdatedElement],
    };
  return {
    ...current,
    addNodes: [...(current?.addNodes?.filter((el) => el.id !== newUpdatedElement.id) ?? []), newUpdatedElement],
  };
};
