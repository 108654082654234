import { useCallback, useState } from 'react';

import { useLazyQuery, gql } from '@apollo/client';

import { partCategoryCloudSearch as PART_CATEGORIES_CLOUD_SEARCH } from 'graphql/queries';
import { stringifyCloudQuery } from 'helpers/stringFunctions';

const DEFAULT_QUERY_PARAMS = {
  parser: 'structured',
  sort: 'category_depth asc',
  queryOptions: encodeURIComponent(
    JSON.stringify({
      fields: ['category_id', 'category_name_for_searching', 'category_path', 'catalog_id', 'company_id'],
    }),
  ),
};

const PART_CATEGORY_CLOUD_SEARCH_LIMIT = 3;

const usePartCategoriesCloudSearchAPI = (catalogId = '') => {
  const [data, setData] = useState([]);

  const [fetchPartCategories, { loading }] = useLazyQuery(gql(PART_CATEGORIES_CLOUD_SEARCH), {
    fetchPolicy: 'no-cache',
  });

  const searchPartCategories = useCallback(
    async (queryString) => {
      const {
        data: { partCategoryCloudSearch },
      } = await fetchPartCategories({
        variables: {
          query: {
            query: stringifyCloudQuery(queryString, catalogId),
            skip: 0,
            take: PART_CATEGORY_CLOUD_SEARCH_LIMIT,
            ...DEFAULT_QUERY_PARAMS,
          },
        },
      });
      setData(partCategoryCloudSearch);
    },
    [catalogId, fetchPartCategories],
  );

  return { data, loading, searchPartCategories };
};

export default usePartCategoriesCloudSearchAPI;
