import React from 'react';

import { Stack } from '@mui/material';

const PartItemHeader = ({ children, ...restProps }) => (
  <Stack direction="row" sx={{ ...restProps, gap: '16px', marginBottom: '8px' }}>
    {children}
  </Stack>
);

export default PartItemHeader;
