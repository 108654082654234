/* eslint-disable import/no-cycle */
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';

import useGeneralContext from 'helpers/useGeneralContext';
import { useItemsIntegrationModalContext } from 'modules/Materials/components/ItemsIntegrationModal/context/ItemsIntegrationModalContext';
import { GeneralDictionary } from 'types/generalTypes';

import { IItemsIntegrationTable } from '../../@types/itemsIntegrationTable';
import {
  ItemsIntegrationItemType,
  LoadingRowsType,
  UseItemsIntegrationTableContextType,
} from '../../@types/itemsIntegrationTableContext';
import { QuantityFieldType } from '../components/QuantityField/hooks/@types/fieldHookTypes';
import { ADD_REMAINING_QUANTITIES_FIELD, ITEMS_INTEGRATION_TABLE_CONTEXT, PBS_FIELD } from '../constants';
import { useGetLoadingRows } from '../hooks/useGetLoadingRows';

const ItemsIntegrationTableContext = React.createContext<any>(ITEMS_INTEGRATION_TABLE_CONTEXT);

const ItemsIntegrationTableProvider: FC<IItemsIntegrationTable> = ({
  children,
  baseERN,
  addColumnHeader = 'Add',
  onItemsCreated = () => {},
  isBOMtoWR = false,
}: IItemsIntegrationTable) => {
  const [rows, setRows] = useState<ItemsIntegrationItemType[]>([]);
  const [loadingBOMRows, setLoadingBOMRows] = useState<LoadingRowsType>(null);
  const [hoveredRow, setHoveredRow] = useState<string>('');
  const [autoFilledFields, setAutoFilledFields] = useState<string[]>([]);
  const [maxNestLevel, setMaxNestLevel] = useState<any>(null);
  const methods = useFormContext();
  const [quantityFields, setQuantityFields] = useState<QuantityFieldType[]>([]);
  const [valuesBeforeAutofill, setValuesBeforeAutofill] = useState<any>('');
  const [fieldErrors, setFieldErrors] = useState<string[]>([]);
  const { setFocusedQtyField, setHoveredQtyField, setSelectedItems, selectedItemsProp, setIsLoading } =
    useItemsIntegrationModalContext();
  const [fieldsCalculating, setFieldsCalculating] = useState<GeneralDictionary<boolean>>({});

  const { isLoadingRows, isCalculatingFields, quantityFieldsByLineItem } = useGetLoadingRows({
    fieldsCalculating,
    loadingBOMRows,
    quantityFields,
  });

  const {
    formState: { isDirty },
    control,
  } = methods;
  const autoFill = useWatch({ control, name: ADD_REMAINING_QUANTITIES_FIELD });
  const projectBreakdownStructure = useWatch({ control, name: PBS_FIELD });

  const isReadyForTriggerValidation = useMemo<boolean>(
    () => !isLoadingRows && isDirty && !autoFill,
    [isLoadingRows, isDirty, autoFill],
  );

  const resetAllRows = useCallback(() => {
    setAutoFilledFields([]);
    setQuantityFields([]);
    setRows([]);
    setFieldErrors([]);
    setValuesBeforeAutofill('');
    setFocusedQtyField(null);
    setHoveredRow('');
    setHoveredQtyField(null);
    setSelectedItems([...selectedItemsProp]);
    setLoadingBOMRows(null);
    setIsLoading(false);
    setFieldsCalculating({});
  }, [setFocusedQtyField, setHoveredQtyField, setSelectedItems, selectedItemsProp, setIsLoading]);

  useEffect(() => {
    if (!quantityFields.length) return;
    setQuantityFields((current: QuantityFieldType[]) =>
      current.filter((field: QuantityFieldType) => (field.nestLevel as number) > 0),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectBreakdownStructure]);

  const valueObj = {
    addColumnHeader,
    isBOMtoWR,
    rows,
    methods,
    quantityFields,
    maxNestLevel,
    autoFilledFields,
    loadingBOMRows,
    hoveredRow,
    baseERN,
    valuesBeforeAutofill,
    quantityFieldsByLineItem,
    fieldErrors,
    isLoadingRows,
    isReadyForTriggerValidation,
    fieldsCalculating,
    isCalculatingFields,
    setFieldsCalculating,
    setFieldErrors,
    setRows,
    setQuantityFields,
    setMaxNestLevel,
    setAutoFilledFields,
    setLoadingBOMRows,
    setHoveredRow,
    setValuesBeforeAutofill,
    onItemsCreated,
    resetAllRows,
  };
  return <ItemsIntegrationTableContext.Provider value={valueObj}>{children}</ItemsIntegrationTableContext.Provider>;
};

const useItemsIntegrationTableContext: () => UseItemsIntegrationTableContextType = () =>
  useGeneralContext(ItemsIntegrationTableContext, 'ItemsIntegrationTableContext');

export { ItemsIntegrationTableContext, useItemsIntegrationTableContext, ItemsIntegrationTableProvider };
