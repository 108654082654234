import React from 'react';

import { ForesiteTextField } from '@evolvemep/foresite-components-ui-react';
import { useController, useFormContext } from 'react-hook-form';

import { getVariantCodes } from './AttributeSelectHelper';

const AttributeSelectCodeField = ({ attribute, sx, index, ...restProps }) => {
  const { control } = useFormContext();

  const {
    field: { onChange, ref, value },
    fieldState: { error },
    formState: { isSubmitting },
  } = useController({
    name: `partAttributeVariantData[${index}].code`,
    control,
    rules: {
      validate: (code) => {
        if (!code) {
          return true;
        }

        if (getVariantCodes(attribute).includes(code.toLowerCase().trim()))
          return 'Code already exists';
        return true;
      },
    },
  });

  const disabled = isSubmitting;
  return (
    <ForesiteTextField
      onChange={onChange}
      value={value}
      name="code"
      inputRef={ref}
      sx={{
        width: '108px',
        '&  .MuiFormHelperText-root.Mui-error': {
          color: error?.type === 'required' ? 'rgba(0, 0, 0, 0.5)' : 'red',
        },
        ...sx,
      }}
      label="Code"
      error={!!error}
      maxLength="6"
      disabled={disabled}
      {...restProps}
    />
  );
};

export default AttributeSelectCodeField;
