import React, { useRef } from 'react';

import { MoreMenu } from 'components';

import CollapseChildrenIcon from '../../components/CollapseChildrenIcon';
import { RowIcon } from '../../components/RowPrefix';
import StyledRow from '../../components/StyledRow';
import { useActiveRow } from '../../contexts/ActiveRowContext';
import { useDeleteModal } from '../../contexts/DeleteModalContext';
import { useLocationsAndWorkPhases } from '../../contexts/LocationsAndWorkPhaseContext';
import EditableRowName from './EditableRowName';

const RowInfo = React.memo(({ node, depth = 0, sx }) => {
  const { openDeleteWorkPhaseModal } = useDeleteModal();
  const {
    workPhases: { toggleOpenRow, toggleEditRow, isEditingARow },
  } = useLocationsAndWorkPhases();
  const { activeRows, setActiveRows } = useActiveRow();

  const inputRef = useRef();

  const focusNameInput = () => {
    setTimeout(() => {
      inputRef?.current?.focus();
    }, 0);
  };

  const MORE_MENU_OPTIONS = [
    {
      label: 'Rename',
      clickEvent: () => {
        toggleEditRow(node);
        focusNameInput();
      },
    },
    { label: 'Delete', clickEvent: () => openDeleteWorkPhaseModal(node), color: 'error.main' },
  ];

  const isAWorkPhase = depth === 2;
  const handleActiveNode = () => (!isAWorkPhase ? setActiveRows([node]) : null);

  return (
    <StyledRow
      isActive={activeRows.some(({ id }) => id === node.id)}
      onClick={handleActiveNode}
      sx={sx}
    >
      <CollapseChildrenIcon
        onClick={() => {
          toggleOpenRow(node);
          setActiveRows([node]);
        }}
        open={node.isOpen}
        showIcon={node.children?.length > 0 || node.hasLocationPhases}
        filled
        sx={{ ml: 2.8 * depth }}
      />
      <RowIcon type={node.type} />
      <EditableRowName node={node} inputRef={inputRef} focusNameInput={focusNameInput} />
      {depth > 0 && (
        <MoreMenu options={MORE_MENU_OPTIONS} disabled={isEditingARow} sx={{ fontSize: 21 }} />
      )}
    </StyledRow>
  );
});

export default RowInfo;
