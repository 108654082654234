import useAddWorkOrderItemFromCatalog from 'hooks-api/mutations/useAddWorkOrderItemFromCatalog';
import useLazyPaginatedWorkOrderItems from 'hooks-api/queries/workOrderItems/useLazyPaginatedWorkOrderItems';

import { getCachedItems } from '../helpers/cacheHelpers';

export const useAddWorkOrderItemFromCatalogDataLayer = (workOrder) => {
  const { workOrderItemsQuery } = useLazyPaginatedWorkOrderItems(workOrder);

  const [addWorkOrderItemFromCatalogMutation] = useAddWorkOrderItemFromCatalog({});

  const handleAddWorkOrderItemFromCatalog = ({ quantity, partId }) =>
    addWorkOrderItemFromCatalogMutation({
      variables: { body: { quantity, partId, workOrderId: workOrder?.workOrderId } },
      update: (cache, result) => {
        const cachedItems = getCachedItems(cache, result, workOrderItemsQuery);
        const newItem = result?.data?.addWorkOrderItemFromCatalog;
        if (!(cachedItems && newItem)) return;

        cache.writeQuery({ ...workOrderItemsQuery, data: { workOrderItem: [...cachedItems, newItem] } });
      },
    });

  return {
    handleAddWorkOrderItemFromCatalog,
  };
};
