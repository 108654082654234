/* eslint-disable import/no-cycle */
import { useEffect, useState } from 'react';

import { gql, useLazyQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useFormContext } from 'react-hook-form';

import { locationById as GET_LOCATION } from 'graphql/queries';
import { Amount, BillOfMaterialLineItemByLBS } from 'graphql/types';

import { useItemsIntegrationTableContext } from '../../../context/ItemsIntegrationTableContext';
import { QuantityFieldType } from '../../QuantityField/hooks/@types/fieldHookTypes';
import { fetchLocationData, getNewQtyFields } from '../helpers/locationRowHelpers';

interface IUseLocationRowEffectsHandler {
  nestLevel: number;
  lineItemId: string;
  locationId: string;
  amount: Amount;
  LBSData: BillOfMaterialLineItemByLBS & { workPhases: BillOfMaterialLineItemByLBS[] };
  loadingLineItem?: boolean;
}

const useLocationRowEffectsHandler = ({
  nestLevel,
  lineItemId,
  locationId,
  amount,
  LBSData,
  loadingLineItem,
}: IUseLocationRowEffectsHandler) => {
  const [locationData, setLocationData] = useState<any>(null);
  const {
    setQuantityFields,
    quantityFields,
    setMaxNestLevel,
    maxNestLevel,
    autoFilledFields,
    setAutoFilledFields,
    setLoadingBOMRows,
  } = useItemsIntegrationTableContext();
  const [getLocationById, { loading: loadingLocation }] = useLazyQuery(gql(GET_LOCATION));
  const { setValue, getValues } = useFormContext();

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (nestLevel > maxNestLevel[lineItemId])
      setMaxNestLevel((current: any) => ({ ...current, [lineItemId]: nestLevel }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nestLevel, maxNestLevel]);

  useEffect(() => {
    const loadingKey = `${locationId}__${lineItemId as string}`;
    if (!loadingLineItem) {
      setLoadingBOMRows((current) => ({
        ...current,
        [loadingKey]: false,
      }));
      return;
    }
    setLoadingBOMRows((current) => ({ ...current, [loadingKey]: true }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingLineItem]);

  useEffect(() => {
    if (
      !LBSData ||
      !locationData ||
      !!quantityFields.find((item) => item.id === locationId && item.lineItemId === lineItemId)
    )
      return;
    setQuantityFields((current: QuantityFieldType[]) =>
      getNewQtyFields({
        current,
        locationData,
        workPhases: LBSData.workPhases || [],
        nestLevel,
        locationId,
        lineItemId,
        amount,
        LBSData,
        autoFilledFields,
        setAutoFilledFields,
        setValue,
        getValues,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationData, lineItemId, locationId, nestLevel, amount, LBSData, loadingLocation]);
  useEffect(() => {
    fetchLocationData({
      getLocationById,
      locationId,
      setLocationData,
      amount,
      lineItemId,
      LBSData,
      enqueueSnackbar,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId, lineItemId]);
  return { loadingLocation, locationData, setLocationData };
};

export default useLocationRowEffectsHandler;
