import { useCallback } from 'react';

import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';

import { useUser } from 'app/UserContext';
import {
  addFolder as ADD_FOLDER,
  addDocument as ADD_DOCUMENT,
  getDocumentPresignedURL as GET_DOCUMENT_PRESIGNED_URL,
} from 'graphql/mutations';
import { folders as FOLDERS } from 'graphql/queries';
import useUploadFile from 'hooks/useUploadFile';

const getDocumentStoragePath = (storagePath, fileName) => {
  const documentGUID = uuidv4();
  return `${storagePath}/${documentGUID}-${fileName}`;
};

const useUploadWorkRequestDocument = ({ workRequestId, documentTypeId }) => {
  const { user } = useUser();
  const [fetchFoldersAPI] = useLazyQuery(gql(FOLDERS));
  const [addFolderAPI] = useMutation(gql(ADD_FOLDER));
  const [addDocumentAPI] = useMutation(gql(ADD_DOCUMENT));
  const [getPresignedURL, { loading: preSignedUrlLoading }] = useMutation(
    gql(GET_DOCUMENT_PRESIGNED_URL),
  );
  const { uploadFile } = useUploadFile(user);

  const storagePath = `all/company/${user.companyId}/field/${workRequestId}/attachments/${documentTypeId}`;

  const uploadFileToS3 = useCallback(
    async ({ file }) => {
      const documentStoragePath = getDocumentStoragePath(storagePath, file.name);
      await uploadFile(file, documentStoragePath);
      return { filename: file.name, documentStoragePath };
    },
    [uploadFile, storagePath],
  );

  const addDocument = useCallback(
    async ({ filename, documentStoragePath }) => {
      try {
        const {
          data: { folders },
        } = await fetchFoldersAPI({
          variables: {
            query: {
              searchPhrase: workRequestId,
              companyId: user.companyId,
            },
          },
        });

        let folderId;
        if (folders?.length > 0) {
          folderId = folders[0].folderId;
        } else {
          const newFolder = await addFolderAPI({
            variables: {
              body: {
                companyId: user.companyId,
                folderName: workRequestId,
                storagePath,
                requestedBy: user.userId,
              },
            },
          });
          folderId = newFolder.data?.addFolder?.folderId;
        }

        const newDocument = await addDocumentAPI({
          variables: {
            body: {
              folderId,
              documentTypeId,
              documentName: filename,
              storagePath: documentStoragePath,
              requestedBy: user.userId,
            },
          },
        });

        return newDocument.data?.addDocument?.documentId;
      } catch (error) {
        return null;
      }
    },
    [
      addDocumentAPI,
      addFolderAPI,
      fetchFoldersAPI,
      user?.companyId,
      user?.userId,
      documentTypeId,
      storagePath,
      workRequestId,
    ],
  );

  const getDocumentPresignedUrl = useCallback(
    async (filePath) => {
      const presignedURLResponse = await getPresignedURL({
        variables: {
          body: {
            expirationHours: 24,
            objectKey: filePath,
            requestedBy: user.userId,
            verb: 'GET',
          },
        },
      });

      return presignedURLResponse?.data?.getDocumentPresignedURL?.preSignedURL || '';
    },
    [getPresignedURL, user.userId],
  );

  return {
    addDocument,
    uploadFileToS3,
    getDocumentPresignedUrl,
    metaData: { preSignedUrlLoading },
  };
};

export default useUploadWorkRequestDocument;
