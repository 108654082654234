import * as React from 'react';

export const useCancelOperation = (callback = () => {}) => {
  const ref = React.useRef(null);

  const handleCallback = (event) => {
    const isCurrentComponent = ref.current && ref.current.contains(event.target);
    if (event.key === 'Escape' && isCurrentComponent) {
      callback();
    }
  };

  React.useEffect(() => {
    document.addEventListener('keydown', handleCallback, true);
    return () => document.removeEventListener('keydown', handleCallback, true);
  });

  return { ref };
};
