import React from 'react';

import { Box, Typography } from '@mui/material';

import Section from '../../../../../components/Section';
import { useCategoriesContext } from '../../Categories/CategoriesContext';
import { useAttributeContext } from '../AttributesContext';
import OpenPlusModalButton from '../components/OpenPlusModalButton';
import AttributeContent from './AttributeContent/AttributeContent';

const Attributes = () => {
  const {
    setAttributesModalOpen,
    attributes,
    getAttributesPaginationHandler,
    setSelectedAttribute,
    selectedAttribute,
    handleAddNewVariant,
    setVariantToAdd,
  } = useAttributeContext();

  const { selectedCategory } = useCategoriesContext();

  const handleEditAttribute = () => {
    setAttributesModalOpen(true);
  };
  const handleClickAttribute = (attribute) => {
    setSelectedAttribute(attribute);
    setVariantToAdd(null);
  };
  return (
    <AttributesContainer>
      <Section borderTopRightRadius="0px" borderBottomRightRadius="0px">
        <Section.Title>
          <HeaderTitle />
          <OpenPlusModalButton
            disabled={selectedCategory === null}
            onClick={() => {
              setAttributesModalOpen(true);
            }}
            title="Add Attribute"
          />
        </Section.Title>
        <Section.Body>
          <AttributeContent
            attributes={attributes || []}
            handleClickAttribute={handleClickAttribute}
            handleEditAttribute={handleEditAttribute}
            selectedAttribute={selectedAttribute}
            paginationHandler={getAttributesPaginationHandler}
            selectedCategoryId={selectedCategory?.partCategoryId}
            handleAddNewVariant={handleAddNewVariant}
          />
        </Section.Body>
      </Section>
    </AttributesContainer>
  );
};

const AttributesContainer = ({ children }) => (
  <Box
    sx={{
      width: '50%',
      borderRight: '1px solid #cccccc',
    }}
  >
    {children}
  </Box>
);

export default Attributes;

const HeaderTitle = () => (
  <Typography variant="tableHeader" sx={{ flex: 1 }}>
    Attributes
  </Typography>
);
