import React, { useState } from 'react';

import { EMPTY_WORKCELL } from '../constants/constants';
import useMachineImageDataLayer from '../hooks/useMachineImageDataLayer';
import useShopConfigWorkCellsDataLayer from '../hooks/useShopConfigWorkCellsDataLayer';
import { useDataContext } from './DataContext';

const WorkCellsAndMachinesContext = React.createContext();

const WorkCellsAndMachinesProvider = ({ children }) => {
  const { activeRow, setActiveRow } = useDataContext();
  const { workCells, loadingWorkCells, addWorkCell, loadingAddingWorkCell } =
    useShopConfigWorkCellsDataLayer();

  const [handleSaveWorkCellForm, setHandleSaveWorkCellForm] = useState(null);
  const [handleSaveMachineForm, setHandleSaveMachineForm] = useState(null);

  const {
    getMachineImage,
    uploadMachineImage,
    uploadingMachineImage,
    uploadingProgress,
    loadingGetImage,
  } = useMachineImageDataLayer();

  const handleAddNewWorkCell = () => {
    if (workCells?.length) {
      const calculatedName = (() => {
        const names = workCells?.map((workCell) => workCell.workCellName);

        let number = 0;
        let stillLoop = true;
        let availableName;

        while (stillLoop) {
          const curName = number === 0 ? 'Work Cell' : `Work Cell ${number}`;
          if (!names.includes(curName)) {
            availableName = curName;
            stillLoop = false;
          } else number += 1;
        }

        return availableName;
      })();

      addWorkCell(calculatedName);
    } else {
      setActiveRow({ ...EMPTY_WORKCELL, isNew: true });
    }
  };

  const saveWorkCellForm = (formValues) =>
    setHandleSaveWorkCellForm({ workCell: activeRow, formValues });

  const saveMachineForm = (formValues) =>
    setHandleSaveMachineForm({ machine: activeRow, formValues });

  const selectedStateObj = {
    getMachineImage,
    loadingGetImage,
    uploadMachineImage,
    uploadingMachineImage,
    uploadingProgress,
    workCells,
    loadingWorkCells,
    loadingAddingWorkCell,
    handleAddNewWorkCell,
    saveWorkCellForm,
    saveMachineForm,
    handleSaveWorkCellForm,
    handleSaveMachineForm,
  };

  return (
    <WorkCellsAndMachinesContext.Provider value={selectedStateObj}>
      {children}
    </WorkCellsAndMachinesContext.Provider>
  );
};

const useWorkCellsAndMachinesContext = () => {
  const context = React.useContext(WorkCellsAndMachinesContext);
  if (context === undefined) {
    throw new Error(
      'useWorkCellsAndMachinesContext must be used within a WorkCellsAndMachinesContext',
    );
  }
  return context;
};

export {
  WorkCellsAndMachinesContext,
  WorkCellsAndMachinesProvider,
  useWorkCellsAndMachinesContext,
};
