import React, { KeyboardEventHandler, useCallback } from 'react';

import { Box, SxProps, Typography } from '@mui/material';

import PartsAutocomplete from './PartsAutocomplete/index';

type AddNewItemProps = {
  actionDefaultLabel: string;
  hook: { isComponentVisible: boolean; setIsComponentVisible: (visible: boolean) => void };
  onOptionSelected: (option: any) => void;
  addNewItemSx: SxProps;
  onInputChange: () => void;
  showModal: () => void;
  disabled: boolean;
  isRecursive: boolean;
};

const AddNewItem = ({
  actionDefaultLabel,
  hook,
  onOptionSelected,
  addNewItemSx,
  onInputChange,
  showModal,
  disabled,
  isRecursive,
}: AddNewItemProps) => {
  const { isComponentVisible, setIsComponentVisible } = hook;

  const hideComponentVisibility = useCallback(() => setIsComponentVisible(false), [setIsComponentVisible]);

  const onChangeComponent = () => {
    if (!isRecursive) {
      setIsComponentVisible(true);
    } else {
      handleCreateNewItem();
    }
  };

  const handleCreateNewItem = useCallback(() => {
    showModal();
    hideComponentVisibility();
  }, [showModal, hideComponentVisibility]);

  const handleSelectOption = useCallback((option: any) => onOptionSelected(option), [onOptionSelected]);

  const onKeyDownPartsAutocomplete = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (event) => {
      if (event.keyCode === 27) hideComponentVisibility();
    },
    [hideComponentVisibility],
  );

  const onClickAddItemAction = () => {
    if (!disabled) onChangeComponent();
  };

  const onKeyDownAddItemAction: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.keyCode === 13 && !disabled) onClickAddItemAction();
  };

  if (isComponentVisible)
    return (
      <PartsAutocomplete
        onInputChange={onInputChange}
        onCreate={handleCreateNewItem}
        onSelect={handleSelectOption}
        onBlur={hideComponentVisibility}
        onKeyDown={onKeyDownPartsAutocomplete}
      />
    );

  return (
    <AddItemAction
      onClick={onClickAddItemAction}
      onKeyDown={onKeyDownAddItemAction}
      label={actionDefaultLabel}
      sx={addNewItemSx}
      disabled={disabled}
    />
  );
};

export default AddNewItem;

type AddItemActionProps = {
  onClick: () => void;
  label: string;
  onKeyDown: KeyboardEventHandler<HTMLDivElement>;
  sx: SxProps;
  disabled: boolean;
};

const AddItemAction = ({ onClick, label, onKeyDown, sx, disabled, ...rest }: AddItemActionProps) => (
  <Box
    tabIndex={0}
    sx={getAddItemActionStyles(sx, disabled)}
    component="div"
    onClick={() => (!disabled ? onClick() : null)}
    onKeyDown={onKeyDown}
    data-testid="addItemToTakeoff"
    {...rest}
  >
    <Typography variant="body2" id="AddItemAction">
      + {label}
    </Typography>
  </Box>
);

const getAddItemActionStyles = (sx: SxProps, disabled: boolean) => ({
  width: 500,
  height: 24,
  color: disabled ? 'surface.lightSurface.secondary' : 'secondary.main',
  display: 'flex',
  alignItems: 'center',
  cursor: disabled ? 'default' : 'pointer',
  transition: '0.2s',
  ...sx,
  ':hover': { textDecoration: 'underline', textDecorationColor: 'secondary.main' },
});
