export const handleSelectAllClick = (e, allSelections, callBack) => {
  if (e.target.checked) {
    callBack(allSelections);
  } else {
    callBack([]);
  }
};

export const handleTableRowClick = (row, selectedRows, callBack) => {
  const selectedIndex = selectedRows.indexOf(row);
  let updatedSelectedRows = [];

  if (selectedIndex === -1) {
    updatedSelectedRows = [...updatedSelectedRows, ...selectedRows, row];
  } else if (selectedIndex === 0) {
    updatedSelectedRows = [...updatedSelectedRows, ...selectedRows.slice(1)];
  } else if (selectedIndex === selectedRows.length - 1) {
    updatedSelectedRows = [...updatedSelectedRows, ...selectedRows.slice(0, -1)];
  } else if (selectedIndex > 0) {
    updatedSelectedRows = [
      ...updatedSelectedRows,
      ...selectedRows.slice(0, selectedIndex),
      ...selectedRows.slice(selectedIndex + 1),
    ];
  }

  callBack(updatedSelectedRows);
};

export const isSelected = (selected, selectedRows) => selectedRows.indexOf(selected) !== -1;
