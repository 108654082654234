import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { Breadcrumbs } from 'components/NavigationComponents';
import { BreadcrumbNextIcon } from 'components/NavigationComponents/Breadcrumbs';
import { useCurrentModuleFromURL } from 'hooks/useCurrentModuleFromURL';
import { EModuleType, ESearchType, MODULE_URL_MAPPING } from 'types/module';

const modulesHasProjectAndFacilities = [
  EModuleType.DATA_ANALYTICS,
  EModuleType.DESIGN,
  EModuleType.ELECTRICAL,
  EModuleType.MATERIALS,
  EModuleType.MECHANICAL,
  EModuleType.FIELD,
  EModuleType.SHOP,
  EModuleType.SYNC,
].map((module) => MODULE_URL_MAPPING[module]);

const FacilitiesProjectsBreadcrumb = ({ ...props }) => {
  const { selectedItem } = useFacilitiesProjects();

  const { searchCurrentModule } = useCurrentModuleFromURL(
    modulesHasProjectAndFacilities,
    MODULE_URL_MAPPING[EModuleType.ADMIN],
  );

  if (!selectedItem?.id) return <></>;

  const breadcrumbsBuilder = () => {
    const root = searchCurrentModule();
    if (selectedItem.type === ESearchType.FACILITY) {
      return { label: 'Facilities', url: `/${root}/facilities` };
    }
    return { label: 'Projects', url: `/${root}/projects` };
  };

  return (
    <Breadcrumbs
      {...props}
      breadcrumbs={[breadcrumbsBuilder(), { label: selectedItem?.label || '' }]}
      aria-label="breadcrumb"
      darkMode
      separator={<BreadcrumbNextIcon darkMode />}
    />
  );
};

export default FacilitiesProjectsBreadcrumb;
