import React from 'react';

import InputSelectField from 'components/FormComponents/InputSelectField';

export const CategoryTypeSelect = ({ control, options }) => (
  <InputSelectField
    margin="dense"
    variant="outlined"
    color="secondary"
    size="small"
    name="attributeType"
    defaultValue={0}
    fullWidth
    disabled
    control={control}
    options={options}
    sx={{
      '.MuiOutlinedInput-notchedOutline': {
        borderStyle: 'dotted',
      },
    }}
    menuItemSx={{ p: { fontSize: 16 } }}
  />
);
