import React, { useState, useCallback, useEffect, createContext, useContext } from 'react';

import { Stack } from '@mui/material';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import { Custom } from 'components/Icons';

import { FilterButtonContextType, FilterButtonWithPopoverProps } from './@types/buttons';

const FilterButtonContext = createContext<FilterButtonContextType>({ updateOpen: () => {} });

export const FilterButtonWithPopover = ({
  children,
  disabled = false,
  color = 'secondary.main',
}: FilterButtonWithPopoverProps) => {
  const LEFT_DISPLACEMENT = window.innerWidth - 738;
  const [toggle, setToggle] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const updateToggle = useCallback((e: boolean) => setToggle(e), []);
  const updateOpen = useCallback((e: boolean) => setOpen(e), []);

  const handleClick = () => {
    updateOpen(true);
  };

  useEffect(() => {
    setOpen(false);
  }, [toggle]);

  const id = open ? 'TakeOffPopover' : undefined;

  return (
    <FilterButtonContext.Provider value={{ updateOpen }}>
      <Button onClick={handleClick} sx={{ marginLeft: 1, height: 'fit-content' }} disabled={disabled}>
        <Stack direction="row" sx={{ alignItems: 'center' }}>
          <Custom.FilterList
            sx={{
              margin: '0px 5px 0px 0px',
              fontSize: '14px',
              color: disabled ? 'secondary.disabled' : color,
            }}
          />
          <Typography
            sx={{
              fontSize: '14px',
              color: disabled ? 'secondary.disabled' : color,
              lineHeight: '24px',
              fontWeight: '600',
            }}
          >
            FILTER
          </Typography>
        </Stack>
      </Button>
      <Popover
        id={id}
        open={open}
        onClose={() => updateToggle(!toggle)}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 172, left: LEFT_DISPLACEMENT }}
        PaperProps={{
          elevation: 12,
        }}
      >
        {children}
      </Popover>
    </FilterButtonContext.Provider>
  );
};

export const useButtonFilterUpdaters = () => {
  const context = useContext(FilterButtonContext);
  if (!context) throw new Error('usePopover must be used within a PopoverProvider');
  return context;
};
