import React from 'react';

import { ForesiteTextField } from '@evolvemep/foresite-components-ui-react';
import { Autocomplete } from '@mui/material';

const ForesiteAutocomplete = ({ sx, textFieldProps, ...restProps }) => (
  <Autocomplete
    sx={{ ...sx }}
    renderInput={(params) => <ForesiteTextField {...params} {...textFieldProps} />}
    {...restProps}
  />
);
export default ForesiteAutocomplete;
