import React from 'react';

import { Typography } from '@mui/material';

const textEllipsis = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const PickText = ({ type, item = {}, identifierSx = {}, nameSx = {} }) => {
  if (type === 'Project') {
    return (
      <>
        <Typography variant="body2" sx={{ margin: '0 80px 0 10px', ...textEllipsis, ...identifierSx }}>
          {item.identifier || '-'}
        </Typography>
        <Typography variant="subtitle2" sx={{ fontWeight: '600', ...textEllipsis, ...nameSx }}>
          {item?.label ?? item?.projectName}
        </Typography>
      </>
    );
  }

  if (type === 'Floor' || type === 'Zone')
    return (
      <>
        <Typography variant="body2" sx={{ margin: '0 30px 0 20px', ...textEllipsis, ...identifierSx }}>
          {item?.locationIdentifier}
        </Typography>
        <Typography variant="subtitle2" sx={{ fontWeight: '600', ...textEllipsis, ...nameSx }}>
          {item?.locationName}
        </Typography>
      </>
    );

  if (type === 'Scope Package')
    return (
      <>
        <Typography variant="body2" sx={{ margin: '0 30px 0 20px', ...textEllipsis, ...identifierSx }}>
          {item.scopePackageIdentifier}
        </Typography>
        <Typography variant="subtitle2" sx={{ fontWeight: '600', ...textEllipsis, ...nameSx }}>
          {item?.scopePackageName}
        </Typography>
      </>
    );

  if (type === 'Location System')
    return (
      <Typography variant="subtitle2" sx={{ fontWeight: '600', ...textEllipsis, ...nameSx }}>
        {item?.locationIdentifier}
      </Typography>
    );

  return (
    <Typography variant="subtitle2" sx={{ ...textEllipsis, ...nameSx }}>
      {item?.workPhaseName}
    </Typography>
  );
};

export default PickText;
