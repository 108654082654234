import React from 'react';

import { SxProps } from '@mui/material';

import { BreadcrumbsItemType } from '../BreadcrumbTypes';
import BreadcrumbsLabel from './BreadcrumbsLabel';
import BreadcrumbsLink from './BreadcrumbsLink';
import BreadcrumbsMenu from './BreadcrumbsMenu';

type BreadcrumbsItemProps = {
  label: string;
  darkMode: boolean;
  url?: string;
  isLastItem: boolean;
  type?: string;
  value?: Array<BreadcrumbsItemType>;
  labelSx?: SxProps;
  lastLabelSx?: SxProps;
  onClick?: () => void;
};

const BreadcrumbsItem = ({
  label,
  url,
  isLastItem = false,
  type,
  value,
  lastLabelSx,
  labelSx,
  darkMode,
  onClick,
}: BreadcrumbsItemProps) => {
  if (type && type === 'collapse') {
    return <BreadcrumbsMenu items={value ?? []} darkMode={darkMode} />;
  }

  if (!isLastItem && (url || onClick)) {
    return <BreadcrumbsLink url={url ?? '/'} onClick={onClick} label={label} darkMode={darkMode} />;
  }

  return (
    <BreadcrumbsLabel
      sx={isLastItem ? lastLabelSx ?? {} : labelSx ?? {}}
      label={label}
      darkMode={darkMode}
      fontWeight={400}
    />
  );
};

export default BreadcrumbsItem;
