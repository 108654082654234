import React, { forwardRef, useRef, useState } from 'react';

import { Grow } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import Popper from '@mui/material/Popper';

import getModuleIcon from 'helpers/module';
import useSetupModule from 'hooks/useSetupModule';

import ModuleSelectContent from '../components/ModuleSelectDropdown';

const MobileModuleSelector = () => {
  const [open, setOpen] = useState(false);
  const { currentModule } = useSetupModule();
  const anchorRef = useRef(null);
  const activeModuleIcon = getModuleIcon(currentModule());

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ModuleSelectorButton ref={anchorRef} onClick={handleToggle} icon={activeModuleIcon} />
      <ClickAwayListener onClickAway={handleClose}>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          transition
          placement="bottom"
          style={{
            width: '100%',
          }}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <ModuleSelectContent />
            </Grow>
          )}
        </Popper>
      </ClickAwayListener>
    </>
  );
};

export default MobileModuleSelector;

const ModuleSelectorButton = forwardRef(({ onClick, icon }, ref) => (
  <IconButton onClick={onClick} style={{ padding: 0 }} ref={ref} size="large">
    <img width="24" height="24" src={icon} alt="module-icon" />
  </IconButton>
));
