import React from 'react';

import { Stack } from '@mui/material';
import { grey } from '@mui/material/colors';

import SearchTextInput from 'components/SearchTextInput';

const DrawerContainerSearch = ({ sx, placeholder = 'Search Tasks...' }) => (
  <Stack sx={{ p: 2, ...sx }}>
    <SearchTextInput
      placeholder={placeholder}
      sx={{
        width: 'auto',
        mt: '2px',
        backgroundColor: grey[700],
        '&:hover': {
          backgroundColor: grey[700],
        },
      }}
      overrideInputSx={{ color: '#ffff' }}
    />
  </Stack>
);

export default DrawerContainerSearch;
