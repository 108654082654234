import React, { useEffect } from 'react';

import { Select, useTheme, MenuItem } from '@mui/material';
import { Controller } from 'react-hook-form';

import { TODAY, getDaysBetween } from 'helpers/dateFunctions';
import { useWorkRequestsContext } from 'modules/Field/WorkRequests/WorkRequestsContext';
import { useUpdateWorkRequest } from 'modules/Field/WorkRequests/WorRequestsProperties/WorkRequestPropertiesAttachments/hooks/useUpdateWorkRequest';

const CustomSelectForm = ({ setOpen, control, facilities }) => {
  const { updateWorkRequest, loading, data } = useUpdateWorkRequest();
  const { workRequestSelected, setWorkRequest, updateWRList, setUpdateWRList } = useWorkRequestsContext();
  const { palette } = useTheme();

  const handleChange = (event) => {
    event.stopPropagation();
    if (event.target.value && event.target.value !== workRequestSelected?.facilityId) {
      const body = { facilityId: event.target.value };
      updateWorkRequest(workRequestSelected.workRequestId, body);
    } else {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (data && !loading) {
      setWorkRequest({
        ...data.updateWorkRequest,
        daysRemaining: data?.updateWorkRequest?.needBy
          ? getDaysBetween(new Date(data?.updateWorkRequest?.needBy), new Date(TODAY))
          : null,
      });
      setUpdateWRList(!updateWRList);
      setOpen(false);
    }
  }, [data, loading, setWorkRequest, setOpen, updateWRList, setUpdateWRList]);

  return (
    <Controller
      name="facilityId"
      control={control}
      defaultValue={workRequestSelected.facilityId}
      rules={{ required: 'This field is required' }}
      render={({ field }) => (
        <Select
          id="select"
          size="small"
          {...field}
          onChange={handleChange}
          disabled={loading}
          sx={{
            '& .MuiSelect-select': {
              color: `${palette.surface.darkSurface.secondary} !important`,
              padding: '2px 8px',
              fontSize: '14px',
              lineHeight: '20px',
            },
            '&.Mui-disabled .MuiSelect-select': {
              color: `${palette.surface.darkSurface.disabled} !important`,
              WebkitTextFillColor: `${palette.surface.darkSurface.disabled} !important`,
            },
            '&.Mui-disabled .MuiSvgIcon-root': {
              color: `${palette.surface.darkSurface.disabled} !important`,
            },
            '& .MuiSvgIcon-root': {
              color: `${palette.surface.darkSurface.secondary} !important`,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: `${palette.surface.darkSurface.secondary} !important`,
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: `${palette.secondary.mainDarkBackground} !important`,
            },
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                height: '250px',
                color: palette.secondary.contrastText,
                backgroundColor: '#303030',
              },
            },
          }}
        >
          {facilities.length ? (
            facilities.map((facility) => (
              <MenuItem
                key={facility.value}
                value={facility.value}
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: '#454545 !important',
                  },
                  '&:hover': {
                    backgroundColor: '#595959',
                  },
                }}
              >
                {facility.label}
              </MenuItem>
            ))
          ) : (
            <MenuItem value="" />
          )}
        </Select>
      )}
    />
  );
};

export default CustomSelectForm;
