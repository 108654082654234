import { EIconType } from 'helpers/MenuIcons';

export const DROPPABLE_ROW_IDS = {
  BOP: 'WORK_REQUEST_DROPPABLE_BILL_OF_PROCESS',
  BOP_DR: 'WORK_REQUEST_DROPPABLE_BILL_OF_PROCESS_DRAWING_REGISTER',
  ITEM: 'WORK_ITEM_REQUEST_DROPPABLE_BILL_OF_PROCESS',
  FOLDER: 'DRAWING-FOLDER',
};

export const headerOptions = [
  { title: 'EMPTY', styles: { minWidth: '40px' } },
  {
    title: 'Work Request Id',
    sortValue: 'workRequestId',
    key: 'workRequestId',
    styles: { fontSize: '14px', fontWeight: 'bold' },
  },
  {
    title: 'Work Request',
    sortValue: 'workRequestName',
    key: 'workRequestName',
    styles: { fontSize: '14px', fontWeight: 'bold' },
  },
  {
    title: 'Status',
    sortValue: 'workRequestStatus',
    key: 'workRequestStatus',
    styles: { fontSize: '14px', fontWeight: 'bold' },
  },
];

export const subItemsWorkRequest = [
  {
    label: 'Bill of Materials',
    id: 'billOfMaterials',
    icon: EIconType.BILL_OF_MATERIALS_WORK_REQUEST,
  },
  {
    label: 'Bill of Process',
    id: 'billOfProcess',
    icon: EIconType.BILL_OF_PROCESS_WORK_REQUEST,
  },
  {
    label: 'Requisitions',
    id: 'requisitions',
    icon: EIconType.REQUISITIONS_WORK_REQUEST,
  },
];

export const getTableStyles = (visibleInputs = false, hiddenOverFlowVirtualScroller = false) => ({
  '&.MuiDataGrid-root .MuiDataGrid-virtualScroller': {
    overflow: hiddenOverFlowVirtualScroller && 'hidden',
  },
  '& .MuiDataGrid-pinnedRows--top': {
    height: 'auto !important',
  },
  '& .MuiDataGrid-pinnedRows--top .MuiDataGrid-pinnedRowsRenderZone': {
    position: 'relative',
  },
  '& .MuiDataGrid-pinnedRows--top .MuiDataGrid-cell': {
    overflow: 'visible',
  },
  '& .MuiDataGrid-cell': {
    borderTop: 'none !important',
  },
  ' div[role="row"], .MuiTableRow-root': {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
  '& div[role="cell"]:nth-of-type(3)': {
    minWidth: '32px',
    maxWidth: '32px',
  },
  '.tasks-cell': {
    padding: '0px !important',
  },
  ' .MuiDataGrid-row, .MuiDataGrid-virtualScrollerRenderZone': {
    width: '100%',
  },
  '.MuiTableCell-root': {
    borderBottom: 'none',
  },
  '& .MuiDataGrid-columnHeaderCheckbox, & .MuiDataGrid-cellCheckbox': {
    width: '45px !important',
    minWidth: '45px !important',
  },
  '& .MuiDataGrid-pinnedRows .MuiDataGrid-cell:nth-of-type(3)': {
    minWidth: '0px !important',
    width: '0px !important',
    paddingRight: '0px !important',
    paddingLeft: '0px !important',
  },
  '& .MuiDataGrid-pinnedRows .MuiDataGrid-cell:nth-of-type(4)': {
    paddingLeft: '0px !important',
  },
  '& .MuiDataGrid-pinnedRows .MuiDataGrid-cell:nth-of-type(5)': {
    marginLeft: '46px !important',
  },
  '& .MuiDataGrid-pinnedRows .MuiDataGrid-row, & .MuiDataGrid-pinnedRows .MuiDataGrid-row:hover': visibleInputs && {
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    boxShadow: 'none',
  },
  '& .MuiDataGrid-cell--detail-panel-toggle ': {
    padding: '0',
  },
});
