import React, { useState } from 'react';

import { Stack } from '@mui/material';

import { ReactComponent as ShopTasksThumbnailIcon } from 'assets/images/module-icons/thumbnails/task-type-thumbnail-icons/shop-task-type.svg';
import { MoreMenu } from 'components';
import TaskTypeCard from 'components/TaskTypeCard/index';

import NodeHandlers from '../../NodeHandlers';
import { ITaskTypeNode } from './@types/taskTypeNodeTypes';

const TaskTypeIcon = () => (
  <Stack
    data-testid="taskTypeDefaultIcon"
    height="100%"
    justifyContent="center"
    alignItems="center"
  >
    <ShopTasksThumbnailIcon />
  </Stack>
);
const TaskTypeNode: React.FC<ITaskTypeNode> = ({
  data,
  onDelete = () => null,
  onDuplicate = () => null,
  showHandlers = true,
  showLabels = true,
  showMoreMenu = true,
  moreMenuOpts = [],
  id,
}: ITaskTypeNode) => {
  const [isHovered, setIsHovered] = useState(false);
  const { taskTypeDescription, taskTypeName, image } = data;
  const deleteNode = () => {
    setIsHovered(false);
    onDelete(id);
  };

  const duplicateNode = () => {
    setIsHovered(false);
    onDuplicate(id);
  };

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const options = moreMenuOpts.length
    ? moreMenuOpts
    : [
        { label: 'Delete', clickEvent: deleteNode },
        { label: 'Duplicate', clickEvent: duplicateNode },
      ];

  return (
    <Stack onMouseEnter={() => handleMouseEnter()} onMouseLeave={() => handleMouseLeave()}>
      <TaskTypeCard
        taskTypeName={taskTypeName}
        image={image}
        taskTypeClass="shop"
        taskTypeDescription={taskTypeDescription}
        icon={<TaskTypeIcon />}
        sx={{ width: '240px', cursor: 'grab', userSelect: 'none' }}
        data-testid={`taskNode-${id}`}
      >
        {showHandlers && <NodeHandlers showLabels={showLabels} isHovered={isHovered} />}
        {isHovered && showMoreMenu && (
          <MoreMenu style={{ position: 'absolute', top: 0, right: 0 }} options={options} />
        )}
      </TaskTypeCard>
    </Stack>
  );
};

export default TaskTypeNode;
