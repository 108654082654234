import React, { useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { useUser } from 'app/UserContext';
import DepartmentUsersDeleteModal from 'components/DepartmentUsersDeleteModal';

import { useShopMembersContext } from './ShopMembersContext';

const getDepartmentUserId = ({ departmentUserId }) => departmentUserId;

const ShopMembersActions = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { user } = useUser();
  const { isShopAdmin, isShopAdminInvitationCompleted } = useFacilitiesProjects();
  const { selected, setSelected } = useShopMembersContext();
  const selectedMemberIds = selected.map(getDepartmentUserId);

  const isDeleteDisabled = useMemo(
    () => !selected?.length || (!user?.isAdmin && !(isShopAdmin && isShopAdminInvitationCompleted)),
    [selected, user, isShopAdmin, isShopAdminInvitationCompleted],
  );

  return (
    <Box>
      <MenuItem onClick={() => setShowDeleteModal(true)} disabled={isDeleteDisabled}>
        Delete
      </MenuItem>
      <DepartmentUsersDeleteModal
        departmentUserIds={selectedMemberIds}
        open={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
          setSelected([]);
        }}
      />
    </Box>
  );
};

export default ShopMembersActions;
