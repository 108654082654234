import React, { useState } from 'react';

import Auth from '@aws-amplify/auth';
import { Box, Button } from '@mui/material';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { Link as RouterLink } from 'react-router-dom';

import CenteredLoadSpinner from 'components/CenteredLoadSpinner';
import { VARIANT_SUCCESS } from 'constants/snackbarConstants';

import ErrorMessages from '../components/ErrorMessages';

const CheckEmailForPasswordReset = ({ email }) => {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [errorCode, setErrorCode] = useState('');

  const resendEmailLink = async () => {
    try {
      setLoading(true);
      await Auth.forgotPassword(email);
      enqueueSnackbar('Forgot Password email resent!', {
        autoHideDuration: 2500,
        ...VARIANT_SUCCESS,
      });
    } catch ({ code }) {
      setErrorCode(code);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      {errorCode && <ErrorMessages code={errorCode} onClose={() => setErrorCode('')} />}
      <CheckMailPrompt />
      <PasswordSentToEmailPrompt email={email} />
      <ResendLinkButton onClick={resendEmailLink} />
      <ReturnToLoginButton />
      <GetInTouchPrompt />
      {loading && <CenteredLoadSpinner />}
    </Container>
  );
};

export default CheckEmailForPasswordReset;

const PasswordSentToEmailPrompt = ({ email }) => (
  <Typography sx={{ marginBottom: '40px' }} variant="body2" color="secondary" align="center">
    {`A reset password link has been sent to ${email}`}
  </Typography>
);

const CheckMailPrompt = () => (
  <Typography component="h1" variant="h4" color="secondary" align="center" style={{ marginBottom: '24px' }}>
    Check your mail!
  </Typography>
);

const ResendLinkButton = ({ onClick }) => (
  <Button
    style={{ marginBottom: '8px' }}
    onClick={onClick}
    fullWidth
    variant="contained"
    color="secondary"
    sx={{
      '&:hover': {
        background: '#434A90',
      },
    }}
  >
    RESEND LINK
  </Button>
);

const ReturnToLoginButton = () => (
  <Button component={RouterLink} to="/auth/login" fullWidth>
    RETURN TO LOGIN
  </Button>
);

const GetInTouchPrompt = () => (
  <Box mt="80px">
    <Typography align="center" variant="body1">
      Questions or problems?{' '}
      <Link href="https://www.evolvemep.com/contact-support/" target="_blank" sx={{ color: '#4A93FF' }}>
        Get in touch.
      </Link>
    </Typography>
  </Box>
);
