/* eslint-disable no-console */
import React, { forwardRef } from 'react';

import { RightSideFlyout } from 'components';
import { Custom } from 'components/Icons';

import AssemblyEditorCatalogPopup from './AssemblyEditorCatalogPopup';
import FieldTaskDrawer from './Drawers/FieldTaskDrawer';
import ProcurementTaskDrawer from './Drawers/ProcurementTaskDrawer';
import { TaskTypesPopup } from './Drawers/ShopTaskDrawer';
import SnippetsDrawer from './Drawers/SnippetsDrawer';

const HIDE_FEATURES_MAT2_1280 = true;

const AssemblyEditorFlyoutMenu = forwardRef((_, ref) => {
  const flyoutItems = {
    staticList: [
      {
        section: 'catalog',
        icon: Custom.MenuBook,
        children: ({ onPanelClose, onDock }) => (
          <AssemblyEditorCatalogPopup onPanelClose={onPanelClose} onDock={onDock} ref={ref} />
        ),
      },
      ...(HIDE_FEATURES_MAT2_1280
        ? []
        : [
            {
              section: 'procurement-tasks',
              icon: <Custom.AssemblyEditor.ProcurementTaskDrawerIcon />,
              custom: true,
              children: () => <ProcurementTaskDrawer />,
            },
            {
              section: 'field-tasks',
              icon: <Custom.AssemblyEditor.FieldTaskDrawerIcon />,
              custom: true,
              children: () => <FieldTaskDrawer />,
            },
          ]),
      {
        section: 'shop-tasks',
        icon: <Custom.AssemblyEditor.ShopTaskDrawerIcon />,
        custom: true,
        children: () => <TaskTypesPopup />,
      },
      ...(HIDE_FEATURES_MAT2_1280
        ? []
        : [
            {
              section: 'decisions',
              icon: <Custom.AssemblyEditor.ActionsDrawerIcon />,
              custom: true,
              children: () => <p>decisions</p>,
            },
            {
              section: 'snippets',
              icon: <Custom.AssemblyEditor.SnippetsDrawerIcon />,
              custom: true,
              children: () => <SnippetsDrawer />,
            },
            {
              section: 'settings',
              icon: Custom.Settings,
              children: () => <p>properties</p>,
            },
          ]),
    ],
  };

  const onDock = () => console.log('docking');

  return <RightSideFlyout flyoutItems={flyoutItems} onDock={onDock} />;
});

export default AssemblyEditorFlyoutMenu;
