import React from 'react';

import Chip from '@mui/material/Chip';

const ChipOption = ({ onDelete, onClick = () => {}, id, label, icon, sx, ...rest }) => (
  <Chip
    data-testid="ChipOption-data-testid"
    size="small"
    key={`${id}-${label}`}
    label={label}
    onClick={() => onClick(id)}
    onDelete={onDelete}
    icon={icon}
    sx={{ marginRight: 1, marginBottom: 1, justifyContent: 'space-between', height: 24, ...sx }}
    {...rest}
  />
);

export default ChipOption;
