import { FieldValues, UseFormReturn } from 'react-hook-form';

import { BOM_ITEM_LINE_TYPES } from 'constants/globalConstants';
import { WriteInItemTypeInput } from 'graphql/types';

import { NEW_ITEM_ROW, parseCatalogNumber } from '../Form/rules';
import { AddBOMItemStrategy, Manufacturer, NewItem } from './AddBOMItemStrategy';

export class PartItemStrategy implements AddBOMItemStrategy {
  public addingItem = false;

  private formMethods: UseFormReturn<FieldValues, any>;

  constructor(formMethods: UseFormReturn<FieldValues, any>) {
    this.formMethods = formMethods;
  }

  // eslint-disable-next-line class-methods-use-this
  public createBOMItem(data: FieldValues, manufacturerId: string) {
    const { lineItemTypeId } = data;
    if (!lineItemTypeId) throw new Error('Error in the form, lineItemTypeId cannot be null.');

    const { quantity, partId, locationId, manufacturerCatalogNumber, workPhaseId } = data;

    const quantityForm = Number.isNaN(Number(quantity)) ? 0 : Number(quantity);

    const item: WriteInItemTypeInput = {
      lineItemTypeId,
      partId,
      locationId,
      workPhaseId,
      manufacturerCatalogNumber,
      manufacturerId,
      quantity: quantityForm,
    };

    return [item];
  }

  public addItemRow(item: NewItem, genericManufacturer: Manufacturer) {
    const { reset, setValue } = this.formMethods;
    this.addingItem = true;
    reset();
    setValue('lineItemTypeId', BOM_ITEM_LINE_TYPES.part);
    setValue('lineItemName', item.lineItemName);
    if (item.unitOfMeasure)
      setValue('uom', {
        value: item.unitOfMeasure.unitOfMeasureId,
        label: item.unitOfMeasure.unitOfMeasureName,
        code: item.unitOfMeasure.unitOfMeasureCode,
      });

    setValue('manufacturerCatalogNumber', item.manufacturerCatalogNumber);
    setValue('partId', item?.partId);

    if (typeof item.manufacturer === 'object') {
      setValue('manufacturer', {
        id: item.manufacturer.manufacturerId,
        label: item.manufacturer.manufacturerName,
      });
    } else {
      setValue('manufacturer', genericManufacturer);
    }

    return NEW_ITEM_ROW(item.lineItemName, parseCatalogNumber(item?.manufacturerCatalogNumber as string | undefined));
  }
}
