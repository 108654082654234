import React from 'react';

import { Typography } from '@mui/material';

import RevitModel from './RevitModel';
import { useRevitModel } from './RevitModelContext';

const RevitPropertiesGrid = () => {
  const { revitModels } = useRevitModel();

  return revitModels?.length ? (
    revitModels.map((revitModel, index) => (
      <RevitModel revitModel={revitModel} isLastRevitModel={index === revitModels?.length - 1} />
    ))
  ) : (
    <Typography sx={{ color: 'white' }}>No Revit models</Typography>
  );
};

export default RevitPropertiesGrid;
