import React from 'react';

import { Box, IconButton, useTheme } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { Custom } from 'components/Icons';
import { ICONS_MAP, NEW_ITEM_ROW_ID } from 'constants/globalConstants';

import RowComponent from '../../../RowComponent';
import RowItemName from '../../../RowItemName';
import { getItemHasParent } from '../BOMTableColumnsHelpers';

const LineItemDisplayCell = ({
  id,
  value,
  row,
  partAutocompleteVisibilityHook,
  onAddWriteInItem,
  onSelectPart,
  isLoading,
  handleRowCancel,
  api,
}) => {
  const { getValues } = useFormContext();
  const { palette } = useTheme();
  const { primary, secondary } = palette.surface.lightSurface;
  const itemHasParent = getItemHasParent(getValues('parentLineItemId'));
  const disableAddButton = api.getRow(NEW_ITEM_ROW_ID);
  const rowIds = api.getExpandedDetailPanels();
  const rowExpanded = rowIds?.find((rowId) => rowId === id);

  if (!id)
    return (
      <RowComponent
        isRecursive={itemHasParent}
        disabled={disableAddButton}
        hook={partAutocompleteVisibilityHook}
        addSelectedPart={onSelectPart}
        onAddWriteInItem={onAddWriteInItem}
      />
    );

  const rowModeEqualsEdit = api.getRowMode(id) === 'edit';
  const Icon = ICONS_MAP[row?.lineItemTypeName];

  return (
    <>
      {rowModeEqualsEdit && (
        <IconButton sx={{ height: 24, width: 24, mr: 1, p: 0 }} onClick={() => handleRowCancel(id)} size="large">
          <Custom.CloseAlt />
        </IconButton>
      )}
      {Icon && (
        <Box sx={{ height: 18, width: 18 }}>
          <Icon
            height={18}
            width={18}
            fill={rowExpanded ? primary : secondary}
            sx={{ path: { fill: palette.action.lightSurface.active } }}
          />
        </Box>
      )}
      <RowItemName isLoading={id === 1 ? isLoading : false} value={value} row={row} />
    </>
  );
};

export default React.memo(LineItemDisplayCell);
