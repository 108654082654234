/* eslint-disable import/namespace */
import React, { FC, ReactElement } from 'react';

import { Tooltip, Typography } from '@mui/material';

interface IQtyRemainingCell {
  qtyRemaining: number;
  qtyAggregate: number;
  children: ReactElement;
  name: string;
}

export const QtyRemainingCell: FC<IQtyRemainingCell> = ({
  qtyRemaining,
  qtyAggregate,
  children,
  name,
}: IQtyRemainingCell) => {
  if (qtyRemaining !== qtyAggregate)
    return (
      <Tooltip
        arrow
        placement="top"
        title={
          <Typography sx={{ fontSize: '10px', padding: 0, margin: 0, lineHeight: '16px', fontWeight: '400' }}>
            {`Aggregated Qty Remaining = ${qtyAggregate}`}
            <br />
            {`Assigned to ${name} = ${qtyRemaining}`}
          </Typography>
        }
      >
        {children}
      </Tooltip>
    );
  return children;
};
