import React from 'react';

import { Box, Stack, SxProps, Typography } from '@mui/material';

type KanbanColumnHeaderProps = {
  title: string;
  detailColor: string;
  counter: number;
  sx?: SxProps;
};

const KanbanColumnHeader = ({ title, detailColor = 'blue', counter = 0, sx }: KanbanColumnHeaderProps) => (
  <Stack sx={sx}>
    <Stack
      direction="row"
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '0 2px',
        width: '196px',
      }}
    >
      <Typography variant="h6" sx={{ fontSize: 16 }}>
        {title}
      </Typography>
      <StyledQtyContainer>
        <Typography variant="body2" fontWeight={500}>
          {counter}
        </Typography>
      </StyledQtyContainer>
    </Stack>
    <KanbanColumnDetail color={detailColor} />
  </Stack>
);

const KanbanColumnDetail = ({ color }: { color: string }) => (
  <Stack width={196}>
    <Box sx={{ width: '196px', height: '6px', background: color, borderRadius: '8px 8px 0 0' }} />
  </Stack>
);

const StyledQtyContainer = ({ children }: { children: React.ReactNode }) => (
  <Box
    sx={{
      background: '#F5F5F5',
      height: 28,
      width: 28,
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    {children}
  </Box>
);

export default KanbanColumnHeader;
