import React from 'react';

import { Box, IconButton, Stack, Typography } from '@mui/material';

import PopperWithArrow from 'components/PopperWithArrow/PopperWithArrow';

import DecisionIcon from './DecisionIcon';
import DecisionsData from './DecisionsData';
import { useDecisionsApi } from './hooks/useDecisionsApi';
import useGetLineItemsAdded from './hooks/useGetLineItemsAdded';
import { useHandlePopper } from './hooks/useHandlePopper';

const DecisionRow = ({ row, isTakeoff = false }) => {
  const { open, anchorEl, handleOpen, handleClose } = useHandlePopper();
  const { getDecisions, decisions, loadingDecisions, refetchDecisions } = useDecisionsApi();
  const { selectedRowDecision, setSelectedRowDecision } = useGetLineItemsAdded();
  const hasOpenDecisions = row?.numberOfOpenDecisions > 0;

  const handleOpenClick = (event) => {
    event.stopPropagation();
    handleOpen(event);
    getDecisions(row?.lineItemId);
    setSelectedRowDecision(row?.lineItemId);
  };

  if (!row?.ableToMakeDecisions) return null;

  const openPopper = open && selectedRowDecision === row?.lineItemId;

  return (
    <Box onClick={(e) => e.stopPropagation()}>
      <Stack direction="row" alignItems="center">
        <IconButton onClick={handleOpenClick} disableFocusRipple>
          <DecisionIcon
            hasOpenDecisions={hasOpenDecisions}
            decisionsContainsDeletedVariant={row?.decisionsHaveVariantDeletedFromCurrentVersionOfPart}
          />
        </IconButton>
        {hasOpenDecisions && <Typography variant="body2">{row?.numberOfOpenDecisions}</Typography>}
      </Stack>
      <PopperWithArrow open={openPopper} anchorEl={anchorEl}>
        <DecisionsData
          decisions={decisions || []}
          onClose={handleClose}
          row={row}
          loadingOptions={loadingDecisions}
          isTakeoff={isTakeoff}
          refetchDecisions={refetchDecisions}
          open={openPopper}
        />
      </PopperWithArrow>
    </Box>
  );
};
export default DecisionRow;
