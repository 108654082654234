import React from 'react';

import { ItemsProvider } from './ItemsContext';
import ItemsSection from './ItemsSection';

const ItemsSectionWithContext = () => (
  <ItemsProvider>
    <ItemsSection />
  </ItemsProvider>
);

export { ItemsSectionWithContext as default };
