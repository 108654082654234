import React from 'react';

import { Stack, Typography, useTheme } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import DescriptionField from './DescriptionField';
import ManufacturerSelect from './ManufacturerSelect';
import PartNameField from './PartNameField';
import UOMAutocomplete from './UOMAutocomplete';

const ItemDetailsForm = ({ selectedItem }) => {
  const { control } = useFormContext();
  const theme = useTheme();

  return (
    <Stack
      gap="16px"
      sx={{
        backgroundColor: theme.palette.action.hover,
        height: '393px',
        width: '416px',
        borderRadius: '5px',
        padding: '24px',
        margin: '0 0 0 32px',
      }}
    >
      <Typography variant="h6">New item</Typography>
      <PartNameField control={control} selectedItem={selectedItem} />
      <ManufacturerSelect />
      <DescriptionField />
      <UOMAutocomplete workRequestItem={selectedItem} />
    </Stack>
  );
};

export default ItemDetailsForm;
