import React, { useCallback, useState } from 'react';

import { useUser } from 'app/UserContext';
import { UOMAutocomplete } from 'modules/Materials/BillOfMaterialsById/BOMCatalogTable/Form';
import { CreateUOMModal } from 'modules/Materials/components/UOM';

const UOMCell = ({ control, setFormControlValue, clearErrors, uomOptions, onKeyDownHandler, disabled }) => {
  const [isOpenUOMModal, setIsOpenUOMModal] = useState(false);
  const { user } = useUser();

  const handleCreateUOM = useCallback(() => {
    setIsOpenUOMModal(true);
  }, []);

  const handleCloseUOMModal = useCallback(() => {
    setIsOpenUOMModal(false);
  }, []);

  const onSelectUOMCreated = (uomCreated) => {
    setFormControlValue('uom', uomCreated);
    clearErrors('uom');
  };

  return (
    <>
      <UOMAutocomplete
        control={control}
        options={uomOptions}
        onKeyDown={onKeyDownHandler}
        onCreate={handleCreateUOM}
        disabled={disabled}
      />
      <CreateUOMModal
        open={isOpenUOMModal}
        companyId={user?.companyId}
        onCreate={handleCloseUOMModal}
        onClose={handleCloseUOMModal}
        onSelectUOMCreated={onSelectUOMCreated}
      />
    </>
  );
};

export default UOMCell;
