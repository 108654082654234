import React from 'react';

import Typography from '@mui/material/Typography';

const OutlinedCardHeaderText = (props) => (
  <Typography
    sx={{
      fontSize: 14,
      lineHeight: '24px',
      fontWeight: 900,
    }}
    {...props}
  />
);

export default OutlinedCardHeaderText;
