import React, { useState } from 'react';

import FilterButton from 'components/Buttons/FilterButton';
import { usePopoverFilterContext } from 'components/Popover/FilterPopover/context/PopoverFilterContext';

import WorkRequestFilterPopover from './WorkRequestFilterPopover';

const WorkRequestFilter = ({ disabled = false, isResponsive }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { columns } = usePopoverFilterContext();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'work-request-filter-popover' : undefined;

  const filterDisabled = disabled || !columns.length;

  return (
    <>
      <FilterButton
        id={id}
        onClick={handleClick}
        disabled={filterDisabled}
        color={`secondary.${isResponsive ? 'main' : 'dark'}`}
        isResponsive={isResponsive}
        lowerTooltip
        sx={{ display: 'flex', alignItems: 'center' }}
      />
      <WorkRequestFilterPopover open={open} onClose={handleClose} anchorEl={anchorEl} id={id} />
    </>
  );
};

export default WorkRequestFilter;
