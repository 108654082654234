import { AccordionList, PropsBase, withCalendarSlotBase } from '@evolvemep/foresite-components-ui-react';
import { Box, Typography } from '@mui/material';

import { Custom } from 'components/Icons';
import { WorkOrder } from 'typings/work-order';

import { squareStyles } from '../../helpers/squareStyles';
import { useGroupByProject } from '../../Hooks/useGroupByProject';
import { ListDetailWorkOrder } from './ListDetailWorkOrder';

export const SlotDayWorkOrder = withCalendarSlotBase<
  WorkOrder,
  PropsBase & { projectsColors: Map<string, string> }
>(({ resourceList, projectsColors }) => {
  const { projects, getProjectNameWithKey, getProjectIdWithKey } = useGroupByProject(resourceList);
  const workOrdersGroup = Object.entries(projects);

  if (!workOrdersGroup.length)
    return (
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        width="100%"
        height="100%"
      >
        <Custom.CalendarBoard style={{ fill: '#BDBDBD', width: 'auto', height: '51px' }} />
        <Typography fontSize={20} fontWeight="500" mt={1} color="grey.500">
          This date has no data.
        </Typography>
      </Box>
    );

  return (
    <AccordionList
      hideFirstBorder
      hideLastBorder={false}
      sx={{
        width: '100%',
        '& .MuiAccordion-root': {
          position: 'unset',
          borderRadius: 0,
          boxShadow: 'none',
          padding: '0.8rem',
          borderBottom: '1px solid #0000001A',
        },
      }}
      titleSx={null}
      infoSx={{
        marginLeft: 5,
        backgroundColor: 'grey.50',
        width: 20,
        lineHeight: '20px',
        height: 20,
        borderRadius: '100%',
        textAlign: 'center',
        fontSize: '10px',
        fontWeight: 500,
        position: 'relative',
        top: 0,
      }}
      items={workOrdersGroup.map(([project, workOrders]) => ({
        title: getProjectNameWithKey(project),
        content: <ListDetailWorkOrder workOrders={workOrders} />,
        info: String(workOrders.length),
        customItemTitleSx: {
          '&.MuiTypography-root:before': {
            ...squareStyles(projectsColors.get(getProjectIdWithKey(project))),
          },
        },
      }))}
    />
  );
});
