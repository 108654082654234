import { useCallback } from 'react';

import { gql, useMutation } from '@apollo/client';

import {
  deleteTakeoffPad as DELETE_TAKE_OFF_PAD,
  renameTakeOffPad as RENAME_TAKE_OFF_PAD,
  addTakeoffPad as ADD_TAKE_OFF_PAD,
  deleteSourcePad as DELETE_SOURCE_PAD,
} from 'graphql/mutations';

export const useAddPadMutation = (projectId, handleResponse) => {
  const [addTakeOffPad] = useMutation(gql(ADD_TAKE_OFF_PAD), {
    refetchQueries: ['SourcePadItems'],
  });
  const onAddTakeOffPad = useCallback(
    async ({ form, successCallback }) => {
      const body = { ...form, projectId };

      await handleResponse(
        addTakeOffPad,
        { variables: { body } },
        { successMessage: 'Takeoff pad successfully created' },
      );

      await successCallback();
    },
    [projectId, handleResponse, addTakeOffPad],
  );
  return onAddTakeOffPad;
};

// RENAME

export const useRenameTakeOffPad = (handleResponse) => {
  const [renameTakeOffPad] = useMutation(gql(RENAME_TAKE_OFF_PAD));
  const onRenameTakeOffPad = useCallback(
    async ({ takeoffPadName, takeoffPadId }) => {
      const body = { takeoffPadName };
      const params = { takeoffPadId };

      await handleResponse(
        renameTakeOffPad,
        { variables: { params, body } },
        { successMessage: 'Take off pad renamed' },
      );
    },
    [handleResponse, renameTakeOffPad],
  );
  return onRenameTakeOffPad;
};

// DELETE

export const useDeleteTakeOffPad = (handleResponse) => {
  const [deleteTakeoffPad] = useMutation(gql(DELETE_TAKE_OFF_PAD));
  const onDeleteTakeoffPad = useCallback(
    async ({ takeoffPadId }) => {
      const params = { takeoffPadId };
      await handleResponse(deleteTakeoffPad, { variables: { params } }, { successMessage: 'Takeoff pad deleted' });
    },
    [deleteTakeoffPad, handleResponse],
  );
  return onDeleteTakeoffPad;
};

export const useDeleteSourcePad = (handleResponse) => {
  const [deleteSourcePad] = useMutation(gql(DELETE_SOURCE_PAD));
  const onDeleteSourcePad = useCallback(
    async ({ sourcePadId }) => {
      const params = { sourcePadId };
      await handleResponse(deleteSourcePad, { variables: { params } }, { successMessage: 'Source pad deleted' });
    },
    [deleteSourcePad, handleResponse],
  );
  return onDeleteSourcePad;
};

// MAP SOURCE PAD

export const useMapPadItem =
  (handleResponse, mapSourcePadItem) =>
  ({ pads, padIndex, selectedPadItems, actionCallback }) =>
  async ({ takeoffPadId }) => {
    const sourcePadId = pads[padIndex]?.takeoffPadId;
    // actualRemainingQuantity is to sourcePad items, takeoffPad items is remainingQuantity
    const lineItems = selectedPadItems?.map(({ lineItemId, actualRemainingQuantity }) => ({
      lineItemId,
      quantity: actualRemainingQuantity,
    }));

    const body = {
      takeoffPadId,
      sourcePadId,
      lineItems,
    };

    const pad = pads.find((pad) => pad?.takeoffPadId === takeoffPadId) ?? {};
    const { takeoffPadName, index } = pad;

    await handleResponse(
      mapSourcePadItem,
      { variables: { body } },
      {
        successMessage: `${lineItems?.length} Items successfully mapped`,
        action: {
          label: `GO TO ${takeoffPadName} PAD`,
          callback: () => {
            actionCallback(index);
          },
        },
      },
    );
  };
