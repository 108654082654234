import React, { useMemo } from 'react';

import { Box, MenuItem, Typography } from '@mui/material';

import TooltipWrapper from 'modules/Materials/BillOfMaterialsById/components/DropdownActions/TooltipWrapper';
import { useItemsIntegrationModalContext } from 'modules/Materials/components/ItemsIntegrationModal/context/ItemsIntegrationModalContext';
import { useFilterOptions } from 'modules/Materials/features/filters/options';

import { useTakeOff } from '../../context/TakeOffContext';
import ModalForItemQty from './SelectActionModal/ModalForItemQty';
import { SendToBomProvider } from './SendToBomModalContext';

const SendToBOMOption = () => {
  const { setOpen, selectedItems } = useItemsIntegrationModalContext();
  const enableSendToBOM = useMemo(
    () => selectedItems.some(({ remainingQuantity }) => remainingQuantity > 0),
    [selectedItems],
  );
  const { parameters: originalParameters } = useTakeOff();
  const { query } = useFilterOptions();
  const parameters = useMemo(() => {
    const { locationIds, locationsWithWorkPhases, scopePackageIds, workPhaseIds } = originalParameters;
    return { locationIds, locationsWithWorkPhases, scopePackageIds, workPhaseIds };
  }, [originalParameters]);

  const hasFilters: boolean = useMemo(
    () =>
      Boolean([...Object.values(parameters).filter((parameter) => Boolean(parameter)), ...Object.keys(query)].length),
    [parameters, query],
  );

  const disableAddToWR = useMemo(() => hasFilters || !enableSendToBOM, [enableSendToBOM, hasFilters]);
  const disableAddToWRToolTipMessage = useMemo(() => {
    if (hasFilters) return 'Cannot send items to a BOM if filters are applied';
    return 'At least 1 item must have Quantity Remaining';
  }, [hasFilters]);

  const handleClick = () => setOpen(true);

  return (
    <SendToBomProvider>
      <Box
        sx={{
          '& .MuiTooltip-tooltip': {
            backgroundColor: '#FF9800 !important',
          },
        }}
      >
        <TooltipWrapper isWarningTooltip={hasFilters} title={disableAddToWRToolTipMessage} showTooltip={disableAddToWR}>
          <MenuItem disabled={disableAddToWR} onClick={handleClick}>
            <Typography sx={{ fontSize: 14, lineHeight: '20px' }}>Send to BOM</Typography>
          </MenuItem>
        </TooltipWrapper>
        <ModalForItemQty />
      </Box>
    </SendToBomProvider>
  );
};

export default SendToBOMOption;
