import React, { useState } from 'react';

import { useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import { Custom } from 'components/Icons';

import DeleteModal from '../../../ShopConfiguration/ShopConfigurationSelect/DeleteModal';

const DeleteTaskType = ({ task, handleSubmit }) => {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  const handleDeleteTaskType = async () => {
    setLoading(true);
    await handleSubmit();
    setOpenModal(false);
    setLoading(false);
  };

  return (
    <>
      <IconButton
        size="small"
        onClick={(e) => {
          setOpenModal(true);
          e.stopPropagation();
        }}
      >
        <Custom.MachineUnmap fill={theme.palette.action.lightSurface.active} />
      </IconButton>
      <DeleteModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        onSubmit={handleDeleteTaskType}
        itemName={task?.taskTypeName}
        loading={loading}
        title="Confirm unmap"
        confirmText="Are you sure you want to unmap"
        deleteButtonText="Confirm"
      />
    </>
  );
};

export default DeleteTaskType;
