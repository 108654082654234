import React, { useEffect, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';

import { SESSION_STORAGE_CONSTANTS } from 'constants/globalConstants';
import { getParamsWihNewValue } from 'helpers/routeFunctions';
import useQuery from 'hooks/useQuery';

import AttributeModalSetup from './AttributeModal/AttributeModalSetup';
import { AttributesProvider } from './AttributesSection/AttributesContext';
import CategorySetup from './AttributesSection/AttributesSection';
import BreadCrumbHeader from './BreadCrumbHeader/BreadCrumbHeader';
import { CatalogProvider } from './CatalogContext';
import CatalogSetupHeader from './CatalogSetupHeader';
import { CatalogSetupProvider } from './CatalogSetupHeader/CatalogSetupContext';
import Categories from './Categories';
import { CategoriesProvider } from './Categories/CategoriesContext';
import CategoriesRevit from './Categories/CategoriesRevit';
import ManageItems from './ItemsSection';

const { REACHED_CATALOG_SETUP_THROUGH_LINK } = SESSION_STORAGE_CONSTANTS;
const tabs = [
  { label: 'category setup', component: <CategorySetup /> },
  { label: 'manage items', component: <ManageItems /> },
];
const convertToBoolean = (string) => {
  if (!string) return false;
  if (string.toLowerCase() === 'false') return false;
  if (string.toLowerCase() === 'true') return true;
  return false;
};
const CatalogSetup = () => {
  const navigationSource = sessionStorage.getItem(REACHED_CATALOG_SETUP_THROUGH_LINK);
  const indexToRender = convertToBoolean(navigationSource) ? 1 : 0;
  const [activeTabIndex, setTabIndex] = useState(indexToRender);
  const query = useQuery();
  const [navigateTabIndex, setNavigateTabIndex] = useState(
    parseInt(query?.get('tabIndex'), 10) || 0,
  );
  const navigate = useNavigate();
  const tabIndexParam = useMemo(() => query?.get('tabIndex'), [query]);
  useEffect(() => {
    if (tabIndexParam === `${navigateTabIndex}`) setTabIndex(navigateTabIndex);
  }, [tabIndexParam, navigateTabIndex]);
  const handleTabChange = (e, newIndex) => {
    const newURL = getParamsWihNewValue(query, { tabIndex: newIndex });
    navigate(newURL);
    setNavigateTabIndex(newIndex);
  };
  return (
    <CatalogProvider>
      <CatalogSetupProvider>
        <CatalogSetupHeader tabs={tabs} activeTab={activeTabIndex} onTabClick={handleTabChange} />
        <CategoriesProvider>
          <AttributesProvider>
            <BreadCrumbHeader showBreadcrumb={activeTabIndex === 1}>
              <FlexContainer>
                {activeTabIndex === 0 ? <Categories /> : <CategoriesRevit />}
                <FlexGrow>{tabs[activeTabIndex].component}</FlexGrow>
              </FlexContainer>
            </BreadCrumbHeader>
            <AttributeModalSetup />
          </AttributesProvider>
        </CategoriesProvider>
      </CatalogSetupProvider>
    </CatalogProvider>
  );
};

export default CatalogSetup;

const FlexGrow = (props) => (
  <Box sx={{ flex: 1, marginLeft: '4px', marginRight: '1px' }} {...props} />
);

const FlexContainer = (props) => (
  <Box sx={{ display: 'flex', height: 'calc(100% - 72px)' }} {...props} />
);
