import { useCallback } from 'react';

import { LazyQueryExecFunction } from '@apollo/client';

import { useUser } from 'app/UserContext';
import { Manufactured, ManufacturedQueryVariables } from 'graphql/types';
import useAddManuracturerMutation from 'hooks-api/mutations/useAddNewManufacturer';
import { useLazyQueryManufacturedList } from 'hooks-api/queries/manufactured/useLazyQueryManufactured';

export const GRAPHQL_ERROR_MESSAGE = 'GRAPHQL_ERROR';

export const NETWORK_ERROR_MESSAGE = 'NETWORK_ERROR_MESSAGE';

const useCreateManufaturerIfDoesntExists = () => {
  const { user } = useUser();
  const [fetchManufacturers] = useLazyQueryManufacturedList({});

  const [addManufacturer] = useAddManuracturerMutation();

  const createManufacturerIfDoesntExists = useCallback(
    async (manufacturer: { id: string; label: string } | string) => {
      const manufacturerObject = typeof manufacturer === 'object';
      if (manufacturerObject) return manufacturer.id;
      const foundManufacturer = await getManufacturerFromExisting(fetchManufacturers, user, manufacturer);
      const manufacturerErrorHandling = foundManufacturer?.toString() ?? '';
      if ([NETWORK_ERROR_MESSAGE, GRAPHQL_ERROR_MESSAGE].includes(manufacturerErrorHandling)) return undefined;
      if (foundManufacturer) return (foundManufacturer as Manufactured).manufacturerId;

      const variables = { body: { companyId: user?.companyId, manufacturerName: manufacturer as string } };
      try {
        const { data } = await addManufacturer({ variables });
        return data?.addManufacturer?.manufacturerId;
      } catch (error) {
        return undefined;
      }
    },
    [addManufacturer, fetchManufacturers, user],
  );

  return createManufacturerIfDoesntExists;
};

export default useCreateManufaturerIfDoesntExists;

async function getManufacturerFromExisting(
  fetchManufacturers: LazyQueryExecFunction<{ manufactured: Manufactured[] }, ManufacturedQueryVariables>,
  user: any,
  manufacturer: string | { id: string; label: string },
) {
  const manufacturerString = manufacturer as string;
  const manufacturerObject = manufacturer as { id: string; label: string };
  const { data, error } = await fetchManufacturers({ variables: { query: { companyId: user?.companyId } } });
  if (error?.graphQLErrors.length) return new Error(GRAPHQL_ERROR_MESSAGE);
  if (error?.networkError) return new Error(NETWORK_ERROR_MESSAGE);
  const foundManufacturer =
    data?.manufactured?.length &&
    data?.manufactured
      ?.filter((m) => m.manufacturerName === manufacturerString || m.manufacturerName === manufacturerObject.label)
      ?.at(0);
  return foundManufacturer;
}
