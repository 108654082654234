/* eslint-disable max-lines-per-function */
import { useState, useEffect } from 'react';

import { useCatalogSelectedPartContext } from 'modules/Materials/CatalogSetup/Providers/CatalogSelectedPartProvider';

import usePartImage from './usePartImage';

export default function useFetchPartImage() {
  const { selectedPart } = useCatalogSelectedPartContext();
  const { getPartImage } = usePartImage();

  const [partAdditionalImages, setPartAdditionalImages] = useState([]);
  const [partDefaultImage, setPartDefaultImage] = useState(null);

  useEffect(() => {
    async function fetchImages() {
      if (selectedPart && selectedPart?.partImages?.length) {
        const { partImages } = selectedPart;

        const defaultImage = partImages.find((item) => item.isDefault === true);
        const defaultImageUrl = await getPartImage(defaultImage?.document?.documentId);
        setPartDefaultImage({
          ...defaultImage,
          imageUrl: defaultImageUrl,
        });

        const additionalImages = partImages
          .filter((item) => !item.isDefault)
          .sort((a, b) => a.sortOrder - b.sortOrder);
        const images = [];

        // eslint-disable-next-line
        for (const item of additionalImages) {
          // eslint-disable-next-line
          const imageUrl = await getPartImage(item?.document?.documentId);
          images.push({ ...item, imageUrl });
        }
        setPartAdditionalImages(images);
      }
    }
    if (!selectedPart) {
      setPartDefaultImage(null);
      setPartAdditionalImages([]);
      return;
    }
    fetchImages();

    // eslint-disable-next-line
  }, [selectedPart, selectedPart?.partImages]);
  return {
    partAdditionalImages,
    partDefaultImage,
  };
}
