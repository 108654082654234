export const tableStyles = {
  '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
    padding: '0px 8px 0px 24px',
  },
  '& .MuiDataGrid-columnHeader:first-of-type .MuiDataGrid-columnSeparator': {
    visibility: 'visible',
  },
  '& .MuiDataGrid-columnHeader:nth-last-of-type(-n+2) .MuiDataGrid-columnSeparator': {
    visibility: 'hidden',
  },
  '& .MuiDataGrid-columnHeader:nth-last-of-type(-n+1) .MuiDataGrid-columnSeparator': {
    visibility: 'hidden',
  },
  '&.MuiDataGrid-root .MuiDataGrid-row': {
    '.MuiBox-trash': {
      opacity: 0,
    },
    '&:hover .MuiBox-trash': {
      opacity: 1,
    },
  },
  '&.MuiDataGrid-root .MuiDataGrid-row:last-child': {
    // NO REMOVE => Important to add the Bottom removed in &.MuiDataGrid-root .MuiDataGrid-cell
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },

  '&.MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell': {
    // NO REMOVE => Important to show the form errors
    overflow: 'visible !important',
  },
  '&.MuiDataGrid-root .MuiDataGrid-cell': {
    // NO REMOVE BUT REQUIRE IMPROVEMENT TO ADD borderBottom TO columns.length
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    borderBottom: 'none',
  },

  '& .MuiDataGrid-pinnedRows': {
    bgcolor: '#fff',
    boxShadow: 'none ',
    zIndex: 1,
  },
  '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within, .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 'bold',
  },
  '& .MuiCheckbox-root svg': {
    width: 16,
    height: 16,
    backgroundColor: 'transparent',
    border: `2px solid #0000008a`,
    borderRadius: '2px',
  },
  '& .MuiCheckbox-root.Mui-checked svg': {
    width: 20,
    height: 20,
    backgroundColor: 'transparent',
    border: `0px`,
  },
  '& .MuiCheckbox-root svg path': {
    display: 'none',
  },
  '& .MuiCheckbox-root.Mui-checked svg path': {
    display: 'block',
    color: 'secondary.main',
    border: `0px solid black`,
  },
  '& .MuiDataGrid-root.MuiDataGrid-panel': {
    margin: '50px !important',
  },
};

export const mapProjects = ({ projectIdentifier, projectName, projectId }) => ({
  id: projectId,
  identifier: projectIdentifier,
  name: projectName,
});
