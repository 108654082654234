import React, { useState } from 'react';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';

import AssignEntitlementsModal from './AssignEntitlementsModal/AssignEntitlementsModal';
import { useCompanyMembersContext } from './CompanyMembersContext';
import CompanyMembersDeleteModal from './CompanyMembersTable/CompanyMembersDeleteModal';
import { ResetPasswordOption } from './CompanyMembersTable/components/ResetPasswordOption';

const CompanyMembersActions = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const { selected, setSelected, refetch } = useCompanyMembersContext();

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => {
    setModalIsOpen(false);
    refetch();
  };

  const selectedMemberIds = selected.map((user) => user.userId);
  const selectedMembersEmails = selected.map((user) => user.userEmail).filter((email) => Boolean(email));

  return (
    <Box>
      <MenuItem data-cy="add-licenses" value="add-entitlements" onClick={openModal}>
        Add Licenses
      </MenuItem>
      <ResetPasswordOption emails={selectedMembersEmails} />
      <MenuItem onClick={() => setShowDeleteModal(true)}>Delete</MenuItem>
      <CompanyMembersDeleteModal
        companyMemberIds={selectedMemberIds}
        open={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
          setSelected([]);
        }}
      />
      <AssignEntitlementsModal selectedUsers={selected} open={modalIsOpen} onClose={closeModal} />
    </Box>
  );
};

export default CompanyMembersActions;
