import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';

import { useTheme } from '@mui/material';

import { useItemsIntegrationModalContext } from 'modules/Materials/components/ItemsIntegrationModal/context/ItemsIntegrationModalContext';
import { useFilterOptions } from 'modules/Materials/features/filters/options';
import { ESearchType } from 'types/module';

import { useCurrentProjectContext } from '../../components/ItemsIntegrationModal/context/CurrentProjectContext';
import { useBOMbyIdContext } from '../BOMbyIdContext';
import { isItemACustomAssembly } from '../BOMCatalogTable/hooks/useBOMTableColumns/BOMTableColumnsHelpers';

type MenuOptionType = {
  text?: string;
  chevron?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  tooltip?: {
    title: string;
    showTooltip: boolean;
  };
};

type UseDropdownMenuOptions = {
  setOpenProjectIdentifierModal: Dispatch<SetStateAction<boolean>>;
  openModalConfirmDelete: () => void;
};

type UseDropdownMenuOptionsType = {
  menuOptions: MenuOptionType[];
};

const useDropdownMenuOptions = ({
  setOpenProjectIdentifierModal,
  openModalConfirmDelete,
}: UseDropdownMenuOptions): UseDropdownMenuOptionsType => {
  const { palette } = useTheme();
  const { setOpen, selectedItems } = useItemsIntegrationModalContext();
  const { currentProject } = useCurrentProjectContext();
  const handleOpenBOMToWRModal = useCallback(() => setOpen(true), [setOpen]);
  const { parameters: originalParameters } = useBOMbyIdContext();
  const { query } = useFilterOptions();
  const parameters = useMemo(() => {
    const { locationIds, locationsWithWorkPhases, scopePackageIds, workPhaseIds } = originalParameters;
    return { locationIds, locationsWithWorkPhases, scopePackageIds, workPhaseIds };
  }, [originalParameters]);

  const hasFilters: boolean = useMemo(
    () =>
      Boolean([...Object.values(parameters).filter((parameter) => Boolean(parameter)), ...Object.keys(query)].length),
    [parameters, query],
  );

  const handleOpenProjectIdentifierModal = useCallback(() => {
    if (currentProject?.type !== ESearchType.PROJECT) return;

    if (currentProject?.identifier) {
      handleOpenBOMToWRModal();
      return;
    }
    setOpenProjectIdentifierModal(true);
  }, [currentProject?.identifier, currentProject?.type, handleOpenBOMToWRModal, setOpenProjectIdentifierModal]);

  const enableAddToWR = useMemo(
    () => selectedItems.some(({ remainingQuantity }) => (remainingQuantity as number) > 0),
    [selectedItems],
  );

  const isAnyOfTheItemsCustomAssembly = useMemo(
    () => selectedItems.some(({ lineItemTypeId }) => isItemACustomAssembly(lineItemTypeId as string)),
    [selectedItems],
  );

  const disableAddToWR = useMemo(
    () => hasFilters || !enableAddToWR || isAnyOfTheItemsCustomAssembly,
    [enableAddToWR, isAnyOfTheItemsCustomAssembly, hasFilters],
  );

  const disableAddToWRToolTipMessage = useMemo(() => {
    if (hasFilters) return 'Cannot send items to a work request if filters are applied';
    return 'At least 1 item must have Quantity Remaining and none of the items can be a Custom Assembly';
  }, [hasFilters]);
  const isWarningTooltip = useMemo(() => {
    if (hasFilters) return true;
    return false;
  }, [hasFilters]);

  const menuOptions: MenuOptionType[] = useMemo(
    () => [
      {
        text: 'Add to Work Request',
        onClick: handleOpenProjectIdentifierModal,
        disabled: disableAddToWR,
        tooltip: {
          title: disableAddToWRToolTipMessage,
          showTooltip: disableAddToWR,
          isWarningTooltip,
        },
      },
      {
        text: 'Delete',
        onClick: () => openModalConfirmDelete(),
        sx: { color: palette.error.main },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [disableAddToWR, disableAddToWRToolTipMessage, handleOpenProjectIdentifierModal],
  );
  return { menuOptions };
};

export default useDropdownMenuOptions;
