import React from 'react';

import { useMutation, gql } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';

import { useUser } from 'app/UserContext';
import { CancelButton, DeleteButton } from 'components';
import { VARIANT_ERROR } from 'constants/snackbarConstants';
import { deleteDepartmentUsers as DELETE_DEPARTMENT_USERS } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

import { useAdminContext } from './AdminContext';
import StyledModal from './components/StyledTable';

const AdminDeleteModal = ({ userIds = [], openStatus, onClose }) => {
  const { lazyLoad } = useAdminContext();
  const { user } = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const { handleResponse } = useGraphqlResponseHandler();

  const [deleteProject, { loading }] = useMutation(gql(DELETE_DEPARTMENT_USERS), {
    refetchQueries: ['DepartmentUsers'],
  });

  const submit = async () => {
    if (userIds.includes(user.userId)) {
      enqueueSnackbar('Error: Cannot delete self from company', VARIANT_ERROR);
      return;
    }
    await handleResponse(deleteProject, {
      variables: {
        body: {
          departmentUserIds: userIds,
        },
      },
    });

    lazyLoad();
    onClose();
  };

  return (
    <StyledModal
      disableModalOutsideClick
      disableCloseButton={loading}
      open={openStatus}
      onClose={onClose}
      title="Confirm Delete"
      footer={
        <>
          {loading && <CircularProgress />}
          <CancelButton buttonText="NO" onClick={onClose} />
          <DeleteButton buttonText="CONFIRM" disabled={loading} onClick={submit} />
        </>
      }
      contentStyles={{ py: 3 }}
    >
      <Typography variant="body1" mb="12px">
        Are you sure you want to delete the selected members?
      </Typography>
    </StyledModal>
  );
};

export default AdminDeleteModal;
