import React from 'react';

import { useMutation, gql } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { Modal, CancelButton, CreateButton } from 'components';
import { deleteUsers as DELETE_USERS } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

import { useCompanyMembersContext } from '../CompanyMembersContext';

const CompanyMemberDeleteModal = ({ companyMemberIds, open, onClose }) => {
  const { refetch } = useCompanyMembersContext();
  const { handleResponse } = useGraphqlResponseHandler();
  const [deleteUsers, { loading }] = useMutation(gql(DELETE_USERS), {
    refetchQueries: ['Users'],
  });

  const submit = async () => {
    await handleResponse(deleteUsers, {
      variables: {
        body: {
          userIds: companyMemberIds,
        },
      },
    });
    refetch();
    onClose();
  };

  return (
    <Modal
      disableModalOutsideClick
      disableCloseButton={loading}
      open={open}
      onClose={onClose}
      title="Confirm Delete"
      reducePadding
      titleStyles={{ fontWeight: 'bold' }}
      maxWidth="false"
      footer={
        <>
          {loading && <CircularProgress />}
          <CancelButton onClick={onClose} buttonText="NO" sx={{ fontWeight: 'bold' }} />
          <CreateButton color="error" buttonText="CONFIRM" disabled={loading} onClick={submit} />
        </>
      }
    >
      <Typography
        variant="body1"
        mb="12px"
        sx={{
          width: '500px',
          mb: '20px',
        }}
      >
        Are you sure you want to delete the selected members?
      </Typography>
    </Modal>
  );
};

export default CompanyMemberDeleteModal;
