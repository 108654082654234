export const stylesBoxCircular = {
  position: 'absolute',
  right: '8px',
  bottom: '5px',
  width: '20px',
  height: '20px',
  backgroundColor: 'common.white',
  borderRadius: '100%',
  lineHeight: '20px',
  textAlign: 'center',
  fontSize: '.8rem',
};
