import React, { useEffect, useRef } from 'react';

import { InputForesiteField } from '@evolvemep/foresite-components-ui-react';
import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useForm } from 'react-hook-form';

import { alreadyExistsRule, requiredRule } from 'constants/inputFieldRules';
import { errorColors } from 'styles/theme/colors';

import { NEW_WORKCELL_ID } from '../WorkCellsAndMachines/constants/constants';
import { useWorkCellsAndMachinesContext } from '../WorkCellsAndMachines/contexts/WorkCellsAndMachinesContext';
import useWorkCellFormValidation from './useWorkCellFormValidation';

const getWorkCellDescription = (workCell, workCellDescriptionForm) => {
  if (workCell?.workCellId === NEW_WORKCELL_ID) return '';
  if (Object.prototype.hasOwnProperty.call(workCell, 'workCellDescription')) return workCell?.workCellDescription || '';
  if (workCellDescriptionForm) return workCellDescriptionForm;
  return '';
};

// eslint-disable-next-line max-lines-per-function
const WorkCellEditForm = ({ workCell }) => {
  const { workCells, saveWorkCellForm } = useWorkCellsAndMachinesContext();
  const {
    control,
    reset,
    clearErrors,
    watch,
    setValue,
    getValues,
    formState: { isValid },
  } = useForm({
    mode: 'all',
    defaultValues: {
      workCellName: workCell?.workCellName || '',
      workCellDescription: workCell?.workCellDescription || '',
    },
  });

  const formValues = watch();

  const watchWorkCellName = watch('workCellName', '');
  const watchWorkCellDescription = watch('workCellDescription', '');

  const { workCellNameWarning, workCellDescriptionWarning } = useWorkCellFormValidation({
    watchWorkCellName,
    watchWorkCellDescription,
    setValue,
    clearErrors,
  });

  const inputRef = useRef();

  const submit = async () => {
    if (isValid) {
      const hasAnyDifference = !Object.keys(formValues)
        .map((key) => formValues[key]?.trim() === workCell[key]?.trim())
        .every((result) => result === true);
      if (hasAnyDifference) saveWorkCellForm(formValues);
    }
  };

  const validateNextFocus = (event) => {
    const hasClickedInOtherField = Boolean(event?.relatedTarget?.value);
    if (!hasClickedInOtherField) submit(event);
  };

  const handleKeyUp = (event) => {
    if (event.key === 'Enter') submit(event);
  };

  const siblingNames = workCells
    .filter(({ workCellId }) => workCellId !== workCell.workCellId)
    .map(({ workCellName }) => workCellName);

  useEffect(() => {
    if (!workCell.noFocus) inputRef?.current?.focus();
  }, [workCell]);

  useEffect(() => {
    reset({
      workCellName: workCell?.workCellName || '',
      workCellDescription: getWorkCellDescription(workCell, getValues('workCellDescription')),
    });
  }, [workCell, reset, getValues]);

  return (
    <Stack spacing={2}>
      <TextField
        inputRef={inputRef}
        maxLength={32}
        control={control}
        name="workCellName"
        id="workCellName"
        label="Work Cell Name"
        onKeyUp={handleKeyUp}
        onBlur={validateNextFocus}
        inputProps={{
          maxLength: 32,
        }}
        rules={{
          ...requiredRule('workCellName', '*Required'),
          ...alreadyExistsRule(siblingNames, 'Name already exists'),
        }}
      />
      {watchWorkCellName?.length === 32 && workCellNameWarning && <ErrorTypography>32/32</ErrorTypography>}
      <TextField
        maxLength={255}
        control={control}
        name="workCellDescription"
        id="workCellDescription"
        label="Description"
        onKeyUp={handleKeyUp}
        inputProps={{
          maxLength: 255,
        }}
        onBlur={validateNextFocus}
      />
      {watchWorkCellDescription?.length === 255 && workCellDescriptionWarning && (
        <ErrorTypography>255/255</ErrorTypography>
      )}
    </Stack>
  );
};

export default WorkCellEditForm;

const ErrorTypography = ({ children }) => (
  <Typography
    align="right"
    color={errorColors.main}
    fontSize="12px"
    sx={{
      marginRight: '14px',
      marginTop: '0px !important',
    }}
  >
    {children}
  </Typography>
);

const TextField = ({ ...rest }) => <InputForesiteField fullWidth size="small" color="secondary" {...rest} />;
