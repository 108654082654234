import React from 'react';

import { Box, Typography } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';

import { InputImage } from 'components';
import { surfaceColors } from 'styles/theme/colors';

const TaskTypeImageField = ({ name, disabled }) => {
  const { control } = useFormContext();
  const {
    field: { value },
  } = useController({
    name,
    control,
  });

  return (
    <Box display="flex">
      <Box>
        <InputImage
          control={control}
          name="taskTypeImage"
          label="Task Type Image"
          accept="image/png, image/gif, image/jpeg"
          defaultValue={value}
          disabled={disabled}
          sx={{ width: 228, height: 184, cursor: 'pointer' }}
          withHover
          withEdit
        />
      </Box>
      <Box ml={2}>
        <Typography
          fontSize={12}
          fontWeight={400}
          width="155px"
          lineHeight="24px"
          color={surfaceColors.lightSurface.secondary}
        >
          Select the image you wish to upload. Images should not be more than 2MB in size. Only GIF,
          PNG, or JPEG files are accepted.
        </Typography>
      </Box>
    </Box>
  );
};

export default TaskTypeImageField;
