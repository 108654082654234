import React from 'react';

import { Stack, SxProps } from '@mui/material';

type ComponentPaneHeaderProps = {
  children: React.ReactNode;
  sx?: SxProps;
};

const ComponentPaneHeader = ({ children, sx, ...restProps }: ComponentPaneHeaderProps) => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    sx={{ ...sx, height: '48px', maxHeight: '48px', width: '100%' }}
    {...restProps}
  >
    {children}
  </Stack>
);

export default ComponentPaneHeader;
