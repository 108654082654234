import React from 'react';

import { Box, Typography } from '@mui/material';

import { IPartNodeFooter } from './@types/partTypeNodeTypes';

const PartNodeFooter: React.FC<IPartNodeFooter> = ({
  qty,
  unitOfMeasureCode = '',
  isMainPartItem = false,
}: IPartNodeFooter) => (
  <Box
    style={{
      paddingLeft: '8px',
      display: 'flex',
      flexDirection: 'row',
      width: 191,
      height: 26,
      backgroundColor: 'rgba(250, 250, 250, 1)',
      columnGap: '16px',
    }}
    color={({ palette }: any) => palette.surface.lightSurface.secondary}
  >
    {!isMainPartItem && <Typography style={{ fontSize: 12 }}>Unit Qty: {qty || 0}</Typography>}
    <Typography style={{ fontSize: 12 }}>UOM: {unitOfMeasureCode}</Typography>
  </Box>
);

export default PartNodeFooter;
