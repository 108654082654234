import React from 'react';

import { useAccordionContext } from './PartItemDetailAccordionContext';

const PartItemDetailAccordionContent = ({ children }) => {
  const { opened } = useAccordionContext();

  if (!opened) return <></>;

  return <>{children}</>;
};

export default PartItemDetailAccordionContent;
