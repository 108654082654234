import React, { useMemo } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import ActionsDropdown from 'components/TableComponents/ActionsDropdown/ActionsDropdown';
import Search from 'components/TableComponents/Search';

import CompanyMembersActions from './CompanyMembersActions';
import { useCompanyMembersContext } from './CompanyMembersContext';
import { DeletedUserFilter, LicenseFilter } from './CompanyMembersTable/components/Filters';

const CompanyMembersHeader = ({ openInviteModal }: { openInviteModal: () => void }) => {
  const {
    selected,
    searchHandler,
    setShowDeletedUsers,
    dataProductPools,
    loadingProductPools,
    selectedLicense,
    setSelectedLicense,
  } = useCompanyMembersContext();

  const dataProductPoolsFormatted = useMemo(
    () =>
      dataProductPools.map((item) => ({
        title: item.label,
        value: item.productPoolId,
      })),
    [dataProductPools],
  );

  return (
    <HeaderWrapper>
      <Typography variant="h5" gutterBottom>
        Company Members
      </Typography>
      <Grid container justifyContent="space-between" wrap="nowrap">
        <Grid container item alignItems="center" wrap="nowrap">
          <ItemsSelected>{selected.length} Items Selected</ItemsSelected>
          <ActionsDropdown
            disabled={selected.length < 1}
            data-cy="drop-down-actions"
            menuItemsRenderer={() => <CompanyMembersActions />}
          />
        </Grid>
        <Grid container item alignItems="center" wrap="nowrap" justifyContent="flex-end">
          <LicenseFilter
            onChange={(e) => setSelectedLicense(e.target.value)}
            data={dataProductPoolsFormatted}
            disabled={loadingProductPools}
            value={selectedLicense}
          />
          <DeletedUserFilter onCheck={(e) => setShowDeletedUsers(e.target.checked)} />
          <Search
            data-cy="search-user-input"
            handleSearch={searchHandler}
            placeholder="Search…"
            disabled={false}
            sx={{
              marginLeft: '16px',
            }}
          />
          <InviteButton onClick={openInviteModal}>+ Invite Users</InviteButton>
        </Grid>
      </Grid>
    </HeaderWrapper>
  );
};

export default CompanyMembersHeader;

const HeaderWrapper = (props: React.ComponentProps<typeof Box>) => <Box mb="20px" {...props} />;

const ItemsSelected = (props: React.ComponentProps<typeof Typography>) => (
  <Typography sx={{ color: 'rgba(0, 0, 0, 0.5)' }} {...props} />
);

const InviteButton = (props: React.ComponentProps<typeof Button>) => (
  <Button
    variant="contained"
    color="secondary"
    sx={{
      minWidth: 150,
      maxHeight: 32,
      ml: '16px',
    }}
    {...props}
  />
);
