import React, { useState } from 'react';

import { Typography, Box } from '@mui/material';
import TableCell from '@mui/material/TableCell';

import SortArrow from './SortArrow';

const HeaderCell = ({
  isEmptyTab,
  header,
  sortValue,
  isSelected,
  onClick,
  styles,
  classes,
  cellStyles = {},
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [direction, setDirection] = useState('desc');

  const handleHeaderClick = () => {
    if (sortValue) {
      const newDirection = direction === 'desc' ? 'asc' : 'desc';
      setDirection(newDirection);
      onClick(sortValue, newDirection);
    }
  };

  return (
    <HeaderCellWrapper classes={classes} cellStyles={cellStyles}>
      <CellContent
        onClick={handleHeaderClick}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        isSortOption={sortValue}
      >
        {typeof header === 'string' ? (
          <Typography sx={styles}>
            <strong>{isEmptyTab ? '' : header}</strong>
          </Typography>
        ) : (
          header
        )}
        {sortValue && (
          <SortArrow isVisible={isSelected} isHovering={isHovering} direction={direction} />
        )}
      </CellContent>
    </HeaderCellWrapper>
  );
};

export default HeaderCell;

const HeaderCellWrapper = ({ children, classes, cellStyles }) => (
  <TableCell
    sx={{
      backgroundColor: 'white',
      ...classes?.tableCell,
    }}
  >
    <Box sx={{ ...cellStyles }}>{children}</Box>
  </TableCell>
);

const CellContent = ({ children, onClick, onMouseEnter, onMouseLeave, isSortOption }) => (
  <Box
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    display="flex"
    alignContent="center"
    onClick={onClick}
    sx={{
      userSelect: 'none',
      '&:hover': {
        cursor: isSortOption && 'pointer',
      },
    }}
  >
    {children}
  </Box>
);
