/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';

import { UsersAutocomplete, InputForesiteField } from '@evolvemep/foresite-components-ui-react';
import { Box, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import InputSelectField from 'components/FormComponents/InputSelectField';
import useUploadImage from 'hooks/useUploadImage';

import { useCustomAssemblyTypesContext } from '../../CustomAssemblyTypesContext';

// eslint-disable-next-line
const CustomAssemblyTypeAddForm = () => {
  const [users, setUsers] = useState([]);
  const [limit, setLimit] = useState(0);
  const [fetching, setFetching] = useState(true);
  const {
    customAssemblyTypes,
    companyUsers,
    checkCustomAssemblyNameUnique,
    checkCustomAssemblyIdentifierUnique,
    defaultAssemblyType,
  } = useCustomAssemblyTypesContext();

  const { control, getValues, setError } = useFormContext();
  const { getImage } = useUploadImage();

  const temp = [];
  customAssemblyTypes.map((val) => {
    if (val.type === 'CustomAssemblyType') {
      temp.push(val);
    }
  });
  const assemblyTypesOptions = [{ id: 1, identifier: 'Unassigned' }, ...temp].map((type) => ({
    value: type.id,
    label: type.identifier,
  }));

  useEffect(() => {
    const fetchMore = async () => {
      if (!fetching) return;

      const newLimit = limit + 10;
      const fetchingUsers = companyUsers.slice(limit, newLimit);

      const userPhotoUrls = await Promise.allSettled(fetchingUsers.map((user) => getImage(user.userPhotoId)));

      const fetchingUsersWithPhotoUrl = userPhotoUrls.reduce((result, photoUrl, index) => {
        if (photoUrl.status !== 'fulfilled') {
          return result;
        }

        const user = fetchingUsers[index];
        return [
          ...result,
          {
            ...user,
            profilePicture: photoUrl.value,
          },
        ];
      }, []);

      setUsers((prev) => [...prev, ...fetchingUsersWithPhotoUrl]);
      setLimit(newLimit);
      setFetching(false);
    };

    fetchMore();
  }, [fetching, limit, companyUsers, getImage]);

  const checkIdExistence = async () => {
    const { CustomAssemblyId } = getValues();
    if (!CustomAssemblyId) return;

    const isUnique = await checkCustomAssemblyIdentifierUnique(CustomAssemblyId);
    if (!isUnique) {
      setError('CustomAssemblyId', { message: 'Id already exists' });
    }
  };

  const checkNameExistence = async () => {
    const { CustomAssemblyName } = getValues();
    if (!CustomAssemblyName) return;

    const isUnique = await checkCustomAssemblyNameUnique(CustomAssemblyName);
    if (!isUnique) {
      setError('CustomAssemblyName', { message: 'Name already exists' });
    }
  };

  return (
    <Box>
      <Stack mt={0}>
        <InputForesiteField
          fullWidth
          size="small"
          color="secondary"
          autoComplete="off"
          control={control}
          onBlur={checkIdExistence}
          sx={{
            '& .MuiFormHelperText-root': {
              margin: 'initial',
              marginLeft: '15px',
            },
          }}
          name="CustomAssemblyId"
          label="Custom Assembly Id"
          data-testid="custom-assembly-id"
          data-cy="custom-assembly-id"
          maxLength={10}
        />
      </Stack>
      <Stack>
        <InputForesiteField
          fullWidth
          size="small"
          color="secondary"
          autoComplete="off"
          control={control}
          onBlur={checkNameExistence}
          sx={{
            '& .MuiFormHelperText-root': {
              margin: 'initial',
              marginLeft: '15px',
            },
          }}
          name="CustomAssemblyName"
          label="Custom Assembly Name*"
          data-testid="custom-assembly-name"
          data-cy="custom-assembly-name"
          rules={{ required: '*Required' }}
          maxLength={64}
        />
      </Stack>
      <Stack>
        <InputSelectField
          name="AssemblyType"
          label="Assembly Type"
          color="secondary"
          size="small"
          fullWidth
          control={control}
          options={assemblyTypesOptions}
          disabled={customAssemblyTypes.length === 0}
          dataTestId="assembly-type"
          data-cy="assembly-type"
          defaultValue={defaultAssemblyType}
          selectProps={{
            SelectProps: {
              MenuProps: {
                sx: {
                  '& .MuiPaper-root': {
                    maxHeight: '300px',
                    maxWidth: '250px',
                  },
                },
              },
            },
          }}
        />
      </Stack>
      <Stack>
        <UsersAutocomplete
          name="Assignee"
          users={users}
          total={companyUsers.length}
          fullWidth
          fetching={fetching}
          setFetching={setFetching}
          data-testid="assignee"
          data-cy="assignee"
        />
      </Stack>
    </Box>
  );
};

export default CustomAssemblyTypeAddForm;
