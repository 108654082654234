import { useCallback } from 'react';

import useFileDownloader from 'hooks/useFileDownloader';
import { useTaskViewerContext } from 'modules/Shop/Fabrication/TaskViewer/TaskViewerContext';

import { Document } from '../../../types/Document';

const useHandleSingleFile = () => {
  const { getDocumentPreSignedUrl } = useTaskViewerContext();

  const { handleDownloadFile } = useFileDownloader();

  const handleDownloadSingleFile = useCallback(
    async (document: Document) => {
      const { storagePath, documentNameFormatted } = document;
      const documentUrl = await getDocumentPreSignedUrl(storagePath);
      handleDownloadFile(documentUrl, documentNameFormatted);
    },
    [getDocumentPreSignedUrl, handleDownloadFile],
  );

  return { handleDownloadSingleFile };
};

export default useHandleSingleFile;
