import { useCallback } from 'react';

const parseStringToFloat = (str = 0) => {
  if (Number.isNaN(Number(str))) return 0;
  return Number(str);
};

const useCreateTakeoffPadItem = ({
  pads,
  padIndex,
  manufacturers,
  onAddNewManufacturer,
  user,
  onAddTakeOffPadItem,
  loadingAddTakeOffPadItem,
  partLineItemType,
  selectedPartRef,
  type,
  resetForm,
  partName,
  getValues,
  assignMetadata,
  formState,
}) =>
  useCallback(async () => {
    if (loadingAddTakeOffPadItem) return;
    const isValid = Object.keys(formState.errors ?? {}).length === 0;
    if (!isValid) return;
    const selectedPad = pads[padIndex];
    if (!selectedPad || selectedPad.isSourcePad) return;
    const isNew = type === 'new';

    const { uom, manufacturer, catalogNumber, quantity, unitQuantity, tradeSize } = getValues();

    // Manufacturer autocomplete is free-solo. User can create a new manufacturer.
    let manufacturerId;
    if (typeof manufacturer !== 'object') {
      const manufacturerEl = manufacturers.find(({ id, label }) => [id, label].includes(manufacturer));
      if (manufacturerEl) {
        manufacturerId = manufacturerEl.id;
      } else {
        const { data: manufacturerRes } = await onAddNewManufacturer({
          companyId: user?.companyId,
          manufacturerName: manufacturer,
        });
        manufacturerId = manufacturerRes?.addManufacturer?.manufacturerId;
      }
    } else {
      manufacturerId = manufacturer.id;
    }

    const catalog = catalogNumber ?? selectedPartRef?.current?.manufacturerCatalogNumber;
    const manufacturerCatalogNumber = catalog;
    const dynamicValues = !isNew
      ? {
          partId: selectedPartRef.current.partId,
          lineItemName: selectedPartRef.current.partName,
        }
      : {
          lineItemName: partName,
        };
    const { takeoffPadId } = selectedPad;
    const items = [
      {
        lineItemTypeId: partLineItemType,
        unitOfMeasureId: uom?.value,
        manufacturerId,
        quantity: parseStringToFloat(quantity),
        unitQuantity: parseStringToFloat(unitQuantity),
        manufacturerCatalogNumber,
        tradeSize,
        ...dynamicValues,
        ...assignMetadata,
      },
    ];

    await onAddTakeOffPadItem({
      takeOffPadId: takeoffPadId,
      items,
    });
    resetForm();
  }, [
    selectedPartRef,
    pads,
    padIndex,
    partLineItemType,
    manufacturers,
    onAddNewManufacturer,
    user,
    onAddTakeOffPadItem,
    loadingAddTakeOffPadItem,
    type,
    resetForm,
    partName,
    getValues,
    assignMetadata,
    formState,
  ]);

export default useCreateTakeoffPadItem;
