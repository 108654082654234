import React, { ReactNode, useEffect, useState } from 'react';

import { removeDuplicates } from 'helpers/arrayFunctions';

export interface IEditQuantityModal {
  uniqueFilteredIds: string[];
  uniqueCheckedIds: string[];
  setSelectedIdsForFilter: (newValues: string[][]) => void;
  addOrRemoveCheckedBoxParentIds: (parentIds: any[], isAdding: boolean) => void;
  removeIdsClickedInChip: (deletedId: string) => void;
}

export interface IChipList {
  id: string;
  chipName: string;
  filterType: string;
  locationId: string | null | undefined;
  workPhaseId: string | null | undefined;
}

const EditQuantityModalContext = React.createContext<IEditQuantityModal | undefined>(undefined);

const EditQuantityModalProvider = ({ children, chipList }: { children: ReactNode; chipList: IChipList[] }) => {
  const [selectedIdsForFilter, setSelectedIdsForFilter] = useState<string[][]>([]);
  const [uniqueFilteredIds, setUniqueFilteredIds] = useState<string[]>([]);
  const [uniqueCheckedIds, setUniqueCheckedIds] = useState<string[]>([]);

  const addOrRemoveCheckedBoxParentIds = (parentIds: string[] = [], isAdding = false) => {
    const validIds: string[] = parentIds?.filter((id) => id);
    if (isAdding) setSelectedIdsForFilter((prevIds) => [...prevIds, validIds]);
    else {
      setSelectedIdsForFilter((prevIds) =>
        prevIds.filter((ids) => !ids.every((element) => validIds.includes(element))),
      );
    }
  };

  const removeIdsClickedInChip = (deletedId: string) => {
    if (deletedId) setSelectedIdsForFilter((prevIds) => prevIds.filter((ids) => !(ids[ids.length - 1] === deletedId)));
  };

  useEffect(() => {
    const allFilteredIds = selectedIdsForFilter?.reduce((acc, ids) => [...acc, ...ids], []);

    setUniqueFilteredIds(removeDuplicates(allFilteredIds));
  }, [selectedIdsForFilter]);

  useEffect(() => {
    const ids = chipList?.map((chip) => chip.id) ?? [];
    setUniqueCheckedIds(ids);
  }, [chipList]);

  const selectedStateObj: IEditQuantityModal = {
    uniqueFilteredIds,
    uniqueCheckedIds,
    setSelectedIdsForFilter,
    removeIdsClickedInChip,
    addOrRemoveCheckedBoxParentIds,
  };

  return <EditQuantityModalContext.Provider value={selectedStateObj}>{children}</EditQuantityModalContext.Provider>;
};

const useEditQuantityModal = () => {
  const context = React.useContext(EditQuantityModalContext);
  if (context === undefined) {
    throw new Error('useEditQuantityModal must be used within a EditQuantityModalContext');
  }
  return context;
};

export { EditQuantityModalContext, EditQuantityModalProvider, useEditQuantityModal };
