import React, { useState, useEffect, useMemo, useContext } from 'react';

import { useGridApiRef } from '@mui/x-data-grid-pro';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import ComponentPaneContent from 'app/Layout/ReactGridLayout/ComponentPaneContent';
import BOMItemsGroup from 'components/BOMItemsGroup';
import { Custom } from 'components/Icons';
import { ItemsDataGridProProvider } from 'components/ItemsDatagridPro';
import ItemsDataGridPro from 'components/ItemsDatagridPro/ItemsDatagridPro';
import { usePopoverFilterContext } from 'components/Popover/FilterPopover/context/PopoverFilterContext';
import SpoolsItemsGroup from 'components/SpoolsItemsGroup';
import useFetchWorkRequests from 'hooks-api/useFetchWorkRequests';
import useFetchWorkRequestsStatusType from 'hooks-api/useFetchWorkRequestsStatusType';
import { DrawingRegisterContext } from 'modules/Design/DrawingRegister';
import { formatFiltersToQueryParams } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestFilter/filterHelpers';

import WorkRequestDeleteModal from '../Components/WorkRequestDeleteModal';
import { useWorkRequestsContext } from '../WorkRequestsContext';
import DefaultMessagesWorkRequestsList from './DefaultMessagesWorkRequestsList';
import {
  useEffectThatUpdatesWorkRequestList,
  useEffectThatFetchesWorkRequests,
  WorkRequestAttachments,
  CellComponentStatus,
  sortWorkRequestList,
  CellComponentDays,
  CellComponentLink,
  MoreMenuCell,
  CellComponentWRName,
  CellComponentLinkResponsive,
} from './WorkRequestListElements';
import { useEffectThatHandlesHoverOnWorkRequests } from './WorkRequestListElements/useEffectThatHandlesHoverOnWorkRequests';
import { useEffectThatUpdatesFilterData } from './WorkRequestListElements/useEffectThatUpdatesFilterData';
import { useEffectThatUpdatesSelectedWorkRequest } from './WorkRequestListElements/useEffectThatUpdatesSelectedWorkRequest';
import { useFetchWorkRequestToFilters } from './WorkRequestListElements/useFetchWorkRequestToFilters';
import WorkRequestsColumns from './WorkRequestsTableColumns';

const WorkRequestsList = ({ workRequestSelected, isResponsive }) => {
  const apiRef = useGridApiRef();
  const { filters, setDisabledFilters, setDisabledNew, setWorkRequest } = useWorkRequestsContext();
  const { selectedItem } = useFacilitiesProjects();

  const { getWorkRequests, workRequests, loading } = useFetchWorkRequests();
  const { loading: loadingStatusType, workRequestsStatus } = useFetchWorkRequestsStatusType();
  const { filters: filtersPopover, clearFilters, setColumns, columnsBackup } = usePopoverFilterContext();

  const [workRequestsList, setWorkRequestsList] = useState([]);
  const [hoveredRequestId, setHoveredRequestId] = useState(null);
  const [deletingRequestId, setDeletingRequestId] = useState(null);
  const [deletingRequestIdentifier, setDeletingRequestIdentifier] = useState(null);
  const [workRequestDeleteModalOpen, setWorkRequestDeleteModalOpen] = useState(false);
  const [orderBy, setOrderBy] = useState({ field: 'needBy', sort: 'asc' });

  const formattedFilters = useMemo(() => formatFiltersToQueryParams(filtersPopover), [filtersPopover]);

  const drawingRegisterContext = useContext(DrawingRegisterContext);

  const realSelectedItem = drawingRegisterContext?.activeItem || selectedItem;

  const { workRequests: workRequestsFilters } = useFetchWorkRequestToFilters(
    realSelectedItem,
    filters,
    workRequestsStatus,
  );

  const isCompleted = filters.includes('Completed');
  const handleSort = sortWorkRequestList(setOrderBy, workRequestsList, setWorkRequestsList);
  const getDetailPanelContent = (id) => (
    <>
      <BOMItemsGroup workRequestId={id} />
      <SpoolsItemsGroup workRequestId={id} />
    </>
  );
  const CellWorkRequestItemAttachments = WorkRequestAttachments();
  const CellComponentMoreMenu = MoreMenuCell(
    hoveredRequestId,
    setWorkRequest,
    setWorkRequestDeleteModalOpen,
    setDeletingRequestId,
    setDeletingRequestIdentifier,
  );

  useEffect(() => {
    if (workRequestSelected) setWorkRequest({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realSelectedItem.identifier]);

  useEffect(
    () => clearFilters(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [realSelectedItem.id],
  );

  useEffectThatFetchesWorkRequests(
    realSelectedItem,
    filters,
    workRequestsStatus,
    getWorkRequests,
    orderBy,
    setDisabledNew,
    setDisabledFilters,
    formattedFilters,
  );

  useEffectThatUpdatesWorkRequestList(
    workRequests,
    setWorkRequestsList,
    setDisabledNew,
    setDisabledFilters,
    filters,
    realSelectedItem,
  );

  useEffectThatUpdatesSelectedWorkRequest(workRequestSelected, workRequests, setWorkRequest);

  useEffectThatHandlesHoverOnWorkRequests(setHoveredRequestId, apiRef);

  useEffectThatUpdatesFilterData(workRequestsFilters, setColumns, realSelectedItem, workRequests, columnsBackup);

  return (
    <ComponentPaneContent>
      <ItemsDataGridProProvider contextId="WorkRequestsTable">
        <ItemsDataGridPro
          apiRef={apiRef}
          rowHeight={32}
          headerHeight={32}
          loading={loading || loadingStatusType}
          hideFooterPagination
          checkboxSelection={false}
          disableColumnMenu
          columns={WorkRequestsColumns(
            CellComponentLink,
            CellComponentLinkResponsive,
            CellComponentStatus,
            CellComponentDays,
            CellComponentMoreMenu,
            CellWorkRequestItemAttachments,
            CellComponentWRName,
            isCompleted,
            selectedItem,
            isResponsive,
          )}
          showCellHorizontalBorder
          rows={workRequestsList}
          getDetailPanelHeight={() => 'auto'}
          getDetailPanelContent={(row) => getDetailPanelContent(row?.id)}
          initialState={{ pinnedColumns: { right: ['new'] } }}
          getRowId={(row) => row?.workRequestId}
          onRowClick={({ row }) => setWorkRequest(row)}
          onSortModelChange={handleSort}
          sortingOrder={['desc', 'asc']}
          components={{
            NoRowsOverlay: () => <DefaultMessagesWorkRequestsList />,
            DetailPanelExpandIcon: () => <Custom.ArrowRightIcon />,
            DetailPanelCollapseIcon: () => <Custom.ArrowDropDownIcon color="primary" />,
          }}
          sx={getWorkRequestsListStyles()}
          data-testid="field-work-requests-list"
        />
      </ItemsDataGridProProvider>

      <WorkRequestDeleteModal
        setWorkRequest={setWorkRequest}
        openStatus={workRequestDeleteModalOpen}
        onClose={() => setWorkRequestDeleteModalOpen(false)}
        id={deletingRequestId}
        identifier={deletingRequestIdentifier}
      />
    </ComponentPaneContent>
  );
};

export default WorkRequestsList;

const getWorkRequestsListStyles = () => ({
  '& .MuiDataGrid-row': {
    width: '100% !important',
  },
  '& .MuiDataGrid-row:hover': {
    backgroundColor: '#f5f5f5',
  },
  '& .MuiDataGrid-cell': {
    borderTop: 'none !important',
    borderBottom: '1px solid rgba(224,224,224,1) !important',
  },
  '& .MuiDataGrid-pinnedColumns': {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    boxShadow: 'none',
  },
  '& .MuiDataGrid-pinnedColumnHeaders': {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    boxShadow: 'none',
  },
  '& .MuiDataGrid-row.Mui-hovered': {
    backgroundColor: '#f5f5f5',
  },
  '& .MuiTextField-root': {
    margin: 0,
  },
  'div[role=menu]': {
    '& .MuiBox-root': {
      '&.css-0, &.css-1xok4pd': {
        width: '40px;',
      },
    },
  },
  '& .MuiDataGrid-detailPanel': {
    overflow: 'inherit',
  },
});
