import { EIconType } from 'helpers/MenuIcons';

import { ROW_TYPES } from '../../constants/constants';

const DISABLED_OPTIONS = [
  { parent: ROW_TYPES.LOCATION_SYSTEM, child: ROW_TYPES.LOCATION_SYSTEM },
  { parent: ROW_TYPES.FLOOR, child: ROW_TYPES.FLOOR },
];

export const buildLocationTypeOptions = (options = [], parent, activeChip) => {
  const getIcon = (type) => {
    if (type === ROW_TYPES.ZONE) return EIconType.ZONE;
    if (type === ROW_TYPES.FLOOR) return EIconType.FLOOR;
    if (type === ROW_TYPES.LOCATION_SYSTEM) return EIconType.LOCATION_SYSTEM;
    return null;
  };

  const parentType = parent.locationTypeName;

  const siblingsTypes =
    parent?.children?.filter((child) => child.id !== activeChip.id)?.map((sibling) => sibling.locationTypeName) || [];

  const requiredType = siblingsTypes.filter((type) => type !== ROW_TYPES.WORK_PHASE)[0];

  const childrenType = activeChip.children?.length && activeChip.children[0].locationTypeName;

  const siblingsWithTheSameType = (type) => requiredType && requiredType !== type.locationTypeName;

  const isInvalidChildType = (disabledOption, { locationTypeName }) =>
    disabledOption.parent === parentType && disabledOption.child === locationTypeName;

  const isInvalidParentType = (disabledOption, option) =>
    disabledOption.parent === option.locationTypeName && disabledOption.child === childrenType;

  const shouldDisableOption = (option) => {
    if (siblingsWithTheSameType(option)) return true;
    if (option.locationTypeName === ROW_TYPES.FLOOR && parent.ascendantIsFloor) return true;
    return DISABLED_OPTIONS.some((disabledOption) => {
      if (isInvalidChildType(disabledOption, option)) return true;
      if (isInvalidParentType(disabledOption, option)) return true;
      return false;
    });
  };

  return options.map((option) => ({
    value: option.locationTypeName,
    label: option.locationTypeName,
    icon: getIcon(option.locationTypeName),
    disabled: shouldDisableOption(option),
  }));
};

const locationIs = (askedLocations, item) => askedLocations.includes(item.locationTypeName);

const getLocationTypeId = (selectedType, locationTypes) =>
  locationTypes.find((type) => type.locationTypeName === selectedType)?.locationTypeId;

const getLocationFormatId = (selectedFormat, formats) =>
  formats.find((format) => format.locationFormatName === selectedFormat)?.locationFormatId;

const getNamePrefix = (chip) => (locationIs([ROW_TYPES.LOCATION_SYSTEM], chip) ? '' : chip.namePrefix);

export const mapChipsToSingleLocationBackend = (projectId, locationTarget, newLocation, types) => ({
  projectId,
  locationTypeId: getLocationTypeId(newLocation.locationTypeName, types),
  locationIdentifier: newLocation.locationIdentifier,
  sortOrder: newLocation.sortOrder,
  locationName: locationIs([ROW_TYPES.LOCATION_SYSTEM], newLocation)
    ? null
    : newLocation.locationName,
  parentLocationId: locationIs([ROW_TYPES.PROJECT], locationTarget)
    ? null
    : locationTarget.locationId,
});

export const buildWizardBody = (projectId, activeRow, chips, types, formats, depth = 0) => {
  const getParentLocationId = () =>
    locationIs([ROW_TYPES.PROJECT], activeRow) || depth > 0 ? null : activeRow.locationId;

  const getProjectId = () => (depth === 0 ? projectId : null);

  const getLocationIdentifier = (chip) => (locationIs([ROW_TYPES.LOCATION_SYSTEM], chip) ? chip.namePrefix : null);

  return chips.reduce((arr, chip) => {
    let children = null;
    const locationsChildren = chip.children?.filter((child) => !child.workPhase && !child.isDraft);
    const workPhasesChildren = chip.children?.filter((child) => child.workPhase && !child.isDraft);

    const getWorkPhases = () => {
      if (!workPhasesChildren) return null;

      const finalWorkPhases = [];
      workPhasesChildren.forEach((chip) => {
        chip.workPhase.forEach((workPhase) => {
          if (workPhase.isNew) {
            finalWorkPhases.push({
              scopePackageId: chip.scopePackage,
              workPhaseName: workPhase.label,
            });
          } else {
            finalWorkPhases.push({ workPhaseId: workPhase.value });
          }
        });
      });
      return finalWorkPhases;
    };

    if (locationsChildren)
      children = buildWizardBody(
        projectId,
        activeRow,
        locationsChildren,
        types,
        formats,
        depth + 1,
      );

    const mappedLocation = {
      projectId: getProjectId(),
      locationTypeId: getLocationTypeId(chip.locationTypeName, types),
      locationFormatId: getLocationFormatId(chip.locationFormatId, formats),
      locationIdentifier: getLocationIdentifier(chip),
      parentLocationId: getParentLocationId(),
      numberOfNodes: chip.numberOfNodes || 1,
      identifierPrefix: chip.identifierPrefix,
      identifierDelimiter: chip.identifierDelimiter === 'none' ? null : chip.identifierDelimiter,
      startOn: chip.startOn,
      namePrefix: getNamePrefix(chip),
      nameDelimiter: chip.nameDelimiter === 'none' ? null : chip.nameDelimiter,
      workPhases: getWorkPhases(),
      childNodes: children,
    };

    Object.keys(mappedLocation).forEach((key) => {
      const isEmptyArray = Array.isArray(mappedLocation[key]) && !mappedLocation[key].length;
      if (!mappedLocation[key] || isEmptyArray) delete mappedLocation[key];
    });

    arr.push(mappedLocation);
    return arr;
  }, []);
};
