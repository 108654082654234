import React from 'react';

import { InputForesiteField } from '@evolvemep/foresite-components-ui-react';

import { ControlledInputCheckbox } from 'components';
import { customMaxLengthRule } from 'constants/inputFieldRules';

const CreateShopConfigurationForm = ({ control, configurationNameInputRules }) => (
  <form>
    <TextField
      required
      control={control}
      name="ShopConfigurationName"
      label="Shop Configuration Name*"
      rules={configurationNameInputRules}
    />
    <TextField
      control={control}
      name="ShopConfigurationDescription"
      label="Shop Configuration Description"
      multiline
      rules={customMaxLengthRule(300, 'Description limited to 300 characters')}
    />
    <ControlledInputCheckbox
      color="secondary"
      name="IsActive"
      control={control}
      label="Make active shop configuration"
    />
  </form>
);

export default CreateShopConfigurationForm;

const TextField = ({ control, name, label, required, onBlur, rules, ...rest }) => (
  <InputForesiteField
    onBlur={onBlur}
    name={name}
    control={control}
    label={label}
    {...rest}
    size="small"
    color="secondary"
    rules={{
      ...rules,
      ...(required && {
        required: `${label.replace('*', '')} is required`,
      }),
    }}
  />
);
