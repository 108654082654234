import React from 'react';

import { useMutation, gql } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { CancelButton, CreateButton } from 'components';
import AssignAdminControlledInput from 'components/FormComponents/AssignAdminControlledInput';
import { VARIANT_ERROR } from 'constants/snackbarConstants';
import { addDepartmentUser as ADD_DEPARTMENT_USER } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

import { useAdminContext } from './AdminContext';
import StyledModal from './components/StyledTable';

const AddAdminModal = () => {
  const { inviteModalShown, closeInviteModal, data } = useAdminContext();
  const { selectedItem, shopDepartmentId } = useFacilitiesProjects();
  const { enqueueSnackbar } = useSnackbar();

  const { handleResponse } = useGraphqlResponseHandler(closeInviteModal);
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: 'all',
  });

  const [addDepartmentUserMutation, { loading: addNewLoading }] = useMutation(gql(ADD_DEPARTMENT_USER), {
    refetchQueries: ['DepartmentUsers'],
  });

  const loading = addNewLoading;

  const submit = async ({ adminUserEmail }) => {
    const users = data?.departmentUsers || [];

    if (users.find((member) => member.user.userId === adminUserEmail.id)) {
      enqueueSnackbar(`Error: ${adminUserEmail.label} is already a member of ${selectedItem.label}`, VARIANT_ERROR);
      return;
    }

    const body = {};
    if (typeof adminUserEmail === 'object') {
      body.userId = adminUserEmail.id;
    } else {
      body.userEmail = adminUserEmail;
    }

    await handleResponse(
      addDepartmentUserMutation,
      {
        variables: {
          body: { ...body, departmentId: shopDepartmentId, isAdmin: true },
        },
      },
      { successMessage: 'Admin successfully added!' },
    );
  };

  return (
    <StyledModal
      title="Add Admin"
      open={inviteModalShown}
      onClose={closeInviteModal}
      footer={
        <>
          {loading && <CircularProgress />}
          <CancelButton disabled={loading} onClick={closeInviteModal} />
          <CreateButton
            buttonText="ADD"
            color="secondary"
            disabled={!isValid || loading}
            onClick={handleSubmit(submit)}
          />
        </>
      }
    >
      <AssignAdminControlledInput control={control} sx={{ margin: 0 }} disabled={loading} />
    </StyledModal>
  );
};

export default AddAdminModal;
