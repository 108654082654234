import { gql, useMutation } from '@apollo/client';

import { updateTaskMultiple as UPDATE_MULTIPLE_TASKS } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

const useUpdateMultipleTasks = () => {
  const { handleResponse } = useGraphqlResponseHandler();
  const [updateWorkOrderMutation, { loading }] = useMutation(gql(UPDATE_MULTIPLE_TASKS));

  const updateMultipleTasks = async ({ body, successCallback }) => {
    await handleResponse(
      updateWorkOrderMutation,
      {
        variables: {
          body,
        },
      },
      {
        successMessage: '',
      },
      successCallback,
    );
  };

  return { loadingMultipleTasks: loading, updateMultipleTasks };
};

export default useUpdateMultipleTasks;
