import { useState } from 'react';

import { gql, useMutation } from '@apollo/client';
import { DragStart, DropResult } from 'react-beautiful-dnd';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { updateWorkOrder as UPDATE_WORK_ORDER } from 'graphql/mutations';
import { workOrders as WORKORDERS } from 'graphql/queries';
import { WorkOrder } from 'graphql/types';
import { useQueryWorkOrderStatusesList } from 'hooks-api/queries/workOrderStatusesList/useQueryWorkOrderStatusesList';
import useCache from 'hooks/useCache';

import { buildVariablesUpdateWorkOrder, buildWorkOrderReadCacheVariables } from '../../WorkOrdersKanbanHelpers';
import getNewWorkOrdersSortOrder from '../getNewWorkOrderSortOrder';
import useGetWorkOrdersFromCache from '../useGetWorkOrdersFromCache';

const useDragAndDropWorkOrdersKanban = () => {
  const { data } = useQueryWorkOrderStatusesList({
    options: { fetchPolicy: 'cache-first' },
  });
  const workOrderStatusesList = data?.workOrderStatusesList;
  const { selectedItem } = useFacilitiesProjects();
  const [updateWorkOrder, { loading }] = useMutation(gql(UPDATE_WORK_ORDER));

  const { writeCache } = useCache();
  const [disableColumns, setDisableColumns] = useState<string | null>(null);
  const [originalCache, setOriginalCache] = useState<Array<WorkOrder> | null>(null);

  const { getWorkOrders } = useGetWorkOrdersFromCache();

  const onDragStart = (start: DragStart) => setDisableColumns(start?.source?.droppableId);

  const onDragEnd = async (result: DropResult) => {
    const { destination, source } = result;

    const destinationEqualsSource =
      destination?.droppableId === source.droppableId && destination.index === source.index;

    if (!destination || !selectedItem?.id || !workOrderStatusesList || destinationEqualsSource) return;

    const variables = buildWorkOrderReadCacheVariables(selectedItem?.id, source, workOrderStatusesList);
    if (!variables) return;

    const workOrders = getWorkOrders(variables);
    if (!workOrders) return;

    const sourceList = [...workOrders];
    const newWorkOrders = getNewWorkOrdersSortOrder(sourceList, source.index, destination.index);

    setOriginalCache(workOrders);

    writeCache(gql(WORKORDERS), variables, { workOrders: newWorkOrders });

    await updateWorkOrder({
      variables: buildVariablesUpdateWorkOrder(result, sourceList, destination.index),
      onError: () => writeCache(gql(WORKORDERS), variables, originalCache),
    });

    setDisableColumns(null);
  };

  return { onDragStart, onDragEnd, disableColumns, updatingWorkOrder: loading };
};

export default useDragAndDropWorkOrdersKanban;
