import React from 'react';

import { Stack } from '@mui/material';

const FilterPopoverHeader = ({ children, ...props }) => (
  <Stack
    sx={{
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
      gap: '8px',
      flexWrap: 'wrap',
      width: '100%',
    }}
    data-cy="filter-popover-header"
    {...props}
  >
    {children}
  </Stack>
);

export default FilterPopoverHeader;
