import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useForm } from 'react-hook-form';

import { removeDuplicates } from 'helpers/arrayFunctions';
import useLBSAPI from 'hooks-api/useLBSAPI';
import { getElementId } from 'modules/Materials/commons/FilterPopoverContent/Breakdown/HelperElements';

import { IRowContext, IRowProvider } from '../@types/modalContextTypes';
import useGetLocations from '../hooks/useGetLocations';
import { useModal } from './ModalContext';
import { useProjectRow } from './ProjectRowContext';

const RowContext = React.createContext<IRowContext | undefined>(undefined);

// eslint-disable-next-line max-lines-per-function
const RowProvider = ({ item, type = 'Location', parentIds = [], parentId, children }: IRowProvider) => {
  const [disableInput, setDisableInput] = useState(false);
  const [amountLoaded, setAmountLoaded] = useState(false);
  const {
    ern,
    nodeTree,
    updateIsLoading,
    parentFilteredIds,
    filteredCheckedIds,
    setFormDirty,
    addNodeToTree,
    setCalculationNodeIds,
    updateParentsAggregatedQty,
    handleLoadingUpdate,
    findItem,
    setValueOnFocus,
  } = useModal();

  const { projectVisualTotalQty, loadingPatchBulk2LBS } = useProjectRow();

  const isDepth1 = parentIds?.length === 0;

  const {
    data,
    fetchRetrieveLBS,
    loading: loadingAmount,
    loadingPatchLBS,
    loadingCreteLBS,
    called,
  } = useLBSAPI(ern, item.locationId, item.workPhaseId);

  const itemId: string = getElementId(item || {});
  const isNotFilterChecked = Boolean(filteredCheckedIds.length && !filteredCheckedIds.includes(itemId));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const nodeTreeItem = useMemo(() => findItem(itemId), [itemId, nodeTree]);

  const requiredQty = useMemo((): number => nodeTreeItem?.amount || 0, [nodeTreeItem]);
  const aggregatedQty = useMemo((): number => nodeTreeItem?.aggregate || 0, [nodeTreeItem]);

  const { control, trigger, setValue } = useForm<{ [key: string]: number }>({
    mode: 'onChange',
    defaultValues: {
      [itemId]: requiredQty,
    },
  });

  const setLastValidQtyToInput = async () => {
    await setValue(itemId, requiredQty);
    trigger();
  };

  const handleBlur = async (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    let inputValue = Number(event?.target?.value);

    setValueOnFocus(null);
    setCalculationNodeIds([]);

    // if the value is invalid, it resets the input
    if (Number.isNaN(inputValue) || inputValue < 0) return setLastValidQtyToInput();
    if (!event?.target?.value?.length) inputValue = 0;

    return null;
  };

  const validateEnterKey = useCallback(
    (event: any) => {
      if ((!event?.target?.value?.length || event?.target?.value <= 0) && event.keyCode === 40) {
        event.preventDefault();
      }
      if (event.key === 'Enter') {
        if (event?.target?.value?.length && event?.target?.value >= 0) {
          handleBlur(event);
          setDisableInput(true);
          setTimeout(() => {
            setDisableInput(false);
          }, 0);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, projectVisualTotalQty],
  );

  const getIdsToBeUpdated = () => {
    const newCalculatedIds = parentIds;
    if (type === 'WorkPhase') newCalculatedIds.push(item?.workPhaseId);
    newCalculatedIds.push(itemId);

    return removeDuplicates(newCalculatedIds);
  };

  const handleFocus = () => {
    setValueOnFocus(nodeTreeItem.amount);
    setCalculationNodeIds(getIdsToBeUpdated());
  };

  const handleChange = (event: any) => {
    trigger();
    const inputValue = Number(event?.target?.value) || 0;
    const calcDiff = -inputValue + data?.amount?.assigned?.individual;
    const isSameAsDefault = calcDiff === 0;
    setFormDirty(true);
    updateParentsAggregatedQty(false, inputValue, isSameAsDefault);
  };

  const handleResetValue = () => {
    const calcDiff = -requiredQty + data?.amount?.assigned?.individual;
    if (calcDiff === 0) return;
    updateParentsAggregatedQty(
      true,
      data?.amount?.assigned?.individual || 0,
      true, // True to set the from isModifiedDirectly to false and not send it in the patch endpoint
      calcDiff,
      itemId,
      getIdsToBeUpdated(),
    );
  };

  useEffect(() => {
    if (((!parentFilteredIds?.length || parentFilteredIds?.includes(itemId)) && addNodeToTree) || isDepth1)
      fetchRetrieveLBS({
        onCompleted: ({ billOfMaterialLineItemByLBS: successResponse }) => {
          addNodeToTree({ ...item, ...(successResponse || {}), type }, parentId, isNotFilterChecked);
          setAmountLoaded(true);
        },
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLastValidQtyToInput();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requiredQty]);

  useEffect(() => {
    handleLoadingUpdate(loadingPatchLBS);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingPatchLBS]);

  useEffect(() => {
    setValue(itemId, nodeTreeItem?.amount);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeTreeItem?.amount]);

  const selectedStateObj: IRowContext = {
    itemId,
    item,
    loadingAmount,
    data,
    requiredQty,
    aggregatedQty,
    handleBlur,
    validateEnterKey,
    handleFocus,
    handleChange,
    control,
    disableInput:
      loadingCreteLBS ||
      loadingPatchLBS ||
      loadingAmount ||
      updateIsLoading ||
      disableInput ||
      isNotFilterChecked ||
      loadingPatchBulk2LBS,
    parentIds,
    called,
    nodeTreeItem,
    handleResetValue,
    parentId,
    amountLoaded,
    ...useGetLocations(item?.locationId),
  };

  return <RowContext.Provider value={selectedStateObj}>{children}</RowContext.Provider>;
};

const useRow = () => {
  const context = React.useContext(RowContext);
  if (context === undefined) {
    throw new Error('useRow must be used within a RowContext');
  }
  return context;
};

export { RowContext, RowProvider, useRow };
