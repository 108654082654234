/* eslint-disable camelcase */

export const USER_TYPES = {
  EVOLVE_ACCOUNTING: { id: '05145251-f699-4fc6-908e-7fbf14609ac0', label: 'eVolve Accounting' },
  EVOLVE_CONSULTING_MANAGER: {
    id: '1ed73b35-daea-47f6-a3d4-cf988395ed7e',
    label: 'eVolve Consulting Manager',
  },
  EVOLVE_CE_MANAGER: { id: '20007b54-d69c-4414-8fd5-25fad6827423', label: 'eVolve CE Engineer' },
  EVOLVE_SALES_REP: { id: '46e39f0b-9df1-49c2-afa6-3b333f9e805c', label: 'eVolve Sales Rep' },
  EVOLVE_SALES_SUPPORT_ENGINEER: {
    id: '667a8577-4b54-434b-846d-8e2f4a626612',
    label: 'eVolve Sales Support Engineer',
  },
  COMPANY_ADMINISTRATOR: {
    id: '71637052-79a8-4031-8420-b44a3ae00cf7',
    label: 'Company Administrator',
  },
  EVOLVE_FACILITY_ADMINISTRATOR: {
    id: '756c1683-f2ac-440d-a1a5-eb88f73d5088',
    label: 'Facility Administrator',
  },
  EVOLVE_ENTITLEMENT_MANAGER: {
    id: '8ff7d0f7-761f-4af8-8a36-7ed5bca462b2',
    label: 'eVolve Entitlement Manager',
  },
  EVOLVE_ADMINISTRATOR: {
    id: '9e9bf6c2-5dbf-4cb2-b70e-7388e5346fef',
    label: 'eVolve Administrator',
  },
  EVOLVE_SALES_MANAGER: {
    id: 'a7af18d9-0bdd-4c1b-bb24-469f0087fc43',
    label: 'eVolve Sales Manager',
  },
};

type ContextUserStorage = {
  selectedCategory: string;
  SELECTED_CATALOG_ID: string;
  'amplify-signin-with-hostedUI': string;
  currentModule: string;
  currentPage: string;
  PROJECT_COLORS: string;
  staySignedIn: string;
  SELECTED_ITEM: string;
  PREV_CATALOG_ID_KEY: string;
  [key: string]: string;
};

type Client = {
  endpoint: string;
  fetchOptions: Record<string, any>;
};

type Pool = {
  userPoolId: string;
  clientId: string;
  client: Client;
  advancedSecurityDataCollectionFlag: boolean;
  storage: ContextUserStorage;
};

type SignInUserSession = {
  idToken: {
    jwtToken: string;
    payload: {
      sub: string;
      email_verified: boolean;
      iss: string;
      phone_number_verified: boolean;
      'cognito:username': string;
      origin_jti: string;
      aud: string;
      event_id: string;
      token_use: string;
      auth_time: number;
      exp: number;
      iat: number;
      jti: string;
      email: string;
    };
  };
  refreshToken: {
    token: string;
  };
  accessToken: {
    jwtToken: string;
    payload: {
      sub: string;
      iss: string;
      client_id: string;
      origin_jti: string;
      event_id: string;
      token_use: string;
      scope: string;
      auth_time: number;
      exp: number;
      iat: number;
      jti: string;
      username: string;
    };
  };
  clockDrift: number;
};

type ContextUserAttributes = {
  sub: string;
  email_verified: boolean;
  phone_number_verified: boolean;
  email: string;
};

type ContextUserTypes = {
  __typename: string;
  userTypeId: string;
  typeName: string;
  typeId: string;
};

export type ContextUser = {
  username: string;
  pool: Pool;
  Session: null;
  client: Client;
  signInUserSession: SignInUserSession;
  authenticationFlowType: string;
  storage: ContextUserStorage;
  keyPrefix: string;
  userDataKey: string;
  attributes: ContextUserAttributes;
  preferredMFA: string;
  companyId: string;
  userId: string;
  firstName: string;
  lastName: string;
  personalPhoneNumber: string;
  workPhoneNumber: string;
  isAdmin: boolean;
  userTypes: ContextUserTypes[];
  userPhotoId: string;
};
