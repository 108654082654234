import React from 'react';

import { Stack, Button } from '@mui/material';

export const CustomAssemblyAction = ({ params }) =>
  params.row.type === 'Custom Assembly' && (
    <Stack direction="row" alignItems="center">
      <Button color="inherit" variant="outlined" size="small" sx={{ paddingY: '0px' }} disabled>
        PROCESS
      </Button>
    </Stack>
  );
