import React from 'react';

import { Stack, Typography, Snackbar, IconButton, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

import { Custom } from 'components/Icons';
import { LOCAL_STORAGE_CONSTANTS, SESSION_STORAGE_CONSTANTS } from 'constants/globalConstants';
import { useCurrentModuleFromURL } from 'hooks/useCurrentModuleFromURL';
import { EModuleType, MODULE_URL_MAPPING } from 'types/module';

import { useCatalogModal } from './ModalToCreateCatalogPart/CatalogModalContext';

const { REACHED_CATALOG_SETUP_THROUGH_LINK, CURRENT_CATALOG, PART_CATEGORY_FROM_WR } = SESSION_STORAGE_CONSTANTS;
const { CURRENT_MODULE } = LOCAL_STORAGE_CONSTANTS;

const SendToCatalogToast = () => {
  const { handleSnackBarClose, setOpenSnackBar, openSnackBar } = useCatalogModal();
  const { searchCurrentModule } = useCurrentModuleFromURL(
    [MODULE_URL_MAPPING[EModuleType.SHOP]],
    MODULE_URL_MAPPING[EModuleType.MATERIALS],
  );

  const partCategory = JSON.parse(sessionStorage.getItem(PART_CATEGORY_FROM_WR));
  const partCategoryId = partCategory?.partCategoryID;
  const { partCatalogName } = JSON.parse(sessionStorage.getItem(CURRENT_CATALOG)) || '';
  const theme = useTheme();
  const handleLinkClick = () => {
    localStorage.setItem(CURRENT_MODULE, 'MATERIALS');
    sessionStorage.setItem(REACHED_CATALOG_SETUP_THROUGH_LINK, true);
  };
  return (
    <Snackbar
      open={openSnackBar}
      onClose={handleSnackBarClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={5000}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ backgroundColor: theme.palette.success.main, borderRadius: '5px' }}
      >
        <Custom.CheckCircleOutline style={{ fill: 'white', margin: '16px 8px 16px 16px' }} />
        <Typography sx={{ color: 'white', marginRight: '16px' }}>
          {`items successfully saved to  ${partCatalogName}`}
        </Typography>
        <Link
          component={Link}
          to={`/${searchCurrentModule()}/catalog-setup?partCategoryId=${partCategoryId}&tabIndex=1`}
          style={{ textDecoration: 'none' }}
          onClick={handleLinkClick}
        >
          <Typography sx={{ color: 'white', fontWeight: 500 }}>GO TO PART FORM</Typography>
        </Link>
        <IconButton onClick={() => setOpenSnackBar(false)} sx={{ padding: 0 }}>
          <Custom.Close style={{ fill: 'white', margin: '16px' }} />
        </IconButton>
      </Stack>
    </Snackbar>
  );
};

export default SendToCatalogToast;
