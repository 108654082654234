import React from 'react';

import { Stack, Typography } from '@mui/material';

import { Custom } from 'components/Icons';
import NavigationHeader from 'modules/Shop/Fabrication/TaskViewer/components/NavigationHeader';
import { useTaskViewerContext } from 'modules/Shop/Fabrication/TaskViewer/TaskViewerContext';
import { greyColors } from 'styles/theme/colors';

const FileNotSupported = () => {
  const { activeDocument } = useTaskViewerContext();

  return (
    <Stack sx={{ height: '100%' }}>
      {activeDocument && <NavigationHeader />}
      <Stack alignItems="center" justifyContent="center" sx={{ height: '70%' }}>
        <Custom.Plans fill={greyColors.grey400} style={{ width: 62, height: 62 }} />
        <TextTypography text="File type not supported." />
        <TextTypography text="Download to browser to view document." />
      </Stack>
    </Stack>
  );
};

const TextTypography = ({ text }) => (
  <Typography variant="h6" sx={{ color: 'surface.lightSurface.secondary', marginBottom: '5px' }}>
    {text}
  </Typography>
);

export default FileNotSupported;
