import React from 'react';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import useBreakpoint from 'hooks/useBreakpoint';

import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader/MobileHeader';
import TabletHeader from './TabletHeader';

const HeaderBar = () => {
  const { isMobile, isTablet, isAboveDesktop } = useBreakpoint();
  return (
    <HeaderBarContainer position="fixed" data-cy="app-bar">
      <ToolBarContainer>
        {isMobile && <MobileHeader />}
        {isAboveDesktop && <DesktopHeader />}
        {isTablet && <TabletHeader />}
      </ToolBarContainer>
    </HeaderBarContainer>
  );
};

export default HeaderBar;

const HeaderBarContainer = (props) => (
  <AppBar
    sx={{ backgroundColor: 'black', color: 'white', height: '64px', zIndex: 1201 }}
    {...props}
  />
);

const ToolBarContainer = (props) => <Toolbar sx={{ py: 1.5, px: 2 }} {...props} />;

// needs translation
