import React from 'react';

import { Button, Stack, Box, Typography } from '@mui/material';
import { useFieldArray, useForm } from 'react-hook-form';

import { ControlledDatePicker, CenteredLoadSpinner, ControlledInputCheckbox } from 'components';
import { formatToBackendDateString, getNextDays, TOMORROW } from 'helpers/dateFunctions';
import { darkScrollBarStyle } from 'styles/theme/scrollBarStyle';

import { useEntitlementCheckInOutForm } from './useEntitlementCheckInOutForm';

const DurationForm = () => {
  const { refreshEntitlements, updateEntitlement, data, loading, licenses, mutationLoading } =
    useEntitlementCheckInOutForm();

  const {
    control,
    watch,
    reset,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: 'all',
    defaultValues: {
      licenses,
      endDate: TOMORROW,
    },
  });

  React.useEffect(() => {
    reset({
      licenses,
      endDate: TOMORROW,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const { fields } = useFieldArray({ control, name: 'licenses' });

  const licenseValues = watch('licenses');

  const checkInEnabled = licenseValues.some((value, index) => licenses[index].isCheckedOut && value.checked);

  const checkOutEnabled = licenseValues.some(
    (value, index) => isValid && !licenses[index].isCheckedOut && value.checked,
  );

  const buildEntitlementVariables = (entitlementId, checkedOut, endDate) => {
    const variables = {
      entitlementId,
      body: {
        checkedOut,
      },
    };

    variables.body = endDate ? { ...variables.body, endDate } : variables.body;
    return variables;
  };

  const handleCheckout = (values) => {
    const formattedEndDate = formatToBackendDateString(new Date(values.endDate));

    Promise.all(
      values.licenses.map((license) => {
        if (!license?.checked || license.isCheckedOut) return null;
        const variables = buildEntitlementVariables(license.entitlementId, true, formattedEndDate);
        return updateEntitlement(variables);
      }),
    ).then(refreshEntitlements);
  };

  const onCheckIn = () => {
    handleSubmit((values) =>
      Promise.all(
        values.licenses.map((license) => {
          if (!license.checked || !license.isCheckedOut) return null;
          return updateEntitlement(buildEntitlementVariables(license.entitlementId, false));
        }),
      ),
    )().then(refreshEntitlements);
  };

  const checkedOutLicenses = licenses.filter((license) => license.isCheckedOut === true);

  const licenseCheckboxes = fields.map((field, index) => (
    <ControlledInputCheckbox
      key={field.id}
      name={`licenses.${index}.checked`}
      control={control}
      label={`${licenses[index].productPool.label} ${licenses[index].isCheckedOut ? '(Checked Out)' : ''}`}
      sx={{ ...(licenses[index].isCheckedOut ? {} : { color: 'white' }) }}
      checkboxProps={{ sx: { color: 'white' } }}
      color="default"
    />
  ));

  return (
    <>
      <CheckedOutPrompt checkedOutLicenses={checkedOutLicenses} />
      <form>
        <Stack direction="row" gap="12px" mt={2} mb={1}>
          <CheckButton disabled={!checkOutEnabled} onClick={handleSubmit(handleCheckout)}>
            check-out
          </CheckButton>
          <CheckButton disabled={!checkInEnabled} onClick={onCheckIn}>
            check-in
          </CheckButton>
        </Stack>
        <DateInput control={control} />
        {/* <DurationInput control={control} /> */}
        {loading ? <CenteredLoadSpinner darkMode /> : <LicenseWrapper>{licenseCheckboxes}</LicenseWrapper>}
        {!!mutationLoading && <CenteredLoadSpinner darkMode />}
      </form>
    </>
  );
};

export default DurationForm;

const DateInput = ({ control }) => (
  <ControlledDatePicker
    name="endDate"
    label="End Date"
    control={control}
    minDate={TOMORROW}
    maxDate={getNextDays(30)}
    sx={{ margin: '16px 0' }}
    darkTheme
  />
);

const CheckButton = ({ children, disabled, onClick }) => (
  <Button
    variant="contained"
    size="small"
    fullWidth
    disabled={disabled}
    onClick={onClick}
    sx={{
      backgroundColor: 'white',
      color: 'black',
      '&.Mui-disabled': {
        backgroundColor: 'rgba(255, 255, 255, 0.12)',
      },
    }}
  >
    {children}
  </Button>
);

const LicenseWrapper = ({ children }) => (
  <Box
    sx={{
      marginTop: 1,
      maxHeight: '160px',
      textAlign: 'left',
      overflow: 'auto',
      ...darkScrollBarStyle,
    }}
  >
    {children}
  </Box>
);

const CheckedOutPrompt = ({ checkedOutLicenses }) => (
  <>
    <Typography variant="body2" sx={{ color: 'white', fontWeight: 'bold', marginTop: '10px' }} textAlign="left">
      Checked out on this device:
    </Typography>
    <ul style={{ margin: '8px 0px 0px -16px', lineHeight: '20px' }}>
      {checkedOutLicenses.map((license) => (
        <li key={license.entitlementId}>
          <Typography textAlign="left" variant="body2">
            <strong>{license.productPool.label}</strong> (Exp.{' '}
            {new Date(license.checkedOutEndDate).toLocaleDateString()})
          </Typography>
        </li>
      ))}
    </ul>
  </>
);
