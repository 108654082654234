import { gql, useMutation } from '@apollo/client';

import { GENERIC_MUTATION_ERROR } from 'constants/globalConstants';
import { updateBillOfMaterialsItemById } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

const useUpdateWriteInName = () => {
  const { handleResponse } = useGraphqlResponseHandler();
  const [updateBOMItem, { loading, data, error }] = useMutation(gql(updateBillOfMaterialsItemById));

  const updateName = async (id, name) => {
    await handleResponse(
      updateBOMItem,
      {
        variables: { body: { lineItemName: name }, params: { id } },
        refetchQueries: ['BillOfMaterialItem'],
        onQueryUpdated(observableQuery) {
          const cachedItem = observableQuery
            .getLastResult()
            ?.data?.billOfMaterialItem?.find((billOfMaterialItem) => billOfMaterialItem.lineItemId === id);
          return Boolean(cachedItem);
        },
      },
      { successMessage: 'Name successfully updated', errorMessage: GENERIC_MUTATION_ERROR },
    );
  };

  return { updateName, loading, data, error };
};

export default useUpdateWriteInName;
