/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';

import { gql } from '@apollo/client';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';

import { ControlledChipsAutoCompleteApi, ControlledRadioGroup } from 'components/FormComponents';
import { users as USERS } from 'graphql/queries';
import useLazySearchableQuery from 'hooks/useLazySearchableQuery';

const recipientTypeOptions = [
  { label: 'All Users', value: 'all' },
  { label: 'Company Admins', value: 'admins' },
  { label: 'Custom', value: 'custom' },
];

const RecipientsForm = ({ control, recipientType }) => {
  const [displayUsersAutocomplete, shouldDisplayUsersAutocomplete] = useState(false);
  const [searchPhrase, setSearchPhrase] = useState('');

  const [{ lazyLoad, searchHandler }, { data, error }] = useLazySearchableQuery(gql(USERS), {
    extraVars: {
      take: 6,
      orderBy: 'email:asc',
    },
  });

  const debounce = (func) => {
    let timer;
    return function debouncer(...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 250);
    };
  };

  const setSearchPhraseDebounced = useCallback(debounce(searchHandler), []);

  useEffect(() => {
    lazyLoad();
    if (recipientType === recipientTypeOptions[2].value) shouldDisplayUsersAutocomplete(true);
  }, []);

  useEffect(() => {
    setSearchPhraseDebounced(searchPhrase);
  }, [searchPhrase]);

  if (error) return <p>{error.message}</p>;

  const users = data?.users || [];

  const formattedOptions = users.map(({ userEmail, userId }) => ({
    label: userEmail,
    id: userId,
  }));

  const handleChange = (event) => {
    const { value } = event.target;

    if (value !== recipientTypeOptions[2].value) {
      shouldDisplayUsersAutocomplete(false);
      return;
    }

    shouldDisplayUsersAutocomplete(true);
  };

  return (
    <RecipientsFormWrapper>
      <FormMainLabel>Select Recipient type</FormMainLabel>
      <ControlledRadioGroup
        name="recipientType"
        radioOptions={recipientTypeOptions}
        control={control}
        row
        sx={{ margin: '30px 0px 50px 0px' }}
        onChange={handleChange}
      />

      {displayUsersAutocomplete && (
        <ControlledChipsAutoCompleteApi
          name="recipients"
          options={formattedOptions}
          control={control}
          label="Enter Name"
          rules={{ validate: (values) => !values.some((value) => !value.id) }}
          style={{ marginBottom: '40px' }}
          inputValue={searchPhrase}
          setInputValue={setSearchPhrase}
        />
      )}
    </RecipientsFormWrapper>
  );
};

export default RecipientsForm;

const RecipientsFormWrapper = ({ children }) => <Box width="650px">{children}</Box>;

const FormMainLabel = ({ children }) => (
  <Box mt="30px">
    <FormLabel
      sx={{
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.25px',
        color: 'rgba(0, 0, 0, 0.87)',
      }}
    >
      {children}
    </FormLabel>
  </Box>
);
