import React from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import SwitchComponent from '@mui/material/Switch';

const AssignModeSwitch = ({ onSwitch, isAssignationModeDisabled, defaultChecked }) => (
  <FormGroup onChange={onSwitch}>
    <FormControlLabel
      disabled={isAssignationModeDisabled}
      control={<SwitchComponent color="secondary" checked={defaultChecked} />}
      label="Assign Mode"
      data-testid="AssignModeSwitch-data-testid"
    />
  </FormGroup>
);

export default AssignModeSwitch;
