import React from 'react';

import { Stack } from '@mui/material';

const FilterPopoverContent = ({ children }) => (
  <Stack
    sx={{
      width: '100%',
      maxHeight: '280px',
      overflowY: 'auto',
      overflowX: 'hidden',
      paddingTop: '14px',
    }}
  >
    {children}
  </Stack>
);

export default FilterPopoverContent;
