import React, { useEffect, useState } from 'react';

import getClient from 'apollo/client';
import useShopFacility from 'hooks/useShopFacility';

import { useOrderBy } from '../hooks/useOrderBy';
import { useWorkCellsTaskDataLayer } from '../WorkCellsList/useWorkCellsTaskDataLayer';

const WorkCellsListContext = React.createContext();
const CUSTOM_KEYS = { taskStatusTypeName: 'taskStatusTypeSortOrder' };

const WorkCellsListProvider = ({ children }) => {
  const [filters, setFilters] = useState([]);
  const [statusToFilter, setStatusToFilter] = useState(null);
  const { orderBy, handleSortOrder } = useOrderBy({ field: 'needBy', sort: 'asc' }, CUSTOM_KEYS);
  const { selectedFacility, refetchData, activeShopConfiguration, fetchInitialData, loading, workCellsData } =
    useShopFacility();

  const { getWorkCellTasks, workCellTasksData, loadingWorkCellTasks, paginationHandler } = useWorkCellsTaskDataLayer();

  const fetchWorkCellTasks = async () => {
    const client = await getClient();
    if (selectedFacility?.id && statusToFilter) {
      client.cache.evict({
        id: 'ROOT_QUERY',
        fieldName: 'workCellTasksMultiSearch',
      });

      getWorkCellTasks({ facilityIds: selectedFacility?.id, orderBy, ...filters });
    }
  };

  const paginationHandlerWorkCellsTask = (skip, vars) => {
    paginationHandler(skip, { ...vars, orderBy });
  };

  useEffect(() => {
    const fetch = async () => {
        await fetchWorkCellTasks();
    }
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFacility?.id, statusToFilter, orderBy]);

  useEffect(() => {
    if (activeShopConfiguration?.value) {
      fetchInitialData();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (activeShopConfiguration?.value && selectedFacility) {
      refetchData();
    }
    // eslint-disable-next-line
  }, [activeShopConfiguration, selectedFacility]);

  const contextObj = {
    workCellTasks: workCellTasksData,
    paginationHandlerWorkCellsTask,
    loading: loadingWorkCellTasks || loading,
    workCells: workCellsData?.workCells,
    setFilters,
    statusToFilter,
    setStatusToFilter,
    facilityId: selectedFacility?.id,
    filters,
    handleSortOrder,
  };

  return <WorkCellsListContext.Provider value={contextObj}>{children}</WorkCellsListContext.Provider>;
};

const useWorkCellsList = () => {
  const context = React.useContext(WorkCellsListContext);
  if (context === undefined) {
    throw new Error('useWorkCellsList must be used within a WorkCellsListContext');
  }
  return context;
};

export { useWorkCellsList, WorkCellsListContext, WorkCellsListProvider };
