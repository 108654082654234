import { useCallback } from 'react';

import { gql, useMutation } from '@apollo/client';

import { getDocumentPresignedURL as GET_DOCUMENT_PRESIGNED_URL } from 'graphql/mutations';

const useUploadFile = (user) => {
  const [getPresignedURL, { loading }] = useMutation(gql(GET_DOCUMENT_PRESIGNED_URL));

  const uploadFile = useCallback(
    async (file, path) => {
      const presignedURLResponse = await getPresignedURL({
        variables: {
          body: {
            expirationHours: 24,
            objectKey: path,
            requestedBy: user.userId,
            verb: 'PUT',
          },
        },
      });
      const presignedURL = presignedURLResponse.data?.getDocumentPresignedURL?.preSignedURL;
      const contentType = presignedURLResponse.data?.getDocumentPresignedURL?.contentType;
      if (!presignedURL) return '';

      await fetch(presignedURL, {
        method: 'PUT',
        mode: 'cors',
        body: file,
        headers: {
          'Content-Type': contentType,
        },
      });

      return presignedURL;
    },
    [getPresignedURL, user],
  );

  return { uploadFile, uploading: loading };
};

export default useUploadFile;
