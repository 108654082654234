import React from 'react';

import { Box, Typography } from '@mui/material';

import Section from 'components/Section/Section';
import StackRow from 'components/StackRow';

import { useItemsIntegrationTableContext } from '../../context/ItemsIntegrationTableContext';

const ItemsIntegrationHeaders = () => {
  const { addColumnHeader } = useItemsIntegrationTableContext();
  return (
    <Section.Title sx={{ minHeight: '32px', p: 0, gap: 0 }} justifyContent="space-between">
      <StackRow gap="10px">
        <Box width="67px" />
        <Typography variant="subtitle2" width="350px">
          Item
        </Typography>
        <Typography variant="subtitle2" sx={{ width: '118px' }}>
          Qty Remaining
        </Typography>
        <Typography variant="subtitle2" width="80px">
          {addColumnHeader}
        </Typography>
      </StackRow>
    </Section.Title>
  );
};

export default ItemsIntegrationHeaders;
