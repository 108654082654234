import React from 'react';

import { Box } from '@mui/material';
import { GridRow } from '@mui/x-data-grid-pro';

import { TableRowFormProvider } from '../../context/TableRowFormContext';
import ClickAwayRow from './ClickAwayRow';
import RowFormProvider from './RowFormProvider';

const EditOrCreateRow = ({ rowData }) => (
  <RowFormProvider>
    <TableRowFormProvider>
      <ClickAwayRow>
        <Box
          sx={{
            '&:not(:last-child) > div[role="row"]': {
              borderBottom: 'none !important',
            },
            '& .MuiDataGrid-row': {
              maxHeight: 'none !important',
              minHeight: 'auto !important',
            },
            '& .MuiCheckbox-root': {
              display: 'none',
            },
            '& .MuiInputBase-input': {
              fontSize: '14px',
              lineHeight: '24px',
              fontWeight: '400',
              height: '20px',
            },
            '& .MuiFormLabel-root': {
              fontSize: '14px',
              lineHeight: '20px',
            },
            '& .MuiSelect-select span': {
              lineHeight: '20px',
            },
          }}
        >
          <GridRow {...rowData} />
        </Box>
      </ClickAwayRow>
    </TableRowFormProvider>
  </RowFormProvider>
);

export default EditOrCreateRow;
