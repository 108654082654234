import React, { useState } from 'react';

import { gql } from '@apollo/client';

import { users as USERS } from 'graphql/queries';
import useSearchableQuery from 'hooks/useSearchableQuery';
import { EVOLVE_COMPANY_ID } from 'types/evolveCompany';

import { EvolveMembersProvider } from './EvolveMembersContext';
import EvolveMembersHeader from './EvolveMembersHeader';
import EvolveMembersTable from './EvolveMembersTable/EvolveMembersTable';
import InviteToEvolveModal from './InviteToEvolveModal/InviteToEvolveModal';

const headerOptions = [
  { title: 'ID', sortValue: 'userIdentifier' },
  { title: 'User', sortValue: 'name' },
  { title: 'Email', sortValue: 'email' },
  { title: 'Role' },
  { title: 'Office Phone' },
  { title: 'Mobile Phone' },
  { title: 'Status', sortValue: 'status' },
  { title: 'EMPTY' },
];

const EvolveMembers = () => {
  const [inviteModalOpen, setInviteModalOpen] = useState(false);

  const [{ searchHandler, sortHandler, paginationHandler }, { data, loading }] = useSearchableQuery(
    gql(USERS),
    {
      extraVars: {
        companyId: EVOLVE_COMPANY_ID,
      },
    },
  );

  const members = data?.users || [];

  const toggleInviteModal = () => setInviteModalOpen(!inviteModalOpen);

  return (
    <EvolveMembersProvider>
      <EvolveMembersHeader handleSearch={searchHandler} openModal={toggleInviteModal} />
      <EvolveMembersTable
        headerOptions={headerOptions}
        loading={loading}
        evolveMembers={members}
        handleSortOrder={sortHandler}
        paginationHandler={paginationHandler}
      />
      <InviteToEvolveModal open={inviteModalOpen} onClose={toggleInviteModal} userList={members} />
    </EvolveMembersProvider>
  );
};

export default EvolveMembers;
