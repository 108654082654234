import React from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { CenteredLoadSpinner } from 'components';

const Loader = () => (
  <Stack direction="row" flex="1" alignItems="center" sx={{ ml: 2 }}>
    <Typography variant="body1" flex="1">
      Loading... please wait.
    </Typography>
    <CenteredLoadSpinner theme="secondary" sx={{ mt: 0, width: 40, mr: 2.5 }} />
  </Stack>
);

export default Loader;
