import React from 'react';

import { useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { Custom } from 'components/Icons';

import { useCatalogSetupContext } from '../CatalogSetupHeader/CatalogSetupContext';
import { useCategoriesContext } from './CategoriesContext';

export const CategoriesHeader = ({ addNewCategoryButton }) => {
  const { selectedCatalog } = useCatalogSetupContext();
  const { setActiveInput } = useCategoriesContext();
  const { palette } = useTheme();

  const addNewCategory = () => {
    setActiveInput((prev) => !prev);
  };

  return (
    <>
      <Typography data-testid="categoires-list-header-title" variant="tableHeader" sx={{ flex: 1 }}>
        Categories
      </Typography>
      {addNewCategoryButton && (
        <Button
          data-testid="create-category-btn"
          color="secondary"
          onClick={addNewCategory}
          disabled={selectedCatalog == null}
        >
          <Custom.Add
            style={{
              fill:
                selectedCatalog == null
                  ? palette.action.lightSurface.disabled
                  : palette.secondary.main,
              marginRight: 5,
            }}
          />
          NEW CATEGORY
        </Button>
      )}
    </>
  );
};
