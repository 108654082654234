import React from 'react';

import { Stack, Typography } from '@mui/material';

import MiniSpinner from 'components/MiniSpinner';

const WorkOrderSpinner = ({ size = '27px' }) => (
  <Stack direction="row" alignItems="center">
    <MiniSpinner size={size} theme="secondary" />
    <Typography variant="body2" sx={{ margin: '0 15px 0 8px' }}>
      Processing...
    </Typography>
  </Stack>
);

export default WorkOrderSpinner;
