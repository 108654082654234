import React from 'react';

import { Stack, Typography } from '@mui/material';

import { WorkOrders } from 'assets/images';

const NoFacilitySelected = () => (
  <Stack direction="column" sx={{ width: '100%', alignItems: 'center', paddingTop: '250px' }}>
    <WorkOrders width={48} height={48} />
    <Typography sx={{ fontWeight: 500, fontSize: 20, opacity: 0.5 }}>
      Select a facility to view work orders
    </Typography>
  </Stack>
);

export default NoFacilitySelected;
