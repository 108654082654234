import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import { selectedWorkCellIdOnQueueVar } from 'apollo/reactiveVars';
import { LOCAL_STORAGE_CONSTANTS } from 'constants/globalConstants';
import { isNil } from 'helpers/isNotNil';
import useUpdateProjectSubscription from 'hooks-api/subscriptions/useUpdatedProjectSubscription/useUpdatedProjectSubscription';
import useShopAdmin from 'hooks-api/useShopAdmin';
import { getLocalStorage, setLocalStorage } from 'hooks/useLocalStorage';
import { getFacilityId } from 'modules/Shop/ShopSetup/WorkCellSetup/WorkCellsAndMachines/helpers/helpers';
import { DEPARTMENT_TYPE } from 'types/DepartmentTypes';
import { ESearchType } from 'types/module';

import { useUser } from './UserContext';

export interface IDepartmentType {
  departmentTypeId: string;
}

export interface IDepartments {
  departmentType: IDepartmentType;
  departmentId: string;
}

export interface ISelectedItem {
  id?: string;
  label?: string;
  type?: string;
  departments?: IDepartments[];
  identifier?: string;
}

export interface IFacilitiesProjectsContext {
  selectedItem?: ISelectedItem | null;
  setSelectedItem: Dispatch<SetStateAction<ISelectedItem | null>>;
  shopDepartmentId: string | null;
  isShopAdmin: boolean;
  isShopAdminInvitationCompleted: boolean;
}

const CONTEXT_DEFAULT_VALUE = {
  selectedItem: {},
  setSelectedItem: () => {},
  shopDepartmentId: '',
  isShopAdmin: false,
  isShopAdminInvitationCompleted: false,
};

const FacilitiesProjectsContext = React.createContext<IFacilitiesProjectsContext>(CONTEXT_DEFAULT_VALUE);

const filterShopDepartment = ({ departmentType: { departmentTypeId } }: IDepartments) =>
  departmentTypeId === DEPARTMENT_TYPE.SHOP;

const getDepartmentId = (data: ISelectedItem | null) => {
  if (data?.type !== 'FACILITY') return null;

  const { departments } = data;
  const matchedDepartment = departments?.find(filterShopDepartment);
  return matchedDepartment?.departmentId || '';
};
const { SELECTED_ITEM } = LOCAL_STORAGE_CONSTANTS;

const FacilitiesProjectsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useUser();
  const [selectedItem, setSelectedItem] = useState<ISelectedItem | null>(null);
  const { project } = useUpdateProjectSubscription();

  useEffect(() => {
    if (isNil(project)) return;
    const updatedProjectIdEqualsId = project.updatedProject?.projectId === selectedItem?.id;
    if (!updatedProjectIdEqualsId || !project.updatedProject) return;

    const { projectId, projectName, projectIdentifier } = project.updatedProject;

    const selectedItemToAdd: ISelectedItem = {
      id: projectId,
      label: projectName,
      type: ESearchType.PROJECT,
      identifier: projectIdentifier,
    };

    setSelectedItem(selectedItemToAdd);
  }, [project, selectedItem?.id, setSelectedItem]);

  useEffect(() => {
    const facilityId = getFacilityId(selectedItem);
    if (facilityId) selectedWorkCellIdOnQueueVar(null);

    if (!user) return;
    setLocalStorage(SELECTED_ITEM, selectedItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  useEffect(() => {
    const selectedItemStored = getLocalStorage(SELECTED_ITEM);
    if (selectedItemStored && !selectedItem) setSelectedItem(selectedItemStored);
  }, [selectedItem]);

  const shopDepartmentId: string | null = useMemo(() => getDepartmentId(selectedItem), [selectedItem]);

  const { isShopAdmin, isInvitationCompleted: isShopAdminInvitationCompleted } = useShopAdmin(
    user?.userId,
    shopDepartmentId,
  );

  const contextValue: IFacilitiesProjectsContext = {
    selectedItem,
    setSelectedItem,
    shopDepartmentId,
    isShopAdmin,
    isShopAdminInvitationCompleted,
  };

  return <FacilitiesProjectsContext.Provider value={contextValue}>{children}</FacilitiesProjectsContext.Provider>;
};

const useFacilitiesProjects = () => {
  const context = React.useContext(FacilitiesProjectsContext);
  if (context === undefined) throw new Error('useFacilitiesProjects must be used within a FacilitiesProjectsContext');
  return context;
};

const formatFacility = ({ facilityId, facilityName, departments, address }: any) => ({
  id: facilityId,
  label: facilityName || 'empty',
  address,
  departments,
  type: ESearchType.FACILITY,
});

const formatProject = ({ projectId, projectIdentifier, projectName }: any) => ({
  id: projectId,
  identifier: projectIdentifier,
  label: projectName || 'empty',
  type: ESearchType.PROJECT,
});

export { FacilitiesProjectsContext, FacilitiesProjectsProvider, useFacilitiesProjects, formatFacility, formatProject };
