import * as React from 'react';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { useSnackbar } from 'notistack';

import { Custom } from 'components/Icons';
import { GENERIC_MUTATION_ERROR } from 'constants/globalConstants';
import { VARIANT_ERROR, VARIANT_SUCCESS } from 'constants/snackbarConstants';

const AUTO_HIDE_DURATION = 2500;

const useGraphqlResponseHandler = (finallyCallBack = () => {}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar() || {};

  const enqueueResults = React.useCallback(
    (errors, data, options = {}, successCallBack = () => {}, errorCallBack = () => {}, snackbarOptions = {}) => {
      if (errors) {
        errors.forEach((error) => {
          let errorMessage = '';

          if (options.errorMessage) {
            errorMessage = options.errorMessage;
          } else if (error.errorInfo) {
            errorMessage = Object.values(error.errorInfo).toString();
          } else if (error.message) {
            errorMessage = error.message;
          }

          if (options.errorMessage === '') {
            errorCallBack(errorMessage);
            return;
          }

          if (!snackbarOptions?.hidden) {
            enqueueSnackbar(errorMessage, {
              autoHideDuration: AUTO_HIDE_DURATION,
              ...VARIANT_ERROR,
              ...snackbarOptions?.error,
            });
          }

          errorCallBack(errorMessage);
        });
      } else if (data) {
        successCallBack(data);
        if (options.successMessage === '') return;
        enqueueSnackbar(options.successMessage || 'Success!', {
          autoHideDuration: AUTO_HIDE_DURATION,
          ...VARIANT_SUCCESS,
          action: options?.action
            ? (key) => (
                <>
                  <Button
                    sx={{ color: 'white' }}
                    size="small"
                    onClick={() => {
                      if (options?.action?.callback) {
                        options?.action?.callback();
                      }
                    }}
                  >
                    {options?.action?.label}
                  </Button>
                  <IconButton onClick={() => closeSnackbar(key)} sx={{ ml: 2, color: 'white' }} size="small">
                    <Custom.CloseIcon />
                  </IconButton>
                </>
              )
            : null,
          ...snackbarOptions?.success,
        });
      } else {
        errorCallBack();
        enqueueSnackbar(GENERIC_MUTATION_ERROR, {
          autoHideDuration: AUTO_HIDE_DURATION,
          ...VARIANT_ERROR,
          ...snackbarOptions?.error,
        });
      }
    },
    [closeSnackbar, enqueueSnackbar],
  );

  const handleResponse = React.useCallback(
    async (request, variables, options = {}, successCallBack, errorCallBack, snackbarOptions) => {
      try {
        const { errors, data } = await request(variables);
        enqueueResults(errors, data, options, successCallBack, errorCallBack, snackbarOptions);
        return { errors, data };
      } finally {
        finallyCallBack();
      }
    },
    [enqueueResults, finallyCallBack],
  );

  const handleBatchResponse = React.useCallback(
    (batchResults, options = {}) => {
      batchResults.forEach(({ data, errors }) => {
        enqueueResults(errors, data, options);
      });
    },
    [enqueueResults],
  );

  return { handleResponse, handleBatchResponse };
};

export default useGraphqlResponseHandler;
