import React, { useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { useWorkRequestItemsContext } from './WorkRequestItemsContext';

const RowFormProvider = ({ children, rowData, isEditingRow }) => {
  const { uomOptions, newUomId, uomDefaultValue } = useWorkRequestItemsContext();

  const uomOption =
    uomOptions?.filter((uomOption) => uomOption.value === rowData.row.unitOfMeasureId)[0] || [];

  const defaultValues = {
    workRequestItemName: rowData.row.workRequestItemName,
    quantity: rowData.row.quantity,
    unitOfMeasureId: isEditingRow ? uomOption : uomDefaultValue,
  };

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const { setValue, clearErrors } = methods;

  useEffect(() => {
    if (newUomId) {
      setValue('unitOfMeasureId', newUomId);
      clearErrors(['unitOfMeasureId']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uomOptions]);

  return <FormProvider {...methods}>{children}</FormProvider>;
};

export default RowFormProvider;
