import { useCallback, useEffect } from 'react';

import { useMutation, gql, useLazyQuery } from '@apollo/client';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { useUser } from 'app/UserContext';
import {
  addShopProperty as ADD_SHOP_PROPERTY,
  updateShopProperty as UPDATE_SHOP_PROPERTY,
} from 'graphql/mutations';
import { shopProperties as GET_SHOP_PROPERTIES } from 'graphql/queries';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

export const getFormattedValues = (shopInfo) => {
  const shopData = {
    ...shopInfo,
    shopPropertyName: shopInfo?.shopPropertyName ?? '',
    shippingAddressLine1: shopInfo?.shippingAddressLine1 ?? '',
    shippingAddressLine2: shopInfo?.shippingAddressLine2 ?? '',
    city: shopInfo?.city ?? '',
    state: shopInfo?.state ?? '',
    zip: shopInfo?.zip ?? '',
    country: shopInfo?.country ?? '',
  };

  // eslint-disable-next-line no-underscore-dangle
  delete shopData.__typename;
  delete shopData.shopPropertyDescription;
  delete shopData.facilityId;

  return shopData;
};

const submitRequest = async (
  values,
  userId,
  shopDepartmentId,
  handleResponse,
  addShopProperty,
  updateShopProperty,
) => {
  const body = {
    ...values,
    departmentId: shopDepartmentId,
  };

  if (!values?.shopPropertyId) {
    await handleResponse(addShopProperty, { variables: { body: { ...body, userId } } });
    return;
  }

  delete body.shopPropertyId;
  delete body.facilityId;
  await handleResponse(updateShopProperty, {
    variables: {
      params: { shopPropertyId: values?.shopPropertyId },
      body,
    },
  });
};

const useInfoForm = (reset) => {
  const { shopDepartmentId } = useFacilitiesProjects();
  const { user } = useUser();
  const [addShopProperty, { loading: creating }] = useMutation(gql(ADD_SHOP_PROPERTY));
  const [updateShopProperty, { loading: updating }] = useMutation(gql(UPDATE_SHOP_PROPERTY));
  const [, { loading: loadingShop, data: shopData, refetch: refetchShop }] = useLazyQuery(
    gql(GET_SHOP_PROPERTIES),
    { variables: { query: { departmentId: shopDepartmentId } } },
  );
  const { handleResponse } = useGraphqlResponseHandler();

  const resetToDepartment = useCallback(() => {
    const shopValues = getFormattedValues(shopData?.shopProperties?.at(0));
    reset({ ...shopValues });
  }, [reset, shopData?.shopProperties]);

  useEffect(() => {
    if (shopData?.shopProperties?.at(0)) {
      resetToDepartment();
      return;
    }
    reset({});
  }, [reset, resetToDepartment, shopData]);

  useEffect(
    () => refetchShop({ query: { departmentId: shopDepartmentId } }),
    [refetchShop, shopDepartmentId],
  );

  const onSubmit = async (values) => {
    await submitRequest(
      values,
      user?.userId,
      shopDepartmentId,
      handleResponse,
      addShopProperty,
      updateShopProperty,
    );
    refetchShop({ query: { departmentId: shopDepartmentId } });
  };

  return { onSubmit, creating, updating, loadingShop };
};

export default useInfoForm;
