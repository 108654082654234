import React from 'react';

import { Button, Stack } from '@mui/material';

import { Custom } from 'components/Icons';
import { greyColors } from 'styles/theme/colors';

export const NewRowButton = ({ label = 'NEW ROW', sx, ...props }) => (
  <Button
    sx={{
      letterSpacing: '0.4px',
      fontWeight: 600,
      color: 'secondary.dark',
      fontSize: '14px',
      height: 32,
      ...sx,
    }}
    startIcon={<Custom.Add fill={greyColors.grey500} />}
    {...props}
  >
    {label}
  </Button>
);

export const AddToLocationsButton = ({ ...props }) => (
  <NewRowButton
    label="ADD TO LOCATIONS"
    variant="outlined"
    color="secondary"
    startIcon={null}
    {...props}
  />
);

export const RowWithSpacer = ({ children, ...props }) => (
  <Stack {...props} direction="row" gap={2}>
    {children}
  </Stack>
);
