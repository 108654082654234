import { gql, useApolloClient } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { CancelButton, CreateButton, Modal } from 'components';
import { workRequests } from 'graphql/queries';

import WorkRequestRejectForm from './WorkRequestRejectForm';

const WorkRequestRejectModal = ({ workRequest, openStatus, onClose, updateWorkRequestStatus }) => {
  const client = useApolloClient();
  const methods = useForm({ mode: 'onBlur' });
  const {
    handleSubmit,

    formState: { isValid, isSubmitting },
  } = methods;

  const navigate = useNavigate();

  const submit = async ({ WorkRequestStatusDescription }) => {
    await updateWorkRequestStatus(workRequest, 'Rejected', WorkRequestStatusDescription);

    await client.refetchQueries({
      include: [gql(workRequests)],
      onQueryUpdated(observableQuery) {
        const childWorkRequests = observableQuery.getLastResult()?.data?.workRequests;
        if (!childWorkRequests) return false;

        const isWorkRequestIncluded = childWorkRequests.some(
          (childWorkRequest) => childWorkRequest.workRequestId === workRequest.workRequestId,
        );
        return isWorkRequestIncluded;
      },
    });

    navigate('/shop/work-requests');
    onClose();
  };

  return (
    <Modal
      disableModalOutsideClick
      open={openStatus}
      onClose={onClose}
      title="Confirm Reject"
      titleContainerStyles={{ padding: '16px 10px 16px 24px' }}
      titleStyles={{ fontWeight: '500' }}
      reducePadding
      fullWidth
      maxWidth="sm"
      sizeIconClose="small"
      footer={
        <>
          {isSubmitting && <CircularProgress sx={{ color: 'secondary.main' }} />}
          <CancelButton onClick={onClose} sx={{ color: 'secondary.dark', fontWeight: 500 }} disabled={isSubmitting} />
          <CreateButton
            onClick={handleSubmit(submit)}
            buttonText="REJECT"
            disabled={!isValid || isSubmitting}
            color="secondary"
            sx={{ fontWeight: '500' }}
          />
        </>
      }
    >
      <FormProvider {...methods}>
        <WorkRequestRejectForm />
      </FormProvider>
    </Modal>
  );
};

export default WorkRequestRejectModal;
