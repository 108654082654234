import { useMemo } from 'react';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useNavigate } from 'react-router-dom';

import { UTCFORMATDAYJS } from 'constants/globalConstants';
import useAddWorkRequestMutation from 'hooks-api/mutations/useAddWorkRequestMutation';
import useFetchProjectById from 'hooks-api/useFetchProjectById';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import useSetupModule from 'hooks/useSetupModule';
import { DEPARTMENT_TYPE } from 'types/DepartmentTypes';

import { getModuleRoute } from '../../FieldWorkRequestHelpers';

export const useWorkRequestModalCreate = ({ project, initialFormValues, reset, onClose, redirect = true }) => {
  const { currentModule } = useSetupModule();
  const moduleName = currentModule();
  const moduleRoute = getModuleRoute(moduleName);
  const { project: projectById } = useFetchProjectById(project?.projectId);

  const defaultFacility = useMemo(() => {
    if (!projectById) return null;

    const { departments } = projectById;
    const matchedDepartment = departments?.find((department) => department?.departmentTypeId === DEPARTMENT_TYPE.SHOP);
    return matchedDepartment?.facility.facilityId ? matchedDepartment?.facility : '';
  }, [projectById]);

  const onCloseModal = () => {
    reset({ ...initialFormValues, FacilityId: defaultFacility });
    onClose();
  };

  const { handleResponse } = useGraphqlResponseHandler(onCloseModal);
  const navigate = useNavigate();

  const { addWorkRequest, loading, addWorkRequestData } = useAddWorkRequestMutation();

  const redirectToNewWorkRequest = (newWorkRequest) => {
    if (newWorkRequest?.addWorkRequest?.workRequestId && redirect) {
      navigate(`/${moduleRoute}/work-requests/${newWorkRequest.addWorkRequest.workRequestId}`);
    }
  };

  const submit = async (inputWorkRequest, returnWorkRequestData = false) => {
    dayjs.extend(utc);
    const newDate = inputWorkRequest.NeedBy
      ? inputWorkRequest.NeedBy.local().endOf('day').utc().format(UTCFORMATDAYJS)
      : null;
    const body = {
      ...inputWorkRequest,
      NeedBy: newDate,
      ProjectId: projectById?.projectId,
      FacilityId: inputWorkRequest.FacilityId.value,
    };
    delete body.ProjectIdentifier;
    if (returnWorkRequestData) {
      return addWorkRequest({ variables: { body } });
    }
    await handleResponse(
      addWorkRequest,
      { variables: { body } },
      { successMessage: `${inputWorkRequest.WorkRequestName} created successfully` },
      redirectToNewWorkRequest,
    );
    return undefined;
  };

  return { submit, loading, defaultFacility, onCloseModal, addWorkRequestData };
};
