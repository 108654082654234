import React from 'react';

import { Stack, useTheme } from '@mui/material';

const TabArrowButton = ({ onClick = () => {}, children, ...props }) => {
  const { palette } = useTheme();
  return (
    <Stack
      sx={{
        height: '30px',
        width: 40,
        padding: '5px 15px',
        borderRadius: props?.left ? '4px 0px 0px 0px' : '0px 4px 0px 0px',
        border: '1px solid #CCCCCC',
        borderBottom: '0px',
        boxShadow: 'none',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
          fill: props.disabled ? palette.surface.lightSurface.disabled : palette.secondary.main,
        },
      }}
      onClick={onClick}
      disabled={props.disabled}
      direction="row"
      alignItems="center"
    >
      {children}
    </Stack>
  );
};

export default TabArrowButton;
