import React from 'react';

import { ForesiteTextField } from '@evolvemep/foresite-components-ui-react';
import { useTheme } from '@mui/material';

const InputField = ({ hasError, ...props }) => {
  const { palette } = useTheme();
  return (
    <ForesiteTextField
      autoFocus
      variant="outlined"
      size="extra-small"
      fullWidth
      autoComplete="none"
      sx={{
        '& .MuiInputBase-root': {
          height: 24,
          marginTop: hasError ? 3 : 0,
        },
        '& .MuiFormHelperText-root.Mui-error': {
          textAlign: 'end',
          color: palette.error.dark,
        },
      }}
      {...props}
    />
  );
};

export default InputField;
