import React from 'react';

import { SxProps } from '@mui/material';
import Button from '@mui/material/Button';

import { MUITheme } from 'constants/globalTypes';
import { surfaceColors } from 'styles/theme/colors';

type ButtonProps = {
  color?: MUITheme;
  preventCustomHoverBg?: boolean;
  sx?: SxProps;
  onClick?: () => void;
  children?: React.ReactNode;
  disabled?: boolean;
  props?: any;
};

const PrimaryButton = ({
  color = 'primary',
  preventCustomHoverBg = false,
  children,
  sx = {},
  onClick,
  disabled,
  ...props
}: ButtonProps) => (
  <Button
    color={color}
    onClick={onClick}
    variant="contained"
    disabled={disabled}
    sx={{
      minWidth: 'unset',
      height: '32px',
      boxShadow: 'none',
      color: 'white !important',
      '&:hover': {
        backgroundColor: !preventCustomHoverBg ? 'primary.containedHoverBackground' : '',
      },
      backgroundColor: disabled ? `${surfaceColors.lightSurface.disabled} !important` : '',
      ...sx,
    }}
    {...props}
  >
    {children}
  </Button>
);

export default PrimaryButton;
