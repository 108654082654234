import { useState, useCallback, useEffect } from 'react';

import useGetTaskById from 'hooks/task/useTaskById/useGetTaskById';
import { useTaskViewerContext } from 'modules/Shop/Fabrication/TaskViewer/TaskViewerContext';

export const useHandleVisibilityState = () => {
  const { taskId } = useTaskViewerContext();
  const { fetchTaskById } = useGetTaskById(taskId);

  const [handleVisibility, setHandleVisibility] = useState(false);

  const handleVisibilityChange = useCallback(() => {
    if (document.visibilityState === 'visible') {
      setHandleVisibility(true);
      fetchTaskById();
    }
    if (document.visibilityState === 'hidden') setHandleVisibility(false);
  }, [fetchTaskById]);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange, false);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [handleVisibilityChange]);

  return {
    handleVisibility,
  };
};
