import { useEffect, useMemo, useState } from 'react';

import { gql, useLazyQuery } from '@apollo/client';

import {
  scopePackage as GET_SCOPE_PACKAGE,
  billOfMaterialLineItemByLBS as GET_BOM_LINE_ITEM_BY_LBS,
  workPhases as GET_WORK_PHASES,
} from 'graphql/queries';
import { useItemsIntegrationModalContext } from 'modules/Materials/components/ItemsIntegrationModal/context/ItemsIntegrationModalContext';

import { useItemsIntegrationTableContext } from '../../../context/ItemsIntegrationTableContext';
import { QuantityFieldType } from '../../QuantityField/hooks/@types/fieldHookTypes';
import { filterWorkPhasesAvailable } from '../helpers/workPhasesScopeTableHelpers';

const useWorkPhasesScopePackageTableEffectsHandler = ({ BOMData }: any) => {
  const [workPhasesLBS, setWorkPhasesLBS] = useState<any[]>([]);
  const [LBSData, setLBSData] = useState<any[]>([]);

  const { setQuantityFields, baseERN, quantityFields } = useItemsIntegrationTableContext();
  const { selectedProject } = useItemsIntegrationModalContext();

  const [getScopePackages, { data: scopePackagesData, loading: loadingScopePackages }] = useLazyQuery(
    gql(GET_SCOPE_PACKAGE),
    {
      fetchPolicy: 'cache-first',
    },
  );
  const [getLBSData, { data: LBSItems, loading: loadingLBSData }] = useLazyQuery(gql(GET_BOM_LINE_ITEM_BY_LBS), {
    fetchPolicy: 'cache-first',
  });
  const [getWorkPhases, { data: workPhasesData, loading: loadingWorkPhases }] = useLazyQuery(gql(GET_WORK_PHASES), {
    fetchPolicy: 'cache-first',
  });

  const LBSWorkPhases = useMemo(
    () => LBSItems?.billOfMaterialLineItemByLBS?.children?.filter((child: any) => child.workPhaseId) || [],
    [LBSItems],
  );

  useEffect(() => {
    setLBSData(() => filterWorkPhasesAvailable(LBSWorkPhases));
  }, [LBSWorkPhases]);

  useEffect(() => {
    getScopePackages({ variables: { query: { projectId: selectedProject?.locationId } } });
    getLBSData({
      variables: {
        query: { ern: `${baseERN}:${BOMData?.id}`, filterType: 'Both', includeZeroAmounts: true },
      },
    });
    getWorkPhases({
      variables: {
        query: {
          projectId: selectedProject?.locationId,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject, BOMData]);
  const workPhases = useMemo(() => workPhasesData?.workPhases, [workPhasesData]);

  useEffect(() => {
    const workPhaseAmounts = LBSData;
    if (!workPhaseAmounts?.length || !workPhases.length) return;
    setWorkPhasesLBS(() =>
      workPhaseAmounts.map((workPhase: any) => ({
        workPhaseId: workPhase.workPhaseId,
        workPhaseName: workPhase.workPhaseName,
        qtyAggregate: Math.round(workPhase.amount?.available?.aggregate) || 0,
        qtyRemaining: Math.round(workPhase.amount?.available?.individual) || 0,
        lineItemId: BOMData.id,
        scopePackageId: workPhases.find(({ workPhaseId }: any) => workPhaseId === workPhase.workPhaseId)?.scopePackageId,
      })),
    );
    if (quantityFields.some((field) => workPhaseAmounts.some((workPhase: any) => workPhase.workPhaseId === field.id)))
      return;
    const newWorkPhases = workPhaseAmounts.map((workPhase: any) => ({
      id: workPhase.workPhaseId,
      qtyRemaining: Math.round(workPhase?.amount?.available?.individual) || 0,
      qtyAggregate: Math.round(workPhase?.amount?.available?.aggregate) || 0,
      nestLevel: 2,
      lineItemId: BOMData.id,
      isWorkPhase: true,
    }));
    setQuantityFields((current: QuantityFieldType[]) => {
      const parentLineItem = current.find((field) => field.id === BOMData.id);
      return [
        ...current.filter((field) => field.id !== BOMData.id),
        { ...parentLineItem, children: [...newWorkPhases] },
        ...newWorkPhases,
      ] as QuantityFieldType[];
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LBSData, BOMData, workPhases]);

  return {
    loadingLBSData,
    scopePackagesData,
    loadingScopePackages,
    workPhases,
    loadingWorkPhases,
    workPhasesLBS,
  };
};

export default useWorkPhasesScopePackageTableEffectsHandler;
