import React from 'react';

import { Stack, Button } from '@mui/material';

import { useFilterOptions } from '../features/filters/options';
import { BreakdownChips, OptionsChips, RulesChips, SourceDataChips } from './FilterPopoverContent/ChipsFilters';
import { useChipsFilterHelpers } from './FilterPopoverContent/useChipsFilterHelpers';

const LocationChipsArea = ({ contextHook }) => {
  const { onClearFilters, hasFilters } = useChipsFilterHelpers(contextHook);
  const { showFilters } = useFilterOptions();

  if (hasFilters && showFilters)
    return (
      <Stack
        id="location-chip-area"
        direction="row"
        sx={{
          flexWrap: 'wrap',
          margin: '0 0 8px 0',
          backgroundColor: '#f4f4f4',
          borderRadius: '8px',
          padding: '12px 16px 12px 16px',
          marginTop: 2,
        }}
      >
        <BreakdownChips sx={{ margin: '5px 8px 3px 0' }} contextHook={contextHook} />
        <OptionsChips sx={{ margin: '5px 8px 3px 0' }} />
        <RulesChips sx={{ margin: '5px 8px 3px 0' }} />
        <SourceDataChips sx={{ margin: '5px 8px 3px 0' }} />

        <Button style={{ textTransform: 'Capitalize' }} color="secondary" variant="text" onClick={onClearFilters}>
          Clear All
        </Button>
      </Stack>
    );

  return <></>;
};

export default LocationChipsArea;
