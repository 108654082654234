import React, { useEffect } from 'react';

import { Stack } from '@mui/material';
import { isValid, isAfter } from 'date-fns';
import { useForm } from 'react-hook-form';

import { usePopoverFilterContext } from 'components/Popover/FilterPopover/context/PopoverFilterContext';
import { formatLocalTimeToUTC } from 'helpers/dateFunctions';

import DatePicker from './DatePicker';

const DEFAULT_VALUES = {
  dateTo: null,
  dateFrom: null,
};

const DateFilterInput = ({ onChange }) => {
  const { filters } = usePopoverFilterContext();

  const { control, setError, setFocus, watch, handleSubmit, reset } = useForm({
    mode: 'all',
    defaultValues: DEFAULT_VALUES,
  });

  useEffect(() => {
    const dateFrom = filters.find((filter) => filter.column === 'dateFrom')?.values ?? null;
    const dateTo = filters.find((filter) => filter.column === 'dateTo')?.values ?? null;
    reset({ dateFrom, dateTo });

    return () => {
      reset(DEFAULT_VALUES);
    };
  }, [reset, filters]);

  const dateFromWatched = watch('dateFrom');
  const dateToWatched = watch('dateTo');

  const showError = (field, message) => {
    setError(field, { type: 'error', message });
    setFocus(field);
  };

  const onSubmit = async ({ dateFrom, dateTo }) => {
    if (dateTo !== null && dateFrom === null) {
      showError('dateFrom', 'Please select a date from');
      return;
    }

    if (dateFrom !== null && dateTo === null) {
      showError('dateTo', 'Please select a date to');
      return;
    }

    if (isAfter(new Date(dateFrom), new Date(dateTo))) {
      showError('dateFrom', 'Please select a date before to');
      return;
    }

    if (dateFrom && dateTo && isValid(new Date(dateFrom)) && isValid(new Date(dateTo))) {
      onChange({
        dateFrom: formatLocalTimeToUTC(dateFrom),
        dateTo: formatLocalTimeToUTC(dateTo),
      });
    }
  };

  return (
    <Stack
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        gap: '8px',
        flex: 1,
        marginLeft: '42px',
        mb: '8px',
      }}
      data-testid="date-filter-input"
    >
      <DatePicker
        name="dateFrom"
        id="dateFrom"
        label="Date From"
        control={control}
        maxDate={dateToWatched}
        onDateChange={handleSubmit(onSubmit)}
        onDateBlur={handleSubmit(onSubmit)}
        data-cy="date-from-input"
        data-testid="date-from-input"
      />
      <DatePicker
        name="dateTo"
        id="dateTo"
        label="Date To"
        control={control}
        triggerChangeOnlyOnBlur
        minDate={dateFromWatched}
        onDateChange={handleSubmit(onSubmit)}
        onDateBlur={handleSubmit(onSubmit)}
        data-cy="date-to-input"
        data-testid="date-to-input"
      />
    </Stack>
  );
};

export default DateFilterInput;
