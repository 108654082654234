import React, { FC } from 'react';

import { useFormContext } from 'react-hook-form';

import InputSelectField from 'components/FormComponents/InputSelectField';

import { PBS_FIELD } from '../../constants';
import { useItemsIntegrationTableContext } from '../../context/ItemsIntegrationTableContext';

const ProjectBreakdownStructureDropdown: FC = () => {
  const { control, reset, setValue } = useFormContext();
  const { setAutoFilledFields } = useItemsIntegrationTableContext();
  return (
    <InputSelectField
      label="Project Breakdown Structure"
      variant="outlined"
      fullWidth
      name={PBS_FIELD}
      control={control}
      options={[
        // { label: 'Locations', value: 'Location' }, // ! DON'T REMOVE!! this is temporarely commented due to scope changes
        { label: 'Locations with Work Phases', value: 'Both' },
        // { label: 'Work Phases', value: 'Work Phase' } // ! DON'T REMOVE!! this is temporarely commented due to scope changes
      ]}
      onSelectChange={(event) => {
        reset();
        setValue(PBS_FIELD, event.target.value);
        setAutoFilledFields([]);
      }}
      defaultValue="Both"
    />
  );
};

export default ProjectBreakdownStructureDropdown;
