import React, { forwardRef } from 'react';

import { Input } from '@mui/base';
import Box from '@mui/material/Box';

const getMainColor = (editable, error, disabled, value = '', skipError) => {
  if (disabled) return 'rgba(0, 0, 0, 0.25)';
  if (value.length === 0) return 'secondary.main';
  if (skipError) return 'secondary.main';
  if (error) return 'error.main';
  if (editable) return 'secondary.main';

  return 'rgba(0, 0, 0, 0.25)';
};

const TabNameInput = forwardRef(({ editable, error, skipError, ...restProps }, ref) => {
  const { disabled, value } = restProps;
  const color = getMainColor(editable, error, disabled, value, skipError);

  return (
    <Box
      ref={ref}
      sx={{
        borderTop: '1px solid #CCCCCC',
        borderLeftWidth: '0px',
        borderLeftStyle: 'solid',
        borderLeftColor: editable ? 'other.border' : 'surface.lightSurface.disabled',
        borderBottomWidth: '2px',
        borderBottomStyle: 'solid',
        borderBottomColor: color,
        height: '30px',
        minWidth: '98px',
        position: 'relative',
        boxSizing: 'border-box',
        borderRight: editable ? '1px solid' : 'none',
        borderRightColor: editable ? 'other.border' : 'none',
        '& .Mui-disabled': {
          borderTop: 'none !important',
          backgroundColor: 'transparent !important',
          borderLeft: 'none !important',
          borderRight: 'none !important',
        },
        '& .MuiInput-input': {
          border: 'none',
          outline: 'none',
          paddingLeft: '5px',
          width: '96px',
        },
        '& .MuiInput-input::placeholder': {
          fontWeight: 'bold',
        },
      }}
      disabled={!editable}
    >
      <Input
        variant="body2"
        slots={{
          root: StyledInputElement,
          input: StyledTabNameInput,
        }}
        placeholder={editable || disabled ? 'Untitled Pad' : ''}
        disabled={!editable}
        {...restProps}
        // This is a fix to avoid show 0 and not the placeholder
        value={value === 0 ? undefined : value}
        slotProps={{ input: { 'data-cy': 'pad-name' } }}
      />
    </Box>
  );
});

export default TabNameInput;

const StyledTabNameInput = (props) => (
  <Box
    sx={{
      input: {
        borderRight: '0px',
        fontSize: '14px',
        fontWeight: 700,
        border: 'none',
        outline: 0,
        fontFamily: 'Roboto',
        width: '96px',
        color: 'black',
        caretColor: 'black',
        lineHeight: '14px',
        fontStyle: 'normal',
        paddingLeft: '0.3rem',
        paddingRight: '0.5rem',
        '::placeholder': {
          color: 'rgba(0,0,0,0.5)',
          textAlign: 'center',
        },
      },
    }}
  >
    <input {...props} />
  </Box>
);

const StyledInputElement = (props) => <Box sx={{ '&:focus': { borderColor: 'none' } }} {...props} />;
