import { GRID_CHECKBOX_SELECTION_COL_DEF, GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro';

import { DETAIL_PANEL_TOGGLE_COLUMN } from '../constants';

export const WorkRequestResponsive = (handleCellComponent) => [
  {
    field: 'workRequestItemStatusTypeId',
    headerName: ' ',
    minWidth: 40,
    width: 40,
    padding: 0,
    renderCell: handleCellComponent,
  },
  { ...GRID_CHECKBOX_SELECTION_COL_DEF, type: '' },
  {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    ...DETAIL_PANEL_TOGGLE_COLUMN,
    renderCell: handleCellComponent,
  },
  {
    field: 'workRequestItemName',
    headerName: 'Item',
    flex: 4,
    renderCell: handleCellComponent,
  },
  {
    field: 'quantity',
    headerName: 'Qty',
    flex: 1,
    renderCell: handleCellComponent,
    align: 'left',
  },
];
