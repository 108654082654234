import React from 'react';

import { Typography, Stack, Tooltip } from '@mui/material';

import { Custom } from 'components/Icons';
import { surfaceColors } from 'styles/theme/colors';

const DrawerContainerTitle = ({ children, textHelp }) => (
  <Stack
    direction="row"
    sx={{
      color: 'surface.darkSurface.primary',
      pb: '10px',
      mt: 2,
      ml: 2,
      mr: 2,
      borderBottom: '1px solid',
    }}
  >
    <Typography sx={{ fontSize: '20px', fontWeight: '500' }}>{children}</Typography>
    <Tooltip placement="bottom" title={textHelp}>
      <Custom.InfoRounded
        style={{
          fill: surfaceColors.darkSurface.primaryOpacity70,
          marginTop: '7px',
          marginLeft: '8px',
          width: '18px',
          height: '18px',
        }}
      />
    </Tooltip>
  </Stack>
);

export default DrawerContainerTitle;
