import React, { Fragment } from 'react';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';

import InfiniteScroll from 'components/TableComponents/InfiniteScroll';
import NoContentMessage from 'components/TableComponents/NoContentMessage';
import TableContainer from 'components/TableComponents/TableContainer';
import TableHeader from 'components/TableComponents/TableHeaders';
import TableSkeleton from 'components/TableComponents/TableSkeleton';
import {
  handleTableRowClick,
  handleSelectAllClick,
  isSelected,
} from 'components/TableComponents/tableUtilities';

import { useAdminContext } from '../AdminContext';
import AdminTableRow from './AdminTableRow';

const AdminTable = ({
  loading,
  headerOptions,
  admins,
  handleSortOrder,
  paginationHandler,
  sortOptions,
}) => {
  const { selected, setSelected } = useAdminContext();

  const TableBodyContent = () => (
    <TableBody>
      {admins.map((projectUser, index) => (
        <Fragment key={projectUser.projectUserId}>
          <AdminTableRow
            projectUser={projectUser}
            handleRowClick={() => handleTableRowClick(projectUser, selected, setSelected)}
            isItemSelected={isSelected(projectUser, selected)}
          />
          {index === admins.length - 1 && (
            <InfiniteScroll data={admins} fetchMore={paginationHandler} />
          )}
        </Fragment>
      ))}
    </TableBody>
  );

  return (
    <AdminTableBorderBox>
      <TableContainer>
        <Table stickyHeader>
          <TableHeader
            headerOptions={headerOptions}
            sortOptions={sortOptions}
            onSelectAllClick={(e) => handleSelectAllClick(e, admins, setSelected)}
            handleSortOrder={handleSortOrder}
            rowCount={admins.length}
            numSelected={selected.length}
            selectable
          />
          {loading ? (
            <TableSkeleton skeletons={headerOptions.map(({ title }) => title)} />
          ) : (
            <TableBodyContent />
          )}
        </Table>
      </TableContainer>
      {!admins.length && !loading && <NoContentMessage text="No Admins..." />}
    </AdminTableBorderBox>
  );
};

export default AdminTable;

const AdminTableBorderBox = ({ children }) => (
  <Box
    mt="20px"
    sx={{
      border: `1px solid #0000001F`,
      borderRadius: 1,
      padding: '5px 0 0 0',
      height: 'calc(100% - 135px)',
    }}
  >
    {children}
  </Box>
);
