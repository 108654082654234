import React, { FC, useEffect, useMemo } from 'react';

import { Box, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import StackRow from 'components/StackRow';

import { ItemsIntegrationItemType } from '../../../@types/itemsIntegrationTableContext';
import { UNASSIGNED } from '../../constants';
import { useItemsIntegrationTableContext } from '../../context/ItemsIntegrationTableContext';
import { QuantityFieldType } from '../QuantityField/hooks/@types/fieldHookTypes';
import QuantityField from '../QuantityField/QuantityField';
import RowWrapper from '../RowWrapper/RowWrapper';

interface IUnassignedRow {
  lineItem: ItemsIntegrationItemType;
}
const UnassignedRow: FC<IUnassignedRow> = ({ lineItem }: IUnassignedRow) => {
  const { quantityFields, setQuantityFields } = useItemsIntegrationTableContext();
  const qtyFieldName = useMemo(() => `${UNASSIGNED}__${lineItem.id}`, [lineItem?.id]);
  const qty = useMemo(() => lineItem.unassignedAmount, [lineItem]);
  const disabled = useMemo(() => !qty, [qty]);
  const qtyFieldId = useMemo(() => `${UNASSIGNED}__${lineItem.id}`, [lineItem]);
  const { getValues, setValue } = useFormContext();

  useEffect(() => {
    setQuantityFields((current: QuantityFieldType[]) => {
      if (quantityFields.find((field) => field.id === qtyFieldId)) return current;
      const unassignedQtyField = {
        id: qtyFieldId,
        lineItemId: lineItem.id,
        nestLevel: 1,
        qtyAggregate: qty,
        qtyRemaining: qty,
        isUnassignedRow: true,
        parentId: lineItem.id,
      } as QuantityFieldType;
      return [...current, unassignedQtyField];
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qtyFieldId, qty]);

  useEffect(() => {
    const lineItemFieldName = `qtyToAdd__bomItem__${lineItem.id}`;
    const fieldName = `qtyToAdd__${qtyFieldName}`;
    if (parseInt(getValues(lineItemFieldName), 10) !== lineItem.totalQuantityRemaining) return;
    if (getValues(fieldName)) return;
    setValue(fieldName, lineItem.unassignedAmount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RowWrapper disabled={disabled} qtyFieldName={qtyFieldName} qtyAggregate={qty} qtyRemaining={qty}>
      <StackRow sx={{ flex: 1, gap: '8px', width: `418px` }}>
        <Box sx={{ width: `8px` }} />
        <StackRow>
          <Box width="164px" />
        </StackRow>
        <Typography variant="subtitle2"> Unassigned</Typography>
      </StackRow>
      <StackRow marginRight="16px" gap="16px">
        <Box width="96px">
          <Typography marginRight="20px" textAlign="right" variant="body2">
            {qty}
          </Typography>
        </Box>
        <QuantityField
          disabled={disabled}
          name={qtyFieldName}
          rowIdentifier={UNASSIGNED}
          qtyRemaining={qty}
          qtyAggregate={qty}
        />
      </StackRow>
    </RowWrapper>
  );
};

export default UnassignedRow;
