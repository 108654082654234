import React, { useEffect } from 'react';

import { Box } from '@mui/material';

import { sort } from 'helpers/arrayFunctions';

import Section from '../../../../../components/Section';
import { useAttributeContext } from '../AttributesContext';
import AddNewVariantForm from './AddNewVariantForm';
import AttributeVariantsHeader from './AttributeVariantHeaders';
import AttributeVariantMenu from './AttributeVariantMenu';
import VariantRow from './VariantRow';

const AttributeVariants = () => {
  const {
    setSelectedAttribute,
    selectedAttribute,
    handleAddNewVariant,
    variantToAdd,
    setVariantToAdd,
    variantFormOpen,
    setVariantFormOpen,
  } = useAttributeContext();

  const handleKeyDown = () => {
    setVariantToAdd(null);
    setVariantFormOpen(false);
  };

  useEffect(() => {
    if (selectedAttribute) {
      setVariantToAdd((prev) => {
        if (JSON.stringify(prev) === JSON.stringify(selectedAttribute)) {
          return prev;
        }
        return selectedAttribute;
      });
    }
  }, [selectedAttribute, setVariantToAdd]);

  const handleClickAttribute = (attribute) => {
    setSelectedAttribute(attribute);
  };
  const variants = sort(selectedAttribute?.selectVariants || [], 'textValue');

  return (
    <Box width="55%">
      <Section borderTopLeftRadius="0px" borderBottomLeftRadius="0px">
        <Section.Title padding="0px 5px 0px 16px !important">
          <AttributeVariantsHeader
            handleAddNewVariant={handleAddNewVariant}
            disabled={!selectedAttribute?.selectVariants}
          />
        </Section.Title>
        <Section.Body>
          {variantToAdd && variantToAdd?.id === selectedAttribute?.id && variantFormOpen && (
            <AddNewVariantForm
              variants={variants}
              handleKeyDown={handleKeyDown}
              selectedAttribute={selectedAttribute}
              handleClickAttribute={handleClickAttribute}
            />
          )}
          <Box sx={{ paddingBottom: '50px' }}>
            {variants.map((variant) => (
              <VariantRow
                data-testid={`variant-${variant?.partAttributeSelectVariantId}`}
                key={variant?.partAttributeSelectVariantId}
                variant={variant}
                variants={variants}
                actionComponent={<AttributeVariantMenu />}
              />
            ))}
          </Box>
        </Section.Body>
      </Section>
    </Box>
  );
};

export default AttributeVariants;
