import { useCallback } from 'react';

import { Auth } from 'aws-amplify';
import axios, { AxiosRequestConfig } from 'axios';

import getEnvVars from 'envVars';

type CallType = 'GET' | 'POST' | 'PATCH' | 'DELETE';
type NoBodyCalls = 'GET' | 'DELETE';

const methodMap = {
  GET: axios.get,
  POST: axios.post,
  PATCH: axios.patch,
  DELETE: axios.delete,
} as const;

export const getJwtToken = async () => Auth.currentSession().then((s) => s.getIdToken().getJwtToken());
/**
 * Global function to get the JWT token for the logged in user
 * This is intended to be used by 360 Sync, which does not have import access to our code,
 * but whose code runs inside of ours all the same.
 */
// @ts-ignore
window.getJwtToken = getJwtToken;

type ApiVersion = 'v1';
export const getBaseApiUrl = async (version: ApiVersion = 'v1') =>
  getEnvVars().then((vars) => `${vars.REACT_APP_API_BASE_URL}/${version}`);

const useEvolveApi = () => {
  const callBuilder = useCallback(
    async <ReturnType, DataType = any>({
      path,
      config: customConfig,
      method,
      body,
    }: {
      path: string;
      config?: AxiosRequestConfig<DataType>;
    } & (
      | {
          method: Extract<CallType, NoBodyCalls>;
          body?: never;
        }
      | {
          method: Exclude<CallType, NoBodyCalls>;
          body: DataType;
        }
    )): Promise<ReturnType> => {
      const endpoint = await getBaseApiUrl();
      const token = await getJwtToken();
      const headers = {
        Authorization: `Bearer ${token}`,
        Accept: '*/*',
      };
      const config = {
        ...customConfig,
        headers,
      };
      if (method === 'GET' || method === 'DELETE') {
        return methodMap[method](`${endpoint}/${path}`, config).then((res) => res.data ?? (res as ReturnType));
      }
      return methodMap[method](`${endpoint}/${path}`, body, config).then((res) => res.data ?? (res as ReturnType));
    },
    [],
  );

  const get = useCallback(
    <T, D = any>(path: string, config?: AxiosRequestConfig<D>) => callBuilder<T, D>({ path, config, method: 'GET' }),
    [callBuilder],
  );

  const post = useCallback(
    <T, D = any>(path: string, body: D, config?: AxiosRequestConfig<D>) =>
      callBuilder<T, D>({ path, body, config, method: 'POST' }),
    [callBuilder],
  );

  const patch = useCallback(
    <T, D = any>(path: string, body: any, config?: AxiosRequestConfig<D>) =>
      callBuilder<T, D>({ path, body, config, method: 'PATCH' }),
    [callBuilder],
  );

  const apiDelete = useCallback(
    <T, D = any>(path: string, body: any, config?: AxiosRequestConfig<D>) =>
      callBuilder<T, D>({ path, config, method: 'DELETE' }),
    [callBuilder],
  );

  return { get, post, patch, apiDelete };
};

export default useEvolveApi;
