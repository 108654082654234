import React, { useState } from 'react';

import { Stack } from '@mui/material';

import Attributes from './Attributes/Attributes';
import AttributeVariants from './AttributeVariants/AttributeVariants';

const CategorySetupContent = () => {
  const [variantToAdd, setVariantToAdd] = useState(null);

  return (
    <AttributesSectionWrapper>
      <Attributes />
      <AttributeVariants variantToAdd={variantToAdd} setVariantToAdd={setVariantToAdd} />
    </AttributesSectionWrapper>
  );
};

export default CategorySetupContent;

const AttributesSectionWrapper = ({ children }) => (
  <Stack
    direction="row"
    sx={{
      outline: '1px solid rgba(204, 204, 204, 1)',
      borderRadius: '4px',
      height: '100%',
      flex: 1,
    }}
  >
    {children}
  </Stack>
);
