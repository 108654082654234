import React, { useState } from 'react';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';

const DataContext = React.createContext();

const DataProvider = ({ children }) => {
  const { selectedItem, shopDepartmentId } = useFacilitiesProjects();
  const [shopConfiguration, setShopConfiguration] = useState({});
  const [activeRow, setStateActiveRow] = useState({});
  const [isDragging, setIsDragging] = useState(false);
  const [draggingTaskId, setDraggingTaskId] = useState(null);
  const [closeFlyoutMenu, setCloseFlyoutMenu] = useState(false);

  const setActiveRow = (row) => {
    setStateActiveRow({});
    setTimeout(() => {
      setStateActiveRow(row);
    }, 0);
  };

  const selectedStateObj = {
    selectedItem,
    shopDepartmentId,
    activeRow,
    setActiveRow,
    shopConfiguration,
    setShopConfiguration,
    isDragging,
    setIsDragging,
    closeFlyoutMenu,
    setCloseFlyoutMenu,
    draggingTaskId,
    setDraggingTaskId,
  };

  return <DataContext.Provider value={selectedStateObj}>{children}</DataContext.Provider>;
};

const useDataContext = () => {
  const context = React.useContext(DataContext);
  if (context === undefined) {
    throw new Error('useDataContext must be used within a DataContext');
  }
  return context;
};

export { DataContext, DataProvider, useDataContext };
