import React from 'react';

import { InputForesiteField } from '@evolvemep/foresite-components-ui-react';
import { Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

const WorkRequestRejectForm = () => {
  const {
    control,
    formState: { isSubmitting },
  } = useFormContext();

  return (
    <Stack sx={{ fontSize: '16px' }}>
      <Typography sx={{ fontWeight: 'bold', display: 'inline' }}>Why is this Work Request being rejected?</Typography>
      <InputForesiteField
        label="Response*"
        id="WorkRequestStatusDescription"
        multiline
        rows={6}
        color="secondary"
        inputProps={{
          maxLength: 1000,
          'data-testid': 'work-request-reject-description',
        }}
        maxLength={1000}
        rules={{ required: '*Required' }}
        name="WorkRequestStatusDescription"
        control={control}
        disabled={isSubmitting}
      />
    </Stack>
  );
};

export default WorkRequestRejectForm;
