import React from 'react';

import { Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import StackRow from 'components/StackRow';
import { NEW_ITEM_ROW_ID } from 'constants/globalConstants';

import { LineItemNameInput } from '../../../Form';
import RenameWriteInInput from '../../../Form/RenameWriteInInput';
import { BOMCatalogTableFields, isItemAWriteIn } from '../BOMTableColumnsHelpers';
import CloseButton from './CloseButton';

const LineItemNameEditCell = ({ row, id, handleRowCancel }) => {
  const { getValues, formState } = useFormContext();
  const isWriteInItem = isItemAWriteIn(row.lineItemTypeId);

  if (!isWriteInItem)
    return (
      <StackRow>
        <CloseButton onClick={() => handleRowCancel(id)} isSubmitting={formState.isSubmitting} />
        <Typography variant="body2">{getValues(BOMCatalogTableFields.LINE_ITEM_NAME)}</Typography>
      </StackRow>
    );

  if (isWriteInItem && NEW_ITEM_ROW_ID.toString() !== id)
    return <RenameWriteInInput id={id} defaultValue={row.lineItemName} autoFocus={isWriteInItem} />;

  return <LineItemNameInput onClose={() => handleRowCancel(id)} autoFocus={isWriteInItem} />;
};

export default React.memo(LineItemNameEditCell);
