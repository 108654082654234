import { Box } from '@mui/material';
import { GRID_CHECKBOX_SELECTION_COL_DEF, GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro';

import StatusBadge from 'components/StatusBadges/StatusBadge';
import OverFlowTooltip from 'components/Tooltip/OverFlowTooltip';
import { BADGE_MAPPING_STATUS } from 'constants/badgeMappingStatus';
import { getDaysBetween } from 'helpers/dateFunctions';
import { isNotNil } from 'helpers/isNotNil';
import CustomDetailPanelToggle from 'modules/Field/WorkRequests/WorkRequest/components/CustomDetailPanelToggle';

import DeleteCell from '../DeleteCell';
import PredecessorAutoComplete from '../PredecessorAutoComplete';

export const columnTaskTable = (
  tasks,
  canEdit,
  canDelete,
  onDeleteTask,
  isReleased = false,
  tasksPredecessorBlacklist = {},
  taskIdsRelateDuplicate = {},
) => [
  {
    field: 'whiteSpace',
    headerName: '',
    width: 150,
    sortable: false,
  },
  { ...GRID_CHECKBOX_SELECTION_COL_DEF, type: '' },
  {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    headerClassName: 'toggle-column-header',
    minWidth: 40,
    maxWidth: 40,
    type: '',
    renderCell: (params) => {
      if (!params.row.hasDocuments) return <></>;
      return <CustomDetailPanelToggle id={params?.id} value={params?.value} />;
    },
  },
  {
    field: 'taskName',
    headerName: 'Task Name',
    width: 150,
    sortable: false,
    disableReorder: true,
    renderCell: ({ row }) => <OverFlowTooltip title={row?.taskName}>{row?.taskName}</OverFlowTooltip>,
  },
  {
    field: 'predecessor',
    headerName: 'Predecessor',
    flex: 1.5,
    editable: true,
    sortable: false,
    disableReorder: true,
    renderCell: (params) => orderPredecessor(params?.row?.taskPredecessorIds, canEdit, taskIdsRelateDuplicate),
    renderEditCell: ({ row }) => (
      <PredecessorEditCell
        row={row}
        canEdit={canEdit}
        tasks={tasks.filter(
          (task) => !tasksPredecessorBlacklist?.[row?.taskId]?.some((taskBlacklist) => task.taskId === taskBlacklist),
        )}
      />
    ),
  },
  {
    field: 'taskStatusTypeName',
    headerName: 'Status',
    flex: 0.6,
    minWidth: 150,
    sortable: false,
    renderCell: ({ row, value }) => {
      if (!isReleased) return <></>;

      const status = BADGE_MAPPING_STATUS[value];
      const firstDate = new Date(`${row.needBy}Z`);
      const completedTimestamp = row.time?.events?.find((r) => r.description === 'Completed')?.timesStamp;
      const secondDate = isNotNil(completedTimestamp) ? new Date(`${completedTimestamp}Z`) : new Date();
      const isLate = getDaysBetween(firstDate, secondDate) < 0;

      return <StatusBadge label={status.label} variant={isLate ? 'pastDaysRemaining' : status.variant} />;
    },
  },
  {
    field: 'workCellName',
    headerName: 'Work Cell',
    flex: 1.5,
    sortable: false,
  },
  {
    field: 'actions',
    type: 'actions',
    disableReorder: true,
    renderCell: ({ id, row }) => <DeleteCell row={row} id={id} canDelete={canDelete} />,
  },
];

const orderPredecessor = (predecessor = [], canEdit, taskIdsRelateDuplicate) => {
  const addIndicatorDuplicate = (duplicateCount) => (duplicateCount > 0 ? `(${duplicateCount})` : '');

  const message = predecessor
    ?.map((taskPredecessor) => ({
      ...taskPredecessor,
      taskName: `${taskPredecessor.taskName}  ${addIndicatorDuplicate(
        taskIdsRelateDuplicate?.[taskPredecessor.taskId],
      )}`,
    }))
    ?.map((task) => task?.taskName)
    ?.sort((a, b) => a.localeCompare(b))
    ?.join(', ');
  return canEdit ? (
    <Box sx={{ pointerEvents: 'none' }} textOverflow="ellipsis" overflow="hidden">
      {message}
    </Box>
  ) : (
    <OverFlowTooltip title={message}>{message}</OverFlowTooltip>
  );
};

const PredecessorEditCell = ({ row, canEdit, tasks }) => {
  if (!canEdit) return null;

  return <PredecessorAutoComplete key={row.id} tasks={tasks} row={row} />;
};
