import { greyColors } from 'styles/theme/colors';

export const inputStyles = {
  margin: 0,
  marginBottom: '10px',
  marginTop: '1px',
  '& .MuiFilledInput-root': {
    backgroundColor: greyColors.grey50,
    '&:hover, &:active': {
      backgroundColor: 'transparent',
    },
    '& > input': {
      padding: '0px !important',
      margin: 0,
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '32px',
    },
  },
};
