import React from 'react';

import { Box, SxProps } from '@mui/material';

type ComponentPaneChipsProps = {
  children: React.ReactNode;
  sx?: SxProps;
};

const ComponentPaneChips = ({ children, sx, ...restProps }: ComponentPaneChipsProps) => (
  <Box sx={{ ...sx, pb: 1 }} {...restProps}>
    {children}
  </Box>
);

export default ComponentPaneChips;
