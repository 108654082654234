/* eslint-disable max-lines-per-function */
import React, { useCallback } from 'react';

import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';

import { FIELDS, HEADERS_NAME } from './constants';
import useTableHeaderHelpers from './useTableHeaderhelpers';

const useTableHeaders = ({
  isAddingRow,
  control,
  handleSubmitForm,
  uomOptions,
  manufacturers,
  hasUserSelectedManufacturer,
  partName,
  type,
  onChangePartName,
  padSelected,
  selectedManufacturer,
  resetForm,
  itemNameRules,
  onKeyDownHandler,
  editingQuantityRowData,
  handleClickEditQuantity,
  isRevitSourcePad,
  setFormControlValue,
  clearErrors,
  loadingAddTakeOffPadItem,
}) => {
  const { renderCell } = useTableHeaderHelpers({
    control,
    uomOptions,
    manufacturers,
    partName,
    onChangePartName,
    resetForm,
    onKeyDownHandler,
    handleSubmitForm,
    itemNameRules,
    type,
    isRevitSourcePad,
    setFormControlValue,
    clearErrors,
    loadingAddTakeOffPadItem,
  });

  return useCallback(
    (RowComponent) => {
      const primaryColumns = [
        {
          useForm: true,
          headerName: HEADERS_NAME()[FIELDS.lineItemName],
          field: FIELDS.lineItemName,
          flex: 3.86,
          sortable: true,
          resizable: true,
          colSpan: (params) => (params.id === -1 ? 9 : 1),
          renderCell: ({ id, field, value, row }) =>
            renderCell({
              condition: type === 'new' && !id && isAddingRow,
              field,
              id,
              RowComponent,
              value,
              row,
            }),
        },
        {
          useForm: true,
          headerName: HEADERS_NAME()[FIELDS.tradeSize],
          field: FIELDS.tradeSize,
          flex: 1.05,
          sortable: true,
          resizable: true,
          renderCell: ({ id, field, value }) =>
            renderCell({
              condition: !id && isAddingRow,
              field,
              id,
              RowComponent,
              value,
            }),
        },
        {
          useForm: true,
          headerName: HEADERS_NAME()[FIELDS.unitQuantity],
          field: FIELDS.unitQuantity,
          flex: 1.05,
          sortable: true,
          resizable: true,
          renderCell: ({ id, field, value }) =>
            renderCell({
              condition: !id && isAddingRow,
              field,
              id,
              RowComponent,
              value,
            }),
        },
        {
          useForm: true,
          headerName: HEADERS_NAME()[FIELDS.uom],
          field: FIELDS.uom,
          flex: 1.05,
          sortable: true,
          resizable: true,
          renderCell: ({ id, field, value }) =>
            renderCell({
              condition: !id && isAddingRow,
              field,
              id,
              RowComponent,
              value,
            }),
        },
        {
          useForm: true,
          headerName: HEADERS_NAME()[FIELDS.manufacturer],
          field: FIELDS.manufacturer,
          flex: 1.05,
          sortable: true,
          resizable: true,
          renderCell: ({ id, field, value }) =>
            renderCell({
              condition: !id && isAddingRow,
              field,
              id,
              RowComponent,
              value,
            }),
        },
        {
          useForm: true,
          headerName: HEADERS_NAME()[FIELDS.catalogNumber],
          field: FIELDS.catalogNumber,
          flex: 1.05,
          sortable: true,
          resizable: true,
          renderCell: ({ id, field, value }) => {
            const manufacturerIsObject = typeof selectedManufacturer !== 'string';
            const isGeneric = manufacturerIsObject
              ? selectedManufacturer?.label?.toLowerCase() === 'generic'
              : selectedManufacturer?.toLowerCase() === 'generic';
            const condition = !id && isAddingRow && hasUserSelectedManufacturer;
            return renderCell({
              condition: condition && !isGeneric,
              field,
              id,
              RowComponent,
              value,
            });
          },
        },
        {
          useForm: true,
          headerName: HEADERS_NAME()[FIELDS.quantity],
          field: FIELDS.quantity,
          flex: 0.82,
          sortable: true,
          resizable: true,
          renderCell: ({ id, field, value }) =>
            renderCell({
              condition: (!id && isAddingRow) || editingQuantityRowData?.lineItemId === id,
              field,
              id,
              RowComponent,
              value,
              onClick: (event) => {
                if (isRevitSourcePad) return;

                handleClickEditQuantity(id);
                event.stopPropagation();
              },
              isEditingQuantity: editingQuantityRowData?.lineItemId === id,
            }),
        },
        {
          headerName: HEADERS_NAME()[FIELDS.remainingQuantity],
          field: FIELDS.remainingQuantity,
          flex: 0.58,
          sortable: true,
          resizable: true,
          renderCell: ({ id, field, value }) =>
            renderCell({
              field,
              id,
              RowComponent,
              value,
            }),
        },
        {
          headerName: HEADERS_NAME(padSelected?.isSourcePad)[FIELDS.padName],
          field: FIELDS.padName,
          flex: 1,
          sortable: false,
          resizable: true,
          renderCell: ({ id, field, value }) =>
            renderCell({
              field,
              id,
              RowComponent,
              value,
            }),
        },
        {
          headerName: HEADERS_NAME()[FIELDS.decisions],
          field: FIELDS.decisions,
          flex: 1.5,
          sortable: false,
          resizable: true,
          renderCell: ({ id, field, value, row }) => {
            if (id === -1) return <></>;

            return renderCell({
              field,
              id,
              RowComponent,
              value,
              row,
            });
          },
        },
      ].map((head) => ({ ...head, reorderable: false }));

      const columns = [
        {
          ...GRID_CHECKBOX_SELECTION_COL_DEF,
          disableReorder: true,
          type: '',
          hideable: false,
          targetIndex: 1,
          renderCell: (cell) => {
            if (cell?.id === 0) return <></>;
            return GRID_CHECKBOX_SELECTION_COL_DEF.renderCell(cell);
          },
        },
        ...primaryColumns,
      ];

      return columns;
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAddingRow, hasUserSelectedManufacturer, type, padSelected, selectedManufacturer, isRevitSourcePad, renderCell],
  );
};

export default useTableHeaders;
