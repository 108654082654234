import { useMemo } from 'react';

import { gql } from '@apollo/client';

import { workRequests as WORK_REQUESTS } from 'graphql/queries';
import useLazyPaginatedQuery from 'hooks/useLazyPaginatedQuery';

const take = 1000;

const useFetchWorkRequests = (): any => {
  const [{ lazyLoad: getWorkRequests, paginationHandler }, { loading, data }] = useLazyPaginatedQuery(
    gql(WORK_REQUESTS),
    'no-cache',
    take,
    false,
  );

  const workRequestsList = useMemo(() => data?.workRequests ?? [], [data?.workRequests]);

  return {
    getWorkRequests,
    workRequests: workRequestsList,
    loading,
    paginationHandler,
  };
};

export default useFetchWorkRequests;
