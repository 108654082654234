import { useCallback } from 'react';

import { FILE_FORMATS } from 'constants/globalConstants';
import { convertURL } from 'helpers/urlFunctions';
import { useTaskViewerContext } from 'modules/Shop/Fabrication/TaskViewer/TaskViewerContext';

import { Document } from '../../../types/Document';

interface useHandleViewDocumentProps {
  section: string | undefined;
}

const useHandleViewDocument = ({ section }: useHandleViewDocumentProps) => {
  const { setActiveDocument, setTypeFileFormat, setSelectedIndexTable } = useTaskViewerContext();

  const handleViewDocument = useCallback(
    (document: Document) => {
      if (document.externalUrl) {
        const url = convertURL(document.externalUrl);
        window.open(url, '_blank');
        return;
      }

      if (document.extension === '.pdf') {
        setTypeFileFormat(FILE_FORMATS.PDF);
      }
      setSelectedIndexTable(section);
      setActiveDocument(document);
    },
    [section, setActiveDocument, setSelectedIndexTable, setTypeFileFormat],
  );

  return { handleViewDocument };
};

export default useHandleViewDocument;
