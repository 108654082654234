import React, { useMemo, useEffect, useCallback, useState } from 'react';

import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { ForesiteTextField } from '@evolvemep/foresite-components-ui-react';
import Grid from '@mui/material/Grid';
import { useWatch, Controller } from 'react-hook-form';

import { CenteredLoadSpinner, ControlledRadioGroup } from 'components';
import { unitOfMeasureTypes as UOM_TYPES, unitOfMeasure as UOM } from 'graphql/queries';
import { debounce } from 'helpers/common';

function UOMForm({ companyId = '', control, errors = {}, onDuplicationStateChange = () => {} }) {
  const { data: uomTypes, loading: loadingUOMTypes } = useQuery(gql(UOM_TYPES), {
    fetchPolicy: 'cache-and-network',
  });
  const uomTypesOptions = useMemo(
    () =>
      (uomTypes?.unitOfMeasureTypes ?? []).map(({ unitOfMeasureTypeId, unitOfMeasureTypeName }) => ({
        value: unitOfMeasureTypeId,
        label: unitOfMeasureTypeName,
      })),
    [uomTypes],
  );
  const defaultUOMType = uomTypesOptions[0]?.value;

  const uomName = useWatch({ control, name: 'uomName' });
  const uomCode = useWatch({ control, name: 'uomCode' });
  const [hasUOMNameDuplicated, setHasUOMNameDuplicated] = useState(false);
  const [hasUOMCodeDuplicated, setHasUOMCodeDuplicated] = useState(false);

  useEffect(() => {
    onDuplicationStateChange({ uomName: hasUOMNameDuplicated, uomCode: hasUOMCodeDuplicated });
  }, [hasUOMCodeDuplicated, hasUOMNameDuplicated, onDuplicationStateChange]);

  const [fetchUOM] = useLazyQuery(gql(UOM), { fetchPolicy: 'no-cache' });

  const checkUOMName = useCallback(
    async (unitOfMeasureName) => {
      const {
        data: { unitOfMeasure: res },
      } = await fetchUOM({
        variables: { query: { companyId, unitOfMeasureName } },
      });
      setHasUOMNameDuplicated(Boolean(res?.length));
    },
    [companyId, fetchUOM],
  );
  const handleUOMNameChange = debounce(checkUOMName);

  useEffect(() => {
    if (uomName) {
      setHasUOMNameDuplicated(false);
      handleUOMNameChange(uomName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uomName]);

  const checkUOMCode = useCallback(
    async (unitOfMeasureCode) => {
      const {
        data: { unitOfMeasure: res },
      } = await fetchUOM({
        variables: { query: { companyId, unitOfMeasureCode } },
      });
      setHasUOMCodeDuplicated(Boolean(res?.length));
    },
    [companyId, fetchUOM],
  );
  const handleUOMCodeChange = debounce(checkUOMCode);

  useEffect(() => {
    if (uomCode) {
      setHasUOMCodeDuplicated(false);
      handleUOMCodeChange(uomCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uomCode]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <UOMName control={control} errors={errors} hasUOMNameDuplicated={hasUOMNameDuplicated} />
        </Grid>
        <Grid item xs={3}>
          <UOMCode control={control} errors={errors} hasUOMCodeDuplicated={hasUOMCodeDuplicated} />
        </Grid>
        <Grid item xs={12}>
          {loadingUOMTypes ? (
            <CenteredLoadSpinner />
          ) : (
            <ControlledRadioGroup
              name="uomType"
              radioOptions={uomTypesOptions}
              control={control}
              defaultValue={defaultUOMType}
              row
              direction="row"
              size="small"
              activeColor="secondary"
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default UOMForm;

const UOMName = ({ control, errors, hasUOMNameDuplicated }) => (
  <Controller
    shouldUnregister
    name="uomName"
    control={control}
    rules={{ required: '*Required' }}
    render={({ field }) => (
      <ForesiteTextField
        fullWidth
        label="*UOM Name"
        maxLength={50}
        helperText={errors.uomName?.message || (hasUOMNameDuplicated && 'UOM already exists')}
        error={errors.uomName || hasUOMNameDuplicated}
        size="small"
        inputProps={{ autoComplete: 'off' }}
        sx={{
          '& .MuiFormHelperText-root.Mui-error': {
            textAlign: 'left !important',
            marginTop: '0px !important',
            marginLeft: '0px !important',
          },
        }}
        {...field}
      />
    )}
  />
);

const UOMCode = ({ control, errors, hasUOMCodeDuplicated }) => (
  <Controller
    shouldUnregister
    name="uomCode"
    control={control}
    rules={{ required: '*Required' }}
    render={({ field }) => (
      <ForesiteTextField
        fullWidth
        label="*Code"
        maxLength={32}
        helperText={errors.uomCode?.message || (hasUOMCodeDuplicated && 'Code already exists')}
        error={errors.uomCode || hasUOMCodeDuplicated}
        size="small"
        inputProps={{ autoComplete: 'off' }}
        sx={{
          '& .MuiFormHelperText-root.Mui-error': {
            textAlign: 'left !important',
            marginTop: '0px !important',
            marginLeft: '0px !important',
          },
        }}
        {...field}
      />
    )}
  />
);
