import type { CombinedUser } from 'app/UserContext';

import { isNil } from './isNotNil';

export const validateUserNameOrEmail = (user: CombinedUser | undefined | null): string => {
  if (isNil(user)) {
    return '';
  }
  if (user.firstName && user.lastName) return `${user.firstName} ${user.lastName}`;
  if (user.firstName) return user.firstName as string;
  if (user.userFirstName && user.userLastName) return `${user.userFirstName} ${user.userLastName}`;
  if (user.userFirstName) return user.userFirstName;
  return user.userEmail ?? '';
};
