import React, { useState, useCallback, useEffect } from 'react';

import { Stack, Button } from '@mui/material';

import { useButtonFilterUpdaters } from 'components/Buttons/FilterButtonWithPopover';

import { usePopover } from './PopoverProvider';
import { useChipsFilterHelpers } from './useChipsFilterHelpers';

const FooterWithButtons = () => {
  const { updateOpen } = useButtonFilterUpdaters();

  const [triggerDone, setTriggerDone] = useState(false);
  const { onClearFilters } = useChipsFilterHelpers(usePopover);

  const updateTriggerDone = useCallback((element) => setTriggerDone(element), []);

  const handleDone = () => updateTriggerDone(true);

  useEffect(() => {
    if (triggerDone) {
      updateOpen(false);
    }
    // eslint-disable-next-line
  }, [triggerDone]);

  return (
    <Stack spacing={2} direction="row" justifyContent="right" sx={{ margin: '8px 8px 16px 8px ' }}>
      <Button color="secondary" variant="outlined" onClick={onClearFilters}>
        CLEAR FILTERS
      </Button>
      <Button color="secondary" variant="contained" onClick={handleDone}>
        DONE
      </Button>
    </Stack>
  );
};

export default FooterWithButtons;
