import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const getModuleRouteWorkOrder = (module: string) =>
  ({
    SHOP: 'shop',
  }[module]);

export const convertToLocalTime = (dateToConvert: string) => dayjs(`${dateToConvert}Z`).local();
