import {
  addFilterOption,
  combineFilterColumns,
  FilterOptionType,
  FilterType,
  sortColumnsOptions,
} from 'components/Popover/FilterPopover/helpers/filterPopoverHelpers';
import { removeDuplicates } from 'helpers/arrayFunctions';

interface WorkOrderType {
  workOrderId: string;
  workOrderName: string;
  workOrderStatusTypeId: string;
  workOrderStatusTypeName: string;
  workRequest: {
    workRequestId: string;
    workRequestName: string;
    projectId: string;
    projectName: string;
    projectIdentifier: string;
  };
}

interface WorkOrdersFiltersType {
  needBy: string;
  workOrderStatusIds: string;
  workRequestIds: string;
  workOrderIds: string;
  projectIds: string;
  take?: number;
}

type WorkOrderstFiltersKeyType = 'needBy' | 'workOrderStatusIds' | 'workRequestIds' | 'workOrderIds' | 'projectIds';

const addFiltersOptions = (workOrders: Array<WorkOrderType>) =>
  workOrders.reduce<Array<FilterOptionType>>((prev, current) => {
    prev = addFilterOption('workOrderId', 'Work Order Id', prev, {
      optionValue: current?.workOrderId,
      optionLabel: current?.workOrderName,
    });
    prev = addFilterOption('workOrderName', 'Work Order Name', prev, {
      optionValue: current?.workRequest?.workRequestId,
      optionLabel: current?.workRequest?.workRequestName,
    });
    prev = addFilterOption('projectName', 'Project Name', prev, {
      optionValue: current?.workRequest?.projectId,
      optionLabel: current?.workRequest?.projectName,
    });
    prev = addFilterOption('projectIdentifier', 'Project ID', prev, {
      optionValue: current?.workRequest?.projectId,
      optionLabel: current?.workRequest?.projectIdentifier,
    });
    prev = addFilterOption('workOrderStatusTypeId', 'Status', prev, {
      optionValue: current?.workOrderStatusTypeId,
      optionLabel: current?.workOrderStatusTypeName,
    });

    return prev;
  }, []);

// Convert workOrders to columns, it's used for fill filter dropdown
export const formatWorkOrdersToColumns = (
  workOrders: Array<WorkOrderType>,
  workOrdersFromTable: Array<WorkOrderType> = [],
  columnsBackup: Array<FilterOptionType> = [],
): Array<FilterOptionType> => {
  if (workOrders.length === 0) return [];

  if (columnsBackup?.length) {
    const columnsFromDataFiltered = addFiltersOptions(workOrdersFromTable);
    const columnsFromDataFilteredSorted = sortColumnsOptions(columnsFromDataFiltered);
    return combineFilterColumns(columnsFromDataFilteredSorted, columnsBackup);
  }

  const columns = addFiltersOptions(workOrders);
  return sortColumnsOptions(columns);
};

export const getFilterNameParamByColumn = (column: string): WorkOrderstFiltersKeyType => {
  switch (column) {
    case 'workOrderStatusTypeId':
      return 'workOrderStatusIds';
    case 'workOrderName':
      return 'workRequestIds';
    case 'workOrderId':
      return 'workOrderIds';
    case 'projectIdentifier':
    case 'projectName':
      return 'projectIds';
    default:
      return 'projectIds';
  }
};

export const formatFiltersToQueryParams = (filters: Array<FilterType>) => {
  if (filters.length === 0) return {};

  return filters.reduce<WorkOrdersFiltersType>((prev, current) => {
    if (current.column === 'dateFrom') {
      prev.needBy = `start:${current.values.toString()}`;
      return prev;
    }

    if (current.column === 'dateTo') {
      prev.needBy = `${prev.needBy},end:${current.values.toString()}`;
      return prev;
    }

    if (current.column === 'take') {
      prev.take = Number(current.values);
      return prev;
    }

    if (!current?.values.length) return prev;

    // merge two same endpoint filter name
    const filterParamName = getFilterNameParamByColumn(current.column);
    if (filterParamName && prev[filterParamName]) {
      const filtersAdded = removeDuplicates([
        ...prev[filterParamName].split(','),
        ...(typeof current.values !== 'string' ? current.values.map((val) => val.value) : []),
      ]);
      prev[filterParamName] = filtersAdded.join(',');
      return prev;
    }

    if (filterParamName) {
      return Object.assign(prev, {
        [filterParamName]: typeof current.values !== 'string' ? current.values.map((val) => val.value).join(',') : '-',
      });
    }

    return prev;
    // @ts-ignore
  }, {});
};
