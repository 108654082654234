import { useEffect, useMemo, useState } from 'react';

import { useForm } from 'react-hook-form';

import { removeDuplicates } from 'helpers/arrayFunctions';

import { useModal } from '../contexts/ModalContext';
import { FOOTER_MESSAGE } from '../LocationsTable/constants/constants';
import useChildrenSummation from './useChildrenSummation';

const useRowQuantities = (projectData) => {
  const [initialUnassignedValue, setInitialUnassignedValue] = useState(0);
  const [unassignedIsDirty, setUnassignedIsDirty] = useState(false);
  const {
    calculationNodeIds,
    errorNodeIds,
    filteredCheckedIds,
    lineItem,
    formDirty,
    setErrorNodeIds,
    setFooterMessage,
  } = useModal();

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      unassigned: 0,
    },
  });

  const { watch, setValue } = formMethods;

  const unassignedQty = watch('unassigned');

  const { childrenSummation, filteredChildrenInputSummation } = useChildrenSummation();

  const projectTotalQty = useMemo(
    () => {
      if (!formDirty) return lineItem.quantity || 0;

      const sum = Number(childrenSummation) + Number(unassignedQty);
      if (!Number.isNaN(sum)) return sum;

      return lineItem.quantity || 0;
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formDirty, unassignedQty, childrenSummation, projectData],
  );

  const projectVisualTotalQty = useMemo(
    () => {
      if (!formDirty) return lineItem.quantity || 0;

      const realChildrenSum = (filteredCheckedIds?.length ? filteredChildrenInputSummation : childrenSummation) || 0;
      let sum = Number(realChildrenSum);
      if (!filteredCheckedIds?.length) sum += Number(unassignedQty);
      if (!Number.isNaN(sum)) return sum;

      return lineItem.quantity || 0;
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formDirty, unassignedQty, childrenSummation, filteredChildrenInputSummation, projectData],
  );

  useEffect(() => {
    if (!Number.isNaN(projectData?.amount)) {
      setInitialUnassignedValue(projectData?.amount);
      setValue('unassigned', projectData?.amount || 0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectData]);

  useEffect(() => {
    if (projectVisualTotalQty > 999999) {
      setErrorNodeIds(removeDuplicates([...calculationNodeIds, ...errorNodeIds]));
      setFooterMessage(FOOTER_MESSAGE.QTY_LIMIT_EXCEEDED);
    } else {
      setErrorNodeIds([]);
      setFooterMessage(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectVisualTotalQty, calculationNodeIds]);

  return {
    unassignedQty,
    formMethods,
    projectTotalQty,
    projectVisualTotalQty,
    filteredChildrenInputSummation,
    initialUnassignedValue,
    unassignedIsDirty,
    setUnassignedIsDirty,
  };
};

export default useRowQuantities;
