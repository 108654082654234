import React from 'react';

import { Box, Divider, Typography } from '@mui/material';

import StackRow from 'components/StackRow';

import { useProjectRow } from '../../contexts/ProjectRowContext';
import QtyInput from './QuantityInput';

const UnassignedRow = () => {
  const { unassignedQty } = useProjectRow();

  return (
    unassignedQty >= 0 && (
      <>
        <StackRow sx={{ justifyContent: 'space-between', minHeight: '32px', gap: '8px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
            <Box sx={{ width: '191px' }} />
            <Typography variant="subtitle2" sx={{ fontWeight: '600' }}>
              Unassigned
            </Typography>
          </Box>
          <QtyInput />
        </StackRow>
        <Divider sx={{ width: '100%' }} />
      </>
    )
  );
};

export default UnassignedRow;
