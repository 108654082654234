import React from 'react';

import Typography from '@mui/material/Typography';

import Divider from 'components/Divider';
import {
  cleanExtraFormFields,
  ROW_TYPES,
  ROW_TYPES as TYPES,
} from 'modules/Field/LocationsAndWorkPhases/constants/constants';

import IdentifierDelimiterList from './components/FormFields/IdentifierDelimiterList';
import IdentifierPrefixInput from './components/FormFields/IdentifierPrefixInput';
import LocationTypeList from './components/FormFields/LocationTypeList';
import NameDelimiterList from './components/FormFields/NameDelimiter';
import NamePrefixInput from './components/FormFields/NamePrefixInput';
import NomenclatureRadioList from './components/FormFields/NomenclatureRadioList';
import NumberOfNodesInput from './components/FormFields/NumberOfNodes';
import StartOnInput from './components/FormFields/StartOnInput';
import { RowWithSpacer } from './components/SharedComponents';

const LocationForm = ({ form }) => {
  const { formValues, setValue, trigger, control } = form;
  const { locationTypeName, locationFormatId } = formValues;

  const locationIs = (askedLocations) => askedLocations.includes(locationTypeName);

  const onLocationTypeChange = (selectedLocationType) => {
    if ([locationTypeName, selectedLocationType].includes(ROW_TYPES.LOCATION_SYSTEM))
      cleanExtraFormFields(setValue, trigger);
  };

  const onLocationFormatChange = (event) => {
    const newLocationFormatId = event.target.value;
    if (newLocationFormatId === 'Alpha') setValue('startOn', 'A');
    else if (newLocationFormatId === 'Numeric') setValue('startOn', '1');
    else if (newLocationFormatId === 'Roman Numeral') setValue('startOn', 'I');
    setTimeout(() => {
      trigger('startOn');
    }, 0);
  };

  return (
    <>
      <Typography variant="body2" sx={{ mt: 2, mb: 0.5, fontWeight: 600, lineHeight: '24px' }}>
        Fill in all fields when creating a new location type
      </Typography>
      <RowWithSpacer marginTop={2}>
        <LocationTypeList
          onSelectChange={(e) => onLocationTypeChange(e.target.value)}
          control={control}
          sx={{ marginTop: 0 }}
          formControlSx={{
            '& .MuiTextField-root': {
              margin: 0,
            },
          }}
        />
        {!locationIs([TYPES.LOCATION_SYSTEM]) && <NumberOfNodesInput control={control} />}
      </RowWithSpacer>
      <Divider sx={{ backgroundColor: '#CCC', marginY: 1 }} />
      {!locationIs([TYPES.LOCATION_SYSTEM]) && (
        <>
          <NomenclatureRadioList control={control} onChange={onLocationFormatChange} />
          <RowWithSpacer marginTop={2}>
            <IdentifierPrefixInput control={control} />
            <IdentifierDelimiterList
              control={control}
              sx={{ marginTop: 0 }}
              formControlSx={{
                '& .MuiTextField-root': {
                  margin: 0,
                },
              }}
            />
            <StartOnInput format={locationFormatId} control={control} mt={9} />
          </RowWithSpacer>
        </>
      )}
      <RowWithSpacer marginTop={2}>
        <NamePrefixInput
          label={!locationIs([TYPES.LOCATION_SYSTEM]) ? 'Location Prefix' : 'Id'}
          control={control}
        />
        {!locationIs([TYPES.LOCATION_SYSTEM]) && (
          <NameDelimiterList
            control={control}
            sx={{ marginTop: 0 }}
            formControlSx={{
              '& .MuiTextField-root': {
                margin: 0,
              },
            }}
          />
        )}
      </RowWithSpacer>
    </>
  );
};

export default LocationForm;
