import React, { useEffect, useMemo } from 'react';

import { Stack, Typography } from '@mui/material';

import ConfirmLeavingModal from 'components/ConfirmLeavingModal/ConfirmLeavingModal';
import WizardStepper from 'modules/Field/LocationsAndWorkPhases/Locations/CreateLocationsModal/components/WizardStepper';

import { useCurrentProjectContext } from '../../context/CurrentProjectContext';
import { useItemsIntegrationModalContext } from '../../context/ItemsIntegrationModalContext';
import { useNewWRFormContext } from '../NewWRForm/context/NewWRFormContext';
import NewWRForm from '../NewWRForm/NewWRForm';
import { useItemsIntegrationTableContext } from '../QuantityStep/components/ItemsIntegrationTable/context/ItemsIntegrationTableContext';
import QuantityStep from '../QuantityStep/QuantityStep';
import WorkRequestsTable from '../WorkRequestsTable/WorkRequestsTable';

const WizardContent = () => {
  const {
    steps,
    completed,
    activeStep,
    setActiveStep,
    addNewWRForm,
    showOnlyQtyStep,
    showDialog,
    openConfirmLeaveModal,
    handleClose,
    setOpenConfirmLeaveModal,
    setShowDialog,
    setAddNewWRForm,
    showSteps,
    isLoading,
  } = useItemsIntegrationModalContext();
  const {
    methods: { reset: resetQtyValues },
    resetAllRows,
  } = useItemsIntegrationTableContext();
  const { currentProject } = useCurrentProjectContext();
  const {
    methods: {
      reset,
      formState: { isDirty },
    },
  } = useNewWRFormContext();

  const canClickSteps = useMemo(() => !isLoading, [isLoading]);

  const handleClickStep = () => {
    if (isDirty) {
      setAddNewWRForm(true);
    }
    setActiveStep(0);
  };

  useEffect(() => {
    if (!showDialog) setShowDialog(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const renderWizardSteps: any = !showOnlyQtyStep
    ? {
        0: addNewWRForm ? <NewWRForm /> : <WorkRequestsTable />,
        1: <QuantityStep />,
      }
    : {
        0: <QuantityStep />,
      };

  if (!currentProject) return <Typography>No Project found. Please select a Project first</Typography>;
  return (
    <>
      <ConfirmLeavingModal
        sx={{ maxWidth: '505px' }}
        onConfirmNavigation={() =>
          handleClose(() => {
            resetAllRows();
            resetQtyValues();
            reset();
          })
        }
        onCancelNavigation={() => {
          setShowDialog(true);
          setOpenConfirmLeaveModal(false);
        }}
        open={openConfirmLeaveModal}
        showDialog={showDialog}
      >
        Navigating away will lose all changes. Are you sure you want to leave this page?
      </ConfirmLeavingModal>
      {showSteps && (
        <Stack sx={{ padding: '16px 0px' }}>
          <WizardStepper {...{ steps, activeStep, setActiveStep, completed, handleClickStep, canClickSteps }} />
        </Stack>
      )}
      {renderWizardSteps[activeStep]}
    </>
  );
};

export default WizardContent;
