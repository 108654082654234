import React, { useEffect, useRef, useState } from 'react';

import { InputForesiteField } from '@evolvemep/foresite-components-ui-react';
import { Grid, Typography, Box } from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';

const VariantCodesForm = ({ isEdit, loading }) => {
  const { control, watch, setValue } = useFormContext();
  const [focusIndex, setFocusIndex] = useState(0);
  const { fields } = useFieldArray({ name: 'variantCodes', control });
  const variants = watch('variants');
  const variantCodes = watch('variantCodes');
  const variantCodesRef = useRef([]);

  const getCodeValue = (variant) => {
    const variantExists = variantCodesRef.current?.find((item) => item.label === variant);
    return variantExists?.value || '';
  };

  useEffect(
    () => {
      if (!isEdit) {
        const variantCodes = variants?.map((variant) => ({
          value: getCodeValue(variant),
          label: variant,
        }));

        setValue('variantCodes', variantCodes);

        variantCodesRef.current = variantCodes;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [variants, variants?.[0]],
    isEdit,
  );
  const handleChange = () => {
    variantCodesRef.current = variantCodes;
  };
  return (
    <Box sx={{ mt: 4, ml: 2 }}>
      {fields.map((field, index) => (
        <VariantCodeRow sx={{ minWidth: 200 }} key={field.id}>
          <Grid item sm={5} sx={{ alignSelf: 'center' }}>
            <VariantCodeLabel label={field.label} />
          </Grid>
          <Grid item sm={7}>
            <VariantCodeField
              disabled={loading}
              onChange={handleChange}
              index={index}
              control={control}
              variantCodes={variantCodes}
              setFocusIndex={setFocusIndex}
              autoFocus={focusIndex === index}
            />
          </Grid>
        </VariantCodeRow>
      ))}
    </Box>
  );
};

export default VariantCodesForm;

const VariantCodeRow = ({ children }) => (
  <Grid container sx={{ width: '51%' }} spacing={2}>
    {children}
  </Grid>
);

const VariantCodeLabel = ({ label }) => (
  <Typography
    sx={{
      fontsize: '13px',
      lineHeight: '18px',
      // textOverflow: 'ellipsis'
      overflowWrap: 'break-word',
    }}
  >
    {label}
  </Typography>
);

const VariantCodeField = ({ index, variantCodes, control, onChange, disabled, setFocusIndex, autoFocus }) => (
  <InputForesiteField
    onKeyDown={(e) => {
      if (e.keyCode === 13) {
        setFocusIndex(index + 1);
      }
    }}
    autoComplete="off"
    onChange={onChange}
    autoFocus={autoFocus}
    name={`variantCodes[${index}].value`}
    size="small"
    label="Variant Code"
    control={control}
    color="secondary"
    inputProps={{ maxLength: 6 }}
    disabled={disabled}
    rules={{
      maxLength: {
        value: 6,
        message: 'Code limited to 6 characters',
      },
      validate: () => {
        const codes = variantCodes.filter((item) => item.value.length).map((item) => item.value.trim().toLowerCase());
        if (new Set(codes).size !== codes.length) {
          return 'Name already exists.';
        }
        return true;
      },
    }}
    sx={{
      '& .MuiFormHelperText-contained': {
        marginTop: '0px',
        height: variantCodes?.length - 1 === index ? 0 : 14,
      },
    }}
  />
);
