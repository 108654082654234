import React from 'react';

import { Typography } from '@mui/material';

import OverflowTip from 'components/Tooltip/OverflowTip';

export const CellComponentWRName = ({ value }) => (
  <OverflowTip title={value}>
    <Typography overflow="hidden" textOverflow="ellipsis" variant="body2">
      {value}
    </Typography>
  </OverflowTip>
);
