/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { memo, useCallback } from 'react';

import { Box } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import OverflowTip from 'components/Tooltip/OverflowTip';
import { requiredRule } from 'constants/inputFieldRules';
import useLazyPaginatedWorkOrderItems from 'hooks-api/queries/workOrderItems/useLazyPaginatedWorkOrderItems';
import WorkRequestControlInput from 'modules/Field/WorkRequests/WorkRequest/WorkRequestControlInput';

import { useTableRowContext } from '../context/TableRowContext';
import { useTableRowFormContext } from '../context/TableRowFormContext';
import { useWorkOrderItemsContext } from '../context/WorkOrderItemsContext';
import AddItemButton from './AddItemButton';

const WorkOrderItemNameCell = memo(() => {
  const { item, isAddItemRow, showForm, setShowForm, setFieldFocus, fieldFocus } = useTableRowContext();
  const { canEditItemName } = useWorkOrderItemsContext();

  const handleItemNameClick = () => {
    if (canEditItemName) {
      setShowForm(true);
      setFieldFocus('workOrderItemName');
    }
  };

  if (showForm)
    return (
      <Box sx={{ width: '100%', height: '100%', pt: showForm ? '4px' : '6px' }}>
        <ItemNameInput autoFocus={isAddItemRow || fieldFocus === 'workOrderItemName'} />
      </Box>
    );

  return isAddItemRow ? (
    <AddItemButton />
  ) : (
    <OverflowTip title={item?.workOrderItemName}>
      <div
        onClick={handleItemNameClick}
        onKeyDown={handleItemNameClick}
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: '100%',
          height: '100%',
          cursor: canEditItemName && 'pointer',
          paddingTop: '6px',
        }}
      >
        {item?.workOrderItemName}
      </div>
    </OverflowTip>
  );
});

export default WorkOrderItemNameCell;

const ItemNameInput = ({ autoFocus }) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();
  const { workOrder, canEditItemName } = useWorkOrderItemsContext();
  const { workOrderItems } = useLazyPaginatedWorkOrderItems(workOrder);
  const { item, isAddItemRow } = useTableRowContext();
  const { onKeyDown } = useTableRowFormContext();

  const noDuplicateItemName = useCallback(
    (value) => {
      const itemNamesDuplicated = workOrderItems?.some(
        (iterator) =>
          iterator?.workOrderItemName.trim().toLowerCase() === value &&
          iterator?.workOrderItemId !== item?.workOrderItemId,
      );

      return !itemNamesDuplicated || 'Item already exists';
    },
    [item?.workOrderItemId, workOrderItems],
  );

  const inputValue = watch('workOrderItemName');
  const workOrderItemNameValidation = inputValue?.length === 128;

  return (
    <WorkRequestControlInput
      control={control}
      name="workOrderItemName"
      placeholder="Item"
      rules={{
        ...requiredRule('Item name', '*Required'),
        validate: {
          noDuplicate: (value) => noDuplicateItemName(value.trim().toLowerCase()),
        },
      }}
      autoFocus={autoFocus}
      maxLength={128}
      helperText={workOrderItemNameValidation ? '128/128' : null}
      error={!!errors?.workOrderItemName || workOrderItemNameValidation}
      defaultValue={!isAddItemRow ? item.workOrderItemName : null}
      onKeyDown={onKeyDown}
      inputProps={{
        maxLength: 128,
        'data-cy': 'work-request-item-name',
      }}
      disabled={!canEditItemName}
    />
  );
};
