import React, { useState } from 'react';

const DeleteModalContext = React.createContext();

const DeleteModalProvider = ({ children }) => {
  const [deleteModalText, setDeleteModalText] = useState(null);
  const [locationToBeDeleted, setLocationToBeDeleted] = useState();
  const [workPhaseToBeDeleted, setWorkPhaseToBeDeleted] = useState();

  const openDeleteLocationModal = (location, action = 'delete') => {
    setLocationToBeDeleted(location);
    const nodeName = location.locationName || location.locationIdentifier || location.workPhaseName;
    setDeleteModalText(`Are you sure you want to ${action} ${nodeName}?`);
  };

  const openDeleteWorkPhaseModal = (workPhaseRow) => {
    setWorkPhaseToBeDeleted(workPhaseRow);
    setDeleteModalText(`Are you sure you want to delete ${workPhaseRow.label}?`);
  };

  const hideDeleteModal = () => {
    setLocationToBeDeleted(null);
    setWorkPhaseToBeDeleted(null);
    setDeleteModalText(null);
  };

  const valueObj = {
    deleteModalText,
    locationToBeDeleted,
    workPhaseToBeDeleted,
    openDeleteLocationModal,
    openDeleteWorkPhaseModal,
    hideDeleteModal,
  };

  return <DeleteModalContext.Provider value={valueObj}>{children}</DeleteModalContext.Provider>;
};

const useDeleteModal = () => {
  const context = React.useContext(DeleteModalContext);
  if (context === undefined) {
    throw new Error('useDeleteModal must be used within a DeleteModalContext');
  }
  return context;
};

export { DeleteModalContext, DeleteModalProvider, useDeleteModal };
