import { Typography, Box } from '@mui/material';

const PositionTitle = () => (
  <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
    <Typography variant="subtitle2" ml={5}>
      File name
    </Typography>
    <Typography variant="subtitle2" ml="auto" mr={10}>
      Date
    </Typography>
  </Box>
);

export default PositionTitle;
