import React, { FC } from 'react';

import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';

import { Custom } from 'components/Icons';
import StackRow from 'components/StackRow';
import { SelectedIntegrationItem } from 'modules/Materials/components/ItemsIntegrationModal/@types/itemsIntegrationModalContext';

import { ITEM_ICON_DICT } from '../../constants';
import ArrowDetailIcon from '../ArrowDetailIcon/ArrowDetailIcon';
import LBSRowCell from '../LocationRow/LBSRowCell';
import ProjectRow from '../ProjectRow/ProjectRow';
import QuantityField from '../QuantityField/QuantityField';
import RowWrapper from '../RowWrapper/RowWrapper';
import { useItemRow } from './hooks/useItemRow';

type IItemsIntegrationRow = {
  selectedItem: SelectedIntegrationItem;
};

const ItemsIntegrationRow: FC<IItemsIntegrationRow> = ({ selectedItem }: IItemsIntegrationRow) => {
  const {
    qtyFieldName,
    item,
    showDeleteButton,
    showRows,
    projectInfo,
    showExpandRowsArrow,
    handleDeleteItemCallback,
    handleShowRows,
    handleShowDeleteBtn,
  } = useItemRow(selectedItem);
  if (!item || item?.totalQuantityRemaining === 0) return <></>;
  return (
    <>
      <RowWrapper
        isBomLineItem
        onMouseEnter={() => handleShowDeleteBtn(true)}
        onMouseLeave={() => handleShowDeleteBtn(false)}
        qtyFieldName={qtyFieldName}
        qtyRemaining={item?.qtyRemaining}
        qtyAggregate={item?.qtyAggregate}
      >
        <StackRow sx={{ flex: 1, gap: '8px' }}>
          <Box sx={{ width: `8px` }} />
          <StackRow position="relative">
            <DeleteButton onClick={handleDeleteItemCallback} show={showDeleteButton} />
            <ArrowDetailIcon onToggle={() => handleShowRows()} show={showExpandRowsArrow} />
            <LineItemTypeIcon showRows={showRows}>{ITEM_ICON_DICT[item?.lineItemTypeId]}</LineItemTypeIcon>
          </StackRow>
          <LBSRowCell width="340px" label={item?.itemName ?? ''} />
        </StackRow>
        <StackRow marginRight="16px" gap="16px">
          <TopLevelQuantity totalQuantity={item?.totalQuantityRemaining ?? 0} />
          <QuantityField
            name={`bomItem__${item?.id}`}
            rowIdentifier={item?.itemName ?? ''}
            qtyRemaining={item?.totalQuantityRemaining}
            qtyAggregate={item?.totalQuantityRemaining}
          />
        </StackRow>
      </RowWrapper>
      <ProjectRow
        show={showRows}
        lineItem={item}
        workPhases={item?.workPhases}
        projectInfo={projectInfo}
        lineItemId={item?.id}
      />
    </>
  );
};

export default ItemsIntegrationRow;

interface IDeleteButton {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  show: boolean;
}

const DeleteButton: FC<IDeleteButton> = ({ onClick, show }: IDeleteButton) => {
  if (!show) return null;
  return (
    <IconButton tabIndex={-1} size="small" onClick={onClick} sx={{ position: 'absolute', left: -22, zIndex: 3 }}>
      <Custom.Close opacity={0.54} />
    </IconButton>
  );
};

type LineItemTypeIconProps = {
  showRows: boolean;
  children: React.ReactNode;
};

const LineItemTypeIcon = ({ showRows, children }: LineItemTypeIconProps) => {
  const { palette }: any = useTheme();
  return (
    <Stack
      alignItems="center"
      sx={{
        '& svg': {
          fill: `${showRows ? palette.surface.lightSurface.primary : palette.surface.lightSurface.secondary}`,
        },
      }}
    >
      {children}
    </Stack>
  );
};

type TopLevelQuantityProps = {
  totalQuantity: number;
};

const TopLevelQuantity = ({ totalQuantity }: TopLevelQuantityProps) => (
  <Typography width="96px" marginRight="20px" textAlign="right" variant="body2">
    {totalQuantity}
  </Typography>
);
