/* eslint-disable max-lines-per-function */
import { gql, useMutation, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { VARIANT_ERROR } from 'constants/snackbarConstants';
import {
  addLocation as ADD_LOCATION,
  deleteLocation as DELETE_LOCATION,
  updateLocation as UPDATE_LOCATION,
  unmapWorkPhaseFromLocation as UNMAP_WORK_PHASE,
} from 'graphql/mutations';
import { locations as LOCATIONS } from 'graphql/queries';
import { findParent, removeChildrenRecursively, replaceItemsById, findNode } from 'helpers/arrayFunctions';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

import useProjectDataLayer from '../../hooks/useProjectDataLayer';
import { mapChipsToSingleLocationBackend } from '../CreateLocationsModal/helpers';

export default function useLocationsDataLayer(updateSuffix = () => {}, fetchLocationsByWorkPhase = () => {}) {
  const { handleResponse } = useGraphqlResponseHandler();
  const { fetchProjectLocations } = useProjectDataLayer();
  const { enqueueSnackbar } = useSnackbar();

  const [addLocationMutation] = useMutation(gql(ADD_LOCATION));
  const [deleteLocationMutation] = useMutation(gql(DELETE_LOCATION));
  const [updateLocationMutation] = useMutation(gql(UPDATE_LOCATION));
  const [unmapWorkPhaseMutation] = useMutation(gql(UNMAP_WORK_PHASE));

  const { loading, refetch } = useQuery(gql(LOCATIONS), {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const fetchLocations = async (locations, node, successCallback = () => {}) => {
    const mapLocations = (fetchedLocations) => {
      const updatedNode = { ...node, isOpen: true, children: fetchedLocations };
      const updatedLocations = replaceItemsById(updatedNode, locations, 'locationId');
      successCallback(updatedLocations);
    };

    const query = {
      projectId: locations[0].locationId,
      parentLocationId: node.locationId,
      orderBy: 'sortOrder:asc',
    };
    const response = await refetch({ query });

    const fetchedLocations = response?.data?.locations;
    if (fetchedLocations === null) {
      enqueueSnackbar('Error fetching locations', VARIANT_ERROR);
      mapLocations();
    } else mapLocations(fetchedLocations);
  };

  const updatedSiblings = (locations, parent, mainLocationId) => {
    const siblings = parent.children.filter(({ locationId }) => locationId !== mainLocationId);
    const updatedParent = findNode(parent.locationId, locations, 'locationId');
    updatedParent.children = updatedParent.children.map((child) => {
      const sibling = siblings.filter((sibling) => sibling.locationId === child.locationId)[0];
      if (sibling) return { ...child, children: sibling.children, isOpen: sibling.isOpen };
      return child;
    });
    return updatedParent;
  };

  const updateSiblings = (setLocations, mainLocationId, locations, parent) => {
    const parentIsProject = parent === locations[0];

    if (parentIsProject) {
      fetchProjectLocations(parent, (newLocations) => {
        setLocations([updatedSiblings([newLocations], parent, mainLocationId)]);
      });
    } else {
      fetchLocations(locations, parent, (newLocations) => {
        setLocations(
          replaceItemsById(updatedSiblings(newLocations, parent, mainLocationId), newLocations, 'locationId'),
        );
      });
    }
  };

  const updateLocationListOnWorkPhasesTable = (locationPhases) => {
    if (!locationPhases || !locationPhases.length) return;
    const deletedLocationPhaseIds = locationPhases.map(({ workPhaseId }) => workPhaseId);
    const uniqueWorkPhaseIds = Array.from(new Set(deletedLocationPhaseIds));
    fetchLocationsByWorkPhase(uniqueWorkPhaseIds);
  };

  const addLocation = (location, locations, locationTypes, { locationId: projectId }, setLocations = () => {}) => {
    const parent = findParent(location.locationId, locations, 'locationId');
    handleResponse(
      addLocationMutation,
      {
        variables: {
          body: mapChipsToSingleLocationBackend(projectId, parent, location, locationTypes),
        },
      },
      { successMessage: `${location.locationTypeName} successfully created` },
      ({ addLocation: response }) => {
        // update siblings for preventing sortOrder issues
        updateSiblings(setLocations, location.locationId, locations, parent, response);
        const { movedLocationPhases } = response || {};
        updateLocationListOnWorkPhasesTable(movedLocationPhases);
      },
      () => setLocations(removeChildrenRecursively(location.locationId, locations, 'locationId')),
    );
  };

  const updateLocation = (location, errorCallback = () => {}) => {
    const { locationId, locationIdentifier, locationName, parentLocationId, sortOrder, locationTypeName } = location;

    handleResponse(
      updateLocationMutation,
      {
        variables: {
          params: { locationId },
          body: { locationIdentifier, locationName, parentLocationId, sortOrder },
        },
      },
      { successMessage: `${locationTypeName} successfully updated` },
      (response) => {
        if (!response?.updateLocation) return;

        const { updatedLocationPhases } = response?.updateLocation || {};
        updateLocationListOnWorkPhasesTable(updatedLocationPhases);
      },
      () => errorCallback(),
    );
  };

  const removeLocationRow = (location, locations, response, setLocations = () => {}) => {
    const { locationId } = location;
    const parent = findParent(locationId, locations, 'locationId');
    parent.children = parent.children.filter((child) => child.locationId !== locationId);
    if (parent.children.length === 0) parent.childrenTypeName = null;
    setLocations(replaceItemsById(parent, locations, 'locationId'));
    updateSuffix();

    const { deletedLocationPhases } = response?.deleteLocation || {};
    updateLocationListOnWorkPhasesTable(deletedLocationPhases);
  };

  const unmapWorkPhase = (workPhase, locations, setLocations = () => {}) => {
    const { locationPhaseId, workPhaseId } = workPhase;

    const parent = findParent(locationPhaseId, locations, 'locationPhaseId', 'children', 'locationPhases');
    parent.locationPhases = parent.locationPhases.filter(({ locationPhaseId: id }) => locationPhaseId !== id);
    setLocations(replaceItemsById(parent, locations, 'locationId'));
    updateSuffix();
    fetchLocationsByWorkPhase([workPhaseId]);
  };

  const removeLocation = (location, locations, setLocations = () => {}) => {
    if (location.locationPhaseId) {
      const { locationPhaseId } = location;
      handleResponse(
        unmapWorkPhaseMutation,
        { variables: { params: { locationPhaseId } } },
        { successMessage: `Work Phase successfully deleted` },
        (response) => {
          if (response) unmapWorkPhase(location, locations, setLocations);
        },
      );
      return;
    }

    const { locationId, locationTypeName } = location;
    handleResponse(
      deleteLocationMutation,
      { variables: { params: { locationId } } },
      { successMessage: `${locationTypeName} successfully deleted` },
      (response) => {
        if (response.deleteLocation) removeLocationRow(location, locations, response, setLocations);
      },
    );
  };

  return {
    fetchLocations,
    addLocation,
    updateLocation,
    removeLocation,
    loadingLocations: loading,
  };
}
