import React, { useState } from 'react';

import { Stack } from '@mui/material';

import AttributeMultiSelectField from './AttributeMultiSelectField';
import AttributeSelectCodeField from './AttributeSelectCodeField';
import { ATTRIBUTE_TYPE } from './AttributeSelectHelper';
import AttributeSingleSelectField from './AttributeSingleSelectField';

const AttributeSelect = ({ attribute, index }) => {
  const [newAttributeVariant, setNewAttributeVariant] = useState(false);

  return (
    <Stack direction="row" sx={{ gap: 2 }}>
      <Stack sx={{ gap: 2, flex: 1 }}>
        {attribute?.partAttributeTypeName === ATTRIBUTE_TYPE.multiSelect ? (
          <AttributeMultiSelectField
            setNewAttributeVariant={setNewAttributeVariant}
            attribute={attribute}
            index={index}
          />
        ) : (
          <AttributeSingleSelectField
            setNewAttributeVariant={setNewAttributeVariant}
            attribute={attribute}
            index={index}
          />
        )}
      </Stack>
      <>
        {newAttributeVariant && (
          <AttributeSelectCodeField attribute={attribute} size="small" index={index} />
        )}
      </>
    </Stack>
  );
};

export default AttributeSelect;
