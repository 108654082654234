export type StatusKey =
  | 'Draft'
  | 'Rejected'
  | 'Submitted'
  | 'Pending'
  | 'Accepted'
  | 'Approved'
  | 'Canceled'
  | 'Not Started'
  | 'In Progress'
  | 'workOrderDraft'
  | 'Completed'
  | 'Started'
  | 'Not started'
  | 'Ready'
  | 'Paused'
  | 'Late'
  | 'Blocked';

export interface BadgeStatus {
  variant: string;
  label: string;
}

export const BADGE_MAPPING_STATUS: Record<StatusKey, BadgeStatus> = {
  Draft: {
    variant: 'draft',
    label: 'Draft',
  },
  Rejected: {
    variant: 'rejected',
    label: 'Rejected',
  },
  Submitted: {
    variant: 'submittedForApproval',
    label: 'Submitted',
  },
  Pending: {
    variant: 'pending',
    label: 'Pending',
  },
  Accepted: {
    variant: 'approved',
    label: 'Approved',
  },
  Approved: {
    variant: 'approved',
    label: 'Approved',
  },
  Canceled: {
    variant: 'canceled',
    label: 'Canceled',
  },
  'Not Started': {
    variant: 'new',
    label: 'Not started',
  },
  'In Progress': {
    variant: 'inProgress',
    label: 'In progress',
  },
  workOrderDraft: {
    variant: 'draftWorkOrder',
    label: 'Draft',
  },
  Completed: {
    variant: 'completed',
    label: 'Completed',
  },
  Started: {
    variant: 'inProgress',
    label: 'In progress',
  },
  'Not started': {
    variant: 'new',
    label: 'Not started',
  },
  Ready: {
    variant: 'inProcess',
    label: 'Ready',
  },
  Paused: {
    variant: 'inProgress',
    label: 'In progress',
  },
  Late: {
    variant: 'late',
    label: 'Late',
  },
  Blocked: {
    variant: 'blocked',
    label: 'Blocked',
  },
};
