import { gql, useMutation } from '@apollo/client';

import { updateWorkRequest as UPDATE_WORK_REQUEST } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

export const useUpdateWorkRequest = () => {
  const { handleResponse } = useGraphqlResponseHandler();

  const [updateWorkRequestMutation, { data, loading }] = useMutation(gql(UPDATE_WORK_REQUEST), {
    awaitRefetchQueries: true,
  });

  const updateWorkRequest = async (workRequestId, body, successCallBack = () => {}) =>
    handleResponse(
      updateWorkRequestMutation,
      {
        variables: { params: { workRequestId }, body },
        refetchQueries: ['WorkRequests', 'WorkOrders'],
      },
      { successMessage: '' },
      successCallBack,
    );

  return {
    data,
    loading,
    updateWorkRequest,
  };
};
