import { SxProps } from '@mui/material';

import { getRandomColor } from 'helpers/getRandomColor';

export const squareStyles: (color?: string) => SxProps = (color) => ({
  content: "''",
  backgroundColor: color ?? getRandomColor(),
  display: 'inline-block',
  width: '8px',
  height: '8px',
  borderRadius: '1px',
  marginRight: '9px',
  position: 'relative',
  top: '-1px',
});
