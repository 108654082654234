import { Fragment, forwardRef, useMemo } from 'react';

import { ListItem, List, ListItemText, Divider, Typography, SxProps, Button } from '@mui/material';

import { ProjectDetailPopup } from '../ProjectDetailWorkOrder';
import {
  listItemStyles,
  textSecondaryStyles,
  primaryTypographyStyles,
  listItemTextStyles,
  buttonViewAllStyles,
} from './styles';
import { SlotBaseWeekAndMonthProps } from './types/SlotBaseWeekAndMonthTypes';

export const SlotBaseWeekAndMonth = forwardRef<HTMLUListElement, SlotBaseWeekAndMonthProps>(
  (
    {
      resourceList,
      day,
      onClose,
      listProps,
      anchorEl,
      projects,
      showDivider,
      listItemSxPros,
      popoverProps,
      projectsColors,
      getProjectNameWithKey,
      getProjectIdWithKey,
    },
    ref,
  ) => {
    const projectsEntries = useMemo(() => Object.entries(projects), [projects]);
    const showViewAll = useMemo(() => projectsEntries.length > 2, [projectsEntries]);

    return (
      <>
        <ProjectDetailPopup
          {...{
            projects,
            day,
            anchorEl,
            onClose,
            popoverProps,
            projectsColors,
            getProjectIdWithKey,
            getProjectNameWithKey,
            countWorkOrder: resourceList.length,
          }}
        />
        <List
          dense
          ref={ref}
          sx={{
            height: showViewAll ? 'calc(100% - 2.8rem)' : 'calc(100% - 1.2rem)',
            overflow: 'auto',
          }}
          {...listProps}
        >
          {projectsEntries.map(([project, workOrders], i, arr) => (
            <Fragment key={`${project}-list-item`}>
              <ListItem
                sx={
                  {
                    ...listItemStyles(projectsColors.get(getProjectIdWithKey(project))),
                    ...listItemSxPros,
                  } as SxProps
                }
              >
                <ListItemText
                  sx={{ ...listItemTextStyles }}
                  primaryTypographyProps={{
                    fontSize: 'small',
                    sx: { ...primaryTypographyStyles },
                  }}
                  secondary={
                    <Typography component="span" sx={{ ...textSecondaryStyles }}>
                      {workOrders.length}
                    </Typography>
                  }
                >
                  {getProjectNameWithKey(project)}
                </ListItemText>
              </ListItem>
              {(arr.length - 1 !== i || showDivider) && <Divider />}
            </Fragment>
          ))}
        </List>
        {showViewAll && (
          <Button size="small" sx={buttonViewAllStyles} variant="outlined" color="secondary">
            View all
          </Button>
        )}
      </>
    );
  },
);
