import { useEffect, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from 'notistack';
import { matchRoutes, useLocation, useNavigate, useParams } from 'react-router-dom';

import ComponentPaneHeader from 'app/Layout/ReactGridLayout/ComponentPaneHeader';
import CenteredLoadSpinner from 'components/CenteredLoadSpinner';
import Header from 'components/Header';
import { VARIANT_ERROR, VARIANT_SUCCESS } from 'constants/snackbarConstants';
import { getDateFromString, getDaysBetween } from 'helpers/dateFunctions';
import useFetchWorkRequest from 'hooks-api/useFetchWorkRequest';
import useWorkRequestMutation from 'hooks-api/useWorkRequestMutation';
import { WorkRequestByIdQuery } from 'hooks-api/useWorkRequestStatusMutation';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import useSetupModule from 'hooks/useSetupModule';
import useUrlParams from 'hooks/useUrlParams';

import { getModuleRoute } from '../FieldWorkRequestHelpers';
import { useWorkRequestsContext } from '../WorkRequestsContext';
import useUpdateWorkRequestStatus from './hooks/useUpdateWorkRequestStatus';
import { InterfaceSelectorWorkRequest } from './InterfaceSelectorWorkRequest';
import { useWorkRequestItemsContext } from './WorkRequestItemsContext';
import WorkRequestTitle from './WorkRequestTitle';
import WorkRequestTitleButtons from './WorkRequestTitleButtons';

const routes = [{ path: '/shop/work-requests/:id' }];
// eslint-disable-next-line max-lines-per-function
const WorkRequestHeader = ({ selectedItem, isResponsiveWR }) => {
  const [loadingSubmitWorkRequestStatus, setLoadingSubmitWorkRequestStatus] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { id: workRequestId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const isWorkrequestRoute = matchRoutes(routes, location);
  const { currentModule } = useSetupModule();
  const moduleName = currentModule();
  const moduleRoute = getModuleRoute(moduleName);
  const { setWorkRequest, workRequestSelected, currentTable } = useWorkRequestsContext();
  const { inForgeView } = useWorkRequestItemsContext();
  const { workRequest, loading } = useFetchWorkRequest(workRequestId ?? workRequestSelected?.workRequestId);
  const { handleResponse } = useGraphqlResponseHandler();
  const { updateWorkRequestMutation, loading: loadingSubmit } = useWorkRequestMutation();

  const { updateWorkRequestStatus } = useUpdateWorkRequestStatus();

  const {
    params: { nav },
  } = useUrlParams();

  const updateWorkRequest = async (workRequestProperties, callback) => {
    await handleResponse(
      updateWorkRequestMutation,
      {
        variables: {
          params: { workRequestId: workRequest.workRequestId },
          body: workRequestProperties,
        },
        refetchQueries: [WorkRequestByIdQuery(workRequest.workRequestId)],
        awaitRefetchQueries: true,
      },
      { successMessage: `${workRequest.workRequestIdentifier} updated successfully` },
      callback,
    );
  };

  const lastDate =
    workRequest?.workRequestStatusName === 'Completed' && workRequest?.lastStatusUpdate
      ? getDateFromString(workRequest?.lastStatusUpdate)
      : new Date();
  const needByDate = getDateFromString(workRequest?.needBy);
  const daysRemainingCount = needByDate && lastDate ? getDaysBetween(needByDate, lastDate) : 0;

  const isLate = daysRemainingCount < 0;

  const updatedWorkRequestStatus = async (workRequestToUpdate, label, description, successCallBack) => {
    try {
      setLoadingSubmitWorkRequestStatus(true);
      const result = await updateWorkRequestStatus(workRequestToUpdate, label, description);
      enqueueSnackbar(result, VARIANT_SUCCESS);
      if (successCallBack) successCallBack();
    } catch (err) {
      enqueueSnackbar('Could not save changes.', VARIANT_ERROR);
    } finally {
      setLoadingSubmitWorkRequestStatus(false);
    }
  };

  const updateWorkRequestStatusToPending = () => {
    if (workRequest?.workRequestStatusName === 'Submitted' && selectedItem?.type !== 'PROJECT') {
      updatedWorkRequestStatus(workRequest, 'Pending', workRequest.workRequestDescription);
    }
  };

  useEffect(() => {
    if (loading) return;
    if (!workRequest) {
      navigate(`/${moduleRoute}/work-requests`);
      return;
    }
    updateWorkRequestStatusToPending();
    setWorkRequest(workRequest);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, navigate, setWorkRequest, workRequest]);

  const renderInterfaceSelector = () =>
    inForgeView ? (
      <InterfaceSelectorWorkRequest key="1" currentTable={currentTable} forcedPosition={{ x: 26, y: 20 }} />
    ) : (
      <InterfaceSelectorWorkRequest key="2" currentTable={currentTable} />
    );

  const getStatusButtons = () => {
    if (location.pathname.includes('drawing-register')) return <></>;
    if (loadingSubmitWorkRequestStatus) return <CircularProgress />;
    return (
      <>
        {isWorkrequestRoute || nav === 'work-requests' ? renderInterfaceSelector() : null}

        <WorkRequestTitleButtons
          updateWorkRequest={updateWorkRequest}
          loadingWRStatus={loadingSubmitWorkRequestStatus}
          updateWorkRequestStatus={updatedWorkRequestStatus}
          selectedItem={selectedItem}
          workRequest={workRequest}
          inForgeViewer={inForgeView}
        />
      </>
    );
  };

  const statusButtons = getStatusButtons();

  return loadingSubmit || loading || !workRequest ? (
    <CenteredLoadSpinner />
  ) : (
    <ComponentPaneHeader>
      <Header
        title={
          <WorkRequestTitle
            title={`${workRequest?.workRequestIdentifier}: ${workRequest?.workRequestName}`}
            status={workRequest?.workRequestStatusName}
            isResponsiveWR={isResponsiveWR}
            isLate={isLate}
          />
        }
        option={statusButtons}
        sx={{ height: '100%' }}
      />
    </ComponentPaneHeader>
  );
};

export default WorkRequestHeader;
