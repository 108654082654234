import React from 'react';

import { ISelectedItem } from 'app/FacilitiesProjectsContext';
import { Custom } from 'components/Icons';
import { actionColors } from 'styles/theme/colors';

import WorkOrdersMessage from './WorkOrdersMessage';

const MESSAGES = {
  noFacility: 'Select a facility to view work orders.',
  noFacilityShop: 'This facility does not have a shop. Add a shop to continue.',
};

type DefaultMessagesProps = {
  selectedItem?: ISelectedItem | null | undefined;
  shopDepartmentId?: string | null;
  hasWorkOrders?: boolean;
};

const DefaultMessages = ({ selectedItem, shopDepartmentId, hasWorkOrders }: DefaultMessagesProps) => {
  const facility = selectedItem?.type === 'FACILITY';

  if (!selectedItem || selectedItem?.type !== 'FACILITY')
    return (
      <WorkOrdersMessage message={MESSAGES.noFacility} icon={<Custom.WorkOrders fill={actionColors.disabled} />} />
    );
  if (facility && !shopDepartmentId)
    return <WorkOrdersMessage message={MESSAGES.noFacilityShop} icon={<Custom.ShopProperties />} />;
  if (facility && !hasWorkOrders)
    return (
      <WorkOrdersMessage
        message="This facility has no open work orders."
        icon={<Custom.WorkOrders fill={actionColors.disabled} />}
      />
    );
  return null;
};

export default DefaultMessages;
