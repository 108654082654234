import React from 'react';

import { Stack, Typography } from '@mui/material';

import Section from 'components/Section';

import SelectedItems from './SelectedItems';

const ModalTable = () => (
  <Section margin="8px 24px 24px 24px" maxHeight="320px">
    <Section.Title justifyContent="space-between" minHeight="32px" padding="0 24px">
      <Typography variant="tableHeader">Items</Typography>
      <Stack direction="row">
        <Typography variant="tableHeader" marginRight="40px">
          Qty Rem...
        </Typography>
        <Typography variant="tableHeader" marginRight="40px">
          Qty
        </Typography>
      </Stack>
    </Section.Title>
    <Section.Body>
      <SelectedItems />
    </Section.Body>
  </Section>
);

export default ModalTable;
