import React from 'react';

import { AccordionList } from '@evolvemep/foresite-components-ui-react';

import { GetItemList } from './GetItemList';

const FlyoutAccordion = () => {
  const items = GetItemList();
  const titleStyles = { color: 'white', fontSize: '14px', fontWeight: 600 };
  return (
    <AccordionList
      items={items}
      hideFirstBorder
      hideLastBorder
      sx={{
        '& .MuiPaper-root': { backgroundColor: 'transparent !important' },
        margin: '0 10px 0 10px',
        '& svg': {
          fill: 'white',
          opacity: 1,
        },
      }}
      titleSx={titleStyles}
      infoSx={{ ...titleStyles, lineHeight: '20px', marginLeft: '5px' }}
    />
  );
};

export default FlyoutAccordion;
