import React, { useEffect } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';
import ReactDOM from 'react-dom';

import { CenteredLoadSpinner } from 'components';
import WorkCellItem from 'components/KanbanBoard/KanbanCard/common/WorkCellItem';
import KanbanColumn from 'components/KanbanBoard/KanbanColumn';

import { useWorkCellTasks } from './WorkCellTasksContext';

const dndPortal = document.getElementById('dnd-portal');

const WorkCellsColumn = ({ workCellName, workCellId, disabled, isDisabledDragAndDrop, setHasTasks }) => {
  const {
    tasks: workCellTasks,
    loadingWorkCellTasks,
    workOrderColors,
    onDragUpdate,
    onDragEnd,
    onDragStart,
    placeholderProps,
  } = useWorkCellTasks();

  const handleDragStart = (event) => onDragStart(event);
  const handleDragEnd = (event) => onDragEnd(event);
  const handleDragUpdate = (event) => onDragUpdate(event);

  const showColor = (workOrderId) => workOrderColors?.find((group) => group?.workOrderId === workOrderId)?.color;

  useEffect(() => {
    if (workCellTasks?.length > 0) setHasTasks(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workCellTasks]);

  const renderTasks = () => {
    if (loadingWorkCellTasks) return <CenteredLoadSpinner />;
    if (!workCellTasks?.length) return null;
    return workCellTasks.map((workCellTask, index) => (
      <WorkCellTaskItem
        data-testid="kanban-task"
        key={workCellTask.workCellTaskId}
        index={index}
        workCellId={workCellId}
        isDisabledDragAndDrop={isDisabledDragAndDrop}
        color={showColor(workCellTask.workOrderId)}
        workRequestName={workCellTask.workRequestName}
        workOrderName={workCellTask.workOrderName}
        workOrderId={workCellTask.workOrderId}
        taskId={workCellTask.taskId}
        {...workCellTask}
      />
    ));
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart} onDragUpdate={handleDragUpdate}>
      <Droppable droppableId={workCellId} type={workCellId} isDropDisabled={isDisabledDragAndDrop}>
        {({ droppableProps, innerRef }, { isDraggingOver }) => (
          <KanbanColumn
            title={workCellName}
            disabled={disabled}
            numberItems={workCellTasks?.length ?? 0}
            active={isDraggingOver}
            {...droppableProps}
            ref={innerRef}
          >
            {renderTasks()}
            {isDraggingOver && <TargetLine dimensions={placeholderProps} />}
          </KanbanColumn>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const TargetLine = ({ dimensions, ...props }) => (
  <Box
    sx={{
      position: 'absolute',
      top: dimensions.clientY + 50,
      left: '8px',
      height: '2px',
      background: 'secondary.main',
      width: dimensions.clientWidth,
    }}
    {...props}
  />
);

const WorkCellTaskItem = ({
  taskStatusTypeName,
  projectName,
  taskName,
  isEnabled = true,
  needBy,
  index,
  color,
  workCellTaskId,
  isDisabledDragAndDrop,
  workRequestName,
  workOrderName,
  workOrderId,
  taskId,
  ...restProps
}) => (
  <Draggable
    draggableId={workCellTaskId}
    key={workCellTaskId}
    index={index}
    isDragDisabled={isDisabledDragAndDrop || (isEnabled !== null ? !isEnabled : false)}
  >
    {(provided, snapshot) => {
      const MainContent = (
        <Stack {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
          <WorkCellItem
            status={taskStatusTypeName}
            color={color}
            disabled={isEnabled !== null ? !isEnabled : false}
            projectName={projectName}
            taskName={taskName}
            date={needBy}
            workRequestName={workRequestName}
            workOrderName={workOrderName}
            workOrderId={workOrderId}
            taskId={taskId}
            {...restProps}
          />
          {provided.placeholder}
        </Stack>
      );

      return snapshot.isDragging ? ReactDOM.createPortal(MainContent, dndPortal) : MainContent;
    }}
  </Draggable>
);

export default React.memo(WorkCellsColumn);
