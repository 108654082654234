import { useCallback } from 'react';

import { gql, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { GENERIC_MUTATION_ERROR } from 'constants/globalConstants';
import { addTaskDocument as ADD_TASK_DOCUMENT } from 'graphql/mutations';

const useRegisterDocument = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [addTaskDocument, { loading }] = useMutation(gql(ADD_TASK_DOCUMENT), {
    refetchQueries: ['TaskMultiSearch', 'TaskDocument'],
  });

  const addDocs = useCallback(
    async (targetTaskId, documentIds, notification, checkboxFolderId) => {
      const body = {
        taskId: targetTaskId,
        documentIds: checkboxFolderId ? [] : documentIds,
        folderId: checkboxFolderId,
      };

      await addTaskDocument({
        variables: {
          body,
        },
        awaitRefetchQueries: true,
        onCompleted: () => notification.snackBar,
        onError: () => {
          enqueueSnackbar(GENERIC_MUTATION_ERROR, {
            preventDuplicate: true,
            variant: 'error',
          });
        },
      });
    },
    [addTaskDocument, enqueueSnackbar],
  );

  return { addDocs, loading };
};

export default useRegisterDocument;
