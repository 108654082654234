/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import { VARIANT_ERROR } from 'constants/snackbarConstants';
import {
  addChildrenRecursively,
  clone,
  findAnyOccurrence,
  findFirstOccurrence,
  findNode,
  findParent,
  recursiveToggleEditNodeLabel,
  recursiveToggleKey,
  recursiveUpdateNode,
  removeChildrenRecursively,
} from 'helpers/arrayFunctions';

import { ROW_TYPES } from '../../constants/constants';
import { useActiveRow } from '../../contexts/ActiveRowContext';
import { useDeleteModal } from '../../contexts/DeleteModalContext';
import { useProject } from '../../contexts/ProjectContext';
import useProjectDataLayer from '../../hooks/useProjectDataLayer';
import { appendNewChildBySortOrder } from '../../Locations/helpers/tableRowHelpers';
import { createRow, mapProject, mapScopePackages } from '../helpers/scopePackageHelper';
import useLocationsByWorkPhasesDataLayer from './useLocationsByWorkPhaseDataLayer';
import useScopePackageDataLayer from './useScopePackageDataLayer';
import useWorkPhasesDataLayer from './useWorkPhaseDataLayer';

export default function useWorkPhases(locations, setLocations) {
  const { fetchSummarize } = useProjectDataLayer();
  const [resume, setResume] = useState(false);
  const { activeRows, setActiveRows } = useActiveRow();
  const [tableRows, setTableRows] = useState();
  const [backupState, _setBackupState] = useState();
  const [backupInfo, setBackupInfo] = useState();

  const { selectedProject, loading: loadingProjectDetails } = useProject();
  const { workPhaseToBeDeleted, hideDeleteModal } = useDeleteModal();
  const { enqueueSnackbar } = useSnackbar();

  const isEditingARow = findAnyOccurrence(tableRows || [], 'isEditing');

  const backupRef = React.useRef(backupState);
  const setBackupState = (data) => {
    backupRef.current = data;
    _setBackupState(data);
  };

  const cleanBackup = () => {
    setBackupState(null);
    setBackupInfo(null);
  };

  const goToBackup = () => {
    setTableRows(backupRef.current);
    setActiveRows([]);
    cleanBackup();
  };

  const { fetchScopePackages, loadingScopePackages, addScopePackage, updateScopePackage, removeScopePackage } =
    useScopePackageDataLayer(locations, setLocations, () => goToBackup());

  const { fetchWorkPhases, addWorkPhase, updateWorkPhase, removeWorkPhase, loadingWorkPhases } = useWorkPhasesDataLayer(
    locations,
    setLocations,
    () => goToBackup(),
  );

  const { fetchLocationListByWorkPhase, fetchLocationList, loadingLocationList } = useLocationsByWorkPhasesDataLayer(
    () => goToBackup(),
  );

  const getLastChildSortOrder = (node) => {
    const { children } = node;
    return children?.length ? children[children.length - 1].sortOrder + 1 : 1;
  };

  const insertPrefixToNode = (parent, node, prefix) => {
    let siblingIdentifiers = parent.children?.map(({ prefix }) => prefix?.split('-').pop());

    const idNumbersOnly = siblingIdentifiers?.map((id) => id.replace(/[^0-9]/g, ''));
    siblingIdentifiers = idNumbersOnly?.filter((id) => !Number.isNaN(id)) || [0];
    let highestIdentifier = Math.max(...siblingIdentifiers);
    if (highestIdentifier === -Infinity) highestIdentifier = 0;
    node.prefix = `${prefix}-${highestIdentifier + 1}`;
  };

  const insertWorkPhaseAtEnd = (parent, node) => {
    insertPrefixToNode(parent, node, 'WPH');
    node.sortOrder = getLastChildSortOrder(parent);
    const updatedParent = { ...appendNewChildBySortOrder(parent, node), isOpen: true };
    setTableRows(recursiveUpdateNode(updatedParent, tableRows));
  };

  const insertScopePackage = (parent, node) => {
    insertPrefixToNode(parent, node, 'SP');
    setTableRows(addChildrenRecursively(parent, tableRows, node));
  };

  const handleAddRow = () => {
    setBackupState(clone(tableRows));
    const activeRow = activeRows[0];
    const newChild = createRow(activeRow.type, activeRow.id);

    const targetParent = activeRow.type === ROW_TYPES.WORK_PHASE ? findParent(activeRow.id, tableRows) : activeRow;

    if (newChild.type === ROW_TYPES.SCOPE_PACKAGE) insertScopePackage(targetParent, newChild);
    else insertWorkPhaseAtEnd(targetParent, newChild);

    setActiveRows([newChild]);
  };

  const fetchLocationsList = (workPhase) =>
    fetchLocationListByWorkPhase(selectedProject.locationId, workPhase, setTableRows);

  const fetchLocationsByWorkPhase = async (workphasesIds = []) => {
    try {
      const workPhases = workphasesIds.map((id) => findNode(id, tableRows));
      const results = await Promise.all(
        workPhases.map(async (workPhase) => {
          const res = await fetchLocationList(selectedProject.locationId, workPhase);
          return res;
        }),
      );

      results.forEach(({ data }, index) => {
        const workPhase = workPhases[index];
        const newChildren = clone(data?.locationListByWorkPhase || []);
        workPhase.isOpen = true;
        workPhase.children = newChildren;
        workPhase.hasLocationPhases = newChildren.length > 0;
        setTableRows((prev) => recursiveUpdateNode(workPhase, prev));
      });
    } catch (error) {
      enqueueSnackbar('Error updating locations lists', VARIANT_ERROR);
    }
  };

  const toggleOpenRow = (node) => {
    setTableRows(recursiveToggleKey(node.id, tableRows, 'isOpen'));

    if (!node.isOpen) {
      if (node.hasLocationPhases && !node.children) fetchLocationsList(node);
      return;
    }

    const editingNode = findFirstOccurrence('isEditing', true, node.children);
    if (editingNode) setTableRows(removeChildrenRecursively(editingNode.id, tableRows));
  };

  const toggleEditRow = (targetNode) => {
    if (!targetNode.isEditing) {
      setBackupInfo(targetNode);
      setBackupState(clone(tableRows));
    }
    setTableRows(recursiveToggleEditNodeLabel(targetNode, tableRows));

    setActiveRows([targetNode]);
  };

  const createScopePackage = (scopePackage) => {
    const { locationId: projectId } = selectedProject;
    addScopePackage(scopePackage, projectId, tableRows, (newRows) => setTableRows(newRows));
  };

  const createWorkPhase = (workPhase) => {
    const { locationId: projectId } = selectedProject;
    addWorkPhase(workPhase, projectId, tableRows, (newRows) => setTableRows(newRows));
  };

  const handleUpdateRow = (row) => {
    if (
      row.isEditing &&
      row.prefix?.trim() === backupInfo?.prefix?.trim() &&
      row.label?.trim() === backupInfo?.label?.trim()
    ) {
      toggleEditRow(row);
      return;
    }

    const isUpdatingAScopePackage = row.isEditing && row.type === ROW_TYPES.SCOPE_PACKAGE;
    const isUpdatingAWorkPhase = row.isEditing && row.type === ROW_TYPES.WORK_PHASE;

    const isCreatingAWorkPhase = row.type === ROW_TYPES.WORK_PHASE && row.isNew && row.isEditing;
    const isCreatingAScopePackage = row.type === ROW_TYPES.SCOPE_PACKAGE && row.isNew && row.isEditing;

    if (isCreatingAScopePackage) createScopePackage(row);
    else if (isUpdatingAScopePackage) updateScopePackage(row);
    else if (isCreatingAWorkPhase) createWorkPhase(row);
    else if (isUpdatingAWorkPhase) updateWorkPhase(row, tableRows);

    toggleEditRow(row);
  };

  const getWorkPhases = () => fetchWorkPhases(selectedProject.locationId, setTableRows, setActiveRows);

  const fetchScopePackagesList = () => {
    const project = mapProject(selectedProject);
    const displayScopePackages = (scopePackages = []) => {
      const children = mapScopePackages(scopePackages);
      if (children[0]) setActiveRows([children[0]]);
      setTableRows([{ ...project, children, isOpen: true }]);
      getWorkPhases();
    };

    setActiveRows([project]);
    setTableRows([project]);
    fetchScopePackages(selectedProject.locationId, displayScopePackages);
  };

  const updateSuffix = () => fetchSummarize(selectedProject, setResume);

  const handleDeleteRow = () => {
    if (workPhaseToBeDeleted.type === ROW_TYPES.SCOPE_PACKAGE)
      removeScopePackage(workPhaseToBeDeleted.id, tableRows, setTableRows, updateSuffix, () => {
        if (!tableRows[0].children.length) fetchScopePackagesList();
      });
    else removeWorkPhase(workPhaseToBeDeleted.id, tableRows, setTableRows, updateSuffix);

    hideDeleteModal();
  };

  const cancelCreation = () => {
    if (backupRef.current) goToBackup();
  };

  useEffect(() => {
    if (selectedProject) {
      setBackupState(null);
      fetchScopePackagesList();
      updateSuffix();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject]);

  const rowsStatus = { tableRows, setTableRows };
  const frontendEvents = { toggleOpenRow, toggleEditRow, isEditingARow };
  const backendEvents = { handleAddRow, handleDeleteRow, handleUpdateRow };

  const workPhases = (() => {
    if (!tableRows) return [];
    return tableRows[0]?.children?.reduce((workPhases, scopePackage) => {
      workPhases = [...workPhases, ...(scopePackage.children || [])];
      return workPhases;
    }, []);
  })();

  return {
    ...rowsStatus,
    ...frontendEvents,
    ...backendEvents,
    loading: loadingScopePackages || loadingProjectDetails,
    loadingWorkPhases,
    cancelCreation,
    scopePackages: tableRows ? tableRows[0]?.children : [],
    getWorkPhases,
    workPhases,
    updateSuffix,
    loadingLocationList,
    fetchLocationsByWorkPhase,
    fetchLocationsList,
    resume,
  };
}
