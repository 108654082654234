import React from 'react';

import { Box, Link, Typography } from '@mui/material';

import { Custom } from 'components/Icons';
import { otherColors } from 'styles/theme/colors';

import EntitlementCheckInOutForm from './EntitlementCheckInOutForm';

const LicenseCheckDropdownContent = ({ onBack }: { onBack: () => void }) => (
  <Box textAlign="center" width="281px" padding="16px">
    <BackButton onClick={onBack} />
    <EntitlementCheckInOutForm />
  </Box>
);

export default LicenseCheckDropdownContent;

const BackButton = ({ onClick }: { onClick: () => void }) => (
  <Typography variant="body2">
    <Link
      onClick={onClick}
      sx={{ color: 'other.links', cursor: 'pointer', display: 'flex', alignItems: 'center' }}
    >
      <Custom.ArrowBackAlt fill={otherColors.links} />
      Back
    </Link>
  </Typography>
);
