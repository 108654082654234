import React from 'react';

import { InputForesiteField } from '@evolvemep/foresite-components-ui-react';
import { IconButton, InputAdornment } from '@mui/material';
import { Control, FieldValues, useFormContext } from 'react-hook-form';

import { Custom } from 'components/Icons';

export type WorkOrderNameInputComponentProps = {
  control: Control<FieldValues, any>;
};

const WorkOrderNameInputComponent = () => {
  const {
    reset,
    control,
    formState: { isDirty, isSubmitting },
  } = useFormContext();

  const handleClearInput = () => reset({ workOrderName: '' });

  return (
    <InputForesiteField
      name="workOrderName"
      autoFocus
      control={control}
      maxLength={32}
      inputProps={{ style: { backgroundColor: 'transparent', color: 'white' } }}
      size="extra-small"
      color="secondary"
      sx={{ mt: 1 }}
      disabled={isSubmitting}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={{
        endAdornment: isDirty && !isSubmitting && (
          <InputAdornment position="end">
            <IconButton sx={{ mr: -2 }} onClick={handleClearInput} aria-label="clear">
              <Custom.Close fill="white" />
            </IconButton>
          </InputAdornment>
        ),
      }}
      darkTheme
    />
  );
};

export default WorkOrderNameInputComponent;
