import React, { useState, useEffect } from 'react';

import { Typography } from '@mui/material';
import { useForm } from 'react-hook-form';

import useKeyPressEffect from 'hooks/useKeyPressEffect';

import { useWorkRequestsContext } from '../../../WorkRequestsContext';
import { InformativeRow } from '../InformativeRow';
import DescriptionForm from './DescriptionForm';
import DescriptionText from './DescriptionText';

export const DescriptionField = () => {
  const { workRequestSelected, workOrderSelected } = useWorkRequestsContext();
  const [hovered, setHovered] = useState(false);
  const editable =
    ['Draft', 'Canceled', 'Rejected', 'Submitted', 'Pending'].includes(workRequestSelected.workRequestStatusName) &&
    !workOrderSelected;

  const {
    control,
    reset,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm({
    mode: 'all',
    defaultValues: { workRequestDescription: workRequestSelected.workRequestDescription },
  });

  useKeyPressEffect('Escape', () => setHovered(false));

  useEffect(() => {
    reset({ workRequestDescription: workRequestSelected?.workRequestDescription });
    setHovered(false);
  }, [workRequestSelected.workRequestDescription, reset]);

  return (
    <InformativeRow>
      <Typography color="white" variant="body2">
        Description
      </Typography>
      {hovered && editable ? (
        <DescriptionForm
          setHovered={setHovered}
          control={control}
          reset={reset}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
        />
      ) : (
        <DescriptionText setHovered={setHovered} />
      )}
    </InformativeRow>
  );
};
