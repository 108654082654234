import React from 'react';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Custom } from 'components/Icons';
import OverFlowTooltip from 'components/Tooltip/OverFlowTooltip';

import { FilterButtonProps } from './@types/buttons';

const FilterButton = ({
  onClickFilter = () => {},
  buttonText = 'FILTER',
  sx,
  disabled = false,
  color = 'secondary.dark',
  isResponsive = false,
  lowerTooltip,
  ...props
}: FilterButtonProps) => (
  <OverFlowTooltip title="Filter" showAlways={isResponsive} lowerTooltip={lowerTooltip}>
    <Button
      data-testid="filter-button"
      onClick={onClickFilter}
      sx={{
        padding: isResponsive ? '0px 0px' : '6px 8px',
        minWidth: 0,
        color: disabled ? 'secondary.disabled' : color,
        '&:hover': { backgroundColor: 'secondary.outlinedHoverBackground' },
        ...sx,
      }}
      disabled={disabled}
      {...props}
    >
      <Stack direction="row" alignItems="center">
        <Custom.FilterList
          sx={{
            fontSize: isResponsive ? null : '16px',
            marginRight: isResponsive ? null : '4px',
            color: disabled ? 'secondary.disabled' : color,
          }}
        />
        {!isResponsive && (
          <Typography
            variant="button"
            sx={{
              fontWeight: 600,
              letterSpacing: '0.4px',
              color: disabled ? 'secondary.disabled' : color,
            }}
          >
            {buttonText}
          </Typography>
        )}
      </Stack>
    </Button>
  </OverFlowTooltip>
);

export default FilterButton;
