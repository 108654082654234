import { useCallback, useMemo } from 'react';

import { gql } from '@apollo/client';

import { locations as LOCATIONS } from 'graphql/queries';
import useLazyPaginatedQuery from 'hooks/useLazyPaginatedQuery';
import { useProject } from 'modules/Field/LocationsAndWorkPhases/contexts/ProjectContext';

const getId = (project, id) => {
  if (id) return id;
  if (project) return project.projectById.projectId;
  return null;
};

const useLocationQuery = (parentLocationId = null, take = 10, fetchPolicy = 'cache-and-network') => {
  const { selectedProject, itemSelectedId } = useProject();

  const apiQuery = useMemo(
    () => ({
      projectId: getId(selectedProject, itemSelectedId),
      parentLocationId,
      take,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [parentLocationId, selectedProject, itemSelectedId],
  );

  const [{ lazyLoad, paginationHandler: customPaginationHandler }, { data, loading, called }] = useLazyPaginatedQuery(
    gql(LOCATIONS),
    fetchPolicy,
    10,
  );

  const fetchLocations = useCallback(async () => {
    lazyLoad({ ...apiQuery, skip: 0 });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentLocationId]);

  const paginationHandler = (skip) => customPaginationHandler(skip, apiQuery);

  return { fetchLocations, paginationHandler, locations: data?.locations || [], called, loading };
};

export default useLocationQuery;
