import { useState } from 'react';

import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { useUser } from 'app/UserContext';
import { VARIANT_ERROR } from 'constants/snackbarConstants';
import {
  addFolder as ADD_FOLDER,
  addDocument as ADD_DOCUMENT,
  getDocumentPresignedURL as GET_DOCUMENT_PRESIGNED_URL,
} from 'graphql/mutations';
import { folders as FOLDERS, documentById as DOCUMENT_BY_ID } from 'graphql/queries';

import useMachineDataLayer from '../components/MachineRow/hooks/useMachineDataLayer';

const getFolderId = async (folders, addFolder, user, machineId, storagePath) => {
  if (folders?.length > 0) {
    return folders[0].folderId;
  }
  const newFolder = await addFolder({
    variables: {
      body: {
        companyId: user.companyId,
        folderName: machineId,
        storagePath,
        requestedBy: user.userId,
      },
    },
  });
  return newFolder.data?.addFolder?.folderId;
};

// eslint-disable-next-line max-lines-per-function
export default function useMachineImageDataLayer() {
  const { updateMachineMutation } = useMachineDataLayer();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useUser();
  const [uploadingMachineImage, setUploadingMachineImage] = useState(false);
  const [uploadingProgress, setUploadingProgress] = useState(0);
  const [addFolder] = useMutation(gql(ADD_FOLDER));
  const [addDocument] = useMutation(gql(ADD_DOCUMENT));
  const [getPresignedURL, { loading: loadingPresignedUrl }] = useMutation(gql(GET_DOCUMENT_PRESIGNED_URL));

  const [fetchFolders] = useLazyQuery(gql(FOLDERS));
  const [fetchDocument, { loading: loadingDocument }] = useLazyQuery(gql(DOCUMENT_BY_ID));

  const uploadMachineImage = async (machineId, imgFile) => {
    setUploadingMachineImage(true);
    try {
      const storagePath = `all/company/${user.companyId}/machine/${machineId}/images`;
      const fileName = imgFile.name;

      const presignedURLResponse = await getPresignedURL({
        variables: {
          body: {
            expirationHours: 24,
            objectKey: `${storagePath}/${fileName}`,
            requestedBy: user.userId,
            verb: 'PUT',
          },
        },
      });
      setUploadingProgress(15);

      const presignedURL = presignedURLResponse.data?.getDocumentPresignedURL?.preSignedURL;
      const contentType = presignedURLResponse.data?.getDocumentPresignedURL?.contentType;
      await fetch(presignedURL, {
        method: 'PUT',
        mode: 'cors',
        body: imgFile,
        headers: {
          'Content-Type': contentType,
        },
      });
      setUploadingProgress(30);

      const {
        data: { folders },
      } = await fetchFolders({
        variables: { query: { searchPhrase: machineId, companyId: user.companyId } },
      });
      setUploadingProgress(45);

      const folderId = await getFolderId(folders, addFolder, user, machineId, storagePath);
      setUploadingProgress(55);

      const newDocument = await addDocument({
        variables: {
          body: {
            folderId,
            documentName: fileName,
            storagePath,
            requestedBy: user.userId,
          },
        },
      });
      const documentId = newDocument.data?.addDocument?.documentId;
      setUploadingProgress(80);

      if (!documentId) return null;

      await updateMachineMutation({
        variables: { params: { machineId }, body: { machineImageId: documentId } },
      });

      setUploadingProgress(100);
      return documentId;
    } catch (error) {
      enqueueSnackbar('Uploading machine image failed', VARIANT_ERROR);
      return null;
    } finally {
      setUploadingMachineImage(false);
      setUploadingProgress(0);
    }
  };

  const getMachineImage = async (machineImageId) => {
    const document = await fetchDocument({
      variables: {
        params: {
          documentId: machineImageId,
        },
      },
    });
    if (!document.data) return null;
    const filePath = `${document.data.documentById.storagePath}/${document.data.documentById.documentName}`;

    const presignedURLResponse = await getPresignedURL({
      variables: {
        body: {
          expirationHours: 24,
          objectKey: filePath,
          requestedBy: user.userId,
          verb: 'GET',
        },
      },
    });

    return presignedURLResponse.data?.getDocumentPresignedURL?.preSignedURL;
  };

  return {
    getMachineImage,
    loadingGetImage: loadingDocument || loadingPresignedUrl,
    uploadMachineImage,
    uploadingMachineImage,
    uploadingProgress,
  };
}
