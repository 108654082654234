import React from 'react';

import Typography from '@mui/material/Typography';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import ComponentPaneHeader from 'app/Layout/ReactGridLayout/ComponentPaneHeader';

import { useDrawingRegisterContext } from '../DrawingRegisterContext';
import useDrawingRegisterBreadcrumbs from '../DrawingRegisterUtils/useDrawingRegisterBreadcrumbs';
import DrawingFolderBreadcrumb from './components/DrawingFolderBreadcrumb/DrawingFolderBreadcrumb';
import { DRAWING_DOCUMENT_VIEWS, useDrawingDocumentContext } from './DrawingDocumentContext';

const DrawingDocumentsHeader = () => {
  const { navigateToFolder } = useDrawingRegisterContext();
  const { breadcrumbs, loading } = useDrawingRegisterBreadcrumbs();
  const { inForgeView, setCurrentView } = useDrawingDocumentContext();
  const { selectedItem } = useFacilitiesProjects();
  const { activeItem, setActiveItem } = useDrawingRegisterContext();

  const onSelectBreadcrumb = (folder) => {
    if (folder === null) setActiveItem(selectedItem);
    navigateToFolder(folder?.folderId);

    setCurrentView(DRAWING_DOCUMENT_VIEWS.DOCUMENTS_TABLE);
  };

  const showBreadcrumbs =
    (selectedItem.type === 'FACILITY' && breadcrumbs.length >= 0) ||
    (selectedItem.type === 'PROJECT' && breadcrumbs.length > 0) ||
    inForgeView;

  return (
    <ComponentPaneHeader>
      {showBreadcrumbs ? (
        <DrawingFolderBreadcrumb
          folders={breadcrumbs}
          loading={loading}
          onSelect={onSelectBreadcrumb}
          inForgeView={inForgeView}
          selectedItem={selectedItem}
          activeItem={activeItem}
        />
      ) : (
        <Typography variant="h5" sx={{ color: 'rgba(0, 0, 0, 0.87)', display: 'inline' }}>
          Drawing Register
        </Typography>
      )}
    </ComponentPaneHeader>
  );
};

export default DrawingDocumentsHeader;
