import React from 'react';

import { Button, Menu, SxProps, Tooltip } from '@mui/material';

import { Custom } from 'components/Icons';
import { slugify } from 'helpers/stringFunctions';

import BreadcrumbsLink from './BreadcrumbsLink';

type BreadcrumbsMenuProps = {
  items: Array<{ label: string; url?: string; onClick?: () => void }>;
  sx?: SxProps;
  buttonSx?: SxProps;
  icon?: React.ReactNode;
  darkMode: boolean;
};

const BreadcrumbsMenu = ({ items, sx, buttonSx, icon, darkMode }: BreadcrumbsMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    // @ts-ignore
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title={items.length === 1 ? items[0].label : undefined} arrow>
        <Button
          id="breadcrumb-collapse-button"
          data-testid="breadcrumb-collapse-button"
          aria-controls={open ? 'breadcrumb-collapse-button' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          size="small"
          sx={{
            minWidth: '24px',
            padding: '2px',
            color: darkMode ? 'surface.darkSurface.secondary' : 'surface.lightSurface.secondary',
            ...buttonSx,
          }}
        >
          {icon || <Custom.MoreVerAlt sx={{ fontSize: 24, transform: 'rotate(90deg)' }} />}
        </Button>
      </Tooltip>
      <Menu
        id="breadcrumb-collapse-menu"
        data-testid="breadcrumb-collapse-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'breadcrumb-collapse-button',
        }}
        sx={sx}
      >
        {items?.map(({ label, url, onClick }) => (
          <BreadcrumbsLink
            key={slugify(label)}
            url={url ?? '/'}
            onClick={() => {
              if (onClick) onClick();
              handleClose();
            }}
            label={label}
            darkMode={darkMode}
            isMenuItem
          />
        ))}
      </Menu>
    </>
  );
};

export default BreadcrumbsMenu;
