import React, { useEffect } from 'react';

import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import { VARIANT_ERROR } from 'constants/snackbarConstants';

import { useTakeOff } from '../../context/TakeOffContext';
import { TakeOffActionModal } from '../TakeOffActionModal';
import { useTabPadsContext } from './TabPadsContext';
import TabsComponent from './TabsComponent';
import useTabPadsActions from './useTabPadsActions';

const enqueueErrorMessage = (errorMessage, enqueueSnackbar) =>
  enqueueSnackbar(errorMessage, { autoHideDuration: 3000, ...VARIANT_ERROR });

const TabPads = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, getValues, reset } = useForm({ mode: 'all' });

  const {
    padItems,
    pads,
    padItemsCount,
    loadingSourcePads,
    loadingTakeOffPads,
    sourcePadsQty,
    onAddTakeOffPad,
    onSelectPad,
  } = useTakeOff();
  const { currentName, savingNewPad, setSavingNewPad, modalIsActive, currentPadSelected, hideModal, setAddNewPad } =
    useTabPadsContext();
  const { nameRule, onAccept } = useTabPadsActions();

  useEffect(() => {
    setAddNewPad(!pads.length);
  }, [pads, setAddNewPad]);

  const handleKeyPressNewPad = async (key, error) => {
    if (pads.length === 0 && currentName.length === 0) return;
    if (key !== 'Enter') return;

    setSavingNewPad(true);
    const isEmpty = currentName.length === 0;

    const errorCallback = (message) => {
      enqueueErrorMessage(message, enqueueSnackbar);
      setSavingNewPad(false);
    };

    if (error && !isEmpty) {
      errorCallback(error?.message);
      return;
    }

    const formValues = getValues();
    if (!formValues.takeoffPadName) {
      setSavingNewPad(false);
      return;
    }
    const hasError = nameRule.error;
    if (hasError && !isEmpty) {
      errorCallback(nameRule.message);
      return;
    }
    if (!isEmpty) {
      await onAddTakeOffPad({
        form: formValues,
        successCallback: () => onSelectPad(pads.length),
      });
    }

    setSavingNewPad(false);
    setAddNewPad(false);
    reset();
  };

  const mustShowPadsTypeSeparator = pads.length > sourcePadsQty && sourcePadsQty > 0;
  const showLoading = savingNewPad || loadingSourcePads || loadingTakeOffPads || padItemsCount === undefined;

  return (
    <Stack direction="row" alignItems="center" gap="8px">
      <Modal
        modalIsActive={modalIsActive}
        onAccept={onAccept}
        hideModal={hideModal}
        currentPadSelected={currentPadSelected}
      />
      <TabsComponent separator={mustShowPadsTypeSeparator} keyPress={handleKeyPressNewPad} control={control} />

      {showLoading ? (
        <LoadingIndicator />
      ) : (
        <ItemsCounter padItemsCount={padItemsCount} padItemsDisplayed={padItems?.length} />
      )}
    </Stack>
  );
};

export default React.memo(TabPads);

const Modal = ({ modalIsActive, onAccept, hideModal, currentPadSelected }) => (
  <TakeOffActionModal title="Confirm Delete" open={modalIsActive} onAccept={onAccept} onDecline={hideModal}>
    <Typography variant="body1" sx={{ display: 'flex', gap: '4px' }}>
      Are you sure you want to delete the
      <Typography variant="body1" fontWeight={700}>
        {currentPadSelected?.takeoffPadName}
      </Typography>
      takeoff pad?
    </Typography>
    {Boolean(currentPadSelected?.hasItemsMappedFromSourcePad) && (
      <Typography variant="body1">Items from a source data tab will be unmapped and returned.</Typography>
    )}
  </TakeOffActionModal>
);

const ItemsCounter = ({ padItemsCount, padItemsDisplayed = 0 }) => (
  <Stack direction="row" alignItems="center">
    <Typography variant="body2" sx={{ color: '#999' }} data-cy="pad-items-counter">{`${padItemsDisplayed} of ${
      padItemsCount === undefined ? '' : padItemsCount
    } Item${padItemsCount === 1 ? '' : 's'}`}</Typography>
  </Stack>
);

const LoadingIndicator = () => (
  <Box sx={{ height: 24, position: 'relative' }}>
    <CircularProgress size="20px" color="secondary" />
  </Box>
);
