import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';

import Divider from 'components/Divider';
import { Custom } from 'components/Icons';
import { Breadcrumbs } from 'components/NavigationComponents';

import NavBarContent from './NavBarContent/NavBarContent';

const breadcrumbs = [
  {
    label: 'Projects',
    link: '/test',
  },
  {
    label: 'Project Title',
  },
];

const MobileNavBar = () => {
  const [navBarOpened, setNavBarOpened] = useState(false);
  return (
    <>
      <IconButton
        onClick={() => setNavBarOpened(true)}
        aria-label="open drawer"
        edge="start"
        size="large"
      >
        <Custom.Menu />
      </IconButton>
      <StyledDrawer
        anchor="left"
        open={navBarOpened}
        onClose={() => setNavBarOpened(false)}
        onOpen={() => setNavBarOpened(true)}
      >
        <Box>
          <IconButton color="inherit" size="small" onClick={() => setNavBarOpened(false)}>
            <Custom.Close />
          </IconButton>
          <Breadcrumbs style={{ marginTop: '52px' }} breadcrumbs={breadcrumbs} darkMode />
          <Divider sx={{ marginTop: '24px', marginBottom: '16px' }} />
          <NavBarContent />
        </Box>
      </StyledDrawer>
    </>
  );
};

const StyledDrawer = (props) => (
  <Drawer
    sx={{
      '& .MuiPaper-root': {
        width: '344px',
        backgroundColor: '#424242',
        color: '#ffffff',
        py: 3,
        px: 2.5,
      },
    }}
    {...props}
  />
);

export default MobileNavBar;
