import React from 'react';

import { useForm } from 'react-hook-form';

import InputSelectField from 'components/FormComponents/InputSelectField';

const PresetsDropdown = ({ options = [], ...rest }) => {
  const { control } = useForm({
    mode: 'all',
  });

  return (
    <InputSelectField
      label="Filter preset"
      name="filterPreset"
      color="secondary"
      size="small"
      control={control}
      fullWidth
      formControlSx={{ width: '320px' }}
      options={options}
      {...rest}
    />
  );
};

export default PresetsDropdown;
