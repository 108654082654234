import React from 'react';

import { useMutation, gql } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { Modal, CancelButton, CreateButton } from 'components';
import { deleteUsers as DELETE_USERS } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

const EvolveMembersDeleteModal = ({ companyMemberIds, open, onClose }) => {
  const { handleResponse } = useGraphqlResponseHandler();
  const [deleteUsers, { loading }] = useMutation(gql(DELETE_USERS), {
    refetchQueries: ['Users'],
  });

  const submit = async () => {
    handleResponse(deleteUsers, {
      variables: {
        body: {
          userIds: companyMemberIds,
        },
      },
    });
    onClose();
  };

  return (
    <Modal
      disableModalOutsideClick
      disableCloseButton={loading}
      open={open}
      onClose={onClose}
      title="Confirm Delete"
      reducePadding
      maxWidth="xs"
      footer={
        <>
          {loading && <CircularProgress />}
          <CancelButton onClick={onClose} />
          <CreateButton color="error" buttonText="Delete" disabled={loading} onClick={submit} />
        </>
      }
    >
      <Typography variant="body1" mb="12px" maxWidth="300px">
        Are you sure you want to delete the selected members?
      </Typography>
    </Modal>
  );
};

export default EvolveMembersDeleteModal;
