import React from 'react';

import StatusBadge from 'components/StatusBadges/StatusBadge';
import { BADGE_MAPPING_STATUS } from 'constants/badgeMappingStatus';
import { getDateFromString, getDaysBetween } from 'helpers/dateFunctions';

export const CellComponentStatus = ({ value, row }) => {
  if (row === null) return '';

  const newStatus = BADGE_MAPPING_STATUS[value];
  const lastDate =
    value === 'Completed' && row?.lastStatusUpdate ? getDateFromString(row.lastStatusUpdate) : new Date();
  const needByDate = getDateFromString(row.needBy);
  const daysRemainingCount = needByDate && lastDate ? getDaysBetween(needByDate, lastDate) : 0;

  const variantClass = daysRemainingCount < 0 ? 'pastDaysRemaining' : newStatus?.variant || value;
  return <StatusBadge key={newStatus?.variant} label={newStatus?.label || value} variant={variantClass} />;
};
