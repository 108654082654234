import React, { FC } from 'react';

import { Custom } from 'components/Icons';
import { ICustomAttachmentType } from 'modules/Shop/WorkOrders/WorkOrdersList/types/attachmentType';

import AttachmentType from './AttachmentType';

const WorkRequestsAttachType: FC<ICustomAttachmentType> = (props: ICustomAttachmentType) => (
  <AttachmentType label="Work Requests" icon={<Custom.WorkRequests fill="#ffffff" />} {...props} />
);

export default WorkRequestsAttachType;
