import { useState, useEffect } from 'react';

import Container from '@mui/material/Container';
import { Auth } from 'aws-amplify';

import CenteredLoadSpinner from 'components/CenteredLoadSpinner';

import ErrorMessage from '../components/ErrorMessages';
import CheckEmailForPasswordReset from './CheckEmailForPasswordReset';
import ForgotPasswordForm from './ForgotPasswordForm';

const ForgotPassword = () => {
  const [activeEmail, setActiveEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => () => setActiveEmail(''), [setActiveEmail]);

  if (activeEmail) return <CheckEmailForPasswordReset email={activeEmail} />;

  const handleForgotPassword = async ({ email }: { email: string }) => {
    setLoading(true);

    try {
      const res = await Auth.forgotPassword(email);
      if (res) {
        setActiveEmail(email);
      }
    } catch ({ code }: any) {
      setError(code);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      {error && <ErrorMessage code={error} onClose={() => setError('')} />}
      <ForgotPasswordForm loading={loading} onSubmit={handleForgotPassword} />
      {loading && <CenteredLoadSpinner />}
    </Container>
  );
};

export default ForgotPassword;
