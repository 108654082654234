/* eslint-disable import/no-cycle */

import { useMemo } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';

import useDynamicWatch from 'hooks/useDynamicWatch';

import { useItemsIntegrationTableContext } from '../../../context/ItemsIntegrationTableContext';
import { getAllChildrenBelowLocation, getQtyFieldName } from '../../QtyField/helpers/qtyFieldHelpers';
import { ElementType, IUseGetLBSElements, QuantityFieldType, UseGetLBSElementsType } from './@types/fieldHookTypes';

const useGetLBSElements = ({
  isBomLineItem,
  locationItemsByLineItem,
  fieldId,
  lineItemId,
  currentNestLevel,
  quantityField,
}: IUseGetLBSElements): UseGetLBSElementsType => {
  const { control } = useFormContext();
  const { quantityFieldsByLineItem, autoFilledFields } = useItemsIntegrationTableContext();
  const childrenFields = useMemo(() => {
    if (!locationItemsByLineItem.length) return [];
    if (isBomLineItem)
      return quantityFieldsByLineItem[lineItemId].filter(
        (child: QuantityFieldType) => (child.nestLevel as number) > 0 && child.id !== fieldId,
      );
    return [];
  }, [locationItemsByLineItem.length, isBomLineItem, quantityFieldsByLineItem, lineItemId, fieldId]);
  const childrenToWatch = useMemo(() => {
    if (!childrenFields?.length) return [];
    if (isBomLineItem)
      return locationItemsByLineItem.filter((child) => (child.nestLevel as number) > 0 && child.id !== fieldId);
    return [];
  }, [childrenFields?.length, fieldId, isBomLineItem, locationItemsByLineItem]);

  const childrenFieldNames = useMemo(() => {
    if (!childrenToWatch?.length) return [];
    return [
      ...childrenToWatch.map((childField) =>
        getQtyFieldName({
          locationId: (childField.isWorkPhase ? childField.locationId : childField.id) as string,
          lineItemId,
          workPhaseId: childField.isWorkPhase ? childField.id : undefined,
        }),
      ),
    ];
  }, [childrenToWatch, lineItemId]);
  const lineItemFieldName = useMemo(() => getQtyFieldName({ lineItemId }), [lineItemId]);
  const lineItemWatched = useWatch({ control, name: lineItemFieldName });
  const lineItemValueWatched = useMemo(() => parseInt(lineItemWatched || 0, 10), [lineItemWatched]);

  const allChildrenBellowCurrentLocation = useMemo(() => {
    if (!locationItemsByLineItem.length) return [];
    if (!quantityField?.children?.length) return [];
    if (quantityField.children.some((child) => !child.nestLevel)) return [];
    return getAllChildrenBelowLocation({ element: quantityField as ElementType }).filter(
      (field) => field.id !== fieldId,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationItemsByLineItem, lineItemId, currentNestLevel, autoFilledFields, quantityField]);
  const allChildrenNameBelowCurrentLocation: string[] = useMemo(
    () =>
      allChildrenBellowCurrentLocation.map((child) =>
        getQtyFieldName({
          locationId: (child.isWorkPhase ? child.locationId : child.id) as string,
          lineItemId,
          workPhaseId: child.isWorkPhase ? child.id : undefined,
        }),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allChildrenBellowCurrentLocation, lineItemId],
  );
  const allChildrenAmounts = useDynamicWatch(allChildrenNameBelowCurrentLocation);
  const hasChildrenBelowAmount = useMemo(() => {
    if (!allChildrenAmounts?.length) return false;
    return allChildrenAmounts.some((value: any) => Boolean(value));
  }, [allChildrenAmounts]);
  const quantityFieldChildren = useMemo(
    () => [...(quantityField?.children || []), ...(quantityField?.workPhasesFields || [])],
    [quantityField],
  );
  return {
    childrenFields,
    childrenToWatch,
    childrenFieldNames,
    lineItemFieldName,
    lineItemValueWatched,
    allChildrenBellowCurrentLocation,
    allChildrenNameBelowCurrentLocation,
    allChildrenAmounts,
    hasChildrenBelowAmount,
    quantityFieldChildren,
  };
};

export default useGetLBSElements;
