import React, { useState, useRef, useEffect } from 'react';

import { Stack, CircularProgress, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';

import { shouldBlockNavigation } from 'apollo/reactiveVars';
import deepMerge from 'helpers/deepMeger';
import useWorkOrderDocumentAPI from 'hooks-api/useWorkOrderDocumentAPI';
import { useCallbackPrompt } from 'hooks/useCallbackPrompt';
import { useWorkRequestsContext } from 'modules/Field/WorkRequests/WorkRequestsContext';
import useUploadWorkOrderDocument from 'modules/Shop/WorkOrders/hooks/useUploadWorkOrderDocument';
import { useWorkOrdersContext } from 'modules/Shop/WorkOrders/WorkOrdersContext';

import WorkRequestUploadIncompleteModal from '../../../WorRequestsProperties/WorkRequestPropertiesAttachments/components/WorkRequestUploadIncompleteModal';
import useUploadWorkRequestDocument from '../../../WorRequestsProperties/WorkRequestPropertiesAttachments/hooks/useUploadWorkRequestDocument';
import { useWorkRequestPropertiesAttachmentsContext } from '../../../WorRequestsProperties/WorkRequestPropertiesAttachments/WorkRequestPropertiesAttachmentsContext';
import { AttachButtonWrap } from './AttachButtonWrap';
import { LinkButton } from './LinkButton';
import { LoadFile } from './LoadFile';

export const AttachmentButtons = ({ setReloadWorkOrderAttachments = () => {} }: any) => {
  const { palette } = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const inputRef = useRef<HTMLInputElement>(null);
  const [uploading, setUploading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const { workOrders, selectedWorkOrder, setSelectedWorkOrder } = useWorkOrdersContext();
  const { workRequestSelected, updateWRList, setUpdateWRList, workOrderSelected } = useWorkRequestsContext();
  const DOCUMENT_TYPE_COMMON = '540f5ec2-6646-44f5-834c-a87d7369a253';
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog, []);
  const { showUrlInput, addDocumentsToWorkRequest, workRequestDocuments } =
    useWorkRequestPropertiesAttachmentsContext();
  const {
    callbacks: { addDocumentsToWorkOrder },
  } = useWorkOrderDocumentAPI();

  const { uploadFileToS3, addDocument } = useUploadWorkRequestDocument({
    workRequestId: workRequestSelected?.workRequestId,
    documentTypeId: DOCUMENT_TYPE_COMMON,
  });

  const { uploadWorkOrderDocToS3, addWorkOrderDoc } = useUploadWorkOrderDocument({
    workOrderId: workOrderSelected?.workOrderId,
    documentTypeId: DOCUMENT_TYPE_COMMON,
  });

  const handleClick = () => {
    if (inputRef.current) inputRef.current.click();
  };
  const handleChange = LoadFile({
    setUploading,
    setShowDialog,
    enqueueSnackbar,
    uploadFileToS3: workOrderSelected ? uploadWorkOrderDocToS3 : uploadFileToS3,
    addDocument: workOrderSelected ? addWorkOrderDoc : addDocument,
    setUpdateWRList,
    updateWRList,
    addDocumentsToWorkRequestOrWorkOrder: workOrderSelected ? addDocumentsToWorkOrder : addDocumentsToWorkRequest,
    workRequestSelected,
    workOrderSelected,
    cancelNavigation,
    shouldBlockNavigation,
    setReloadWorkOrderAttachments,
  });

  useEffect(() => {
    if (selectedWorkOrder) {
      const found = workOrders.find((WorkOrder: any) => selectedWorkOrder.workOrderId === WorkOrder.workOrderId);
      setSelectedWorkOrder(deepMerge(selectedWorkOrder, found));
    }

    // eslint-disable-next-line
  }, [workOrders]);

  return (
    <>
      <WorkRequestUploadIncompleteModal openStatus={showPrompt} submit={confirmNavigation} onClose={cancelNavigation} />
      {uploading ? (
        <CircularProgress sx={{ color: palette.secondary.mainDarkBackground }} size={32} />
      ) : (
        <>
          {workRequestSelected.workRequestStatusName !== 'Completed' && (
            <Stack direction="row" gap="16px" margin={workRequestDocuments.length ? '10px 0 0 0' : '0 0 0 0'}>
              <AttachButtonWrap showUrlInput={showUrlInput} handleClick={handleClick}>
                Attach Files
                <input multiple hidden ref={inputRef} type="file" onChange={handleChange} />
              </AttachButtonWrap>
              <LinkButton />
            </Stack>
          )}
        </>
      )}
    </>
  );
};
