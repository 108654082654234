import React from 'react';

import Box from '@mui/material/Box';

import CardItem from 'components/CardItem';
import Header from 'components/Header';

import { ROW_TYPES } from '../WorkCellsAndMachines/constants/constants';
import { useDataContext } from '../WorkCellsAndMachines/contexts/DataContext';
import MachineEditForm from './MachineEditForm/MachineEditForm';
import WorkCellEditForm from './WorkCellEditForm';

const WorkCellsAndMachinesProperties = () => {
  const { activeRow } = useDataContext();

  const title =
    activeRow?.type === ROW_TYPES.MACHINE ? 'Machine Properties' : 'Work Cell Properties';

  return (
    <Box>
      <Header title={title} />
      <CardItem>
        <>
          {activeRow?.type === ROW_TYPES.WORK_CELL && <WorkCellEditForm workCell={activeRow} />}
          {activeRow?.type === ROW_TYPES.MACHINE && <MachineEditForm machine={activeRow} />}
        </>
      </CardItem>
    </Box>
  );
};

export default WorkCellsAndMachinesProperties;
