/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React from 'react';

import { useMutation, gql } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import { useUser } from 'app/UserContext';
import { Modal, CancelButton, CreateButton } from 'components';
import { addUser as ADD_USER } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

import EvolveModalForm from './EvolveModalForm';

const InviteToEvolveModal = ({ open, onClose }) => {
  const { handleResponse } = useGraphqlResponseHandler(onClose);
  const { user } = useUser();
  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm({
    mode: 'all',
  });

  const [addEvolveMember, { loading }] = useMutation(gql(ADD_USER), {
    refetchQueries: ['Users'],
  });

  const submit = ({ email, role }) => {
    handleResponse(addEvolveMember, {
      variables: {
        body: {
          companyId: user.companyId,
          userEmail: email,
          userStatusCode: 'UNCONFIRMED',
          userName: uuidv4(),
          userRoles: [{ roleId: role.id }],
          isAdmin: true,
        },
      },
    });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Invite User"
      fullWidth
      maxWidth="xs"
      footer={
        <>
          {loading && <CircularProgress />}
          <CancelButton color="primary" onClick={onClose} />
          <CreateButton
            color="primary"
            onClick={handleSubmit(submit)}
            disabled={!isValid || loading}
            buttonText="invite"
          />
        </>
      }
    >
      <EvolveModalForm control={control} />
    </Modal>
  );
};

export default InviteToEvolveModal;
