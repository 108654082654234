import React from 'react';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { Breadcrumbs } from 'components';
import Header from 'components/Header';
import { useProject } from 'modules/Field/LocationsAndWorkPhases/contexts/ProjectContext';

const TypesListHeader = () => {
  const { selectedItem } = useFacilitiesProjects();
  const { selectedProject } = useProject();
  const isFacility = Boolean(selectedItem && selectedItem?.type === 'FACILITY');
  return (
    <Header
      title={
        isFacility ? (
          <Breadcrumbs
            breadcrumbs={[
              { label: 'Custom Assemblies', url: '/shop/custom-assembly' },
              { label: selectedProject?.projectName },
            ].filter(({ label }) => Boolean(label))}
          />
        ) : (
          'Custom Assemblies'
        )
      }
    />
  );
};

export default TypesListHeader;
