const stylesBuilder = (errorState, focus, theme) => {
  const styleColor = focus ? theme.palette.secondary.main : theme.palette.primary.light;
  const texfieldLabelColor = focus
    ? theme.palette.secondary.main
    : theme.palette.action.lightSurface.active;
  return errorState
    ? {
        width: '100%',
        '.MuiInputLabel-root': {
          color: `${theme.palette.error.main} !important`,
        },
        '.MuiInputLabel-root.Mui-focused': {
          color: `${theme.palette.error.main} !important`,
        },
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: `${theme.palette.error.main} !important`,
        },
      }
    : {
        width: '100%',
        '&.Mui-focused': {
          color: styleColor,
        },
        '.MuiTypography-root': {
          color: theme.palette.surface.lightSurface.primary,
          fontSize: '16px',
        },
        '.MuiInputLabel-root': {
          color: `${texfieldLabelColor} !important`,
        },
        '.MuiInputLabel-root.Mui-focused': {
          color: `${styleColor} !important`,
        },
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: `${styleColor} !important`,
        },
      };
};

export default stylesBuilder;
