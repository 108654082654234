import React from 'react';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { useUser } from 'app/UserContext';
import { ThemedCheckBox } from 'components';
import Search from 'components/TableComponents/Search';
import { isNotNil } from 'helpers/isNotNil';

import { useAdminContext } from './AdminContext';

const AdminHeaderBar = ({ canAddAdmin, title }) => {
  const { user } = useUser();
  const { searchHandler, setShowDeletedUsers, openInviteModal } = useAdminContext();

  return (
    <Grid container justifyContent="space-between" wrap="nowrap">
      <Grid container item alignItems="center">
        {isNotNil(title) && <Typography variant="h6">{title}</Typography>}
      </Grid>
      <Grid container item alignItems="center" wrap="nowrap" justifyContent="flex-end">
        <ThemedCheckBox onChange={(e) => setShowDeletedUsers(e.target.checked)} />
        <Typography component="p" noWrap>
          Show Deleted Users
        </Typography>
        <Search handleSearch={searchHandler} placeholder="Search…" />
        {canAddAdmin && <>{user.isAdmin && <InviteButton onClick={openInviteModal}>+ Add Admin</InviteButton>}</>}
      </Grid>
    </Grid>
  );
};

export default AdminHeaderBar;

const InviteButton = ({ onClick, children }) => (
  <Button
    onClick={onClick}
    variant="contained"
    color="secondary"
    sx={{
      minWidth: 150,
      maxHeight: 32,
      marginLeft: '8px',
    }}
  >
    {children}
  </Button>
);
