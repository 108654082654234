import React from 'react';

import { InputForesiteField } from '@evolvemep/foresite-components-ui-react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import parse from 'html-react-parser';

import { TabBar } from 'components';

const AddSourceForm = ({ control, body }) => {
  const [activeTab, setActiveTab] = React.useState(0);

  const handleChange = (_, newValue) => setActiveTab(newValue);

  return (
    <AddSourceFormWrapper>
      <TabOptions value={activeTab} handleChange={handleChange}>
        <Tab label="Source" sx={{ width: '50%' }} />
        <Tab label="Preview" sx={{ width: '50%' }} />
      </TabOptions>

      <TabPanel value={activeTab} index={0}>
        <Box>
          <InputForesiteField
            name="body"
            label="Add Source"
            control={control}
            multiline
            minRows={8}
            maxRows={8}
            shouldUnregister={false}
            sx={{
              height: '226px',
              width: '650px',
            }}
            InputLabelProps={{ margin: 'dense' }}
          />
        </Box>
      </TabPanel>

      <TabPanel value={activeTab} index={1}>
        <HTMLPreview>{parse(body)}</HTMLPreview>
      </TabPanel>
    </AddSourceFormWrapper>
  );
};

export default AddSourceForm;

const AddSourceFormWrapper = ({ children }) => <Box sx={{ width: '100%' }}>{children}</Box>;

const TabOptions = ({ value, handleChange, children }) => (
  <Box sx={{ borderBottom: 1, borderColor: 'divider', margin: '16px 0 24px 0' }}>
    <TabBar value={value} onChange={handleChange}>
      {children}
    </TabBar>
  </Box>
);

const TabPanel = ({ value, index, children }) => (
  <Box role="tabpanel" hidden={value !== index} width="100%">
    {children}
  </Box>
);

const HTMLPreview = ({ children }) => (
  <Box
    sx={{
      borderRadius: '4px',
      background: 'white',
      height: '240px',
      minWidth: '650px',
      overflowY: 'scroll',
      padding: 0,
    }}
  >
    {children}
  </Box>
);
