const validateInput = (value, remaining) => {
  if (typeof remaining !== 'number') {
    throw new Error(
      'The retrieved remaining quantity for an item has a type different from a number, contact your sysAdmin for more information',
    );
  }

  let code = 0;

  if (value === '') {
    code = 0;
    return code;
  }
  if (typeof value !== 'number') {
    code = 2;
    return code;
  }
  const parsedValue = Number(value);
  const parsedRem = Number(remaining);
  if (parsedValue > parsedRem) code = 1;
  if (Number.isNaN(parsedValue)) code = 2;
  if (!Number.isInteger(parsedValue)) code = 2;
  if (parsedValue < 0) code = 2;
  return code;
};

export default validateInput;
