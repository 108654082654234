import React, { FC } from 'react';

import { ButtonProps } from '@mui/material';

import { CancelButton as Cancel } from 'components';

const CancelButton: FC<ButtonProps> = ({ onClick = () => {}, disabled }: ButtonProps) => (
  <Cancel
    onClick={(e) => onClick(e)}
    disabled={disabled}
    sx={{ color: 'secondary.main', letterSpacing: '0.4px', fontWeight: 600 }}
  />
);

export default CancelButton;
