const updateWorkPhases = (id, checked, list, setter) => {
  if (checked) {
    const updatedList = list.map((item) =>
      item.scopePackageId === id
        ? {
            ...item,
            workPhases: item.workPhases.map((element) => ({ ...element, isGroupSelected: true })),
          }
        : item,
    );
    setter(updatedList);
  } else {
    const updatedList = list.map((item) =>
      item.scopePackageId === id
        ? {
            ...item,
            workPhases: item.workPhases.map((element) => ({ ...element, isGroupSelected: false })),
          }
        : item,
    );
    setter(updatedList);
  }
};

export default updateWorkPhases;
