import { Custom } from 'components/Icons';
import { EModuleType } from 'types/module';

export default (module) =>
  ({
    [EModuleType.ADMIN]: Custom.AdminModule,
    [EModuleType.DATA_ANALYTICS]: Custom.DataAnalyticsModule,
    [EModuleType.ELECTRICAL]: Custom.ElectricalModule,
    [EModuleType.FIELD]: Custom.FieldModule,
    [EModuleType.MATERIALS]: Custom.MaterialsModule,
    [EModuleType.MECHANICAL]: Custom.MechanicalModule,
    [EModuleType.SHOP]: Custom.ShopModule,
    [EModuleType.MANAGEMENT]: Custom.ManagementModule,
    [EModuleType.DESIGN]: Custom.DesignModule,
    [EModuleType.SYNC]: Custom.Module360,
  }[module]);
