import { clone, findNode, recursiveUpdateNode } from 'helpers/arrayFunctions';

export default function useWorkPhasesByLocation(locations, setLocations) {
  const removeWorkPhasesFromLocationsTable = (response) => {
    const { deletedLocationPhases } = response || {};

    if (!deletedLocationPhases || !deletedLocationPhases.length) return;

    const locationPhasesByLocationId = deletedLocationPhases.reduce((acc, cur) => {
      if (acc[cur.locationId]) acc[cur.locationId].push(cur.deletedLocationPhaseId);
      else acc[cur.locationId] = [cur.deletedLocationPhaseId];
      return acc;
    }, {});

    Object.keys(locationPhasesByLocationId).forEach((locationId) => {
      const location = clone(findNode(locationId, locations, 'locationId')) || {};
      location.locationPhases = location?.locationPhases?.filter(
        ({ locationPhaseId }) =>
          !locationPhasesByLocationId[locationId].some((id) => id === locationPhaseId),
      );
      setLocations((prev) => recursiveUpdateNode(location, prev, 'locationId'));
    });
  };

  const updateWorkPhasesNamesOnLocationsTable = (response) => {
    const { updateWorkPhase } = response || {};
    if (!updateWorkPhase) return;

    const { updatedWorkPhaseLocationPhases } = updateWorkPhase || {};
    if (!updatedWorkPhaseLocationPhases || !updatedWorkPhaseLocationPhases.length) return;

    const clonedLocations = clone(locations);
    updatedWorkPhaseLocationPhases.forEach(({ locationId }) => {
      const location = findNode(locationId, clonedLocations, 'locationId');
      if (!location) return;
      location.locationPhases = location.locationPhases.map((locationPhase) => {
        if (locationPhase.workPhaseId === updateWorkPhase.workPhaseId)
          return { ...locationPhase, ...updateWorkPhase };
        return locationPhase;
      });
    });
    setLocations(clonedLocations);
  };

  return {
    removeWorkPhasesFromLocationsTable,
    updateWorkPhasesNamesOnLocationsTable,
  };
}
