import { useCallback, useEffect } from 'react';

import { useUser } from 'app/UserContext';
import { debounce } from 'helpers/common';

import {
  addSearchToLocalStorage,
  getLocalStorageKey,
  getRecentSearchesFromLocalStorage,
} from '../CatalogSearchHelper';

const useHandleCatalogSearch = ({
  searchValue,
  onSearchParts,
  setSearchValue,
  onSearchKeyChange,
  setOptions,
  searchParts,
  searchPartCategories,
}) => {
  const { user } = useUser();

  const saveSearchHistory = useCallback(
    (value) => {
      const localStorageKey = getLocalStorageKey(user.userId);
      addSearchToLocalStorage(localStorageKey, value, {
        label: value,
        type: 'history',
      });
    },
    [user.userId],
  );

  const handleKeyDown = (event) => {
    const value = searchValue.trim();

    if (event.key === 'Enter' && value.length >= 3) {
      onSearchParts(value);
      saveSearchHistory(value);
    }
  };

  const fetchParts = debounce(searchParts);
  const fetchPartCategories = debounce(searchPartCategories);

  const loadSearchHistory = useCallback(() => {
    const localStorageKey = getLocalStorageKey(user.userId);
    const searchHistory = getRecentSearchesFromLocalStorage(localStorageKey);
    setOptions(searchHistory);
  }, [setOptions, user.userId]);

  useEffect(() => {
    loadSearchHistory();
  }, [loadSearchHistory]);

  const handleInputChange = (_event, value, reason) => {
    setSearchValue(value);
    onSearchKeyChange(value);

    if (value.length < 3) {
      loadSearchHistory();
      return;
    }

    if (reason !== 'reset') {
      fetchParts(value);
      fetchPartCategories(value);
    }
  };

  return {
    handleKeyDown,
    handleInputChange,
  };
};

export default useHandleCatalogSearch;
