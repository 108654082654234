import useUpdateWorkOrderItem from 'hooks-api/mutations/useUpdateWorkOrderItem';
import useLazyPaginatedWorkOrderItems from 'hooks-api/queries/workOrderItems/useLazyPaginatedWorkOrderItems';
import useWorkRequestItemTypesIds from 'hooks-api/useWorkRequestItemTypeIds';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

import { getCachedItems } from '../helpers/cacheHelpers';

export const useUpdateWorkOrderItemDataLayer = (workOrder) => {
  const { writeInItemTypeId } = useWorkRequestItemTypesIds();
  const { handleResponse } = useGraphqlResponseHandler();

  const { workOrderItemsQuery } = useLazyPaginatedWorkOrderItems(workOrder);

  const [updateWorkOrderItemMutation] = useUpdateWorkOrderItem({});

  const handleUpdateWorkOrderItem = ({
    unitOfMeasureId,
    workOrderItemName,
    quantity,
    workOrderItemId,
    itemType = null,
  }) => {
    const body = {
      workOrderItemTypeId: itemType || writeInItemTypeId,
      unitOfMeasureId: unitOfMeasureId?.value,
      workOrderId: workOrder?.workOrderId,
      workOrderItemName,
      quantity,
      workOrderItemIdentification: '',
    };

    handleResponse(
      updateWorkOrderItemMutation,
      {
        variables: { params: { workOrderItemId }, body },
        update: (cache, result) => {
          const cachedItems = getCachedItems(cache, result, workOrderItemsQuery);
          const updatedItem = result?.data?.updateWorkOrderItem;
          if (!(cachedItems && updatedItem)) return;

          const newArrayOfWorkOrderItems = cachedItems.map((item) => {
            if (item.workOrderItemId === updatedItem.workOrderItemId) return { ...item, ...updatedItem };
            return item;
          });

          cache.writeQuery({ ...workOrderItemsQuery, data: { workOrderItem: newArrayOfWorkOrderItems } });
        },
      },
      { successMessage: 'Item successfully updated', errorMessage: 'Could not update item' },
    );
  };

  return {
    handleUpdateWorkOrderItem,
  };
};
