/* eslint-disable import/no-cycle */
import React, { FC, memo, useCallback } from 'react';

import { Box } from '@mui/material';

import { Custom } from 'components/Icons';

import { useFieldContext } from '../../QuantityField/context/FieldContext';
import useFieldHandlers from '../../QuantityField/hooks/useFieldHandlers';
import { IIconButton } from './@types/closeButton';

const IconButton: FC<IIconButton> = ({ showArrows, setDeleteBtnClicked, handleDeleteBtnClicked }: IIconButton) => {
  const getContainerStyles = useCallback(
    () => ({
      position: 'absolute',
      right: showArrows ? 20 : 4,
      opacity: 0.54,
      padding: 0,
      cursor: 'pointer',
      margin: 0,
      height: '18px',
    }),
    [showArrows],
  );
  return (
    <Box
      onMouseOver={() => showArrows && setDeleteBtnClicked(true)}
      onMouseLeave={() => showArrows && setDeleteBtnClicked(false)}
      onClick={() => handleDeleteBtnClicked()}
      sx={getContainerStyles()}
    >
      <Custom.Close width="18px" />
    </Box>
  );
};

const IconButtonMemo = memo(IconButton);

const CloseButton: FC = () => {
  const { isReadOnly, showArrows, fieldWatched, setDeleteBtnClicked, showDeleteButton } = useFieldContext();
  const { handleDeleteBtnClicked } = useFieldHandlers();
  if (showDeleteButton && !isReadOnly && Boolean(fieldWatched))
    return (
      <IconButtonMemo
        showArrows={showArrows}
        setDeleteBtnClicked={setDeleteBtnClicked}
        handleDeleteBtnClicked={handleDeleteBtnClicked}
      />
    );
  return null;
};

export default CloseButton;
