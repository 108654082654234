import React, { ReactNode, memo } from 'react';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useSnackbar } from 'notistack';

import { CenteredLoadSpinner } from 'components';
import { Custom } from 'components/Icons';
import { useEntitlementGet } from 'hooks-api/useEntitlementGet';
import { useUnassignEntitlement } from 'hooks-api/useUnassignEntitlement';
import type { EntitlementId } from 'types/types-api';

const CompanyMembersCollapsedContent = memo(
  ({
    open,
    userId,
    selected,
    ...rest
  }: ReturnType<typeof useEntitlementGet> & { open: boolean; userId: string; selected: boolean }) => (
    <CollapsedRow selected={selected}>
      <TableCell sx={{ p: 0 }} colSpan={10}>
        <Collapse in={open} timeout="auto" unmountOnExit sx={{ width: 1 }}>
          <Box width={1}>
            <Table size="small" aria-label="entitlements">
              <TableHead>
                <TableRow>
                  <CellSpacer reducePadding />
                  <TableCell sx={{ fontWeight: 600 }}>Licenses</TableCell>
                </TableRow>
              </TableHead>
              <TableContent userId={userId} {...rest} />
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </CollapsedRow>
  ),
);

export default CompanyMembersCollapsedContent;

const CollapsedRow = ({ selected, children }: { selected: boolean; children: ReactNode }) => (
  <TableRow selected={selected}>{children}</TableRow>
);

const LicenseRow = ({ children }: { children: ReactNode }) => (
  <TableRow
    sx={{
      '& > *': {
        borderBottom: 'unset',
      },
      '& .MuiSvgIcon-root': {
        opacity: 0,
      },
      '&:hover': {
        '& .MuiSvgIcon-root': {
          opacity: 0.5,
        },
      },
    }}
  >
    {children}
  </TableRow>
);

const LicenseCell = ({ onClick, children }: { onClick: () => void; children: ReactNode }) => (
  <TableCell>
    <Stack direction="row" alignItems="center">
      <Custom.Delete
        onClick={onClick}
        sx={{
          m: '3px 10px 0 0',
          cursor: 'pointer',
        }}
      />
      {children}
    </Stack>
  </TableCell>
);

const LicensesLoadSpinner = () => (
  <TableRow>
    <CellSpacer reducePadding />
    <TableCell>
      <CenteredLoadSpinner width="50px" sx={{ mb: 1 }} />
    </TableCell>
  </TableRow>
);

const CellSpacer = ({ reducePadding = false }: { reducePadding?: boolean }) => (
  <>
    <TableCell padding={reducePadding ? 'checkbox' : 'normal'} />
    <TableCell padding={reducePadding ? 'checkbox' : 'normal'} />
  </>
);

const TableContent = ({
  userId,
  data,
  loading,
  entitlementGetList,
}: ReturnType<typeof useEntitlementGet> & {
  userId: string;
}) => {
  const { doUnassignEntitlement, loading: deleting } = useUnassignEntitlement();
  const { enqueueSnackbar } = useSnackbar();

  const licenses = data?.data ?? [];

  const handleDelete = async (entitlementId: EntitlementId) => {
    doUnassignEntitlement({ entitlementId })
      .then(() => {
        enqueueSnackbar('Successfully removed!', { variant: 'success' });
      })
      .catch((errors) => {
        enqueueSnackbar(errors, { variant: 'error' });
      })
      .finally(() => entitlementGetList({ userId }));
  };

  if (loading || deleting) return <LicensesLoadSpinner />;

  return (
    <TableBody>
      {licenses.length ? (
        licenses.map((license) => (
          <LicenseRow key={license.productPool.productPoolId}>
            <CellSpacer />
            <LicenseCell onClick={() => handleDelete(license.entitlementId)}>{license.productPool.label}</LicenseCell>
          </LicenseRow>
        ))
      ) : (
        <TableRow hover>
          <CellSpacer />
          <TableCell component="th" scope="row">
            No Licenses Found
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};
