import React from 'react';

import { useLocationsAndWorkPhases } from '../../../../../contexts/LocationsAndWorkPhaseContext';
import { useLocationTypeAndFormat } from '../../../../../contexts/LocationTypeAndFormatContext';
import { useChips } from '../../../CreateLocationsModalContext';
import { buildLocationTypeOptions } from '../../../helpers';
import DropDown from './Dropdown';

const LocationTypeList = ({ sx, ...props }) => {
  const {
    locations: { activeRow },
  } = useLocationsAndWorkPhases();
  const { locationTypes } = useLocationTypeAndFormat();
  const { parentActiveChip, activeChip } = useChips();

  const parent = parentActiveChip || activeRow;

  return (
    <DropDown
      name="locationTypeName"
      label="Location Type"
      options={buildLocationTypeOptions(locationTypes, parent, activeChip)}
      sx={{ width: '73%', ...sx }}
      {...props}
    />
  );
};
export default LocationTypeList;
