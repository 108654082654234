import { usePopover } from '../../PopoverProvider';
import checkType from './checkType';

const getName = (item) => {
  const LOCATION_TYPES = ['Floor', 'Zone'];
  const type = checkType(item);
  if (LOCATION_TYPES.includes(type)) return item.locationName;
  if (type === 'Location System') return item.locationIdentifier;
  if (type === 'Work Phase') return item.workPhaseName;
  if (type === 'Scope Package') return item.scopePackageName;
  return '';
};

const useGetChipName = () => {
  const { locations } = usePopover();
  const names = [];

  const getChipName = (item, clean) => {
    if (clean) names.splice(0, names.length);

    const type = checkType(item);

    if (type === 'Work Phase') {
      const workPhaseList = ['/', ' ', getName(item)];
      names.unshift(...workPhaseList);
    }
    if (item.parentLocationId) {
      const locationList = [',', ' ', getName(item)];
      names.unshift(...locationList);
    }
    if (type !== 'Work Phase' && !item.parentLocationId) names.unshift(getName(item));
    const id = type === 'Work Phase' ? item.locationId : item.parentLocationId;

    if (id) {
      const newItem = locations.find((e) => e.locationId === id);
      getChipName(newItem);
    }
    const finalName = names.join('');
    return finalName;
  };

  return { getChipName };
};

export default useGetChipName;
