import React, { useRef } from 'react';

import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import useOnScreen from 'hooks/useOnScreen';

import { usePartCategorySearch } from '../PartCategory/PartCategorySearchContext';

const AddParts = ({ handleAddParts, addAllButtonEnabled, buttonSx }) => {
  const { partsCount: cloudSearchPartsCount, searchKey } = usePartCategorySearch();

  const ref = useRef();
  const isIntersecting = useOnScreen(ref);

  return (
    <>
      <Box ref={ref} />
      <Stack
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        style={{
          width: '100%',
          maxWidth: '752px',
          position: 'sticky',
          top: 0,
          transition: 'background-color 0.5s ease',
          backgroundColor: isIntersecting ? 'transparent' : 'rgba(58, 58, 58, 0.9)',
        }}
      >
        <Typography variant="body2" sx={{ color: 'white', display: 'flex' }}>
          {cloudSearchPartsCount} results for
          <Typography variant="body2" fontWeight="700" sx={{ ml: 0.5 }}>
            {searchKey}
          </Typography>
        </Typography>
        <Button
          variant="text"
          disabled={!addAllButtonEnabled}
          color="secondary"
          sx={buttonSx}
          onClick={handleAddParts}
          data-cy="addAllPartsBtn"
        >
          ADD ALL
        </Button>
      </Stack>
    </>
  );
};

export default AddParts;
