import React from 'react';

import { Stack } from '@mui/material';

import { AttachmentButtons } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestListFlyoutMenu/WorkRequestAttachments/AttachmentButtons';
import { AttachmentList as WorkRequestAttachmentList } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestListFlyoutMenu/WorkRequestAttachments/AttachmentList';
import WorkRequestAttachUrlInput from 'modules/Field/WorkRequests/WorRequestsProperties/WorkRequestPropertiesAttachments/components/WorkRequestAttachUrlInput';
import { useWorkRequestPropertiesAttachmentsContext } from 'modules/Field/WorkRequests/WorRequestsProperties/WorkRequestPropertiesAttachments/WorkRequestPropertiesAttachmentsContext';

import { useWorkOrdersContext } from './WorkOrdersContext';
import TaskAttachmentList from './WorkOrdersList/Components/TaskAttachmentList';
import WorkOrderAttachmentList from './WorkOrdersList/Components/WorkOrderAttachmentList';

export const WorkOrderAttachments = () => {
  const { showUrlInput } = useWorkRequestPropertiesAttachmentsContext();
  const { setReloadWorkOrderAttachments } = useWorkOrdersContext();
  return (
    <Stack direction="column">
      <TaskAttachmentList />
      <WorkOrderAttachmentList />
      {showUrlInput && <WorkRequestAttachUrlInput setReloadWorkOrderAttachments={setReloadWorkOrderAttachments} />}
      <AttachmentButtons setReloadWorkOrderAttachments={setReloadWorkOrderAttachments} />
      <WorkRequestAttachmentList />
    </Stack>
  );
};
