import React, { useState, useEffect } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';

import { useUser } from 'app/UserContext';
import usePartCategoriesCloudSearchAPI from 'hooks-api/usePartCategoriesCloudSearchAPI';
import usePartsCloudSearchAPI from 'hooks-api/usePartsCloudSearchAPI';
import { darkSurfaceScrollBarStyle } from 'styles/theme/scrollBarStyle';

import { usePartCategorySearch } from '../PartCategory/PartCategorySearchContext';
import { filterSearchOptions, optionMapFn } from './CatalogSearchHelper';
import CatalogSearchInput from './CatalogSearchInput/CatalogSearchInput';
import CatalogSearchOptions from './CatalogSearchOptions/CatalogSearchOptions';
import { StyledDropdownPaper } from './CatalogSearchStyledComponent';
import useHandleCatalogSearch from './hooks/useHandleCatalogSearch';
import useOnSelectPart from './hooks/useOnSelectPart';

const PopperComponent = (props) => <Popper {...props} placement="bottom-start" />;

const CatalogSearch = ({ catalogId }) => {
  const { user } = useUser();
  const [isOpened, setIsOpened] = useState(false);
  const [options, setOptions] = useState([]);

  const {
    onSearchKeyChange,
    reset: resetSearch,
    onSearchParts,
    onSelectPartFromSearch,
    searchValue,
    setSearchValue,
  } = usePartCategorySearch();

  useEffect(() => {
    setSearchValue('');
    resetSearch();
  }, [catalogId, setSearchValue, resetSearch]);

  const {
    data: partsResponse,
    loading: loadingParts,
    searchParts,
  } = usePartsCloudSearchAPI(catalogId, 0);
  const {
    data: partCategoriesResponse,
    loading: loadingPartCategories,
    searchPartCategories,
  } = usePartCategoriesCloudSearchAPI(catalogId);

  const { handleKeyDown, handleInputChange } = useHandleCatalogSearch({
    searchValue,
    onSearchParts,
    setSearchValue,
    onSearchKeyChange,
    setOptions,
    searchParts,
    searchPartCategories,
  });

  const { onSelectPart, onSelectPartCategory } = useOnSelectPart({
    setSearchValue,
    setOptions,
    resetSearch,
    onSelectPartFromSearch,
  });

  useEffect(() => {
    const searchOptions = [...partCategoriesResponse, ...partsResponse].map(optionMapFn);
    setOptions(searchOptions);
  }, [partsResponse, partCategoriesResponse]);

  return (
    <Autocomplete
      data-testid="catalog-search-autocomplete"
      freeSolo
      openOnFocus
      inputValue={searchValue}
      loading={loadingParts || loadingPartCategories}
      options={options}
      PaperComponent={StyledDropdownPaper}
      ListboxProps={{
        sx: {
          ...darkSurfaceScrollBarStyle,
          maxHeight: '386px',
        },
      }}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      open={isOpened}
      onOpen={() => setIsOpened(true)}
      onClose={() => setIsOpened(false)}
      renderInput={CatalogSearchInput}
      renderOption={(props, option) => (
        <CatalogSearchOptions
          option={option}
          setIsOpened={setIsOpened}
          searchValue={searchValue}
          onSelectPartCategory={onSelectPartCategory}
          onSelectPart={onSelectPart}
          sx={{ '&:hover': { backgroundColor: 'surface.darkSurface.primaryOpacity10' } }}
          {...props}
        />
      )}
      forcePopupIcon={false}
      filterOptions={(options) => filterSearchOptions(options, user.userId, searchValue)}
      PopperComponent={PopperComponent}
      loadingText={<LoadingText />}
    />
  );
};

const LoadingText = () => (
  <Typography variant="body2" sx={{ color: 'myWhite.main' }}>
    Loading...
  </Typography>
);

export default CatalogSearch;
