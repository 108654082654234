import React, { createContext, useContext, useState } from 'react';

export const AccordionContext = createContext();

export const AccordionProvider = ({ children }) => {
  const [opened, setOpened] = useState(true);

  return (
    <AccordionContext.Provider value={{ opened, setOpened }}>{children}</AccordionContext.Provider>
  );
};

export const useAccordionContext = () => {
  const context = useContext(AccordionContext);
  if (context === undefined) {
    throw new Error('useAccordionContext must be used within a AccordionProvider');
  }
  return context;
};
