import * as React from 'react';

type Order = {
  field: string;
  sort: 'asc' | 'desc';
};

type CustomKeys = {
  [key: string]: string;
};

export const useOrderBy = (defaultOrder: Order, customKeys: CustomKeys = {}) => {
  const [orderBy, setOrderBy] = React.useState<Order>(defaultOrder);

  const handleSortOrder = (clickedOrder: Order[]) => {
    const customFinalKey = customKeys[clickedOrder[0].field] || clickedOrder[0].field;

    setOrderBy({ ...clickedOrder[0], field: customFinalKey });
  };

  return {
    orderBy: `${orderBy.field}:${orderBy.sort}`,
    handleSortOrder,
  };
};
