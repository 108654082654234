import React from 'react';

import { InputForesiteField } from '@evolvemep/foresite-components-ui-react';

import AssignAdminControlledInput from 'components/FormComponents/AssignAdminControlledInput';

const ProjectCreateForm = ({ control, disabled }) => (
  <form autoComplete="off">
    <InputForesiteField
      name="projectName"
      defaultValue=""
      label="Project Title"
      size="small"
      disabled={disabled}
      control={control}
      rules={{ required: true }}
      sx={{ marginTop: 0, marginBottom: '24px', p: { margin: 0 } }}
      data-cy="projectName"
      data-testid="project-title-input"
      maxLength={30}
    />
    <AssignAdminControlledInput control={control} sx={{ margin: 0 }} disabled={disabled} />
  </form>
);

export default ProjectCreateForm;
