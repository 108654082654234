import React from 'react';

import DeleteModal from 'components/DeleteModal';

const DrawingFolderDeleteModal = ({ open, setOpen, onSubmit, folder, loading }) => (
  <>
    <DeleteModal
      open={open}
      onClose={() => setOpen(false)}
      onSubmit={onSubmit}
      itemName={folder?.documentName}
      confirmText="Deleting this folder will also delete all of its drawings and subfolders. Are you sure you want to delete"
      loading={loading}
      paperSx={{ maxWidth: 505 }}
      deleteButtonText="Confirm"
    />
  </>
);

export default DrawingFolderDeleteModal;
