import React, { useCallback } from 'react';

import { Waypoint } from 'react-waypoint';

import { usePartCategory } from '../PartCategory/PartCategoryContext';
import { usePartCategorySearch } from '../PartCategory/PartCategorySearchContext';

const CatalogPopupInfiniteControl = () => {
  const { parts, onFetchMoreParts } = usePartCategory();
  const {
    parts: cloudSearchParts,
    onFetchMoreParts: onFetchMoreCloudSearchParts,
    isSearching,
  } = usePartCategorySearch();

  const fetchMoreParts = useCallback(() => {
    if (!parts?.length) return;

    onFetchMoreParts(parts.length);
  }, [onFetchMoreParts, parts]);

  const fetchMoreCloudSearchParts = useCallback(() => {
    if (!cloudSearchParts.length) return;

    onFetchMoreCloudSearchParts(cloudSearchParts.length);
  }, [cloudSearchParts, onFetchMoreCloudSearchParts]);

  const fetchMore = useCallback(() => {
    if (isSearching) {
      fetchMoreCloudSearchParts();
      return;
    }

    fetchMoreParts();
  }, [fetchMoreCloudSearchParts, fetchMoreParts, isSearching]);

  return <Waypoint key={parts?.length} bottomOffset="-20%" onEnter={fetchMore} />;
};

export default CatalogPopupInfiniteControl;
