import React from 'react';

import { Box, Stack, Typography } from '@mui/material';

import { Custom } from 'components/Icons';

const NoRowsOverlay = ({ message, sx, icon = <Custom.WorkCellQueue />, iconFixed = false }) => (
  <Stack
    sx={{
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      paddingBottom: '10%',
      margin: '0 auto',
      '@media screen and ( max-height: 700px )': {
        paddingBottom: 'unset !important',
      },
      ...sx,
    }}
  >
    <Box sx={{ textAlign: 'center', maxWidth: '402px' }}>
      <Box
        sx={{
          '& svg': {
            height: !iconFixed ? '42px' : null,
            width: !iconFixed ? '46px' : null,
            path: { fill: '#BDBDBD', fillOpacity: 1 },
          },
        }}
      >
        {icon}
      </Box>
      <Typography
        variant="h6"
        sx={{ color: 'surface.lightSurface.secondary', letterSpacing: '0.15px' }}
      >
        {message}
      </Typography>
    </Box>
  </Stack>
);

export default NoRowsOverlay;
