import { Box } from '@mui/material';
import { Route, Routes } from 'react-router-dom';

import {
  CompanyMembersInterfaceNavContent,
  FacilitiesInterfaceNavContent,
  ProjectInterfaceNavContent,
  ProjectMembersInterfaceNavContent,
  ProjectPropertiesInterfaceNavContent,
} from 'modules/Admin/AdminNavInterfaces';

const FACILITIES_ROUTE = 'facilities';
const PROJECTS_ROUTE = 'projects';
const SYNC_MEMBERS_ROUTE = '360-sync-members';
const PROJECT_MEMBERS_ROUTE = 'members/project-members';
const PROJECT_PROPERTIES_ROUTE = 'properties/project-properties';

const SyncModule = () => (
  <Box width="100%" id="sync-module-content-web-component">
    <sync-module-content-web-component />
  </Box>
);

const SyncRoutes = () => (
  <Routes>
    <Route path={FACILITIES_ROUTE} element={<FacilitiesInterfaceNavContent />} />
    <Route path={PROJECTS_ROUTE} element={<ProjectInterfaceNavContent />} />
    <Route path={SYNC_MEMBERS_ROUTE} element={<CompanyMembersInterfaceNavContent />} />
    <Route path={PROJECT_MEMBERS_ROUTE} element={<ProjectMembersInterfaceNavContent />} />
    <Route path={PROJECT_PROPERTIES_ROUTE} element={<ProjectPropertiesInterfaceNavContent />} />
    <Route path="*" element={<SyncModule />} />
  </Routes>
);

export default SyncRoutes;
