import React, { useState, useEffect } from 'react';

import { gql, useLazyQuery } from '@apollo/client';
import { ForesiteTextField } from '@evolvemep/foresite-components-ui-react';
import { useController, useFormContext } from 'react-hook-form';

import { useUser } from 'app/UserContext';
import { taskTypes as TASK_TYPES } from 'graphql/queries';

const TaskTypeNameField = ({ name, ...restProps }) => {
  const { control, setError, defaultValues, clearErrors, trigger } = useFormContext();
  const { user } = useUser();
  const [searchTaskType, { data: foundTaskType }] = useLazyQuery(gql(TASK_TYPES), {
    fetchPolicy: 'no-cache',
  });

  const [searchName, setSearchName] = useState(null);

  const taskTypeNameInputRules = {
    required: 'Task Type Name is Required',
    validate: (value) => {
      const isEqualToInitialValue =
        defaultValues?.taskTypeName &&
        value.toLowerCase().trim() === defaultValues?.taskTypeName?.toLowerCase().trim();

      if (isEqualToInitialValue) return null;

      if (foundTaskType?.taskTypes?.length) {
        return 'Task Type Name already exists';
      }

      return true;
    },
  };

  const {
    field: { onChange, onBlur, ref, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: taskTypeNameInputRules,
  });

  const [searchNameDebounced, setSearchNameDebounced] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => setSearchName(searchNameDebounced), 300);
    return () => clearTimeout(timer);
  }, [searchNameDebounced]);

  useEffect(() => {
    if (foundTaskType == null) return;
    if (foundTaskType?.taskTypes?.length) {
      setError(name, { type: 'custom', message: 'Task Type Name already exists' });
      return;
    }
    clearErrors(name);
    trigger(name);
  }, [foundTaskType, setError, name, clearErrors, trigger]);

  useEffect(() => {
    if (!searchName) return;
    const isEqualToInitialValue =
      defaultValues?.taskTypeName &&
      searchName.toLowerCase().trim() === defaultValues?.taskTypeName?.toLowerCase().trim();

    if (isEqualToInitialValue) return;

    searchTaskType({
      variables: {
        query: {
          taskTypeName: `eq:${encodeURIComponent(searchName.trim())}`,
          companyId: user?.companyId,
          take: 1,
        },
      },
    });
  }, [searchName, searchTaskType, user?.companyId, defaultValues?.taskTypeName]);

  return (
    <ForesiteTextField
      onChange={(e) => {
        onChange(e);
        setSearchNameDebounced(e.target.value);
      }}
      onBlur={onBlur}
      value={searchNameDebounced}
      name={name}
      inputRef={ref}
      sx={{ width: '100%' }}
      error={!!error}
      helperText={error?.message}
      label="Task Type Name*"
      maxLength={64}
      {...restProps}
    />
  );
};

export default TaskTypeNameField;
