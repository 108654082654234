import React from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { DeviceSizes } from 'constants/globalConstants';
import useDeviceSize from 'hooks/useDeviceSize';

import InvitationExpiredBackground from '../../assets/images/backgrounds/Invitation-Expired-bg.png';

const InvitationExpired = () => {
  const { deviceSize } = useDeviceSize();

  return (
    <PageWrapper deviceSize={deviceSize}>
      <PageBackground />
      <PageContent>
        <Typography variant="h3" color="#10143D" gutterBottom sx={{ textAlign: 'center' }}>
          Sorry, this invitation has expired :(
        </Typography>
        <Typography variant="body2" color="#303030" sx={{ maxWidth: '634px', textAlign: 'center' }}>
          Hi there, it seems the invitation link has expired or has been canceled. Please check with
          your administrator to request a new invitation.
        </Typography>
      </PageContent>
    </PageWrapper>
  );
};

export default InvitationExpired;

const PageWrapper = ({ deviceSize, children }) => (
  <Stack
    sx={{
      height: '100vh',
      width: 1,
      position: [DeviceSizes.tablet, DeviceSizes.mobile].includes(deviceSize) ? 'fixed' : 'inherit',
      left: 0,
    }}
  >
    {children}
  </Stack>
);

const PageBackground = () => (
  <img src={InvitationExpiredBackground} alt="Invitation Expired" height="100%" />
);

const PageContent = ({ children }) => {
  const contentStyles = {
    position: 'absolute',
    zIndex: 9001,
    top: '50%',
    transform: 'translateY(-50%)',
  };

  return (
    <Stack alignItems="center" width={1} sx={contentStyles}>
      {children}
    </Stack>
  );
};
