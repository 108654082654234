import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';

import { PasswordCheck, PasswordControlField } from 'components';
import { passwordRegEx } from 'constants/regEx';

const maxCharactersPassword = 20;
const passwordRules = {
  required: 'Password required.',
  minLength: {
    value: 8,
    message: 'Password should be more than 8 letters.',
  },
  maxLength: {
    value: 20,
    message: 'Password should be less than 20 letters.',
  },
  pattern: {
    value: passwordRegEx,
    message: 'Password is not strong.',
  },
};

const JoinForm = ({ onSubmit, type }) => {
  const [passwordFocus, setPasswordFocus] = useState(false);
  const {
    handleSubmit,
    control,
    watch,
    formState: { isValid, isDirty },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const password = watch('password');

  const PasswordCharacterCounter = () => (
    <HelperText textAlign="right">
      {password?.length || 0}/{maxCharactersPassword}
    </HelperText>
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <PasswordInputField
        control={control}
        focusPassword={() => setPasswordFocus(true)}
        unfocusPassword={() => setPasswordFocus(false)}
      />
      <PasswordCharacterCounter />
      <ConfirmPasswordInputField password={password} control={control} />
      {password && <PasswordCheck password={password || ''} focused={passwordFocus} />}
      <JoinCompanyButton
        disabled={isDirty && !isValid}
        fullWidth
        type="submit"
        variant="contained"
        color="secondary"
      >
        {`join ${type}`}
      </JoinCompanyButton>
    </form>
  );
};

export default JoinForm;

const HelperText = (props) => (
  <Box sx={{ margin: 0, lineHeight: '24px', fontSize: '12px' }} {...props} />
);

const JoinCompanyButton = (props) => (
  <Button
    sx={{
      backgroundColor: '#10143D',
      marginTop: '32px',
      fontSize: '15px',
      lineHeight: '24px',
      letterSpacing: '0.46px',
      textTransform: 'uppercase',
      '&:hover': {
        background: '#434A90',
      },
    }}
    {...props}
  />
);

const PasswordInputField = ({ control, focusPassword, unfocusPassword }) => (
  <PasswordControlField
    name="password"
    defaultValue=""
    control={control}
    onFocus={focusPassword}
    onBlur={unfocusPassword}
    label="Create Password"
    rules={passwordRules}
  />
);

const ConfirmPasswordInputField = ({ password, control }) => {
  const confirmPassRules = {
    required: 'Confirm password is required',
    validate: (value) => value === password || 'Passwords do not match.',
  };

  return (
    <PasswordControlField
      name="confirm"
      defaultValue=""
      control={control}
      label="Confirm Password"
      rules={confirmPassRules}
    />
  );
};
