import React, { useEffect, useMemo, useState } from 'react';

import { gql, useMutation } from '@apollo/client';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { useLocation, useParams } from 'react-router-dom';

import {
  addWorkRequestItem as ADD_WORK_REQUEST_ITEM,
  updateWorkRequestItem as UPDATE_WORK_REQUEST_ITEM,
  addWorkRequestItemFromCatalog as ADD_ITEM_FROM_CATALOG,
} from 'graphql/mutations';
import { workRequestItems as WR_ITEMS } from 'graphql/queries';
import useFetchWorkOrders from 'hooks-api/useFetchWorkOrders';
import { useUnitOfMeasureAPI } from 'hooks-api/useUnitOfMeasureAPI';
import useWorkRequestItemsAPI from 'hooks-api/useWorkRequestItemsAPI';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import { getUomOptionsToSelect } from 'modules/Materials/CatalogSetup/ItemsSection/Manufacturer/UnitOfMeasure/unitOfMeasureHelpers';

import { WRITE_IN_ASSEMBLY } from './constants';

const WorkRequestItemsContext = React.createContext();

const WORK_REQUEST_ITEMS_VIEWS = {
  TABLE_VIEW: 'talbleView',
  FORGE_VIEW: 'forgeView',
};

// eslint-disable-next-line max-lines-per-function
const WorkRequestItemsProvider = ({ children, isTasksReadOnly = false }) => {
  const { getWorkOrders, workOrders } = useFetchWorkOrders();
  const [workRequestItemsSelected, setWorkRequestItemsSelected] = useState([]);
  const [newUomId, setNewUomId] = useState(null);
  const [workRequestStatus, setWorkRequestStatus] = useState(null);
  const [workRequest, setWorkRequest] = useState(null);
  const [visibleInputs, setVisibleInputs] = useState(false);
  const [isEditingItem, setIsEditingItem] = useState(false);
  const [isDrawingWRItem, setIsDrawingWRItem] = useState(false);
  const [selectedWorkRequestId, setSelectedWorkRequestId] = useState(null);
  const [currentView, setCurrentView] = useState(WORK_REQUEST_ITEMS_VIEWS.TABLE_VIEW);
  const [activeDocument, setActiveDocument] = useState(null);
  const { id: workRequestId } = useParams();
  const currentWR = workRequestId ?? selectedWorkRequestId;
  const { workRequestItems, workRequestItemType, fetchMore } = useWorkRequestItemsAPI(currentWR);
  const { handleResponse } = useGraphqlResponseHandler();
  const { uoms } = useUnitOfMeasureAPI();
  const uomOptions = useMemo(() => getUomOptionsToSelect(uoms), [uoms]);
  const uomDefaultValue = uomOptions?.filter((uom) => uom.label === 'asm')[0];
  const [addWorkRequestItemMutation] = useMutation(gql(ADD_WORK_REQUEST_ITEM), {
    update(cache, { data: workRequestItemData }) {
      const data = cache.readQuery({
        query: gql(WR_ITEMS),
        variables: { query: { workRequestId: currentWR } },
      });

      if (data?.workRequestItems && workRequestItemData?.addWorkRequestItem) {
        cache.writeQuery({
          query: gql(WR_ITEMS),
          variables: { query: { workRequestId: currentWR } },
          data: {
            workRequestItems: [...data.workRequestItems, workRequestItemData.addWorkRequestItem],
          },
        });
      }
    },
  });
  const [modalUom, setModalUom] = useState(false);

  const [addWorkRequestItemMutationWithCacheUpdate] = useMutation(gql(ADD_ITEM_FROM_CATALOG), {
    update(cache, { data: workRequestItemData }) {
      const data = cache.readQuery({
        query: gql(WR_ITEMS),
        variables: { query: { workRequestId: currentWR } },
      });
      if (data?.workRequestItems && workRequestItemData?.addWorkRequestItemFromCatalog) {
        cache.writeQuery({
          query: gql(WR_ITEMS),
          variables: { query: { workRequestId: currentWR } },
          data: {
            workRequestItems: [...data.workRequestItems, workRequestItemData.addWorkRequestItemFromCatalog],
          },
        });
      }
    },
  });

  const [updateWorkRequestItemMutation] = useMutation(gql(UPDATE_WORK_REQUEST_ITEM), {
    update(cache, { data: workRequestItemData }) {
      const data = cache.readQuery({
        query: gql(WR_ITEMS),
        variables: { query: { workRequestId: currentWR } },
      });
      if (data?.workRequestItems && workRequestItemData?.updateWorkRequestItem) {
        const updatedData = data.workRequestItems.map((workRequestItem) => {
          const itemCopy = { ...workRequestItem };
          if (itemCopy?.workRequestItemId === workRequestItemData?.updateWorkRequestItem?.workRequestItemId) {
            itemCopy.workRequestItemName = workRequestItemData?.updateWorkRequestItem.workRequestItemName;
            itemCopy.quantity = workRequestItemData?.updateWorkRequestItem.quantity;
            itemCopy.unitOfMeasureId = workRequestItemData?.updateWorkRequestItem.unitOfMeasureId;
            itemCopy.workRequestItemTypeId = workRequestItemData?.updateWorkRequestItem.workRequestItemTypeId;
          }
          return itemCopy;
        });
        cache.writeQuery({
          query: gql(WR_ITEMS),
          variables: { query: { workRequestId: currentWR } },
          data: {
            workRequestItems: updatedData,
          },
        });
      }
    },
  });

  const location = useLocation();
  const isWriteInAssembly = WRITE_IN_ASSEMBLY === workRequest?.workRequestItemTypeId;

  const isReadOnly = useMemo(() => {
    const inDrawingRegister = !!location.pathname.match(/drawing-register/g);
    if (inDrawingRegister) return inDrawingRegister;
    const status = isWriteInAssembly
      ? ['Canceled', 'Draft', 'Pending', 'Rejected']
      : ['Canceled', 'Draft', 'Pending', 'Rejected', 'Submitted'];
    return !status.includes(workRequestStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workRequestStatus]);

  const apiRef = useGridApiRef();

  const WRItemType = workRequestItemType?.filter((type) => type.workRequestItemTypeName === 'Write-In Assembly');

  const addWorkRequestItem = (
    { unitOfMeasureId, workRequestItemName, quantity, itemType = null },
    onFinish = () => {},
  ) => {
    const body = {
      workRequestId: currentWR,
      workRequestItemTypeId: itemType || WRItemType[0]?.workRequestItemTypeId,
      unitOfMeasureId: unitOfMeasureId?.value,
      workRequestItemName,
      quantity,
    };

    handleResponse(
      addWorkRequestItemMutation,
      {
        variables: {
          body,
        },
      },
      { successMessage: `Item successfully created` },
    ).then(() => {
      onFinish();
    });
  };

  const addWorkRequestItemWithCacheUpdate = async ({ partId, quantity }) => {
    const body = { workRequestId, partId, quantity };
    return addWorkRequestItemMutationWithCacheUpdate({ variables: { body } });
  };

  const updateWorkRequestItem = (values, workRequestItemId, workRequest = {}) => {
    const isWorkRequestStatusSubmitted = workRequest?.workRequestStatusName === 'Submitted';

    const body = {
      ...values,
      workRequestId: currentWR,
      unitOfMeasureId: values?.unitOfMeasureId?.value,
      ...(isWorkRequestStatusSubmitted ? {} : { workRequestItemTypeId: WRItemType[0]?.workRequestItemTypeId }),
    };
    handleResponse(
      updateWorkRequestItemMutation,
      {
        variables: {
          params: { workRequestItemId },
          body,
        },
      },
      { successMessage: `Item successfully updated` },
    );
  };

  const inWorkOrders = location.pathname.match(/work-order/g);
  const inForgeView = currentView === WORK_REQUEST_ITEMS_VIEWS.FORGE_VIEW;

  useEffect(() => {
    if (workRequestId) {
      getWorkOrders({
        variables: {
          query: {
            workRequestIds: workRequestId,
          },
        },
      });
    }
  }, [workRequestId, getWorkOrders, workRequest]);

  const selectedStateObj = {
    uomOptions,
    workRequestItems,
    addWorkRequestItem,
    updateWorkRequestItemMutation,
    updateWorkRequestItem,
    isTasksReadOnly: inWorkOrders ? true : isTasksReadOnly,
    newUomId,
    setNewUomId,
    setWorkRequestStatus,
    workRequestStatus,
    isReadOnly: inWorkOrders ? true : isReadOnly,
    setWorkRequest,
    workRequest,
    fetchMore,
    addWorkRequestItemWithCacheUpdate,
    visibleInputs,
    setVisibleInputs,
    isEditingItem,
    setIsEditingItem,
    uomDefaultValue,
    inWorkOrders,
    isDrawingWRItem,
    setIsDrawingWRItem,
    setSelectedWorkRequestId,
    apiRef,
    workRequestItemsSelected,
    setWorkRequestItemsSelected,
    setCurrentView,
    activeDocument,
    setActiveDocument,
    inForgeView,
    WROrder: workOrders?.length ? workOrders[0] : null,
    modalUom,
    setModalUom,
  };

  return <WorkRequestItemsContext.Provider value={selectedStateObj}>{children}</WorkRequestItemsContext.Provider>;
};

const useWorkRequestItemsContext = () => {
  const context = React.useContext(WorkRequestItemsContext);

  if (context === undefined) {
    throw new Error('useWorkRequestItemsContext must be used within a WorkRequestItemsContext');
  }

  return context;
};

export { WorkRequestItemsContext, WorkRequestItemsProvider, useWorkRequestItemsContext, WORK_REQUEST_ITEMS_VIEWS };
