import React from 'react';

import { ForesiteTextField } from '@evolvemep/foresite-components-ui-react';
import { useController, useFormContext } from 'react-hook-form';

const DescriptionField = ({ name, sx, ...restProps }) => {
  const { control, disabled } = useFormContext();

  const {
    field: { onChange, onBlur, ref, value },
    fieldState: { error },
    formState: { isSubmitting },
  } = useController({
    name,
    control,
    rules: {
      maxLength: { value: 255, message: 'Description must be 255 characters or less' },
    },
  });

  return (
    <ForesiteTextField
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      name={name}
      inputRef={ref}
      disabled={isSubmitting || disabled}
      sx={{ width: '100%', ...sx }}
      error={!!error}
      helperText={error?.message}
      label="Description"
      size="small"
      multiline
      rows={7}
      maxRows={7}
      maxLength="255"
      {...restProps}
    />
  );
};

export default DescriptionField;
