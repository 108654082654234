import React, { useState } from 'react';

import { Drawer, Box, useTheme } from '@mui/material';

import { useModule } from 'app/Modules/ModuleContext';
import { Custom } from 'components/Icons';

import NavBarContent from './NavBarContent/NavBarContent';

const DesktopNavBar = () => {
  const [open, setOpen] = useState(false);
  const { loading } = useModule();

  const openDrawer = () => setOpen(true);
  const closeDrawer = () => setOpen(false);

  return (
    <EvolveDrawer variant="permanent" open={open} onMouseEnter={openDrawer} onMouseLeave={closeDrawer}>
      {!loading && <NavBarContent open={open} />}
      <Box alignItems="start" mt="auto">
        <Custom.Menu />
      </Box>
    </EvolveDrawer>
  );
};

export default DesktopNavBar;

const drawerWidth = 260;

const openedMixin = (theme) => ({
  color: theme.palette.grey[500],
  backgroundColor: theme.palette.other.navbarBackground,
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  padding: '8px',
  paddingTop: '72px',
  overflowX: 'hidden',
  '& .NavList-nestedListItem': {
    paddingLeft: '16px',
    marginBottom: '8px',
    transition: '0.1s',
  },
});

const closedMixin = (theme) => ({
  color: theme.palette.grey[500],
  backgroundColor: theme.palette.other.navbarBackground,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  padding: '8px',
  paddingTop: '72px',
  overflowX: 'hidden',
  width: '64px',
  '& .NavList-nestedListItem': {
    paddingLeft: '7px',
    marginBottom: '8px',
    transition: '0.1s',
    '& > .MuiListItemText-root': {
      display: 'none',
    },
    '& > .MuiListItemIcon-root': {
      minWidth: '19px',
    },
    '& > svg:first-of-type': {
      marginRight: '8px',
    },
  },
});

const EvolveDrawer = ({ open, ...props }) => {
  const theme = useTheme();

  return (
    <Drawer
      sx={{
        position: 'fixed',
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
          ...openedMixin(theme),
          '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': closedMixin(theme),
        }),
        zIndex: 3,
        '@media screen and ( max-width: 903px )': {
          display: 'none',
        },
      }}
      {...props}
    />
  );
};
