import React, { FC } from 'react';

import { Box, TableRow, Typography } from '@mui/material';

export interface IAttachmentType {
  label?: string;
  icon: any;
  darkMode?: boolean;
}

const AttachmentType: FC<IAttachmentType> = ({ label, icon, darkMode = false }: IAttachmentType) => (
  <TableRow
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '8px',
      ...(darkMode
        ? { color: '#fff', borderBottom: '1px solid rgba(224, 224, 224, .7)' }
        : {
            color: '#373737',
            borderBottom: '1px solid rgba(224, 224, 224, .7)',
          }),
      height: '32px',
    }}
  >
    <Box
      sx={{
        padding: 0,
        margin: 0,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        '& svg': { path: { fill: darkMode ? '#fff' : '#373737' } },
      }}
    >
      {icon}
    </Box>
    <Typography fontWeight="bold" variant="body2">
      {label}
    </Typography>
  </TableRow>
);

export default AttachmentType;
