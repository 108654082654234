/* eslint-disable max-lines-per-function */
// Copyright ©️ 2022 eVolve MEP, LLC
import React, { useEffect } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import { FormProvider, useForm } from 'react-hook-form';

import { useUser } from 'app/UserContext';
import { Modal, CancelButton, CreateButton, CenteredLoadSpinner } from 'components';
import useFetchFacilities from 'hooks-api/useFetchFacilities';
import useFetchProjectById from 'hooks-api/useFetchProjectById';

import { useWorkRequestModalCreate } from './useWorkRequestModalCreate';
import { WorkRequestCreateForm } from './WorkRequestCreateForm';

const initialFormValues = {
  ProjectId: '',
  FacilityId: '',
  NeedBy: null,
  WorkRequestName: '',
  WorkRequestDescription: '',
};

const WorkRequestModalCreate = ({ open, onClose, selectedItem }) => {
  const { loading: loadingProject, project } = useFetchProjectById(selectedItem?.id);
  const { user } = useUser();

  const { loading: loadingFacilities, facilities } = useFetchFacilities({
    companyId: user.companyId,
    hasShopDepartment: true,
    showAvailableFacilities: true,
  });

  const methods = useForm({ mode: 'all', defaultValues: initialFormValues });

  const {
    handleSubmit,
    formState: { errors, isValid },
    reset,
    setValue,
  } = methods;

  const { submit, loading, defaultFacility, onCloseModal } = useWorkRequestModalCreate({
    project,
    initialFormValues,
    reset,
    onClose,
  });

  useEffect(() => {
    if (!defaultFacility) return;
    setValue('FacilityId', { value: defaultFacility.facilityId, label: defaultFacility.facilityName });
  }, [setValue, defaultFacility, open]);

  return (
    <Modal
      title="Create new work request"
      open={open}
      onClose={onCloseModal}
      footer={
        <>
          {loading && <CircularProgress />}
          <CancelButton color="secondary.dark" onClick={onCloseModal} sx={{ marginRight: '8px' }} disabled={loading} />
          <CreateButton
            disabled={!isValid || Object.keys(errors).length !== 0 || loading}
            onClick={handleSubmit((data) => submit(data))}
            buttonText="SAVE"
            data-cy="work-request-save-button"
          />
        </>
      }
      sx={{
        '.MuiDialog-paper': {
          maxWidth: '505px',
        },
      }}
    >
      <FormProvider {...methods}>
        {loadingProject || loadingFacilities ? (
          <CenteredLoadSpinner sx={{ height: '294px' }} />
        ) : (
          <WorkRequestCreateForm facilities={facilities} isAdding={loading} selectedItem={selectedItem} />
        )}
      </FormProvider>
    </Modal>
  );
};

export default WorkRequestModalCreate;
