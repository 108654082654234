import React from 'react';

import { useTheme } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { ControlledDatePicker } from 'components';
import { Custom } from 'components/Icons';
import { isPastDate, TODAY } from 'helpers/dateFunctions';

const NeedByDateField = React.memo(({ ...rest }) => {
  const { control } = useFormContext();

  const { palette } = useTheme();

  const validate = (newDate) => {
    if (typeof newDate?.isValid === 'function' && !newDate.isValid()) return 'Invalid date';
    if (isPastDate(newDate) && newDate !== null) return 'Invalid date';
    return true;
  };

  return (
    <ControlledDatePicker
      name="NeedBy"
      autoComplete="off"
      pickerIcon={Custom.CalendarTodayOutlined}
      control={control}
      hideErrorMessage={false}
      minDate={TODAY}
      label="Need By Date"
      size="small"
      fullWidth
      color="secondary"
      PaperProps={{
        sx: {
          '& .MuiPickersDay-today': {
            borderColor: `${palette.secondary.main} !important`,
          },
          '& .Mui-selected': {
            backgroundColor: `${palette.secondary.main} !important`,
          },
        },
      }}
      rules={{ validate }}
      {...rest}
    />
  );
});

export default NeedByDateField;
