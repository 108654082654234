import React from 'react';

import { IconButton, Tooltip } from '@mui/material';

import { Custom } from 'components/Icons';
import { greyColors } from 'styles/theme/colors';

type Props = {
  title?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  dataTestId?: string;
};

const NewFolderButton = ({
  title = 'New folder',
  disabled = false,
  onClick,
  dataTestId = 'create-folder',
}: Props) => (
  <Tooltip
    placement="top"
    arrow
    title={title}
    data-testid="create-folder-tooltip"
    PopperProps={{
      sx: {
        '& .MuiTooltip-tooltip': {
          paddingTop: '0px !important',
          paddingBottom: '0px !important',
        },
      },
    }}
  >
    <span>
      <IconButton
        sx={{
          color: greyColors.grey900,
          paddingLeft: '0px !important',
          paddingRight: '0px !important',
        }}
        onClick={onClick}
        disabled={disabled}
        data-cy={dataTestId}
        data-testid={dataTestId}
        aria-label={title}
      >
        <Custom.AddBox />
      </IconButton>
    </span>
  </Tooltip>
);

export default NewFolderButton;
