import { useCallback } from 'react';

import { gql, useLazyQuery } from '@apollo/client';

import { useUser } from 'app/UserContext';
import { customMaxLengthRule, multiSearchRule } from 'constants/inputFieldRules';
import { projectsMultiSearch as PROJECTS_MULTI_SEARCH } from 'graphql/queries';

const useProjectIdentifier = () => {
  const { user } = useUser();
  const [getProjects, { data }] = useLazyQuery(gql(PROJECTS_MULTI_SEARCH), {
    fetchPolicy: 'cache-and-network',
  });

  const VALIDATE_ID = 'Id already exists';

  const ruleSearchByIdentifier = {
    required: 'Required*',
    ...customMaxLengthRule(30, '30/30'),
    ...multiSearchRule(data?.projectsMultiSearch, VALIDATE_ID),
  };

  const projectsByIdentifier = useCallback(
    async (projectIdentifier) => {
      await getProjects({
        variables: {
          query: {
            companyId: user.companyId,
            projectIdentifier: `${encodeURIComponent(projectIdentifier.trim())}`,
          },
        },
        fetchPolicy: 'cache-and-network',
      });
    },
    [getProjects, user.companyId],
  );

  return {
    ruleSearchByIdentifier,
    projectsByIdentifier,
    projectsMultiSearch: data?.projectsMultiSearch ?? [],
  };
};

export default useProjectIdentifier;
