import React from 'react';

import {
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Box,
} from '@mui/material';

import {
  WorkOrders as WorkOrdersIcon,
  WorkRequests as WorkRequestIcon,
} from 'assets/images/nav-icons';
import { TODAY, getDaysBetween, getParsedDate } from 'helpers/dateFunctions';
import { WorkOrder } from 'typings/work-order';

import {
  CellComponentLink,
  CellComponentLinkGeneric,
  CellComponentStatus,
} from '../WorkOrderTableComponents';

export const ListDetailWorkOrder: React.FC<{ workOrders: WorkOrder[] }> = ({ workOrders }) => {
  const workOrdersComponents = workOrders.map((wo) => {
    const daysRemaining =
      wo?.workOrderStatusTypeName === 'Completed' && wo?.lastStatusUpdate
        ? wo.lastStatusUpdate
        : TODAY;

    const daysRemainingCount =
      !!wo.workRequest.needBy &&
      getDaysBetween(new Date(wo.workRequest.needBy), new Date(daysRemaining));

    return (
      <TableRow key={wo.workOrderId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell component="th" scope="row">
          <Stack flexDirection="row" gap={1} flexGrow={1} height="100%" overflow="hidden">
            <WorkOrdersIcon style={{ marginTop: 5 }} />
            <Stack flexDirection="column">
              <Tooltip arrow placement="top" title={wo.workOrderName}>
                <Box>
                  <CellComponentLink
                    value={wo.workOrderName}
                    row={wo}
                    sx={{ fontWeight: 500, maxWidth: '200px' }}
                  />
                </Box>
              </Tooltip>
              <Tooltip arrow placement="top" title={wo.workRequest.workRequestName}>
                <Typography
                  textOverflow="ellipsis"
                  overflow="hidden"
                  whiteSpace="nowrap"
                  lineHeight="inherit"
                  maxWidth="200px"
                  fontSize="smaller"
                >
                  {wo.workRequest.workRequestName}
                </Typography>
              </Tooltip>
            </Stack>
          </Stack>
        </TableCell>
        <TableCell>
          <CellComponentStatus
            value={wo.workOrderStatusTypeName}
            row={{ ...wo, daysRemaining: daysRemainingCount }}
          />
        </TableCell>
        <TableCell>
          <Typography color="greyColors.grey600">
            Need by: {getParsedDate(wo.workRequest.needBy) ?? 'N/A'}
          </Typography>
          {wo.workRequest?.needBy && (
            <Typography color="greyColors.grey600" variant="caption">
              Days remaining: {daysRemainingCount}
            </Typography>
          )}
        </TableCell>
        <TableCell>
          <Stack flexDirection="row" gap={1} flexGrow={1} height="100%" overflow="hidden">
            <WorkRequestIcon style={{ marginTop: 5 }} />
            <CellComponentLinkGeneric
              sx={{ fontWeight: 500 }}
              value={wo.workRequest.workRequestIdentifier}
              linkProps={{
                to: `/shop/work-requests/${wo.workRequestId}`,
              }}
            />
          </Stack>
        </TableCell>
      </TableRow>
    );
  });
  return (
    <>
      <Typography fontWeight="500">Work Orders</Typography>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="detail work orders slot day">
        <TableBody>{workOrdersComponents}</TableBody>
      </Table>
    </>
  );
};
