import React from 'react';

import OverFlowTooltip from 'components/Tooltip/OverFlowTooltip';

export default ({ label = '' }: { label: string }) => (
  <OverFlowTooltip
    sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
    title={label}
  >
    {label}
  </OverFlowTooltip>
);
