import React from 'react';

import { Typography, Stack } from '@mui/material';

import OpenPlusModalButton from '../components/OpenPlusModalButton';

const AttributeVariantsHeader = ({ handleAddNewVariant, disabled }) => (
  <>
    <HeaderTitle />
    <Stack direction="row" alignItems="center">
      <Typography variant="tableHeader">Code</Typography>
      <OpenPlusModalButton onClick={handleAddNewVariant} disabled={disabled} title="Add Variant" />
    </Stack>
  </>
);

export default AttributeVariantsHeader;

const HeaderTitle = () => (
  <Typography variant="tableHeader" sx={{ flex: 1 }}>
    Attribute Variants
  </Typography>
);
