/* eslint-disable max-lines-per-function */
import { useCallback } from 'react';

import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';

import { useUser } from 'app/UserContext';
import {
  addFolder as ADD_FOLDER,
  addDocument as ADD_DOCUMENT,
  getDocumentPresignedURL as GET_DOCUMENT_PRESIGNED_URL,
} from 'graphql/mutations';
import { folders as FOLDERS } from 'graphql/queries';
import useUploadFile from 'hooks/useUploadFile';

const getDocumentStoragePath = (storagePath: string, fileName: string) => {
  const documentGUID = uuidv4();
  return `${storagePath}/${documentGUID}-${fileName}`;
};

interface IUseUploadWorkOrderDocument {
  workOrderId: string;
  documentTypeId: string;
}

const useUploadWorkOrderDocument = ({ workOrderId, documentTypeId }: IUseUploadWorkOrderDocument) => {
  const { user } = useUser();
  const [fetchFoldersAPI] = useLazyQuery(gql(FOLDERS));
  const [addFolderAPI] = useMutation(gql(ADD_FOLDER));
  const [addDocumentAPI] = useMutation(gql(ADD_DOCUMENT));
  const [getPresignedURL, { loading: preSignedUrlLoading }] = useMutation(gql(GET_DOCUMENT_PRESIGNED_URL));
  const { uploadFile } = useUploadFile(user);

  const storagePath = `all/company/${user.companyId}/field/${workOrderId}/attachments/${documentTypeId}`;

  const uploadWorkOrderDocToS3 = useCallback(
    async ({ file }: any) => {
      const documentStoragePath = getDocumentStoragePath(storagePath, file.name);
      await uploadFile(file, documentStoragePath);
      return { filename: file.name, documentStoragePath };
    },
    [uploadFile, storagePath],
  );

  const getFolderId = async ({ folders = [] }: { folders: any[] }) => {
    if (folders?.length > 0) return folders[0].folderId;
    const newFolder = await addFolderAPI({
      variables: {
        body: {
          companyId: user.companyId,
          folderName: workOrderId,
          storagePath,
          requestedBy: user.userId,
        },
      },
    });
    return newFolder.data?.addFolder?.folderId;
  };

  const getFolders = async () => {
    const {
      data: { folders },
    } = await fetchFoldersAPI({
      variables: {
        query: {
          searchPhrase: workOrderId,
          companyId: user.companyId,
        },
      },
    });
    return folders;
  };

  const getNewDocument = async ({
    folderId,
    filename,
    documentStoragePath,
  }: {
    folderId: string;
    filename: string;
    documentStoragePath: string;
  }) =>
    addDocumentAPI({
      variables: {
        body: {
          folderId,
          documentTypeId,
          documentName: filename,
          storagePath: documentStoragePath,
          requestedBy: user.userId,
        },
      },
    });

  const addWorkOrderDoc = useCallback(
    async ({ filename, documentStoragePath }: { filename: string; documentStoragePath: string }) => {
      try {
        const folders = await getFolders();
        const folderId = await getFolderId({ folders });
        const newDocument = await getNewDocument({ folderId, filename, documentStoragePath });
        return newDocument?.data?.addDocument?.documentId;
      } catch (error) {
        return null;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      addDocumentAPI,
      addFolderAPI,
      fetchFoldersAPI,
      user?.companyId,
      user?.userId,
      documentTypeId,
      storagePath,
      workOrderId,
    ],
  );

  const getDocumentPresignedUrl = useCallback(
    async (filePath: string) => {
      const presignedURLResponse = await getPresignedURL({
        variables: {
          body: {
            expirationHours: 24,
            objectKey: filePath,
            requestedBy: user.userId,
            verb: 'GET',
          },
        },
      });

      return presignedURLResponse?.data?.getDocumentPresignedURL?.preSignedURL || '';
    },
    [getPresignedURL, user.userId],
  );

  return {
    addWorkOrderDoc,
    uploadWorkOrderDocToS3,
    getDocumentPresignedUrl,
    metaData: { preSignedUrlLoading },
  };
};

export default useUploadWorkOrderDocument;
