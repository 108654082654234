import { validateQty } from '../../CatalogSearchResultPartItem/helpers/validate';

const findPartCb = (partId) => (parts) => parts.find((el) => el.partId === partId);

const useAddParts = ({ partsQty, onAddParts, setPartsQty, isSearching, cloudSearchParts, parts }) => {
  const addPart = async (part) => {
    const partQty = partsQty[part.partId]?.qty;

    if (!partQty) return;

    await onAddParts([{ part, partQty }]);
    setPartsQty((prev) => ({
      ...prev,
      [part.partId]: { qty: '', qtySuccessMsg: `${partQty} items added` },
    }));
  };

  const addParts = async () => {
    const selectedParts = Object.entries(partsQty)
      .filter(([, value]) => validateQty(value?.qty))
      .reduce((acc, cur) => {
        const [partId, partQty] = cur;
        const findPartByIdCb = findPartCb(partId);
        const part = isSearching ? findPartByIdCb(cloudSearchParts) : findPartByIdCb(parts);
        if (!part) return [...acc];
        return [...acc, { part, partQty: partQty.qty }];
      }, []);

    await onAddParts(selectedParts);

    let partsQtyWithStr = {};
    selectedParts.forEach(({ part, partQty }) => {
      partsQtyWithStr = {
        ...partsQtyWithStr,
        [part.partId]: { qtySuccessMsg: `${partQty} items added` },
      };
    });

    setPartsQty(partsQtyWithStr);
  };

  return {
    addPart,
    addParts,
  };
};

export default useAddParts;
