import React from 'react';

import { Stack, SxProps } from '@mui/material';

type ComponentPaneActionProps = {
  children: React.ReactNode;
  sx?: SxProps;
};

const ComponentPaneActions = ({ children, sx, ...restProps }: ComponentPaneActionProps) => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    sx={{ ...sx, height: '48px', maxHeight: '48px' }}
    {...restProps}
  >
    {children}
  </Stack>
);

export default ComponentPaneActions;
