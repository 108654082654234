import React, { useMemo, useState } from 'react';

import Stack from '@mui/material/Stack';

import { MoreMenu } from 'components';

import DecisionRow from '../BillOfMaterialsById/BOMCatalogTable/Decisions/DecisionRow';
import { useTakeOff } from './context/TakeOffContext';
import DeletePadItem from './DeletePadItem';

const menuStyles = {
  '.MuiDataGrid-row & > svg': {
    display: 'none',
  },
  '.MuiDataGrid-row:hover & > svg': {
    display: 'block',
  },
};

const addCommonOptions = () => [
  {
    label: 'Add comment',
    clickEvent: () => {},
  },
  {
    label: 'Add reminder',
    clickEvent: () => {},
  },
  {
    label: 'Add flag',
    clickEvent: () => {},
  },
  {
    label: 'Add attachment',
    clickEvent: () => {},
  },
];

const addDeleteOption = (onDelete) => ({
  label: 'Delete',
  clickEvent: onDelete,
  color: 'error.main',
});

const MoreOptionsBOM = ({ id, row }) => {
  const [visible, setVisible] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { pads, padIndex } = useTakeOff();

  const activePad = useMemo(() => pads?.[padIndex], [padIndex, pads]);

  const MORE_MENU_OPTIONS = [...addCommonOptions()];

  const onDelete = () => setOpenModal(true);

  if (!activePad?.isSourcePad && row?.padName === 'Unassigned')
    MORE_MENU_OPTIONS.push(addDeleteOption(onDelete));

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ width: '100%' }}
      data-cy="cellBOMMoreMenu"
    >
      <DecisionRow row={row} isTakeoff />
      <MoreMenu
        options={MORE_MENU_OPTIONS}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        sxMenu={{ left: '30px' }}
        handleOpen={() => setVisible(true)}
        handleExtClose={() => setVisible(false)}
        sxIconButton={visible ? {} : menuStyles}
      />
      {openModal && <DeletePadItem openModal={openModal} setOpenModal={setOpenModal} id={id} />}
    </Stack>
  );
};

export default MoreOptionsBOM;
