import { gql, useLazyQuery } from '@apollo/client';

import { documents as DOCUMENTS } from 'graphql/queries';

const useFetchDocumentsByIdentifier = (onCompleted) => {
  const [getDocumentsByIdentifier, { loading, data }] = useLazyQuery(gql(DOCUMENTS), {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      onCompleted(data.documents);
    },
  });
  return { getDocumentsByIdentifier, loading, data: data?.documents };
};

export default useFetchDocumentsByIdentifier;
