import React, { useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { draftPartReactiveVar } from 'apollo/reactiveVars';
import { SESSION_STORAGE_CONSTANTS } from 'constants/globalConstants';
import { VARIANT_SUCCESS } from 'constants/snackbarConstants';
import useAssembliesAPI from 'hooks-api/useAssembliesAPI';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import { PART_ITEM_FORM_DEFAULT_VALUE } from 'modules/Materials/CatalogSetup/ItemsSection/NewItemsSection/newItemSectionHelpers';
import usePartImage from 'modules/Materials/CatalogSetup/ItemsSection/usePartImage';
import { useCatalogPartContext } from 'modules/Materials/CatalogSetup/Providers/CatalogPartProvider';
import { useCatalogSelectedPartContext } from 'modules/Materials/CatalogSetup/Providers/CatalogSelectedPartProvider';

import { AssemblyEditorProvider } from '../ReactFlowProviders/AssemblyEditorContext';
import {
  convertAssemblyElements,
  createAssemblyBody,
  getNewUpdateElementsWithModifiedNode,
  getNodesWithModifiedAssembly,
} from '../Utils/assemblyEditorHelpers';
import { autoLayoutedNodes } from '../Utils/AutoLayoutNodes';
import {
  DEFAULT_ASSEMBLY_NODE_POSITION,
  NEW_PART_ITEM_ID,
  SELECTED_CATEGORY,
  UNPUBLISHED_PART,
} from '../Utils/constants';

const { PUBLISHED_PART_FROM_EDITOR } = SESSION_STORAGE_CONSTANTS;

// eslint-disable-next-line max-lines-per-function
const AssemblyEditorAPIProvider = ({ children }) => {
  const [assemblyId, setAssemblyId] = useState(null);
  const [mainPartItem, setMainPartItem] = useState(null);
  const [elements, setElements] = useState([]);
  const [numberOfElementsAdded, setNumberOfElementsAdded] = useState(0);
  const [loadingNodesImages, setLoadingNodesImages] = useState(false);
  const [updateElements, setUpdateElements] = useState({
    prevNodes: [],
    prevEdges: [],
    addNodes: [],
    addEdges: [],
    updateNodes: [],
    updateEdges: [],
    deleteNodes: [],
    deleteEdges: [],
  });
  const {
    addAssembly,
    loadingAddAssembly,
    updateAssembly,
    loadingUpdateAssembly,
    getAssemblies,
    assembliesData,
    loadingAssemblies,
  } = useAssembliesAPI();

  const { handleResponse } = useGraphqlResponseHandler();
  const navigate = useNavigate();
  const {
    onSubmit,
    methods: { handleSubmit, reset, getValues },
    setLoadingPublishAssembly,
    loadingPublishAssembly,
    setShowNewItem,
  } = useCatalogPartContext();
  const { setSelectedPart } = useCatalogSelectedPartContext();
  const isUpdateAssembly = useMemo(() => Boolean(updateElements.prevNodes.length), [updateElements]);
  const { enqueueSnackbar } = useSnackbar();
  const { getPartImage } = usePartImage();

  useEffect(() => {
    setLoadingNodesImages(true);
    if (!localStorage.getItem(UNPUBLISHED_PART)) {
      setLoadingNodesImages(false);
      return null;
    }
    const part = JSON.parse(localStorage.getItem(UNPUBLISHED_PART));
    setMainPartItem(part);
    let newPartElements = [
      {
        id: uuid(),
        type: `assemblyType`,
        data: part,
        position: {
          x: DEFAULT_ASSEMBLY_NODE_POSITION.positionX,
          y: DEFAULT_ASSEMBLY_NODE_POSITION.positionY,
        },
      },
    ];
    getAssemblies({ variables: { query: { partId: part.partId } } })
      .then(async ({ data }) => {
        if (!data?.assemblies?.length) return;
        setAssemblyId(data.assemblies[0].assemblyId);
        const { assemblyEdges, assemblyNodes } = convertAssemblyElements(
          [...data.assemblies[0].assemblyNodes],
          [...data.assemblies[0].assemblyEdges],
          part.partId,
        );
        const { nodes } = getNodesWithModifiedAssembly(assemblyNodes, newPartElements, part);
        setUpdateElements((current) => ({
          ...current,
          prevNodes: [...assemblyNodes],
          prevEdges: [...assemblyEdges],
        }));
        const nodesWithImages = await Promise.all(
          [...nodes].map(async (node) => {
            if (!node?.data?.documentIds?.length) return node;
            const image = await getPartImage(node?.data?.documentIds[0]);
            return { ...node, data: { ...node.data, image } };
          }),
        );
        newPartElements = [...nodesWithImages, ...assemblyEdges];
        localStorage.setItem(UNPUBLISHED_PART, JSON.stringify({ ...part, isUpdateAssembly: true }));
        const shouldRelocateNodes = assemblyNodes.find((node) => !node.position.x && !node.position.y);

        if (shouldRelocateNodes) {
          const relocatedNodes = autoLayoutedNodes(nodes, assemblyEdges);
          newPartElements = [...relocatedNodes, ...assemblyEdges];
          setUpdateElements((current) => ({
            ...current,
            updateNodes: [
              ...relocatedNodes.map((node) => ({
                ...node,
                positionX: parseInt(node.position.x, 10),
                positionY: parseInt(node.position.y, 10),
              })),
            ],
          }));
        }
      })
      .then(() => {
        setLoadingNodesImages(false);
        setElements(newPartElements);
      });

    return () => {
      setLoadingNodesImages(false);
      setElements([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToBackToCatalogSetup = (partId) => {
    setShowNewItem(false);
    reset({
      ...PART_ITEM_FORM_DEFAULT_VALUE,
    });
    setSelectedPart(null);
    const unpublishedPart = JSON.parse(localStorage.getItem(UNPUBLISHED_PART))?.previousLocation;

    localStorage.removeItem(UNPUBLISHED_PART);
    sessionStorage.setItem(PUBLISHED_PART_FROM_EDITOR, partId);
    if (!unpublishedPart) {
      navigate('/materials/catalog-setup');
    } else {
      const { pathname, search, hash } = unpublishedPart;
      navigate(pathname + search + hash);
    }
  };
  const createPartBeforePublish = async (part = null) => {
    setLoadingPublishAssembly(true);
    let res;
    await handleSubmit(async () => {
      res = await onSubmit(getValues(), true, part, false);
      return res;
    })();
    return res;
  };
  const handleUpdateOrAddAssembly = async () => {
    const { partId: mainPartId } = mainPartItem;
    if (!isUpdateAssembly) {
      const body = createAssemblyBody(elements, mainPartId);
      if (body.partId.partName) {
        const resPartCreated = await createPartBeforePublish();
        body.partId = resPartCreated?.addPart?.partId;
        body.nodes?.forEach((node, index) => {
          if (node.partId === NEW_PART_ITEM_ID) body.nodes[index].partId = resPartCreated.addPart.partId;
        });
      }
      return {
        mutation: addAssembly,
        options: { variables: { body } },
        mutationName: 'addAssembly',
      };
    }
    const part = JSON.parse(localStorage.getItem(UNPUBLISHED_PART));
    if (part?.isFormModified) {
      await createPartBeforePublish(part);
    }

    const { addNodes, addEdges, updateNodes, updateEdges, deleteNodes, deleteEdges } = updateElements;

    const hasNoChange = [addNodes, addEdges, updateNodes, updateEdges, deleteNodes, deleteEdges].every(
      (el) => !el.length,
    );
    if (hasNoChange && (!part?.isFormModified || part?.canContinue)) {
      return { mutation: null, options: null, canContinue: mainPartId };
    }

    let body = { ...updateElements, prevEdges: [], prevNodes: [] };
    body.addNodes = createAssemblyBody(body.addNodes, mainPartId)?.nodes || [];
    body.addEdges = createAssemblyBody(body.addEdges, mainPartId)?.edges || [];
    body.updateNodes = body.updateNodes.map(({ positionX, positionY, id, data }) => ({
      assemblyNodeId: id,
      positionX: parseInt(positionX, 10),
      positionY: parseInt(positionY, 10),
      ...(data?.qty ? { quantity: data?.qty } : {}),
    }));
    if (part?.isFormModified) {
      const mainReferenceId = elements.find((node) => node.data?.partId === mainPartId)?.id;
      if (body.updateNodes.filter((updateNode) => updateNode.assemblyNodeId === mainReferenceId).length) {
        body.updateNodes = body.updateNodes.map((updateNode) =>
          updateNode.assemblyNodeId === mainReferenceId
            ? { ...updateNode, assemblyNodeName: part.partName }
            : updateNode,
        );
      } else {
        const assemblyElement = elements.find((el) => el.data.partId === mainPartId);
        body.updateNodes.push({
          assemblyNodeId: assemblyElement.id,
          assemblyNodeName: assemblyElement.data.partName,
        });
      }
    }
    body = Object.fromEntries(Object.entries(body).filter((item) => item[1]?.length));
    return {
      mutation: updateAssembly,
      options: { variables: { body: { ...body, assemblyId } } },
      mutationName: 'updateAssembly',
    };
  };
  const handlePublish = async () => {
    const { mutation, options, mutationName, canContinue } = await handleUpdateOrAddAssembly();
    if (canContinue) {
      draftPartReactiveVar(null);
      goToBackToCatalogSetup(canContinue);
      setLoadingPublishAssembly(false);
      enqueueSnackbar('Success!', VARIANT_SUCCESS);
      return;
    }
    if (!options) return;
    // reset draftPart just before publishing part from AssemblyEditor
    draftPartReactiveVar(null);

    handleResponse(mutation, options, { successMessage: `Success!` }).then(async (res) => {
      setLoadingPublishAssembly(false);
      if (res.errors) return;
      goToBackToCatalogSetup(res.data[mutationName].part.partId);
    });
  };

  const onDeleteNext = (toBeDeleted = []) => {
    if (!isUpdateAssembly) return;
    setUpdateElements((current) => ({
      ...current,
      addNodes: [...current.addNodes.filter((node) => node.id !== toBeDeleted.id)],
      addEdges: [
        ...current.addEdges.filter((edge) => edge.source !== toBeDeleted.id && edge.target !== toBeDeleted.id),
      ],
      updateNodes: [...current.updateNodes.filter((node) => node.id !== toBeDeleted.id)],
      deleteNodes: [
        ...current.deleteNodes,
        ...(current.prevNodes.find((node) => node.id === toBeDeleted.id) ? [toBeDeleted.id] : []),
      ],
      deleteEdges: [
        ...new Set([
          ...current.deleteEdges,
          ...current.prevEdges
            .map((edge) => (edge.source === toBeDeleted.id || edge.target === toBeDeleted.id) && edge.id)
            .filter((edge) => !!edge),
        ]),
      ],
    }));
  };

  const onDuplicateNext = (duplicateNode = {}) => {
    if (isUpdateAssembly)
      setUpdateElements((current) => ({
        ...current,
        addNodes: [...current.addNodes, duplicateNode],
      }));
    setNumberOfElementsAdded(numberOfElementsAdded + 1);
  };

  const onDropNext = (newNode = {}) => {
    if (isUpdateAssembly) setUpdateElements((current) => ({ ...current, addNodes: [...current.addNodes, newNode] }));
    setNumberOfElementsAdded(numberOfElementsAdded + 1);
  };

  const onConnectNext = (lastElement) => {
    if (isUpdateAssembly)
      setUpdateElements((current) => {
        const isUniqueEdge = !current.addEdges.filter(
          (edge) =>
            edge.source === lastElement.source &&
            edge.sourceHandle === lastElement.sourceHandle &&
            edge.target === lastElement.target &&
            edge.targetHandle === lastElement.targetHandle,
        ).length;
        if (!isUniqueEdge) return current;
        return {
          ...current,
          addEdges: [...current.addEdges, lastElement],
        };
      });
  };

  const onEditNext = (updatedElement) => {
    const newUpdatedElement = {
      ...updatedElement,
      positionX: parseInt(updatedElement.position.x, 10),
      positionY: parseInt(updatedElement.position.y, 10),
      position: {
        x: parseInt(updatedElement.position.x, 10),
        y: parseInt(updatedElement.position.y, 10),
      },
    };
    if (!isUpdateAssembly) return;
    setUpdateElements((current) =>
      getNewUpdateElementsWithModifiedNode({ updateElements, newUpdatedElement, current }),
    );
  };

  const onCancel = () => {
    setShowNewItem(false);
    setSelectedPart(null);
    draftPartReactiveVar(null);
    reset({ ...PART_ITEM_FORM_DEFAULT_VALUE });
    localStorage.removeItem(SELECTED_CATEGORY);
    localStorage.removeItem(UNPUBLISHED_PART);
    sessionStorage.removeItem(SESSION_STORAGE_CONSTANTS.PUBLISHED_PART_FROM_EDITOR);
    navigate(-1);
  };
  return (
    <AssemblyEditorProvider
      {...{
        elements,
        setElements,
        mainPartItem,
        setMainPartItem,
        onDeleteNext,
        onDuplicateNext,
        onDropNext,
        onConnectNext,
        assemblyId,
        setAssemblyId,
        updateElements,
        setUpdateElements,
        isUpdateAssembly,
        handlePublish,
        loadingAddAssembly,
        loadingPublishAssembly,
        loadingUpdateAssembly,
        assembliesData,
        loadingAssemblies: loadingAssemblies || loadingNodesImages,
        onCancel,
        onEditNext,
      }}
    >
      {children}
    </AssemblyEditorProvider>
  );
};

export { AssemblyEditorAPIProvider };
