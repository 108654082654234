import React, { useContext, createContext, useState, useEffect } from 'react';

import { gql, useLazyQuery, useMutation } from '@apollo/client';

import { useUser } from 'app/UserContext';
import { SESSION_STORAGE_CONSTANTS } from 'constants/globalConstants';
import { addAssembly as ADD_ASSEMBLY, addPart as ADD_PART } from 'graphql/mutations';
import {
  taskMultiSearch as TASK_MULTISEARCH,
  partCategory as CATEGORIES,
  manufactured as MANUFACTURERS,
  unitOfMeasure as UOMS,
  parts as PARTS,
} from 'graphql/queries';

import getNodesAndEdges from './GetNodesAndEdges';

export const CatalogModalContext = createContext();

const { PART_ADDED_FROM_WR } = SESSION_STORAGE_CONSTANTS;

export const CatalogModalProvider = ({ children }) => {
  const [selectedCatalog, setSelectedCatalog] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [descriptionText, setDescriptionText] = useState(null);
  const [nodesAndEdges, setnodesAndEdges] = useState(null);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [manufacturer, setManufacturer] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [UOM, setUOM] = useState(null);
  const [partCreated, setPartCreated] = useState(null);

  const handleSnackBarClose = (e, reason) => {
    if (reason === 'clickaway') setOpenSnackBar(true);
    else if (reason === 'escapeKeyDown') setOpenSnackBar(true);
    else setOpenSnackBar(false);
  };

  const resetStates = () => {
    setSelectedCatalog(null);
    setSelectedCategory(null);
    setDescriptionText(null);
    setnodesAndEdges(null);
    setManufacturer(null);
    setCategoryId(null);
    setUOM(null);
  };

  return (
    <CatalogModalContext.Provider
      value={{
        handleSnackBarClose,
        selectedCatalog,
        setSelectedCatalog,
        selectedCategory,
        setSelectedCategory,
        descriptionText,
        setDescriptionText,
        openSnackBar,
        setnodesAndEdges,
        nodesAndEdges,
        setOpenSnackBar,
        manufacturer,
        setManufacturer,
        categoryId,
        setCategoryId,
        UOM,
        setUOM,
        resetStates,
        partCreated,
        setPartCreated,
      }}
    >
      {children}
    </CatalogModalContext.Provider>
  );
};

export const useCatalogModal = () => {
  const context = useContext(CatalogModalContext);
  if (context === undefined) {
    throw new Error('useCatalogModal must be used within a CatalogModalProvider');
  }
  return context;
};

export const useCatalogModalQuery = (categoryId = null, BOPid = null) => {
  const { selectedCatalog, setnodesAndEdges, partCreated } = useCatalogModal();
  const { user } = useUser();

  const [fetchTasksInBOP, { data: BOPdata, loading: BOPloading }] = useLazyQuery(
    gql(TASK_MULTISEARCH),
    { fetchPolicy: 'cache-and-network', variables: { query: { billOfProcessId: BOPid } } },
  );

  const [
    fetchCategories,
    { called: calledCategories, data: categoriesData, loading: loadingCategories },
  ] = useLazyQuery(gql(CATEGORIES), {
    fetchPolicy: 'cache-and-network',
    variables: {
      query: {
        companyId: user?.companyId,
        partCatalogId: selectedCatalog,
        orderBy: 'name:asc',
        take: 99999,
        parentPartCategoryId: categoryId,
      },
    },
  });
  const [fetchManufacturers, { data: manufacturersData, loading: manufacturersLoading }] =
    useLazyQuery(gql(MANUFACTURERS), {
      fetchPolicy: 'cache-and-network',
      variables: { query: { companyId: user?.companyId } },
    });
  const [fetchUOMs, { data: UOMsData, loading: UOMsLoading }] = useLazyQuery(gql(UOMS), {
    fetchPolicy: 'cache-and-network',
    variables: { query: { companyId: user?.companyId } },
  });
  const [searchPartName, { data: foundPartName, loading: foundPartLoading }] = useLazyQuery(
    gql(PARTS),
    {
      fetchPolicy: 'no-cache',
    },
  );
  const [addPartToCatalog] = useMutation(gql(ADD_PART), {
    onCompleted: (data) => {
      sessionStorage.setItem(PART_ADDED_FROM_WR, JSON.stringify(data.addPart));
    },
  });

  const [addAssembly, { loading: addAssemblyLoading }] = useMutation(gql(ADD_ASSEMBLY));

  useEffect(() => {
    if (BOPdata?.taskMultiSearch && partCreated) {
      setnodesAndEdges(getNodesAndEdges(BOPdata?.taskMultiSearch, partCreated));
    }
    // eslint-disable-next-line
  }, [BOPloading, partCreated, BOPdata]);

  return {
    fetchTasksInBOP,
    BOPdata,
    BOPloading,
    fetchCategories,
    calledCategories,
    categoriesData,
    loadingCategories,
    fetchManufacturers,
    manufacturersData,
    manufacturersLoading,
    fetchUOMs,
    UOMsData,
    UOMsLoading,
    searchPartName,
    foundPartName,
    foundPartLoading,
    addPartToCatalog,
    addAssembly,
    addAssemblyLoading,
  };
};
