import {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  deepOrange,
  brown,
  grey,
  blueGrey,
} from '@mui/material/colors';

export function getRandomColor() {
  const colors = [
    red,
    pink,
    purple,
    deepPurple,
    indigo,
    blue,
    lightBlue,
    cyan,
    teal,
    green,
    lightGreen,
    lime,
    yellow,
    amber,
    orange,
    deepOrange,
    brown,
    grey,
    blueGrey,
  ];

  const randomIndex = Math.floor(Math.random() * colors.length);

  const color = colors[randomIndex];

  const randomShade = Object.keys(color).filter((key) => key !== 'contrastText')[
    Math.floor(Math.random() * 15)
  ];

  return color[randomShade as keyof typeof red];
}
