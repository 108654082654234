import React, { forwardRef } from 'react';

import { StyledLink, withOverflowTooltip } from '@evolvemep/foresite-components-ui-react';
import { Box, Stack } from '@mui/material';
import { Link } from 'react-router-dom';

import StatusBadge from 'components/StatusBadges/StatusBadge';
import OverflowTypography from 'components/Tooltip/OverflowTypography';
import { BADGE_MAPPING_STATUS } from 'constants/badgeMappingStatus';
import { EIconType, getNavIcon } from 'helpers/MenuIcons';

import DaysRemaining from '../../../WorkCells/WorkCellsList/Columns/DaysRemaining';
import WorkOrderSpinner from '../../WorkOrderSpinner';
import { IconDeleteWorkOrder } from './IconDeleteWorkOrder';

export const CellComponentWOName = ({ value }) => <OverflowTypography title={value} variant="body2" />;

export const CellComponentWOProjectId = ({ value }) => <OverflowTypography title={value} variant="body2" />;

export const CellComponentDays = ({ row }) => (
  <DaysRemaining
    needByDate={row?.workRequest?.needBy}
    statusDate={row?.lastStatusUpdate}
    status={row?.workOrderStatusTypeName}
  />
);

export const linkStyles = {
  '& .link': {
    textDecoration: 'underline',
    color: 'surface.lightSurface.primary',
    '&:hover': {
      color: 'secondary.main',
      textDecoration: 'underline',
    },
  },
};

export const CellComponentLinkGeneric = ({ value, linkProps, sx = {} }) => (
  <Box
    sx={{
      ...linkStyles,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      ...sx,
    }}
  >
    <Link className="link" {...linkProps}>
      <OverflowTypography
        title={value}
        variant="body2"
        TypographyProps={{ color: 'secondary.main', variant: 'tableCell' }}
      />
    </Link>
  </Box>
);

export const CellComponentLink = ({ value, row, stateLink = {}, sx = {} }) => {
  const LinkComponent = forwardRef((props, ref) => {
    const { sx: sxFromProps, ...restProps } = props;
    return (
      <Box {...restProps} ref={ref} sx={{ ...sx, ...sxFromProps }} variant="body2">
        <StyledLink to={row.workOrderId} state={{ workOrderId: row.workOrderId, from: 'Work Orders', ...stateLink }}>
          {value}
        </StyledLink>
      </Box>
    );
  });
  const OverflowToolTip = withOverflowTooltip(LinkComponent);
  return <OverflowToolTip OverflowTooltipProps={{ title: value }} />;
};

export const CellComponentFlag = () => <IconWorkOrder />;

export const CellComponentTrash = ({ row }) => <IconDeleteWorkOrder row={row} />;

export const CellComponentStatus = ({ value, row }) => {
  const newValue = value === 'Draft' ? `workOrder${value}` : value;
  const newStatus = BADGE_MAPPING_STATUS[newValue];

  if (row?.isStepFunctionProcessing) return <WorkOrderSpinner size="20px" />;

  return (
    <StatusBadge
      key={newStatus?.variant}
      label={newStatus?.label || value}
      variant={newStatus?.variant || value}
      isLate={row?.daysRemaining < 0}
    />
  );
};

const IconWorkOrder = () => (
  <Stack direction="row">
    <Box sx={{ mt: '6px', width: '20px' }}>{getNavIcon(EIconType.WORK_ORDERS)}</Box>
  </Stack>
);
