import React, { FC } from 'react';

import { Tooltip, TooltipProps } from '@mui/material';

const WarningTooltip: FC<TooltipProps> = ({ children, ...rest }: TooltipProps) => (
  <Tooltip
    componentsProps={{
      popper: {
        sx: {
          '& .MuiTooltip-tooltip': {
            backgroundColor: '#FF9800 !important',
            color: 'surface.lightSurface.primary',
            height: '24px',
            padding: 'auto',
            alignItems: 'center',
            alignContent: 'center',
            display: 'flex',
            lineHeight: '10px',
            maxWidth: '500px !important',
          },
          '& .MuiTooltip-arrow': {
            color: '#FF9800 !important',
          },
        },
      },
    }}
    {...rest}
  >
    {children}
  </Tooltip>
);

export default WarningTooltip;
