import { createSvgIcon } from '@mui/material';

import { ReactComponent as ActionAlt } from './action-alt.svg';
import { ReactComponent as Action } from './action.svg';
import { ReactComponent as Connection } from './connection.svg';
import { ReactComponent as CONNECTIONS } from './connections.svg';
import { ReactComponent as DASHBOARD } from './dashboard.svg';
import { ReactComponent as DataUsage } from './data-usage.svg';
import { ReactComponent as LOGS } from './logs.svg';
import { ReactComponent as Mapping } from './mapping.svg';
import { ReactComponent as NavItem10 } from './nav-item-10.svg';
import { ReactComponent as NavItem11 } from './nav-item-11.svg';
import { ReactComponent as NavItem13 } from './nav-item-13.svg';
import { ReactComponent as NavItem14 } from './nav-item-14.svg';
import { ReactComponent as NavItem6 } from './nav-item-6.svg';
import { ReactComponent as NavItem7 } from './nav-item-7.svg';
import { ReactComponent as NavItem8 } from './nav-item-8.svg';
import { ReactComponent as NavItem9 } from './nav-item-9.svg';
import { ReactComponent as SYNC } from './sync.svg';
import { ReactComponent as Template } from './template.svg';
import { ReactComponent as Webhook } from './webhook.svg';
import { ReactComponent as WorkflowEditorBg } from './workflow-editor-bg.svg';
import { ReactComponent as Workflow } from './workflow.svg';
import { ReactComponent as WORKFLOWS } from './workflows.svg';

const DataUsageSvg = createSvgIcon(<DataUsage />, 'DataUsageSvg');
export {
  Action,
  ActionAlt,
  Connection,
  NavItem6,
  NavItem7,
  NavItem8,
  NavItem9,
  NavItem10,
  NavItem11,
  NavItem13,
  NavItem14,
  WORKFLOWS,
  Workflow,
  WorkflowEditorBg,
  Template,
  CONNECTIONS,
  LOGS,
  DASHBOARD,
  SYNC,
  Mapping,
  Webhook,
  DataUsage,
  DataUsageSvg,
};
