/* eslint-disable import/no-cycle */
import { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import { useItemsIntegrationModalContext } from 'modules/Materials/components/ItemsIntegrationModal/context/ItemsIntegrationModalContext';

import { ADD_REMAINING_QUANTITIES_FIELD } from '../constants';
import { useItemsIntegrationTableContext } from '../context/ItemsIntegrationTableContext';

const useEnableDone = () => {
  const { rows, fieldErrors } = useItemsIntegrationTableContext();
  const { setEnableDone, isLoading } = useItemsIntegrationModalContext();
  const { formState, clearErrors, watch } = useFormContext();
  const autoFill = watch(ADD_REMAINING_QUANTITIES_FIELD);
  const { isDirty, errors, isValid } = formState;

  useEffect(() => {
    if (isLoading) {
      setEnableDone(false);
      return;
    }
    if (autoFill) {
      setEnableDone(true);
      return;
    }
    if (!isDirty || !isValid || fieldErrors.length) {
      setEnableDone(false);
      return;
    }
    if (!isLoading) setEnableDone(!Object.keys(errors).length && Boolean(rows?.length));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, isLoading, errors, formState, fieldErrors, autoFill]);
  useEffect(() => {
    if (!rows?.length) {
      setEnableDone(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);
  useEffect(() => {
    if (isLoading) {
      setEnableDone(false);
      return;
    }
    if (autoFill && rows?.length && !isLoading) {
      clearErrors();
      setEnableDone(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoFill, rows, isLoading]);
};

export default useEnableDone;
