/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

import { Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import { useController } from 'react-hook-form';

// DOCUMENTATION:
// This component is designed to accept an optional prop options to render options to select to the screen.
// If you have an options object it needs to be formated in one of the two following ways
// 1. A simple array of strings (eg -> ['string 1', 'string 2'], etc)
// 2. An array of complex objects containing an object that has properties "label" and "id" (e.g. [{id: '123', label: 'SomeText'}])
// 3. No options will default to an empty array but, will allow new values (also as chips) to be types into the text field
// If you fail to do these things, the auto complete will have some funky styling issues when you go to select multiple chips, causing alot of problems.

const isDuplicate = (newSelectedOptions) => {
  const options = newSelectedOptions.map((item) => item.trim().toLowerCase());
  return new Set(options).size !== options.length;
};

const handleDelete = (value, index, onChange) => {
  const valueCopy = value;
  valueCopy.splice(index, 1);
  onChange(valueCopy);
};

const ControlledChipsAutoComplete = ({
  options = [],
  control,
  name,
  label,
  rules,
  defaultValue,
  style,
  sx,
  placeholder,
  color,
  chipSx,
  isDisabled,
  ignoreCaseAndTrailingSpaceOnSelect = false,
  ...props
}) => {
  const [inputValue, setInputValue] = React.useState('');

  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue, shouldUnregister: true });

  return (
    <OutlinedWrapper label={label} style={style} sx={sx} error={error} color={color}>
      <Autocomplete
        onChange={(e, newSelectedOptions) => {
          if (ignoreCaseAndTrailingSpaceOnSelect && isDuplicate(newSelectedOptions)) {
            newSelectedOptions.pop();
            onChange(newSelectedOptions);
            return;
          }

          onChange([
            ...newSelectedOptions.map((item) => {
              if (typeof item === 'string') return item.trim();
              return item;
            }),
          ]);

          setInputValue('');
        }}
        onBlur={() => {
          if (ignoreCaseAndTrailingSpaceOnSelect && isDuplicate([...value, inputValue.trim()])) {
            onChange([...value]);
            return;
          }

          if (inputValue !== '') {
            onChange([...value, inputValue.trim()]);
            setInputValue('');
          }
        }}
        renderTags={() => null}
        freeSolo
        multiple
        disablePortal
        disabled={isDisabled}
        autoHighlight
        options={options}
        isOptionEqualToValue={(option, value) => {
          if (option.id) return option.id === value.id;
          return option === value;
        }}
        sx={{ paddingTop: 0.5 }}
        value={value}
        inputValue={inputValue}
        renderInput={(params) => {
          const { inputProps, ...rest } = props;
          return (
            <TextField
              {...params}
              inputProps={{ ...params.inputProps, ...inputProps }}
              variant="standard"
              onChange={(e) => setInputValue(e.target.value)}
              fullWidth
              color={color}
              {...rest}
              sx={{ mb: 1 }}
              placeholder={placeholder}
            />
          );
        }}
      />
      {value?.map((option, index) => (
        <Chip
          // eslint-disable-next-line react/no-array-index-key
          key={`${index}-${label}`}
          style={{ margin: 8, ...chipSx }}
          label={option.label || option}
          disabled={isDisabled}
          onDelete={() => handleDelete(value, index, onChange)}
        />
      ))}
      {error && (
        <div>
          <Typography variant="caption">One or more entries are invalid</Typography>
        </div>
      )}
    </OutlinedWrapper>
  );
};

export default ControlledChipsAutoComplete;

// this must be defined on this level for disableCloseOnSelect to work
const InputComponent = React.forwardRef(({ ...other }, ref) => <div ref={ref} {...other} />);

const OutlinedWrapper = ({ children, label, style, error, color, sx }) => (
  <StyledTextField
    variant="outlined"
    label={label}
    error={!!error}
    multiline
    InputProps={{
      inputComponent: InputComponent,
    }}
    // eslint-disable-next-line react/jsx-no-duplicate-props
    inputProps={{ children }}
    color={color}
    style={{
      width: '100%',
      ...style,
    }}
    sx={sx}
    helperText={error?.message}
  />
);

const StyledTextField = ({ sx, ...props }) => (
  <TextField sx={{ '& .MuiOutlinedInput-root': { padding: '8px 16px' }, ...sx }} {...props} />
);
