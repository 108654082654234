/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

import { DeviceSizes } from 'constants/globalConstants';
import useDeviceSize from 'hooks/useDeviceSize';

import MobileErrorBackground from '../assets/images/backgrounds/ErrorPage-bg-mobile.png';
import TabletErrorBackground from '../assets/images/backgrounds/ErrorPage-bg-tablet.png';
import DesktopErrorBackground from '../assets/images/backgrounds/ErrorPage-bg.png';

const PageNotFound = () => {
  const { deviceSize } = useDeviceSize();
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  // eslint-disable-next-line no-console
  const goToHelpCenter = () => console.log('help');

  return (
    <PageNotFoundWrapper deviceSize={deviceSize}>
      <PageNotFoundBackground deviceSize={deviceSize} />
      <PageNotFoundContent deviceSize={deviceSize}>
        <ErrorTitle deviceSize={deviceSize} />
        <NotFoundText deviceSize={deviceSize} onClick={goToHelpCenter} />
        <BackButton onClick={goBack} />
      </PageNotFoundContent>
    </PageNotFoundWrapper>
  );
};

export default PageNotFound;

const PageNotFoundWrapper = ({ deviceSize, children }) => (
  <Stack
    sx={{
      height: 'calc(100%)',
      width: 1,
      position:
        deviceSize === DeviceSizes.tablet || deviceSize === DeviceSizes.mobile
          ? 'fixed'
          : 'inherit',
      left: 0,
    }}
  >
    {children}
  </Stack>
);

const PageNotFoundContent = ({ deviceSize, children }) => {
  const contentStyles = {
    position: 'absolute',
  };

  if (deviceSize === DeviceSizes.desktop) {
    contentStyles.top = '20%';
    contentStyles.right = '18%';
  }

  return (
    <Stack alignItems="center" width={1} sx={contentStyles}>
      {children}
    </Stack>
  );
};

const ErrorTitle = ({ deviceSize }) => {
  const getFontSize = () => {
    if (deviceSize === DeviceSizes.desktop) return '250px';
    if (deviceSize === DeviceSizes.tablet) return '200px';
    return '150px';
  };

  return (
    <Typography variant="h1" sx={{ fontSize: getFontSize(), fontWeight: 700, color: '#10143D' }}>
      404
    </Typography>
  );
};

const NotFoundText = ({ deviceSize, onClick }) => (
  <Typography
    variant="body2"
    align="center"
    sx={{ width: deviceSize === DeviceSizes.desktop ? '480px' : 3 / 5 }}
  >
    Uh oh, we can’t seem to find the page you’re looking for. Try going back to the previous page or
    see our{' '}
    <Typography
      variant="body2"
      onClick={onClick}
      sx={{ color: '#589CE4', display: 'inline', cursor: 'pointer' }}
    >
      Help Center
    </Typography>{' '}
    for more information.
  </Typography>
);

const BackButton = ({ onClick }) => (
  <Button
    variant="contained"
    onClick={onClick}
    sx={{ width: '250px', height: '40px', backgroundColor: '#10143D', mt: '55px' }}
  >
    GO BACK
  </Button>
);

const PageNotFoundBackground = ({ deviceSize }) => {
  if (deviceSize === DeviceSizes.desktop)
    return <img src={DesktopErrorBackground} alt="404 Page Not Found" height="100%" />;

  if (deviceSize === DeviceSizes.tablet)
    return <img src={TabletErrorBackground} alt="404 Page Not Found" height="100%" width="100%" />;

  if (deviceSize === DeviceSizes.mobile)
    return <img src={MobileErrorBackground} alt="404 Page Not Found" height="100%" />;

  return <p>Loading...</p>;
};
