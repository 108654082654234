import { useCallback, useEffect, useMemo, useState } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';

import { SelectedIntegrationItem } from 'modules/Materials/components/ItemsIntegrationModal/@types/itemsIntegrationModalContext';
import { useItemsIntegrationModalContext } from 'modules/Materials/components/ItemsIntegrationModal/context/ItemsIntegrationModalContext';

import { ItemsIntegrationItemType } from '../../../../@types/itemsIntegrationTableContext';
import { PBS_FIELD } from '../../../constants';
import { useItemsIntegrationTableContext } from '../../../context/ItemsIntegrationTableContext';
import { handleDeleteItem } from '../../../helpers/dataGridHelpers';
import useGetItemData from '../../../hooks/useGetItemData';
import { ProjectInfoType } from '../../ProjectRow/ProjectRow';

export const useItemRow = (selectedItem: SelectedIntegrationItem) => {
  const [item, setItem] = useState<ItemsIntegrationItemType | null>(null);
  const [showRows, setShowRows] = useState<boolean>(false);
  const [showDeleteButton, setShowDeleteButton] = useState<boolean>(false);
  const { getItemData } = useGetItemData({ selectedItem });
  const handleShowRows = useCallback(() => setShowRows(!showRows), [showRows]);
  const { selectedProject, setHoveredQtyField, setSelectedItems, selectedItemIdKey, isLoading } =
    useItemsIntegrationModalContext();
  const { setQuantityFields, setRows, setFieldErrors, isLoadingRows, rows } = useItemsIntegrationTableContext();

  const { control, trigger } = useFormContext();
  const projectBreakdownStructure = useWatch({ control, name: PBS_FIELD });
  const projectInfo = useMemo(
    () => ({
      identifier: selectedProject?.locationIdentifier,
      id: `${selectedProject?.locationIdentifier || ''}__${item?.id}`,
      locationName: selectedProject?.locationName,
      children: projectBreakdownStructure === 'Work Phase' ? item?.workPhases : item?.children,
    }),
    [item, selectedProject, projectBreakdownStructure],
  ) as ProjectInfoType;
  const methods = useFormContext();

  const handleDeleteItemCallback = useCallback(() => {
    handleDeleteItem(item?.id || '', {
      setQuantityFields,
      methods,
      setRows,
      setFieldErrors,
      setSelectedItems,
      selectedItemIdKey,
    });
    setShowRows(false);
    setHoveredQtyField('');
    trigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);
  const qtyFieldName = useMemo(() => `bomItem__${item?.id}`, [item]);

  const handleShowDeleteBtn = useCallback(
    (showBtn: boolean) => {
      if (isLoading || isLoadingRows) {
        setShowDeleteButton(false);
        return;
      }
      setShowDeleteButton(showBtn);
    },
    [isLoading, isLoadingRows],
  );

  const showExpandRowsArrow = useMemo(
    () =>
      !(!item?.children?.length && projectBreakdownStructure !== 'Work Phase') ||
      (projectBreakdownStructure === 'Work Phase' && !item?.workPhases?.length),
    [projectBreakdownStructure, item],
  );

  useEffect(() => {
    const handleItemData = async () => {
      const itemData = await getItemData();
      if (!itemData) return;
      if (!rows.find((row) => row.id === itemData.id))
        setRows((currentRows) => [...currentRows, itemData] as ItemsIntegrationItemType[]);
      setItem(itemData as ItemsIntegrationItemType);
    };
    handleItemData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!isLoading && !isLoadingRows) return;
    setShowDeleteButton(false);
  }, [isLoading, isLoadingRows]);

  return {
    qtyFieldName,
    item,
    showDeleteButton,
    showRows,
    projectInfo,
    showExpandRowsArrow,
    handleShowDeleteBtn,
    handleDeleteItemCallback,
    handleShowRows,
  };
};
