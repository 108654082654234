import { InputForesiteField } from '@evolvemep/foresite-components-ui-react';
import Box from '@mui/material/Box';

import DownloadZip from './DownloadZip';

const FormFields = ({ control, downloadPaths }) => (
  <>
    <Box width="65%">
      <InputForesiteField
        name="companyName"
        control={control}
        label="Company Name"
        size="small"
        readOnly
        color="secondary"
      />
    </Box>
    <Box mt={0} mb={2} alignContent="rt" width="35%">
      {!!downloadPaths?.length && <DownloadZip zips={downloadPaths} />}
    </Box>
  </>
);

export default FormFields;
