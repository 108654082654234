import React from 'react';

import { List, ListItem, Typography } from '@mui/material';

import { RECORDING_STATES } from 'constants/globalConstants';

import { useWorkOrderCancelModalContext } from './context/WorkOrderCancelModalContext';
import { useWorkOrderItemsContext } from './context/WorkOrderItemsContext';
import WorkOrderInProgressContent from './WorkOrderInProgressContent';

const confirmBoxStyles = (showStyles) =>
  showStyles
    ? {
        backgroundColor: '#FAFAFA',
        borderRadius: '4px',
        padding: '16px 0',
      }
    : {};

const WorkOrderModalContent = () => {
  const { workOrder } = useWorkOrderItemsContext();
  const { showCancelTable } = useWorkOrderCancelModalContext();

  const { workOrderName, workOrderStatusTypeName } = workOrder;
  return (
    <>
      <TextModal mb="16px">
        Canceling this work order will allow you to make changes and release it again when complete.
        {showCancelTable && ' You can also decide on how to handle any in progress tasks.'}
      </TextModal>
      <List
        dense
        sx={{
          backgroundColor: '#FAFAFA',
          borderRadius: '4px',
          padding: '20px 24px',
          '& li': { padding: '0px 8px' },
        }}
      >
        <Typography variant="subtitle2">Canceling will: </Typography>
        <ListItem dense>
          <Typography variant="body2">· Change the work order status to ‘Blocked’</Typography>
        </ListItem>
        {workOrderStatusTypeName !== RECORDING_STATES.Not_started && (
          <ListItem dense>
            <Typography variant="body2">· Pause in progress tasks</Typography>
          </ListItem>
        )}
        <ListItem dense>
          <Typography variant="body2">· Remove all tasks from their work cells</Typography>
        </ListItem>
      </List>
      <WorkOrderInProgressContent />
      <TextModal sx={{ margin: '16px 0', ...confirmBoxStyles(showCancelTable) }} align="center">
        Are you sure you want to cancel <b>{workOrderName}</b>?
      </TextModal>
    </>
  );
};

const TextModal = ({ children, ...props }) => (
  <Typography variant="body1" lineHeight="24px" {...props}>
    {children}
  </Typography>
);

export default React.memo(WorkOrderModalContent);
