import React from 'react';

import { Box, IconButton } from '@mui/material';

import { Custom } from 'components/Icons';
import OverFlowTooltip from 'components/Tooltip/OverFlowTooltip';
import { greyColors } from 'styles/theme/colors';

const NewRequestBtnCellResponsive = ({ checkProjectIdentifier, disabled }) => (
  <Box sx={{ marginLeft: '12px' }}>
    <OverFlowTooltip title="New work request" showAlways lowerTooltip>
      <IconButton
        sx={{
          color: greyColors.grey900,
          padding: '0px !important',
          height: '24px',
          display: 'flex',
          alignItems: 'center',
        }}
        onClick={() => checkProjectIdentifier()}
        disabled={disabled}
        data-cy="create-work-request"
      >
        <Custom.AddBox />
      </IconButton>
    </OverFlowTooltip>
  </Box>
);

export default NewRequestBtnCellResponsive;
