import React from 'react';

import Box from '@mui/material/Box';
import grey from '@mui/material/colors/grey';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';

export const StyledDropdownPaper = (props) => (
  <Paper
    sx={{
      marginTop: 1.8,
      color: 'white',
      backgroundColor: grey[800],
      '& .MuiAutocomplete-groupLabel': {
        fontSize: '20px',
        fontWeight: 500,
        textTransform: 'capitalize',
      },
      '& .MuiAutocomplete-option': {
        paddingLeft: '16px',
        '&:hover': {
          backgroundColor: '#656565 !important',
        },
      },
      "& .MuiAutocomplete-option[aria-selected='true']": {
        '&:hover': {
          backgroundColor: '#656565 !important',
        },
      },
    }}
    {...props}
  />
);

export const StyledPopper = (props) => (
  <Popper
    sx={{
      width: { xs: '100% !important', md: '336px !important' },
      marginRight: { xs: '-5px !important', md: '0 !important' },
      marginTop: { xs: '-4px !important', md: '0 !important' },
    }}
    {...props}
  />
);

export const GroupTitle = ({ children }) => (
  <Typography variant="h5" component="div" sx={{ textTransform: 'capitalize' }}>
    {children}
  </Typography>
);

export const GroupHeaderWrapper = ({ children }) => (
  <Box display="flex" justifyContent="space-between" padding="4px 16px" alignItems="center">
    {children}
  </Box>
);
