import { useFormContext } from 'react-hook-form';

import { TradeSizeInput } from '../../Form';
import { BOMCatalogTableFields, BOMCatalogTableHeaders, getItemHasParent } from './BOMTableColumnsHelpers';

export const getTradeSizeColumn = () => ({
  useForm: true,
  field: BOMCatalogTableFields.TRADE_SIZE,
  headerName: BOMCatalogTableHeaders[BOMCatalogTableFields.TRADE_SIZE],
  flex: 1.05,
  resizable: true,
  editable: true,
  renderCell: ({ row }) => <TradeSizeViewCell row={row} />,
  renderEditCell: () => <TradeSizeEditCell />,
});

const TradeSizeEditCell = () => {
  const { getValues } = useFormContext();

  const itemHasParent = getItemHasParent(getValues('parentLineItemId'));
  if (!itemHasParent) return <></>;
  return <TradeSizeInput />;
};

const TradeSizeViewCell = ({ row }) => {
  if (!row?.parentLineItem) return <></>;
  return <>{row?.tradeSize}</>;
};
