// this is a regular js function not a custom hook
// custom hooks cannot be called recursively

const deepMerge = (target, source) => {
  if (!target || !source) return null;

  const mergedObject = { ...(target || {}) };

  Object.keys(source).forEach((key) => {
    if (!Object.prototype.hasOwnProperty.call(source, key)) return;

    if (source[key] instanceof Object && !mergedObject[key]) mergedObject[key] = {};

    if (source[key] instanceof Object) mergedObject[key] = deepMerge(mergedObject[key], source[key]);

    if (!(source[key] instanceof Object)) mergedObject[key] = source[key];
  });

  return mergedObject;
};

export default deepMerge;
