import React, { useCallback } from 'react';

import { IconButton, Typography } from '@mui/material';

import { Custom } from 'components/Icons';
import CustomDetailPanelToggle from 'modules/Field/WorkRequests/WorkRequest/components/CustomDetailPanelToggle';

import { TaskProps } from '../types/TaskProps';

type handleCellComponentProps = {
  id: string;
  field: string;
  value: string;
  row: TaskProps;
};

export default function useRenderCell(
  handleFetchMore: () => void,
  tasks: Array<TaskProps>,
  handleOpenModalToDelete: (task: TaskProps) => void,
) {
  const handleCellComponent = useCallback(
    ({ id, field, value, row }: handleCellComponentProps) => {
      const isDisabled = !row.isEnabled;

      const opacity = isDisabled ? 0.5 : 1;

      if (field === '__detail_panel_toggle__') return <CustomDetailPanelToggle id={id} value={value} isDisabled />;

      if (field === 'delete')
        return (
          <IconButton className="delete-button" onClick={() => handleOpenModalToDelete(row)}>
            <Custom.Trash />
          </IconButton>
        );

      return (
        <Typography variant="body2" sx={{ opacity, overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {value}
        </Typography>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleFetchMore, tasks],
  );

  return {
    handleCellComponent,
  };
}

// type LinkProps = {
//   disabled: boolean;
//   children: any;
//   onClick: () => void;
// };

// const Link = ({ disabled, ...rest }: LinkProps) => (
//   <Typography
//     variant="body2"
//     sx={{
//       whiteSpace: 'nowrap',
//       overflow: 'hidden',
//       textOverflow: 'ellipsis',
//       cursor: 'pointer',
//       '&:hover': {
//         color: 'secondary.main',
//         textDecoration: !disabled ? 'underline' : 'none'
//       }
//     }}
//     {...rest}
//   />
// );

// const Icon = () => (
//   <Stack sx={{ mr: 1, flexDirection: 'row', alignItems: 'center' }}>{getNavIcon('WORK_ORDERS')}</Stack>
// );
