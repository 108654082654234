import { useDeleteModal } from 'modules/Field/LocationsAndWorkPhases/contexts/DeleteModalContext';

import { createOption } from './moreMenuHelpers';

export const useWorkPhaseOptions = (node) => {
  const { openDeleteLocationModal } = useDeleteModal();

  const getWorkPhaseOptions = () => [
    createOption('Unmap work phase', () => openDeleteLocationModal(node, 'unmap')),
  ];

  return { getWorkPhaseOptions };
};
