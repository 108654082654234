import React, { useState, useEffect } from 'react';

import getClient from 'apollo/client';
import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import FilterButton from 'components/Buttons/FilterButton';
import { usePopoverFilterContext } from 'components/Popover/FilterPopover/context/PopoverFilterContext';
import useFetchWorkOrders from 'hooks-api/useFetchWorkOrders';
import useDocumentTypes from 'modules/Shop/Fabrication/TaskViewer/PlansModelsContent/hooks/useDocumentTypes';
import { shopColors } from 'styles/theme/colors';

import { useWorkOrdersContext } from '../WorkOrdersContext';
import { formatWorkOrdersToColumns } from './helpers/filterHelpers';
import WorkOrdersFilterPopover from './WorkOrdersFilterPopover';

const WorkOrdersFilter = ({ disabled }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { setColumns, columnsBackup, clearFilters } = usePopoverFilterContext();
  const { loading, workOrders, getWorkOrders } = useFetchWorkOrders();
  const { selectedItem } = useFacilitiesProjects();
  const { filters: basicFilters, workOrders: workOrdersFromTable } = useWorkOrdersContext();
  const { commonTypeId } = useDocumentTypes();

  useEffect(() => {
    if (!commonTypeId) return;

    const fetchWorkOrders = async (key, id) => {
      const client = await getClient();
      client.cache.evict({
        id: 'ROOT_QUERY',
        fieldName: 'workOrders',
      });
      await getWorkOrders({
        variables: {
          query: {
            take: 50,
            [key]: id,
            ...basicFilters,
            orderBy: 'needBy:asc',
            documentTypeIds: commonTypeId,
          },
        },
      });
    };

    if (selectedItem?.type === 'PROJECT' && basicFilters) {
      clearFilters();
      fetchWorkOrders('projectId', selectedItem?.id);
    }
    if (selectedItem?.type === 'FACILITY' && basicFilters) {
      clearFilters();
      fetchWorkOrders('facilityId', selectedItem?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, basicFilters, commonTypeId]);

  useEffect(() => {
    if (workOrders.length > 0 && workOrdersFromTable.length > 0) {
      setColumns(formatWorkOrdersToColumns(workOrders, workOrdersFromTable, columnsBackup));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setColumns, workOrders, workOrdersFromTable, columnsBackup]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'work-order-filter-popover' : undefined;

  return (
    <>
      <FilterButton id={id} onClick={handleClick} disabled={disabled || loading} color={shopColors.dark} />
      <WorkOrdersFilterPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        id={id}
        sx={{
          color: 'secondary.dark',
          '&:hover': { backgroundColor: 'secondary.outlinedHoverBackground' },
        }}
      />
    </>
  );
};

export default WorkOrdersFilter;
