import { useState } from 'react';

import useUserById from 'modules/Shop/Fabrication/TaskViewer/TaskWorkOrderWorkRequest/components/FileRowComponent/hooks/useUserById';

interface ICachedUserNames {
  [key: string]: ICompleteName;
}

interface ICompleteName {
  completeName: string;
}

const useUserNames = () => {
  const [cachedUserData, setCachedUserData] = useState<ICachedUserNames>({});
  const { getUserById } = useUserById();

  const getUserData = async (userId: string) => {
    if (cachedUserData[userId]) return cachedUserData[userId];
    return getUserById(userId);
  };

  const getUsersData = async (userIds: string[]) => {
    let usersData = await Promise.all(
      userIds.map(async (userId) => {
        try {
          if (!userId) return null;
          const user = await getUserData(userId);
          return { ...user, userId };
        } catch {
          return null;
        }
      }),
    );

    usersData = usersData.filter((file) => Boolean(file));

    const cachedData: ICachedUserNames = {};

    usersData.forEach((user) => {
      if (user) cachedData[user.userId] = user;
    });

    setCachedUserData(cachedData);
    return { usersData, cachedData };
  };

  return {
    getUsersData,
    cachedUserData,
  };
};

export default useUserNames;
