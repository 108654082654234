/* eslint-disable max-lines-per-function */
import React, { useEffect, useMemo, useState } from 'react';

import { v4 as uuidv4 } from 'uuid';

import {
  addChildrenRecursively,
  findNode,
  findParent,
  recursiveToggleKey,
  recursiveUpdateNode,
  removeChildrenRecursively,
  replaceItemsById,
  clone,
  getNumberOfOccurrences,
  findFirstOccurrence,
  removeOccurrencesRecursively,
  getOccurrences,
} from 'helpers/arrayFunctions';

import { buildChipTemplate, MAX_CHIP_DEPTH, ROW_TYPES } from '../../constants/constants';
import { useLocationsAndWorkPhases } from '../../contexts/LocationsAndWorkPhaseContext';
import { appendNewChild } from '../helpers/tableRowHelpers';

const ChipsContext = React.createContext();

const ChipsProvider = ({ children }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [chips, setChips] = useState([]);
  const [activeChip, setActiveChip] = useState({});
  const [activeChipInStep2, setActiveChipInStep2] = useState({});
  const [formIsValid, setFormIsValid] = useState([]);
  const [scopePackages, setScopePackages] = useState([]);
  const [parentActiveChip, setParentActiveChip] = useState({});
  const [hasNotWorkPhases, setHasNotWorkPhases] = useState(false);
  const [showForm, setShowForm] = useState(true);

  const {
    workPhases: { tableRows },
  } = useLocationsAndWorkPhases();

  const numberOfWorkPhases = useMemo(
    () => getNumberOfOccurrences('locationTypeName', ROW_TYPES.WORK_PHASE, chips),
    [chips],
  );

  const toggleOpenChip = (node) => setChips(recursiveToggleKey(node.id, chips, 'isOpen'));

  const updateChipWithFormValues = (form) => setChips(recursiveUpdateNode(form, chips));

  const addSubLocation = (activeStep, addSibling) => {
    const isWorkPhase = activeStep === 1;
    const activeForm = activeChipInStep2.id ? activeChipInStep2 : activeChip;
    const targetParent = addSibling ? parentActiveChip : activeForm;

    const newChild = {
      ...buildChipTemplate(isWorkPhase),
      depth: targetParent.depth + 1,
      ascendantIsFloor: activeChip.ascendantIsFloor,
    };
    setChips(addChildrenRecursively(targetParent, clone(chips), newChild));
    setActiveChip(newChild);
    setActiveChipInStep2({});
  };

  const handleResetStepper = () => {
    setActiveStep(0);
    setActiveChipInStep2({});
    setCompleted({});
  };

  const goToStep = (step, callback = () => {}) => {
    setTimeout(() => {
      callback();
      setActiveStep(step);
    }, 0);
  };

  const deleteWorkPhase = (id) => {
    // delete the only existing work phase
    if (numberOfWorkPhases === 1) {
      const parent = findParent(id, chips);
      setChips(removeChildrenRecursively(id, chips));
      goToStep(0, () => setActiveChip(parent));
      return;
    }

    // delete a work phase and setActive the new work phase first occurrence
    setChips(removeChildrenRecursively(id, chips));
    if (activeChip.id === id)
      setActiveChip(findFirstOccurrence('locationTypeName', ROW_TYPES.WORK_PHASE, chips));
  };

  const deleteChip = ({ id, locationTypeName }) => {
    // delete first chip
    if (id === chips[0].id) {
      const newDraft = buildChipTemplate();
      setChips([newDraft]);
      setActiveChip(newDraft);
      return setTimeout(() => {
        handleResetStepper();
      }, 0);
    }

    // delete any chip in "Locations" step
    if (activeStep !== 1) {
      setChips(removeChildrenRecursively(id, chips));
      return setActiveChip(chips[0]);
    }

    // delete a work phase on "Work Phase" step
    if (locationTypeName === ROW_TYPES.WORK_PHASE) return deleteWorkPhase(id);

    // delete a location on "Work Phase" step
    const updatedChips = removeChildrenRecursively(id, chips);
    const firstWorkPhase = findFirstOccurrence('locationTypeName', ROW_TYPES.WORK_PHASE, chips);
    setChips(updatedChips);
    if (firstWorkPhase) return setActiveChip(firstWorkPhase);
    return goToStep(0, () => setActiveChip(updatedChips[0]));
  };

  const addManualWorkPhase = (label, scopePackageId) => {
    const newWorkPhase = { label, id: uuidv4(), isNew: true };
    const selectedScopePackage = findNode(scopePackageId, scopePackages);
    const updatedWorkPhaseList = appendNewChild(selectedScopePackage, newWorkPhase);
    setScopePackages(replaceItemsById(updatedWorkPhaseList, scopePackages));
  };

  const addToLocations = (ascendantIsFloor) => {
    const currentActiveChip = findNode(activeChip.id, chips);
    currentActiveChip.isDraft = false;
    currentActiveChip.ascendantIsFloor =
      ascendantIsFloor ||
      currentActiveChip.locationTypeName === ROW_TYPES.FLOOR ||
      currentActiveChip.ascendantIsFloor;

    setChips((prevChips) => recursiveUpdateNode(currentActiveChip, prevChips));
    setActiveChip(currentActiveChip);
  };

  const getDraftWorkPhases = () => {
    const workPhases = getOccurrences('locationTypeName', ROW_TYPES.WORK_PHASE, chips);
    return (workPhases || []).filter((workPhase) => workPhase.isDraft);
  };

  const handleNextStep = () => {
    if (activeStep === 0) {
      if (activeChip.locationTypeName !== ROW_TYPES.LOCATION_SYSTEM)
        addSubLocation(1, activeChip.depth === MAX_CHIP_DEPTH);
      setActiveStep(activeStep + 1);
    }
  };

  const handleNext = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNextStep();
  };

  const removeUnsavedWorkPhases = () => {
    let clonedChips = clone(chips);
    const draftWorkPhases = getDraftWorkPhases();
    draftWorkPhases.forEach((draftWorkPhase) => {
      clonedChips = removeChildrenRecursively(draftWorkPhase.id, clonedChips);
    });
    setChips(clonedChips);
    setActiveChip(clonedChips[0]);
  };

  const handleChangeActiveStep = (step) => {
    setActiveChipInStep2({});
    if (step === 0) return goToStep(0, () => removeUnsavedWorkPhases());
    if (step === 1) {
      const firstWorkPhase = findFirstOccurrence('locationTypeName', ROW_TYPES.WORK_PHASE, chips);
      if (firstWorkPhase) setActiveChip(firstWorkPhase);
      else addSubLocation(1, activeChip.depth === MAX_CHIP_DEPTH);
    }

    return goToStep(step);
  };

  const deleteNewWorkPhases = () => {
    const scopesWithoutNewWorkPhases = removeOccurrencesRecursively(scopePackages, 'isNew', true);
    setScopePackages(scopesWithoutNewWorkPhases);
  };

  useEffect(() => {
    if (!showForm)
      setTimeout(() => {
        setShowForm(true);
      }, 0);
  }, [showForm]);

  useEffect(() => {
    if (activeChip) setParentActiveChip(findParent(activeChip.id, chips));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChip]);

  useEffect(() => {
    if (tableRows) setScopePackages(tableRows[0].children);
  }, [tableRows]);

  const valueObj = {
    chips,
    activeChip,
    parentActiveChip,
    setChips,
    setActiveChip,
    toggleOpenChip,
    updateChipWithFormValues,
    addSubLocation,
    deleteChip,
    formIsValid,
    setFormIsValid,
    scopePackages,
    addManualWorkPhase,
    hasNotWorkPhases,
    setHasNotWorkPhases,
    addToLocations,
    activeStep,
    setActiveStep,
    completed,
    handleResetStepper,
    handleNext,
    activeChipInStep2,
    setActiveChipInStep2,
    handleChangeActiveStep,
    deleteNewWorkPhases,
    goToStep,
    showForm,
    setShowForm,
  };

  return <ChipsContext.Provider value={valueObj}>{children}</ChipsContext.Provider>;
};

const useChips = () => {
  const context = React.useContext(ChipsContext);
  if (context === undefined) {
    throw new Error('useChips must be used within a ChipsContext');
  }
  return context;
};

export { ChipsContext, ChipsProvider, useChips };
