import React, { useMemo } from 'react';

import { useItemsIntegrationModalContext } from 'modules/Materials/components/ItemsIntegrationModal/context/ItemsIntegrationModalContext';

import TableRowsWrapper from '../../../TableRowsWrapper/TableRowsWrapper';
import ItemsIntegrationHeaders from './components/ItemsIntegrationHeaders/ItemsIntegrationHeaders';
import ItemsIntegrationRow from './components/ItemsIntegrationRow/ItemsIntegrationRow';
import TableWrapper from './components/TableWrapper/TableWrapper';
import { useItemsIntegrationTableContext } from './context/ItemsIntegrationTableContext';
import useItemsIntegrationTableContextEffectsHandler from './hooks/useItemsIntegrationTableContextEffectsHandler';

const ItemsIntegrationTable = () => {
  const { selectedItems, isLoading } = useItemsIntegrationModalContext();
  const { isLoadingRows } = useItemsIntegrationTableContext();
  const loading = useMemo(() => isLoading || isLoadingRows, [isLoading, isLoadingRows]);
  useItemsIntegrationTableContextEffectsHandler();

  if (!selectedItems.length) return 'No rows';

  return (
    <TableWrapper loading={loading}>
      <ItemsIntegrationHeaders />
      <TableRowsWrapper>
        {selectedItems.map((selectedItem) => (
          <ItemsIntegrationRow key={selectedItem.lineItemId} selectedItem={selectedItem} />
        ))}
      </TableRowsWrapper>
    </TableWrapper>
  );
};

export default ItemsIntegrationTable;
