import { useGridApiContext } from '@mui/x-data-grid-pro';

import useExpandedIconColor from 'hooks/useExpandedIconColor';

type Props = {
  id: string;
};

const useIconColorForDataGridTable = ({ id }: Props) => {
  const apiRef = useGridApiContext();
  const expanded = apiRef?.current?.getExpandedDetailPanels().includes(id);
  const getIconColor = useExpandedIconColor();

  return { iconColor: getIconColor(expanded) };
};

export default useIconColorForDataGridTable;
