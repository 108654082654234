import React, { useEffect, useRef } from 'react';

import { InputForesiteField } from '@evolvemep/foresite-components-ui-react';
import Box from '@mui/material/Box';

const DrawingControlDocumentField = ({ autoFocus, ...restProps }) => {
  const textInput = useRef(null);

  useEffect(() => {
    if (autoFocus) {
      textInput.current.focus();
    }
  }, [autoFocus]);

  return (
    <Box sx={{ width: '70%', '.MuiFormControl-root': { width: '80%', margin: 0 } }}>
      <InputForesiteField
        inputRef={textInput}
        size="small"
        color="secondary"
        sx={{
          '.MuiOutlinedInput-root': { padding: 0 },
          '.MuiOutlinedInput-input': { padding: '2px 12px !important' },
        }}
        autoComplete="off"
        {...restProps}
      />
    </Box>
  );
};

export default DrawingControlDocumentField;
