import React, { useEffect } from 'react';

import { gql, useMutation } from '@apollo/client';
import { ForesiteTextField } from '@evolvemep/foresite-components-ui-react';
import { Stack, useTheme } from '@mui/material';
import { useForm, useController } from 'react-hook-form';

import { useUser } from 'app/UserContext';
import { Custom } from 'components/Icons';
import { updateAssemblyCustomCategory as UPDATE_CUSTOM_ASSEMBLY } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

import { useCustomAssemblyTypesContext } from '../CustomAssemblyTypesContext';
import { useCustomAssemblyTypes } from '../hooks/useCustomAssemblyTypes';
// eslint-disable-next-line
const RenameCustomAssemblyForm = () => {
  const { customAssemblyTypes, setCustomAssemblyTypes, currentRow, setRenamingId, setErrorInForm } =
    useCustomAssemblyTypesContext();
  const { customAssemblyTypesQuery, customAssemblyTypesData, loadingCustomAssemblyTypes } =
    useCustomAssemblyTypes();

  const { handleResponse } = useGraphqlResponseHandler();
  const { control, setError, clearErrors } = useForm({ mode: 'all' });
  const { palette } = useTheme();
  const { user } = useUser();
  const names = [
    'unassigned',
    ...customAssemblyTypes.map(
      (element) => element.type === 'CustomAssemblyType' && element.identifier.trim().toLowerCase(),
    ),
  ];

  const rules = {
    required: '*Required',
    maxLength: { value: 63, message: '64/64' },
    validate: (customAssemblyName) => {
      customAssemblyTypesQuery({
        variables: { query: { companyId: user.companyId, name: customAssemblyName } },
      });
      if (names.includes(customAssemblyName.trim().toLowerCase())) {
        return 'Name already exists';
      }
      const search = { index: -1 };
      const temp = [...customAssemblyTypes];
      customAssemblyTypes.forEach((item, index) => {
        if (item.id === currentRow.id) {
          search.index = index;
        }
      });
      temp[search.index].identifier = customAssemblyName;
      setCustomAssemblyTypes(temp);
      return true;
    },
  };
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name: 'updateCustomAssemblyName',
    control,
    rules,
    defaultValue: currentRow?.identifier,
  });

  const [updateCustomAssembly, { loading }] = useMutation(gql(UPDATE_CUSTOM_ASSEMBLY), {
    refetchQueries: ['AssemblyCustomCategories'],
  });

  const submit = async () => {
    if (!error && !customAssemblyTypesData?.assemblyCustomCategories.length) {
      await handleResponse(
        updateCustomAssembly,
        {
          variables: {
            params: {
              assemblyCustomCategoryId: currentRow.id,
            },
            body: { assemblyCustomCategoryName: value },
          },
        },
        { successMessage: 'Assembly type successfully updated' },
      );
      setRenamingId(null);
    }
  };

  const keyDownHandler = (event) => {
    event.stopPropagation();
    if (event.key === 'Enter') {
      submit();
    }
  };

  useEffect(() => {
    if (customAssemblyTypesData?.assemblyCustomCategories.length) {
      setError('updateCustomAssemblyName', { type: 'manual', message: 'Name already exists' });
    }
  }, [customAssemblyTypesData, loadingCustomAssemblyTypes, setError, clearErrors]);

  useEffect(
    () => setErrorInForm(Boolean(error)),
    [error, setErrorInForm, loadingCustomAssemblyTypes, value],
  );

  return (
    <>
      <Stack direction="row" alignItems="center" sx={{ paddingLeft: '50px' }}>
        <Custom.Close
          fill={palette.action.active}
          onClick={() => setRenamingId(null)}
          data-testid="close-button"
        />
        <ForesiteTextField
          margin="none"
          width="430px"
          value={value}
          onBlur={() => submit()}
          variant="outlined"
          disabled={loading}
          onChange={onChange}
          applyModuleColorAsDefault
          onKeyDown={keyDownHandler}
          error={Boolean(error)}
          size="extra-small"
          inputProps={{ maxLength: 64, 'data-cy': 'RenameAssemblyTypeForm' }}
          helperText={error?.message || ''}
          sx={{
            '& .MuiFormHelperText-root': {
              textAlign: error?.type === 'maxLength' ? 'end' : '',
              margin: '0',
              padding: '0 10px 0 10px',
              lineHeight: '16px',
              maxHeight: '14px',
            },
          }}
        />
      </Stack>
    </>
  );
};

export default RenameCustomAssemblyForm;
