/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type MutationAddAssemblyQueryInput = {
  partId?: string | null;
  nodes?: Array<AssemblyNodeInput | null> | null;
  edges?: Array<AssemblyEdgeInput | null> | null;
};

export type AssemblyNodeInput = {
  referenceId: string;
  assemblyNodeTypeId: string;
  assemblyNodeName: string;
  assemblyNodeDescription?: string | null;
  positionX?: number | null;
  positionY?: number | null;
  quantity?: number | null;
  partId?: string | null;
  shopTaskId?: string | null;
};

export type AssemblyEdgeInput = {
  beginNodeReferenceId?: string | null;
  endNodeReferenceId?: string | null;
  assemblyEdgeTypeId: string;
  beginHandlePositionId?: string | null;
  endHandlePositionId?: string | null;
};

export type MutationAddAssemblyBodyInput = {
  partId?: string | null;
  nodes?: Array<AssemblyNodeInput | null> | null;
  edges?: Array<AssemblyEdgeInput | null> | null;
};

export type Assembly = {
  __typename: 'Assembly';
  part?: Part | null;
  assemblyId?: string | null;
  historyTypeId?: string | null;
  assemblyNodes?: Array<AssemblyNode | null> | null;
  assemblyEdges?: Array<AssemblyEdge | null> | null;
};

export type Part = {
  __typename: 'Part';
  partId: string;
  partName: string;
  description?: string | null;
  stateId?: string | null;
  isDefault?: boolean | null;
  manufacturerCatalogNumber?: string | null;
  unitOfMeasureCode?: string | null;
  unitOfMeasureName?: string | null;
  upcCode?: string | null;
  url?: string | null;
  catalogUrl?: string | null;
  partUrl?: string | null;
  internalPartNumber?: string | null;
  createdOn?: string | null;
  hasAssembly?: boolean | null;
  manufacturer?: Manufactured | null;
  attriubuteVariantData?: Array<PartAttributeVariantData | null> | null;
  partCategory?: PartCategory | null;
  partImages?: Array<PartImage | null> | null;
  documentIds?: Array<string | null> | null;
  unitOfMeasure?: UnitOfMeasure | null;
};

export type Manufactured = {
  __typename: 'Manufactured';
  manufacturerId: string;
  companyId?: string | null;
  manufacturerName: string;
  homePageUrl?: string | null;
};

export type PartAttributeVariantData = {
  __typename: 'PartAttributeVariantData';
  partAttributeVariantDataId?: string | null;
  partAttributeId?: string | null;
  partAttributeName?: string | null;
  rangeValue?: number | null;
  part?: Part | null;
  partAttribute?: PartAttribute | null;
  selectValues?: Array<PartAttributeSelectVariant | null> | null;
};

export type PartAttribute = {
  __typename: 'PartAttribute';
  partAttributeId: string;
  partAttributeName: string;
  allowWriteInVariant?: boolean | null;
  rangeMinimumValue?: number | null;
  rangeMaximumValue?: number | null;
  selectVariants?: Array<PartAttributeSelectVariant | null> | null;
  partAttributeType?: PartAttributeType | null;
  partCategory?: PartCategory | null;
};

export type PartAttributeSelectVariant = {
  __typename: 'PartAttributeSelectVariant';
  partAttributeSelectVariantId?: string | null;
  textValue?: string | null;
  codeValue?: string | null;
  isDeleted?: boolean | null;
};

export type PartAttributeType = {
  __typename: 'PartAttributeType';
  partAttributeTypeId: string;
  partAttributeTypeName: string;
  partAttributeTypeDescription?: string | null;
  sortOrder?: number | null;
};

export type PartCategory = {
  __typename: 'PartCategory';
  partCategoryId?: string | null;
  partCategoryName?: string | null;
  partCatalog?: PartCatalog | null;
  parentPartCategory?: PartCategory | null;
  subNodes?: Array<PartCategory | null> | null;
};

export type PartCatalog = {
  __typename: 'PartCatalog';
  partCatalogId: string;
  partCatalogName: string;
  companyId: string;
  topLevelNodes?: Array<PartCategory | null> | null;
};

export type PartImage = {
  __typename: 'PartImage';
  partImageId?: string | null;
  sortOrder?: number | null;
  isDefault?: boolean | null;
  document?: Document | null;
  part?: Part | null;
};

export type Document = {
  __typename: 'Document';
  documentId: string;
  documentName?: string | null;
  documentIdentifier?: string | null;
  documentTypeId?: string | null;
  storagePath?: string | null;
  extension?: string | null;
  encodedUrn?: string | null;
  documentStatusTypeId?: string | null;
  isMappedFromShop?: boolean | null;
  folderDocumentResponse?: Array<FolderDocument | null> | null;
  documentNameFormatted?: string | null;
  createdOn?: string | null;
  createdBy?: string | null;
};

export type FolderDocument = {
  __typename: 'FolderDocument';
  folderDocumentId: string;
  folderId: string;
  folderName?: string | null;
  documentId: string;
};

export type UnitOfMeasure = {
  __typename: 'UnitOfMeasure';
  unitOfMeasureId: string;
  unitOfMeasureTypeId?: string | null;
  companyId?: string | null;
  unitOfMeasureName: string;
  unitOfMeasureCode: string;
  createdOn?: string | null;
  createdBy?: string | null;
  updatedOn?: string | null;
  modifiedBy?: string | null;
  modifiedOn?: string | null;
};

export type AssemblyNode = {
  __typename: 'AssemblyNode';
  quantity?: number | null;
  part?: Part | null;
  shopTaskId?: string | null;
  assemblyNodeId?: string | null;
  historyTypeId?: string | null;
  assembly?: Assembly | null;
  assemblyNodeType?: AssemblyNodeType | null;
  assemblyNodeName?: string | null;
  assemblyNodeDescription?: string | null;
  positionX?: number | null;
  positionY?: number | null;
};

export type AssemblyNodeType = {
  __typename: 'AssemblyNodeType';
  assemblyNodeTypeId?: string | null;
  assemblyNodeTypeName?: string | null;
};

export type AssemblyEdge = {
  __typename: 'AssemblyEdge';
  assemblyEdgeId?: string | null;
  historyTypeId?: string | null;
  assembly?: Assembly | null;
  assemblyEdgeType?: AssemblyEdgeType | null;
  beginHandlePosition?: AssemblyHandlePosition | null;
  endHandlePosition?: AssemblyHandlePosition | null;
  beginNode?: AssemblyNode | null;
  endNode?: AssemblyNode | null;
};

export type AssemblyEdgeType = {
  __typename: 'AssemblyEdgeType';
  assemblyEdgeTypeId?: string | null;
  assemblyEdgeTypeName?: string | null;
};

export type AssemblyHandlePosition = {
  __typename: 'AssemblyHandlePosition';
  assemblyHandlePositionId?: string | null;
  assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
};

export enum ASSEMBLY_HANDLE_POSITION_NAME {
  Right = 'Right',
  Top = 'Top',
  Bottom = 'Bottom',
  Left = 'Left',
}

export type MutationUpdateAssemblyQueryInput = {
  assemblyId?: string | null;
  nodes?: Array<AssemblyNodeInput | null> | null;
  edges?: Array<AssemblyEdgeInput | null> | null;
  addNodes?: Array<AssemblyNodeInput | null> | null;
  addEdges?: Array<AssemblyEdgeInput | null> | null;
  updateNodes?: Array<UpdateNodeInAssemblyInput | null> | null;
  updateEdges?: Array<AssemblyEdgeInput | null> | null;
  deleteNodes?: Array<string | null> | null;
  deleteEdges?: Array<string | null> | null;
};

export type UpdateNodeInAssemblyInput = {
  assemblyNodeId: string;
  assemblyNodeName?: string | null;
  assemblyNodeDescription?: string | null;
  positionX?: number | null;
  positionY?: number | null;
  quantity?: number | null;
  partId?: string | null;
  shopTaskId?: string | null;
};

export type MutationUpdateAssemblyBodyInput = {
  assemblyId?: string | null;
  nodes?: Array<AssemblyNodeInput | null> | null;
  edges?: Array<AssemblyEdgeInput | null> | null;
  addNodes?: Array<AssemblyNodeInput | null> | null;
  addEdges?: Array<AssemblyEdgeInput | null> | null;
  updateNodes?: Array<UpdateNodeInAssemblyInput | null> | null;
  updateEdges?: Array<AssemblyEdgeInput | null> | null;
  deleteNodes?: Array<string | null> | null;
  deleteEdges?: Array<string | null> | null;
};

export type MutationDeleteAssemblyParamsInput = {
  assemblyId: string;
};

export type MutationAddAssemblyNodeQueryInput = {
  assemblyBaseId?: string | null;
  assemblyNodeTypeId?: string | null;
  assemblyNodeName?: string | null;
  assemblyNodeDescription?: string | null;
  positionX?: number | null;
  positionY?: number | null;
  quantity?: number | null;
  partId?: string | null;
  shopTaskId?: string | null;
};

export type MutationAddAssemblyNodeBodyInput = {
  assemblyBaseId?: string | null;
  assemblyNodeTypeId?: string | null;
  assemblyNodeName?: string | null;
  assemblyNodeDescription?: string | null;
  positionX?: number | null;
  positionY?: number | null;
  quantity?: number | null;
  partId?: string | null;
  shopTaskId?: string | null;
};

export type MutationUpdateAssemblyNodeParamsInput = {
  assemblyNodeID: string;
};

export type MutationUpdateAssemblyNodeQueryInput = {
  assemblyNodeName?: string | null;
  assemblyNodeDescription?: string | null;
  positionX?: number | null;
  positionY?: number | null;
  quantity?: number | null;
  partId?: string | null;
  shopTaskId?: string | null;
};

export type MutationUpdateAssemblyNodeBodyInput = {
  assemblyNodeName?: string | null;
  assemblyNodeDescription?: string | null;
  positionX?: number | null;
  positionY?: number | null;
  quantity?: number | null;
  partId?: string | null;
  shopTaskId?: string | null;
};

export type MutationDeleteAssemblyNodeParamsInput = {
  assemblyNodeID: string;
};

export type MutationAddAssemblyEdgeQueryInput = {
  assemblyBaseId?: string | null;
  beginNodeId?: string | null;
  endNodeId?: string | null;
  assemblyEdgeTypeId?: string | null;
  beginHandlePositionId?: string | null;
  endHandlePositionId?: string | null;
};

export type MutationAddAssemblyEdgeBodyInput = {
  assemblyBaseId?: string | null;
  beginNodeId?: string | null;
  endNodeId?: string | null;
  assemblyEdgeTypeId?: string | null;
  beginHandlePositionId?: string | null;
  endHandlePositionId?: string | null;
};

export type MutationUpdateAssemblyEdgeParamsInput = {
  assemblyEdgeID: string;
};

export type MutationUpdateAssemblyEdgeQueryInput = {
  beginNodeId?: string | null;
  endNodeId?: string | null;
  assemblyEdgeTypeId?: string | null;
  beginHandlePositionId?: string | null;
  endHandlePositionId?: string | null;
};

export type MutationUpdateAssemblyEdgeBodyInput = {
  beginNodeId?: string | null;
  endNodeId?: string | null;
  assemblyEdgeTypeId?: string | null;
  beginHandlePositionId?: string | null;
  endHandlePositionId?: string | null;
};

export type MutationDeleteAssemblyEdgeParamsInput = {
  assemblyEdgeID: string;
};

export type MutationAddLocationQueryInput = {
  parentLocationId?: string | null;
  projectId?: string | null;
  locationTypeId?: string | null;
  locationIdentifier?: string | null;
  locationName?: string | null;
  sortOrder?: number | null;
};

export type MutationAddLocationBodyInput = {
  parentLocationId?: string | null;
  projectId?: string | null;
  locationTypeId?: string | null;
  locationIdentifier?: string | null;
  locationName?: string | null;
  sortOrder?: number | null;
};

export type Location = {
  __typename: 'Location';
  locationId?: string | null;
  locationTypeId?: string | null;
  locationTypeName?: string | null;
  projectId?: string | null;
  locationIdentifier?: string | null;
  locationCompositeIdentifier?: string | null;
  sortOrder?: number | null;
  hasChildren?: boolean | null;
  parentLocationId?: string | null;
  locationName?: string | null;
  locationCompositeName?: string | null;
  locationPhases?: Array<LocationPhase | null> | null;
  childrenTypeName?: string | null;
  movedLocationPhases?: Array<UpdatedLocationPhase | null> | null;
};

export type LocationPhase = {
  __typename: 'LocationPhase';
  locationPhaseId: string;
  locationId?: string | null;
  workPhaseId: string;
  workPhaseName: string;
  scopePackageId: string;
  scopePackageName: string;
  sortOrder: number;
};

export type UpdatedLocationPhase = {
  __typename: 'UpdatedLocationPhase';
  workPhaseId: string;
  locationPhaseId: string;
};

export type MutationAddScopePackageQueryInput = {
  projectId?: string | null;
  scopePackageName?: string | null;
  scopePackageIdentifier?: string | null;
};

export type MutationAddScopePackageBodyInput = {
  projectId?: string | null;
  scopePackageName?: string | null;
  scopePackageIdentifier?: string | null;
};

export type ScopePackage = {
  __typename: 'ScopePackage';
  projectId: string;
  scopePackageId: string;
  scopePackageName: string;
  scopePackageIdentifier: string;
  sortOrder: number;
  hasWorkPhases?: boolean | null;
};

export type MutationAddWorkPhaseQueryInput = {
  projectId?: string | null;
  scopePackageId?: string | null;
  workPhaseName?: string | null;
  sortOrder?: number | null;
  workPhaseIdentifier?: string | null;
};

export type MutationAddWorkPhaseBodyInput = {
  projectId?: string | null;
  scopePackageId?: string | null;
  workPhaseName?: string | null;
  sortOrder?: number | null;
  workPhaseIdentifier?: string | null;
};

export type WorkPhase = {
  __typename: 'WorkPhase';
  workPhaseId: string;
  workPhaseIdentifier: string;
  workPhaseName: string;
  sortOrder: number;
  scopePackageId: string;
  scopePackageName?: string | null;
  projectId: string;
  hasLocationPhases?: boolean | null;
  updatedWorkPhaseLocationPhases?: Array<MappedLocation | null> | null;
};

export type MappedLocation = {
  __typename: 'MappedLocation';
  locationId: string;
  locationPhaseId: string;
};

export type MutationAddPartAttributeQueryInput = {
  partCatalogId?: string | null;
  partCategoryId?: string | null;
  partAttributeTypeId?: string | null;
  partAttributeName?: string | null;
  allowWriteInVariant?: boolean | null;
  rangeMinimumValue?: number | null;
  rangeMaximumValue?: number | null;
  addPartAttributeSelectVariantRequests?: Array<AddPartAttributeSelectVariantInput | null> | null;
};

export type AddPartAttributeSelectVariantInput = {
  textValue?: string | null;
  codeValue?: string | null;
};

export type MutationAddPartAttributeBodyInput = {
  partCatalogId?: string | null;
  partCategoryId?: string | null;
  partAttributeTypeId?: string | null;
  partAttributeName?: string | null;
  allowWriteInVariant?: boolean | null;
  rangeMinimumValue?: number | null;
  rangeMaximumValue?: number | null;
  addPartAttributeSelectVariantRequests?: Array<AddPartAttributeSelectVariantInput | null> | null;
};

export type MutationUpdateMessageParamsInput = {
  messageId: string;
};

export type MutationUpdateMessageQueryInput = {
  status?: string | null;
};

export type MutationUpdateMessageBodyInput = {
  status?: string | null;
};

export type Message = {
  __typename: 'Message';
  messageId?: string | null;
  companyId?: string | null;
  messagePriorityId?: string | null;
  fromName?: string | null;
  subject?: string | null;
  body?: string | null;
  messagePriorityCode?: MESSAGE_PRIORITY_CODE | null;
  statusId?: string | null;
  statusName?: string | null;
  accessedOn?: string | null;
  createdOn?: string | null;
};

export enum MESSAGE_PRIORITY_CODE {
  HIGH = 'HIGH',
  NORMAL = 'NORMAL',
}

export type MutationUpdatePartAttributeQueryInput = {
  partAttributeName?: string | null;
  allowWriteInVariant?: boolean | null;
  rangeMinimumValue?: number | null;
  rangeMaximumValue?: number | null;
  addSelectVariants?: Array<AddPartAttributeSelectVariantInput | null> | null;
  deleteSelectVariants?: Array<string | null> | null;
  updateSelectVariants?: Array<UpdatePartAttributeSelectVariantInput | null> | null;
  partAttributeId?: string | null;
};

export type UpdatePartAttributeSelectVariantInput = {
  partAttributeSelectVariantId?: string | null;
  textValue?: string | null;
  codeValue?: string | null;
};

export type MutationUpdatePartAttributeBodyInput = {
  partAttributeName?: string | null;
  allowWriteInVariant?: boolean | null;
  rangeMinimumValue?: number | null;
  rangeMaximumValue?: number | null;
  addSelectVariants?: Array<AddPartAttributeSelectVariantInput | null> | null;
  deleteSelectVariants?: Array<string | null> | null;
  updateSelectVariants?: Array<UpdatePartAttributeSelectVariantInput | null> | null;
  partAttributeId?: string | null;
};

export type MutationDeletePartAttributeParamsInput = {
  partAttributeId: string;
};

export type MutationAddLocationWizardQueryInput = {
  projectId?: string | null;
  locationTypeId?: string | null;
  locationFormatId?: string | null;
  parentLocationId?: string | null;
  startOn?: string | null;
  identifierPrefix?: string | null;
  identifierDelimiter?: string | null;
  locationIdentifier?: string | null;
  namePrefix?: string | null;
  nameDelimiter?: string | null;
  numberOfNodes?: number | null;
  childNodes?: Array<NestedLocationInput | null> | null;
  workPhases?: Array<WorkPhaseBodyWizard | null> | null;
};

export type NestedLocationInput = {
  projectId?: string | null;
  locationTypeId: string;
  locationFormatId?: string | null;
  parentLocationId?: string | null;
  startOn?: string | null;
  identifierPrefix?: string | null;
  identifierDelimiter?: string | null;
  locationIdentifier?: string | null;
  namePrefix?: string | null;
  nameDelimiter?: string | null;
  numberOfNodes: number;
  workPhases?: Array<WorkPhaseBodyWizard | null> | null;
  childNodes?: Array<NestedLocationInput | null> | null;
};

export type WorkPhaseBodyWizard = {
  workPhaseId?: string | null;
  scopePackageId?: string | null;
  workPhaseName?: string | null;
};

export type MutationAddLocationWizardBodyInput = {
  projectId?: string | null;
  locationTypeId?: string | null;
  locationFormatId?: string | null;
  parentLocationId?: string | null;
  startOn?: string | null;
  identifierPrefix?: string | null;
  identifierDelimiter?: string | null;
  locationIdentifier?: string | null;
  namePrefix?: string | null;
  nameDelimiter?: string | null;
  numberOfNodes?: number | null;
  childNodes?: Array<NestedLocationInput | null> | null;
  workPhases?: Array<WorkPhaseBodyWizard | null> | null;
};

export type MutationAddWorkRequestQueryInput = {
  FacilityId?: string | null;
  ProjectId?: string | null;
  WorkRequestDescription?: string | null;
  WorkRequestName?: string | null;
  NeedBy?: string | null;
};

export type MutationAddWorkRequestBodyInput = {
  FacilityId?: string | null;
  ProjectId?: string | null;
  WorkRequestDescription?: string | null;
  WorkRequestName?: string | null;
  NeedBy?: string | null;
};

export type WorkRequest = {
  __typename: 'WorkRequest';
  workRequestId?: string | null;
  workRequestStatusId?: string | null;
  workRequestStatusName?: string | null;
  facilityId?: string | null;
  projectId?: string | null;
  projectIdentifier?: string | null;
  projectName?: string | null;
  workRequestIdentifier?: string | null;
  workRequestName?: string | null;
  needBy?: string | null;
  workRequestDescription?: string | null;
  facilityName?: string | null;
  workRequestStatusDescription?: string | null;
  lastStatusUpdate?: string | null;
  numberOfAttachments?: number | null;
};

export type MutationAddWorkRequestItemQueryInput = {
  workRequestId?: string | null;
  workRequestItemTypeId?: string | null;
  unitOfMeasureId?: string | null;
  workRequestItemName?: string | null;
  workRequestItemIdentification?: string | null;
  quantity?: number | null;
};

export type MutationAddWorkRequestItemBodyInput = {
  workRequestId?: string | null;
  workRequestItemTypeId?: string | null;
  unitOfMeasureId?: string | null;
  workRequestItemName?: string | null;
  workRequestItemIdentification?: string | null;
  quantity?: number | null;
};

export type WorkRequestItem = {
  __typename: 'WorkRequestItem';
  workRequestItemId?: string | null;
  workRequestId?: string | null;
  workRequestItemTypeId?: string | null;
  unitOfMeasureId?: string | null;
  billOfProcessId?: string | null;
  workRequestItemName?: string | null;
  workRequestItemStatusTypeId?: string | null;
  quantity?: number | null;
  unityQuantity?: number | null;
  assignedQuantity?: number | null;
  remainingQuantity?: number | null;
  hasShopTask?: boolean | null;
  createdOn?: string | null;
  createdBy?: string | null;
  lineItemId?: string | null;
};

export type MutationAddWorkRequestItemFromCatalogQueryInput = {
  workRequestId?: string | null;
  partId?: string | null;
  quantity?: number | null;
};

export type MutationAddWorkRequestItemFromCatalogBodyInput = {
  workRequestId?: string | null;
  partId?: string | null;
  quantity?: number | null;
};

export type MutationAddWorkRequestItemFromLineItemQueryInput = {
  workRequestId?: string | null;
  lineItemIds?: Array<LineItemIdInput | null> | null;
  lineItemTypeId?: string | null;
};

export type LineItemIdInput = {
  lineItemId: string;
  lineItemName?: string | null;
  partId?: string | null;
  unitOfMeasureId?: string | null;
  quantity?: number | null;
};

export type MutationAddWorkRequestItemFromLineItemBodyInput = {
  workRequestId?: string | null;
  lineItemIds?: Array<LineItemIdInput | null> | null;
  lineItemTypeId?: string | null;
};

export type MutationAddMessageQueryInput = {
  companyId?: string | null;
  fromName?: string | null;
  fromUserId?: string | null;
  messagePriority?: MESSAGE_PRIORITY_CODE | null;
  subject?: string | null;
  body?: string | null;
  recipients?: Array<string | null> | null;
  bulkRecipientCode?: BULK_RECIPIENT_CODE | null;
  isEncrypted?: boolean | null;
};

export enum BULK_RECIPIENT_CODE {
  ADMIN = 'ADMIN',
  ALL = 'ALL',
}

export type MutationAddMessageBodyInput = {
  companyId?: string | null;
  fromName?: string | null;
  fromUserId?: string | null;
  messagePriority?: MESSAGE_PRIORITY_CODE | null;
  subject?: string | null;
  body?: string | null;
  recipients?: Array<string | null> | null;
  bulkRecipientCode?: BULK_RECIPIENT_CODE | null;
  isEncrypted?: boolean | null;
};

export type MutationAddEntitlementQueryInput = {
  userIds?: Array<string | null> | null;
  productPoolIds?: Array<string | null> | null;
};

export type MutationAddEntitlementBodyInput = {
  userIds?: Array<string | null> | null;
  productPoolIds?: Array<string | null> | null;
};

export type Entitlement = {
  __typename: 'Entitlement';
  entitlementId: string;
  userId: string;
  isCheckedOut?: boolean | null;
  checkedOutEndDate?: string | null;
  secureData?: string | null;
  secureSignature?: string | null;
  licenseTypeCode?: LICENSE_CODE | null;
  productPool?: ProductPool | null;
};

export enum LICENSE_CODE {
  USER = 'USER',
  SERVER = 'SERVER',
}

export type ProductPool = {
  __typename: 'ProductPool';
  productPoolId: string;
  poolQuantity?: number | null;
  productId?: string | null;
  bundleId?: string | null;
  type?: string | null;
  label?: string | null;
  poolAvailableQuantity?: number | null;
  renewalDate?: string | null;
  customData?: string | null;
  termDate?: string | null;
  startDate?: string | null;
  trialRenewalDate?: string | null;
  company?: Company | null;
  product?: Product | null;
};

export type Company = {
  __typename: 'Company';
  companyId: string;
  companyName?: string | null;
  renewalDate?: string | null;
  mfA_Required?: boolean | null;
  data_Analytics?: boolean | null;
  companyDownloadPaths?: Array<CompanyDownloadPath | null> | null;
  companyImageId?: string | null;
  address?: Address | null;
  productPool?: Array<ProductPool | null> | null;
};

export type CompanyDownloadPath = {
  __typename: 'CompanyDownloadPath';
  document?: string | null;
  updateBy?: string | null;
  updatedOn?: string | null;
};

export type Address = {
  __typename: 'Address';
  addressId?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  zip?: string | null;
};

export type Product = {
  __typename: 'Product';
  productId: string;
  productName?: string | null;
  baseProductName?: string | null;
  isForesiteModule?: boolean | null;
  licenseTypeId?: string | null;
  licenseTypeName?: string | null;
  licenseClassId?: string | null;
  licenseClassName?: string | null;
  productNameFormatted?: string | null;
  onlyVisibleToMembers?: boolean | null;
  supportsTrial?: boolean | null;
  productLicenseTypes?: Array<ProductLicenseType | null> | null;
};

export type ProductLicenseType = {
  __typename: 'ProductLicenseType';
  productLicenseTypeId: string;
  productId: string;
  licenseTypeId: string;
  licenseType?: LicenseType | null;
};

export type LicenseType = {
  __typename: 'LicenseType';
  licenseTypeId: string;
  licenseTypeName: string;
  licenseTypeCode?: LICENSE_CODE | null;
  sortOrder?: number | null;
};

export type MutationAddBundleQueryInput = {
  bundleName?: string | null;
  bundleLicenseTypes?: Array<BundleLicenseTypeInput | null> | null;
  bundleProducts?: Array<BundleProductsInput | null> | null;
};

export type BundleLicenseTypeInput = {
  licenseTypeId?: string | null;
};

export type BundleProductsInput = {
  productId?: string | null;
};

export type MutationAddBundleBodyInput = {
  bundleName?: string | null;
  bundleLicenseTypes?: Array<BundleLicenseTypeInput | null> | null;
  bundleProducts?: Array<BundleProductsInput | null> | null;
};

export type Bundle = {
  __typename: 'Bundle';
  bundleId: string;
  bundleName: string;
  bundleProducts?: Array<BundleProduct | null> | null;
};

export type BundleProduct = {
  __typename: 'BundleProduct';
  bundleProductId: string;
  productId: string;
  product?: Product | null;
};

export type MutationAddCompanyQueryInput = {
  companyName?: string | null;
  userEmails?: Array<string | null> | null;
};

export type MutationAddCompanyBodyInput = {
  companyName?: string | null;
  userEmails?: Array<string | null> | null;
};

export type MutationAddUserQueryInput = {
  password?: string | null;
  userEmail?: string | null;
  companyId?: string | null;
  companyName?: string | null;
  userStatusCode?: USER_STATUS | null;
  userName?: string | null;
  userRoles?: Array<RoleInput | null> | null;
  isAdmin?: boolean | null;
};

export enum USER_STATUS {
  CONFIRMED = 'CONFIRMED',
  UNCONFIRMED = 'UNCONFIRMED',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  EMAIL_NOT_FOUND = 'EMAIL_NOT_FOUND',
  FORCE_CHANGE_PASSWORD = 'FORCE_CHANGE_PASSWORD',
}

export type RoleInput = {
  roleId?: string | null;
};

export type MutationAddUserBodyInput = {
  password?: string | null;
  userEmail?: string | null;
  companyId?: string | null;
  companyName?: string | null;
  userStatusCode?: USER_STATUS | null;
  userName?: string | null;
  userRoles?: Array<RoleInput | null> | null;
  isAdmin?: boolean | null;
};

export type User = {
  __typename: 'User';
  userId: string;
  userName: string;
  userFirstName?: string | null;
  userLastName?: string | null;
  userIdentifier?: string | null;
  userEmail?: string | null;
  workPhoneNumber?: string | null;
  personalPhoneNumber?: string | null;
  userStatus?: string | null;
  userStatusId?: string | null;
  inviteStatus?: USER_INVITE_STATUS | null;
  userTypes?: Array<UserType | null> | null;
  acceptedLatestEula?: boolean | null;
  companyId?: string | null;
  isAdmin?: boolean | null;
  isDeleted?: boolean | null;
  userPhotoId?: string | null;
};

export enum USER_INVITE_STATUS {
  Active = 'Active',
  Pending = 'Pending',
  Deleted = 'Deleted',
}

export type UserType = {
  __typename: 'UserType';
  userTypeId?: string | null;
  typeName?: string | null;
  typeId?: string | null;
};

export type MutationAddProjectUserQueryInput = {
  projectId?: string | null;
  userId?: string | null;
  userEmail?: string | null;
  isAdmin?: boolean | null;
};

export type MutationAddProjectUserBodyInput = {
  projectId?: string | null;
  userId?: string | null;
  userEmail?: string | null;
  isAdmin?: boolean | null;
};

export type ProjectUser = {
  __typename: 'ProjectUser';
  projectUserId: string;
  projectId: string;
  inviteStatus?: PROJECT_USER_INVITE_STATUS | null;
  expiresAt?: string | null;
  isAdmin?: boolean | null;
  isDeleted?: boolean | null;
  user?: User | null;
};

export enum PROJECT_USER_INVITE_STATUS {
  Active = 'Active',
  Pending = 'Pending',
  Deleted = 'Deleted',
}

export type MutationAddProjectQueryInput = {
  projectIdentifier?: string | null;
  projectName?: string | null;
  companyId?: string | null;
  requestedBy?: string | null;
  projectTypeId?: string | null;
  userId?: string | null;
  userEmail?: string | null;
  isAdmin?: boolean | null;
};

export type MutationAddProjectBodyInput = {
  projectIdentifier?: string | null;
  projectName?: string | null;
  companyId?: string | null;
  requestedBy?: string | null;
  projectTypeId?: string | null;
  userId?: string | null;
  userEmail?: string | null;
  isAdmin?: boolean | null;
};

export type Project = {
  __typename: 'Project';
  projectId: string;
  projectName?: string | null;
  projectIdentifier?: string | null;
  dataAnalytics?: boolean | null;
  units?: string | null;
  companyId?: string | null;
  address?: Address | null;
  projectImageId?: string | null;
  disallowAutomaticDistributionQty?: boolean | null;
  projectType?: ProjectType | null;
};

export type ProjectType = {
  __typename: 'ProjectType';
  projectTypeId?: string | null;
  projectTypeName?: string | null;
  companyId?: string | null;
};

export type MutationAddProductQueryInput = {
  baseProductName?: string | null;
  isForesiteModule?: boolean | null;
  licenseTypeIds?: Array<string | null> | null;
  licenseClassIds?: Array<string | null> | null;
};

export type MutationAddProductBodyInput = {
  baseProductName?: string | null;
  isForesiteModule?: boolean | null;
  licenseTypeIds?: Array<string | null> | null;
  licenseClassIds?: Array<string | null> | null;
};

export type MutationAddProductPoolQueryInput = {
  companyId?: string | null;
  productId?: string | null;
  bundleId?: string | null;
  poolQuantity?: number | null;
  customData?: string | null;
  term?: number | null;
  startDate?: string | null;
  termDate?: string | null;
  trialTermDate?: string | null;
  trialDuration?: string | null;
};

export type MutationAddProductPoolBodyInput = {
  companyId?: string | null;
  productId?: string | null;
  bundleId?: string | null;
  poolQuantity?: number | null;
  customData?: string | null;
  term?: number | null;
  startDate?: string | null;
  termDate?: string | null;
  trialTermDate?: string | null;
  trialDuration?: string | null;
};

export type MutationAddPartCatalogQueryInput = {
  partCatalogName?: string | null;
  companyId?: string | null;
};

export type MutationAddPartCatalogBodyInput = {
  partCatalogName?: string | null;
  companyId?: string | null;
};

export type MutationAddTopLevelPartCategoryQueryInput = {
  partCategoryName?: string | null;
  partCatalogId?: string | null;
};

export type MutationAddTopLevelPartCategoryBodyInput = {
  partCategoryName?: string | null;
  partCatalogId?: string | null;
};

export type MutationAddSubLevelPartCategoryQueryInput = {
  partCategoryName?: string | null;
  partCatalogId?: string | null;
  parentPartCategoryId?: string | null;
};

export type MutationAddSubLevelPartCategoryBodyInput = {
  partCategoryName?: string | null;
  partCatalogId?: string | null;
  parentPartCategoryId?: string | null;
};

export type MutationAddTasktypeQueryInput = {
  taskTypeImageId?: string | null;
  taskTypeName?: string | null;
  taskTypeDescription?: string | null;
  taskTypeClassId?: string | null;
  companyId?: string | null;
};

export type MutationAddTasktypeBodyInput = {
  taskTypeImageId?: string | null;
  taskTypeName?: string | null;
  taskTypeDescription?: string | null;
  taskTypeClassId?: string | null;
  companyId?: string | null;
};

export type TaskType = {
  __typename: 'TaskType';
  taskTypeId: string;
  taskTypeImageId?: string | null;
  taskTypeName?: string | null;
  taskTypeDescription?: string | null;
  taskTypeClassId: string;
  companyId: string;
};

export type MutationUpdatePartCategoryParamsInput = {
  id: string;
};

export type MutationUpdatePartCategoryQueryInput = {
  partCategoryName?: string | null;
  documentName?: string | null;
  storagePath?: string | null;
};

export type MutationUpdatePartCategoryBodyInput = {
  partCategoryName?: string | null;
  documentName?: string | null;
  storagePath?: string | null;
};

export type MutationDeletePartCategoryParamsInput = {
  id: string;
};

export type MutationAddDepartmentUserQueryInput = {
  departmentId?: string | null;
  userId?: string | null;
  userEmail?: string | null;
  isAdmin?: boolean | null;
};

export type MutationAddDepartmentUserBodyInput = {
  departmentId?: string | null;
  userId?: string | null;
  userEmail?: string | null;
  isAdmin?: boolean | null;
};

export type DepartmentUser = {
  __typename: 'DepartmentUser';
  departmentUserId: string;
  departmentId: string;
  expiresAt?: string | null;
  inviteStatus?: DEPARTMENT_USER_INVITE_STATUS | null;
  isDeleted?: boolean | null;
  isAdmin?: boolean | null;
  user?: User | null;
};

export enum DEPARTMENT_USER_INVITE_STATUS {
  Active = 'Active',
  Pending = 'Pending',
  Deleted = 'Deleted',
}

export type MutationAddFacilityQueryInput = {
  companyId?: string | null;
  facilityName?: string | null;
  address?: AddressInput | null;
  departments?: Array<DepartmentInput | null> | null;
};

export type AddressInput = {
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  zip?: string | null;
};

export type DepartmentInput = {
  departmentName?: string | null;
  departmentTypeId?: string | null;
  userId?: string | null;
  userName?: string | null;
  userEmail?: string | null;
  isAdmin?: boolean | null;
};

export type MutationAddFacilityBodyInput = {
  companyId?: string | null;
  facilityName?: string | null;
  address?: AddressInput | null;
  departments?: Array<DepartmentInput | null> | null;
};

export type Facility = {
  __typename: 'Facility';
  facilityId: string;
  facilityName: string;
  companyId: string;
  address?: Address | null;
  departments: Array<Department | null>;
};

export type Department = {
  __typename: 'Department';
  departmentId?: string | null;
  departmentName?: string | null;
  departmentTypeId?: string | null;
  facility?: DepartmentFacility | null;
  departmentType?: DepartmentType | null;
  adminUser?: User | null;
};

export type DepartmentFacility = {
  __typename: 'DepartmentFacility';
  facilityId?: string | null;
  facilityName?: string | null;
};

export type DepartmentType = {
  __typename: 'DepartmentType';
  departmentTypeId?: string | null;
  departmentTypeName?: string | null;
};

export type MutationUpdateFacilityQueryInput = {
  companyId?: string | null;
  facilityName?: string | null;
  facilityIdParam?: string | null;
  address?: UpdateFacilityAddressInput | null;
  departments?: Array<UpdateFacilityDepartmentsInput | null> | null;
};

export type UpdateFacilityAddressInput = {
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  country?: string | null;
  state?: string | null;
  zip?: string | null;
};

export type UpdateFacilityDepartmentsInput = {
  departmentId?: string | null;
  departmentName?: string | null;
  departmentTypeId?: string | null;
  userId?: string | null;
  userEmail?: string | null;
  userName?: string | null;
};

export type MutationUpdateFacilityBodyInput = {
  companyId?: string | null;
  facilityName?: string | null;
  facilityIdParam?: string | null;
  address?: UpdateFacilityAddressInput | null;
  departments?: Array<UpdateFacilityDepartmentsInput | null> | null;
};

export type MutationUserAcceptLatestEulaParamsInput = {
  id: string;
};

export type Eula = {
  __typename: 'Eula';
  eulaId: string;
  eulaVersion: string;
  eulaTimeStamp: string;
  content: string;
};

export type MutationAddCompanyRenewalDateParamsInput = {
  companyId: string;
};

export type MutationAddCompanyRenewalDateQueryInput = {
  renewalDateMonth?: number | null;
  renewalDateDay?: number | null;
};

export type MutationAddCompanyRenewalDateBodyInput = {
  renewalDateMonth?: number | null;
  renewalDateDay?: number | null;
};

export type MutationUpdateEntitlementParamsInput = {
  entitlementId: string;
};

export type MutationUpdateEntitlementQueryInput = {
  endDate?: string | null;
  checkedOut?: boolean | null;
};

export type MutationUpdateEntitlementBodyInput = {
  endDate?: string | null;
  checkedOut?: boolean | null;
};

export type MutationUpdateEntitlementsQueryInput = {
  endDate?: string | null;
  checkedOut?: boolean | null;
  entitlementIds?: Array<string | null> | null;
};

export type MutationUpdateEntitlementsBodyInput = {
  endDate?: string | null;
  checkedOut?: boolean | null;
  entitlementIds?: Array<string | null> | null;
};

export type MutationUpdateUserParamsInput = {
  userId: string;
};

export type MutationUpdateUserQueryInput = {
  firstName?: string | null;
  lastName?: string | null;
  personalPhoneNumber?: string | null;
  workPhoneNumber?: string | null;
  isAdmin?: boolean | null;
  userPhotoId?: string | null;
};

export type MutationUpdateUserBodyInput = {
  firstName?: string | null;
  lastName?: string | null;
  personalPhoneNumber?: string | null;
  workPhoneNumber?: string | null;
  isAdmin?: boolean | null;
  userPhotoId?: string | null;
};

export type MutationUpdateProductPoolRenewalDateParamsInput = {
  productPoolId: string;
};

export type MutationUpdateProductPoolRenewalDateQueryInput = {
  quantity?: number | null;
  startDate?: string | null;
  newTerm?: string | null;
  newTrialTerm?: string | null;
  customData?: string | null;
};

export type MutationUpdateProductPoolRenewalDateBodyInput = {
  quantity?: number | null;
  startDate?: string | null;
  newTerm?: string | null;
  newTrialTerm?: string | null;
  customData?: string | null;
};

export type MutationUpdateProjectParamsInput = {
  projectId: string;
};

export type MutationUpdateProjectQueryInput = {
  projectName?: string | null;
  projectIdentifier?: string | null;
  projectTypeId?: string | null;
  units?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  country?: string | null;
  dataAnalytics?: boolean | null;
  departmentIds?: Array<string | null> | null;
  projectImageId?: string | null;
  disallowAutomaticDistributionQty?: boolean | null;
};

export type MutationUpdateProjectBodyInput = {
  projectName?: string | null;
  projectIdentifier?: string | null;
  projectTypeId?: string | null;
  units?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  country?: string | null;
  dataAnalytics?: boolean | null;
  departmentIds?: Array<string | null> | null;
  projectImageId?: string | null;
  disallowAutomaticDistributionQty?: boolean | null;
};

export type MutationUpdateCompanyParamsInput = {
  companyId: string;
};

export type MutationUpdateCompanyQueryInput = {
  companyName?: string | null;
  data_Analytics?: boolean | null;
  mga_required?: boolean | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  country?: string | null;
  companyImageId?: string | null;
};

export type MutationUpdateCompanyBodyInput = {
  companyName?: string | null;
  data_Analytics?: boolean | null;
  mga_required?: boolean | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  country?: string | null;
  companyImageId?: string | null;
};

export type MutationUpdateLocationParamsInput = {
  locationId: string;
};

export type MutationUpdateLocationQueryInput = {
  parentLocationId?: string | null;
  locationIdentifier?: string | null;
  locationName?: string | null;
  sortOrder?: number | null;
};

export type MutationUpdateLocationBodyInput = {
  parentLocationId?: string | null;
  locationIdentifier?: string | null;
  locationName?: string | null;
  sortOrder?: number | null;
};

export type UpdatedLocation = {
  __typename: 'UpdatedLocation';
  explicitlyUpdatedLocation?: Location | null;
  updatedLocationPhases?: Array<UpdatedLocationPhase | null> | null;
};

export type MutationUpdateWorkRequestParamsInput = {
  workRequestId: string;
};

export type MutationUpdateWorkRequestQueryInput = {
  workRequestStatusId?: string | null;
  facilityId?: string | null;
  projectId?: string | null;
  projectIdentifier?: string | null;
  workRequestIdentifier?: string | null;
  workRequestName?: string | null;
  needBy?: string | null;
  workRequestDescription?: string | null;
};

export type MutationUpdateWorkRequestBodyInput = {
  workRequestStatusId?: string | null;
  facilityId?: string | null;
  projectId?: string | null;
  projectIdentifier?: string | null;
  workRequestIdentifier?: string | null;
  workRequestName?: string | null;
  needBy?: string | null;
  workRequestDescription?: string | null;
};

export type MutationAddWorkRequestDocumentQueryInput = {
  workRequestId?: string | null;
  documentIds?: Array<string | null> | null;
  externalUrl?: string | null;
};

export type MutationAddWorkRequestDocumentBodyInput = {
  workRequestId?: string | null;
  documentIds?: Array<string | null> | null;
  externalUrl?: string | null;
};

export type WorkRequestDocument = {
  __typename: 'WorkRequestDocument';
  workRequestDocumentId: string;
  workRequestId: string;
  documentId?: string | null;
  externalUrl?: string | null;
  createdOn: string;
  updatedOn?: string | null;
  createdBy: string;
};

export type MutationAddWorkRequestItemDocumentQueryInput = {
  workRequestItemIds?: Array<string | null> | null;
  documentIds?: Array<string | null> | null;
  folderId?: string | null;
};

export type MutationAddWorkRequestItemDocumentBodyInput = {
  workRequestItemIds?: Array<string | null> | null;
  documentIds?: Array<string | null> | null;
  folderId?: string | null;
};

export type MutationDeleteWorkRequestDocumentParamsInput = {
  workRequestDocumentId: string;
};

export type MutationAddWorkOrderDocumentQueryInput = {
  workOrderId?: string | null;
  documentIds?: Array<string | null> | null;
  externalUrl?: string | null;
};

export type MutationAddWorkOrderDocumentBodyInput = {
  workOrderId?: string | null;
  documentIds?: Array<string | null> | null;
  externalUrl?: string | null;
};

export type WorkOrderDocument = {
  __typename: 'WorkOrderDocument';
  workOrderDocumentId: string;
  workOrderId: string;
  documentId?: string | null;
  externalUrl?: string | null;
  createdOn?: string | null;
  updatedOn?: string | null;
  createdBy?: string | null;
};

export type MutationDeleteWorkOrderDocumentParamsInput = {
  workOrderDocumentId: string;
};

export type MutationUpdateWorkRequestItemParamsInput = {
  workRequestItemId: string;
};

export type MutationUpdateWorkRequestItemQueryInput = {
  workRequestId?: string | null;
  workRequestItemTypeId?: string | null;
  unitOfMeasureId?: string | null;
  workRequestItemName?: string | null;
  quantity?: number | null;
};

export type MutationUpdateWorkRequestItemBodyInput = {
  workRequestId?: string | null;
  workRequestItemTypeId?: string | null;
  unitOfMeasureId?: string | null;
  workRequestItemName?: string | null;
  quantity?: number | null;
};

export type MutationUpdateWorkRequestStatusParamsInput = {
  workRequestId: string;
  workRequestStatusName: string;
};

export type MutationUpdateWorkRequestStatusQueryInput = {
  workRequestStatusDescription?: string | null;
};

export type MutationUpdateWorkRequestStatusBodyInput = {
  workRequestStatusDescription?: string | null;
};

export type WorkRequestsStatus = {
  __typename: 'WorkRequestsStatus';
  workRequestStatusId: string;
  workRequestStatusTypeId: string;
  workRequestStatusTypeName?: string | null;
  workRequestStatusTypeDescription?: string | null;
};

export type MutationAddTaskTypeToWorkRequestParamsInput = {
  workRequestId: string;
};

export type MutationAddTaskTypeToWorkRequestQueryInput = {
  taskTypeIds?: Array<string | null> | null;
};

export type MutationAddTaskTypeToWorkRequestBodyInput = {
  taskTypeIds?: Array<string | null> | null;
};

export type MutationUpdateScopePackageParamsInput = {
  id: string;
};

export type MutationUpdateScopePackageQueryInput = {
  ScopePackageName?: string | null;
  scopePackageIdentifier?: string | null;
};

export type MutationUpdateScopePackageBodyInput = {
  ScopePackageName?: string | null;
  scopePackageIdentifier?: string | null;
};

export type MutationUpdateTaskTypeParamsInput = {
  taskTypeId: string;
};

export type MutationUpdateTaskTypeQueryInput = {
  taskTypeImageId?: string | null;
  taskTypeName?: string | null;
  taskTypeDescription?: string | null;
  taskTypeClassId?: string | null;
  companyId?: string | null;
};

export type MutationUpdateTaskTypeBodyInput = {
  taskTypeImageId?: string | null;
  taskTypeName?: string | null;
  taskTypeDescription?: string | null;
  taskTypeClassId?: string | null;
  companyId?: string | null;
};

export type MutationUpdateWorkPhaseParamsInput = {
  id: string;
};

export type MutationUpdateWorkPhaseQueryInput = {
  scopePackageId?: string | null;
  phaseName?: string | null;
  sortOrder?: number | null;
  workPhaseIdentifier?: string | null;
};

export type MutationUpdateWorkPhaseBodyInput = {
  scopePackageId?: string | null;
  phaseName?: string | null;
  sortOrder?: number | null;
  workPhaseIdentifier?: string | null;
};

export type MutationDeleteProjectUsersQueryInput = {
  projectUserIds?: Array<string | null> | null;
};

export type MutationDeleteProjectUsersBodyInput = {
  projectUserIds?: Array<string | null> | null;
};

export type MutationDeleteDepartmentUsersQueryInput = {
  departmentUserIds?: Array<string | null> | null;
};

export type MutationDeleteDepartmentUsersBodyInput = {
  departmentUserIds?: Array<string | null> | null;
};

export type MutationDeleteUsersQueryInput = {
  userIds?: Array<string | null> | null;
};

export type MutationDeleteUsersBodyInput = {
  userIds?: Array<string | null> | null;
};

export type MutationDeleteProjectParamsInput = {
  projectId: string;
};

export type MutationDeleteFacilityParamsInput = {
  facilityId: string;
};

export type MutationDeleteTaskTypeParamsInput = {
  taskTypeId: string;
};

export type MutationTrashMessageParamsInput = {
  messageId: string;
};

export type MutationRestoreMessageParamsInput = {
  messageId: string;
};

export type MutationMarkMessageAsReadParamsInput = {
  messageId: string;
};

export type MutationMarkMessageAsReadQueryInput = {
  action?: string | null;
};

export type MutationMarkMessageAsReadBodyInput = {
  action?: string | null;
};

export type MutationDeleteDepartmentParamsInput = {
  departmentId: string;
};

export type MutationDeleteEntitlementParamsInput = {
  entitlementId: string;
};

export type MutationDeleteLocationParamsInput = {
  locationId: string;
};

export type DeletedLocationResponse = {
  __typename: 'DeletedLocationResponse';
  explicitlyDeletedLocation: Location;
  deletedLocationChildrenIds?: Array<string | null> | null;
  deletedLocationPhases?: Array<DeletedWorkPhase | null> | null;
};

export type DeletedWorkPhase = {
  __typename: 'DeletedWorkPhase';
  workPhaseId: string;
  deletedLocationPhaseId: string;
};

export type MutationDeleteScopePackageParamsInput = {
  id: string;
};

export type DeletedScopePackageResponse = {
  __typename: 'DeletedScopePackageResponse';
  deletedScopePackage: ScopePackage;
  deletedWorkPhasesIds?: Array<string | null> | null;
  deletedLocationPhases?: Array<DeletedLocationPhase | null> | null;
};

export type DeletedLocationPhase = {
  __typename: 'DeletedLocationPhase';
  locationId: string;
  deletedLocationPhaseId: string;
};

export type MutationDeleteWorkPhaseParamsInput = {
  id: string;
};

export type DeletedWorkPhaseResponse = {
  __typename: 'DeletedWorkPhaseResponse';
  deletedWorkPhase: WorkPhase;
  deletedLocationPhases?: Array<DeletedLocationPhase | null> | null;
};

export type MutationDeleteWorkRequestParamsInput = {
  workRequestId: string;
};

export type MutationDeleteWorkRequestItemParamsInput = {
  workRequestItemId: string;
};

export type MutationResendUserInvitationParamsInput = {
  userId: string;
};

export type MutationResendProjectUserInvitationParamsInput = {
  projectUserId: string;
};

export type MutationResendDepartmentUserInvitationParamsInput = {
  departmentUserId: string;
};

export type MutationAddBillOfMaterialQueryInput = {
  projectId?: string | null;
  billOfMaterialsName?: string | null;
};

export type MutationAddBillOfMaterialBodyInput = {
  projectId?: string | null;
  billOfMaterialsName?: string | null;
};

export type BillOfMaterial = {
  __typename: 'BillOfMaterial';
  billOfMaterialsId: string;
  billOfMaterialsStatusId?: string | null;
  billOfMaterialsStatusName?: string | null;
  projectId: string;
  billOfMaterialsName: string;
  createdOn: string;
  createdBy: string;
  modifiedOn?: string | null;
  modifiedBy?: string | null;
  createdByFirstName?: string | null;
  createdByLastName?: string | null;
  createdByEmail?: string | null;
  updatedOn?: string | null;
  updatedBy?: string | null;
  updatedByFirstName?: string | null;
  updatedByLastName?: string | null;
  updatedByEmail?: string | null;
};

export type MutationAddBillOfMaterialsWriteInItemQueryInput = {
  projectId?: string | null;
  items?: Array<WriteInItemTypeInput | null> | null;
};

export type WriteInItemTypeInput = {
  lineItemTypeId: string;
  unitOfMeasureId?: string | null;
  manufacturerId?: string | null;
  locationId?: string | null;
  workPhaseId?: string | null;
  scopePackageId?: string | null;
  lineItemName?: string | null;
  manufacturerCatalogNumber?: string | null;
  parentLineItemId?: string | null;
  quantity?: number | null;
  assemblyCustomCategoryId?: string | null;
  partId?: string | null;
  lineItemIdentifier?: string | null;
  tradeSize?: string | null;
  unitQuantity?: number | null;
};

export type MutationAddBillOfMaterialsWriteInItemBodyInput = {
  projectId?: string | null;
  items?: Array<WriteInItemTypeInput | null> | null;
};

export type BillOfMaterialItem = {
  __typename: 'BillOfMaterialItem';
  lineItemId: string;
  lineItemTypeId: string;
  lineItemTypeName?: string | null;
  billOfMaterialsId?: string | null;
  unitOfMeasureId?: string | null;
  unitOfMeasureName?: string | null;
  unitOfMeasureCode?: string | null;
  manufacturerId?: string | null;
  manufacturerName?: string | null;
  partId?: string | null;
  locationId?: string | null;
  workPhaseId?: string | null;
  scopePackageId?: string | null;
  workPackageId?: string | null;
  kitPlanId?: string | null;
  spoolId?: string | null;
  lineItemName?: string | null;
  manufacturerCatalogNumber?: string | null;
  quantity?: number | null;
  remainingQuantity?: number | null;
  unitQuantity?: number | null;
  tradeSize?: string | null;
  createdOn?: string | null;
  createdBy?: string | null;
  createdByFirstName?: string | null;
  childLineItemContainerName?: string | null;
  createdByLastName?: string | null;
  createdByEmail?: string | null;
  updatedOn?: string | null;
  updatedBy?: string | null;
  updatedByFirstName?: string | null;
  updatedByLastName?: string | null;
  updatedByEmail?: string | null;
  numberOfOpenDecisions?: number | null;
  decisionsHaveVariantDeletedFromCurrentVersionOfPart?: boolean | null;
  ableToMakeDecisions?: boolean | null;
  numberOfDecisionsMade?: number | null;
  hasChildNodes?: boolean | null;
  parentLineItem?: string | null;
  projectId?: string | null;
  assemblyCustomCategoryId?: string | null;
  assemblyCustomCategoryName?: string | null;
  canChildLineItemBeAdded?: boolean | null;
};

export type MutationAddBillOfMaterialsItemQueryInput = {
  projectId?: string | null;
  items?: Array<BillOfMaterialItemInput | null> | null;
};

export type BillOfMaterialItemInput = {
  lineItemTypeId: string;
  unitOfMeasureId?: string | null;
  manufacturerId?: string | null;
  locationId?: string | null;
  workPhaseId?: string | null;
  scopePackageId?: string | null;
  lineItemName?: string | null;
  manufacturerCatalogNumber?: string | null;
  quantity: number;
  partId: string;
  parentLineItemId?: string | null;
  unitQuantity?: number | null;
};

export type MutationAddBillOfMaterialsItemBodyInput = {
  projectId?: string | null;
  items?: Array<BillOfMaterialItemInput | null> | null;
};

export type MutationDeleteBillOfMaterialsParamsInput = {
  billOfMaterialsId: string;
};

export type MutationUpdateBillOfMaterialsItemByIdParamsInput = {
  id: string;
};

export type MutationUpdateBillOfMaterialsItemByIdQueryInput = {
  unitQuantity?: number | null;
  lineItemName?: string | null;
};

export type MutationUpdateBillOfMaterialsItemByIdBodyInput = {
  unitQuantity?: number | null;
  lineItemName?: string | null;
};

export type MutationUpdateDecisionsQueryInput = {
  lineItemDecisions?: Array<lineItemsDecisions | null> | null;
  locationIds?: string | null;
  workPhaseIds?: string | null;
  scopePackageIds?: string | null;
  locationsWithWorkPhases?: string | null;
  assignedlocationId?: string | null;
  assignedworkPhaseId?: string | null;
};

export type lineItemsDecisions = {
  lineItemId?: string | null;
  quantity?: number | null;
  decisions?: Array<DecisionVariantInput | null> | null;
};

export type DecisionVariantInput = {
  partAttributeId?: string | null;
  partAttributeSelectVariantId?: string | null;
  customValue?: string | null;
  isSkipped?: boolean | null;
};

export type MutationUpdateDecisionsBodyInput = {
  lineItemDecisions?: Array<lineItemsDecisions | null> | null;
  locationIds?: string | null;
  workPhaseIds?: string | null;
  scopePackageIds?: string | null;
  locationsWithWorkPhases?: string | null;
  assignedlocationId?: string | null;
  assignedworkPhaseId?: string | null;
};

export type MutationUpdateBillOfMaterialItemAssemblyCategoryByIdParamsInput = {
  id: string;
};

export type MutationUpdateBillOfMaterialItemAssemblyCategoryByIdQueryInput = {
  assemblyCustomCategoryId?: string | null;
};

export type MutationUpdateBillOfMaterialItemAssemblyCategoryByIdBodyInput = {
  assemblyCustomCategoryId?: string | null;
};

export type MutationUpdateDecisionsTakeoffQueryInput = {
  lineItemDecisions?: Array<lineItemsDecisions | null> | null;
  locationIds?: string | null;
  workPhaseIds?: string | null;
  scopePackageIds?: string | null;
  locationsWithWorkPhases?: string | null;
  assignedlocationId?: string | null;
  assignedworkPhaseId?: string | null;
};

export type MutationUpdateDecisionsTakeoffBodyInput = {
  lineItemDecisions?: Array<lineItemsDecisions | null> | null;
  locationIds?: string | null;
  workPhaseIds?: string | null;
  scopePackageIds?: string | null;
  locationsWithWorkPhases?: string | null;
  assignedlocationId?: string | null;
  assignedworkPhaseId?: string | null;
};

export type TakeOffPadItem = {
  __typename: 'TakeOffPadItem';
  lineItemId: string;
  lineItemTypeId: string;
  lineItemTypeName?: string | null;
  takeoffPadId: string;
  unitOfMeasureId?: string | null;
  unitOfMeasureName?: string | null;
  unitOfMeasureCode?: string | null;
  manufacturerId?: string | null;
  manufacturerName?: string | null;
  partId?: string | null;
  locationId?: string | null;
  workPhaseId?: string | null;
  scopePackageId?: string | null;
  workPackageId?: string | null;
  kitPlanId?: string | null;
  spoolId?: string | null;
  lineItemName?: string | null;
  manufacturerCatalogNumber?: string | null;
  quantity?: number | null;
  remainingQuantity?: number | null;
  unitQuantity?: number | null;
  tradeSize?: string | null;
  billOfMaterialsName?: string | null;
  createdOn?: string | null;
  createdBy?: string | null;
  createdByFirstName?: string | null;
  createdByLastName?: string | null;
  createdByEmail?: string | null;
  updatedOn?: string | null;
  updatedBy?: string | null;
  updatedByFirstName?: string | null;
  updatedByLastName?: string | null;
  updatedByEmail?: string | null;
  numberOfOpenDecisions?: number | null;
  decisionsHaveVariantDeletedFromCurrentVersionOfPart?: boolean | null;
  ableToMakeDecisions?: boolean | null;
  numberOfDecisionsMade?: number | null;
};

export type MutationRenameBillOfMaterialsParamsInput = {
  billOfMaterialsId: string;
};

export type MutationRenameBillOfMaterialsQueryInput = {
  billOfMaterialsName?: string | null;
};

export type MutationRenameBillOfMaterialsBodyInput = {
  billOfMaterialsName?: string | null;
};

export type MutationCloseBillOfMaterialsParamsInput = {
  billOfMaterialsId: string;
};

export type MutationOpenBillOfMaterialsParamsInput = {
  billOfMaterialsId: string;
};

export type MutationAddManufacturerQueryInput = {
  companyId?: string | null;
  manufacturerName?: string | null;
  homePageUrl?: string | null;
};

export type MutationAddManufacturerBodyInput = {
  companyId?: string | null;
  manufacturerName?: string | null;
  homePageUrl?: string | null;
};

export type MutationAddPartQueryInput = {
  partName?: string | null;
  description?: string | null;
  unitOfMeasureId?: string | null;
  partCategoryId?: string | null;
  manufacturerCatalogNumber?: string | null;
  manufacturerId?: string | null;
  upcCode?: string | null;
  url?: string | null;
  catalogUrl?: string | null;
  partUrl?: string | null;
  internalPartNumber?: string | null;
  partAttributeVariantData?: Array<PartAttributeVariantDataInput | null> | null;
};

export type PartAttributeVariantDataInput = {
  partAttributeId?: string | null;
  rangeValue?: number | null;
  selectValueIds?: Array<string | null> | null;
};

export type MutationAddPartBodyInput = {
  partName?: string | null;
  description?: string | null;
  unitOfMeasureId?: string | null;
  partCategoryId?: string | null;
  manufacturerCatalogNumber?: string | null;
  manufacturerId?: string | null;
  upcCode?: string | null;
  url?: string | null;
  catalogUrl?: string | null;
  partUrl?: string | null;
  internalPartNumber?: string | null;
  partAttributeVariantData?: Array<PartAttributeVariantDataInput | null> | null;
};

export type MutationUpdatePartQueryInput = {
  partName?: string | null;
  description?: string | null;
  unitOfMeasureId?: string | null;
  manufacturerCatalogNumber?: string | null;
  manufacturerId?: string | null;
  updatePartAttributeVariantData?: Array<UpdatePartAttributeVariantDataInput | null> | null;
  addPartAttributeVariantData?: Array<AddPartAttributeVariantDataInput | null> | null;
  deletePartAttributeVariantData?: Array<string | null> | null;
  partId?: string | null;
};

export type UpdatePartAttributeVariantDataInput = {
  partAttributeVariantDataId?: string | null;
  selectValueIds?: Array<string | null> | null;
  rangeValue?: number | null;
};

export type AddPartAttributeVariantDataInput = {
  partAttributeId?: string | null;
  rangeValue?: number | null;
  selectValueIds?: Array<string | null> | null;
};

export type MutationUpdatePartBodyInput = {
  partName?: string | null;
  description?: string | null;
  unitOfMeasureId?: string | null;
  manufacturerCatalogNumber?: string | null;
  manufacturerId?: string | null;
  updatePartAttributeVariantData?: Array<UpdatePartAttributeVariantDataInput | null> | null;
  addPartAttributeVariantData?: Array<AddPartAttributeVariantDataInput | null> | null;
  deletePartAttributeVariantData?: Array<string | null> | null;
  partId?: string | null;
};

export type MutationDeletePartParamsInput = {
  partId: string;
};

export type MutationRemovePartsFromCategoryParamsInput = {
  partCategoryId: string;
};

export type MutationRemovePartsFromCategoryQueryInput = {
  partIds?: Array<string | null> | null;
};

export type MutationRemovePartsFromCategoryBodyInput = {
  partIds?: Array<string | null> | null;
};

export type MutationRemovePartAttributesFromCategoryParamsInput = {
  partCategoryId: string;
};

export type MutationRemovePartAttributesFromCategoryQueryInput = {
  partAttributeIds?: Array<string | null> | null;
};

export type MutationRemovePartAttributesFromCategoryBodyInput = {
  partAttributeIds?: Array<string | null> | null;
};

export type MutationAddPartImageWithDocumentIdQueryInput = {
  partId?: string | null;
  documentId?: string | null;
  isDefault?: boolean | null;
};

export type MutationAddPartImageWithDocumentIdBodyInput = {
  partId?: string | null;
  documentId?: string | null;
  isDefault?: boolean | null;
};

export type MutationUpdatePartImageWithDocumentIdParamsInput = {
  partImageId: string;
};

export type MutationUpdatePartImageWithDocumentIdQueryInput = {
  documentId?: string | null;
  isDefault?: boolean | null;
  sortOrder?: number | null;
};

export type MutationUpdatePartImageWithDocumentIdBodyInput = {
  documentId?: string | null;
  isDefault?: boolean | null;
  sortOrder?: number | null;
};

export type MutationDeletePartImageParamsInput = {
  partImageId: string;
};

export type MutationAddUnitOfMeasureQueryInput = {
  companyId?: string | null;
  unitOfMeasureTypeId?: string | null;
  unitOfMeasureName?: string | null;
  unitOfMeasureCode?: string | null;
};

export type MutationAddUnitOfMeasureBodyInput = {
  companyId?: string | null;
  unitOfMeasureTypeId?: string | null;
  unitOfMeasureName?: string | null;
  unitOfMeasureCode?: string | null;
};

export type MutationAddTakeoffPadQueryInput = {
  projectId?: string | null;
  takeoffPadName?: string | null;
};

export type MutationAddTakeoffPadBodyInput = {
  projectId?: string | null;
  takeoffPadName?: string | null;
};

export type TakeOffPad = {
  __typename: 'TakeOffPad';
  takeoffPadId: string;
  takeoffPadName: string;
  lineItemContainerId?: string | null;
  lineItemContainerStatusId?: string | null;
  lineItemContainerStatusName?: string | null;
  projectId: string;
  lineItemContainerName?: string | null;
  hasMappedItems?: boolean | null;
  hasItemsMappedFromSourcePad?: boolean | null;
  createdOn: string;
  createdBy: string;
  createdByFirstName?: string | null;
  createdByLastName?: string | null;
  createdByEmail?: string | null;
  updatedOn?: string | null;
  updatedBy?: string | null;
  updatedByFirstName?: string | null;
  updatedByLastName?: string | null;
  updatedByEmail?: string | null;
};

export type MutationMoveTakeoffPadItemsQueryInput = {
  TakeoffPadId?: string | null;
  BillOfMaterialsId?: string | null;
  SendSpecificQuanties?: boolean | null;
  LineItems?: Array<TakeOffPadLineItemInput | null> | null;
};

export type TakeOffPadLineItemInput = {
  LineItemId: string;
  Quantity: number;
};

export type MutationMoveTakeoffPadItemsBodyInput = {
  TakeoffPadId?: string | null;
  BillOfMaterialsId?: string | null;
  SendSpecificQuanties?: boolean | null;
  LineItems?: Array<TakeOffPadLineItemInput | null> | null;
};

export type SourcePadItem = {
  __typename: 'SourcePadItem';
  previousLineItemId?: string | null;
  lineItemId?: string | null;
  lineItemTypeId?: string | null;
  lineItemTypeName?: string | null;
  sourcePadId?: string | null;
  unitOfMeasureId?: string | null;
  unitOfMeasureName?: string | null;
  unitOfMeasureCode?: string | null;
  manufacturerId?: string | null;
  manufacturerName?: string | null;
  partId?: string | null;
  locationId?: string | null;
  workPhaseId?: string | null;
  scopePackageId?: string | null;
  workPackageId?: string | null;
  kitPlanId?: string | null;
  spoolId?: string | null;
  lineItemName?: string | null;
  manufacturerCatalogNumber?: string | null;
  quantity?: number | null;
  actualQuantity?: number | null;
  remainingQuantity?: number | null;
  actualRemainingQuantity?: number | null;
  unitQuantity?: number | null;
  tradeSize?: string | null;
  takeoffPadName?: string | null;
  createdOn?: string | null;
  createdBy?: string | null;
  createdByFirstName?: string | null;
  createdByLastName?: string | null;
  createdByEmail?: string | null;
  updatedOn?: string | null;
  updatedBy?: string | null;
  updatedByFirstName?: string | null;
  updatedByLastName?: string | null;
  updatedByEmail?: string | null;
};

export type MutationDeleteTakeoffPadParamsInput = {
  takeoffPadId: string;
};

export type MutationRenameTakeOffPadParamsInput = {
  takeoffPadId: string;
};

export type MutationRenameTakeOffPadQueryInput = {
  takeoffPadName?: string | null;
};

export type MutationRenameTakeOffPadBodyInput = {
  takeoffPadName?: string | null;
};

export type MutationAddTakeOffPadItemQueryInput = {
  takeoffpadId?: string | null;
  items?: Array<TakeOffPadItemInput | null> | null;
};

export type TakeOffPadItemInput = {
  lineItemTypeId: string;
  unitOfMeasureId?: string | null;
  manufacturerId?: string | null;
  locationId?: string | null;
  workPhaseId?: string | null;
  scopePackageId?: string | null;
  lineItemName?: string | null;
  manufacturerCatalogNumber?: string | null;
  quantity: number;
  partId?: string | null;
  tradeSize?: string | null;
  unitQuantity?: number | null;
};

export type MutationAddTakeOffPadItemBodyInput = {
  takeoffpadId?: string | null;
  items?: Array<TakeOffPadItemInput | null> | null;
};

export type MutationDeleteTakeOffPadItemQueryInput = {
  lineItemIds?: Array<string | null> | null;
};

export type MutationDeleteTakeOffPadItemBodyInput = {
  lineItemIds?: Array<string | null> | null;
};

export type MutationAddShopConfigurationQueryInput = {
  ShopConfigurationName?: string | null;
  ShopConfigurationDescription?: string | null;
  FacilityId?: string | null;
  DepartmentId?: string | null;
  IsActive?: boolean | null;
};

export type MutationAddShopConfigurationBodyInput = {
  ShopConfigurationName?: string | null;
  ShopConfigurationDescription?: string | null;
  FacilityId?: string | null;
  DepartmentId?: string | null;
  IsActive?: boolean | null;
};

export type ShopConfiguration = {
  __typename: 'ShopConfiguration';
  shopConfigurationId?: string | null;
  shopConfigurationName?: string | null;
  shopConfigurationDescription?: string | null;
  departmentId?: string | null;
  facilityId?: string | null;
  isActive?: boolean | null;
};

export type MutationUpdateShopConfigurationParamsInput = {
  shopConfigurationId: string;
};

export type MutationUpdateShopConfigurationQueryInput = {
  ShopConfigurationName?: string | null;
  ShopConfigurationDescription?: string | null;
  FacilityId?: string | null;
  DepartmentId?: string | null;
  IsActive?: boolean | null;
};

export type MutationUpdateShopConfigurationBodyInput = {
  ShopConfigurationName?: string | null;
  ShopConfigurationDescription?: string | null;
  FacilityId?: string | null;
  DepartmentId?: string | null;
  IsActive?: boolean | null;
};

export type MutationDeleteShopConfigurationParamsInput = {
  id: string;
};

export type MutationAddPartAttributeSelectVariantQueryInput = {
  partAttributeId?: string | null;
  textValue?: string | null;
  codeValue?: string | null;
};

export type MutationAddPartAttributeSelectVariantBodyInput = {
  partAttributeId?: string | null;
  textValue?: string | null;
  codeValue?: string | null;
};

export type MutationAddShopPropertyQueryInput = {
  shopPropertyName?: string | null;
  shopPropertyDescription?: string | null;
  shippingAddressLine1?: string | null;
  shippingAddressLine2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  country?: string | null;
  userId?: string | null;
  shopConfigurationId?: string | null;
  departmentId?: string | null;
};

export type MutationAddShopPropertyBodyInput = {
  shopPropertyName?: string | null;
  shopPropertyDescription?: string | null;
  shippingAddressLine1?: string | null;
  shippingAddressLine2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  country?: string | null;
  userId?: string | null;
  shopConfigurationId?: string | null;
  departmentId?: string | null;
};

export type ShopProperty = {
  __typename: 'ShopProperty';
  shopPropertyId?: string | null;
  shopPropertyName?: string | null;
  shopPropertyDescription?: string | null;
  shippingAddressLine1?: string | null;
  shippingAddressLine2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  country?: string | null;
  userId?: string | null;
  shopConfigurationId?: string | null;
  facilityId?: string | null;
};

export type MutationUpdateShopPropertyParamsInput = {
  shopPropertyId: string;
};

export type MutationUpdateShopPropertyQueryInput = {
  shopPropertyName?: string | null;
  shopPropertyDescription?: string | null;
  shippingAddressLine1?: string | null;
  shippingAddressLine2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  country?: string | null;
  userId?: string | null;
  shopConfigurationId?: string | null;
  departmentId?: string | null;
};

export type MutationUpdateShopPropertyBodyInput = {
  shopPropertyName?: string | null;
  shopPropertyDescription?: string | null;
  shippingAddressLine1?: string | null;
  shippingAddressLine2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  country?: string | null;
  userId?: string | null;
  shopConfigurationId?: string | null;
  departmentId?: string | null;
};

export type MutationAddWorkCellQueryInput = {
  workCellName?: string | null;
  workCellDescription?: string | null;
  facilityId?: string | null;
  shopConfigurationId?: string | null;
};

export type MutationAddWorkCellBodyInput = {
  workCellName?: string | null;
  workCellDescription?: string | null;
  facilityId?: string | null;
  shopConfigurationId?: string | null;
};

export type WorkCell = {
  __typename: 'WorkCell';
  workCellId: string;
  workCellName: string;
  workCellDescription?: string | null;
  facilityId: string;
  shopConfigurationId: string;
  hasTaskTypes: boolean;
  hasMachines: boolean;
};

export type MutationUpdateWorkCellParamsInput = {
  workcellId: string;
};

export type MutationUpdateWorkCellQueryInput = {
  workCellName?: string | null;
  workCellDescription?: string | null;
  facilityId?: string | null;
  shopConfigurationId?: string | null;
};

export type MutationUpdateWorkCellBodyInput = {
  workCellName?: string | null;
  workCellDescription?: string | null;
  facilityId?: string | null;
  shopConfigurationId?: string | null;
};

export type MutationUpdateWorkCellTaskParamsInput = {
  workCellTaskId: string;
};

export type MutationUpdateWorkCellTaskQueryInput = {
  workCellId?: string | null;
  sortOrder?: number | null;
};

export type MutationUpdateWorkCellTaskBodyInput = {
  workCellId?: string | null;
  sortOrder?: number | null;
};

export type UpdatedWorkCellTask = {
  __typename: 'UpdatedWorkCellTask';
  workCellTaskId?: string | null;
  workCellId?: string | null;
  taskId?: string | null;
  workOrderId?: string | null;
  sortOrder?: number | null;
};

export type MutationDeleteWorkCellTaskParamsInput = {
  workCellTaskId: string;
};

export type MutationDeleteWorkCellParamsInput = {
  workCellId: string;
};

export type MutationDeleteWorkCellTaskTypeParamsInput = {
  workCellTaskTypeId: string;
};

export type MutationDeleteMachineTaskTypeParamsInput = {
  machineTaskTypeId: string;
};

export type MutationMapSourcePadItemQueryInput = {
  takeoffPadId?: string | null;
  sourcePadId?: string | null;
  lineItems?: Array<SourcePadMapInput | null> | null;
};

export type SourcePadMapInput = {
  lineItemId: string;
  quantity: number;
};

export type MutationMapSourcePadItemBodyInput = {
  takeoffPadId?: string | null;
  sourcePadId?: string | null;
  lineItems?: Array<SourcePadMapInput | null> | null;
};

export type MutationMapTaskTypeToWorkCellQueryInput = {
  taskTypeId?: string | null;
  workCellIds?: Array<string | null> | null;
};

export type MutationMapTaskTypeToWorkCellBodyInput = {
  taskTypeId?: string | null;
  workCellIds?: Array<string | null> | null;
};

export type MappedWorkCellTaskType = {
  __typename: 'MappedWorkCellTaskType';
  workCellTaskTypeId: string;
  taskTypeId: string;
  workCellId: string;
};

export type MutationMapTaskTypeToMachineQueryInput = {
  taskTypeId?: string | null;
  workCellId?: string | null;
  machineIds?: Array<string | null> | null;
};

export type MutationMapTaskTypeToMachineBodyInput = {
  taskTypeId?: string | null;
  workCellId?: string | null;
  machineIds?: Array<string | null> | null;
};

export type MappedMachineTaskType = {
  __typename: 'MappedMachineTaskType';
  machineTaskTypeId: string;
  taskTypeId: string;
  machineId: string;
};

export type MutationGetDocumentPresignedURLQueryInput = {
  expirationHours?: number | null;
  objectKey?: string | null;
  requestedBy?: string | null;
  verb?: string | null;
  ContentType?: string | null;
};

export type MutationGetDocumentPresignedURLBodyInput = {
  expirationHours?: number | null;
  objectKey?: string | null;
  requestedBy?: string | null;
  verb?: string | null;
  ContentType?: string | null;
};

export type PresignedURL = {
  __typename: 'PresignedURL';
  objectKey?: string | null;
  expirationHours?: number | null;
  preSignedURL?: string | null;
  contentType?: string | null;
};

export type MutationAddFolderQueryInput = {
  companyId?: string | null;
  folderName?: string | null;
  storagePath?: string | null;
  requestedBy?: string | null;
  folderDescription?: string | null;
  parentFolderId?: string | null;
};

export type MutationAddFolderBodyInput = {
  companyId?: string | null;
  folderName?: string | null;
  storagePath?: string | null;
  requestedBy?: string | null;
  folderDescription?: string | null;
  parentFolderId?: string | null;
};

export type Folder = {
  __typename: 'Folder';
  folderId: string;
  parentFolderId?: string | null;
  companyId: string;
  folderName: string;
  storagePath: string;
  folderDescription?: string | null;
  hasSubFolder?: boolean | null;
  isHidden?: boolean | null;
  projectId: string;
};

export type MutationUpdateFolderParamsInput = {
  folderId: string;
};

export type MutationUpdateFolderQueryInput = {
  companyId?: string | null;
  folderName?: string | null;
  storagePath?: string | null;
  folderDescription?: string | null;
  parentFolderId?: string | null;
};

export type MutationUpdateFolderBodyInput = {
  companyId?: string | null;
  folderName?: string | null;
  storagePath?: string | null;
  folderDescription?: string | null;
  parentFolderId?: string | null;
};

export type MutationDeleteFolderParamsInput = {
  folderId: string;
};

export type MutationAddDocumentQueryInput = {
  folderId?: string | null;
  documentTypeId?: string | null;
  documentIdentifier?: string | null;
  documentName?: string | null;
  requestedBy?: string | null;
  storagePath?: string | null;
  extension?: string | null;
};

export type MutationAddDocumentBodyInput = {
  folderId?: string | null;
  documentTypeId?: string | null;
  documentIdentifier?: string | null;
  documentName?: string | null;
  requestedBy?: string | null;
  storagePath?: string | null;
  extension?: string | null;
};

export type MutationUpdateDocumentParamsInput = {
  documentId: string;
};

export type MutationUpdateDocumentQueryInput = {
  documentIdentifier?: string | null;
  documentName?: string | null;
  folderId?: string | null;
  moveToFolder?: boolean | null;
};

export type MutationUpdateDocumentBodyInput = {
  documentIdentifier?: string | null;
  documentName?: string | null;
  folderId?: string | null;
  moveToFolder?: boolean | null;
};

export type MutationMapWorkPhaseToLocationQueryInput = {
  locationId?: string | null;
  workphasesIds?: Array<string | null> | null;
};

export type MutationMapWorkPhaseToLocationBodyInput = {
  locationId?: string | null;
  workphasesIds?: Array<string | null> | null;
};

export type MapWorkPhasesToLocationResponse = {
  __typename: 'MapWorkPhasesToLocationResponse';
  locationId: string;
  location: Location;
  workPhases: Array<WorkPhase | null>;
};

export type MutationUnmapWorkPhaseFromLocationParamsInput = {
  locationPhaseId: string;
};

export type MutationAddMachineQueryInput = {
  facilityId?: string | null;
  workCellId?: string | null;
  manufacturer?: string | null;
  model?: string | null;
  serialNumber?: string | null;
  machineImageId?: string | null;
  machineIdentifier?: string | null;
};

export type MutationAddMachineBodyInput = {
  facilityId?: string | null;
  workCellId?: string | null;
  manufacturer?: string | null;
  model?: string | null;
  serialNumber?: string | null;
  machineImageId?: string | null;
  machineIdentifier?: string | null;
};

export type Machine = {
  __typename: 'Machine';
  machineId: string;
  manufacturer?: string | null;
  model?: string | null;
  serialNumber?: string | null;
  machineImageId?: string | null;
  facilityId: string;
  machineIdentifier: string;
  workCells?: Array<MachineWorkCell | null> | null;
};

export type MachineWorkCell = {
  __typename: 'MachineWorkCell';
  workCellId: string;
  shopConfigurationId: string;
};

export type MutationUpdateMachineParamsInput = {
  machineId: string;
};

export type MutationUpdateMachineQueryInput = {
  workCellId?: string | null;
  manufacturer?: string | null;
  model?: string | null;
  serialNumber?: string | null;
  machineImageId?: string | null;
  facilityId?: string | null;
  machineIdentifier?: string | null;
};

export type MutationUpdateMachineBodyInput = {
  workCellId?: string | null;
  manufacturer?: string | null;
  model?: string | null;
  serialNumber?: string | null;
  machineImageId?: string | null;
  facilityId?: string | null;
  machineIdentifier?: string | null;
};

export type MutationDeleteWorkCellMachineParamsInput = {
  workCellMachineId: string;
};

export type MutationAddWorkCellMachineQueryInput = {
  workCellIds?: Array<string> | null;
  machineId?: string | null;
};

export type MutationAddWorkCellMachineBodyInput = {
  workCellIds?: Array<string> | null;
  machineId?: string | null;
};

export type WorkCellMachine = {
  __typename: 'WorkCellMachine';
  workCellMachineId: string;
  facilityId: string;
  shopConfigurationId: string;
  workCellId: string;
  machineId: string;
  machineManufacturer?: string | null;
  machineIdentifier?: string | null;
};

export type MutationSyncDeleteConnectionQueryInput = {
  ConnectionIds?: Array<string | null> | null;
};

export type MutationSyncDeleteConnectionBodyInput = {
  ConnectionIds?: Array<string | null> | null;
};

export type DeletedConnectionList = {
  __typename: 'DeletedConnectionList';
  connectionsInUsed?: Array<ConnectorFieldItem | null> | null;
};

export type ConnectorFieldItem = {
  __typename: 'ConnectorFieldItem';
  id?: string | null;
  name?: string | null;
};

export type MutationSyncCreateConnectionParamsInput = {
  connection: string;
};

export type MutationSyncCreateConnectionQueryInput = {
  ConnectionName?: string | null;
  AuthenticationCode?: string | null;
  Configuration?: string | null;
  ConnectionId?: string | null;
  DesktopConnectionId?: string | null;
};

export type MutationSyncCreateConnectionBodyInput = {
  ConnectionName?: string | null;
  AuthenticationCode?: string | null;
  Configuration?: string | null;
  ConnectionId?: string | null;
  DesktopConnectionId?: string | null;
};

export type MutationSyncUpdateConnectionParamsInput = {
  connection: string;
};

export type MutationSyncUpdateConnectionQueryInput = {
  connectionId?: string | null;
  ConnectionName?: string | null;
  AuthenticationCode?: string | null;
  Configuration?: string | null;
};

export type MutationSyncUpdateConnectionBodyInput = {
  connectionId?: string | null;
  ConnectionName?: string | null;
  AuthenticationCode?: string | null;
  Configuration?: string | null;
};

export type MutationUpdateWorkOrderParamsInput = {
  workOrderId: string;
};

export type MutationUpdateWorkOrderQueryInput = {
  workOrderStatusTypeId?: string | null;
  workOrderName?: string | null;
  workOrderNumber?: string | null;
  workOrderDescription?: string | null;
  sortOrder?: string | null;
};

export type MutationUpdateWorkOrderBodyInput = {
  workOrderStatusTypeId?: string | null;
  workOrderName?: string | null;
  workOrderNumber?: string | null;
  workOrderDescription?: string | null;
  sortOrder?: string | null;
};

declare const workOrderIdSymbol: unique symbol;
export type WorkOrderId = string & { [workOrderIdSymbol]: never };

export type WorkOrder = {
  __typename: 'WorkOrder';
  workOrderId: WorkOrderId;
  workRequestId?: string | null;
  workOrderStatusTypeId?: string | null;
  workOrderStatusTypeName?: string | null;
  workOrderName?: string | null;
  workOrderDescription?: string | null;
  workOrderNumber?: string | null;
  workRequest?: WorkRequest | null;
  sortOrder?: number | null;
  createdBy?: string | null;
  lastStatusUpdate?: string | null;
  createdOn?: string | null;
  isStepFunctionProcessing?: boolean | null;
  stepFunctionProcessingMessage?: string | null;
  numberOfAttachments?: number | null;
  hasCustomWorkOrderName?: boolean | null;
};

export type MutationDeleteWorkOrderParamsInput = {
  workOrderId: WorkOrderId;
};

export type MutationSyncCreateWorkflowQueryInput = {
  WorkflowName?: string | null;
  MappingName?: string | null;
  TemplateMappingId?: string | null;
};

export type MutationSyncCreateWorkflowBodyInput = {
  WorkflowName?: string | null;
  MappingName?: string | null;
  TemplateMappingId?: string | null;
};

export type NewWorkflow = {
  __typename: 'NewWorkflow';
  workflowId: string;
  workflowName?: string | null;
  workflowMapping?: NewWorkflowMappingItem | null;
};

export type NewWorkflowMappingItem = {
  __typename: 'NewWorkflowMappingItem';
  workflowMappingId: string;
  workflowMappingName?: string | null;
  isSyncRunning?: boolean | null;
  lastSyncAt?: string | null;
  lastSavedAt?: string | null;
  solitaryFlows?: Array<NewSolitaryFlowItem | null> | null;
};

export type NewSolitaryFlowItem = {
  __typename: 'NewSolitaryFlowItem';
  solitaryFlowId?: string | null;
  action?: string | null;
  actionNodeId?: string | null;
  actionAlias?: string | null;
  source?: MappingFlowConfigurationSourceNodeInfo | null;
  destinations?: Array<MappingFlowConfigurationDestinationNodeInfo | null> | null;
  syncStatus?: number | null;
};

export type MappingFlowConfigurationSourceNodeInfo = {
  __typename: 'MappingFlowConfigurationSourceNodeInfo';
  syncDetailsId?: string | null;
  nodeId?: string | null;
  connection?: SyncConnection | null;
  hub?: ConnectorFieldItem | null;
  projects?: Array<ConnectorProjectItem | null> | null;
  additionalData?: string | null;
  settings?: MappingConfigurationSettings | null;
};

export type SyncConnection = {
  __typename: 'SyncConnection';
  id: string;
  name?: string | null;
  type?: string | null;
  configuration?: string | null;
  isConnectionUsed?: boolean | null;
  lastModified?: string | null;
  desktopConnectionId?: string | null;
};

export type ConnectorProjectItem = {
  __typename: 'ConnectorProjectItem';
  id?: string | null;
  name?: string | null;
  folders?: Array<ConnectorFieldFolder | null> | null;
};

export type ConnectorFieldFolder = {
  __typename: 'ConnectorFieldFolder';
  id?: string | null;
  name?: string | null;
  items?: Array<ConnectorFieldItem | null> | null;
};

export type MappingConfigurationSettings = {
  __typename: 'MappingConfigurationSettings';
  isSyncEnabled?: boolean | null;
  includeExtensions?: string | null;
  secondaryFilesSetting?: string | null;
  isSyncAllRevisions?: boolean | null;
  syncType?: string | null;
  scheduleSyncConfig?: string | null;
};

export type MappingFlowConfigurationDestinationNodeInfo = {
  __typename: 'MappingFlowConfigurationDestinationNodeInfo';
  syncDetailsId?: string | null;
  nodeId?: string | null;
  connection?: SyncConnection | null;
  hub?: ConnectorFieldItem | null;
  project?: ConnectorProjectSingleFieldItem | null;
  additionalData?: string | null;
};

export type ConnectorProjectSingleFieldItem = {
  __typename: 'ConnectorProjectSingleFieldItem';
  id?: string | null;
  name?: string | null;
  folder?: ConnectorFieldItem | null;
};

export type MutationSyncRenameWorkflowQueryInput = {
  WorkflowName?: string | null;
  WorkflowId?: string | null;
};

export type MutationSyncRenameWorkflowBodyInput = {
  WorkflowName?: string | null;
  WorkflowId?: string | null;
};

export type MutationSyncDuplicateWorkflowQueryInput = {
  WorkflowName?: string | null;
  WorkflowId?: string | null;
};

export type MutationSyncDuplicateWorkflowBodyInput = {
  WorkflowName?: string | null;
  WorkflowId?: string | null;
};

export type MutationSyncCreateMappingQueryInput = {
  WorkflowId?: string | null;
  MappingName?: string | null;
  TemplateMappingId?: string | null;
};

export type MutationSyncCreateMappingBodyInput = {
  WorkflowId?: string | null;
  MappingName?: string | null;
  TemplateMappingId?: string | null;
};

export type MutationSyncRenameMappingQueryInput = {
  WorkflowId?: string | null;
  MappingName?: string | null;
  MappingId?: string | null;
};

export type MutationSyncRenameMappingBodyInput = {
  WorkflowId?: string | null;
  MappingName?: string | null;
  MappingId?: string | null;
};

export type MutationSyncDuplicateMappingQueryInput = {
  WorkflowId?: string | null;
  MappingName?: string | null;
  MappingId?: string | null;
};

export type MutationSyncDuplicateMappingBodyInput = {
  WorkflowId?: string | null;
  MappingName?: string | null;
  MappingId?: string | null;
};

export type MutationSyncDeleteWorkflowQueryInput = {
  WorkflowIds?: Array<string | null> | null;
  MappingIds?: Array<string | null> | null;
  SolitaryFlowIds?: Array<string | null> | null;
  Email?: string | null;
};

export type MutationSyncDeleteWorkflowBodyInput = {
  WorkflowIds?: Array<string | null> | null;
  MappingIds?: Array<string | null> | null;
  SolitaryFlowIds?: Array<string | null> | null;
  Email?: string | null;
};

export type MutationSyncArchiveWorkflowQueryInput = {
  WorkflowIds?: Array<string | null> | null;
  MappingIds?: Array<string | null> | null;
  SolitaryFlowIds?: Array<string | null> | null;
};

export type MutationSyncArchiveWorkflowBodyInput = {
  WorkflowIds?: Array<string | null> | null;
  MappingIds?: Array<string | null> | null;
  SolitaryFlowIds?: Array<string | null> | null;
};

export type MutationSyncRestoreWorkflowQueryInput = {
  WorkflowIds?: Array<string | null> | null;
  MappingIds?: Array<string | null> | null;
  SolitaryFlowIds?: Array<string | null> | null;
};

export type MutationSyncRestoreWorkflowBodyInput = {
  WorkflowIds?: Array<string | null> | null;
  MappingIds?: Array<string | null> | null;
  SolitaryFlowIds?: Array<string | null> | null;
};

export type MutationSyncDeactivateWorkflowQueryInput = {
  WorkflowIds?: Array<string | null> | null;
  MappingIds?: Array<string | null> | null;
  SolitaryFlowIds?: Array<string | null> | null;
};

export type MutationSyncDeactivateWorkflowBodyInput = {
  WorkflowIds?: Array<string | null> | null;
  MappingIds?: Array<string | null> | null;
  SolitaryFlowIds?: Array<string | null> | null;
};

export type MutationSyncActivateWorkflowQueryInput = {
  WorkflowIds?: Array<string | null> | null;
  MappingIds?: Array<string | null> | null;
  SolitaryFlowIds?: Array<string | null> | null;
};

export type MutationSyncActivateWorkflowBodyInput = {
  WorkflowIds?: Array<string | null> | null;
  MappingIds?: Array<string | null> | null;
  SolitaryFlowIds?: Array<string | null> | null;
};

export type MutationSyncDeleteWebhookQueryInput = {
  WebhooksIds?: Array<string | null> | null;
  Email?: string | null;
};

export type MutationSyncDeleteWebhookBodyInput = {
  WebhooksIds?: Array<string | null> | null;
  Email?: string | null;
};

export type MutationSyncDeactivateWebhookQueryInput = {
  WebhookIds?: Array<string | null> | null;
};

export type MutationSyncDeactivateWebhookBodyInput = {
  WebhookIds?: Array<string | null> | null;
};

export type MutationSyncActivateWebhookQueryInput = {
  WebhookIds?: Array<string | null> | null;
};

export type MutationSyncActivateWebhookBodyInput = {
  WebhookIds?: Array<string | null> | null;
};

export type MutationSyncDeleteTemplateQueryInput = {
  TemplateIds?: Array<string | null> | null;
};

export type MutationSyncDeleteTemplateBodyInput = {
  TemplateIds?: Array<string | null> | null;
};

export type MutationSyncRenameTemplateQueryInput = {
  TemplateId?: string | null;
  TemplateName?: string | null;
};

export type MutationSyncRenameTemplateBodyInput = {
  TemplateId?: string | null;
  TemplateName?: string | null;
};

export type MutationSyncDuplicateTemplateQueryInput = {
  TemplateId?: string | null;
  TemplateName?: string | null;
};

export type MutationSyncDuplicateTemplateBodyInput = {
  TemplateId?: string | null;
  TemplateName?: string | null;
};

export type MutationAddTaskQueryInput = {
  taskTypeIds?: Array<string | null> | null;
  billOfProcessId?: string | null;
  taskStatusTypeId?: string | null;
  taskName?: string | null;
  taskDescription?: string | null;
};

export type MutationAddTaskBodyInput = {
  taskTypeIds?: Array<string | null> | null;
  billOfProcessId?: string | null;
  taskStatusTypeId?: string | null;
  taskName?: string | null;
  taskDescription?: string | null;
};

export type Task = {
  __typename: 'Task';
  taskId?: string | null;
  taskTypeId?: string | null;
  billOfProcessId?: string | null;
  workRequestItemId?: string | null;
  taskStatusTypeId?: string | null;
  taskPredecessorIds?: Array<TaskPredecessor | null> | null;
  taskPredecessorDeletedIds?: Array<string | null> | null;
  taskTypeIds?: Array<string | null> | null;
  taskName?: string | null;
  taskDescription?: string | null;
  taskStatusTypeName?: string | null;
  sortOrder?: number | null;
  isEnabled?: boolean | null;
  createdOn?: string | null;
  time?: Time | null;
  hasDocuments?: boolean | null;
  hasDrawingRegisterDocuments?: boolean | null;
  hasCommonDocuments?: boolean | null;
  hasExternalDocuments?: boolean | null;
  quantity?: number | null;
  needBy?: string | null;
};

export type TaskPredecessor = {
  __typename: 'TaskPredecessor';
  taskId?: string | null;
  taskName?: string | null;
  taskStatusTypeId?: string | null;
};

export type Time = {
  __typename: 'Time';
  totalTime?: string | null;
  events?: Array<Event | null> | null;
};

export type Event = {
  __typename: 'Event';
  taskStatusTypeId?: string | null;
  description?: string | null;
  timesStamp?: string | null;
  userId?: string | null;
};

export type MutationUpdateTaskParamsInput = {
  taskId: string;
};

export type MutationUpdateTaskQueryInput = {
  billOfProcessId?: string | null;
  taskTypeId?: string | null;
  taskName?: string | null;
  taskDescription?: string | null;
  taskStatusTypeId?: string | null;
  isEnabled?: boolean | null;
  sortOrder?: number | null;
  taskPredecessorIds?: Array<string | null> | null;
};

export type MutationUpdateTaskBodyInput = {
  billOfProcessId?: string | null;
  taskTypeId?: string | null;
  taskName?: string | null;
  taskDescription?: string | null;
  taskStatusTypeId?: string | null;
  isEnabled?: boolean | null;
  sortOrder?: number | null;
  taskPredecessorIds?: Array<string | null> | null;
};

export type MutationUpdateTaskMultipleQueryInput = {
  tasks?: Array<UpdateTaskInput | null> | null;
};

export type UpdateTaskInput = {
  taskId?: string | null;
  isSaveData?: boolean | null;
};

export type MutationUpdateTaskMultipleBodyInput = {
  tasks?: Array<UpdateTaskInput | null> | null;
};

export type MutationDeleteTaskParamsInput = {
  taskId: string;
};

export type MutationDeleteSourcePadParamsInput = {
  sourcePadId: string;
};

export type MutationCreateAutodeskFieldFolderQueryInput = {
  connectionId?: string | null;
  ProjectId?: string | null;
  ParentFolderId?: string | null;
  Name?: string | null;
};

export type MutationCreateAutodeskFieldFolderBodyInput = {
  connectionId?: string | null;
  ProjectId?: string | null;
  ParentFolderId?: string | null;
  Name?: string | null;
};

export type NewAutodeskFieldFolder = {
  __typename: 'NewAutodeskFieldFolder';
  success?: boolean | null;
};

export type MutationCreateAutodeskForgeFolderQueryInput = {
  connectionId?: string | null;
  ProjectId?: string | null;
  ParentFolderId?: string | null;
  Name?: string | null;
};

export type MutationCreateAutodeskForgeFolderBodyInput = {
  connectionId?: string | null;
  ProjectId?: string | null;
  ParentFolderId?: string | null;
  Name?: string | null;
};

export type NewAutodeskForgeFolder = {
  __typename: 'NewAutodeskForgeFolder';
  success?: boolean | null;
  name?: string | null;
};

export type MutationCreateProcoreFolderParamsInput = {
  folderType: string;
};

export type MutationCreateProcoreFolderQueryInput = {
  connectionId?: string | null;
  CompanyId?: string | null;
  ProjectId?: string | null;
  ParentFolderId?: string | null;
  SelectedFolderId?: string | null;
  Name?: string | null;
};

export type MutationCreateProcoreFolderBodyInput = {
  connectionId?: string | null;
  CompanyId?: string | null;
  ProjectId?: string | null;
  ParentFolderId?: string | null;
  SelectedFolderId?: string | null;
  Name?: string | null;
};

export type NewProcoreFolder = {
  __typename: 'NewProcoreFolder';
  success?: boolean | null;
};

export type MutationCreateSharepointFolderQueryInput = {
  connectionId?: string | null;
  SiteId?: string | null;
  ParentFolderId?: string | null;
  Name?: string | null;
};

export type MutationCreateSharepointFolderBodyInput = {
  connectionId?: string | null;
  SiteId?: string | null;
  ParentFolderId?: string | null;
  Name?: string | null;
};

export type MutationCreateNewformaFolderQueryInput = {
  connectionId?: string | null;
  parentFolderId?: string | null;
  name?: string | null;
};

export type MutationCreateNewformaFolderBodyInput = {
  connectionId?: string | null;
  parentFolderId?: string | null;
  name?: string | null;
};

export type MutationSyncUpdateWorkflowCanvasQueryInput = {
  MappingId?: string | null;
  CanvasNode?: string | null;
};

export type MutationSyncUpdateWorkflowCanvasBodyInput = {
  MappingId?: string | null;
  CanvasNode?: string | null;
};

export type MutationSyncUpdateMappingConfigurationsQueryInput = {
  MappingId?: string | null;
  SolitaryFlowConfigurations?: Array<MappingFlowConfigurationInput | null> | null;
};

export type MappingFlowConfigurationInput = {
  solitaryFlowId?: string | null;
  action?: string | null;
  actionNodeId?: string | null;
  actionAlias?: string | null;
  actionType?: string | null;
  source?: MappingFlowConfigurationSourceNodeInfoInput | null;
  destinations?: Array<MappingFlowConfigurationDestinationNodeInfoInput | null> | null;
};

export type MappingFlowConfigurationSourceNodeInfoInput = {
  syncDetailsId?: string | null;
  nodeId?: string | null;
  connectionId?: string | null;
  hub?: ConnectorFieldItemInput | null;
  projects?: Array<ConnectorProjectItemInput | null> | null;
  additionalData?: string | null;
  settings?: MappingConfigurationSettingsInput | null;
  connectionName?: string | null;
};

export type ConnectorFieldItemInput = {
  id?: string | null;
  name?: string | null;
};

export type ConnectorProjectItemInput = {
  id?: string | null;
  name?: string | null;
  folders?: Array<ConnectorFolderItemInput | null> | null;
};

export type ConnectorFolderItemInput = {
  id?: string | null;
  name?: string | null;
  items?: Array<ConnectorFieldItemInput | null> | null;
};

export type MappingConfigurationSettingsInput = {
  isSyncEnabled?: boolean | null;
  includeExtensions?: string | null;
  secondaryFilesSetting?: string | null;
  isSyncAllRevisions?: boolean | null;
  syncType?: string | null;
  scheduleSyncConfig?: string | null;
};

export type MappingFlowConfigurationDestinationNodeInfoInput = {
  syncDetailsId?: string | null;
  nodeId?: string | null;
  connectionId?: string | null;
  hub?: ConnectorFieldItemInput | null;
  project?: ConnectorProjectSingleFolderItemInput | null;
  additionalData?: string | null;
  connectionName?: string | null;
};

export type ConnectorProjectSingleFolderItemInput = {
  id?: string | null;
  name?: string | null;
  folder?: ConnectorFieldItemInput | null;
};

export type MutationSyncUpdateMappingConfigurationsBodyInput = {
  MappingId?: string | null;
  SolitaryFlowConfigurations?: Array<MappingFlowConfigurationInput | null> | null;
};

export type MutationSyncUpdatePublishActionConfigurationsQueryInput = {
  MappingId?: string | null;
  SolitaryFlowPublishModelConfigurations?: Array<MappingFlowConfigurationInput | null> | null;
};

export type MutationSyncUpdatePublishActionConfigurationsBodyInput = {
  MappingId?: string | null;
  SolitaryFlowPublishModelConfigurations?: Array<MappingFlowConfigurationInput | null> | null;
};

export type MutationSyncCreateMappingTemplateQueryInput = {
  TemplateId?: string | null;
  TemplateName?: string | null;
};

export type MutationSyncCreateMappingTemplateBodyInput = {
  TemplateId?: string | null;
  TemplateName?: string | null;
};

export type WorkflowMappingTemplate = {
  __typename: 'WorkflowMappingTemplate';
  templateId?: string | null;
  templateName?: string | null;
};

export type MutationSyncWorkflowsImportQueryInput = {
  Workflows?: Array<WorkflowImportInput | null> | null;
};

export type WorkflowImportInput = {
  workflowName?: string | null;
  workflowId?: string | null;
  mappings?: Array<ImportWorkflowMappingInput | null> | null;
};

export type ImportWorkflowMappingInput = {
  mappingName?: string | null;
  mappingId?: string | null;
  solitaryFlowConfigurations?: Array<MappingFlowConfigurationInput | null> | null;
  canvasNode?: string | null;
  shouldOverwrite?: boolean | null;
};

export type MutationSyncWorkflowsImportBodyInput = {
  Workflows?: Array<WorkflowImportInput | null> | null;
};

export type WorkflowImportBaseItem = {
  __typename: 'WorkflowImportBaseItem';
  workflows?: Array<WorkflowImportItem | null> | null;
};

export type WorkflowImportItem = {
  __typename: 'WorkflowImportItem';
  workflowName?: string | null;
  workflowId?: string | null;
  workflowError?: string | null;
  mappings?: Array<WorkflowImportMappingItem | null> | null;
};

export type WorkflowImportMappingItem = {
  __typename: 'WorkflowImportMappingItem';
  mappingName?: string | null;
  mappingId?: string | null;
  canvasNode?: string | null;
  mappingError?: string | null;
  solitaryFlowConfigurations?: Array<MappingFlowConfigurationItem | null> | null;
  shouldOverwrite?: boolean | null;
};

export type MappingFlowConfigurationItem = {
  __typename: 'MappingFlowConfigurationItem';
  solitaryFlowId?: string | null;
  action?: string | null;
  actionNodeId?: string | null;
  actionAlias?: string | null;
  source?: MappingFlowConfigurationSourceNodeInfoItem | null;
  destinations?: Array<MappingFlowConfigurationDestinationNodeInfoItem | null> | null;
  solitaryFlowError?: string | null;
};

export type MappingFlowConfigurationSourceNodeInfoItem = {
  __typename: 'MappingFlowConfigurationSourceNodeInfoItem';
  syncDetailsId?: string | null;
  nodeId?: string | null;
  connectionId?: string | null;
  hub?: ConnectorFieldItem | null;
  projects?: Array<ConnectorProjectItem | null> | null;
  additionalData?: string | null;
  settings?: MappingConfigurationSettingsItem | null;
  connectionName?: string | null;
};

export type MappingConfigurationSettingsItem = {
  __typename: 'MappingConfigurationSettingsItem';
  isSyncEnabled?: boolean | null;
  includeExtensions?: string | null;
  secondaryFilesSetting?: string | null;
  isSyncAllRevisions?: boolean | null;
  syncType?: string | null;
  scheduleSyncConfig?: string | null;
};

export type MappingFlowConfigurationDestinationNodeInfoItem = {
  __typename: 'MappingFlowConfigurationDestinationNodeInfoItem';
  syncDetailsId?: string | null;
  nodeId?: string | null;
  connectionId?: string | null;
  hub?: ConnectorFieldItem | null;
  project?: ConnectorProjectSingleFolderItem | null;
  additionalData?: string | null;
  connectionName?: string | null;
};

export type ConnectorProjectSingleFolderItem = {
  __typename: 'ConnectorProjectSingleFolderItem';
  id?: string | null;
  name?: string | null;
  folder?: ConnectorFieldItem | null;
};

export type MutationSyncMultipleWorkflowsQueryInput = {
  Workflows?: Array<MultipleWorkflowsInput | null> | null;
};

export type MultipleWorkflowsInput = {
  workflowId?: string | null;
  mappings?: Array<MultipleWorkflowMappingInput | null> | null;
};

export type MultipleWorkflowMappingInput = {
  mappingId?: string | null;
  solitaryFlowSettings?: Array<MappingFlowStatusSettingInput | null> | null;
};

export type MappingFlowStatusSettingInput = {
  solitaryFlowId?: string | null;
  isSyncEnabled?: boolean | null;
  syncType?: string | null;
  scheduleSyncConfig?: string | null;
  syncStatus?: string | null;
};

export type MutationSyncMultipleWorkflowsBodyInput = {
  Workflows?: Array<MultipleWorkflowsInput | null> | null;
};

export type MultipleWorkflowsBaseItem = {
  __typename: 'MultipleWorkflowsBaseItem';
  workflows?: Array<MultipleWorkflowsItem | null> | null;
};

export type MultipleWorkflowsItem = {
  __typename: 'MultipleWorkflowsItem';
  workflowId?: string | null;
  mappings?: Array<MultipleWorkflowMappingItem | null> | null;
};

export type MultipleWorkflowMappingItem = {
  __typename: 'MultipleWorkflowMappingItem';
  mappingId?: string | null;
  solitaryFlowSettings?: Array<MappingFlowStatusSettingItem | null> | null;
};

export type MappingFlowStatusSettingItem = {
  __typename: 'MappingFlowStatusSettingItem';
  solitaryFlowId?: string | null;
  isSyncEnabled?: boolean | null;
  syncType?: string | null;
  scheduleSyncConfig?: string | null;
  syncStatus?: string | null;
};

export type MutationSyncMigratedWorkflowsQueryInput = {
  workflowIds?: Array<string | null> | null;
  syncType?: string | null;
  scheduleSyncConfig?: string | null;
  interval?: number | null;
  mappingCount?: number | null;
};

export type MutationSyncMigratedWorkflowsBodyInput = {
  workflowIds?: Array<string | null> | null;
  syncType?: string | null;
  scheduleSyncConfig?: string | null;
  interval?: number | null;
  mappingCount?: number | null;
};

export type MutationSyncDeleteMappingDataQueryInput = {
  MappingId?: string | null;
};

export type MutationSyncDeleteMappingDataBodyInput = {
  MappingId?: string | null;
};

export type MutationAddDrawingTypeCategoryQueryInput = {
  userDefinedId?: string | null;
  name?: string | null;
  drawingTypes?: Array<DrawingTypeInput | null> | null;
};

export type DrawingTypeInput = {
  userDefinedId?: string | null;
  name?: string | null;
  filterRules?: Array<DrawingTypeFilterRuleInput | null> | null;
  taskTypes?: Array<DrawingTypeTaskTypeInput | null> | null;
  workPhases?: Array<DrawingTypeWorkPhaseInput | null> | null;
};

export type DrawingTypeFilterRuleInput = {
  filterType?: string | null;
  name?: string | null;
  expressionType?: string | null;
  expression?: string | null;
};

export type DrawingTypeTaskTypeInput = {
  taskTypeId?: string | null;
};

export type DrawingTypeWorkPhaseInput = {
  workPhaseId?: string | null;
};

export type MutationAddDrawingTypeCategoryBodyInput = {
  userDefinedId?: string | null;
  name?: string | null;
  drawingTypes?: Array<DrawingTypeInput | null> | null;
};

export type DrawingTypeCategory = {
  __typename: 'DrawingTypeCategory';
  companyId?: string | null;
  userDefinedId?: string | null;
  name?: string | null;
  hasDrawingTypes?: boolean | null;
  id?: string | null;
  createdBy?: string | null;
  createdOnUtc?: string | null;
  modifiedBy?: string | null;
  modifiedOnUtc?: string | null;
};

export type MutationAddTaskDocumentQueryInput = {
  taskId?: string | null;
  documentIds?: Array<string | null> | null;
  folderId?: string | null;
  externalUrl?: string | null;
};

export type MutationAddTaskDocumentBodyInput = {
  taskId?: string | null;
  documentIds?: Array<string | null> | null;
  folderId?: string | null;
  externalUrl?: string | null;
};

export type TaskDocument = {
  __typename: 'TaskDocument';
  taskDocumentId?: string | null;
  taskId?: string | null;
  documentId?: string | null;
  externalUrl?: string | null;
  createdBy?: string | null;
  createdOn?: string | null;
  documentTypeId?: string | null;
};

export type MutationDeleteTaskDocumentParamsInput = {
  taskDocumentId: string;
};

export type MutationSyncCreateTemplateFromMappingQueryInput = {
  MappingId?: string | null;
  Name?: string | null;
};

export type MutationSyncCreateTemplateFromMappingBodyInput = {
  MappingId?: string | null;
  Name?: string | null;
};

export type MutationSyncDeleteMappingFlowQueryInput = {
  MappingId?: string | null;
  SolitaryFlowId?: string | null;
};

export type MutationSyncDeleteMappingFlowBodyInput = {
  MappingId?: string | null;
  SolitaryFlowId?: string | null;
};

export type MutationSyncFlowNowQueryInput = {
  MappingId?: string | null;
  SolitaryFlowId?: string | null;
};

export type MutationSyncFlowNowBodyInput = {
  MappingId?: string | null;
  SolitaryFlowId?: string | null;
};

export type MutationSyncMultipleFlowNowQueryInput = {
  SolitaryflowIds?: Array<string | null> | null;
};

export type MutationSyncMultipleFlowNowBodyInput = {
  SolitaryflowIds?: Array<string | null> | null;
};

export type MutationSyncStopAllQueryInput = {
  MappingId?: string | null;
};

export type MutationSyncStopAllBodyInput = {
  MappingId?: string | null;
};

export type MutationSyncSaveAllQueryInput = {
  MappingId?: string | null;
  SolitaryFlowSettings?: Array<MappingFlowStatusSettingInput | null> | null;
};

export type MutationSyncSaveAllBodyInput = {
  MappingId?: string | null;
  SolitaryFlowSettings?: Array<MappingFlowStatusSettingInput | null> | null;
};

export type MutationAddBillOfMaterialLineItemByLBSQueryInput = {
  ern?: string | null;
  locationId?: string | null;
  workPhaseId?: string | null;
  amount?: number | null;
};

export type MutationAddBillOfMaterialLineItemByLBSBodyInput = {
  ern?: string | null;
  locationId?: string | null;
  workPhaseId?: string | null;
  amount?: number | null;
};

export type BillOfMaterialLineItemByLBS = {
  __typename: 'BillOfMaterialLineItemByLBS';
  ern?: string | null;
  locationId?: string | null;
  workPhaseId?: string | null;
  amount?: Amount | null;
  parentLocationId?: string | null;
  parentWorkPhases?: Array<string | null> | null;
  children?: Array<Children | null> | null;
  unassignedAmount?: number | null;
  unassignedAmountRemaining?: number | null;
  totalAmount?: number | null;
  totalAmountRemaining?: number | null;
};

export type Amount = {
  __typename: 'Amount';
  assigned?: AmountClass | null;
  available?: AmountClass | null;
};

export type AmountClass = {
  __typename: 'AmountClass';
  individual?: number | null;
  aggregate?: number | null;
};

export type Children = {
  __typename: 'Children';
  locationId?: string | null;
  workPhaseId?: string | null;
  amount?: Amount | null;
};

export type MutationAddBillOfMaterialLineItemByLBSBulkQueryInput = {
  bulk?: Array<BillOfMaterialLineItemByLBSBulk | null> | null;
};

export type BillOfMaterialLineItemByLBSBulk = {
  ern?: string | null;
  amounts?: Array<BulkAmount | null> | null;
};

export type BulkAmount = {
  locationId?: string | null;
  workPhaseId?: string | null;
  amount?: number | null;
  autoPopulate?: boolean | null;
};

export type MutationAddBillOfMaterialLineItemByLBSBulkBodyInput = {
  bulk?: Array<BillOfMaterialLineItemByLBSBulk | null> | null;
};

export type MutationPatchBillOfMaterialLineItemByLBSQueryInput = {
  ern?: string | null;
  locationId?: string | null;
  workPhaseId?: string | null;
  operation?: string | null;
  amount?: number | null;
};

export type MutationPatchBillOfMaterialLineItemByLBSBodyInput = {
  ern?: string | null;
  locationId?: string | null;
  workPhaseId?: string | null;
  operation?: string | null;
  amount?: number | null;
};

export type MutationPatchBulkBillOfMaterialLineItemByLBSQueryInput = {
  ern?: string | null;
  operation?: string | null;
  lbs?: Array<BulkLBSResponseInput | null> | null;
};

export type BulkLBSResponseInput = {
  locationId?: string | null;
  workPhaseId?: string | null;
  amount: number;
  children?: Array<BulkLBSResponseInput | null> | null;
};

export type MutationPatchBulkBillOfMaterialLineItemByLBSBodyInput = {
  ern?: string | null;
  operation?: string | null;
  lbs?: Array<BulkLBSResponseInput | null> | null;
};

export type LBSBulkResponse = {
  __typename: 'LBSBulkResponse';
  key: string;
  value: string;
};

export type MutationPatchBulk2BillOfMaterialLineItemByLBSQueryInput = {
  bulk?: Array<Bulk2LBSInput | null> | null;
};

export type Bulk2LBSInput = {
  ern: string;
  amounts: Array<AmountBulk2 | null>;
};

export type AmountBulk2 = {
  locationId?: string | null;
  workPhaseId?: string | null;
  amount: number;
  autoPopulate?: boolean | null;
};

export type MutationPatchBulk2BillOfMaterialLineItemByLBSBodyInput = {
  bulk?: Array<Bulk2LBSInput | null> | null;
};

export type MutationQuantitiesCreateQueryInput = {
  ern?: string | null;
  amount?: number | null;
  properties?: PropertiesInput | null;
  parentResourceERN?: string | null;
};

export type PropertiesInput = {
  companyId?: string | null;
  projectId?: string | null;
};

export type MutationQuantitiesCreateBodyInput = {
  ern?: string | null;
  amount?: number | null;
  properties?: PropertiesInput | null;
  parentResourceERN?: string | null;
};

export type QuantitiesCreateResponse = {
  __typename: 'QuantitiesCreateResponse';
  ern?: string | null;
  resourceId?: string | null;
  properties?: GetProperties | null;
  amount?: number | null;
  amountBreakdown?: Array<AmountBreakdownProperties | null> | null;
  constraints?: Array<string | null> | null;
};

export type GetProperties = {
  __typename: 'GetProperties';
  companyId?: string | null;
  projectId?: string | null;
};

export type AmountBreakdownProperties = {
  __typename: 'AmountBreakdownProperties';
  amountId?: string | null;
  amount?: number | null;
  children?: Array<ChildAmountBreakdown | null> | null;
};

export type ChildAmountBreakdown = {
  __typename: 'ChildAmountBreakdown';
  childAmountId?: string | null;
  childResourceERN?: string | null;
};

export type MutationQuantitiesPatchQueryInput = {
  ern?: string | null;
  amount?: number | null;
  ParentResourceERN?: string | null;
  operation?: string | null;
};

export type MutationQuantitiesPatchBodyInput = {
  ern?: string | null;
  amount?: number | null;
  ParentResourceERN?: string | null;
  operation?: string | null;
};

export type MutationAddAssemblyCustomCategoryQueryInput = {
  assemblyCustomCategoryName?: string | null;
  companyId?: string | null;
  workFlowId?: string | null;
};

export type MutationAddAssemblyCustomCategoryBodyInput = {
  assemblyCustomCategoryName?: string | null;
  companyId?: string | null;
  workFlowId?: string | null;
};

export type AssemblyCustomCategory = {
  __typename: 'AssemblyCustomCategory';
  assemblyCustomCategoryId: string;
  workFlowId?: string | null;
  companyId?: string | null;
  assemblyCustomCategoryName: string;
};

export type MutationUpdateAssemblyCustomCategoryParamsInput = {
  assemblyCustomCategoryId: string;
};

export type MutationUpdateAssemblyCustomCategoryQueryInput = {
  assemblyCustomCategoryName?: string | null;
  workFlowId?: string | null;
  companyId?: string | null;
};

export type MutationUpdateAssemblyCustomCategoryBodyInput = {
  assemblyCustomCategoryName?: string | null;
  workFlowId?: string | null;
  companyId?: string | null;
};

export type MutationDeleteAssemblyCustomCategoryParamsInput = {
  assemblyCustomCategoryId: string;
};

export type MutationSyncMakeForesiteFolderQueryInput = {
  connectionId?: string | null;
  CompanyId?: string | null;
  ProjectId?: string | null;
  ParentFolderId?: string | null;
  FolderName?: string | null;
};

export type MutationSyncMakeForesiteFolderBodyInput = {
  connectionId?: string | null;
  CompanyId?: string | null;
  ProjectId?: string | null;
  ParentFolderId?: string | null;
  FolderName?: string | null;
};

export type NewForesiteFolder = {
  __typename: 'NewForesiteFolder';
  success?: boolean | null;
};

export type MutationAddBOMItemCustomAssemblyQueryInput = {
  projectId?: string | null;
  customAssemblyName?: string | null;
  customAssemblyIdentifier?: string | null;
  assemblyCustomCategoryId?: string | null;
  assignedto?: string | null;
};

export type MutationAddBOMItemCustomAssemblyBodyInput = {
  projectId?: string | null;
  customAssemblyName?: string | null;
  customAssemblyIdentifier?: string | null;
  assemblyCustomCategoryId?: string | null;
  assignedto?: string | null;
};

export type CustomAssembly = {
  __typename: 'CustomAssembly';
  lineItemId?: string | null;
  lineItemTypeId?: string | null;
  lineItemTypeName?: string | null;
  projectId?: string | null;
  customAssemblyName?: string | null;
  customAssemblyIdentifier?: string | null;
  assemblyCustomCategoryId?: string | null;
  assemblyCustomCategoryName?: string | null;
  customAssemblyStatus?: string | null;
  assignedto?: string | null;
  assignedtoFirstName?: string | null;
  assignedtoLastName?: string | null;
  assignedtoEmail?: string | null;
  quantity?: number | null;
  createdOn?: string | null;
  createdBy?: string | null;
  createdByFirstName?: string | null;
  createdByLastName?: string | null;
  createdByEmail?: string | null;
  hasChildren?: boolean | null;
};

export type MutationDeleteBulkBOMItemCustomAssemblyQueryInput = {
  lineItemIds?: Array<string | null> | null;
};

export type MutationDeleteBulkBOMItemCustomAssemblyBodyInput = {
  lineItemIds?: Array<string | null> | null;
};

export type MutationDeleteBOMItemCustomAssemblyParamsInput = {
  lineItemId: string;
};

export type MutationSyncCreateWindowsFolderQueryInput = {
  connectionId?: string | null;
  ParentFolderId?: string | null;
  Name?: string | null;
};

export type MutationSyncCreateWindowsFolderBodyInput = {
  connectionId?: string | null;
  ParentFolderId?: string | null;
  Name?: string | null;
};

export type NewWindowsFolder = {
  __typename: 'NewWindowsFolder';
  success?: boolean | null;
};

export type MutationAddWorkOrderItemQueryInput = {
  workOrderItemId?: string | null;
  workOrderId?: string | null;
  workOrderItemTypeId?: string | null;
  unitOfMeasureId?: string | null;
  lineItemId?: string | null;
  lineItemTypeId?: string | null;
  workOrderItemName?: string | null;
  workOrderItemIdentification?: string | null;
  quantity?: number | null;
};

export type MutationAddWorkOrderItemBodyInput = {
  workOrderItemId?: string | null;
  workOrderId?: string | null;
  workOrderItemTypeId?: string | null;
  unitOfMeasureId?: string | null;
  lineItemId?: string | null;
  lineItemTypeId?: string | null;
  workOrderItemName?: string | null;
  workOrderItemIdentification?: string | null;
  quantity?: number | null;
};

export type WorkOrderItem = {
  __typename: 'WorkOrderItem';
  workRequestItemId?: string | null;
  hasShopTask?: boolean | null;
  workOrderItemId?: string | null;
  workOrderId?: string | null;
  workOrderItemTypeId?: string | null;
  unitOfMeasureId?: string | null;
  billOfProcessId?: string | null;
  workOrderItemName?: string | null;
  WorkOrderItemIdentification?: string | null;
  quantity?: number | null;
  unityQuantity?: number | null;
  assignedQuantity?: number | null;
  remainingQuantity?: number | null;
  createdOn?: string | null;
  createdBy?: string | null;
  workOrderItemStatusTypeId?: string | null;
};

export type MutationAddWorkOrderItemFromCatalogQueryInput = {
  partId?: string | null;
  workOrderId?: string | null;
  quantity?: number | null;
};

export type MutationAddWorkOrderItemFromCatalogBodyInput = {
  partId?: string | null;
  workOrderId?: string | null;
  quantity?: number | null;
};

export type MutationUpdateWorkOrderItemParamsInput = {
  workOrderItemId: string;
};

export type MutationUpdateWorkOrderItemQueryInput = {
  workOrderId?: string | null;
  workOrderItemTypeId?: string | null;
  unitOfMeasureId?: string | null;
  workOrderItemName?: string | null;
  workOrderItemIdentification?: string | null;
  quantity?: number | null;
  lineItemId?: string | null;
  lineItemTypeId?: string | null;
};

export type MutationUpdateWorkOrderItemBodyInput = {
  workOrderId?: string | null;
  workOrderItemTypeId?: string | null;
  unitOfMeasureId?: string | null;
  workOrderItemName?: string | null;
  workOrderItemIdentification?: string | null;
  quantity?: number | null;
  lineItemId?: string | null;
  lineItemTypeId?: string | null;
};

export type MutationDeleteWorkOrderItemParamsInput = {
  workOrderItemId: string;
};

export type MutationSyncRenameActionQueryInput = {
  MappingId?: string | null;
  SolitaryFlowId?: string | null;
  ActionAlias?: string | null;
};

export type MutationSyncRenameActionBodyInput = {
  MappingId?: string | null;
  SolitaryFlowId?: string | null;
  ActionAlias?: string | null;
};

export type NavigationModule = {
  __typename: 'NavigationModule';
  navigationModuleId: string;
  navigationModuleName: string;
  navigationModuleCode: string;
  sortOrder?: number | null;
  navigationModuleSets?: Array<NavigationModuleSet | null> | null;
};

export type NavigationModuleSet = {
  __typename: 'NavigationModuleSet';
  navigationModuleSetId: string;
  navigationModuleId: string;
  navigationSetId: string;
  navigationSet?: NavigationSet | null;
  sortOrder?: number | null;
  isLastInGroup?: boolean | null;
};

export type NavigationSet = {
  __typename: 'NavigationSet';
  navigationSetId: string;
  navigationSetName: string;
  isMenu?: boolean | null;
  icon?: string | null;
  sortOrder?: number | null;
  navigationSetItems?: Array<NavigationSetItem | null> | null;
};

export type NavigationSetItem = {
  __typename: 'NavigationSetItem';
  navigationSetItemId: string;
  navigationSetId: string;
  navigationItemId: string;
  navigationItem?: NavigationItem | null;
  sortOrder?: number | null;
};

export type NavigationItem = {
  __typename: 'NavigationItem';
  navigationItemId: string;
  navigationItemName: string;
  icon: string;
  routePath: string;
  iconPath?: string | null;
};

export type PartAttributePredefinedName = {
  __typename: 'PartAttributePredefinedName';
  partAttributePredefinedNameId: string;
  predefinedAttributeName: string;
};

export type WorkRequestsStatusType = {
  __typename: 'WorkRequestsStatusType';
  workRequestStatusTypeId: string;
  workRequestStatusTypeName: string;
};

export type WorkRequestItemType = {
  __typename: 'WorkRequestItemType';
  workRequestItemTypeId?: string | null;
  workRequestItemTypeName?: string | null;
};

export type LocationType = {
  __typename: 'LocationType';
  locationTypeId: string;
  locationTypeName: string;
};

export type LocationFormat = {
  __typename: 'LocationFormat';
  locationFormatId: string;
  locationFormatName: string;
};

export type LineItemType = {
  __typename: 'LineItemType';
  lineItemTypeId: string;
  lineItemTypeName: string;
};

export type MessageType = {
  __typename: 'MessageType';
  messageTypeId?: string | null;
  messageTypeName?: string | null;
};

export type MessagePriority = {
  __typename: 'MessagePriority';
  messagePriorityId?: string | null;
  priorityName?: string | null;
  priorityCode?: MessagePriorityCode | null;
  sortOrder?: number | null;
  createdOn?: string | null;
  createdBy?: string | null;
};

export enum MessagePriorityCode {
  NORMAL = 'NORMAL',
  HIGH = 'HIGH',
}

export type BillOfMaterialsStatuses = {
  __typename: 'BillOfMaterialsStatuses';
  billOfMaterialsStatusId: string;
  billOfMaterialsStatusName: string;
};

export type TaskTypeClass = {
  __typename: 'TaskTypeClass';
  taskTypeClassId: string;
  taskTypeClassName?: string | null;
};

export type UnitOfMeasureType = {
  __typename: 'UnitOfMeasureType';
  unitOfMeasureTypeId: string;
  unitOfMeasureTypeName: string;
  unitOfMeasureCode?: string | null;
};

export type LicenseClass = {
  __typename: 'LicenseClass';
  licenseClassId: string;
  licenseClassName: string;
  sortOrder?: number | null;
};

export type WorkOrderStatusesList = {
  __typename: 'WorkOrderStatusesList';
  workOrderStatusTypeId?: string | null;
  workOrderStatusTypeName?: string | null;
};

export type SyncConnectors = {
  __typename: 'SyncConnectors';
  name?: string | null;
  url?: string | null;
  logoutUrl?: string | null;
  realTimeSync?: boolean | null;
  isLicensed?: boolean | null;
};

export type SupportedExtension = {
  __typename: 'SupportedExtension';
  type?: string | null;
  extensions?: Array<string | null> | null;
};

export type TaskStatusType = {
  __typename: 'TaskStatusType';
  taskStatusTypeId: string;
  taskStatusTypeName?: string | null;
  createdOn?: string | null;
  createdBy?: string | null;
  updatedOn?: string | null;
  updatedBy?: string | null;
  deletedOn?: string | null;
  deletedBy?: string | null;
};

export type DocumentType = {
  __typename: 'DocumentType';
  documentTypeId: string;
  documentTypeName?: string | null;
};

export type SyncLogsDataUsageReport = {
  __typename: 'SyncLogsDataUsageReport';
  monthlyDataUsage?: string | null;
  yearlyDataUsage?: string | null;
};

export type QueryUserStatusParamsInput = {
  email: string;
};

export type UserStatus = {
  __typename: 'UserStatus';
  userEmail?: string | null;
  userName?: string | null;
  userStatus?: USER_STATUS | null;
};

export type QueryUserStatusByIdParamsInput = {
  userId: string;
};

export type QueryUserByNameParamsInput = {
  userName: string;
};

export type QueryUserByIdParamsInput = {
  id: string;
};

export type QueryUsersQueryInput = {
  companyId?: string | null;
  searchPhrase?: string | null;
  skip?: number | null;
  take?: number | null;
  orderBy?: string | null;
  includeDeleted?: string | null;
  isAdmin?: boolean | null;
  userIds?: string | null;
  productPoolIds?: string | null;
  hasLicense?: boolean | null;
};

export type QueryProjectUsersQueryInput = {
  projectId: string;
  searchPhrase?: string | null;
  skip?: number | null;
  take?: number | null;
  orderBy?: string | null;
  includeDeleted?: INCLUDE_DELETED_OPTIONS | null;
};

export enum INCLUDE_DELETED_OPTIONS {
  NO = 'NO',
  YES = 'YES',
  ONLYDELETED = 'ONLYDELETED',
}

export type QueryProjectTypesQueryInput = {
  companyId: string;
};

export type QueryProjectsQueryInput = {
  companyId: string;
  skip?: number | null;
  take?: number | null;
  orderBy?: string | null;
  searchPhrase?: string | null;
  facilityId?: string | null;
  facilityIds?: string | null;
};

export type QueryProjectsMultiSearchQueryInput = {
  companyId: string;
  projectIdentifier?: string | null;
};

export type QueryProjectsFromDepartmentUsersQueryInput = {
  companyId: string;
  skip?: number | null;
  take?: number | null;
  orderBy?: string | null;
  searchPhrase?: string | null;
};

export type QueryProjectByIdParamsInput = {
  projectId: string;
};

export type ProjectWithDepartment = {
  __typename: 'ProjectWithDepartment';
  projectId: string;
  projectName?: string | null;
  projectIdentifier?: string | null;
  dataAnalytics?: boolean | null;
  units?: string | null;
  companyId?: string | null;
  address?: Address | null;
  projectImageId?: string | null;
  departments?: Array<ProjectDepartment | null> | null;
  disallowAutomaticDistributionQty?: boolean | null;
  projectType?: ProjectType | null;
};

export type ProjectDepartment = {
  __typename: 'ProjectDepartment';
  departmentId?: string | null;
  departmentName?: string | null;
  departmentTypeId?: string | null;
  facility?: FacilityDepartment | null;
};

export type FacilityDepartment = {
  __typename: 'FacilityDepartment';
  facilityId?: string | null;
  facilityName?: string | null;
};

export type QueryProductParamsInput = {
  id: string;
};

export type QueryProductsQueryInput = {
  skip?: number | null;
  take?: number | null;
  orderBy?: string | null;
};

export type QueryBundlesQueryInput = {
  skip?: number | null;
  take?: number | null;
  orderBy?: string | null;
};

export type QueryProductPoolByIdParamsInput = {
  id: string;
};

export type QueryProductPoolsQueryInput = {
  companyId: string;
  productId?: string | null;
  productName?: string | null;
};

export type QueryFacilitiesQueryInput = {
  companyId: string;
  searchPhrase?: string | null;
  skip?: number | null;
  take?: number | null;
  orderBy?: string | null;
  hasShopDepartment?: boolean | null;
  showAvailableFacilities?: boolean | null;
};

export type QueryFacilityByIdParamsInput = {
  id: string;
};

export type QueryEntitlementsQueryInput = {
  userId?: string | null;
  productName?: string | null;
};

export type QueryDepartmentsQueryInput = {
  companyId: string;
  facilityId?: string | null;
  departmentTypeId?: string | null;
  skip?: number | null;
  take?: number | null;
};

export type QueryDepartmentUsersQueryInput = {
  departmentId: string;
  searchPhrase?: string | null;
  skip?: number | null;
  take?: number | null;
  orderBy?: string | null;
  includeDeleted?: INCLUDE_DELETED_OPTIONS | null;
  includeAdmin?: string | null;
};

export type QueryCompaniesQueryInput = {
  searchPhrase?: string | null;
  skip?: number | null;
  take?: number | null;
  orderBy?: string | null;
};

export type QueryCompanyByIdParamsInput = {
  companyId: string;
};

export type QueryCompanyProductPoolParamsInput = {
  id: string;
};

export type QueryCompanyProductPoolQueryInput = {
  includeExpired?: boolean | null;
};

export type CompanyProductPool = {
  __typename: 'CompanyProductPool';
  companyId: string;
  address?: string | null;
  renewalDate?: string | null;
  productPool?: Array<ProductPool | null> | null;
};

export type QueryNavigationModuleByIdParamsInput = {
  moduleCode: string;
};

export type QueryPartCatalogsQueryInput = {
  companyId: string;
  name?: string | null;
  skip?: number | null;
  take?: number | null;
  orderBy?: string | null;
};

export type QueryPartCatalogByIdParamsInput = {
  id: string;
};

export type QueryPartCategoryQueryInput = {
  companyId: string;
  parentPartCategoryId?: string | null;
  partCatalogId?: string | null;
  skip?: number | null;
  take?: number | null;
  orderBy?: string | null;
  name?: string | null;
};

export type QueryPartCategoryCloudSearchQueryInput = {
  parser: string;
  query: string;
  skip?: number | null;
  take: number;
  sort?: string | null;
  queryOptions?: string | null;
};

export type PartCategoryCloudSearch = {
  __typename: 'PartCategoryCloudSearch';
  categoryDepth?: string | null;
  categoryId?: string | null;
  categoryName?: string | null;
  categoryPath?: string | null;
  catalogId?: string | null;
  companyId?: string | null;
};

export type QueryPartCategoryByIdParamsInput = {
  id: string;
};

export type QueryPartAttributeByIdParamsInput = {
  id: string;
};

export type QueryPartAttributesQueryInput = {
  companyId?: string | null;
  partAttributeTypeId?: string | null;
  partCategoryId?: string | null;
  partCatalogId?: string | null;
  name?: string | null;
  skip?: number | null;
  take?: number | null;
  orderBy?: string | null;
};

export type QueryPartAttributeVariantDataQueryInput = {
  companyId?: string | null;
  partId?: string | null;
  partAttributeId?: string | null;
  skip?: number | null;
  take?: number | null;
  orderBy?: string | null;
};

export type QueryGetAttributeNamesParamsInput = {
  companyId: string;
};

export type QueryWorkRequestsQueryInput = {
  skip?: number | null;
  take?: number | null;
  facilityId?: string | null;
  projectId?: string | null;
  orderBy?: string | null;
  searchPhrase?: string | null;
  projectIdentifier?: string | null;
  projectName?: string | null;
  facilityName?: string | null;
  statusName?: string | null;
  workRequestIdentifier?: string | null;
  workRequestName?: string | null;
  workRequestStatusIds?: string | null;
  projectIds?: string | null;
  facilityIds?: string | null;
  workRequestIds?: string | null;
  needBy?: string | null;
};

export type QueryWorkRequestsByIdParamsInput = {
  workRequestId: string;
};

export type QueryWorkRequestsByIdQueryInput = {
  skip?: number | null;
  take?: number | null;
  facilityId?: string | null;
  projectId?: string | null;
};

export type QueryWorkRequestsItemByIdParamsInput = {
  workRequestItemId: string;
};

export type QueryWorkRequestItemsQueryInput = {
  workRequestItemTypeId?: string | null;
  skip?: number | null;
  take?: number | null;
  workRequestItemName?: string | null;
  unitOfMeasureId?: string | null;
  orderBy?: string | null;
  workOrderId?: string | null;
  workRequestId?: string | null;
  workRequestItemIdentification?: string | null;
};

export type QueryWorkRequestDocumentsQueryInput = {
  workRequestId: string;
  skip?: number | null;
  take?: number | null;
};

export type QueryWorkOrderDocumentsQueryInput = {
  workOrderId?: string | null;
  workOrderIds?: string | null;
  skip?: number | null;
  take?: number | null;
};

export type QueryWorkRequestProjectsParamsInput = {
  facilityId: string;
};

export type QueryWorkRequestProjectsQueryInput = {
  departmentTypeIds?: string | null;
  isOpenedWorkRequest?: string | null;
};

export type WorkRequestProject = {
  __typename: 'WorkRequestProject';
  projectId: string;
  projectName?: string | null;
  projectIdentifier?: string | null;
};

export type QueryScopePackageQueryInput = {
  projectId: string;
  skip?: number | null;
  take?: number | null;
  orderby?: string | null;
};

export type QueryWorkPhaseByIdParamsInput = {
  workPhaseId: string;
};

export type QueryWorkPhasesQueryInput = {
  projectId: string;
  skip?: number | null;
  take?: number | null;
  orderBy?: string | null;
  scopePackageId?: string | null;
};

export type QueryWorkPhasesCountQueryInput = {
  projectId: string;
};

export type QueryLocationsQueryInput = {
  projectId: string;
  parentLocationId?: string | null;
  skip?: number | null;
  take?: number | null;
  orderBy?: string | null;
};

export type QueryLocationByIdParamsInput = {
  locationId: string;
};

export type QueryExternalParametersParamsInput = {
  projectId: string;
};

export type ExternalParameters = {
  __typename: 'ExternalParameters';
  parameters?: Array<Parameter | null> | null;
  sourceData?: Array<string | null> | null;
};

export type Parameter = {
  __typename: 'Parameter';
  name?: string | null;
  values?: Array<string | null> | null;
};

export type QueryBillOfMaterialsQueryInput = {
  projectId?: string | null;
  facilityId?: string | null;
  billOfMaterialsName?: string | null;
  orderby?: string | null;
  skip?: number | null;
  take?: number | null;
  lineItemId?: string | null;
  lineItemContainerStatusId?: string | null;
};

export type QueryBillOfMaterialItemQueryInput = {
  projectId: string;
  parentLineItemIds?: string | null;
  lineItemTypeId?: string | null;
  unitOfMeasureId?: string | null;
  manufacturerId?: string | null;
  locationIds?: string | null;
  workPhaseIds?: string | null;
  scopePackageIds?: string | null;
  locationsWithWorkPhases?: string | null;
  lineItemName?: string | null;
  manufacturerCatalogNumber?: string | null;
  quantity?: number | null;
  remainingQuantity?: number | null;
  unitOfMeasureName?: string | null;
  unitOfMeasureCode?: string | null;
  manufacturerName?: string | null;
  hideMappedLineItems?: boolean | null;
  hideLineItemsWithZeroQuantity?: boolean | null;
  hideLineItemsWithZeroRemainingQuantity?: boolean | null;
  showLineItemsWithZeroQuantity?: boolean | null;
  showLineItemsMappedFromLineItems?: boolean | null;
  showUnassignedLineItemsOnly?: boolean | null;
  showWriteInLineItemsOnly?: boolean | null;
  rules?: string | null;
  externalSourceImportDescription?: string | null;
  orderby?: string | null;
  skip?: number | null;
  take?: number | null;
};

export type QueryBillOfMaterialItemSearchParamsInput = {
  id: string;
};

export type QueryBillOfMaterialItemSearchQueryInput = {
  locationIds?: string | null;
  workPhaseIds?: string | null;
  scopePackageIds?: string | null;
  hideLineItemsWithZeroQuantity?: boolean | null;
  hideLineItemsWithZeroRemainingQuantity?: boolean | null;
  showLineItemsWithZeroQuantity?: boolean | null;
  showLineItemsMappedFromLineItems?: boolean | null;
  showUnassignedLineItemsOnly?: boolean | null;
  showWriteInLineItemsOnly?: boolean | null;
  hideMappedLineItems?: boolean | null;
  rules?: string | null;
  externalSourceImportDescription?: string | null;
  orderby?: string | null;
  skip?: number | null;
  take?: number | null;
};

export type QueryBillOfMaterialItemByIdParamsInput = {
  id: string;
};

export type QueryGetDecisionsParamsInput = {
  id: string;
};

export type QueryGetDecisionsQueryInput = {
  orderBy?: string | null;
};

export type Decisions = {
  __typename: 'Decisions';
  partAttribute?: PartAttribute | null;
  partAttributeVariantData?: PartAttributeVariantData | null;
  lineItemDecision?: LineItemDecisions | null;
};

export type LineItemDecisions = {
  __typename: 'LineItemDecisions';
  lineItemDecisionId?: string | null;
  partAttribute?: PartAttributeDecisions | null;
  partAttributeSelectVariant?: PartAttributeSelectVariant | null;
  customValue?: string | null;
  isVariantDeletedFromCurrentVersionOfPart?: boolean | null;
  allVariantsDeletedFromCurrentVersionOfPart?: boolean | null;
};

export type PartAttributeDecisions = {
  __typename: 'PartAttributeDecisions';
  partAttributeId?: string | null;
  partAttributeName?: string | null;
};

export type QueryBillOfMaterialLineItemByLBSQueryInput = {
  ern?: string | null;
  filterType?: string | null;
  locationId?: string | null;
  workPhaseId?: string | null;
  includeZeroAmounts?: boolean | null;
};

export type QueryQuantitiesRetrieveQueryInput = {
  ern?: string | null;
};

export type RetrieveGet = {
  __typename: 'RetrieveGet';
  ern?: string | null;
  resourceId?: string | null;
  properties?: GetProperties | null;
  amount?: number | null;
  amountBreakdown?: Array<AmountBreakdownProperties | null> | null;
};

export type QueryQuantitiesRetrieveByIdentifierParamsInput = {
  identifier: string;
};

export type RetrieveByIdentifierGet = {
  __typename: 'RetrieveByIdentifierGet';
  ern?: string | null;
  resourceId?: string | null;
  properties?: GetByIdentifierProperties | null;
  amount?: number | null;
  amountRemaining?: number | null;
  amountBreakdown?: Array<AmountBreakdownProperties | null> | null;
  constraints?: Array<string | null> | null;
  failedConstraints?: Array<FailedConstraintsByIdentifier | null> | null;
};

export type GetByIdentifierProperties = {
  __typename: 'GetByIdentifierProperties';
  companyId?: string | null;
  projectId?: string | null;
  locationId?: string | null;
};

export type FailedConstraintsByIdentifier = {
  __typename: 'FailedConstraintsByIdentifier';
  constraintId?: string | null;
  parentResourceERN?: string | null;
  description?: string | null;
};

export type QueryUnitOfMeasureQueryInput = {
  companyId?: string | null;
  unitOfMeasureTypeId?: string | null;
  unitOfMeasureName?: string | null;
  unitOfMeasureCode?: string | null;
};

export type QueryUnitOfMeasureByIdParamsInput = {
  id: string;
};

export type QueryManufacturedQueryInput = {
  companyId: string;
};

export type QueryPartsQueryInput = {
  partCategoryId?: string | null;
  partCatalogId?: string | null;
  unitOfMeasureId?: string | null;
  rangeValues?: string | null;
  selectValues?: string | null;
  name?: string | null;
  skip?: number | null;
  take?: number | null;
  orderBy?: string | null;
};

export type QueryPartsCountQueryInput = {
  partCategoryId?: string | null;
  partCatalogId?: string | null;
  unitOfMeasureId?: string | null;
  rangeValues?: string | null;
  selectValues?: string | null;
  name?: string | null;
  includeDrafts?: boolean | null;
};

export type QueryPartsCloudSearchQueryInput = {
  query?: string | null;
  skip?: number | null;
  take?: number | null;
  sort?: string | null;
  parser?: string | null;
  queryOptions?: string | null;
};

export type PartCloudSearchResult = {
  __typename: 'PartCloudSearchResult';
  partDescription?: string | null;
  manufacturerName?: string | null;
  partCategoryPath?: string | null;
  partName?: string | null;
  unitOfMeasureCode?: string | null;
  partHistoryRefId?: string | null;
  version?: string | null;
  partId?: string | null;
  partImageDocumentId?: string | null;
  partHistoryId?: string | null;
  partCatalogId?: string | null;
  partCategories?: Array<string | null> | null;
  catalogNumber?: string | null;
  hasAssembly?: string | null;
};

export type QueryPartsCloudSearchWithCountQueryInput = {
  query?: string | null;
  skip?: number | null;
  take?: number | null;
  sort?: string | null;
  parser?: string | null;
  queryOptions?: string | null;
};

export type PartCloudSearchResultWithTotalCount = {
  __typename: 'PartCloudSearchResultWithTotalCount';
  items?: Array<PartCloudSearchResult | null> | null;
  totalCount?: number | null;
};

export type QueryPartsCloudSearchCountQueryInput = {
  query?: string | null;
  sort?: string | null;
  parser?: string | null;
  queryOptions?: string | null;
};

export type QueryPartByIdParamsInput = {
  id: string;
};

export type QueryMessagesQueryInput = {
  messagePriorityId?: string | null;
  accessedOn?: boolean | null;
  messagePriority?: boolean | null;
  status?: string | null;
  read?: string | null;
  searchPhrase?: string | null;
  includeDeleted?: string | null;
  skip?: number | null;
  take?: number | null;
};

export type QueryMessagesCountQueryInput = {
  messagePriorityId?: string | null;
  accessedOn?: boolean | null;
  messagePriority?: boolean | null;
  status?: string | null;
  read?: string | null;
  searchPhrase?: string | null;
  includeDeleted?: string | null;
};

export type QueryMessageByIdParamsInput = {
  id: string;
};

export type QueryMessageByIdQueryInput = {
  includeDeleted?: string | null;
};

export type QueryTakeoffPadsQueryInput = {
  projectId: string;
  takeoffPadName?: string | null;
  orderby?: string | null;
  skip?: number | null;
  take?: number | null;
};

export type QueryTakeoffPadItemsQueryInput = {
  takeOffPadId: string;
  lineItemTypeId?: string | null;
  unitOfMeasureId?: string | null;
  manufacturerId?: string | null;
  locationIds?: string | null;
  workPhaseIds?: string | null;
  scopePackageIds?: string | null;
  locationsWithWorkPhases?: string | null;
  lineItemName?: string | null;
  manufacturerCatalogNumber?: string | null;
  quantity?: number | null;
  remainingQuantity?: number | null;
  unitOfMeasureName?: string | null;
  unitOfMeasureCode?: string | null;
  manufacturerName?: string | null;
  hideMappedLineItems?: boolean | null;
  hideLineItemsWithZeroQuantity?: boolean | null;
  hideLineItemsWithZeroRemainingQuantity?: boolean | null;
  showLineItemsWithZeroQuantity?: boolean | null;
  showLineItemsMappedFromLineItems?: boolean | null;
  showUnassignedLineItemsOnly?: boolean | null;
  showWriteInLineItemsOnly?: boolean | null;
  rules?: string | null;
  externalSourceImportDescription?: string | null;
  orderBy?: string | null;
  skip?: number | null;
  take?: number | null;
};

export type QueryTakeoffPadItemsCountQueryInput = {
  takeOffPadId: string;
  lineItemTypeId?: string | null;
  unitOfMeasureId?: string | null;
  manufacturerId?: string | null;
  locationIds?: string | null;
  workPhaseIds?: string | null;
  scopePackageIds?: string | null;
  locationsWithWorkPhases?: string | null;
  lineItemName?: string | null;
  manufacturerCatalogNumber?: string | null;
  quantity?: number | null;
  remainingQuantity?: number | null;
  unitOfMeasureName?: string | null;
  unitOfMeasureCode?: string | null;
  manufacturerName?: string | null;
  hideMappedLineItems?: boolean | null;
  hideLineItemsWithZeroQuantity?: boolean | null;
  hideLineItemsWithZeroRemainingQuantity?: boolean | null;
  showLineItemsWithZeroQuantity?: boolean | null;
  showLineItemsMappedFromLineItems?: boolean | null;
  showUnassignedLineItemsOnly?: boolean | null;
  showWriteInLineItemsOnly?: boolean | null;
  rules?: string | null;
  externalSourceImportDescription?: string | null;
  orderBy?: string | null;
};

export type QueryTaskTypesQueryInput = {
  take?: number | null;
  skip?: number | null;
  orderBy?: string | null;
  taskTypeClassId?: string | null;
  searchPhrase?: string | null;
  companyId?: string | null;
  taskTypeName?: string | null;
};

export type QueryTaskTypeByIdParamsInput = {
  taskTypeId: string;
};

export type QueryShopConfigurationsParamsInput = {
  facilityId: string;
};

export type QueryShopConfigurationsMultiSearchQueryInput = {
  facilityId: string;
  shopConfigurationName?: string | null;
};

export type QueryShopPropertyByIdParamsInput = {
  shopPropertyId: string;
};

export type QueryShopPropertiesQueryInput = {
  departmentId?: string | null;
};

export type QueryWorkCellParamsInput = {
  workcellId: string;
};

export type QueryWorkCellsQueryInput = {
  take?: number | null;
  skip?: number | null;
  facilityId?: string | null;
  orderBy?: string | null;
  shopConfigurationId?: string | null;
};

export type QueryWorkCellTaskTypeQueryInput = {
  taskTypeId?: string | null;
  workCellId?: string | null;
  StartFragmentWorkCellTaskTypeId?: string | null;
  taskTypeName?: string | null;
  taskTypeDescription?: string | null;
  skip?: number | null;
  take?: number | null;
  orderby?: string | null;
};

export type WorkCellTaskType = {
  __typename: 'WorkCellTaskType';
  workCellTaskTypeId: string;
  workCellId: string;
  taskTypeId: string;
  taskTypeImageId?: string | null;
  taskTypeName: string;
  taskTypeDescription?: string | null;
  taskTypeClassId?: string | null;
  companyId: string;
  machines?: Array<Machine | null> | null;
};

export type QuerySourcePadItemsQueryInput = {
  projectId: string;
  sourcePadName: SOURCE_PAD_NAME;
  lineItemName?: string | null;
  manufacturerCatalogNumber?: string | null;
  quantity?: number | null;
  remainingQuantity?: number | null;
  unitOfMeasureName?: string | null;
  unitOfMeasureCode?: string | null;
  manufacturerName?: string | null;
  orderBy?: string | null;
  skip?: number | null;
  take?: number | null;
  locationIds?: string | null;
  workPhaseIds?: string | null;
  scopePackageIds?: string | null;
  locationsWithWorkPhases?: string | null;
  hideMappedLineItems?: boolean | null;
  hideLineItemsWithZeroQuantity?: boolean | null;
  hideLineItemsWithZeroRemainingQuantity?: boolean | null;
  showUnassignedLineItemsOnly?: boolean | null;
  showWriteInLineItemsOnly?: boolean | null;
  showLineItemsWithZeroQuantity?: boolean | null;
  rules?: string | null;
};

export enum SOURCE_PAD_NAME {
  Revit = 'Revit',
  Bluebeam = 'Bluebeam',
}

export type QuerySourcePadItemsCountQueryInput = {
  projectId: string;
  sourcePadName: SOURCE_PAD_NAME;
  lineItemName?: string | null;
  manufacturerCatalogNumber?: string | null;
  quantity?: number | null;
  remainingQuantity?: number | null;
  unitOfMeasureName?: string | null;
  unitOfMeasureCode?: string | null;
  manufacturerName?: string | null;
  orderBy?: string | null;
  skip?: number | null;
  take?: number | null;
  locationIds?: string | null;
  workPhaseIds?: string | null;
  scopePackageIds?: string | null;
  locationsWithWorkPhases?: string | null;
  hideMappedLineItems?: boolean | null;
  hideLineItemsWithZeroQuantity?: boolean | null;
  hideLineItemsWithZeroRemainingQuantity?: boolean | null;
  showUnassignedLineItemsOnly?: boolean | null;
  showWriteInLineItemsOnly?: boolean | null;
  showLineItemsWithZeroQuantity?: boolean | null;
  rules?: string | null;
};

export type QuerySourcePadNamesQueryInput = {
  projectId: string;
};

export type SourcePadName = {
  __typename: 'SourcePadName';
  sourcePadId?: string | null;
  sourcePadName?: string | null;
  hasMappedItems?: boolean | null;
  createdOn?: string | null;
};

export type QuerySourcePadPropertiesParamsInput = {
  sourcePadId: string;
};

export type SourcePadProperties = {
  __typename: 'SourcePadProperties';
  sourcePadId: string;
  externalSourceImportDescription?: string | null;
  lastPublished?: string | null;
  publishedBy?: string | null;
  sourcePadNotes?: Array<SourcePadNote | null> | null;
};

export type SourcePadNote = {
  __typename: 'SourcePadNote';
  sourcePadNoteId: string;
  note?: string | null;
  date?: string | null;
  createdBy?: string | null;
};

export type QueryFoldersQueryInput = {
  companyId?: string | null;
  searchPhrase?: string | null;
  parentFolderId?: string | null;
  folderIds?: string | null;
  projectIds?: string | null;
  folderName?: string | null;
  storagePath?: string | null;
  isHidden?: boolean | null;
  orderBy?: string | null;
  skip?: number | null;
  take?: number | null;
};

export type QueryFolderByIdParamsInput = {
  folderId: string;
};

export type QueryDocumentsQueryInput = {
  folderId?: string | null;
  parentDocumentIds?: string | null;
  documentIds?: string | null;
  documentTypeIds?: string | null;
  documentIdentifier?: string | null;
  folderIds?: string | null;
  skip?: number | null;
  take?: number | null;
  orderBy?: string | null;
};

export type QueryDocumentByIdParamsInput = {
  documentId: string;
};

export type QueryDocumentIsTheLastVersionParamsInput = {
  documentId: string;
};

export type DocumentIsTheLastVersion = {
  __typename: 'DocumentIsTheLastVersion';
  isLatestVersion?: string | null;
};

export type QueryForgeDocumentParamsInput = {
  documentId: string;
};

export type ForgeDocument = {
  __typename: 'ForgeDocument';
  token?: string | null;
  timeInSeconds: number;
  encodedUrn?: string | null;
  documentStatusType?: DocumentStatusType | null;
};

export type DocumentStatusType = {
  __typename: 'DocumentStatusType';
  documentStatusTypeId: string;
  documentStatusTypeName: string;
};

export type QueryLicensesQueryInput = {
  companyId?: string | null;
  productOrBundleId?: string | null;
  productOrBundleName?: string | null;
  skip?: number | null;
  take?: number | null;
};

export type License = {
  __typename: 'License';
  productPoolId?: string | null;
  companyId?: string | null;
  productId?: string | null;
  product?: LicenseProduct | null;
  bundleId?: string | null;
  bundle?: LicenseBundle | null;
  poolQuantity?: number | null;
  poolAvailableQuantity?: number | null;
  customData?: string | null;
  renewalDate?: string | null;
  trialRenewalDate?: string | null;
  startDate?: string | null;
  label?: string | null;
  type?: string | null;
};

export type LicenseProduct = {
  __typename: 'LicenseProduct';
  productId?: string | null;
  productName?: string | null;
  licenseClassId?: string | null;
  licenseTypeId?: string | null;
};

export type LicenseBundle = {
  __typename: 'LicenseBundle';
  bundleId?: string | null;
  bundleName?: string | null;
};

export type QuerySummarizeLocationPhasesQueryInput = {
  projectId: string;
  workPhaseId?: string | null;
};

export type SummarizeLocationPhases = {
  __typename: 'SummarizeLocationPhases';
  projectId?: string | null;
  hasLocationPhases?: boolean | null;
  numberOfLocationPhases?: number | null;
};

export type QueryLocationListByWorkPhaseQueryInput = {
  projectId: string;
  workPhaseId: string;
  orderBy?: string | null;
};

export type QueryMachineByIdParamsInput = {
  machineId: string;
};

export type QueryMachinesQueryInput = {
  facilityId?: string | null;
  workCellId?: string | null;
  searchPhrase?: string | null;
  skip?: number | null;
  take?: number | null;
  orderBy?: string | null;
  machineIdentifier?: string | null;
};

export type QueryMachineTaskTypeQueryInput = {
  taskTypeId?: string | null;
  machineTaskTypeId?: string | null;
  taskTypeName?: string | null;
  taskTypeDescription?: string | null;
  skip?: number | null;
  take?: number | null;
  orderby?: string | null;
  machineId?: string | null;
  workCellId?: string | null;
};

export type MachineTaskType = {
  __typename: 'MachineTaskType';
  machineTaskTypeId: string;
  machineId: string;
  taskTypeId: string;
  taskTypeImageId?: string | null;
  taskTypeName: string;
  taskTypeDescription?: string | null;
  taskTypeClassId?: string | null;
  companyId: string;
};

export type QueryWorkOrdersQueryInput = {
  workRequestId?: string | null;
  orderBy?: string | null;
  skip?: number | null;
  take?: number | null;
  facilityId?: string | null;
  projectId?: string | null;
  needBy?: string | null;
  workOrderStatusIds?: string | null;
  projectIds?: string | null;
  workRequestIds?: string | null;
  workOrderIds?: string | null;
  documentTypeIds?: string | null;
};

export type QueryWorkOrderItemQueryInput = {
  workOrderId?: string | null;
  workOrderItemTypeId?: string | null;
  skip?: number | null;
  take?: number | null;
  workOrderItemName?: string | null;
  unitOfMeasureId?: string | null;
  orderBy?: string | null;
  workOrderItemIdentification?: string | null;
  searchPhrase?: string | null;
};

export type QueryWorkOrderItemByIdParamsInput = {
  workOrderItemId: string;
};

export type QueryWorkOrdersCountQueryInput = {
  workRequestId?: string | null;
  orderBy?: string | null;
  skip?: number | null;
  take?: number | null;
  facilityId?: string | null;
  projectId?: string | null;
  needBy?: string | null;
  workOrderStatusIds?: string | null;
  projectIds?: string | null;
  workRequestIds?: string | null;
  workOrderIds?: string | null;
};

export type PaginationResponse = {
  __typename: 'PaginationResponse';
  requestedSkip?: number | null;
  requestedTake?: number | null;
  entireCount?: number | null;
};

export type QuerySyncConnectionsQueryInput = {
  searchPhrase?: string | null;
  skip?: number | null;
  take?: number | null;
  orderBy?: string | null;
};

export type QueryWorkCellMachineQueryInput = {
  facilityId?: string | null;
  workCellId?: string | null;
  machineId?: string | null;
  shopConfigurationId?: string | null;
  searchPhrase?: string | null;
  skip?: number | null;
  take?: number | null;
  orderBy?: string | null;
};

export type QueryWorkCellTasksMultiSearchQueryInput = {
  skip?: number | null;
  take?: number | null;
  isEnabled?: boolean | null;
  taskStatusTypeIds?: string | null;
  orderBy?: string | null;
  facilityIds?: string | null;
  projectIds?: string | null;
  workCellIds?: string | null;
  documentIds?: string | null;
  taskIds?: string | null;
  workRequestId?: string | null;
};

export type WorkCellTask = {
  __typename: 'WorkCellTask';
  workCellTaskId?: string | null;
  workCellId?: string | null;
  workCellName?: string | null;
  projectId?: string | null;
  workOrderId?: string | null;
  workRequestItemId?: string | null;
  workRequestId?: string | null;
  taskId?: string | null;
  taskTypeId?: string | null;
  taskStatusTypeId?: string | null;
  projectIdentifier?: string | null;
  projectName?: string | null;
  workOrderName?: string | null;
  workRequestItemName?: string | null;
  taskName?: string | null;
  taskTypeName?: string | null;
  taskStatusTypeName?: string | null;
  sortOrder?: number | null;
  isEnabled?: boolean | null;
  needBy?: string | null;
  hasTaskTypes?: boolean | null;
  taskStatusTypeUpdatedOn?: string | null;
  workRequestName?: string | null;
  workRequestItemTypeName?: string | null;
  workRequestItemTypeId?: string | null;
  workRequestIdentifier?: string | null;
};

export type QueryWorkCellTasksMultiSearchCountQueryInput = {
  skip?: number | null;
  take?: number | null;
  isEnabled?: boolean | null;
  taskStatusTypeIds?: string | null;
  orderBy?: string | null;
  facilityIds?: string | null;
  projectIds?: string | null;
  workCellIds?: string | null;
  documentIds?: string | null;
  taskIds?: string | null;
  workRequestId?: string | null;
};

export type QueryWorkCellTasksQueueQueryInput = {
  skip?: number | null;
  take?: number | null;
  isEnabled?: boolean | null;
  taskStatusTypeIds?: string | null;
  workCellId?: string | null;
  orderBy?: string | null;
};

export type QueryWorkCellTasksQueueCountQueryInput = {
  isEnabled?: boolean | null;
  taskStatusTypeIds?: string | null;
  skip?: number | null;
  take?: number | null;
  workCellId?: string | null;
  orderBy?: string | null;
};

export type QuerySyncWorkflowsQueryInput = {
  searchPhrase?: string | null;
  skip?: number | null;
  take?: number | null;
  orderBy?: string | null;
};

export type Workflow = {
  __typename: 'Workflow';
  workflowId: string;
  workflowName?: string | null;
  workflowMapping?: Array<WorkflowMappingItem | null> | null;
  isArchived?: boolean | null;
  isDeactivated?: boolean | null;
};

export type WorkflowMappingItem = {
  __typename: 'WorkflowMappingItem';
  workflowMappingId: string;
  workflowMappingName?: string | null;
  isSyncRunning?: boolean | null;
  lastSyncAt?: string | null;
  lastSavedAt?: string | null;
  isArchived?: boolean | null;
  isDeactivated?: boolean | null;
  solitaryFlows?: Array<SolitaryFlowItem | null> | null;
  publishModelSolitaryFlows?: Array<PublishModelSolitaryFlowItem | null> | null;
};

export type SolitaryFlowItem = {
  __typename: 'SolitaryFlowItem';
  solitaryFlowId?: string | null;
  action?: string | null;
  actionNodeId?: string | null;
  actionAlias?: string | null;
  isArchived?: boolean | null;
  isDeactivated?: boolean | null;
  source?: MappingFlowConfigurationSourceNodeInfo | null;
  destinations?: Array<MappingFlowConfigurationDestinationNodeInfo | null> | null;
  syncStatus?: number | null;
  lastSyncAt?: string | null;
  actionType?: string | null;
};

export type PublishModelSolitaryFlowItem = {
  __typename: 'PublishModelSolitaryFlowItem';
  solitaryFlowId?: string | null;
  action?: string | null;
  actionNodeId?: string | null;
  actionAlias?: string | null;
  isArchived?: boolean | null;
  isDeactivated?: boolean | null;
  source?: MappingFlowConfigurationSourceNodeInfo | null;
  destinations?: Array<MappingFlowConfigurationDestinationNodeInfo | null> | null;
  syncStatus?: number | null;
  lastSyncAt?: string | null;
  actionType?: string | null;
};

export type QuerySyncArchivedWorkflowsQueryInput = {
  searchPhrase?: string | null;
  skip?: number | null;
  take?: number | null;
  orderBy?: string | null;
};

export type QuerySyncWebhooksQueryInput = {
  searchPhrase?: string | null;
  skip?: number | null;
  take?: number | null;
  orderBy?: string | null;
};

export type Webhook = {
  __typename: 'Webhook';
  webhookId?: string | null;
  webhookDetails?: string | null;
  connectionType?: string | null;
  isActive?: boolean | null;
  lastSavedAt?: string | null;
  workflows?: Array<WorkflowItems | null> | null;
};

export type WorkflowItems = {
  __typename: 'WorkflowItems';
  workflowId: string;
  workflowName?: string | null;
  mappings?: Array<WorkflowMappingItems | null> | null;
};

export type WorkflowMappingItems = {
  __typename: 'WorkflowMappingItems';
  mappingId: string;
  mappingName?: string | null;
  isSyncRunning?: boolean | null;
  syncStatus?: string | null;
  lastSyncTime?: string | null;
  solitaryFlows?: Array<SolitaryFlowItems | null> | null;
};

export type SolitaryFlowItems = {
  __typename: 'SolitaryFlowItems';
  solitaryFlowId: string;
  actionAlias?: string | null;
  syncStatus?: string | null;
  syncType?: string | null;
  lastSyncTime?: string | null;
  webhookRegistryId?: string | null;
};

export type QueryGetWorkflowByIdParamsInput = {
  workflowId: string;
};

export type QueryGetProcoreCompaniesQueryInput = {
  connectionId?: string | null;
  skip?: number | null;
  take?: number | null;
};

export type ProcoreCompany = {
  __typename: 'ProcoreCompany';
  id: string;
  text?: string | null;
};

export type QueryGetProcoreProjectsQueryInput = {
  connectionId?: string | null;
  companyId?: string | null;
  skip?: number | null;
  take?: number | null;
};

export type ProcoreProject = {
  __typename: 'ProcoreProject';
  id: string;
  text?: string | null;
};

export type QueryGetProcoreFoldersParamsInput = {
  folderType: string;
};

export type QueryGetProcoreFoldersQueryInput = {
  connectionId?: string | null;
  companyId?: string | null;
  projectId?: string | null;
  parentFolderId?: string | null;
  skip?: number | null;
  take?: number | null;
};

export type ProcoreFolder = {
  __typename: 'ProcoreFolder';
  id: string;
  text?: string | null;
};

export type QueryGetProcoreSelectedFolderIdStackParamsInput = {
  folderType: string;
};

export type QueryGetProcoreSelectedFolderIdStackQueryInput = {
  connectionId?: string | null;
  companyId?: string | null;
  projectId?: string | null;
  selectedFolderId?: string | null;
};

export type QueryGetProcoreFolderNameQueryInput = {
  connectionId?: string | null;
  companyId?: string | null;
  projectId?: string | null;
  folderId?: string | null;
  connectionTypeId?: string | null;
};

export type ProcoreFolderName = {
  __typename: 'ProcoreFolderName';
  success?: boolean | null;
  name?: string | null;
};

export type QueryGetAutodeskFieldProjectsQueryInput = {
  connectionId?: string | null;
  skip?: number | null;
  take?: number | null;
};

export type AutodeskFieldProject = {
  __typename: 'AutodeskFieldProject';
  id: string;
  text?: string | null;
};

export type QueryGetAutodeskFieldFoldersQueryInput = {
  connectionId?: string | null;
  projectId?: string | null;
  parentFolderId?: string | null;
  skip?: number | null;
  take?: number | null;
};

export type AutodeskFieldFolder = {
  __typename: 'AutodeskFieldFolder';
  id?: string | null;
  text?: string | null;
};

export type QueryGetAutodeskFieldFolderNameQueryInput = {
  connectionId?: string | null;
  projectId?: string | null;
  folderId?: string | null;
};

export type AutodeskFieldFolderName = {
  __typename: 'AutodeskFieldFolderName';
  success?: boolean | null;
  name?: string | null;
};

export type QueryGetAutodeskForgeHubsQueryInput = {
  connectionId?: string | null;
  skip?: number | null;
  take?: number | null;
};

export type AutodeskForgeHub = {
  __typename: 'AutodeskForgeHub';
  id: string;
  text?: string | null;
};

export type QueryGetAutodeskForgeProjectsQueryInput = {
  connectionId?: string | null;
  hubId?: string | null;
  skip?: number | null;
  take?: number | null;
};

export type AutodeskForgeProject = {
  __typename: 'AutodeskForgeProject';
  id: string;
  text?: string | null;
};

export type QueryGetAutodeskForgeSubItemsQueryInput = {
  connectionId?: string | null;
  hubId?: string | null;
  projectId?: string | null;
  isPublishModel?: boolean | null;
  parentFolderId?: string | null;
  skip?: number | null;
  take?: number | null;
};

export type AutodeskForgeSubItem = {
  __typename: 'AutodeskForgeSubItem';
  id: string;
  text?: string | null;
  isFolder?: boolean | null;
};

export type QueryGetAutodeskForgeItemNameQueryInput = {
  connectionId?: string | null;
  hubId?: string | null;
  projectId?: string | null;
  folderId?: string | null;
};

export type AutodeskForgeItem = {
  __typename: 'AutodeskForgeItem';
  success?: boolean | null;
  name?: string | null;
};

export type QueryGetAutodeskForgeSelectedSubItemIdStackQueryInput = {
  connectionId?: string | null;
  hubId?: string | null;
  projectId?: string | null;
  isPublishModel?: boolean | null;
  selectedFolderId?: string | null;
};

export type QueryTaskByIdParamsInput = {
  taskId: string;
};

export type QueryTaskMultiSearchQueryInput = {
  workRequestItemId?: string | null;
  billOfProcessId?: string | null;
  skip?: number | null;
  take?: number | null;
  orderBy?: string | null;
  taskStatusTypeIds?: string | null;
  workRequestId?: string | null;
};

export type QueryTaskDocumentQueryInput = {
  taskId?: string | null;
  taskIds?: string | null;
  skip?: number | null;
  take?: number | null;
  documentTypeIds?: string | null;
  includeExternalUrl?: boolean | null;
};

export type QueryBillOfProcessByIdParamsInput = {
  billOfProcessId: string;
};

export type BillOfProcess = {
  __typename: 'BillOfProcess';
  billOfProcessId: string;
  billOfMaterialName?: string | null;
  workRequestItemId?: string | null;
  createdOn: string;
  createdBy: string;
  updatedOn?: string | null;
  updatedBy?: string | null;
  deletedOn?: string | null;
  deletedBy?: string | null;
};

export type QueryWorkOrderByIdParamsInput = {
  workOrderId: string;
};

export type QuerySyncGetWorkflowMappingConfigurationParamsInput = {
  mappingId: string;
};

export type WorkflowMappingCanvasConfigData = {
  __typename: 'WorkflowMappingCanvasConfigData';
  workflowId?: string | null;
  workflowName?: string | null;
  mapping?: WorkflowMapping | null;
};

export type WorkflowMapping = {
  __typename: 'WorkflowMapping';
  mappingId?: string | null;
  mappingName?: string | null;
  canvasNode?: string | null;
  isSyncRunning?: boolean | null;
  lastSyncAt?: string | null;
  lastSavedAt?: string | null;
  isArchived?: boolean | null;
  isDeactivated?: boolean | null;
  solitaryFlowConfiguration?: Array<MappingFlowConfiguration | null> | null;
  solitaryFlowPublishModelConfiguration?: Array<MappingFlowPublishModelConfiguration | null> | null;
};

export type MappingFlowConfiguration = {
  __typename: 'MappingFlowConfiguration';
  solitaryFlowId?: string | null;
  action?: string | null;
  actionNodeId?: string | null;
  actionAlias?: string | null;
  isDeactivated?: boolean | null;
  isArchived?: boolean | null;
  source?: MappingFlowConfigurationSourceNodeInfo | null;
  destinations?: Array<MappingFlowConfigurationDestinationNodeInfo | null> | null;
};

export type MappingFlowPublishModelConfiguration = {
  __typename: 'MappingFlowPublishModelConfiguration';
  solitaryFlowId?: string | null;
  action?: string | null;
  actionNodeId?: string | null;
  actionAlias?: string | null;
  syncStatus?: number | null;
  lastSyncAt?: string | null;
  isDeactivated?: boolean | null;
  isArchived?: boolean | null;
  source?: MappingFlowConfigurationSourceNodeInfo | null;
};

export type QuerySyncGetWorkflowMappingTemplatesListQueryInput = {
  searchPhrase?: string | null;
  skip?: number | null;
  take?: number | null;
  orderBy?: string | null;
};

export type QueryDrawingTypeCategoryByIdParamsInput = {
  drawingTypeCategoryId: string;
};

export type QuerySyncGetConnectionByIdParamsInput = {
  connectionId: string;
};

export type QueryAssembliesQueryInput = {
  skip?: number | null;
  take?: number | null;
  partId?: string | null;
  includeDrafts?: boolean | null;
};

export type QueryAssemblyByIdParamsInput = {
  assemblyId: string;
};

export type QueryAssemblyNodesQueryInput = {
  skip?: number | null;
  take?: number | null;
  name?: string | null;
  typeId?: string | null;
  assemblyId?: string | null;
  includeDrafts?: boolean | null;
};

export type QueryAssemblyNodeByIdParamsInput = {
  assemblyNodeID: string;
};

export type QueryAssemblyEdgeQueryInput = {
  skip?: number | null;
  take?: number | null;
  typeId?: string | null;
  assemblyId?: string | null;
  beginNodeId?: string | null;
  endNodeId?: string | null;
  includeDrafts?: boolean | null;
};

export type QueryAssemblyEdgeByIdParamsInput = {
  assemblyEdgeID: string;
};

export type QuerySyncLogsQueryInput = {
  searchPhrase?: string | null;
  skip?: number | null;
  take?: number | null;
  orderBy?: string | null;
};

export type SyncLogFile = {
  __typename: 'SyncLogFile';
  workflowName?: string | null;
  workflowId?: string | null;
  totalFiles?: number | null;
  successfulFilesCount?: number | null;
  mappings?: Array<SyncLogMapping | null> | null;
  lastSyncTime?: string | null;
};

export type SyncLogMapping = {
  __typename: 'SyncLogMapping';
  mappingName?: string | null;
  mappingId?: string | null;
  totalFiles?: number | null;
  successfulFilesCount?: number | null;
  solitaryFlows?: Array<SolitaryFlow | null> | null;
  publishModelSolitaryFlows?: Array<SolitaryFlow | null> | null;
  lastSyncTime?: string | null;
};

export type SolitaryFlow = {
  __typename: 'SolitaryFlow';
  fileSyncLogId?: string | null;
  source?: SyncLogNode | null;
  syncStatus?: number | null;
  syncType?: number | null;
  totalFiles?: number | null;
  totalSize?: string | null;
  successfulFilesCount?: number | null;
  actionAlias?: string | null;
  destinations?: Array<SyncLogNode | null> | null;
  lastSyncTime?: string | null;
  isContactSupportTeam?: boolean | null;
};

export type SyncLogNode = {
  __typename: 'SyncLogNode';
  name?: string | null;
  type?: string | null;
};

export type QuerySyncRealTimeLogsQueryInput = {
  searchPhrase?: string | null;
  skip?: number | null;
  take?: number | null;
  orderBy?: string | null;
  timeZone?: string | null;
};

export type SyncRealTimeLogFile = {
  __typename: 'SyncRealTimeLogFile';
  date?: string | null;
  files?: Array<SyncRealTimeLogFileDetails | null> | null;
};

export type SyncRealTimeLogFileDetails = {
  __typename: 'SyncRealTimeLogFileDetails';
  fileName?: string | null;
  fileSyncTime?: string | null;
  solitaryFlows?: Array<SyncRealTimeLogFileFlowDetails | null> | null;
};

export type SyncRealTimeLogFileFlowDetails = {
  __typename: 'SyncRealTimeLogFileFlowDetails';
  workflowId?: string | null;
  workflowName?: string | null;
  mappingId?: string | null;
  mappingName?: string | null;
  actionAlias?: string | null;
  source?: string | null;
  sourcePath?: string | null;
  destinations?: Array<SyncRealTimeLogFileFlowDestination | null> | null;
};

export type SyncRealTimeLogFileFlowDestination = {
  __typename: 'SyncRealTimeLogFileFlowDestination';
  fileSyncLogId?: string | null;
  destination?: string | null;
  destinationPath?: string | null;
  syncStatus?: string | null;
  isContactSupportTeam?: boolean | null;
};

export type QuerySyncLogFileDetailsQueryInput = {
  fileSyncLogId?: string | null;
  searchPhrase?: string | null;
  skip?: number | null;
  take?: number | null;
  IsDebug?: boolean | null;
  timeZone?: string | null;
};

export type QuerySyncLogActionDetailsQueryInput = {
  fileSyncLogId?: string | null;
};

export type SyncLogActionDetails = {
  __typename: 'SyncLogActionDetails';
  workflowId?: string | null;
  workflowName?: string | null;
  mappingId?: string | null;
  mappingName?: string | null;
  actionAlias?: string | null;
  syncStatus?: number | null;
  data?: SyncLogActionDetailsData | null;
};

export type SyncLogActionDetailsData = {
  __typename: 'SyncLogActionDetailsData';
  destinations?: Array<SyncLogActionDetailsDestination | null> | null;
  isComplete?: boolean | null;
  isPublishModel?: boolean | null;
  isIssueManagement?: boolean | null;
};

export type SyncLogActionDetailsDestination = {
  __typename: 'SyncLogActionDetailsDestination';
  syncFlowMetricId?: string | null;
  name?: string | null;
  terminalStatus?: number | null;
  publishModelSuccess?: string | null;
  publishModelMessage?: string | null;
  issueManagementCount?: number | null;
  numFiles?: number | null;
  totalSize?: string | null;
};

export type QuerySyncLogActionFolderDetailsQueryInput = {
  fileSyncLogId?: string | null;
  syncFlowMetricId?: string | null;
  syncFolderMetricId?: string | null;
};

export type SyncLogActionFolderDetails = {
  __typename: 'SyncLogActionFolderDetails';
  syncFolderMetricId?: string | null;
  name?: string | null;
  numFiles?: number | null;
  numSubFolders?: number | null;
  terminalStatus?: number | null;
  removeFilesNotInSourceFailed?: boolean | null;
  removeSubFoldersNotInSourceFailed?: boolean | null;
  skippedFilesDueToLimit?: boolean | null;
  skippedSubFoldersDueToDepth?: boolean | null;
  totalSize?: string | null;
};

export type QuerySyncLogActionFileDetailsQueryInput = {
  fileSyncLogId?: string | null;
  syncFolderMetricId?: string | null;
  skip?: number | null;
  take?: number | null;
};

export type SyncLogActionFileDetails = {
  __typename: 'SyncLogActionFileDetails';
  name?: string | null;
  fileSyncDate?: string | null;
  terminalStatus?: number | null;
  totalSize?: string | null;
};

export type QueryGetAutodeskGlueProjectsQueryInput = {
  connectionId?: string | null;
  skip?: number | null;
  take?: number | null;
};

export type AutodeskGlueProject = {
  __typename: 'AutodeskGlueProject';
  id: string;
  text?: string | null;
};

export type QueryGetAutodeskGlueFoldersQueryInput = {
  connectionId?: string | null;
  projectId?: string | null;
  parentFolderId?: string | null;
  skip?: number | null;
  take?: number | null;
};

export type AutodeskGlueFolder = {
  __typename: 'AutodeskGlueFolder';
  id: string;
  text?: string | null;
  isFolder?: boolean | null;
};

export type QueryGetAutodeskGlueSelectedFolderIdStackQueryInput = {
  connectionId?: string | null;
  projectId?: string | null;
  selectedFolderId?: string | null;
};

export type QueryGetAutodeskGlueFolderNameQueryInput = {
  connectionId?: string | null;
  projectId?: string | null;
  folderId?: string | null;
};

export type AutodeskGlueFolderName = {
  __typename: 'AutodeskGlueFolderName';
  name?: string | null;
};

export type QueryGetSharepointSitesQueryInput = {
  connectionId?: string | null;
  skip?: number | null;
  take?: number | null;
};

export type SharepointSite = {
  __typename: 'SharepointSite';
  id: string;
  text?: string | null;
};

export type QueryGetSharepointFoldersQueryInput = {
  connectionId?: string | null;
  siteId?: string | null;
  parentFolderId?: string | null;
  skip?: number | null;
  take?: number | null;
};

export type SharepointFolder = {
  __typename: 'SharepointFolder';
  id: string;
  text?: string | null;
  isFolder?: boolean | null;
};

export type QueryGetSharepointSelectedFolderIdStackQueryInput = {
  connectionId?: string | null;
  siteId?: string | null;
  selectedFolderId?: string | null;
};

export type QueryGetSharepointFolderNameQueryInput = {
  connectionId?: string | null;
  SiteId?: string | null;
  folderId?: string | null;
};

export type SharepointFolderName = {
  __typename: 'SharepointFolderName';
  success?: boolean | null;
  name?: string | null;
};

export type QueryGetNewformaProjectsQueryInput = {
  connectionId?: string | null;
  skip?: number | null;
  take?: number | null;
};

export type NewformaProject = {
  __typename: 'NewformaProject';
  id: string;
  text?: string | null;
};

export type QueryGetNewformaFoldersQueryInput = {
  connectionId?: string | null;
  projectId?: string | null;
  parentFolderId?: string | null;
  skip?: number | null;
  take?: number | null;
};

export type NewformaFolder = {
  __typename: 'NewformaFolder';
  id: string;
  text?: string | null;
};

export type QueryGetNewformaSelectedFolderIdStackQueryInput = {
  connectionId?: string | null;
  projectId?: string | null;
  selectedFolderId?: string | null;
};

export type QueryGetNewformaFolderNameQueryInput = {
  connectionId?: string | null;
  folderId?: string | null;
};

export type NewformaFolderName = {
  __typename: 'NewformaFolderName';
  success?: boolean | null;
  name?: string | null;
};

export type QueryGetPlangridProjectsQueryInput = {
  connectionId?: string | null;
  skip?: number | null;
  take?: number | null;
};

export type PlangridProject = {
  __typename: 'PlangridProject';
  id: string;
  text?: string | null;
};

export type QueryGetPlangridFoldersQueryInput = {
  connectionId?: string | null;
  projectId?: string | null;
  parentFolderId?: string | null;
  skip?: number | null;
  take?: number | null;
};

export type PlangridFolder = {
  __typename: 'PlangridFolder';
  id: string;
  text?: string | null;
};

export type QueryGetPlangridSelectedFolderIdStackQueryInput = {
  connectionId?: string | null;
  projectId?: string | null;
  selectedFolderId?: string | null;
};

export type QueryGetPlangridFolderNameQueryInput = {
  connectionId?: string | null;
  projectId?: string | null;
  folderId?: string | null;
};

export type PlangridFolderName = {
  __typename: 'PlangridFolderName';
  success?: boolean | null;
  name?: string | null;
};

export type QuerySyncGetWorkflowMappingSyncStatusQueryInput = {
  mappingId?: string | null;
};

export type SyncStatus = {
  __typename: 'SyncStatus';
  isSyncRunning?: boolean | null;
  lastSyncAt?: string | null;
  solitaryFlows?: Array<SolitaryFlowStatus | null> | null;
};

export type SolitaryFlowStatus = {
  __typename: 'SolitaryFlowStatus';
  id: string;
  actionNodeId?: string | null;
  syncStatus?: number | null;
};

export type QueryAssemblyCustomCategoriesQueryInput = {
  skip?: number | null;
  take?: number | null;
  name?: string | null;
  workflowId?: string | null;
  companyId?: string | null;
  projectId?: string | null;
};

export type QueryAssemblyCustomCategoryByIdParamsInput = {
  assemblyCustomCategoryId: string;
};

export type QuerySyncFetchForesiteCompaniesQueryInput = {
  connectionId?: string | null;
  skip?: number | null;
  take?: number | null;
};

export type ForesiteCompany = {
  __typename: 'ForesiteCompany';
  id: string;
  text?: string | null;
};

export type QuerySyncFetchForesiteProjectsQueryInput = {
  connectionId?: string | null;
  companyId?: string | null;
  skip?: number | null;
  take?: number | null;
};

export type ForesiteProject = {
  __typename: 'ForesiteProject';
  id: string;
  text?: string | null;
};

export type QuerySyncFetchForesiteFoldersQueryInput = {
  connectionId?: string | null;
  companyId?: string | null;
  projectId?: string | null;
  parentFolderId?: string | null;
  skip?: number | null;
  take?: number | null;
};

export type ForesiteFolder = {
  __typename: 'ForesiteFolder';
  id: string;
  text?: string | null;
  isFolder?: boolean | null;
};

export type QuerySyncFetchForesiteSelectedFolderIdStackQueryInput = {
  connectionId?: string | null;
  companyId?: string | null;
  projectId?: string | null;
  selectedFolderId?: string | null;
};

export type QueryCheckCustomAssemblyNameUniqueQueryInput = {
  customAssemblyName?: string | null;
  projectId?: string | null;
};

export type CheckPropertyUnique = {
  __typename: 'CheckPropertyUnique';
  isUnique?: boolean | null;
};

export type QueryCheckCustomAssemblyIdentifierUniqueQueryInput = {
  customAssemblyIdentifier?: string | null;
  projectId?: string | null;
};

export type QueryCustomAssembliesQueryInput = {
  projectId?: string | null;
  parentLineItemId?: string | null;
  assemblyCustomCategoryId?: string | null;
  skip?: number | null;
  take?: number | null;
  orderBy?: string | null;
};

export type QuerySyncGetWindowsFoldersQueryInput = {
  connectionId?: string | null;
  parentFolderId?: string | null;
  skip?: number | null;
  take?: number | null;
};

export type WindowsFolder = {
  __typename: 'WindowsFolder';
  id: string;
  text?: string | null;
  isFolder?: boolean | null;
};

export type QuerySyncValidateNameParamsInput = {
  entity: string;
};

export type QuerySyncValidateNameQueryInput = {
  name?: string | null;
  workflowId?: string | null;
};

export type QueryPlatformSettingsQueryInput = {
  platformSetting?: string | null;
  skip?: number | null;
  take?: number | null;
};

export type PlatformSetting = {
  __typename: 'PlatformSetting';
  platformSettingId?: string | null;
  platformSettingName?: string | null;
  platformSettingValue?: string | null;
};

export type QuerySyncRecommendedScheduleTimesQueryInput = {
  mappingCount?: number | null;
  days?: string | null;
  interval?: number | null;
};

export type ReadAllMessageMutation = {
  readAllMessage?: boolean | null;
};

export type AddAssemblyMutationVariables = {
  query?: MutationAddAssemblyQueryInput | null;
  body?: MutationAddAssemblyBodyInput | null;
};

export type AddAssemblyMutation = {
  addAssembly?: {
    __typename: 'Assembly';
    part?: {
      __typename: 'Part';
      partId: string;
      partName: string;
      description?: string | null;
      stateId?: string | null;
      isDefault?: boolean | null;
      manufacturerCatalogNumber?: string | null;
      unitOfMeasureCode?: string | null;
      unitOfMeasureName?: string | null;
      upcCode?: string | null;
      url?: string | null;
      catalogUrl?: string | null;
      partUrl?: string | null;
      internalPartNumber?: string | null;
      createdOn?: string | null;
      hasAssembly?: boolean | null;
      manufacturer?: {
        __typename: 'Manufactured';
        manufacturerId: string;
        companyId?: string | null;
        manufacturerName: string;
        homePageUrl?: string | null;
      } | null;
      attriubuteVariantData?: Array<{
        __typename: 'PartAttributeVariantData';
        partAttributeVariantDataId?: string | null;
        partAttributeId?: string | null;
        partAttributeName?: string | null;
        rangeValue?: number | null;
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
        partAttribute?: {
          __typename: 'PartAttribute';
          partAttributeId: string;
          partAttributeName: string;
          allowWriteInVariant?: boolean | null;
          rangeMinimumValue?: number | null;
          rangeMaximumValue?: number | null;
          selectVariants?: Array<{
            __typename: 'PartAttributeSelectVariant';
            partAttributeSelectVariantId?: string | null;
            textValue?: string | null;
            codeValue?: string | null;
            isDeleted?: boolean | null;
          } | null> | null;
          partAttributeType?: {
            __typename: 'PartAttributeType';
            partAttributeTypeId: string;
            partAttributeTypeName: string;
            partAttributeTypeDescription?: string | null;
            sortOrder?: number | null;
          } | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
        } | null;
        selectValues?: Array<{
          __typename: 'PartAttributeSelectVariant';
          partAttributeSelectVariantId?: string | null;
          textValue?: string | null;
          codeValue?: string | null;
          isDeleted?: boolean | null;
        } | null> | null;
      } | null> | null;
      partCategory?: {
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      partImages?: Array<{
        __typename: 'PartImage';
        partImageId?: string | null;
        sortOrder?: number | null;
        isDefault?: boolean | null;
        document?: {
          __typename: 'Document';
          documentId: string;
          documentName?: string | null;
          documentIdentifier?: string | null;
          documentTypeId?: string | null;
          storagePath?: string | null;
          extension?: string | null;
          encodedUrn?: string | null;
          documentStatusTypeId?: string | null;
          isMappedFromShop?: boolean | null;
          folderDocumentResponse?: Array<{
            __typename: 'FolderDocument';
            folderDocumentId: string;
            folderId: string;
            folderName?: string | null;
            documentId: string;
          } | null> | null;
          documentNameFormatted?: string | null;
          createdOn?: string | null;
          createdBy?: string | null;
        } | null;
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
      } | null> | null;
      documentIds?: Array<string | null> | null;
      unitOfMeasure?: {
        __typename: 'UnitOfMeasure';
        unitOfMeasureId: string;
        unitOfMeasureTypeId?: string | null;
        companyId?: string | null;
        unitOfMeasureName: string;
        unitOfMeasureCode: string;
        createdOn?: string | null;
        createdBy?: string | null;
        updatedOn?: string | null;
        modifiedBy?: string | null;
        modifiedOn?: string | null;
      } | null;
    } | null;
    assemblyId?: string | null;
    historyTypeId?: string | null;
    assemblyNodes?: Array<{
      __typename: 'AssemblyNode';
      quantity?: number | null;
      part?: {
        __typename: 'Part';
        partId: string;
        partName: string;
        description?: string | null;
        stateId?: string | null;
        isDefault?: boolean | null;
        manufacturerCatalogNumber?: string | null;
        unitOfMeasureCode?: string | null;
        unitOfMeasureName?: string | null;
        upcCode?: string | null;
        url?: string | null;
        catalogUrl?: string | null;
        partUrl?: string | null;
        internalPartNumber?: string | null;
        createdOn?: string | null;
        hasAssembly?: boolean | null;
        manufacturer?: {
          __typename: 'Manufactured';
          manufacturerId: string;
          companyId?: string | null;
          manufacturerName: string;
          homePageUrl?: string | null;
        } | null;
        attriubuteVariantData?: Array<{
          __typename: 'PartAttributeVariantData';
          partAttributeVariantDataId?: string | null;
          partAttributeId?: string | null;
          partAttributeName?: string | null;
          rangeValue?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          partAttribute?: {
            __typename: 'PartAttribute';
            partAttributeId: string;
            partAttributeName: string;
            allowWriteInVariant?: boolean | null;
            rangeMinimumValue?: number | null;
            rangeMaximumValue?: number | null;
            selectVariants?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
            partAttributeType?: {
              __typename: 'PartAttributeType';
              partAttributeTypeId: string;
              partAttributeTypeName: string;
              partAttributeTypeDescription?: string | null;
              sortOrder?: number | null;
            } | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
          } | null;
          selectValues?: Array<{
            __typename: 'PartAttributeSelectVariant';
            partAttributeSelectVariantId?: string | null;
            textValue?: string | null;
            codeValue?: string | null;
            isDeleted?: boolean | null;
          } | null> | null;
        } | null> | null;
        partCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        partImages?: Array<{
          __typename: 'PartImage';
          partImageId?: string | null;
          sortOrder?: number | null;
          isDefault?: boolean | null;
          document?: {
            __typename: 'Document';
            documentId: string;
            documentName?: string | null;
            documentIdentifier?: string | null;
            documentTypeId?: string | null;
            storagePath?: string | null;
            extension?: string | null;
            encodedUrn?: string | null;
            documentStatusTypeId?: string | null;
            isMappedFromShop?: boolean | null;
            folderDocumentResponse?: Array<{
              __typename: 'FolderDocument';
              folderDocumentId: string;
              folderId: string;
              folderName?: string | null;
              documentId: string;
            } | null> | null;
            documentNameFormatted?: string | null;
            createdOn?: string | null;
            createdBy?: string | null;
          } | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
        } | null> | null;
        documentIds?: Array<string | null> | null;
        unitOfMeasure?: {
          __typename: 'UnitOfMeasure';
          unitOfMeasureId: string;
          unitOfMeasureTypeId?: string | null;
          companyId?: string | null;
          unitOfMeasureName: string;
          unitOfMeasureCode: string;
          createdOn?: string | null;
          createdBy?: string | null;
          updatedOn?: string | null;
          modifiedBy?: string | null;
          modifiedOn?: string | null;
        } | null;
      } | null;
      shopTaskId?: string | null;
      assemblyNodeId?: string | null;
      historyTypeId?: string | null;
      assembly?: {
        __typename: 'Assembly';
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
        assemblyId?: string | null;
        historyTypeId?: string | null;
        assemblyNodes?: Array<{
          __typename: 'AssemblyNode';
          quantity?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          shopTaskId?: string | null;
          assemblyNodeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyNodeType?: {
            __typename: 'AssemblyNodeType';
            assemblyNodeTypeId?: string | null;
            assemblyNodeTypeName?: string | null;
          } | null;
          assemblyNodeName?: string | null;
          assemblyNodeDescription?: string | null;
          positionX?: number | null;
          positionY?: number | null;
        } | null> | null;
        assemblyEdges?: Array<{
          __typename: 'AssemblyEdge';
          assemblyEdgeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyEdgeType?: {
            __typename: 'AssemblyEdgeType';
            assemblyEdgeTypeId?: string | null;
            assemblyEdgeTypeName?: string | null;
          } | null;
          beginHandlePosition?: {
            __typename: 'AssemblyHandlePosition';
            assemblyHandlePositionId?: string | null;
            assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
          } | null;
          endHandlePosition?: {
            __typename: 'AssemblyHandlePosition';
            assemblyHandlePositionId?: string | null;
            assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
          } | null;
          beginNode?: {
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null;
          endNode?: {
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null;
        } | null> | null;
      } | null;
      assemblyNodeType?: {
        __typename: 'AssemblyNodeType';
        assemblyNodeTypeId?: string | null;
        assemblyNodeTypeName?: string | null;
      } | null;
      assemblyNodeName?: string | null;
      assemblyNodeDescription?: string | null;
      positionX?: number | null;
      positionY?: number | null;
    } | null> | null;
    assemblyEdges?: Array<{
      __typename: 'AssemblyEdge';
      assemblyEdgeId?: string | null;
      historyTypeId?: string | null;
      assembly?: {
        __typename: 'Assembly';
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
        assemblyId?: string | null;
        historyTypeId?: string | null;
        assemblyNodes?: Array<{
          __typename: 'AssemblyNode';
          quantity?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          shopTaskId?: string | null;
          assemblyNodeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyNodeType?: {
            __typename: 'AssemblyNodeType';
            assemblyNodeTypeId?: string | null;
            assemblyNodeTypeName?: string | null;
          } | null;
          assemblyNodeName?: string | null;
          assemblyNodeDescription?: string | null;
          positionX?: number | null;
          positionY?: number | null;
        } | null> | null;
        assemblyEdges?: Array<{
          __typename: 'AssemblyEdge';
          assemblyEdgeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyEdgeType?: {
            __typename: 'AssemblyEdgeType';
            assemblyEdgeTypeId?: string | null;
            assemblyEdgeTypeName?: string | null;
          } | null;
          beginHandlePosition?: {
            __typename: 'AssemblyHandlePosition';
            assemblyHandlePositionId?: string | null;
            assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
          } | null;
          endHandlePosition?: {
            __typename: 'AssemblyHandlePosition';
            assemblyHandlePositionId?: string | null;
            assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
          } | null;
          beginNode?: {
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null;
          endNode?: {
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null;
        } | null> | null;
      } | null;
      assemblyEdgeType?: {
        __typename: 'AssemblyEdgeType';
        assemblyEdgeTypeId?: string | null;
        assemblyEdgeTypeName?: string | null;
      } | null;
      beginHandlePosition?: {
        __typename: 'AssemblyHandlePosition';
        assemblyHandlePositionId?: string | null;
        assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
      } | null;
      endHandlePosition?: {
        __typename: 'AssemblyHandlePosition';
        assemblyHandlePositionId?: string | null;
        assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
      } | null;
      beginNode?: {
        __typename: 'AssemblyNode';
        quantity?: number | null;
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
        shopTaskId?: string | null;
        assemblyNodeId?: string | null;
        historyTypeId?: string | null;
        assembly?: {
          __typename: 'Assembly';
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          assemblyId?: string | null;
          historyTypeId?: string | null;
          assemblyNodes?: Array<{
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null> | null;
          assemblyEdges?: Array<{
            __typename: 'AssemblyEdge';
            assemblyEdgeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyEdgeType?: {
              __typename: 'AssemblyEdgeType';
              assemblyEdgeTypeId?: string | null;
              assemblyEdgeTypeName?: string | null;
            } | null;
            beginHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            endHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            beginNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
            endNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
          } | null> | null;
        } | null;
        assemblyNodeType?: {
          __typename: 'AssemblyNodeType';
          assemblyNodeTypeId?: string | null;
          assemblyNodeTypeName?: string | null;
        } | null;
        assemblyNodeName?: string | null;
        assemblyNodeDescription?: string | null;
        positionX?: number | null;
        positionY?: number | null;
      } | null;
      endNode?: {
        __typename: 'AssemblyNode';
        quantity?: number | null;
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
        shopTaskId?: string | null;
        assemblyNodeId?: string | null;
        historyTypeId?: string | null;
        assembly?: {
          __typename: 'Assembly';
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          assemblyId?: string | null;
          historyTypeId?: string | null;
          assemblyNodes?: Array<{
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null> | null;
          assemblyEdges?: Array<{
            __typename: 'AssemblyEdge';
            assemblyEdgeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyEdgeType?: {
              __typename: 'AssemblyEdgeType';
              assemblyEdgeTypeId?: string | null;
              assemblyEdgeTypeName?: string | null;
            } | null;
            beginHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            endHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            beginNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
            endNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
          } | null> | null;
        } | null;
        assemblyNodeType?: {
          __typename: 'AssemblyNodeType';
          assemblyNodeTypeId?: string | null;
          assemblyNodeTypeName?: string | null;
        } | null;
        assemblyNodeName?: string | null;
        assemblyNodeDescription?: string | null;
        positionX?: number | null;
        positionY?: number | null;
      } | null;
    } | null> | null;
  } | null;
};

export type UpdateAssemblyMutationVariables = {
  query?: MutationUpdateAssemblyQueryInput | null;
  body?: MutationUpdateAssemblyBodyInput | null;
};

export type UpdateAssemblyMutation = {
  updateAssembly?: {
    __typename: 'Assembly';
    part?: {
      __typename: 'Part';
      partId: string;
      partName: string;
      description?: string | null;
      stateId?: string | null;
      isDefault?: boolean | null;
      manufacturerCatalogNumber?: string | null;
      unitOfMeasureCode?: string | null;
      unitOfMeasureName?: string | null;
      upcCode?: string | null;
      url?: string | null;
      catalogUrl?: string | null;
      partUrl?: string | null;
      internalPartNumber?: string | null;
      createdOn?: string | null;
      hasAssembly?: boolean | null;
      manufacturer?: {
        __typename: 'Manufactured';
        manufacturerId: string;
        companyId?: string | null;
        manufacturerName: string;
        homePageUrl?: string | null;
      } | null;
      attriubuteVariantData?: Array<{
        __typename: 'PartAttributeVariantData';
        partAttributeVariantDataId?: string | null;
        partAttributeId?: string | null;
        partAttributeName?: string | null;
        rangeValue?: number | null;
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
        partAttribute?: {
          __typename: 'PartAttribute';
          partAttributeId: string;
          partAttributeName: string;
          allowWriteInVariant?: boolean | null;
          rangeMinimumValue?: number | null;
          rangeMaximumValue?: number | null;
          selectVariants?: Array<{
            __typename: 'PartAttributeSelectVariant';
            partAttributeSelectVariantId?: string | null;
            textValue?: string | null;
            codeValue?: string | null;
            isDeleted?: boolean | null;
          } | null> | null;
          partAttributeType?: {
            __typename: 'PartAttributeType';
            partAttributeTypeId: string;
            partAttributeTypeName: string;
            partAttributeTypeDescription?: string | null;
            sortOrder?: number | null;
          } | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
        } | null;
        selectValues?: Array<{
          __typename: 'PartAttributeSelectVariant';
          partAttributeSelectVariantId?: string | null;
          textValue?: string | null;
          codeValue?: string | null;
          isDeleted?: boolean | null;
        } | null> | null;
      } | null> | null;
      partCategory?: {
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      partImages?: Array<{
        __typename: 'PartImage';
        partImageId?: string | null;
        sortOrder?: number | null;
        isDefault?: boolean | null;
        document?: {
          __typename: 'Document';
          documentId: string;
          documentName?: string | null;
          documentIdentifier?: string | null;
          documentTypeId?: string | null;
          storagePath?: string | null;
          extension?: string | null;
          encodedUrn?: string | null;
          documentStatusTypeId?: string | null;
          isMappedFromShop?: boolean | null;
          folderDocumentResponse?: Array<{
            __typename: 'FolderDocument';
            folderDocumentId: string;
            folderId: string;
            folderName?: string | null;
            documentId: string;
          } | null> | null;
          documentNameFormatted?: string | null;
          createdOn?: string | null;
          createdBy?: string | null;
        } | null;
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
      } | null> | null;
      documentIds?: Array<string | null> | null;
      unitOfMeasure?: {
        __typename: 'UnitOfMeasure';
        unitOfMeasureId: string;
        unitOfMeasureTypeId?: string | null;
        companyId?: string | null;
        unitOfMeasureName: string;
        unitOfMeasureCode: string;
        createdOn?: string | null;
        createdBy?: string | null;
        updatedOn?: string | null;
        modifiedBy?: string | null;
        modifiedOn?: string | null;
      } | null;
    } | null;
    assemblyId?: string | null;
    historyTypeId?: string | null;
    assemblyNodes?: Array<{
      __typename: 'AssemblyNode';
      quantity?: number | null;
      part?: {
        __typename: 'Part';
        partId: string;
        partName: string;
        description?: string | null;
        stateId?: string | null;
        isDefault?: boolean | null;
        manufacturerCatalogNumber?: string | null;
        unitOfMeasureCode?: string | null;
        unitOfMeasureName?: string | null;
        upcCode?: string | null;
        url?: string | null;
        catalogUrl?: string | null;
        partUrl?: string | null;
        internalPartNumber?: string | null;
        createdOn?: string | null;
        hasAssembly?: boolean | null;
        manufacturer?: {
          __typename: 'Manufactured';
          manufacturerId: string;
          companyId?: string | null;
          manufacturerName: string;
          homePageUrl?: string | null;
        } | null;
        attriubuteVariantData?: Array<{
          __typename: 'PartAttributeVariantData';
          partAttributeVariantDataId?: string | null;
          partAttributeId?: string | null;
          partAttributeName?: string | null;
          rangeValue?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          partAttribute?: {
            __typename: 'PartAttribute';
            partAttributeId: string;
            partAttributeName: string;
            allowWriteInVariant?: boolean | null;
            rangeMinimumValue?: number | null;
            rangeMaximumValue?: number | null;
            selectVariants?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
            partAttributeType?: {
              __typename: 'PartAttributeType';
              partAttributeTypeId: string;
              partAttributeTypeName: string;
              partAttributeTypeDescription?: string | null;
              sortOrder?: number | null;
            } | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
          } | null;
          selectValues?: Array<{
            __typename: 'PartAttributeSelectVariant';
            partAttributeSelectVariantId?: string | null;
            textValue?: string | null;
            codeValue?: string | null;
            isDeleted?: boolean | null;
          } | null> | null;
        } | null> | null;
        partCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        partImages?: Array<{
          __typename: 'PartImage';
          partImageId?: string | null;
          sortOrder?: number | null;
          isDefault?: boolean | null;
          document?: {
            __typename: 'Document';
            documentId: string;
            documentName?: string | null;
            documentIdentifier?: string | null;
            documentTypeId?: string | null;
            storagePath?: string | null;
            extension?: string | null;
            encodedUrn?: string | null;
            documentStatusTypeId?: string | null;
            isMappedFromShop?: boolean | null;
            folderDocumentResponse?: Array<{
              __typename: 'FolderDocument';
              folderDocumentId: string;
              folderId: string;
              folderName?: string | null;
              documentId: string;
            } | null> | null;
            documentNameFormatted?: string | null;
            createdOn?: string | null;
            createdBy?: string | null;
          } | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
        } | null> | null;
        documentIds?: Array<string | null> | null;
        unitOfMeasure?: {
          __typename: 'UnitOfMeasure';
          unitOfMeasureId: string;
          unitOfMeasureTypeId?: string | null;
          companyId?: string | null;
          unitOfMeasureName: string;
          unitOfMeasureCode: string;
          createdOn?: string | null;
          createdBy?: string | null;
          updatedOn?: string | null;
          modifiedBy?: string | null;
          modifiedOn?: string | null;
        } | null;
      } | null;
      shopTaskId?: string | null;
      assemblyNodeId?: string | null;
      historyTypeId?: string | null;
      assembly?: {
        __typename: 'Assembly';
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
        assemblyId?: string | null;
        historyTypeId?: string | null;
        assemblyNodes?: Array<{
          __typename: 'AssemblyNode';
          quantity?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          shopTaskId?: string | null;
          assemblyNodeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyNodeType?: {
            __typename: 'AssemblyNodeType';
            assemblyNodeTypeId?: string | null;
            assemblyNodeTypeName?: string | null;
          } | null;
          assemblyNodeName?: string | null;
          assemblyNodeDescription?: string | null;
          positionX?: number | null;
          positionY?: number | null;
        } | null> | null;
        assemblyEdges?: Array<{
          __typename: 'AssemblyEdge';
          assemblyEdgeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyEdgeType?: {
            __typename: 'AssemblyEdgeType';
            assemblyEdgeTypeId?: string | null;
            assemblyEdgeTypeName?: string | null;
          } | null;
          beginHandlePosition?: {
            __typename: 'AssemblyHandlePosition';
            assemblyHandlePositionId?: string | null;
            assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
          } | null;
          endHandlePosition?: {
            __typename: 'AssemblyHandlePosition';
            assemblyHandlePositionId?: string | null;
            assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
          } | null;
          beginNode?: {
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null;
          endNode?: {
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null;
        } | null> | null;
      } | null;
      assemblyNodeType?: {
        __typename: 'AssemblyNodeType';
        assemblyNodeTypeId?: string | null;
        assemblyNodeTypeName?: string | null;
      } | null;
      assemblyNodeName?: string | null;
      assemblyNodeDescription?: string | null;
      positionX?: number | null;
      positionY?: number | null;
    } | null> | null;
    assemblyEdges?: Array<{
      __typename: 'AssemblyEdge';
      assemblyEdgeId?: string | null;
      historyTypeId?: string | null;
      assembly?: {
        __typename: 'Assembly';
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
        assemblyId?: string | null;
        historyTypeId?: string | null;
        assemblyNodes?: Array<{
          __typename: 'AssemblyNode';
          quantity?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          shopTaskId?: string | null;
          assemblyNodeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyNodeType?: {
            __typename: 'AssemblyNodeType';
            assemblyNodeTypeId?: string | null;
            assemblyNodeTypeName?: string | null;
          } | null;
          assemblyNodeName?: string | null;
          assemblyNodeDescription?: string | null;
          positionX?: number | null;
          positionY?: number | null;
        } | null> | null;
        assemblyEdges?: Array<{
          __typename: 'AssemblyEdge';
          assemblyEdgeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyEdgeType?: {
            __typename: 'AssemblyEdgeType';
            assemblyEdgeTypeId?: string | null;
            assemblyEdgeTypeName?: string | null;
          } | null;
          beginHandlePosition?: {
            __typename: 'AssemblyHandlePosition';
            assemblyHandlePositionId?: string | null;
            assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
          } | null;
          endHandlePosition?: {
            __typename: 'AssemblyHandlePosition';
            assemblyHandlePositionId?: string | null;
            assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
          } | null;
          beginNode?: {
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null;
          endNode?: {
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null;
        } | null> | null;
      } | null;
      assemblyEdgeType?: {
        __typename: 'AssemblyEdgeType';
        assemblyEdgeTypeId?: string | null;
        assemblyEdgeTypeName?: string | null;
      } | null;
      beginHandlePosition?: {
        __typename: 'AssemblyHandlePosition';
        assemblyHandlePositionId?: string | null;
        assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
      } | null;
      endHandlePosition?: {
        __typename: 'AssemblyHandlePosition';
        assemblyHandlePositionId?: string | null;
        assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
      } | null;
      beginNode?: {
        __typename: 'AssemblyNode';
        quantity?: number | null;
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
        shopTaskId?: string | null;
        assemblyNodeId?: string | null;
        historyTypeId?: string | null;
        assembly?: {
          __typename: 'Assembly';
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          assemblyId?: string | null;
          historyTypeId?: string | null;
          assemblyNodes?: Array<{
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null> | null;
          assemblyEdges?: Array<{
            __typename: 'AssemblyEdge';
            assemblyEdgeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyEdgeType?: {
              __typename: 'AssemblyEdgeType';
              assemblyEdgeTypeId?: string | null;
              assemblyEdgeTypeName?: string | null;
            } | null;
            beginHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            endHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            beginNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
            endNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
          } | null> | null;
        } | null;
        assemblyNodeType?: {
          __typename: 'AssemblyNodeType';
          assemblyNodeTypeId?: string | null;
          assemblyNodeTypeName?: string | null;
        } | null;
        assemblyNodeName?: string | null;
        assemblyNodeDescription?: string | null;
        positionX?: number | null;
        positionY?: number | null;
      } | null;
      endNode?: {
        __typename: 'AssemblyNode';
        quantity?: number | null;
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
        shopTaskId?: string | null;
        assemblyNodeId?: string | null;
        historyTypeId?: string | null;
        assembly?: {
          __typename: 'Assembly';
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          assemblyId?: string | null;
          historyTypeId?: string | null;
          assemblyNodes?: Array<{
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null> | null;
          assemblyEdges?: Array<{
            __typename: 'AssemblyEdge';
            assemblyEdgeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyEdgeType?: {
              __typename: 'AssemblyEdgeType';
              assemblyEdgeTypeId?: string | null;
              assemblyEdgeTypeName?: string | null;
            } | null;
            beginHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            endHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            beginNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
            endNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
          } | null> | null;
        } | null;
        assemblyNodeType?: {
          __typename: 'AssemblyNodeType';
          assemblyNodeTypeId?: string | null;
          assemblyNodeTypeName?: string | null;
        } | null;
        assemblyNodeName?: string | null;
        assemblyNodeDescription?: string | null;
        positionX?: number | null;
        positionY?: number | null;
      } | null;
    } | null> | null;
  } | null;
};

export type DeleteAssemblyMutationVariables = {
  params: MutationDeleteAssemblyParamsInput;
};

export type DeleteAssemblyMutation = {
  deleteAssembly?: boolean | null;
};

export type AddAssemblyNodeMutationVariables = {
  query?: MutationAddAssemblyNodeQueryInput | null;
  body?: MutationAddAssemblyNodeBodyInput | null;
};

export type AddAssemblyNodeMutation = {
  addAssemblyNode?: {
    __typename: 'AssemblyNode';
    quantity?: number | null;
    part?: {
      __typename: 'Part';
      partId: string;
      partName: string;
      description?: string | null;
      stateId?: string | null;
      isDefault?: boolean | null;
      manufacturerCatalogNumber?: string | null;
      unitOfMeasureCode?: string | null;
      unitOfMeasureName?: string | null;
      upcCode?: string | null;
      url?: string | null;
      catalogUrl?: string | null;
      partUrl?: string | null;
      internalPartNumber?: string | null;
      createdOn?: string | null;
      hasAssembly?: boolean | null;
      manufacturer?: {
        __typename: 'Manufactured';
        manufacturerId: string;
        companyId?: string | null;
        manufacturerName: string;
        homePageUrl?: string | null;
      } | null;
      attriubuteVariantData?: Array<{
        __typename: 'PartAttributeVariantData';
        partAttributeVariantDataId?: string | null;
        partAttributeId?: string | null;
        partAttributeName?: string | null;
        rangeValue?: number | null;
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
        partAttribute?: {
          __typename: 'PartAttribute';
          partAttributeId: string;
          partAttributeName: string;
          allowWriteInVariant?: boolean | null;
          rangeMinimumValue?: number | null;
          rangeMaximumValue?: number | null;
          selectVariants?: Array<{
            __typename: 'PartAttributeSelectVariant';
            partAttributeSelectVariantId?: string | null;
            textValue?: string | null;
            codeValue?: string | null;
            isDeleted?: boolean | null;
          } | null> | null;
          partAttributeType?: {
            __typename: 'PartAttributeType';
            partAttributeTypeId: string;
            partAttributeTypeName: string;
            partAttributeTypeDescription?: string | null;
            sortOrder?: number | null;
          } | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
        } | null;
        selectValues?: Array<{
          __typename: 'PartAttributeSelectVariant';
          partAttributeSelectVariantId?: string | null;
          textValue?: string | null;
          codeValue?: string | null;
          isDeleted?: boolean | null;
        } | null> | null;
      } | null> | null;
      partCategory?: {
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      partImages?: Array<{
        __typename: 'PartImage';
        partImageId?: string | null;
        sortOrder?: number | null;
        isDefault?: boolean | null;
        document?: {
          __typename: 'Document';
          documentId: string;
          documentName?: string | null;
          documentIdentifier?: string | null;
          documentTypeId?: string | null;
          storagePath?: string | null;
          extension?: string | null;
          encodedUrn?: string | null;
          documentStatusTypeId?: string | null;
          isMappedFromShop?: boolean | null;
          folderDocumentResponse?: Array<{
            __typename: 'FolderDocument';
            folderDocumentId: string;
            folderId: string;
            folderName?: string | null;
            documentId: string;
          } | null> | null;
          documentNameFormatted?: string | null;
          createdOn?: string | null;
          createdBy?: string | null;
        } | null;
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
      } | null> | null;
      documentIds?: Array<string | null> | null;
      unitOfMeasure?: {
        __typename: 'UnitOfMeasure';
        unitOfMeasureId: string;
        unitOfMeasureTypeId?: string | null;
        companyId?: string | null;
        unitOfMeasureName: string;
        unitOfMeasureCode: string;
        createdOn?: string | null;
        createdBy?: string | null;
        updatedOn?: string | null;
        modifiedBy?: string | null;
        modifiedOn?: string | null;
      } | null;
    } | null;
    shopTaskId?: string | null;
    assemblyNodeId?: string | null;
    historyTypeId?: string | null;
    assembly?: {
      __typename: 'Assembly';
      part?: {
        __typename: 'Part';
        partId: string;
        partName: string;
        description?: string | null;
        stateId?: string | null;
        isDefault?: boolean | null;
        manufacturerCatalogNumber?: string | null;
        unitOfMeasureCode?: string | null;
        unitOfMeasureName?: string | null;
        upcCode?: string | null;
        url?: string | null;
        catalogUrl?: string | null;
        partUrl?: string | null;
        internalPartNumber?: string | null;
        createdOn?: string | null;
        hasAssembly?: boolean | null;
        manufacturer?: {
          __typename: 'Manufactured';
          manufacturerId: string;
          companyId?: string | null;
          manufacturerName: string;
          homePageUrl?: string | null;
        } | null;
        attriubuteVariantData?: Array<{
          __typename: 'PartAttributeVariantData';
          partAttributeVariantDataId?: string | null;
          partAttributeId?: string | null;
          partAttributeName?: string | null;
          rangeValue?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          partAttribute?: {
            __typename: 'PartAttribute';
            partAttributeId: string;
            partAttributeName: string;
            allowWriteInVariant?: boolean | null;
            rangeMinimumValue?: number | null;
            rangeMaximumValue?: number | null;
            selectVariants?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
            partAttributeType?: {
              __typename: 'PartAttributeType';
              partAttributeTypeId: string;
              partAttributeTypeName: string;
              partAttributeTypeDescription?: string | null;
              sortOrder?: number | null;
            } | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
          } | null;
          selectValues?: Array<{
            __typename: 'PartAttributeSelectVariant';
            partAttributeSelectVariantId?: string | null;
            textValue?: string | null;
            codeValue?: string | null;
            isDeleted?: boolean | null;
          } | null> | null;
        } | null> | null;
        partCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        partImages?: Array<{
          __typename: 'PartImage';
          partImageId?: string | null;
          sortOrder?: number | null;
          isDefault?: boolean | null;
          document?: {
            __typename: 'Document';
            documentId: string;
            documentName?: string | null;
            documentIdentifier?: string | null;
            documentTypeId?: string | null;
            storagePath?: string | null;
            extension?: string | null;
            encodedUrn?: string | null;
            documentStatusTypeId?: string | null;
            isMappedFromShop?: boolean | null;
            folderDocumentResponse?: Array<{
              __typename: 'FolderDocument';
              folderDocumentId: string;
              folderId: string;
              folderName?: string | null;
              documentId: string;
            } | null> | null;
            documentNameFormatted?: string | null;
            createdOn?: string | null;
            createdBy?: string | null;
          } | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
        } | null> | null;
        documentIds?: Array<string | null> | null;
        unitOfMeasure?: {
          __typename: 'UnitOfMeasure';
          unitOfMeasureId: string;
          unitOfMeasureTypeId?: string | null;
          companyId?: string | null;
          unitOfMeasureName: string;
          unitOfMeasureCode: string;
          createdOn?: string | null;
          createdBy?: string | null;
          updatedOn?: string | null;
          modifiedBy?: string | null;
          modifiedOn?: string | null;
        } | null;
      } | null;
      assemblyId?: string | null;
      historyTypeId?: string | null;
      assemblyNodes?: Array<{
        __typename: 'AssemblyNode';
        quantity?: number | null;
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
        shopTaskId?: string | null;
        assemblyNodeId?: string | null;
        historyTypeId?: string | null;
        assembly?: {
          __typename: 'Assembly';
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          assemblyId?: string | null;
          historyTypeId?: string | null;
          assemblyNodes?: Array<{
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null> | null;
          assemblyEdges?: Array<{
            __typename: 'AssemblyEdge';
            assemblyEdgeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyEdgeType?: {
              __typename: 'AssemblyEdgeType';
              assemblyEdgeTypeId?: string | null;
              assemblyEdgeTypeName?: string | null;
            } | null;
            beginHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            endHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            beginNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
            endNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
          } | null> | null;
        } | null;
        assemblyNodeType?: {
          __typename: 'AssemblyNodeType';
          assemblyNodeTypeId?: string | null;
          assemblyNodeTypeName?: string | null;
        } | null;
        assemblyNodeName?: string | null;
        assemblyNodeDescription?: string | null;
        positionX?: number | null;
        positionY?: number | null;
      } | null> | null;
      assemblyEdges?: Array<{
        __typename: 'AssemblyEdge';
        assemblyEdgeId?: string | null;
        historyTypeId?: string | null;
        assembly?: {
          __typename: 'Assembly';
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          assemblyId?: string | null;
          historyTypeId?: string | null;
          assemblyNodes?: Array<{
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null> | null;
          assemblyEdges?: Array<{
            __typename: 'AssemblyEdge';
            assemblyEdgeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyEdgeType?: {
              __typename: 'AssemblyEdgeType';
              assemblyEdgeTypeId?: string | null;
              assemblyEdgeTypeName?: string | null;
            } | null;
            beginHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            endHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            beginNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
            endNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
          } | null> | null;
        } | null;
        assemblyEdgeType?: {
          __typename: 'AssemblyEdgeType';
          assemblyEdgeTypeId?: string | null;
          assemblyEdgeTypeName?: string | null;
        } | null;
        beginHandlePosition?: {
          __typename: 'AssemblyHandlePosition';
          assemblyHandlePositionId?: string | null;
          assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
        } | null;
        endHandlePosition?: {
          __typename: 'AssemblyHandlePosition';
          assemblyHandlePositionId?: string | null;
          assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
        } | null;
        beginNode?: {
          __typename: 'AssemblyNode';
          quantity?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          shopTaskId?: string | null;
          assemblyNodeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyNodeType?: {
            __typename: 'AssemblyNodeType';
            assemblyNodeTypeId?: string | null;
            assemblyNodeTypeName?: string | null;
          } | null;
          assemblyNodeName?: string | null;
          assemblyNodeDescription?: string | null;
          positionX?: number | null;
          positionY?: number | null;
        } | null;
        endNode?: {
          __typename: 'AssemblyNode';
          quantity?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          shopTaskId?: string | null;
          assemblyNodeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyNodeType?: {
            __typename: 'AssemblyNodeType';
            assemblyNodeTypeId?: string | null;
            assemblyNodeTypeName?: string | null;
          } | null;
          assemblyNodeName?: string | null;
          assemblyNodeDescription?: string | null;
          positionX?: number | null;
          positionY?: number | null;
        } | null;
      } | null> | null;
    } | null;
    assemblyNodeType?: {
      __typename: 'AssemblyNodeType';
      assemblyNodeTypeId?: string | null;
      assemblyNodeTypeName?: string | null;
    } | null;
    assemblyNodeName?: string | null;
    assemblyNodeDescription?: string | null;
    positionX?: number | null;
    positionY?: number | null;
  } | null;
};

export type UpdateAssemblyNodeMutationVariables = {
  params: MutationUpdateAssemblyNodeParamsInput;
  query?: MutationUpdateAssemblyNodeQueryInput | null;
  body?: MutationUpdateAssemblyNodeBodyInput | null;
};

export type UpdateAssemblyNodeMutation = {
  updateAssemblyNode?: {
    __typename: 'AssemblyNode';
    quantity?: number | null;
    part?: {
      __typename: 'Part';
      partId: string;
      partName: string;
      description?: string | null;
      stateId?: string | null;
      isDefault?: boolean | null;
      manufacturerCatalogNumber?: string | null;
      unitOfMeasureCode?: string | null;
      unitOfMeasureName?: string | null;
      upcCode?: string | null;
      url?: string | null;
      catalogUrl?: string | null;
      partUrl?: string | null;
      internalPartNumber?: string | null;
      createdOn?: string | null;
      hasAssembly?: boolean | null;
      manufacturer?: {
        __typename: 'Manufactured';
        manufacturerId: string;
        companyId?: string | null;
        manufacturerName: string;
        homePageUrl?: string | null;
      } | null;
      attriubuteVariantData?: Array<{
        __typename: 'PartAttributeVariantData';
        partAttributeVariantDataId?: string | null;
        partAttributeId?: string | null;
        partAttributeName?: string | null;
        rangeValue?: number | null;
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
        partAttribute?: {
          __typename: 'PartAttribute';
          partAttributeId: string;
          partAttributeName: string;
          allowWriteInVariant?: boolean | null;
          rangeMinimumValue?: number | null;
          rangeMaximumValue?: number | null;
          selectVariants?: Array<{
            __typename: 'PartAttributeSelectVariant';
            partAttributeSelectVariantId?: string | null;
            textValue?: string | null;
            codeValue?: string | null;
            isDeleted?: boolean | null;
          } | null> | null;
          partAttributeType?: {
            __typename: 'PartAttributeType';
            partAttributeTypeId: string;
            partAttributeTypeName: string;
            partAttributeTypeDescription?: string | null;
            sortOrder?: number | null;
          } | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
        } | null;
        selectValues?: Array<{
          __typename: 'PartAttributeSelectVariant';
          partAttributeSelectVariantId?: string | null;
          textValue?: string | null;
          codeValue?: string | null;
          isDeleted?: boolean | null;
        } | null> | null;
      } | null> | null;
      partCategory?: {
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      partImages?: Array<{
        __typename: 'PartImage';
        partImageId?: string | null;
        sortOrder?: number | null;
        isDefault?: boolean | null;
        document?: {
          __typename: 'Document';
          documentId: string;
          documentName?: string | null;
          documentIdentifier?: string | null;
          documentTypeId?: string | null;
          storagePath?: string | null;
          extension?: string | null;
          encodedUrn?: string | null;
          documentStatusTypeId?: string | null;
          isMappedFromShop?: boolean | null;
          folderDocumentResponse?: Array<{
            __typename: 'FolderDocument';
            folderDocumentId: string;
            folderId: string;
            folderName?: string | null;
            documentId: string;
          } | null> | null;
          documentNameFormatted?: string | null;
          createdOn?: string | null;
          createdBy?: string | null;
        } | null;
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
      } | null> | null;
      documentIds?: Array<string | null> | null;
      unitOfMeasure?: {
        __typename: 'UnitOfMeasure';
        unitOfMeasureId: string;
        unitOfMeasureTypeId?: string | null;
        companyId?: string | null;
        unitOfMeasureName: string;
        unitOfMeasureCode: string;
        createdOn?: string | null;
        createdBy?: string | null;
        updatedOn?: string | null;
        modifiedBy?: string | null;
        modifiedOn?: string | null;
      } | null;
    } | null;
    shopTaskId?: string | null;
    assemblyNodeId?: string | null;
    historyTypeId?: string | null;
    assembly?: {
      __typename: 'Assembly';
      part?: {
        __typename: 'Part';
        partId: string;
        partName: string;
        description?: string | null;
        stateId?: string | null;
        isDefault?: boolean | null;
        manufacturerCatalogNumber?: string | null;
        unitOfMeasureCode?: string | null;
        unitOfMeasureName?: string | null;
        upcCode?: string | null;
        url?: string | null;
        catalogUrl?: string | null;
        partUrl?: string | null;
        internalPartNumber?: string | null;
        createdOn?: string | null;
        hasAssembly?: boolean | null;
        manufacturer?: {
          __typename: 'Manufactured';
          manufacturerId: string;
          companyId?: string | null;
          manufacturerName: string;
          homePageUrl?: string | null;
        } | null;
        attriubuteVariantData?: Array<{
          __typename: 'PartAttributeVariantData';
          partAttributeVariantDataId?: string | null;
          partAttributeId?: string | null;
          partAttributeName?: string | null;
          rangeValue?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          partAttribute?: {
            __typename: 'PartAttribute';
            partAttributeId: string;
            partAttributeName: string;
            allowWriteInVariant?: boolean | null;
            rangeMinimumValue?: number | null;
            rangeMaximumValue?: number | null;
            selectVariants?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
            partAttributeType?: {
              __typename: 'PartAttributeType';
              partAttributeTypeId: string;
              partAttributeTypeName: string;
              partAttributeTypeDescription?: string | null;
              sortOrder?: number | null;
            } | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
          } | null;
          selectValues?: Array<{
            __typename: 'PartAttributeSelectVariant';
            partAttributeSelectVariantId?: string | null;
            textValue?: string | null;
            codeValue?: string | null;
            isDeleted?: boolean | null;
          } | null> | null;
        } | null> | null;
        partCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        partImages?: Array<{
          __typename: 'PartImage';
          partImageId?: string | null;
          sortOrder?: number | null;
          isDefault?: boolean | null;
          document?: {
            __typename: 'Document';
            documentId: string;
            documentName?: string | null;
            documentIdentifier?: string | null;
            documentTypeId?: string | null;
            storagePath?: string | null;
            extension?: string | null;
            encodedUrn?: string | null;
            documentStatusTypeId?: string | null;
            isMappedFromShop?: boolean | null;
            folderDocumentResponse?: Array<{
              __typename: 'FolderDocument';
              folderDocumentId: string;
              folderId: string;
              folderName?: string | null;
              documentId: string;
            } | null> | null;
            documentNameFormatted?: string | null;
            createdOn?: string | null;
            createdBy?: string | null;
          } | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
        } | null> | null;
        documentIds?: Array<string | null> | null;
        unitOfMeasure?: {
          __typename: 'UnitOfMeasure';
          unitOfMeasureId: string;
          unitOfMeasureTypeId?: string | null;
          companyId?: string | null;
          unitOfMeasureName: string;
          unitOfMeasureCode: string;
          createdOn?: string | null;
          createdBy?: string | null;
          updatedOn?: string | null;
          modifiedBy?: string | null;
          modifiedOn?: string | null;
        } | null;
      } | null;
      assemblyId?: string | null;
      historyTypeId?: string | null;
      assemblyNodes?: Array<{
        __typename: 'AssemblyNode';
        quantity?: number | null;
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
        shopTaskId?: string | null;
        assemblyNodeId?: string | null;
        historyTypeId?: string | null;
        assembly?: {
          __typename: 'Assembly';
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          assemblyId?: string | null;
          historyTypeId?: string | null;
          assemblyNodes?: Array<{
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null> | null;
          assemblyEdges?: Array<{
            __typename: 'AssemblyEdge';
            assemblyEdgeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyEdgeType?: {
              __typename: 'AssemblyEdgeType';
              assemblyEdgeTypeId?: string | null;
              assemblyEdgeTypeName?: string | null;
            } | null;
            beginHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            endHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            beginNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
            endNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
          } | null> | null;
        } | null;
        assemblyNodeType?: {
          __typename: 'AssemblyNodeType';
          assemblyNodeTypeId?: string | null;
          assemblyNodeTypeName?: string | null;
        } | null;
        assemblyNodeName?: string | null;
        assemblyNodeDescription?: string | null;
        positionX?: number | null;
        positionY?: number | null;
      } | null> | null;
      assemblyEdges?: Array<{
        __typename: 'AssemblyEdge';
        assemblyEdgeId?: string | null;
        historyTypeId?: string | null;
        assembly?: {
          __typename: 'Assembly';
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          assemblyId?: string | null;
          historyTypeId?: string | null;
          assemblyNodes?: Array<{
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null> | null;
          assemblyEdges?: Array<{
            __typename: 'AssemblyEdge';
            assemblyEdgeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyEdgeType?: {
              __typename: 'AssemblyEdgeType';
              assemblyEdgeTypeId?: string | null;
              assemblyEdgeTypeName?: string | null;
            } | null;
            beginHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            endHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            beginNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
            endNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
          } | null> | null;
        } | null;
        assemblyEdgeType?: {
          __typename: 'AssemblyEdgeType';
          assemblyEdgeTypeId?: string | null;
          assemblyEdgeTypeName?: string | null;
        } | null;
        beginHandlePosition?: {
          __typename: 'AssemblyHandlePosition';
          assemblyHandlePositionId?: string | null;
          assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
        } | null;
        endHandlePosition?: {
          __typename: 'AssemblyHandlePosition';
          assemblyHandlePositionId?: string | null;
          assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
        } | null;
        beginNode?: {
          __typename: 'AssemblyNode';
          quantity?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          shopTaskId?: string | null;
          assemblyNodeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyNodeType?: {
            __typename: 'AssemblyNodeType';
            assemblyNodeTypeId?: string | null;
            assemblyNodeTypeName?: string | null;
          } | null;
          assemblyNodeName?: string | null;
          assemblyNodeDescription?: string | null;
          positionX?: number | null;
          positionY?: number | null;
        } | null;
        endNode?: {
          __typename: 'AssemblyNode';
          quantity?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          shopTaskId?: string | null;
          assemblyNodeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyNodeType?: {
            __typename: 'AssemblyNodeType';
            assemblyNodeTypeId?: string | null;
            assemblyNodeTypeName?: string | null;
          } | null;
          assemblyNodeName?: string | null;
          assemblyNodeDescription?: string | null;
          positionX?: number | null;
          positionY?: number | null;
        } | null;
      } | null> | null;
    } | null;
    assemblyNodeType?: {
      __typename: 'AssemblyNodeType';
      assemblyNodeTypeId?: string | null;
      assemblyNodeTypeName?: string | null;
    } | null;
    assemblyNodeName?: string | null;
    assemblyNodeDescription?: string | null;
    positionX?: number | null;
    positionY?: number | null;
  } | null;
};

export type DeleteAssemblyNodeMutationVariables = {
  params: MutationDeleteAssemblyNodeParamsInput;
};

export type DeleteAssemblyNodeMutation = {
  deleteAssemblyNode?: boolean | null;
};

export type AddAssemblyEdgeMutationVariables = {
  query?: MutationAddAssemblyEdgeQueryInput | null;
  body?: MutationAddAssemblyEdgeBodyInput | null;
};

export type AddAssemblyEdgeMutation = {
  addAssemblyEdge?: {
    __typename: 'AssemblyEdge';
    assemblyEdgeId?: string | null;
    historyTypeId?: string | null;
    assembly?: {
      __typename: 'Assembly';
      part?: {
        __typename: 'Part';
        partId: string;
        partName: string;
        description?: string | null;
        stateId?: string | null;
        isDefault?: boolean | null;
        manufacturerCatalogNumber?: string | null;
        unitOfMeasureCode?: string | null;
        unitOfMeasureName?: string | null;
        upcCode?: string | null;
        url?: string | null;
        catalogUrl?: string | null;
        partUrl?: string | null;
        internalPartNumber?: string | null;
        createdOn?: string | null;
        hasAssembly?: boolean | null;
        manufacturer?: {
          __typename: 'Manufactured';
          manufacturerId: string;
          companyId?: string | null;
          manufacturerName: string;
          homePageUrl?: string | null;
        } | null;
        attriubuteVariantData?: Array<{
          __typename: 'PartAttributeVariantData';
          partAttributeVariantDataId?: string | null;
          partAttributeId?: string | null;
          partAttributeName?: string | null;
          rangeValue?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          partAttribute?: {
            __typename: 'PartAttribute';
            partAttributeId: string;
            partAttributeName: string;
            allowWriteInVariant?: boolean | null;
            rangeMinimumValue?: number | null;
            rangeMaximumValue?: number | null;
            selectVariants?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
            partAttributeType?: {
              __typename: 'PartAttributeType';
              partAttributeTypeId: string;
              partAttributeTypeName: string;
              partAttributeTypeDescription?: string | null;
              sortOrder?: number | null;
            } | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
          } | null;
          selectValues?: Array<{
            __typename: 'PartAttributeSelectVariant';
            partAttributeSelectVariantId?: string | null;
            textValue?: string | null;
            codeValue?: string | null;
            isDeleted?: boolean | null;
          } | null> | null;
        } | null> | null;
        partCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        partImages?: Array<{
          __typename: 'PartImage';
          partImageId?: string | null;
          sortOrder?: number | null;
          isDefault?: boolean | null;
          document?: {
            __typename: 'Document';
            documentId: string;
            documentName?: string | null;
            documentIdentifier?: string | null;
            documentTypeId?: string | null;
            storagePath?: string | null;
            extension?: string | null;
            encodedUrn?: string | null;
            documentStatusTypeId?: string | null;
            isMappedFromShop?: boolean | null;
            folderDocumentResponse?: Array<{
              __typename: 'FolderDocument';
              folderDocumentId: string;
              folderId: string;
              folderName?: string | null;
              documentId: string;
            } | null> | null;
            documentNameFormatted?: string | null;
            createdOn?: string | null;
            createdBy?: string | null;
          } | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
        } | null> | null;
        documentIds?: Array<string | null> | null;
        unitOfMeasure?: {
          __typename: 'UnitOfMeasure';
          unitOfMeasureId: string;
          unitOfMeasureTypeId?: string | null;
          companyId?: string | null;
          unitOfMeasureName: string;
          unitOfMeasureCode: string;
          createdOn?: string | null;
          createdBy?: string | null;
          updatedOn?: string | null;
          modifiedBy?: string | null;
          modifiedOn?: string | null;
        } | null;
      } | null;
      assemblyId?: string | null;
      historyTypeId?: string | null;
      assemblyNodes?: Array<{
        __typename: 'AssemblyNode';
        quantity?: number | null;
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
        shopTaskId?: string | null;
        assemblyNodeId?: string | null;
        historyTypeId?: string | null;
        assembly?: {
          __typename: 'Assembly';
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          assemblyId?: string | null;
          historyTypeId?: string | null;
          assemblyNodes?: Array<{
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null> | null;
          assemblyEdges?: Array<{
            __typename: 'AssemblyEdge';
            assemblyEdgeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyEdgeType?: {
              __typename: 'AssemblyEdgeType';
              assemblyEdgeTypeId?: string | null;
              assemblyEdgeTypeName?: string | null;
            } | null;
            beginHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            endHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            beginNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
            endNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
          } | null> | null;
        } | null;
        assemblyNodeType?: {
          __typename: 'AssemblyNodeType';
          assemblyNodeTypeId?: string | null;
          assemblyNodeTypeName?: string | null;
        } | null;
        assemblyNodeName?: string | null;
        assemblyNodeDescription?: string | null;
        positionX?: number | null;
        positionY?: number | null;
      } | null> | null;
      assemblyEdges?: Array<{
        __typename: 'AssemblyEdge';
        assemblyEdgeId?: string | null;
        historyTypeId?: string | null;
        assembly?: {
          __typename: 'Assembly';
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          assemblyId?: string | null;
          historyTypeId?: string | null;
          assemblyNodes?: Array<{
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null> | null;
          assemblyEdges?: Array<{
            __typename: 'AssemblyEdge';
            assemblyEdgeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyEdgeType?: {
              __typename: 'AssemblyEdgeType';
              assemblyEdgeTypeId?: string | null;
              assemblyEdgeTypeName?: string | null;
            } | null;
            beginHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            endHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            beginNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
            endNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
          } | null> | null;
        } | null;
        assemblyEdgeType?: {
          __typename: 'AssemblyEdgeType';
          assemblyEdgeTypeId?: string | null;
          assemblyEdgeTypeName?: string | null;
        } | null;
        beginHandlePosition?: {
          __typename: 'AssemblyHandlePosition';
          assemblyHandlePositionId?: string | null;
          assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
        } | null;
        endHandlePosition?: {
          __typename: 'AssemblyHandlePosition';
          assemblyHandlePositionId?: string | null;
          assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
        } | null;
        beginNode?: {
          __typename: 'AssemblyNode';
          quantity?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          shopTaskId?: string | null;
          assemblyNodeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyNodeType?: {
            __typename: 'AssemblyNodeType';
            assemblyNodeTypeId?: string | null;
            assemblyNodeTypeName?: string | null;
          } | null;
          assemblyNodeName?: string | null;
          assemblyNodeDescription?: string | null;
          positionX?: number | null;
          positionY?: number | null;
        } | null;
        endNode?: {
          __typename: 'AssemblyNode';
          quantity?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          shopTaskId?: string | null;
          assemblyNodeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyNodeType?: {
            __typename: 'AssemblyNodeType';
            assemblyNodeTypeId?: string | null;
            assemblyNodeTypeName?: string | null;
          } | null;
          assemblyNodeName?: string | null;
          assemblyNodeDescription?: string | null;
          positionX?: number | null;
          positionY?: number | null;
        } | null;
      } | null> | null;
    } | null;
    assemblyEdgeType?: {
      __typename: 'AssemblyEdgeType';
      assemblyEdgeTypeId?: string | null;
      assemblyEdgeTypeName?: string | null;
    } | null;
    beginHandlePosition?: {
      __typename: 'AssemblyHandlePosition';
      assemblyHandlePositionId?: string | null;
      assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
    } | null;
    endHandlePosition?: {
      __typename: 'AssemblyHandlePosition';
      assemblyHandlePositionId?: string | null;
      assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
    } | null;
    beginNode?: {
      __typename: 'AssemblyNode';
      quantity?: number | null;
      part?: {
        __typename: 'Part';
        partId: string;
        partName: string;
        description?: string | null;
        stateId?: string | null;
        isDefault?: boolean | null;
        manufacturerCatalogNumber?: string | null;
        unitOfMeasureCode?: string | null;
        unitOfMeasureName?: string | null;
        upcCode?: string | null;
        url?: string | null;
        catalogUrl?: string | null;
        partUrl?: string | null;
        internalPartNumber?: string | null;
        createdOn?: string | null;
        hasAssembly?: boolean | null;
        manufacturer?: {
          __typename: 'Manufactured';
          manufacturerId: string;
          companyId?: string | null;
          manufacturerName: string;
          homePageUrl?: string | null;
        } | null;
        attriubuteVariantData?: Array<{
          __typename: 'PartAttributeVariantData';
          partAttributeVariantDataId?: string | null;
          partAttributeId?: string | null;
          partAttributeName?: string | null;
          rangeValue?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          partAttribute?: {
            __typename: 'PartAttribute';
            partAttributeId: string;
            partAttributeName: string;
            allowWriteInVariant?: boolean | null;
            rangeMinimumValue?: number | null;
            rangeMaximumValue?: number | null;
            selectVariants?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
            partAttributeType?: {
              __typename: 'PartAttributeType';
              partAttributeTypeId: string;
              partAttributeTypeName: string;
              partAttributeTypeDescription?: string | null;
              sortOrder?: number | null;
            } | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
          } | null;
          selectValues?: Array<{
            __typename: 'PartAttributeSelectVariant';
            partAttributeSelectVariantId?: string | null;
            textValue?: string | null;
            codeValue?: string | null;
            isDeleted?: boolean | null;
          } | null> | null;
        } | null> | null;
        partCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        partImages?: Array<{
          __typename: 'PartImage';
          partImageId?: string | null;
          sortOrder?: number | null;
          isDefault?: boolean | null;
          document?: {
            __typename: 'Document';
            documentId: string;
            documentName?: string | null;
            documentIdentifier?: string | null;
            documentTypeId?: string | null;
            storagePath?: string | null;
            extension?: string | null;
            encodedUrn?: string | null;
            documentStatusTypeId?: string | null;
            isMappedFromShop?: boolean | null;
            folderDocumentResponse?: Array<{
              __typename: 'FolderDocument';
              folderDocumentId: string;
              folderId: string;
              folderName?: string | null;
              documentId: string;
            } | null> | null;
            documentNameFormatted?: string | null;
            createdOn?: string | null;
            createdBy?: string | null;
          } | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
        } | null> | null;
        documentIds?: Array<string | null> | null;
        unitOfMeasure?: {
          __typename: 'UnitOfMeasure';
          unitOfMeasureId: string;
          unitOfMeasureTypeId?: string | null;
          companyId?: string | null;
          unitOfMeasureName: string;
          unitOfMeasureCode: string;
          createdOn?: string | null;
          createdBy?: string | null;
          updatedOn?: string | null;
          modifiedBy?: string | null;
          modifiedOn?: string | null;
        } | null;
      } | null;
      shopTaskId?: string | null;
      assemblyNodeId?: string | null;
      historyTypeId?: string | null;
      assembly?: {
        __typename: 'Assembly';
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
        assemblyId?: string | null;
        historyTypeId?: string | null;
        assemblyNodes?: Array<{
          __typename: 'AssemblyNode';
          quantity?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          shopTaskId?: string | null;
          assemblyNodeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyNodeType?: {
            __typename: 'AssemblyNodeType';
            assemblyNodeTypeId?: string | null;
            assemblyNodeTypeName?: string | null;
          } | null;
          assemblyNodeName?: string | null;
          assemblyNodeDescription?: string | null;
          positionX?: number | null;
          positionY?: number | null;
        } | null> | null;
        assemblyEdges?: Array<{
          __typename: 'AssemblyEdge';
          assemblyEdgeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyEdgeType?: {
            __typename: 'AssemblyEdgeType';
            assemblyEdgeTypeId?: string | null;
            assemblyEdgeTypeName?: string | null;
          } | null;
          beginHandlePosition?: {
            __typename: 'AssemblyHandlePosition';
            assemblyHandlePositionId?: string | null;
            assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
          } | null;
          endHandlePosition?: {
            __typename: 'AssemblyHandlePosition';
            assemblyHandlePositionId?: string | null;
            assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
          } | null;
          beginNode?: {
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null;
          endNode?: {
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null;
        } | null> | null;
      } | null;
      assemblyNodeType?: {
        __typename: 'AssemblyNodeType';
        assemblyNodeTypeId?: string | null;
        assemblyNodeTypeName?: string | null;
      } | null;
      assemblyNodeName?: string | null;
      assemblyNodeDescription?: string | null;
      positionX?: number | null;
      positionY?: number | null;
    } | null;
    endNode?: {
      __typename: 'AssemblyNode';
      quantity?: number | null;
      part?: {
        __typename: 'Part';
        partId: string;
        partName: string;
        description?: string | null;
        stateId?: string | null;
        isDefault?: boolean | null;
        manufacturerCatalogNumber?: string | null;
        unitOfMeasureCode?: string | null;
        unitOfMeasureName?: string | null;
        upcCode?: string | null;
        url?: string | null;
        catalogUrl?: string | null;
        partUrl?: string | null;
        internalPartNumber?: string | null;
        createdOn?: string | null;
        hasAssembly?: boolean | null;
        manufacturer?: {
          __typename: 'Manufactured';
          manufacturerId: string;
          companyId?: string | null;
          manufacturerName: string;
          homePageUrl?: string | null;
        } | null;
        attriubuteVariantData?: Array<{
          __typename: 'PartAttributeVariantData';
          partAttributeVariantDataId?: string | null;
          partAttributeId?: string | null;
          partAttributeName?: string | null;
          rangeValue?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          partAttribute?: {
            __typename: 'PartAttribute';
            partAttributeId: string;
            partAttributeName: string;
            allowWriteInVariant?: boolean | null;
            rangeMinimumValue?: number | null;
            rangeMaximumValue?: number | null;
            selectVariants?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
            partAttributeType?: {
              __typename: 'PartAttributeType';
              partAttributeTypeId: string;
              partAttributeTypeName: string;
              partAttributeTypeDescription?: string | null;
              sortOrder?: number | null;
            } | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
          } | null;
          selectValues?: Array<{
            __typename: 'PartAttributeSelectVariant';
            partAttributeSelectVariantId?: string | null;
            textValue?: string | null;
            codeValue?: string | null;
            isDeleted?: boolean | null;
          } | null> | null;
        } | null> | null;
        partCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        partImages?: Array<{
          __typename: 'PartImage';
          partImageId?: string | null;
          sortOrder?: number | null;
          isDefault?: boolean | null;
          document?: {
            __typename: 'Document';
            documentId: string;
            documentName?: string | null;
            documentIdentifier?: string | null;
            documentTypeId?: string | null;
            storagePath?: string | null;
            extension?: string | null;
            encodedUrn?: string | null;
            documentStatusTypeId?: string | null;
            isMappedFromShop?: boolean | null;
            folderDocumentResponse?: Array<{
              __typename: 'FolderDocument';
              folderDocumentId: string;
              folderId: string;
              folderName?: string | null;
              documentId: string;
            } | null> | null;
            documentNameFormatted?: string | null;
            createdOn?: string | null;
            createdBy?: string | null;
          } | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
        } | null> | null;
        documentIds?: Array<string | null> | null;
        unitOfMeasure?: {
          __typename: 'UnitOfMeasure';
          unitOfMeasureId: string;
          unitOfMeasureTypeId?: string | null;
          companyId?: string | null;
          unitOfMeasureName: string;
          unitOfMeasureCode: string;
          createdOn?: string | null;
          createdBy?: string | null;
          updatedOn?: string | null;
          modifiedBy?: string | null;
          modifiedOn?: string | null;
        } | null;
      } | null;
      shopTaskId?: string | null;
      assemblyNodeId?: string | null;
      historyTypeId?: string | null;
      assembly?: {
        __typename: 'Assembly';
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
        assemblyId?: string | null;
        historyTypeId?: string | null;
        assemblyNodes?: Array<{
          __typename: 'AssemblyNode';
          quantity?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          shopTaskId?: string | null;
          assemblyNodeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyNodeType?: {
            __typename: 'AssemblyNodeType';
            assemblyNodeTypeId?: string | null;
            assemblyNodeTypeName?: string | null;
          } | null;
          assemblyNodeName?: string | null;
          assemblyNodeDescription?: string | null;
          positionX?: number | null;
          positionY?: number | null;
        } | null> | null;
        assemblyEdges?: Array<{
          __typename: 'AssemblyEdge';
          assemblyEdgeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyEdgeType?: {
            __typename: 'AssemblyEdgeType';
            assemblyEdgeTypeId?: string | null;
            assemblyEdgeTypeName?: string | null;
          } | null;
          beginHandlePosition?: {
            __typename: 'AssemblyHandlePosition';
            assemblyHandlePositionId?: string | null;
            assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
          } | null;
          endHandlePosition?: {
            __typename: 'AssemblyHandlePosition';
            assemblyHandlePositionId?: string | null;
            assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
          } | null;
          beginNode?: {
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null;
          endNode?: {
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null;
        } | null> | null;
      } | null;
      assemblyNodeType?: {
        __typename: 'AssemblyNodeType';
        assemblyNodeTypeId?: string | null;
        assemblyNodeTypeName?: string | null;
      } | null;
      assemblyNodeName?: string | null;
      assemblyNodeDescription?: string | null;
      positionX?: number | null;
      positionY?: number | null;
    } | null;
  } | null;
};

export type UpdateAssemblyEdgeMutationVariables = {
  params: MutationUpdateAssemblyEdgeParamsInput;
  query?: MutationUpdateAssemblyEdgeQueryInput | null;
  body?: MutationUpdateAssemblyEdgeBodyInput | null;
};

export type UpdateAssemblyEdgeMutation = {
  updateAssemblyEdge?: {
    __typename: 'AssemblyEdge';
    assemblyEdgeId?: string | null;
    historyTypeId?: string | null;
    assembly?: {
      __typename: 'Assembly';
      part?: {
        __typename: 'Part';
        partId: string;
        partName: string;
        description?: string | null;
        stateId?: string | null;
        isDefault?: boolean | null;
        manufacturerCatalogNumber?: string | null;
        unitOfMeasureCode?: string | null;
        unitOfMeasureName?: string | null;
        upcCode?: string | null;
        url?: string | null;
        catalogUrl?: string | null;
        partUrl?: string | null;
        internalPartNumber?: string | null;
        createdOn?: string | null;
        hasAssembly?: boolean | null;
        manufacturer?: {
          __typename: 'Manufactured';
          manufacturerId: string;
          companyId?: string | null;
          manufacturerName: string;
          homePageUrl?: string | null;
        } | null;
        attriubuteVariantData?: Array<{
          __typename: 'PartAttributeVariantData';
          partAttributeVariantDataId?: string | null;
          partAttributeId?: string | null;
          partAttributeName?: string | null;
          rangeValue?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          partAttribute?: {
            __typename: 'PartAttribute';
            partAttributeId: string;
            partAttributeName: string;
            allowWriteInVariant?: boolean | null;
            rangeMinimumValue?: number | null;
            rangeMaximumValue?: number | null;
            selectVariants?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
            partAttributeType?: {
              __typename: 'PartAttributeType';
              partAttributeTypeId: string;
              partAttributeTypeName: string;
              partAttributeTypeDescription?: string | null;
              sortOrder?: number | null;
            } | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
          } | null;
          selectValues?: Array<{
            __typename: 'PartAttributeSelectVariant';
            partAttributeSelectVariantId?: string | null;
            textValue?: string | null;
            codeValue?: string | null;
            isDeleted?: boolean | null;
          } | null> | null;
        } | null> | null;
        partCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        partImages?: Array<{
          __typename: 'PartImage';
          partImageId?: string | null;
          sortOrder?: number | null;
          isDefault?: boolean | null;
          document?: {
            __typename: 'Document';
            documentId: string;
            documentName?: string | null;
            documentIdentifier?: string | null;
            documentTypeId?: string | null;
            storagePath?: string | null;
            extension?: string | null;
            encodedUrn?: string | null;
            documentStatusTypeId?: string | null;
            isMappedFromShop?: boolean | null;
            folderDocumentResponse?: Array<{
              __typename: 'FolderDocument';
              folderDocumentId: string;
              folderId: string;
              folderName?: string | null;
              documentId: string;
            } | null> | null;
            documentNameFormatted?: string | null;
            createdOn?: string | null;
            createdBy?: string | null;
          } | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
        } | null> | null;
        documentIds?: Array<string | null> | null;
        unitOfMeasure?: {
          __typename: 'UnitOfMeasure';
          unitOfMeasureId: string;
          unitOfMeasureTypeId?: string | null;
          companyId?: string | null;
          unitOfMeasureName: string;
          unitOfMeasureCode: string;
          createdOn?: string | null;
          createdBy?: string | null;
          updatedOn?: string | null;
          modifiedBy?: string | null;
          modifiedOn?: string | null;
        } | null;
      } | null;
      assemblyId?: string | null;
      historyTypeId?: string | null;
      assemblyNodes?: Array<{
        __typename: 'AssemblyNode';
        quantity?: number | null;
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
        shopTaskId?: string | null;
        assemblyNodeId?: string | null;
        historyTypeId?: string | null;
        assembly?: {
          __typename: 'Assembly';
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          assemblyId?: string | null;
          historyTypeId?: string | null;
          assemblyNodes?: Array<{
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null> | null;
          assemblyEdges?: Array<{
            __typename: 'AssemblyEdge';
            assemblyEdgeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyEdgeType?: {
              __typename: 'AssemblyEdgeType';
              assemblyEdgeTypeId?: string | null;
              assemblyEdgeTypeName?: string | null;
            } | null;
            beginHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            endHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            beginNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
            endNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
          } | null> | null;
        } | null;
        assemblyNodeType?: {
          __typename: 'AssemblyNodeType';
          assemblyNodeTypeId?: string | null;
          assemblyNodeTypeName?: string | null;
        } | null;
        assemblyNodeName?: string | null;
        assemblyNodeDescription?: string | null;
        positionX?: number | null;
        positionY?: number | null;
      } | null> | null;
      assemblyEdges?: Array<{
        __typename: 'AssemblyEdge';
        assemblyEdgeId?: string | null;
        historyTypeId?: string | null;
        assembly?: {
          __typename: 'Assembly';
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          assemblyId?: string | null;
          historyTypeId?: string | null;
          assemblyNodes?: Array<{
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null> | null;
          assemblyEdges?: Array<{
            __typename: 'AssemblyEdge';
            assemblyEdgeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyEdgeType?: {
              __typename: 'AssemblyEdgeType';
              assemblyEdgeTypeId?: string | null;
              assemblyEdgeTypeName?: string | null;
            } | null;
            beginHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            endHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            beginNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
            endNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
          } | null> | null;
        } | null;
        assemblyEdgeType?: {
          __typename: 'AssemblyEdgeType';
          assemblyEdgeTypeId?: string | null;
          assemblyEdgeTypeName?: string | null;
        } | null;
        beginHandlePosition?: {
          __typename: 'AssemblyHandlePosition';
          assemblyHandlePositionId?: string | null;
          assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
        } | null;
        endHandlePosition?: {
          __typename: 'AssemblyHandlePosition';
          assemblyHandlePositionId?: string | null;
          assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
        } | null;
        beginNode?: {
          __typename: 'AssemblyNode';
          quantity?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          shopTaskId?: string | null;
          assemblyNodeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyNodeType?: {
            __typename: 'AssemblyNodeType';
            assemblyNodeTypeId?: string | null;
            assemblyNodeTypeName?: string | null;
          } | null;
          assemblyNodeName?: string | null;
          assemblyNodeDescription?: string | null;
          positionX?: number | null;
          positionY?: number | null;
        } | null;
        endNode?: {
          __typename: 'AssemblyNode';
          quantity?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          shopTaskId?: string | null;
          assemblyNodeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyNodeType?: {
            __typename: 'AssemblyNodeType';
            assemblyNodeTypeId?: string | null;
            assemblyNodeTypeName?: string | null;
          } | null;
          assemblyNodeName?: string | null;
          assemblyNodeDescription?: string | null;
          positionX?: number | null;
          positionY?: number | null;
        } | null;
      } | null> | null;
    } | null;
    assemblyEdgeType?: {
      __typename: 'AssemblyEdgeType';
      assemblyEdgeTypeId?: string | null;
      assemblyEdgeTypeName?: string | null;
    } | null;
    beginHandlePosition?: {
      __typename: 'AssemblyHandlePosition';
      assemblyHandlePositionId?: string | null;
      assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
    } | null;
    endHandlePosition?: {
      __typename: 'AssemblyHandlePosition';
      assemblyHandlePositionId?: string | null;
      assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
    } | null;
    beginNode?: {
      __typename: 'AssemblyNode';
      quantity?: number | null;
      part?: {
        __typename: 'Part';
        partId: string;
        partName: string;
        description?: string | null;
        stateId?: string | null;
        isDefault?: boolean | null;
        manufacturerCatalogNumber?: string | null;
        unitOfMeasureCode?: string | null;
        unitOfMeasureName?: string | null;
        upcCode?: string | null;
        url?: string | null;
        catalogUrl?: string | null;
        partUrl?: string | null;
        internalPartNumber?: string | null;
        createdOn?: string | null;
        hasAssembly?: boolean | null;
        manufacturer?: {
          __typename: 'Manufactured';
          manufacturerId: string;
          companyId?: string | null;
          manufacturerName: string;
          homePageUrl?: string | null;
        } | null;
        attriubuteVariantData?: Array<{
          __typename: 'PartAttributeVariantData';
          partAttributeVariantDataId?: string | null;
          partAttributeId?: string | null;
          partAttributeName?: string | null;
          rangeValue?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          partAttribute?: {
            __typename: 'PartAttribute';
            partAttributeId: string;
            partAttributeName: string;
            allowWriteInVariant?: boolean | null;
            rangeMinimumValue?: number | null;
            rangeMaximumValue?: number | null;
            selectVariants?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
            partAttributeType?: {
              __typename: 'PartAttributeType';
              partAttributeTypeId: string;
              partAttributeTypeName: string;
              partAttributeTypeDescription?: string | null;
              sortOrder?: number | null;
            } | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
          } | null;
          selectValues?: Array<{
            __typename: 'PartAttributeSelectVariant';
            partAttributeSelectVariantId?: string | null;
            textValue?: string | null;
            codeValue?: string | null;
            isDeleted?: boolean | null;
          } | null> | null;
        } | null> | null;
        partCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        partImages?: Array<{
          __typename: 'PartImage';
          partImageId?: string | null;
          sortOrder?: number | null;
          isDefault?: boolean | null;
          document?: {
            __typename: 'Document';
            documentId: string;
            documentName?: string | null;
            documentIdentifier?: string | null;
            documentTypeId?: string | null;
            storagePath?: string | null;
            extension?: string | null;
            encodedUrn?: string | null;
            documentStatusTypeId?: string | null;
            isMappedFromShop?: boolean | null;
            folderDocumentResponse?: Array<{
              __typename: 'FolderDocument';
              folderDocumentId: string;
              folderId: string;
              folderName?: string | null;
              documentId: string;
            } | null> | null;
            documentNameFormatted?: string | null;
            createdOn?: string | null;
            createdBy?: string | null;
          } | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
        } | null> | null;
        documentIds?: Array<string | null> | null;
        unitOfMeasure?: {
          __typename: 'UnitOfMeasure';
          unitOfMeasureId: string;
          unitOfMeasureTypeId?: string | null;
          companyId?: string | null;
          unitOfMeasureName: string;
          unitOfMeasureCode: string;
          createdOn?: string | null;
          createdBy?: string | null;
          updatedOn?: string | null;
          modifiedBy?: string | null;
          modifiedOn?: string | null;
        } | null;
      } | null;
      shopTaskId?: string | null;
      assemblyNodeId?: string | null;
      historyTypeId?: string | null;
      assembly?: {
        __typename: 'Assembly';
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
        assemblyId?: string | null;
        historyTypeId?: string | null;
        assemblyNodes?: Array<{
          __typename: 'AssemblyNode';
          quantity?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          shopTaskId?: string | null;
          assemblyNodeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyNodeType?: {
            __typename: 'AssemblyNodeType';
            assemblyNodeTypeId?: string | null;
            assemblyNodeTypeName?: string | null;
          } | null;
          assemblyNodeName?: string | null;
          assemblyNodeDescription?: string | null;
          positionX?: number | null;
          positionY?: number | null;
        } | null> | null;
        assemblyEdges?: Array<{
          __typename: 'AssemblyEdge';
          assemblyEdgeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyEdgeType?: {
            __typename: 'AssemblyEdgeType';
            assemblyEdgeTypeId?: string | null;
            assemblyEdgeTypeName?: string | null;
          } | null;
          beginHandlePosition?: {
            __typename: 'AssemblyHandlePosition';
            assemblyHandlePositionId?: string | null;
            assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
          } | null;
          endHandlePosition?: {
            __typename: 'AssemblyHandlePosition';
            assemblyHandlePositionId?: string | null;
            assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
          } | null;
          beginNode?: {
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null;
          endNode?: {
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null;
        } | null> | null;
      } | null;
      assemblyNodeType?: {
        __typename: 'AssemblyNodeType';
        assemblyNodeTypeId?: string | null;
        assemblyNodeTypeName?: string | null;
      } | null;
      assemblyNodeName?: string | null;
      assemblyNodeDescription?: string | null;
      positionX?: number | null;
      positionY?: number | null;
    } | null;
    endNode?: {
      __typename: 'AssemblyNode';
      quantity?: number | null;
      part?: {
        __typename: 'Part';
        partId: string;
        partName: string;
        description?: string | null;
        stateId?: string | null;
        isDefault?: boolean | null;
        manufacturerCatalogNumber?: string | null;
        unitOfMeasureCode?: string | null;
        unitOfMeasureName?: string | null;
        upcCode?: string | null;
        url?: string | null;
        catalogUrl?: string | null;
        partUrl?: string | null;
        internalPartNumber?: string | null;
        createdOn?: string | null;
        hasAssembly?: boolean | null;
        manufacturer?: {
          __typename: 'Manufactured';
          manufacturerId: string;
          companyId?: string | null;
          manufacturerName: string;
          homePageUrl?: string | null;
        } | null;
        attriubuteVariantData?: Array<{
          __typename: 'PartAttributeVariantData';
          partAttributeVariantDataId?: string | null;
          partAttributeId?: string | null;
          partAttributeName?: string | null;
          rangeValue?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          partAttribute?: {
            __typename: 'PartAttribute';
            partAttributeId: string;
            partAttributeName: string;
            allowWriteInVariant?: boolean | null;
            rangeMinimumValue?: number | null;
            rangeMaximumValue?: number | null;
            selectVariants?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
            partAttributeType?: {
              __typename: 'PartAttributeType';
              partAttributeTypeId: string;
              partAttributeTypeName: string;
              partAttributeTypeDescription?: string | null;
              sortOrder?: number | null;
            } | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
          } | null;
          selectValues?: Array<{
            __typename: 'PartAttributeSelectVariant';
            partAttributeSelectVariantId?: string | null;
            textValue?: string | null;
            codeValue?: string | null;
            isDeleted?: boolean | null;
          } | null> | null;
        } | null> | null;
        partCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        partImages?: Array<{
          __typename: 'PartImage';
          partImageId?: string | null;
          sortOrder?: number | null;
          isDefault?: boolean | null;
          document?: {
            __typename: 'Document';
            documentId: string;
            documentName?: string | null;
            documentIdentifier?: string | null;
            documentTypeId?: string | null;
            storagePath?: string | null;
            extension?: string | null;
            encodedUrn?: string | null;
            documentStatusTypeId?: string | null;
            isMappedFromShop?: boolean | null;
            folderDocumentResponse?: Array<{
              __typename: 'FolderDocument';
              folderDocumentId: string;
              folderId: string;
              folderName?: string | null;
              documentId: string;
            } | null> | null;
            documentNameFormatted?: string | null;
            createdOn?: string | null;
            createdBy?: string | null;
          } | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
        } | null> | null;
        documentIds?: Array<string | null> | null;
        unitOfMeasure?: {
          __typename: 'UnitOfMeasure';
          unitOfMeasureId: string;
          unitOfMeasureTypeId?: string | null;
          companyId?: string | null;
          unitOfMeasureName: string;
          unitOfMeasureCode: string;
          createdOn?: string | null;
          createdBy?: string | null;
          updatedOn?: string | null;
          modifiedBy?: string | null;
          modifiedOn?: string | null;
        } | null;
      } | null;
      shopTaskId?: string | null;
      assemblyNodeId?: string | null;
      historyTypeId?: string | null;
      assembly?: {
        __typename: 'Assembly';
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
        assemblyId?: string | null;
        historyTypeId?: string | null;
        assemblyNodes?: Array<{
          __typename: 'AssemblyNode';
          quantity?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          shopTaskId?: string | null;
          assemblyNodeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyNodeType?: {
            __typename: 'AssemblyNodeType';
            assemblyNodeTypeId?: string | null;
            assemblyNodeTypeName?: string | null;
          } | null;
          assemblyNodeName?: string | null;
          assemblyNodeDescription?: string | null;
          positionX?: number | null;
          positionY?: number | null;
        } | null> | null;
        assemblyEdges?: Array<{
          __typename: 'AssemblyEdge';
          assemblyEdgeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyEdgeType?: {
            __typename: 'AssemblyEdgeType';
            assemblyEdgeTypeId?: string | null;
            assemblyEdgeTypeName?: string | null;
          } | null;
          beginHandlePosition?: {
            __typename: 'AssemblyHandlePosition';
            assemblyHandlePositionId?: string | null;
            assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
          } | null;
          endHandlePosition?: {
            __typename: 'AssemblyHandlePosition';
            assemblyHandlePositionId?: string | null;
            assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
          } | null;
          beginNode?: {
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null;
          endNode?: {
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null;
        } | null> | null;
      } | null;
      assemblyNodeType?: {
        __typename: 'AssemblyNodeType';
        assemblyNodeTypeId?: string | null;
        assemblyNodeTypeName?: string | null;
      } | null;
      assemblyNodeName?: string | null;
      assemblyNodeDescription?: string | null;
      positionX?: number | null;
      positionY?: number | null;
    } | null;
  } | null;
};

export type DeleteAssemblyEdgeMutationVariables = {
  params: MutationDeleteAssemblyEdgeParamsInput;
};

export type DeleteAssemblyEdgeMutation = {
  deleteAssemblyEdge?: boolean | null;
};

export type AddLocationMutationVariables = {
  query?: MutationAddLocationQueryInput | null;
  body?: MutationAddLocationBodyInput | null;
};

export type AddLocationMutation = {
  addLocation?: {
    __typename: 'Location';
    locationId?: string | null;
    locationTypeId?: string | null;
    locationTypeName?: string | null;
    projectId?: string | null;
    locationIdentifier?: string | null;
    locationCompositeIdentifier?: string | null;
    sortOrder?: number | null;
    hasChildren?: boolean | null;
    parentLocationId?: string | null;
    locationName?: string | null;
    locationCompositeName?: string | null;
    locationPhases?: Array<{
      __typename: 'LocationPhase';
      locationPhaseId: string;
      locationId?: string | null;
      workPhaseId: string;
      workPhaseName: string;
      scopePackageId: string;
      scopePackageName: string;
      sortOrder: number;
    } | null> | null;
    childrenTypeName?: string | null;
    movedLocationPhases?: Array<{
      __typename: 'UpdatedLocationPhase';
      workPhaseId: string;
      locationPhaseId: string;
    } | null> | null;
  } | null;
};

export type AddScopePackageMutationVariables = {
  query?: MutationAddScopePackageQueryInput | null;
  body?: MutationAddScopePackageBodyInput | null;
};

export type AddScopePackageMutation = {
  addScopePackage?: {
    __typename: 'ScopePackage';
    projectId: string;
    scopePackageId: string;
    scopePackageName: string;
    scopePackageIdentifier: string;
    sortOrder: number;
    hasWorkPhases?: boolean | null;
  } | null;
};

export type AddWorkPhaseMutationVariables = {
  query?: MutationAddWorkPhaseQueryInput | null;
  body?: MutationAddWorkPhaseBodyInput | null;
};

export type AddWorkPhaseMutation = {
  addWorkPhase?: {
    __typename: 'WorkPhase';
    workPhaseId: string;
    workPhaseIdentifier: string;
    workPhaseName: string;
    sortOrder: number;
    scopePackageId: string;
    scopePackageName?: string | null;
    projectId: string;
    hasLocationPhases?: boolean | null;
    updatedWorkPhaseLocationPhases?: Array<{
      __typename: 'MappedLocation';
      locationId: string;
      locationPhaseId: string;
    } | null> | null;
  } | null;
};

export type AddPartAttributeMutationVariables = {
  query?: MutationAddPartAttributeQueryInput | null;
  body?: MutationAddPartAttributeBodyInput | null;
};

export type AddPartAttributeMutation = {
  addPartAttribute?: {
    __typename: 'PartAttribute';
    partAttributeId: string;
    partAttributeName: string;
    allowWriteInVariant?: boolean | null;
    rangeMinimumValue?: number | null;
    rangeMaximumValue?: number | null;
    selectVariants?: Array<{
      __typename: 'PartAttributeSelectVariant';
      partAttributeSelectVariantId?: string | null;
      textValue?: string | null;
      codeValue?: string | null;
      isDeleted?: boolean | null;
    } | null> | null;
    partAttributeType?: {
      __typename: 'PartAttributeType';
      partAttributeTypeId: string;
      partAttributeTypeName: string;
      partAttributeTypeDescription?: string | null;
      sortOrder?: number | null;
    } | null;
    partCategory?: {
      __typename: 'PartCategory';
      partCategoryId?: string | null;
      partCategoryName?: string | null;
      partCatalog?: {
        __typename: 'PartCatalog';
        partCatalogId: string;
        partCatalogName: string;
        companyId: string;
        topLevelNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      parentPartCategory?: {
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      subNodes?: Array<{
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type UpdateMessageMutationVariables = {
  params: MutationUpdateMessageParamsInput;
  query?: MutationUpdateMessageQueryInput | null;
  body?: MutationUpdateMessageBodyInput | null;
};

export type UpdateMessageMutation = {
  updateMessage?: {
    __typename: 'Message';
    messageId?: string | null;
    companyId?: string | null;
    messagePriorityId?: string | null;
    fromName?: string | null;
    subject?: string | null;
    body?: string | null;
    messagePriorityCode?: MESSAGE_PRIORITY_CODE | null;
    statusId?: string | null;
    statusName?: string | null;
    accessedOn?: string | null;
    createdOn?: string | null;
  } | null;
};

export type UpdatePartAttributeMutationVariables = {
  query?: MutationUpdatePartAttributeQueryInput | null;
  body?: MutationUpdatePartAttributeBodyInput | null;
};

export type UpdatePartAttributeMutation = {
  updatePartAttribute?: {
    __typename: 'PartAttribute';
    partAttributeId: string;
    partAttributeName: string;
    allowWriteInVariant?: boolean | null;
    rangeMinimumValue?: number | null;
    rangeMaximumValue?: number | null;
    selectVariants?: Array<{
      __typename: 'PartAttributeSelectVariant';
      partAttributeSelectVariantId?: string | null;
      textValue?: string | null;
      codeValue?: string | null;
      isDeleted?: boolean | null;
    } | null> | null;
    partAttributeType?: {
      __typename: 'PartAttributeType';
      partAttributeTypeId: string;
      partAttributeTypeName: string;
      partAttributeTypeDescription?: string | null;
      sortOrder?: number | null;
    } | null;
    partCategory?: {
      __typename: 'PartCategory';
      partCategoryId?: string | null;
      partCategoryName?: string | null;
      partCatalog?: {
        __typename: 'PartCatalog';
        partCatalogId: string;
        partCatalogName: string;
        companyId: string;
        topLevelNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      parentPartCategory?: {
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      subNodes?: Array<{
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type DeletePartAttributeMutationVariables = {
  params: MutationDeletePartAttributeParamsInput;
};

export type DeletePartAttributeMutation = {
  deletePartAttribute?: boolean | null;
};

export type AddLocationWizardMutationVariables = {
  query?: MutationAddLocationWizardQueryInput | null;
  body?: MutationAddLocationWizardBodyInput | null;
};

export type AddLocationWizardMutation = {
  addLocationWizard?: Array<{
    __typename: 'Location';
    locationId?: string | null;
    locationTypeId?: string | null;
    locationTypeName?: string | null;
    projectId?: string | null;
    locationIdentifier?: string | null;
    locationCompositeIdentifier?: string | null;
    sortOrder?: number | null;
    hasChildren?: boolean | null;
    parentLocationId?: string | null;
    locationName?: string | null;
    locationCompositeName?: string | null;
    locationPhases?: Array<{
      __typename: 'LocationPhase';
      locationPhaseId: string;
      locationId?: string | null;
      workPhaseId: string;
      workPhaseName: string;
      scopePackageId: string;
      scopePackageName: string;
      sortOrder: number;
    } | null> | null;
    childrenTypeName?: string | null;
    movedLocationPhases?: Array<{
      __typename: 'UpdatedLocationPhase';
      workPhaseId: string;
      locationPhaseId: string;
    } | null> | null;
  } | null> | null;
};

export type AddWorkRequestMutationVariables = {
  query?: MutationAddWorkRequestQueryInput | null;
  body?: MutationAddWorkRequestBodyInput | null;
};

export type AddWorkRequestMutation = {
  addWorkRequest?: {
    __typename: 'WorkRequest';
    workRequestId?: string | null;
    workRequestStatusId?: string | null;
    workRequestStatusName?: string | null;
    facilityId?: string | null;
    projectId?: string | null;
    projectIdentifier?: string | null;
    projectName?: string | null;
    workRequestIdentifier?: string | null;
    workRequestName?: string | null;
    needBy?: string | null;
    workRequestDescription?: string | null;
    facilityName?: string | null;
    workRequestStatusDescription?: string | null;
    lastStatusUpdate?: string | null;
    numberOfAttachments?: number | null;
  } | null;
};

export type AddWorkRequestItemMutationVariables = {
  query?: MutationAddWorkRequestItemQueryInput | null;
  body?: MutationAddWorkRequestItemBodyInput | null;
};

export type AddWorkRequestItemMutation = {
  addWorkRequestItem?: {
    __typename: 'WorkRequestItem';
    workRequestItemId?: string | null;
    workRequestId?: string | null;
    workRequestItemTypeId?: string | null;
    unitOfMeasureId?: string | null;
    billOfProcessId?: string | null;
    workRequestItemName?: string | null;
    workRequestItemStatusTypeId?: string | null;
    quantity?: number | null;
    unityQuantity?: number | null;
    assignedQuantity?: number | null;
    remainingQuantity?: number | null;
    hasShopTask?: boolean | null;
    createdOn?: string | null;
    createdBy?: string | null;
    lineItemId?: string | null;
  } | null;
};

export type AddWorkRequestItemFromCatalogMutationVariables = {
  query?: MutationAddWorkRequestItemFromCatalogQueryInput | null;
  body?: MutationAddWorkRequestItemFromCatalogBodyInput | null;
};

export type AddWorkRequestItemFromCatalogMutation = {
  addWorkRequestItemFromCatalog?: {
    __typename: 'WorkRequestItem';
    workRequestItemId?: string | null;
    workRequestId?: string | null;
    workRequestItemTypeId?: string | null;
    unitOfMeasureId?: string | null;
    billOfProcessId?: string | null;
    workRequestItemName?: string | null;
    workRequestItemStatusTypeId?: string | null;
    quantity?: number | null;
    unityQuantity?: number | null;
    assignedQuantity?: number | null;
    remainingQuantity?: number | null;
    hasShopTask?: boolean | null;
    createdOn?: string | null;
    createdBy?: string | null;
    lineItemId?: string | null;
  } | null;
};

export type AddWorkRequestItemFromLineItemMutationVariables = {
  query?: MutationAddWorkRequestItemFromLineItemQueryInput | null;
  body?: MutationAddWorkRequestItemFromLineItemBodyInput | null;
};

export type AddWorkRequestItemFromLineItemMutation = {
  addWorkRequestItemFromLineItem?: Array<{
    __typename: 'WorkRequestItem';
    workRequestItemId?: string | null;
    workRequestId?: string | null;
    workRequestItemTypeId?: string | null;
    unitOfMeasureId?: string | null;
    billOfProcessId?: string | null;
    workRequestItemName?: string | null;
    workRequestItemStatusTypeId?: string | null;
    quantity?: number | null;
    unityQuantity?: number | null;
    assignedQuantity?: number | null;
    remainingQuantity?: number | null;
    hasShopTask?: boolean | null;
    createdOn?: string | null;
    createdBy?: string | null;
    lineItemId?: string | null;
  } | null> | null;
};

export type AddMessageMutationVariables = {
  query?: MutationAddMessageQueryInput | null;
  body?: MutationAddMessageBodyInput | null;
};

export type AddMessageMutation = {
  addMessage?: {
    __typename: 'Message';
    messageId?: string | null;
    companyId?: string | null;
    messagePriorityId?: string | null;
    fromName?: string | null;
    subject?: string | null;
    body?: string | null;
    messagePriorityCode?: MESSAGE_PRIORITY_CODE | null;
    statusId?: string | null;
    statusName?: string | null;
    accessedOn?: string | null;
    createdOn?: string | null;
  } | null;
};

export type AddEntitlementMutationVariables = {
  query?: MutationAddEntitlementQueryInput | null;
  body?: MutationAddEntitlementBodyInput | null;
};

export type AddEntitlementMutation = {
  addEntitlement?: Array<{
    __typename: 'Entitlement';
    entitlementId: string;
    userId: string;
    isCheckedOut?: boolean | null;
    checkedOutEndDate?: string | null;
    secureData?: string | null;
    secureSignature?: string | null;
    licenseTypeCode?: LICENSE_CODE | null;
    productPool?: {
      __typename: 'ProductPool';
      productPoolId: string;
      poolQuantity?: number | null;
      productId?: string | null;
      bundleId?: string | null;
      type?: string | null;
      label?: string | null;
      poolAvailableQuantity?: number | null;
      renewalDate?: string | null;
      customData?: string | null;
      termDate?: string | null;
      startDate?: string | null;
      trialRenewalDate?: string | null;
      company?: {
        __typename: 'Company';
        companyId: string;
        companyName?: string | null;
        renewalDate?: string | null;
        mfA_Required?: boolean | null;
        data_Analytics?: boolean | null;
        companyDownloadPaths?: Array<{
          __typename: 'CompanyDownloadPath';
          document?: string | null;
          updateBy?: string | null;
          updatedOn?: string | null;
        } | null> | null;
        companyImageId?: string | null;
        address?: {
          __typename: 'Address';
          addressId?: string | null;
          addressLine1?: string | null;
          addressLine2?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
          zip?: string | null;
        } | null;
        productPool?: Array<{
          __typename: 'ProductPool';
          productPoolId: string;
          poolQuantity?: number | null;
          productId?: string | null;
          bundleId?: string | null;
          type?: string | null;
          label?: string | null;
          poolAvailableQuantity?: number | null;
          renewalDate?: string | null;
          customData?: string | null;
          termDate?: string | null;
          startDate?: string | null;
          trialRenewalDate?: string | null;
          company?: {
            __typename: 'Company';
            companyId: string;
            companyName?: string | null;
            renewalDate?: string | null;
            mfA_Required?: boolean | null;
            data_Analytics?: boolean | null;
            companyDownloadPaths?: Array<{
              __typename: 'CompanyDownloadPath';
              document?: string | null;
              updateBy?: string | null;
              updatedOn?: string | null;
            } | null> | null;
            companyImageId?: string | null;
            address?: {
              __typename: 'Address';
              addressId?: string | null;
              addressLine1?: string | null;
              addressLine2?: string | null;
              city?: string | null;
              state?: string | null;
              country?: string | null;
              zip?: string | null;
            } | null;
            productPool?: Array<{
              __typename: 'ProductPool';
              productPoolId: string;
              poolQuantity?: number | null;
              productId?: string | null;
              bundleId?: string | null;
              type?: string | null;
              label?: string | null;
              poolAvailableQuantity?: number | null;
              renewalDate?: string | null;
              customData?: string | null;
              termDate?: string | null;
              startDate?: string | null;
              trialRenewalDate?: string | null;
            } | null> | null;
          } | null;
          product?: {
            __typename: 'Product';
            productId: string;
            productName?: string | null;
            baseProductName?: string | null;
            isForesiteModule?: boolean | null;
            licenseTypeId?: string | null;
            licenseTypeName?: string | null;
            licenseClassId?: string | null;
            licenseClassName?: string | null;
            productNameFormatted?: string | null;
            onlyVisibleToMembers?: boolean | null;
            supportsTrial?: boolean | null;
            productLicenseTypes?: Array<{
              __typename: 'ProductLicenseType';
              productLicenseTypeId: string;
              productId: string;
              licenseTypeId: string;
            } | null> | null;
          } | null;
        } | null> | null;
      } | null;
      product?: {
        __typename: 'Product';
        productId: string;
        productName?: string | null;
        baseProductName?: string | null;
        isForesiteModule?: boolean | null;
        licenseTypeId?: string | null;
        licenseTypeName?: string | null;
        licenseClassId?: string | null;
        licenseClassName?: string | null;
        productNameFormatted?: string | null;
        onlyVisibleToMembers?: boolean | null;
        supportsTrial?: boolean | null;
        productLicenseTypes?: Array<{
          __typename: 'ProductLicenseType';
          productLicenseTypeId: string;
          productId: string;
          licenseTypeId: string;
          licenseType?: {
            __typename: 'LicenseType';
            licenseTypeId: string;
            licenseTypeName: string;
            licenseTypeCode?: LICENSE_CODE | null;
            sortOrder?: number | null;
          } | null;
        } | null> | null;
      } | null;
    } | null;
  } | null> | null;
};

export type AddBundleMutationVariables = {
  query?: MutationAddBundleQueryInput | null;
  body?: MutationAddBundleBodyInput | null;
};

export type AddBundleMutation = {
  addBundle?: {
    __typename: 'Bundle';
    bundleId: string;
    bundleName: string;
    bundleProducts?: Array<{
      __typename: 'BundleProduct';
      bundleProductId: string;
      productId: string;
      product?: {
        __typename: 'Product';
        productId: string;
        productName?: string | null;
        baseProductName?: string | null;
        isForesiteModule?: boolean | null;
        licenseTypeId?: string | null;
        licenseTypeName?: string | null;
        licenseClassId?: string | null;
        licenseClassName?: string | null;
        productNameFormatted?: string | null;
        onlyVisibleToMembers?: boolean | null;
        supportsTrial?: boolean | null;
        productLicenseTypes?: Array<{
          __typename: 'ProductLicenseType';
          productLicenseTypeId: string;
          productId: string;
          licenseTypeId: string;
          licenseType?: {
            __typename: 'LicenseType';
            licenseTypeId: string;
            licenseTypeName: string;
            licenseTypeCode?: LICENSE_CODE | null;
            sortOrder?: number | null;
          } | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type AddCompanyMutationVariables = {
  query?: MutationAddCompanyQueryInput | null;
  body?: MutationAddCompanyBodyInput | null;
};

export type AddCompanyMutation = {
  addCompany?: {
    __typename: 'Company';
    companyId: string;
    companyName?: string | null;
    renewalDate?: string | null;
    mfA_Required?: boolean | null;
    data_Analytics?: boolean | null;
    companyDownloadPaths?: Array<{
      __typename: 'CompanyDownloadPath';
      document?: string | null;
      updateBy?: string | null;
      updatedOn?: string | null;
    } | null> | null;
    companyImageId?: string | null;
    address?: {
      __typename: 'Address';
      addressId?: string | null;
      addressLine1?: string | null;
      addressLine2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zip?: string | null;
    } | null;
    productPool?: Array<{
      __typename: 'ProductPool';
      productPoolId: string;
      poolQuantity?: number | null;
      productId?: string | null;
      bundleId?: string | null;
      type?: string | null;
      label?: string | null;
      poolAvailableQuantity?: number | null;
      renewalDate?: string | null;
      customData?: string | null;
      termDate?: string | null;
      startDate?: string | null;
      trialRenewalDate?: string | null;
      company?: {
        __typename: 'Company';
        companyId: string;
        companyName?: string | null;
        renewalDate?: string | null;
        mfA_Required?: boolean | null;
        data_Analytics?: boolean | null;
        companyDownloadPaths?: Array<{
          __typename: 'CompanyDownloadPath';
          document?: string | null;
          updateBy?: string | null;
          updatedOn?: string | null;
        } | null> | null;
        companyImageId?: string | null;
        address?: {
          __typename: 'Address';
          addressId?: string | null;
          addressLine1?: string | null;
          addressLine2?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
          zip?: string | null;
        } | null;
        productPool?: Array<{
          __typename: 'ProductPool';
          productPoolId: string;
          poolQuantity?: number | null;
          productId?: string | null;
          bundleId?: string | null;
          type?: string | null;
          label?: string | null;
          poolAvailableQuantity?: number | null;
          renewalDate?: string | null;
          customData?: string | null;
          termDate?: string | null;
          startDate?: string | null;
          trialRenewalDate?: string | null;
          company?: {
            __typename: 'Company';
            companyId: string;
            companyName?: string | null;
            renewalDate?: string | null;
            mfA_Required?: boolean | null;
            data_Analytics?: boolean | null;
            companyDownloadPaths?: Array<{
              __typename: 'CompanyDownloadPath';
              document?: string | null;
              updateBy?: string | null;
              updatedOn?: string | null;
            } | null> | null;
            companyImageId?: string | null;
            address?: {
              __typename: 'Address';
              addressId?: string | null;
              addressLine1?: string | null;
              addressLine2?: string | null;
              city?: string | null;
              state?: string | null;
              country?: string | null;
              zip?: string | null;
            } | null;
            productPool?: Array<{
              __typename: 'ProductPool';
              productPoolId: string;
              poolQuantity?: number | null;
              productId?: string | null;
              bundleId?: string | null;
              type?: string | null;
              label?: string | null;
              poolAvailableQuantity?: number | null;
              renewalDate?: string | null;
              customData?: string | null;
              termDate?: string | null;
              startDate?: string | null;
              trialRenewalDate?: string | null;
            } | null> | null;
          } | null;
          product?: {
            __typename: 'Product';
            productId: string;
            productName?: string | null;
            baseProductName?: string | null;
            isForesiteModule?: boolean | null;
            licenseTypeId?: string | null;
            licenseTypeName?: string | null;
            licenseClassId?: string | null;
            licenseClassName?: string | null;
            productNameFormatted?: string | null;
            onlyVisibleToMembers?: boolean | null;
            supportsTrial?: boolean | null;
            productLicenseTypes?: Array<{
              __typename: 'ProductLicenseType';
              productLicenseTypeId: string;
              productId: string;
              licenseTypeId: string;
            } | null> | null;
          } | null;
        } | null> | null;
      } | null;
      product?: {
        __typename: 'Product';
        productId: string;
        productName?: string | null;
        baseProductName?: string | null;
        isForesiteModule?: boolean | null;
        licenseTypeId?: string | null;
        licenseTypeName?: string | null;
        licenseClassId?: string | null;
        licenseClassName?: string | null;
        productNameFormatted?: string | null;
        onlyVisibleToMembers?: boolean | null;
        supportsTrial?: boolean | null;
        productLicenseTypes?: Array<{
          __typename: 'ProductLicenseType';
          productLicenseTypeId: string;
          productId: string;
          licenseTypeId: string;
          licenseType?: {
            __typename: 'LicenseType';
            licenseTypeId: string;
            licenseTypeName: string;
            licenseTypeCode?: LICENSE_CODE | null;
            sortOrder?: number | null;
          } | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type AddUserMutationVariables = {
  query?: MutationAddUserQueryInput | null;
  body?: MutationAddUserBodyInput | null;
};

export type AddUserMutation = {
  addUser?: {
    __typename: 'User';
    userId: string;
    userName: string;
    userFirstName?: string | null;
    userLastName?: string | null;
    userIdentifier?: string | null;
    userEmail?: string | null;
    workPhoneNumber?: string | null;
    personalPhoneNumber?: string | null;
    userStatus?: string | null;
    userStatusId?: string | null;
    inviteStatus?: USER_INVITE_STATUS | null;
    userTypes?: Array<{
      __typename: 'UserType';
      userTypeId?: string | null;
      typeName?: string | null;
      typeId?: string | null;
    } | null> | null;
    acceptedLatestEula?: boolean | null;
    companyId?: string | null;
    isAdmin?: boolean | null;
    isDeleted?: boolean | null;
    userPhotoId?: string | null;
  } | null;
};

export type AddProjectUserMutationVariables = {
  query?: MutationAddProjectUserQueryInput | null;
  body?: MutationAddProjectUserBodyInput | null;
};

export type AddProjectUserMutation = {
  addProjectUser?: {
    __typename: 'ProjectUser';
    projectUserId: string;
    projectId: string;
    inviteStatus?: PROJECT_USER_INVITE_STATUS | null;
    expiresAt?: string | null;
    isAdmin?: boolean | null;
    isDeleted?: boolean | null;
    user?: {
      __typename: 'User';
      userId: string;
      userName: string;
      userFirstName?: string | null;
      userLastName?: string | null;
      userIdentifier?: string | null;
      userEmail?: string | null;
      workPhoneNumber?: string | null;
      personalPhoneNumber?: string | null;
      userStatus?: string | null;
      userStatusId?: string | null;
      inviteStatus?: USER_INVITE_STATUS | null;
      userTypes?: Array<{
        __typename: 'UserType';
        userTypeId?: string | null;
        typeName?: string | null;
        typeId?: string | null;
      } | null> | null;
      acceptedLatestEula?: boolean | null;
      companyId?: string | null;
      isAdmin?: boolean | null;
      isDeleted?: boolean | null;
      userPhotoId?: string | null;
    } | null;
  } | null;
};

export type AddProjectMutationVariables = {
  query?: MutationAddProjectQueryInput | null;
  body?: MutationAddProjectBodyInput | null;
};

export type AddProjectMutation = {
  addProject?: {
    __typename: 'Project';
    projectId: string;
    projectName?: string | null;
    projectIdentifier?: string | null;
    dataAnalytics?: boolean | null;
    units?: string | null;
    companyId?: string | null;
    address?: {
      __typename: 'Address';
      addressId?: string | null;
      addressLine1?: string | null;
      addressLine2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zip?: string | null;
    } | null;
    projectImageId?: string | null;
    disallowAutomaticDistributionQty?: boolean | null;
    projectType?: {
      __typename: 'ProjectType';
      projectTypeId?: string | null;
      projectTypeName?: string | null;
      companyId?: string | null;
    } | null;
  } | null;
};

export type AddProductMutationVariables = {
  query?: MutationAddProductQueryInput | null;
  body?: MutationAddProductBodyInput | null;
};

export type AddProductMutation = {
  addProduct?: Array<{
    __typename: 'Product';
    productId: string;
    productName?: string | null;
    baseProductName?: string | null;
    isForesiteModule?: boolean | null;
    licenseTypeId?: string | null;
    licenseTypeName?: string | null;
    licenseClassId?: string | null;
    licenseClassName?: string | null;
    productNameFormatted?: string | null;
    onlyVisibleToMembers?: boolean | null;
    supportsTrial?: boolean | null;
    productLicenseTypes?: Array<{
      __typename: 'ProductLicenseType';
      productLicenseTypeId: string;
      productId: string;
      licenseTypeId: string;
      licenseType?: {
        __typename: 'LicenseType';
        licenseTypeId: string;
        licenseTypeName: string;
        licenseTypeCode?: LICENSE_CODE | null;
        sortOrder?: number | null;
      } | null;
    } | null> | null;
  } | null> | null;
};

export type AddProductPoolMutationVariables = {
  query?: MutationAddProductPoolQueryInput | null;
  body?: MutationAddProductPoolBodyInput | null;
};

export type AddProductPoolMutation = {
  addProductPool?: {
    __typename: 'ProductPool';
    productPoolId: string;
    poolQuantity?: number | null;
    productId?: string | null;
    bundleId?: string | null;
    type?: string | null;
    label?: string | null;
    poolAvailableQuantity?: number | null;
    renewalDate?: string | null;
    customData?: string | null;
    termDate?: string | null;
    startDate?: string | null;
    trialRenewalDate?: string | null;
    company?: {
      __typename: 'Company';
      companyId: string;
      companyName?: string | null;
      renewalDate?: string | null;
      mfA_Required?: boolean | null;
      data_Analytics?: boolean | null;
      companyDownloadPaths?: Array<{
        __typename: 'CompanyDownloadPath';
        document?: string | null;
        updateBy?: string | null;
        updatedOn?: string | null;
      } | null> | null;
      companyImageId?: string | null;
      address?: {
        __typename: 'Address';
        addressId?: string | null;
        addressLine1?: string | null;
        addressLine2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zip?: string | null;
      } | null;
      productPool?: Array<{
        __typename: 'ProductPool';
        productPoolId: string;
        poolQuantity?: number | null;
        productId?: string | null;
        bundleId?: string | null;
        type?: string | null;
        label?: string | null;
        poolAvailableQuantity?: number | null;
        renewalDate?: string | null;
        customData?: string | null;
        termDate?: string | null;
        startDate?: string | null;
        trialRenewalDate?: string | null;
        company?: {
          __typename: 'Company';
          companyId: string;
          companyName?: string | null;
          renewalDate?: string | null;
          mfA_Required?: boolean | null;
          data_Analytics?: boolean | null;
          companyDownloadPaths?: Array<{
            __typename: 'CompanyDownloadPath';
            document?: string | null;
            updateBy?: string | null;
            updatedOn?: string | null;
          } | null> | null;
          companyImageId?: string | null;
          address?: {
            __typename: 'Address';
            addressId?: string | null;
            addressLine1?: string | null;
            addressLine2?: string | null;
            city?: string | null;
            state?: string | null;
            country?: string | null;
            zip?: string | null;
          } | null;
          productPool?: Array<{
            __typename: 'ProductPool';
            productPoolId: string;
            poolQuantity?: number | null;
            productId?: string | null;
            bundleId?: string | null;
            type?: string | null;
            label?: string | null;
            poolAvailableQuantity?: number | null;
            renewalDate?: string | null;
            customData?: string | null;
            termDate?: string | null;
            startDate?: string | null;
            trialRenewalDate?: string | null;
            company?: {
              __typename: 'Company';
              companyId: string;
              companyName?: string | null;
              renewalDate?: string | null;
              mfA_Required?: boolean | null;
              data_Analytics?: boolean | null;
              companyImageId?: string | null;
            } | null;
            product?: {
              __typename: 'Product';
              productId: string;
              productName?: string | null;
              baseProductName?: string | null;
              isForesiteModule?: boolean | null;
              licenseTypeId?: string | null;
              licenseTypeName?: string | null;
              licenseClassId?: string | null;
              licenseClassName?: string | null;
              productNameFormatted?: string | null;
              onlyVisibleToMembers?: boolean | null;
              supportsTrial?: boolean | null;
            } | null;
          } | null> | null;
        } | null;
        product?: {
          __typename: 'Product';
          productId: string;
          productName?: string | null;
          baseProductName?: string | null;
          isForesiteModule?: boolean | null;
          licenseTypeId?: string | null;
          licenseTypeName?: string | null;
          licenseClassId?: string | null;
          licenseClassName?: string | null;
          productNameFormatted?: string | null;
          onlyVisibleToMembers?: boolean | null;
          supportsTrial?: boolean | null;
          productLicenseTypes?: Array<{
            __typename: 'ProductLicenseType';
            productLicenseTypeId: string;
            productId: string;
            licenseTypeId: string;
            licenseType?: {
              __typename: 'LicenseType';
              licenseTypeId: string;
              licenseTypeName: string;
              licenseTypeCode?: LICENSE_CODE | null;
              sortOrder?: number | null;
            } | null;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null;
    product?: {
      __typename: 'Product';
      productId: string;
      productName?: string | null;
      baseProductName?: string | null;
      isForesiteModule?: boolean | null;
      licenseTypeId?: string | null;
      licenseTypeName?: string | null;
      licenseClassId?: string | null;
      licenseClassName?: string | null;
      productNameFormatted?: string | null;
      onlyVisibleToMembers?: boolean | null;
      supportsTrial?: boolean | null;
      productLicenseTypes?: Array<{
        __typename: 'ProductLicenseType';
        productLicenseTypeId: string;
        productId: string;
        licenseTypeId: string;
        licenseType?: {
          __typename: 'LicenseType';
          licenseTypeId: string;
          licenseTypeName: string;
          licenseTypeCode?: LICENSE_CODE | null;
          sortOrder?: number | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type AddPartCatalogMutationVariables = {
  query?: MutationAddPartCatalogQueryInput | null;
  body?: MutationAddPartCatalogBodyInput | null;
};

export type AddPartCatalogMutation = {
  addPartCatalog?: {
    __typename: 'PartCatalog';
    partCatalogId: string;
    partCatalogName: string;
    companyId: string;
    topLevelNodes?: Array<{
      __typename: 'PartCategory';
      partCategoryId?: string | null;
      partCategoryName?: string | null;
      partCatalog?: {
        __typename: 'PartCatalog';
        partCatalogId: string;
        partCatalogName: string;
        companyId: string;
        topLevelNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      parentPartCategory?: {
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      subNodes?: Array<{
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type AddTopLevelPartCategoryMutationVariables = {
  query?: MutationAddTopLevelPartCategoryQueryInput | null;
  body?: MutationAddTopLevelPartCategoryBodyInput | null;
};

export type AddTopLevelPartCategoryMutation = {
  addTopLevelPartCategory?: {
    __typename: 'PartCategory';
    partCategoryId?: string | null;
    partCategoryName?: string | null;
    partCatalog?: {
      __typename: 'PartCatalog';
      partCatalogId: string;
      partCatalogName: string;
      companyId: string;
      topLevelNodes?: Array<{
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null;
    parentPartCategory?: {
      __typename: 'PartCategory';
      partCategoryId?: string | null;
      partCategoryName?: string | null;
      partCatalog?: {
        __typename: 'PartCatalog';
        partCatalogId: string;
        partCatalogName: string;
        companyId: string;
        topLevelNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      parentPartCategory?: {
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      subNodes?: Array<{
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null;
    subNodes?: Array<{
      __typename: 'PartCategory';
      partCategoryId?: string | null;
      partCategoryName?: string | null;
      partCatalog?: {
        __typename: 'PartCatalog';
        partCatalogId: string;
        partCatalogName: string;
        companyId: string;
        topLevelNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      parentPartCategory?: {
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      subNodes?: Array<{
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type AddSubLevelPartCategoryMutationVariables = {
  query?: MutationAddSubLevelPartCategoryQueryInput | null;
  body?: MutationAddSubLevelPartCategoryBodyInput | null;
};

export type AddSubLevelPartCategoryMutation = {
  addSubLevelPartCategory?: {
    __typename: 'PartCategory';
    partCategoryId?: string | null;
    partCategoryName?: string | null;
    partCatalog?: {
      __typename: 'PartCatalog';
      partCatalogId: string;
      partCatalogName: string;
      companyId: string;
      topLevelNodes?: Array<{
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null;
    parentPartCategory?: {
      __typename: 'PartCategory';
      partCategoryId?: string | null;
      partCategoryName?: string | null;
      partCatalog?: {
        __typename: 'PartCatalog';
        partCatalogId: string;
        partCatalogName: string;
        companyId: string;
        topLevelNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      parentPartCategory?: {
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      subNodes?: Array<{
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null;
    subNodes?: Array<{
      __typename: 'PartCategory';
      partCategoryId?: string | null;
      partCategoryName?: string | null;
      partCatalog?: {
        __typename: 'PartCatalog';
        partCatalogId: string;
        partCatalogName: string;
        companyId: string;
        topLevelNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      parentPartCategory?: {
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      subNodes?: Array<{
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type AddTasktypeMutationVariables = {
  query?: MutationAddTasktypeQueryInput | null;
  body?: MutationAddTasktypeBodyInput | null;
};

export type AddTasktypeMutation = {
  addTasktype?: {
    __typename: 'TaskType';
    taskTypeId: string;
    taskTypeImageId?: string | null;
    taskTypeName?: string | null;
    taskTypeDescription?: string | null;
    taskTypeClassId: string;
    companyId: string;
  } | null;
};

export type UpdatePartCategoryMutationVariables = {
  params: MutationUpdatePartCategoryParamsInput;
  query?: MutationUpdatePartCategoryQueryInput | null;
  body?: MutationUpdatePartCategoryBodyInput | null;
};

export type UpdatePartCategoryMutation = {
  updatePartCategory?: {
    __typename: 'PartCategory';
    partCategoryId?: string | null;
    partCategoryName?: string | null;
    partCatalog?: {
      __typename: 'PartCatalog';
      partCatalogId: string;
      partCatalogName: string;
      companyId: string;
      topLevelNodes?: Array<{
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null;
    parentPartCategory?: {
      __typename: 'PartCategory';
      partCategoryId?: string | null;
      partCategoryName?: string | null;
      partCatalog?: {
        __typename: 'PartCatalog';
        partCatalogId: string;
        partCatalogName: string;
        companyId: string;
        topLevelNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      parentPartCategory?: {
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      subNodes?: Array<{
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null;
    subNodes?: Array<{
      __typename: 'PartCategory';
      partCategoryId?: string | null;
      partCategoryName?: string | null;
      partCatalog?: {
        __typename: 'PartCatalog';
        partCatalogId: string;
        partCatalogName: string;
        companyId: string;
        topLevelNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      parentPartCategory?: {
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      subNodes?: Array<{
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type DeletePartCategoryMutationVariables = {
  params: MutationDeletePartCategoryParamsInput;
};

export type DeletePartCategoryMutation = {
  deletePartCategory?: boolean | null;
};

export type AddDepartmentUserMutationVariables = {
  query?: MutationAddDepartmentUserQueryInput | null;
  body?: MutationAddDepartmentUserBodyInput | null;
};

export type AddDepartmentUserMutation = {
  addDepartmentUser?: {
    __typename: 'DepartmentUser';
    departmentUserId: string;
    departmentId: string;
    expiresAt?: string | null;
    inviteStatus?: DEPARTMENT_USER_INVITE_STATUS | null;
    isDeleted?: boolean | null;
    isAdmin?: boolean | null;
    user?: {
      __typename: 'User';
      userId: string;
      userName: string;
      userFirstName?: string | null;
      userLastName?: string | null;
      userIdentifier?: string | null;
      userEmail?: string | null;
      workPhoneNumber?: string | null;
      personalPhoneNumber?: string | null;
      userStatus?: string | null;
      userStatusId?: string | null;
      inviteStatus?: USER_INVITE_STATUS | null;
      userTypes?: Array<{
        __typename: 'UserType';
        userTypeId?: string | null;
        typeName?: string | null;
        typeId?: string | null;
      } | null> | null;
      acceptedLatestEula?: boolean | null;
      companyId?: string | null;
      isAdmin?: boolean | null;
      isDeleted?: boolean | null;
      userPhotoId?: string | null;
    } | null;
  } | null;
};

export type AddFacilityMutationVariables = {
  query?: MutationAddFacilityQueryInput | null;
  body?: MutationAddFacilityBodyInput | null;
};

export type AddFacilityMutation = {
  addFacility?: {
    __typename: 'Facility';
    facilityId: string;
    facilityName: string;
    companyId: string;
    address?: {
      __typename: 'Address';
      addressId?: string | null;
      addressLine1?: string | null;
      addressLine2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zip?: string | null;
    } | null;
    departments: Array<{
      __typename: 'Department';
      departmentId?: string | null;
      departmentName?: string | null;
      departmentTypeId?: string | null;
      facility?: {
        __typename: 'DepartmentFacility';
        facilityId?: string | null;
        facilityName?: string | null;
      } | null;
      departmentType?: {
        __typename: 'DepartmentType';
        departmentTypeId?: string | null;
        departmentTypeName?: string | null;
      } | null;
      adminUser?: {
        __typename: 'User';
        userId: string;
        userName: string;
        userFirstName?: string | null;
        userLastName?: string | null;
        userIdentifier?: string | null;
        userEmail?: string | null;
        workPhoneNumber?: string | null;
        personalPhoneNumber?: string | null;
        userStatus?: string | null;
        userStatusId?: string | null;
        inviteStatus?: USER_INVITE_STATUS | null;
        userTypes?: Array<{
          __typename: 'UserType';
          userTypeId?: string | null;
          typeName?: string | null;
          typeId?: string | null;
        } | null> | null;
        acceptedLatestEula?: boolean | null;
        companyId?: string | null;
        isAdmin?: boolean | null;
        isDeleted?: boolean | null;
        userPhotoId?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type UpdateFacilityMutationVariables = {
  query?: MutationUpdateFacilityQueryInput | null;
  body?: MutationUpdateFacilityBodyInput | null;
};

export type UpdateFacilityMutation = {
  updateFacility?: {
    __typename: 'Facility';
    facilityId: string;
    facilityName: string;
    companyId: string;
    address?: {
      __typename: 'Address';
      addressId?: string | null;
      addressLine1?: string | null;
      addressLine2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zip?: string | null;
    } | null;
    departments: Array<{
      __typename: 'Department';
      departmentId?: string | null;
      departmentName?: string | null;
      departmentTypeId?: string | null;
      facility?: {
        __typename: 'DepartmentFacility';
        facilityId?: string | null;
        facilityName?: string | null;
      } | null;
      departmentType?: {
        __typename: 'DepartmentType';
        departmentTypeId?: string | null;
        departmentTypeName?: string | null;
      } | null;
      adminUser?: {
        __typename: 'User';
        userId: string;
        userName: string;
        userFirstName?: string | null;
        userLastName?: string | null;
        userIdentifier?: string | null;
        userEmail?: string | null;
        workPhoneNumber?: string | null;
        personalPhoneNumber?: string | null;
        userStatus?: string | null;
        userStatusId?: string | null;
        inviteStatus?: USER_INVITE_STATUS | null;
        userTypes?: Array<{
          __typename: 'UserType';
          userTypeId?: string | null;
          typeName?: string | null;
          typeId?: string | null;
        } | null> | null;
        acceptedLatestEula?: boolean | null;
        companyId?: string | null;
        isAdmin?: boolean | null;
        isDeleted?: boolean | null;
        userPhotoId?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type UserAcceptLatestEulaMutationVariables = {
  params: MutationUserAcceptLatestEulaParamsInput;
};

export type UserAcceptLatestEulaMutation = {
  userAcceptLatestEula?: {
    __typename: 'Eula';
    eulaId: string;
    eulaVersion: string;
    eulaTimeStamp: string;
    content: string;
  } | null;
};

export type AddCompanyRenewalDateMutationVariables = {
  params: MutationAddCompanyRenewalDateParamsInput;
  query?: MutationAddCompanyRenewalDateQueryInput | null;
  body?: MutationAddCompanyRenewalDateBodyInput | null;
};

export type AddCompanyRenewalDateMutation = {
  addCompanyRenewalDate?: {
    __typename: 'Company';
    companyId: string;
    companyName?: string | null;
    renewalDate?: string | null;
    mfA_Required?: boolean | null;
    data_Analytics?: boolean | null;
    companyDownloadPaths?: Array<{
      __typename: 'CompanyDownloadPath';
      document?: string | null;
      updateBy?: string | null;
      updatedOn?: string | null;
    } | null> | null;
    companyImageId?: string | null;
    address?: {
      __typename: 'Address';
      addressId?: string | null;
      addressLine1?: string | null;
      addressLine2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zip?: string | null;
    } | null;
    productPool?: Array<{
      __typename: 'ProductPool';
      productPoolId: string;
      poolQuantity?: number | null;
      productId?: string | null;
      bundleId?: string | null;
      type?: string | null;
      label?: string | null;
      poolAvailableQuantity?: number | null;
      renewalDate?: string | null;
      customData?: string | null;
      termDate?: string | null;
      startDate?: string | null;
      trialRenewalDate?: string | null;
      company?: {
        __typename: 'Company';
        companyId: string;
        companyName?: string | null;
        renewalDate?: string | null;
        mfA_Required?: boolean | null;
        data_Analytics?: boolean | null;
        companyDownloadPaths?: Array<{
          __typename: 'CompanyDownloadPath';
          document?: string | null;
          updateBy?: string | null;
          updatedOn?: string | null;
        } | null> | null;
        companyImageId?: string | null;
        address?: {
          __typename: 'Address';
          addressId?: string | null;
          addressLine1?: string | null;
          addressLine2?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
          zip?: string | null;
        } | null;
        productPool?: Array<{
          __typename: 'ProductPool';
          productPoolId: string;
          poolQuantity?: number | null;
          productId?: string | null;
          bundleId?: string | null;
          type?: string | null;
          label?: string | null;
          poolAvailableQuantity?: number | null;
          renewalDate?: string | null;
          customData?: string | null;
          termDate?: string | null;
          startDate?: string | null;
          trialRenewalDate?: string | null;
          company?: {
            __typename: 'Company';
            companyId: string;
            companyName?: string | null;
            renewalDate?: string | null;
            mfA_Required?: boolean | null;
            data_Analytics?: boolean | null;
            companyDownloadPaths?: Array<{
              __typename: 'CompanyDownloadPath';
              document?: string | null;
              updateBy?: string | null;
              updatedOn?: string | null;
            } | null> | null;
            companyImageId?: string | null;
            address?: {
              __typename: 'Address';
              addressId?: string | null;
              addressLine1?: string | null;
              addressLine2?: string | null;
              city?: string | null;
              state?: string | null;
              country?: string | null;
              zip?: string | null;
            } | null;
            productPool?: Array<{
              __typename: 'ProductPool';
              productPoolId: string;
              poolQuantity?: number | null;
              productId?: string | null;
              bundleId?: string | null;
              type?: string | null;
              label?: string | null;
              poolAvailableQuantity?: number | null;
              renewalDate?: string | null;
              customData?: string | null;
              termDate?: string | null;
              startDate?: string | null;
              trialRenewalDate?: string | null;
            } | null> | null;
          } | null;
          product?: {
            __typename: 'Product';
            productId: string;
            productName?: string | null;
            baseProductName?: string | null;
            isForesiteModule?: boolean | null;
            licenseTypeId?: string | null;
            licenseTypeName?: string | null;
            licenseClassId?: string | null;
            licenseClassName?: string | null;
            productNameFormatted?: string | null;
            onlyVisibleToMembers?: boolean | null;
            supportsTrial?: boolean | null;
            productLicenseTypes?: Array<{
              __typename: 'ProductLicenseType';
              productLicenseTypeId: string;
              productId: string;
              licenseTypeId: string;
            } | null> | null;
          } | null;
        } | null> | null;
      } | null;
      product?: {
        __typename: 'Product';
        productId: string;
        productName?: string | null;
        baseProductName?: string | null;
        isForesiteModule?: boolean | null;
        licenseTypeId?: string | null;
        licenseTypeName?: string | null;
        licenseClassId?: string | null;
        licenseClassName?: string | null;
        productNameFormatted?: string | null;
        onlyVisibleToMembers?: boolean | null;
        supportsTrial?: boolean | null;
        productLicenseTypes?: Array<{
          __typename: 'ProductLicenseType';
          productLicenseTypeId: string;
          productId: string;
          licenseTypeId: string;
          licenseType?: {
            __typename: 'LicenseType';
            licenseTypeId: string;
            licenseTypeName: string;
            licenseTypeCode?: LICENSE_CODE | null;
            sortOrder?: number | null;
          } | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type UpdateEntitlementMutationVariables = {
  params: MutationUpdateEntitlementParamsInput;
  query?: MutationUpdateEntitlementQueryInput | null;
  body?: MutationUpdateEntitlementBodyInput | null;
};

export type UpdateEntitlementMutation = {
  updateEntitlement?: {
    __typename: 'Entitlement';
    entitlementId: string;
    userId: string;
    isCheckedOut?: boolean | null;
    checkedOutEndDate?: string | null;
    secureData?: string | null;
    secureSignature?: string | null;
    licenseTypeCode?: LICENSE_CODE | null;
    productPool?: {
      __typename: 'ProductPool';
      productPoolId: string;
      poolQuantity?: number | null;
      productId?: string | null;
      bundleId?: string | null;
      type?: string | null;
      label?: string | null;
      poolAvailableQuantity?: number | null;
      renewalDate?: string | null;
      customData?: string | null;
      termDate?: string | null;
      startDate?: string | null;
      trialRenewalDate?: string | null;
      company?: {
        __typename: 'Company';
        companyId: string;
        companyName?: string | null;
        renewalDate?: string | null;
        mfA_Required?: boolean | null;
        data_Analytics?: boolean | null;
        companyDownloadPaths?: Array<{
          __typename: 'CompanyDownloadPath';
          document?: string | null;
          updateBy?: string | null;
          updatedOn?: string | null;
        } | null> | null;
        companyImageId?: string | null;
        address?: {
          __typename: 'Address';
          addressId?: string | null;
          addressLine1?: string | null;
          addressLine2?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
          zip?: string | null;
        } | null;
        productPool?: Array<{
          __typename: 'ProductPool';
          productPoolId: string;
          poolQuantity?: number | null;
          productId?: string | null;
          bundleId?: string | null;
          type?: string | null;
          label?: string | null;
          poolAvailableQuantity?: number | null;
          renewalDate?: string | null;
          customData?: string | null;
          termDate?: string | null;
          startDate?: string | null;
          trialRenewalDate?: string | null;
          company?: {
            __typename: 'Company';
            companyId: string;
            companyName?: string | null;
            renewalDate?: string | null;
            mfA_Required?: boolean | null;
            data_Analytics?: boolean | null;
            companyDownloadPaths?: Array<{
              __typename: 'CompanyDownloadPath';
              document?: string | null;
              updateBy?: string | null;
              updatedOn?: string | null;
            } | null> | null;
            companyImageId?: string | null;
            address?: {
              __typename: 'Address';
              addressId?: string | null;
              addressLine1?: string | null;
              addressLine2?: string | null;
              city?: string | null;
              state?: string | null;
              country?: string | null;
              zip?: string | null;
            } | null;
            productPool?: Array<{
              __typename: 'ProductPool';
              productPoolId: string;
              poolQuantity?: number | null;
              productId?: string | null;
              bundleId?: string | null;
              type?: string | null;
              label?: string | null;
              poolAvailableQuantity?: number | null;
              renewalDate?: string | null;
              customData?: string | null;
              termDate?: string | null;
              startDate?: string | null;
              trialRenewalDate?: string | null;
            } | null> | null;
          } | null;
          product?: {
            __typename: 'Product';
            productId: string;
            productName?: string | null;
            baseProductName?: string | null;
            isForesiteModule?: boolean | null;
            licenseTypeId?: string | null;
            licenseTypeName?: string | null;
            licenseClassId?: string | null;
            licenseClassName?: string | null;
            productNameFormatted?: string | null;
            onlyVisibleToMembers?: boolean | null;
            supportsTrial?: boolean | null;
            productLicenseTypes?: Array<{
              __typename: 'ProductLicenseType';
              productLicenseTypeId: string;
              productId: string;
              licenseTypeId: string;
            } | null> | null;
          } | null;
        } | null> | null;
      } | null;
      product?: {
        __typename: 'Product';
        productId: string;
        productName?: string | null;
        baseProductName?: string | null;
        isForesiteModule?: boolean | null;
        licenseTypeId?: string | null;
        licenseTypeName?: string | null;
        licenseClassId?: string | null;
        licenseClassName?: string | null;
        productNameFormatted?: string | null;
        onlyVisibleToMembers?: boolean | null;
        supportsTrial?: boolean | null;
        productLicenseTypes?: Array<{
          __typename: 'ProductLicenseType';
          productLicenseTypeId: string;
          productId: string;
          licenseTypeId: string;
          licenseType?: {
            __typename: 'LicenseType';
            licenseTypeId: string;
            licenseTypeName: string;
            licenseTypeCode?: LICENSE_CODE | null;
            sortOrder?: number | null;
          } | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateEntitlementsMutationVariables = {
  query?: MutationUpdateEntitlementsQueryInput | null;
  body?: MutationUpdateEntitlementsBodyInput | null;
};

export type UpdateEntitlementsMutation = {
  updateEntitlements?: Array<{
    __typename: 'Entitlement';
    entitlementId: string;
    userId: string;
    isCheckedOut?: boolean | null;
    checkedOutEndDate?: string | null;
    secureData?: string | null;
    secureSignature?: string | null;
    licenseTypeCode?: LICENSE_CODE | null;
    productPool?: {
      __typename: 'ProductPool';
      productPoolId: string;
      poolQuantity?: number | null;
      productId?: string | null;
      bundleId?: string | null;
      type?: string | null;
      label?: string | null;
      poolAvailableQuantity?: number | null;
      renewalDate?: string | null;
      customData?: string | null;
      termDate?: string | null;
      startDate?: string | null;
      trialRenewalDate?: string | null;
      company?: {
        __typename: 'Company';
        companyId: string;
        companyName?: string | null;
        renewalDate?: string | null;
        mfA_Required?: boolean | null;
        data_Analytics?: boolean | null;
        companyDownloadPaths?: Array<{
          __typename: 'CompanyDownloadPath';
          document?: string | null;
          updateBy?: string | null;
          updatedOn?: string | null;
        } | null> | null;
        companyImageId?: string | null;
        address?: {
          __typename: 'Address';
          addressId?: string | null;
          addressLine1?: string | null;
          addressLine2?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
          zip?: string | null;
        } | null;
        productPool?: Array<{
          __typename: 'ProductPool';
          productPoolId: string;
          poolQuantity?: number | null;
          productId?: string | null;
          bundleId?: string | null;
          type?: string | null;
          label?: string | null;
          poolAvailableQuantity?: number | null;
          renewalDate?: string | null;
          customData?: string | null;
          termDate?: string | null;
          startDate?: string | null;
          trialRenewalDate?: string | null;
          company?: {
            __typename: 'Company';
            companyId: string;
            companyName?: string | null;
            renewalDate?: string | null;
            mfA_Required?: boolean | null;
            data_Analytics?: boolean | null;
            companyDownloadPaths?: Array<{
              __typename: 'CompanyDownloadPath';
              document?: string | null;
              updateBy?: string | null;
              updatedOn?: string | null;
            } | null> | null;
            companyImageId?: string | null;
            address?: {
              __typename: 'Address';
              addressId?: string | null;
              addressLine1?: string | null;
              addressLine2?: string | null;
              city?: string | null;
              state?: string | null;
              country?: string | null;
              zip?: string | null;
            } | null;
            productPool?: Array<{
              __typename: 'ProductPool';
              productPoolId: string;
              poolQuantity?: number | null;
              productId?: string | null;
              bundleId?: string | null;
              type?: string | null;
              label?: string | null;
              poolAvailableQuantity?: number | null;
              renewalDate?: string | null;
              customData?: string | null;
              termDate?: string | null;
              startDate?: string | null;
              trialRenewalDate?: string | null;
            } | null> | null;
          } | null;
          product?: {
            __typename: 'Product';
            productId: string;
            productName?: string | null;
            baseProductName?: string | null;
            isForesiteModule?: boolean | null;
            licenseTypeId?: string | null;
            licenseTypeName?: string | null;
            licenseClassId?: string | null;
            licenseClassName?: string | null;
            productNameFormatted?: string | null;
            onlyVisibleToMembers?: boolean | null;
            supportsTrial?: boolean | null;
            productLicenseTypes?: Array<{
              __typename: 'ProductLicenseType';
              productLicenseTypeId: string;
              productId: string;
              licenseTypeId: string;
            } | null> | null;
          } | null;
        } | null> | null;
      } | null;
      product?: {
        __typename: 'Product';
        productId: string;
        productName?: string | null;
        baseProductName?: string | null;
        isForesiteModule?: boolean | null;
        licenseTypeId?: string | null;
        licenseTypeName?: string | null;
        licenseClassId?: string | null;
        licenseClassName?: string | null;
        productNameFormatted?: string | null;
        onlyVisibleToMembers?: boolean | null;
        supportsTrial?: boolean | null;
        productLicenseTypes?: Array<{
          __typename: 'ProductLicenseType';
          productLicenseTypeId: string;
          productId: string;
          licenseTypeId: string;
          licenseType?: {
            __typename: 'LicenseType';
            licenseTypeId: string;
            licenseTypeName: string;
            licenseTypeCode?: LICENSE_CODE | null;
            sortOrder?: number | null;
          } | null;
        } | null> | null;
      } | null;
    } | null;
  } | null> | null;
};

export type UpdateUserMutationVariables = {
  params: MutationUpdateUserParamsInput;
  query?: MutationUpdateUserQueryInput | null;
  body?: MutationUpdateUserBodyInput | null;
};

export type UpdateUserMutation = {
  updateUser?: {
    __typename: 'User';
    userId: string;
    userName: string;
    userFirstName?: string | null;
    userLastName?: string | null;
    userIdentifier?: string | null;
    userEmail?: string | null;
    workPhoneNumber?: string | null;
    personalPhoneNumber?: string | null;
    userStatus?: string | null;
    userStatusId?: string | null;
    inviteStatus?: USER_INVITE_STATUS | null;
    userTypes?: Array<{
      __typename: 'UserType';
      userTypeId?: string | null;
      typeName?: string | null;
      typeId?: string | null;
    } | null> | null;
    acceptedLatestEula?: boolean | null;
    companyId?: string | null;
    isAdmin?: boolean | null;
    isDeleted?: boolean | null;
    userPhotoId?: string | null;
  } | null;
};

export type UpdateProductPoolRenewalDateMutationVariables = {
  params: MutationUpdateProductPoolRenewalDateParamsInput;
  query?: MutationUpdateProductPoolRenewalDateQueryInput | null;
  body?: MutationUpdateProductPoolRenewalDateBodyInput | null;
};

export type UpdateProductPoolRenewalDateMutation = {
  updateProductPoolRenewalDate?: {
    __typename: 'ProductPool';
    productPoolId: string;
    poolQuantity?: number | null;
    productId?: string | null;
    bundleId?: string | null;
    type?: string | null;
    label?: string | null;
    poolAvailableQuantity?: number | null;
    renewalDate?: string | null;
    customData?: string | null;
    termDate?: string | null;
    startDate?: string | null;
    trialRenewalDate?: string | null;
    company?: {
      __typename: 'Company';
      companyId: string;
      companyName?: string | null;
      renewalDate?: string | null;
      mfA_Required?: boolean | null;
      data_Analytics?: boolean | null;
      companyDownloadPaths?: Array<{
        __typename: 'CompanyDownloadPath';
        document?: string | null;
        updateBy?: string | null;
        updatedOn?: string | null;
      } | null> | null;
      companyImageId?: string | null;
      address?: {
        __typename: 'Address';
        addressId?: string | null;
        addressLine1?: string | null;
        addressLine2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zip?: string | null;
      } | null;
      productPool?: Array<{
        __typename: 'ProductPool';
        productPoolId: string;
        poolQuantity?: number | null;
        productId?: string | null;
        bundleId?: string | null;
        type?: string | null;
        label?: string | null;
        poolAvailableQuantity?: number | null;
        renewalDate?: string | null;
        customData?: string | null;
        termDate?: string | null;
        startDate?: string | null;
        trialRenewalDate?: string | null;
        company?: {
          __typename: 'Company';
          companyId: string;
          companyName?: string | null;
          renewalDate?: string | null;
          mfA_Required?: boolean | null;
          data_Analytics?: boolean | null;
          companyDownloadPaths?: Array<{
            __typename: 'CompanyDownloadPath';
            document?: string | null;
            updateBy?: string | null;
            updatedOn?: string | null;
          } | null> | null;
          companyImageId?: string | null;
          address?: {
            __typename: 'Address';
            addressId?: string | null;
            addressLine1?: string | null;
            addressLine2?: string | null;
            city?: string | null;
            state?: string | null;
            country?: string | null;
            zip?: string | null;
          } | null;
          productPool?: Array<{
            __typename: 'ProductPool';
            productPoolId: string;
            poolQuantity?: number | null;
            productId?: string | null;
            bundleId?: string | null;
            type?: string | null;
            label?: string | null;
            poolAvailableQuantity?: number | null;
            renewalDate?: string | null;
            customData?: string | null;
            termDate?: string | null;
            startDate?: string | null;
            trialRenewalDate?: string | null;
            company?: {
              __typename: 'Company';
              companyId: string;
              companyName?: string | null;
              renewalDate?: string | null;
              mfA_Required?: boolean | null;
              data_Analytics?: boolean | null;
              companyImageId?: string | null;
            } | null;
            product?: {
              __typename: 'Product';
              productId: string;
              productName?: string | null;
              baseProductName?: string | null;
              isForesiteModule?: boolean | null;
              licenseTypeId?: string | null;
              licenseTypeName?: string | null;
              licenseClassId?: string | null;
              licenseClassName?: string | null;
              productNameFormatted?: string | null;
              onlyVisibleToMembers?: boolean | null;
              supportsTrial?: boolean | null;
            } | null;
          } | null> | null;
        } | null;
        product?: {
          __typename: 'Product';
          productId: string;
          productName?: string | null;
          baseProductName?: string | null;
          isForesiteModule?: boolean | null;
          licenseTypeId?: string | null;
          licenseTypeName?: string | null;
          licenseClassId?: string | null;
          licenseClassName?: string | null;
          productNameFormatted?: string | null;
          onlyVisibleToMembers?: boolean | null;
          supportsTrial?: boolean | null;
          productLicenseTypes?: Array<{
            __typename: 'ProductLicenseType';
            productLicenseTypeId: string;
            productId: string;
            licenseTypeId: string;
            licenseType?: {
              __typename: 'LicenseType';
              licenseTypeId: string;
              licenseTypeName: string;
              licenseTypeCode?: LICENSE_CODE | null;
              sortOrder?: number | null;
            } | null;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null;
    product?: {
      __typename: 'Product';
      productId: string;
      productName?: string | null;
      baseProductName?: string | null;
      isForesiteModule?: boolean | null;
      licenseTypeId?: string | null;
      licenseTypeName?: string | null;
      licenseClassId?: string | null;
      licenseClassName?: string | null;
      productNameFormatted?: string | null;
      onlyVisibleToMembers?: boolean | null;
      supportsTrial?: boolean | null;
      productLicenseTypes?: Array<{
        __typename: 'ProductLicenseType';
        productLicenseTypeId: string;
        productId: string;
        licenseTypeId: string;
        licenseType?: {
          __typename: 'LicenseType';
          licenseTypeId: string;
          licenseTypeName: string;
          licenseTypeCode?: LICENSE_CODE | null;
          sortOrder?: number | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type UpdateProjectMutationVariables = {
  params: MutationUpdateProjectParamsInput;
  query?: MutationUpdateProjectQueryInput | null;
  body?: MutationUpdateProjectBodyInput | null;
};

export type UpdateProjectMutation = {
  updateProject?: {
    __typename: 'Project';
    projectId: string;
    projectName?: string | null;
    projectIdentifier?: string | null;
    dataAnalytics?: boolean | null;
    units?: string | null;
    companyId?: string | null;
    address?: {
      __typename: 'Address';
      addressId?: string | null;
      addressLine1?: string | null;
      addressLine2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zip?: string | null;
    } | null;
    projectImageId?: string | null;
    disallowAutomaticDistributionQty?: boolean | null;
    projectType?: {
      __typename: 'ProjectType';
      projectTypeId?: string | null;
      projectTypeName?: string | null;
      companyId?: string | null;
    } | null;
  } | null;
};

export type UpdateCompanyMutationVariables = {
  params: MutationUpdateCompanyParamsInput;
  query?: MutationUpdateCompanyQueryInput | null;
  body?: MutationUpdateCompanyBodyInput | null;
};

export type UpdateCompanyMutation = {
  updateCompany?: {
    __typename: 'Company';
    companyId: string;
    companyName?: string | null;
    renewalDate?: string | null;
    mfA_Required?: boolean | null;
    data_Analytics?: boolean | null;
    companyDownloadPaths?: Array<{
      __typename: 'CompanyDownloadPath';
      document?: string | null;
      updateBy?: string | null;
      updatedOn?: string | null;
    } | null> | null;
    companyImageId?: string | null;
    address?: {
      __typename: 'Address';
      addressId?: string | null;
      addressLine1?: string | null;
      addressLine2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zip?: string | null;
    } | null;
    productPool?: Array<{
      __typename: 'ProductPool';
      productPoolId: string;
      poolQuantity?: number | null;
      productId?: string | null;
      bundleId?: string | null;
      type?: string | null;
      label?: string | null;
      poolAvailableQuantity?: number | null;
      renewalDate?: string | null;
      customData?: string | null;
      termDate?: string | null;
      startDate?: string | null;
      trialRenewalDate?: string | null;
      company?: {
        __typename: 'Company';
        companyId: string;
        companyName?: string | null;
        renewalDate?: string | null;
        mfA_Required?: boolean | null;
        data_Analytics?: boolean | null;
        companyDownloadPaths?: Array<{
          __typename: 'CompanyDownloadPath';
          document?: string | null;
          updateBy?: string | null;
          updatedOn?: string | null;
        } | null> | null;
        companyImageId?: string | null;
        address?: {
          __typename: 'Address';
          addressId?: string | null;
          addressLine1?: string | null;
          addressLine2?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
          zip?: string | null;
        } | null;
        productPool?: Array<{
          __typename: 'ProductPool';
          productPoolId: string;
          poolQuantity?: number | null;
          productId?: string | null;
          bundleId?: string | null;
          type?: string | null;
          label?: string | null;
          poolAvailableQuantity?: number | null;
          renewalDate?: string | null;
          customData?: string | null;
          termDate?: string | null;
          startDate?: string | null;
          trialRenewalDate?: string | null;
          company?: {
            __typename: 'Company';
            companyId: string;
            companyName?: string | null;
            renewalDate?: string | null;
            mfA_Required?: boolean | null;
            data_Analytics?: boolean | null;
            companyDownloadPaths?: Array<{
              __typename: 'CompanyDownloadPath';
              document?: string | null;
              updateBy?: string | null;
              updatedOn?: string | null;
            } | null> | null;
            companyImageId?: string | null;
            address?: {
              __typename: 'Address';
              addressId?: string | null;
              addressLine1?: string | null;
              addressLine2?: string | null;
              city?: string | null;
              state?: string | null;
              country?: string | null;
              zip?: string | null;
            } | null;
            productPool?: Array<{
              __typename: 'ProductPool';
              productPoolId: string;
              poolQuantity?: number | null;
              productId?: string | null;
              bundleId?: string | null;
              type?: string | null;
              label?: string | null;
              poolAvailableQuantity?: number | null;
              renewalDate?: string | null;
              customData?: string | null;
              termDate?: string | null;
              startDate?: string | null;
              trialRenewalDate?: string | null;
            } | null> | null;
          } | null;
          product?: {
            __typename: 'Product';
            productId: string;
            productName?: string | null;
            baseProductName?: string | null;
            isForesiteModule?: boolean | null;
            licenseTypeId?: string | null;
            licenseTypeName?: string | null;
            licenseClassId?: string | null;
            licenseClassName?: string | null;
            productNameFormatted?: string | null;
            onlyVisibleToMembers?: boolean | null;
            supportsTrial?: boolean | null;
            productLicenseTypes?: Array<{
              __typename: 'ProductLicenseType';
              productLicenseTypeId: string;
              productId: string;
              licenseTypeId: string;
            } | null> | null;
          } | null;
        } | null> | null;
      } | null;
      product?: {
        __typename: 'Product';
        productId: string;
        productName?: string | null;
        baseProductName?: string | null;
        isForesiteModule?: boolean | null;
        licenseTypeId?: string | null;
        licenseTypeName?: string | null;
        licenseClassId?: string | null;
        licenseClassName?: string | null;
        productNameFormatted?: string | null;
        onlyVisibleToMembers?: boolean | null;
        supportsTrial?: boolean | null;
        productLicenseTypes?: Array<{
          __typename: 'ProductLicenseType';
          productLicenseTypeId: string;
          productId: string;
          licenseTypeId: string;
          licenseType?: {
            __typename: 'LicenseType';
            licenseTypeId: string;
            licenseTypeName: string;
            licenseTypeCode?: LICENSE_CODE | null;
            sortOrder?: number | null;
          } | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type UpdateLocationMutationVariables = {
  params: MutationUpdateLocationParamsInput;
  query?: MutationUpdateLocationQueryInput | null;
  body?: MutationUpdateLocationBodyInput | null;
};

export type UpdateLocationMutation = {
  updateLocation?: {
    __typename: 'UpdatedLocation';
    explicitlyUpdatedLocation?: {
      __typename: 'Location';
      locationId?: string | null;
      locationTypeId?: string | null;
      locationTypeName?: string | null;
      projectId?: string | null;
      locationIdentifier?: string | null;
      locationCompositeIdentifier?: string | null;
      sortOrder?: number | null;
      hasChildren?: boolean | null;
      parentLocationId?: string | null;
      locationName?: string | null;
      locationCompositeName?: string | null;
      locationPhases?: Array<{
        __typename: 'LocationPhase';
        locationPhaseId: string;
        locationId?: string | null;
        workPhaseId: string;
        workPhaseName: string;
        scopePackageId: string;
        scopePackageName: string;
        sortOrder: number;
      } | null> | null;
      childrenTypeName?: string | null;
      movedLocationPhases?: Array<{
        __typename: 'UpdatedLocationPhase';
        workPhaseId: string;
        locationPhaseId: string;
      } | null> | null;
    } | null;
    updatedLocationPhases?: Array<{
      __typename: 'UpdatedLocationPhase';
      workPhaseId: string;
      locationPhaseId: string;
    } | null> | null;
  } | null;
};

export type UpdateWorkRequestMutationVariables = {
  params: MutationUpdateWorkRequestParamsInput;
  query?: MutationUpdateWorkRequestQueryInput | null;
  body?: MutationUpdateWorkRequestBodyInput | null;
};

export type UpdateWorkRequestMutation = {
  updateWorkRequest?: {
    __typename: 'WorkRequest';
    workRequestId?: string | null;
    workRequestStatusId?: string | null;
    workRequestStatusName?: string | null;
    facilityId?: string | null;
    projectId?: string | null;
    projectIdentifier?: string | null;
    projectName?: string | null;
    workRequestIdentifier?: string | null;
    workRequestName?: string | null;
    needBy?: string | null;
    workRequestDescription?: string | null;
    facilityName?: string | null;
    workRequestStatusDescription?: string | null;
    lastStatusUpdate?: string | null;
    numberOfAttachments?: number | null;
  } | null;
};

export type AddWorkRequestDocumentMutationVariables = {
  query?: MutationAddWorkRequestDocumentQueryInput | null;
  body?: MutationAddWorkRequestDocumentBodyInput | null;
};

export type AddWorkRequestDocumentMutation = {
  addWorkRequestDocument?: Array<{
    __typename: 'WorkRequestDocument';
    workRequestDocumentId: string;
    workRequestId: string;
    documentId?: string | null;
    externalUrl?: string | null;
    createdOn: string;
    updatedOn?: string | null;
    createdBy: string;
  } | null> | null;
};

export type AddWorkRequestItemDocumentMutationVariables = {
  query?: MutationAddWorkRequestItemDocumentQueryInput | null;
  body?: MutationAddWorkRequestItemDocumentBodyInput | null;
};

export type AddWorkRequestItemDocumentMutation = {
  addWorkRequestItemDocument?: boolean | null;
};

export type DeleteWorkRequestDocumentMutationVariables = {
  params: MutationDeleteWorkRequestDocumentParamsInput;
};

export type DeleteWorkRequestDocumentMutation = {
  deleteWorkRequestDocument?: boolean | null;
};

export type AddWorkOrderDocumentMutationVariables = {
  query?: MutationAddWorkOrderDocumentQueryInput | null;
  body?: MutationAddWorkOrderDocumentBodyInput | null;
};

export type AddWorkOrderDocumentMutation = {
  addWorkOrderDocument?: Array<{
    __typename: 'WorkOrderDocument';
    workOrderDocumentId: string;
    workOrderId: string;
    documentId?: string | null;
    externalUrl?: string | null;
    createdOn?: string | null;
    updatedOn?: string | null;
    createdBy?: string | null;
  } | null> | null;
};

export type DeleteWorkOrderDocumentMutationVariables = {
  params: MutationDeleteWorkOrderDocumentParamsInput;
};

export type DeleteWorkOrderDocumentMutation = {
  deleteWorkOrderDocument?: boolean | null;
};

export type UpdateWorkRequestItemMutationVariables = {
  params: MutationUpdateWorkRequestItemParamsInput;
  query?: MutationUpdateWorkRequestItemQueryInput | null;
  body?: MutationUpdateWorkRequestItemBodyInput | null;
};

export type UpdateWorkRequestItemMutation = {
  updateWorkRequestItem?: {
    __typename: 'WorkRequestItem';
    workRequestItemId?: string | null;
    workRequestId?: string | null;
    workRequestItemTypeId?: string | null;
    unitOfMeasureId?: string | null;
    billOfProcessId?: string | null;
    workRequestItemName?: string | null;
    workRequestItemStatusTypeId?: string | null;
    quantity?: number | null;
    unityQuantity?: number | null;
    assignedQuantity?: number | null;
    remainingQuantity?: number | null;
    hasShopTask?: boolean | null;
    createdOn?: string | null;
    createdBy?: string | null;
    lineItemId?: string | null;
  } | null;
};

export type UpdateWorkRequestStatusMutationVariables = {
  params: MutationUpdateWorkRequestStatusParamsInput;
  query?: MutationUpdateWorkRequestStatusQueryInput | null;
  body?: MutationUpdateWorkRequestStatusBodyInput | null;
};

export type UpdateWorkRequestStatusMutation = {
  updateWorkRequestStatus?: {
    __typename: 'WorkRequestsStatus';
    workRequestStatusId: string;
    workRequestStatusTypeId: string;
    workRequestStatusTypeName?: string | null;
    workRequestStatusTypeDescription?: string | null;
  } | null;
};

export type AddTaskTypeToWorkRequestMutationVariables = {
  params: MutationAddTaskTypeToWorkRequestParamsInput;
  query?: MutationAddTaskTypeToWorkRequestQueryInput | null;
  body?: MutationAddTaskTypeToWorkRequestBodyInput | null;
};

export type AddTaskTypeToWorkRequestMutation = {
  addTaskTypeToWorkRequest?: boolean | null;
};

export type UpdateScopePackageMutationVariables = {
  params: MutationUpdateScopePackageParamsInput;
  query?: MutationUpdateScopePackageQueryInput | null;
  body?: MutationUpdateScopePackageBodyInput | null;
};

export type UpdateScopePackageMutation = {
  updateScopePackage?: {
    __typename: 'ScopePackage';
    projectId: string;
    scopePackageId: string;
    scopePackageName: string;
    scopePackageIdentifier: string;
    sortOrder: number;
    hasWorkPhases?: boolean | null;
  } | null;
};

export type UpdateTaskTypeMutationVariables = {
  params: MutationUpdateTaskTypeParamsInput;
  query?: MutationUpdateTaskTypeQueryInput | null;
  body?: MutationUpdateTaskTypeBodyInput | null;
};

export type UpdateTaskTypeMutation = {
  updateTaskType?: {
    __typename: 'TaskType';
    taskTypeId: string;
    taskTypeImageId?: string | null;
    taskTypeName?: string | null;
    taskTypeDescription?: string | null;
    taskTypeClassId: string;
    companyId: string;
  } | null;
};

export type UpdateWorkPhaseMutationVariables = {
  params: MutationUpdateWorkPhaseParamsInput;
  query?: MutationUpdateWorkPhaseQueryInput | null;
  body?: MutationUpdateWorkPhaseBodyInput | null;
};

export type UpdateWorkPhaseMutation = {
  updateWorkPhase?: {
    __typename: 'WorkPhase';
    workPhaseId: string;
    workPhaseIdentifier: string;
    workPhaseName: string;
    sortOrder: number;
    scopePackageId: string;
    scopePackageName?: string | null;
    projectId: string;
    hasLocationPhases?: boolean | null;
    updatedWorkPhaseLocationPhases?: Array<{
      __typename: 'MappedLocation';
      locationId: string;
      locationPhaseId: string;
    } | null> | null;
  } | null;
};

export type DeleteProjectUsersMutationVariables = {
  query?: MutationDeleteProjectUsersQueryInput | null;
  body?: MutationDeleteProjectUsersBodyInput | null;
};

export type DeleteProjectUsersMutation = {
  deleteProjectUsers?: boolean | null;
};

export type DeleteDepartmentUsersMutationVariables = {
  query?: MutationDeleteDepartmentUsersQueryInput | null;
  body?: MutationDeleteDepartmentUsersBodyInput | null;
};

export type DeleteDepartmentUsersMutation = {
  deleteDepartmentUsers?: boolean | null;
};

export type DeleteUsersMutationVariables = {
  query?: MutationDeleteUsersQueryInput | null;
  body?: MutationDeleteUsersBodyInput | null;
};

export type DeleteUsersMutation = {
  deleteUsers?: boolean | null;
};

export type DeleteProjectMutationVariables = {
  params: MutationDeleteProjectParamsInput;
};

export type DeleteProjectMutation = {
  deleteProject?: boolean | null;
};

export type DeleteFacilityMutationVariables = {
  params: MutationDeleteFacilityParamsInput;
};

export type DeleteFacilityMutation = {
  deleteFacility?: boolean | null;
};

export type DeleteTaskTypeMutationVariables = {
  params: MutationDeleteTaskTypeParamsInput;
};

export type DeleteTaskTypeMutation = {
  deleteTaskType?: boolean | null;
};

export type TrashMessageMutationVariables = {
  params: MutationTrashMessageParamsInput;
};

export type TrashMessageMutation = {
  trashMessage?: boolean | null;
};

export type RestoreMessageMutationVariables = {
  params: MutationRestoreMessageParamsInput;
};

export type RestoreMessageMutation = {
  restoreMessage?: {
    __typename: 'Message';
    messageId?: string | null;
    companyId?: string | null;
    messagePriorityId?: string | null;
    fromName?: string | null;
    subject?: string | null;
    body?: string | null;
    messagePriorityCode?: MESSAGE_PRIORITY_CODE | null;
    statusId?: string | null;
    statusName?: string | null;
    accessedOn?: string | null;
    createdOn?: string | null;
  } | null;
};

export type MarkMessageAsReadMutationVariables = {
  params: MutationMarkMessageAsReadParamsInput;
  query?: MutationMarkMessageAsReadQueryInput | null;
  body?: MutationMarkMessageAsReadBodyInput | null;
};

export type MarkMessageAsReadMutation = {
  markMessageAsRead?: boolean | null;
};

export type DeleteDepartmentMutationVariables = {
  params: MutationDeleteDepartmentParamsInput;
};

export type DeleteDepartmentMutation = {
  deleteDepartment?: boolean | null;
};

export type DeleteEntitlementMutationVariables = {
  params: MutationDeleteEntitlementParamsInput;
};

export type DeleteEntitlementMutation = {
  deleteEntitlement?: {
    __typename: 'Entitlement';
    entitlementId: string;
    userId: string;
    isCheckedOut?: boolean | null;
    checkedOutEndDate?: string | null;
    secureData?: string | null;
    secureSignature?: string | null;
    licenseTypeCode?: LICENSE_CODE | null;
    productPool?: {
      __typename: 'ProductPool';
      productPoolId: string;
      poolQuantity?: number | null;
      productId?: string | null;
      bundleId?: string | null;
      type?: string | null;
      label?: string | null;
      poolAvailableQuantity?: number | null;
      renewalDate?: string | null;
      customData?: string | null;
      termDate?: string | null;
      startDate?: string | null;
      trialRenewalDate?: string | null;
      company?: {
        __typename: 'Company';
        companyId: string;
        companyName?: string | null;
        renewalDate?: string | null;
        mfA_Required?: boolean | null;
        data_Analytics?: boolean | null;
        companyDownloadPaths?: Array<{
          __typename: 'CompanyDownloadPath';
          document?: string | null;
          updateBy?: string | null;
          updatedOn?: string | null;
        } | null> | null;
        companyImageId?: string | null;
        address?: {
          __typename: 'Address';
          addressId?: string | null;
          addressLine1?: string | null;
          addressLine2?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
          zip?: string | null;
        } | null;
        productPool?: Array<{
          __typename: 'ProductPool';
          productPoolId: string;
          poolQuantity?: number | null;
          productId?: string | null;
          bundleId?: string | null;
          type?: string | null;
          label?: string | null;
          poolAvailableQuantity?: number | null;
          renewalDate?: string | null;
          customData?: string | null;
          termDate?: string | null;
          startDate?: string | null;
          trialRenewalDate?: string | null;
          company?: {
            __typename: 'Company';
            companyId: string;
            companyName?: string | null;
            renewalDate?: string | null;
            mfA_Required?: boolean | null;
            data_Analytics?: boolean | null;
            companyDownloadPaths?: Array<{
              __typename: 'CompanyDownloadPath';
              document?: string | null;
              updateBy?: string | null;
              updatedOn?: string | null;
            } | null> | null;
            companyImageId?: string | null;
            address?: {
              __typename: 'Address';
              addressId?: string | null;
              addressLine1?: string | null;
              addressLine2?: string | null;
              city?: string | null;
              state?: string | null;
              country?: string | null;
              zip?: string | null;
            } | null;
            productPool?: Array<{
              __typename: 'ProductPool';
              productPoolId: string;
              poolQuantity?: number | null;
              productId?: string | null;
              bundleId?: string | null;
              type?: string | null;
              label?: string | null;
              poolAvailableQuantity?: number | null;
              renewalDate?: string | null;
              customData?: string | null;
              termDate?: string | null;
              startDate?: string | null;
              trialRenewalDate?: string | null;
            } | null> | null;
          } | null;
          product?: {
            __typename: 'Product';
            productId: string;
            productName?: string | null;
            baseProductName?: string | null;
            isForesiteModule?: boolean | null;
            licenseTypeId?: string | null;
            licenseTypeName?: string | null;
            licenseClassId?: string | null;
            licenseClassName?: string | null;
            productNameFormatted?: string | null;
            onlyVisibleToMembers?: boolean | null;
            supportsTrial?: boolean | null;
            productLicenseTypes?: Array<{
              __typename: 'ProductLicenseType';
              productLicenseTypeId: string;
              productId: string;
              licenseTypeId: string;
            } | null> | null;
          } | null;
        } | null> | null;
      } | null;
      product?: {
        __typename: 'Product';
        productId: string;
        productName?: string | null;
        baseProductName?: string | null;
        isForesiteModule?: boolean | null;
        licenseTypeId?: string | null;
        licenseTypeName?: string | null;
        licenseClassId?: string | null;
        licenseClassName?: string | null;
        productNameFormatted?: string | null;
        onlyVisibleToMembers?: boolean | null;
        supportsTrial?: boolean | null;
        productLicenseTypes?: Array<{
          __typename: 'ProductLicenseType';
          productLicenseTypeId: string;
          productId: string;
          licenseTypeId: string;
          licenseType?: {
            __typename: 'LicenseType';
            licenseTypeId: string;
            licenseTypeName: string;
            licenseTypeCode?: LICENSE_CODE | null;
            sortOrder?: number | null;
          } | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteLocationMutationVariables = {
  params: MutationDeleteLocationParamsInput;
};

export type DeleteLocationMutation = {
  deleteLocation?: {
    __typename: 'DeletedLocationResponse';
    explicitlyDeletedLocation: {
      __typename: 'Location';
      locationId?: string | null;
      locationTypeId?: string | null;
      locationTypeName?: string | null;
      projectId?: string | null;
      locationIdentifier?: string | null;
      locationCompositeIdentifier?: string | null;
      sortOrder?: number | null;
      hasChildren?: boolean | null;
      parentLocationId?: string | null;
      locationName?: string | null;
      locationCompositeName?: string | null;
      locationPhases?: Array<{
        __typename: 'LocationPhase';
        locationPhaseId: string;
        locationId?: string | null;
        workPhaseId: string;
        workPhaseName: string;
        scopePackageId: string;
        scopePackageName: string;
        sortOrder: number;
      } | null> | null;
      childrenTypeName?: string | null;
      movedLocationPhases?: Array<{
        __typename: 'UpdatedLocationPhase';
        workPhaseId: string;
        locationPhaseId: string;
      } | null> | null;
    };
    deletedLocationChildrenIds?: Array<string | null> | null;
    deletedLocationPhases?: Array<{
      __typename: 'DeletedWorkPhase';
      workPhaseId: string;
      deletedLocationPhaseId: string;
    } | null> | null;
  } | null;
};

export type DeleteScopePackageMutationVariables = {
  params: MutationDeleteScopePackageParamsInput;
};

export type DeleteScopePackageMutation = {
  deleteScopePackage?: {
    __typename: 'DeletedScopePackageResponse';
    deletedScopePackage: {
      __typename: 'ScopePackage';
      projectId: string;
      scopePackageId: string;
      scopePackageName: string;
      scopePackageIdentifier: string;
      sortOrder: number;
      hasWorkPhases?: boolean | null;
    };
    deletedWorkPhasesIds?: Array<string | null> | null;
    deletedLocationPhases?: Array<{
      __typename: 'DeletedLocationPhase';
      locationId: string;
      deletedLocationPhaseId: string;
    } | null> | null;
  } | null;
};

export type DeleteWorkPhaseMutationVariables = {
  params: MutationDeleteWorkPhaseParamsInput;
};

export type DeleteWorkPhaseMutation = {
  deleteWorkPhase?: {
    __typename: 'DeletedWorkPhaseResponse';
    deletedWorkPhase: {
      __typename: 'WorkPhase';
      workPhaseId: string;
      workPhaseIdentifier: string;
      workPhaseName: string;
      sortOrder: number;
      scopePackageId: string;
      scopePackageName?: string | null;
      projectId: string;
      hasLocationPhases?: boolean | null;
      updatedWorkPhaseLocationPhases?: Array<{
        __typename: 'MappedLocation';
        locationId: string;
        locationPhaseId: string;
      } | null> | null;
    };
    deletedLocationPhases?: Array<{
      __typename: 'DeletedLocationPhase';
      locationId: string;
      deletedLocationPhaseId: string;
    } | null> | null;
  } | null;
};

export type DeleteWorkRequestMutationVariables = {
  params: MutationDeleteWorkRequestParamsInput;
};

export type DeleteWorkRequestMutation = {
  deleteWorkRequest?: boolean | null;
};

export type DeleteWorkRequestItemMutationVariables = {
  params: MutationDeleteWorkRequestItemParamsInput;
};

export type DeleteWorkRequestItemMutation = {
  deleteWorkRequestItem?: boolean | null;
};

export type ResendUserInvitationMutationVariables = {
  params: MutationResendUserInvitationParamsInput;
};

export type ResendUserInvitationMutation = {
  resendUserInvitation?: {
    __typename: 'User';
    userId: string;
    userName: string;
    userFirstName?: string | null;
    userLastName?: string | null;
    userIdentifier?: string | null;
    userEmail?: string | null;
    workPhoneNumber?: string | null;
    personalPhoneNumber?: string | null;
    userStatus?: string | null;
    userStatusId?: string | null;
    inviteStatus?: USER_INVITE_STATUS | null;
    userTypes?: Array<{
      __typename: 'UserType';
      userTypeId?: string | null;
      typeName?: string | null;
      typeId?: string | null;
    } | null> | null;
    acceptedLatestEula?: boolean | null;
    companyId?: string | null;
    isAdmin?: boolean | null;
    isDeleted?: boolean | null;
    userPhotoId?: string | null;
  } | null;
};

export type ResendProjectUserInvitationMutationVariables = {
  params: MutationResendProjectUserInvitationParamsInput;
};

export type ResendProjectUserInvitationMutation = {
  resendProjectUserInvitation?: {
    __typename: 'ProjectUser';
    projectUserId: string;
    projectId: string;
    inviteStatus?: PROJECT_USER_INVITE_STATUS | null;
    expiresAt?: string | null;
    isAdmin?: boolean | null;
    isDeleted?: boolean | null;
    user?: {
      __typename: 'User';
      userId: string;
      userName: string;
      userFirstName?: string | null;
      userLastName?: string | null;
      userIdentifier?: string | null;
      userEmail?: string | null;
      workPhoneNumber?: string | null;
      personalPhoneNumber?: string | null;
      userStatus?: string | null;
      userStatusId?: string | null;
      inviteStatus?: USER_INVITE_STATUS | null;
      userTypes?: Array<{
        __typename: 'UserType';
        userTypeId?: string | null;
        typeName?: string | null;
        typeId?: string | null;
      } | null> | null;
      acceptedLatestEula?: boolean | null;
      companyId?: string | null;
      isAdmin?: boolean | null;
      isDeleted?: boolean | null;
      userPhotoId?: string | null;
    } | null;
  } | null;
};

export type ResendDepartmentUserInvitationMutationVariables = {
  params: MutationResendDepartmentUserInvitationParamsInput;
};

export type ResendDepartmentUserInvitationMutation = {
  resendDepartmentUserInvitation?: {
    __typename: 'DepartmentUser';
    departmentUserId: string;
    departmentId: string;
    expiresAt?: string | null;
    inviteStatus?: DEPARTMENT_USER_INVITE_STATUS | null;
    isDeleted?: boolean | null;
    isAdmin?: boolean | null;
    user?: {
      __typename: 'User';
      userId: string;
      userName: string;
      userFirstName?: string | null;
      userLastName?: string | null;
      userIdentifier?: string | null;
      userEmail?: string | null;
      workPhoneNumber?: string | null;
      personalPhoneNumber?: string | null;
      userStatus?: string | null;
      userStatusId?: string | null;
      inviteStatus?: USER_INVITE_STATUS | null;
      userTypes?: Array<{
        __typename: 'UserType';
        userTypeId?: string | null;
        typeName?: string | null;
        typeId?: string | null;
      } | null> | null;
      acceptedLatestEula?: boolean | null;
      companyId?: string | null;
      isAdmin?: boolean | null;
      isDeleted?: boolean | null;
      userPhotoId?: string | null;
    } | null;
  } | null;
};

export type AddBillOfMaterialMutationVariables = {
  query?: MutationAddBillOfMaterialQueryInput | null;
  body?: MutationAddBillOfMaterialBodyInput | null;
};

export type AddBillOfMaterialMutation = {
  addBillOfMaterial?: {
    __typename: 'BillOfMaterial';
    billOfMaterialsId: string;
    billOfMaterialsStatusId?: string | null;
    billOfMaterialsStatusName?: string | null;
    projectId: string;
    billOfMaterialsName: string;
    createdOn: string;
    createdBy: string;
    modifiedOn?: string | null;
    modifiedBy?: string | null;
    createdByFirstName?: string | null;
    createdByLastName?: string | null;
    createdByEmail?: string | null;
    updatedOn?: string | null;
    updatedBy?: string | null;
    updatedByFirstName?: string | null;
    updatedByLastName?: string | null;
    updatedByEmail?: string | null;
  } | null;
};

export type AddBillOfMaterialsWriteInItemMutationVariables = {
  query?: MutationAddBillOfMaterialsWriteInItemQueryInput | null;
  body?: MutationAddBillOfMaterialsWriteInItemBodyInput | null;
};

export type AddBillOfMaterialsWriteInItemMutation = {
  addBillOfMaterialsWriteInItem?: Array<{
    __typename: 'BillOfMaterialItem';
    lineItemId: string;
    lineItemTypeId: string;
    lineItemTypeName?: string | null;
    billOfMaterialsId?: string | null;
    unitOfMeasureId?: string | null;
    unitOfMeasureName?: string | null;
    unitOfMeasureCode?: string | null;
    manufacturerId?: string | null;
    manufacturerName?: string | null;
    partId?: string | null;
    locationId?: string | null;
    workPhaseId?: string | null;
    scopePackageId?: string | null;
    workPackageId?: string | null;
    kitPlanId?: string | null;
    spoolId?: string | null;
    lineItemName?: string | null;
    manufacturerCatalogNumber?: string | null;
    quantity?: number | null;
    remainingQuantity?: number | null;
    unitQuantity?: number | null;
    tradeSize?: string | null;
    createdOn?: string | null;
    createdBy?: string | null;
    createdByFirstName?: string | null;
    childLineItemContainerName?: string | null;
    createdByLastName?: string | null;
    createdByEmail?: string | null;
    updatedOn?: string | null;
    updatedBy?: string | null;
    updatedByFirstName?: string | null;
    updatedByLastName?: string | null;
    updatedByEmail?: string | null;
    numberOfOpenDecisions?: number | null;
    decisionsHaveVariantDeletedFromCurrentVersionOfPart?: boolean | null;
    ableToMakeDecisions?: boolean | null;
    numberOfDecisionsMade?: number | null;
    hasChildNodes?: boolean | null;
    parentLineItem?: string | null;
    projectId?: string | null;
    assemblyCustomCategoryId?: string | null;
    assemblyCustomCategoryName?: string | null;
    canChildLineItemBeAdded?: boolean | null;
  } | null> | null;
};

export type AddBillOfMaterialsItemMutationVariables = {
  query?: MutationAddBillOfMaterialsItemQueryInput | null;
  body?: MutationAddBillOfMaterialsItemBodyInput | null;
};

export type AddBillOfMaterialsItemMutation = {
  addBillOfMaterialsItem?: {
    __typename: 'BillOfMaterialItem';
    lineItemId: string;
    lineItemTypeId: string;
    lineItemTypeName?: string | null;
    billOfMaterialsId?: string | null;
    unitOfMeasureId?: string | null;
    unitOfMeasureName?: string | null;
    unitOfMeasureCode?: string | null;
    manufacturerId?: string | null;
    manufacturerName?: string | null;
    partId?: string | null;
    locationId?: string | null;
    workPhaseId?: string | null;
    scopePackageId?: string | null;
    workPackageId?: string | null;
    kitPlanId?: string | null;
    spoolId?: string | null;
    lineItemName?: string | null;
    manufacturerCatalogNumber?: string | null;
    quantity?: number | null;
    remainingQuantity?: number | null;
    unitQuantity?: number | null;
    tradeSize?: string | null;
    createdOn?: string | null;
    createdBy?: string | null;
    createdByFirstName?: string | null;
    childLineItemContainerName?: string | null;
    createdByLastName?: string | null;
    createdByEmail?: string | null;
    updatedOn?: string | null;
    updatedBy?: string | null;
    updatedByFirstName?: string | null;
    updatedByLastName?: string | null;
    updatedByEmail?: string | null;
    numberOfOpenDecisions?: number | null;
    decisionsHaveVariantDeletedFromCurrentVersionOfPart?: boolean | null;
    ableToMakeDecisions?: boolean | null;
    numberOfDecisionsMade?: number | null;
    hasChildNodes?: boolean | null;
    parentLineItem?: string | null;
    projectId?: string | null;
    assemblyCustomCategoryId?: string | null;
    assemblyCustomCategoryName?: string | null;
    canChildLineItemBeAdded?: boolean | null;
  } | null;
};

export type DeleteBillOfMaterialsMutationVariables = {
  params: MutationDeleteBillOfMaterialsParamsInput;
};

export type DeleteBillOfMaterialsMutation = {
  deleteBillOfMaterials?: boolean | null;
};

export type UpdateBillOfMaterialsItemByIdMutationVariables = {
  params: MutationUpdateBillOfMaterialsItemByIdParamsInput;
  query?: MutationUpdateBillOfMaterialsItemByIdQueryInput | null;
  body?: MutationUpdateBillOfMaterialsItemByIdBodyInput | null;
};

export type UpdateBillOfMaterialsItemByIdMutation = {
  updateBillOfMaterialsItemById?: {
    __typename: 'BillOfMaterialItem';
    lineItemId: string;
    lineItemTypeId: string;
    lineItemTypeName?: string | null;
    billOfMaterialsId?: string | null;
    unitOfMeasureId?: string | null;
    unitOfMeasureName?: string | null;
    unitOfMeasureCode?: string | null;
    manufacturerId?: string | null;
    manufacturerName?: string | null;
    partId?: string | null;
    locationId?: string | null;
    workPhaseId?: string | null;
    scopePackageId?: string | null;
    workPackageId?: string | null;
    kitPlanId?: string | null;
    spoolId?: string | null;
    lineItemName?: string | null;
    manufacturerCatalogNumber?: string | null;
    quantity?: number | null;
    remainingQuantity?: number | null;
    unitQuantity?: number | null;
    tradeSize?: string | null;
    createdOn?: string | null;
    createdBy?: string | null;
    createdByFirstName?: string | null;
    childLineItemContainerName?: string | null;
    createdByLastName?: string | null;
    createdByEmail?: string | null;
    updatedOn?: string | null;
    updatedBy?: string | null;
    updatedByFirstName?: string | null;
    updatedByLastName?: string | null;
    updatedByEmail?: string | null;
    numberOfOpenDecisions?: number | null;
    decisionsHaveVariantDeletedFromCurrentVersionOfPart?: boolean | null;
    ableToMakeDecisions?: boolean | null;
    numberOfDecisionsMade?: number | null;
    hasChildNodes?: boolean | null;
    parentLineItem?: string | null;
    projectId?: string | null;
    assemblyCustomCategoryId?: string | null;
    assemblyCustomCategoryName?: string | null;
    canChildLineItemBeAdded?: boolean | null;
  } | null;
};

export type UpdateDecisionsMutationVariables = {
  query?: MutationUpdateDecisionsQueryInput | null;
  body?: MutationUpdateDecisionsBodyInput | null;
};

export type UpdateDecisionsMutation = {
  updateDecisions?: {
    __typename: 'BillOfMaterialItem';
    lineItemId: string;
    lineItemTypeId: string;
    lineItemTypeName?: string | null;
    billOfMaterialsId?: string | null;
    unitOfMeasureId?: string | null;
    unitOfMeasureName?: string | null;
    unitOfMeasureCode?: string | null;
    manufacturerId?: string | null;
    manufacturerName?: string | null;
    partId?: string | null;
    locationId?: string | null;
    workPhaseId?: string | null;
    scopePackageId?: string | null;
    workPackageId?: string | null;
    kitPlanId?: string | null;
    spoolId?: string | null;
    lineItemName?: string | null;
    manufacturerCatalogNumber?: string | null;
    quantity?: number | null;
    remainingQuantity?: number | null;
    unitQuantity?: number | null;
    tradeSize?: string | null;
    createdOn?: string | null;
    createdBy?: string | null;
    createdByFirstName?: string | null;
    childLineItemContainerName?: string | null;
    createdByLastName?: string | null;
    createdByEmail?: string | null;
    updatedOn?: string | null;
    updatedBy?: string | null;
    updatedByFirstName?: string | null;
    updatedByLastName?: string | null;
    updatedByEmail?: string | null;
    numberOfOpenDecisions?: number | null;
    decisionsHaveVariantDeletedFromCurrentVersionOfPart?: boolean | null;
    ableToMakeDecisions?: boolean | null;
    numberOfDecisionsMade?: number | null;
    hasChildNodes?: boolean | null;
    parentLineItem?: string | null;
    projectId?: string | null;
    assemblyCustomCategoryId?: string | null;
    assemblyCustomCategoryName?: string | null;
    canChildLineItemBeAdded?: boolean | null;
  } | null;
};

export type UpdateBillOfMaterialItemAssemblyCategoryByIdMutationVariables = {
  params: MutationUpdateBillOfMaterialItemAssemblyCategoryByIdParamsInput;
  query?: MutationUpdateBillOfMaterialItemAssemblyCategoryByIdQueryInput | null;
  body?: MutationUpdateBillOfMaterialItemAssemblyCategoryByIdBodyInput | null;
};

export type UpdateBillOfMaterialItemAssemblyCategoryByIdMutation = {
  updateBillOfMaterialItemAssemblyCategoryById?: {
    __typename: 'BillOfMaterialItem';
    lineItemId: string;
    lineItemTypeId: string;
    lineItemTypeName?: string | null;
    billOfMaterialsId?: string | null;
    unitOfMeasureId?: string | null;
    unitOfMeasureName?: string | null;
    unitOfMeasureCode?: string | null;
    manufacturerId?: string | null;
    manufacturerName?: string | null;
    partId?: string | null;
    locationId?: string | null;
    workPhaseId?: string | null;
    scopePackageId?: string | null;
    workPackageId?: string | null;
    kitPlanId?: string | null;
    spoolId?: string | null;
    lineItemName?: string | null;
    manufacturerCatalogNumber?: string | null;
    quantity?: number | null;
    remainingQuantity?: number | null;
    unitQuantity?: number | null;
    tradeSize?: string | null;
    createdOn?: string | null;
    createdBy?: string | null;
    createdByFirstName?: string | null;
    childLineItemContainerName?: string | null;
    createdByLastName?: string | null;
    createdByEmail?: string | null;
    updatedOn?: string | null;
    updatedBy?: string | null;
    updatedByFirstName?: string | null;
    updatedByLastName?: string | null;
    updatedByEmail?: string | null;
    numberOfOpenDecisions?: number | null;
    decisionsHaveVariantDeletedFromCurrentVersionOfPart?: boolean | null;
    ableToMakeDecisions?: boolean | null;
    numberOfDecisionsMade?: number | null;
    hasChildNodes?: boolean | null;
    parentLineItem?: string | null;
    projectId?: string | null;
    assemblyCustomCategoryId?: string | null;
    assemblyCustomCategoryName?: string | null;
    canChildLineItemBeAdded?: boolean | null;
  } | null;
};

export type UpdateDecisionsTakeoffMutationVariables = {
  query?: MutationUpdateDecisionsTakeoffQueryInput | null;
  body?: MutationUpdateDecisionsTakeoffBodyInput | null;
};

export type UpdateDecisionsTakeoffMutation = {
  updateDecisionsTakeoff?: {
    __typename: 'TakeOffPadItem';
    lineItemId: string;
    lineItemTypeId: string;
    lineItemTypeName?: string | null;
    takeoffPadId: string;
    unitOfMeasureId?: string | null;
    unitOfMeasureName?: string | null;
    unitOfMeasureCode?: string | null;
    manufacturerId?: string | null;
    manufacturerName?: string | null;
    partId?: string | null;
    locationId?: string | null;
    workPhaseId?: string | null;
    scopePackageId?: string | null;
    workPackageId?: string | null;
    kitPlanId?: string | null;
    spoolId?: string | null;
    lineItemName?: string | null;
    manufacturerCatalogNumber?: string | null;
    quantity?: number | null;
    remainingQuantity?: number | null;
    unitQuantity?: number | null;
    tradeSize?: string | null;
    billOfMaterialsName?: string | null;
    createdOn?: string | null;
    createdBy?: string | null;
    createdByFirstName?: string | null;
    createdByLastName?: string | null;
    createdByEmail?: string | null;
    updatedOn?: string | null;
    updatedBy?: string | null;
    updatedByFirstName?: string | null;
    updatedByLastName?: string | null;
    updatedByEmail?: string | null;
    numberOfOpenDecisions?: number | null;
    decisionsHaveVariantDeletedFromCurrentVersionOfPart?: boolean | null;
    ableToMakeDecisions?: boolean | null;
    numberOfDecisionsMade?: number | null;
  } | null;
};

export type RenameBillOfMaterialsMutationVariables = {
  params: MutationRenameBillOfMaterialsParamsInput;
  query?: MutationRenameBillOfMaterialsQueryInput | null;
  body?: MutationRenameBillOfMaterialsBodyInput | null;
};

export type RenameBillOfMaterialsMutation = {
  renameBillOfMaterials?: {
    __typename: 'BillOfMaterial';
    billOfMaterialsId: string;
    billOfMaterialsStatusId?: string | null;
    billOfMaterialsStatusName?: string | null;
    projectId: string;
    billOfMaterialsName: string;
    createdOn: string;
    createdBy: string;
    modifiedOn?: string | null;
    modifiedBy?: string | null;
    createdByFirstName?: string | null;
    createdByLastName?: string | null;
    createdByEmail?: string | null;
    updatedOn?: string | null;
    updatedBy?: string | null;
    updatedByFirstName?: string | null;
    updatedByLastName?: string | null;
    updatedByEmail?: string | null;
  } | null;
};

export type CloseBillOfMaterialsMutationVariables = {
  params: MutationCloseBillOfMaterialsParamsInput;
};

export type CloseBillOfMaterialsMutation = {
  closeBillOfMaterials?: boolean | null;
};

export type OpenBillOfMaterialsMutationVariables = {
  params: MutationOpenBillOfMaterialsParamsInput;
};

export type OpenBillOfMaterialsMutation = {
  openBillOfMaterials?: boolean | null;
};

export type AddManufacturerMutationVariables = {
  query?: MutationAddManufacturerQueryInput | null;
  body?: MutationAddManufacturerBodyInput | null;
};

export type AddManufacturerMutation = {
  addManufacturer?: {
    __typename: 'Manufactured';
    manufacturerId: string;
    companyId?: string | null;
    manufacturerName: string;
    homePageUrl?: string | null;
  } | null;
};

export type AddPartMutationVariables = {
  query?: MutationAddPartQueryInput | null;
  body?: MutationAddPartBodyInput | null;
};

export type AddPartMutation = {
  addPart?: {
    __typename: 'Part';
    partId: string;
    partName: string;
    description?: string | null;
    stateId?: string | null;
    isDefault?: boolean | null;
    manufacturerCatalogNumber?: string | null;
    unitOfMeasureCode?: string | null;
    unitOfMeasureName?: string | null;
    upcCode?: string | null;
    url?: string | null;
    catalogUrl?: string | null;
    partUrl?: string | null;
    internalPartNumber?: string | null;
    createdOn?: string | null;
    hasAssembly?: boolean | null;
    manufacturer?: {
      __typename: 'Manufactured';
      manufacturerId: string;
      companyId?: string | null;
      manufacturerName: string;
      homePageUrl?: string | null;
    } | null;
    attriubuteVariantData?: Array<{
      __typename: 'PartAttributeVariantData';
      partAttributeVariantDataId?: string | null;
      partAttributeId?: string | null;
      partAttributeName?: string | null;
      rangeValue?: number | null;
      part?: {
        __typename: 'Part';
        partId: string;
        partName: string;
        description?: string | null;
        stateId?: string | null;
        isDefault?: boolean | null;
        manufacturerCatalogNumber?: string | null;
        unitOfMeasureCode?: string | null;
        unitOfMeasureName?: string | null;
        upcCode?: string | null;
        url?: string | null;
        catalogUrl?: string | null;
        partUrl?: string | null;
        internalPartNumber?: string | null;
        createdOn?: string | null;
        hasAssembly?: boolean | null;
        manufacturer?: {
          __typename: 'Manufactured';
          manufacturerId: string;
          companyId?: string | null;
          manufacturerName: string;
          homePageUrl?: string | null;
        } | null;
        attriubuteVariantData?: Array<{
          __typename: 'PartAttributeVariantData';
          partAttributeVariantDataId?: string | null;
          partAttributeId?: string | null;
          partAttributeName?: string | null;
          rangeValue?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          partAttribute?: {
            __typename: 'PartAttribute';
            partAttributeId: string;
            partAttributeName: string;
            allowWriteInVariant?: boolean | null;
            rangeMinimumValue?: number | null;
            rangeMaximumValue?: number | null;
            selectVariants?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
            partAttributeType?: {
              __typename: 'PartAttributeType';
              partAttributeTypeId: string;
              partAttributeTypeName: string;
              partAttributeTypeDescription?: string | null;
              sortOrder?: number | null;
            } | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
          } | null;
          selectValues?: Array<{
            __typename: 'PartAttributeSelectVariant';
            partAttributeSelectVariantId?: string | null;
            textValue?: string | null;
            codeValue?: string | null;
            isDeleted?: boolean | null;
          } | null> | null;
        } | null> | null;
        partCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        partImages?: Array<{
          __typename: 'PartImage';
          partImageId?: string | null;
          sortOrder?: number | null;
          isDefault?: boolean | null;
          document?: {
            __typename: 'Document';
            documentId: string;
            documentName?: string | null;
            documentIdentifier?: string | null;
            documentTypeId?: string | null;
            storagePath?: string | null;
            extension?: string | null;
            encodedUrn?: string | null;
            documentStatusTypeId?: string | null;
            isMappedFromShop?: boolean | null;
            folderDocumentResponse?: Array<{
              __typename: 'FolderDocument';
              folderDocumentId: string;
              folderId: string;
              folderName?: string | null;
              documentId: string;
            } | null> | null;
            documentNameFormatted?: string | null;
            createdOn?: string | null;
            createdBy?: string | null;
          } | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
        } | null> | null;
        documentIds?: Array<string | null> | null;
        unitOfMeasure?: {
          __typename: 'UnitOfMeasure';
          unitOfMeasureId: string;
          unitOfMeasureTypeId?: string | null;
          companyId?: string | null;
          unitOfMeasureName: string;
          unitOfMeasureCode: string;
          createdOn?: string | null;
          createdBy?: string | null;
          updatedOn?: string | null;
          modifiedBy?: string | null;
          modifiedOn?: string | null;
        } | null;
      } | null;
      partAttribute?: {
        __typename: 'PartAttribute';
        partAttributeId: string;
        partAttributeName: string;
        allowWriteInVariant?: boolean | null;
        rangeMinimumValue?: number | null;
        rangeMaximumValue?: number | null;
        selectVariants?: Array<{
          __typename: 'PartAttributeSelectVariant';
          partAttributeSelectVariantId?: string | null;
          textValue?: string | null;
          codeValue?: string | null;
          isDeleted?: boolean | null;
        } | null> | null;
        partAttributeType?: {
          __typename: 'PartAttributeType';
          partAttributeTypeId: string;
          partAttributeTypeName: string;
          partAttributeTypeDescription?: string | null;
          sortOrder?: number | null;
        } | null;
        partCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
      } | null;
      selectValues?: Array<{
        __typename: 'PartAttributeSelectVariant';
        partAttributeSelectVariantId?: string | null;
        textValue?: string | null;
        codeValue?: string | null;
        isDeleted?: boolean | null;
      } | null> | null;
    } | null> | null;
    partCategory?: {
      __typename: 'PartCategory';
      partCategoryId?: string | null;
      partCategoryName?: string | null;
      partCatalog?: {
        __typename: 'PartCatalog';
        partCatalogId: string;
        partCatalogName: string;
        companyId: string;
        topLevelNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      parentPartCategory?: {
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      subNodes?: Array<{
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null;
    partImages?: Array<{
      __typename: 'PartImage';
      partImageId?: string | null;
      sortOrder?: number | null;
      isDefault?: boolean | null;
      document?: {
        __typename: 'Document';
        documentId: string;
        documentName?: string | null;
        documentIdentifier?: string | null;
        documentTypeId?: string | null;
        storagePath?: string | null;
        extension?: string | null;
        encodedUrn?: string | null;
        documentStatusTypeId?: string | null;
        isMappedFromShop?: boolean | null;
        folderDocumentResponse?: Array<{
          __typename: 'FolderDocument';
          folderDocumentId: string;
          folderId: string;
          folderName?: string | null;
          documentId: string;
        } | null> | null;
        documentNameFormatted?: string | null;
        createdOn?: string | null;
        createdBy?: string | null;
      } | null;
      part?: {
        __typename: 'Part';
        partId: string;
        partName: string;
        description?: string | null;
        stateId?: string | null;
        isDefault?: boolean | null;
        manufacturerCatalogNumber?: string | null;
        unitOfMeasureCode?: string | null;
        unitOfMeasureName?: string | null;
        upcCode?: string | null;
        url?: string | null;
        catalogUrl?: string | null;
        partUrl?: string | null;
        internalPartNumber?: string | null;
        createdOn?: string | null;
        hasAssembly?: boolean | null;
        manufacturer?: {
          __typename: 'Manufactured';
          manufacturerId: string;
          companyId?: string | null;
          manufacturerName: string;
          homePageUrl?: string | null;
        } | null;
        attriubuteVariantData?: Array<{
          __typename: 'PartAttributeVariantData';
          partAttributeVariantDataId?: string | null;
          partAttributeId?: string | null;
          partAttributeName?: string | null;
          rangeValue?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          partAttribute?: {
            __typename: 'PartAttribute';
            partAttributeId: string;
            partAttributeName: string;
            allowWriteInVariant?: boolean | null;
            rangeMinimumValue?: number | null;
            rangeMaximumValue?: number | null;
            selectVariants?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
            partAttributeType?: {
              __typename: 'PartAttributeType';
              partAttributeTypeId: string;
              partAttributeTypeName: string;
              partAttributeTypeDescription?: string | null;
              sortOrder?: number | null;
            } | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
          } | null;
          selectValues?: Array<{
            __typename: 'PartAttributeSelectVariant';
            partAttributeSelectVariantId?: string | null;
            textValue?: string | null;
            codeValue?: string | null;
            isDeleted?: boolean | null;
          } | null> | null;
        } | null> | null;
        partCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        partImages?: Array<{
          __typename: 'PartImage';
          partImageId?: string | null;
          sortOrder?: number | null;
          isDefault?: boolean | null;
          document?: {
            __typename: 'Document';
            documentId: string;
            documentName?: string | null;
            documentIdentifier?: string | null;
            documentTypeId?: string | null;
            storagePath?: string | null;
            extension?: string | null;
            encodedUrn?: string | null;
            documentStatusTypeId?: string | null;
            isMappedFromShop?: boolean | null;
            folderDocumentResponse?: Array<{
              __typename: 'FolderDocument';
              folderDocumentId: string;
              folderId: string;
              folderName?: string | null;
              documentId: string;
            } | null> | null;
            documentNameFormatted?: string | null;
            createdOn?: string | null;
            createdBy?: string | null;
          } | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
        } | null> | null;
        documentIds?: Array<string | null> | null;
        unitOfMeasure?: {
          __typename: 'UnitOfMeasure';
          unitOfMeasureId: string;
          unitOfMeasureTypeId?: string | null;
          companyId?: string | null;
          unitOfMeasureName: string;
          unitOfMeasureCode: string;
          createdOn?: string | null;
          createdBy?: string | null;
          updatedOn?: string | null;
          modifiedBy?: string | null;
          modifiedOn?: string | null;
        } | null;
      } | null;
    } | null> | null;
    documentIds?: Array<string | null> | null;
    unitOfMeasure?: {
      __typename: 'UnitOfMeasure';
      unitOfMeasureId: string;
      unitOfMeasureTypeId?: string | null;
      companyId?: string | null;
      unitOfMeasureName: string;
      unitOfMeasureCode: string;
      createdOn?: string | null;
      createdBy?: string | null;
      updatedOn?: string | null;
      modifiedBy?: string | null;
      modifiedOn?: string | null;
    } | null;
  } | null;
};

export type UpdatePartMutationVariables = {
  query?: MutationUpdatePartQueryInput | null;
  body?: MutationUpdatePartBodyInput | null;
};

export type UpdatePartMutation = {
  updatePart?: {
    __typename: 'Part';
    partId: string;
    partName: string;
    description?: string | null;
    stateId?: string | null;
    isDefault?: boolean | null;
    manufacturerCatalogNumber?: string | null;
    unitOfMeasureCode?: string | null;
    unitOfMeasureName?: string | null;
    upcCode?: string | null;
    url?: string | null;
    catalogUrl?: string | null;
    partUrl?: string | null;
    internalPartNumber?: string | null;
    createdOn?: string | null;
    hasAssembly?: boolean | null;
    manufacturer?: {
      __typename: 'Manufactured';
      manufacturerId: string;
      companyId?: string | null;
      manufacturerName: string;
      homePageUrl?: string | null;
    } | null;
    attriubuteVariantData?: Array<{
      __typename: 'PartAttributeVariantData';
      partAttributeVariantDataId?: string | null;
      partAttributeId?: string | null;
      partAttributeName?: string | null;
      rangeValue?: number | null;
      part?: {
        __typename: 'Part';
        partId: string;
        partName: string;
        description?: string | null;
        stateId?: string | null;
        isDefault?: boolean | null;
        manufacturerCatalogNumber?: string | null;
        unitOfMeasureCode?: string | null;
        unitOfMeasureName?: string | null;
        upcCode?: string | null;
        url?: string | null;
        catalogUrl?: string | null;
        partUrl?: string | null;
        internalPartNumber?: string | null;
        createdOn?: string | null;
        hasAssembly?: boolean | null;
        manufacturer?: {
          __typename: 'Manufactured';
          manufacturerId: string;
          companyId?: string | null;
          manufacturerName: string;
          homePageUrl?: string | null;
        } | null;
        attriubuteVariantData?: Array<{
          __typename: 'PartAttributeVariantData';
          partAttributeVariantDataId?: string | null;
          partAttributeId?: string | null;
          partAttributeName?: string | null;
          rangeValue?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          partAttribute?: {
            __typename: 'PartAttribute';
            partAttributeId: string;
            partAttributeName: string;
            allowWriteInVariant?: boolean | null;
            rangeMinimumValue?: number | null;
            rangeMaximumValue?: number | null;
            selectVariants?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
            partAttributeType?: {
              __typename: 'PartAttributeType';
              partAttributeTypeId: string;
              partAttributeTypeName: string;
              partAttributeTypeDescription?: string | null;
              sortOrder?: number | null;
            } | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
          } | null;
          selectValues?: Array<{
            __typename: 'PartAttributeSelectVariant';
            partAttributeSelectVariantId?: string | null;
            textValue?: string | null;
            codeValue?: string | null;
            isDeleted?: boolean | null;
          } | null> | null;
        } | null> | null;
        partCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        partImages?: Array<{
          __typename: 'PartImage';
          partImageId?: string | null;
          sortOrder?: number | null;
          isDefault?: boolean | null;
          document?: {
            __typename: 'Document';
            documentId: string;
            documentName?: string | null;
            documentIdentifier?: string | null;
            documentTypeId?: string | null;
            storagePath?: string | null;
            extension?: string | null;
            encodedUrn?: string | null;
            documentStatusTypeId?: string | null;
            isMappedFromShop?: boolean | null;
            folderDocumentResponse?: Array<{
              __typename: 'FolderDocument';
              folderDocumentId: string;
              folderId: string;
              folderName?: string | null;
              documentId: string;
            } | null> | null;
            documentNameFormatted?: string | null;
            createdOn?: string | null;
            createdBy?: string | null;
          } | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
        } | null> | null;
        documentIds?: Array<string | null> | null;
        unitOfMeasure?: {
          __typename: 'UnitOfMeasure';
          unitOfMeasureId: string;
          unitOfMeasureTypeId?: string | null;
          companyId?: string | null;
          unitOfMeasureName: string;
          unitOfMeasureCode: string;
          createdOn?: string | null;
          createdBy?: string | null;
          updatedOn?: string | null;
          modifiedBy?: string | null;
          modifiedOn?: string | null;
        } | null;
      } | null;
      partAttribute?: {
        __typename: 'PartAttribute';
        partAttributeId: string;
        partAttributeName: string;
        allowWriteInVariant?: boolean | null;
        rangeMinimumValue?: number | null;
        rangeMaximumValue?: number | null;
        selectVariants?: Array<{
          __typename: 'PartAttributeSelectVariant';
          partAttributeSelectVariantId?: string | null;
          textValue?: string | null;
          codeValue?: string | null;
          isDeleted?: boolean | null;
        } | null> | null;
        partAttributeType?: {
          __typename: 'PartAttributeType';
          partAttributeTypeId: string;
          partAttributeTypeName: string;
          partAttributeTypeDescription?: string | null;
          sortOrder?: number | null;
        } | null;
        partCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
      } | null;
      selectValues?: Array<{
        __typename: 'PartAttributeSelectVariant';
        partAttributeSelectVariantId?: string | null;
        textValue?: string | null;
        codeValue?: string | null;
        isDeleted?: boolean | null;
      } | null> | null;
    } | null> | null;
    partCategory?: {
      __typename: 'PartCategory';
      partCategoryId?: string | null;
      partCategoryName?: string | null;
      partCatalog?: {
        __typename: 'PartCatalog';
        partCatalogId: string;
        partCatalogName: string;
        companyId: string;
        topLevelNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      parentPartCategory?: {
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      subNodes?: Array<{
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null;
    partImages?: Array<{
      __typename: 'PartImage';
      partImageId?: string | null;
      sortOrder?: number | null;
      isDefault?: boolean | null;
      document?: {
        __typename: 'Document';
        documentId: string;
        documentName?: string | null;
        documentIdentifier?: string | null;
        documentTypeId?: string | null;
        storagePath?: string | null;
        extension?: string | null;
        encodedUrn?: string | null;
        documentStatusTypeId?: string | null;
        isMappedFromShop?: boolean | null;
        folderDocumentResponse?: Array<{
          __typename: 'FolderDocument';
          folderDocumentId: string;
          folderId: string;
          folderName?: string | null;
          documentId: string;
        } | null> | null;
        documentNameFormatted?: string | null;
        createdOn?: string | null;
        createdBy?: string | null;
      } | null;
      part?: {
        __typename: 'Part';
        partId: string;
        partName: string;
        description?: string | null;
        stateId?: string | null;
        isDefault?: boolean | null;
        manufacturerCatalogNumber?: string | null;
        unitOfMeasureCode?: string | null;
        unitOfMeasureName?: string | null;
        upcCode?: string | null;
        url?: string | null;
        catalogUrl?: string | null;
        partUrl?: string | null;
        internalPartNumber?: string | null;
        createdOn?: string | null;
        hasAssembly?: boolean | null;
        manufacturer?: {
          __typename: 'Manufactured';
          manufacturerId: string;
          companyId?: string | null;
          manufacturerName: string;
          homePageUrl?: string | null;
        } | null;
        attriubuteVariantData?: Array<{
          __typename: 'PartAttributeVariantData';
          partAttributeVariantDataId?: string | null;
          partAttributeId?: string | null;
          partAttributeName?: string | null;
          rangeValue?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          partAttribute?: {
            __typename: 'PartAttribute';
            partAttributeId: string;
            partAttributeName: string;
            allowWriteInVariant?: boolean | null;
            rangeMinimumValue?: number | null;
            rangeMaximumValue?: number | null;
            selectVariants?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
            partAttributeType?: {
              __typename: 'PartAttributeType';
              partAttributeTypeId: string;
              partAttributeTypeName: string;
              partAttributeTypeDescription?: string | null;
              sortOrder?: number | null;
            } | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
          } | null;
          selectValues?: Array<{
            __typename: 'PartAttributeSelectVariant';
            partAttributeSelectVariantId?: string | null;
            textValue?: string | null;
            codeValue?: string | null;
            isDeleted?: boolean | null;
          } | null> | null;
        } | null> | null;
        partCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        partImages?: Array<{
          __typename: 'PartImage';
          partImageId?: string | null;
          sortOrder?: number | null;
          isDefault?: boolean | null;
          document?: {
            __typename: 'Document';
            documentId: string;
            documentName?: string | null;
            documentIdentifier?: string | null;
            documentTypeId?: string | null;
            storagePath?: string | null;
            extension?: string | null;
            encodedUrn?: string | null;
            documentStatusTypeId?: string | null;
            isMappedFromShop?: boolean | null;
            folderDocumentResponse?: Array<{
              __typename: 'FolderDocument';
              folderDocumentId: string;
              folderId: string;
              folderName?: string | null;
              documentId: string;
            } | null> | null;
            documentNameFormatted?: string | null;
            createdOn?: string | null;
            createdBy?: string | null;
          } | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
        } | null> | null;
        documentIds?: Array<string | null> | null;
        unitOfMeasure?: {
          __typename: 'UnitOfMeasure';
          unitOfMeasureId: string;
          unitOfMeasureTypeId?: string | null;
          companyId?: string | null;
          unitOfMeasureName: string;
          unitOfMeasureCode: string;
          createdOn?: string | null;
          createdBy?: string | null;
          updatedOn?: string | null;
          modifiedBy?: string | null;
          modifiedOn?: string | null;
        } | null;
      } | null;
    } | null> | null;
    documentIds?: Array<string | null> | null;
    unitOfMeasure?: {
      __typename: 'UnitOfMeasure';
      unitOfMeasureId: string;
      unitOfMeasureTypeId?: string | null;
      companyId?: string | null;
      unitOfMeasureName: string;
      unitOfMeasureCode: string;
      createdOn?: string | null;
      createdBy?: string | null;
      updatedOn?: string | null;
      modifiedBy?: string | null;
      modifiedOn?: string | null;
    } | null;
  } | null;
};

export type DeletePartMutationVariables = {
  params: MutationDeletePartParamsInput;
};

export type DeletePartMutation = {
  deletePart?: boolean | null;
};

export type RemovePartsFromCategoryMutationVariables = {
  params: MutationRemovePartsFromCategoryParamsInput;
  query?: MutationRemovePartsFromCategoryQueryInput | null;
  body?: MutationRemovePartsFromCategoryBodyInput | null;
};

export type RemovePartsFromCategoryMutation = {
  removePartsFromCategory?: {
    __typename: 'PartCategory';
    partCategoryId?: string | null;
    partCategoryName?: string | null;
    partCatalog?: {
      __typename: 'PartCatalog';
      partCatalogId: string;
      partCatalogName: string;
      companyId: string;
      topLevelNodes?: Array<{
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null;
    parentPartCategory?: {
      __typename: 'PartCategory';
      partCategoryId?: string | null;
      partCategoryName?: string | null;
      partCatalog?: {
        __typename: 'PartCatalog';
        partCatalogId: string;
        partCatalogName: string;
        companyId: string;
        topLevelNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      parentPartCategory?: {
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      subNodes?: Array<{
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null;
    subNodes?: Array<{
      __typename: 'PartCategory';
      partCategoryId?: string | null;
      partCategoryName?: string | null;
      partCatalog?: {
        __typename: 'PartCatalog';
        partCatalogId: string;
        partCatalogName: string;
        companyId: string;
        topLevelNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      parentPartCategory?: {
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      subNodes?: Array<{
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type RemovePartAttributesFromCategoryMutationVariables = {
  params: MutationRemovePartAttributesFromCategoryParamsInput;
  query?: MutationRemovePartAttributesFromCategoryQueryInput | null;
  body?: MutationRemovePartAttributesFromCategoryBodyInput | null;
};

export type RemovePartAttributesFromCategoryMutation = {
  removePartAttributesFromCategory?: {
    __typename: 'PartCategory';
    partCategoryId?: string | null;
    partCategoryName?: string | null;
    partCatalog?: {
      __typename: 'PartCatalog';
      partCatalogId: string;
      partCatalogName: string;
      companyId: string;
      topLevelNodes?: Array<{
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null;
    parentPartCategory?: {
      __typename: 'PartCategory';
      partCategoryId?: string | null;
      partCategoryName?: string | null;
      partCatalog?: {
        __typename: 'PartCatalog';
        partCatalogId: string;
        partCatalogName: string;
        companyId: string;
        topLevelNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      parentPartCategory?: {
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      subNodes?: Array<{
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null;
    subNodes?: Array<{
      __typename: 'PartCategory';
      partCategoryId?: string | null;
      partCategoryName?: string | null;
      partCatalog?: {
        __typename: 'PartCatalog';
        partCatalogId: string;
        partCatalogName: string;
        companyId: string;
        topLevelNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      parentPartCategory?: {
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      subNodes?: Array<{
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type AddPartImageWithDocumentIdMutationVariables = {
  query?: MutationAddPartImageWithDocumentIdQueryInput | null;
  body?: MutationAddPartImageWithDocumentIdBodyInput | null;
};

export type AddPartImageWithDocumentIdMutation = {
  addPartImageWithDocumentId?: {
    __typename: 'PartImage';
    partImageId?: string | null;
    sortOrder?: number | null;
    isDefault?: boolean | null;
    document?: {
      __typename: 'Document';
      documentId: string;
      documentName?: string | null;
      documentIdentifier?: string | null;
      documentTypeId?: string | null;
      storagePath?: string | null;
      extension?: string | null;
      encodedUrn?: string | null;
      documentStatusTypeId?: string | null;
      isMappedFromShop?: boolean | null;
      folderDocumentResponse?: Array<{
        __typename: 'FolderDocument';
        folderDocumentId: string;
        folderId: string;
        folderName?: string | null;
        documentId: string;
      } | null> | null;
      documentNameFormatted?: string | null;
      createdOn?: string | null;
      createdBy?: string | null;
    } | null;
    part?: {
      __typename: 'Part';
      partId: string;
      partName: string;
      description?: string | null;
      stateId?: string | null;
      isDefault?: boolean | null;
      manufacturerCatalogNumber?: string | null;
      unitOfMeasureCode?: string | null;
      unitOfMeasureName?: string | null;
      upcCode?: string | null;
      url?: string | null;
      catalogUrl?: string | null;
      partUrl?: string | null;
      internalPartNumber?: string | null;
      createdOn?: string | null;
      hasAssembly?: boolean | null;
      manufacturer?: {
        __typename: 'Manufactured';
        manufacturerId: string;
        companyId?: string | null;
        manufacturerName: string;
        homePageUrl?: string | null;
      } | null;
      attriubuteVariantData?: Array<{
        __typename: 'PartAttributeVariantData';
        partAttributeVariantDataId?: string | null;
        partAttributeId?: string | null;
        partAttributeName?: string | null;
        rangeValue?: number | null;
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
        partAttribute?: {
          __typename: 'PartAttribute';
          partAttributeId: string;
          partAttributeName: string;
          allowWriteInVariant?: boolean | null;
          rangeMinimumValue?: number | null;
          rangeMaximumValue?: number | null;
          selectVariants?: Array<{
            __typename: 'PartAttributeSelectVariant';
            partAttributeSelectVariantId?: string | null;
            textValue?: string | null;
            codeValue?: string | null;
            isDeleted?: boolean | null;
          } | null> | null;
          partAttributeType?: {
            __typename: 'PartAttributeType';
            partAttributeTypeId: string;
            partAttributeTypeName: string;
            partAttributeTypeDescription?: string | null;
            sortOrder?: number | null;
          } | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
        } | null;
        selectValues?: Array<{
          __typename: 'PartAttributeSelectVariant';
          partAttributeSelectVariantId?: string | null;
          textValue?: string | null;
          codeValue?: string | null;
          isDeleted?: boolean | null;
        } | null> | null;
      } | null> | null;
      partCategory?: {
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      partImages?: Array<{
        __typename: 'PartImage';
        partImageId?: string | null;
        sortOrder?: number | null;
        isDefault?: boolean | null;
        document?: {
          __typename: 'Document';
          documentId: string;
          documentName?: string | null;
          documentIdentifier?: string | null;
          documentTypeId?: string | null;
          storagePath?: string | null;
          extension?: string | null;
          encodedUrn?: string | null;
          documentStatusTypeId?: string | null;
          isMappedFromShop?: boolean | null;
          folderDocumentResponse?: Array<{
            __typename: 'FolderDocument';
            folderDocumentId: string;
            folderId: string;
            folderName?: string | null;
            documentId: string;
          } | null> | null;
          documentNameFormatted?: string | null;
          createdOn?: string | null;
          createdBy?: string | null;
        } | null;
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
      } | null> | null;
      documentIds?: Array<string | null> | null;
      unitOfMeasure?: {
        __typename: 'UnitOfMeasure';
        unitOfMeasureId: string;
        unitOfMeasureTypeId?: string | null;
        companyId?: string | null;
        unitOfMeasureName: string;
        unitOfMeasureCode: string;
        createdOn?: string | null;
        createdBy?: string | null;
        updatedOn?: string | null;
        modifiedBy?: string | null;
        modifiedOn?: string | null;
      } | null;
    } | null;
  } | null;
};

export type UpdatePartImageWithDocumentIdMutationVariables = {
  params: MutationUpdatePartImageWithDocumentIdParamsInput;
  query?: MutationUpdatePartImageWithDocumentIdQueryInput | null;
  body?: MutationUpdatePartImageWithDocumentIdBodyInput | null;
};

export type UpdatePartImageWithDocumentIdMutation = {
  updatePartImageWithDocumentId?: {
    __typename: 'PartImage';
    partImageId?: string | null;
    sortOrder?: number | null;
    isDefault?: boolean | null;
    document?: {
      __typename: 'Document';
      documentId: string;
      documentName?: string | null;
      documentIdentifier?: string | null;
      documentTypeId?: string | null;
      storagePath?: string | null;
      extension?: string | null;
      encodedUrn?: string | null;
      documentStatusTypeId?: string | null;
      isMappedFromShop?: boolean | null;
      folderDocumentResponse?: Array<{
        __typename: 'FolderDocument';
        folderDocumentId: string;
        folderId: string;
        folderName?: string | null;
        documentId: string;
      } | null> | null;
      documentNameFormatted?: string | null;
      createdOn?: string | null;
      createdBy?: string | null;
    } | null;
    part?: {
      __typename: 'Part';
      partId: string;
      partName: string;
      description?: string | null;
      stateId?: string | null;
      isDefault?: boolean | null;
      manufacturerCatalogNumber?: string | null;
      unitOfMeasureCode?: string | null;
      unitOfMeasureName?: string | null;
      upcCode?: string | null;
      url?: string | null;
      catalogUrl?: string | null;
      partUrl?: string | null;
      internalPartNumber?: string | null;
      createdOn?: string | null;
      hasAssembly?: boolean | null;
      manufacturer?: {
        __typename: 'Manufactured';
        manufacturerId: string;
        companyId?: string | null;
        manufacturerName: string;
        homePageUrl?: string | null;
      } | null;
      attriubuteVariantData?: Array<{
        __typename: 'PartAttributeVariantData';
        partAttributeVariantDataId?: string | null;
        partAttributeId?: string | null;
        partAttributeName?: string | null;
        rangeValue?: number | null;
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
        partAttribute?: {
          __typename: 'PartAttribute';
          partAttributeId: string;
          partAttributeName: string;
          allowWriteInVariant?: boolean | null;
          rangeMinimumValue?: number | null;
          rangeMaximumValue?: number | null;
          selectVariants?: Array<{
            __typename: 'PartAttributeSelectVariant';
            partAttributeSelectVariantId?: string | null;
            textValue?: string | null;
            codeValue?: string | null;
            isDeleted?: boolean | null;
          } | null> | null;
          partAttributeType?: {
            __typename: 'PartAttributeType';
            partAttributeTypeId: string;
            partAttributeTypeName: string;
            partAttributeTypeDescription?: string | null;
            sortOrder?: number | null;
          } | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
        } | null;
        selectValues?: Array<{
          __typename: 'PartAttributeSelectVariant';
          partAttributeSelectVariantId?: string | null;
          textValue?: string | null;
          codeValue?: string | null;
          isDeleted?: boolean | null;
        } | null> | null;
      } | null> | null;
      partCategory?: {
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      partImages?: Array<{
        __typename: 'PartImage';
        partImageId?: string | null;
        sortOrder?: number | null;
        isDefault?: boolean | null;
        document?: {
          __typename: 'Document';
          documentId: string;
          documentName?: string | null;
          documentIdentifier?: string | null;
          documentTypeId?: string | null;
          storagePath?: string | null;
          extension?: string | null;
          encodedUrn?: string | null;
          documentStatusTypeId?: string | null;
          isMappedFromShop?: boolean | null;
          folderDocumentResponse?: Array<{
            __typename: 'FolderDocument';
            folderDocumentId: string;
            folderId: string;
            folderName?: string | null;
            documentId: string;
          } | null> | null;
          documentNameFormatted?: string | null;
          createdOn?: string | null;
          createdBy?: string | null;
        } | null;
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
      } | null> | null;
      documentIds?: Array<string | null> | null;
      unitOfMeasure?: {
        __typename: 'UnitOfMeasure';
        unitOfMeasureId: string;
        unitOfMeasureTypeId?: string | null;
        companyId?: string | null;
        unitOfMeasureName: string;
        unitOfMeasureCode: string;
        createdOn?: string | null;
        createdBy?: string | null;
        updatedOn?: string | null;
        modifiedBy?: string | null;
        modifiedOn?: string | null;
      } | null;
    } | null;
  } | null;
};

export type DeletePartImageMutationVariables = {
  params: MutationDeletePartImageParamsInput;
};

export type DeletePartImageMutation = {
  deletePartImage?: boolean | null;
};

export type AddUnitOfMeasureMutationVariables = {
  query?: MutationAddUnitOfMeasureQueryInput | null;
  body?: MutationAddUnitOfMeasureBodyInput | null;
};

export type AddUnitOfMeasureMutation = {
  addUnitOfMeasure?: {
    __typename: 'UnitOfMeasure';
    unitOfMeasureId: string;
    unitOfMeasureTypeId?: string | null;
    companyId?: string | null;
    unitOfMeasureName: string;
    unitOfMeasureCode: string;
    createdOn?: string | null;
    createdBy?: string | null;
    updatedOn?: string | null;
    modifiedBy?: string | null;
    modifiedOn?: string | null;
  } | null;
};

export type AddTakeoffPadMutationVariables = {
  query?: MutationAddTakeoffPadQueryInput | null;
  body?: MutationAddTakeoffPadBodyInput | null;
};

export type AddTakeoffPadMutation = {
  addTakeoffPad?: {
    __typename: 'TakeOffPad';
    takeoffPadId: string;
    takeoffPadName: string;
    lineItemContainerId?: string | null;
    lineItemContainerStatusId?: string | null;
    lineItemContainerStatusName?: string | null;
    projectId: string;
    lineItemContainerName?: string | null;
    hasMappedItems?: boolean | null;
    hasItemsMappedFromSourcePad?: boolean | null;
    createdOn: string;
    createdBy: string;
    createdByFirstName?: string | null;
    createdByLastName?: string | null;
    createdByEmail?: string | null;
    updatedOn?: string | null;
    updatedBy?: string | null;
    updatedByFirstName?: string | null;
    updatedByLastName?: string | null;
    updatedByEmail?: string | null;
  } | null;
};

export type MoveTakeoffPadItemsMutationVariables = {
  query?: MutationMoveTakeoffPadItemsQueryInput | null;
  body?: MutationMoveTakeoffPadItemsBodyInput | null;
};

export type MoveTakeoffPadItemsMutation = {
  moveTakeoffPadItems?: Array<{
    __typename: 'SourcePadItem';
    previousLineItemId?: string | null;
    lineItemId?: string | null;
    lineItemTypeId?: string | null;
    lineItemTypeName?: string | null;
    sourcePadId?: string | null;
    unitOfMeasureId?: string | null;
    unitOfMeasureName?: string | null;
    unitOfMeasureCode?: string | null;
    manufacturerId?: string | null;
    manufacturerName?: string | null;
    partId?: string | null;
    locationId?: string | null;
    workPhaseId?: string | null;
    scopePackageId?: string | null;
    workPackageId?: string | null;
    kitPlanId?: string | null;
    spoolId?: string | null;
    lineItemName?: string | null;
    manufacturerCatalogNumber?: string | null;
    quantity?: number | null;
    actualQuantity?: number | null;
    remainingQuantity?: number | null;
    actualRemainingQuantity?: number | null;
    unitQuantity?: number | null;
    tradeSize?: string | null;
    takeoffPadName?: string | null;
    createdOn?: string | null;
    createdBy?: string | null;
    createdByFirstName?: string | null;
    createdByLastName?: string | null;
    createdByEmail?: string | null;
    updatedOn?: string | null;
    updatedBy?: string | null;
    updatedByFirstName?: string | null;
    updatedByLastName?: string | null;
    updatedByEmail?: string | null;
  } | null> | null;
};

export type DeleteTakeoffPadMutationVariables = {
  params: MutationDeleteTakeoffPadParamsInput;
};

export type DeleteTakeoffPadMutation = {
  deleteTakeoffPad?: boolean | null;
};

export type RenameTakeOffPadMutationVariables = {
  params: MutationRenameTakeOffPadParamsInput;
  query?: MutationRenameTakeOffPadQueryInput | null;
  body?: MutationRenameTakeOffPadBodyInput | null;
};

export type RenameTakeOffPadMutation = {
  renameTakeOffPad?: {
    __typename: 'TakeOffPad';
    takeoffPadId: string;
    takeoffPadName: string;
    lineItemContainerId?: string | null;
    lineItemContainerStatusId?: string | null;
    lineItemContainerStatusName?: string | null;
    projectId: string;
    lineItemContainerName?: string | null;
    hasMappedItems?: boolean | null;
    hasItemsMappedFromSourcePad?: boolean | null;
    createdOn: string;
    createdBy: string;
    createdByFirstName?: string | null;
    createdByLastName?: string | null;
    createdByEmail?: string | null;
    updatedOn?: string | null;
    updatedBy?: string | null;
    updatedByFirstName?: string | null;
    updatedByLastName?: string | null;
    updatedByEmail?: string | null;
  } | null;
};

export type AddTakeOffPadItemMutationVariables = {
  query?: MutationAddTakeOffPadItemQueryInput | null;
  body?: MutationAddTakeOffPadItemBodyInput | null;
};

export type AddTakeOffPadItemMutation = {
  addTakeOffPadItem?: Array<{
    __typename: 'TakeOffPadItem';
    lineItemId: string;
    lineItemTypeId: string;
    lineItemTypeName?: string | null;
    takeoffPadId: string;
    unitOfMeasureId?: string | null;
    unitOfMeasureName?: string | null;
    unitOfMeasureCode?: string | null;
    manufacturerId?: string | null;
    manufacturerName?: string | null;
    partId?: string | null;
    locationId?: string | null;
    workPhaseId?: string | null;
    scopePackageId?: string | null;
    workPackageId?: string | null;
    kitPlanId?: string | null;
    spoolId?: string | null;
    lineItemName?: string | null;
    manufacturerCatalogNumber?: string | null;
    quantity?: number | null;
    remainingQuantity?: number | null;
    unitQuantity?: number | null;
    tradeSize?: string | null;
    billOfMaterialsName?: string | null;
    createdOn?: string | null;
    createdBy?: string | null;
    createdByFirstName?: string | null;
    createdByLastName?: string | null;
    createdByEmail?: string | null;
    updatedOn?: string | null;
    updatedBy?: string | null;
    updatedByFirstName?: string | null;
    updatedByLastName?: string | null;
    updatedByEmail?: string | null;
    numberOfOpenDecisions?: number | null;
    decisionsHaveVariantDeletedFromCurrentVersionOfPart?: boolean | null;
    ableToMakeDecisions?: boolean | null;
    numberOfDecisionsMade?: number | null;
  } | null> | null;
};

export type DeleteTakeOffPadItemMutationVariables = {
  query?: MutationDeleteTakeOffPadItemQueryInput | null;
  body?: MutationDeleteTakeOffPadItemBodyInput | null;
};

export type DeleteTakeOffPadItemMutation = {
  deleteTakeOffPadItem?: boolean | null;
};

export type AddShopConfigurationMutationVariables = {
  query?: MutationAddShopConfigurationQueryInput | null;
  body?: MutationAddShopConfigurationBodyInput | null;
};

export type AddShopConfigurationMutation = {
  addShopConfiguration?: {
    __typename: 'ShopConfiguration';
    shopConfigurationId?: string | null;
    shopConfigurationName?: string | null;
    shopConfigurationDescription?: string | null;
    departmentId?: string | null;
    facilityId?: string | null;
    isActive?: boolean | null;
  } | null;
};

export type UpdateShopConfigurationMutationVariables = {
  params: MutationUpdateShopConfigurationParamsInput;
  query?: MutationUpdateShopConfigurationQueryInput | null;
  body?: MutationUpdateShopConfigurationBodyInput | null;
};

export type UpdateShopConfigurationMutation = {
  updateShopConfiguration?: {
    __typename: 'ShopConfiguration';
    shopConfigurationId?: string | null;
    shopConfigurationName?: string | null;
    shopConfigurationDescription?: string | null;
    departmentId?: string | null;
    facilityId?: string | null;
    isActive?: boolean | null;
  } | null;
};

export type DeleteShopConfigurationMutationVariables = {
  params: MutationDeleteShopConfigurationParamsInput;
};

export type DeleteShopConfigurationMutation = {
  deleteShopConfiguration?: boolean | null;
};

export type AddPartAttributeSelectVariantMutationVariables = {
  query?: MutationAddPartAttributeSelectVariantQueryInput | null;
  body?: MutationAddPartAttributeSelectVariantBodyInput | null;
};

export type AddPartAttributeSelectVariantMutation = {
  addPartAttributeSelectVariant?: {
    __typename: 'PartAttributeSelectVariant';
    partAttributeSelectVariantId?: string | null;
    textValue?: string | null;
    codeValue?: string | null;
    isDeleted?: boolean | null;
  } | null;
};

export type AddShopPropertyMutationVariables = {
  query?: MutationAddShopPropertyQueryInput | null;
  body?: MutationAddShopPropertyBodyInput | null;
};

export type AddShopPropertyMutation = {
  addShopProperty?: {
    __typename: 'ShopProperty';
    shopPropertyId?: string | null;
    shopPropertyName?: string | null;
    shopPropertyDescription?: string | null;
    shippingAddressLine1?: string | null;
    shippingAddressLine2?: string | null;
    city?: string | null;
    state?: string | null;
    zip?: string | null;
    country?: string | null;
    userId?: string | null;
    shopConfigurationId?: string | null;
    facilityId?: string | null;
  } | null;
};

export type UpdateShopPropertyMutationVariables = {
  params: MutationUpdateShopPropertyParamsInput;
  query?: MutationUpdateShopPropertyQueryInput | null;
  body?: MutationUpdateShopPropertyBodyInput | null;
};

export type UpdateShopPropertyMutation = {
  updateShopProperty?: {
    __typename: 'ShopProperty';
    shopPropertyId?: string | null;
    shopPropertyName?: string | null;
    shopPropertyDescription?: string | null;
    shippingAddressLine1?: string | null;
    shippingAddressLine2?: string | null;
    city?: string | null;
    state?: string | null;
    zip?: string | null;
    country?: string | null;
    userId?: string | null;
    shopConfigurationId?: string | null;
    facilityId?: string | null;
  } | null;
};

export type AddWorkCellMutationVariables = {
  query?: MutationAddWorkCellQueryInput | null;
  body?: MutationAddWorkCellBodyInput | null;
};

export type AddWorkCellMutation = {
  addWorkCell?: {
    __typename: 'WorkCell';
    workCellId: string;
    workCellName: string;
    workCellDescription?: string | null;
    facilityId: string;
    shopConfigurationId: string;
    hasTaskTypes: boolean;
    hasMachines: boolean;
  } | null;
};

export type UpdateWorkCellMutationVariables = {
  params: MutationUpdateWorkCellParamsInput;
  query?: MutationUpdateWorkCellQueryInput | null;
  body?: MutationUpdateWorkCellBodyInput | null;
};

export type UpdateWorkCellMutation = {
  updateWorkCell?: {
    __typename: 'WorkCell';
    workCellId: string;
    workCellName: string;
    workCellDescription?: string | null;
    facilityId: string;
    shopConfigurationId: string;
    hasTaskTypes: boolean;
    hasMachines: boolean;
  } | null;
};

export type UpdateWorkCellTaskMutationVariables = {
  params: MutationUpdateWorkCellTaskParamsInput;
  query?: MutationUpdateWorkCellTaskQueryInput | null;
  body?: MutationUpdateWorkCellTaskBodyInput | null;
};

export type UpdateWorkCellTaskMutation = {
  updateWorkCellTask?: {
    __typename: 'UpdatedWorkCellTask';
    workCellTaskId?: string | null;
    workCellId?: string | null;
    taskId?: string | null;
    workOrderId?: string | null;
    sortOrder?: number | null;
  } | null;
};

export type DeleteWorkCellTaskMutationVariables = {
  params: MutationDeleteWorkCellTaskParamsInput;
};

export type DeleteWorkCellTaskMutation = {
  deleteWorkCellTask?: boolean | null;
};

export type DeleteWorkCellMutationVariables = {
  params: MutationDeleteWorkCellParamsInput;
};

export type DeleteWorkCellMutation = {
  deleteWorkCell?: boolean | null;
};

export type DeleteWorkCellTaskTypeMutationVariables = {
  params: MutationDeleteWorkCellTaskTypeParamsInput;
};

export type DeleteWorkCellTaskTypeMutation = {
  deleteWorkCellTaskType?: boolean | null;
};

export type DeleteMachineTaskTypeMutationVariables = {
  params: MutationDeleteMachineTaskTypeParamsInput;
};

export type DeleteMachineTaskTypeMutation = {
  deleteMachineTaskType?: boolean | null;
};

export type MapSourcePadItemMutationVariables = {
  query?: MutationMapSourcePadItemQueryInput | null;
  body?: MutationMapSourcePadItemBodyInput | null;
};

export type MapSourcePadItemMutation = {
  mapSourcePadItem?: boolean | null;
};

export type MapTaskTypeToWorkCellMutationVariables = {
  query?: MutationMapTaskTypeToWorkCellQueryInput | null;
  body?: MutationMapTaskTypeToWorkCellBodyInput | null;
};

export type MapTaskTypeToWorkCellMutation = {
  mapTaskTypeToWorkCell?: Array<{
    __typename: 'MappedWorkCellTaskType';
    workCellTaskTypeId: string;
    taskTypeId: string;
    workCellId: string;
  } | null> | null;
};

export type MapTaskTypeToMachineMutationVariables = {
  query?: MutationMapTaskTypeToMachineQueryInput | null;
  body?: MutationMapTaskTypeToMachineBodyInput | null;
};

export type MapTaskTypeToMachineMutation = {
  mapTaskTypeToMachine?: Array<{
    __typename: 'MappedMachineTaskType';
    machineTaskTypeId: string;
    taskTypeId: string;
    machineId: string;
  } | null> | null;
};

export type GetDocumentPresignedURLMutationVariables = {
  query?: MutationGetDocumentPresignedURLQueryInput | null;
  body?: MutationGetDocumentPresignedURLBodyInput | null;
};

export type GetDocumentPresignedURLMutation = {
  getDocumentPresignedURL?: {
    __typename: 'PresignedURL';
    objectKey?: string | null;
    expirationHours?: number | null;
    preSignedURL?: string | null;
    contentType?: string | null;
  } | null;
};

export type AddFolderMutationVariables = {
  query?: MutationAddFolderQueryInput | null;
  body?: MutationAddFolderBodyInput | null;
};

export type AddFolderMutation = {
  addFolder?: {
    __typename: 'Folder';
    folderId: string;
    parentFolderId?: string | null;
    companyId: string;
    folderName: string;
    storagePath: string;
    folderDescription?: string | null;
    hasSubFolder?: boolean | null;
    isHidden?: boolean | null;
    projectId: string;
  } | null;
};

export type UpdateFolderMutationVariables = {
  params: MutationUpdateFolderParamsInput;
  query?: MutationUpdateFolderQueryInput | null;
  body?: MutationUpdateFolderBodyInput | null;
};

export type UpdateFolderMutation = {
  updateFolder?: {
    __typename: 'Folder';
    folderId: string;
    parentFolderId?: string | null;
    companyId: string;
    folderName: string;
    storagePath: string;
    folderDescription?: string | null;
    hasSubFolder?: boolean | null;
    isHidden?: boolean | null;
    projectId: string;
  } | null;
};

export type DeleteFolderMutationVariables = {
  params: MutationDeleteFolderParamsInput;
};

export type DeleteFolderMutation = {
  deleteFolder?: boolean | null;
};

export type AddDocumentMutationVariables = {
  query?: MutationAddDocumentQueryInput | null;
  body?: MutationAddDocumentBodyInput | null;
};

export type AddDocumentMutation = {
  addDocument?: {
    __typename: 'Document';
    documentId: string;
    documentName?: string | null;
    documentIdentifier?: string | null;
    documentTypeId?: string | null;
    storagePath?: string | null;
    extension?: string | null;
    encodedUrn?: string | null;
    documentStatusTypeId?: string | null;
    isMappedFromShop?: boolean | null;
    folderDocumentResponse?: Array<{
      __typename: 'FolderDocument';
      folderDocumentId: string;
      folderId: string;
      folderName?: string | null;
      documentId: string;
    } | null> | null;
    documentNameFormatted?: string | null;
    createdOn?: string | null;
    createdBy?: string | null;
  } | null;
};

export type UpdateDocumentMutationVariables = {
  params: MutationUpdateDocumentParamsInput;
  query?: MutationUpdateDocumentQueryInput | null;
  body?: MutationUpdateDocumentBodyInput | null;
};

export type UpdateDocumentMutation = {
  updateDocument?: {
    __typename: 'Document';
    documentId: string;
    documentName?: string | null;
    documentIdentifier?: string | null;
    documentTypeId?: string | null;
    storagePath?: string | null;
    extension?: string | null;
    encodedUrn?: string | null;
    documentStatusTypeId?: string | null;
    isMappedFromShop?: boolean | null;
    folderDocumentResponse?: Array<{
      __typename: 'FolderDocument';
      folderDocumentId: string;
      folderId: string;
      folderName?: string | null;
      documentId: string;
    } | null> | null;
    documentNameFormatted?: string | null;
    createdOn?: string | null;
    createdBy?: string | null;
  } | null;
};

export type MapWorkPhaseToLocationMutationVariables = {
  query?: MutationMapWorkPhaseToLocationQueryInput | null;
  body?: MutationMapWorkPhaseToLocationBodyInput | null;
};

export type MapWorkPhaseToLocationMutation = {
  mapWorkPhaseToLocation?: {
    __typename: 'MapWorkPhasesToLocationResponse';
    locationId: string;
    location: {
      __typename: 'Location';
      locationId?: string | null;
      locationTypeId?: string | null;
      locationTypeName?: string | null;
      projectId?: string | null;
      locationIdentifier?: string | null;
      locationCompositeIdentifier?: string | null;
      sortOrder?: number | null;
      hasChildren?: boolean | null;
      parentLocationId?: string | null;
      locationName?: string | null;
      locationCompositeName?: string | null;
      locationPhases?: Array<{
        __typename: 'LocationPhase';
        locationPhaseId: string;
        locationId?: string | null;
        workPhaseId: string;
        workPhaseName: string;
        scopePackageId: string;
        scopePackageName: string;
        sortOrder: number;
      } | null> | null;
      childrenTypeName?: string | null;
      movedLocationPhases?: Array<{
        __typename: 'UpdatedLocationPhase';
        workPhaseId: string;
        locationPhaseId: string;
      } | null> | null;
    };
    workPhases: Array<{
      __typename: 'WorkPhase';
      workPhaseId: string;
      workPhaseIdentifier: string;
      workPhaseName: string;
      sortOrder: number;
      scopePackageId: string;
      scopePackageName?: string | null;
      projectId: string;
      hasLocationPhases?: boolean | null;
      updatedWorkPhaseLocationPhases?: Array<{
        __typename: 'MappedLocation';
        locationId: string;
        locationPhaseId: string;
      } | null> | null;
    } | null>;
  } | null;
};

export type UnmapWorkPhaseFromLocationMutationVariables = {
  params: MutationUnmapWorkPhaseFromLocationParamsInput;
};

export type UnmapWorkPhaseFromLocationMutation = {
  unmapWorkPhaseFromLocation?: boolean | null;
};

export type AddMachineMutationVariables = {
  query?: MutationAddMachineQueryInput | null;
  body?: MutationAddMachineBodyInput | null;
};

export type AddMachineMutation = {
  addMachine?: {
    __typename: 'Machine';
    machineId: string;
    manufacturer?: string | null;
    model?: string | null;
    serialNumber?: string | null;
    machineImageId?: string | null;
    facilityId: string;
    machineIdentifier: string;
    workCells?: Array<{
      __typename: 'MachineWorkCell';
      workCellId: string;
      shopConfigurationId: string;
    } | null> | null;
  } | null;
};

export type UpdateMachineMutationVariables = {
  params: MutationUpdateMachineParamsInput;
  query?: MutationUpdateMachineQueryInput | null;
  body?: MutationUpdateMachineBodyInput | null;
};

export type UpdateMachineMutation = {
  updateMachine?: {
    __typename: 'Machine';
    machineId: string;
    manufacturer?: string | null;
    model?: string | null;
    serialNumber?: string | null;
    machineImageId?: string | null;
    facilityId: string;
    machineIdentifier: string;
    workCells?: Array<{
      __typename: 'MachineWorkCell';
      workCellId: string;
      shopConfigurationId: string;
    } | null> | null;
  } | null;
};

export type DeleteWorkCellMachineMutationVariables = {
  params: MutationDeleteWorkCellMachineParamsInput;
};

export type DeleteWorkCellMachineMutation = {
  deleteWorkCellMachine?: boolean | null;
};

export type AddWorkCellMachineMutationVariables = {
  query?: MutationAddWorkCellMachineQueryInput | null;
  body?: MutationAddWorkCellMachineBodyInput | null;
};

export type AddWorkCellMachineMutation = {
  addWorkCellMachine?: Array<{
    __typename: 'WorkCellMachine';
    workCellMachineId: string;
    facilityId: string;
    shopConfigurationId: string;
    workCellId: string;
    machineId: string;
    machineManufacturer?: string | null;
    machineIdentifier?: string | null;
  } | null> | null;
};

export type SyncDeleteConnectionMutationVariables = {
  query?: MutationSyncDeleteConnectionQueryInput | null;
  body?: MutationSyncDeleteConnectionBodyInput | null;
};

export type SyncDeleteConnectionMutation = {
  syncDeleteConnection?: {
    __typename: 'DeletedConnectionList';
    connectionsInUsed?: Array<{
      __typename: 'ConnectorFieldItem';
      id?: string | null;
      name?: string | null;
    } | null> | null;
  } | null;
};

export type SyncCreateConnectionMutationVariables = {
  params: MutationSyncCreateConnectionParamsInput;
  query?: MutationSyncCreateConnectionQueryInput | null;
  body?: MutationSyncCreateConnectionBodyInput | null;
};

export type SyncCreateConnectionMutation = {
  syncCreateConnection?: boolean | null;
};

export type SyncUpdateConnectionMutationVariables = {
  params: MutationSyncUpdateConnectionParamsInput;
  query?: MutationSyncUpdateConnectionQueryInput | null;
  body?: MutationSyncUpdateConnectionBodyInput | null;
};

export type SyncUpdateConnectionMutation = {
  syncUpdateConnection?: boolean | null;
};

export type UpdateWorkOrderMutationVariables = {
  params: MutationUpdateWorkOrderParamsInput;
  query?: MutationUpdateWorkOrderQueryInput | null;
  body?: MutationUpdateWorkOrderBodyInput | null;
};

export type UpdateWorkOrderMutation = {
  updateWorkOrder?: {
    __typename: 'WorkOrder';
    workOrderId?: string | null;
    workRequestId?: string | null;
    workOrderStatusTypeId?: string | null;
    workOrderStatusTypeName?: string | null;
    workOrderName?: string | null;
    workOrderDescription?: string | null;
    workOrderNumber?: string | null;
    workRequest?: {
      __typename: 'WorkRequest';
      workRequestId?: string | null;
      workRequestStatusId?: string | null;
      workRequestStatusName?: string | null;
      facilityId?: string | null;
      projectId?: string | null;
      projectIdentifier?: string | null;
      projectName?: string | null;
      workRequestIdentifier?: string | null;
      workRequestName?: string | null;
      needBy?: string | null;
      workRequestDescription?: string | null;
      facilityName?: string | null;
      workRequestStatusDescription?: string | null;
      lastStatusUpdate?: string | null;
      numberOfAttachments?: number | null;
    } | null;
    sortOrder?: number | null;
    createdBy?: string | null;
    lastStatusUpdate?: string | null;
    createdOn?: string | null;
    isStepFunctionProcessing?: boolean | null;
    stepFunctionProcessingMessage?: string | null;
    numberOfAttachments?: number | null;
    hasCustomWorkOrderName?: boolean | null;
  } | null;
};

export type DeleteWorkOrderMutationVariables = {
  params: MutationDeleteWorkOrderParamsInput;
};

export type DeleteWorkOrderMutation = {
  deleteWorkOrder?: boolean | null;
};

export type SyncCreateWorkflowMutationVariables = {
  query?: MutationSyncCreateWorkflowQueryInput | null;
  body?: MutationSyncCreateWorkflowBodyInput | null;
};

export type SyncCreateWorkflowMutation = {
  syncCreateWorkflow?: {
    __typename: 'NewWorkflow';
    workflowId: string;
    workflowName?: string | null;
    workflowMapping?: {
      __typename: 'NewWorkflowMappingItem';
      workflowMappingId: string;
      workflowMappingName?: string | null;
      isSyncRunning?: boolean | null;
      lastSyncAt?: string | null;
      lastSavedAt?: string | null;
      solitaryFlows?: Array<{
        __typename: 'NewSolitaryFlowItem';
        solitaryFlowId?: string | null;
        action?: string | null;
        actionNodeId?: string | null;
        actionAlias?: string | null;
        source?: {
          __typename: 'MappingFlowConfigurationSourceNodeInfo';
          syncDetailsId?: string | null;
          nodeId?: string | null;
          connection?: {
            __typename: 'SyncConnection';
            id: string;
            name?: string | null;
            type?: string | null;
            configuration?: string | null;
            isConnectionUsed?: boolean | null;
            lastModified?: string | null;
            desktopConnectionId?: string | null;
          } | null;
          hub?: {
            __typename: 'ConnectorFieldItem';
            id?: string | null;
            name?: string | null;
          } | null;
          projects?: Array<{
            __typename: 'ConnectorProjectItem';
            id?: string | null;
            name?: string | null;
            folders?: Array<{
              __typename: 'ConnectorFieldFolder';
              id?: string | null;
              name?: string | null;
            } | null> | null;
          } | null> | null;
          additionalData?: string | null;
          settings?: {
            __typename: 'MappingConfigurationSettings';
            isSyncEnabled?: boolean | null;
            includeExtensions?: string | null;
            secondaryFilesSetting?: string | null;
            isSyncAllRevisions?: boolean | null;
            syncType?: string | null;
            scheduleSyncConfig?: string | null;
          } | null;
        } | null;
        destinations?: Array<{
          __typename: 'MappingFlowConfigurationDestinationNodeInfo';
          syncDetailsId?: string | null;
          nodeId?: string | null;
          connection?: {
            __typename: 'SyncConnection';
            id: string;
            name?: string | null;
            type?: string | null;
            configuration?: string | null;
            isConnectionUsed?: boolean | null;
            lastModified?: string | null;
            desktopConnectionId?: string | null;
          } | null;
          hub?: {
            __typename: 'ConnectorFieldItem';
            id?: string | null;
            name?: string | null;
          } | null;
          project?: {
            __typename: 'ConnectorProjectSingleFieldItem';
            id?: string | null;
            name?: string | null;
            folder?: {
              __typename: 'ConnectorFieldItem';
              id?: string | null;
              name?: string | null;
            } | null;
          } | null;
          additionalData?: string | null;
        } | null> | null;
        syncStatus?: number | null;
      } | null> | null;
    } | null;
  } | null;
};

export type SyncRenameWorkflowMutationVariables = {
  query?: MutationSyncRenameWorkflowQueryInput | null;
  body?: MutationSyncRenameWorkflowBodyInput | null;
};

export type SyncRenameWorkflowMutation = {
  syncRenameWorkflow?: boolean | null;
};

export type SyncDuplicateWorkflowMutationVariables = {
  query?: MutationSyncDuplicateWorkflowQueryInput | null;
  body?: MutationSyncDuplicateWorkflowBodyInput | null;
};

export type SyncDuplicateWorkflowMutation = {
  syncDuplicateWorkflow?: boolean | null;
};

export type SyncCreateMappingMutationVariables = {
  query?: MutationSyncCreateMappingQueryInput | null;
  body?: MutationSyncCreateMappingBodyInput | null;
};

export type SyncCreateMappingMutation = {
  syncCreateMapping?: {
    __typename: 'NewWorkflow';
    workflowId: string;
    workflowName?: string | null;
    workflowMapping?: {
      __typename: 'NewWorkflowMappingItem';
      workflowMappingId: string;
      workflowMappingName?: string | null;
      isSyncRunning?: boolean | null;
      lastSyncAt?: string | null;
      lastSavedAt?: string | null;
      solitaryFlows?: Array<{
        __typename: 'NewSolitaryFlowItem';
        solitaryFlowId?: string | null;
        action?: string | null;
        actionNodeId?: string | null;
        actionAlias?: string | null;
        source?: {
          __typename: 'MappingFlowConfigurationSourceNodeInfo';
          syncDetailsId?: string | null;
          nodeId?: string | null;
          connection?: {
            __typename: 'SyncConnection';
            id: string;
            name?: string | null;
            type?: string | null;
            configuration?: string | null;
            isConnectionUsed?: boolean | null;
            lastModified?: string | null;
            desktopConnectionId?: string | null;
          } | null;
          hub?: {
            __typename: 'ConnectorFieldItem';
            id?: string | null;
            name?: string | null;
          } | null;
          projects?: Array<{
            __typename: 'ConnectorProjectItem';
            id?: string | null;
            name?: string | null;
            folders?: Array<{
              __typename: 'ConnectorFieldFolder';
              id?: string | null;
              name?: string | null;
            } | null> | null;
          } | null> | null;
          additionalData?: string | null;
          settings?: {
            __typename: 'MappingConfigurationSettings';
            isSyncEnabled?: boolean | null;
            includeExtensions?: string | null;
            secondaryFilesSetting?: string | null;
            isSyncAllRevisions?: boolean | null;
            syncType?: string | null;
            scheduleSyncConfig?: string | null;
          } | null;
        } | null;
        destinations?: Array<{
          __typename: 'MappingFlowConfigurationDestinationNodeInfo';
          syncDetailsId?: string | null;
          nodeId?: string | null;
          connection?: {
            __typename: 'SyncConnection';
            id: string;
            name?: string | null;
            type?: string | null;
            configuration?: string | null;
            isConnectionUsed?: boolean | null;
            lastModified?: string | null;
            desktopConnectionId?: string | null;
          } | null;
          hub?: {
            __typename: 'ConnectorFieldItem';
            id?: string | null;
            name?: string | null;
          } | null;
          project?: {
            __typename: 'ConnectorProjectSingleFieldItem';
            id?: string | null;
            name?: string | null;
            folder?: {
              __typename: 'ConnectorFieldItem';
              id?: string | null;
              name?: string | null;
            } | null;
          } | null;
          additionalData?: string | null;
        } | null> | null;
        syncStatus?: number | null;
      } | null> | null;
    } | null;
  } | null;
};

export type SyncRenameMappingMutationVariables = {
  query?: MutationSyncRenameMappingQueryInput | null;
  body?: MutationSyncRenameMappingBodyInput | null;
};

export type SyncRenameMappingMutation = {
  syncRenameMapping?: boolean | null;
};

export type SyncDuplicateMappingMutationVariables = {
  query?: MutationSyncDuplicateMappingQueryInput | null;
  body?: MutationSyncDuplicateMappingBodyInput | null;
};

export type SyncDuplicateMappingMutation = {
  syncDuplicateMapping?: boolean | null;
};

export type SyncDeleteWorkflowMutationVariables = {
  query?: MutationSyncDeleteWorkflowQueryInput | null;
  body?: MutationSyncDeleteWorkflowBodyInput | null;
};

export type SyncDeleteWorkflowMutation = {
  syncDeleteWorkflow?: string | null;
};

export type SyncArchiveWorkflowMutationVariables = {
  query?: MutationSyncArchiveWorkflowQueryInput | null;
  body?: MutationSyncArchiveWorkflowBodyInput | null;
};

export type SyncArchiveWorkflowMutation = {
  syncArchiveWorkflow?: string | null;
};

export type SyncRestoreWorkflowMutationVariables = {
  query?: MutationSyncRestoreWorkflowQueryInput | null;
  body?: MutationSyncRestoreWorkflowBodyInput | null;
};

export type SyncRestoreWorkflowMutation = {
  syncRestoreWorkflow?: string | null;
};

export type SyncDeactivateWorkflowMutationVariables = {
  query?: MutationSyncDeactivateWorkflowQueryInput | null;
  body?: MutationSyncDeactivateWorkflowBodyInput | null;
};

export type SyncDeactivateWorkflowMutation = {
  syncDeactivateWorkflow?: string | null;
};

export type SyncActivateWorkflowMutationVariables = {
  query?: MutationSyncActivateWorkflowQueryInput | null;
  body?: MutationSyncActivateWorkflowBodyInput | null;
};

export type SyncActivateWorkflowMutation = {
  syncActivateWorkflow?: string | null;
};

export type SyncDeleteWebhookMutationVariables = {
  query?: MutationSyncDeleteWebhookQueryInput | null;
  body?: MutationSyncDeleteWebhookBodyInput | null;
};

export type SyncDeleteWebhookMutation = {
  syncDeleteWebhook?: string | null;
};

export type SyncDeactivateWebhookMutationVariables = {
  query?: MutationSyncDeactivateWebhookQueryInput | null;
  body?: MutationSyncDeactivateWebhookBodyInput | null;
};

export type SyncDeactivateWebhookMutation = {
  syncDeactivateWebhook?: string | null;
};

export type SyncActivateWebhookMutationVariables = {
  query?: MutationSyncActivateWebhookQueryInput | null;
  body?: MutationSyncActivateWebhookBodyInput | null;
};

export type SyncActivateWebhookMutation = {
  syncActivateWebhook?: string | null;
};

export type SyncDeleteTemplateMutationVariables = {
  query?: MutationSyncDeleteTemplateQueryInput | null;
  body?: MutationSyncDeleteTemplateBodyInput | null;
};

export type SyncDeleteTemplateMutation = {
  syncDeleteTemplate?: string | null;
};

export type SyncRenameTemplateMutationVariables = {
  query?: MutationSyncRenameTemplateQueryInput | null;
  body?: MutationSyncRenameTemplateBodyInput | null;
};

export type SyncRenameTemplateMutation = {
  syncRenameTemplate?: boolean | null;
};

export type SyncDuplicateTemplateMutationVariables = {
  query?: MutationSyncDuplicateTemplateQueryInput | null;
  body?: MutationSyncDuplicateTemplateBodyInput | null;
};

export type SyncDuplicateTemplateMutation = {
  syncDuplicateTemplate?: boolean | null;
};

export type AddTaskMutationVariables = {
  query?: MutationAddTaskQueryInput | null;
  body?: MutationAddTaskBodyInput | null;
};

export type AddTaskMutation = {
  addTask?: Array<{
    __typename: 'Task';
    taskId?: string | null;
    taskTypeId?: string | null;
    billOfProcessId?: string | null;
    workRequestItemId?: string | null;
    taskStatusTypeId?: string | null;
    taskPredecessorIds?: Array<{
      __typename: 'TaskPredecessor';
      taskId?: string | null;
      taskName?: string | null;
      taskStatusTypeId?: string | null;
    } | null> | null;
    taskPredecessorDeletedIds?: Array<string | null> | null;
    taskTypeIds?: Array<string | null> | null;
    taskName?: string | null;
    taskDescription?: string | null;
    taskStatusTypeName?: string | null;
    sortOrder?: number | null;
    isEnabled?: boolean | null;
    createdOn?: string | null;
    time?: {
      __typename: 'Time';
      totalTime?: string | null;
      events?: Array<{
        __typename: 'Event';
        taskStatusTypeId?: string | null;
        description?: string | null;
        timesStamp?: string | null;
        userId?: string | null;
      } | null> | null;
    } | null;
    hasDocuments?: boolean | null;
    hasDrawingRegisterDocuments?: boolean | null;
    hasCommonDocuments?: boolean | null;
    hasExternalDocuments?: boolean | null;
    quantity?: number | null;
    needBy?: string | null;
  } | null> | null;
};

export type UpdateTaskMutationVariables = {
  params: MutationUpdateTaskParamsInput;
  query?: MutationUpdateTaskQueryInput | null;
  body?: MutationUpdateTaskBodyInput | null;
};

export type UpdateTaskMutation = {
  updateTask?: {
    __typename: 'Task';
    taskId?: string | null;
    taskTypeId?: string | null;
    billOfProcessId?: string | null;
    workRequestItemId?: string | null;
    taskStatusTypeId?: string | null;
    taskPredecessorIds?: Array<{
      __typename: 'TaskPredecessor';
      taskId?: string | null;
      taskName?: string | null;
      taskStatusTypeId?: string | null;
    } | null> | null;
    taskPredecessorDeletedIds?: Array<string | null> | null;
    taskTypeIds?: Array<string | null> | null;
    taskName?: string | null;
    taskDescription?: string | null;
    taskStatusTypeName?: string | null;
    sortOrder?: number | null;
    isEnabled?: boolean | null;
    createdOn?: string | null;
    time?: {
      __typename: 'Time';
      totalTime?: string | null;
      events?: Array<{
        __typename: 'Event';
        taskStatusTypeId?: string | null;
        description?: string | null;
        timesStamp?: string | null;
        userId?: string | null;
      } | null> | null;
    } | null;
    hasDocuments?: boolean | null;
    hasDrawingRegisterDocuments?: boolean | null;
    hasCommonDocuments?: boolean | null;
    hasExternalDocuments?: boolean | null;
    quantity?: number | null;
    needBy?: string | null;
  } | null;
};

export type UpdateTaskMultipleMutationVariables = {
  query?: MutationUpdateTaskMultipleQueryInput | null;
  body?: MutationUpdateTaskMultipleBodyInput | null;
};

export type UpdateTaskMultipleMutation = {
  updateTaskMultiple?: boolean | null;
};

export type DeleteTaskMutationVariables = {
  params: MutationDeleteTaskParamsInput;
};

export type DeleteTaskMutation = {
  deleteTask?: boolean | null;
};

export type DeleteSourcePadMutationVariables = {
  params: MutationDeleteSourcePadParamsInput;
};

export type DeleteSourcePadMutation = {
  deleteSourcePad?: boolean | null;
};

export type CreateAutodeskFieldFolderMutationVariables = {
  query?: MutationCreateAutodeskFieldFolderQueryInput | null;
  body?: MutationCreateAutodeskFieldFolderBodyInput | null;
};

export type CreateAutodeskFieldFolderMutation = {
  createAutodeskFieldFolder?: {
    __typename: 'NewAutodeskFieldFolder';
    success?: boolean | null;
  } | null;
};

export type CreateAutodeskForgeFolderMutationVariables = {
  query?: MutationCreateAutodeskForgeFolderQueryInput | null;
  body?: MutationCreateAutodeskForgeFolderBodyInput | null;
};

export type CreateAutodeskForgeFolderMutation = {
  createAutodeskForgeFolder?: {
    __typename: 'NewAutodeskForgeFolder';
    success?: boolean | null;
    name?: string | null;
  } | null;
};

export type CreateProcoreFolderMutationVariables = {
  params: MutationCreateProcoreFolderParamsInput;
  query?: MutationCreateProcoreFolderQueryInput | null;
  body?: MutationCreateProcoreFolderBodyInput | null;
};

export type CreateProcoreFolderMutation = {
  createProcoreFolder?: {
    __typename: 'NewProcoreFolder';
    success?: boolean | null;
  } | null;
};

export type CreateSharepointFolderMutationVariables = {
  query?: MutationCreateSharepointFolderQueryInput | null;
  body?: MutationCreateSharepointFolderBodyInput | null;
};

export type CreateSharepointFolderMutation = {
  createSharepointFolder?: boolean | null;
};

export type CreateNewformaFolderMutationVariables = {
  query?: MutationCreateNewformaFolderQueryInput | null;
  body?: MutationCreateNewformaFolderBodyInput | null;
};

export type CreateNewformaFolderMutation = {
  createNewformaFolder?: boolean | null;
};

export type SyncUpdateWorkflowCanvasMutationVariables = {
  query?: MutationSyncUpdateWorkflowCanvasQueryInput | null;
  body?: MutationSyncUpdateWorkflowCanvasBodyInput | null;
};

export type SyncUpdateWorkflowCanvasMutation = {
  syncUpdateWorkflowCanvas?: string | null;
};

export type SyncUpdateMappingConfigurationsMutationVariables = {
  query?: MutationSyncUpdateMappingConfigurationsQueryInput | null;
  body?: MutationSyncUpdateMappingConfigurationsBodyInput | null;
};

export type SyncUpdateMappingConfigurationsMutation = {
  syncUpdateMappingConfigurations?: string | null;
};

export type SyncUpdatePublishActionConfigurationsMutationVariables = {
  query?: MutationSyncUpdatePublishActionConfigurationsQueryInput | null;
  body?: MutationSyncUpdatePublishActionConfigurationsBodyInput | null;
};

export type SyncUpdatePublishActionConfigurationsMutation = {
  syncUpdatePublishActionConfigurations?: string | null;
};

export type SyncCreateMappingTemplateMutationVariables = {
  query?: MutationSyncCreateMappingTemplateQueryInput | null;
  body?: MutationSyncCreateMappingTemplateBodyInput | null;
};

export type SyncCreateMappingTemplateMutation = {
  syncCreateMappingTemplate?: {
    __typename: 'WorkflowMappingTemplate';
    templateId?: string | null;
    templateName?: string | null;
  } | null;
};

export type SyncWorkflowsImportMutationVariables = {
  query?: MutationSyncWorkflowsImportQueryInput | null;
  body?: MutationSyncWorkflowsImportBodyInput | null;
};

export type SyncWorkflowsImportMutation = {
  syncWorkflowsImport?: {
    __typename: 'WorkflowImportBaseItem';
    workflows?: Array<{
      __typename: 'WorkflowImportItem';
      workflowName?: string | null;
      workflowId?: string | null;
      workflowError?: string | null;
      mappings?: Array<{
        __typename: 'WorkflowImportMappingItem';
        mappingName?: string | null;
        mappingId?: string | null;
        canvasNode?: string | null;
        mappingError?: string | null;
        solitaryFlowConfigurations?: Array<{
          __typename: 'MappingFlowConfigurationItem';
          solitaryFlowId?: string | null;
          action?: string | null;
          actionNodeId?: string | null;
          actionAlias?: string | null;
          source?: {
            __typename: 'MappingFlowConfigurationSourceNodeInfoItem';
            syncDetailsId?: string | null;
            nodeId?: string | null;
            connectionId?: string | null;
            hub?: {
              __typename: 'ConnectorFieldItem';
              id?: string | null;
              name?: string | null;
            } | null;
            projects?: Array<{
              __typename: 'ConnectorProjectItem';
              id?: string | null;
              name?: string | null;
            } | null> | null;
            additionalData?: string | null;
            settings?: {
              __typename: 'MappingConfigurationSettingsItem';
              isSyncEnabled?: boolean | null;
              includeExtensions?: string | null;
              secondaryFilesSetting?: string | null;
              isSyncAllRevisions?: boolean | null;
              syncType?: string | null;
              scheduleSyncConfig?: string | null;
            } | null;
            connectionName?: string | null;
          } | null;
          destinations?: Array<{
            __typename: 'MappingFlowConfigurationDestinationNodeInfoItem';
            syncDetailsId?: string | null;
            nodeId?: string | null;
            connectionId?: string | null;
            hub?: {
              __typename: 'ConnectorFieldItem';
              id?: string | null;
              name?: string | null;
            } | null;
            project?: {
              __typename: 'ConnectorProjectSingleFolderItem';
              id?: string | null;
              name?: string | null;
            } | null;
            additionalData?: string | null;
            connectionName?: string | null;
          } | null> | null;
          solitaryFlowError?: string | null;
        } | null> | null;
        shouldOverwrite?: boolean | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type SyncMultipleWorkflowsMutationVariables = {
  query?: MutationSyncMultipleWorkflowsQueryInput | null;
  body?: MutationSyncMultipleWorkflowsBodyInput | null;
};

export type SyncMultipleWorkflowsMutation = {
  syncMultipleWorkflows?: {
    __typename: 'MultipleWorkflowsBaseItem';
    workflows?: Array<{
      __typename: 'MultipleWorkflowsItem';
      workflowId?: string | null;
      mappings?: Array<{
        __typename: 'MultipleWorkflowMappingItem';
        mappingId?: string | null;
        solitaryFlowSettings?: Array<{
          __typename: 'MappingFlowStatusSettingItem';
          solitaryFlowId?: string | null;
          isSyncEnabled?: boolean | null;
          syncType?: string | null;
          scheduleSyncConfig?: string | null;
          syncStatus?: string | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type SyncMigratedWorkflowsMutationVariables = {
  query?: MutationSyncMigratedWorkflowsQueryInput | null;
  body?: MutationSyncMigratedWorkflowsBodyInput | null;
};

export type SyncMigratedWorkflowsMutation = {
  syncMigratedWorkflows?: boolean | null;
};

export type SyncDeleteMappingDataMutationVariables = {
  query?: MutationSyncDeleteMappingDataQueryInput | null;
  body?: MutationSyncDeleteMappingDataBodyInput | null;
};

export type SyncDeleteMappingDataMutation = {
  syncDeleteMappingData?: boolean | null;
};

export type AddDrawingTypeCategoryMutationVariables = {
  query?: MutationAddDrawingTypeCategoryQueryInput | null;
  body?: MutationAddDrawingTypeCategoryBodyInput | null;
};

export type AddDrawingTypeCategoryMutation = {
  addDrawingTypeCategory?: {
    __typename: 'DrawingTypeCategory';
    companyId?: string | null;
    userDefinedId?: string | null;
    name?: string | null;
    hasDrawingTypes?: boolean | null;
    id?: string | null;
    createdBy?: string | null;
    createdOnUtc?: string | null;
    modifiedBy?: string | null;
    modifiedOnUtc?: string | null;
  } | null;
};

export type AddTaskDocumentMutationVariables = {
  query?: MutationAddTaskDocumentQueryInput | null;
  body?: MutationAddTaskDocumentBodyInput | null;
};

export type AddTaskDocumentMutation = {
  addTaskDocument?: Array<{
    __typename: 'TaskDocument';
    taskDocumentId?: string | null;
    taskId?: string | null;
    documentId?: string | null;
    externalUrl?: string | null;
    createdBy?: string | null;
    createdOn?: string | null;
    documentTypeId?: string | null;
  } | null> | null;
};

export type DeleteTaskDocumentMutationVariables = {
  params: MutationDeleteTaskDocumentParamsInput;
};

export type DeleteTaskDocumentMutation = {
  deleteTaskDocument?: boolean | null;
};

export type SyncCreateTemplateFromMappingMutationVariables = {
  query?: MutationSyncCreateTemplateFromMappingQueryInput | null;
  body?: MutationSyncCreateTemplateFromMappingBodyInput | null;
};

export type SyncCreateTemplateFromMappingMutation = {
  syncCreateTemplateFromMapping?: boolean | null;
};

export type SyncDeleteMappingFlowMutationVariables = {
  query?: MutationSyncDeleteMappingFlowQueryInput | null;
  body?: MutationSyncDeleteMappingFlowBodyInput | null;
};

export type SyncDeleteMappingFlowMutation = {
  syncDeleteMappingFlow?: boolean | null;
};

export type SyncFlowNowMutationVariables = {
  query?: MutationSyncFlowNowQueryInput | null;
  body?: MutationSyncFlowNowBodyInput | null;
};

export type SyncFlowNowMutation = {
  syncFlowNow?: boolean | null;
};

export type SyncMultipleFlowNowMutationVariables = {
  query?: MutationSyncMultipleFlowNowQueryInput | null;
  body?: MutationSyncMultipleFlowNowBodyInput | null;
};

export type SyncMultipleFlowNowMutation = {
  syncMultipleFlowNow?: boolean | null;
};

export type SyncStopAllMutationVariables = {
  query?: MutationSyncStopAllQueryInput | null;
  body?: MutationSyncStopAllBodyInput | null;
};

export type SyncStopAllMutation = {
  syncStopAll?: boolean | null;
};

export type SyncSaveAllMutationVariables = {
  query?: MutationSyncSaveAllQueryInput | null;
  body?: MutationSyncSaveAllBodyInput | null;
};

export type SyncSaveAllMutation = {
  syncSaveAll?: boolean | null;
};

export type AddBillOfMaterialLineItemByLBSMutationVariables = {
  query?: MutationAddBillOfMaterialLineItemByLBSQueryInput | null;
  body?: MutationAddBillOfMaterialLineItemByLBSBodyInput | null;
};

export type AddBillOfMaterialLineItemByLBSMutation = {
  addBillOfMaterialLineItemByLBS?: {
    __typename: 'BillOfMaterialLineItemByLBS';
    ern?: string | null;
    locationId?: string | null;
    workPhaseId?: string | null;
    amount?: {
      __typename: 'Amount';
      assigned?: {
        __typename: 'AmountClass';
        individual?: number | null;
        aggregate?: number | null;
      } | null;
      available?: {
        __typename: 'AmountClass';
        individual?: number | null;
        aggregate?: number | null;
      } | null;
    } | null;
    parentLocationId?: string | null;
    parentWorkPhases?: Array<string | null> | null;
    children?: Array<{
      __typename: 'Children';
      locationId?: string | null;
      workPhaseId?: string | null;
      amount?: {
        __typename: 'Amount';
        assigned?: {
          __typename: 'AmountClass';
          individual?: number | null;
          aggregate?: number | null;
        } | null;
        available?: {
          __typename: 'AmountClass';
          individual?: number | null;
          aggregate?: number | null;
        } | null;
      } | null;
    } | null> | null;
    unassignedAmount?: number | null;
    unassignedAmountRemaining?: number | null;
    totalAmount?: number | null;
    totalAmountRemaining?: number | null;
  } | null;
};

export type AddBillOfMaterialLineItemByLBSBulkMutationVariables = {
  query?: MutationAddBillOfMaterialLineItemByLBSBulkQueryInput | null;
  body?: MutationAddBillOfMaterialLineItemByLBSBulkBodyInput | null;
};

export type AddBillOfMaterialLineItemByLBSBulkMutation = {
  addBillOfMaterialLineItemByLBSBulk?: string | null;
};

export type PatchBillOfMaterialLineItemByLBSMutationVariables = {
  query?: MutationPatchBillOfMaterialLineItemByLBSQueryInput | null;
  body?: MutationPatchBillOfMaterialLineItemByLBSBodyInput | null;
};

export type PatchBillOfMaterialLineItemByLBSMutation = {
  patchBillOfMaterialLineItemByLBS?: {
    __typename: 'BillOfMaterialLineItemByLBS';
    ern?: string | null;
    locationId?: string | null;
    workPhaseId?: string | null;
    amount?: {
      __typename: 'Amount';
      assigned?: {
        __typename: 'AmountClass';
        individual?: number | null;
        aggregate?: number | null;
      } | null;
      available?: {
        __typename: 'AmountClass';
        individual?: number | null;
        aggregate?: number | null;
      } | null;
    } | null;
    parentLocationId?: string | null;
    parentWorkPhases?: Array<string | null> | null;
    children?: Array<{
      __typename: 'Children';
      locationId?: string | null;
      workPhaseId?: string | null;
      amount?: {
        __typename: 'Amount';
        assigned?: {
          __typename: 'AmountClass';
          individual?: number | null;
          aggregate?: number | null;
        } | null;
        available?: {
          __typename: 'AmountClass';
          individual?: number | null;
          aggregate?: number | null;
        } | null;
      } | null;
    } | null> | null;
    unassignedAmount?: number | null;
    unassignedAmountRemaining?: number | null;
    totalAmount?: number | null;
    totalAmountRemaining?: number | null;
  } | null;
};

export type PatchBulkBillOfMaterialLineItemByLBSMutationVariables = {
  query?: MutationPatchBulkBillOfMaterialLineItemByLBSQueryInput | null;
  body?: MutationPatchBulkBillOfMaterialLineItemByLBSBodyInput | null;
};

export type PatchBulkBillOfMaterialLineItemByLBSMutation = {
  patchBulkBillOfMaterialLineItemByLBS?: {
    __typename: 'LBSBulkResponse';
    key: string;
    value: string;
  } | null;
};

export type PatchBulk2BillOfMaterialLineItemByLBSMutationVariables = {
  query?: MutationPatchBulk2BillOfMaterialLineItemByLBSQueryInput | null;
  body?: MutationPatchBulk2BillOfMaterialLineItemByLBSBodyInput | null;
};

export type PatchBulk2BillOfMaterialLineItemByLBSMutation = {
  patchBulk2BillOfMaterialLineItemByLBS?: {
    __typename: 'LBSBulkResponse';
    key: string;
    value: string;
  } | null;
};

export type QuantitiesCreateMutationVariables = {
  query?: MutationQuantitiesCreateQueryInput | null;
  body?: MutationQuantitiesCreateBodyInput | null;
};

export type QuantitiesCreateMutation = {
  quantitiesCreate?: {
    __typename: 'QuantitiesCreateResponse';
    ern?: string | null;
    resourceId?: string | null;
    properties?: {
      __typename: 'GetProperties';
      companyId?: string | null;
      projectId?: string | null;
    } | null;
    amount?: number | null;
    amountBreakdown?: Array<{
      __typename: 'AmountBreakdownProperties';
      amountId?: string | null;
      amount?: number | null;
      children?: Array<{
        __typename: 'ChildAmountBreakdown';
        childAmountId?: string | null;
        childResourceERN?: string | null;
      } | null> | null;
    } | null> | null;
    constraints?: Array<string | null> | null;
  } | null;
};

export type QuantitiesPatchMutationVariables = {
  query?: MutationQuantitiesPatchQueryInput | null;
  body?: MutationQuantitiesPatchBodyInput | null;
};

export type QuantitiesPatchMutation = {
  quantitiesPatch?: {
    __typename: 'QuantitiesCreateResponse';
    ern?: string | null;
    resourceId?: string | null;
    properties?: {
      __typename: 'GetProperties';
      companyId?: string | null;
      projectId?: string | null;
    } | null;
    amount?: number | null;
    amountBreakdown?: Array<{
      __typename: 'AmountBreakdownProperties';
      amountId?: string | null;
      amount?: number | null;
      children?: Array<{
        __typename: 'ChildAmountBreakdown';
        childAmountId?: string | null;
        childResourceERN?: string | null;
      } | null> | null;
    } | null> | null;
    constraints?: Array<string | null> | null;
  } | null;
};

export type AddAssemblyCustomCategoryMutationVariables = {
  query?: MutationAddAssemblyCustomCategoryQueryInput | null;
  body?: MutationAddAssemblyCustomCategoryBodyInput | null;
};

export type AddAssemblyCustomCategoryMutation = {
  addAssemblyCustomCategory?: {
    __typename: 'AssemblyCustomCategory';
    assemblyCustomCategoryId: string;
    workFlowId?: string | null;
    companyId?: string | null;
    assemblyCustomCategoryName: string;
  } | null;
};

export type UpdateAssemblyCustomCategoryMutationVariables = {
  params: MutationUpdateAssemblyCustomCategoryParamsInput;
  query?: MutationUpdateAssemblyCustomCategoryQueryInput | null;
  body?: MutationUpdateAssemblyCustomCategoryBodyInput | null;
};

export type UpdateAssemblyCustomCategoryMutation = {
  updateAssemblyCustomCategory?: {
    __typename: 'AssemblyCustomCategory';
    assemblyCustomCategoryId: string;
    workFlowId?: string | null;
    companyId?: string | null;
    assemblyCustomCategoryName: string;
  } | null;
};

export type DeleteAssemblyCustomCategoryMutationVariables = {
  params: MutationDeleteAssemblyCustomCategoryParamsInput;
};

export type DeleteAssemblyCustomCategoryMutation = {
  deleteAssemblyCustomCategory?: boolean | null;
};

export type SyncMakeForesiteFolderMutationVariables = {
  query?: MutationSyncMakeForesiteFolderQueryInput | null;
  body?: MutationSyncMakeForesiteFolderBodyInput | null;
};

export type SyncMakeForesiteFolderMutation = {
  syncMakeForesiteFolder?: {
    __typename: 'NewForesiteFolder';
    success?: boolean | null;
  } | null;
};

export type AddBOMItemCustomAssemblyMutationVariables = {
  query?: MutationAddBOMItemCustomAssemblyQueryInput | null;
  body?: MutationAddBOMItemCustomAssemblyBodyInput | null;
};

export type AddBOMItemCustomAssemblyMutation = {
  addBOMItemCustomAssembly?: {
    __typename: 'CustomAssembly';
    lineItemId?: string | null;
    lineItemTypeId?: string | null;
    lineItemTypeName?: string | null;
    projectId?: string | null;
    customAssemblyName?: string | null;
    customAssemblyIdentifier?: string | null;
    assemblyCustomCategoryId?: string | null;
    assemblyCustomCategoryName?: string | null;
    customAssemblyStatus?: string | null;
    assignedto?: string | null;
    assignedtoFirstName?: string | null;
    assignedtoLastName?: string | null;
    assignedtoEmail?: string | null;
    quantity?: number | null;
    createdOn?: string | null;
    createdBy?: string | null;
    createdByFirstName?: string | null;
    createdByLastName?: string | null;
    createdByEmail?: string | null;
    hasChildren?: boolean | null;
  } | null;
};

export type DeleteBulkBOMItemCustomAssemblyMutationVariables = {
  query?: MutationDeleteBulkBOMItemCustomAssemblyQueryInput | null;
  body?: MutationDeleteBulkBOMItemCustomAssemblyBodyInput | null;
};

export type DeleteBulkBOMItemCustomAssemblyMutation = {
  deleteBulkBOMItemCustomAssembly?: boolean | null;
};

export type DeleteBOMItemCustomAssemblyMutationVariables = {
  params: MutationDeleteBOMItemCustomAssemblyParamsInput;
};

export type DeleteBOMItemCustomAssemblyMutation = {
  deleteBOMItemCustomAssembly?: boolean | null;
};

export type SyncCreateWindowsFolderMutationVariables = {
  query?: MutationSyncCreateWindowsFolderQueryInput | null;
  body?: MutationSyncCreateWindowsFolderBodyInput | null;
};

export type SyncCreateWindowsFolderMutation = {
  syncCreateWindowsFolder?: {
    __typename: 'NewWindowsFolder';
    success?: boolean | null;
  } | null;
};

export type AddWorkOrderItemMutationVariables = {
  query?: MutationAddWorkOrderItemQueryInput | null;
  body?: MutationAddWorkOrderItemBodyInput | null;
};

export type AddWorkOrderItemMutation = {
  addWorkOrderItem?: {
    __typename: 'WorkOrderItem';
    workRequestItemId?: string | null;
    hasShopTask?: boolean | null;
    workOrderItemId?: string | null;
    workOrderId?: string | null;
    workOrderItemTypeId?: string | null;
    unitOfMeasureId?: string | null;
    billOfProcessId?: string | null;
    workOrderItemName?: string | null;
    WorkOrderItemIdentification?: string | null;
    quantity?: number | null;
    unityQuantity?: number | null;
    assignedQuantity?: number | null;
    remainingQuantity?: number | null;
    createdOn?: string | null;
    createdBy?: string | null;
    workOrderItemStatusTypeId?: string | null;
  } | null;
};

export type AddWorkOrderItemFromCatalogMutationVariables = {
  query?: MutationAddWorkOrderItemFromCatalogQueryInput | null;
  body?: MutationAddWorkOrderItemFromCatalogBodyInput | null;
};

export type AddWorkOrderItemFromCatalogMutation = {
  addWorkOrderItemFromCatalog?: {
    __typename: 'WorkOrderItem';
    workRequestItemId?: string | null;
    hasShopTask?: boolean | null;
    workOrderItemId?: string | null;
    workOrderId?: string | null;
    workOrderItemTypeId?: string | null;
    unitOfMeasureId?: string | null;
    billOfProcessId?: string | null;
    workOrderItemName?: string | null;
    WorkOrderItemIdentification?: string | null;
    quantity?: number | null;
    unityQuantity?: number | null;
    assignedQuantity?: number | null;
    remainingQuantity?: number | null;
    createdOn?: string | null;
    createdBy?: string | null;
    workOrderItemStatusTypeId?: string | null;
  } | null;
};

export type UpdateWorkOrderItemMutationVariables = {
  params: MutationUpdateWorkOrderItemParamsInput;
  query?: MutationUpdateWorkOrderItemQueryInput | null;
  body?: MutationUpdateWorkOrderItemBodyInput | null;
};

export type UpdateWorkOrderItemMutation = {
  updateWorkOrderItem?: {
    __typename: 'WorkOrderItem';
    workRequestItemId?: string | null;
    hasShopTask?: boolean | null;
    workOrderItemId?: string | null;
    workOrderId?: string | null;
    workOrderItemTypeId?: string | null;
    unitOfMeasureId?: string | null;
    billOfProcessId?: string | null;
    workOrderItemName?: string | null;
    WorkOrderItemIdentification?: string | null;
    quantity?: number | null;
    unityQuantity?: number | null;
    assignedQuantity?: number | null;
    remainingQuantity?: number | null;
    createdOn?: string | null;
    createdBy?: string | null;
    workOrderItemStatusTypeId?: string | null;
  } | null;
};

export type DeleteWorkOrderItemMutationVariables = {
  params: MutationDeleteWorkOrderItemParamsInput;
};

export type DeleteWorkOrderItemMutation = {
  deleteWorkOrderItem?: boolean | null;
};

export type SyncRenameActionMutationVariables = {
  query?: MutationSyncRenameActionQueryInput | null;
  body?: MutationSyncRenameActionBodyInput | null;
};

export type SyncRenameActionMutation = {
  syncRenameAction?: boolean | null;
};

export type DepartmentTypesQuery = {
  departmentTypes?: Array<{
    __typename: 'DepartmentType';
    departmentTypeId?: string | null;
    departmentTypeName?: string | null;
  } | null> | null;
};

export type NavigationModulesQuery = {
  navigationModules?: Array<{
    __typename: 'NavigationModule';
    navigationModuleId: string;
    navigationModuleName: string;
    navigationModuleCode: string;
    sortOrder?: number | null;
    navigationModuleSets?: Array<{
      __typename: 'NavigationModuleSet';
      navigationModuleSetId: string;
      navigationModuleId: string;
      navigationSetId: string;
      navigationSet?: {
        __typename: 'NavigationSet';
        navigationSetId: string;
        navigationSetName: string;
        isMenu?: boolean | null;
        icon?: string | null;
        sortOrder?: number | null;
        navigationSetItems?: Array<{
          __typename: 'NavigationSetItem';
          navigationSetItemId: string;
          navigationSetId: string;
          navigationItemId: string;
          navigationItem?: {
            __typename: 'NavigationItem';
            navigationItemId: string;
            navigationItemName: string;
            icon: string;
            routePath: string;
            iconPath?: string | null;
          } | null;
          sortOrder?: number | null;
        } | null> | null;
      } | null;
      sortOrder?: number | null;
      isLastInGroup?: boolean | null;
    } | null> | null;
  } | null> | null;
};

export type PartAttributeTypesQuery = {
  partAttributeTypes?: Array<{
    __typename: 'PartAttributeType';
    partAttributeTypeId: string;
    partAttributeTypeName: string;
    partAttributeTypeDescription?: string | null;
    sortOrder?: number | null;
  } | null> | null;
};

export type PartAttributePredefinedNamesQuery = {
  partAttributePredefinedNames?: Array<{
    __typename: 'PartAttributePredefinedName';
    partAttributePredefinedNameId: string;
    predefinedAttributeName: string;
  } | null> | null;
};

export type LicenseTypesQuery = {
  licenseTypes?: Array<{
    __typename: 'LicenseType';
    licenseTypeId: string;
    licenseTypeName: string;
    licenseTypeCode?: LICENSE_CODE | null;
    sortOrder?: number | null;
  } | null> | null;
};

export type LatestEulaQuery = {
  latestEula?: {
    __typename: 'Eula';
    eulaId: string;
    eulaVersion: string;
    eulaTimeStamp: string;
    content: string;
  } | null;
};

export type WorkRequestsStatusTypeQuery = {
  workRequestsStatusType?: Array<{
    __typename: 'WorkRequestsStatusType';
    workRequestStatusTypeId: string;
    workRequestStatusTypeName: string;
  } | null> | null;
};

export type WorkRequestItemTypeQuery = {
  workRequestItemType?: Array<{
    __typename: 'WorkRequestItemType';
    workRequestItemTypeId?: string | null;
    workRequestItemTypeName?: string | null;
  } | null> | null;
};

export type LocationTypesQuery = {
  locationTypes?: Array<{
    __typename: 'LocationType';
    locationTypeId: string;
    locationTypeName: string;
  } | null> | null;
};

export type LocationFormatsQuery = {
  locationFormats?: Array<{
    __typename: 'LocationFormat';
    locationFormatId: string;
    locationFormatName: string;
  } | null> | null;
};

export type LineItemTypesQuery = {
  lineItemTypes?: Array<{
    __typename: 'LineItemType';
    lineItemTypeId: string;
    lineItemTypeName: string;
  } | null> | null;
};

export type MessageTypesQuery = {
  messageTypes?: Array<{
    __typename: 'MessageType';
    messageTypeId?: string | null;
    messageTypeName?: string | null;
  } | null> | null;
};

export type MessagePrioritiesQuery = {
  messagePriorities?: Array<{
    __typename: 'MessagePriority';
    messagePriorityId?: string | null;
    priorityName?: string | null;
    priorityCode?: MessagePriorityCode | null;
    sortOrder?: number | null;
    createdOn?: string | null;
    createdBy?: string | null;
  } | null> | null;
};

export type BillOfMaterialsStatusesQuery = {
  billOfMaterialsStatuses?: Array<{
    __typename: 'BillOfMaterialsStatuses';
    billOfMaterialsStatusId: string;
    billOfMaterialsStatusName: string;
  } | null> | null;
};

export type TasktypeClassesQuery = {
  tasktypeClasses?: Array<{
    __typename: 'TaskTypeClass';
    taskTypeClassId: string;
    taskTypeClassName?: string | null;
  } | null> | null;
};

export type UnitOfMeasureTypesQuery = {
  unitOfMeasureTypes?: Array<{
    __typename: 'UnitOfMeasureType';
    unitOfMeasureTypeId: string;
    unitOfMeasureTypeName: string;
    unitOfMeasureCode?: string | null;
  } | null> | null;
};

export type LicenseClassQuery = {
  licenseClass?: Array<{
    __typename: 'LicenseClass';
    licenseClassId: string;
    licenseClassName: string;
    sortOrder?: number | null;
  } | null> | null;
};

export type WorkOrderStatusesListQuery = {
  workOrderStatusesList?: Array<{
    __typename: 'WorkOrderStatusesList';
    workOrderStatusTypeId?: string | null;
    workOrderStatusTypeName?: string | null;
  } | null> | null;
};

export type SyncConnectorsListQuery = {
  syncConnectorsList?: Array<{
    __typename: 'SyncConnectors';
    name?: string | null;
    url?: string | null;
    logoutUrl?: string | null;
    realTimeSync?: boolean | null;
    isLicensed?: boolean | null;
  } | null> | null;
};

export type GetSupportedExtensionsQuery = {
  getSupportedExtensions?: Array<{
    __typename: 'SupportedExtension';
    type?: string | null;
    extensions?: Array<string | null> | null;
  } | null> | null;
};

export type TaskStatusTypesQuery = {
  taskStatusTypes?: Array<{
    __typename: 'TaskStatusType';
    taskStatusTypeId: string;
    taskStatusTypeName?: string | null;
    createdOn?: string | null;
    createdBy?: string | null;
    updatedOn?: string | null;
    updatedBy?: string | null;
    deletedOn?: string | null;
    deletedBy?: string | null;
  } | null> | null;
};

export type SyncGetAllWorkflowMappingTemplatesQuery = {
  syncGetAllWorkflowMappingTemplates?: Array<{
    __typename: 'WorkflowMappingTemplate';
    templateId?: string | null;
    templateName?: string | null;
  } | null> | null;
};

export type DrawingTypeCategoriesQuery = {
  drawingTypeCategories?: Array<{
    __typename: 'DrawingTypeCategory';
    companyId?: string | null;
    userDefinedId?: string | null;
    name?: string | null;
    hasDrawingTypes?: boolean | null;
    id?: string | null;
    createdBy?: string | null;
    createdOnUtc?: string | null;
    modifiedBy?: string | null;
    modifiedOnUtc?: string | null;
  } | null> | null;
};

export type DocumentTypesQuery = {
  documentTypes?: Array<{
    __typename: 'DocumentType';
    documentTypeId: string;
    documentTypeName?: string | null;
  } | null> | null;
};

export type AssemblyNodeTypeQuery = {
  assemblyNodeType?: Array<{
    __typename: 'AssemblyNodeType';
    assemblyNodeTypeId?: string | null;
    assemblyNodeTypeName?: string | null;
  } | null> | null;
};

export type AssemblyEdgeTypeQuery = {
  assemblyEdgeType?: Array<{
    __typename: 'AssemblyEdgeType';
    assemblyEdgeTypeId?: string | null;
    assemblyEdgeTypeName?: string | null;
  } | null> | null;
};

export type SyncLogsDataUsageReportQuery = {
  syncLogsDataUsageReport?: {
    __typename: 'SyncLogsDataUsageReport';
    monthlyDataUsage?: string | null;
    yearlyDataUsage?: string | null;
  } | null;
};

export type UserStatusQueryVariables = {
  params: QueryUserStatusParamsInput;
};

export type UserStatusQuery = {
  userStatus?: {
    __typename: 'UserStatus';
    userEmail?: string | null;
    userName?: string | null;
    userStatus?: USER_STATUS | null;
  } | null;
};

export type UserStatusByIdQueryVariables = {
  params: QueryUserStatusByIdParamsInput;
};

export type UserStatusByIdQuery = {
  userStatusById?: {
    __typename: 'UserStatus';
    userEmail?: string | null;
    userName?: string | null;
    userStatus?: USER_STATUS | null;
  } | null;
};

export type UserByNameQueryVariables = {
  params: QueryUserByNameParamsInput;
};

export type UserByNameQuery = {
  userByName?: {
    __typename: 'User';
    userId: string;
    userName: string;
    userFirstName?: string | null;
    userLastName?: string | null;
    userIdentifier?: string | null;
    userEmail?: string | null;
    workPhoneNumber?: string | null;
    personalPhoneNumber?: string | null;
    userStatus?: string | null;
    userStatusId?: string | null;
    inviteStatus?: USER_INVITE_STATUS | null;
    userTypes?: Array<{
      __typename: 'UserType';
      userTypeId?: string | null;
      typeName?: string | null;
      typeId?: string | null;
    } | null> | null;
    acceptedLatestEula?: boolean | null;
    companyId?: string | null;
    isAdmin?: boolean | null;
    isDeleted?: boolean | null;
    userPhotoId?: string | null;
  } | null;
};

export type UserByIdQueryVariables = {
  params: QueryUserByIdParamsInput;
};

export type UserByIdQuery = {
  userById?: {
    __typename: 'User';
    userId: string;
    userName: string;
    userFirstName?: string | null;
    userLastName?: string | null;
    userIdentifier?: string | null;
    userEmail?: string | null;
    workPhoneNumber?: string | null;
    personalPhoneNumber?: string | null;
    userStatus?: string | null;
    userStatusId?: string | null;
    inviteStatus?: USER_INVITE_STATUS | null;
    userTypes?: Array<{
      __typename: 'UserType';
      userTypeId?: string | null;
      typeName?: string | null;
      typeId?: string | null;
    } | null> | null;
    acceptedLatestEula?: boolean | null;
    companyId?: string | null;
    isAdmin?: boolean | null;
    isDeleted?: boolean | null;
    userPhotoId?: string | null;
  } | null;
};

export type UsersQueryVariables = {
  query?: QueryUsersQueryInput | null;
};

export type UsersQuery = {
  users?: Array<{
    __typename: 'User';
    userId: string;
    userName: string;
    userFirstName?: string | null;
    userLastName?: string | null;
    userIdentifier?: string | null;
    userEmail?: string | null;
    workPhoneNumber?: string | null;
    personalPhoneNumber?: string | null;
    userStatus?: string | null;
    userStatusId?: string | null;
    inviteStatus?: USER_INVITE_STATUS | null;
    userTypes?: Array<{
      __typename: 'UserType';
      userTypeId?: string | null;
      typeName?: string | null;
      typeId?: string | null;
    } | null> | null;
    acceptedLatestEula?: boolean | null;
    companyId?: string | null;
    isAdmin?: boolean | null;
    isDeleted?: boolean | null;
    userPhotoId?: string | null;
  } | null> | null;
};

export type ProjectUsersQueryVariables = {
  query: QueryProjectUsersQueryInput;
};

export type ProjectUsersQuery = {
  projectUsers?: Array<{
    __typename: 'ProjectUser';
    projectUserId: string;
    projectId: string;
    inviteStatus?: PROJECT_USER_INVITE_STATUS | null;
    expiresAt?: string | null;
    isAdmin?: boolean | null;
    isDeleted?: boolean | null;
    user?: {
      __typename: 'User';
      userId: string;
      userName: string;
      userFirstName?: string | null;
      userLastName?: string | null;
      userIdentifier?: string | null;
      userEmail?: string | null;
      workPhoneNumber?: string | null;
      personalPhoneNumber?: string | null;
      userStatus?: string | null;
      userStatusId?: string | null;
      inviteStatus?: USER_INVITE_STATUS | null;
      userTypes?: Array<{
        __typename: 'UserType';
        userTypeId?: string | null;
        typeName?: string | null;
        typeId?: string | null;
      } | null> | null;
      acceptedLatestEula?: boolean | null;
      companyId?: string | null;
      isAdmin?: boolean | null;
      isDeleted?: boolean | null;
      userPhotoId?: string | null;
    } | null;
  } | null> | null;
};

export type ProjectTypesQueryVariables = {
  query: QueryProjectTypesQueryInput;
};

export type ProjectTypesQuery = {
  projectTypes?: Array<{
    __typename: 'ProjectType';
    projectTypeId?: string | null;
    projectTypeName?: string | null;
    companyId?: string | null;
  } | null> | null;
};

export type ProjectsQueryVariables = {
  query: QueryProjectsQueryInput;
};

export type ProjectsQuery = {
  projects?: Array<{
    __typename: 'Project';
    projectId: string;
    projectName?: string | null;
    projectIdentifier?: string | null;
    dataAnalytics?: boolean | null;
    units?: string | null;
    companyId?: string | null;
    address?: {
      __typename: 'Address';
      addressId?: string | null;
      addressLine1?: string | null;
      addressLine2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zip?: string | null;
    } | null;
    projectImageId?: string | null;
    disallowAutomaticDistributionQty?: boolean | null;
    projectType?: {
      __typename: 'ProjectType';
      projectTypeId?: string | null;
      projectTypeName?: string | null;
      companyId?: string | null;
    } | null;
  } | null> | null;
};

export type ProjectsMultiSearchQueryVariables = {
  query: QueryProjectsMultiSearchQueryInput;
};

export type ProjectsMultiSearchQuery = {
  projectsMultiSearch?: Array<{
    __typename: 'Project';
    projectId: string;
    projectName?: string | null;
    projectIdentifier?: string | null;
    dataAnalytics?: boolean | null;
    units?: string | null;
    companyId?: string | null;
    address?: {
      __typename: 'Address';
      addressId?: string | null;
      addressLine1?: string | null;
      addressLine2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zip?: string | null;
    } | null;
    projectImageId?: string | null;
    disallowAutomaticDistributionQty?: boolean | null;
    projectType?: {
      __typename: 'ProjectType';
      projectTypeId?: string | null;
      projectTypeName?: string | null;
      companyId?: string | null;
    } | null;
  } | null> | null;
};

export type ProjectsFromDepartmentUsersQueryVariables = {
  query: QueryProjectsFromDepartmentUsersQueryInput;
};

export type ProjectsFromDepartmentUsersQuery = {
  projectsFromDepartmentUsers?: Array<{
    __typename: 'Project';
    projectId: string;
    projectName?: string | null;
    projectIdentifier?: string | null;
    dataAnalytics?: boolean | null;
    units?: string | null;
    companyId?: string | null;
    address?: {
      __typename: 'Address';
      addressId?: string | null;
      addressLine1?: string | null;
      addressLine2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zip?: string | null;
    } | null;
    projectImageId?: string | null;
    disallowAutomaticDistributionQty?: boolean | null;
    projectType?: {
      __typename: 'ProjectType';
      projectTypeId?: string | null;
      projectTypeName?: string | null;
      companyId?: string | null;
    } | null;
  } | null> | null;
};

export type ProjectByIdQueryVariables = {
  params: QueryProjectByIdParamsInput;
};

export type ProjectByIdQuery = {
  projectById?: {
    __typename: 'ProjectWithDepartment';
    projectId: string;
    projectName?: string | null;
    projectIdentifier?: string | null;
    dataAnalytics?: boolean | null;
    units?: string | null;
    companyId?: string | null;
    address?: {
      __typename: 'Address';
      addressId?: string | null;
      addressLine1?: string | null;
      addressLine2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zip?: string | null;
    } | null;
    projectImageId?: string | null;
    departments?: Array<{
      __typename: 'ProjectDepartment';
      departmentId?: string | null;
      departmentName?: string | null;
      departmentTypeId?: string | null;
      facility?: {
        __typename: 'FacilityDepartment';
        facilityId?: string | null;
        facilityName?: string | null;
      } | null;
    } | null> | null;
    disallowAutomaticDistributionQty?: boolean | null;
    projectType?: {
      __typename: 'ProjectType';
      projectTypeId?: string | null;
      projectTypeName?: string | null;
      companyId?: string | null;
    } | null;
  } | null;
};

export type ProductQueryVariables = {
  params: QueryProductParamsInput;
};

export type ProductQuery = {
  product?: {
    __typename: 'Product';
    productId: string;
    productName?: string | null;
    baseProductName?: string | null;
    isForesiteModule?: boolean | null;
    licenseTypeId?: string | null;
    licenseTypeName?: string | null;
    licenseClassId?: string | null;
    licenseClassName?: string | null;
    productNameFormatted?: string | null;
    onlyVisibleToMembers?: boolean | null;
    supportsTrial?: boolean | null;
    productLicenseTypes?: Array<{
      __typename: 'ProductLicenseType';
      productLicenseTypeId: string;
      productId: string;
      licenseTypeId: string;
      licenseType?: {
        __typename: 'LicenseType';
        licenseTypeId: string;
        licenseTypeName: string;
        licenseTypeCode?: LICENSE_CODE | null;
        sortOrder?: number | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ProductsQueryVariables = {
  query?: QueryProductsQueryInput | null;
};

export type ProductsQuery = {
  products?: Array<{
    __typename: 'Product';
    productId: string;
    productName?: string | null;
    baseProductName?: string | null;
    isForesiteModule?: boolean | null;
    licenseTypeId?: string | null;
    licenseTypeName?: string | null;
    licenseClassId?: string | null;
    licenseClassName?: string | null;
    productNameFormatted?: string | null;
    onlyVisibleToMembers?: boolean | null;
    supportsTrial?: boolean | null;
    productLicenseTypes?: Array<{
      __typename: 'ProductLicenseType';
      productLicenseTypeId: string;
      productId: string;
      licenseTypeId: string;
      licenseType?: {
        __typename: 'LicenseType';
        licenseTypeId: string;
        licenseTypeName: string;
        licenseTypeCode?: LICENSE_CODE | null;
        sortOrder?: number | null;
      } | null;
    } | null> | null;
  } | null> | null;
};

export type BundlesQueryVariables = {
  query?: QueryBundlesQueryInput | null;
};

export type BundlesQuery = {
  bundles?: Array<{
    __typename: 'Bundle';
    bundleId: string;
    bundleName: string;
    bundleProducts?: Array<{
      __typename: 'BundleProduct';
      bundleProductId: string;
      productId: string;
      product?: {
        __typename: 'Product';
        productId: string;
        productName?: string | null;
        baseProductName?: string | null;
        isForesiteModule?: boolean | null;
        licenseTypeId?: string | null;
        licenseTypeName?: string | null;
        licenseClassId?: string | null;
        licenseClassName?: string | null;
        productNameFormatted?: string | null;
        onlyVisibleToMembers?: boolean | null;
        supportsTrial?: boolean | null;
        productLicenseTypes?: Array<{
          __typename: 'ProductLicenseType';
          productLicenseTypeId: string;
          productId: string;
          licenseTypeId: string;
          licenseType?: {
            __typename: 'LicenseType';
            licenseTypeId: string;
            licenseTypeName: string;
            licenseTypeCode?: LICENSE_CODE | null;
            sortOrder?: number | null;
          } | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null> | null;
};

export type ProductPoolByIdQueryVariables = {
  params: QueryProductPoolByIdParamsInput;
};

export type ProductPoolByIdQuery = {
  productPoolById?: {
    __typename: 'ProductPool';
    productPoolId: string;
    poolQuantity?: number | null;
    productId?: string | null;
    bundleId?: string | null;
    type?: string | null;
    label?: string | null;
    poolAvailableQuantity?: number | null;
    renewalDate?: string | null;
    customData?: string | null;
    termDate?: string | null;
    startDate?: string | null;
    trialRenewalDate?: string | null;
    company?: {
      __typename: 'Company';
      companyId: string;
      companyName?: string | null;
      renewalDate?: string | null;
      mfA_Required?: boolean | null;
      data_Analytics?: boolean | null;
      companyDownloadPaths?: Array<{
        __typename: 'CompanyDownloadPath';
        document?: string | null;
        updateBy?: string | null;
        updatedOn?: string | null;
      } | null> | null;
      companyImageId?: string | null;
      address?: {
        __typename: 'Address';
        addressId?: string | null;
        addressLine1?: string | null;
        addressLine2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zip?: string | null;
      } | null;
      productPool?: Array<{
        __typename: 'ProductPool';
        productPoolId: string;
        poolQuantity?: number | null;
        productId?: string | null;
        bundleId?: string | null;
        type?: string | null;
        label?: string | null;
        poolAvailableQuantity?: number | null;
        renewalDate?: string | null;
        customData?: string | null;
        termDate?: string | null;
        startDate?: string | null;
        trialRenewalDate?: string | null;
        company?: {
          __typename: 'Company';
          companyId: string;
          companyName?: string | null;
          renewalDate?: string | null;
          mfA_Required?: boolean | null;
          data_Analytics?: boolean | null;
          companyDownloadPaths?: Array<{
            __typename: 'CompanyDownloadPath';
            document?: string | null;
            updateBy?: string | null;
            updatedOn?: string | null;
          } | null> | null;
          companyImageId?: string | null;
          address?: {
            __typename: 'Address';
            addressId?: string | null;
            addressLine1?: string | null;
            addressLine2?: string | null;
            city?: string | null;
            state?: string | null;
            country?: string | null;
            zip?: string | null;
          } | null;
          productPool?: Array<{
            __typename: 'ProductPool';
            productPoolId: string;
            poolQuantity?: number | null;
            productId?: string | null;
            bundleId?: string | null;
            type?: string | null;
            label?: string | null;
            poolAvailableQuantity?: number | null;
            renewalDate?: string | null;
            customData?: string | null;
            termDate?: string | null;
            startDate?: string | null;
            trialRenewalDate?: string | null;
            company?: {
              __typename: 'Company';
              companyId: string;
              companyName?: string | null;
              renewalDate?: string | null;
              mfA_Required?: boolean | null;
              data_Analytics?: boolean | null;
              companyImageId?: string | null;
            } | null;
            product?: {
              __typename: 'Product';
              productId: string;
              productName?: string | null;
              baseProductName?: string | null;
              isForesiteModule?: boolean | null;
              licenseTypeId?: string | null;
              licenseTypeName?: string | null;
              licenseClassId?: string | null;
              licenseClassName?: string | null;
              productNameFormatted?: string | null;
              onlyVisibleToMembers?: boolean | null;
              supportsTrial?: boolean | null;
            } | null;
          } | null> | null;
        } | null;
        product?: {
          __typename: 'Product';
          productId: string;
          productName?: string | null;
          baseProductName?: string | null;
          isForesiteModule?: boolean | null;
          licenseTypeId?: string | null;
          licenseTypeName?: string | null;
          licenseClassId?: string | null;
          licenseClassName?: string | null;
          productNameFormatted?: string | null;
          onlyVisibleToMembers?: boolean | null;
          supportsTrial?: boolean | null;
          productLicenseTypes?: Array<{
            __typename: 'ProductLicenseType';
            productLicenseTypeId: string;
            productId: string;
            licenseTypeId: string;
            licenseType?: {
              __typename: 'LicenseType';
              licenseTypeId: string;
              licenseTypeName: string;
              licenseTypeCode?: LICENSE_CODE | null;
              sortOrder?: number | null;
            } | null;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null;
    product?: {
      __typename: 'Product';
      productId: string;
      productName?: string | null;
      baseProductName?: string | null;
      isForesiteModule?: boolean | null;
      licenseTypeId?: string | null;
      licenseTypeName?: string | null;
      licenseClassId?: string | null;
      licenseClassName?: string | null;
      productNameFormatted?: string | null;
      onlyVisibleToMembers?: boolean | null;
      supportsTrial?: boolean | null;
      productLicenseTypes?: Array<{
        __typename: 'ProductLicenseType';
        productLicenseTypeId: string;
        productId: string;
        licenseTypeId: string;
        licenseType?: {
          __typename: 'LicenseType';
          licenseTypeId: string;
          licenseTypeName: string;
          licenseTypeCode?: LICENSE_CODE | null;
          sortOrder?: number | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type ProductPoolsQueryVariables = {
  query: QueryProductPoolsQueryInput;
};

export type ProductPoolsQuery = {
  productPools?: Array<{
    __typename: 'ProductPool';
    productPoolId: string;
    poolQuantity?: number | null;
    productId?: string | null;
    bundleId?: string | null;
    type?: string | null;
    label?: string | null;
    poolAvailableQuantity?: number | null;
    renewalDate?: string | null;
    customData?: string | null;
    termDate?: string | null;
    startDate?: string | null;
    trialRenewalDate?: string | null;
    company?: {
      __typename: 'Company';
      companyId: string;
      companyName?: string | null;
      renewalDate?: string | null;
      mfA_Required?: boolean | null;
      data_Analytics?: boolean | null;
      companyDownloadPaths?: Array<{
        __typename: 'CompanyDownloadPath';
        document?: string | null;
        updateBy?: string | null;
        updatedOn?: string | null;
      } | null> | null;
      companyImageId?: string | null;
      address?: {
        __typename: 'Address';
        addressId?: string | null;
        addressLine1?: string | null;
        addressLine2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zip?: string | null;
      } | null;
      productPool?: Array<{
        __typename: 'ProductPool';
        productPoolId: string;
        poolQuantity?: number | null;
        productId?: string | null;
        bundleId?: string | null;
        type?: string | null;
        label?: string | null;
        poolAvailableQuantity?: number | null;
        renewalDate?: string | null;
        customData?: string | null;
        termDate?: string | null;
        startDate?: string | null;
        trialRenewalDate?: string | null;
        company?: {
          __typename: 'Company';
          companyId: string;
          companyName?: string | null;
          renewalDate?: string | null;
          mfA_Required?: boolean | null;
          data_Analytics?: boolean | null;
          companyDownloadPaths?: Array<{
            __typename: 'CompanyDownloadPath';
            document?: string | null;
            updateBy?: string | null;
            updatedOn?: string | null;
          } | null> | null;
          companyImageId?: string | null;
          address?: {
            __typename: 'Address';
            addressId?: string | null;
            addressLine1?: string | null;
            addressLine2?: string | null;
            city?: string | null;
            state?: string | null;
            country?: string | null;
            zip?: string | null;
          } | null;
          productPool?: Array<{
            __typename: 'ProductPool';
            productPoolId: string;
            poolQuantity?: number | null;
            productId?: string | null;
            bundleId?: string | null;
            type?: string | null;
            label?: string | null;
            poolAvailableQuantity?: number | null;
            renewalDate?: string | null;
            customData?: string | null;
            termDate?: string | null;
            startDate?: string | null;
            trialRenewalDate?: string | null;
            company?: {
              __typename: 'Company';
              companyId: string;
              companyName?: string | null;
              renewalDate?: string | null;
              mfA_Required?: boolean | null;
              data_Analytics?: boolean | null;
              companyImageId?: string | null;
            } | null;
            product?: {
              __typename: 'Product';
              productId: string;
              productName?: string | null;
              baseProductName?: string | null;
              isForesiteModule?: boolean | null;
              licenseTypeId?: string | null;
              licenseTypeName?: string | null;
              licenseClassId?: string | null;
              licenseClassName?: string | null;
              productNameFormatted?: string | null;
              onlyVisibleToMembers?: boolean | null;
              supportsTrial?: boolean | null;
            } | null;
          } | null> | null;
        } | null;
        product?: {
          __typename: 'Product';
          productId: string;
          productName?: string | null;
          baseProductName?: string | null;
          isForesiteModule?: boolean | null;
          licenseTypeId?: string | null;
          licenseTypeName?: string | null;
          licenseClassId?: string | null;
          licenseClassName?: string | null;
          productNameFormatted?: string | null;
          onlyVisibleToMembers?: boolean | null;
          supportsTrial?: boolean | null;
          productLicenseTypes?: Array<{
            __typename: 'ProductLicenseType';
            productLicenseTypeId: string;
            productId: string;
            licenseTypeId: string;
            licenseType?: {
              __typename: 'LicenseType';
              licenseTypeId: string;
              licenseTypeName: string;
              licenseTypeCode?: LICENSE_CODE | null;
              sortOrder?: number | null;
            } | null;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null;
    product?: {
      __typename: 'Product';
      productId: string;
      productName?: string | null;
      baseProductName?: string | null;
      isForesiteModule?: boolean | null;
      licenseTypeId?: string | null;
      licenseTypeName?: string | null;
      licenseClassId?: string | null;
      licenseClassName?: string | null;
      productNameFormatted?: string | null;
      onlyVisibleToMembers?: boolean | null;
      supportsTrial?: boolean | null;
      productLicenseTypes?: Array<{
        __typename: 'ProductLicenseType';
        productLicenseTypeId: string;
        productId: string;
        licenseTypeId: string;
        licenseType?: {
          __typename: 'LicenseType';
          licenseTypeId: string;
          licenseTypeName: string;
          licenseTypeCode?: LICENSE_CODE | null;
          sortOrder?: number | null;
        } | null;
      } | null> | null;
    } | null;
  } | null> | null;
};

export type FacilitiesQueryVariables = {
  query: QueryFacilitiesQueryInput;
};

export type FacilitiesQuery = {
  facilities?: Array<{
    __typename: 'Facility';
    facilityId: string;
    facilityName: string;
    companyId: string;
    address?: {
      __typename: 'Address';
      addressId?: string | null;
      addressLine1?: string | null;
      addressLine2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zip?: string | null;
    } | null;
    departments: Array<{
      __typename: 'Department';
      departmentId?: string | null;
      departmentName?: string | null;
      departmentTypeId?: string | null;
      facility?: {
        __typename: 'DepartmentFacility';
        facilityId?: string | null;
        facilityName?: string | null;
      } | null;
      departmentType?: {
        __typename: 'DepartmentType';
        departmentTypeId?: string | null;
        departmentTypeName?: string | null;
      } | null;
      adminUser?: {
        __typename: 'User';
        userId: string;
        userName: string;
        userFirstName?: string | null;
        userLastName?: string | null;
        userIdentifier?: string | null;
        userEmail?: string | null;
        workPhoneNumber?: string | null;
        personalPhoneNumber?: string | null;
        userStatus?: string | null;
        userStatusId?: string | null;
        inviteStatus?: USER_INVITE_STATUS | null;
        userTypes?: Array<{
          __typename: 'UserType';
          userTypeId?: string | null;
          typeName?: string | null;
          typeId?: string | null;
        } | null> | null;
        acceptedLatestEula?: boolean | null;
        companyId?: string | null;
        isAdmin?: boolean | null;
        isDeleted?: boolean | null;
        userPhotoId?: string | null;
      } | null;
    } | null>;
  } | null> | null;
};

export type FacilityByIdQueryVariables = {
  params: QueryFacilityByIdParamsInput;
};

export type FacilityByIdQuery = {
  facilityById?: {
    __typename: 'Facility';
    facilityId: string;
    facilityName: string;
    companyId: string;
    address?: {
      __typename: 'Address';
      addressId?: string | null;
      addressLine1?: string | null;
      addressLine2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zip?: string | null;
    } | null;
    departments: Array<{
      __typename: 'Department';
      departmentId?: string | null;
      departmentName?: string | null;
      departmentTypeId?: string | null;
      facility?: {
        __typename: 'DepartmentFacility';
        facilityId?: string | null;
        facilityName?: string | null;
      } | null;
      departmentType?: {
        __typename: 'DepartmentType';
        departmentTypeId?: string | null;
        departmentTypeName?: string | null;
      } | null;
      adminUser?: {
        __typename: 'User';
        userId: string;
        userName: string;
        userFirstName?: string | null;
        userLastName?: string | null;
        userIdentifier?: string | null;
        userEmail?: string | null;
        workPhoneNumber?: string | null;
        personalPhoneNumber?: string | null;
        userStatus?: string | null;
        userStatusId?: string | null;
        inviteStatus?: USER_INVITE_STATUS | null;
        userTypes?: Array<{
          __typename: 'UserType';
          userTypeId?: string | null;
          typeName?: string | null;
          typeId?: string | null;
        } | null> | null;
        acceptedLatestEula?: boolean | null;
        companyId?: string | null;
        isAdmin?: boolean | null;
        isDeleted?: boolean | null;
        userPhotoId?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type EntitlementsQueryVariables = {
  query?: QueryEntitlementsQueryInput | null;
};

export type EntitlementsQuery = {
  entitlements?: Array<{
    __typename: 'Entitlement';
    entitlementId: string;
    userId: string;
    isCheckedOut?: boolean | null;
    checkedOutEndDate?: string | null;
    secureData?: string | null;
    secureSignature?: string | null;
    licenseTypeCode?: LICENSE_CODE | null;
    productPool?: {
      __typename: 'ProductPool';
      productPoolId: string;
      poolQuantity?: number | null;
      productId?: string | null;
      bundleId?: string | null;
      type?: string | null;
      label?: string | null;
      poolAvailableQuantity?: number | null;
      renewalDate?: string | null;
      customData?: string | null;
      termDate?: string | null;
      startDate?: string | null;
      trialRenewalDate?: string | null;
      company?: {
        __typename: 'Company';
        companyId: string;
        companyName?: string | null;
        renewalDate?: string | null;
        mfA_Required?: boolean | null;
        data_Analytics?: boolean | null;
        companyDownloadPaths?: Array<{
          __typename: 'CompanyDownloadPath';
          document?: string | null;
          updateBy?: string | null;
          updatedOn?: string | null;
        } | null> | null;
        companyImageId?: string | null;
        address?: {
          __typename: 'Address';
          addressId?: string | null;
          addressLine1?: string | null;
          addressLine2?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
          zip?: string | null;
        } | null;
        productPool?: Array<{
          __typename: 'ProductPool';
          productPoolId: string;
          poolQuantity?: number | null;
          productId?: string | null;
          bundleId?: string | null;
          type?: string | null;
          label?: string | null;
          poolAvailableQuantity?: number | null;
          renewalDate?: string | null;
          customData?: string | null;
          termDate?: string | null;
          startDate?: string | null;
          trialRenewalDate?: string | null;
          company?: {
            __typename: 'Company';
            companyId: string;
            companyName?: string | null;
            renewalDate?: string | null;
            mfA_Required?: boolean | null;
            data_Analytics?: boolean | null;
            companyDownloadPaths?: Array<{
              __typename: 'CompanyDownloadPath';
              document?: string | null;
              updateBy?: string | null;
              updatedOn?: string | null;
            } | null> | null;
            companyImageId?: string | null;
            address?: {
              __typename: 'Address';
              addressId?: string | null;
              addressLine1?: string | null;
              addressLine2?: string | null;
              city?: string | null;
              state?: string | null;
              country?: string | null;
              zip?: string | null;
            } | null;
            productPool?: Array<{
              __typename: 'ProductPool';
              productPoolId: string;
              poolQuantity?: number | null;
              productId?: string | null;
              bundleId?: string | null;
              type?: string | null;
              label?: string | null;
              poolAvailableQuantity?: number | null;
              renewalDate?: string | null;
              customData?: string | null;
              termDate?: string | null;
              startDate?: string | null;
              trialRenewalDate?: string | null;
            } | null> | null;
          } | null;
          product?: {
            __typename: 'Product';
            productId: string;
            productName?: string | null;
            baseProductName?: string | null;
            isForesiteModule?: boolean | null;
            licenseTypeId?: string | null;
            licenseTypeName?: string | null;
            licenseClassId?: string | null;
            licenseClassName?: string | null;
            productNameFormatted?: string | null;
            onlyVisibleToMembers?: boolean | null;
            supportsTrial?: boolean | null;
            productLicenseTypes?: Array<{
              __typename: 'ProductLicenseType';
              productLicenseTypeId: string;
              productId: string;
              licenseTypeId: string;
            } | null> | null;
          } | null;
        } | null> | null;
      } | null;
      product?: {
        __typename: 'Product';
        productId: string;
        productName?: string | null;
        baseProductName?: string | null;
        isForesiteModule?: boolean | null;
        licenseTypeId?: string | null;
        licenseTypeName?: string | null;
        licenseClassId?: string | null;
        licenseClassName?: string | null;
        productNameFormatted?: string | null;
        onlyVisibleToMembers?: boolean | null;
        supportsTrial?: boolean | null;
        productLicenseTypes?: Array<{
          __typename: 'ProductLicenseType';
          productLicenseTypeId: string;
          productId: string;
          licenseTypeId: string;
          licenseType?: {
            __typename: 'LicenseType';
            licenseTypeId: string;
            licenseTypeName: string;
            licenseTypeCode?: LICENSE_CODE | null;
            sortOrder?: number | null;
          } | null;
        } | null> | null;
      } | null;
    } | null;
  } | null> | null;
};

export type DepartmentsQueryVariables = {
  query: QueryDepartmentsQueryInput;
};

export type DepartmentsQuery = {
  departments?: Array<{
    __typename: 'Department';
    departmentId?: string | null;
    departmentName?: string | null;
    departmentTypeId?: string | null;
    facility?: {
      __typename: 'DepartmentFacility';
      facilityId?: string | null;
      facilityName?: string | null;
    } | null;
    departmentType?: {
      __typename: 'DepartmentType';
      departmentTypeId?: string | null;
      departmentTypeName?: string | null;
    } | null;
    adminUser?: {
      __typename: 'User';
      userId: string;
      userName: string;
      userFirstName?: string | null;
      userLastName?: string | null;
      userIdentifier?: string | null;
      userEmail?: string | null;
      workPhoneNumber?: string | null;
      personalPhoneNumber?: string | null;
      userStatus?: string | null;
      userStatusId?: string | null;
      inviteStatus?: USER_INVITE_STATUS | null;
      userTypes?: Array<{
        __typename: 'UserType';
        userTypeId?: string | null;
        typeName?: string | null;
        typeId?: string | null;
      } | null> | null;
      acceptedLatestEula?: boolean | null;
      companyId?: string | null;
      isAdmin?: boolean | null;
      isDeleted?: boolean | null;
      userPhotoId?: string | null;
    } | null;
  } | null> | null;
};

export type DepartmentUsersQueryVariables = {
  query: QueryDepartmentUsersQueryInput;
};

export type DepartmentUsersQuery = {
  departmentUsers?: Array<{
    __typename: 'DepartmentUser';
    departmentUserId: string;
    departmentId: string;
    expiresAt?: string | null;
    inviteStatus?: DEPARTMENT_USER_INVITE_STATUS | null;
    isDeleted?: boolean | null;
    isAdmin?: boolean | null;
    user?: {
      __typename: 'User';
      userId: string;
      userName: string;
      userFirstName?: string | null;
      userLastName?: string | null;
      userIdentifier?: string | null;
      userEmail?: string | null;
      workPhoneNumber?: string | null;
      personalPhoneNumber?: string | null;
      userStatus?: string | null;
      userStatusId?: string | null;
      inviteStatus?: USER_INVITE_STATUS | null;
      userTypes?: Array<{
        __typename: 'UserType';
        userTypeId?: string | null;
        typeName?: string | null;
        typeId?: string | null;
      } | null> | null;
      acceptedLatestEula?: boolean | null;
      companyId?: string | null;
      isAdmin?: boolean | null;
      isDeleted?: boolean | null;
      userPhotoId?: string | null;
    } | null;
  } | null> | null;
};

export type CompaniesQueryVariables = {
  query?: QueryCompaniesQueryInput | null;
};

export type CompaniesQuery = {
  companies?: Array<{
    __typename: 'Company';
    companyId: string;
    companyName?: string | null;
    renewalDate?: string | null;
    mfA_Required?: boolean | null;
    data_Analytics?: boolean | null;
    companyDownloadPaths?: Array<{
      __typename: 'CompanyDownloadPath';
      document?: string | null;
      updateBy?: string | null;
      updatedOn?: string | null;
    } | null> | null;
    companyImageId?: string | null;
    address?: {
      __typename: 'Address';
      addressId?: string | null;
      addressLine1?: string | null;
      addressLine2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zip?: string | null;
    } | null;
    productPool?: Array<{
      __typename: 'ProductPool';
      productPoolId: string;
      poolQuantity?: number | null;
      productId?: string | null;
      bundleId?: string | null;
      type?: string | null;
      label?: string | null;
      poolAvailableQuantity?: number | null;
      renewalDate?: string | null;
      customData?: string | null;
      termDate?: string | null;
      startDate?: string | null;
      trialRenewalDate?: string | null;
      company?: {
        __typename: 'Company';
        companyId: string;
        companyName?: string | null;
        renewalDate?: string | null;
        mfA_Required?: boolean | null;
        data_Analytics?: boolean | null;
        companyDownloadPaths?: Array<{
          __typename: 'CompanyDownloadPath';
          document?: string | null;
          updateBy?: string | null;
          updatedOn?: string | null;
        } | null> | null;
        companyImageId?: string | null;
        address?: {
          __typename: 'Address';
          addressId?: string | null;
          addressLine1?: string | null;
          addressLine2?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
          zip?: string | null;
        } | null;
        productPool?: Array<{
          __typename: 'ProductPool';
          productPoolId: string;
          poolQuantity?: number | null;
          productId?: string | null;
          bundleId?: string | null;
          type?: string | null;
          label?: string | null;
          poolAvailableQuantity?: number | null;
          renewalDate?: string | null;
          customData?: string | null;
          termDate?: string | null;
          startDate?: string | null;
          trialRenewalDate?: string | null;
          company?: {
            __typename: 'Company';
            companyId: string;
            companyName?: string | null;
            renewalDate?: string | null;
            mfA_Required?: boolean | null;
            data_Analytics?: boolean | null;
            companyDownloadPaths?: Array<{
              __typename: 'CompanyDownloadPath';
              document?: string | null;
              updateBy?: string | null;
              updatedOn?: string | null;
            } | null> | null;
            companyImageId?: string | null;
            address?: {
              __typename: 'Address';
              addressId?: string | null;
              addressLine1?: string | null;
              addressLine2?: string | null;
              city?: string | null;
              state?: string | null;
              country?: string | null;
              zip?: string | null;
            } | null;
            productPool?: Array<{
              __typename: 'ProductPool';
              productPoolId: string;
              poolQuantity?: number | null;
              productId?: string | null;
              bundleId?: string | null;
              type?: string | null;
              label?: string | null;
              poolAvailableQuantity?: number | null;
              renewalDate?: string | null;
              customData?: string | null;
              termDate?: string | null;
              startDate?: string | null;
              trialRenewalDate?: string | null;
            } | null> | null;
          } | null;
          product?: {
            __typename: 'Product';
            productId: string;
            productName?: string | null;
            baseProductName?: string | null;
            isForesiteModule?: boolean | null;
            licenseTypeId?: string | null;
            licenseTypeName?: string | null;
            licenseClassId?: string | null;
            licenseClassName?: string | null;
            productNameFormatted?: string | null;
            onlyVisibleToMembers?: boolean | null;
            supportsTrial?: boolean | null;
            productLicenseTypes?: Array<{
              __typename: 'ProductLicenseType';
              productLicenseTypeId: string;
              productId: string;
              licenseTypeId: string;
            } | null> | null;
          } | null;
        } | null> | null;
      } | null;
      product?: {
        __typename: 'Product';
        productId: string;
        productName?: string | null;
        baseProductName?: string | null;
        isForesiteModule?: boolean | null;
        licenseTypeId?: string | null;
        licenseTypeName?: string | null;
        licenseClassId?: string | null;
        licenseClassName?: string | null;
        productNameFormatted?: string | null;
        onlyVisibleToMembers?: boolean | null;
        supportsTrial?: boolean | null;
        productLicenseTypes?: Array<{
          __typename: 'ProductLicenseType';
          productLicenseTypeId: string;
          productId: string;
          licenseTypeId: string;
          licenseType?: {
            __typename: 'LicenseType';
            licenseTypeId: string;
            licenseTypeName: string;
            licenseTypeCode?: LICENSE_CODE | null;
            sortOrder?: number | null;
          } | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null> | null;
};

export type CompanyByIdQueryVariables = {
  params: QueryCompanyByIdParamsInput;
};

export type CompanyByIdQuery = {
  companyById?: {
    __typename: 'Company';
    companyId: string;
    companyName?: string | null;
    renewalDate?: string | null;
    mfA_Required?: boolean | null;
    data_Analytics?: boolean | null;
    companyDownloadPaths?: Array<{
      __typename: 'CompanyDownloadPath';
      document?: string | null;
      updateBy?: string | null;
      updatedOn?: string | null;
    } | null> | null;
    companyImageId?: string | null;
    address?: {
      __typename: 'Address';
      addressId?: string | null;
      addressLine1?: string | null;
      addressLine2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zip?: string | null;
    } | null;
    productPool?: Array<{
      __typename: 'ProductPool';
      productPoolId: string;
      poolQuantity?: number | null;
      productId?: string | null;
      bundleId?: string | null;
      type?: string | null;
      label?: string | null;
      poolAvailableQuantity?: number | null;
      renewalDate?: string | null;
      customData?: string | null;
      termDate?: string | null;
      startDate?: string | null;
      trialRenewalDate?: string | null;
      company?: {
        __typename: 'Company';
        companyId: string;
        companyName?: string | null;
        renewalDate?: string | null;
        mfA_Required?: boolean | null;
        data_Analytics?: boolean | null;
        companyDownloadPaths?: Array<{
          __typename: 'CompanyDownloadPath';
          document?: string | null;
          updateBy?: string | null;
          updatedOn?: string | null;
        } | null> | null;
        companyImageId?: string | null;
        address?: {
          __typename: 'Address';
          addressId?: string | null;
          addressLine1?: string | null;
          addressLine2?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
          zip?: string | null;
        } | null;
        productPool?: Array<{
          __typename: 'ProductPool';
          productPoolId: string;
          poolQuantity?: number | null;
          productId?: string | null;
          bundleId?: string | null;
          type?: string | null;
          label?: string | null;
          poolAvailableQuantity?: number | null;
          renewalDate?: string | null;
          customData?: string | null;
          termDate?: string | null;
          startDate?: string | null;
          trialRenewalDate?: string | null;
          company?: {
            __typename: 'Company';
            companyId: string;
            companyName?: string | null;
            renewalDate?: string | null;
            mfA_Required?: boolean | null;
            data_Analytics?: boolean | null;
            companyDownloadPaths?: Array<{
              __typename: 'CompanyDownloadPath';
              document?: string | null;
              updateBy?: string | null;
              updatedOn?: string | null;
            } | null> | null;
            companyImageId?: string | null;
            address?: {
              __typename: 'Address';
              addressId?: string | null;
              addressLine1?: string | null;
              addressLine2?: string | null;
              city?: string | null;
              state?: string | null;
              country?: string | null;
              zip?: string | null;
            } | null;
            productPool?: Array<{
              __typename: 'ProductPool';
              productPoolId: string;
              poolQuantity?: number | null;
              productId?: string | null;
              bundleId?: string | null;
              type?: string | null;
              label?: string | null;
              poolAvailableQuantity?: number | null;
              renewalDate?: string | null;
              customData?: string | null;
              termDate?: string | null;
              startDate?: string | null;
              trialRenewalDate?: string | null;
            } | null> | null;
          } | null;
          product?: {
            __typename: 'Product';
            productId: string;
            productName?: string | null;
            baseProductName?: string | null;
            isForesiteModule?: boolean | null;
            licenseTypeId?: string | null;
            licenseTypeName?: string | null;
            licenseClassId?: string | null;
            licenseClassName?: string | null;
            productNameFormatted?: string | null;
            onlyVisibleToMembers?: boolean | null;
            supportsTrial?: boolean | null;
            productLicenseTypes?: Array<{
              __typename: 'ProductLicenseType';
              productLicenseTypeId: string;
              productId: string;
              licenseTypeId: string;
            } | null> | null;
          } | null;
        } | null> | null;
      } | null;
      product?: {
        __typename: 'Product';
        productId: string;
        productName?: string | null;
        baseProductName?: string | null;
        isForesiteModule?: boolean | null;
        licenseTypeId?: string | null;
        licenseTypeName?: string | null;
        licenseClassId?: string | null;
        licenseClassName?: string | null;
        productNameFormatted?: string | null;
        onlyVisibleToMembers?: boolean | null;
        supportsTrial?: boolean | null;
        productLicenseTypes?: Array<{
          __typename: 'ProductLicenseType';
          productLicenseTypeId: string;
          productId: string;
          licenseTypeId: string;
          licenseType?: {
            __typename: 'LicenseType';
            licenseTypeId: string;
            licenseTypeName: string;
            licenseTypeCode?: LICENSE_CODE | null;
            sortOrder?: number | null;
          } | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type CompanyProductPoolQueryVariables = {
  params: QueryCompanyProductPoolParamsInput;
  query?: QueryCompanyProductPoolQueryInput | null;
};

export type CompanyProductPoolQuery = {
  companyProductPool?: {
    __typename: 'CompanyProductPool';
    companyId: string;
    address?: string | null;
    renewalDate?: string | null;
    productPool?: Array<{
      __typename: 'ProductPool';
      productPoolId: string;
      poolQuantity?: number | null;
      productId?: string | null;
      bundleId?: string | null;
      type?: string | null;
      label?: string | null;
      poolAvailableQuantity?: number | null;
      renewalDate?: string | null;
      customData?: string | null;
      termDate?: string | null;
      startDate?: string | null;
      trialRenewalDate?: string | null;
      company?: {
        __typename: 'Company';
        companyId: string;
        companyName?: string | null;
        renewalDate?: string | null;
        mfA_Required?: boolean | null;
        data_Analytics?: boolean | null;
        companyDownloadPaths?: Array<{
          __typename: 'CompanyDownloadPath';
          document?: string | null;
          updateBy?: string | null;
          updatedOn?: string | null;
        } | null> | null;
        companyImageId?: string | null;
        address?: {
          __typename: 'Address';
          addressId?: string | null;
          addressLine1?: string | null;
          addressLine2?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
          zip?: string | null;
        } | null;
        productPool?: Array<{
          __typename: 'ProductPool';
          productPoolId: string;
          poolQuantity?: number | null;
          productId?: string | null;
          bundleId?: string | null;
          type?: string | null;
          label?: string | null;
          poolAvailableQuantity?: number | null;
          renewalDate?: string | null;
          customData?: string | null;
          termDate?: string | null;
          startDate?: string | null;
          trialRenewalDate?: string | null;
          company?: {
            __typename: 'Company';
            companyId: string;
            companyName?: string | null;
            renewalDate?: string | null;
            mfA_Required?: boolean | null;
            data_Analytics?: boolean | null;
            companyDownloadPaths?: Array<{
              __typename: 'CompanyDownloadPath';
              document?: string | null;
              updateBy?: string | null;
              updatedOn?: string | null;
            } | null> | null;
            companyImageId?: string | null;
            address?: {
              __typename: 'Address';
              addressId?: string | null;
              addressLine1?: string | null;
              addressLine2?: string | null;
              city?: string | null;
              state?: string | null;
              country?: string | null;
              zip?: string | null;
            } | null;
            productPool?: Array<{
              __typename: 'ProductPool';
              productPoolId: string;
              poolQuantity?: number | null;
              productId?: string | null;
              bundleId?: string | null;
              type?: string | null;
              label?: string | null;
              poolAvailableQuantity?: number | null;
              renewalDate?: string | null;
              customData?: string | null;
              termDate?: string | null;
              startDate?: string | null;
              trialRenewalDate?: string | null;
            } | null> | null;
          } | null;
          product?: {
            __typename: 'Product';
            productId: string;
            productName?: string | null;
            baseProductName?: string | null;
            isForesiteModule?: boolean | null;
            licenseTypeId?: string | null;
            licenseTypeName?: string | null;
            licenseClassId?: string | null;
            licenseClassName?: string | null;
            productNameFormatted?: string | null;
            onlyVisibleToMembers?: boolean | null;
            supportsTrial?: boolean | null;
            productLicenseTypes?: Array<{
              __typename: 'ProductLicenseType';
              productLicenseTypeId: string;
              productId: string;
              licenseTypeId: string;
            } | null> | null;
          } | null;
        } | null> | null;
      } | null;
      product?: {
        __typename: 'Product';
        productId: string;
        productName?: string | null;
        baseProductName?: string | null;
        isForesiteModule?: boolean | null;
        licenseTypeId?: string | null;
        licenseTypeName?: string | null;
        licenseClassId?: string | null;
        licenseClassName?: string | null;
        productNameFormatted?: string | null;
        onlyVisibleToMembers?: boolean | null;
        supportsTrial?: boolean | null;
        productLicenseTypes?: Array<{
          __typename: 'ProductLicenseType';
          productLicenseTypeId: string;
          productId: string;
          licenseTypeId: string;
          licenseType?: {
            __typename: 'LicenseType';
            licenseTypeId: string;
            licenseTypeName: string;
            licenseTypeCode?: LICENSE_CODE | null;
            sortOrder?: number | null;
          } | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type NavigationModuleByIdQueryVariables = {
  params: QueryNavigationModuleByIdParamsInput;
};

export type NavigationModuleByIdQuery = {
  navigationModuleById?: {
    __typename: 'NavigationModule';
    navigationModuleId: string;
    navigationModuleName: string;
    navigationModuleCode: string;
    sortOrder?: number | null;
    navigationModuleSets?: Array<{
      __typename: 'NavigationModuleSet';
      navigationModuleSetId: string;
      navigationModuleId: string;
      navigationSetId: string;
      navigationSet?: {
        __typename: 'NavigationSet';
        navigationSetId: string;
        navigationSetName: string;
        isMenu?: boolean | null;
        icon?: string | null;
        sortOrder?: number | null;
        navigationSetItems?: Array<{
          __typename: 'NavigationSetItem';
          navigationSetItemId: string;
          navigationSetId: string;
          navigationItemId: string;
          navigationItem?: {
            __typename: 'NavigationItem';
            navigationItemId: string;
            navigationItemName: string;
            icon: string;
            routePath: string;
            iconPath?: string | null;
          } | null;
          sortOrder?: number | null;
        } | null> | null;
      } | null;
      sortOrder?: number | null;
      isLastInGroup?: boolean | null;
    } | null> | null;
  } | null;
};

export type PartCatalogsQueryVariables = {
  query: QueryPartCatalogsQueryInput;
};

export type PartCatalogsQuery = {
  partCatalogs?: Array<{
    __typename: 'PartCatalog';
    partCatalogId: string;
    partCatalogName: string;
    companyId: string;
    topLevelNodes?: Array<{
      __typename: 'PartCategory';
      partCategoryId?: string | null;
      partCategoryName?: string | null;
      partCatalog?: {
        __typename: 'PartCatalog';
        partCatalogId: string;
        partCatalogName: string;
        companyId: string;
        topLevelNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      parentPartCategory?: {
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      subNodes?: Array<{
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null> | null;
};

export type PartCatalogByIdQueryVariables = {
  params: QueryPartCatalogByIdParamsInput;
};

export type PartCatalogByIdQuery = {
  partCatalogById?: {
    __typename: 'PartCatalog';
    partCatalogId: string;
    partCatalogName: string;
    companyId: string;
    topLevelNodes?: Array<{
      __typename: 'PartCategory';
      partCategoryId?: string | null;
      partCategoryName?: string | null;
      partCatalog?: {
        __typename: 'PartCatalog';
        partCatalogId: string;
        partCatalogName: string;
        companyId: string;
        topLevelNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      parentPartCategory?: {
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      subNodes?: Array<{
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type PartCategoryQueryVariables = {
  query: QueryPartCategoryQueryInput;
};

export type PartCategoryQuery = {
  partCategory?: Array<{
    __typename: 'PartCategory';
    partCategoryId?: string | null;
    partCategoryName?: string | null;
    partCatalog?: {
      __typename: 'PartCatalog';
      partCatalogId: string;
      partCatalogName: string;
      companyId: string;
      topLevelNodes?: Array<{
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null;
    parentPartCategory?: {
      __typename: 'PartCategory';
      partCategoryId?: string | null;
      partCategoryName?: string | null;
      partCatalog?: {
        __typename: 'PartCatalog';
        partCatalogId: string;
        partCatalogName: string;
        companyId: string;
        topLevelNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      parentPartCategory?: {
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      subNodes?: Array<{
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null;
    subNodes?: Array<{
      __typename: 'PartCategory';
      partCategoryId?: string | null;
      partCategoryName?: string | null;
      partCatalog?: {
        __typename: 'PartCatalog';
        partCatalogId: string;
        partCatalogName: string;
        companyId: string;
        topLevelNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      parentPartCategory?: {
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      subNodes?: Array<{
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null> | null;
};

export type PartCategoryCloudSearchQueryVariables = {
  query: QueryPartCategoryCloudSearchQueryInput;
};

export type PartCategoryCloudSearchQuery = {
  partCategoryCloudSearch?: Array<{
    __typename: 'PartCategoryCloudSearch';
    categoryDepth?: string | null;
    categoryId?: string | null;
    categoryName?: string | null;
    categoryPath?: string | null;
    catalogId?: string | null;
    companyId?: string | null;
  } | null> | null;
};

export type PartCategoryByIdQueryVariables = {
  params: QueryPartCategoryByIdParamsInput;
};

export type PartCategoryByIdQuery = {
  partCategoryById?: {
    __typename: 'PartCategory';
    partCategoryId?: string | null;
    partCategoryName?: string | null;
    partCatalog?: {
      __typename: 'PartCatalog';
      partCatalogId: string;
      partCatalogName: string;
      companyId: string;
      topLevelNodes?: Array<{
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null;
    parentPartCategory?: {
      __typename: 'PartCategory';
      partCategoryId?: string | null;
      partCategoryName?: string | null;
      partCatalog?: {
        __typename: 'PartCatalog';
        partCatalogId: string;
        partCatalogName: string;
        companyId: string;
        topLevelNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      parentPartCategory?: {
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      subNodes?: Array<{
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null;
    subNodes?: Array<{
      __typename: 'PartCategory';
      partCategoryId?: string | null;
      partCategoryName?: string | null;
      partCatalog?: {
        __typename: 'PartCatalog';
        partCatalogId: string;
        partCatalogName: string;
        companyId: string;
        topLevelNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      parentPartCategory?: {
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      subNodes?: Array<{
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type PartAttributeByIdQueryVariables = {
  params: QueryPartAttributeByIdParamsInput;
};

export type PartAttributeByIdQuery = {
  partAttributeById?: {
    __typename: 'PartAttribute';
    partAttributeId: string;
    partAttributeName: string;
    allowWriteInVariant?: boolean | null;
    rangeMinimumValue?: number | null;
    rangeMaximumValue?: number | null;
    selectVariants?: Array<{
      __typename: 'PartAttributeSelectVariant';
      partAttributeSelectVariantId?: string | null;
      textValue?: string | null;
      codeValue?: string | null;
      isDeleted?: boolean | null;
    } | null> | null;
    partAttributeType?: {
      __typename: 'PartAttributeType';
      partAttributeTypeId: string;
      partAttributeTypeName: string;
      partAttributeTypeDescription?: string | null;
      sortOrder?: number | null;
    } | null;
    partCategory?: {
      __typename: 'PartCategory';
      partCategoryId?: string | null;
      partCategoryName?: string | null;
      partCatalog?: {
        __typename: 'PartCatalog';
        partCatalogId: string;
        partCatalogName: string;
        companyId: string;
        topLevelNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      parentPartCategory?: {
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      subNodes?: Array<{
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type PartAttributesQueryVariables = {
  query?: QueryPartAttributesQueryInput | null;
};

export type PartAttributesQuery = {
  partAttributes?: Array<{
    __typename: 'PartAttribute';
    partAttributeId: string;
    partAttributeName: string;
    allowWriteInVariant?: boolean | null;
    rangeMinimumValue?: number | null;
    rangeMaximumValue?: number | null;
    selectVariants?: Array<{
      __typename: 'PartAttributeSelectVariant';
      partAttributeSelectVariantId?: string | null;
      textValue?: string | null;
      codeValue?: string | null;
      isDeleted?: boolean | null;
    } | null> | null;
    partAttributeType?: {
      __typename: 'PartAttributeType';
      partAttributeTypeId: string;
      partAttributeTypeName: string;
      partAttributeTypeDescription?: string | null;
      sortOrder?: number | null;
    } | null;
    partCategory?: {
      __typename: 'PartCategory';
      partCategoryId?: string | null;
      partCategoryName?: string | null;
      partCatalog?: {
        __typename: 'PartCatalog';
        partCatalogId: string;
        partCatalogName: string;
        companyId: string;
        topLevelNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      parentPartCategory?: {
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      subNodes?: Array<{
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null> | null;
};

export type PartAttributeVariantDataQueryVariables = {
  query?: QueryPartAttributeVariantDataQueryInput | null;
};

export type PartAttributeVariantDataQuery = {
  partAttributeVariantData?: Array<{
    __typename: 'PartAttributeVariantData';
    partAttributeVariantDataId?: string | null;
    partAttributeId?: string | null;
    partAttributeName?: string | null;
    rangeValue?: number | null;
    part?: {
      __typename: 'Part';
      partId: string;
      partName: string;
      description?: string | null;
      stateId?: string | null;
      isDefault?: boolean | null;
      manufacturerCatalogNumber?: string | null;
      unitOfMeasureCode?: string | null;
      unitOfMeasureName?: string | null;
      upcCode?: string | null;
      url?: string | null;
      catalogUrl?: string | null;
      partUrl?: string | null;
      internalPartNumber?: string | null;
      createdOn?: string | null;
      hasAssembly?: boolean | null;
      manufacturer?: {
        __typename: 'Manufactured';
        manufacturerId: string;
        companyId?: string | null;
        manufacturerName: string;
        homePageUrl?: string | null;
      } | null;
      attriubuteVariantData?: Array<{
        __typename: 'PartAttributeVariantData';
        partAttributeVariantDataId?: string | null;
        partAttributeId?: string | null;
        partAttributeName?: string | null;
        rangeValue?: number | null;
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
        partAttribute?: {
          __typename: 'PartAttribute';
          partAttributeId: string;
          partAttributeName: string;
          allowWriteInVariant?: boolean | null;
          rangeMinimumValue?: number | null;
          rangeMaximumValue?: number | null;
          selectVariants?: Array<{
            __typename: 'PartAttributeSelectVariant';
            partAttributeSelectVariantId?: string | null;
            textValue?: string | null;
            codeValue?: string | null;
            isDeleted?: boolean | null;
          } | null> | null;
          partAttributeType?: {
            __typename: 'PartAttributeType';
            partAttributeTypeId: string;
            partAttributeTypeName: string;
            partAttributeTypeDescription?: string | null;
            sortOrder?: number | null;
          } | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
        } | null;
        selectValues?: Array<{
          __typename: 'PartAttributeSelectVariant';
          partAttributeSelectVariantId?: string | null;
          textValue?: string | null;
          codeValue?: string | null;
          isDeleted?: boolean | null;
        } | null> | null;
      } | null> | null;
      partCategory?: {
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      partImages?: Array<{
        __typename: 'PartImage';
        partImageId?: string | null;
        sortOrder?: number | null;
        isDefault?: boolean | null;
        document?: {
          __typename: 'Document';
          documentId: string;
          documentName?: string | null;
          documentIdentifier?: string | null;
          documentTypeId?: string | null;
          storagePath?: string | null;
          extension?: string | null;
          encodedUrn?: string | null;
          documentStatusTypeId?: string | null;
          isMappedFromShop?: boolean | null;
          folderDocumentResponse?: Array<{
            __typename: 'FolderDocument';
            folderDocumentId: string;
            folderId: string;
            folderName?: string | null;
            documentId: string;
          } | null> | null;
          documentNameFormatted?: string | null;
          createdOn?: string | null;
          createdBy?: string | null;
        } | null;
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
      } | null> | null;
      documentIds?: Array<string | null> | null;
      unitOfMeasure?: {
        __typename: 'UnitOfMeasure';
        unitOfMeasureId: string;
        unitOfMeasureTypeId?: string | null;
        companyId?: string | null;
        unitOfMeasureName: string;
        unitOfMeasureCode: string;
        createdOn?: string | null;
        createdBy?: string | null;
        updatedOn?: string | null;
        modifiedBy?: string | null;
        modifiedOn?: string | null;
      } | null;
    } | null;
    partAttribute?: {
      __typename: 'PartAttribute';
      partAttributeId: string;
      partAttributeName: string;
      allowWriteInVariant?: boolean | null;
      rangeMinimumValue?: number | null;
      rangeMaximumValue?: number | null;
      selectVariants?: Array<{
        __typename: 'PartAttributeSelectVariant';
        partAttributeSelectVariantId?: string | null;
        textValue?: string | null;
        codeValue?: string | null;
        isDeleted?: boolean | null;
      } | null> | null;
      partAttributeType?: {
        __typename: 'PartAttributeType';
        partAttributeTypeId: string;
        partAttributeTypeName: string;
        partAttributeTypeDescription?: string | null;
        sortOrder?: number | null;
      } | null;
      partCategory?: {
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
    } | null;
    selectValues?: Array<{
      __typename: 'PartAttributeSelectVariant';
      partAttributeSelectVariantId?: string | null;
      textValue?: string | null;
      codeValue?: string | null;
      isDeleted?: boolean | null;
    } | null> | null;
  } | null> | null;
};

export type GetAttributeNamesQueryVariables = {
  params: QueryGetAttributeNamesParamsInput;
};

export type GetAttributeNamesQuery = {
  getAttributeNames?: Array<string | null> | null;
};

export type WorkRequestsQueryVariables = {
  query?: QueryWorkRequestsQueryInput | null;
};

export type WorkRequestsQuery = {
  workRequests?: Array<{
    __typename: 'WorkRequest';
    workRequestId?: string | null;
    workRequestStatusId?: string | null;
    workRequestStatusName?: string | null;
    facilityId?: string | null;
    projectId?: string | null;
    projectIdentifier?: string | null;
    projectName?: string | null;
    workRequestIdentifier?: string | null;
    workRequestName?: string | null;
    needBy?: string | null;
    workRequestDescription?: string | null;
    facilityName?: string | null;
    workRequestStatusDescription?: string | null;
    lastStatusUpdate?: string | null;
    numberOfAttachments?: number | null;
  } | null> | null;
};

export type WorkRequestsByIdQueryVariables = {
  params: QueryWorkRequestsByIdParamsInput;
  query?: QueryWorkRequestsByIdQueryInput | null;
};

export type WorkRequestsByIdQuery = {
  workRequestsById?: {
    __typename: 'WorkRequest';
    workRequestId?: string | null;
    workRequestStatusId?: string | null;
    workRequestStatusName?: string | null;
    facilityId?: string | null;
    projectId?: string | null;
    projectIdentifier?: string | null;
    projectName?: string | null;
    workRequestIdentifier?: string | null;
    workRequestName?: string | null;
    needBy?: string | null;
    workRequestDescription?: string | null;
    facilityName?: string | null;
    workRequestStatusDescription?: string | null;
    lastStatusUpdate?: string | null;
    numberOfAttachments?: number | null;
  } | null;
};

export type WorkRequestsItemByIdQueryVariables = {
  params: QueryWorkRequestsItemByIdParamsInput;
};

export type WorkRequestsItemByIdQuery = {
  workRequestsItemById?: {
    __typename: 'WorkRequestItem';
    workRequestItemId?: string | null;
    workRequestId?: string | null;
    workRequestItemTypeId?: string | null;
    unitOfMeasureId?: string | null;
    billOfProcessId?: string | null;
    workRequestItemName?: string | null;
    workRequestItemStatusTypeId?: string | null;
    quantity?: number | null;
    unityQuantity?: number | null;
    assignedQuantity?: number | null;
    remainingQuantity?: number | null;
    hasShopTask?: boolean | null;
    createdOn?: string | null;
    createdBy?: string | null;
    lineItemId?: string | null;
  } | null;
};

export type WorkRequestItemsQueryVariables = {
  query?: QueryWorkRequestItemsQueryInput | null;
};

export type WorkRequestItemsQuery = {
  workRequestItems?: Array<{
    __typename: 'WorkRequestItem';
    workRequestItemId?: string | null;
    workRequestId?: string | null;
    workRequestItemTypeId?: string | null;
    unitOfMeasureId?: string | null;
    billOfProcessId?: string | null;
    workRequestItemName?: string | null;
    workRequestItemStatusTypeId?: string | null;
    quantity?: number | null;
    unityQuantity?: number | null;
    assignedQuantity?: number | null;
    remainingQuantity?: number | null;
    hasShopTask?: boolean | null;
    createdOn?: string | null;
    createdBy?: string | null;
    lineItemId?: string | null;
  } | null> | null;
};

export type WorkRequestDocumentsQueryVariables = {
  query: QueryWorkRequestDocumentsQueryInput;
};

export type WorkRequestDocumentsQuery = {
  workRequestDocuments?: Array<{
    __typename: 'WorkRequestDocument';
    workRequestDocumentId: string;
    workRequestId: string;
    documentId?: string | null;
    externalUrl?: string | null;
    createdOn: string;
    updatedOn?: string | null;
    createdBy: string;
  } | null> | null;
};

export type WorkOrderDocumentsQueryVariables = {
  query?: QueryWorkOrderDocumentsQueryInput | null;
};

export type WorkOrderDocumentsQuery = {
  workOrderDocuments?: Array<{
    __typename: 'WorkOrderDocument';
    workOrderDocumentId: string;
    workOrderId: string;
    documentId?: string | null;
    externalUrl?: string | null;
    createdOn?: string | null;
    updatedOn?: string | null;
    createdBy?: string | null;
  } | null> | null;
};

export type WorkRequestProjectsQueryVariables = {
  params: QueryWorkRequestProjectsParamsInput;
  query?: QueryWorkRequestProjectsQueryInput | null;
};

export type WorkRequestProjectsQuery = {
  workRequestProjects?: Array<{
    __typename: 'WorkRequestProject';
    projectId: string;
    projectName?: string | null;
    projectIdentifier?: string | null;
  } | null> | null;
};

export type ScopePackageQueryVariables = {
  query: QueryScopePackageQueryInput;
};

export type ScopePackageQuery = {
  scopePackage?: Array<{
    __typename: 'ScopePackage';
    projectId: string;
    scopePackageId: string;
    scopePackageName: string;
    scopePackageIdentifier: string;
    sortOrder: number;
    hasWorkPhases?: boolean | null;
  } | null> | null;
};

export type WorkPhaseByIdQueryVariables = {
  params: QueryWorkPhaseByIdParamsInput;
};

export type WorkPhaseByIdQuery = {
  workPhaseById?: {
    __typename: 'WorkPhase';
    workPhaseId: string;
    workPhaseIdentifier: string;
    workPhaseName: string;
    sortOrder: number;
    scopePackageId: string;
    scopePackageName?: string | null;
    projectId: string;
    hasLocationPhases?: boolean | null;
    updatedWorkPhaseLocationPhases?: Array<{
      __typename: 'MappedLocation';
      locationId: string;
      locationPhaseId: string;
    } | null> | null;
  } | null;
};

export type WorkPhasesQueryVariables = {
  query: QueryWorkPhasesQueryInput;
};

export type WorkPhasesQuery = {
  workPhases?: Array<{
    __typename: 'WorkPhase';
    workPhaseId: string;
    workPhaseIdentifier: string;
    workPhaseName: string;
    sortOrder: number;
    scopePackageId: string;
    scopePackageName?: string | null;
    projectId: string;
    hasLocationPhases?: boolean | null;
    updatedWorkPhaseLocationPhases?: Array<{
      __typename: 'MappedLocation';
      locationId: string;
      locationPhaseId: string;
    } | null> | null;
  } | null> | null;
};

export type WorkPhasesCountQueryVariables = {
  query: QueryWorkPhasesCountQueryInput;
};

export type WorkPhasesCountQuery = {
  workPhasesCount?: number | null;
};

export type LocationsQueryVariables = {
  query: QueryLocationsQueryInput;
};

export type LocationsQuery = {
  locations?: Array<{
    __typename: 'Location';
    locationId?: string | null;
    locationTypeId?: string | null;
    locationTypeName?: string | null;
    projectId?: string | null;
    locationIdentifier?: string | null;
    locationCompositeIdentifier?: string | null;
    sortOrder?: number | null;
    hasChildren?: boolean | null;
    parentLocationId?: string | null;
    locationName?: string | null;
    locationCompositeName?: string | null;
    locationPhases?: Array<{
      __typename: 'LocationPhase';
      locationPhaseId: string;
      locationId?: string | null;
      workPhaseId: string;
      workPhaseName: string;
      scopePackageId: string;
      scopePackageName: string;
      sortOrder: number;
    } | null> | null;
    childrenTypeName?: string | null;
    movedLocationPhases?: Array<{
      __typename: 'UpdatedLocationPhase';
      workPhaseId: string;
      locationPhaseId: string;
    } | null> | null;
  } | null> | null;
};

export type LocationByIdQueryVariables = {
  params: QueryLocationByIdParamsInput;
};

export type LocationByIdQuery = {
  locationById?: {
    __typename: 'Location';
    locationId?: string | null;
    locationTypeId?: string | null;
    locationTypeName?: string | null;
    projectId?: string | null;
    locationIdentifier?: string | null;
    locationCompositeIdentifier?: string | null;
    sortOrder?: number | null;
    hasChildren?: boolean | null;
    parentLocationId?: string | null;
    locationName?: string | null;
    locationCompositeName?: string | null;
    locationPhases?: Array<{
      __typename: 'LocationPhase';
      locationPhaseId: string;
      locationId?: string | null;
      workPhaseId: string;
      workPhaseName: string;
      scopePackageId: string;
      scopePackageName: string;
      sortOrder: number;
    } | null> | null;
    childrenTypeName?: string | null;
    movedLocationPhases?: Array<{
      __typename: 'UpdatedLocationPhase';
      workPhaseId: string;
      locationPhaseId: string;
    } | null> | null;
  } | null;
};

export type ExternalParametersQueryVariables = {
  params: QueryExternalParametersParamsInput;
};

export type ExternalParametersQuery = {
  externalParameters?: {
    __typename: 'ExternalParameters';
    parameters?: Array<{
      __typename: 'Parameter';
      name?: string | null;
      values?: Array<string | null> | null;
    } | null> | null;
    sourceData?: Array<string | null> | null;
  } | null;
};

export type BillOfMaterialsQueryVariables = {
  query?: QueryBillOfMaterialsQueryInput | null;
};

export type BillOfMaterialsQuery = {
  billOfMaterials?: Array<{
    __typename: 'BillOfMaterial';
    billOfMaterialsId: string;
    billOfMaterialsStatusId?: string | null;
    billOfMaterialsStatusName?: string | null;
    projectId: string;
    billOfMaterialsName: string;
    createdOn: string;
    createdBy: string;
    modifiedOn?: string | null;
    modifiedBy?: string | null;
    createdByFirstName?: string | null;
    createdByLastName?: string | null;
    createdByEmail?: string | null;
    updatedOn?: string | null;
    updatedBy?: string | null;
    updatedByFirstName?: string | null;
    updatedByLastName?: string | null;
    updatedByEmail?: string | null;
  } | null> | null;
};

export type BillOfMaterialItemQueryVariables = {
  query: QueryBillOfMaterialItemQueryInput;
};

export type BillOfMaterialItemQuery = {
  billOfMaterialItem?: Array<{
    __typename: 'BillOfMaterialItem';
    lineItemId: string;
    lineItemTypeId: string;
    lineItemTypeName?: string | null;
    billOfMaterialsId?: string | null;
    unitOfMeasureId?: string | null;
    unitOfMeasureName?: string | null;
    unitOfMeasureCode?: string | null;
    manufacturerId?: string | null;
    manufacturerName?: string | null;
    partId?: string | null;
    locationId?: string | null;
    workPhaseId?: string | null;
    scopePackageId?: string | null;
    workPackageId?: string | null;
    kitPlanId?: string | null;
    spoolId?: string | null;
    lineItemName?: string | null;
    manufacturerCatalogNumber?: string | null;
    quantity?: number | null;
    remainingQuantity?: number | null;
    unitQuantity?: number | null;
    tradeSize?: string | null;
    createdOn?: string | null;
    createdBy?: string | null;
    createdByFirstName?: string | null;
    childLineItemContainerName?: string | null;
    createdByLastName?: string | null;
    createdByEmail?: string | null;
    updatedOn?: string | null;
    updatedBy?: string | null;
    updatedByFirstName?: string | null;
    updatedByLastName?: string | null;
    updatedByEmail?: string | null;
    numberOfOpenDecisions?: number | null;
    decisionsHaveVariantDeletedFromCurrentVersionOfPart?: boolean | null;
    ableToMakeDecisions?: boolean | null;
    numberOfDecisionsMade?: number | null;
    hasChildNodes?: boolean | null;
    parentLineItem?: string | null;
    projectId?: string | null;
    assemblyCustomCategoryId?: string | null;
    assemblyCustomCategoryName?: string | null;
    canChildLineItemBeAdded?: boolean | null;
  } | null> | null;
};

export type BillOfMaterialItemSearchQueryVariables = {
  params: QueryBillOfMaterialItemSearchParamsInput;
  query?: QueryBillOfMaterialItemSearchQueryInput | null;
};

export type BillOfMaterialItemSearchQuery = {
  billOfMaterialItemSearch?: Array<{
    __typename: 'BillOfMaterialItem';
    lineItemId: string;
    lineItemTypeId: string;
    lineItemTypeName?: string | null;
    billOfMaterialsId?: string | null;
    unitOfMeasureId?: string | null;
    unitOfMeasureName?: string | null;
    unitOfMeasureCode?: string | null;
    manufacturerId?: string | null;
    manufacturerName?: string | null;
    partId?: string | null;
    locationId?: string | null;
    workPhaseId?: string | null;
    scopePackageId?: string | null;
    workPackageId?: string | null;
    kitPlanId?: string | null;
    spoolId?: string | null;
    lineItemName?: string | null;
    manufacturerCatalogNumber?: string | null;
    quantity?: number | null;
    remainingQuantity?: number | null;
    unitQuantity?: number | null;
    tradeSize?: string | null;
    createdOn?: string | null;
    createdBy?: string | null;
    createdByFirstName?: string | null;
    childLineItemContainerName?: string | null;
    createdByLastName?: string | null;
    createdByEmail?: string | null;
    updatedOn?: string | null;
    updatedBy?: string | null;
    updatedByFirstName?: string | null;
    updatedByLastName?: string | null;
    updatedByEmail?: string | null;
    numberOfOpenDecisions?: number | null;
    decisionsHaveVariantDeletedFromCurrentVersionOfPart?: boolean | null;
    ableToMakeDecisions?: boolean | null;
    numberOfDecisionsMade?: number | null;
    hasChildNodes?: boolean | null;
    parentLineItem?: string | null;
    projectId?: string | null;
    assemblyCustomCategoryId?: string | null;
    assemblyCustomCategoryName?: string | null;
    canChildLineItemBeAdded?: boolean | null;
  } | null> | null;
};

export type BillOfMaterialItemByIdQueryVariables = {
  params: QueryBillOfMaterialItemByIdParamsInput;
};

export type BillOfMaterialItemByIdQuery = {
  billOfMaterialItemById?: {
    __typename: 'BillOfMaterialItem';
    lineItemId: string;
    lineItemTypeId: string;
    lineItemTypeName?: string | null;
    billOfMaterialsId?: string | null;
    unitOfMeasureId?: string | null;
    unitOfMeasureName?: string | null;
    unitOfMeasureCode?: string | null;
    manufacturerId?: string | null;
    manufacturerName?: string | null;
    partId?: string | null;
    locationId?: string | null;
    workPhaseId?: string | null;
    scopePackageId?: string | null;
    workPackageId?: string | null;
    kitPlanId?: string | null;
    spoolId?: string | null;
    lineItemName?: string | null;
    manufacturerCatalogNumber?: string | null;
    quantity?: number | null;
    remainingQuantity?: number | null;
    unitQuantity?: number | null;
    tradeSize?: string | null;
    createdOn?: string | null;
    createdBy?: string | null;
    createdByFirstName?: string | null;
    childLineItemContainerName?: string | null;
    createdByLastName?: string | null;
    createdByEmail?: string | null;
    updatedOn?: string | null;
    updatedBy?: string | null;
    updatedByFirstName?: string | null;
    updatedByLastName?: string | null;
    updatedByEmail?: string | null;
    numberOfOpenDecisions?: number | null;
    decisionsHaveVariantDeletedFromCurrentVersionOfPart?: boolean | null;
    ableToMakeDecisions?: boolean | null;
    numberOfDecisionsMade?: number | null;
    hasChildNodes?: boolean | null;
    parentLineItem?: string | null;
    projectId?: string | null;
    assemblyCustomCategoryId?: string | null;
    assemblyCustomCategoryName?: string | null;
    canChildLineItemBeAdded?: boolean | null;
  } | null;
};

export type GetDecisionsQueryVariables = {
  params: QueryGetDecisionsParamsInput;
  query?: QueryGetDecisionsQueryInput | null;
};

export type GetDecisionsQuery = {
  getDecisions?: Array<{
    __typename: 'Decisions';
    partAttribute?: {
      __typename: 'PartAttribute';
      partAttributeId: string;
      partAttributeName: string;
      allowWriteInVariant?: boolean | null;
      rangeMinimumValue?: number | null;
      rangeMaximumValue?: number | null;
      selectVariants?: Array<{
        __typename: 'PartAttributeSelectVariant';
        partAttributeSelectVariantId?: string | null;
        textValue?: string | null;
        codeValue?: string | null;
        isDeleted?: boolean | null;
      } | null> | null;
      partAttributeType?: {
        __typename: 'PartAttributeType';
        partAttributeTypeId: string;
        partAttributeTypeName: string;
        partAttributeTypeDescription?: string | null;
        sortOrder?: number | null;
      } | null;
      partCategory?: {
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
    } | null;
    partAttributeVariantData?: {
      __typename: 'PartAttributeVariantData';
      partAttributeVariantDataId?: string | null;
      partAttributeId?: string | null;
      partAttributeName?: string | null;
      rangeValue?: number | null;
      part?: {
        __typename: 'Part';
        partId: string;
        partName: string;
        description?: string | null;
        stateId?: string | null;
        isDefault?: boolean | null;
        manufacturerCatalogNumber?: string | null;
        unitOfMeasureCode?: string | null;
        unitOfMeasureName?: string | null;
        upcCode?: string | null;
        url?: string | null;
        catalogUrl?: string | null;
        partUrl?: string | null;
        internalPartNumber?: string | null;
        createdOn?: string | null;
        hasAssembly?: boolean | null;
        manufacturer?: {
          __typename: 'Manufactured';
          manufacturerId: string;
          companyId?: string | null;
          manufacturerName: string;
          homePageUrl?: string | null;
        } | null;
        attriubuteVariantData?: Array<{
          __typename: 'PartAttributeVariantData';
          partAttributeVariantDataId?: string | null;
          partAttributeId?: string | null;
          partAttributeName?: string | null;
          rangeValue?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          partAttribute?: {
            __typename: 'PartAttribute';
            partAttributeId: string;
            partAttributeName: string;
            allowWriteInVariant?: boolean | null;
            rangeMinimumValue?: number | null;
            rangeMaximumValue?: number | null;
            selectVariants?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
            partAttributeType?: {
              __typename: 'PartAttributeType';
              partAttributeTypeId: string;
              partAttributeTypeName: string;
              partAttributeTypeDescription?: string | null;
              sortOrder?: number | null;
            } | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
          } | null;
          selectValues?: Array<{
            __typename: 'PartAttributeSelectVariant';
            partAttributeSelectVariantId?: string | null;
            textValue?: string | null;
            codeValue?: string | null;
            isDeleted?: boolean | null;
          } | null> | null;
        } | null> | null;
        partCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        partImages?: Array<{
          __typename: 'PartImage';
          partImageId?: string | null;
          sortOrder?: number | null;
          isDefault?: boolean | null;
          document?: {
            __typename: 'Document';
            documentId: string;
            documentName?: string | null;
            documentIdentifier?: string | null;
            documentTypeId?: string | null;
            storagePath?: string | null;
            extension?: string | null;
            encodedUrn?: string | null;
            documentStatusTypeId?: string | null;
            isMappedFromShop?: boolean | null;
            folderDocumentResponse?: Array<{
              __typename: 'FolderDocument';
              folderDocumentId: string;
              folderId: string;
              folderName?: string | null;
              documentId: string;
            } | null> | null;
            documentNameFormatted?: string | null;
            createdOn?: string | null;
            createdBy?: string | null;
          } | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
        } | null> | null;
        documentIds?: Array<string | null> | null;
        unitOfMeasure?: {
          __typename: 'UnitOfMeasure';
          unitOfMeasureId: string;
          unitOfMeasureTypeId?: string | null;
          companyId?: string | null;
          unitOfMeasureName: string;
          unitOfMeasureCode: string;
          createdOn?: string | null;
          createdBy?: string | null;
          updatedOn?: string | null;
          modifiedBy?: string | null;
          modifiedOn?: string | null;
        } | null;
      } | null;
      partAttribute?: {
        __typename: 'PartAttribute';
        partAttributeId: string;
        partAttributeName: string;
        allowWriteInVariant?: boolean | null;
        rangeMinimumValue?: number | null;
        rangeMaximumValue?: number | null;
        selectVariants?: Array<{
          __typename: 'PartAttributeSelectVariant';
          partAttributeSelectVariantId?: string | null;
          textValue?: string | null;
          codeValue?: string | null;
          isDeleted?: boolean | null;
        } | null> | null;
        partAttributeType?: {
          __typename: 'PartAttributeType';
          partAttributeTypeId: string;
          partAttributeTypeName: string;
          partAttributeTypeDescription?: string | null;
          sortOrder?: number | null;
        } | null;
        partCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
      } | null;
      selectValues?: Array<{
        __typename: 'PartAttributeSelectVariant';
        partAttributeSelectVariantId?: string | null;
        textValue?: string | null;
        codeValue?: string | null;
        isDeleted?: boolean | null;
      } | null> | null;
    } | null;
    lineItemDecision?: {
      __typename: 'LineItemDecisions';
      lineItemDecisionId?: string | null;
      partAttribute?: {
        __typename: 'PartAttributeDecisions';
        partAttributeId?: string | null;
        partAttributeName?: string | null;
      } | null;
      partAttributeSelectVariant?: {
        __typename: 'PartAttributeSelectVariant';
        partAttributeSelectVariantId?: string | null;
        textValue?: string | null;
        codeValue?: string | null;
        isDeleted?: boolean | null;
      } | null;
      customValue?: string | null;
      isVariantDeletedFromCurrentVersionOfPart?: boolean | null;
      allVariantsDeletedFromCurrentVersionOfPart?: boolean | null;
    } | null;
  } | null> | null;
};

export type BillOfMaterialLineItemByLBSQueryVariables = {
  query?: QueryBillOfMaterialLineItemByLBSQueryInput | null;
};

export type BillOfMaterialLineItemByLBSQuery = {
  billOfMaterialLineItemByLBS?: {
    __typename: 'BillOfMaterialLineItemByLBS';
    ern?: string | null;
    locationId?: string | null;
    workPhaseId?: string | null;
    amount?: {
      __typename: 'Amount';
      assigned?: {
        __typename: 'AmountClass';
        individual?: number | null;
        aggregate?: number | null;
      } | null;
      available?: {
        __typename: 'AmountClass';
        individual?: number | null;
        aggregate?: number | null;
      } | null;
    } | null;
    parentLocationId?: string | null;
    parentWorkPhases?: Array<string | null> | null;
    children?: Array<{
      __typename: 'Children';
      locationId?: string | null;
      workPhaseId?: string | null;
      amount?: {
        __typename: 'Amount';
        assigned?: {
          __typename: 'AmountClass';
          individual?: number | null;
          aggregate?: number | null;
        } | null;
        available?: {
          __typename: 'AmountClass';
          individual?: number | null;
          aggregate?: number | null;
        } | null;
      } | null;
    } | null> | null;
    unassignedAmount?: number | null;
    unassignedAmountRemaining?: number | null;
    totalAmount?: number | null;
    totalAmountRemaining?: number | null;
  } | null;
};

export type QuantitiesRetrieveQueryVariables = {
  query?: QueryQuantitiesRetrieveQueryInput | null;
};

export type QuantitiesRetrieveQuery = {
  quantitiesRetrieve?: {
    __typename: 'RetrieveGet';
    ern?: string | null;
    resourceId?: string | null;
    properties?: {
      __typename: 'GetProperties';
      companyId?: string | null;
      projectId?: string | null;
    } | null;
    amount?: number | null;
    amountBreakdown?: Array<{
      __typename: 'AmountBreakdownProperties';
      amountId?: string | null;
      amount?: number | null;
      children?: Array<{
        __typename: 'ChildAmountBreakdown';
        childAmountId?: string | null;
        childResourceERN?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type QuantitiesRetrieveByIdentifierQueryVariables = {
  params: QueryQuantitiesRetrieveByIdentifierParamsInput;
};

export type QuantitiesRetrieveByIdentifierQuery = {
  quantitiesRetrieveByIdentifier?: {
    __typename: 'RetrieveByIdentifierGet';
    ern?: string | null;
    resourceId?: string | null;
    properties?: {
      __typename: 'GetByIdentifierProperties';
      companyId?: string | null;
      projectId?: string | null;
      locationId?: string | null;
    } | null;
    amount?: number | null;
    amountRemaining?: number | null;
    amountBreakdown?: Array<{
      __typename: 'AmountBreakdownProperties';
      amountId?: string | null;
      amount?: number | null;
      children?: Array<{
        __typename: 'ChildAmountBreakdown';
        childAmountId?: string | null;
        childResourceERN?: string | null;
      } | null> | null;
    } | null> | null;
    constraints?: Array<string | null> | null;
    failedConstraints?: Array<{
      __typename: 'FailedConstraintsByIdentifier';
      constraintId?: string | null;
      parentResourceERN?: string | null;
      description?: string | null;
    } | null> | null;
  } | null;
};

export type UnitOfMeasureQueryVariables = {
  query?: QueryUnitOfMeasureQueryInput | null;
};

export type UnitOfMeasureQuery = {
  unitOfMeasure?: Array<{
    __typename: 'UnitOfMeasure';
    unitOfMeasureId: string;
    unitOfMeasureTypeId?: string | null;
    companyId?: string | null;
    unitOfMeasureName: string;
    unitOfMeasureCode: string;
    createdOn?: string | null;
    createdBy?: string | null;
    updatedOn?: string | null;
    modifiedBy?: string | null;
    modifiedOn?: string | null;
  } | null> | null;
};

export type UnitOfMeasureByIdQueryVariables = {
  params: QueryUnitOfMeasureByIdParamsInput;
};

export type UnitOfMeasureByIdQuery = {
  unitOfMeasureById?: {
    __typename: 'UnitOfMeasure';
    unitOfMeasureId: string;
    unitOfMeasureTypeId?: string | null;
    companyId?: string | null;
    unitOfMeasureName: string;
    unitOfMeasureCode: string;
    createdOn?: string | null;
    createdBy?: string | null;
    updatedOn?: string | null;
    modifiedBy?: string | null;
    modifiedOn?: string | null;
  } | null;
};

export type ManufacturedQueryVariables = {
  query: QueryManufacturedQueryInput;
};

export type ManufacturedQuery = {
  manufactured?: Array<{
    __typename: 'Manufactured';
    manufacturerId: string;
    companyId?: string | null;
    manufacturerName: string;
    homePageUrl?: string | null;
  } | null> | null;
};

export type PartsQueryVariables = {
  query?: QueryPartsQueryInput | null;
};

export type PartsQuery = {
  parts?: Array<{
    __typename: 'Part';
    partId: string;
    partName: string;
    description?: string | null;
    stateId?: string | null;
    isDefault?: boolean | null;
    manufacturerCatalogNumber?: string | null;
    unitOfMeasureCode?: string | null;
    unitOfMeasureName?: string | null;
    upcCode?: string | null;
    url?: string | null;
    catalogUrl?: string | null;
    partUrl?: string | null;
    internalPartNumber?: string | null;
    createdOn?: string | null;
    hasAssembly?: boolean | null;
    manufacturer?: {
      __typename: 'Manufactured';
      manufacturerId: string;
      companyId?: string | null;
      manufacturerName: string;
      homePageUrl?: string | null;
    } | null;
    attriubuteVariantData?: Array<{
      __typename: 'PartAttributeVariantData';
      partAttributeVariantDataId?: string | null;
      partAttributeId?: string | null;
      partAttributeName?: string | null;
      rangeValue?: number | null;
      part?: {
        __typename: 'Part';
        partId: string;
        partName: string;
        description?: string | null;
        stateId?: string | null;
        isDefault?: boolean | null;
        manufacturerCatalogNumber?: string | null;
        unitOfMeasureCode?: string | null;
        unitOfMeasureName?: string | null;
        upcCode?: string | null;
        url?: string | null;
        catalogUrl?: string | null;
        partUrl?: string | null;
        internalPartNumber?: string | null;
        createdOn?: string | null;
        hasAssembly?: boolean | null;
        manufacturer?: {
          __typename: 'Manufactured';
          manufacturerId: string;
          companyId?: string | null;
          manufacturerName: string;
          homePageUrl?: string | null;
        } | null;
        attriubuteVariantData?: Array<{
          __typename: 'PartAttributeVariantData';
          partAttributeVariantDataId?: string | null;
          partAttributeId?: string | null;
          partAttributeName?: string | null;
          rangeValue?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          partAttribute?: {
            __typename: 'PartAttribute';
            partAttributeId: string;
            partAttributeName: string;
            allowWriteInVariant?: boolean | null;
            rangeMinimumValue?: number | null;
            rangeMaximumValue?: number | null;
            selectVariants?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
            partAttributeType?: {
              __typename: 'PartAttributeType';
              partAttributeTypeId: string;
              partAttributeTypeName: string;
              partAttributeTypeDescription?: string | null;
              sortOrder?: number | null;
            } | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
          } | null;
          selectValues?: Array<{
            __typename: 'PartAttributeSelectVariant';
            partAttributeSelectVariantId?: string | null;
            textValue?: string | null;
            codeValue?: string | null;
            isDeleted?: boolean | null;
          } | null> | null;
        } | null> | null;
        partCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        partImages?: Array<{
          __typename: 'PartImage';
          partImageId?: string | null;
          sortOrder?: number | null;
          isDefault?: boolean | null;
          document?: {
            __typename: 'Document';
            documentId: string;
            documentName?: string | null;
            documentIdentifier?: string | null;
            documentTypeId?: string | null;
            storagePath?: string | null;
            extension?: string | null;
            encodedUrn?: string | null;
            documentStatusTypeId?: string | null;
            isMappedFromShop?: boolean | null;
            folderDocumentResponse?: Array<{
              __typename: 'FolderDocument';
              folderDocumentId: string;
              folderId: string;
              folderName?: string | null;
              documentId: string;
            } | null> | null;
            documentNameFormatted?: string | null;
            createdOn?: string | null;
            createdBy?: string | null;
          } | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
        } | null> | null;
        documentIds?: Array<string | null> | null;
        unitOfMeasure?: {
          __typename: 'UnitOfMeasure';
          unitOfMeasureId: string;
          unitOfMeasureTypeId?: string | null;
          companyId?: string | null;
          unitOfMeasureName: string;
          unitOfMeasureCode: string;
          createdOn?: string | null;
          createdBy?: string | null;
          updatedOn?: string | null;
          modifiedBy?: string | null;
          modifiedOn?: string | null;
        } | null;
      } | null;
      partAttribute?: {
        __typename: 'PartAttribute';
        partAttributeId: string;
        partAttributeName: string;
        allowWriteInVariant?: boolean | null;
        rangeMinimumValue?: number | null;
        rangeMaximumValue?: number | null;
        selectVariants?: Array<{
          __typename: 'PartAttributeSelectVariant';
          partAttributeSelectVariantId?: string | null;
          textValue?: string | null;
          codeValue?: string | null;
          isDeleted?: boolean | null;
        } | null> | null;
        partAttributeType?: {
          __typename: 'PartAttributeType';
          partAttributeTypeId: string;
          partAttributeTypeName: string;
          partAttributeTypeDescription?: string | null;
          sortOrder?: number | null;
        } | null;
        partCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
      } | null;
      selectValues?: Array<{
        __typename: 'PartAttributeSelectVariant';
        partAttributeSelectVariantId?: string | null;
        textValue?: string | null;
        codeValue?: string | null;
        isDeleted?: boolean | null;
      } | null> | null;
    } | null> | null;
    partCategory?: {
      __typename: 'PartCategory';
      partCategoryId?: string | null;
      partCategoryName?: string | null;
      partCatalog?: {
        __typename: 'PartCatalog';
        partCatalogId: string;
        partCatalogName: string;
        companyId: string;
        topLevelNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      parentPartCategory?: {
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      subNodes?: Array<{
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null;
    partImages?: Array<{
      __typename: 'PartImage';
      partImageId?: string | null;
      sortOrder?: number | null;
      isDefault?: boolean | null;
      document?: {
        __typename: 'Document';
        documentId: string;
        documentName?: string | null;
        documentIdentifier?: string | null;
        documentTypeId?: string | null;
        storagePath?: string | null;
        extension?: string | null;
        encodedUrn?: string | null;
        documentStatusTypeId?: string | null;
        isMappedFromShop?: boolean | null;
        folderDocumentResponse?: Array<{
          __typename: 'FolderDocument';
          folderDocumentId: string;
          folderId: string;
          folderName?: string | null;
          documentId: string;
        } | null> | null;
        documentNameFormatted?: string | null;
        createdOn?: string | null;
        createdBy?: string | null;
      } | null;
      part?: {
        __typename: 'Part';
        partId: string;
        partName: string;
        description?: string | null;
        stateId?: string | null;
        isDefault?: boolean | null;
        manufacturerCatalogNumber?: string | null;
        unitOfMeasureCode?: string | null;
        unitOfMeasureName?: string | null;
        upcCode?: string | null;
        url?: string | null;
        catalogUrl?: string | null;
        partUrl?: string | null;
        internalPartNumber?: string | null;
        createdOn?: string | null;
        hasAssembly?: boolean | null;
        manufacturer?: {
          __typename: 'Manufactured';
          manufacturerId: string;
          companyId?: string | null;
          manufacturerName: string;
          homePageUrl?: string | null;
        } | null;
        attriubuteVariantData?: Array<{
          __typename: 'PartAttributeVariantData';
          partAttributeVariantDataId?: string | null;
          partAttributeId?: string | null;
          partAttributeName?: string | null;
          rangeValue?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          partAttribute?: {
            __typename: 'PartAttribute';
            partAttributeId: string;
            partAttributeName: string;
            allowWriteInVariant?: boolean | null;
            rangeMinimumValue?: number | null;
            rangeMaximumValue?: number | null;
            selectVariants?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
            partAttributeType?: {
              __typename: 'PartAttributeType';
              partAttributeTypeId: string;
              partAttributeTypeName: string;
              partAttributeTypeDescription?: string | null;
              sortOrder?: number | null;
            } | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
          } | null;
          selectValues?: Array<{
            __typename: 'PartAttributeSelectVariant';
            partAttributeSelectVariantId?: string | null;
            textValue?: string | null;
            codeValue?: string | null;
            isDeleted?: boolean | null;
          } | null> | null;
        } | null> | null;
        partCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        partImages?: Array<{
          __typename: 'PartImage';
          partImageId?: string | null;
          sortOrder?: number | null;
          isDefault?: boolean | null;
          document?: {
            __typename: 'Document';
            documentId: string;
            documentName?: string | null;
            documentIdentifier?: string | null;
            documentTypeId?: string | null;
            storagePath?: string | null;
            extension?: string | null;
            encodedUrn?: string | null;
            documentStatusTypeId?: string | null;
            isMappedFromShop?: boolean | null;
            folderDocumentResponse?: Array<{
              __typename: 'FolderDocument';
              folderDocumentId: string;
              folderId: string;
              folderName?: string | null;
              documentId: string;
            } | null> | null;
            documentNameFormatted?: string | null;
            createdOn?: string | null;
            createdBy?: string | null;
          } | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
        } | null> | null;
        documentIds?: Array<string | null> | null;
        unitOfMeasure?: {
          __typename: 'UnitOfMeasure';
          unitOfMeasureId: string;
          unitOfMeasureTypeId?: string | null;
          companyId?: string | null;
          unitOfMeasureName: string;
          unitOfMeasureCode: string;
          createdOn?: string | null;
          createdBy?: string | null;
          updatedOn?: string | null;
          modifiedBy?: string | null;
          modifiedOn?: string | null;
        } | null;
      } | null;
    } | null> | null;
    documentIds?: Array<string | null> | null;
    unitOfMeasure?: {
      __typename: 'UnitOfMeasure';
      unitOfMeasureId: string;
      unitOfMeasureTypeId?: string | null;
      companyId?: string | null;
      unitOfMeasureName: string;
      unitOfMeasureCode: string;
      createdOn?: string | null;
      createdBy?: string | null;
      updatedOn?: string | null;
      modifiedBy?: string | null;
      modifiedOn?: string | null;
    } | null;
  } | null> | null;
};

export type PartsCountQueryVariables = {
  query?: QueryPartsCountQueryInput | null;
};

export type PartsCountQuery = {
  partsCount?: number | null;
};

export type PartsCloudSearchQueryVariables = {
  query?: QueryPartsCloudSearchQueryInput | null;
};

export type PartsCloudSearchQuery = {
  partsCloudSearch?: Array<{
    __typename: 'PartCloudSearchResult';
    partDescription?: string | null;
    manufacturerName?: string | null;
    partCategoryPath?: string | null;
    partName?: string | null;
    unitOfMeasureCode?: string | null;
    partHistoryRefId?: string | null;
    version?: string | null;
    partId?: string | null;
    partImageDocumentId?: string | null;
    partHistoryId?: string | null;
    partCatalogId?: string | null;
    partCategories?: Array<string | null> | null;
    catalogNumber?: string | null;
    hasAssembly?: string | null;
  } | null> | null;
};

export type PartsCloudSearchWithCountQueryVariables = {
  query?: QueryPartsCloudSearchWithCountQueryInput | null;
};

export type PartsCloudSearchWithCountQuery = {
  partsCloudSearchWithCount?: {
    __typename: 'PartCloudSearchResultWithTotalCount';
    items?: Array<{
      __typename: 'PartCloudSearchResult';
      partDescription?: string | null;
      manufacturerName?: string | null;
      partCategoryPath?: string | null;
      partName?: string | null;
      unitOfMeasureCode?: string | null;
      partHistoryRefId?: string | null;
      version?: string | null;
      partId?: string | null;
      partImageDocumentId?: string | null;
      partHistoryId?: string | null;
      partCatalogId?: string | null;
      partCategories?: Array<string | null> | null;
      catalogNumber?: string | null;
      hasAssembly?: string | null;
    } | null> | null;
    totalCount?: number | null;
  } | null;
};

export type PartsCloudSearchCountQueryVariables = {
  query?: QueryPartsCloudSearchCountQueryInput | null;
};

export type PartsCloudSearchCountQuery = {
  partsCloudSearchCount?: number | null;
};

export type PartByIdQueryVariables = {
  params: QueryPartByIdParamsInput;
};

export type PartByIdQuery = {
  partById?: {
    __typename: 'Part';
    partId: string;
    partName: string;
    description?: string | null;
    stateId?: string | null;
    isDefault?: boolean | null;
    manufacturerCatalogNumber?: string | null;
    unitOfMeasureCode?: string | null;
    unitOfMeasureName?: string | null;
    upcCode?: string | null;
    url?: string | null;
    catalogUrl?: string | null;
    partUrl?: string | null;
    internalPartNumber?: string | null;
    createdOn?: string | null;
    hasAssembly?: boolean | null;
    manufacturer?: {
      __typename: 'Manufactured';
      manufacturerId: string;
      companyId?: string | null;
      manufacturerName: string;
      homePageUrl?: string | null;
    } | null;
    attriubuteVariantData?: Array<{
      __typename: 'PartAttributeVariantData';
      partAttributeVariantDataId?: string | null;
      partAttributeId?: string | null;
      partAttributeName?: string | null;
      rangeValue?: number | null;
      part?: {
        __typename: 'Part';
        partId: string;
        partName: string;
        description?: string | null;
        stateId?: string | null;
        isDefault?: boolean | null;
        manufacturerCatalogNumber?: string | null;
        unitOfMeasureCode?: string | null;
        unitOfMeasureName?: string | null;
        upcCode?: string | null;
        url?: string | null;
        catalogUrl?: string | null;
        partUrl?: string | null;
        internalPartNumber?: string | null;
        createdOn?: string | null;
        hasAssembly?: boolean | null;
        manufacturer?: {
          __typename: 'Manufactured';
          manufacturerId: string;
          companyId?: string | null;
          manufacturerName: string;
          homePageUrl?: string | null;
        } | null;
        attriubuteVariantData?: Array<{
          __typename: 'PartAttributeVariantData';
          partAttributeVariantDataId?: string | null;
          partAttributeId?: string | null;
          partAttributeName?: string | null;
          rangeValue?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          partAttribute?: {
            __typename: 'PartAttribute';
            partAttributeId: string;
            partAttributeName: string;
            allowWriteInVariant?: boolean | null;
            rangeMinimumValue?: number | null;
            rangeMaximumValue?: number | null;
            selectVariants?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
            partAttributeType?: {
              __typename: 'PartAttributeType';
              partAttributeTypeId: string;
              partAttributeTypeName: string;
              partAttributeTypeDescription?: string | null;
              sortOrder?: number | null;
            } | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
          } | null;
          selectValues?: Array<{
            __typename: 'PartAttributeSelectVariant';
            partAttributeSelectVariantId?: string | null;
            textValue?: string | null;
            codeValue?: string | null;
            isDeleted?: boolean | null;
          } | null> | null;
        } | null> | null;
        partCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        partImages?: Array<{
          __typename: 'PartImage';
          partImageId?: string | null;
          sortOrder?: number | null;
          isDefault?: boolean | null;
          document?: {
            __typename: 'Document';
            documentId: string;
            documentName?: string | null;
            documentIdentifier?: string | null;
            documentTypeId?: string | null;
            storagePath?: string | null;
            extension?: string | null;
            encodedUrn?: string | null;
            documentStatusTypeId?: string | null;
            isMappedFromShop?: boolean | null;
            folderDocumentResponse?: Array<{
              __typename: 'FolderDocument';
              folderDocumentId: string;
              folderId: string;
              folderName?: string | null;
              documentId: string;
            } | null> | null;
            documentNameFormatted?: string | null;
            createdOn?: string | null;
            createdBy?: string | null;
          } | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
        } | null> | null;
        documentIds?: Array<string | null> | null;
        unitOfMeasure?: {
          __typename: 'UnitOfMeasure';
          unitOfMeasureId: string;
          unitOfMeasureTypeId?: string | null;
          companyId?: string | null;
          unitOfMeasureName: string;
          unitOfMeasureCode: string;
          createdOn?: string | null;
          createdBy?: string | null;
          updatedOn?: string | null;
          modifiedBy?: string | null;
          modifiedOn?: string | null;
        } | null;
      } | null;
      partAttribute?: {
        __typename: 'PartAttribute';
        partAttributeId: string;
        partAttributeName: string;
        allowWriteInVariant?: boolean | null;
        rangeMinimumValue?: number | null;
        rangeMaximumValue?: number | null;
        selectVariants?: Array<{
          __typename: 'PartAttributeSelectVariant';
          partAttributeSelectVariantId?: string | null;
          textValue?: string | null;
          codeValue?: string | null;
          isDeleted?: boolean | null;
        } | null> | null;
        partAttributeType?: {
          __typename: 'PartAttributeType';
          partAttributeTypeId: string;
          partAttributeTypeName: string;
          partAttributeTypeDescription?: string | null;
          sortOrder?: number | null;
        } | null;
        partCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
      } | null;
      selectValues?: Array<{
        __typename: 'PartAttributeSelectVariant';
        partAttributeSelectVariantId?: string | null;
        textValue?: string | null;
        codeValue?: string | null;
        isDeleted?: boolean | null;
      } | null> | null;
    } | null> | null;
    partCategory?: {
      __typename: 'PartCategory';
      partCategoryId?: string | null;
      partCategoryName?: string | null;
      partCatalog?: {
        __typename: 'PartCatalog';
        partCatalogId: string;
        partCatalogName: string;
        companyId: string;
        topLevelNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      parentPartCategory?: {
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      subNodes?: Array<{
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null> | null;
    } | null;
    partImages?: Array<{
      __typename: 'PartImage';
      partImageId?: string | null;
      sortOrder?: number | null;
      isDefault?: boolean | null;
      document?: {
        __typename: 'Document';
        documentId: string;
        documentName?: string | null;
        documentIdentifier?: string | null;
        documentTypeId?: string | null;
        storagePath?: string | null;
        extension?: string | null;
        encodedUrn?: string | null;
        documentStatusTypeId?: string | null;
        isMappedFromShop?: boolean | null;
        folderDocumentResponse?: Array<{
          __typename: 'FolderDocument';
          folderDocumentId: string;
          folderId: string;
          folderName?: string | null;
          documentId: string;
        } | null> | null;
        documentNameFormatted?: string | null;
        createdOn?: string | null;
        createdBy?: string | null;
      } | null;
      part?: {
        __typename: 'Part';
        partId: string;
        partName: string;
        description?: string | null;
        stateId?: string | null;
        isDefault?: boolean | null;
        manufacturerCatalogNumber?: string | null;
        unitOfMeasureCode?: string | null;
        unitOfMeasureName?: string | null;
        upcCode?: string | null;
        url?: string | null;
        catalogUrl?: string | null;
        partUrl?: string | null;
        internalPartNumber?: string | null;
        createdOn?: string | null;
        hasAssembly?: boolean | null;
        manufacturer?: {
          __typename: 'Manufactured';
          manufacturerId: string;
          companyId?: string | null;
          manufacturerName: string;
          homePageUrl?: string | null;
        } | null;
        attriubuteVariantData?: Array<{
          __typename: 'PartAttributeVariantData';
          partAttributeVariantDataId?: string | null;
          partAttributeId?: string | null;
          partAttributeName?: string | null;
          rangeValue?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          partAttribute?: {
            __typename: 'PartAttribute';
            partAttributeId: string;
            partAttributeName: string;
            allowWriteInVariant?: boolean | null;
            rangeMinimumValue?: number | null;
            rangeMaximumValue?: number | null;
            selectVariants?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
            partAttributeType?: {
              __typename: 'PartAttributeType';
              partAttributeTypeId: string;
              partAttributeTypeName: string;
              partAttributeTypeDescription?: string | null;
              sortOrder?: number | null;
            } | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
          } | null;
          selectValues?: Array<{
            __typename: 'PartAttributeSelectVariant';
            partAttributeSelectVariantId?: string | null;
            textValue?: string | null;
            codeValue?: string | null;
            isDeleted?: boolean | null;
          } | null> | null;
        } | null> | null;
        partCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        partImages?: Array<{
          __typename: 'PartImage';
          partImageId?: string | null;
          sortOrder?: number | null;
          isDefault?: boolean | null;
          document?: {
            __typename: 'Document';
            documentId: string;
            documentName?: string | null;
            documentIdentifier?: string | null;
            documentTypeId?: string | null;
            storagePath?: string | null;
            extension?: string | null;
            encodedUrn?: string | null;
            documentStatusTypeId?: string | null;
            isMappedFromShop?: boolean | null;
            folderDocumentResponse?: Array<{
              __typename: 'FolderDocument';
              folderDocumentId: string;
              folderId: string;
              folderName?: string | null;
              documentId: string;
            } | null> | null;
            documentNameFormatted?: string | null;
            createdOn?: string | null;
            createdBy?: string | null;
          } | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
        } | null> | null;
        documentIds?: Array<string | null> | null;
        unitOfMeasure?: {
          __typename: 'UnitOfMeasure';
          unitOfMeasureId: string;
          unitOfMeasureTypeId?: string | null;
          companyId?: string | null;
          unitOfMeasureName: string;
          unitOfMeasureCode: string;
          createdOn?: string | null;
          createdBy?: string | null;
          updatedOn?: string | null;
          modifiedBy?: string | null;
          modifiedOn?: string | null;
        } | null;
      } | null;
    } | null> | null;
    documentIds?: Array<string | null> | null;
    unitOfMeasure?: {
      __typename: 'UnitOfMeasure';
      unitOfMeasureId: string;
      unitOfMeasureTypeId?: string | null;
      companyId?: string | null;
      unitOfMeasureName: string;
      unitOfMeasureCode: string;
      createdOn?: string | null;
      createdBy?: string | null;
      updatedOn?: string | null;
      modifiedBy?: string | null;
      modifiedOn?: string | null;
    } | null;
  } | null;
};

export type MessagesQueryVariables = {
  query?: QueryMessagesQueryInput | null;
};

export type MessagesQuery = {
  messages?: Array<{
    __typename: 'Message';
    messageId?: string | null;
    companyId?: string | null;
    messagePriorityId?: string | null;
    fromName?: string | null;
    subject?: string | null;
    body?: string | null;
    messagePriorityCode?: MESSAGE_PRIORITY_CODE | null;
    statusId?: string | null;
    statusName?: string | null;
    accessedOn?: string | null;
    createdOn?: string | null;
  } | null> | null;
};

export type MessagesCountQueryVariables = {
  query?: QueryMessagesCountQueryInput | null;
};

export type MessagesCountQuery = {
  messagesCount?: number | null;
};

export type MessageByIdQueryVariables = {
  params: QueryMessageByIdParamsInput;
  query?: QueryMessageByIdQueryInput | null;
};

export type MessageByIdQuery = {
  messageById?: {
    __typename: 'Message';
    messageId?: string | null;
    companyId?: string | null;
    messagePriorityId?: string | null;
    fromName?: string | null;
    subject?: string | null;
    body?: string | null;
    messagePriorityCode?: MESSAGE_PRIORITY_CODE | null;
    statusId?: string | null;
    statusName?: string | null;
    accessedOn?: string | null;
    createdOn?: string | null;
  } | null;
};

export type TakeoffPadsQueryVariables = {
  query: QueryTakeoffPadsQueryInput;
};

export type TakeoffPadsQuery = {
  takeoffPads?: Array<{
    __typename: 'TakeOffPad';
    takeoffPadId: string;
    takeoffPadName: string;
    lineItemContainerId?: string | null;
    lineItemContainerStatusId?: string | null;
    lineItemContainerStatusName?: string | null;
    projectId: string;
    lineItemContainerName?: string | null;
    hasMappedItems?: boolean | null;
    hasItemsMappedFromSourcePad?: boolean | null;
    createdOn: string;
    createdBy: string;
    createdByFirstName?: string | null;
    createdByLastName?: string | null;
    createdByEmail?: string | null;
    updatedOn?: string | null;
    updatedBy?: string | null;
    updatedByFirstName?: string | null;
    updatedByLastName?: string | null;
    updatedByEmail?: string | null;
  } | null> | null;
};

export type TakeoffPadItemsQueryVariables = {
  query: QueryTakeoffPadItemsQueryInput;
};

export type TakeoffPadItemsQuery = {
  takeoffPadItems?: Array<{
    __typename: 'TakeOffPadItem';
    lineItemId: string;
    lineItemTypeId: string;
    lineItemTypeName?: string | null;
    takeoffPadId: string;
    unitOfMeasureId?: string | null;
    unitOfMeasureName?: string | null;
    unitOfMeasureCode?: string | null;
    manufacturerId?: string | null;
    manufacturerName?: string | null;
    partId?: string | null;
    locationId?: string | null;
    workPhaseId?: string | null;
    scopePackageId?: string | null;
    workPackageId?: string | null;
    kitPlanId?: string | null;
    spoolId?: string | null;
    lineItemName?: string | null;
    manufacturerCatalogNumber?: string | null;
    quantity?: number | null;
    remainingQuantity?: number | null;
    unitQuantity?: number | null;
    tradeSize?: string | null;
    billOfMaterialsName?: string | null;
    createdOn?: string | null;
    createdBy?: string | null;
    createdByFirstName?: string | null;
    createdByLastName?: string | null;
    createdByEmail?: string | null;
    updatedOn?: string | null;
    updatedBy?: string | null;
    updatedByFirstName?: string | null;
    updatedByLastName?: string | null;
    updatedByEmail?: string | null;
    numberOfOpenDecisions?: number | null;
    decisionsHaveVariantDeletedFromCurrentVersionOfPart?: boolean | null;
    ableToMakeDecisions?: boolean | null;
    numberOfDecisionsMade?: number | null;
  } | null> | null;
};

export type TakeoffPadItemsCountQueryVariables = {
  query: QueryTakeoffPadItemsCountQueryInput;
};

export type TakeoffPadItemsCountQuery = {
  takeoffPadItemsCount?: number | null;
};

export type TaskTypesQueryVariables = {
  query?: QueryTaskTypesQueryInput | null;
};

export type TaskTypesQuery = {
  taskTypes?: Array<{
    __typename: 'TaskType';
    taskTypeId: string;
    taskTypeImageId?: string | null;
    taskTypeName?: string | null;
    taskTypeDescription?: string | null;
    taskTypeClassId: string;
    companyId: string;
  } | null> | null;
};

export type TaskTypeByIdQueryVariables = {
  params: QueryTaskTypeByIdParamsInput;
};

export type TaskTypeByIdQuery = {
  taskTypeById?: {
    __typename: 'TaskType';
    taskTypeId: string;
    taskTypeImageId?: string | null;
    taskTypeName?: string | null;
    taskTypeDescription?: string | null;
    taskTypeClassId: string;
    companyId: string;
  } | null;
};

export type ShopConfigurationsQueryVariables = {
  params: QueryShopConfigurationsParamsInput;
};

export type ShopConfigurationsQuery = {
  shopConfigurations?: Array<{
    __typename: 'ShopConfiguration';
    shopConfigurationId?: string | null;
    shopConfigurationName?: string | null;
    shopConfigurationDescription?: string | null;
    departmentId?: string | null;
    facilityId?: string | null;
    isActive?: boolean | null;
  } | null> | null;
};

export type ShopConfigurationsMultiSearchQueryVariables = {
  query: QueryShopConfigurationsMultiSearchQueryInput;
};

export type ShopConfigurationsMultiSearchQuery = {
  shopConfigurationsMultiSearch?: Array<{
    __typename: 'ShopConfiguration';
    shopConfigurationId?: string | null;
    shopConfigurationName?: string | null;
    shopConfigurationDescription?: string | null;
    departmentId?: string | null;
    facilityId?: string | null;
    isActive?: boolean | null;
  } | null> | null;
};

export type ShopPropertyByIdQueryVariables = {
  params: QueryShopPropertyByIdParamsInput;
};

export type ShopPropertyByIdQuery = {
  shopPropertyById?: {
    __typename: 'ShopProperty';
    shopPropertyId?: string | null;
    shopPropertyName?: string | null;
    shopPropertyDescription?: string | null;
    shippingAddressLine1?: string | null;
    shippingAddressLine2?: string | null;
    city?: string | null;
    state?: string | null;
    zip?: string | null;
    country?: string | null;
    userId?: string | null;
    shopConfigurationId?: string | null;
    facilityId?: string | null;
  } | null;
};

export type ShopPropertiesQueryVariables = {
  query?: QueryShopPropertiesQueryInput | null;
};

export type ShopPropertiesQuery = {
  shopProperties?: Array<{
    __typename: 'ShopProperty';
    shopPropertyId?: string | null;
    shopPropertyName?: string | null;
    shopPropertyDescription?: string | null;
    shippingAddressLine1?: string | null;
    shippingAddressLine2?: string | null;
    city?: string | null;
    state?: string | null;
    zip?: string | null;
    country?: string | null;
    userId?: string | null;
    shopConfigurationId?: string | null;
    facilityId?: string | null;
  } | null> | null;
};

export type WorkCellQueryVariables = {
  params: QueryWorkCellParamsInput;
};

export type WorkCellQuery = {
  workCell?: {
    __typename: 'WorkCell';
    workCellId: string;
    workCellName: string;
    workCellDescription?: string | null;
    facilityId: string;
    shopConfigurationId: string;
    hasTaskTypes: boolean;
    hasMachines: boolean;
  } | null;
};

export type WorkCellsQueryVariables = {
  query?: QueryWorkCellsQueryInput | null;
};

export type WorkCellsQuery = {
  workCells?: Array<{
    __typename: 'WorkCell';
    workCellId: string;
    workCellName: string;
    workCellDescription?: string | null;
    facilityId: string;
    shopConfigurationId: string;
    hasTaskTypes: boolean;
    hasMachines: boolean;
  } | null> | null;
};

export type WorkCellTaskTypeQueryVariables = {
  query?: QueryWorkCellTaskTypeQueryInput | null;
};

export type WorkCellTaskTypeQuery = {
  workCellTaskType?: Array<{
    __typename: 'WorkCellTaskType';
    workCellTaskTypeId: string;
    workCellId: string;
    taskTypeId: string;
    taskTypeImageId?: string | null;
    taskTypeName: string;
    taskTypeDescription?: string | null;
    taskTypeClassId?: string | null;
    companyId: string;
    machines?: Array<{
      __typename: 'Machine';
      machineId: string;
      manufacturer?: string | null;
      model?: string | null;
      serialNumber?: string | null;
      machineImageId?: string | null;
      facilityId: string;
      machineIdentifier: string;
      workCells?: Array<{
        __typename: 'MachineWorkCell';
        workCellId: string;
        shopConfigurationId: string;
      } | null> | null;
    } | null> | null;
  } | null> | null;
};

export type SourcePadItemsQueryVariables = {
  query: QuerySourcePadItemsQueryInput;
};

export type SourcePadItemsQuery = {
  sourcePadItems?: Array<{
    __typename: 'SourcePadItem';
    previousLineItemId?: string | null;
    lineItemId?: string | null;
    lineItemTypeId?: string | null;
    lineItemTypeName?: string | null;
    sourcePadId?: string | null;
    unitOfMeasureId?: string | null;
    unitOfMeasureName?: string | null;
    unitOfMeasureCode?: string | null;
    manufacturerId?: string | null;
    manufacturerName?: string | null;
    partId?: string | null;
    locationId?: string | null;
    workPhaseId?: string | null;
    scopePackageId?: string | null;
    workPackageId?: string | null;
    kitPlanId?: string | null;
    spoolId?: string | null;
    lineItemName?: string | null;
    manufacturerCatalogNumber?: string | null;
    quantity?: number | null;
    actualQuantity?: number | null;
    remainingQuantity?: number | null;
    actualRemainingQuantity?: number | null;
    unitQuantity?: number | null;
    tradeSize?: string | null;
    takeoffPadName?: string | null;
    createdOn?: string | null;
    createdBy?: string | null;
    createdByFirstName?: string | null;
    createdByLastName?: string | null;
    createdByEmail?: string | null;
    updatedOn?: string | null;
    updatedBy?: string | null;
    updatedByFirstName?: string | null;
    updatedByLastName?: string | null;
    updatedByEmail?: string | null;
  } | null> | null;
};

export type SourcePadItemsCountQueryVariables = {
  query: QuerySourcePadItemsCountQueryInput;
};

export type SourcePadItemsCountQuery = {
  sourcePadItemsCount?: number | null;
};

export type SourcePadNamesQueryVariables = {
  query: QuerySourcePadNamesQueryInput;
};

export type SourcePadNamesQuery = {
  sourcePadNames?: Array<{
    __typename: 'SourcePadName';
    sourcePadId?: string | null;
    sourcePadName?: string | null;
    hasMappedItems?: boolean | null;
    createdOn?: string | null;
  } | null> | null;
};

export type SourcePadPropertiesQueryVariables = {
  params: QuerySourcePadPropertiesParamsInput;
};

export type SourcePadPropertiesQuery = {
  sourcePadProperties?: Array<{
    __typename: 'SourcePadProperties';
    sourcePadId: string;
    externalSourceImportDescription?: string | null;
    lastPublished?: string | null;
    publishedBy?: string | null;
    sourcePadNotes?: Array<{
      __typename: 'SourcePadNote';
      sourcePadNoteId: string;
      note?: string | null;
      date?: string | null;
      createdBy?: string | null;
    } | null> | null;
  } | null> | null;
};

export type FoldersQueryVariables = {
  query?: QueryFoldersQueryInput | null;
};

export type FoldersQuery = {
  folders?: Array<{
    __typename: 'Folder';
    folderId: string;
    parentFolderId?: string | null;
    companyId: string;
    folderName: string;
    storagePath: string;
    folderDescription?: string | null;
    hasSubFolder?: boolean | null;
    isHidden?: boolean | null;
    projectId: string;
  } | null> | null;
};

export type FolderByIdQueryVariables = {
  params: QueryFolderByIdParamsInput;
};

export type FolderByIdQuery = {
  folderById?: {
    __typename: 'Folder';
    folderId: string;
    parentFolderId?: string | null;
    companyId: string;
    folderName: string;
    storagePath: string;
    folderDescription?: string | null;
    hasSubFolder?: boolean | null;
    isHidden?: boolean | null;
    projectId: string;
  } | null;
};

export type DocumentsQueryVariables = {
  query?: QueryDocumentsQueryInput | null;
};

export type DocumentsQuery = {
  documents?: Array<{
    __typename: 'Document';
    documentId: string;
    documentName?: string | null;
    documentIdentifier?: string | null;
    documentTypeId?: string | null;
    storagePath?: string | null;
    extension?: string | null;
    encodedUrn?: string | null;
    documentStatusTypeId?: string | null;
    isMappedFromShop?: boolean | null;
    folderDocumentResponse?: Array<{
      __typename: 'FolderDocument';
      folderDocumentId: string;
      folderId: string;
      folderName?: string | null;
      documentId: string;
    } | null> | null;
    documentNameFormatted?: string | null;
    createdOn?: string | null;
    createdBy?: string | null;
  } | null> | null;
};

export type DocumentByIdQueryVariables = {
  params: QueryDocumentByIdParamsInput;
};

export type DocumentByIdQuery = {
  documentById?: {
    __typename: 'Document';
    documentId: string;
    documentName?: string | null;
    documentIdentifier?: string | null;
    documentTypeId?: string | null;
    storagePath?: string | null;
    extension?: string | null;
    encodedUrn?: string | null;
    documentStatusTypeId?: string | null;
    isMappedFromShop?: boolean | null;
    folderDocumentResponse?: Array<{
      __typename: 'FolderDocument';
      folderDocumentId: string;
      folderId: string;
      folderName?: string | null;
      documentId: string;
    } | null> | null;
    documentNameFormatted?: string | null;
    createdOn?: string | null;
    createdBy?: string | null;
  } | null;
};

export type DocumentIsTheLastVersionQueryVariables = {
  params: QueryDocumentIsTheLastVersionParamsInput;
};

export type DocumentIsTheLastVersionQuery = {
  documentIsTheLastVersion?: {
    __typename: 'DocumentIsTheLastVersion';
    isLatestVersion?: string | null;
  } | null;
};

export type ForgeDocumentQueryVariables = {
  params: QueryForgeDocumentParamsInput;
};

export type ForgeDocumentQuery = {
  forgeDocument?: {
    __typename: 'ForgeDocument';
    token?: string | null;
    timeInSeconds: number;
    encodedUrn?: string | null;
    documentStatusType?: {
      __typename: 'DocumentStatusType';
      documentStatusTypeId: string;
      documentStatusTypeName: string;
    } | null;
  } | null;
};

export type LicensesQueryVariables = {
  query?: QueryLicensesQueryInput | null;
};

export type LicensesQuery = {
  licenses?: Array<{
    __typename: 'License';
    productPoolId?: string | null;
    companyId?: string | null;
    productId?: string | null;
    product?: {
      __typename: 'LicenseProduct';
      productId?: string | null;
      productName?: string | null;
      licenseClassId?: string | null;
      licenseTypeId?: string | null;
    } | null;
    bundleId?: string | null;
    bundle?: {
      __typename: 'LicenseBundle';
      bundleId?: string | null;
      bundleName?: string | null;
    } | null;
    poolQuantity?: number | null;
    poolAvailableQuantity?: number | null;
    customData?: string | null;
    renewalDate?: string | null;
    trialRenewalDate?: string | null;
    startDate?: string | null;
    label?: string | null;
    type?: string | null;
  } | null> | null;
};

export type SummarizeLocationPhasesQueryVariables = {
  query: QuerySummarizeLocationPhasesQueryInput;
};

export type SummarizeLocationPhasesQuery = {
  summarizeLocationPhases?: {
    __typename: 'SummarizeLocationPhases';
    projectId?: string | null;
    hasLocationPhases?: boolean | null;
    numberOfLocationPhases?: number | null;
  } | null;
};

export type LocationListByWorkPhaseQueryVariables = {
  query: QueryLocationListByWorkPhaseQueryInput;
};

export type LocationListByWorkPhaseQuery = {
  locationListByWorkPhase?: Array<{
    __typename: 'Location';
    locationId?: string | null;
    locationTypeId?: string | null;
    locationTypeName?: string | null;
    projectId?: string | null;
    locationIdentifier?: string | null;
    locationCompositeIdentifier?: string | null;
    sortOrder?: number | null;
    hasChildren?: boolean | null;
    parentLocationId?: string | null;
    locationName?: string | null;
    locationCompositeName?: string | null;
    locationPhases?: Array<{
      __typename: 'LocationPhase';
      locationPhaseId: string;
      locationId?: string | null;
      workPhaseId: string;
      workPhaseName: string;
      scopePackageId: string;
      scopePackageName: string;
      sortOrder: number;
    } | null> | null;
    childrenTypeName?: string | null;
    movedLocationPhases?: Array<{
      __typename: 'UpdatedLocationPhase';
      workPhaseId: string;
      locationPhaseId: string;
    } | null> | null;
  } | null> | null;
};

export type MachineByIdQueryVariables = {
  params: QueryMachineByIdParamsInput;
};

export type MachineByIdQuery = {
  machineById?: {
    __typename: 'Machine';
    machineId: string;
    manufacturer?: string | null;
    model?: string | null;
    serialNumber?: string | null;
    machineImageId?: string | null;
    facilityId: string;
    machineIdentifier: string;
    workCells?: Array<{
      __typename: 'MachineWorkCell';
      workCellId: string;
      shopConfigurationId: string;
    } | null> | null;
  } | null;
};

export type MachinesQueryVariables = {
  query?: QueryMachinesQueryInput | null;
};

export type MachinesQuery = {
  machines?: Array<{
    __typename: 'Machine';
    machineId: string;
    manufacturer?: string | null;
    model?: string | null;
    serialNumber?: string | null;
    machineImageId?: string | null;
    facilityId: string;
    machineIdentifier: string;
    workCells?: Array<{
      __typename: 'MachineWorkCell';
      workCellId: string;
      shopConfigurationId: string;
    } | null> | null;
  } | null> | null;
};

export type MachineTaskTypeQueryVariables = {
  query?: QueryMachineTaskTypeQueryInput | null;
};

export type MachineTaskTypeQuery = {
  machineTaskType?: Array<{
    __typename: 'MachineTaskType';
    machineTaskTypeId: string;
    machineId: string;
    taskTypeId: string;
    taskTypeImageId?: string | null;
    taskTypeName: string;
    taskTypeDescription?: string | null;
    taskTypeClassId?: string | null;
    companyId: string;
  } | null> | null;
};

export type WorkOrdersQueryVariables = {
  query?: QueryWorkOrdersQueryInput | null;
};

export type WorkOrdersQuery = {
  workOrders?: Array<{
    __typename: 'WorkOrder';
    workOrderId?: string | null;
    workRequestId?: string | null;
    workOrderStatusTypeId?: string | null;
    workOrderStatusTypeName?: string | null;
    workOrderName?: string | null;
    workOrderDescription?: string | null;
    workOrderNumber?: string | null;
    workRequest?: {
      __typename: 'WorkRequest';
      workRequestId?: string | null;
      workRequestStatusId?: string | null;
      workRequestStatusName?: string | null;
      facilityId?: string | null;
      projectId?: string | null;
      projectIdentifier?: string | null;
      projectName?: string | null;
      workRequestIdentifier?: string | null;
      workRequestName?: string | null;
      needBy?: string | null;
      workRequestDescription?: string | null;
      facilityName?: string | null;
      workRequestStatusDescription?: string | null;
      lastStatusUpdate?: string | null;
      numberOfAttachments?: number | null;
    } | null;
    sortOrder?: number | null;
    createdBy?: string | null;
    lastStatusUpdate?: string | null;
    createdOn?: string | null;
    isStepFunctionProcessing?: boolean | null;
    stepFunctionProcessingMessage?: string | null;
    numberOfAttachments?: number | null;
    hasCustomWorkOrderName?: boolean | null;
  } | null> | null;
};

export type WorkOrderItemQueryVariables = {
  query?: QueryWorkOrderItemQueryInput | null;
};

export type WorkOrderItemQuery = {
  workOrderItem?: Array<{
    __typename: 'WorkOrderItem';
    workRequestItemId?: string | null;
    hasShopTask?: boolean | null;
    workOrderItemId?: string | null;
    workOrderId?: string | null;
    workOrderItemTypeId?: string | null;
    unitOfMeasureId?: string | null;
    billOfProcessId?: string | null;
    workOrderItemName?: string | null;
    WorkOrderItemIdentification?: string | null;
    quantity?: number | null;
    unityQuantity?: number | null;
    assignedQuantity?: number | null;
    remainingQuantity?: number | null;
    createdOn?: string | null;
    createdBy?: string | null;
    workOrderItemStatusTypeId?: string | null;
  } | null> | null;
};

export type WorkOrderItemByIdQueryVariables = {
  params: QueryWorkOrderItemByIdParamsInput;
};

export type WorkOrderItemByIdQuery = {
  workOrderItemById?: {
    __typename: 'WorkOrderItem';
    workRequestItemId?: string | null;
    hasShopTask?: boolean | null;
    workOrderItemId?: string | null;
    workOrderId?: string | null;
    workOrderItemTypeId?: string | null;
    unitOfMeasureId?: string | null;
    billOfProcessId?: string | null;
    workOrderItemName?: string | null;
    WorkOrderItemIdentification?: string | null;
    quantity?: number | null;
    unityQuantity?: number | null;
    assignedQuantity?: number | null;
    remainingQuantity?: number | null;
    createdOn?: string | null;
    createdBy?: string | null;
    workOrderItemStatusTypeId?: string | null;
  } | null;
};

export type WorkOrdersCountQueryVariables = {
  query?: QueryWorkOrdersCountQueryInput | null;
};

export type WorkOrdersCountQuery = {
  workOrdersCount?: {
    __typename: 'PaginationResponse';
    requestedSkip?: number | null;
    requestedTake?: number | null;
    entireCount?: number | null;
  } | null;
};

export type SyncConnectionsQueryVariables = {
  query?: QuerySyncConnectionsQueryInput | null;
};

export type SyncConnectionsQuery = {
  syncConnections?: Array<{
    __typename: 'SyncConnection';
    id: string;
    name?: string | null;
    type?: string | null;
    configuration?: string | null;
    isConnectionUsed?: boolean | null;
    lastModified?: string | null;
    desktopConnectionId?: string | null;
  } | null> | null;
};

export type WorkCellMachineQueryVariables = {
  query?: QueryWorkCellMachineQueryInput | null;
};

export type WorkCellMachineQuery = {
  workCellMachine?: Array<{
    __typename: 'WorkCellMachine';
    workCellMachineId: string;
    facilityId: string;
    shopConfigurationId: string;
    workCellId: string;
    machineId: string;
    machineManufacturer?: string | null;
    machineIdentifier?: string | null;
  } | null> | null;
};

export type WorkCellTasksMultiSearchQueryVariables = {
  query?: QueryWorkCellTasksMultiSearchQueryInput | null;
};

export type WorkCellTasksMultiSearchQuery = {
  workCellTasksMultiSearch?: Array<{
    __typename: 'WorkCellTask';
    workCellTaskId?: string | null;
    workCellId?: string | null;
    workCellName?: string | null;
    projectId?: string | null;
    workOrderId?: string | null;
    workRequestItemId?: string | null;
    workRequestId?: string | null;
    taskId?: string | null;
    taskTypeId?: string | null;
    taskStatusTypeId?: string | null;
    projectIdentifier?: string | null;
    projectName?: string | null;
    workOrderName?: string | null;
    workRequestItemName?: string | null;
    taskName?: string | null;
    taskTypeName?: string | null;
    taskStatusTypeName?: string | null;
    sortOrder?: number | null;
    isEnabled?: boolean | null;
    needBy?: string | null;
    hasTaskTypes?: boolean | null;
    taskStatusTypeUpdatedOn?: string | null;
    workRequestName?: string | null;
    workRequestItemTypeName?: string | null;
    workRequestItemTypeId?: string | null;
    workRequestIdentifier?: string | null;
  } | null> | null;
};

export type WorkCellTasksMultiSearchCountQueryVariables = {
  query?: QueryWorkCellTasksMultiSearchCountQueryInput | null;
};

export type WorkCellTasksMultiSearchCountQuery = {
  workCellTasksMultiSearchCount?: {
    __typename: 'PaginationResponse';
    requestedSkip?: number | null;
    requestedTake?: number | null;
    entireCount?: number | null;
  } | null;
};

export type WorkCellTasksQueueQueryVariables = {
  query?: QueryWorkCellTasksQueueQueryInput | null;
};

export type WorkCellTasksQueueQuery = {
  workCellTasksQueue?: Array<{
    __typename: 'WorkCellTask';
    workCellTaskId?: string | null;
    workCellId?: string | null;
    workCellName?: string | null;
    projectId?: string | null;
    workOrderId?: string | null;
    workRequestItemId?: string | null;
    workRequestId?: string | null;
    taskId?: string | null;
    taskTypeId?: string | null;
    taskStatusTypeId?: string | null;
    projectIdentifier?: string | null;
    projectName?: string | null;
    workOrderName?: string | null;
    workRequestItemName?: string | null;
    taskName?: string | null;
    taskTypeName?: string | null;
    taskStatusTypeName?: string | null;
    sortOrder?: number | null;
    isEnabled?: boolean | null;
    needBy?: string | null;
    hasTaskTypes?: boolean | null;
    taskStatusTypeUpdatedOn?: string | null;
    workRequestName?: string | null;
    workRequestItemTypeName?: string | null;
    workRequestItemTypeId?: string | null;
    workRequestIdentifier?: string | null;
  } | null> | null;
};

export type WorkCellTasksQueueCountQueryVariables = {
  query?: QueryWorkCellTasksQueueCountQueryInput | null;
};

export type WorkCellTasksQueueCountQuery = {
  workCellTasksQueueCount?: number | null;
};

export type SyncWorkflowsQueryVariables = {
  query?: QuerySyncWorkflowsQueryInput | null;
};

export type SyncWorkflowsQuery = {
  syncWorkflows?: Array<{
    __typename: 'Workflow';
    workflowId: string;
    workflowName?: string | null;
    workflowMapping?: Array<{
      __typename: 'WorkflowMappingItem';
      workflowMappingId: string;
      workflowMappingName?: string | null;
      isSyncRunning?: boolean | null;
      lastSyncAt?: string | null;
      lastSavedAt?: string | null;
      isArchived?: boolean | null;
      isDeactivated?: boolean | null;
      solitaryFlows?: Array<{
        __typename: 'SolitaryFlowItem';
        solitaryFlowId?: string | null;
        action?: string | null;
        actionNodeId?: string | null;
        actionAlias?: string | null;
        isArchived?: boolean | null;
        isDeactivated?: boolean | null;
        source?: {
          __typename: 'MappingFlowConfigurationSourceNodeInfo';
          syncDetailsId?: string | null;
          nodeId?: string | null;
          connection?: {
            __typename: 'SyncConnection';
            id: string;
            name?: string | null;
            type?: string | null;
            configuration?: string | null;
            isConnectionUsed?: boolean | null;
            lastModified?: string | null;
            desktopConnectionId?: string | null;
          } | null;
          hub?: {
            __typename: 'ConnectorFieldItem';
            id?: string | null;
            name?: string | null;
          } | null;
          projects?: Array<{
            __typename: 'ConnectorProjectItem';
            id?: string | null;
            name?: string | null;
            folders?: Array<{
              __typename: 'ConnectorFieldFolder';
              id?: string | null;
              name?: string | null;
            } | null> | null;
          } | null> | null;
          additionalData?: string | null;
          settings?: {
            __typename: 'MappingConfigurationSettings';
            isSyncEnabled?: boolean | null;
            includeExtensions?: string | null;
            secondaryFilesSetting?: string | null;
            isSyncAllRevisions?: boolean | null;
            syncType?: string | null;
            scheduleSyncConfig?: string | null;
          } | null;
        } | null;
        destinations?: Array<{
          __typename: 'MappingFlowConfigurationDestinationNodeInfo';
          syncDetailsId?: string | null;
          nodeId?: string | null;
          connection?: {
            __typename: 'SyncConnection';
            id: string;
            name?: string | null;
            type?: string | null;
            configuration?: string | null;
            isConnectionUsed?: boolean | null;
            lastModified?: string | null;
            desktopConnectionId?: string | null;
          } | null;
          hub?: {
            __typename: 'ConnectorFieldItem';
            id?: string | null;
            name?: string | null;
          } | null;
          project?: {
            __typename: 'ConnectorProjectSingleFieldItem';
            id?: string | null;
            name?: string | null;
            folder?: {
              __typename: 'ConnectorFieldItem';
              id?: string | null;
              name?: string | null;
            } | null;
          } | null;
          additionalData?: string | null;
        } | null> | null;
        syncStatus?: number | null;
        lastSyncAt?: string | null;
        actionType?: string | null;
      } | null> | null;
      publishModelSolitaryFlows?: Array<{
        __typename: 'PublishModelSolitaryFlowItem';
        solitaryFlowId?: string | null;
        action?: string | null;
        actionNodeId?: string | null;
        actionAlias?: string | null;
        isArchived?: boolean | null;
        isDeactivated?: boolean | null;
        source?: {
          __typename: 'MappingFlowConfigurationSourceNodeInfo';
          syncDetailsId?: string | null;
          nodeId?: string | null;
          connection?: {
            __typename: 'SyncConnection';
            id: string;
            name?: string | null;
            type?: string | null;
            configuration?: string | null;
            isConnectionUsed?: boolean | null;
            lastModified?: string | null;
            desktopConnectionId?: string | null;
          } | null;
          hub?: {
            __typename: 'ConnectorFieldItem';
            id?: string | null;
            name?: string | null;
          } | null;
          projects?: Array<{
            __typename: 'ConnectorProjectItem';
            id?: string | null;
            name?: string | null;
            folders?: Array<{
              __typename: 'ConnectorFieldFolder';
              id?: string | null;
              name?: string | null;
            } | null> | null;
          } | null> | null;
          additionalData?: string | null;
          settings?: {
            __typename: 'MappingConfigurationSettings';
            isSyncEnabled?: boolean | null;
            includeExtensions?: string | null;
            secondaryFilesSetting?: string | null;
            isSyncAllRevisions?: boolean | null;
            syncType?: string | null;
            scheduleSyncConfig?: string | null;
          } | null;
        } | null;
        destinations?: Array<{
          __typename: 'MappingFlowConfigurationDestinationNodeInfo';
          syncDetailsId?: string | null;
          nodeId?: string | null;
          connection?: {
            __typename: 'SyncConnection';
            id: string;
            name?: string | null;
            type?: string | null;
            configuration?: string | null;
            isConnectionUsed?: boolean | null;
            lastModified?: string | null;
            desktopConnectionId?: string | null;
          } | null;
          hub?: {
            __typename: 'ConnectorFieldItem';
            id?: string | null;
            name?: string | null;
          } | null;
          project?: {
            __typename: 'ConnectorProjectSingleFieldItem';
            id?: string | null;
            name?: string | null;
            folder?: {
              __typename: 'ConnectorFieldItem';
              id?: string | null;
              name?: string | null;
            } | null;
          } | null;
          additionalData?: string | null;
        } | null> | null;
        syncStatus?: number | null;
        lastSyncAt?: string | null;
        actionType?: string | null;
      } | null> | null;
    } | null> | null;
    isArchived?: boolean | null;
    isDeactivated?: boolean | null;
  } | null> | null;
};

export type SyncArchivedWorkflowsQueryVariables = {
  query?: QuerySyncArchivedWorkflowsQueryInput | null;
};

export type SyncArchivedWorkflowsQuery = {
  syncArchivedWorkflows?: Array<{
    __typename: 'Workflow';
    workflowId: string;
    workflowName?: string | null;
    workflowMapping?: Array<{
      __typename: 'WorkflowMappingItem';
      workflowMappingId: string;
      workflowMappingName?: string | null;
      isSyncRunning?: boolean | null;
      lastSyncAt?: string | null;
      lastSavedAt?: string | null;
      isArchived?: boolean | null;
      isDeactivated?: boolean | null;
      solitaryFlows?: Array<{
        __typename: 'SolitaryFlowItem';
        solitaryFlowId?: string | null;
        action?: string | null;
        actionNodeId?: string | null;
        actionAlias?: string | null;
        isArchived?: boolean | null;
        isDeactivated?: boolean | null;
        source?: {
          __typename: 'MappingFlowConfigurationSourceNodeInfo';
          syncDetailsId?: string | null;
          nodeId?: string | null;
          connection?: {
            __typename: 'SyncConnection';
            id: string;
            name?: string | null;
            type?: string | null;
            configuration?: string | null;
            isConnectionUsed?: boolean | null;
            lastModified?: string | null;
            desktopConnectionId?: string | null;
          } | null;
          hub?: {
            __typename: 'ConnectorFieldItem';
            id?: string | null;
            name?: string | null;
          } | null;
          projects?: Array<{
            __typename: 'ConnectorProjectItem';
            id?: string | null;
            name?: string | null;
            folders?: Array<{
              __typename: 'ConnectorFieldFolder';
              id?: string | null;
              name?: string | null;
            } | null> | null;
          } | null> | null;
          additionalData?: string | null;
          settings?: {
            __typename: 'MappingConfigurationSettings';
            isSyncEnabled?: boolean | null;
            includeExtensions?: string | null;
            secondaryFilesSetting?: string | null;
            isSyncAllRevisions?: boolean | null;
            syncType?: string | null;
            scheduleSyncConfig?: string | null;
          } | null;
        } | null;
        destinations?: Array<{
          __typename: 'MappingFlowConfigurationDestinationNodeInfo';
          syncDetailsId?: string | null;
          nodeId?: string | null;
          connection?: {
            __typename: 'SyncConnection';
            id: string;
            name?: string | null;
            type?: string | null;
            configuration?: string | null;
            isConnectionUsed?: boolean | null;
            lastModified?: string | null;
            desktopConnectionId?: string | null;
          } | null;
          hub?: {
            __typename: 'ConnectorFieldItem';
            id?: string | null;
            name?: string | null;
          } | null;
          project?: {
            __typename: 'ConnectorProjectSingleFieldItem';
            id?: string | null;
            name?: string | null;
            folder?: {
              __typename: 'ConnectorFieldItem';
              id?: string | null;
              name?: string | null;
            } | null;
          } | null;
          additionalData?: string | null;
        } | null> | null;
        syncStatus?: number | null;
        lastSyncAt?: string | null;
        actionType?: string | null;
      } | null> | null;
      publishModelSolitaryFlows?: Array<{
        __typename: 'PublishModelSolitaryFlowItem';
        solitaryFlowId?: string | null;
        action?: string | null;
        actionNodeId?: string | null;
        actionAlias?: string | null;
        isArchived?: boolean | null;
        isDeactivated?: boolean | null;
        source?: {
          __typename: 'MappingFlowConfigurationSourceNodeInfo';
          syncDetailsId?: string | null;
          nodeId?: string | null;
          connection?: {
            __typename: 'SyncConnection';
            id: string;
            name?: string | null;
            type?: string | null;
            configuration?: string | null;
            isConnectionUsed?: boolean | null;
            lastModified?: string | null;
            desktopConnectionId?: string | null;
          } | null;
          hub?: {
            __typename: 'ConnectorFieldItem';
            id?: string | null;
            name?: string | null;
          } | null;
          projects?: Array<{
            __typename: 'ConnectorProjectItem';
            id?: string | null;
            name?: string | null;
            folders?: Array<{
              __typename: 'ConnectorFieldFolder';
              id?: string | null;
              name?: string | null;
            } | null> | null;
          } | null> | null;
          additionalData?: string | null;
          settings?: {
            __typename: 'MappingConfigurationSettings';
            isSyncEnabled?: boolean | null;
            includeExtensions?: string | null;
            secondaryFilesSetting?: string | null;
            isSyncAllRevisions?: boolean | null;
            syncType?: string | null;
            scheduleSyncConfig?: string | null;
          } | null;
        } | null;
        destinations?: Array<{
          __typename: 'MappingFlowConfigurationDestinationNodeInfo';
          syncDetailsId?: string | null;
          nodeId?: string | null;
          connection?: {
            __typename: 'SyncConnection';
            id: string;
            name?: string | null;
            type?: string | null;
            configuration?: string | null;
            isConnectionUsed?: boolean | null;
            lastModified?: string | null;
            desktopConnectionId?: string | null;
          } | null;
          hub?: {
            __typename: 'ConnectorFieldItem';
            id?: string | null;
            name?: string | null;
          } | null;
          project?: {
            __typename: 'ConnectorProjectSingleFieldItem';
            id?: string | null;
            name?: string | null;
            folder?: {
              __typename: 'ConnectorFieldItem';
              id?: string | null;
              name?: string | null;
            } | null;
          } | null;
          additionalData?: string | null;
        } | null> | null;
        syncStatus?: number | null;
        lastSyncAt?: string | null;
        actionType?: string | null;
      } | null> | null;
    } | null> | null;
    isArchived?: boolean | null;
    isDeactivated?: boolean | null;
  } | null> | null;
};

export type SyncWebhooksQueryVariables = {
  query?: QuerySyncWebhooksQueryInput | null;
};

export type SyncWebhooksQuery = {
  syncWebhooks?: Array<{
    __typename: 'Webhook';
    webhookId?: string | null;
    webhookDetails?: string | null;
    connectionType?: string | null;
    isActive?: boolean | null;
    lastSavedAt?: string | null;
    workflows?: Array<{
      __typename: 'WorkflowItems';
      workflowId: string;
      workflowName?: string | null;
      mappings?: Array<{
        __typename: 'WorkflowMappingItems';
        mappingId: string;
        mappingName?: string | null;
        isSyncRunning?: boolean | null;
        syncStatus?: string | null;
        lastSyncTime?: string | null;
        solitaryFlows?: Array<{
          __typename: 'SolitaryFlowItems';
          solitaryFlowId: string;
          actionAlias?: string | null;
          syncStatus?: string | null;
          syncType?: string | null;
          lastSyncTime?: string | null;
          webhookRegistryId?: string | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null> | null;
};

export type GetWorkflowByIdQueryVariables = {
  params: QueryGetWorkflowByIdParamsInput;
};

export type GetWorkflowByIdQuery = {
  getWorkflowById?: {
    __typename: 'Workflow';
    workflowId: string;
    workflowName?: string | null;
    workflowMapping?: Array<{
      __typename: 'WorkflowMappingItem';
      workflowMappingId: string;
      workflowMappingName?: string | null;
      isSyncRunning?: boolean | null;
      lastSyncAt?: string | null;
      lastSavedAt?: string | null;
      isArchived?: boolean | null;
      isDeactivated?: boolean | null;
      solitaryFlows?: Array<{
        __typename: 'SolitaryFlowItem';
        solitaryFlowId?: string | null;
        action?: string | null;
        actionNodeId?: string | null;
        actionAlias?: string | null;
        isArchived?: boolean | null;
        isDeactivated?: boolean | null;
        source?: {
          __typename: 'MappingFlowConfigurationSourceNodeInfo';
          syncDetailsId?: string | null;
          nodeId?: string | null;
          connection?: {
            __typename: 'SyncConnection';
            id: string;
            name?: string | null;
            type?: string | null;
            configuration?: string | null;
            isConnectionUsed?: boolean | null;
            lastModified?: string | null;
            desktopConnectionId?: string | null;
          } | null;
          hub?: {
            __typename: 'ConnectorFieldItem';
            id?: string | null;
            name?: string | null;
          } | null;
          projects?: Array<{
            __typename: 'ConnectorProjectItem';
            id?: string | null;
            name?: string | null;
            folders?: Array<{
              __typename: 'ConnectorFieldFolder';
              id?: string | null;
              name?: string | null;
            } | null> | null;
          } | null> | null;
          additionalData?: string | null;
          settings?: {
            __typename: 'MappingConfigurationSettings';
            isSyncEnabled?: boolean | null;
            includeExtensions?: string | null;
            secondaryFilesSetting?: string | null;
            isSyncAllRevisions?: boolean | null;
            syncType?: string | null;
            scheduleSyncConfig?: string | null;
          } | null;
        } | null;
        destinations?: Array<{
          __typename: 'MappingFlowConfigurationDestinationNodeInfo';
          syncDetailsId?: string | null;
          nodeId?: string | null;
          connection?: {
            __typename: 'SyncConnection';
            id: string;
            name?: string | null;
            type?: string | null;
            configuration?: string | null;
            isConnectionUsed?: boolean | null;
            lastModified?: string | null;
            desktopConnectionId?: string | null;
          } | null;
          hub?: {
            __typename: 'ConnectorFieldItem';
            id?: string | null;
            name?: string | null;
          } | null;
          project?: {
            __typename: 'ConnectorProjectSingleFieldItem';
            id?: string | null;
            name?: string | null;
            folder?: {
              __typename: 'ConnectorFieldItem';
              id?: string | null;
              name?: string | null;
            } | null;
          } | null;
          additionalData?: string | null;
        } | null> | null;
        syncStatus?: number | null;
        lastSyncAt?: string | null;
        actionType?: string | null;
      } | null> | null;
      publishModelSolitaryFlows?: Array<{
        __typename: 'PublishModelSolitaryFlowItem';
        solitaryFlowId?: string | null;
        action?: string | null;
        actionNodeId?: string | null;
        actionAlias?: string | null;
        isArchived?: boolean | null;
        isDeactivated?: boolean | null;
        source?: {
          __typename: 'MappingFlowConfigurationSourceNodeInfo';
          syncDetailsId?: string | null;
          nodeId?: string | null;
          connection?: {
            __typename: 'SyncConnection';
            id: string;
            name?: string | null;
            type?: string | null;
            configuration?: string | null;
            isConnectionUsed?: boolean | null;
            lastModified?: string | null;
            desktopConnectionId?: string | null;
          } | null;
          hub?: {
            __typename: 'ConnectorFieldItem';
            id?: string | null;
            name?: string | null;
          } | null;
          projects?: Array<{
            __typename: 'ConnectorProjectItem';
            id?: string | null;
            name?: string | null;
            folders?: Array<{
              __typename: 'ConnectorFieldFolder';
              id?: string | null;
              name?: string | null;
            } | null> | null;
          } | null> | null;
          additionalData?: string | null;
          settings?: {
            __typename: 'MappingConfigurationSettings';
            isSyncEnabled?: boolean | null;
            includeExtensions?: string | null;
            secondaryFilesSetting?: string | null;
            isSyncAllRevisions?: boolean | null;
            syncType?: string | null;
            scheduleSyncConfig?: string | null;
          } | null;
        } | null;
        destinations?: Array<{
          __typename: 'MappingFlowConfigurationDestinationNodeInfo';
          syncDetailsId?: string | null;
          nodeId?: string | null;
          connection?: {
            __typename: 'SyncConnection';
            id: string;
            name?: string | null;
            type?: string | null;
            configuration?: string | null;
            isConnectionUsed?: boolean | null;
            lastModified?: string | null;
            desktopConnectionId?: string | null;
          } | null;
          hub?: {
            __typename: 'ConnectorFieldItem';
            id?: string | null;
            name?: string | null;
          } | null;
          project?: {
            __typename: 'ConnectorProjectSingleFieldItem';
            id?: string | null;
            name?: string | null;
            folder?: {
              __typename: 'ConnectorFieldItem';
              id?: string | null;
              name?: string | null;
            } | null;
          } | null;
          additionalData?: string | null;
        } | null> | null;
        syncStatus?: number | null;
        lastSyncAt?: string | null;
        actionType?: string | null;
      } | null> | null;
    } | null> | null;
    isArchived?: boolean | null;
    isDeactivated?: boolean | null;
  } | null;
};

export type GetProcoreCompaniesQueryVariables = {
  query?: QueryGetProcoreCompaniesQueryInput | null;
};

export type GetProcoreCompaniesQuery = {
  getProcoreCompanies?: Array<{
    __typename: 'ProcoreCompany';
    id: string;
    text?: string | null;
  } | null> | null;
};

export type GetProcoreProjectsQueryVariables = {
  query?: QueryGetProcoreProjectsQueryInput | null;
};

export type GetProcoreProjectsQuery = {
  getProcoreProjects?: Array<{
    __typename: 'ProcoreProject';
    id: string;
    text?: string | null;
  } | null> | null;
};

export type GetProcoreFoldersQueryVariables = {
  params: QueryGetProcoreFoldersParamsInput;
  query?: QueryGetProcoreFoldersQueryInput | null;
};

export type GetProcoreFoldersQuery = {
  getProcoreFolders?: Array<{
    __typename: 'ProcoreFolder';
    id: string;
    text?: string | null;
  } | null> | null;
};

export type GetProcoreSelectedFolderIdStackQueryVariables = {
  params: QueryGetProcoreSelectedFolderIdStackParamsInput;
  query?: QueryGetProcoreSelectedFolderIdStackQueryInput | null;
};

export type GetProcoreSelectedFolderIdStackQuery = {
  getProcoreSelectedFolderIdStack?: Array<string | null> | null;
};

export type GetProcoreFolderNameQueryVariables = {
  query?: QueryGetProcoreFolderNameQueryInput | null;
};

export type GetProcoreFolderNameQuery = {
  getProcoreFolderName?: {
    __typename: 'ProcoreFolderName';
    success?: boolean | null;
    name?: string | null;
  } | null;
};

export type GetAutodeskFieldProjectsQueryVariables = {
  query?: QueryGetAutodeskFieldProjectsQueryInput | null;
};

export type GetAutodeskFieldProjectsQuery = {
  getAutodeskFieldProjects?: Array<{
    __typename: 'AutodeskFieldProject';
    id: string;
    text?: string | null;
  } | null> | null;
};

export type GetAutodeskFieldFoldersQueryVariables = {
  query?: QueryGetAutodeskFieldFoldersQueryInput | null;
};

export type GetAutodeskFieldFoldersQuery = {
  getAutodeskFieldFolders?: Array<{
    __typename: 'AutodeskFieldFolder';
    id?: string | null;
    text?: string | null;
  } | null> | null;
};

export type GetAutodeskFieldFolderNameQueryVariables = {
  query?: QueryGetAutodeskFieldFolderNameQueryInput | null;
};

export type GetAutodeskFieldFolderNameQuery = {
  getAutodeskFieldFolderName?: {
    __typename: 'AutodeskFieldFolderName';
    success?: boolean | null;
    name?: string | null;
  } | null;
};

export type GetAutodeskForgeHubsQueryVariables = {
  query?: QueryGetAutodeskForgeHubsQueryInput | null;
};

export type GetAutodeskForgeHubsQuery = {
  getAutodeskForgeHubs?: Array<{
    __typename: 'AutodeskForgeHub';
    id: string;
    text?: string | null;
  } | null> | null;
};

export type GetAutodeskForgeProjectsQueryVariables = {
  query?: QueryGetAutodeskForgeProjectsQueryInput | null;
};

export type GetAutodeskForgeProjectsQuery = {
  getAutodeskForgeProjects?: Array<{
    __typename: 'AutodeskForgeProject';
    id: string;
    text?: string | null;
  } | null> | null;
};

export type GetAutodeskForgeSubItemsQueryVariables = {
  query?: QueryGetAutodeskForgeSubItemsQueryInput | null;
};

export type GetAutodeskForgeSubItemsQuery = {
  getAutodeskForgeSubItems?: Array<{
    __typename: 'AutodeskForgeSubItem';
    id: string;
    text?: string | null;
    isFolder?: boolean | null;
  } | null> | null;
};

export type GetAutodeskForgeItemNameQueryVariables = {
  query?: QueryGetAutodeskForgeItemNameQueryInput | null;
};

export type GetAutodeskForgeItemNameQuery = {
  getAutodeskForgeItemName?: {
    __typename: 'AutodeskForgeItem';
    success?: boolean | null;
    name?: string | null;
  } | null;
};

export type GetAutodeskForgeSelectedSubItemIdStackQueryVariables = {
  query?: QueryGetAutodeskForgeSelectedSubItemIdStackQueryInput | null;
};

export type GetAutodeskForgeSelectedSubItemIdStackQuery = {
  getAutodeskForgeSelectedSubItemIdStack?: Array<string | null> | null;
};

export type TaskByIdQueryVariables = {
  params: QueryTaskByIdParamsInput;
};

export type TaskByIdQuery = {
  taskById?: {
    __typename: 'Task';
    taskId?: string | null;
    taskTypeId?: string | null;
    billOfProcessId?: string | null;
    workRequestItemId?: string | null;
    taskStatusTypeId?: string | null;
    taskPredecessorIds?: Array<{
      __typename: 'TaskPredecessor';
      taskId?: string | null;
      taskName?: string | null;
      taskStatusTypeId?: string | null;
    } | null> | null;
    taskPredecessorDeletedIds?: Array<string | null> | null;
    taskTypeIds?: Array<string | null> | null;
    taskName?: string | null;
    taskDescription?: string | null;
    taskStatusTypeName?: string | null;
    sortOrder?: number | null;
    isEnabled?: boolean | null;
    createdOn?: string | null;
    time?: {
      __typename: 'Time';
      totalTime?: string | null;
      events?: Array<{
        __typename: 'Event';
        taskStatusTypeId?: string | null;
        description?: string | null;
        timesStamp?: string | null;
        userId?: string | null;
      } | null> | null;
    } | null;
    hasDocuments?: boolean | null;
    hasDrawingRegisterDocuments?: boolean | null;
    hasCommonDocuments?: boolean | null;
    hasExternalDocuments?: boolean | null;
    quantity?: number | null;
    needBy?: string | null;
  } | null;
};

export type TaskMultiSearchQueryVariables = {
  query?: QueryTaskMultiSearchQueryInput | null;
};

export type TaskMultiSearchQuery = {
  taskMultiSearch?: Array<{
    __typename: 'Task';
    taskId?: string | null;
    taskTypeId?: string | null;
    billOfProcessId?: string | null;
    workRequestItemId?: string | null;
    taskStatusTypeId?: string | null;
    taskPredecessorIds?: Array<{
      __typename: 'TaskPredecessor';
      taskId?: string | null;
      taskName?: string | null;
      taskStatusTypeId?: string | null;
    } | null> | null;
    taskPredecessorDeletedIds?: Array<string | null> | null;
    taskTypeIds?: Array<string | null> | null;
    taskName?: string | null;
    taskDescription?: string | null;
    taskStatusTypeName?: string | null;
    sortOrder?: number | null;
    isEnabled?: boolean | null;
    createdOn?: string | null;
    time?: {
      __typename: 'Time';
      totalTime?: string | null;
      events?: Array<{
        __typename: 'Event';
        taskStatusTypeId?: string | null;
        description?: string | null;
        timesStamp?: string | null;
        userId?: string | null;
      } | null> | null;
    } | null;
    hasDocuments?: boolean | null;
    hasDrawingRegisterDocuments?: boolean | null;
    hasCommonDocuments?: boolean | null;
    hasExternalDocuments?: boolean | null;
    quantity?: number | null;
    needBy?: string | null;
  } | null> | null;
};

export type TaskDocumentQueryVariables = {
  query?: QueryTaskDocumentQueryInput | null;
};

export type TaskDocumentQuery = {
  taskDocument?: Array<{
    __typename: 'TaskDocument';
    taskDocumentId?: string | null;
    taskId?: string | null;
    documentId?: string | null;
    externalUrl?: string | null;
    createdBy?: string | null;
    createdOn?: string | null;
    documentTypeId?: string | null;
  } | null> | null;
};

export type BillOfProcessByIdQueryVariables = {
  params: QueryBillOfProcessByIdParamsInput;
};

export type BillOfProcessByIdQuery = {
  billOfProcessById?: {
    __typename: 'BillOfProcess';
    billOfProcessId: string;
    billOfMaterialName?: string | null;
    workRequestItemId?: string | null;
    createdOn: string;
    createdBy: string;
    updatedOn?: string | null;
    updatedBy?: string | null;
    deletedOn?: string | null;
    deletedBy?: string | null;
  } | null;
};

export type WorkOrderByIdQueryVariables = {
  params: QueryWorkOrderByIdParamsInput;
};

export type WorkOrderByIdQuery = {
  workOrderById?: {
    __typename: 'WorkOrder';
    workOrderId?: string | null;
    workRequestId?: string | null;
    workOrderStatusTypeId?: string | null;
    workOrderStatusTypeName?: string | null;
    workOrderName?: string | null;
    workOrderDescription?: string | null;
    workOrderNumber?: string | null;
    workRequest?: {
      __typename: 'WorkRequest';
      workRequestId?: string | null;
      workRequestStatusId?: string | null;
      workRequestStatusName?: string | null;
      facilityId?: string | null;
      projectId?: string | null;
      projectIdentifier?: string | null;
      projectName?: string | null;
      workRequestIdentifier?: string | null;
      workRequestName?: string | null;
      needBy?: string | null;
      workRequestDescription?: string | null;
      facilityName?: string | null;
      workRequestStatusDescription?: string | null;
      lastStatusUpdate?: string | null;
      numberOfAttachments?: number | null;
    } | null;
    sortOrder?: number | null;
    createdBy?: string | null;
    lastStatusUpdate?: string | null;
    createdOn?: string | null;
    isStepFunctionProcessing?: boolean | null;
    stepFunctionProcessingMessage?: string | null;
    numberOfAttachments?: number | null;
    hasCustomWorkOrderName?: boolean | null;
  } | null;
};

export type SyncGetWorkflowMappingConfigurationQueryVariables = {
  params: QuerySyncGetWorkflowMappingConfigurationParamsInput;
};

export type SyncGetWorkflowMappingConfigurationQuery = {
  syncGetWorkflowMappingConfiguration?: {
    __typename: 'WorkflowMappingCanvasConfigData';
    workflowId?: string | null;
    workflowName?: string | null;
    mapping?: {
      __typename: 'WorkflowMapping';
      mappingId?: string | null;
      mappingName?: string | null;
      canvasNode?: string | null;
      isSyncRunning?: boolean | null;
      lastSyncAt?: string | null;
      lastSavedAt?: string | null;
      isArchived?: boolean | null;
      isDeactivated?: boolean | null;
      solitaryFlowConfiguration?: Array<{
        __typename: 'MappingFlowConfiguration';
        solitaryFlowId?: string | null;
        action?: string | null;
        actionNodeId?: string | null;
        actionAlias?: string | null;
        isDeactivated?: boolean | null;
        isArchived?: boolean | null;
        source?: {
          __typename: 'MappingFlowConfigurationSourceNodeInfo';
          syncDetailsId?: string | null;
          nodeId?: string | null;
          connection?: {
            __typename: 'SyncConnection';
            id: string;
            name?: string | null;
            type?: string | null;
            configuration?: string | null;
            isConnectionUsed?: boolean | null;
            lastModified?: string | null;
            desktopConnectionId?: string | null;
          } | null;
          hub?: {
            __typename: 'ConnectorFieldItem';
            id?: string | null;
            name?: string | null;
          } | null;
          projects?: Array<{
            __typename: 'ConnectorProjectItem';
            id?: string | null;
            name?: string | null;
            folders?: Array<{
              __typename: 'ConnectorFieldFolder';
              id?: string | null;
              name?: string | null;
            } | null> | null;
          } | null> | null;
          additionalData?: string | null;
          settings?: {
            __typename: 'MappingConfigurationSettings';
            isSyncEnabled?: boolean | null;
            includeExtensions?: string | null;
            secondaryFilesSetting?: string | null;
            isSyncAllRevisions?: boolean | null;
            syncType?: string | null;
            scheduleSyncConfig?: string | null;
          } | null;
        } | null;
        destinations?: Array<{
          __typename: 'MappingFlowConfigurationDestinationNodeInfo';
          syncDetailsId?: string | null;
          nodeId?: string | null;
          connection?: {
            __typename: 'SyncConnection';
            id: string;
            name?: string | null;
            type?: string | null;
            configuration?: string | null;
            isConnectionUsed?: boolean | null;
            lastModified?: string | null;
            desktopConnectionId?: string | null;
          } | null;
          hub?: {
            __typename: 'ConnectorFieldItem';
            id?: string | null;
            name?: string | null;
          } | null;
          project?: {
            __typename: 'ConnectorProjectSingleFieldItem';
            id?: string | null;
            name?: string | null;
            folder?: {
              __typename: 'ConnectorFieldItem';
              id?: string | null;
              name?: string | null;
            } | null;
          } | null;
          additionalData?: string | null;
        } | null> | null;
      } | null> | null;
      solitaryFlowPublishModelConfiguration?: Array<{
        __typename: 'MappingFlowPublishModelConfiguration';
        solitaryFlowId?: string | null;
        action?: string | null;
        actionNodeId?: string | null;
        actionAlias?: string | null;
        syncStatus?: number | null;
        lastSyncAt?: string | null;
        isDeactivated?: boolean | null;
        isArchived?: boolean | null;
        source?: {
          __typename: 'MappingFlowConfigurationSourceNodeInfo';
          syncDetailsId?: string | null;
          nodeId?: string | null;
          connection?: {
            __typename: 'SyncConnection';
            id: string;
            name?: string | null;
            type?: string | null;
            configuration?: string | null;
            isConnectionUsed?: boolean | null;
            lastModified?: string | null;
            desktopConnectionId?: string | null;
          } | null;
          hub?: {
            __typename: 'ConnectorFieldItem';
            id?: string | null;
            name?: string | null;
          } | null;
          projects?: Array<{
            __typename: 'ConnectorProjectItem';
            id?: string | null;
            name?: string | null;
            folders?: Array<{
              __typename: 'ConnectorFieldFolder';
              id?: string | null;
              name?: string | null;
            } | null> | null;
          } | null> | null;
          additionalData?: string | null;
          settings?: {
            __typename: 'MappingConfigurationSettings';
            isSyncEnabled?: boolean | null;
            includeExtensions?: string | null;
            secondaryFilesSetting?: string | null;
            isSyncAllRevisions?: boolean | null;
            syncType?: string | null;
            scheduleSyncConfig?: string | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type SyncGetWorkflowMappingTemplatesListQueryVariables = {
  query?: QuerySyncGetWorkflowMappingTemplatesListQueryInput | null;
};

export type SyncGetWorkflowMappingTemplatesListQuery = {
  syncGetWorkflowMappingTemplatesList?: Array<{
    __typename: 'WorkflowMappingTemplate';
    templateId?: string | null;
    templateName?: string | null;
  } | null> | null;
};

export type DrawingTypeCategoryByIdQueryVariables = {
  params: QueryDrawingTypeCategoryByIdParamsInput;
};

export type DrawingTypeCategoryByIdQuery = {
  drawingTypeCategoryById?: {
    __typename: 'DrawingTypeCategory';
    companyId?: string | null;
    userDefinedId?: string | null;
    name?: string | null;
    hasDrawingTypes?: boolean | null;
    id?: string | null;
    createdBy?: string | null;
    createdOnUtc?: string | null;
    modifiedBy?: string | null;
    modifiedOnUtc?: string | null;
  } | null;
};

export type SyncGetConnectionByIdQueryVariables = {
  params: QuerySyncGetConnectionByIdParamsInput;
};

export type SyncGetConnectionByIdQuery = {
  syncGetConnectionById?: {
    __typename: 'SyncConnection';
    id: string;
    name?: string | null;
    type?: string | null;
    configuration?: string | null;
    isConnectionUsed?: boolean | null;
    lastModified?: string | null;
    desktopConnectionId?: string | null;
  } | null;
};

export type AssembliesQueryVariables = {
  query?: QueryAssembliesQueryInput | null;
};

export type AssembliesQuery = {
  assemblies?: Array<{
    __typename: 'Assembly';
    part?: {
      __typename: 'Part';
      partId: string;
      partName: string;
      description?: string | null;
      stateId?: string | null;
      isDefault?: boolean | null;
      manufacturerCatalogNumber?: string | null;
      unitOfMeasureCode?: string | null;
      unitOfMeasureName?: string | null;
      upcCode?: string | null;
      url?: string | null;
      catalogUrl?: string | null;
      partUrl?: string | null;
      internalPartNumber?: string | null;
      createdOn?: string | null;
      hasAssembly?: boolean | null;
      manufacturer?: {
        __typename: 'Manufactured';
        manufacturerId: string;
        companyId?: string | null;
        manufacturerName: string;
        homePageUrl?: string | null;
      } | null;
      attriubuteVariantData?: Array<{
        __typename: 'PartAttributeVariantData';
        partAttributeVariantDataId?: string | null;
        partAttributeId?: string | null;
        partAttributeName?: string | null;
        rangeValue?: number | null;
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
        partAttribute?: {
          __typename: 'PartAttribute';
          partAttributeId: string;
          partAttributeName: string;
          allowWriteInVariant?: boolean | null;
          rangeMinimumValue?: number | null;
          rangeMaximumValue?: number | null;
          selectVariants?: Array<{
            __typename: 'PartAttributeSelectVariant';
            partAttributeSelectVariantId?: string | null;
            textValue?: string | null;
            codeValue?: string | null;
            isDeleted?: boolean | null;
          } | null> | null;
          partAttributeType?: {
            __typename: 'PartAttributeType';
            partAttributeTypeId: string;
            partAttributeTypeName: string;
            partAttributeTypeDescription?: string | null;
            sortOrder?: number | null;
          } | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
        } | null;
        selectValues?: Array<{
          __typename: 'PartAttributeSelectVariant';
          partAttributeSelectVariantId?: string | null;
          textValue?: string | null;
          codeValue?: string | null;
          isDeleted?: boolean | null;
        } | null> | null;
      } | null> | null;
      partCategory?: {
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      partImages?: Array<{
        __typename: 'PartImage';
        partImageId?: string | null;
        sortOrder?: number | null;
        isDefault?: boolean | null;
        document?: {
          __typename: 'Document';
          documentId: string;
          documentName?: string | null;
          documentIdentifier?: string | null;
          documentTypeId?: string | null;
          storagePath?: string | null;
          extension?: string | null;
          encodedUrn?: string | null;
          documentStatusTypeId?: string | null;
          isMappedFromShop?: boolean | null;
          folderDocumentResponse?: Array<{
            __typename: 'FolderDocument';
            folderDocumentId: string;
            folderId: string;
            folderName?: string | null;
            documentId: string;
          } | null> | null;
          documentNameFormatted?: string | null;
          createdOn?: string | null;
          createdBy?: string | null;
        } | null;
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
      } | null> | null;
      documentIds?: Array<string | null> | null;
      unitOfMeasure?: {
        __typename: 'UnitOfMeasure';
        unitOfMeasureId: string;
        unitOfMeasureTypeId?: string | null;
        companyId?: string | null;
        unitOfMeasureName: string;
        unitOfMeasureCode: string;
        createdOn?: string | null;
        createdBy?: string | null;
        updatedOn?: string | null;
        modifiedBy?: string | null;
        modifiedOn?: string | null;
      } | null;
    } | null;
    assemblyId?: string | null;
    historyTypeId?: string | null;
    assemblyNodes?: Array<{
      __typename: 'AssemblyNode';
      quantity?: number | null;
      part?: {
        __typename: 'Part';
        partId: string;
        partName: string;
        description?: string | null;
        stateId?: string | null;
        isDefault?: boolean | null;
        manufacturerCatalogNumber?: string | null;
        unitOfMeasureCode?: string | null;
        unitOfMeasureName?: string | null;
        upcCode?: string | null;
        url?: string | null;
        catalogUrl?: string | null;
        partUrl?: string | null;
        internalPartNumber?: string | null;
        createdOn?: string | null;
        hasAssembly?: boolean | null;
        manufacturer?: {
          __typename: 'Manufactured';
          manufacturerId: string;
          companyId?: string | null;
          manufacturerName: string;
          homePageUrl?: string | null;
        } | null;
        attriubuteVariantData?: Array<{
          __typename: 'PartAttributeVariantData';
          partAttributeVariantDataId?: string | null;
          partAttributeId?: string | null;
          partAttributeName?: string | null;
          rangeValue?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          partAttribute?: {
            __typename: 'PartAttribute';
            partAttributeId: string;
            partAttributeName: string;
            allowWriteInVariant?: boolean | null;
            rangeMinimumValue?: number | null;
            rangeMaximumValue?: number | null;
            selectVariants?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
            partAttributeType?: {
              __typename: 'PartAttributeType';
              partAttributeTypeId: string;
              partAttributeTypeName: string;
              partAttributeTypeDescription?: string | null;
              sortOrder?: number | null;
            } | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
          } | null;
          selectValues?: Array<{
            __typename: 'PartAttributeSelectVariant';
            partAttributeSelectVariantId?: string | null;
            textValue?: string | null;
            codeValue?: string | null;
            isDeleted?: boolean | null;
          } | null> | null;
        } | null> | null;
        partCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        partImages?: Array<{
          __typename: 'PartImage';
          partImageId?: string | null;
          sortOrder?: number | null;
          isDefault?: boolean | null;
          document?: {
            __typename: 'Document';
            documentId: string;
            documentName?: string | null;
            documentIdentifier?: string | null;
            documentTypeId?: string | null;
            storagePath?: string | null;
            extension?: string | null;
            encodedUrn?: string | null;
            documentStatusTypeId?: string | null;
            isMappedFromShop?: boolean | null;
            folderDocumentResponse?: Array<{
              __typename: 'FolderDocument';
              folderDocumentId: string;
              folderId: string;
              folderName?: string | null;
              documentId: string;
            } | null> | null;
            documentNameFormatted?: string | null;
            createdOn?: string | null;
            createdBy?: string | null;
          } | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
        } | null> | null;
        documentIds?: Array<string | null> | null;
        unitOfMeasure?: {
          __typename: 'UnitOfMeasure';
          unitOfMeasureId: string;
          unitOfMeasureTypeId?: string | null;
          companyId?: string | null;
          unitOfMeasureName: string;
          unitOfMeasureCode: string;
          createdOn?: string | null;
          createdBy?: string | null;
          updatedOn?: string | null;
          modifiedBy?: string | null;
          modifiedOn?: string | null;
        } | null;
      } | null;
      shopTaskId?: string | null;
      assemblyNodeId?: string | null;
      historyTypeId?: string | null;
      assembly?: {
        __typename: 'Assembly';
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
        assemblyId?: string | null;
        historyTypeId?: string | null;
        assemblyNodes?: Array<{
          __typename: 'AssemblyNode';
          quantity?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          shopTaskId?: string | null;
          assemblyNodeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyNodeType?: {
            __typename: 'AssemblyNodeType';
            assemblyNodeTypeId?: string | null;
            assemblyNodeTypeName?: string | null;
          } | null;
          assemblyNodeName?: string | null;
          assemblyNodeDescription?: string | null;
          positionX?: number | null;
          positionY?: number | null;
        } | null> | null;
        assemblyEdges?: Array<{
          __typename: 'AssemblyEdge';
          assemblyEdgeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyEdgeType?: {
            __typename: 'AssemblyEdgeType';
            assemblyEdgeTypeId?: string | null;
            assemblyEdgeTypeName?: string | null;
          } | null;
          beginHandlePosition?: {
            __typename: 'AssemblyHandlePosition';
            assemblyHandlePositionId?: string | null;
            assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
          } | null;
          endHandlePosition?: {
            __typename: 'AssemblyHandlePosition';
            assemblyHandlePositionId?: string | null;
            assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
          } | null;
          beginNode?: {
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null;
          endNode?: {
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null;
        } | null> | null;
      } | null;
      assemblyNodeType?: {
        __typename: 'AssemblyNodeType';
        assemblyNodeTypeId?: string | null;
        assemblyNodeTypeName?: string | null;
      } | null;
      assemblyNodeName?: string | null;
      assemblyNodeDescription?: string | null;
      positionX?: number | null;
      positionY?: number | null;
    } | null> | null;
    assemblyEdges?: Array<{
      __typename: 'AssemblyEdge';
      assemblyEdgeId?: string | null;
      historyTypeId?: string | null;
      assembly?: {
        __typename: 'Assembly';
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
        assemblyId?: string | null;
        historyTypeId?: string | null;
        assemblyNodes?: Array<{
          __typename: 'AssemblyNode';
          quantity?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          shopTaskId?: string | null;
          assemblyNodeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyNodeType?: {
            __typename: 'AssemblyNodeType';
            assemblyNodeTypeId?: string | null;
            assemblyNodeTypeName?: string | null;
          } | null;
          assemblyNodeName?: string | null;
          assemblyNodeDescription?: string | null;
          positionX?: number | null;
          positionY?: number | null;
        } | null> | null;
        assemblyEdges?: Array<{
          __typename: 'AssemblyEdge';
          assemblyEdgeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyEdgeType?: {
            __typename: 'AssemblyEdgeType';
            assemblyEdgeTypeId?: string | null;
            assemblyEdgeTypeName?: string | null;
          } | null;
          beginHandlePosition?: {
            __typename: 'AssemblyHandlePosition';
            assemblyHandlePositionId?: string | null;
            assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
          } | null;
          endHandlePosition?: {
            __typename: 'AssemblyHandlePosition';
            assemblyHandlePositionId?: string | null;
            assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
          } | null;
          beginNode?: {
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null;
          endNode?: {
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null;
        } | null> | null;
      } | null;
      assemblyEdgeType?: {
        __typename: 'AssemblyEdgeType';
        assemblyEdgeTypeId?: string | null;
        assemblyEdgeTypeName?: string | null;
      } | null;
      beginHandlePosition?: {
        __typename: 'AssemblyHandlePosition';
        assemblyHandlePositionId?: string | null;
        assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
      } | null;
      endHandlePosition?: {
        __typename: 'AssemblyHandlePosition';
        assemblyHandlePositionId?: string | null;
        assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
      } | null;
      beginNode?: {
        __typename: 'AssemblyNode';
        quantity?: number | null;
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
        shopTaskId?: string | null;
        assemblyNodeId?: string | null;
        historyTypeId?: string | null;
        assembly?: {
          __typename: 'Assembly';
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          assemblyId?: string | null;
          historyTypeId?: string | null;
          assemblyNodes?: Array<{
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null> | null;
          assemblyEdges?: Array<{
            __typename: 'AssemblyEdge';
            assemblyEdgeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyEdgeType?: {
              __typename: 'AssemblyEdgeType';
              assemblyEdgeTypeId?: string | null;
              assemblyEdgeTypeName?: string | null;
            } | null;
            beginHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            endHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            beginNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
            endNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
          } | null> | null;
        } | null;
        assemblyNodeType?: {
          __typename: 'AssemblyNodeType';
          assemblyNodeTypeId?: string | null;
          assemblyNodeTypeName?: string | null;
        } | null;
        assemblyNodeName?: string | null;
        assemblyNodeDescription?: string | null;
        positionX?: number | null;
        positionY?: number | null;
      } | null;
      endNode?: {
        __typename: 'AssemblyNode';
        quantity?: number | null;
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
        shopTaskId?: string | null;
        assemblyNodeId?: string | null;
        historyTypeId?: string | null;
        assembly?: {
          __typename: 'Assembly';
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          assemblyId?: string | null;
          historyTypeId?: string | null;
          assemblyNodes?: Array<{
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null> | null;
          assemblyEdges?: Array<{
            __typename: 'AssemblyEdge';
            assemblyEdgeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyEdgeType?: {
              __typename: 'AssemblyEdgeType';
              assemblyEdgeTypeId?: string | null;
              assemblyEdgeTypeName?: string | null;
            } | null;
            beginHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            endHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            beginNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
            endNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
          } | null> | null;
        } | null;
        assemblyNodeType?: {
          __typename: 'AssemblyNodeType';
          assemblyNodeTypeId?: string | null;
          assemblyNodeTypeName?: string | null;
        } | null;
        assemblyNodeName?: string | null;
        assemblyNodeDescription?: string | null;
        positionX?: number | null;
        positionY?: number | null;
      } | null;
    } | null> | null;
  } | null> | null;
};

export type AssemblyByIdQueryVariables = {
  params: QueryAssemblyByIdParamsInput;
};

export type AssemblyByIdQuery = {
  assemblyById?: {
    __typename: 'Assembly';
    part?: {
      __typename: 'Part';
      partId: string;
      partName: string;
      description?: string | null;
      stateId?: string | null;
      isDefault?: boolean | null;
      manufacturerCatalogNumber?: string | null;
      unitOfMeasureCode?: string | null;
      unitOfMeasureName?: string | null;
      upcCode?: string | null;
      url?: string | null;
      catalogUrl?: string | null;
      partUrl?: string | null;
      internalPartNumber?: string | null;
      createdOn?: string | null;
      hasAssembly?: boolean | null;
      manufacturer?: {
        __typename: 'Manufactured';
        manufacturerId: string;
        companyId?: string | null;
        manufacturerName: string;
        homePageUrl?: string | null;
      } | null;
      attriubuteVariantData?: Array<{
        __typename: 'PartAttributeVariantData';
        partAttributeVariantDataId?: string | null;
        partAttributeId?: string | null;
        partAttributeName?: string | null;
        rangeValue?: number | null;
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
        partAttribute?: {
          __typename: 'PartAttribute';
          partAttributeId: string;
          partAttributeName: string;
          allowWriteInVariant?: boolean | null;
          rangeMinimumValue?: number | null;
          rangeMaximumValue?: number | null;
          selectVariants?: Array<{
            __typename: 'PartAttributeSelectVariant';
            partAttributeSelectVariantId?: string | null;
            textValue?: string | null;
            codeValue?: string | null;
            isDeleted?: boolean | null;
          } | null> | null;
          partAttributeType?: {
            __typename: 'PartAttributeType';
            partAttributeTypeId: string;
            partAttributeTypeName: string;
            partAttributeTypeDescription?: string | null;
            sortOrder?: number | null;
          } | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
        } | null;
        selectValues?: Array<{
          __typename: 'PartAttributeSelectVariant';
          partAttributeSelectVariantId?: string | null;
          textValue?: string | null;
          codeValue?: string | null;
          isDeleted?: boolean | null;
        } | null> | null;
      } | null> | null;
      partCategory?: {
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      partImages?: Array<{
        __typename: 'PartImage';
        partImageId?: string | null;
        sortOrder?: number | null;
        isDefault?: boolean | null;
        document?: {
          __typename: 'Document';
          documentId: string;
          documentName?: string | null;
          documentIdentifier?: string | null;
          documentTypeId?: string | null;
          storagePath?: string | null;
          extension?: string | null;
          encodedUrn?: string | null;
          documentStatusTypeId?: string | null;
          isMappedFromShop?: boolean | null;
          folderDocumentResponse?: Array<{
            __typename: 'FolderDocument';
            folderDocumentId: string;
            folderId: string;
            folderName?: string | null;
            documentId: string;
          } | null> | null;
          documentNameFormatted?: string | null;
          createdOn?: string | null;
          createdBy?: string | null;
        } | null;
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
      } | null> | null;
      documentIds?: Array<string | null> | null;
      unitOfMeasure?: {
        __typename: 'UnitOfMeasure';
        unitOfMeasureId: string;
        unitOfMeasureTypeId?: string | null;
        companyId?: string | null;
        unitOfMeasureName: string;
        unitOfMeasureCode: string;
        createdOn?: string | null;
        createdBy?: string | null;
        updatedOn?: string | null;
        modifiedBy?: string | null;
        modifiedOn?: string | null;
      } | null;
    } | null;
    assemblyId?: string | null;
    historyTypeId?: string | null;
    assemblyNodes?: Array<{
      __typename: 'AssemblyNode';
      quantity?: number | null;
      part?: {
        __typename: 'Part';
        partId: string;
        partName: string;
        description?: string | null;
        stateId?: string | null;
        isDefault?: boolean | null;
        manufacturerCatalogNumber?: string | null;
        unitOfMeasureCode?: string | null;
        unitOfMeasureName?: string | null;
        upcCode?: string | null;
        url?: string | null;
        catalogUrl?: string | null;
        partUrl?: string | null;
        internalPartNumber?: string | null;
        createdOn?: string | null;
        hasAssembly?: boolean | null;
        manufacturer?: {
          __typename: 'Manufactured';
          manufacturerId: string;
          companyId?: string | null;
          manufacturerName: string;
          homePageUrl?: string | null;
        } | null;
        attriubuteVariantData?: Array<{
          __typename: 'PartAttributeVariantData';
          partAttributeVariantDataId?: string | null;
          partAttributeId?: string | null;
          partAttributeName?: string | null;
          rangeValue?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          partAttribute?: {
            __typename: 'PartAttribute';
            partAttributeId: string;
            partAttributeName: string;
            allowWriteInVariant?: boolean | null;
            rangeMinimumValue?: number | null;
            rangeMaximumValue?: number | null;
            selectVariants?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
            partAttributeType?: {
              __typename: 'PartAttributeType';
              partAttributeTypeId: string;
              partAttributeTypeName: string;
              partAttributeTypeDescription?: string | null;
              sortOrder?: number | null;
            } | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
          } | null;
          selectValues?: Array<{
            __typename: 'PartAttributeSelectVariant';
            partAttributeSelectVariantId?: string | null;
            textValue?: string | null;
            codeValue?: string | null;
            isDeleted?: boolean | null;
          } | null> | null;
        } | null> | null;
        partCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        partImages?: Array<{
          __typename: 'PartImage';
          partImageId?: string | null;
          sortOrder?: number | null;
          isDefault?: boolean | null;
          document?: {
            __typename: 'Document';
            documentId: string;
            documentName?: string | null;
            documentIdentifier?: string | null;
            documentTypeId?: string | null;
            storagePath?: string | null;
            extension?: string | null;
            encodedUrn?: string | null;
            documentStatusTypeId?: string | null;
            isMappedFromShop?: boolean | null;
            folderDocumentResponse?: Array<{
              __typename: 'FolderDocument';
              folderDocumentId: string;
              folderId: string;
              folderName?: string | null;
              documentId: string;
            } | null> | null;
            documentNameFormatted?: string | null;
            createdOn?: string | null;
            createdBy?: string | null;
          } | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
        } | null> | null;
        documentIds?: Array<string | null> | null;
        unitOfMeasure?: {
          __typename: 'UnitOfMeasure';
          unitOfMeasureId: string;
          unitOfMeasureTypeId?: string | null;
          companyId?: string | null;
          unitOfMeasureName: string;
          unitOfMeasureCode: string;
          createdOn?: string | null;
          createdBy?: string | null;
          updatedOn?: string | null;
          modifiedBy?: string | null;
          modifiedOn?: string | null;
        } | null;
      } | null;
      shopTaskId?: string | null;
      assemblyNodeId?: string | null;
      historyTypeId?: string | null;
      assembly?: {
        __typename: 'Assembly';
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
        assemblyId?: string | null;
        historyTypeId?: string | null;
        assemblyNodes?: Array<{
          __typename: 'AssemblyNode';
          quantity?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          shopTaskId?: string | null;
          assemblyNodeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyNodeType?: {
            __typename: 'AssemblyNodeType';
            assemblyNodeTypeId?: string | null;
            assemblyNodeTypeName?: string | null;
          } | null;
          assemblyNodeName?: string | null;
          assemblyNodeDescription?: string | null;
          positionX?: number | null;
          positionY?: number | null;
        } | null> | null;
        assemblyEdges?: Array<{
          __typename: 'AssemblyEdge';
          assemblyEdgeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyEdgeType?: {
            __typename: 'AssemblyEdgeType';
            assemblyEdgeTypeId?: string | null;
            assemblyEdgeTypeName?: string | null;
          } | null;
          beginHandlePosition?: {
            __typename: 'AssemblyHandlePosition';
            assemblyHandlePositionId?: string | null;
            assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
          } | null;
          endHandlePosition?: {
            __typename: 'AssemblyHandlePosition';
            assemblyHandlePositionId?: string | null;
            assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
          } | null;
          beginNode?: {
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null;
          endNode?: {
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null;
        } | null> | null;
      } | null;
      assemblyNodeType?: {
        __typename: 'AssemblyNodeType';
        assemblyNodeTypeId?: string | null;
        assemblyNodeTypeName?: string | null;
      } | null;
      assemblyNodeName?: string | null;
      assemblyNodeDescription?: string | null;
      positionX?: number | null;
      positionY?: number | null;
    } | null> | null;
    assemblyEdges?: Array<{
      __typename: 'AssemblyEdge';
      assemblyEdgeId?: string | null;
      historyTypeId?: string | null;
      assembly?: {
        __typename: 'Assembly';
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
        assemblyId?: string | null;
        historyTypeId?: string | null;
        assemblyNodes?: Array<{
          __typename: 'AssemblyNode';
          quantity?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          shopTaskId?: string | null;
          assemblyNodeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyNodeType?: {
            __typename: 'AssemblyNodeType';
            assemblyNodeTypeId?: string | null;
            assemblyNodeTypeName?: string | null;
          } | null;
          assemblyNodeName?: string | null;
          assemblyNodeDescription?: string | null;
          positionX?: number | null;
          positionY?: number | null;
        } | null> | null;
        assemblyEdges?: Array<{
          __typename: 'AssemblyEdge';
          assemblyEdgeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyEdgeType?: {
            __typename: 'AssemblyEdgeType';
            assemblyEdgeTypeId?: string | null;
            assemblyEdgeTypeName?: string | null;
          } | null;
          beginHandlePosition?: {
            __typename: 'AssemblyHandlePosition';
            assemblyHandlePositionId?: string | null;
            assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
          } | null;
          endHandlePosition?: {
            __typename: 'AssemblyHandlePosition';
            assemblyHandlePositionId?: string | null;
            assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
          } | null;
          beginNode?: {
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null;
          endNode?: {
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null;
        } | null> | null;
      } | null;
      assemblyEdgeType?: {
        __typename: 'AssemblyEdgeType';
        assemblyEdgeTypeId?: string | null;
        assemblyEdgeTypeName?: string | null;
      } | null;
      beginHandlePosition?: {
        __typename: 'AssemblyHandlePosition';
        assemblyHandlePositionId?: string | null;
        assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
      } | null;
      endHandlePosition?: {
        __typename: 'AssemblyHandlePosition';
        assemblyHandlePositionId?: string | null;
        assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
      } | null;
      beginNode?: {
        __typename: 'AssemblyNode';
        quantity?: number | null;
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
        shopTaskId?: string | null;
        assemblyNodeId?: string | null;
        historyTypeId?: string | null;
        assembly?: {
          __typename: 'Assembly';
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          assemblyId?: string | null;
          historyTypeId?: string | null;
          assemblyNodes?: Array<{
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null> | null;
          assemblyEdges?: Array<{
            __typename: 'AssemblyEdge';
            assemblyEdgeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyEdgeType?: {
              __typename: 'AssemblyEdgeType';
              assemblyEdgeTypeId?: string | null;
              assemblyEdgeTypeName?: string | null;
            } | null;
            beginHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            endHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            beginNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
            endNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
          } | null> | null;
        } | null;
        assemblyNodeType?: {
          __typename: 'AssemblyNodeType';
          assemblyNodeTypeId?: string | null;
          assemblyNodeTypeName?: string | null;
        } | null;
        assemblyNodeName?: string | null;
        assemblyNodeDescription?: string | null;
        positionX?: number | null;
        positionY?: number | null;
      } | null;
      endNode?: {
        __typename: 'AssemblyNode';
        quantity?: number | null;
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
        shopTaskId?: string | null;
        assemblyNodeId?: string | null;
        historyTypeId?: string | null;
        assembly?: {
          __typename: 'Assembly';
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          assemblyId?: string | null;
          historyTypeId?: string | null;
          assemblyNodes?: Array<{
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null> | null;
          assemblyEdges?: Array<{
            __typename: 'AssemblyEdge';
            assemblyEdgeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyEdgeType?: {
              __typename: 'AssemblyEdgeType';
              assemblyEdgeTypeId?: string | null;
              assemblyEdgeTypeName?: string | null;
            } | null;
            beginHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            endHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            beginNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
            endNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
          } | null> | null;
        } | null;
        assemblyNodeType?: {
          __typename: 'AssemblyNodeType';
          assemblyNodeTypeId?: string | null;
          assemblyNodeTypeName?: string | null;
        } | null;
        assemblyNodeName?: string | null;
        assemblyNodeDescription?: string | null;
        positionX?: number | null;
        positionY?: number | null;
      } | null;
    } | null> | null;
  } | null;
};

export type AssemblyNodesQueryVariables = {
  query?: QueryAssemblyNodesQueryInput | null;
};

export type AssemblyNodesQuery = {
  assemblyNodes?: Array<{
    __typename: 'AssemblyNode';
    quantity?: number | null;
    part?: {
      __typename: 'Part';
      partId: string;
      partName: string;
      description?: string | null;
      stateId?: string | null;
      isDefault?: boolean | null;
      manufacturerCatalogNumber?: string | null;
      unitOfMeasureCode?: string | null;
      unitOfMeasureName?: string | null;
      upcCode?: string | null;
      url?: string | null;
      catalogUrl?: string | null;
      partUrl?: string | null;
      internalPartNumber?: string | null;
      createdOn?: string | null;
      hasAssembly?: boolean | null;
      manufacturer?: {
        __typename: 'Manufactured';
        manufacturerId: string;
        companyId?: string | null;
        manufacturerName: string;
        homePageUrl?: string | null;
      } | null;
      attriubuteVariantData?: Array<{
        __typename: 'PartAttributeVariantData';
        partAttributeVariantDataId?: string | null;
        partAttributeId?: string | null;
        partAttributeName?: string | null;
        rangeValue?: number | null;
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
        partAttribute?: {
          __typename: 'PartAttribute';
          partAttributeId: string;
          partAttributeName: string;
          allowWriteInVariant?: boolean | null;
          rangeMinimumValue?: number | null;
          rangeMaximumValue?: number | null;
          selectVariants?: Array<{
            __typename: 'PartAttributeSelectVariant';
            partAttributeSelectVariantId?: string | null;
            textValue?: string | null;
            codeValue?: string | null;
            isDeleted?: boolean | null;
          } | null> | null;
          partAttributeType?: {
            __typename: 'PartAttributeType';
            partAttributeTypeId: string;
            partAttributeTypeName: string;
            partAttributeTypeDescription?: string | null;
            sortOrder?: number | null;
          } | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
        } | null;
        selectValues?: Array<{
          __typename: 'PartAttributeSelectVariant';
          partAttributeSelectVariantId?: string | null;
          textValue?: string | null;
          codeValue?: string | null;
          isDeleted?: boolean | null;
        } | null> | null;
      } | null> | null;
      partCategory?: {
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      partImages?: Array<{
        __typename: 'PartImage';
        partImageId?: string | null;
        sortOrder?: number | null;
        isDefault?: boolean | null;
        document?: {
          __typename: 'Document';
          documentId: string;
          documentName?: string | null;
          documentIdentifier?: string | null;
          documentTypeId?: string | null;
          storagePath?: string | null;
          extension?: string | null;
          encodedUrn?: string | null;
          documentStatusTypeId?: string | null;
          isMappedFromShop?: boolean | null;
          folderDocumentResponse?: Array<{
            __typename: 'FolderDocument';
            folderDocumentId: string;
            folderId: string;
            folderName?: string | null;
            documentId: string;
          } | null> | null;
          documentNameFormatted?: string | null;
          createdOn?: string | null;
          createdBy?: string | null;
        } | null;
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
      } | null> | null;
      documentIds?: Array<string | null> | null;
      unitOfMeasure?: {
        __typename: 'UnitOfMeasure';
        unitOfMeasureId: string;
        unitOfMeasureTypeId?: string | null;
        companyId?: string | null;
        unitOfMeasureName: string;
        unitOfMeasureCode: string;
        createdOn?: string | null;
        createdBy?: string | null;
        updatedOn?: string | null;
        modifiedBy?: string | null;
        modifiedOn?: string | null;
      } | null;
    } | null;
    shopTaskId?: string | null;
    assemblyNodeId?: string | null;
    historyTypeId?: string | null;
    assembly?: {
      __typename: 'Assembly';
      part?: {
        __typename: 'Part';
        partId: string;
        partName: string;
        description?: string | null;
        stateId?: string | null;
        isDefault?: boolean | null;
        manufacturerCatalogNumber?: string | null;
        unitOfMeasureCode?: string | null;
        unitOfMeasureName?: string | null;
        upcCode?: string | null;
        url?: string | null;
        catalogUrl?: string | null;
        partUrl?: string | null;
        internalPartNumber?: string | null;
        createdOn?: string | null;
        hasAssembly?: boolean | null;
        manufacturer?: {
          __typename: 'Manufactured';
          manufacturerId: string;
          companyId?: string | null;
          manufacturerName: string;
          homePageUrl?: string | null;
        } | null;
        attriubuteVariantData?: Array<{
          __typename: 'PartAttributeVariantData';
          partAttributeVariantDataId?: string | null;
          partAttributeId?: string | null;
          partAttributeName?: string | null;
          rangeValue?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          partAttribute?: {
            __typename: 'PartAttribute';
            partAttributeId: string;
            partAttributeName: string;
            allowWriteInVariant?: boolean | null;
            rangeMinimumValue?: number | null;
            rangeMaximumValue?: number | null;
            selectVariants?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
            partAttributeType?: {
              __typename: 'PartAttributeType';
              partAttributeTypeId: string;
              partAttributeTypeName: string;
              partAttributeTypeDescription?: string | null;
              sortOrder?: number | null;
            } | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
          } | null;
          selectValues?: Array<{
            __typename: 'PartAttributeSelectVariant';
            partAttributeSelectVariantId?: string | null;
            textValue?: string | null;
            codeValue?: string | null;
            isDeleted?: boolean | null;
          } | null> | null;
        } | null> | null;
        partCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        partImages?: Array<{
          __typename: 'PartImage';
          partImageId?: string | null;
          sortOrder?: number | null;
          isDefault?: boolean | null;
          document?: {
            __typename: 'Document';
            documentId: string;
            documentName?: string | null;
            documentIdentifier?: string | null;
            documentTypeId?: string | null;
            storagePath?: string | null;
            extension?: string | null;
            encodedUrn?: string | null;
            documentStatusTypeId?: string | null;
            isMappedFromShop?: boolean | null;
            folderDocumentResponse?: Array<{
              __typename: 'FolderDocument';
              folderDocumentId: string;
              folderId: string;
              folderName?: string | null;
              documentId: string;
            } | null> | null;
            documentNameFormatted?: string | null;
            createdOn?: string | null;
            createdBy?: string | null;
          } | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
        } | null> | null;
        documentIds?: Array<string | null> | null;
        unitOfMeasure?: {
          __typename: 'UnitOfMeasure';
          unitOfMeasureId: string;
          unitOfMeasureTypeId?: string | null;
          companyId?: string | null;
          unitOfMeasureName: string;
          unitOfMeasureCode: string;
          createdOn?: string | null;
          createdBy?: string | null;
          updatedOn?: string | null;
          modifiedBy?: string | null;
          modifiedOn?: string | null;
        } | null;
      } | null;
      assemblyId?: string | null;
      historyTypeId?: string | null;
      assemblyNodes?: Array<{
        __typename: 'AssemblyNode';
        quantity?: number | null;
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
        shopTaskId?: string | null;
        assemblyNodeId?: string | null;
        historyTypeId?: string | null;
        assembly?: {
          __typename: 'Assembly';
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          assemblyId?: string | null;
          historyTypeId?: string | null;
          assemblyNodes?: Array<{
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null> | null;
          assemblyEdges?: Array<{
            __typename: 'AssemblyEdge';
            assemblyEdgeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyEdgeType?: {
              __typename: 'AssemblyEdgeType';
              assemblyEdgeTypeId?: string | null;
              assemblyEdgeTypeName?: string | null;
            } | null;
            beginHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            endHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            beginNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
            endNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
          } | null> | null;
        } | null;
        assemblyNodeType?: {
          __typename: 'AssemblyNodeType';
          assemblyNodeTypeId?: string | null;
          assemblyNodeTypeName?: string | null;
        } | null;
        assemblyNodeName?: string | null;
        assemblyNodeDescription?: string | null;
        positionX?: number | null;
        positionY?: number | null;
      } | null> | null;
      assemblyEdges?: Array<{
        __typename: 'AssemblyEdge';
        assemblyEdgeId?: string | null;
        historyTypeId?: string | null;
        assembly?: {
          __typename: 'Assembly';
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          assemblyId?: string | null;
          historyTypeId?: string | null;
          assemblyNodes?: Array<{
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null> | null;
          assemblyEdges?: Array<{
            __typename: 'AssemblyEdge';
            assemblyEdgeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyEdgeType?: {
              __typename: 'AssemblyEdgeType';
              assemblyEdgeTypeId?: string | null;
              assemblyEdgeTypeName?: string | null;
            } | null;
            beginHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            endHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            beginNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
            endNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
          } | null> | null;
        } | null;
        assemblyEdgeType?: {
          __typename: 'AssemblyEdgeType';
          assemblyEdgeTypeId?: string | null;
          assemblyEdgeTypeName?: string | null;
        } | null;
        beginHandlePosition?: {
          __typename: 'AssemblyHandlePosition';
          assemblyHandlePositionId?: string | null;
          assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
        } | null;
        endHandlePosition?: {
          __typename: 'AssemblyHandlePosition';
          assemblyHandlePositionId?: string | null;
          assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
        } | null;
        beginNode?: {
          __typename: 'AssemblyNode';
          quantity?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          shopTaskId?: string | null;
          assemblyNodeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyNodeType?: {
            __typename: 'AssemblyNodeType';
            assemblyNodeTypeId?: string | null;
            assemblyNodeTypeName?: string | null;
          } | null;
          assemblyNodeName?: string | null;
          assemblyNodeDescription?: string | null;
          positionX?: number | null;
          positionY?: number | null;
        } | null;
        endNode?: {
          __typename: 'AssemblyNode';
          quantity?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          shopTaskId?: string | null;
          assemblyNodeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyNodeType?: {
            __typename: 'AssemblyNodeType';
            assemblyNodeTypeId?: string | null;
            assemblyNodeTypeName?: string | null;
          } | null;
          assemblyNodeName?: string | null;
          assemblyNodeDescription?: string | null;
          positionX?: number | null;
          positionY?: number | null;
        } | null;
      } | null> | null;
    } | null;
    assemblyNodeType?: {
      __typename: 'AssemblyNodeType';
      assemblyNodeTypeId?: string | null;
      assemblyNodeTypeName?: string | null;
    } | null;
    assemblyNodeName?: string | null;
    assemblyNodeDescription?: string | null;
    positionX?: number | null;
    positionY?: number | null;
  } | null> | null;
};

export type AssemblyNodeByIdQueryVariables = {
  params: QueryAssemblyNodeByIdParamsInput;
};

export type AssemblyNodeByIdQuery = {
  assemblyNodeById?: {
    __typename: 'AssemblyNode';
    quantity?: number | null;
    part?: {
      __typename: 'Part';
      partId: string;
      partName: string;
      description?: string | null;
      stateId?: string | null;
      isDefault?: boolean | null;
      manufacturerCatalogNumber?: string | null;
      unitOfMeasureCode?: string | null;
      unitOfMeasureName?: string | null;
      upcCode?: string | null;
      url?: string | null;
      catalogUrl?: string | null;
      partUrl?: string | null;
      internalPartNumber?: string | null;
      createdOn?: string | null;
      hasAssembly?: boolean | null;
      manufacturer?: {
        __typename: 'Manufactured';
        manufacturerId: string;
        companyId?: string | null;
        manufacturerName: string;
        homePageUrl?: string | null;
      } | null;
      attriubuteVariantData?: Array<{
        __typename: 'PartAttributeVariantData';
        partAttributeVariantDataId?: string | null;
        partAttributeId?: string | null;
        partAttributeName?: string | null;
        rangeValue?: number | null;
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
        partAttribute?: {
          __typename: 'PartAttribute';
          partAttributeId: string;
          partAttributeName: string;
          allowWriteInVariant?: boolean | null;
          rangeMinimumValue?: number | null;
          rangeMaximumValue?: number | null;
          selectVariants?: Array<{
            __typename: 'PartAttributeSelectVariant';
            partAttributeSelectVariantId?: string | null;
            textValue?: string | null;
            codeValue?: string | null;
            isDeleted?: boolean | null;
          } | null> | null;
          partAttributeType?: {
            __typename: 'PartAttributeType';
            partAttributeTypeId: string;
            partAttributeTypeName: string;
            partAttributeTypeDescription?: string | null;
            sortOrder?: number | null;
          } | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
        } | null;
        selectValues?: Array<{
          __typename: 'PartAttributeSelectVariant';
          partAttributeSelectVariantId?: string | null;
          textValue?: string | null;
          codeValue?: string | null;
          isDeleted?: boolean | null;
        } | null> | null;
      } | null> | null;
      partCategory?: {
        __typename: 'PartCategory';
        partCategoryId?: string | null;
        partCategoryName?: string | null;
        partCatalog?: {
          __typename: 'PartCatalog';
          partCatalogId: string;
          partCatalogName: string;
          companyId: string;
          topLevelNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        parentPartCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        subNodes?: Array<{
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      } | null;
      partImages?: Array<{
        __typename: 'PartImage';
        partImageId?: string | null;
        sortOrder?: number | null;
        isDefault?: boolean | null;
        document?: {
          __typename: 'Document';
          documentId: string;
          documentName?: string | null;
          documentIdentifier?: string | null;
          documentTypeId?: string | null;
          storagePath?: string | null;
          extension?: string | null;
          encodedUrn?: string | null;
          documentStatusTypeId?: string | null;
          isMappedFromShop?: boolean | null;
          folderDocumentResponse?: Array<{
            __typename: 'FolderDocument';
            folderDocumentId: string;
            folderId: string;
            folderName?: string | null;
            documentId: string;
          } | null> | null;
          documentNameFormatted?: string | null;
          createdOn?: string | null;
          createdBy?: string | null;
        } | null;
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
      } | null> | null;
      documentIds?: Array<string | null> | null;
      unitOfMeasure?: {
        __typename: 'UnitOfMeasure';
        unitOfMeasureId: string;
        unitOfMeasureTypeId?: string | null;
        companyId?: string | null;
        unitOfMeasureName: string;
        unitOfMeasureCode: string;
        createdOn?: string | null;
        createdBy?: string | null;
        updatedOn?: string | null;
        modifiedBy?: string | null;
        modifiedOn?: string | null;
      } | null;
    } | null;
    shopTaskId?: string | null;
    assemblyNodeId?: string | null;
    historyTypeId?: string | null;
    assembly?: {
      __typename: 'Assembly';
      part?: {
        __typename: 'Part';
        partId: string;
        partName: string;
        description?: string | null;
        stateId?: string | null;
        isDefault?: boolean | null;
        manufacturerCatalogNumber?: string | null;
        unitOfMeasureCode?: string | null;
        unitOfMeasureName?: string | null;
        upcCode?: string | null;
        url?: string | null;
        catalogUrl?: string | null;
        partUrl?: string | null;
        internalPartNumber?: string | null;
        createdOn?: string | null;
        hasAssembly?: boolean | null;
        manufacturer?: {
          __typename: 'Manufactured';
          manufacturerId: string;
          companyId?: string | null;
          manufacturerName: string;
          homePageUrl?: string | null;
        } | null;
        attriubuteVariantData?: Array<{
          __typename: 'PartAttributeVariantData';
          partAttributeVariantDataId?: string | null;
          partAttributeId?: string | null;
          partAttributeName?: string | null;
          rangeValue?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          partAttribute?: {
            __typename: 'PartAttribute';
            partAttributeId: string;
            partAttributeName: string;
            allowWriteInVariant?: boolean | null;
            rangeMinimumValue?: number | null;
            rangeMaximumValue?: number | null;
            selectVariants?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
            partAttributeType?: {
              __typename: 'PartAttributeType';
              partAttributeTypeId: string;
              partAttributeTypeName: string;
              partAttributeTypeDescription?: string | null;
              sortOrder?: number | null;
            } | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
          } | null;
          selectValues?: Array<{
            __typename: 'PartAttributeSelectVariant';
            partAttributeSelectVariantId?: string | null;
            textValue?: string | null;
            codeValue?: string | null;
            isDeleted?: boolean | null;
          } | null> | null;
        } | null> | null;
        partCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        partImages?: Array<{
          __typename: 'PartImage';
          partImageId?: string | null;
          sortOrder?: number | null;
          isDefault?: boolean | null;
          document?: {
            __typename: 'Document';
            documentId: string;
            documentName?: string | null;
            documentIdentifier?: string | null;
            documentTypeId?: string | null;
            storagePath?: string | null;
            extension?: string | null;
            encodedUrn?: string | null;
            documentStatusTypeId?: string | null;
            isMappedFromShop?: boolean | null;
            folderDocumentResponse?: Array<{
              __typename: 'FolderDocument';
              folderDocumentId: string;
              folderId: string;
              folderName?: string | null;
              documentId: string;
            } | null> | null;
            documentNameFormatted?: string | null;
            createdOn?: string | null;
            createdBy?: string | null;
          } | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
        } | null> | null;
        documentIds?: Array<string | null> | null;
        unitOfMeasure?: {
          __typename: 'UnitOfMeasure';
          unitOfMeasureId: string;
          unitOfMeasureTypeId?: string | null;
          companyId?: string | null;
          unitOfMeasureName: string;
          unitOfMeasureCode: string;
          createdOn?: string | null;
          createdBy?: string | null;
          updatedOn?: string | null;
          modifiedBy?: string | null;
          modifiedOn?: string | null;
        } | null;
      } | null;
      assemblyId?: string | null;
      historyTypeId?: string | null;
      assemblyNodes?: Array<{
        __typename: 'AssemblyNode';
        quantity?: number | null;
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
        shopTaskId?: string | null;
        assemblyNodeId?: string | null;
        historyTypeId?: string | null;
        assembly?: {
          __typename: 'Assembly';
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          assemblyId?: string | null;
          historyTypeId?: string | null;
          assemblyNodes?: Array<{
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null> | null;
          assemblyEdges?: Array<{
            __typename: 'AssemblyEdge';
            assemblyEdgeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyEdgeType?: {
              __typename: 'AssemblyEdgeType';
              assemblyEdgeTypeId?: string | null;
              assemblyEdgeTypeName?: string | null;
            } | null;
            beginHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            endHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            beginNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
            endNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
          } | null> | null;
        } | null;
        assemblyNodeType?: {
          __typename: 'AssemblyNodeType';
          assemblyNodeTypeId?: string | null;
          assemblyNodeTypeName?: string | null;
        } | null;
        assemblyNodeName?: string | null;
        assemblyNodeDescription?: string | null;
        positionX?: number | null;
        positionY?: number | null;
      } | null> | null;
      assemblyEdges?: Array<{
        __typename: 'AssemblyEdge';
        assemblyEdgeId?: string | null;
        historyTypeId?: string | null;
        assembly?: {
          __typename: 'Assembly';
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          assemblyId?: string | null;
          historyTypeId?: string | null;
          assemblyNodes?: Array<{
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null> | null;
          assemblyEdges?: Array<{
            __typename: 'AssemblyEdge';
            assemblyEdgeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyEdgeType?: {
              __typename: 'AssemblyEdgeType';
              assemblyEdgeTypeId?: string | null;
              assemblyEdgeTypeName?: string | null;
            } | null;
            beginHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            endHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            beginNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
            endNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
          } | null> | null;
        } | null;
        assemblyEdgeType?: {
          __typename: 'AssemblyEdgeType';
          assemblyEdgeTypeId?: string | null;
          assemblyEdgeTypeName?: string | null;
        } | null;
        beginHandlePosition?: {
          __typename: 'AssemblyHandlePosition';
          assemblyHandlePositionId?: string | null;
          assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
        } | null;
        endHandlePosition?: {
          __typename: 'AssemblyHandlePosition';
          assemblyHandlePositionId?: string | null;
          assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
        } | null;
        beginNode?: {
          __typename: 'AssemblyNode';
          quantity?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          shopTaskId?: string | null;
          assemblyNodeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyNodeType?: {
            __typename: 'AssemblyNodeType';
            assemblyNodeTypeId?: string | null;
            assemblyNodeTypeName?: string | null;
          } | null;
          assemblyNodeName?: string | null;
          assemblyNodeDescription?: string | null;
          positionX?: number | null;
          positionY?: number | null;
        } | null;
        endNode?: {
          __typename: 'AssemblyNode';
          quantity?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          shopTaskId?: string | null;
          assemblyNodeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyNodeType?: {
            __typename: 'AssemblyNodeType';
            assemblyNodeTypeId?: string | null;
            assemblyNodeTypeName?: string | null;
          } | null;
          assemblyNodeName?: string | null;
          assemblyNodeDescription?: string | null;
          positionX?: number | null;
          positionY?: number | null;
        } | null;
      } | null> | null;
    } | null;
    assemblyNodeType?: {
      __typename: 'AssemblyNodeType';
      assemblyNodeTypeId?: string | null;
      assemblyNodeTypeName?: string | null;
    } | null;
    assemblyNodeName?: string | null;
    assemblyNodeDescription?: string | null;
    positionX?: number | null;
    positionY?: number | null;
  } | null;
};

export type AssemblyEdgeQueryVariables = {
  query?: QueryAssemblyEdgeQueryInput | null;
};

export type AssemblyEdgeQuery = {
  assemblyEdge?: Array<{
    __typename: 'AssemblyEdge';
    assemblyEdgeId?: string | null;
    historyTypeId?: string | null;
    assembly?: {
      __typename: 'Assembly';
      part?: {
        __typename: 'Part';
        partId: string;
        partName: string;
        description?: string | null;
        stateId?: string | null;
        isDefault?: boolean | null;
        manufacturerCatalogNumber?: string | null;
        unitOfMeasureCode?: string | null;
        unitOfMeasureName?: string | null;
        upcCode?: string | null;
        url?: string | null;
        catalogUrl?: string | null;
        partUrl?: string | null;
        internalPartNumber?: string | null;
        createdOn?: string | null;
        hasAssembly?: boolean | null;
        manufacturer?: {
          __typename: 'Manufactured';
          manufacturerId: string;
          companyId?: string | null;
          manufacturerName: string;
          homePageUrl?: string | null;
        } | null;
        attriubuteVariantData?: Array<{
          __typename: 'PartAttributeVariantData';
          partAttributeVariantDataId?: string | null;
          partAttributeId?: string | null;
          partAttributeName?: string | null;
          rangeValue?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          partAttribute?: {
            __typename: 'PartAttribute';
            partAttributeId: string;
            partAttributeName: string;
            allowWriteInVariant?: boolean | null;
            rangeMinimumValue?: number | null;
            rangeMaximumValue?: number | null;
            selectVariants?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
            partAttributeType?: {
              __typename: 'PartAttributeType';
              partAttributeTypeId: string;
              partAttributeTypeName: string;
              partAttributeTypeDescription?: string | null;
              sortOrder?: number | null;
            } | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
          } | null;
          selectValues?: Array<{
            __typename: 'PartAttributeSelectVariant';
            partAttributeSelectVariantId?: string | null;
            textValue?: string | null;
            codeValue?: string | null;
            isDeleted?: boolean | null;
          } | null> | null;
        } | null> | null;
        partCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        partImages?: Array<{
          __typename: 'PartImage';
          partImageId?: string | null;
          sortOrder?: number | null;
          isDefault?: boolean | null;
          document?: {
            __typename: 'Document';
            documentId: string;
            documentName?: string | null;
            documentIdentifier?: string | null;
            documentTypeId?: string | null;
            storagePath?: string | null;
            extension?: string | null;
            encodedUrn?: string | null;
            documentStatusTypeId?: string | null;
            isMappedFromShop?: boolean | null;
            folderDocumentResponse?: Array<{
              __typename: 'FolderDocument';
              folderDocumentId: string;
              folderId: string;
              folderName?: string | null;
              documentId: string;
            } | null> | null;
            documentNameFormatted?: string | null;
            createdOn?: string | null;
            createdBy?: string | null;
          } | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
        } | null> | null;
        documentIds?: Array<string | null> | null;
        unitOfMeasure?: {
          __typename: 'UnitOfMeasure';
          unitOfMeasureId: string;
          unitOfMeasureTypeId?: string | null;
          companyId?: string | null;
          unitOfMeasureName: string;
          unitOfMeasureCode: string;
          createdOn?: string | null;
          createdBy?: string | null;
          updatedOn?: string | null;
          modifiedBy?: string | null;
          modifiedOn?: string | null;
        } | null;
      } | null;
      assemblyId?: string | null;
      historyTypeId?: string | null;
      assemblyNodes?: Array<{
        __typename: 'AssemblyNode';
        quantity?: number | null;
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
        shopTaskId?: string | null;
        assemblyNodeId?: string | null;
        historyTypeId?: string | null;
        assembly?: {
          __typename: 'Assembly';
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          assemblyId?: string | null;
          historyTypeId?: string | null;
          assemblyNodes?: Array<{
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null> | null;
          assemblyEdges?: Array<{
            __typename: 'AssemblyEdge';
            assemblyEdgeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyEdgeType?: {
              __typename: 'AssemblyEdgeType';
              assemblyEdgeTypeId?: string | null;
              assemblyEdgeTypeName?: string | null;
            } | null;
            beginHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            endHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            beginNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
            endNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
          } | null> | null;
        } | null;
        assemblyNodeType?: {
          __typename: 'AssemblyNodeType';
          assemblyNodeTypeId?: string | null;
          assemblyNodeTypeName?: string | null;
        } | null;
        assemblyNodeName?: string | null;
        assemblyNodeDescription?: string | null;
        positionX?: number | null;
        positionY?: number | null;
      } | null> | null;
      assemblyEdges?: Array<{
        __typename: 'AssemblyEdge';
        assemblyEdgeId?: string | null;
        historyTypeId?: string | null;
        assembly?: {
          __typename: 'Assembly';
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          assemblyId?: string | null;
          historyTypeId?: string | null;
          assemblyNodes?: Array<{
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null> | null;
          assemblyEdges?: Array<{
            __typename: 'AssemblyEdge';
            assemblyEdgeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyEdgeType?: {
              __typename: 'AssemblyEdgeType';
              assemblyEdgeTypeId?: string | null;
              assemblyEdgeTypeName?: string | null;
            } | null;
            beginHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            endHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            beginNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
            endNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
          } | null> | null;
        } | null;
        assemblyEdgeType?: {
          __typename: 'AssemblyEdgeType';
          assemblyEdgeTypeId?: string | null;
          assemblyEdgeTypeName?: string | null;
        } | null;
        beginHandlePosition?: {
          __typename: 'AssemblyHandlePosition';
          assemblyHandlePositionId?: string | null;
          assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
        } | null;
        endHandlePosition?: {
          __typename: 'AssemblyHandlePosition';
          assemblyHandlePositionId?: string | null;
          assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
        } | null;
        beginNode?: {
          __typename: 'AssemblyNode';
          quantity?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          shopTaskId?: string | null;
          assemblyNodeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyNodeType?: {
            __typename: 'AssemblyNodeType';
            assemblyNodeTypeId?: string | null;
            assemblyNodeTypeName?: string | null;
          } | null;
          assemblyNodeName?: string | null;
          assemblyNodeDescription?: string | null;
          positionX?: number | null;
          positionY?: number | null;
        } | null;
        endNode?: {
          __typename: 'AssemblyNode';
          quantity?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          shopTaskId?: string | null;
          assemblyNodeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyNodeType?: {
            __typename: 'AssemblyNodeType';
            assemblyNodeTypeId?: string | null;
            assemblyNodeTypeName?: string | null;
          } | null;
          assemblyNodeName?: string | null;
          assemblyNodeDescription?: string | null;
          positionX?: number | null;
          positionY?: number | null;
        } | null;
      } | null> | null;
    } | null;
    assemblyEdgeType?: {
      __typename: 'AssemblyEdgeType';
      assemblyEdgeTypeId?: string | null;
      assemblyEdgeTypeName?: string | null;
    } | null;
    beginHandlePosition?: {
      __typename: 'AssemblyHandlePosition';
      assemblyHandlePositionId?: string | null;
      assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
    } | null;
    endHandlePosition?: {
      __typename: 'AssemblyHandlePosition';
      assemblyHandlePositionId?: string | null;
      assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
    } | null;
    beginNode?: {
      __typename: 'AssemblyNode';
      quantity?: number | null;
      part?: {
        __typename: 'Part';
        partId: string;
        partName: string;
        description?: string | null;
        stateId?: string | null;
        isDefault?: boolean | null;
        manufacturerCatalogNumber?: string | null;
        unitOfMeasureCode?: string | null;
        unitOfMeasureName?: string | null;
        upcCode?: string | null;
        url?: string | null;
        catalogUrl?: string | null;
        partUrl?: string | null;
        internalPartNumber?: string | null;
        createdOn?: string | null;
        hasAssembly?: boolean | null;
        manufacturer?: {
          __typename: 'Manufactured';
          manufacturerId: string;
          companyId?: string | null;
          manufacturerName: string;
          homePageUrl?: string | null;
        } | null;
        attriubuteVariantData?: Array<{
          __typename: 'PartAttributeVariantData';
          partAttributeVariantDataId?: string | null;
          partAttributeId?: string | null;
          partAttributeName?: string | null;
          rangeValue?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          partAttribute?: {
            __typename: 'PartAttribute';
            partAttributeId: string;
            partAttributeName: string;
            allowWriteInVariant?: boolean | null;
            rangeMinimumValue?: number | null;
            rangeMaximumValue?: number | null;
            selectVariants?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
            partAttributeType?: {
              __typename: 'PartAttributeType';
              partAttributeTypeId: string;
              partAttributeTypeName: string;
              partAttributeTypeDescription?: string | null;
              sortOrder?: number | null;
            } | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
          } | null;
          selectValues?: Array<{
            __typename: 'PartAttributeSelectVariant';
            partAttributeSelectVariantId?: string | null;
            textValue?: string | null;
            codeValue?: string | null;
            isDeleted?: boolean | null;
          } | null> | null;
        } | null> | null;
        partCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        partImages?: Array<{
          __typename: 'PartImage';
          partImageId?: string | null;
          sortOrder?: number | null;
          isDefault?: boolean | null;
          document?: {
            __typename: 'Document';
            documentId: string;
            documentName?: string | null;
            documentIdentifier?: string | null;
            documentTypeId?: string | null;
            storagePath?: string | null;
            extension?: string | null;
            encodedUrn?: string | null;
            documentStatusTypeId?: string | null;
            isMappedFromShop?: boolean | null;
            folderDocumentResponse?: Array<{
              __typename: 'FolderDocument';
              folderDocumentId: string;
              folderId: string;
              folderName?: string | null;
              documentId: string;
            } | null> | null;
            documentNameFormatted?: string | null;
            createdOn?: string | null;
            createdBy?: string | null;
          } | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
        } | null> | null;
        documentIds?: Array<string | null> | null;
        unitOfMeasure?: {
          __typename: 'UnitOfMeasure';
          unitOfMeasureId: string;
          unitOfMeasureTypeId?: string | null;
          companyId?: string | null;
          unitOfMeasureName: string;
          unitOfMeasureCode: string;
          createdOn?: string | null;
          createdBy?: string | null;
          updatedOn?: string | null;
          modifiedBy?: string | null;
          modifiedOn?: string | null;
        } | null;
      } | null;
      shopTaskId?: string | null;
      assemblyNodeId?: string | null;
      historyTypeId?: string | null;
      assembly?: {
        __typename: 'Assembly';
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
        assemblyId?: string | null;
        historyTypeId?: string | null;
        assemblyNodes?: Array<{
          __typename: 'AssemblyNode';
          quantity?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          shopTaskId?: string | null;
          assemblyNodeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyNodeType?: {
            __typename: 'AssemblyNodeType';
            assemblyNodeTypeId?: string | null;
            assemblyNodeTypeName?: string | null;
          } | null;
          assemblyNodeName?: string | null;
          assemblyNodeDescription?: string | null;
          positionX?: number | null;
          positionY?: number | null;
        } | null> | null;
        assemblyEdges?: Array<{
          __typename: 'AssemblyEdge';
          assemblyEdgeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyEdgeType?: {
            __typename: 'AssemblyEdgeType';
            assemblyEdgeTypeId?: string | null;
            assemblyEdgeTypeName?: string | null;
          } | null;
          beginHandlePosition?: {
            __typename: 'AssemblyHandlePosition';
            assemblyHandlePositionId?: string | null;
            assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
          } | null;
          endHandlePosition?: {
            __typename: 'AssemblyHandlePosition';
            assemblyHandlePositionId?: string | null;
            assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
          } | null;
          beginNode?: {
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null;
          endNode?: {
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null;
        } | null> | null;
      } | null;
      assemblyNodeType?: {
        __typename: 'AssemblyNodeType';
        assemblyNodeTypeId?: string | null;
        assemblyNodeTypeName?: string | null;
      } | null;
      assemblyNodeName?: string | null;
      assemblyNodeDescription?: string | null;
      positionX?: number | null;
      positionY?: number | null;
    } | null;
    endNode?: {
      __typename: 'AssemblyNode';
      quantity?: number | null;
      part?: {
        __typename: 'Part';
        partId: string;
        partName: string;
        description?: string | null;
        stateId?: string | null;
        isDefault?: boolean | null;
        manufacturerCatalogNumber?: string | null;
        unitOfMeasureCode?: string | null;
        unitOfMeasureName?: string | null;
        upcCode?: string | null;
        url?: string | null;
        catalogUrl?: string | null;
        partUrl?: string | null;
        internalPartNumber?: string | null;
        createdOn?: string | null;
        hasAssembly?: boolean | null;
        manufacturer?: {
          __typename: 'Manufactured';
          manufacturerId: string;
          companyId?: string | null;
          manufacturerName: string;
          homePageUrl?: string | null;
        } | null;
        attriubuteVariantData?: Array<{
          __typename: 'PartAttributeVariantData';
          partAttributeVariantDataId?: string | null;
          partAttributeId?: string | null;
          partAttributeName?: string | null;
          rangeValue?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          partAttribute?: {
            __typename: 'PartAttribute';
            partAttributeId: string;
            partAttributeName: string;
            allowWriteInVariant?: boolean | null;
            rangeMinimumValue?: number | null;
            rangeMaximumValue?: number | null;
            selectVariants?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
            partAttributeType?: {
              __typename: 'PartAttributeType';
              partAttributeTypeId: string;
              partAttributeTypeName: string;
              partAttributeTypeDescription?: string | null;
              sortOrder?: number | null;
            } | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
          } | null;
          selectValues?: Array<{
            __typename: 'PartAttributeSelectVariant';
            partAttributeSelectVariantId?: string | null;
            textValue?: string | null;
            codeValue?: string | null;
            isDeleted?: boolean | null;
          } | null> | null;
        } | null> | null;
        partCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        partImages?: Array<{
          __typename: 'PartImage';
          partImageId?: string | null;
          sortOrder?: number | null;
          isDefault?: boolean | null;
          document?: {
            __typename: 'Document';
            documentId: string;
            documentName?: string | null;
            documentIdentifier?: string | null;
            documentTypeId?: string | null;
            storagePath?: string | null;
            extension?: string | null;
            encodedUrn?: string | null;
            documentStatusTypeId?: string | null;
            isMappedFromShop?: boolean | null;
            folderDocumentResponse?: Array<{
              __typename: 'FolderDocument';
              folderDocumentId: string;
              folderId: string;
              folderName?: string | null;
              documentId: string;
            } | null> | null;
            documentNameFormatted?: string | null;
            createdOn?: string | null;
            createdBy?: string | null;
          } | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
        } | null> | null;
        documentIds?: Array<string | null> | null;
        unitOfMeasure?: {
          __typename: 'UnitOfMeasure';
          unitOfMeasureId: string;
          unitOfMeasureTypeId?: string | null;
          companyId?: string | null;
          unitOfMeasureName: string;
          unitOfMeasureCode: string;
          createdOn?: string | null;
          createdBy?: string | null;
          updatedOn?: string | null;
          modifiedBy?: string | null;
          modifiedOn?: string | null;
        } | null;
      } | null;
      shopTaskId?: string | null;
      assemblyNodeId?: string | null;
      historyTypeId?: string | null;
      assembly?: {
        __typename: 'Assembly';
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
        assemblyId?: string | null;
        historyTypeId?: string | null;
        assemblyNodes?: Array<{
          __typename: 'AssemblyNode';
          quantity?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          shopTaskId?: string | null;
          assemblyNodeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyNodeType?: {
            __typename: 'AssemblyNodeType';
            assemblyNodeTypeId?: string | null;
            assemblyNodeTypeName?: string | null;
          } | null;
          assemblyNodeName?: string | null;
          assemblyNodeDescription?: string | null;
          positionX?: number | null;
          positionY?: number | null;
        } | null> | null;
        assemblyEdges?: Array<{
          __typename: 'AssemblyEdge';
          assemblyEdgeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyEdgeType?: {
            __typename: 'AssemblyEdgeType';
            assemblyEdgeTypeId?: string | null;
            assemblyEdgeTypeName?: string | null;
          } | null;
          beginHandlePosition?: {
            __typename: 'AssemblyHandlePosition';
            assemblyHandlePositionId?: string | null;
            assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
          } | null;
          endHandlePosition?: {
            __typename: 'AssemblyHandlePosition';
            assemblyHandlePositionId?: string | null;
            assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
          } | null;
          beginNode?: {
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null;
          endNode?: {
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null;
        } | null> | null;
      } | null;
      assemblyNodeType?: {
        __typename: 'AssemblyNodeType';
        assemblyNodeTypeId?: string | null;
        assemblyNodeTypeName?: string | null;
      } | null;
      assemblyNodeName?: string | null;
      assemblyNodeDescription?: string | null;
      positionX?: number | null;
      positionY?: number | null;
    } | null;
  } | null> | null;
};

export type AssemblyEdgeByIdQueryVariables = {
  params: QueryAssemblyEdgeByIdParamsInput;
};

export type AssemblyEdgeByIdQuery = {
  assemblyEdgeById?: {
    __typename: 'AssemblyEdge';
    assemblyEdgeId?: string | null;
    historyTypeId?: string | null;
    assembly?: {
      __typename: 'Assembly';
      part?: {
        __typename: 'Part';
        partId: string;
        partName: string;
        description?: string | null;
        stateId?: string | null;
        isDefault?: boolean | null;
        manufacturerCatalogNumber?: string | null;
        unitOfMeasureCode?: string | null;
        unitOfMeasureName?: string | null;
        upcCode?: string | null;
        url?: string | null;
        catalogUrl?: string | null;
        partUrl?: string | null;
        internalPartNumber?: string | null;
        createdOn?: string | null;
        hasAssembly?: boolean | null;
        manufacturer?: {
          __typename: 'Manufactured';
          manufacturerId: string;
          companyId?: string | null;
          manufacturerName: string;
          homePageUrl?: string | null;
        } | null;
        attriubuteVariantData?: Array<{
          __typename: 'PartAttributeVariantData';
          partAttributeVariantDataId?: string | null;
          partAttributeId?: string | null;
          partAttributeName?: string | null;
          rangeValue?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          partAttribute?: {
            __typename: 'PartAttribute';
            partAttributeId: string;
            partAttributeName: string;
            allowWriteInVariant?: boolean | null;
            rangeMinimumValue?: number | null;
            rangeMaximumValue?: number | null;
            selectVariants?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
            partAttributeType?: {
              __typename: 'PartAttributeType';
              partAttributeTypeId: string;
              partAttributeTypeName: string;
              partAttributeTypeDescription?: string | null;
              sortOrder?: number | null;
            } | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
          } | null;
          selectValues?: Array<{
            __typename: 'PartAttributeSelectVariant';
            partAttributeSelectVariantId?: string | null;
            textValue?: string | null;
            codeValue?: string | null;
            isDeleted?: boolean | null;
          } | null> | null;
        } | null> | null;
        partCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        partImages?: Array<{
          __typename: 'PartImage';
          partImageId?: string | null;
          sortOrder?: number | null;
          isDefault?: boolean | null;
          document?: {
            __typename: 'Document';
            documentId: string;
            documentName?: string | null;
            documentIdentifier?: string | null;
            documentTypeId?: string | null;
            storagePath?: string | null;
            extension?: string | null;
            encodedUrn?: string | null;
            documentStatusTypeId?: string | null;
            isMappedFromShop?: boolean | null;
            folderDocumentResponse?: Array<{
              __typename: 'FolderDocument';
              folderDocumentId: string;
              folderId: string;
              folderName?: string | null;
              documentId: string;
            } | null> | null;
            documentNameFormatted?: string | null;
            createdOn?: string | null;
            createdBy?: string | null;
          } | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
        } | null> | null;
        documentIds?: Array<string | null> | null;
        unitOfMeasure?: {
          __typename: 'UnitOfMeasure';
          unitOfMeasureId: string;
          unitOfMeasureTypeId?: string | null;
          companyId?: string | null;
          unitOfMeasureName: string;
          unitOfMeasureCode: string;
          createdOn?: string | null;
          createdBy?: string | null;
          updatedOn?: string | null;
          modifiedBy?: string | null;
          modifiedOn?: string | null;
        } | null;
      } | null;
      assemblyId?: string | null;
      historyTypeId?: string | null;
      assemblyNodes?: Array<{
        __typename: 'AssemblyNode';
        quantity?: number | null;
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
        shopTaskId?: string | null;
        assemblyNodeId?: string | null;
        historyTypeId?: string | null;
        assembly?: {
          __typename: 'Assembly';
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          assemblyId?: string | null;
          historyTypeId?: string | null;
          assemblyNodes?: Array<{
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null> | null;
          assemblyEdges?: Array<{
            __typename: 'AssemblyEdge';
            assemblyEdgeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyEdgeType?: {
              __typename: 'AssemblyEdgeType';
              assemblyEdgeTypeId?: string | null;
              assemblyEdgeTypeName?: string | null;
            } | null;
            beginHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            endHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            beginNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
            endNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
          } | null> | null;
        } | null;
        assemblyNodeType?: {
          __typename: 'AssemblyNodeType';
          assemblyNodeTypeId?: string | null;
          assemblyNodeTypeName?: string | null;
        } | null;
        assemblyNodeName?: string | null;
        assemblyNodeDescription?: string | null;
        positionX?: number | null;
        positionY?: number | null;
      } | null> | null;
      assemblyEdges?: Array<{
        __typename: 'AssemblyEdge';
        assemblyEdgeId?: string | null;
        historyTypeId?: string | null;
        assembly?: {
          __typename: 'Assembly';
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          assemblyId?: string | null;
          historyTypeId?: string | null;
          assemblyNodes?: Array<{
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null> | null;
          assemblyEdges?: Array<{
            __typename: 'AssemblyEdge';
            assemblyEdgeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyEdgeType?: {
              __typename: 'AssemblyEdgeType';
              assemblyEdgeTypeId?: string | null;
              assemblyEdgeTypeName?: string | null;
            } | null;
            beginHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            endHandlePosition?: {
              __typename: 'AssemblyHandlePosition';
              assemblyHandlePositionId?: string | null;
              assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
            } | null;
            beginNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
            endNode?: {
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null;
          } | null> | null;
        } | null;
        assemblyEdgeType?: {
          __typename: 'AssemblyEdgeType';
          assemblyEdgeTypeId?: string | null;
          assemblyEdgeTypeName?: string | null;
        } | null;
        beginHandlePosition?: {
          __typename: 'AssemblyHandlePosition';
          assemblyHandlePositionId?: string | null;
          assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
        } | null;
        endHandlePosition?: {
          __typename: 'AssemblyHandlePosition';
          assemblyHandlePositionId?: string | null;
          assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
        } | null;
        beginNode?: {
          __typename: 'AssemblyNode';
          quantity?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          shopTaskId?: string | null;
          assemblyNodeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyNodeType?: {
            __typename: 'AssemblyNodeType';
            assemblyNodeTypeId?: string | null;
            assemblyNodeTypeName?: string | null;
          } | null;
          assemblyNodeName?: string | null;
          assemblyNodeDescription?: string | null;
          positionX?: number | null;
          positionY?: number | null;
        } | null;
        endNode?: {
          __typename: 'AssemblyNode';
          quantity?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          shopTaskId?: string | null;
          assemblyNodeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyNodeType?: {
            __typename: 'AssemblyNodeType';
            assemblyNodeTypeId?: string | null;
            assemblyNodeTypeName?: string | null;
          } | null;
          assemblyNodeName?: string | null;
          assemblyNodeDescription?: string | null;
          positionX?: number | null;
          positionY?: number | null;
        } | null;
      } | null> | null;
    } | null;
    assemblyEdgeType?: {
      __typename: 'AssemblyEdgeType';
      assemblyEdgeTypeId?: string | null;
      assemblyEdgeTypeName?: string | null;
    } | null;
    beginHandlePosition?: {
      __typename: 'AssemblyHandlePosition';
      assemblyHandlePositionId?: string | null;
      assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
    } | null;
    endHandlePosition?: {
      __typename: 'AssemblyHandlePosition';
      assemblyHandlePositionId?: string | null;
      assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
    } | null;
    beginNode?: {
      __typename: 'AssemblyNode';
      quantity?: number | null;
      part?: {
        __typename: 'Part';
        partId: string;
        partName: string;
        description?: string | null;
        stateId?: string | null;
        isDefault?: boolean | null;
        manufacturerCatalogNumber?: string | null;
        unitOfMeasureCode?: string | null;
        unitOfMeasureName?: string | null;
        upcCode?: string | null;
        url?: string | null;
        catalogUrl?: string | null;
        partUrl?: string | null;
        internalPartNumber?: string | null;
        createdOn?: string | null;
        hasAssembly?: boolean | null;
        manufacturer?: {
          __typename: 'Manufactured';
          manufacturerId: string;
          companyId?: string | null;
          manufacturerName: string;
          homePageUrl?: string | null;
        } | null;
        attriubuteVariantData?: Array<{
          __typename: 'PartAttributeVariantData';
          partAttributeVariantDataId?: string | null;
          partAttributeId?: string | null;
          partAttributeName?: string | null;
          rangeValue?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          partAttribute?: {
            __typename: 'PartAttribute';
            partAttributeId: string;
            partAttributeName: string;
            allowWriteInVariant?: boolean | null;
            rangeMinimumValue?: number | null;
            rangeMaximumValue?: number | null;
            selectVariants?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
            partAttributeType?: {
              __typename: 'PartAttributeType';
              partAttributeTypeId: string;
              partAttributeTypeName: string;
              partAttributeTypeDescription?: string | null;
              sortOrder?: number | null;
            } | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
          } | null;
          selectValues?: Array<{
            __typename: 'PartAttributeSelectVariant';
            partAttributeSelectVariantId?: string | null;
            textValue?: string | null;
            codeValue?: string | null;
            isDeleted?: boolean | null;
          } | null> | null;
        } | null> | null;
        partCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        partImages?: Array<{
          __typename: 'PartImage';
          partImageId?: string | null;
          sortOrder?: number | null;
          isDefault?: boolean | null;
          document?: {
            __typename: 'Document';
            documentId: string;
            documentName?: string | null;
            documentIdentifier?: string | null;
            documentTypeId?: string | null;
            storagePath?: string | null;
            extension?: string | null;
            encodedUrn?: string | null;
            documentStatusTypeId?: string | null;
            isMappedFromShop?: boolean | null;
            folderDocumentResponse?: Array<{
              __typename: 'FolderDocument';
              folderDocumentId: string;
              folderId: string;
              folderName?: string | null;
              documentId: string;
            } | null> | null;
            documentNameFormatted?: string | null;
            createdOn?: string | null;
            createdBy?: string | null;
          } | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
        } | null> | null;
        documentIds?: Array<string | null> | null;
        unitOfMeasure?: {
          __typename: 'UnitOfMeasure';
          unitOfMeasureId: string;
          unitOfMeasureTypeId?: string | null;
          companyId?: string | null;
          unitOfMeasureName: string;
          unitOfMeasureCode: string;
          createdOn?: string | null;
          createdBy?: string | null;
          updatedOn?: string | null;
          modifiedBy?: string | null;
          modifiedOn?: string | null;
        } | null;
      } | null;
      shopTaskId?: string | null;
      assemblyNodeId?: string | null;
      historyTypeId?: string | null;
      assembly?: {
        __typename: 'Assembly';
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
        assemblyId?: string | null;
        historyTypeId?: string | null;
        assemblyNodes?: Array<{
          __typename: 'AssemblyNode';
          quantity?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          shopTaskId?: string | null;
          assemblyNodeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyNodeType?: {
            __typename: 'AssemblyNodeType';
            assemblyNodeTypeId?: string | null;
            assemblyNodeTypeName?: string | null;
          } | null;
          assemblyNodeName?: string | null;
          assemblyNodeDescription?: string | null;
          positionX?: number | null;
          positionY?: number | null;
        } | null> | null;
        assemblyEdges?: Array<{
          __typename: 'AssemblyEdge';
          assemblyEdgeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyEdgeType?: {
            __typename: 'AssemblyEdgeType';
            assemblyEdgeTypeId?: string | null;
            assemblyEdgeTypeName?: string | null;
          } | null;
          beginHandlePosition?: {
            __typename: 'AssemblyHandlePosition';
            assemblyHandlePositionId?: string | null;
            assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
          } | null;
          endHandlePosition?: {
            __typename: 'AssemblyHandlePosition';
            assemblyHandlePositionId?: string | null;
            assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
          } | null;
          beginNode?: {
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null;
          endNode?: {
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null;
        } | null> | null;
      } | null;
      assemblyNodeType?: {
        __typename: 'AssemblyNodeType';
        assemblyNodeTypeId?: string | null;
        assemblyNodeTypeName?: string | null;
      } | null;
      assemblyNodeName?: string | null;
      assemblyNodeDescription?: string | null;
      positionX?: number | null;
      positionY?: number | null;
    } | null;
    endNode?: {
      __typename: 'AssemblyNode';
      quantity?: number | null;
      part?: {
        __typename: 'Part';
        partId: string;
        partName: string;
        description?: string | null;
        stateId?: string | null;
        isDefault?: boolean | null;
        manufacturerCatalogNumber?: string | null;
        unitOfMeasureCode?: string | null;
        unitOfMeasureName?: string | null;
        upcCode?: string | null;
        url?: string | null;
        catalogUrl?: string | null;
        partUrl?: string | null;
        internalPartNumber?: string | null;
        createdOn?: string | null;
        hasAssembly?: boolean | null;
        manufacturer?: {
          __typename: 'Manufactured';
          manufacturerId: string;
          companyId?: string | null;
          manufacturerName: string;
          homePageUrl?: string | null;
        } | null;
        attriubuteVariantData?: Array<{
          __typename: 'PartAttributeVariantData';
          partAttributeVariantDataId?: string | null;
          partAttributeId?: string | null;
          partAttributeName?: string | null;
          rangeValue?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          partAttribute?: {
            __typename: 'PartAttribute';
            partAttributeId: string;
            partAttributeName: string;
            allowWriteInVariant?: boolean | null;
            rangeMinimumValue?: number | null;
            rangeMaximumValue?: number | null;
            selectVariants?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
            partAttributeType?: {
              __typename: 'PartAttributeType';
              partAttributeTypeId: string;
              partAttributeTypeName: string;
              partAttributeTypeDescription?: string | null;
              sortOrder?: number | null;
            } | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
          } | null;
          selectValues?: Array<{
            __typename: 'PartAttributeSelectVariant';
            partAttributeSelectVariantId?: string | null;
            textValue?: string | null;
            codeValue?: string | null;
            isDeleted?: boolean | null;
          } | null> | null;
        } | null> | null;
        partCategory?: {
          __typename: 'PartCategory';
          partCategoryId?: string | null;
          partCategoryName?: string | null;
          partCatalog?: {
            __typename: 'PartCatalog';
            partCatalogId: string;
            partCatalogName: string;
            companyId: string;
            topLevelNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          parentPartCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          subNodes?: Array<{
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
        partImages?: Array<{
          __typename: 'PartImage';
          partImageId?: string | null;
          sortOrder?: number | null;
          isDefault?: boolean | null;
          document?: {
            __typename: 'Document';
            documentId: string;
            documentName?: string | null;
            documentIdentifier?: string | null;
            documentTypeId?: string | null;
            storagePath?: string | null;
            extension?: string | null;
            encodedUrn?: string | null;
            documentStatusTypeId?: string | null;
            isMappedFromShop?: boolean | null;
            folderDocumentResponse?: Array<{
              __typename: 'FolderDocument';
              folderDocumentId: string;
              folderId: string;
              folderName?: string | null;
              documentId: string;
            } | null> | null;
            documentNameFormatted?: string | null;
            createdOn?: string | null;
            createdBy?: string | null;
          } | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
        } | null> | null;
        documentIds?: Array<string | null> | null;
        unitOfMeasure?: {
          __typename: 'UnitOfMeasure';
          unitOfMeasureId: string;
          unitOfMeasureTypeId?: string | null;
          companyId?: string | null;
          unitOfMeasureName: string;
          unitOfMeasureCode: string;
          createdOn?: string | null;
          createdBy?: string | null;
          updatedOn?: string | null;
          modifiedBy?: string | null;
          modifiedOn?: string | null;
        } | null;
      } | null;
      shopTaskId?: string | null;
      assemblyNodeId?: string | null;
      historyTypeId?: string | null;
      assembly?: {
        __typename: 'Assembly';
        part?: {
          __typename: 'Part';
          partId: string;
          partName: string;
          description?: string | null;
          stateId?: string | null;
          isDefault?: boolean | null;
          manufacturerCatalogNumber?: string | null;
          unitOfMeasureCode?: string | null;
          unitOfMeasureName?: string | null;
          upcCode?: string | null;
          url?: string | null;
          catalogUrl?: string | null;
          partUrl?: string | null;
          internalPartNumber?: string | null;
          createdOn?: string | null;
          hasAssembly?: boolean | null;
          manufacturer?: {
            __typename: 'Manufactured';
            manufacturerId: string;
            companyId?: string | null;
            manufacturerName: string;
            homePageUrl?: string | null;
          } | null;
          attriubuteVariantData?: Array<{
            __typename: 'PartAttributeVariantData';
            partAttributeVariantDataId?: string | null;
            partAttributeId?: string | null;
            partAttributeName?: string | null;
            rangeValue?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            partAttribute?: {
              __typename: 'PartAttribute';
              partAttributeId: string;
              partAttributeName: string;
              allowWriteInVariant?: boolean | null;
              rangeMinimumValue?: number | null;
              rangeMaximumValue?: number | null;
            } | null;
            selectValues?: Array<{
              __typename: 'PartAttributeSelectVariant';
              partAttributeSelectVariantId?: string | null;
              textValue?: string | null;
              codeValue?: string | null;
              isDeleted?: boolean | null;
            } | null> | null;
          } | null> | null;
          partCategory?: {
            __typename: 'PartCategory';
            partCategoryId?: string | null;
            partCategoryName?: string | null;
            partCatalog?: {
              __typename: 'PartCatalog';
              partCatalogId: string;
              partCatalogName: string;
              companyId: string;
            } | null;
            parentPartCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            subNodes?: Array<{
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null> | null;
          } | null;
          partImages?: Array<{
            __typename: 'PartImage';
            partImageId?: string | null;
            sortOrder?: number | null;
            isDefault?: boolean | null;
            document?: {
              __typename: 'Document';
              documentId: string;
              documentName?: string | null;
              documentIdentifier?: string | null;
              documentTypeId?: string | null;
              storagePath?: string | null;
              extension?: string | null;
              encodedUrn?: string | null;
              documentStatusTypeId?: string | null;
              isMappedFromShop?: boolean | null;
              documentNameFormatted?: string | null;
              createdOn?: string | null;
              createdBy?: string | null;
            } | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
          } | null> | null;
          documentIds?: Array<string | null> | null;
          unitOfMeasure?: {
            __typename: 'UnitOfMeasure';
            unitOfMeasureId: string;
            unitOfMeasureTypeId?: string | null;
            companyId?: string | null;
            unitOfMeasureName: string;
            unitOfMeasureCode: string;
            createdOn?: string | null;
            createdBy?: string | null;
            updatedOn?: string | null;
            modifiedBy?: string | null;
            modifiedOn?: string | null;
          } | null;
        } | null;
        assemblyId?: string | null;
        historyTypeId?: string | null;
        assemblyNodes?: Array<{
          __typename: 'AssemblyNode';
          quantity?: number | null;
          part?: {
            __typename: 'Part';
            partId: string;
            partName: string;
            description?: string | null;
            stateId?: string | null;
            isDefault?: boolean | null;
            manufacturerCatalogNumber?: string | null;
            unitOfMeasureCode?: string | null;
            unitOfMeasureName?: string | null;
            upcCode?: string | null;
            url?: string | null;
            catalogUrl?: string | null;
            partUrl?: string | null;
            internalPartNumber?: string | null;
            createdOn?: string | null;
            hasAssembly?: boolean | null;
            manufacturer?: {
              __typename: 'Manufactured';
              manufacturerId: string;
              companyId?: string | null;
              manufacturerName: string;
              homePageUrl?: string | null;
            } | null;
            attriubuteVariantData?: Array<{
              __typename: 'PartAttributeVariantData';
              partAttributeVariantDataId?: string | null;
              partAttributeId?: string | null;
              partAttributeName?: string | null;
              rangeValue?: number | null;
            } | null> | null;
            partCategory?: {
              __typename: 'PartCategory';
              partCategoryId?: string | null;
              partCategoryName?: string | null;
            } | null;
            partImages?: Array<{
              __typename: 'PartImage';
              partImageId?: string | null;
              sortOrder?: number | null;
              isDefault?: boolean | null;
            } | null> | null;
            documentIds?: Array<string | null> | null;
            unitOfMeasure?: {
              __typename: 'UnitOfMeasure';
              unitOfMeasureId: string;
              unitOfMeasureTypeId?: string | null;
              companyId?: string | null;
              unitOfMeasureName: string;
              unitOfMeasureCode: string;
              createdOn?: string | null;
              createdBy?: string | null;
              updatedOn?: string | null;
              modifiedBy?: string | null;
              modifiedOn?: string | null;
            } | null;
          } | null;
          shopTaskId?: string | null;
          assemblyNodeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyNodeType?: {
            __typename: 'AssemblyNodeType';
            assemblyNodeTypeId?: string | null;
            assemblyNodeTypeName?: string | null;
          } | null;
          assemblyNodeName?: string | null;
          assemblyNodeDescription?: string | null;
          positionX?: number | null;
          positionY?: number | null;
        } | null> | null;
        assemblyEdges?: Array<{
          __typename: 'AssemblyEdge';
          assemblyEdgeId?: string | null;
          historyTypeId?: string | null;
          assembly?: {
            __typename: 'Assembly';
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            assemblyId?: string | null;
            historyTypeId?: string | null;
            assemblyNodes?: Array<{
              __typename: 'AssemblyNode';
              quantity?: number | null;
              shopTaskId?: string | null;
              assemblyNodeId?: string | null;
              historyTypeId?: string | null;
              assemblyNodeName?: string | null;
              assemblyNodeDescription?: string | null;
              positionX?: number | null;
              positionY?: number | null;
            } | null> | null;
            assemblyEdges?: Array<{
              __typename: 'AssemblyEdge';
              assemblyEdgeId?: string | null;
              historyTypeId?: string | null;
            } | null> | null;
          } | null;
          assemblyEdgeType?: {
            __typename: 'AssemblyEdgeType';
            assemblyEdgeTypeId?: string | null;
            assemblyEdgeTypeName?: string | null;
          } | null;
          beginHandlePosition?: {
            __typename: 'AssemblyHandlePosition';
            assemblyHandlePositionId?: string | null;
            assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
          } | null;
          endHandlePosition?: {
            __typename: 'AssemblyHandlePosition';
            assemblyHandlePositionId?: string | null;
            assemblyHandlePositionName?: ASSEMBLY_HANDLE_POSITION_NAME | null;
          } | null;
          beginNode?: {
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null;
          endNode?: {
            __typename: 'AssemblyNode';
            quantity?: number | null;
            part?: {
              __typename: 'Part';
              partId: string;
              partName: string;
              description?: string | null;
              stateId?: string | null;
              isDefault?: boolean | null;
              manufacturerCatalogNumber?: string | null;
              unitOfMeasureCode?: string | null;
              unitOfMeasureName?: string | null;
              upcCode?: string | null;
              url?: string | null;
              catalogUrl?: string | null;
              partUrl?: string | null;
              internalPartNumber?: string | null;
              createdOn?: string | null;
              hasAssembly?: boolean | null;
              documentIds?: Array<string | null> | null;
            } | null;
            shopTaskId?: string | null;
            assemblyNodeId?: string | null;
            historyTypeId?: string | null;
            assembly?: {
              __typename: 'Assembly';
              assemblyId?: string | null;
              historyTypeId?: string | null;
            } | null;
            assemblyNodeType?: {
              __typename: 'AssemblyNodeType';
              assemblyNodeTypeId?: string | null;
              assemblyNodeTypeName?: string | null;
            } | null;
            assemblyNodeName?: string | null;
            assemblyNodeDescription?: string | null;
            positionX?: number | null;
            positionY?: number | null;
          } | null;
        } | null> | null;
      } | null;
      assemblyNodeType?: {
        __typename: 'AssemblyNodeType';
        assemblyNodeTypeId?: string | null;
        assemblyNodeTypeName?: string | null;
      } | null;
      assemblyNodeName?: string | null;
      assemblyNodeDescription?: string | null;
      positionX?: number | null;
      positionY?: number | null;
    } | null;
  } | null;
};

export type SyncLogsQueryVariables = {
  query?: QuerySyncLogsQueryInput | null;
};

export type SyncLogsQuery = {
  syncLogs?: Array<{
    __typename: 'SyncLogFile';
    workflowName?: string | null;
    workflowId?: string | null;
    totalFiles?: number | null;
    successfulFilesCount?: number | null;
    mappings?: Array<{
      __typename: 'SyncLogMapping';
      mappingName?: string | null;
      mappingId?: string | null;
      totalFiles?: number | null;
      successfulFilesCount?: number | null;
      solitaryFlows?: Array<{
        __typename: 'SolitaryFlow';
        fileSyncLogId?: string | null;
        source?: {
          __typename: 'SyncLogNode';
          name?: string | null;
          type?: string | null;
        } | null;
        syncStatus?: number | null;
        syncType?: number | null;
        totalFiles?: number | null;
        totalSize?: string | null;
        successfulFilesCount?: number | null;
        actionAlias?: string | null;
        destinations?: Array<{
          __typename: 'SyncLogNode';
          name?: string | null;
          type?: string | null;
        } | null> | null;
        lastSyncTime?: string | null;
        isContactSupportTeam?: boolean | null;
      } | null> | null;
      publishModelSolitaryFlows?: Array<{
        __typename: 'SolitaryFlow';
        fileSyncLogId?: string | null;
        source?: {
          __typename: 'SyncLogNode';
          name?: string | null;
          type?: string | null;
        } | null;
        syncStatus?: number | null;
        syncType?: number | null;
        totalFiles?: number | null;
        totalSize?: string | null;
        successfulFilesCount?: number | null;
        actionAlias?: string | null;
        destinations?: Array<{
          __typename: 'SyncLogNode';
          name?: string | null;
          type?: string | null;
        } | null> | null;
        lastSyncTime?: string | null;
        isContactSupportTeam?: boolean | null;
      } | null> | null;
      lastSyncTime?: string | null;
    } | null> | null;
    lastSyncTime?: string | null;
  } | null> | null;
};

export type SyncRealTimeLogsQueryVariables = {
  query?: QuerySyncRealTimeLogsQueryInput | null;
};

export type SyncRealTimeLogsQuery = {
  syncRealTimeLogs?: Array<{
    __typename: 'SyncRealTimeLogFile';
    date?: string | null;
    files?: Array<{
      __typename: 'SyncRealTimeLogFileDetails';
      fileName?: string | null;
      fileSyncTime?: string | null;
      solitaryFlows?: Array<{
        __typename: 'SyncRealTimeLogFileFlowDetails';
        workflowId?: string | null;
        workflowName?: string | null;
        mappingId?: string | null;
        mappingName?: string | null;
        actionAlias?: string | null;
        source?: string | null;
        sourcePath?: string | null;
        destinations?: Array<{
          __typename: 'SyncRealTimeLogFileFlowDestination';
          fileSyncLogId?: string | null;
          destination?: string | null;
          destinationPath?: string | null;
          syncStatus?: string | null;
          isContactSupportTeam?: boolean | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null> | null;
};

export type SyncLogFileDetailsQueryVariables = {
  query?: QuerySyncLogFileDetailsQueryInput | null;
};

export type SyncLogFileDetailsQuery = {
  syncLogFileDetails?: Array<string | null> | null;
};

export type SyncLogActionDetailsQueryVariables = {
  query?: QuerySyncLogActionDetailsQueryInput | null;
};

export type SyncLogActionDetailsQuery = {
  syncLogActionDetails?: {
    __typename: 'SyncLogActionDetails';
    workflowId?: string | null;
    workflowName?: string | null;
    mappingId?: string | null;
    mappingName?: string | null;
    actionAlias?: string | null;
    syncStatus?: number | null;
    data?: {
      __typename: 'SyncLogActionDetailsData';
      destinations?: Array<{
        __typename: 'SyncLogActionDetailsDestination';
        syncFlowMetricId?: string | null;
        name?: string | null;
        terminalStatus?: number | null;
        publishModelSuccess?: string | null;
        publishModelMessage?: string | null;
        issueManagementCount?: number | null;
        numFiles?: number | null;
        totalSize?: string | null;
      } | null> | null;
      isComplete?: boolean | null;
      isPublishModel?: boolean | null;
      isIssueManagement?: boolean | null;
    } | null;
  } | null;
};

export type SyncLogActionFolderDetailsQueryVariables = {
  query?: QuerySyncLogActionFolderDetailsQueryInput | null;
};

export type SyncLogActionFolderDetailsQuery = {
  syncLogActionFolderDetails?: Array<{
    __typename: 'SyncLogActionFolderDetails';
    syncFolderMetricId?: string | null;
    name?: string | null;
    numFiles?: number | null;
    numSubFolders?: number | null;
    terminalStatus?: number | null;
    removeFilesNotInSourceFailed?: boolean | null;
    removeSubFoldersNotInSourceFailed?: boolean | null;
    skippedFilesDueToLimit?: boolean | null;
    skippedSubFoldersDueToDepth?: boolean | null;
    totalSize?: string | null;
  } | null> | null;
};

export type SyncLogActionFileDetailsQueryVariables = {
  query?: QuerySyncLogActionFileDetailsQueryInput | null;
};

export type SyncLogActionFileDetailsQuery = {
  syncLogActionFileDetails?: Array<{
    __typename: 'SyncLogActionFileDetails';
    name?: string | null;
    fileSyncDate?: string | null;
    terminalStatus?: number | null;
    totalSize?: string | null;
  } | null> | null;
};

export type GetAutodeskGlueProjectsQueryVariables = {
  query?: QueryGetAutodeskGlueProjectsQueryInput | null;
};

export type GetAutodeskGlueProjectsQuery = {
  getAutodeskGlueProjects?: Array<{
    __typename: 'AutodeskGlueProject';
    id: string;
    text?: string | null;
  } | null> | null;
};

export type GetAutodeskGlueFoldersQueryVariables = {
  query?: QueryGetAutodeskGlueFoldersQueryInput | null;
};

export type GetAutodeskGlueFoldersQuery = {
  getAutodeskGlueFolders?: Array<{
    __typename: 'AutodeskGlueFolder';
    id: string;
    text?: string | null;
    isFolder?: boolean | null;
  } | null> | null;
};

export type GetAutodeskGlueSelectedFolderIdStackQueryVariables = {
  query?: QueryGetAutodeskGlueSelectedFolderIdStackQueryInput | null;
};

export type GetAutodeskGlueSelectedFolderIdStackQuery = {
  getAutodeskGlueSelectedFolderIdStack?: Array<string | null> | null;
};

export type GetAutodeskGlueFolderNameQueryVariables = {
  query?: QueryGetAutodeskGlueFolderNameQueryInput | null;
};

export type GetAutodeskGlueFolderNameQuery = {
  getAutodeskGlueFolderName?: {
    __typename: 'AutodeskGlueFolderName';
    name?: string | null;
  } | null;
};

export type GetSharepointSitesQueryVariables = {
  query?: QueryGetSharepointSitesQueryInput | null;
};

export type GetSharepointSitesQuery = {
  getSharepointSites?: Array<{
    __typename: 'SharepointSite';
    id: string;
    text?: string | null;
  } | null> | null;
};

export type GetSharepointFoldersQueryVariables = {
  query?: QueryGetSharepointFoldersQueryInput | null;
};

export type GetSharepointFoldersQuery = {
  getSharepointFolders?: Array<{
    __typename: 'SharepointFolder';
    id: string;
    text?: string | null;
    isFolder?: boolean | null;
  } | null> | null;
};

export type GetSharepointSelectedFolderIdStackQueryVariables = {
  query?: QueryGetSharepointSelectedFolderIdStackQueryInput | null;
};

export type GetSharepointSelectedFolderIdStackQuery = {
  getSharepointSelectedFolderIdStack?: Array<string | null> | null;
};

export type GetSharepointFolderNameQueryVariables = {
  query?: QueryGetSharepointFolderNameQueryInput | null;
};

export type GetSharepointFolderNameQuery = {
  getSharepointFolderName?: {
    __typename: 'SharepointFolderName';
    success?: boolean | null;
    name?: string | null;
  } | null;
};

export type GetNewformaProjectsQueryVariables = {
  query?: QueryGetNewformaProjectsQueryInput | null;
};

export type GetNewformaProjectsQuery = {
  getNewformaProjects?: Array<{
    __typename: 'NewformaProject';
    id: string;
    text?: string | null;
  } | null> | null;
};

export type GetNewformaFoldersQueryVariables = {
  query?: QueryGetNewformaFoldersQueryInput | null;
};

export type GetNewformaFoldersQuery = {
  getNewformaFolders?: Array<{
    __typename: 'NewformaFolder';
    id: string;
    text?: string | null;
  } | null> | null;
};

export type GetNewformaSelectedFolderIdStackQueryVariables = {
  query?: QueryGetNewformaSelectedFolderIdStackQueryInput | null;
};

export type GetNewformaSelectedFolderIdStackQuery = {
  getNewformaSelectedFolderIdStack?: Array<string | null> | null;
};

export type GetNewformaFolderNameQueryVariables = {
  query?: QueryGetNewformaFolderNameQueryInput | null;
};

export type GetNewformaFolderNameQuery = {
  getNewformaFolderName?: {
    __typename: 'NewformaFolderName';
    success?: boolean | null;
    name?: string | null;
  } | null;
};

export type GetPlangridProjectsQueryVariables = {
  query?: QueryGetPlangridProjectsQueryInput | null;
};

export type GetPlangridProjectsQuery = {
  getPlangridProjects?: Array<{
    __typename: 'PlangridProject';
    id: string;
    text?: string | null;
  } | null> | null;
};

export type GetPlangridFoldersQueryVariables = {
  query?: QueryGetPlangridFoldersQueryInput | null;
};

export type GetPlangridFoldersQuery = {
  getPlangridFolders?: Array<{
    __typename: 'PlangridFolder';
    id: string;
    text?: string | null;
  } | null> | null;
};

export type GetPlangridSelectedFolderIdStackQueryVariables = {
  query?: QueryGetPlangridSelectedFolderIdStackQueryInput | null;
};

export type GetPlangridSelectedFolderIdStackQuery = {
  getPlangridSelectedFolderIdStack?: Array<string | null> | null;
};

export type GetPlangridFolderNameQueryVariables = {
  query?: QueryGetPlangridFolderNameQueryInput | null;
};

export type GetPlangridFolderNameQuery = {
  getPlangridFolderName?: {
    __typename: 'PlangridFolderName';
    success?: boolean | null;
    name?: string | null;
  } | null;
};

export type SyncGetWorkflowMappingSyncStatusQueryVariables = {
  query?: QuerySyncGetWorkflowMappingSyncStatusQueryInput | null;
};

export type SyncGetWorkflowMappingSyncStatusQuery = {
  syncGetWorkflowMappingSyncStatus?: {
    __typename: 'SyncStatus';
    isSyncRunning?: boolean | null;
    lastSyncAt?: string | null;
    solitaryFlows?: Array<{
      __typename: 'SolitaryFlowStatus';
      id: string;
      actionNodeId?: string | null;
      syncStatus?: number | null;
    } | null> | null;
  } | null;
};

export type AssemblyCustomCategoriesQueryVariables = {
  query?: QueryAssemblyCustomCategoriesQueryInput | null;
};

export type AssemblyCustomCategoriesQuery = {
  assemblyCustomCategories?: Array<{
    __typename: 'AssemblyCustomCategory';
    assemblyCustomCategoryId: string;
    workFlowId?: string | null;
    companyId?: string | null;
    assemblyCustomCategoryName: string;
  } | null> | null;
};

export type AssemblyCustomCategoryByIdQueryVariables = {
  params: QueryAssemblyCustomCategoryByIdParamsInput;
};

export type AssemblyCustomCategoryByIdQuery = {
  assemblyCustomCategoryById?: {
    __typename: 'AssemblyCustomCategory';
    assemblyCustomCategoryId: string;
    workFlowId?: string | null;
    companyId?: string | null;
    assemblyCustomCategoryName: string;
  } | null;
};

export type SyncFetchForesiteCompaniesQueryVariables = {
  query?: QuerySyncFetchForesiteCompaniesQueryInput | null;
};

export type SyncFetchForesiteCompaniesQuery = {
  syncFetchForesiteCompanies?: Array<{
    __typename: 'ForesiteCompany';
    id: string;
    text?: string | null;
  } | null> | null;
};

export type SyncFetchForesiteProjectsQueryVariables = {
  query?: QuerySyncFetchForesiteProjectsQueryInput | null;
};

export type SyncFetchForesiteProjectsQuery = {
  syncFetchForesiteProjects?: Array<{
    __typename: 'ForesiteProject';
    id: string;
    text?: string | null;
  } | null> | null;
};

export type SyncFetchForesiteFoldersQueryVariables = {
  query?: QuerySyncFetchForesiteFoldersQueryInput | null;
};

export type SyncFetchForesiteFoldersQuery = {
  syncFetchForesiteFolders?: Array<{
    __typename: 'ForesiteFolder';
    id: string;
    text?: string | null;
    isFolder?: boolean | null;
  } | null> | null;
};

export type SyncFetchForesiteSelectedFolderIdStackQueryVariables = {
  query?: QuerySyncFetchForesiteSelectedFolderIdStackQueryInput | null;
};

export type SyncFetchForesiteSelectedFolderIdStackQuery = {
  syncFetchForesiteSelectedFolderIdStack?: Array<string | null> | null;
};

export type CheckCustomAssemblyNameUniqueQueryVariables = {
  query?: QueryCheckCustomAssemblyNameUniqueQueryInput | null;
};

export type CheckCustomAssemblyNameUniqueQuery = {
  checkCustomAssemblyNameUnique?: {
    __typename: 'CheckPropertyUnique';
    isUnique?: boolean | null;
  } | null;
};

export type CheckCustomAssemblyIdentifierUniqueQueryVariables = {
  query?: QueryCheckCustomAssemblyIdentifierUniqueQueryInput | null;
};

export type CheckCustomAssemblyIdentifierUniqueQuery = {
  checkCustomAssemblyIdentifierUnique?: {
    __typename: 'CheckPropertyUnique';
    isUnique?: boolean | null;
  } | null;
};

export type CustomAssembliesQueryVariables = {
  query?: QueryCustomAssembliesQueryInput | null;
};

export type CustomAssembliesQuery = {
  customAssemblies?: Array<{
    __typename: 'CustomAssembly';
    lineItemId?: string | null;
    lineItemTypeId?: string | null;
    lineItemTypeName?: string | null;
    projectId?: string | null;
    customAssemblyName?: string | null;
    customAssemblyIdentifier?: string | null;
    assemblyCustomCategoryId?: string | null;
    assemblyCustomCategoryName?: string | null;
    customAssemblyStatus?: string | null;
    assignedto?: string | null;
    assignedtoFirstName?: string | null;
    assignedtoLastName?: string | null;
    assignedtoEmail?: string | null;
    quantity?: number | null;
    createdOn?: string | null;
    createdBy?: string | null;
    createdByFirstName?: string | null;
    createdByLastName?: string | null;
    createdByEmail?: string | null;
    hasChildren?: boolean | null;
  } | null> | null;
};

export type SyncGetWindowsFoldersQueryVariables = {
  query?: QuerySyncGetWindowsFoldersQueryInput | null;
};

export type SyncGetWindowsFoldersQuery = {
  syncGetWindowsFolders?: Array<{
    __typename: 'WindowsFolder';
    id: string;
    text?: string | null;
    isFolder?: boolean | null;
  } | null> | null;
};

export type SyncValidateNameQueryVariables = {
  params: QuerySyncValidateNameParamsInput;
  query?: QuerySyncValidateNameQueryInput | null;
};

export type SyncValidateNameQuery = {
  syncValidateName?: boolean | null;
};

export type PlatformSettingsQueryVariables = {
  query?: QueryPlatformSettingsQueryInput | null;
};

export type PlatformSettingsQuery = {
  platformSettings?: Array<{
    __typename: 'PlatformSetting';
    platformSettingId?: string | null;
    platformSettingName?: string | null;
    platformSettingValue?: string | null;
  } | null> | null;
};

export type SyncRecommendedScheduleTimesQueryVariables = {
  query?: QuerySyncRecommendedScheduleTimesQueryInput | null;
};

export type SyncRecommendedScheduleTimesQuery = {
  syncRecommendedScheduleTimes?: Array<string | null> | null;
};

export type AddedProjectSubscription = {
  addedProject?: {
    __typename: 'Project';
    projectId: string;
    projectName?: string | null;
    projectIdentifier?: string | null;
    dataAnalytics?: boolean | null;
    units?: string | null;
    companyId?: string | null;
    address?: {
      __typename: 'Address';
      addressId?: string | null;
      addressLine1?: string | null;
      addressLine2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zip?: string | null;
    } | null;
    projectImageId?: string | null;
    disallowAutomaticDistributionQty?: boolean | null;
    projectType?: {
      __typename: 'ProjectType';
      projectTypeId?: string | null;
      projectTypeName?: string | null;
      companyId?: string | null;
    } | null;
  } | null;
};

export type AddedTaskSubscription = {
  addedTask?: {
    __typename: 'Task';
    taskId?: string | null;
    taskTypeId?: string | null;
    billOfProcessId?: string | null;
    workRequestItemId?: string | null;
    taskStatusTypeId?: string | null;
    taskPredecessorIds?: Array<{
      __typename: 'TaskPredecessor';
      taskId?: string | null;
      taskName?: string | null;
      taskStatusTypeId?: string | null;
    } | null> | null;
    taskPredecessorDeletedIds?: Array<string | null> | null;
    taskTypeIds?: Array<string | null> | null;
    taskName?: string | null;
    taskDescription?: string | null;
    taskStatusTypeName?: string | null;
    sortOrder?: number | null;
    isEnabled?: boolean | null;
    createdOn?: string | null;
    time?: {
      __typename: 'Time';
      totalTime?: string | null;
      events?: Array<{
        __typename: 'Event';
        taskStatusTypeId?: string | null;
        description?: string | null;
        timesStamp?: string | null;
        userId?: string | null;
      } | null> | null;
    } | null;
    hasDocuments?: boolean | null;
    hasDrawingRegisterDocuments?: boolean | null;
    hasCommonDocuments?: boolean | null;
    hasExternalDocuments?: boolean | null;
    quantity?: number | null;
    needBy?: string | null;
  } | null;
};

export type AddedMessageSubscription = {
  addedMessage?: {
    __typename: 'Message';
    messageId?: string | null;
    companyId?: string | null;
    messagePriorityId?: string | null;
    fromName?: string | null;
    subject?: string | null;
    body?: string | null;
    messagePriorityCode?: MESSAGE_PRIORITY_CODE | null;
    statusId?: string | null;
    statusName?: string | null;
    accessedOn?: string | null;
    createdOn?: string | null;
  } | null;
};

export type AddedWorkRequestSubscription = {
  addedWorkRequest?: {
    __typename: 'WorkRequest';
    workRequestId?: string | null;
    workRequestStatusId?: string | null;
    workRequestStatusName?: string | null;
    facilityId?: string | null;
    projectId?: string | null;
    projectIdentifier?: string | null;
    projectName?: string | null;
    workRequestIdentifier?: string | null;
    workRequestName?: string | null;
    needBy?: string | null;
    workRequestDescription?: string | null;
    facilityName?: string | null;
    workRequestStatusDescription?: string | null;
    lastStatusUpdate?: string | null;
    numberOfAttachments?: number | null;
  } | null;
};

export type AssignedEntitlementSubscription = {
  assignedEntitlement?: Array<{
    __typename: 'Entitlement';
    entitlementId: string;
    userId: string;
    isCheckedOut?: boolean | null;
    checkedOutEndDate?: string | null;
    secureData?: string | null;
    secureSignature?: string | null;
    licenseTypeCode?: LICENSE_CODE | null;
    productPool?: {
      __typename: 'ProductPool';
      productPoolId: string;
      poolQuantity?: number | null;
      productId?: string | null;
      bundleId?: string | null;
      type?: string | null;
      label?: string | null;
      poolAvailableQuantity?: number | null;
      renewalDate?: string | null;
      customData?: string | null;
      termDate?: string | null;
      startDate?: string | null;
      trialRenewalDate?: string | null;
      company?: {
        __typename: 'Company';
        companyId: string;
        companyName?: string | null;
        renewalDate?: string | null;
        mfA_Required?: boolean | null;
        data_Analytics?: boolean | null;
        companyDownloadPaths?: Array<{
          __typename: 'CompanyDownloadPath';
          document?: string | null;
          updateBy?: string | null;
          updatedOn?: string | null;
        } | null> | null;
        companyImageId?: string | null;
        address?: {
          __typename: 'Address';
          addressId?: string | null;
          addressLine1?: string | null;
          addressLine2?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
          zip?: string | null;
        } | null;
        productPool?: Array<{
          __typename: 'ProductPool';
          productPoolId: string;
          poolQuantity?: number | null;
          productId?: string | null;
          bundleId?: string | null;
          type?: string | null;
          label?: string | null;
          poolAvailableQuantity?: number | null;
          renewalDate?: string | null;
          customData?: string | null;
          termDate?: string | null;
          startDate?: string | null;
          trialRenewalDate?: string | null;
          company?: {
            __typename: 'Company';
            companyId: string;
            companyName?: string | null;
            renewalDate?: string | null;
            mfA_Required?: boolean | null;
            data_Analytics?: boolean | null;
            companyDownloadPaths?: Array<{
              __typename: 'CompanyDownloadPath';
              document?: string | null;
              updateBy?: string | null;
              updatedOn?: string | null;
            } | null> | null;
            companyImageId?: string | null;
            address?: {
              __typename: 'Address';
              addressId?: string | null;
              addressLine1?: string | null;
              addressLine2?: string | null;
              city?: string | null;
              state?: string | null;
              country?: string | null;
              zip?: string | null;
            } | null;
            productPool?: Array<{
              __typename: 'ProductPool';
              productPoolId: string;
              poolQuantity?: number | null;
              productId?: string | null;
              bundleId?: string | null;
              type?: string | null;
              label?: string | null;
              poolAvailableQuantity?: number | null;
              renewalDate?: string | null;
              customData?: string | null;
              termDate?: string | null;
              startDate?: string | null;
              trialRenewalDate?: string | null;
            } | null> | null;
          } | null;
          product?: {
            __typename: 'Product';
            productId: string;
            productName?: string | null;
            baseProductName?: string | null;
            isForesiteModule?: boolean | null;
            licenseTypeId?: string | null;
            licenseTypeName?: string | null;
            licenseClassId?: string | null;
            licenseClassName?: string | null;
            productNameFormatted?: string | null;
            onlyVisibleToMembers?: boolean | null;
            supportsTrial?: boolean | null;
            productLicenseTypes?: Array<{
              __typename: 'ProductLicenseType';
              productLicenseTypeId: string;
              productId: string;
              licenseTypeId: string;
            } | null> | null;
          } | null;
        } | null> | null;
      } | null;
      product?: {
        __typename: 'Product';
        productId: string;
        productName?: string | null;
        baseProductName?: string | null;
        isForesiteModule?: boolean | null;
        licenseTypeId?: string | null;
        licenseTypeName?: string | null;
        licenseClassId?: string | null;
        licenseClassName?: string | null;
        productNameFormatted?: string | null;
        onlyVisibleToMembers?: boolean | null;
        supportsTrial?: boolean | null;
        productLicenseTypes?: Array<{
          __typename: 'ProductLicenseType';
          productLicenseTypeId: string;
          productId: string;
          licenseTypeId: string;
          licenseType?: {
            __typename: 'LicenseType';
            licenseTypeId: string;
            licenseTypeName: string;
            licenseTypeCode?: LICENSE_CODE | null;
            sortOrder?: number | null;
          } | null;
        } | null> | null;
      } | null;
    } | null;
  } | null> | null;
};

export type UnassignedEntitlementSubscription = {
  unassignedEntitlement?: {
    __typename: 'Entitlement';
    entitlementId: string;
    userId: string;
    isCheckedOut?: boolean | null;
    checkedOutEndDate?: string | null;
    secureData?: string | null;
    secureSignature?: string | null;
    licenseTypeCode?: LICENSE_CODE | null;
    productPool?: {
      __typename: 'ProductPool';
      productPoolId: string;
      poolQuantity?: number | null;
      productId?: string | null;
      bundleId?: string | null;
      type?: string | null;
      label?: string | null;
      poolAvailableQuantity?: number | null;
      renewalDate?: string | null;
      customData?: string | null;
      termDate?: string | null;
      startDate?: string | null;
      trialRenewalDate?: string | null;
      company?: {
        __typename: 'Company';
        companyId: string;
        companyName?: string | null;
        renewalDate?: string | null;
        mfA_Required?: boolean | null;
        data_Analytics?: boolean | null;
        companyDownloadPaths?: Array<{
          __typename: 'CompanyDownloadPath';
          document?: string | null;
          updateBy?: string | null;
          updatedOn?: string | null;
        } | null> | null;
        companyImageId?: string | null;
        address?: {
          __typename: 'Address';
          addressId?: string | null;
          addressLine1?: string | null;
          addressLine2?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
          zip?: string | null;
        } | null;
        productPool?: Array<{
          __typename: 'ProductPool';
          productPoolId: string;
          poolQuantity?: number | null;
          productId?: string | null;
          bundleId?: string | null;
          type?: string | null;
          label?: string | null;
          poolAvailableQuantity?: number | null;
          renewalDate?: string | null;
          customData?: string | null;
          termDate?: string | null;
          startDate?: string | null;
          trialRenewalDate?: string | null;
          company?: {
            __typename: 'Company';
            companyId: string;
            companyName?: string | null;
            renewalDate?: string | null;
            mfA_Required?: boolean | null;
            data_Analytics?: boolean | null;
            companyDownloadPaths?: Array<{
              __typename: 'CompanyDownloadPath';
              document?: string | null;
              updateBy?: string | null;
              updatedOn?: string | null;
            } | null> | null;
            companyImageId?: string | null;
            address?: {
              __typename: 'Address';
              addressId?: string | null;
              addressLine1?: string | null;
              addressLine2?: string | null;
              city?: string | null;
              state?: string | null;
              country?: string | null;
              zip?: string | null;
            } | null;
            productPool?: Array<{
              __typename: 'ProductPool';
              productPoolId: string;
              poolQuantity?: number | null;
              productId?: string | null;
              bundleId?: string | null;
              type?: string | null;
              label?: string | null;
              poolAvailableQuantity?: number | null;
              renewalDate?: string | null;
              customData?: string | null;
              termDate?: string | null;
              startDate?: string | null;
              trialRenewalDate?: string | null;
            } | null> | null;
          } | null;
          product?: {
            __typename: 'Product';
            productId: string;
            productName?: string | null;
            baseProductName?: string | null;
            isForesiteModule?: boolean | null;
            licenseTypeId?: string | null;
            licenseTypeName?: string | null;
            licenseClassId?: string | null;
            licenseClassName?: string | null;
            productNameFormatted?: string | null;
            onlyVisibleToMembers?: boolean | null;
            supportsTrial?: boolean | null;
            productLicenseTypes?: Array<{
              __typename: 'ProductLicenseType';
              productLicenseTypeId: string;
              productId: string;
              licenseTypeId: string;
            } | null> | null;
          } | null;
        } | null> | null;
      } | null;
      product?: {
        __typename: 'Product';
        productId: string;
        productName?: string | null;
        baseProductName?: string | null;
        isForesiteModule?: boolean | null;
        licenseTypeId?: string | null;
        licenseTypeName?: string | null;
        licenseClassId?: string | null;
        licenseClassName?: string | null;
        productNameFormatted?: string | null;
        onlyVisibleToMembers?: boolean | null;
        supportsTrial?: boolean | null;
        productLicenseTypes?: Array<{
          __typename: 'ProductLicenseType';
          productLicenseTypeId: string;
          productId: string;
          licenseTypeId: string;
          licenseType?: {
            __typename: 'LicenseType';
            licenseTypeId: string;
            licenseTypeName: string;
            licenseTypeCode?: LICENSE_CODE | null;
            sortOrder?: number | null;
          } | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type UpdatedTaskSubscription = {
  updatedTask?: {
    __typename: 'Task';
    taskId?: string | null;
    taskTypeId?: string | null;
    billOfProcessId?: string | null;
    workRequestItemId?: string | null;
    taskStatusTypeId?: string | null;
    taskPredecessorIds?: Array<{
      __typename: 'TaskPredecessor';
      taskId?: string | null;
      taskName?: string | null;
      taskStatusTypeId?: string | null;
    } | null> | null;
    taskPredecessorDeletedIds?: Array<string | null> | null;
    taskTypeIds?: Array<string | null> | null;
    taskName?: string | null;
    taskDescription?: string | null;
    taskStatusTypeName?: string | null;
    sortOrder?: number | null;
    isEnabled?: boolean | null;
    createdOn?: string | null;
    time?: {
      __typename: 'Time';
      totalTime?: string | null;
      events?: Array<{
        __typename: 'Event';
        taskStatusTypeId?: string | null;
        description?: string | null;
        timesStamp?: string | null;
        userId?: string | null;
      } | null> | null;
    } | null;
    hasDocuments?: boolean | null;
    hasDrawingRegisterDocuments?: boolean | null;
    hasCommonDocuments?: boolean | null;
    hasExternalDocuments?: boolean | null;
    quantity?: number | null;
    needBy?: string | null;
  } | null;
};

export type UpdatedWorkRequestSubscription = {
  updatedWorkRequest?: {
    __typename: 'WorkRequest';
    workRequestId?: string | null;
    workRequestStatusId?: string | null;
    workRequestStatusName?: string | null;
    facilityId?: string | null;
    projectId?: string | null;
    projectIdentifier?: string | null;
    projectName?: string | null;
    workRequestIdentifier?: string | null;
    workRequestName?: string | null;
    needBy?: string | null;
    workRequestDescription?: string | null;
    facilityName?: string | null;
    workRequestStatusDescription?: string | null;
    lastStatusUpdate?: string | null;
    numberOfAttachments?: number | null;
  } | null;
};

export type UpdatedProjectSubscription = {
  updatedProject?: {
    __typename: 'Project';
    projectId: string;
    projectName?: string | null;
    projectIdentifier?: string | null;
    dataAnalytics?: boolean | null;
    units?: string | null;
    companyId?: string | null;
    address?: {
      __typename: 'Address';
      addressId?: string | null;
      addressLine1?: string | null;
      addressLine2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zip?: string | null;
    } | null;
    projectImageId?: string | null;
    disallowAutomaticDistributionQty?: boolean | null;
    projectType?: {
      __typename: 'ProjectType';
      projectTypeId?: string | null;
      projectTypeName?: string | null;
      companyId?: string | null;
    } | null;
  } | null;
};

export type UpdatedWorkRequestStatusSubscription = {
  updatedWorkRequestStatus?: {
    __typename: 'WorkRequestsStatus';
    workRequestStatusId: string;
    workRequestStatusTypeId: string;
    workRequestStatusTypeName?: string | null;
    workRequestStatusTypeDescription?: string | null;
  } | null;
};

export type UpdatedWorkOrderSubscriptionVariables = {
  workOrderId: string;
};

export type UpdatedWorkOrderSubscription = {
  updatedWorkOrder?: {
    __typename: 'WorkOrder';
    workOrderId?: string | null;
    workRequestId?: string | null;
    workOrderStatusTypeId?: string | null;
    workOrderStatusTypeName?: string | null;
    workOrderName?: string | null;
    workOrderDescription?: string | null;
    workOrderNumber?: string | null;
    workRequest?: {
      __typename: 'WorkRequest';
      workRequestId?: string | null;
      workRequestStatusId?: string | null;
      workRequestStatusName?: string | null;
      facilityId?: string | null;
      projectId?: string | null;
      projectIdentifier?: string | null;
      projectName?: string | null;
      workRequestIdentifier?: string | null;
      workRequestName?: string | null;
      needBy?: string | null;
      workRequestDescription?: string | null;
      facilityName?: string | null;
      workRequestStatusDescription?: string | null;
      lastStatusUpdate?: string | null;
      numberOfAttachments?: number | null;
    } | null;
    sortOrder?: number | null;
    createdBy?: string | null;
    lastStatusUpdate?: string | null;
    createdOn?: string | null;
    isStepFunctionProcessing?: boolean | null;
    stepFunctionProcessingMessage?: string | null;
    numberOfAttachments?: number | null;
    hasCustomWorkOrderName?: boolean | null;
  } | null;
};
