export const getUomOptions = (uoms) =>
  uoms?.unitOfMeasure?.map((uom) => ({
    label: uom.unitOfMeasureName,
    id: uom.unitOfMeasureId,
    code: uom.unitOfMeasureCode,
    type: uom.unitOfMeasureTypeId,
  }));

export const getUomNames = (uoms) =>
  uoms?.unitOfMeasure?.map((uom) => uom.unitOfMeasureName.toLowerCase());

export const getUomCodes = (uoms) =>
  uoms?.unitOfMeasure?.map((uom) => uom.unitOfMeasureCode.toLowerCase());

export const getUomOptionsToSelect = (uoms) =>
  uoms?.unitOfMeasure?.map((uom) => ({
    label: uom.unitOfMeasureCode,
    value: uom.unitOfMeasureId,
  }));
