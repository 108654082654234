import React from 'react';

import TextField from '@mui/material/TextField';

// this must be defined on this level for disableCloseOnSelect to work
const InputComponent = React.forwardRef(({ ...other }, ref) => <div ref={ref} {...other} />);

const OutlinedWrapper = ({ children, label, error, color, sx }) => (
  <StyledTextField
    variant="outlined"
    label={label}
    error={!!error}
    multiline
    InputProps={{
      inputComponent: InputComponent,
    }}
    // eslint-disable-next-line react/jsx-no-duplicate-props
    inputProps={{ children }}
    color={color}
    style={{
      width: '100%',
    }}
    sx={sx}
    helperText={error?.message}
  />
);

const StyledTextField = ({ sx, ...props }) => (
  <TextField sx={{ '& .MuiOutlinedInput-root': { padding: '8px 16px' }, ...sx }} {...props} />
);

export { OutlinedWrapper };
