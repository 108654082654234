import { ComponentProps, useEffect, useMemo } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { Outlet, Navigate } from 'react-router';

import { useModule } from 'app/Modules/ModuleContext';
import { PRODUCT_IDS } from 'constants/globalConstants';
import { isNil } from 'helpers/isNotNil';
import { useEntitlementGet } from 'hooks-api/useEntitlementGet';
import useSetupModule from 'hooks/useSetupModule';

import HeaderBar from './HeaderBar';
import MessageAlertBanner from './MessageAlertBanner/MessageAlertBanner';
import DesktopNavBar from './NavBar/DesktopNavBar';

const electricalOrMechanicalProducts: string[] = [PRODUCT_IDS.ELECTRICAL_LICENSE, PRODUCT_IDS.MECHANICAL_LICENSE];

const Layout = () => {
  const { setSelectedModule, module } = useSetupModule();
  const { data: entitlements, loading: loadingEntitlements } = useEntitlementGet();
  const { data: navModules, loading: loadingModules } = useModule();

  const moduleIsEnabled = useMemo(
    () => navModules.some(({ navigationModuleCode }) => navigationModuleCode === module),
    [navModules, module],
  );

  const electricalOrMechanicalLicense = useMemo(
    () => entitlements?.data.some((e) => electricalOrMechanicalProducts.includes(e.productPool.productId)),
    [entitlements],
  );

  useEffect(() => {
    if (navModules?.length && !module) setSelectedModule(navModules[0]?.navigationModuleCode);
  }, [navModules, setSelectedModule, module]);

  if (loadingModules) return <CircularProgress />;
  if ((isNil(navModules) || navModules.length === 0) && loadingEntitlements) return <CircularProgress />;

  if (navModules?.length === 0 && electricalOrMechanicalLicense) {
    return <Navigate to="reset-password-landing" />;
  }

  if (!moduleIsEnabled) return <Navigate to="/no-licenses" />;

  return (
    <>
      <HeaderBar />
      <StyledContent>
        <DesktopNavBar />
        <FlexCenteredContainer>
          <MessageAlertBanner />
          <Outlet />
        </FlexCenteredContainer>
      </StyledContent>
    </>
  );
};

export default Layout;

const StyledContent = (props?: ComponentProps<typeof Stack>) => (
  <Stack sx={{ flexDirection: 'row', height: 'calc(100% - 64px)' }} {...props} />
);

const FlexCenteredContainer = (props?: ComponentProps<typeof Stack>) => (
  <Stack
    sx={{
      flexDirection: 'row',
      height: 'calc(100vh - 64px)',
      width: 'calc(100% - 64px)',
      marginTop: '64px',
      marginLeft: '64px',
      justifyContent: 'center',
      position: 'relative',
    }}
    {...props}
  />
);
