import React, { useState } from 'react';

import { Stack, Divider, Box } from '@mui/material';
import { useParams } from 'react-router-dom';

import DeleteModal from 'components/DeleteModal';
import { POSITION_TITLE } from 'constants/globalConstants';
import { convertURL } from 'helpers/urlFunctions';
import { greyColors } from 'styles/theme/colors';

import { useTaskViewerContext } from '../../../TaskViewerContext';
import { TaskWrWoAttachmentsDocument } from '../../types/Document';
import DownloadButton from '../DownloadButton';
import LinkButton from '../LinkButton';
import CustomTrashComponent from './CustomTrashComponent';
import GetDateAndCreatedBy from './GetDateAndCreatedBy';
import useHandleDownloadSingleFile from './hooks/useHandleDownloadSingleFile';
import useHandleViewDocument from './hooks/useHandleViewDocument';
import OverFlowComponent from './OverFlowComponent';
import RowComponent from './RowComponent';

const FileRowComponent = ({ document, section }: TaskWrWoAttachmentsDocument) => {
  const { documentName, taskDocumentId, externalUrl, createdOn, createdBy } = document;

  const [showTrashIcon, setShowTrashIcon] = useState(false);
  const [isTrashHovered, setIsTrashHovered] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { handleDownloadSingleFile } = useHandleDownloadSingleFile();
  const { handleViewDocument } = useHandleViewDocument({ section });
  const { id: taskId } = useParams();

  const { removeTaskDocument, activeDocument, handleSelectNextDetailedDoc } = useTaskViewerContext();

  const handleLinkButton = async (externalUrl: string) => {
    const url = convertURL(externalUrl);
    window.open(url, '_blank');
  };

  const handleDelete = async (taskDocumentId: string, event: any) => {
    const response = await removeTaskDocument(taskDocumentId, taskId);

    if (response?.data?.deleteTaskDocument) {
      const isActiveDoc = !document?.externalUrl
        ? document.documentId === activeDocument?.documentId
        : document.externalUrl === activeDocument?.externalUrl && document.createdOn === activeDocument?.createdOn;

      if (isActiveDoc) handleSelectNextDetailedDoc();
    }

    setIsModalOpen(false);
    event.preventDefault();
  };

  const handleOpenModal = () => setIsModalOpen(true);

  const handleCloseModal = () => {
    setShowTrashIcon(false);
    setIsModalOpen(false);
  };

  const handleOnMouseEnter = () => {
    if (section === POSITION_TITLE.tasks) setShowTrashIcon(true);
  };

  const isSelectedDocument = activeDocument?.documentId === document?.documentId;

  const selectDocument = () => handleViewDocument(document);

  return (
    <Stack onMouseEnter={handleOnMouseEnter} onMouseLeave={() => setShowTrashIcon(false)}>
      <RowComponent>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            padding: '5px 0px',
            backgroundColor: isSelectedDocument ? greyColors.grey100 : 'transparent',
            opacity: isTrashHovered ? 0.2 : 1,
          }}
        >
          {externalUrl ? (
            <LinkButton onClick={() => handleLinkButton(externalUrl)} />
          ) : (
            <DownloadButton onClick={() => handleDownloadSingleFile(document)} />
          )}

          <OverFlowComponent
            selectDocument={selectDocument}
            externalUrl={externalUrl}
            documentName={documentName}
            isSelectedDocument={isSelectedDocument}
          />

          <GetDateAndCreatedBy createdOn={createdOn} createdBy={createdBy} />
        </Box>
        <CustomTrashComponent
          isHovered={showTrashIcon}
          handleOpenModal={handleOpenModal}
          onMouseEnter={() => setIsTrashHovered(true)}
          onMouseLeave={() => setIsTrashHovered(false)}
        />
      </RowComponent>
      <Divider light sx={{ mr: 2 }} />
      <DeleteModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onSubmit={(event: HTMLButtonElement) => handleDelete(taskDocumentId, event)}
        itemName={documentName}
        paperSx={{ maxWidth: 505 }}
        confirmText={`Are you sure you want to delete ${externalUrl ? 'this URL Link' : ''}`}
      />
    </Stack>
  );
};

export default FileRowComponent;
