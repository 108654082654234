import React from 'react';

import { useQuery, gql } from '@apollo/client';

import { useUser } from 'app/UserContext';
import ControlledChipsAutoComplete from 'components/FormComponents/ControlledChipsAutoComplete';
import { users as USERS } from 'graphql/queries';

const ShopInviteForm = ({ control, setLoadingUsers }) => {
  const { user } = useUser();
  const { data, loading, error } = useQuery(gql(USERS), {
    variables: {
      query: {
        companyId: user.companyId,
        orderBy: 'email:asc',
      },
    },
  });
  setLoadingUsers(loading);

  if (loading || !data) return <form />;
  if (error) return <p>{error.message}</p>;

  const users = data?.users || [];

  const formattedOptions = users.map(({ userEmail, userId }) => ({ label: userEmail, id: userId }));

  return (
    <form>
      <ControlledChipsAutoComplete
        options={formattedOptions}
        control={control}
        name="newShopUsers"
        rules={{ required: true }}
        label="Email or Name"
        defaultValue={[]}
        placeholder="Search or add email"
      />
    </form>
  );
};

export default ShopInviteForm;
