import { useCallback, useContext, useMemo, useEffect } from 'react';

import { EditQuantityModalContext } from 'modules/Materials/TakeOff/TakeOffPadTab/EditQuantityModal/contexts/EditQuantityModalContext';

import { useFilterOptions } from '../../features/filters/options';
import { useRulesFilter } from '../../features/filters/rules';
import { useSourceDataOption } from '../../features/filters/source-data';

export const useChipsFilterHelpers = (contextHook) => {
  const { chipList, updateChipList } = contextHook();
  const editQuantityModalContext = useContext(EditQuantityModalContext);
  const { setSelectedIdsForFilter, removeIdsClickedInChip } = editQuantityModalContext || {};
  const { onClearQuery: onClearFilterOptionsQuery, onlyActive: onlyOptionsActive, setShowFilters } = useFilterOptions();
  const { onClearQuery: onClearRulesFilterQuery, encodedQuery: encodedRulesQuery } = useRulesFilter();
  const { onClearQuery: onClearSourceDataQuery, query: querySourceDataQuery } = useSourceDataOption();

  const onClearFilters = useCallback(() => {
    updateChipList([]);
    if (setSelectedIdsForFilter) setSelectedIdsForFilter([]);
    onClearFilterOptionsQuery();
    onClearRulesFilterQuery();
    onClearSourceDataQuery();
  }, [
    updateChipList,
    onClearFilterOptionsQuery,
    onClearRulesFilterQuery,
    onClearSourceDataQuery,
    setSelectedIdsForFilter,
  ]);

  const onDeleteChip = useCallback(
    (id) => {
      if (removeIdsClickedInChip) removeIdsClickedInChip(id);
    },
    [removeIdsClickedInChip],
  );

  const hasFilters = useMemo(
    () =>
      Boolean(
        chipList.length ||
          onlyOptionsActive.length ||
          JSON.parse(decodeURIComponent(encodedRulesQuery)).length ||
          querySourceDataQuery.length,
      ),
    [chipList, onlyOptionsActive, encodedRulesQuery, querySourceDataQuery],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setShowFilters(hasFilters), [hasFilters]);

  return {
    onDeleteChip,
    onClearFilters,
    hasFilters,
  };
};
