import React from 'react';

import { InputForesiteField } from '@evolvemep/foresite-components-ui-react';

import SimpleControlledAutoComplete from 'components/FormComponents/SimpleControlledAutoComplete';

const roleOptions = [
  { label: 'eVolve Accounting', id: '05145251-f699-4fc6-908e-7fbf14609ac0' },
  { label: 'eVolve Consulting Manager', id: '1ed73b35-daea-47f6-a3d4-cf988395ed7e' },
  { label: 'eVolve CE Engineer', id: '20007b54-d69c-4414-8fd5-25fad6827423' },
  { label: 'eVolve Sales Rep', id: '46e39f0b-9df1-49c2-afa6-3b333f9e805c' },
  { label: 'eVolve Sales Support Engineer', id: '667a8577-4b54-434b-846d-8e2f4a626612' },
  { label: 'eVolve Entitlement Manager', id: '8ff7d0f7-761f-4af8-8a36-7ed5bca462b2' },
  { label: 'eVolve Administrator', id: '9e9bf6c2-5dbf-4cb2-b70e-7388e5346fef' },
  { label: 'eVolve Sales Manager', id: 'a7af18d9-0bdd-4c1b-bb24-469f0087fc43' },
];

const EvolveModalForm = ({ control }) => (
  <form>
    <InputForesiteField defaultValue="" size="small" name="email" label="Email" control={control} />
    <SimpleControlledAutoComplete
      options={roleOptions}
      control={control}
      defaultValue=""
      size="small"
      name="role"
      rules={{ required: true }}
      label="Role"
    />
  </form>
);

export default EvolveModalForm;
