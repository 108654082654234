import React from 'react';

import { useReactiveVar } from '@apollo/client';
import Box from '@mui/material/Box';

import { selectedMessageVar } from 'apollo/reactiveVars';
import CenteredLoadSpinner from 'components/CenteredLoadSpinner';
import { debounce } from 'helpers/common';

import {
  useMessagesContext,
  useActiveMessagesContext,
  useArchiveMessagesContext,
} from '../contexts';
import MessageListTabs from './MessageListTabs';
import MessageListActiveToolbar from './MessageListToolbar/MessageListActiveToolbar';
import MessageListTrashToolbar from './MessageListToolbar/MessageListTrashToolbar';
import MessagesList from './MessagesList';

const MessagesListBar = () => {
  const selectedMessage = useReactiveVar(selectedMessageVar);

  const {
    onSelectMessage,
    onArchiveMessage,
    onDeleteMessage,
    selectedMessagesTab,
    onChangeMessagesTab,
  } = useMessagesContext();

  const {
    messages: activeMessages,
    loading: loadingActiveMessages,
    onSearch: onSearchActiveMessages,
    onFetchMore: onFetchMoreActiveMessages,
  } = useActiveMessagesContext();
  const {
    messages: archiveMessages,
    loading: loadingArchiveMessages,
    onSearch: onSearchArchiveMessages,
    onFetchMore: onFetchMoreArchiveMessages,
  } = useArchiveMessagesContext();

  const handleMessagesTabChange = (_, tabIndex) => {
    onChangeMessagesTab(tabIndex);
  };

  const handleSearchActiveMessages = debounce(onSearchActiveMessages);
  const handleSearchArchiveMessages = debounce(onSearchArchiveMessages);

  return (
    <MessagesListBarWrapper>
      <MessageListTabs activeTab={selectedMessagesTab} onChange={handleMessagesTabChange} />

      <TabPanel value={selectedMessagesTab} index={0}>
        <MessageListActiveToolbar onSearch={handleSearchActiveMessages} />
        {loadingActiveMessages && <CenteredLoadSpinner />}
        <MessagesList
          height="calc(100% - 172px)"
          messages={activeMessages}
          selectedMessage={selectedMessage}
          onSelectMessage={onSelectMessage}
          onArchiveMessage={onArchiveMessage}
          onDeleteMessage={onDeleteMessage}
          onFetchMore={onFetchMoreActiveMessages}
        />
      </TabPanel>
      <TabPanel value={selectedMessagesTab} index={1}>
        <MessageListTrashToolbar onSearch={handleSearchArchiveMessages} />
        {loadingArchiveMessages && <CenteredLoadSpinner />}
        <MessagesList
          isTrash
          height="calc(100% - 178px)"
          messages={archiveMessages}
          selectedMessage={selectedMessage}
          onSelectMessage={onSelectMessage}
          onArchiveMessage={onArchiveMessage}
          onDeleteMessage={onDeleteMessage}
          onFetchMore={onFetchMoreArchiveMessages}
        />
      </TabPanel>
    </MessagesListBarWrapper>
  );
};

export default MessagesListBar;

const MessagesListBarWrapper = ({ children }) => (
  <Box
    sx={{
      height: '100%',
      width: '100%',
      maxWidth: '451px',
      bgcolor: 'background.paper',
      boxSizing: 'border-box',
    }}
  >
    {children}
  </Box>
);

const TabPanel = ({ children, value, index, ...rest }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`messages-tabpanel-${index}`}
    aria-labelledby={`messages-tab-${index}`}
    style={{ height: '100%' }}
    {...rest}
  >
    {value === index && <>{children}</>}
  </div>
);
