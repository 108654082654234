import { ReactNode } from 'react';

import { CircularProgress, Stack, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import { useParams } from 'react-router-dom';

import { getDatePartFromDateTime } from 'helpers/dateFunctions';
import { useLicensesGet } from 'hooks-api/useLicensesGet';
import type { ProductPool } from 'types/types-api';

import EntitlementPoolHeader from './EntitlementPoolHeader';

const EntitlementPoolTable = () => {
  const { companyId } = useParams();

  const { data, loading } = useLicensesGet({ CompanyId: companyId, Skip: 0, Take: 99999 });

  return (
    <StyledCard>
      <EntitlementPoolHeader />
      {loading ? (
        <CircularProgress />
      ) : (
        data && data.data?.map((product) => <ProductPoolRow key={product.productPoolId} productData={product} />)
      )}
    </StyledCard>
  );
};

export default EntitlementPoolTable;

const StyledCard = ({ children }: { children: ReactNode }) => (
  <Card
    sx={{
      border: '1px solid',
      borderColor: 'other.border',
      borderRadius: '4px',
      boxShadow: 'none',
      marginRight: 0.5,
      flex: 2,
      overflow: 'auto',
    }}
  >
    {children}
  </Card>
);

const ProductPoolRow = ({ productData }: { productData: ProductPool }) => {
  const produtName = productData.label;

  const startDate = new Date(productData.startDate);
  const isInTheFuture = startDate > new Date();
  return (
    <Stack
      flexDirection="row"
      justifyContent="space-between"
      width="100%"
      alignItems="center"
      sx={{
        marginRight: 3,
        height: 56,
        borderBottom: '1px solid',
        borderColor: 'other.border',
        padding: 3,
        paddingRight: '51px',
      }}
    >
      <Typography
        sx={{
          textOverflow: 'elipsis',
          paddingRight: 1,
          minWidth: '120px',
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
        }}
        noWrap
      >
        <Typography sx={{ color: '#D89F30', fontWeight: 700 }}>{isInTheFuture ? 'PENDING' : ''}</Typography>
        {produtName}
      </Typography>
      <Stack flexDirection="row" gap="56px" alignItems="center">
        <Typography width="72px" color="secondary.main">
          {productData.poolQuantity}
        </Typography>
        <Typography width="36px">{productData.poolQuantity - productData.poolAvailableQuantity}</Typography>
        <Typography width="58px">{productData.poolAvailableQuantity}</Typography>
        <Typography width="60px" color={isInTheFuture ? '#0FA93A' : 'secondary.main'}>
          {getDatePartFromDateTime(productData.startDate)}
        </Typography>
        <Typography width="85px" color="secondary.main">
          {getDatePartFromDateTime(productData.renewalDate)}
        </Typography>
      </Stack>
    </Stack>
  );
};
