import React, { useRef } from 'react';

import { Typography, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import ComponentPaneLayout from 'app/Layout/ReactGridLayout/ComponentPaneLayout';
import { Custom } from 'components/Icons';
import { InterfaceSelectorWorkRequest, InterfaceSelectorWorkRequestRef } from 'components/InterfaceSelectorWorkRequest';
import useSetupModule from 'hooks/useSetupModule';
import useUrlParams from 'hooks/useUrlParams';
import { WorkRequestDragDropProvider } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestDragDropProvider';
import { WorkRequestItemsProvider } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestItemsContext';
import { WorkRequestsProvider, WorkRequestsContext } from 'modules/Field/WorkRequests/WorkRequestsContext';
import WorkRequestFilter from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestFilter/WorkRequestFilter';
import WorkRequestListFlyoutMenu from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestListFlyoutMenu/WorkRequestListFlyoutMenu';
import { ShopTasksProvider } from 'modules/Shop/ShopSetup/ShopTasks/ShopTasksContext';
import { WorkOrdersProvider } from 'modules/Shop/WorkOrders/WorkOrdersContext';
import { actionColors } from 'styles/theme/colors';

import { DrawingDocument, DrawingDocumentProvider } from './DrawingDocument';
import { DrawingFolderProvider } from './DrawingDocument/DrawingFolderContext';
import { useDrawingRegisterContext } from './DrawingRegisterContext';
import { DrawingRegisterDragDropProvider } from './DrawingRegisterDragDropProvider';
import { FacilityProjectsTable } from './FacilityProjectsTable/FacilityProjectsTable';
import DrawingRegisterWorkRequests from './WorkRequests/DrawingRegisterWorkRequests';

const DrawingRegister = () => {
  const { activeItem, selectedFolder } = useDrawingRegisterContext();

  return activeItem?.type === 'PROJECT' ? (
    <DrawingRegisterProjectContent selectedFolder={selectedFolder} />
  ) : (
    <DrawingRegisterFacilityContent />
  );
};

export default DrawingRegister;

const DrawingRegisterProjectContent = ({ selectedFolder }) => {
  const partDroppables = useRef();
  const navigate = useNavigate();
  const { currentModule } = useSetupModule();
  const {
    params: { nav },
  } = useUrlParams();
  const interfaceSelectorOptions = [
    {
      title: 'Work Requests + Drawing Register',
      description: 'Two pane view',
      icon: <Custom.DrawingRegister />,
      onClick: () => {},
    },
    {
      title: 'Work Requests',
      description: 'Single pane view',
      icon: <Custom.WorkRequests fill={actionColors.disabled} />,
      onClick: () =>
        navigate({
          pathname: `/${currentModule()}/work-requests`,
          search: '&nav=work-requests',
        }),
    },
  ];

  return (
    <DrawingRegisterDragDropProvider>
      <WorkRequestsProvider>
        <WorkOrdersProvider>
          <ComponentPaneLayout flyoutMenu={<WorkRequestListFlyoutMenu ref={partDroppables} />}>
            <DrawingFolderProvider folderParent={selectedFolder}>
              <DrawingDocumentProvider>
                <DrawingDocument />
              </DrawingDocumentProvider>
            </DrawingFolderProvider>
            <ShopTasksProvider>
              <WorkRequestDragDropProvider needsDragDropContext={false}>
                <WorkRequestItemsProvider>
                  <Stack sx={{ flexGrow: 1 }}>
                    <WorkRequestsContext.Consumer>
                      {({ currentTable }) =>
                        currentTable !== 'WorkRequestItemsList' && nav === 'work-requests' ? (
                          <InterfaceSelectorWorkRequest options={interfaceSelectorOptions} />
                        ) : null
                      }
                    </WorkRequestsContext.Consumer>
                    <DrawingRegisterWorkRequests forceResponsive filterComponent={<WorkRequestFilter isResponsive />} />
                  </Stack>
                </WorkRequestItemsProvider>
              </WorkRequestDragDropProvider>
            </ShopTasksProvider>
          </ComponentPaneLayout>
        </WorkOrdersProvider>
      </WorkRequestsProvider>
    </DrawingRegisterDragDropProvider>
  );
};

const DrawingRegisterFacilityContent = () => {
  const navigate = useNavigate();
  const { currentModule } = useSetupModule();
  const {
    params: { nav },
  } = useUrlParams();
  const interfaceSelectorOptions = [
    {
      title: 'Work Requests + Drawing Register',
      description: 'Two pane view',
      icon: <Custom.DrawingRegister />,
      onClick: () => {},
    },
    {
      title: 'Work Requests',
      description: 'Single pane view',
      icon: <Custom.WorkRequests fill={actionColors.disabled} />,
      onClick: () => navigate(`/${currentModule()}/work-requests`),
    },
  ];
  return (
    <ComponentPaneLayout>
      <>
        <Typography variant="h5" component="h1" marginTop="4px" marginBottom="16px">
          Drawing Register
        </Typography>
        <InterfaceSelectorWorkRequestRef />
        {nav === 'work-requests' && (
          <InterfaceSelectorWorkRequest options={interfaceSelectorOptions} offset={{ x: -40, y: 20 }} />
        )}
        <FacilityProjectsTable />
      </>
    </ComponentPaneLayout>
  );
};
