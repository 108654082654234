import React, { useEffect, useState } from 'react';

import { gql, useQuery } from '@apollo/client';
import { useTheme } from '@mui/material';
import dayjs from 'dayjs';

import KanbanCard from 'components/Kanban/KanbanCard';
import { users as USERS } from 'graphql/queries';
import { formatLocalTime, getDaysBetween, TODAY } from 'helpers/dateFunctions';

import useUploadImage from '../../../../hooks/useUploadImage';

const WorkOrdersKanbanItem = ({ order = {}, index, disableDrag = false }) => {
  const { palette } = useTheme();
  const { getImage } = useUploadImage();
  const [cardImg, setCardImg] = useState('');

  const remainingDays = getDaysBetween(formatLocalTime(order.workRequest.needBy), new Date(TODAY));
  const expired = remainingDays < 0;

  const { data } = useQuery(gql(USERS), { fetchPolicy: 'cache-first' });

  useEffect(() => {
    const fetchImg = async (userImageId) => {
      if (!userImageId) return;

      const image = await getImage(userImageId);
      setCardImg(image);
    };
    const user = data?.users.find((obj) => obj.userId === order.createdBy);
    fetchImg(user?.userPhotoId);
  }, [data, order.createdBy, getImage]);

  const needByDate = dayjs(`${order.workRequest.needBy}Z`);
  return (
    <KanbanCard
      disableDragFunction={disableDrag}
      key={order.workOrderId}
      draggableId={order.workOrderId}
      index={index}
      color="white"
      border="white"
      expired={expired}
    >
      <KanbanCard.Header>{order.workRequest.projectName || '...'}</KanbanCard.Header>
      <KanbanCard.Content>
        <KanbanCard.OrderId order={order}>{order.workOrderName || '...'}</KanbanCard.OrderId>
        <KanbanCard.OrderName>{order.workRequest?.workRequestName || '...'}</KanbanCard.OrderName>
        <KanbanCard.OrderDate expired={expired}>
          {needByDate.isValid() && needByDate.local().format('MM/DD/YYYY')}
        </KanbanCard.OrderDate>
        <KanbanCard.OrderRemaining expired={expired}>{needByDate.isValid() && remainingDays}</KanbanCard.OrderRemaining>
      </KanbanCard.Content>
      <KanbanCard.Footer comments={7} img={cardImg} detailColor={palette.kanban.border} />
    </KanbanCard>
  );
};

export default WorkOrdersKanbanItem;
