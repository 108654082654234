import React from 'react';

import { Typography } from '@mui/material';

import { getDaysBetween } from 'helpers/dateFunctions';

import { useWorkRequestsContext } from '../../../WorkRequestsContext';
import { InformativeRow } from '../InformativeRow';

export const DaysRemaining = () => {
  const { workRequestSelected } = useWorkRequestsContext();
  return (
    <InformativeRow>
      <Typography color="white" variant="body2">
        Days Remaining
      </Typography>
      <Typography color="white" variant="body2">
        {workRequestSelected?.needBy ? getDaysBetween(new Date(`${workRequestSelected?.needBy}Z`), new Date()) : '-'}
      </Typography>
    </InformativeRow>
  );
};
