import React, { useEffect } from 'react';

import { CircularProgress } from '@mui/material';
import { Navigate } from 'react-router-dom';

import { useUser } from 'app/UserContext';

import { ProfilePictureProvider } from './Layout/HeaderBar/ProfileDropdown/ProfilePictureContext';
import Layout from './Layout/Layout';
import { MessageAlertBannerProvider } from './Layout/MessageAlertBanner';
import { ModuleProvider } from './Modules/ModuleContext';

const PrivateWrapper = () => {
  const { user, loading } = useUser();

  useEffect(() => {
    if (!user) return;
    window.initializePendo(user?.userId);
  }, [user]);

  if (loading) return <CircularProgress />;

  if (!user) return <Navigate to="/auth/login" />;

  return (
    <ModuleProvider>
      <MessageAlertBannerProvider>
        <ProfilePictureProvider>
          <Layout />
        </ProfilePictureProvider>
      </MessageAlertBannerProvider>
    </ModuleProvider>
  );
};

export default PrivateWrapper;
