import React, { Suspense } from 'react';

const InputImage = React.lazy(() => import('./InputImage'));
const InputAdditionalImage = React.lazy(() => import('./InputAdditionalImage'));

const LazyInputImage = ({ ...restProps }) => (
  <Suspense fallback={<div>...</div>}>
    <InputImage {...restProps} />
  </Suspense>
);

const LazyInputAdditionalImage = ({ ...restProps }) => (
  <Suspense fallback={<div>...</div>}>
    <InputAdditionalImage {...restProps} />
  </Suspense>
);

export { LazyInputImage as default, LazyInputAdditionalImage as InputAdditionalImage };
