import React from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useTableRowContext } from '../context/TableRowContext';

const AddItemButton = () => {
  const { setShowForm } = useTableRowContext();
  return (
    <Stack
      sx={{
        cursor: 'pointer',
        padding: '2px 0',
        width: '100%',
        height: '100%',
        color: 'secondary.dark',
        ml: -8,
        '&:hover': {
          color: 'secondary.main',
        },
      }}
      direction="row"
      alignItems="center"
      onClick={() => setShowForm(true)}
      data-cy="add-work-request-item-button"
    >
      <Typography variant="body2" sx={{ letterSpacing: '0.15px', '&:hover': { textDecoration: 'underline' } }}>
        + Add item
      </Typography>
    </Stack>
  );
};

export default AddItemButton;
