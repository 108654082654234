import React from 'react';

import { IconButton, Stack } from '@mui/material';

import { Custom } from 'components/Icons';

const CategoryItemIcon = ({ visible, opened, sx, onClick }) => (
  <Stack
    sx={{
      minHeight: '24px',
      minWidth: '24px',
      alignItems: 'center',
      transform: opened ? `rotate(90deg)` : '',
    }}
  >
    {visible && (
      <IconButton onClick={onClick} sx={{ height: '24px', width: '24px', padding: 0 }}>
        <Custom.ArrowRight
          style={{ height: '40px', width: '40px', fill: 'rgba(0, 0, 0, 0.5)', ...sx }}
        />
      </IconButton>
    )}
  </Stack>
);

export default CategoryItemIcon;
