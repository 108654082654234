import React, { useMemo } from 'react';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { NoContentFallback } from 'components';
import { Custom } from 'components/Icons';

const ShopDepartmentFallback = ({ children }) => {
  const { shopDepartmentId, selectedItem } = useFacilitiesProjects();

  const isFacilitySelected = useMemo(() => selectedItem?.type === 'FACILITY', [selectedItem]);

  if (isFacilitySelected && !shopDepartmentId) {
    return (
      <NoContentFallback
        icon={Custom.Info}
        message="This facility does not have a Shop department."
      />
    );
  }

  return children;
};

export default ShopDepartmentFallback;
