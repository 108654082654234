import React from 'react';

import { gql, useLazyQuery, useMutation } from '@apollo/client';

import {
  addPart as ADD_PART,
  updatePart as UPDATE_PART,
  removePartsFromCategory as REMOVE_PARTS_FROM_CATEGORY,
} from 'graphql/mutations';
import { parts as PARTS, partsCount as PARTS_COUNT } from 'graphql/queries';
import useLazyPaginatedQuery from 'hooks/useLazyPaginatedQuery';

export const clearPartsCloudSearchCache = (cache) => {
  if (!Object.keys(cache.data.data.ROOT_QUERY).some((key) => /(partsCloudSearch:)/gm.test(key))) return;
  cache.modify({
    id: 'ROOT_QUERY',
    fields: {
      parts(_, { DELETE }) {
        return DELETE;
      },
      partsCloudSearch(_, { DELETE }) {
        return DELETE;
      },
    },
  });
  cache.gc();
};
const PartAPIContext = React.createContext();

const PartAPIProvider = ({ children }) => {
  const [addPart, { loading: loadingAddPart }] = useMutation(gql(ADD_PART), {
    refetchQueries: ['PartsCount'],
    update(cache) {
      clearPartsCloudSearchCache(cache);
    },
  });

  const [searchPart, { data: foundPart }] = useLazyQuery(gql(PARTS), { fetchPolicy: 'no-cache' });

  const [{ lazyLoad: loadParts, paginationHandler: fetchMoreParts }, { data, loading }] = useLazyPaginatedQuery(
    gql(PARTS),
    'network-only',
  );

  const [updatePart, { loading: loadingUpdatePart }] = useMutation(gql(UPDATE_PART), {
    update(cache) {
      clearPartsCloudSearchCache(cache);
      cache.evict([
        {
          id: 'ROOT_QUERY',
          fieldName: 'billOfMaterialItem',
        },
        {
          id: 'ROOT_QUERY',
          fieldName: 'takeoffPadItems',
        },
      ]);
    },
  });
  const [loadPartsCount, { data: partsCountResponse, loading: loadingPartsCount }] = useLazyQuery(gql(PARTS_COUNT), {
    fetchPolicy: 'no-cache',
  });

  const [removePartsFromCategory, { loading: isRemovingPartsFromCategory }] = useMutation(
    gql(REMOVE_PARTS_FROM_CATEGORY),
    {
      refetchQueries: ['Parts', 'PartsCount'],
      awaitRefetchQueries: true,
    },
  );

  const valueObj = {
    addPart,
    loadingAddPart,
    updatePart,
    loadingUpdatePart,
    fetchMoreParts,
    data,
    loading,
    loadParts,
    searchPart,
    foundPart,
    loadPartsCount,
    partsCount: partsCountResponse?.partsCount ?? 0,
    loadingPartsCount,
    removePartsFromCategory,
    isRemovingPartsFromCategory,
  };
  return <PartAPIContext.Provider value={valueObj}>{children}</PartAPIContext.Provider>;
};

const usePartAPIContext = () => {
  const context = React.useContext(PartAPIContext);
  if (context === undefined) throw new Error('usePartAPIContext must be within a PartAPIContext');
  return context;
};

export { PartAPIProvider, PartAPIContext, usePartAPIContext };
