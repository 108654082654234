import React, { useState } from 'react';

import StackRow from 'components/StackRow';

import MobileNavBar from '../../NavBar/MobileNavBar';
import EvolveLogoLink from '../components/EvolveLogoLink';
import SearchButton from '../components/SearchButton';
import ProfileDropdown from '../ProfileDropdown/ProfileDropdown';
import MobileModuleSelector from './MobileModuleSelector';
import MobileSearch from './MobileSearch';

const MobileHeader = () => {
  const [showSearch, setShowSearch] = useState(false);

  const handleToggleSearch = () => {
    setShowSearch(!showSearch);
  };

  const LeftAligned = () => (
    <StackRow>
      <StackRow style={{ marginRight: '4px' }}>
        <MobileNavBar />
      </StackRow>
      <MobileModuleSelector />
    </StackRow>
  );

  const RightAligned = () => (
    <StackRow>
      <SearchButton onClick={handleToggleSearch} />
      <ProfileDropdown />
    </StackRow>
  );

  return (
    <>
      <SpaceBetweenContainer>
        <LeftAligned />
        <EvolveLogoLink />
        <RightAligned />
      </SpaceBetweenContainer>
      <MobileSearch isOpen={showSearch} />
    </>
  );
};

export default MobileHeader;

const SpaceBetweenContainer = (props) => (
  <StackRow sx={{ justifyContent: 'space-between', width: '100%' }} {...props} />
);
