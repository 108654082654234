import React, { useEffect, useMemo } from 'react';

import { useReactiveVar } from '@apollo/client';

import { draftPartReactiveVar } from 'apollo/reactiveVars';
import { NEW_PART_ITEM_ID } from 'modules/Materials/AssemblyEditor/Utils/constants';

import { useCatalogPartContext } from '../Providers/CatalogPartProvider';
import { useCatalogSelectedPartContext } from '../Providers/CatalogSelectedPartProvider';
import ItemsListSection from './ItemsListSection';
import NewItemsSection from './NewItemsSection';

const formatDraftPart = (draftPart) => {
  if (!draftPart) return null;

  const { partId, partName, description, manufacturerId, unitOfMeasureId } = draftPart;
  return {
    partId,
    partName,
    description,
    unitOfMeasure: {
      unitOfMeasureCode: unitOfMeasureId?.code ?? '',
      unitOfMeasureId: unitOfMeasureId?.id ?? null,
      unitOfMeasureName: unitOfMeasureId?.label ?? '',
      unitOfMeasureTypeId: unitOfMeasureId?.type ?? '',
    },
    manufacturer: {
      manufacturerId: manufacturerId?.id ?? null,
      manufacturerName: manufacturerId?.label ?? '',
    },
    attriubuteVariantData: [],
  };
};

const ItemsSection = () => {
  const { showNewItem } = useCatalogPartContext();
  const { selectedPart, setSelectedPart } = useCatalogSelectedPartContext();

  const draftPart = useReactiveVar(draftPartReactiveVar);
  useEffect(() => {
    const isDraftPartNew = draftPart?.partId === NEW_PART_ITEM_ID;
    if (!isDraftPartNew) return;

    const formattedDraftPart = formatDraftPart(draftPart);
    setSelectedPart(formattedDraftPart);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftPart]);

  const shouldDisplayNewItemSection = useMemo(() => showNewItem || selectedPart, [showNewItem, selectedPart]);

  return (
    <>
      {shouldDisplayNewItemSection && <NewItemsSection />}
      {!shouldDisplayNewItemSection && <ItemsListSection />}
    </>
  );
};

export default ItemsSection;
