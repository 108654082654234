import React from 'react';

import { GridRow } from '@mui/x-data-grid-pro';

import { useWorkRequestDragDrop } from './WorkRequestDragDropProvider';
import EditOrCreateRow from './WorkRequestRow/EditOrCreateRow';
import GridDroppable from './WorkRequestRow/GridDroppable';

const WorkRequestRowProvider = ({ rowData, renderLastRow }) => {
  const isEditingRow = rowData?.row?.isEditing;
  const isRowToCreateNewItem = rowData?.row?.workRequestItemId === 0;

  const { needsDragDropContext } = useWorkRequestDragDrop();

  if (isEditingRow || isRowToCreateNewItem) return <EditOrCreateRow isEditingRow={isEditingRow} rowData={rowData} />;

  if (needsDragDropContext)
    return (
      <>
        <GridRow {...rowData} />
        {renderLastRow && renderLastRow()}
      </>
    );

  return (
    <>
      <GridDroppable rowData={rowData} />
      {renderLastRow && renderLastRow()}
    </>
  );
};

export default WorkRequestRowProvider;
