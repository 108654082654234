import React from 'react';

import { Chip, Stack } from '@mui/material';
import InputBase from '@mui/material/InputBase';

import { Custom } from 'components/Icons';

const SearchBar = ({
  handleSearch = () => {},
  disabled,
  placeholder = 'Search…',
  sx,
  closeSearchBar = () => {},
  workRequestSelected = false,
}) => {
  const [inputValue, setInputValue] = React.useState('');
  const [searchFilter, setSearchFilter] = React.useState('');

  const updateSearchFilter = (newValue) => {
    handleSearch(newValue);
    setSearchFilter(newValue);
  };

  const onEnter = (e) => {
    if (e.keyCode === 13) {
      updateSearchFilter(inputValue);
      setInputValue('');
    }
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      data-testid="search-bar"
      sx={{
        minWidth: '400px',
        maxHeight: '32px',
        backgroundColor: '#EEEEEE',
        borderRadius: '5px',
        paddingLeft: '8px',
        ...sx,
      }}
    >
      <Custom.SearchIcon sx={{ color: '#a4a4a4' }} />
      {searchFilter && <Chip label={searchFilter} onDelete={() => updateSearchFilter('')} sx={{ mr: 2 }} />}
      <InputBase
        value={inputValue}
        onKeyDown={onEnter}
        placeholder={placeholder}
        disabled={disabled}
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
        sx={{ paddingLeft: '6px' }}
      />
      {workRequestSelected && (
        <Stack sx={{ ml: 'auto' }}>
          <Custom.CloseIcon sx={{ color: '#a4a4a4', mr: 1 }} onClick={closeSearchBar} />
        </Stack>
      )}
    </Stack>
  );
};

export default SearchBar;
