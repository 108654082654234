import { gql, useMutation } from '@apollo/client';

import { GENERIC_MUTATION_ERROR } from 'constants/globalConstants';
import { deleteWorkCellMachine as UNMAP_WORK_CELL_MACHINE, updateMachine as UPDATE_MACHINE } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

import { ROW_TYPES } from '../../../constants/constants';
import { useDataContext } from '../../../contexts/DataContext';
import { buildMachineBody } from '../../../helpers/helpers';
import useCachedData from '../../../hooks/useCachedData';

export default function useMachineDataLayer(machine) {
  const { handleResponse } = useGraphqlResponseHandler();
  const { selectedItem, activeRow, setActiveRow } = useDataContext();
  const { disableWorkCellMachinesContainerCache } = useCachedData();

  const [unmapWorkCellMachineMutation, { loading: loadingUnmapWorkCellMachine }] = useMutation(
    gql(UNMAP_WORK_CELL_MACHINE),
    { refetchQueries: ['WorkCellMachine', 'WorkCellTaskType', 'Machines', 'MachineTaskType'] },
  );

  const [updateMachineMutation] = useMutation(gql(UPDATE_MACHINE), {
    refetchQueries: ['WorkCellMachine', 'Machines'],
  });

  const updateMachine = async (formValues) => {
    handleResponse(
      updateMachineMutation,
      {
        variables: {
          params: { machineId: machine.machineId },
          body: buildMachineBody(formValues, selectedItem, false).body,
        },
      },
      { successMessage: 'Work Cell successfully edited' },
      (result) => {
        if (result.updateMachine)
          setActiveRow({
            id: machine.workCellMachineId,
            type: ROW_TYPES.MACHINE,
            ...result.updateMachine,
          });
      },
    );
  };

  const unmapWorkCellMachine = async (
    workCellMachineId,
    workCellId,
    successMessage = 'Machine successfully unmapped',
  ) =>
    handleResponse(
      unmapWorkCellMachineMutation,
      { variables: { params: { workCellMachineId } } },
      { successMessage, errorMessage: GENERIC_MUTATION_ERROR },
      (result) => {
        if (result.deleteWorkCellMachine) {
          if (workCellMachineId === activeRow.workCellMachineId) setActiveRow({});
          disableWorkCellMachinesContainerCache(workCellId, workCellMachineId);
        }
      },
    );

  return {
    updateMachine,
    unmapWorkCellMachine,
    loadingUnmapWorkCellMachine,
    updateMachineMutation,
  };
}
