import React from 'react';

import { Stack } from '@mui/material';
import { Draggable } from 'react-beautiful-dnd';
import ReactDOM from 'react-dom';

// eslint-disable-next-line import/no-cycle
import LocationsRow from '../LocationsRow';

const dndPortal = document.getElementById('dnd-portal');

const LocationDraggableRow = ({
  index,
  draggableId,
  node,
  disableDragFunction,
  depth = 1,
  ...rest
}) => (
  <Draggable draggableId={draggableId} index={index} isDragDisabled={disableDragFunction}>
    {(provided, snapshot) => {
      const MainContent = (
        <StyledItem
          {...provided.draggableProps}
          ref={provided.innerRef}
          sx={{ position: 'relative', zIndex: depth }}
        >
          <Stack {...provided.dragHandleProps} />
          <LocationsRow node={node} depth={depth} {...rest} />
        </StyledItem>
      );
      return snapshot.isDragging ? ReactDOM.createPortal(MainContent, dndPortal) : MainContent;
    }}
  </Draggable>
);

export default LocationDraggableRow;

const StyledItem = React.forwardRef(({ children, sx, ...props }, ref) => (
  <Stack ref={ref} sx={{ backgroundColor: 'white', ...sx }} {...props}>
    {children}
  </Stack>
));
