import React, { useEffect, useState } from 'react';

import { IModalContext, IModalProvider } from '../@types/modalContextTypes';
import useCheckboxesForm from '../hooks/useCheckboxesForm';
import useNodeTree from '../hooks/useNodeTree';
import { FOOTER_MESSAGE } from '../LocationsTable/constants/constants';

const SUCCESS_MESSAGE = [FOOTER_MESSAGE.EDITED_SUCCESS];

const ModalContext = React.createContext<IModalContext | undefined>(undefined);

const ModalProvider = ({
  lineItem,
  ernPrefix = 'moab:takeoffpaditem',
  children,
  parentFilteredIds = [],
  filteredCheckedIds = [],
  project,
}: IModalProvider) => {
  const [calculationNodeIds, setCalculationNodeIds] = useState<string[]>([]);
  const [errorNodeIds, setErrorNodeIds] = useState<string[]>([]);
  const [footerMessage, setFooterMessage] = useState<string>();
  const [updateIsLoading, setUpdateIsLoading] = useState<boolean>(false);

  const checkIfIsSuccessMessage = () => SUCCESS_MESSAGE.includes(footerMessage as string);

  const handleLoadingUpdate = (newState: boolean) => {
    if (newState !== updateIsLoading) setUpdateIsLoading(newState);
  };

  useEffect(() => {
    if (footerMessage && footerMessage !== FOOTER_MESSAGE.QTY_LIMIT_EXCEEDED) {
      setTimeout(() => {
        setFooterMessage(undefined);
      }, 5000);
    }
  }, [footerMessage]);

  const selectedStateObj: IModalContext = {
    ern: `ern:${ernPrefix}:${lineItem?.lineItemId}`,
    lineItem,
    footerMessage,
    calculationNodeIds,
    errorNodeIds,
    updateIsLoading,
    parentFilteredIds,
    filteredCheckedIds,
    project,
    checkIfIsSuccessMessage,
    setCalculationNodeIds,
    setErrorNodeIds,
    setFooterMessage,
    handleLoadingUpdate,
    ...useNodeTree(calculationNodeIds, filteredCheckedIds),
    ...useCheckboxesForm(filteredCheckedIds),
  };

  return <ModalContext.Provider value={selectedStateObj}>{children}</ModalContext.Provider>;
};

const useModal = () => {
  const context = React.useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalContext');
  }
  return context;
};

export { ModalContext, ModalProvider, useModal };
