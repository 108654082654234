import React from 'react';

import SearchTextInput from 'components/SearchTextInput';

const CatalogSearchInput = (params) => {
  const {
    InputProps: { ref, ...otherProps },
    ...rest
  } = params;

  delete rest.InputLabelProps;
  return (
    <SearchTextInput
      data-testid="catalog-search-autocomplete-input"
      xRef={ref}
      placeholder="Search ..."
      sx={{
        width: '392px !important',
        backgroundColor: 'other.inputBackground',
        '&:hover': {
          backgroundColor: 'other.inputBackground',
        },
      }}
      overrideInputSx={{ color: '#ffff' }}
      {...otherProps}
      {...rest}
    />
  );
};

export default CatalogSearchInput;
