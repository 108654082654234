import React, { useCallback, useMemo } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ChipOption from './ChipOption';

const LocationWithAWorkPhaseChipsContainer = ({
  currentItem,
  data = [],
  onClickOption = () => {},
}) => {
  const sxChipOption = useCallback(
    (itemId) => {
      if (currentItem && currentItem?.id === itemId)
        return {
          borderColor: 'secondary.main',
          borderWidth: 2,
          borderStyle: 'solid',
        };
      return {};
    },
    [currentItem],
  );

  const options = useMemo(() => {
    const parse = data.map((item) => ({ ...item, label: item.chipName }));
    return parse.map(({ id, ...rest }) => (
      <ChipOption
        data-testid={`LocationWithAWorkPhaseChipsContainer-ChipOption-${id}-data-testid`}
        sx={sxChipOption(id)}
        key={id}
        onClick={onClickOption}
        id={id}
        {...rest}
      />
    ));
  }, [data, onClickOption, sxChipOption]);

  return (
    <Box
      data-testid="LocationWithAWorkPhaseChipsContainer-data-testid"
      sx={{
        display: 'flex',
        height: 113,
        flexDirection: 'column',
        marginLeft: 3,
        marginRight: 3,
        marginTop: 3,
      }}
    >
      {!currentItem && (
        <Typography
          data-testid="LocationWithAWorkPhaseChipsContainer-Typography-data-testid"
          variant="subtitle1"
          sx={{ height: 32, marginBottom: 1 }}
        >
          Select a location with workphase OR a location and a workphase to assign.
        </Typography>
      )}
      <Box
        data-testid="LocationWithAWorkPhaseChipsContainer-Options-data-testid"
        sx={{
          display: 'flex',
          overflow: 'auto',
          flexWrap: 'wrap',
        }}
      >
        {options}
      </Box>
    </Box>
  );
};

export default LocationWithAWorkPhaseChipsContainer;
