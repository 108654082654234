import React from 'react';

import InputSelectField from 'components/FormComponents/InputSelectField';
import { requiredRule } from 'constants/inputFieldRules';

const DropDown = ({ label, options = [], sx, formControlSx, ...props }) => (
  <InputSelectField
    color="secondary"
    size="small"
    label={label}
    fullWidth
    options={options}
    style={{
      marginTop: '8px',
      marginBottom: '8px',
      ...sx,
    }}
    formControlSx={{
      '& .MuiTypography-root': { fontSize: '16px' },
      ...formControlSx,
    }}
    rules={requiredRule(label)}
    {...props}
  />
);

export default DropDown;
