import React from 'react';

import { Stack, Typography, Checkbox } from '@mui/material';

import { useSendToBomModal } from '../SendToBomModalContext';
import ModalTable from './ModalTable';

const ModalBody = () => {
  const { updateSelectAll, selectAll } = useSendToBomModal();
  const handleCheckBox = () => updateSelectAll({ active: !selectAll.active, modifiedBy: 'checkbox' });

  return (
    <>
      <Stack direction="row" alignItems="center" margin="8px 0 0 24px">
        <Checkbox color="secondary" onChange={handleCheckBox} checked={selectAll.active} />
        <Typography>Send all remaining qtys to BOM</Typography>
      </Stack>
      <ModalTable />
    </>
  );
};

export default ModalBody;
