import React from 'react';

import { ClickAwayListener } from '@mui/material';

import { useTableRowFormContext } from '../../context/TableRowFormContext';

const ClickAwayRow = ({ children }) => {
  const { submitItem } = useTableRowFormContext();

  const handleClickAway = () => submitItem();

  return <ClickAwayListener onClickAway={handleClickAway}>{children}</ClickAwayListener>;
};

export default ClickAwayRow;
