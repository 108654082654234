import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

import { Custom } from 'components/Icons';
import { ProjectsTable } from 'components/ProjectsTable/ProjectsTable';

export const CustomAssembly = () => {
  const navigate = useNavigate();

  return (
    <Stack sx={{ flex: 1, pb: 2 }}>
      <Title />
      <Stack sx={{ flex: 1 }}>
        <ProjectsTable
          icon={<Custom.AssemblyType />}
          onProjectClick={(project) => {
            navigate(`/shop/custom-assembly/${project.id}`, { replace: true });
          }}
        />
      </Stack>
    </Stack>
  );
};

const Title = () => (
  <Box marginTop="4px" marginBottom="16px">
    <Typography variant="h5" component="h1">
      Custom Assemblies
    </Typography>
  </Box>
);
