import React from 'react';

import { Stack, Typography, Box, useTheme } from '@mui/material';

import { Custom } from 'components/Icons';

export const NoWorkRequestOverlay = ({ itemName = 'work request' }) => {
  const { palette } = useTheme();
  return (
    <Stack alignItems="center" height="100%" sx={{ marginTop: '10rem' }}>
      <Box height="22px" sx={{ marginTop: '22px', marginX: '4px' }}>
        <Custom.ManWithShovel width={47} height={47} fill={palette.action.disabled} data-testid="man-with-shovel" />
      </Box>
      <Typography variant="h6" sx={{ color: palette.action.disabled, marginTop: '36px' }}>
        Select a {itemName}
      </Typography>
      <Typography variant="h6" sx={{ color: palette.action.disabled }}>
        to view properties.
      </Typography>
    </Stack>
  );
};
