import { MutationHookOptions, gql, useMutation } from '@apollo/client';

import { updateWorkOrderItem as UPDATE_WORK_ORDER_ITEM } from 'graphql/mutations';
import { UpdateWorkOrderItemMutation, UpdateWorkOrderItemMutationVariables } from 'graphql/types';

const useUpdateWorkOrderItem = (
  options: MutationHookOptions<UpdateWorkOrderItemMutation, UpdateWorkOrderItemMutationVariables>,
) =>
  useMutation<UpdateWorkOrderItemMutation, UpdateWorkOrderItemMutationVariables>(gql(UPDATE_WORK_ORDER_ITEM), options);

export default useUpdateWorkOrderItem;
