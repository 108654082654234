import { useCallback, useState } from 'react';

import { gql, useMutation } from '@apollo/client';

import { useUser } from 'app/UserContext';
import { getDocumentPresignedURL as GET_DOCUMENT_PRESIGNED_URL } from 'graphql/mutations';
import { withHttp } from 'helpers/common';
import useFileDownloader from 'hooks/useFileDownloader';

const useAttachments = () => {
  const { user } = useUser();
  const { handleDownloadFile } = useFileDownloader();

  const [getPresignedURL, { loading: preSignedUrlLoading }] = useMutation(
    gql(GET_DOCUMENT_PRESIGNED_URL),
  );

  const [selectedIndex, setSelectedIndex] = useState(null);

  const getDocumentsPresignedUrl = useCallback(
    async (filePath) => {
      const presignedURLResponse = await getPresignedURL({
        variables: {
          body: {
            expirationHours: 24,
            objectKey: filePath,
            requestedBy: user.userId,
            verb: 'GET',
          },
        },
      });

      return presignedURLResponse?.data?.getDocumentPresignedURL?.preSignedURL || '';
    },
    [getPresignedURL, user.userId],
  );

  const handleDownloadSingleFile = useCallback(
    async (document, selectedIndex) => {
      setSelectedIndex(selectedIndex);
      const documentUrl =
        document?.externalUrl || (await getDocumentsPresignedUrl(document?.storagePath));
      const fileName = document.documentNameFormatted;
      handleDownloadFile(documentUrl, fileName);
      setSelectedIndex(null);
    },
    [getDocumentsPresignedUrl, handleDownloadFile],
  );

  const handleOpenSingleFile = useCallback(
    async (document, selectedIndex) => {
      setSelectedIndex(selectedIndex);
      const documentUrl =
        document?.externalUrl || (await getDocumentsPresignedUrl(document?.storagePath));

      window.open(withHttp(documentUrl), '_blank');
      setSelectedIndex(null);
    },
    [getDocumentsPresignedUrl],
  );

  return {
    getDocumentsPresignedUrl,
    handleDownloadSingleFile,
    handleOpenSingleFile,
    metadata: {
      preSignedUrlLoading,
      selectedIndex,
    },
  };
};

export default useAttachments;
