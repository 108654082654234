import React, { useEffect, useMemo } from 'react';

import { objIsEmpty } from 'helpers/objectFunctions';
import useQuantityAPI from 'hooks-api/useQuantityAPI';
import useScopePackagesAPI from 'hooks-api/useScopePackagesAPI';
import useWorkPhaseAPI from 'hooks-api/useWorkPhasesAPI';

import useGetLocations from '../hooks/useGetLocations';
import useLineItemQuantity from '../hooks/useLineItemQuantity';
import useRowQuantities from '../hooks/useRowQuantities';
import { useModal } from './ModalContext';

const ProjectRowContext = React.createContext();

const ProjectRowProvider = ({ children }) => {
  const { lineItem, ern, project, addNodeToTree, findItem } = useModal();

  const item = useMemo(() => findItem(lineItem?.lineItemId), [findItem, lineItem?.lineItemId]);

  const {
    data: projectData,
    loading: loadingProjectData,
    fetchRetrieveByIdentifierQuantities,
    handleFetchCreateQuantity,
    loadingCreteQuantity,
  } = useQuantityAPI(ern);

  const { fetchScopePackages, scopePackages, loading: loadingScopePackages } = useScopePackagesAPI('cache-and-network');

  const { fetchWorkPhases, workPhases, loading: loadingWorkPhases } = useWorkPhaseAPI('cache-and-network');

  const handleFetchScopePackages = () => fetchScopePackages({ projectId: project?.projectId });

  const handleFetchWorkPhases = () => fetchWorkPhases({ projectId: project?.projectId });

  useEffect(() => {
    if (project && !objIsEmpty(projectData))
      addNodeToTree({ ...project, ...projectData, ...lineItem, type: 'PROJECT' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectData]);

  useEffect(() => {
    fetchRetrieveByIdentifierQuantities({
      onCompleted: ({ quantitiesRetrieveByIdentifier: successResponse }) => {
        if (!successResponse) handleFetchCreateQuantity(lineItem?.quantity, project?.projectId);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedStateObj = {
    loadingProjectData,
    item,
    loadingCreteQuantity,
    handleFetchScopePackages,
    scopePackages,
    loadingScopePackages,
    handleFetchWorkPhases,
    workPhases,
    loadingWorkPhases,
    ...useLineItemQuantity(),
    ...useRowQuantities(projectData),
    ...useGetLocations(),
  };

  return <ProjectRowContext.Provider value={selectedStateObj}>{children}</ProjectRowContext.Provider>;
};

const useProjectRow = () => {
  const context = React.useContext(ProjectRowContext);

  if (context === undefined) throw new Error('useProjectRow must be used within a ProjectRowContext');

  return context;
};

export { ProjectRowContext, ProjectRowProvider, useProjectRow };
