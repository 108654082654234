import React from 'react';

import { CircularProgress, Stack } from '@mui/material';
import { DragDropContext } from 'react-beautiful-dnd';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { useQueryWorkOrderStatusesList } from 'hooks-api/queries/workOrderStatusesList/useQueryWorkOrderStatusesList';

import useDragAndDropWorkOrdersKanban from './helpers/useDragAndDropWorkOrdersKanban';
import useWorkOrdersKanban from './useWorkOrdersKanban';
import WorkOrderKanbanColumns from './WorkOrderKanbanColumns';
import DefaultMessages from './WorkOrdersMessage/WorkOrdersKanbanMessages';

const WorkOrdersKanban = () => {
  const { data, loading } = useQueryWorkOrderStatusesList({
    options: { fetchPolicy: 'cache-first' },
  });

  const { onDragStart, onDragEnd, disableColumns, updatingWorkOrder } = useDragAndDropWorkOrdersKanban();
  const { hasWorkOrders, shouldDisplayErrorMessage, loadingCount } = useWorkOrdersKanban();
  const { selectedItem, shopDepartmentId } = useFacilitiesProjects();

  if (loading || loadingCount) return <CircularProgress />;

  return (
    <Stack direction="row" sx={{ width: 'auto', overflowY: 'auto', mt: '50px', height: '100%' }}>
      {shouldDisplayErrorMessage && (
        <DefaultMessages
          selectedItem={selectedItem}
          shopDepartmentId={shopDepartmentId}
          hasWorkOrders={hasWorkOrders}
        />
      )}

      {!shouldDisplayErrorMessage && data && (
        <DragDropContext onBeforeDragStart={onDragStart} onDragEnd={onDragEnd}>
          <WorkOrderKanbanColumns
            data={data?.workOrderStatusesList}
            disabled={disableColumns}
            disabledDrag={selectedItem?.type === 'PROJECT' || updatingWorkOrder}
          />
        </DragDropContext>
      )}
    </Stack>
  );
};

export default WorkOrdersKanban;
