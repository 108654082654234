import React, { useEffect, useMemo } from 'react';

import { Box, Stack } from '@mui/material';
import { Waypoint } from 'react-waypoint';

import { SESSION_STORAGE_CONSTANTS } from 'constants/globalConstants';
import useQueryParamMemoized from 'hooks/useQueryParamMemoized';

import { useCategoriesContext } from '../../Categories/CategoriesContext';
import { useCatalogPartContext } from '../../Providers/CatalogPartProvider';
import { useCatalogSelectedPartContext } from '../../Providers/CatalogSelectedPartProvider';
import { usePartAPIContext } from '../../Providers/PartAPIProvider';
import { useItemsContext } from '../ItemsContext';
import Item from './Item/Item';
import ItemCheckbox from './ItemCheckbox';

const { PUBLISHED_PART_FROM_EDITOR } = SESSION_STORAGE_CONSTANTS;

const getParts = (data) =>
  data?.parts?.map((part) => ({
    title: part?.partName,
    catalogNumber: part?.manufacturerCatalogNumber,
    manufacturerName: part?.manufacturer?.manufacturerName,
    description: part?.description,
    partId: part?.partId,
    partImages: part?.partImages,
  }));

const getPartById = (data, id) => data?.parts?.find((part) => part?.partId === id);
const RECENTLY_ADDED_PART_ITEM = 'recently-added-part-item';

const ItemsList = () => {
  const { data, fetchMoreParts, loading } = usePartAPIContext();

  const { recentlyAddedPart, setRecentlyAddedPart } = useCatalogPartContext();

  const { setSelectedPart, selectedPart } = useCatalogSelectedPartContext();
  const { selectedCategory } = useCategoriesContext();
  const { selectedItems, onChangeItems, isDeleteItems } = useItemsContext();

  const partCategoryIdParam = useQueryParamMemoized('partCategoryId');

  useEffect(() => {
    const partId = sessionStorage.getItem(PUBLISHED_PART_FROM_EDITOR);
    if (partId) setRecentlyAddedPart(partId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (recentlyAddedPart)
      setTimeout(() => {
        setRecentlyAddedPart(null);
        sessionStorage.removeItem(PUBLISHED_PART_FROM_EDITOR);
      }, 2500);
  }, [recentlyAddedPart, setRecentlyAddedPart]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const parts = useMemo(() => getParts(data), [selectedPart, data]);

  useEffect(() => {
    if (!parts?.length || !recentlyAddedPart) return;
    const element = document.getElementById(RECENTLY_ADDED_PART_ITEM);
    if (element) element.scrollIntoView({ behavior: 'smooth' });
  }, [parts, recentlyAddedPart]);

  const onClick = (partId) => {
    const selectedPart = getPartById(data, partId);
    setSelectedPart(selectedPart);
  };

  if (!partCategoryIdParam || selectedCategory == null) return null;

  return (
    <Box sx={{ overflowY: 'auto' }}>
      {parts?.map((part) => {
        const isPartChecked = selectedItems.some((el) => el.partId === part.partId);
        return (
          <Stack key={part.partId} display="flex" direction="row">
            {isDeleteItems && (
              <ItemCheckbox checked={isPartChecked} onChange={(checked) => onChangeItems(part, checked)} />
            )}
            <Item
              part={part}
              isSelectable={isDeleteItems}
              checked={isPartChecked}
              handleClick={onClick}
              recentlyAddedPart={recentlyAddedPart}
              id={recentlyAddedPart === part.partId ? RECENTLY_ADDED_PART_ITEM : part.partId}
            />
          </Stack>
        );
      })}
      <Box>
        <Waypoint
          bottomOffset="-20%"
          onEnter={() => {
            if (!loading) {
              fetchMoreParts(parts.length, {
                partCategoryId: selectedCategory?.partCategoryId,
                orderBy: 'dateadded:desc',
              });
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default ItemsList;
