import React from 'react';

import { InputForesiteField } from '@evolvemep/foresite-components-ui-react';
import { useFormContext } from 'react-hook-form';

import StackRow from 'components/StackRow';

import CloseButton from '../hooks/useBOMTableColumns/LineItemName/CloseButton';

const LineItemNameInput = ({ defaultValue = '', onClose = () => {}, autoFocus = false, ...props }) => {
  const { control, formState } = useFormContext();

  const handleMouseDown = (event) => {
    setTimeout(() => event.target.focus(), 300);
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <StackRow sx={{ width: 'calc(100% - 40px)', pr: 1 }}>
      <CloseButton onClick={onClose} isSubmitting={formState.isSubmitting} />
      <InputForesiteField
        control={control}
        defaultValue={defaultValue}
        name="lineItemName"
        id="testing-a"
        rules={{ required: { value: true, message: 'Required' } }}
        autoFocus={autoFocus}
        fullWidth
        variant="outlined"
        autoComplete="none"
        onMouseDown={handleMouseDown}
        size="extra-small"
        maxLength={128}
        disabled={formState.isSubmitting}
        {...props}
      />
    </StackRow>
  );
};

export default LineItemNameInput;
