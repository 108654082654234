import React from 'react';

import { Breadcrumbs, Stack, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import OverflowTypography from 'components/Tooltip/OverflowTypography';
import useSetupModule from 'hooks/useSetupModule';

import { getModuleRoute } from '../../FieldWorkRequestHelpers';
import { WORK_REQUEST_TABLES, useWorkRequestsContext } from '../../WorkRequestsContext';
import WorkRequestStatus from '../../WorkRequestsList/WorkRequestStatus';
import { WORK_REQUEST_ITEMS_VIEWS, useWorkRequestItemsContext } from '../WorkRequestItemsContext';

const linkStyles = {
  whiteSpace: 'nowrap',
  cursor: 'pointer',
  color: 'surface.lightSurface.secondary',
  '&:hover': {
    textDecoration: 'underline',
  },
};

const WorkRequestTitle = ({ title, status, isResponsiveWR, isLate }) => {
  const { currentModule } = useSetupModule();
  const moduleName = currentModule();
  const moduleRoute = getModuleRoute(moduleName);
  const navigate = useNavigate();
  const location = useLocation();

  const { setCurrentTable } = useWorkRequestsContext();
  const { inForgeView, setCurrentView } = useWorkRequestItemsContext();
  const inDrawingRegister = !!location.pathname.match(/drawing-register/g);
  const overflowLinkStyles = inForgeView ? linkStyles : null;

  const navigateToWorkRequest = () => {
    if (inDrawingRegister) {
      setCurrentTable(WORK_REQUEST_TABLES.WORK_REQUEST_LIST);
      return;
    }

    navigate(`/${moduleRoute}/${location?.state?.from === 'work-orders' ? 'work-orders' : 'work-requests'}`);
  };

  const navigateToWorkRequestItems = () => setCurrentView(WORK_REQUEST_ITEMS_VIEWS.TABLE_VIEW);

  return (
    <Stack direction="row" alignItems="center" width="100%">
      <Breadcrumbs
        separator="/"
        aria-label="breadcrumb"
        sx={{ '.MuiBreadcrumbs-ol': { flexDirection: 'row', flexWrap: 'nowrap' } }}
      >
        <Typography sx={linkStyles} onClick={navigateToWorkRequest}>
          {location?.state?.from === 'work-orders' ? 'Work Orders' : 'Work Requests'}
        </Typography>
        <OverflowTypography
          title={title}
          sx={{ color: 'black', maxWidth: isResponsiveWR ? '255px' : '380px', ...overflowLinkStyles }}
          onClick={navigateToWorkRequestItems}
          placement="bottom"
        />
        {inForgeView && <Typography sx={{ color: 'surface.lightSurface.primary' }}>Viewer</Typography>}
      </Breadcrumbs>
      <Stack mb="3px" ml={1}>
        <WorkRequestStatus status={status} isResponsiveWR={isResponsiveWR} pastDaysRemaining={isLate} />
      </Stack>
    </Stack>
  );
};

export default WorkRequestTitle;
