import { useApolloClient } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import useSetupModule from 'hooks/useSetupModule';
import ConfirmationToast from 'modules/Materials/components/ItemsIntegrationModal/components/ConfirmationToast/ConfirmationToast';
import { addBOMItems } from 'modules/Materials/components/ItemsIntegrationModal/helpers/itemsIntegrationModalHelpers';

import { useTakeOff } from '../context/TakeOffContext';

const useTakeoffToBomIntegration = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { currentModule } = useSetupModule();
  const client = useApolloClient();
  const { selectedItem } = useFacilitiesProjects();
  const { apiRef } = useTakeOff();

  const handleAddItems = async ({ integrationId, itemFields, rows }: any) =>
    addBOMItems({
      client,
      BOMid: integrationId,
      takeoffPadId: rows[0].takeoffPadId,
      lineItems: rows,
      lineItemsFields: itemFields,
      projectId: selectedItem?.id,
    });

  const handleItemsCreated = ({ totalItemsCreated }: any) => {
    apiRef.current?.setSelectionModel([]);
    enqueueSnackbar('', {
      content: (key) => (
        <ConfirmationToast
          text={`${totalItemsCreated} item${
            totalItemsCreated > 1 ? 's' : ''
          } successfully added to BOM`}
          action={{
            text: 'GO TO BOM',
            onClick: () => navigate(`/${currentModule()?.toLowerCase()}/bill-of-materials/`),
          }}
          id={key}
          key={key}
        />
      ),
    });
  };
  return { handleAddItems, handleItemsCreated };
};

export default useTakeoffToBomIntegration;
