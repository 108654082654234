import { gql, useMutation } from '@apollo/client';

import { updateWorkRequestStatus as UPDATE_WORK_REQUEST_STATUS } from 'graphql/mutations';
import { workRequestsById as WORK_REQUEST_BY_ID } from 'graphql/queries';

export const WorkRequestByIdQuery = (workRequestId) => ({
  query: gql(WORK_REQUEST_BY_ID),
  variables: { params: { workRequestId } },
});

const useWorkRequestStatusMutation = () => {
  const [updateWorkRequestStatusMutation, { loading }] = useMutation(gql(UPDATE_WORK_REQUEST_STATUS));

  return { loading, updateWorkRequestStatusMutation };
};

export default useWorkRequestStatusMutation;
