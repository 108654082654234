/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { Button, Stack, useTheme } from '@mui/material';

import MiniSpinner from 'components/MiniSpinner';

import { useCustomAssembliesContext } from '../../CustomAssembliesContext';

const ModalFooter = ({ onSubmit, onCancel, disable }) => {
  const { palette } = useTheme();
  const { isCreatingCustomAssembly } = useCustomAssembliesContext();
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if (!isCreatingCustomAssembly) {
      if (firstLoad === true) setFirstLoad(false);
      else {
        onCancel(false);
      }
    }
  }, [isCreatingCustomAssembly]);
  return (
    <Stack direction="row">
      <Button sx={{ marginRight: '8px', fontWeight: 'bold' }} onClick={() => onCancel(false)}>
        Cancel
      </Button>
      <Button
        sx={{
          fontWeight: 'bold',
          backgroundColor: disable ? palette.action.disabled : palette.secondary.main,
          height: '44px',
        }}
        variant="contained"
        color="secondary"
        onClick={onSubmit}
        disabled={disable || isCreatingCustomAssembly}
      >
        {isCreatingCustomAssembly ? <MiniSpinner sx={{ padding: '0px' }} /> : <></>} Create
      </Button>
    </Stack>
  );
};

export default ModalFooter;
