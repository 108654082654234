import { useCallback } from 'react';

import { gql } from '@apollo/client';

import { taskDocument as TASK_DOCUMENTS } from 'graphql/queries';
import useCache from 'hooks/useCache';
import useDocumentsCache from 'hooks/useDocumentsCache';

import { useNotificationMsg } from './snackBarMessages';
import useRegisterDocument from './useRegisterDocument';

const TIME_TO_ACTIVE_DRAG = 1500;

const useMapDocuments = () => {
  const { readCache } = useCache();
  const { deleteDocumentsCache } = useDocumentsCache();
  const { getMsg } = useNotificationMsg();

  const { addDocs, loading } = useRegisterDocument();

  const handleMappedDocument = useCallback(
    async (params, selectedRows, checkboxFolderId, setIsMapping) => {
      const { destination, draggableId } = params;
      const [, targetTaskId] = destination?.droppableId.split('/') ?? [];

      const variables = {
        query: {
          taskId: targetTaskId,
        },
      };

      const cache = readCache(gql(TASK_DOCUMENTS), variables);

      const notificationObj = {
        addList: [],
        alreadyMappedList: [],
        snackBar: () => {},
      };

      selectedRows.forEach(({ id }) => {
        const docsExists = cache?.taskDocument?.some(({ documentId }) => documentId === id);
        if (docsExists) {
          notificationObj.alreadyMappedList.push(id);
        } else {
          notificationObj.addList.push(id);
        }
      });

      const singleDocExists = cache?.taskDocument?.some(({ documentId }) => documentId === draggableId);

      const { addList, alreadyMappedList } = notificationObj;
      notificationObj.snackBar = getMsg(notificationObj, singleDocExists);
      if (!notificationObj.snackBar) return;

      const isMappingSingleDocument = !selectedRows.length && !alreadyMappedList.length;
      const mappableDocuments = isMappingSingleDocument ? [draggableId] : [...addList];

      setIsMapping(true);
      await addDocs(targetTaskId, mappableDocuments, notificationObj, checkboxFolderId);

      deleteDocumentsCache('taskDocument', { taskIds: targetTaskId });

      if (!loading) {
        setTimeout(() => {
          setIsMapping(false);
        }, TIME_TO_ACTIVE_DRAG);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getMsg, readCache],
  );

  return { handleMappedDocument };
};

export default useMapDocuments;
