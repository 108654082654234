import React from 'react';

import { useTableRowContext } from '../context/TableRowContext';

const HideWhenEditingWrapper = ({ children }) => {
  const { showForm } = useTableRowContext();

  return <>{!showForm && children}</>;
};

export default HideWhenEditingWrapper;
