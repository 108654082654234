export const ATTRIBUTE_TYPE = {
  singleSelect: 'Single Select',
  multiSelect: 'Multi Select',
};

export const getAttributeVariants = (attribute) =>
  attribute?.selectVariants?.map((variant) => ({
    id: variant.partAttributeSelectVariantId,
    label: variant.textValue,
    code: variant.codeValue,
  }));

export const getVariantText = (attribute) =>
  attribute?.selectVariants?.map((variant) => variant.textValue.toLowerCase());

export const getVariantCodes = (attribute) =>
  attribute?.selectVariants?.map((variant) => variant?.codeValue?.toLowerCase());

export const getSelectVariantDefaultValue = (attributeType) =>
  attributeType === ATTRIBUTE_TYPE.singleSelect ? null : [];

export const getAttributePartFormDefaultValue = (attribute) => ({
  partAttributeId: attribute?.partAttributeId,
  code: '',
  text: '',
  isNewVariant: false,
  selectedVariants: getSelectVariantDefaultValue(
    attribute?.partAttributeType?.partAttributeTypeName,
  ),
  partAttributeTypeName: attribute?.partAttributeType?.partAttributeTypeName,
  partAttributeName: attribute?.partAttributeName,
  allowWriteInVariant: attribute?.allowWriteInVariant,
  selectVariants: attribute?.selectVariants,
});
