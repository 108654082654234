import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

const PREFIX = 'FILTER';

const setColumnsFilterCache = (contextId, field, state, all) => {
  const base = `${contextId}--${PREFIX}`;
  const prevValues = localStorage.getItem(base) ?? '{}';
  if (all) {
    localStorage.setItem(base, JSON.stringify(all));
  } else {
    const parse = JSON.parse(prevValues);
    const newStatus = { ...parse, [field]: state };
    localStorage.setItem(base, JSON.stringify(newStatus));
  }
};

const geColumnsFilterCache = (contextId) => {
  const base = `${contextId}--${PREFIX}`;
  const map = JSON.parse(localStorage.getItem(base) ?? '{}');
  return map;
};

export const ItemsDataGridProContext = createContext();

export const ItemsDataGridProProvider = ({ children, contextId = 'ItemsDataGridPro-default' }) => {
  const columnsRef = useRef([]);
  const [columnsFilter, setColumnsFilter] = useState({});

  useEffect(() => {
    setColumnsFilter(geColumnsFilterCache(contextId));
  }, [contextId]);

  const onUpdateColumns = useCallback((newColumns) => {
    columnsRef.current = newColumns;
  }, []);

  const onUpdateColumnsFilter = useCallback(
    (field, state, all) => {
      if (all) {
        setColumnsFilter(all);
        setColumnsFilterCache(contextId, undefined, undefined, all);
      } else {
        setColumnsFilter((prev) => ({ ...prev, [field]: state }));
        setColumnsFilterCache(contextId, field, state);
      }
    },
    [contextId],
  );

  const onGetColumnsReference = useCallback(() => columnsRef.current, [columnsRef]);

  const exportColumns = useMemo(() => {
    const filterByState = Object.entries(columnsFilter)
      // eslint-disable-next-line no-unused-vars
      .filter(([, value]) => value)
      .map(([key]) => key);

    const filterByKey = onGetColumnsReference().filter(({ field }) => filterByState.includes(field));

    return filterByKey;
  }, [onGetColumnsReference, columnsFilter]);

  const apiRef = useMemo(
    () => ({
      onUpdateColumns,
      contextId,
      onGetColumnsReference,
      onUpdateColumnsFilter,
      columnsFilter,
      exportColumns,
    }),
    [onUpdateColumns, contextId, onGetColumnsReference, onUpdateColumnsFilter, columnsFilter, exportColumns],
  );
  return <ItemsDataGridProContext.Provider value={apiRef}>{children}</ItemsDataGridProContext.Provider>;
};

export const useItemsDataGridPro = ({ prevColumns, updateColumns = true } = {}) => {
  const context = useContext(ItemsDataGridProContext);
  if (!context) throw new Error('ItemsDataGridPro require ItemsDataGridProProvider');
  const { onUpdateColumns, onGetColumnsReference, contextId, columnsFilter, onUpdateColumnsFilter, exportColumns } =
    context;

  useEffect(() => {
    if (updateColumns) onUpdateColumns(prevColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevColumns]);

  return {
    onGetColumnsReference,
    contextId,
    onUpdateColumns,
    onUpdateColumnsFilter,
    columnsFilter,
    exportColumns,
  };
};
