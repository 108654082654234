import React, { useContext, useMemo, useState } from 'react';

import { useUOMAPIContext } from 'modules/Materials/CatalogSetup/Providers/UOMAPIProvider';

import { getUomOptions } from './unitOfMeasureHelpers';

export const UnitOfMeasureContext = React.createContext();

export const UnitOfMeasureProvider = ({ children }) => {
  const [newUnitOfMeasure, setNewUnitOfMeasure] = useState(false);
  const { uoms } = useUOMAPIContext();
  const uomOptions = useMemo(() => getUomOptions(uoms), [uoms]);

  return (
    <UnitOfMeasureContext.Provider value={{ newUnitOfMeasure, setNewUnitOfMeasure, uomOptions }}>
      {children}
    </UnitOfMeasureContext.Provider>
  );
};

export const useUnitOfMeasureContext = () => {
  const context = useContext(UnitOfMeasureContext);
  if (context === undefined) {
    throw new Error('useUnitOfMeasureContext must be used within a UnitOfMeasureProvider');
  }
  return context;
};
