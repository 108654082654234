import React from 'react';

import { Stack } from '@mui/material';

import { BreakdownChips, OptionsChips, RulesChips, SourceDataChips } from './ChipsFilters';
import { usePopover } from './PopoverProvider';

const ChipsArea = () => (
  <Stack direction="row" sx={{ flexWrap: 'wrap', margin: '16px 24px 0 24px', width: '640px' }}>
    <BreakdownChips contextHook={usePopover} />
    <OptionsChips />
    <RulesChips />
    <SourceDataChips />
  </Stack>
);

export default ChipsArea;
