import React from 'react';

import { Box, Stack } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Droppable } from 'react-beautiful-dnd';

// eslint-disable-next-line import/no-cycle
import LocationDraggableRow from './LocationDraggableRow';

const LocationDroppableContainer = ({ droppableId, childrenNodes = [], ...rest }) => (
  <Box sx={{ height: 'auto' }}>
    <Droppable droppableId={droppableId} type="DROPPABLE-AREA">
      {(droppableProvided, snapshot) => (
        <>
          <Box
            isDraggingOver={snapshot.isDraggingOver}
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps}
            {...droppableProvided.dragHandleProps}
            sx={{ backgroundColor: grey[100] }}
          >
            <EmptyContent />
            {droppableProvided.placeholder}
          </Box>
          {childrenNodes.length ? (
            <>
              {childrenNodes.map((node, index) => (
                <LocationDraggableRow
                  key={node.locationPhaseId || node.locationId}
                  index={index}
                  draggableId={node.locationPhaseId || node.locationId}
                  node={node}
                  {...rest}
                />
              ))}
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </Droppable>
  </Box>
);

export default LocationDroppableContainer;

const EmptyContent = () => <Stack sx={{ height: '1px', overflow: 'hidden' }}>&nbsp;</Stack>;
