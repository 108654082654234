/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';

import {
  addChildrenRecursively,
  clone,
  findAnyOccurrence,
  findNode,
  findParent,
  recursiveToggleEditNodeLabel,
  recursiveToggleKey,
  recursiveUpdateNode,
  removeChildrenRecursively,
  replaceItemsById,
} from 'helpers/arrayFunctions';

import { ROW_TYPES } from '../../constants/constants';
import { useDeleteModal } from '../../contexts/DeleteModalContext';
import { useLocationTypeAndFormat } from '../../contexts/LocationTypeAndFormatContext';
import { useProject } from '../../contexts/ProjectContext';
import useProjectDataLayer from '../../hooks/useProjectDataLayer';
import { createLocation } from '../helpers/locationsHelpers';
import {
  appendNewChildren,
  appendNewChildBySortOrder,
  appendNewChild,
} from '../helpers/tableRowHelpers';
import useLocationsDataLayer from './useLocationsDataLayer';
import { getFirstChildType } from './useMoreMenu/moreMenuHelpers';

export default function useLocations(
  locations,
  setLocations,
  updateSuffix,
  fetchLocationsByWorkPhase,
) {
  const [backupState, _setBackupState] = useState();
  const [previewActiveRow, setPreviewActiveRow] = useState({});
  const [activeRow, setActiveRow] = useState({});

  const { selectedProject, loading: loadingProjectDetails } = useProject();
  const { locationToBeDeleted, hideDeleteModal } = useDeleteModal();
  const { locationTypes } = useLocationTypeAndFormat();

  const isEditingALocation = findAnyOccurrence(locations || [], 'isEditing');

  const { fetchProjectLocations, loadingProjectLocations } = useProjectDataLayer();

  const backupRef = React.useRef(backupState);
  const setBackupState = (data) => {
    backupRef.current = data;
    _setBackupState(data);
  };

  const { fetchLocations, loadingLocations, addLocation, updateLocation, removeLocation } =
    useLocationsDataLayer(updateSuffix, fetchLocationsByWorkPhase);

  const goToBackup = () => {
    setLocations(backupRef.current);
    setBackupState(null);
  };

  const displayProjectRow = () => {
    setActiveRow(selectedProject);
    setLocations([selectedProject]);
    fetchProjectLocations(selectedProject, (projectWithChildren) => {
      setActiveRow(projectWithChildren);
      setLocations([projectWithChildren]);
    });
  };

  const toggleEditNode = (location) => {
    if (!location.isEditing) setBackupState(clone(locations));
    setLocations(recursiveToggleEditNodeLabel(location, locations, 'locationId'));
  };

  const collapseNodeChildren = (node) =>
    setLocations(recursiveToggleKey(node.locationId, locations, 'isOpen', 'locationId'));

  const fetchLocationData = (node) => fetchLocations(locations, node, setLocations);

  const toggleOpenNode = async (node, addNewLocation, avoidClosing) => {
    const isOpeningALocationWithHiddenChildren =
      !node.isOpen && node.childrenTypeName && !node.children;
    const hasClickedOnAddChildInMoreMenuOptions = addNewLocation;

    if (isOpeningALocationWithHiddenChildren) {
      return fetchLocations(locations, node, (newLocations) => {
        if (hasClickedOnAddChildInMoreMenuOptions) addNewLocation(newLocations);
        else setLocations(newLocations);
      });
    }

    if (!(node.isOpen && avoidClosing)) collapseNodeChildren(node);
    if (hasClickedOnAddChildInMoreMenuOptions) addNewLocation();
    return null;
  };

  const createLocationRow = (selectedRow, newRowData, position, updatedLocations) => {
    const targetLocations = updatedLocations || locations;
    const updatedSelectedRow = updatedLocations
      ? findNode(selectedRow.locationId, updatedLocations, 'locationId')
      : selectedRow;

    const createChildNode = () => {
      const firstChildType = getFirstChildType(selectedRow);
      if (
        newRowData.locationTypeName === ROW_TYPES.LOCATION_SYSTEM &&
        [ROW_TYPES.FLOOR, ROW_TYPES.ZONE].includes(selectedRow.locationTypeName) &&
        firstChildType
      ) {
        if ([ROW_TYPES.FLOOR, ROW_TYPES.ZONE].includes(firstChildType)) {
          newRowData.children = updatedSelectedRow.children;
          newRowData.isOpen = true;
          updatedSelectedRow.children = [];
        }
      }
      setLocations(
        replaceItemsById(
          appendNewChild(updatedSelectedRow, newRowData),
          targetLocations,
          'locationId',
        ),
      );
    };

    setActiveRow(newRowData);

    if (position === 'child') return createChildNode();
    if (position === 'above') newRowData.sortOrder = updatedSelectedRow.sortOrder;
    else if (position === 'below') newRowData.sortOrder = updatedSelectedRow.sortOrder + 1;

    const parent = findParent(updatedSelectedRow.locationId, targetLocations, 'locationId');
    const parentWithNewChild = appendNewChildBySortOrder(parent, newRowData);
    const newLocations = replaceItemsById(parentWithNewChild, targetLocations, 'locationId');
    return setLocations(newLocations);
  };

  const handleAddLocation = async (selectedRow, type = ROW_TYPES.ZONE, position = 'child') => {
    const newRowData = createLocation(type);
    setBackupState(clone(locations));
    if (position === 'child' && !selectedRow.isOpen) {
      toggleOpenNode(selectedRow, (newLocations) =>
        createLocationRow(selectedRow, newRowData, position, newLocations),
      );
    } else {
      createLocationRow(selectedRow, newRowData, position);
    }
  };

  const handleUpdateLocation = (location) => {
    if (location.isNew && location.isEditing)
      addLocation(location, locations, locationTypes, selectedProject, setLocations);
    else if (location.isEditing) updateLocation(location);

    toggleEditNode(location);
  };

  const handleDeleteLocation = async () => {
    removeLocation(locationToBeDeleted, locations, setLocations);
    hideDeleteModal();
  };

  const addWizardLocations = (addedLocations) => {
    setActiveRow({});
    setLocations(
      replaceItemsById(appendNewChildren(activeRow, addedLocations), locations, 'locationId'),
    );
  };

  const cancelCreation = () => {
    if (backupRef.current) goToBackup();
  };

  const promote = (location, parent) => {
    const backup = clone(locations);
    let grandfather = clone(findParent(parent.locationId, locations, 'locationId'));
    removeChildrenRecursively(location.locationId, grandfather.children, 'locationId');

    const parentLocationId =
      grandfather.locationId === locations[0].locationId ? null : grandfather.locationId;

    const newLocation = { ...location, parentLocationId, sortOrder: parent.sortOrder + 1 };
    grandfather = appendNewChildBySortOrder(grandfather, newLocation);
    setLocations(recursiveUpdateNode(grandfather, locations, 'locationId'));
    updateLocation(newLocation, () => setLocations(backup));
  };

  const demote = (location, parent, aboveSibling) => {
    const backup = clone(locations);
    let newChildren = removeChildrenRecursively(location.locationId, parent.children, 'locationId');
    newChildren = addChildrenRecursively(
      aboveSibling,
      newChildren,
      location,
      false,
      false,
      'locationId',
    );
    parent.children = newChildren;

    const newLocation = {
      ...location,
      parentLocationId: aboveSibling.locationId,
      sortOrder: location.sortOrder || 1,
    };
    updateLocation(newLocation, () => setLocations(backup));
  };

  useEffect(() => {
    if (selectedProject) {
      setBackupState(null);
      displayProjectRow();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject]);

  const rowsStatus = { locations, activeRow, setActiveRow, previewActiveRow, setPreviewActiveRow };
  const frontendEvents = { toggleOpenNode, isEditingALocation, toggleEditNode };
  const backendEvents = { handleAddLocation, handleDeleteLocation, handleUpdateLocation };

  return {
    ...rowsStatus,
    ...frontendEvents,
    ...backendEvents,
    loading: loadingProjectLocations || loadingProjectDetails,
    loadingLocations,
    addWizardLocations,
    cancelCreation,
    promote,
    demote,
    fetchLocationData,
    setBackupState,
    goToBackup,
    setLocations,
  };
}
