import React, { useRef, useCallback, useEffect } from 'react';

import { CalendarProvider, layoutOptions } from '@evolvemep/foresite-components-ui-react';
import { DragDropContext } from 'react-beautiful-dnd';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import ComponentPaneLayout from 'app/Layout/ReactGridLayout/ComponentPaneLayout';
import { FacilityAndProjectWrapper } from 'components';
import { ItemsDataGridProProvider } from 'components/ItemsDatagridPro';
import { PopoverFilterProvider } from 'components/Popover/FilterPopover/context/PopoverFilterContext';
import { WorkOrderUpdateProvider } from 'hooks-api/useWorkOrderUpdate';
import { DrawingRegister, DrawingRegisterProvider } from 'modules/Design/DrawingRegister';
import { ProjectProvider } from 'modules/Field/LocationsAndWorkPhases/contexts/ProjectContext';
import { UpdateWorkRequestStatusProvider } from 'modules/Field/WorkRequests/WorkRequest/hooks/useUpdateWorkRequestStatus/useUpdateWorkRequestStatus';
import WorkRequest from 'modules/Field/WorkRequests/WorkRequest/WorkRequest';
import { WorkRequestDragDropProvider } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestDragDropProvider';
import WorkRequestFlyoutMenu from 'modules/Field/WorkRequests/WorkRequest/WorkRequestFlyoutMenu/WorkRequestFlyoutMenu';
import { WorkRequestItemsProvider } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestItemsContext';
import WorkRequestsAndProperties from 'modules/Field/WorkRequests/WorkRequestsAndProperties';
import { WorkRequestsProvider as GeneralWorkRequestProvider } from 'modules/Field/WorkRequests/WorkRequestsContext';
import WorkRequestListFlyoutMenu from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestListFlyoutMenu/WorkRequestListFlyoutMenu';
import BillOfMaterials from 'modules/Materials/BillOfMaterials/BillOfMaterials';
import BillOfMaterialsById from 'modules/Materials/BillOfMaterialsById/BillOfMaterialsById';
import BillOfMaterialsFlyoutMenu from 'modules/Materials/BillOfMaterialsById/BillOfMaterialyFlyoutMenu';
import { BOMbyIdProvider } from 'modules/Materials/BillOfMaterialsById/BOMbyIdContext';
import CatalogSetup from 'modules/Materials/CatalogSetup/CatalogSetup';
import { PartImageProvider } from 'modules/Materials/CatalogSetup/ItemsSection/Manufacturer/PartImage/PartImageContext';
import { CatalogPartProvider } from 'modules/Materials/CatalogSetup/Providers/CatalogPartProvider';
import { CatalogSelectedPartProvider } from 'modules/Materials/CatalogSetup/Providers/CatalogSelectedPartProvider';
import { ManufacturerAPIProvider } from 'modules/Materials/CatalogSetup/Providers/ManufacturerAPIProvider';
import { PartAPIProvider } from 'modules/Materials/CatalogSetup/Providers/PartAPIProvider';
import { UOMAPIProvider } from 'modules/Materials/CatalogSetup/Providers/UOMAPIProvider';
import { BOMItemProvider } from 'modules/Materials/components/BillOfMaterialsItemPopup/BOMItemContext';
import { AssignationModeProvider } from 'modules/Materials/features/assignation-mode';
import FiltersProviderBase from 'modules/Materials/FiltersProviderBase';

import WorkRequestFilter from '../Field/WorkRequests/WorkRequestsList/WorkRequestFilter/WorkRequestFilter';
import { ShopDepartmentFallback } from './components/ShopDepartmentFallback';
import { CustomAssembliesProvider } from './CustomAssemblies/CustomAssembliesContext';
import { CustomAssemblyTypesList } from './CustomAssemblies/CustomAssemblyTypes/CustomAssemblyTypesList';
import { CustomAssemblyTypesProvider } from './CustomAssemblies/CustomAssemblyTypesContext';
import { CustomAssembly } from './CustomAssembly/CustomAssembly';
import TaskViewer from './Fabrication/TaskViewer/TaskViewer';
import WorkCellQueue from './Fabrication/WorkCellQueue/WorkCellQueue';
import { WorkCellQueueContextProvider } from './Fabrication/WorkCellQueue/WorkCellQueueContext';
import NoShopDptOverlay from './NoShopDptOverlay';
import ShopMembers from './ShopMembers/ShopMembers';
import ShopProperties from './ShopProperties/ShopProperties';
import ShopTasksTypes from './ShopSetup/ShopTasks/ShopTasks';
import { ShopTasksProvider } from './ShopSetup/ShopTasks/ShopTasksContext';
import { DataProvider } from './ShopSetup/WorkCellSetup/WorkCellsAndMachines/contexts/DataContext';
import { WorkCellsAndMachinesProvider } from './ShopSetup/WorkCellSetup/WorkCellsAndMachines/contexts/WorkCellsAndMachinesContext';
import { ShopConfigurationSelectProvider } from './ShopSetup/WorkCellSetup/WorkCellsAndMachines/ShopConfiguration/ShopConfigurationSelect/ShopConfigurationSelectContext';
import WorkCellsAndMachinesLayout from './ShopSetup/WorkCellSetup/WorkCellsAndMachinesLayout';
import { WorkCellsKanbanProvider } from './WorkCells/context/WorkCellsContext';
import { WorkCellsListProvider } from './WorkCells/context/WorkCellsListContext';
import WorkCellsList from './WorkCells/WorkCellsList/WorkCellsList';
import { Workflows } from './Workflows';
import { WorkfowsEditor } from './WorkfowsEditor';
import { WorkflowEditorProvider } from './WorkfowsEditor/WorkflowsEditorContext';
import { WorkOrderItemsProvider } from './WorkOrders/WorkOrder/context/WorkOrderItemsContext';
import WorkOrderItems from './WorkOrders/WorkOrder/WorkOrderItems';
import { WorkOrdersProvider } from './WorkOrders/WorkOrdersContext';
import WorkOrderWRProvider from './WorkOrders/WorkOrdersList/context/WorkOrderWRProvider';
import WorkOrdersList from './WorkOrders/WorkOrdersList/WorkOrdersList';
import { WorkRequestsProvider } from './WorkRequests/WorkRequestsContext';

export const Dashboard = () => (
  <ComponentPaneLayout>
    <Navigate to="work-requests" />
  </ComponentPaneLayout>
);

export const ShopMembersInterfaceNavContent = () => (
  <FacilityAndProjectWrapper isFacilityRequired>
    <ShopDepartmentFallback>
      <ComponentPaneLayout>
        <ShopMembers />
      </ComponentPaneLayout>
    </ShopDepartmentFallback>
  </FacilityAndProjectWrapper>
);

export const WorkCellsAndMachinesInterfaceNavContent = () => (
  <FacilityAndProjectWrapper isFacilityRequired>
    <ShopDepartmentFallback>
      <DataProvider>
        <WorkCellsAndMachinesProvider>
          <ShopConfigurationSelectProvider>
            <WorkCellsAndMachinesLayout />
          </ShopConfigurationSelectProvider>
        </WorkCellsAndMachinesProvider>
      </DataProvider>
    </ShopDepartmentFallback>
  </FacilityAndProjectWrapper>
);

export const ShopTasksInterfaceNavContent = () => (
  <ComponentPaneLayout>
    <ShopTasksTypes />
  </ComponentPaneLayout>
);

export const WorkRequestsNavContent = () => {
  const partDroppables = useRef();
  return (
    <FacilityAndProjectWrapper isFacilityRequired isProjectRequired>
      <NoShopDptOverlay>
        <GeneralWorkRequestProvider>
          <PopoverFilterProvider>
            <WorkOrderUpdateProvider>
              <UpdateWorkRequestStatusProvider>
                <WorkOrdersProvider>
                  <PopoverFilterProvider>
                    <ComponentPaneLayout flyoutMenu={<WorkRequestListFlyoutMenu ref={partDroppables} />}>
                      <WorkRequestsAndProperties filterComponent={<WorkRequestFilter />} />
                    </ComponentPaneLayout>
                  </PopoverFilterProvider>
                </WorkOrdersProvider>
              </UpdateWorkRequestStatusProvider>
            </WorkOrderUpdateProvider>
          </PopoverFilterProvider>
        </GeneralWorkRequestProvider>
      </NoShopDptOverlay>
    </FacilityAndProjectWrapper>
  );
};

export const WorkRequestNavContent = () => {
  const partDroppables = useRef();
  return (
    <GeneralWorkRequestProvider>
      <ShopTasksProvider>
        <WorkOrderUpdateProvider>
          <UpdateWorkRequestStatusProvider>
            <WorkRequestsProvider>
              <WorkRequestDragDropProvider>
                <WorkRequestItemsProvider>
                  <ComponentPaneLayout
                    componentPaneProps={{ pb: '8px' }}
                    flyoutMenu={<WorkRequestFlyoutMenu ref={partDroppables} />}
                  >
                    <WorkRequest hideBulkActions />
                  </ComponentPaneLayout>
                </WorkRequestItemsProvider>
              </WorkRequestDragDropProvider>
            </WorkRequestsProvider>
          </UpdateWorkRequestStatusProvider>
        </WorkOrderUpdateProvider>
      </ShopTasksProvider>
    </GeneralWorkRequestProvider>
  );
};

export const TaskTypeMappingInterfaceNavContent = () => (
  <ComponentPaneLayout>
    <div>Type Task Mapping</div>
  </ComponentPaneLayout>
);

export const ShopPropertiesInterfaceNavContent = () => (
  <FacilityAndProjectWrapper isFacilityRequired>
    <ShopDepartmentFallback>
      <ComponentPaneLayout>
        <ShopProperties />
      </ComponentPaneLayout>
    </ShopDepartmentFallback>
  </FacilityAndProjectWrapper>
);

export const WorkCellsInterfaceNavContent = () => (
  <FacilityAndProjectWrapper isFacilityRequired>
    <ShopDepartmentFallback>
      <DataProvider>
        <PopoverFilterProvider>
          <WorkCellsAndMachinesProvider>
            <WorkCellsListProvider>
              <WorkCellsKanbanProvider>
                <ComponentPaneLayout componentPaneProps={{ pb: 0 }}>
                  <WorkCellsList />
                </ComponentPaneLayout>
              </WorkCellsKanbanProvider>
            </WorkCellsListProvider>
          </WorkCellsAndMachinesProvider>
        </PopoverFilterProvider>
      </DataProvider>
    </ShopDepartmentFallback>
  </FacilityAndProjectWrapper>
);

export const CatalogSetupInterfaceNavContent = () => (
  <ComponentPaneLayout>
    <CatalogSelectedPartProvider>
      <PartAPIProvider>
        <UOMAPIProvider>
          <ManufacturerAPIProvider>
            <PartImageProvider>
              <CatalogPartProvider>
                <CatalogSetup />
              </CatalogPartProvider>
            </PartImageProvider>
          </ManufacturerAPIProvider>
        </UOMAPIProvider>
      </PartAPIProvider>
    </CatalogSelectedPartProvider>
  </ComponentPaneLayout>
);

export const WorkOrdersNavContent = () => (
  <GeneralWorkRequestProvider>
    <FacilityAndProjectWrapper isFacilityRequired>
      <ShopDepartmentFallback>
        <PopoverFilterProvider>
          <WorkOrderUpdateProvider>
            <UpdateWorkRequestStatusProvider>
              <WorkOrdersProvider>
                <WorkOrderWRProvider>
                  <CalendarProvider allowViewPicker defaultLayout={layoutOptions.YEAR}>
                    <ComponentPaneLayout componentPaneProps={{ pb: '8px' }} flyoutMenu={<WorkRequestListFlyoutMenu />}>
                      <WorkOrdersList />
                    </ComponentPaneLayout>
                  </CalendarProvider>
                </WorkOrderWRProvider>
              </WorkOrdersProvider>
            </UpdateWorkRequestStatusProvider>
          </WorkOrderUpdateProvider>
        </PopoverFilterProvider>
      </ShopDepartmentFallback>
    </FacilityAndProjectWrapper>
  </GeneralWorkRequestProvider>
);

export const WorkCellQueueNavContent = () => (
  <FacilityAndProjectWrapper isFacilityRequired>
    <ShopDepartmentFallback>
      <WorkCellQueueContextProvider>
        <ComponentPaneLayout>
          <WorkCellQueue />
        </ComponentPaneLayout>
      </WorkCellQueueContextProvider>
    </ShopDepartmentFallback>
  </FacilityAndProjectWrapper>
);

export const TaskViewerNavContent = () => (
  <ComponentPaneLayout>
    <TaskViewer />
  </ComponentPaneLayout>
);

export const WorkOrderItemsNavContent = () => (
  <ShopTasksProvider>
    <WorkOrderUpdateProvider>
      <UpdateWorkRequestStatusProvider>
        <WorkOrderItemsProvider>
          <WorkRequestDragDropProvider>
            <ComponentPaneLayout componentPaneProps={{ pb: '8px' }} flyoutMenu={<WorkRequestFlyoutMenu />}>
              <WorkOrderItems />
            </ComponentPaneLayout>
          </WorkRequestDragDropProvider>
        </WorkOrderItemsProvider>
      </UpdateWorkRequestStatusProvider>
    </WorkOrderUpdateProvider>
  </ShopTasksProvider>
);

export const DrawingRegisterInterfaceNavContent = () => (
  <FacilityAndProjectWrapper isFacilityRequired isProjectRequired>
    <ProjectProvider>
      <WorkOrderUpdateProvider>
        <UpdateWorkRequestStatusProvider>
          <WorkRequestsProvider>
            <WorkRequestItemsProvider>
              <DrawingRegisterProvider>
                <PopoverFilterProvider>
                  <DrawingRegister />
                </PopoverFilterProvider>
              </DrawingRegisterProvider>
            </WorkRequestItemsProvider>
          </WorkRequestsProvider>
        </UpdateWorkRequestStatusProvider>
      </WorkOrderUpdateProvider>
    </ProjectProvider>
  </FacilityAndProjectWrapper>
);

export const CustomAssemblyNavContent = () => {
  const { selectedItem } = useFacilitiesProjects();

  const isProject = Boolean(selectedItem && selectedItem?.type === 'PROJECT');

  const Component = () => {
    if (isProject) return <Navigate to={selectedItem.id} />;
    return <CustomAssembly />;
  };

  return (
    <FacilityAndProjectWrapper isFacilityRequired isProjectRequired>
      <ComponentPaneLayout>
        <Component />
      </ComponentPaneLayout>
    </FacilityAndProjectWrapper>
  );
};

export const CustomAssemblyByIdNavContent = () => {
  const Component = () => <CustomAssemblyTypesList />;

  return (
    <ProjectProvider>
      <CustomAssemblyTypesProvider>
        <CustomAssembliesProvider>
          <ComponentPaneLayout>
            <Component />
          </ComponentPaneLayout>
        </CustomAssembliesProvider>
      </CustomAssemblyTypesProvider>
    </ProjectProvider>
  );
};

export const WorkflowsNavContent = () => (
  <FacilityAndProjectWrapper isFacilityRequired isProjectRequired>
    <ComponentPaneLayout>
      <Workflows />
    </ComponentPaneLayout>
  </FacilityAndProjectWrapper>
);

export const WorkflowNavContent = () => (
  <FacilityAndProjectWrapper isFacilityRequired isProjectRequired>
    <ComponentPaneLayout>
      <WorkflowEditorProvider>
        <WorkfowsEditor />
      </WorkflowEditorProvider>
    </ComponentPaneLayout>
  </FacilityAndProjectWrapper>
);

export const BillOfMaterialsInterfaceNavContent = () => {
  const { selectedItem } = useFacilitiesProjects();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (selectedItem === null && params.id) navigate('../bill-of-materials');
    // eslint-disable-next-line
  }, [selectedItem]);

  return (
    <FacilityAndProjectWrapper isFacilityRequired isProjectRequired>
      <ItemsDataGridProProvider contextId="BillOfMaterials">
        <ComponentPaneLayout>
          <BillOfMaterials />
        </ComponentPaneLayout>
      </ItemsDataGridProProvider>
    </FacilityAndProjectWrapper>
  );
};
export const BillOfMaterialsByIdInterfaceNavContent = () => {
  const { selectedItem } = useFacilitiesProjects();
  const navigate = useNavigate();
  const params = useParams();
  const BOMRef = useRef();
  const partDroppables = useRef();

  const onDropPartDroppable = useCallback(({ draggableId }) => {
    const partDroppable = partDroppables.current?.parts?.find(({ partId }) => partId === draggableId);
    BOMRef.current.handleDropItem(partDroppable);
  }, []);

  useEffect(() => {
    if (selectedItem === null && params.id) navigate('../bill-of-materials');
    // eslint-disable-next-line
  }, [selectedItem]);

  return (
    <FiltersProviderBase>
      <BOMbyIdProvider>
        <DragDropContext onDragEnd={onDropPartDroppable}>
          <AssignationModeProvider>
            <BOMItemProvider>
              <FacilityAndProjectWrapper isFacilityRequired isProjectRequired>
                <ComponentPaneLayout flyoutMenu={<BillOfMaterialsFlyoutMenu ref={partDroppables} />}>
                  <ItemsDataGridProProvider contextId="BillOfMaterialsById">
                    <BillOfMaterialsById ref={BOMRef} />
                  </ItemsDataGridProProvider>
                </ComponentPaneLayout>
              </FacilityAndProjectWrapper>
            </BOMItemProvider>
          </AssignationModeProvider>
        </DragDropContext>
      </BOMbyIdProvider>
    </FiltersProviderBase>
  );
};
