import React, { useEffect, useMemo, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import useFetchWorkOrder from 'hooks-api/useFetchWorkOrder';
import useFetchWorkOrderStatusType from 'hooks-api/useFetchWorkOrderStatusType';

import { mapWorkOrderDataToTableColumns } from '../../WorkOrdersList/WorkOrdersTable';
import { WORK_REQUEST_ITEMS_VIEWS } from '../constants/constants';
import { useWorkCells } from '../hooks/useWorkCells';
import { useWorkOrderPermissions } from '../hooks/useWorkOrderPermissions';

const WorkOrderItemsContext = React.createContext();

const WorkOrderItemsProvider = ({ children }) => {
  const { id: workOrderId } = useParams();
  const navigate = useNavigate();

  const { workOrder, loading, startPolling, stopPolling } = useFetchWorkOrder(workOrderId);
  const { workOrderStatusType, loading: loadingStatus } = useFetchWorkOrderStatusType();

  const { inProgressTasks, loadingTasksData, refetchTaskData } = useWorkCells(workOrder?.workRequestId);

  const [currentView, setCurrentView] = useState(WORK_REQUEST_ITEMS_VIEWS.TABLE_VIEW);
  const [activeDocument, setActiveDocument] = useState(null);
  const [newUomId, setNewUomId] = useState(null);
  const [isDrawingWRItem, setIsDrawingWRItem] = useState(false);

  const WOisLate = useMemo(() => {
    if (!workOrder) return false;
    const WOmapped = mapWorkOrderDataToTableColumns(workOrder);
    return WOmapped?.daysRemaining < 0;
  }, [workOrder]);

  useEffect(() => {
    if (loading) return;
    if (!workOrder) navigate('/shop/work-orders');
  }, [loading, navigate, workOrder]);

  useEffect(() => {
    if (workOrder?.isStepFunctionProcessing) startPolling(5000);

    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling, workOrder]);

  const selectedStateObj = {
    activeDocument,
    currentView,
    inForgeView: currentView === WORK_REQUEST_ITEMS_VIEWS.FORGE_VIEW,
    inProgressTasks,
    refetchTaskData,
    loading,
    loadingStatus,
    loadingTasksData,
    setActiveDocument,
    setCurrentView,
    workOrder,
    workOrderStatusType,
    WOisLate,
    WOStatus: workOrder?.workOrderStatusTypeName,
    newUomId,
    setNewUomId,
    isDrawingWRItem,
    setIsDrawingWRItem,
    ...useWorkOrderPermissions(workOrder),
  };

  return <WorkOrderItemsContext.Provider value={selectedStateObj}>{children}</WorkOrderItemsContext.Provider>;
};

const useWorkOrderItemsContext = () => {
  const context = React.useContext(WorkOrderItemsContext);

  if (context === undefined) {
    throw new Error('useWorkOrderItemsContext must be used within a WorkOrderItemsContext');
  }

  return context;
};

export { WorkOrderItemsContext, WorkOrderItemsProvider, useWorkOrderItemsContext };
