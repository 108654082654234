import React, { useCallback, useEffect, useMemo } from 'react';

import { MessageRibbon } from '@evolvemep/foresite-components-ui-react';
import { useLocation, useNavigate } from 'react-router-dom';

import { extractTextFromHTML } from 'helpers/stringFunctions';

import { useMessageAlertBannerContext } from './MessageAlertBannerContext';
import useFectchMessageById from './useFetchMessageById';

const MessageAlertBanner = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const onViewMoreAlerts = () => navigate('/message', { replace: true });

  const { alertsCount } = useMessageAlertBannerContext();
  const { alerts, dismissAlert } = useMessageAlertBannerContext();
  const { message, fetchMessageById } = useFectchMessageById();

  useEffect(() => {
    if (!alerts?.length) return;

    const selectedAlert = alerts[0];
    if (selectedAlert?.messageId)
      fetchMessageById({ variables: { params: { id: selectedAlert.messageId } } });
  }, [alerts, fetchMessageById]);

  const handleViewAlert = useCallback(() => {
    if (message?.messageId) dismissAlert(message?.messageId);

    navigate(`/message?messageId=${message?.messageId}`, {
      replace: true,
      state: { prevPath: location.pathname },
    });
  }, [navigate, message, dismissAlert, location.pathname]);

  const handleDismissAlert = () => {
    if (message?.messageId) dismissAlert(message?.messageId);
  };

  const messageBodyPlainText = useMemo(
    () => (message?.body ? extractTextFromHTML(message.body) : ''),
    [message?.body],
  );

  if (!alertsCount) return <></>;

  return (
    <MessageRibbon sx={{ top: 64, left: 64, right: 0, width: 'auto' }}>
      <MessageRibbon.Main>
        <MessageRibbon.Main.Description>
          <MessageRibbon.Main.Description.Title>
            {message?.subject ?? 'Loading...'}
          </MessageRibbon.Main.Description.Title>
          <MessageRibbon.Main.Description.Subtitle>
            {messageBodyPlainText}
          </MessageRibbon.Main.Description.Subtitle>
        </MessageRibbon.Main.Description>
        <MessageRibbon.Main.Actions
          onViewClick={handleViewAlert}
          onDismissClick={handleDismissAlert}
        />
      </MessageRibbon.Main>
      {alertsCount > 1 && (
        <MessageRibbon.Footer numberOfMessages={alertsCount} onMessagesClick={onViewMoreAlerts} />
      )}
    </MessageRibbon>
  );
};

export default MessageAlertBanner;
