import React, { useState } from 'react';

import { useMutation, gql } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import { Modal, CancelButton, CreateButton } from 'components';
import { emailRegEx } from 'constants/regEx';
import { VARIANT_ERROR, VARIANT_SUCCESS } from 'constants/snackbarConstants';
import { addDepartmentUser as ADD_DEPARTMENT_USER } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

import ShopInviteForm from './ShopInviteForm';

const InviteToShopModal = ({ openStatus, onClose, departmentId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loadingUsers, setLoadingUsers] = useState(false);

  const { handleBatchResponse } = useGraphqlResponseHandler();
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: 'all',
  });

  const [addDepartmentUserMutation, { loading }] = useMutation(gql(ADD_DEPARTMENT_USER), {
    refetchQueries: ['DepartmentUsers'],
  });

  const submit = async ({ newShopUsers }) => {
    const invalidEmails = newShopUsers.filter((shopUser) => typeof shopUser !== 'object' && !emailRegEx.test(shopUser));

    if (invalidEmails.length > 0) {
      enqueueSnackbar(
        `'${invalidEmails.join(', ')}' ${invalidEmails.length > 1 ? 'are' : 'is'} not a valid email`,
        VARIANT_ERROR,
      );
      return;
    }
    try {
      const results = await Promise.all(
        newShopUsers.map(async (shopUser) => {
          const body = {
            departmentId,
          };

          if (typeof shopUser === 'object') {
            body.userId = shopUser.id;
          } else {
            body.userEmail = shopUser;
          }

          const res = await addDepartmentUserMutation({
            variables: {
              body,
            },
          });

          return res;
        }),
      );

      handleBatchResponse(results, { successMessage: '' });
      enqueueSnackbar('Invites successfully sent!', VARIANT_SUCCESS);
    } finally {
      onClose();
    }
  };

  return (
    <Modal
      open={openStatus}
      onClose={onClose}
      title="Invite users to shop"
      reducePadding
      fullWidth
      allowOverflow
      maxWidth="xs"
      titleStyles={{ fontWeight: 'bold' }}
      footer={
        <>
          {(loading || loadingUsers) && <CircularProgress sx={{ mr: 2 }} />}
          <CancelButton color="primary" onClick={onClose} sx={{ fontWeight: '500', color: 'primary.main' }} />
          <CreateButton
            buttonText="Invite"
            onClick={handleSubmit(submit)}
            disabled={!isValid || loading}
            sx={{ fontWeight: '500' }}
          />
        </>
      }
    >
      <ShopInviteForm control={control} setLoadingUsers={setLoadingUsers} />
    </Modal>
  );
};

export default InviteToShopModal;
