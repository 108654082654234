import React, { useCallback } from 'react';

import { phoneInputRules } from 'constants/inputFieldRules';

import { NumberForesiteField } from '../NumberForesiteField';

const minifyNumber = (phoneNumber) => phoneNumber.replace(/\D/g, '');

const PhoneControlField = ({
  name,
  rulesText,
  setValue,
  trigger,
  hyphensPositions = [3, 6],
  minLength,
  maxLength,
  ...props
}) => {
  const nextIsDash = useCallback(
    (nextNumberIndex) => hyphensPositions.some((nashIndex) => nashIndex === nextNumberIndex),
    [hyphensPositions],
  );

  const formatPhoneNumber = useCallback(
    (phoneNumber) => {
      let formattedNumber = '';
      minifyNumber(phoneNumber)
        .split('')
        .forEach((nextNumber, nextNumberIndex) => {
          formattedNumber += nextIsDash(nextNumberIndex) ? `-${nextNumber}` : `${nextNumber}`;
        });
      return formattedNumber;
    },
    [nextIsDash],
  );

  const formatPhone = useCallback(
    async (value, formProperty) => {
      setValue(formProperty, formatPhoneNumber(value), { shouldDirty: true });
      await trigger(formProperty);
    },
    [formatPhoneNumber, setValue, trigger],
  );

  return (
    <NumberForesiteField
      type="phone"
      name={name}
      onChange={(e) => formatPhone(e.target.value, name)}
      rules={phoneInputRules(rulesText, hyphensPositions, minLength, maxLength)}
      {...props}
    />
  );
};

export default PhoneControlField;
