import React, { useState } from 'react';

import { Box, Typography, Grid } from '@mui/material';

import CardItemContainer from 'modules/Materials/components/CardItemContainer';

import VariantForm from './VariantForm';

const VariantRow = ({ actionComponent, variant, variants, ...rest }) => {
  const [isEditMode] = useState(false);
  return (
    <Box {...rest}>
      {isEditMode ? (
        <EditVariantRow
          variants={variants}
          actionComponent={actionComponent}
          variant={variant}
          selectedItem={{ code: variant?.codeValue, text: variant?.textValue }}
        />
      ) : (
        <TextVariantRow
          isEditMode={isEditMode}
          setIsEditMode={() => {}}
          primaryText={variant?.textValue}
          codeText={variant?.codeValue}
          actionComponent={actionComponent}
        />
      )}
    </Box>
  );
};

const TextVariantRow = ({ primaryText, codeText, setIsEditMode }) => (
  <CardItemContainer>
    <Grid container alignItems="center">
      <Grid item xs={9} onClick={setIsEditMode}>
        <Typography variant="body2" flexGrow={3}>
          {primaryText}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        {/* <Stack direction="row" alignItems="center" flexGrow={1}> */}
        <Typography variant="body2">{codeText}</Typography>
        {/* {actionComponent} */}
        {/* </Stack> */}
      </Grid>
    </Grid>
  </CardItemContainer>
);

const EditVariantRow = ({ actionComponent, variant, selectedItem, variants }) => (
  <VariantForm
    variants={variants}
    actionComponent={actionComponent}
    defaultValues={variant}
    selectedItem={selectedItem}
    handleClickAttribute={() => {}}
    handleKeyDown={() => {}}
  />
);

export default VariantRow;
