import React from 'react';

import { Button, useTheme } from '@mui/material';

import { Custom } from 'components/Icons';

type props = {
  isShort: boolean;
  setIsShort: React.Dispatch<React.SetStateAction<boolean>>;
};
export const ShowMoreButton = ({ isShort, setIsShort }: props) => {
  const { palette } = useTheme();
  return (
    <Button
      sx={{
        fontSize: '14px',
        lineHeight: '20px',
        textTransform: 'none',
        padding: '0',
        color: palette.secondary.mainDarkBackground,
        width: '96px',
        height: '24px',
      }}
      variant="text"
      onClick={() => setIsShort(!isShort)}
      endIcon={isShort ? <Custom.ExpandMoreIcon /> : <Custom.ExpandLessIcon />}
    >{`Show ${isShort ? 'more' : 'less'}`}</Button>
  );
};
